import { IRheometerChartPoint } from './fluid.selectors';
import { RheologyCalculations } from '@dunefront/common/modules/fluid/dto/rheology/rheology.calculations';
import { RheologyDto } from '@dunefront/common/modules/fluid/dto/rheology/rheology.dto';

export const calculateRheologyFluidLine = (rheology: RheologyDto, rheometerReadings: IRheometerChartPoint[]): IRheometerChartPoint[] => {
  return rheometerReadings.map((rheometerReading) => {
    const shearRate = rheometerReading.shearRate;

    return {
      shearRate,
      viscosity: RheologyCalculations.ViscosityAtShearRate(rheology, shearRate),
    };
  });
};

export const calculateRheologyFluidShuntTargetLine = (rheometerReadings: IRheometerChartPoint[]): IRheometerChartPoint[] => {
  return rheometerReadings.map((rheometerReading) => {
    const shearRate = rheometerReading.shearRate;
    return {
      shearRate,
      viscosity: RheologyCalculations.ViscosityAtShearRateForShuntTargetRheology(shearRate),
    };
  });
};
