<div class="home-container">
  <div class="home-nav" #homeNav>
    <div class="nav-header">
      <span>
        <span class="icon-logo icon-white"></span>
        {{ appConfig.appName }}
      </span>
    </div>
    <div
      *ngFor="let homeTab of homeTabs"
      [ngClass]="{ 'menu-item-accent': homeTab.uri === selectedTab }"
      class="home-menu-item"
      [attr.data-cy]="homeTab.label.toLowerCase().replace(' ', '-')"
      (click)="navigate(homeTab.uri)"
    >
      <div></div>
      <span>
        <span class="{{ homeTab.icon }} icon-light"></span>
        {{ homeTab.label }}
      </span>
    </div>

    <div class="nav-footer">
      <span> </span>
    </div>
  </div>

  <div class="home-content">
    <div class="top-bar">
      <app-home-header [repository]="repository" [folder]="folder" (uploadTriggered)="uploadFile()"></app-home-header>
    </div>
    <div class="home-page-content" *ngIf="isBackendConnected$ | async">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

<app-upload
  [uploadUrl]="getUploadUrl()"
  [fileListInFolder]="getFileListInFolder()"
  [accept]="getAcceptedFileExtension()"
  (uploadFinished)="uploadFinished($event)"
>
</app-upload>
