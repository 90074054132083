import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { ColumnType } from '../../../../../components/grid/grid.interfaces';
import { IDataRowWithCode, IReportTableColumnConfig } from '../common-report-data-helpers';
import { IWellGeneralData } from '../../doc/sections/well-report-generator.helper';

export const wellGeneralGridConfig: IReportTableColumnConfig<IDataRowWithCode<IWellGeneralData>>[] = [
  {
    colId: 'TreatmentType',
    headerText: 'Treatment Type:',
    type: ColumnType.select,
    unitSystem: UnitSystem.None,
  },
  {
    colId: 'LocationType',
    headerText: 'Location Type:',
    type: ColumnType.select,
    unitSystem: UnitSystem.None,
  },
  {
    colId: 'SeaLevelDepth',
    headerText: 'Sea Level Depth:',
    type: ColumnType.number,
    unitSystem: UnitSystem.Long_Length,
    decimalPlaces: 2,
  },
  {
    colId: 'SeaBedDepth',
    headerText: 'Sea Bed Depth:',
    type: ColumnType.number,
    unitSystem: UnitSystem.Long_Length,
    decimalPlaces: 2,
  },
  {
    colId: 'BottomholeMD',
    headerText: 'Bottomhole MD:',
    type: ColumnType.number,
    unitSystem: UnitSystem.Long_Length,
    decimalPlaces: 2,
  },
  {
    colId: 'BottomholeTVD',
    headerText: 'Bottomhole TVD:',
    type: ColumnType.number,
    unitSystem: UnitSystem.Long_Length,
    decimalPlaces: 2,
  },
  {
    colId: 'MaxDeviation',
    headerText: 'Max Deviation:',
    type: ColumnType.number,
    unitSystem: UnitSystem.Angle,
    decimalPlaces: 2,
  },
  {
    colId: 'TemperatureProfile',
    headerText: 'Temperature Profile:',
    type: ColumnType.select,
    unitSystem: UnitSystem.None,
  },
  {
    colId: 'IsLossRateCalculated',
    headerText: 'Loss Definition:',
    type: ColumnType.select,
    unitSystem: UnitSystem.None,
  },
];
