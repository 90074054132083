<div class="recent" *ngIf="recentFilesState$ | async as recentFilesState">
  <app-file-list [isRecentMode]="true"></app-file-list>
  <div class="recent-folders">
    <div class="subhead-bar">Recently viewed project folders</div>
    <div class="recent-folder-contents" data-cy="recent-folder-contents">
      <div *ngFor="let recentFolderDto of recentFilesState.recent.recentFolders" class="recent-folders-item">
        <div class="recent-folders-item-block" (click)="recentFolderClick(recentFolderDto)">
          <span data-cy="block-path">
            /&#8203;My Projects/&#8203;{{ recentFolderDto.Folder.join('/&#8203;') }}{{ recentFolderDto.Folder.length > 0 ? '/&#8203;' : ''
            }}{{ recentFolderDto.Name }}
          </span>
        </div>
        <div class="recent-folders-item-date">
          {{ recentFolderDto.LastOpenTimestamp | date: 'medium' }}
        </div>
      </div>
    </div>
  </div>
</div>
