import { DataFileType } from '../../../dto/data-storage';
import { RangeConstants, RangeDto } from '../../../dto/range.dto';
import { ArgumentType, ImportColumnDto } from './import-column.dto';

export interface BaseImportFile {
  Id: number;
  FileType: DataFileType;
  PumpTime: number;
  PumpVolume: number;
}

export interface ImportFileDto extends BaseImportFile {
  ScenarioId: number;
  RangeId: number;
  FileName: string;
  XAxisShift: number;
  SortOrder: number;
  // TypeOrm reads it as Date, in ImportFileRepositoryService.loadEntities it's converted to JS milliseconds timestamp string
  UpdateDate: string;
  EquationHash: string | null;
}

export interface ImportFileWithMinMaxArgumentsDto extends ImportFileDto {
  MinArg: number;
  MaxArg: number;
}

export interface ImportFileWithRangeHashDto extends ImportFileWithMinMaxArgumentsDto {
  RangeHash: string;
}

export class ImportFileFactory {
  public static create(FileName: string, FileType: DataFileType, id = -1): ImportFileDto {
    return {
      Id: id,
      ScenarioId: 0,
      FileName,
      FileType,
      XAxisShift: 0,
      PumpVolume: 0,
      PumpTime: 0,
      SortOrder: -1,
      UpdateDate: new Date().toISOString(),
      EquationHash: null,
      RangeId: RangeConstants.EntireRangeId,
    };
  }

  public static isFileInsideRange(file: ImportFileWithMinMaxArgumentsDto, range: RangeDto, fileColumns: ImportColumnDto[]): boolean {
    const xAxisColumn = fileColumns.find((column) => column.FileId === file.Id && column.IsXAxis === ArgumentType.Primary_Argument);
    const startDate = xAxisColumn?.StartDate;

    if (startDate == null) {
      throw new Error(`Missing startDate: (fileId: ${file.Id})!`);
    }

    return file.MinArg < range.RangeEnd && file.MaxArg > range.RangeStart;
  }
}
