import { BaseHtmlParser, ParagraphType } from '../common/base-html-parser';
import PptxGenJS from 'pptxgenjs';
import TextProps = PptxGenJS.TextProps;

export class PptHtmlParser extends BaseHtmlParser {
  public override render(): TextProps[] {
    const texts: TextProps[] = [];
    for (const p of this.paragraphs) {
      for (const text of p.texts) {
        texts.push({
          text: (p.type === ParagraphType.bullet ? '  ● ' : '') + text.text,
          options: {
            bold: text.isBold,
            italic: text.isItalic,
            underline: text.isUnderline ? { style: 'heavy' } : undefined
          }
        });
      }
      texts.push({ text: '\n' });
    }
    return texts;
  }
}
