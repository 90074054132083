import { DictionaryWithArray, IDictionaryWithArray, noErrors } from '../../../../common/state.helpers';
import { FlowPathType } from '../../resuspension-calculator/resuspension-calculator.dto';
import { ConvertUnitPipe } from '../../../units/convert-unit.pipe/convert-unit.pipe';
import { MAX_GRAVEL_CONCENTRATION_SI, MAX_RATE_LIMIT_SI } from '../../../../common/constants';
import { IError } from '../../../../common/common-state.interfaces';
import { FrictionCalculatorDto } from '../friction-calculator.dto';
import {
  FrictionCalculatorModuleState,
  ValidatedFrictionCalculator,
  ValidatedFrictionCalculatorModuleState,
} from '../friction-calculator-module.state';
import { UnitSystem } from '../../../../dto/unit-system.dto';
import { Validation } from '../../../../common/validation';
import { Fluid } from '../../../fluid/model/fluid';
import { FluidType } from '../../../fluid/dto/fluid.dto';

export class FrictionCalculatorValidation {
  public static validate(
    state: FrictionCalculatorModuleState,
    fluids: IDictionaryWithArray<Fluid>,
  ): ValidatedFrictionCalculatorModuleState {
    const selectedFluid = DictionaryWithArray.get(fluids, state.FrictionCalculator.FluidId);
    const validatedFrictionCalculator = this.ValidateFrictionCalculator(state.FrictionCalculator, selectedFluid);
    return {
      ...state,
      FrictionCalculator: validatedFrictionCalculator,
      error: {},
      isValid: validatedFrictionCalculator.isValid,
    };
  }

  public static ValidateFrictionCalculator(
    frictionCalculator: FrictionCalculatorDto,
    fluid: Fluid | undefined,
  ): ValidatedFrictionCalculator {
    const error: IError<FrictionCalculatorDto> = {
      Length: this.ValidateLength(frictionCalculator.Length),
      MinDiameter: this.ValidateMinDiameter(frictionCalculator),
      MaxDiameter: this.ValidateMaxDiameter(frictionCalculator),
      Width: this.ValidateWidth(frictionCalculator),
      Height: this.ValidateHeight(frictionCalculator),
      FluidId: Validation.ValidateFluid(frictionCalculator.FluidId),
      GravelId: Validation.ValidateGravel(frictionCalculator.GravelId),
      Concentration: this.ValidateConcentration(frictionCalculator),
      MaxRate: this.ValidateMaxRate(frictionCalculator),
      RateStep: this.ValidateRateStep(frictionCalculator),
      Roughness: this.ValidateRoughness(frictionCalculator),
      Eccentricity: this.ValidateEccentricity(frictionCalculator),
      UseDegradationFactor: this.ValidateUseDegradationFactor(frictionCalculator, fluid),
    };
    return { ...frictionCalculator, error, isValid: noErrors(error) };
  }

  private static ValidateLength(length: number): string {
    if (length <= 0) {
      return 'Length must be greater than 0';
    }
    return '';
  }

  private static ValidateMinDiameter(frictionCalculator: FrictionCalculatorDto): string {
    if (frictionCalculator.MinDiameter <= 0 && frictionCalculator.FlowPathType !== FlowPathType.Rectangular_Shunt) {
      return 'ID must be greater than 0';
    }
    if (frictionCalculator.FlowPathType === FlowPathType.Annulus && frictionCalculator.MinDiameter >= frictionCalculator.MaxDiameter) {
      return 'ID must be less than OD';
    }
    return '';
  }

  private static ValidateMaxDiameter(frictionCalculator: FrictionCalculatorDto): string {
    if (frictionCalculator.FlowPathType === FlowPathType.Annulus && frictionCalculator.MaxDiameter <= 0) {
      return 'OD must be greater than 0';
    }
    if (frictionCalculator.FlowPathType === FlowPathType.Annulus && frictionCalculator.MinDiameter >= frictionCalculator.MaxDiameter) {
      return 'OD must be greater than ID';
    }
    return '';
  }

  private static ValidateHeight(frictionCalculator: FrictionCalculatorDto): string {
    if (frictionCalculator.FlowPathType === FlowPathType.Rectangular_Shunt && frictionCalculator.Height <= 0) {
      return 'Height must be greater than 0';
    }
    return '';
  }

  private static ValidateWidth(frictionCalculator: FrictionCalculatorDto): string {
    if (frictionCalculator.FlowPathType === FlowPathType.Rectangular_Shunt && frictionCalculator.Width <= 0) {
      return 'Width must be greater than 0';
    }
    return '';
  }

  private static ValidateConcentration(frictionCalculator: FrictionCalculatorDto): string {
    if ((frictionCalculator.GravelId ?? 0) > 0 && frictionCalculator.Concentration <= 0) {
      return 'Concentration must be greater than 0';
    }

    if ((frictionCalculator.GravelId ?? 0) > 0 && frictionCalculator.Concentration > MAX_GRAVEL_CONCENTRATION_SI) {
      const encodeValue = ConvertUnitPipe.encode(UnitSystem.Solid_Concentration, MAX_GRAVEL_CONCENTRATION_SI);
      return `Concentration must be less than or equal to ${encodeValue}`;
    }
    return '';
  }

  private static ValidateMaxRate(frictionCalculator: FrictionCalculatorDto): string {
    if (frictionCalculator.MaxRate <= 0) {
      return 'Max rate must be greater than 0';
    }

    if (frictionCalculator.MaxRate > MAX_RATE_LIMIT_SI) {
      const encodeValue = ConvertUnitPipe.encode(UnitSystem.Rate, MAX_RATE_LIMIT_SI);
      return `Max rate must be less than or equal to ${encodeValue}`;
    }
    return '';
  }

  private static ValidateRateStep(frictionCalculator: FrictionCalculatorDto): string {
    if (frictionCalculator.RateStep <= 0) {
      return 'Rate step must be greater than 0';
    }
    if (frictionCalculator.RateStep >= frictionCalculator.MaxRate) {
      return 'Rate step must be less than max rate';
    }
    return '';
  }

  private static ValidateRoughness(frictionCalculator: FrictionCalculatorDto): string {
    if (
      frictionCalculator.Roughness < 0 &&
      (frictionCalculator.FlowPathType === FlowPathType.Pipe || frictionCalculator.FlowPathType === FlowPathType.Annulus)
    ) {
      return 'Roughness must be greater than or equal to 0';
    }
    return '';
  }

  private static ValidateEccentricity(frictionCalculator: FrictionCalculatorDto): string {
    if (frictionCalculator.FlowPathType === FlowPathType.Annulus && frictionCalculator.Eccentricity < 0) {
      return 'Eccentricity must be greater than or equal to 0';
    }
    if (frictionCalculator.FlowPathType === FlowPathType.Annulus && frictionCalculator.Eccentricity > 1) {
      return 'Eccentricity must be less than or equal to 1';
    }
    return '';
  }

  private static ValidateUseDegradationFactor(frictionCalculator: FrictionCalculatorDto, fluid: Fluid | undefined): string {
    if (frictionCalculator.UseDegradationFactor && fluid && fluid.Type === FluidType.Newtonian) {
      return 'Fluid Degradation can’t be used with Newtonian fluids';
    }
    if (
      frictionCalculator.UseDegradationFactor &&
      (frictionCalculator.FlowPathType === FlowPathType.Round_Shunt || frictionCalculator.FlowPathType === FlowPathType.Rectangular_Shunt)
    ) {
      return 'Fluid Degradation can only be used with pipe and annulus flow paths';
    }
    return '';
  }
}
