import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { ColumnType } from '../../../../../components/grid/grid.interfaces';
import { ZoneModel } from '@dunefront/common/modules/well/model/zone/zone.model';
import { IReportTableColumnConfig } from '../common-report-data-helpers';

export const specifyLossesZoneProperties: IReportTableColumnConfig<ZoneModel>[] = [
  { colId: 'Code', headerText: 'Code', unitSystem: UnitSystem.None, type: ColumnType.number },
  { colId: 'Name', headerText: 'Name', unitSystem: UnitSystem.None, type: ColumnType.text },
  {
    colId: 'LossZoneType',
    headerText: 'Loss Zone Type',
    unitSystem: UnitSystem.None,
    type: ColumnType.select,
  },
  {
    colId: 'LossPercentage',
    headerText: 'Loss Percentage',
    unitSystem: UnitSystem.None,
    type: ColumnType.number,
    decimalPlaces: 2,
    overrideUnitSymbol: '%',
  },
];

export const calculateLossesZoneProperties: IReportTableColumnConfig<ZoneModel>[] = [
  { colId: 'Code', headerText: 'Code', unitSystem: UnitSystem.None, type: ColumnType.number },
  { colId: 'Name', headerText: 'Name', unitSystem: UnitSystem.None, type: ColumnType.text },
  {
    colId: 'PermeabilityToReservoirFluid',
    headerText: 'Perm. to Reservoir Fluid',
    reportingHeaderName: 'Res. Fluid Perm.',
    unitSystem: UnitSystem.Permeability,
    type: ColumnType.number,
  },
  {
    colId: 'PermeabilityToInjectedFluid',
    headerText: 'Perm. to Injected Fluid',
    reportingHeaderName: 'Inj. Fluid Perm.',
    unitSystem: UnitSystem.Permeability,
    type: ColumnType.number,
  },
  {
    colId: 'ReservoirFluidViscosity',
    headerText: 'Reservoir Fluid Viscosity',
    reportingHeaderName: 'Res. Fluid Visc.',
    unitSystem: UnitSystem.Viscosity,
    type: ColumnType.number,
    decimalPlaces: 3,
  },
  {
    colId: 'FluidLossCoefficient',
    headerText: 'Fluid loss Coefficient',
    reportingHeaderName: 'Fluid Loss Coeff.',
    unitSystem: UnitSystem.Fluid_Loss_Coefficient,
    type: ColumnType.number,
    decimalPlaces: 4,
  },
  {
    colId: 'Porosity',
    headerText: 'Porosity',
    unitSystem: UnitSystem.None,
    type: ColumnType.number,
    overrideUnitSymbol: '%',
  },
  {
    colId: 'Skin',
    headerText: 'Skin',
    unitSystem: UnitSystem.None,
    type: ColumnType.number,
  },
  {
    colId: 'YoungsModulus',
    headerText: "Young's Modulus",
    unitSystem: UnitSystem.Pressure,
    type: ColumnType.number,
  },
  {
    colId: 'PoissonsRatio',
    headerText: "Poisson's Ratio",
    unitSystem: UnitSystem.None,
    type: ColumnType.number,
  },
];
