import {
  Chart,
  ChartConfiguration,
  ChartOptions,
  CoreScaleOptions,
  LegendOptions,
  Scale,
  ScaleOptionsByType,
  ScaleType,
  TooltipItem,
} from 'chart.js';
import { DuneFrontAnnotationId } from '../annotations-plugin/dunefront-annotations';
import { DuneFrontAnnotationOptions } from '../annotations-plugin/types';
import { DeepPartial } from 'chart.js/dist/types/utils';
import { ChartContext, IAxisData, IAxisProps, IZoomPluginListener } from './chart-types';
import {
  getArgumentAxisUnit,
  IChartDataDto,
  IChartDataDtoColumn,
  LineChartDataSet,
} from '@dunefront/common/modules/reporting/dto/chart-data.dto';
import { IChartUnitDetails, UnitConverterHelper } from '@dunefront/common/unit-converters/unit.converter.helper';
import { ChartAxis, XAxisFormat } from '@dunefront/common/modules/reporting/dto/chart-axis-property.dto';
import { IUnitSystemDto, TimeUnit } from '@dunefront/common/dto/unit-system.dto';
import dayjs, { ManipulateType, OpUnitType } from 'dayjs';
import {
  axisTypeFromColumn,
  getAxisId,
  getAxisProps,
  getAxisStyle,
  getAxisTicksFont,
  getAxisTitleFont,
  getColumnKey,
  getIsTimeAxis,
  getPositionFromPPAxis,
} from './chart-misc-helpers';
import { CrosshairPluginId, defaultCrosshairLineColor } from '../crosshair-plugin/constants';
import { CrosshairDataset, CrosshairPluginOptions } from '../crosshair-plugin/types';
import {
  COMMON_DATE_TIME_SECOND_MILLISECOND_STRING_FORMAT,
  COMMON_DATE_TIME_SECOND_STRING_FORMAT,
} from '@dunefront/common/common/constants';
import { ChartDataSeriesStyleHelpers } from './chart-data-series-style-helpers';
import { getAxisUnitsSummary } from './chart-axis-units-summary-helpers';
import { ChartDataAxisLimitsHelpers } from './chart-data-axis-limits-helpers';
import { ChartDataPointsHelpers } from './chart-data-points-helpers';
import { ConvertUnitPipe } from '@dunefront/common/modules/units/convert-unit.pipe/convert-unit.pipe';
import { MathHelpers } from '@dunefront/common/common/math-helpers';
import { ZoomPluginOptions } from 'chartjs-plugin-zoom/types/options';
import { GradientLinePluginId } from '../gradient-line-plugin/gradient-line-plugin';
import { GradientLinePluginOptions } from '../gradient-line-plugin/types';
import { IAxisUnit } from '@dunefront/common/unit-converters/converter.interfaces';
import { IScenarioDict } from '@dunefront/common/modules/scenario/scenario';

const tooltipCaretPadding = 25;
const tooltipRegularItemColor = '#BBB'; // ordinary item (light gray)
const tooltipClosestItemColor = '#FFF'; // closest  item (white)

export interface MinMax {
  min: number;
  max: number;
}

export class ChartDataHelpers {
  constructor(public styleHelpers: ChartDataSeriesStyleHelpers) {}

  public static getZoomPluginOptions(
    zoomPluginListener: IZoomPluginListener,
    allowInteractions: boolean,
  ): Partial<ZoomPluginOptions> | undefined {
    if (!allowInteractions) {
      return undefined;
    }

    return {
      pan: {
        enabled: true,
        mode: 'xy',
        modifierKey: 'ctrl',
        onPanStart: (context): boolean => zoomPluginListener.onPanStart(context.event),
        onPanComplete: (): void => zoomPluginListener.onPanComplete(),
        onPanRejected: (): void => zoomPluginListener.onPanComplete(),
      },
      zoom: {
        mode: 'xy',
        wheel: {
          enabled: true,
        },
        drag: {
          backgroundColor: 'rgba(225,225,225,0.5)',
          modifierKey: 'shift',
          enabled: true,
        },
        pinch: {
          enabled: true,
        },
        onZoomStart: (context): void => zoomPluginListener.onZoomStart(context.event),
        onZoomComplete: (): void => zoomPluginListener.onZoomComplete(),
        onZoomRejected: (): void => zoomPluginListener.onZoomComplete(),
      },
    };
  }

  public buildChartConfiguration(
    chartContext: ChartContext,
    allowInteractions: boolean,
    annotationsOptions: DuneFrontAnnotationOptions,
    gradientLineOptions: GradientLinePluginOptions,
    zoomPluginListener: IZoomPluginListener,
    legendOptions: DeepPartial<LegendOptions<'line'>>,
    tooltipArgumentConverter: (arg: number) => string,
  ): ChartConfiguration {
    const config: ChartConfiguration = {
      type: 'line',
      data: { labels: ['label'], datasets: [] },
    };

    const rotate = chartContext.isRotated;
    const options: ChartOptions = {
      animation: false,
      animations: {
        colors: false,
        x: false,
      },
      transitions: {
        active: {
          animation: {
            duration: 0,
          },
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: legendOptions,
        tooltip: {
          yAlign: rotate ? 'bottom' : undefined,
          animation: false as any,
          mode: 'interpolate' as any,
          intersect: false,
          caretPadding: tooltipCaretPadding,
          filter: (item, data, idx) => {
            // limit datasets displayed in tooltip to show only amount set in settings
            return chartContext.sortedAndTrimmedDataSets.map((ds) => ds.dataSetIndex).includes(item.datasetIndex);
          },
          callbacks: {
            title: (tooltipItems: TooltipItem<any>[]): string => {
              const item = tooltipItems[0];
              if (item == null) {
                return '';
              }

              const argument = rotate ? item.element.y : item.element.x;

              return tooltipArgumentConverter(argument);
            },
            label: (tooltipItem: TooltipItem<any>) => {
              const value = rotate ? tooltipItem.element.x : tooltipItem.element.y;

              const roundedValue = MathHelpers.round(value, 2);
              let label = roundedValue.toFixed(2);

              const datasetName = tooltipItem.dataset?.label;
              if (datasetName) {
                label += ' - ' + datasetName;
              }

              return label;
            },

            labelTextColor: (tooltipItem: TooltipItem<any>) =>
              chartContext.closestDataSetIndex === tooltipItem.datasetIndex ? tooltipClosestItemColor : tooltipRegularItemColor,
          },
        },
      },
    };

    if (options.plugins) {
      // zooming
      options.plugins.zoom = ChartDataHelpers.getZoomPluginOptions(zoomPluginListener, allowInteractions);

      // markers
      options.plugins.annotation = allowInteractions ? { annotations: [] } : undefined;

      // annotations
      (options.plugins as any)[DuneFrontAnnotationId] = allowInteractions ? annotationsOptions : undefined;

      // gradient line
      (options.plugins as any)[GradientLinePluginId] = allowInteractions ? gradientLineOptions : undefined;
    }

    // add Crosshair options
    const crosshairOptions: CrosshairPluginOptions = {
      enabled: true,
      isChartRotated: rotate,
      lineStyle: {
        color: defaultCrosshairLineColor,
        width: 1,
        dashPattern: [],
      },
      crosshairContext: chartContext,
    };
    if (options.plugins) {
      (options.plugins as any)[CrosshairPluginId] = crosshairOptions;
    }

    config.options = options;

    return config;
  }

  public initializeChartDatasetsScales(
    chartContext: ChartContext,
    chartDataDto: IChartDataDto,
    chart: Chart | undefined,
    afterViewInit: boolean,
    sizeMultiplier: number,
    argumentStart: number | undefined,
    argumentEnd: number | undefined,
    notifyOnScaleUpdate = true,
  ): IAxisData[] | undefined {
    if (!chart || chart.config == null || !chart.config.options || !chart.config.options.scales) {
      return undefined;
    }

    const {
      isRotated,
      reverseArgument,
      currentUnitSystem,
      defaultAxisStyle,
      axesProperties,
      convertUnitPipe,
      chartZoomedDataService,
      defaultSeriesStyles,
      chartSeriesTemplates,
      tension,
      scenariosToCompare,
    } = chartContext;

    // clear previous scales
    chart.config.options.scales = {};

    const chartDataColumns = chartDataDto.ChartDataColumns;
    const argumentAxisUnit = getArgumentAxisUnit(chartDataDto);
    const argumentUnitDetails = UnitConverterHelper.getUnitTypeAndName(
      argumentAxisUnit.dataType,
      argumentAxisUnit.unitSystem,
      currentUnitSystem,
    );
    const reverse = reverseArgument;
    const argumentDecimalPlaces = argumentUnitDetails.DecimalPlaces ?? 0;
    const argAxisProps = getAxisProps(ChartAxis.Argument, axesProperties);
    const argAxisStyle = argAxisProps?.style ?? defaultAxisStyle;
    const argumentAxisData: IAxisData = {
      axis: ChartAxis.Argument,
      axisUnits: [argumentAxisUnit],
      title: argumentUnitDetails.AxisName,
    };

    const type: ScaleType = argAxisProps?.isLogarithmic ?? argumentUnitDetails?.Logarithmic ? 'logarithmic' : 'linear';

    // argument axis
    const argumentAxisId = getAxisId(ChartAxis.Argument, isRotated);

    chart.config.options.scales[argumentAxisId] = {
      display: true,
      type,
      afterBuildTicks: (scale: Scale): void => this.updateTicks(scale),
      afterUpdate: (scale: Scale): void => {
        if (notifyOnScaleUpdate) {
          chartZoomedDataService.updateScales(scale, chartDataDto, argumentStart, argumentEnd, currentUnitSystem);
        }
      },
      title: {
        display: true,
        text: this.getArgumentAxisText(argAxisProps, argumentUnitDetails, argumentAxisUnit),
        font: getAxisTitleFont(argAxisStyle),
        color: argAxisStyle.AxisTitleFontColor,
      },
      grid: { lineWidth: sizeMultiplier },
      min: Number.MAX_VALUE,
      max: -Number.MAX_VALUE,
      reverse,
      ticks: {
        font: getAxisTicksFont(argAxisStyle),
        color: argAxisStyle.AxisLabelFontColor,
        autoSkip: true,
        autoSkipPadding: isRotated ? 20 : 40,
        maxRotation: 0,
        callback: (value: string | number): string | number =>
          this.formatScaleTick(
            value,
            type === 'logarithmic',
            argumentDecimalPlaces,
            COMMON_DATE_TIME_SECOND_STRING_FORMAT,
            getIsTimeAxis(argumentAxisUnit) && argAxisProps?.xAxisFormat === XAxisFormat.timestamp ? chartDataDto.StartDate : null,
            chartContext.currentUnitSystem.Time,
          ),
      },
    };

    const chartValueAxis: IAxisData[] = [
      { axis: ChartAxis.PrimaryValue, axisUnits: [] },
      { axis: ChartAxis.SecondaryValue, axisUnits: [] },
      { axis: ChartAxis.OppositePrimaryValue, axisUnits: [] },
      { axis: ChartAxis.OppositeSecondaryValue, axisUnits: [] },
    ];

    chart.data.datasets = [];

    chartDataColumns.forEach((column, index) => {
      const label = this.getSeriesLabel(column, chartDataColumns, scenariosToCompare, convertUnitPipe, currentUnitSystem);
      const seriesStyle = this.styleHelpers.getSeriesStyle(index, chartDataDto, defaultSeriesStyles, chartSeriesTemplates);
      const axisType = axisTypeFromColumn(column);
      const valueAxisID = getAxisId(axisType, isRotated);

      const dataset: LineChartDataSet & CrosshairDataset = {
        tension,
        [isRotated ? 'yAxisID' : 'xAxisID']: argumentAxisId,
        [isRotated ? 'xAxisID' : 'yAxisID']: valueAxisID,
        label,
        stepped: UnitConverterHelper.getUnitTypeAndName(column.DataType, column.UnitSystem, currentUnitSystem).IsStepSeries
          ? reverseArgument
            ? 'after'
            : 'before'
          : false,
        data: [],
        interpolate: true,
      };

      this.styleHelpers.setSeriesColour(dataset, seriesStyle.SeriesColour);
      this.styleHelpers.setPointStyles(dataset, seriesStyle, sizeMultiplier);
      this.styleHelpers.setLineStyle(
        dataset,
        seriesStyle,
        sizeMultiplier,
        chartDataColumns,
        index,
        scenariosToCompare.scenarioIds,
        seriesStyle,
      );

      if (chart?.data?.datasets == null) {
        console.warn('missing datasets');
        return;
      }

      chart.data.datasets.push(dataset);

      if (!chartValueAxis[axisType].axisUnits.some((unit) => unit.dataType === column.DataType && unit.unitSystem === column.UnitSystem)) {
        chartValueAxis[axisType].axisUnits.push({ dataType: column.DataType, unitSystem: column.UnitSystem });
      }
    });

    // set value axis units
    chartValueAxis.forEach((valueAxis) => {
      if (valueAxis.axisUnits.length > 0) {
        const { unitTypeNames, unitTypeUnits, hasAnyLogarithmicUnit, decimalPlaces } = getAxisUnitsSummary(valueAxis, currentUnitSystem);

        const axisProps = getAxisProps(valueAxis.axis, axesProperties);
        const type: ScaleType = axisProps?.isLogarithmic ?? hasAnyLogarithmicUnit ? 'logarithmic' : 'linear';

        valueAxis.title = unitTypeNames.toString();

        const axisId = getAxisId(valueAxis.axis, isRotated);
        const position = getPositionFromPPAxis(valueAxis.axis, isRotated);
        const axisStyle = getAxisStyle(valueAxis.axis, axesProperties, defaultAxisStyle);

        const chartValueAxe: DeepPartial<ScaleOptionsByType<'linear' | 'logarithmic'>> = {
          type,
          position,
          title: {
            display: true,
            text: this.getValueAxisText(axisProps, unitTypeNames, unitTypeUnits),
            font: getAxisTitleFont(axisStyle),
            color: axisStyle.AxisTitleFontColor,
          },
          afterBuildTicks: (scale: Scale<CoreScaleOptions>) => this.updateTicks(scale),
          grid: {
            lineWidth: sizeMultiplier,
            drawTicks: true,
            display: true,
            drawOnChartArea: valueAxis.axis === ChartAxis.PrimaryValue,
          },
          min: Number.MAX_VALUE,
          max: -Number.MAX_VALUE,
          ticks: {
            font: getAxisTicksFont(axisStyle),
            color: axisStyle.AxisLabelFontColor,
            autoSkip: true,
            autoSkipPadding: isRotated ? 40 : 20,
            maxRotation: 0,
            callback: (value: string | number) =>
              this.formatScaleTick(value, type === 'logarithmic', decimalPlaces, COMMON_DATE_TIME_SECOND_STRING_FORMAT, null),
          },
        };

        const scales = chart?.config?.options?.scales;
        if (scales) {
          scales[axisId] = chartValueAxe;
        }
      }
    });

    chartValueAxis.push(argumentAxisData);

    return chartValueAxis;
  }

  public columnLabelSuffix(column: IChartDataDtoColumn, allColumns: IChartDataDtoColumn[], scenariosToCompare: IScenarioDict): string {
    // compare scenarios
    if (scenariosToCompare.scenarioIds.length && column.ScenarioId != null) {
      const scenarioToCompare = scenariosToCompare[column.ScenarioId];
      const scenarioName = scenarioToCompare == null ? scenariosToCompare.currentScenarioName : scenarioToCompare.Name;
      return ` (${scenarioName})`;
    }

    // is in optimize
    if (allColumns.some((col) => col.AddSimulateToEvaluate === true)) {
      return column.AddSimulateToEvaluate ? ' (Simulate)' : '';
    }

    return '';
  }

  public getSeriesLabel(
    column: IChartDataDtoColumn,
    allColumns: IChartDataDtoColumn[],
    scenariosToCompare: IScenarioDict,
    convertUnitPipe: ConvertUnitPipe,
    currentUnitSystem: IUnitSystemDto,
  ): string {
    const labelSuffix = this.columnLabelSuffix(column, allColumns, scenariosToCompare);

    return `${convertUnitPipe.transform(column.Name, currentUnitSystem)}${labelSuffix}`;
  }

  public writeChartValues(chartContext: ChartContext, chartData: IChartDataDto, chart: Chart, updateScaleLimits: boolean): void {
    const chartConfig = chart.config as ChartConfiguration;
    if (chartConfig.data == null || chartConfig.data.datasets == null) {
      return;
    }

    const { currentUnitSystem, isRotated, axesProperties, defaultAxisMargin } = chartContext;

    const columnsSmoothedPoints = new Map<string, [number, number][]>();

    for (let sourceIndex = 0; sourceIndex < chartData.ChartDataColumns.length; sourceIndex++) {
      const column = chartData.ChartDataColumns[sourceIndex];
      const targetDataset = chartConfig.data.datasets[sourceIndex] as LineChartDataSet;

      const smoothedPoints = ChartDataPointsHelpers.calcSmoothedPoints(column, chartContext, chartData);

      columnsSmoothedPoints.set(getColumnKey(column), smoothedPoints);

      // set points to chart dataset
      targetDataset.data = smoothedPoints.map(([arg, value]) =>
        !isRotated
          ? { x: arg, y: value }
          : {
              x: value,
              y: arg,
            },
      );
    }

    if (updateScaleLimits) {
      // value axes
      const valueAxes: ChartAxis[] = [...new Set(chartData.ChartDataColumns.map((col) => axisTypeFromColumn(col)))];
      const getSmoothedPoints = (column: IChartDataDtoColumn): [number, number][] => columnsSmoothedPoints.get(getColumnKey(column)) ?? [];

      for (const valueAxis of valueAxes) {
        const valueLimits = ChartDataAxisLimitsHelpers.getValueAxisLimits(
          valueAxis,
          chartContext,
          chartData,
          getSmoothedPoints,
          defaultAxisMargin,
        );
        const axisId = getAxisId(valueAxis, isRotated);
        ChartDataAxisLimitsHelpers.applyScaleLimits(axisId, valueLimits, chartConfig);
      }

      // argument axis
      const argumentAxisId = getAxisId(ChartAxis.Argument, isRotated);
      const argumentAxisUnit = getArgumentAxisUnit(chartData);

      const argLimits = ChartDataAxisLimitsHelpers.getArgumentScaleLimits(
        chartData,
        currentUnitSystem,
        argumentAxisUnit,
        axesProperties,
        isRotated,
        defaultAxisMargin,
      );
      ChartDataAxisLimitsHelpers.applyScaleLimits(argumentAxisId, argLimits, chartConfig);
    }
  }

  public formatScaleTick(
    value: number | string,
    isLogarithmic: boolean,
    decimalPlaces: number,
    timestampFormat: string,
    startDate: number | undefined | null,
    currentTimeUnit?: TimeUnit,
  ): number | string {
    if (startDate != undefined && currentTimeUnit != undefined) {
      const isTimeUnit = (value: ManipulateType | string): value is OpUnitType => {
        return ['hour', 'minute', 'second'].includes(value as OpUnitType);
      };

      const date = dayjs(startDate * 1000);
      const unitName = TimeUnit[currentTimeUnit].toLowerCase() as ManipulateType;

      if (isTimeUnit(unitName)) {
        return date.add(+value, unitName).format(timestampFormat);
      }
    }

    return +parseFloat(value + '').toPrecision(12);
  }

  public updateTicks(scale: Scale): void {
    if (scale.ticks.length <= 2) {
      return;
    } else if (scale.type === 'logarithmic' || scale.ticks.length < 4) {
      scale.ticks = scale.ticks.length > 1 ? scale.ticks.slice(1, -1) : scale.ticks;
    } else {
      const ticks = [...scale.ticks];
      const step = ticks[2].value - ticks[1].value;

      // fix first tick
      ticks[0].value = ticks[1].value - step;
      if (ticks[0].value < scale.min || ticks[0].value > scale.max) {
        ticks.splice(0, 1);
      }

      // fix last tick
      ticks[ticks.length - 1].value = ticks[ticks.length - 2].value + step;
      if (ticks[ticks.length - 1].value < scale.min || ticks[ticks.length - 1].value > scale.max) {
        ticks.pop();
      }

      scale.ticks = [...ticks];
    }
  }

  public chartDataWithUpdatedXAxisShift(chartData: IChartDataDto, datasetIndex: number, xAxisShift: number): IChartDataDto {
    const ChartDataSets = [...chartData.ChartDataSets];
    ChartDataSets[datasetIndex] = { ...ChartDataSets[datasetIndex], XAxisShift: xAxisShift };
    return { ...chartData, ChartDataSets };
  }

  public getArgumentAxisText(
    argAxisProps: IAxisProps | undefined,
    argumentUnitDetails: IChartUnitDetails,
    argumentAxisUnit: IAxisUnit,
  ): string {
    let axisText = argAxisProps?.title ?? argumentUnitDetails.AxisName;
    if (!(getIsTimeAxis(argumentAxisUnit) && argAxisProps?.xAxisFormat === XAxisFormat.timestamp)) {
      axisText += ` (${argumentUnitDetails.AxisSymbol})`;
    }

    return axisText;
  }

  public getValueAxisText(axisProps: IAxisProps | undefined, unitTypeNames: string[], unitTypeUnits: string[]): string {
    const titleUnit = unitTypeUnits.toString();
    return (axisProps?.title ?? unitTypeNames.toString()) + (titleUnit ? ' (' + titleUnit + ')' : '');
  }

  public formatArgument(arg: number, chartContext: ChartContext, chartData: IChartDataDto): string {
    // this is used for copy chart data
    const { axesProperties, currentUnitSystem } = chartContext;
    const argumentAxisProps = axesProperties.find((props) => props.axis === ChartAxis.Argument) || null;
    const startTime = chartData.StartDate;
    const argumentAxisUnit = getArgumentAxisUnit(chartData);

    if (
      startTime != null &&
      startTime !== 0 &&
      chartData.IsPrimaryArgument &&
      getIsTimeAxis(argumentAxisUnit) &&
      argumentAxisProps?.xAxisFormat === XAxisFormat.timestamp
    ) {
      return this.formatScaleTick(
        arg,
        argumentAxisProps.isLogarithmic,
        2,
        COMMON_DATE_TIME_SECOND_MILLISECOND_STRING_FORMAT,
        startTime,
        currentUnitSystem.Time,
      ).toString();
    }
    return arg.toFixed(2);
  }
}
