<div class="grid-form-container" *ngIf="state">
  <div class="grid-two-one">
    <app-radio-group
      id="treatment-type"
      [items]="treatmentTypeRadioItems"
      dataCy="well-treatment-type"
      layout="horizontal"
      [source]="state"
      [key]="'TreatmentType'"
      (valueChanged)="wellPropertyChanged.emit($event)"
    >
    </app-radio-group>
  </div>
  <div class="grid-two-one">
    <app-radio-group
      id="location-type"
      [items]="locationTypeRadioItems"
      dataCy="well-location-type"
      layout="horizontal"
      [source]="state"
      [key]="'LocationType'"
      (valueChanged)="wellPropertyChanged.emit($event)"
    >
    </app-radio-group>
  </div>

  <div *ngIf="state.TemperatureProfile" class="grid-form-container no-padding">
    <app-form-input
      label="Sea Level Depth"
      appUnwrap
      [source]="state.TemperatureProfile"
      [disabled]="isLand()"
      [key]="'SeaLevelDepth'"
      dataCy="well-sea-level-depth"
      [decimalPlaces]="2"
      [unitType]="UnitType.Long_Length"
      (valueChanged)="onTempProfileValueChange($event)"
    >
    </app-form-input>
    <app-form-input
      label="Sea Bed Depth"
      appUnwrap
      [source]="state.TemperatureProfile"
      [disabled]="isLand()"
      key="SeaBedDepth"
      dataCy="well-sea-bed-depth"
      [decimalPlaces]="2"
      [unitType]="UnitType.Long_Length"
      (valueChanged)="onTempProfileValueChange($event)"
    >
    </app-form-input>
  </div>
</div>
