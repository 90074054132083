<app-ribbon-menu-group groupName="Range Management" (click)="changeHelp('toolbars', 'analysis-range-management')">
  <app-range-management></app-range-management>
</app-ribbon-menu-group>
<hr />
<app-ribbon-menu-group
  groupName="Data Management"
  *ngIf="analysisMenuProps$ | async as analysisMenuProps"
  (click)="changeHelp('toolbars', 'analysis-data-management')"
>
  <div class="ribbon-menu-button-group menu-open-reverse">
    <app-button
      class="big-button"
      text="Import Data"
      (buttonClick)="onImportDataClick()"
      [disabled]="!analysisMenuProps.importEnabled"
      [dataCy]="'analysis-menu-panel-import-data'"
      textIcon="icon-file-add-plus"
    ></app-button>

    <app-button
      class="big-button"
      text="Edit Chart"
      (buttonClick)="onEditChartClick()"
      [isUiLockable]="false"
      textIcon="icon-chart-analytics-edit-pen-square"
      [dataCy]="'analysis-menu-edit-chart-btn'"
      [disabled]="!analysisMenuProps.editChartEnabled"
    ></app-button>

    <!-- X Axis Shift - only on Evaluate and Data Analysis  -->
    <ng-container *ngIf="analysisMenuProps.isXAxisShiftAvailable">
      <app-button
        class="big-button"
        [isToggled]="analysisMenuProps.xAxisShiftActive"
        text="X-Axis Shift"
        textIcon="icon-left-right-arrows-circle"
        [dataCy]="'analysis-menu-x-axis-shift-btn'"
        [disabled]="!analysisMenuProps.xAxisShiftEnabled"
        (buttonClick)="onXShiftClick()"
      >
      </app-button>

      <app-button
        class="big-button"
        [isToggled]="analysisMenuProps.xAutoShiftActive"
        text="Auto <br/>X-Shift"
        textIcon="icon-magic-wand"
        [dataCy]="'analysis-menu-auto-x-shift-btn'"
        [disabled]="!analysisMenuProps.xAxisAutoShiftEnabled"
        (buttonClick)="onXAutoShift()"
      ></app-button>

      <app-button
        class="big-button"
        text="Reset </br>X-Shift"
        textIcon="icon-pen-edit-undo"
        [dataCy]="'analysis-menu-reset-x-shift-btn'"
        [disabled]="!analysisMenuProps.resetXAxisShiftEnabled"
        (buttonClick)="onResetXShiftClick()"
      ></app-button>
    </ng-container>

    <!-- Y Axis Shift - only on Simulate and Data Analysis  -->
    <ng-container *ngIf="analysisMenuProps.isYAxisShiftAvailable">
      <app-button
        class="big-button"
        [isToggled]="analysisMenuProps.yAxisShiftActive"
        text="Y-Axis Shift"
        textIcon="icon-arrow-up-down"
        [disabled]="!analysisMenuProps.yAxisShiftEnabled"
        (buttonClick)="onYShiftClick()"
        [dataCy]="'analysis-menu-y-axis-shift-btn'"
      >
      </app-button>

      <app-button
        class="big-button"
        [isToggled]="analysisMenuProps.yAutoShiftActive"
        text="Auto <br/>Y-Shift"
        textIcon="icon-magic-wand"
        [dataCy]="'analysis-menu-auto-y-shift-btn'"
        [disabled]="!analysisMenuProps.yAxisAutoShiftEnabled"
        (buttonClick)="onYAutoShift()"
      ></app-button>

      <app-button
        class="big-button"
        text="Reset </br>Y-Shift"
        textIcon="icon-pen-edit-undo"
        [dataCy]="'analysis-menu-reset-y-shift-btn'"
        [disabled]="!analysisMenuProps.resetYAxisShiftEnabled"
        (buttonClick)="onResetYShiftClick()"
      ></app-button>
    </ng-container>
  </div>
</app-ribbon-menu-group>
