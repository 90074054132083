import { IWellValidationDependencies, ValidatedWellModuleState, WellModuleState } from '../well-module.state';
import { SurveyValidation } from '../model/survey/survey.validation';
import { TemperatureProfileValidation } from '../model/temperature-profile/temperature-profile.validation';
import { CaliperValidation } from '../model/caliper/caliper.validation';
import { CasingValidation } from '../model/casing/casing.validation';
import { ZoneValidation } from '../model/zone/zone.validation';
import { noErrors } from '../../../common/state.helpers';
import { IError } from '../../../common/common-state.interfaces';
import { TreatmentType } from '../dto/well.dto';
import { validateFeatureNotSupportedInFluidPro } from '../../licensing/licensing.validator';
import { ModuleType } from '../../scenario/scenario.dto';

export class WellValidation {
  public static validate(well: WellModuleState, maxSurveyMD: number, deps: IWellValidationDependencies): ValidatedWellModuleState {
    if (!well.isLoaded) {
      return {
        ...well,
        isValid: true,
        isGeneralDataScreenValid: true,
        isCasingDataScreenValid: true,
        isCasingDataScreenWarning: true,
        isReservoirDataScreenValid: true,
        isCaliperDataScreenValid: true,
        error: {},
      };
    }
    const temperatureProfile = TemperatureProfileValidation.ValidateTemperatureProfile(well, deps);

    const treatmentTypeError: IError<WellModuleState> = {
      TreatmentType: this.ValidateTreatmentType(well, deps.currentModuleType),
    };

    const surveyData = SurveyValidation.ValidateSurveyGrid(well);
    const caliperData = CaliperValidation.ValidateCaliperGrid(well, deps);
    const casingData = CasingValidation.ValidateCasingGrid(well, maxSurveyMD, deps);
    const zoneData = ZoneValidation.ValidateZoneGrid(well, deps);
    const isGeneralDataScreenValid = surveyData.isValid && temperatureProfile.isValid && noErrors(treatmentTypeError);

    const isValid = isGeneralDataScreenValid && caliperData.isValid && casingData.isValid && zoneData.isValid;

    const isWarning = casingData.isWarning ?? false;

    const caliperError: IError<WellModuleState> = {
      CaliperMultiplier: CaliperValidation.ValidateCaliperMultiplier(well.CaliperMultiplier),
      CaliperTolerance: CaliperValidation.validateCaliperToleranceInput(well.CaliperTolerance),
    };

    const reservoirScreenError: IError<WellModuleState> = {
      IsLossRateCalculated: this.ValidateIsLossRateCalculated(well),
    };

    const isCaliperDataScreenValid = !CaliperValidation.IsCaliperScreenEnabled(well) || (caliperData.isValid && noErrors(caliperError));

    const isReservoirDataScreenValid = zoneData.isValid && noErrors(reservoirScreenError);

    const error: IError<WellModuleState> = { ...caliperError, ...reservoirScreenError, ...treatmentTypeError };

    return {
      ...well,
      TemperatureProfile: temperatureProfile,
      SurveyData: surveyData,
      CasingData: casingData,
      CaliperData: caliperData,
      ZoneData: zoneData,
      isGeneralDataScreenValid,
      isCasingDataScreenValid: casingData.isValid,
      isCasingDataScreenWarning: casingData.isWarning ?? false,
      isReservoirDataScreenValid,
      isCaliperDataScreenValid,
      isValid,
      isWarning,
      error,
    };
  }

  private static ValidateIsLossRateCalculated(well: WellModuleState): string {
    if (well.TreatmentType === TreatmentType.High_Rate_Water_Pack && !well.IsLossRateCalculated) {
      return 'Specify losses is not supported for High Rate Water Pack treatments';
    }
    return '';
  }

  private static ValidateTreatmentType(well: WellModuleState, currentModuleType: ModuleType): string {
    if (well.TreatmentType === TreatmentType.High_Rate_Water_Pack) {
      return validateFeatureNotSupportedInFluidPro(currentModuleType);
    }
    return '';
  }
}
