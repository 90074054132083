import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { BackendConnectionService } from '../../shared/backend-connection/backend-connection.service';
import {
  AllFilesAndFoldersAction,
  AllFilesAndFoldersActionResponse,
  ClearRecentFilesAction,
  CopyReturnFileAction,
  CopyReturnListAction,
  DeleteAction,
  DeleteUserAction,
  EmptyTrashAction,
  FileActionResponse,
  FileManagerActions,
  FileManagerModuleActionTypes,
  FileManagerModuleName,
  FileMoveInfoAction,
  ListFilesActionResponse,
  ListOrganizationUsersAction,
  MoveAction,
  NewFolderAction,
  RecentFilesAction,
  RecentFilesActionResponse,
  RestoreDbBackupAction,
  RestoreFileAction,
} from '@dunefront/common/modules/file-manager/file-manager.actions';
import * as actions from './file-manager.actions';
import { emptyTrashAction, restoreOrgDBAction } from './file-manager.actions';
import { catchError, filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Router } from '@angular/router';
import { BaseWsEffects } from '../base-ws.effects';
import { ModalService } from '../../common-modules/modals/modal.service';
import { dbDisconnectAction } from '../backend-connection/backend-connection.actions';
import { FileManagerHelper } from './file-manager.helper';
import { PackProHttpErrorResponse } from '@dunefront/common/exceptions/http.errors';
import { getSelectedUser } from './file-manager.selectors';
import { RouterHelperService } from '../../shared/services/router-helper.service';
import { AppError } from '@dunefront/common/exceptions/IAppError';
import { PackProProfileErrorData } from '@dunefront/common/exceptions/file.errors';
import { IUserProfile } from '@dunefront/common/modules/auth/auth.interfaces';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const fileOperationFailure = (error: AppError<PackProProfileErrorData>) => of(actions.fileOperationFailure({ error }));

@Injectable()
export class FileManagerEffects extends BaseWsEffects {
  private unloadFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.deleteFileOrFolder, actions.moveFileOrFolder),
      map(() => dbDisconnectAction()),
    ),
  );

  private loadAllFilesAndFolders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loadAllFilesAndFolders, actions.selectUser),
      concatLatestFrom(() => this.store.select(getSelectedUser)),
      mergeMap(([action, user]) =>
        this.emit<AllFilesAndFoldersActionResponse>(new AllFilesAndFoldersAction(user)).pipe(
          map((result) => actions.loadAllFilesAndFoldersSuccess({ allFilesAndFoldersActionResponse: result.payload })),
          catchError((responseError: PackProHttpErrorResponse) => of(actions.loadAllFilesAndFoldersFailure(responseError.error))),
        ),
      ),
    ),
  );

  private deleteUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.deleteSelectedUser),
      concatLatestFrom(() => this.store.select(getSelectedUser)),
      filter(([action, user]) => user != null),
      mergeMap(([action, user]) =>
        this.emit<any>(new DeleteUserAction(user)).pipe(
          mergeMap(() => {
            this.modalService.showAlert(`All files belonging to account: ${user?.uid} are successfully deleted`).then();
            return [actions.deleteUserSuccess({ user: user as IUserProfile }), actions.loadAllFilesAndFolders()];
          }),
          catchError((responseError: Error) => {
            this.modalService.showAlert(responseError.message, responseError.name).then();
            return of(actions.deleteUserFailure(responseError));
          }),
        ),
      ),
    ),
  );

  private loadRecentFiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loadRecentFiles),
      switchMap(() =>
        this.emit<RecentFilesActionResponse>(new RecentFilesAction()).pipe(
          map((result) => actions.loadRecentFilesSuccess({ recentFilesActionResponse: result.payload })),
          catchError((responseError: PackProHttpErrorResponse) => of(actions.loadRecentFilesFailure(responseError.error))),
        ),
      ),
    ),
  );

  private deleteFileOrFolder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.deleteFileOrFolder),
      concatLatestFrom(() => this.store.select(getSelectedUser)),
      mergeMap(([action, user]) =>
        this.emit<ListFilesActionResponse>(new DeleteAction(action.target, action.isAdminPanel, user)).pipe(
          map((res) => actions.loadAllFilesAndFolders()),
          catchError(fileOperationFailure),
        ),
      ),
    ),
  );

  public copyFileReturnFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.copyFileReturnFile),
      mergeMap((action) =>
        this.emit<FileActionResponse>(new CopyReturnFileAction(action.source, action.target)).pipe(
          tap((res) => this.store.dispatch(actions.loadAllFilesAndFolders())),
          map((res) => actions.copyFileReturnFileSuccess({ fileActionResponse: res.payload })),
          catchError(fileOperationFailure),
        ),
      ),
    ),
  );

  private copyFileReturnList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.copyFileReturnList),
      mergeMap((action) =>
        this.emit<ListFilesActionResponse>(new CopyReturnListAction(action.source, action.target)).pipe(
          map((res) => {
            if (action.isSaveAs) {
              FileManagerHelper.switchFile(this.router, action.target);
            }
            return actions.loadAllFilesAndFolders();
          }),
          catchError(fileOperationFailure),
        ),
      ),
    ),
  );

  private moveFileOrFolder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.moveFileOrFolder),
      concatLatestFrom(() => this.store.select(getSelectedUser)),
      mergeMap(([action, user]) =>
        this.emit<ListFilesActionResponse>(new MoveAction(action.source, action.target, action.mode, user)).pipe(
          map((res) => actions.loadAllFilesAndFolders()),
          catchError(fileOperationFailure),
        ),
      ),
    ),
  );

  private copyFileReturnFileSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.copyFileReturnFileSuccess),
        tap(async (action) => {
          this.modalService.dismissAll();
          await FileManagerHelper.navigateToFile(this.router, action.fileActionResponse.file);
        }),
      ),
    { dispatch: false },
  );

  private newFolder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.newFolder),
      mergeMap((action) =>
        this.emit<ListFilesActionResponse>(new NewFolderAction(action.target)).pipe(
          map((res) => actions.loadAllFilesAndFolders()),
          catchError(fileOperationFailure),
        ),
      ),
    ),
  );

  private clearRecentFiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.clearRecentFiles),
      mergeMap((action) =>
        this.emit<void>(new ClearRecentFilesAction()).pipe(
          map(() => actions.clearRecentFilesSuccess()),
          catchError((error: AppError<PackProProfileErrorData>) => of(actions.clearRecentFilesFailure({ error }))),
        ),
      ),
    ),
  );

  private restoreFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.restoreFile),
      mergeMap((action) =>
        this.emit<FileActionResponse>(new RestoreFileAction(action.source, action.target)).pipe(
          tap((res) => this.store.dispatch(actions.loadAllFilesAndFolders())),
          map((res) => actions.restoreFileSuccess({ fileActionResponse: res.payload })),
          catchError(fileOperationFailure),
        ),
      ),
    ),
  );

  private emptyTrashAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(emptyTrashAction),
      mergeMap(() =>
        this.emit<FileActionResponse>(new EmptyTrashAction()).pipe(
          tap(() => this.store.dispatch(actions.loadAllFilesAndFolders())),
          map(() => actions.emptyTrashActionSuccess()),
          catchError(fileOperationFailure),
        ),
      ),
    ),
  );

  private loadUsersWithFolders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loadOrganizationUsers),
      mergeMap(() => {
        return this.emit<IUserProfile[]>(new ListOrganizationUsersAction()).pipe(
          map((result) => actions.loadOrganizationUsersSuccess({ organizationUsers: result.payload })),
          catchError((responseError: Error) => {
            this.modalService.showAlert(responseError.message).then();
            return of(actions.loadOrganizationUsersFailure(responseError));
          }),
        );
      }),
    ),
  );

  private restoreOrgDBAction$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(restoreOrgDBAction),
        mergeMap((action) =>
          this.emit<any>(new RestoreDbBackupAction(action.backup), 'Restoring database...').pipe(
            map(() => this.modalService.showAlert(`Organization database is successfully restored`)),
            catchError((e) => this.modalService.showAlert(e.message)),
          ),
        ),
      );
    },
    { dispatch: false },
  );

  constructor(
    actions$: Actions,
    store: Store,
    wsService: BackendConnectionService,
    modalService: ModalService,
    protected router: Router,
    private routerHelperService: RouterHelperService,
  ) {
    super(actions$, wsService, FileManagerModuleName, true, false, modalService, store);
  }

  protected override onIncomingMessage(action: FileManagerActions): void {
    switch (action.type) {
      case FileManagerModuleActionTypes.FileDeletedInfo:
        this.onFileInfo('deleted').then();
        return;
      case FileManagerModuleActionTypes.FileMoveInfo:
        this.onFileMoveInfo(action).then();
        return;
      case FileManagerModuleActionTypes.FileOverwriteInfo:
        this.onFileInfo('overwritten').then();
        return;
    }
  }

  private async onFileInfo(action: string): Promise<void> {
    await this.modalService.showAlert(`File was ${action} in another session. <br> Press OK to go to the home page.`);
    this.store.dispatch(dbDisconnectAction());
    await this.routerHelperService.navigateToHome();
  }

  private async onFileMoveInfo(action: FileMoveInfoAction): Promise<void> {
    const actionText = action.mode === 'rename' ? 'renamed' : 'moved';
    await this.modalService.showAlert(`File was ${actionText} in another session. <br> You can continue working as normal.`);
    this.store.dispatch(dbDisconnectAction());
    await FileManagerHelper.navigateToFile(this.router, action.targetFile);
  }
}
