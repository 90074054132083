import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import {
  getCurrentColConfig,
  ImportDataModuleState,
  IValidatedImportDataModuleState,
} from '../../../+store/import-data/import-data-module.state';
import { FileParserService } from '../import-paste-data-common/file-parser/file-parser.service';
import { ModalService } from '../modal.service';
import { IFileLoadedArgs } from './file-loader/file-loader.component';
import { createColConfig, DELTA_TIME_COL_NAME } from '../../../+store/import-data/model/col-config';
import { Store } from '@ngrx/store';
import {
  applyImportTemplateAction,
  nextColumnAction,
  prevColumnAction,
  resetImportDataStateAction,
  updateImportDataStateAction,
} from '../../../+store/import-data/import-data.actions';
import { getValidatedImportModuleState } from '../../../+store/import-data/import-data.selectors';
import { changeProp, ErrorHelper, getChangeProp } from '@dunefront/common/common/common-state.interfaces';
import { ImportDataValidation } from '../../../+store/import-data/validation/import-data.validation';
import {
  ImportDataColumnDelimiterConfig,
  ImportDataFilePropertiesConfig,
  ImportFileColumnDelimiter,
} from '@dunefront/common/modules/data-storage/dto/import-template/import-template.dto';
import * as reportingActions from '../../../+store/reporting/reporting.actions';
import { ChartDataSourceType } from '@dunefront/common/modules/reporting/dto/chart.dto';
import { ImportFileAdvancedValidatorService } from './services/import-file-advanced-validator/import-file-advanced-validator.service';
import { getStartDateOfMostRecentlyImportedFile, getStorageFileNames } from '../../../+store/data-storage/data-storage.selectors';
import { ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ChartTimeVolMode, GetChartDataRequestType } from '@dunefront/common/modules/reporting/reporting-module.actions';
import { RangeConstants } from '@dunefront/common/dto/range.dto';
import { DbDependentComponent } from '../../db-connection/db-dependent.component';
import { ColumnNameGeneratorService } from '../import-paste-data-common/column-name-generator.service';
import { firstValueFrom } from 'rxjs';
import { ModalHelpButtonComponent } from '../modal-help-button/modal-help-button.component';
import { getCurrentScenario } from '../../../+store/scenario/scenario.selectors';
import { filter } from 'rxjs/operators';
import { updateScenariosAction } from '../../../+store/scenario/scenario.actions';
import { getModifiedTemplateTemplateId, getNoneTemplateTemplateId } from '@dunefront/common/common/templates/template-parser';
import { Scenario } from '@dunefront/common/modules/scenario/scenario';

export enum ImportDataStep {
  SelectFile = 0,
  Preview = 1,
  DefineData = 2,
  SendData = 3,
}

const initialStep = ImportDataStep.SelectFile;

@Component({
  selector: 'app-import-data',
  templateUrl: './import-data.component.html',
  styleUrls: ['./import-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImportDataComponent extends DbDependentComponent implements OnDestroy {
  public ImportDataStep = ImportDataStep;

  public FileParser = FileParserService;
  public state!: IValidatedImportDataModuleState;
  public startDateOfMostRecentlyImportedFile?: number;
  public isUploaded = false;
  public anyFilesImported = false;
  private readonly failureWarning = "File could not be read - please make sure you are using a valid data file or change it's delimiter";
  private readonly fileTooShortWarning = `The file must contain at least ${FileParserService.lineTolerance} lines of data`;
  public isHelpOpen = false;
  public helpContent = helpContent;
  public currentHelpContent: ImportHelpContent[] | undefined = helpContent[initialStep];
  public scenario!: Scenario;

  @ViewChild(ModalHelpButtonComponent)
  private modalHelpButtonComponent: ModalHelpButtonComponent | undefined;

  public helpAccordionActiveIndex: number | number[] | null = 0;
  private originalFileLines: string[] = [];

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    store: Store,
    private modalService: ModalService,
    cdRef: ChangeDetectorRef,
    private parser: FileParserService,
    private advancedValidator: ImportFileAdvancedValidatorService,
    private colNameParser: ColumnNameGeneratorService,
    private el: ElementRef,
  ) {
    super(store, cdRef);
    this.resetParsers();
    this.subscription.add(store.select(getValidatedImportModuleState).subscribe((state) => this.onStateChange(state)));
    this.subscription.add(
      store.select(getStartDateOfMostRecentlyImportedFile).subscribe((startDate) => (this.startDateOfMostRecentlyImportedFile = startDate)),
    );
    this.subscription.add(
      this.store
        .select(getCurrentScenario)
        .pipe(filter(Boolean))
        .subscribe((scenario) => (this.scenario = scenario)),
    );
  }

  private _step = initialStep;
  public get step(): ImportDataStep {
    return this._step;
  }

  public set step(newStep: ImportDataStep) {
    this._step = newStep;
    this.setHelpContent(helpContent[newStep]).then();
  }

  private async setHelpContent(helpContent: ImportHelpContent[] | undefined): Promise<void> {
    if (this.currentHelpContent === helpContent || this.currentHelpContent?.[0]?.url === helpContent?.[0]?.url) {
      return;
    }

    // enforce removing iframe from DOM
    // this prevents old help being displayed when new is loading
    // (sometimes token validation takes ~7secs)
    this.currentHelpContent = undefined;
    this.cdRef.detectChanges();

    // add new iframe for new help content
    this.currentHelpContent = helpContent;
    this.cdRef.detectChanges();
  }

  public get isFinishButtonEnabled(): boolean {
    return this.step === ImportDataStep.SendData && this.isUploaded;
  }

  public get isImportButtonEnabled(): boolean {
    return this.isLastColumnConfigScreen();
  }

  public get isBackButtonDisabled(): boolean {
    return this.step <= 0 || this.step === ImportDataStep.SendData;
  }

  public get isNextButtonDisabled(): boolean {
    return (
      !FileParserService.parsedResult.isParsed ||
      this.step === ImportDataStep.SendData ||
      this.isLastColumnConfigScreen() ||
      this.state.templateError?.length > 0
    );
  }

  public get isSendDataStep(): boolean {
    return this.step === ImportDataStep.SendData;
  }

  public changeState(state: ImportDataModuleState): void {
    if (
      state.selectedTemplateId !== getNoneTemplateTemplateId() &&
      (this.step === ImportDataStep.Preview || this.step === ImportDataStep.DefineData)
    ) {
      state.selectedTemplateId = getModifiedTemplateTemplateId();
    }
    this.store.dispatch(updateImportDataStateAction(state));
  }

  public async onFileLoaded(args: IFileLoadedArgs): Promise<void> {
    // clear previous file
    this.changeState(changeProp(this.state, getChangeProp('fileName', '', false)));

    const shouldContinue = await this.validateFileName(args.fileName);
    if (!shouldContinue) {
      return;
    }

    this.originalFileLines = args.data;
    this.parser.fileLines = [...this.originalFileLines];
    await this.parseFile(true, false, false, args.fileName);
  }

  public onImportAnotherFileClick(): void {
    this.store.dispatch(resetImportDataStateAction());
    this.resetParsers();
    this.step = ImportDataStep.SelectFile;
  }

  public onBackClick(): void {
    if (this.step === ImportDataStep.DefineData && this.state.defineDataIndex > 0) {
      this.store.dispatch(prevColumnAction());
    } else {
      this.step--;
    }
  }

  public onNextClick(): void {
    if (!this.validateScreen()) {
      return;
    }
    if (this.step === ImportDataStep.SelectFile || this.step === ImportDataStep.Preview) {
      this.step++;
      return;
    }

    this.store.dispatch(nextColumnAction());
  }

  public async onImportClick(): Promise<void> {
    if (!this.validateScreen()) {
      return;
    }

    const isConfigurationValid = await this.advancedValidator.validate(this.state, this.startDateOfMostRecentlyImportedFile);

    if (!isConfigurationValid) {
      return;
    }
    this.step = ImportDataStep.SendData;
    this.isHelpOpen = false;
    this.cdRef.markForCheck();
  }

  public onFinishClick(): void {
    this.resetParsers();
    this.ref.close();

    this.setEntireRange();
    this.fetchGaugeChartData();
  }

  public async onCancelClick(): Promise<void> {
    const result = await this.modalService.showConfirm('Are you sure you want to exit?', '');
    if (!result) {
      return;
    }

    this.ref.close();

    if (this.anyFilesImported) {
      this.setEntireRange();
      this.fetchGaugeChartData();
    }
  }

  public override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.resetParsers();
    this.store.dispatch(resetImportDataStateAction());
  }

  public onIsUploadedChanged(isUploaded: boolean): void {
    this.isUploaded = isUploaded;
    if (isUploaded) {
      this.anyFilesImported = true;
    }
  }

  private resetParsers(): void {
    FileParserService.reset();
    this.colNameParser.resetColNames();
    this.parser.fileLines = [];
  }

  private async onStateChange(newState: IValidatedImportDataModuleState): Promise<void> {
    const prevState = this.state as IValidatedImportDataModuleState | undefined;
    this.state = newState;
    const isDelimiterConfigChanged =
      prevState != null ? this.isDelimiterConfigChanged(prevState.delimiterConfig, newState.delimiterConfig) : false;
    const isFilePropertiesChanged =
      prevState != null ? this.isFilePropertiesChanged(prevState.filePropertiesConfig, newState.filePropertiesConfig) : false;
    if (prevState == null || isDelimiterConfigChanged || isFilePropertiesChanged) {
      if (newState.delimiterConfig.delimiter === ImportFileColumnDelimiter.Custom && !newState.delimiterConfig.customDelimiter) {
        return;
      }
      await this.parseFile(false, isDelimiterConfigChanged, isFilePropertiesChanged);
    }
    this.cdRef.markForCheck();
  }

  private isDelimiterConfigChanged(prev: ImportDataColumnDelimiterConfig, curr: ImportDataColumnDelimiterConfig): boolean {
    return prev.customDelimiter !== curr.customDelimiter || prev.delimiter !== curr.delimiter;
  }

  private isFilePropertiesChanged(prev: ImportDataFilePropertiesConfig, curr: ImportDataFilePropertiesConfig): boolean {
    return prev.samplingFrequency !== curr.samplingFrequency || prev.initialRowsToSkip !== curr.initialRowsToSkip;
  }

  private isLastColumnConfigScreen(): boolean {
    return this.step === ImportDataStep.DefineData && this.state.defineDataIndex + 1 === this.state.includedColumnIds.length;
  }

  private setEntireRange(): void {
    this.store.dispatch(
      updateScenariosAction({
        scenarios: [{ ...this.scenario, CurrentRangeId: RangeConstants.EntireRangeId }],
        changedKey: 'CurrentRangeId',
      }),
    );
  }

  private fetchGaugeChartData(): void {
    const chartId = this.chartIds$?.value?.gaugeDataChartId;
    if (chartId == null) {
      return;
    }

    this.store.dispatch(
      reportingActions.getTimeVolChartData({
        getChartDataParams: {
          chartId,
          dataSourceType: ChartDataSourceType.ChartSourceGaugeData,
          timeVolMode: ChartTimeVolMode.time,
          moduleType: ModuleType.Evaluate,
          requestType: GetChartDataRequestType.Initial,
          rangeId: RangeConstants.EntireRangeId,
          isOptimizeEvaluationChart: false,
        },
        gaugeDataRequestedAfterFileImport: true,
        src: 'import-data.component - onFinishClick',
      }),
    );
  }

  private validateScreen(): boolean {
    const errorMessage = this.state.errorMessage || this.state.templateError;
    if (errorMessage) {
      this.modalService.showAlert(errorMessage).then();
      return false;
    }

    if (this.step === ImportDataStep.Preview) {
      const noDataErrorMessage = ImportDataValidation.validateParsedFileLines(FileParserService.parsedResult.dataLength);
      if (noDataErrorMessage) {
        this.modalService.showAlert(noDataErrorMessage).then();
        return false;
      }

      const columnSelectionsErrorMessage = ImportDataValidation.validateColumnSelections(this.state);
      if (columnSelectionsErrorMessage) {
        this.modalService.showAlert(`Please check following errors: <br> ${columnSelectionsErrorMessage}`).then();
        return false;
      }
    }

    if (this.step === ImportDataStep.DefineData) {
      const errorMessages = ErrorHelper.getErrorMessages(getCurrentColConfig(this.state).error).join('<br>');
      if (errorMessages) {
        this.modalService.showAlert(`Please check following errors: <br> ${errorMessages}`).then();
        return false;
      }
    }

    return true;
  }

  private async validateFileName(fileName: string): Promise<boolean> {
    const fileNames = await firstValueFrom(this.store.select(getStorageFileNames));

    const similarFileNames = fileNames.filter((f) => f.includes(fileName));
    if (similarFileNames.length) {
      let importFileWarningMessage = 'Similar file was already imported:<br>';
      similarFileNames.forEach((fileName) => (importFileWarningMessage += fileName + '<br>'));

      return await this.modalService.showConfirm(importFileWarningMessage, '', 'sm', 'Import', 'Cancel');
    }
    return true;
  }

  private async parseFile(
    fileJustOpened: boolean,
    isDelimiterConfigChanged = false,
    isFilePropertiesChanged = false,
    fileName?: string,
  ): Promise<void> {
    // file lines may be sliced in the process of reading file
    // everytime we change delimiter we want to have original file
    this.parser.fileLines = [...this.originalFileLines];

    if (!this.parser.fileLines.length) {
      return;
    }

    const showErrorAndStop = async (message: string): Promise<void> => {
      await this.modalService.showAlert(message, 'Warning');
      FileParserService.reset();
      this.cdRef.markForCheck();
      return;
    };

    if (this.parser.fileLines.length < FileParserService.lineTolerance) {
      return showErrorAndStop(this.fileTooShortWarning);
    }

    this.colNameParser.resetColNames();
    const columnDelimiter = this.parser.getColumnDelimiter(this.state.delimiterConfig);

    if (columnDelimiter == null) {
      return showErrorAndStop(this.failureWarning);
    }

    if (fileName) {
      // add filename to store after it passed validation
      this.changeState(changeProp(this.state, getChangeProp('fileName', fileName, false)));
    }

    const isDataRow = (row: string): boolean => {
      const isRowEmpty = row.trim().length === 0;
      let afterSplit = row.split(columnDelimiter);

      // if delimiter is a empty space, filter out empty columns
      if (columnDelimiter === ' ' || columnDelimiter === '  ' || columnDelimiter === '\t') {
        afterSplit = afterSplit.filter((col) => !!col);
      }
      // row is not empty, and each column is a valid number or date
      return !isRowEmpty && afterSplit.every((col) => !isNaN(new Date(col).getTime()) || !isNaN(parseInt(col)));
    };

    const isSeparatorRow = (row: string): boolean => {
      // skip empty line
      if (row.trim().replace(/\t/g, '').replace(/ /g, '').length === 0) {
        return false;
      }
      // check if every char is the same ( like ----- )
      return row.split(columnDelimiter).every((col) => col.split('').every((char) => char === col[0]) && isNaN(parseInt(col[0])));
    };

    // find where actual data starts
    const firstNumericRow = this.parser.fileLines.findIndex((line) => isDataRow(line) || isSeparatorRow(line));

    // find two text lines ( omit empty ones )
    let sliceIdx = 0;
    let textLinesFound = 0;
    if (firstNumericRow !== 0) {
      for (let i = firstNumericRow - 1; i >= 0; i--) {
        const line = this.parser.fileLines[i];
        if (line.trim().replace(/\n/g, '').length && !isDataRow(line)) {
          textLinesFound++;
        }

        if (textLinesFound === 2) {
          sliceIdx = i;
          break;
        }
      }
    }

    if (sliceIdx > 0) {
      this.parser.fileLines = this.parser.fileLines.slice(sliceIdx);
    }

    FileParserService.parsedResult = this.parser.tryReadFileData(this.parser.fileLines, columnDelimiter, true, this.state.filePropertiesConfig);

    const includedColumnIds = FileParserService.parsedResult.columnsWithUnits
      .filter((colWithUnit) => colWithUnit.columnName !== DELTA_TIME_COL_NAME)
      .map((_, index) => index + 1);

    const colConfigs = FileParserService.parsedResult.columnsWithUnits.map((colWithUnit, index) => createColConfig(colWithUnit, index));

    const inclColumnsLengthChanged = includedColumnIds.length !== this.state.includedColumnIds.length;

    const dontResetColConfigs = (isDelimiterConfigChanged && !inclColumnsLengthChanged) || isFilePropertiesChanged;

    this.changeState({
      ...this.state,
      includedColumnIds: dontResetColConfigs ? this.state.includedColumnIds : includedColumnIds,
      colConfigs: dontResetColConfigs ? this.state.colConfigs : colConfigs,
      originalIncludedColumnIds: fileJustOpened ? includedColumnIds : this.state.originalIncludedColumnIds,
      originalFileConfigs: fileJustOpened ? [...colConfigs] : this.state.originalFileConfigs,
    });

    if (this.state.selectedTemplateId) {
      this.store.dispatch(
        applyImportTemplateAction({
          templateId: this.state.selectedTemplateId,
          applyFilePropertiesConfig: fileJustOpened && this.state.selectedTemplateId !== getNoneTemplateTemplateId(),
        }),
      );
    }
  }

  public onHelpClick(isOpen: boolean): void {
    this.isHelpOpen = isOpen;
    this.helpAccordionActiveIndex = 0;
  }

  public openDateFormatHelp(): void {
    this.modalHelpButtonComponent?.onHelpClick(true);
    this.helpAccordionActiveIndex = helpContent[ImportDataStep.DefineData].findIndex((obj) => obj.label === 'Date Formats');
  }

  public get dialogHasScrollbar(): boolean {
    const el = this.el.nativeElement.parentElement;
    return el.scrollHeight > el.clientHeight;
  }

  public get haveIncludedColumns(): boolean {
    return this.state.includedColumnIds.length > 0;
  }
}

export interface ImportHelpContent {
  url: string;
  label: string;
}

const helpContent: { [id: number]: ImportHelpContent[] } = {
  [ImportDataStep.SelectFile]: [{ url: 'assets/help-files/import-wizard/data-import.html', label: '' }],
  [ImportDataStep.Preview]: [{ url: 'assets/help-files/import-wizard/preview.html', label: '' }],
  [ImportDataStep.DefineData]: [
    {
      label: 'Define Data',
      url: 'assets/help-files/import-wizard/define-data.html',
    },
    {
      label: 'Date Formats',
      url: 'assets/help-files/import-wizard/date-formats.html',
    },
  ],
};
