import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnInit } from '@angular/core';
import { createTableRow, ITableRow, ITableState } from '@dunefront/common/common/common-grid.interfaces';
import { GridContainerComponent } from '../../../../../shared/components/grid/grid-container.component';
import { Store } from '@ngrx/store';
import { ModalService } from '../../../../../common-modules/modals/modal.service';
import { ScreenService } from '../../../../../shared/services';
import { IGridColumnConfig } from '../../../../../shared/components/grid/grid.interfaces';
import { getFluidsSelectData } from '../../../../../+store/fluid/fluid.selectors';
import { getGravelsSelectData } from '../../../../../+store/gravel/gravel.selectors';
import {
  deletePumpingScheduleRowsAction,
  insertPumpingScheduleRowsAction,
  updatePumpingScheduleRowsAction,
} from '../../../../../+store/pumping/pumping.actions';
import { notEmpty } from '@dunefront/common/common/state.helpers';
import { GridConfig } from '../../../../../shared/components/grid/grid-config';
import { ISelectItem } from '@dunefront/common/common/select.helpers';
import { IPumpingScheduleValidationDeps } from '@dunefront/common/modules/pumping/model/pumping-schedule/pumping-schedule.validation';
import { PumpingScheduleFactory } from '@dunefront/common/modules/pumping/model/pumping-schedule/pumping-schedule.factory';
import { PumpingSchedule } from '@dunefront/common/modules/pumping/model/pumping-schedule/pumping-schedule';
import { getPumpingScheduleValidationDeps } from '../../../../../+store/pumping/selectors/pumping-schedule.selectors';
import { GridResizeService } from '../../../../../shared/services/grid-resize.service';
import { IDeleteRowsProps, IInsertRowsProps, IUpdateTableRowsProps } from '@dunefront/common/common/common-store-crud.interfaces';
import { FLUID_TYPES_LOOKUP_SRC_ID, GRAVEL_TYPES_LOOKUP_SRC_ID, scheduleGridConfig } from './schedule-grid.config';
import { PumpingScheduleCalculations } from '@dunefront/common/modules/pumping/model/pumping-schedule/pumping-schedule.calculations';
import { firstValueFrom } from 'rxjs';
import { getValidatedPumping } from '../../../../../+store/pumping/selectors/pumping-validation.selectors';
import { PumpingExtensions, VariableChokeSetting } from '@dunefront/common/modules/pumping/dto/pumping.dto';
import { InsertLocation } from '@dunefront/common/modules/common.interfaces';

@Component({
  selector: 'app-schedule-grid',
  templateUrl: './schedule-grid.component.html',
  styleUrls: ['./schedule-grid.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScheduleGridComponent extends GridContainerComponent<PumpingSchedule> implements OnInit {
  @Input() public pumpingSchedule!: ITableState<PumpingSchedule>;
  @Input() public isReadOnlyMode = false;

  constructor(
    store: Store,
    cdRef: ChangeDetectorRef,
    protected modalService: ModalService,
    el: ElementRef,
    gridResizeService: GridResizeService,
    protected resizeService: ScreenService,
  ) {
    super(store, cdRef, el, new ScheduleGridConfig(store, modalService), gridResizeService);
  }

  protected async getColumns(): Promise<IGridColumnConfig<PumpingSchedule>[]> {
    const pumping = await firstValueFrom(this.store.select(getValidatedPumping));
    const showChokePressure = PumpingExtensions.getVariableChoke(pumping) === VariableChokeSetting.Managed_Pressure;

    const isAutoManagePressure = pumping.IsAutoManagePressure;
    return scheduleGridConfig(this.isReadOnlyMode, showChokePressure, isAutoManagePressure);
  }
}

export class ScheduleGridConfig extends GridConfig<PumpingSchedule> {
  private fluids: ISelectItem<number>[] = [];
  private gravels: ISelectItem<number>[] = [];
  private validationDeps!: IPumpingScheduleValidationDeps;

  constructor(
    private store: Store,
    modalService: ModalService,
  ) {
    super(modalService);
    this.headerText = 'Schedule';
    this.subscription.add(
      this.store.select(getFluidsSelectData).subscribe((fluids) => {
        this.fluids = fluids.items;
      }),
    );

    this.subscription.add(
      this.store.select(getGravelsSelectData).subscribe((gravels) => {
        this.gravels = gravels.items;
      }),
    );

    this.subscription.add(
      notEmpty(this.store.select(getPumpingScheduleValidationDeps)).subscribe((deps) => {
        this.validationDeps = deps;
      }),
    );
  }

  public override isCellDisabled = (rows: ITableRow<PumpingSchedule>[], rowIndex: number, cellIndex: number): boolean => {
    if (rows.length === 0) {
      return false;
    }

    const column = this.columns[cellIndex];
    if (column.disabled) {
      return true;
    }
    const row = rows[rowIndex];
    if (row == null) {
      return false;
    }

    if (row?.rowType === 'insert-row' && column.colId !== 'Name') {
      return true;
    }

    if (column.colId === 'GravelConcentration' && (row?.rowData.GravelId === 0 || row?.rowData.GravelId == null)) {
      return true;
    }

    if (column.colId === 'ReturnRate' && PumpingScheduleCalculations.IsStageReturnRateZero(row.rowData, this.validationDeps)) {
      return true;
    }

    if (column.colId === 'ChokePressure' && PumpingScheduleCalculations.IsChokePressureLocked(row.rowData, this.validationDeps)) {
      return true;
    }

    const blockedColumns: (keyof PumpingSchedule)[] = ['CumulativeVolume', 'StageTime', 'CumulativeTime', 'StageGravelMass', 'StageGravelRate'];
    return blockedColumns.includes(column.colId as keyof PumpingSchedule);
  };

  // this need to be defined like that because of lexical scope
  public override getLookupDataSource = (type: string): ISelectItem<number>[] => {
    if (type === GRAVEL_TYPES_LOOKUP_SRC_ID) {
      return this.gravels;
    }
    if (type === FLUID_TYPES_LOOKUP_SRC_ID) {
      return this.fluids;
    }
    return [];
  };

  public override async deleteRowsAction(props: IDeleteRowsProps): Promise<void> {
    this.store.dispatch(deletePumpingScheduleRowsAction(props));
  }

  public override insertRowAction(props: IInsertRowsProps<PumpingSchedule>, refRow: ITableRow<PumpingSchedule>): void {
    this.store.dispatch(insertPumpingScheduleRowsAction(props));
  }

  public override updateRowsAction(props: IUpdateTableRowsProps<PumpingSchedule>): void {
    this.store.dispatch(updatePumpingScheduleRowsAction(props));
  }

  public override getLookupDataPlaceholder(type: string): string {
    if (type === FLUID_TYPES_LOOKUP_SRC_ID) {
      return 'Select Fluid';
    }
    if (type === GRAVEL_TYPES_LOOKUP_SRC_ID) {
      return 'Select Gravel';
    }
    return '';
  }

  public override createEmptyModel(scenarioId: number): PumpingSchedule {
    return PumpingScheduleFactory.createEmpty(scenarioId);
  }

  public override createDefaultTableRows(scenarioId: number, noOfRows: number): ITableRow<PumpingSchedule>[] {
    const newTableRows = [];
    for (let index = 0; index < noOfRows; index++) {
      newTableRows.push(createTableRow(this.createEmptyModel(scenarioId), 'data', index, false));
    }
    return newTableRows;
  }

  public override replaceGridAction(rows: ITableRow<PumpingSchedule>[]): void {
    if (rows.length > 0) {
      const props: IInsertRowsProps<any> = {
        rows,
        refId: 0,
        insertLocation: 'replace' as InsertLocation,
        shouldResetResults: true,
      };
      this.store.dispatch(insertPumpingScheduleRowsAction(props));
    }
  }
}
