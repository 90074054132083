import { Annotation, DrawingAnnotation } from './annotation';
import { Chart, Point } from 'chart.js';
import { AnnotationOffsets, OffsetMinMax, ResizeHandle } from './types';
import { AnnotationsPluginState } from './state';

import {
  annotationTailPointDistance,
  getPointsAngle,
  getTailTrianglePoints,
  isPointInsideRect,
  pointsDistance,
  Rect,
  rectCenter,
  Segment1D,
} from '@dunefront/common/common/math-geometry-helpers';
import { toDegrees, toRadians } from 'chart.js/helpers';

export const resizeHandleRadius = 4;

export const isOverAnnotation = (point: Point, annotation: DrawingAnnotation): boolean => {
  return isPointInsideRect(point, annotation.textBox);
};

export const resizeHandleAtPoint = (point: Point, annotation: DrawingAnnotation): ResizeHandle | undefined => {
  const resizeHandles = getResizeHandles(annotation);

  for (const resizeHandle of resizeHandles) {
    if (pointsDistance(point, resizeHandle) <= resizeHandleRadius) {
      return resizeHandle;
    }
  }

  return undefined;
};

export const getDrawingAnnotationBoundingRect = (annotation: DrawingAnnotation): Rect => {
  const { x, y, textBox } = annotation;

  const minX = Math.min(x, textBox.x);
  const minY = Math.min(y, textBox.y);
  const maxX = Math.max(x, textBox.x + textBox.width);
  const maxY = Math.max(y, textBox.y + textBox.height);

  return {
    x: minX,
    y: minY,
    width: maxX - minX,
    height: maxY - minY,
  };
};

export const annotationToDrawingAnnotation = (annotation: Annotation, chart: Chart): DrawingAnnotation | undefined => {
  const { id, text, xValue, yValue, centerX, centerY, width, height, xScaleID, yScaleID, style, sizeMultiplier } = annotation;

  const xScale = chart.scales[xScaleID];
  const yScale = chart.scales[yScaleID];

  if (xScale == null || yScale == null) {
    return undefined;
  }

  const x = xScale.getPixelForValue(xValue);
  const y = yScale.getPixelForValue(yValue);

  const textBoxCenterX = xScale.getPixelForValue(centerX);
  const textBoxCenterY = yScale.getPixelForValue(centerY);

  const textBoxX = textBoxCenterX - annotation.width / 2;
  const textBoxY = textBoxCenterY - annotation.height / 2;

  const angle = toDegrees(getPointsAngle({ x, y }, { x: textBoxCenterX, y: textBoxCenterY }));
  const dist = annotationTailPointDistance(annotation);

  return {
    id,
    text,
    x,
    y,
    textBox: {
      x: textBoxX,
      y: textBoxY,
      width,
      height,
    },
    arrowPoint1: {
      x: textBoxCenterX + Math.cos(toRadians(angle + 90)) * dist,
      y: textBoxCenterY - Math.sin(toRadians(angle + 90)) * dist,
    },
    arrowPoint2: {
      x: textBoxCenterX + Math.cos(toRadians(angle - 90)) * dist,
      y: textBoxCenterY - Math.sin(toRadians(angle - 90)) * dist,
    },
    xScaleID,
    yScaleID,
    style,
    sizeMultiplier: sizeMultiplier,
  };
};

export const drawingAnnotationToAnnotation = (annotation: DrawingAnnotation, chart: Chart): Annotation => {
  const { id, x, y, textBox, text, xScaleID, yScaleID, style, sizeMultiplier } = annotation;

  const xScale = chart.scales[xScaleID];
  const yScale = chart.scales[yScaleID];

  const xValue = xScale.getValueForPixel(x) ?? 0;
  const yValue = yScale.getValueForPixel(y) ?? 0;

  const textBoxCenter = rectCenter(textBox);

  const centerX = xScale.getValueForPixel(textBoxCenter.x) ?? 0;
  const centerY = yScale.getValueForPixel(textBoxCenter.y) ?? 0;

  return {
    id,
    text,
    xValue,
    yValue,
    centerX,
    centerY,
    width: textBox.width,
    height: textBox.height,
    xScaleID,
    yScaleID,
    style,
    sizeMultiplier: sizeMultiplier,
  };
};

export const applyStateOffsets = (annotation: DrawingAnnotation, state: AnnotationsPluginState): DrawingAnnotation => {
  if (annotation.id === state.selectedAnnotation?.id && state.selectedAnnotationOffsets != null) {
    return drawingAnnotationWithOffsets(annotation, state.selectedAnnotationOffsets);
  }

  return annotation;
};

export const drawingAnnotationWithOffsets = (annotation: DrawingAnnotation, offsets: AnnotationOffsets): DrawingAnnotation => {
  const { x, y, textBox } = annotation;
  const { offsetX, offsetY, offsetBoxX, offsetBoxY, offsetBoxWidth, offsetBoxHeight } = offsets;

  const annotationWithOffsets: DrawingAnnotation = {
    ...annotation,
    x: x + offsetX,
    y: y + offsetY,
    textBox: {
      x: textBox.x + offsetBoxX,
      y: textBox.y + offsetBoxY,
      width: textBox.width + offsetBoxWidth,
      height: textBox.height + offsetBoxHeight,
    },
  };

  updateArrowPoints(annotationWithOffsets);

  return annotationWithOffsets;
};

export const updateArrowPoints = (annotation: DrawingAnnotation): void => {
  const points = getTailTrianglePoints(annotation, annotation.textBox);

  annotation.arrowPoint1 = points[0];
  annotation.arrowPoint2 = points[1];
};

/**
 * Returns collection of ResizeHandle for given annotation.
 * Each ResizeHandle is drawn as a circle when annotation is selected.
 * @param annotation
 */
export const getResizeHandles = (annotation: DrawingAnnotation): ResizeHandle[] => {
  const { x, y, width, height } = annotation.textBox;

  return [
    {
      // top left
      x,
      y,
      resizeX: true,
      resizeY: true,
      cursor: 'nwse-resize',
    },
    {
      // bottom right
      x: x + width,
      y: y + height,
      resizeX: true,
      resizeY: true,
      cursor: 'nwse-resize',
    },
    {
      // top right
      x: x + width,
      y,
      resizeX: true,
      resizeY: true,
      cursor: 'nesw-resize',
    },
    {
      // bottom left
      x,
      y: y + height,
      resizeX: true,
      resizeY: true,
      cursor: 'nesw-resize',
    },
    {
      // top center
      x: x + width / 2,
      y,
      resizeX: false,
      resizeY: true,
      cursor: 'ns-resize',
    },
    {
      // bottom center
      x: x + width / 2,
      y: y + height,
      resizeX: false,
      resizeY: true,
      cursor: 'ns-resize',
    },
    {
      // middle left
      x,
      y: y + height / 2,
      resizeX: true,
      resizeY: false,
      cursor: 'ew-resize',
    },
    {
      // middle right
      x: x + width,
      y: y + height / 2,
      resizeX: true,
      resizeY: false,
      cursor: 'ew-resize',
    },
  ];
};

export const getMaxAllowedOffset = (inner: Segment1D, outer: Segment1D): OffsetMinMax => {
  const innerEnd = inner.start + inner.len;
  const outerEnd = outer.start + outer.len;

  return {
    min: inner.start > outer.start ? outer.start - inner.start : 0,
    max: innerEnd < outerEnd ? outerEnd - innerEnd : 0,
  };
};

export const getXSegment = (rect: Rect): Segment1D => ({ start: rect.x, len: rect.width });
export const getYSegment = (rect: Rect): Segment1D => ({ start: rect.y, len: rect.height });
