<app-page-panel header="Packing Factor">
  <div class="grid-form-container">
    <app-form-input
      label="Packing Factor"
      [source]="shuntTube"
      key="ShuntPackingFactor"
      [decimalPlaces]="2"
      [unitType]="UnitType.None"
      (valueChanged)="onValueChange($event)"
    >
    </app-form-input>
  </div>
</app-page-panel>
