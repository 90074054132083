import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { EnumHelpers } from '@dunefront/common/utils/enum.helpers';
import { BasicPropertyChangeComponent } from '../../../../../common-modules/db-connection/basic-property-change.component';
import { ValidatedWellModuleState } from '@dunefront/common/modules/well/well-module.state';
import { ZonePressureType } from '@dunefront/common/modules/well/dto/well.dto';

@Component({
  selector: 'app-pressure-definition',
  templateUrl: './pressure-definition.component.html',
  styleUrls: ['./pressure-definition.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PressureDefinitionComponent extends BasicPropertyChangeComponent<ValidatedWellModuleState> {
  @Input() public state!: ValidatedWellModuleState;
  public pressureDefRadioItems = EnumHelpers.EnumToRadioItems(ZonePressureType);
}
