import { createReducer, on } from '@ngrx/store';
import { deleteRowsSuccess, insertRowsSuccess, loadScenarioDataSuccessAction, resetScenarioState, updateRowSuccess } from '../app.actions';
import { PSDAnalysisReducerHelper } from './psd-analysis.reducer.helper';
import { selectPSDAction, updateLastPSDInputType } from './psd-analysis.actions';
import { psdAnalysisInitialState, PSDAnalysisModuleState } from '@dunefront/common/modules/psd-analysis/psd-analysis.module.state';

export const psdAnalysisModuleReducer = createReducer<PSDAnalysisModuleState>(
  psdAnalysisInitialState,
  on(resetScenarioState, (): PSDAnalysisModuleState => ({ ...psdAnalysisInitialState })),
  on(
    loadScenarioDataSuccessAction,
    (state, action): PSDAnalysisModuleState =>
      PSDAnalysisReducerHelper.onLoadScenarioDataSuccessAction(state, action.loadScenarioResponse.psdAnalysisModule),
  ),
  on(updateLastPSDInputType, (state, action): PSDAnalysisModuleState => ({ ...state, LastInputType: action.PSDInputType })),
  on(updateRowSuccess, (state, action): PSDAnalysisModuleState => PSDAnalysisReducerHelper.updatePSDAnalysisSuccess(state, action)),
  on(updateRowSuccess, (state, action): PSDAnalysisModuleState => PSDAnalysisReducerHelper.updatePsdResultsSuccess(state, action)),
  on(selectPSDAction, (state, action): PSDAnalysisModuleState => PSDAnalysisReducerHelper.changeSelectedPSD(state, action.psdId)),
  on(updateRowSuccess, (state, action): PSDAnalysisModuleState => PSDAnalysisReducerHelper.updatePSDRowSuccess(state, action)),
  on(insertRowsSuccess, (state, action): PSDAnalysisModuleState => PSDAnalysisReducerHelper.insertPSDRowSuccess(state, action)),
  on(deleteRowsSuccess, (state, action): PSDAnalysisModuleState => PSDAnalysisReducerHelper.deletePSDRowsSuccess(state, action)),
  on(updateRowSuccess, (state, action): PSDAnalysisModuleState => PSDAnalysisReducerHelper.updatePSDReadingsRowSuccess(state, action)),
  on(deleteRowsSuccess, (state, action): PSDAnalysisModuleState => PSDAnalysisReducerHelper.deletePSDReadingsRowsSuccess(state, action)),
);
