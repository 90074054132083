import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthModuleFeatureName, IAuthState } from './auth.reducer';
import { isAdmin } from '@dunefront/common/modules/auth/auth.helpers';

export const getAuthState = createFeatureSelector<IAuthState>(AuthModuleFeatureName);
export const getUser = createSelector(getAuthState, (state) => state.user);
export const getIsAuthenticated = createSelector(getAuthState, (state) => state.isAuthenticated);

export const getIsAdmin = createSelector(getAuthState, (state) => {
  if (state.user?.role == null) {
    return null;
  }

  return isAdmin(state.user.role);
});
