import { UnitConverter } from '../../converter.interfaces';
import { LiquidConcentrationUnit } from '../../../dto/unit-system.dto';

export class LiquidConcentrationConverter extends UnitConverter {
  public static symbols: Array<string> = ['', 'm³/m³', 'L/1000L', 'galUS/1000galUS'];

  public static unit = LiquidConcentrationUnit;

  public static fromSi(value: number, unit: LiquidConcentrationUnit): number {
    switch (unit) {
      case LiquidConcentrationUnit.Cubic_meter_per_cubic_meter: {
        return value;
      }
      case LiquidConcentrationUnit.Liter_per_1000_liter: {
        return value * 1.0e3;
      }
      case LiquidConcentrationUnit.US_gallon_per_1000_US_gallon: {
        return value * 1.0e3;
      }
      default: {
        throw new TypeError('Unknown Liquid Concentration Unit:' + unit);
      }
    }
  }

  public static toSi(value: number, unit: LiquidConcentrationUnit): number {
    switch (unit) {
      case LiquidConcentrationUnit.Cubic_meter_per_cubic_meter: {
        return value;
      }
      case LiquidConcentrationUnit.Liter_per_1000_liter: {
        return value / 1.0e3;
      }
      case LiquidConcentrationUnit.US_gallon_per_1000_US_gallon: {
        return value / 1.0e3;
      }
      default: {
        throw new TypeError('Unknown Liquid Concentration Unit:' + unit);
      }
    }
  }

  public static getSymbol(unit: LiquidConcentrationUnit): string {
    return this.symbols[unit];
  }
}
