import { UnitConverter } from '../../converter.interfaces';
import { FluidLossCoefficientUnit } from '../../../dto/unit-system.dto';

export class FluidLossCoefficientConverter extends UnitConverter {
  public static symbols: Array<string> = ['', 'm/√s', 'm/√min', 'ft/√s', 'ft/√min'];

  public static unit = FluidLossCoefficientUnit;

  public static fromSi(value: number, unit: FluidLossCoefficientUnit): number {
    switch (unit) {
      case FluidLossCoefficientUnit.Meter_per_root_second: {
        return value;
      }
      case FluidLossCoefficientUnit.Meter_per_root_minute: {
        return value * 7.745966692;
      }
      case FluidLossCoefficientUnit.Foot_per_root_second: {
        return value * 3.280839895;
      }
      case FluidLossCoefficientUnit.Foot_per_root_minute: {
        return value * 25.41327655;
      }

      default: {
        throw new TypeError('Unknown Fluid Loss Coefficient Unit:' + unit);
      }
    }
  }

  public static toSi(value: number, unit: FluidLossCoefficientUnit): number {
    switch (unit) {
      case FluidLossCoefficientUnit.Meter_per_root_second: {
        return value;
      }
      case FluidLossCoefficientUnit.Meter_per_root_minute: {
        return value / 7.745966692;
      }
      case FluidLossCoefficientUnit.Foot_per_root_second: {
        return value / 3.280839895;
      }
      case FluidLossCoefficientUnit.Foot_per_root_minute: {
        return value / 25.41327655;
      }
      default: {
        throw new TypeError('Unknown Fluid Loss Coefficient Unit:' + unit);
      }
    }
  }

  public static getSymbol(unit: FluidLossCoefficientUnit): string {
    return this.symbols[unit];
  }
}
