import { IcdPortDataDto } from '../../../../dto/icd-port-data.dto';
import { ICDType } from '../../../../dto/lower-completion-pipe.dto';
import { ITableRow, ITableState } from '../../../../common/common-grid.interfaces';
import { IcdScreenPipe } from '../../../pipes/lower-completion-pipes/pipes/icd-screen-pipe';
import { IError } from '../../../../common/common-state.interfaces';
import { ArrayHelpers } from '../../../../common/array-helpers';

export class IcdPortDataValidation {
  public static ValidateIcdPortDataGrid(
    icdPortData: ITableState<IcdPortDataDto>,
    permanentCompletionRow: ITableRow<IcdScreenPipe>,
  ): ITableState<IcdPortDataDto> {
    let isValid = true;

    const updatedRows = icdPortData.rows.map((row, rowIndex) => {
      if (row.rowType === 'insert-row') {
        return row;
      }
      const newRow = this.ValidateIcdPortDataGridRow(icdPortData.rows, rowIndex, permanentCompletionRow);
      isValid = isValid && newRow.isValid && newRow.error.PortLength === '';
      return newRow;
    });
    return { ...icdPortData, rows: updatedRows, isValid };
  }

  public static ValidateIcdPortDataGridRow(
    rows: ITableRow<IcdPortDataDto>[],
    rowIndex: number,
    screenRow: ITableRow<IcdScreenPipe>,
  ): ITableRow<IcdPortDataDto> {
    const row: ITableRow<IcdPortDataDto> = { ...rows[rowIndex] };
    const error: IError<IcdPortDataDto> = {
      NumberOfPorts: this.ValidateIcdNumberOfPorts(row, screenRow, rows),
      PortDiameter: this.ValidateIcdPortDiameter(row, screenRow),
      PortLength: this.ValidateIcdPortLength(row, screenRow),
    };
    const isValid = error.NumberOfPorts === '' && error.PortDiameter === '' && error.PortLength === '';
    return { ...row, error, isValid };
  }

  private static ValidateIcdNumberOfPorts(
    row: ITableRow<IcdPortDataDto>,
    icdScreenRow: ITableRow<IcdScreenPipe>,
    rows: ITableRow<IcdPortDataDto>[],
  ): string {
    const icdType = icdScreenRow.rowData.ICDType;
    if (icdType != ICDType.BHGE_ICD_Or_AICD) {
      if (row.rowData.NumberOfPorts <= 0) {
        return 'Number of ports must be greater than zero';
      } else if (
        icdType == ICDType.Halliburton_EquiFlow_Nozzle_ICD &&
        ArrayHelpers.sum(rows.map((row) => row.rowData.NumberOfPorts)) > 12
      ) {
        return "There can't be more than 12 ports";
      }
    }

    return '';
  }

  private static ValidateIcdPortDiameter(row: ITableRow<IcdPortDataDto>, icdScreenRow: ITableRow<IcdScreenPipe>): string {
    if (icdScreenRow.rowData.ICDType !== ICDType.Generic_ICD) {
      return '';
    }

    if (row.rowData.PortDiameter <= 0) {
      return 'Port diameter must be greater than zero';
    }
    if (row.rowData.PortDiameter >= icdScreenRow.rowData.OuterDiameter) {
      return 'Port diameter must be less than screen OD';
    }

    return '';
  }

  private static ValidateIcdPortLength(row: ITableRow<IcdPortDataDto>, icdScreenRow: ITableRow<IcdScreenPipe>): string {
    if (icdScreenRow.rowData.ICDType !== ICDType.Generic_ICD) {
      return '';
    }

    if (row.rowData.PortLength <= 0) {
      return 'Port length must be greater than zero';
    }
    if (row.rowData.PortLength >= icdScreenRow.rowData.JointLength) {
      return 'Port length must be less than joint length';
    }

    return '';
  }

  public static validateGenericIcdGridLength(icdPortDataTable: ITableState<IcdPortDataDto>, rows: number[]): string {
    const dataRows = icdPortDataTable.rows.filter((row) => row.rowType === 'data');

    if (dataRows.length - rows.length <= 0) {
      return 'At least 1 row must remain in the grid';
    }
    return '';
  }
}
