<div class="grid-form-container main with-gap" *ngIf="zoneRow && well">
  <ng-container *ngIf="isLossRateCalculated">
    <div class="grid-form-container no-padding inline">
      <app-form-input
        appUnwrap
        label="Perm. to Reservoir Fluid"
        [source]="zoneRow"
        [key]="'PermeabilityToReservoirFluid'"
        [unitType]="UnitType.Permeability"
        dataCy="perm-to-reservoir-fluid"
        (valueChanged)="onValueChange($event)"
      ></app-form-input>

      <app-form-input
        appUnwrap
        label="Perm. to Injected Fluid"
        [source]="zoneRow"
        [key]="'PermeabilityToInjectedFluid'"
        [unitType]="UnitType.Permeability"
        dataCy="perm-to-injected-fluid"
        (valueChanged)="onValueChange($event)"
      ></app-form-input>

      <app-form-input
        appUnwrap
        label="Reservoir Fluid Viscosity"
        [source]="zoneRow"
        [key]="'ReservoirFluidViscosity'"
        [decimalPlaces]="3"
        [unitType]="UnitType.Viscosity"
        dataCy="reservoir-fluid-viscosity"
        (valueChanged)="onValueChange($event)"
      ></app-form-input>

      <app-form-input
        appUnwrap
        label="Fluid loss Coefficient"
        [source]="zoneRow"
        [key]="'FluidLossCoefficient'"
        [decimalPlaces]="4"
        [unitType]="UnitType.Fluid_Loss_Coefficient"
        [disabled]="well.TreatmentType === TreatmentType.Gravel_Pack"
        (valueChanged)="onValueChange($event)"
      ></app-form-input>
    </div>
    <div class="grid-form-container no-padding inline">
      <app-form-input
        appUnwrap
        label="Porosity"
        [source]="zoneRow"
        [key]="'Porosity'"
        [unitType]="UnitType.None"
        dataCy="porosity"
        unitLabel="%"
        (valueChanged)="onValueChange($event)"
      ></app-form-input>

      <app-form-input
        appUnwrap
        label="Skin"
        [source]="zoneRow"
        [key]="'Skin'"
        [unitType]="UnitType.None"
        [unitLabel]=""
        (valueChanged)="onValueChange($event)"
      ></app-form-input>

      <app-form-input
        appUnwrap
        label="Young's Modulus"
        [source]="zoneRow"
        [key]="'YoungsModulus'"
        [unitType]="UnitType.Pressure"
        [disabled]="well.TreatmentType === TreatmentType.Gravel_Pack"
        (valueChanged)="onValueChange($event)"
      ></app-form-input>

      <app-form-input
        appUnwrap
        label="Poisson's Ratio"
        [source]="zoneRow"
        [key]="'PoissonsRatio'"
        [unitType]="UnitType.None"
        [disabled]="well.TreatmentType === TreatmentType.Gravel_Pack"
        (valueChanged)="onValueChange($event)"
      ></app-form-input>
    </div>
  </ng-container>

  <ng-container *ngIf="!isLossRateCalculated">
    <div class="grid-form-container no-padding inline">
      <label>Loss Zone Type</label>
      <app-select
        [source]="zoneRow.rowData"
        [key]="'LossZoneType'"
        [items]="lossZoneTypeItems"
        dataCy="loss-zone-type-select"
        [value]="zoneRow.rowData.LossZoneType"
        [overrideErrorMessage]="zoneRow.error.LossZoneType"
        [width]="154"
        (valueChanged)="onValueChange($event)"
      >
      </app-select>
    </div>
    <div class="grid-form-container no-padding inline">
      <app-form-input
        appUnwrap
        label="Loss Distribution"
        [source]="zoneRow"
        [key]="'LossPercentage'"
        [unitType]="UnitType.None"
        dataCy="loss-distribution-input"
        unitLabel="%"
        [disabled]="zoneRow.rowData.LossZoneType === LossZoneType.None"
        (valueChanged)="onValueChange($event)"
      ></app-form-input>
    </div>
  </ng-container>
</div>
