export interface AbandonedTempFile {
  projectFileName: string;
  tempDirPath: string;
  sourceProjectFilePath: string;
  restoreIssueType: AbandonedTempFileRestoreIssueType;
}

export enum AbandonedTempFileHandlingOption {
  // OverwriteExisting = 'Overwrite existing',
  SaveAs = 'Save as',
  Discard = 'Discard',
}

export enum AbandonedTempFileRestoreIssueType {
  OrgFileExistsHasBeenModified = 1,
  OrgFileExistsUnableToOverwrite = 2,
  OrgFileDoesNotExist = 3,
}

export interface AbandonedTempFileHandling {
  file: AbandonedTempFile;
  handlingOption: AbandonedTempFileHandlingOption;
}
