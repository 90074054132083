import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { openImportDataModalAction } from '../../../+store/import-data/import-data.actions';

@Component({
  selector: 'app-no-gauge-data-message',
  templateUrl: './no-gauge-data-message.component.html',
  styleUrls: ['./no-gauge-data-message.component.scss'],
})
export class NoGaugeDataMessageComponent {
  constructor(private store: Store) {}

  public onOpenImportDataClick(): void {
    this.store.dispatch(openImportDataModalAction());
  }
}
