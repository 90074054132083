<div *ngIf="temperatureProfile">
  <div class="grid-form-container with-gap">
    <div class="grid-form-container no-padding" style="grid-column: span 2">
      <app-radio-group
        id="temperature-profile-radio"
        [isUiLockable]="true"
        [items]="temperatureProfileTypeRadioItems"
        layout="horizontal"
        dataCy="well-temp-profile-type"
        [source]="temperatureProfile"
        [key]="'TemperatureProfileType'"
        (valueChanged)="onValueChange($event)"
      >
      </app-radio-group>
    </div>

    <div class="grid-form-container no-padding" appEqualizeSymbols>
      <app-form-input
        class="grid-two-one"
        appUnwrap
        label="Surface Temp"
        [source]="temperatureProfile"
        dataCy="well-surface-temp"
        key="SurfaceTemp"
        [decimalPlaces]="2"
        [unitType]="UnitType.Temperature"
        (valueChanged)="onValueChange($event)"
      >
      </app-form-input>
      <label *ngIf="temperatureProfile.TemperatureProfileType === TemperatureProfileType.Fixed">BHCT</label>
      <label *ngIf="temperatureProfile.TemperatureProfileType !== TemperatureProfileType.Fixed">Initial BHCT</label>
      <app-form-input
        class="ml-auto"
        [source]="temperatureProfile"
        [disabled]="temperatureProfile.TemperatureProfileType === TemperatureProfileType.Detailed_Calc"
        dataCy="well-bottomhole-circulating-temp"
        key="BottomholeCirculating"
        [decimalPlaces]="2"
        [unitType]="UnitType.Temperature"
        (valueChanged)="onValueChange($event)"
      >
      </app-form-input>
      <app-form-input
        class="grid-two-one"
        appUnwrap
        label="Min Water Temp"
        [source]="temperatureProfile"
        [disabled]="isLand()"
        key="MinWaterTemp"
        dataCy="well-min-water-temp"
        [decimalPlaces]="2"
        [unitType]="UnitType.Temperature"
        (valueChanged)="onValueChange($event)"
      >
      </app-form-input>
    </div>

    <div class="grid-form-container no-padding" appEqualizeSymbols>
      <app-form-input
        class="grid-two-one"
        appUnwrap
        label="BH TVD"
        [source]="temperatureProfile"
        [disabled]="true"
        key="BottomHoleTVD"
        dataCy="well-bottomhole-tvd"
        [decimalPlaces]="2"
        [unitType]="UnitType.Long_Length"
        (valueChanged)="onValueChange($event)"
      >
      </app-form-input>
      <app-form-input
        class="grid-two-one"
        appUnwrap
        label="BHST"
        [source]="temperatureProfile"
        key="BottomholeStaticTemperature"
        dataCy="well-bottomhole-static-temp"
        [decimalPlaces]="2"
        [unitType]="UnitType.Temperature"
        (valueChanged)="onValueChange($event)"
      >
      </app-form-input>
      <app-form-input
        class="grid-two-one"
        appUnwrap
        label="at Depth"
        [source]="temperatureProfile"
        [disabled]="isLand()"
        key="MinWaterTempDepth"
        dataCy="well-min-water-temp-depth"
        [decimalPlaces]="2"
        [unitType]="UnitType.Long_Length"
        (valueChanged)="onValueChange($event)"
      >
      </app-form-input>
    </div>
  </div>
</div>
