import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { IValidatedDataType } from '@dunefront/common/common/common-grid.interfaces';
import { IError, ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';
import { noErrors } from '@dunefront/common/common/state.helpers';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { IAnnotation } from '../chart-component-helpers/chart-types';
import { IAnnotationStyle } from '@dunefront/common/modules/reporting/dto/chart-annotation.dto';
import {
  defaultModalCancelButton,
  defaultModalConfirmButton,
  modalButton,
  ModalButtonConfig,
} from '../../modals/generic-modal/generic-modal.component';

@Component({
  templateUrl: './chart-annotation.component.html',
  styleUrls: ['./chart-annotation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartAnnotationComponent implements OnInit {
  public annotation!: IAnnotation;
  public defaultAnnotationStyle!: IAnnotationStyle;
  public isChartRotated = false;
  public allowDelete = false;
  public allowDuplicate = false;

  public UnitType = UnitSystem;
  public validatedAnnotation!: IValidatedAnnotation;
  public modalTitle = 'Chart Annotation';

  public modalButtonsConfigs: ModalButtonConfig[] = [];

  constructor(
    public activeModal: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private cdRef: ChangeDetectorRef,
  ) {
    this.annotation = { ...config.data.annotation };
    this.defaultAnnotationStyle = config.data.defaultAnnotationStyle;
    this.isChartRotated = config.data.isChartRotated;
    this.allowDelete = config.data.allowDelete;
    this.allowDuplicate = this.annotation.Id !== -1;

    this.modalButtonsConfigs = [
      modalButton('Duplicate', (): void => this.duplicateClicked(), 'chart-annotation-duplicate-btn', 'primary', this.allowDuplicate),
      modalButton('Delete', (): void => this.deleteClicked(), 'chart-annotation-dlg--delete-btn', 'primary', this.allowDelete),
      defaultModalCancelButton((): void => this.cancelClicked()),
      defaultModalConfirmButton((): void => this.okClicked(), 'OK', 'chart-annotation-dlg--ok-btn'),
    ];
  }

  public ngOnInit(): void {
    this.validatedAnnotation = this.validateAnnotation(this.annotation);
  }

  public get isOverrideStyle(): boolean {
    return this.annotation.style != null;
  }

  public onOverrideStyleChanged(override: boolean): void {
    this.annotation.style = override ? { ...this.defaultAnnotationStyle } : undefined;

    this.validatedAnnotation = this.validateAnnotation(this.annotation);
    this.cdRef.markForCheck();
  }

  public emitValue(event: ObjectChangeProp<IValidatedAnnotation>): void {
    this.annotation = { ...this.annotation, [event.key]: event.value };

    this.validatedAnnotation = this.validateAnnotation(this.annotation);
    this.cdRef.markForCheck();
  }

  public emitStyleValue(event: ObjectChangeProp<IAnnotation>): void {
    const style = this.annotation.style;
    if (style != null) {
      this.annotation.style = { ...style, [event.key]: event.value };
    }

    this.validatedAnnotation = this.validateAnnotation(this.annotation);
    this.cdRef.markForCheck();
  }

  public okClicked(): void {
    this.validatedAnnotation = this.validateAnnotation(this.annotation);
    if (!this.validatedAnnotation.isValid) {
      return;
    }
    const result = { action: 'ok', value: this.annotation };
    this.activeModal.close(result);
  }

  public cancelClicked(): void {
    this.activeModal.close();
  }

  public deleteClicked(): void {
    const result: ChartAnnotationDialogResult = { action: 'delete', value: this.validatedAnnotation };
    this.activeModal.close(result);
  }

  private validateAnnotation(annotation: IAnnotation): IValidatedAnnotation {
    const error: IError<IAnnotation> = {};
    if (!annotation.text.trim().length) {
      error.text = 'Annotation text required';
    }
    return { ...annotation, error, isValid: noErrors(error) };
  }

  public duplicateClicked(): void {
    const duplicatedAnnotation = {
      ...this.annotation,
      Id: -1,
      boxValue: this.annotation.value - (this.annotation.value - this.annotation.boxValue) / 1.2,
      boxArgument: this.annotation.argument - (this.annotation.argument - this.annotation.boxArgument) / 1.2,
    };

    const validatedAnnotation = this.validateAnnotation(duplicatedAnnotation);
    if (!validatedAnnotation.isValid) {
      return;
    }
    const result = { action: 'ok', value: duplicatedAnnotation };
    this.activeModal.close(result);
  }
}

export interface ChartAnnotationDialogResult {
  action: 'ok' | 'delete';
  value: IAnnotation;
}

export interface IValidatedAnnotation extends IAnnotation, IValidatedDataType {
  error: IError<IAnnotation>;
}
