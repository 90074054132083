import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { packProTargetConfig } from '@dunefront/common/configs/packpro-target-config';
import { initSentry } from '@dunefront/client-common/shared/helpers/sentry-client';

if (environment.production) {
  enableProdMode();
  initSentry(environment, packProTargetConfig);
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
