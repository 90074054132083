<div class="grid-form-container main with-gap">
  <div class="grid-form-container no-padding inline">
    <app-form-input
      appUnwrap
      label="Eccentricity"
      [source]="completion"
      [key]="'WashpipeEccentricity'"
      [unitType]="UnitType.None"
      (valueChanged)="propertyChanged.emit($event)"
    ></app-form-input>
  </div>
  <div class="grid-form-container no-padding inline">
    <app-form-input
      appUnwrap
      label="Ratio"
      [disabled]="true"
      [readOnlyValue]="CompletionCalculations.WashpipeBasepipeRatio(completion)"
      [unitType]="UnitType.None"
      [dataCy]="'washpipe-props-ratio'"
    ></app-form-input>
  </div>
</div>
