import { createEmptyPumpingDto, PumpingDto } from '../../dto/pumping.dto';
import { createTableState } from '../../../../common/common-grid.interfaces';
import { PumpingSchedule } from '../pumping-schedule/pumping-schedule';
import { Pumping } from './pumping';
import { WellFluid } from '../well-fluid/well-fluid';
import { WellFluidFactory } from '../well-fluid/well-fluid.factory';
import { PumpingModuleState } from '../../pumping-module.state';
import { PumpingScheduleFactory } from '../pumping-schedule/pumping-schedule.factory';
import { RangeConstants } from '../../../../dto/range.dto';
import { PumpedFluidAndGravelFactory } from '../pumped-fluid-and-gravel/pumped-fluid-and-gravel.factory';
import { LoadPumpingActionResponse } from '../../pumping-module.actions';

export class PumpingFactory {
  public static create(payload: LoadPumpingActionResponse, scenarioId: number): PumpingModuleState {
    const { pumpingDtos, pumpingScheduleDtos, pumpedFluidAndGravelDtos, wellFluidDtos } = payload;
    const ranges = pumpingDtos.map((p) => p.RangeId);
    const pumpingSchedule = createTableState<PumpingSchedule>(
      PumpingScheduleFactory.createRowListFromDtos(pumpingScheduleDtos, pumpingDtos[0].ScenarioId, true),
    );

    let state: PumpingModuleState = {
      pumpingSchedule,
      evaluationSchedule: createTableState([]),

      [RangeConstants.EntireRangeId]: {
        pumping: this.createEmptyPumping(scenarioId, RangeConstants.EntireRangeId),
        wellFluids: createTableState([]),
        pumpedFluidAndGravel: createTableState([]),
        rangeId: RangeConstants.EntireRangeId,
        isLoaded: true,
      },
    };

    ranges.forEach((rangeId) => {
      state = {
        ...state,
        [rangeId]: {
          pumping: this.createPumping(pumpingDtos.find((p) => p.RangeId === rangeId) as PumpingDto),
          wellFluids: createTableState<WellFluid>(
            WellFluidFactory.createRowListFromDtos(wellFluidDtos.filter((wf) => wf.RangeId === rangeId)),
          ),
          pumpedFluidAndGravel: createTableState(
            PumpedFluidAndGravelFactory.createRowListFromDtos(
              scenarioId,
              rangeId,
              pumpedFluidAndGravelDtos.filter((p) => p.RangeId === rangeId),
            ),
          ),
          rangeId,
          isLoaded: true,
        },
      };
    });

    return state;
  }

  public static createEmptyPumping(scenarioId: number, rangeId: number): Pumping {
    return this.createPumping(createEmptyPumpingDto(scenarioId, rangeId));
  }

  public static createPumping(pumpingDto: PumpingDto): Pumping {
    return { ...pumpingDto, isValid: true, error: {} };
  }

  public static updatePumpingProps(pumpingState: PumpingModuleState, dto: PumpingDto): PumpingModuleState {
    return { ...pumpingState, [dto.RangeId]: { ...pumpingState[dto.RangeId], pumping: this.createPumping(dto) } };
  }

  public static toDto(pumping: Pumping): PumpingDto {
    const { error, isValid, ...pumpingDto } = pumping;
    return pumpingDto;
  }
}
