import { GeneralCalculations } from '../../../../common/general.calculations';
import { RheometerConstants, RheometerDto, RheometerReadingDto, RheometerTestType } from '../rheometer.dto';
import { ArrayHelpers } from '../../../../common/array-helpers';
import { RheologyDto, RheologyProps } from './rheology.dto';
import { Rheology } from '../../model/rheology/rheology';

export class RheologyCalculations {
  public static CalculateRheology(
    rheometer: RheometerDto,
    rheometerReadings: RheometerReadingDto[] | undefined,
    testType: RheometerTestType,
  ): RheologyProps {
    const rheologyProps: RheologyProps = {
      NPrime: 0,
      KPrime: 0,
      RSquared: 0,
    };

    if (!rheometerReadings || !rheometerReadings.length) {
      return rheologyProps;
    }

    // Check if data is valid
    if (testType === RheometerTestType.Shear_Rate_Viscosity) {
      //Check if there are any repeat values (will cause an error with the chart)
      for (const rr of rheometerReadings) {
        if (rheometerReadings.filter((r1) => r1.ShearRate === rr.ShearRate).length > 1) {
          //There are multiple values at the same reading
          return rheologyProps;
        }
      }

      if (rheometerReadings.filter((reading) => reading.ShearRate > 0 && reading.Viscosity > 0).length < 2) {
        //At least 2 points are needed to calculate without errors
        return rheologyProps;
      }
    }

    const logShearRate: number[] = [];
    const logViscosity: number[] = [];

    //Check if data is valid
    if (testType === RheometerTestType.Shear_Rate_Viscosity) {
      for (const rr of rheometerReadings.filter((reading) => reading.ShearRate > 0 && reading.Viscosity > 0)) {
        logShearRate.push(Math.log10(rr.ShearRate));
        logViscosity.push(Math.log10(rr.Viscosity));
      }
    } else {
      const shearRateConstant = RheometerConstants.GetShearRateConstant(rheometer.RotorBobCombination);
      const springFactor = RheometerConstants.GetTorsionSpringFactor(rheometer.TorsionSpringAssembly);
      const rotorBobFactor = RheometerConstants.GetRotorBobFactor(rheometer.RotorBobCombination);

      for (const rr of rheometerReadings.filter((reading) => reading.DialReading > 0 && reading.RPM > 0)) {
        logShearRate.push(Math.log10(rr.RPM * shearRateConstant));
        const speedFactor = 300 / rr.RPM;
        logViscosity.push(Math.log10((rr.DialReading * speedFactor * springFactor * rotorBobFactor) / 1000));
      }
    }

    if (logShearRate.length > 0) {
      const meanX = ArrayHelpers.average(logShearRate);
      const meanY = ArrayHelpers.average(logViscosity);

      const count = logShearRate.length;
      const sumX = ArrayHelpers.sum(logShearRate);
      const sumY = ArrayHelpers.sum(logViscosity);

      const sumXSquared = ArrayHelpers.sum(logShearRate.map((lsr) => GeneralCalculations.Pow(lsr, 2)));
      const sumYSquared = ArrayHelpers.sum(logViscosity.map((lv) => GeneralCalculations.Pow(lv, 2)));
      let sumXY = 0;
      let sumXYDiff = 0;
      let sumXDiffSquared = 0;
      let slope = 0;

      //Calculate the differences for summing
      for (let index = 0; index < logShearRate.length; index++) {
        //Calculate the difference in the x and y values to the mean
        const xDiff = logShearRate[index] - meanX;
        const yDiff = logViscosity[index] - meanY;

        //Calculate the sums
        sumXY += logShearRate[index] * logViscosity[index];
        sumXYDiff += xDiff * yDiff;
        sumXDiffSquared += GeneralCalculations.Pow(xDiff, 2);
      }

      if (sumXDiffSquared > 0) {
        slope = sumXYDiff / sumXDiffSquared;

        //calculate n' and k'
        rheologyProps.NPrime = slope + 1;
        rheologyProps.KPrime = GeneralCalculations.Pow(10, meanY - slope * meanX);

        //Calculate r2
        const term1 = count * sumXY - sumX * sumY;
        const term2 = Math.sqrt(count * sumXSquared - GeneralCalculations.Pow(sumX, 2));
        const term3 = Math.sqrt(count * sumYSquared - GeneralCalculations.Pow(sumY, 2));

        rheologyProps.RSquared = GeneralCalculations.Pow(term1 / (term2 * term3), 2);
        if (isNaN(rheologyProps.RSquared)) {
          rheologyProps.RSquared = 0;
        }
      }
    }

    return rheologyProps;
  }

  // Method to calculate the log10 value of the kprime
  public static Log10KPrime(rheology: RheologyDto): number {
    return Math.log10(rheology.KPrime);
  }

  // Method to calculate ki at a given viscosity
  public static KPrime(rheology: RheologyDto, shearRate: number): number {
    return shearRate <= 0 || rheology.Viscosity <= 0 ? 0 : rheology.Viscosity / GeneralCalculations.Pow(shearRate, rheology.NPrime - 1.0);
  }

  // Method to calculate the viscosity at a given shear rate
  public static ViscosityAtShearRate(rheology: RheologyDto, shearRate: number): number {
    if (shearRate <= 0) {
      return 0;
    }
    shearRate = Math.max(shearRate, 1.0);
    const viscosity = rheology.KPrime * GeneralCalculations.Pow(shearRate, rheology.NPrime - 1.0);
    return viscosity;
  }

  public static ViscosityAtShearRateForShuntTargetRheology(shearRate: number): number {
    if (shearRate <= 0) {
      return 0;
    }
    shearRate = Math.max(shearRate, 1.0);
    return 2.8627 * GeneralCalculations.Pow(shearRate, -0.767);
  }

  public static getViscosity(temperature: number, shearRate: number, rheologies: RheologyDto[] | Rheology[]): number {
    if (rheologies.length === 0) {
      return 0;
    }

    let nPrime = 0,
      kPrime = 0;
    if (temperature <= rheologies[0].Temperature) {
      nPrime = rheologies[0].NPrime;
      kPrime = rheologies[0].KPrime;
    } else if (temperature >= rheologies[rheologies.length - 1].Temperature) {
      nPrime = rheologies[rheologies.length - 1].NPrime;
      kPrime = rheologies[rheologies.length - 1].KPrime;
    } else {
      for (let i = 1; i < rheologies.length; i++) {
        if (temperature <= rheologies[i].Temperature) {
          nPrime = GeneralCalculations.LinearInterpolation(
            temperature,
            rheologies[i - 1].Temperature,
            rheologies[i].Temperature,
            rheologies[i - 1].NPrime,
            rheologies[i].NPrime,
          );
          kPrime = Math.pow(
            10,
            GeneralCalculations.LinearInterpolation(
              temperature,
              rheologies[i - 1].Temperature,
              rheologies[i].Temperature,
              Math.log10(rheologies[i - 1].KPrime),
              Math.log10(rheologies[i].KPrime),
            ),
          );
          break;
        }
      }
    }
    const shearRatePow = this.getShearRatePow(shearRate, nPrime - 1);
    return kPrime * shearRatePow;
  }

  private static getShearRatePow(shearRate: number, nPrime: number): number {
    try {
      return GeneralCalculations.Pow(shearRate, nPrime);
    } catch (e) {
      return 0;
    }
  }

  public static getViscosityAtTemperature(rheologies: RheologyDto[], temperature: number, shearRate: number): number {
    const rheology = this.interpolateRheology(temperature, rheologies);
    return this.ViscosityAtShearRate(rheology, shearRate);
  }

  public static interpolateRheology(temperature: number, rheologies: RheologyDto[]): RheologyDto {
    if (temperature <= rheologies[0].Temperature) {
      return rheologies[0];
    }
    if (temperature >= rheologies[rheologies.length - 1].Temperature) {
      return rheologies[rheologies.length - 1];
    }
    for (let i = 0; i < rheologies.length; i++) {
      if (rheologies[i + 1].Temperature === temperature) {
        return rheologies[i + 1];
      }
      if (rheologies[i + 1].Temperature > temperature) {
        const newRheology: RheologyDto = {
          Viscosity: 0,
          KPrime: 0,
          NPrime: 0,
          Temperature: 0,
          YieldStress: 0,
          FluidId: 0,
          Id: 0,
          RSquared: 0,
          ScenarioId: 0,
          SortOrder: 0,
        };

        newRheology.Temperature = temperature;

        newRheology.NPrime = GeneralCalculations.LinearInterpolation(
          temperature,
          rheologies[i].Temperature,
          rheologies[i + 1].Temperature,
          rheologies[i].NPrime,
          rheologies[i + 1].NPrime,
        );

        newRheology.KPrime = GeneralCalculations.Pow(
          10,
          GeneralCalculations.LinearInterpolation(
            temperature,
            rheologies[i].Temperature,
            rheologies[i + 1].Temperature,
            this.Log10KPrime(rheologies[i]),
            this.Log10KPrime(rheologies[i + 1]),
          ),
        );

        newRheology.Viscosity = newRheology.NPrime * newRheology.KPrime;

        return newRheology;
      }
    }
    return rheologies[0];
  }
}
