<div class="activity-overlay" data-cy="activity-overlay">
  <app-loader *ngIf="activityIndicator"></app-loader>
  <div class="message-container">
    <div class="title" data-cy="activity-overlay-message-title">
      {{ title }}
    </div>
    <div class="message-panel" *ngIf="message || isProgressVisible">
      <p-progressBar *ngIf="isProgressVisible" [value]="progress ? progress : 0"></p-progressBar>
      <div class="message" data-cy="activity-overlay-message-content">{{ message }}</div>
    </div>
  </div>
</div>
