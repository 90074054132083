import { ChangeDetectorRef, Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ChartSeriesTemplateDto } from '@dunefront/common/dto/chart-series-template.dto';
import { IGlobalSeriesStyles } from '@dunefront/common/modules/reporting/dto/chart-series.dto';
import { ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';
import {
  defaultModalCancelButton,
  defaultModalConfirmButton,
  modalButton,
  ModalButtonConfig,
} from '../../modals/generic-modal/generic-modal.component';
import { ConvertUnitPipe } from '@dunefront/common/modules/units/convert-unit.pipe/convert-unit.pipe';
import { IUnitSystemDto } from '@dunefront/common/dto/unit-system.dto';

@Component({
  selector: 'app-chart-series-props',
  template: ` <app-generic-modal [title]="modalTitle" [modalButtonsConfigs]="modalButtonsConfigs">
    <div>
      <app-chart-series-style [seriesTemplate]="chartSeriesTemplate" (valueChanged)="onValueChanged($event)"></app-chart-series-style>
    </div>
  </app-generic-modal>`,
})
export class ChartSeriesPropsComponent {
  public chartSeriesTemplate!: ChartSeriesTemplateDto;
  public modalButtonsConfigs: ModalButtonConfig[];
  public currentUnitSystem: IUnitSystemDto;

  constructor(
    public activeModal: DynamicDialogRef,
    config: DynamicDialogConfig,
    private cdRef: ChangeDetectorRef,
  ) {
    this.chartSeriesTemplate = config.data.chartSeriesTemplate;
    this.currentUnitSystem = config.data.currentUnitSystem;

    this.modalButtonsConfigs = [
      modalButton('Reset to default', () => this.onResetToDefault(), 'chart-series-reset', 'primary', this.chartSeriesTemplate.Id !== -1),
      defaultModalCancelButton(() => this.cancelClicked(), 'Cancel'),
      defaultModalConfirmButton(() => this.onConfirm(), 'Ok', 'chart-marker-dlg--ok-btn'),
    ];
  }

  public onValueChanged(event: ObjectChangeProp<ChartSeriesTemplateDto> | ObjectChangeProp<IGlobalSeriesStyles>): void {
    if (event.key in this.chartSeriesTemplate) {
      this.chartSeriesTemplate = { ...this.chartSeriesTemplate, [event.key]: event.value };
    }
    this.cdRef.markForCheck();
  }

  public onConfirm(): void {
    this.activeModal.close({ type: 'confirm', template: this.chartSeriesTemplate } as ChartSeriesProps);
  }

  public cancelClicked(): void {
    this.activeModal.close({ type: 'cancel' } as ChartSeriesProps);
  }

  public onResetToDefault(): void {
    this.activeModal.close({ type: 'resetDefault' } as ChartSeriesProps);
  }

  public get modalTitle(): string {
    const decodedSeriesName = ConvertUnitPipe.decode(this.chartSeriesTemplate.ColumnName, this.currentUnitSystem);
    return `Chart Series - ${decodedSeriesName}`;
  }
}

export interface ChartSeriesProps {
  type: 'confirm' | 'cancel' | 'resetDefault';
  template?: ChartSeriesTemplateDto;
}
