<p-multiSelect
        [options]="items"
        [appendTo]="'body'"
        [style]="{ width: componentWidth +'px' }"
        [(ngModel)]="selectedLabels"
        [disabled]="isDisabled"
        [attr.data-cy]="dataCy"
        scrollHeight="250px"
        optionValue="label"
        tooltipPosition="right"
        positionStyle="absolute"
        tooltipStyleClass="regular-tooltip"
        [pTooltip]="tooltipText"
        [class]="class"
        [placeholder]="'Select labels'"
        [escape]="false"
        (onChange)="onLabelsUpdated()"
        [resetFilterOnHide]="true"
>
</p-multiSelect>
