import { setOneTimeInstructionPopupShownAction, showOneTimeInstructionPopupAction } from './ui.actions';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { ModalCloseHandle, ModalService } from '../../common-modules/modals/modal.service';
import { filter, map, tap } from 'rxjs/operators';
import { getIsOneTimeInstructionShown } from './ui.selectors';
import { OneTimeMessageHelpers } from './one-time-message-helpers';
import { CommonDbModuleName } from '@dunefront/common/modules/common-db/common-db.actions';
import { BaseWsEffects } from '../base-ws.effects';
import { BackendConnectionService } from '../../shared/backend-connection/backend-connection.service';
import { backendRequestStartedAction, backendResponseReceivedAction } from '../backend-connection/backend-connection.actions';
import { UiActionTypes, UiModuleActions } from '@dunefront/common/modules/common.actions';

@Injectable()
export class UiEffects extends BaseWsEffects {
  private requestActivityOverlayModalHandleDict = new Map<string, ModalCloseHandle>();

  constructor(actions$: Actions, store: Store, modalService: ModalService, wsService: BackendConnectionService) {
    super(actions$, wsService, CommonDbModuleName, true, true, modalService, store);
  }

  public showInstructionPopup$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(showOneTimeInstructionPopupAction),
        concatLatestFrom(() => this.store.select(getIsOneTimeInstructionShown)),
        map(([action, instructionShownState]) => {
          const { instructionType } = action;
          if (OneTimeMessageHelpers.shouldShowInstruction(instructionType, instructionShownState)) {
            const message = OneTimeMessageHelpers.getMessage(instructionType);
            this.modalService.showAlert(message).then();
            this.store.dispatch(setOneTimeInstructionPopupShownAction({ instructionType: action.instructionType }));
          }
        }),
      ),
    { dispatch: false },
  );

  public backendRequestStartedAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(backendRequestStartedAction),
        tap((action) => {
          const config = action.activityOverlayConfig;
          if (config == null) {
            return;
          }
          if (!this.requestActivityOverlayModalHandleDict.has(config.taskId)) {
            this.requestActivityOverlayModalHandleDict.set(
              config.taskId,
              this.modalService.showActivityOverlay(
                config.title,
                config.message,
                config.taskId,
                config.displayDelayMs,
                config.minDisplayTimeMs,
                config.width,
              ),
            );
          }
        }),
      ),
    { dispatch: false },
  );

  public backendResponseReceivedAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(backendResponseReceivedAction),
        filter((action) => action.closeActivityOverlay),
        tap((action) => {
          const handle = this.requestActivityOverlayModalHandleDict.get(action.taskId);
          if (handle != null) {
            handle.close();
          }
          this.requestActivityOverlayModalHandleDict.delete(action.taskId);
        }),
      ),
    { dispatch: false },
  );

  protected override onIncomingMessage(action: UiModuleActions, fileHash: string | undefined): void {
    switch (action.type) {
      case UiActionTypes.UiActivityOverlayShow:
        this.store.dispatch(
          backendRequestStartedAction({
            taskId: action.taskId,
            activityOverlayConfig: action.activityOverlayConfig,
            actionType: action.actionType,
          }),
        );
        break;
      case UiActionTypes.UiActivityOverlayHide:
        this.store.dispatch(backendResponseReceivedAction({ taskId: action.taskId, closeActivityOverlay: true }));
    }
  }
}
