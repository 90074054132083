import { GaugeDataModuleState } from './gauge-data-module.state';
import { GaugeDataFactory } from './model/gauge-data.factory';
import { ImportColumnDto } from '@dunefront/common/modules/data-storage/dto/import-column.dto';
import { ImportDataDto } from '@dunefront/common/modules/data-storage/dto/import-data.dto';
import { LoadDataStorageActionResponse } from '@dunefront/common/modules/data-storage/data-storage-module.actions';
import { CrudResponse } from '@dunefront/common/modules/common.actions';
import { createTableState } from '@dunefront/common/common/common-grid.interfaces';
import { ActionResponse } from '@dunefront/common/response-ws.action';

export class GaugeDataReducerHelper {
  public static onLoadScenarioDataSuccessAction(
    state: GaugeDataModuleState,
    response: ActionResponse<LoadDataStorageActionResponse>,
  ): GaugeDataModuleState {
    return {
      ...state,
      SelectedFileId: response.payload?.importFileDtos[0]?.Id ?? -1,
    };
  }

  public static onloadGaugeDataSuccess(
    state: GaugeDataModuleState,
    data: ImportDataDto[],
    columns: ImportColumnDto[],
  ): GaugeDataModuleState {
    const gridColumns = GaugeDataFactory.getGridColumns(columns);
    const tableState = GaugeDataFactory.toTableState({ data, columns: columns });

    return { ...state, tableState, gridColumns, isLoaded: true, isLoading: false };
  }

  public static insertOrDeleteRowsSuccess(state: GaugeDataModuleState, response: CrudResponse): GaugeDataModuleState {
    const importFiles = response.affectedRows.importFiles;
    if (!importFiles) {
      return state;
    }

    const newInsertedRowId = importFiles.insertedRows?.[0]?.Id;
    const firstRow = importFiles.rows[0]?.Id;
    const SelectedFileId = newInsertedRowId ?? firstRow ?? -1;

    return { ...state, gridColumns: [], tableState: createTableState([]), SelectedFileId };
  }
}
