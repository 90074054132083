import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { GridConfig } from '../../../../shared/components/grid/grid-config';
import { ModalService } from '../../../../common-modules/modals/modal.service';
import { createTableState, ITableState } from '@dunefront/common/common/common-grid.interfaces';
import { GaugeDataModuleState, IGaugeDataRow } from '../../../../+store/gauge-data/gauge-data-module.state';
import { IScreenSize } from '../../../../shared/services';
import { GridContainerComponent } from '../../../../shared/components/grid/grid-container.component';
import { Store } from '@ngrx/store';
import { GridResizeService } from '../../../../shared/services/grid-resize.service';
import { IGridColumnConfig } from '../../../../shared/components/grid/grid.interfaces';

@Component({
  selector: 'app-gauge-data-grid',
  templateUrl: './gauge-data-grid.component.html',
  styleUrls: ['./gauge-data-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GaugeDateGridComponent extends GridContainerComponent<any> implements OnChanges, AfterViewInit {
  @Input()
  public state!: GaugeDataModuleState | null;

  @Output()
  public selectedCellIndexChanged = new EventEmitter<number>();

  @Input()
  public selectedCellIndex: number | undefined;

  @Input()
  public noValueColumn = true;

  public data: ITableState<IGaugeDataRow> = createTableState([]);

  public get showData(): boolean {
    return this.state?.isLoading === false && this.height > 0 && !this.noValueColumn && this.data.rows.length > 0;
  }

  public get showLoader(): boolean {
    return this.state == null || this.state.isLoading;
  }

  constructor(store: Store, modalService: ModalService, cdRef: ChangeDetectorRef, el: ElementRef, gridResizeService: GridResizeService) {
    super(store, cdRef, el, new GaugeDataGridConfig(modalService), gridResizeService);
    this.gridConfig.columns = [];
  }

  public override async ngOnChanges(changes: SimpleChanges): Promise<void> {
    await super.ngOnChanges(changes);
    if (this.state != null) {
      this.data = this.state.tableState;
      // needs to resize as Column Properties panel may show/hide which will break virtual scroll otherwise
      this.resize();
    }
  }

  public override resize(screenSize?: IScreenSize): void {
    setTimeout(() => {
      if (this.el != null) {
        this.height = this.el.nativeElement.clientHeight;
        this.cdRef.markForCheck();
        return;
      }
      this.resize();
    }, 10);
  }

  protected getColumns(): IGridColumnConfig<any>[] {
    return this.state?.gridColumns || [];
  }
}

export class GaugeDataGridConfig extends GridConfig<any> {}
