import { UnitConverter } from '../../converter.interfaces';
import { NoneUnit } from '../../../dto/unit-system.dto';

export class NoneConverter extends UnitConverter {
  public static symbols: Array<string> = ['None'];

  public static unit = NoneUnit;

  public static fromSi(value: number, unit: NoneUnit): number {
    return value;
  }

  public static toSi(value: number, unit: NoneUnit): number {
    return value;
  }

  public static getSymbol(unit: NoneUnit): string {
    return this.symbols[0];
  }
}
