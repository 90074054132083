import { BasePptSectionGenerator, PptContext } from '../base-ppt-section-generator';

import { IUnitSystemDto } from '@dunefront/common/dto/unit-system.dto';
import { firstValueFrom } from 'rxjs';
import { selectCurrentUnitSystem } from '../../../../../../+store/units/units.selectors';
import { WellModuleState } from '@dunefront/common/modules/well/well-module.state';
import { getWellModuleState } from '../../../../../../+store/well/well.selectors';
import { CommonReportDataHelpers } from '../../common/common-report-data-helpers';
import { PptDocumentTableGeneratorHelper } from '../ppt-document-table-generator.helper';
import { wellGeneralGridConfig } from '../../common/grid-configs/well-general';
import { IWellGeneralData } from '../../doc/sections/well-report-generator.helper';

export class GeneralDataPptGenerator extends BasePptSectionGenerator {
  constructor(context: PptContext) {
    super(context);
  }

  public async generate(): Promise<void> {
    const title = 'Well - General Data';
    const currentUnitSystem: IUnitSystemDto = await firstValueFrom(this.store.select(selectCurrentUnitSystem));
    const wellModuleState: WellModuleState = await firstValueFrom(this.store.select(getWellModuleState));
    const { keyValueData, enumItemMap } = CommonReportDataHelpers.createGeneralData(wellModuleState);

    const tableRows = PptDocumentTableGeneratorHelper.createGenericKeyValueTable<IWellGeneralData>(
      wellGeneralGridConfig,
      keyValueData,
      currentUnitSystem,
      enumItemMap,
    );

    this.addKeyValueTableSlide({ title, tableRows });
  }
}
