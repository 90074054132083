import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { DbDependentComponent } from '../../../common-modules/db-connection/db-dependent.component';
import { IPSDModuleValid } from '../../../+store/psd-analysis/psd-analysis.selectors';
import { Store } from '@ngrx/store';
import { ModalService } from '../../../common-modules/modals/modal.service';
import * as uiActions from '../../../+store/ui/ui.actions';

@Component({
  selector: 'app-psd-input',
  templateUrl: './psd-input.component.html',
  styleUrls: ['./psd-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PsdInputComponent extends DbDependentComponent {
  public isPSDModuleValid!: IPSDModuleValid;

  constructor(
    store: Store,
    cdRef: ChangeDetectorRef,
    protected modalService: ModalService,
  ) {
    super(store, cdRef);
    this.onHelpChange('psd', 'psd-analysis');
    store.dispatch(uiActions.setAppSectionAction({ section: 'psd-input' }));
  }
}
