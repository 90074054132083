import { FrictionCalculatorDto, FrictionCalculatorResultDto, FrictionXAxis } from './friction-calculator.dto';
import { FlowPathType } from '../resuspension-calculator/resuspension-calculator.dto';
import { IModuleState, IValidatedModuleState } from '../../../common/common-state.interfaces';

export interface FrictionCalculatorModuleState extends IModuleState {
  FrictionCalculator: FrictionCalculatorDto;
  FrictionCalculatorResult: FrictionCalculatorResultDto[];
}

export const FrictionCalculatorInitialState: FrictionCalculatorModuleState = {
  FrictionCalculator: {
    Concentration: 0,
    Eccentricity: 0,
    FlowPathType: FlowPathType.Pipe,
    FluidId: -1,
    GravelId: -1,
    Height: 0,
    IsXAxisRate: FrictionXAxis.Velocity,
    Length: 0,
    MaxDiameter: 0,
    MaxRate: 0,
    MinDiameter: 0,
    RateStep: 0,
    Roughness: 0,
    ScenarioId: -1,
    Temperature: 0,
    UseShuntVESFrictionCorrelations: false,
    UseDegradationFactor: true,
    Width: 0,
  },
  FrictionCalculatorResult: [],
  isLoaded: false,
};

export interface ValidatedFrictionCalculatorModuleState
  extends FrictionCalculatorModuleState,
    IValidatedModuleState<FrictionCalculatorModuleState> {
  FrictionCalculator: FrictionCalculatorDto & IValidatedModuleState<FrictionCalculatorDto>;
}

export type ValidatedFrictionCalculator = FrictionCalculatorDto & IValidatedModuleState<FrictionCalculatorDto>;
