<button
  type="button"
  (click)="onHelpClick(undefined)"
  class="toggle-button toggle-button-display help-button"
  [class.toggle-button-open]="isHelpOpen"
  data-cy="modal-help-btn"
  [ngClass]="{ 'toggle-button-open': isHelpOpen, hasScrollbar: dialogHasScrollbar }"
>
  <ng-container>
    <span class="icon-left-triangle" [class.right-triangle]="isHelpOpen"></span>
  </ng-container>

  <div class="label">Help</div>
  <div class="question-mark-container">
    <span class="icon-question-mark"></span>
  </div>
</button>
