export enum SeriesLineStyle {
  solid = 0,
  dots = 1,
  dashes = 2,
  scatter = 3,
}

export enum LineStyle {
  solid = 0,
  dots = 1,
  dashes = 2,
}

export enum HorizontalAlignment {
  start = 0,
  center = 1,
  end = 2,
}

export enum VerticalAlignment {
  start = 0,
  center = 1,
  end = 2,
}

export enum Color {
  Black = 'Black',
  DimGray = 'DimGray',
  Gray = 'Gray',
  DarkGray = 'DarkGray',
  Silver = 'Silver',
  LightGray = 'LightGray',
  Gainsboro = 'Gainsboro',
  WhiteSmoke = 'WhiteSmoke',
  White = 'White',
  RosyBrown = 'RosyBrown',
  IndianRed = 'IndianRed',
  Brown = 'Brown',
  Firebrick = 'Firebrick',
  LightCoral = 'LightCoral',
  Maroon = 'Maroon',
  DarkRed = 'DarkRed',
  Red = 'Red',
  Snow = 'Snow',
  MistyRose = 'MistyRose',
  Salmon = 'Salmon',
  Tomato = 'Tomato',
  DarkSalmon = 'DarkSalmon',
  Coral = 'Coral',
  OrangeRed = 'OrangeRed',
  LightSalmon = 'LightSalmon',
  Sienna = 'Sienna',
  SeaShell = 'SeaShell',
  Chocolate = 'Chocolate',
  SaddleBrown = 'SaddleBrown',
  SandyBrown = 'SandyBrown',
  PeachPuff = 'PeachPuff',
  Peru = 'Peru',
  Linen = 'Linen',
  Bisque = 'Bisque',
  DarkOrange = 'DarkOrange',
  BurlyWood = 'BurlyWood',
  Tan = 'Tan',
  AntiqueWhite = 'AntiqueWhite',
  NavajoWhite = 'NavajoWhite',
  BlanchedAlmond = 'BlanchedAlmond',
  PapayaWhip = 'PapayaWhip',
  Moccasin = 'Moccasin',
  Orange = 'Orange',
  Wheat = 'Wheat',
  OldLace = 'OldLace',
  FloralWhite = 'FloralWhite',
  DarkGoldenrod = 'DarkGoldenrod',
  Goldenrod = 'Goldenrod',
  Cornsilk = 'Cornsilk',
  Gold = 'Gold',
  Khaki = 'Khaki',
  LemonChiffon = 'LemonChiffon',
  PaleGoldenrod = 'PaleGoldenrod',
  DarkKhaki = 'DarkKhaki',
  Beige = 'Beige',
  LightGoldenrodYellow = 'LightGoldenrodYellow',
  Olive = 'Olive',
  Yellow = 'Yellow',
  LightYellow = 'LightYellow',
  Ivory = 'Ivory',
  OliveDrab = 'OliveDrab',
  YellowGreen = 'YellowGreen',
  DarkOliveGreen = 'DarkOliveGreen',
  GreenYellow = 'GreenYellow',
  Chartreuse = 'Chartreuse',
  LawnGreen = 'LawnGreen',
  DarkSeaGreen = 'DarkSeaGreen',
  ForestGreen = 'ForestGreen',
  LimeGreen = 'LimeGreen',
  LightGreen = 'LightGreen',
  PaleGreen = 'PaleGreen',
  DarkGreen = 'DarkGreen',
  Green = 'Green',
  Lime = 'Lime',
  Honeydew = 'Honeydew',
  SeaGreen = 'SeaGreen',
  MediumSeaGreen = 'MediumSeaGreen',
  SpringGreen = 'SpringGreen',
  MintCream = 'MintCream',
  MediumSpringGreen = 'MediumSpringGreen',
  MediumAquamarine = 'MediumAquamarine',
  Aquamarine = 'Aquamarine',
  Turquoise = 'Turquoise',
  LightSeaGreen = 'LightSeaGreen',
  MediumTurquoise = 'MediumTurquoise',
  DarkSlateGrey = 'DarkSlateGrey',
  PaleTurquoise = 'PaleTurquoise',
  Teal = 'Teal',
  DarkCyan = 'DarkCyan',
  Aqua = 'Aqua',
  Cyan = 'Cyan',
  LightCyan = 'LightCyan',
  Azure = 'Azure',
  DarkTurquoise = 'DarkTurquoise',
  CadetBlue = 'CadetBlue',
  PowderBlue = 'PowderBlue',
  LightBlue = 'LightBlue',
  DeepSkyBlue = 'DeepSkyBlue',
  SkyBlue = 'SkyBlue',
  LightSkyBlue = 'LightSkyBlue',
  SteelBlue = 'SteelBlue',
  AliceBlue = 'AliceBlue',
  DodgerBlue = 'DodgerBlue',
  SlateGrey = 'SlateGrey',
  LightSlateGrey = 'LightSlateGrey',
  LightSteelBlue = 'LightSteelBlue',
  CornflowerBlue = 'CornflowerBlue',
  RoyalBlue = 'RoyalBlue',
  MidnightBlue = 'MidnightBlue',
  Lavender = 'Lavender',
  Navy = 'Navy',
  DarkBlue = 'DarkBlue',
  MediumBlue = 'MediumBlue',
  Blue = 'Blue',
  GhostWhite = 'GhostWhite',
  SlateBlue = 'SlateBlue',
  DarkSlateBlue = 'DarkSlateBlue',
  MediumSlateBlue = 'MediumSlateBlue',
  MediumPurple = 'MediumPurple',
  BlueViolet = 'BlueViolet',
  Indigo = 'Indigo',
  DarkOrchid = 'DarkOrchid',
  DarkViolet = 'DarkViolet',
  MediumOrchid = 'MediumOrchid',
  Thistle = 'Thistle',
  Plum = 'Plum',
  Violet = 'Violet',
  Purple = 'Purple',
  DarkMagenta = 'DarkMagenta',
  Magenta = 'Magenta',
  Fuchsia = 'Fuchsia',
  Orchid = 'Orchid',
  MediumVioletRed = 'MediumVioletRed',
  DeepPink = 'DeepPink',
  HotPink = 'HotPink',
  LavenderBlush = 'LavenderBlush',
  PaleVioletRed = 'PaleVioletRed',
  Crimson = 'Crimson',
  Pink = 'Pink',
  LightPink = 'LightPink',
}

export interface ILineStyleProperties {
  LineType: LineStyle;
  LineThickness: number;
  LineColor: Color;
}
