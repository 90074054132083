import { IReportTableColumnConfig } from '../common-report-data-helpers';
import { IUnitSystemDto, UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { ColumnType } from '../../../../../components/grid/grid.interfaces';
import { PSDDto, PSDResultDto } from '@dunefront/common/modules/psd-analysis/dto/psd.dto';
import { ConvertUnitPipe } from '@dunefront/common/modules/units/convert-unit.pipe/convert-unit.pipe';
import { PSDValidation } from '@dunefront/common/modules/psd-analysis/model/psd/psd.validation';
import { PsdSandProductionInputs } from '@dunefront/common/modules/psd-analysis/dto/psd-sand-production.inputs';

export const psdDefinition = (): IReportTableColumnConfig<PSDDto>[] => [
  { colId: 'Description', headerText: 'PSD Name', unitSystem: UnitSystem.None, type: ColumnType.text },
  {
    colId: 'Depth',
    headerText: 'PSD Depth',
    unitSystem: UnitSystem.Long_Length,
    type: ColumnType.number,
    decimalPlaces: 2,
  },
];

export const getPsdPropertiesGridConfig = (
  currentUnitSystem: IUnitSystemDto,
  convertUnitPipe: ConvertUnitPipe,
): IReportTableColumnConfig<PSDResultDto>[] => [
  { colId: 'D10', headerText: 'd10', unitSystem: UnitSystem.Screen_Opening, type: ColumnType.number, decimalPlaces: 3 },
  { colId: 'D40', headerText: 'd40', unitSystem: UnitSystem.Screen_Opening, type: ColumnType.number, decimalPlaces: 3 },
  { colId: 'D50', headerText: 'd50', unitSystem: UnitSystem.Screen_Opening, type: ColumnType.number, decimalPlaces: 3 },
  { colId: 'D90', headerText: 'd90', unitSystem: UnitSystem.Screen_Opening, type: ColumnType.number, decimalPlaces: 3 },
  { colId: 'D95', headerText: 'd95', unitSystem: UnitSystem.Screen_Opening, type: ColumnType.number, decimalPlaces: 3 },
  {
    colId: 'PercentFines',
    headerText: PSDValidation.getPercentFinesLabel(currentUnitSystem, convertUnitPipe),
    unitSystem: UnitSystem.None,
    type: ColumnType.number,
    decimalPlaces: 2,
    overrideUnitSymbol: '%',
  },
  {
    colId: 'UniformityCoefficient',
    headerText: 'Uniformity Coefficient',
    unitSystem: UnitSystem.None,
    type: ColumnType.number,
    decimalPlaces: 2,
  },
  {
    colId: 'SortingCoefficient',
    headerText: 'Sorting Coefficient',
    unitSystem: UnitSystem.None,
    type: ColumnType.number,
    decimalPlaces: 2,
  },
];

export const psdSandProduction = (): IReportTableColumnConfig<PsdSandProductionInputs>[] => [
  {
    colId: 'IsCalculateSandProduction',
    headerText: 'Calculate Sand Production through Wire-Wrap Screens',
    unitSystem: UnitSystem.None,
    type: ColumnType.boolean,
  },
  {
    colId: 'SandDensity',
    headerText: 'Sand Density',
    unitSystem: UnitSystem.Solid_Density,
    type: ColumnType.number,
    decimalPlaces: 2,
  },
  {
    colId: 'IntervalLength',
    headerText: 'Interval Length',
    unitSystem: UnitSystem.Long_Length,
    type: ColumnType.number,
    decimalPlaces: 2,
  },
  {
    colId: 'ScreenSlotSize',
    headerText: 'Screen Slot Size',
    unitSystem: UnitSystem.Screen_Opening,
    type: ColumnType.number,
    decimalPlaces: 3,
  },
  {
    colId: 'ScreenWireWidth',
    headerText: 'Screen Wire Width',
    unitSystem: UnitSystem.Screen_Opening,
    type: ColumnType.number,
    decimalPlaces: 3,
  },
  {
    colId: 'ScreenJointLength',
    headerText: 'Screen Joint Length',
    unitSystem: UnitSystem.Long_Length,
    type: ColumnType.number,
    decimalPlaces: 2,
  },
  {
    colId: 'ScreenHandlingLength',
    headerText: 'Screen Handling Length',
    unitSystem: UnitSystem.Long_Length,
    type: ColumnType.number,
    decimalPlaces: 2,
  },
  {
    colId: 'ScreenFilterOD',
    headerText: 'Screen Filter OD',
    unitSystem: UnitSystem.Diameter,
    type: ColumnType.number,
    decimalPlaces: 2,
  },
];
