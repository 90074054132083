import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputMaskModule } from 'primeng/inputmask';
import { CalendarModule } from 'primeng/calendar';
import { FormsModule } from '@angular/forms';
import { TooltipModule } from 'primeng/tooltip';
import dayjs from 'dayjs';
import { getIsUiLocked } from '../../../../+store/ui/calc-engine-ui.selectors';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { PrimitiveChangeValue } from '@dunefront/common/common/common-state.interfaces';

@Component({
  selector: 'app-date-time-input',
  templateUrl: './date-time-input.component.html',
  styleUrls: ['./date-time-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateTimeInputComponent implements OnChanges, OnInit, OnDestroy {
  @Input() public showTimePicker = false;
  @Input() public inputWidth: number | undefined;

  @Output() public primitiveValueChanged = new EventEmitter<PrimitiveChangeValue<Date>>();
  @Output() public isValid = new EventEmitter<boolean>();

  @Input() public isUiLockable = true;

  @Input()
  public set errorMessage(error: string | undefined) {
    this._errorMessage = error;
  }

  public get errorMessage(): string {
    return (this._localErrorMessage || this._errorMessage) ?? '';
  }

  @Input()
  public value: Date | undefined;
  private _date: Date | undefined;
  private _localErrorMessage: string | undefined;
  private _dateWithoutSec: Date | null = null;
  private _errorMessage: string | undefined;
  public isReadOnlyMode = false;
  protected subscription = new Subscription();

  constructor(
    private store: Store,
    private cdRef: ChangeDetectorRef,
  ) {
    this._date = new Date();
  }

  public get dateCalendar(): Date | undefined {
    return this._date;
  }

  public set dateCalendar(val: Date | undefined) {
    this._date = val;
    if (val == null) {
      this._localErrorMessage = 'Invalid date or time';
      this.isValid.emit(false);
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.value != null && this.value != null && changes.value.firstChange !== changes.value.currentValue) {
      this._date = this.value;
    }
  }

  public ngOnInit(): void {
    this.subscription.add(
      this.store.select(getIsUiLocked).subscribe((isUiLocked) => {
        this.isReadOnlyMode = isUiLocked && this.isUiLockable;
        this.cdRef.markForCheck();
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public emitChange(): void {
    if (this._date == null && this._dateWithoutSec != undefined) {
      this._date = this._dateWithoutSec;
    }
    this._localErrorMessage = '';
    this.isValid.emit(true);
    if (this._date != null) {
      this.primitiveValueChanged.emit({ value: this._date, shouldResetResults: this.isUiLockable });
    } else {
      this._date = this.value ?? new Date();
    }
  }

  public get yearRange(): string {
    const currYear = new Date().getFullYear();
    return currYear - 10 + ':' + (currYear + 5);
  }

  public onDateUpdated(e: any): void {
    const dateWithoutSec = dayjs(e.target.value, 'DD-MM-YYYY HH:mm');
    this._dateWithoutSec = dateWithoutSec.isValid() ? dateWithoutSec.toDate() : null;
  }
}

@NgModule({
  imports: [CommonModule, FormsModule, InputMaskModule, CalendarModule, TooltipModule],
  declarations: [DateTimeInputComponent],
  exports: [DateTimeInputComponent],
})
export class DateTimeInputModule {}
