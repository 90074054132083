import { AvailableAppVersion } from '@dunefront/common/dto/update.dto';

export const cmpVersions = (v1: string, v2: string): number => {
  let i, diff;
  const regExStrip0 = /(\.0+)+$/;

  const segmentsA = v1.replace(regExStrip0, '').split('.');
  const segmentsB = v2.replace(regExStrip0, '').split('.');
  const l = Math.min(segmentsA.length, segmentsB.length);

  for (i = 0; i < l; i++) {
    diff = parseInt(segmentsA[i], 10) - parseInt(segmentsB[i], 10);
    if (diff) {
      return diff;
    }
  }
  return segmentsA.length - segmentsB.length;
};

export const getLatestAppVersion = (array: AvailableAppVersion[]): AvailableAppVersion =>
  array.sort((a, b) => cmpVersions(a.version, b.version)).reverse()[0];

export const isMoreThanDayDiff = (timestamp: number | null): boolean => {
  const dayInMS = 1000 * 60 * 60 * 24;

  if (timestamp == null || timestamp == 0) {
    return true;
  }

  return new Date().getTime() - timestamp >= dayInMS;
};
