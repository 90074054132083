import { FormsModule } from '@angular/forms';
import { GridModule } from '../../../shared/components/grid/grid.module';
import { PagePanelModule } from '../../../common-modules/page-panel/page-panel.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TabbedPageModule } from '../../../common-modules/tabbed-page/tabbed-page.component';
import { UnitsModule } from '../../../common-modules/units/units.module';
import { GravelPropertiesComponent } from './project-gravels/gravel-properties/gravel-properties.component';
import { FormInputModule } from '../../../shared/components/form-components/form-input/form-input.component';
import { UnwrapDirectiveModule } from '../../../shared/directives/unwrap.directive';
import { DatabaseGravelsComponent } from './database-gravels/database-gravels.component';
import { DatabaseGravelsGridComponent } from './database-gravels/database-gravels-grid/database-gravels-grid.component';
import { ProjectGravelsComponent } from './project-gravels/project-gravels.component';
import { GravelSelectorModule } from '../gravel-selector/gravel-selector.module';
import { SelectModule } from '../../../shared/components/form-components/select/select.module';
import { ButtonModule } from '../../../shared/components/button/button.module';
import { SearchbarModule } from '../../../shared/components/searchbar/searchbar.component';
import { TooltipModule } from 'primeng/tooltip';
import { GravelAdvancedPropertiesComponent } from './gravel-advanced-properties/gravel-advanced-properties.component';
import { CheckBoxModule } from '../../../shared/components/form-components/check-box/check-box.component';
import { GravelHeaderComponent } from './common/gravel-header/gravel-header.component';

@NgModule({
  declarations: [
    GravelHeaderComponent,
    ProjectGravelsComponent,
    GravelAdvancedPropertiesComponent,
    GravelPropertiesComponent,
    DatabaseGravelsComponent,
    DatabaseGravelsGridComponent,
  ],
  imports: [
    GravelSelectorModule,
    CommonModule,
    TabbedPageModule,
    UnitsModule,
    FormsModule,
    GridModule,
    ButtonModule,
    SelectModule,
    PagePanelModule,
    FormInputModule,
    CheckBoxModule,
    UnwrapDirectiveModule,
    SearchbarModule,
    TooltipModule,
  ],
})
export class GravelModule {}
