import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { ShuntTube } from '@dunefront/common/modules/shunt-tube/shunt-tube';
import { ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';

@Component({
  selector: 'app-packing-factor',
  templateUrl: './packing-factor.component.html',
  styleUrls: ['./packing-factor.component.scss'],
})
export class PackingFactorPropertiesComponent {
  @Input() public shuntTube!: ShuntTube;

  @Output() public shuntTubeValueChange = new EventEmitter<ObjectChangeProp<ShuntTube>>();

  public UnitType = UnitSystem;

  public onValueChange(props: ObjectChangeProp<ShuntTube>): void {
    this.shuntTubeValueChange.emit(props);
  }
}
