import { IPumpingValidationDependencies, PumpingStateByRange, ValidatedPumpingModuleState } from '../pumping-module.state';
import { PumpingInitialDataValidation } from './initial-data/pumping-initial-data.validation';
import { WellFluidValidation } from './well-fluid/well-fluid.validation';
import { PumpingScheduleValidation } from './pumping-schedule/pumping-schedule.validation';
import { ScheduleOptionsValidation } from './schedule-options/schedule-options.validation';
import { ITableState } from '../../../common/common-grid.interfaces';
import { PumpingSchedule } from './pumping-schedule/pumping-schedule';

export class PumpingStateValidation {
  public static validate(
    state: PumpingStateByRange,
    pumpingSchedule: ITableState<PumpingSchedule>,
    evaluationSchedule: ITableState<PumpingSchedule>,
    deps: IPumpingValidationDependencies,
  ): ValidatedPumpingModuleState {
    const newValidatedPumpingModuleState: ValidatedPumpingModuleState = {
      ...state,
      pumpingSchedule,
      evaluationSchedule,
      isValid: true,
      isWellFluidsScreenValid: true,
      isInitialScreenValid: true,
      isScheduleScreenValid: true,
      isAutomaticSchedulingPanelValid: true,
      isScheduleGeneratorPanelValid: true,
      error: {},
    };
    if (!state.isLoaded) {
      return newValidatedPumpingModuleState;
    }
    let newPumpingState = { ...newValidatedPumpingModuleState };

    newPumpingState = PumpingInitialDataValidation.validatePumpingInitialDataTab(newPumpingState, deps);
    newPumpingState = WellFluidValidation.validateWellFluidsTab(newPumpingState, deps);
    newPumpingState = PumpingScheduleValidation.validatePumpingScheduleTab(newPumpingState, pumpingSchedule, deps);
    newPumpingState = ScheduleOptionsValidation.validateScheduleOptionsTab(newPumpingState, deps);

    const isValid =
      newPumpingState.isInitialScreenValid &&
      newPumpingState.isAutomaticSchedulingPanelValid &&
      newPumpingState.isScheduleScreenValid &&
      newPumpingState.isWellFluidsScreenValid;

    return { ...newPumpingState, isValid, error: {} };
  }
}
