import { DrawableImageProvider } from '../../shared/services/drawable-registry.service';
import { ModalService } from '../modals/modal.service';

export interface ImageSize {
  width: number;
  height: number;
}

export interface Base64Image {
  size: ImageSize;
  imageDataBase64: string;
}

export interface Base64Media extends Base64Image {
  videoDataBase64?: string;
}

export function copyBase64ToClipboard(base64Image: string): Promise<boolean> {
  return new Promise((res) => {
    const img = new Image();
    const c = document.createElement('canvas');
    const ctx = c.getContext('2d');

    img.onload = (): void => {
      c.width = img.naturalWidth;
      c.height = img.naturalHeight;
      ctx && ctx.drawImage(img, 0, 0);
      c.toBlob((imgBlob) => {
        if (imgBlob != null) {
          navigator.clipboard
            .write([new ClipboardItem({ 'image/png': imgBlob })])
            .then(() => res(true))
            .catch(() => res(false));
        } else {
          res(false);
        }
      }, 'image/png');
    };
    img.src = base64Image;
  });
}

export async function copyImageToClipboard(provider: DrawableImageProvider, modalService: ModalService): Promise<void> {
  const imageName = provider.getDisplayName() ?? provider.id;

  const base64Image = await provider.getBase64Image();
  const copySuccess = base64Image != null && (await copyBase64ToClipboard(base64Image.imageDataBase64));
  const resultString = copySuccess ? 'successful' : 'unsuccessful';

  modalService.showAlert(`Copy ${imageName} was ${resultString}.`, 'Information').then();
}
