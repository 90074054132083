export interface WellFluidDto {
  Id: number;
  Volume: number;
  FluidId: number | null;
  GravelId: number;
  GravelConcentration: number;
  FlowPathDescription: FlowPathDescription;
  IsPacked: boolean;
  TopMD: number;
  BottomMD: number;
  SortOrder: number;
  ScenarioId: number;
  RangeId: number;
}

export enum FlowPathDescription {
  Workstring = 0,
  Upper_Annulus = 1,
  Washpipe = 2,
  Lower_Annulus = 3,
}

export const createEmptyWellFluidDto = (
  scenarioId: number,
  rangeId: number,
  flowPathDescription: FlowPathDescription,
  topMD = 0,
  bottomMd = 0,
): WellFluidDto => ({
  Id: -1,
  ScenarioId: scenarioId,
  RangeId: rangeId,
  TopMD: topMD,
  BottomMD: bottomMd,
  Volume: 0,
  FluidId: null,
  GravelId: 0,
  GravelConcentration: 0,
  IsPacked: false,
  SortOrder: -1,
  FlowPathDescription: flowPathDescription,
});
