import { UnitConverter } from '../../converter.interfaces';
import { FluidConcentrationUnit } from '../../../dto/unit-system.dto';

export class FluidConcentrationConverter extends UnitConverter {
  public static symbols: Array<string> = ['', '%'];

  public static unit = FluidConcentrationUnit;

  public static fromSi(value: number, unit: FluidConcentrationUnit): number {
    return value;
  }

  public static toSi(value: number, unit: FluidConcentrationUnit): number {
    return value;
  }

  public static getSymbol(unit: FluidConcentrationUnit): string {
    return this.symbols[unit];
  }
}
