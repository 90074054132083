import { ChangeDetectionStrategy, Component, Inject, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { ScreenService } from '@dunefront/client-common/shared/services';
import { BaseAppComponent } from '@dunefront/client-common/shared/base-app-component/base-app-component';
import { environment } from '../environments/environment';
import { ModalService } from '@dunefront/client-common/common-modules/modals/modal.service';
import { Actions } from '@ngrx/effects';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { ElectronService } from '@dunefront/client-common/shared/services/electron-service/electron.service';
import { RouterHelperService } from '@dunefront/client-common/shared/services/router-helper.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent extends BaseAppComponent {
  constructor(
    actions$: Actions,
    store: Store,
    electronService: ElectronService,
    screen: ScreenService,
    modalService: ModalService,
    router: Router,
    routerHelper: RouterHelperService,
    @Inject(DOCUMENT) document: Document,
  ) {
    super(actions$, store, electronService, screen, modalService, router, routerHelper);
    this.environment = environment;
    this.themeName = 'theme-pack-pro';
  }
}
