import { CasingPipeDto } from '../../well/dto/casing/casing-pipe.dto';
import { LowerCompletionPipeDtoWithICDPorts } from '../../../dto/lower-completion-pipe.dto';
import { RunningStringPipeDto } from '../../../dto/running-string-pipe.dto';
import { CaliperTransferArray } from '../../well/dto/caliper/caliper.dto';
import { ChokeSetting, PumpingDirection, ToolPosition, VariableChokeSetting } from '../../pumping/dto/pumping.dto';
import { ShuntTubeDto } from '../../../dto/shunt-tube.dto';
import { ImportFileForAdvancedValidation } from '../../data-storage/dto/import-file-with-columns';
import { IValidatedSimulateEvaluateState } from '../validation-helpers';
import { getRowsForCalculations } from '../../../common/common-grid.interfaces';
import { SurveyFactory } from '../../well/model/survey/survey.factory';
import { CasingPipesFactory } from '../../pipes/casing-pipes/casing-pipes.factory';
import { CaliperFactory } from '../../well/model/caliper/caliper.factory';
import { LowerCompletionPipesFactory } from '../../pipes/lower-completion-pipes/lower-completion-pipes.factory';
import { ShuntTubeFactory } from '../../shunt-tube/shunt-tube.factory';
import { RunningStringPipesFactory } from '../../pipes/running-string-pipes/running-string-pipes.factory';
import { AnalysisDataSelectedColumnNames } from '../../../dto/scenario-range-properties.dto';

export interface SimulateAdvancedValidationJobInputData {
  CasingData: CasingPipeDto[];
  LowerCompletion: LowerCompletionPipeDtoWithICDPorts[];
  RunningString: RunningStringPipeDto[];
  CalculatedCaliperData: CaliperTransferArray;
  ToolPosition: ToolPosition;
  PumpingDirection: PumpingDirection;
  MaxSurveyDeviation: number;
  IsZoneDepthByMD: boolean;
  IsDiameterValidationDisabled: boolean;
  IsModelScreenHandlingLength: boolean;
  GlobalTolerance: number;
  ShuntTube: ShuntTubeDto;
  VariableChoke: VariableChokeSetting;
  ChokePosition: ChokeSetting;
}

export interface TrendAnalysisAdvancedValidationJobInputData {
  ImportFiles: ImportFileForAdvancedValidation[];
  AnalysisDataSelectedColumnNames: AnalysisDataSelectedColumnNames;
}

export interface AdvancedValidationJobInputData {
  SimulateInputData: SimulateAdvancedValidationJobInputData | null;
  TrendAnalysisInputData: TrendAnalysisAdvancedValidationJobInputData | null;
}

export class AdvancedValidationJobInputDataFactory {
  public static createSimulateAdvancedValidationJobInputData(
    state: IValidatedSimulateEvaluateState,
  ): SimulateAdvancedValidationJobInputData {
    const Survey = getRowsForCalculations(state.wellState.SurveyData.rows).map((survey) => SurveyFactory.toDto(survey));
    const CasingData = getRowsForCalculations(state.wellState.CasingData.rows).map((pipe) => CasingPipesFactory.toDto(pipe));
    const caliperDtos = getRowsForCalculations(state.wellState.CaliperData.rows).map((caliper) => CaliperFactory.toDto(caliper));
    const CalculatedCaliperData = CaliperFactory.toCalculatedCaliperTransferArray(caliperDtos, state.wellState.CaliperTolerance);

    const LowerCompletion = getRowsForCalculations(state.completionState.LowerCompletion.rows).map((pipe) =>
      LowerCompletionPipesFactory.toDtoWithIcdPorts(pipe),
    );
    const RunningString = getRowsForCalculations(state.completionState.RunningString.rows).map((pipe) =>
      RunningStringPipesFactory.toDto(pipe),
    );

    const pumping = state.pumpingState.pumping;

    return {
      CasingData,
      CalculatedCaliperData,
      LowerCompletion,
      RunningString,
      ToolPosition: pumping.ToolPosition,
      PumpingDirection: pumping.PumpingDirection,
      IsDiameterValidationDisabled: state.settings.IsDiameterValidationDisabled,
      GlobalTolerance: state.developerSettings.GlobalTolerance,
      IsModelScreenHandlingLength: state.settings.ModelScreenHandlingLength,
      IsZoneDepthByMD: state.wellState.IsZoneDepthByMD,
      MaxSurveyDeviation: Math.max(...Survey.map((s) => s.Deviation)),
      ShuntTube: ShuntTubeFactory.toDto(state.completionState.ShuntTube),
      VariableChoke: pumping.VariableChoke,
      ChokePosition: pumping.ChokePosition,
    };
  }
}
