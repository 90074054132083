import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { BaseChartConfigurationComponent } from '../../../../common-modules/chart/chart-configuration/base-chart-configuration.component';
import { Store } from '@ngrx/store';
import { ModalService } from '../../../../common-modules/modals/modal.service';
import { ScreenService } from '../../../../shared/services';
import {
  ChartUpdateType,
  saveChartConfigDialog,
  setChartResultColumns,
  setGaugeDataRequestedAfterFileImport,
} from '../../../../+store/reporting/reporting.actions';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MenuItem } from 'primeng/api';
import { ScenarioConstants } from '@dunefront/common/modules/scenario/scenario.dto';

const templates = {
  default: 'Default',
  pressureOnly: 'Pressure Only',
  surfacePressureOnly: 'Surface pressure only',
  bottomHolePressureOnly: 'Bottomhole pressure only',
  rateOnly: 'Rate Only',
};

@Component({
  templateUrl: './gauge-data-chart-configuration.component.html',
  styleUrls: ['./gauge-data-chart-configuration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-gauge-data-chart-configuration-dialog',
})
export class GaugeDataChartConfigurationComponent extends BaseChartConfigurationComponent {
  public gaugeDataRequestedAfterFileImport: boolean | undefined = false;
  public templatesList: Array<MenuItem> = [];
  public isInitialConfiguration: boolean;

  constructor(
    store: Store,
    modalService: ModalService,
    resizeService: ScreenService,
    cdRef: ChangeDetectorRef,
    ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
  ) {
    super(ref, store, cdRef, modalService, resizeService);

    this.isInitialConfiguration = config.data.isInitialConfiguration;
  }

  public override onCancelClicked(): void {
    this.store.dispatch(setGaugeDataRequestedAfterFileImport({ gaugeDataRequestedAfterFileImport: false }));
    super.onCancelClicked();
  }

  public override onOkClicked(): void {
    const chartId = this.chartId;
    if (chartId == null) {
      console.error('chartId is missing!');
      return;
    }

    this.store.dispatch(setGaugeDataRequestedAfterFileImport({ gaugeDataRequestedAfterFileImport: false }));

    this.store.dispatch(
      saveChartConfigDialog({
        chartResultsTableState: this.resultColumnsTableState,
        chartId,
        scenarioId: ScenarioConstants.EmptyScenarioId,
      }),
    );

    super.onOkClicked();
  }

  public onPresetChanged(updateType: number): void {
    this.store.dispatch(setChartResultColumns({ updateType }));
    this.templatesList = this.generatePresetsList();
  }

  public onMenuToggle($event: Event, menu: any): void {
    if (!this.templatesList.length) {
      this.templatesList = this.generatePresetsList();
    }

    menu.toggle($event);
  }

  public generatePresetsList(): Array<MenuItem> {
    return [
      {
        label: templates.default,
        command: (): void => this.onPresetChanged(ChartUpdateType.gaugeDataDefault),
      },
      {
        label: templates.pressureOnly,
        command: (): void => {
          this.onPresetChanged(ChartUpdateType.pressureOnly);
        },
        disabled: !this.chartUnitDetails.some((unit) => unit.IsPressure),
      },
      {
        label: templates.surfacePressureOnly,
        command: (): void => this.onPresetChanged(ChartUpdateType.surfacePressureOnly),
        disabled: !this.chartUnitDetails.some((unit) => unit.IsSurfacePressure),
      },
      {
        label: templates.bottomHolePressureOnly,
        command: (): void => this.onPresetChanged(ChartUpdateType.bottomHolePressureOnly),
        disabled: !this.chartUnitDetails.some((unit) => unit.IsBottomHolePressure),
      },
      {
        label: templates.rateOnly,
        command: (): void => this.onPresetChanged(ChartUpdateType.rateOnly),
        disabled: !this.chartUnitDetails.some((unit) => unit.IsFlowRate),
      },
    ];
  }
}
