import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { DbDependentComponent } from '../../../../../common-modules/db-connection/db-dependent.component';
import { ActivatedRoute } from '@angular/router';
import * as reportingActions from '../../../../../+store/reporting/reporting.actions';
import { ModalService } from '../../../../../common-modules/modals/modal.service';
import { ChartDataSourceType } from '@dunefront/common/modules/reporting/dto/chart.dto';
import { getDrawing, isDrawing, isDrawingError } from '../../../../../+store/reporting/reporting-depth-animation.selectors';
import { getAnimationChartCanFetchData } from '../animation-charts.selectors';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { DrawingLayoutType, WellPartType } from '@dunefront/common/modules/settings/dto/settingsDto';
import { undefinedChartId } from '@dunefront/common/modules/reporting/dto/chart-data.dto';
import { DrawableContentProviderComponent } from '../../../../../shared/services/drawable-registry.service';

const WELL_IMAGE_NAME = 'Well';

@Component({
  selector: 'app-animation-packing-drawing',
  templateUrl: './animation-packing-drawing.component.html',
  styleUrls: ['./animation-packing-drawing.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnimationPackingDrawingComponent
  extends DbDependentComponent
  implements OnInit, OnDestroy, OnChanges, DrawableContentProviderComponent
{
  @Input() public layoutType: DrawingLayoutType = DrawingLayoutType.Survey;
  @Input() public drawingSection: WellPartType = WellPartType.Full_Well;
  @Input() public isInPanel = false;

  @Input() public drawableProviderId = WELL_IMAGE_NAME;

  @Input({ required: true }) public chartId: number = undefinedChartId;

  public selectedSimulationTime: number | undefined;
  public hideChartAfterRotate = false;
  public drawing$ = this.store.select(getDrawing);
  public readonly isDrawingError = isDrawingError;
  public readonly isDrawing = isDrawing;

  public get showDrawing(): boolean {
    return !this.hideChartAfterRotate;
  }

  constructor(
    store: Store,
    cdRef: ChangeDetectorRef,
    protected route: ActivatedRoute,
    protected modalService: ModalService,
  ) {
    super(store, cdRef);
  }

  public override ngOnInit(): void {
    super.ngOnInit();

    this.subscription.add(
      this.store
        .select(getAnimationChartCanFetchData)
        .pipe(
          distinctUntilChanged(),
          filter((canFetch) => canFetch === true),
        )
        .subscribe(() => {
          this.fetchInitialChartData().then();
          this.cdRef.markForCheck();
        }),
    );
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.rotateChart != null) {
      this.hideChartAfterRotate = true;
      setTimeout(() => {
        this.hideChartAfterRotate = false;
        this.cdRef.markForCheck();
      });
    }
  }

  private async fetchInitialChartData(): Promise<void> {
    if (this.currentAppModuleType == null) {
      return;
    }

    this.store.dispatch(
      reportingActions.checkDepthDataAction({
        depthDataKeys: [
          {
            moduleType: this.currentAppModuleType,
            scenarioId: this.currentScenarioId,
            rangeId: this.currentRangeId,
          },
        ],
      }),
    );
  }

  public override ngOnDestroy(): void {
    this.store.dispatch(reportingActions.clearReportingChartData({ chartDataSourceType: ChartDataSourceType.ChartSourceResultsTimeBased }));
    super.ngOnDestroy();
  }
}
