import { CompletionModuleState } from '../../completion-module.state';
import { PipeType } from '../../../../dto/pipe.dto';
import { TreatmentType } from '../../../well/dto/well.dto';
import { ICDType } from '../../../../dto/lower-completion-pipe.dto';
import { IError } from '../../../../common/common-state.interfaces';
import { isShuntedPipe } from '../../../pipes/lower-completion-pipes/pipes/shunted-screen-pipe';
import { ITableRow } from '../../../../common/common-grid.interfaces';
import { IcdScreenPipe } from '../../../pipes/lower-completion-pipes/pipes/icd-screen-pipe';
import { WellModuleState } from '../../../well/well-module.state';

export class IcdScreenValidation {
  public static validateICDScreenRow(error: IError<IcdScreenPipe>, row: ITableRow<IcdScreenPipe>): IError<IcdScreenPipe> {
    return {
      ...error,
      ICDType: this.ValidateICDType(row),
      NoOf0_075inPorts: this.ValidateNoOf0_075inPorts(row),
      NoOf0_100inPorts: this.ValidateNoOf0_100inPorts(row),
      NoOf0_125inPorts: this.ValidateNoOf0_125inPorts(row),
      NoOf0_150inPorts: this.ValidateNoOf0_150inPorts(row),
      A_Coefficient_Production: this.ValidateA_Coefficient_Production(row),
      B_Coefficient_Production: this.ValidateB_Coefficient_Production(row),
      C_Coefficient_Production: this.ValidateC_Coefficient_Production(row),
      A_Coefficient_Injection: this.ValidateA_Coefficient_Injection(row),
      B_Coefficient_Injection: this.ValidateB_Coefficient_Injection(row),
      C_Coefficient_Injection: this.ValidateC_Coefficient_Injection(row),
    };
  }

  public static validateIcdScreenComponentName(well: WellModuleState, completion: CompletionModuleState): string {
    if (well.TreatmentType === TreatmentType.High_Rate_Water_Pack) {
      return "ICDs can't be run when the high rate water pack option is selected";
    }
    if (well.IsLossRateCalculated) {
      return "ICDs can't be run when the calculate losses option is selected";
    }
    if (completion.LowerCompletion.rows.some((pipe) => isShuntedPipe(pipe.rowData))) {
      return "ICDs and shunt tubes can't be run together";
    }
    if (completion.RunningString.rows.some((pipe) => pipe.rowData.PipeType === PipeType.Pressure_Attenuator)) {
      return "ICDs and pressure attenuators can't be run together";
    }
    return '';
  }

  private static ValidateICDType(row: ITableRow<IcdScreenPipe>): string {
    if (row.rowData.ICDType === ICDType.None) {
      return 'ICD type must be defined';
    }

    return '';
  }

  private static ValidateNoOf0_075inPorts(row: ITableRow<IcdScreenPipe>): string {
    if (row.rowData.ICDType !== ICDType.Halliburton_EquiFlow_Nozzle_ICD) {
      return '';
    }

    const portCount =
      Math.max(0, row.rowData.NoOf0_075inPorts) +
      Math.max(0, row.rowData.NoOf0_100inPorts) +
      Math.max(0, row.rowData.NoOf0_125inPorts) +
      Math.max(0, row.rowData.NoOf0_150inPorts);
    if (row.rowData.NoOf0_075inPorts < 0) {
      return 'Number of ports must be greater than or equal to zero';
    } else if (portCount <= 0) {
      return 'There must be at least one port per ICD';
    } else if (portCount > 12) {
      return "There can't be more than 12 ports per ICD";
    }

    return '';
  }

  private static ValidateNoOf0_100inPorts(row: ITableRow<IcdScreenPipe>): string {
    if (row.rowData.ICDType !== ICDType.Halliburton_EquiFlow_Nozzle_ICD) {
      return '';
    }

    const portCount =
      Math.max(0, row.rowData.NoOf0_075inPorts) +
      Math.max(0, row.rowData.NoOf0_100inPorts) +
      Math.max(0, row.rowData.NoOf0_125inPorts) +
      Math.max(0, row.rowData.NoOf0_150inPorts);
    if (row.rowData.NoOf0_100inPorts < 0) {
      return 'Number of ports must be greater than or equal to zero';
    } else if (portCount <= 0) {
      return 'There must be at least one port per ICD';
    } else if (portCount > 12) {
      return "There can't be more than 12 ports per ICD";
    }

    return '';
  }

  private static ValidateNoOf0_125inPorts(row: ITableRow<IcdScreenPipe>): string {
    if (row.rowData.ICDType !== ICDType.Halliburton_EquiFlow_Nozzle_ICD) {
      return '';
    }

    const portCount =
      Math.max(0, row.rowData.NoOf0_075inPorts) +
      Math.max(0, row.rowData.NoOf0_100inPorts) +
      Math.max(0, row.rowData.NoOf0_125inPorts) +
      Math.max(0, row.rowData.NoOf0_150inPorts);
    if (row.rowData.NoOf0_125inPorts < 0) {
      return 'Number of ports must be greater than or equal to zero';
    } else if (portCount <= 0) {
      return 'There must be at least one port per ICD';
    } else if (portCount > 12) {
      return "There can't be more than 12 ports per ICD";
    }

    return '';
  }

  private static ValidateNoOf0_150inPorts(row: ITableRow<IcdScreenPipe>): string {
    if (row.rowData.ICDType !== ICDType.Halliburton_EquiFlow_Nozzle_ICD) {
      return '';
    }

    const portCount =
      Math.max(0, row.rowData.NoOf0_075inPorts) +
      Math.max(0, row.rowData.NoOf0_100inPorts) +
      Math.max(0, row.rowData.NoOf0_125inPorts) +
      Math.max(0, row.rowData.NoOf0_150inPorts);
    if (row.rowData.NoOf0_150inPorts < 0) {
      return 'Number of ports must be greater than or equal to zero';
    } else if (portCount <= 0) {
      return 'There must be at least one port per ICD';
    } else if (portCount > 12) {
      return "There can't be more than 12 ports per ICD";
    }

    return '';
  }

  private static ValidateA_Coefficient_Production(row: ITableRow<IcdScreenPipe>): string {
    if (row.rowData.ICDType !== ICDType.BHGE_ICD_Or_AICD) {
      return '';
    }

    if (row.rowData.A_Coefficient_Production === 0) {
      return 'Production coefficients must be defined';
    }

    return '';
  }

  private static ValidateB_Coefficient_Production(row: ITableRow<IcdScreenPipe>): string {
    if (row.rowData.ICDType !== ICDType.BHGE_ICD_Or_AICD) {
      return '';
    }

    if (row.rowData.B_Coefficient_Production === 0) {
      return 'Production coefficients must be defined';
    }

    return '';
  }

  private static ValidateC_Coefficient_Production(row: ITableRow<IcdScreenPipe>): string {
    if (row.rowData.ICDType !== ICDType.BHGE_ICD_Or_AICD) {
      return '';
    }

    if (row.rowData.C_Coefficient_Production === 0) {
      return 'Production coefficients must be defined';
    }

    return '';
  }

  private static ValidateA_Coefficient_Injection(row: ITableRow<IcdScreenPipe>): string {
    if (row.rowData.ICDType !== ICDType.BHGE_ICD_Or_AICD) {
      return '';
    }

    if (row.rowData.A_Coefficient_Injection === 0) {
      return 'Injection coefficients must be defined';
    }

    return '';
  }

  private static ValidateB_Coefficient_Injection(row: ITableRow<IcdScreenPipe>): string {
    if (row.rowData.ICDType !== ICDType.BHGE_ICD_Or_AICD) {
      return '';
    }

    if (row.rowData.B_Coefficient_Injection === 0) {
      return 'Injection coefficients must be defined';
    }

    return '';
  }

  private static ValidateC_Coefficient_Injection(row: ITableRow<IcdScreenPipe>): string {
    if (row.rowData.ICDType !== ICDType.BHGE_ICD_Or_AICD) {
      return '';
    }

    if (row.rowData.C_Coefficient_Injection === 0) {
      return 'Injection coefficients must be defined';
    }

    return '';
  }
}
