import { UnitConverter } from '../../converter.interfaces';
import { TimeUnit } from '../../../dto/unit-system.dto';

export class TimeConverter extends UnitConverter {
  public static symbols: Array<string> = ['', 's', 'h', 'min'];

  public static unit = TimeUnit;

  public static fromSi(value: number, unit: TimeUnit): number {
    switch (unit) {
      case TimeUnit.Second: {
        return value;
      }
      case TimeUnit.Hour: {
        return value * 2.7777777778e-4;
      }
      case TimeUnit.Minute: {
        return value * 1.6666666667e-2;
      }

      default: {
        throw new TypeError('Unknown Time Unit:' + unit);
      }
    }
  }

  public static toSi(value: number, unit: TimeUnit): number {
    switch (unit) {
      case TimeUnit.Second: {
        return value;
      }
      case TimeUnit.Hour: {
        return value / 2.7777777778e-4;
      }
      case TimeUnit.Minute: {
        return value / 1.6666666667e-2;
      }

      default: {
        throw new TypeError('Unknown Time Unit:' + unit);
      }
    }
  }

  public static getSymbol(unit: TimeUnit): string {
    return this.symbols[unit];
  }
}
