import { NozzleProperties, Shroud, ShuntTube, Tube } from './shunt-tube';
import { ShuntTubeDto, TubeShape } from '../../dto/shunt-tube.dto';

export class ShuntTubeFactory {
  public static create(shuntTubeDto: ShuntTubeDto): ShuntTube {
    const shuntTube: ShuntTube = {
      ScenarioId: shuntTubeDto.ScenarioId,
      IsTransportTubePresent: shuntTubeDto.IsTransportTubePresent,
      IsInternalReservoir: shuntTubeDto.IsInternalReservoir,
      ShuntPackingFactor: shuntTubeDto.ShuntPackingFactor,
      Shape: shuntTubeDto.Shape,
      IsExternal: shuntTubeDto.IsExternal,
      Shroud: this.createShroud(shuntTubeDto),
      NozzleProperties: this.createNozzleProprties(shuntTubeDto),
      PackingTube: this.createPackingTube(shuntTubeDto),
      TransportTube: this.createTransportTube(shuntTubeDto),
      isValid: true,
      error: {},
    };
    return shuntTube;
  }

  public static createEmpty(): ShuntTube {
    return {
      Shape: TubeShape.Rectangle,
      IsExternal: false,
      IsInternalReservoir: false,
      IsTransportTubePresent: false,
      NozzleProperties: {
        IsNozzleLeakoff: false,
        LengthBetweenNozzles: 0,
        LengthToFirstNozzle: 0,
        NozzleDiameter: 0,
        NozzleLength: 0,
        isValid: true,
        error: {},
      },
      PackingTube: this.createEmptyTube(),
      ScenarioId: -1,
      Shroud: {
        IsShroudPresent: false,
        OpenAreaPercentage: 0,
        ShroudInnerDiameter: 0,
        ShroudOuterDiameter: 0,
        error: {},
        isValid: false,
      },
      ShuntPackingFactor: 0,
      TransportTube: this.createEmptyTube(),
      error: {},
      isValid: false,
    };
  }

  private static createEmptyTube(): Tube {
    return {
      BurstRating: 0,
      CollapseRating: 0,
      Height: 0,
      InnerDiameter: 0,
      NumberOfTubes: 0,
      OuterDiameter: 0,
      Thickness: 0,
      Width: 0,
      isValid: false,
      error: {},
    };
  }

  public static createShroud(dto: ShuntTubeDto): Shroud {
    const shroud: Shroud = {
      IsShroudPresent: dto.IsShroudPresent,
      ShroudOuterDiameter: dto.ShroudOuterDiameter,
      ShroudInnerDiameter: dto.ShroudInnerDiameter,
      OpenAreaPercentage: dto.OpenAreaPercentage,
      isValid: true,
      error: {},
    };
    return shroud;
  }

  public static createNozzleProprties(dto: ShuntTubeDto): NozzleProperties {
    const nozzleProperties: NozzleProperties = {
      IsNozzleLeakoff: dto.IsNozzleLeakoff,
      NozzleDiameter: dto.NozzleDiameter,
      NozzleLength: dto.NozzleLength,
      LengthBetweenNozzles: dto.LengthBetweenNozzles,
      LengthToFirstNozzle: dto.LengthToFirstNozzle,
      isValid: true,
      error: {},
    };
    return nozzleProperties;
  }

  public static createPackingTube(dto: ShuntTubeDto): Tube {
    const packingTube: Tube = {
      NumberOfTubes: dto.NumberOfPackingTubes,
      Width: dto.PackingTubeWidth,
      Height: dto.PackingTubeHeight,
      Thickness: dto.PackingTubeThickness,
      OuterDiameter: dto.PackingTubeOuterDiameter,
      InnerDiameter: dto.PackingTubeInnerDiameter,
      BurstRating: dto.PackingTubeBurstRating,
      CollapseRating: dto.PackingTubeCollapseRating,
      isValid: true,
      error: {},
    };
    return packingTube;
  }

  public static createTransportTube(dto: ShuntTubeDto): Tube {
    const transportTube: Tube = {
      NumberOfTubes: dto.NumberOfTransportTubes,
      Width: dto.TransportTubeWidth,
      Height: dto.TransportTubeHeight,
      Thickness: dto.TransportTubeThickness,
      OuterDiameter: dto.TransportTubeOuterDiameter,
      InnerDiameter: dto.TransportTubeInnerDiameter,
      BurstRating: dto.TransportTubeBurstRating,
      CollapseRating: dto.TransportTubeCollapseRating,
      isValid: true,
      error: {},
    };
    return transportTube;
  }

  public static toDto(shuntTube: ShuntTube): ShuntTubeDto {
    const shuntTubeDto: ShuntTubeDto = {
      ScenarioId: shuntTube.ScenarioId,
      IsTransportTubePresent: shuntTube.IsTransportTubePresent,
      IsInternalReservoir: shuntTube.IsInternalReservoir,
      ShuntPackingFactor: shuntTube.ShuntPackingFactor,
      IsExternal: shuntTube.IsExternal,
      Shape: shuntTube.Shape,

      IsNozzleLeakoff: shuntTube.NozzleProperties.IsNozzleLeakoff,
      NozzleDiameter: shuntTube.NozzleProperties.NozzleDiameter,
      NozzleLength: shuntTube.NozzleProperties.NozzleLength,
      LengthBetweenNozzles: shuntTube.NozzleProperties.LengthBetweenNozzles,
      LengthToFirstNozzle: shuntTube.NozzleProperties.LengthToFirstNozzle,

      IsShroudPresent: shuntTube.Shroud.IsShroudPresent,
      ShroudOuterDiameter: shuntTube.Shroud.ShroudOuterDiameter,
      ShroudInnerDiameter: shuntTube.Shroud.ShroudInnerDiameter,
      OpenAreaPercentage: shuntTube.Shroud.OpenAreaPercentage,

      NumberOfPackingTubes: shuntTube.PackingTube.NumberOfTubes,
      PackingTubeWidth: shuntTube.PackingTube.Width,
      PackingTubeHeight: shuntTube.PackingTube.Height,
      PackingTubeThickness: shuntTube.PackingTube.Thickness,
      PackingTubeOuterDiameter: shuntTube.PackingTube.OuterDiameter,
      PackingTubeInnerDiameter: shuntTube.PackingTube.InnerDiameter,
      PackingTubeBurstRating: shuntTube.PackingTube.BurstRating,
      PackingTubeCollapseRating: shuntTube.PackingTube.CollapseRating,

      NumberOfTransportTubes: shuntTube.TransportTube.NumberOfTubes,
      TransportTubeWidth: shuntTube.TransportTube.Width,
      TransportTubeHeight: shuntTube.TransportTube.Height,
      TransportTubeThickness: shuntTube.TransportTube.Thickness,
      TransportTubeOuterDiameter: shuntTube.TransportTube.OuterDiameter,
      TransportTubeInnerDiameter: shuntTube.TransportTube.InnerDiameter,
      TransportTubeBurstRating: shuntTube.TransportTube.BurstRating,
      TransportTubeCollapseRating: shuntTube.TransportTube.CollapseRating,
    };
    return shuntTubeDto;
  }
}
