export const RouteModuleHome = 'home';
export const RouteModuleHomePersonal = 'personal';
export const RouteModuleHomeShared = 'shared';
export const RouteModuleHomeDemo = 'demo';
export const RouteModuleHomeTrash = 'trash';
export const RouteModuleHomeFileOptions = 'file-options';
export const RouteModuleModuleSelector = 'module-selector';
export const RouteModuleTestPages = 'test-pages';
export const RouteModuleUnitsTest = 'units-test';
export const RouteModuleFile = 'file';
