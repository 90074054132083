import { createSelector } from '@ngrx/store';
import { getUiState } from '../../ui/ui.selectors';
import { getChartState } from '../../reporting/reporting.selectors';
import { getAnyFileHasXShift } from '../../data-storage/data-storage.selectors';
import { getCurrentRangeHasAnyVerticalShifts, getCurrentRangeId, getEditingRangeState } from '../../range/range.selectors';
import { RangeConstants } from '@dunefront/common/dto/range.dto';
import { isDataAnalysis, isDataBased, isEvaluate, isTrendAnalysis } from '../menu-selectors.helpers';
import { XYShiftAxis } from '../../ui/ui-module.state';

export const getAnalysisMenuProps = createSelector(
  getUiState,
  getCurrentRangeId,
  getCurrentRangeHasAnyVerticalShifts,
  getAnyFileHasXShift,

  (...[uiState, currentRangeId, currentRangeHasAnyVerticalShifts, anyFileHasXShift]): AnalysisMenuProps => {
    const _isEvaluate = isEvaluate(uiState.appModuleType);
    const _isTrendAnalysis = isTrendAnalysis(uiState.appModuleType);
    const _isDataAnalysis = isDataAnalysis(uiState.appModuleType);
    const _isGaugeData = isDataBased(uiState);
    const _isGaugeDataChart = _isGaugeData && uiState.gaugeDataTab === 'chart';
    const _isRangeSelected = currentRangeId !== RangeConstants.EntireRangeId;
    const _isTrendAnalysisChart = _isTrendAnalysis && uiState.appModuleSection === 'trend-analysis';

    const xAxisShiftEnabled = _isGaugeDataChart;
    const yAxisShiftEnabled = (_isTrendAnalysisChart || (_isDataAnalysis && _isGaugeDataChart)) && _isRangeSelected;

    return {
      importEnabled: _isEvaluate || _isTrendAnalysis || _isDataAnalysis,
      editChartEnabled: _isGaugeDataChart,

      isXAxisShiftAvailable: !_isTrendAnalysisChart,
      xAxisShiftEnabled,
      xAxisAutoShiftEnabled: xAxisShiftEnabled,
      xAxisShiftActive: xAxisShiftEnabled && uiState.xyAxisShift === XYShiftAxis.x,
      xAutoShiftActive: xAxisShiftEnabled && uiState.isXAutoShiftActive,
      resetXAxisShiftEnabled: xAxisShiftEnabled && anyFileHasXShift,

      isYAxisShiftAvailable: _isTrendAnalysis || _isDataAnalysis,
      yAxisShiftEnabled,
      yAxisShiftActive: yAxisShiftEnabled && uiState.xyAxisShift === XYShiftAxis.y,
      yAxisAutoShiftEnabled: yAxisShiftEnabled,
      yAutoShiftActive: yAxisShiftEnabled && uiState.isYAutoShiftActive,
      resetYAxisShiftEnabled: yAxisShiftEnabled && currentRangeHasAnyVerticalShifts,
    };
  },
);

export const getRangeMenuProps = createSelector(
  getUiState,
  getEditingRangeState,
  getChartState,
  (...[uiState, editingRangeState, chartData]) => {
    const _isEvaluate = isEvaluate(uiState.appModuleType);
    const _isTrendAnalysis = isTrendAnalysis(uiState.appModuleType);
    const _isDataAnalysis = isDataAnalysis(uiState.appModuleType);
    const _isGaugeData = isDataBased(uiState);

    const isGaugeDataChart = _isGaugeData && uiState.gaugeDataTab === 'chart';
    const currentRangeId = editingRangeState.currentRange?.Id ?? RangeConstants.EntireRangeId;

    const rangeMenuProps: RangeMenuProps = {
      comboBoxEnabled: (_isEvaluate || _isTrendAnalysis || _isDataAnalysis) && !editingRangeState.isEditing,
      isEditing: editingRangeState.isEditing,
      addEnabled: isGaugeDataChart && !editingRangeState.isEditing && (chartData.chartData?.ChartDataSets ?? []).length > 0,
      editEnabled: isGaugeDataChart && currentRangeId > 0 && !editingRangeState.isEditing,
      saveEnabled: isGaugeDataChart && editingRangeState.isEditing,
      addVisible: !(isGaugeDataChart && editingRangeState.isEditing),
      saveVisible: isGaugeDataChart && editingRangeState.isEditing,
      saveLabel: currentRangeId === RangeConstants.NewRangeId ? 'Save' : 'Edit',
      deleteEnabled: isGaugeDataChart && currentRangeId > 0 && !editingRangeState.isEditing,
      deleteDataEnabled: isGaugeDataChart && currentRangeId > 0 && !editingRangeState.isEditing,
    };
    return rangeMenuProps;
  },
);

export interface AnalysisMenuProps {
  importEnabled: boolean;
  editChartEnabled: boolean;
  xAxisShiftEnabled: boolean;
  xAxisAutoShiftEnabled: boolean;
  xAxisShiftActive: boolean;
  resetXAxisShiftEnabled: boolean;
  yAxisShiftEnabled: boolean;
  yAxisShiftActive: boolean;
  yAxisAutoShiftEnabled: boolean;
  resetYAxisShiftEnabled: boolean;
  yAutoShiftActive: boolean;
  xAutoShiftActive: boolean;
  isXAxisShiftAvailable: boolean;
  isYAxisShiftAvailable: boolean;
}

export interface RangeMenuProps {
  comboBoxEnabled: boolean;
  isEditing: boolean;
  addEnabled: boolean;
  saveEnabled: boolean;
  editEnabled: boolean;
  addVisible: boolean;
  saveVisible: boolean;
  saveLabel: string;
  deleteEnabled: boolean;
  deleteDataEnabled: boolean;
}
