<button
  type="button"
  (click)="toggleHelpVisible()"
  class="toggle-button toggle-button-display help-button main-help-btn"
  [class.toggle-button-open]="isHelpPanelOpen"
  id="btnHelpOpenClose"
  data-cy="help-open-close-btn"
>
  <ng-container *ngIf="isHelpPanelOpen; else panelClosed">
    <div class="right-triangle">
      <span class="icon-left-triangle"></span>
    </div>
  </ng-container>
  <ng-template #panelClosed>
    <span class="icon-left-triangle"></span>
  </ng-template>
  <div class="label">Help & Notes</div>
  <div class="question-mark-container">
    <span class="icon-question-mark"></span>
  </div>

  <div class="pin-unpin" data-cy="help-pin-unpin" (click)="toggleHelpPinned($event)" [class.active]="isHelpPinned">
    <span class="icon-pin-cross" *ngIf="isHelpPinned"></span>
    <span class="icon-pin" *ngIf="!isHelpPinned"></span>
  </div>
</button>

<p-accordion [styleClass]="'help-notes-accordion'" [ngClass]="'tabs-'+accordion.tabs.length" #accordion>
  <p-accordionTab header="Help" [contentStyleClass]="'help-tab'">
    <ng-container *ngIf="helpUrl">
      <iframe data-cy="help-content-iframe" [src]="helpUrl"></iframe>
    </ng-container>
  </p-accordionTab>
  <p-accordionTab header="Scenario Notes" *ngIf="currentNotes !== null" data-cy="scenario-notes-tab">
    <quill-editor
      [disabled]="(isUiLocked$ | async) === true"
      [debounceTime]="500"
      (onContentChanged)="onNoteContentChanged($event, currentNotes.scenarioNote)"
      [ngModel]="currentNotes.scenarioNote.Note"
    ></quill-editor>
  </p-accordionTab>
  <p-accordionTab header="Range Notes" *ngIf="currentNotes && currentNotes.rangeNote !== null"
                  data-cy="range-notes-tab">
    <quill-editor
      [disabled]="(isUiLocked$ | async) === true"
      [debounceTime]="500"
      (onContentChanged)="onNoteContentChanged($event, currentNotes.rangeNote)"
      [ngModel]="currentNotes.rangeNote.Note"
    ></quill-editor>
  </p-accordionTab>

  <p-accordionTab header="Chart Notes" *ngIf="(currentReportingTab$ | async) as reportingTab" data-cy="chart-notes-tab">
    <quill-editor
      [disabled]="(isUiLocked$ | async) === true"
      [debounceTime]="500"
      (onContentChanged)="onChartNoteChanged($event, reportingTab)"
      [ngModel]="reportingTab.ChartDescription"
    ></quill-editor>
  </p-accordionTab>

  <p-accordionTab header="Fluid Notes" *ngIf="(appModuleSection$ | async) === 'fluids' && (currentFluid$ | async) as fluid" data-cy="fluid-notes-tab">
    <quill-editor
      [disabled]="(isUiLocked$ | async) === true"
      [debounceTime]="500"
      (onContentChanged)="onFluidNoteChanged($event, fluid)"
      [ngModel]="fluid.Notes"
    ></quill-editor>
  </p-accordionTab>
  <p-accordionTab header="Gravel Notes" *ngIf="(appModuleSection$ | async) === 'gravel' && (currentGravel$ | async) as gravel" data-cy="gravel-notes-tab">
    <quill-editor
      [disabled]="(isUiLocked$ | async) === true"
      [debounceTime]="500"
      (onContentChanged)="onGravelNoteChanged($event, gravel)"
      [ngModel]="gravel.Notes"
    ></quill-editor>
  </p-accordionTab>
</p-accordion>
