import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TreeNode } from 'primeng/api';

@Component({
  selector: 'app-tree',
  templateUrl: './app-tree.component.html',
  styleUrls: ['./app-tree.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppTreeComponent<T> {
  @Input() public nodes: TreeNode<T>[] = [];
  @Input() public isCheckboxMode = false;

  @Input() public selection: TreeNode<any> | TreeNode<any>[] | null = [];
  @Output() public selectionChange = new EventEmitter<TreeNode | TreeNode[] | null>();
}
