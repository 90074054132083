import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { IFile } from '@dunefront/common/dto/file.dto';
import { loadAllFilesAndFoldersSuccess, newFolder } from '../../../../+store/file-manager/file-manager.actions';
import { BaseFileActionDialogComponent } from '../base-file-action.dialog.component';
import { Store } from '@ngrx/store';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Actions, ofType } from '@ngrx/effects';
import { FileErrorHelper, IFileFolderName } from '../file-error.helper';
import {
  CY_FOLDER_OK,
  defaultModalCancelButton,
  defaultModalConfirmButton,
  ModalButtonConfig,
} from '../../../../common-modules/modals/generic-modal/generic-modal.component';

@Component({
  selector: 'app-new-folder-dialog',
  templateUrl: './new-folder.dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewFolderDialogComponent extends BaseFileActionDialogComponent {
  public targetFolder!: IFile;
  private folderNameList!: string[];
  public folderName: IFileFolderName = {
    targetName: '',
    error: {},
  };
  public modalTitle = 'New Folder';
  public modalButtonsConfigs: ModalButtonConfig[] = [
    defaultModalCancelButton((): void => this.cancelClicked(), 'Cancel', 'button-cancel'),
    defaultModalConfirmButton((): void => this.okClicked(), 'OK', CY_FOLDER_OK, this.folderName.targetName === ''),
  ];

  constructor(activeModal: DynamicDialogRef, config: DynamicDialogConfig, store: Store, cdRef: ChangeDetectorRef, actions$: Actions) {
    super(activeModal, config, store, cdRef);
    this.targetFolder = config.data.targetFolder;
    this.folderNameList = config.data.folderNameList;
    this.subscription.add(actions$.pipe(ofType(loadAllFilesAndFoldersSuccess)).subscribe((_) => this.activeModal.close()));
  }

  public textValueChanged(textValue: string): void {
    this.folderName = FileErrorHelper.validateFileFolderName(
      {
        ...this.folderName,
        targetName: textValue,
      },
      this.folderNameList,
    );
    this.errorMessage = this.folderName.error.targetName;
    const okButton = this.modalButtonsConfigs.find((conf) => conf.dataCy === CY_FOLDER_OK);
    if (okButton) {
      okButton.disabled = !!this.errorMessage;
    }
  }

  public okClicked(): void {
    this.folderName = FileErrorHelper.validateFileFolderName(this.folderName, this.folderNameList);
    this.store.dispatch(
      newFolder({
        target: {
          ...this.targetFolder,
          Name: this.folderName.targetName,
          Folder: this.getFolderArrayFromParent(this.targetFolder),
        },
      }),
    );
  }
}
