import { LicenseFeature, LicenseInfo, LicensingLoginResponse } from './licensing.interfaces';

export interface LicensingModuleState {
  licenseInfo: LicenseInfo;
  isLoaded: boolean;
  licensingLogin: LicensingLoginResponse | undefined;
  mainFeature: LicenseFeature | undefined;
  selectedAddons: LicenseFeature[];
}

export const licensingModuleInitialState: LicensingModuleState = {
  licenseInfo: { features: [], products: [], licenseIds: [] },
  isLoaded: false,
  licensingLogin: undefined,
  mainFeature: undefined,
  selectedAddons: [],
};
