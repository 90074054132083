import { DictionaryWithArray, IDictionaryWithArray } from '@dunefront/common/common/state.helpers';
import { getCurrentAppModuleType } from '../ui/ui.selectors';
import { getCurrentRangeId } from '../range/range.selectors';
import { getFluidUsageSimulateEvaluateFinderDeps } from '../pumping/selectors/fluid-and-gravel-delete.selectors';
import { createSelector } from '@ngrx/store';
import { isSimulateBased, ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import { FluidGravelUsageFinder } from '@dunefront/common/modules/validation/fluid-gravel-usage-finder';
import { isCalculator } from '../menu-selectors/menu-selectors.helpers';
import { getFluidAndGravelUsageCalculatorsDeps } from './fluid-delete.selectors';
import { getValidatedFluidModuleState } from './fluid.selectors';
import { Fluid } from '@dunefront/common/modules/fluid/model/fluid';
import { Rheology } from '@dunefront/common/modules/fluid/model/rheology/rheology';
import { ITableState } from '@dunefront/common/common/common-grid.interfaces';

export const getFluidsUsedInSelectedModule = createSelector(
  getValidatedFluidModuleState,
  getFluidUsageSimulateEvaluateFinderDeps,
  getFluidAndGravelUsageCalculatorsDeps,
  getCurrentRangeId,
  getCurrentAppModuleType,
  (fluidState, simulateEvaluateDeps, calculatorsDeps, rangeId, module) => {
    const result: Fluid[] = [];
    const fluids = DictionaryWithArray.getArray(fluidState.Fluids);

    const isSimulateEvaluate = isSimulateBased(module) || module === ModuleType.Evaluate;
    const isCalc = isCalculator(module);

    for (const fluid of fluids) {
      // current module is Simulate/Evaluate and this fluid is used in it
      const isSimulateEvaluateAndIsUsed =
        isSimulateEvaluate && FluidGravelUsageFinder.FindFluidInPumping(fluid.Id, simulateEvaluateDeps, rangeId).length > 0;

      // current module is Calculators and this fluid is used in it
      const isCalculatorsAndIsUsed = isCalc && FluidGravelUsageFinder.FindFluidInCalculators(fluid.Id, calculatorsDeps).length > 0;

      if (isSimulateEvaluateAndIsUsed || isCalculatorsAndIsUsed) {
        result.push(fluid);
      }
    }

    return result;
  },
);

export const getFluidsNotUsedInSelectedModule = createSelector(
  getValidatedFluidModuleState,
  getFluidsUsedInSelectedModule,
  (fluidState, usedFluids) => {
    const allFluids = DictionaryWithArray.getArray(fluidState.Fluids);

    return allFluids.filter((fluid) => !usedFluids.includes(fluid));
  },
);

const getRheologies = createSelector(getValidatedFluidModuleState, (fluidState) => fluidState.Rheologies);

const areAllFluidsWithRheologiesValid = (fluids: Fluid[], rheologies: IDictionaryWithArray<ITableState<Rheology>>): boolean => {
  for (const fluid of fluids) {
    if (!fluid.isValid || rheologies.dict[fluid.Id]?.isValid === false) {
      return false;
    }
  }

  return true;
};

export const getAreFluidsWithRheologiesUsedInSelectedModuleValid = createSelector(
  getFluidsUsedInSelectedModule,
  getRheologies,
  (usedFluids, rheologies) => areAllFluidsWithRheologiesValid(usedFluids, rheologies),
);

export const getAreFluidsWithRheologiesNotUsedInSelectedModuleValid = createSelector(
  getFluidsNotUsedInSelectedModule,
  getRheologies,
  (unusedFluids, rheologies) => areAllFluidsWithRheologiesValid(unusedFluids, rheologies),
);
