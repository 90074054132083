import {
  DeleteResultsFilter,
  EntityToCopy,
  LONG_CALCULATION_WITH_SIMULATION_RESULTS_MODULE_TYPES,
  ModuleType,
} from '../../modules/scenario/scenario.dto';
import { RangeConstants } from '../../dto/range.dto';

export const getCloningDeleteResultsFilter = (
  scenarioIds: number[],
  entitiesToCopy: EntityToCopy[],
  rangeId: number,
): DeleteResultsFilter | undefined => {
  // First check if deleting of the results is needed
  // Fluids and Gravels are not modified or deleted but new clones are created - so results are still valid
  // Calculators - store keeps results only for current scenario, so they will be reloaded
  if (
    entitiesToCopy.some(
      (entity) =>
        ![
          EntityToCopy.ProjectFluid,
          EntityToCopy.ProjectGravel,
          EntityToCopy.Report,
          EntityToCopy.Charts,
          EntityToCopy.PSDAnalysis,
          EntityToCopy.FrictionCalculator,
          EntityToCopy.SettlingCalculator,
          EntityToCopy.ResuspensionCalculator,
          EntityToCopy.MaspCalculator,
          EntityToCopy.InjectionTestCalculator,
          EntityToCopy.LeakoffCoefficientCalculator,
        ].includes(entity),
    )
  ) {
    // This check needs to be first as it will delete more data when some of the pages with bigger impact is cloned
    if (
      entitiesToCopy.some((entity) =>
        [
          EntityToCopy.GeneralData,
          EntityToCopy.CasingData,
          EntityToCopy.ReservoirData,
          EntityToCopy.CaliperData,
          EntityToCopy.LowerCompletion,
          EntityToCopy.RunningString,
          EntityToCopy.ShuntTube,
          EntityToCopy.Volumes,
          EntityToCopy.EngineOptions,
        ].includes(entity),
      )
    ) {
      return { moduleTypes: LONG_CALCULATION_WITH_SIMULATION_RESULTS_MODULE_TYPES, scenarioIds };
    }

    // This check needs to be second as it will delete fewer data in case only Pumping screens are cloned
    if (
      entitiesToCopy.some((entity) =>
        [EntityToCopy.InitialData, EntityToCopy.WellFluids, EntityToCopy.Schedule, EntityToCopy.ScheduleOptions].includes(entity),
      )
    ) {
      return {
        moduleTypes:
          rangeId === RangeConstants.EmptyRangeId
            ? [ModuleType.Simulate, ModuleType.Simulate_CH, ModuleType.Simulate_Disp]
            : [ModuleType.Evaluate],
        scenarioIds,
        rangeIds: [rangeId],
      };
    }
  }

  return;
};
