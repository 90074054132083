import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { ISelectItem, toSelectItem } from '@dunefront/common/common/select.helpers';
import { ImportDataFilePropertiesConfig } from '@dunefront/common/modules/data-storage/dto/import-template/import-template.dto';
import { IColNameWithUnit } from '../../../../../+store/import-data/model/parsed-result';
import { changeProp, ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';

@Component({
  selector: 'app-file-properties',
  templateUrl: './file-properties.component.html',
  styleUrls: ['./file-properties.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilePropertiesComponent {
  @Input()
  public filePropertiesConfig!: ImportDataFilePropertiesConfig;

  @Input()
  public set columns(cols: IColNameWithUnit[]) {
    this.columnSelectItems = cols.map((col, index) => toSelectItem(index, col.columnName));
  }

  @Output()
  public filePropertiesConfigChange = new EventEmitter<ImportDataFilePropertiesConfig>();

  public columnSelectItems: ISelectItem<number>[] = [];
  public UnitType = UnitSystem;

  public onValueChange(prop: ObjectChangeProp<ImportDataFilePropertiesConfig>): void {
    this.filePropertiesConfigChange.emit(changeProp(this.filePropertiesConfig, prop));
  }
}
