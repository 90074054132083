import { AfterViewInit, ChangeDetectorRef, Component, Input, OnChanges } from '@angular/core';
import { DbDependentComponent } from '../../../../../common-modules/db-connection/db-dependent.component';
import { Store } from '@ngrx/store';
import {
  createChartDataColumn,
  createChartDataRowDto,
  createChartDataSet,
  createEmptyChartData,
  IChartDataDto,
} from '@dunefront/common/modules/reporting/dto/chart-data.dto';
import { FrictionXAxis } from '@dunefront/common/modules/calculators/friction-calculator/friction-calculator.dto';
import { DataType } from '@dunefront/common/dto/data-storage';
import { getChartIds } from '../../../../../+store/reporting/reporting.selectors';
import { SettlingCalculatorResultDto } from '@dunefront/common/modules/calculators/dto/settling-calculator.dto';
import { ChartState } from '../../../../../+store/reporting/reporting-module.state';
import { ChartDataSourceType } from '@dunefront/common/modules/reporting/dto/chart.dto';
import { ChartAxis } from '@dunefront/common/modules/reporting/dto/chart-axis-property.dto';
import { ReportingFactory } from '../../../../../+store/reporting/model/reporting.factory';

@Component({
  selector: 'app-settling-calculator-result-chart',
  templateUrl: './settling-calculator-result-chart.component.html',
  styleUrls: ['./settling-calculator-result-chart.component.scss'],
})
export class SettlingCalculatorResultChartComponent extends DbDependentComponent implements OnChanges, AfterViewInit {
  @Input() public settlingCalculatorResult: SettlingCalculatorResultDto[] = [];

  @Input() public displayedAxis!: FrictionXAxis;

  public chartState?: ChartState;
  public chartId = 0;

  constructor(store: Store, cdRef: ChangeDetectorRef) {
    super(store, cdRef);

    this.subscription.add(
      store.select(getChartIds).subscribe((chartIds) => {
        this.chartId = chartIds.settlingCalculatorChartId;
        cdRef.markForCheck();
      }),
    );
  }

  public ngAfterViewInit(): void {
    this.setChartData();
  }

  public ngOnChanges(): void {
    this.setChartData();
  }

  public setChartData(): void {
    // this is to fix chart 'shaking' just after page is opened.
    setTimeout(() => {
      this.chartState = ReportingFactory.createChartStateWithData(ChartDataSourceType.SettlingCalculator, this.generateChartData());
      this.cdRef.markForCheck();
    }, 0);
  }

  public generateChartData(): IChartDataDto {
    const chartDataDto = createEmptyChartData(
      this.displayedAxis === FrictionXAxis.Rate ? DataType.Return_Rate : DataType.Fluid_Velocity,
      ChartDataSourceType.SettlingCalculator,
      this.chartId,
    );

    const columnDuneHeight = createChartDataColumn(ChartAxis.PrimaryValue, DataType.Dune_Height, 0, 0, 'Settling');
    chartDataDto.ChartDataColumns.push(columnDuneHeight);

    const chartDataRows = this.settlingCalculatorResult.map((result) =>
      createChartDataRowDto(this.getChartArgument(result), [result.DuneHeight]),
    );

    chartDataDto.ChartDataSets.push(createChartDataSet(chartDataRows));
    return chartDataDto;
  }

  private getChartArgument(settlingResult: SettlingCalculatorResultDto): number {
    return this.displayedAxis === FrictionXAxis.Rate ? settlingResult.Rate : settlingResult.Velocity;
  }
}
