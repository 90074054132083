import { ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import { HelpIds } from '../help/help.actions';
import { PartialEnumDictionary } from '@dunefront/common/common/state.helpers';
import { ResetZoomMode } from './ui.actions';
import { ChartZoomMode } from '@dunefront/common/modules/reporting/reporting.settings';
import { NotificationState, NotificationStatus } from '@dunefront/common/dto/custom-settings.dto';

export interface Notification {
  title: string;
  description: string;
  url: string;
  id: string;
  appName: string[];
  expirationDate?: Date;
  maxAppVersion?: string;
  minAppVersion?: string;
  showDate: Date;
}

export interface NotificationWithStatus extends Notification {
  status: NotificationStatus;
  date: Date;
}

export enum OneTimeInstructionType {
  annotation = 0,
  marker = 1,
  verticalAutoShift = 2,
  horizontalAutoShift = 3,
  optimize = 4,
  deleteResults = 5,
  videoRecordingResizeInfo = 6,
  gradientLine = 7,
  changePSDInputType = 8,
  mergeRowsInGrid = 9,
}

export interface UiState {
  appModuleType: ModuleType;
  appModuleSection: ModuleSection;
  wellTab: WellTab;
  completionTab: CompletionTab;
  fluidsTab: FluidsTab;
  gravelsTab: GravelsTab;
  pumpingTab: PumpingTab;
  psdAnalysisTab: PsdAnalysisTab;
  calculatorsTab: CalculatorsTab | null;
  resultsTab: ResultsTab;
  reportingTab: ReportingTab;
  trendAnalysisTab: TrendAnalysisTab;
  gaugeDataTab: GaugeDataTab;
  xyAxisShift: XYShiftAxis;
  isYAutoShiftActive: boolean;
  isXAutoShiftActive: boolean;
  evaluateResetAutoShift: string;
  chartZoomMode: ChartZoomMode;
  chartZoomOriginalSize: {
    uuid: string;
    mode: ResetZoomMode;
  };
  copyImage: string | null;
  chartMode: ChartMode;
  annotationsVisible: boolean;
  gradientLinesVisible: boolean;
  markersVisible: boolean;
  isBackendConnected: boolean;
  uiMode: UiMode;
  shouldShowBeforeUnloadWarning: boolean;
  isRibbonDisplayed: boolean;
  isSideNavPinned: boolean;
  isHelpPinned: boolean;
  isOneTimeInstructionShown: PartialEnumDictionary<OneTimeInstructionType, boolean>;
  currentMenuTab: HelpIds;
  exportDataUseTimestamps: boolean;
  canvasToRecord: HTMLCanvasElement | null;
  notificationsStates: NotificationState[];
  notifications: Notification[];
}

export type ModuleSection =
  | 'well'
  | 'completion'
  | 'fluids'
  | 'gravel'
  | 'gauge-data'
  | 'pumping'
  | 'settings'
  | 'results'
  | 'reporting'
  | 'psd-analysis'
  | 'trend-analysis'
  | 'calculators'
  | 'psd-input';

export type WellTab = 'general-data' | 'casing-data' | 'reservoir-data' | 'caliper-data';
export type CompletionTab = 'lower-completion' | 'running-string' | 'shunt-tubes' | 'schematic' | 'volumes';
export type FluidsTab = 'project-fluids' | 'rheometer-data' | 'database-fluids';
export type GravelsTab = 'project-gravels' | 'database-gravels';
export type PumpingTab = 'initial-data' | 'well-fluids' | 'schedule' | 'schedule-options';
export type PsdAnalysisTab = 'psd-data' | 'psd-chart' | 'psd-comparison' | 'sand-production' | 'summary' | 'screen-and-gravel-selection';
export type CalculatorsTab = 'friction' | 'settling' | 'resuspension' | 'masp' | 'injection-test' | 'leakoff-coefficient';
export type ReportingTab = 'report' | 'reporting-chart';
export type ResultsTab = 'evaluation-animation' | 'simulation-animation' | 'well-visualization' | 'simulation-summary';
export type GaugeDataTab = 'data' | 'chart';
export type TrendAnalysisTab = 'trend-analysis';

export enum ChartMode {
  annotate = 'annotate',
  editVerticalMarker = 'editVerticalMarker',
  editHorizontalMarker = 'editHorizontalMarker',
  editGradientLine = 'gradientLine',
  default = 'default',
}

export const isChartInMarkerMode = (mode: ChartMode): boolean =>
  mode === ChartMode.editHorizontalMarker || mode === ChartMode.editVerticalMarker;

export enum UiMode {
  loading = 0,
  ready = 1,
}

export enum XYShiftAxis {
  none = 0,
  x = 1,
  y = 2,
}

export const initialUiModuleState: UiState = {
  appModuleType: ModuleType.None,
  appModuleSection: 'well',
  wellTab: 'general-data',
  completionTab: 'lower-completion',
  fluidsTab: 'project-fluids',
  gravelsTab: 'project-gravels',
  pumpingTab: 'initial-data',
  psdAnalysisTab: 'psd-data',
  calculatorsTab: null,
  resultsTab: 'simulation-animation',
  reportingTab: 'report',
  gaugeDataTab: 'data',
  xyAxisShift: XYShiftAxis.none,
  isYAutoShiftActive: false,
  isXAutoShiftActive: false,
  evaluateResetAutoShift: '',
  chartZoomMode: 'xy',
  uiMode: UiMode.loading,
  annotationsVisible: true,
  gradientLinesVisible: true,
  markersVisible: true,
  chartZoomOriginalSize: {
    uuid: '',
    mode: ResetZoomMode.ALL,
  },
  copyImage: null,
  chartMode: ChartMode.default,
  isBackendConnected: false,
  trendAnalysisTab: 'trend-analysis',
  shouldShowBeforeUnloadWarning: true,
  isRibbonDisplayed: true,
  isSideNavPinned: false,
  isHelpPinned: false,
  isOneTimeInstructionShown: {},
  currentMenuTab: 'general',
  exportDataUseTimestamps: false,
  canvasToRecord: null,
  notificationsStates: [],
  notifications: [],
};
