import { CasingPipeDto } from '../../../well/dto/casing/casing-pipe.dto';
import { PipeType } from '../../../../dto/pipe.dto';
import { BasePipeFactory } from '../../base.pipe.factory';
import { Pipe } from '../../pipe';
import { ITableRow } from '../../../../common/common-grid.interfaces';

export interface OpenHolePipe extends Pipe {
  PipeType: PipeType.Open_Hole;
  IsReservoirSection: true;
}

export class OpenHolePipeFactory {
  public static createFromCasing(casingDto: CasingPipeDto): OpenHolePipe {
    const pipe = BasePipeFactory.createFromDto(casingDto);
    const perforatedCasingPipe: OpenHolePipe = {
      ...pipe,
      PipeType: PipeType.Open_Hole,
      IsReservoirSection: true,
    };
    return perforatedCasingPipe;
  }
}

export function isOpenHolePipe(pipe: Pipe): pipe is OpenHolePipe {
  return pipe.PipeType === PipeType.Open_Hole;
}

export function isOpenHoleRow(row: ITableRow<Pipe>): row is ITableRow<OpenHolePipe> {
  return isOpenHolePipe(row.rowData);
}
