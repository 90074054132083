import { ChangeDetectorRef, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { TabMenuItem } from '../../../common-modules/tabbed-page/tabbed-page.component';
import { DbDependentComponent } from '../../../common-modules/db-connection/db-dependent.component';
import { getHelpUrlSuccessAction } from '../../../+store/help/help.actions';

@Component({
  templateUrl: './test-page.component.html',
  styleUrls: ['./test-page.component.scss'],
})
export class TestPageComponent extends DbDependentComponent {
  public tabs: TabMenuItem[] = [
    { name: 'Tab One', routerLink: 'tab-one' },
    { name: 'Tab Two', routerLink: 'tab-two' },
    { name: 'Tab Three', routerLink: 'tab-three' },
  ];

  constructor(store: Store, cdRef: ChangeDetectorRef) {
    super(store, cdRef);

    this.store.dispatch(getHelpUrlSuccessAction({ helpUrl: 'assets/help-files/pumping/tool-position.html' }));
  }
}
