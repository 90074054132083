import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WellComponent } from './well.component';
import { GeneralDataComponent } from './general-data/general-data.component';
import { CasingDataComponent } from './casing-data/casing-data.component';
import { ReservoirDataComponent } from './reservoir-data/reservoir-data.component';
import { CaliperDataComponent } from './caliper-data/caliper-data.component';
import { TabbedPageModule } from '../../../common-modules/tabbed-page/tabbed-page.component';
import { PagePanelModule } from '../../../common-modules/page-panel/page-panel.component';
import { UnitsModule } from '../../../common-modules/units/units.module';
import { TemperatureProfileComponent } from './general-data/temperature-profile/temperature-profile.component';
import { LocationTypeComponent } from './general-data/location-type/location-type.component';
import { SurveyPreviewChartComponent } from './general-data/survey-preview-chart/survey-preview-chart.component';
import { SurveyGridComponent } from './general-data/survey-grid/survey-grid.component';
import { FormsModule } from '@angular/forms';
import { GridModule } from '../../../shared/components/grid/grid.module';
import { ChartModule } from '../../../common-modules/chart/chart.module';
import { CheckBoxModule } from '../../../shared/components/form-components/check-box/check-box.component';
import { RadioGroupModule } from '../../../shared/components/form-components/radio-group/radio-group.component';
import { CasingDataGridComponent } from './casing-data/casing-data-grid/casing-data-grid.component';
import { PerforationPropertiesComponent } from './casing-data/perforation-properties/perforation-properties.component';
import { DepthDefinitionComponent } from './reservoir-data/depth-definition/depth-definition.component';
import { PressureDefinitionComponent } from './reservoir-data/pressure-definition/pressure-definition.component';
import { LossDefinitionComponent } from './reservoir-data/loss-definition/loss-definition.component';
import { ReservoirGridComponent } from './reservoir-data/reservoir-grid/reservoir-grid.component';
import { GridSelectComponentModule } from '../../../common-modules/grid-select/grid-select.component';
import { ZonePropertiesComponent } from './reservoir-data/zone-properties/zone-properties.component';
import { FormInputModule } from '../../../shared/components/form-components/form-input/form-input.component';
import { UnwrapDirectiveModule } from '../../../shared/directives/unwrap.directive';
import { CaliperDataGridComponent } from './caliper-data/caliper-data-grid/caliper-data-grid.component';
import { CaliperPreviewChartComponent } from './caliper-data/caliper-preview-chart/caliper-preview-chart.component';
import { SelectModule } from '../../../shared/components/form-components/select/select.module';
import { ButtonModule } from '../../../shared/components/button/button.module';
import { RouterModule } from '@angular/router';
import { SurveyPreviewChartRendererComponent } from './general-data/survey-preview-chart-renderer/survey-preview-chart-renderer.component';
import { CaliperPreviewChartRendererComponent } from './caliper-data/caliper-preview-chart-renderer/caliper-preview-chart-renderer.component';
import { EqualizeSymbolsDirectiveModule } from '../../../shared/directives/equalize-symbols.directive';
import { OnResizeDirectiveModule } from '../../../shared/directives/on-resize.directive';

@NgModule({
  declarations: [
    WellComponent,
    GeneralDataComponent,
    CasingDataComponent,
    ReservoirDataComponent,
    CaliperDataComponent,
    TemperatureProfileComponent,
    LocationTypeComponent,
    SurveyPreviewChartComponent,
    SurveyPreviewChartRendererComponent,
    SurveyGridComponent,
    CasingDataGridComponent,
    PerforationPropertiesComponent,
    DepthDefinitionComponent,
    PressureDefinitionComponent,
    LossDefinitionComponent,
    ReservoirGridComponent,
    ZonePropertiesComponent,
    CaliperDataGridComponent,
    CaliperPreviewChartComponent,
    CaliperPreviewChartRendererComponent,
  ],
  imports: [
    PagePanelModule,
    CommonModule,
    TabbedPageModule,
    UnitsModule,
    FormsModule,
    GridModule,
    ChartModule,
    CheckBoxModule,
    RadioGroupModule,
    ButtonModule,
    SelectModule,
    GridSelectComponentModule,
    FormInputModule,
    UnwrapDirectiveModule,
    GridSelectComponentModule,
    RouterModule,
    EqualizeSymbolsDirectiveModule,
    OnResizeDirectiveModule,
  ],
  exports: [PerforationPropertiesComponent],
})
export class WellModule {}
