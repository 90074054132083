<ng-container *ngIf="selectedFluidData && selectedFluidData?.fluid?.RheologyType === RheologyType.Rheometer; else rheologyDisabled">
  <app-fluid-header [isReadOnly]="true"></app-fluid-header>
  <app-rheometer-test-configuration
    [selectedFluidData]="selectedFluidData"
    [temperatureSelect]="temperatureSelect"
    [temperatureSelectLabel]="temperatureSelectLabel"
    (rheometerPropertyChange)="onRheometerPropertyChanged($event)"
    (fluidPropertyChange)="onFluidPropertyChanged($event)"
    (click)="onHelpChange('fluids', 'test-configuration')"
  ></app-rheometer-test-configuration>
  <app-rheometer-test-data
    [selectedFluidData]="selectedFluidData"
    (click)="onHelpChange('fluids', 'rheometer-test-data')"
    [rheology]="rheology$ | async"
    (rheometerPropertyChange)="onRheometerPropertyChanged($event)"
  ></app-rheometer-test-data>
  <div class="buttons-panel">
    <div class="align-right">
      <button appButton data-cy="rheometer-add-temperature" type="button" class="btn btn-primary btn-sm" (buttonClick)="addTemperature()">
        Add Temperature
      </button>
      <button appButton type="button" class="btn btn-primary btn-sm" (buttonClick)="deleteTemperature()">Delete Temperature</button>
    </div>
  </div>
</ng-container>
<ng-template #rheologyDisabled>
  <div class="centered-message">Rheometer data is not defined for the selected fluid</div>
</ng-template>
