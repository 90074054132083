import { PSDReadingDto } from '../../dto/psd-reading.dto';
import { PsdReading } from './psd-reading';
import { createTableRow, createTableState, GridRowType, ITableRow, ITableState } from '../../../../common/common-grid.interfaces';
import { Dict, IDictionaryWithArray } from '../../../../common/state.helpers';
import { groupBy } from 'lodash';

export class PsdReadingsFactory {
  public static create(
    psdReadingsDtos: PSDReadingDto[],
    scenarioId: number,
    psdIds: string[],
  ): IDictionaryWithArray<ITableState<PsdReading>> {
    const psdReadingsByPSDId = groupBy(psdReadingsDtos, (data) => data.PSDId);
    const dict: Dict<ITableState<PsdReading>> = {};
    for (const psdId of psdIds) {
      const psdR = psdReadingsByPSDId[psdId] ?? [];
      dict[+psdId] = this.createTableState(psdR, scenarioId);
    }
    return { ids: psdIds, dict };
  }

  public static createTableState(psdReadingsDtos: PSDReadingDto[], scenarioId: number): ITableState<PsdReading> {
    const tableRows = [...psdReadingsDtos.map((dto, index) => this.createRowFromDto(dto, index))];
    return createTableState(tableRows);
  }

  public static createRowFromDto(
    psdReadingsDto: PSDReadingDto,
    index: number,
    isEditingDisabled = false,
    rowType: GridRowType = 'data',
  ): ITableRow<PsdReading> {
    return createTableRow<PsdReading>(this.createPsdReadings(psdReadingsDto), rowType, index, isEditingDisabled);
  }

  public static createPsdReadings(psdReadingsDto: PSDReadingDto): PsdReading {
    return {
      ...psdReadingsDto,
      error: {},
      isValid: true,
    };
  }

  public static psdReadingsToDto(psdReadings: PsdReading): PSDReadingDto {
    const { isValid, error, ...dto } = psdReadings;
    return dto;
  }

  public static createEmpty(scenarioId: number, psdId: number): PsdReading {
    return {
      Id: -1,
      PSDId: psdId,
      SortOrder: 0,
      SieveDescription: 'New Sieve',
      CumulativePercentWeightRetained: 0,
      PercentWeightRetained: 0,
      InitialSieveWeight: 0,
      FinalSieveWeight: 0,
      SieveOpening: 0,
      ScenarioId: scenarioId,
      error: {},
      isValid: true,
    };
  }

  public static createRowFromPaste(
    sieveOpening: number,
    initialSieveWeight: number,
    finalSieveWeight: number,
    percentWeightRetained: number,
    cumulativePercentWeightRetained: number,
    sieveDescription: string,
    scenarioId: number,
    psdId: number,
  ): ITableRow<PsdReading> {
    return this.createRowFromDto(
      {
        Id: -1,
        PSDId: psdId,
        SortOrder: 0,
        SieveDescription: sieveDescription,
        CumulativePercentWeightRetained: cumulativePercentWeightRetained,
        PercentWeightRetained: percentWeightRetained,
        InitialSieveWeight: initialSieveWeight,
        FinalSieveWeight: finalSieveWeight,
        SieveOpening: sieveOpening,
        ScenarioId: scenarioId,
      },
      0,
    );
  }
}
