import { ILeftNavElement, LeftNavHelpers } from '../../../shared/components/left-nav/left-nav.helpers';
import { ReportingTabDto } from '@dunefront/common/dto/reporting-tab.dto';
import { RouteModuleReporting, RouteModuleReportingTab, RouteModuleReportingTabReport } from './reporting.routes';

export const getReportingLeftMenu = (reportingTabs: ReportingTabDto[], isDisabled: boolean): ILeftNavElement => {
  return LeftNavHelpers.getNavElement(
    'Reporting',
    RouteModuleReporting,
    'icon-survey',
    true,
    false,
    [
      LeftNavHelpers.getItem(
        'Report',
        `${RouteModuleReporting}/${RouteModuleReportingTab}/${RouteModuleReportingTabReport}`,
        true,
        isDisabled,
      ),
      ...reportingTabs.map((tab) =>
        LeftNavHelpers.getItem(tab.TabName, `${RouteModuleReporting}/${RouteModuleReportingTab}/${tab.Id}`, true, isDisabled, !isDisabled),
      ),
    ],
    true,
    RouteModuleReporting,
  );
};
