import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { Pumping } from '@dunefront/common/modules/pumping/model/pumping/pumping';
import { ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';

@Component({
  selector: 'app-pump-pop-off',
  templateUrl: './pump-pop-off.component.html',
  styleUrls: ['./pump-pop-off.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PumpPopOffComponent {
  @Input() public pumping!: Pumping;
  @Output() public dataChange = new EventEmitter<ObjectChangeProp<Pumping>>();
  public UnitType = UnitSystem;
}
