import { UnitConverter } from '../../converter.interfaces';
import { PressureVolumeDerivativeUnit } from '../../../dto/unit-system.dto';

export class PressureVolumeDerivativeConverter extends UnitConverter {
  public static symbols: Array<string> = [
    '',
    'Pa/m³',
    'Pa/ft³',
    'Pa/bbl',
    'Pa/L',
    'Pa/galUS',
    'kPa/m³',
    'kPa/ft³',
    'kPa/bbl',
    'kPa/L',
    'kPa/galUS',
    'psi/m³',
    'psi/ft³',
    'psi/bbl',
    'psi/L',
    'psi/galUS',
    'kg/cm²/m³',
    'kg/cm²/ft³',
    'kg/cm²/bbl',
    'kg/cm²/L',
    'kg/cm²/galUS',
    'bar/m³',
    'bar/ft³',
    'bar/bbl',
    'bar/L',
    'bar/galUS',
    'MPa/m³',
    'MPa/ft³',
    'MPa/bbl',
    'MPa/L',
    'MPa/galUS',
  ];

  public static unit = PressureVolumeDerivativeUnit;

  public static fromSi(value: number, unit: PressureVolumeDerivativeUnit): number {
    switch (unit) {
      case PressureVolumeDerivativeUnit.Pascal_per_cubic_meter: {
        return value;
      }
      case PressureVolumeDerivativeUnit.Pascal_per_cubic_foot: {
        return value * 0.0283168466;
      }
      case PressureVolumeDerivativeUnit.Pascal_per_barrel: {
        return value * 0.158987;
      }
      case PressureVolumeDerivativeUnit.Pascal_per_litre: {
        return value * 0.001;
      }
      case PressureVolumeDerivativeUnit.Pascal_per_US_gallon: {
        return value * 0.00378541;
      }
      case PressureVolumeDerivativeUnit.Kilo_Pascal_per_cubic_meter: {
        return value * 0.001;
      }
      case PressureVolumeDerivativeUnit.Kilo_Pascal_per_cubic_foot: {
        return value * 0.0000283168;
      }
      case PressureVolumeDerivativeUnit.Kilo_Pascal_per_barrel: {
        return value * 0.000158987;
      }
      case PressureVolumeDerivativeUnit.Kilo_Pascal_per_litre: {
        return value * 0.000001;
      }
      case PressureVolumeDerivativeUnit.Kilo_Pascal_per_US_gallon: {
        return value * 0.0000037854;
      }
      case PressureVolumeDerivativeUnit.Pound_per_square_inch_per_cubic_meter: {
        return value * 0.0001450377;
      }
      case PressureVolumeDerivativeUnit.Pound_per_square_inch_per_cubic_foot: {
        return value * 0.000004107;
      }
      case PressureVolumeDerivativeUnit.Pound_per_square_inch_per_barrel: {
        return value * 0.0000230591;
      }
      case PressureVolumeDerivativeUnit.Pound_per_square_inch_per_litre: {
        return value * 1.45038e-7;
      }
      case PressureVolumeDerivativeUnit.Pound_per_square_inch_per_US_gallon: {
        return value * 5.49027e-7;
      }
      case PressureVolumeDerivativeUnit.Kilogram_per_square_centimeter_per_cubic_meter: {
        return value * 0.0000101972;
      }
      case PressureVolumeDerivativeUnit.Kilogram_per_square_centimeter_per_cubic_foot: {
        return value * 2.88751e-7;
      }
      case PressureVolumeDerivativeUnit.Kilogram_per_square_centimeter_per_barrel: {
        return value * 0.0000016212;
      }
      case PressureVolumeDerivativeUnit.Kilogram_per_square_centimeter_per_litre: {
        return value * 1.0197e-8;
      }
      case PressureVolumeDerivativeUnit.Kilogram_per_square_centimeter_per_US_gallon: {
        return value * 3.86e-8;
      }
      case PressureVolumeDerivativeUnit.Bar_per_cubic_meter: {
        return value * 0.00001;
      }
      case PressureVolumeDerivativeUnit.Bar_per_cubic_foot: {
        return value * 2.8317e-7;
      }
      case PressureVolumeDerivativeUnit.Bar_per_barrel: {
        return value * 0.0000015899;
      }
      case PressureVolumeDerivativeUnit.Bar_per_litre: {
        return value * 1.0e-8;
      }
      case PressureVolumeDerivativeUnit.Bar_per_US_gallon: {
        return value * 3.7854e-8;
      }

      case PressureVolumeDerivativeUnit.MPa_per_cubic_meter: {
        return value * 1.0e-6;
      }
      case PressureVolumeDerivativeUnit.MPa_per_cubic_foot: {
        return value * 2.8317e-8;
      }
      case PressureVolumeDerivativeUnit.MPa_per_barrel: {
        return value * 1.58987e-7;
      }
      case PressureVolumeDerivativeUnit.MPa_per_litre: {
        return value * 1.0e-9;
      }
      case PressureVolumeDerivativeUnit.MPa_per_US_gallon: {
        return value * 3.78541e-9;
      }
      default: {
        throw new TypeError('Unknown Pressure Volume Derivative Unit:' + unit);
      }
    }
  }

  public static toSi(value: number, unit: PressureVolumeDerivativeUnit): number {
    switch (unit) {
      case PressureVolumeDerivativeUnit.Pascal_per_cubic_meter: {
        return value;
      }
      case PressureVolumeDerivativeUnit.Pascal_per_cubic_foot: {
        return value / 0.0283168466;
      }
      case PressureVolumeDerivativeUnit.Pascal_per_barrel: {
        return value / 0.158987;
      }
      case PressureVolumeDerivativeUnit.Pascal_per_litre: {
        return value / 0.001;
      }
      case PressureVolumeDerivativeUnit.Pascal_per_US_gallon: {
        return value / 0.00378541;
      }
      case PressureVolumeDerivativeUnit.Kilo_Pascal_per_cubic_meter: {
        return value / 0.001;
      }
      case PressureVolumeDerivativeUnit.Kilo_Pascal_per_cubic_foot: {
        return value / 0.0000283168;
      }
      case PressureVolumeDerivativeUnit.Kilo_Pascal_per_barrel: {
        return value / 0.000158987;
      }
      case PressureVolumeDerivativeUnit.Kilo_Pascal_per_litre: {
        return value / 0.000001;
      }
      case PressureVolumeDerivativeUnit.Kilo_Pascal_per_US_gallon: {
        return value / 0.0000037854;
      }
      case PressureVolumeDerivativeUnit.Pound_per_square_inch_per_cubic_meter: {
        return value / 0.0001450377;
      }
      case PressureVolumeDerivativeUnit.Pound_per_square_inch_per_cubic_foot: {
        return value / 0.000004107;
      }
      case PressureVolumeDerivativeUnit.Pound_per_square_inch_per_barrel: {
        return value / 0.0000230591;
      }
      case PressureVolumeDerivativeUnit.Pound_per_square_inch_per_litre: {
        return value / 1.45038e-7;
      }
      case PressureVolumeDerivativeUnit.Pound_per_square_inch_per_US_gallon: {
        return value / 5.49027e-7;
      }
      case PressureVolumeDerivativeUnit.Kilogram_per_square_centimeter_per_cubic_meter: {
        return value / 0.0000101972;
      }
      case PressureVolumeDerivativeUnit.Kilogram_per_square_centimeter_per_cubic_foot: {
        return value / 2.88751e-7;
      }
      case PressureVolumeDerivativeUnit.Kilogram_per_square_centimeter_per_barrel: {
        return value / 0.0000016212;
      }
      case PressureVolumeDerivativeUnit.Kilogram_per_square_centimeter_per_litre: {
        return value / 1.0197e-8;
      }
      case PressureVolumeDerivativeUnit.Kilogram_per_square_centimeter_per_US_gallon: {
        return value / 3.86e-8;
      }
      case PressureVolumeDerivativeUnit.Bar_per_cubic_meter: {
        return value / 0.00001;
      }
      case PressureVolumeDerivativeUnit.Bar_per_cubic_foot: {
        return value / 2.8317e-7;
      }
      case PressureVolumeDerivativeUnit.Bar_per_barrel: {
        return value / 0.0000015899;
      }
      case PressureVolumeDerivativeUnit.Bar_per_litre: {
        return value / 1.0e-8;
      }
      case PressureVolumeDerivativeUnit.Bar_per_US_gallon: {
        return value / 3.7854e-8;
      }

      case PressureVolumeDerivativeUnit.MPa_per_cubic_meter: {
        return value / 1.0e-6;
      }
      case PressureVolumeDerivativeUnit.MPa_per_cubic_foot: {
        return value / 2.8317e-8;
      }
      case PressureVolumeDerivativeUnit.MPa_per_barrel: {
        return value / 1.58987e-7;
      }
      case PressureVolumeDerivativeUnit.MPa_per_litre: {
        return value / 1.0e-9;
      }
      case PressureVolumeDerivativeUnit.MPa_per_US_gallon: {
        return value / 3.78541e-9;
      }
      default: {
        throw new TypeError('Unknown Pressure Volume Derivative Unit:' + unit);
      }
    }
  }

  public static getSymbol(unit: PressureVolumeDerivativeUnit): string {
    if (unit == null) {
      return '';
    }
    return this.symbols[unit];
  }
}
