import { WsAction } from '../../ws.action';
import { CancelDetachInputData, DetachInputData } from './licensing.interfaces';
import { ConnectionLicensingConfig } from '../db-connection/db-connection.actions';

export const LicensingModuleName = 'LicensingModule';

export enum LicensingModuleActionTypes {
  GetLicenseInfo = '[Licensing] GetLicenseInfo',
  LicenseLogout = '[Licensing] Logout',
  LicenseSwitch = '[Licensing] LicenseSwitch',
  CheckLicenseSessions = '[Licensing] CheckSessions',
  LicenseRelogin = '[Licensing] LicenseRelogin',
  GenerateDiagnosticsReport = '[Licensing] GenerateDiagnosticsReport',
  DetachLicense = '[Licensing] DetachLicense',
  CancelDetachLicense = '[Licensing] CancelDetachLicense',
}

export class GetLicenseInfoAction extends WsAction {
  public override readonly type = LicensingModuleActionTypes.GetLicenseInfo;
}

export class LicenseLogoutAction extends WsAction {
  public override readonly type = LicensingModuleActionTypes.LicenseLogout;
}

export class LicenseSwitchAction extends WsAction {
  public override readonly type = LicensingModuleActionTypes.LicenseSwitch;

  constructor(public licensingConfig: ConnectionLicensingConfig) {
    super();
  }
}

export class DetachLicenseAction extends WsAction implements DetachInputData {
  public override readonly type = LicensingModuleActionTypes.DetachLicense;
  constructor(
    public licenseId: string,
    public productId: string,
    public duration: number,
  ) {
    super();
  }
}

export class CancelDetachLicenseAction extends WsAction implements CancelDetachInputData {
  public override readonly type = LicensingModuleActionTypes.CancelDetachLicense;
  constructor(
    public licenseId: string,
    public parentLicenseId: string,
  ) {
    super();
  }
}

export class LicenseCheckSessionsAction extends WsAction {
  public override readonly type = LicensingModuleActionTypes.CheckLicenseSessions;
}

export class LicenseReloginAction extends WsAction {
  public override readonly type = LicensingModuleActionTypes.LicenseRelogin;
  constructor(public licensingConfig: ConnectionLicensingConfig) {
    super();
  }
}

export class LicenseGenerateDiagnosticsReport extends WsAction {
  public override readonly type = LicensingModuleActionTypes.GenerateDiagnosticsReport;
}

export type LicensingModuleActions =
  | GetLicenseInfoAction
  | LicenseLogoutAction
  | LicenseSwitchAction
  | LicenseCheckSessionsAction
  | LicenseReloginAction
  | LicenseGenerateDiagnosticsReport
  | DetachLicenseAction
  | CancelDetachLicenseAction;
