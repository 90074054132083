import { AfterViewInit, Directive, ElementRef, NgModule } from '@angular/core';

@Directive({
  selector: '[appDateFormatPosition]',
})
export class DateFormatSuggestionPositionDirective implements AfterViewInit {
  constructor(private el: ElementRef) {}

  public ngAfterViewInit(): void {
    const box = this.el.nativeElement;
    const boxWidth = box.offsetWidth;

    const chips: HTMLElement[] = Array.from(box.querySelectorAll('p-chip'));
    const chipsWidth = chips.reduce((prev, next) => (prev += next.offsetWidth), 0);

    // if there are more suggestions, move whole box, to the left border
    if (boxWidth < chipsWidth) {
      box.style.left = '-160px';
      box.style.width = chipsWidth + 'px';
    }
  }
}
@NgModule({
  declarations: [DateFormatSuggestionPositionDirective],
  exports: [DateFormatSuggestionPositionDirective],
})
export class DateFormatSuggestionPositionModule {}
