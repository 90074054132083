import { createSelector } from '@ngrx/store';
import { getSimulateMenuValidation } from '../simulate-evaluate-page.validators';
import { getCurrentAppModuleType } from '../../../+store/ui/ui.selectors';
import { getValidatedPumpingModuleState } from '../../../+store/pumping/selectors/pumping-validation.selectors';
import { DraggableMenuItem, ILeftNavElement, LeftNavHelpers } from '../../../shared/components/left-nav/left-nav.helpers';
import { MenuItem } from 'primeng/api';

import { ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';

export const getPumpingLeftNavElement$ = createSelector(
  getSimulateMenuValidation,
  getCurrentAppModuleType,
  getValidatedPumpingModuleState,
  (isValid, moduleType, data): ILeftNavElement => {
    const items: MenuItem[] = [
      LeftNavHelpers.getItem('Initial data', 'pumping/initial-data', data.isInitialScreenValid),
      LeftNavHelpers.getItem('Well fluids', 'pumping/well-fluids', data.isWellFluidsScreenValid),
      LeftNavHelpers.getItem('Schedule', 'pumping/schedule', data.isScheduleScreenValid),
    ];

    if (moduleType === ModuleType.Simulate || moduleType === ModuleType.Simulate_CH) {
      items.push({
        label: 'Schedule options',
        routerLink: 'pumping/schedule-options',
        isError: !data.isAutomaticSchedulingPanelValid,
        isWarning: !data.isScheduleGeneratorPanelValid,
      } as DraggableMenuItem);
    }

    return LeftNavHelpers.toNavElement({
      label: 'Pumping',
      uri: 'pumping',
      icon: 'icon-pumping',
      isError: !isValid.isPumpingValid,
      isWarning: !data.isScheduleGeneratorPanelValid,
      items,
    });
  },
);
