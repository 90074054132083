import { createTableRow, GridRowType, ITableRow } from '../../../../common/common-grid.interfaces';
import { createEmptyZoneDto, ZoneDto } from '../../dto/zone/zone.dto';
import { ZoneModel } from './zone.model';
import { ZoneCalculations } from '../../dto/zone/zone.calculations';

export class ZoneFactory {
  public static createRowListFromDtos(dtoRows: ZoneDto[], scenarioId: number): ITableRow<ZoneModel>[] {
    const rowArray: ITableRow<ZoneModel>[] = [
      ...dtoRows.map((dtoRow, index) => this.createRowFromDto(dtoRow, index)),
      this.createInsertRow(scenarioId, dtoRows.length),
    ];
    return rowArray;
  }

  public static createRowFromDto(
    rowDto: ZoneDto,
    rowIndex: number,
    isEditingDisabled = false,
    rowType: GridRowType = 'data',
  ): ITableRow<ZoneModel> {
    if (rowIndex === 0) {
      rowType = 'first-row';
    }
    return createTableRow<ZoneModel>(this.createZone(rowDto), rowType, rowIndex, isEditingDisabled);
  }

  public static createZone(zoneDto: ZoneDto): ZoneModel {
    const zone: ZoneModel = {
      ...zoneDto,
      Length: ZoneCalculations.ZoneLength(zoneDto),
      Height: ZoneCalculations.ZoneHeight(zoneDto),
      PlaneStrainModulus: ZoneCalculations.PlaneStrainModulus(zoneDto),
      error: {},
    };
    return zone;
  }

  public static createInsertRow(scenarioId: number, rowIndex: number): ITableRow<ZoneModel> {
    const emptyZone = this.createEmpty(scenarioId);
    return createTableRow(emptyZone, 'insert-row', rowIndex, false);
  }

  public static createEmpty(scenarioId: number): ZoneModel {
    const rowData: ZoneModel = this.createZone(createEmptyZoneDto(scenarioId));
    return rowData;
  }

  public static toDto(zone: ZoneModel): ZoneDto {
    const { Length, Height, PlaneStrainModulus, error, ...zoneDto } = zone;
    return zoneDto;
  }
}
