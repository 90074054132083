import { Injectable } from '@angular/core';

@Injectable()
export class GridStackService {
  private gridIds: string[] = [];

  public addGrid(id: string): void {
    this.gridIds.push(id);
  }

  public removeGrid(id: string): void {
    this.gridIds = this.gridIds.filter((gridId) => gridId !== id);
  }

  public getLastGridId(): string {
    return this.gridIds[this.gridIds.length - 1];
  }
}
