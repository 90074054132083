import { UnitConverter } from '../../converter.interfaces';
import { MassUnit } from '../../../dto/unit-system.dto';

export class MassConverter extends UnitConverter {
  public static symbols: Array<string> = ['', 'kg', 'g', 'mg', 'lbm', 'oz', 'klbm', 'hlbm', 'ton US', 'ton UK', 'tonne'];

  public static unit = MassUnit;

  public static fromSi(value: number, unit: MassUnit): number {
    switch (unit) {
      case MassUnit.Kilogram: {
        return value;
      }
      case MassUnit.Gram: {
        return value * 1e3;
      }
      case MassUnit.Milligram: {
        return value * 1e6;
      }
      case MassUnit.Pound: {
        return value * 2.20462262;
      }
      case MassUnit.Ounce: {
        return value * 35.2739619;
      }
      case MassUnit.ThousandPound: {
        return value * 2.20462262e-3;
      }
      case MassUnit.Hundred_Pound: {
        return value * 2.20462262e-2;
      }
      case MassUnit.Ton_US: {
        return value * 1.102311311e-3;
      }
      case MassUnit.Ton_UK: {
        return value * 9.842035333e-4;
      }
      case MassUnit.Tonne: {
        return value * 1.0e-3;
      }
      default: {
        throw new TypeError('Unknown Mass Unit:' + unit);
      }
    }
  }

  public static toSi(value: number, unit: MassUnit): number {
    switch (unit) {
      case MassUnit.Kilogram: {
        return value;
      }
      case MassUnit.Gram: {
        return value / 1e3;
      }
      case MassUnit.Milligram: {
        return value / 1e6;
      }
      case MassUnit.Pound: {
        return value / 2.20462262;
      }
      case MassUnit.Ounce: {
        return value / 35.2739619;
      }
      case MassUnit.ThousandPound: {
        return value / 2.20462262e-3;
      }
      case MassUnit.Hundred_Pound: {
        return value / 2.20462262e-2;
      }
      case MassUnit.Ton_US: {
        return value / 1.102311311e-3;
      }
      case MassUnit.Ton_UK: {
        return value / 9.842035333e-4;
      }
      case MassUnit.Tonne: {
        return value / 1.0e-3;
      }
      default: {
        throw new TypeError('Unknown Mass Unit:' + unit);
      }
    }
  }

  public static getSymbol(unit: MassUnit): string {
    return this.symbols[unit];
  }
}
