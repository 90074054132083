import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { UnitsModule } from '../../../common-modules/units/units.module';
import { GridComponent } from './grid.component';
import { TableCellComponent } from './table-cell/table-cell.component';
import { GridSelectComponentModule } from '../../../common-modules/grid-select/grid-select.component';
import { TableVirtualScrollModule } from 'ng-table-virtual-scroll';
import { TableCellHeaderComponent } from './table-cell-header/table-cell-header.component';
import { FormInputModule } from '../form-components/form-input/form-input.component';
import { CheckBoxModule } from '../form-components/check-box/check-box.component';
import { ButtonModule } from '../button/button.module';
import { ContextMenuModule } from 'primeng/contextmenu';
import { GridStackService } from './grid-stack.service';
import { ClickOutsideModule } from '../../directives/click-outside.directive';

@NgModule({
  imports: [
    CommonModule,
    ScrollingModule,
    MatTableModule,
    MatIconModule,
    UnitsModule,
    ButtonModule,
    GridSelectComponentModule,
    TableVirtualScrollModule,
    FormInputModule,
    CheckBoxModule,
    ContextMenuModule,
    ClickOutsideModule,
  ],
  declarations: [GridComponent, TableCellComponent, TableCellHeaderComponent],
  exports: [GridComponent],
  providers: [GridStackService],
})
export class GridModule {}
