import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { PanelButtonComponent } from '../panel.button.component';
import { Store } from '@ngrx/store';
import { openImportDataModalAction } from '../../../../+store/import-data/import-data.actions';
import {
  editGaugeDataChartAction,
  showOneTimeInstructionPopupAction,
  xAxisAutoShiftAction,
  xAxisShiftAction,
  yAxisAutoShiftAction,
  yAxisShiftAction,
} from '../../../../+store/ui/ui.actions';
import { AnalysisMenuProps, getAnalysisMenuProps } from '../../../../+store/menu-selectors/analysis/menu-analysis.selector';
import { resetYAxisShiftAction } from '../../../../+store/range/range.actions';
import { ModalService } from '../../../modals/modal.service';
import { Observable } from 'rxjs';
import { resetXAxisShiftAction } from '../../../../+store/reporting/reporting.actions';
import { OneTimeInstructionType } from '../../../../+store/ui/ui-module.state';

@Component({
  selector: 'app-analysis-menu-panel',
  templateUrl: './analysis-menu-panel.component.html',
  styleUrls: ['./analysis-menu-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnalysisMenuPanelComponent extends PanelButtonComponent {
  public analysisMenuProps$: Observable<AnalysisMenuProps> = this.store.select(getAnalysisMenuProps);

  constructor(
    protected override store: Store,
    protected cdRef: ChangeDetectorRef,
    private modalService: ModalService,
  ) {
    super(store);
  }

  public onImportDataClick(): void {
    this.store.dispatch(openImportDataModalAction());
  }

  public onEditChartClick(): void {
    this.store.dispatch(editGaugeDataChartAction({}));
  }

  public onXShiftClick(): void {
    this.store.dispatch(xAxisShiftAction());
  }

  public onYShiftClick(): void {
    this.store.dispatch(yAxisShiftAction());
  }

  public onYAutoShift(): void {
    this.store.dispatch(yAxisAutoShiftAction());
    this.store.dispatch(showOneTimeInstructionPopupAction({ instructionType: OneTimeInstructionType.verticalAutoShift }));
  }

  public onXAutoShift(): void {
    this.store.dispatch(xAxisAutoShiftAction());
    this.store.dispatch(showOneTimeInstructionPopupAction({ instructionType: OneTimeInstructionType.horizontalAutoShift }));
  }

  public async onResetYShiftClick(): Promise<void> {
    if (
      await this.modalService.showConfirm(
        'Y-Shift will be reset and the data restored to its original values. Do you want to continue?',
        'Warning',
      )
    ) {
      this.store.dispatch(resetYAxisShiftAction());
    }
  }

  public async onResetXShiftClick(): Promise<void> {
    if (
      await this.modalService.showConfirm(
        'X-Shift will be reset and the data restored to its original values. Do you want to continue?',
        'Warning',
      )
    ) {
      this.store.dispatch(resetXAxisShiftAction());
    }
  }
}
