import { createSelector } from '@ngrx/store';
import { getValidatedGravelModuleState } from '../../gravel/gravel.selectors';
import { getValidatedEvaluationPumpingSchedule, getValidatedPumpingSchedule } from './pumping-validation.selectors';
import { IPumpingScheduleValidationDeps } from '@dunefront/common/modules/pumping/model/pumping-schedule/pumping-schedule.validation';
import { PumpingScheduleCalculations } from '@dunefront/common/modules/pumping/model/pumping-schedule/pumping-schedule.calculations';
import { getCurrentPumpingModuleState } from './pumping.selectors';
import { getValidatedWellModuleState } from '../../well/validated-well.selectors';

export const getPumpingScheduleValidationDeps = createSelector(
  getValidatedWellModuleState,
  getCurrentPumpingModuleState,
  (...[well, pumpingState]): IPumpingScheduleValidationDeps => ({
    isLossCalculated: well.IsLossRateCalculated,
    pumping: pumpingState.pumping,
  }),
);

export const getCalculatedSimulatePumpingSchedule = createSelector(
  getValidatedPumpingSchedule,
  getValidatedGravelModuleState,
  getPumpingScheduleValidationDeps,
  (...[pumpingSchedule, gravelState, deps]) =>
    PumpingScheduleCalculations.UpdateCalculatedScheduleProperties(pumpingSchedule, gravelState.Gravels, deps),
);

export const getCalculatedEvaluationPumpingSchedule = createSelector(
  getValidatedEvaluationPumpingSchedule,
  getValidatedGravelModuleState,
  getPumpingScheduleValidationDeps,
  (...[pumpingSchedule, gravelState, deps]) =>
    PumpingScheduleCalculations.UpdateCalculatedScheduleProperties(pumpingSchedule, gravelState.Gravels, deps),
);
