import { createSelector, Store } from '@ngrx/store';
import { OneTimeInstructionType, UiState } from '../../+store/ui/ui-module.state';
import { ModalService } from '../../common-modules/modals/modal.service';
import { getUiState } from '../../+store/ui/ui.selectors';
import { setOneTimeInstructionPopupShownAction } from '../../+store/ui/ui.actions';
import {
  getAreCurrentCalculatorResultsPresent,
  getAreCurrentResultsPresent,
  getAreFluidOrGravelDependantCalculatorResultsPresent,
} from '../../+store/calculation-engine/calculation-engine-results.selectors';
import { isCalculatorModuleType, isLongCalculationModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import { getDeleteResultsParams, IDeleteResultsParams } from '../../+store/import-data/import-data.selectors';
import { BackendConnectionService } from '../backend-connection/backend-connection.service';
import { OneTimeMessageHelpers } from '../../+store/ui/one-time-message-helpers';
import { Injectable } from '@angular/core';
import { deleteResultsAction } from '../../+store/data-storage/data-storage.actions';
import { combineLatest, firstValueFrom } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { selectUrl } from '../../+store/router/router.selectors';
import { RouteModuleFluids } from '../../pages/common/fluids/fluids-routes-names';
import { RouteModuleGravels } from '../../pages/common/gravel/gravel-routes-names';
import { filterNil } from '@dunefront/common/common/state.helpers';
import { RouterHelperService } from './router-helper.service';

const getAreResultsPresent = createSelector(
  getAreCurrentResultsPresent,
  getAreCurrentCalculatorResultsPresent,
  (areCurrentResultsPresent, areCalculatorResultsPresent) => areCurrentResultsPresent || areCalculatorResultsPresent,
);

const getInputsHelperData = createSelector(
  getUiState,
  getAreResultsPresent,
  getDeleteResultsParams,
  (...[uiState, areResultsPresent, deleteResultsParams]): InputsHelperData => ({
    uiState,
    areResultsPresent,
    ...deleteResultsParams,
  }),
);

@Injectable({ providedIn: 'root' })
export class InputsHelperService {
  private data!: InputsHelperData;
  private isDeleteNeededByCalculators = false;

  constructor(
    private store: Store,
    public modalService: ModalService,
    protected wsService: BackendConnectionService,
    protected routerHelperService: RouterHelperService,
  ) {
    this.store.select(getInputsHelperData).subscribe((data) => (this.data = data));

    // isDeleteNeededByCalculators is used to indicate that calculators which depends on fluids or gravels should be cleared.
    // It happens when checkResultsAndDeleteIfNeeded method is triggered from fluids or gravels pages and some of those calculators
    // have results
    combineLatest([this.store.select(selectUrl), this.store.select(getAreFluidOrGravelDependantCalculatorResultsPresent)])
      .pipe(
        filterNil(),
        map(
          ([currentUrl, areFluidOrGravelDependantCalculatorResultsPresent]) =>
            (this.routerHelperService.isPageCurrentlyVisible(currentUrl, RouteModuleFluids) ||
              this.routerHelperService.isPageCurrentlyVisible(currentUrl, RouteModuleGravels)) &&
            areFluidOrGravelDependantCalculatorResultsPresent,
        ),
      )
      .subscribe((isDeleteNeededByCalculators) => {
        this.isDeleteNeededByCalculators = isDeleteNeededByCalculators;
      });
  }

  public async checkResultsAndDeleteIfNeeded(isUiLockable: boolean, confirmOnly = false): Promise<boolean> {
    const isCalculator = isCalculatorModuleType(this.data.moduleType);

    if (
      !isUiLockable ||
      !(isCalculator || isLongCalculationModuleType(this.data.moduleType)) ||
      (!this.isDeleteNeededByCalculators && !this.data.areResultsPresent)
    ) {
      return true;
    }

    const deleteResultsOneTimeInstructionType = OneTimeInstructionType.deleteResults;
    if (OneTimeMessageHelpers.shouldShowInstruction(deleteResultsOneTimeInstructionType, this.data.uiState.isOneTimeInstructionShown)) {
      const message = OneTimeMessageHelpers.getMessage(deleteResultsOneTimeInstructionType);
      const deleteResults = await this.modalService.showConfirmNoUndoable(message, 'Warning');
      if (!deleteResults) {
        return false;
      }
      this.store.dispatch(setOneTimeInstructionPopupShownAction({ instructionType: deleteResultsOneTimeInstructionType }));
    }

    if (!this.isDeleteNeededByCalculators && isCalculator) {
      return true;
    }

    if (this.data.fileHash != null && !confirmOnly) {
      this.store.dispatch(deleteResultsAction());

      // wait while results are deleted
      await firstValueFrom(this.store.select(getAreResultsPresent).pipe(filter((resultsPresent) => !resultsPresent)));
    }

    return true;
  }
}

interface InputsHelperData extends IDeleteResultsParams {
  uiState: UiState;
  areResultsPresent: boolean;
}
