<div *ngIf="pumping && refVariables" class="grid-form-container">
  <app-check-box
    [source]="pumping"
    [key]="'IsBlankPackingForGravelRequired'"
    (valueChanged)="dataChange.emit($event)"
    [isUiLockable]="false"
    label="Blank Packing"
  >
  </app-check-box>

  <app-form-input
    [source]="pumping"
    [key]="'BlankPackingPercentageForGravelRequired'"
    (valueChanged)="dataChange.emit($event)"
    [decimalPlaces]="2"
    unitLabel="%"
    [isUiLockable]="false"
    [unitType]="UnitType.None"
    [disabled]="!isTxtBlankPackingPercentageForGravelRequiredEnabled"
    appUnwrap
  >
  </app-form-input>

  <app-form-input
    [source]="refVariables"
    [unitType]="UnitType.Mass"
    [disabled]="true"
    appUnwrap
    [key]="'WellboreGravel'"
    label="Wellbore Annulus"
    dataCy="wellbore-annulus"
  >
  </app-form-input>

  <app-form-input
    [source]="refVariables"
    [unitType]="UnitType.Mass"
    [disabled]="true"
    appUnwrap
    [key]="'PerforationGravel'"
    label="Perforations"
  >
  </app-form-input>
</div>
