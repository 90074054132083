import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TubeShape } from '@dunefront/common/dto/shunt-tube.dto';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { ShuntTube, Tube } from '@dunefront/common/modules/shunt-tube/shunt-tube';
import { changeProp, getChangeProp, ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';

@Component({
  selector: 'app-packing-tube-properties',
  templateUrl: './packing-tube-properties.component.html',
  styleUrls: ['./packing-tube-properties.component.scss'],
})
export class PackingTubePropertiesComponent {
  @Input() public shuntTube!: ShuntTube;

  @Output() public shuntTubeValueChange = new EventEmitter<ObjectChangeProp<ShuntTube>>();

  public UnitType = UnitSystem;

  public TubeShape = TubeShape;

  public get isRectangle(): boolean {
    return this.shuntTube.Shape === TubeShape.Rectangle;
  }

  public onValueChange(props: ObjectChangeProp<Tube>): void {
    const shuntTubeProp: ObjectChangeProp<ShuntTube> = getChangeProp(
      'PackingTube',
      changeProp(this.shuntTube.PackingTube, props),
      props.shouldResetResults,
    );
    this.shuntTubeValueChange.emit(shuntTubeProp);
  }
}
