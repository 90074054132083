import { IEquationVariable } from './equation-variable.dto';
import { ImportColumnDto, ImportColumnFactory } from '../data-storage/dto/import-column.dto';

export interface EquationColumn extends Omit<ImportColumnDto, 'Equation'> {
  Equation: IEquation;
}

export interface IEquation {
  variables: IEquationVariable[];
  equationFormula: string;
  constantValue: number | undefined;
}

export class EquationColumnFactory {
  private static createEmptyEquation = (): IEquation => ({ variables: [], equationFormula: '', constantValue: undefined });

  public static createEmpty = (fileId: number): EquationColumn => ({
    ...ImportColumnFactory.create(fileId),
    Equation: EquationColumnFactory.createEmptyEquation(),
  });
  public static toDto = (col: EquationColumn): ImportColumnDto => ({ ...col, Equation: JSON.stringify(col.Equation) });

  public static fromDto = (dto: ImportColumnDto): EquationColumn => ({
    ...dto,
    Equation: dto.Equation ? JSON.parse(dto.Equation) : EquationColumnFactory.createEmptyEquation(),
  });

  public static getConstantValue(dto: ImportColumnDto): number | undefined {
    return dto.Equation ? JSON.parse(dto.Equation).constantValue : undefined;
  }
}
