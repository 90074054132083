import { GravelDto } from './dto/gravel.dto';
import { CopyRowsWsAction, DeleteRowsWsAction, InsertRowsWsAction, UpdateRowsWsAction } from '../../ws.action';
import { IUpdateRowsProps } from '../../common/common-store-crud.interfaces';
import { Gravel } from './model/gravel';
import { WsActionPropsFactory } from '../../common/ws-action/ws-action-props.factory';
import { GravelFactory } from './model/gravel.factory';

export const GravelModuleName = 'GravelModule';

export enum GravelModuleActionTypes {
  GravelInsertRows = '[GravelModule] [Gravel] InsertRows',
  GravelUpdateRow = '[GravelModule] [Gravel] UpdateRow',
  GravelDeleteRows = '[GravelModule] [Gravel] DeleteRows',
  GravelCopyRow = '[GravelModule] [Gravel] CopyRow',
}

export interface LoadGravelsActionResponse {
  gravelDtos: GravelDto[];
}

export class GravelInsertRowsAction extends InsertRowsWsAction<GravelDto> {
  public override readonly type = GravelModuleActionTypes.GravelInsertRows;
}

export class GravelUpdateRowAction extends UpdateRowsWsAction<GravelDto> {
  public override readonly type = GravelModuleActionTypes.GravelUpdateRow;
  constructor(action: IUpdateRowsProps<Gravel>) {
    super(WsActionPropsFactory.updateAction(action, GravelFactory.gravelToDto));
  }
}

export class GravelDeleteRowsAction extends DeleteRowsWsAction {
  public override readonly type = GravelModuleActionTypes.GravelDeleteRows;
}

export class GravelCopyRowAction extends CopyRowsWsAction {
  public override readonly type = GravelModuleActionTypes.GravelCopyRow;
}

export type GravelModuleActions = GravelInsertRowsAction | GravelUpdateRowAction | GravelDeleteRowsAction | GravelCopyRowAction;
