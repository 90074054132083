import { IBackendConnectionService } from './backend-connection.interfaces';
import { WsAction } from '@dunefront/common/ws.action';
import { Observable, Subscriber } from 'rxjs';
import { DocumentExtension } from '@dunefront/common/common/helpFile';
import * as actions from '../../+store/backend-connection/backend-connection.actions';
import { parseError } from '@dunefront/common/exceptions/errors';
import { Store } from '@ngrx/store';
import { IErrorState } from '../components/error/client-error-handler.service';
import { WsActionResponse } from '@dunefront/common/response-ws.action';

export abstract class BaseBackendConnectionService implements IBackendConnectionService {
  constructor(protected store: Store) {}

  private _socketId: string | undefined;
  public get socketId(): string | undefined {
    return this._socketId;
  }

  public set socketId(value: string | undefined) {
    this._socketId = value;
  }

  public abstract connect(accessToken: string, sessionId: string): Promise<boolean>;

  public abstract disconnect(): void;

  public abstract emit<T>(moduleName: string, isDbDependent: boolean, action: WsAction): Observable<WsActionResponse<T>>;

  public abstract emitAsync<T>(moduleName: string, isDbDependent: boolean, action: WsAction): Promise<WsActionResponse<T>>;

  public abstract on(moduleName: string, isDbDependent: boolean): Observable<WsAction>;

  public abstract openDocument(url: string, extension?: DocumentExtension): Promise<void>;

  protected dispatchError(error: unknown): void {
    this.store.dispatch(actions.backendConnectFailedAction({ error: parseError(error) }));
  }

  protected handleObservableResponse<T>(observer: Subscriber<WsActionResponse<T>>, response: WsActionResponse<T>): void {
    // console.log(`WS EMIT: ${moduleName}`, action);
    // console.log("WS RESP: ", response);
    try {
      if (response.status !== 'ok') {
        observer.error(response.error);
        return;
      }
      observer.next(response);
    } catch (err: any) {
      observer.error(this.parseError(err));
    }
  }

  protected parseError(err: any): IErrorState {
    const error: IErrorState = {
      status: 'backend',
      message: err.message ?? err,
      stack: err.stack,
    };
    return error;
  }

  protected handleEmitAsyncResponse<T>(
    response: WsActionResponse<T>,
    resolve: (value: WsActionResponse<T>) => void,
    reject: (reason?: any) => void,
  ): void {
    try {
      if (response.status !== 'ok') {
        return reject(response.error);
      }
      resolve(response as WsActionResponse<T>);
    } catch (err: any) {
      reject(this.parseError(err));
    }
  }
}
