import { ITableRow } from './common-grid.interfaces';

export type IError<T> = {
  [key in keyof Partial<T>]: string;
};

export type DataSource<T> = T | ITableRow<T>;
export type DataSourceKey<T> = keyof T;
export type DataSourceValue<T> = T[keyof T];

export interface ITypeWithError<T> {
  error?: IError<T>;
  warning?: IError<T>;
}

export interface IModuleState {
  isLoaded: boolean;
}

export interface IValidatedModuleState<T> {
  error: IError<T>;
  warning?: IError<T>;
  isValid: boolean;
  isWarning?: boolean;
}

export class ErrorHelper {
  public static readonly ERROR_MULTIPLE_SCREENS_MESSAGE_HEADER =
    'Inputs are not consistent. Please check errors on the following screens before continuing: <br>';
  public static readonly ERROR_CURRENT_SCREENS_MESSAGE_HEADER = 'Inputs are not consistent. Please check errors before continuing.';
  public static readonly ERROR_NO_RESULTS_IN_FILE =
    'There are currently no results in the file. Please run a simulation or evaluation in any scenario to import a temperature profile.';
  public static readonly ERROR_MULTIPLE_SCENARIOS_MESSAGE_HEADER = 'Inputs are not consistent for following scenarios: <br>';
  public static readonly ERROR_SIMULATION_ONLY_VALID_CONTINUE =
    '<br/> Simulation will not be performed for invalid scenarios. Do you want to continue?';
  public static readonly ERROR_SIMULATION_COMPARE_SCENARIO_NOT_POSSIBLE =
    '<br/> There are no valid scenarios selected, comparison cannot be performed.';
  public static readonly ERROR_IMPORT_TEMP_PROFILE_ONLY_DETAILED_CALC =
    'To use the imported temperature profile in calculations, select the “Detailed Calc” temperature profile';
  public static getErrorMessages<T>(error: IError<T>): string[] {
    const errorMessages: string[] = [];
    Object.keys(error).forEach((key) => {
      const k = key as keyof T;
      if (error[k]) {
        errorMessages.push(' - ' + error[k]);
      }
    });
    return errorMessages;
  }
}

export const changeProp = <T>(obj: T, changedProp: ObjectChangeProp<T>, overrideValue?: DataSourceValue<T>): T =>
  changePropValue(obj, changedProp.key, overrideValue ?? changedProp.value);

export const changePropValue = <T>(obj: T, key: DataSourceKey<T>, value: DataSourceValue<T>): T => ({
  ...obj,
  [key]: value,
});

export interface PrimitiveChangeValue<T> {
  value: T;
  shouldResetResults: boolean;
}

export interface ObjectChangeProp<T> {
  key: DataSourceKey<T>;
  value: DataSourceValue<T>;
  shouldResetResults: boolean;
}

export const getChangeProp = <T>(key: DataSourceKey<T>, value: DataSourceValue<T>, shouldResetResults: boolean): ObjectChangeProp<T> => ({
  key,
  value,
  shouldResetResults,
});
