<ng-container *ngIf="currentUnitSystem && wellState.isLoaded">
  <app-page-panel header="Casing Data" (click)="changeHelp('pipe-properties')" [panelHelpMode]="PanelHelpMode.GRID">
    <app-casing-data-grid #gridComponent (selectedRowsChanged)="onSelectedRowsChanged($event)"
                          [casingGridData]="wellState.CasingData">
    </app-casing-data-grid>
  </app-page-panel>
  <app-page-panel class="options-panel" header="Perforation Properties" *ngIf="showPerforatedCasingProps">
    <app-perforation-properties
      [perforatedCasingPipe]="perforatedCasingPipe"
      (perforationPropertiesChanged)="updateRow($event)"
      (click)="changeHelp('perforated-casing')"
    >
    </app-perforation-properties>
  </app-page-panel>

  <div class="bottom-panel-button-lg">
    <div></div>
    <div class="buttons-panel">
      <div class="align-buttons">
        <button
          appButton
          type="button"
          class="btn btn-primary btn-sm"
          data-cy="pipe-data"
          [isUiLockable]="false"
          (buttonClick)="onShowPipeDataClick()"
          [disabled]="isPipeDataDisabled"
        >
          Pipe Data
        </button>
        <button
          appButton
          type="button"
          class="btn btn-primary btn-sm"
          data-cy="casing-data-import"
          (buttonClick)="onImport()"
          [isDeleteResultsEnabled]="false"
        >
          Import
        </button>
        <button appButton type="button" class="btn btn-primary btn-sm" (buttonClick)="onInsert()"
                [isDeleteResultsEnabled]="false">
          Insert
        </button>
        <button appButton type="button" class="btn btn-primary btn-sm" (buttonClick)="onDelete()"
                [isDeleteResultsEnabled]="false">
          Delete
        </button>
      </div>
    </div>
  </div>
</ng-container>
