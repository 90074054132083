import { Directive, EventEmitter, HostListener, NgModule, Output } from '@angular/core';

@Directive({
  selector: '[appInputGridNavigation]',
})
export class InputGridNavigationDirective {
  @Output() public changeFocusCell = new EventEmitter();

  @HostListener('keydown', ['$event'])
  public onKeyDown(e: KeyboardEvent): void {
    if (e.key === 'Tab' || e.key === 'Enter') {
      e.stopPropagation();
      this.changeFocusCell.emit(e.key);
    }
  }
}

@NgModule({
  declarations: [InputGridNavigationDirective],
  exports: [InputGridNavigationDirective],
})
export class InputGridNavigationModule {}
