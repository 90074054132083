import { emptyParsedFileResult, IColNameWithUnit, ILineError, IParsedFileResult } from '../../../../+store/import-data/model/parsed-result';
import { ColumnNameGeneratorService } from '../column-name-generator.service';
import { Injectable } from '@angular/core';
import {
  ImportDataColumnDelimiterConfig,
  ImportDataFilePropertiesConfig,
  ImportFileColumnDelimiter,
} from '@dunefront/common/modules/data-storage/dto/import-template/import-template.dto';
import { DELTA_TIME_COL_NAME } from '../../../../+store/import-data/model/col-config';
import { ColumnDelimiterHelper } from '../../../../+store/import-data/model/col-delimiter';

export const CANT_PARSE_ARGUMENT_ERROR = "Couldn't parse argument";
export const CANT_PARSE_CUSTOM_FORMAT_ERROR = "Can't parse custom format value";

@Injectable()
export class FileParserService {
  public static parsedResult = emptyParsedFileResult;
  //Added by Philip 10-Feb-14
  public static readonly minImportLineCount = 100;

  public static readonly lineTolerance = 15;

  public isPasteMode = false; // when pasting we need to be least strict with a checks

  public fileLines: string[] = [];

  public static reset(): void {
    this.parsedResult = { ...emptyParsedFileResult };
  }

  public getColumnDelimiter(delimiterConfig: ImportDataColumnDelimiterConfig, lineTolerance?: number): string | null {
    const autoDelimiter = delimiterConfig.delimiter === ImportFileColumnDelimiter.Auto;
    return autoDelimiter ? this.detectColumnDelimiter(this.fileLines, lineTolerance) : ColumnDelimiterHelper.get(delimiterConfig);
  }

  constructor(private colNameParser: ColumnNameGeneratorService) {}

  public detectColumnDelimiter(fileLines: string[], lineTolerance?: number): string | null {
    if (fileLines.length === 0) {
      return null;
    }

    //Define list of possible splitters in order of priority
    const columnDelimiters = ['\t', ',', ' ', ';', '|', '/', ':', '-', '_', '#', '~', '*', '^', '&', '@'];
    //Try each separator in turn
    for (const delimiter of columnDelimiters) {
      //Try to read the file
      const parsingResult = this.tryReadFileData(fileLines, delimiter, false, null, lineTolerance);
      if (parsingResult.isParsed) {
        //Separator works for the file
        return delimiter;
      }
    }

    return null;
  }

  public tryReadFileData(
    fileLines: string[],
    columnDelimiter: string,
    isReadEntireFile: boolean,
    fileConfig: ImportDataFilePropertiesConfig | null,
    lineTolerance?: number,
  ): IParsedFileResult {
    let lastHeaderLineIndex = 0;
    let currentLineIndex = 0;
    let dataColumnCount = 0;
    let emptyLineCount = 0;
    let headerColsQty: number | undefined = undefined;

    const result: IParsedFileResult = JSON.parse(JSON.stringify(emptyParsedFileResult));
    result.initialRowsToSkip = fileConfig?.initialRowsToSkip ?? 0;

    lineTolerance = lineTolerance != null ? lineTolerance : FileParserService.lineTolerance;

    if (fileLines.length < lineTolerance) {
      // Can't parse this file, because there are not enough lines
      return result;
    }

    do {
      //Read row from file
      const line = fileLines[currentLineIndex];
      currentLineIndex++;
      const parsedLine = this.parseLine(line, columnDelimiter);
      if (parsedLine === null || parsedLine.length === 0) {
        //Check if there is any data left
        if (emptyLineCount > lineTolerance) {
          //There is no more data and everything has been identified as a header
          result.header = '';
          return result;
        } else {
          //This is an empty row so log and add to header
          emptyLineCount++;
          result.header += this.joinParsedLine(parsedLine, columnDelimiter);
          lastHeaderLineIndex = currentLineIndex;
        }
      } else if (lastHeaderLineIndex > FileParserService.minImportLineCount) {
        //The min number of import rows are all headers which is likely a problem
        result.header = '';
        return result;
      } else {
        //Row has data
        emptyLineCount = 0;

        //Check the data type in the line
        if (parsedLine.length - 1 <= 1) {
          //There is not enough data (there must be at least 2 columns for time and data)
          result.header += this.joinParsedLine(parsedLine, columnDelimiter);
          lastHeaderLineIndex = currentLineIndex;
        } else if (!parsedLine.some((col) => this.tryParseNumber(col))) {
          let joinedParsedLine = this.joinParsedLine(parsedLine, columnDelimiter);

          // if it's a second header line, and columns quantity is different from the first one, it means that default parsing failed
          // let's use fallback method
          if (headerColsQty != null && headerColsQty !== joinedParsedLine.split(columnDelimiter).length) {
            joinedParsedLine = this.fallbackParseHeaderLine(fileLines[currentLineIndex - 1], columnDelimiter);
          }

          //There should be at least 1 numeric (data) column
          result.header += joinedParsedLine;

          headerColsQty = joinedParsedLine.split(columnDelimiter).length;
          lastHeaderLineIndex = currentLineIndex;
        } else {
          //This is a data row so count the columns for reference
          dataColumnCount = this.isPasteMode ? Math.max(dataColumnCount, parsedLine.length) : parsedLine.length;
        }
      }
    } while ((!this.isPasteMode || fileLines[currentLineIndex]) && currentLineIndex - lastHeaderLineIndex < lineTolerance);

    result.headerLines = lastHeaderLineIndex;

    // Construct the data table
    result.columnsWithUnits = this.tryParseColumnNamesAndUnits(result.header, columnDelimiter, dataColumnCount);

    // Check if all data needs to be read
    if (!isReadEntireFile) {
      // Header and data rows were successfully identified
      result.header = '';
      result.isParsed = true;
      return result;
    }

    //Reset variables for reading data
    currentLineIndex = -1;
    emptyLineCount = 0;
    let tmpEmptyLines: ILineError[] = [];

    for (const line of fileLines) {
      currentLineIndex++;

      //Skip the home-header rows
      if (currentLineIndex < lastHeaderLineIndex) {
        continue;
      }

      const currentDataLineIndex = currentLineIndex - lastHeaderLineIndex;

      if (fileConfig && fileConfig.initialRowsToSkip > 0 && currentDataLineIndex < fileConfig.initialRowsToSkip) {
        continue;
      }

      if (fileConfig && (currentDataLineIndex - fileConfig.initialRowsToSkip) % fileConfig.samplingFrequency !== 0) {
        continue;
      }

      const parsedLine = this.parseLine(line, columnDelimiter);
      if (parsedLine === null || !parsedLine.length) {
        //Check if there is any data left
        if (emptyLineCount > lineTolerance) {
          //All data has been read
          break;
        } else {
          // This is an empty row so log and skip
          tmpEmptyLines.push({ line: currentLineIndex, error: 'Data row is empty' });
          emptyLineCount++;
        }
      } else {
        //Row has data
        emptyLineCount = 0;

        // this is for not marking last empty lines in file as warning
        if (tmpEmptyLines.length) {
          result.skippedLineWarnings.push(...tmpEmptyLines);
          tmpEmptyLines = [];
        }
        //Check the data
        if (parsedLine.length !== dataColumnCount && !this.isPasteMode) {
          // The data count does not match the number of columns
          // Skip the row
          result.skippedLineWarnings.push({
            line: currentLineIndex,
            error: 'Data count does not match the number of columns',
          });
        } else {
          // Add the row to the table
          result.data.push(parsedLine);
        }
      }
    }

    result.dataLength = result.data.length;
    result.isParsed = true;
    result.header = this.removeFirstColumnDelimiterFromHeader(result.header.trim(), columnDelimiter);
    return result;
  }

  private removeFirstColumnDelimiterFromHeader(header: string, columnDelimiter: string): string {
    // coma needs to be on header for parsing column names
    // it's used for parsing invisible time column, but we don't want to display it on ui
    const headerLines = header.split('\n').map((line) => {
      const trimmed = line.trim();
      if (trimmed.startsWith(columnDelimiter)) {
        return line.slice(1);
      }
      return trimmed;
    });

    return headerLines.join('\n');
  }

  public tryParseColumnNamesAndUnits(header: string, columnDelimiter: string, foundColumnsLength: number): IColNameWithUnit[] {
    const headerLines = header.split('\n');
    let colNames: string[] = [];
    let colNamesWithoutSuffix: string[] = [];
    let colUnits: string[] = [];
    let originalColNames: string[] = [];

    for (let i = headerLines.length - 1; i >= 0; i--) {
      let columns = this.unescapeCsvValues(headerLines[i], columnDelimiter);
      if (columnDelimiter === '\t' && columns.length !== foundColumnsLength) {
        // sometimes people are manually adding header names by hand and using spaces instead of \t
        // use 2 spaces because there could be space in header name
        columns = headerLines[i].replace(/[ ]{2,}/gm, '\t').split('\t');
      }

      if (columns.length === foundColumnsLength || (header && this.isPasteMode)) {
        if (colNames.length) {
          colUnits = colNamesWithoutSuffix.map((colName) => (colName.startsWith('Column#') ? '' : colName));
          colNames = [];
          colNamesWithoutSuffix = [];
        }
        originalColNames = columns.map((col) => col.trim());
        columns.forEach((col, index) => {
          colNames.push(this.colNameParser.getColName(col.trim(), index, colNames));
          colNamesWithoutSuffix.push(this.colNameParser.getColName(col.trim(), index));
        });
      }
    }
    if (colNames.length === 0) {
      colNames.push(...this.colNameParser.generateGenericColumnNames(foundColumnsLength));
    }
    const columnNames = [DELTA_TIME_COL_NAME, ...colNames.splice(1)];
    const units = ['', ...colUnits.splice(1)];
    return columnNames.map((columnName, index) => ({
      columnName,
      originalUnit: units[index],
      originalColumnName: originalColNames[index],
    }));
  }

  private unescapeCsvValues(data: string, columnDelimiter: string): string[] {
    const unescapedValues: string[] = [];

    let currentValue = '';
    let isInQuotes = false;

    for (let i = 0; i < data.length; i++) {
      const currentChar = data[i];

      if (currentChar === '"') {
        if (isInQuotes && data[i + 1] === '"') {
          currentValue += '"';
          i++; // Skip the next double quote
        } else {
          isInQuotes = !isInQuotes;
        }
      } else if (!isInQuotes && columnDelimiter !== '' && data.substring(i, i + columnDelimiter.length) === columnDelimiter) {
        unescapedValues.push(currentValue.trim());
        currentValue = '';

        // Skip past the delimiter in the input string
        i += columnDelimiter.length - 1;
      } else {
        currentValue += currentChar;
      }
    }

    unescapedValues.push(currentValue.trim()); // Add the last value

    return unescapedValues;
  }

  //Method to parse a line with a specified delimiter
  private parseLine(line: string | undefined, columnDelimiter: string): string[] | null {
    const trimmed = line?.trim();
    if (!trimmed) {
      return null;
    }

    let afterSplit = trimmed.split(columnDelimiter);
    // when delimiter is 'invisible' like tab(s) or space(s) it could be added multiple times by mistake. Filter out empty columns;
    // when pasting blank fields are allowed so don't filter then
    if (!this.isPasteMode) {
      if (afterSplit[afterSplit.length - 1] === '') {
        afterSplit.pop();
      }

      if (columnDelimiter.trim().length === 0) {
        afterSplit = afterSplit.filter((col) => !!col);
      }
    }

    return ['', ...afterSplit];
  }

  private fallbackParseHeaderLine(line: string, columnDelimiter: string): string {
    // slice out trailing delimiter
    const notParsedLine = line.substring(0, line.lastIndexOf(columnDelimiter) === line.length - 1 ? line.length - 1 : line.length);

    // split raw line by delimiter
    const fixedHeader = ['', ...notParsedLine.split(columnDelimiter)];
    return this.joinParsedLine(fixedHeader, columnDelimiter);
  }

  private tryParseNumber(str: string): boolean {
    if (!str) {
      return false;
    }
    const parsed = +str;
    return !isNaN(parsed) && isFinite(parsed);
  }

  private joinParsedLine(parsedLine: string[] | null, columnDelimiter: string): string {
    if (parsedLine === null || !parsedLine.length) {
      return '\n';
    } else {
      let line = '';

      //Put the line together using the delimiter

      for (let index = 0; index < parsedLine.length; index++) {
        if (index === 0) {
          line += parsedLine[index];
        } else {
          line += columnDelimiter + parsedLine[index];
        }
      }

      return line + '\n';
    }
  }
}
