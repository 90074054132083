import { Component } from '@angular/core';
import { ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import { CommonCalculationPageComponent } from '../common-calculation-page.component';
import { CalculatorsTab } from '../../../+store/ui/ui-module.state';

@Component({
  selector: 'app-masp',
  templateUrl: './masp.component.html',
  styleUrls: ['./masp.component.scss'],
})
export class MASPComponent extends CommonCalculationPageComponent {
  public override appModuleType = ModuleType.MASP_Calc;
  public override tab: CalculatorsTab = 'masp';
}
