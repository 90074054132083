import { createAction } from '@ngrx/store';
import { UndoOrRedo } from '@dunefront/common/modules/common.actions';

export interface UndoRedoActionArg {
  undoOrRedo: UndoOrRedo;
}

// these are real undo/redo actions
export const undoRedoAction = createAction('[undo-redo] undoRedo', (undoOrRedo: UndoOrRedo): UndoRedoActionArg => ({ undoOrRedo }));

// these are start undo/redo actions when is not busy
export const undoRedoActionClick = createAction(
  '[undo-redo] undoRedoClick',
  (undoOrRedo: UndoOrRedo): UndoRedoActionArg => ({ undoOrRedo }),
);

export const undoRedoBusyAction = createAction('[undo-redo] undoRedoBusy');
export const undoRedoNotBusyAction = createAction('[undo-redo] undoRedoNotBusy');

export const undoRedoClearHistoryAction = createAction('[undo-redo] clearHistoryAction');
