import { createSelector } from '@ngrx/store';
import { getValidatedGravels } from '../../gravel/gravel.selectors';
import { IPumpingValidationDependencies } from '@dunefront/common/modules/pumping/pumping-module.state';
import { getValidatedFluids } from '../../fluid/fluid.selectors';
import { getCurrentAppModuleType } from '../../ui/ui.selectors';
import { getValidatedDeveloperSettings, getValidatedSettings } from '../../settings/validated-settings.selectors';
import { getValidatedCompletionModuleState } from '../../completion/validated-completion.selectors';
import { getCurrentRangeId } from '../../range/range.selectors';
import { getReferenceVariables } from '../../reference-variables/reference-variables.selectors';
import { RangeConstants } from '@dunefront/common/dto/range.dto';
import { getValidatedWellModuleState } from '../../well/validated-well.selectors';
import { getCurrentFeatures } from '../../licensing/licensing.selectors';

export const getCurrentRangeAndAppModuleName = createSelector(
  getCurrentRangeId,
  getCurrentAppModuleType,
  (...[rangeId, appModuleName]) => ({ rangeId, appModuleName }),
);

export const getPumpingValidationDeps = createSelector(
  getValidatedWellModuleState,
  getValidatedCompletionModuleState,
  getValidatedSettings,
  getReferenceVariables,
  getValidatedGravels,
  getValidatedFluids,
  getValidatedDeveloperSettings,
  getCurrentRangeAndAppModuleName,
  getCurrentFeatures,
  (
    well,
    completion,
    settings,
    referenceVariables,
    gravels,
    fluids,
    developerSettings,
    rangeIdAdAppModuleName,
    currentLicenseFeatures,
  ): IPumpingValidationDependencies => ({
    well,
    completion,
    settings,
    gravels,
    referenceVariables,
    fluids,
    developerSettings: developerSettings,
    rangeId: rangeIdAdAppModuleName.rangeId ?? RangeConstants.EntireRangeId,
    appModuleType: rangeIdAdAppModuleName.appModuleName,
    currentLicenseFeatures,
  }),
);
