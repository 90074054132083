import { ChangeDetectionStrategy, Component } from '@angular/core';
import { OptionsComponent } from '../../options-component';

@Component({
  selector: 'app-friction-options',
  templateUrl: './friction-options.component.html',
  styleUrls: ['./friction-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FrictionOptionsComponent extends OptionsComponent {}
