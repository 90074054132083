<app-generic-modal [title]="modalTitle" [modalButtonsConfigs]="modalButtonsConfigs">
  <span>Trigger Sentry ignored error</span>
  <div class="btn-row">
    <button
            appButton
            type="button"
            class="btn btn-primary btn-sm"
            data-cy="btn-trigger-ignored-frontend"
            [isUiLockable]="false"
            [isDisabledWhenCalculationActive]="false"
            (click)="triggerIgnoredFrontendError()"
    >
      Ignored Frontend
    </button>
    <button
            appButton
            type="button"
            class="btn btn-primary btn-sm"
            [isUiLockable]="false"
            [isDisabledWhenCalculationActive]="false"
            (click)="triggerIgnoredBackendError()"
            data-cy="btn-trigger-ignored-backend"
    >
      Ignored Backend
    </button>
    <button
            appButton
            type="button"
            class="btn btn-primary btn-sm"
            [isUiLockable]="false"
            [isDisabledWhenCalculationActive]="false"
            (click)="triggerCalcEngineShortWorkerError(true, 'crash')"
            data-cy="btn-trigger-ignored-short-worker"
    >
      Ignored Calc Engine Short Worker
    </button>

    <button
            appButton
            type="button"
            class="btn btn-primary btn-sm"
            [isUiLockable]="false"
            [isDisabledWhenCalculationActive]="false"
            [disabled]="currentCalculationJob === undefined"
            (click)="triggerIgnoredRunningCalcEngineError()"
            data-cy="btn-trigger-ignored-long-worker"
    >
      Ignored Calc Engine Long Worker
    </button>
  </div>

  <span>Trigger Sentry error</span>
  <div class="btn-row">
    <button
            appButton
            type="button"
            class="btn btn-primary btn-sm"
            [isUiLockable]="false"
            [isDisabledWhenCalculationActive]="false"
            (click)="triggerNotIgnoredFrontendError()"
            data-cy="btn-trigger-frontend"
    >
      Sentry Frontend
    </button>
    <button
            appButton
            type="button"
            class="btn btn-primary btn-sm"
            [isUiLockable]="false"
            [isDisabledWhenCalculationActive]="false"
            (click)="triggerNotIgnoredBackendError()"
            data-cy="btn-trigger-backend"
    >
      Sentry Backend
    </button>
    <button
            appButton
            type="button"
            class="btn btn-primary btn-sm"
            [isUiLockable]="false"
            [isDisabledWhenCalculationActive]="false"
            (click)="triggerCalcEngineShortWorkerError(false, 'crash')"
            data-cy="btn-trigger-short-worker"
    >
      Sentry Calc Engine Short Worker
    </button>
    <button
            appButton
            type="button"
            class="btn btn-primary btn-sm"
            [isUiLockable]="false"
            [isDisabledWhenCalculationActive]="false"
            (click)="triggerCalcEngineShortWorkerError(false, 'timeout')"
            data-cy="btn-trigger-short-worker-timeout"
    >
      Sentry Calc Engine Short Worker Timeout
    </button>
    <button
            appButton
            type="button"
            class="btn btn-primary btn-sm"
            [isUiLockable]="false"
            [isDisabledWhenCalculationActive]="false"
            [disabled]="currentCalculationJob === undefined"
            (click)="triggerNotIgnoredRunningCalcEngineError()"
            data-cy="btn-trigger-long-worker"
    >
      Sentry Calc Engine Long Worker
    </button>
  </div>

  <span>Other</span>
  <div class="btn-row">
    <button
            appButton
            type="button"
            class="btn btn-primary btn-sm"
            [isUiLockable]="false"
            [isDisabledWhenCalculationActive]="false"
            (click)="freezeBackend()"
            data-cy="btn-freeze-backend"
    >
      Freeze Backend
    </button>

    <button
            appButton
            type="button"
            class="btn btn-primary btn-sm"
            [isUiLockable]="false"
            [isDisabledWhenCalculationActive]="false"
            (click)="pingPong()"
            data-cy="btn-ping-pong"
    >
      Ping calc engine
    </button>
  </div>
</app-generic-modal>
