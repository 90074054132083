import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SelectComponent } from './select.component';
import { DropdownModule } from 'primeng/dropdown';
import { TooltipModule } from 'primeng/tooltip';
import { InputGridNavigationModule } from '../../../../../src/shared/directives/input-grid-navigation.directive';

@NgModule({
  imports: [CommonModule, FormsModule, DropdownModule, TooltipModule, InputGridNavigationModule],
  declarations: [SelectComponent],
  exports: [SelectComponent],
})
export class SelectModule {}
