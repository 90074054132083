import { SummaryModuleState } from './summary.reducer';
import { LoadSummaryActionResponse } from '@dunefront/common/modules/summary/summary-module.actions';
import { WarningMessageFactory } from '@dunefront/common/modules/summary/warning-message.factory';
import { ActionResponse } from '@dunefront/common/response-ws.action';

export class SummaryReducerHelper {
  public static loadSummaryModule(state: SummaryModuleState, response: ActionResponse<LoadSummaryActionResponse>): SummaryModuleState {
    if (!response.payload) {
      return state;
    }
    return {
      ...state,
      isLoaded: true,
      summary: response.payload.summaryDto,
      warningMessages: WarningMessageFactory.create(response.payload.warningMessagesDto),
    };
  }
}
