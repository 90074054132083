import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Output } from '@angular/core';
import { DbDependentComponent } from '../../../db-connection/db-dependent.component';
import { Store } from '@ngrx/store';
import { ModalService } from '../../modal.service';
import { CreateEditCopyScenarioComponent } from './create-edit-copy-scenario/create-edit-copy-scenario.component';
import { deleteScenarioAction, reorderScenariosAction } from '../../../../+store/scenario/scenario.actions';
import { getScenarioState } from '../../../../+store/scenario/scenario.selectors';
import { isSimulateBased, ScenarioConstants } from '@dunefront/common/modules/scenario/scenario.dto';
import {
  CalculationContext,
  IScenarioAndRange,
  updateRefVariablesValidateAndAddCalculationToQueueAction,
} from '../../../../+store/calculation-engine/calculation-engine.actions';
import { RangeConstants } from '@dunefront/common/dto/range.dto';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { navigateToScenarioAction } from '../../../../+store/reporting/reporting.actions';
import { Scenario } from '@dunefront/common/modules/scenario/scenario';

@Component({
  selector: 'app-scenario-manager-basic-operations',
  templateUrl: './scenario-manager-basic-operations.component.html',
  styleUrls: ['./scenario-manager-basic-operations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScenarioManagerBasicOperationsComponent extends DbDependentComponent {
  public selectedScenarioIds: number[] = [];
  public scenarios!: Scenario[];
  public isReorderOn = false;
  public isSimulateBased = isSimulateBased;

  @Output() public simulationsStarted = new EventEmitter<void>();

  constructor(
    store: Store,
    cdRef: ChangeDetectorRef,
    private modalService: ModalService,
  ) {
    super(store, cdRef);
    this.subscription.add(
      this.store.select(getScenarioState).subscribe((scenarioState) => {
        this.scenarios = scenarioState.scenarios;
        this.cdRef.markForCheck();
      }),
    );
  }

  public async createScenario(): Promise<void> {
    const data = { mode: 'new' };
    this.modalService.open(CreateEditCopyScenarioComponent, data, 'scenario-create-modal', '400px');
  }

  public async editScenario(): Promise<void> {
    const data = { mode: 'edit', selectedScenarioId: this.selectedScenarioIds[0] };
    this.modalService.open(CreateEditCopyScenarioComponent, data, 'scenario-create-modal', '400px');
  }

  public async copyScenario(): Promise<void> {
    const data = { mode: 'copy', selectedScenarioId: this.selectedScenarioIds[0] };
    this.modalService.open(CreateEditCopyScenarioComponent, data, 'scenario-create-modal', '400px');
  }

  public async deleteScenarios(): Promise<void> {
    if (this.selectedScenarioIds.find((id) => id === this.currentScenarioId) != null) {
      const selectedScenarioName = this.scenarios.find((scenario) => scenario.Id === this.currentScenarioId)?.Name;
      await this.modalService.showAlert(`${selectedScenarioName} is the current working scenario and it can't be deleted.`);
      return;
    }
    const confirmResult = await this.modalService.showConfirm(
      'All selected scenarios will be deleted. Do you want to continue?',
      'Warning',
    );
    if (confirmResult) {
      this.store.dispatch(
        deleteScenarioAction({
          rowIds: this.selectedScenarioIds,
          scenarioId: ScenarioConstants.EmptyScenarioId,
          shouldResetResults: false,
        }),
      );
      this.selectedScenarioIds.forEach((selectedScenarioId) => this.removeSelectedId(selectedScenarioId));
    }
  }

  public async switchScenario(): Promise<void> {
    this.store.dispatch(navigateToScenarioAction({ scenarioId: this.selectedScenarioIds[0] }));
  }

  public runSimulations(): void {
    if (!this.dbInfo) {
      return;
    }
    const scenariosAndRanges: IScenarioAndRange[] = this.scenarios
      .filter((scenario) => this.selectedScenarioIds.includes(scenario.Id))
      .sort((a, b) => a.SortOrder - b.SortOrder)
      .map((scenario) => ({
        scenarioId: scenario.Id,
        rangeId: RangeConstants.EmptyRangeId,
      }));

    if (!isSimulateBased(this.currentAppModuleType)) {
      return;
    }

    this.store.dispatch(
      updateRefVariablesValidateAndAddCalculationToQueueAction({
        scenariosAndRanges,
        fileHash: this.dbInfo.fileHash,
        redirect: false,
        moduleType: this.currentAppModuleType,
        calculationContext: CalculationContext.SCENARIO_MANAGER,
        currentLicenseFeatures: this.currentLicenseFeatures,
      }),
    );

    this.simulationsStarted.emit();
  }

  public updateSelectedIds(selectId: { id: number; value: boolean }): void {
    if (selectId.value) {
      this.selectedScenarioIds = [...this.selectedScenarioIds, selectId.id];
    } else {
      this.removeSelectedId(selectId.id);
    }
  }

  public removeSelectedId(selectedId: number): void {
    const index = this.selectedScenarioIds.indexOf(selectedId);
    this.selectedScenarioIds = [...this.selectedScenarioIds.slice(0, index), ...this.selectedScenarioIds.slice(index + 1)];
  }

  public toggleReorder(): void {
    this.isReorderOn = !this.isReorderOn;
  }

  public onDrop(event: CdkDragDrop<Scenario>): void {
    this.store.dispatch(
      reorderScenariosAction({
        scenarios: this.scenarios,
        from: event.previousIndex,
        to: event.currentIndex,
        changedKey: 'SortOrder',
      }),
    );
  }
}
