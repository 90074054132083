import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnChanges, OnInit } from '@angular/core';
import { createTableState, ITableRow, ITableState } from '@dunefront/common/common/common-grid.interfaces';
import { GridContainerComponent } from '../../../../../../../shared/components/grid/grid-container.component';
import { Store } from '@ngrx/store';
import { ModalService } from '../../../../../../../common-modules/modals/modal.service';
import { GridConfig } from '../../../../../../../shared/components/grid/grid-config';
import { EnumHelpers } from '@dunefront/common/utils/enum.helpers';
import { LowerCompletionPipeType } from '@dunefront/common/dto/pipe.dto';
import * as completionActions from '../../../../../../../+store/completion/completion.actions';
import { ColumnType, IGridColumnConfig } from '../../../../../../../shared/components/grid/grid.interfaces';
import { IcdPortDataDto } from '@dunefront/common/dto/icd-port-data.dto';
import { CompletionModuleState } from '@dunefront/common/modules/completion/completion-module.state';
import { DictionaryWithArray, IDictionaryWithArray } from '@dunefront/common/common/state.helpers';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { ISelectItem } from '@dunefront/common/common/select.helpers';
import { IcdPortDataValidation } from '@dunefront/common/modules/completion/model/lower-completion/icd-port-data.validation';
import {
  getIcdPortDataDict,
  getValidatedCompletionModuleState,
} from '../../../../../../../+store/completion/validated-completion.selectors';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { GridResizeService } from '../../../../../../../shared/services/grid-resize.service';
import { IcdPortDataFactory } from '@dunefront/common/modules/completion/model/icd-port-data.factory';
import { changeProp, ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';
import {
  IDeleteRowsProps,
  IInsertRowsProps,
  IUpdateTableRowsProps,
  StoreCrudPropsFactory,
} from '@dunefront/common/common/common-store-crud.interfaces';
import { isICDScreenRow } from '@dunefront/common/modules/pipes/lower-completion-pipes/pipes/icd-screen-pipe';
import { ICDType } from '@dunefront/common/dto/lower-completion-pipe.dto';
import { modalButton, ModalButtonConfig } from '../../../../../../../common-modules/modals/generic-modal/generic-modal.component';

@Component({
  selector: 'app-generic-icd-port-data',
  templateUrl: './generic-icd-port-data.modal.component.html',
  styleUrls: ['./generic-icd-port-data.modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenericIcdPortDataModalComponent extends GridContainerComponent<IcdPortDataDto> implements OnInit, OnChanges {
  public UnitType = UnitSystem;
  private _columns: IGridColumnConfig<IcdPortDataDto>[] = [
    { disabled: false, visible: true, colId: ' ', type: ColumnType.selection },
    {
      disabled: false,
      visible: true,
      colId: 'PortDiameter',
      type: ColumnType.number,
      unitSystem: UnitSystem.Diameter,
      decimalPlaces: 3,
      headerText: 'Port Diameter',
      width: 150,
    },
    {
      disabled: false,
      visible: true,
      colId: 'NumberOfPorts',
      type: ColumnType.number,
      unitSystem: UnitSystem.None,
      decimalPlaces: 0,
      headerText: 'No. of Ports',
      width: 150,
    },
  ];
  private completion!: CompletionModuleState;
  public firstRow!: ITableRow<IcdPortDataDto> | undefined;
  public icdPortData: ITableState<IcdPortDataDto> = createTableState([]);
  public override gridConfig!: IcdScreenPipeGridConfig;

  private lowerCompletionId$ = new BehaviorSubject<number | undefined>(undefined);
  public modalTitle = 'ICD Port Data';

  public modalButtonsConfigs: ModalButtonConfig[] = [
    modalButton('Close', (): void => this.onCloseClicked(), 'generic-icd-cancel-btn', 'cancel'),
    modalButton('Delete', (): void => this.onDeleteClicked(), 'generic-icd-delete-btn'),
  ];

  constructor(
    public activeModal: DynamicDialogRef,
    public config: DynamicDialogConfig,
    store: Store,
    cdRef: ChangeDetectorRef,
    protected modalService: ModalService,
    el: ElementRef,
    protected resizeService: GridResizeService,
  ) {
    super(store, cdRef, el, new IcdScreenPipeGridConfig(store, modalService), resizeService);

    this.lowerCompletionId$.next(config.data.lowerCompletionId);
    this.height = 200;

    this.subscription.add(
      combineLatest([store.select(getValidatedCompletionModuleState), this.lowerCompletionId$]).subscribe(
        ([completionData, lowerCompletionId]) => {
          this.completion = completionData;
          this.loadData(lowerCompletionId);
          if (lowerCompletionId != null && lowerCompletionId > 0) {
            this.closeDialogWhenNotNeededAnymore(lowerCompletionId);
          }
          this.cdRef.markForCheck();
        },
      ),
    );

    this.updateColumns(this.getColumns());
  }

  public loadData(lowerCompletionId: number | undefined): void {
    if (lowerCompletionId != null && lowerCompletionId > 0) {
      this.gridConfig.screenPipeId = lowerCompletionId;
      const icdPortData = DictionaryWithArray.get(this.completion.IcdPortData, lowerCompletionId);
      this.icdPortData = icdPortData ?? createTableState([]);
      this.firstRow = icdPortData?.rows[0];
    } else {
      this.icdPortData = createTableState([]);
      this.firstRow = undefined;
    }
    this.cdRef.markForCheck();
  }

  public onCloseClicked(): void {
    this.activeModal.close();
  }

  public onPortLengthChange(event: ObjectChangeProp<IcdPortDataDto>): void {
    const newRow: ITableRow<IcdPortDataDto> = {
      ...(this.firstRow as ITableRow<IcdPortDataDto>),
      rowData: changeProp((this.firstRow as ITableRow<IcdPortDataDto>).rowData, event),
    };
    this.store.dispatch(completionActions.updateIcdPortDataRow(StoreCrudPropsFactory.updateTableRows([newRow], event)));
  }

  public onDeleteClicked(): void {
    this.grid.onDeleteClicked().then();
  }

  protected getColumns(): IGridColumnConfig<IcdPortDataDto>[] {
    return this._columns;
  }

  private closeDialogWhenNotNeededAnymore(lowerCompletionId: number): void {
    const icdLowerCompletion = this.completion.LowerCompletion.rows.find((row) => row.rowData.Id === lowerCompletionId);
    if (!icdLowerCompletion || !isICDScreenRow(icdLowerCompletion) || icdLowerCompletion.rowData.ICDType !== ICDType.Generic_ICD) {
      this.activeModal.close();
    }
  }
}

export class IcdScreenPipeGridConfig extends GridConfig<IcdPortDataDto> {
  public pipeTypes: ISelectItem<LowerCompletionPipeType>[] = [];
  public placeholder = '';
  public screenPipeId = 0;
  public data!: IDictionaryWithArray<ITableState<IcdPortDataDto>>;

  constructor(
    private store: Store,
    modalService: ModalService,
  ) {
    super(modalService);
    this.initLookupData();

    this.subscription.add(
      store.select(getIcdPortDataDict).subscribe((icdPortDataDict) => {
        this.data = icdPortDataDict as IDictionaryWithArray<ITableState<IcdPortDataDto>>;
      }),
    );
  }

  public initLookupData(): void {
    const pipeTypes = EnumHelpers.EnumToISelectItemArray(LowerCompletionPipeType);
    this.pipeTypes = [...pipeTypes];
    this.placeholder = 'Select Component';
  }

  public override updateRowsAction(props: IUpdateTableRowsProps<IcdPortDataDto>): void {
    this.store.dispatch(completionActions.updateIcdPortDataRow(props));
  }

  public override insertRowAction(props: IInsertRowsProps<IcdPortDataDto>): void {
    this.store.dispatch(completionActions.insertIcdPortDataRow(props));
  }

  public override deleteRowsAction(props: IDeleteRowsProps): void {
    this.store.dispatch(completionActions.deleteIcdPortDataRow(props));
  }

  public override createEmptyModel(scenarioId: number): IcdPortDataDto | undefined {
    return IcdPortDataFactory.createEmpty(scenarioId, this.screenPipeId);
  }

  public override async canRowsBeDeleted(props: IDeleteRowsProps): Promise<boolean> {
    const table = DictionaryWithArray.get(this.data, this.screenPipeId);
    if (!table) {
      return false;
    }

    const errorMessage = IcdPortDataValidation.validateGenericIcdGridLength(table, props.rowIds);
    if (errorMessage.length) {
      this.modalService.showAlert(errorMessage).then();
      return false;
    }
    return true;
  }
}
