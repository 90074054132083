import { ConvertUnitPipe } from '../../../units/convert-unit.pipe/convert-unit.pipe';
import { MASPCalculationType } from '../masp-calculator.dto';
import { noErrors } from '../../../../common/state.helpers';
import { MAX_GRAVEL_CONCENTRATION_SI } from '../../../../common/constants';
import { IError } from '../../../../common/common-state.interfaces';
import { MASPCalculatorModuleState, ValidatedMaspCalculatorModuleState } from '../masp-calculator-module.state';
import { UnitSystem } from '../../../../dto/unit-system.dto';
import { Validation } from '../../../../common/validation';

export class MASPCalculatorValidation {
  public static validate(maspCalculator: MASPCalculatorModuleState): ValidatedMaspCalculatorModuleState {
    const error: IError<MASPCalculatorModuleState> = {
      FluidId: Validation.ValidateFluid(maspCalculator.FluidId),
      GravelId: Validation.ValidateGravel(maspCalculator.GravelId),
      Concentration: this.ValidateConcentration(maspCalculator),
      TVD: this.ValidateTVD(maspCalculator.TVD),
      MaxPressureAtTVD: this.ValidateMaxPressureAtTVD(maspCalculator),
      InternalPressure: this.ValidateInternalPressure(maspCalculator),
      CollapseRating: this.ValidateCollapseRating(maspCalculator),
      SafetyFactor: this.ValidateSafetyFactor(maspCalculator),
    };
    return { ...maspCalculator, error, isValid: noErrors(error) };
  }

  private static ValidateConcentration(maspCalculator: MASPCalculatorModuleState): string {
    if ((maspCalculator.GravelId ?? 0) > 0 && maspCalculator.Concentration <= 0) {
      return 'Concentration must be greater than 0';
    }

    if ((maspCalculator.GravelId ?? 0) > 0 && maspCalculator.Concentration > MAX_GRAVEL_CONCENTRATION_SI) {
      const encodeValue = ConvertUnitPipe.encode(UnitSystem.Solid_Concentration, MAX_GRAVEL_CONCENTRATION_SI);
      return `Concentration must be less than or equal to ${encodeValue}`;
    }
    return '';
  }

  private static ValidateTVD(TVD: number): string {
    if (TVD <= 0) {
      return 'TVD must be greater than 0';
    }
    return '';
  }

  private static ValidateMaxPressureAtTVD(maspCalculator: MASPCalculatorModuleState): string {
    if (maspCalculator.MASPCalculationType === MASPCalculationType.Absolute_Pressure && maspCalculator.MaxPressureAtTVD <= 0) {
      return 'Max pressure at TVD must be greater than 0';
    }
    return '';
  }

  private static ValidateInternalPressure(maspCalculator: MASPCalculatorModuleState): string {
    if (maspCalculator.MASPCalculationType === MASPCalculationType.Differential_Pressure && maspCalculator.InternalPressure <= 0) {
      return 'Internal pressure must be greater than 0';
    }
    return '';
  }

  private static ValidateCollapseRating(maspCalculator: MASPCalculatorModuleState): string {
    if (maspCalculator.MASPCalculationType === MASPCalculationType.Differential_Pressure && maspCalculator.CollapseRating <= 0) {
      return 'Collapse rating must be greater than 0';
    }
    return '';
  }

  private static ValidateSafetyFactor(maspCalculator: MASPCalculatorModuleState): string {
    if (
      maspCalculator.MASPCalculationType === MASPCalculationType.Differential_Pressure &&
      (maspCalculator.SafetyFactor <= 0 || maspCalculator.SafetyFactor > 100)
    ) {
      return 'Safety factor must be between 0 and 100';
    }
    return '';
  }
}
