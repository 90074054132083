import { IReportTableColumnConfig } from '../common-report-data-helpers';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { ColumnType } from '../../../../../components/grid/grid.interfaces';
import { SummaryDto } from '@dunefront/common/dto/summary.dto';

export const summaryPackEfficienciesProperties: IReportTableColumnConfig<SummaryDto>[] = [
  {
    colId: 'FinalOverallPackPercentage',
    headerText: 'Wellbore Packing',
    unitSystem: UnitSystem.None,
    type: ColumnType.number,
    overrideUnitSymbol: '%',
  },
  {
    colId: 'FinalScreenPackPercentage',
    headerText: 'Screen Packing',
    unitSystem: UnitSystem.None,
    type: ColumnType.number,
    overrideUnitSymbol: '%',
  },
  {
    colId: 'FinalPerforationPackPercentage',
    headerText: 'Perforation Packing',
    unitSystem: UnitSystem.None,
    type: ColumnType.number,
    overrideUnitSymbol: '%',
  },
  {
    colId: 'PerfLinearGravelMass',
    headerText: 'Perforation Packing',
    unitSystem: UnitSystem.Linear_Mass,
    type: ColumnType.number,
  },
];

export const summaryGravelBelowPackerProperties: IReportTableColumnConfig<SummaryDto>[] = [
  {
    colId: 'BlankGravelMass',
    headerText: 'Blank Gravel',
    unitSystem: UnitSystem.Mass,
    type: ColumnType.number,
  },
  {
    colId: 'ScreenGravelMass',
    headerText: 'Screen Gravel',
    unitSystem: UnitSystem.Mass,
    type: ColumnType.number,
  },
  {
    colId: 'PerfGravelMass',
    headerText: 'Perforation Gravel',
    unitSystem: UnitSystem.Mass,
    type: ColumnType.number,
  },
];

export const summaryMaxTotalPressureProperties: IReportTableColumnConfig<SummaryDto>[] = [
  {
    colId: 'MaxSurfacePressure',
    headerText: 'Surface Pressure',
    unitSystem: UnitSystem.Pressure,
    type: ColumnType.number,
  },
  {
    colId: 'MaxHeelPressure',
    headerText: 'Heel Pressure',
    unitSystem: UnitSystem.Pressure,
    type: ColumnType.number,
  },
  {
    colId: 'MaxToePressure',
    headerText: 'Toe Pressure',
    unitSystem: UnitSystem.Pressure,
    type: ColumnType.number,
  },
];

export const summaryFinalFracDataProperties: IReportTableColumnConfig<SummaryDto>[] = [
  {
    colId: 'FracNetPressure',
    headerText: 'Net Pressure',
    unitSystem: UnitSystem.Pressure,
    type: ColumnType.number,
  },
  {
    colId: 'FracFluidEfficiency',
    headerText: 'Fluid Efficiency',
    unitSystem: UnitSystem.None,
    type: ColumnType.number,
    overrideUnitSymbol: '%',
  },
  {
    colId: 'FracPackEfficiency',
    headerText: 'Pack Efficiency',
    unitSystem: UnitSystem.None,
    type: ColumnType.number,
    overrideUnitSymbol: '%',
  },
];

export const summaryMaxFrictionPressureProperties: IReportTableColumnConfig<SummaryDto>[] = [
  {
    colId: 'MaxWorkstringFriction',
    headerText: 'Workstring',
    unitSystem: UnitSystem.Pressure,
    type: ColumnType.number,
  },
  {
    colId: 'MaxScreenFriction',
    headerText: 'Screen',
    unitSystem: UnitSystem.Pressure,
    type: ColumnType.number,
  },
  {
    colId: 'MaxWashpipeFriction',
    headerText: 'Washpipe',
    unitSystem: UnitSystem.Pressure,
    type: ColumnType.number,
  },
  {
    colId: 'MaxPressureAttenuatorFriction',
    headerText: 'Pressure Attenuators',
    unitSystem: UnitSystem.Pressure,
    type: ColumnType.number,
  },
  {
    colId: 'MaxServiceToolDownFlowFriction',
    headerText: 'Service Tool Cross Over',
    unitSystem: UnitSystem.Pressure,
    type: ColumnType.number,
  },
  {
    colId: 'MaxUpperAnnulusFriction',
    headerText: 'Annulus',
    unitSystem: UnitSystem.Pressure,
    type: ColumnType.number,
  },
  {
    colId: 'MaxChokeFriction',
    headerText: 'Choke',
    unitSystem: UnitSystem.Pressure,
    type: ColumnType.number,
  },
  {
    colId: 'MaxBlankFriction',
    headerText: 'Blank',
    unitSystem: UnitSystem.Pressure,
    type: ColumnType.number,
  },
  {
    colId: 'MaxBasePipeFriction',
    headerText: 'Basepipe',
    unitSystem: UnitSystem.Pressure,
    type: ColumnType.number,
  },
  {
    colId: 'MaxGaugeCarrierFriction',
    headerText: 'Gauge Carriers',
    unitSystem: UnitSystem.Pressure,
    type: ColumnType.number,
  },
  {
    colId: 'MaxShuntFriction',
    headerText: 'Shunt',
    unitSystem: UnitSystem.Pressure,
    type: ColumnType.number,
  },
  {
    colId: 'MaxServiceToolUpFlowFriction',
    headerText: 'Service Tool Return',
    unitSystem: UnitSystem.Pressure,
    type: ColumnType.number,
  },
  {
    colId: 'MaxTreatingFriction',
    headerText: 'Treating',
    unitSystem: UnitSystem.Pressure,
    type: ColumnType.number,
  },
  {
    colId: 'GravelPack',
    headerText: 'Gravel Pack',
    unitSystem: UnitSystem.Pressure,
    type: ColumnType.number,
  },
];
