import { PipeType } from '../../../../dto/pipe.dto';
import { Pipe } from '../../pipe';

export interface BlankPipe extends Pipe {
  PipeType: BlankPipeType;
}

export type BlankPipeType = PipeType.Blank_Pipe | PipeType.Shunted_Blank_Pipe;
const blankPipeTypes = [PipeType.Blank_Pipe, PipeType.Shunted_Blank_Pipe];

export function isBlankPipe(pipe: Pipe): pipe is BlankPipe {
  return blankPipeTypes.includes(pipe.PipeType);
}
