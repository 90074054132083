import { NgModule } from '@angular/core';
import { GravelSelectorComponent } from './gravel-selector.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SelectModule } from '../../../shared/components/form-components/select/select.module';

@NgModule({
  declarations: [GravelSelectorComponent],
  imports: [CommonModule, FormsModule, SelectModule],
  exports: [GravelSelectorComponent],
})
export class GravelSelectorModule {}
