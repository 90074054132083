import { Component } from '@angular/core';
import { IFile } from '@dunefront/common/dto/file.dto';
import { Store } from '@ngrx/store';
import { showFileSaveDialog } from '../../../../+store/electron-main/electron-main.actions';

@Component({
  templateUrl: 'electron-demo-files.component.html',
  styleUrls: ['./electron-demo-files.component.scss'],
})
export class ElectronDemoFilesComponent {
  constructor(private store: Store) {}

  public onFileClicked = (source: IFile): void => {
    this.store.dispatch(showFileSaveDialog({ source }));
  };
}
