import { ChartDataSourceType, ChartDto } from '@dunefront/common/modules/reporting/dto/chart.dto';
import { ITableState } from '@dunefront/common/common/common-grid.interfaces';
import { IChartResultColumn } from './reporting.selectors';
import { DepthDataStatus, IChartDataDto } from '@dunefront/common/modules/reporting/dto/chart-data.dto';
import { ChartSeriesDto } from '@dunefront/common/modules/reporting/dto/chart-series.dto';
import { ReportingCalculationJobStatus } from './reporting.actions';
import { DictionaryWithArray, IDictionaryWithArray } from '@dunefront/common/common/state.helpers';
import { ReportingTabDto } from '@dunefront/common/dto/reporting-tab.dto';
import { ChartLoadingStatus, ReportingFactory } from './model/reporting.factory';
import { IChartTemplateDto } from '@dunefront/common/dto/chart-templates.dto';
import { ChartSeriesTemplateDto } from '@dunefront/common/dto/chart-series-template.dto';
import { IMarker } from '@dunefront/common/modules/reporting/dto/chart-marker.dto';
import {
  ExportDataStartJobActionPayload,
  GetChartDataRequestType,
  IDepthBasedResult,
  ITimeVolDataPoint,
} from '@dunefront/common/modules/reporting/reporting-module.actions';
import { IAnnotation, IAxisProps, IGradientLine } from '../../common-modules/chart/chart-component-helpers/chart-types';
import { CrosshairMode, TooltipPosition } from '@dunefront/common/modules/reporting/reporting.settings';
import { defaultMaxSeriesInTooltip } from '@dunefront/common/common/constants';

export const ReportingModuleName = 'reporting';

export const defaultSelectedReportingTabId = 0;

export interface ReportingModuleState {
  calculationJobId: string | undefined;
  calculationJobStatus: ReportingCalculationJobStatus;
  calculationJobMessage: string | undefined;
  calculationJobProgress: number | undefined;
  chartState: ChartState;
  resultsTimeChartState: ChartState;
  resultsDepthChartState: ChartState;
  resultsEvaluatePressureChartState: ChartState;
  resultsEvaluateFrictionChartState: ChartState;
  depthDataForScenarios: IDictionaryWithArray<IDepthDataForScenario>;
  reportingTabDtos: ReportingTabDto[];
  sourceReportingTabDtos: ReportingTabDto[];
  chartDtos: ChartDto[];
  chartUserAddonsDict: IDictionaryWithArray<IChartUserAddons>;
  newReportingTabId: number;
  selectedReportingTabId: number | undefined;
  prevSelectedReportingTabIds: number[];
  simulationDuration: number;
  selectedSimulationTime: number;
  gaugeDataRequestedAfterFileImport: boolean;
  lowerCompletionRange: ILowerCompletionRange;
  isLoaded: boolean;
  reportingTabsIdsRequested: number[];
  chartConfig: IChartConfig;
  crosshairMode: CrosshairMode;
  tooltipPosition: TooltipPosition;
  maxSeriesInTooltip: number;
  chartTemplates: IChartTemplateDto[];
  exportDataJobPayload: ExportDataStartJobActionPayload | undefined;
  currentlyVisibleDrawables: VisibleDrawable[];
  chartSeriesTemplates: ChartSeriesTemplateDto[];
}

export interface VisibleDrawable {
  id: string;
  name: string;
  chartId?: number;
  isImageProvider: boolean;
  isVideoProvider: boolean;
  isDataProvider: boolean;
}

export interface ChartIds {
  wellSurveyChartId: number;
  caliperChartId: number;
  frictionCalculatorChartId: number;
  settlingCalculatorChartId: number;
  injectionTestCalculatorChartId: number;
  psdAChartId: number;
  consolidatedPsd: number;
  rheometerChartId: number;
  trendAnalysisChartId: number;
  gaugeDataChartId: number;
  wellVisualizationId: number;
  simulationVisualizationId: number;
}

export interface IDepthDataForScenario {
  scenarioId: number;
  depthDataForRanges: IDictionaryWithArray<IDepthDataForRange>;
}

export interface IDepthDataForRange {
  rangeId: number;
  simulationDuration: number;
  depthDataResultsStatus: DepthDataStatus;
  allDataPoints: ITimeVolDataPoint[];
  loadedDataPoints: number[]; // array of times
  depthDataResults: IDictionaryWithArray<IDepthBasedResult>;
}

export const reportingInitialState: ReportingModuleState = {
  chartState: ReportingFactory.getEmptyChartState(),
  calculationJobStatus: ReportingCalculationJobStatus.notActive,
  calculationJobMessage: undefined,
  calculationJobProgress: undefined,
  resultsTimeChartState: ReportingFactory.getEmptyChartState(),
  resultsDepthChartState: ReportingFactory.getEmptyChartState(),
  resultsEvaluatePressureChartState: ReportingFactory.getEmptyChartState(),
  resultsEvaluateFrictionChartState: ReportingFactory.getEmptyChartState(),
  reportingTabDtos: [],
  sourceReportingTabDtos: [],
  chartDtos: [],
  chartUserAddonsDict: { ids: [], dict: {} },
  newReportingTabId: 0,
  simulationDuration: 0,
  selectedSimulationTime: 0,
  selectedReportingTabId: defaultSelectedReportingTabId,
  prevSelectedReportingTabIds: [],
  calculationJobId: undefined,
  depthDataForScenarios: DictionaryWithArray.clear(),
  gaugeDataRequestedAfterFileImport: false,
  lowerCompletionRange: { lcTop: 0, lcBottom: 0 },
  isLoaded: false,
  reportingTabsIdsRequested: [],
  chartConfig: ReportingFactory.getEmptyChartConfig(),
  chartTemplates: [],
  exportDataJobPayload: undefined,
  currentlyVisibleDrawables: [],
  chartSeriesTemplates: [],
  crosshairMode: CrosshairMode.MULTIPLE,
  tooltipPosition: TooltipPosition.DEFAULT,
  maxSeriesInTooltip: defaultMaxSeriesInTooltip,
};

export interface ChartState {
  chartData: IChartDataDto | undefined;
  chartSeries: ChartSeriesDto[];
  requestType: GetChartDataRequestType;
  chartDataSourceType?: ChartDataSourceType;
  chartLoadingStatus: ChartLoadingStatus;
}

export enum ChartDisplayArgumentMode {
  Time = 0,
  Volume = 1,
  MD = 2,
  TVD = 3,
}

export interface IChartConfig {
  chartId?: number;
  tabId?: number;
  chartDataSourceType: ChartDataSourceType;
  resultColumnsTableState: ITableState<IChartResultColumn>;
}

export interface IXAxisShiftUpdate {
  FileId: number;
  XAxisShift: number;
}

export interface IYAxisShiftUpdate {
  ColumnId: number;
  FileId: number;
  VerticalShift: number;
}

export interface ILowerCompletionRange {
  lcTop: number;
  lcBottom: number;
}

export interface IChartUserAddons {
  chartId: number;
  chartMarkers: IMarker[];
  chartAxisProperties: IAxisProps[];
  chartAnnotations: IAnnotation[];
  chartGradientLines: IGradientLine[];
}
