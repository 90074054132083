import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TabbedPageModule } from '../../../common-modules/tabbed-page/tabbed-page.component';
import { FormInputModule } from '../../../shared/components/form-components/form-input/form-input.component';
import { UnwrapDirectiveModule } from '../../../shared/directives/unwrap.directive';
import { SelectModule } from '../../../shared/components/form-components/select/select.module';
import { ButtonModule } from '../../../shared/components/button/button.module';
import { SearchbarModule } from '../../../shared/components/searchbar/searchbar.component';
import { TooltipModule } from 'primeng/tooltip';
import { CalculationQueueComponent } from './components/calculation-queue.component';
import { ModalsModule } from '../../../common-modules/modals/modals.module';
import { ChartModule } from '../../../common-modules/chart/chart.module';
import { RadioGroupModule } from '../../../shared/components/form-components/radio-group/radio-group.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { CheckBoxModule } from '../../../shared/components/form-components/check-box/check-box.component';
import { GridModule } from '../../../shared/components/grid/grid.module';
import { PagePanelModule } from '../../../common-modules/page-panel/page-panel.component';
import { LoaderModule } from '../../../common-modules/loader/loader.component';
import { ColorPickerModule } from 'primeng/colorpicker';
import { CalendarModule } from 'primeng/calendar';
import { DateTimeInputModule } from '../../../shared/components/form-components/date-time-input/date-time-input.component';
import { FontStyleComponent } from '../../../shared/components/font-style/font-style.component';
import { UnitsModule } from '../../../common-modules/units/units.module';
import { HelpUrlPipeModule } from '../../../shared/pipes/help-url.pipe';

@NgModule({
  declarations: [CalculationQueueComponent],
  exports: [CalculationQueueComponent],
  imports: [
    CommonModule,
    TabbedPageModule,
    FormsModule,
    ButtonModule,
    SelectModule,
    FormInputModule,
    UnwrapDirectiveModule,
    SearchbarModule,
    TooltipModule,
    ModalsModule,
    ChartModule,
    RadioGroupModule,
    CommonModule,
    ButtonModule,
    FormInputModule,
    MultiSelectModule,
    UnwrapDirectiveModule,
    RadioGroupModule,
    SelectModule,
    CheckBoxModule,
    GridModule,
    PagePanelModule,
    LoaderModule,
    ColorPickerModule,
    FormsModule,
    CalendarModule,
    TooltipModule,
    DateTimeInputModule,
    ModalsModule,
    FontStyleComponent,
    UnitsModule,
    HelpUrlPipeModule,
  ],
})
export class CalculationQueueModule {}
