import { Dict } from '../../common/state.helpers';
import { evaluate } from 'mathjs';
import { UnitConverterHelper } from '../../unit-converters/unit.converter.helper';
import { EquationColumn, IEquation } from './equation-column';
import { ImportColumnDto } from '../data-storage/dto/import-column.dto';

export interface IEquationVariable {
  Id: number;
  Name: string;
  FileId: number;
  ColId: number;
  Unit: number;
}

export interface IEquationPreviewDto {
  columns: Dict<number[]>;
  maxDataLength: number;
}

export interface EquationPreviewRow {
  Id: number;
  [Key: string]: number;
}

export const isEquationColumnConstant = (equationColumn: EquationColumn): boolean =>
  isCorrectConstantValue(equationColumn.Equation.constantValue);

export const isCorrectConstantValue = (constantValue: number | undefined): boolean => !isNaN(Number(constantValue));

export const isEquationFormulaConstant = (formula: string, variables: IEquationVariable[]): boolean => {
  const variableNames = variables.map((v) => v.Name);
  return formula.split('').every((char) => !variableNames.includes(char));
};

export const evaluateEquationResult = (
  row: EquationPreviewRow,
  equation: IEquation,
  allColumnsByColId: Map<number, ImportColumnDto>,
): number => {
  const convertedRow = { ...row };
  for (const variable of equation.variables) {
    // convert value to one that is set up in variable and build equation formula
    const column = allColumnsByColId.get(variable.ColId);
    if (!column) {
      continue;
    }
    const value = row[variable.Name];
    convertedRow[variable.Name] = UnitConverterHelper.convertFromSiForUnit(column.UnitSystem, variable.Unit, value);
  }

  const parsedEquation = isEquationFormulaConstant(equation.equationFormula, equation.variables)
    ? equation.equationFormula
    : equation.equationFormula
        .split('')
        .map((char) => (convertedRow[char] != null ? convertedRow[char] : char))
        .join('');

  const result = equationEvaluate(parsedEquation);

  if (result == null || isNaN(result)) {
    return 0;
  }

  return result;
};

export const equationEvaluate = (parsedEquationFormula: string): number | undefined => {
  let result: number | undefined;
  try {
    const evaluation = evaluate(parsedEquationFormula);

    if (Number(evaluation)) {
      result = evaluation;
    }
  } catch (e) {
    console.warn(e);
  }
  return result;
};
