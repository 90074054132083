import { DocumentGeneratorHelper } from '../document-generator.helper';
import { HeadingLevel, Paragraph, Table } from 'docx';
import { IUnitSystemDto } from '@dunefront/common/dto/unit-system.dto';
import { getRowsForCalculations, ITableState } from '@dunefront/common/common/common-grid.interfaces';
import { EnumHelpers } from '@dunefront/common/utils/enum.helpers';
import { defaultExtraTableConfig, DocumentTableGeneratorHelper, PresetColumnsWidths } from '../document-table-generator.helper';
import { Pipe } from '@dunefront/common/modules/pipes/pipe';
import { ValidatedCompletionModuleState } from '@dunefront/common/modules/completion/completion-module.state';
import { PipeType } from '@dunefront/common/dto/pipe.dto';
import { WorkstringPipe } from '@dunefront/common/modules/pipes/running-string-pipes/pipes/workstring-pipe';
import { ToolJoint } from '@dunefront/common/modules/pipes/tool-joint/tool-joint';
import { ServiceToolPipe } from '@dunefront/common/modules/pipes/running-string-pipes/pipes/service-tool-pipe';
import { BypassAnnulus } from '@dunefront/common/modules/pipes/running-string-pipes/bypass-annulus';
import { ConcentricGaugeCarrierPipe } from '@dunefront/common/modules/pipes/running-string-pipes/pipes/concentric-gauge-carrier-pipe';
import { PressureAttenuatorPipe } from '@dunefront/common/modules/pipes/running-string-pipes/pipes/pressure-attenuator-pipe';
import {
  getGaugeSectionInformationRows,
  getValidatedCompletionModuleState,
} from '../../../../../../+store/completion/validated-completion.selectors';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';
import { icdScreenProperties, lowerCompletionScreenProperties } from '../../common/grid-configs/completion-lower-completion';
import { ScreenPipe } from '@dunefront/common/modules/pipes/lower-completion-pipes/pipes/screen-pipe';
import { IcdScreenPipe } from '@dunefront/common/modules/pipes/lower-completion-pipes/pipes/icd-screen-pipe';
import { DictionaryWithArray, isDefined } from '@dunefront/common/common/state.helpers';
import { ICDType } from '@dunefront/common/dto/lower-completion-pipe.dto';
import { NozzleProperties, Shroud, ShuntTube, Tube } from '@dunefront/common/modules/shunt-tube/shunt-tube';
import {
  nozzleGridConfig,
  shroudGridConfig,
  shuntTubesSystemGridConfig,
  tubeGridConfig,
} from '../../common/grid-configs/completion-shunt-tubes';
import {
  bypassAnnulusesProperties,
  washPipeAccessoriesProperties,
  workstringProperties,
} from '../../common/grid-configs/completion-running-string';
import { completionGeneralConfig } from '../../common/grid-configs/completion-general';
import { calculateVolumesSectionAction } from '../../../../../../+store/completion/completion.actions';
import { SectionInformationRow } from '@dunefront/common/modules/completion/model/volumes/section-information-row';
import { ReportScenarioImages } from './chart-report-generator.helper';
import { CompletionValidation } from '@dunefront/common/modules/completion/model/completion.validation';
import {
  CommonReportDataHelpers,
  IDataRowWithCode,
  IReportTableColumnConfig,
  ITableData,
  keyValueGridConfig,
} from '../../common/common-report-data-helpers';
import { filter } from 'rxjs/operators';
import { DocxImageGenerator } from '../docx-image-generator';

export class CompletionReportGeneratorHelper {
  public static async createCompletionDataSection(
    store: Store,
    currentUnitSystem: IUnitSystemDto,
    images: ReportScenarioImages,
    docxImageGenerator: DocxImageGenerator,
  ): Promise<Array<Paragraph | Table>> {
    store.dispatch(calculateVolumesSectionAction({ calculationType: 'multiple' }));

    const completionState: ValidatedCompletionModuleState = await firstValueFrom(store.select(getValidatedCompletionModuleState));
    const volumeGaugeSection: ITableState<SectionInformationRow> = await firstValueFrom(
      store.select(getGaugeSectionInformationRows).pipe(filter(isDefined)),
    );

    return [
      DocumentGeneratorHelper.createParagraphHeader(`Completion`, HeadingLevel.HEADING_1, 0),
      DocumentGeneratorHelper.createParagraphHeader(`General Data`, HeadingLevel.HEADING_2, 1),

      this.createCompletionGeneralData(completionState, currentUnitSystem),
      ...CompletionReportGeneratorHelper.createLowerCompletionSection(completionState, currentUnitSystem),
      ...CompletionReportGeneratorHelper.createRunningStringSection(completionState, currentUnitSystem),
      ...(CompletionValidation.IsShuntTubeEnabled(completionState)
        ? CompletionReportGeneratorHelper.createShuntTubesSection(completionState, currentUnitSystem)
        : []),
      ...(images.schematic
        ? [
            DocumentGeneratorHelper.createParagraphHeader(`Schematic`, HeadingLevel.HEADING_2, 1),
            ...(await DocumentGeneratorHelper.drawGenericImage(images.schematic, docxImageGenerator)),
          ]
        : []),

      ...(volumeGaugeSection.rows.length > 0
        ? CompletionReportGeneratorHelper.createVolumesSection(volumeGaugeSection, currentUnitSystem)
        : []),
    ];
  }

  public static createLowerCompletionSection(
    completionState: ValidatedCompletionModuleState,
    currentUnitSystem: IUnitSystemDto,
  ): Array<Paragraph | Table> {
    const lowerCompletionData: IDataRowWithCode<Pipe>[] = CommonReportDataHelpers.generateCodeValues(
      getRowsForCalculations(completionState.LowerCompletion.rows),
    );
    const hasICD = !!lowerCompletionData.find((pipe) => pipe.PipeType === PipeType.ICD_Screen);

    return [
      DocumentGeneratorHelper.createParagraphHeader('Lower Completion', HeadingLevel.HEADING_2, 1),

      this.createLowerCompletion(lowerCompletionData, currentUnitSystem),
      DocumentGeneratorHelper.createParagraphHeader(`Screen Properties`, HeadingLevel.HEADING_3, 2),

      this.createLowerCompletionScreens(lowerCompletionData, currentUnitSystem),
      ...(hasICD
        ? [
            DocumentGeneratorHelper.createParagraphHeader(`ICD/AICD Properties`, HeadingLevel.HEADING_3, 2),

            this.createICD(completionState, currentUnitSystem),
          ]
        : []),
    ];
  }

  public static createRunningStringSection(
    completionState: ValidatedCompletionModuleState,
    currentUnitSystem: IUnitSystemDto,
  ): Array<Paragraph | Table> {
    const runningStringData: IDataRowWithCode<Pipe>[] = CommonReportDataHelpers.generateCodeValues(
      getRowsForCalculations(completionState.RunningString.rows),
    );
    const washPipeAccessories = <Array<IDataRowWithCode<ConcentricGaugeCarrierPipe | PressureAttenuatorPipe>>>(
      runningStringData.filter(
        (component) => component.PipeType === PipeType.Concentric_Gauge_Carrier || component.PipeType === PipeType.Pressure_Attenuator,
      )
    );
    return [
      DocumentGeneratorHelper.createParagraphHeader(`Running String`, HeadingLevel.HEADING_2, 1),
      this.createRunningString(runningStringData, currentUnitSystem),

      DocumentGeneratorHelper.createParagraphHeader(`Workstring Properties`, HeadingLevel.HEADING_3, 2),
      this.createWorkstringProperties(runningStringData, currentUnitSystem),

      DocumentGeneratorHelper.createParagraphHeader(`Service Tool Properties`, HeadingLevel.HEADING_3, 2),
      this.createServiceToolProperties(runningStringData, currentUnitSystem),

      ...(washPipeAccessories.length
        ? [
            DocumentGeneratorHelper.createParagraphHeader(`Washpipe Accessories`, HeadingLevel.HEADING_3, 2),

            this.createWashPipeAccessoriesProperties(washPipeAccessories, currentUnitSystem),
          ]
        : []),
    ];
  }

  private static createRunningString(runningStringData: IDataRowWithCode<Pipe>[], currentUnitSystem: IUnitSystemDto): Table {
    const { tableData, enumItemMap } = CommonReportDataHelpers.createRunningStringTableData(runningStringData);
    const presetColumnsWidths: PresetColumnsWidths = { 1: 18, 5: 9, 6: 9, 7: 9 };

    return DocumentTableGeneratorHelper.createTable(tableData, enumItemMap, currentUnitSystem, undefined, presetColumnsWidths);
  }

  private static createWorkstringProperties(runningStringData: IDataRowWithCode<Pipe>[], currentUnitSystem: IUnitSystemDto): Table {
    const toolJoints: ToolJoint[] = runningStringData
      .filter((component) => component.PipeType === PipeType.Workstring)
      .map((component) => (component as WorkstringPipe).ToolJoint);

    const runningStringTableData: ITableData<ToolJoint> = {
      dataRows: CommonReportDataHelpers.generateCodeValues(toolJoints).map((col) => ({
        ...col,
        Name: 'Workstring',
      })),
      dataCells: workstringProperties,
    };

    return DocumentTableGeneratorHelper.createTable(runningStringTableData, new Map(), currentUnitSystem);
  }

  private static createServiceToolProperties(runningStringData: IDataRowWithCode<Pipe>[], currentUnitSystem: IUnitSystemDto): Table {
    // There always is only one service tool
    const serviceTool = <ServiceToolPipe>runningStringData.find((component) => component.PipeType === PipeType.Service_Tool);

    const bypassAnnuluses: ReportServiceTool[] = [
      { ...serviceTool.DownFlow, FlowType: 'Cross Over' },
      {
        ...serviceTool.UpFlow,
        FlowType: 'Return',
      },
    ];

    const runningStringTableData: ITableData<ReportServiceTool> = {
      dataRows: bypassAnnuluses,
      dataCells: bypassAnnulusesProperties,
    };

    return DocumentTableGeneratorHelper.createTable(runningStringTableData, new Map(), currentUnitSystem);
  }

  private static createWashPipeAccessoriesProperties(
    runningStringData: Array<IDataRowWithCode<ConcentricGaugeCarrierPipe | PressureAttenuatorPipe>>,
    currentUnitSystem: IUnitSystemDto,
  ): Table {
    const washPipeTableData: ITableData<IDataRowWithCode<ReportWashPipeAccessory>> = {
      dataRows: runningStringData.map((component) =>
        'ActivationPressure' in component
          ? { Name: 'Pressure Attenuator', ActivationPressure: component.ActivationPressure, Code: component.Code }
          : { ...component.FlowPorts, Name: 'Concentric Gauge Carrier', Code: component.Code },
      ),
      dataCells: washPipeAccessoriesProperties,
    };

    return DocumentTableGeneratorHelper.createTable(washPipeTableData, new Map(), currentUnitSystem);
  }

  private static createCompletionGeneralData(completionState: ValidatedCompletionModuleState, currentUnitSystem: IUnitSystemDto): Table {
    const hasCentralizersTextVal = completionState.HasCentralizers ? 'Yes' : 'No';

    const generalTable: ITableData<any> = {
      dataRows: completionGeneralConfig(completionState.HasCentralizers).map(
        (cell: IReportTableColumnConfig<IDataRowWithCode<ValidatedCompletionModuleState>>) => {
          const name = cell.headerText;
          const value = CommonReportDataHelpers.getTableCellValue(cell, completionState, new Map(), currentUnitSystem, true);

          return {
            name,
            value: cell.colId === 'HasCentralizers' ? hasCentralizersTextVal : value, // TODO: review!!
          };
        },
      ),
      dataCells: keyValueGridConfig,
    };

    const extraTableConfig = { ...defaultExtraTableConfig, drawBorders: false, drawShadows: false, drawHeader: false };

    return DocumentTableGeneratorHelper.createTable(generalTable, null, currentUnitSystem, extraTableConfig, { 0: 10, 1: 20 }, true);
  }

  private static createLowerCompletion(lowerCompletionData: IDataRowWithCode<Pipe>[], currentUnitSystem: IUnitSystemDto): Table {
    const { tableData, enumItemMap } = CommonReportDataHelpers.createLowerCompletionTableData(lowerCompletionData);
    const presetColumnsWidths: PresetColumnsWidths = { 1: 18, 5: 9, 6: 9, 7: 9 };

    return DocumentTableGeneratorHelper.createTable(tableData, enumItemMap, currentUnitSystem, undefined, presetColumnsWidths);
  }

  private static createLowerCompletionScreens(lowerCompletionData: IDataRowWithCode<Pipe>[], currentUnitSystem: IUnitSystemDto): Table {
    const lowerCompletionScreens = <ScreenPipe[]>(
      lowerCompletionData.filter(
        (pipe) => pipe.PipeType === PipeType.Screen || pipe.PipeType === PipeType.Shunted_Screen || pipe.PipeType === PipeType.ICD_Screen,
      )
    );

    const lowerCompletionTableData: ITableData<ScreenPipe> = {
      dataRows: lowerCompletionScreens.map((screen) => ({
        ...screen,
        Name: EnumHelpers.enumToText(PipeType[screen.PipeType]),
      })),
      dataCells: lowerCompletionScreenProperties,
    };

    return DocumentTableGeneratorHelper.createTable(lowerCompletionTableData, new Map(), currentUnitSystem);
  }

  private static createICD(completionModuleState: ValidatedCompletionModuleState, currentUnitSystem: IUnitSystemDto): Table {
    const ICDScreens = <IDataRowWithCode<IcdScreenPipe>[]>(
      CommonReportDataHelpers.generateCodeValues(getRowsForCalculations(completionModuleState.LowerCompletion.rows)).filter(
        (pipe) => pipe.PipeType === PipeType.ICD_Screen,
      )
    );

    const ICDPortData = <IDataRowWithCode<ReportIcdScreen[]>>ICDScreens.map(
      (icdScreen) =>
        DictionaryWithArray.get(completionModuleState.IcdPortData, icdScreen.Id)
          ?.rows.filter((row) => row.rowType === 'data')
          .map((row) => ({
            Code: icdScreen.Code,
            Name: 'ICD Screen',
            Type: EnumHelpers.enumToText(ICDType[icdScreen.ICDType]),
            NoOfPorts: row.rowData.NumberOfPorts,
            PortDiameter: row.rowData.PortDiameter || undefined,
            PortLength: row.rowData.PortLength || undefined,
          })),
    ).flat();

    const icdScreenTableData: ITableData<ReportIcdScreen> = {
      dataRows: ICDPortData,
      dataCells: icdScreenProperties,
    };

    return DocumentTableGeneratorHelper.createTable(icdScreenTableData, new Map(), currentUnitSystem);
  }

  public static createShuntTubesSection(
    completionState: ValidatedCompletionModuleState,
    currentUnitSystem: IUnitSystemDto,
  ): Array<Paragraph | Table> {
    return [
      DocumentGeneratorHelper.createParagraphHeader('Shunt Tube Data', HeadingLevel.HEADING_2, 1),
      DocumentGeneratorHelper.createParagraphHeader('System Configuration', HeadingLevel.HEADING_3, 2),

      this.createSystemConfiguration(completionState, currentUnitSystem),
      ...(completionState.ShuntTube.IsTransportTubePresent
        ? [
            DocumentGeneratorHelper.createParagraphHeader('Transport Tube Properties', HeadingLevel.HEADING_3, 2),

            this.createTransportTubeProperties(completionState, currentUnitSystem),
          ]
        : []),
      DocumentGeneratorHelper.createParagraphHeader('Packing Tube Properties', HeadingLevel.HEADING_3, 2),

      this.createPackingTubeProperties(completionState, currentUnitSystem),

      ...(completionState.ShuntTube.NozzleProperties.IsNozzleLeakoff
        ? [
            DocumentGeneratorHelper.createParagraphHeader('Nozzle Properties', HeadingLevel.HEADING_3, 2),

            this.createNozzleProperties(completionState, currentUnitSystem),
          ]
        : []),

      ...(completionState.ShuntTube.Shroud.IsShroudPresent
        ? [
            DocumentGeneratorHelper.createParagraphHeader('Shroud Properties', HeadingLevel.HEADING_3, 2),

            this.createShroudProperties(completionState, currentUnitSystem),
          ]
        : []),
    ];
  }

  private static createSystemConfiguration(completionState: ValidatedCompletionModuleState, currentUnitSystem: IUnitSystemDto): Table {
    const { keyValueData, enumItemMap } = CommonReportDataHelpers.createShuntTubeSystemConfigurationKeyValueData(completionState);

    return DocumentTableGeneratorHelper.createGenericKeyValueTable<ReportShuntTube>(
      shuntTubesSystemGridConfig,
      keyValueData,
      currentUnitSystem,
      enumItemMap,
    );
  }

  private static createTransportTubeProperties(completionState: ValidatedCompletionModuleState, currentUnitSystem: IUnitSystemDto): Table {
    return DocumentTableGeneratorHelper.createGenericKeyValueTable<Tube>(
      tubeGridConfig(completionState.ShuntTube.Shape),
      completionState.ShuntTube.TransportTube,
      currentUnitSystem,
      null,
    );
  }

  private static createPackingTubeProperties(completionState: ValidatedCompletionModuleState, currentUnitSystem: IUnitSystemDto): Table {
    return DocumentTableGeneratorHelper.createGenericKeyValueTable<Tube>(
      tubeGridConfig(completionState.ShuntTube.Shape),
      completionState.ShuntTube.PackingTube,
      currentUnitSystem,
      null,
    );
  }

  private static createNozzleProperties(completionState: ValidatedCompletionModuleState, currentUnitSystem: IUnitSystemDto): Table {
    return DocumentTableGeneratorHelper.createGenericKeyValueTable<NozzleProperties>(
      nozzleGridConfig,
      completionState.ShuntTube.NozzleProperties,
      currentUnitSystem,
      null,
    );
  }

  private static createShroudProperties(completionState: ValidatedCompletionModuleState, currentUnitSystem: IUnitSystemDto): Table {
    return DocumentTableGeneratorHelper.createGenericKeyValueTable<Shroud>(
      shroudGridConfig,
      completionState.ShuntTube.Shroud,
      currentUnitSystem,
      null,
    );
  }

  private static createVolumesSection(
    volumeGaugeSection: ITableState<SectionInformationRow>,
    currentUnitSystem: IUnitSystemDto,
  ): Array<Paragraph | Table> {
    const { tableData } = CommonReportDataHelpers.createVolumesGaugeSectionTableData(volumeGaugeSection, currentUnitSystem);

    return [
      DocumentGeneratorHelper.createParagraphHeader('Volumes', HeadingLevel.HEADING_2, 1),
      DocumentGeneratorHelper.createParagraphHeader(`Gauge Section Information`, HeadingLevel.HEADING_3, 2),
      DocumentTableGeneratorHelper.createTable(tableData, null, currentUnitSystem, undefined, {
        0: 5,
        1: 20,
        2: 10,
        3: 10,
        4: 10,
        5: 10,
        6: 10,
        7: 10,
      }),
    ];
  }
}

export interface ReportServiceTool extends BypassAnnulus {
  FlowType: string;
}

export interface ReportWashPipeAccessory {
  Name: string;
  ActivationPressure?: number;
  NoOfPorts?: number;
  PortDiameter?: number;
  PortLength?: number;
}

export interface ReportIcdScreen {
  Type: string;
  NoOfPorts: number;
  PortDiameter: number | string;
  PortLength: number | string;
}

export interface ReportShuntTube extends Omit<ShuntTube, 'IsExternal' | 'Shroud' | 'IsInternalReservoir' | 'IsTransportTubePresent'> {
  Shroud: string;
  IsInternalReservoir: string;
  IsTransportTubePresent: string;
  IsExternal: string;
}
