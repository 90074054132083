import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { CommonDbType } from '@dunefront/common/dto/common-dto.interfaces';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { defaultModalCancelButton, defaultModalConfirmButton, ModalButtonConfig } from '../generic-modal/generic-modal.component';

@Component({
  selector: 'app-get-ppd-target',
  template: `<app-generic-modal [title]="'Save ' + saveName" [modalButtonsConfigs]="modalButtonsConfigs">
    <app-radio-group
      [dataCy]="saveName"
      [isUiLockable]="false"
      id="get-ppd-target-radio"
      [items]="[
        { value: 'User', text: 'Save to my settings' },
        { value: 'Organization', text: 'Save to organization settings' }
      ]"
      [value]="selectedValue"
      (primitiveValueChanged)="valueChanged($event.value)"
    ></app-radio-group>
  </app-generic-modal>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GetPpdTargetComponent {
  @Input() public saveName!: string;
  public selectedValue?: CommonDbType;
  public modalButtonsConfigs: ModalButtonConfig[] = [
    defaultModalCancelButton((): void => this.cancelClicked()),
    defaultModalConfirmButton((): void => this.okClicked()),
  ];

  constructor(
    protected store: Store,
    protected cdRef: ChangeDetectorRef,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
  ) {
    this.saveName = config.data.saveName;
  }

  public valueChanged(selectedValue: string | undefined): void {
    if (selectedValue === 'User' || selectedValue === 'Organization') {
      this.selectedValue = selectedValue;
    } else {
      this.selectedValue = undefined;
    }
    this.cdRef.markForCheck();
  }

  public okClicked(): void {
    if (this.selectedValue === undefined) {
      return;
    }
    this.ref.close(this.selectedValue);
  }

  public cancelClicked(): void {
    this.ref.close();
  }
}
