<app-grid
  [dataSource]="inputData"
  [gridConfig]="gridConfig"
  [columns]="gridConfig.columns"
  [showCrudControls]="false"
  [headerTopRowHeight]="30"
  [height]="height - 60"
  [minRowCount]="1"
  [isFirstRowInsertingAllowed]="true"
  elementId="injection-test-grid"
  [isFirstRowDeleteAllowed]="true"
  (selectedRowsChanged)="selectedRowsChanged.emit($event)"
></app-grid>
<div class="buttons-panel" *ngIf="inputData">
  <div class="align-right">
    <button
      appButton
      type="button"
      class="btn btn-primary btn-sm"
      data-cy="injection-test-input-data-import"
      (buttonClick)="onImport()"
    >
      Import
    </button>
    <button appButton type="button" class="btn btn-primary btn-sm" (buttonClick)="onDelete()">Delete</button>
  </div>
</div>
