import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ResuspensionCalculatorModuleFeatureName } from './resuspension-calculator.reducer';
import { ResuspensionCalculatorModuleState } from '@dunefront/common/modules/calculators/resuspension-calculator/resuspension-calculator-module.state';
import { ResuspensionCalculatorValidation } from '@dunefront/common/modules/calculators/resuspension-calculator/model/resuspension-calculator.validation';

const getResuspensionCalculatorModuleState = createFeatureSelector<ResuspensionCalculatorModuleState>(
  ResuspensionCalculatorModuleFeatureName,
);

export const getValidatedResuspensionCalculatorModuleState = createSelector(getResuspensionCalculatorModuleState, (state) =>
  ResuspensionCalculatorValidation.validate(state),
);

export const getHasResuspensionCalculatorResults = createSelector(
  getValidatedResuspensionCalculatorModuleState,
  (state) => state.ResuspensionRate !== 0 || state.ResuspensionVelocity !== 0,
);
