import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { BaseChartConfigurationComponent } from '../../../../../common-modules/chart/chart-configuration/base-chart-configuration.component';
import { Store } from '@ngrx/store';
import { ModalService } from '../../../../../common-modules/modals/modal.service';
import { ScreenService } from '../../../../../shared/services';
import { addChartTabConfigDone, clearCustomizations, saveChartConfigDialog } from '../../../../../+store/reporting/reporting.actions';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import {
  EditChartTemplateModalComponent,
  EditChartTemplateModalComponentPayload,
} from '../../../../../common-modules/chart/edit-template-modal/edit-chart-template-modal.component';

import { getChartConfig, getChartUserAddonsDict } from '../../../../../+store/reporting/reporting.selectors';
import { getCurrentAppModuleType } from '../../../../../+store/ui/ui.selectors';
import { combineLatest, firstValueFrom } from 'rxjs';
import { ReportingFactory } from '../../../../../+store/reporting/model/reporting.factory';
import { getChartTemplateProperties } from '../../../../../+store/menu-selectors/chart/menu-chart.selector';

@Component({
  templateUrl: './reporting-chart-configuration.component.html',
  styleUrls: ['./reporting-chart-configuration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportingChartConfigurationComponent extends BaseChartConfigurationComponent {
  private newChartType: 'timevol' | 'mdtvd' = 'timevol';

  public get isDataPro(): boolean {
    return this.currentAppModuleType === ModuleType.Data_Analysis;
  }

  constructor(
    store: Store,
    cdRef: ChangeDetectorRef,
    modalService: ModalService,
    resizeService: ScreenService,
    ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
  ) {
    super(ref, store, cdRef, modalService, resizeService);
    if (config.data.chartType != null) {
      this.newChartType = config.data.chartType;
    }
  }

  public async onSaveClicked(): Promise<void> {
    if (this.isImportDataColumnSelected()) {
      return await this.modalService.showAlert('Templates can’t be created for charts with imported data', '', 'sm', false);
    }

    const [chartConfig, chartUserAddons, moduleType, props] = await firstValueFrom(
      combineLatest([
        this.store.select(getChartConfig),
        this.store.select(getChartUserAddonsDict),
        this.store.select(getCurrentAppModuleType),
        this.store.select(getChartTemplateProperties),
      ]),
    );

    const template = ReportingFactory.createChartTemplate(
      chartConfig,
      chartUserAddons,
      '',
      'User',
      this.newChartType === 'timevol',
      '',
      moduleType,
      props,
    );

    this.modalService.open<EditChartTemplateModalComponentPayload>(EditChartTemplateModalComponent, { template });
  }

  public override onOkClicked(): void {
    const chartId = this.chartId;

    if (chartId != null && chartId !== 0) {
      this.store.dispatch(
        saveChartConfigDialog({
          chartResultsTableState: this.resultColumnsTableState,
          chartId,
          scenarioId: this.currentScenarioId,
        }),
      );
    } else {
      this.store.dispatch(
        addChartTabConfigDone({
          chartResultsTableState: this.resultColumnsTableState,
          chartType: this.newChartType,
        }),
      );
    }

    super.onOkClicked();
  }

  public onClearCustomizationsClicked(): void {
    const chartId = this.chartId;
    if (chartId == null) {
      return;
    }

    this.store.dispatch(clearCustomizations({ chartId }));
  }
}
