<div class="grid-form-container" *ngIf="refVariables">
  <app-form-input
    [source]="refVariables"
    [unitType]="UnitType.Mass"
    [disabled]="true"
    appUnwrap
    [key]="'GravelPumped'"
    label="Gravel Pumped"
  >
  </app-form-input>

  <app-form-input
    [source]="refVariables"
    unitLabel="%"
    [unitType]="UnitType.None"
    [disabled]="true"
    appUnwrap
    [key]="'ExcessGravel'"
    label="Excess Gravel"
  >
  </app-form-input>

  <app-form-input
    [source]="refVariables"
    [unitType]="UnitType.Liquid_Volume"
    [disabled]="true"
    appUnwrap
    [key]="'DisplacementToPacker'"
    label="Flush Volume"
  >
  </app-form-input>
</div>
