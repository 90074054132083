import { Pipe, ShuntedPipe } from '../../pipe';
import { ShuntedScreenPipe } from './shunted-screen-pipe';
import { GeneralCalculations } from '../../../../common/general.calculations';
import { PipeType } from '../../../../dto/pipe.dto';
import { LowerCompletionPipeDto } from '../../../../dto/lower-completion-pipe.dto';
import { ShuntTube } from '../../../shunt-tube/shunt-tube';
import { BasePipeFactory } from '../../base.pipe.factory';
import { TubeShape } from '../../../../dto/shunt-tube.dto';
import { RectangleCalculations } from '../../../../common/shapes/rectangle';
import { RoundCalculations } from '../../../../common/shapes/round';

export interface ShuntedBlankPipe extends Pipe, ShuntedPipe {
  PipeType: PipeType.Shunted_Blank_Pipe;
}

export class ShuntedBlankPipeFactory {
  public static createFromPermanentCompletion(dto: LowerCompletionPipeDto): ShuntedBlankPipe {
    const pipe = BasePipeFactory.createFromDto(dto);
    const shuntedBlankPipe: ShuntedBlankPipe = { ...pipe, PipeType: PipeType.Shunted_Blank_Pipe };
    return shuntedBlankPipe;
  }

  public static createFromShuntedScreen(theShuntedScreen: ShuntedScreenPipe, bottomMD: number, tomMD: number): ShuntedBlankPipe {
    const shuntedBlank: ShuntedBlankPipe = { ...theShuntedScreen, BottomMD: bottomMD, TopMD: tomMD, PipeType: PipeType.Shunted_Blank_Pipe };
    return shuntedBlank;
  }
}

export class ShuntedBlankCalculations {
  // Method to calculate the physical diameter of a component which must fit inside the outer string
  public MaxDiameter(pipe: ShuntedBlankPipe, shuntTube: ShuntTube): number {
    // Declare a variable for the assembly diameter
    let assemblyDiameter = pipe.OuterDiameter;
    // Adjust the assembly diameter using the transport or packing tubes as applicable
    const tube = shuntTube.IsTransportTubePresent ? shuntTube.TransportTube : shuntTube.PackingTube;
    const displacedArea =
      shuntTube.Shape === TubeShape.Rectangle
        ? RectangleCalculations.DisplacedArea(tube, shuntTube.IsExternal)
        : RoundCalculations.DisplacedArea(tube, shuntTube.IsExternal);
    // Calculate the diameter of the assembly
    assemblyDiameter += GeneralCalculations.EquivalentDiameter(displacedArea);
    // Compare with the shroud if present
    if (shuntTube.Shroud.IsShroudPresent) {
      // Calculate the max diameter of the
      return Math.max(assemblyDiameter, shuntTube.Shroud.ShroudOuterDiameter);
    } else {
      return assemblyDiameter;
    }
  }
}

export function isShuntedBlankPipe(pipe: Pipe): pipe is ShuntedBlankPipe {
  return pipe.PipeType === PipeType.Shunted_Blank_Pipe;
}
