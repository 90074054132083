import { areStringsTheSame, rationalizeString } from '../../../../common/helpers';
import { IColNameWithId } from '../../../data-storage/data-storage-module.state';
import { IError } from '../../../../common/common-state.interfaces';
import { noErrors } from '../../../../common/state.helpers';
import { IAnalysisDataComponentState, ValidatedAnalysisDataComponentState } from '../../pumping-module.state';

export class AnalysisDataComponentValidation {
  public static validate(
    state: IAnalysisDataComponentState,
    importDataColumns: IColNameWithId[],
    isTrendAnalysis = false,
  ): ValidatedAnalysisDataComponentState {
    const error: IError<IAnalysisDataComponentState> = {
      pumpRateItems: this.validateColumnName(
        state.currentScenarioRangeProperties.PumpRateColumnName,
        importDataColumns,
        isTrendAnalysis,
        'Pump rate',
      ),
      pumpPressureItems: this.validateColumnName(
        state.currentScenarioRangeProperties.PumpPressureColumnName,
        importDataColumns,
        isTrendAnalysis,
        'Pump pressure',
      ),

      returnRateItems: this.validateColumnName(
        state.currentScenarioRangeProperties.ReturnRateColumnName,
        importDataColumns,
        isTrendAnalysis,
        'Return rate',
      ),
      gravelConcItems: this.validateColumnName(
        state.currentScenarioRangeProperties.GravelConcColumnName,
        importDataColumns,
        isTrendAnalysis,
        'Gravel concentration',
      ),
    };

    return {
      ...state,
      error,
      isValid: noErrors(error),
    };
  }

  private static validateColumnName(
    columnName: string,
    importDataColumns: IColNameWithId[],
    isTrendAnalysis: boolean,
    displayColumnName: string,
  ): string {
    if (!columnName) {
      return `${displayColumnName} column must be selected`;
    } else if (areStringsTheSame(columnName, 'No Columns')) {
      if (!isTrendAnalysis) {
        //Trend analysis can be run without a pump rate column
        return `${displayColumnName} column must be defined`;
      }
    } else if (!importDataColumns.map((col) => rationalizeString(col.name)).includes(rationalizeString(columnName))) {
      if (!isTrendAnalysis) {
        return `${displayColumnName} column does not exist`;
      }
    }
    return '';
  }
}
