import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { ITableRow } from '@dunefront/common/common/common-grid.interfaces';
import { ColumnType, GridCellAlign, IGridColumnConfig } from '../grid.interfaces';
import { StopEditingKey } from '../../../../common-modules/units/components/input-2/input-2.component';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { ISelectItem } from '@dunefront/common/common/select.helpers';
import { ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';

const defaultCellHeight = 20;

@Component({
  selector: 'app-table-cell',
  templateUrl: './table-cell.component.html',
  styleUrls: ['./table-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableCellComponent<T> implements OnInit {
  @Input() public selected = false;
  @Input() public isOnlyOneSelected = false;
  @Input() public row!: ITableRow<any>;
  @Input() public colConfig!: IGridColumnConfig<any>;
  @Input() public isInEditMode = false;
  @Input() public getLookupDataSourceFn?: (type: string, rowId: number) => ISelectItem<number>[];
  @Input() public getLookupDataPlaceholderFn?: (type: string, rowId: number) => string;
  @Input() public gridElementId?: string;
  @Input() public disabled = false;
  @Input() public readonly = false;
  @Input() public align?: GridCellAlign;
  @Input() public dataCyGrid = '';
  @Input() public dataCy = '';
  @Input() public allowOptionalStringValue = false;
  @Input() public castNullToNumber = true;
  @Input() public height = defaultCellHeight;
  @Input() public initialInputValue?: number | string;
  @Output() public valueChanged = new EventEmitter<ObjectChangeProp<T>>();
  @Output() public stopEditing = new EventEmitter<StopEditingKey>();
  @Output() public focusNextCell = new EventEmitter();
  @Output() public changeFocusCell = new EventEmitter();
  @Output() public keyPressed = new EventEmitter();

  public UnitType = UnitSystem;
  public ColumnType = ColumnType;

  @HostBinding('attr.data-cy')
  public get dataCyCell(): string {
    return this.dataCy + '_cell';
  }

  public get errorColumnCss(): string {
    if (!this.row.isValid) {
      return 'error-row-icon';
    } else if (this.row.isWarning) {
      return 'warning-row-icon';
    } else {
      return '';
    }
  }

  constructor(private readonly hostElementRef: ElementRef<HTMLElement>) {}

  public ngOnInit(): void {
    this.adjustPadding();
  }

  public get isInsertRow(): boolean {
    return this.row?.rowType === 'insert-row';
  }

  public get inputElementId(): string | undefined {
    if (!this.gridElementId) {
      return undefined;
    }
    return this.gridElementId + '_' + this.colConfig.colId.toString() + '_' + this.row.rowIndex;
  }

  public getLookupDataSource(): ISelectItem<number>[] {
    if (!this.getLookupDataSourceFn || !this.colConfig.lookupDataSourceType) {
      return [];
    }
    return this.getLookupDataSourceFn(this.colConfig.lookupDataSourceType, this.row?.rowData.Id);
  }

  public getLookupDataPlaceholder(): string {
    if (!this.getLookupDataPlaceholderFn || !this.colConfig.lookupDataSourceType || (this.disabled && this.row.rowType === 'insert-row')) {
      return '';
    }
    return this.getLookupDataPlaceholderFn(this.colConfig.lookupDataSourceType, this.row?.rowData.Id);
  }

  /**
   * Adds extra top padding to cell element in order to center vertically content element
   */
  private adjustPadding(): void {
    const defaultContentHeight = this.colConfig.type === ColumnType.select ? 24 : 20;
    if (this.height <= defaultContentHeight) {
      return;
    }

    const padding = (this.height - defaultContentHeight) / 2;
    if (padding > 0) {
      this.hostElementRef.nativeElement.style.paddingTop = `${padding}px`;
    }
  }

  public get shouldShowSelectInput(): boolean {
    return this.isInEditMode || (this.selected && this.isOnlyOneSelected);
  }
}
