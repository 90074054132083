import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { PipeType } from '@dunefront/common/dto/pipe.dto';
import { ITableRow, ITableState } from '@dunefront/common/common/common-grid.interfaces';
import { LengthUnit, UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { IcdPortDataDto } from '@dunefront/common/dto/icd-port-data.dto';
import { DbDependentComponent } from '../../../../../common-modules/db-connection/db-dependent.component';
import { Store } from '@ngrx/store';
import { ScreenPipe } from '@dunefront/common/modules/pipes/lower-completion-pipes/pipes/screen-pipe';
import { IcdScreenPipe, isICDScreenRow } from '@dunefront/common/modules/pipes/lower-completion-pipes/pipes/icd-screen-pipe';
import { changeProp, ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';
import { IUpdateTableRowsProps, StoreCrudPropsFactory } from '@dunefront/common/common/common-store-crud.interfaces';

@Component({
  selector: 'app-screen-component',
  templateUrl: './screen-component.component.html',
  styleUrls: ['./screen-component.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScreenComponentComponent extends DbDependentComponent {
  @Input() public screenPipeRow: ITableRow<ScreenPipe> | undefined;
  @Input() public icdPortData: ITableState<IcdPortDataDto> | undefined;
  @Input() public diameterUnit: LengthUnit | undefined;

  @Output() public rowUpdated = new EventEmitter<IUpdateTableRowsProps<ScreenPipe>>();
  @Output() public icdPortDataRowUpdated = new EventEmitter<IUpdateTableRowsProps<IcdPortDataDto>>();

  public PipeType = PipeType;
  public UnitType = UnitSystem;

  constructor(store: Store, cdRef: ChangeDetectorRef) {
    super(store, cdRef);
  }

  public get icdScreenRow(): ITableRow<IcdScreenPipe> | undefined {
    return this.screenPipeRow && isICDScreenRow(this.screenPipeRow) ? this.screenPipeRow : undefined;
  }

  public onValueChange(event: ObjectChangeProp<ScreenPipe>): void {
    if (!this.screenPipeRow) {
      return;
    }
    const newRow: ITableRow<ScreenPipe> = {
      ...this.screenPipeRow,
      rowData: changeProp(this.screenPipeRow.rowData, event),
    };
    this.rowUpdated.emit(StoreCrudPropsFactory.updateTableRows([newRow], event));
  }

  protected updateRow(props: IUpdateTableRowsProps<ScreenPipe>): void {
    this.rowUpdated.emit(props);
  }
}
