import { Point } from 'chart.js';

export const getRectWithCenter = (center: Point, size: Size): Rect => ({
  x: center.x - size.width / 2,
  y: center.y - size.height / 2,
  width: size.width,
  height: size.height,
});

export const getRectWithTopLeftCorner = ({ x, y }: Point, { width, height }: Size): Rect => ({
  x,
  y,
  width,
  height,
});

export const getRectWithTopRightCorner = ({ x, y }: Point, { width, height }: Size): Rect => ({
  x: x - width,
  y,
  width,
  height,
});

export const getRectWithBottomLeftCorner = ({ x, y }: Point, { width, height }: Size): Rect => ({
  x: x,
  y: y - height,
  width,
  height,
});

export const getRectWithBottomRightCorner = ({ x, y }: Point, { width, height }: Size): Rect => ({
  x: x - width,
  y: y - height,
  width,
  height,
});

export const annotationTailPointDistance = (textBoxSize: Size): number => {
  return Math.max(Math.min(textBoxSize.width, textBoxSize.height) / 12, 4);
};

export const getPointsAngle = (centerPoint: Point, otherPoint: Point): number => {
  return -Math.atan2(otherPoint.y - centerPoint.y, otherPoint.x - centerPoint.x);
};

export const isPointInsideRect = (point: Point, rect: Rect): boolean => {
  return point.x >= rect.x && point.x <= rect.x + rect.width && point.y >= rect.y && point.y <= rect.y + rect.height;
};

export const isPointCloseToRect = (point: Point, rect: Rect, minDistance = 0): boolean => {
  return (
    point.x >= rect.x - minDistance &&
    point.x <= rect.x + rect.width + minDistance &&
    point.y >= rect.y - minDistance &&
    point.y <= rect.y + rect.height + minDistance
  );
};

export const areRectsClose = (rect1: Rect, rect2: Rect, minDistance: number): boolean => {
  const { topLeft, topRight, bottomLeft, bottomRight } = rectCorners(rect1);

  return (
    doRectsOverlap(rect1, rect2) ||
    isPointCloseToRect(topLeft, rect2, minDistance) ||
    isPointCloseToRect(topRight, rect2, minDistance) ||
    isPointCloseToRect(bottomLeft, rect2, minDistance) ||
    isPointCloseToRect(bottomRight, rect2, minDistance) ||
    isPointCloseToRect(rect2, rect1, minDistance) // make sure rect1 is not containing rect2
  );
};

export const diagonalLen = ({ width, height }: Size): number => {
  return Math.sqrt(width * width + height * height);
};

export const rectCenter = (rect: Rect): Point => {
  return {
    x: rect.x + rect.width / 2,
    y: rect.y + rect.height / 2,
  };
};

export const rectWithPadding = (rect: Rect, padding: number): Rect => {
  const center = rectCenter(rect);

  const width = Math.max(rect.width - padding * 2, 0);
  const height = Math.max(rect.height - padding * 2, 0);

  return {
    x: center.x - width / 2,
    y: center.y - height / 2,
    width,
    height,
  };
};

const sign = (p1: Point, p2: Point, p3: Point): number => (p1.x - p3.x) * (p2.y - p3.y) - (p2.x - p3.x) * (p1.y - p3.y);

export const isPointInsideTriangle = (point: Point, v1: Point, v2: Point, v3: Point): boolean => {
  const d1 = sign(point, v1, v2);
  const d2 = sign(point, v2, v3);
  const d3 = sign(point, v3, v1);

  const hasNeg = d1 < 0 || d2 < 0 || d3 < 0;
  const hasPos = d1 > 0 || d2 > 0 || d3 > 0;

  return !(hasNeg && hasPos);
};

export const pointToSegmentDistance = (point: Point, p1: Point, p2: Point): number => {
  const a = point.x - p1.x;
  const b = point.y - p1.y;
  const c = p2.x - p1.x;
  const d = p2.y - p1.y;

  let param = -1;

  const lenSq = c * c + d * d;
  if (lenSq != 0) {
    const dot = a * c + b * d;
    //in case of 0 length line
    param = dot / lenSq;
  }

  let xx, yy;

  if (param < 0) {
    xx = p1.x;
    yy = p1.y;
  } else if (param > 1) {
    xx = p2.x;
    yy = p2.y;
  } else {
    xx = p1.x + param * c;
    yy = p1.y + param * d;
  }

  const dx = point.x - xx;
  const dy = point.y - yy;
  return Math.sqrt(dx * dx + dy * dy);
};

// returns true if the line from (a,b)->(c,d) intersects with (p,q)->(r,s)
export const doSegmentsIntersect = ({ point1: ab, point2: cd }: Segment2D, { point1: pq, point2: rs }: Segment2D): boolean => {
  const { x: a, y: b } = ab;
  const { x: c, y: d } = cd;
  const { x: p, y: q } = pq;
  const { x: r, y: s } = rs;

  const det = (c - a) * (s - q) - (r - p) * (d - b);
  let gamma, lambda;
  if (det === 0) {
    return false;
  } else {
    lambda = ((s - q) * (r - a) + (p - r) * (s - b)) / det;
    gamma = ((b - d) * (r - a) + (c - a) * (s - b)) / det;
    return 0 < lambda && lambda < 1 && 0 < gamma && gamma < 1;
  }
};

/**
 * Returns value inner segment needs to be offset in order to fit outer.
 * If inner segment is longer, it returns offset that centers it.
 * @param inner
 * @param outer
 */
export const getOffsetToFitSegment = (inner: Segment1D, outer: Segment1D): number => {
  const innerEnd = inner.start + inner.len;
  const outerEnd = outer.start + outer.len;
  const innerCenter = inner.start + inner.len / 2;
  const outerCenter = outer.start + outer.len / 2;

  if (inner.len > outer.len) {
    return outerCenter - innerCenter;
  } else if (inner.start < outer.start) {
    return outer.start - inner.start;
  } else if (innerEnd > outerEnd) {
    return outerEnd - innerEnd;
  }

  return 0;
};

export const pointsDistance = (pointA: Point, pointB: Point): number => {
  const xDiff = Math.abs(pointA.x - pointB.x);
  const yDiff = Math.abs(pointA.y - pointB.y);
  return Math.sqrt(xDiff * xDiff + yDiff * yDiff);
};

export const segmentCenter = (p1: Point, p2: Point): Point => ({
  x: (p1.x + p2.x) / 2,
  y: (p1.y + p2.y) / 2,
});

export const segmentLength = ({ point1, point2 }: Segment2D): number => {
  return pointsDistance(point1, point2);
};

export const getTailTrianglePoints = (anchor: Point, box: Rect): [Point, Point] => {
  const textBoxCenter = rectCenter(box);
  const angleRadians = getPointsAngle(anchor, textBoxCenter);
  const dist = annotationTailPointDistance(box);

  return [
    {
      x: textBoxCenter.x + Math.cos(angleRadians + Math.PI / 2) * dist,
      y: textBoxCenter.y - Math.sin(angleRadians + Math.PI / 2) * dist,
    },
    {
      x: textBoxCenter.x + Math.cos(angleRadians - Math.PI / 2) * dist,
      y: textBoxCenter.y - Math.sin(angleRadians - Math.PI / 2) * dist,
    },
  ];
};

export const rotatePoint = ({ x, y }: Point, angleRadians: number): Point => {
  const sin = Math.sin(angleRadians);
  const cos = Math.cos(angleRadians);

  return {
    x: x * cos - y * sin,
    y: x * sin + y * cos,
  };
};

export const offsetPointWithPolarCoordinates = ({ x, y }: Point, angleRadians: number, length: number): Point => {
  const rotatedPointOffset = rotatePoint({ x: length, y: 0 }, angleRadians);
  return { x: x + rotatedPointOffset.x, y: y + rotatedPointOffset.y };
};

export const rotateRect = (rect: Rect, angleRadians: number): RectCorners => {
  const center = rectCenter(rect);

  const rectWithResetCenter = offsetRect(rect, { x: -center.x, y: -center.y });

  const { topLeft, topRight, bottomLeft, bottomRight } = rectCorners(rectWithResetCenter);

  return {
    topLeft: offsetPoint(rotatePoint(topLeft, angleRadians), center),
    topRight: offsetPoint(rotatePoint(topRight, angleRadians), center),
    bottomLeft: offsetPoint(rotatePoint(bottomLeft, angleRadians), center),
    bottomRight: offsetPoint(rotatePoint(bottomRight, angleRadians), center),
  };
};

export const getRotatedRectBoundary = ({ topLeft, topRight, bottomLeft, bottomRight }: RectCorners): Rect => {
  const allPoints = [topLeft, topRight, bottomLeft, bottomRight];

  const allX = allPoints.map((point) => point.x);
  const allY = allPoints.map((point) => point.y);

  const x = Math.min(...allX);
  const y = Math.min(...allY);
  const width = Math.max(...allX) - x;
  const height = Math.max(...allY) - y;

  return { x, y, width, height };
};

export const rectCorners = ({ x, y, width, height }: Rect): RectCorners => ({
  topLeft: { x, y },
  topRight: { x: x + width, y },
  bottomLeft: { x, y: y + height },
  bottomRight: { x: x + width, y: y + height },
});

export const offsetRect = (rect: Rect, offset: Point): Rect => ({
  ...rect,
  x: rect.x + offset.x,
  y: rect.y + offset.y,
});

export const offsetPoint = (point: Point, offset: Point): Point => ({
  x: point.x + offset.x,
  y: point.y + offset.y,
});

export const updateRectPositionToKeepDistanceToPoint = (rect: Rect, point: Point, distance: number): Rect => {
  const { topLeft, topRight, bottomLeft } = rectCorners(rect);

  const isOutsideOnLeft = point.x + distance <= topLeft.x;
  const isOutsideOnRight = point.x - distance >= topRight.x;

  const isOutsideOnTop = point.y + distance <= topLeft.y;
  const isOutsideOnBottom = point.y - distance >= bottomLeft.y;

  let offsetX = 0;
  let offsetY = 0;

  if (isOutsideOnLeft) {
    offsetX = point.x + distance - topLeft.x;
  } else if (isOutsideOnRight) {
    offsetX = point.x - distance - topRight.x;
  }

  if (isOutsideOnTop) {
    offsetY = point.y + distance - topLeft.y;
  } else if (isOutsideOnBottom) {
    offsetY = point.y - distance - bottomLeft.y;
  }

  return offsetRect(rect, { x: offsetX, y: offsetY });
};

export const doRectsOverlap = (rect1: Rect, rect2: Rect): boolean => {
  // one rect is on right side of other
  if (rect1.x > rect2.x + rect2.width || rect2.x > rect1.x + rect1.width) {
    return false;
  }

  // one rect is below other
  if (rect1.y > rect2.y + rect2.height || rect2.y > rect1.y + rect1.height) {
    return false;
  }

  return true;
};

export interface Size {
  width: number;
  height: number;
}

export interface Segment1D {
  start: number;
  len: number;
}

export interface Segment2D {
  point1: Point;
  point2: Point;
}

export interface Rect extends Size, Point {}

export interface RectCorners {
  topLeft: Point;
  topRight: Point;
  bottomLeft: Point;
  bottomRight: Point;
}

export const rectFitsInside = (innerRect: Rect, otherRect: Rect): boolean => {
  const innerRight = innerRect.x + innerRect.width;
  const innerBottom = innerRect.y + innerRect.height;
  const otherRight = otherRect.x + otherRect.width;
  const otherBottom = otherRect.y + otherRect.height;

  return innerRect.x >= otherRect.x && innerRect.y >= otherRect.y && innerRight <= otherRight && innerBottom <= otherBottom;
};

export const calcFittingRect = (size: Size, container: Rect): Rect => {
  const aspectRatio = size.width / size.height;
  const containerAspectRatio = container.width / container.height;

  // Determine the dimensions of RectC
  let width, height;

  if (aspectRatio > containerAspectRatio) {
    // rect is wider than container
    width = container.width;
    height = width / aspectRatio;
  } else {
    // rect is taller than container
    height = container.height;
    width = height * aspectRatio;
  }

  // Calculate the position of RectC to center it inside RectB
  const x = container.x + (container.width - width) / 2;
  const y = container.y + (container.height - height) / 2;

  return { x, y, width, height };
};
