import { UnitConverter } from '../../converter.interfaces';
import { VelocityUnit } from '../../../dto/unit-system.dto';

export class VelocityConverter extends UnitConverter {
  public static symbols: Array<string> = ['', 'm/s', 'm/min', 'ft/min', 'ft/s'];

  public static unit = VelocityUnit;

  public static fromSi(value: number, unit: VelocityUnit): number {
    switch (unit) {
      case VelocityUnit.Meter_per_second: {
        return value;
      }
      case VelocityUnit.Meter_per_minute: {
        return value * 60;
      }
      case VelocityUnit.Foot_per_minute: {
        return value * 196.850394;
      }
      case VelocityUnit.Foot_per_second: {
        return value * 3.2808399;
      }
      default: {
        throw new TypeError('Unknown Velocity Unit:' + unit);
      }
    }
  }

  public static toSi(value: number, unit: VelocityUnit): number {
    switch (unit) {
      case VelocityUnit.Meter_per_second: {
        return value;
      }
      case VelocityUnit.Meter_per_minute: {
        return value / 60;
      }
      case VelocityUnit.Foot_per_minute: {
        return value / 196.850394;
      }
      case VelocityUnit.Foot_per_second: {
        return value / 3.2808399;
      }
      default: {
        throw new TypeError('Unknown Velocity Unit:' + unit);
      }
    }
  }

  public static getSymbol(unit: VelocityUnit): string {
    return this.symbols[unit];
  }
}
