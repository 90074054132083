import { IChartDataDtoColumn } from '@dunefront/common/modules/reporting/dto/chart-data.dto';
import { getColumnKey } from './chart-misc-helpers';
import { Observable, ReplaySubject } from 'rxjs';

export interface VisibleColumnsChangePayload {
  hiddenColumnKeys: string[];
  eventSource: any;
}

export class ChartSeriesVisibilityManager {
  private hiddenColumnKeys = new Set<string>();
  private replaySubject = new ReplaySubject<VisibleColumnsChangePayload>();

  public onColumnsVisibilityChanged(columns: IChartDataDtoColumn[], visible: boolean, eventSource: any): void {
    let notify = false;
    for (const column of columns) {
      const columnKey = getColumnKey(column);

      // shown
      if (visible && this.hiddenColumnKeys.has(columnKey)) {
        this.hiddenColumnKeys.delete(columnKey);
        notify = true;
      }
      // hidden
      else if (!visible && !this.hiddenColumnKeys.has(columnKey)) {
        this.hiddenColumnKeys.add(columnKey);
        notify = true;
      }
    }

    if (notify) {
      this.notifyChange(eventSource);
    }
  }

  public reset(hiddenColumns: IChartDataDtoColumn[], eventSource: any): void {
    this.hiddenColumnKeys.clear();

    for (const column of hiddenColumns) {
      const columnKey = getColumnKey(column);
      this.hiddenColumnKeys.add(columnKey);
    }

    this.notifyChange(eventSource);
  }

  public get onVisibleColumnsChanged(): Observable<VisibleColumnsChangePayload> {
    return this.replaySubject.asObservable();
  }

  public getHiddenColumnKeys(): string[] {
    return Array.from(this.hiddenColumnKeys.values());
  }

  private notifyChange(eventSource: any): void {
    this.replaySubject.next({ hiddenColumnKeys: Array.from(this.hiddenColumnKeys.values()), eventSource });
  }
}
