export interface RangeDto {
  Id: number;
  ScenarioId: 0;
  Name: string;
  RangeStart: number;
  RangeEnd: number;
  SortOrder: number;
  Labels: string;
}

export class RangeConstants {
  public static readonly EntireRangeId = 0;
  public static readonly EmptyRangeId = -1; // for simulate
  public static readonly NewRangeId = -2;
}

export interface ISimpleRange {
  RangeStart: number;
  RangeEnd: number;
}
