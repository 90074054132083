import { ChangeDetectionStrategy, Component, Input, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RadioButtonModule } from 'primeng/radiobutton';
import { IRadioItem } from '@dunefront/common/common/radio.helpers';
import { BaseFormComponent } from '../base-form-component';
import { TooltipModule } from 'primeng/tooltip';
import { DropdownModule } from 'primeng/dropdown';
import { MenuModule } from 'primeng/menu';

@Component({
  selector: 'app-radio-group',
  templateUrl: './radio-group.component.html',
  styleUrls: ['./radio-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioGroupComponent<T, ITempType extends T[keyof T]> extends BaseFormComponent<T, ITempType> {
  @Input() public items: IRadioItem<ITempType>[] = [];
  @Input() public layout: 'horizontal' | 'vertical' = 'horizontal';
  @Input() public breakItems: number[] = [];
  @Input() public cssOverride = '';

  public isBreak(i: number): boolean {
    return this.breakItems.length ? this.breakItems.includes(i) : false;
  }

  public isRadioItemDisabled(item: IRadioItem<ITempType>): boolean {
    return item.disabled || this.isDisabled;
  }

  public getDefaultTooltip(): string {
    if (this.sourceDefaults == null || this.key == null) {
      return '';
    }
    const key = this.key;
    const defaultValue = this.items.find((item) => item.value === (this.sourceDefaults as T)[key])?.text;
    return defaultValue ? 'Default value: ' + defaultValue : '';
  }
}

@NgModule({
  imports: [CommonModule, FormsModule, RadioButtonModule, TooltipModule, DropdownModule, MenuModule],
  declarations: [RadioGroupComponent],
  exports: [RadioGroupComponent],
})
export class RadioGroupModule {}

export interface IRadioButtonValue {
  value: boolean | string | number;
}
