import { RangeDto } from '../../dto/range.dto';
import { DeleteRowsWsAction, InsertRowsWsAction, UpdateRowsWsAction, WsAction } from '../../ws.action';
import { VerticalShiftDto } from '../../dto/trend-analysis/vertical-shift.dto';
import { WsActionPropsFactory } from '../../common/ws-action/ws-action-props.factory';
import { IDeleteRowsProps, IUpdateRowsProps } from '../../common/common-store-crud.interfaces';
import { ScenarioRangePropertiesDto } from '../../dto/scenario-range-properties.dto';
import { ScenarioConstants } from '../scenario/scenario.dto';

export const RangeModuleName = 'RangeModule';

export enum RangeModuleActionTypes {
  RangeInsertRows = '[RangeModule] InsertRows',
  RangeUpdateRow = '[RangeModule] UpdateRow',
  RangeDeleteRows = '[RangeModule] DeleteRows',
  RangeDeleteRangeData = '[RangeModule] DeleteRangeData',
  ScenarioRangePropertiesUpdateRow = '[RangeModule] ScenarioRangePropertiesUpdateRow',
  ScenarioRangePropertiesInsertRows = '[RangeModule] ScenarioRangePropertiesInsertRows',
  VerticalShiftInsertRows = '[RangeModule] [VerticalShift] InsertRows',
  VerticalShiftUpdateRow = '[RangeModule] [VerticalShift] UpdateRow',
  VerticalShiftDeleteRows = '[RangeModule] [VerticalShift] DeleteRows',
}

export interface LoadRangesActionResponse {
  ranges: RangeDto[];
  scenarioRangeProperties: ScenarioRangePropertiesDto[];
  verticalShifts: VerticalShiftDto[];
}

export class RangeInsertRowsAction extends InsertRowsWsAction<RangeDto> {
  public override readonly type = RangeModuleActionTypes.RangeInsertRows;

  constructor(action: IUpdateRowsProps<RangeDto>, scenarioId: number) {
    super(WsActionPropsFactory.insert(action.rows, scenarioId, action.shouldResetResults, 'add'));
  }
}

export class RangeUpdateRowAction extends UpdateRowsWsAction<RangeDto> {
  public override readonly type = RangeModuleActionTypes.RangeUpdateRow;

  constructor(action: IUpdateRowsProps<RangeDto>, currentScenarioId: number) {
    super({ ...WsActionPropsFactory.update(action.rows, action.shouldResetResults, action.colIds), scenarioId: currentScenarioId });
  }
}

export class RangeDeleteRowsAction extends DeleteRowsWsAction {
  public override readonly type = RangeModuleActionTypes.RangeDeleteRows;
  constructor(action: IDeleteRowsProps, currentScenarioId: number) {
    super({ ...action, scenarioId: currentScenarioId });
  }
}

export class RangeDeleteRangeDataAction extends WsAction {
  public override readonly type = RangeModuleActionTypes.RangeDeleteRangeData;
  public readonly scenarioId: number;
  constructor(public readonly rangeId: number) {
    super();
    this.scenarioId = ScenarioConstants.AllScenarioId;
  }
}

export interface DeleteRangeDataActionResponse {
  ranges: RangeDto[];
  removedFileIds: number[];
}

export class VerticalShiftInsertRowsAction extends InsertRowsWsAction<VerticalShiftDto> {
  public override readonly type = RangeModuleActionTypes.VerticalShiftInsertRows;
}

export class VerticalShiftUpdateRowAction extends UpdateRowsWsAction<VerticalShiftDto> {
  public override readonly type = RangeModuleActionTypes.VerticalShiftUpdateRow;
}

export class VerticalShiftDeleteRowsAction extends DeleteRowsWsAction {
  public override readonly type = RangeModuleActionTypes.VerticalShiftDeleteRows;
}

export class ScenarioRangePropertiesUpdateRowAction extends UpdateRowsWsAction<ScenarioRangePropertiesDto> {
  public override readonly type = RangeModuleActionTypes.ScenarioRangePropertiesUpdateRow;

  constructor(action: IUpdateRowsProps<ScenarioRangePropertiesDto>) {
    super(WsActionPropsFactory.update(action.rows, action.shouldResetResults, action.colIds, undefined, action.isUndoEnabled));
  }
}

export type RangeModuleActions =
  | RangeInsertRowsAction
  | RangeUpdateRowAction
  | RangeDeleteRowsAction
  | RangeDeleteRangeDataAction
  | VerticalShiftInsertRowsAction
  | VerticalShiftUpdateRowAction
  | VerticalShiftDeleteRowsAction
  | ScenarioRangePropertiesUpdateRowAction;
