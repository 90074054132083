import { Directive, ElementRef, EventEmitter, HostListener, NgModule, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

@Directive({
  selector: '[appClickOutside]',
})
export class ClickOutsideDirective {
  @Output() public clickOutside = new EventEmitter<void>();
  @Output() public rightClickOutside = new EventEmitter<void>();

  constructor(private elementRef: ElementRef) {}

  @HostListener('document:click', ['$event.target'])
  public onClick(target: HTMLElement): void {
    if (this.isClickedOutside(target)) {
      this.clickOutside.emit();
    }
  }

  @HostListener('window:contextmenu', ['$event.target'])
  public onContextMenu(target: HTMLElement): void {
    if (this.isClickedOutside(target)) {
      this.rightClickOutside.emit();
    }
  }

  private isClickedOutside(target: HTMLElement): boolean {
    return !this.elementRef.nativeElement.contains(target);
  }
}

@NgModule({
  imports: [CommonModule],
  declarations: [ClickOutsideDirective],
  exports: [ClickOutsideDirective],
})
export class ClickOutsideModule {}
