import { BasePptSectionGenerator, PptContext } from '../base-ppt-section-generator';
import { CommonReportDataHelpers } from '../../common/common-report-data-helpers';
import { ReportInfoDto } from '@dunefront/common/dto/report-info.dto';
import { PptDocumentTableGeneratorHelper } from '../ppt-document-table-generator.helper';
import { IUnitSystemDto } from '@dunefront/common/dto/unit-system.dto';
import { firstValueFrom } from 'rxjs';
import { selectCurrentUnitSystem } from '../../../../../../+store/units/units.selectors';

export class ReportInformationPptGenerator extends BasePptSectionGenerator {
  constructor(protected override context: PptContext & { reportInfo: ReportInfoDto; reportDate: string }) {
    super(context);
  }

  public async generate(): Promise<void> {
    const title = 'Report Information';
    const currentUnitSystem: IUnitSystemDto = await firstValueFrom(this.store.select(selectCurrentUnitSystem));
    const bulletPoints = CommonReportDataHelpers.createReportInformationBulletPoints(this.context.reportInfo, this.context.reportDate);
    const tableData = PptDocumentTableGeneratorHelper.bulletPointsToKeyValueTableData(bulletPoints);
    const tableRows = PptDocumentTableGeneratorHelper.createGenericKeyValueTableRowsWithTableData(tableData, currentUnitSystem);

    this.addKeyValueTableSlide({ title, tableRows });
  }
}
