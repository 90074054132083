import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { DbDependentComponent } from '../../../../../common-modules/db-connection/db-dependent.component';
import { ActivatedRoute } from '@angular/router';
import * as reportingActions from '../../../../../+store/reporting/reporting.actions';
import { ReportingCalculationJobStatus } from '../../../../../+store/reporting/reporting.actions';
import {
  getChartDtos,
  getCurrentCalculationStatus,
  ICurrentCalculationStatusDetails,
} from '../../../../../+store/reporting/reporting.selectors';
import {
  ChartTimeVolMode,
  GetChartDataParams,
  GetChartDataRequestType,
} from '@dunefront/common/modules/reporting/reporting-module.actions';
import { ChartDataSourceType, ChartDto } from '@dunefront/common/modules/reporting/dto/chart.dto';
import { RangeConstants } from '@dunefront/common/dto/range.dto';
import { ChartControllerConfig, ChartControllerFetchDataPayload } from '../../../../../common-modules/chart/chart-controller.component';
import { getChartId } from '@dunefront/common/modules/reporting/dto/chart-data.dto';
import { getDepthSimulationChartState } from '../../../../../+store/reporting/reporting-get-depth-chart-state.selector';
import { firstValueFrom } from 'rxjs';
import { getAnimationChartCanFetchData } from '../animation-charts.selectors';
import { DrawingLayoutType } from '@dunefront/common/modules/settings/dto/settingsDto';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { DrawableContentProviderComponent } from '../../../../../shared/services/drawable-registry.service';

@Component({
  selector: 'app-animation-depth-based-chart',
  templateUrl: './animation-depth-based-chart.component.html',
  styleUrls: ['./animation-depth-based-chart.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnimationDepthBasedChartComponent extends DbDependentComponent implements OnInit, OnDestroy, DrawableContentProviderComponent {
  @Input() public drawableProviderId = ''; // used for copy data - value only seen if multiple charts visible
  @Input() public layoutType: DrawingLayoutType = DrawingLayoutType.Survey;
  @Input() public allowRecording = false;

  public readonly config: ChartControllerConfig = {
    loadData: (payload) => this.fetchData(payload),
    resetAxisLimitsOnRangeChange: true,
  };

  public chartState$ = this.store.select(getDepthSimulationChartState);

  public currentCalculationStatus?: ICurrentCalculationStatusDetails;
  private chartTimeVolMode!: ChartTimeVolMode;
  private chartDtos: ChartDto[] = [];

  public get overlayText(): string {
    if (
      !this.currentCalculationStatus ||
      this.currentCalculationStatus.calculationJobStatus === ReportingCalculationJobStatus.chartAvailable
    ) {
      return '';
    }
    return this.currentCalculationStatus.calculationJobMessage ?? '';
  }

  public get isRotated(): boolean {
    return this.layoutType !== DrawingLayoutType.Horizontal;
  }

  constructor(
    store: Store,
    cdRef: ChangeDetectorRef,
    protected route: ActivatedRoute,
  ) {
    super(store, cdRef);
  }

  public override ngOnInit(): void {
    super.ngOnInit();

    this.subscription.add(
      this.store
        .select(getAnimationChartCanFetchData)
        .pipe(
          distinctUntilChanged(),
          filter((ok) => ok),
        )
        .subscribe(() => this.fetchInitialChartData()),
    );

    this.subscription.add(this.store.select(getChartDtos).subscribe((chartDtos) => (this.chartDtos = chartDtos)));

    this.subscription.add(
      this.store.select(getCurrentCalculationStatus).subscribe((currentCalculationStatus) => {
        this.currentCalculationStatus = currentCalculationStatus;
        this.cdRef.markForCheck();
      }),
    );
  }

  public get chartId(): number {
    return getChartId(this.chartDtos, ChartDataSourceType.ChartSourceResultsDepthBased);
  }

  private fetchInitialChartData(): void {
    this.fetchData({ src: 'fetchInitialChartData', requestType: GetChartDataRequestType.Initial }).then();
  }

  private async fetchData(payload: ChartControllerFetchDataPayload): Promise<void> {
    const canFetchData = await firstValueFrom(this.store.select(getAnimationChartCanFetchData));

    // this component might get destroyed while awaiting firstValueFrom(this.store.select(getAnimationChartCanFetchData))
    // in doesn't make sense to fetch data, it can be detected by checking: this.currentRangeId$ == null
    if (!canFetchData || this.currentAppModuleType == null || this.currentRangeId$ == null) {
      return;
    }

    const { src, requestType, argumentStart, argumentEnd, isPrimaryArgument, isPrimaryArgumentRelative } = payload;

    const getChartDataParams: GetChartDataParams = {
      chartId: this.chartId,
      dataSourceType: ChartDataSourceType.ChartSourceResultsDepthBased,
      timeVolMode: this.chartTimeVolMode,
      moduleType: this.currentAppModuleType,
      rangeId: RangeConstants.EmptyRangeId,
      argumentStart,
      argumentEnd,
      isPrimaryArgument,
      isPrimaryArgumentRelative,
      requestType,
      isOptimizeEvaluationChart: false,
    };

    this.store.dispatch(
      reportingActions.getTimeVolChartData({
        src: 'animation-depth-based-chart.component - fetchData ' + src,
        getChartDataParams,
      }),
    );
  }

  public override ngOnDestroy(): void {
    this.store.dispatch(
      reportingActions.clearReportingChartData({ chartDataSourceType: ChartDataSourceType.ChartSourceResultsDepthBased }),
    );
    super.ngOnDestroy();
  }
}
