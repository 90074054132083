import { EquationModuleStateFeatureName } from './equation.reducer';
import { ITableState } from '@dunefront/common/common/common-grid.interfaces';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EquationPreviewRow, IEquationVariable } from '@dunefront/common/modules/equation/equation-variable.dto';
import { IGridColumnConfig } from '../../shared/components/grid/grid.interfaces';
import {
  getAllStorageColumnsArray,
  getStorageColumns,
  getStorageFilesArray,
  getStorageFilesArrayWithRangeHash,
  getValidatedStorageFilesWithColumns,
} from '../data-storage/data-storage.selectors';
import { EquationSelectorsHelper } from './equation.selectors.helper';
import { getGaugeDataSelectedFileId } from '../gauge-data/gauge-data.selectors';
import { toSelectItem } from '@dunefront/common/common/select.helpers';
import { EquationModuleState, EquationPreviewRowWithResult } from '@dunefront/common/modules/equation/equation-module.state';
import { EquationStateValidation } from '@dunefront/common/modules/equation/equation-module.validation';
import { EquationHashHelper } from '@dunefront/common/modules/equation/equation-hash.helper';
import { getRanges } from '../range/range.selectors';

export const selectEquationState = createFeatureSelector<EquationModuleState>(EquationModuleStateFeatureName);
export const selectEquationPreview = createSelector(selectEquationState, (state) =>
  state.preview.length > 0 ? state.preview : [{ Id: 0 }],
);
export const getEquationFilesSelectItems = createSelector(getStorageFilesArray, getGaugeDataSelectedFileId, (files, currentFileId) =>
  files.filter((file) => file.Id !== currentFileId).map((file) => toSelectItem(file.Id, file.FileName)),
);

export const selectEquationColumn = createSelector(selectEquationState, (state) => state.equationColumn);
export const selectEquation = createSelector(selectEquationColumn, (state) => state.Equation);
export const selectEquationVariables = createSelector(selectEquation, (equation) => equation.variables);
export const selectCorrectEquationVariables = createSelector(selectEquationVariables, (variables) =>
  variables.filter((v) => v.FileId >= 0 && v.ColId >= 0 && v.Unit >= 0),
);

export const selectEquationFormula = createSelector(selectEquation, (equation) => equation.equationFormula);

export const selectValidatedDataSourceVariables = createSelector(
  selectEquationColumn,
  getValidatedStorageFilesWithColumns,
  (equationColumn, columns): ITableState<IEquationVariable> => {
    return EquationStateValidation.createValidatedDataSourceVariables(equationColumn.Equation.variables, columns, equationColumn.FileId);
  },
);

export const selectEquationValidityState = createSelector(selectEquationColumn, selectValidatedDataSourceVariables, (column, variables) => {
  return EquationStateValidation.validateEquationState(column, variables);
});

export const selectDataSourcePreview = createSelector(
  selectEquationPreview,
  selectEquation,
  getAllStorageColumnsArray,
  selectValidatedDataSourceVariables,
  (preview, equation, allColumns, variables): ITableState<EquationPreviewRowWithResult> => {
    return EquationSelectorsHelper.createDataSourcePreview(preview, equation, allColumns, variables.isValid);
  },
);

export const selectPreviewDataSourceColumns = createSelector(
  selectDataSourcePreview,
  selectValidatedDataSourceVariables,
  getStorageColumns,
  selectEquationColumn,
  (preview, variables, columns, equationColumn): IGridColumnConfig<EquationPreviewRow>[] => {
    if (preview.rows.length === 0) {
      return [];
    }
    return EquationSelectorsHelper.createPreviewDataSourceColumns(variables, columns, equationColumn);
  },
);

export const selectEquationSourceHash = createSelector(
  getStorageFilesArrayWithRangeHash,
  getAllStorageColumnsArray,
  getRanges,
  (files, columns) => EquationHashHelper.getEquationSourceFileHashes(files, columns),
);
