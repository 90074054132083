import { Component } from '@angular/core';
import { BaseGaugeCarrierPropertiesComponent } from '../base.gauge-carrier-properies.component';
import { EccentricGaugeCarrierPipe } from '@dunefront/common/modules/pipes/running-string-pipes/pipes/eccentric-gauge-carrier-pipe';

@Component({
  selector: 'app-eccentric-gauge-carrier-properties',
  templateUrl: './eccentric-gauge-carrier-properties.component.html',
  styleUrls: ['./eccentric-gauge-carrier-properties.component.css'],
})
export class EccentricGaugeCarrierPropertiesComponent extends BaseGaugeCarrierPropertiesComponent<EccentricGaugeCarrierPipe> {}
