import { LeakoffCoefficientCalculatorDto } from './leakoff-coefficient-calculator.dto';
import { IModuleState, IValidatedModuleState } from '../../../common/common-state.interfaces';

export type LeakoffCoefficientCalculatorModuleState = LeakoffCoefficientCalculatorDto & IModuleState;

export type ValidatedLeakoffCoefficientCalculatorModuleState = LeakoffCoefficientCalculatorModuleState &
  IValidatedModuleState<LeakoffCoefficientCalculatorModuleState>;

export const LeakoffCoefficientCalculatorInitialState: LeakoffCoefficientCalculatorModuleState = {
  isLoaded: false,
  CombinedLeakoffCoefficient: 0,
  CompressibilityControlledLeakoff: 0,
  InjectedFluidViscosity: 0,
  PermeabilityToInjectedFluid: 0,
  PermeabilityToReservoirFluid: 0,
  PressureDifferential: 0,
  ReservoirCompressibility: 0,
  ReservoirFluidViscosity: 0,
  ReservoirPorosity: 0,
  ScenarioId: -1,
  ViscosityControlledLeakoff: 0,
};
