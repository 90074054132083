import { DictionaryWithArray, IDictionaryWithArray, noErrors } from '../../../../common/state.helpers';
import { PSD } from './psd';
import { ITableState } from '../../../../common/common-grid.interfaces';
import { PsdReading } from '../psd-readings/psd-reading';
import { IUnitSystemDto, LengthUnit, UnitSystem } from '../../../../dto/unit-system.dto';
import { ConvertUnitPipe } from '../../../units/convert-unit.pipe/convert-unit.pipe';
import { IError } from '../../../../common/common-state.interfaces';

export class PSDValidation {
  public static ValidatePSD(
    psdDict: IDictionaryWithArray<PSD>,
    psdReadings: IDictionaryWithArray<ITableState<PsdReading>>,
  ): IDictionaryWithArray<PSD> {
    let newPsds = { ...psdDict };
    for (const psdId of psdDict.ids) {
      const psd = DictionaryWithArray.getCopy(psdDict, psdId);
      if (!psd) {
        continue;
      }

      const psdArray = DictionaryWithArray.getArray(psdDict);
      const error: IError<PSD> = {
        Depth: this.validateDepth(psdArray, psd.Depth),
        Description: this.validateDescription(psdArray, psd.Description),
      };

      psd.error = error;
      psd.isValid = noErrors(error) && (psdReadings.dict[psdId]?.isValid ?? true);
      newPsds = DictionaryWithArray.upsertById(newPsds, psd, psdId);
    }
    return newPsds;
  }

  private static validateDepth(psdArray: PSD[], depth: number): string {
    if (depth <= 0) {
      return 'Depth must be greater than 0';
    }
    if (psdArray.filter((psd) => psd.Depth === depth).length > 1) {
      return 'Depth must be unique';
    }
    return '';
  }

  private static validateDescription(psdArray: PSD[], description: string): string {
    if (description.length === 0) {
      return "Description can't be empty";
    }
    if (psdArray.filter((psd) => psd.Description === description).length > 1) {
      return 'The description already exists - please use a different one';
    }
    return '';
  }

  public static getPercentFinesLabel(currentUnitSystem: IUnitSystemDto | undefined, convertUnitPipe: ConvertUnitPipe): string {
    if (!currentUnitSystem) {
      return '';
    }
    const encodedValueAndSymbol = ConvertUnitPipe.encodeValueAndSymbol(UnitSystem.Screen_Opening, 45e-6);

    return `< ${convertUnitPipe.transform(
      encodedValueAndSymbol.encodedValue,
      currentUnitSystem,
      currentUnitSystem.Sieve_Opening === LengthUnit.Micron ? 0 : 3,
    )}`;
  }

  public static getProducedSandMassLabel(
    currentUnitSystem: IUnitSystemDto,
    convertUnitPipe: ConvertUnitPipe,
    screenSlotSize: number,
  ): string {
    const encodedValueAndSymbol = ConvertUnitPipe.encodeValueAndSymbol(UnitSystem.Screen_Opening, screenSlotSize);

    return `< ${convertUnitPipe.transform(
      encodedValueAndSymbol.encodedValue,
      currentUnitSystem,
      currentUnitSystem.Sieve_Opening === LengthUnit.Micron ? 0 : 3,
    )}`;
  }
}
