import { Rheology } from './rheology';
import { RheologyDto } from '../../dto/rheology/rheology.dto';
import { createTableRow, createTableState, GridRowType, ITableRow, ITableState } from '../../../../common/common-grid.interfaces';
import { Dict, IDictionaryWithArray } from '../../../../common/state.helpers';
import { groupBy } from 'lodash';
import { RheometerReading } from '../rheometer-reading/rheometer-reading';
import { Rheometer } from '../rheometer/rheometer';
import { RheologyCalculations } from '../../dto/rheology/rheology.calculations';
import { Fluid } from '../fluid';
import { Validation } from '../../../../common/validation';

export class RheologyFactory {
  public static createRheologyFromRheometer(
    fluid: Fluid,
    rheometer: Rheometer,
    rheometerReadings: RheometerReading[],
    sortOrder: number,
  ): Rheology {
    const sortedRheometerReadings = [...rheometerReadings.filter((rr) => rr.RheometerId === rheometer.Id)].sort(
      (r1, r2) => r1.RPM - r2.RPM,
    );
    const rheologyProps = RheologyCalculations.CalculateRheology(rheometer, sortedRheometerReadings, fluid.RheometerTestType);
    return {
      ...rheologyProps,
      Id: -1,
      ScenarioId: fluid.ScenarioId,
      FluidId: fluid.Id,
      Temperature: rheometer.Temperature,
      YieldStress: 0,
      Viscosity: 0,
      Log10KPrime: 0,
      SortOrder: sortOrder,
    };
  }

  public static create(rheologyDtos: RheologyDto[], fluidIds: string[], scenarioId: number): IDictionaryWithArray<ITableState<Rheology>> {
    const rheologies = [...rheologyDtos];

    const rheologiesGroupedByFluidId = groupBy(rheologies, (data) => data.FluidId);

    // make it reusable in DictionaryWithArray...
    const dict: Dict<ITableState<Rheology>> = {};
    fluidIds.map((fluidId) => {
      const rh = rheologiesGroupedByFluidId[fluidId] ?? [];
      dict[+fluidId] = this.createTableStateForFluidId(rh, scenarioId, +fluidId);
    });
    return { ids: fluidIds.map((id) => id), dict };
  }

  public static createTableStateForFluidId(fluidRheologies: RheologyDto[], scenarioId: number, fluidId: number): ITableState<Rheology> {
    const tableRows = [
      ...fluidRheologies.map((dto, index) => this.createRheologyRowFromDto(dto, index)),
      /// this is not nice :(
      this.createRheologyEmptyRow(scenarioId, fluidId, fluidRheologies.length, false, 'insert-row'),
    ];
    return createTableState(tableRows);
  }

  public static createRheologyRowFromDto(
    rheologyDto: RheologyDto,
    index: number,
    isEditingDisabled = false,
    rowType: GridRowType = 'data',
  ): ITableRow<Rheology> {
    return createTableRow<Rheology>(this.createRheology(rheologyDto), rowType, index, isEditingDisabled);
  }

  public static createRheology(rheologyDto: RheologyDto): Rheology {
    return { ...rheologyDto, Log10KPrime: 0 };
  }

  public static createEmpty(scenarioId: number, fluidId: number): Rheology {
    return {
      Id: -1,
      FluidId: fluidId,
      KPrime: 0,
      NPrime: 0,
      Temperature: 0,
      YieldStress: 0,
      Viscosity: 0,
      SortOrder: -1,
      ScenarioId: scenarioId,
      RSquared: 0,
      Log10KPrime: 0,
    };
  }

  public static createRheologyEmptyRow(
    scenarioId: number,
    fluidId: number,
    rowIndex: number,
    isEditingDisabled: boolean,
    rowType: GridRowType,
  ): ITableRow<Rheology> {
    return createTableRow(this.createEmpty(scenarioId, fluidId), rowType, rowIndex, isEditingDisabled);
  }

  public static createRowFromPaste(
    temperature: number,
    nPrime: number,
    kPrime: number,
    viscosity: number,
    scenarioId: number,
    fluidId: number,
  ): ITableRow<Rheology> {
    return this.createRheologyRowFromDto(
      {
        Id: -1,
        FluidId: fluidId,
        KPrime: kPrime,
        NPrime: nPrime,
        Temperature: temperature,
        YieldStress: 0,
        Viscosity: viscosity,
        SortOrder: 0,
        ScenarioId: scenarioId,
        RSquared: 0,
      },
      0,
    );
  }

  public static rheologyToDto(rheology: Rheology): RheologyDto {
    return {
      Id: rheology.Id,
      FluidId: rheology.FluidId,
      KPrime: rheology.KPrime,
      NPrime: rheology.NPrime,
      Temperature: rheology.Temperature,
      YieldStress: rheology.YieldStress,
      Viscosity: Validation.UICheckNumberValidity(rheology.Viscosity ?? 0),
      SortOrder: rheology.SortOrder,
      ScenarioId: rheology.ScenarioId,
      RSquared: 0,
    };
  }
}
