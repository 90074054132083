import { createReducer, on } from '@ngrx/store';
import { UndoRedoReducerHelper } from './undo-redo.reducer.helper';
import { deleteRowsSuccess, insertRowsSuccess, loadScenarioDataAction, reorderRowSuccess, updateRowSuccess } from '../app.actions';
import { undoRedoBusyAction, undoRedoClearHistoryAction, undoRedoNotBusyAction } from './undo-redo.action';
import { WsAction } from '@dunefront/common/ws.action';
import { copyPumpingBetweenRangesSuccess } from '../scenario/scenario.actions';

export const UndoRedoModuleFeatureName = 'undoRedo';

export interface IUndoRedoModuleState {
  history: IUndoableAction[];
  currentIndex: number;
  isBusy: boolean;
  dataStateId: string;
}

const initialState: IUndoRedoModuleState = {
  history: [{}],
  currentIndex: 0,
  isBusy: false,
  dataStateId: '',
};

export interface IUndoableAction {
  doNotUndo?: boolean;
  undo?: WsAction;
  redo?: WsAction;
}

export const undoRedoModuleReducer = createReducer<IUndoRedoModuleState>(
  initialState,
  on(undoRedoBusyAction, (state): IUndoRedoModuleState => ({ ...state, isBusy: true })),
  on(undoRedoNotBusyAction, (state): IUndoRedoModuleState => ({ ...state, isBusy: false })),
  on(loadScenarioDataAction, undoRedoClearHistoryAction, (): IUndoRedoModuleState => ({ ...initialState })),
  on(
    updateRowSuccess,
    insertRowsSuccess,
    deleteRowsSuccess,
    reorderRowSuccess,
    copyPumpingBetweenRangesSuccess,
    (state, action): IUndoRedoModuleState => UndoRedoReducerHelper.onUpdateSuccessAction(state, action),
  ),
);
