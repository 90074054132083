import { ResuspensionCalculatorDto } from './resuspension-calculator.dto';
import { UpdateRowsWsAction } from '../../../ws.action';
import { WsActionPropsFactory } from '../../../common/ws-action/ws-action-props.factory';
import { IUpdateRowsProps } from '../../../common/common-store-crud.interfaces';
import { ValidatedResuspensionCalculatorModuleState } from './resuspension-calculator-module.state';
import { ResuspensionCalculatorFactory } from './model/resuspension-calculator.factory';
import { DeveloperSettingsDto } from '../../settings/dto/settingsDto';

export const ResuspensionCalculatorModuleName = 'ResuspensionCalculatorModule';

export enum ResuspensionCalculatorModuleActionTypes {
  UpdateResuspensionCalculator = '[ResuspensionCalculatorModule] Update',
  CalculateResuspensionAction = '[ResuspensionCalculatorModule] Calculate',
  UpdateResult = '[ResuspensionCalculatorModule] UpdateResult',
}

export interface LoadResuspensionCalculatorActionResponse {
  resuspensionCalculatorDto: ResuspensionCalculatorDto;
}

export class UpdateResuspensionCalculatorAction extends UpdateRowsWsAction<ResuspensionCalculatorDto> {
  public override readonly type = ResuspensionCalculatorModuleActionTypes.UpdateResuspensionCalculator;

  constructor(action: IUpdateRowsProps<ValidatedResuspensionCalculatorModuleState>) {
    super(WsActionPropsFactory.updateAction(action, ResuspensionCalculatorFactory.toDto));
  }
}

export class CalculateResuspensionAction extends UpdateRowsWsAction<{ ScenarioId: number }> {
  public override readonly type = ResuspensionCalculatorModuleActionTypes.CalculateResuspensionAction;

  constructor(
    scenarioId: number,
    public developerSettings: DeveloperSettingsDto,
  ) {
    super(WsActionPropsFactory.update([{ ScenarioId: scenarioId }], true));
  }
}

export type ResuspensionCalculatorModuleActions = UpdateResuspensionCalculatorAction | CalculateResuspensionAction;
