<app-generic-modal [title]="modalTitle" [modalButtonsConfigs]="modalButtonsConfigs" [showHelpBtn]="true" (helpClick)="onHelpClick($event)">
  <div class="search">
    <app-form-input
      label="Jump to OD:"
      [source]="{ value: OD }"
      key="value"
      [unitType]="UnitType.Short_Length"
      [decimalPlaces]="3"
      (valueChanged)="onChange($event.value)"
      [triggerOnKeyPress]="true"
      [autoFocus]="autoFocusPosition.START"
      [isUiLockable]="false"
    >
    </app-form-input>
  </div>
  <div class="grid-panel">
    <app-grid
      *ngIf="isGridVisible"
      [dataSource]="filteredPipeData"
      [columns]="columns"
      [showCrudControls]="false"
      (selectedRowsChanged)="onSelectedRowsChanged($event)"
      [singleRowSelectionMode]="true"
    >
    </app-grid>
  </div>
</app-generic-modal>

<div class="modal-help" [class.open]="isHelpOpen">
  <div class="help-content">
    <iframe [src]="'assets/help-files/well/pipe-data.html' | helpUrl"></iframe>
  </div>
</div>
