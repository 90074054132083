import { bitsToBuffer, numToBuffer, numToFixedBuffer, strToBuffer } from './bufferConverters';
import { toFlatArray } from './toFlatArray';

export const generateEBML = (json: Array<any>, outputAsArray: boolean): Blob | Uint8Array => {
  const ebml = [];
  for (let i = 0; i < json.length; i++) {
    if (!('id' in json[i])) {
      //already encoded blob or byteArray
      ebml.push(json[i]);
      continue;
    }

    let data = json[i].data;
    if (typeof data == 'object') {
      data = generateEBML(data, outputAsArray);
    }
    if (typeof data == 'number') {
      data = 'size' in json[i] ? numToFixedBuffer(data, json[i].size) : bitsToBuffer(data.toString(2));
    }
    if (typeof data == 'string') {
      data = strToBuffer(data);
    }

    if (data.length) {
      // const z = z;
    }

    const len = data.size || data.byteLength || data.length;
    const zeroes = Math.ceil(Math.ceil(Math.log(len) / Math.log(2)) / 8);
    const size_str = len.toString(2);
    const padded = new Array(zeroes * 7 + 7 + 1 - size_str.length).join('0') + size_str;
    const size = new Array(zeroes).join('0') + '1' + padded;

    ebml.push(numToBuffer(json[i].id));
    ebml.push(bitsToBuffer(size));
    ebml.push(data);
  }

  //output as blob or byteArray
  if (outputAsArray) {
    //convert ebml to an array
    const buffer = toFlatArray(ebml);
    return new Uint8Array(buffer);
  } else {
    return new Blob(ebml, { type: 'video/webm' });
  }
};
