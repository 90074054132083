import { Routes } from '@angular/router';
import { ResuspensionComponent } from './resuspension.component';
import { ResuspensionCalculatorComponent } from './resuspension-calculator/resuspension-calculator.component';

export const RouteModuleResuspension = 'resuspension';

export const ResuspensionRoutes: Routes = [
  {
    path: RouteModuleResuspension,
    component: ResuspensionComponent,
    data: {
      breadcrumb: 'Resuspension Calculator',
    },
    children: [{ path: '', component: ResuspensionCalculatorComponent }],
  },
];
