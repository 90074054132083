import { Directive, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import { HideOnBaseDirective } from './hide-on-base.directive';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[hideOnDataPro]',
})
export class HideOnDataProDirective extends HideOnBaseDirective {
  public override hideOnModule: ModuleType = ModuleType.Data_Analysis;
}

@NgModule({
  imports: [CommonModule],
  declarations: [HideOnDataProDirective],
  exports: [HideOnDataProDirective],
})
export class HideOnDataProModule {}
