<app-grid
  [dataSource]="tableState"
  [gridConfig]="gridConfig"
  [columns]="gridConfig.columns"
  (selectedRowsChanged)="selectedRowsChanged.emit($event)"
  [showCrudControls]="false"
  [height]="height"
  [isFirstRowInsertingAllowed]="true"
  [isFirstRowDeleteAllowed]="true"
  [minRowCount]="1"
  [headerTopRowHeight]="30"
  dataCy="pumped-fluid-and-gravel-grid"
>
</app-grid>
<div class="buttons-panel">
  <div class="align-right">
    <button
      appButton
      type="button"
      class="btn btn-primary btn-sm"
      data-cy="pumped-fluid-and-gravel-import"
      (buttonClick)="onImportClick()"
    >
      Import
    </button>
    <button appButton type="button" class="btn btn-primary btn-sm" (buttonClick)="onInsertClick()">Insert</button>
    <button appButton type="button" class="btn btn-primary btn-sm" (buttonClick)="onDeleteClick()">Delete</button>
  </div>
</div>
