export interface RheologyDto extends RheologyProps {
  Id: number;
  FluidId: number;
  Temperature: number;
  YieldStress: number;
  Viscosity: number;
  SortOrder: number;
  ScenarioId: number;
}

export enum RheologyType {
  NPrimeAndKPrime = 0,
  Rheometer = 1,
  Viscosity = 2,
}

export interface RheologyProps {
  KPrime: number;
  NPrime: number;
  RSquared: number;
}
