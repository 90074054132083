import { Chart, UpdateMode } from 'chart.js';
import { updateChart } from './chart-common-helpers';
type ChartWithPrivates = Chart & {
  _updateDatasets(mode?: UpdateMode): void;

  _destroyDatasetMeta(datasetIndex: number): void;

  _metasets: any[];
  hasDestroyDatasetMetaPatch?: boolean;
};

/**
 * Applies all needed patches to Chart
 * @param chart
 */
export const patchChart = (chart: Chart): void => {
  applyDestroyDatasetMetaPatch(chart);
};

/**
 * It applies patch to private method: "_destroyDatasetMeta"
 * which is meant to fix crash:
 * TypeError: Cannot read properties of null (reading 'buildOrUpdateElements')
 * Version 3.6 should be fixed: https://github.com/chartjs/Chart.js/issues/9653
 * @param chart
 */
const applyDestroyDatasetMetaPatch = (chart: Chart): void => {
  const chartToPatch = chart as ChartWithPrivates;

  function _destroyDatasetMeta(this: ChartWithPrivates, datasetIndex: number): void {
    const meta = this._metasets[datasetIndex];
    if (meta && meta.controller) {
      meta.controller._destroy();
    }
    delete this._metasets[datasetIndex];
  }

  if (chartToPatch.hasDestroyDatasetMetaPatch !== true) {
    chartToPatch._destroyDatasetMeta = _destroyDatasetMeta;
    chartToPatch.hasDestroyDatasetMetaPatch = true;
  }
};

/**
 * Updates chart with additional data update, which fixes hitRadius crash
 * @param chart
 */
export const updateChartWithHitRadiusFix = (chart: Chart): void => {
  const chartToPatch = chart as ChartWithPrivates;

  // store original implementation of _updateDatasets
  const updateDatasetsOrg = chartToPatch._updateDatasets;

  function updateDatasetsPatched(this: Chart, mode?: UpdateMode): void {
    // update all data elements - this fixes inconsistent data issue (hitRadius)
    const datasetsLength = this.data.datasets.length;
    for (let i = 0; i < datasetsLength; i++) {
      const { controller } = this.getDatasetMeta(i);
      const points = controller._cachedMeta.data;
      controller.updateElements(points, 0, points.length, 'reset');
    }

    // call original method
    updateDatasetsOrg.call(this, mode);
  }

  // swizzle _updateDatasets method
  chartToPatch._updateDatasets = updateDatasetsPatched;

  // update chart internally calls _updateDatasets
  updateChart(chart);

  // restore original _updateDatasets
  chartToPatch._updateDatasets = updateDatasetsOrg;
};

/**
 * Keep the below as easy way to reproduce hitRadius crash
 *
 * Steps to reproduce:
 * Open Evaluate Demo - Gauge Data Chart
 * Call this method from ChartComponent.onDblClick
 * While working keep mouse over chart (move)
 *
 * @param chart
 * @param ngZone
 * @param store
 * @param errorHandlerService
 */
// export const reproduceHitRadius = (chart: Chart, ngZone: NgZone, store: Store, errorHandlerService: PackproErrorHandlerService): void => {
//
//   // use errorHandlerService to stop process when error occurred
//   let err: IErrorState | null = null;
//   errorHandlerService.errorSubject.subscribe(e => err = e);
//
//   // init random number generator
//   const math = create(all, { randomSeed: "99998" });
//   const random = math.random;
//   if (random == null) {
//     return;
//   }
//
//   const performAction = (): void => {
//     // stop if error
//     if (err) {
//       return;
//     }
//
//     const actionType = Math.floor(random(0, 10));
//     if (actionType < 3) {
//       chart?.pan({ x: random(-1000, 1000), y: random(-500, 500) });
//     } else if (actionType < 9) {
//       chart?.zoom({ x: random(.3, 1.7), y: random(.3, 1.7), focalPoint: { x: random(1, 1500), y: random(1, 500) } });
//     } else {
//       ngZone.run(() => store.dispatch(chartZoomOriginalSizeAction()));
//     }
//
//     // schedule next step
//     setTimeout(() => {
//       performAction();
//     }, random(ChartZoomedDataService.ZOOM_REQUEST_TIMEOUT + 7, ChartZoomedDataService.ZOOM_REQUEST_TIMEOUT + 9));
//   };
//
//   // trigger process
//   performAction();
// };

/**
 * Keep the below as easy way to reproduce 'buildOrUpdateElements' crash
 *
 * Steps to reproduce:
 * Cased Hole Water Pack Demo, Simulate module
 * Call this method eg from HomeMenuPanelComponent.lockInputs
 * While working keep mouse over chart (move)
 *
 * @param store
 * @param runCalculationService
 * @param errorHandlerService
 */
// export const reproduceBuildOrUpdateElementsCrash =
//   (store: Store, runCalculationService: LeftNavHelperService, errorHandlerService: PackproErrorHandlerService): void => {
//
//     // init random number generator
//     const math = create(all, { randomSeed: "99998" });
//     const random = math.random;
//     if (random == null) {
//       return;
//     }
//
//     errorHandlerService.errorSubject.subscribe(e => console.warn("ERRRR!!!", e));
//
//     const err$ = observableToBehaviorSubject(errorHandlerService.errorSubject, null);
//
//     store.select(getStartStopButton).subscribe(button => {
//       if (button.enabled && err$.value == null) {
//         setTimeout(() => runCalculationService.startOrCancelSimulation(), random(500, 2000));
//       }
//     });
//   };
