import { createSelector } from '@ngrx/store';
import { getIsCompareScenariosEnabled, getUiState } from '../../ui/ui.selectors';
import { ChartMode, ModuleSection, UiState } from '../../ui/ui-module.state';
import { getAreCurrentResultsPresent } from '../../calculation-engine/calculation-engine-results.selectors';
import { isSimulateBased, ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import { isDataAnalysis, isEvaluate, isTrendAnalysis } from '../menu-selectors.helpers';
import { ChartTimeVolMode } from '@dunefront/common/modules/reporting/reporting-module.actions';
import {
  getAreAnyDataProvidersAvailable,
  getAreAnyImageProvidersAvailable,
  getAreAnyVideoProvidersAvailable,
  getIsReportingEnabled,
  getMdTvdEnabled,
  getReportingChartHasOnlyImportData,
  getReportingChartMdTvdMode,
  getReportingDataState,
  getReportingIsCompareScenarioActive,
  getReportingIsOptimizeActive,
  getRestrictedReportingChartTimeVolMode,
  getSelectedReportingTab,
  getTimeVolEnabled,
} from '../../reporting/reporting.selectors';
import { defaultSelectedReportingTabId, ReportingModuleState } from '../../reporting/reporting-module.state';
import { ReportingTabDto } from '@dunefront/common/dto/reporting-tab.dto';
import { ChartMdTvdMode, ChartZoomMode, CrosshairMode, TooltipPosition } from '@dunefront/common/modules/reporting/reporting.settings';
import { getIsProjectCalculationActive } from '../../ui/calc-engine-ui.selectors';
import { IChartTemplateProperties } from '@dunefront/common/dto/chart-templates.dto';

export class MenuChartHelpers {
  public static isOptimizeChartEnabled(
    _isEvaluate: boolean,
    areCurrentResultsPresent: boolean,
    uiState: UiState,
    reportingTab: ReportingTabDto | undefined,
    isOptimizeActive: boolean | undefined,
    reportingChartHasOnlyImportData: boolean,
  ): boolean {
    const isEnabledForReporting = uiState.appModuleSection === 'reporting' && reportingTab?.IsChartTimeVolume === true;
    return isOptimizeActive != null && _isEvaluate && areCurrentResultsPresent && isEnabledForReporting && !reportingChartHasOnlyImportData;
  }

  public static isReportingSectionChart(
    isSimulateEvaluateTrendOrDataAnalysis: boolean,
    appModuleSection: ModuleSection,
    uiState: UiState,
  ): boolean {
    return isSimulateEvaluateTrendOrDataAnalysis && appModuleSection === 'reporting' && uiState.reportingTab !== 'report';
  }

  public static isCopyChartEnabled(isReportingSectionChart: boolean, reportingState: ReportingModuleState): boolean {
    return (
      isReportingSectionChart &&
      reportingState.selectedReportingTabId !== undefined &&
      reportingState.selectedReportingTabId !== defaultSelectedReportingTabId
    );
  }
}

export const getChartMenuProps = createSelector(
  getUiState,
  getIsProjectCalculationActive,
  getReportingDataState,
  getSelectedReportingTab,
  getAreCurrentResultsPresent,
  getIsCompareScenariosEnabled,
  getIsReportingEnabled,
  getReportingIsOptimizeActive,
  getReportingIsCompareScenarioActive,
  getAreAnyImageProvidersAvailable,
  getAreAnyVideoProvidersAvailable,
  getAreAnyDataProvidersAvailable,
  getReportingChartHasOnlyImportData,
  (
    uiState,
    isCalculationActive,
    reportingState,
    reportingTab,
    areCurrentResultsPresent,
    isCompareScenariosEnabled,
    isReportingEnabled,
    isOptimizeChartActive,
    isCompareScenariosActive,
    areAnyImageProvidersAvailable,
    areAnyVideoProvidersAvailable,
    areAnyDataProvidersAvailable,
    reportingChartHasOnlyImportData,
  ) => {
    const { annotationsVisible, markersVisible, chartMode, appModuleSection, gradientLinesVisible } = uiState;
    const _isSimulate = isSimulateBased(uiState.appModuleType);
    const _isEvaluate = isEvaluate(uiState.appModuleType);
    const _isTrendAnalysis = isTrendAnalysis(uiState.appModuleType);
    const _isDataAnalysis = isDataAnalysis(uiState.appModuleType);
    const isSimulateEvaluateTrendOrDataAnalysis = _isSimulate || _isEvaluate || _isTrendAnalysis || _isDataAnalysis;
    const isReportingSection = isSimulateEvaluateTrendOrDataAnalysis && appModuleSection === 'reporting';
    const isReportingSectionChart = MenuChartHelpers.isReportingSectionChart(
      isSimulateEvaluateTrendOrDataAnalysis,
      appModuleSection,
      uiState,
    );
    const isCopyChartTabEnabled = MenuChartHelpers.isCopyChartEnabled(isReportingSectionChart, reportingState);
    const markChartEnabled = isReportingSectionChart && !isCalculationActive;

    const chartMenuProps: ChartMenuProps = {
      chartMode,
      newChartEnabled: isReportingEnabled && isReportingSection,
      newChartProps: { mdTvdDisabled: _isTrendAnalysis || (_isEvaluate && !areCurrentResultsPresent) },
      editChartEnabled: isReportingEnabled && isReportingSectionChart,
      deleteChartEnabled: isReportingSectionChart,
      copyChartTabEnabled: isCopyChartTabEnabled,
      markChartEnabled,
      annotateChartEnabled: markChartEnabled,
      gradientLineEnabled: markChartEnabled,
      isCompareScenariosVisible: !_isDataAnalysis,
      isCompareScenariosEnabled: isCompareScenariosEnabled,
      isCompareScenariosActive: isCompareScenariosActive,
      isOptimizeChartVisible: !_isDataAnalysis,
      isOptimizeChartEnabled: MenuChartHelpers.isOptimizeChartEnabled(
        _isEvaluate,
        areCurrentResultsPresent,
        uiState,
        reportingTab,
        isOptimizeChartActive,
        reportingChartHasOnlyImportData,
      ),
      isOptimizeChartActive: isOptimizeChartActive,
      isCopyChartEnabled: areAnyImageProvidersAvailable && !isCalculationActive,
      isRecordChartEnabled: areAnyVideoProvidersAvailable && !isCalculationActive,
      isCopyDataEnabled: areAnyDataProvidersAvailable && !isCalculationActive,
      annotationsVisible,
      markersVisible,
      gradientLinesVisible,
      crossHairMode: reportingState.crosshairMode,
      tooltipPosition: reportingState.tooltipPosition,
      maxSeriesInTooltip: reportingState.maxSeriesInTooltip,
      isCrosshairOptionsEnabled: areAnyImageProvidersAvailable,
    };
    return chartMenuProps;
  },
);

export const getAxisSelectionProps = createSelector(
  getMdTvdEnabled,
  getTimeVolEnabled,
  getReportingChartMdTvdMode,
  getRestrictedReportingChartTimeVolMode,
  (mdTvdEnabled, timeVolEnabled, chartMdTvdMode, chartTimeVolMode) => ({
    mdTvdEnabled,
    timeVolEnabled,
    chartMdTvdMode,
    chartTimeVolMode,
  }),
);

export const getChartTemplateProperties = createSelector(
  getChartMenuProps,
  getAxisSelectionProps,
  ({ isCompareScenariosActive, isOptimizeChartActive }, { chartTimeVolMode, chartMdTvdMode }): Omit<IChartTemplateProperties, 'axis'> => {
    return {
      isCompareScenarioActive: isCompareScenariosActive,
      isOptimizeActive: isOptimizeChartActive,
      isVolumeMode: chartTimeVolMode === ChartTimeVolMode.volume,
      isTvdMode: chartMdTvdMode === ChartMdTvdMode.tvd,
    };
  },
);

export const calculatorModuleTypes: ModuleType[] = [
  ModuleType.Injection_Test_Calc,
  ModuleType.Settling_Calc,
  ModuleType.Friction_Calc,
  ModuleType.Resuspension_Calc,
  ModuleType.MASP_Calc,
  ModuleType.Leakoff_Coefficient_Calc,
];

export const getChartZoomProps = createSelector(getUiState, getAreAnyImageProvidersAvailable, (uiState, areAnyImageProvidersAvailable) => {
  const chartZoomProps: ChartZoomProps = {
    chartZoomEnabled: areAnyImageProvidersAvailable,
    chartZoomMode: uiState.chartZoomMode,
  };
  return chartZoomProps;
});

export interface ChartMenuProps {
  chartMode: ChartMode;
  newChartEnabled: boolean;
  newChartProps: NewChartProps;
  editChartEnabled: boolean;
  deleteChartEnabled: boolean;
  copyChartTabEnabled: boolean;
  annotateChartEnabled: boolean;
  isCrosshairOptionsEnabled: boolean;
  markChartEnabled: boolean;
  gradientLineEnabled: boolean;
  isCompareScenariosVisible: boolean;
  isCompareScenariosEnabled: boolean;
  isCompareScenariosActive: boolean;
  isOptimizeChartVisible: boolean;
  isOptimizeChartEnabled: boolean;
  isOptimizeChartActive: boolean;
  isCopyChartEnabled: boolean;
  isRecordChartEnabled: boolean;
  isCopyDataEnabled: boolean;
  crossHairMode: CrosshairMode;
  tooltipPosition: TooltipPosition;
  maxSeriesInTooltip: number;
  annotationsVisible: boolean;
  markersVisible: boolean;
  gradientLinesVisible: boolean;
}

export interface NewChartProps {
  mdTvdDisabled: boolean;
}

export interface ChartZoomProps {
  chartZoomEnabled: boolean;
  chartZoomMode: ChartZoomMode;
}

export interface AxisSelectionProps {
  mdTvdEnabled: boolean;
  timeVolEnabled: boolean;
  chartTimeVolMode: ChartTimeVolMode;
  chartMdTvdMode: ChartMdTvdMode;
}
