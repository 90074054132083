import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import {
  closeEditImportTemplateModalAction,
  createImportDataTemplateAction,
  updateImportDataTemplateAction,
} from '../../../../../+store/import-data/import-data.actions';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { CommonDbType } from '@dunefront/common/dto/common-dto.interfaces';
import { IImportTemplateDto } from '@dunefront/common/modules/data-storage/dto/import-template/import-template.dto';
import { EditTemplateModalMode } from '@dunefront/common/common/templates/template-parser';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { IRadioItem } from '@dunefront/common/common/radio.helpers';
import { ElectronService } from '../../../../../shared/services/electron-service/electron.service';
import { defaultModalCancelButton, modalButton, ModalButtonConfig } from '../../../generic-modal/generic-modal.component';

@Component({
  selector: 'app-edit-import-template-modal',
  templateUrl: './edit-import-template-modal.component.html',
  styleUrls: ['./edit-import-template-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditImportTemplateModalComponent implements OnDestroy {
  public templateNameError = '';
  public radioItems: IRadioItem<SaveTemplateResultAction>[] = [
    { value: SaveTemplateResultAction.saveUser, text: 'Save to my settings' },
    { value: SaveTemplateResultAction.saveOrg, text: 'Save to organization settings' },
  ];

  public saveMode = SaveTemplateResultAction.saveUser;
  public mode!: EditTemplateModalMode;
  public dbType!: CommonDbType;
  public usedNames!: string[];
  public isTimeVolume!: boolean;
  public EditTemplateModalMode = EditTemplateModalMode;
  public selectedTemplate: IImportTemplateDto | undefined;
  private subscription = new Subscription();

  public modalButtonsConfigs: ModalButtonConfig[] = [
    defaultModalCancelButton((): void => this.onCancelClick()),
    modalButton(
      'Save',
      (): void => (this.mode === EditTemplateModalMode.create ? this.onCreateClick() : this.onSaveClick()),
      'edit-import-template-save',
      'primary',
      true,
      false,
    ),
  ];

  constructor(
    private store: Store,
    protected actions$: Actions,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public electronService: ElectronService,
  ) {
    this.mode = config.data.mode;
    this.dbType = config.data.dbType;
    this.isTimeVolume = config.data.isTimeVolume;
    this.usedNames = config.data.usedNames ?? [];
    this.selectedTemplate = config.data.selectedTemplate;
    this.templateName = this.selectedTemplate?.Name ?? '';
    this.validate();
    this.subscription.add(actions$.pipe(ofType(closeEditImportTemplateModalAction)).subscribe(() => this.ref.close()));

    if (this.electronService.isElectronApp) {
      this.saveMode = SaveTemplateResultAction.saveUser;
    }
  }

  private _templateName = '';

  public get templateName(): string {
    return this._templateName;
  }

  public set templateName(name: string) {
    this._templateName = name;
    this.validate();
  }

  public get title(): string {
    return this.mode === EditTemplateModalMode.create ? 'Save template' : 'Rename template';
  }

  public changeSaveMode(mode: SaveTemplateResultAction): void {
    this.saveMode = mode;
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public onCreateClick(): void {
    if (!this.templateNameError.length) {
      const dbType: CommonDbType = this.saveMode === SaveTemplateResultAction.saveUser ? 'User' : 'Organization';
      const action = createImportDataTemplateAction({ templateName: this.templateName, dbType });

      this.store.dispatch(action);
    }
  }

  public onSaveClick(): void {
    if (this.selectedTemplate) {
      const action = updateImportDataTemplateAction({
        templateName: this.templateName,
        template: this.selectedTemplate as IImportTemplateDto,
      });

      this.store.dispatch(action);
    }
  }

  public onCancelClick(): void {
    this.ref.close();
  }

  private validate(): void {
    const newName = this.templateName.trim();

    if (!newName) {
      this.templateNameError = 'Please enter template name';
    } else if (this.usedNames.includes(newName)) {
      this.templateNameError = `Template with the name: "${newName}" ( ${this.dbType.toLocaleLowerCase()} settings ) already exists`;
    } else {
      this.templateNameError = '';
    }

    const saveBtn = this.modalButtonsConfigs.find((btn) => btn.text === 'Save');
    if (saveBtn) {
      saveBtn.disabled = !!this.templateNameError.length;
    }
  }

  public onTemplateNameChange(event: string): void {
    this.templateName = event;
    this.validate();
  }
}

export enum SaveTemplateResultAction {
  saveUser = 0,
  saveOrg = 1,
  cancel = 2,
}
