import { createSelector } from '@ngrx/store';
import { ChartState } from './reporting-module.state';
import { getChartState, getSelectedReportingTab } from './reporting.selectors';
import { getDepthReportingChartState } from './reporting-get-depth-chart-state.selector';

export const getTimeVolReportingChartState = createSelector(
  getChartState,
  getSelectedReportingTab,
  (chartState, selectedReportingTab): ChartState | undefined => {
    if (!selectedReportingTab || !selectedReportingTab.IsChartTimeVolume) {
      return undefined;
    }

    return chartState;
  },
);

export const getReportingChartState = createSelector(
  getTimeVolReportingChartState,
  getDepthReportingChartState,
  (timeVolChartState, depthChartState): ChartState | undefined => {
    if (timeVolChartState) {
      return timeVolChartState;
    }
    if (depthChartState) {
      return depthChartState;
    }
    return;
  },
);
