import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef } from '@angular/core';
import { selectDataSourcePreview, selectPreviewDataSourceColumns } from '../../../../../+store/equation/equation.selectors';
import { GridContainerComponent } from '../../../../../shared/components/grid/grid-container.component';
import { Store } from '@ngrx/store';
import { ModalService } from '../../../../../common-modules/modals/modal.service';
import { IGridColumnConfig } from '../../../../../shared/components/grid/grid.interfaces';
import { GridConfig } from '../../../../../shared/components/grid/grid-config';
import { EquationPreviewRow } from '@dunefront/common/modules/equation/equation-variable.dto';
import { GridResizeService } from '../../../../../shared/services/grid-resize.service';

@Component({
  selector: 'app-equation-preview',
  templateUrl: './equation-preview.component.html',
  styleUrls: ['./equation-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EquationPreviewComponent extends GridContainerComponent<EquationPreviewRow> {
  public dataSource$ = this.store.select(selectDataSourcePreview);
  public override height = 250;
  public columns$ = this.store.select(selectPreviewDataSourceColumns);

  public getColumns(): IGridColumnConfig<EquationPreviewRow>[] {
    return [];
  }

  constructor(
    store: Store,
    cdRef: ChangeDetectorRef,
    protected modalService: ModalService,
    el: ElementRef,
    gridResizeService: GridResizeService,
  ) {
    super(store, cdRef, el, new EquationPreviewGridConfig(modalService), gridResizeService);
  }
}

export class EquationPreviewGridConfig extends GridConfig<EquationPreviewRow> {}
