import { createSelector } from '@ngrx/store';
import { getSimulateMenuValidation } from '../simulate-evaluate-page.validators';
import { getValidatedCompletionModuleState } from '../../../+store/completion/validated-completion.selectors';
import { ILeftNavElement, LeftNavHelpers } from '../../../shared/components/left-nav/left-nav.helpers';
import { CompletionValidation } from '@dunefront/common/modules/completion/model/completion.validation';

export const getCompletionLeftNavElement$ = createSelector(
  getSimulateMenuValidation,
  getValidatedCompletionModuleState,
  (isValid, data): ILeftNavElement =>
    LeftNavHelpers.toNavElement({
      label: 'Completion',
      uri: 'completion',
      icon: 'icon-completion',
      isError: !isValid.isCompletionValid,
      isWarning: !data.Volumes.isValid || data.isRunningStringScreenWarning || data.isLowerCompletionScreenWarning,
      items: [
        LeftNavHelpers.getItem(
          'Lower completion',
          'completion/lower-completion',
          data.isLowerCompletionScreenValid,
          false,
          false,
          data.isLowerCompletionScreenWarning,
        ),
        LeftNavHelpers.getItem(
          'Running string',
          'completion/running-string',
          data.isRunningStringScreenValid,
          false,
          false,
          data.isRunningStringScreenWarning,
        ),
        LeftNavHelpers.getItem(
          'Shunt tubes',
          'completion/shunt-tubes',
          data.isShuntTubesScreenValid,
          !CompletionValidation.IsShuntTubeEnabled(data),
        ),
        LeftNavHelpers.getItem('Schematic', 'completion/schematic'),
        {
          label: 'Volumes',
          routerLink: 'completion/volumes',
          isWarning: !data.Volumes.isValid,
        },
      ],
    }),
);
