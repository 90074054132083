import { UnitConverter } from '../../converter.interfaces';
import { LinearVolumeUnit } from '../../../dto/unit-system.dto';

export class LinearVolumeConverter extends UnitConverter {
  public static symbols: Array<string> = ['', 'm³/m', 'm³/ft', 'ft³/ft', 'ft³/m'];

  public static unit = LinearVolumeUnit;

  public static fromSi(value: number, unit: LinearVolumeUnit): number {
    switch (unit) {
      case LinearVolumeUnit.Cubic_meter_per_meter: {
        return value;
      }
      case LinearVolumeUnit.Cubic_meter_per_foot: {
        return value * 0.3048;
      }
      case LinearVolumeUnit.Cubic_foot_per_foot: {
        return value * 10.7639104;
      }
      case LinearVolumeUnit.Cubic_foot_per_meter: {
        return value * 35.3146667;
      }
      default: {
        throw new TypeError('Unknown Linear Volume Unit:' + unit);
      }
    }
  }

  public static toSi(value: number, unit: LinearVolumeUnit): number {
    switch (unit) {
      case LinearVolumeUnit.Cubic_meter_per_meter: {
        return value;
      }
      case LinearVolumeUnit.Cubic_meter_per_foot: {
        return value / 0.3048;
      }
      case LinearVolumeUnit.Cubic_foot_per_foot: {
        return value / 10.7639104;
      }
      case LinearVolumeUnit.Cubic_foot_per_meter: {
        return value / 35.3146667;
      }
      default: {
        throw new TypeError('Unknown Linear Volume Unit:' + unit);
      }
    }
  }

  public static getSymbol(unit: LinearVolumeUnit): string {
    if (unit == null) {
      return '';
    }
    return this.symbols[unit];
  }
}
