import { IDrawingHoleSection } from '../../../../+store/reporting/reporting-depth-animation.selectors';
import { Survey } from '@dunefront/common/modules/well/model/survey/survey';
import { DrawingLayoutType } from '@dunefront/common/modules/settings/dto/settingsDto';

export class DrawingSurveyMapHelper {
  public static getSurveyMap(layoutType: DrawingLayoutType, survey: Survey[]): IMappedSurveySection[] {
    // returns horizontal, vertical or 2d mapped survey
    return layoutType === DrawingLayoutType.Horizontal
      ? [
          {
            topMd: survey[0].MD,
            bottomMd: survey[survey.length - 1].MD,
            topVd: 0,
            bottomVd: 0,
            topHd: survey[0].MD,
            bottomHd: survey[survey.length - 1].MD,
            length: survey[survey.length - 1].MD - survey[0].MD,
            topDeviation: 90,
            bottomDeviation: 90,
          },
        ]
      : layoutType === DrawingLayoutType.Vertical
      ? [
          {
            topMd: survey[0].MD,
            bottomMd: survey[survey.length - 1].MD,
            topVd: survey[0].MD,
            bottomVd: survey[survey.length - 1].MD,
            topHd: 0,
            bottomHd: 0,
            length: survey[survey.length - 1].MD - survey[0].MD,
            topDeviation: 0,
            bottomDeviation: 0,
          },
        ]
      : this.generateSurveyMap(survey);
  }

  private static generateSurveyMap(survey: Survey[]): IMappedSurveySection[] {
    // generates 2d projection of hole from survey
    let runningHd = 0;
    let runningVd = 0;

    const surveyMap: IMappedSurveySection[] = [];
    for (let i = 1; i < survey.length; i++) {
      const thisLength = survey[i].MD - survey[i - 1].MD;
      const deviationRadians = ((survey[i - 1].Deviation + survey[i].Deviation) * Math.PI) / 360;
      const thisHd = thisLength * Math.sin(deviationRadians);
      const thisVd = thisLength * Math.cos(deviationRadians);

      const bottomHd = runningHd + thisHd;
      const bottomVd = runningVd + thisVd;

      const mappedSection: IMappedSurveySection = {
        topMd: survey[i - 1].MD,
        bottomMd: survey[i].MD,
        topVd: runningVd,
        bottomVd: bottomVd,
        topHd: runningHd,
        bottomHd: bottomHd,
        length: thisLength,
        topDeviation: survey[i - 1].Deviation,
        bottomDeviation: survey[i].Deviation,
      };

      surveyMap.push(mappedSection);
      runningHd = bottomHd;
      runningVd = bottomVd;
    }
    return surveyMap;
  }

  public static smoothSurvey(survey: Survey[]): Survey[] {
    const maxDeviationChangePerMd = 0.35;

    if (!survey.length) {
      return [];
    }

    let prevSegment = survey[survey.length - 1];
    const smoothedSurvey: Survey[] = [prevSegment];

    for (let i = survey.length - 1; i >= 0; i--) {
      const segment = survey[i];

      const mdChange = prevSegment.MD - segment.MD;
      if (mdChange === 0) {
        continue;
      }

      const deviationChange = prevSegment.Deviation - segment.Deviation;
      const deviationChangeAbs = Math.abs(deviationChange);

      const deviationChangePerMd = deviationChangeAbs / Math.abs(mdChange);
      if (deviationChangePerMd > maxDeviationChangePerMd && i > 0) {
        continue;
      }

      if (deviationChangeAbs > 1) {
        const subSections = Math.trunc(deviationChangeAbs) + 1;
        const deviationPart = deviationChange / subSections;
        const mdPart = mdChange / subSections;

        for (let i = 1; i < subSections; i++) {
          smoothedSurvey.push({
            ...segment,
            MD: prevSegment.MD - mdPart * i,
            Deviation: prevSegment.Deviation - deviationPart * i,
          });
        }
      }

      smoothedSurvey.push(segment);
      prevSegment = segment;
    }

    return smoothedSurvey.reverse();
  }

  public static extraHoleSectionsFromSurvey(holeSections: IDrawingHoleSection[], survey: Survey[]): IDrawingHoleSection[] {
    // makes extra hole section at each change of hole direction
    // used to generate a single structure with new element each time hole size or direction changes
    const augmentedHoleSections: IDrawingHoleSection[] = [];
    holeSections.forEach((holeSection) => {
      const surveyPointsInSection = survey.filter(
        (surveyPoint) => surveyPoint.MD > holeSection.topMd && surveyPoint.MD < holeSection.bottomMd,
      );

      if (surveyPointsInSection.length) {
        let topMd = holeSection.topMd;
        surveyPointsInSection.forEach((surveyPoint) => {
          augmentedHoleSections.push({ topMd: topMd, bottomMd: surveyPoint.MD, diameter: holeSection.diameter });
          topMd = surveyPoint.MD;
        });
        augmentedHoleSections.push({ topMd: topMd, bottomMd: holeSection.bottomMd, diameter: holeSection.diameter });
      } else {
        augmentedHoleSections.push(holeSection);
      }
    });

    return augmentedHoleSections;
  }
}

export interface IMappedSurveySection {
  topMd: number;
  bottomMd: number;
  topVd: number; // vertical distance
  bottomVd: number;
  topHd: number; // horizontal distance
  bottomHd: number;
  length: number;
  topDeviation: number;
  bottomDeviation: number;
}
