import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { MenuItem } from 'primeng/api';
import { AxisSelectionProps } from '../../../../../../../+store/menu-selectors/chart/menu-chart.selector';
import { IRadioItem } from '@dunefront/common/common/radio.helpers';
import { updateChartAxisSelectionAction } from '../../../../../../../+store/reporting/reporting.actions';
import { ChartMdTvdMode } from '@dunefront/common/modules/reporting/reporting.settings';

@Component({
  selector: 'app-md-tvd-button',
  templateUrl: './md-tvd-button.component.html',
  styleUrls: ['./md-tvd-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MdTvdButtonComponent implements OnChanges {
  @Input() public axisSelectionProps!: AxisSelectionProps;
  @Input() public isDataBasedModule = false;

  public mdTvdRadio: IRadioItem<ChartMdTvdMode>[] = [
    { value: ChartMdTvdMode.md, text: 'MD' },
    { value: ChartMdTvdMode.tvd, text: 'TVD' },
  ];

  private setMdForRangeMenuItem = {
    label: 'Set <b>MD</b> for all charts in <b>range</b>',
    escape: false,
    command: (): void => {
      this.store.dispatch(
        updateChartAxisSelectionAction({
          changeScope: 'range',
          chartMdTvdMode: ChartMdTvdMode.md,
        }),
      );
    },
  };

  private setMdForScenarioMenuItem = {
    label: 'Set <b>MD</b> for all charts in <b>scenario</b>',
    escape: false,
    command: (): void => {
      this.store.dispatch(
        updateChartAxisSelectionAction({
          changeScope: 'scenario',
          chartMdTvdMode: ChartMdTvdMode.md,
        }),
      );
    },
  };

  private setMdForProjectMenuItem = {
    label: 'Set <b>MD</b> for all charts in <b>project</b>',
    escape: false,
    style: { borderBottom: '1px solid lightgray' },
    command: (): void => {
      this.store.dispatch(
        updateChartAxisSelectionAction({
          changeScope: 'project',
          chartMdTvdMode: ChartMdTvdMode.md,
        }),
      );
    },
  };

  private setTvdForRangeMenuItem = {
    label: 'Set <b>TVD</b> for all charts in <b>range</b>',
    escape: false,
    command: (): void => {
      this.store.dispatch(
        updateChartAxisSelectionAction({
          changeScope: 'range',
          chartMdTvdMode: ChartMdTvdMode.tvd,
        }),
      );
    },
  };

  private setTvdForScenarioMenuItem = {
    label: 'Set <b>TVD</b> for all charts in <b>scenario</b>',
    escape: false,
    command: (): void => {
      this.store.dispatch(
        updateChartAxisSelectionAction({
          changeScope: 'scenario',
          chartMdTvdMode: ChartMdTvdMode.tvd,
        }),
      );
    },
  };

  private setTvdForProjectMenuItem = {
    label: 'Set <b>TVD</b> for all charts in <b>project</b>',
    escape: false,
    command: (): void => {
      this.store.dispatch(
        updateChartAxisSelectionAction({
          changeScope: 'project',
          chartMdTvdMode: ChartMdTvdMode.tvd,
        }),
      );
    },
  };

  public advancedButtonMenuItems: MenuItem[] = [];
  public simulateBasedAdvancedButtonMenuItems: MenuItem[] = [
    this.setMdForScenarioMenuItem,
    this.setMdForProjectMenuItem,
    this.setTvdForScenarioMenuItem,
    this.setTvdForProjectMenuItem,
  ];
  public dataBasedAdvancedButtonMenuItems: MenuItem[] = [
    this.setMdForRangeMenuItem,
    this.setMdForScenarioMenuItem,
    this.setMdForProjectMenuItem,
    this.setTvdForRangeMenuItem,
    this.setTvdForScenarioMenuItem,
    this.setTvdForProjectMenuItem,
  ];

  constructor(private store: Store) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.isDataBasedModule?.currentValue !== changes.isDataBasedModule?.previousValue) {
      this.advancedButtonMenuItems = changes.isDataBasedModule?.currentValue
        ? this.dataBasedAdvancedButtonMenuItems
        : this.simulateBasedAdvancedButtonMenuItems;
    }
  }

  public mdTvdModeChanged($event: ChartMdTvdMode): void {
    this.store.dispatch(
      updateChartAxisSelectionAction({
        changeScope: 'single',
        chartMdTvdMode: $event,
      }),
    );
  }
}
