import { ChangeDetectorRef, Component } from '@angular/core';
import { DbDependentComponent } from '../../../../common-modules/db-connection/db-dependent.component';
import { Store } from '@ngrx/store';
import { notEmpty } from '@dunefront/common/common/state.helpers';
import { getPSDAnalysis } from '../../../../+store/psd-analysis/psd-analysis.selectors';
import { updatePSDAnalysis } from '../../../../+store/psd-analysis/psd-analysis.actions';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import * as uiActions from '../../../../+store/ui/ui.actions';
import { changeProp, ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';
import { StoreCrudPropsFactory } from '@dunefront/common/common/common-store-crud.interfaces';
import { PSDAnalysis } from '@dunefront/common/modules/psd-analysis/model/psd-analysis/psd-analysis';

@Component({
  selector: 'app-sand-production',
  templateUrl: './sand-production.component.html',
  styleUrls: ['./sand-production.component.scss'],
})
export class SandProductionComponent extends DbDependentComponent {
  public psdAnalysis!: PSDAnalysis;

  public UnitType = UnitSystem;

  constructor(store: Store, cdRef: ChangeDetectorRef) {
    super(store, cdRef);
    this.subscription.add(
      notEmpty(store.select(getPSDAnalysis)).subscribe((psdAnalysis) => {
        this.psdAnalysis = psdAnalysis;
        this.cdRef.markForCheck();
      }),
    );
    store.dispatch(uiActions.setPsdAnalysisTabAction({ tab: 'sand-production' }));
    this.onHelpChange('psd', 'psd-sand-production');
  }

  public updatePSDAnalysis(props: ObjectChangeProp<PSDAnalysis>): void {
    const psdAnalysis = changeProp(this.psdAnalysis, props);
    this.store.dispatch(updatePSDAnalysis(StoreCrudPropsFactory.updateRow(psdAnalysis, props)));
  }
}
