import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ISelectedFluidData } from '../../../../../+store/fluid/fluid.selectors';
import { EnumHelpers } from '@dunefront/common/utils/enum.helpers';
import { ConvertUnitPipe } from '@dunefront/common/modules/units/convert-unit.pipe/convert-unit.pipe';
import { ModalService } from '../../../../../common-modules/modals/modal.service';
import {
  RheometerConstants,
  RheometerTestType,
  RotorBobCombination,
  SpeedType,
  TorsionSpringAssembly,
} from '@dunefront/common/modules/fluid/dto/rheometer.dto';
import { cancelChange } from '../../../../../+store/store.helpers';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { ISelectItem } from '@dunefront/common/common/select.helpers';
import { Rheometer } from '@dunefront/common/modules/fluid/model/rheometer/rheometer';
import { Fluid } from '@dunefront/common/modules/fluid/model/fluid';
import { IRadioItem } from '@dunefront/common/common/radio.helpers';
import { RouterHelperService } from '../../../../../shared/services/router-helper.service';
import { changeProp, ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';
import { IUpdateTableRowsProps, StoreCrudPropsFactory } from '@dunefront/common/common/common-store-crud.interfaces';

@Component({
  selector: 'app-rheometer-test-configuration',
  templateUrl: './rheometer-test-configuration.component.html',
  styleUrls: ['./rheometer-test-configuration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RheometerTestConfigurationComponent implements OnChanges {
  @Input() public selectedFluidData: ISelectedFluidData | undefined;
  @Input() public temperatureSelect: ISelectItem<number>[] | undefined;
  @Input() public temperatureSelectLabel!: string;
  @Output() public rheometerPropertyChange = new EventEmitter<IUpdateTableRowsProps<Rheometer>>();
  @Output() public fluidPropertyChange = new EventEmitter<ObjectChangeProp<Fluid>>();

  public RheometerTestType = RheometerTestType;
  public UnitType = UnitSystem;
  public rotorBobValue = 0;
  public torsionSpringValue = 0;
  public temperatureUnitSymbol = '';

  public speedTypeSelect: ISelectItem<SpeedType>[] = EnumHelpers.EnumToISelectItemArray(
    SpeedType,
    false,
    undefined,
    undefined,
    new Map([
      [SpeedType.Six_Speed, '6 Speed'],
      [SpeedType.Twelve_Speed, '12 Speed'],
    ]),
  );
  public rotorBobSelect: ISelectItem<RotorBobCombination>[] = EnumHelpers.EnumToISelectItemArray(RotorBobCombination, true, [
    RotorBobCombination.R1B1,
    RotorBobCombination.R1B2,
    RotorBobCombination.R1B3,
    RotorBobCombination.R1B4,
    RotorBobCombination.R2B1,
    RotorBobCombination.R3B1,
  ]);
  public torsionSpringSelect: ISelectItem<TorsionSpringAssembly>[] = EnumHelpers.EnumToISelectItemArray(
    TorsionSpringAssembly,
    false,
    undefined,
    undefined,
    new Map([
      [TorsionSpringAssembly.F0_2, 'F0.2'],
      [TorsionSpringAssembly.F0_5, 'F0.5'],
    ]),
  );
  public testTypeRadio: IRadioItem<RheometerTestType>[] = [
    { value: RheometerTestType.RPM_Dial_Reading, text: 'RPM and Dial Reading' },
    { value: RheometerTestType.Shear_Rate_Viscosity, text: 'Shear Rate and Viscosity' },
  ];

  constructor(
    private modalService: ModalService,
    private cdRef: ChangeDetectorRef,
    private routerHelperService: RouterHelperService,
  ) {}

  public async onSelectedTemperatureChange(temperatureId: number): Promise<void> {
    if (this.selectedFluidData) {
      await this.routerHelperService.navigateToRheometer(this.selectedFluidData.fluid.Id, temperatureId);
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedFluidData?.currentValue?.rheometer) {
      this.rotorBobValue = RheometerConstants.GetRotorBobFactor(
        changes.selectedFluidData.currentValue.rheometer.rowData.RotorBobCombination,
      );
      this.torsionSpringValue = RheometerConstants.GetTorsionSpringFactor(
        changes.selectedFluidData.currentValue.rheometer.rowData.TorsionSpringAssembly,
      );
      this.temperatureUnitSymbol = ConvertUnitPipe.encodeUnitSymbol(UnitSystem.Temperature);
    }
  }

  public emitRheometerValue(props: ObjectChangeProp<Rheometer>): void {
    if (this.selectedFluidData?.rheometer) {
      const rheometerRow = this.selectedFluidData.rheometer;
      const row = { ...rheometerRow, rowData: changeProp(rheometerRow.rowData, props) };
      this.rheometerPropertyChange.emit(StoreCrudPropsFactory.updateTableRows([row], props));
    }
  }

  public async emitFluidValue(props: ObjectChangeProp<Fluid>): Promise<void> {
    if (!this.selectedFluidData) {
      return;
    }
    if (props.key === 'RheometerTestType') {
      const confirmResult = await this.modalService.showConfirm(
        'Existing rheometer readings will be deleted - would you like to continue?',
        'Information',
      );
      if (!confirmResult) {
        const cancelGenerator = cancelChange(this.selectedFluidData.fluid, props, this.cdRef);
        this.selectedFluidData.fluid = cancelGenerator.next().value;
        this.selectedFluidData.fluid = cancelGenerator.next().value;
        return;
      }
    }
    this.fluidPropertyChange.emit(props);
  }
}
