<ng-container *ngIf="currentUnitSystem$ | async as currentUnitSystem">
  <ng-container *ngIf="drawableProviderId">
    <app-survey-preview-chart
      *ngIf="wellState$ | async as wellState"
      [state]="wellState"
      [longLengthUnit]="currentUnitSystem.Long_Length"
      [drawableProviderId]="drawableProviderId"
      [noMenu]="true"
    ></app-survey-preview-chart>
  </ng-container>
</ng-container>
