import { createAction, props } from '@ngrx/store';
import { ImportDataModuleState } from './import-data-module.state';
import { CommonDbType } from '@dunefront/common/dto/common-dto.interfaces';
import { IImportTemplateDto } from '@dunefront/common/modules/data-storage/dto/import-template/import-template.dto';
import { ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import { TemplatesLoadActionResponse } from '@dunefront/common/common/templates/templates.interfaces';

export const openImportDataModalAction = createAction('[importData] openModal');

export const closeEditImportTemplateModalAction = createAction('[importData] closeEditImportTemplateModal');

export const updateImportDataStateAction = createAction('[importData] updateImportDataState', props<ImportDataModuleState>());

export const resetImportDataStateAction = createAction('[importData] reset');

export const redirectToGaugeDataAction = createAction('[importData] redirectToGaugeData', props<{ moduleType: ModuleType }>());

export const loadImportDataTemplatesSuccessAction = createAction(
  '[importData] loadTemplatesSuccess',
  props<TemplatesLoadActionResponse<IImportTemplateDto>>(),
);

export const createImportDataTemplateAction = createAction(
  '[importData] createTemplate',
  props<{ templateName: string; dbType: CommonDbType }>(),
);

export const updateImportDataTemplateAction = createAction(
  '[importData] updateTemplate',
  props<{ templateName: string; template: IImportTemplateDto }>(),
);

export const applyImportTemplateAction = createAction(
  '[importData] applyImportTemplate',
  props<{ templateId: string; applyFilePropertiesConfig: boolean }>(),
);

export const deleteImportTemplateAction = createAction('[importData] deleteImportTemplate', props<{ templateId: string }>());

export const importTemplateAlreadyExitsAction = createAction(
  '[importData] importTemplateAlreadyExits',
  props<{ templateName: string; dbType: CommonDbType }>(),
);

export const nextColumnAction = createAction('[importData] nextColumnAction');
export const prevColumnAction = createAction('[importData] prevColumnAction');
export const includedColumnIdChangedAction = createAction(
  '[importData] includedColumnIdChangedAction',
  props<{ colId: number; isEnabled: boolean }>(),
);
export const fileLoadedAction = createAction('[importData] fileLoadedAction');
export const updateFileConfiguration = createAction('[importData] updateFileConfiguration');
