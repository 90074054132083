import { ChartMode, isChartInMarkerMode, UiState, XYShiftAxis } from './ui-module.state';
import { CrudResponse } from '@dunefront/common/modules/common.actions';
import { ICustomSettings } from '@dunefront/common/dto/custom-settings.dto';

export class UiReducerHelper {
  public static xAxisShiftAction(state: UiState): UiState {
    return {
      ...UiReducerHelper.chartModeAction(ChartMode.default, state),
      isXAutoShiftActive: false,
      isYAutoShiftActive: false,
      xyAxisShift: state.xyAxisShift === XYShiftAxis.x ? XYShiftAxis.none : XYShiftAxis.x,
    };
  }

  public static yAxisShiftAction(state: UiState): UiState {
    return {
      ...UiReducerHelper.chartModeAction(ChartMode.default, state),
      isXAutoShiftActive: false,
      isYAutoShiftActive: false,
      xyAxisShift: state.xyAxisShift === XYShiftAxis.y ? XYShiftAxis.none : XYShiftAxis.y,
    };
  }

  public static yAxisAutoShiftAction(state: UiState): UiState {
    const isYAutoShiftActive = !state.isYAutoShiftActive;
    return {
      ...UiReducerHelper.chartModeAction(ChartMode.default, state),
      xyAxisShift: XYShiftAxis.none,
      isXAutoShiftActive: false,
      isYAutoShiftActive,
    };
  }

  public static xAxisAutoShiftAction(state: UiState): UiState {
    const isXAutoShiftActive = !state.isXAutoShiftActive;
    return {
      ...UiReducerHelper.chartModeAction(ChartMode.default, state),
      xyAxisShift: XYShiftAxis.none,
      isYAutoShiftActive: false,
      isXAutoShiftActive,
    };
  }

  public static resetButtonsAfterRedirect(state: UiState): UiState {
    return {
      ...UiReducerHelper.chartModeAction(ChartMode.default, state),
      isYAutoShiftActive: false,
      xyAxisShift: XYShiftAxis.none,
    };
  }

  public static chartModeAction(mode: ChartMode, state: UiState): UiState {
    return {
      ...state,
      chartMode: state.chartMode !== mode ? mode : ChartMode.default,
      xyAxisShift: mode !== ChartMode.default ? XYShiftAxis.none : state.xyAxisShift,
      annotationsVisible: mode === ChartMode.annotate ? true : state.annotationsVisible,
      gradientLinesVisible: mode === ChartMode.editGradientLine ? true : state.gradientLinesVisible,
      markersVisible: isChartInMarkerMode(mode) ? true : state.markersVisible,
    };
  }

  public static onUpdateRowsSuccess(state: UiState, response: CrudResponse): UiState {
    const customSettings = response.affectedRows.customSettings;
    if (!customSettings) {
      return state;
    }
    return this.updateCustomSettings(state, customSettings);
  }

  public static updateCustomSettings(state: UiState, customSettings: Partial<ICustomSettings>): UiState {
    return {
      ...state,
      isSideNavPinned: customSettings.pinSettings?.isSideNavPinned ?? false,
      isHelpPinned: customSettings.pinSettings?.isHelpPinned ?? false,
      exportDataUseTimestamps: customSettings.exportDataSettings?.shouldUseTimestamps ?? false,
      chartZoomMode: customSettings.zoomSettings?.zoomMode ?? 'xy',
      notificationsStates: customSettings.notificationsStatus ?? [],
    };
  }
}
