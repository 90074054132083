import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, NgModule, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { MenuItem } from 'primeng/api';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbComponent implements OnInit, AfterViewInit, OnDestroy {
  public items: MenuItem[] = [];
  private routerEvents$ = this.router.events.pipe(filter((event) => event instanceof NavigationEnd));
  private subscription = new Subscription();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    protected cdRef: ChangeDetectorRef,
  ) {}

  public ngOnInit(): void {
    this.items = this.createBreadcrumbs(this.activatedRoute.root);
  }

  public ngAfterViewInit(): void {
    this.subscription.add(
      this.routerEvents$.subscribe(() => {
        this.items = this.createBreadcrumbs(this.activatedRoute.root);
        this.cdRef.markForCheck();
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private createBreadcrumbs(route: ActivatedRoute, breadcrumbs: MenuItem[] = []): MenuItem[] {
    const children: ActivatedRoute[] = route.children;
    if (!children.length) {
      return breadcrumbs;
    }

    for (const child of children) {
      const label = child.snapshot.data['breadcrumb'];
      if (label !== null && label !== undefined) {
        breadcrumbs.push({ label });
      }
      return this.createBreadcrumbs(child, breadcrumbs);
    }
    return breadcrumbs;
  }
}

@NgModule({
  imports: [CommonModule, FormsModule, BreadcrumbModule],
  declarations: [BreadcrumbComponent],
  exports: [BreadcrumbComponent],
})
export class BreadcrumbsModule {}
