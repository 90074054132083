import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ScenarioModuleFeatureName, ScenarioModuleState } from './scenario.reducer';
import { toSelectItem } from '@dunefront/common/common/select.helpers';
import { ScenarioFactory } from '@dunefront/common/modules/scenario/scenario';

export const getScenarioState = createFeatureSelector<ScenarioModuleState>(ScenarioModuleFeatureName);
export const getAllScenarios = createSelector(getScenarioState, (state) => state.scenarios);
export const getCurrentScenario = createSelector(getScenarioState, (state) =>
  state.scenarios.find((s) => s.Id === state.currentScenarioId),
);
export const getCurrentScenarioId = createSelector(getScenarioState, (state) => state.currentScenarioId);
export const getIsScenarioStateLoaded = createSelector(getScenarioState, (state) => state.isScenarioLoaded);
export const getSelectData = createSelector(getScenarioState, (state) =>
  state.scenarios.map((scenario) => toSelectItem(scenario.Id, scenario.Name)),
);

export const getScenariosToCompare = createSelector(getScenarioState, (state) => {
  const currentScenario = state.scenarios.find((scenario) => scenario.Id === state.currentScenarioId);
  const compareScenarioIds = currentScenario?.CompareScenarioIds ?? [];
  const scenarioDict = ScenarioFactory.createEmptyScenarioDict(currentScenario?.Name ?? '');
  if (!compareScenarioIds.length) {
    return scenarioDict;
  }

  const sortedScenariosToCompare = state.scenarios
    .filter((scenario) => compareScenarioIds.includes(scenario.Id))
    .sort((a, b) => a.SortOrder - b.SortOrder);
  for (const scenarioToCompare of sortedScenariosToCompare) {
    scenarioDict[scenarioToCompare.Id] = scenarioToCompare;
    scenarioDict.scenarioIds.push(scenarioToCompare.Id);
  }
  return scenarioDict;
});
