export interface RheometerDto {
  Id: number;
  FluidId: number;
  Temperature: number;
  RotorBobCombination: RotorBobCombination;
  TorsionSpringAssembly: TorsionSpringAssembly;
  SpeedType: SpeedType;
  IsShuntRefCurveShown: boolean;
  SortOrder: number;
  ScenarioId: number;
}

export interface RheometerReadingDto {
  Id: number;
  RheometerId: number;
  RPM: number;
  DialReading: number;
  ShearRate: number;
  Viscosity: number;
  SortOrder: number;
  ScenarioId: number;
}

export enum RotorBobCombination {
  // Commonly used combinations
  R1B1 = 0,
  R1B2 = 1,
  R1B3 = 2,
  R1B4 = 3,
  R2B1 = 4,
  R3B1 = 5,

  // Not recommended by Fann as the shear gap becomes so large that the data generated would be inconsistent and questionable
  R2B2 = 6,
  R2B3 = 7,
  R2B4 = 8,
  R3B2 = 9,
  R3B3 = 10,
  R3B4 = 11,
}

export enum TorsionSpringAssembly {
  F0_2 = 0,
  F0_5 = 1,
  F1 = 2,
  F2 = 3,
  F3 = 4,
  F4 = 5,
  F5 = 6,
  F10 = 7,
}

export enum SpeedType {
  Six_Speed = 0,
  Twelve_Speed = 1,
  Custom = 2,
}

export enum RheometerTestType {
  RPM_Dial_Reading = 0,
  Shear_Rate_Viscosity = 1,
}

export class RheometerConstants {
  public static GetRotorBobFactor(rotorBobCombination: RotorBobCombination): number {
    switch (rotorBobCombination) {
      case RotorBobCombination.R1B1:
        return 1.0;
      case RotorBobCombination.R1B2:
        return 8.915;
      case RotorBobCombination.R1B3:
        return 25.392;
      case RotorBobCombination.R1B4:
        return 50.787;
      case RotorBobCombination.R2B1:
        return 0.315;
      case RotorBobCombination.R2B2:
        return 8.229;
      case RotorBobCombination.R2B3:
        return 24.707;
      case RotorBobCombination.R2B4:
        return 49.412;
      case RotorBobCombination.R3B1:
        return 4.517;
      case RotorBobCombination.R3B2:
        return 12.431;
      case RotorBobCombination.R3B3:
        return 28.909;
      case RotorBobCombination.R3B4:
        return 57.815;
      default:
        throw new TypeError('The rotor bob combination is not valid:' + rotorBobCombination);
    }
  }

  public static GetShearRateConstant(rotorBobCombination: RotorBobCombination): number {
    switch (rotorBobCombination) {
      case RotorBobCombination.R1B1:
        return 1.7023;
      case RotorBobCombination.R1B2:
        return 0.377;
      case RotorBobCombination.R1B3:
        return 0.268;
      case RotorBobCombination.R1B4:
        return 0.268;
      case RotorBobCombination.R2B1:
        return 5.4225;
      case RotorBobCombination.R2B2:
        return 0.362;
      case RotorBobCombination.R2B3:
        return 0.215;
      case RotorBobCombination.R2B4:
        return 0.215;
      case RotorBobCombination.R3B1:
        return 0.377;
      case RotorBobCombination.R3B2:
        return 0.204;
      case RotorBobCombination.R3B3:
        return 0.162;
      case RotorBobCombination.R3B4:
        return 0.162;
      default:
        throw new TypeError('The rotor bob combination is not valid:' + rotorBobCombination);
    }
  }

  public static GetTorsionSpringFactor(torsionSpringAssembly: TorsionSpringAssembly): number {
    switch (torsionSpringAssembly) {
      case TorsionSpringAssembly.F0_2:
        return 0.2;
      case TorsionSpringAssembly.F0_5:
        return 0.5;
      case TorsionSpringAssembly.F1:
        return 1.0;
      case TorsionSpringAssembly.F2:
        return 2.0;
      case TorsionSpringAssembly.F3:
        return 3.0;
      case TorsionSpringAssembly.F4:
        return 4.0;
      case TorsionSpringAssembly.F5:
        return 5.0;
      case TorsionSpringAssembly.F10:
        return 10.0;
      default:
        throw new TypeError('The torsion spring assembly is not valid:' + torsionSpringAssembly);
    }
  }

  public static get SixSpeedRheometerReadings(): number[] {
    return [3, 6, 100, 200, 300, 600];
  }

  public static get TwelveSpeedRheometerReadings(): number[] {
    return [0.9, 1.8, 3, 6, 30, 60, 90, 100, 180, 200, 300, 600];
  }
}

export interface RheometerWithRheometerReadings extends RheometerDto {
  RheometerReadings: RheometerReadingDto[];
}
