export enum ChartAxis {
  PrimaryValue = 0,
  SecondaryValue = 1,
  OppositePrimaryValue = 2,
  OppositeSecondaryValue = 3,
  Argument = 10,
}

export enum XAxisFormat {
  deltaTime = 0,
  timestamp = 1,
}

export interface ChartAxisPropertyDto {
  Id: number;
  ScenarioId: number;
  ChartId: number;
  SortOrder: number;
  AxisType: ChartAxis;
  AxisTitle: string | null;
  IsLogarithmic: boolean;
  IsManualLimit: boolean;
  MinValue: number | null;
  MaxValue: number | null;
  IsOverrideChartAxis: boolean;
  AxisTitleFontSize: number | null;
  AxisTitleFontColor: string | null;
  AxisTitleFontBold: boolean | null;
  AxisTitleFontItalic: boolean | null;
  AxisLabelFontSize: number | null;
  AxisLabelFontColor: string | null;
  AxisLabelFontBold: boolean | null;
  AxisLabelFontItalic: boolean | null;
  XAxisFormat: XAxisFormat;
  StartTimeFileId: number | null;
}

export interface IAxisStyle {
  AxisTitleFontSize: number;
  AxisTitleFontColor: string;
  AxisTitleFontBold: boolean;
  AxisTitleFontItalic: boolean;
  AxisLabelFontSize: number;
  AxisLabelFontColor: string;
  AxisLabelFontBold: boolean;
  AxisLabelFontItalic: boolean;
}

export interface IAxisMargin {
  XAxisStartMarginPercent: number;
  XAxisNoMarginWhenStartingNearZero: boolean;
  XAxisEndMarginPercent: number;
  YAxisStartMarginPercent: number;
  YAxisNoMarginWhenStartingNearZero: boolean;
  YAxisEndMarginPercent: number;
}
