import { PipeType } from '../../dto/pipe.dto';
import { GeneralCalculations } from '../../common/general.calculations';
import { Validation } from '../../common/validation';

export interface Pipe {
  ScenarioId: number;
  Id: number;
  TopMD: number;
  BottomMD: number;
  InnerDiameter: number;
  OuterDiameter: number;
  PipeType: PipeType;
  Weight: number;
  SortOrder: number;
  Length: number;
  IsReservoirSection: boolean;
  Description: string;
}

export interface ShuntedPipe extends Pipe {
  PipeType: PipeType.Shunted_Screen | PipeType.Shunted_Blank_Pipe;
}

export const ShuntedPipeTypes = [PipeType.Shunted_Screen, PipeType.Shunted_Blank_Pipe];

export abstract class BasePipeCalculations {
  // Method to calculate the internal volume
  public static InternalVolume(pipe: Pipe, flowDirection: FlowDirection, isToolJointAnalysed: boolean): number {
    // Calculate the pipe volume
    // Return the pipe volume
    return GeneralCalculations.CircleArea(pipe.InnerDiameter) * this.PipeLength(pipe);
  }

  public static MaxDiameter(pipe: Pipe): number {
    // Return the outer diameter
    return pipe.OuterDiameter;
  }

  // move that to factory class
  public static PipeLength(pipe: Pipe): number {
    return Validation.UICheckNumberValidity(pipe.BottomMD - pipe.TopMD);
  }
}

export enum FlowDirection {
  None = 0,
  DownFlow = 1,
  UpFlow = 2,
}
