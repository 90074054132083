import { ScenarioRangePropertiesDto } from '../../../dto/scenario-range-properties.dto';
import { IValidatedModuleState } from '../../../common/common-state.interfaces';
import { RangeFactory } from './range.factory';

export interface IValidatedScenarioRangeProperties extends ScenarioRangePropertiesDto, IValidatedModuleState<ScenarioRangePropertiesDto> {
  isTrendAnalysisInputsValid: boolean;
}

export const initialValidatedScenarioRangeProperties = (scenarioId: number, rangeId: number): IValidatedScenarioRangeProperties => ({
  ...RangeFactory.newScenarioRangeProperties(scenarioId, rangeId),
  error: {},
  isValid: true,
  isTrendAnalysisInputsValid: true,
});
