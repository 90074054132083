import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { ReferenceVariableCalculatorResultsDto } from '@dunefront/common/modules/reference-variables/dto/reference-variable-calculator-results.dto';

@Component({
  selector: 'app-reference-parameters-panel',
  templateUrl: './reference-parameters-panel.component.html',
  styleUrls: ['./reference-parameters-panel.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReferenceParametersPanelComponent {
  @Input() public refVariables!: ReferenceVariableCalculatorResultsDto | null | undefined;

  public UnitType = UnitSystem;
}
