import { Injectable } from '@angular/core';
import { BaseWsEffects } from '../base-ws.effects';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { BackendConnectionService } from '../../shared/backend-connection/backend-connection.service';
import { ModalService } from '../../common-modules/modals/modal.service';
import {
  CheckIfVacuumNeeded,
  CheckIfVacuumNeededResponse,
  DataStorageFileDeleteAction,
  DataStorageFilesLoadAction,
  DataStorageImportColumnDeleteRowsAction,
  DataStorageImportColumnResetDataAction,
  DataStorageImportColumnUpdateRowsAction,
  DataStorageModuleActions,
  DataStorageModuleActionTypes,
  DataStorageModuleName,
  DeleteAllResultsAction,
  DeleteResultsAction,
  VacuumDbAction,
} from '@dunefront/common/modules/data-storage/data-storage-module.actions';
import {
  deleteAllResultsAction,
  deleteColumnAction,
  deleteFileAction,
  deleteResultsAction,
  deleteResultsSuccessAction,
  loadFilesAction,
  removeDataResultsFromStoreIfNeeded,
  resetDataAction,
  updateImportColumnAction,
  vacuumDbAction,
} from './data-storage.actions';

import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { CrudResponse } from '@dunefront/common/modules/common.actions';
import { dataFailed, deleteRowsSuccess, updateRowSuccess } from '../app.actions';
import { firstValueFrom, Observable, of } from 'rxjs';
import { getUiState } from '../ui/ui.selectors';
import { getFileHash } from '../backend-connection/backend-connection.selectors';
import { undoRedoClearHistoryAction } from '../undo-redo/undo-redo.action';
import { RouterHelperService } from '../../shared/services/router-helper.service';
import * as actions from '../backend-connection/backend-connection.actions';
import { checkIfVacuumNeededAndCloseFile, dbDisconnectAction } from '../backend-connection/backend-connection.actions';
import {
  DeleteResultsFilter,
  isSimulateBased,
  LONG_CALCULATION_WITH_SIMULATION_RESULTS_MODULE_TYPES,
  ModuleType,
} from '@dunefront/common/modules/scenario/scenario.dto';
import { RouteModuleData, RouteModuleDataGrid } from '../../pages/gauge-data-page/gauge-data-routes-names';
import { getDeleteResultsParams, IDeleteResultsParams } from '../import-data/import-data.selectors';
import { filterNil } from '@dunefront/common/common/state.helpers';
import { RouteModuleWell, RouteModuleWellGeneralData } from '../../pages/simulate-evaluate-page/well/well-routes-names';
import { getCurrentScenarioId } from '../scenario/scenario.selectors';
import { getIsUndoRedoHistoryEmpty } from '../undo-redo/undo-redo.selectors';
import { selectUrl } from '../router/router.selectors';
import { RouteModuleCompletion } from '../../pages/simulate-evaluate-page/completion/completion-routes-names';
import { RouteModuleSettings, RouteModuleSettingsProject } from '../../pages/common/settings/settings-routes-names';
import { RouteModuleFluids } from '../../pages/common/fluids/fluids-routes-names';
import { RouteModuleGravels } from '../../pages/common/gravel/gravel-routes-names';
import { RouteModulePumping } from '../../pages/simulate-evaluate-page/pumping/pumping-routes-names';
import { RouteModuleTrendAnalysis } from '../../pages/trend-analysis-page/trend-analysis-page.routes';
import { WsActionResponse } from '@dunefront/common/response-ws.action';
import { getCurrentRangeId } from '../range/range.selectors';

@Injectable()
export class DataStorageEffects extends BaseWsEffects {
  constructor(
    actions$: Actions,
    store: Store,
    wsService: BackendConnectionService,
    modalService: ModalService,
    protected routerHelperService: RouterHelperService,
  ) {
    super(actions$, wsService, DataStorageModuleName, true, true, modalService, store);
  }

  public updateImportColumnAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateImportColumnAction),
      mergeMap((action) => this.emitUpdate(new DataStorageImportColumnUpdateRowsAction(action), 'Updating column...')),
    ),
  );

  public deleteColumnAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteColumnAction),
      mergeMap((action) =>
        this.emit<CrudResponse>(new DataStorageImportColumnDeleteRowsAction(action), 'Deleting column...').pipe(
          mergeMap((result) => [deleteRowsSuccess(result.payload), undoRedoClearHistoryAction()]),
          catchError((err) => of(dataFailed(err))),
        ),
      ),
    ),
  );

  public loadFilesAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadFilesAction),
      mergeMap((action) =>
        this.emit<CrudResponse>(new DataStorageFilesLoadAction(), 'Loading files...').pipe(
          mergeMap((result) => [updateRowSuccess(result.payload)]),
          catchError((err) => of(dataFailed(err))),
        ),
      ),
    ),
  );

  public deleteFileAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteFileAction),
      mergeMap((action) =>
        this.emit<CrudResponse>(new DataStorageFileDeleteAction(action), 'Deleting file...').pipe(
          mergeMap((result) => [deleteRowsSuccess(result.payload), undoRedoClearHistoryAction()]),
          catchError((err) => of(dataFailed(err))),
        ),
      ),
    ),
  );

  public resetDataAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resetDataAction),
      mergeMap((action) =>
        this.emit<CrudResponse>(new DataStorageImportColumnResetDataAction(action.fileId)).pipe(
          map((result) => updateRowSuccess(result.payload)),
          catchError((err) => of(dataFailed(err))),
        ),
      ),
    ),
  );

  // region Delete Results

  public deleteResults$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteResultsAction),
      concatLatestFrom(() => [
        this.store.select(getDeleteResultsParams),
        this.store.select(getFileHash).pipe(filterNil()),
        this.store.select(selectUrl),
      ]),
      mergeMap(([, params, fileHash, currentUrl]) =>
        this.emit<CrudResponse>(
          new DeleteResultsAction(this.modulesToRemoveResults(params, currentUrl)),
          'Information',
          'Results are being deleted',
          true, // this is mandatory to force this Activity Overlay to be shown (see: PC-5260)
          800, // as it is always shown, 800ms gives a user chance to read a message
        ).pipe(
          mergeMap(() => [
            deleteResultsSuccessAction({
              fileHash,
              deleteResultsFilter: this.modulesToRemoveResults(params, currentUrl),
            }),
          ]),
          catchError((err) => of(dataFailed(err))),
        ),
      ),
    ),
  );

  public deleteAllResults$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteAllResultsAction),
      concatLatestFrom(() => [this.store.select(getUiState), this.store.select(getFileHash).pipe(filterNil())]),
      mergeMap(([, uiState, fileHash]) =>
        this.emit<CrudResponse>(new DeleteAllResultsAction(), 'Information', 'Results are being deleted').pipe(
          mergeMap(() => [deleteResultsSuccessAction({ fileHash, deleteResultsFilter: {} })]),
          catchError((err) => of(dataFailed(err))),
        ),
      ),
    ),
  );

  public deleteResultsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteResultsSuccessAction),
      concatLatestFrom(() => [
        this.store.select(getCurrentScenarioId),
        this.store.select(getCurrentRangeId),
        this.store.select(getUiState),
        this.store.select(getFileHash).pipe(filterNil()),
      ]),
      tap(([action, currentScenarioId, currentRangeId, uiState, fileHash]) => {
        if (
          action.fileHash === fileHash &&
          (action.deleteResultsFilter.scenarioIds == null || action.deleteResultsFilter.scenarioIds.includes(currentScenarioId))
        ) {
          if (
            (isSimulateBased(uiState.appModuleType) || uiState.appModuleType === ModuleType.Evaluate) &&
            (uiState.appModuleSection === 'results' || uiState.appModuleSection === 'reporting')
          ) {
            this.routerHelperService
              .navigateToScenarioBasedPage(uiState.appModuleType, [RouteModuleWell, RouteModuleWellGeneralData], null)
              .then();
          } else if (uiState.appModuleType === ModuleType.Trend_Analysis && uiState.appModuleSection === 'reporting') {
            this.routerHelperService.navigateToScenarioBasedPage(uiState.appModuleType, [RouteModuleData, RouteModuleDataGrid], null).then();
          }
        }
      }),
      map(([action, currentScenarioId, currentRangeId, uiState]) =>
        removeDataResultsFromStoreIfNeeded({
          fileHash: action.fileHash,
          deleteResultsFilter: action.deleteResultsFilter,
          currentData: { currentAppModuleType: uiState.appModuleType, currentScenarioId, currentRangeId },
        }),
      ),
    ),
  );

  // endregion

  public vacuumDb$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(vacuumDbAction),
        concatLatestFrom(() => this.store.select(getIsUndoRedoHistoryEmpty)),
        mergeMap(([action, isUndoRedoHistoryEmpty]) => {
          return this.emit<CheckIfVacuumNeededResponse>(new CheckIfVacuumNeeded()).pipe(
            map(async (response) => {
              const shouldVacuum = await this.modalService.showConfirm(
                'File will be shrunk, so you will no longer be able to undo/redo changes made before this point.' +
                  ' Would you still like to do this now?',
                'Shrink File',
              );

              if (shouldVacuum) {
                this.store.dispatch(undoRedoClearHistoryAction());
                await firstValueFrom(this.emitVacuum(true));
              }
            }),
            catchError((err) => of(dataFailed(err))),
          );
        }),
      );
    },
    { dispatch: false },
  );

  public onCheckIfVacuumNeededAndCloseFile$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(checkIfVacuumNeededAndCloseFile),
        mergeMap(() =>
          this.emit<CheckIfVacuumNeededResponse>(new CheckIfVacuumNeeded()).pipe(
            map(async (response) => {
              if (response.payload.isVacuumNeeded) {
                const shouldVacuum = await this.showOptimizeConfirm();
                if (shouldVacuum) {
                  await firstValueFrom(this.emitVacuum(true));
                }
              }
              this.store.dispatch(dbDisconnectAction());
            }),
            catchError(() => of(actions.dbConnectionFailedAction)),
          ),
        ),
      ),
    { dispatch: false },
  );

  public async vacuumIfNeededAsync(): Promise<void> {
    const response = await this.wsService.emitAsync<CheckIfVacuumNeededResponse>(this.moduleName, this.isDbDependent, new CheckIfVacuumNeeded());

    if (response.payload.isVacuumNeeded) {
      const isConfirmed = await this.showOptimizeConfirm();
      if (isConfirmed) {
        await firstValueFrom(this.emitVacuum(true));
      }
    }
  }

  private emitVacuum(forceVacuum = false): Observable<WsActionResponse<unknown>> {
    return this.emit(new VacuumDbAction(forceVacuum), 'Information', 'Optimizing project file...');
  }

  private async showOptimizeConfirm(): Promise<boolean> {
    return await this.modalService.showConfirm(
      'File size may be reduced but the operation could take up to several minutes. <br>' + 'Would you like to do this now?',
      'Shrink File',
    );
  }

  protected override onIncomingMessage(action: DataStorageModuleActions, fileHash: string | undefined): void {
    if (action.type === DataStorageModuleActionTypes.DeleteResultsSuccess) {
      this.store.dispatch(
        deleteResultsSuccessAction({
          fileHash: fileHash as string,
          deleteResultsFilter: action.deleteResultsFilter,
        }),
      );
    }
  }

  private modulesToRemoveResults(params: IDeleteResultsParams, url: string): DeleteResultsFilter {
    if (
      this.routerHelperService.isPageCurrentlyVisible(url, RouteModuleWell) ||
      this.routerHelperService.isPageCurrentlyVisible(url, RouteModuleCompletion) ||
      this.routerHelperService.isPageCurrentlyVisible(url, RouteModuleSettings, RouteModuleSettingsProject)
    ) {
      return { moduleTypes: LONG_CALCULATION_WITH_SIMULATION_RESULTS_MODULE_TYPES, scenarioIds: [params.scenarioId] };
    }

    if (
      this.routerHelperService.isPageCurrentlyVisible(url, RouteModuleFluids) ||
      this.routerHelperService.isPageCurrentlyVisible(url, RouteModuleGravels)
    ) {
      return {
        moduleTypes: [
          ModuleType.Simulate,
          ModuleType.Simulate_CH,
          ModuleType.Simulate_Disp,
          ModuleType.Evaluate,
          ModuleType.Friction_Calc,
          ModuleType.Settling_Calc,
          ModuleType.Resuspension_Calc,
          ModuleType.MASP_Calc,
        ],
        scenarioIds: [params.scenarioId],
      };
    }

    if (this.routerHelperService.isPageCurrentlyVisible(url, RouteModulePumping)) {
      return {
        moduleTypes: [ModuleType.Simulate, ModuleType.Simulate_CH, ModuleType.Simulate_Disp, ModuleType.Evaluate],
        scenarioIds: [params.scenarioId],
        rangeIds: [params.rangeId],
      };
    }

    if (this.routerHelperService.isPageCurrentlyVisible(url, RouteModuleData)) {
      return { moduleTypes: [ModuleType.Evaluate, ModuleType.Trend_Analysis] };
    }

    if (this.routerHelperService.isPageCurrentlyVisible(url, RouteModuleTrendAnalysis)) {
      return { moduleTypes: [ModuleType.Trend_Analysis], scenarioIds: [params.scenarioId], rangeIds: [params.rangeId] };
    }

    return { moduleTypes: [params.moduleType], scenarioIds: [params.scenarioId], rangeIds: [params.rangeId] };
  }
}
