import { createSelector } from '@ngrx/store';
import { SettingsValidation } from '@dunefront/common/modules/settings/model/settings.validation';
import { ValidatedDeveloperSettings } from '@dunefront/common/modules/settings/dto/settingsDto';
import { getSettingsModuleState } from './settings.selectors';
import { getMaxLowerCompletionBottomMD } from '../completion/max-lower-completion-bottom-md.selector';
import { getReferenceVariables } from '../reference-variables/reference-variables.selectors';
import { selectCurrentUnitSystem } from '../units/units.selectors';
import { getCurrentAppModuleType } from '../ui/ui.selectors';

export const getValidatedSettings = createSelector(
  getSettingsModuleState,
  getMaxLowerCompletionBottomMD,
  getReferenceVariables,
  selectCurrentUnitSystem,
  getCurrentAppModuleType,
  (...[state, maxLowerCompletionBottomMD, referenceVariables, unitSystem, currentModuleType]) =>
    SettingsValidation.validate(state.settings, maxLowerCompletionBottomMD, referenceVariables, currentModuleType, unitSystem),
);
export const getValidatedDeveloperSettings = createSelector(
  getSettingsModuleState,
  (state): ValidatedDeveloperSettings => ({ ...state.developerSettings, error: {}, isLoaded: true, isValid: true }),
);
export const getSettingsDefaults = createSelector(getSettingsModuleState, (state) => state.defaults);
export const getDeveloperSettingsDefaults = createSelector(getSettingsModuleState, (state) => state.developerSettingsDefaults);
export const getValidatedSettingsState = createSelector(
  getValidatedSettings,
  getSettingsDefaults,
  getDeveloperSettingsDefaults,
  (...[settings, defaults, developerSettingsDefaults]) => ({
    settings: settings,
    defaults: defaults,
    developerSettingsDefaults: developerSettingsDefaults,
  }),
);
export const getIsSettingsStateLoaded = createSelector(getValidatedSettings, (state) => state.isLoaded);
export const getShearRate = createSelector(getValidatedSettings, (state) => state.ShearRate);
export const getSimulationAnimationWellLayout = createSelector(getValidatedSettings, (state) => state.SimulationAnimationWellLayout);
export const getWellVisualizationWellLayout = createSelector(getValidatedSettings, (state) => state.WellVisualizationWellLayout);
export const getWellVisualizationWellPart = createSelector(getValidatedSettings, (state) => state.WellVisualizationWellPart);
