<div class="masp-calculator-container" *ngIf="leakoffCoefficientCalculator.isLoaded">
  <app-page-panel header="Input Data" (click)="onHelpChange('calculators', 'leakoff-coefficient-input-data')">
    <div class="grid-form-container cols-2">
      <app-form-input
        class="grid-two-one"
        appUnwrap
        label="Reservoir Compressibility"
        [source]="leakoffCoefficientCalculator"
        [key]="'ReservoirCompressibility'"
        [decimalPlaces]="6"
        [unitType]="UnitType.Compressibility"
        (valueChanged)="submitCalcData($event)"
        dataCy="leakoff-calculator-compressibility"
      >
      </app-form-input>
      <app-form-input
        class="grid-two-one"
        appUnwrap
        label="Reservoir Porosity"
        [source]="leakoffCoefficientCalculator"
        [key]="'ReservoirPorosity'"
        [decimalPlaces]="2"
        [unitType]="UnitType.None"
        [unitLabel]="'%'"
        (valueChanged)="submitCalcData($event)"
        dataCy="leakoff-calculator-porosity"
      >
      </app-form-input>
      <app-form-input
        class="grid-two-one"
        appUnwrap
        label="Permeability to Reservoir Fluid"
        [source]="leakoffCoefficientCalculator"
        [key]="'PermeabilityToReservoirFluid'"
        [decimalPlaces]="2"
        [unitType]="UnitType.Permeability"
        (valueChanged)="submitCalcData($event)"
        dataCy="leakoff-calculator-permeability-reservoir"
      >
      </app-form-input>
      <app-form-input
        class="grid-two-one"
        appUnwrap
        label="Permeability to Injected Fluid"
        [source]="leakoffCoefficientCalculator"
        [key]="'PermeabilityToInjectedFluid'"
        [decimalPlaces]="2"
        [unitType]="UnitType.Permeability"
        (valueChanged)="submitCalcData($event)"
        dataCy="leakoff-calculator-permeability-injected"
      >
      </app-form-input>
      <app-form-input
        class="grid-two-one"
        appUnwrap
        label="Reservoir Fluid Viscosity"
        [source]="leakoffCoefficientCalculator"
        [key]="'ReservoirFluidViscosity'"
        [decimalPlaces]="3"
        [unitType]="UnitType.Viscosity"
        (valueChanged)="submitCalcData($event)"
        dataCy="leakoff-calculator-viscosity-reservoir"
      >
      </app-form-input>
      <app-form-input
        class="grid-two-one"
        appUnwrap
        label="Injected Fluid Viscosity"
        [source]="leakoffCoefficientCalculator"
        [key]="'InjectedFluidViscosity'"
        [decimalPlaces]="3"
        [unitType]="UnitType.Viscosity"
        (valueChanged)="submitCalcData($event)"
        dataCy="leakoff-calculator-viscosity-injected"
      >
      </app-form-input>
      <app-form-input
        class="grid-two-one"
        appUnwrap
        label="Pressure Differential"
        [source]="leakoffCoefficientCalculator"
        [key]="'PressureDifferential'"
        [decimalPlaces]="2"
        [unitType]="UnitType.Pressure"
        (valueChanged)="submitCalcData($event)"
        dataCy="leakoff-calculator-pressure-differential"
      >
      </app-form-input>
    </div>
  </app-page-panel>
  <app-page-panel class="panel-container" header="Results" (click)="onHelpChange('calculators', 'leakoff-coefficient-results')">
    <div class="grid-form-container cols-2">
      <app-form-input
        class="grid-two-one"
        appUnwrap
        label="Viscosity Controlled Leakoff (Cv)"
        [disabled]="true"
        [source]="leakoffCoefficientCalculator"
        [key]="'ViscosityControlledLeakoff'"
        [decimalPlaces]="4"
        [unitType]="UnitType.Fluid_Loss_Coefficient"
      >
      </app-form-input>
      <app-form-input
        class="grid-two-one"
        appUnwrap
        label="Compressible Controlled Leakoff (Cc)"
        [disabled]="true"
        [source]="leakoffCoefficientCalculator"
        [key]="'CompressibilityControlledLeakoff'"
        [decimalPlaces]="4"
        [unitType]="UnitType.Fluid_Loss_Coefficient"
      >
      </app-form-input>
      <app-form-input
        class="grid-two-one"
        appUnwrap
        label="Combined Leakoff Coefficient(Ccv)"
        [disabled]="true"
        [source]="leakoffCoefficientCalculator"
        [key]="'CombinedLeakoffCoefficient'"
        [decimalPlaces]="4"
        [unitType]="UnitType.Fluid_Loss_Coefficient"
      >
      </app-form-input>
    </div>
  </app-page-panel>
</div>
