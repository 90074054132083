import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';
import { ModalService } from '../../modals/modal.service';
import { selectUserGlobalOptions } from '../../../+store/common-db/common-db.selectors';
import { deleteChartMarker, insertChartMarker, updateChartMarker } from '../../../+store/reporting/reporting.actions';
import { ChartMarkerComponent, ChartMarkerDialogResult } from '../chart-marker/chart-marker.component';
import { MarkersServiceBase } from './markers-service-base';
import { IMarker, IMarkerStyle } from '@dunefront/common/modules/reporting/dto/chart-marker.dto';
import { CreateMarkerPayload } from '../chart-component-helpers/chart-marker-helpers';
import { ArgConverter, ChartAddonsWithConverter } from '../chart-component-helpers/chart-addons-helpers';
import { MarkerMode } from '../chart-controller.component';

@Injectable()
export class ChartUserMarkersService extends MarkersServiceBase {
  private defaultMarkerStyle!: IMarkerStyle;
  private chartId?: number;
  private _markers$ = new BehaviorSubject<IMarker[]>([]);
  private toPrimaryArgConverter: ArgConverter | undefined;

  constructor(
    private store: Store,
    private modalService: ModalService,
    addonsWithConverter$: Observable<ChartAddonsWithConverter | undefined>,
  ) {
    super(MarkerMode.UserMarkers);

    this.subscription.add(
      this.store.select(selectUserGlobalOptions).subscribe((globalOptions) => (this.defaultMarkerStyle = globalOptions)),
    );

    this.subscription.add(
      addonsWithConverter$.subscribe((addonsWithConverter) => {
        let newMarkers = addonsWithConverter?.chartUserAddons?.chartMarkers;
        const toSecondaryArgConverter = addonsWithConverter?.toSecondaryArgConverter;

        // convert to secondary argument if possible and needed
        if (newMarkers != null && newMarkers.length > 0 && toSecondaryArgConverter != null) {
          const result: IMarker[] = [];

          for (const marker of newMarkers) {
            if (marker.isValueAxisMarker) {
              // marker on value axis - no conversion needed, add it as is
              result.push({
                ...marker,
                value: marker.value,
              });
            } else {
              // marker on argument axis, if possible - convert and add to list
              result.push({
                ...marker,
                value: toSecondaryArgConverter(marker.value),
              });
            }
          }

          newMarkers = result;
        }

        this.toPrimaryArgConverter = addonsWithConverter?.toPrimaryArgConverter;
        this.chartId = addonsWithConverter?.chartId;
        this._markers$.next(newMarkers ?? []);
      }),
    );
  }

  public get markers$(): Observable<IMarker[]> {
    return this._markers$.asObservable();
  }

  public get disableAddingMarkers(): boolean {
    return false;
  }

  public override onEditMarker(marker: IMarker, isChartRotated: boolean): void {
    this.showChartMarkerDialog(marker, true, isChartRotated).then();
  }

  public override onCreateMarker(payload: CreateMarkerPayload, scenarioId: number, isChartRotated: boolean): void {
    if (this.chartId == null) {
      throw new Error("ChartId can't be undefined!");
    }

    const { value, isValueAxisMarker } = payload;

    const marker: IMarker = {
      id: -1,
      chartId: this.chartId,
      scenarioId,
      name: '',
      value,
      isValueAxisMarker,
      isOverrideStyle: false,
      style: { ...this.defaultMarkerStyle },
    };

    this.showChartMarkerDialog(marker, false, isChartRotated).then();
  }

  public onMarkerMoved(secondaryArgMarker: IMarker): void {
    const marker = this.toPrimaryArgMarker(secondaryArgMarker);
    this.store.dispatch(updateChartMarker(marker));
  }

  private async showChartMarkerDialog(displayMarker: IMarker, allowDelete: boolean, isChartRotated: boolean): Promise<void> {
    const dialogRef = this.modalService.open(ChartMarkerComponent, {
      marker: displayMarker,
      defaultMarkerStyle: this.defaultMarkerStyle,
      isChartRotated,
      allowDelete,
    });

    const result = await firstValueFrom<ChartMarkerDialogResult | undefined>(dialogRef.onClose);
    if (!result) {
      return;
    }

    const secondaryArgMarker = result.value;
    const marker = this.toPrimaryArgMarker(secondaryArgMarker);

    if (marker.id == null || marker.scenarioId == null) {
      return;
    }

    switch (result.action) {
      case 'ok':
        this.store.dispatch(marker.id === -1 ? insertChartMarker(marker) : updateChartMarker(marker));
        break;
      case 'delete':
        this.store.dispatch(
          deleteChartMarker({
            rowIds: [marker.id],
            shouldResetResults: false,
            scenarioId: marker.scenarioId,
          }),
        );
        break;
    }
  }

  private toPrimaryArgMarker(marker: IMarker): IMarker {
    return {
      ...marker,
      value: marker.isValueAxisMarker || this.toPrimaryArgConverter == null ? marker.value : this.toPrimaryArgConverter(marker.value),
    };
  }
}
