import { IChartResultColumn } from './reporting.selectors';
import { ITableRow } from '@dunefront/common/common/common-grid.interfaces';
import { ImportColumnDto, ImportColumnGroupType } from '@dunefront/common/modules/data-storage/dto/import-column.dto';
import { UnitConverterHelper } from '@dunefront/common/unit-converters/unit.converter.helper';
import { ChartUpdateType } from './reporting.actions';
import { ChartDataSourceType } from '@dunefront/common/modules/reporting/dto/chart.dto';
import { IChartConfig, ReportingModuleState } from './reporting-module.state';
import { ImportFileDto } from '@dunefront/common/modules/data-storage/dto/import-file.dto';
import { ReportingFactory } from './model/reporting.factory';
import { ReportingColumnDto } from '@dunefront/common/dto/reporting-column.dto';
import { ChartSeriesDto } from '@dunefront/common/modules/reporting/dto/chart-series.dto';
import { DataFileType, DataType } from '@dunefront/common/dto/data-storage';
import { GetReportingColumnsActionResponse } from '@dunefront/common/modules/reporting/reporting-module.actions';
import { ChartAxis } from '@dunefront/common/modules/reporting/dto/chart-axis-property.dto';
import { IUpdateTableRowsProps } from '@dunefront/common/common/common-store-crud.interfaces';
import { IDictionaryWithArray } from '@dunefront/common/common/state.helpers';
import { RangeDto } from '@dunefront/common/dto/range.dto';
import { formatFilename } from '../data-storage/data-storage.selectors';

export class ReportingChartConfigModuleReducerHelper {
  public static getChartReportingColumnsSuccess(
    state: ReportingModuleState,
    result: GetReportingColumnsActionResponse,
    ranges: IDictionaryWithArray<RangeDto>,
  ): ReportingModuleState {
    const workingState = { ...state };
    workingState.chartConfig = this.getChartConfigFromReportingColumnsAndChart(
      workingState,
      result.resultColumns,
      result.importFiles,
      result.importColumns,
      result.chartId,
      result.chartSeries,
      ChartDataSourceType.ChartSourceReportingTab,
      ranges,
    );
    return workingState;
  }

  public static getChartConfigFromReportingColumnsAndChart(
    workingState: ReportingModuleState,
    resultColumns: ReportingColumnDto[] | undefined,
    importFiles: ImportFileDto[] | undefined,
    importColumns: ImportColumnDto[] | undefined,
    chartId: number | undefined,
    chartSeries: ChartSeriesDto[],
    chartDataSourceType: ChartDataSourceType,
    ranges: IDictionaryWithArray<RangeDto>,
  ): IChartConfig {
    const tabId = chartId != null ? workingState.selectedReportingTabId : undefined;

    if (chartDataSourceType === undefined) {
      return ReportingFactory.getEmptyChartConfig();
    }

    // Calculation Engine Results
    const workingResultColumns: IWorkingColumn[] = (resultColumns ?? [])
      .slice()
      .sort((a, b) => a.GroupType - b.GroupType)
      .map((resultColumn) => ({
        ColumnName: resultColumn.ColumnName,
        GroupName: ImportColumnGroupType[resultColumn.GroupType].replace('_', ' '),
        DataType: resultColumn.DataType,
        IsImportData: false,
        FileType: resultColumn.FileType,
        FileName: '',
      }));

    // Import Data
    const workingImportColumns: IWorkingColumn[] = (importColumns ?? [])
      .slice()
      .sort((a, b) => a.GroupType - b.GroupType)
      .map((importColumn) => {
        const importFile = (importFiles ?? []).find((importFile) => importFile.Id === importColumn.FileId);

        const fileType = importFile?.FileType ?? DataFileType.ImportedData;
        const formatedFile = importFile ? formatFilename(importFile, ranges) : undefined;
        const fileName = formatedFile?.FileName ?? '';
        return {
          ColumnName: importColumn.ColumnName,
          GroupName: fileName,
          DataType: importColumn.DataType,
          IsImportData: true,
          FileType: fileType,
          FileName: fileName,
        };
      });

    const rows = [...workingResultColumns, ...workingImportColumns].map((col, index) => {
      const chartResultsColumn: IChartResultColumn = {
        ...col,
        Id: index,
        PrimaryLeft: false,
        SecondaryLeft: false,
        PrimaryRight: false,
        SecondaryRight: false,
        DataType: col.DataType,
        IsImportData: col.IsImportData,
        FileType: col.FileType,
        FileName: col.FileName,
      };

      const thisChartSeries = chartSeries.find((series) => series.ColumnName === col.ColumnName && series.FileType === col.FileType);
      if (thisChartSeries) {
        switch (thisChartSeries.AxisType) {
          case ChartAxis.PrimaryValue:
            chartResultsColumn.PrimaryLeft = true;
            break;
          case ChartAxis.SecondaryValue:
            chartResultsColumn.SecondaryLeft = true;
            break;
          case ChartAxis.OppositePrimaryValue:
            chartResultsColumn.PrimaryRight = true;
            break;
          case ChartAxis.OppositeSecondaryValue:
            chartResultsColumn.SecondaryRight = true;
            break;
          default:
            break;
        }
      }

      const row: ITableRow<IChartResultColumn> = {
        rowType: 'data',
        rowData: chartResultsColumn,
        isEditingDisabled: false,
        isValid: true,
        error: {},
        rowIndex: index,
      };
      return row;
    });

    return { chartId, chartDataSourceType, tabId, resultColumnsTableState: { rows, isValid: true } };
  }

  public static updatedChartResultColumn(
    state: ReportingModuleState,
    props: IUpdateTableRowsProps<IChartResultColumn>,
  ): ReportingModuleState {
    const rowData = { ...props.rows[0].rowData };
    if (props.colIds === undefined) {
      return state;
    }
    if (props.colIds.find((col) => col === 'PrimaryLeft') && rowData.PrimaryLeft) {
      rowData.SecondaryLeft = false;
      rowData.PrimaryRight = false;
      rowData.SecondaryRight = false;
    } else if (props.colIds.find((col) => col === 'SecondaryLeft') && rowData.SecondaryLeft) {
      rowData.PrimaryLeft = false;
      rowData.PrimaryRight = false;
      rowData.SecondaryRight = false;
    } else if (props.colIds.find((col) => col === 'PrimaryRight') && rowData.PrimaryRight) {
      rowData.PrimaryLeft = false;
      rowData.SecondaryLeft = false;
      rowData.SecondaryRight = false;
    } else if (props.colIds.find((col) => col === 'SecondaryRight') && rowData.SecondaryRight) {
      rowData.PrimaryLeft = false;
      rowData.SecondaryLeft = false;
      rowData.PrimaryRight = false;
    }

    const rows = [...state.chartConfig.resultColumnsTableState.rows];
    rows[props.rows[0].rowIndex] = { ...props.rows[0], rowData };
    return {
      ...state,
      chartConfig: {
        ...state.chartConfig,
        resultColumnsTableState: { rows, isValid: true },
      },
    };
  }

  public static setChartResultColumns(state: ReportingModuleState, updateType: ChartUpdateType): ReportingModuleState {
    const rows = state.chartConfig.resultColumnsTableState.rows.map((row) => {
      const updatedRow = { ...row, rowData: { ...row.rowData } };
      updatedRow.rowData.PrimaryLeft = false;
      updatedRow.rowData.SecondaryLeft = false;
      updatedRow.rowData.PrimaryRight = false;
      updatedRow.rowData.SecondaryRight = false;
      const unitSystem = UnitConverterHelper.getUnitSystemFromDataType(updatedRow.rowData.DataType);
      const unitDetails = UnitConverterHelper.getUnitTypeAndName(updatedRow.rowData.DataType, unitSystem);
      switch (updateType) {
        case ChartUpdateType.clear:
          // already set new row to clear
          break;
        case ChartUpdateType.gaugeDataDefault:
          switch (true) {
            case unitDetails.IsPressure:
              updatedRow.rowData.PrimaryLeft = true;
              break;
            case unitDetails.IsBottomHoleTemperature:
              updatedRow.rowData.SecondaryLeft = true;
              break;
            case unitDetails.IsFlowRate:
              updatedRow.rowData.PrimaryRight = true;
              break;
            case unitDetails.IsGravelConcentration:
              updatedRow.rowData.SecondaryRight = true;
              break;
          }
          break;
        case ChartUpdateType.pressureOnly:
          if (unitDetails.IsPressure) {
            updatedRow.rowData.PrimaryLeft = true;
          }
          break;
        case ChartUpdateType.bottomHolePressureOnly:
          if (unitDetails.IsBottomHolePressure) {
            updatedRow.rowData.PrimaryLeft = true;
          }
          break;
        case ChartUpdateType.rateOnly:
          if (unitDetails.IsFlowRate) {
            updatedRow.rowData.PrimaryLeft = true;
          }
          break;
        case ChartUpdateType.surfacePressureOnly:
          if (unitDetails.IsSurfacePressure) {
            updatedRow.rowData.PrimaryLeft = true;
          }
          break;
      }
      return updatedRow;
    });

    return {
      ...state,
      chartConfig: {
        ...state.chartConfig,
        resultColumnsTableState: { rows, isValid: true },
      },
    };
  }
}

interface IWorkingColumn {
  ColumnName: string;
  GroupName: string;
  DataType: DataType;
  IsImportData: boolean;
  FileType: DataFileType;
  FileName: string;
}
