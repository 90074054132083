import { UnitConverter } from '../../converter.interfaces';
import { ViscosityUnit } from '../../../dto/unit-system.dto';

export class ViscosityConverter extends UnitConverter {
  public static symbols: Array<string> = ['', 'Pa·s', 'cP', 'P'];

  public static unit = ViscosityUnit;

  public static fromSi(value: number, unit: ViscosityUnit): number {
    switch (unit) {
      case ViscosityUnit.Pascal_second: {
        return value;
      }
      case ViscosityUnit.Centipoise: {
        return value * 1e3;
      }
      case ViscosityUnit.Poise: {
        return value * 10;
      }

      default: {
        throw new TypeError('Unknown Viscosity Unit:' + unit);
      }
    }
  }

  public static toSi(value: number, unit: ViscosityUnit): number {
    switch (unit) {
      case ViscosityUnit.Pascal_second: {
        return value;
      }
      case ViscosityUnit.Centipoise: {
        return value / 1e3;
      }
      case ViscosityUnit.Poise: {
        return value / 10;
      }
      default: {
        throw new TypeError('Unknown Viscosity Unit:' + unit);
      }
    }
  }

  public static getSymbol(unit: ViscosityUnit): string {
    return this.symbols[unit];
  }
}
