import { Routes } from '@angular/router';
import { DemoFilesComponent } from './file-list-component/demo-files-component/demo-files.component';
import { SharedFilesComponent } from './file-list-component/shared-files-component/shared-files.component';
import { PersonalFilesComponent } from './file-list-component/personal-files-component/personal-files.component';
import { TrashFilesComponent } from './file-list-component/trash-files-component/trash-files.component';
import { isElectron } from '@dunefront/common/common/electron/is-electron';
import { ElectronDemoFilesComponent } from './file-list-component/electron-demo-files-component/electron-demo-files.component';
import { RecentFilesComponent } from './file-list-component/recent-files-component/recent-files.component';
import {
  RouteModuleHomeDemo,
  RouteModuleHomeFileOptions,
  RouteModuleHomePersonal,
  RouteModuleHomeShared,
  RouteModuleHomeTrash,
} from './home-page-routes-names';
import { HomeOptionsComponent } from '../common/settings/file-options/home-options.component';

export const homePageRoutes: Routes = [
  {
    path: RouteModuleHomePersonal,
    component: PersonalFilesComponent,
  },
  {
    path: `${RouteModuleHomePersonal}/:path`,
    component: PersonalFilesComponent,
  },
  {
    path: RouteModuleHomeShared,
    component: SharedFilesComponent,
  },
  {
    path: `${RouteModuleHomeShared}/:path`,
    component: SharedFilesComponent,
  },
  {
    path: RouteModuleHomeDemo,
    component: isElectron() ? ElectronDemoFilesComponent : DemoFilesComponent,
  },
  {
    path: RouteModuleHomeTrash,
    component: TrashFilesComponent,
  },
  {
    path: RouteModuleHomeFileOptions,
    component: HomeOptionsComponent,
  },
  {
    path: '',
    component: RecentFilesComponent,
  },
];
