import { createAction, props } from '@ngrx/store';
import { ValidatedFrictionCalculator } from '@dunefront/common/modules/calculators/friction-calculator/friction-calculator-module.state';
import { IUpdateRowsProps } from '@dunefront/common/common/common-store-crud.interfaces';

export const updateFrictionCalculatorRow = createAction(
  '[calculators] updateFrictionCalculatorRow',
  props<IUpdateRowsProps<ValidatedFrictionCalculator>>(),
);

export const calculateFriction = createAction('[calculators] calculateFriction', props<{ ScenarioId: number }>());
