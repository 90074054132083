<ng-container *ngIf="verticalShifts$ | async as verticalShifts; else waiting">
  <ng-container *ngIf="axisPropertiesService.axisProperties$ | async as axisProperties; else waiting">
    <ng-container *ngIf="markersService?.markers$ | async as markers; else waiting">
      <ng-container *ngIf="annotationsService?.annotations$ | async as annotations; else waiting">
        <ng-container *ngIf="gradientLinesService?.gradientLines$ | async as gradientLines; else waiting">
          <ng-container *ngIf="chartData$ | async as chartData; else waiting">
            <ng-container *ngIf="shouldDisplayChart$ | async; else waiting">
              <app-chart
                [chartName]="chartDisplayName"
                [drawableProviderId]="drawableProviderId ?? chartData.ChartId.toString()"
                [chartData]="chartData"
                [allowRecording]="allowRecording"
                [chartDto]="chartDto$ | async"
                [chartZoomData]="chartZoomData$ | async"
                [disableAddingMarkers]="markersService?.disableAddingMarkers ?? false"
                [isRotated]="rotateChart"
                [reverseArgument]="reverseArgument"
                [storageFiles]="storageFiles$ | async"
                (argumentRangeChanged)="onArgumentRangeChanged($event)"
                (initialArgumentRange)="onInitialArgumentRange($event)"
                (updateXAxisShift)="horizontalShiftService.onUpdateXAxisShift($event)"
                (updateYAxisShift)="verticalShiftService.onUpdateYAxisShift($event)"
                (editMarker)="markersService?.onEditMarker($event, rotateChart)"
                (createMarker)="markersService?.onCreateMarker($event, currentScenarioId, rotateChart)"
                (markerMoved)="markersService?.onMarkerMoved($event)"
                (autoYAxisShift)="onAutoYShift($event)"
                (autoXAxisShift)="autoShiftService.onXAutoShift($event)"
                (createAnnotation)="annotationsService.onCreateAnnotation($event, currentScenarioId)"
                (editAnnotation)="annotationsService.onEditAnnotation($event)"
                (annotationsUpdated)="annotationsService.onAnnotationsUpdated($event)"
                (keyboardDeleteAnnotation)="annotationsService.onKeyboardDeleteAnnotation($event)"
                (createGradientLine)="gradientLinesService.onCreateGradientLine($event, currentScenarioId)"
                (editGradientLine)="gradientLinesService.onEditGradientLine($event)"
                (gradientLinesUpdated)="gradientLinesService.onGradientLinesUpdated($event)"
                (keyboardDeleteGradientLine)="gradientLinesService.onKeyboardDeleteGradientLine($event)"
                (configureAxis)="axisPropertiesService.onConfigureAxis($event, currentScenarioId)"
                (configureLegend)="chartLegendPropertiesService.onConfigureLegend($event)"
                (configureSeries)="chartSeriesTemplatesService.onConfigureSeries($event, currentUnitSystem)"
                (isZoomed)="isZoomed = $event"
                [markers]="markers"
                [annotations]="annotations"
                [gradientLines]="gradientLines"
                [axesProperties]="axisProperties"
                [allowedXYShiftAxis]="allowedChangingXYShift"
                [allowCompareScenarios]="allowCompareScenarios"
                [chartSeriesVisibilityManager]="chartSeriesVisibilityManager"
                [verticalShifts]="verticalShifts"
                [displayLegend]="displayLegend"
                [attr.data-cy]="dataCy"
                [tension]="tension"
              >
              </app-chart>
              <!-- eg: shows 'Loading...' when downloading high res data -->
              <app-chart-overlay-info data-cy="chart-overlay-info" *ngIf="chartOverlayText"
                                      [overlayText]="chartOverlayText"></app-chart-overlay-info>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #waiting>
  <app-chart-overlay-info *ngIf="waitingOverlayText$ | async as waitingOverlayText"
                          [overlayText]="waitingOverlayText"></app-chart-overlay-info>
</ng-template>

<!--this is for debug - should stay here-->
<!--<div class="debug-info">-->
<!--  <div>verticalShifts$</div><div>{{verticalShifts$ | async | json}}</div>-->
<!--  <div>axisProperties$</div><div>{{axisPropertiesService.axisProperties$ | async | json}}</div>-->
<!--  <div>markers$</div><div>{{markersService?.markers$ | async | json}}</div>-->
<!--  <div>chartData$</div><div>{{chartData$ | async | json}}</div>-->
<!--  <div>shouldDisplayChart$</div><div>{{shouldDisplayChart$ | async | json}}</div>-->
<!--</div>-->
