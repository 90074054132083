import { ChartLoadingStatus, ReportingFactory } from './model/reporting.factory';
import { DepthDataStatus } from '@dunefront/common/modules/reporting/dto/chart-data.dto';
import { CrudResponse } from '@dunefront/common/modules/common.actions';
import { DictionaryWithArray, IDictionaryWithArray } from '@dunefront/common/common/state.helpers';
import { ChartDataSourceType } from '@dunefront/common/modules/reporting/dto/chart.dto';
import { ArrayHelpers } from '@dunefront/common/common/array-helpers';
import {
  ChartState,
  IChartUserAddons,
  IDepthDataForRange,
  IDepthDataForScenario,
  ILowerCompletionRange,
  reportingInitialState,
  ReportingModuleState,
} from './reporting-module.state';
import {
  GetChartDataParams,
  GetChartDataRequestType,
  GetChartUserAddonsResultsActionResponse,
  GetDepthDataResultsActionResponse,
  IDepthBasedResult,
  IDepthBasedTime,
  LoadReportingActionResponse,
  ResultsSourceKey,
  TimeVolChartDataResponse,
} from '@dunefront/common/modules/reporting/reporting-module.actions';
import { CrosshairMode, TooltipPosition } from '@dunefront/common/modules/reporting/reporting.settings';
import { IDbConnectionOpenedResponse } from '@dunefront/common/modules/db-connection/db-connection.actions';
import { encodeTemplateId, sortTemplates } from '@dunefront/common/common/templates/template-parser';
import { IChartTemplateDto } from '@dunefront/common/dto/chart-templates.dto';
import { ActionResponse } from '@dunefront/common/response-ws.action';
import { defaultMaxSeriesInTooltip } from '@dunefront/common/common/constants';
import { ICommonDbInitialData } from '@dunefront/common/modules/common-db/common-db.actions';

export class ReportingModuleReducerHelper {
  public static onLoadScenarioDataSuccessAction(
    state: ReportingModuleState,
    response: ActionResponse<LoadReportingActionResponse>,
  ): ReportingModuleState {
    if (response.status !== 'ok' || !response.payload) {
      return reportingInitialState;
    }

    return {
      ...this.resetSelectedSimulationTime(state),
      reportingTabDtos: [...response.payload.reportingTabDtos].sort((t1, t2) => t1.SortOrder - t2.SortOrder),
      chartDtos: response.payload.chartDtos,
      chartState: { ...state.chartState },
    };
  }

  public static resetSelectedSimulationTime(state: ReportingModuleState): ReportingModuleState {
    return { ...state, selectedSimulationTime: Number.MAX_VALUE };
  }

  public static clearReportingChartData(state: ReportingModuleState): ReportingModuleState {
    return { ...state, chartState: ReportingFactory.getEmptyChartState() };
  }

  public static clearResultsChartsData(state: ReportingModuleState): ReportingModuleState {
    return {
      ...state,
      resultsTimeChartState: ReportingFactory.getEmptyChartState(),
      resultsEvaluatePressureChartState: ReportingFactory.getEmptyChartState(),
      resultsEvaluateFrictionChartState: ReportingFactory.getEmptyChartState(),
      resultsDepthChartState: ReportingFactory.getEmptyChartState(),
    };
  }

  public static getChartUserAddonsSuccess(
    state: ReportingModuleState,
    response: GetChartUserAddonsResultsActionResponse,
  ): ReportingModuleState {
    const annotations: IChartUserAddons = {
      chartId: response.chartId,
      chartMarkers: ReportingFactory.toIMarkers(response.chartMarkers),
      chartAxisProperties: ReportingFactory.toIAxesProps(response.chartAxisProperties),
      chartAnnotations: ReportingFactory.toIAnnotations(response.chartAnnotations),
      chartGradientLines: ReportingFactory.toIGradientLines(response.chartGradientLines),
    };
    return {
      ...state,
      chartUserAddonsDict: DictionaryWithArray.upsert(state.chartUserAddonsDict, annotations, 'chartId'),
    };
  }

  // region TimeVolChartData

  public static getTimeVolChartData(
    state: ReportingModuleState,
    getChartDataParams: GetChartDataParams,
    requestedReportingTabId: number | undefined,
    gaugeDataRequestedAfterFileImport: boolean,
  ): ReportingModuleState {
    const isInitial = getChartDataParams.requestType === GetChartDataRequestType.Initial;
    const chartState = isInitial
      ? { ...ReportingFactory.getEmptyChartState(), chartLoadingStatus: ChartLoadingStatus.loading }
      : { ...state.chartState, chartLoadingStatus: ChartLoadingStatus.loading };
    return {
      ...state,
      chartState,
      gaugeDataRequestedAfterFileImport: gaugeDataRequestedAfterFileImport,
      reportingTabsIdsRequested:
        requestedReportingTabId != null ? [...state.reportingTabsIdsRequested, requestedReportingTabId] : state.reportingTabsIdsRequested,
    };
  }

  public static getTimeVolChartDataSuccess(
    state: ReportingModuleState,
    response: TimeVolChartDataResponse,
    reportingTabId: number | undefined,
  ): ReportingModuleState {
    const workingChartState: ChartState = {
      ...ReportingFactory.getEmptyChartState(),
      chartDataSourceType: response.chartDataSourceType,
      chartData: response.chartDataDto,
      chartLoadingStatus: ChartLoadingStatus.loaded,
      chartSeries: response.chartSeries ?? state.chartState.chartSeries,
      requestType: response.requestType,
    };

    const reportingTabsIdsRequested = state.reportingTabsIdsRequested.filter((tabId) => tabId !== reportingTabId);

    switch (response.chartDataSourceType) {
      case ChartDataSourceType.ChartSourceReportingTab:
      case ChartDataSourceType.ChartSourceGaugeData:
      case ChartDataSourceType.ChartSourceTrendAnalysis: {
        return {
          ...state,
          chartState: workingChartState,
          reportingTabsIdsRequested,
        };
      }
      case ChartDataSourceType.ChartSourceResultsTimeBased:
      case ChartDataSourceType.ChartSourceFluidProPressureAndECD:
        return {
          ...state,
          resultsTimeChartState: workingChartState,
          reportingTabsIdsRequested,
        };
      case ChartDataSourceType.ChartSourceResultsDepthBased:
        return {
          ...state,
          resultsDepthChartState: workingChartState,
          reportingTabsIdsRequested,
        };
      case ChartDataSourceType.ChartSourceEvaluatePressure:
        return {
          ...state,
          resultsEvaluatePressureChartState: workingChartState,
          reportingTabsIdsRequested,
        };
      case ChartDataSourceType.ChartSourceEvaluateFriction:
        return {
          ...state,
          resultsEvaluateFrictionChartState: workingChartState,
          reportingTabsIdsRequested,
        };
      default:
        throw new Error('Unknown ChartDataSourceType');
    }
  }

  // endregion

  // region DepthData

  public static requestedDepthData(
    state: ReportingModuleState,
    lowerCompletionRange: ILowerCompletionRange,
    depthDataKey: ResultsSourceKey,
  ): ReportingModuleState {
    const depthDataForScenarios = ReportingModuleReducerHelper.updateDepthDataResultsStatus(
      { ...state.depthDataForScenarios },
      depthDataKey,
      DepthDataStatus.Requested,
    );

    return {
      ...state,
      depthDataForScenarios,
      lowerCompletionRange,
    };
  }

  public static getSinglePointDepthDataSuccess(state: ReportingModuleState, depthResults: IDepthBasedResult[]): ReportingModuleState {
    let depthDataForScenarios = { ...state.depthDataForScenarios };
    for (const depthResult of depthResults) {
      const depthDataForScenario = depthDataForScenarios.dict[depthResult.ScenarioId];
      if (!depthDataForScenario) {
        continue;
      }

      const depthDataForRange = depthDataForScenario.depthDataForRanges.dict[depthResult.RangeId];
      if (!depthDataForRange) {
        continue;
      }

      const depthDataForFileType = depthDataForRange.depthDataResults.dict[depthResult.FileType];
      if (!depthDataForFileType) {
        continue;
      }

      const updatedTimes = [...depthDataForFileType.Times];
      const updatedLoadedDataPoints = [...depthDataForRange.loadedDataPoints];

      for (const newDepthBasedTime of depthResult.Times) {
        ArrayHelpers.insertOrdered<IDepthBasedTime>(updatedTimes, newDepthBasedTime, (a, b) => b.Time - a.Time);

        if (!updatedLoadedDataPoints.includes(newDepthBasedTime.Time)) {
          ArrayHelpers.insertOrdered<number>(updatedLoadedDataPoints, newDepthBasedTime.Time, (a, b) => b - a);
        }
      }

      const updatedDepthDataForFileType: IDepthBasedResult = {
        ...depthDataForFileType,
        Times: updatedTimes,
      };

      const updatedDepthDataForRange: IDepthDataForRange = {
        ...depthDataForRange,
        depthDataResults: DictionaryWithArray.upsert(depthDataForRange.depthDataResults, updatedDepthDataForFileType, 'FileType'),
        loadedDataPoints: updatedLoadedDataPoints,
      };

      const updatedDepthDataForScenario: IDepthDataForScenario = {
        ...depthDataForScenario,
        depthDataForRanges: DictionaryWithArray.upsert(depthDataForScenario.depthDataForRanges, updatedDepthDataForRange, 'rangeId'),
      };

      depthDataForScenarios = DictionaryWithArray.upsert(depthDataForScenarios, updatedDepthDataForScenario, 'scenarioId');
    }

    return {
      ...state,
      depthDataForScenarios,
    };
  }

  public static getDepthDataSuccess(
    state: ReportingModuleState,
    depthDataKey: ResultsSourceKey,
    response: GetDepthDataResultsActionResponse,
  ): ReportingModuleState {
    let depthDataForScenarios = { ...state.depthDataForScenarios };
    for (const depthResults of response.DepthResults) {
      let depthDataForScenario: IDepthDataForScenario | undefined = depthDataForScenarios.dict[depthResults.ScenarioId];
      if (!depthDataForScenario) {
        depthDataForScenario = { scenarioId: depthResults.ScenarioId, depthDataForRanges: { ids: [], dict: {} } };
      }

      depthDataForScenario = {
        scenarioId: depthDataForScenario.scenarioId,
        depthDataForRanges: DictionaryWithArray.upsert(
          depthDataForScenario.depthDataForRanges,
          {
            rangeId: depthResults.RangeId,
            simulationDuration: response.SimulationDuration,
            depthDataResults: DictionaryWithArray.create(
              response.DepthResults.filter((depthResult) => depthResult.ScenarioId === depthResults.ScenarioId),
              'FileType',
            ),
            depthDataResultsStatus: DepthDataStatus.Loaded,
            allDataPoints: response.AllDataPoints,
            loadedDataPoints: response.LoadedDataPoints,
          },
          'rangeId',
        ),
      };

      depthDataForScenarios = DictionaryWithArray.upsert(depthDataForScenarios, depthDataForScenario, 'scenarioId');
    }

    // update DepthDataResultsStatus for requested depthDataKey if not present in results
    const { scenarioId, rangeId } = depthDataKey;
    const hasResultsForDataKey = response.DepthResults.some((result) => result.ScenarioId === scenarioId && result.RangeId === rangeId);
    if (!hasResultsForDataKey) {
      depthDataForScenarios = ReportingModuleReducerHelper.updateDepthDataResultsStatus(
        depthDataForScenarios,
        depthDataKey,
        DepthDataStatus.Loaded,
      );
    }

    return {
      ...state,
      depthDataForScenarios,
    };
  }

  private static updateDepthDataResultsStatus(
    depthDataForScenarios: IDictionaryWithArray<IDepthDataForScenario>,
    depthDataKey: ResultsSourceKey,
    depthDataResultsStatus: DepthDataStatus,
  ): IDictionaryWithArray<IDepthDataForScenario> {
    let depthDataForScenario: IDepthDataForScenario | undefined = depthDataForScenarios.dict[depthDataKey.scenarioId];
    if (depthDataForScenario === undefined) {
      depthDataForScenario = { scenarioId: depthDataKey.scenarioId, depthDataForRanges: { ids: [], dict: {} } };
    }

    let depthDataForRange: IDepthDataForRange | undefined = depthDataForScenario.depthDataForRanges.dict[depthDataKey.rangeId];
    if (depthDataForRange === undefined) {
      depthDataForRange = {
        rangeId: depthDataKey.rangeId,
        simulationDuration: 0,
        depthDataResultsStatus,
        depthDataResults: { ids: [], dict: {} },
        allDataPoints: [],
        loadedDataPoints: [],
      };
    } else {
      depthDataForRange = { ...depthDataForRange, depthDataResultsStatus };
    }

    const depthDataForRanges = DictionaryWithArray.upsertById(
      { ...depthDataForScenario.depthDataForRanges },
      depthDataForRange,
      depthDataKey.rangeId,
    );

    depthDataForScenarios = DictionaryWithArray.upsertById(
      depthDataForScenarios,
      {
        ...depthDataForScenario,
        depthDataForRanges,
      },
      depthDataKey.scenarioId,
    );

    return depthDataForScenarios;
  }

  // endregion

  public static insertRowsSuccess(state: ReportingModuleState, response: CrudResponse): ReportingModuleState {
    let workingState = this.insertReportingTabsSuccess(state, response);
    workingState = this.insertChartSuccess(workingState, response);
    workingState = this.insertChartMarkerRowsSuccess(workingState, response);
    workingState = this.insertChartAnnotationRowsSuccess(workingState, response);
    workingState = this.insertChartGradientLineRowsSuccess(workingState, response);
    workingState = this.insertChartAxisPropertyRowsSuccess(workingState, response);
    workingState = this.insertChartSeriesTemplateRowsSuccess(workingState, response);
    return workingState;
  }

  public static updateRowsSuccess(state: ReportingModuleState, response: CrudResponse): ReportingModuleState {
    let workingState = this.updateReportingTabSuccess(state, response);
    workingState = this.updateChartMarkerRowsSuccess(workingState, response);
    workingState = this.updateChartAnnotationRowsSuccess(workingState, response);
    workingState = this.updateGradientLineRowsSuccess(workingState, response);
    workingState = this.updateChartAxisPropertyRowsSuccess(workingState, response);
    workingState = this.updateChartSeriesTemplateRowsSuccess(workingState, response);
    workingState = this.updateChartTemplateRowsSuccess(workingState, response);
    return this.updateChartRowsSuccess(workingState, response);
  }

  public static deleteRowsSuccess(state: ReportingModuleState, response: CrudResponse): ReportingModuleState {
    let workingState = this.deleteReportingTabsSuccess(state, response);
    workingState = this.deleteChartSuccess(workingState, response);
    workingState = this.deleteChartMarkerRowsSuccess(workingState, response);
    workingState = this.deleteChartAnnotationRowsSuccess(workingState, response);
    workingState = this.deleteChartGradientLineRowsSuccess(workingState, response);
    workingState = this.deleteChartSeriesTemplateSuccess(workingState, response);
    workingState = this.deleteChartTemplateSuccess(workingState, response);
    return workingState;
  }

  public static reorderRowSuccess(state: ReportingModuleState, response: CrudResponse): ReportingModuleState {
    return this.updateReportingTabsSuccess(state, response);
  }

  // region ChartDto

  /**
   * Removes ReportingTabDtos and IChartUserAddons related to the deleted chart
   * @param state
   * @param response
   */
  public static deleteChartSuccess(state: ReportingModuleState, response: CrudResponse): ReportingModuleState {
    const chart = response.affectedRows.chart;
    if (chart == null) {
      return state;
    }

    // find reporting tabs referring to deleted charts
    const reportingTabIds = state.reportingTabDtos.filter((tab) => chart.deletedIds.includes(tab.ChartId)).map((tab) => tab.Id);

    return {
      ...state,
      chartDtos: ArrayHelpers.deleteRowsWithIds(state.chartDtos, chart.deletedIds),
      reportingTabDtos: ArrayHelpers.deleteRowsWithIds(state.reportingTabDtos, reportingTabIds),
      chartUserAddonsDict: DictionaryWithArray.deleteItems(state.chartUserAddonsDict, chart.deletedIds),
    };
  }

  // endregion

  // region ReportingTab

  public static insertReportingTabsSuccess(state: ReportingModuleState, response: CrudResponse): ReportingModuleState {
    const reportingTabs = response.affectedRows.reportingTabs;
    if (!reportingTabs) {
      return state;
    }

    const insertedRows = response.affectedRows.reportingTabs?.insertedRows ?? [];
    const newReportingTabId = insertedRows.length ? insertedRows[0].Id : 0;

    const reportingTabDtos = [...reportingTabs.rows].sort((t1, t2) => t1.SortOrder - t2.SortOrder);

    return {
      ...state,
      reportingTabDtos,
      newReportingTabId,
    };
  }

  public static updateReportingTabSuccess(state: ReportingModuleState, response: CrudResponse): ReportingModuleState {
    const updatedReportingTab = response.affectedRows.reportingTabs?.rows[0];
    if (!updatedReportingTab) {
      return state;
    }
    return {
      ...state,
      reportingTabDtos: ArrayHelpers.replaceById(state.reportingTabDtos, updatedReportingTab),
    };
  }

  public static deleteReportingTabsSuccess(state: ReportingModuleState, response: CrudResponse): ReportingModuleState {
    const reportingTabs = response.affectedRows.reportingTabs;
    if (reportingTabs == null) {
      return state;
    }

    return {
      ...state,
      reportingTabDtos: ArrayHelpers.deleteRowsWithIds(state.reportingTabDtos, reportingTabs.deletedIds),
    };
  }

  public static updateReportingTabsSuccess(state: ReportingModuleState, response: CrudResponse): ReportingModuleState {
    const rowsToUpdate = response.affectedRows.reportingTabs?.rows;
    if (!rowsToUpdate) {
      return state;
    }
    return {
      ...state,
      reportingTabDtos: ArrayHelpers.replace(state.reportingTabDtos, rowsToUpdate),
    };
  }

  public static pushPrevSelectedReportingTabIds(state: ReportingModuleState, newTabId?: number): number[] {
    let prev = [...state.prevSelectedReportingTabIds];
    if (newTabId != null && (prev.length == 0 || newTabId != prev[prev.length - 1])) {
      prev = prev.filter((tabId) => tabId !== newTabId).concat([newTabId]);
    }
    return prev;
  }

  // endregion

  // region ChartMarker

  private static insertChartMarkerRowsSuccess(state: ReportingModuleState, response: CrudResponse): ReportingModuleState {
    if (!response.affectedRows.chartMarker) {
      return state;
    }

    let chartUserAddonsDict = state.chartUserAddonsDict;
    chartUserAddonsDict.ids.forEach((chartId) => {
      const chartAddons = DictionaryWithArray.get(chartUserAddonsDict, chartId);
      if (!chartAddons) {
        return;
      }

      const chartMarkers = ReportingFactory.toIMarkers(
        response.affectedRows.chartMarker?.rows.filter((row) => row.ChartId === Number(chartId)) ?? [],
      );
      chartUserAddonsDict = DictionaryWithArray.upsert(
        chartUserAddonsDict,
        {
          ...chartAddons,
          chartMarkers,
        },
        'chartId',
      );
    });

    return { ...state, chartUserAddonsDict };
  }

  private static updateChartMarkerRowsSuccess(state: ReportingModuleState, response: CrudResponse): ReportingModuleState {
    if (!response.affectedRows.chartMarker) {
      return state;
    }

    const updatedMarker = ReportingFactory.toIMarker(response.affectedRows.chartMarker.rows[0]);

    let chartUserAddonsDict = state.chartUserAddonsDict;
    const chartAddons = DictionaryWithArray.get(chartUserAddonsDict, updatedMarker.chartId ?? 0);
    if (chartAddons) {
      let chartMarkers = [...chartAddons.chartMarkers];
      chartMarkers = chartMarkers.filter((marker) => marker.id !== updatedMarker.id);
      chartMarkers = [...chartMarkers, updatedMarker];
      chartUserAddonsDict = DictionaryWithArray.upsert(
        chartUserAddonsDict,
        {
          ...chartAddons,
          chartMarkers,
        },
        'chartId',
      );
    }

    return { ...state, chartUserAddonsDict };
  }

  private static deleteChartMarkerRowsSuccess(state: ReportingModuleState, response: CrudResponse): ReportingModuleState {
    const deletedMarkers = response.affectedRows.chartMarker;
    if (!deletedMarkers) {
      return state;
    }

    let chartUserAddonsDict = state.chartUserAddonsDict;

    chartUserAddonsDict.ids.forEach((chartId) => {
      const chartAddons = DictionaryWithArray.get(chartUserAddonsDict, chartId);
      if (!chartAddons) {
        return;
      }

      const chartMarkers = chartAddons.chartMarkers.filter(
        (marker) => response.affectedRows.chartMarker?.deletedIds.findIndex((id) => id === marker.id) === -1,
      );
      chartUserAddonsDict = DictionaryWithArray.upsert(
        chartUserAddonsDict,
        {
          ...chartAddons,
          chartMarkers,
        },
        'chartId',
      );
    });

    return { ...state, chartUserAddonsDict };
  }

  // endregion

  // region ChartAnnotation

  private static insertChartAnnotationRowsSuccess(state: ReportingModuleState, response: CrudResponse): ReportingModuleState {
    if (!response.affectedRows.chartAnnotation) {
      return state;
    }

    let chartUserAddonsDict = state.chartUserAddonsDict;

    chartUserAddonsDict.ids.forEach((chartId) => {
      const chartAddons = DictionaryWithArray.get(chartUserAddonsDict, chartId);
      if (!chartAddons) {
        return;
      }

      const newAnnotations = ReportingFactory.toIAnnotations(
        response.affectedRows.chartAnnotation?.rows.filter((row) => row.ChartId === Number(chartId)) ?? [],
      );
      chartUserAddonsDict = DictionaryWithArray.upsert(
        chartUserAddonsDict,
        {
          ...chartAddons,
          chartAnnotations: newAnnotations,
        },
        'chartId',
      );
    });

    return { ...state, chartUserAddonsDict };
  }

  private static updateChartAnnotationRowsSuccess(state: ReportingModuleState, response: CrudResponse): ReportingModuleState {
    if (!response.affectedRows.chartAnnotation) {
      return state;
    }

    let chartUserAddonsDict = state.chartUserAddonsDict;

    for (const updatedAnnotationDto of response.affectedRows.chartAnnotation.rows) {
      const updatedAnnotation = ReportingFactory.toIAnnotation(updatedAnnotationDto);
      const chartAddons = DictionaryWithArray.get(chartUserAddonsDict, updatedAnnotationDto.ChartId);
      if (chartAddons) {
        let chartAnnotations = [...chartAddons.chartAnnotations];
        chartAnnotations = chartAnnotations.filter((a) => a.Id !== updatedAnnotation.Id);
        chartAnnotations = [...chartAnnotations, updatedAnnotation];
        chartUserAddonsDict = DictionaryWithArray.upsert(
          chartUserAddonsDict,
          {
            ...chartAddons,
            chartAnnotations,
          },
          'chartId',
        );
      }
    }

    return { ...state, chartUserAddonsDict };
  }

  private static deleteChartAnnotationRowsSuccess(state: ReportingModuleState, response: CrudResponse): ReportingModuleState {
    const deletedAnnotations = response.affectedRows.chartAnnotation;
    if (!deletedAnnotations) {
      return state;
    }

    let chartUserAddonsDict = state.chartUserAddonsDict;

    for (const chartId of chartUserAddonsDict.ids) {
      const chartAddons = DictionaryWithArray.get(chartUserAddonsDict, chartId);
      if (!chartAddons) {
        continue;
      }

      const chartAnnotations = chartAddons.chartAnnotations.filter((ann) => !deletedAnnotations.deletedIds.includes(ann.Id));
      chartUserAddonsDict = DictionaryWithArray.upsert(
        chartUserAddonsDict,
        {
          ...chartAddons,
          chartAnnotations,
        },
        'chartId',
      );
    }

    return { ...state, chartUserAddonsDict };
  }

  // endregion

  // region ChartGradientLine

  private static insertChartGradientLineRowsSuccess(state: ReportingModuleState, response: CrudResponse): ReportingModuleState {
    if (!response.affectedRows.chartGradientLine) {
      return state;
    }

    let chartUserAddonsDict = state.chartUserAddonsDict;

    chartUserAddonsDict.ids.forEach((chartId) => {
      const chartAddons = DictionaryWithArray.get(chartUserAddonsDict, chartId);
      if (!chartAddons) {
        return;
      }

      const newGradientLines = ReportingFactory.toIGradientLines(
        response.affectedRows.chartGradientLine?.rows.filter((row) => row.ChartId === Number(chartId)) ?? [],
      );

      chartUserAddonsDict = DictionaryWithArray.upsert(
        chartUserAddonsDict,
        { ...chartAddons, chartGradientLines: newGradientLines },
        'chartId',
      );
    });

    return { ...state, chartUserAddonsDict };
  }

  private static updateGradientLineRowsSuccess(state: ReportingModuleState, response: CrudResponse): ReportingModuleState {
    if (!response.affectedRows.chartGradientLine) {
      return state;
    }

    let chartUserAddonsDict = state.chartUserAddonsDict;

    for (const updatedGradientLineDto of response.affectedRows.chartGradientLine.rows) {
      const updatedLine = ReportingFactory.toIGradientLine(updatedGradientLineDto);
      const chartAddons = DictionaryWithArray.get(chartUserAddonsDict, updatedGradientLineDto.ChartId);
      if (chartAddons) {
        let chartGradientLines = [...chartAddons.chartGradientLines];
        chartGradientLines = chartGradientLines.filter((a) => a.Id !== updatedLine.Id);
        chartGradientLines = [...chartGradientLines, updatedLine];
        chartUserAddonsDict = DictionaryWithArray.upsert(
          chartUserAddonsDict,
          {
            ...chartAddons,
            chartGradientLines,
          },
          'chartId',
        );
      }
    }

    return { ...state, chartUserAddonsDict };
  }

  private static deleteChartGradientLineRowsSuccess(state: ReportingModuleState, response: CrudResponse): ReportingModuleState {
    const deletedGradientLines = response.affectedRows.chartGradientLine;
    if (!deletedGradientLines) {
      return state;
    }

    let chartUserAddonsDict = state.chartUserAddonsDict;

    for (const chartId of chartUserAddonsDict.ids) {
      const chartAddons = DictionaryWithArray.get(chartUserAddonsDict, chartId);
      if (!chartAddons) {
        continue;
      }

      const chartGradientLines = chartAddons.chartGradientLines.filter((line) => !deletedGradientLines.deletedIds.includes(line.Id));
      chartUserAddonsDict = DictionaryWithArray.upsert(
        chartUserAddonsDict,
        {
          ...chartAddons,
          chartGradientLines,
        },
        'chartId',
      );
    }

    return { ...state, chartUserAddonsDict };
  }

  // endregion

  // region ChartAxisProperty

  private static insertChartAxisPropertyRowsSuccess(state: ReportingModuleState, response: CrudResponse): ReportingModuleState {
    if (!response.affectedRows.chartAxisProperty) {
      return state;
    }

    let chartUserAddonsDict = state.chartUserAddonsDict;

    chartUserAddonsDict.ids.forEach((chartId) => {
      const chartAddons = DictionaryWithArray.get(chartUserAddonsDict, chartId);
      if (!chartAddons) {
        return;
      }

      const chartAxisProperties = ReportingFactory.toIAxesProps(
        response.affectedRows.chartAxisProperty?.rows.filter((row) => row.ChartId === Number(chartId)) ?? [],
      );
      chartUserAddonsDict = DictionaryWithArray.upsert(
        chartUserAddonsDict,
        {
          ...chartAddons,
          chartAxisProperties,
        },
        'chartId',
      );
    });

    return { ...state, chartUserAddonsDict };
  }

  private static insertChartSeriesTemplateRowsSuccess(state: ReportingModuleState, response: CrudResponse): ReportingModuleState {
    if (!response.affectedRows.chartSeriesTemplate) {
      return state;
    }
    const chartSeriesTemplates = response.affectedRows.chartSeriesTemplate.rows;
    return { ...state, chartSeriesTemplates };
  }

  private static updateChartAxisPropertyRowsSuccess(state: ReportingModuleState, response: CrudResponse): ReportingModuleState {
    if (!response.affectedRows.chartAxisProperty) {
      return state;
    }

    let chartUserAddonsDict = state.chartUserAddonsDict;

    response.affectedRows.chartAxisProperty.rows.forEach((updatedAxisPropertiesDto) => {
      const updatedAxisProps = ReportingFactory.toIAxisProps(updatedAxisPropertiesDto);

      const chartAddons = DictionaryWithArray.get(chartUserAddonsDict, updatedAxisPropertiesDto.ChartId);

      if (chartAddons) {
        let chartAxisProperties = [...chartAddons.chartAxisProperties];
        chartAxisProperties = chartAxisProperties.filter((axisProps) => axisProps.id !== updatedAxisProps.id);
        chartAxisProperties = [...chartAxisProperties, updatedAxisProps];
        chartUserAddonsDict = DictionaryWithArray.upsert(
          chartUserAddonsDict,
          {
            ...chartAddons,
            chartAxisProperties,
          },
          'chartId',
        );
      }
    });

    return { ...state, chartUserAddonsDict };
  }

  // endregion

  // region chart

  public static insertChartSuccess(state: ReportingModuleState, response: CrudResponse): ReportingModuleState {
    const charts = response.affectedRows.chart;
    if (!charts) {
      return state;
    }
    const chartDtos = [...state.chartDtos.filter((chartDto) => chartDto.ScenarioId !== response.scenarioId), ...charts.rows];

    return {
      ...state,
      chartDtos,
    };
  }

  private static updateChartRowsSuccess(state: ReportingModuleState, response: CrudResponse): ReportingModuleState {
    if (!response.affectedRows.chart) {
      return state;
    }
    const chartDtos = state.chartDtos.map((chart) => {
      const updatedChartDto = response.affectedRows.chart?.rows.find((row) => row.Id === chart.Id);
      return updatedChartDto ? updatedChartDto : chart;
    });

    return {
      ...state,
      chartDtos: chartDtos,
    };
  }

  // endregion

  // region series
  private static updateChartSeriesTemplateRowsSuccess(state: ReportingModuleState, response: CrudResponse): ReportingModuleState {
    if (!response.affectedRows.chartSeriesTemplate) {
      return state;
    }
    const chartSeriesTemplates = state.chartSeriesTemplates.map((tpl) => {
      const updatedTemplate = response.affectedRows.chartSeriesTemplate?.rows.find((row) => row.ColumnName === tpl.ColumnName);
      return updatedTemplate ? updatedTemplate : tpl;
    });

    return {
      ...state,
      chartSeriesTemplates,
    };
  }

  private static updateChartTemplateRowsSuccess(state: ReportingModuleState, response: CrudResponse): ReportingModuleState {
    const affectedTemplates = response.affectedRows.chartTemplate;
    if (!affectedTemplates) {
      return state;
    }

    const inserted = affectedTemplates.insertedRows ?? [];
    const updated = affectedTemplates.rows;
    let templates: IChartTemplateDto[] = [...state.chartTemplates];

    if (inserted.length) {
      templates = sortTemplates<IChartTemplateDto>([...templates, ...inserted]);
    }
    if (updated?.length) {
      const tmplMap = new Map(updated.map((u) => [encodeTemplateId(u.Id, u.Type), u]));
      templates = templates.map((tmpl) => {
        const templateId = encodeTemplateId(tmpl.Id, tmpl.Type);
        return tmplMap.has(templateId) ? (tmplMap.get(templateId) as IChartTemplateDto) : tmpl;
      });
    }

    return {
      ...state,
      chartTemplates: templates,
    };
  }

  private static deleteChartSeriesTemplateSuccess(state: ReportingModuleState, response: CrudResponse): ReportingModuleState {
    const affected = response.affectedRows.chartSeriesTemplate;
    if (!affected) {
      return state;
    }

    const chartSeriesTemplates = state.chartSeriesTemplates.filter(
      (row) => !response.affectedRows.chartSeriesTemplate?.deletedIds.includes(row.Id),
    );
    return { ...state, chartSeriesTemplates };
  }

  private static deleteChartTemplateSuccess(state: ReportingModuleState, response: CrudResponse): ReportingModuleState {
    const deletedIds = response.affectedRows.chartTemplate?.deletedIds ?? [];
    if (!deletedIds.length) {
      return state;
    }
    const templates = state.chartTemplates.filter((tmpl) => !deletedIds.includes(encodeTemplateId(tmpl.Id, tmpl.Type)));

    return {
      ...state,
      chartTemplates: templates,
    };
  }

  // endregion

  public static onDbConnectedSuccessAction(state: ReportingModuleState, payload: IDbConnectionOpenedResponse): ReportingModuleState {
    return {
      ...state,
      chartSeriesTemplates: payload.chartSeriesTemplates,
    };
  }

  public static onloadCommonDbInitialSuccess(state: ReportingModuleState, payload: ICommonDbInitialData): ReportingModuleState {
    return {
      ...state,
      crosshairMode: payload.customSettings.crosshairSettings?.crosshairMode ?? CrosshairMode.MULTIPLE,
      tooltipPosition: payload.customSettings.crosshairSettings?.tooltipPosition ?? TooltipPosition.DEFAULT,
      maxSeriesInTooltip: payload.customSettings.crosshairSettings?.maxSeriesInTooltip ?? defaultMaxSeriesInTooltip,
    };
  }
}
