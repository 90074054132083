import { Routes } from '@angular/router';
import { DataAnalysisPageComponent } from './data-analysis-page.component';
import { gaugeDataPageRoutes } from '../gauge-data-page/gauge-data-page.routes';
import { reportingRoutes } from '../common/reporting/reporting.routes';
import { LicenseFeature } from '@dunefront/common/modules/licensing/licensing.interfaces';
import { settingsRoutes } from '../common/settings/settings.routes';
import { RouteModuleData, RouteModuleDataGrid } from '../gauge-data-page/gauge-data-routes-names';

export const RouteModuleDataPro = 'data-pro';

export const dataAnalysisPageRoutes: Routes = [
  {
    path: RouteModuleDataPro,
    component: DataAnalysisPageComponent,
    data: {
      breadcrumb: 'DataPro',
      licenseFeature: LicenseFeature.Data_Analysis,
    },
    children: [
      ...gaugeDataPageRoutes,
      ...settingsRoutes,
      ...reportingRoutes,
      {
        path: '',
        redirectTo: `${RouteModuleData}/${RouteModuleDataGrid}`,
        pathMatch: 'full',
      },
    ],
  },
];
