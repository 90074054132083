import { createAction, props } from '@ngrx/store';
import { EquationPreviewRow, IEquationVariable } from '@dunefront/common/modules/equation/equation-variable.dto';
import { EquationColumn } from '@dunefront/common/modules/equation/equation-column';
import { ICalculationEngineUpdatePayload } from '@dunefront/common/modules/calculation-engine/calculation-engine.actions';
import { ImportColumnDto } from '@dunefront/common/modules/data-storage/dto/import-column.dto';

export const updateEquationPreview = createAction('[equation] updateEquationPreviewAction', props<{ preview: EquationPreviewRow[] }>());

export const fetchDataPreviewForEquation = createAction(
  '[equation] fetchDataPreviewForEquation',
  props<{ variables: IEquationVariable[] }>(),
);
export const fetchDataForEquationSuccess = createAction(
  '[equation] fetchDataForEquationActionSuccess',
  props<{ equationPreview: EquationPreviewRow[] }>(),
);

export const fetchDataForEquationFailed = createAction('[equation] fetchDataForEquationActionFailed');

export const updateEquation = createAction('[equation] updateEquationAction', props<{ equation: string }>());

export const insertNewVariable = createAction('[equation] insertNewVariableAction');
export const insertNewVariableSuccess = createAction('[equation] insertNewVariableActionSuccess', props<{ variable: IEquationVariable }>());
export const insertNewVariableFailure = createAction('[equation] insertNewVariableActionFailure', props<{ error: string }>());
export const removeVariable = createAction('[equation] removeVariableAction', props<{ index: number }>());

export const updateEquationColumnFields = createAction(
  '[equation] updateEquationColumnFieldsAction',
  props<{ changes: Partial<EquationColumn> }>(),
);

export const updateEquationVariable = createAction('[equation] updateEquationVariableAction', props<{ variable: IEquationVariable }>());

export const saveEquationColumnAction = createAction('[equation] saveEquationColumnAction');
export const updateEquationColumnAction = createAction('[equation] updateEquationColumnAction');

export const recalculateAllEquationsAction = createAction('[equation] recalculateAllEquationsAction', props<{ currentUiFileId: number }>());
export const generateSourceVariablesAction = createAction(
  '[equation] generateSourceVariablesAction',
  props<{ fileId: number; bulkAction?: { nextFilesIdsToProcess: number[]; currentUiFileId: number } }>(),
);
export const applyEquationFormulaCalculationsAction = createAction(
  '[equation] applyEquationFormulaCalculationsAction',
  props<{ fileId: number; bulkAction?: { nextFilesIdsToProcess: number[]; currentUiFileId: number } }>(),
);

export const applyEquationFormulaCalculationsSuccessAction = createAction(
  '[equation] applyEquationFormulaCalculationsSuccessAction',
  props<{ fileId: number }>(),
);

export const userDefinedFormulaPrepareDataUpdateInfoAction = createAction(
  '[equation] userDefinedFormulaPrepareDataUpdateInfoAction',
  props<{ payload: ICalculationEngineUpdatePayload }>(),
);

export const editUserDefinedFormulaColumnAction = createAction(
  '[equation] editUserDefinedFormulaAction',
  props<{ selectedColumn: ImportColumnDto }>(),
);
export const addUserDefinedFormulaColumnAction = createAction('[equation] addUserDefinedFormulaColumnAction', props<{ fileId: number }>());
export const addUserDefinedFileAction = createAction('[equation] addUserDefinedFile');
export const editUserDefinedFileAction = createAction('[equation] editUserDefinedFile');

export const setColumnForEdit = createAction('[equation] setColumnForEditAction', props<{ column: ImportColumnDto }>());
