import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { DbDependentComponent } from '../../../common-modules/db-connection/db-dependent.component';
import { getRowsForCalculations, ITableRow, ITableState } from '@dunefront/common/common/common-grid.interfaces';
import { GridHelpers } from './grid.helpers';
import { Store } from '@ngrx/store';
import { GridConfig } from './grid-config';
import { ModalService } from '../../../common-modules/modals/modal.service';
import { ColumnType, ICustomKeyboardHandlers, IGridColumnConfig, KeyboardKey } from './grid.interfaces';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { InsertLocation } from '@dunefront/common/modules/common.interfaces';
import { UnitConverterHelper } from '@dunefront/common/unit-converters/unit.converter.helper';
import { IIndexedDataType } from '@dunefront/common/dto/common-dto.interfaces';
import { StopEditingKey } from '../../../common-modules/units/components/input-2/input-2.component';
import { ArrayHelpers } from '@dunefront/common/common/array-helpers';
import { getIsUiLocked } from '../../../+store/ui/calc-engine-ui.selectors';
import { getBackendActiveRequestsCount } from '../../../+store/backend-connection/backend-connection.selectors';
import { MenuItem } from 'primeng/api';
import { ContextMenu } from 'primeng/contextmenu';
import { InputsHelperService } from '../../services/inputs-helper.service';
import { GridStackService } from './grid-stack.service';
import { v4 as uuidv4 } from 'uuid';
import { changeProp, ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';
import { IDeleteRowsProps } from '@dunefront/common/common/common-store-crud.interfaces';
import IsEqual from 'lodash/isEqual';
import { ConvertUnitPipe } from '@dunefront/common/modules/units/convert-unit.pipe/convert-unit.pipe';
import { PasteDataComponent } from '../../../common-modules/modals/paste-data/paste-data.component';
import { setOneTimeInstructionPopupShownAction } from '../../../+store/ui/ui.actions';
import { OneTimeInstructionType } from '../../../+store/ui/ui-module.state';
import { OneTimeMessageHelpers } from '../../../+store/ui/one-time-message-helpers';
import { firstValueFrom } from 'rxjs';
import { getIsOneTimeInstructionShown } from '../../../+store/ui/ui.selectors';

const arrowKeys = ['ArrowUp', 'ArrowRight', 'ArrowDown', 'ArrowLeft'];

@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class GridComponent<T extends IIndexedDataType> extends DbDependentComponent implements OnChanges, OnDestroy {
  public allColumnIds: string[] = [];
  @Input()
  public selectedCellIndex: number | undefined;
  public selectedCells = new Set<string>();
  public selectedRowsIndexes: number[] = [];
  public editModeCellPosition = '';
  public currentCellSelectionLocation = '';
  public virtualScrollDataSource: TableVirtualScrollDataSource<ITableRow<IIndexedDataType> | Group> = new TableVirtualScrollDataSource<
    ITableRow<IIndexedDataType> | Group
  >([]);
  @Input() public customKeyboardHandlers: ICustomKeyboardHandlers | undefined;
  @Input() public hasSelectionColumn = true;
  @Input() public dataSource: ITableState<T> | undefined;
  @Input() public gridConfig: GridConfig<T> | undefined;
  @Input() public columns: IGridColumnConfig<T>[] = [];
  @Input() public showCrudControls = true;
  @Input() public singleRowSelectionMode = false;
  @Input() public singleItemSelectionMode = false;
  @Input() public groupByColumns: string[] = [];
  @Input() public showGroupTotalCounts = false;
  @Input() public height = 480;
  @Input() public showHeaderUnits = true;
  @Input() public headerTopRowHeight = 25;
  @Input() public rowHeight = 24;
  @Input() public isFirstRowInsertingAllowed = false;
  @Input() public isFirstRowDeleteAllowed = false;
  @Input() public minRowCount = 0;
  @Input() public isPastingEnabled = false;
  @Input() public isMergingOfRowsEnabled = false;
  @Input() public elementId?: string;
  @Input() public dataCy = '';
  @Input() public isColumnSelectionMode = false;
  @Input() public isSelectionDisabled = false;
  @Input() public isInsertingRowDisabled = false;
  @Input() public isInsertingDisabled = false;
  @Input() public isDeletingDisabled = false;
  @Input() public isEditingDisabled = false;
  @Input() public isUiLockable = true;
  @Input() public isContextMenuDisabled = false;
  @Input() public hideThead = false;
  private isUiLocked = false;
  private isActiveBackendRequest = false;
  public isContextMenuVisible = false;
  private isCtrlKeyDown = false;
  private isShiftKeyDown = false;
  private gridId = uuidv4();
  private selectedColSortOrder: number | undefined;
  public scrollInterval: any;
  private lastMouseMoveY: number | null = null;
  private lastCellSelectionLocation: string | null = null;
  private wasClickedOutside = false;

  public initialInputsValues: { [key: string]: number | string } = {};

  public isPastePossible = false;
  public isMergingOfRowsPossible = false;

  private get isReadOnlyMode(): boolean {
    return this.isUiLockable && this.isUiLocked;
  }

  @ViewChild(CdkVirtualScrollViewport) public viewport: CdkVirtualScrollViewport | undefined;
  @ViewChild(ContextMenu) public contextMenu: ContextMenu | undefined;
  @ViewChild('gridOutline') private gridOutline?: ElementRef;
  public contextMenuPosTop!: number;
  public contextMenuPosLeft!: number;

  public isScrolling = false;
  public contextMenuItems: MenuItem[] = [];

  @Output() public selectedRowsChanged = new EventEmitter<ISelectedRowProps[]>();
  @Output() public selectedCellIndexChanged = new EventEmitter<number>();

  private gridHelpers!: GridHelpers;
  public ColumnType = ColumnType;

  private headerTopRowHeight_NoUnits = 50;
  private headerUnitsRowHeight = 25;

  public get actualHeaderTopRowHeight(): number {
    return this.showHeaderUnits ? this.headerTopRowHeight : this.headerTopRowHeight_NoUnits;
  }

  public get headerTotalHeight(): number {
    return this.actualHeaderTopRowHeight + (this.showHeaderUnits ? this.headerUnitsRowHeight : 0);
  }

  public getCellDataCy(rowIndex: number | 'header', colConfig: IGridColumnConfig<T>): string {
    return `${this.dataCy}_${rowIndex}_${colConfig.type === ColumnType.selection ? 'selection' : (colConfig.colId as string)}`;
  }

  constructor(
    store: Store,
    cdRef: ChangeDetectorRef,
    protected modalService: ModalService,
    protected elementRef: ElementRef,
    private inputsHelperService: InputsHelperService,
    private gridStackService: GridStackService,
    private ngZone: NgZone,
    public renderer: Renderer2,
  ) {
    super(store, cdRef);
    this.gridHelpers = new GridHelpers(modalService);
    this.subscription.add(this.store.select(getIsUiLocked).subscribe((isUiLocked) => (this.isUiLocked = isUiLocked)));
    this.subscription.add(
      this.store
        .select(getBackendActiveRequestsCount)
        .subscribe((activeBackendRequests) => (this.isActiveBackendRequest = activeBackendRequests > 0)),
    );
    this.gridStackService.addGrid(this.gridId);
    this.updateContextMenuItems();
  }

  public override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.gridStackService.removeGrid(this.gridId);

    // Temporary fix for closing context menu on changing route
    // this is already fixed in primeng >= 16.7.2
    // remove this after updating -> PC-5890
    const contextMenuRef = this.contextMenu?.containerViewChild?.nativeElement;
    if (contextMenuRef) {
      this.renderer.removeChild(document.body, contextMenuRef);
    }
  }

  // this was added in PC-1947-dropdown-in-detailed-well-fluids but breaks app
  // 100-evaluate-demo.spec.ts
  // @HostListener("document:click", ["$event"])
  // public clickOut(event: MouseEvent): void {
  //   if(!this.gridOutline?.nativeElement.contains(event.target)) {
  //     this.clearSelection(false);
  //   }
  // }

  @HostListener('window:mouseup', ['$event'])
  public documentMouseUp(event: MouseEvent): void {
    // event.preventDefault(); // removed to fix PC-36 Set the cursor in any position in a grids text box using the mouse
    // not sure if that break something

    const gridComponentTagFound = this.findParentTag('cdk-virtual-scroll-content-wrapper', event);
    if (gridComponentTagFound) {
      return;
    }

    if (this.currentCellSelectionLocation) {
      this.currentCellSelectionLocation = '';
    }
  }

  @HostListener('document:keydown.escape')
  public documentKeydownEscape(): void {
    if (!this.editModeCellPosition.length) {
      this.clearSelection();
    }
  }

  // Listening on ctrl and shift keys is done this way because mouseover event doesn't report shift and ctrl keys properly
  // when mouse button clicked
  @HostListener('document:keydown.control')
  public documentKeydownCtrl(): void {
    this.isCtrlKeyDown = true;
  }

  @HostListener('document:keyup.control')
  public documentKeyupCtrl(): void {
    this.isCtrlKeyDown = false;
  }

  @HostListener('document:keydown.shift')
  public documentKeydownShift(): void {
    this.isShiftKeyDown = true;
  }

  @HostListener('document:keyup.shift')
  public documentKeyupShift(): void {
    this.isShiftKeyDown = false;
  }

  @HostListener('document:paste', ['$event'])
  public async documentPaste(event: ClipboardEvent): Promise<void> {
    if (!this.isPastingEnabled || !this.isPastePossible) {
      return;
    }

    // check if current grid is on top of stack
    if (this.gridStackService.getLastGridId() !== this.gridId) {
      return;
    }

    // check if paste is performed on other element
    const body = document.querySelector('body');
    const firstPathElement = event.composedPath()[0] as HTMLElement | Document;
    const gridTableElement = this.gridOutline?.nativeElement.querySelector('table');

    if (firstPathElement !== body && firstPathElement !== document && firstPathElement !== gridTableElement) {
      return;
    }

    // Paste
    if (!event.clipboardData) {
      return;
    }

    const clipboardText = event.clipboardData?.getData('text/plain');

    if (await this.inputsHelperService.checkResultsAndDeleteIfNeeded(this.isUiLockable)) {
      this.ngZone.run(() => {
        this.paste(clipboardText).then();
      });
    }
  }

  public getHeaderText(cellIndex: number): string {
    if (this.columns[cellIndex] == null) {
      return '';
    }
    const colConfig = this.columns[cellIndex];
    return colConfig.headerText ?? (colConfig.colId as string);
  }

  public getShowError(cellIndex: number): boolean | undefined {
    if (this.columns[cellIndex] == null) {
      return false;
    }

    return this.columns[cellIndex]?.showError === true;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.columns != null && changes.columns.currentValue) {
      this.allColumnIds = (this.columns ?? []).map((c) => c.colId) as string[];
    }
    if (changes.dataSource != null && changes.dataSource.currentValue) {
      this.updateFocusIfNeeded(changes);

      this.assignDataSource();
    }
    if (changes.height != null) {
      setTimeout(() => {
        if (this.dataSource && this.viewport) {
          this.viewport.checkViewportSize();
        }
      });
    }

    if (changes.isPastingEnabled != null || changes.isMergingOfRowsEnabled != null) {
      this.updateContextMenuItems();
    }

    this.notifySelectedRowsChanged();
  }

  private assignDataSource(): void {
    if (this.dataSource == null) {
      return;
    }
    if (this.groupByColumns.length) {
      // don't use virtual data scroll
      const data = this.addGroups(this.dataSource.rows, this.groupByColumns);
      this.virtualScrollDataSource = new TableVirtualScrollDataSource(data);
      this.virtualScrollDataSource.filterPredicate = this.customFilterPredicate.bind(this);
      this.virtualScrollDataSource.filter = performance.now().toString();
    } else {
      const rows = this.isInsertingRowDisabled ? this.dataSource.rows.filter((row) => row.rowType !== 'insert-row') : this.dataSource.rows;

      // if newTotalContentHeight is smaller than currentScrollOffset - CdkVirtualScrollViewport will need to resize its
      // container size and scroll to "new" bottom most position, default smooth scrolling here leads grid to very slow reload
      // as multiple Change Detections are triggered, in order to prevent this, viewport first needs to get scrolled manually
      // with behavior: 'auto' (not smooth)
      const newTotalContentHeight = rows.length * this.rowHeight;
      const currentScrollOffset = this.viewport?.measureScrollOffset('top') ?? 0;
      if (newTotalContentHeight < currentScrollOffset) {
        this.viewport?.scrollTo({ top: 0, behavior: 'auto' });
      }

      this.virtualScrollDataSource = new TableVirtualScrollDataSource(rows as any);
    }
  }

  public scroll(downOrUp: 'down' | 'up', size = 10): void {
    if (!this.viewport) {
      return;
    }
    if (downOrUp === 'down') {
      this.viewport.scrollToOffset(this.viewport.measureScrollOffset() + size);
    } else {
      this.viewport.scrollToOffset(this.viewport.measureScrollOffset() - size);
    }
  }

  public trackByRowId(index: number, item: ITableRow<IIndexedDataType> | Group): string {
    if (item instanceof Group) {
      return `group_${item.Id}`;
    } else {
      return `item_${item.rowData.Id}`;
    }
  }

  public async onKeyPress(event: KeyboardEvent): Promise<void> {
    if (this.dataSource == null) {
      return;
    }

    if (this.selectedCells.size === 1 && !this.editModeCellPosition.length && event.key.length === 1 && !event.ctrlKey) {
      const selectedCellPosition = Array.from(this.selectedCells)[0];
      const [rowIndex, cellIndex] = this.gridHelpers.decodeCellPosition(selectedCellPosition);

      this.initialInputsValues[selectedCellPosition] = event.key;
      this.editCell(rowIndex, cellIndex);

      return;
    }

    if (arrowKeys.includes(event.key) && this.editModeCellPosition.length) {
      // when in edit mode - don't handle arrow keys ( use default input behavior )
      return;
    }

    if (this.customKeyboardHandlers?.[event.key as KeyboardKey] != undefined) {
      return (this.customKeyboardHandlers[event.key as KeyboardKey] as () => any)();
    }

    if (event.key === 'ArrowUp' && this.selectedRowsIndexes[0] > 0) {
      this.selectedRowsIndexes[0]--;
    }

    if (event.key === 'ArrowDown' && this.selectedRowsIndexes[0] < this.dataSource.rows.length - 1) {
      this.selectedRowsIndexes[0]++;
    }

    if (event.key === 'Insert') {
      await this.onInsertClicked();
    }

    if (event.key === 'Delete' || event.key === 'Del') {
      await this.onDeleteClicked();
    }

    if (event.key === 'Escape' || event.key === 'Esc') {
      this.selectedRowsIndexes = [];
    }

    if (event.ctrlKey && event.code === 'KeyA') {
      if (!this.singleRowSelectionMode) {
        this.selectAll();
      }
      event.preventDefault();
      event.stopPropagation();
    }

    if (event.ctrlKey && event.code === 'KeyC') {
      this.copyToClipboard().then();
    }

    if (event.ctrlKey && event.code === 'KeyD') {
      this.changeSubsequentCells().then();
      event.preventDefault();
    }

    if (event.key === 'Tab') {
      event.preventDefault();
      this.changeFocusedCell(event.key);
    }

    if (arrowKeys.includes(event.key)) {
      event.preventDefault();

      if (event.shiftKey) {
        this.selectCellsWithShiftAndArrows(event);
        return;
      }

      if (event.ctrlKey) {
        this.selectCellWithCtrlAndArrows(event);
        return;
      }

      this.changeFocusedCell(event.key);
    }

    if (event.key === 'Enter') {
      this.onEnterPress();
    }

    if (event.code === 'PageUp' || event.code === 'PageDown') {
      event.preventDefault();
      event.stopPropagation();

      if (this.editModeCellPosition.length === 0) {
        this.onPageUpOrDown(event.code);
      }
    }
  }

  private selectCellWithCtrlAndArrows(event: KeyboardEvent): void {
    const baseCell = this.lastCellSelectionLocation || [...this.selectedCells][0];

    if (!baseCell) {
      return;
    }

    const [targetRow, targetCell] = this.gridHelpers.decodeCellPosition(this.getLastCellInDirection(event.key, baseCell));

    this.clearSelection(true);
    this.selectedCells.add(this.gridHelpers.encodeCellPosition(targetRow, targetCell));

    this.lastCellSelectionLocation = this.gridHelpers.encodeCellPosition(targetRow, targetCell);

    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      this.scrollToRowIndex(targetRow);
    }
  }

  private getLastCellInDirection(key: string, baseCell: string): string {
    if (!baseCell || !this.dataSource) {
      return baseCell;
    }

    const [currRow, currCell] = this.gridHelpers.decodeCellPosition(baseCell);

    let targetCell = currCell;
    let targetRow = currRow;

    switch (key) {
      case 'ArrowUp':
        targetRow = 0;
        break;
      case 'ArrowDown':
        targetRow = getRowsForCalculations(this.dataSource.rows).length;
        break;
      case 'ArrowRight':
        targetCell = this.columns.reduce((acc, cur, index) => (cur.visible ? index : acc), -1);
        break;
      case 'ArrowLeft':
        targetCell = 1;
        break;
    }

    return this.gridHelpers.encodeCellPosition(targetRow, targetCell);
  }

  private selectCellsWithShiftAndArrows(event: KeyboardEvent): void {
    if (this.selectedCells.size === 0 || !this.lastCellSelectionLocation) {
      return;
    }

    const baseCell = this.gridHelpers.getMostDistantCell(this.lastCellSelectionLocation, this.selectedCells);
    const cellToAdd = event.ctrlKey ? this.getLastCellInDirection(event.key, baseCell) : this.getCellToSelectByKey(event.key, baseCell, false);

    if (!cellToAdd) {
      return;
    }

    const [newRowIndex, newCellIndex] = this.gridHelpers.decodeCellPosition(cellToAdd);

    if (this.gridHelpers.encodeCellPosition(newRowIndex, newCellIndex) === this.lastCellSelectionLocation) {
      // when selection gets back to original cell, clear selection and select just that one cell
      this.clearSelection(true);
    }

    this.currentCellSelectionLocation = cellToAdd;
    this.selectCell(newRowIndex, newCellIndex, event);

    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      if (event.ctrlKey) {
        this.scrollToRowIndex(newRowIndex);
      } else {
        this.scrollToRowIfNotVisible(newRowIndex);
      }
    }
  }

  // region GROUPING

  private customFilterPredicate(data: any | Group): boolean {
    return data instanceof Group ? data.visible : this.getDataRowVisible(data);
  }

  private getDataRowVisible(data: any): boolean {
    const groupRows = this.virtualScrollDataSource.data.filter((row) => {
      if (!(row instanceof Group)) {
        return false;
      }
      let match = true;
      this.groupByColumns.forEach((column) => {
        if (
          (row.rowData as any)[column as keysGroup] === undefined ||
          data.rowData[column] === undefined ||
          (row.rowData as any)[column as keysGroup] !== data.rowData[column]
        ) {
          match = false;
        }
      });
      return match;
    });

    if (groupRows.length === 0) {
      return true;
    }
    const parent = groupRows[0] as Group;
    return parent.visible && parent.expanded;
  }

  private addGroups(data: any[], groupByColumns: string[]): any[] {
    const rootGroup = new Group('root');
    rootGroup.expanded = true;
    return this.getSubLevel(data, 0, groupByColumns as keysGroup[], rootGroup);
  }

  private getSubLevel(data: any[], level: number, groupByColumns: string[], parent: Group): any[] {
    if (level >= groupByColumns.length) {
      return data;
    }
    const groups = this.uniqueBy(
      data.map((row) => {
        const result = new Group(level + 1 + '');
        result.level = level + 1;
        result.parent = parent;
        for (let i = 0; i <= level; i++) {
          (result.rowData as any)[groupByColumns[i]] = row.rowData[groupByColumns[i]];
        }
        return result;
      }),
      JSON.stringify,
    );

    const currentColumn = groupByColumns[level];
    let subGroups: any[] = [];
    groups.forEach((group: any) => {
      const rowsInGroup = data.filter((row) => group.rowData[currentColumn] === row.rowData[currentColumn]);
      group.totalCounts = rowsInGroup.length;
      const subGroup = this.getSubLevel(rowsInGroup, level + 1, groupByColumns, group);
      subGroup.unshift(group);
      subGroups = subGroups.concat(subGroup);
    });
    return subGroups;
  }

  /* eslint-disable no-prototype-builtins */
  private uniqueBy(a: Group[], key: any): any {
    const seen = {};
    return a.filter((item) => {
      const k = key(item);
      return seen.hasOwnProperty(k) ? false : ((seen as any)[k] = true);
    });
  }

  /* eslint-enable no-prototype-builtins */

  public groupHeaderClick(row: Group): void {
    row.expanded = !row.expanded;
    this.virtualScrollDataSource.filter = performance.now().toString(); // bug here need to fix
  }

  public isGroup(index: any, item: { level: boolean }): boolean {
    return item.level;
  }

  public getGroupName(colId: string, value: number | string): string | undefined {
    const colDef = this.columns.find((col) => col.colId === colId);
    if (!colDef || this.gridConfig == null) {
      return '';
    }
    if (!colDef.lookupDataSourceType) {
      return String(value).replace('_', ' ');
    }
    const lookup = this.gridConfig.getLookupDataSource(colDef.lookupDataSourceType);
    const foundLookup = lookup.find((l) => l.value === value);
    return foundLookup?.text;
  }

  // endregion

  // region CRUD

  public onCellValueChanged(value: ObjectChangeProp<T>, rowIndex: number, cellIndex: number): void {
    if (this.gridConfig == null || this.dataSource == null) {
      return;
    }

    const colId = this.columns[cellIndex].colId;
    const row = this.dataSource.rows[rowIndex];
    const newRow: ITableRow<T> = { ...row, rowData: changeProp(row.rowData, value) };
    const refRow = this.dataSource.rows[rowIndex];
    const refId = refRow.rowData.Id;

    if (newRow.rowType === 'insert-row') {
      this.gridConfig.insertRowAction(
        {
          rows: [newRow],
          insertLocation: 'insert',
          refId,
          shouldResetResults: this.isUiLockable,
        },
        refRow,
      );
    } else {
      this.gridConfig.updateRowsAction(
        {
          rows: [newRow],
          focusedRowKey: row.rowData.Id,
          colIds: colId === ' ' ? undefined : [colId],
          shouldResetResults: this.isUiLockable,
        },
        cellIndex,
      );
    }
  }

  public onImportClicked(needsRowSelection = false): void {
    if (
      !this.gridConfig ||
      !this.dataSource ||
      (needsRowSelection && !this.gridHelpers.canRowsBeInserted(this.selectedRowsIndexes, this.isFirstRowInsertingAllowed, true))
    ) {
      return;
    }
    const refRow = needsRowSelection ? this.getRow(this.selectedRowsIndexes[0]) : undefined;

    this.modalService.open(
      PasteDataComponent,
      {
        gridConfig: this.gridConfig,
        refRow,
      },
      'import-data-modal',
      '1200px',
      undefined,
      false,
    );
  }

  public async onInsertClicked<Entity extends T>(
    isRefNodeBased = true,
    overrideProps?: ObjectChangeProp<Entity>[],
    insertLocation?: InsertLocation,
  ): Promise<void> {
    if (
      this.isActiveBackendRequest ||
      this.isInsertingRowDisabled ||
      this.isInsertingDisabled ||
      !this.gridConfig ||
      !this.dataSource ||
      !this.gridHelpers.canRowsBeInserted(this.selectedRowsIndexes, this.isFirstRowInsertingAllowed, isRefNodeBased)
    ) {
      return;
    }

    if (!(await this.inputsHelperService.checkResultsAndDeleteIfNeeded(this.isUiLockable))) {
      return;
    }

    const selectedRowIndex = this.selectedRowsIndexes[0];
    const refRow = isRefNodeBased
      ? this.getRow(selectedRowIndex)
      : this.getRow(ArrayHelpers.findLastIndex(this.getDataRows(), (row) => row.rowType !== 'insert-row'));

    if (refRow == null || refRow.rowType === 'insert-row') {
      return;
    }

    const newRow: ITableRow<Entity> = JSON.parse(JSON.stringify(refRow));
    newRow.rowType = 'data';
    newRow.rowData.Id = -1;

    if (overrideProps) {
      for (const appendProp of overrideProps) {
        newRow.rowData[appendProp.key] = appendProp.value;
      }
    }

    this.gridConfig.insertRowAction(
      {
        rows: [newRow],
        refId: refRow.rowData.Id,
        insertLocation: insertLocation !== undefined ? insertLocation : isRefNodeBased ? 'insert' : 'add',
        shouldResetResults: this.isUiLockable,
      },
      refRow,
    );

    this.clearSelection();
    this.selectRow(selectedRowIndex);
  }

  public async onDeleteClicked(): Promise<void> {
    if (this.isActiveBackendRequest || this.isDeletingDisabled || !this.gridConfig || this.editModeCellPosition.length > 0) {
      return;
    }

    const rowsLength = this.getDataRows().length; // should not count first empty row
    let selectedRowsKeys = this.getSelectedRowsKeys();

    const isEveryRowFullySelected = this.selectedRowsIndexes.every((r) => this.isWholeRowSelected(r));

    if (!isEveryRowFullySelected) {
      await this.modalService.showAlert('In order to delete rows from the table,  all columns in each row should be selected.', 'Information');
      return;
    }

    if (selectedRowsKeys.length === 0) {
      await this.modalService.showAlert('Select at least one row to delete', 'Information');
      return;
    }

    let selectedRowIndexes = this.selectedRowsIndexes;
    if (!this.isFirstRowInsertingAllowed && selectedRowsKeys.length > 1) {
      if (!this.isFirstRowDeleteAllowed) {
        selectedRowsKeys = selectedRowsKeys.filter((key) => key !== this.getDataRows()[0].rowData.Id);
      }
      selectedRowIndexes = selectedRowIndexes.filter((rowIndex) => rowIndex > 0);
    }
    const deleteRowsActionProps: IDeleteRowsProps = {
      rowIds: selectedRowsKeys,
      scenarioId: this.currentScenarioId,
      shouldResetResults: this.isUiLockable,
    };
    if (
      (await this.gridConfig.canRowsBeDeleted(deleteRowsActionProps)) &&
      (await this.gridHelpers.canRowsBeDeleted(selectedRowIndexes, rowsLength, this.minRowCount, this.isFirstRowDeleteAllowed))
    ) {
      if (!(await this.inputsHelperService.checkResultsAndDeleteIfNeeded(this.isUiLockable))) {
        return;
      }

      // DELETE ROW
      this.gridConfig.deleteRowsAction({
        rowIds: selectedRowsKeys,
        scenarioId: this.currentScenarioId,
        shouldResetResults: this.isUiLockable,
      });
      this.clearSelection();
    }
  }

  private async changeSubsequentCells(): Promise<void> {
    if (this.dataSource == null) {
      return;
    }

    if (
      this.gridConfig &&
      this.selectedRowsIndexes.length &&
      (await this.inputsHelperService.checkResultsAndDeleteIfNeeded(this.isUiLockable))
    ) {
      const newRows: ITableRow<T>[] = [];

      const selectedColumnsWithRows = this.getSelectedColumnsWithRowsMap();

      for (const cellIndex of Array.from(selectedColumnsWithRows.keys())) {
        const column = this.columns[cellIndex];
        if (column.isFillDownDisabled) {
          return;
        }

        const { colId } = column;
        const rowIndexes = Array.from(selectedColumnsWithRows.get(cellIndex) as Set<number>).sort((a, b) => a - b);
        const refValue = this.dataSource.rows[rowIndexes[0]].rowData[colId as keyof T];

        for (let i = 1; i < rowIndexes.length; i++) {
          const rowIndex = rowIndexes[i];

          if (this.isCellDisabled(rowIndex, cellIndex)) {
            continue;
          }

          const dsRow = this.dataSource.rows[rowIndex];
          if (dsRow == null || dsRow.rowType === 'insert-row') {
            continue;
          }

          let newRow = newRows.find((row) => row.rowData.Id === dsRow.rowData.Id);
          if (!newRow) {
            newRow = JSON.parse(JSON.stringify(dsRow)) as ITableRow<T>;
            newRows.push(newRow);
          }

          newRow.rowData[colId as keyof T] = refValue;
        }
      }

      if (newRows.length) {
        this.gridConfig.updateRowsAction({ rows: newRows, shouldResetResults: this.isUiLockable });
      }
    }
  }

  // endregion

  // region SELECTION

  public isCellSelected(rowIndex: number, cellIndex: number): boolean {
    if (this.isColumnSelectionMode) {
      return cellIndex === this.selectedCellIndex;
    } else {
      return this.selectedCells.has(this.gridHelpers.encodeCellPosition(rowIndex, cellIndex));
    }
  }

  public isSelectionCol(cellIndex: number): boolean {
    return cellIndex === 0 && this.columns[0]?.type === ColumnType.selection;
  }

  public selectAll(): void {
    if (this.dataSource == null) {
      return;
    }
    this.clearSelection();
    const rowIndexes = this.dataSource.rows.filter((row) => row.rowType !== 'insert-row').map((row) => row.rowIndex);
    this.selectRows(rowIndexes);
    this.isMergingOfRowsPossible = rowIndexes.length > 1;
    this.updateContextMenuItems();
  }

  private selectRows(rowIndexes: number[]): void {
    this.selectedRowsIndexes.push(...rowIndexes);
    rowIndexes.forEach((rowIndex) => {
      for (let c = 0; c < this.allColumnIds.length; c++) {
        if (this.isCellVisible(c)) {
          this.selectedCells.add(this.gridHelpers.encodeCellPosition(rowIndex, c));
        }
      }
    });
  }

  private selectRow(rowIndex: number): void {
    this.clearSelection();
    this.selectRows([rowIndex]);
  }

  public selectCell(rowIndex: number, cellIndex: number, event: MouseEvent | KeyboardEvent, isColHead = false, isRightClick = false): void {
    if (
      event.type !== 'mousedown' &&
      event.type !== 'mouseover' &&
      event.type !== 'click' &&
      event.type !== 'contextmenu' &&
      event.type !== 'keydown'
    ) {
      return;
    }

    if (this.gridHelpers.encodeCellPosition(rowIndex, cellIndex) !== this.editModeCellPosition) {
      this.editModeCellPosition = '';
    }

    if (this.singleItemSelectionMode) {
      // in single item selection mode, clear all selection, and just select clicked cell or row
      this.clearSelection(true);
      if (cellIndex === 0) {
        for (let i = 0; i < this.columns.length; i++) {
          if (this.columns[i].visible) {
            this.selectedCells.add(this.gridHelpers.encodeCellPosition(rowIndex, i));
          }
        }
      } else {
        this.selectedCells.add(this.gridHelpers.encodeCellPosition(rowIndex, cellIndex));
      }

      this.selectedRowsIndexes = [rowIndex];
      return;
    }

    if (this.isColumnSelectionMode && this.dataSource) {
      // in column selection mode, clear all selection, and select all cells in a column(it is used only in gauge-data component)
      if (this.selectedCellIndex !== cellIndex) {
        const maxRow = getRowsForCalculations(this.dataSource.rows).length;
        const allRows = [...Array(maxRow)];

        this.clearSelection();
        allRows.forEach((_, rowIdx) => {
          this.selectedCells.add(this.gridHelpers.encodeCellPosition(rowIdx, cellIndex));
        });
        this.selectedCellIndex = cellIndex;
        this.selectedCellIndexChanged.emit(cellIndex);
      }
      return;
    }

    if (isColHead && this.dataSource) {
      const isSelectionHeadCol = rowIndex === -1 && cellIndex === 0;

      const maxRow = getRowsForCalculations(this.dataSource.rows).length;

      const allRows = [...Array(maxRow)];

      const isWholeColumnSelected = allRows.every((_, rowIdx) => this.isCellSelected(rowIdx, cellIndex));

      if (!event.ctrlKey && !isSelectionHeadCol && !event.shiftKey && !(isWholeColumnSelected && isRightClick)) {
        this.clearSelection();
      }

      // if it's a header selection col ( top left ) - toggle select all/unselect all
      if (isSelectionHeadCol) {
        const visibleColsLen = this.columns.filter((c) => c.visible).length;
        const rowsLen = this.getDataRows().length;

        const isEverythingSelected = this.selectedCells.size === visibleColsLen * rowsLen;

        if (isEverythingSelected) {
          return this.clearSelection();
        } else {
          return this.selectAll();
        }
      }

      const lastClickedCol = this.gridHelpers.decodeCellPosition(this.currentCellSelectionLocation)[1];

      let isDragging = false;

      if (lastClickedCol != null && this.currentCellSelectionLocation !== this.gridHelpers.encodeCellPosition(rowIndex, cellIndex)) {
        isDragging = true;
      }

      let startCol = lastClickedCol ? lastClickedCol : cellIndex;
      let endCol = cellIndex;

      if (event.shiftKey || isDragging) {
        const relativeMousePosition = isDragging ? this.currentCellSelectionLocation : this.lastCellSelectionLocation;

        const lastClickedCol = relativeMousePosition ? this.gridHelpers.decodeCellPosition(relativeMousePosition)[1] : 1;

        startCol = Math.min(lastClickedCol, cellIndex);
        endCol = Math.max(lastClickedCol, cellIndex);

        this.clearSelection();
      }

      // Toggle selection of columns
      for (let i = startCol; i <= endCol; i++) {
        allRows.forEach((_, rowIdx) => {
          if (isWholeColumnSelected && !event.shiftKey && !isDragging && !isRightClick) {
            this.selectedCells.delete(this.gridHelpers.encodeCellPosition(rowIdx, i));
          } else {
            this.selectedCells.add(this.gridHelpers.encodeCellPosition(rowIdx, i));
          }
        });
      }
    }

    // Save last clicked position (without shift) for later selections
    if (!event.shiftKey) {
      this.lastCellSelectionLocation = this.gridHelpers.encodeCellPosition(rowIndex, cellIndex);
    }

    if (this.isSelectionDisabled || this.dataSource == null) {
      return;
    }

    // this is when column is clicked
    if (this.currentCellSelectionLocation === '') {
      // means that do data cell was selected
      return;
    }

    const shiftStartCellIndex = this.editModeCellPosition ? this.editModeCellPosition : this.lastCellSelectionLocation || '1:1';
    const [startRowIndex, startCellIndex] = this.gridHelpers.decodeCellPosition(
      event.shiftKey && shiftStartCellIndex ? shiftStartCellIndex : this.currentCellSelectionLocation,
    );
    const maxCellIndex = this.columns.length - 1;

    if (event.shiftKey && this.editModeCellPosition) {
      this.selectedCells.add(this.editModeCellPosition);
      this.editModeCellPosition = '';
    }

    const maxVisibleColIdx = this.columns.reduce((acc, cur, index) => (cur.visible ? index : acc), -1);

    if (isColHead) {
      const isColDisabled = this.columns[cellIndex].disabled;
      this.isPastePossible = !isColDisabled;
      this.isMergingOfRowsPossible = false;
      this.updateContextMenuItems();
      return;
    }

    if ((!event.shiftKey && !event.ctrlKey) || this.singleRowSelectionMode) {
      this.clearSelection(false);
    }

    let rowFrom = startRowIndex >= 0 ? startRowIndex : 0;
    let rowTo = rowIndex;
    let cellFrom = startCellIndex;
    let cellTo = this.isSelectionCol(cellFrom) ? maxVisibleColIdx : cellIndex;

    const isSingleCell = rowFrom === rowTo && cellFrom === cellTo;
    const isSingleRow = rowFrom === rowTo && this.isSelectionCol(cellFrom);

    if (rowIndex < startRowIndex) {
      rowFrom = rowIndex;
      rowTo = startRowIndex;
    }

    if (cellFrom === 0) {
      cellTo = this.columns.filter((c) => c.type !== ColumnType.selection).length;
    } else if (cellIndex < startCellIndex) {
      cellFrom = cellIndex;
      cellTo = startCellIndex;
    }

    if (event.shiftKey) {
      // if clicked on selection col, select all cols from row
      if (this.isSelectionCol(cellIndex)) {
        const maxCol = maxVisibleColIdx;
        cellTo = maxCol;
      }

      this.selectedRowsIndexes = [];
      for (let r = rowFrom; r <= rowTo; r++) {
        this.selectedRowsIndexes.push(r);
      }

      this.selectedCells = new Set(
        Array.from(this.selectedCells).filter((cell) => {
          const [rowIdx, cellIdx] = cell.split(':');
          return +cellIdx <= cellTo && +cellIdx >= cellFrom && +rowIdx <= rowTo && +rowIdx >= rowFrom;
        }),
      );
    }

    if (this.singleRowSelectionMode) {
      this.selectedRowsIndexes.push(rowIndex);
      for (let c = 0; c <= maxCellIndex; c++) {
        if (this.isCellVisible(c)) {
          this.selectedCells.add(this.gridHelpers.encodeCellPosition(rowIndex, c));
        }
      }
    } else {
      // if user clicked on single cell ( and no full row is selected ) then select or deselect cell

      if (isSingleCell && !this.isAnyFullRowSelected()) {
        this.toggleSelectCell(rowIndex, cellIndex);
      } else if (isSingleRow || (this.isAnyFullRowSelected() && !event.shiftKey)) {
        // if a row selection is clicked, select or deselect whole row
        if (!this.isInsertRow(rowIndex)) {
          this.toggleSelectRow(rowIndex);
        }
      } else {
        // Otherwise select all selected cells
        for (let r = rowFrom; r <= rowTo; r++) {
          if (this.isInsertRow(r)) {
            continue;
          }

          // prevent duplicates
          if (!this.selectedRowsIndexes.includes(r)) {
            this.selectedRowsIndexes.push(r);
          }

          for (let c = cellFrom; c <= cellTo; c++) {
            if (this.isCellVisible(c)) {
              this.selectedCells.add(this.gridHelpers.encodeCellPosition(r, c));
            }
          }
        }
      }
    }

    const isAnySelectedCellDisabled = Array.from(this.selectedCells).some((selectedCell) => {
      const [row, cell] = this.gridHelpers.decodeCellPosition(selectedCell);
      return this.isCellDisabled(row, cell);
    });

    const isFullRowsSelection = this.selectedRowsIndexes.every((r) => this.isWholeRowSelected(r));
    this.isPastePossible =
      (!isAnySelectedCellDisabled && this.gridHelpers.getSelectionDimensions(this.selectedCells) != null) || isFullRowsSelection;
    this.isMergingOfRowsPossible = this.selectedRowsIndexes.length > 1 && isFullRowsSelection;
    this.updateContextMenuItems();
  }

  private notifySelectedRowsChanged(): void {
    this.selectedRowsChanged.emit(
      this.selectedRowsIndexes.map((rowIndex) => ({
        rowIndex,
        rowId: this.getRowKey(rowIndex),
      })),
    );
  }

  private getSelectedRowsKeys(): number[] {
    return this.selectedRowsIndexes.map((rowIndex) => this.getRowKey(rowIndex)).filter((id) => !!id && id >= 0) as number[];
  }

  private clearSelection(notify = true): void {
    this.selectedCells.clear();
    this.selectedRowsIndexes = [];
    this.editModeCellPosition = '';
    if (notify) {
      this.notifySelectedRowsChanged();
    }
  }

  private getSelectedColumnsWithRowsMap(): Map<number, Set<number>> {
    const columnsWithRows = new Map<number, Set<number>>();
    this.selectedCells.forEach((cell) => {
      const [rowIndex, cellIndex] = this.gridHelpers.decodeCellPosition(cell);
      if (!columnsWithRows.has(cellIndex)) {
        columnsWithRows.set(cellIndex, new Set());
      }
      const col = columnsWithRows.get(cellIndex) as Set<number>;
      col.add(rowIndex);
    });

    return columnsWithRows;
  }

  // endregion

  // region EDITING

  public isCellInEditMode(rowIndex: number, cellIndex: number): boolean {
    return this.editModeCellPosition === this.gridHelpers.encodeCellPosition(rowIndex, cellIndex);
  }

  public onCellStoppedEditing(key: StopEditingKey, rowIndex: number, cellIndex: number): void {
    delete this.initialInputsValues[this.gridHelpers.encodeCellPosition(rowIndex, cellIndex)];

    if (this.gridHelpers.encodeCellPosition(rowIndex, cellIndex) !== this.editModeCellPosition) {
      return;
    }

    if (key === 'None') {
      this.editModeCellPosition = '';
      this.gridOutline?.nativeElement.focus();
      return;
    }

    if (key !== 'Enter' && key !== 'Tab') {
      this.clearSelection(false);
      return;
    }

    let nextEditableCellIndex = cellIndex;
    for (let i = cellIndex + 1; i <= this.allColumnIds.length; i++) {
      if (i === this.allColumnIds.length) {
        nextEditableCellIndex = 1; // 0 when selection col is disabled
        rowIndex += 1;
        break;
      }
      if (!this.isCellDisabled(rowIndex, i)) {
        nextEditableCellIndex = i;
        break;
      }
    }
    this.editCell(rowIndex, nextEditableCellIndex);
  }

  private editCell(rowIndex: number, cellIndex: number): void {
    this.clearSelection(false);
    if (this.isEditingDisabled) {
      return;
    }
    const position = this.gridHelpers.encodeCellPosition(rowIndex, cellIndex);
    this.selectedCells.add(position);
    this.selectedRowsIndexes = [rowIndex];
    this.editModeCellPosition = position;
  }

  // endregion

  // region MOUSE

  public onMouseDown(rowIndex: number, cellIndex: number, event: MouseEvent, isHeaderCol = false): boolean {
    this.contextMenu?.hide();

    if (this.isSelectionDisabled) {
      return false;
    }
    if (event.button === 2) {
      return true;
    }

    if (this.wasClickedOutside && this.columns[cellIndex].type !== ColumnType.select) {
      this.selectedCells.clear();
      this.editModeCellPosition = '';
      this.wasClickedOutside = false;
    }

    const isOnlyThisCellSelected =
      this.selectedCells.size === 1 && [...this.selectedCells][0] === this.gridHelpers.encodeCellPosition(rowIndex, cellIndex);

    const isNumberOrText = this.columns[cellIndex].type === ColumnType.text || this.columns[cellIndex].type === ColumnType.number;

    if (isOnlyThisCellSelected && !event.ctrlKey && !event.shiftKey && isNumberOrText && !this.isCellInEditMode(rowIndex, cellIndex)) {
      this.editCell(rowIndex, cellIndex);
      return false;
    }

    if (!this.isCellInEditMode(rowIndex, cellIndex) || isHeaderCol) {
      // this is here because we need to wait for value changed event from input box
      // without that, input is switched to view mode before sending data
      setTimeout(() => {
        if (!event.shiftKey && !event.ctrlKey && !isHeaderCol) {
          this.clearSelection(false);
        }
        this.selectCell(rowIndex, cellIndex, event, isHeaderCol);
      });

      if (!(isHeaderCol && event.shiftKey)) {
        this.currentCellSelectionLocation = this.gridHelpers.encodeCellPosition(rowIndex, cellIndex);
      }
    }

    return true;
  }

  public onMouseUp(rowIndex: number, cellIndex: number, event: MouseEvent, isHeaderCell = false): boolean {
    if (this.isSelectionDisabled) {
      return false;
    }

    if (this.isContextMenuVisible || this.isCellInEditMode(rowIndex, cellIndex)) {
      return true;
    }

    // leave for debugging
    // console.log(`GRID: MouseUp: mousePosition: ${ this.mouseDownPosition}, currentPosition: ${currentPosition},
    // isDisabled: ${isDisabled},
    // isSelectionCol: ${isSelectionCol}, isContextMenuVisible: ${this.isContextMenuVisible}`);

    this.currentCellSelectionLocation = '';

    if (this.selectedRowsIndexes.length === 1) {
      this.notifySelectedRowsChanged();
    }

    return true;
  }

  public onDoubleClick(rowIndex: number, cellIndex: number, event: MouseEvent): void {
    const isDisabled = this.isCellDisabled(rowIndex, cellIndex);
    const isSelectionCol = this.isSelectionCol(cellIndex);
    if (!isDisabled && !isSelectionCol && !this.isContextMenuVisible && !event.shiftKey && !event.ctrlKey) {
      this.editCell(rowIndex, cellIndex);
    }
  }

  public onMouseOver(rowIndex: number, cellIndex: number, event: MouseEvent, isHeaderCol = false): void {
    if (event.buttons === 0) {
      this.currentCellSelectionLocation = '';
    }
    if (!this.currentCellSelectionLocation || this.isContextMenuVisible || this.isEditingDisabled || this.isCtrlKeyDown || this.isShiftKeyDown) {
      return;
    }
    this.selectCell(rowIndex, cellIndex, event, isHeaderCol);
  }

  public onMouseMove(event: any): void {
    if (this.isSelectionDisabled) {
      return;
    }
    if (event.buttons !== 1 || this.isEditingDisabled) {
      return;
    }

    // scroll only if mousemove is vertical
    const yDiff = this.lastMouseMoveY != null ? this.lastMouseMoveY - event.y : 0;
    const absYDiff = Math.abs(yDiff);
    const minYDiff = 10;

    if (absYDiff > 0 && absYDiff < minYDiff) {
      return;
    }
    if (absYDiff >= minYDiff) {
      const tableParams = this.getTableParameters();
      if (!tableParams) {
        return;
      }
      const { visibleArea } = tableParams;
      const [top, bottom] = visibleArea;
      const buffer = 50;

      // keep scrolling, even when mouse is not moving
      this.stopScrollingTable();
      const shouldScrollUp = event.y <= top + buffer && yDiff > 0;
      const shouldScrollDown = event.y >= bottom - buffer && yDiff < 0;

      if (shouldScrollUp || shouldScrollDown) {
        this.scrollInterval = setInterval(() => {
          this.scroll(shouldScrollUp ? 'up' : 'down');
        }, 20);
      }
    }

    this.lastMouseMoveY = event.y;
  }

  // endregion

  // region ROW HELPERS

  private getRowKey(rowIndex: number): number {
    return this.dataSource?.rows[rowIndex]?.rowData.Id ?? -1;
  }

  private isRowDisabled(rowIndex: number): boolean {
    return this.isReadOnlyMode || !!this.dataSource?.rows[rowIndex]?.isEditingDisabled;
  }

  private getDataRows(): ITableRow<T>[] {
    return this.dataSource?.rows.filter((row) => row.rowType !== 'insert-row') ?? [];
  }

  private getRow(rowIndex: number): ITableRow<T> | undefined {
    return this.dataSource?.rows[rowIndex];
  }

  private isAnyFullRowSelected(): boolean {
    return this.selectedRowsIndexes.some((rowIdx) => {
      return this.columns.every((col, idx) => {
        if (!col.visible) {
          return true;
        }
        return this.isCellSelected(rowIdx, idx);
      });
    });
  }

  // endregion

  // region COLUMN HELPERS

  private isColumnDisabled(cellIndex: number): boolean {
    return !!this.columns[cellIndex]?.disabled;
  }

  private isColumnReadOnly(cellIndex: number): boolean {
    return !!this.columns[cellIndex]?.readonly;
  }

  public get visibleColumnsLength(): number {
    return this.columns.filter((c) => c.visible).length;
  }

  // endregion

  // region CELL HELPERS

  public isCellDisabled(rowIndex: number, cellIndex: number): boolean {
    if (this.dataSource == null || this.gridConfig == null || rowIndex === -1) {
      return true;
    }

    const isDisabled =
      this.isColumnDisabled(cellIndex) ||
      this.isRowDisabled(rowIndex) ||
      this.gridConfig.isCellDisabled(this.dataSource.rows, rowIndex, cellIndex) ||
      this.isReadOnlyMode;

    if (isDisabled && this.isCellInEditMode(rowIndex, cellIndex)) {
      this.editModeCellPosition = '';
      this.selectedCells.delete(this.gridHelpers.encodeCellPosition(rowIndex, cellIndex));
    }

    return isDisabled;
  }

  public isCellReadOnly(rowIndex: number, cellIndex: number): boolean {
    if (!this.dataSource) {
      return true;
    }
    return this.isColumnReadOnly(cellIndex);
  }

  public isCellVisible(cellIndex: number): boolean {
    return this.columns[cellIndex]?.visible;
  }

  public isCellHidden(rowIndex: number, cellIndex: number): boolean {
    return !this.isCellVisible(cellIndex);
  }

  // endregion

  private findParentTag(className: string, event: MouseEvent, stepsCount = 10): boolean {
    let step = 0;
    for (const target of event.composedPath()) {
      const targetClassName = (target as HTMLElement).className;

      // quill editor's toolbar icons, uses SVGAnimatedStrings, which would break this code
      if (typeof targetClassName === 'string' && targetClassName && targetClassName.includes(className)) {
        return true;
      }
      if (step++ >= stepsCount) {
        break;
      }
    }

    return false;
  }

  // region CONTEXT MENU

  public async onContextMenuItemClick(event: 'paste' | 'copy' | 'copy-with-header' | 'merge-rows'): Promise<void> {
    if (event === 'paste' && (await this.inputsHelperService.checkResultsAndDeleteIfNeeded(this.isUiLockable))) {
      const clipboardText = await navigator.clipboard.readText();
      await this.paste(clipboardText);
    } else if (event === 'merge-rows' && (await this.inputsHelperService.checkResultsAndDeleteIfNeeded(this.isUiLockable))) {
      await this.mergeRows();
    } else if (event === 'copy') {
      await this.copyToClipboard();
    } else if (event === 'copy-with-header') {
      await this.copyToClipboard(true);
    }
  }

  public onContextMenu(rowIndex: number, cellIndex: number, event: MouseEvent): boolean {
    if (
      !this.elementRef?.nativeElement ||
      this.isContextMenuDisabled ||
      this.gridHelpers.encodeCellPosition(rowIndex, cellIndex) === this.editModeCellPosition // don't show menu when right-clicked on edited cell
    ) {
      return false;
    }

    const currentPosition = this.gridHelpers.encodeCellPosition(rowIndex, cellIndex);
    if (!this.selectedCells.has(currentPosition)) {
      this.currentCellSelectionLocation = this.gridHelpers.encodeCellPosition(rowIndex, cellIndex);
      this.selectCell(rowIndex, cellIndex, event, rowIndex === -1, true);
      this.currentCellSelectionLocation = 'null';
    }

    const offsetTop = 10;
    const offsetLeft = 15;

    this.contextMenuPosTop = event.y + offsetTop;
    this.contextMenuPosLeft = event.x + offsetLeft;

    this.contextMenu?.show(event);
    event.preventDefault();
    return false;
  }

  private async paste(textFromClipboard: string): Promise<void> {
    if (!this.gridConfig || this.editModeCellPosition.length > 0) {
      return;
    }

    let selectedColumn;
    if (this.dataSource != null) {
      const maxRow = getRowsForCalculations(this.dataSource.rows).length;

      const allRows = [...Array(maxRow)];

      const distinctColumns = [...new Set(Array.from(this.selectedCells).map((c) => this.gridHelpers.decodeCellPosition(c)[1]))];

      const isSingleColumnSelected =
        distinctColumns.length === 1 && allRows.every((_, rowIdx) => this.isCellSelected(rowIdx, distinctColumns[0]));

      if (isSingleColumnSelected) {
        selectedColumn = distinctColumns[0];
      }
    }

    const insertLocation: InsertLocation = 'paste';
    const rowIndex = this.selectedRowsIndexes[0];
    const refRow =
      selectedColumn != null ? this.getRow(0) : this.getRow(rowIndex) || this.dataSource?.rows.find((r) => r.rowType === 'insert-row');

    if (refRow == null) {
      return;
    }

    if (!this.gridHelpers.canRowsBePasted(this.selectedRowsIndexes[0], this.isFirstRowInsertingAllowed)) {
      return;
    }

    if (selectedColumn != null && !this.dataSource) {
      return;
    }

    if (await this.gridConfig?.beforePasteCheck()) {
      await this.gridConfig?.pasteFromClipboard(
        this.currentUnitSystem,
        refRow,
        this.currentScenarioId,
        insertLocation,
        textFromClipboard,
        this.isUiLockable,
        this.dataSource?.rows ?? [],
        this.selectedCells,
        selectedColumn,
      );
    }
  }

  private async mergeRows(): Promise<void> {
    if (!this.gridConfig || this.selectedRowsIndexes.length < 2) {
      return;
    }

    // General checks
    if (!this.gridHelpers.canRowsBeMerged(this.selectedRowsIndexes)) {
      return;
    }

    // Grid specific checks
    if (!this.gridConfig?.canRowsBeMerged(this.dataSource?.rows ?? [], this.selectedRowsIndexes)) {
      return;
    }

    const mergeRowsInGridOneTimeInstructionType = OneTimeInstructionType.mergeRowsInGrid;
    const oneTimeInstructionState = await firstValueFrom(this.store.select(getIsOneTimeInstructionShown));

    let changeConfirmed = true;

    if (OneTimeMessageHelpers.shouldShowInstruction(mergeRowsInGridOneTimeInstructionType, oneTimeInstructionState)) {
      const message = OneTimeMessageHelpers.getMessage(mergeRowsInGridOneTimeInstructionType);
      changeConfirmed = await this.modalService.showConfirm(message, 'Warning');
      this.store.dispatch(setOneTimeInstructionPopupShownAction({ instructionType: mergeRowsInGridOneTimeInstructionType }));
    }

    if (changeConfirmed) {
      await this.gridConfig?.mergeRows(this.isUiLockable, this.dataSource?.rows ?? [], this.selectedRowsIndexes);
    }
  }

  private async copyToClipboard(addHeader = false): Promise<void> {
    if (this.dataSource == null || this.gridConfig == null || this.currentUnitSystem == null) {
      return;
    }

    if (this.gridHelpers.getSelectionDimensions(this.selectedCells) == null) {
      this.modalService.showAlert('Cannot copy multiple selections').then();
      return;
    }

    let prevRowIndex: number | undefined;
    let headerToCopy = '';
    let dataToCopy = '';

    if (addHeader) {
      headerToCopy = this.createHeaderText();
    }

    for (let it = this.sortedSelectedCells.values(), val = null; (val = it.next().value); ) {
      const [rowIndex, cellIndex] = this.gridHelpers.decodeCellPosition(val);
      if (prevRowIndex === undefined) {
        prevRowIndex = rowIndex;
      }

      const row = this.getRow(rowIndex);
      if (row == null) {
        continue;
      }

      if (row.rowType === 'insert-row') {
        break;
      }

      if (this.isSelectionCol(cellIndex)) {
        continue;
      }

      if (prevRowIndex !== rowIndex) {
        dataToCopy += '\r\n';
        prevRowIndex = rowIndex;
      } else {
        if (dataToCopy) {
          dataToCopy += '\t';
        }
      }

      const colConfig = this.columns[cellIndex];

      const cell = this.dataSource.rows[rowIndex]?.rowData[this.allColumnIds[cellIndex] as keyof T] as any;
      if (cell === undefined) {
        continue;
      }
      if (colConfig.type === ColumnType.text) {
        dataToCopy += ConvertUnitPipe.decode(cell, this.currentUnitSystem);
      } else if (colConfig.lookupDataSourceType) {
        const lookupDs = this.gridConfig.getLookupDataSource(colConfig.lookupDataSourceType);
        dataToCopy += lookupDs.find((lookup) => lookup.value === cell)?.text;
      } else if (colConfig.unitSystem != null) {
        dataToCopy += UnitConverterHelper.convertFromSiWithDecimalPlaces(
          colConfig.unitSystem,
          this.currentUnitSystem,
          cell,
          colConfig.decimalPlaces,
        );
      } else if (colConfig.type === ColumnType.number) {
        dataToCopy += cell.toFixed(colConfig.decimalPlaces ?? 2);
      } else {
        dataToCopy += cell;
      }
    }
    try {
      // grid is pretty slow and if somebody highlights a lot of rows to copy, hit Ctrl+C, and change a window rapidly a crash may happen
      await navigator.clipboard.writeText(headerToCopy + dataToCopy);
      // eslint-disable-next-line no-empty
    } catch (err) {}
    /* eslint-enable no-cond-assign */
  }

  private createHeaderText(): string {
    let headerText = '';
    let subheaderText = '';
    let firstRowIndex;
    for (let it = this.sortedSelectedCells.values(), val = null; (val = it.next().value); ) {
      const [rowIndex, cellIndex] = this.gridHelpers.decodeCellPosition(val);
      firstRowIndex = firstRowIndex != null ? firstRowIndex : rowIndex;
      if (firstRowIndex !== rowIndex) {
        break;
      } else if (headerText) {
        headerText += '\t';
        subheaderText += '\t';
      }
      headerText += this.getHeaderText(cellIndex).trim();
      subheaderText += this.gridHelpers.getUnitSystemText(this.columns[cellIndex], this.currentUnitSystem).replace(/&nbsp;/g, '');
    }
    headerText += '\r\n';
    subheaderText += '\r\n';
    return headerText + subheaderText;
  }

  public get sortedSelectedCells(): Set<string> {
    const cellToArr = (cell: string): Array<number> => cell.split(':').map((x) => parseInt(x));

    const sortedCells = Array.from(this.selectedCells).sort((a, b) => {
      const arrA = cellToArr(a);
      const arrB = cellToArr(b);

      if (arrA[0] === arrB[0]) {
        return arrA[1] - arrB[1];
      }
      return arrA[0] - arrB[0];
    });
    return new Set(sortedCells);
  }

  // endregion
  public trackByFn = (index: number): number => index;

  public get isGridExpandable(): boolean {
    return this.groupByColumns.length > 0;
  }

  public changeFocusedCell(key: string, fromInput = false): void {
    if (this.dataSource == null || this.selectedCells.size === 0) {
      return;
    }

    // if any column is edited, we want to get events only from inputs
    if (this.editModeCellPosition.length && !fromInput) {
      return;
    }

    let selectedCell = this.selectedCells.values().next().value;

    // select first col if nothing is selected
    if (!selectedCell && !this.editModeCellPosition) {
      selectedCell = '0:1';
    }

    const cellToSelect = this.getCellToSelectByKey(key, selectedCell);

    if (!cellToSelect) {
      return;
    }

    const [oldRowIndex, oldCellIndex] = this.gridHelpers.decodeCellPosition(selectedCell);
    const [newRowIndex, newCellIndex] = this.gridHelpers.decodeCellPosition(cellToSelect);

    const oldRow = this.dataSource.rows[oldRowIndex];
    if (oldRow == null) {
      return;
    }

    this.selectedColSortOrder = this.editModeCellPosition.length ? (oldRow.rowData as any).SortOrder : undefined;

    if (oldRow.rowType === 'insert-row') {
      this.selectedColSortOrder = Math.max(...this.dataSource.rows.map((row) => (row.rowData as any).SortOrder)) + 1;
    }

    if (oldCellIndex === newCellIndex && oldRowIndex === newRowIndex) {
      return;
    }

    const isFullRowSelected = this.isWholeRowSelected(oldRowIndex);

    this.clearSelection(true);

    if (fromInput && !this.isCellDisabled(newRowIndex, newCellIndex)) {
      this.editCell(newRowIndex, newCellIndex);
    }

    if (isFullRowSelected && (key === 'ArrowDown' || key === 'ArrowUp')) {
      // if whole row was previously selected, and user pressed up or down, select whole row as well
      this.visibleCols.forEach((col) => this.selectedCells.add(this.gridHelpers.encodeCellPosition(newRowIndex, col)));
    } else {
      // otherwise focus next single cell
      this.selectedCells.add(this.gridHelpers.encodeCellPosition(newRowIndex, newCellIndex));
    }

    this.gridOutline?.nativeElement.focus();

    this.selectedRowsIndexes[0] = newRowIndex;
    this.notifySelectedRowsChanged();

    this.lastCellSelectionLocation = this.gridHelpers.encodeCellPosition(newRowIndex, newCellIndex);

    this.scrollToRowIfNotVisible(newRowIndex);
  }

  private getCellToSelectByKey(key: string, startCell: string, allowSkipNextLine = true): string | null {
    if (!this.dataSource) {
      return null;
    }
    const isOnlyOneCellSelected = this.selectedCells.size === 1;
    const isOnlyOneRowSelected = this.selectedRowsIndexes.length === 1;

    const [selectedCellPosition] = this.selectedCells;
    const [selRow, selCol] = this.gridHelpers.decodeCellPosition(selectedCellPosition);
    // biggest available row and cell idx
    const focusableCellsIndexes = this.columns
      .map((c, idx) => idx)
      .filter((i) => this.columns[i].type !== ColumnType.selection && this.columns[i].visible);

    const maxCell = focusableCellsIndexes[focusableCellsIndexes.length - 1];
    const minCell = focusableCellsIndexes[0];
    const maxRow = getRowsForCalculations(this.dataSource.rows).length - 1;

    const [oldRowIndex, oldCellIndex] = this.gridHelpers.decodeCellPosition(startCell);
    let [newRowIndex, newCellIndex] = [oldRowIndex, oldCellIndex];
    const prevRow = (): number => (newRowIndex > 0 ? newRowIndex - 1 : newRowIndex);
    const nextRow = (): number => (newRowIndex < maxRow ? newRowIndex + 1 : newRowIndex);

    switch (key) {
      case 'ArrowUp':
        newRowIndex = prevRow();
        break;
      case 'ArrowDown':
        newRowIndex = nextRow();
        break;
      case 'ArrowRight':
        if (newCellIndex < maxCell) {
          newCellIndex = focusableCellsIndexes[focusableCellsIndexes.indexOf(newCellIndex) + 1];
        } else if (allowSkipNextLine) {
          newCellIndex = focusableCellsIndexes[0];
          newRowIndex = nextRow();
        }
        break;
      case 'ArrowLeft':
        if (newCellIndex > minCell) {
          newCellIndex = focusableCellsIndexes[focusableCellsIndexes.indexOf(newCellIndex) - 1];
        } else if (allowSkipNextLine) {
          newCellIndex = maxCell;
          newRowIndex = prevRow();
        }
        break;

      case 'Tab':
      case 'Enter':
        if (isOnlyOneCellSelected && !this.isCellDisabled(selRow, selCol) && isOnlyOneRowSelected) {
          const [row, cell] = this.getNextCell();
          newRowIndex = row;
          newCellIndex = cell;
        }
        break;
    }

    return this.gridHelpers.encodeCellPosition(newRowIndex, newCellIndex);
  }

  private onEnterPress(): void {
    if (this.selectedCells.size === 0) {
      return;
    }
    const [row, cell] = this.gridHelpers.decodeCellPosition(this.selectedCells.values().next().value);
    if (this.selectedCells.size === 1 && !this.editModeCellPosition.length && !this.isCellDisabled(row, cell)) {
      this.editCell(row, cell);
    }
  }

  public getNextCell(): [number, number] {
    const [selectedCol] = this.selectedCells;
    const [currRow, currCel] = this.gridHelpers.decodeCellPosition(selectedCol);

    // get array of focusable cells ids in specified row
    const focusableCells = (row: number): number[] =>
      this.columns
        .filter((col) => col.colId !== ' ')
        .map((col, idx) =>
          !this.isCellDisabled(this.isInsertRow(currRow) ? row - 1 : row, idx + 1) &&
          col.visible &&
          !col.disabled &&
          (col.type === ColumnType.number || col.type === ColumnType.text || col.type === ColumnType.select || col.type === ColumnType.checkbox)
            ? idx + 1
            : -1,
        )
        .filter((i) => i >= 0);

    const rowsWithFocusableCols = getRowsForCalculations(this.dataSource?.rows ?? [])
      .map((row, idx) => (focusableCells(idx).length > 0 ? idx : -1))
      .filter((i) => i >= 0);

    const maxCell = Math.max(...focusableCells(currRow));
    const maxRow = Math.max(...rowsWithFocusableCols);

    const newSelectedCol = (): [number, number] => {
      if (currRow === maxRow && currCel === maxCell) {
        return [rowsWithFocusableCols[0], focusableCells(0)[0]];
      }
      if (currCel === maxCell) {
        const nextFocusableRow = rowsWithFocusableCols[rowsWithFocusableCols.indexOf(currRow) + 1];
        return [nextFocusableRow, focusableCells(nextFocusableRow)[0]];
      } else {
        return [currRow, focusableCells(currRow)[focusableCells(currRow).indexOf(currCel) + 1]];
      }
    };

    return newSelectedCol();
  }

  private getTableParameters(): TableViewParameters | undefined {
    if (!this.viewport) {
      return;
    }
    const table = this.viewport.elementRef.nativeElement.querySelector('table');
    const viewPortSize = this.viewport.getViewportSize();
    const headSize = table?.querySelector('thead')?.offsetHeight ?? 0;
    const top = this.viewport.elementRef.nativeElement.getBoundingClientRect().top + headSize;
    const visibleArea = [top, top + viewPortSize - headSize];
    return { table, visibleArea };
  }

  private scrollToRowIfNotVisible(rowID: number): void {
    const tableParams = this.getTableParameters();

    if (!tableParams) {
      return;
    }
    const { table, visibleArea } = tableParams;
    const row = table?.querySelector(`[data-row-id="${rowID}"]`);
    const rowRect = row?.getBoundingClientRect();

    if (!rowRect) {
      return;
    }

    const isRowAboveView = rowRect.top < visibleArea[0];
    const isRowBelowView = rowRect.top + rowRect.height > visibleArea[1];

    if (isRowAboveView || isRowBelowView) {
      this.scroll(isRowAboveView ? 'up' : 'down', rowRect.height);
    }
  }

  private scrollToRowIndex(targetRowIndex: number): void {
    const table = this.viewport?.elementRef.nativeElement.querySelector('table');
    const headSize = table?.querySelector('thead')?.offsetHeight ?? 0;
    this.viewport?.scrollToIndex(targetRowIndex - Math.round(headSize / this.rowHeight));
  }

  private isOrderChanged(changes: SimpleChanges): boolean {
    if (changes.dataSource == null || changes.dataSource.previousValue == null) {
      return false;
    }
    const getSortOrdersList = (list: Array<any>): number[] => list.map((row) => row.rowData.SortOrder || 0);
    return !IsEqual(getSortOrdersList(changes.dataSource.currentValue.rows), getSortOrdersList(changes.dataSource.previousValue.rows));
  }

  private updateFocusIfNeeded(changes: SimpleChanges): void {
    if (this.dataSource == null) {
      return;
    }

    if (this.isOrderChanged(changes) && this.selectedColSortOrder != null) {
      const reorderedRowId = this.dataSource.rows.findIndex((row) => (row.rowData as any).SortOrder === this.selectedColSortOrder);
      if (this.editModeCellPosition.length) {
        const [, cell] = this.gridHelpers.decodeCellPosition(this.editModeCellPosition);
        this.editCell(reorderedRowId, cell);
      }

      if (this.selectedCells.size === 1 && !this.editModeCellPosition.length) {
        const [, cell] = this.gridHelpers.decodeCellPosition(this.selectedCells.values().next().value);
        this.clearSelection();
        this.selectedCells.add(this.gridHelpers.encodeCellPosition(reorderedRowId, cell));
      }
      this.gridOutline?.nativeElement.focus();
      this.selectedColSortOrder = undefined;
    }
  }

  private onPageUpOrDown(code: string): void {
    if (this.isScrolling || this.dataSource == null) {
      return;
    }

    const tableParams = this.getTableParameters();
    if (!tableParams) {
      return;
    }
    const { table, visibleArea } = tableParams;
    const isPageUp = code === 'PageUp';
    const isPageDown = code === 'PageDown';

    // find target row
    const closestRow = (list: HTMLElement[], boundary: number, bottom = false): HTMLElement =>
      list.reduce((a, b) => {
        const offsetA = a.getBoundingClientRect().top;
        const offsetB = b.getBoundingClientRect().top;
        const targetBoundary = bottom ? boundary - a.getBoundingClientRect().height : boundary;
        return Math.abs(offsetA - targetBoundary) < Math.abs(offsetB - targetBoundary) ? a : b;
      });

    const rows = <HTMLElement[]>Array.from(table?.querySelectorAll('tbody tr') as NodeList);
    if (!rows.length) {
      return;
    }

    let targetRow;

    if (isPageDown) {
      targetRow = closestRow(rows, visibleArea[1], true);
    }
    if (isPageUp) {
      targetRow = closestRow(rows, visibleArea[0]);
    }

    const isCellEditMode = !!this.editModeCellPosition.length;
    const [row, cell] = this.gridHelpers.decodeCellPosition(
      isCellEditMode ? this.editModeCellPosition : this.selectedCells.values().next().value,
    );
    const newRowId = targetRow?.dataset.rowId != undefined ? parseInt(targetRow?.dataset.rowId) : null;
    const rowHeight = rows[0].offsetHeight;
    const maxRow = getRowsForCalculations(this.dataSource.rows).length - 1;

    // if selected column is equal to new column (so it's on the edge), scroll 10 rows and run this method once again
    if (newRowId != null && newRowId === row && newRowId < maxRow) {
      const defaultTowsToSkip = 10;
      let rowsToSkip = defaultTowsToSkip;

      if (isPageUp) {
        rowsToSkip = newRowId - defaultTowsToSkip >= 0 ? defaultTowsToSkip : newRowId;
      }
      if (isPageDown) {
        rowsToSkip = newRowId + defaultTowsToSkip <= maxRow ? defaultTowsToSkip : maxRow;
      }
      this.isScrolling = true;

      this.scroll(isPageUp ? 'up' : 'down', rowHeight * rowsToSkip);
      this.onPageUpOrDown(code);

      this.isScrolling = false;
      return;
    }

    // edit or select target column
    if (newRowId != undefined) {
      this.clearSelection(true);
      this.selectedCells.add(this.gridHelpers.encodeCellPosition(newRowId, cell));
      if (isCellEditMode && !this.isCellDisabled(newRowId, cell)) {
        this.editCell(newRowId, cell);
      }
      this.gridOutline?.nativeElement.focus();
    }
  }

  public stopScrollingTable(): void {
    this.lastMouseMoveY = null;
    clearInterval(this.scrollInterval);
    this.scrollInterval = null;
  }

  public onClickOutside(): void {
    // on next click, we need to know if user clicked outside of grid before
    this.wasClickedOutside = true;
  }

  private toggleSelectCell(rowIndex: number, cellIndex: number): void {
    if (this.isCellSelected(rowIndex, cellIndex)) {
      this.selectedCells.delete(this.gridHelpers.encodeCellPosition(rowIndex, cellIndex));
      const isAnyCellInRowSelected = this.columns.some((_, idx) => this.isCellSelected(rowIndex, idx));
      if (!isAnyCellInRowSelected) {
        this.selectedRowsIndexes = this.selectedRowsIndexes.filter((idx) => idx !== rowIndex);
      }
    } else {
      this.selectedCells.add(this.gridHelpers.encodeCellPosition(rowIndex, cellIndex));
      this.addSelectionRowIfNeeded(rowIndex);
    }
  }

  private addSelectionRowIfNeeded(rowIndex: number): void {
    if (!this.selectedRowsIndexes.includes(rowIndex)) {
      this.selectedRowsIndexes.push(rowIndex);
    }
  }

  private toggleSelectRow(rowIndex: number): void {
    if (this.isWholeRowSelected(rowIndex)) {
      this.selectedRowsIndexes = this.selectedRowsIndexes.filter((rowId) => rowId !== rowIndex);
      this.visibleCols.forEach((col) => this.selectedCells.delete(this.gridHelpers.encodeCellPosition(rowIndex, col)));
    } else {
      if (!this.selectedRowsIndexes.includes(rowIndex)) {
        this.selectedRowsIndexes.push(rowIndex);
      }

      this.visibleCols.forEach((col) => this.selectedCells.add(this.gridHelpers.encodeCellPosition(rowIndex, col)));
    }
  }

  private isInsertRow(rowIndex: number): boolean {
    return this.getRow(rowIndex)?.rowType === 'insert-row';
  }

  private get visibleCols(): number[] {
    return this.columns.map((c, idx) => (c.visible ? idx : null)).filter((c) => c != null) as number[];
  }

  private isWholeRowSelected(rowIndex: number): boolean {
    return this.visibleCols.every((col) => this.isCellSelected(rowIndex, col));
  }

  public getCellInitialValue(rowIndex: number, cellIndex: number): number | string | undefined {
    return this.initialInputsValues[this.gridHelpers.encodeCellPosition(rowIndex, cellIndex)];
  }

  private updateContextMenuItems(): void {
    this.contextMenuItems = [
      { label: 'Copy selected data', command: (): Promise<void> => this.onContextMenuItemClick('copy') },
      {
        label: 'Copy selected data and header',
        command: (): Promise<void> => this.onContextMenuItemClick('copy-with-header'),
      },
      {
        visible: this.isPastingEnabled && this.isPastePossible,
        label: 'Paste data',
        command: (): Promise<void> => this.onContextMenuItemClick('paste'),
      },
      {
        visible: this.isMergingOfRowsEnabled && this.isMergingOfRowsPossible,
        label: 'Merge selected rows',
        command: (): Promise<void> => this.onContextMenuItemClick('merge-rows'),
      },
    ];
  }
}

export interface TableViewParameters {
  table: HTMLElement | null;
  visibleArea: Array<number>;
}

export interface ISelectedRowProps {
  rowId: number;
  rowIndex: number;
}

export class Group {
  constructor(public Id: string) {}

  public level = 0;
  public parent: Group | undefined;
  public expanded = true;
  public totalCounts = 0;
  public rowData = {};

  public get visible(): boolean {
    return this.parent == null || (this.parent.visible && this.parent.expanded);
  }
}

export type keysGroup = 'level' | 'parent' | 'expanded' | 'totalCounts';
