import { AnnotationsPluginState, getEmptyOffsets, getState } from './state';
import { Chart, Point } from 'chart.js';
import {
  applyStateOffsets,
  drawingAnnotationToAnnotation,
  getDrawingAnnotationBoundingRect,
  getMaxAllowedOffset,
  getXSegment,
  getYSegment,
  isOverAnnotation,
  resizeHandleAtPoint,
  resizeHandleRadius,
} from './helpers';
import { DrawingAnnotation } from './annotation';
import { AllowedOffsets, OffsetMinMax } from './types';
import { isPointInsideRect, pointsDistance, rectCenter } from '@dunefront/common/common/math-geometry-helpers';
import { getChartDrawingArea, getDrawingAreaWithPadding, getPointFromEvent } from '../plugins-common-helpers';

type MouseHandler = (chart: Chart, event: any) => void;

const textBoxMinWidth = 20;
const textBoxMinHeight = 20;

const mouseDown = (chart: Chart, event: MouseEvent): void => {
  const state = getState(chart);
  if (state.options.readonly) {
    return;
  }

  const point = getPointFromEvent(event);

  state.mode = 'default';

  if (state.options.canStartDrag?.() === false) {
    return;
  }

  for (const annotation of [...state.annotations].reverse()) {
    const isSelected = state.selectedAnnotation?.id === annotation.id;
    const resizeHandle = isSelected ? resizeHandleAtPoint(point, annotation) : undefined;

    if (resizeHandle != null) {
      state.mode = 'resize';
      state.resizeHandle = resizeHandle;
    } else if (pointsDistance(point, annotation) <= resizeHandleRadius) {
      state.mode = 'move-all';
    } else if (isOverAnnotation(point, annotation)) {
      state.mode = 'move-text';
    }

    if (state.mode !== 'default') {
      state.selectedAnnotation = annotation;
      state.highlightedAnnotationId = annotation.id;
      break;
    }
  }

  if (state.mode !== 'default') {
    state.prevPoint = point;
    state.mouseDownPoint = point;
    state.options.dragChanged?.(true);
  } else {
    state.selectedAnnotation = undefined;
    state.prevPoint = undefined;
    state.mouseDownPoint = undefined;
  }

  chart.render();
};

const mouseMove = (chart: Chart, event: MouseEvent): void => {
  const state = getState(chart);
  if (state.options.readonly) {
    return;
  }

  const point = getPointFromEvent(event);

  const drawingArea = getChartDrawingArea(chart);
  if (!isPointInsideRect(point, drawingArea)) {
    mouseUp(chart);
  }

  let updateNeeded = handleDrag(chart, state, point);
  updateNeeded ||= handleHover(chart, point);

  if (updateNeeded) {
    chart.render();
  }
};

export const getEmptyAllowedOffsets = (): AllowedOffsets => ({
  offsetX: { min: 0, max: 0 },
  offsetY: { min: 0, max: 0 },
  offsetBoxX: { min: 0, max: 0 },
  offsetBoxY: { min: 0, max: 0 },
  offsetBoxWidth: { min: 0, max: 0 },
  offsetBoxHeight: { min: 0, max: 0 },
});

const getMaxAllowedOffsets = (chart: Chart, state: AnnotationsPluginState): AllowedOffsets => {
  const drawingArea = getDrawingAreaWithPadding(chart);
  const { selectedAnnotation } = state;
  const result = getEmptyAllowedOffsets();

  if (selectedAnnotation == null) {
    return result;
  }

  const drawingAreaSegmentX = getXSegment(drawingArea);
  const drawingAreaSegmentY = getYSegment(drawingArea);

  const selectedAnnotationWithCurrentOffsets = applyStateOffsets(selectedAnnotation, state);
  const { textBox } = selectedAnnotationWithCurrentOffsets;
  const boundingRect = getDrawingAnnotationBoundingRect(selectedAnnotationWithCurrentOffsets);

  result.offsetX = getMaxAllowedOffset(getXSegment(boundingRect), drawingAreaSegmentX);
  result.offsetY = getMaxAllowedOffset(getYSegment(boundingRect), drawingAreaSegmentY);

  result.offsetBoxX = getMaxAllowedOffset(getXSegment(textBox), drawingAreaSegmentX);
  result.offsetBoxY = getMaxAllowedOffset(getYSegment(textBox), drawingAreaSegmentY);

  result.offsetBoxWidth = {
    min: textBoxMinWidth - textBox.width,
    max: Math.min(Math.abs(result.offsetBoxX.min), Math.abs(result.offsetBoxX.max)),
  };
  result.offsetBoxHeight = {
    min: textBoxMinHeight - textBox.height,
    max: Math.min(Math.abs(result.offsetBoxY.min), Math.abs(result.offsetBoxY.max)),
  };

  return result;
};

const correctedOffset = (offset: number, { min, max }: OffsetMinMax): number => {
  if (offset < min) {
    return min;
  } else if (offset > max) {
    return max;
  }

  return offset;
};

const handleDrag = (chart: Chart, state: AnnotationsPluginState, point: Point): boolean => {
  const { selectedAnnotation, selectedAnnotationOffsets } = state;

  if (state.mode === 'default' || selectedAnnotation == null || state.prevPoint == null) {
    return false;
  }

  const xDiff = point.x - state.prevPoint.x;
  const yDiff = point.y - state.prevPoint.y;
  const offsets = selectedAnnotationOffsets ?? getEmptyOffsets();

  const maxOffsets = getMaxAllowedOffsets(chart, state);

  // move entire annotation
  if (state.mode === 'move-all') {
    offsets.offsetX += correctedOffset(xDiff, maxOffsets.offsetX);
    offsets.offsetY += correctedOffset(yDiff, maxOffsets.offsetY);
    offsets.offsetBoxX += correctedOffset(xDiff, maxOffsets.offsetX);
    offsets.offsetBoxY += correctedOffset(yDiff, maxOffsets.offsetY);
  }
  // move text box
  else if (state.mode === 'move-text') {
    offsets.offsetBoxX += correctedOffset(xDiff, maxOffsets.offsetBoxX);
    offsets.offsetBoxY += correctedOffset(yDiff, maxOffsets.offsetBoxY);
  }
  // resize text box
  else if (state.mode === 'resize' && state.resizeHandle) {
    const textBoxCenter = rectCenter(selectedAnnotation.textBox);
    const mouseDownPoint = state.mouseDownPoint;

    if (state.resizeHandle.resizeX && mouseDownPoint != null) {
      const isOnLeft = mouseDownPoint.x < textBoxCenter.x;
      const diff = isOnLeft ? -xDiff : xDiff;
      offsets.offsetBoxWidth += correctedOffset(diff * 2, maxOffsets.offsetBoxWidth);
    }

    if (state.resizeHandle.resizeY && mouseDownPoint != null) {
      const isOnTop = mouseDownPoint.y < textBoxCenter.y;
      const diff = isOnTop ? -yDiff : yDiff;
      offsets.offsetBoxHeight += correctedOffset(diff * 2, maxOffsets.offsetBoxHeight);
    }

    // keep text box center fixed
    offsets.offsetBoxX = -(offsets.offsetBoxWidth / 2);
    offsets.offsetBoxY = -(offsets.offsetBoxHeight / 2);
  }

  state.prevPoint = point;
  state.selectedAnnotationOffsets = offsets;
  state.selectedAnnotationUpdated = true;

  return true;
};

const handleHover = (chart: Chart, point: Point): boolean => {
  const state = getState(chart);
  if (state.options.readonly) {
    return false;
  }

  const prevHighlightedAnnotationId = state.highlightedAnnotationId;
  const highlightedAnnotation = annotationUnderPoint(chart, point);
  const newHighlightedId = highlightedAnnotation?.annotation.id;

  chart.canvas.style.cursor = highlightedAnnotation?.cursor ?? 'default';

  if (newHighlightedId !== prevHighlightedAnnotationId) {
    state.highlightedAnnotationId = newHighlightedId;
    return true;
  }

  return false;
};

export const annotationUnderPoint = (chart: Chart, point: Point): { annotation: DrawingAnnotation; cursor: string } | undefined => {
  const state = getState(chart);

  for (const annotation of [...state.annotations].reverse()) {
    const annotationWithOffset = applyStateOffsets(annotation, state);
    const isSelected = state.selectedAnnotation?.id === annotation.id;

    if (isSelected) {
      const resizeHandle = resizeHandleAtPoint(point, annotationWithOffset);
      if (resizeHandle != null) {
        return { annotation, cursor: resizeHandle.cursor };
      }
    }

    const isOverMoveAllHandle = pointsDistance(point, annotationWithOffset) <= resizeHandleRadius;
    if (isOverMoveAllHandle || isOverAnnotation(point, annotationWithOffset)) {
      return { annotation, cursor: 'move' };
    }
  }

  return undefined;
};

const mouseUp = (chart: Chart): void => {
  const state = getState(chart);
  if (state.options.readonly) {
    return;
  }

  if (state.selectedAnnotationUpdated === true && state.selectedAnnotation != null) {
    const annotationWithOffsets = applyStateOffsets(state.selectedAnnotation, state);

    state.selectedAnnotationOffsets = undefined;
    state.selectedAnnotationUpdated = false;

    state.options.annotationMoved?.(drawingAnnotationToAnnotation(annotationWithOffsets, chart));
  }

  state.mode = 'default';
  state.options.dragChanged?.(false);
};

const mouseOut = (chart: Chart): void => {
  mouseUp(chart);
};

const mouseDblClick = (chart: Chart, event: MouseEvent): void => {
  const state = getState(chart);
  if (state.options.readonly) {
    return;
  }

  const point = getPointFromEvent(event);

  const drawingAnnotation = annotationUnderPoint(chart, point)?.annotation;
  const annotation = drawingAnnotation != null ? state.options.annotations.find((a) => a.id === drawingAnnotation.id) : null;
  if (annotation != null) {
    state.options.annotationDoubleClicked?.(annotation);
  }

  state.mode = 'default';
};

export const addHandlers = (chart: Chart): void => {
  addHandler(chart, chart.canvas, 'mousedown', mouseDown);
  addHandler(chart, chart.canvas, 'mousemove', mouseMove);
  addHandler(chart, chart.canvas, 'mouseup', mouseUp);
  addHandler(chart, chart.canvas, 'mouseout', mouseOut);
  addHandler(chart, chart.canvas, 'dblclick', mouseDblClick);
};

export const removeHandlers = (chart: Chart): void => {
  removeHandler(chart, 'mousedown');
  removeHandler(chart, 'mousemove');
  removeHandler(chart, 'mouseup');
  removeHandler(chart, 'mouseout');
  removeHandler(chart, 'dblclick');
};

export const addHandler = (chart: Chart, target: HTMLElement, type: string, handler: MouseHandler): void => {
  const { handlers } = getState(chart);

  removeHandler(chart, type);

  handlers[type] = {
    handler: (event): void => handler(chart, event),
    target,
  };

  target.addEventListener(type, handlers[type].handler);
};

export const removeHandler = (chart: Chart, type: string): void => {
  const { handlers } = getState(chart);
  const handler = handlers[type];

  if (handler != null) {
    handler.target.removeEventListener(type, handler.handler);
    delete handlers[type];
  }
};
