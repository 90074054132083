import { ArgumentOutOfRangeError } from '../exceptions/argumentOutOfRangeException';
import { Validation } from './validation';
import { ArithmeticException } from '../exceptions/arithmeticException';
import { areStringsTheSame } from './helpers';

export class GeneralCalculations {
  public static LinearInterpolation(
    targetVar1: number,
    lowerBoundVar1: number,
    upperBoundVar1: number,
    lowerBoundVar2: number,
    upperBoundVar2: number,
  ): number {
    if (targetVar1 < Math.min(lowerBoundVar1, upperBoundVar1) || targetVar1 > Math.max(lowerBoundVar1, upperBoundVar1)) {
      throw new ArgumentOutOfRangeError('targetVar1', 'The target variable must be within the specified bounds');
    } else if (targetVar1 === upperBoundVar1) {
      return upperBoundVar2;
    } else if (targetVar1 === lowerBoundVar1) {
      return lowerBoundVar2;
    } else {
      return lowerBoundVar2 + ((targetVar1 - lowerBoundVar1) * (upperBoundVar2 - lowerBoundVar2)) / (upperBoundVar1 - lowerBoundVar1);
    }
  }

  public static CircleArea(diameter1: number, diameter2?: number): number {
    const internalArea =
      diameter2 !== undefined
        ? Math.PI * Math.abs(GeneralCalculations.Pow(diameter1 / 2.0, 2) - GeneralCalculations.Pow(diameter2 / 2.0, 2))
        : Math.PI * GeneralCalculations.Pow(diameter1 / 2.0, 2);
    return Validation.UICheckNumberValidity(internalArea);
  }

  public static CirclePerimeter(diameter1: number, diameter2?: number): number {
    if (diameter2 !== undefined) {
      return Math.PI * (diameter1 + diameter2);
    }
    return Math.PI * diameter1;
  }

  public static HydraulicDiameter(flowArea: number, wettedPerimeter: number): number {
    return (4.0 * flowArea) / wettedPerimeter;
  }

  public static EquivalentDiameter(flowArea: number): number {
    const equivalentDiameter: number = Math.sqrt((4.0 * flowArea) / Math.PI);
    return Validation.UICheckNumberValidity(equivalentDiameter);
  }

  public static Pow(value: number, exponent: number): number {
    if (value === 0) {
      if (exponent <= 0) {
        throw new ArithmeticException('zero cannot be raised to the power of zero or a negative number');
      } else {
        return value;
      }
    } else if (exponent === 0) {
      return 1.0;
    } else if (value === 1 || exponent === 1) {
      return value;
    } else if (exponent === 0.5) {
      return Math.sqrt(value);
    } else if (exponent < 0 || exponent % 1 !== 0) {
      return Math.pow(value, exponent);
    } else {
      let powValue: number = value;
      for (let power = 2; power <= exponent; power++) {
        powValue *= value;
      }
      return powValue;
    }
  }

  public static IsValueWithinTolerance(primaryValue: number, secondaryValue: number, tolerance: number): boolean {
    return Math.abs(primaryValue - secondaryValue) <= tolerance;
  }

  public static DegreesToRadians(angle: number): number {
    return (angle * Math.PI) / 180.0;
  }

  public static AreStringsTheSame(string1: string, string2: string): boolean {
    return areStringsTheSame(string1, string2);
  }

  public static DoesArrayContainsString(array: string[], string: string): boolean {
    return array.some((item) => areStringsTheSame(item, string));
  }
}
