import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Color, HorizontalAlignment, LineStyle, VerticalAlignment } from '@dunefront/common/modules/reporting/dto/chart.types';
import { EnumHelpers } from '@dunefront/common/utils/enum.helpers';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { IMarkerStyle } from '@dunefront/common/modules/reporting/dto/chart-marker.dto';
import { ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';
import { presetFontSizeItems, presetLineThicknessItems } from '../../../chart/chart.helpers';

@Component({
  selector: 'app-chart-marker-details',
  templateUrl: './chart-marker-details.component.html',
  styleUrls: ['./chart-marker-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartMarkerDetailsComponent {
  @Input() public markerStyle!: IMarkerStyle;
  @Input() public defaultMarkerStyle?: IMarkerStyle;
  @Input() public showHorizontalAlignment = false;
  @Input() public showVerticalAlignment = false;
  @Input() public disabled = false;

  @Output() public valueChanged = new EventEmitter<ObjectChangeProp<any>>();

  public UnitType = UnitSystem;
  public Colors = EnumHelpers.StringEnumToSelectItemArray(Color);

  public HorizontalAlignments = EnumHelpers.EnumToISelectItemArray(HorizontalAlignment, false);
  public VerticalAlignments = EnumHelpers.EnumToISelectItemArray(VerticalAlignment, false);
  public LineStyles = EnumHelpers.EnumToISelectItemArray(LineStyle, false);

  public VerticalAlignment = VerticalAlignment;
  public HorizontalAlignment = HorizontalAlignment;
  protected readonly LineStyle = LineStyle;

  public readonly fontSizeSelect = presetFontSizeItems;
  public readonly lineThicknessSelect = presetLineThicknessItems;

  public get isVerticalTextIndentationDisabled(): boolean {
    return this.markerStyle.ChartMarkerVerticalTextAlignment === VerticalAlignment.center;
  }

  public get isHorizontalTextIndentationDisabled(): boolean {
    return this.markerStyle.ChartMarkerHorizontalTextAlignment === HorizontalAlignment.center;
  }

  public emitStyleValue(event: ObjectChangeProp<any>): void {
    this.valueChanged.emit(event);
  }

  public isValueDiffThanDefault(key: keyof IMarkerStyle): boolean {
    return this.defaultMarkerStyle != null && this.markerStyle[key] !== this.defaultMarkerStyle[key];
  }

  public getDefaultTooltip(key: keyof IMarkerStyle, itemsEnum?: VerticalAlignment | HorizontalAlignment | LineStyle): string | null {
    if (this.defaultMarkerStyle == null) {
      return null;
    }
    const defaultValue =
      itemsEnum != null ? EnumHelpers.getEnumName(itemsEnum, this.defaultMarkerStyle[key] as number) : this.defaultMarkerStyle[key];
    return this.isValueDiffThanDefault(key) ? 'Default value: ' + defaultValue : null;
  }
}
