<ng-container *ngIf="row.rowData">
  <ng-container *ngIf="colConfig.type === ColumnType.selection; else dataCell">
    <ng-container *ngIf="row.rowData">
      <span [class]="errorColumnCss" class="selection-col" [attr.data-cy]="dataCy + '_select'">
        <ng-container *ngIf="row.isValid && !row.isWarning && selected">&#10151;</ng-container>
      </span>
    </ng-container>
    <ng-container *ngIf="isInsertRow">
      <span class="insert-icon" [attr.data-cy]="dataCyGrid + '_insert'">&#8727;</span>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #dataCell [ngSwitch]="colConfig.type">
  <app-grid-select
    *ngSwitchCase="ColumnType.select"
    [id]="'grid_' + row.rowData.Id"
    [source]="row.rowData"
    [key]="colConfig.colId"
    [errorMessage]="row.error[$any(colConfig.colId)]"
    [warningMessage]="row.warning ? row.warning[$any(colConfig.colId)] : ''"
    [isInEditMode]="isInEditMode"
    (valuedChanged)="valueChanged.emit($event)"
    [renderAsLabel]="disabled || !shouldShowSelectInput"
    (stopEditing)="stopEditing.emit($event)"
    (changeFocusCell)="changeFocusCell.emit($event)"
    [dataSource]="getLookupDataSource()"
    [placeholder]="getLookupDataPlaceholder()"
    [dataCy]="dataCy + '_select'"
    [selected]="selected"
  ></app-grid-select>
  <app-check-box
    *ngSwitchCase="ColumnType.checkbox"
    [source]="row.rowData"
    [key]="colConfig.colId"
    [isUiLockable]="!colConfig.isNotUiLockable"
    [disabled]="disabled"
    [id]="'cb_' + row.rowData.Id.toString() + '_' + colConfig.colId.toString()"
    (valueChanged)="valueChanged.emit($event)"
    [dataCy]="dataCy + '_checkbox'"
  >
  </app-check-box>
  <app-input
    *ngSwitchDefault
    [source]="row.rowData"
    [key]="colConfig.colId"
    [id]="'grid_' + row.rowData.Id"
    [overrideUnitType]="colConfig.overrideUnitType"
    [emitOnSaveChanges]="true"
    [name]="$any(colConfig.colId)"
    [showSymbol]="false"
    [disabled]="disabled"
    [errorMessage]="row.error[$any(colConfig.colId)]"
    [warningMessage]="row.warning ? row.warning[$any(colConfig.colId)] : ''"
    [noBorderRadius]="true"
    (valueChanged)="valueChanged.emit($event)"
    [renderAsLabel]="readonly ? true : !isInEditMode"
    [isGridInput]="true"
    [elementId]="inputElementId"
    [align]="align"
    (stopEditing)="stopEditing.emit($event)"
    (keyPressed)="keyPressed.emit($event)"
    (changeFocusCell)="changeFocusCell.emit($event)"
    [isInsertRow]="isInsertRow"
    [isTextInput]="colConfig.type === ColumnType.text"
    [unitSystem]="colConfig.type === ColumnType.text || colConfig.unitSystem === undefined ? UnitType.None : colConfig.unitSystem"
    [decimalPlaces]="colConfig.decimalPlaces"
    [placeholder]="colConfig.placeholder"
    [dataCy]="dataCy + '_input'"
    [selected]="selected"
    [isOnlyOneSelected]="isOnlyOneSelected"
    [allowOptionalStringValue]="allowOptionalStringValue"
    [castNullToNumber]="castNullToNumber"
    [maxLength]="colConfig.maxLength || 0"
    [isUiLockable]="!colConfig.isNotUiLockable"
    [initialInputValue]="initialInputValue"
  >
  </app-input>
</ng-template>
