import { createReducer, on } from '@ngrx/store';
import {
  addUserDefinedFormulaColumnAction,
  fetchDataForEquationFailed,
  fetchDataForEquationSuccess,
  insertNewVariableSuccess,
  removeVariable,
  setColumnForEdit,
  updateEquation,
  updateEquationColumnFields,
  updateEquationPreview,
  updateEquationVariable,
} from './equation.actions';
import { DictionaryWithArray } from '@dunefront/common/common/state.helpers';
import { EquationColumnFactory } from '@dunefront/common/modules/equation/equation-column';
import { EquationReducerHelper } from './equation.reducer.helper';
import { EquationModuleState } from '@dunefront/common/modules/equation/equation-module.state';

export const EquationModuleStateFeatureName = 'equation';

const equationInitialState: EquationModuleState = {
  preview: [],
  data: DictionaryWithArray.clear(),
  equationColumn: EquationColumnFactory.createEmpty(0),
};

export const equationModuleReducer = createReducer<EquationModuleState>(
  equationInitialState,
  on(addUserDefinedFormulaColumnAction, (state, action): EquationModuleState => {
    return { ...state, equationColumn: EquationColumnFactory.createEmpty(action.fileId) };
  }),
  on(updateEquationPreview, (state, action): EquationModuleState => {
    return { ...state, preview: action.preview };
  }),
  on(fetchDataForEquationSuccess, (state, action): EquationModuleState => {
    return { ...state, preview: action.equationPreview };
  }),
  on(fetchDataForEquationFailed, (state, action): EquationModuleState => {
    return { ...state, preview: [] };
  }),
  on(updateEquation, (state, action): EquationModuleState => {
    return EquationReducerHelper.updateEquation(state, action.equation);
  }),
  on(insertNewVariableSuccess, (state, action): EquationModuleState => {
    return EquationReducerHelper.insertVariable(state, action.variable);
  }),
  on(updateEquationColumnFields, (state, action): EquationModuleState => {
    return EquationReducerHelper.updateEquationColumnFields(state, action.changes);
  }),
  on(updateEquationVariable, (state, action): EquationModuleState => {
    return EquationReducerHelper.updateEquationVariable(state, action.variable);
  }),
  on(removeVariable, (state, action): EquationModuleState => {
    return EquationReducerHelper.removeVariable(state, action.index);
  }),
  on(setColumnForEdit, (state, action): EquationModuleState => {
    return {
      ...state,
      equationColumn: EquationColumnFactory.fromDto(action.column),
    };
  }),
);
