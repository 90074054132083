import { createFeatureSelector, createSelector } from '@ngrx/store';
import { getCurrentRangeId } from '../range/range.selectors';
import { NoteFeatureName, NoteModuleState } from './note.reducers';
import { newNoteDto, NoteDto } from '@dunefront/common/dto/note.dto';
import { getCurrentScenarioId } from '../scenario/scenario.selectors';
import { getCurrentAppModuleType } from '../ui/ui.selectors';
import { isDataAnalysis, isEvaluate, isTrendAnalysis } from '../menu-selectors/menu-selectors.helpers';
import { RangeConstants } from '@dunefront/common/dto/range.dto';

export interface ICurrentNotes {
  scenarioNote: NoteDto;
  rangeNote: NoteDto | null;
}

const getNoteModuleState = createFeatureSelector<NoteModuleState>(NoteFeatureName);

export const getCurrentNotes = createSelector(
  getNoteModuleState,
  getCurrentAppModuleType,
  getCurrentScenarioId,
  getCurrentRangeId,
  (state, appModule, currentScenarioId, currentRangeId): ICurrentNotes => {
    const scenarioNote = state.Notes.find((note) => note.RangeId === -1) || newNoteDto(currentScenarioId, -1);

    const isAppModuleValidForRangeNote = isEvaluate(appModule) || isTrendAnalysis(appModule) || isDataAnalysis(appModule);
    const isRangeValidForRangeNote = currentRangeId !== RangeConstants.EntireRangeId && currentRangeId !== RangeConstants.EmptyRangeId;
    const shouldShowRangeNote = isAppModuleValidForRangeNote && isRangeValidForRangeNote;

    const rangeNote = shouldShowRangeNote
      ? state.Notes.find((note) => note.RangeId === currentRangeId) || newNoteDto(0, currentRangeId)
      : null;

    return { scenarioNote, rangeNote };
  },
);
