export interface WellDto {
  ScenarioId: number;
  LocationType: LocationType;
  SeaLevelDepth: number;
  SeaBedDepth: number;
  SurfaceTemp: number;
  BottomholeStaticTemperature: number;
  BottomholeCirculatingTemperature: number;
  TreatmentType: TreatmentType;
  IsLossRateCalculated: boolean;
  CaliperMultiplier: number;
  CaliperTolerance: number;
  MinWaterTemp: number;
  MinWaterTempDepth: number;
  ZonePressureType: ZonePressureType;
  IsZoneDepthByMD: boolean;
  IsSurveyDepthByMD: boolean;
  TemperatureProfileType: TemperatureProfileType;
}

export enum LocationType {
  Land = 0,
  Offshore = 1,
}

export enum ZonePressureType {
  Pressure_Gradient = 0,
  Pressure = 1,
  Equivalent_Mud_Weight = 2,
}

export enum TemperatureProfileType {
  Fixed = 0,
  Simple_Calc = 1,
  Detailed_Calc = 2,
}

export enum TreatmentType {
  Gravel_Pack = 0,
  High_Rate_Water_Pack = 1,
}
