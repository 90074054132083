import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { GlobalOptionsDto } from '@dunefront/common/dto/global-options.dto';
import {
  selectValidatedOrganizationGlobalOptions,
  selectValidatedUserGlobalOptions,
} from '../../../../+store/common-db/common-db.selectors';
import { changeAndSaveGlobalOptionsProperty } from '../../../../+store/common-db/common-db.actions';
import { DbDependentComponent } from '../../../../common-modules/db-connection/db-dependent.component';
import { ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';
import { WritableCommonDbType } from '@dunefront/common/dto/common-dto.interfaces';

@Component({
  selector: 'app-file-options',
  templateUrl: './file-options.component.html',
  styleUrls: ['./file-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileOptionsComponent extends DbDependentComponent implements OnInit {
  public dbType: WritableCommonDbType = 'User';
  public globalOptions!: GlobalOptionsDto;

  constructor(store: Store, cdRef: ChangeDetectorRef) {
    super(store, cdRef);
  }

  public override ngOnInit(): void {
    super.ngOnInit();

    this.subscription.add(
      this.store
        .select(this.dbType === 'Organization' ? selectValidatedOrganizationGlobalOptions : selectValidatedUserGlobalOptions)
        .subscribe((globalOptions) => {
          this.globalOptions = globalOptions;

          this.cdRef.markForCheck();
        }),
    );
  }

  public onValueChange(props: ObjectChangeProp<GlobalOptionsDto>): void {
    this.store.dispatch(changeAndSaveGlobalOptionsProperty({ props, commonDbType: this.dbType }));
  }
}
