import { loadScenarioDataSuccessAction, updateRowSuccess } from '../../app.actions';
import { createReducer, on } from '@ngrx/store';
import { LeakoffCoefficientCalculatorModuleReducerHelper } from '../leakoff-coefficient-calculator/leakoff-coefficient-calculator.reducer.helper';
import {
  LeakoffCoefficientCalculatorInitialState,
  LeakoffCoefficientCalculatorModuleState,
} from '@dunefront/common/modules/calculators/leakoff-coefficient-calculator/leakoff-coefficient-calculator-module.state';
import { removeDataResultsFromStoreIfNeeded } from '../../data-storage/data-storage.actions';

export const LeakoffCoefficientCalculatorModuleFeatureName = 'leakoffCoefficientCalculator';

export const leakoffCoefficientCalculatorModuleReducer = createReducer<LeakoffCoefficientCalculatorModuleState>(
  LeakoffCoefficientCalculatorInitialState,
  on(loadScenarioDataSuccessAction, (state, action) =>
    LeakoffCoefficientCalculatorModuleReducerHelper.onLoadScenarioDataSuccessAction(
      state,
      action.loadScenarioResponse.leakoffCoefficientCalculatorModule,
    ),
  ),
  on(updateRowSuccess, (state, action) =>
    LeakoffCoefficientCalculatorModuleReducerHelper.onUpdateLeakoffCoefficientCalculatorSuccess(state, action),
  ),
  on(
    removeDataResultsFromStoreIfNeeded,
    (state, action): LeakoffCoefficientCalculatorModuleState =>
      LeakoffCoefficientCalculatorModuleReducerHelper.onRemoveDataResultsFromStore(state, action.deleteResultsFilter),
  ),
);
