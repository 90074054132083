import { WsAction } from '../../ws.action';

export const AboutModuleName = 'AboutModule';

export enum AboutModuleActionTypes {
  AboutServerRequest = '[AboutModule] AboutServerRequest',
  CheckForUpdates = '[AboutModule] CheckForUpdates',
  FetchDownloadLink = '[AboutModule] FetchDownloadLink',
  GetLastPeriodicVersionCheck = '[AboutModule] GetLastPeriodicVersionCheck',
  FetchLicenseSessions = '[AboutModule] FetchLicenseSessions',
  KillLicenseSessions = '[AboutModule] KillLicenseSessions',
}

export class AboutServerAction extends WsAction {
  public override readonly type = AboutModuleActionTypes.AboutServerRequest;
}

export class CheckForUpdatesAction extends WsAction {
  public override readonly type = AboutModuleActionTypes.CheckForUpdates;
}

export class FetchDownloadLink extends WsAction {
  public override readonly type = AboutModuleActionTypes.FetchDownloadLink;

  constructor(public version: string) {
    super();
  }
}

export class GetLastPeriodicVersionCheck extends WsAction {
  public override readonly type = AboutModuleActionTypes.GetLastPeriodicVersionCheck;
}

export class FetchLicenseSessions extends WsAction {
  public override readonly type = AboutModuleActionTypes.FetchLicenseSessions;

  constructor(public licenseId: string) {
    super();
  }
}

export class KillLicenseSessions extends WsAction {
  public override readonly type = AboutModuleActionTypes.KillLicenseSessions;

  constructor(
    public licenseId: string,
    public sessionIds: string[],
  ) {
    super();
  }
}

export type AboutModuleActions =
  | AboutServerAction
  | CheckForUpdatesAction
  | FetchDownloadLink
  | GetLastPeriodicVersionCheck
  | FetchLicenseSessions
  | KillLicenseSessions;
