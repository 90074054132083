<ng-container *ngIf="!hidden">
  <label
    [ngClass]="cssClass"
    [class.disabled]="disabled"
    [class.label-padding-left]="labelPaddingLeft"
    [class.highlight]="highlight"
    [style.width.px]="labelWidth"
    *ngIf="label"
    [pTooltip]="tooltipText || ''"
    positionStyle="absolute"
    [positionTop]="28"
    #labelEl
    [positionLeft]="-labelEl.offsetWidth"
  >
    {{ label }}
    <i class="pi pi-info-circle info-icon" *ngIf="tooltipText"></i>
  </label>

  <div [ngClass]="inputCssClass" [style.max-width.px]="maxWidth" [style.min-width.px]="minWidth" [style.width.px]="width">
    <app-input
      [source]="source"
      [sourceDefaults]="sourceDefaults"
      [hideUnitBox]="hideUnitBox"
      [key]="key"
      [highlight]="highlight"
      [isUiLockable]="isUiLockable"
      [dataCy]="dataCy"
      [name]="name"
      [errorMessage]="errorMessage"
      [offset]="offset"
      [min]="min"
      [max]="max"
      [unitSystem]="unitType"
      [disabled]="disabled"
      [value]="currValue"
      [unitLabel]="unitLabel"
      [decimalPlaces]="decimalPlaces"
      [isTextInput]="isTextInput"
      [maxWidth]="maxWidth"
      [align]="isTextInput ? 'left' : 'right'"
      [showSymbol]="showSymbol"
      [forceShowSymbol]="forceShowSymbol"
      [autoFocus]="autoFocus"
      [isStringComparisonStrict]="isStringComparisonStrict"
      [isFullWidth]="true"
      [triggerOnKeyPress]="triggerOnKeyPress"
      [width]="inputWidth"
      (primitiveValueChanged)="primitiveValueChanged.emit($event)"
      (valueChanged)="valueChanged.emit($event)"
      [trim]="trim"
    ></app-input>
  </div>
</ng-container>
