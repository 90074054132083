import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { ModalService } from '../../../../common-modules/modals/modal.service';
import { DbDependentComponent } from '../../../../common-modules/db-connection/db-dependent.component';
import {
  calculateFriction,
  updateFrictionCalculatorRow,
} from '../../../../+store/calculators/friction-calculator/friction-calculator.actions';
import { FlowPathType } from '@dunefront/common/modules/calculators/resuspension-calculator/resuspension-calculator.dto';
import { getIsFluidAndGravelValid } from '../../../../+store/app.selector';
import { take } from 'rxjs/operators';
import { FrictionCalculatorResultDto } from '@dunefront/common/modules/calculators/friction-calculator/friction-calculator.dto';
import { getValidatedFrictionCalculatorModuleState } from '../../../../+store/calculators/friction-calculator/friction-calculator.selectors';
import { ValidatedFrictionCalculator } from '@dunefront/common/modules/calculators/friction-calculator/friction-calculator-module.state';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { changeProp, ErrorHelper, ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';
import { LeftNavHelperService } from '../../../../shared/components/left-nav/left-nav-helper.service';
import { IRadioItem } from '@dunefront/common/common/radio.helpers';
import { StoreCrudPropsFactory } from '@dunefront/common/common/common-store-crud.interfaces';
import { PanelHelpMode } from '../../../../shared/components/help-button/help-buton.component';
import { FluidType } from '@dunefront/common/modules/fluid/dto/fluid.dto';
import { getFluidById } from '../../../../+store/fluid/fluid.selectors';

@Component({
  selector: 'app-friction-calculator',
  templateUrl: './friction-calculator.component.html',
  styleUrls: ['./friction-calculator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FrictionCalculatorComponent extends DbDependentComponent {
  public xAxisValues: IRadioItem<number>[] = [
    {
      value: 1,
      text: 'Rate',
    },
    {
      value: 0,
      text: 'Velocity',
    },
  ];

  public flowPathDimensionsValues: IRadioItem<number>[] = [
    {
      value: 0,
      text: 'Pipe',
    },
    {
      value: 1,
      text: 'Annulus',
    },
    {
      value: 2,
      text: 'Round Shunt',
    },
    {
      value: 3,
      text: 'Rectangular Shunt',
    },
  ];

  public UnitType = UnitSystem;

  public FlowPathType = FlowPathType;

  public frictionCalculatorData!: ValidatedFrictionCalculator;
  public frictionCalculatorResult: FrictionCalculatorResultDto[] = [];
  public degradationFactor = 1;
  public PanelHelpMode = PanelHelpMode;

  constructor(
    store: Store,
    cdRef: ChangeDetectorRef,
    private modalService: ModalService,
    public runCalculationService: LeftNavHelperService,
  ) {
    super(store, cdRef);
    this.subscription.add(
      store.select(getValidatedFrictionCalculatorModuleState).subscribe(async (frictionCalculatorState) => {
        this.frictionCalculatorData = frictionCalculatorState.FrictionCalculator;
        this.frictionCalculatorResult = frictionCalculatorState.FrictionCalculatorResult;
        await this.setDegradationFactor();
        cdRef.markForCheck();
      }),
    );

    this.subscription.add(runCalculationService.calculationSubject.subscribe(() => this.calculate()));
    this.onHelpChange('calculators', 'friction-calculator');
  }

  private async setDegradationFactor(): Promise<void> {
    if (!this.frictionCalculatorData.UseDegradationFactor) {
      this.degradationFactor = 1;
    } else if (this.frictionCalculatorData.FluidId != null) {
      this.store
        .select(getFluidById(this.frictionCalculatorData.FluidId))
        .pipe(take(1))
        .subscribe((fluid) => {
          if (fluid?.Type !== FluidType.Newtonian && this.frictionCalculatorData.FlowPathType === FlowPathType.Pipe) {
            this.degradationFactor = fluid?.WashpipeViscousDegradationFactor ?? 1;
          } else if (fluid?.Type !== FluidType.Newtonian && this.frictionCalculatorData.FlowPathType === FlowPathType.Annulus) {
            this.degradationFactor = fluid?.AnnulusViscousDegradationFactor ?? 1;
          } else {
            this.degradationFactor = 1;
          }
        });
    }
  }

  public async submitCalcData(props: ObjectChangeProp<ValidatedFrictionCalculator>): Promise<void> {
    if (this.frictionCalculatorData[props.key] !== props.value) {
      this.store.dispatch(
        updateFrictionCalculatorRow(StoreCrudPropsFactory.updateRow(changeProp(this.frictionCalculatorData, props), props)),
      );
    }
  }

  public async calculate(): Promise<void> {
    if (!this.frictionCalculatorData.isValid && !this.isDevValidationDisabled) {
      await this.modalService.showAlert(ErrorHelper.ERROR_CURRENT_SCREENS_MESSAGE_HEADER, 'Warning');
      return;
    }
    this.store
      .select(getIsFluidAndGravelValid(this.frictionCalculatorData.FluidId, this.frictionCalculatorData.GravelId))
      .pipe(take(1))
      .subscribe(async (invalidFields) => {
        if (invalidFields.length > 0) {
          await this.modalService.showAlert(invalidFields, 'Warning');
          return;
        }
        this.store.dispatch(calculateFriction({ ScenarioId: this.frictionCalculatorData.ScenarioId }));
      });
  }

  protected readonly FluidType = FluidType;
}
