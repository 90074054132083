import { ColumnType, IGridColumnConfig } from '../../../../../shared/components/grid/grid.interfaces';
import { PSDResult } from '@dunefront/common/modules/psd-analysis/model/psd/psd';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';

export const getStandaloneScreensGridConfig = (
  isCalculateSandProduction: boolean,
  producedSandMassLabel: string,
): IGridColumnConfig<PSDResult>[] => {
  return [
    { disabled: true, visible: true, colId: ' ', type: ColumnType.selection },
    {
      disabled: true,
      visible: true,
      colId: 'Description',
      headerText: 'PSD Description',
      width: 100,
      type: ColumnType.text,
    },
    {
      disabled: true,
      visible: true,
      colId: 'Depth',
      unitSystem: UnitSystem.Long_Length,
      headerText: 'PSD Depth',
      width: 100,
      type: ColumnType.number,
    },
    {
      disabled: true,
      visible: true,
      colId: 'CoberlyCriteria',
      unitSystem: UnitSystem.Screen_Opening,
      headerText: 'Coberly (2 x d10)',
      width: isCalculateSandProduction ? 150 : 200,
      decimalPlaces: 3,
      type: ColumnType.text,
    },
    {
      disabled: true,
      visible: true,
      colId: 'D10',
      unitSystem: UnitSystem.Screen_Opening,
      headerText: 'Gulf Coast (d10)',
      width: isCalculateSandProduction ? 100 : 200,
      decimalPlaces: 3,
      type: ColumnType.number,
    },
    {
      disabled: true,
      visible: isCalculateSandProduction,
      colId: 'ProducedSandMass',
      unitSystem: UnitSystem.Mass,
      width: 150,
      headerText: `Produced Sand Mass (${producedSandMassLabel} Wire-Wrap)`,
      reportingHeaderName: `Produced Sand Mass \n(${producedSandMassLabel} Wire-Wrap)`,
      type: ColumnType.number,
      allowOptionalStringValue: true,
    },
  ];
};
