import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input, NgModule, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { getIsRibbonDisplayed } from '../../+store/ui/ui.selectors';
import { GridResizeService } from '../../shared/services/grid-resize.service';
import { skip } from 'rxjs/operators';
import { HelpButtonComponent, PanelHelpMode } from '../../shared/components/help-button/help-buton.component';

@Component({
  selector: 'app-page-panel',
  templateUrl: './page-panel.component.html',
  styleUrls: ['./page-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PagePanelComponent implements OnInit {
  @Input() public panelHelpMode?: PanelHelpMode;
  @Input() public header = '';
  @Input() public allowFullScreen = true;
  @Input() public noHeader = false;

  private subscription = new Subscription();
  public isFullScreen = false;

  public get showHeader(): boolean {
    if (this.noHeader) {
      return false;
    }

    return this.header.length > 0 || this.panelHelpMode !== undefined;
  }

  constructor(
    private store: Store,
    private gridResizeService: GridResizeService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  public ngOnInit(): void {
    if (this.allowFullScreen) {
      this.subscription.add(
        this.store
          .select(getIsRibbonDisplayed)
          .pipe(skip(1))
          .subscribe(() => {
            this.changeDetectorRef.markForCheck();
            this.gridResizeService.resize();
          }),
      );
    }
  }

  @HostBinding('class.full-screen')
  public get className(): boolean {
    return this.isFullScreen;
  }

  public toggleMode(): void {
    this.isFullScreen = !this.isFullScreen;
    this.gridResizeService.resize();
  }

  public switchToPanelMode(): void {
    this.isFullScreen = false;
    this.gridResizeService.resize();
    this.changeDetectorRef.markForCheck();
  }

  public get showHelpBtn(): boolean {
    return this.panelHelpMode != null;
  }
}

@NgModule({
  imports: [CommonModule, HelpButtonComponent],
  declarations: [PagePanelComponent],
  exports: [PagePanelComponent],
})
export class PagePanelModule {}
