import { createReducer, on } from '@ngrx/store';
import { initialWellModuleState, WellModuleState } from '@dunefront/common/modules/well/well-module.state';
import { deleteRowsSuccess, insertRowsSuccess, loadScenarioDataSuccessAction, resetScenarioState, updateRowSuccess } from '../app.actions';
import * as actions from './well.actions';
import { changeTemperatureProfilePropertyAction } from './well.actions';
import { WellModuleReducerHelper } from './well.reducer.helper';

export const WellModuleFeatureName = 'well';

export const wellModuleReducer = createReducer<WellModuleState>(
  initialWellModuleState,
  on(resetScenarioState, (): WellModuleState => initialWellModuleState),
  on(
    loadScenarioDataSuccessAction,
    (state, action): WellModuleState =>
      WellModuleReducerHelper.onWellModuleLoaded(
        state,
        action.loadScenarioResponse.wellModule,
        action.loadScenarioResponse.settingsModule,
        action.loadScenarioResponse.scenarioId,
      ),
  ),
  on(updateRowSuccess, (state, action): WellModuleState => WellModuleReducerHelper.onUpdateWellSuccess(state, action)),
  on(insertRowsSuccess, (state, action): WellModuleState => WellModuleReducerHelper.onInsertSurveyRowSuccess(state, action)),
  on(deleteRowsSuccess, (state, action): WellModuleState => WellModuleReducerHelper.deleteSurveyRows(state, action)),
  on(updateRowSuccess, (state, action): WellModuleState => WellModuleReducerHelper.updateSurveyRowSuccess(state, action)),
  on(actions.changeWellPropertyAction, (state, action): WellModuleState => WellModuleReducerHelper.changeWellProperty(state, action)),
  on(
    changeTemperatureProfilePropertyAction,
    (state, action): WellModuleState => WellModuleReducerHelper.changeTemperatureProfileProperty(state, action),
  ),
  on(insertRowsSuccess, (state, action): WellModuleState => WellModuleReducerHelper.insertCasingRowSuccess(state, action)),
  on(deleteRowsSuccess, (state, action): WellModuleState => WellModuleReducerHelper.deleteCasingRowsSuccess(state, action)),
  on(updateRowSuccess, (state, action): WellModuleState => WellModuleReducerHelper.updateCasingRowSuccess(state, action)),
  on(insertRowsSuccess, (state, action): WellModuleState => WellModuleReducerHelper.insertCaliperRowSuccess(state, action)),
  on(deleteRowsSuccess, (state, action): WellModuleState => WellModuleReducerHelper.deleteCaliperRows(state, action)),
  on(updateRowSuccess, (state, action): WellModuleState => WellModuleReducerHelper.updateCaliperRowSuccess(state, action)),
  on(insertRowsSuccess, (state, action): WellModuleState => WellModuleReducerHelper.insertZoneRowsSuccess(state, action)),
  on(deleteRowsSuccess, (state, action): WellModuleState => WellModuleReducerHelper.deleteZoneRows(state, action)),
  on(updateRowSuccess, (state, action): WellModuleState => WellModuleReducerHelper.updateZoneRowSuccess(state, action)),
);
