import { UnitConverter } from '../../converter.interfaces';
import { RateUnit } from '../../../dto/unit-system.dto';

export class RateConverter extends UnitConverter {
  public static symbols: Array<string> = ['', 'm³/s', 'm³/min', 'ft³/min', 'ft³/s', 'bbl/min', 'L/min', 'galUS/min'];

  public static unit = RateUnit;

  public static fromSi(value: number, unit: RateUnit): number {
    switch (unit) {
      case RateUnit.Cubic_meter_per_second: {
        return value;
      }
      case RateUnit.Cubic_meter_per_minute: {
        return value * 60;
      }
      case RateUnit.Cubic_foot_per_minute: {
        return value * 2.11889e3;
      }
      case RateUnit.Cubic_foot_per_second: {
        return value * 35.3146667;
      }
      case RateUnit.Barrel_per_minute: {
        return value * 3.77388646e2;
      }
      case RateUnit.Liter_per_minute: {
        return value * 6e4;
      }
      case RateUnit.US_gallon_per_minute: {
        return value * 1.58503231e4;
      }
      default: {
        throw new TypeError('Unknown Rate Unit:' + unit);
      }
    }
  }

  public static toSi(value: number, unit: RateUnit): number {
    switch (unit) {
      case RateUnit.Cubic_meter_per_second: {
        return value;
      }
      case RateUnit.Cubic_meter_per_minute: {
        return value / 60;
      }
      case RateUnit.Cubic_foot_per_minute: {
        return value / 2.11889e3;
      }
      case RateUnit.Cubic_foot_per_second: {
        return value / 35.3146667;
      }
      case RateUnit.Barrel_per_minute: {
        return value / 3.77388646e2;
      }
      case RateUnit.Liter_per_minute: {
        return value / 6e4;
      }
      case RateUnit.US_gallon_per_minute: {
        return value / 1.58503231e4;
      }
      default: {
        throw new TypeError('Unknown Rate Unit:' + unit);
      }
    }
  }

  public static getSymbol(unit: RateUnit): string {
    return this.symbols[unit];
  }
}
