import { BasePptSectionGenerator, PptContext } from '../base-ppt-section-generator';
import { IUnitSystemDto } from '@dunefront/common/dto/unit-system.dto';
import { firstValueFrom } from 'rxjs';
import { selectCurrentUnitSystem } from '../../../../../../+store/units/units.selectors';
import { CommonReportDataHelpers } from '../../common/common-report-data-helpers';
import { PptDocumentTableGeneratorHelper } from '../ppt-document-table-generator.helper';
import { ConvertUnitPipe } from '@dunefront/common/modules/units/convert-unit.pipe/convert-unit.pipe';
import { getPSDsAsGrid } from '../../../../../../+store/psd-analysis/psd-analysis.selectors';
import { PSDResult } from '@dunefront/common/modules/psd-analysis/model/psd/psd';
import { PresetColumnsWidths } from '../../doc/document-table-generator.helper';

export class PsdSummaryDataPptGenerator extends BasePptSectionGenerator {
  constructor(protected override context: PptContext & { convertUnitPipe: ConvertUnitPipe }) {
    super(context);
  }

  public async generate(): Promise<void> {
    const title = `PSD Summary`;
    const { store, convertUnitPipe } = this.context;
    const currentUnitSystem: IUnitSystemDto = await firstValueFrom(this.store.select(selectCurrentUnitSystem));
    const psdGrid = await firstValueFrom(store.select(getPSDsAsGrid));

    const psdSummaryTableData = CommonReportDataHelpers.createPsdSummaryTableData(psdGrid, currentUnitSystem, convertUnitPipe);
    const tableRows = PptDocumentTableGeneratorHelper.createTableRows<PSDResult>(psdSummaryTableData, null, currentUnitSystem);
    const presetColumnsWidths: PresetColumnsWidths = { 2: 15, 3: 25 };

    this.addTableSlide({ title, tableRows, presetColumnsWidths });
  }
}
