import { IFile, Repository } from '../dto/file.dto';

export class ProjectFileHelpers {
  public static getRootFolder(repository: Repository): IFile {
    return { Repository: repository, FileType: 'folder', Name: '', Folder: [] };
  }

  public static getParentFolder(file: IFile): IFile {
    if (file.Folder.length === 0) {
      return {
        FileType: 'folder',
        Folder: [],
        Name: '',
        Repository: file.Repository,
      };
    }
    return {
      FileType: 'folder',
      Folder: file.Folder.slice(0, file.Folder.length - 1),
      Name: file.Folder[file.Folder.length - 1],
      Repository: file.Repository,
    };
  }

  public static getElectronFileFromPath(absoluteFilePath: string): IFile {
    return {
      FileType: 'type-in-filename',
      Folder: [],
      Name: absoluteFilePath,
      Repository: 'electron',
    };
  }

  public static getFileDisplayName(file: IFile): string {
    if (file.Repository == 'electron') {
      // last path component for backslash based paths (Windows)
      let lastComponent = file.Name.split('\\').slice(-1)[0];

      // last path component for slash based paths (Linux/macOS)
      lastComponent = lastComponent.split('/').slice(-1)[0];

      //remove extension
      if (['ppf', 'dpf', 'fpf'].includes(lastComponent.split('.').slice(-1)[0].toLowerCase())) {
        lastComponent = lastComponent.substring(0, lastComponent.length - 4);
      }

      return lastComponent;
    }

    return file.Name;
  }

  /**
   * For Cloud it returns folder path as relative to root,
   * For Electron it returns absolute path extracted from filename
   * @param file
   */
  public static getFileLocationToDisplay(file: IFile): string {
    // for Electron
    if (file.Repository == 'electron') {
      // it extracts file name out of fill path
      const displayName = this.getFileDisplayName(file);
      // slice out filepath up without filename and extension
      return file.Name.slice(0, file.Name.lastIndexOf(displayName));
    }
    // for Cloud
    else {
      const pathComponents = [file.Repository, ...file.Folder];
      return `/${pathComponents.join('/')}`;
    }
  }
}
