import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { CalculationStatus } from '../../../+store/calculation-engine/calculation-engine-module.state';
import {
  CalculationContext,
  cancelJobsAction,
  updateRefVariablesValidateAndAddCalculationToQueueAction,
} from '../../../+store/calculation-engine/calculation-engine.actions';
import { Store } from '@ngrx/store';
import { getCurrentScenarioId } from '../../../+store/scenario/scenario.selectors';
import { IDbInfo } from '@dunefront/common/modules/db-connection/db-connection.actions';
import { getConnectedDbInfo } from '../../../+store/backend-connection/backend-connection.selectors';
import { Router } from '@angular/router';
import { getCurrentAppModuleType } from '../../../+store/ui/ui.selectors';
import { ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import { getLeftNavProps, ILeftNavProps } from './left-nav.helpers';
import { openModuleSelectorDialog } from '../../../+store/licensing/licensing.actions';
import { IGetCurrentFeaturesResult } from '@dunefront/common/modules/licensing/licensing.interfaces';
import { getCurrentFeatures } from '../../../+store/licensing/licensing.selectors';

@Injectable()
export class LeftNavHelperService {
  public calculationSubject: Subject<void> = new Subject();
  private leftNavProps!: ILeftNavProps;
  private moduleType!: ModuleType;
  private scenarioId!: number;
  private dbInfo: IDbInfo | undefined;
  private currentLicenseFeatures: IGetCurrentFeaturesResult = { accessibleFeatures: [], mainFeature: undefined, addonFeatures: [] };

  constructor(
    protected store: Store,
    public router: Router,
  ) {
    store.select(getCurrentAppModuleType).subscribe((appModule) => (this.moduleType = appModule));
    store.select(getLeftNavProps).subscribe((leftNavProps) => {
      this.leftNavProps = leftNavProps;
    });
    store.select(getCurrentScenarioId).subscribe((scenarioId) => (this.scenarioId = scenarioId));
    store.select(getConnectedDbInfo).subscribe((dbInfo) => (this.dbInfo = dbInfo));
    store.select(getCurrentFeatures).subscribe((currentFeatures) => (this.currentLicenseFeatures = currentFeatures));
  }

  public async moduleSelectionClick(): Promise<void> {
    if (this.dbInfo) {
      this.store.dispatch(openModuleSelectorDialog({ redirectHomeAfterCancel: false, file: this.dbInfo.file }));
    }
  }

  public emitCalculation(): void {
    if (
      [ModuleType.Simulate, ModuleType.Evaluate, ModuleType.Trend_Analysis, ModuleType.Simulate_CH, ModuleType.Simulate_Disp].includes(
        this.moduleType,
      )
    ) {
      this.startOrCancelSimulation();
    } else {
      this.calculationSubject.next();
    }
  }

  public startOrCancelSimulation(): void {
    if (!this.dbInfo) {
      return;
    }
    if (this.leftNavProps.activeJob?.status === CalculationStatus.running) {
      this.store.dispatch(
        cancelJobsAction({
          fileHash: this.dbInfo.fileHash,
          jobIds: [this.leftNavProps.activeJob.jobId],
        }),
      );
    } else {
      this.store.dispatch(
        updateRefVariablesValidateAndAddCalculationToQueueAction({
          scenariosAndRanges: [{ scenarioId: this.scenarioId, rangeId: this.leftNavProps.currentRangeId }],
          fileHash: this.dbInfo.fileHash,
          redirect: true,
          moduleType: this.moduleType,
          calculationContext: CalculationContext.SINGLE_SCENARIO,
          currentLicenseFeatures: this.currentLicenseFeatures,
        }),
      );
    }
  }
}
