import { ImportDataModuleState } from '../import-data-module.state';
import {
  filterWithoutDeltaTime,
  IImportTemplateConfiguration,
  IImportTemplateDto,
} from '@dunefront/common/modules/data-storage/dto/import-template/import-template.dto';
import { CommonDbType } from '@dunefront/common/dto/common-dto.interfaces';

export class ImportTemplateConverter {
  public static createTemplate(state: ImportDataModuleState, name: string, dbType: CommonDbType): IImportTemplateDto {
    const template: IImportTemplateDto = {
      Id: -1,
      Type: dbType,
      Name: name,
      TemplateConfig: this.createTemplateConfig(state),
    };
    return template;
  }

  private static createTemplateConfig(state: ImportDataModuleState): IImportTemplateConfiguration {
    const templateConfig: IImportTemplateConfiguration = {
      colConfigs: state.colConfigs.filter(filterWithoutDeltaTime).map((c) => ({ ...c, name: c.name.replace(/-\d*$/, '') })),
      delimiterConfig: state.delimiterConfig,
      filePropertiesConfig: state.filePropertiesConfig,
      importDataType: state.importDataType,
      includedColumnIds: state.includedColumnIds,
    };
    return templateConfig;
  }
}
