import { IUnitSystemDto, UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { defaultExtraTableConfig } from '../doc/document-table-generator.helper';
import { blueColor, lightBlueColor, whiteColor } from '../../report-consts';
import PptxGenJS from 'pptxgenjs';
import {
  CommonReportDataHelpers,
  IBulletPoint,
  IDataRowWithCode,
  IReportEnumItemMap,
  IReportTableColumnConfig,
  ITableData,
  keyValueGridConfig,
  KeyValueTableRow,
} from '../common/common-report-data-helpers';
import { TableGeneratorHelperBase } from '../common/table-generator-helper.base';
import { defaultTableProps } from './style-constants';
import TableRow = PptxGenJS.TableRow;
import TableCell = PptxGenJS.TableCell;
import BorderProps = PptxGenJS.BorderProps;

const borderSolid: BorderProps = { type: 'solid', pt: 0.5, color: blueColor };
const borderNone: BorderProps = { type: 'none' };

export class PptDocumentTableGeneratorHelper extends TableGeneratorHelperBase {
  public static createTableRows<T>(
    tableData: ITableData<T>,
    enumItemMap: IReportEnumItemMap<T> | null | undefined,
    currentUnitSystem: IUnitSystemDto,
    extraConfig = defaultExtraTableConfig,
    isKeyValueTable = false,
  ): TableRow[] {
    tableData = this.hideDescriptionIfNeeded(tableData);

    const tableRows: TableRow[] = [];

    if (extraConfig.drawHeader) {
      const tableRow: TableRow = [];

      for (const dataCell of tableData.dataCells) {
        const text = (dataCell.reportingHeaderName ?? dataCell.headerText) || (typeof dataCell.colId === 'string' ? dataCell.colId : '');
        tableRow.push({ text, options: { fontSize: 9, color: whiteColor, bold: true, fill: { color: blueColor } } });
      }

      tableRows.push(tableRow);
    }

    if (extraConfig.drawHeader && extraConfig.showHeaderUnit) {
      const tableRow: TableRow = [];
      for (const dataCell of tableData.dataCells) {
        const text = CommonReportDataHelpers.getCellUnit(
          currentUnitSystem,
          dataCell.unitSystem ?? UnitSystem.None,
          dataCell.overrideUnitSymbol,
        );
        const margin = (defaultTableProps.margin as number) ?? 0;
        tableRow.push({
          text,
          options: {
            fontSize: 8,
            color: whiteColor,
            fill: { color: blueColor },
            margin: [0, margin, margin, margin],
          },
        });
      }

      tableRows.push(tableRow);
    }

    for (const dataRow of tableData.dataRows) {
      const tableRow: TableRow = [];
      const isAltRow = tableData.dataRows.indexOf(dataRow) % 2 === 1;

      for (let i = 0; i < tableData.dataCells.length; i++) {
        const col = tableData.dataCells[i];
        const text = CommonReportDataHelpers.getTableCellValue(col, dataRow, enumItemMap, currentUnitSystem);
        const border = extraConfig.drawBorders ? this.getCellBorders(col, dataRow, tableData) : borderNone;
        const color = extraConfig.drawShadows && isAltRow ? lightBlueColor : whiteColor;
        const bold = isKeyValueTable && i > 0;
        tableRow.push({ text, options: { fontSize: 9, bold, border, fill: { color } } });
      }

      tableRows.push(tableRow);
    }

    return tableRows;
  }

  private static getCellBorders<T>(
    col: IReportTableColumnConfig<T>,
    row: IDataRowWithCode<T>,
    { dataCells, dataRows }: ITableData<T>,
  ): [BorderProps, BorderProps, BorderProps, BorderProps] {
    const isFirstCol = col === dataCells[0];
    const isLastRow = row === dataRows[dataRows.length - 1];

    return [borderNone, borderSolid, isLastRow ? borderSolid : borderNone, isFirstCol ? borderSolid : borderNone];
  }

  public static createGenericKeyValueTable<T = any>(
    gridConfig: IReportTableColumnConfig<T>[],
    tableDataEntry: IDataRowWithCode<T>,
    currentUnitSystem: IUnitSystemDto,
    enumItemMap: IReportEnumItemMap<T> | null | undefined,
  ): TableRow[] {
    const tableData = CommonReportDataHelpers.createGenericKeyValueTableData(gridConfig, tableDataEntry, currentUnitSystem, enumItemMap);
    return this.createGenericKeyValueTableRowsWithTableData(tableData, currentUnitSystem);
  }

  public static createGenericKeyValueTableRowsWithTableData(
    tableData: ITableData<KeyValueTableRow>,
    currentUnitSystem: IUnitSystemDto,
  ): TableRow[] {
    const extraTableConfig = { ...defaultExtraTableConfig, drawBorders: false, drawShadows: false, drawHeader: false };

    return this.createTableRows(tableData, null, currentUnitSystem, extraTableConfig, true);
  }

  public static bulletPointsToKeyValueTableData(bulletPoints: IBulletPoint[]): ITableData<KeyValueTableRow> {
    const getPrefix = (level: number): string => {
      return ' '.repeat(level * 6) + (level === 0 ? '●' : '○') + '  ';
    };

    return {
      dataRows: bulletPoints.map(({ level, label, value }) => ({
        name: getPrefix(level) + label,
        value,
      })),
      dataCells: keyValueGridConfig,
    };
  }
}

export const emptyTableCell: TableCell = { text: '' };
