import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeResourceUrl, SafeScript, SafeStyle, SafeUrl } from '@angular/platform-browser';
import { AssetService } from '../services/asset.service';

@Pipe({
  name: 'helpUrl',
})
export class HelpUrlPipe implements PipeTransform {
  constructor(
    protected sanitizer: DomSanitizer,
    private assetsService: AssetService,
  ) {}

  public transform(value: any): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.assetsService.getAssetUrl(value));
  }
}

@NgModule({
  declarations: [HelpUrlPipe],
  exports: [HelpUrlPipe],
})
export class HelpUrlPipeModule {}
