import { Injectable } from '@angular/core';
import { ImportColumnDto } from '@dunefront/common/modules/data-storage/dto/import-column.dto';
import { ModalService } from '../../../modal.service';
import * as dayjs from 'dayjs';

@Injectable({
  providedIn: 'root',
})
export class ImportFileStartTimeService {
  constructor(private modalService: ModalService) {}

  public async getStartTime(argColumn: ImportColumnDto, presetDateTime?: number): Promise<number | undefined> {
    if (argColumn.StartDate == null) {
      // delta time, need to get start date
      const date = await this.showTimePicker(true, presetDateTime);
      if (date == null) {
        return undefined;
      }

      return date.unix();
    }
    // check custom format
    const customFormat = argColumn.CustomFormat;
    if (customFormat == null) {
      throw new Error('Missing CustomFormat!');
    }

    if (!this.customFormatContainsDate(customFormat)) {
      // use timepicker to get date
      const pickerStartDate = await this.showTimePicker(false, presetDateTime);
      if (pickerStartDate == null) {
        return undefined;
      }

      const currentStartDate = dayjs.unix(argColumn.StartDate);

      // replace date with values from datepicker;
      return currentStartDate.year(pickerStartDate.year()).month(pickerStartDate.month()).date(pickerStartDate.date()).unix();
    } else {
      return argColumn.StartDate;
    }
  }

  private async showTimePicker(showTimePicker: boolean, presetTime?: number): Promise<dayjs.Dayjs | undefined> {
    const date = await this.modalService.showDateTimePicker(
      'Please provide start date/time of the data in the file.',
      'Information',
      'ms',
      showTimePicker,
      presetTime,
    );

    if (date == null) {
      return undefined;
    }

    return dayjs(date);
  }

  private customFormatContainsDate(format: string): boolean {
    return format.includes('Y') || format.includes('M') || format.includes('D');
  }
}
