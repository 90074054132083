import { WellDto } from '../dto/well.dto';
import { createTableState } from '../../../common/common-grid.interfaces';
import { CasingPipesFactory } from '../../pipes/casing-pipes/casing-pipes.factory';
import { Pipe } from '../../pipes/pipe';
import { Caliper } from './caliper/caliper';
import { Survey } from './survey/survey';
import { SurveyFactory } from './survey/survey.factory';
import { TemperatureProfileFactory } from './temperature-profile/temperature-profile.factory';
import { WellModuleState } from '../well-module.state';
import { LoadWellActionResponse } from '../well-module.actions';
import { ZoneFactory } from './zone/zone.factory';
import { ZoneModel } from './zone/zone.model';
import { CaliperFactory } from './caliper/caliper.factory';

export class WellFactory {
  public static create(payload: LoadWellActionResponse, scenarioId: number): WellModuleState {
    const { wellDto, surveyDtos, caliperDtos, casingDtos, zoneDtos } = payload;
    const surveyArray = SurveyFactory.createRowListFromDtos(surveyDtos, scenarioId);
    const caliperArray = CaliperFactory.createRowListFromDtos(caliperDtos, scenarioId);
    const casingArray = CasingPipesFactory.createRowListFromDtos(casingDtos, scenarioId);
    const zoneArray = ZoneFactory.createRowListFromDtos(zoneDtos, scenarioId);

    const well: WellModuleState = {
      ScenarioId: wellDto.ScenarioId,
      LocationType: wellDto.LocationType,
      IsLossRateCalculated: wellDto.IsLossRateCalculated,
      TemperatureProfile: TemperatureProfileFactory.create(
        wellDto,
        surveyArray.map((s) => s.rowData),
      ),
      CaliperMultiplier: wellDto.CaliperMultiplier,
      CaliperTolerance: wellDto.CaliperTolerance,
      ZonePressureType: wellDto.ZonePressureType,
      IsZoneDepthByMD: wellDto.IsZoneDepthByMD,
      IsSurveyDepthByMD: wellDto.IsSurveyDepthByMD,
      SurveyData: createTableState<Survey>(surveyArray),
      CasingData: createTableState<Pipe>(casingArray),
      ZoneData: createTableState<ZoneModel>(zoneArray),
      CaliperData: createTableState<Caliper>(caliperArray),
      TreatmentType: wellDto.TreatmentType,

      isLoaded: true,
    };

    return well;
  }

  public static updateWellProps(well: WellModuleState, wellDto: WellDto): WellModuleState {
    return {
      ...well,
      LocationType: wellDto.LocationType,
      CaliperTolerance: wellDto.CaliperTolerance,
      CaliperMultiplier: wellDto.CaliperMultiplier,
      IsLossRateCalculated: wellDto.IsLossRateCalculated,
      ZonePressureType: wellDto.ZonePressureType,
      IsZoneDepthByMD: wellDto.IsZoneDepthByMD,
      IsSurveyDepthByMD: wellDto.IsSurveyDepthByMD,
      TreatmentType: wellDto.TreatmentType,
      TemperatureProfile: TemperatureProfileFactory.update(well, wellDto),
    };
  }

  public static toDto(well: WellModuleState): WellDto {
    const wellDto: WellDto = {
      ScenarioId: well.ScenarioId,
      IsSurveyDepthByMD: well.IsSurveyDepthByMD,
      IsZoneDepthByMD: well.IsZoneDepthByMD,
      CaliperMultiplier: well.CaliperMultiplier,
      CaliperTolerance: well.CaliperTolerance,
      IsLossRateCalculated: well.IsLossRateCalculated,
      ZonePressureType: well.ZonePressureType,
      LocationType: well.LocationType,
      TreatmentType: well.TreatmentType,
      BottomholeCirculatingTemperature: well.TemperatureProfile.BottomholeCirculating,
      SeaLevelDepth: well.TemperatureProfile.SeaLevelDepth,
      BottomholeStaticTemperature: well.TemperatureProfile.BottomholeStaticTemperature,
      MinWaterTemp: well.TemperatureProfile.MinWaterTemp,
      MinWaterTempDepth: well.TemperatureProfile.MinWaterTempDepth,
      SeaBedDepth: well.TemperatureProfile.SeaBedDepth,
      SurfaceTemp: well.TemperatureProfile.SurfaceTemp,
      TemperatureProfileType: well.TemperatureProfile.TemperatureProfileType,
    };
    return wellDto;
  }
}
