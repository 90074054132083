import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { isPumpingEnabled } from '../../../../+store/pumping/selectors/pumping.selectors';
import { ValidatedPumpingModuleState } from '@dunefront/common/modules/pumping/pumping-module.state';
import { PageWithGridComponent } from '../../../../shared/components/grid/page-with-grid.component';
import { Store } from '@ngrx/store';
import { ModalService } from '../../../../common-modules/modals/modal.service';
import { ScreenService } from '../../../../shared/services';
import { getValidatedPumpingModuleState } from '../../../../+store/pumping/selectors/pumping-validation.selectors';
import * as uiActions from '../../../../+store/ui/ui.actions';
import { createTableState, getRowsForCalculations, ITableRow, ITableState } from '@dunefront/common/common/common-grid.interfaces';
import { clearEvaluateScheduleAction, generateEvaluationScheduleAction } from '../../../../+store/pumping/pumping.actions';
import { getCalculatedEvaluationPumpingSchedule } from '../../../../+store/pumping/selectors/pumping-schedule.selectors';
import { PumpingSchedule } from '@dunefront/common/modules/pumping/model/pumping-schedule/pumping-schedule';
import { EntityToCopy, ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import { IUpdateTableRowsProps } from '@dunefront/common/common/common-store-crud.interfaces';
import { RangeConstants } from '@dunefront/common/dto/range.dto';
import { copyPumpingBetweenRangesAction } from '../../../../+store/scenario/scenario.actions';

@Component({
  selector: 'app-evaluate-schedule',
  templateUrl: './evaluate-schedule.component.html',
  styleUrls: ['./evaluate-schedule.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EvaluateScheduleComponent extends PageWithGridComponent<PumpingSchedule> implements OnDestroy {
  public isPumpingEnabled$ = this.store.select(isPumpingEnabled);
  public pumpingState!: ValidatedPumpingModuleState;
  public scheduleTableState: ITableState<PumpingSchedule> = createTableState([]);

  constructor(store: Store, cdRef: ChangeDetectorRef, modalService: ModalService, resizeService: ScreenService) {
    super(store, cdRef, modalService, resizeService);
    this.subscription.add(
      this.store.select(getValidatedPumpingModuleState).subscribe((pumpingState) => {
        this.pumpingState = pumpingState;
        this.cdRef.markForCheck();
      }),
    );

    this.subscription.add(
      this.store.select(getCalculatedEvaluationPumpingSchedule).subscribe((scheduleTableState) => {
        this.scheduleTableState = scheduleTableState;
        this.cdRef.markForCheck();
      }),
    );

    store.dispatch(uiActions.setPumpingTabAction({ tab: 'schedule' }));
    this.onHelpChange('pumping', 'schedule-evaluate');
  }

  public override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.store.dispatch(clearEvaluateScheduleAction());
  }

  public getRows(): ITableRow<PumpingSchedule>[] {
    return this.pumpingState.pumpingSchedule.rows;
  }

  protected updateRow(row: IUpdateTableRowsProps<PumpingSchedule>): void {
    // grid is read only so we don't need to update row
  }

  public onPreviewScheduleClick(): void {
    this.store.dispatch(
      generateEvaluationScheduleAction({
        moduleType: ModuleType.Evaluate,
        scenariosAndRanges: [
          {
            scenarioId: this.currentScenarioId,
            rangeId: this.pumpingState.rangeId,
          },
        ],
      }),
    );
  }

  public async onCopyToSimulateClick(): Promise<void> {
    if (!this.scheduleTableState.rows.length) {
      await this.modalService.showAlert(
        'There are no non-zero pump rate stages to copy - check the imported pump rate data and try again.',
        'Information',
      );
      return;
    }

    if (
      this.dbInfo &&
      (await this.modalService.showConfirm(
        'This will overwrite the initial data, well fluids and schedule' + ' screens in the simulate module - do you want to continue?',
        'Information',
      ))
    ) {
      const evaluateSchedule = getRowsForCalculations(this.scheduleTableState.rows).filter(
        (schedule) => schedule.PumpRate > 0 && schedule.StageVolume > 0,
      );

      this.store.dispatch(
        copyPumpingBetweenRangesAction({
          sourceRangeId: this.pumpingState.rangeId,
          targetRangeId: RangeConstants.EmptyRangeId,
          entitiesToCopy: [EntityToCopy.InitialData, EntityToCopy.WellFluids, EntityToCopy.Schedule],
          entitiesExtras: { evaluateSchedule },
          fileHash: this.dbInfo.fileHash,
        }),
      );
    }
  }

  public get isCopyToSimulateEnabled(): boolean {
    return this.scheduleTableState.rows.length > 0;
  }
}
