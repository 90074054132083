import { LeakoffCoefficientCalculatorDto } from './leakoff-coefficient-calculator.dto';
import { UpdateRowsWsAction } from '../../../ws.action';
import { WsActionPropsFactory } from '../../../common/ws-action/ws-action-props.factory';
import { IUpdateRowsProps } from '../../../common/common-store-crud.interfaces';

import { LeakoffCoefficientCalculatorModuleState } from './leakoff-coefficient-calculator-module.state';
import { LeakoffCoefficientCalculatorFactory } from './model/leakoff-coefficient-calculator.factory';

export const LeakoffCoefficientCalculatorModuleName = 'LeakoffCoefficientCalculatorModule';

export enum LeakoffCoefficientCalculatorModuleActionTypes {
  UpdateLeakoffCoefficientCalculator = '[LeakoffCoefficientCalculatorModule] Update',
  CalculateLeakoffCoefficientAction = '[LeakoffCoefficientCalculatorModule] Calculate',
  UpdateResult = '[LeakoffCoefficientCalculatorModule] UpdateResult',
}

export interface LoadLeakoffCoefficientCalculatorActionResponse {
  leakoffCoefficientCalculatorDto: LeakoffCoefficientCalculatorDto;
}

export class UpdateLeakoffCoefficientCalculatorAction extends UpdateRowsWsAction<LeakoffCoefficientCalculatorDto> {
  public override readonly type = LeakoffCoefficientCalculatorModuleActionTypes.UpdateLeakoffCoefficientCalculator;

  constructor(action: IUpdateRowsProps<LeakoffCoefficientCalculatorModuleState>) {
    super(WsActionPropsFactory.updateAction(action, LeakoffCoefficientCalculatorFactory.toDto));
  }
}

export class CalculateLeakoffCoefficientAction extends UpdateRowsWsAction<{ ScenarioId: number }> {
  public override readonly type = LeakoffCoefficientCalculatorModuleActionTypes.CalculateLeakoffCoefficientAction;

  constructor(scenarioId: number) {
    super(WsActionPropsFactory.update([{ ScenarioId: scenarioId }], false));
  }
}

export type LeakoffCoefficientCalculatorModuleActions = UpdateLeakoffCoefficientCalculatorAction | CalculateLeakoffCoefficientAction;
