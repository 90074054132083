import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ButtonComponent } from './button.component';
import { ButtonDirective } from './button.directive';
import { MenuModule } from 'primeng/menu';

@NgModule({
  imports: [CommonModule, FormsModule, MenuModule],
  declarations: [ButtonComponent, ButtonDirective],
  exports: [ButtonComponent, ButtonDirective],
})
export class ButtonModule {}
