<app-generic-modal [title]="'User Profile'" [modalButtonsConfigs]="modalButtonsConfigs">
  <div *ngIf="user$ | async as user">
    <div class="grid-form-container no-padding">
      <app-form-input
        class="grid-two-one"
        appUnwrap
        [readOnlyValue]="user.email"
        [width]="300"
        [label]="'Email'"
        [disabled]="true"
        [isTextInput]="true"
      ></app-form-input>
      <app-form-input
        class="grid-two-one"
        appUnwrap
        [readOnlyValue]="user.name"
        [width]="300"
        [label]="'User name'"
        [disabled]="true"
        [isTextInput]="true"
      ></app-form-input>
    </div>
  </div>
</app-generic-modal>
