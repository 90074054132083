import { IYAxisShiftUpdate } from '../../../+store/reporting/reporting-module.state';
import { VerticalShiftDto } from '@dunefront/common/dto/trend-analysis/vertical-shift.dto';
import * as rangeActions from '../../../+store/range/range.actions';
import { RangeConstants, RangeDto } from '@dunefront/common/dto/range.dto';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';

export class ChartVerticalShiftService {
  constructor(
    private readonly store: Store,
    private readonly currentRange$: BehaviorSubject<RangeDto | undefined>,
    private readonly verticalShifts$: BehaviorSubject<VerticalShiftDto[]>,
    private readonly currentScenarioId$: BehaviorSubject<number>,
  ) {}

  public onUpdateYAxisShift($event: IYAxisShiftUpdate): void {
    const currentScenarioId = this.currentScenarioId$.value;
    if (currentScenarioId == null) {
      return;
    }

    const rangeId = this.currentRange$.value?.Id ?? RangeConstants.EntireRangeId;
    const currentVerticalShift = this.verticalShifts$.value.find((vs) => vs.ColumnId === $event.ColumnId);

    if (currentVerticalShift) {
      const updatedVerticalShift: VerticalShiftDto = {
        ...currentVerticalShift,
        ShiftValue: currentVerticalShift.ShiftValue + $event.VerticalShift,
      };
      this.store.dispatch(rangeActions.updateVerticalShiftDtosAction({ verticalShifts: [updatedVerticalShift] }));
    } else {
      const newVerticalShift: VerticalShiftDto = {
        ColumnId: $event.ColumnId,
        Id: -1,
        RangeId: rangeId,
        ScenarioId: currentScenarioId,
        ShiftValue: $event.VerticalShift,
        SortOrder: -1,
      };
      this.store.dispatch(rangeActions.insertVerticalShiftDtosAction({ verticalShifts: [newVerticalShift] }));
    }
  }
}
