import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { ModalService } from '../../../../../common-modules/modals/modal.service';
import { createTableRow, ITableRow, ITableState } from '@dunefront/common/common/common-grid.interfaces';
import { ColumnType, IGridColumnConfig } from '../../../../../shared/components/grid/grid.interfaces';
import { GridConfig } from '../../../../../shared/components/grid/grid-config';
import * as actions from '../../../../../+store/calculators/injection-test-calculator/injection-test-calculator.actions';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { IDeleteRowsProps, IInsertRowsProps, IUpdateTableRowsProps } from '@dunefront/common/common/common-store-crud.interfaces';
import { InjectionTestReading } from '@dunefront/common/modules/calculators/injection-test-calculator/model/injection-test-reading';
import { InjectionTestReadingFactory } from '@dunefront/common/modules/calculators/injection-test-calculator/model/injection-test-reading.factory';
import { InsertLocation } from '@dunefront/common/modules/common.interfaces';
import { GridContainerComponent } from '../../../../../shared/components/grid/grid-container.component';
import { GridResizeService } from '../../../../../shared/services/grid-resize.service';

@Component({
  selector: 'app-injection-test-input-data-grid',
  templateUrl: './injection-test-input-data-grid.component.html',
  styleUrls: ['./injection-test-input-data-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InjectionTestInputDataGridComponent extends GridContainerComponent<InjectionTestReading> {
  @Input() public inputData: ITableState<InjectionTestReading> = { rows: [], isValid: true };

  private _columns: IGridColumnConfig<InjectionTestReading>[] = [
    { disabled: false, visible: true, colId: ' ', unitSystem: UnitSystem.None, type: ColumnType.selection },
    {
      disabled: false,
      visible: true,
      colId: 'Rate',
      unitSystem: UnitSystem.Rate,
      type: ColumnType.number,
      matchingStrings: ['rate'],
    },
    {
      disabled: false,
      visible: true,
      colId: 'Pressure',
      unitSystem: UnitSystem.Pressure,
      type: ColumnType.number,
      matchingStrings: ['pressure'],
    },
  ];

  constructor(store: Store, cdRef: ChangeDetectorRef, el: ElementRef, modalService: ModalService, gridResizeService: GridResizeService) {
    super(store, cdRef, el, new InjectionTestReadingsGridConfig(store, modalService), gridResizeService);
  }

  public async onImport(): Promise<void> {
    await this.grid.onImportClicked();
  }

  public async onDelete(): Promise<void> {
    await this.grid.onDeleteClicked();
  }

  protected getColumns(): IGridColumnConfig<InjectionTestReading>[] {
    return this._columns;
  }
}

export class InjectionTestReadingsGridConfig extends GridConfig<InjectionTestReading> {
  public override createEmptyModel(scenarioId: number): InjectionTestReading {
    return InjectionTestReadingFactory.createEmpty(scenarioId);
  }

  constructor(
    private store: Store,
    modalService: ModalService,
  ) {
    super(modalService);
    this.headerText = 'Input Data';
  }

  public override updateRowsAction(props: IUpdateTableRowsProps<InjectionTestReading>): void {
    this.store.dispatch(actions.updateInjectionTestReadingsRowAction(props));
  }

  public override insertRowAction(props: IInsertRowsProps<InjectionTestReading>): void {
    this.store.dispatch(actions.insertInjectionTestReadingsRowsAction(props));
  }

  public override deleteRowsAction(props: IDeleteRowsProps): void {
    this.store.dispatch(actions.deleteInjectionTestReadingsRowsAction(props));
  }

  public override createDefaultTableRows(scenarioId: number, noOfRows: number): ITableRow<InjectionTestReading>[] {
    const newTableRows = [];
    for (let index = 0; index < noOfRows; index++) {
      newTableRows.push(createTableRow(this.createEmptyModel(scenarioId), 'data', index, false));
    }
    return newTableRows;
  }

  public override replaceGridAction(rows: ITableRow<InjectionTestReading>[]): void {
    if (rows.length > 0) {
      const props: IInsertRowsProps<any> = {
        rows,
        refId: 0,
        insertLocation: 'replace' as InsertLocation,
        shouldResetResults: true,
      };
      this.store.dispatch(actions.insertInjectionTestReadingsRowsAction(props));
    }
  }
}
