import { UnitConverter } from '../../converter.interfaces';
import { SolidConcentrationUnit } from '../../../dto/unit-system.dto';

export class SolidConcentrationConverter extends UnitConverter {
  public static symbols: Array<string> = ['', 'kgPA', 'PPA', 'lbm/1000galUS', 'lbm/bbl'];

  public static unit = SolidConcentrationUnit;

  public static fromSi(value: number, unit: SolidConcentrationUnit): number {
    switch (unit) {
      case SolidConcentrationUnit.Kilogram_per_cubic_meter: {
        return value;
      }
      case SolidConcentrationUnit.Pound_per_US_gallon: {
        return value * 8.345400519e-3;
      }
      case SolidConcentrationUnit.Pound_per_1000_US_gallon: {
        return value * 8.345400519;
      }
      case SolidConcentrationUnit.Pound_per_barrel: {
        return value * 3.505063368e-1;
      }
      default: {
        throw new TypeError('Unknown Solid Concentration Unit:' + unit);
      }
    }
  }

  public static toSi(value: number, unit: SolidConcentrationUnit): number {
    switch (unit) {
      case SolidConcentrationUnit.Kilogram_per_cubic_meter: {
        return value;
      }
      case SolidConcentrationUnit.Pound_per_US_gallon: {
        return value / 8.345400519e-3;
      }
      case SolidConcentrationUnit.Pound_per_1000_US_gallon: {
        return value / 8.345400519;
      }
      case SolidConcentrationUnit.Pound_per_barrel: {
        return value / 3.505063368e-1;
      }
      default: {
        throw new TypeError('Unknown Solid Concentration Unit:' + unit);
      }
    }
  }

  public static getSymbol(unit: SolidConcentrationUnit): string {
    return this.symbols[unit];
  }
}
