<div *ngIf="data" class="grid-form-container">
  <app-radio-group
    [source]="data.pumping"
    [key]="'HydrilState'"
    (valueChanged)="dataChange.emit($event)"
    [items]="hydrilStateItems"
    class="my-1 grid-two-one"
    id="annular-bop-group"
    layout="horizontal"
    dataCy="hydril-state"
  >
  </app-radio-group>

  <app-form-input
    [source]="data.pumping"
    [key]="'SurfaceChokeLineID'"
    (valueChanged)="dataChange.emit($event)"
    [decimalPlaces]="3"
    [unitType]="UnitType.Diameter"
    [disabled]="!isTxtChokeLineIDEnabled(data.pumping)"
    appUnwrap
    class="my-1 grid-two-one"
    label="Surface Line ID"
    dataCy="surface-line-id"
  >
  </app-form-input>

  <app-form-input
    [source]="data.pumping"
    [key]="'SurfaceChokeLineLength'"
    (valueChanged)="dataChange.emit($event)"
    [decimalPlaces]="2"
    [disabled]="!isTxtSurfaceChokeLineLengthEnabled(data.pumping)"
    [unitType]="UnitType.Long_Length"
    appUnwrap
    class="my-1 grid-two-one"
    label="Surface Line Length"
    dataCy="surface-line-length"
  >
  </app-form-input>

  <app-form-input
    [source]="data.pumping"
    [key]="'ChokeKillLineID'"
    (valueChanged)="dataChange.emit($event)"
    [decimalPlaces]="3"
    [disabled]="!isTxtChokeKillLineIDEnabled(data.pumping)"
    [unitType]="UnitType.Diameter"
    appUnwrap
    class="my-1 grid-two-one"
    label="Choke/Kill Line ID"
    dataCy="choke-kill-line-id"
  >
  </app-form-input>
</div>
