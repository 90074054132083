import { DbDependentComponent } from '../../../../common-modules/db-connection/db-dependent.component';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  getCurrentRheology,
  getSelectedFluidData,
  getTemperaturesSelectData,
  ISelectedFluidData,
} from '../../../../+store/fluid/fluid.selectors';
import * as actions from '../../../../+store/fluid/fluid.actions';
import { ModalService } from '../../../../common-modules/modals/modal.service';
import { notEmpty } from '@dunefront/common/common/state.helpers';
import { RheologyType } from '@dunefront/common/modules/fluid/dto/rheology/rheology.dto';
import { ISelectItem } from '@dunefront/common/common/select.helpers';
import * as uiActions from '../../../../+store/ui/ui.actions';
import { Rheometer } from '@dunefront/common/modules/fluid/model/rheometer/rheometer';
import { Fluid } from '@dunefront/common/modules/fluid/model/fluid';
import { ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';
import { IUpdateTableRowsProps } from '@dunefront/common/common/common-store-crud.interfaces';
import { IUnitSystemDto } from '@dunefront/common/dto/unit-system.dto';

@Component({
  selector: 'app-rheometer-data',
  templateUrl: './rheometer-data.component.html',
  styleUrls: ['./rheometer-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RheometerDataComponent extends DbDependentComponent {
  public selectedFluidData: ISelectedFluidData | undefined;
  public temperatureSelect: ISelectItem<number>[] | undefined;
  public temperatureSelectLabel!: string;

  public RheologyType = RheologyType;

  public rheology$ = this.store.select(getCurrentRheology);

  private maxTemp!: number;

  constructor(
    store: Store,
    cdRef: ChangeDetectorRef,
    private modalService: ModalService,
  ) {
    super(store, cdRef);
    this.subscription.add(
      notEmpty(this.store.select(getSelectedFluidData)).subscribe((selectedFluidData) => {
        this.selectedFluidData = selectedFluidData;
        this.cdRef.markForCheck();
      }),
    );
    this.subscription.add(
      notEmpty(this.store.select(getTemperaturesSelectData)).subscribe((temperatureSelect) => {
        this.temperatureSelect = temperatureSelect.items;
        this.temperatureSelectLabel = temperatureSelect.label;
        this.maxTemp = temperatureSelect.maxTemp;
        this.cdRef.markForCheck();
      }),
    );
    store.dispatch(uiActions.setFluidsTabAction({ tab: 'rheometer-data' }));
    this.onHelpChange('fluids', 'test-configuration');
  }

  public onRheometerPropertyChanged(props: IUpdateTableRowsProps<Rheometer>): void {
    this.store.dispatch(actions.updateRheometerRow(props));
  }

  public onFluidPropertyChanged(args: ObjectChangeProp<Fluid>): void {
    this.store.dispatch(
      actions.updateFluid({
        changedProp: args,
        fluidId: this.selectedFluidData?.fluid.Id as number,
        shouldSaveRheologies: false,
      }),
    );
  }

  public addTemperature(): void {
    if (!this.selectedFluidData) {
      return;
    }
    const selectedRheometer = this.selectedFluidData.rheometer.rowData;

    if (!selectedRheometer.ScenarioId || !selectedRheometer.FluidId) {
      return;
    }

    this.store.dispatch(
      actions.insertRheometerRow({
        scenarioId: selectedRheometer.ScenarioId,
        fluidId: selectedRheometer.FluidId,
        temperature: this.getNewTemp(this.currentUnitSystem),
        speedType: selectedRheometer.SpeedType,
      }),
    );
  }

  private getNewTemp(currentUnitSystem: IUnitSystemDto): number {
    const tempInCurrentUnit = this.TemperatureConverter.fromSi(this.maxTemp, currentUnitSystem.Temperature);
    const newTemp = Math.round(tempInCurrentUnit) + 1;
    return this.TemperatureConverter.toSi(newTemp, currentUnitSystem.Temperature);
  }

  public async deleteTemperature(): Promise<void> {
    if (!this.selectedFluidData || !this.temperatureSelect) {
      return;
    }

    if (this.temperatureSelect.length === 1) {
      await this.modalService.showAlert('At least one temperature point must remain in the list');
      return;
    }

    const confirmResult = await this.modalService.showConfirm(
      'Are you sure you want to delete the selected temperature point?',
      'Delete Fluid',
    );
    if (!confirmResult) {
      return;
    }
    const selectedRheometer = this.selectedFluidData.rheometer.rowData;
    this.store.dispatch(
      actions.deleteRheometerRows({
        scenarioId: selectedRheometer.ScenarioId,
        rowIds: [selectedRheometer.Id],
        shouldResetResults: true,
      }),
    );
  }
}
