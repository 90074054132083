import { DictionaryWithArray, IDictionaryWithArray } from '@dunefront/common/common/state.helpers';
import { flatten } from 'lodash';
import { DataStorageModuleState } from '@dunefront/common/modules/data-storage/data-storage-module.state';
import { getAffected } from '@dunefront/common/dto/dto.common';
import { LoadDataStorageActionResponse } from '@dunefront/common/modules/data-storage/data-storage-module.actions';
import { ImportColumnDto } from '@dunefront/common/modules/data-storage/dto/import-column.dto';
import { CrudResponse } from '@dunefront/common/modules/common.actions';
import { DataStorageFactory } from '@dunefront/common/modules/data-storage/model/data-storage.factory';
import { ActionResponse } from '@dunefront/common/response-ws.action';

export class DataStorageReducerHelper {
  public static onLoadScenarioDataSuccessAction(
    state: DataStorageModuleState,
    dataStorageResponse: ActionResponse<LoadDataStorageActionResponse>,
  ): DataStorageModuleState {
    if (dataStorageResponse.payload == null) {
      return state;
    }
    const importFiles = getAffected(dataStorageResponse.payload.importFileDtos);
    const importColumns = getAffected(dataStorageResponse.payload.importColumnDtos);

    return this.insertFileOrColumnsRowsSuccess(state, {
      affectedRows: { importFiles, importColumns },
      scenarioId: dataStorageResponse.payload.scenarioId,
    });
  }

  private static insertFileOrColumnsRowsSuccess(state: DataStorageModuleState, response: CrudResponse): DataStorageModuleState {
    const importFiles = response.affectedRows.importFiles?.rows;
    const importColumns = response.affectedRows.importColumns?.rows;
    if (importFiles && importColumns) {
      return DataStorageFactory.create({
        importFileDtos: importFiles,
        importColumnDtos: importColumns,
        scenarioId: response.scenarioId,
      });
    } else if (importColumns) {
      return { ...state, columns: this.upsertColumns(state, importColumns) };
    }

    return state;
  }

  private static updateFileRowsSuccess(state: DataStorageModuleState, response: CrudResponse): DataStorageModuleState {
    if (!response.affectedRows.importFiles) {
      return state;
    }

    let files = state.files;
    response.affectedRows.importFiles.rows.forEach((importFile) => {
      files = DictionaryWithArray.upsert(files, importFile, 'Id');
    });

    return {
      ...state,
      files,
    };
  }

  private static updateImportColumnRowsSuccess(state: DataStorageModuleState, response: CrudResponse): DataStorageModuleState {
    const importColumns = response.affectedRows.importColumns?.rows;
    if (!importColumns) {
      return state;
    }

    const columns = this.upsertColumns(state, importColumns);
    const columnNames = DataStorageFactory.getColumnNames(flatten(DictionaryWithArray.getArray(columns)));

    return {
      ...state,
      columns,
      columnNames,
    };
  }

  private static upsertColumns(
    state: DataStorageModuleState,
    importColumnDtos: ImportColumnDto[],
  ): IDictionaryWithArray<ImportColumnDto[]> {
    let columns = state.columns;
    importColumnDtos.forEach((importColumn) => {
      let fileColumns = DictionaryWithArray.get(columns, importColumn.FileId) ?? [];

      if (fileColumns.some((fc) => fc.Id === importColumn.Id)) {
        // if column exist update it
        fileColumns = fileColumns.map((col) => (col.Id === importColumn.Id ? importColumn : col));
      } else {
        //insert new one
        fileColumns = [...fileColumns, importColumn];
      }

      columns = DictionaryWithArray.upsertById(columns, fileColumns, importColumn.FileId);
    });
    return columns;
  }

  public static insertRowsSuccess(state: DataStorageModuleState, response: CrudResponse): DataStorageModuleState {
    const workingState = this.insertFileOrColumnsRowsSuccess(state, response);
    return workingState;
  }

  public static updateRowsSuccess(state: DataStorageModuleState, response: CrudResponse): DataStorageModuleState {
    let workingState = this.updateFileRowsSuccess(state, response);
    workingState = this.updateImportColumnRowsSuccess(workingState, response);
    return workingState;
  }

  public static updateEquationFileHash(
    state: DataStorageModuleState,
    fileId: number,
    fileHash: string,
    updateDate: string,
  ): DataStorageModuleState {
    const file = DictionaryWithArray.get(state.files, fileId);
    if (!file || fileHash == null) {
      return state;
    }

    return {
      ...state,
      files: DictionaryWithArray.upsert(
        state.files,
        {
          ...file,
          EquationHash: fileHash,
          UpdateDate: updateDate,
        },
        'Id',
      ),
    };
  }

  private static deleteColumn(state: DataStorageModuleState, response: CrudResponse): DataStorageModuleState {
    const importColumns = response.affectedRows.importColumns;
    if (!importColumns || !importColumns.deletedIds.length || importColumns.parentId == null || importColumns.parentId === 0) {
      return state;
    }

    const newColumns: ImportColumnDto[] = (DictionaryWithArray.get(state.columns, importColumns.parentId) ?? []).filter(
      (col) => !importColumns.deletedIds.includes(col.Id),
    );
    const columns = DictionaryWithArray.upsertById(state.columns, [...newColumns], importColumns.parentId);
    const columnNames = DataStorageFactory.getColumnNames(flatten(DictionaryWithArray.getArray(columns)));

    return {
      ...state,
      columns,
      columnNames,
    };
  }

  private static deleteFilesByIds(state: DataStorageModuleState, fileIds: (string | number)[]): DataStorageModuleState {
    let columns = { ...state.columns };
    let files = { ...state.files };

    for (const fileIdToDelete of fileIds) {
      columns = DictionaryWithArray.deleteItem(columns, fileIdToDelete);
      files = DictionaryWithArray.deleteItem(files, fileIdToDelete);
    }

    const columnNames = DataStorageFactory.getColumnNames(flatten(DictionaryWithArray.getArray(columns)));

    return {
      ...state,
      files,
      columns,
      columnNames,
    };
  }

  private static deleteFile(state: DataStorageModuleState, response: CrudResponse): DataStorageModuleState {
    const importFiles = response.affectedRows.importFiles;
    if (!importFiles || !importFiles.deletedIds.length) {
      return state;
    }

    return this.deleteFilesByIds(state, importFiles.deletedIds);
  }

  public static deleteRowsSuccess(state: DataStorageModuleState, response: CrudResponse): DataStorageModuleState {
    let workingState = this.deleteColumn(state, response);
    workingState = this.deleteFile(workingState, response);
    workingState = this.deleteRangeData(workingState, response);
    return workingState;
  }

  public static deleteRangeData(state: DataStorageModuleState, response: CrudResponse): DataStorageModuleState {
    const data = response.affectedRows.deleteRangeData;
    if (!data || data.removedFileIds.length === 0) {
      return state;
    }

    return this.deleteFilesByIds(state, data.removedFileIds);
  }
}
