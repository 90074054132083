import * as dayjs from 'dayjs';
import * as customParseFormatPlugin from 'dayjs/plugin/customParseFormat';
import { IValidatedColConfig } from './model/col-config';
import { IColConfig } from '@dunefront/common/modules/data-storage/dto/import-template/import-template.dto';
import 'dayjs/locale/en';

dayjs.extend(customParseFormatPlugin);

export class ImportDataCalculations {
  public static getCombinedCustomFormat(timeCols: IColConfig[]): ICombinedCustomFormatResult {
    const customTimeCols = timeCols.filter((col) => col.isTimeCustomFormat);
    const combinedCustomFormat = customTimeCols.map((col) => col.timeCustomFormat).join(' ');
    const combinedName = customTimeCols.map((col) => col.name).join(' ');
    const combinedColIds = customTimeCols.map((col) => col.colIndex);
    return { combinedCustomFormat, combinedColIds, combinedName };
  }

  public static getCombinedArgColumnValue(row: string[], argColIds: number[]): string {
    return argColIds.map((argColId) => row[argColId]).join(' ');
  }

  public static parseCustomFormatValueReturnDate(value: string, customFormat: string): Date {
    const parsed = this.parseCustomFormatValue(value, customFormat);

    if (!parsed.isValid()) {
      throw new Error(`Can't parse custom format value.
      value: ${value}
      customFormat: ${customFormat}`);
    }

    return parsed.toDate();
  }

  public static parseCustomFormatValue(value: string, customFormat: string): dayjs.Dayjs {
    return dayjs(value, customFormat, 'en', true);
  }

  public static addDays(timeMs: number, days: number): number {
    return timeMs + days * 24 * 60 * 60 * 1000;
  }

  public static checkIfCustomDateCanBeParsed(
    row: string[],
    colConfigs: IValidatedColConfig[],
    combinedCustomFormatResult: ICombinedCustomFormatResult,
    customTimeCols: IValidatedColConfig[],
    throwError: boolean,
  ): ICheckIfDataCanBeParsedResult {
    let customDate = this.getTimestampByCustomFormatInMs(row, colConfigs, combinedCustomFormatResult, customTimeCols, false);
    let newCombinedCustomFormatResult = combinedCustomFormatResult;
    let combinedCustomFormatToReturn = combinedCustomFormatResult;

    if (customDate === null) {
      // data cannot be parsed try different hour format
      if (combinedCustomFormatResult.combinedCustomFormat.includes('HH')) {
        //This is a 24 hour time file so try parsing with a single digit
        newCombinedCustomFormatResult = {
          ...combinedCustomFormatResult,
          combinedCustomFormat: combinedCustomFormatResult.combinedCustomFormat.replace('HH', 'H'),
        };
      } else if (combinedCustomFormatResult.combinedCustomFormat.includes('hh')) {
        //This is a 12 hour time file so try parsing with a single digit
        newCombinedCustomFormatResult = {
          ...combinedCustomFormatResult,
          combinedCustomFormat: combinedCustomFormatResult.combinedCustomFormat.replace('hh', 'h'),
        };
      }

      customDate = this.getTimestampByCustomFormatInMs(row, colConfigs, newCombinedCustomFormatResult, customTimeCols, throwError);
      if (customDate !== null) {
        combinedCustomFormatToReturn = newCombinedCustomFormatResult;
      }
    }
    return {
      customDate,
      combinedCustomFormatResult: combinedCustomFormatToReturn,
    };
  }

  public static getTimestampByCustomFormatInMs(
    row: string[],
    colConfigs: IValidatedColConfig[],
    combinedCustomFormatResult: ICombinedCustomFormatResult,
    customTimeCols: IValidatedColConfig[],
    throwError: boolean,
  ): Date | null {
    const combinedData = customTimeCols
      .map((col) => {
        const colIndex = colConfigs.findIndex((colConfig) => colConfig.colIndex === col.colIndex);
        return row[colIndex];
      })
      .join(' ')
      .trim();
    try {
      return ImportDataCalculations.parseCustomFormatValueReturnDate(combinedData, combinedCustomFormatResult.combinedCustomFormat);
    } catch (err) {
      if (throwError) {
        throw err;
      } else {
        console.warn(err);
      }
    }
    return null;
  }

  public static addDaysIfNeeded(
    currentTime: number,
    prevTimeAndDays: ITimeAndDays,
    combinedCustomFormatResult: ICombinedCustomFormatResult,
  ): ITimeAndDays {
    // When import file does not have date column, only time column.
    // It's possible that there will be day chande
    // ex: 23:59:58
    //     23:59:59
    //      0:00:00
    // We need to add 12 or 24 hours to date to keep it consistent
    let days = prevTimeAndDays.days;
    let time = ImportDataCalculations.addDays(currentTime, days);
    if (time - prevTimeAndDays.time < 0) {
      //There is a day change
      if (combinedCustomFormatResult.combinedCustomFormat.includes('H')) {
        //This is a 24 hour time file so add a full day
        days++;
        time = ImportDataCalculations.addDays(currentTime, days);
      } else if (combinedCustomFormatResult.combinedCustomFormat.includes('h')) {
        //This is a 12 hour time file so add half a day
        days += 0.5;
        time = ImportDataCalculations.addDays(currentTime, days);
      } else {
        //The data does not fit the format
        // skip data
      }
    }

    return { time, days };
  }
}

export interface ICombinedCustomFormatResult {
  combinedCustomFormat: string;
  combinedColIds: number[];
  combinedName: string;
}

export interface ICheckIfDataCanBeParsedResult {
  customDate: Date | null;
  combinedCustomFormatResult: ICombinedCustomFormatResult;
}

export interface ITimeAndDays {
  time: number;
  days: number;
}
