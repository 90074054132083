import { Routes } from '@angular/router';
import { WellComponent } from './well.component';
import { GeneralDataComponent } from './general-data/general-data.component';
import { CasingDataComponent } from './casing-data/casing-data.component';
import { ReservoirDataComponent } from './reservoir-data/reservoir-data.component';
import { CaliperDataComponent } from './caliper-data/caliper-data.component';
import {
  RouteModuleWell,
  RouteModuleWellCaliperData,
  RouteModuleWellCasingData,
  RouteModuleWellGeneralData,
  RouteModuleWellReservoirData,
} from './well-routes-names';

export const wellRoutes: Routes = [
  {
    path: RouteModuleWell,
    component: WellComponent,
    data: {
      breadcrumb: 'Well',
    },
    children: [
      {
        path: RouteModuleWellGeneralData,
        component: GeneralDataComponent,
        data: {
          breadcrumb: 'General Data',
        },
      },
      {
        path: RouteModuleWellCasingData,
        component: CasingDataComponent,
        data: {
          breadcrumb: 'Casing Data',
        },
      },
      {
        path: RouteModuleWellReservoirData,
        component: ReservoirDataComponent,
        data: {
          breadcrumb: 'Reservoir Data',
        },
      },
      {
        path: RouteModuleWellCaliperData,
        component: CaliperDataComponent,
        data: {
          breadcrumb: 'Caliper Data',
        },
      },
      {
        path: '',
        redirectTo: RouteModuleWellGeneralData,
        pathMatch: 'full',
      },
    ],
  },
];
