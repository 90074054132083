import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { GenericOptionsComponent } from '../generic-options.component';
import { Store } from '@ngrx/store';
import { ILegendStyle } from '@dunefront/common/modules/reporting/dto/chart-legend';
import { ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';

@Component({
  selector: 'app-chart-legend-options',
  templateUrl: './chart-legend-options.component.html',
  styleUrls: ['./chart-legend-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartLegendOptionsComponent extends GenericOptionsComponent {
  constructor(store: Store, cdRef: ChangeDetectorRef) {
    super(store, cdRef);
  }

  public onStyleValueChange(legendStyleProp: ObjectChangeProp<ILegendStyle>): void {
    this.settingsValueChange.emit({ ...this.globalOptions, ...legendStyleProp });
  }
}
