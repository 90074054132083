import { Paragraph, TextRun, UnderlineType } from 'docx';
import { BaseHtmlParser, ParagraphType } from '../common/base-html-parser';
import { mainFont } from '../../report-consts';

export class DocxHtmlParser extends BaseHtmlParser {
  public override render(): Paragraph[] {
    const docxParagraphs: Paragraph[] = [];
    for (const p of this.paragraphs) {
      const docxParagraph = new Paragraph(p.type === ParagraphType.bullet ? { bullet: { level: 0 } } : {});
      for (const text of p.texts) {
        docxParagraph.addChildElement(
          new TextRun({
            text: text.text,
            bold: text.isBold,
            italics: text.isItalic,
            font: mainFont,
            underline: text.isUnderline ? { type: UnderlineType.SINGLE } : undefined,
          }),
        );
      }
      docxParagraphs.push(docxParagraph);
    }
    return docxParagraphs;
  }
}
