import { UnitConverterHelper } from '@dunefront/common/unit-converters/unit.converter.helper';
import { ChartDataPointsHelpers } from './chart-component-helpers/chart-data-points-helpers';
import { getColumnKey } from './chart-component-helpers/chart-misc-helpers';
import { getArgument, IChartDataDto, IChartDataSetDto } from '@dunefront/common/modules/reporting/dto/chart-data.dto';
import { ChartDataHelpers } from './chart-component-helpers/chart-data-helpers';
import { ChartDataSeriesStyleHelpers } from './chart-component-helpers/chart-data-series-style-helpers';
import { IUnitSystemDto } from '@dunefront/common/dto/unit-system.dto';
import { IAxisUnit } from '@dunefront/common/unit-converters/converter.interfaces';
import { DrawableDataProvider } from '../../shared/services/drawable-registry.service';

const emptyCell = '';

export class ChartCopyDataHelper {
  public static getChartDatasetNames(provider: DrawableDataProvider): string[] | undefined {
    const context = provider.getDataContext();

    const { chartDisplay: chart } = context;
    if (chart?.data.datasets == null) {
      return;
    }

    return chart.data.datasets.map((dataset) => dataset.label?.trim() ?? '');
  }

  public static getChartCopyData(provider: DrawableDataProvider, selectedColumnsIndexes: number[]): string | undefined {
    const context = provider.getDataContext();

    const {
      chartDisplay: chart,
      activeChartData: chartData,
      currentUnitSystem,
      argumentAxisUnit,
      convertUnitPipe,
      scenariosToCompare,
    } = context;

    if (chart == null || chartData == null || currentUnitSystem == null) {
      return;
    }

    const argumentUnitDetails = UnitConverterHelper.getUnitTypeAndName(
      argumentAxisUnit.dataType,
      argumentAxisUnit.unitSystem,
      currentUnitSystem,
    );

    const argumentAxisName = argumentUnitDetails.AxisName;
    const argumentAxisSymbol = argumentUnitDetails.AxisSymbol ?? emptyCell;

    const selectedColumns = chartData.ChartDataColumns.filter((_, index) => selectedColumnsIndexes.includes(index));
    const allDatasetsRows: string[][][] = [];

    for (let dataSetIndex = 0; dataSetIndex < chartData.ChartDataSets.length; dataSetIndex++) {
      const selectedDatasetColumns = selectedColumns.filter((column) => column.DataSetIndex === dataSetIndex);
      if (selectedDatasetColumns.length === 0) {
        continue;
      }

      const dataset = chartData.ChartDataSets[dataSetIndex];
      const datasetRows: string[][] = [];
      const columnsSmoothedPoints = new Map<string, [number, number][]>();

      const namesRow = [argumentAxisName];
      const unitsRow = [argumentAxisSymbol];

      const chartDataHelpers = new ChartDataHelpers(new ChartDataSeriesStyleHelpers());

      // go over dataset selected columns and created heading rows (names, units) as well as smoothed points for each column
      for (const column of selectedDatasetColumns) {
        namesRow.push(
          chartDataHelpers.getSeriesLabel(column, chartData.ChartDataColumns, scenariosToCompare, convertUnitPipe, currentUnitSystem),
        );

        unitsRow.push(
          UnitConverterHelper.getUnitTypeAndName(column.DataType, column.UnitSystem, currentUnitSystem).AxisSymbol ?? emptyCell,
        );

        columnsSmoothedPoints.set(getColumnKey(column), ChartDataPointsHelpers.calcSmoothedPoints(column, context, chartData));
      }

      datasetRows.push(namesRow);
      datasetRows.push(unitsRow);

      const argFormatter = (arg: number): string => chartDataHelpers.formatArgument(arg, context, chartData);
      // array of all dataset arguments
      const datasetArguments = this.getDatasetArguments(dataset, chartData, argumentAxisUnit, currentUnitSystem);

      // loop over all args and for each try to find values from columnPoints (smoothed)
      // rows with no values won't be added

      // for rotated charts, same argument might appear more than once,
      // we need to keep track of
      const argCounters: { [key: number]: number } = {};

      for (const argument of datasetArguments) {
        const rowCells: string[] = [argFormatter(argument)];

        // which value point should be taken for current arg
        const argValueIndex = argCounters[argument] ?? 0;

        let hasNonEmptyCells = false;
        for (const column of selectedDatasetColumns) {
          // get columnPoints from cache
          const columnPoints = columnsSmoothedPoints.get(getColumnKey(column));

          // find all points for current argument
          const valuePoints = columnPoints?.filter((p) => p[0] === argument);
          // get correct (subsequent) point
          const valuePoint = valuePoints?.[argValueIndex];

          // if present add to rowCells, otherwise add emptyCell
          if (valuePoint != null) {
            rowCells.push(valuePoint[1] + '');
            hasNonEmptyCells = true;
          } else {
            rowCells.push(emptyCell);
          }
        }

        // store next index to read
        argCounters[argument] = argValueIndex + 1;

        // if row has at least one value cell will be added to dataset rows
        if (hasNonEmptyCells) {
          datasetRows.push(rowCells);
        }
      }

      allDatasetsRows.push(datasetRows);
    }

    // find rows count from the longest dataset
    const maxRowsCount = Math.max(...allDatasetsRows.map((rows) => rows.length));
    const combinedRows: string[] = [];

    // combine rows from all datasets into single array of strings
    for (let i = 0; i < maxRowsCount; i++) {
      const combinedRow: string[] = [];

      for (const datasetRows of allDatasetsRows) {
        const rowCells = datasetRows[i] ?? Array(datasetRows[0].length).fill(emptyCell);
        combinedRow.push(...rowCells);

        // add spacing column (empty cell)
        if (datasetRows !== allDatasetsRows[allDatasetsRows.length - 1]) {
          combinedRow.push(emptyCell);
        }
      }

      combinedRows.push(combinedRow.join('\t'));
    }

    return combinedRows.join('\r\n');
  }

  private static getDatasetArguments(
    dataset: IChartDataSetDto,
    chartData: IChartDataDto,
    argumentAxisUnit: IAxisUnit,
    currentUnitSystem: IUnitSystemDto,
  ): number[] {
    return dataset.ChartDataRows.map((row) => {
      const argument = getArgument(chartData, row);
      return UnitConverterHelper.convertFromSi(argumentAxisUnit.unitSystem, currentUnitSystem, argument + dataset.XAxisShift);
    });
  }
}
