import {
  ICheckIfDataCanBeParsedResult,
  ICombinedCustomFormatResult,
  ImportDataCalculations,
  ITimeAndDays,
} from '../../../../+store/import-data/import-data.calculations';
import { IValidatedColConfig } from '../../../../+store/import-data/model/col-config';
import { ArgumentType, ImportColumnDto, ImportColumnGroupType } from '@dunefront/common/modules/data-storage/dto/import-column.dto';
import { IValidatedImportDataModuleState } from '../../../../+store/import-data/import-data-module.state';
import { ILineError, IParsedFileResult } from '../../../../+store/import-data/model/parsed-result';
import { IColConfig, ImportColumnType } from '@dunefront/common/modules/data-storage/dto/import-template/import-template.dto';
import { TimeUnit, UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { DataType } from '@dunefront/common/dto/data-storage';
import { GaugeMeasurementType } from '@dunefront/common/dto/running-string-pipe.dto';
import { ScenarioConstants } from '@dunefront/common/modules/scenario/scenario.dto';
import { ImportDataHelper } from '../../../../shared/helpers/import-data/import-data-helper';
import { CANT_PARSE_ARGUMENT_ERROR } from '../../import-paste-data-common/file-parser/file-parser.service';
import { wait } from '@dunefront/common/common/helpers';

export class DataArgumentGenerator {
  private startDate: Date | undefined;
  private prevTime: number | undefined;
  private days = 0;
  private combinedCustomFormatResult!: ICombinedCustomFormatResult;
  private customTimeCols: IValidatedColConfig[] = [];

  public static getColumns(state: IValidatedImportDataModuleState, allColumnsNames: string[]): IMapColumnsResponse {
    const includedColConfigs = state.colConfigs.filter(
      (col, index) => !col.isDeltaTimePreviewColumn && state.includedColumnIds.includes(index),
    );

    return this.mapColumns(includedColConfigs, allColumnsNames);
  }

  private static mapColumns(includedColConfigs: IColConfig[], allColumnsNames: string[]): IMapColumnsResponse {
    const customFormatCols = includedColConfigs.filter((col) => col.isTimeCustomFormat);
    let argColIds: number[] = [];
    let dtos: ImportColumnDto[] = [];
    let combinedCustomFormat = '';

    // There is no custom format columns
    if (!customFormatCols.length) {
      const timeCol = includedColConfigs.find((col) => col.columnType === ImportColumnType.Time);
      if (!timeCol) {
        throw new Error('Time Column not found');
      }
      timeCol.isXAxis = ArgumentType.Primary_Argument;
      argColIds = [timeCol.colIndex];
      // we're taking all columns;
      dtos = includedColConfigs.map((col) => this.colConfigToImportColumnDto(col));
    } else {
      // There are columns with custom format
      // Merge them to one combined one
      const combinedCustomFormatColsResult = ImportDataCalculations.getCombinedCustomFormat(customFormatCols);
      combinedCustomFormat = combinedCustomFormatColsResult.combinedCustomFormat;
      argColIds = combinedCustomFormatColsResult.combinedColIds;
      // we're taking all columns except custom format cols

      dtos = includedColConfigs.filter((col) => !argColIds.includes(col.colIndex)).map((col) => this.colConfigToImportColumnDto(col));

      const combinedTimeCol = this.createTimeCol(
        ImportDataHelper.getUniqueColName(combinedCustomFormatColsResult.combinedName, allColumnsNames),
        combinedCustomFormatColsResult.combinedCustomFormat,
        TimeUnit.Hour,
        ArgumentType.Primary_Argument,
      );

      dtos.push(combinedTimeCol);
    }
    return { dtos, argColIds, customFormat: combinedCustomFormat };
  }

  private static colConfigToImportColumnDto(colConfig: IColConfig): ImportColumnDto {
    const dto: ImportColumnDto = {
      Id: -1,
      ColumnName: colConfig.name,
      DataType: colConfig.columnType as unknown as DataType,
      FileId: -1,
      GroupType: ImportColumnGroupType.Miscellaneous,
      Unit: colConfig.unitSymbol,
      UnitSystem: colConfig.unitSystem as UnitSystem,
      MeasurementType: colConfig.measurementType ?? GaugeMeasurementType.None,
      MeasuredDepth: colConfig.measuredDepth,
      EndMeasuredDepth: 0,
      SmoothingPoints: 0,
      VerticalOffset: 0,
      SortOrder: -1,
      IsXAxis: colConfig.isXAxis,
      ScenarioId: ScenarioConstants.EmptyScenarioId,
      CustomFormat: colConfig.timeCustomFormat,
      Multiplier: 1,
      Equation: null,
      StartDate: null,
      UpdateDate: new Date().toISOString(),
    };
    return dto;
  }

  private static createTimeCol(
    name: string,
    timeCustomFormat: string | null,
    unitSymbol: TimeUnit,
    isXAxis: ArgumentType,
  ): ImportColumnDto {
    const timeCol = this.colConfigToImportColumnDto({
      columnType: ImportColumnType.Time,
      originalUnit: undefined,
      originalName: undefined,
      measuredDepth: 0,
      isTimeCustomFormat: !!timeCustomFormat,
      unitSystem: UnitSystem.Time,
      measurementType: GaugeMeasurementType.None,
      name,
      unitSymbol: unitSymbol,
      timeCustomFormat,
      isXAxis,
      colIndex: -1,
      isDeltaTimePreviewColumn: false,
      possibleCustomFormats: [],
    });
    return timeCol;
  }

  public initialize(state: IValidatedImportDataModuleState): void {
    this.customTimeCols = state.colConfigs.filter((c) => state.includedColumnIds.includes(c.colIndex) && c.isTimeCustomFormat);

    this.combinedCustomFormatResult = ImportDataCalculations.getCombinedCustomFormat(this.customTimeCols);
    this.startDate = undefined;
  }

  public generateArgument(
    row: string[],
    state: IValidatedImportDataModuleState,
    cols: IMapColumnsResponse,
  ): { argument: number; sourceArgument: string | null } {
    // this there is only one not custom format column
    // just return data without any parsing
    if (cols.argColIds.length === 1 && !cols.customFormat) {
      return { argument: +row[cols.argColIds[0]], sourceArgument: null };
    }

    // parse custom format data
    const sourceArgument = ImportDataCalculations.getCombinedArgColumnValue(row, cols.argColIds);
    let parsedDateResult: ICheckIfDataCanBeParsedResult;
    try {
      parsedDateResult = ImportDataCalculations.checkIfCustomDateCanBeParsed(
        row,
        state.colConfigs,
        this.combinedCustomFormatResult,
        this.customTimeCols,
        true,
      );
    } catch (err) {
      console.error(err);
      throw err;
    }
    // It's never null. It should crash when null
    const parsedCustomDate = parsedDateResult.customDate as Date;

    this.combinedCustomFormatResult = parsedDateResult.combinedCustomFormatResult;

    if (!this.startDate) {
      this.startDate = parsedCustomDate;
      this.days = 0;
    }

    const parsedTime = parsedCustomDate.getTime();

    const prevTime = this.prevTime ?? parsedTime;
    const prevTimeAndDays: ITimeAndDays = { time: prevTime, days: this.days };
    const updateTime = ImportDataCalculations.addDaysIfNeeded(parsedTime, prevTimeAndDays, this.combinedCustomFormatResult);

    this.prevTime = updateTime.time;
    this.days = updateTime.days;

    const ms = updateTime.time - this.startDate.getTime();
    const hours = ms / (1000 * 60 * 60); // convert to hours

    return {
      argument: hours,
      sourceArgument,
    };
  }

  public async validateArgument(
    parsedResult: IParsedFileResult,
    state: IValidatedImportDataModuleState,
    columns: IMapColumnsResponse,
  ): Promise<ILineError[]> {
    let counter = 0;

    const lineErrors: ILineError[] = [];
    do {
      const row = parsedResult.data[counter];
      const errorLine = counter + parsedResult.headerLines + 2;
      try {
        const argument = this.generateArgument(row, state, columns);

        if (isNaN(argument.argument)) {
          lineErrors.push({ line: errorLine, error: CANT_PARSE_ARGUMENT_ERROR });
        }
      } catch (error: any) {
        lineErrors.push({ line: errorLine, error });
      }

      if (counter > 0 && counter % 2500 === 0) {
        await wait(0);
      }
      counter++;
    } while (counter < parsedResult.data.length);
    return lineErrors;
  }
}

export interface IMapColumnsResponse {
  dtos: ImportColumnDto[];
  // colIds for argument data
  // when 1 it means that we're not combining columns
  // when > 1 it means that arg should be generated from multiple cols
  argColIds: number[];
  customFormat: string;
}
