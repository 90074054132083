import { Injectable } from '@angular/core';
import { ElectronService } from './electron.service';
import { Store } from '@ngrx/store';
import { getCurrentFileHasAnyUIBlockingJobs } from '../../../+store/calculation-engine/calculation-engine.selectors';
import {
  ELECTION_ACTION_CANCEL_PREVENT_APP_SUSPENSION,
  ELECTION_ACTION_PREVENT_APP_SUSPENSION,
} from '@dunefront/common/common/electron/electron.actions';

@Injectable()
export class ElectronServiceRef extends ElectronService {
  constructor(private store: Store) {
    super();

    if (this.instance) {
      this.configurePowerBlocker();
    }
  }

  private electronPowerSaverBlockerId: number | null = null;

  private configurePowerBlocker(): void {
    this.store.select(getCurrentFileHasAnyUIBlockingJobs).subscribe(async (isJobRunning) => {
      if (this.instance) {
        if (isJobRunning) {
          if (this.electronPowerSaverBlockerId == null) {
            this.electronPowerSaverBlockerId = (await this.instance.ipcRenderer.invoke(ELECTION_ACTION_PREVENT_APP_SUSPENSION)) as number;
          }
        } else if (this.electronPowerSaverBlockerId != null) {
          await this.instance.ipcRenderer.invoke(ELECTION_ACTION_CANCEL_PREVENT_APP_SUSPENSION, this.electronPowerSaverBlockerId);
          this.electronPowerSaverBlockerId = null;
        }
      }
    });
  }
}
