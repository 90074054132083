import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartComponent } from './chart.component';
import { ChartMarkerComponent } from './chart-marker/chart-marker.component';
import { FormInputModule } from '../../shared/components/form-components/form-input/form-input.component';
import { UnwrapDirectiveModule } from '../../shared/directives/unwrap.directive';
import { RadioGroupModule } from '../../shared/components/form-components/radio-group/radio-group.component';
import { CheckBoxModule } from '../../shared/components/form-components/check-box/check-box.component';
import { ChartMarkerDetailsComponent } from './chart-marker/chart-marker-details/chart-marker-details.component';
import { ChartControllerComponent } from './chart-controller.component';
import { ChartConfigurationGridComponent } from './chart-configuration/chart-configuration-grid/chart-configuration-grid.component';
import { GridModule } from '../../shared/components/grid/grid.module';
import { ReportingChartCreationComponent } from './reporting-chart-creation/reporting-chart-creation.component';
import { PagePanelModule } from '../page-panel/page-panel.component';
import { SelectModule } from '../../shared/components/form-components/select/select.module';
import { BaseChartConfigurationComponent } from './chart-configuration/base-chart-configuration.component';
import { ButtonModule } from '../../shared/components/button/button.module';
import { LoaderModule } from '../loader/loader.component';
import { ChartAxisStyleComponent } from './char-axis/chart-axis-style/chart-axis-style.component';
import { ChartLegendStyleComponent } from './chart-legend/chart-legend-style/chart-legend-style.component';
import { ChartSeriesStyleComponent } from './chart-series/chart-series-style/chart-series-style.component';
import { ColorPickerModule } from 'primeng/colorpicker';
import { FormsModule } from '@angular/forms';
import { ChartAxisPropsComponent } from './char-axis/chart-axis-props.component';
import { ChartLegendPropsComponent } from './chart-legend/chart-legend-props.component';
import { ChartSeriesPropsComponent } from './chart-series/chart-series-props.component';
import { ChartOverlayInfoComponent } from './chart-overlay-info/chart-overlay-info.component';
import { ChartAnnotationComponent } from './chart-annotation/chart-annotation.component';
import { ChartAnnotationDetailsComponent } from './chart-annotation/chart-annotation-details/chart-annotation-details.component';
import { CalendarModule } from 'primeng/calendar';
import { TooltipModule } from 'primeng/tooltip';
import { DateTimeInputModule } from '../../shared/components/form-components/date-time-input/date-time-input.component';
import { ModalsModule } from '../modals/modals.module';
import { FontStyleComponent } from '../../shared/components/font-style/font-style.component';
import { UnitsModule } from '../units/units.module';
import { MultiSelectModule } from 'primeng/multiselect';
import { ChartGradientLineDetailsComponent } from './chart-gradient-line/chart-gradient-line-details/chart-gradient-line-details.component';
import { ChartGradientLineComponent } from './chart-gradient-line/chart-gradient-line.component';
import { RangeLabelsSelectorModule } from '../../shared/components/range-labels-selector/range-labels-selector.module';
import { ListboxModule } from 'primeng/listbox';
import { EditChartTemplateModalComponent } from './edit-template-modal/edit-chart-template-modal.component';
import { HelpUrlPipeModule } from '../../shared/pipes/help-url.pipe';

@NgModule({
  declarations: [
    ChartComponent,
    ChartMarkerComponent,
    ChartMarkerDetailsComponent,
    ChartGradientLineComponent,
    ChartGradientLineDetailsComponent,
    ChartAnnotationComponent,
    ChartAnnotationDetailsComponent,
    ChartControllerComponent,
    ChartConfigurationGridComponent,
    ReportingChartCreationComponent,
    EditChartTemplateModalComponent,
    BaseChartConfigurationComponent,
    ChartAxisStyleComponent,
    ChartAxisPropsComponent,
    ChartLegendStyleComponent,
    ChartSeriesStyleComponent,
    ChartLegendPropsComponent,
    ChartOverlayInfoComponent,
    ChartSeriesPropsComponent,
  ],
  exports: [
    ChartComponent,
    ChartControllerComponent,
    ChartMarkerDetailsComponent,
    ChartGradientLineComponent,
    ChartGradientLineDetailsComponent,
    ChartAnnotationComponent,
    ChartAnnotationDetailsComponent,
    ChartConfigurationGridComponent,
    ChartAxisStyleComponent,
    ChartLegendStyleComponent,
    ChartSeriesStyleComponent,
    ChartLegendPropsComponent,
    ChartOverlayInfoComponent,
  ],
  imports: [
    CommonModule,
    ButtonModule,
    FormInputModule,
    MultiSelectModule,
    UnwrapDirectiveModule,
    RadioGroupModule,
    SelectModule,
    CheckBoxModule,
    GridModule,
    PagePanelModule,
    LoaderModule,
    ColorPickerModule,
    FormsModule,
    CalendarModule,
    TooltipModule,
    DateTimeInputModule,
    ModalsModule,
    FontStyleComponent,
    UnitsModule,
    RangeLabelsSelectorModule,
    ListboxModule,
    HelpUrlPipeModule,
  ],
})
export class ChartModule {}
