import { Component } from '@angular/core';
import { ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import { CommonCalculationPageComponent } from '../common-calculation-page.component';
import { CalculatorsTab } from '../../../+store/ui/ui-module.state';

@Component({
  selector: 'app-settling',
  templateUrl: './settling.component.html',
  styleUrls: ['./settling.component.scss'],
})
export class SettlingComponent extends CommonCalculationPageComponent {
  public override appModuleType = ModuleType.Settling_Calc;
  public override tab: CalculatorsTab = 'settling';
}
