export class ArrayHelpers {
  public static findLastIndex<T>(array: Array<T>, predicate: (value: T, index: number, obj: T[]) => boolean): number {
    let l = array.length;
    while (l--) {
      if (predicate(array[l], l, array)) {
        return l;
      }
    }
    return -1;
  }

  public static findLast<T>(array: Array<T>, predicate: (value: T) => boolean): T | undefined {
    for (let i = array.length - 1; i >= 0; --i) {
      const x = array[i];
      if (predicate(x)) {
        return x;
      }
    }

    return undefined;
  }

  public static prev<T>(array: Array<T>, predicate: (value: T) => boolean): T | undefined {
    const currIndex = array.findIndex(predicate);
    if (currIndex < 1) {
      return undefined;
    }
    return array[currIndex - 1];
  }

  public static last<T>(array: Array<T>): T | undefined {
    return array[array.length - 1];
  }

  public static sum(array: number[]): number {
    return array.reduce((a: number, b: number) => a + b, 0);
  }

  public static average(array: number[]): number {
    return this.sum(array) / array.length;
  }

  public static stringCompare(string1: string, string2: string): number {
    // using string.toString in case the string contains a number
    return string1.toString().localeCompare(string2.toString());
  }

  public static findClosest<T>(array: T[], key: keyof T, needle: number): T {
    return array.reduce((a, b) => {
      const aKeyValue = a[key];
      const bKeyValue = b[key];
      if (typeof aKeyValue !== 'number' || typeof bKeyValue !== 'number') {
        throw new Error('findClosest - property is not a number');
      }
      const aDiff = Math.abs(aKeyValue - needle);
      const bDiff = Math.abs(bKeyValue - needle);

      if (aDiff == bDiff) {
        return needle < aKeyValue ? a : b;
      } else {
        return bDiff < aDiff ? b : a;
      }
    });
  }

  // TODO: move to binary search
  public static findIndexToInsert<T>(array: T[], newItem: T, compare: (a: T, b: T) => number): number {
    for (let i = 0; i < array.length; i++) {
      if (compare(newItem, array[i]) > 0) {
        return i;
      }
    }

    return array.length;
  }

  public static insertOrdered<T>(array: T[], newItem: T, compare: (a: T, b: T) => number): T[] {
    const index = this.findIndexToInsert(array, newItem, compare);
    return array.splice(index, 0, newItem);
  }

  public static countAboveAndBelow(arr: number[], median: number): { above: number; below: number } {
    return arr.reduce(
      (s, n) => {
        s[n <= median ? 'below' : 'above']++;
        return s;
      },
      { above: 0, below: 0 },
    );
  }

  public static move<Entity>(arr: Entity[], from: number, to: number): Entity[] {
    let numberOfDeletedElm = 1;
    const elm = arr.splice(from, numberOfDeletedElm)[0];
    numberOfDeletedElm = 0;
    arr.splice(to, numberOfDeletedElm, elm);
    return arr;
  }

  public static deleteRowsWithIds<T extends { Id: number }>(arr: T[], ids: (number | string)[]): T[] {
    return arr.filter((el) => !ids.includes(el.Id));
  }

  public static replaceById<T extends { Id: number }>(arr: T[], item: T): T[] {
    return arr.map((arrItem) => (arrItem.Id === item.Id ? item : arrItem));
  }

  public static upsertById<T extends { Id: number }>(arr: T[], item: T): T[] {
    if (arr.some((arrItem) => arrItem.Id === item.Id)) {
      return this.replaceById(arr, item);
    } else {
      return [...arr, item];
    }
  }

  public static replace<T extends { Id: number }>(arr: T[], items: T[]): T[] {
    return arr.map((arrItem) => {
      const toUpdate = items.find((i) => i.Id === arrItem.Id);
      return toUpdate ? toUpdate : arrItem;
    });
  }

  public static containCommonElements<T>(arr1: T[], arr2: T[]): boolean {
    return arr1.some((item) => arr2.includes(item));
  }
}
