import { Chart } from 'chart.js';
import { DeepPartial } from 'chart.js/dist/types/utils';
import { ChartContext, IGradientLine } from './chart-types';
import { GradientLine, GradientLinePluginOptions, ScaleInfo } from '../gradient-line-plugin/types';
import { ChartConversionsHelper } from './chart-conversions-helper';
import { GradientLinePluginId } from '../gradient-line-plugin/gradient-line-plugin';
import { updateChart } from './chart-common-helpers';

export interface CreateGradientLinePayload {
  argument1: number;
  value1: number;
  argument2: number;
  value2: number;
}

export const getGradientLinePluginOptions = (chart: Chart): DeepPartial<GradientLinePluginOptions> | undefined => {
  return (chart?.options?.plugins as any | undefined)?.[GradientLinePluginId];
};

export const updateGradientLinePluginOptions = (
  gradientLines: IGradientLine[],
  scales: ScaleInfo[],
  gradientLinesVisible: boolean,
  chart: Chart,
  context: ChartContext,
): void => {
  const pluginOptions = getGradientLinePluginOptions(chart);
  if (pluginOptions == null) {
    return;
  }

  const converter = ChartConversionsHelper.getConverter(chart, context);

  pluginOptions.lines = gradientLinesVisible ? convertSiToAxisUnitsGridLines(gradientLines, chart, context) : [];
  pluginOptions.scales = scales;
  pluginOptions.xScaleID = converter?.xScaleID;
  pluginOptions.yScaleID = converter?.yScaleID;

  updateChart(chart);
};

export const convertSiToAxisUnitsGridLines = (gradientLines: IGradientLine[], chart: Chart, context: ChartContext): GradientLine[] => {
  const converter = ChartConversionsHelper.getConverter(chart, context);
  if (converter == null) {
    return [];
  }

  return gradientLines.map((gradientLine) => {
    const p1 = converter.convertPointFromSiToAxisValues(gradientLine.argument1, gradientLine.value1);
    const p2 = converter.convertPointFromSiToAxisValues(gradientLine.argument2, gradientLine.value2);

    return {
      id: gradientLine.Id,
      xAxisValue1: p1.xAxisValue,
      yAxisValue1: p1.yAxisValue,
      xAxisValue2: p2.xAxisValue,
      yAxisValue2: p2.yAxisValue,
      xScaleID: converter.xScaleID,
      yScaleID: converter.yScaleID,
      style: gradientLine.style ?? context.defaultGradientLineStyle,
    };
  });
};
