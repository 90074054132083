import { Pipe } from '../pipes/pipe';
import { FluidProSettings } from '../settings/dto/settingsDto';

export class FluidProValidation {
  public static ValidateFluidProComponentSize(pipes: Pipe[], rowIndex: number, fluidProSettings: FluidProSettings): string {
    const componentLength = pipes[rowIndex].Length;
    if (componentLength < fluidProSettings.FpMergeCellLength) {
      return 'This is a short component which may be merged with adjacent components to improve performance';
    }

    return '';
  }
}
