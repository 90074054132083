import { LoadMASPCalculatorActionResponse } from '@dunefront/common/modules/calculators/masp-calculator/masp-calculator-module.action';
import { CrudResponse } from '@dunefront/common/modules/common.actions';
import {
  MASPCalculatorModuleInitialState,
  MASPCalculatorModuleState,
} from '@dunefront/common/modules/calculators/masp-calculator/masp-calculator-module.state';
import { DeleteResultsFilter, ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import { MASPCalculatorFactory } from '@dunefront/common/modules/calculators/masp-calculator/model/masp-calculator.factory';
import { ActionResponse } from '@dunefront/common/response-ws.action';

export class MASPCalculatorModuleReducerHelper {
  public static onLoadScenarioDataSuccessAction(
    state: MASPCalculatorModuleState,
    response: ActionResponse<LoadMASPCalculatorActionResponse>,
  ): MASPCalculatorModuleState {
    if (response.status !== 'ok' || !response.payload) {
      return MASPCalculatorModuleInitialState;
    }
    return {
      ...response.payload.MASPCalculatorDto,
      isLoaded: true,
    };
  }

  public static onUpdateMASPCalculatorSuccess(state: MASPCalculatorModuleState, response: CrudResponse): MASPCalculatorModuleState {
    if (!response.affectedRows.MASPCalculator) {
      return state;
    }
    return {
      ...state,
      ...response.affectedRows.MASPCalculator,
    };
  }

  public static onRemoveDataResultsFromStore(
    state: MASPCalculatorModuleState,
    deleteResultsFilter: DeleteResultsFilter,
  ): MASPCalculatorModuleState {
    if (
      (deleteResultsFilter.moduleTypes != null && !deleteResultsFilter.moduleTypes.includes(ModuleType.MASP_Calc)) ||
      (deleteResultsFilter.scenarioIds != null && !deleteResultsFilter.scenarioIds.includes(state.ScenarioId))
    ) {
      return state;
    }
    return {
      ...state,
      ...MASPCalculatorFactory.cleanResultsPartial(),
    };
  }
}
