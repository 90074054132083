import { PSDAnalysisJobInputData, PSDDto } from './dto/psd.dto';
import { PsdSandProductionInputs } from './dto/psd-sand-production.inputs';
import { PSDReadingDto } from './dto/psd-reading.dto';
import { WsAction, DeleteRowsWsAction, InsertRowsWsAction, UpdateRowsWsAction } from '../../ws.action';

import { WsActionPropsFactory } from '../../common/ws-action/ws-action-props.factory';

import { IUpdateRowsProps, IUpdateTableRowsProps } from '../../common/common-store-crud.interfaces';
import { PSD } from './model/psd/psd';
import { PSDFactory } from './model/psd/psd.factory';
import { PSDAnalysis } from './model/psd-analysis/psd-analysis';
import { PSDAnalysisFactory } from './model/psd-analysis/psd-analysis.factory';
import { PsdReading } from './model/psd-readings/psd-reading';
import { PsdReadingsFactory } from './model/psd-readings/psd-readings.factory';

export const PSDAnalysisModuleName = 'PSDAnalysisModule';

export enum PSDAnalysisModuleActionTypes {
  PSDCalculate = '[PSDAnalysisModule] [PSD] Calculate',
  PSDInsertRows = '[PSDAnalysisModule] [PSD] InsertRows',
  PSDUpdateRows = '[PSDAnalysisModule] [PSD] UpdateRows',
  PSDDeleteRows = '[PSDAnalysisModule] [PSD] DeleteRows',
  PSDReadingsInsertRows = '[PSDAnalysisModule] [PSDReadings] InsertRows',
  PSDReadingsUpdateRow = '[PSDAnalysisModule] [PSDReadings] UpdateRow',
  PSDReadingsDeleteRows = '[PSDAnalysisModule] [PSDReadings] DeleteRows',
  UpdatePSDAnalysis = '[PSDAnalysisModule] [PSDAnalysis] Update',
}

export interface LoadPSDAnalysisActionResponse {
  psdDtos: PSDDto[];
  psdAnalysisDto: PsdSandProductionInputs;
  psdReadingsDtos: PSDReadingDto[];
}

export class PSDCalculateAction extends WsAction {
  public override readonly type = PSDAnalysisModuleActionTypes.PSDCalculate;

  constructor(
    public scenarioId: number,
    public override payload: PSDAnalysisJobInputData,
    public isFullAnalysis: boolean,
  ) {
    super();
  }
}

// region PSD

export class PSDInsertRowsAction extends InsertRowsWsAction<PSDDto> {
  public override readonly type = PSDAnalysisModuleActionTypes.PSDInsertRows;
}

export class PSDDeleteRowsAction extends DeleteRowsWsAction {
  public override readonly type = PSDAnalysisModuleActionTypes.PSDDeleteRows;
}

export class PSDUpdateRowsAction extends UpdateRowsWsAction<PSDDto> {
  public override readonly type = PSDAnalysisModuleActionTypes.PSDUpdateRows;

  constructor(action: IUpdateRowsProps<PSD>) {
    super(WsActionPropsFactory.updateAction(action, PSDFactory.psdToDto));
  }
}

// endregion

// region PSD Analysis

export class UpdatePSDAnalysisAction extends UpdateRowsWsAction<PsdSandProductionInputs> {
  public override readonly type = PSDAnalysisModuleActionTypes.UpdatePSDAnalysis;

  constructor(action: IUpdateRowsProps<PSDAnalysis>) {
    super(WsActionPropsFactory.updateAction(action, PSDAnalysisFactory.toDto));
  }
}

// endregion

// region PSD Readings

export class PSDReadingsInsertRowsAction extends InsertRowsWsAction<PSDReadingDto> {
  public override readonly type = PSDAnalysisModuleActionTypes.PSDReadingsInsertRows;
}

export class PSDReadingsUpdateRowAction extends UpdateRowsWsAction<PSDReadingDto> {
  public override readonly type = PSDAnalysisModuleActionTypes.PSDReadingsUpdateRow;

  constructor(action: IUpdateTableRowsProps<PsdReading>) {
    super(WsActionPropsFactory.updateAction(action, PsdReadingsFactory.psdReadingsToDto));
  }
}

export class PSDReadingsDeleteRowsAction extends DeleteRowsWsAction {
  public override readonly type = PSDAnalysisModuleActionTypes.PSDReadingsDeleteRows;
}

// endregion

export type PSDModuleActions =
  | PSDInsertRowsAction
  | PSDDeleteRowsAction
  | PSDUpdateRowsAction
  | UpdatePSDAnalysisAction
  | PSDReadingsInsertRowsAction
  | PSDReadingsUpdateRowAction
  | PSDReadingsDeleteRowsAction
  | PSDCalculateAction;
