import { loadScenarioDataSuccessAction, updateRowSuccess } from '../../app.actions';
import { createReducer, on } from '@ngrx/store';
import { SettlingCalculatorReducerHelper } from './settling-calculator.reducer.helper';
import { SettlingCalculatorInitialState, SettlingCalculatorModuleState } from './settling-calculator-module.state';
import { removeDataResultsFromStoreIfNeeded } from '../../data-storage/data-storage.actions';

export const SettlingCalculatorModuleFeatureName = 'settlingCalculator';

export const settlingCalculatorModuleReducer = createReducer<SettlingCalculatorModuleState>(
  SettlingCalculatorInitialState,
  on(loadScenarioDataSuccessAction, (state, action) =>
    SettlingCalculatorReducerHelper.onLoadScenarioDataSuccessAction(state, action.loadScenarioResponse.settlingCalculatorModule),
  ),
  on(updateRowSuccess, (state, action) => SettlingCalculatorReducerHelper.onUpdateSettlingCalculatorSuccess(state, action)),
  on(updateRowSuccess, (state, action) => SettlingCalculatorReducerHelper.onUpdateSettlingCalculatorResultSuccess(state, action)),
  on(
    removeDataResultsFromStoreIfNeeded,
    (state, action): SettlingCalculatorModuleState =>
      SettlingCalculatorReducerHelper.onRemoveDataResultsFromStore(state, action.deleteResultsFilter),
  ),
);
