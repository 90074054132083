import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ToolPosition } from '@dunefront/common/modules/pumping/dto/pumping.dto';
import { EnumHelpers } from '@dunefront/common/utils/enum.helpers';
import { Pumping } from '@dunefront/common/modules/pumping/model/pumping/pumping';
import { ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';

@Component({
  selector: 'app-tool-position',
  templateUrl: './tool-position.component.html',
  styleUrls: ['./tool-position.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolPositionComponent {
  @Input() public pumping: Pumping | undefined;
  @Output() public dataChange = new EventEmitter<ObjectChangeProp<Pumping>>();

  public items = EnumHelpers.EnumToRadioItems(
    ToolPosition,
    undefined,
    undefined,
    new Map([
      [ToolPosition.Circulating_GP, 'Circulating'],
      [ToolPosition.Reverse_Port_GP, 'RPGP'],
    ]),
  );
}
