import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { ISelectItem, toSelectItem } from '@dunefront/common/common/select.helpers';
import { DictionaryWithArray, IDictionaryWithArray } from '@dunefront/common/common/state.helpers';
import { IValidatedStorageFileWithColumns } from '@dunefront/common/modules/data-storage/data-storage.validation';
import { ImportFileDto } from '@dunefront/common/modules/data-storage/dto/import-file.dto';
import { RouterHelperService } from '../../../../shared/services/router-helper.service';

@Component({
  selector: 'app-data-file-selector',
  templateUrl: './data-file-selector.component.html',
  styleUrls: ['./data-file-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataFileSelectorComponent implements OnChanges {
  @Input()
  public files: IDictionaryWithArray<IValidatedStorageFileWithColumns> = DictionaryWithArray.clear();

  @Input()
  public selectedFileId!: number | undefined;

  public filesItems: ISelectItem<number>[] = [];

  public get fileErrorMessage(): string {
    return DictionaryWithArray.get(this.files, this.selectedFileId)?.equationValidationError ?? '';
  }

  constructor(
    private store: Store,
    private routerHelperService: RouterHelperService,
  ) {}

  public async changeValue(fileId: number): Promise<void> {
    await this.routerHelperService.navigateToFile(fileId);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.files != null) {
      this.filesItems = DictionaryWithArray.getArray(this.files)
        .filter((f) => f.file)
        .map((f) => {
          const file = f.file as ImportFileDto;
          return toSelectItem(file.Id, file.FileName, '', !f.isValid);
        });
    }
  }
}
