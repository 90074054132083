import { RangeConstants, RangeDto } from '../../dto/range.dto';
import { PumpingModuleState } from '../pumping/pumping-module.state';
import { DictionaryWithArray, IDictionaryWithArray } from '../../common/state.helpers';
import { CompletionModuleState } from '../completion/completion-module.state';
import { MASPCalculatorDto } from '../calculators/masp-calculator/masp-calculator.dto';
import { ResuspensionCalculatorDto } from '../calculators/resuspension-calculator/resuspension-calculator.dto';
import { FrictionCalculatorDto } from '../calculators/friction-calculator/friction-calculator.dto';
import { SettlingCalculatorDto } from '../calculators/dto/settling-calculator.dto';

export class FluidGravelUsageFinder {
  public static FindFluidInCalculators(fluidId: number, deps: FluidAndGravelUsageCalculatorsDeps): string[] {
    const errorStrings: string[] = [];
    if (deps.maspCalculator.FluidId === fluidId) {
      errorStrings.push('MASP Calculator');
    }
    if (deps.resuspensionCalculator.FluidId === fluidId) {
      errorStrings.push('Resuspension Calculator');
    }
    if (deps.frictionCalculator.FluidId === fluidId) {
      errorStrings.push('Friction Calculator');
    }
    if (deps.settlingCalculator.FluidId === fluidId) {
      errorStrings.push('Settling Calculator');
    }
    return errorStrings;
  }

  public static FindFluidInPumping(
    fluidId: number,
    deps: FluidUsageSimulateEvaluateFinderDeps,
    forRangeId: number | 'all-ranges',
  ): string[] {
    const { pumpingState } = deps;
    const errorStrings: string[] = [];
    const ranges = this.getRanges(deps.ranges, forRangeId);

    for (const range of ranges) {
      const rangePumping = pumpingState[range.Id];
      // pumping
      if (
        (rangePumping != null &&
          [
            rangePumping.pumping.WashpipeFluidId,
            rangePumping.pumping.WorkstringFluidId,
            rangePumping.pumping.LowerAnnulusFluidId,
            rangePumping.pumping.UpperAnnulusFluidId,
          ].includes(fluidId)) ||
        rangePumping.wellFluids.rows.some((wf) => wf.rowData.FluidId === fluidId)
      ) {
        // well fluids
        errorStrings.push(range.Name + 'Well Fluids');
      }

      if (range.Id === RangeConstants.EmptyRangeId) {
        // this is for simulation
        if (pumpingState.pumpingSchedule.rows.some((ps) => ps.rowData.FluidId === fluidId)) {
          errorStrings.push(range.Name + 'Schedule');
        }
        if ([rangePumping.pumping.AutoScheduleCarrierFluidId, rangePumping.pumping.AutoScheduleFlushFluidId].includes(fluidId)) {
          errorStrings.push('Schedule Options');
        }
      } else {
        // this is for evaluation
        if (rangePumping.pumpedFluidAndGravel.rows.some((wf) => wf.rowData.FluidId === fluidId)) {
          errorStrings.push(range.Name + 'Schedule');
        }
      }
    }
    return errorStrings;
  }

  public static FindGravelInCalculators(gravelId: number, deps: FluidAndGravelUsageCalculatorsDeps): string[] {
    const errorStrings: string[] = [];

    if (deps.maspCalculator.GravelId === gravelId) {
      errorStrings.push('MASP Calculator');
    }
    if (deps.resuspensionCalculator.GravelId === gravelId) {
      errorStrings.push('Resuspension Calculator');
    }
    if (deps.frictionCalculator.GravelId === gravelId) {
      errorStrings.push('Friction Calculator');
    }
    if (deps.settlingCalculator.GravelId === gravelId) {
      errorStrings.push('Settling Calculator');
    }
    return errorStrings;
  }

  public static FindGravelInPumping(
    gravelId: number,
    deps: FluidUsageSimulateEvaluateFinderDeps,
    forRangeId: number | 'all-ranges',
  ): string[] {
    const { pumpingState } = deps;
    const errorStrings: string[] = [];
    const ranges = this.getRanges(deps.ranges, forRangeId);

    for (const range of ranges) {
      const rangePumping = pumpingState[range.Id];
      const rangeName = range.Id === RangeConstants.EmptyRangeId ? '' : 'Evaluate -> ' + range.Name + ' -> ';

      if (rangePumping.wellFluids.rows.some((wf) => wf.rowData.GravelId === gravelId)) {
        errorStrings.push(rangeName + 'Well Fluids');
      }
      if (range.Id === RangeConstants.EmptyRangeId) {
        // this is for simulation
        if (pumpingState.pumpingSchedule.rows.some((ps) => ps.rowData.GravelId === gravelId)) {
          errorStrings.push(rangeName + 'Schedule');
        }
        if (rangePumping.pumping.AutoScheduleGravelId === gravelId) {
          errorStrings.push('Schedule Options');
        }
        if (deps.completion.Volumes.SectionVolCalcGravelId === gravelId) {
          errorStrings.push(rangeName + 'Volumes');
        }
      } else {
        // this is for evaluation
        if (rangePumping.pumpedFluidAndGravel.rows.some((wf) => wf.rowData.GravelId === gravelId)) {
          errorStrings.push(rangeName + 'Schedule');
        }
      }

      if (deps.completion.Volumes.SectionVolCalcGravelId === gravelId) {
        errorStrings.push(rangeName + 'Volumes');
      }
    }
    return errorStrings;
  }

  private static getRanges(allRanges: IDictionaryWithArray<RangeDto>, forRangeId: number | 'all-ranges'): { Id: number; Name: string }[] {
    const rangeIdWithName = DictionaryWithArray.getArray<RangeDto>(allRanges).map((r) => ({
      Id: r.Id,
      Name: 'Evaluate -> ' + r.Name + ' -> ',
    }));

    const ranges = [{ Id: RangeConstants.EmptyRangeId, Name: '' }, ...rangeIdWithName].filter((r) =>
      forRangeId === 'all-ranges' ? true : r.Id === forRangeId,
    );

    return ranges;
  }
}

export interface FluidUsageSimulateEvaluateFinderDeps {
  pumpingState: PumpingModuleState;
  ranges: IDictionaryWithArray<RangeDto>;
  completion: CompletionModuleState;
}

export interface FluidAndGravelUsageCalculatorsDeps {
  maspCalculator: MASPCalculatorDto;
  resuspensionCalculator: ResuspensionCalculatorDto;
  frictionCalculator: FrictionCalculatorDto;
  settlingCalculator: SettlingCalculatorDto;
}
