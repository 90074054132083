import { Injectable, NgZone } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Action, Store } from '@ngrx/store';
import { BackendConnectionService } from '../../shared/backend-connection/backend-connection.service';
import { BaseWsEffects } from '../base-ws.effects';
import {
  CancelDetachLicenseAction,
  DetachLicenseAction,
  GetLicenseInfoAction,
  LicenseCheckSessionsAction,
  LicenseGenerateDiagnosticsReport,
  LicenseReloginAction,
  LicenseSwitchAction,
  LicensingModuleName,
} from '@dunefront/common/modules/licensing/licensing-module.actions';
import { ModalService } from '../../common-modules/modals/modal.service';
import { backendConnectedAction, dbConnectedSuccessAction, dbDisconnectAction } from '../backend-connection/backend-connection.actions';
import { catchError, filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import {
  HaspStatus,
  LicenseFeature,
  LicenseInfo,
  LicensingCheckSessionsResponse,
  LicensingLoginResponse,
} from '@dunefront/common/modules/licensing/licensing.interfaces';
import {
  cancelDetachConnectionCheckFailureAction,
  cancelDetachLicenseAction,
  cancelDetachLicenseSuccessAction,
  detachLicenseAction,
  detachLicenseSuccessAction,
  generateLicensingDiagnosticsReportAction,
  licenseInfoLoadedAction,
  licenseReloginUserAction,
  licenseReloginUserSuccessAction,
  licenseSwitchAction,
  licenseSwitchSuccessAction,
  licensingFailureAction,
  loadLicenseInfoAction,
  openModuleSelectorDialog,
  refreshLicenseInfoSuccessAction,
  toggleAddonAction,
} from './licensing.actions';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { firstValueFrom, of, Subscription, timer } from 'rxjs';
import {
  findAccessibleFeaturesAndLicenses,
  getAccessibleFeaturesAndLicenses,
  getCurrentFeatures,
  getLicensingState,
  getMainFeatureLoginInfo,
  getSelectedAddonFeatures,
} from './licensing.selectors';
import { FileManagerHelper } from '../file-manager/file-manager.helper';
import { Router } from '@angular/router';
import { LicensingModuleState } from '@dunefront/common/modules/licensing/licensing-module.state';
import { getRandomIntInclusive } from '@dunefront/common/common/helpers';
import { createLicensingError, getLicenseErrorTextFromError, isLicensingError } from '@dunefront/common/exceptions/IAppError';
import { LicensingErrorType } from '@dunefront/common/exceptions/errors';
import { RouterHelperService } from '../../shared/services/router-helper.service';
import { undoRedoClearHistoryAction } from '../undo-redo/undo-redo.action';
import {
  ModuleSelectorDialogComponent,
  ModuleSelectorDialogResult,
} from '../../pages/home/module-selector-dialog/module-selector-dialog.component';
import { LICENSING_HEART_BEAT_TIMER_INTERVAL, LICENSING_HEART_BEAT_TIMER_START_RANDOM } from '../../client-constants';

import { WsActionResponse } from '@dunefront/common/response-ws.action';
import { ConnectionLicensingConfig } from '@dunefront/common/modules/db-connection/db-connection.actions';
import { switchModuleAction } from '../app.actions';

@Injectable()
export class LicensingEffects extends BaseWsEffects {
  private licenseHeartBeatSubscription = new Subscription();
  private blockCheckingLicense = false;

  constructor(
    actions$: Actions,
    store: Store,
    wsService: BackendConnectionService,
    modalService: ModalService,
    protected router: Router,
    protected ngZone: NgZone,
    private routerHelperService: RouterHelperService,
  ) {
    super(actions$, wsService, LicensingModuleName, false, false, modalService, store);
  }

  private switchingLicenseDialogRef: DynamicDialogRef | undefined;

  private dbConnectedSuccessAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(dbConnectedSuccessAction),
        tap(() => this.startLicenseHeartBeatCheck()),
      ),
    { dispatch: false },
  );

  private dbDisconnectAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(dbDisconnectAction),
        tap(() => this.licenseHeartBeatSubscription?.unsubscribe()),
      ),
    { dispatch: false },
  );

  private triggerLoadLicenseInfoAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(backendConnectedAction, detachLicenseSuccessAction, cancelDetachLicenseSuccessAction),
      map(() => loadLicenseInfoAction()),
    ),
  );

  public loadLicenseInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadLicenseInfoAction),
      mergeMap((action) =>
        this.emit<LicenseInfo>(new GetLicenseInfoAction()).pipe(
          map((result) => licenseInfoLoadedAction({ licenseInfo: result.payload })),
          catchError((error) => of(licensingFailureAction({ error, srcAction: action }))),
        ),
      ),
    ),
  );

  public detachLicenseAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(detachLicenseAction),
      tap(() => (this.blockCheckingLicense = true)),
      mergeMap((action) =>
        this.emit<LicenseInfo>(new DetachLicenseAction(action.licenseId, action.productId, action.duration)).pipe(
          map(() => detachLicenseSuccessAction()),
          catchError((error) => of(licensingFailureAction({ error, srcAction: action }))),
        ),
      ),
    ),
  );

  public cancelDetachLicenseAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cancelDetachLicenseAction),
      tap(() => (this.blockCheckingLicense = true)),
      mergeMap((action) =>
        this.emit<LicenseInfo>(new CancelDetachLicenseAction(action.licenseId, action.parentLicenseId)).pipe(
          map((result) => cancelDetachLicenseSuccessAction()),
          catchError((error) =>
            error.data.licenseErrorType === LicensingErrorType.ERROR_LICENSE_SERVER_NOT_AVAILABLE
              ? of(cancelDetachConnectionCheckFailureAction({ error, srcAction: action }))
              : of(licensingFailureAction({ error, srcAction: action })),
          ),
        ),
      ),
    ),
  );

  public detachLicenseSuccessAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(detachLicenseSuccessAction, cancelDetachLicenseSuccessAction),
        switchMap(async (action) => {
          const message =
            action.type === detachLicenseSuccessAction.type
              ? 'License detached successfully.<br>You will be switched to new license now.'
              : 'Detached license cancelled successfully.<br>You will be switched to the parent license now.';
          await this.modalService.showAlert(message);
        }),
        concatLatestFrom(() => [
          this.store.select(getMainFeatureLoginInfo),
          this.store.select(getSelectedAddonFeatures),
          this.store.select(getAccessibleFeaturesAndLicenses),
        ]),
        map(([action, mainLogin, addonFeatures, accessibleFeaturesAndLicenses]) => {
          if (mainLogin == null) {
            this.clearUndoHistoryAndGoHome();
          } else {
            this.store.dispatch(
              licenseSwitchAction({
                licensingConfig: {
                  feature: mainLogin.licenseFeature,
                  addonFeatures,
                  licenseIds: accessibleFeaturesAndLicenses.licenseIds,
                },
              }),
            );
          }
        }),
      ),
    { dispatch: false },
  );

  public refreshLicenseInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(openModuleSelectorDialog),
      mergeMap((action) => {
        return this.emit<LicenseInfo>(new GetLicenseInfoAction()).pipe(
          map((result) => refreshLicenseInfoSuccessAction({ licenseInfo: result.payload })),
          filter(() => !this.modalService.areAnyDialogsOfTypeOpen(ModuleSelectorDialogComponent)),
          tap(async () => {
            const dialogRef = this.modalService.open(ModuleSelectorDialogComponent, {}, 'module-selector', 'lg');
            const selectedFeature = (await firstValueFrom(dialogRef.onClose)) as ModuleSelectorDialogResult;
            if (selectedFeature != null) {
              this.store.dispatch(switchModuleAction({ file: action.file, selectedLicenseFeature: selectedFeature.licenseFeature }));
            } else if (action.redirectHomeAfterCancel) {
              await this.routerHelperService.navigateToHome();
            }
          }),
          catchError((error) => of(licensingFailureAction({ error, srcAction: action }))),
        );
      }),
    ),
  );

  public checkSelectedAddons$ = createEffect(() =>
    this.actions$.pipe(
      ofType(refreshLicenseInfoSuccessAction, licenseInfoLoadedAction),
      concatLatestFrom(() => [this.store.select(getSelectedAddonFeatures)]),
      mergeMap(([action, selectedAddons]) => {
        const result: Action[] = [];

        for (const selectedAddon of selectedAddons) {
          if (!action.licenseInfo.features.includes(selectedAddon)) {
            result.push(toggleAddonAction({ licenseFeature: selectedAddon }));
          }
        }

        return result;
      }),
    ),
  );

  public licensingFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(licensingFailureAction),
        tap((action) => this.onLdkServerError(action.error, action.srcAction)),
      ),
    { dispatch: false },
  );

  public cancelDetachLicenseFailureAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(cancelDetachConnectionCheckFailureAction),
        tap((action) => this.onLdkServerError(action.error, action.srcAction, false, true)),
      ),
    { dispatch: false },
  );

  public licenseTryLoginToOtherLicense$ = createEffect(() =>
    this.actions$.pipe(
      ofType(licenseSwitchAction),
      mergeMap((action) =>
        this.emit<LicensingLoginResponse>(new LicenseSwitchAction(action.licensingConfig), 'Switching license...').pipe(
          map((result) => {
            this.blockCheckingLicense = false;
            return licenseSwitchSuccessAction({ licensingLoginResponse: result.payload });
          }),
        ),
      ),
    ),
  );

  public licenseSwitchSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(licenseSwitchSuccessAction),
        tap(() => this.switchingLicenseDialogRef?.close()),
      ),
    { dispatch: false },
  );

  public licenseReloginUserAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(licenseReloginUserAction),
      mergeMap((action) => {
        this.blockCheckingLicense = true;
        return this.emit<LicensingLoginResponse>(new LicenseReloginAction(action.licensingConfig), 'Reconnecting to license session...').pipe(
          map((result) =>
            licenseReloginUserSuccessAction({
              payload: { licensingLoginResponse: result.payload },
              auto: action.auto,
              isChangingModule: action.isChangingModule,
            }),
          ),
          catchError((error) =>
            of(
              licensingFailureAction({
                error,
                srcAction: licenseReloginUserAction({
                  auto: false,
                  licensingConfig: action.licensingConfig,
                  isChangingModule: action.isChangingModule,
                }),
              }),
            ),
          ),
          tap(() => (this.blockCheckingLicense = false)),
        );
      }),
    ),
  );

  public licenseReloginUserSuccessAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(licenseReloginUserSuccessAction),
        concatLatestFrom(() => this.store.select(getLicensingState)),
        tap(async ([action, licensingState]) => {
          if (licensingState.licensingLogin == null) {
            return;
          }

          if (await this.isLicenseValid(licensingState.licensingLogin.mainFeature, licensingState)) {
            if (!action.auto) {
              await this.modalService.showAlert('License session has been successfully restored.');
            }
            if (action.isChangingModule) {
              this.store.dispatch(licenseSwitchSuccessAction({ licensingLoginResponse: action.payload.licensingLoginResponse }));
            }
          }
        }),
      ),
    { dispatch: false },
  );

  public btnGenerateLicensingDiagnosticsAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(generateLicensingDiagnosticsReportAction),
        mergeMap((action) => {
          return this.emit<string>(new LicenseGenerateDiagnosticsReport(), 'Generating licensing diagnostics...', '', true).pipe(
            tap(async (result) => {
              await this.modalService.showAlert(`Report Successfully Generated. <br>It's located in: ${result.payload}`);
            }),
            catchError((error) => this.modalService.showAlert(`Task failed with error: ${error}`)),
          );
        }),
      ),
    { dispatch: false },
  );

  public async checkIsLicenseSessionsActive(licensingConfig: ConnectionLicensingConfig): Promise<void> {
    this.blockCheckingLicense = true;
    let licenses: WsActionResponse<LicensingCheckSessionsResponse>;
    try {
      licenses = await this.wsService.emitAsync<LicensingCheckSessionsResponse>(LicensingModuleName, false, new LicenseCheckSessionsAction());
    } catch {
      licenses = { payload: { sessionStatuses: [] }, status: 'error' };
    }

    const foundSessionsActive = licenses.payload.sessionStatuses.find((session) => session.feature === licensingConfig.feature)?.isActive;
    if (!foundSessionsActive) {
      throw createLicensingError(LicensingErrorType.ERROR_LICENSE_SESSION_EXPIRED, '', licensingConfig);
    }
  }

  private startLicenseHeartBeatCheck(): void {
    this.ngZone.runOutsideAngular(() => {
      this.licenseHeartBeatSubscription = timer(
        getRandomIntInclusive(
          LICENSING_HEART_BEAT_TIMER_INTERVAL - LICENSING_HEART_BEAT_TIMER_START_RANDOM,
          LICENSING_HEART_BEAT_TIMER_INTERVAL + LICENSING_HEART_BEAT_TIMER_START_RANDOM,
        ),
        LICENSING_HEART_BEAT_TIMER_INTERVAL,
      )
        .pipe(
          concatLatestFrom(() => [
            this.store.select(getCurrentFeatures),
            this.store.select(getSelectedAddonFeatures),
            this.store.select(getAccessibleFeaturesAndLicenses),
          ]),
          map(([, features, addonFeatures, accessibleFeaturesAndLicenseIds]) => ({
            features,
            addonFeatures,
            licenseIds: accessibleFeaturesAndLicenseIds.licenseIds,
          })),
          filter(({ features, addonFeatures }) => !this.blockCheckingLicense && features.mainFeature != null),
        )
        .subscribe(async ({ features, addonFeatures, licenseIds }) => {
          const mainFeature = features.mainFeature as LicenseFeature;
          const licensingConfig: ConnectionLicensingConfig = { feature: mainFeature, addonFeatures, licenseIds };

          try {
            await this.checkIsLicenseSessionsActive(licensingConfig);
            this.blockCheckingLicense = false;
          } catch (error: unknown) {
            this.ngZone.run(() => {
              this.onLdkServerError(
                error,
                licenseReloginUserAction({
                  auto: true,
                  licensingConfig,
                  isChangingModule: false,
                }),
                true,
              );
            });
          }
        });
    });
  }

  public async isLicenseValid(
    selectedFeature: LicenseFeature | null,
    licensingState: LicensingModuleState,
    isChangingModule = false,
  ): Promise<boolean> {
    const checkSessions = selectedFeature !== licensingState.mainFeature;
    try {
      const { licensingLogin } = licensingState;
      if (selectedFeature == null) {
        throw createLicensingError(LicensingErrorType.ERROR_LICENSE_FEATURE_NOT_FOUND);
      }
      if (!licensingLogin) {
        throw createLicensingError(LicensingErrorType.ERROR_LICENSE_LOGIN_NOT_FOUND);
      }
      if (licensingLogin.isLicensingDisabled) {
        return true;
      }

      const licensingConfig: ConnectionLicensingConfig = {
        feature: selectedFeature,
        addonFeatures: licensingState.selectedAddons,
        licenseIds: findAccessibleFeaturesAndLicenses(licensingState).licenseIds,
      };

      const featureLoginStatus = licensingLogin.loginInfos.find((li) => li.licenseFeature === selectedFeature);
      if (!featureLoginStatus) {
        const featureNotFoundError = createLicensingError(LicensingErrorType.ERROR_LOGGED_IN_LICENSE_FEATURE_NOT_FOUND);
        featureNotFoundError.data.licensingConfig = licensingConfig;
        throw featureNotFoundError;
      }

      if (featureLoginStatus.haspStatus !== HaspStatus.StatusOk) {
        throw createLicensingError(LicensingErrorType.ERROR_LICENSE_HASP, featureLoginStatus.loginStatusMessage);
      }

      if (checkSessions) {
        await this.checkIsLicenseSessionsActive(licensingConfig);
      }
      return true;
    } catch (error: unknown) {
      await this.onLdkServerError(
        error,
        licenseReloginUserAction({
          auto: true,
          licensingConfig: {
            feature: selectedFeature as LicenseFeature,
            addonFeatures: licensingState.selectedAddons,
            licenseIds: findAccessibleFeaturesAndLicenses(licensingState).licenseIds,
          },
          isChangingModule,
        }),
        true,
      );
      return false;
    } finally {
      this.blockCheckingLicense = false;
    }
  }

  public async onLdkServerError(error: unknown, sourceAction: Action, autoRelogin = false, keepFileOpen = false): Promise<void> {
    if (isLicensingError(error)) {
      const licenseErrorType = error.data.licenseErrorType;
      const licenseErrorText = getLicenseErrorTextFromError(error);

      if (autoRelogin && licenseErrorType === LicensingErrorType.ERROR_LICENSE_SESSION_EXPIRED) {
        if (error.data.licensingConfig == null) {
          throw new Error('License config is null!');
        }
        const isChangingModule = (sourceAction as any).isChangingModule === true ?? false;
        this.store.dispatch(
          licenseReloginUserAction({
            auto: true,
            licensingConfig: error.data.licensingConfig,
            isChangingModule,
          }),
        );
      } else if (autoRelogin && licenseErrorType === LicensingErrorType.ERROR_LOGGED_IN_LICENSE_FEATURE_NOT_FOUND) {
        if (error.data.licensingConfig == null) {
          throw new Error('License config is null!');
        }
        this.store.dispatch(licenseSwitchAction({ licensingConfig: error.data.licensingConfig }));
      } else if (keepFileOpen) {
        const result = await this.modalService.showConfirm(licenseErrorText, '', 'sm', 'Retry', 'Cancel');
        if (result) {
          this.store.dispatch(sourceAction);
        }
      } else {
        // show session expired error message
        this.modalService.dismissAll();
        const result = await this.modalService.showConfirm(licenseErrorText, '', 'sm', 'Retry', 'Cancel');
        if (result) {
          this.store.dispatch(sourceAction);
        } else {
          this.clearUndoHistoryAndGoHome();
        }
      }
    } else {
      // throw unhandled error
      this.clearUndoHistoryAndGoHome();
      throw error;
    }
  }

  private clearUndoHistoryAndGoHome(): void {
    this.store.dispatch(undoRedoClearHistoryAction());
    this.store.dispatch(dbDisconnectAction());
    FileManagerHelper.navigateHome(this.router).then();
  }
}
