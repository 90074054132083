import { DictionaryWithArray, IDictionaryWithArray } from '../../common/state.helpers';
import { Fluid } from './model/fluid';
import { ITableState } from '../../common/common-grid.interfaces';
import { Rheology } from './model/rheology/rheology';
import { Rheometer } from './model/rheometer/rheometer';
import { RheometerReading } from './model/rheometer-reading/rheometer-reading';
import { IModuleState, IValidatedModuleState } from '../../common/common-state.interfaces';
import { ValidatedSettings } from '../settings/dto/settingsDto';
import { ModuleType } from '../scenario/scenario.dto';

export interface FluidModuleState extends IModuleState {
  Fluids: IDictionaryWithArray<Fluid>;
  Rheologies: IDictionaryWithArray<ITableState<Rheology>>;
  Rheometers: IDictionaryWithArray<ITableState<Rheometer>>;
  RheometerReadings: IDictionaryWithArray<ITableState<RheometerReading>>;
  SelectedFluidId: number;
  SelectedTemperatureId: number;
}

export interface ValidatedFluidModuleState extends FluidModuleState, IValidatedModuleState<FluidModuleState> {
  isShearRateValid: boolean;
  validFluidIds: number[];
}

export const fluidsModuleInitialState: FluidModuleState = {
  Fluids: DictionaryWithArray.clear(),
  Rheologies: DictionaryWithArray.clear(),
  Rheometers: DictionaryWithArray.clear(),
  RheometerReadings: DictionaryWithArray.clear(),
  SelectedFluidId: -1,
  SelectedTemperatureId: -1,

  isLoaded: false,
};

export interface IFluidValidationDependencies {
  settings: ValidatedSettings;
  currentModuleType: ModuleType;
}
