import { PumpingScheduleDto } from '../../dto/pumping-schedule.dto';
import { PumpingSchedule } from './pumping-schedule';
import { createTableRow, ITableRow } from '../../../../common/common-grid.interfaces';
import { RangeConstants } from '../../../../dto/range.dto';

export class PumpingScheduleFactory {
  public static createRowListFromDtos(
    pumpingSchedulesDtos: PumpingScheduleDto[],
    scenarioId: number,
    createInsertRow: boolean,
  ): ITableRow<PumpingSchedule>[] {
    const rows = pumpingSchedulesDtos.map((dto, index) => this.createElement(dto, index));
    if (!createInsertRow) {
      return rows;
    }
    const insertRow = this.createEmptyInsertRow(scenarioId, rows.length);
    return [...rows, insertRow];
  }

  public static createElement(pumpingScheduleDto: PumpingScheduleDto, rowIndex: number): ITableRow<PumpingSchedule> {
    return createTableRow<PumpingSchedule>(this.createPumpingSchedule(pumpingScheduleDto), 'data', rowIndex);
  }

  public static createPumpingSchedule(pumpingScheduleDto: PumpingScheduleDto): PumpingSchedule {
    return {
      ...pumpingScheduleDto,
      ShutDownTime: 0,
      CumulativeVolume: 0,
      StageTime: 0,
      CumulativeTime: 0,
      StageGravelMass: 0,
      StageGravelRate: 0,
    };
  }

  public static createEmptyPumpingScheduleDto(scenarioId: number): PumpingScheduleDto {
    return {
      ScenarioId: scenarioId,
      Id: -1,
      PumpRate: 0,
      StageVolume: 0,
      GravelConcentration: 0,
      GravelId: null,
      FluidId: null,
      ReturnRate: 0,
      Name: '',
      SortOrder: 0,
      RangeId: RangeConstants.EmptyRangeId,
      ChokePressure: 0,
    };
  }

  public static createEmpty(scenarioId: number): PumpingSchedule {
    return {
      ...this.createEmptyPumpingScheduleDto(scenarioId),
      StageGravelMass: 0,
      StageGravelRate: 0,
      ShutDownTime: 0,
      StageTime: 0,
      CumulativeTime: 0,
      CumulativeVolume: 0,
    };
  }

  public static createEmptyInsertRow(scenarioId: number, rowIndex: number): ITableRow<PumpingSchedule> {
    return createTableRow(this.createEmpty(scenarioId), 'insert-row', rowIndex);
  }

  public static toDto(pumpingSchedule: PumpingSchedule, defaultFluidId?: number): PumpingScheduleDto {
    const { ShutDownTime, CumulativeVolume, StageTime, CumulativeTime, StageGravelMass, StageGravelRate, ...pumpingScheduleDto } =
      pumpingSchedule;
    if (pumpingScheduleDto.GravelId === null) {
      pumpingScheduleDto.GravelId = 0;
    }
    if (defaultFluidId !== undefined && pumpingScheduleDto.FluidId === null) {
      pumpingScheduleDto.FluidId = defaultFluidId;
    }
    return pumpingScheduleDto;
  }
}
