import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { DictionaryWithArray, IDictionaryWithArray, isDefined } from '@dunefront/common/common/state.helpers';
import { createTableState, ITableRow, ITableState } from '@dunefront/common/common/common-grid.interfaces';
import { selectCurrentUnitSystem } from '../units/units.selectors';
import { TemperatureConverter } from '@dunefront/common/unit-converters/converters/temperature/temperature.converter';
import { RheometerConstants, RheometerTestType } from '@dunefront/common/modules/fluid/dto/rheometer.dto';
import { Validation } from '@dunefront/common/common/validation';
import { FluidModuleState } from '@dunefront/common/modules/fluid/fluid-module.state';
import { ISelectItem, toSelectItem } from '@dunefront/common/common/select.helpers';
import { FluidValidation } from '@dunefront/common/modules/fluid/model/fluid.validation';
import { Fluid } from '@dunefront/common/modules/fluid/model/fluid';
import { Rheology } from '@dunefront/common/modules/fluid/model/rheology/rheology';
import { Rheometer } from '@dunefront/common/modules/fluid/model/rheometer/rheometer';
import { RheometerReading } from '@dunefront/common/modules/fluid/model/rheometer-reading/rheometer-reading';
import { getValidatedSettings } from '../settings/validated-settings.selectors';
import { selectRouteParam } from '../router/router.selectors';
import { calculateRheologyFluidLine, calculateRheologyFluidShuntTargetLine } from './fluid.calculations';
import { getCurrentAppModuleType } from '../ui/ui.selectors';

export const getFluidModuleState = createFeatureSelector<FluidModuleState>('fluid');

export const getValidatedFluidModuleState = createSelector(
  getFluidModuleState,
  getValidatedSettings,
  getCurrentAppModuleType,
  (...[state, settings, currentModuleType]) => FluidValidation.validate(state, { settings, currentModuleType }),
);

const getFluidIds = createSelector(getFluidModuleState, (state) => state.Fluids.ids);
export const getFluidsArray = createSelector(getFluidModuleState, (state) => Object.values(state.Fluids.dict).filter(isDefined));

export const getFluidIdFromUrl = createSelector(selectRouteParam('fluidId'), (fluidId) => Number(fluidId ?? 0));
export const getSelectedFluidIdFromState = createSelector(getFluidModuleState, (state) => state.SelectedFluidId);

export const getSelectedFluidId = createSelector(
  getFluidIdFromUrl,
  getSelectedFluidIdFromState,
  getFluidIds,
  (fluidIdFromUrl, fluidIdFromState, fluidIds) => {
    const fluidId = fluidIdFromUrl > 0 ? fluidIdFromUrl : fluidIdFromState;
    if (!fluidIds.includes(fluidId + '')) {
      return Number(fluidIds[0]);
    } else {
      return fluidId;
    }
  },
);

export const getValidatedFluids = createSelector(getValidatedFluidModuleState, (state): IDictionaryWithArray<Fluid> => state.Fluids);

export const getSelectedFluid = createSelector(
  getValidatedFluidModuleState,
  getSelectedFluidId,
  (state, fluidId): Fluid | undefined => state.Fluids.dict[fluidId],
);

export const getFluidById = (fluidId: number): MemoizedSelector<any, Fluid | undefined> =>
  createSelector(getValidatedFluidModuleState, (fluids) => fluids.Fluids.dict[fluidId]);

export const getSelectedFluidState = createSelector(
  getValidatedFluidModuleState,
  getSelectedFluidId,
  (state, fluidId): ISelectedFluidState | undefined => {
    if (fluidId === 0) {
      return;
    }
    const fluid = DictionaryWithArray.get(state.Fluids, fluidId);

    if (!fluid) {
      return;
    }

    const rheologies = DictionaryWithArray.get(state.Rheologies, fluidId);
    if (!rheologies) {
      console.error('Fluid Rheologies not found ' + fluidId);
      return;
    }

    const rheometers = DictionaryWithArray.get(state.Rheometers, fluidId);
    if (!rheometers) {
      console.error('Fluid Rheometers not found ' + fluidId);
      return;
    }

    const rheometerReadings = state.RheometerReadings;

    return { fluid, rheologies, rheometers, rheometerReadings };
  },
);

export const getFluidsSelectData = createSelector(
  getValidatedFluidModuleState,
  getSelectedFluidId,
  (state: FluidModuleState, fluidId): IFluidSelectorData => ({
    items: DictionaryWithArray.getArray(state.Fluids)
      .sort((a, b) => a.Id - b.Id)
      .map((fluid) => toSelectItem(fluid.Id, fluid.Name, undefined, !fluid.isValid)),
    selectedFluid: state.Fluids.dict[fluidId],
  }),
);

export const getTemperaturesSelectData = createSelector(
  getValidatedFluidModuleState,
  selectCurrentUnitSystem,
  getSelectedFluidId,
  (fluidState, currentUnitState, fluidId): ITemperaturesSelect | undefined => {
    const rheometers = DictionaryWithArray.get(fluidState.Rheometers, fluidId);
    if (!rheometers) {
      return undefined;
    }
    return {
      items: rheometers.rows.map((row) => ({
        value: row.rowData.Id,
        text: TemperatureConverter.fromSi(row.rowData.Temperature, currentUnitState.Temperature).toString(),
        isInvalid: !row.isValid,
      })),
      label: TemperatureConverter.getSymbol(currentUnitState.Temperature),
      maxTemp: Math.max(...rheometers.rows.map((r) => r.rowData.Temperature)),
    };
  },
);

export const getIsFluidStateLoaded = createSelector(getFluidModuleState, (state) => state.isLoaded);

export const getLastFluidId = createSelector(getFluidModuleState, (state) => Math.max(...state.Fluids.ids.map((id) => +id)));

export const getFluidNames = createSelector(getFluidsSelectData, (state) => state.items.map((item) => item.text));

export const getFluidsCount = createSelector(getFluidModuleState, (state) => state.Fluids.ids.length);

export const getRheologies = createSelector(getSelectedFluidState, (state) => state?.rheologies ?? createTableState<Rheology>([]));

export const getTemperatureFromUrl = createSelector(selectRouteParam('temperatureId'), (tempId) => Number(tempId ?? 0));
export const getSelectedTemperatureFromState = createSelector(getFluidModuleState, (state) => state.SelectedTemperatureId);

export const getFluidTemperatureIds = createSelector(getSelectedFluidState, (state) =>
  state == null ? [] : state.rheometers.rows.map((row) => row.rowData.Id).filter((rowId) => rowId > 0),
);

export const getSelectedTemperatureId = createSelector(
  getTemperatureFromUrl,
  getSelectedTemperatureFromState,
  getFluidTemperatureIds,
  (tempIdFromUrl, tempIdFromState, fluidTemperatureIds) => {
    const tempId = tempIdFromUrl > 0 ? tempIdFromUrl : tempIdFromState;
    if (fluidTemperatureIds.length === 0) {
      return -1;
    }
    if (!fluidTemperatureIds.includes(tempId)) {
      return Number(fluidTemperatureIds[0]);
    } else {
      return tempId;
    }
  },
);

export const getSelectedFluidData = createSelector(
  getSelectedFluidState,
  getValidatedFluidModuleState,
  getSelectedTemperatureId,
  (...[selectedFluidState, state, selectedTemperatureId]): ISelectedFluidData | undefined => {
    if (!selectedFluidState) {
      return;
    }
    const { fluid, rheologies, rheometers, rheometerReadings } = selectedFluidState;

    const rheometer =
      selectedTemperatureId >= 0 ? rheometers.rows.find((row) => row.rowData.Id === selectedTemperatureId) : rheometers.rows[0];

    if (!rheometer) {
      console.error('Rheometer not found ' + selectedTemperatureId);
      return;
    }

    let rheometerReading = DictionaryWithArray.get(rheometerReadings, rheometer.rowData.Id);
    if (!rheometerReading) {
      console.error('Rheometer Readings not found ' + rheometer.rowData.Id);
      rheometerReading = createTableState([]);
    }

    return {
      fluid,
      rheologies,
      rheometer,
      rheometerReading,
      isRheometerStateValid: rheometers.isValid,
      isShearRateValid: state.isShearRateValid,
      selectedTemperatureId: selectedTemperatureId,
    };
  },
);

export const getCurrentRheology = createSelector(
  getValidatedFluidModuleState,
  getSelectedFluidId,
  getSelectedTemperatureId,
  (state, fluidId, tempId): Rheology | null => {
    const rheometer = DictionaryWithArray.get(state.Rheometers, fluidId)?.rows.find((rh) => rh.rowData.Id === tempId);
    if (!rheometer) {
      return null;
    }
    const rheology = DictionaryWithArray.get(state.Rheologies, fluidId)?.rows.find(
      (rh) => rh.rowData.Temperature === rheometer.rowData.Temperature,
    )?.rowData;

    return rheology ?? null;
  },
);

export const getRheometerChartData = createSelector(
  getValidatedFluidModuleState,
  getSelectedFluidId,
  getSelectedTemperatureId,
  (state, fluidId, tempId) => {
    const fluid = DictionaryWithArray.get(state.Fluids, fluidId);
    const rheometer = DictionaryWithArray.get(state.Rheometers, fluidId)?.rows.find((rh) => rh.rowData.Id === tempId);
    const rheology = DictionaryWithArray.get(state.Rheologies, fluidId)?.rows.find(
      (rh) => rh.rowData.Temperature === rheometer?.rowData.Temperature,
    );
    const rheometerReadings = DictionaryWithArray.get(state.RheometerReadings, rheometer?.rowData.Id ?? 0)?.rows.filter(
      (rhr) => rhr.rowType === 'data',
    );

    const rheometerChartSelectorData: IRheometerChartSelectorData = {
      rheometerReadings: [],
      fluidLine: [],
      targetLine: [],
    };

    if (!fluid || !rheology || !rheometer || !rheometerReadings) {
      return { rheometerReadings: [], fluidLine: [], targetLine: [] };
    }

    if (fluid.RheometerTestType === RheometerTestType.Shear_Rate_Viscosity) {
      for (const rr of rheometerReadings.filter((reading) => reading.rowData.ShearRate > 0 && reading.rowData.Viscosity > 0)) {
        rheometerChartSelectorData.rheometerReadings.push({
          shearRate: rr.rowData.ShearRate,
          viscosity: Validation.UICheckNumberValidity(rr.rowData.Viscosity),
        });
      }
    } else {
      const shearRateConstant = RheometerConstants.GetShearRateConstant(rheometer.rowData.RotorBobCombination);
      const springFactor = RheometerConstants.GetTorsionSpringFactor(rheometer.rowData.TorsionSpringAssembly);
      const rotorBobFactor = RheometerConstants.GetRotorBobFactor(rheometer.rowData.RotorBobCombination);

      for (const rr of rheometerReadings.filter((reading) => reading.rowData.DialReading > 0 && reading.rowData.RPM > 0)) {
        const speedFactor = 300 / rr.rowData.RPM;
        const rrShearRate = rr.rowData.RPM * shearRateConstant;
        const rrViscosity = Validation.UICheckNumberValidity((rr.rowData.DialReading * speedFactor * springFactor * rotorBobFactor) / 1000);
        rheometerChartSelectorData.rheometerReadings.push({
          shearRate: rrShearRate,
          viscosity: rrViscosity,
        });
      }
    }

    if (rheometerChartSelectorData.rheometerReadings.length < 2) {
      return { rheometerReadings: [], fluidLine: [], targetLine: [] };
    }

    rheometerChartSelectorData.fluidLine = calculateRheologyFluidLine(rheology.rowData, rheometerChartSelectorData.rheometerReadings);

    if (rheometer.rowData.IsShuntRefCurveShown) {
      rheometerChartSelectorData.targetLine = calculateRheologyFluidShuntTargetLine(rheometerChartSelectorData.rheometerReadings);
    }

    return rheometerChartSelectorData;
  },
);

export const isCurrentFluidValid = createSelector(
  getSelectedFluidState,
  (state) => state?.fluid.isValid && state.rheologies.isValid && state.rheometers.isValid,
);

export const getFluidAndRheologies = createSelector(getValidatedFluidModuleState, (state: FluidModuleState) => {
  return {
    fluid: state.Fluids,
    rheologies: state.Rheologies,
  };
});

export const getFluidStateIsValid = createSelector(getValidatedFluidModuleState, (state) => state.isValid);

export interface ISelectedFluidState {
  fluid: Fluid;
  rheologies: ITableState<Rheology>;
  rheometers: ITableState<Rheometer>;
  rheometerReadings: IDictionaryWithArray<ITableState<RheometerReading>>;
}

export interface ISelectedFluidData {
  fluid: Fluid;
  rheologies: ITableState<Rheology>;
  rheometer: ITableRow<Rheometer>;
  rheometerReading: ITableState<RheometerReading>;
  isShearRateValid: boolean;
  isRheometerStateValid: boolean;
  selectedTemperatureId: number | undefined;
}

export interface IFluidSelectorData {
  items: ISelectItem<number>[];
  selectedFluid?: Fluid;
}

export interface IRheometerChartSelectorData {
  rheometerReadings: IRheometerChartPoint[];
  fluidLine: IRheometerChartPoint[];
  targetLine: IRheometerChartPoint[];
}

export interface IRheometerChartPoint {
  shearRate: number;
  viscosity: number;
}

export interface ITemperaturesSelect {
  items: ISelectItem<number>[];
  label: string;
  maxTemp: number;
}
