<form>
  <label>Equation:</label>
  <app-input
    [errorMessage]="(validityState$ | async)?.equation?.error?.equationFormula ?? ''"
    [maxWidth]="500"
    [align]="'left'"
    [triggerOnKeyPress]="true"
    [value]="$any(equation$ | async)"
    [isTextInput]="true"
    [dataCy]="'equation-formula-input'"
    (primitiveValueChanged)="onEquationChanged($event.value)"
  ></app-input>
</form>
