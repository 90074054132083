import { Injectable } from '@angular/core';
import { BaseWsEffects } from '../base-ws.effects';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { BackendConnectionService } from '../../shared/backend-connection/backend-connection.service';
import { ModalService } from '../../common-modules/modals/modal.service';
import { Router } from '@angular/router';
import { LoadSummaryAction, LoadSummaryActionResponse, SummaryModuleName } from '@dunefront/common/modules/summary/summary-module.actions';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import { dataFailed } from '../app.actions';
import { of } from 'rxjs';
import { loadSummaryModuleAction, loadSummaryModuleSuccessAction } from './summary.actions';
import { getCurrentScenarioId } from '../scenario/scenario.selectors';
import { getCurrentRangeId } from '../range/range.selectors';
import { RangeConstants } from '@dunefront/common/dto/range.dto';
import { getAreCurrentResultsPresentAndCompleted } from '../calculation-engine/calculation-engine-results.selectors';
import { getCurrentAppModuleType } from '../ui/ui.selectors';

@Injectable()
export class SummaryEffects extends BaseWsEffects {
  constructor(
    actions$: Actions,
    store: Store,
    wsService: BackendConnectionService,
    modalService: ModalService,
    protected router: Router,
  ) {
    super(actions$, wsService, SummaryModuleName, false, true, modalService, store);
  }

  public loadSummaryModule$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSummaryModuleAction),
      concatLatestFrom(() => this.store.select(getAreCurrentResultsPresentAndCompleted)),
      filter(([, areResultsPresent]) => areResultsPresent),
      concatLatestFrom(() => [
        this.store.select(getCurrentAppModuleType),
        this.store.select(getCurrentScenarioId),
        this.store.select(getCurrentRangeId),
      ]),
      mergeMap(([, moduleType, scenarioId, rangeId]) =>
        this.emit<LoadSummaryActionResponse>(
          new LoadSummaryAction({ scenarioId, rangeId: rangeId ?? RangeConstants.EmptyRangeId, moduleType }),
        ).pipe(
          map((result) => loadSummaryModuleSuccessAction(result)),
          catchError((err) => of(dataFailed(err))),
        ),
      ),
    ),
  );
}
