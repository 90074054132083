import { createAction, props } from '@ngrx/store';
import { CalcEngineShortWorkerErrorType } from '@dunefront/common/modules/error-testing/error-testing-module.actions';

export const triggerFrontendError = createAction('[error-testing] createFrontendError', props<{ errorMessage: string }>());
export const triggerBackendError = createAction('[error-testing] triggerBackendError', props<{ errorMessage: string }>());
export const triggerPing = createAction('[error-testing] triggerPing', props<{ inputData: string }>());
export const triggerCalcEngineShortWorkerError = createAction(
  '[error-testing] triggerCalcEngineShortWorkerError',
  props<{ ignoreSentry: boolean; errorType: CalcEngineShortWorkerErrorType }>(),
);
export const triggerCalcEngineLongWorkerError = createAction(
  '[error-testing] triggerCalcEngineLongWorkerError',
  props<{ jobId: string; ignoreSentry: boolean }>(),
);
export const freezeBackend = createAction('[error-testing] freezeBackend');
