import { DeleteRowsWsAction, InsertRowsWsAction, ReloadRowsWsAction, UpdateRowsWsAction, WsAction } from '../../ws.action';
import { CompletionDto } from './dto/completion.dto';
import { LowerCompletionPipeDto } from '../../dto/lower-completion-pipe.dto';
import { RunningStringPipeDto } from '../../dto/running-string-pipe.dto';
import { ShuntTubeDto } from '../../dto/shunt-tube.dto';
import { IcdPortDataDto } from '../../dto/icd-port-data.dto';
import { SectionCalculatorJobInputData } from './dto/section-calculator.dto';
import { WsActionPropsFactory } from '../../common/ws-action/ws-action-props.factory';
import { LowerCompletionPipesFactory } from '../pipes/lower-completion-pipes/lower-completion-pipes.factory';
import { Pipe } from '../pipes/pipe';
import { RunningStringPipesFactory } from '../pipes/running-string-pipes/running-string-pipes.factory';
import { IUpdateTableRowsProps } from '../../common/common-store-crud.interfaces';

export const CompletionModuleName = 'CompletionModule';

export enum CompletionModuleActionTypes {
  UpdateCompletion = '[CompletionModule] [Completion] Update',
  UpdateShuntTube = '[CompletionModule] [ShuntTube] Update',

  LowerCompletionInsertRows = '[CompletionModule] [LowerCompletion] InsertRows',
  LowerCompletionUpdateRow = '[CompletionModule] [LowerCompletion] UpdateRow',
  LowerCompletionDeleteRows = '[CompletionModule] [LowerCompletion] DeleteRows',

  IcdPortDataInsertRows = '[CompletionModule] [IcdPortData] InsertRows',
  IcdPortDataUpdateRow = '[CompletionModule] [IcdPortData] UpdateRow',
  IcdPortDataDeleteRows = '[CompletionModule] [IcdPortData] DeleteRows',

  RunningStringInsertRows = '[CompletionModule] [RunningString] InsertRows',
  RunningStringUpdateRow = '[CompletionModule] [RunningString] UpdateRow',
  RunningStringDeleteRows = '[CompletionModule] [RunningString] DeleteRows',
  RunningStringReloadRows = '[CompletionModule] [RunningString] ReloadRows',
  CalculateSection = '[CompletionModule] CalculateSection Update',
}

export interface LoadCompletionActionResponse {
  completionDto: CompletionDto;
  shuntTubeDto: ShuntTubeDto;
  lowerCompletionDtos: LowerCompletionPipeDto[];
  icdPortDataDtos: IcdPortDataDto[];
  runningStringDtos: RunningStringPipeDto[];
}

// region Lower completion

export class LowerCompletionInsertRowsAction extends InsertRowsWsAction<LowerCompletionPipeDto> {
  public override readonly type = CompletionModuleActionTypes.LowerCompletionInsertRows;
}

export class LowerCompletionUpdateRowAction extends UpdateRowsWsAction<LowerCompletionPipeDto> {
  public override readonly type = CompletionModuleActionTypes.LowerCompletionUpdateRow;

  constructor(action: IUpdateTableRowsProps<Pipe>) {
    super(WsActionPropsFactory.updateAction(action, LowerCompletionPipesFactory.toDto));
  }
}

export class LowerCompletionDeleteRowsAction extends DeleteRowsWsAction {
  public override readonly type = CompletionModuleActionTypes.LowerCompletionDeleteRows;
}

// endregion

// region icd port data

export class IcdPortDataInsertRowsAction extends InsertRowsWsAction<IcdPortDataDto> {
  public override readonly type = CompletionModuleActionTypes.IcdPortDataInsertRows;
}

export class IcdPortDataUpdateRowAction extends UpdateRowsWsAction<IcdPortDataDto> {
  public override readonly type = CompletionModuleActionTypes.IcdPortDataUpdateRow;

  constructor(action: IUpdateTableRowsProps<IcdPortDataDto>) {
    super(WsActionPropsFactory.updateAction(action, (row) => row));
  }
}

export class IcdPortDataDeleteRowsAction extends DeleteRowsWsAction {
  public override readonly type = CompletionModuleActionTypes.IcdPortDataDeleteRows;
}

// endregion

// region Running string

export class RunningStringInsertRowsAction extends InsertRowsWsAction<RunningStringPipeDto> {
  public override readonly type = CompletionModuleActionTypes.RunningStringInsertRows;
}

export class RunningStringUpdateRowAction extends UpdateRowsWsAction<RunningStringPipeDto> {
  public override readonly type = CompletionModuleActionTypes.RunningStringUpdateRow;
  constructor(action: IUpdateTableRowsProps<Pipe>) {
    super(WsActionPropsFactory.updateAction(action, RunningStringPipesFactory.toDto));
  }
}

export class RunningStringDeleteRowsAction extends DeleteRowsWsAction {
  public override readonly type = CompletionModuleActionTypes.RunningStringDeleteRows;
}

export class RunningStringReloadRowsAction extends ReloadRowsWsAction {
  public override readonly type = CompletionModuleActionTypes.RunningStringReloadRows;
}

// endregion

// region Shunt Tube

export class UpdateShuntTubeAction extends UpdateRowsWsAction<ShuntTubeDto> {
  public override readonly type = CompletionModuleActionTypes.UpdateShuntTube;
}

// endregion

// region Completion

export class UpdateCompletionAction extends UpdateRowsWsAction<CompletionDto> {
  public override readonly type = CompletionModuleActionTypes.UpdateCompletion;
}

export class CalculateSectionAction extends WsAction {
  public override readonly type = CompletionModuleActionTypes.CalculateSection;

  constructor(
    public override payload: SectionCalculatorJobInputData,
    public scenarioId: number,
    public calculationType: SectionCalculationType,
  ) {
    super();
  }
}

export type SectionCalculationType = 'single' | 'multiple';

// endregion

export type CompletionModuleActions =
  | LowerCompletionInsertRowsAction
  | LowerCompletionUpdateRowAction
  | LowerCompletionDeleteRowsAction
  | IcdPortDataInsertRowsAction
  | IcdPortDataUpdateRowAction
  | IcdPortDataDeleteRowsAction
  | RunningStringInsertRowsAction
  | RunningStringUpdateRowAction
  | RunningStringDeleteRowsAction
  | RunningStringReloadRowsAction
  | UpdateCompletionAction
  | UpdateShuntTubeAction
  | CalculateSectionAction;
