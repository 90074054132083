import { ChartContext } from './chart-types';
import { Chart } from 'chart.js';
import { getAxisId, getAxisUnit, getChartAxisFromAxisId, getFirstAvailableValueScale } from './chart-misc-helpers';
import { ChartAxis } from '@dunefront/common/modules/reporting/dto/chart-axis-property.dto';
import { IAxisUnit } from '@dunefront/common/unit-converters/converter.interfaces';
import { UnitConverterHelper } from '@dunefront/common/unit-converters/unit.converter.helper';

export interface ConverterScales {
  xScaleID: string;
  yScaleID: string;
}

export class ChartConversionsHelper {
  private constructor(
    public readonly chart: Chart,
    public readonly context: ChartContext,
    public readonly xScaleID: string,
    public readonly yScaleID: string,
    public readonly xAxisUnit: IAxisUnit,
    public readonly yAxisUnit: IAxisUnit,
  ) {}

  private static getDefaultScales(chart: Chart, context: ChartContext): ConverterScales | undefined {
    const valueScaleId = getFirstAvailableValueScale(chart, context.isRotated)?.id;
    if (valueScaleId == null) {
      return undefined;
    }

    const valueAxis = getChartAxisFromAxisId(valueScaleId);
    const valueAxisUnit = getAxisUnit(valueAxis, context.axesDefaults);
    if (valueAxisUnit == null) {
      return undefined;
    }

    const argumentScaleId = getAxisId(ChartAxis.Argument, context.isRotated);

    const xScaleID = context.isRotated ? valueScaleId : argumentScaleId;
    const yScaleID = context.isRotated ? argumentScaleId : valueScaleId;

    return { xScaleID, yScaleID };
  }

  public static getConverter(chart: Chart, context: ChartContext, scales?: ConverterScales): ChartConversionsHelper | undefined {
    scales ??= this.getDefaultScales(chart, context);
    if (scales == null) {
      return undefined;
    }

    const { xScaleID, yScaleID } = scales;

    const xAxis = getChartAxisFromAxisId(xScaleID);
    const yAxis = getChartAxisFromAxisId(yScaleID);

    const xAxisUnit = getAxisUnit(xAxis, context.axesDefaults);
    const yAxisUnit = getAxisUnit(yAxis, context.axesDefaults);

    if (xAxisUnit == null || yAxisUnit == null) {
      return undefined;
    }

    return new ChartConversionsHelper(chart, context, xScaleID, yScaleID, xAxisUnit, yAxisUnit);
  }

  public convertPointFromSiToAxisValues(argument: number, value: number): { xAxisValue: number; yAxisValue: number } {
    return {
      xAxisValue: UnitConverterHelper.convertFromSi(
        this.xAxisUnit.unitSystem,
        this.context.currentUnitSystem,
        this.context.isRotated ? value : argument,
      ),
      yAxisValue: UnitConverterHelper.convertFromSi(
        this.yAxisUnit.unitSystem,
        this.context.currentUnitSystem,
        this.context.isRotated ? argument : value,
      ),
    };
  }

  public convertFromAxisValuesToSi(xAxisValue: number, yAxisValue: number): { argument: number; value: number } {
    const xAxisValueSi = UnitConverterHelper.convertToSi(this.xAxisUnit.unitSystem, this.context.currentUnitSystem, xAxisValue);
    const yAxisValueSi = UnitConverterHelper.convertToSi(this.yAxisUnit.unitSystem, this.context.currentUnitSystem, yAxisValue);

    const argument = this.context.isRotated ? yAxisValueSi : xAxisValueSi;
    const value = this.context.isRotated ? xAxisValueSi : yAxisValueSi;

    return { argument, value };
  }
}
