<div class="subhead-bar" [ngSwitch]="repository" *ngIf="!isRecentMode">
  <span *ngSwitchCase="'personal'">Personal Projects</span>
  <span *ngSwitchCase="'shared'">Shared Projects</span>
  <span *ngSwitchCase="'demo'">Demo Projects</span>
  <div *ngSwitchCase="'trash'" class="trash-subheader">
    <span>Trash</span>
    <div class="small-button-panel">
      <app-button
        textIcon="icon-bin-trash-remove-cross"
        class="btn btn-primary btn-sm btn-cancel"
        text="Empty trash"
        [isUiLockable]="false"
        (buttonClick)="onEmptyTrash()"
      ></app-button>
    </div>
  </div>
  <span *ngSwitchCase="'backup'">Backup</span>
</div>

<div class="subhead-bar" *ngIf="isRecentMode">
  <span>Recently viewed projects</span>
</div>

<div class="breadcrumb-row" *ngIf="!isRecentMode && folder && (repository === 'personal' || repository === 'shared')">
  <div class="breadcrumb-row-item" (click)="folderClick(-1)">/{{ user ? user.uid : repository }}</div>
  <div class="breadcrumb-row-item" *ngFor="let folderName of folder?.Folder; let i = index" (click)="folderClick(i)">
    /{{ folderName }}</div>
  <div class="breadcrumb-row-item" *ngIf="folder.Name !== ''" (click)="folderClick()">/{{ folder.Name }}</div>
</div>

<div class="expanding-row" data-cy="expanding-row" *ngIf="files" appClickOutside
     (rightClickOutside)="resetContextMenu()">
  <cdk-virtual-scroll-viewport itemSize="30px" class="file-list" (scroll)="resetContextMenu()">
    <div
      *cdkVirtualFor="let file of files; trackBy: trackByFn"
      class="file-list-item"
      (click)="openFile(file)"
      (contextmenu)="onContextMenu(file, $event)"
      [attr.data-cy]="file.Name.toLowerCase().replace(removeSpaceRegex, '-')"
      [class.disableHover]="isAdmin && file.FileType !== 'folder'"
    >
      <div class="filename">
        <span class="{{ getItemIconName(file) }} file-list-icon"></span>
        <div>
          <span data-cy="file-name">{{ file | fileDisplayName }}</span>
          <span class="file-size" *ngIf="!isRecentMode"> - ( {{ file.FileSize | fileSize }} )</span>
          <div class="file-location" data-cy="file-location" *ngIf="isRecentMode">
            {{ file | fileLocation }}
          </div>
        </div>
      </div>
      <div class="trash-folder" *ngIf="repository === 'trash'">/{{ file.Folder.join('/') }}</div>
      <div class="trash-date" *ngIf="repository === 'trash'">{{ file.TrashTimestamp | date: 'medium' }}</div>

      <ng-container *ngIf="!isReadOnly(file)">
        <div class="file-item-date" *ngIf="file.LastOpenTimestamp">
          {{ file.LastOpenTimestamp | date: 'medium' }}
        </div>

        <div class="file-item-date" *ngIf="repository === 'backup' && file.LastChangedTime">
          {{ file.LastChangedTime | date: 'medium' }}
        </div>

        <div *ngIf="showRowContextMenu" class="file-button">
          <button class="context-menu-button" (click)="contextMenuClick(file, $event)">&#8942;</button>
        </div>
      </ng-container>

      <ng-container *ngIf="isReadOnly(file)">
        <div class="file-item-date">
          {{ getReadOnlyFileDescription(file) }}
        </div>

        <div *ngIf="showRowContextMenu" class="file-button">
          <button class="context-menu-button disabled">&#8942;</button>
        </div>
      </ng-container>


    </div>
  </cdk-virtual-scroll-viewport>
  <app-file-context-menu
    [(contextMenuRequired)]="contextMenuRequired"
    [isRecentMode]="isRecentMode"
    (openFile)="openFile($event)"
    data-cy="file-context-menu"
  ></app-file-context-menu>
</div>
