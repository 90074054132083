import { GlobalOptionsDto, ValidatedGlobalOptions } from '@dunefront/common/dto/global-options.dto';
import { IError } from '@dunefront/common/common/common-state.interfaces';
import { noErrors } from '@dunefront/common/common/state.helpers';

export class GlobalOptionsValidation {
  public static validate(options: GlobalOptionsDto): ValidatedGlobalOptions {
    const error: IError<Partial<GlobalOptionsDto>> = {
      MaxChartPoints: this.validateMaxChartPoints(options),
      EngineRealTimeUpdateInterval: this.validateEngineRealTimeUpdateInterval(options),
      ChartSliderUpdateInterval: this.validateChartSliderUpdateInterval(options),
    };

    return { ...options, error, isValid: noErrors(error) };
  }

  private static validateMaxChartPoints(options: GlobalOptionsDto): string {
    if (options.MaxChartPoints < 1000) {
      return 'Max Chart Points must be greater than or equal to 1000';
    }
    return '';
  }

  private static validateEngineRealTimeUpdateInterval(options: GlobalOptionsDto): string {
    if (options.EngineRealTimeUpdateInterval > 10 || options.EngineRealTimeUpdateInterval < 0.1) {
      return 'Engine Update Interval must be between 0.1 and 10';
    }
    return '';
  }

  private static validateChartSliderUpdateInterval(options: GlobalOptionsDto): string {
    if (options.ChartSliderUpdateInterval <= 0 || options.ChartSliderUpdateInterval > 1) {
      return 'Chart Slider Update Interval must be between 0 and 1';
    }

    return '';
  }
}
