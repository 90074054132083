<app-page-panel header="Chart Annotation" [allowFullScreen]="false"
                (click)="onHelpChange('settings', 'chart-annotation')">
  <div style="flex-direction: column" class="p-1">
    <app-radio-group
      [items]="[
        { value: false, text: 'Default' },
        { value: true, text: 'Custom' }
      ]"
      [source]="globalOptions"
      [key]="'IsOverrideAnnotationStyle'"
      id="IsOverrideAnnotationStyle"
      dataCy="is-override-annotation-style-radio"
      [isUiLockable]="false"
      (valueChanged)="onValueChange($event)"
      class="grid-two-one"
      layout="horizontal"
    >
    </app-radio-group>
    <app-chart-annotation-details
      class="block m-top-1"
      [disabled]="!globalOptions.IsOverrideAnnotationStyle"
      [annotationStyle]="globalOptions"
      [defaultAnnotationStyle]="defaultGlobalOptions"
      (valueChanged)="onValueChange($event)"
    >
    </app-chart-annotation-details>
  </div>
</app-page-panel>
