import { createReducer, on } from '@ngrx/store';
import { loadScenarioDataSuccessAction, updateRowSuccess } from '../../app.actions';
import { MASPCalculatorModuleReducerHelper } from './masp-calculator.reducer.helper';
import {
  MASPCalculatorModuleInitialState,
  MASPCalculatorModuleState,
} from '@dunefront/common/modules/calculators/masp-calculator/masp-calculator-module.state';
import { removeDataResultsFromStoreIfNeeded } from '../../data-storage/data-storage.actions';

export const MASPCalculatorModuleFeatureName = 'maspCalculator';

export const maspCalculatorModuleReducer = createReducer<MASPCalculatorModuleState>(
  MASPCalculatorModuleInitialState,
  on(loadScenarioDataSuccessAction, (state, action) =>
    MASPCalculatorModuleReducerHelper.onLoadScenarioDataSuccessAction(state, action.loadScenarioResponse.MASPCalculatorModule),
  ),
  on(updateRowSuccess, (state, action) => MASPCalculatorModuleReducerHelper.onUpdateMASPCalculatorSuccess(state, action)),
  on(
    removeDataResultsFromStoreIfNeeded,
    (state, action): MASPCalculatorModuleState =>
      MASPCalculatorModuleReducerHelper.onRemoveDataResultsFromStore(state, action.deleteResultsFilter),
  ),
);
