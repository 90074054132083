import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImportDataComponent } from './import-data.component';
import { RouterModule } from '@angular/router';
import { FilePreviewComponent } from './file-preview/file-preview.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatTableModule } from '@angular/material/table';
import { TableVirtualScrollModule } from 'ng-table-virtual-scroll';
import { PagePanelModule } from '../../page-panel/page-panel.component';
import { RadioGroupModule } from '../../../shared/components/form-components/radio-group/radio-group.component';
import { FilePropertiesComponent } from './file-preview/file-properties/file-properties.component';
import { FormInputModule } from '../../../shared/components/form-components/form-input/form-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SelectModule } from '../../../shared/components/form-components/select/select.module';
import { UnwrapDirectiveModule } from '../../../shared/directives/unwrap.directive';
import { ImportDataGridComponent } from './import-data-grid/import-data-grid.component';
import { CheckBoxModule } from '../../../shared/components/form-components/check-box/check-box.component';
import { ColumnPropertiesComponent } from './file-preview/column-properties/column-properties.component';
import { SendDataComponent } from './send-data/send-data.component';
import { UnitsModule } from '../../units/units.module';
import { TemplateManagerComponent } from './template-manager/template-manager.component';
import { ImportTemplateService } from './services/import-template.service';
import { ImportFileAdvancedValidatorService } from './services/import-file-advanced-validator/import-file-advanced-validator.service';
import { ButtonModule } from '../../../shared/components/button/button.module';
import { ProgressBarModule } from 'primeng/progressbar';
import { NgxFileDropModule } from '../../ngx-file-drop/ngx-file-drop.module';
import { AccordionModule } from 'primeng/accordion';
import { ModalsModule } from '../modals.module';
import { ChipModule } from 'primeng/chip';
import { DateFormatSuggestionPositionModule } from '../../../shared/directives/date-format-suggestion-position.directive';
import { HelpUrlPipeModule } from '../../../shared/pipes/help-url.pipe';
import { RangeLabelsSelectorModule } from '../../../shared/components/range-labels-selector/range-labels-selector.module';
import { MultiSelectModule } from 'primeng/multiselect';
import { EditImportTemplateModalComponent } from './template-manager/edit-import-template-modal/edit-import-template-modal.component';
import { ImportPasteDataCommonModule } from '../import-paste-data-common/import-paste-data-common.module';
import { FileLoaderComponent } from './file-loader/file-loader.component';

@NgModule({
  declarations: [
    ImportDataComponent,
    FileLoaderComponent,
    FilePreviewComponent,
    FilePropertiesComponent,
    ImportDataGridComponent,
    ColumnPropertiesComponent,
    SendDataComponent,
    TemplateManagerComponent,
    EditImportTemplateModalComponent,
  ],
  providers: [ImportTemplateService, ImportFileAdvancedValidatorService],
  imports: [
    CommonModule,
    RouterModule,
    NgxFileDropModule,
    ScrollingModule,
    MatTableModule,
    TableVirtualScrollModule,
    PagePanelModule,
    RadioGroupModule,
    FormInputModule,
    SelectModule,
    ReactiveFormsModule,
    FormsModule,
    SelectModule,
    UnwrapDirectiveModule,
    CheckBoxModule,
    UnitsModule,
    ButtonModule,
    ProgressBarModule,
    AccordionModule,
    ModalsModule,
    ChipModule,
    DateFormatSuggestionPositionModule,
    HelpUrlPipeModule,
    RangeLabelsSelectorModule,
    MultiSelectModule,
    ImportPasteDataCommonModule,
  ],
})
export class ImportDataModule {}
