import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { BackendConnectionEffects } from '../../+store/backend-connection/backend-connection.effects';
import { BackendConnectionService } from '../../shared/backend-connection/backend-connection.service';
import { BackendConnectionModuleFeatureName, backendConnectionReducer } from '../../+store/backend-connection/backend-connection.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(BackendConnectionModuleFeatureName, backendConnectionReducer),
    EffectsModule.forFeature([BackendConnectionEffects]),
  ],
  providers: [BackendConnectionService],
})
export class DbConnectionModule {}
