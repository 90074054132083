import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { getCurrentFileActiveJsWorkerJob } from './js-worker-jobs.selectors';
import { backendRequestStartedAction } from '../backend-connection/backend-connection.actions';
import { filterNil } from '@dunefront/common/common/state.helpers';

@Injectable()
export class JsWorkerJobsEffects {
  constructor(private store: Store) {
    this.store
      .select(getCurrentFileActiveJsWorkerJob)
      .pipe(filterNil())
      .subscribe((job) => {
        this.store.dispatch(
          backendRequestStartedAction({
            taskId: job.id,
            actionType: job.type,
            activityOverlayConfig: job.activityOverlayConfig,
          }),
        );
      });
  }
}
