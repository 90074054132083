import { Injectable } from '@angular/core';
import { BaseWsEffects } from '../base-ws.effects';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { BackendConnectionService } from '../../shared/backend-connection/backend-connection.service';
import { ModalService } from '../../common-modules/modals/modal.service';
import {
  applyImportTemplateAction,
  closeEditImportTemplateModalAction,
  createImportDataTemplateAction,
  deleteImportTemplateAction,
  loadImportDataTemplatesSuccessAction,
  openImportDataModalAction,
  redirectToGaugeDataAction,
  importTemplateAlreadyExitsAction,
  updateImportDataStateAction,
  updateImportDataTemplateAction,
} from './import-data.actions';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { ImportDataComponent } from '../../common-modules/modals/import-data/import-data.component';
import { RouterHelperService } from '../../shared/services/router-helper.service';
import { dataFailed, deleteRowsSuccess, updateRowSuccess } from '../app.actions';
import { of } from 'rxjs';
import { ImportTemplateService } from '../../common-modules/modals/import-data/services/import-template.service';
import { getImportDataModuleState, getImportDataTemplates } from './import-data.selectors';
import { ImportTemplateConverter } from './model/import-template-converter';
import { CrudResponse } from '@dunefront/common/modules/common.actions';
import { IImportTemplateDto } from '@dunefront/common/modules/data-storage/dto/import-template/import-template.dto';
import { getNoneTemplateTemplateId } from '@dunefront/common/common/templates/template-parser';
import {
  ImportDataModuleName,
  ImportDataTemplateDeleteAction,
  ImportDataTemplateLoadAction,
  ImportDataTemplateSaveAction,
} from '@dunefront/common/modules/import-data/import-data-module.actions';
import { RouteModuleData, RouteModuleDataChart } from '../../pages/gauge-data-page/gauge-data-routes-names';
import { TemplatesLoadActionResponse } from '@dunefront/common/common/templates/templates.interfaces';

@Injectable()
export class ImportDataEffects extends BaseWsEffects {
  constructor(
    actions$: Actions,
    store: Store,
    wsService: BackendConnectionService,
    modalService: ModalService,
    protected routerHelperService: RouterHelperService,
    private importTemplateService: ImportTemplateService,
  ) {
    super(actions$, wsService, ImportDataModuleName, false, true, modalService, store);
  }

  public openImportDataModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(openImportDataModalAction),
        tap(() => this.modalService.open(ImportDataComponent, undefined, 'import-data-modal', '1200px', undefined, false)),
      ),
    { dispatch: false },
  );

  public redirectToGaugeData$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(redirectToGaugeDataAction),
        tap((action) => {
          this.routerHelperService.navigateToScenarioBasedPage(action.moduleType, [RouteModuleData, RouteModuleDataChart], null).then();
        }),
      ),
    { dispatch: false },
  );

  public loadImportDataTemplatesAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(openImportDataModalAction),
      mergeMap(() =>
        this.emit<TemplatesLoadActionResponse<IImportTemplateDto>>(new ImportDataTemplateLoadAction()).pipe(
          map((result) => loadImportDataTemplatesSuccessAction(result.payload)),
          catchError((err) => of(dataFailed(err))),
        ),
      ),
    ),
  );

  public createImportDataTemplatesAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createImportDataTemplateAction),
      concatLatestFrom(() => this.store.select(getImportDataModuleState)),
      mergeMap(([action, state]) => {
        const templateDto = ImportTemplateConverter.createTemplate(state, action.templateName, action.dbType);
        return this.emit<CrudResponse>(new ImportDataTemplateSaveAction(templateDto)).pipe(
          switchMap((result) => [updateRowSuccess(result.payload), closeEditImportTemplateModalAction()]),
          catchError((err) => {
            if (err.name === 'exists') {
              return of(importTemplateAlreadyExitsAction(action));
            } else {
              return of(dataFailed(err));
            }
          }),
        );
      }),
    ),
  );

  public templateAlreadyExistsAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(importTemplateAlreadyExitsAction),
        concatLatestFrom(() => [this.store.select(getImportDataTemplates), this.store.select(getImportDataModuleState)]),
        mergeMap(async ([action, templates, state]) => {
          const templateDto = ImportTemplateConverter.createTemplate(state, action.templateName, action.dbType);

          const template = templates.find((tpl) => tpl.Name === action.templateName && tpl.Type === action.dbType);
          if (template == null) {
            return;
          }
          templateDto.Id = template.Id;

          const confirm = await this.modalService.showConfirm(
            `Template with the name: "${
              template.Name
            }" ( ${template.Type.toLocaleLowerCase()} settings ) already exists. Do you want to override it?`,
            'Template already exists',
          );

          if (confirm) {
            this.store.dispatch(updateImportDataTemplateAction({ template: templateDto, templateName: template.Name }));
          }
        }),
      ),
    { dispatch: false },
  );

  public updateImportDataTemplatesAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateImportDataTemplateAction),
      concatLatestFrom(() => this.store.select(getImportDataModuleState)),
      mergeMap(([action]) => {
        const templateDto: IImportTemplateDto = { ...action.template, Name: action.templateName };
        return this.emit<CrudResponse>(new ImportDataTemplateSaveAction(templateDto)).pipe(
          switchMap((result) => [updateRowSuccess(result.payload), closeEditImportTemplateModalAction()]),
          catchError((err) => of(dataFailed(err))),
        );
      }),
    ),
  );

  public deleteImportDataTemplateAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteImportTemplateAction),
      mergeMap((action) =>
        this.emit<CrudResponse>(new ImportDataTemplateDeleteAction(action.templateId)).pipe(
          mergeMap((result) => [
            deleteRowsSuccess(result.payload),
            applyImportTemplateAction({ templateId: getNoneTemplateTemplateId(), applyFilePropertiesConfig: true }),
          ]),
          catchError((err) => of(dataFailed(err))),
        ),
      ),
    ),
  );

  public applyImportTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(applyImportTemplateAction),
      concatLatestFrom(() => this.store.select(getImportDataModuleState)),
      map(([action, state]) => {
        const newState = this.importTemplateService.applyTemplate(state, action.templateId, action.applyFilePropertiesConfig);
        return updateImportDataStateAction(newState);
      }),
    ),
  );
}
