import { createSelector } from '@ngrx/store';
import { getSelectedGravelId, getValidatedGravels } from './gravel.selectors';
import { getPumpingScreensGravelIsUsed } from '../pumping/selectors/fluid-and-gravel-delete.selectors';
import { FluidGravelUsageFinder } from '@dunefront/common/modules/validation/fluid-gravel-usage-finder';
import { getFluidAndGravelUsageCalculatorsDeps } from '../fluid/fluid-delete.selectors';

export const getGravelsCount = createSelector(getValidatedGravels, (state) => state.ids.length);

const getCalculatorsScreensGravelIsUsed = createSelector(getSelectedGravelId, getFluidAndGravelUsageCalculatorsDeps, (...[fluidId, deps]) =>
  FluidGravelUsageFinder.FindGravelInCalculators(fluidId, deps),
);

export const getScreensGravelIsUsed = createSelector(
  getCalculatorsScreensGravelIsUsed,
  getPumpingScreensGravelIsUsed,
  (...[calculatorsScreensGravelIsUsed, pumpingScreensGravelIsUsed]) => {
    const errorStrings = [...calculatorsScreensGravelIsUsed];
    if (pumpingScreensGravelIsUsed.length) {
      errorStrings.push(...pumpingScreensGravelIsUsed);
    }
    return errorStrings;
  },
);

export const isGravelDeletable = createSelector(getGravelsCount, getScreensGravelIsUsed, (gravelsCount, gravelErrors) => {
  if (gravelsCount <= 1) {
    return 'At least one gravel must remain in the list';
  }
  if (gravelErrors.length > 0) {
    return `This gravel can't be deleted as it is being used in the following screens: <br> -> ${gravelErrors.join('<br> -> ')}`;
  }
  return '';
});
