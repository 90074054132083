import { createSelector } from '@ngrx/store';
import { getFluidsLeftNavElement$ } from '../common/fluids/fluids.menu';
import { getGravelsLeftNavElement$ } from '../common/gravel/gravel.menu';
import { ILeftNavItem, LeftNavHelpers } from '../../shared/components/left-nav/left-nav.helpers';
import { getSettingsLeftNavElement } from '../common/settings/settings.menu';

export const getPsdAnalysisModuleMenuData = createSelector(
  getFluidsLeftNavElement$,
  getGravelsLeftNavElement$,
  getSettingsLeftNavElement,
  (fluidsLeftNavElement, gravelsLeftNavElement, settings): ILeftNavItem[] => [
    fluidsLeftNavElement,
    gravelsLeftNavElement,
    LeftNavHelpers.getNavElement('Friction', 'friction', 'icon-fingerprint-circle-copy'),
    LeftNavHelpers.getNavElement('Settling', 'settling', 'icon-download-arrow-circle'),
    LeftNavHelpers.getNavElement('Resuspension', 'resuspension', 'icon-image-picture-modern-circle-copy'),
    LeftNavHelpers.getNavElement('MASP', 'masp', 'icon-dashboard-style-6'),
    LeftNavHelpers.getNavElement('Injection test', 'injection-test', 'icon-enter-circle'),
    LeftNavHelpers.getNavElement('Leakoff coefficient', 'leakoff-coefficient', 'icon-share-arrow-circle-copy'),
    settings,
    LeftNavHelpers.getBreak(),
    LeftNavHelpers.getStartStopButton(),
  ],
);
