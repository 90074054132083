import { UnitConverter } from '../../converter.interfaces';
import { PlaneAngleUnit } from '../../../dto/unit-system.dto';

export class PlaneAngleConverter extends UnitConverter {
  public static symbols: Array<string> = ['', 'deg', 'rad'];

  public static unit = PlaneAngleUnit;

  public static fromSi(value: number, unit: PlaneAngleUnit): number {
    switch (unit) {
      case PlaneAngleUnit.Degree: {
        return value;
      }
      case PlaneAngleUnit.Radian: {
        return value * 1.74532925e-2;
      }
      default: {
        throw new TypeError('Unknown Plane Angle Unit:' + unit);
      }
    }
  }

  public static toSi(value: number, unit: PlaneAngleUnit): number {
    switch (unit) {
      case PlaneAngleUnit.Degree: {
        return value;
      }
      case PlaneAngleUnit.Radian: {
        return value / 1.74532925e-2;
      }
      default: {
        throw new TypeError('Unknown Plane Angle Unit:' + unit);
      }
    }
  }

  public static getSymbol(unit: PlaneAngleUnit): string {
    return this.symbols[unit];
  }
}
