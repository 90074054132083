import { IDictionaryWithArray, isDefined } from '../../common/state.helpers';
import { DataFileType } from '../../dto/data-storage';
import { IValidatedStorageFileWithColumns } from '../data-storage/data-storage.validation';
import { KahnsAlgorithm } from './kahns-algorithm/kahns-algorithm';

export class EquationRecalculationHelper {
  public static findDependencyOrder(
    storageFiles: IDictionaryWithArray<IValidatedStorageFileWithColumns>,
    dependencyToCheck?: { vertex: string; dependency: string },
  ): number[] {
    const ka = new KahnsAlgorithm();

    // check of potential equation variable used for validation
    if (dependencyToCheck != null) {
      ka.addDependency(
        dependencyToCheck.vertex,
        storageFiles.dict[dependencyToCheck.dependency]?.file?.FileType === DataFileType.EquationResult
          ? dependencyToCheck.dependency
          : undefined,
      );
    }

    Object.values(storageFiles.dict)
      .filter(isDefined)
      .filter((file) => file.file?.FileType === DataFileType.EquationResult)
      .forEach((file: IValidatedStorageFileWithColumns) =>
        file.columns.forEach((col) => {
          if (col.Equation != null) {
            const parsedEquation = JSON.parse(col.Equation);
            parsedEquation.variables.forEach((eqVar: any) =>
              ka.addDependency(
                col.FileId.toString(),
                storageFiles.dict[eqVar.FileId]?.file?.FileType === DataFileType.EquationResult ? eqVar.FileId : undefined,
              ),
            );
          }
        }),
      );

    return ka.calculateExecutionOrder().map((fileId) => +fileId);
  }
}
