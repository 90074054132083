import { createAction, props } from '@ngrx/store';
import { ValidatedMaspCalculatorModuleState } from '@dunefront/common/modules/calculators/masp-calculator/masp-calculator-module.state';
import { IUpdateRowsProps } from '@dunefront/common/common/common-store-crud.interfaces';

export const updateMASPCalculatorRow = createAction(
  '[calculators] updateMASPCalculatorRow',
  props<IUpdateRowsProps<ValidatedMaspCalculatorModuleState>>(),
);

export const calculateMASP = createAction('[calculators] calculateMASP', props<{ ScenarioId: number }>());
