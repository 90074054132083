<div class="grid-form-container" *ngIf="pumping">
  <div class="grid-two-one">
    <app-radio-group
      id="well-fluids-is-simple"
      [items]="items"
      layout="horizontal"
      [source]="pumping"
      [key]="'IsWellFluidSelectionSimple'"
      (valueChanged)="pumpingPropertyChanged.emit($event)"
      dataCy="well-fluids-is-simple"
    >
    </app-radio-group>
  </div>
</div>
