import { BasePptSectionGenerator, PptContext } from '../base-ppt-section-generator';
import { IUnitSystemDto } from '@dunefront/common/dto/unit-system.dto';
import { firstValueFrom } from 'rxjs';
import { selectCurrentUnitSystem } from '../../../../../../+store/units/units.selectors';
import { WellModuleState } from '@dunefront/common/modules/well/well-module.state';
import { getWellModuleState } from '../../../../../../+store/well/well.selectors';
import { CommonReportDataHelpers, IDataRowWithCode } from '../../common/common-report-data-helpers';
import { PptDocumentTableGeneratorHelper } from '../ppt-document-table-generator.helper';
import { getRowsForCalculations } from '@dunefront/common/common/common-grid.interfaces';
import { ZoneModel } from '@dunefront/common/modules/well/model/zone/zone.model';

export class ReservoirDataPptGenerator extends BasePptSectionGenerator {
  constructor(context: PptContext) {
    super(context);
  }

  public async generate(): Promise<void> {
    const title = 'Well - Reservoir Zones';
    const currentUnitSystem: IUnitSystemDto = await firstValueFrom(this.store.select(selectCurrentUnitSystem));
    const wellModuleState: WellModuleState = await firstValueFrom(this.store.select(getWellModuleState));
    const zoneData: IDataRowWithCode<ZoneModel>[] = CommonReportDataHelpers.generateCodeValues(
      getRowsForCalculations(wellModuleState.ZoneData.rows),
    );

    const { tableData, enumItemMap } = await CommonReportDataHelpers.createReservoirZoneTableData(zoneData, this.store);

    const tableRows = PptDocumentTableGeneratorHelper.createTableRows<ZoneModel>(tableData, enumItemMap, currentUnitSystem);

    this.addTableSlide({ title, tableRows, presetColumnsWidths: { 1: 30 } });
  }
}
