import { createTableState, ITableState } from '../../common/common-grid.interfaces';
import { Pipe } from '../pipes/pipe';
import { ShuntTube } from '../shunt-tube/shunt-tube';
import { Volumes } from './model/volumes/volumes';
import { IcdPortDataDto } from '../../dto/icd-port-data.dto';
import { DictionaryWithArray, IDictionaryWithArray } from '../../common/state.helpers';
import { IModuleState, IValidatedModuleState } from '../../common/common-state.interfaces';
import { ShuntTubeFactory } from '../shunt-tube/shunt-tube.factory';
import { VolumesFactory } from './model/volumes/volumes.factory';
import {
  createEmptyGaugeSectionInformationDto,
  createEmptySectionInformationDto,
  SectionInformationDto,
} from './dto/section-information.dto';
import { SectionInformationRow } from './model/volumes/section-information-row';

export const emptyVolumeSectionCalculatorResult = (): SectionInformationDto & IModuleState => ({
  ...createEmptySectionInformationDto(),
  isLoaded: false,
});

export const emptyVolumeGaugeSectionCalculatorResult = (): { rows: SectionInformationRow[] } & IModuleState => ({
  ...createEmptyGaugeSectionInformationDto(),
  isLoaded: false,
});

export interface CompletionModuleState extends IModuleState {
  LowerCompletion: ITableState<Pipe>;
  RunningString: ITableState<Pipe>;
  IcdPortData: IDictionaryWithArray<ITableState<IcdPortDataDto>>;
  ShuntTube: ShuntTube;
  Volumes: Volumes;
  VolumeSectionCalculatorResult: SectionInformationDto & IModuleState;
  VolumeGaugeSectionInformationResult: { rows: SectionInformationRow[] } & IModuleState;
  TopMDofLowerCompletion: number;
  ScenarioId: number;
  CentralizerOD: number;
  WashpipeEccentricity: number;
  HasCentralizers: boolean;
}

export const completionInitialState: CompletionModuleState = {
  ShuntTube: ShuntTubeFactory.createEmpty(),
  Volumes: VolumesFactory.createEmpty(),
  IcdPortData: DictionaryWithArray.clear(),
  RunningString: createTableState([]),
  LowerCompletion: createTableState([]),
  VolumeSectionCalculatorResult: emptyVolumeSectionCalculatorResult(),
  VolumeGaugeSectionInformationResult: emptyVolumeGaugeSectionCalculatorResult(),
  CentralizerOD: 0,
  HasCentralizers: false,
  ScenarioId: -1,
  TopMDofLowerCompletion: 0,
  WashpipeEccentricity: 0,
  isLoaded: false,
};

export interface ValidatedCompletionModuleState extends CompletionModuleState, IValidatedModuleState<CompletionModuleState> {
  isLowerCompletionScreenValid: boolean;
  isRunningStringScreenValid: boolean;
  isLowerCompletionScreenWarning: boolean;
  isRunningStringScreenWarning: boolean;
  isShuntTubesScreenValid: boolean;
}
