import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { createSelector, Store } from '@ngrx/store';
import * as actions from '../../../../../+store/gravel/gravel.actions';
import { ModalService } from '../../../../../common-modules/modals/modal.service';
import { ScreenService } from '../../../../../shared/services';
import { cancelChange, getCurrentValue } from '../../../../../+store/store.helpers';
import { DbDependentComponent } from '../../../../../common-modules/db-connection/db-dependent.component';
import { MeshSize } from '@dunefront/common/modules/gravel/dto/gravel.dto';
import { GeneralCalculations } from '@dunefront/common/common/general.calculations';
import { getGravelNames } from '../../../../../+store/gravel/gravel.selectors';
import { notEmpty } from '@dunefront/common/common/state.helpers';
import { areStringsTheSame } from '@dunefront/common/common/helpers';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { ISelectItem } from '@dunefront/common/common/select.helpers';
import { Gravel } from '@dunefront/common/modules/gravel/model/gravel';
import { changeProp, ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';
import { StoreCrudPropsFactory } from '@dunefront/common/common/common-store-crud.interfaces';
import { gravelAdvOptionsValid } from '../../gravel.menu';
import { RouterHelperService } from '../../../../../shared/services/router-helper.service';
import { getAppModuleType } from '../../../../../+store/ui/ui.selectors';
import { isSimulateDisp } from '../../../../../+store/menu-selectors/menu-selectors.helpers';

const getGravelAdvancedOptionsAvailable$ = createSelector(getAppModuleType, (moduleType) => !isSimulateDisp(moduleType));

@Component({
  selector: 'app-gravel-properties',
  templateUrl: './gravel-properties.component.html',
  styleUrls: ['./gravel-properties.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GravelPropertiesComponent extends DbDependentComponent {
  private _gravel!: Gravel;
  public gravelAdvOptionsValid = false;

  public gravelAdvancedOptionsAvailable$ = this.store.select(getGravelAdvancedOptionsAvailable$);

  @Input()
  public set gravel(gravel: Gravel) {
    this._gravel = gravel;
    this.gravelAdvOptionsValid = gravelAdvOptionsValid(gravel);
  }

  public get gravel(): Gravel {
    return this._gravel;
  }

  @Output() public gravelPropertyChange = new EventEmitter<ObjectChangeProp<Gravel>>();

  public UnitType = UnitSystem;
  public meshSizes!: ISelectItem<MeshSize>[];

  constructor(
    store: Store,
    cdRef: ChangeDetectorRef,
    protected modalService: ModalService,
    protected resizeService: ScreenService,
    private routerHelperService: RouterHelperService,
  ) {
    super(store, cdRef);

    this.meshSizes = [
      { value: MeshSize['6/12'], text: MeshSize['6/12'] },
      { value: MeshSize['8/16'], text: MeshSize['8/16'] },
      { value: MeshSize['12/18'], text: MeshSize['12/18'] },
      { value: MeshSize['12/20'], text: MeshSize['12/20'] },
      { value: MeshSize['16/20'], text: MeshSize['16/20'] },
      { value: MeshSize['16/30'], text: MeshSize['16/30'] },
      { value: MeshSize['20/40'], text: MeshSize['20/40'] },
      { value: MeshSize['30/50'], text: MeshSize['30/50'] },
      { value: MeshSize['40/60'], text: MeshSize['40/60'] },
      { value: MeshSize['40/70'], text: MeshSize['40/70'] },
      { value: MeshSize['Other'], text: MeshSize['Other'] },
    ];
  }

  public async emitValue(props: ObjectChangeProp<Gravel>): Promise<void> {
    const isValid = await this.validateGravelBeforeUpdate(props);
    if (!isValid) {
      return;
    }

    const gravel = changeProp(this.gravel, props);
    this.store.dispatch(actions.updateGravel(StoreCrudPropsFactory.updateRow(gravel, props)));
  }

  private async validateGravelBeforeUpdate(props: ObjectChangeProp<Gravel>): Promise<boolean> {
    if (props.key === 'Name') {
      if ((props.value as string).trim().length === 0 || areStringsTheSame(props.value as string, this.gravel.Name)) {
        const cancelGenerator = cancelChange(this.gravel, props, this.cdRef);
        this.gravel = cancelGenerator.next().value;
        this.gravel = cancelGenerator.next().value;
        this.cdRef.markForCheck();
        return false;
      }
      const gravelNames = (await getCurrentValue<string[]>(notEmpty(this.store.select(getGravelNames)))) || [];
      const isGravelNameAlreadyExist = GeneralCalculations.DoesArrayContainsString(gravelNames, props.value as string);
      if (isGravelNameAlreadyExist) {
        await this.modalService.showAlert('This name already exists - please use a different one.', 'Information');

        const cancelGenerator = cancelChange(this.gravel, props, this.cdRef);
        this.gravel = cancelGenerator.next().value;
        this.gravel = cancelGenerator.next().value;
        this.cdRef.markForCheck();
        return false;
      }
    }

    return true;
  }

  public openAdvancedGravelProperties(): void {
    this.routerHelperService.navigateToGravelAdvancedProperties(this.gravel.Id).then();
  }
}
