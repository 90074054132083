import { BasePptSectionGenerator, PptContext } from '../base-ppt-section-generator';
import { firstValueFrom } from 'rxjs';
import { selectCurrentUnitSystem } from '../../../../../../+store/units/units.selectors';
import { PptDocumentTableGeneratorHelper } from '../ppt-document-table-generator.helper';
import { IUnitSystemDto } from '@dunefront/common/dto/unit-system.dto';
import { CommonReportDataHelpers } from '../../common/common-report-data-helpers';
import { IReportImportColumn } from '../../doc/sections/imported-data-generator.helper';

export class ImportedDataInformationPptGenerator extends BasePptSectionGenerator {
  constructor(context: PptContext) {
    super(context);
  }

  public async generate(): Promise<void> {
    const title = 'Imported Data Information';
    const currentUnitSystem: IUnitSystemDto = await firstValueFrom(this.store.select(selectCurrentUnitSystem));
    const { tableData, enumItemMap } = await CommonReportDataHelpers.createImportedDataTableData(this.store, currentUnitSystem);
    const tableRows = PptDocumentTableGeneratorHelper.createTableRows<IReportImportColumn>(tableData, enumItemMap, currentUnitSystem);

    this.addTableSlide({ title, tableRows, presetColumnsWidths: { 0: 22, 1: 22, 2: 12, 3: 10, 4: 16, 5: 9, 6: 9 } });
  }
}
