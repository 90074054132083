import { createTableRow, ITableRow } from '../../../../common/common-grid.interfaces';
import { createEmptyPumpedFluidAndGravelDto, PumpedFluidAndGravelDto } from '../../dto/pumped-fluid-and-gravel.dto';

export class PumpedFluidAndGravelFactory {
  public static createRowListFromDtos(
    scenarioId: number,
    rangeId: number,
    pumpedFluidAndGravels: PumpedFluidAndGravelDto[],
  ): ITableRow<PumpedFluidAndGravelDto>[] {
    const rows = pumpedFluidAndGravels.map((row, rowIndex) => createTableRow(row, 'data', rowIndex));
    const maxRowIndex = rows.length ? Math.max(...rows.map((row) => row.rowIndex)) + 1 : 0;
    rows.push(createTableRow(createEmptyPumpedFluidAndGravelDto(scenarioId, rangeId), 'insert-row', maxRowIndex));
    return rows;
  }

  public static createEmpty(scenarioId: number, rangeId: number): PumpedFluidAndGravelDto {
    return {
      Id: -1,
      ScenarioId: scenarioId,
      RangeId: rangeId,
      SortOrder: -1,
      FluidId: null,
      GravelId: null,
      Volume: 0,
    };
  }
}
