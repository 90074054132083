import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DictionaryWithArray, isDefined } from '@dunefront/common/common/state.helpers';
import { GravelCalculations } from '@dunefront/common/modules/gravel/dto/gravel.calculations';
import { GravelModuleState } from '@dunefront/common/modules/gravel/gravel-module.state';
import { ISelectItem, toSelectItem } from '@dunefront/common/common/select.helpers';
import { Gravel } from '@dunefront/common/modules/gravel/model/gravel';
import { GravelValidation } from '@dunefront/common/modules/gravel/model/gravel.validation';
import { selectRouteParam } from '../router/router.selectors';
import { GravelDto } from '@dunefront/common/modules/gravel/dto/gravel.dto';
import { getAppModuleType } from '../ui/ui.selectors';

const getGraveModuleState = createFeatureSelector<GravelModuleState>('gravel');

export const getValidatedGravelModuleState = createSelector(getGraveModuleState, getAppModuleType, (state, currentModuleType) =>
  GravelValidation.validate(state, { currentModuleType }),
);

export const getGravelIdFromUrl = createSelector(selectRouteParam('gravelId'), (gravelId) => Number(gravelId ?? 0));
export const getSelectedGravelIdFromState = createSelector(getGraveModuleState, (state) => state.SelectedGravelId);
const getGravelIds = createSelector(getGraveModuleState, (state) => state.Gravels.ids);

export const getSelectedGravelId = createSelector(
  getGravelIdFromUrl,
  getSelectedGravelIdFromState,
  getGravelIds,
  (gravelIdFromUrl, gravelIdFromState, gravelIds) => {
    const gravelId = gravelIdFromUrl > 0 ? gravelIdFromUrl : gravelIdFromState;
    if (!gravelIds.includes(gravelId + '')) {
      return Number(gravelIds[0]);
    } else {
      return gravelId;
    }
  },
);

export const getValidatedGravels = createSelector(getValidatedGravelModuleState, (state) => state.Gravels);

export const getSelectedGravel = createSelector(getValidatedGravelModuleState, getSelectedGravelId, (state, gravelId) => {
  return state.Gravels.dict[gravelId];
});

export const getAllGravelsArray = createSelector(getValidatedGravelModuleState, (state) =>
  Object.values(state.Gravels.dict)
    .filter(isDefined)
    .map((value) => {
      const { isValid, error, ForchheimerBeta, ...remainingProperties } = value;
      return remainingProperties as GravelDto;
    }),
);

export const getSelectedGravelState = createSelector(
  getValidatedGravelModuleState,
  getSelectedGravelId,
  (state, gravelId): Gravel | undefined => {
    if (!gravelId) {
      return undefined;
    }
    const gravel = DictionaryWithArray.get(state.Gravels, gravelId);

    if (!gravel) {
      console.error('Gravel not found ' + gravelId);
      return;
    }

    if (gravel.PackPermeability <= 0 || gravel.BulkDensity <= 0 || gravel.AbsoluteDensity <= 0) {
      return { ...gravel, ForchheimerBeta: 0 };
    }

    return { ...gravel, ForchheimerBeta: GravelCalculations.ForchheimerBeta(gravel) };
  },
);

export const getIsGravelStateLoaded = createSelector(getGraveModuleState, (state) => state.isLoaded);

export const getGravelsSelectData = createSelector(
  getValidatedGravelModuleState,
  getSelectedGravelId,
  (state, gravelId): IGravelSelectorData => ({
    items: [
      { text: 'None', value: 0 },
      ...DictionaryWithArray.getArray(state.Gravels)
        .sort((a, b) => a.Id - b.Id)
        .map((gravel) => toSelectItem(gravel.Id, gravel.Name, undefined, !gravel.isValid)),
    ],
    selectedGravel: state.Gravels.dict[gravelId],
  }),
);

export const getGravelsSelectDataWithoutNone = createSelector(
  getValidatedGravelModuleState,
  getSelectedGravelId,
  (state, gravelId): IGravelSelectorData => ({
    items: DictionaryWithArray.getArray(state.Gravels)
      .sort((a, b) => a.Id - b.Id)
      .map((gravel) => toSelectItem(gravel.Id, gravel.Name, undefined, !gravel.isValid)),
    selectedGravel: state.Gravels.dict[gravelId],
  }),
);

export const getLastGravelId = createSelector(getGraveModuleState, (state) => Math.max(...state.Gravels.ids.map((id) => +id)));

export const getGravelNames = createSelector(getGravelsSelectData, (state) => state.items.map((item) => item.text));

export interface IGravelSelectorData {
  items: ISelectItem<number>[];
  selectedGravel?: Gravel;
}

export const getGravelStateIsValid = createSelector(getValidatedGravelModuleState, (state) => state.isValid);
