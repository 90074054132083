export class MathHelpers {
  /**
   * @param value - Number to be rounded
   * @param digits – The number of digits to appear after the decimal point;
   */
  public static round(value: number, digits: number): number {
    const multiplier = Math.pow(10, digits);

    return Math.round((value + Number.EPSILON) * multiplier) / multiplier;
  }
}
