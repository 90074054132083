<div class="left-nav-container" [class.open]="isSideNavExpanded" (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()">
  <div class="nav-items">
    <app-left-nav-item
      [isSideNavExpanded]="isSideNavExpanded"
      [element]="homeButtonItem"
      [isCalculationActive]="isCalculationActive"
      [isDisabledWhenCalculationActive]="true"
    ></app-left-nav-item>

    <div class="link-buttons-container">
      <app-left-nav-item
        *ngIf="areMultipleModulesAvailable && showModuleSwitcher"
        [element]="moduleSelectionItem"
        [isRouterLinkDisabled]="true"
        [isSideNavExpanded]="isSideNavExpanded"
        (navClick)="runCalculationService.moduleSelectionClick()"
        [isCalculationActive]="isCalculationActive"
        [isDisabledWhenCalculationActive]="true"
      ></app-left-nav-item>

      <ng-template ngFor let-menuElement let-i="index" [ngForOf]="menuData" [ngForTrackBy]="trackByFn">
        <ng-container [ngSwitch]="menuElement.type">
          <app-left-nav-item
            *ngSwitchCase="'NavElement'"
            #navMenuItem
            [isSideNavExpanded]="isSideNavExpanded"
            [selectedUri]="selectedUri"
            [isSideNavPinned]="isSideNavPinned"
            [element]="$any(menuElement)"
            [initOpenElement]="isInitHover(menuElement)"
            (mouseenter)="onHoverNavItem(menuElement)"
            (reordered)="onReordered($event)"
            id="{{'nav-element-' + menuElement.label}}"
            (activeMenuItemChanged)="activeMenuItemChanged.emit($event); initialHoverItem = null"
          ></app-left-nav-item>

          <app-start-stop-button
            *ngSwitchCase="'StartStopButton'"
            (mouseenter)="onHoverNavItem(menuElement)"
            [isSideNavExpanded]="isSideNavExpanded"
          ></app-start-stop-button>

          <div *ngSwitchCase="'Break'" class="item-break"></div>
        </ng-container>
      </ng-template>
    </div>
  </div>

  <div class="open-close-btn" (click)="togglePinSideNav()" [class.active]="isSideNavPinned" *ngIf="!isAdminPanel">
    <span class="icon-pin-cross" *ngIf="isSideNavPinned"></span>
    <span class="icon-pin" *ngIf="!isSideNavPinned"></span>
  </div>
</div>
