import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { getCurrentColIndex, IValidatedImportDataModuleState } from '../../../../+store/import-data/import-data-module.state';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';
import { MatTableDataSource } from '@angular/material/table';
import { UnitHelpers } from '../../../units/unit-helpers';
import * as dayjs from 'dayjs';
import * as durationPlugin from 'dayjs/plugin/duration';
import * as customParseFormatPlugin from 'dayjs/plugin/customParseFormat';
import { ICombinedCustomFormatResult, ImportDataCalculations, ITimeAndDays } from '../../../../+store/import-data/import-data.calculations';

import { TimeUnit } from '@dunefront/common/dto/unit-system.dto';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { ScreenService } from '../../../../shared/services';
import { Subscription } from 'rxjs';
import { IParsedFileResult } from '../../../../+store/import-data/model/parsed-result';
import { IValidatedColConfig } from '../../../../+store/import-data/model/col-config';
import { ImportColumnType } from '@dunefront/common/modules/data-storage/dto/import-template/import-template.dto';
import { GridResizeService } from '../../../../shared/services/grid-resize.service';
import { includedColumnIdChangedAction } from '../../../../+store/import-data/import-data.actions';
import { Store } from '@ngrx/store';

dayjs.extend(durationPlugin);
dayjs.extend(customParseFormatPlugin);

@Component({
  selector: 'app-import-data-grid',
  templateUrl: './import-data-grid.component.html',
  styleUrls: ['./import-data-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImportDataGridComponent implements OnChanges, OnDestroy, AfterViewInit {
  @ViewChild(CdkVirtualScrollViewport)
  public virtualScroll: CdkVirtualScrollViewport | undefined;

  @Input()
  public state!: IValidatedImportDataModuleState;

  @Input()
  public isShowingHiddenColumns = true;

  private _parsedResult: IParsedFileResult | undefined;
  public colNames: string[] = [];

  @Input()
  public set parsedResult(parsedResult: IParsedFileResult | undefined) {
    this._parsedResult = parsedResult;
    this.colNames = this._parsedResult?.columnsWithUnits.map((colWithUnit) => colWithUnit.columnName) ?? [];
  }

  public get parsedResult(): IParsedFileResult | undefined {
    return this._parsedResult;
  }

  @Input()
  public isInDefineDataMode = false;

  public height = 0;

  public dataSource: MatTableDataSource<string[]> | null = null;

  private baseTime: number | undefined;

  private customTimeCols: IValidatedColConfig[] = [];
  private combinedCustomFormatResult!: ICombinedCustomFormatResult;
  private timeColIndex = -1;

  private rowIdTimeMap = new Map<number, ITimeAndDays>();

  private subscription = new Subscription();

  constructor(
    private resizeService: ScreenService,
    private cdRef: ChangeDetectorRef,
    private el: ElementRef,
    private store: Store,
    gridResizeService: GridResizeService,
  ) {
    this.subscription.add(this.resizeService.screenResized$.subscribe((size) => this.resize()));
    this.subscription.add(gridResizeService.getResizeSubject().subscribe(() => this.resize()));
  }

  public ngAfterViewInit(): void {
    this.resize();
  }

  public isColumnIncluded(colId: number): boolean {
    return this.state.includedColumnIds.includes(colId);
  }

  public isColumnHidden(colId: number): boolean {
    if (colId === 0) {
      return !this.isInDefineDataMode;
    }
    return !this.isColumnIncluded(colId) && !this.isShowingHiddenColumns;
  }

  public isColumnDisabled(colId: number): boolean {
    return colId === 0 || !this.isColumnIncluded(colId);
  }

  public isCurrentColumn(colId: number): boolean {
    return this.isInDefineDataMode ? getCurrentColIndex(this.state) === colId : false;
  }

  public getColName(colId: number, nbspIfEmpty = true): string {
    return this.getTrimmedTextOrNbsp(this.state.colConfigs[colId]?.name ?? '', nbspIfEmpty);
  }

  public getOriginalColName(colId: number, nbspIfEmpty = true): string {
    return this.getTrimmedTextOrNbsp(this.state.colConfigs[colId]?.originalName ?? '', nbspIfEmpty);
  }

  public getOriginalUnitName(colId: number, nbspIfEmpty = true): string {
    return this.getTrimmedTextOrNbsp(this.state.colConfigs[colId]?.originalUnit ?? '', nbspIfEmpty);
  }

  private getTrimmedTextOrNbsp(text: string, nbspIfEmpty: boolean): string {
    const trimmed = text.trim();
    return trimmed === '' && nbspIfEmpty ? '&nbsp' : trimmed;
  }

  public getColSymbol(colId: number, nbspIfEmpty = true): string {
    const colConfig = this.state.colConfigs[colId];
    if (colConfig == null || colConfig.unitSystem === null) {
      return nbspIfEmpty ? '&nbsp;' : '';
    }
    if (colConfig.isTimeCustomFormat) {
      return colConfig.timeCustomFormat ?? (nbspIfEmpty ? '&nbsp;' : '');
    }
    return UnitHelpers.getUnitSystemText(colConfig.unitSystem, colConfig.unitSymbol);
  }

  public async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (this.parsedResult == null) {
      return;
    }

    if (changes.parsedResult != null) {
      this.dataSource = new TableVirtualScrollDataSource(this.parsedResult.data);
    }
    if (changes.state != null) {
      const prevCombinedCustomFormat = this.combinedCustomFormatResult?.combinedCustomFormat ?? '';
      this.baseTime = undefined;
      this.customTimeCols = this.state.colConfigs.filter((c) => c.isTimeCustomFormat && this.state.includedColumnIds.includes(c.colIndex));
      this.combinedCustomFormatResult = ImportDataCalculations.getCombinedCustomFormat(this.customTimeCols);
      this.timeColIndex = this.state.colConfigs.length
        ? this.state.colConfigs.findIndex((c) => !c.isTimeCustomFormat && c.columnType === ImportColumnType.Time)
        : -1;
      this.rowIdTimeMap.clear();
      if (prevCombinedCustomFormat !== this.combinedCustomFormatResult.combinedCustomFormat) {
        // reload grid when custom format changed
        this.dataSource = new TableVirtualScrollDataSource();
        this.dataSource.filter = performance.now().toString();
        this.virtualScroll?.scrollToIndex(0);
        this.dataSource = new TableVirtualScrollDataSource(this.parsedResult.data);
      }
    }
  }

  public onIgnoredColumnIdChanged(colId: number, isEnabled: boolean): void {
    this.store.dispatch(includedColumnIdChangedAction({ colId, isEnabled }));
  }

  public getTimestamp(row: string[]): string {
    const ms = this.parseTimestampForRow(row);
    const duration = dayjs.duration(ms);
    let dMs = Math.round(duration.milliseconds());
    let dSeconds = duration.seconds();
    let dMinutes = duration.minutes();
    let dHours = duration.hours();
    let dDays = duration.days();
    if (dMs > 999) {
      dMs -= 1000;
      dSeconds++;
    }
    if (dSeconds > 59) {
      dSeconds -= 60;
      dMinutes++;
    }
    if (dMinutes > 59) {
      dMinutes -= 60;
      dHours++;
    }
    if (dHours > 23) {
      dHours -= 24;
      dDays++;
    }

    return `${dDays} day(s) ${dHours}:${dMinutes}:${dSeconds}.${dMs}`;
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private resize(): void {
    setTimeout(() => {
      if (this.el != null) {
        this.height = this.el.nativeElement.clientHeight;
        this.cdRef.markForCheck();
        return;
      }
      this.resize();
    }, 10);
  }

  private findPrevTime(rowIndex: number): ITimeAndDays | undefined {
    const sortedKeys = [...this.rowIdTimeMap.keys()].sort((a, b) => a - b);
    const currentRowIndex = sortedKeys.indexOf(rowIndex);
    return currentRowIndex > 0 ? this.rowIdTimeMap.get(sortedKeys[currentRowIndex - 1]) : undefined;
  }

  private calculateCustomFormatTimestamp(customDate: Date, rowIndex: number): number {
    const currentTime = customDate.getTime();
    this.rowIdTimeMap.set(rowIndex, { time: currentTime, days: 0 });
    if (this.baseTime === undefined) {
      this.baseTime = currentTime;
    }

    // Account for day changes
    let prevTime = this.findPrevTime(rowIndex);
    if (!prevTime) {
      prevTime = { time: currentTime, days: 0 };
    }

    const { time: newCurrentTime, days } = ImportDataCalculations.addDaysIfNeeded(currentTime, prevTime, this.combinedCustomFormatResult);

    this.rowIdTimeMap.set(rowIndex, { time: newCurrentTime, days });
    return newCurrentTime - this.baseTime;
  }

  private parseTimestampForRow(row: string[]): number {
    const isCustomTime = this.customTimeCols.length > 0;

    if (isCustomTime) {
      // calculate time for custom formatted time
      if (this.combinedCustomFormatResult.combinedCustomFormat.trim() === '') {
        return 0;
      }

      if (this.parsedResult == null) {
        return 0;
      }

      const rowIndex = this.parsedResult.data.indexOf(row);
      // Check if data can be parsed
      const parseResult = ImportDataCalculations.checkIfCustomDateCanBeParsed(
        row,
        this.state.colConfigs,
        this.combinedCustomFormatResult,
        this.customTimeCols,
        false,
      );
      this.combinedCustomFormatResult = parseResult.combinedCustomFormatResult;
      if (parseResult.customDate === null) {
        // couldn't parse custom date;
        return 0;
      }
      return this.calculateCustomFormatTimestamp(parseResult.customDate, rowIndex);
    }

    // parse based on normal delta time column
    if (this.timeColIndex < 0) {
      return 0;
    }

    return this.timeColConfigsToMilliseconds(row[this.timeColIndex], this.state.colConfigs[this.timeColIndex].unitSymbol);
  }

  private timeColConfigsToMilliseconds(value: string, unit: TimeUnit): number {
    const valueNumber = +value;
    if (!isFinite(valueNumber)) {
      return 0;
    }

    const ms = valueNumber * 1000;
    if (unit === TimeUnit.Second) {
      return ms;
    }
    if (unit === TimeUnit.Minute) {
      return ms * 60;
    }
    return ms * 60 * 60;
  }
}
