import { FluidGravelDatabaseType } from '../../fluid/dto/fluid.dto';
import { GeneralCalculations } from '../../../common/general.calculations';
import { COPY_NAME_SUFFIX } from '../../../common/constants';

export interface GravelDto {
  Id: number;
  ScenarioId: number;
  Name: string;
  MeshSize: MeshSize;
  MeanDiameter: number;
  AbsoluteDensity: number;
  BulkDensity: number;
  PackPermeability: number;
  ThermalConductivity: number;
  SpecificHeatCapacity: number;
  OroskarAndTurianCoefficient: number;
  AngleOfRepose: number;

  SortOrder: number;
  MinDiameter?: number;
  MaxDiameter?: number;
  DatabaseType: FluidGravelDatabaseType;

  FrictionMultiplier: number;
  IsFrictionMultiplierByDeviation: boolean;
  FrictionMultiplierDeviation: number;
  Notes: string;
}

export enum MeshSize {
  '6/12' = '6/12',
  '8/16' = '8/16',
  '12/18' = '12/18',
  '12/20' = '12/20',
  '16/20' = '16/20',
  '16/30' = '16/30',
  '20/40' = '20/40',
  '30/50' = '30/50',
  '40/60' = '40/60',
  '40/70' = '40/70',
  'Other' = 'Other',
  'NoGravel' = '0',
}

export class GravelHelper {
  public static readonly DEFAULT_NAME = 'New Gravel';

  public static getGravelCopyName(gravelName: string): string {
    return gravelName + COPY_NAME_SUFFIX;
  }

  public static IsDefaultGravel(gravel: GravelDto): boolean {
    if (!this.IsNamedNewGravel(gravel)) {
      return false;
    }
    if (gravel.MeshSize !== '6/12') {
      return false;
    }
    if (gravel.MeanDiameter !== 0) {
      return false;
    }
    if (gravel.BulkDensity !== 0) {
      return false;
    }
    if (gravel.PackPermeability !== 0) {
      return false;
    }
    if (gravel.OroskarAndTurianCoefficient !== 1.85) {
      return false;
    }
    if (gravel.AngleOfRepose !== 30) {
      return false;
    }
    if (gravel.ThermalConductivity !== 0.2) {
      return false;
    }
    if (gravel.SpecificHeatCapacity !== 830) {
      return false;
    }
    return true;
  }

  public static IsNamedNewGravel(gravel: GravelDto): boolean {
    return GeneralCalculations.AreStringsTheSame(gravel.Name, this.DEFAULT_NAME);
  }
}

export const createNoneGravel = (scenarioId: number): GravelDto => ({
  Id: GravelConstants.NoGravel,
  ScenarioId: scenarioId,
  Name: 'None',
  AbsoluteDensity: 0,
  BulkDensity: 0,
  DatabaseType: FluidGravelDatabaseType.Custom,
  MeanDiameter: 0,
  MeshSize: MeshSize.NoGravel,
  OroskarAndTurianCoefficient: 0,
  AngleOfRepose: 0,
  PackPermeability: 0,
  SpecificHeatCapacity: 0,
  SortOrder: 0,
  ThermalConductivity: 0,
  FrictionMultiplier: 1,
  FrictionMultiplierDeviation: 45,
  IsFrictionMultiplierByDeviation: false,
  Notes: ''
});

export class GravelConstants {
  public static readonly NoGravel = 0;
}
