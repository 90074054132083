import { Component, OnChanges, SimpleChanges } from '@angular/core';
import { ITableRow } from '@dunefront/common/common/common-grid.interfaces';
import { BaseGaugeCarrierPropertiesComponent } from '../base.gauge-carrier-properies.component';
import { ConcentricGaugeCarrierPipe } from '@dunefront/common/modules/pipes/running-string-pipes/pipes/concentric-gauge-carrier-pipe';
import { BypassPorts } from '@dunefront/common/modules/pipes/bypass-ports';
import { changeProp, ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';

@Component({
  selector: 'app-concentric-gauge-carrier-properties',
  templateUrl: './concentric-gauge-carrier-properties.component.html',
  styleUrls: ['./concentric-gauge-carrier-properties.component.css'],
})
export class ConcentricGaugeCarrierPropertiesComponent
  extends BaseGaugeCarrierPropertiesComponent<ConcentricGaugeCarrierPipe>
  implements OnChanges
{
  public flowPorts?: BypassPorts;

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.pipe != null && this.pipe != null) {
      this.flowPorts = this.pipe.rowData.FlowPorts;
    }
  }

  public onFlowPortsChange(event: ObjectChangeProp<BypassPorts>): void {
    if (this.pipe == null) {
      return;
    }
    const newRow: ITableRow<ConcentricGaugeCarrierPipe> = {
      ...this.pipe,
      rowData: {
        ...this.pipe.rowData,
        FlowPorts: changeProp(this.pipe.rowData.FlowPorts, event),
      },
    };
    this.rowUpdated.emit({ rows: [newRow], colIds: ['FlowPorts'], shouldResetResults: event.shouldResetResults });
  }
}
