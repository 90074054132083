import { InsertLocation } from '../modules/common.interfaces';
import { DataSourceKey, ObjectChangeProp } from './common-state.interfaces';
import { ITableRow } from './common-grid.interfaces';

export interface ICommonStoreCrudProps {
  shouldResetResults: boolean;
}

// region INSERT
// noOfRowsToDelete is used when insertLocation equals 'merge-rows'
export interface IInsertRowsPropsCommon extends ICommonStoreCrudProps {
  refId?: number;
  insertLocation?: InsertLocation;
  noOfRowsToDelete?: number;
}

export interface IInsertRowsProps<T> extends IInsertRowsPropsCommon {
  rows: ITableRow<T>[];
}

// endregion

// region UPDATE

export interface IUpdateTableRowsProps<T> extends ICommonStoreCrudProps {
  rows: ITableRow<T>[];
  colIds?: DataSourceKey<T>[];
  focusedRowKey?: number;
  scenarioId?: number;
  isUndoEnabled?: boolean;
}

export interface IUpdateRowsProps<T> extends ICommonStoreCrudProps {
  rows: T[];
  colIds?: DataSourceKey<T>[];
  scenarioId?: number;
  isUndoEnabled?: boolean;
}

// endregion

// region DELETE

export interface IDeleteRowsProps {
  rowIds: number[];
  scenarioId: number;
  shouldResetResults: boolean;
}

// endregion

export class StoreCrudPropsFactory {
  public static deleteRows(obj: { Id: number; ScenarioId: number }, shouldResetResults: boolean): IDeleteRowsProps {
    return {
      rowIds: [obj.Id],
      scenarioId: obj.ScenarioId,
      shouldResetResults,
    };
  }

  public static updateTableRows<T>(
    rows: ITableRow<T>[],
    change: ObjectChangeProp<T>,
    overrideColIds: DataSourceKey<T>[] = [],
    focusedRowKey?: number,
  ): IUpdateTableRowsProps<T> {
    return {
      rows,
      colIds: overrideColIds.length === 0 ? [change.key] : overrideColIds,
      shouldResetResults: change.shouldResetResults,
      focusedRowKey,
    };
  }

  public static updateRow<T>(
    row: T,
    change?: ObjectChangeProp<T>,
    shouldResetResults?: boolean,
    overrideColIds?: DataSourceKey<T>[],
    isUndoEnabled?: boolean,
  ): IUpdateRowsProps<T> {
    return this.updateRows([row], change, shouldResetResults, overrideColIds, isUndoEnabled);
  }

  public static updateRows<T>(
    rows: T[],
    change?: ObjectChangeProp<T>,
    shouldResetResults?: boolean,
    overrideColIds?: DataSourceKey<T>[],
    isUndoEnabled?: boolean,
  ): IUpdateRowsProps<T> {
    if (change == null) {
      return {
        rows,
        colIds: overrideColIds,
        shouldResetResults: shouldResetResults ?? false,
        isUndoEnabled: isUndoEnabled ?? true,
      };
    } else {
      return {
        rows,
        colIds: overrideColIds == null ? [change.key] : overrideColIds,
        shouldResetResults: shouldResetResults == null ? change.shouldResetResults : shouldResetResults,
        isUndoEnabled: isUndoEnabled ?? true,
      };
    }
  }
}
