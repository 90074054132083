import { DocumentGeneratorHelper } from '../document-generator.helper';
import { HeadingLevel, Paragraph, Table } from 'docx';
import { IUnitSystemDto } from '@dunefront/common/dto/unit-system.dto';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';
import { getSummaryState } from '../../../../../../+store/summary/summary.selectors';
import { filter } from 'rxjs/operators';
import { SummaryModuleState } from '../../../../../../+store/summary/summary.reducer';
import { loadSummaryModuleAction } from '../../../../../../+store/summary/summary.actions';
import { DocumentTableGeneratorHelper } from '../document-table-generator.helper';
import { SummaryDto } from '@dunefront/common/dto/summary.dto';
import { getWellTreatmentType } from '../../../../../../+store/well/well.selectors';
import { TreatmentType } from '@dunefront/common/modules/well/dto/well.dto';
import { CommonReportDataHelpers } from '../../common/common-report-data-helpers';
import { getAreCurrentResultsPresent } from '../../../../../../+store/calculation-engine/calculation-engine-results.selectors';

export class SummaryReportGeneratorHelper {
  public static async createSummarySection(
    store: Store,
    currentUnitSystem: IUnitSystemDto,
    isTestRun: boolean,
    rangeName?: string,
  ): Promise<Array<Paragraph | Table>> {
    const mainTitle = DocumentGeneratorHelper.addRangeNameToTitle('Simulation Summary', rangeName);
    const areCurrentResultsPresent = await firstValueFrom(store.select(getAreCurrentResultsPresent));
    if (!areCurrentResultsPresent) {
      return CommonReportDataHelpers.createNoResultsMessage(mainTitle);
    }

    store.dispatch(loadSummaryModuleAction());

    const summaryState: SummaryModuleState = await firstValueFrom(store.select(getSummaryState).pipe(filter((state) => state.isLoaded)));
    const wellTreatment: TreatmentType = await firstValueFrom(store.select(getWellTreatmentType));
    const summary = summaryState.summary;

    if (!summary) {
      return CommonReportDataHelpers.createNoResultsMessage(mainTitle);
    }

    return [
      DocumentGeneratorHelper.createParagraphHeader(mainTitle, HeadingLevel.HEADING_2, 1),

      DocumentGeneratorHelper.createParagraphHeader('Warning Messages', HeadingLevel.HEADING_3, 2),
      this.createWarningMessages(summaryState, currentUnitSystem, isTestRun),

      DocumentGeneratorHelper.createParagraphHeader('Gravel Pack Efficiencies', HeadingLevel.HEADING_3, 2),
      this.createGravelPackEfficienciesSection(summary, currentUnitSystem),

      DocumentGeneratorHelper.createParagraphHeader('Gravel Below Packer', HeadingLevel.HEADING_3, 2),
      this.createGravelBelowPackerSection(summary, currentUnitSystem),

      DocumentGeneratorHelper.createParagraphHeader('Maximum Total Pressure', HeadingLevel.HEADING_3, 2),
      this.createMaxTotalPressureSection(summary, currentUnitSystem),

      ...(wellTreatment === TreatmentType.High_Rate_Water_Pack
        ? [
            DocumentGeneratorHelper.createParagraphHeader('Final Frac Data', HeadingLevel.HEADING_3, 2),
            this.createFinalFracDataSection(summary, currentUnitSystem),
          ]
        : []),

      DocumentGeneratorHelper.createParagraphHeader('Maximum Friction Pressure', HeadingLevel.HEADING_3, 2),
      this.createMaxFrictionPressureSection(summary, currentUnitSystem),
    ];
  }

  private static createWarningMessages(summaryState: SummaryModuleState, currentUnitSystem: IUnitSystemDto, isTestRun: boolean): Table {
    const tableData = CommonReportDataHelpers.createSummaryWarningMessagesTableData(summaryState, currentUnitSystem, isTestRun);

    return DocumentTableGeneratorHelper.createTable(tableData, null, currentUnitSystem, undefined, {
      0: 12,
      1: 12,
      2: 12,
      3: 64,
    });
  }

  private static createGravelPackEfficienciesSection(summary: SummaryDto, currentUnitSystem: IUnitSystemDto): Table {
    const tableData = CommonReportDataHelpers.createSummaryGravelPackEfficienciesSectionTableData(summary, currentUnitSystem);

    return DocumentTableGeneratorHelper.createGenericKeyValueTableFromTableData(tableData, currentUnitSystem);
  }

  private static createGravelBelowPackerSection(summary: SummaryDto, currentUnitSystem: IUnitSystemDto): Table {
    const tableData = CommonReportDataHelpers.createSummaryGravelBelowPackerSectionTableData(summary, currentUnitSystem);

    return DocumentTableGeneratorHelper.createGenericKeyValueTableFromTableData(tableData, currentUnitSystem);
  }

  private static createMaxTotalPressureSection(summary: SummaryDto, currentUnitSystem: IUnitSystemDto): Table {
    const tableData = CommonReportDataHelpers.createSummaryMaxTotalPressureSectionTableData(summary, currentUnitSystem);

    return DocumentTableGeneratorHelper.createGenericKeyValueTableFromTableData(tableData, currentUnitSystem);
  }

  private static createFinalFracDataSection(summary: SummaryDto, currentUnitSystem: IUnitSystemDto): Table {
    const tableData = CommonReportDataHelpers.createSummaryFinalFracDataSectionTableData(summary, currentUnitSystem);

    return DocumentTableGeneratorHelper.createGenericKeyValueTableFromTableData(tableData, currentUnitSystem);
  }

  private static createMaxFrictionPressureSection(summary: SummaryDto, currentUnitSystem: IUnitSystemDto): Table {
    const tableData = CommonReportDataHelpers.createSummaryMaxFrictionPressureSectionTableData(summary, currentUnitSystem);

    return DocumentTableGeneratorHelper.createGenericKeyValueTableFromTableData(tableData, currentUnitSystem);
  }
}
