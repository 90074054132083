import { PipeType } from '../../../../dto/pipe.dto';
import { RunningStringPipeDto } from '../../../../dto/running-string-pipe.dto';
import { BasePipeFactory } from '../../base.pipe.factory';
import { Pipe } from '../../pipe';

export interface WashPipe extends Pipe {
  PipeType: PipeType.Washpipe;
}

export class WashPipeFactory {
  public static createFromRunningString(runningStringDto: RunningStringPipeDto): WashPipe {
    const pipe = BasePipeFactory.createFromDto(runningStringDto);
    const washPipe: WashPipe = {
      ...pipe,
      PipeType: PipeType.Washpipe,
    };
    return washPipe;
  }
}

export function isWashPipe(pipe: Pipe): pipe is WashPipe {
  return pipe.PipeType === PipeType.Washpipe;
}
