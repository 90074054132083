import { UnitConverter } from '../../converter.interfaces';
import { CompressibilityUnit } from '../../../dto/unit-system.dto';

export class CompressibilityConverter extends UnitConverter {
  public static symbols: Array<string> = ['', '1/Pa', '1/kPa', '1/psi', 'cm²/kg', '1/bar'];

  public static unit = CompressibilityUnit;

  public static fromSi(value: number, unit: CompressibilityUnit): number {
    switch (unit) {
      case CompressibilityUnit.Reciprocal_pascal: {
        return value;
      }
      case CompressibilityUnit.Reciprocal_kilo_pascal: {
        return value * 1e3;
      }
      case CompressibilityUnit.Reciprocal_pound_per_square_inch: {
        return value * 6894.757293;
      }
      case CompressibilityUnit.Square_centimeter_perkilogram: {
        return value * 98066.5;
      }
      case CompressibilityUnit.Reciprocal_bar: {
        return value * 100000;
      }
      default: {
        throw new TypeError('Unknown Compressibility Unit:' + unit);
      }
    }
  }

  public static toSi(value: number, unit: CompressibilityUnit): number {
    switch (unit) {
      case CompressibilityUnit.Reciprocal_pascal: {
        return value;
      }
      case CompressibilityUnit.Reciprocal_kilo_pascal: {
        return value / 1e3;
      }
      case CompressibilityUnit.Reciprocal_pound_per_square_inch: {
        return value / 6894.757293;
      }
      case CompressibilityUnit.Square_centimeter_perkilogram: {
        return value / 98066.5;
      }
      case CompressibilityUnit.Reciprocal_bar: {
        return value / 100000;
      }
      default: {
        throw new TypeError('Unknown Compressibility Unit:' + unit);
      }
    }
  }

  public static getSymbol(unit: CompressibilityUnit): string {
    if (unit == null) {
      return '';
    }
    return this.symbols[unit];
  }
}
