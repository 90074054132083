import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ScenarioDto } from '@dunefront/common/modules/scenario/scenario.dto';
import { Store } from '@ngrx/store';
import { ModalService } from '../../../modal.service';
import { copyScenarioAction, createScenarioAction, updateScenariosAction } from '../../../../../+store/scenario/scenario.actions';
import { DbDependentComponent } from '../../../../db-connection/db-dependent.component';
import { getScenarioState } from '../../../../../+store/scenario/scenario.selectors';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';
import { modalButton, ModalButtonConfig } from '../../../generic-modal/generic-modal.component';
import { Scenario } from '@dunefront/common/modules/scenario/scenario';

@Component({
  selector: 'app-create-scenario',
  templateUrl: './create-edit-copy-scenario.component.html',
  styleUrls: ['./create-edit-copy-scenario.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateEditCopyScenarioComponent extends DbDependentComponent implements OnInit {
  @Input() public mode!: 'new' | 'edit' | 'copy';
  @Input() public selectedScenarioId!: number;

  public UnitType = UnitSystem;
  public scenario!: Scenario;
  public scenarioName = '';
  public scenarios!: Scenario[];
  public modalTitle = 'Scenario Manager';
  public modalButtonsConfigs: ModalButtonConfig[] = [
    modalButton('Cancel', (): void => this.cancelClicked(), 'create-scenario-cancel-btn', 'cancel'),
    modalButton('Ok', (): Promise<void> => this.okClicked(), 'create-scenario-ok-btn'),
  ];

  constructor(
    store: Store,
    protected modalService: ModalService,
    cdRef: ChangeDetectorRef,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
  ) {
    super(store, cdRef);
    this.subscription.add(
      this.store.select(getScenarioState).subscribe((scenarioState) => {
        this.scenarios = scenarioState.scenarios;
        this.cdRef.markForCheck();
        this.mode = config.data.mode;
        if (config.data.selectedScenarioId) {
          this.selectedScenarioId = config.data.selectedScenarioId;
        }
      }),
    );
  }

  public override ngOnInit(): void {
    super.ngOnInit();

    if (this.mode !== 'new' && this.selectedScenarioId) {
      const scenario = this.scenarios.find((scenario) => scenario.Id === this.selectedScenarioId);
      if (scenario) {
        this.scenario = scenario;
        this.scenarioName = this.scenario.Name;
      }
    }
  }

  public valueChanged(prop: ObjectChangeProp<ScenarioDto>): void {
    if (prop.key === 'Name') {
      this.scenario = { ...this.scenario, Name: prop.value as string };
    }
  }

  public textValueChanged(name: string): void {
    this.scenarioName = name;
  }

  public async okClicked(): Promise<void> {
    if (this.scenarioName === '') {
      await this.modalService.showAlert('You must enter a name for the scenario', 'Information');
      return;
    }
    if (this.scenarios.find((scenario) => scenario.Name.toLowerCase() === this.scenarioName.toLowerCase())) {
      await this.modalService.showAlert('This name already exists - please use a different one', 'Warning');
      return;
    }
    if (this.mode === 'edit') {
      this.store.dispatch(
        updateScenariosAction({
          scenarios: [{ ...this.scenario, Name: this.scenarioName }],
          changedKey: 'Name',
        }),
      );
    }
    if (this.mode === 'new') {
      this.store.dispatch(createScenarioAction({ name: this.scenarioName }));
    }
    if (this.mode === 'copy') {
      this.store.dispatch(copyScenarioAction({ scenarioId: this.scenario.Id, scenarioName: this.scenarioName }));
    }
    this.ref.close();
  }

  public cancelClicked(): void {
    this.ref.close();
  }
}
