import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ShuntTube } from '@dunefront/common/modules/shunt-tube/shunt-tube';
import { IRadioItem } from '@dunefront/common/common/radio.helpers';
import { ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';

@Component({
  selector: 'app-system-configuration',
  templateUrl: './system-configuration.component.html',
  styleUrls: ['./system-configuration.component.scss'],
})
export class SystemConfigurationComponent implements OnChanges {
  @Input() public shuntTube!: ShuntTube;

  @Output() public shuntTubeValueChange = new EventEmitter<ObjectChangeProp<ShuntTube>>();

  @Output() public isTransportTubePresentChange = new EventEmitter<boolean>();

  public tubeShapeRadio: IRadioItem<number>[] = [
    { value: 0, text: 'Rectangle' },
    { value: 1, text: 'Round' },
  ];

  public tubeLocationRadio: IRadioItem<boolean>[] = [
    { value: true, text: 'External' },
    { value: false, text: 'Internal' },
  ];

  public internalReservoirRadio: IRadioItem<boolean>[] = [
    { value: true, text: 'Yes', disabled: false },
    { value: false, text: 'No' },
  ];

  public transportTubeRadio: IRadioItem<boolean>[] = [
    { value: true, text: 'Yes' },
    { value: false, text: 'No' },
  ];

  public ngOnChanges(changes: SimpleChanges): void {
    this.isTransportTubePresentChange.emit(changes.shuntTube.currentValue?.IsTransportTubePresent);
  }

  public onValueChange(props: ObjectChangeProp<ShuntTube>): void {
    this.shuntTubeValueChange.emit(props);
  }
}
