import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding } from '@angular/core';
import { Store } from '@ngrx/store';
import { ValidatedWellModuleState } from '@dunefront/common/modules/well/well-module.state';
import * as wellActions from '../../../../+store/well/well.actions';
import { ITableRow } from '@dunefront/common/common/common-grid.interfaces';
import { PageWithGridComponent } from '../../../../shared/components/grid/page-with-grid.component';
import { ModalService } from '../../../../common-modules/modals/modal.service';
import { ScreenService } from '../../../../shared/services';
import * as uiActions from '../../../../+store/ui/ui.actions';
import { ZoneModel } from '@dunefront/common/modules/well/model/zone/zone.model';
import { getValidatedDeveloperSettings } from '../../../../+store/settings/validated-settings.selectors';
import { ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';
import { IUpdateTableRowsProps } from '@dunefront/common/common/common-store-crud.interfaces';
import { getValidatedWellModuleState } from '../../../../+store/well/validated-well.selectors';
import { PanelHelpMode } from '../../../../shared/components/help-button/help-buton.component';
import { getAppModuleType } from '../../../../+store/ui/ui.selectors';
import { isSimulateDisp } from '../../../../+store/menu-selectors/menu-selectors.helpers';

@Component({
  selector: 'app-reservoir-data',
  templateUrl: './reservoir-data.component.html',
  styleUrls: ['./reservoir-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReservoirDataComponent extends PageWithGridComponent<ZoneModel> {
  public wellState!: ValidatedWellModuleState;
  public developerSettings$ = this.store.select(getValidatedDeveloperSettings);
  public isLossDefinitionAvailable = true;

  public PanelHelpMode = PanelHelpMode;

  @HostBinding('class.is-loss-rate-calculated')
  public get IsLossRateCalculated(): boolean {
    return this.wellState.IsLossRateCalculated ?? false;
  }

  @HostBinding('class.is-loss-definition-disabled')
  public get IsLossDefinitionDisabled(): boolean {
    return !this.isLossDefinitionAvailable;
  }

  constructor(store: Store, cdRef: ChangeDetectorRef, modalService: ModalService, resizeService: ScreenService) {
    super(store, cdRef, modalService, resizeService);
    store.dispatch(uiActions.setWellTabAction({ tab: 'reservoir-data' }));

    this.subscription.add(
      store.select(getValidatedWellModuleState).subscribe((state) => {
        this.wellState = state;
        this.setSelectedRow();
        cdRef.markForCheck();
      }),
    );

    this.subscription.add(
      this.store.select(getAppModuleType).subscribe((moduleType) => (this.isLossDefinitionAvailable = !isSimulateDisp(moduleType))),
    );

    super.onHelpChange('well-reservoir-data', 'zone-properties');
  }

  public onWellPropertyChanged(props: ObjectChangeProp<ValidatedWellModuleState>): void {
    this.store.dispatch(wellActions.changeWellPropertyAction(props));
  }

  public getRows(): ITableRow<ZoneModel>[] {
    return this.wellState?.ZoneData.rows ?? [];
  }

  public updateRow(row: IUpdateTableRowsProps<ZoneModel>): void {
    this.gridComponent?.grid.gridConfig?.updateRowsAction(row);
  }
}
