import { createAction, props } from '@ngrx/store';
import { IDeleteRowsProps, IUpdateRowsProps, StoreCrudPropsFactory } from '@dunefront/common/common/common-store-crud.interfaces';
import { ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';
import { ImportOrEquationColumn } from '@dunefront/common/modules/data-storage/data-storage.validation';
import { DeleteResultsFilter, ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';

export const deleteColumnAction = createAction('[DataStorage] deleteColumn', props<IDeleteRowsProps>());
export const updateImportColumnAction = createAction(
  '[DataStorage] saveColumn',
  (
    column: ImportOrEquationColumn,
    change: ObjectChangeProp<ImportOrEquationColumn>,
    isUndoEnabled = true,
  ): IUpdateRowsProps<ImportOrEquationColumn> => StoreCrudPropsFactory.updateRow(column, change, undefined, undefined, isUndoEnabled),
);

export const loadFilesAction = createAction('[DataStorage] loadFiles');
export const deleteFileAction = createAction('[DataStorage] deleteFile', props<IDeleteRowsProps>());
export const resetDataAction = createAction('[DataStorage] resetData', props<{ fileId: number }>());
export const reduceDataAction = createAction(
  '[DataStorage] reduceData',
  props<{
    fileId: number;
    samplingFrequency: number;
  }>(),
);

export const deleteResultsAction = createAction('[DataStorage] DeleteResults');
export const deleteAllResultsAction = createAction('[DataStorage] DeleteAllResults');

export const deleteResultsSuccessAction = createAction(
  '[DataStorage] deleteResultsSuccessAction',
  props<{ fileHash: string; deleteResultsFilter: DeleteResultsFilter }>(),
);

export const removeDataResultsFromStoreIfNeeded = createAction(
  '[DataStorage] RemoveDataResultsFromStoreIfNeeded',
  props<{
    fileHash: string;
    deleteResultsFilter: DeleteResultsFilter;
    currentData?: { currentAppModuleType?: ModuleType; currentScenarioId?: number; currentRangeId?: number };
  }>(),
);
export const removeDataResultsFromStore = createAction(
  '[DataStorage] RemoveDataResultsFromStore',
);

export const updateEquationFileHash = createAction(
  '[DataStorage] updateEquationFileHash',
  props<{ fileId: number; fileHash: string; updateDate: string }>(),
);

export const vacuumDbAction = createAction('[DataStorage] VacuumDbAction', props<{ force: boolean }>());
