import { UiState } from '../ui/ui-module.state';
import { ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';

export const isEvaluate = (moduleType: ModuleType): boolean => moduleType === ModuleType.Evaluate;
export const isSimulate = (moduleType: ModuleType): boolean => moduleType === ModuleType.Simulate;
export const isSimulateCH = (moduleType: ModuleType): boolean => moduleType === ModuleType.Simulate_CH;
export const isSimulateDisp = (moduleType: ModuleType): boolean => moduleType === ModuleType.Simulate_Disp;
export const isTrendAnalysis = (moduleType: ModuleType): boolean => moduleType === ModuleType.Trend_Analysis;
export const isPsdAnalysis = (moduleType: ModuleType): boolean =>
  [ModuleType.PSD_Analysis_Basic, ModuleType.PSD_Analysis_Full].includes(moduleType);
export const isDataAnalysis = (moduleType: ModuleType): boolean => moduleType === ModuleType.Data_Analysis;
export const isCalculator = (moduleType: ModuleType): boolean =>
  [
    ModuleType.Friction_Calc,
    ModuleType.Settling_Calc,
    ModuleType.Resuspension_Calc,
    ModuleType.Injection_Test_Calc,
    ModuleType.MASP_Calc,
    ModuleType.Leakoff_Coefficient_Calc,
    ModuleType.Calculators_Generic,
  ].includes(moduleType);

export const isDataBased = (uiState: UiState): boolean =>
  (isEvaluate(uiState.appModuleType) || isTrendAnalysis(uiState.appModuleType) || isDataAnalysis(uiState.appModuleType)) &&
  uiState.appModuleSection === 'gauge-data';
