import { Component, ElementRef, HostBinding, Input, NgModule, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { getLicenseExpirationWarning } from '../../../+store/licensing/licensing.selectors';
import { Store } from '@ngrx/store';
import { isElectron } from '@dunefront/common/common/electron/is-electron';

@Component({
  selector: 'app-license-expiration-warning',
  template: '<p #paragraphElement *ngIf="licenseExpirationWarning$ | async as warning">{{warning}}</p>',
  styleUrls: ['./license-expiration-warning.component.scss'],
})
export class LicenseExpirationWarningComponent {
  @Input() public expirationWarning?: string;
  @Input()
  public set isSaved(isSaved: boolean | null | undefined) {
    const paragraph = this.paragraphElement?.nativeElement;

    // hide warning for 5 seconds, to show 'saved label
    if (isSaved) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }

      paragraph?.classList.add('fadeOut');
      this.timeoutId = setTimeout(() => {
        paragraph?.classList.remove('fadeOut');
      }, 5000);
    }
  }
  @HostBinding('class.isElectron') public isElectron: boolean = isElectron();
  @ViewChild('paragraphElement') public paragraphElement?: ElementRef;

  private timeoutId?: ReturnType<typeof setTimeout>;
  public licenseExpirationWarning$ = this.store.select(getLicenseExpirationWarning);
  constructor(private store: Store) {}
}

@NgModule({
  imports: [CommonModule],
  declarations: [LicenseExpirationWarningComponent],
  exports: [LicenseExpirationWarningComponent],
})
export class LicenseExpirationWarningModule {}
