import { WarningMessageDto, WarningMessageType } from '../../dto/warning-message.dto';
import { createTableRow, createTableState, ITableState } from '../../common/common-grid.interfaces';

export interface WarningMessage extends WarningMessageDto {
  Id: number;
}

export class WarningMessageFactory {
  public static create(warningMessageDtos: WarningMessageDto[]): ITableState<WarningMessage> {
    const warningMessages: WarningMessage[] = warningMessageDtos.map((warningMessageDto, index) => ({
      ...warningMessageDto,
      Id: index,
    }));
    return createTableState(
      warningMessages.map((warningMessage) => {
        const newTableRow = createTableRow(warningMessage, 'data', warningMessage.Id);
        newTableRow.isWarning = newTableRow.rowData.MessageType === WarningMessageType.Warning;
        return newTableRow;
      }),
    );
  }
}
