<div class="wrapper with-help" [class.help-open]="isHelpOpen" *ngIf="state">
  <div class="dialog-header">
    <h4>
      Import Data
      <ng-container *ngIf="state.fileName"> - {{ state.fileName }}</ng-container>
    </h4>

    <app-modal-help-button
      *ngIf="step !== ImportDataStep.SendData"
      [dialogHasScrollbar]="dialogHasScrollbar"
      (helpClick)="onHelpClick($event.isHelpOpen)"
    ></app-modal-help-button>
  </div>
  <div class="dialog-content">
    <app-file-loader
      *ngIf="step === ImportDataStep.SelectFile"
      [state]="state"
      (fileLoaded)="onFileLoaded($event)"
      (stateChange)="changeState($event)"
    ></app-file-loader>
    <app-file-preview
      data-cy="import-data-preview"
      *ngIf="step === ImportDataStep.Preview || step === ImportDataStep.DefineData"
      [parsedResult]="FileParser.parsedResult"
      [state]="state"
      [currentUnitSystem]="currentUnitSystem"
      (stateChange)="changeState($event)"
      (dateFormatsHelpClicked)="openDateFormatHelp()"
      [step]="step"
    ></app-file-preview>
    <app-send-data
      *ngIf="step === ImportDataStep.SendData"
      [parsedResult]="FileParser.parsedResult"
      [state]="state"
      (isUploadedChange)="onIsUploadedChanged($event)"
    ></app-send-data>
  </div>
  <div class="dialog-footer">
    <button
      *ngIf="!isSendDataStep"
      appButton
      type="button"
      data-cy="cancel-data-import"
      [isUiLockable]="false"
      (buttonClick)="onCancelClick()"
      class="btn btn-primary btn-sm"
    >
      Cancel
    </button>
    <button
      *ngIf="isSendDataStep"
      appButton
      type="button"
      [isUiLockable]="false"
      (buttonClick)="onImportAnotherFileClick()"
      class="btn btn-primary btn-sm"
      data-cy="import-another-file-btn"
      [disabled]="!isFinishButtonEnabled"
    >
      Import another file
    </button>
    <button
      *ngIf="!isSendDataStep"
      appButton
      type="button"
      [isUiLockable]="false"
      (buttonClick)="onBackClick()"
      class="btn btn-primary btn-sm"
      [disabled]="isBackButtonDisabled"
    >
      < Back
    </button>
    <button
      *ngIf="!isSendDataStep"
      appButton
      type="button"
      [isUiLockable]="false"
      (buttonClick)="onNextClick()"
      class="btn btn-primary btn-sm"
      data-cy="import-next-btn"
      [disabled]="isNextButtonDisabled"
    >
      Next >
    </button>
    <button
      *ngIf="!isSendDataStep"
      appButton
      type="button"
      [isUiLockable]="false"
      (buttonClick)="onImportClick()"
      class="btn btn-primary btn-sm"
      [disabled]="!isImportButtonEnabled || !haveIncludedColumns"
    >
      Import
    </button>
    <button
      *ngIf="isSendDataStep"
      appButton
      type="button"
      [isUiLockable]="false"
      (buttonClick)="onFinishClick()"
      class="btn btn-primary btn-sm"
      [disabled]="!isFinishButtonEnabled"
    >
      Finish
    </button>
  </div>
</div>

<div class="modal-help" [class.open]="isHelpOpen && helpContent[step]" *ngIf="step !== ImportDataStep.SendData">
  <div class="help-content">

    <ng-container *ngIf="currentHelpContent">
      <iframe *ngIf="currentHelpContent.length === 1" [src]="currentHelpContent[0].url | helpUrl"></iframe>

      <p-accordion
        *ngIf="currentHelpContent.length > 1"
        class="help-accordion"
        [ngClass]="'tabs-'+currentHelpContent.length"
        [styleClass]="'data-import-help-accordion'"
        [(activeIndex)]="helpAccordionActiveIndex"
      >
        <p-accordionTab header="{{ help.label }}" *ngFor="let help of currentHelpContent">
          <iframe [src]="help.url | helpUrl"></iframe>
        </p-accordionTab>
      </p-accordion>

    </ng-container>

  </div>
</div>
