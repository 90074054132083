import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import * as uiActions from '../../+store/ui/ui.actions';
import { ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import { Subscription } from 'rxjs';
import { getCurrentScenarioId } from '../../+store/scenario/scenario.selectors';
import { getConnectedDbInfo } from '../../+store/backend-connection/backend-connection.selectors';
import { IDbInfo } from '@dunefront/common/modules/db-connection/db-connection.actions';
import { getTrendAnalysisModuleMenuData } from './trend-analysis-page.menu';

@Component({
  selector: 'app-trend-analysis-page',
  templateUrl: './trend-analysis-page.component.html',
  styleUrls: ['./trend-analysis-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrendAnalysisPageComponent {
  public trendAnalysisMenu$ = this.store.select(getTrendAnalysisModuleMenuData);
  public subscription = new Subscription();
  private dbInfo: IDbInfo | undefined;
  private scenarioId!: number;
  public selectedUri: string | null = null;
  public showPrevNextNav = false;
  public moduleType = ModuleType.Trend_Analysis;

  constructor(private store: Store) {
    store.dispatch(uiActions.setAppModuleAction({ appModuleType: this.moduleType }));
    this.subscription.add(store.select(getCurrentScenarioId).subscribe((scenarioId) => (this.scenarioId = scenarioId)));
    this.subscription.add(store.select(getConnectedDbInfo).subscribe((dbInfo) => (this.dbInfo = dbInfo)));
  }
}
