<div class="grid-form-container main with-gap">
  <div class="grid-form-container no-padding inline">
    <app-form-input
      appUnwrap
      label="Joint Length"
      [source]="screenPipeRow"
      [key]="'JointLength'"
      [unitType]="UnitType.Long_Length"
      (valueChanged)="onValueChange($event)"
      dataCy="joint-length"
    ></app-form-input>

    <app-form-input
      appUnwrap
      label="Base Pipe OD"
      [source]="screenPipeRow"
      [key]="'BasepipeOuterDiameter'"
      [unitType]="UnitType.Diameter"
      (valueChanged)="onValueChange($event)"
      [decimalPlaces]="3"
      dataCy="base-pipe-od"
    ></app-form-input>
  </div>
  <div class="grid-form-container no-padding inline">
    <app-form-input
      appUnwrap
      label="Handling Length"
      [source]="screenPipeRow"
      [key]="'HandlingLength'"
      [unitType]="UnitType.Long_Length"
      (valueChanged)="onValueChange($event)"
      dataCy="handling-length"
    ></app-form-input>

    <app-form-input
      appUnwrap
      label="Filter ID"
      [source]="screenPipeRow"
      [key]="'FilterInnerDiameter'"
      [unitType]="UnitType.Diameter"
      (valueChanged)="onValueChange($event)"
      [decimalPlaces]="3"
      dataCy="filter-id"
    ></app-form-input>
  </div>

  <app-icd-screen-component-props
    appUnwrap
    [icdPortData]="icdPortData"
    [icdScreenPipeRow]="icdScreenRow"
    [diameterUnit]="diameterUnit"
    (rowUpdated)="rowUpdated.emit($any($event))"
    (icdPortDataRowUpdated)="icdPortDataRowUpdated.emit($any($event))"
  >
  </app-icd-screen-component-props>
</div>
