import { IWellValidationDependencies, WellModuleState } from '../../well-module.state';
import { LocationType, TemperatureProfileType } from '../../dto/well.dto';
import { TemperatureProfile } from './temperature-profile';
import { noErrors } from '../../../../common/state.helpers';
import { IError } from '../../../../common/common-state.interfaces';
import { LicenseFeature } from '../../../licensing/licensing.interfaces';
import { validateFeatureNotSupportedInFluidPro, validateFeatureRequired } from '../../../licensing/licensing.validator';

export class TemperatureProfileValidation {
  public static ValidateTemperatureProfile(well: WellModuleState, deps: IWellValidationDependencies): TemperatureProfile {
    const tempProfileError: IError<TemperatureProfile> = {
      SeaLevelDepth: this.ValidateSeaLevelDepth(well),
      SeaBedDepth: this.ValidateSeaBedDepth(well),
      MinWaterTempDepth: this.ValidateMinWaterTempDepth(well),
      TemperatureProfileType: this.ValidateTemperatureProfileType(well, deps),
    };

    return {
      ...well.TemperatureProfile,
      error: tempProfileError,
      isValid: noErrors(tempProfileError),
    };
  }

  private static ValidateSeaLevelDepth(well: WellModuleState): string {
    if (well.LocationType === LocationType.Offshore) {
      const seaLevelDepth = well.TemperatureProfile.SeaLevelDepth;
      const seaBedDepth = well.TemperatureProfile.SeaBedDepth;
      const bottomHoleTVD = well.TemperatureProfile.BottomHoleTVD;
      if (seaLevelDepth <= 0) {
        return 'Sea level depth must be greater than zero';
      } else if (seaLevelDepth >= seaBedDepth) {
        return 'Sea level depth must be less than sea bed depth';
      } else if (seaLevelDepth >= bottomHoleTVD) {
        return 'Sea level depth must be less than bottomhole TVD';
      }
    }
    return '';
  }

  private static ValidateSeaBedDepth(well: WellModuleState): string {
    if (well.LocationType === LocationType.Offshore) {
      const seaLevelDepth = well.TemperatureProfile.SeaLevelDepth;
      const seaBedDepth = well.TemperatureProfile.SeaBedDepth;
      const bottomHoleTVD = well.TemperatureProfile.BottomHoleTVD;
      if (seaBedDepth <= 0) {
        return 'Sea bed depth must be greater than zero';
      } else if (seaLevelDepth >= seaBedDepth) {
        return 'Sea bed depth must be greater than sea level depth';
      } else if (seaBedDepth >= bottomHoleTVD) {
        return 'Sea bed depth must be less than bottomhole TVD';
      }
    }
    return '';
  }

  private static ValidateMinWaterTempDepth(well: WellModuleState): string {
    if (well.LocationType === LocationType.Offshore) {
      const minWaterTempDepth = well.TemperatureProfile.MinWaterTempDepth;
      const seaLevelDepth = well.TemperatureProfile.SeaLevelDepth;
      const seaBedDepth = well.TemperatureProfile.SeaBedDepth;

      if (minWaterTempDepth <= 0) {
        return 'Minimum water temperature depth must be greater than zero';
      } else if (minWaterTempDepth > seaBedDepth) {
        return 'Minimum water temperature depth must be less than or equal to sea bed depth';
      } else if (minWaterTempDepth <= seaLevelDepth) {
        return 'Minimum water temperature depth must be greater than sea level depth';
      }
    }
    return '';
  }

  private static ValidateTemperatureProfileType(
    well: WellModuleState,
    { currentLicenseFeatures, currentModuleType }: IWellValidationDependencies,
  ): string {
    if (well.TemperatureProfile.TemperatureProfileType !== TemperatureProfileType.Fixed) {
      return (
        validateFeatureNotSupportedInFluidPro(currentModuleType) ||
        validateFeatureRequired([LicenseFeature.Simulate, LicenseFeature.Evaluate], currentLicenseFeatures)
      );
    }

    return '';
  }
}
