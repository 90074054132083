<ng-container *ngIf="psd">
  <div class="input-type-radio-container">
    <div>Input Type</div>
    <app-radio-group
      [source]="psd"
      [key]="'PSDInputType'"
      [items]="gridRadioItems"
      id="psd-data-grid-radio"
      [dataCy]="'psd-data-grid-input-type-radio'"
      (valueChanged)="onInputTypeChanged($event)"
    >
    </app-radio-group>
  </div>
  <app-grid
    #grid
    [dataSource]="psdReadings"
    [gridConfig]="gridConfig"
    [columns]="gridConfig.columns"
    [height]="height"
    [headerTopRowHeight]="30"
    [showCrudControls]="false"
    [minRowCount]="3"
    [isFirstRowInsertingAllowed]="true"
    elementId="psd-data-grid"
    [isFirstRowDeleteAllowed]="true"
    [isPastingEnabled]="true"
    dataCy="psd-data-grid"
  ></app-grid>
  <div class="buttons-panel">
    <div class="align-right">
      <button appButton type="button" class="btn btn-primary btn-sm" (buttonClick)="onImport()"
              data-cy="psd-data-import">
        Import
      </button>
      <button appButton type="button" class="btn btn-primary btn-sm" (buttonClick)="onInsert()"
              data-cy="psd-readings-add-row">
        Add Row
      </button>
      <button appButton type="button" class="btn btn-primary btn-sm" (buttonClick)="onDelete()"
              data-cy="psd-readings-delete-row">
        Delete Row(s)
      </button>
    </div>
  </div>
</ng-container>
