import { DataType } from '../../dto/data-storage';
import { ChartDataSourceType } from './dto/chart.dto';
import { ChartAxis } from './dto/chart-axis-property.dto';

export class ReportingHelpers {
  public static getAxisForEvaluateAnimationCharts(dataType: DataType, chartDataSourceType: ChartDataSourceType): ChartAxis {
    if (chartDataSourceType === ChartDataSourceType.ChartSourceFluidProPressureAndECD) {
      switch (dataType) {
        case DataType.Pump_Pressure:
          return ChartAxis.PrimaryValue;
        case DataType.Heel_Pressure:
        case DataType.Toe_Pressure:
          return ChartAxis.SecondaryValue;
        case DataType.Pump_Rate:
          return ChartAxis.OppositePrimaryValue;
        case DataType.Heel_ECD:
        case DataType.Toe_ECD:
          return ChartAxis.OppositeSecondaryValue;
      }
    } else {
      switch (dataType) {
        case DataType.Pump_Pressure:
        case DataType.Return_Pressure:
          return ChartAxis.PrimaryValue;
        case DataType.Bottomhole_Pressure:
        case DataType.Section_Friction_Pressure:
          return ChartAxis.SecondaryValue;
        case DataType.Pump_Rate:
        case DataType.Return_Rate:
          return ChartAxis.OppositePrimaryValue;
        case DataType.Surface_Gravel_Concentration:
          return ChartAxis.OppositeSecondaryValue;
      }
    }

    return ChartAxis.SecondaryValue;
  }

  public static isFixedChartDataType(chartDataSourceType: ChartDataSourceType, dataType: DataType): boolean {
    switch (chartDataSourceType) {
      case ChartDataSourceType.ChartSourceResultsTimeBased:
        switch (dataType) {
          case DataType.Pump_Pressure:
          case DataType.Return_Pressure:
          case DataType.Toe_Pressure:
          case DataType.Toe_Fracture_Pressure:
          case DataType.Heel_Pressure:
          case DataType.Heel_Fracture_Pressure:
          case DataType.Pump_Rate:
          case DataType.Return_Rate:
          case DataType.Surface_Gravel_Concentration:
            return true;
        }
        return false;

      case ChartDataSourceType.ChartSourceEvaluatePressure:
        switch (dataType) {
          case DataType.Bottomhole_Pressure:
          case DataType.Pump_Pressure:
          case DataType.Return_Pressure:
          case DataType.Toe_Fracture_Pressure:
          case DataType.Heel_Fracture_Pressure:
          case DataType.Pump_Rate:
          case DataType.Return_Rate:
          case DataType.Surface_Gravel_Concentration:
            return true;
        }
        return false;

      case ChartDataSourceType.ChartSourceEvaluateFriction:
        switch (dataType) {
          case DataType.Section_Friction_Pressure:
          case DataType.Pump_Rate:
          case DataType.Return_Rate:
          case DataType.Surface_Gravel_Concentration:
            return true;
        }
        return false;

      case ChartDataSourceType.ChartSourceTrendAnalysis:
        switch (dataType) {
          case DataType.Pump_Pressure:
          case DataType.Return_Pressure:
          case DataType.Bottomhole_Pressure:
            return true;
        }
        return false;

      case ChartDataSourceType.ChartSourceFluidProPressureAndECD:
        switch (dataType) {
          case DataType.Pump_Pressure:
          case DataType.Heel_Pressure:
          case DataType.Toe_Pressure:
          case DataType.Pump_Rate:
          case DataType.Heel_ECD:
          case DataType.Toe_ECD:
            return true;
        }
        return false;
    }
    return false;
  }
}
