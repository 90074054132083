import { NgModule } from '@angular/core';
import { PagePanelModule } from '../../../common-modules/page-panel/page-panel.component';
import { CommonModule } from '@angular/common';
import { TabbedPageModule } from '../../../common-modules/tabbed-page/tabbed-page.component';
import { UnitsModule } from '../../../common-modules/units/units.module';
import { FluidSelectorModule } from '../../common/fluid-selector/fluid-selector.module';
import { GravelSelectorModule } from '../../common/gravel-selector/gravel-selector.module';
import { FormInputModule } from '../../../shared/components/form-components/form-input/form-input.component';
import { RadioGroupModule } from '../../../shared/components/form-components/radio-group/radio-group.component';
import { UnwrapDirectiveModule } from '../../../shared/directives/unwrap.directive';
import { ChartModule } from '../../../common-modules/chart/chart.module';
import { CheckBoxModule } from '../../../shared/components/form-components/check-box/check-box.component';
import { PsdSelectorModule } from '../common-components/psd-selector/psd-selector.component';
import { ModalService } from '../../../common-modules/modals/modal.service';
import { ScreenService } from '../../../shared/services';
import { PsdComparisonComponent } from './psd-comparison/psd-comparison.component';
import { SandProductionComponent } from './sand-production/sand-production.component';
import { PsdSelectionComponent } from './psd-comparison/psd-selection/psd-selection.component';
import { ConsolidatedPsdChartComponent } from './psd-comparison/consolidated-psd-chart/consolidated-psd-chart.component';
import { ConvertUnitPipe } from '@dunefront/common/modules/units/convert-unit.pipe/convert-unit.pipe';
import { SelectModule } from '../../../shared/components/form-components/select/select.module';
import { ButtonModule } from '../../../shared/components/button/button.module';
import { ConsolidatedPsdChartRendererComponent } from './psd-comparison/consolidated-psd-chart-renderer/consolidated-psd-chart-renderer.component';

@NgModule({
  declarations: [
    PsdComparisonComponent,
    SandProductionComponent,
    PsdSelectionComponent,
    ConsolidatedPsdChartComponent,
    ConsolidatedPsdChartRendererComponent,
  ],
  imports: [
    PagePanelModule,
    CommonModule,
    TabbedPageModule,
    UnitsModule,
    SelectModule,
    FluidSelectorModule,
    GravelSelectorModule,
    FormInputModule,
    RadioGroupModule,
    UnwrapDirectiveModule,
    ChartModule,
    CheckBoxModule,
    PsdSelectorModule,
    ButtonModule,
  ],
  providers: [ModalService, ScreenService, ConvertUnitPipe],
})
export class PSDAnalysisModule {}
