import { AfterViewInit, Directive, ElementRef, NgModule, OnDestroy } from '@angular/core';

@Directive({
  selector: '[appEqualizeSymbols]',
})
export class EqualizeSymbolsDirective implements AfterViewInit, OnDestroy {
  private observer: MutationObserver;

  constructor(private el: ElementRef) {
    this.observer = new MutationObserver(() => {
      // temporarily disconnect observer in order to prevent getting triggered by changed made in setUnitsWidth
      this.disconnectObserver();

      // apply changes
      this.setUnitsWidth();

      // reconnect observer
      this.connectObserver();
    });
  }

  public ngOnDestroy(): void {
    this.observer.disconnect();
  }

  public ngAfterViewInit(): void {
    this.setUnitsWidth();

    this.connectObserver();
  }

  private disconnectObserver(): void {
    this.observer.disconnect();
  }

  private connectObserver(): void {
    this.observer.observe(this.el.nativeElement, {
      attributes: true,
      subtree: true,
      childList: false,
    });
  }

  private setUnitsWidth(): void {
    // get all unit labels in current element
    const symbols = <HTMLElement[]>Array.from(this.el.nativeElement.querySelectorAll('.form-component-symbol'));

    // find the longest one
    const newWidth = Math.max(...symbols.map((el) => el.querySelector('span')?.offsetWidth ?? 0));

    // set its length to each of those
    symbols.forEach((el) => (el.style.width = `${newWidth}px`));
  }
}

@NgModule({
  declarations: [EqualizeSymbolsDirective],
  exports: [EqualizeSymbolsDirective],
})
export class EqualizeSymbolsDirectiveModule {}
