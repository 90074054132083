import { ErrorHandler, Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class ClientErrorHandlerService implements ErrorHandler {
  private sentryErrorHandler = Sentry.createErrorHandler({ showDialog: false });

  private errorSubject = new Subject<IErrorState | null>();

  public handleError(error: unknown, showError = true): void {
    try {
      if (showError) {
        const errorState = this.buildErrorState(error);
        this.setError(errorState);
      }
      this.sentryErrorHandler.handleError(error);
    } finally {
      console.error(error);
    }
  }

  public handleStoreError(error: unknown): void {
    try {
      const errorState = this.buildErrorState(error);
      if (errorState.customHandle) {
        return;
      }
      this.setError(errorState);

      if (errorState.status !== 'handled') {
        Sentry.withScope((scope) => {
          scope.setExtra('error', { error });
          Sentry.captureMessage(`Server Error: ${errorState.message}`, 'error');
        });
      }
    } finally {
      console.error(error);
    }
  }

  private buildErrorState(error: any): IErrorState {
    const message = error.message ? error.message : JSON.stringify(error);
    const stack = error.stack ?? '';
    let status = error.status ?? 'unexpected';
    const type = error.type || '';

    if (error.isHandled) {
      status = 'handled';
    }

    return { message, status, type, stack, dialogTitle: error.dialogTitle ?? '', customHandle: error.customHandle };
  }

  private setError(error: IErrorState | null): void {
    this.errorSubject.next(error);
  }

  public clearError(): void {
    this.setError(null);
  }

  public getError(): Observable<IErrorState | null> {
    return this.errorSubject.asObservable();
  }
}

export interface IErrorState {
  status: string | 'handled' | 'unexpected' | 'backend' | 'undo-redo';
  message: string;
  customHandle?: string;
  dialogTitle?: string;
  stack?: string;
  type?: string;
}
