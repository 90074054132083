import { ColumnType, IGridColumnConfig } from '../../shared/components/grid/grid.interfaces';
import { DictionaryWithArray, IDictionaryWithArray } from '@dunefront/common/common/state.helpers';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { EquationPreviewRow, evaluateEquationResult, IEquationVariable } from '@dunefront/common/modules/equation/equation-variable.dto';
import { ImportColumnDto } from '@dunefront/common/modules/data-storage/dto/import-column.dto';
import { createTableRow, createTableState, ITableState } from '@dunefront/common/common/common-grid.interfaces';
import { EquationColumn, IEquation } from '@dunefront/common/modules/equation/equation-column';
import { UnitConverterHelper } from '@dunefront/common/unit-converters/unit.converter.helper';
import { EquationPreviewRowWithResult } from '@dunefront/common/modules/equation/equation-module.state';

export class EquationSelectorsHelper {
  public static createPreviewDataSourceColumns(
    variables: ITableState<IEquationVariable>,
    columns: IDictionaryWithArray<ImportColumnDto[]>,
    equationColumn: EquationColumn,
  ): IGridColumnConfig<EquationPreviewRow>[] {
    const getUnitSystem = (variable: IEquationVariable): UnitSystem => {
      const column = (DictionaryWithArray.get(columns, variable.FileId) ?? []).find((col) => col.Id === variable.ColId);
      if (!column || column.Unit === -1 || column.UnitSystem === -1) {
        return UnitSystem.None;
      }
      return column.UnitSystem;
    };

    return [
      { disabled: false, visible: true, colId: ' ', type: ColumnType.selection },
      ...variables.rows
        .filter((variable) => variable.isValid)
        .map((variable) => ({
          disabled: true,
          visible: true,
          colId: variable.rowData.Name,
          headerText: variable.rowData.Name,
          type: ColumnType.number,
          width: 150,
          decimalPlaces: 3,
          isFillDownDisabled: true,
          overrideUnitType: variable.rowData.Unit,
          unitSystem: getUnitSystem(variable.rowData),
        })),
      {
        disabled: true,
        visible: true,
        colId: 'Result',
        type: ColumnType.number,
        decimalPlaces: 3,
        headerText: 'Result',
        overrideUnitSymbol: UnitConverterHelper.getUnitConverter(equationColumn.UnitSystem).getSymbol(equationColumn.Unit),
      },
    ];
  }

  public static createDataSourcePreview(
    preview: EquationPreviewRow[],
    equation: IEquation,
    allColumns: ImportColumnDto[],
    isValid: boolean,
  ): ITableState<EquationPreviewRowWithResult> {
    // create map - it's much faster that filtering by array
    const columnsByColId = new Map(allColumns.map((col) => [col.Id, col]));

    const rows = isValid
      ? preview.map((row, idx) => {
          const Result = evaluateEquationResult(row, equation, columnsByColId);
          return createTableRow({ ...row, Result }, 'data', idx);
        })
      : [];
    return createTableState(rows);
  }
}
