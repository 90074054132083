import { getSimulateMenuValidation } from '../simulate-evaluate-page/simulate-evaluate-page.validators';
import { createSelector } from '@ngrx/store';
import { ILeftNavElement, LeftNavHelpers } from '../../shared/components/left-nav/left-nav.helpers';
import { dataChartName, dataGridName, dataPageName } from './gauge-data-page-consts';
import { RouteModuleData, RouteModuleDataChart, RouteModuleDataGrid } from './gauge-data-routes-names';
import { getGaugeDataLeftNavData } from '../../+store/gauge-data/gauge-data.selectors';

export const getGaugeDataLeftNavElement = createSelector(
  getSimulateMenuValidation,
  getGaugeDataLeftNavData,
  (isValid, data): ILeftNavElement => {
    return LeftNavHelpers.getNavElement(dataPageName, RouteModuleData, 'icon-speed-dashboard', isValid.isGaugeDataPageValid, false, [
      LeftNavHelpers.getItem(dataGridName, `${RouteModuleData}/${RouteModuleDataGrid}/${data.selectedFileId}`, data.isGaugeDataPageValid),
      LeftNavHelpers.getItem(dataChartName, `${RouteModuleData}/${RouteModuleDataChart}`),
    ]);
  },
);
