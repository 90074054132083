<div class="wrapper" #wrapper [class.column-properties-hidden]="!selectedColumn || noValueColumn">
  <ng-container *ngIf="!storageFiles.ids.length">
    <app-no-gauge-data-message class="row-span-4"></app-no-gauge-data-message>
  </ng-container>
  <ng-container *ngIf="storageFiles.ids.length">
    <app-data-file-selector [selectedFileId]="state.SelectedFileId" [files]="storageFiles"></app-data-file-selector>
    <app-page-panel header="Data" [panelHelpMode]="PanelHelpMode.GRID">
      <app-gauge-data-grid
        #gridComponent
        [state]="state"
        [selectedCellIndex]="selectedColumnCellIndex"
        [noValueColumn]="noValueColumn"
        (selectedCellIndexChanged)="onSelectedCellIndexChanged($event)"
        (click)="onDataPageHelpChange('data')"
      ></app-gauge-data-grid>
    </app-page-panel>
    <app-page-panel *ngIf="selectedColumn && !noValueColumn"
                    header="Column Properties"
                    (click)="onDataPageHelpChange('column-properties')"
                    appOnResize
                    (elResize)="wrapper.classList.toggle('smaller', $event.width <= 1035)"
    >
      <app-gauge-data-column-props [selectedColumn]="selectedColumn"></app-gauge-data-column-props>
    </app-page-panel>
    <div class="buttons-panel">
      <div class="align-left">
        <p-menu #dataMenu [model]="dataMenuItems" [popup]="true" [appendTo]="'body'"></p-menu>
        <button
          appButton
          data-cy="btn-data-component-data"
          type="button"
          class="btn btn-primary btn-sm"
          [isDeleteResultsEnabled]="false"
          (click)="toggleDataMenu($event)"
          [isMenuButton]="true"
          [disabled]="!selectedColumn"
        >
          Data
        </button>

        <p-menu #recalculateMenu [model]="recalculateMenuItems" [popup]="true" [appendTo]="'body'"></p-menu>
        <button
          appButton
          data-cy="btn-data-component-recalculate"
          type="button"
          *ngIf="isEquationResultFile || isAtLeastOneEquationResultFile"
          class="btn btn-primary btn-sm"
          [isDeleteResultsEnabled]="false"
          (click)="toggleRecalculateMenu($event)"
          [isMenuButton]="true"
          [disabled]="!selectedColumn"
        >
          Recalculate
        </button>
      </div>
      <div class="align-right">
        <p-menu #fileMenu [model]="fileMenuItems" [popup]="true" [appendTo]="'body'"></p-menu>
        <button
          appButton
          data-cy="btn-data-component-file"
          type="button"
          [isDeleteResultsEnabled]="false"
          class="btn btn-primary btn-sm"
          (click)="toggleFileMenu($event)"
          [isMenuButton]="true"
        >
          File
        </button>

        <p-menu #columnMenu [model]="columnMenuItems" [popup]="true" [appendTo]="'body'"></p-menu>
        <button
          appButton
          data-cy="btn-data-component-column"
          type="button"
          [isDeleteResultsEnabled]="false"
          class="btn btn-primary btn-sm"
          (click)="toggleColumnMenu($event)"
          [isMenuButton]="true"
          [disabled]="!selectedColumn"
        >
          Column
        </button>
      </div>
    </div>
  </ng-container>
</div>
