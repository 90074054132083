import { Injectable, NgZone } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  aboutServerAction,
  aboutServerSuccessAction,
  checkForUpdatesAction,
  checkForUpdatesSuccessAction,
  fetchDownloadUrl,
  fetchDownloadUrlSuccess,
  fetchLicenseSessions,
  fetchLicenseSessionsSuccess,
  killLicenseSession,
  performPeriodicVersionCheck,
} from './about.actions';
import { combineLatestWith, filter, map, mergeMap, take } from 'rxjs/operators';
import {
  AboutModuleName,
  AboutServerAction,
  CheckForUpdatesAction,
  FetchDownloadLink,
  FetchLicenseSessions,
  GetLastPeriodicVersionCheck,
  KillLicenseSessions,
} from '@dunefront/common/modules/about/about-module.actions';
import { AboutServerDto } from '@dunefront/common/dto/about-server.dto';
import { BaseWsEffects } from '../base-ws.effects';
import { Store } from '@ngrx/store';
import { BackendConnectionService } from '../../shared/backend-connection/backend-connection.service';
import { ModalService } from '../../common-modules/modals/modal.service';
import { backendConnectedAction } from '../backend-connection/backend-connection.actions';
import { UpdateInfoDto } from '@dunefront/common/dto/update.dto';
import { cmpVersions, getLatestAppVersion, isMoreThanDayDiff } from './about.effect.helper';
import { BuildInfo } from '@dunefront/common/common/build-info';
import { CheckForUpdatesComponent } from '../../common-modules/modals/check-for-updates/check-for-updates.component';
import { LicenseWithSessionsInfo } from '@dunefront/common/modules/licensing/licensing.interfaces';
import { getIsAnyFeatureUsableFactory } from '../licensing/licensing.selectors';
import { firstValueFrom } from 'rxjs';
import { AppTargetConfig } from '../../shared/services/app-target-config';

@Injectable()
export class AboutEffects extends BaseWsEffects {
  public clientBuildInfo = BuildInfo;

  constructor(
    actions$: Actions,
    store: Store,
    wsService: BackendConnectionService,
    modalService: ModalService,
    protected zone: NgZone,
    private appTargetConfig: AppTargetConfig,
  ) {
    super(actions$, wsService, AboutModuleName, false, false, modalService, store);
  }

  private backendConnected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(backendConnectedAction),
      map(() => aboutServerAction()),
    ),
  );

  public getAboutServerAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(aboutServerAction),
      mergeMap(() =>
        this.emit<AboutServerDto>(new AboutServerAction()).pipe(
          map((result) => aboutServerSuccessAction({ aboutServerResult: result.payload })),
        ),
      ),
    ),
  );

  public checkForUpdatesAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(checkForUpdatesAction),
      mergeMap((action) =>
        this.emit<UpdateInfoDto>(new CheckForUpdatesAction()).pipe(
          map((result) => {
            const versions = result.payload?.versions ?? [];
            if (action.isPeriodicCheck && versions.length) {
              const latestAvailable = getLatestAppVersion([...versions]);
              const versionDiff = cmpVersions(this.clientBuildInfo.version, latestAvailable.version);
              if (versionDiff < 0) {
                this.modalService.open(CheckForUpdatesComponent, { updateInfo: result.payload });
              }
            }
            return checkForUpdatesSuccessAction({ updateInfo: result.payload });
          }),
        ),
      ),
    ),
  );

  public fetchDownloadLinkAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchDownloadUrl),
      mergeMap((action) =>
        this.emit<string>(new FetchDownloadLink(action.version)).pipe(
          map((result) => {
            return fetchDownloadUrlSuccess({ url: result.payload });
          }),
        ),
      ),
    ),
  );

  public fetchLicenseSessions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchLicenseSessions),
      mergeMap((action) =>
        this.emit<LicenseWithSessionsInfo[]>(new FetchLicenseSessions(action.licenseId)).pipe(
          map((result) => fetchLicenseSessionsSuccess({ response: result.payload })),
        ),
      ),
    ),
  );

  public killLicenseSession$ = createEffect(() =>
    this.actions$.pipe(
      ofType(killLicenseSession),
      mergeMap((action) =>
        this.emit<LicenseWithSessionsInfo[]>(new KillLicenseSessions(action.licenseId, [action.sessionId])).pipe(
          map((result) => fetchLicenseSessionsSuccess({ response: result.payload })),
        ),
      ),
    ),
  );

  public performPeriodicVersionCheck$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(performPeriodicVersionCheck),
        combineLatestWith(
          this.store
            .select(getIsAnyFeatureUsableFactory(this.appTargetConfig.appName))
            .pipe(filter((isAnyLicenseAvailable) => isAnyLicenseAvailable)),
        ),
        take(1),
        map(async () => {
          const lastCheck = await firstValueFrom(this.emit<string>(new GetLastPeriodicVersionCheck()));
          const shouldCheck = isMoreThanDayDiff(parseInt(lastCheck.payload) || null);

          if (shouldCheck) {
            this.store.dispatch(checkForUpdatesAction({ isPeriodicCheck: true }));
          }
        }),
      ),
    { dispatch: false },
  );
}
