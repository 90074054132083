import { IDefaultSeriesColors, IEffectiveSeriesStyle, IGlobalSeriesStyles } from '@dunefront/common/modules/reporting/dto/chart-series.dto';
import { MarkerShape } from '@dunefront/common/modules/reporting/dto/chart-marker.dto';
import { SeriesLineStyle } from '@dunefront/common/modules/reporting/dto/chart.types';
import { IChartDataDto, IChartDataDtoColumn, LineChartDataSet } from '@dunefront/common/modules/reporting/dto/chart-data.dto';
import { areStringsTheSame } from '@dunefront/common/common/helpers';
import { ChartContext } from './chart-types';
import { Chart } from 'chart.js';
import { CrosshairDataset } from '../crosshair-plugin/types';
import { defaultChartSeriesColours } from '@dunefront/common/common/constants';
import { ChartSeriesTemplateDto } from '@dunefront/common/dto/chart-series-template.dto';
import { DataType } from '@dunefront/common/dto/data-storage';
import { getLineStyle } from './chart-misc-helpers';

const grayedOutSeriesColour = '#0003';

export const getUniqueColumnNames = (chartData: IChartDataDto): string[] =>
  Array.from(new Set(chartData.ChartDataColumns.map((column) => column.Name)));

export type ChartDataSeriesLineStyleFormat = 'dash' | 'dot';

export class ChartDataSeriesStyleHelpers {
  private readonly compareScenarioLineStyles: ('dash' | 'dot')[][] = [
    ['dot'],
    ['dash'],
    ['dash', 'dot'],
    ['dash', 'dash', 'dot', 'dot'],
    ['dash', 'dash', 'dot', 'dot', 'dot', 'dash'],
    ['dash', 'dash', 'dot', 'dot', 'dot', 'dot', 'dash', 'dash'],
    ['dash', 'dash', 'dot', 'dot', 'dot', 'dot', 'dot', 'dash', 'dash', 'dash'],
    ['dash', 'dash', 'dot', 'dot', 'dot', 'dot', 'dot', 'dot', 'dash', 'dash', 'dash', 'dash'],
    ['dash', 'dash', 'dot', 'dot', 'dot', 'dot', 'dot', 'dot', 'dot', 'dash', 'dash', 'dash', 'dash', 'dash'],
  ];

  public setDataSeriesStyle(
    chartContext: ChartContext,
    chart: Chart | undefined,
    chartData: IChartDataDto,
    sizeMultiplier: number,
    grayedOutColumnIds: Set<number> | null,
  ): void {
    const { defaultSeriesStyles, chartSeriesTemplates } = chartContext;

    if (chart == null || defaultSeriesStyles == null) {
      return;
    }

    for (let index = 0; index < chart.data.datasets.length; index++) {
      const dataset = chart.data.datasets[index] as LineChartDataSet & CrosshairDataset;
      const column = chartData.ChartDataColumns[index];
      const isGrayedOut = grayedOutColumnIds != null && column.ColumnId != null ? grayedOutColumnIds.has(column.ColumnId) : false;
      const seriesStyle = this.getSeriesStyle(index, chartData, defaultSeriesStyles, chartSeriesTemplates);
      const seriesColor = isGrayedOut ? grayedOutSeriesColour : seriesStyle.SeriesColour;

      this.setSeriesColour(dataset, seriesColor);
      this.setPointStyles(dataset, seriesStyle, sizeMultiplier);
      this.setLineStyle(
        dataset,
        seriesStyle,
        sizeMultiplier,
        chartData.ChartDataColumns,
        index,
        chartContext.scenariosToCompare.scenarioIds,
        seriesStyle,
      );
    }
  }

  public setSeriesColour(dataset: LineChartDataSet, color: string): void {
    dataset.backgroundColor = color;
    dataset.borderColor = color;
    dataset.pointBackgroundColor = color;
    dataset.pointBorderColor = color;
  }

  public setLineStyle(
    dataset: LineChartDataSet,
    style: IEffectiveSeriesStyle | null,
    sizeMultiplier: number,
    columns: IChartDataDtoColumn[],
    columnIndex: number,
    scenarioIdsToCompare: number[],
    seriesStyle: IEffectiveSeriesStyle,
  ): void {
    const column = columns[columnIndex];
    dataset.borderWidth = (style?.SeriesLineThickness ?? 2) * sizeMultiplier;
    const isCompareScenarioOrOptimizeMode = scenarioIdsToCompare.length > 0 || columns.some((col) => col.AddSimulateToEvaluate);
    if (style?.SeriesLineStyle !== SeriesLineStyle.scatter) {
      dataset.backgroundColor = 'transparent';
    }
    if (style?.SeriesLineStyle === SeriesLineStyle.scatter) {
      if (!isCompareScenarioOrOptimizeMode) {
        dataset.borderWidth = 0;
      }
    }

    if (isCompareScenarioOrOptimizeMode) {
      this.setSolidPointStyle(dataset);
    }

    dataset.borderDash = this.configureBorderDash(
      column,
      scenarioIdsToCompare,
      seriesStyle,
      sizeMultiplier,
      isCompareScenarioOrOptimizeMode,
    );
  }

  public configureBorderDash(
    column: IChartDataDtoColumn,
    scenarioIdsToCompare: number[],
    seriesStyle: IEffectiveSeriesStyle,
    sizeMultiplier: number,
    isCompareScenarioOrOptimizeMode: boolean,
  ): number[] {
    let format: ChartDataSeriesLineStyleFormat[] | null = null;
    if (column.AddSimulateToEvaluate) {
      // Optimize child column
      format = ['dot'];
    } else if (column.ScenarioId != null && scenarioIdsToCompare.includes(column.ScenarioId)) {
      // Compare Scenarios child column
      format = this.compareScenarioLineStyles[scenarioIdsToCompare.indexOf(column.ScenarioId)];
    } else if (isCompareScenarioOrOptimizeMode) {
      // Optimize or Compare Scenarios primary column
      format = [];
    } else if (seriesStyle.SeriesLineStyle === SeriesLineStyle.dashes) {
      format = ['dash'];
    } else if (seriesStyle.SeriesLineStyle === SeriesLineStyle.dots) {
      format = ['dot'];
    }

    return getLineStyle(format, (seriesStyle.SeriesLineThickness ?? 2) * sizeMultiplier);
  }

  public setSolidPointStyle(dataset: LineChartDataSet): void {
    const pointRadius = 0;
    dataset.pointRadius = pointRadius;
    dataset.pointHoverRadius = pointRadius;
    dataset.pointHitRadius = pointRadius;
    dataset.pointBorderWidth = pointRadius;
    dataset.pointHoverBorderWidth = pointRadius + 1;
    dataset.pointStyle = MarkerShape[MarkerShape.circle];
  }

  public setPointStyles(dataset: LineChartDataSet, style: IEffectiveSeriesStyle | null, sizeMultiplier: number): void {
    const pointRadius = style?.SeriesPointMarkersEnabled ? style?.SeriesPointMarkersSize * sizeMultiplier : 0;
    const pointBorderWidth = style?.SeriesPointMarkersEnabled ? 2 * sizeMultiplier : 0;
    dataset.pointRadius = pointRadius;
    dataset.pointHoverRadius = pointRadius;
    dataset.pointHitRadius = pointRadius;
    dataset.pointBorderWidth = pointBorderWidth;
    dataset.pointHoverBorderWidth = pointBorderWidth + 1;
    dataset.pointStyle = MarkerShape[style?.SeriesPointMarkersShape ?? 0];
  }

  public getSeriesStyle(
    seriesIndex: number,
    chartData: IChartDataDto,
    defaultSeriesStyles: IGlobalSeriesStyles,
    chartSeriesTemplates: ChartSeriesTemplateDto[],
  ): IEffectiveSeriesStyle {
    const column = chartData.ChartDataColumns[seriesIndex];
    const template = this.getColumnSeriesTemplate(column, chartSeriesTemplates);

    const seriesColor = this.getSeriesColour(seriesIndex, chartData, defaultSeriesStyles, chartSeriesTemplates);

    // combined global series style with column style override
    const columnLineStyle = column.ColumnDefaultStyle?.lineStyle;
    const defaultColumnSeriesStyle = {
      ...defaultSeriesStyles,
      SeriesLineStyle: columnLineStyle ?? defaultSeriesStyles.SeriesLineStyle,
      SeriesPointMarkersEnabled: columnLineStyle === SeriesLineStyle.scatter ? true : defaultSeriesStyles.SeriesPointMarkersEnabled,
    };

    const style = template ?? defaultColumnSeriesStyle;

    return {
      SeriesLineStyle: style.SeriesLineStyle,
      SeriesColour: seriesColor,
      SeriesLineThickness: style.SeriesLineThickness,
      SeriesPointMarkersEnabled: style.SeriesPointMarkersEnabled,
      SeriesPointMarkersShape: style.SeriesPointMarkersShape,
      SeriesPointMarkersSize: style.SeriesPointMarkersSize,
    };
  }

  public getSeriesColour(
    seriesIndex: number,
    { ChartDataColumns }: IChartDataDto,
    defaultSeriesStyles: IGlobalSeriesStyles,
    chartSeriesTemplates: ChartSeriesTemplateDto[],
  ): string {
    const column = ChartDataColumns[seriesIndex];

    // if there is custom user defined template - it takes priority over others
    const template = this.getColumnSeriesTemplate(column, chartSeriesTemplates);
    if (template != null) {
      return template.SeriesColour;
    }

    // if series data type has defined colour - use it as next
    const dataTypeBasedSeriesColor = this.getDataTypeSeriesColor(column.DataType, defaultSeriesStyles);
    if (dataTypeBasedSeriesColor != null) {
      return dataTypeBasedSeriesColor;
    }

    const globalColours = defaultSeriesStyles.SeriesColours?.split(',') ?? [];
    const indexBasedColours =
      globalColours.length && globalColours.length >= defaultChartSeriesColours.length ? globalColours : defaultChartSeriesColours;

    // list of unique column names that their colors are not data type based
    const indexBasedUniqueColumnNames = this.nonDataTypeBasedColumnNames(ChartDataColumns, defaultSeriesStyles);

    const colorsCount = Math.min(indexBasedUniqueColumnNames.length, indexBasedColours.length);
    const columnIndex = indexBasedUniqueColumnNames.indexOf(column.Name);

    return indexBasedColours[columnIndex % colorsCount];
  }

  private nonDataTypeBasedColumnNames(columns: IChartDataDtoColumn[], colors: IDefaultSeriesColors): string[] {
    const result = new Set<string>();

    for (const column of columns) {
      if (!this.getDataTypeSeriesColor(column.DataType, colors)) {
        result.add(column.Name);
      }
    }

    return Array.from(result);
  }

  private getColumnSeriesTemplate(column: IChartDataDtoColumn, templates: ChartSeriesTemplateDto[]): ChartSeriesTemplateDto | undefined {
    return templates?.find((tpl) => areStringsTheSame(tpl.ColumnName, column.Name));
  }

  private getDataTypeSeriesColor(seriesDataType: DataType, colors: IDefaultSeriesColors): string | undefined {
    switch (seriesDataType) {
      case DataType.Pump_Pressure:
        return colors.PumpPressureColor;

      case DataType.Pump_Rate:
        return colors.PumpRateColor;

      case DataType.Return_Rate:
        return colors.ReturnRateColor;

      case DataType.Surface_Gravel_Concentration:
      case DataType.Total_Perf_Pack_Volume:
        return colors.GravelConcColor;
    }

    return undefined;
  }
}
