<div class="grid-2-rows" *ngIf="psdTable$ | async as psdTable" (click)="onHelpChange('psd', 'psd-selection')">
  <app-page-panel header="Standalone Screens">
    <div class="grid-container">
      <app-standalone-screens-grid [psdTable]="psdTable"></app-standalone-screens-grid>
    </div>
  </app-page-panel>
  <app-page-panel header="Gravel Pack">
    <div class="grid-container">
      <app-gravel-pack-grid [psdTable]="psdTable"></app-gravel-pack-grid>
    </div>
  </app-page-panel>
</div>
