import { UnitConverter } from '../../converter.interfaces';
import { ConsistencyIndexUnit } from '../../../dto/unit-system.dto';

export class ConsistencyIndexConverter extends UnitConverter {
  public static symbols: Array<string> = ['', 'Pa·s^n', 'lbf·s^n/ft²'];

  public static unit = ConsistencyIndexUnit;

  public static fromSi(value: number, unit: ConsistencyIndexUnit): number {
    switch (unit) {
      case ConsistencyIndexUnit.Metric_consistency_index: {
        return value;
      }
      case ConsistencyIndexUnit.Oilfield_consistency_index: {
        return value * 2.088543e-2;
      }
      default: {
        throw new TypeError('Unknown Consistency Index Unit:' + unit);
      }
    }
  }

  public static toSi(value: number, unit: ConsistencyIndexUnit): number {
    switch (unit) {
      case ConsistencyIndexUnit.Metric_consistency_index: {
        return value;
      }
      case ConsistencyIndexUnit.Oilfield_consistency_index: {
        return value / 2.088543e-2;
      }
      default: {
        throw new TypeError('Unknown Consistency Index Unit:' + unit);
      }
    }
  }

  public static getSymbol(unit: ConsistencyIndexUnit): string {
    if (unit == null) {
      return '';
    }
    return this.symbols[unit];
  }
}
