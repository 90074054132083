import { createAction, props } from '@ngrx/store';
import { SpeedType } from '@dunefront/common/modules/fluid/dto/rheometer.dto';
import { Rheology } from '@dunefront/common/modules/fluid/model/rheology/rheology';
import { Rheometer } from '@dunefront/common/modules/fluid/model/rheometer/rheometer';
import { RheometerReading } from '@dunefront/common/modules/fluid/model/rheometer-reading/rheometer-reading';
import { Fluid } from '@dunefront/common/modules/fluid/model/fluid';
import { ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';
import { IDeleteRowsProps, IInsertRowsProps, IUpdateTableRowsProps } from '@dunefront/common/common/common-store-crud.interfaces';

// region fluid

//export const

export const selectFluidAction = createAction('[fluid] selectFluid', props<{ fluidId: number }>());

export const saveFluidToDatabase = createAction('[fluid] saveFluidToDatabase', props<{ fluidId: number }>());

export const newFluid = createAction('[fluid] newFluid', props<{ scenarioId: number }>());

export const copyFluid = createAction('[fluid] copyFluid', props<{ fluidId: number; scenarioId: number; fluidName: string }>());

export const deleteFluid = createAction('[fluid] deleteFluid', props<IDeleteRowsProps>());

export const updateFluid = createAction('[fluid] saveFluid', props<IFluidUpdate>());

// endregion

// region rheology grid

export const updateRheologyRow = createAction('[rheology] updateRow', props<IUpdateTableRowsProps<Rheology>>());

export const insertRheologyRow = createAction('[rheology] insertRows', props<IInsertRowsProps<Rheology>>());

export const deleteRheologyRows = createAction('[rheology] deleteRows', props<IDeleteRowsProps>());

// endregion

// region rheometer

export const selectTemperature = createAction('[fluid] selectTemperature', props<{ temperatureId: number }>());

export const updateRheometerRow = createAction('[rheometer] updateRow', props<IUpdateTableRowsProps<Rheometer>>());

export const insertRheometerRow = createAction(
  '[rheometer] insertRows',
  props<{
    fluidId: number;
    scenarioId: number;
    temperature: number;
    speedType: SpeedType;
  }>(),
);

export const deleteRheometerRows = createAction('[rheometer] deleteRows', props<IDeleteRowsProps>());

// endregion

// region rheometer readings grid

export const updateRheometerReadingRow = createAction('[rheometer-reading] updateRow', props<IUpdateTableRowsProps<RheometerReading>>());

export const insertRheometerReadingRow = createAction('[rheometer-reading] insertRows', props<IInsertRowsProps<RheometerReading>>());

export const deleteRheometerReadingRows = createAction('[rheometer-reading] deleteRows', props<IDeleteRowsProps>());

// endregion

export const recalculateRheologies = createAction(
  '[fluid] recalculateRheologies',
  props<{
    shearRate: number;
    fluidId?: number;
  }>(),
);

export interface IFluidUpdate {
  changedProp: ObjectChangeProp<Fluid>;
  fluidId: number;
  shouldSaveRheologies: boolean;
}
