import { CommonModule } from '@angular/common';
import { EnvironmentProviders, ModuleWithProviders, NgModule } from '@angular/core';
import { ElectronAuthModule } from './electron.auth.module';
import { AuthGuard, AuthHttpInterceptor, AuthModule, AuthService } from '@auth0/auth0-angular';
import { HttpInterceptorConfig } from '@auth0/auth0-angular/lib/auth.config';
import { Auth0ClientOptions } from '@auth0/auth0-spa-js';
import { AdminAuthGuard } from './guards/admin-auth.guard';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { isElectron } from '@dunefront/common/common/electron/is-electron';

@NgModule({ imports: [CommonModule, BrowserModule], providers: [provideHttpClient(withInterceptorsFromDi())] })
export class ClientAuthModule {
  public static forRoot(
    envAuth: Auth0ClientOptions | undefined,
    httpInterceptorConfig: HttpInterceptorConfig,
  ): ModuleWithProviders<ClientAuthModule> {
    if (isElectron() || envAuth == null) {
      console.warn('Using Electron Auth Module!');
      return ElectronAuthModule.forRoot();
    }

    const authModule = AuthModule.forRoot({
      ...envAuth,
      httpInterceptor: {
        ...httpInterceptorConfig,
      },
      cacheLocation: 'localstorage',
    });
    const providers = [
      AuthGuard,
      AdminAuthGuard,
      AuthService,
      {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthHttpInterceptor,
        multi: true,
      },
    ];

    authModule.providers = [...(authModule.providers as EnvironmentProviders[]), providers];
    return authModule;
  }
}
