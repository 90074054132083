import { PumpingScheduleDto } from '../pumping/dto/pumping-schedule.dto';

export class ScenarioConstants {
  public static readonly EmptyScenarioId = 0;
  public static readonly AllScenarioId = -1; // used in ws notifications for actions that are scenario independent (like custom settings)
}

export interface ScenarioDto {
  Id: number;
  Name: string;
  ModuleType: ModuleType | null;
  CurrentRangeId: number;
  CompareScenarioConfig: string | null | undefined;
  SortOrder: number;
}

export enum ModuleType {
  Simulate = 0,
  Evaluate = 1,
  PSD_Analysis_Full = 2,
  Friction_Calc = 3,
  Settling_Calc = 4,
  Resuspension_Calc = 5,
  Injection_Test_Calc = 6,
  MASP_Calc = 7,
  Leakoff_Coefficient_Calc = 8,
  Simulate_Schedule_Generator = 9,
  Evaluate_Schedule_Generator = 10,
  Trend_Analysis = 11,
  Well_Fluid_MD_To_Volume = 12,
  Well_Fluid_Volume_To_MD = 13,
  Zone_Depth = 14,
  Zone_Pore_And_Frac = 15,
  Get_Engine_Options_Defaults = 16,
  Merge_Import_Data = 17,
  PSD_Analysis_Basic = 18,
  Simulate_Advanced_Validation = 19,
  Evaluate_Advanced_Validation = 20,
  Trend_Analysis_Advanced_Validation = 21,
  Import_Temperature_Profile = 22,
  Reference_Variable_Calc = 23,
  Single_Section_Volume_Calc = 24,
  Multiple_Section_Volume_Calc = 25,
  Export_Data = 26,
  Licensing_Products_And_Features = 27,
  Licensing_Login = 28,
  Licensing_Logout = 29,
  Licensing_CheckSessions = 30,
  Licensing_GetSessions = 31,
  Licensing_KillSessions = 32,
  Licensing_Detach = 33,
  Licensing_CancelDetach = 34,
  Calculators_Generic = 40,
  Simulate_CH = 41,
  Simulate_Disp = 42,
  Evaluate_Disp = 43,
  Data_Analysis = 99,
  Test = 100,
  Error = 101,
  None = 102,
  Sentry_Ignored_Error = 103,
  Test_ShortWorker_Sleep = 104,
  Ping_Pong = 999,
}

export const LONG_CALCULATION_WITH_SIMULATION_RESULTS_MODULE_TYPES = [
  ModuleType.Simulate,
  ModuleType.Simulate_CH,
  ModuleType.Simulate_Disp,
  ModuleType.Evaluate,
];
const LONG_CALCULATION_MODULE_TYPES = [...LONG_CALCULATION_WITH_SIMULATION_RESULTS_MODULE_TYPES, ModuleType.Trend_Analysis];

export const CALCULATOR_MODULE_TYPES = [
  ModuleType.MASP_Calc,
  ModuleType.Settling_Calc,
  ModuleType.Single_Section_Volume_Calc,
  ModuleType.Multiple_Section_Volume_Calc,
  ModuleType.Reference_Variable_Calc,
  ModuleType.Resuspension_Calc,
  ModuleType.Leakoff_Coefficient_Calc,
  ModuleType.Friction_Calc,
  ModuleType.Injection_Test_Calc,
  ModuleType.Calculators_Generic,
];

export enum EntityToCopy {
  PSDAnalysis = 0,
  GeneralData = 1,
  CasingData = 2,
  ReservoirData = 3,
  CaliperData = 4,
  LowerCompletion = 5,
  RunningString = 6,
  ShuntTube = 7,
  Volumes = 8,
  ProjectFluid = 9,
  ProjectGravel = 10,
  InitialData = 11,
  WellFluids = 12,
  Schedule = 13,
  ScheduleOptions = 14,
  EngineOptions = 15,
  FrictionCalculator = 16,
  SettlingCalculator = 17,
  ResuspensionCalculator = 18,
  MaspCalculator = 19,
  InjectionTestCalculator = 20,
  LeakoffCoefficientCalculator = 21,
  Report = 22,
  Charts = 23,
  Default = 99,
}

export interface IEntitiesExtras {
  evaluateSchedule?: PumpingScheduleDto[];
}

export interface DeleteResultsFilter {
  moduleTypes?: ModuleType[];
  scenarioIds?: number[];
  rangeIds?: number[];
}

export const isSimulateBased = (moduleType: ModuleType): boolean =>
  moduleType === ModuleType.Simulate || moduleType === ModuleType.Simulate_CH || moduleType === ModuleType.Simulate_Disp;

export const isDataBased = (moduleType: ModuleType): boolean => {
  return moduleType === ModuleType.Evaluate || moduleType === ModuleType.Trend_Analysis || moduleType === ModuleType.Data_Analysis;
};

export const isLongCalculationModuleType = (moduleType: ModuleType): boolean => LONG_CALCULATION_MODULE_TYPES.includes(moduleType);
export const isLongCalculationWithSimulateResultModuleType = (moduleType: ModuleType): boolean =>
  LONG_CALCULATION_WITH_SIMULATION_RESULTS_MODULE_TYPES.includes(moduleType);
export const isCalculatorModuleType = (moduleType: ModuleType): boolean => CALCULATOR_MODULE_TYPES.includes(moduleType);
