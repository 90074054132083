<app-page-panel
  header="Test Data"
  *ngIf="selectedFluidData && selectedFluidData.rheometer"
  [allowFullScreen]="false"
  [panelHelpMode]="PanelHelpMode.GRID"
>
  <div class="page-container">
    <div class="buttons-panel">
      <div class="align-left">
        <app-form-input
          label="Temperature"
          [source]="selectedFluidData.rheometer"
          key="Temperature"
          [decimalPlaces]="2"
          [unitType]="UnitType.Temperature"
          (valueChanged)="emitRheometerValue($event)"
        >
        </app-form-input>
      </div>
    </div>

    <div class="grid-and-chart-panel">
      <app-rheometer-test-data-grid
        #gridComponent
        [rheometerReadingData]="selectedFluidData.rheometerReading"
        [fluid]="selectedFluidData.fluid"
        [rheometer]="selectedFluidData.rheometer"
      >
      </app-rheometer-test-data-grid>
    </div>

    <div class="buttons-panel">
      <div class="align-left">
        <button
          appButton
          type="button"
          class="btn btn-primary btn-sm"
          data-cy="rheometer-test-data-import"
          (buttonClick)="onImport()"
        >
          Import Reading
        </button>
        <button
          appButton
          type="button"
          class="btn btn-primary btn-sm"
          (buttonClick)="onDelete()"
          [disabled]="selectedFluidData.rheometer.rowData.SpeedType !== SpeedType.Custom"
        >
          Delete Reading
        </button>
      </div>
    </div>
  </div>
</app-page-panel>

<app-page-panel header="Test Data Chart" [panelHelpMode]="PanelHelpMode.CHART">
  <div class="page-container">
    <div class="align-right">
      <app-check-box
        [source]="selectedFluidData.rheometer.rowData"
        [key]="'IsShuntRefCurveShown'"
        [isUiLockable]="false"
        label="Show Shunt Target Rheology (IPTC 12549)"
        (valueChanged)="emitRheometerValue($event)"
      >
      </app-check-box>
    </div>

    <app-rheometer-test-data-chart></app-rheometer-test-data-chart>

    <div class="align-right" *ngIf="rheology">
      <div class="props-panel">
        <app-form-input
          label="R&#xb2;"
          class="ml-auto"
          [disabled]="true"
          [readOnlyValue]="rheology.RSquared"
          [decimalPlaces]="3"
          [unitType]="UnitType.None"
        >
        </app-form-input>
        <app-form-input label="n'" [disabled]="true" [readOnlyValue]="rheology.NPrime" [decimalPlaces]="4" [unitType]="UnitType.None">
        </app-form-input>
        <app-form-input
          label="k'"
          [disabled]="true"
          [readOnlyValue]="rheology.KPrime"
          [decimalPlaces]="6"
          [unitType]="UnitType.Consistency_Index"
        >
        </app-form-input>
      </div>
    </div>
  </div>
</app-page-panel>
