import { createReducer, on } from '@ngrx/store';
import { GaugeDataModuleState, initialGaugeDataModuleState } from './gauge-data-module.state';
import { loadGaugeDataAction, loadGaugeDataSuccess, selectGaugeDataFileAction } from './gauge-data.actions';
import { GaugeDataReducerHelper } from './gauge-data.reducer.helper';
import { deleteRowsSuccess, insertRowsSuccess, loadScenarioDataSuccessAction, resetScenarioState } from '../app.actions';

export const gaugeDataModuleReducer = createReducer<GaugeDataModuleState>(
  initialGaugeDataModuleState,
  on(resetScenarioState, (): GaugeDataModuleState => ({ ...initialGaugeDataModuleState, isLoaded: false })),
  on(
    loadScenarioDataSuccessAction,
    (state, action): GaugeDataModuleState =>
      GaugeDataReducerHelper.onLoadScenarioDataSuccessAction(state, action.loadScenarioResponse.dataStorageModule),
  ),
  on(
    selectGaugeDataFileAction,
    (state, action): GaugeDataModuleState => ({
      ...state,
      SelectedFileId: action.fileId ?? -1,
    }),
  ),
  on(loadGaugeDataAction, (state, action): GaugeDataModuleState => ({ ...state, SelectedFileId: action.fileId, isLoading: true })),
  on(
    loadGaugeDataSuccess,
    (state, action): GaugeDataModuleState => GaugeDataReducerHelper.onloadGaugeDataSuccess(state, action.data, action.columns),
  ),
  on(
    insertRowsSuccess,
    deleteRowsSuccess,
    (state, action): GaugeDataModuleState => GaugeDataReducerHelper.insertOrDeleteRowsSuccess(state, action),
  ),
);
