import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GridResizeService {
  private resizeSubject = new Subject();

  public getResizeSubject(): Subject<any> {
    return this.resizeSubject;
  }

  public resize(): void {
    this.resizeSubject.next(true);
  }
}
