import { createReducer, on } from '@ngrx/store';
import { deleteRowsSuccess, insertRowsSuccess, loadScenarioDataSuccessAction, resetScenarioState, updateRowSuccess } from '../app.actions';
import { initialRangeModuleState, RangeModuleState } from '@dunefront/common/modules/range/range-module.state';
import { RangeReducerHelper } from './range.reducer.helper';
import {
  cancelEditRangeAction,
  changeCurrentRangeIdAction,
  editRangeAction,
  insertVerticalShiftDtosAction,
  newRangeAction,
  updateEditRangeAction,
  updateVerticalShiftDtosAction,
} from './range.actions';
import { updateScenariosAction } from '../scenario/scenario.actions';

export const rangeModuleReducer = createReducer<RangeModuleState>(
  initialRangeModuleState,
  on(resetScenarioState, (): RangeModuleState => ({ ...initialRangeModuleState, isLoaded: false })),
  on(
    loadScenarioDataSuccessAction,
    (state, action): RangeModuleState =>
      RangeReducerHelper.onLoadScenarioDataSuccessAction(state, action.loadScenarioResponse.rangeModule, action.rangeId),
  ),
  on(updateScenariosAction, (state, action): RangeModuleState => RangeReducerHelper.onUpdateScenarios(state, action)),
  on(changeCurrentRangeIdAction, (state, action): RangeModuleState => {
    return { ...state, currentRangeId: action.rangeId };
  }),
  on(editRangeAction, (state): RangeModuleState => RangeReducerHelper.onEditRange(state)),
  on(newRangeAction, (state, action): RangeModuleState => RangeReducerHelper.onNewRange(state)),
  on(updateEditRangeAction, (state, action): RangeModuleState => {
    return { ...state, editingDto: action.range };
  }),
  on(cancelEditRangeAction, (state): RangeModuleState => ({ ...state, editingDto: undefined })),
  on(updateRowSuccess, (state, action): RangeModuleState => RangeReducerHelper.onUpdateRowSuccess(state, action)),
  on(insertRowsSuccess, (state, action): RangeModuleState => RangeReducerHelper.onInsertRowsSuccess(state, action)),
  on(deleteRowsSuccess, (state, action): RangeModuleState => RangeReducerHelper.onDeleteRowsSuccess(state, action)),
  on(
    insertVerticalShiftDtosAction,
    updateVerticalShiftDtosAction,
    (state, action): RangeModuleState => RangeReducerHelper.upsertVerticalShiftDtosAction(state, action.verticalShifts),
  ),
);
