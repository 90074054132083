import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { firstValueFrom, Subscription } from 'rxjs';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { ISelectItem } from '@dunefront/common/common/select.helpers';
import { EnumHelpers } from '@dunefront/common/utils/enum.helpers';
import { PumpingDirection, ToolPosition } from '@dunefront/common/modules/pumping/dto/pumping.dto';
import { updateScenarioRangePropertiesAction } from '../../../../+store/range/range.actions';
import { RangeConstants } from '@dunefront/common/dto/range.dto';
import { getValidatedTrendAnalysisRange } from '../../../../+store/trend-analysis/trend-analysis-page.selectors';
import { changeProp, DataSourceKey, ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';

import { getRowsForCalculations } from '@dunefront/common/common/common-grid.interfaces';
import { getCompletionModuleState } from '../../../../+store/completion/completion.selectors';
import { getCurrentPumpingModuleState, getSimulatePumpingModuleState } from '../../../../+store/pumping/selectors/pumping.selectors';
import { getAccessibleFeaturesAndLicenses } from '../../../../+store/licensing/licensing.selectors';
import { LicenseFeature } from '@dunefront/common/modules/licensing/licensing.interfaces';
import { IValidatedScenarioRangeProperties } from '@dunefront/common/modules/range/model/range';
import { ScenarioRangePropertiesDto } from '@dunefront/common/dto/scenario-range-properties.dto';

@Component({
  selector: 'app-trend-analysis-inputs',
  templateUrl: './trend-analysis-inputs.component.html',
  styleUrls: ['./trend-analysis-inputs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrendAnalysisInputsComponent implements OnDestroy, OnInit {
  private subscription = new Subscription();

  public UnitType = UnitSystem;
  public scenarioRangeProperties!: IValidatedScenarioRangeProperties;

  public toolPositions!: ISelectItem<ToolPosition>[];
  public pumpingDirections!: ISelectItem<PumpingDirection>[];

  public isEvaluateEnabled = false;
  public isSimulateEnabled = false;

  public get disableInputs(): boolean {
    return this.scenarioRangeProperties.Id === RangeConstants.EntireRangeId;
  }

  constructor(
    private store: Store,
    private cdRef: ChangeDetectorRef,
  ) {
    this.subscription.add(
      store.select(getValidatedTrendAnalysisRange).subscribe((range) => {
        this.scenarioRangeProperties = range;
        this.cdRef.markForCheck();
      }),
    );

    this.toolPositions = EnumHelpers.EnumToISelectItemArray(ToolPosition);
    this.pumpingDirections = EnumHelpers.EnumToISelectItemArray(PumpingDirection);
  }

  public async ngOnInit(): Promise<void> {
    const userFeatures = await firstValueFrom(this.store.select(getAccessibleFeaturesAndLicenses));
    this.isEvaluateEnabled = userFeatures.features.includes(LicenseFeature.Evaluate);
    this.isSimulateEnabled =
      userFeatures.features.includes(LicenseFeature.Simulate) ||
      userFeatures.features.includes(LicenseFeature.Simulate_CH) ||
      userFeatures.features.includes(LicenseFeature.Simulate_Disp);
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public onChange(event: ObjectChangeProp<IValidatedScenarioRangeProperties>): void {
    const updatedRange = changeProp(this.scenarioRangeProperties, event);
    this.store.dispatch(updateScenarioRangePropertiesAction(updatedRange, [event.key as DataSourceKey<ScenarioRangePropertiesDto>], true));
  }

  public async copyFromSimulateEvaluate(mode: 'Evaluate' | 'Simulate'): Promise<void> {
    const pumpingModuleStateSelector = mode === 'Evaluate' ? getCurrentPumpingModuleState : getSimulatePumpingModuleState;
    const { pumping } = await firstValueFrom(this.store.select(pumpingModuleStateSelector));
    const { PumpingDirection: pumpingDirection, ToolPosition: toolPosition } = pumping;

    const completion = await firstValueFrom(this.store.select(getCompletionModuleState));
    const crossOverMD = getRowsForCalculations(completion.LowerCompletion.rows)[0].TopMD;
    const washpipeBottomMD = getRowsForCalculations(completion.RunningString.rows).slice(-1)[0].BottomMD;

    const updatedRange: any = {
      ...this.scenarioRangeProperties,
      TrendAnalysisXOverMD: crossOverMD,
      TrendAnalysisWashpipeMD: washpipeBottomMD,
      TrendAnalysisToolPosition: toolPosition,
      TrendAnalysisPumpingDirection: pumpingDirection,
    };

    this.store.dispatch(
      updateScenarioRangePropertiesAction(
        updatedRange,
        ['TrendAnalysisXOverMD', 'TrendAnalysisWashpipeMD', 'TrendAnalysisToolPosition', 'TrendAnalysisPumpingDirection'],
        true,
      ),
    );
  }
}
