import { IAxisData, IAxisProps } from './chart-types';
import { IUnitSystemDto } from '@dunefront/common/dto/unit-system.dto';
import { UnitConverterHelper } from '@dunefront/common/unit-converters/unit.converter.helper';
import { IAxisUnit } from '@dunefront/common/unit-converters/converter.interfaces';

interface AxisUnitsSummary {
  unitTypeNames: string[];
  unitTypeUnits: string[];
  hasAnyLogarithmicUnit: boolean;
  decimalPlaces: number;
}

export function getAxisUnitsSummary(axisData: IAxisData, currentUnitSystem: IUnitSystemDto): AxisUnitsSummary {
  const unitTypeNames: string[] = [];
  const unitTypeUnits: string[] = [];
  let hasAnyLogarithmicUnit = false;
  let decimalPlaces = 0;

  axisData.axisUnits.forEach((axisUnit) => {
    const unitDetails = UnitConverterHelper.getUnitTypeAndName(axisUnit.dataType, axisUnit.unitSystem, currentUnitSystem);

    if (!unitTypeNames.includes(unitDetails.AxisName)) {
      unitTypeNames.push(unitDetails.AxisName);
    }
    if (unitDetails.AxisSymbol) {
      if (!unitTypeUnits.includes(unitDetails.AxisSymbol)) {
        unitTypeUnits.push(unitDetails.AxisSymbol);
      }
    }
    if (unitDetails.Logarithmic) {
      hasAnyLogarithmicUnit = true;
    }
    if ((unitDetails.DecimalPlaces ?? 0) > decimalPlaces) {
      decimalPlaces = unitDetails.DecimalPlaces ?? 0;
    }
  });

  return { unitTypeNames, unitTypeUnits, hasAnyLogarithmicUnit, decimalPlaces };
}

export function isAxesLogarithmic(axesUnits: IAxisUnit[], axesProps: IAxisProps | undefined): boolean {
  return axesProps != null
    ? axesProps.isLogarithmic
    : axesUnits.some((unit) => UnitConverterHelper.getUnitTypeAndName(unit.dataType, unit.unitSystem).Logarithmic);
}
