import { ISelectItem } from '@dunefront/common/common/select.helpers';

export const presetFontSizeItems: ISelectItem<number>[] = [
  { text: '8', value: 8 },
  { text: '9', value: 9 },
  { text: '10', value: 10 },
  { text: '11', value: 11 },
  { text: '12', value: 12 },
  { text: '14', value: 14 },
  { text: '16', value: 16 },
  { text: '18', value: 18 },
  { text: '20', value: 20 },
  { text: '22', value: 22 },
  { text: '24', value: 24 },
  { text: '26', value: 26 },
  { text: '28', value: 28 },
  { text: '36', value: 36 },
  { text: '48', value: 48 },
];

export const presetLineThicknessItems: ISelectItem<number>[] = [
  { text: '1', value: 1 },
  { text: '2', value: 2 },
  { text: '3', value: 3 },
  { text: '4', value: 4 },
  { text: '5', value: 5 },
  { text: '6', value: 6 },
  { text: '7', value: 7 },
  { text: '8', value: 8 },
  { text: '9', value: 9 },
  { text: '10', value: 10 },
];

export const presetPointMarkersSizeItems: ISelectItem<number>[] = [
  { text: '3', value: 3 },
  { text: '4', value: 4 },
  { text: '5', value: 5 },
  { text: '6', value: 6 },
  { text: '8', value: 8 },
  { text: '10', value: 10 },
  { text: '12', value: 12 },
  { text: '14', value: 14 },
  { text: '16', value: 16 },
  { text: '18', value: 18 },
  { text: '20', value: 20 },
];
