import { ChangeDetectionStrategy, Component, HostListener, NgModule } from '@angular/core';
import { Store } from '@ngrx/store';
import { getUndoRedoState } from '../../+store/undo-redo/undo-redo.selectors';
import { CommonModule } from '@angular/common';
import { ModalService } from '../modals/modal.service';
import { undoRedoActionClick } from '../../+store/undo-redo/undo-redo.action';
import { WsAction } from '@dunefront/common/ws.action';

@Component({
  selector: 'app-undo-redo',
  templateUrl: './undo-redo.component.html',
  styleUrls: ['./undo-redo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UndoRedoComponent {
  public isVisible = false;
  public undoRedo$ = this.store.select(getUndoRedoState);

  constructor(
    private store: Store,
    private modalService: ModalService,
  ) {}

  @HostListener('document:keydown.control.f2')
  private showHide(): void {
    this.isVisible = !this.isVisible;
  }

  @HostListener('document:keydown.control.z')
  private undo(): void {
    this.store.dispatch(undoRedoActionClick('undo'));
  }

  @HostListener('document:keydown.control.y')
  @HostListener('document:keydown.control.shift.z')
  private redo(): void {
    this.store.dispatch(undoRedoActionClick('redo'));
  }

  public showPayload(action: WsAction | undefined): void {
    if (!action) {
      return;
    }
    this.modalService.showAlert(JSON.stringify(action), 'Undo Redo').then();
  }
}

@NgModule({
  imports: [CommonModule],
  declarations: [UndoRedoComponent],
  exports: [UndoRedoComponent],
})
export class UndoRedoModule {}
