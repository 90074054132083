import { createAction, props } from '@ngrx/store';
import { ImportColumnDto } from '@dunefront/common/modules/data-storage/dto/import-column.dto';
import { ImportDataDto } from '@dunefront/common/modules/data-storage/dto/import-data.dto';

export const selectGaugeDataFileAction = createAction('[GaugeData] selectFile', props<{ fileId: number | undefined; src: string }>());
export const loadGaugeDataAction = createAction('[GaugeData] loadGaugeDataAction', props<{ fileId: number }>());
export const loadGaugeDataSuccess = createAction(
  '[GaugeData] loadGaugeDataSuccess',
  props<{ data: ImportDataDto[]; columns: ImportColumnDto[] }>(),
);
