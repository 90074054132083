import { ReferenceVariableCalculatorResultsDto } from './dto/reference-variable-calculator-results.dto';
import { IModuleState } from '../../common/common-state.interfaces';

export interface ReferenceVariablesModuleState extends IModuleState {
  referenceVariables: ReferenceVariableCalculatorResultsDto | undefined;
  isLoading: boolean;
  dataStateId: string;
}

export const initialReferenceVariablesModuleState: ReferenceVariablesModuleState = {
  referenceVariables: undefined,
  isLoaded: false,
  isLoading: false,
  dataStateId: '',
};
