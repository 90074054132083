import { ChangeDetectorRef, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { ModalService } from '../../../../common-modules/modals/modal.service';
import { DbDependentComponent } from '../../../../common-modules/db-connection/db-dependent.component';
import {
  getSettlingCalculatorWithStaticVisc,
  ISettlingAndStaticViscosity,
} from '../../../../+store/calculators/settling-calculator/settling-calculator.selectors';
import {
  calculateSettling,
  updateSettlingCalculatorRow,
} from '../../../../+store/calculators/settling-calculator/settling-calculator.actions';
import { cancelChange } from '../../../../+store/store.helpers';
import { FlowPathType } from '@dunefront/common/modules/calculators/resuspension-calculator/resuspension-calculator.dto';
import { FluidType } from '@dunefront/common/modules/fluid/dto/fluid.dto';
import { getIsFluidAndGravelValid } from '../../../../+store/app.selector';
import { take } from 'rxjs/operators';
import { ValidatedSettlingCalculator } from '../../../../+store/calculators/settling-calculator/settling-calculator-module.state';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { changeProp, ErrorHelper, ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';
import { LeftNavHelperService } from '../../../../shared/components/left-nav/left-nav-helper.service';
import { IRadioItem } from '@dunefront/common/common/radio.helpers';
import { StoreCrudPropsFactory } from '@dunefront/common/common/common-store-crud.interfaces';
import { PanelHelpMode } from '../../../../shared/components/help-button/help-buton.component';

@Component({
  selector: 'app-settling-calculator',
  templateUrl: './settling-calculator.component.html',
  styleUrls: ['./settling-calculator.component.scss'],
})
export class SettlingCalculatorComponent extends DbDependentComponent {
  public PanelHelpMode = PanelHelpMode;

  public xAxisValues: IRadioItem<number>[] = [
    {
      value: 1,
      text: 'Rate',
    },
    {
      value: 0,
      text: 'Velocity',
    },
  ];

  public flowPathDimensionsValues: IRadioItem<number>[] = [
    {
      value: 0,
      text: 'Pipe',
    },
    {
      value: 1,
      text: 'Annulus',
    },
  ];

  public OroskarAndTurianDiameterValues: IRadioItem<number>[] = [
    {
      value: 0,
      text: 'Equivalent',
    },
    {
      value: 1,
      text: 'Hydraulic',
    },
  ];

  public UnitType = UnitSystem;
  public FlowPathType = FlowPathType;
  public FluidType = FluidType;

  public state!: ISettlingAndStaticViscosity;

  constructor(
    store: Store,
    cdRef: ChangeDetectorRef,
    private modalService: ModalService,
    public runCalculationService: LeftNavHelperService,
  ) {
    super(store, cdRef);
    this.subscription.add(
      store.select(getSettlingCalculatorWithStaticVisc).subscribe((state) => {
        this.state = state;
        cdRef.markForCheck();
      }),
    );
    this.subscription.add(runCalculationService.calculationSubject.subscribe(() => this.calculate()));
    this.onHelpChange('calculators', 'settling-calculator');
  }

  public async submitCalcData(props: ObjectChangeProp<ValidatedSettlingCalculator>): Promise<void> {
    if (props.key === 'GravelId' && props.value === 0) {
      await this.modalService.showAlert('Gravel cannot be none');
      const cancelGenerator = cancelChange(this.state.SettlingCalculator, props, this.cdRef);
      this.state.SettlingCalculator = cancelGenerator.next().value;
      this.state.SettlingCalculator = cancelGenerator.next().value;
      if (this.state.SettlingCalculator.GravelId === 0) {
        this.state.SettlingCalculator.GravelId = null;
      }
      this.cdRef.markForCheck();
      return;
    }
    if (this.state.SettlingCalculator[props.key] !== props.value) {
      this.state.SettlingCalculator = changeProp(this.state.SettlingCalculator, props);
      this.store.dispatch(updateSettlingCalculatorRow(StoreCrudPropsFactory.updateRow(this.state.SettlingCalculator, props)));
    }
  }

  public async calculate(): Promise<void> {
    if (!this.state.SettlingCalculator.isValid) {
      await this.modalService.showAlert(ErrorHelper.ERROR_CURRENT_SCREENS_MESSAGE_HEADER, 'Warning');
      return;
    }
    this.store
      .select(getIsFluidAndGravelValid(this.state.SettlingCalculator.FluidId, this.state.SettlingCalculator.GravelId))
      .pipe(take(1))
      .subscribe(async (invalidFields) => {
        if (invalidFields.length > 0) {
          await this.modalService.showAlert(invalidFields, 'Warning');
          return;
        }
        this.store.dispatch(
          calculateSettling({
            ScenarioId: this.state.SettlingCalculator.ScenarioId,
            StaticViscosity: this.state.isNewtonian ? this.state.viscosityAtTemperature : this.state.SettlingCalculator.StaticViscosity,
          }),
        );
      });
  }
}
