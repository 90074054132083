import { IScenarioIdBasedEntity } from '../../../dto/common-dto.interfaces';
import { GaugeMeasurementType } from '../../../dto/running-string-pipe.dto';
import { IModuleState, IValidatedModuleState } from '../../../common/common-state.interfaces';

export interface Roughness {
  IsRoughness: boolean;
  WorkstringRoughness: number;
  BlankAnnulusRoughness: number;
  ScreenAnnulusRoughness: number;
  WashpipeAnnulusRoughness: number;
  WashpipeRoughness: number;
  WorkstringAnnulusRoughness: number;
  TreatingLineRoughness: number;
  ChokeLineRoughness: number;
  ThirdAnnulusRoughness: number;
}

export interface DeveloperSettingsDto {
  // temporary vales from PackproMaster
  MaxLoopIterations: number;
  GlobalTolerance: number;
  RateDistributionMaxTolerance: number;
  RateDistributionMinTolerance: number;
  VerticalDeviationLimit: number;
  IsPackingEccentric: true;
  MaxCellLength: number;
  MaxChartPointsPerSeries: number;

  MinimumShuntAndDarcyViscosity: number;
  MaxDarcyVolumeStep: number;
  MaxReservoirBoundaryRadius: number;
  PenetrationBoundaryRatio: number;
  DamageBoundaryRatio: number;
  LeakoffGravelConcentrationTolerance: number;
  LeakoffMinRateTolerance: number;
  LeakoffMaxRateTolerance: number;

  MinCellLength: number;
  SettlingGravelConcentrationTolerance: number;

  NumberOfSmoothingPoints: number;
}

export interface SettingsDto extends Settings, IScenarioIdBasedEntity, Roughness, SettingsNotResetable, FluidProSettings {}

export interface FluidProSettings {
  FpNumParamsNz: number;
  FpNumParamsNtheta: number;
  FpNumParamsCFL: number;
  FpNumParamsTolDP: number;
  FpNumParamsTimeout: number;
  FpOptionsIshade: number;
  FpOptionsContentrationTolerance: number;
  FpOptionsFlowRegimeMethod: number;
  FpOptionsKinertialValue: number;
  FpOptionsDDF: number;
  FpMinCellLength: number;
  FpMergeCellLength: number;
  FpMergeVolumeRatio: number;
  FpMinComputationalSections: number;
  FpIsMergeDifferentTubulars: boolean;
  FpDataComparisonTolerance: number;
  FpPipeToAnnulusSectionTransition: number;
}

export interface SettingsNotResetable {
  ShearRate: number;
  SimulationAnimationWellLayout: AnimationLayout;
  WellVisualizationWellLayout: DrawingLayoutType;
  WellVisualizationWellPart: WellPartType;
}

export interface Settings {
  StokesSettlingMultiplier: number;
  DoesPackCureLosses: boolean;
  IsToolJointAnalysed: boolean;
  MaxVolumeStep: number;
  ICDRateDistributionMinTolerance: number;
  IsFrictionEccentric: boolean;
  RateChangeTolerance: number;
  GravelConcentrationChangeTolerance: number;
  ChokePressureChangeTolerance: number;

  OroskarAndTurianDiameter: DiameterType;
  UseShuntVESFrictionCorrelations: boolean;

  BridgingRatio: number;
  NumberOfGrainDiametersToBridge: number;
  DischargeCoefficient: number;
  ModelScreenHandlingLength: boolean;
  RopingPercentage: number;
  MaxNumberOfReservoirCells: number;
  UseNumberOfGrainDiametersForBridging: boolean;
  PerfAutoPackPercentage: number;
  IsThirdAnnulusAnalysed: boolean;

  NetPressureForBridging: number;
  IsBottomholeGravelConcMD: boolean;
  BottomholeGravelConcMD: number;
  WashpipeGaugeMeasurementFlowPath: GaugeMeasurementType;
  PipeThermalConductivity: number;
  CementThickness: number;
  CementThermalConductivity: number;
  FilterCakeThickness: number;

  FilterCakeThermalConductivity: number;
  MaxThermalCellLength: number;
  MinThermalCellLength: number;
  AreLossesRedistributed: boolean;
  MinDeviationForHorizontal: number;
  HorizontalThermalMultiplier: number;
  ScreenThermalConductivity: number;
  RopingVolume: number;

  IsRopingPercentage: boolean;
  PermeabilityRatio: number;
  IsDiameterValidationDisabled: boolean;
  IsNozzleLeakoffConvergence: boolean;
  NozzleLeakoffBeta: number;
  IsNozzleLeakoffBeta: boolean;
}

export interface SettingsDefaults extends Settings, SettingsNotResetable, FluidProSettings {
  Roughness: Roughness;
}

export interface IEngineOptions {
  settings: SettingsDefaults;
  developerSettings: DeveloperSettingsDto;
}

export type ValidatedSettings = SettingsDto & IModuleState & IValidatedModuleState<SettingsDto>;
export type ValidatedDeveloperSettings = DeveloperSettingsDto & IModuleState & IValidatedModuleState<DeveloperSettingsDto>;

export enum AnimationLayout {
  Auto = 0,
  Horizontal = 1,
  Vertical = 2,
}

export enum WellPartType {
  Full_Well = 0,
  Above_Packer = 1,
  Below_Packer = 2,
}

export enum DrawingLayoutType {
  Survey = 0,
  Horizontal = 1,
  Vertical = 2,
}

export enum DiameterType {
  Equivalent = 0,
  Hydraulic = 1,
}

export enum WellVisualizationType {
  Packing = 0,
  Concentration = 1,
  Concentration_With_Stream_Lines = 2,
  Flow_Regime = 3,
  Flow_Regime_With_Stream_Lines = 4,
  Stream_Function = 5,
}

export const isConcentrationWellVisualizationType = (type: WellVisualizationType): boolean =>
  [WellVisualizationType.Concentration, WellVisualizationType.Concentration_With_Stream_Lines].includes(type);

export const isFlowRegimeWellVisualizationType = (type: WellVisualizationType): boolean =>
  [WellVisualizationType.Flow_Regime, WellVisualizationType.Flow_Regime_With_Stream_Lines].includes(type);

export const isStreamLinesBasedWellVisualizationType = (type: WellVisualizationType): boolean =>
  [WellVisualizationType.Concentration_With_Stream_Lines, WellVisualizationType.Flow_Regime_With_Stream_Lines].includes(type);
