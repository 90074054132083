import { Routes } from '@angular/router';
import { TrendAnalysisPageComponent } from './trend-analysis-page.component';
import { gaugeDataPageRoutes } from '../gauge-data-page/gauge-data-page.routes';
import { TrendAnalysisComponent } from './trend-analysis/trend-analysis.component';
import { reportingRoutes } from '../common/reporting/reporting.routes';
import { LicenseFeature } from '@dunefront/common/modules/licensing/licensing.interfaces';
import { settingsRoutes } from '../common/settings/settings.routes';
import { RouteModuleData, RouteModuleDataGrid } from '../gauge-data-page/gauge-data-routes-names';

export const RouteModuleTrendAnalysis = 'trend-analysis';

export const trendAnalysisPageRoutes: Routes = [
  {
    path: RouteModuleTrendAnalysis,
    component: TrendAnalysisPageComponent,
    data: {
      breadcrumb: 'Trend Analysis',
      licenseFeature: LicenseFeature.Trend_Analysis,
    },
    children: [
      ...gaugeDataPageRoutes,
      {
        path: RouteModuleTrendAnalysis,
        component: TrendAnalysisComponent,
      },
      ...settingsRoutes,
      ...reportingRoutes,
      {
        path: '',
        redirectTo: `${RouteModuleData}/${RouteModuleDataGrid}`,
        pathMatch: 'full',
      },
    ],
  },
];
