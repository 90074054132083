import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectCurrentUnitSystem } from '../../../../../+store/units/units.selectors';
import { getValidatedWellModuleState } from '../../../../../+store/well/validated-well.selectors';
import { DrawableContentProviderComponent } from '../../../../../shared/services/drawable-registry.service';

@Component({
  templateUrl: './survey-preview-chart-renderer.component.html',
})
export class SurveyPreviewChartRendererComponent implements DrawableContentProviderComponent {
  @Input() public drawableProviderId?: string;
  public wellState$ = this.store.select(getValidatedWellModuleState);
  public currentUnitSystem$ = this.store.select(selectCurrentUnitSystem);

  constructor(protected store: Store) {}
}
