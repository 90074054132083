import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-working-indicator-modal-content',
  template: `
    <div class="dialog-content">
      <div [innerHTML]="message"></div>
    </div>
  `,
  styles: [
    `
      .dialog-content {
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        height: 150px;
        align-items: center;
        font-size: 1rem;
      }
    `,
  ],
})
export class WorkingModalContentComponent {
  public message!: string;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
  ) {
    this.message = config.data.message;
  }
}
