<app-page-panel header="Chart Marker" [allowFullScreen]="false" (click)="onHelpChange('settings', 'chart-marker')">
  <div style="flex-direction: column" class="p-1">
    <app-radio-group
      [items]="[
        { value: false, text: 'Default' },
        { value: true, text: 'Custom' }
      ]"
      [source]="globalOptions"
      [key]="'IsOverrideChartMarker'"
      id="IsOverrideChartMarker"
      dataCy="is-override-chart-marker-radio"
      [isUiLockable]="false"
      (valueChanged)="onValueChange($event)"
      class="grid-two-one"
      layout="horizontal"
    >
    </app-radio-group>
    <app-chart-marker-details
      class="block m-top-1"
      [disabled]="!globalOptions.IsOverrideChartMarker"
      [showVerticalAlignment]="true"
      [showHorizontalAlignment]="true"
      [markerStyle]="globalOptions"
      [defaultMarkerStyle]="defaultGlobalOptions"
      (valueChanged)="onValueChange($event)"
    ></app-chart-marker-details>
  </div>
</app-page-panel>
