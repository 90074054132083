import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  NgZone,
  OnDestroy,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { IGridColumnConfig } from '../grid.interfaces';
import { IUnitSystemDto } from '@dunefront/common/dto/unit-system.dto';
import { GridHelpers } from '../grid.helpers';
import { ModalService } from '../../../../common-modules/modals/modal.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[app-table-cell-header]',
  templateUrl: './table-cell-header.component.html',
  styleUrls: ['./table-cell-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class TableCellHeaderComponent implements AfterViewInit, OnDestroy {
  @Input() public headerTopRowHeight: number | undefined;
  @Input() public colConfig: IGridColumnConfig<any> | undefined;
  @Input() public currentUnitSystem: IUnitSystemDto | undefined;
  @Input() public headerText = '';
  @Input() public showHeaderUnits: boolean | undefined;
  @Input() public showError?: boolean;
  @ViewChild('headerCell') public headerCell!: ElementRef;

  private unlistenMouseOver!: () => void;
  public tooltipEnabled = false;
  private gridHelpers!: GridHelpers;

  constructor(
    private ngZone: NgZone,
    private renderer: Renderer2,
    private cdRef: ChangeDetectorRef,
    private modalService: ModalService,
  ) {
    this.gridHelpers = new GridHelpers(modalService);
  }

  public ngAfterViewInit(): void {
    this.headerCell?.nativeElement &&
      this.ngZone.runOutsideAngular(() => {
        this.unlistenMouseOver = this.renderer.listen(this.headerCell.nativeElement, 'mouseover', (event: MouseEvent) => {
          const refreshTooltip = (tooltipEnabled: boolean): void => {
            this.ngZone.run(() => {
              this.tooltipEnabled = tooltipEnabled;
              setTimeout(() => this.headerCell.nativeElement.dispatchEvent(new Event('mouseenter')), 0);
              this.cdRef.markForCheck();
            });
          };
          if (!this.tooltipEnabled && (event.target as HTMLElement).scrollWidth > (event.target as HTMLElement).clientWidth) {
            refreshTooltip(true);
          } else if (this.tooltipEnabled && (event.target as HTMLElement).scrollWidth <= (event.target as HTMLElement).clientWidth) {
            refreshTooltip(false);
          }
        });
      });
  }

  public ngOnDestroy(): void {
    this.unlistenMouseOver?.();
  }

  public get unitSystemText(): string {
    return this.gridHelpers.getUnitSystemText(this.colConfig, this.currentUnitSystem);
  }
}
