import { LoadFrictionCalculatorActionResponse } from '@dunefront/common/modules/calculators/friction-calculator/friction-calculator-module.action';
import { CrudResponse } from '@dunefront/common/modules/common.actions';
import {
  FrictionCalculatorInitialState,
  FrictionCalculatorModuleState,
} from '@dunefront/common/modules/calculators/friction-calculator/friction-calculator-module.state';
import { DeleteResultsFilter, ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import { ActionResponse } from '@dunefront/common/response-ws.action';

export class FrictionCalculatorModuleReducerHelper {
  public static onLoadScenarioDataSuccessAction(
    state: FrictionCalculatorModuleState,
    response: ActionResponse<LoadFrictionCalculatorActionResponse>,
  ): FrictionCalculatorModuleState {
    if (response.status !== 'ok' || !response.payload) {
      return FrictionCalculatorInitialState;
    }
    const { frictionCalculatorDto, frictionCalculatorResultDto } = response.payload;

    return {
      ...state,
      FrictionCalculator: frictionCalculatorDto,
      FrictionCalculatorResult: frictionCalculatorResultDto,
      isLoaded: true,
    };
  }

  public static onUpdateFrictionCalculatorSuccess(
    state: FrictionCalculatorModuleState,
    response: CrudResponse,
  ): FrictionCalculatorModuleState {
    if (!response.affectedRows.frictionCalculator) {
      return state;
    }
    return { ...state, FrictionCalculator: response.affectedRows.frictionCalculator };
  }

  public static onUpdateFrictionCalculatorResultSuccess(
    state: FrictionCalculatorModuleState,
    response: CrudResponse,
  ): FrictionCalculatorModuleState {
    if (!response.affectedRows.frictionCalculatorResult) {
      return state;
    }
    return {
      ...state,
      FrictionCalculatorResult: response.affectedRows.frictionCalculatorResult,
    };
  }

  public static onRemoveDataResultsFromStore(
    state: FrictionCalculatorModuleState,
    deleteResultsFilter: DeleteResultsFilter,
  ): FrictionCalculatorModuleState {
    if (deleteResultsFilter.moduleTypes != null && !deleteResultsFilter.moduleTypes.includes(ModuleType.Friction_Calc)) {
      return state;
    }
    return {
      ...state,
      FrictionCalculatorResult: state.FrictionCalculatorResult.filter(
        (result) => deleteResultsFilter.scenarioIds != null && !deleteResultsFilter.scenarioIds.includes(result.ScenarioId),
      ),
    };
  }
}
