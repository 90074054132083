<ng-container *ngIf="contentVisible && (currentResultsPresent$ | async); else noResults">
  <ng-container *ngIf="chartIds$ | async as chartIds; else noResults">
    <div
      [class]="isHorizontal ? 'root-container horizontal' : 'root-container vertical'"
      data-cy="simulation-animation-charts-horizontal"
      (click)="onHelpChange('results', 'animation-simulate')"
    >
      <div class="charts-container" [class.with-depth-chart]="showDepthChart$ | async">
        <div *ngIf="showDepthChart$ | async" class="animation-chart depth-chart">
          <app-page-panel header="Perf Packing" [allowFullScreen]="true" [panelHelpMode]="PanelHelpMode.CHART">
            <app-animation-depth-based-chart
              drawableProviderId="Perf Packing"
              [layoutType]="isHorizontal ? DrawingLayoutType.Horizontal : DrawingLayoutType.Vertical"
            ></app-animation-depth-based-chart>
          </app-page-panel>
        </div>
        <div class="animation-chart">
          <app-page-panel header="Pressure" [allowFullScreen]="true" [panelHelpMode]="PanelHelpMode.CHART">
            <app-animation-time-based-chart
              [allowRecording]="true"
              [chartDataSourceType]="ChartDataSourceType.ChartSourceResultsTimeBased"
              drawableProviderId="Pressure"
            >
            </app-animation-time-based-chart>
          </app-page-panel>
        </div>
      </div>

      <div class="packing-drawing-container">
        <app-page-panel [header]="isHorizontal ? '' : 'Wellbore'" [allowFullScreen]="false">
          <app-animation-packing-drawing
            [isInPanel]="true"
            [layoutType]="isHorizontal ? DrawingLayoutType.Horizontal : DrawingLayoutType.Vertical"
            [drawingSection]="drawingSection"
            [chartId]="chartIds.simulationVisualizationId"
          >
          </app-animation-packing-drawing>
        </app-page-panel>
      </div>
    </div>
    <div class="animation-footer" (click)="onHelpChange('results', 'animation-simulate')">
      <div class="slider">
        <app-chart-slider data-cy="animation-footer-slider"></app-chart-slider>
      </div>
      <div class="layout-selection">
        <app-select
          [items]="layoutTypes"
          [value]="selectedLayoutType"
          [width]="115"
          [isUiLockable]="false"
          (primitiveValueChanged)="onLayoutChange($event.value)"
        >
        </app-select>
      </div>
    </div>
  </ng-container>
</ng-container>
<ng-template #noResults>
  <div class="centered-message" (click)="onHelpChange('results', 'animation-simulate')">No results available, please run
    a simulation.
  </div>
</ng-template>

