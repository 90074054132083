import { BasePptSectionGenerator, PptContext } from '../base-ppt-section-generator';
import { IUnitSystemDto } from '@dunefront/common/dto/unit-system.dto';
import { firstValueFrom } from 'rxjs';
import { selectCurrentUnitSystem } from '../../../../../../+store/units/units.selectors';
import { CommonReportDataHelpers } from '../../common/common-report-data-helpers';
import { Pumping } from '@dunefront/common/modules/pumping/model/pumping/pumping';
import { getValidatedPumping } from '../../../../../../+store/pumping/selectors/pumping-validation.selectors';
import { PptDocumentTableGeneratorHelper } from '../ppt-document-table-generator.helper';
import { getFluidsSelectData, IFluidSelectorData } from '../../../../../../+store/fluid/fluid.selectors';
import { getGravelsSelectData, IGravelSelectorData } from '../../../../../../+store/gravel/gravel.selectors';
import { isSimulateBased } from '@dunefront/common/modules/scenario/scenario.dto';
import { getCurrentAppModuleType } from '../../../../../../+store/ui/ui.selectors';
import { ReferenceVariableCalculatorResultsDto } from '@dunefront/common/modules/reference-variables/dto/reference-variable-calculator-results.dto';
import { getReferenceVariables } from '../../../../../../+store/reference-variables/reference-variables.selectors';
import { PumpingSchedule } from '@dunefront/common/modules/pumping/model/pumping-schedule/pumping-schedule';
import { getCalculatedSimulatePumpingSchedule } from '../../../../../../+store/pumping/selectors/pumping-schedule.selectors';
import { ReportPumpingSchedule } from '../../doc/sections/pumping-report-generator.helper';

export class PumpingScheduleDataPptGenerator extends BasePptSectionGenerator {
  constructor(context: PptContext) {
    super(context);
  }

  public async generate(rangeName?: string): Promise<void> {
    const pumpingInitialState: Pumping = await firstValueFrom(this.store.select(getValidatedPumping));
    const currentUnitSystem: IUnitSystemDto = await firstValueFrom(this.store.select(selectCurrentUnitSystem));
    const fluidsSelectData: IFluidSelectorData = await firstValueFrom(this.store.select(getFluidsSelectData));
    const gravelsSelectData: IGravelSelectorData = await firstValueFrom(this.store.select(getGravelsSelectData));
    const pumpingSchedule: PumpingSchedule[] = (await firstValueFrom(this.store.select(getCalculatedSimulatePumpingSchedule))).rows
      .filter((row) => row.rowType !== 'insert-row')
      .map((row) => row.rowData);
    const refVariables: ReferenceVariableCalculatorResultsDto | undefined = await firstValueFrom(this.store.select(getReferenceVariables));
    const isSimulate = isSimulateBased(await firstValueFrom(this.store.select(getCurrentAppModuleType)));
    const mainTitle = 'Pumping';
    const subTitle = rangeName;

    if (isSimulate && refVariables) {
      const title = `${mainTitle} - Schedule Reference Parameters`;

      const tableData = CommonReportDataHelpers.createPumpingScheduleReferenceParametersTableData(refVariables, currentUnitSystem);
      const tableRows = PptDocumentTableGeneratorHelper.createGenericKeyValueTableRowsWithTableData(tableData, currentUnitSystem);

      this.addKeyValueTableSlide({ title, subTitle, tableRows });
    }

    if (isSimulate) {
      const title = `${mainTitle} - Schedule`;
      const tableData = CommonReportDataHelpers.createPumpingScheduleTableData(
        pumpingSchedule,
        fluidsSelectData,
        gravelsSelectData,
        pumpingInitialState,
      );
      const tableRows = PptDocumentTableGeneratorHelper.createTableRows<ReportPumpingSchedule>(tableData, null, currentUnitSystem);

      this.addTableSlide({ title, subTitle, tableRows, presetColumnsWidths: { 0: 20, 3: 20, 4: 20 } });
    }
  }
}
