import { createSelector } from '@ngrx/store';
import { getCurrentScenarioId } from '../scenario/scenario.selectors';
import { getCurrentRangeId } from '../range/range.selectors';
import { getCurrentAppModuleType } from '../ui/ui.selectors';
import { ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import { CalculationResultsInfoDto } from '@dunefront/common/modules/calculation-engine/dto/calculation-results-info.dto';
import { RangeConstants } from '@dunefront/common/dto/range.dto';
import { getCurrentFileCalculationEngineState } from './calculation-engine.selectors';
import { getHasMASPCalculatorResults } from '../calculators/masp-calculator/masp-calculator.selectors';
import { getHasFrictionCalculatorResults } from '../calculators/friction-calculator/friction-calculator.selectors';
import { getHasInjectionCalculatorResults } from '../calculators/injection-test-calculator/injection-test-calculator.selectors';
import { getHasLeakoffCalculatorResults } from '../calculators/leakoff-coefficient-calculator/leakoff-coefficient-calculator.selectors';
import { getHasSettlingCalculatorResults } from '../calculators/settling-calculator/settling-calculator.selectors';
import { getHasResuspensionCalculatorResults } from '../calculators/resuspension-calculator/resuspension-calculator.selectors';

export const getCurrentFileResultInfos = createSelector(
  getCurrentFileCalculationEngineState,
  (state): CalculationResultsInfoDto[] => state.resultsInfos,
);

export const getCompletedResultsForCurrentModule = createSelector(
  getCurrentFileResultInfos,
  getCurrentAppModuleType,
  (resultInfos, moduleType) => resultInfos.filter((ri) => ri.moduleType === moduleType && ri.isCompleted),
);

export const getCurrentResults = createSelector(
  getCurrentAppModuleType,
  getCurrentFileResultInfos,
  getCurrentScenarioId,
  getCurrentRangeId,
  (...[appModuleType, resultsInfos, scenarioId, rangeId]) => {
    return resultsInfos.find((info) => findResultInfoFilter(info, scenarioId, appModuleType, rangeId ?? RangeConstants.EntireRangeId));
  },
);

export const getAreCurrentResultsPresent = createSelector(getCurrentResults, (results) => results != null);
export const getAreCurrentResultsPresentAndCompleted = createSelector(
  getCurrentResults,
  (results) => results != null && results.isCompleted,
);

export const getAreCurrentCalculatorResultsPresent = createSelector(
  getCurrentAppModuleType,
  getHasMASPCalculatorResults,
  getHasFrictionCalculatorResults,
  getHasInjectionCalculatorResults,
  getHasLeakoffCalculatorResults,
  getHasSettlingCalculatorResults,
  getHasResuspensionCalculatorResults,
  (
    appModuleType,
    hasMASPResults,
    hasFrictionResults,
    hasInjectionResults,
    hasLeakoffResults,
    hasSettlingResults,
    hasResuspensionResults,
  ): boolean => {
    switch (appModuleType) {
      case ModuleType.MASP_Calc:
        return hasMASPResults;
      case ModuleType.Friction_Calc:
        return hasFrictionResults;
      case ModuleType.Injection_Test_Calc:
        return hasInjectionResults;
      case ModuleType.Leakoff_Coefficient_Calc:
        return hasLeakoffResults;
      case ModuleType.Settling_Calc:
        return hasSettlingResults;
      case ModuleType.Resuspension_Calc:
        return hasResuspensionResults;
      default:
        return false;
    }
  },
);

export const getAreFluidOrGravelDependantCalculatorResultsPresent = createSelector(
  getHasMASPCalculatorResults,
  getHasFrictionCalculatorResults,
  getHasSettlingCalculatorResults,
  getHasResuspensionCalculatorResults,
  (hasMASPResults, hasFrictionResults, hasSettlingResults, hasResuspensionResults): boolean =>
    hasMASPResults || hasFrictionResults || hasSettlingResults || hasResuspensionResults,
);

export const findResultInfoFilter = (
  info: CalculationResultsInfoDto,
  scenarioId: number,
  moduleType: ModuleType,
  rangeId: number,
): boolean => info.scenarioId === scenarioId && info.moduleType === moduleType && info.rangeId === rangeId;
