<app-page-panel header="FluidPro Options" *ngIf="settings && defaultSettings" [allowFullScreen]="false" (click)="helpChanged.emit('fluidpro')">
  <div class="grid-form-container fluidpro">

    <app-form-input
      [source]="settings"
      [sourceDefaults]="defaultSettings"
      [key]="'FpNumParamsNz'"
      class="grid-two-one"
      appUnwrap
      label="No. of Axial Cells"
      [decimalPlaces]="0"
      [inputWidth]="150"
      [unitType]="UnitType.None"
      [highlight]="settings.FpNumParamsNz !== defaultSettings.FpNumParamsNz"
      (valueChanged)="onValueChange($event)"
    >
    </app-form-input>

    <app-form-input
      [source]="settings"
      [sourceDefaults]="defaultSettings"
      [key]="'FpNumParamsNtheta'"
      class="grid-two-one"
      appUnwrap
      label="No. of Radial Cells"
      [decimalPlaces]="0"
      [inputWidth]="150"
      [unitType]="UnitType.None"
      [highlight]="settings.FpNumParamsNtheta !== defaultSettings.FpNumParamsNtheta"
      (valueChanged)="onValueChange($event)"
    >
    </app-form-input>
    <app-form-input
      [source]="settings"
      [sourceDefaults]="defaultSettings"
      [key]="'FpNumParamsTimeout'"
      class="grid-two-one"
      appUnwrap
      label="Output Time Interval"
      [decimalPlaces]="4"
      [inputWidth]="150"
      [labelWidth]="200"
      [unitType]="UnitType.Time"
      [highlight]="settings.FpNumParamsTimeout !== defaultSettings.FpNumParamsTimeout"
      (valueChanged)="onValueChange($event)"
    >
    </app-form-input>

    <div>Pipe to Annulus Transition Method</div>
    <app-radio-group
      [items]="[
        { value: 0, text: 'Mix fluids' },
        { value: 1, text: 'Heavy fluid at bottom' }
      ]"
      [source]="settings"
      [sourceDefaults]="defaultSettings"
      [key]="'FpPipeToAnnulusSectionTransition'"
      [highlight]="settings.FpPipeToAnnulusSectionTransition  !== defaultSettings.FpPipeToAnnulusSectionTransition "
      (valueChanged)="onValueChange($event)"
    >
    </app-radio-group>

    <app-form-input
      [source]="settings"
      [sourceDefaults]="defaultSettings"
      [key]="'FpNumParamsTolDP'"
      class="grid-two-one"
      appUnwrap
      label="Pressure Tolerance"
      [decimalPlaces]="10"
      [inputWidth]="150"
      [unitType]="UnitType.Pressure"
      [highlight]="settings.FpNumParamsTolDP !== defaultSettings.FpNumParamsTolDP"
      (valueChanged)="onValueChange($event)"
    >
    </app-form-input>

    <app-form-input
      [source]="settings"
      [sourceDefaults]="defaultSettings"
      [key]="'FpOptionsKinertialValue'"
      class="grid-two-one"
      appUnwrap
      label="Kinertial Value"
      [decimalPlaces]="2"
      [unitType]="UnitType.None"
      [inputWidth]="150"
      [highlight]="settings.FpOptionsKinertialValue !== defaultSettings.FpOptionsKinertialValue"
      (valueChanged)="onValueChange($event)"
    >
    </app-form-input>

    <app-form-input
      [source]="settings"
      [sourceDefaults]="defaultSettings"
      [key]="'FpOptionsDDF'"
      class="grid-two-one"
      appUnwrap
      label="Diffusivity Multiplier"
      [decimalPlaces]="2"
      [unitType]="UnitType.None"
      [inputWidth]="150"
      [highlight]="settings.FpOptionsDDF !== defaultSettings.FpOptionsDDF"
      (valueChanged)="onValueChange($event)"
    >
    </app-form-input>

    <app-form-input
      [source]="settings"
      [sourceDefaults]="defaultSettings"
      [key]="'FpMinComputationalSections'"
      class="grid-two-one"
      appUnwrap
      label="Min Computational Sections"
      [decimalPlaces]="0"
      [inputWidth]="150"
      [unitType]="UnitType.None"
      [highlight]="settings.FpMinComputationalSections !== defaultSettings.FpMinComputationalSections"
      (valueChanged)="onValueChange($event)"
    >
    </app-form-input>

    <app-form-input
      [source]="settings"
      [sourceDefaults]="defaultSettings"
      [key]="'FpMinCellLength'"
      class="grid-two-one"
      appUnwrap
      label="Min Cell Length"
      [decimalPlaces]="2"
      [inputWidth]="150"
      [unitType]="UnitType.Long_Length"
      [highlight]="settings.FpMinCellLength !== defaultSettings.FpMinCellLength"
      (valueChanged)="onValueChange($event)"
    >
    </app-form-input>

    <app-form-input
      [source]="settings"
      [sourceDefaults]="defaultSettings"
      [key]="'FpMergeCellLength'"
      class="grid-two-one"
      appUnwrap
      label="Merge Cell Length"
      [decimalPlaces]="2"
      [inputWidth]="150"
      [unitType]="UnitType.Long_Length"
      [highlight]="settings.FpMergeCellLength !== defaultSettings.FpMergeCellLength"
      (valueChanged)="onValueChange($event)"
      dataCy="inputSettingsFpMergeCellLength"
    >
    </app-form-input>

    <app-form-input
      [source]="settings"
      [sourceDefaults]="defaultSettings"
      [key]="'FpMergeVolumeRatio'"
      class="grid-two-one"
      appUnwrap
      label="Merge Volume Ratio"
      [decimalPlaces]="3"
      [inputWidth]="150"
      [unitType]="UnitType.None"
      [highlight]="settings.FpMergeVolumeRatio !== defaultSettings.FpMergeVolumeRatio"
      (valueChanged)="onValueChange($event)"
    >
    </app-form-input>

    <app-form-input
      [source]="settings"
      [sourceDefaults]="defaultSettings"
      [key]="'FpDataComparisonTolerance'"
      class="grid-two-one"
      appUnwrap
      label="Data Comparison Tolerance"
      [decimalPlaces]="8"
      [inputWidth]="150"
      [unitType]="UnitType.None"
      [highlight]="settings.FpDataComparisonTolerance !== defaultSettings.FpDataComparisonTolerance"
      (valueChanged)="onValueChange($event)"
    >
    </app-form-input>

    <app-check-box
      [source]="settings"
      [sourceDefaults]="defaultSettings"
      [key]="'FpIsMergeDifferentTubulars'"
      class="grid-two-one"
      label="Merge Different Cell Types"
      [highlight]="settings.FpIsMergeDifferentTubulars !== defaultSettings.FpIsMergeDifferentTubulars"
      (valueChanged)="onValueChange($event)"
    >
    </app-check-box>
  </div>
</app-page-panel>


<app-page-panel header="FluidPro Developer Options" *ngIf="settings && defaultSettings" [allowFullScreen]="false" (click)="helpChanged.emit('fluidpro_dev')">
  <div class="grid-form-container">


    <app-form-input
      [source]="settings"
      [sourceDefaults]="defaultSettings"
      [key]="'FpNumParamsCFL'"
      class="grid-two-one"
      appUnwrap
      label="CFL"
      [decimalPlaces]="2"
      [inputWidth]="150"
      [unitType]="UnitType.None"
      [highlight]="settings.FpNumParamsCFL !== defaultSettings.FpNumParamsCFL"
      (valueChanged)="onValueChange($event)"
    >
    </app-form-input>
    <app-form-input
      [source]="settings"
      [sourceDefaults]="defaultSettings"
      [key]="'FpOptionsContentrationTolerance'"
      class="grid-two-one"
      appUnwrap
      label="Concentration tolerance at which displacing fluid is signalled"
      [decimalPlaces]="2"
      [unitType]="UnitType.None"
      [inputWidth]="150"
      [highlight]="settings.FpOptionsContentrationTolerance !== defaultSettings.FpOptionsContentrationTolerance"
      (valueChanged)="onValueChange($event)"
    >
    </app-form-input>


    <div>Flow Regime Method</div>
    <app-radio-group
      [items]="[
        { value: 0, text: 'Use local conditions' },
        { value: 1, text: 'Use upstream regimes' }
      ]"
      [source]="settings"
      [sourceDefaults]="defaultSettings"
      [key]="'FpOptionsFlowRegimeMethod'"
      [highlight]="settings.FpOptionsFlowRegimeMethod !== defaultSettings.FpOptionsFlowRegimeMethod"
      (valueChanged)="onValueChange($event)"
    >
    </app-radio-group>

  </div>

</app-page-panel>
