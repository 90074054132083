// !! Only first 3 valid formats will be shown to user, so this list should be sorted from most popular/relevant formats

export const dateFormats = [
  ['MM', 'DD', 'YY'],
  ['MM', 'DD', 'YYYY'],
  ['DD', 'MM', 'YY'],
  ['DD', 'MM', 'YYYY'],
  ['YYYY', 'MM', 'DD'],
  ['YY', 'MM', 'DD'],

  ['M', 'D', 'YY'],
  ['M', 'D', 'YYYY'],
  ['D', 'M', 'YY'],
  ['D', 'M', 'YYYY'],
];

export const timeFormats = [
  ['HH', 'mm', 'ss'],
  ['H', 'mm', 'ss'],
  ['H', 'm', 's'],
  ['H', 'm', 'ss'],
  ['H', 'mm', 's'],
  ['HH', 'm', 's'],
  ['HH', 'm', 'ss'],
  ['HH', 'mm', 's'],
  ['h', 'm', 's'],
  ['h', 'm', 'ss'],
  ['h', 'mm', 's'],
  ['h', 'mm', 'ss'],
  ['hh', 'm', 's'],
  ['hh', 'm', 'ss'],
  ['hh', 'mm', 's'],
  ['hh', 'mm', 'ss'],

  ['HH', 'mm', 'ss', 'SSS'],
  ['H', 'mm', 'ss', 'SSS'],
  ['H', 'mm', 's', 'SSS'],
  ['H', 'm', 's', 'SSS'],
  ['H', 'm', 'ss', 'SSS'],
  ['HH', 'm', 's', 'SSS'],
  ['HH', 'm', 'ss', 'SSS'],
  ['HH', 'mm', 's', 'SSS'],
  ['h', 'm', 's', 'SSS'],
  ['h', 'm', 'ss', 'SSS'],
  ['h', 'mm', 's', 'SSS'],
  ['h', 'mm', 'ss', 'SSS'],
  ['hh', 'm', 's', 'SSS'],
  ['hh', 'm', 'ss', 'SSS'],
  ['hh', 'mm', 's', 'SSS'],
  ['hh', 'mm', 'ss', 'SSS'],
];
