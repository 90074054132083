<app-generic-modal [title]="title" [modalButtonsConfigs]="modalButtonsConfigs">
  <div class="grid-form-container no-padding">
    <label for="chartTemplateNameInput">Template Name</label>
    <app-input
      id="chartTemplateNameInput"
      [isUiLockable]="false"
      [errorMessage]="errors.Name"
      [isTextInput]="true"
      [value]="selectedTemplate.Name"
      [align]="'left'"
      [maxWidth]="300"
      [ngStyle]="{ 'width.px': 300 }"
      [triggerOnKeyPress]="true"
      [dataCy]="'edit-chart-template-name-input'"
      (primitiveValueChanged)="onTemplateNameChange($event.value)"
    ></app-input>

    <label>Labels</label>
    <app-labels-selector
      [rangeLabels]="selectedTemplate.Labels"
      [componentWidth]="300"
      [class]="'blue-bg'"
      (labelsUpdated)="onLabelsChange($event)"
    ></app-labels-selector>

    <label>Modules</label>
    <p-multiSelect
      [(ngModel)]="selectedTemplate.Modules"
      [options]="modulesItems"
      optionValue="value"
      optionLabel="text"

      [appendTo]="'body'"
      [style]="{ width: '300px' }"
      scrollHeight="250px"

      [attr.data-cy]="'edit-chart-template-module-multiselect'"
      [class]="'blue-bg'"
      [class.error]="errors.Modules"

      [tooltip]="errors.Modules ?? ''"
      tooltipStyleClass="error-tooltip"
      tooltipPosition="top"

      placeholder="Select modules"

      (onChange)="onModulesChange()"
    >
    </p-multiSelect>

    <app-radio-group
      *ngIf="isCreateNew && !electronService.isElectronApp"
      class="col-span-2"
      dataCy="edit-chart-template-db-type-radio"
      [isUiLockable]="false"
      [items]="dbItems"
      [value]="selectedTemplate.Type"
      layout="vertical"
      id="edit-chart-template-modal-radio"
      (primitiveValueChanged)="onDbChange($event.value)"
    ></app-radio-group>
  </div>
</app-generic-modal>
