import { createSelector } from '@ngrx/store';
import { DataFileType, DataType } from '@dunefront/common/dto/data-storage';
import {
  getChartState,
  getChartTimeVolMode,
  getCurrentlyVisibleChartCompareScenarioIds,
  getDepthData,
  getReportingChartMdTvdMode,
  getReportingLowerCompletionRange,
  getResultsDepthChartDataState,
  getSelectedReportingTab,
  getSelectedSimulationTime,
} from './reporting.selectors';
import { ChartState, IDepthDataForRange, IDepthDataForScenario } from './reporting-module.state';
import { getCurrentScenarioId } from '../scenario/scenario.selectors';
import { getCurrentAppModuleType } from '../ui/ui.selectors';
import { getCurrentRangeId } from '../range/range.selectors';
import { IDictionaryWithArray } from '@dunefront/common/common/state.helpers';
import {
  ChartTimeVolMode,
  GetChartDataRequestType,
  IDepthBasedColumn,
  IDepthBasedResult,
  IDepthBasedRow,
  ResultsSourceKeyWithArg,
} from '@dunefront/common/modules/reporting/reporting-module.actions';
import { isSimulateBased, ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import { RangeConstants } from '@dunefront/common/dto/range.dto';
import {
  createChartDataColumn,
  createChartDataSet,
  createEmptyChartData,
  DepthDataStatus,
  IChartDataDtoColumn,
  IChartDataRowDto,
} from '@dunefront/common/modules/reporting/dto/chart-data.dto';
import { ChartDataSourceType } from '@dunefront/common/modules/reporting/dto/chart.dto';
import { ChartSeriesDto } from '@dunefront/common/modules/reporting/dto/chart-series.dto';
import { ChartLoadingStatus } from './model/reporting.factory';
import { rationalizeString } from '@dunefront/common/common/helpers';
import { FlowPathDescription } from '@dunefront/common/modules/pumping/dto/well-fluid.dto';
import { ArrayHelpers } from '@dunefront/common/common/array-helpers';
import { ChartMdTvdMode } from '@dunefront/common/modules/reporting/reporting.settings';
import { FlowDirection } from '@dunefront/common/modules/pipes/pipe';
import { PrimarySecondaryArgumentConverter } from '@dunefront/common/modules/reporting/dto/primary-secondary-argument-converter';

export class DepthDataHelpers {
  public static getDepthDataForScenarioRange(
    depthDataForScenarios: IDictionaryWithArray<IDepthDataForScenario>,
    scenarioId: number,
    rangeId: number,
  ): IDepthDataForRange | undefined {
    const depthDataForScenario = depthDataForScenarios.dict[scenarioId];
    if (depthDataForScenario == null) {
      return;
    }
    return depthDataForScenario.depthDataForRanges.dict[rangeId];
  }

  public static getDepthDataSimulationDuration(
    depthDataForScenarios: IDictionaryWithArray<IDepthDataForScenario>,
    scenarioId: number,
    rangeId: number,
    scenarioIdsToCompare: number[],
  ): number {
    const currentDepthData = this.getDepthDataForScenarioRange(depthDataForScenarios, scenarioId, rangeId);
    if (currentDepthData == null) {
      return 0;
    }
    const simulationDurationForCurrentScenario = currentDepthData.simulationDuration;

    // compare scenario works only for simulation, so rangeId will be always -1
    const simulationDurationsForScenariosToCompare = scenarioIdsToCompare.map(
      (scenarioIdToCompare) =>
        this.getDepthDataForScenarioRange(depthDataForScenarios, scenarioIdToCompare, RangeConstants.EmptyRangeId)?.simulationDuration ?? 0,
    );

    return Math.max(simulationDurationForCurrentScenario, ...simulationDurationsForScenariosToCompare);
  }
}

export const getDepthDataForCurrentScenarioRange = createSelector(
  getDepthData,
  getCurrentScenarioId,
  getCurrentRangeId,
  (depthData, scenarioId, rangeId) => DepthDataHelpers.getDepthDataForScenarioRange(depthData, scenarioId, rangeId),
);

function getGetSelectedDepthDataForFileType(
  depthData: IDepthDataForRange | undefined,
  simulationTime: number,
  fileType: DataFileType,
): ISelectedDepthDataWithLoading {
  if (!depthData) {
    return { isLoaded: false, data: undefined };
  }

  return {
    isLoaded: true,
    data: getSelectedDepthData(depthData.depthDataResults.dict[fileType], simulationTime),
  };
}

export const getSelectedPackingData = createSelector(
  getDepthDataForCurrentScenarioRange,
  getSelectedSimulationTime,
  getCurrentAppModuleType,
  (depthData, simulationTime, module) => {
    const fileType: DataFileType = module === ModuleType.Simulate_Disp ? DataFileType.FpFluidConcentrationResult : DataFileType.PackingResult;

    return getGetSelectedDepthDataForFileType(depthData, simulationTime, fileType);
  },
);

export const getSelectedFluidFrontData = createSelector(
  getDepthDataForCurrentScenarioRange,
  getSelectedSimulationTime,
  (depthData, simulationTime) => getGetSelectedDepthDataForFileType(depthData, simulationTime, DataFileType.FpFluidFrontResult),
);

export const getDepthDataSimulationDuration = createSelector(
  getDepthData,
  getCurrentScenarioId,
  getCurrentRangeId,
  getCurrentlyVisibleChartCompareScenarioIds,
  (depthData, currentScenarioId, rangeId, compareScenarioIds) =>
    DepthDataHelpers.getDepthDataSimulationDuration(depthData, currentScenarioId, rangeId, compareScenarioIds),
);

export const getDepthDataResultsStatus = createSelector(getDepthDataForCurrentScenarioRange, (depthDataForRange) => {
  if (!depthDataForRange) {
    return;
  }
  return depthDataForRange.depthDataResultsStatus;
});

const getReportingPropsForDepthBasedChartData = createSelector(
  getReportingLowerCompletionRange,
  getReportingChartMdTvdMode,
  getSelectedSimulationTime,
  (lowerCompletionRange, chartMdTvdMode, selectedSimulationTime) => ({
    lowerCompletionRange,
    chartMdTvdMode,
    selectedSimulationTime,
  }),
);

// region getDepthReportingChartState

function getSelectedDepthDataForScenarios(
  depthDataForCurrentScenarioRange: IDepthDataForRange,
  appModuleType: ModuleType,
  scenarioIdsToCompare: number[],
  depthData: IDictionaryWithArray<IDepthDataForScenario>,
  selectedSimulationTimeAndVolume: ISelectedSimulationTimeAndVolume,
): ISelectedDepthData[] {
  const selectedDepthDataForScenarios: IDepthDataForRange[] = [depthDataForCurrentScenarioRange];
  // do not add other scenarios if current scenario Depth Data is empty (comparison not possible)
  if (isSimulateBased(appModuleType) && !isDepthDataEmpty(depthDataForCurrentScenarioRange)) {
    for (const id of scenarioIdsToCompare) {
      const depthDataForScenario = depthData.dict[id];
      if (!depthDataForScenario) {
        continue;
      }

      const depthDataForRange = depthDataForScenario.depthDataForRanges.dict[RangeConstants.EmptyRangeId];
      if (!depthDataForRange) {
        continue;
      }

      selectedDepthDataForScenarios.push(depthDataForRange);
    }
  }

  const selectedDepthDataArr: ISelectedDepthData[] = [];
  for (const depthDataForScenario of selectedDepthDataForScenarios) {
    const selectedTime = getCorrespondingTimeForScenario(depthDataForScenario, selectedSimulationTimeAndVolume);

    for (const ddId of depthDataForScenario.depthDataResults.ids) {
      const selectedDepthData = getSelectedDepthData(depthDataForScenario.depthDataResults.dict[ddId], selectedTime);
      if (selectedDepthData) {
        selectedDepthDataArr.push(selectedDepthData);
      }
    }
  }
  return selectedDepthDataArr;
}

function getCorrespondingTimeForScenario(
  depthDataForScenario: IDepthDataForRange,
  selectedSimulationTimeAndVolume: ISelectedSimulationTimeAndVolume,
): number {
  let selectedTime = selectedSimulationTimeAndVolume.selectedSimulationTime;

  if (
    selectedSimulationTimeAndVolume.timeVolMode === ChartTimeVolMode.volume &&
    selectedSimulationTimeAndVolume.selectedSimulationVolume != null
  ) {
    selectedTime = PrimarySecondaryArgumentConverter.convertIfNeededFromTimeVolData(
      depthDataForScenario.allDataPoints,
      selectedSimulationTimeAndVolume.selectedSimulationVolume,
      'secondary-to-primary',
    );
  }

  return selectedTime;
}

export const getSelectedSimulationTimeAndVolume = createSelector(
  getSelectedSimulationTime,
  getChartTimeVolMode,
  getDepthDataForCurrentScenarioRange,
  (selectedSimulationTime, timeVolMode, depthData): ISelectedSimulationTimeAndVolume => {
    const selectedSimulationVolume =
      depthData != null && depthData.allDataPoints.length > 1
        ? PrimarySecondaryArgumentConverter.convertIfNeededFromTimeVolData(
            depthData.allDataPoints,
            selectedSimulationTime,
            'primary-to-secondary',
          )
        : undefined;

    return {
      selectedSimulationTime,
      timeVolMode,
      selectedSimulationVolume,
    };
  },
);

export const getDepthDataPointsToLoad = createSelector(
  getCurrentScenarioId,
  getDepthData,
  getCurrentAppModuleType,
  getCurrentlyVisibleChartCompareScenarioIds,
  getSelectedSimulationTimeAndVolume,
  (
    currentScenarioId,
    depthData,
    moduleType,
    currentlyVisibleChartCompareScenarioIds,
    selectedSimulationTimeAndVolume,
  ): ResultsSourceKeyWithArg[] => {
    const results: ResultsSourceKeyWithArg[] = [];
    const rangeId = RangeConstants.EmptyRangeId;
    const allScenariosToCheck = [...currentlyVisibleChartCompareScenarioIds, currentScenarioId];

    for (const scenarioId of allScenariosToCheck) {
      const depthDataForScenario = depthData.dict[scenarioId];
      if (depthDataForScenario == null) {
        continue;
      }

      const depthDataForRange = depthDataForScenario.depthDataForRanges.dict[rangeId];
      if (!depthDataForRange) {
        continue;
      }

      if (depthDataForRange.allDataPoints == null || depthDataForRange.allDataPoints.length < 2) {
        continue;
      }

      const scenarioBasedTime = getCorrespondingTimeForScenario(depthDataForRange, selectedSimulationTimeAndVolume);
      const closestPoint = ArrayHelpers.findClosest(depthDataForRange.allDataPoints, 'PumpTime', scenarioBasedTime);
      const isClosestPointLoaded = depthDataForRange.loadedDataPoints.includes(closestPoint.PumpTime);

      if (!isClosestPointLoaded) {
        results.push({
          moduleType,
          rangeId,
          scenarioId,
          argument: scenarioBasedTime,
        });
      }
    }

    return results;
  },
);

// This selector checks if 'closest' depth data for the current simulation time is fully loaded.
// It utilizes `getDepthDataPointsToLoad` which returns an array of points that need to be loaded.
// If the length of this array is zero, it indicates that all depth data points are loaded.
export const isDepthDataForCurrentSimulationTimeLoaded = createSelector(getDepthDataPointsToLoad, (pointsToLoad) => pointsToLoad.length === 0);

function isDepthDataEmpty(depthData: IDepthDataForRange): boolean {
  return depthData.simulationDuration === 0 || depthData.allDataPoints.length === 0 || depthData.depthDataResults.ids.length === 0;
}

function getSelectedDepthData(result: IDepthBasedResult | undefined, simulationTime: number): ISelectedDepthData | undefined {
  const times = result?.Times ?? [];
  if (!times.length || !result) {
    return undefined;
  }

  const packingData = ArrayHelpers.findClosest(times, 'Time', simulationTime);

  const sortedRows = [...packingData.Rows].sort((row1, row2) => row1.RowIndex - row2.RowIndex);

  return {
    Columns: result.Columns,
    Time: packingData.Time,
    Rows: sortedRows,
    FileType: result.FileType,
    ScenarioId: result.ScenarioId,
  };
}

interface ICreateChartDatasetColumnsResult {
  chartDataColumns: IChartDataDtoColumn[];
  chartToSourceColumnMapNewItems: number[];
}

function createChartColumnsForDataset(
  dataset: ISelectedDepthData,
  matchingColumns: IDepthBasedColumn[],
  chartSeries: ChartSeriesDto[],
  datasetIndex: number,
): ICreateChartDatasetColumnsResult {
  const chartDataColumns: IChartDataDtoColumn[] = [];
  const chartToSourceColumnMapNewItems: number[] = [];
  // add columns from this dataset to chart data
  let chartDataValueIndex = 0;
  for (const column of matchingColumns) {
    if (dataset.FileType === DataFileType.SurveyProfileResult && column.DataType === DataType.Bottomhole_Temperature) {
      // ignore temperature readings from survey profile as they will be overridden by circulating temperature
      break;
    }
    const dataValueIndex = dataset.Columns.findIndex((dsc) => dsc.ColumnName === column.ColumnName);
    chartToSourceColumnMapNewItems.push(dataValueIndex);

    const chartColumn: IChartDataDtoColumn = {
      ...createChartDataColumn(
        chartSeries.find((series) => series.ColumnName === column.ColumnName)?.AxisType ?? 0,
        column.DataType,
        datasetIndex,
        chartDataValueIndex++,
        column.ColumnName,
      ),
      ScenarioId: dataset.ScenarioId,
    };
    chartDataColumns.push(chartColumn);
  }

  return {
    chartDataColumns,
    chartToSourceColumnMapNewItems,
  };
}

/**
 * For Packing Data we use only Lower_Annulus rows + Workstring rows (if there is any packing in Workstring)
 * @param dataset
 */

interface IFilteredResults {
  groupedRows: IDepthBasedRow[][];
  columns: IChartDataDtoColumn[];
}

function getFilteredPackingResultRows(dataset: ISelectedDepthData, columns: IChartDataDtoColumn[]): IFilteredResults {
  const flowPathDescIndex = dataset.Columns.findIndex((column) => column.DataType === DataType.Flow_Path_Description);
  const packHeightIndex = dataset.Columns.findIndex((column) => column.DataType === DataType.Pack_Height);

  const lowerAnnulusRows = dataset.Rows.filter((row) => row.Values[flowPathDescIndex][0] === FlowPathDescription.Lower_Annulus);
  const workstringRows = dataset.Rows.filter((row) => row.Values[flowPathDescIndex][0] === FlowPathDescription.Workstring);

  const isPackingInWorkstring = workstringRows.some((row) => row.Values[packHeightIndex][0] > 0);

  return {
    groupedRows: [[...(isPackingInWorkstring ? workstringRows : []), ...lowerAnnulusRows]],
    columns,
  };
}

function getFilteredFluidConcentrationResultRows(dataset: ISelectedDepthData, columns: IChartDataDtoColumn[]): IFilteredResults {
  const flowDirectionIndex = dataset.Columns.findIndex((column) => column.DataType === DataType.Flow_Direction);

  const isRowInFlowDirection = (row: IDepthBasedRow, flowDirection: FlowDirection): boolean => {
    return row.Values[flowDirectionIndex][0] === flowDirection;
  };

  const downPathRows = dataset.Rows.filter((row) => isRowInFlowDirection(row, FlowDirection.DownFlow));
  const upPathRows = dataset.Rows.filter((row) => isRowInFlowDirection(row, FlowDirection.UpFlow));

  const updatedColumns = columns.map((col) => {
    if (col.DataType === DataType.Up_Path_Fluid_Concentration) {
      return { ...col, DataSetIndex: col.DataSetIndex + 1 };
    }
    return col;
  });

  return { groupedRows: [downPathRows, upPathRows], columns: updatedColumns };
}

function createChartDataRowsForDataset(
  dataset: ISelectedDepthData,
  chartToSourceColumnMap: number[],
  columns: IChartDataDtoColumn[],
): { groupedRows: IChartDataRowDto[][]; columns: IChartDataDtoColumn[] } {
  // create chart data rows for the new columns
  const chartDataRows: IChartDataRowDto[][] = [];
  let filteredResult: IFilteredResults = { columns, groupedRows: [dataset.Rows] };
  if (dataset.FileType === DataFileType.PackingResult) {
    filteredResult = getFilteredPackingResultRows(dataset, columns);
  } else if (dataset.FileType === DataFileType.FpFluidConcentrationResult) {
    filteredResult = getFilteredFluidConcentrationResultRows(dataset, columns);
  }

  for (const rowGroup of filteredResult.groupedRows) {
    const resultRows: IChartDataRowDto[] = [];
    for (const sourceRow of rowGroup) {
      const chartRow: IChartDataRowDto = {
        Argument: sourceRow.MD,
        SecondaryArgument: sourceRow.TVD,
        Values: [],
      };

      for (const mapping of chartToSourceColumnMap) {
        chartRow.Values.push(DepthBasedValuesConverter.convert(sourceRow.Values, mapping, dataset.FileType));
      }

      resultRows.push(chartRow);
    }
    chartDataRows.push(resultRows);
  }

  // add rows to the new dataset

  return { groupedRows: chartDataRows, columns: filteredResult.columns };
}

export class DepthBasedValuesConverter {
  public static convert(values: number[][], mapping: number, fileType: DataFileType): number | undefined {
    let value: number | undefined;
    try {
      if (fileType === DataFileType.FpFluidConcentrationResult) {
        value = values[mapping].reduce((prev, curr) => prev + curr, 0);
        value /= values[mapping].length;
      } else {
        value = values[mapping][0];
        if (value === -1) {
          value = undefined;
        }
      }
    } catch (err) {
      console.error(err);
    }
    return value;
  }
}

export const getDepthReportingChartState = createSelector(
  getReportingPropsForDepthBasedChartData,
  getChartState,
  getSelectedReportingTab,
  getDepthData,
  getDepthDataForCurrentScenarioRange,
  getCurrentAppModuleType,
  getCurrentlyVisibleChartCompareScenarioIds,
  getSelectedSimulationTimeAndVolume,
  (
    reportingProps,
    chartState,
    selectedReportingTab,
    depthData,
    depthDataForCurrentScenarioRange,
    appModuleType,
    currentlyVisibleChartCompareScenarioIds,
    selectedSimulationTimeAndVolume,
  ): ChartState | undefined => {
    if (!chartState.chartData) {
      return undefined;
    }
    if (!selectedReportingTab || selectedReportingTab.IsChartTimeVolume) {
      return undefined;
    }
    if (!depthData.ids.length) {
      return undefined;
    }
    if (!depthDataForCurrentScenarioRange || depthDataForCurrentScenarioRange.depthDataResultsStatus !== DepthDataStatus.Loaded) {
      return undefined;
    }

    const selectedDepthDataForScenarios = getSelectedDepthDataForScenarios(
      depthDataForCurrentScenarioRange,
      appModuleType,
      currentlyVisibleChartCompareScenarioIds,
      depthData,
      selectedSimulationTimeAndVolume,
    );

    const allColumnNames = chartState.chartSeries.map((series) => series.ColumnName);

    // derive chart data from packing data
    const reportingChartData = createEmptyChartData(
      DataType.Measured_Depth,
      ChartDataSourceType.ChartSourceReportingTab,
      chartState.chartData.ChartId,
      selectedReportingTab.RangeId,
      DataType.True_Vertical_Depth,
    );

    reportingChartData.IsPrimaryArgument = reportingProps.chartMdTvdMode === ChartMdTvdMode.md;

    for (const dataset of selectedDepthDataForScenarios) {
      const matchingColumns = dataset.Columns.filter((column) => allColumnNames.includes(column.ColumnName));
      if (!matchingColumns.length) {
        continue;
      }

      const chartToSourceColumnMap: number[] = [];

      // add columns from this dataset to chart data
      const { chartDataColumns, chartToSourceColumnMapNewItems } = createChartColumnsForDataset(
        dataset,
        matchingColumns,
        chartState.chartSeries,
        reportingChartData.ChartDataSets.length,
      );

      reportingChartData.ChartDataColumns.push(...chartDataColumns);
      chartToSourceColumnMap.push(...chartToSourceColumnMapNewItems);

      const { groupedRows, columns } = createChartDataRowsForDataset(dataset, chartToSourceColumnMap, reportingChartData.ChartDataColumns);

      for (const rows of groupedRows) {
        // add dataset to chart
        reportingChartData.ChartDataSets.push(createChartDataSet(rows));
      }

      reportingChartData.ChartDataColumns = columns;
    }

    return { ...chartState, chartData: reportingChartData };
  },
);

export const getDepthSimulationChartState = createSelector(
  getResultsDepthChartDataState,
  getReportingPropsForDepthBasedChartData,
  getDepthData,
  getDepthDataForCurrentScenarioRange,
  getCurrentAppModuleType,
  getCurrentlyVisibleChartCompareScenarioIds,
  getSelectedSimulationTimeAndVolume,
  (
    resultsDepthChartDataState,
    reportingProps,
    depthData,
    depthDataForCurrentScenarioRange,
    appModuleType,
    currentlyVisibleChartCompareScenarioIds,
    selectedSimulationTimeAndVolume,
  ): ChartState | undefined => {
    if (!depthData.ids.length || !depthDataForCurrentScenarioRange) {
      return undefined;
    }

    const chartId = resultsDepthChartDataState?.chartData?.ChartId;
    if (chartId == null) {
      return undefined;
    }

    const { chartSeries } = resultsDepthChartDataState;
    const allColumnNames = chartSeries.map((series) => series.ColumnName);
    const { rangeId } = depthDataForCurrentScenarioRange;

    const selectedDepthDataForScenarios = getSelectedDepthDataForScenarios(
      depthDataForCurrentScenarioRange,
      appModuleType,
      currentlyVisibleChartCompareScenarioIds,
      depthData,
      selectedSimulationTimeAndVolume,
    );

    // derive chart data from packing data
    const chartData = createEmptyChartData(
      DataType.Measured_Depth,
      ChartDataSourceType.ChartSourceResultsDepthBased,
      chartId,
      rangeId,
      DataType.True_Vertical_Depth,
    );

    chartData.IsPrimaryArgument = reportingProps.chartMdTvdMode === ChartMdTvdMode.md;

    for (const depthData of selectedDepthDataForScenarios) {
      const matchingColumns = depthData.Columns.filter((column) => allColumnNames.some((col) => areColumnNamesEqual(col, column.ColumnName)));
      if (!matchingColumns.length) {
        continue;
      }

      const chartToSourceColumnMap: number[] = [];

      // add columns from this depthData to chart data
      const { chartDataColumns, chartToSourceColumnMapNewItems } = createChartColumnsForDataset(
        depthData,
        matchingColumns,
        chartSeries,
        chartData.ChartDataSets.length,
      );

      chartData.ChartDataColumns.push(...chartDataColumns);
      chartToSourceColumnMap.push(...chartToSourceColumnMapNewItems);

      const { groupedRows, columns } = createChartDataRowsForDataset(depthData, chartToSourceColumnMap, chartData.ChartDataColumns);

      for (const rows of groupedRows) {
        // add depthData to chart
        chartData.ChartDataSets.push(createChartDataSet(rows));
      }

      chartData.ChartDataColumns = columns;
    }

    return {
      chartSeries,
      chartData,
      chartDataSourceType: ChartDataSourceType.ChartSourceResultsDepthBased,
      chartLoadingStatus: ChartLoadingStatus.loaded,
      requestType: GetChartDataRequestType.Initial,
    };
  },
);

const areColumnNamesEqual = (col1: string, col2: string): boolean =>
  rationalizeString(col1).replace('.', '') === rationalizeString(col2).replace('.', '');

// endregion

export interface ISelectedDepthData {
  FileType: DataFileType;
  Columns: IDepthBasedColumn[];
  Time: number;
  Rows: IDepthBasedRow[];
  ScenarioId: number;
}

export interface ISelectedSimulationTimeAndVolume {
  timeVolMode: ChartTimeVolMode;
  selectedSimulationTime: number;
  selectedSimulationVolume: number | undefined;
}

export interface ISelectedDepthDataWithLoading {
  isLoaded: boolean;
  data: ISelectedDepthData | undefined;
}
