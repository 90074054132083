import { AboutModuleState } from './about.reducer';
import { AboutServerDto } from '@dunefront/common/dto/about-server.dto';

export const aboutInitialState: AboutModuleState = { licenseWithSessions: [] };

export class AboutModuleReducerHelper {
  public static aboutServerInfo(state: AboutModuleState, serverInfo: AboutServerDto): AboutModuleState {
    return { ...state, serverInfo };
  }
}
