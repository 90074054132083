import { PSDDto, PSDInputDto, PSDInputType } from '../../dto/psd.dto';
import { DictionaryWithArray, IDictionaryWithArray } from '../../../../common/state.helpers';
import { PSD, PSDResult } from './psd';
import { PsdReading } from '../psd-readings/psd-reading';
import { PsdReadingsFactory } from '../psd-readings/psd-readings.factory';

export class PSDFactory {
  public static create(psdDtos: PSDDto[]): IDictionaryWithArray<PSD> {
    const psds = psdDtos.map((psdDto) => this.createPsd(psdDto));
    return DictionaryWithArray.create(psds, 'Id');
  }

  public static newPsd(scenarioId: number, description?: string, psdInputType: PSDInputType = PSDInputType.Weight): PSD {
    return {
      Id: -1,
      ScenarioId: scenarioId,
      Description: description ?? '',
      PSDInputType: psdInputType,
      Depth: 0,
      IsSelected: false,
      SortOrder: -1,
      isValid: true,
      result: this.getEmptyResult(-1),
      error: {},
    };
  }

  public static createPsd(psdDto: PSDDto): PSD {
    return {
      ...psdDto,
      isValid: true,
      result: this.getEmptyResult(psdDto.Id),
      error: {},
    };
  }

  public static psdToDto(psd: PSD): PSDDto {
    const { error, isValid, ...dto } = psd;
    return dto;
  }

  public static psdToPsdWitReadingsDto(psd: PSD, psdReadings: PsdReading[]): PSDInputDto {
    const psdWithReadings: PSDInputDto = {
      ...this.psdToDto(psd),
      PSDReadings: psdReadings.map((reading) => PsdReadingsFactory.psdReadingsToDto(reading)),
    };
    return psdWithReadings;
  }

  public static getEmptyResult(id: number): PSDResult {
    return {
      Id: id,
      D10: 0,
      D40: 0,
      D50: 0,
      D70: 0,
      D90: 0,
      D95: 0,
      PercentFines: 0,
      UniformityCoefficient: 0,
      SortingCoefficient: 0,
      IsFullAnalysis: false,
      Depth: 0,
      CoberlyCriteria: '',
      Description: '',
      SaucierCriteria: '',
      GravelSize: 0,
      GulfCoastCriteria: '',
      MalbrelCriteria: '',
      MaxPenberthyCopeOpening: 0,
      MinPenberthyCopeOpening: 0,
      PenberthyCopeCriteria: '',
      ProducedSandMass: 0,
      ProducedSandMassCriteria: '',
      RowIndex: 0,
      ScreenOpeningGridValue: '',
    };
  }
}
