import { IDataRowWithCode, IReportTableColumnConfig } from '../common-report-data-helpers';
import { ValidatedCompletionModuleState } from '@dunefront/common/modules/completion/completion-module.state';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { ColumnType } from '../../../../../components/grid/grid.interfaces';

export const completionGeneralConfig = (
  showCentralizerOd: boolean,
): IReportTableColumnConfig<IDataRowWithCode<ValidatedCompletionModuleState>>[] => {
  const config: IReportTableColumnConfig<IDataRowWithCode<ValidatedCompletionModuleState>>[] = [
    {
      colId: 'HasCentralizers',
      headerText: 'Centralizers',
      unitSystem: UnitSystem.None,
      type: ColumnType.text,
    },

    {
      colId: 'WashpipeEccentricity',
      headerText: 'Washpipe Eccentricity',
      unitSystem: UnitSystem.None,
      type: ColumnType.number,
    },
  ];

  const centralizerOD: IReportTableColumnConfig<IDataRowWithCode<ValidatedCompletionModuleState>> = {
    colId: 'CentralizerOD',
    headerText: 'Centralizer OD',
    unitSystem: UnitSystem.Diameter,
    type: ColumnType.number,
  };

  if (showCentralizerOd) {
    return [...config.slice(0, 1), centralizerOD, ...config.slice(1)];
  }

  return config;
};
