export interface NoteDto {
  Id: number;
  Note: string;
  RangeId: number;
  ScenarioId: number;
  SortOrder: number;
}

export const newNoteDto = (ScenarioId: number, RangeId: number): NoteDto => ({
  Id: -1,
  Note: '',
  ScenarioId,
  RangeId,
  SortOrder: -1,
});
