import { NgModule } from '@angular/core';
import { GaugeDataPageModule } from '../gauge-data-page/gauge-data-page.module';
import { CommonModule } from '@angular/common';
import { DataAnalysisPageComponent } from './data-analysis-page.component';
import { RouterModule } from '@angular/router';
import { ChartModule } from '../../common-modules/chart/chart.module';
import { AnalysisDataModule } from '../common/analysis-data/analysis-data.module';
import { SelectModule } from '../../shared/components/form-components/select/select.module';
import { UnwrapDirectiveModule } from '../../shared/directives/unwrap.directive';
import { FormInputModule } from '../../shared/components/form-components/form-input/form-input.component';
import { PagePanelModule } from '../../common-modules/page-panel/page-panel.component';
import { TabbedPageModule } from '../../common-modules/tabbed-page/tabbed-page.component';
import { BreadcrumbsModule } from '../../shared/components/breadcrumbs/breadcrumb.component';
import { LeftNavModule } from '../../shared/components/left-nav/left-nav.component';
import { ModalsModule } from '../../common-modules/modals/modals.module';

@NgModule({
  imports: [
    CommonModule,
    GaugeDataPageModule,
    RouterModule,
    ChartModule,
    ModalsModule,
    AnalysisDataModule,
    SelectModule,
    UnwrapDirectiveModule,
    FormInputModule,
    PagePanelModule,
    TabbedPageModule,
    BreadcrumbsModule,
    LeftNavModule,
  ],
  declarations: [DataAnalysisPageComponent],
})
export class DataAnalysisPageModule {}
