import { Chart } from 'chart.js/dist/types';
import { getState } from './state';
import { getChartDrawingArea } from '../plugins-common-helpers';
import { borderDashForLineStyle } from '../chart-component-helpers/chart-misc-helpers';
import { Point } from 'chart.js';
import {
  diagonalLen,
  getPointsAngle,
  getRectWithBottomLeftCorner,
  getRectWithBottomRightCorner,
  getRectWithCenter,
  getRectWithTopLeftCorner,
  getRectWithTopRightCorner,
  Rect,
  rectCenter,
  rectFitsInside,
  segmentCenter,
  segmentLength,
  Size,
} from '@dunefront/common/common/math-geometry-helpers';
import { drawingGradientLineWithOffsets, getGradientLineParams, pointWithOffset } from './helpers';
import {
  DrawingGradientLine,
  GradientLineAxisParameters,
  MeasuredTooltipDrawingCell,
  MeasuredTooltipDrawingData,
  MeasuredTooltipDrawingItem,
  TooltipDrawingCell,
  TooltipDrawingData,
  TooltipDrawingRow,
} from './types';
import {
  getCanvasFontString,
  getRoundedRectPath,
  measureText,
  TextDrawStyle,
  transparencyPercentageToHexAlpha,
} from '../../../shared/helpers/canvas-drawing-helpers';
import { minLineLength } from './constants';

const opaqueLineTransparency = 0;
const semiTransparentLineTransparency = 35;

const dragHandleFillColor = '#FFF'; // white;
const dragHandleStrokeColor = '#555';
const dragHandleLineWidth = 1;
const tooltipCornerRadius = 6;
const resizeHandleRadius = 4;

const tooltipFillColor = '#000';
const tooltipFillTransparency = 20;
const tooltipBorderLineColor = '#000';
const tooltipBorderLineWidth = 1;
const tooltipTextPadding = 10;
const tooltipBoxToLineSpacing = 6;
const tooltipMaxColWidth = 300;
const tooltipColSpacing = 12;
const tooltipRowSpacing = 6;
const tooltipCellItemSpacing = 4;

const defaultTextStyle: TextDrawStyle = {
  fontSize: 12,
  fontColour: '#DDD',
  italic: false,
  bold: false,
};

const headingTextStyle: TextDrawStyle = {
  ...defaultTextStyle,
  bold: true,
};

const valueTextStyle: TextDrawStyle = {
  ...defaultTextStyle,
  fontColour: '#FFF',
  bold: true,
};

const unitTextStyle: TextDrawStyle = {
  ...defaultTextStyle,
  fontColour: '#AAA',
};

export const drawGradientLines = (chart: Chart): void => {
  const state = getState(chart);
  const { lines, options, highlightedGradientLineId, editedGradientLine, createdGradientLine, dragOffsetsPoint1, dragOffsetsPoint2 } =
    state;
  const { defaultStyle } = options;
  const { ctx } = chart;

  ctx.save();

  const drawingRect = getChartDrawingArea(chart);

  // apply clipping rect
  const clippingPath = new Path2D();
  clippingPath.rect(drawingRect.x, drawingRect.y, drawingRect.width, drawingRect.height);
  ctx.clip(clippingPath);

  const linesToDraw = createdGradientLine != null ? [...lines, createdGradientLine] : lines;

  const isAnyHighlighted = highlightedGradientLineId != null || editedGradientLine != null;

  for (const line of linesToDraw) {
    const isEdited = editedGradientLine?.id === line.id;

    const lineWithOffsets = isEdited ? drawingGradientLineWithOffsets(line, dragOffsetsPoint1, dragOffsetsPoint2) : line;
    const { point1, point2, style, id } = lineWithOffsets;

    const isHighlighted = highlightedGradientLineId === id || editedGradientLine?.id === id;
    const lineThickness = style.GradientLineThickness ?? defaultStyle.GradientLineThickness;
    const lineStyle = style.GradientLineStyle ?? defaultStyle.GradientLineStyle;

    ctx.lineWidth = isHighlighted ? lineThickness + 1 : lineThickness;
    ctx.strokeStyle = style.GradientLineColor ?? defaultStyle.GradientLineColor;

    const lineTransparency = isHighlighted || !isAnyHighlighted ? opaqueLineTransparency : semiTransparentLineTransparency;
    const alphaHex = transparencyPercentageToHexAlpha(lineTransparency);
    ctx.strokeStyle = ctx.strokeStyle + alphaHex;
    ctx.setLineDash(borderDashForLineStyle(lineStyle, lineThickness) ?? []);

    // draw main line
    ctx.beginPath();
    ctx.moveTo(point1.x, point1.y);
    ctx.lineTo(point2.x, point2.y);
    ctx.stroke();

    // draw drag handles
    if (isHighlighted && state.options.mode === 'edit') {
      ctx.setLineDash([]);
      ctx.fillStyle = dragHandleFillColor;
      ctx.strokeStyle = dragHandleStrokeColor;
      ctx.lineWidth = dragHandleLineWidth;

      drawDragHandle(point1, ctx);
      drawDragHandle(point2, ctx);

      if (segmentLength(lineWithOffsets) >= minLineLength) {
        drawDragHandle(segmentCenter(point1, point2), ctx);
      }
    }
  }

  // draw tooltip on the top of all lines
  const lineWithTooltip = editedGradientLine ?? lines.find((line) => line.id === highlightedGradientLineId);

  if (lineWithTooltip) {
    const lineWithOffsets = drawingGradientLineWithOffsets(lineWithTooltip, dragOffsetsPoint1, dragOffsetsPoint2);
    if (segmentLength(lineWithOffsets) >= minLineLength) {
      drawInfoTooltip(lineWithOffsets, chart);
    }
  }

  ctx.restore();
};

const drawDragHandle = (point: Point, ctx: CanvasRenderingContext2D): void => {
  ctx.beginPath();
  ctx.arc(point.x, point.y, resizeHandleRadius, 0, 2 * Math.PI);
  ctx.fill();
  ctx.stroke();
};

export const drawTooltipBackground = (rect: Rect, ctx: CanvasRenderingContext2D): void => {
  const alphaHex = transparencyPercentageToHexAlpha(tooltipFillTransparency);

  // this is a little trick:
  // fillColor holds color name (eg 'green'), needs to get converted to hex in order to let us combine with alphaHex
  // assigning it to fillStyle and then reading in next line does the trick (read value is hex)
  ctx.fillStyle = tooltipFillColor;
  ctx.fillStyle = ctx.fillStyle + alphaHex;
  ctx.lineWidth = tooltipBorderLineWidth;
  ctx.strokeStyle = tooltipBorderLineColor;
  ctx.strokeStyle = ctx.strokeStyle + alphaHex;
  ctx.setLineDash([]);

  const entireCanvasRectPath = new Path2D();
  entireCanvasRectPath.rect(0, 0, ctx.canvas.width, ctx.canvas.height);

  const rectPath = getRoundedRectPath(rect, tooltipCornerRadius);

  // draw text box border
  ctx.save();
  ctx.stroke(rectPath);
  ctx.restore();

  // fill text box
  ctx.fill(rectPath);
};

export const drawInfoTooltip = (line: DrawingGradientLine, chart: Chart): void => {
  const { ctx } = chart;

  const params = getGradientLineParams(line, chart);
  const tooltipData = gradientLineAxisParametersToTooltipData(params);
  const measuredTooltipData = toMeasuredTooltipDrawingData(tooltipData, ctx, defaultTextStyle);
  const paramsTextBoxSize: Size = {
    width: measuredTooltipData.size.width + tooltipTextPadding * 2,
    height: measuredTooltipData.size.height + tooltipTextPadding * 2,
  };

  const textBox = getTextBoxRect(line, paramsTextBoxSize, chart);

  drawTooltipBackground(textBox, ctx);

  const textDrawingOrigin: Point = {
    x: textBox.x + tooltipTextPadding,
    y: textBox.y + tooltipTextPadding,
  };
  drawTooltipText(measuredTooltipData, textDrawingOrigin, ctx, defaultTextStyle);
};

const drawTooltipText = (
  data: MeasuredTooltipDrawingData,
  { x, y }: Point,
  ctx: CanvasRenderingContext2D,
  defaultTextStyle: TextDrawStyle,
): void => {
  ctx.textBaseline = 'middle';
  if (defaultTextStyle != null) {
    ctx.fillStyle = defaultTextStyle.fontColour;
    ctx.font = getCanvasFontString(defaultTextStyle);
  }

  let rowY = y;

  for (const row of data.rows) {
    let colX = x;
    let rowHeight = 0;

    for (let cellIndex = 0; cellIndex < row.cells.length; cellIndex++) {
      let itemX = 0;
      const cell = row.cells[cellIndex];

      for (const item of cell.items) {
        const spaceLeftInCell = data.columnWidths[cellIndex] - itemX;
        const position = { x: colX + itemX, y: rowY + item.size.height / 2 }; // textBaseline is set to 'middle'

        drawText(item.text, ctx, position, spaceLeftInCell, item.style ?? defaultTextStyle);

        itemX += item.size.width + tooltipCellItemSpacing;
      }

      colX += data.columnWidths[cellIndex] + tooltipColSpacing;
      rowHeight = Math.max(rowHeight, cell.measuredSize.height);
    }

    rowY += rowHeight + tooltipRowSpacing;
  }
};

const drawText = (text: string, context: CanvasRenderingContext2D, { x, y }: Point, maxWidth: number, style: TextDrawStyle): void => {
  const ellipsis = '\u{2026}';

  context.font = `${style.italic ? 'italic' : ''} ${style.bold ? 'bold ' : ' '}  ${style.fontSize}px Arial`;
  context.fillStyle = style.fontColour;

  let textWidth = context.measureText(text).width;
  let truncatedText = text;

  if (textWidth > maxWidth) {
    // text is too wide, needs to get truncated
    while (textWidth > maxWidth && truncatedText.length > 0) {
      truncatedText = truncatedText.slice(0, -1);
      textWidth = context.measureText(truncatedText + ellipsis).width;
    }

    truncatedText += ellipsis;
  }

  context.fillText(truncatedText, x, y);
};

const measureCell = (cell: TooltipDrawingCell, ctx: CanvasRenderingContext2D, textStyle: TextDrawStyle): MeasuredTooltipDrawingCell => {
  let width = 0;
  let height = 0;

  const measuredItems: MeasuredTooltipDrawingItem[] = [];

  for (const item of cell.items) {
    const size = measureText(ctx, item.text, item.style ?? textStyle);
    width += size.width;
    if (size.height > height) {
      height = size.height;
    }

    measuredItems.push({
      ...item,
      size,
    });
  }

  const spacings = Math.max(cell.items.length - 1, 0) * tooltipCellItemSpacing;
  width += spacings;

  return {
    ...cell,
    items: measuredItems,
    measuredSize: { width, height },
  };
};

const toMeasuredTooltipDrawingData = (
  data: TooltipDrawingData,
  ctx: CanvasRenderingContext2D,
  textStyle: TextDrawStyle,
): MeasuredTooltipDrawingData => {
  // map rows to measured rows
  const rows = data.rows.map((row) => ({
    cells: row.cells.map((cell) => measureCell(cell, ctx, textStyle)),
  }));

  const columnWidths: number[] = [];
  const size: Size = {
    width: 0,
    height: 0,
  };

  for (const row of rows) {
    let rowHeight = 0;
    for (let i = 0; i < row.cells.length; i++) {
      const cell = row.cells[i];
      const maxCellWidth = data.columnMaxWidths?.[i] ?? tooltipMaxColWidth;
      const cellEffectiveWidth = Math.min(cell.measuredSize.width, maxCellWidth);
      const colWidth = columnWidths[i] ?? 0;

      columnWidths[i] = Math.max(cellEffectiveWidth, colWidth);

      rowHeight = Math.max(rowHeight, cell.measuredSize.height);
    }

    size.height += rowHeight;
  }

  size.height += Math.max(rows.length - 1, 0) * tooltipRowSpacing;

  for (const colWidth of columnWidths) {
    size.width += colWidth;
  }
  size.width += Math.max(columnWidths.length - 1, 0) * tooltipColSpacing;

  return { rows, columnWidths, size };
};

export const getTextBoxRect = ({ point1, point2 }: DrawingGradientLine, boxSize: Size, chart: Chart): Rect => {
  const lineCenter = segmentCenter(point1, point2);
  const angleRadians = getPointsAngle(point1, point2);

  const normalisedAngle = angleRadians >= 0 ? angleRadians : angleRadians + Math.PI;
  const isLineMoreHorizontal = normalisedAngle <= Math.PI / 4 || normalisedAngle >= Math.PI * (3 / 4);

  const drawingRect = getChartDrawingArea(chart);

  const distanceToLineCenter = ((isLineMoreHorizontal ? boxSize.width : boxSize.height) / 2) * Math.cos(angleRadians * 2);

  const anchorsOnSegment = anchorsOnLine(angleRadians, lineCenter, distanceToLineCenter);

  const anchor0Points = perpendicularPoints(angleRadians, anchorsOnSegment[0], tooltipBoxToLineSpacing);
  const anchor1Points = perpendicularPoints(angleRadians, anchorsOnSegment[1], tooltipBoxToLineSpacing);

  let textBoxRects: [Rect, Rect];

  if (angleRadians > 0 && angleRadians < Math.PI / 2) {
    textBoxRects = [getRectWithBottomRightCorner(anchor0Points[0], boxSize), getRectWithTopLeftCorner(anchor1Points[1], boxSize)];
  } else if (angleRadians <= 0 && angleRadians > -(Math.PI / 2)) {
    textBoxRects = [getRectWithBottomLeftCorner(anchor1Points[0], boxSize), getRectWithTopRightCorner(anchor0Points[1], boxSize)];
  } else if (angleRadians < -(Math.PI / 2)) {
    textBoxRects = [getRectWithBottomRightCorner(anchor1Points[1], boxSize), getRectWithTopLeftCorner(anchor0Points[0], boxSize)];
  } /* angleRadians > Math.PI / 2 */ else {
    textBoxRects = [getRectWithBottomLeftCorner(anchor0Points[1], boxSize), getRectWithTopRightCorner(anchor1Points[0], boxSize)];
  }

  const fittingRects: Rect[] = [];

  for (const rect of textBoxRects) {
    if (rectFitsInside(rect, drawingRect)) {
      fittingRects.push(rect);
    }
  }

  if (fittingRects.length === 0) {
    const boxDiagonal = diagonalLen(boxSize);
    const lineLength = segmentLength({ point1, point2 });
    const anchors2 = anchorsOnLine(angleRadians, lineCenter, lineLength / 2 + boxDiagonal / 2 + tooltipBoxToLineSpacing);

    for (const an of anchors2) {
      const rect = getRectWithCenter(an, boxSize);
      if (rectFitsInside(rect, drawingRect)) {
        fittingRects.push(rect);
      }
    }
  }

  if (fittingRects.length === 0) {
    fittingRects.push(getRectWithCenter(rectCenter(drawingRect), boxSize));
  }

  return fittingRects[0];
};

export const anchorsOnLine = (lineAngle: number, lineCenter: Point, distanceToCenter: number): [Point, Point] => [
  pointWithOffset(lineCenter, {
    offsetY: -distanceToCenter * Math.sin(lineAngle),
    offsetX: distanceToCenter * Math.cos(lineAngle),
  }),
  pointWithOffset(lineCenter, {
    offsetY: distanceToCenter * Math.sin(lineAngle),
    offsetX: -distanceToCenter * Math.cos(lineAngle),
  }),
];

export const perpendicularPoints = (lineAngle: number, pointOnLine: Point, pointsDistance: number): [Point, Point] => [
  {
    x: pointOnLine.x + Math.cos(lineAngle + Math.PI / 2) * pointsDistance,
    y: pointOnLine.y - Math.sin(lineAngle + Math.PI / 2) * pointsDistance,
  },
  {
    x: pointOnLine.x - Math.cos(lineAngle + Math.PI / 2) * pointsDistance,
    y: pointOnLine.y + Math.sin(lineAngle + Math.PI / 2) * pointsDistance,
  },
];

const gradientLineAxisParametersToTooltipData = (paramsArr: GradientLineAxisParameters[]): TooltipDrawingData => {
  const rows: TooltipDrawingRow<TooltipDrawingCell>[] = [];

  // add headers
  rows.push({
    cells: [
      { items: [{ text: 'Axis', style: headingTextStyle }] },
      { items: [{ text: 'Delta', style: headingTextStyle }] },
      { items: [{ text: 'Gradient', style: headingTextStyle }] },
    ],
  });

  const valToText = (value: number, decimalPlaces: number): string => parseFloat(value.toFixed(decimalPlaces)).toString();

  for (const { title, delta, gradient, decimalPlaces } of paramsArr) {
    const cells: TooltipDrawingCell[] = [
      // axis name
      { items: [{ text: title }] },
      // delta
      {
        items: [
          { text: valToText(delta.value, decimalPlaces), style: valueTextStyle },
          { text: delta.unit, style: unitTextStyle },
        ],
      },
    ];

    if (gradient != null) {
      cells.push({
        items: [
          { text: valToText(gradient.value, decimalPlaces).toString(), style: valueTextStyle },
          { text: gradient.unit, style: unitTextStyle },
        ],
      });
    } else {
      cells.push({
        items: [{ text: '---', style: unitTextStyle }],
      });
    }

    rows.push({ cells });
  }

  return { rows };
};
