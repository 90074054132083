import { DbDependentComponent } from '../../../common-modules/db-connection/db-dependent.component';
import { ChangeDetectorRef, Component, HostBinding, ViewChild } from '@angular/core';
import { ITableRow } from '@dunefront/common/common/common-grid.interfaces';
import { GridHelpers } from './grid.helpers';
import { Store } from '@ngrx/store';
import { ModalService } from '../../../common-modules/modals/modal.service';
import { GridComponent, ISelectedRowProps } from './grid.component';
import { IScreenSize, ScreenService } from '../../services';
import { IIndexedDataType } from '@dunefront/common/dto/common-dto.interfaces';
import { IUpdateTableRowsProps } from '@dunefront/common/common/common-store-crud.interfaces';

@Component({
  template: '',
})
export abstract class PageWithGridComponent<T extends IIndexedDataType> extends DbDependentComponent {
  @HostBinding('class.row-selected')
  public selectedRow: ITableRow<T> | undefined;

  @ViewChild('gridComponent')
  public gridComponent: IGridComponent<T> | undefined;

  public selectedRowIndex?: number;
  protected gridHelpers!: GridHelpers;

  protected constructor(
    store: Store,
    cdRef: ChangeDetectorRef,
    protected modalService: ModalService,
    protected resizeService: ScreenService,
  ) {
    super(store, cdRef);
    this.gridHelpers = new GridHelpers(modalService);

    this.subscription.add(
      this.resizeService.screenResized$.subscribe((size) => {
        this.gridComponent?.resize(size);
      }),
    );
  }

  public onSelectedRowsChanged(event: ISelectedRowProps[]): void {
    if (event.length && event[0] != null && this.getRows().length) {
      this.selectedRowIndex = event[0].rowIndex;
    } else {
      this.selectedRowIndex = undefined;
    }

    this.setSelectedRow();
  }

  public setSelectedRow(): void {
    if (this.selectedRowIndex !== undefined) {
      const selectedRow = this.getRows()[this.selectedRowIndex];
      this.selectedRow = selectedRow?.rowType === 'insert-row' ? undefined : selectedRow;
    } else {
      this.selectedRow = undefined;
    }
    this.gridComponent?.resize();
  }

  public abstract getRows(): ITableRow<T>[];

  protected abstract updateRow(row: IUpdateTableRowsProps<any>): void;

  public onAdd(): void {
    this.gridComponent?.grid.onInsertClicked(true, undefined, 'add');
  }

  public onImport(needsRowSelection = false): void {
    this.gridComponent?.grid.onImportClicked(needsRowSelection);
  }

  public onInsert(): void {
    this.gridComponent?.grid.onInsertClicked(true, undefined, 'insert');
  }

  public onDelete(): void {
    this.gridComponent?.grid.onDeleteClicked();
  }
}

export interface IGridComponent<T extends IIndexedDataType> {
  grid: GridComponent<T>;

  resize(size?: IScreenSize): void;
}
