<div class="buttons-panel">
  <div class="align-right inline-btns">
    <button
      appButton
      type="button"
      class="btn btn-primary btn-sm"
      [disabled]="prevDisabled"
      [isUiLockable]="false"
      [isDisabledWhenCalculationActive]="false"
      (buttonClick)="navigate('prev')"
    >
      Prev
    </button>
    <button
      appButton
      type="button"
      class="btn btn-primary btn-sm"
      [disabled]="nextDisabled"
      [isUiLockable]="false"
      [isDisabledWhenCalculationActive]="false"
      (buttonClick)="navigate('next')"
    >
      Next
    </button>
  </div>
</div>
