<app-page-panel header="Trend Analysis Chart" [panelHelpMode]="PanelHelpMode.CHART" [noHeader]="noHeader">
  <ng-container *ngIf="trendAnalysisErrorType$ | async as trendAnalysisErrorType">
    <div *ngIf="trendAnalysisErrorType === 'no-error'; else noData" class="trend-analysis-chart"
         data-cy="trend-analysis-chart">
      <app-chart-controller
        [chartId]="chartId"
        [config]="config"
        [chartState]="chartState$ | async"
        [chartDisplayName]="chartDisplayName"
        [drawableProviderId]="drawableProviderId ?? chartDisplayName"
        [allowedChangingXYShift]="AllowedXYShiftAxis.y"
        [isVerticalShiftEnabled]="true"
      >
      </app-chart-controller>
    </div>

    <ng-template #noData>
      <app-no-trend-analysis-message [messageType]="trendAnalysisErrorType"></app-no-trend-analysis-message>
    </ng-template>
  </ng-container>
</app-page-panel>
