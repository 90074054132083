import { Routes } from '@angular/router';
import { SummaryComponent } from './summary/summary.component';
import { ResultsComponent } from './results.component';
import { SimulationAnimationComponent } from './animation/simulation-animation.component';
import { WellVisualizationComponent } from './well-visualization/well-visualization.component';
import { RouteModuleResults, RouteSimulateAnimation, RouteSummary, RouteWellVisualization } from '../simulate-evaluate-routes-names';

export const resultsSimulateRoutes: Routes = [
  {
    path: RouteModuleResults,
    component: ResultsComponent,
    data: {
      breadcrumb: 'Results',
    },
    children: [
      {
        path: RouteSimulateAnimation,
        component: SimulationAnimationComponent,
        data: {
          breadcrumb: 'Animation',
        },
      },
      {
        path: RouteWellVisualization,
        component: WellVisualizationComponent,
        data: {
          breadcrumb: 'Well Visualization',
        },
      },
      {
        path: RouteSummary,
        component: SummaryComponent,
        data: {
          breadcrumb: 'Summary',
        },
      },
      {
        path: '',
        redirectTo: RouteSimulateAnimation,
        pathMatch: 'full',
      },
    ],
  },
];
