import { Injectable } from '@angular/core';
import { map, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { ModalService } from '../../modals/modal.service';
import { ChartLegendPropsComponent } from '../chart-legend/chart-legend-props.component';
import { getChartDtos } from '../../../+store/reporting/reporting.selectors';
import { updateChartAction } from '../../../+store/reporting/reporting.actions';
import { ILegendStyle } from '@dunefront/common/modules/reporting/dto/chart-legend';
import { filterNil } from '@dunefront/common/common/state.helpers';
import { concatLatestFrom } from '@ngrx/operators';
import { ChartDto } from '@dunefront/common/modules/reporting/dto/chart.dto';

@Injectable()
export class ChartPropertiesService {
  private readonly chartDto$: Observable<ChartDto> = combineLatest([this.chartId$, this.store.select(getChartDtos)]).pipe(
    map(([chartId, chartDtos]) => chartDtos.find((chart) => chart.Id === chartId)),
    filterNil(),
  );

  constructor(
    private store: Store,
    private modalService: ModalService,
    private chartId$: Observable<number>,
  ) {}

  public get chart(): Observable<ChartDto> {
    return this.chartDto$;
  }

  public onConfigureLegend(editLegendProps: ILegendStyle): void {
    const dialog = this.modalService.open(ChartLegendPropsComponent, { editLegendProps });

    const dialogWithChartSub = dialog.onClose.pipe(concatLatestFrom(() => this.chartDto$));

    dialogWithChartSub.pipe(take(1)).subscribe(([dialogResult, chart]) => {
      if (dialogResult == null) {
        return;
      }

      const updateChartBody: ChartDto = { ...chart, ...dialogResult };
      this.store.dispatch(updateChartAction([updateChartBody]));
    });
  }
}
