import { Routes } from '@angular/router';
import { PumpingComponent } from './pumping.component';
import { InitialDataComponent } from './initial-data/initial-data.component';
import { WellFluidsComponent } from './well-fluids/well-fluids.component';
import { ScheduleComponent } from './schedule/schedule.component';
import { ScheduleOptionsComponent } from './schedule-options/schedule-options.component';
import { EvaluateScheduleComponent } from './evaluate-schedule/evaluate-schedule.component';
import {
  RouteModulePumping,
  RouteModulePumpingInitialData,
  RouteModulePumpingSchedule,
  RouteModulePumpingScheduleOptions,
  RouteModulePumpingWellFluids,
} from './pumping-routes-names';

export const simulatePumpingRoutes: Routes = [
  {
    path: RouteModulePumping,
    component: PumpingComponent,
    data: {
      breadcrumb: 'Pumping',
    },
    children: [
      {
        path: RouteModulePumpingInitialData,
        component: InitialDataComponent,
        data: {
          breadcrumb: 'Initial Data',
        },
      },
      {
        path: RouteModulePumpingWellFluids,
        component: WellFluidsComponent,
        data: {
          breadcrumb: 'Well Fluids',
        },
      },
      {
        path: RouteModulePumpingSchedule,
        component: ScheduleComponent,
        data: {
          breadcrumb: 'Schedule',
        },
      },
      {
        path: RouteModulePumpingScheduleOptions,
        component: ScheduleOptionsComponent,
        data: {
          breadcrumb: 'Schedule Options',
        },
      },
      {
        path: '',
        redirectTo: RouteModulePumpingInitialData,
        pathMatch: 'full',
      },
    ],
  },
];

export const evaluatePumpingRoutes: Routes = [
  {
    path: RouteModulePumping,
    component: PumpingComponent,
    data: {
      breadcrumb: 'Pumping',
    },
    children: [
      {
        path: RouteModulePumpingInitialData,
        component: InitialDataComponent,
        data: {
          breadcrumb: 'Initial Data',
        },
      },
      {
        path: RouteModulePumpingWellFluids,
        component: WellFluidsComponent,
        data: {
          breadcrumb: 'Well Fluids',
        },
      },
      {
        path: RouteModulePumpingSchedule,
        component: EvaluateScheduleComponent,
        data: {
          breadcrumb: 'Schedule',
        },
      },
      {
        path: '',
        redirectTo: RouteModulePumpingInitialData,
        pathMatch: 'full',
      },
    ],
  },
];
