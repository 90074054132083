import {
  getArgument,
  getArgumentAxisUnit,
  IChartDataDto,
  IChartDataDtoColumn,
} from '@dunefront/common/modules/reporting/dto/chart-data.dto';
import { IUnitSystemDto } from '@dunefront/common/dto/unit-system.dto';
import { VerticalShiftDto } from '@dunefront/common/dto/trend-analysis/vertical-shift.dto';
import { IAxisUnit } from '@dunefront/common/unit-converters/converter.interfaces';
import { getAxisUnitForColumn } from './chart-misc-helpers';
import { UnitConverterHelper } from '@dunefront/common/unit-converters/unit.converter.helper';
import { ChartContext } from './chart-types';

export class ChartDataPointsHelpers {
  private static smoothPoints(points: [number, number][], smoothingPoints: number | undefined): [number, number][] {
    if (smoothingPoints == null || smoothingPoints === 0) {
      return points;
    }

    const result: [number, number][] = [];

    for (let index = 0; index < points.length; index++) {
      const [convertedArgument] = points[index];

      let valueSum = 0;
      let valueCount = 0;
      for (let avIndex = Math.max(0, index - smoothingPoints); avIndex < Math.min(points.length, index + smoothingPoints + 1); avIndex++) {
        valueSum += points[avIndex][1];
        valueCount++;
      }
      result.push([convertedArgument, valueSum / valueCount]);
    }

    return result;
  }

  public static getDataPoints(
    chartData: IChartDataDto,
    column: IChartDataDtoColumn,
    currentUnitSystem: IUnitSystemDto,
    verticalShifts: VerticalShiftDto[],
    argumentAxisUnit: IAxisUnit,
  ): [number, number][] {
    const points: [number, number][] = [];

    const dataSetIndex = column.DataSetIndex;
    const sourceDataSet = chartData.ChartDataSets[dataSetIndex];
    const dataValueIndex = column.DataValueIndex;
    const multiplier = column.Multiplier ?? 1;
    const xAxisShift = sourceDataSet.XAxisShift;

    const verticalShift = (verticalShifts.find((vs) => vs.ColumnId === column.ColumnId)?.ShiftValue ?? 0) + (column.VerticalOffset ?? 0);

    for (const row of sourceDataSet.ChartDataRows) {
      const importColumnAxisUnit = getAxisUnitForColumn(column);
      const value = row.Values[dataValueIndex];
      if (value == null || !Number.isFinite(value) || importColumnAxisUnit == undefined) {
        continue;
      }

      const convertedValue = UnitConverterHelper.convertFromSi(
        importColumnAxisUnit.unitSystem,
        currentUnitSystem,
        value * multiplier + verticalShift,
      );

      const argument = getArgument(chartData, row);
      const convertedArgument = UnitConverterHelper.convertFromSi(argumentAxisUnit.unitSystem, currentUnitSystem, argument + xAxisShift);

      points.push([convertedArgument, convertedValue]);
    }

    return points;
  }

  public static calcSmoothedPoints(column: IChartDataDtoColumn, chartContext: ChartContext, chartData: IChartDataDto): [number, number][] {
    const { currentUnitSystem, verticalShifts } = chartContext;
    const argumentAxisUnit = getArgumentAxisUnit(chartData);

    const points = ChartDataPointsHelpers.getDataPoints(chartData, column, currentUnitSystem, verticalShifts, argumentAxisUnit);
    const dataSet = chartData.ChartDataSets.find((dataset) => dataset.FileId === column.FileId);

    // when ResampleInterval not defined or less than 1 then set to 1
    const interval = Math.max(dataSet?.ResampleInterval ?? 1, 1);
    const smoothingPoints = column.SmoothingPoints != null ? Math.round(column.SmoothingPoints / interval) : undefined;

    return ChartDataPointsHelpers.smoothPoints(points, smoothingPoints);
  }
}
