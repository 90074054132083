import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { createTableRow, getRowsForCalculations, ITableRow, ITableState } from '@dunefront/common/common/common-grid.interfaces';
import { IGridColumnConfig } from '../../../../../shared/components/grid/grid.interfaces';
import { Store } from '@ngrx/store';
import { ModalService } from '../../../../../common-modules/modals/modal.service';
import { ZonePressureType } from '@dunefront/common/modules/well/dto/well.dto';
import { GridConfig } from '../../../../../shared/components/grid/grid-config';
import * as wellActions from '../../../../../+store/well/well.actions';
import { GridContainerComponent } from '../../../../../shared/components/grid/grid-container.component';
import { WellModuleState } from '@dunefront/common/modules/well/well-module.state';
import { notEmpty } from '@dunefront/common/common/state.helpers';
import { ArrayHelpers } from '@dunefront/common/common/array-helpers';
import { ZoneFactory } from '@dunefront/common/modules/well/model/zone/zone.factory';
import { ZoneModel } from '@dunefront/common/modules/well/model/zone/zone.model';
import { GridResizeService } from '../../../../../shared/services/grid-resize.service';
import { IDeleteRowsProps, IInsertRowsProps, IUpdateTableRowsProps } from '@dunefront/common/common/common-store-crud.interfaces';
import { firstValueFrom } from 'rxjs';
import { getReservoirGridConfig } from '../../../../../+store/well/well.grid.selectors';
import { getValidatedWellModuleState } from '../../../../../+store/well/validated-well.selectors';
import { InsertLocation } from '@dunefront/common/modules/common.interfaces';

@Component({
  selector: 'app-reservoir-grid',
  templateUrl: './reservoir-grid.component.html',
  styleUrls: ['./reservoir-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReservoirGridComponent extends GridContainerComponent<ZoneModel> implements OnChanges, AfterViewInit {
  @Input() public zoneData: ITableState<ZoneModel> = { rows: [], isValid: true };
  @Input() public isZoneDepthByMd = false;
  @Input() public zonePressureType: ZonePressureType = ZonePressureType.Pressure;

  constructor(
    store: Store,
    cdRef: ChangeDetectorRef,
    protected modalService: ModalService,
    el: ElementRef,
    gridResizeService: GridResizeService,
  ) {
    super(store, cdRef, el, new ReservoirGridConfig(store, modalService), gridResizeService);
  }

  public override async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.isZoneDepthByMd != null || changes.zonePressureType != null) {
      this.updateColumns(await this.getColumns());
    }
  }

  protected async getColumns(): Promise<IGridColumnConfig<ZoneModel>[]> {
    return await firstValueFrom(this.store.select(getReservoirGridConfig));
  }
}

export class ReservoirGridConfig extends GridConfig<ZoneModel> {
  private well!: WellModuleState;

  public override isCellDisabled(rows: ITableRow<ZoneModel>[], rowIndex: number, cellIndex: number): boolean {
    if (rows.length === 0) {
      return false;
    }

    const colId = this.columns[cellIndex].colId;
    if (rows.length && rows[rowIndex]?.rowType === 'insert-row') {
      return colId !== 'Name';
    }

    if (colId === 'TopMD' && rowIndex > 0) {
      return true;
    }

    if (colId === 'TopTVD' && rowIndex > 0) {
      return true;
    }

    return false;
  }

  constructor(
    private store: Store,
    modalService: ModalService,
  ) {
    super(modalService);
    this.headerText = 'Reservoir Data';
    this.subscription.add(
      notEmpty(this.store.select(getValidatedWellModuleState)).subscribe((well) => {
        this.well = well;
      }),
    );
  }

  public override createEmptyModel(scenarioId: number): ZoneModel {
    return ZoneFactory.createEmpty(scenarioId);
  }

  public override updateRowsAction(props: IUpdateTableRowsProps<ZoneModel>): void {
    this.store.dispatch(wellActions.updateZoneRow(props));
  }

  public override insertRowAction(props: IInsertRowsProps<ZoneModel>, refRow: ITableRow<ZoneModel>, isPaste = false): void {
    if (props.insertLocation !== 'merge-rows') {
      const prevRow = ArrayHelpers.prev(getRowsForCalculations(this.well.ZoneData.rows), (row) => row.Id === refRow.rowData.Id);
      if (!isPaste) {
        if (this.well.IsZoneDepthByMD) {
          const topMD = prevRow?.BottomMD ?? refRow.rowData.TopMD;
          props.rows[0].rowData.TopMD = topMD;
          props.rows[0].rowData.BottomMD = topMD;
        } else {
          const topTVD = prevRow?.BottomTVD ?? refRow.rowData.TopTVD;
          props.rows[0].rowData.TopTVD = topTVD;
          props.rows[0].rowData.BottomTVD = topTVD;
        }
      }
    }
    this.store.dispatch(wellActions.insertZoneRows(props));
  }

  public override deleteRowsAction(props: IDeleteRowsProps): void {
    this.store.dispatch(wellActions.deleteZoneRows(props));
  }

  public override createDefaultTableRows(scenarioId: number, noOfRows: number): ITableRow<ZoneModel>[] {
    const newTableRows = [];
    for (let index = 0; index < noOfRows; index++) {
      newTableRows.push(createTableRow(this.createEmptyModel(scenarioId), 'data', index, false));
    }
    return newTableRows;
  }

  public override replaceGridAction(rows: ITableRow<ZoneModel>[]): void {
    if (rows.length > 0) {
      const props: IInsertRowsProps<any> = {
        rows,
        refId: 0,
        insertLocation: 'replace' as InsertLocation,
        shouldResetResults: true,
      };
      this.store.dispatch(wellActions.insertZoneRows(props));
    }
  }

  protected override createMergedRow(
    rows: ITableRow<ZoneModel>[],
    firstRowIndex: number,
    lastRowIndex: number,
  ): ITableRow<ZoneModel> | undefined {
    if (rows[firstRowIndex] == null || rows[lastRowIndex] == null) {
      return undefined;
    }
    const mergedRow = { ...rows[firstRowIndex], rowData: { ...rows[firstRowIndex].rowData } };
    mergedRow.rowData = { ...mergedRow.rowData, Id: -1, BottomMD: rows[lastRowIndex].rowData['BottomMD'] };
    return mergedRow;
  }
}
