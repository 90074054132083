import { Component, NgZone, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { getIsBackendConnected } from '../../+store/backend-connection/backend-connection.selectors';
import { Store } from '@ngrx/store';
import { IHomeTab } from './home.component';
import { AppTargetConfig } from '../../shared/services/app-target-config';
import { HomeBaseComponent } from './home.base.component';

import { showFileOpenDialogSuccess } from '../../+store/electron-main/electron-main.actions';
import { performPeriodicVersionCheck } from '../../+store/about/about.actions';
import { filter, take } from 'rxjs/operators';
import { ElectronService } from '../../shared/services/electron-service/electron.service';
import { RouterHelperService } from '../../shared/services/router-helper.service';
import { RouteModuleHome, RouteModuleHomeDemo, RouteModuleHomeFileOptions } from './home-page-routes-names';
import { loadAllFilesAndFolders } from '../../+store/file-manager/file-manager.actions';
import { Subscription } from 'rxjs';
import { ELECTRON_ACTION_OPEN_FILE_PATH } from '@dunefront/common/common/electron/electron.actions';

@Component({
  templateUrl: 'electron-home.component.html',
  styleUrls: ['./home.component.scss', './electron-home.component.scss'],
})
export class ElectronHomeComponent extends HomeBaseComponent implements OnDestroy {
  public isBackendConnected$ = this.store.select(getIsBackendConnected);
  public selectedTab!: string;
  public homeTabs: IHomeTab[] = [
    { icon: 'icon-home', label: 'Home', uri: `/${RouteModuleHome}` },
    { icon: 'icon-search-file', label: 'Demo Projects', uri: `/${RouteModuleHome}/${RouteModuleHomeDemo}` },
    { icon: 'icon-setting-gear', label: 'Settings', uri: `/${RouteModuleHome}/${RouteModuleHomeFileOptions}` },
  ];

  private subscription = new Subscription();

  constructor(
    store: Store,
    private readonly router: Router,
    public appConfig: AppTargetConfig,
    private electronService: ElectronService,
    private routerHelperService: RouterHelperService,
    private ngZone: NgZone,
  ) {
    super(store);
    this.selectedTab = router.url;

    this.subscription.add(
      this.isBackendConnected$.pipe(filter((isConnected) => isConnected)).subscribe(() => this.store.dispatch(loadAllFilesAndFolders())),
    );

    if (this.electronService.instance) {
      this.electronService.instance.ipcRenderer.on(ELECTRON_ACTION_OPEN_FILE_PATH, (evt, filepath) => {
        const extension = filepath.split('.').reverse()[0];
        if (this.getAcceptedFileExtension().includes(extension)) {
          this.ngZone.run(() => this.store.dispatch(showFileOpenDialogSuccess({ filePath: filepath })));
        }
      });
    }

    this.store // once backend is ready, check app version
      .select(getIsBackendConnected)
      .pipe(filter((isBackendConnected) => isBackendConnected))
      .pipe(take(1))
      .subscribe(() => {
        this.store.dispatch(performPeriodicVersionCheck());
      });
  }

  public navigate(uri: string): void {
    this.selectedTab = uri;
    this.routerHelperService.navigate([uri]).then();
  }

  public getAcceptedFileExtension(): string {
    return `.${this.appConfig.fileTypeExtension}`;
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
