import { FileSettingsDto } from '@dunefront/common/dto/file-settings.dto';
import { createReducer, on } from '@ngrx/store';
import * as dbConnectionActions from '../backend-connection/backend-connection.actions';
import { FileSettingsReducerHelper } from './file-settings.reducer.helper';
import { updateRowSuccess } from '../app.actions';

export const FileSettingsModuleFeatureName = 'fileSettings';

export interface FileSettingsModuleState {
  fileSettings: FileSettingsDto;
  isLoaded: boolean;
}

const initialFileSettings: FileSettingsDto = {
  Id: -1,
  IsFileLocked: false,
  LockUserName: '',
  LockDate: null,
  LastUsedScenarioId: -1,
};

const initialState: FileSettingsModuleState = {
  fileSettings: initialFileSettings,
  isLoaded: false,
};

export const fileSettingsModuleReducer = createReducer<FileSettingsModuleState>(
  initialState,
  on(dbConnectionActions.backendDisconnectedAction, (state): FileSettingsModuleState => ({ ...state, isLoaded: false })),
  on(
    dbConnectionActions.dbConnectedSuccessAction,
    (state, action): FileSettingsModuleState => FileSettingsReducerHelper.fileSettingsModuleLoaded(state, action),
  ),
  on(updateRowSuccess, (state, action): FileSettingsModuleState => FileSettingsReducerHelper.updateFileSettingsSuccess(state, action)),
);
