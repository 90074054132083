import { BasePptSectionGenerator, PptContext } from '../base-ppt-section-generator';
import { baseTextProps, reportTitlePosition } from '../style-constants';

export class EmptyResultsPptGenerator extends BasePptSectionGenerator {
  constructor(protected override context: PptContext & { title: string; subTitle?: string }) {
    super(context);
  }

  public async generate(): Promise<void> {
    const { title, subTitle } = this.context;
    const slide = BasePptSectionGenerator.addSlide(this.context.pptx, { title, subTitle });
    slide.addText([{ text: `${title} is not available - results are needed.` }], {
      ...reportTitlePosition,
      ...baseTextProps,
      align: 'center',
      valign: 'middle',
    });
  }
}
