import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { createSelector, Store } from '@ngrx/store';
import { ModalService } from '../../../../common-modules/modals/modal.service';
import { PageWithGridComponent } from '../../../../shared/components/grid/page-with-grid.component';
import { ScreenService } from '../../../../shared/services';
import { ITableRow } from '@dunefront/common/common/common-grid.interfaces';
import * as uiActions from '../../../../+store/ui/ui.actions';
import { ZoneModel } from '@dunefront/common/modules/well/model/zone/zone.model';
import { getValidatedCompletionModuleState } from '../../../../+store/completion/validated-completion.selectors';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { IUpdateTableRowsProps } from '@dunefront/common/common/common-store-crud.interfaces';
import { calculateVolumesSectionAction } from '../../../../+store/completion/completion.actions';
import { concatLatestFrom } from '@ngrx/operators';
import { PanelHelpMode } from '../../../../shared/components/help-button/help-buton.component';

@Component({
  selector: 'app-volumes',
  templateUrl: './volumes.component.html',
  styleUrls: ['./volumes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VolumesComponent extends PageWithGridComponent<ZoneModel> implements OnInit {
  public completion$ = this.store.select(getValidatedCompletionModuleState);
  public PanelHelpMode = PanelHelpMode;
  constructor(store: Store, cdRef: ChangeDetectorRef, modalService: ModalService, resizeService: ScreenService) {
    super(store, cdRef, modalService, resizeService);
    store.dispatch(uiActions.setCompletionTabAction({ tab: 'volumes' }));
    this.onHelpChange('completion', 'section-volumes-calculator');
  }

  public override ngOnInit(): void {
    super.ngOnInit();

    this.subscription.add(
      this.store
        .select(hasVolumeGravelChanged)
        .pipe(
          concatLatestFrom(() => this.isStateLoaded$),
          filter(([_, isStateLoaded]) => isStateLoaded),
          distinctUntilChanged(),
        )
        .subscribe(() => this.store.dispatch(calculateVolumesSectionAction({ calculationType: 'multiple' }))),
    );
  }

  public getRows(): ITableRow<ZoneModel>[] {
    return [];
  }

  protected updateRow(row: IUpdateTableRowsProps<any>): void {
    return;
  }
}

const hasVolumeGravelChanged = createSelector(
  getValidatedCompletionModuleState,
  (state) => state.Volumes.SectionVolCalcGravelId + '_' + state.Volumes.SectionVolCalcGravelConc,
);
