import { SolidConcentrationConverter } from './converters/solid-concentration/solid-concentration.converter';
import { MassConverter } from './converters/mass/mass.converter';
import { PressureConverter } from './converters/pressure/pressure.converter';
import { RateConverter } from './converters/rate/rate.converter';
import { TemperatureConverter } from './converters/temperature/temperature.converter';
import { VolumeConverter } from './converters/volume/volume.converter';
import { LengthConverter } from './converters/length/length.converter';
import { ViscosityConverter } from './converters/viscosity/viscosity.converter';
import { VelocityConverter } from './converters/velocity/velocity.converter';
import { TimeConverter } from './converters/time/time.converter';
import { LinearVolumeConverter } from './converters/linear-volume/linear-volume.converter';
import { DensityConverter } from './converters/density/density.converter';
import { TemperatureTimeDerivativeConverter } from './converters/temperature-time-derivative/temperature-time-derivative.converter';
import { TemperatureVolumeDerivativeConverter } from './converters/temperature-volume-derivative/temperature-volume-derivative.converter';
import { PressureTimeDerivativeConverter } from './converters/pressure-time-derivative/pressure-time-derivative.converter';
import { PressureVolumeDerivativeConverter } from './converters/pressure-volume-derivative/pressure-volume-derivative.converter';
import { DataType } from '../dto/data-storage';
import { UnitConverter } from './converter.interfaces';
import { PlaneAngleConverter } from './converters/plane-angle/plane-angle.converter';
import { ThermalConductivityConverter } from './converters/thermal-conductivity/thermal-conductivity.converter';
import { ShotDensityConverter } from './converters/shot-density/shot-density.converter';
import { NoneConverter } from './converters/none-unit/none-unit.converter';
import { MassFlowRateConverter } from './converters/mass-flow-rate/mass-flow-rate.converter';
import { PressureRateCubedDerivativeConverter } from './converters/pressure-rate-cubed-derivative/pressure-rate-cubed-derivative.converter';
import { SpecificHeatCapacityConverter } from './converters/specific-heat-capacity/specific-heat-capacity.converter';
import { PressureRateDerivativeConverter } from './converters/pressure-rate-derivative/pressure-rate-derivative.converter';
import { ReciprocalLengthConverter } from './converters/reciprocal-length/reciprocal-length.converter';
import { PressureGradientConverter } from './converters/pressure-gradient/pressure-gradient.converter';
import { FluidLossCoefficientConverter } from './converters/fluid-loss-coefficient/fluid-loss-coefficient.converter';
import { ConsistencyIndexConverter } from './converters/consistency/consistency-index.converter';
import { CompressibilityConverter } from './converters/compressibility/compressibility.converter';
import { LinearMassConverter } from './converters/linear-mass/linear-mass.converter';
import { PermeabilityConverter } from './converters/permeablity/permeability.converter';
import { PressureRateSquaredDerivativeConverter } from './converters/pressure-rate-squared/pressure-rate-squared-derivative.converter';
import { FluidConcentrationUnit, IUnitSystemDto, UnitSystem } from '../dto/unit-system.dto';
import { EnumHelpers } from '../utils/enum.helpers';
import { ForceConverter } from './converters/force/force.converter';
import { InjectivityConverter } from './converters/injectivity/injectivity.converter';
import { LiquidConcentrationConverter } from './converters/liquid-concentration/liquid-concentration.converter';
import { FluidConcentrationConverter } from './converters/fluid-contentration/fluid-concentration.converter';

export class UnitConverterHelper {
  public static getCurrentUnit(unitType: UnitSystem, currentUnitSystem: IUnitSystemDto): number {
    const unitTypeName = UnitSystem[unitType];
    return currentUnitSystem[unitTypeName as keyof IUnitSystemDto] as number;
  }

  public static isUnitCorrect(unitSystem: UnitSystem | -1, unit: number): boolean {
    if (unitSystem === -1) {
      return false;
    }
    const conv = this.getUnitConverter(unitSystem);
    return conv.symbols[unit] != null && conv.symbols[unit] !== '';
  }

  public static convertFromSi(unitSystem: UnitSystem, currentUnitSystem: IUnitSystemDto, value: number): number {
    const unit = this.getCurrentUnit(unitSystem, currentUnitSystem);
    return this.convertFromSiForUnit(unitSystem, unit, value);
  }

  public static convertFromSiWithDecimalPlaces(
    unitSystem: UnitSystem,
    currentUnitSystem: IUnitSystemDto,
    value: number,
    decimalPlaces = 2,
  ): string {
    return this.convertFromSi(unitSystem, currentUnitSystem, value).toFixed(decimalPlaces);
  }

  public static convertToSi(unitSystem: UnitSystem, currentUnitSystem: IUnitSystemDto, value: number): number {
    const unit = this.getCurrentUnit(unitSystem, currentUnitSystem);
    return this.convertToSiForUnit(unitSystem, unit, value);
  }

  public static convertToSiForUnit(unitSystem: UnitSystem, unit: number, value: number): number {
    const conv = this.getUnitConverter(unitSystem);
    return conv.toSi(value, unit);
  }

  public static convertFromSiForUnit(unitSystem: UnitSystem, unit: number, value: number): number {
    const conv = this.getUnitConverter(unitSystem);
    return conv.fromSi(value, unit);
  }

  public static convertDeltaToSi(unitSystem: UnitSystem, currentUnitSystem: IUnitSystemDto, valueDelta: number): number {
    return this.convertToSi(unitSystem, currentUnitSystem, valueDelta) - this.convertToSi(unitSystem, currentUnitSystem, 0);
  }

  public static getUnitConverter(unitSystem: UnitSystem | -1): UnitConverter {
    switch (unitSystem) {
      case UnitSystem.Long_Length:
      case UnitSystem.Short_Length:
      case UnitSystem.Diameter:
        return LengthConverter;
      case UnitSystem.Angle:
        return PlaneAngleConverter;
      case UnitSystem.Linear_Mass:
        return LinearMassConverter;
      case UnitSystem.Temperature:
        return TemperatureConverter;
      case UnitSystem.Temperature_Volume_Derivative:
        return TemperatureVolumeDerivativeConverter;
      case UnitSystem.Temperature_Time_Derivative:
        return TemperatureTimeDerivativeConverter;
      case UnitSystem.Pressure:
      case UnitSystem.Yield_Stress:
        return PressureConverter;
      case UnitSystem.Pressure_Gradient:
        return PressureGradientConverter;
      case UnitSystem.Liquid_Density:
        return DensityConverter;
      case UnitSystem.Permeability:
        return PermeabilityConverter;
      case UnitSystem.Viscosity:
        return ViscosityConverter;
      case UnitSystem.Fluid_Loss_Coefficient:
        return FluidLossCoefficientConverter;
      case UnitSystem.Solid_Concentration:
        return SolidConcentrationConverter;
      case UnitSystem.Linear_Volume:
        return LinearVolumeConverter;
      case UnitSystem.Mass:
      case UnitSystem.Small_Mass:
        return MassConverter;
      case UnitSystem.Rate:
        return RateConverter;
      case UnitSystem.Shot_Density:
        return ShotDensityConverter;
      case UnitSystem.Thermal_Conductivity:
        return ThermalConductivityConverter;
      case UnitSystem.Liquid_Volume:
      case UnitSystem.Solid_Volume:
        return VolumeConverter;
      case UnitSystem.Reciprocal_Length:
        return ReciprocalLengthConverter;
      case UnitSystem.Specific_Heat_Capacity:
        return SpecificHeatCapacityConverter;
      case UnitSystem.Consistency_Index:
        return ConsistencyIndexConverter;
      case UnitSystem.Pressure_Rate_Derivative:
        return PressureRateDerivativeConverter;
      case UnitSystem.Pressure_Rate_Squared_Derivative:
        return PressureRateSquaredDerivativeConverter;
      case UnitSystem.Pressure_Rate_Cubed_Derivative:
        return PressureRateCubedDerivativeConverter;
      case UnitSystem.Pressure_Volume_Derivative:
        return PressureVolumeDerivativeConverter;
      case UnitSystem.Pressure_Time_Derivative:
        return PressureTimeDerivativeConverter;
      case UnitSystem.Solid_Density:
        return DensityConverter;
      case UnitSystem.Bulk_Density:
        return DensityConverter;
      case UnitSystem.Velocity:
        return VelocityConverter;
      case UnitSystem.Mass_Flow_Rate:
        return MassFlowRateConverter;
      case UnitSystem.Time:
        return TimeConverter;
      case UnitSystem.Compressibility:
        return CompressibilityConverter;
      case UnitSystem.Sieve_Opening:
        return LengthConverter;
      case UnitSystem.Screen_Opening:
        return LengthConverter;
      case UnitSystem.Force:
        return ForceConverter;
      case UnitSystem.Injectivity:
        return InjectivityConverter;
      case UnitSystem.Liquid_Concentration:
        return LiquidConcentrationConverter;
      case UnitSystem.Fluid_Concentration:
        return FluidConcentrationConverter;
      default:
        return NoneConverter;
    }
  }

  public static getUnitSystemFromDataType(dataType: DataType): UnitSystem {
    switch (dataType) {
      case DataType.Time:
        return UnitSystem.Time;
      case DataType.Pump_Rate:
      case DataType.Return_Rate:
      case DataType.Shunt_Rate:
      case DataType.Fluid_Rate:
        return UnitSystem.Rate;
      case DataType.Surface_Gravel_Concentration:
        return UnitSystem.Solid_Concentration;
      case DataType.Pump_Pressure:
      case DataType.Return_Pressure:
      case DataType.Bottomhole_Pressure:
      case DataType.Heel_Pressure:
      case DataType.Heel_Fracture_Pressure:
      case DataType.Toe_Pressure:
      case DataType.Reverse_Out_Pressure:
      case DataType.Hydrostatic_Pressure:
      case DataType.Friction_Pressure:
      case DataType.Section_Friction_Pressure:
      case DataType.Reservoir_Pressure:
      case DataType.Fracture_Pressure:
      case DataType.Max_Shunt_Burst_Pressure:
      case DataType.Max_Shunt_Collapse_Pressure:
      case DataType.Toe_Reservoir_Pressure:
      case DataType.Toe_Fracture_Pressure:
      case DataType.Heel_Reservoir_Pressure:
      case DataType.Heel_Hydrostatic_Pressure:
      case DataType.Toe_Hydrostatic_Pressure:
      case DataType.Frac_Net_Pressure:
      case DataType.Shunt_Burst_Rating:
      case DataType.Shunt_Collapse_Rating:
      case DataType.Workstring_Friction:
      case DataType.Annulus_Friction:
      case DataType.Washpipe_Friction:
      case DataType.Blank_Friction:
      case DataType.Screen_Friction:
      case DataType.Basepipe_Friction:
      case DataType.Service_Tool_Up_Flow_Friction:
      case DataType.Service_Tool_Down_Flow_Friction:
      case DataType.Shunt_Friction:
      case DataType.Gauge_Carrier_Friction:
      case DataType.Pressure_Attenuator_Friction:
      case DataType.Treating_Friction:
      case DataType.Choke_Friction:
      case DataType.Shunt_Pressure:
      case DataType.Fracture_Margin:
      case DataType.Toe_Fracture_Margin:
      case DataType.Heel_Fracture_Margin:
      case DataType.Upper_Annulus_Friction:
      case DataType.Lower_Annulus_Friction:
      case DataType.Packer_Pressure:
      case DataType.Packer_Hydrostatic_Pressure:
      case DataType.Packer_Reservoir_Pressure:
      case DataType.Packer_Fracture_Pressure:
      case DataType.Packer_Fracture_Margin:
        return UnitSystem.Pressure;
      case DataType.Section_Friction_Pressure_Gradient:
        return UnitSystem.Pressure_Gradient;
      case DataType.Bottomhole_Temperature:
      case DataType.Up_Path_Temperature:
      case DataType.Down_Path_Temperature:
        return UnitSystem.Temperature;
      case DataType.Pump_Volume:
      case DataType.Return_Volume:
      case DataType.Perf_Pack_Volume:
        return UnitSystem.Liquid_Volume;
      case DataType.Bottomhole_Gravel_Mass:
      case DataType.Surface_Gravel_Mass:
        return UnitSystem.Mass;
      case DataType.Hook_Load:
        return UnitSystem.Force;
      case DataType.Horizontal_Displacement:
      case DataType.True_Vertical_Depth:
      case DataType.Measured_Depth:
      case DataType.Dune_Front_Location:
      case DataType.Block_Height:
      case DataType.Bit_Depth:
      case DataType.Fluid_Level:
        return UnitSystem.Long_Length;
      case DataType.Open_Hole_Diameter:
      case DataType.Open_Hole_Averaged_Diameter:
      case DataType.Dune_Height:
        return UnitSystem.Diameter;
      case DataType.Viscosity:
        return UnitSystem.Viscosity;
      case DataType.Fluid_Velocity:
      case DataType.Particle_Pickup_Velocity:
        return UnitSystem.Velocity;
      case DataType.Particle_Size:
        return UnitSystem.Screen_Opening;
      case DataType.Total_Perf_Pack_Volume:
      case DataType.Frac_Pack_Volume:
        return UnitSystem.Linear_Volume;
      case DataType.Pack_Height:
        return UnitSystem.Short_Length;
      case DataType.Heel_ECD:
      case DataType.Toe_ECD:
      case DataType.Equivalent_Circulating_Density:
      case DataType.Mud_Weight:
      case DataType.Reservoir_ECD:
      case DataType.Fracture_ECD:
      case DataType.Heel_Reservoir_ECD:
      case DataType.Toe_Reservoir_ECD:
      case DataType.Heel_Fracture_ECD:
      case DataType.Toe_Fracture_ECD:
      case DataType.Packer_ECD:
      case DataType.Packer_Reservoir_ECD:
        return UnitSystem.Liquid_Density;
      case DataType.Temperature_Time_Derivative:
        return UnitSystem.Temperature_Time_Derivative;
      case DataType.Temperature_Volume_Derivative:
        return UnitSystem.Temperature_Volume_Derivative;
      case DataType.Shunt_Gravel_Concentration:
      case DataType.External_Gravel_Concentration:
      case DataType.Bottomhole_Gravel_Concentration:
        return UnitSystem.Solid_Concentration;
      case DataType.Pressure_Time_Derivative:
        return UnitSystem.Pressure_Time_Derivative;
      case DataType.Pressure_Volume_Derivative:
        return UnitSystem.Pressure_Volume_Derivative;
      case DataType.Other:
      case DataType.Fluid_Index:
      case DataType.Gravel_Index:
      case DataType.Unknown:
      case DataType.Shear_Rate: // shear rate has no unit
      case DataType.Cumulative_Percent_Weight_Retained:
      case DataType.Fluid_Ratio:
      case DataType.Pack_Height_Ratio:
      case DataType.Total_Perf_Pack_Percent:
      case DataType.Frac_Fluid_Efficiency:
      case DataType.Frac_Pack_Efficiency:
      case DataType.Up_Path_StreamFunction:
      case DataType.Down_Path_StreamFunction:
      case DataType.Up_Path_Flow_Regime:
      case DataType.Down_Path_Flow_Regime:
        return UnitSystem.None;
      case DataType.Injectivity:
        return UnitSystem.Injectivity;
      case DataType.Liquid_Additive_Concentration:
        return UnitSystem.Liquid_Concentration;
      case DataType.Down_Path_Fluid_Concentration:
      case DataType.Up_Path_Fluid_Concentration:
        return UnitSystem.Fluid_Concentration;
      default:
        throw new Error('Attempt to convert unknown unit id ' + dataType);
    }
  }

  public static getUnitTypeAndName(dataType: DataType, unitSystem: UnitSystem, currentUnitSystem?: IUnitSystemDto): IChartUnitDetails {
    //returns unit name, unit symbol, logarithmic flag, optional decimal places
    switch (dataType) {
      case DataType.Time: {
        return {
          AxisName: 'Time',
          AxisSymbol: currentUnitSystem ? TimeConverter.getSymbol(currentUnitSystem.Time) : undefined,
        };
      }
      case DataType.Pump_Rate:
      case DataType.Return_Rate:
      case DataType.Shunt_Rate:
      case DataType.Fluid_Rate: {
        return {
          AxisName: 'Rate',
          AxisSymbol: currentUnitSystem ? RateConverter.getSymbol(currentUnitSystem.Rate) : undefined,
          IsFlowRate: true,
          IsStepSeries: true,
        };
      }
      case DataType.Surface_Gravel_Concentration: {
        return {
          AxisName: 'Gravel Concentration',
          AxisSymbol: currentUnitSystem ? SolidConcentrationConverter.getSymbol(currentUnitSystem.Solid_Concentration) : undefined,
          IsGravelConcentration: true,
          IsStepSeries: true,
        };
      }
      case DataType.Pump_Pressure:
      case DataType.Return_Pressure:
      case DataType.Reverse_Out_Pressure:
        return {
          ...this.getPressureUnitType(currentUnitSystem),
          AxisName: 'Surface Pressure',
          IsSurfacePressure: true,
        };
      case DataType.Bottomhole_Pressure:
      case DataType.Heel_Pressure:
      case DataType.Heel_Fracture_Pressure:
      case DataType.Toe_Pressure:
      case DataType.Toe_Fracture_Pressure:
      case DataType.Hydrostatic_Pressure:
      case DataType.Reservoir_Pressure:
      case DataType.Fracture_Pressure:
      case DataType.Toe_Reservoir_Pressure:
      case DataType.Heel_Reservoir_Pressure:
      case DataType.Heel_Hydrostatic_Pressure:
      case DataType.Toe_Hydrostatic_Pressure:
      case DataType.Frac_Net_Pressure:
      case DataType.Packer_Pressure:
      case DataType.Packer_Hydrostatic_Pressure:
      case DataType.Packer_Reservoir_Pressure:
      case DataType.Packer_Fracture_Pressure:
        return {
          ...this.getPressureUnitType(currentUnitSystem),
          AxisName: 'Downhole Pressure',
          IsBottomHolePressure: true,
        };
      case DataType.Shunt_Pressure:
        return {
          ...this.getPressureUnitType(currentUnitSystem),
          AxisName: 'Downhole Pressure',
          IsBottomHolePressure: true,
          IsStepSeries: true,
        };
      case DataType.Workstring_Friction:
      case DataType.Annulus_Friction:
      case DataType.Lower_Annulus_Friction:
      case DataType.Upper_Annulus_Friction:
      case DataType.Washpipe_Friction:
      case DataType.Blank_Friction:
      case DataType.Screen_Friction:
      case DataType.Basepipe_Friction:
      case DataType.Service_Tool_Up_Flow_Friction:
      case DataType.Service_Tool_Down_Flow_Friction:
      case DataType.Shunt_Friction:
      case DataType.Gauge_Carrier_Friction:
      case DataType.Pressure_Attenuator_Friction:
      case DataType.Treating_Friction:
      case DataType.Choke_Friction:
      case DataType.Friction_Pressure:
      case DataType.Section_Friction_Pressure:
        return { ...this.getPressureUnitType(currentUnitSystem), AxisName: 'Friction Pressure' };
      case DataType.Section_Friction_Pressure_Gradient:
        return { ...this.getPressureGradientUnitType(currentUnitSystem), AxisName: 'Pressure Gradient' };
      case DataType.Max_Shunt_Burst_Pressure:
      case DataType.Max_Shunt_Collapse_Pressure:
      case DataType.Shunt_Burst_Rating:
      case DataType.Shunt_Collapse_Rating:
      case DataType.Fracture_Margin:
      case DataType.Toe_Fracture_Margin:
      case DataType.Heel_Fracture_Margin:
      case DataType.Packer_Fracture_Margin:
        return { ...this.getPressureUnitType(currentUnitSystem), AxisName: 'Pressure' };
      case DataType.Bottomhole_Temperature: {
        return {
          AxisName: 'Temperature',
          AxisSymbol: currentUnitSystem ? TemperatureConverter.getSymbol(currentUnitSystem.Temperature) : undefined,
          IsBottomHoleTemperature: true,
        };
      }
      case DataType.Pump_Volume:
      case DataType.Return_Volume:
      case DataType.Perf_Pack_Volume: {
        return {
          AxisName: 'Volume',
          AxisSymbol: currentUnitSystem ? VolumeConverter.getSymbol(currentUnitSystem.Liquid_Volume) : undefined,
        };
      }
      case DataType.Bottomhole_Gravel_Mass:
      case DataType.Surface_Gravel_Mass: {
        return {
          AxisName: 'Mass',
          AxisSymbol: currentUnitSystem ? MassConverter.getSymbol(currentUnitSystem.Mass) : undefined,
        };
      }
      case DataType.Hook_Load: {
        return {
          AxisName: 'Force',
          AxisSymbol: currentUnitSystem ? ForceConverter.getSymbol(currentUnitSystem.Force) : undefined,
        };
      }
      case DataType.True_Vertical_Depth: {
        return {
          AxisName: 'True Vertical Depth',
          AxisSymbol: currentUnitSystem ? LengthConverter.getSymbol(currentUnitSystem.Long_Length) : undefined,
        };
      }
      case DataType.Horizontal_Displacement: {
        return {
          AxisName: 'Horizontal Displacement',
          AxisSymbol: currentUnitSystem ? LengthConverter.getSymbol(currentUnitSystem.Long_Length) : undefined,
        };
      }
      case DataType.Measured_Depth:
      case DataType.Dune_Front_Location:
      case DataType.Block_Height:
      case DataType.Bit_Depth:
      case DataType.Fluid_Level: {
        return {
          AxisName: 'Measured Depth',
          AxisSymbol: currentUnitSystem ? LengthConverter.getSymbol(currentUnitSystem.Long_Length) : undefined,
        };
      }
      case DataType.Open_Hole_Diameter:
      case DataType.Open_Hole_Averaged_Diameter: {
        return {
          AxisName: 'Diameter',
          AxisSymbol: currentUnitSystem ? LengthConverter.getSymbol(currentUnitSystem.Diameter) : undefined,
          IsStepSeries: true,
        };
      }
      case DataType.Dune_Height: {
        return {
          AxisName: 'Dune Height',
          AxisSymbol: currentUnitSystem ? LengthConverter.getSymbol(currentUnitSystem.Diameter) : undefined,
        };
      }
      case DataType.Up_Path_Temperature:
      case DataType.Down_Path_Temperature: {
        return {
          AxisName: 'Temperature',
          AxisSymbol: currentUnitSystem ? TemperatureConverter.getSymbol(currentUnitSystem.Temperature) : undefined,
        };
      }
      case DataType.Viscosity: {
        return {
          AxisName: 'Viscosity',
          AxisSymbol: currentUnitSystem ? ViscosityConverter.getSymbol(currentUnitSystem.Viscosity) : undefined,
          Logarithmic: true,
        };
      }
      case DataType.Shear_Rate: {
        return {
          AxisName: 'Shear Rate',
          AxisSymbol: '1/s',
          Logarithmic: true,
        };
      }
      case DataType.Fluid_Velocity:
      case DataType.Particle_Pickup_Velocity: {
        return {
          AxisName: 'Velocity',
          AxisSymbol: currentUnitSystem ? VelocityConverter.getSymbol(currentUnitSystem.Velocity) : undefined,
        };
      }
      case DataType.Particle_Size: {
        return {
          AxisName: 'Particle Size',
          AxisSymbol: currentUnitSystem ? LengthConverter.getSymbol(currentUnitSystem.Screen_Opening) : undefined,
          Logarithmic: true,
        };
      }
      case DataType.Frac_Fluid_Efficiency:
      case DataType.Frac_Pack_Efficiency:
      case DataType.Cumulative_Percent_Weight_Retained: {
        return {
          AxisName: 'Percentage',
          AxisSymbol: '%',
        };
      }
      case DataType.Pack_Height_Ratio: {
        return {
          AxisName: 'Percentage',
          AxisSymbol: '%',
          IsStepSeries: true,
        };
      }
      case DataType.Fluid_Ratio:
      case DataType.Total_Perf_Pack_Percent: {
        return {
          AxisName: 'Percentage',
          AxisSymbol: '%',
          IsStepSeries: true,
        };
      }
      case DataType.Frac_Pack_Volume: {
        return {
          AxisName: 'Linear Volume',
          AxisSymbol: currentUnitSystem ? LinearVolumeConverter.getSymbol(currentUnitSystem.Linear_Volume) : undefined,
        };
      }
      case DataType.Total_Perf_Pack_Volume: {
        return {
          AxisName: 'Linear Volume',
          AxisSymbol: currentUnitSystem ? LinearVolumeConverter.getSymbol(currentUnitSystem.Linear_Volume) : undefined,
          IsStepSeries: true,
        };
      }
      case DataType.Pack_Height: {
        return {
          AxisName: 'Height',
          AxisSymbol: currentUnitSystem ? LengthConverter.getSymbol(currentUnitSystem.Short_Length) : undefined,
          IsStepSeries: true,
        };
      }
      case DataType.Heel_ECD:
      case DataType.Toe_ECD:
      case DataType.Equivalent_Circulating_Density:
      case DataType.Mud_Weight:
      case DataType.Reservoir_ECD:
      case DataType.Fracture_ECD:
      case DataType.Heel_Reservoir_ECD:
      case DataType.Toe_Reservoir_ECD:
      case DataType.Heel_Fracture_ECD:
      case DataType.Toe_Fracture_ECD:
      case DataType.Packer_ECD:
      case DataType.Packer_Reservoir_ECD: {
        return {
          AxisName: 'Density',
          AxisSymbol: currentUnitSystem ? DensityConverter.getSymbol(currentUnitSystem.Liquid_Density) : undefined,
        };
      }
      case DataType.Temperature_Time_Derivative: {
        return {
          AxisName: 'Temperature Time Derivative',
          AxisSymbol: currentUnitSystem
            ? TemperatureTimeDerivativeConverter.getSymbol(currentUnitSystem.Temperature_Time_Derivative)
            : undefined,
        };
      }
      case DataType.Temperature_Volume_Derivative: {
        return {
          AxisName: 'Temperature Volume Derivative',
          AxisSymbol: currentUnitSystem
            ? TemperatureVolumeDerivativeConverter.getSymbol(currentUnitSystem.Temperature_Volume_Derivative)
            : undefined,
        };
      }
      case DataType.Shunt_Gravel_Concentration:
      case DataType.External_Gravel_Concentration:
      case DataType.Bottomhole_Gravel_Concentration: {
        return {
          AxisName: 'Gravel Concentration',
          AxisSymbol: currentUnitSystem ? SolidConcentrationConverter.getSymbol(currentUnitSystem.Solid_Concentration) : undefined,
          IsStepSeries: true,
        };
      }
      case DataType.Pressure_Time_Derivative: {
        return {
          AxisName: 'Pressure Time Derivative',
          AxisSymbol: currentUnitSystem ? PressureTimeDerivativeConverter.getSymbol(currentUnitSystem.Pressure_Time_Derivative) : undefined,
        };
      }
      case DataType.Pressure_Volume_Derivative: {
        return {
          AxisName: 'Pressure Volume Derivative',
          AxisSymbol: currentUnitSystem ? PressureVolumeDerivativeConverter.getSymbol(currentUnitSystem.Pressure_Volume_Derivative) : undefined,
        };
      }
      case DataType.Injectivity: {
        return {
          AxisName: 'Injectivity',
          AxisSymbol: currentUnitSystem ? InjectivityConverter.getSymbol(currentUnitSystem.Injectivity) : undefined,
        };
      }
      case DataType.Liquid_Additive_Concentration: {
        return {
          AxisName: 'Liquid Concentration',
          AxisSymbol: currentUnitSystem ? LiquidConcentrationConverter.getSymbol(currentUnitSystem.Liquid_Concentration) : undefined,
        };
      }
      case DataType.Down_Path_Fluid_Concentration: {
        return {
          AxisName: 'Down Path Fluid Concentration',
          AxisSymbol: FluidConcentrationConverter.getSymbol(FluidConcentrationUnit.Percent),
          IsStepSeries: true,
        };
      }
      case DataType.Up_Path_Fluid_Concentration: {
        return {
          AxisName: 'Up Path Fluid Concentration',
          AxisSymbol: FluidConcentrationConverter.getSymbol(FluidConcentrationUnit.Percent),
          IsStepSeries: true,
        };
      }
      case DataType.Down_Path_Flow_Regime:
      case DataType.Up_Path_Flow_Regime:
      case DataType.Down_Path_StreamFunction:
      case DataType.Up_Path_StreamFunction:
      case DataType.Other: {
        let axisSymbol = 'Unknown Symbol';
        if (currentUnitSystem) {
          const unit = +currentUnitSystem[UnitSystem[unitSystem] as keyof IUnitSystemDto] ?? 0;
          axisSymbol = UnitConverterHelper.getUnitConverter(unitSystem).getSymbol(unit);
        }
        return {
          AxisName: EnumHelpers.enumToText(UnitSystem[unitSystem]),
          AxisSymbol: axisSymbol,
        };
      }
      case DataType.Fluid_Index:
      case DataType.Gravel_Index:
      default: {
        // todo - implement these units
        return {
          AxisName: 'Unknown Unit',
          AxisSymbol: 'Unknown Symbol',
        };
      }
    }
  }

  private static getPressureUnitType(currentUnitSystem?: IUnitSystemDto): Partial<IChartUnitDetails> {
    return {
      AxisSymbol: currentUnitSystem ? PressureConverter.getSymbol(currentUnitSystem.Pressure) : undefined,
      IsPressure: true,
    };
  }

  private static getPressureGradientUnitType(currentUnitSystem?: IUnitSystemDto): Partial<IChartUnitDetails> {
    return {
      AxisSymbol: currentUnitSystem ? PressureGradientConverter.getSymbol(currentUnitSystem.Pressure_Gradient) : undefined,
    };
  }

  public static isPressureDataType(dataType: DataType): boolean {
    const unitSystem = UnitConverterHelper.getUnitSystemFromDataType(dataType);
    const unitDetails = UnitConverterHelper.getUnitTypeAndName(dataType, unitSystem);

    return unitDetails.IsPressure === true;
  }
}

export interface IChartUnitDetails {
  AxisName: string;
  AxisSymbol?: string;
  DecimalPlaces?: number;
  Logarithmic?: boolean;
  IsStepSeries?: boolean;
  IsPressure?: boolean;
  IsBottomHoleTemperature?: boolean;
  IsBottomHolePressure?: boolean;
  IsFlowRate?: boolean;
  IsGravelConcentration?: boolean;
  IsSurfacePressure?: boolean;
}

export type ConvertDirection = 'from-si' | 'to-si';
