import { Component, HostBinding, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';
import { EnumHelpers } from '@dunefront/common/utils/enum.helpers';
import { Color } from '@dunefront/common/modules/reporting/dto/chart.types';
import { Fluid } from '@dunefront/common/modules/fluid/model/fluid';
import * as actions from '../../../+store/fluid/fluid.actions';

@Component({
  selector: 'app-fluid-color-selector',
  templateUrl: './fluid-color-selector.component.html',
  styleUrls: ['./fluid-color-selector.component.scss'],
})
export class FluidColorSelectorComponent {
  @Input() public selectedFluid: Fluid | undefined;
  @HostBinding('class') public classAttribute = 'grid-form-container no-padding';

  public Colors = EnumHelpers.StringEnumToSelectItemArray(Color);

  constructor(private store: Store) {}

  public async changeFluidColor(props: ObjectChangeProp<Fluid>): Promise<void> {
    if (this.selectedFluid == null) {
      return;
    }

    this.store.dispatch(
      actions.updateFluid({
        changedProp: props,
        fluidId: this.selectedFluid.Id,
        shouldSaveRheologies: false,
      }),
    );
  }
}
