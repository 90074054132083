import { createAction, props } from '@ngrx/store';
import { IFile } from '@dunefront/common/dto/file.dto';
import { ISaveReportPayload } from '@dunefront/common/common/electron/electron.actions';

export const showFileOpenDialog = createAction('[ElectronMain] ShowFileOpenDialog');
export const showFileOpenDialogSuccess = createAction('[ElectronMain] ShowFileOpenDialogSuccess', props<{ filePath?: string }>());

export const showFileSaveDialog = createAction('[ElectronMain] ShowFileSaveDialog', props<{ source: IFile }>());
export const showFileSaveDialogSuccess = createAction(
  '[ElectronMain] ShowFileSaveDialogSuccess',
  props<{ source: IFile; filePath?: string }>(),
);

export const electronOpenItemAction = createAction('[ElectronMain] OpenItem', props<{ filePath: string }>());
export const electronSaveCSVAsFileAction = createAction('[ElectronMain] OpenItem', props<{ fileName: string }>());

export const electronSaveProjectAsFileAction = createAction('[file-manager] saveAsFileElectronAction', props<IFile>());

export const electronSaveReportAsFileAction = createAction('[ElectronMain] saveReportFile', props<{ payload: ISaveReportPayload }>());
