import { IMarker } from '@dunefront/common/modules/reporting/dto/chart-marker.dto';
import { Chart } from 'chart.js';
import { DeepPartial } from 'chart.js/dist/types/utils';
import { AnnotationOptions, LabelPosition, LineAnnotationOptions } from 'chartjs-plugin-annotation';
import {
  borderDashForLineStyle,
  getAxisId,
  getAxisUnit,
  getAxisValueFromPoint,
  getChartAxisFromAxisId,
  getFirstAvailableValueAxis,
  getFirstAvailableValueScale,
  isAxisHorizontal,
} from './chart-misc-helpers';
import { HorizontalAlignment, VerticalAlignment } from '@dunefront/common/modules/reporting/dto/chart.types';
import { ChartAxis } from '@dunefront/common/modules/reporting/dto/chart-axis-property.dto';
import { UnitConverterHelper } from '@dunefront/common/unit-converters/unit.converter.helper';
import { ChartContext } from './chart-types';
import { getPointFromEvent } from '../plugins-common-helpers';

export interface CreateMarkerPayload {
  value: number;
  isValueAxisMarker: boolean;
}

export const isMarkerHorizontal = (isValueAxisMarker: boolean, isChartRotated: boolean): boolean => {
  return (isValueAxisMarker && !isChartRotated) || (!isValueAxisMarker && isChartRotated);
};

export class ChartMarkerHelpers {
  public checkMarkers(
    markers: IMarker[],
    markersVisible: boolean,
    chartContext: ChartContext,
    chart: Chart | undefined,
    sizeMultiplier: number,
  ): DeepPartial<LineAnnotationOptions> | undefined {
    const { defaultMarkerStyle, activeMarker$, isRotated } = chartContext;

    if (chart == null || markers == null || defaultMarkerStyle == null) {
      return;
    }

    const valueScale = getFirstAvailableValueScale(chart, isRotated);
    if (valueScale == null) {
      return undefined;
    }

    const newAnnotations = markersVisible
      ? markers.map((marker) => this.markerToAnnotation(marker, chart, chartContext, sizeMultiplier))
      : [];

    const annotations = chart?.options?.plugins?.annotation?.annotations as DeepPartial<AnnotationOptions<'line'>>[];
    if (annotations?.push != null) {
      annotations.push(...newAnnotations);
    }

    return newAnnotations.find((newMarker) => newMarker.id === activeMarker$.value?.id);
  }

  public markerToAnnotation(
    marker: IMarker,
    chart: Chart,
    chartContext: ChartContext,
    sizeMultiplier: number,
  ): DeepPartial<AnnotationOptions<'line'>> {
    const { defaultMarkerStyle, isRotated, argumentAxisUnit, currentUnitSystem, axesDefaults } = chartContext;

    const markerStyle = marker.isOverrideStyle ? marker.style : defaultMarkerStyle;
    const id = 'marker' + marker.id;
    const valueScale = getFirstAvailableValueScale(chart, isRotated);

    const valueAxis = getChartAxisFromAxisId(valueScale?.id ?? '');
    const valueAxisUnit = getAxisUnit(valueAxis, axesDefaults);
    if (valueAxisUnit == null) {
      throw new Error("Can't find marker axis data type!");
    }

    const axisUnit = marker.isValueAxisMarker ? valueAxisUnit : argumentAxisUnit;

    const fontSize = markerStyle.ChartMarkerFontSize * sizeMultiplier;

    let position: LabelPosition | undefined;
    let xAdjust = 0,
      yAdjust = 0;

    const isHorizontal = isMarkerHorizontal(marker.isValueAxisMarker, isRotated);

    if (
      isHorizontal &&
      markerStyle.ChartMarkerHorizontalTextAlignment != null &&
      markerStyle.ChartMarkerHorizontalTextIndentation != null
    ) {
      position = HorizontalAlignment[markerStyle.ChartMarkerHorizontalTextAlignment] as LabelPosition;

      yAdjust -= fontSize * 0.5; // to offset label and print above the marker rather than over it, 0.5 of fontSize works best
      xAdjust += labelOffset(position, sizeMultiplier);
      if (markerStyle.ChartMarkerHorizontalTextAlignment === HorizontalAlignment.start) {
        xAdjust += (markerStyle.ChartMarkerHorizontalTextIndentation * (valueScale?.width ?? 0)) / 100;
      }
      if (markerStyle.ChartMarkerHorizontalTextAlignment === HorizontalAlignment.end) {
        xAdjust += (markerStyle.ChartMarkerHorizontalTextIndentation * (valueScale?.width ?? 0)) / 100;
      }
    }
    if (!isHorizontal && markerStyle.ChartMarkerVerticalTextAlignment != null && markerStyle.ChartMarkerVerticalTextIndentation != null) {
      position = VerticalAlignment[markerStyle.ChartMarkerVerticalTextAlignment] as LabelPosition;

      xAdjust += fontSize * 0.7; // to offset label and print next (right) to the marker rather over it, 0.7 of fontSize works best
      yAdjust += labelOffset(position, sizeMultiplier);
      if (markerStyle.ChartMarkerVerticalTextAlignment === VerticalAlignment.end) {
        yAdjust += (markerStyle.ChartMarkerVerticalTextIndentation * (valueScale?.height ?? 0)) / 100;
      }
      if (markerStyle.ChartMarkerVerticalTextAlignment === VerticalAlignment.start) {
        yAdjust -= (markerStyle?.ChartMarkerVerticalTextIndentation * (valueScale?.height ?? 0)) / 100;
      }
    }

    const borderWidth = markerStyle.ChartMarkerLineThickness * sizeMultiplier;

    return {
      drawTime: 'afterDatasetsDraw',
      id,
      type: 'line',
      scaleID: marker.isValueAxisMarker ? valueScale?.id : getAxisId(ChartAxis.Argument, isRotated),
      value: UnitConverterHelper.convertFromSi(axisUnit.unitSystem, currentUnitSystem, marker.value),
      endValue: UnitConverterHelper.convertFromSi(axisUnit.unitSystem, currentUnitSystem, marker.value),
      borderColor: markerStyle.ChartMarkerLineColor,
      borderWidth,
      borderDash: borderDashForLineStyle(markerStyle.ChartMarkerLineStyle, borderWidth),
      label: {
        backgroundColor: '#fff0', //transparent
        content: marker.name,
        rotation: isHorizontal ? 0 : -90,
        color: markerStyle.ChartMarkerFontColor,
        font: {
          size: fontSize,
          style: markerStyle.IsChartMarkerFontItalic ? 'italic' : 'normal',
          weight: markerStyle.IsChartMarkerFontBold ? 'bold' : 'normal',
        },
        position,
        xAdjust,
        yAdjust,
        display: true,
      },
    };
  }
}

const alignmentModifier = (position: LabelPosition): -1 | 0 | 1 => {
  if (position === 'start') {
    return 1;
  }

  if (position === 'end') {
    return -1;
  }

  return 0;
};

const labelOffset = (position: LabelPosition, sizeMultiplier: number): number => alignmentModifier(position) * (sizeMultiplier - 1) * 40;

export const getMarkerUnderMouseEvent = (
  event: MouseEvent,
  chart: Chart,
  isRotated: boolean,
  valueAxisMarker: boolean,
): DeepPartial<LineAnnotationOptions> | undefined => {
  const annotations = chart?.options?.plugins?.annotation?.annotations as DeepPartial<LineAnnotationOptions>[];
  if (chart == null || annotations == null) {
    return undefined;
  }

  const axis = valueAxisMarker ? getFirstAvailableValueAxis(chart, isRotated) : ChartAxis.Argument;
  if (axis == null) {
    return undefined;
  }

  const axisId = getAxisId(axis, isRotated);

  const point = getPointFromEvent(event);
  const argumentValue = getAxisValueFromPoint(point, axis, chart, isRotated);
  if (argumentValue == null) {
    return undefined;
  }

  let closestLine: DeepPartial<LineAnnotationOptions> | undefined;
  let closestLineValue = Number.MAX_VALUE;

  annotations.forEach((annotation) => {
    const value = annotation.value as number | undefined;
    if (value == null || annotation.scaleID !== axisId) {
      return;
    }

    if (closestLine === undefined || Math.abs(value - argumentValue) < Math.abs(closestLineValue - argumentValue)) {
      closestLine = annotation;
      closestLineValue = value;
    }
  });

  if (closestLine === undefined) {
    return;
  }

  const pixelGap = axisValueDiffLengthToPixels(axis, Math.abs(closestLineValue - argumentValue), chart, isRotated);
  const isOverMarker = pixelGap < 5;

  return isOverMarker ? closestLine : undefined;
};

export const axisValueDiffLengthToPixels = (axis: ChartAxis, argumentLength: number, chart: Chart, isRotated: boolean): number => {
  const isHorizontal = isAxisHorizontal(axis, isRotated);
  const scale = chart.scales[getAxisId(axis, isRotated)];

  return (argumentLength * (isHorizontal ? scale.width : scale.height)) / (scale.max - scale.min);
};
