import { ColumnType } from '../../../../../components/grid/grid.interfaces';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { TubeShape } from '@dunefront/common/dto/shunt-tube.dto';
import { NozzleProperties, Shroud, Tube } from '@dunefront/common/modules/shunt-tube/shunt-tube';
import { ReportShuntTube } from '../../doc/sections/completion-report-generator.helper';
import { IDataRowWithCode, IReportTableColumnConfig } from '../common-report-data-helpers';

export const shuntTubesSystemGridConfig: IReportTableColumnConfig<IDataRowWithCode<ReportShuntTube>>[] = [
  {
    colId: 'Shape',
    headerText: 'Tube Shape:',
    type: ColumnType.select,
    unitSystem: UnitSystem.None,
  },
  {
    colId: 'IsExternal',
    headerText: 'Tube Location:',
    type: ColumnType.text,
    unitSystem: UnitSystem.None,
  },
  {
    colId: 'IsInternalReservoir',
    headerText: 'Internal Reservoir:',
    type: ColumnType.text,
    unitSystem: UnitSystem.None,
  },
  {
    colId: 'IsTransportTubePresent',
    headerText: 'Transport Tube:',
    type: ColumnType.text,
    unitSystem: UnitSystem.None,
  },
  {
    colId: 'Shroud',
    headerText: 'Shroud:',
    type: ColumnType.text,
    unitSystem: UnitSystem.None,
  },
  {
    colId: 'ShuntPackingFactor',
    headerText: 'Packing Factor:',
    type: ColumnType.number,
    unitSystem: UnitSystem.None,
  },
];

export const tubeGridConfig = (tubeShape: TubeShape): IReportTableColumnConfig<IDataRowWithCode<Tube>>[] => {
  const rectSizing: IReportTableColumnConfig<IDataRowWithCode<Tube>>[] = [
    {
      colId: 'Width',
      headerText: 'Tube Width:',
      type: ColumnType.number,
      unitSystem: UnitSystem.Short_Length,
    },
    {
      colId: 'Height',
      headerText: 'Tube Height:',
      type: ColumnType.number,
      unitSystem: UnitSystem.Short_Length,
    },
    {
      colId: 'Thickness',
      headerText: 'Tube Thickness:',
      type: ColumnType.number,
      unitSystem: UnitSystem.Short_Length,
    },
  ];
  const roundSizing: IReportTableColumnConfig<IDataRowWithCode<Tube>>[] = [
    {
      colId: 'InnerDiameter',
      headerText: 'Tube ID:',
      type: ColumnType.number,
      unitSystem: UnitSystem.Diameter,
    },
    {
      colId: 'OuterDiameter',
      headerText: 'Tube OD',
      type: ColumnType.number,
      unitSystem: UnitSystem.Diameter,
    },
  ];

  return [
    {
      colId: 'NumberOfTubes',
      headerText: 'No. of Tubes:',
      type: ColumnType.number,
      unitSystem: UnitSystem.None,
      decimalPlaces: 0,
    },
    ...(tubeShape === TubeShape.Rectangle ? rectSizing : roundSizing),
    {
      colId: 'BurstRating',
      headerText: 'Tube Burst Rating:',
      type: ColumnType.number,
      unitSystem: UnitSystem.Pressure,
    },
    {
      colId: 'CollapseRating',
      headerText: 'Tube Collapse Rating:',
      type: ColumnType.number,
      unitSystem: UnitSystem.Pressure,
    },
  ];
};

export const nozzleGridConfig: IReportTableColumnConfig<IDataRowWithCode<NozzleProperties>>[] = [
  {
    colId: 'NozzleDiameter',
    headerText: 'Nozzle Diameter',
    type: ColumnType.number,
    unitSystem: UnitSystem.Diameter,
  },
  {
    colId: 'NozzleLength',
    headerText: 'Nozzle Length',
    type: ColumnType.number,
    unitSystem: UnitSystem.Short_Length,
  },
  {
    colId: 'LengthToFirstNozzle',
    headerText: 'Length to First Nozzle',
    type: ColumnType.number,
    unitSystem: UnitSystem.Long_Length,
  },
  {
    colId: 'LengthBetweenNozzles',
    headerText: 'Length Between Nozzles',
    type: ColumnType.number,
    unitSystem: UnitSystem.Long_Length,
  },
];

export const shroudGridConfig: IReportTableColumnConfig<IDataRowWithCode<Shroud>>[] = [
  {
    colId: 'ShroudOuterDiameter',
    headerText: 'Outer Diameter',
    type: ColumnType.number,
    unitSystem: UnitSystem.Diameter,
  },
  {
    colId: 'ShroudInnerDiameter',
    headerText: 'Inner Diameter',
    type: ColumnType.number,
    unitSystem: UnitSystem.Diameter,
  },
  {
    colId: 'OpenAreaPercentage',
    headerText: 'Open Area',
    type: ColumnType.number,
    unitSystem: UnitSystem.None,
    overrideUnitSymbol: '%',
  },
];
