import { NgModule } from '@angular/core';
import { PagePanelModule } from '../../../common-modules/page-panel/page-panel.component';
import { CommonModule } from '@angular/common';
import { TabbedPageModule } from '../../../common-modules/tabbed-page/tabbed-page.component';
import { UnitsModule } from '../../../common-modules/units/units.module';
import { FluidSelectorModule } from '../../common/fluid-selector/fluid-selector.module';
import { GravelSelectorModule } from '../../common/gravel-selector/gravel-selector.module';
import { FormInputModule } from '../../../shared/components/form-components/form-input/form-input.component';
import { RadioGroupModule } from '../../../shared/components/form-components/radio-group/radio-group.component';
import { UnwrapDirectiveModule } from '../../../shared/directives/unwrap.directive';
import { ChartModule } from '../../../common-modules/chart/chart.module';
import { CheckBoxModule } from '../../../shared/components/form-components/check-box/check-box.component';
import { GridModule } from '../../../shared/components/grid/grid.module';
import { ModalService } from '../../../common-modules/modals/modal.service';
import { PsdDataComponent } from './psd-data/psd-data.component';
import { PsdDataGridComponent } from './psd-data/psd-data-grid/psd-data-grid.component';
import { ScreenService } from '../../../shared/services';
import { PsdChartPanelComponent } from './psd-chart/psd-chart-panel/psd-chart-panel.component';
import { PsdChartComponent } from './psd-chart/psd-chart.component';
import { PsdSelectorModule } from '../common-components/psd-selector/psd-selector.component';
import { ConvertUnitPipe } from '@dunefront/common/modules/units/convert-unit.pipe/convert-unit.pipe';
import { SelectModule } from '../../../shared/components/form-components/select/select.module';
import { ButtonModule } from '../../../shared/components/button/button.module';
import { PsdChartPanelRendererComponent } from './psd-chart/psd-chart-panel-renderer/psd-chart-panel-renderer.component';
import { EqualizeSymbolsDirectiveModule } from '../../../shared/directives/equalize-symbols.directive';

@NgModule({
  declarations: [PsdDataComponent, PsdDataGridComponent, PsdChartComponent, PsdChartPanelComponent, PsdChartPanelRendererComponent],
  imports: [
    PagePanelModule,
    CommonModule,
    TabbedPageModule,
    UnitsModule,
    SelectModule,
    FluidSelectorModule,
    GravelSelectorModule,
    FormInputModule,
    RadioGroupModule,
    UnwrapDirectiveModule,
    ChartModule,
    CheckBoxModule,
    GridModule,
    PsdSelectorModule,
    ButtonModule,
    EqualizeSymbolsDirectiveModule,
  ],
  providers: [ModalService, ScreenService, ConvertUnitPipe],
})
export class PSDInputModule {}
