import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabbedPageModule } from '../../common-modules/tabbed-page/tabbed-page.component';
import { PagePanelModule } from '../../common-modules/page-panel/page-panel.component';
import { UnitsModule } from '../../common-modules/units/units.module';
import { GridSelectComponentModule } from '../../common-modules/grid-select/grid-select.component';
import { CheckBoxModule } from '../../shared/components/form-components/check-box/check-box.component';
import { RadioGroupModule } from '../../shared/components/form-components/radio-group/radio-group.component';
import { GridModule } from '../../shared/components/grid/grid.module';
import { FormInputModule } from '../../shared/components/form-components/form-input/form-input.component';
import { UnwrapDirectiveModule } from '../../shared/directives/unwrap.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChartModule } from '../../common-modules/chart/chart.module';
import { GaugeDataChartComponent } from './gauge-data-chart/gauge-data-chart.component';
import { GaugeDataComponent } from './gauge-data/gauge-data.component';
import { GaugeDataPageComponent } from './gauge-data-page.component';
import { SelectModule } from '../../shared/components/form-components/select/select.module';
import { GaugeDataChartConfigurationComponent } from './gauge-data-chart/gauge-data-chart-configuration/gauge-data-chart-configuration.component';
import { ButtonModule } from '../../shared/components/button/button.module';
import { DataFileSelectorComponent } from './gauge-data/gauge-data-file-selector/data-file-selector.component';
import { GaugeDateGridComponent } from './gauge-data/gauge-data-grid/gauge-date-grid.component';
import { GaugeDataColumnPropsComponent } from './gauge-data/gauge-data-column-props/gauge-data-column-props.component';
import { NoGaugeDataMessageComponent } from './no-gauge-data-message/no-gauge-data-message.component';
import { AnalysisDataModule } from '../common/analysis-data/analysis-data.module';
import { AddEditColumnDialogComponent } from './gauge-data/add-column-dialog/add-edit-column-dialog.component';
import { AddColumnEquationInputComponent } from './gauge-data/add-column-dialog/add-column-equation-input/add-column-equation-input.component';
import { MatTableModule } from '@angular/material/table';
import { VariablesGridComponent } from './gauge-data/add-column-dialog/variables-grid/variables-grid.component';
import { EquationPreviewComponent } from './gauge-data/add-column-dialog/equation-preview/equation-preview.component';
import { MenuModule } from 'primeng/menu';
import { ButtonModule as BtnModule } from 'primeng/button';
import { DateTimeInputModule } from '../../shared/components/form-components/date-time-input/date-time-input.component';
import { ModalsModule } from '../../common-modules/modals/modals.module';
import { OnResizeDirectiveModule } from '../../shared/directives/on-resize.directive';
import { NewEquationFileDialogComponent } from '../../pages/gauge-data-page/gauge-data/new-equation-file-dialog/new-equation-file-dialog.component';
import { HelpUrlPipeModule } from '../../shared/pipes/help-url.pipe';

@NgModule({
  declarations: [
    GaugeDataPageComponent,
    GaugeDataChartComponent,
    GaugeDataComponent,
    GaugeDataChartConfigurationComponent,
    DataFileSelectorComponent,
    GaugeDateGridComponent,
    GaugeDataColumnPropsComponent,
    NoGaugeDataMessageComponent,
    AddEditColumnDialogComponent,
    AddColumnEquationInputComponent,
    VariablesGridComponent,
    EquationPreviewComponent,
    NewEquationFileDialogComponent,
  ],
  imports: [
    PagePanelModule,
    CommonModule,
    TabbedPageModule,
    UnitsModule,
    FormsModule,
    GridModule,
    ChartModule,
    CheckBoxModule,
    RadioGroupModule,
    ButtonModule,
    SelectModule,
    GridSelectComponentModule,
    FormInputModule,
    UnwrapDirectiveModule,
    GridSelectComponentModule,
    ChartModule,
    AnalysisDataModule,
    ReactiveFormsModule,
    MatTableModule,
    MenuModule,
    BtnModule,
    DateTimeInputModule,
    ModalsModule,
    OnResizeDirectiveModule,
    HelpUrlPipeModule,
  ],
})
export class GaugeDataPageModule {}
