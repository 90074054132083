import { WsAction } from '../../ws.action';
import { IImportTemplateDto } from '../data-storage/dto/import-template/import-template.dto';

export const ImportDataModuleName = 'ImportDataTemplateModule';

export enum ImportDataModuleActionTypes {
  ImportDataTemplateLoad = '[ImportDataModule] Load Template',
  ImportDataTemplateSave = '[ImportDataModule] Save Template',
  ImportDataTemplateDelete = '[ImportDataModule] Delete Template',
}

export class ImportDataTemplateLoadAction extends WsAction {
  public override readonly type = ImportDataModuleActionTypes.ImportDataTemplateLoad;
}

export class ImportDataTemplateSaveAction extends WsAction {
  public override readonly type = ImportDataModuleActionTypes.ImportDataTemplateSave;

  constructor(public dto: IImportTemplateDto) {
    super();
  }
}

export class ImportDataTemplateDeleteAction extends WsAction {
  public override readonly type = ImportDataModuleActionTypes.ImportDataTemplateDelete;

  constructor(public templateId: string) {
    super();
  }
}

export type ImportDataModuleActions = ImportDataTemplateLoadAction | ImportDataTemplateSaveAction | ImportDataTemplateDeleteAction;
