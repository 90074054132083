import { DocumentGeneratorHelper } from '../document-generator.helper';
import { HeadingLevel, Paragraph, Table } from 'docx';
import { IUnitSystemDto } from '@dunefront/common/dto/unit-system.dto';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';
import { getValidatedGravels } from '../../../../../../+store/gravel/gravel.selectors';
import { DictionaryWithArray } from '@dunefront/common/common/state.helpers';
import { Gravel } from '@dunefront/common/modules/gravel/model/gravel';
import { DocumentTableGeneratorHelper } from '../document-table-generator.helper';
import { gravelsProperties } from '../../common/grid-configs/gravels';
import { DocxHtmlParser } from '../../../generators/doc/docx-html-parser';

export class GravelsReportGeneratorHelper {
  public static async createGravelsDataSection(store: Store, currentUnitSystem: IUnitSystemDto): Promise<Array<Paragraph | Table>> {
    const gravels: Gravel[] = DictionaryWithArray.getArray(await firstValueFrom(store.select(getValidatedGravels)));

    return [
      DocumentGeneratorHelper.createParagraphHeader(`Gravel Data`, HeadingLevel.HEADING_1, 0),
      ...this.createGravelsData(gravels, currentUnitSystem),
    ];
  }

  private static createGravelsData(gravels: Gravel[], currentUnitSystem: IUnitSystemDto):  Array<Paragraph | Table> {


    const elements: Array<Paragraph | Table> = [];

    gravels.forEach((gravel) => {
      const singleFluidTableData = {
        dataRows: [{
          ...gravel,
          FrictionMultiplierDeviation: gravel.IsFrictionMultiplierByDeviation ? gravel.FrictionMultiplierDeviation : 0,
        }],
        dataCells: gravelsProperties
      };
      elements.push(
        ...[
          DocumentGeneratorHelper.createParagraphHeader(gravel.Name, HeadingLevel.HEADING_2, 1),
          DocumentTableGeneratorHelper.createTable(singleFluidTableData, null, currentUnitSystem, undefined, {
            0: 15,
            3: 11
          }),
          new Paragraph({}),
          ...new DocxHtmlParser(gravel.Notes).render()
        ]
      );
    });
    return elements;
  }
}
