import { BasePptSectionGenerator, PptContext } from '../base-ppt-section-generator';
import { IUnitSystemDto } from '@dunefront/common/dto/unit-system.dto';
import { firstValueFrom } from 'rxjs';
import { selectCurrentUnitSystem } from '../../../../../../+store/units/units.selectors';
import { CommonReportDataHelpers } from '../../common/common-report-data-helpers';
import { Pumping } from '@dunefront/common/modules/pumping/model/pumping/pumping';
import {
  getValidatedPumping,
  getValidatedPumpingModuleState,
} from '../../../../../../+store/pumping/selectors/pumping-validation.selectors';
import { PptDocumentTableGeneratorHelper } from '../ppt-document-table-generator.helper';
import { getFluidsSelectData, IFluidSelectorData } from '../../../../../../+store/fluid/fluid.selectors';
import { getGravelsSelectData, IGravelSelectorData } from '../../../../../../+store/gravel/gravel.selectors';
import { ValidatedPumpingModuleState } from '@dunefront/common/modules/pumping/pumping-module.state';

export class PumpingWellFluidsDataPptGenerator extends BasePptSectionGenerator {
  constructor(context: PptContext) {
    super(context);
  }

  public async generate(rangeName?: string): Promise<void> {
    const pumpingInitialState: Pumping = await firstValueFrom(this.store.select(getValidatedPumping));
    const pumpingState: ValidatedPumpingModuleState = await firstValueFrom(this.store.select(getValidatedPumpingModuleState));
    const currentUnitSystem: IUnitSystemDto = await firstValueFrom(this.store.select(selectCurrentUnitSystem));
    const fluidsSelectData: IFluidSelectorData = await firstValueFrom(this.store.select(getFluidsSelectData));
    const gravelsSelectData: IGravelSelectorData = await firstValueFrom(this.store.select(getGravelsSelectData));
    const mainTitle = 'Pumping';
    const subTitle = rangeName;

    if (pumpingInitialState.IsWellFluidSelectionSimple) {
      const title = `${mainTitle} - Well Fluids`;
      const tableData = CommonReportDataHelpers.createSimplePumpingWellFluidsTableData(
        pumpingInitialState,
        fluidsSelectData,
        currentUnitSystem,
      );
      const tableRows = PptDocumentTableGeneratorHelper.createGenericKeyValueTableRowsWithTableData(tableData, currentUnitSystem);

      this.addKeyValueTableSlide({ title, subTitle, tableRows });
    } else {
      const sections = CommonReportDataHelpers.createDetailedPumpingWellFluidsTableData(
        pumpingInitialState,
        pumpingState,
        fluidsSelectData,
        gravelsSelectData,
      );

      for (const { tableData, title: sectionTitle } of sections) {
        const title = `${mainTitle} - Well Fluids - ${sectionTitle}`;
        const tableRows = PptDocumentTableGeneratorHelper.createTableRows(tableData, null, currentUnitSystem);

        this.addTableSlide({ title, subTitle, tableRows, presetColumnsWidths: { 2: 30, 3: 30 } });
      }
    }
  }
}
