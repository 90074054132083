import { createAction, props } from '@ngrx/store';
import { IUpdateRowsWsActionProps } from '@dunefront/common/ws.action';
import { ReportInfoDto, ReportInfoFields } from '@dunefront/common/dto/report-info.dto';
import { ReportScenarioImages } from '../../shared/helpers/document-generator/generators/doc/sections/chart-report-generator.helper';
import { ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import { ActionResponse } from '@dunefront/common/response-ws.action';
import { ReportInfoRangesConfig } from './report-info.reducer';
import { DataSourceValue } from '@dunefront/common/common/common-state.interfaces';

export const loadReportInfoModuleAction = createAction('[ReportInfo] Load');
export const loadReportInfoModuleSuccessAction = createAction('[ReportInfo] LoadSuccess', props<ActionResponse<ReportInfoDto>>());
export const updateReportInfoAction = createAction('[ReportInfo] Update', props<IUpdateRowsWsActionProps<ReportInfoDto>>());

export const updateReportInfoFieldsAction = createAction(
  '[ReportInfo] UpdateReportInfoFieldsAction',
  props<{ changes: { value: DataSourceValue<ReportInfoFields>; changedKey: keyof ReportInfoFields }[] }>(),
);

export const updateReportInfoRangesConfigAction = createAction(
  '[ReportInfo] UpdateReportInfoRangesConfigAction',
  props<{ value: DataSourceValue<ReportInfoRangesConfig>; changedKey: keyof ReportInfoRangesConfig }>(),
);

export const generateReportAction = createAction(
  '[Report] GenerateReport',
  props<{
    reportInfo: ReportInfoDto;
    reportInfoFields: ReportInfoFields;
    reportName: string;
    images: ReportScenarioImages;
  }>(),
);
export const reportInfoSelectAllClearAllAction = createAction('[ReportInfo] toggleInfoFields', props<ToggleInfoFieldsActionPayload>());

export interface ToggleInfoFieldsActionPayload {
  selectAll: boolean;
  moduleType: ModuleType;
  isInputDataVisible: boolean;
  isImportedDataInfoVisible: boolean;
  isTrendAnalysisVisible: boolean;
  isPsdDataVisible: boolean;
  isSimulationAnimationVisible: boolean;
  isEvaluationAnimationVisible: boolean;
  isSimulationSummaryVisible: boolean;
  isChartsVisible: boolean;
}
