import { toWebM } from './utils/toWebM';
import { IWebPFrame } from './interfaces';
import { parseWebP } from './utils/parseWebP';
import { parseRIFF } from './utils/parseRIFF';

/**
 *
 * @param images - array of Base64 WebP images
 * @param fps - frames per second
 * @param outputAsArray - if true, it will return Uint8Array of video, otherwise will return Blob ( default option )
 */
export const videoFromImageArray = (images: string[], fps: number, outputAsArray = false): Blob | Uint8Array => {
  return toWebM(
    images.map((image: string) => {
      const webp: IWebPFrame = { ...parseWebP(parseRIFF(atob(image.slice(23)))), duration: 1000 / fps };
      return webp;
    }),
    outputAsArray,
  );
};
