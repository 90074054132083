import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { EnumHelpers } from '@dunefront/common/utils/enum.helpers';
import * as casingActions from '../../../../../+store/well/well.actions';
import { CasingPipeType, PipeType } from '@dunefront/common/dto/pipe.dto';
import { createTableRow, ITableRow, ITableState } from '@dunefront/common/common/common-grid.interfaces';
import { IGridColumnConfig } from '../../../../../shared/components/grid/grid.interfaces';
import { ModalService } from '../../../../../common-modules/modals/modal.service';
import { GridConfig } from '../../../../../shared/components/grid/grid-config';
import { GridContainerComponent } from '../../../../../shared/components/grid/grid-container.component';
import { ScreenService } from '../../../../../shared/services';
import { ISelectItem } from '@dunefront/common/common/select.helpers';
import { Pipe } from '@dunefront/common/modules/pipes/pipe';
import { GridResizeService } from '../../../../../shared/services/grid-resize.service';
import { IDeleteRowsProps, IInsertRowsProps, IUpdateTableRowsProps } from '@dunefront/common/common/common-store-crud.interfaces';
import { casingDataGridConfig, PIPE_TYPES_LOOKUP_SRC_ID } from './casing-data-grid.config';
import { ImportDataHelper } from '../../../../../shared/helpers/import-data/import-data-helper';
import { CasingPipesFactory } from '@dunefront/common/modules/pipes/casing-pipes/casing-pipes.factory';
import { InsertLocation } from '@dunefront/common/modules/common.interfaces';

@Component({
  selector: 'app-casing-data-grid',
  templateUrl: './casing-data-grid.component.html',
  styleUrls: ['./casing-data-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CasingDataGridComponent extends GridContainerComponent<Pipe> implements OnInit {
  @Input() public casingGridData: ITableState<Pipe> = { rows: [], isValid: true };

  private _columns: IGridColumnConfig<Pipe>[] = casingDataGridConfig;

  constructor(
    store: Store,
    cdRef: ChangeDetectorRef,
    protected modalService: ModalService,
    el: ElementRef,
    protected resizeService: ScreenService,
    gridResizeService: GridResizeService,
  ) {
    super(store, cdRef, el, new CasingGridConfig(store, modalService), gridResizeService);
  }

  protected getColumns(): IGridColumnConfig<Pipe>[] {
    return this._columns;
  }
}

export class CasingGridConfig extends GridConfig<Pipe> {
  public pipeTypes: ISelectItem<CasingPipeType>[] = [];
  public placeholder = '';
  public modal: ModalService;

  constructor(
    private store: Store,
    modalService: ModalService,
  ) {
    super(modalService);
    this.initLookupData();
    this.modal = modalService;
    this.headerText = 'Casing Data';
  }

  public initLookupData(): void {
    const pipeTypes = EnumHelpers.EnumToISelectItemArray(CasingPipeType);
    this.pipeTypes = [...pipeTypes];
    this.placeholder = 'Select Component';
  }

  public override mappingLookupDataByText(text: string): number | undefined {
    if (ImportDataHelper.containsWords(text, ['perf'])) {
      return CasingPipeType.Perforated_Casing;
    } else if (ImportDataHelper.containsWords(text, ['cas'])) {
      return CasingPipeType.Casing;
    } else if (ImportDataHelper.containsWords(text, ['rise'])) {
      return CasingPipeType.Riser;
    } else if (ImportDataHelper.containsWords(text, ['open']) || ImportDataHelper.containsWords(text, ['hole'])) {
      return CasingPipeType.Open_Hole;
    } else {
      return undefined;
    }
  }

  public override createEmptyModel(scenarioId: number): Pipe {
    return CasingPipesFactory.createEmpty(scenarioId);
  }

  public override createDefaultTableRows(scenarioId: number, noOfRows: number): ITableRow<Pipe>[] {
    const newTableRows = [];
    for (let index = 0; index < noOfRows; index++) {
      const newEmptyModel = this.createEmptyModel(scenarioId);
      newEmptyModel.PipeType = PipeType.Open_Hole;
      newTableRows.push(createTableRow(newEmptyModel, 'data', index, false));
    }
    return newTableRows;
  }

  public override replaceGridAction(rows: ITableRow<Pipe>[]): void {
    if (rows.length > 0) {
      const props: IInsertRowsProps<any> = {
        rows,
        refId: 0,
        insertLocation: 'replace' as InsertLocation,
        shouldResetResults: true,
      };
      this.store.dispatch(casingActions.insertCasingRow(props));
    }
  }

  public override isCellDisabled(rows: ITableRow<Pipe>[], rowIndex: number, cellIndex: number): boolean {
    if (rows.length === 0) {
      return false;
    }

    if (rows[rowIndex].rowType === 'insert-row') {
      return this.columns[cellIndex].colId !== 'PipeType';
    }

    const colId = this.columns[cellIndex].colId as keyof Pipe;
    const openHoleDisabledColumns: (keyof Pipe)[] = ['OuterDiameter', 'InnerDiameter', 'Weight'];
    return rows[rowIndex]?.rowData.PipeType === PipeType.Open_Hole && openHoleDisabledColumns.includes(colId);
  }

  public override updateRowsAction(props: IUpdateTableRowsProps<Pipe>): void {
    this.store.dispatch(casingActions.updateCasingRow(props));
  }

  public override insertRowAction(props: IInsertRowsProps<Pipe>, refRow?: ITableRow<Pipe>): void {
    if (props.rows.length === 1 && refRow && props.insertLocation !== 'merge-rows') {
      const row = props.rows[0];
      if (refRow.rowData.PipeType === PipeType.Riser) {
        row.rowData.PipeType = PipeType.Casing;
      }
      row.rowData.BottomMD = refRow.rowData.TopMD;
    }

    this.store.dispatch(casingActions.insertCasingRow(props));
  }

  public override deleteRowsAction(props: IDeleteRowsProps): void {
    this.store.dispatch(casingActions.deleteCasingRows(props));
  }

  // this need to be defined like that because of lexical scope
  public override getLookupDataSource = (type: string): ISelectItem<any>[] => {
    if (type === PIPE_TYPES_LOOKUP_SRC_ID) {
      return this.pipeTypes;
    }
    return [];
  };

  public override getLookupDataPlaceholder = (type: string): string => {
    if (type === PIPE_TYPES_LOOKUP_SRC_ID) {
      return this.placeholder;
    }
    return '';
  };

  protected override createMergedRow(rows: ITableRow<Pipe>[], firstRowIndex: number, lastRowIndex: number): ITableRow<Pipe> | undefined {
    if (rows[firstRowIndex] == null || rows[lastRowIndex] == null) {
      return undefined;
    }
    const mergedRow = { ...rows[firstRowIndex], rowData: { ...rows[firstRowIndex].rowData } };
    mergedRow.rowData = { ...mergedRow.rowData, Id: -1, BottomMD: rows[lastRowIndex].rowData['BottomMD'] };
    return mergedRow;
  }
}
