import { IValidatedModuleState } from '@dunefront/common/common/common-state.interfaces';
import {
  ImportDataColumnDelimiterConfig,
  ImportFileColumnDelimiter,
} from '@dunefront/common/modules/data-storage/dto/import-template/import-template.dto';

export type IValidatedImportDataColumnDelimiterConfig = ImportDataColumnDelimiterConfig &
  IValidatedModuleState<ImportDataColumnDelimiterConfig>;

export class ColumnDelimiterHelper {
  public static get(delimiterConfig: ImportDataColumnDelimiterConfig): string {
    switch (delimiterConfig.delimiter) {
      case ImportFileColumnDelimiter.Auto:
        throw new Error("Can't use Auto delimiter");
      case ImportFileColumnDelimiter.Comma:
        return ',';
      case ImportFileColumnDelimiter.Space:
        return ' ';
      case ImportFileColumnDelimiter.Tab:
        return '\t';
      case ImportFileColumnDelimiter.Custom:
        return delimiterConfig.customDelimiter;
    }
  }
}
