import { PipeType } from '../../../../dto/pipe.dto';
import { GaugeMeasurementType, RunningStringPipeDto } from '../../../../dto/running-string-pipe.dto';
import { BasePipeFactory } from '../../base.pipe.factory';
import { Pipe } from '../../pipe';
import { ITableRow } from '../../../../common/common-grid.interfaces';

export interface GaugeCarrierPipe extends Pipe {
  GaugeMeasurementType: GaugeMeasurementType;
  PipeType: PipeType.Eccentric_Gauge_Carrier | PipeType.Concentric_Gauge_Carrier;
  IsGaugeCarrier: true;
}

export class GaugeCarrierPipeFactory {
  public static createFromRunningString(runningStringDto: RunningStringPipeDto): GaugeCarrierPipe {
    const pipe = BasePipeFactory.createFromDto(runningStringDto);
    const eccentricGaugeCarrierPipe: GaugeCarrierPipe = {
      ...pipe,
      GaugeMeasurementType: runningStringDto.GaugeMeasurementType,
      PipeType: PipeType.Eccentric_Gauge_Carrier,
      IsGaugeCarrier: true,
    };
    return eccentricGaugeCarrierPipe;
  }
}

export function isGaugeCarrierPipeRow(row: ITableRow<Pipe>): row is ITableRow<GaugeCarrierPipe> {
  return isGaugeCarrierPipe(row.rowData);
}

export function isGaugeCarrierPipe(pipe: Pipe): pipe is GaugeCarrierPipe {
  return pipe.PipeType === PipeType.Eccentric_Gauge_Carrier || pipe.PipeType === PipeType.Concentric_Gauge_Carrier;
}
