import { DictionaryWithArray, IDictionaryWithArray, noErrors } from '../../../../common/state.helpers';
import { PsdReading } from './psd-reading';
import { getRowsForCalculations, ITableRow, ITableState } from '../../../../common/common-grid.interfaces';
import { PSD } from '../psd/psd';
import { PSDInputType } from '../../dto/psd.dto';
import { IError } from '../../../../common/common-state.interfaces';
import { MathHelpers } from '../../../../common/math-helpers';

export class PSDReadingsValidation {
  public static ValidatePSDReadings(
    psdReadingsDict: IDictionaryWithArray<ITableState<PsdReading>>,
    psdDict: IDictionaryWithArray<PSD>,
  ): IDictionaryWithArray<ITableState<PsdReading>> {
    let newPsdReadingsDict = { ...psdReadingsDict };

    psdReadingsDict.ids.forEach((psdId) => {
      const psdReadingsTableState = DictionaryWithArray.get(psdReadingsDict, psdId);
      const psd = DictionaryWithArray.get(psdDict, psdId);
      if (!psd || !psdReadingsTableState) {
        return;
      }
      const rows = psdReadingsTableState.rows;
      const psdReadings = getRowsForCalculations(psdReadingsTableState.rows);
      let isValid = true;
      const newRows = rows.map((row, index) => {
        const newRow = this.ValidatePSDReadingsRow(row, psdReadings, psd, index);
        isValid = isValid && newRow.isValid;
        return newRow;
      });
      const newPsdReadingsTableState: ITableState<PsdReading> = { ...psdReadingsTableState, rows: newRows, isValid };
      newPsdReadingsDict = DictionaryWithArray.upsertById(newPsdReadingsDict, newPsdReadingsTableState, psdId);
    });

    return newPsdReadingsDict;
  }

  private static ValidatePSDReadingsRow(
    row: ITableRow<PsdReading>,
    psdReadings: PsdReading[],
    psd: PSD,
    index: number,
  ): ITableRow<PsdReading> {
    const error: IError<PsdReading> = {
      SieveOpening: this.ValidateSieveOpening(row.rowData, psdReadings),
      InitialSieveWeight: this.ValidateInitialSieveWeight(row.rowData, psd),
      FinalSieveWeight: this.ValidateFinalSieveWeight(row.rowData, psdReadings, psd),
      PercentWeightRetained: this.ValidatePercentWeightRetained(row.rowData.PercentWeightRetained, psd),
      CumulativePercentWeightRetained: this.ValidateCumulativePercentWeightRetained(
        row.rowData.CumulativePercentWeightRetained,
        psdReadings,
        index,
        psd,
      ),
      SieveDescription: this.ValidateSieveDescription(row.rowData.SieveDescription),
    };
    return { ...row, error, isValid: noErrors(error) };
  }

  private static ValidateSieveOpening(psdReading: PsdReading, psdReadings: PsdReading[]): string {
    if (psdReading.SieveDescription !== 'Pan') {
      if (psdReading.SieveOpening <= 0) {
        return 'Sieve opening must be greater than 0';
      }
      if (psdReadings.filter((row) => row.SieveOpening === psdReading.SieveOpening).length > 1) {
        return 'Sieve opening must be unique';
      }
    }
    return '';
  }

  private static ValidateInitialSieveWeight(psdReading: PsdReading, psd: PSD): string {
    if (psd.PSDInputType === PSDInputType.Weight) {
      if (psdReading.InitialSieveWeight < 0) {
        return 'Initial sieve weight must be greater than or equal to 0';
      }
      if (psdReading.InitialSieveWeight > psdReading.FinalSieveWeight) {
        return 'Initial sieve weight must be less than or equal to final sieve weight';
      }
    }
    return '';
  }

  private static ValidateFinalSieveWeight(psdReading: PsdReading, psdReadings: PsdReading[], psd: PSD): string {
    if (psd.PSDInputType === PSDInputType.Weight) {
      let totalWeight = 0;
      for (const reading of psdReadings) {
        totalWeight += reading.FinalSieveWeight - reading.InitialSieveWeight;
      }
      if (psdReading.FinalSieveWeight < 0) {
        return 'Final sieve weight must be greater than or equal to 0';
      }
      if (psdReading.InitialSieveWeight > psdReading.FinalSieveWeight) {
        return 'Final sieve weight must be greater than or equal to initial sieve weight';
      }
      if (totalWeight <= 0) {
        return 'Total sample weight must be greater than 0';
      }
    }
    return '';
  }

  private static ValidatePercentWeightRetained(percentWeightRetained: number, psd: PSD): string {
    if (psd.PSDInputType === PSDInputType.PercentWeight) {
      if (percentWeightRetained < 0 || percentWeightRetained >= 100) {
        return 'Percent weight retained must be between 0 and 100';
      }
    }
    return '';
  }

  private static ValidateCumulativePercentWeightRetained(
    cumulativePercentWeightRetained: number,
    psdReadings: PsdReading[],
    index: number,
    psd: PSD,
  ): string {
    if (psd.PSDInputType === PSDInputType.CumulativePercentWeight) {
      if (cumulativePercentWeightRetained < 0 || MathHelpers.round(cumulativePercentWeightRetained, 3) > 100) {
        return 'Cumulative percent weight retained must be between 0 and 100';
      }
      if (index > 0 && cumulativePercentWeightRetained < psdReadings[index - 1].CumulativePercentWeightRetained) {
        return 'Cumulative percent weight retained must be greater than or equal to previous row';
      }
    }
    return '';
  }

  private static ValidateSieveDescription(sieveDescription: string): string {
    if (sieveDescription.length === 0) {
      return "Sieve description can't be empty";
    }
    return '';
  }
}
