import { ColumnType, IGridColumnConfig } from '../../../../../shared/components/grid/grid.interfaces';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { PSDResult } from '@dunefront/common/modules/psd-analysis/model/psd/psd';

export const getSummaryGridConfig = (percentFinesLabel: string): IGridColumnConfig<PSDResult>[] => {
  return [
    { disabled: true, visible: true, colId: ' ', type: ColumnType.selection },
    {
      disabled: true,
      visible: true,
      colId: 'Description',
      headerText: 'PSD Description',
      width: 100,
      type: ColumnType.text,
    },
    {
      disabled: true,
      visible: true,
      colId: 'Depth',
      width: 100,
      type: ColumnType.number,
      unitSystem: UnitSystem.Long_Length,
      headerText: 'PSD Depth',
    },
    {
      disabled: true,
      visible: true,
      colId: 'UniformityCoefficient',
      width: 100,
      type: ColumnType.number,
      unitSystem: UnitSystem.None,
      headerText: 'Uniformity Coefficient',
    },
    {
      disabled: true,
      visible: true,
      colId: 'SortingCoefficient',
      width: 100,
      type: ColumnType.number,
      unitSystem: UnitSystem.None,
      headerText: 'Sorting Coefficient',
    },
    {
      disabled: true,
      visible: true,
      colId: 'PercentFines',
      width: 100,
      type: ColumnType.number,
      unitSystem: UnitSystem.None,
      headerText: percentFinesLabel,
      overrideUnitSymbol: '%',
    },
    {
      disabled: true,
      visible: true,
      colId: 'D10',
      width: 100,
      type: ColumnType.number,
      headerText: 'd10',
      unitSystem: UnitSystem.Screen_Opening,
      decimalPlaces: 3,
    },
    {
      disabled: true,
      visible: true,
      colId: 'D50',
      width: 100,
      type: ColumnType.number,
      headerText: 'd50',
      unitSystem: UnitSystem.Screen_Opening,
      decimalPlaces: 3,
    },
  ];
};
