import { UnitConverter } from '../../converter.interfaces';
import { ThermalConductivityUnit } from '../../../dto/unit-system.dto';

export class ThermalConductivityConverter extends UnitConverter {
  public static symbols: Array<string> = ['', 'W/m·°C', 'Btu/h·ft·°F'];

  public static unit = ThermalConductivityUnit;

  public static fromSi(value: number, unit: ThermalConductivityUnit): number {
    switch (unit) {
      case ThermalConductivityUnit.MetricThermalConductivity: {
        return value;
      }
      case ThermalConductivityUnit.OilfieldThermalConductivity: {
        return value * 0.5777870453;
      }
      default: {
        throw new TypeError('Unknown Thermal Conductivity Unit:' + unit);
      }
    }
  }

  public static toSi(value: number, unit: ThermalConductivityUnit): number {
    switch (unit) {
      case ThermalConductivityUnit.MetricThermalConductivity: {
        return value;
      }
      case ThermalConductivityUnit.OilfieldThermalConductivity: {
        return value / 0.5777870453;
      }
      default: {
        throw new TypeError('Unknown Thermal Conductivity Unit:' + unit);
      }
    }
  }

  public static getSymbol(unit: ThermalConductivityUnit): string {
    return this.symbols[unit];
  }
}
