<ng-container *ngIf="icdScreenPipeRow">
  <div class="grid-form-container no-padding inline col-span-2">
    <label>ICD/AICD Type</label>
    <app-select
      [source]="icdScreenPipeRow.rowData"
      [key]="'ICDType'"
      [items]="IcdTypeItems"
      (valueChanged)="onValueChange($event)"
      [overrideErrorMessage]="icdScreenPipeRow.error.ICDType"
      [width]="250"
      dataCy="icd-aicd-type"
    >
    </app-select>
  </div>
  <div class="grid-form-container no-padding inline" *ngIf="icdType === ICDType.BHGE_ICD_Or_AICD || icdType === ICDType.Generic_ICD">
    <button
      appButton
      data-cy="edit-port-data-btn"
      type="button"
      class="btn btn-primary btn-sm"
      tooltipPosition="top"
      positionStyle="absolute"
      tooltipStyleClass="error-tooltip"
      [tooltipDisabled]="icdValid"
      [pTooltip]="'Click the button to correct port data errors'"
      (buttonClick)="editPortDataClick()"
      [isUiLockable]="false"
    >
      Edit Port Data<span *ngIf="!icdValid" class="error-row-icon"></span>
    </button>
    <ng-template #errorMessageTemplate>Click the button to correct port data errors</ng-template>
  </div>
  <div
    class="grid-form-container no-padding inline"
    *ngIf="isNumberOfPortsInputVisible && this.icdPortData && this.icdPortData.rows.length"
  >
    <app-form-input
      appUnwrap
      label="No. of Ports"
      [source]="icdPortData.rows[0]!"
      [key]="'NumberOfPorts'"
      [unitType]="UnitType.None"
      [decimalPlaces]="0"
      [hideUnitBox]="true"
      (valueChanged)="onIcdValueChange($event)"
    ></app-form-input>
  </div>
  <div
    class="grid-form-container no-padding inline cols-8 equiflow-ports col-span-2"
    *ngIf="icdType === ICDType.Halliburton_EquiFlow_Nozzle_ICD"
  >
    <app-form-input
      appUnwrap
      [label]="getPortsLabel(0.075)"
      [source]="icdScreenPipeRow"
      [key]="'NoOf0_075inPorts'"
      [unitType]="UnitType.None"
      [decimalPlaces]="0"
      [hideUnitBox]="true"
      (valueChanged)="onValueChange($event)"
    ></app-form-input>
    <app-form-input
      appUnwrap
      [label]="getPortsLabel(0.1)"
      [source]="icdScreenPipeRow"
      [key]="'NoOf0_100inPorts'"
      [unitType]="UnitType.None"
      [decimalPlaces]="0"
      [hideUnitBox]="true"
      (valueChanged)="onValueChange($event)"
    ></app-form-input>
    <app-form-input
      appUnwrap
      [label]="getPortsLabel(0.125)"
      [source]="icdScreenPipeRow"
      [key]="'NoOf0_125inPorts'"
      [unitType]="UnitType.None"
      [decimalPlaces]="0"
      [hideUnitBox]="true"
      (valueChanged)="onValueChange($event)"
    ></app-form-input>
    <app-form-input
      appUnwrap
      [label]="getPortsLabel(0.15)"
      [source]="icdScreenPipeRow"
      [key]="'NoOf0_150inPorts'"
      [unitType]="UnitType.None"
      [decimalPlaces]="0"
      [hideUnitBox]="true"
      (valueChanged)="onValueChange($event)"
    ></app-form-input>
  </div>
</ng-container>
