import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CompletionModuleState } from '@dunefront/common/modules/completion/completion-module.state';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';

@Component({
  selector: 'app-screen-centralizers',
  templateUrl: './screen-centralizers.component.html',
  styleUrls: ['./screen-centralizers.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScreenCentralizersComponent {
  @Input() public completionData: CompletionModuleState | undefined;
  @Output() public completionPropertyChanged: EventEmitter<ObjectChangeProp<CompletionModuleState>> = new EventEmitter();
  public UnitType = UnitSystem;

  public onValueChange(props: ObjectChangeProp<CompletionModuleState>): void {
    this.completionPropertyChanged.emit(props);
  }
}
