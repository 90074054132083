import { UserRole } from './auth.interfaces';
import { User } from '@auth0/auth0-angular';

export const userRoleFromClaims = (claims: User): UserRole => {
  let role = UserRole.USER;

  if (claims['https://dunefrontcloud.com/is_admin'] === true) {
    role = UserRole.SUPER_ADMIN;
  }

  // Todo add org admin field to claims

  return role;
};

export const isAdmin = (role: UserRole): boolean => role === UserRole.ORG_ADMIN || role === UserRole.SUPER_ADMIN;
export const isSuperAdmin = (role: UserRole): boolean => role === UserRole.SUPER_ADMIN;

export const allowAdminOrThrowError = (role: UserRole): void => {
  if (!isAdmin(role)) {
    throw new Error('Only Admin user can perform this operation');
  }
};

export const allowSuperAdminOrThrowError = (role: UserRole): void => {
  if (!isSuperAdmin(role)) {
    throw new Error('Only SuperAdmin user can perform this operation');
  }
};
