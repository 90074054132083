import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { DbDependentComponent } from '../../../../common-modules/db-connection/db-dependent.component';
import { getFlowRegimeLegendItems, IDrawing } from '../../../../+store/reporting/reporting-depth-animation.selectors';
import { defaultDrawingPoint, IDrawPoint, IDrawPointWithAngle } from './drawing.component.types';
import { ScreenService } from '../../../../shared/services';
import { IUnitSystemDto } from '@dunefront/common/dto/unit-system.dto';
import { IAxisStyle } from '@dunefront/common/modules/reporting/dto/chart-axis-property.dto';
import { selectUserGlobalOptions } from '../../../../+store/common-db/common-db.selectors';
import { combineLatest, firstValueFrom } from 'rxjs';
import { getCurrentAppModuleType, getIsRibbonDisplayed, getPinSettings, getUiChartZoomOriginalSize } from '../../../../+store/ui/ui.selectors';
import { distinctUntilChanged, filter, skip } from 'rxjs/operators';
import { Base64Image } from '../../../../common-modules/chart/image-provider.helpers';
import { Point } from 'chart.js';
import { GlobalOptionsDto, initialGlobalOptionsDto } from '@dunefront/common/dto/global-options.dto';
import { ResetZoomMode } from '../../../../+store/ui/ui.actions';
import { getIsCrosshairVisible } from '../../../../+store/reporting/reporting.selectors';
import { VideoRecorderService } from '../../../../common-modules/chart/video-recorder.service';
import { DrawingLayoutType, isFlowRegimeWellVisualizationType, WellPartType } from '@dunefront/common/modules/settings/dto/settingsDto';
import { undefinedChartId } from '@dunefront/common/modules/reporting/dto/chart-data.dto';
import { DrawableRegistryService } from '../../../../shared/services/drawable-registry.service';
import {
  partsToDrawAll,
  WellDrawingCommonParams,
  WellDrawingContext,
  WellDrawingHelpers,
  WellDrawingParams,
  WellDrawingResults,
} from './well-drawing-helpers';
import { ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import { findCenterPoint } from '../../../../shared/helpers/canvas-drawing-helpers';
import { DrawingSurveyMapHelper } from './drawing.survey-map.helper';
import { Size } from '@dunefront/common/common/math-geometry-helpers';

@Component({
  selector: 'app-drawing',
  templateUrl: './drawing.component.html',
  styleUrls: ['./drawing.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DrawingComponent extends DbDependentComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy, WellDrawingContext {
  @ViewChild('drawingCanvas') public drawingCanvas?: ElementRef<HTMLCanvasElement>;
  @ViewChild('drawingDiv') public drawingDiv?: ElementRef<HTMLDivElement>;

  @Input() public isRecordingAllowed = false;

  @Input() public drawing!: IDrawing;
  @Input() public drawingSection: WellPartType = WellPartType.Full_Well;
  @Input() public layoutType: DrawingLayoutType = DrawingLayoutType.Horizontal;
  @Input() public isInPanel = false;
  @Input() public drawableProviderId = 'unknown';
  @Input() public drawableProviderName = 'unknown';
  @Input() public chartId: number = undefinedChartId;

  private isReadyToDraw = false;

  public axisStyles: IAxisStyle | null = null;
  private draggingPosition: IDrawPoint | null = null;
  private lastDraggingPosition: IDrawPoint = defaultDrawingPoint();
  private canvasOffset: IDrawPoint = defaultDrawingPoint();
  private topCenterPoint: IDrawPointWithAngle = { ...defaultDrawingPoint(), angle: 0 };

  public moduleType?: ModuleType;
  public zoom = 1;

  public lastMousePosition: Point | undefined;
  public defaultGlobalOptions: GlobalOptionsDto = initialGlobalOptionsDto;
  public isTooltipVisible = true;

  constructor(
    store: Store,
    cdRef: ChangeDetectorRef,
    private readonly screenService: ScreenService,
    private readonly drawableRegistryService: DrawableRegistryService,
    private readonly videoRecorder: VideoRecorderService,
  ) {
    super(store, cdRef);

    this.subscription.add(this.store.select(selectUserGlobalOptions).subscribe((globalOptions) => (this.defaultGlobalOptions = globalOptions)));

    this.subscription.add(this.store.select(getCurrentAppModuleType).subscribe((moduleType) => (this.moduleType = moduleType)));

    this.subscription.add(this.screenService.screenResized$.subscribe(() => this.draw()));

    this.subscription.add(
      this.store
        .select(getUiChartZoomOriginalSize)
        .pipe(
          filter((action) => action.mode === ResetZoomMode.ALL || action.mode === ResetZoomMode.DRAWING),
          skip(1),
          distinctUntilChanged(),
        )
        .subscribe(() => {
          this.resetZoomAndDrag();
          this.draw();
        }),
    );

    this.subscription.add(
      combineLatest([this.store.select(getPinSettings), this.store.select(getIsRibbonDisplayed)]).subscribe(() => {
        // resize animation have to end first
        setTimeout(() => {
          this.draw();
        }, 300);
      }),
    );

    this.subscription.add(
      this.store.select(getIsCrosshairVisible).subscribe((isCrosshairVisible) => {
        this.isTooltipVisible = isCrosshairVisible;
        this.draw();
      }),
    );
  }

  public override ngOnInit(): void {
    super.ngOnInit();

    this.isReadyToDraw = true;
    this.draw();
  }

  // @HostListener('document:keydown', ['$event'])
  // public onKeyDown(event: KeyboardEvent): void {
  //   if (event.key === 'Shift') {
  //     this.isShiftDown = true;
  //   }
  // }
  //
  // @HostListener('document:keyup', ['$event'])
  // public onKeyUp(event: KeyboardEvent): void {
  //   if (event.key === 'Shift') {
  //     this.isShiftDown = false;
  //   }
  // }

  @HostListener('mouseout', ['$event'])
  public onMouseOut(): void {
    // hide crosshair on mouse out
    this.lastMousePosition = undefined;
    this.draw();
  }

  @HostListener('document:mousedown', ['$event'])
  public onMouseDown(event: MouseEvent): void {
    if (this.drawingDiv == null || this.videoRecorder.isRecordingAnything()) {
      return;
    }
    const canvasBoundingRect = this.drawingDiv.nativeElement.getBoundingClientRect();
    const isMouseOnCanvas =
      event.x >= canvasBoundingRect.left &&
      event.x <= canvasBoundingRect.width + canvasBoundingRect.left &&
      event.y >= canvasBoundingRect.top &&
      event.y <= canvasBoundingRect.height + canvasBoundingRect.top;

    if (isMouseOnCanvas) {
      this.draggingPosition = { x: event.x, y: event.y };
    }
  }

  @HostListener('document:mouseup')
  public onMouseUp(): void {
    if (this.videoRecorder.isRecordingAnything()) {
      return;
    }
    this.lastDraggingPosition = { ...this.canvasOffset };
    this.draggingPosition = null;

    this.draw();
  }

  @HostListener('document:mousemove', ['$event'])
  public onMouseMove(event: MouseEvent): void {
    if (this.videoRecorder.isRecordingAnything()) {
      return;
    }

    const canvas = this.drawingCanvas?.nativeElement;
    if (canvas != null && event.target === canvas) {
      const { offsetX, offsetY } = event;
      this.lastMousePosition = { x: offsetX, y: offsetY } as Point;
      this.draw();
    }
    // TODO: !!!!!

    if (!this.draggingPosition) {
      return;
    }
    // if (!this.isShiftDown) { // Draw squares functionality
    const offsetX = event.x - this.draggingPosition.x;
    const offsetY = event.y - this.draggingPosition.y;
    this.canvasOffset = { x: this.lastDraggingPosition.x + offsetX, y: this.lastDraggingPosition.y + offsetY };
    this.draw();
    // } else {
    //   const canvas = this.drawingCanvas.nativeElement;
    //   const ctx = canvas.getContext('2d');
    //   const el = this.drawingDiv.nativeElement;
    //
    //   const x = this.draggingPosition.x - el.offsetLeft;
    //   const y = this.draggingPosition.y - el.offsetTop;
    //   const width = event.x - x - el.offsetLeft;
    //   const height = event.y - y - el.offsetTop;
    //
    //   this.squareDrawn = { x, y, height, width };
    //
    //   this.draw();
    //   ctx.fillStyle = 'rgba(225,225,225,0.3)';
    //   ctx.fillRect(x, y, width, height);
    // }
  }

  @HostListener('wheel', ['$event'])
  public onScroll(event: WheelEvent): void {
    if (this.drawingDiv == null) {
      return;
    }
    const delta = event.deltaY / 20;
    const factor = delta < 0 ? 1 / 0.95 : 0.95;

    this.zoom *= factor;

    const { left, top } = this.drawingDiv.nativeElement.getBoundingClientRect();

    const x = event.x - left;
    const y = event.y - top;

    const dx = (x - this.topCenterPoint.x) * (factor - 1);
    const dy = (y - this.topCenterPoint.y) * (factor - 1);

    this.canvasOffset.x -= dx;
    this.canvasOffset.y -= dy;

    this.lastDraggingPosition = { ...this.canvasOffset };
    this.draw();
  }

  @HostListener('dblclick')
  public onDbClick(): void {
    this.resetZoomAndDrag();
    this.draw();
  }

  public async ngAfterViewInit(): Promise<void> {
    this.axisStyles = await firstValueFrom(this.store.select(selectUserGlobalOptions));
    this.draw();

    this.drawableRegistryService.registerProvider({
      id: this.drawableProviderId,
      getDisplayName: () => this.drawableProviderId,
      getChartId: () => this.chartId,
      getBase64Image: () => this.getBase64Image(),
      getCanvasForRecording:
        this.isRecordingAllowed && this.drawingCanvas ? (): HTMLCanvasElement => (this.drawingCanvas as ElementRef).nativeElement : undefined,
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.drawingSection != null || changes.layoutType != null) {
      this.resetZoomAndDrag();
    }
    this.draw();
  }

  public override ngOnDestroy(): void {
    this.isReadyToDraw = false;
    super.ngOnDestroy();
    this.drawableRegistryService.unregisterProvider(this.drawableProviderId);
  }

  private resetZoomAndDrag(): void {
    this.zoom = 1;
    this.draggingPosition = defaultDrawingPoint();
    this.canvasOffset = defaultDrawingPoint();
    this.lastDraggingPosition = defaultDrawingPoint();
    this.draggingPosition = null;
  }

  public override onUnitSystemChanged(currentUnitSystem: IUnitSystemDto): void {
    super.onUnitSystemChanged(currentUnitSystem);
    this.draw();
  }

  private draw(): void {
    if (!this.isReadyToDraw || this.drawing == null || this.drawingDiv == null || this.moduleType == null) {
      return;
    }

    const canvas = this.drawingCanvas?.nativeElement;
    if (canvas == null) {
      return;
    }

    const ctx = canvas.getContext('2d');
    if (ctx == null) {
      return;
    }

    // update canvas size
    const { width, height } = this.drawingDiv.nativeElement.getBoundingClientRect();
    canvas.width = width;
    canvas.height = height;

    // obtain legendHeight
    const legendItems = isFlowRegimeWellVisualizationType(this.drawing.wellVisualizationType)
      ? getFlowRegimeLegendItems()
      : WellDrawingHelpers.fluidsToLegend(this.drawing);

    const legendHeight = WellDrawingHelpers.getLegendHeight(legendItems, ctx, width);

    // add white background ( for recording )
    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    const smoothedSurvey = DrawingSurveyMapHelper.smoothSurvey(this.drawing.survey);
    const smoothedHole = DrawingSurveyMapHelper.extraHoleSectionsFromSurvey(this.drawing.hole, smoothedSurvey);
    const pipesAsDrawingHoleSections = DrawingSurveyMapHelper.extraHoleSectionsFromSurvey(
      WellDrawingHelpers.innerPipesToHoleSizes(this.drawing.runningString),
      smoothedSurvey,
    );

    const commonWellDrawingParams: WellDrawingCommonParams = {
      ctx,
      canvasSize: { width, height },
      context: this,
      drawing: this.drawing,
      drawingSection: this.drawingSection,
      layoutType: this.layoutType,
      smoothedSurvey,
      smoothedHole,
      pipesAsDrawingHoleSections,
      legendHeight,
      legendItems,
    };

    const defaultWellDrawingParams: WellDrawingParams = {
      ...commonWellDrawingParams,
      partsToDraw: partsToDrawAll,
      wellSize: { width, height },
      offset: this.canvasOffset,
      wScaleFactor: 1,
      zoomFactor: this.zoom,
    };

    let drawingResults: WellDrawingResults[] | undefined;

    // FluidPro
    if (this.moduleType === ModuleType.Simulate_Disp) {
      const isHorizontal = this.layoutType === DrawingLayoutType.Horizontal;

      const xOffset = isHorizontal ? 0 : (width / 2.0) * this.zoom;
      const yOffset = isHorizontal ? (height / 2.0) * this.zoom : 0;

      // make wells thinner when less space available
      const wScaleFactor = (isHorizontal && height < 500) || (!isHorizontal && width < 500) ? 0.5 : 1;

      const size: Size = isHorizontal ? { width, height: height / 2 } : { width: width / 2, height };

      // down path params
      const downPathParams: WellDrawingParams = {
        ...defaultWellDrawingParams,
        partsToDraw: {
          ...partsToDrawAll,
          upperAnnulus: false,
          washpipe: false,
        },
        offset: { x: this.canvasOffset.x, y: this.canvasOffset.y },
        wScaleFactor,
        wellSize: size,
      };

      // up path params
      const upPathParams: WellDrawingParams = {
        ...defaultWellDrawingParams,
        partsToDraw: {
          ...partsToDrawAll,
          lowerAnnulus: false,
          workstring: false,
        },
        ctx,
        offset: { x: this.canvasOffset.x + xOffset, y: this.canvasOffset.y + yOffset },
        wScaleFactor,
        wellSize: size,
      };

      // draw wells
      drawingResults = WellDrawingHelpers.drawWells(commonWellDrawingParams, [downPathParams, upPathParams]);
    } else {
      // PackPro
      drawingResults = WellDrawingHelpers.drawWells(commonWellDrawingParams, [defaultWellDrawingParams]);
    }

    if (drawingResults != null) {
      this.updateTopCenterPoint(drawingResults);
      this.videoRecorder.handleChartUpdated(this.drawableProviderId).then();
    }
  }

  private updateTopCenterPoint(drawingResults: WellDrawingResults[]): void {
    if (drawingResults.length === 0) {
      return;
    }

    const centerPoints = drawingResults.map((r) => WellDrawingHelpers.xyAngleForMd(r.layout.topMd, r.layout));
    this.topCenterPoint = {
      ...findCenterPoint(centerPoints),
      angle: 0,
    };
  }

  private async getBase64Image(): Promise<Base64Image | null> {
    const canvas = this.drawingCanvas?.nativeElement;
    if (canvas == null) {
      return null;
    }

    const { width, height } = canvas;
    const imageDataBase64 = canvas.toDataURL();

    return {
      imageDataBase64,
      size: { width, height },
    };
  }
}
