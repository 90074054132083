import { ChartSeriesDto } from '@dunefront/common/modules/reporting/dto/chart-series.dto';
import { DeepPartial } from 'chart.js/dist/types/utils';
import { Chart, CoreScaleOptions, FontSpec, Point, Scale } from 'chart.js';
import { ChartAxis, IAxisStyle } from '@dunefront/common/modules/reporting/dto/chart-axis-property.dto';
import { IChartDataDto, IChartDataDtoColumn, LineChartDataSet } from '@dunefront/common/modules/reporting/dto/chart-data.dto';
import { ChartContext, IArgumentRange, IAxisData, IAxisProps, MouseMoveMode } from './chart-types';
import { CrosshairDataset } from '../crosshair-plugin/types';
import { BehaviorSubject } from 'rxjs';
import { LineAnnotationOptions } from 'chartjs-plugin-annotation';
import { IAxisUnit } from '@dunefront/common/unit-converters/converter.interfaces';
import { UnitConverterHelper } from '@dunefront/common/unit-converters/unit.converter.helper';
import { updateChart } from './chart-common-helpers';
import { DataType } from '@dunefront/common/dto/data-storage';
import { LineStyle } from '@dunefront/common/modules/reporting/dto/chart.types';
import { ChartDataSeriesLineStyleFormat } from './chart-data-series-style-helpers';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';

export const OrderedValueAxes: ChartAxis[] = [
  ChartAxis.PrimaryValue,
  ChartAxis.OppositePrimaryValue,
  ChartAxis.SecondaryValue,
  ChartAxis.OppositePrimaryValue,
];

export const chartSeriesChanged = (a: ChartSeriesDto[], b: ChartSeriesDto[]): boolean => {
  if (a.length !== b.length) {
    return true;
  }
  for (let i = 0; i < a.length; i++) {
    if (a[i].ColumnName !== b[i].ColumnName || a[i].AxisType !== b[i].AxisType) {
      return true;
    }
  }
  return false;
};

export const getLineStyle = (format: ChartDataSeriesLineStyleFormat[] | null, lineThickness: number): number[] => {
  if (format == null) {
    return [];
  }
  const dot = [lineThickness, 1.5 * lineThickness];
  const dash = [5 * lineThickness, 3 * lineThickness];

  return format.map((f) => (f === 'dot' ? dot : dash)).flat();
};

export const borderDashForLineStyle = (lineStyle: LineStyle, lineThickness: number): number[] | undefined => {
  switch (lineStyle) {
    case LineStyle.solid:
      return undefined;
    case LineStyle.dots:
      return getLineStyle(['dot'], lineThickness);
    case LineStyle.dashes:
      return getLineStyle(['dash'], lineThickness);
  }
  throw new Error('Unknown lineStyle');
};

export const getFontSpec = (size: number, isBold: boolean, isItalic: boolean): DeepPartial<FontSpec> => ({
  size,
  weight: isBold ? 'bold' : 'normal',
  style: isItalic ? 'italic' : 'normal',
});

export const getAxisTitleFont = (axisStyle: IAxisStyle): DeepPartial<FontSpec> =>
  getFontSpec(axisStyle.AxisTitleFontSize, axisStyle.AxisTitleFontBold, axisStyle.AxisTitleFontItalic);

export const getAxisTicksFont = (axisStyle: IAxisStyle): DeepPartial<FontSpec> =>
  getFontSpec(axisStyle.AxisLabelFontSize, axisStyle.AxisLabelFontBold, axisStyle.AxisLabelFontItalic);

export const axisTypeFromColumn = (column: IChartDataDtoColumn): ChartAxis =>
  Object.values(ChartAxis).includes(column.AxisType) ? column.AxisType : ChartAxis.PrimaryValue;

export const getChartAxisFromAxisId = (axisId: string): ChartAxis => {
  const axisInt = axisId.split('_')[1];

  return parseInt(axisInt);
};

export const getAxisProps = (axis: ChartAxis, axesProperties: IAxisProps[]): IAxisProps | undefined => {
  return axesProperties.find((c) => c.axis === axis);
};

export const isAxisHorizontal = (axis: ChartAxis, isRotated: boolean): boolean => {
  const isArgument = axis === ChartAxis.Argument;
  return (isArgument && !isRotated) || (!isArgument && isRotated);
};

export const getAxisId = (chartAxis: ChartAxis, isRotated: boolean): string => {
  const prefix = isAxisHorizontal(chartAxis, isRotated) ? 'x' : 'y';
  return `${prefix}_${chartAxis}`;
};

export const getAxisStyle = (axis: ChartAxis, axesProperties: IAxisProps[], defaultAxisStyle: IAxisStyle): IAxisStyle => {
  const axisProps = getAxisProps(axis, axesProperties);
  return axisProps?.style ?? defaultAxisStyle;
};

export const getPositionFromPPAxis = (chartAxis: ChartAxis, isRotated: boolean): 'left' | 'top' | 'right' | 'bottom' => {
  if (isRotated) {
    if (chartAxis > 1) {
      return 'top';
    }
    return 'bottom';
  } else {
    if (chartAxis > 1) {
      return 'right';
    }
    return 'left';
  }
};

export const getAxisTitle = (axis: ChartAxis, axesProperties: IAxisProps[]): string | null => {
  const axisProps = getAxisProps(axis, axesProperties);
  return axisProps?.title || null;
};

export const getGetChartAxisIdAtEvent = (event: MouseEvent, chart: Chart): string | undefined => {
  const x = event.offsetX;
  const y = event.offsetY;

  for (const axisId of Object.keys(chart.scales)) {
    const scale = chart.scales[axisId];
    if (x >= scale.left && x <= scale.right && y >= scale.top && y <= scale.bottom) {
      return axisId;
    }
  }

  return undefined;
};

export const getFirstAvailableValueScale = (chart: Chart, isRotated: boolean): Scale<CoreScaleOptions> | undefined => {
  const chartAxis = getFirstAvailableValueAxis(chart, isRotated);
  if (chartAxis == null) {
    return undefined;
  }

  return chart.scales[getAxisId(chartAxis, isRotated)];
};

export const getFirstAvailableValueAxis = (chart: Chart, isRotated: boolean): ChartAxis | undefined => {
  for (const axis of OrderedValueAxes) {
    if (chart.scales[getAxisId(axis, isRotated)] != null) {
      return axis;
    }
  }

  return undefined;
};

export const getAxisUnit = (axis: ChartAxis, axisDefaults?: IAxisData[]): IAxisUnit | undefined =>
  axisDefaults?.find((c) => c.axis === axis)?.axisUnits[0];

export const getAxisPrimaryUnitSystemAndDataType = (axis: ChartAxis, chartData: IChartDataDto): IAxisUnit | undefined => {
  const firstColumn = chartData.ChartDataColumns.find((column) => axisTypeFromColumn(column) === axis);
  if (firstColumn == null) {
    return undefined;
  }

  return { dataType: firstColumn.DataType, unitSystem: firstColumn.UnitSystem };
};

export const getAxisDataTypes = (axis: ChartAxis, chartData: IChartDataDto): DataType[] =>
  chartData.ChartDataColumns.filter((col) => axisTypeFromColumn(col) === axis).map((col) => col.DataType);

export const setTooltipEnabled = (chart: Chart, enabled: boolean): void => {
  if (chart.options?.plugins?.tooltip == null) {
    return;
  }

  const tooltipOptions = chart.options?.plugins?.tooltip;
  if (tooltipOptions.enabled === enabled) {
    return;
  }

  chart.options.plugins.tooltip = {
    ...tooltipOptions,
    enabled,
  };

  (chart as any).tooltip.setActiveElements([], { x: 0, y: 0 });

  updateChart(chart);
};

export const setMouseMoveMode = (newMode: MouseMoveMode, subject$: BehaviorSubject<MouseMoveMode>): void => {
  if (newMode !== subject$.value) {
    subject$.next(newMode);
  }
};

export const getAxisUnitForColumn = (column: IChartDataDtoColumn): IAxisUnit => ({
  dataType: column.DataType,
  unitSystem: column.UnitSystem,
});

export const getIsTimeAxis = (axisUnit: IAxisUnit): boolean => {
  return axisUnit.unitSystem === UnitSystem.Time && axisUnit.dataType === DataType.Time;
};

export const setActiveMarker = (
  newActiveMarker: DeepPartial<LineAnnotationOptions> | undefined,
  subject$: BehaviorSubject<DeepPartial<LineAnnotationOptions> | undefined>,
): void => {
  if (newActiveMarker !== subject$.value) {
    subject$.next(newActiveMarker);
  }
};

export const getArgumentAxisPrimaryArgSiValueFromPoint = (point: Point, chart: Chart, chartContext: ChartContext): number | undefined => {
  const argumentAxisValue = getAxisValueFromPoint(point, ChartAxis.Argument, chart, chartContext.isRotated);
  if (argumentAxisValue == null) {
    return undefined;
  }

  return UnitConverterHelper.convertToSi(chartContext.argumentAxisUnit.unitSystem, chartContext.currentUnitSystem, argumentAxisValue);
};

export const getFirstValueAxisSiValueFromPoint = (point: Point, chart: Chart, chartContext: ChartContext): number | undefined => {
  const valueScale = getFirstAvailableValueScale(chart, chartContext.isRotated);
  const valueAxis = getChartAxisFromAxisId(valueScale?.id ?? '');

  return getValueAxisSiValueFromPoint(valueAxis, point, chart, chartContext);
};

export const getValueAxisSiValueFromPoint = (
  valueAxis: ChartAxis,
  point: Point,
  chart: Chart,
  chartContext: ChartContext,
): number | undefined => {
  const valueAxisValue = getAxisValueFromPoint(point, valueAxis, chart, chartContext.isRotated);
  const valueAxisUnit = getAxisUnit(valueAxis, chartContext.axesDefaults);
  if (valueAxisValue == null || valueAxisUnit == null) {
    return undefined;
  }

  return UnitConverterHelper.convertToSi(valueAxisUnit.unitSystem, chartContext.currentUnitSystem, valueAxisValue);
};

export const getAxisValueFromPoint = (point: Point, axis: ChartAxis, chart: Chart, isRotated: boolean): number | undefined => {
  const axisId = getAxisId(axis, isRotated);
  const scale = chart.scales[axisId];
  const pixel = isAxisHorizontal(axis, isRotated) ? point.x : point.y;

  return scale.getValueForPixel(pixel);
};

export const areRangesEqual = (range1: IArgumentRange | undefined | null, range2: IArgumentRange | undefined | null): boolean => {
  return range1?.argumentStart === range2?.argumentStart && range1?.argumentEnd === range2?.argumentEnd;
};

export const rangeContainsRange = (range: IArgumentRange, otherRange: IArgumentRange): boolean => {
  return range.argumentStart <= otherRange.argumentStart && range.argumentEnd >= otherRange.argumentEnd;
};

export const hasManualAxisLimitChanged = (prevProps: IAxisProps | undefined, newProps: IAxisProps | undefined): boolean => {
  return (
    // there was no limit but was set
    (prevProps?.manualLimit !== true && newProps?.manualLimit === true) ||
    // there was a limit and was removed
    (prevProps?.manualLimit === true && newProps?.manualLimit !== true) ||
    // limit was changed
    (prevProps?.manualLimit === true &&
      newProps?.manualLimit === true &&
      (prevProps.min !== newProps.min || prevProps.max !== newProps.max))
  );
};

export const isScatterSeries = (dataset: LineChartDataSet & CrosshairDataset): boolean => {
  return dataset.borderWidth === 0;
};

export const getColumnKey = (column: IChartDataDtoColumn): string => {
  return `${column.Name}-${column.FileId}-${column.ColumnId}-${column.ScenarioId}`;
};

export const updateColumnsVisibility = (chartData: IChartDataDto, chart: Chart, hiddenColumnKeys: string[]): void => {
  for (let i = 0; i < chartData.ChartDataColumns.length; i++) {
    const column = chartData.ChartDataColumns[i];
    const columnKey = getColumnKey(column);
    const isVisible = !hiddenColumnKeys.includes(columnKey);

    chart.setDatasetVisibility(i, isVisible);
  }
};
