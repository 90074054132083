import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { createSelector, Store } from '@ngrx/store';
import * as pumpingActions from '../../../../+store/pumping/pumping.actions';
import { DbDependentComponent } from '../../../../common-modules/db-connection/db-dependent.component';
import { WellModuleState } from '@dunefront/common/modules/well/well-module.state';
import { notEmpty } from '@dunefront/common/common/state.helpers';
import { PumpingDto } from '@dunefront/common/modules/pumping/dto/pumping.dto';
import { getValidatedPumping } from '../../../../+store/pumping/selectors/pumping-validation.selectors';
import * as uiActions from '../../../../+store/ui/ui.actions';
import { isPumpingEnabled } from '../../../../+store/pumping/selectors/pumping.selectors';
import { Pumping } from '@dunefront/common/modules/pumping/model/pumping/pumping';
import { changeProp, ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';
import { getValidatedWellModuleState } from '../../../../+store/well/validated-well.selectors';
import { combineLatestWith, take } from 'rxjs/operators';
import { ModalService } from '../../../../common-modules/modals/modal.service';
import { CopyDataFromComponent } from '../../../../common-modules/modals/copy-data-from/copy-data-from.component';
import { EntityToCopy, ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import { getCurrentRangeId } from '../../../../+store/range/range.selectors';
import { copyPumpingBetweenRangesAction } from '../../../../+store/scenario/scenario.actions';

@Component({
  selector: 'app-initial-data',
  templateUrl: './initial-data.component.html',
  styleUrls: ['./initial-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InitialDataComponent extends DbDependentComponent {
  public isPumpingEnabled$ = this.store.select(isPumpingEnabled);
  public data: IInitialData | undefined;
  public ModuleType = ModuleType;

  constructor(
    store: Store,
    cdRef: ChangeDetectorRef,
    private modalService: ModalService,
  ) {
    super(store, cdRef);
    this.subscription.add(
      notEmpty(this.store.select(getInitialData)).subscribe((initialData) => {
        this.data = initialData;
        this.cdRef.markForCheck();
      }),
    );
    store.dispatch(uiActions.setPumpingTabAction({ tab: 'initial-data' }));
    super.onHelpChange('pumping', 'tool-position');
  }

  public onInitialDataPropertyChanged(args: ObjectChangeProp<Pumping>): void {
    if (this.data != null && this.data.pumping[args.key] !== args.value) {
      const pumping = changeProp(this.data.pumping, args);
      this.store.dispatch(
        pumpingActions.updatePumpingAction({
          isSimulate: false,
          pumping,
          changedKeys: [args.key] as (keyof PumpingDto)[],
        }),
      );
    }
  }

  public async copyFromSimulateEvaluate(): Promise<void> {
    const dialog = this.modalService.open(CopyDataFromComponent, undefined, 'copy-data-from-modal');

    dialog.onClose
      .pipe(combineLatestWith(this.store.select(getCurrentRangeId)), take(1))
      .subscribe((result: [copyData: { moduleType: ModuleType; rangeId?: number }, rangeId: number]) => {
        if (result[0] == null || !this.dbInfo) {
          return;
        }
        this.store.dispatch(
          copyPumpingBetweenRangesAction({
            sourceRangeId: result[0].rangeId ?? -1,
            targetRangeId: result[1],
            entitiesToCopy: [EntityToCopy.InitialData],
            fileHash: this.dbInfo.fileHash,
          }),
        );
      });
  }
}

export const getInitialData = createSelector(
  getValidatedPumping,
  getValidatedWellModuleState,
  (pumping, well): IInitialData | undefined => {
    return pumping != null && well != null ? { pumping, well } : undefined;
  },
);

export interface IInitialData {
  pumping: Pumping;
  well: WellModuleState;
}
