import {
  createTableRow,
  createTableState,
  getRowsForCalculations,
  ITableRow,
  ITableState,
  IValidatedDataType,
} from './common-grid.interfaces';
import { Dictionary, groupBy, uniq, ValueIteratee } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { mergeArrays } from '../dto/dto.common';
import { IIndexedDataType } from '../dto/common-dto.interfaces';
import { IError } from './common-state.interfaces';

export interface Dict<T> {
  [id: string]: T | undefined;
}

export interface IDictionaryWithArray<T> {
  ids: string[];
  dict: Dict<T>;
}

export type EnumDictionary<T extends string | symbol | number, U> = {
  [K in T]: U;
};

export type PartialEnumDictionary<T extends string | symbol | number, U> = {
  [K in T]?: U;
};

/**
 * Creates an object composed of the picked object properties.
 * @param obj The source object
 * @param paths The property paths to pick
 */
export function pick<T, K extends keyof T>(obj: T, paths: K[]): Pick<T, K> {
  return { ...paths.reduce((mem, key) => ({ ...mem, [key]: obj[key] }), {}) } as Pick<T, K>;
}

export class DictionaryWithArray {
  public static create<T>(data: T[], key: keyof T): IDictionaryWithArray<T> {
    const dict = data.reduce((map, obj) => {
      const id = obj[key] + '';
      map[id] = obj;
      return map;
    }, {} as Dict<T>);
    const ids = data.map((item) => item[key] + '');
    return { ids, dict };
  }

  public static createByFn<T>(data: T[], keyFn: (obj: T) => string): IDictionaryWithArray<T> {
    const dict = data.reduce((map, obj) => {
      const id = keyFn(obj);
      map[id] = obj;
      return map;
    }, {} as Dict<T>);
    const ids = data.map((item) => keyFn(item));
    return { ids, dict };
  }

  public static createTableStateFromArrayByKey<T extends IIndexedDataType>(arr: T[], key: keyof T): IDictionaryWithArray<ITableState<T>> {
    const arrGroupedById = groupBy(arr, (item) => item[key]);
    const ids = uniq(arr.map((item) => item[key]));
    // make it reusable in DictionaryWithArray...
    const dict: Dict<ITableState<T>> = {};
    ids.map((id) => {
      const rows = arrGroupedById[id + ''] ?? [];
      const tableRows = rows.map((row, rowIndex) => createTableRow(row, 'data', rowIndex));
      dict[id + ''] = createTableState(tableRows);
    });
    return { ids: ids.map((id) => id + ''), dict };
  }

  public static createFromArray<T>(data: T[]): IDictionaryWithArray<T> {
    const dict = data.reduce((map, obj, index) => {
      map[index] = obj;
      return map;
    }, {} as Dict<T>);
    const ids = Object.keys(dict);
    return { ids, dict };
  }

  public static getArray<T>(src: IDictionaryWithArray<T>): T[] {
    return src.ids.map((id) => src.dict[id]).filter((value) => value !== undefined) as T[];
  }

  public static get<T>(src: IDictionaryWithArray<T>, key: string | number | null | undefined): T | undefined {
    if (!src.ids.length || key == null) {
      return undefined;
    }

    return src.dict[key];
  }

  public static toDictionary<T>(src: IDictionaryWithArray<T>): Dictionary<T> {
    const dict: Dictionary<T> = {};
    src.ids.forEach((id) => (dict[id] = src.dict[id] as T));
    return dict;
  }

  public static getCopy<T>(src: IDictionaryWithArray<T>, key: string | number): T | undefined {
    const value = this.get(src, key);
    return value != null ? { ...value } : undefined;
  }

  public static upsert<T>(src: IDictionaryWithArray<T>, item: T, key: keyof T): IDictionaryWithArray<T> {
    const id = item[key] + '';
    return this.upsertById(src, item, id);
  }

  public static clear<T>(): IDictionaryWithArray<T> {
    return { ids: [], dict: {} };
  }

  public static deleteItem<T>(src: IDictionaryWithArray<T>, idToRemove: number | string): IDictionaryWithArray<T> {
    const ids = src.ids.filter((id) => id !== idToRemove + '');
    const { [idToRemove]: omit, ...dict } = src.dict;
    return { ...src, ids, dict };
  }

  public static deleteItems<T>(src: IDictionaryWithArray<T>, idsToRemove: (number | string)[]): IDictionaryWithArray<T> {
    let newDict: IDictionaryWithArray<T> = { ...src };
    idsToRemove.forEach((id) => (newDict = this.deleteItem(newDict, id)));
    return newDict;
  }

  public static upsertById<T>(src: IDictionaryWithArray<T>, item: T, id: number | string): IDictionaryWithArray<T> {
    const ids = src.ids.includes(id + '') ? src.ids : [...src.ids, id + ''];
    return { ...src, ids, dict: { ...src.dict, [id]: item } };
  }

  public static isValid<T extends IValidatedDataType>(src: IDictionaryWithArray<T>): boolean {
    return Object.values(src.dict).every((val) => val && val.isValid);
  }

  public static getMaxId<T>(src: IDictionaryWithArray<T>): number {
    const arr = src.ids.map((id) => +id).filter((id) => !!id);
    return arr.length ? Math.max(...arr) : -1;
  }

  public static groupAndUpsert<T extends IIndexedDataType>(
    src: IDictionaryWithArray<ITableState<T>>,
    items: T[],
    groupByFn: ValueIteratee<T>,
    factoryFn: (arr: T[]) => ITableState<T>,
    replace = false,
  ): IDictionaryWithArray<ITableState<T>> {
    let state: IDictionaryWithArray<ITableState<T>> = { ...src };
    const groupedItems = groupBy(items, groupByFn);

    Object.keys(groupedItems).forEach((key) => {
      const itemsByKey = groupedItems[key];
      const existingRows: ITableRow<T>[] = replace ? [] : DictionaryWithArray.get(state, +key)?.rows ?? [];

      const existingItemsByKey = getRowsForCalculations(existingRows);
      const mergedItems = mergeArrays(existingItemsByKey, itemsByKey);
      const rheometerReadings = factoryFn(mergedItems);
      state = DictionaryWithArray.upsertById(state, rheometerReadings, key);
    });
    return state;
  }
}

export const getMapFromArray = <T extends IIndexedDataType>(array: T[], index: keyof T): Map<number, T> => {
  const map = new Map<number, T>();
  array.forEach((item) => {
    if (item.Id) {
      map.set(item.Id, item);
    }
  });
  return map;
};

export const noErrors = <T>(error: IError<T>, filterOut: (keyof T)[] = []): boolean => {
  if (filterOut.length > 0) {
    const keys = Object.keys(error).filter((err) => !filterOut.includes(err as keyof T));
    const errorsMessages = keys.map((key) => error[key as keyof T]);
    return !errorsMessages.some((err) => err);
  }

  return !Object.values(error).some((err) => err);
};

export const getObject = <T>(src: IDictionaryWithArray<T>, id: number): T | undefined => src.dict[id];

export function updateObjectInArray<T>(array: T[], replaceAt: number, value: T): T[] {
  return array.map((item, index) => {
    if (index !== replaceAt) {
      // This isn't the item we care about - keep it as-is
      return item;
    }

    // Otherwise, this is the one we want - return an updated value
    return {
      ...item,
      ...value,
    };
  });
}

export function updateObjectsInArray<Model extends ITableRow<IIndexedDataType>, Dto extends IIndexedDataType>(
  array: Model[],
  arrayToReplace: Dto[],
): Model[] {
  const dict = getMapFromArray(arrayToReplace, 'Id');
  const keys = Array.from(dict.keys());
  return array.map((item) => {
    if (!item.rowData.Id) {
      return item;
    }
    if (!keys.includes(item.rowData.Id)) {
      // This isn't the item we care about - keep it as-is
      return item;
    }

    const value = dict.get(item.rowData.Id);
    // Otherwise, this is the one we want - return an updated value
    return {
      ...item,
      rowData: { ...value },
    };
  });
}

export function deleteObjectsFromArray<Model extends ITableRow<IIndexedDataType>>(rows: Model[], rowIds: (number | string)[]): Model[] {
  const rowsThatLeft = rows.filter((row) => (row.rowData.Id && !rowIds.includes(row.rowData.Id)) || row.rowType === 'insert-row');
  return rowsThatLeft.map((row, index) => ({ ...row, rowIndex: index }));
}

export function isDefined<T>(arg: T | null | undefined): arg is T {
  return arg != null;
}

export function filterNil() {
  return function <T>(source: Observable<T | null | undefined>): Observable<T> {
    return source.pipe(filter(isDefined));
  };
}

export function observableToBehaviorSubject<T>(observable: Observable<T>, initValue: T): BehaviorSubject<T> {
  const subject = new BehaviorSubject(initValue);

  observable.subscribe({
    complete: () => subject.complete(),
    error: (x) => subject.error(x),
    next: (x) => subject.next(x),
  });

  return subject;
}

export const notEmpty = <T>(state: Observable<T | undefined | null>): Observable<T> => state.pipe(filter(isDefined));
