<div class="title" *ngIf="showHeader" [ngClass]="{ 'with-help': showHelpBtn }">
  <h1>{{ header }}</h1>

  <div class="tooltip">
    <ng-content select="[tooltip]"></ng-content>
  </div>

  <div class="buttons">
    <app-help-button *ngIf="showHelpBtn" [helpMode]="panelHelpMode"
                     [class.margin-right]="allowFullScreen"></app-help-button>
    <div class="fullscreen-toggle" *ngIf="allowFullScreen" (click)="toggleMode()">
      <span class="icon-light icon-enlarge" *ngIf="!isFullScreen"></span>
      <span class="icon-light icon-shrink" *ngIf="isFullScreen"></span>
    </div>
  </div>
</div>
<div class="content" [class.with-header]="showHeader">
  <ng-content></ng-content>
</div>
