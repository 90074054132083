import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { DbDependentComponent } from '../../../../common-modules/db-connection/db-dependent.component';
import { Store } from '@ngrx/store';
import { UnitConverterHelper } from '@dunefront/common/unit-converters/unit.converter.helper';
import { DataType } from '@dunefront/common/dto/data-storage';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';

@Component({
  templateUrl: './report-test.component.html',
  styleUrls: ['./report-test.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportTestComponent extends DbDependentComponent {
  public ChartUnitDescriptions: TestChartUnitDescription[] = [];
  public ChartUnitConverter = UnitConverterHelper;

  constructor(store: Store, cdRef: ChangeDetectorRef) {
    super(store, cdRef);

    for (const n in DataType) {
      if (typeof DataType[n] === 'number') {
        const value = +DataType[n];

        const chartUnitDescription: TestChartUnitDescription = {
          unitName: DataType[value],
          unitSystem: UnitConverterHelper.getUnitSystemFromDataType(value),
          dataType: value,
        };
        this.ChartUnitDescriptions.push(chartUnitDescription);
      }
    }
  }
}

export interface TestChartUnitDescription {
  dataType: DataType;
  unitSystem: UnitSystem;
  unitName: string;
}
