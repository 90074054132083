import { WellDto } from '../../dto/well.dto';
import { TemperatureProfile, TemperatureProfileProps } from './temperature-profile';
import { SurveyCalculations, SurveyDto } from '../../dto/survey/survey.dto';
import { WellModuleState } from '../../well-module.state';

export class TemperatureProfileFactory {
  public static create(dto: WellDto, survey: SurveyDto[]): TemperatureProfile {
    const bottomHoleTVD = SurveyCalculations.BottomholeTVD(survey);
    return {
      ...this.updateProps(dto, bottomHoleTVD),
      isValid: true,
      error: {},
    };
  }

  private static updateProps(dto: WellDto, bottomHoleTVD?: number): TemperatureProfileProps {
    return {
      SeaLevelDepth: dto.SeaLevelDepth,
      SeaBedDepth: dto.SeaBedDepth,
      BottomholeCirculating: dto.BottomholeCirculatingTemperature,
      BottomholeStaticTemperature: dto.BottomholeStaticTemperature,
      SurfaceTemp: dto.SurfaceTemp,
      MinWaterTemp: dto.MinWaterTemp,
      MinWaterTempDepth: dto.MinWaterTempDepth,
      TemperatureProfileType: dto.TemperatureProfileType,
      BottomHoleTVD: bottomHoleTVD ?? 0,
    };
  }

  public static update(well: WellModuleState, dto: WellDto): TemperatureProfile {
    return {
      ...well.TemperatureProfile,
      ...this.updateProps(dto, well.TemperatureProfile.BottomHoleTVD),
    };
  }
}
