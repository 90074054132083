import { PipeType } from '../../../../dto/pipe.dto';
import { RunningStringPipeDto } from '../../../../dto/running-string-pipe.dto';
import { BasePipeFactory } from '../../base.pipe.factory';
import { Pipe } from '../../pipe';
import { ITableRow } from '../../../../common/common-grid.interfaces';

export interface PressureAttenuatorPipe extends Pipe {
  PipeType: PipeType.Pressure_Attenuator;
  ActivationPressure: number;
}

export class PressureAttenuatorPipeFactory {
  public static createFromRunningString(runningStringDto: RunningStringPipeDto): PressureAttenuatorPipe {
    const pipe = BasePipeFactory.createFromDto(runningStringDto);
    const pressureAttenuatorPipe: PressureAttenuatorPipe = {
      ...pipe,
      PipeType: PipeType.Pressure_Attenuator,
      ActivationPressure: runningStringDto.ActivationPressure,
    };
    return pressureAttenuatorPipe;
  }
}

export function isPressureAttenuatorPipeRow(row: ITableRow<Pipe>): row is ITableRow<PressureAttenuatorPipe> {
  return isPressureAttenuatorPipe(row.rowData);
}

export function isPressureAttenuatorPipe(pipe: Pipe): pipe is PressureAttenuatorPipe {
  return pipe.PipeType === PipeType.Pressure_Attenuator;
}
