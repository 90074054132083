import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { IFile } from '@dunefront/common/dto/file.dto';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { MenuItem } from 'primeng/api';
import { ContextMenu } from 'primeng/contextmenu';
import { IEnvironment } from '@dunefront/common/common/environment.interface';
import { isElectron } from '@dunefront/common/common/electron/is-electron';
import { ModalService } from '../../../../common-modules/modals/modal.service';
import { ENVIRONMENT } from '../../../../shared/services/environment';
import { CopyDialogComponent } from '../../action-dialogs/copy-dialog/copy.dialog.component';
import { DeleteDialogComponent } from '../../action-dialogs/delete-dialog/delete.dialog.component';
import { MoveDialogComponent } from '../../action-dialogs/move-dialog/move.dialog.component';
import { FileUrlHelper } from '../../file-url.helper';
import { RenameDialogComponent } from '../../action-dialogs/rename-dialog/rename.dialog.component';
import { ShareDialogComponent } from '../../action-dialogs/share/share.dialog.component';
import { FileManagerHelper } from '../../../../+store/file-manager/file-manager.helper';
import { RestoreDialogComponent } from '../../action-dialogs/restore-dialog/restore.dialog.component';
import { firstValueFrom } from 'rxjs';
import { getSelectedUser } from '../../../../+store/file-manager/file-manager.selectors';
import { restoreOrgDBAction } from '../../../../+store/file-manager/file-manager.actions';
import { ContextMenuButtonBuilder } from './file-context-menu-button-builder';
import { ClientAuthService } from '../../../../common-modules/auth/client-auth.service';

export type contextMenuEvent =
  | 'open'
  | 'share'
  | 'rename'
  | 'copy'
  | 'move'
  | 'delete'
  | 'download'
  | 'restore'
  | 'go-to-location'
  | 'restore-backup';

@Component({
  selector: 'app-file-context-menu',
  templateUrl: './file-context-menu.component.html',
  styleUrls: ['./file-context-menu.component.scss'],
})
export class FileContextMenuComponent implements OnChanges {
  @Input() public contextMenuRequired?: ContextMenuRequired | null;
  @Input() public isRecentMode = false;
  @Output() public contextMenuRequiredChange = new EventEmitter();
  @Output() public openFile: EventEmitter<IFile> = new EventEmitter();
  @ViewChild(ContextMenu) public contextMenu!: ContextMenu;
  private readonly isAdmin: boolean;

  private get isDemoRepo(): boolean {
    return this.file?.Repository === 'demo';
  }

  private get isPersonalRepo(): boolean {
    return this.file?.Repository === 'personal';
  }

  private get isFile(): boolean {
    return this.file?.FileType === 'ppf-file' || this.file?.FileType === 'backup-file';
  }

  private get ifFileNameEmpty(): boolean {
    return this.file?.Name.trim() === '';
  }

  public contextMenuItems: MenuItem[] = [];
  public contextMenuPosTop!: number;
  public contextMenuPosLeft!: number;
  public headers: any;

  public get file(): IFile | undefined {
    if (
      this.contextMenuRequired &&
      this.contextMenuRequired.file.Repository !== 'trash' &&
      this.contextMenuRequired.file.Repository !== 'backup'
    ) {
      return this.contextMenuRequired.file;
    }
    return;
  }

  constructor(
    private route: ActivatedRoute,
    private store: Store,
    private cdRef: ChangeDetectorRef,
    private authService: ClientAuthService,
    private router: Router,
    private http: HttpClient,
    private modalService: ModalService,
    @Inject(ENVIRONMENT) private env: IEnvironment,
  ) {
    this.isAdmin = FileUrlHelper.isAdmin(router.url);
  }

  public ngOnChanges(): void {
    if (this.contextMenu == null) {
      return;
    }

    this.contextMenu.hide();
    if (!this.contextMenuRequired) {
      return;
    }

    this.setUpContextMenu();

    this.contextMenuPosLeft = this.contextMenuRequired.x;

    const menuItemHeight = 41;
    const dialogHeight = this.contextMenuItems.filter((item) => item.visible || !('visible' in item)).length * menuItemHeight;
    const dialogFitsWindow = innerHeight >= this.contextMenuRequired.y + dialogHeight;

    this.contextMenuPosTop = dialogFitsWindow ? this.contextMenuRequired.y : this.contextMenuRequired.y - dialogHeight;
    this.contextMenu.show(this.contextMenuRequired.event);
  }

  private setUpContextMenu(): void {
    const contextMenu: MenuItem[] = [];

    const Button = new ContextMenuButtonBuilder(this.onContextMenuItemClick.bind(this));

    if (this.file) {
      if (!this.isAdmin) {
        contextMenu.push(Button.open());
      }
      if (!this.isDemoRepo && !this.isRecentMode) {
        contextMenu.push(
          ...[
            Button.share({ visible: this.isFile && this.isPersonalRepo && !this.isAdmin }),
            Button.rename({ visible: !this.ifFileNameEmpty }),
            Button.copy({ visible: this.isFile && !this.isAdmin }),
            Button.move({ visible: !this.ifFileNameEmpty && !this.isAdmin }),
            Button.delete({ label: this.isPersonalRepo && this.isFile && !this.isAdmin ? 'Move to Trash' : 'Delete' }),
            Button.download({ visible: this.isFile }),
          ],
        );
      }

      if (this.isRecentMode && !isElectron()) {
        contextMenu.push(...[Button.download({ visible: this.isFile }), Button.goToLocation({ visible: this.isFile })]);
      }
    }

    if (this.contextMenuRequired && this.contextMenuRequired.file.Repository === 'backup') {
      contextMenu.push(...[Button.restoreBackup(), Button.download(), Button.delete()]);
    }
    if (this.contextMenuRequired && this.contextMenuRequired.file.Repository === 'trash') {
      contextMenu.push(Button.restoreFromTrash());
    }
    this.contextMenuItems = contextMenu;
  }

  private async onContextMenuItemClick(event: contextMenuEvent): Promise<void> {
    if (!this.contextMenuRequired) {
      return;
    }
    switch (event) {
      case 'copy':
        {
          this.modalService.open(CopyDialogComponent, { file: this.contextMenuRequired.file }, '', 'sm');
        }
        break;
      case 'delete':
        {
          this.modalService.open(
            DeleteDialogComponent,
            {
              file: this.contextMenuRequired.file,
              isAdmin: this.isAdmin,
            },
            '',
            'sm',
          );
        }
        break;
      case 'move':
        {
          this.modalService.open(MoveDialogComponent, { file: this.contextMenuRequired.file }, '', 'sm');
        }
        break;
      case 'open':
        {
          this.openFile.emit(this.contextMenuRequired.file);
        }
        break;
      case 'rename':
        {
          this.modalService.open(RenameDialogComponent, { file: this.contextMenuRequired.file }, '', 'sm');
        }
        break;
      case 'share':
        {
          this.modalService.open(ShareDialogComponent, { file: this.contextMenuRequired.file }, '', 'sm');
        }
        break;
      case 'download':
        {
          const file = this.contextMenuRequired.file;
          const token = this.authService.accessToken;
          const sessionId = this.authService.sessionId;
          const selectedUser = await firstValueFrom(this.store.select(getSelectedUser));
          FileManagerHelper.downloadFile(file, token as string, sessionId, this.env, selectedUser);
        }
        break;
      case 'restore':
        {
          this.modalService.open(RestoreDialogComponent, { file: this.contextMenuRequired.file }, '', 'sm');
        }
        break;
      case 'go-to-location':
        if (this.file) {
          await FileManagerHelper.navigateToFolder(this.router, this.file);
        }
        break;

      case 'restore-backup':
        if (
          await this.modalService.showConfirmNoUndoable(
            "Are you sure you want to restore organization's database?",
            'Restore database backup',
          )
        ) {
          this.store.dispatch(restoreOrgDBAction({ backup: this.contextMenuRequired.file }));
        }
    }

    this.contextMenuRequiredChange.emit(undefined);
  }
}

export interface ContextMenuRequired {
  file: IFile;
  x: number;
  y: number;
  event: MouseEvent;
}
