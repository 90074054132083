import { ChangeDetectionStrategy, Component } from '@angular/core';
import { defaultModalCancelButton, ModalButtonConfig } from '../../generic-modal/generic-modal.component';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-notification-content',
  template: ` <app-generic-modal [modalButtonsConfigs]="modalButtonsConfigs">
    <iframe
      *ngIf="sanitizedUrl"
      [src]="sanitizedUrl"
      style="position: absolute;
      width: 100%;
      height: 100%;
      border: none;"
    ></iframe>
  </app-generic-modal>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationContentModalComponent {
  public modalButtonsConfigs: ModalButtonConfig[] = [defaultModalCancelButton((): void => this.cancelClicked(), 'Close')];

  public sanitizedUrl: SafeResourceUrl;

  constructor(
    public dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public sanitizer: DomSanitizer,
  ) {
    this.sanitizedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.config.data.url);
  }

  public cancelClicked(): void {
    this.dialogRef.close();
  }
}
