// errors which contains those strings will be filtered out from sentry

import { ERROR_VACUUM_NOT_NEEDED } from './errors';

export const sentryNotIgnoredFrontendError = 'Frontend Sentry Test Error';
export const sentryNotIgnoredBackendError = 'Backend Sentry Test Error';

export const sentryIgnoredFrontendError = 'Frontend Test Error';
export const sentryIgnoredBackendError = 'Backend Test Error';
export const sentryIgnoredShortCalcEngineError = 'Unhandled Sentry Ignored exception thrown deliberately for testing purposes';
export const sentryIgnoredLongCalcEngineError = 'Sentry Ignored User Triggered Error!';

export const ignoredErrors = [
  sentryIgnoredFrontendError,
  sentryIgnoredShortCalcEngineError,
  sentryIgnoredLongCalcEngineError,
  sentryIgnoredBackendError,
  ERROR_VACUUM_NOT_NEEDED,
];
