import { CasingPipeDto, PerforationPhasingTypeEnum } from '../../../well/dto/casing/casing-pipe.dto';
import { PipeType } from '../../../../dto/pipe.dto';
import { BasePipeFactory } from '../../base.pipe.factory';
import { Pipe } from '../../pipe';
import { ITableRow } from '../../../../common/common-grid.interfaces';
import { IError } from '../../../../common/common-state.interfaces';

export interface PerforatedCasingPipe extends Pipe {
  PipeType: PipeType.Perforated_Casing;
  PerforationVolume: number;
  PerforationShotDensity: number;
  PerforationTunnelLength: number;
  PerforationDiameter: number;
  PerforationPhasingType: PerforationPhasingTypeEnum;
  OpenPerforationPercentage: number;
  IsReservoirSection: true;
  error: IError<PerforatedCasingPipe>;
}

export class PerforatedCasingPipeFactory {
  public static createFromCasing(casingDto: CasingPipeDto): PerforatedCasingPipe {
    const pipe = BasePipeFactory.createFromDto(casingDto);
    const {
      PerforationVolume,
      PerforationShotDensity,
      PerforationTunnelLength,
      PerforationDiameter,
      PerforationPhasingType,
      OpenPerforationPercentage,
    } = casingDto;

    const perforatedCasingPipe: PerforatedCasingPipe = {
      ...pipe,
      PipeType: PipeType.Perforated_Casing,
      IsReservoirSection: true,
      PerforationVolume,
      PerforationShotDensity,
      PerforationTunnelLength,
      PerforationDiameter,
      PerforationPhasingType,
      OpenPerforationPercentage,
      error: {},
    };
    return perforatedCasingPipe;
  }
}

export function isPerforatedCasingPipe(pipe: Pipe): pipe is PerforatedCasingPipe {
  return pipe.PipeType === PipeType.Perforated_Casing;
}

export function isPerforatedCasingRow(row: ITableRow<Pipe>): row is ITableRow<PerforatedCasingPipe> {
  return isPerforatedCasingPipe(row.rowData);
}
