import { BaseWsEffects } from '../base-ws.effects';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { BackendConnectionService } from '../../shared/backend-connection/backend-connection.service';
import { ModalService } from '../../common-modules/modals/modal.service';
import { Injectable } from '@angular/core';
import {
  PSDAnalysisModuleName,
  PSDCalculateAction,
  PSDDeleteRowsAction,
  PSDInsertRowsAction,
  PSDReadingsDeleteRowsAction,
  PSDReadingsInsertRowsAction,
  PSDReadingsUpdateRowAction,
  PSDUpdateRowsAction,
  UpdatePSDAnalysisAction,
} from '@dunefront/common/modules/psd-analysis/psd-analysis-module.actions';
import * as actions from './psd-analysis.actions';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { CrudResponse } from '@dunefront/common/modules/common.actions';
import { dataFailed, deleteRowsSuccess, insertRowsSuccess, updateRowSuccess } from '../app.actions';
import { of } from 'rxjs';
import { getLastPSDId, getLastPSDInputType, getValidatedPSDAnalysisModuleState } from './psd-analysis.selectors';
import { PSDAnalysisJobInputData } from '@dunefront/common/modules/psd-analysis/dto/psd.dto';
import { DictionaryWithArray } from '@dunefront/common/common/state.helpers';
import { getCurrentScenarioId } from '../scenario/scenario.selectors';
import { getRowsForCalculations, ITableState } from '@dunefront/common/common/common-grid.interfaces';
import { WsActionPropsFactory } from '@dunefront/common/common/ws-action/ws-action-props.factory';
import { PsdReading } from '@dunefront/common/modules/psd-analysis/model/psd-readings/psd-reading';
import { PSDAnalysisFactory } from '@dunefront/common/modules/psd-analysis/model/psd-analysis/psd-analysis.factory';
import { PSDFactory } from '@dunefront/common/modules/psd-analysis/model/psd/psd.factory';
import { PsdReadingsFactory } from '@dunefront/common/modules/psd-analysis/model/psd-readings/psd-readings.factory';

@Injectable()
export class PSDAnalysisEffects extends BaseWsEffects {
  constructor(actions$: Actions, store: Store, wsService: BackendConnectionService, modalService: ModalService) {
    super(actions$, wsService, PSDAnalysisModuleName, false, true, modalService, store);
  }

  public calculate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.calculatePsdAnalysis),
      concatLatestFrom(() => [this.store.select(getValidatedPSDAnalysisModuleState), this.store.select(getCurrentScenarioId)]),
      mergeMap(([action, state, currentScenarioId]) => {
        const payload: PSDAnalysisJobInputData = {
          PSDInputs: DictionaryWithArray.getArray(state.PSD)
            .filter((psd) => (action.isFullAnalysis ? true : psd.Id === state.SelectedPSDId))
            .map((psd) => {
              const psdReadingsTableState = DictionaryWithArray.get(state.PSDReadings, psd.Id) as ITableState<PsdReading>;
              const psrReadings = getRowsForCalculations(psdReadingsTableState.rows);
              return PSDFactory.psdToPsdWitReadingsDto(psd, psrReadings);
            }),
          PSDSandProductionInputs: action.isFullAnalysis ? PSDAnalysisFactory.toDto(state.PSDAnalysis) : undefined,
        };
        return this.emit<CrudResponse>(new PSDCalculateAction(currentScenarioId, payload, action.isFullAnalysis)).pipe(
          map((result) => updateRowSuccess(result.payload)),
          catchError((err) => of(dataFailed(err))),
        );
      }),
    ),
  );

  //region PSD Analysis

  public updatePSDAnalysis$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.updatePSDAnalysis),
      mergeMap((action) => this.emitUpdate(new UpdatePSDAnalysisAction(action))),
    ),
  );

  //endregion

  //region PSD

  public updatePSDRow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.updatePSD),
      mergeMap((action) => this.emitUpdate(new PSDUpdateRowsAction(action))),
    ),
  );

  public insertPSDRow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.addPSD),
      concatLatestFrom(() => [this.store.select(getLastPSDId), this.store.select(getLastPSDInputType)]),
      mergeMap(([action, lastPsdId, lastInputType]) =>
        this.emit<CrudResponse>(
          new PSDInsertRowsAction(
            WsActionPropsFactory.insertDto(
              PSDFactory.psdToDto(PSDFactory.newPsd(action.scenarioId, 'New PSD', lastInputType)),
              true,
              'insert',
              lastPsdId,
            ),
          ),
        ).pipe(
          map((result) => insertRowsSuccess(result.payload)),
          catchError((err) => of(dataFailed(err))),
        ),
      ),
    ),
  );

  public toggleIsSelectedPSD$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.updatePSDs),
      mergeMap((action) => this.emitUpdate(new PSDUpdateRowsAction(action))),
    ),
  );

  public deletePSDRow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.deletePSD),
      mergeMap((action) =>
        this.emit<CrudResponse>(new PSDDeleteRowsAction(action)).pipe(
          map((result) => deleteRowsSuccess(result.payload)),
          catchError((err) => of(dataFailed(err))),
        ),
      ),
    ),
  );

  // endregion

  //region psd readings

  public updatePsdReadingsRow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.updatePsdReadingsRow),
      mergeMap((action) => this.emitUpdate(new PSDReadingsUpdateRowAction(action))),
    ),
  );

  public insertPsdReadingsRow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.insertPsdReadingsRow),
      mergeMap((action) =>
        this.emit<CrudResponse>(
          new PSDReadingsInsertRowsAction(WsActionPropsFactory.insertAction(action, PsdReadingsFactory.psdReadingsToDto)),
        ).pipe(
          map((result) => insertRowsSuccess(result.payload)),
          catchError((err) => of(dataFailed(err))),
        ),
      ),
    ),
  );

  public deletePsdReadingsRow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.deletePsdReadingsRows),
      mergeMap((action) =>
        this.emit<CrudResponse>(new PSDReadingsDeleteRowsAction(action)).pipe(
          map((result) => deleteRowsSuccess(result.payload)),
          catchError((err) => of(dataFailed(err))),
        ),
      ),
    ),
  );

  // endregion
}
