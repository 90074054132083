import { Routes } from '@angular/router';
import { FrictionComponent } from './friction.component';
import { FrictionCalculatorComponent } from './friction-calculator/friction-calculator.component';

export const RouteModuleFriction = 'friction';

export const FrictionRoutes: Routes = [
  {
    path: RouteModuleFriction,
    component: FrictionComponent,
    data: {
      breadcrumb: 'Friction Calculator',
    },
    children: [{ path: '', component: FrictionCalculatorComponent }],
  },
];
