import { Routes } from '@angular/router';
import { GaugeDataComponent } from './gauge-data/gauge-data.component';
import { GaugeDataChartComponent } from './gauge-data-chart/gauge-data-chart.component';
import { GaugeDataPageComponent } from './gauge-data-page.component';
import { dataChartName, dataGridName, dataPageName } from './gauge-data-page-consts';
import { RouteModuleData, RouteModuleDataChart, RouteModuleDataGrid } from './gauge-data-routes-names';

export const gaugeDataPageRoutes: Routes = [
  {
    path: RouteModuleData,
    component: GaugeDataPageComponent,
    data: {
      breadcrumb: dataPageName,
    },
    children: [
      {
        path: `${RouteModuleDataGrid}/:fileId`,
        component: GaugeDataComponent,
        data: {
          breadcrumb: dataGridName,
        },
      },
      {
        path: RouteModuleDataGrid,
        redirectTo: `${RouteModuleDataGrid}/`,
        pathMatch: 'full',
      },
      {
        path: RouteModuleDataGrid,
        component: GaugeDataComponent,
        data: {
          breadcrumb: dataGridName,
        },
      },
      {
        path: RouteModuleDataChart,
        component: GaugeDataChartComponent,
        data: {
          breadcrumb: dataChartName,
        },
      },
      {
        path: '',
        redirectTo: 'grid',
        pathMatch: 'full',
      },
    ],
  },
];
