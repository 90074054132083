<app-page-panel header="Data Chart" [panelHelpMode]="PanelHelpMode.CHART" [allowFullScreen]="false">
  <div
    *ngIf="(storageFiles$ | async)?.length; else noData"
    class="gauge-data-chart"
    data-cy="gauge-data-chart"
    (click)="onDataPageHelpChange('data-chart')"
  >
    <app-chart-controller
      [config]="config"
      [chartState]="chartState$ | async"
      [chartId]="chartId"
      [allowedChangingXYShift]="AllowedXYShiftAxis.both"
      [isVerticalShiftEnabled]="(analysisMenuProps$ | async)?.yAxisShiftEnabled || false"
      [chartDisplayName]="'Data Chart'"
    >
    </app-chart-controller>
  </div>

  <ng-template #noData>
    <app-no-gauge-data-message></app-no-gauge-data-message>
  </ng-template>
</app-page-panel>
