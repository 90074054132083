import { LoadDataStorageActionResponse } from '../data-storage-module.actions';
import { DataStorageModuleState, IColNameWithId } from '../data-storage-module.state';
import { DictionaryWithArray, IDictionaryWithArray } from '../../../common/state.helpers';
import { groupBy } from 'lodash';
import { ImportColumnDto } from '../../data-storage/dto/import-column.dto';

export class DataStorageFactory {
  public static create(payload: LoadDataStorageActionResponse): DataStorageModuleState {
    const files = DictionaryWithArray.create(payload.importFileDtos, 'Id');
    const columnsGroupedByFileId = groupBy(payload.importColumnDtos, (data) => data.FileId);

    let columns: IDictionaryWithArray<ImportColumnDto[]> = DictionaryWithArray.createFromArray([]);

    for (const fileId of files.ids) {
      const fileColumns = columnsGroupedByFileId[fileId] ?? [];
      columns = DictionaryWithArray.upsertById(columns, fileColumns, fileId);
    }

    const columnNames = this.getColumnNames(payload.importColumnDtos);

    const dataStorageState: DataStorageModuleState = {
      files,
      columns,
      columnNames,
      isLoaded: true,
    };
    return dataStorageState;
  }

  public static getColumnNames(importColumns: ImportColumnDto[]): IColNameWithId[] {
    return importColumns.map((col) => ({ colId: col.Id, name: col.ColumnName }));
  }
}
