import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { Store } from '@ngrx/store';
import {
  freezeBackend,
  triggerBackendError,
  triggerCalcEngineLongWorkerError,
  triggerCalcEngineShortWorkerError,
  triggerFrontendError,
  triggerPing,
} from '../../../+store/error-testing/error-testing.actions';
import {
  sentryIgnoredBackendError,
  sentryIgnoredFrontendError,
  sentryNotIgnoredBackendError,
  sentryNotIgnoredFrontendError,
} from '@dunefront/common/exceptions/ignoredErrors';
import { getJobForCurrentContext } from '../../../+store/calculation-engine/calculation-engine.selectors';
import { Subscription } from 'rxjs';
import { ICalculationJob } from '../../../+store/calculation-engine/calculation-engine-module.state';
import { defaultModalCancelButton, ModalButtonConfig } from '../../../common-modules/modals/generic-modal/generic-modal.component';
import { CalcEngineShortWorkerErrorType } from '@dunefront/common/modules/error-testing/error-testing-module.actions';
import { ModalService } from '../../../common-modules/modals/modal.service';

@Component({
  selector: 'app-dev-console-modal',
  templateUrl: './dev-console-modal.component.html',
  styleUrls: ['./dev-console-modal.component.scss'],
})
export class DevConsoleModalComponent implements OnDestroy {
  private subscription = new Subscription();

  public currentCalculationJob: ICalculationJob | undefined;
  public modalTitle = 'Developer Panel';
  public modalButtonsConfigs: ModalButtonConfig[] = [defaultModalCancelButton((): void => this.cancelClicked())];
  constructor(
    public activeModal: DynamicDialogRef,
    private store: Store,
    private cdRef: ChangeDetectorRef,
    private modalService: ModalService,
  ) {
    this.subscription.add(
      this.store.select(getJobForCurrentContext).subscribe((currentCalculationJob) => {
        this.currentCalculationJob = currentCalculationJob;
        this.cdRef.markForCheck();
      }),
    );
  }

  public cancelClicked(): void {
    this.activeModal.close();
  }

  public triggerIgnoredFrontendError(): void {
    this.store.dispatch(triggerFrontendError({ errorMessage: sentryIgnoredFrontendError }));
    this.activeModal.close();
  }

  public triggerIgnoredBackendError(): void {
    this.store.dispatch(triggerBackendError({ errorMessage: sentryIgnoredBackendError }));
    this.activeModal.close();
  }

  public triggerCalcEngineShortWorkerError(ignoreSentry: boolean, errorType: CalcEngineShortWorkerErrorType): void {
    this.store.dispatch(triggerCalcEngineShortWorkerError({ ignoreSentry, errorType }));
    this.activeModal.close();
  }

  public triggerIgnoredRunningCalcEngineError(): void {
    if (this.currentCalculationJob == null) {
      return;
    }
    this.store.dispatch(
      triggerCalcEngineLongWorkerError({
        jobId: this.currentCalculationJob?.jobId,
        ignoreSentry: true,
      }),
    );
    this.activeModal.close();
  }

  public triggerNotIgnoredFrontendError(): void {
    this.store.dispatch(triggerFrontendError({ errorMessage: sentryNotIgnoredFrontendError }));
    this.activeModal.close();
  }

  public triggerNotIgnoredBackendError(): void {
    this.store.dispatch(triggerBackendError({ errorMessage: sentryNotIgnoredBackendError }));
    this.activeModal.close();
  }

  public triggerNotIgnoredRunningCalcEngineError(): void {
    if (this.currentCalculationJob == null) {
      return;
    }
    this.store.dispatch(
      triggerCalcEngineLongWorkerError({
        jobId: this.currentCalculationJob?.jobId,
        ignoreSentry: false,
      }),
    );
    this.activeModal.close();
  }

  public freezeBackend(): void {
    this.store.dispatch(freezeBackend());
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public async pingPong(): Promise<void> {
    const inputData = await this.modalService.showTextareaPrompt('Enter Ping message', 'Ping');
    if (inputData != null) {
      this.store.dispatch(triggerPing({ inputData }));
    }
  }
}
