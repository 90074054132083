import { createReducer, on } from '@ngrx/store';
import { settingsInitialState, SettingsModuleState } from '@dunefront/common/modules/settings/settings-module.state';
import * as actions from './settings.actions';
import { changeWellVisualizationModeAction } from './settings.actions';
import { SettingsModuleReducerHelper } from './settings.reducer.helper';
import { loadScenarioDataSuccessAction, updateRowSuccess } from '../app.actions';

export const SettingsModuleFeatureName = 'settings';

export const settingsModuleReducer = createReducer<SettingsModuleState>(
  settingsInitialState,
  on(loadScenarioDataSuccessAction, (state, action) =>
    SettingsModuleReducerHelper.onLoadScenarioDataSuccessAction(state, action.loadScenarioResponse.settingsModule),
  ),
  on(actions.changeShearRateAction, actions.changeSettingsProperty, (state, action) =>
    SettingsModuleReducerHelper.changeSettingsProperty(state, action),
  ),
  on(updateRowSuccess, (state, action) => SettingsModuleReducerHelper.onUpdateSuccess(state, action)),
  on(
    changeWellVisualizationModeAction,
    (state, action): SettingsModuleState => ({ ...state, wellVisualizationType: action.wellVisualizationType }),
  ),
);
