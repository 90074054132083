import { IReportTableColumnConfig, ReportFluid } from '../common-report-data-helpers';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { ColumnType } from '../../../../../components/grid/grid.interfaces';

export const fluidsProperties = (shearRate: number): IReportTableColumnConfig<ReportFluid>[] => [
  { colId: 'Type', headerText: 'Fluid Type', reportingHeaderName: 'Type', unitSystem: UnitSystem.None, type: ColumnType.select },
  {
    colId: 'CleanFluidDensity',
    headerText: 'Fluid Density',
    reportingHeaderName: 'Density',
    unitSystem: UnitSystem.Liquid_Density,
    type: ColumnType.number,
    decimalPlaces: 3,
  },
  { colId: 'DragReductionFactor', headerText: 'Drag Reduction', unitSystem: UnitSystem.None, type: ColumnType.number },
  { colId: 'IsGravelSettling', headerText: 'Gravel Settling', unitSystem: UnitSystem.None, type: ColumnType.boolean },
  { colId: 'IsYieldStress', headerText: 'Yield Stress', unitSystem: UnitSystem.Yield_Stress, type: ColumnType.boolean },
  {
    colId: 'ViscosityAtSurfaceTemp',
    headerText: `Viscosity at Surface Temp and ${shearRate} sec^-1 `,
    reportingHeaderName: `Surface Visc. at\n${shearRate} s^-1`,
    unitSystem: UnitSystem.Viscosity,
    type: ColumnType.number,
  },
  {
    colId: 'ViscosityAtBHCT',
    headerText: `Viscosity at BHCT and ${shearRate} sec^-1`,
    reportingHeaderName: `BHCT Visc. at\n${shearRate} s^-1`,
    unitSystem: UnitSystem.Viscosity,
    type: ColumnType.number,
  },
  {
    colId: 'WashpipeViscousDegradationFactor',
    headerText: 'Viscous Fluid Degradation Factor (Washpipe)',
    reportingHeaderName: `Deg. Factor (WP)`,
    unitSystem: UnitSystem.None,
    type: ColumnType.number,
    decimalPlaces: 4,
  },
  {
    colId: 'AnnulusViscousDegradationFactor',
    headerText: 'Viscous Fluid Degradation Factor (Annulus)',
    reportingHeaderName: `Deg. Factor (Ann.)`,
    unitSystem: UnitSystem.None,
    type: ColumnType.number,
    decimalPlaces: 4,
  },
];
