import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { Pumping } from '@dunefront/common/modules/pumping/model/pumping/pumping';
import { PumpingInitialDataValidation } from '@dunefront/common/modules/pumping/model/initial-data/pumping-initial-data.validation';
import { ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';

@Component({
  selector: 'app-treating-line-coiled-tubing',
  templateUrl: './treating-line-coiled-tubing.component.html',
  styleUrls: ['./treating-line-coiled-tubing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TreatingLineCoiledTubingComponent {
  @Input() public pumping!: Pumping;

  @Output() public dataChange = new EventEmitter<ObjectChangeProp<Pumping>>();

  public UnitType = UnitSystem;

  public items = [
    { value: true, text: 'Yes' },
    { value: false, text: 'No' },
  ];

  public get isCheckEditReelDiameterEnabled(): boolean {
    return PumpingInitialDataValidation.isCheckEditReelDiameterEnabled(this.pumping);
  }

  public get isTxtReelDiameterEnabled(): boolean {
    return PumpingInitialDataValidation.isTxtReelDiameterEnabled(this.pumping);
  }

  public get isTxtTreatLineIDEnabled(): boolean {
    return PumpingInitialDataValidation.isTxtTreatLineIDEnabled(this.pumping);
  }

  public get isTxtTreatLineLengthEnabled(): boolean {
    return PumpingInitialDataValidation.isTxtTreatLineLengthEnabled(this.pumping);
  }

  public get lineIdLabelText(): string {
    return this.pumping.IsCoiledTubing ? 'Coil ID' : 'Line ID';
  }

  public get lineLengthLabelText(): string {
    return this.pumping.IsCoiledTubing ? 'Coil Length' : 'Line Length';
  }
}
