import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { BackendConnectionService } from '../../shared/backend-connection/backend-connection.service';
import { mergeMap } from 'rxjs/operators';
import * as actions from './settings.actions';
import {
  SettingsModuleName,
  UpdateSettingsAction,
  UpdateSettingsRowAction,
} from '@dunefront/common/modules/settings/settings-module.actions';
import { BaseWsEffects } from '../base-ws.effects';
import { ModalService } from '../../common-modules/modals/modal.service';
import { getValidatedSettings } from './validated-settings.selectors';

@Injectable()
export class SettingsEffects extends BaseWsEffects {
  constructor(actions$: Actions, store: Store, wsService: BackendConnectionService, modalService: ModalService) {
    super(actions$, wsService, SettingsModuleName, false, true, modalService, store);
  }

  public updateSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.changeShearRateAction, actions.changeSettingsProperty),
      concatLatestFrom(() => this.store.select(getValidatedSettings)),
      mergeMap(([action, settings]) => this.emitUpdate(new UpdateSettingsAction(settings, action))),
    ),
  );

  public updateSettingsRow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.updateSettingsRow),
      mergeMap((action) => this.emitUpdate(new UpdateSettingsRowAction(action))),
    ),
  );
}
