import { Component, EventEmitter, Input, NgModule, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormInputModule } from '../form-components/form-input/form-input.component';
import { UnitsModule } from '../../../common-modules/units/units.module';
import { SearchTableStatePipe } from './searchTableState.pipe';

@Component({
  selector: 'app-searchbar',
  templateUrl: './searchbar.component.html',
})
export class SearchbarComponent {
  @Input() public label: string | undefined;
  @Input() public autofocus = true;

  @Output() public searchChanged = new EventEmitter();

  public searchPhrase = '';
}

@NgModule({
  imports: [CommonModule, FormInputModule, UnitsModule],
  declarations: [SearchbarComponent, SearchTableStatePipe],
  exports: [SearchbarComponent, SearchTableStatePipe],
})
export class SearchbarModule {}
