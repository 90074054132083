<div class="file-options-grid" *ngIf="globalOptions">
  <app-page-panel header="File" [allowFullScreen]="false">
    <div class="grid-form-container">
      <app-check-box
        [source]="globalOptions"
        [key]="'UseTemporaryProjectFile'"
        class="grid-two-one"
        label="Work on temp file"
        (valueChanged)="onValueChange($event)"
      >
      </app-check-box>
    </div>
  </app-page-panel>
</div>
