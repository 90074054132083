import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { ModalService } from '../modal.service';
import { ISelectItem } from '@dunefront/common/common/select.helpers';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ChartCopyDataHelper } from '../../chart/chart-copy-data.helper';
import { PrimitiveChangeValue } from '@dunefront/common/common/common-state.interfaces';
import { defaultModalCancelButton, modalButton, ModalButtonConfig } from '../generic-modal/generic-modal.component';
import { DrawableDataProvider, DrawableRegistryService } from '../../../shared/services/drawable-registry.service';
import { map } from 'rxjs/operators';
import { ArrayHelpers } from '@dunefront/common/common/array-helpers';
import { BehaviorSubject, combineLatest, firstValueFrom, Observable } from 'rxjs';

export interface CopyDataComponentViewModel {
  selectedProviderId: string | undefined;
  selectedChartDataSets: ISelectableDataset[];
  providers: DrawableDataProvider[];
  modalButtonsConfigs: ModalButtonConfig[];
}

const sortProviders = (a: DrawableDataProvider, b: DrawableDataProvider): number =>
  ArrayHelpers.stringCompare(a.getDisplayName(), b.getDisplayName());

@Component({
  selector: 'app-copy-data',
  templateUrl: './copy-data.component.html',
  styleUrls: ['./copy-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CopyDataComponent {
  public modalTitle = 'Copy Chart Data';
  private selectedProviderId$ = new BehaviorSubject<string | undefined>(undefined);
  private selectedChartDataSets$ = new BehaviorSubject<ISelectableDataset[]>([]);
  private sortedProviders$ = this.drawableRegistryService.dataProviders$.pipe(map((providers) => providers.sort(sortProviders)));

  public vm$: Observable<CopyDataComponentViewModel> = combineLatest([
    this.sortedProviders$,
    this.selectedChartDataSets$,
    this.selectedProviderId$,
  ]).pipe(
    map(([providers, selectedChartDataSets, selectedProviderId]) => {
      if (selectedProviderId == null && providers.length > 0) {
        const provider = providers[0];

        selectedProviderId = provider.id;
        selectedChartDataSets = CopyDataComponent.getSelectableDataSetsForProvider(provider);

        // When dialog opened provider is unselected. If it's not changed from a dropdown then Select/Clear All triggers this logic
        // again and it clears selection. Emitting new value will trigger this logic once more but it won't get into this if block.
        this.selectedProviderId$.next(selectedProviderId);
      }

      return {
        providers,
        selectedChartDataSets,
        selectedProviderId: selectedProviderId == null && providers.length > 0 ? providers[0].id : selectedProviderId,
        modalButtonsConfigs: this.getModalButtonsConfigs(selectedProviderId, selectedChartDataSets),
      };
    }),
  );

  constructor(
    protected store: Store,
    protected cdRef: ChangeDetectorRef,
    protected modalService: ModalService,
    protected drawableRegistryService: DrawableRegistryService,
    public dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
  ) {}

  public async copyToClipboardClicked(selectedProviderId: string | undefined, selectedChartDataSets: ISelectableDataset[]): Promise<void> {
    if (selectedProviderId == null) {
      return;
    }

    const providers = await firstValueFrom(this.sortedProviders$);
    const provider = providers.find((p) => p.id === selectedProviderId);
    if (provider == null) {
      return;
    }

    const selectedDatasets = selectedChartDataSets.filter((dataset) => dataset.isSelected);
    if (selectedDatasets.length === 0) {
      await this.modalService.showAlert('Select at least one series.', 'Information');
      return;
    }

    const dataStr = ChartCopyDataHelper.getChartCopyData(
      provider,
      selectedDatasets.map((d) => d.datasetIndex),
    );

    if (dataStr == null) {
      return;
    }

    // copy data and close dialog
    await navigator.clipboard.writeText(dataStr);
    this.modalService.showAlert('Chart data copied to clipboard.', 'Information').then();

    this.cancelClicked();
  }

  public onSelectUnselectAllClick(isSelected: boolean, selectedChartDataSets: ISelectableDataset[]): void {
    const newSelectedDataSets = selectedChartDataSets.map((s) => ({ ...s, isSelected }));
    this.selectedChartDataSets$.next(newSelectedDataSets);
  }

  public cancelClicked(): void {
    this.dialogRef.close();
  }

  public onColumnSelected(column: ISelectableDataset, event: PrimitiveChangeValue<boolean>): void {
    column.isSelected = event.value;
  }

  public selectedProviderChanged(event: PrimitiveChangeValue<string>, providers: DrawableDataProvider[]): void {
    const providerId = event.value;
    const provider = providers.find((p) => p.id === providerId);
    if (provider == null) {
      return;
    }

    this.selectedChartDataSets$.next(CopyDataComponent.getSelectableDataSetsForProvider(provider));
    this.selectedProviderId$.next(providerId);
  }

  public providersToSelectItems(providers: DrawableDataProvider[]): ISelectItem<string>[] {
    return providers.map((p) => ({ value: p.id, text: p.getDisplayName() }));
  }

  private static getSelectableDataSetsForProvider(provider: DrawableDataProvider): ISelectableDataset[] {
    const chartDatasetsNames = ChartCopyDataHelper.getChartDatasetNames(provider);
    if (!chartDatasetsNames) {
      return [];
    }

    return chartDatasetsNames.map(
      (datasetName, datasetIndex): ISelectableDataset => ({
        datasetIndex,
        displayName: datasetName,
        isSelected: false,
      }),
    );
  }

  private getModalButtonsConfigs(selectedProviderId: string | undefined, selectedChartDataSets: ISelectableDataset[]): ModalButtonConfig[] {
    return [
      modalButton('Select All', (): void => this.onSelectUnselectAllClick(true, selectedChartDataSets), 'copy-data-select-all'),
      modalButton('Clear All', (): void => this.onSelectUnselectAllClick(false, selectedChartDataSets), 'copy-data-clear-all'),
      defaultModalCancelButton((): void => this.cancelClicked()),
      modalButton(
        'Copy to Clipboard',
        (): Promise<void> => this.copyToClipboardClicked(selectedProviderId, selectedChartDataSets),
        'copy-data-copy-clipboard',
      ),
    ];
  }
}

export interface ISelectableDataset {
  displayName: string;
  datasetIndex: number;
  isSelected: boolean;
}
