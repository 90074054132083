<app-generic-modal [title]="modalTitle" [modalButtonsConfigs]="modalButtonsConfigs">
  <div class="grid-form-container">
    <div class="title-input">
      <app-form-input
        label="Axis Title"
        [isUiLockable]="false"
        [source]="axisProps"
        [key]="'title'"
        [isTextInput]="true"
        [unitType]="UnitType.None"
        [maxWidth]="300"
        [minWidth]="300"
        (valueChanged)="onValueChanged($event)"
      >
      </app-form-input>
    </div>
    <button
      *ngIf="axisProps.title !== editAxisProps.defaultTitle"
      appButton
      type="button"
      buttonType="primary"
      class="btn btn-primary btn-sm ml-1"
      [isUiLockable]="false"
      (buttonClick)="onResetTitle()"
      data-cy="chart-axis-props---reset-title"
    >
      Reset
    </button>
  </div>

  <div class="grid-form-container">
    <div class="grid-two-one">Axis Type</div>

    <app-radio-group
      id="chart-axis-config-log-radio"
      [isUiLockable]="false"
      [items]="[
        { value: false, text: 'Linear' },
        { value: true, text: 'Logarithmic', disabled: isLogarithmicDisabled }
      ]"
      [source]="axisProps"
      [key]="'isLogarithmic'"
      (valueChanged)="onValueChanged($event)"
      class="grid-two-one"
      dataCy="chart-axis-props-logarithmic-radio"
      layout="horizontal"
    >
    </app-radio-group>
  </div>

  <div class="grid-form-container with-gap">
    <div class="grid-two-one">Axis Limits</div>

    <app-radio-group
      id="chart-axis-config-limits-radio"
      [isUiLockable]="false"
      [items]="[
        { value: false, text: 'Auto' },
        { value: true, text: 'Manual' }
      ]"
      [source]="axisProps"
      [key]="'manualLimit'"
      (valueChanged)="onValueChanged($event)"
      class="grid-two-one"
      layout="horizontal"
    >
    </app-radio-group>

    <ng-container *ngIf="axisProps.manualLimit">
      <ng-container *ngIf="!isTimeAxis || axisProps.xAxisFormat === XAxisFormat.deltaTime">
        <app-form-input
          label="Minimum"
          [source]="axisProps"
          key="min"
          [isTextInput]="false"
          [hideUnitBox]="true"
          [unitType]="UnitType.None"
          [maxWidth]="100"
          [minWidth]="100"
          [isUiLockable]="false"
          (valueChanged)="onValueChanged($event)"
        >
        </app-form-input>

        <app-form-input
          label="Maximum"
          [source]="axisProps"
          key="max"
          [isTextInput]="false"
          [unitType]="UnitType.None"
          [maxWidth]="100"
          [hideUnitBox]="true"
          [minWidth]="100"
          [isUiLockable]="false"
          (valueChanged)="onValueChanged($event)"
        >
        </app-form-input>
      </ng-container>
      <ng-container *ngIf="isTimeAxis && axisProps.xAxisFormat === XAxisFormat.timestamp">
        <div class="row">
          <label>Minimum</label>
          <app-date-time-input
            [value]="timeMin"
            [errorMessage]="axisProps.error.min"
            (primitiveValueChanged)="timeMin = $event.value; updateMinMax()"
            [showTimePicker]="true"
            [isUiLockable]="false"
          >
          </app-date-time-input>
        </div>

        <div class="row">
          <label>Maximum</label>
          <app-date-time-input
            [value]="timeMax"
            [errorMessage]="axisProps.error.max"
            (primitiveValueChanged)="timeMax = $event.value; updateMinMax()"
            [showTimePicker]="true"
            [isUiLockable]="false"
          >
          </app-date-time-input>
        </div>
      </ng-container>
    </ng-container>

    <div class="grid-two-one">Axis Styles</div>
    <app-radio-group
      id="chart-axis-config-style-radio"
      [isUiLockable]="false"
      [items]="[
        { value: false, text: 'Default' },
        { value: true, text: 'Custom' }
      ]"
      [source]="axisProps"
      [key]="'overrideStyle'"
      (valueChanged)="onValueChanged($event)"
      class="grid-two-one"
      layout="horizontal"
    >
    </app-radio-group>
  </div>

  <app-chart-axis-style
    *ngIf="axisProps.overrideStyle && axisProps.style"
    [axisStyle]="axisProps.style"
    (valueChanged)="onStyleValueChanged($event)"
  >
  </app-chart-axis-style>

  <div class="grid-form-container" *ngIf="isTimeAxis">
    <div class="grid-two-one">X-Axis format <span *ngIf="!editAxisProps.startDate"> ( no series with start date are defined ) </span></div>
    <app-radio-group
      id="chart-axis-x-axis-format"
      [disabled]="!editAxisProps.startDate"
      [isUiLockable]="false"
      [items]="[
        { value: 0, text: 'Delta time' },
        { value: 1, text: 'Timestamp' }
      ]"
      [source]="axisProps"
      [key]="'xAxisFormat'"
      [value]="editAxisProps.startDate ? axisProps.xAxisFormat : 0"
      (valueChanged)="onValueChanged($event)"
      class="grid-two-one"
      dataCy="chart-axis-props-x-axis-format-radio"
      layout="horizontal"
    >
    </app-radio-group>
  </div>
</app-generic-modal>
