import { BaseReportGenerator } from '../common/base-report-generator';
import { ReportInfoDto, ReportInfoFields } from '@dunefront/common/dto/report-info.dto';
import { IAppTargetConfig } from '@dunefront/common/common/app-target-config';
import { ChartReportGeneratorHelper, ReportScenarioImages } from './sections/chart-report-generator.helper';
import { Store } from '@ngrx/store';
import { ConvertUnitPipe } from '@dunefront/common/modules/units/convert-unit.pipe/convert-unit.pipe';
import {
  AlignmentType,
  Document,
  DocumentAttributes,
  HeadingLevel,
  ISectionOptions,
  LevelFormat,
  Packer,
  TableOfContents,
  XmlComponent,
} from 'docx';
import { DocumentGeneratorHelper } from './document-generator.helper';
import { FileChild } from 'docx/build/file/file-child';
import { WellReportGeneratorHelper } from './sections/well-report-generator.helper';
import { CompletionReportGeneratorHelper } from './sections/completion-report-generator.helper';
import { ImportedDataGeneratorHelper } from './sections/imported-data-generator.helper';
import { FluidsReportGeneratorHelper } from './sections/fluids-report-generator.helper';
import { GravelsReportGeneratorHelper } from './sections/gravels-report-generator.helper';
import { PumpingReportGeneratorHelper } from './sections/pumping-report-generator.helper';
import { SettingsReportGeneratorHelper } from './sections/settings-report-generator.helper';
import { AnimationVisualizationReportGeneratorHelper } from './sections/animation-report-generator.helper';
import { SummaryReportGeneratorHelper } from './sections/summary-report-generator.helper';
import { PsdInputReportGeneratorHelper } from './sections/psd-input-report-generator.helper';
import { PsdAnalysisReportGeneratorHelper } from './sections/psd-analysis-report-generator.helper';
import { PsdSummaryReportGeneratorHelper } from './sections/psd-summary-report-generator.helper';
import { TrendAnalysisReportGeneratorHelper } from './sections/trend-analysis-report-generator.helper';
import { PsdScreenAndGravelSelectionReportGeneratorHelper } from './sections/psd-screen-and-gravel-selection-generator.helper';
import { accentColor, headerFontSize, subHeaderFontSize } from '../../report-consts';
import { DocxImageGenerator } from './docx-image-generator';
import { DocxHtmlParser } from './docx-html-parser';

export class DocReportGenerator extends BaseReportGenerator {
  private readonly sections: ISectionOptions[] = [];
  private readonly contentSectionItems: FileChild[] = [];
  private readonly docxImageGenerator = new DocxImageGenerator();

  public readonly reportFileExtension = 'docx';
  public readonly reportTypeName = 'Word Document';

  constructor(
    reportInfo: ReportInfoDto,
    reportInfoFields: ReportInfoFields,
    reportName: string,
    appConfig: IAppTargetConfig,
    images: ReportScenarioImages,
    store: Store,
    convertUnitPipe: ConvertUnitPipe,
  ) {
    super(reportInfo, reportInfoFields, reportName, appConfig, images, store, convertUnitPipe);
  }

  private addContent(items: FileChild[]): void {
    this.contentSectionItems.push(...items);
  }

  protected override async createTitleAndReportInfo(): Promise<void> {
    this.sections.push(DocumentGeneratorHelper.createFirstPage(this.reportInfo, this.reportDate, this.appConfig.appName));
  }

  protected override async createDisclaimer(): Promise<void> {
    this.sections.push(DocumentGeneratorHelper.createDisclaimer());
  }

  protected override async createTOC(): Promise<void> {
    this.addContent([
      DocumentGeneratorHelper.createParagraphHeader('Contents', HeadingLevel.HEADING_1, 0),
      new TableOfContents('Summary', {
        hyperlink: true,
        headingStyleRange: '1-5',
      }),
    ]);
  }

  protected override async createWellSection(isOpenHole: boolean): Promise<void> {
    const { store, images, reportInfoFields } = this;
    const currentUnitSystem = await this.getCurrentUnitSystem();
    this.addContent(
      await WellReportGeneratorHelper.createWellDataSection(
        store,
        currentUnitSystem,
        images,
        reportInfoFields,
        isOpenHole,
        this.docxImageGenerator,
      ),
    );
  }

  protected override async createCompletionSection(): Promise<void> {
    const { store, images } = this;
    const currentUnitSystem = await this.getCurrentUnitSystem();

    this.addContent(
      await CompletionReportGeneratorHelper.createCompletionDataSection(store, currentUnitSystem, images, this.docxImageGenerator),
    );
  }

  protected override async createFluidsSection(): Promise<void> {
    const { store } = this;
    const currentUnitSystem = await this.getCurrentUnitSystem();

    this.addContent(await FluidsReportGeneratorHelper.createFluidsDataSection(store, currentUnitSystem));
  }

  protected override async createGravelsSection(): Promise<void> {
    const { store } = this;
    const currentUnitSystem = await this.getCurrentUnitSystem();

    this.addContent(await GravelsReportGeneratorHelper.createGravelsDataSection(store, currentUnitSystem));
  }

  protected override async createImportedDataInformationSection(): Promise<void> {
    const { store } = this;
    const currentUnitSystem = await this.getCurrentUnitSystem();

    this.addContent(await ImportedDataGeneratorHelper.createImportedDataSection(store, currentUnitSystem));
  }

  protected override async createRangeHeader(rangeName: string, rangeNotes: string | null): Promise<void> {
    this.addContent([
      DocumentGeneratorHelper.createParagraphHeader(DocumentGeneratorHelper.addRangeNameToTitle(rangeName), HeadingLevel.HEADING_1, 0),
    ]);

    if (rangeNotes) {
      this.addContent(new DocxHtmlParser(rangeNotes).render());
    }
  }

  protected override async createPumpingSection(rangeName: string): Promise<void> {
    const { store } = this;
    const currentUnitSystem = await this.getCurrentUnitSystem();

    this.addContent(await PumpingReportGeneratorHelper.createPumpingDataSection(store, currentUnitSystem, rangeName));
  }

  protected override async createSettingsSection(): Promise<void> {
    const { store } = this;
    const currentUnitSystem = await this.getCurrentUnitSystem();

    this.addContent(await SettingsReportGeneratorHelper.createSettingsSection(store, currentUnitSystem));
  }

  protected override async createEvaluationAnimationSection(rangeId: number, rangeName?: string): Promise<void> {
    this.addContent(
      await AnimationVisualizationReportGeneratorHelper.createEvaluationAnimationSection(
        this.images,
        this.docxImageGenerator,
        rangeId,
        rangeName,
      ),
    );
  }

  protected override async createSimulationAnimationSection(rangeId: number, rangeName?: string): Promise<void> {
    this.addContent(
      await AnimationVisualizationReportGeneratorHelper.createSimulationAnimationSection(
        this.images,
        this.docxImageGenerator,
        rangeId,
        rangeName,
      ),
    );
  }

  protected override async createWellVisualizationSection(rangeId: number, rangeName?: string): Promise<void> {
    this.addContent(
      await AnimationVisualizationReportGeneratorHelper.createWellVisualization(this.images, this.docxImageGenerator, rangeId, rangeName),
    );
  }

  protected override async createSummarySection(isTestRun: boolean, rangeName?: string): Promise<void> {
    const { store } = this;
    const currentUnitSystem = await this.getCurrentUnitSystem();
    this.addContent(await SummaryReportGeneratorHelper.createSummarySection(store, currentUnitSystem, isTestRun, rangeName));
  }

  protected override async createChartsSection(rangeId: number, rangeName?: string): Promise<void> {
    this.addContent(await ChartReportGeneratorHelper.createReportingChartSection(this.images, this.docxImageGenerator, rangeId, rangeName));
  }

  protected override async createPsdInputSection(): Promise<void> {
    const { store, convertUnitPipe, images } = this;
    const currentUnitSystem = await this.getCurrentUnitSystem();

    this.addContent(
      await PsdInputReportGeneratorHelper.createPsdInputDataSection(store, currentUnitSystem, convertUnitPipe, images, this.docxImageGenerator),
    );
  }

  protected override async createPsdAnalysisSection(): Promise<void> {
    const { store, images } = this;
    const currentUnitSystem = await this.getCurrentUnitSystem();

    this.addContent(
      await PsdAnalysisReportGeneratorHelper.createPsdAnalysisDataSection(store, currentUnitSystem, images, this.docxImageGenerator),
    );
  }

  protected override async createPsdSummarySection(): Promise<void> {
    const { store, convertUnitPipe } = this;
    const currentUnitSystem = await this.getCurrentUnitSystem();

    this.addContent(await PsdSummaryReportGeneratorHelper.createPsdSummaryDataSection(store, currentUnitSystem, convertUnitPipe));
  }

  protected override async createTrendAnalysisInputsSection(rangeId: number, rangeName: string): Promise<void> {
    const currentUnitSystem = await this.getCurrentUnitSystem();
    this.addContent(
      await TrendAnalysisReportGeneratorHelper.createTrendAnalysisReport(
        this.store,
        currentUnitSystem,
        this.images,
        this.docxImageGenerator,
        rangeId,
        rangeName,
      ),
    );
  }

  protected override async createPsdScreenAndGravelSelectionSection(): Promise<void> {
    const { store, convertUnitPipe } = this;
    const currentUnitSystem = await this.getCurrentUnitSystem();

    this.addContent(
      await PsdScreenAndGravelSelectionReportGeneratorHelper.createPsdScreenAndGravelSelectionDataSection(
        store,
        currentUnitSystem,
        convertUnitPipe,
      ),
    );
  }

  protected buildDocument(): Document {
    const doc = new Document({
      numbering: numbering,
      features: {
        updateFields: true,
      },
      styles: DocumentGeneratorHelper.getDocStyles(),

      sections: [
        ...this.sections,
        {
          properties: DocumentGeneratorHelper.configurePageMargins(),
          children: this.contentSectionItems,
        },
      ],
    });

    this.fixDocDate(doc, this.reportInfoFields);

    return doc;
  }

  protected override async getReportAsBase64(): Promise<string> {
    const doc = this.buildDocument();

    return await Packer.toBase64String(doc);
  }

  protected override async getReportAsBlob(): Promise<Blob> {
    const doc = this.buildDocument();

    return await Packer.toBlob(doc);
  }

  /**
   * Fixes the date elements in the given Document object based on the provided ReportInfoFields.
   * Removes the original date elements and adds new date elements with date value from reportInfoFields.
   *
   * @param {Document} doc - The Document object to fix the date elements.
   * @param {ReportInfoFields} reportInfoFields - The ReportInfoFields containing the new date value.
   * @returns {void}
   */
  private fixDocDate(doc: Document, reportInfoFields: ReportInfoFields): void {
    const root: { rootKey?: string }[] = (doc.CoreProperties as any).root;

    // remove original elements
    const dateCreatedIndex = root.findIndex((item) => item.rootKey === 'dcterms:created');
    if (dateCreatedIndex > -1) {
      root.splice(dateCreatedIndex, 1);
    }
    const dateModifiedIndex = root.findIndex((item) => item.rootKey === 'dcterms:modified');
    if (dateModifiedIndex > -1) {
      root.splice(dateModifiedIndex, 1);
    }

    const date = new Date(reportInfoFields.Date);

    root.push(new TimestampElement('dcterms:created', date) as any);
    root.push(new TimestampElement('dcterms:modified', date) as any);
  }
}

class TimestampElement extends XmlComponent {
  constructor(name: string, date: Date) {
    super(name);
    this.root.push(
      new DocumentAttributes({
        type: 'dcterms:W3CDTF',
      }),
    );
    this.root.push(this.dateTimeValue(date));
  }

  private dateTimeValue(val: Date): string {
    return val.toISOString();
  }
}

export const numbering = {
  config: [
    {
      reference: 'main-numbering',
      levels: [
        {
          level: 0,
          format: LevelFormat.DECIMAL,
          text: '%1',
          alignment: AlignmentType.LEFT,
          style: {
            run: {
              color: accentColor,
              size: headerFontSize,
            },
          },
        },
        {
          level: 1,
          format: LevelFormat.DECIMAL,
          text: '%1.%2',
          alignment: AlignmentType.LEFT,
          style: {
            run: {
              color: accentColor,
              size: subHeaderFontSize,
            },
          },
        },
        {
          level: 2,
          format: LevelFormat.DECIMAL,
          text: '%1.%2.%3',
          alignment: AlignmentType.LEFT,
          style: {
            run: {
              color: accentColor,
              size: subHeaderFontSize,
            },
          },
        },
        {
          level: 3,
          format: LevelFormat.DECIMAL,
          text: '%1.%2.%3.%4',
          alignment: AlignmentType.LEFT,
          style: {
            run: {
              color: accentColor,
              size: subHeaderFontSize,
            },
          },
        },
      ],
    },
  ],
};
