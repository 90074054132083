import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { GenericOptionsComponent } from '../generic-options.component';
import { Store } from '@ngrx/store';
import { ISelectItem } from '@dunefront/common/common/select.helpers';
import { GlobalOptionsDto } from '@dunefront/common/dto/global-options.dto';

@Component({
  selector: 'app-chart-performance-options',
  templateUrl: './chart-performance-options.component.html',
  styleUrls: ['./chart-performance-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartPerformanceOptionsComponent extends GenericOptionsComponent {
  constructor(store: Store, cdRef: ChangeDetectorRef) {
    super(store, cdRef);
  }

  public override getDefaultTooltip(key: keyof GlobalOptionsDto, itemsArray?: ISelectItem<number>[]): string | null {
    if (this.defaultGlobalOptions == null) {
      return null;
    }
    const defaultValue =
      itemsArray != null
        ? itemsArray.find((item) => this.defaultGlobalOptions != null && item.value === (this.defaultGlobalOptions[key] as number))?.text
        : this.defaultGlobalOptions[key];
    return this.isValueDiffThanDefault(key) ? 'Default value: ' + defaultValue : null;
  }
}
