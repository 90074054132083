import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { PumpingStateByRange } from '@dunefront/common/modules/pumping/pumping-module.state';
import { PageWithGridComponent } from '../../../../../shared/components/grid/page-with-grid.component';
import { ModalService } from '../../../../../common-modules/modals/modal.service';
import { ScreenService } from '../../../../../shared/services';
import { ITableRow } from '@dunefront/common/common/common-grid.interfaces';
import { switchDetailedFluidDefinitionTypeAction, updateWellFluidsRowsAction } from '../../../../../+store/pumping/pumping.actions';
import { WellFluid } from '@dunefront/common/modules/pumping/model/well-fluid/well-fluid';
import { Pumping } from '@dunefront/common/modules/pumping/model/pumping/pumping';
import {
  getWellFluidReferenceVariablesData,
  ReferenceVariablesData,
} from '../../../../../+store/pumping/selectors/well-fluid-reference-variables.selectors';
import { filter } from 'rxjs/operators';
import { ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';
import { IUpdateTableRowsProps } from '@dunefront/common/common/common-store-crud.interfaces';
import { WellFluidValidation } from '@dunefront/common/modules/pumping/model/well-fluid/well-fluid.validation';

@Component({
  selector: 'app-detailed-fluid-definition',
  templateUrl: './detailed-fluid-definition.component.html',
  styleUrls: ['./detailed-fluid-definition.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetailedFluidDefinitionComponent extends PageWithGridComponent<WellFluid> implements OnInit, OnChanges {
  @Input() public pumpingState!: PumpingStateByRange;
  @Input() public isCalculate = false;

  @Output() public pumpingPropertyChanged = new EventEmitter<ObjectChangeProp<Pumping>>();
  public items = [
    { value: true, text: 'Volume' },
    { value: false, text: 'MD' },
  ];

  public referenceVariablesData!: ReferenceVariablesData;
  public isWellFluidDepthByVolume = false;

  constructor(store: Store, cdRef: ChangeDetectorRef, modalService: ModalService, resizeService: ScreenService) {
    super(store, cdRef, modalService, resizeService);
  }

  public override ngOnInit(): void {
    super.ngOnInit();

    this.subscription.add(
      this.store
        .select(getWellFluidReferenceVariablesData)
        .pipe(filter((refVar) => !!refVar))
        .subscribe((referenceVariablesData) => {
          this.referenceVariablesData = referenceVariablesData as ReferenceVariablesData;
          this.cdRef.markForCheck();
        }),
    );
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.pumpingState != null) {
      this.isWellFluidDepthByVolume = this.pumpingState.pumping.IsWellFluidDepthByVolume;
    }
  }

  public getRows(): ITableRow<WellFluid>[] {
    return this.pumpingState?.wellFluids.rows;
  }

  public async switchDetailedFluidDefinitionType(event: ObjectChangeProp<Pumping>): Promise<void> {
    const isWellFluidDepthByVolume = event.value as boolean;
    const isValid = await this.validateLineVolume(isWellFluidDepthByVolume);
    if (isValid) {
      this.store.dispatch(
        switchDetailedFluidDefinitionTypeAction({
          isWellFluidDepthByVolume,
          scenarioId: this.currentScenarioId,
          rangeId: this.pumpingState.pumping.RangeId,
        }),
      );
    }
  }

  private async validateLineVolume(isWellFluidDepthByVolume: boolean): Promise<boolean> {
    const errorMessage = !isWellFluidDepthByVolume
      ? WellFluidValidation.ValidateLineVolume(this.pumpingState.wellFluids, this.referenceVariablesData.referenceVariables)
      : '';

    if (errorMessage) {
      await this.modalService.showAlert(errorMessage, 'Information');
      this.isWellFluidDepthByVolume = isWellFluidDepthByVolume;
      this.cdRef.detectChanges();
      this.isWellFluidDepthByVolume = !isWellFluidDepthByVolume;
      this.cdRef.markForCheck();
      return false;
    }
    return true;
  }

  protected updateRow(row: IUpdateTableRowsProps<WellFluid>): void {
    this.store.dispatch(updateWellFluidsRowsAction(row));
  }
}
