import { NgModule } from '@angular/core';
import { PagePanelModule } from '../../../common-modules/page-panel/page-panel.component';
import { CommonModule } from '@angular/common';
import { TabbedPageModule } from '../../../common-modules/tabbed-page/tabbed-page.component';
import { UnitsModule } from '../../../common-modules/units/units.module';
import { FormInputModule } from '../../../shared/components/form-components/form-input/form-input.component';
import { UnwrapDirectiveModule } from '../../../shared/directives/unwrap.directive';
import { GridModule } from '../../../shared/components/grid/grid.module';
import { ModalService } from '../../../common-modules/modals/modal.service';
import { ScreenService } from '../../../shared/services';
import { ConvertUnitPipe } from '@dunefront/common/modules/units/convert-unit.pipe/convert-unit.pipe';
import { PsdResultsComponent } from './psd-results.component';
import { SummaryComponent } from './summary/summary.component';
import { ScreenAndGravelSelectionComponent } from './screen-and-gravel-selection/screen-and-gravel-selection.component';
import { FluidSelectorModule } from '../../common/fluid-selector/fluid-selector.module';
import { GravelSelectorModule } from '../../common/gravel-selector/gravel-selector.module';
import { RadioGroupModule } from '../../../shared/components/form-components/radio-group/radio-group.component';
import { ChartModule } from '../../../common-modules/chart/chart.module';
import { CheckBoxModule } from '../../../shared/components/form-components/check-box/check-box.component';
import { PsdSelectorModule } from '../common-components/psd-selector/psd-selector.component';
import { StandaloneScreensGridComponent } from './screen-and-gravel-selection/standalone-screens-grid/standalone-screens-grid.component';
import { SummaryGridComponent } from './summary/summary-grid/summary-grid.component';
import { GravelPackGridComponent } from './screen-and-gravel-selection/gravel-pack-grid/gravel-pack-grid.component';
import { SelectModule } from '../../../shared/components/form-components/select/select.module';

@NgModule({
  declarations: [
    PsdResultsComponent,
    SummaryComponent,
    ScreenAndGravelSelectionComponent,
    StandaloneScreensGridComponent,
    SummaryGridComponent,
    GravelPackGridComponent,
  ],
  imports: [
    PagePanelModule,
    CommonModule,
    TabbedPageModule,
    UnitsModule,
    SelectModule,
    FluidSelectorModule,
    GravelSelectorModule,
    FormInputModule,
    RadioGroupModule,
    UnwrapDirectiveModule,
    ChartModule,
    CheckBoxModule,
    GridModule,
    PsdSelectorModule,
  ],
  providers: [ModalService, ScreenService, ConvertUnitPipe],
})
export class PsdResultsModule {}
