import { FrictionCalculatorResults } from '../injection-test-calculator.dto';

export class InjectionTestCalculatorFactory {
  public static cleanResultsPartial(): FrictionCalculatorResults {
    return {
      ReservoirPressure: 0,
      ClosurePressure: 0,
      ExtensionPressure: 0,
      ExtensionRate: 0,
      BelowFracSlope: 0,
      BelowFracIntercept: 0,
      AboveFracSlope: 0,
      AboveFracIntercept: 0,
    };
  }
}
