import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { DeveloperSettingsDto, ValidatedDeveloperSettings } from '@dunefront/common/modules/settings/dto/settingsDto';
import { ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';

@Component({
  selector: 'app-general-dev-options',
  templateUrl: './general-dev-options.component.html',
  styleUrls: ['./general-dev-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeneralDevOptionsComponent {
  @Input() public developerSettings!: ValidatedDeveloperSettings;
  @Input() public defaultOptions!: DeveloperSettingsDto;
  @Output() public developerSettingsValueChange = new EventEmitter<ObjectChangeProp<ValidatedDeveloperSettings>>();

  public UnitType = UnitSystem;

  public onValueChange(props: ObjectChangeProp<ValidatedDeveloperSettings>): void {
    this.developerSettingsValueChange.emit(props);
  }
}
