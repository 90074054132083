import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import { CommonCalculationPageComponent } from '../common-calculation-page.component';
import { CalculatorsTab } from '../../../+store/ui/ui-module.state';

@Component({
  selector: 'app-injection-test',
  templateUrl: './injection-test.component.html',
  styleUrls: ['./injection-test.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InjectionTestComponent extends CommonCalculationPageComponent {
  public override appModuleType = ModuleType.Injection_Test_Calc;
  public override tab: CalculatorsTab = 'injection-test';
}
