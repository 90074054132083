import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { PanelButtonComponent } from '../panel.button.component';
import {
  addChartTabAction,
  chartModeAction,
  deleteReportingChartAction,
  editChartTabAction,
  showOneTimeInstructionPopupAction,
} from '../../../../+store/ui/ui.actions';
import { Store } from '@ngrx/store';
import { ChartMenuProps, getChartMenuProps, NewChartProps } from '../../../../+store/menu-selectors/chart/menu-chart.selector';
import { ChartMode, OneTimeInstructionType } from '../../../../+store/ui/ui-module.state';
import { ModalService } from '../../../modals/modal.service';
import { copyReportingChartTabAction } from '../../../../+store/reporting/reporting.actions';
import { CopyDataComponent } from '../../../modals/copy-data/copy-data.component';
import { ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import { firstValueFrom, Observable } from 'rxjs';
import { copyImageToClipboard } from '../../../chart/image-provider.helpers';
import { ImageProviderMode, SelectProviderComponent } from '../../../modals/select-image-provider/select-provider.component';
import { DrawableImageProvider, DrawableRegistryService } from '../../../../shared/services/drawable-registry.service';

@Component({
  selector: 'app-charts-menu-panel',
  templateUrl: './charts-menu-panel.component.html',
  styleUrls: ['./charts-menu-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartsMenuPanelComponent extends PanelButtonComponent {
  public ChartMode = ChartMode;
  public ModuleType = ModuleType;

  public chartMenuProps$: Observable<ChartMenuProps> = this.store.select(getChartMenuProps);

  constructor(
    private modalService: ModalService,
    private drawableRegistryService: DrawableRegistryService,
    protected override store: Store,
    protected cdRef: ChangeDetectorRef,
  ) {
    super(store);
  }

  public setChartMode(chartMode: ChartMode): void {
    this.store.dispatch(chartModeAction({ chartMode }));

    this.showInstruction(chartMode);
  }

  public async copyChartClicked(): Promise<void> {
    const imageProviders = await firstValueFrom(this.drawableRegistryService.imageProviders$);

    const selectedImageProvider = await this.selectImageProvider(imageProviders, ImageProviderMode.IMAGE);
    if (selectedImageProvider === null) {
      // on cancel
      return;
    }

    if (selectedImageProvider === undefined) {
      console.error('No ImageProviders found!');
      return;
    }

    await copyImageToClipboard(selectedImageProvider, this.modalService);
  }

  public async selectImageProvider(
    imageProviders: DrawableImageProvider[],
    mode: ImageProviderMode,
  ): Promise<DrawableImageProvider | undefined> {
    if (imageProviders.length > 1) {
      return await firstValueFrom(
        this.modalService.open(
          SelectProviderComponent,
          {
            providers: imageProviders,
            mode,
          },
          undefined,
          undefined,
          undefined,
          false,
        ).onClose,
      );
    } else if (imageProviders.length == 1) {
      return imageProviders[0];
    }
    return;
  }

  public copyDataClicked(): void {
    this.modalService.open(CopyDataComponent, undefined, 'copy-data-modal');
  }

  public newChartClicked(newChartProps: NewChartProps): void {
    this.store.dispatch(addChartTabAction({ newChartProps }));
  }

  public editChartClicked(): void {
    this.store.dispatch(editChartTabAction({}));
  }

  public deleteChartClicked(): void {
    this.store.dispatch(deleteReportingChartAction());
  }

  public copyChartTabClicked(): void {
    this.store.dispatch(copyReportingChartTabAction());
  }

  private showInstruction(chartMode: ChartMode): void {
    let instructionType: OneTimeInstructionType | undefined;

    switch (chartMode) {
      case 'editHorizontalMarker':
      case 'editVerticalMarker':
        instructionType = OneTimeInstructionType.marker;
        break;
      case 'annotate':
        instructionType = OneTimeInstructionType.annotation;
        break;
      case 'gradientLine':
        instructionType = OneTimeInstructionType.gradientLine;
    }

    if (instructionType != null) {
      this.store.dispatch(showOneTimeInstructionPopupAction({ instructionType }));
    }
  }
}
