import { PP_SESSION_PREFIX } from '../../common/constants';
import { User } from '@auth0/auth0-angular';

export interface IUser extends IUserId, IPersonalFolders {
  id: string;
  orgFolder: string;
  tempOrgFolder?: string;
  sharedFolder: string;
  personalPpd: string;
  orgPpd: string;
  role: UserRole;
}

export interface IUserId {
  uid: string;
  org: string;
  licenseIds: string[];
  sessionId: string;
}

export const userToUserId = (user: IUser): IUserId => ({ org: user.org, uid: user.uid, licenseIds: user.licenseIds, sessionId: '' });

export interface IPersonalFolders {
  personalRootFolder: string;
  personalFolder: string;
  personalTempRootFolder: string;
  personalTempFolder: string;
  tempFolder: string;
  trashFolder: string;
}

export enum UserRole {
  USER = 1,
  ORG_ADMIN = 2,
  SUPER_ADMIN = 3,
}

export interface IAuthClaims extends IUserMetaData {
  uid: string;
  sub: string;
  role: UserRole;
}

export interface IUserMetaData {
  organization_id: string;
  license_id: string;
}

export const getSessionIdLocalStorageKey = (userClaims: User): string => {
  return PP_SESSION_PREFIX + userClaims.sub;
};

export interface IUserProfile {
  email: string;
  name: string;
  uid: string;
  loginsCount: number;
  lastIp: string;
  lastLogin: Date;
  updatedAt: Date;
  createdAt: Date;
  picture: string;
  isEmailVerified: boolean;
  authPlatform: string;
  metadata?: IUserMetaData;
}
