import { Component, HostListener } from '@angular/core';
import { DevConsoleModalComponent } from './dev-console-modal/dev-console-modal.component';
import { ModalService } from '../../common-modules/modals/modal.service';

@Component({
  selector: 'app-dev-console',
  template: '',
})
export class DevConsoleComponent {
  @HostListener('document:keydown.control.shift.F2')
  public onCtrlShiftF2(): void {
    this.openDevConsoleModal();
  }

  constructor(private modalService: ModalService) {}

  public openDevConsoleModal(): void {
    this.modalService.open(DevConsoleModalComponent, {});
  }
}
