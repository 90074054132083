import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { SettingsDto, ValidatedSettings } from '@dunefront/common/modules/settings/dto/settingsDto';
import { ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { Store } from '@ngrx/store';

@Directive()
export class OptionsComponent {
  @Input() public settings!: ValidatedSettings;
  @Input() public defaultSettings!: SettingsDto;

  @Output() public settingsValueChange = new EventEmitter<ObjectChangeProp<ValidatedSettings>>();

  constructor(private store: Store) {}

  public UnitType = UnitSystem;

  public onValueChange(props: ObjectChangeProp<ValidatedSettings>): void {
    this.settingsValueChange.emit(props);
  }
}
