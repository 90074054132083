import { CompletionDto } from '../dto/completion.dto';
import {
  CompletionModuleState,
  emptyVolumeGaugeSectionCalculatorResult,
  emptyVolumeSectionCalculatorResult,
} from '../completion-module.state';
import { createTableState } from '../../../common/common-grid.interfaces';
import { Pipe } from '../../pipes/pipe';
import { RunningStringPipesFactory } from '../../pipes/running-string-pipes/running-string-pipes.factory';
import { LowerCompletionPipesFactory } from '../../pipes/lower-completion-pipes/lower-completion-pipes.factory';
import { ShuntTubeFactory } from '../../shunt-tube/shunt-tube.factory';
import { VolumesFactory } from './volumes/volumes.factory';
import { pick } from '../../../common/state.helpers';
import { IcdPortDataFactory } from './icd-port-data.factory';
import { LoadCompletionActionResponse } from '../completion-module.actions';

export class CompletionFactory {
  private static readonly COMPLETION_COMMON_PROPS: (keyof (CompletionDto | CompletionModuleState))[] = [
    'ScenarioId',
    'CentralizerOD',
    'WashpipeEccentricity',
    'HasCentralizers',
  ];

  public static create(payload: LoadCompletionActionResponse, scenarioId: number): CompletionModuleState {
    const { completionDto, lowerCompletionDtos, runningStringDtos, shuntTubeDto, icdPortDataDtos } = payload;

    const shuntTube = ShuntTubeFactory.create(shuntTubeDto);
    const lowerCompletionArray = LowerCompletionPipesFactory.createRowListFromDtos(lowerCompletionDtos, scenarioId);
    const runningStringArray = RunningStringPipesFactory.createRowListFromDtos(runningStringDtos, scenarioId);

    const icdPortData = IcdPortDataFactory.create(
      icdPortDataDtos,
      lowerCompletionDtos.map((lc) => lc.Id.toString()),
      scenarioId,
    );

    const completion: CompletionModuleState = {
      ...pick(completionDto, this.COMPLETION_COMMON_PROPS),
      LowerCompletion: createTableState<Pipe>(lowerCompletionArray),
      RunningString: createTableState<Pipe>(runningStringArray),
      IcdPortData: icdPortData,
      ShuntTube: shuntTube,
      Volumes: VolumesFactory.create(completionDto),
      isLoaded: true,
      VolumeSectionCalculatorResult: emptyVolumeSectionCalculatorResult(),
      VolumeGaugeSectionInformationResult: emptyVolumeGaugeSectionCalculatorResult(),
    };

    return completion;
  }

  public static updateCompletionProps(completion: CompletionModuleState, dto: CompletionDto): CompletionModuleState {
    return { ...completion, ...pick(dto, this.COMPLETION_COMMON_PROPS), Volumes: VolumesFactory.create(dto) };
  }

  public static toDto(completion: CompletionModuleState): CompletionDto {
    const completionParts = pick(completion, this.COMPLETION_COMMON_PROPS);
    return { ...completion.Volumes, ...completionParts };
  }
}
