<app-grid
  *ngIf="height > 0"
  [dataSource]="pumpingSchedule"
  [gridConfig]="gridConfig"
  [columns]="gridConfig.columns"
  (selectedRowsChanged)="selectedRowsChanged.emit($event)"
  [showCrudControls]="false"
  [height]="height"
  [isFirstRowInsertingAllowed]="true"
  [isFirstRowDeleteAllowed]="true"
  [minRowCount]="1"
  [headerTopRowHeight]="30"
  [isInsertingDisabled]="isReadOnlyMode"
  [isDeletingDisabled]="isReadOnlyMode"
  [isEditingDisabled]="isReadOnlyMode"
  dataCy="schedule-grid"
>
</app-grid>
