import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { openImportDataModalAction } from '../../../../../+store/import-data/import-data.actions';
import { TrendAnalysisChartErrorMessageType } from '../trend-analysis-chart.component';

@Component({
  selector: 'app-no-trend-analysis-message',
  templateUrl: './no-trend-analysis-message.component.html',
  styleUrls: ['./no-trend-analysis-message.component.scss'],
})
export class NoTrendAnalysisMessageComponent {
  constructor(private store: Store) {}

  @Input()
  public messageType: TrendAnalysisChartErrorMessageType = 'no-import-data';

  public onOpenImportDataClick(): void {
    this.store.dispatch(openImportDataModalAction());
  }
}
