import { Directive, HostBinding, HostListener, OnDestroy } from '@angular/core';

import { Store } from '@ngrx/store';
import { getUserHasAnyWaitingOrRunningJobs } from '../../+store/calculation-engine/calculation-engine.selectors';
import { selectShouldShowBeforeUnloadWarning } from '../../+store/ui/ui.selectors';
import { getDeviceRatioCss, IScreenSizes, ScreenService } from '../services';
import { checkIfVacuumNeededAndCloseFile, dbDisconnectSuccessAction } from '../../+store/backend-connection/backend-connection.actions';
import { ModalService } from '../../common-modules/modals/modal.service';
import { Actions, ofType } from '@ngrx/effects';
import { filter, firstValueFrom, Subscription } from 'rxjs';
import { getIsDatabaseConnected } from '../../+store/backend-connection/backend-connection.selectors';
import { NavigationEnd, Router } from '@angular/router';
import { ElectronService } from '../services/electron-service/electron.service';
import { AppUrlPart, RouterHelperService } from '../services/router-helper.service';

@Directive()
export class BaseAppComponent implements OnDestroy {
  public environment: any;
  public themeName = '';
  private areAnyCalcJobsInProgress = false;
  private shouldShowBeforeUnloadWarning = true;
  private isCloseAppRequested = false;
  private isCloseConfirmShown = false;
  private isStopCalcsBeforeClosingAlertShown = false;
  private subscription = new Subscription();

  constructor(
    actions$: Actions,
    private store: Store,
    private electronService: ElectronService,
    private screen: ScreenService,
    private modalService: ModalService,
    private router: Router,
    private routerHelper: RouterHelperService,
  ) {
    this.subscription.add(
      this.store.select(getUserHasAnyWaitingOrRunningJobs).subscribe((anyJobsInProgress) => {
        this.areAnyCalcJobsInProgress = anyJobsInProgress;
      }),
    );

    this.subscription.add(
      actions$
        .pipe(
          ofType(dbDisconnectSuccessAction),
          filter(() => this.electronService.isElectronApp && this.isCloseAppRequested),
        )
        .subscribe(() => this.quitElectronApp()),
    );

    this.subscription.add(
      this.store
        .select(selectShouldShowBeforeUnloadWarning)
        .subscribe((shouldShowBeforeUnloadWarning) => (this.shouldShowBeforeUnloadWarning = shouldShowBeforeUnloadWarning)),
    );

    const isBackButton = (event: any): boolean => {
      return event.navigationTrigger === 'popstate';
    };

    this.subscription.add(
      this.router.events.pipe(filter((e) => e instanceof NavigationEnd || isBackButton(e))).subscribe((routerEvent: any) => {
        if (isBackButton(routerEvent)) {
          // Close all modals when browser back btn is clicked
          this.modalService.dismissAll();
          // if previous state contains -1 in it's url,
          // it means that it's initial state of app, so we can redirect user to homepage
          if (this.routerHelper.getDecodedUrlParts(routerEvent.url)[AppUrlPart.rangeId] === '-1') {
            this.routerHelper.navigateToHome().then();
            return;
          }

          // if prev stat contains licenceFeature phrase, it means that user pressed back after switching module
          // in that case we want to go back extra step, to end up on initial state of prev module
          if (routerEvent.url.includes('licenseFeature=')) {
            window.history.go(-1);
          }
        } else {
          const moduleClassName = (routerEvent.urlAfterRedirects || '').split('/')[1];
          document.body.className = `${this.themeName} ${moduleClassName}`;
        }
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  @HostBinding('class')
  public get getClass(): string {
    const screenSizes = (Object.keys(this.screen.sizes) as (keyof IScreenSizes)[]).filter((cl) => this.screen.sizes[cl]).join(' ');
    const ratio = getDeviceRatioCss(this.screen.currentPixelRatio);
    return screenSizes + ' ' + ratio;
  }

  @HostListener('window:beforeunload', ['$event'])
  private beforeUnloadHandler(event: any): any {
    // this is electron only feature!
    if (!this.electronService.isElectronApp) {
      return;
    }

    if (!this.shouldShowBeforeUnloadWarning) {
      return;
    }

    event.preventDefault();

    if (this.checkClosingAllowedShowAlertIfNeeded()) {
      this.showCloseConfirmIfNotVisible().then(async (isConfirmed) => {
        if (isConfirmed) {
          this.isCloseAppRequested = true;
          const isDatabaseConnected = await firstValueFrom(this.store.select(getIsDatabaseConnected));
          if (isDatabaseConnected) {
            this.store.dispatch(checkIfVacuumNeededAndCloseFile());
          } else {
            this.quitElectronApp();
          }
        }
      });
    }

    return (event.returnValue = '');
  }

  private checkClosingAllowedShowAlertIfNeeded(): boolean {
    if (this.areAnyCalcJobsInProgress && !this.isStopCalcsBeforeClosingAlertShown) {
      this.modalService
        .showAlert('Please stop all calculations before closing the app', '', 'sm', false)
        .then(() => (this.isStopCalcsBeforeClosingAlertShown = false));

      this.isStopCalcsBeforeClosingAlertShown = true;
    }

    return !this.areAnyCalcJobsInProgress && !this.isStopCalcsBeforeClosingAlertShown;
  }

  private async showCloseConfirmIfNotVisible(): Promise<boolean> {
    let result = false;

    if (!this.isCloseConfirmShown) {
      this.isCloseConfirmShown = true;
      result = await this.modalService.showConfirm('Are you sure you want to quit?', 'Close', undefined, undefined, undefined, 10000);
      this.isCloseConfirmShown = false;
    }

    return result;
  }

  private quitElectronApp(): void {
    this.electronService.appClose();
  }
}
