import { BasePptSectionGenerator, PptContext } from '../base-ppt-section-generator';
import { IUnitSystemDto } from '@dunefront/common/dto/unit-system.dto';
import { firstValueFrom } from 'rxjs';
import { selectCurrentUnitSystem } from '../../../../../../+store/units/units.selectors';
import { CommonReportDataHelpers } from '../../common/common-report-data-helpers';
import { PptDocumentTableGeneratorHelper } from '../ppt-document-table-generator.helper';
import { getCompletionModuleState } from '../../../../../../+store/completion/completion.selectors';
import { CompletionModuleState } from '@dunefront/common/modules/completion/completion-module.state';
import { ReportShuntTube } from '../../doc/sections/completion-report-generator.helper';
import {
  nozzleGridConfig,
  shroudGridConfig,
  shuntTubesSystemGridConfig,
  tubeGridConfig,
} from '../../common/grid-configs/completion-shunt-tubes';
import { NozzleProperties, Shroud, Tube } from '@dunefront/common/modules/shunt-tube/shunt-tube';

export class ShuntTubeDataPptGenerator extends BasePptSectionGenerator {
  constructor(context: PptContext) {
    super(context);
  }

  public async generate(): Promise<void> {
    const currentUnitSystem: IUnitSystemDto = await firstValueFrom(this.store.select(selectCurrentUnitSystem));
    const completionState: CompletionModuleState = await firstValueFrom(this.store.select(getCompletionModuleState));

    // system configuration
    {
      const title = 'Shunt Tube - System Configuration';
      const { keyValueData, enumItemMap } = CommonReportDataHelpers.createShuntTubeSystemConfigurationKeyValueData(completionState);
      const tableRows = PptDocumentTableGeneratorHelper.createGenericKeyValueTable<ReportShuntTube>(
        shuntTubesSystemGridConfig,
        keyValueData,
        currentUnitSystem,
        enumItemMap,
      );

      this.addKeyValueTableSlide({ title, tableRows });
    }

    // transport tube properties
    if (completionState.ShuntTube.IsTransportTubePresent) {
      const title = 'Shunt Tube - Transport Tube Properties';
      const tableRows = PptDocumentTableGeneratorHelper.createGenericKeyValueTable<Tube>(
        tubeGridConfig(completionState.ShuntTube.Shape),
        completionState.ShuntTube.TransportTube,
        currentUnitSystem,
        null,
      );

      this.addKeyValueTableSlide({ title, tableRows });
    }

    // packing tube properties
    {
      const title = 'Shunt Tube - Packing Tube Properties';
      const tableRows = PptDocumentTableGeneratorHelper.createGenericKeyValueTable<Tube>(
        tubeGridConfig(completionState.ShuntTube.Shape),
        completionState.ShuntTube.PackingTube,
        currentUnitSystem,
        null,
      );

      this.addKeyValueTableSlide({ title, tableRows });
    }

    // nozzle properties
    if (completionState.ShuntTube.NozzleProperties.IsNozzleLeakoff) {
      const title = 'Shunt Tube - Nozzle Properties';
      const tableRows = PptDocumentTableGeneratorHelper.createGenericKeyValueTable<NozzleProperties>(
        nozzleGridConfig,
        completionState.ShuntTube.NozzleProperties,
        currentUnitSystem,
        null,
      );

      this.addKeyValueTableSlide({ title, tableRows });
    }

    // shroud properties
    if (completionState.ShuntTube.Shroud.IsShroudPresent) {
      const title = 'Shunt Tube - Shroud Properties';
      const tableRows = PptDocumentTableGeneratorHelper.createGenericKeyValueTable<Shroud>(
        shroudGridConfig,
        completionState.ShuntTube.Shroud,
        currentUnitSystem,
        null,
      );

      this.addKeyValueTableSlide({ title, tableRows });
    }
  }
}
