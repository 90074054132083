import { IReportTableColumnConfig } from '../common-report-data-helpers';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { ColumnType } from '../../../../../components/grid/grid.interfaces';
import { ReferenceVariableCalculatorResultsDto } from '@dunefront/common/modules/reference-variables/dto/reference-variable-calculator-results.dto';

export const pumpingRefParamsProperties: IReportTableColumnConfig<ReferenceVariableCalculatorResultsDto>[] = [
  { colId: 'WellboreGravel', headerText: 'Wellbore Gravel:', unitSystem: UnitSystem.Mass, type: ColumnType.number },
  { colId: 'PerforationGravel', headerText: 'Perforation Gravel:', unitSystem: UnitSystem.Mass, type: ColumnType.number },
  { colId: 'GravelRequired', headerText: 'Gravel Required:', unitSystem: UnitSystem.Mass, type: ColumnType.number },
  { colId: 'GravelPumped', headerText: 'Gravel Pumped:', unitSystem: UnitSystem.Mass, type: ColumnType.number },
  { colId: 'ExcessGravel', headerText: 'Excess Pumped:', unitSystem: UnitSystem.None, type: ColumnType.number, overrideUnitSymbol: '%' },
  { colId: 'DisplacementToPacker', headerText: 'Flush Volume:', unitSystem: UnitSystem.Liquid_Volume, type: ColumnType.number },
];
