import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { BackendConnectionService } from '../../shared/backend-connection/backend-connection.service';
import { chooseUnitSystemAction, createUnitSystemAction, deleteUnitSystemAction, updateUnitSystemAction } from './units.actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import {
  DeleteUnitSystemAction,
  InsertUnitSystemAction,
  UnitsModuleName,
  UpdateUnitSystemAction,
} from '@dunefront/common/modules/units/units.actions';
import { BaseWsEffects } from '../base-ws.effects';
import { ModalService } from '../../common-modules/modals/modal.service';
import { Store } from '@ngrx/store';
import { ScenarioConstants } from '@dunefront/common/modules/scenario/scenario.dto';
import { deleteRowsSuccess, insertRowsSuccess, updateRowSuccess } from '../app.actions';

@Injectable()
export class UnitsEffects extends BaseWsEffects {
  constructor(actions$: Actions, wsService: BackendConnectionService, modalService: ModalService, store: Store) {
    super(actions$, wsService, UnitsModuleName, false, true, modalService, store);
  }

  public unitOperationSuccessAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(insertRowsSuccess, updateRowSuccess, deleteRowsSuccess),
      filter((action) => action.affectedRows.unitSystem?.currentUnitSystemId != null),
      map((action) => chooseUnitSystemAction({ unitSystemId: action.affectedRows.unitSystem?.currentUnitSystemId as number })),
    ),
  );

  public createUnitSystem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createUnitSystemAction),
      mergeMap((action) =>
        this.emitInsert(
          new InsertUnitSystemAction({ rows: [action.unitSystem], scenarioId: ScenarioConstants.AllScenarioId, shouldResetResults: false }),
        ),
      ),
    ),
  );

  public updateUnitSystem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateUnitSystemAction),
      mergeMap((action) =>
        this.emitUpdate(
          new UpdateUnitSystemAction({ rows: [action.unitSystem], scenarioId: ScenarioConstants.AllScenarioId, shouldResetResults: false }),
        ),
      ),
    ),
  );

  public deleteUnitSystem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteUnitSystemAction),
      mergeMap((action) => {
        const { sourceId, commonDbType } = action.unitSystem;
        return this.emitDelete(new DeleteUnitSystemAction({ sourceId, commonDbType }));
      }),
    ),
  );
}
