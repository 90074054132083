import { createReducer, on } from '@ngrx/store';
import { deleteRowsSuccess, insertRowsSuccess, loadScenarioDataSuccessAction, resetScenarioState, updateRowSuccess } from '../app.actions';
import { PumpingModuleReducerHelper } from './pumping.reducer.helper';
import { pumpingModuleInitialState, PumpingModuleState } from '@dunefront/common/modules/pumping/pumping-module.state';
import { createTableState } from '@dunefront/common/common/common-grid.interfaces';
import {
  clearEvaluateScheduleAction,
  deletePumpedFluidAndGravelRowsAction,
  insertPumpedFluidAndGravelRowsAction,
  updatePumpedFluidAndGravelRowsAction,
} from './pumping.actions';

export const pumpingModuleReducer = createReducer<PumpingModuleState>(
  pumpingModuleInitialState,
  on(resetScenarioState, (): PumpingModuleState => pumpingModuleInitialState),
  on(
    loadScenarioDataSuccessAction,
    (state, action): PumpingModuleState =>
      PumpingModuleReducerHelper.pumpingModuleLoaded(
        state,
        action.loadScenarioResponse.pumpingModule,
        action.loadScenarioResponse.scenarioId,
      ),
  ),

  on(insertRowsSuccess, (state, action): PumpingModuleState => PumpingModuleReducerHelper.onInsertRows(state, action)),
  on(updateRowSuccess, (state, action): PumpingModuleState => PumpingModuleReducerHelper.onUpdateRows(state, action)),
  on(deleteRowsSuccess, (state, action): PumpingModuleState => PumpingModuleReducerHelper.onDeleteRows(state, action)),
  on(
    insertPumpedFluidAndGravelRowsAction,
    updatePumpedFluidAndGravelRowsAction,
    deletePumpedFluidAndGravelRowsAction,
    clearEvaluateScheduleAction,
    (state): PumpingModuleState => ({ ...state, evaluationSchedule: createTableState([]) }),
  ),
);
