<app-page-panel header="Test Configuration">
  <div class="grid-form-container cols-4" *ngIf="selectedFluidData && temperatureSelect && selectedFluidData.fluid">
    <label>Test Type</label>
    <app-radio-group
      [source]="selectedFluidData.fluid"
      [key]="'RheometerTestType'"
      [items]="testTypeRadio"
      class="col-span-3"
      dataCy="rheometer-test-type-select"
      id="is_gravel_settling"
      (valueChanged)="emitFluidValue($event)"
    >
    </app-radio-group>

    <label>Temperature</label>
    <div class="group margin-right">
      <app-select
        *ngIf="selectedFluidData.rheometer.rowData"
        [items]="temperatureSelect"
        [value]="selectedFluidData.rheometer.rowData.Id"
        [width]="100"
        dataCy="rheometer-temperature-select"
        [isUiLockable]="false"
        [decimalPlaces]="2"
        (primitiveValueChanged)="onSelectedTemperatureChange($event.value)"
      >
      </app-select>
      <span class="temp-unit">{{ temperatureSelectLabel }}</span>
    </div>

    <label>Rotor-Bob Combination</label>
    <div class="group">
      <app-select
        [source]="selectedFluidData.rheometer.rowData"
        [key]="'RotorBobCombination'"
        [items]="rotorBobSelect"
        [width]="100"
        class="margin-right"
        [disabled]="selectedFluidData.fluid.RheometerTestType === RheometerTestType.Shear_Rate_Viscosity"
        (valueChanged)="emitRheometerValue($event)"
      >
      </app-select>

      <app-form-input [disabled]="true" [readOnlyValue]="rotorBobValue" [decimalPlaces]="3" [width]="120" [unitType]="UnitType.None">
      </app-form-input>
    </div>

    <label>Speed Type</label>
    <app-select
      [source]="selectedFluidData.rheometer.rowData"
      [key]="'SpeedType'"
      [items]="speedTypeSelect"
      [width]="100"
      dataCy="rheometer-speed-type-select"
      [disabled]="selectedFluidData.fluid.RheometerTestType === RheometerTestType.Shear_Rate_Viscosity"
      (valueChanged)="emitRheometerValue($event)"
    >
    </app-select>

    <label>Torsion Spring Assembly</label>
    <div class="group">
      <app-select
        [source]="selectedFluidData.rheometer.rowData"
        [key]="'TorsionSpringAssembly'"
        [items]="torsionSpringSelect"
        [disableSorting]="true"
        [width]="100"
        [disabled]="selectedFluidData.fluid.RheometerTestType === RheometerTestType.Shear_Rate_Viscosity"
        class="margin-right"
        (valueChanged)="emitRheometerValue($event)"
      >
      </app-select>

      <app-form-input [disabled]="true" [readOnlyValue]="torsionSpringValue" [decimalPlaces]="3" [width]="120" [unitType]="UnitType.None">
      </app-form-input>
    </div>
  </div>
</app-page-panel>
