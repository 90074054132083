import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { IFile } from '@dunefront/common/dto/file.dto';
import { ModalService } from '../../../../common-modules/modals/modal.service';
import { getRecentFilesState } from '../../../../+store/file-manager/file-manager.selectors';
import { FileUrlHelper } from '../../file-url.helper';

@Component({
  selector: 'app-recent-files',
  templateUrl: 'recent-files.component.html',
  styleUrls: ['./recent-files.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecentFilesComponent {
  public recentFilesState$ = this.store.select(getRecentFilesState);

  constructor(
    private store: Store,
    private router: Router,
    private modalService: ModalService,
  ) {}

  public recentFolderClick(recentFolderDto: IFile): void {
    FileUrlHelper.fileNavigate(recentFolderDto, this.router, this.modalService);
  }
}
