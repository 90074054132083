import { WsAction, UpdateRowsWsAction } from '../../ws.action';
import { ReportInfoDto } from '../../dto/report-info.dto';

export const ReportInfoModuleName = 'ReportInfoModule';

export enum ReportInfoModuleActionTypes {
  Load = '[ReportInfoModule] Load',
  Update = '[ReportInfoModule] Update',
}

export class UpdateReportInfoAction extends UpdateRowsWsAction<ReportInfoDto> {
  public override readonly type = ReportInfoModuleActionTypes.Update;
}

export class LoadReportInfoAction extends WsAction {
  public override readonly type = ReportInfoModuleActionTypes.Load;

  constructor(public scenarioId: number) {
    super();
  }
}

export type ReportInfoModuleActions = UpdateReportInfoAction | LoadReportInfoAction;
