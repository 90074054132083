import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ITableRow } from '@dunefront/common/common/common-grid.interfaces';
import { EnumHelpers } from '@dunefront/common/utils/enum.helpers';
import { LossZoneType } from '@dunefront/common/modules/well/dto/zone/zone.dto';
import { WellModuleState } from '@dunefront/common/modules/well/well-module.state';
import { TreatmentType } from '@dunefront/common/modules/well/dto/well.dto';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { ZoneModel } from '@dunefront/common/modules/well/model/zone/zone.model';
import { changeProp, ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';
import { IUpdateTableRowsProps, StoreCrudPropsFactory } from '@dunefront/common/common/common-store-crud.interfaces';

@Component({
  selector: 'app-zone-properties',
  templateUrl: './zone-properties.component.html',
  styleUrls: ['./zone-properties.component.scss'],
})
export class ZonePropertiesComponent {
  @Input() public zoneRow: ITableRow<ZoneModel> | undefined;

  @Input() public well: WellModuleState | undefined;

  public get isLossRateCalculated(): boolean {
    return this.well?.IsLossRateCalculated || false;
  }

  @Output() public rowUpdated = new EventEmitter<IUpdateTableRowsProps<ZoneModel>>();
  public lossZoneTypeItems = EnumHelpers.EnumToISelectItemArray(LossZoneType);
  public UnitType = UnitSystem;
  public LossZoneType = LossZoneType;
  public TreatmentType = TreatmentType;

  public onValueChange(props: ObjectChangeProp<ZoneModel>): void {
    if (this.zoneRow == null) {
      return;
    }
    const newZoneRow: ITableRow<ZoneModel> = {
      ...this.zoneRow,
      rowData: changeProp(this.zoneRow.rowData, props),
    };
    this.rowUpdated.emit(StoreCrudPropsFactory.updateTableRows([newZoneRow], props));
  }
}
