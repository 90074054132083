import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { copyFileReturnList, loadAllFilesAndFoldersSuccess } from '../../../../+store/file-manager/file-manager.actions';
import { BaseFileActionDialogComponent } from '../base-file-action.dialog.component';
import { Store } from '@ngrx/store';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Actions, ofType } from '@ngrx/effects';
import { FileErrorHelper } from '../file-error.helper';
import {
  CY_BUTTON_OK,
  defaultModalCancelButton,
  defaultModalConfirmButton,
  ModalButtonConfig,
} from '../../../../common-modules/modals/generic-modal/generic-modal.component';

@Component({
  selector: 'app-share-dialog',
  templateUrl: './share.dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShareDialogComponent extends BaseFileActionDialogComponent implements OnInit {
  public modalTitle = 'Share File';

  public modalButtonsConfigs: ModalButtonConfig[] = [
    defaultModalCancelButton((): void => this.cancelClicked(), 'Cancel', 'button-cancel'),
    defaultModalConfirmButton((): void => this.okClicked(), 'OK', CY_BUTTON_OK, !!this.fileName.error.targetName),
  ];

  constructor(activeModal: DynamicDialogRef, config: DynamicDialogConfig, store: Store, cdRef: ChangeDetectorRef, actions$: Actions) {
    super(activeModal, config, store, cdRef);
    this.file = config.data.file;
    this.subscription.add(actions$.pipe(ofType(loadAllFilesAndFoldersSuccess)).subscribe((_) => this.activeModal.close()));
  }

  public ngOnInit(): void {
    this.fileName = { ...this.fileName, targetName: this.file.Name.trim() };
    this.validateShareAction();
  }

  public okClicked(): void {
    this.store.dispatch(
      copyFileReturnList({
        source: this.file,
        target: { Repository: 'shared', Name: this.file.Name, Folder: [], FileType: this.file.FileType },
      }),
    );
  }

  private validateShareAction(): void {
    const fileNameList = this.getNamesForValidation('shared', [], this.file.FileType);
    this.fileName = FileErrorHelper.validateFileFolderName(this.fileName, fileNameList);
    this.errorMessage = this.fileName.error.targetName;
    const okButton = this.modalButtonsConfigs.find((conf) => conf.dataCy === CY_BUTTON_OK);
    if (okButton) {
      okButton.disabled = !!this.errorMessage;
    }
  }
}
