import { Routes } from '@angular/router';
import { DatabaseFluidsComponent } from './database-fluids/database-fluids.component';
import { RheometerDataComponent } from './rheometer-data/rheometer-data.component';
import { ProjectFluidsComponent } from './project-fluids/project-fluids.component';
import { FluidsComponent } from './fluids.component';
import {
  RouteModuleFluids,
  RouteModuleFluidsAdvancedProperties,
  RouteModuleFluidsDatabaseFluids,
  RouteModuleFluidsProjectFluids,
  RouteModuleFluidsRheometerData,
} from './fluids-routes-names';
import { FluidAdvancedPropertiesComponent } from './fluid-advanced-properties/fluid-advanced-properties.component';

export const fluidsRoutes: Routes = [
  {
    path: RouteModuleFluids,
    component: FluidsComponent,
    data: {
      breadcrumb: 'Fluids',
    },
    children: [
      {
        path: `${RouteModuleFluidsProjectFluids}/:fluidId`,
        component: ProjectFluidsComponent,
        data: {
          breadcrumb: 'Project Fluids',
        },
      },
      {
        path: RouteModuleFluidsProjectFluids,
        redirectTo: `${RouteModuleFluidsProjectFluids}/`,
        pathMatch: 'full',
      },
      {
        path: `${RouteModuleFluidsAdvancedProperties}/:fluidId`,
        component: FluidAdvancedPropertiesComponent,
        data: {
          breadcrumb: 'Advanced Fluid Properties',
        },
      },
      {
        path: RouteModuleFluidsAdvancedProperties,
        redirectTo: `${RouteModuleFluidsAdvancedProperties}/`,
        pathMatch: 'full',
      },
      {
        path: `${RouteModuleFluidsRheometerData}/:fluidId/:temperatureId`,
        component: RheometerDataComponent,
        data: {
          breadcrumb: 'Rheometer Data',
        },
      },
      {
        path: `${RouteModuleFluidsRheometerData}/:fluidId`,
        redirectTo: `${RouteModuleFluidsProjectFluids}/:fluidId/`,
        pathMatch: 'full',
      },
      {
        path: RouteModuleFluidsDatabaseFluids,
        component: DatabaseFluidsComponent,
        data: {
          breadcrumb: 'Database Fluids',
        },
      },
      {
        path: '',
        redirectTo: RouteModuleFluidsProjectFluids,
        pathMatch: 'full',
      },
    ],
  },
];
