import { createTableRow, GridRowType, ITableRow } from '@dunefront/common/common/common-grid.interfaces';
import { ILoadCommonGravelsActionResponse } from '@dunefront/common/modules/common-db/common-db.actions';
import { CommonGravelModel } from './common-gravel.model';
import { GravelDto } from '@dunefront/common/modules/gravel/dto/gravel.dto';
import { ArrayHelpers } from '@dunefront/common/common/array-helpers';
import { CommonDbType } from '@dunefront/common/dto/common-dto.interfaces';

export class CommonGravelFactory {
  public static createRowListFromDtos(response: ILoadCommonGravelsActionResponse): ITableRow<CommonGravelModel>[] {
    const rowArray: ITableRow<CommonGravelModel>[] = [];
    this.addLevelDtos(rowArray, response.personalCommonGravels, 'User');
    this.addLevelDtos(rowArray, response.orgCommonGravels, 'Organization');
    this.addLevelDtos(rowArray, response.predefinedCommonGravels, 'Predefined');
    return rowArray;
  }

  public static addLevelDtos(rowArray: ITableRow<CommonGravelModel>[], gravels: GravelDto[], level: CommonDbType): void {
    const currentCount = rowArray.length;
    [...gravels]
      .sort((a, b) => ArrayHelpers.stringCompare(a.Name, b.Name))
      .forEach((gravel, index) => {
        rowArray.push(this.createRowFromDto(gravel, level, currentCount + index));
      });
  }

  public static createRowFromDto(
    rowDto: GravelDto,
    level: CommonDbType,
    rowIndex: number,
    isEditingDisabled = true,
    rowType: GridRowType = 'data',
  ): ITableRow<CommonGravelModel> {
    if (rowIndex === 0) {
      rowType = 'first-row';
    }
    return createTableRow<CommonGravelModel>(this.createCommonGravel(rowDto, level), rowType, rowIndex, isEditingDisabled);
  }

  public static createCommonGravel(commonGravelDto: GravelDto, level: CommonDbType): CommonGravelModel {
    const commonGravel: CommonGravelModel = {
      ...commonGravelDto,
      commonDbType: level,
    };
    return commonGravel;
  }
}
