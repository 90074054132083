<app-generic-modal  [title]="modalTitle" [modalButtonsConfigs]="modalButtonsConfigs" [showHelpBtn]="true" (helpClick)="isHelpOpen = $event">
  <div class="modal-content-container" data-cy="calculation-queue-main-container">
    <app-page-panel header="Calculations" [allowFullScreen]="false">
      <div class="scrollable-content-container">
        <ng-container *ngIf="allJobs$ | async as jobs else noCalculations">
          <table *ngIf="jobs.length > 0 else noCalculations"
                 class="table table-sm table-bordered"
                 data-cy="calculation-queue-table">
            <thead>
            <tr>
              <th></th>
              <th>File</th>
              <th>Scenario</th>
              <th style="width: 100px">Module</th>
              <th style="width: 100px">Status</th>
              <th style="width: 120px">Actions</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let job of jobs; index as index;" [ngClass]="{alternate: index % 2 === 0,}">
              <td></td>
              <td>{{ fileName(job.dbFile) }}</td>
              <td>{{ scenarioName(job.scenarioId) }}</td>
              <td class="centered">{{ moduleName(job.moduleType) }}</td>
              <td class="centered">{{ statusAsString(job) }}</td>
              <td class="centered no-wrap">
                <button
                  appButton
                  type="button"
                  class="btn btn-cancel btn-sm"
                  [attr.data-cy]="job.status === CalculationStatus.running ? 'calculation-queue-stop-btn' : 'calculation-queue-cancel-btn'"
                  [isUiLockable]="false"
                  [isDisabledWhenCalculationActive]="false"
                  [disabled]="isActionButtonDisabled(job)"
                  (buttonClick)="onCancelJob(job)">
                  {{ job.status === CalculationStatus.running ? 'Stop' : 'Cancel' }}
                </button>
                <button *ngIf="isGoToAvailable(job)"
                        appButton
                        type="button"
                        class="btn btn-cancel btn-sm with-margin-left"
                        data-cy="calculation-queue-goto-btn"
                        [isUiLockable]="false"
                        [isDisabledWhenCalculationActive]="false"
                        [disabled]="isActionButtonDisabled(job)"
                        (buttonClick)="onGoTo(job)">
                  Open
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </ng-container>
        <ng-template #noCalculations>
          <div class="no-calculations-container" data-cy="no-calculations-container">
            No calculations
          </div>
        </ng-template>
      </div>
    </app-page-panel>
  </div>
</app-generic-modal>
<div class="modal-help" [class.open]="isHelpOpen">
  <div class="help-content">
    <iframe [src]="'assets/help-files/top-bar/calculation-queue.html' | helpUrl"></iframe>
  </div>
</div>
