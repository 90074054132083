import { ColumnType, IGridColumnConfig } from '../../../../../shared/components/grid/grid.interfaces';
import { Pipe } from '@dunefront/common/modules/pipes/pipe';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';

const PIPE_TYPES_LOOKUP_SRC_ID = 'PIPE_TYPES';

export const runningStringGridConfig: IGridColumnConfig<Pipe>[] = [
  { disabled: false, visible: true, colId: ' ', type: ColumnType.selection },
  {
    disabled: false,
    visible: true,
    colId: 'PipeType',
    headerText: 'Component',
    lookupDataSourceType: PIPE_TYPES_LOOKUP_SRC_ID,
    type: ColumnType.select,
    width: 180,
    isFillDownDisabled: true,
    matchingStrings: ['component', 'element', 'pipe'],
  },
  {
    disabled: false,
    visible: true,
    colId: 'TopMD',
    headerText: 'Top MD',
    unitSystem: UnitSystem.Long_Length,
    type: ColumnType.number,
    matchingStrings: ['top'],
  },
  {
    disabled: false,
    visible: true,
    colId: 'BottomMD',
    headerText: 'Bottom MD',
    unitSystem: UnitSystem.Long_Length,
    type: ColumnType.number,
    matchingStrings: ['bottom'],
  },
  {
    disabled: true,
    visible: true,
    colId: 'Length',
    headerText: 'Length',
    unitSystem: UnitSystem.Long_Length,
    type: ColumnType.number,
  },
  {
    disabled: false,
    visible: true,
    colId: 'OuterDiameter',
    headerText: 'OD',
    unitSystem: UnitSystem.Diameter,
    type: ColumnType.number,
    decimalPlaces: 3,
    matchingStrings: ['outer', 'od', 'o.d', 'o. d'],
  },
  {
    disabled: false,
    visible: true,
    colId: 'InnerDiameter',
    headerText: 'ID',
    unitSystem: UnitSystem.Diameter,
    type: ColumnType.number,
    decimalPlaces: 3,
    matchingStrings: ['inner', 'id', 'i.d', 'i. d'],
  },
  {
    disabled: false,
    visible: true,
    colId: 'Weight',
    headerText: 'Weight',
    unitSystem: UnitSystem.Linear_Mass,
    type: ColumnType.number,
    matchingStrings: ['weight', 'mass'],
  },
  {
    disabled: false,
    visible: true,
    colId: 'Description',
    type: ColumnType.text,
    headerText: 'Description',
    maxLength: 100,
    isNotUiLockable: true,
    align: 'left',
    matchingStrings: ['desc', 'info'],
  },
];
