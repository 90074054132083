import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ITableRow } from '@dunefront/common/common/common-grid.interfaces';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { PressureAttenuatorPipe } from '@dunefront/common/modules/pipes/running-string-pipes/pipes/pressure-attenuator-pipe';
import { changeProp, ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';
import { IUpdateTableRowsProps, StoreCrudPropsFactory } from '@dunefront/common/common/common-store-crud.interfaces';

@Component({
  selector: 'app-pressure-attenuator-properties',
  templateUrl: './pressure-attenuator-properties.component.html',
  styleUrls: ['./pressure-attenuator-properties.component.css'],
})
export class PressureAttenuatorPropertiesComponent {
  @Input() public pipe!: ITableRow<PressureAttenuatorPipe>;
  @Output() public rowUpdated = new EventEmitter<IUpdateTableRowsProps<PressureAttenuatorPipe>>();

  public UnitType = UnitSystem;

  public onValueChange(event: ObjectChangeProp<PressureAttenuatorPipe>): void {
    const newRow: ITableRow<PressureAttenuatorPipe> = {
      ...this.pipe,
      rowData: changeProp(this.pipe.rowData, event),
    };
    this.rowUpdated.emit(StoreCrudPropsFactory.updateTableRows([newRow], event));
  }
}
