import * as ElectronRenderer from 'electron/renderer';
import { isElectron } from '@dunefront/common/common/electron/is-electron';
import { ELECTRON_ACTION_APP_CLOSE } from '@dunefront/common/common/electron/electron.actions';

export class ElectronService {
  private _instance: null | typeof ElectronRenderer = null;

  public get instance(): null | typeof ElectronRenderer {
    if (!this.isElectronApp) {
      return null;
    }

    if (this._instance == null) {
      if (window != null && window.require != null) {
        this._instance = window.require('electron');
        return this._instance;
      }
      return null;
    }
    return this._instance;
  }

  /**
   * determines if SPA is running in ElectronRenderer
   */
  public get isElectronApp(): boolean {
    return isElectron();
  }

  public get isMacOS(): boolean {
    return this.isElectronApp && process.platform === 'darwin';
  }

  public get isWindows(): boolean {
    return this.isElectronApp && process.platform === 'win32';
  }

  public get isLinux(): boolean {
    return this.isElectronApp && process.platform === 'linux';
  }

  public get isX86(): boolean {
    return this.isElectronApp && process.arch === 'ia32';
  }

  public get isX64(): boolean {
    return this.isElectronApp && process.arch === 'x64';
  }

  public get isArm(): boolean {
    return this.isElectronApp && process.arch === 'arm';
  }

  public appClose(): void {
    this.instance?.ipcRenderer.send(ELECTRON_ACTION_APP_CLOSE);
  }
}
