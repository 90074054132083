export class Validation {
  public static UICheckNumberValidity(parameter: number, allowNegativeValue = false): number {
    if (Number.isNaN(parameter) || !Number.isFinite(parameter)) {
      return 0;
    }
    if (!allowNegativeValue && parameter < 0) {
      return 0;
    }
    return parameter;
  }

  public static ValidateFluid(fluidId: number | null): string {
    if (fluidId == null || fluidId <= 0) {
      return 'Fluid must be selected';
    }
    return '';
  }

  public static ValidateGravel(gravelId: number | null): string {
    if (gravelId === null || gravelId < 0) {
      return 'Gravel must be selected';
    }
    return '';
  }
}
