import { LoadSettingsActionResponse } from '@dunefront/common/modules/settings/settings-module.actions';
import { settingsInitialState, SettingsModuleState } from '@dunefront/common/modules/settings/settings-module.state';
import { CrudResponse } from '@dunefront/common/modules/common.actions';
import { SettingsFactory } from '@dunefront/common/modules/settings/model/settings.factory';
import { SettingsDto } from '@dunefront/common/modules/settings/dto/settingsDto';
import { changeProp, IModuleState, ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';
import { ActionResponse } from '@dunefront/common/response-ws.action';

export class SettingsModuleReducerHelper {
  public static onLoadScenarioDataSuccessAction(
    state: SettingsModuleState,
    response: ActionResponse<LoadSettingsActionResponse>,
  ): SettingsModuleState {
    if (response.status !== 'ok' || !response.payload) {
      return settingsInitialState;
    }

    return SettingsFactory.create(response.payload);
  }

  public static changeSettingsProperty(
    state: SettingsModuleState,
    changedProp: ObjectChangeProp<SettingsDto & IModuleState>,
  ): SettingsModuleState {
    return { ...state, settings: changeProp(state.settings, changedProp) };
  }

  public static onUpdateSuccess(state: SettingsModuleState, response: CrudResponse): SettingsModuleState {
    if (response == null || !response.affectedRows.settings) {
      return state;
    }
    return { ...state, settings: { ...response.affectedRows.settings, isLoaded: true } };
  }
}
