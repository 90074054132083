import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ModalService } from '../../../common-modules/modals/modal.service';
import { HelpModalComponent } from '../../../common-modules/modals/help/help-modal.component';

export enum PanelHelpMode {
  CHART = 0,
  GRID = 1,
}

@Component({
  selector: 'app-help-button',
  standalone: true,
  templateUrl: './help-buton.component.html',
  styleUrls: ['./help-buton.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HelpButtonComponent {
  @Input() public helpMode?: PanelHelpMode;

  constructor(private modal: ModalService) {}

  public openHelp(): void {
    if (this.helpMode == null) {
      return;
    }
    this.modal.open(HelpModalComponent, { mode: this.helpMode });
  }
}
