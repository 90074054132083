import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { getStorageColumnNames } from '../../../+store/data-storage/data-storage.selectors';
import { ImportDataHelper } from '../../../shared/helpers/import-data/import-data-helper';

@Injectable()
export class ColumnNameGeneratorService {
  public colNames: string[] = [];
  private _originalColNames: string[] = [];

  constructor(private store: Store) {
    store.select(getStorageColumnNames).subscribe((colNames) => {
      this._originalColNames = colNames.map((col) => col.name);
      this.resetColNames();
    });
  }

  public resetColNames(): void {
    this.colNames = [...this._originalColNames];
  }

  public getColName(colName: string, colIndex: number, currentColumnNames: string[] = []): string {
    return ImportDataHelper.getUniqueColName(colName, this.colNames, colIndex, currentColumnNames);
  }

  // generates array of column names with prefix Column#
  public generateGenericColumnNames(foundColumnsLength: number): string[] {
    const colNames: string[] = [];
    for (let i = 1; i <= foundColumnsLength; i++) {
      colNames.push(this.getColName('', +i - 1));
    }
    return colNames;
  }
}
