import { Pipe } from '../pipe';
import { isScreenPipe } from './pipes/screen-pipe';
import { ArrayHelpers } from '../../../common/array-helpers';

export class LowerCompletionCalculations {
  //Method to calculate the Top of screens MD
  public static ScreenTopMD(lowerCompletion: Pipe[]): number {
    //Find the top of the first screen
    if (lowerCompletion === null || lowerCompletion.length === 0) {
      //There are no components in the list so return zero
      return 0;
    } else {
      const permanentCompletion = lowerCompletion.find((pipe) => isScreenPipe(pipe));
      return permanentCompletion ? permanentCompletion.TopMD : 0;
    }
  }

  //Method to calculate the Bottom of screens MD
  public static ScreenBottomMD(lowerCompletions: Pipe[]): number {
    //Find the bottom of the last screen
    if (lowerCompletions === null || lowerCompletions.length === 0) {
      //There are no components in the list so return zero
      return 0;
    }
    const permanentCompletion = ArrayHelpers.findLast(lowerCompletions, (pipe) => isScreenPipe(pipe));
    return permanentCompletion ? permanentCompletion.BottomMD : 0;
  }
}
