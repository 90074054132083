import { createReducer, on } from '@ngrx/store';
import {
  completionInitialState,
  CompletionModuleState,
  emptyVolumeSectionCalculatorResult,
} from '@dunefront/common/modules/completion/completion-module.state';
import { deleteRowsSuccess, insertRowsSuccess, loadScenarioDataSuccessAction, resetScenarioState, updateRowSuccess } from '../app.actions';
import * as actions from './completion.actions';
import { resetVolumeCalculatorResults } from './completion.actions';
import { CompletionModuleReducerHelper } from './completion.reducer.helper';

export const CompletionModuleFeatureName = 'completion';

export const completionModuleReducer = createReducer<CompletionModuleState>(
  completionInitialState,
  on(resetScenarioState, (): CompletionModuleState => completionInitialState),
  on(
    loadScenarioDataSuccessAction,
    (state, action): CompletionModuleState =>
      CompletionModuleReducerHelper.onLoadScenarioDataSuccessAction(
        state,
        action.loadScenarioResponse.completionModule,
        action.loadScenarioResponse.scenarioId,
      ),
  ),
  on(updateRowSuccess, (state, action): CompletionModuleState => CompletionModuleReducerHelper.updateCompletionSuccess(state, action)),
  on(
    insertRowsSuccess,
    (state, action): CompletionModuleState => CompletionModuleReducerHelper.insertLowerCompletionRowSuccess(state, action),
  ),
  on(insertRowsSuccess, (state, action): CompletionModuleState => CompletionModuleReducerHelper.insertIcdPortDataRowSuccess(state, action)),
  on(
    insertRowsSuccess,
    (state, action): CompletionModuleState => CompletionModuleReducerHelper.insertRunningStringRowSuccess(state, action),
  ),
  on(
    actions.updateRunningStringRow,
    (state, action): CompletionModuleState => CompletionModuleReducerHelper.updateRunningStringRow(state, action),
  ),
  on(
    deleteRowsSuccess,
    (state, action): CompletionModuleState => CompletionModuleReducerHelper.deleteLowerCompletionRowsSuccess(state, action),
  ),
  on(
    updateRowSuccess,
    (state, action): CompletionModuleState => CompletionModuleReducerHelper.updateLowerCompletionRowSuccess(state, action),
  ),
  on(
    deleteRowsSuccess,
    (state, action): CompletionModuleState => CompletionModuleReducerHelper.deleteIcdPortDataRowsSuccess(state, action),
  ),
  on(updateRowSuccess, (state, action): CompletionModuleState => CompletionModuleReducerHelper.updateIcdPortDataRowSuccess(state, action)),
  on(
    deleteRowsSuccess,
    (state, action): CompletionModuleState => CompletionModuleReducerHelper.deleteRunningStringRowsSuccess(state, action),
  ),
  on(
    updateRowSuccess,
    (state, action): CompletionModuleState => CompletionModuleReducerHelper.updateRunningStringRowSuccess(state, action),
  ),
  on(
    actions.changeCompletionProperty,
    (state, action): CompletionModuleState => CompletionModuleReducerHelper.changeCompletionProperty(state, action),
  ),
  on(
    actions.changeShuntTubeProperty,
    (state, action): CompletionModuleState => CompletionModuleReducerHelper.changeShuntTubeProperty(state, action),
  ),
  on(updateRowSuccess, (state, action): CompletionModuleState => CompletionModuleReducerHelper.onUpdateShuntTubeSuccess(state, action)),
  on(
    actions.calculateVolumesSectionAction,
    (state, action): CompletionModuleState => CompletionModuleReducerHelper.onCalculateVolumesSectionAction(state, action),
  ),
  on(
    actions.calculateVolumesSectionAbortedAction,
    (state, action): CompletionModuleState => CompletionModuleReducerHelper.onCalculateVolumesSectionAbortedAction(state, action),
  ),
  on(
    updateRowSuccess,
    (state, action): CompletionModuleState => CompletionModuleReducerHelper.onVolumeSectionCalculatorResultSuccess(state, action),
  ),
  on(
    updateRowSuccess,
    (state, action): CompletionModuleState => CompletionModuleReducerHelper.onVolumeGaugeSectionInformationResultSuccess(state, action),
  ),
  on(
    resetVolumeCalculatorResults,
    (state): CompletionModuleState => ({
      ...state,
      VolumeSectionCalculatorResult: emptyVolumeSectionCalculatorResult(),
    }),
  ),
);
