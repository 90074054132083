import { createEmptySummaryDto, SummaryDto } from '@dunefront/common/dto/summary.dto';
import { createReducer, on } from '@ngrx/store';
import { loadSummaryModuleAction, loadSummaryModuleSuccessAction } from './summary.actions';
import { SummaryReducerHelper } from './summary.reducer.helper';
import { createTableState, ITableState } from '@dunefront/common/common/common-grid.interfaces';
import { WarningMessage } from '@dunefront/common/modules/summary/warning-message.factory';

export const SummaryModuleFeatureName = 'summary';

export interface SummaryModuleState {
  summary?: SummaryDto;
  warningMessages: ITableState<WarningMessage>;
  isLoaded: boolean;
}

const initialState: SummaryModuleState = {
  summary: createEmptySummaryDto(),
  warningMessages: createTableState([]),
  isLoaded: false,
};

export const summaryModuleReducer = createReducer<SummaryModuleState>(
  initialState,
  on(loadSummaryModuleAction, (state): SummaryModuleState => ({ ...state, isLoaded: false })),
  on(loadSummaryModuleSuccessAction, (state, action): SummaryModuleState => SummaryReducerHelper.loadSummaryModule(state, action)),
);
