import { ColumnType, IGridColumnConfig } from '../../../../../shared/components/grid/grid.interfaces';
import { SectionInformationRow } from '@dunefront/common/modules/completion/model/volumes/section-information-row';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';

export const gaugeSectionInfoGridConfig: IGridColumnConfig<SectionInformationRow>[] = [
  { disabled: true, visible: true, colId: ' ', type: ColumnType.selection },
  {
    disabled: true,
    visible: true,
    colId: 'Name',
    headerText: 'Gauge Section',
    width: 230,
    type: ColumnType.text,
    align: 'left',
  },
  { disabled: true, visible: true, colId: 'Length', unitSystem: UnitSystem.Long_Length, type: ColumnType.number },
  {
    disabled: true,
    visible: true,
    colId: 'InnerVolume',
    headerText: 'Inner Volume',
    unitSystem: UnitSystem.Liquid_Volume,
    type: ColumnType.number,
  },
  {
    disabled: true,
    visible: true,
    colId: 'OuterVolume',
    headerText: 'Outer Volume',
    unitSystem: UnitSystem.Liquid_Volume,
    type: ColumnType.number,
  },
  {
    disabled: true,
    visible: true,
    colId: 'SuspendedGravelMass',
    type: ColumnType.number,
    headerText: 'Suspended Gravel Mass',
    unitSystem: UnitSystem.Mass,
  },
  {
    disabled: true,
    visible: true,
    colId: 'PackedGravelMass',
    type: ColumnType.number,
    headerText: 'Packed Gravel Mass',
    unitSystem: UnitSystem.Mass,
  },
  {
    disabled: true,
    visible: true,
    colId: 'VolumeToSectionStart',
    type: ColumnType.number,
    headerText: 'Volume To Section Start',
    unitSystem: UnitSystem.Liquid_Volume,
  },
];
