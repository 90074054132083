<app-chart-controller
  *ngIf="displayChart; else defaultMessage"
  [chartState]="chartState"
  [hiddenColumns]="hiddenColumns"
  [chartId]="chartId"
  [chartDisplayName]="chartName"
  [drawableProviderId]="drawableProviderId"
  [reverseArgument]="true"
  [dataCy]="'consolidated-psd-chart'"
  (hiddenColumnsChange)="onHiddenColumnsChanged($event)"
  [tension]="0.2"
>
</app-chart-controller>
<ng-template #defaultMessage>
  <div class="error">Select at least one PSD to display the chart.</div>
</ng-template>
