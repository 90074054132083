import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { GenericOptionsComponent } from '../generic-options.component';
import { Store } from '@ngrx/store';
import { ISelectItem } from '@dunefront/common/common/select.helpers';

@Component({
  selector: 'app-chart-video-performance-options',
  templateUrl: './chart-video-performance-options.component.html',
  styleUrls: ['./chart-video-performance-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartVideoPerformanceOptionsComponent extends GenericOptionsComponent {
  public videoFramerates: ISelectItem<number>[] = [
    { value: 15, text: '15 fps' },
    { value: 24, text: '24 fps' },
    { value: 30, text: '30 fps' },
    { value: 60, text: '60 fps' },
  ];

  constructor(store: Store, cdRef: ChangeDetectorRef) {
    super(store, cdRef);
  }
}
