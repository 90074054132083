import { Injectable } from '@angular/core';
import { BaseWsEffects } from '../../base-ws.effects';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BackendConnectionService } from '../../../shared/backend-connection/backend-connection.service';
import { mergeMap } from 'rxjs/operators';
import {
  CalculateInjectionTestAction,
  InjectionTestCalculatorModuleName,
  InjectionTestDeleteRowsAction,
  InjectionTestInsertRowsAction,
  InjectionTestUpdateRowAction,
  UpdateInjectionTestCalculatorAction,
} from '@dunefront/common/modules/calculators/injection-test-calculator/injection-test-calculator-module.action';
import * as actions from './injection-test-calculator.actions';
import { ModalService } from '../../../common-modules/modals/modal.service';
import { WsActionPropsFactory } from '@dunefront/common/common/ws-action/ws-action-props.factory';
import { InjectionTestReadingFactory } from '@dunefront/common/modules/calculators/injection-test-calculator/model/injection-test-reading.factory';

@Injectable()
export class InjectionTestCalculatorEffects extends BaseWsEffects {
  constructor(actions$: Actions, store: Store, wsService: BackendConnectionService, modalService: ModalService) {
    super(actions$, wsService, InjectionTestCalculatorModuleName, false, true, modalService, store);
  }

  public insertInjectionTestRow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.insertInjectionTestReadingsRowsAction),
      mergeMap((action) =>
        this.emitInsert(new InjectionTestInsertRowsAction(WsActionPropsFactory.insertAction(action, InjectionTestReadingFactory.toDto))),
      ),
    ),
  );

  public updateInjectionTestRow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.updateInjectionTestReadingsRowAction),
      mergeMap((action) => this.emitUpdate(new InjectionTestUpdateRowAction(action))),
    ),
  );

  public deleteInjectionTestRow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.deleteInjectionTestReadingsRowsAction),
      mergeMap((action) => this.emitDelete(new InjectionTestDeleteRowsAction(action))),
    ),
  );

  public calculateInjectionTest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.calculateInjectionTest),
      mergeMap((action) => this.emitUpdate(new CalculateInjectionTestAction(action.ScenarioId), 'Calculating Injection Test...')),
    ),
  );

  public updateInjectionTestCalculator$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.updateInjectionTestCalculatorRow),
      mergeMap((action) => this.emitUpdate(new UpdateInjectionTestCalculatorAction(action))),
    ),
  );
}
