import { UnitConverter } from '../../converter.interfaces';
import { SpecificHeatCapacityUnit } from '../../../dto/unit-system.dto';

export class SpecificHeatCapacityConverter extends UnitConverter {
  public static symbols: Array<string> = ['', 'J/kg·°C', 'Btu/lbs·°F'];

  public static unit = SpecificHeatCapacityUnit;

  public static fromSi(value: number, unit: SpecificHeatCapacityUnit): number {
    switch (unit) {
      case SpecificHeatCapacityUnit.Metric_specific_heat: {
        return value;
      }
      case SpecificHeatCapacityUnit.Oilfield_specific_heat: {
        return value * 0.0002388449577;
      }
      default: {
        throw new TypeError('Unknown Specific Heat Capacity Unit:' + unit);
      }
    }
  }

  public static toSi(value: number, unit: SpecificHeatCapacityUnit): number {
    switch (unit) {
      case SpecificHeatCapacityUnit.Metric_specific_heat: {
        return value;
      }
      case SpecificHeatCapacityUnit.Oilfield_specific_heat: {
        return value / 0.0002388449577;
      }
      default: {
        throw new TypeError('Unknown Specific Heat Capacity Unit:' + unit);
      }
    }
  }

  public static getSymbol(unit: SpecificHeatCapacityUnit): string {
    return this.symbols[unit];
  }
}
