import { Pipe } from '../../pipe';
import { LowerCompletionPipeDto } from '../../../../dto/lower-completion-pipe.dto';
import { PipeType } from '../../../../dto/pipe.dto';
import { BasePipeFactory } from '../../base.pipe.factory';
import { ITableRow } from '../../../../common/common-grid.interfaces';

export interface ScreenPipe extends Pipe {
  PipeType: PipeType.Screen | PipeType.Shunted_Screen | PipeType.ICD_Screen;
  BasepipeOuterDiameter: number;
  FilterInnerDiameter: number;
  JointLength: number;
  HandlingLength: number;
}

const screenPipeTypes = [PipeType.Screen, PipeType.Shunted_Screen, PipeType.ICD_Screen];

export class ScreenPipeFactory {
  public static createDto(dto: LowerCompletionPipeDto): ScreenPipe {
    const pipe = BasePipeFactory.createFromDto(dto);

    const screenPipe: ScreenPipe = {
      ...pipe,
      PipeType: PipeType.Screen,
      BasepipeOuterDiameter: dto.BasePipeOD ?? 0,
      FilterInnerDiameter: dto.FilterID ?? 0,
      JointLength: dto.JointLength ?? 0,
      HandlingLength: dto.HandlingLength ?? 0,
    };
    return screenPipe;
  }
}

export function isScreenPipe(pipe: Pipe): pipe is ScreenPipe {
  return screenPipeTypes.includes(pipe.PipeType);
}

export function isScreenRow(row: ITableRow<Pipe>): row is ITableRow<ScreenPipe> {
  return isScreenPipe(row.rowData);
}
