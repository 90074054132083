<div class="wrapper">
  <div class="d-flex flex-column">
    <app-page-panel header="Sand Production through Wire-Wrap Screens" (click)="onHelpChange('psd', 'psd-sand-production')">
      <div class="grid-form-container cols-4" *ngIf="psdAnalysis">
        <app-check-box
          [source]="psdAnalysis"
          [key]="'IsCalculateSandProduction'"
          class="grid-four-one"
          [label]="'Calculate Sand Production through Wire-Wrap Screens'"
          (valueChanged)="updatePSDAnalysis($event)"
          dataCy="is-calculate-sand-production-checkbox"
        >
        </app-check-box>
        <app-form-input
          [source]="psdAnalysis"
          [key]="'SandDensity'"
          appUnwrap
          class="grid-two-one"
          label="Sand Density"
          [decimalPlaces]="2"
          [disabled]="!psdAnalysis.IsCalculateSandProduction"
          [unitType]="UnitType.Solid_Density"
          (valueChanged)="updatePSDAnalysis($event)"
          dataCy="sand-density-input"
        >
        </app-form-input>
        <app-form-input
          [source]="psdAnalysis"
          [key]="'IntervalLength'"
          appUnwrap
          class="grid-two-one"
          label="Interval Length"
          [decimalPlaces]="2"
          [disabled]="!psdAnalysis.IsCalculateSandProduction"
          [unitType]="UnitType.Long_Length"
          (valueChanged)="updatePSDAnalysis($event)"
          dataCy="interval-length-input"
        >
        </app-form-input>
        <app-form-input
          [source]="psdAnalysis"
          [key]="'ScreenSlotSize'"
          appUnwrap
          class="grid-two-one"
          label="Screen Slot Size"
          [decimalPlaces]="3"
          [disabled]="!psdAnalysis.IsCalculateSandProduction"
          [unitType]="UnitType.Screen_Opening"
          (valueChanged)="updatePSDAnalysis($event)"
          dataCy="screen-slot-size-input"
        >
        </app-form-input>
        <app-form-input
          [source]="psdAnalysis"
          [key]="'ScreenWireWidth'"
          appUnwrap
          class="grid-two-one"
          label="Screen Wire Width"
          [decimalPlaces]="3"
          [disabled]="!psdAnalysis.IsCalculateSandProduction"
          [unitType]="UnitType.Screen_Opening"
          (valueChanged)="updatePSDAnalysis($event)"
          dataCy="screen-wire-width-input"
        >
        </app-form-input>
        <app-form-input
          [source]="psdAnalysis"
          [key]="'ScreenJointLength'"
          appUnwrap
          class="grid-two-one"
          label="Screen Joint Length"
          [decimalPlaces]="2"
          [disabled]="!psdAnalysis.IsCalculateSandProduction"
          [unitType]="UnitType.Long_Length"
          (valueChanged)="updatePSDAnalysis($event)"
          dataCy="screen-joint-length-input"
        >
        </app-form-input>
        <app-form-input
          [source]="psdAnalysis"
          [key]="'ScreenHandlingLength'"
          appUnwrap
          class="grid-two-one"
          label="Screen Handling Length"
          [decimalPlaces]="2"
          [disabled]="!psdAnalysis.IsCalculateSandProduction"
          [unitType]="UnitType.Long_Length"
          (valueChanged)="updatePSDAnalysis($event)"
          dataCy="screen-joint-filter-length-input"
        >
        </app-form-input>
        <app-form-input
          [source]="psdAnalysis"
          [key]="'ScreenFilterOD'"
          appUnwrap
          class="grid-two-one"
          label="Screen Filter OD"
          [decimalPlaces]="2"
          [disabled]="!psdAnalysis.IsCalculateSandProduction"
          [unitType]="UnitType.Diameter"
          (valueChanged)="updatePSDAnalysis($event)"
          dataCy="screen-filter-OD-input"
        >
        </app-form-input>
      </div>
    </app-page-panel>
  </div>
</div>
