export const Auth0Config = {
  domain: 'auth.dunefrontcloud.com',
  clientId: 'bKTG1elLJydzEyNcQOzD7uvQjxxgZzgB',
  authorizationParams: {
    audience: 'https://dunefront.uk.auth0.com/api/v2/',
    redirect_uri: window.location.origin,
  },
  apiUri: 'http://localhost:3001',
  appUri: window.location.origin,
  errorPath: '/error',
};
