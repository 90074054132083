import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { DbDependentComponent } from '../../../common-modules/db-connection/db-dependent.component';
import { Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { getChartState, getGaugeDataRequestedAfterFileImport } from '../../../+store/reporting/reporting.selectors';
import * as reportingActions from '../../../+store/reporting/reporting.actions';
import * as uiActions from '../../../+store/ui/ui.actions';
import { getStorageFilesArray } from '../../../+store/data-storage/data-storage.selectors';
import { cancelEditRangeAction } from '../../../+store/range/range.actions';
import {
  ChartTimeVolMode,
  GetChartDataParams,
  GetChartDataRequestType,
} from '@dunefront/common/modules/reporting/reporting-module.actions';
import { ChartDataSourceType } from '@dunefront/common/modules/reporting/dto/chart.dto';
import { ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import { ChartControllerConfig, ChartControllerFetchDataPayload } from '../../../common-modules/chart/chart-controller.component';
import { RangeConstants } from '@dunefront/common/dto/range.dto';
import { AllowedXYShiftAxis } from '../../../common-modules/chart/chart-component-helpers/chart-types';
import { getEditingRangeState } from '../../../+store/range/range.selectors';
import { HelpIds } from '../../../+store/help/help.actions';
import { Observable, ReplaySubject } from 'rxjs';
import { AnalysisMenuProps, getAnalysisMenuProps } from '../../../+store/menu-selectors/analysis/menu-analysis.selector';
import { PanelHelpMode } from '../../../shared/components/help-button/help-buton.component';
import { Scenario } from '@dunefront/common/modules/scenario/scenario';

@Component({
  selector: 'app-gauge-data-chart',
  templateUrl: './gauge-data-chart.component.html',
  styleUrls: ['./gauge-data-chart.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GaugeDataChartComponent extends DbDependentComponent implements OnDestroy, AfterViewInit, OnInit {
  public AllowedXYShiftAxis = AllowedXYShiftAxis;

  public chartState$ = this.store.select(getChartState);
  public storageFiles$ = this.store.select(getStorageFilesArray);
  public analysisMenuProps$: Observable<AnalysisMenuProps> = this.store.select(getAnalysisMenuProps);

  public gaugeDataRequestedAfterFileImport = false;
  public isEditing = false;

  private readonly notifyToFetchInitialData$ = new ReplaySubject<void>(1);

  public readonly config: ChartControllerConfig = {
    loadData: (payload) => this.fetchData(payload),
    resetAxisLimitsOnRangeChange: true,
    fetchInitialData$: this.notifyToFetchInitialData$,
  };

  public PanelHelpMode = PanelHelpMode;

  public get chartId(): number {
    if (this.chartIds$.value == null) {
      throw new Error('chartIds$.value is empty!');
    }

    return this.chartIds$.value.gaugeDataChartId;
  }

  constructor(
    store: Store,
    cdRef: ChangeDetectorRef,
    protected route: ActivatedRoute,
  ) {
    super(store, cdRef);
  }

  public override ngOnInit(): void {
    super.ngOnInit();

    this.subscription.add(
      this.store.select(getGaugeDataRequestedAfterFileImport).subscribe((gaugeDataRequestedAfterFileImport) => {
        this.gaugeDataRequestedAfterFileImport = gaugeDataRequestedAfterFileImport ?? false;
      }),
    );

    // don't move it to init because it needs to be triggered before fetchData!
    this.subscription.add(this.store.select(getEditingRangeState).subscribe((state) => (this.isEditing = state.isEditing)));

    this.store.dispatch(uiActions.setGaugeDataTabAction({ tab: 'chart' }));
  }

  public ngAfterViewInit(): void {
    this.onDataPageHelpChange('data-chart');
  }

  private fetchData(payload: ChartControllerFetchDataPayload): void {
    if (
      this.gaugeDataRequestedAfterFileImport ||
      this.currentScenarioId === undefined ||
      this.currentRange === undefined ||
      this.chartIds$.value === undefined
    ) {
      return;
    }

    const { src, requestType, argumentStart, argumentEnd, isPrimaryArgumentRelative, isPrimaryArgument } = payload;

    const params: GetChartDataParams = {
      chartId: this.chartIds$.value.gaugeDataChartId,
      dataSourceType: ChartDataSourceType.ChartSourceGaugeData,
      timeVolMode: ChartTimeVolMode.time,
      rangeId: this.isEditing ? RangeConstants.EntireRangeId : this.currentRangeId,
      moduleType: ModuleType.Evaluate,
      argumentStart,
      argumentEnd,
      isPrimaryArgument,
      isPrimaryArgumentRelative,
      requestType,
      isOptimizeEvaluationChart: false,
    };

    this.store.dispatch(
      reportingActions.getTimeVolChartData({
        src: 'gauge-data-chart.component - fetchData ' + src,
        getChartDataParams: params,
      }),
    );
  }

  private fetchInitialChartData(src: string): void {
    this.fetchData({ src: 'fetchInitialChartData ' + src, requestType: GetChartDataRequestType.Initial });
  }

  public override ngOnDestroy(): void {
    this.store.dispatch(cancelEditRangeAction());
    this.store.dispatch(reportingActions.clearReportingChartData({ chartDataSourceType: ChartDataSourceType.ChartSourceGaugeData }));

    super.ngOnDestroy();
  }

  public override onScenarioLoaded(currentScenario: Scenario): void {
    this.notifyToFetchInitialData$.next();
  }

  public onDataPageHelpChange(uri: HelpIds): void {
    this.onHelpChange('data', uri);
  }
}
