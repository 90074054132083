import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ModalService } from '../../../../common-modules/modals/modal.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Store } from '@ngrx/store';
import { ISelectItem } from '@dunefront/common/common/select.helpers';
import { selectEquationColumn, selectEquationValidityState } from '../../../../+store/equation/equation.selectors';
import {
  insertNewVariable,
  removeVariable,
  saveEquationColumnAction,
  updateEquationColumnAction,
} from '../../../../+store/equation/equation.actions';
import { take } from 'rxjs/operators';
import { ErrorHelper } from '@dunefront/common/common/common-state.interfaces';
import { ISelectedRowProps } from '../../../../shared/components/grid/grid.component';

@Component({
  selector: 'app-add-column-dialog',
  templateUrl: './add-edit-column-dialog.component.html',
  styleUrls: ['./add-edit-column-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddEditColumnDialogComponent {
  public units: ISelectItem<number>[] = [];
  public column$ = this.store.select(selectEquationColumn);
  public selectedRow: number | null | undefined = null;

  public validityState$ = this.store.select(selectEquationValidityState);
  public isHelpOpen = false;

  constructor(
    protected modalService: ModalService,
    public ref: DynamicDialogRef,
    private store: Store,
    public config: DynamicDialogConfig,
  ) {}

  public cancelClicked(): void {
    this.ref.close();
  }

  public onConfirm(): void {
    this.validityState$.pipe(take(1)).subscribe((validity) => {
      if (!validity.isValid) {
        const errorMessages = [
          ...ErrorHelper.getErrorMessages(validity.equationColumn.error),
          ...ErrorHelper.getErrorMessages(validity.equation.error),
        ];
        this.modalService.showAlert(`Please check following errors: <br> ${errorMessages.join('<br>')}`).then();
      } else {
        if (this.config.data.isEditMode) {
          this.store.dispatch(updateEquationColumnAction());
        } else {
          this.store.dispatch(saveEquationColumnAction());
        }
        this.ref.close();
      }
    });
  }

  public onAddNewVariable(): void {
    this.store.dispatch(insertNewVariable());
  }

  public async onRemoveVariable(): Promise<void> {
    if (this.selectedRow == null) {
      return;
    }
    if (await this.modalService.showConfirmNoUndoable('Are you sure you want to delete this variable?', 'Delete Variable')) {
      this.store.dispatch(removeVariable({ index: this.selectedRow }));
    }
  }

  public onHelpClick(isHelpOpen: boolean): void {
    this.isHelpOpen = isHelpOpen;
  }

  public onSelectRowChanged(event: ISelectedRowProps[]): void {
    if (event.length === 0) {
      return;
    }

    this.selectedRow = event[0].rowId !== -1 && event[0].rowId != null ? event[0].rowIndex : null;
  }
}
