import { createFeatureSelector, createSelector } from '@ngrx/store';
import { getFileHash } from '../backend-connection/backend-connection.selectors';
import { JsWorkerJobsState } from './js-worker-jobs-state';

const getJsWorkerState = createFeatureSelector<JsWorkerJobsState>('jsWorker');

export const getCurrentFileJsWorkerJobsState = createSelector(getFileHash, getJsWorkerState, (fileHash, state) => {
  if (fileHash == null) {
    return undefined;
  }

  return state.fileHash[fileHash];
});

export const getCurrentFileActiveJsWorkerJob = createSelector(getCurrentFileJsWorkerJobsState, (fileJsWorkerJobsState) => {
  if (fileJsWorkerJobsState == null) {
    return undefined;
  }
  const activeJobId = fileJsWorkerJobsState?.activeJobId;
  if (activeJobId == null) {
    return undefined;
  }

  return fileJsWorkerJobsState.jobs.find((job) => job.id === activeJobId);
});

export const getCurrentFileHasAnyJsWorkerJobs = createSelector(getCurrentFileJsWorkerJobsState, (state) => {
  const jobs = state?.jobs;
  if (jobs == null) {
    return false;
  }

  return jobs.length > 0;
});

export const getJsWorkerHashesAndJobs = createSelector(getJsWorkerState, (state) => {
  return state.fileHash;
});
