import { IMappedSurveySection } from './drawing.survey-map.helper';
import { Point } from 'chart.js';
import { Rect } from '@dunefront/common/common/math-geometry-helpers';
import { Survey } from '@dunefront/common/modules/well/model/survey/survey';

export interface ILayoutParams {
  topMd: number;
  topHd: number;
  topVd: number;
  bottomMd: number;
  topMargin: number;
  leftMargin: number;
  lScale: number;
  wScale: number;
  holeWidth: number;
  lowestMd: number; // the point of greatest vertical depth (may not be bottomMD!)
  maxVd: number; // the greatest vertical depth
  mappedSurvey: IMappedSurveySection[];
  smoothedSurvey: Survey[];
}

export interface IFluidLabelParams {
  color: string;
  name: string;
  x: number;
  width: number;
  row: number;
}

export interface IDrawPoint {
  x: number;
  y: number;
}

export interface ISquare {
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface IDrawPointWithAngle extends IDrawPoint {
  angle: number;
}

export interface IMappedPoint {
  hd: number;
  vd: number;
  surveySection: IMappedSurveySection; // survey section that contains the mapped point
  lengthProportion: number; // proportion of distance down survey section
}

export interface TooltipRow {
  name: string;
  value: string;
  unit: string;
}

export enum TextType {
  TITLE = 0,
  LABEL = 1,
  LEGEND = 2,
}

export const defaultDrawingPoint = (): IDrawPoint => ({ x: 0, y: 0 });

export interface ScaleTickCoordinates {
  tickStart: Point;
  tickEnd: Point;
  labelRect: Rect;
  label: string;
}
