<app-page-panel header="Performance" [allowFullScreen]="false" (click)="onHelpChange('settings', 'chart-performance')">
  <div style="flex-direction: column" class="p-1">
    <app-radio-group
      [source]="globalOptions"
      [key]="'IsOverrideChartPerformance'"
      [items]="[
        { value: false, text: 'Default' },
        { value: true, text: 'Custom' }
      ]"
      [isUiLockable]="false"
      id="IsOverrideChartPerformance"
      dataCy="is-override-chart-performance-radio"
      (valueChanged)="onValueChange($event)"
      class="grid-two-one"
      layout="horizontal"
    >
    </app-radio-group>
    <div class="grid-form-container no-padding m-top-1">
      <app-form-input
        appUnwrap
        [isUiLockable]="false"
        label="Max Chart Points"
        [source]="globalOptions"
        [sourceDefaults]="defaultGlobalOptions"
        [key]="'MaxChartPoints'"
        [decimalPlaces]="0"
        [isTextInput]="false"
        [unitType]="UnitType.None"
        [inputWidth]="180"
        [disabled]="!globalOptions.IsOverrideChartPerformance"
        [highlight]="isValueDiffThanDefault('MaxChartPoints')"
        (valueChanged)="onValueChange($event)"
      ></app-form-input>

      <!--      Following code is hidden because it's not implemented yet.-->
      <!--      <app-form-input-->
      <!--        appUnwrap-->
      <!--        [isUiLockable]="false"-->
      <!--        label="Engine Update Interval"-->
      <!--        [source]="globalOptions"-->
      <!--        *hideOnDataPro-->
      <!--        [key]="'EngineRealTimeUpdateInterval'"-->
      <!--        [isTextInput]="false"-->
      <!--        [unitType]="UnitType.None"-->
      <!--        unitLabel="s"-->
      <!--        [inputWidth]="180"-->
      <!--        (valueChanged)="onValueChange($event)"-->
      <!--      ></app-form-input>-->

      <app-form-input
        appUnwrap
        [isUiLockable]="false"
        label="Slider Update Interval"
        [source]="globalOptions"
        [sourceDefaults]="defaultGlobalOptions"
        *hideOnDataPro
        [key]="'ChartSliderUpdateInterval'"
        [isTextInput]="false"
        [unitType]="UnitType.None"
        unitLabel="s"
        [inputWidth]="180"
        [disabled]="!globalOptions.IsOverrideChartPerformance"
        [highlight]="isValueDiffThanDefault('ChartSliderUpdateInterval')"
        (valueChanged)="onValueChange($event)"
      ></app-form-input>

      <app-form-input
        appUnwrap
        [isUiLockable]="false"
        label="Import File Max Timespan"
        [source]="globalOptions"
        [sourceDefaults]="defaultGlobalOptions"
        [key]="'ImportFileMaxTimespan'"
        [decimalPlaces]="0"
        [min]="2"
        unitLabel="days"
        [isTextInput]="false"
        [unitType]="UnitType.None"
        [inputWidth]="180"
        [disabled]="!globalOptions.IsOverrideChartPerformance"
        [highlight]="isValueDiffThanDefault('ImportFileMaxTimespan')"
        (valueChanged)="onValueChange($event)"
      ></app-form-input>

    </div>
  </div>
</app-page-panel>
