import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatTableModule } from '@angular/material/table';
import { TableVirtualScrollModule } from 'ng-table-virtual-scroll';
import { PagePanelModule } from '../../page-panel/page-panel.component';
import { RadioGroupModule } from '../../../shared/components/form-components/radio-group/radio-group.component';
import { FormInputModule } from '../../../shared/components/form-components/form-input/form-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SelectModule } from '../../../shared/components/form-components/select/select.module';
import { UnwrapDirectiveModule } from '../../../shared/directives/unwrap.directive';
import { CheckBoxModule } from '../../../shared/components/form-components/check-box/check-box.component';
import { UnitsModule } from '../../units/units.module';
import { ButtonModule } from '../../../shared/components/button/button.module';
import { ProgressBarModule } from 'primeng/progressbar';
import { NgxFileDropModule } from '../../ngx-file-drop/ngx-file-drop.module';
import { ModalsModule } from '../modals.module';
import { DateFormatSuggestionPositionModule } from '../../../shared/directives/date-format-suggestion-position.directive';
import { ColumnDelimiterComponent } from './column-delimiter/column-delimiter.component';
import { ColumnNameGeneratorService } from './column-name-generator.service';
import { FileParserService } from './file-parser/file-parser.service';

@NgModule({
  declarations: [ColumnDelimiterComponent],
  providers: [ColumnNameGeneratorService, FileParserService],
  imports: [
    CommonModule,
    RouterModule,
    NgxFileDropModule,
    ScrollingModule,
    MatTableModule,
    TableVirtualScrollModule,
    PagePanelModule,
    RadioGroupModule,
    FormInputModule,
    SelectModule,
    ReactiveFormsModule,
    FormsModule,
    SelectModule,
    UnwrapDirectiveModule,
    CheckBoxModule,
    UnitsModule,
    ButtonModule,
    ProgressBarModule,
    ModalsModule,
    DateFormatSuggestionPositionModule,
  ],
  exports: [ColumnDelimiterComponent],
})
export class ImportPasteDataCommonModule {}
