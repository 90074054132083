<ng-container *ngIf="isPumpingEnabled$ | async; else pageDisabled">
  <ng-container *ngIf="pumpingState.isLoaded">
    <div class="grid-outline-container" (click)="onHelpChange('pumping', 'schedule-evaluate')">
      <app-schedule-grid
        #gridComponent
        *ngIf="scheduleTableState.rows.length; else scheduleMessage"
        [pumpingSchedule]="scheduleTableState"
        [isReadOnlyMode]="true"
      ></app-schedule-grid>
      <ng-template #scheduleMessage>
        <div class="schedule-message">Click the preview schedule button to generate a schedule from the imported pumping
          data
        </div>
      </ng-template>
    </div>
    <div class="bottom-panel">
      <div class="left-bottom-panel">
        <div class="schedule-buttons-panel">
          <button
            appButton
            type="button"
            data-cy="preview-schedule-btn"
            [isUiLockable]="true"
            [isDeleteResultsEnabled]="false"
            class="btn btn-primary btn-sm"
            (buttonClick)="onPreviewScheduleClick()"
          >
            Preview Schedule
          </button>
          <button
            appButton
            type="button"
            data-cy="copy-to-simulate-btn"
            class="btn btn-primary btn-sm"
            [isDeleteResultsEnabled]="false"
            [disabled]="scheduleTableState.rows.length === 0"
            (buttonClick)="onCopyToSimulateClick()"
          >
            Copy to Simulate
          </button>
        </div>
        <app-page-panel header="Analysis Data" (click)="onHelpChange('pumping', 'schedule-evaluate-analysis')">
          <app-analysis-data></app-analysis-data>
        </app-page-panel>
      </div>
      <app-page-panel header="Pumped Fluid and Gravel"
                      (click)="onHelpChange('pumping', 'schedule-evaluate-fluid-and-gravel')">
        <app-pumped-fluid-and-gravel-grid #gridComponent
                                          [tableState]="pumpingState.pumpedFluidAndGravel"></app-pumped-fluid-and-gravel-grid>
      </app-page-panel>
    </div>
  </ng-container>
</ng-container>
<ng-template #pageDisabled>
  <div class="centered-message">The Schedule screen is not available for the Entire Range - select another range to
    update the data
  </div>
</ng-template>
