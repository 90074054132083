import { UnitConverter } from '../../converter.interfaces';
import { DensityUnit } from '../../../dto/unit-system.dto';

export class DensityConverter extends UnitConverter {
  public static symbols: Array<string> = ['', 'kg/m³', 'g/cm³', 'kg/L', 'g/L', 'lb/ft³', 'lb/galUS', 'S.G.'];

  public static unit = DensityUnit;

  public static fromSi(value: number, unit: DensityUnit): number {
    switch (unit) {
      case DensityUnit.Kilogram_per_cubic_meter:
        return value;
      case DensityUnit.Gram_per_cubic_centimeter:
        return value * 1e-3;
      case DensityUnit.Kilogram_per_liter:
        return value * 1e-3;
      case DensityUnit.Gram_per_liter:
        return value;
      case DensityUnit.Pound_per_cubic_foot:
        return value * 6.24279606e-2;
      case DensityUnit.Pound_per_US_gallon:
        return value * 8.345390594e-3;
      case DensityUnit.Specific_Gravity:
        return value * 1e-3;
      default: {
        throw new TypeError('Unknown Density Unit:' + unit);
      }
    }
  }

  public static toSi(value: number, unit: DensityUnit): number {
    switch (unit) {
      case DensityUnit.Kilogram_per_cubic_meter:
        return value;
      case DensityUnit.Gram_per_cubic_centimeter:
        return value / 1e-3;
      case DensityUnit.Kilogram_per_liter:
        return value / 1e-3;
      case DensityUnit.Gram_per_liter:
        return value;
      case DensityUnit.Pound_per_cubic_foot:
        return value / 6.24279606e-2;
      case DensityUnit.Pound_per_US_gallon:
        return value / 8.345390594e-3;
      case DensityUnit.Specific_Gravity:
        return value / 1e-3;
      default: {
        throw new TypeError('Unknown Pressure Unit:' + unit);
      }
    }
  }

  public static getSymbol(unit: DensityUnit): string {
    return this.symbols[unit];
  }
}
