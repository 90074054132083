import { UpdateRowsWsAction } from '../../ws.action';
import { DeveloperSettingsDto, SettingsDto } from './dto/settingsDto';
import { WsActionPropsFactory } from '../../common/ws-action/ws-action-props.factory';
import { ObjectChangeProp } from '../../common/common-state.interfaces';

export const SettingsModuleName = 'SettingsModule';

export enum SettingsModuleActionTypes {
  UpdateSettings = '[SettingsModule] Update',
  UpdateSettingsRow = '[SettingsModule] UpdateRow',
}

export interface LoadSettingsActionResponse {
  settingsDto: SettingsDto;
  developerSettingsDto: DeveloperSettingsDto;
  settingsDefaults: SettingsDto;
  developerSettingsDefaults: DeveloperSettingsDto;
}

export class UpdateSettingsAction extends UpdateRowsWsAction<SettingsDto> {
  public override readonly type = SettingsModuleActionTypes.UpdateSettings;

  constructor(settings: SettingsDto, props: ObjectChangeProp<SettingsDto>) {
    super(WsActionPropsFactory.updateDto(settings, props));
  }
}

export class UpdateSettingsRowAction extends UpdateRowsWsAction<SettingsDto> {
  public override readonly type = SettingsModuleActionTypes.UpdateSettingsRow;

  constructor(settings: SettingsDto) {
    super(WsActionPropsFactory.update([settings], true));
  }
}

export type SettingsModuleActions = UpdateSettingsAction | UpdateSettingsRowAction;
