import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IInitialData } from '../initial-data.component';
import { Pumping } from '@dunefront/common/modules/pumping/model/pumping/pumping';
import { IRadioItem } from '@dunefront/common/common/radio.helpers';
import { ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';

@Component({
  selector: 'app-subsea-bop',
  templateUrl: './subsea-bop.component.html',
  styleUrls: ['./subsea-bop.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubseaBopComponent {
  @Input() public data!: IInitialData;
  @Output() public dataChange = new EventEmitter<ObjectChangeProp<Pumping>>();

  public isSubseaBOPSItems: IRadioItem<boolean>[] = [
    { value: true, text: 'Yes' },
    { value: false, text: 'No' },
  ];
}
