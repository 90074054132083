import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ICDType } from '@dunefront/common/dto/lower-completion-pipe.dto';
import { EnumHelpers } from '@dunefront/common/utils/enum.helpers';
import { ITableRow, ITableState } from '@dunefront/common/common/common-grid.interfaces';
import { IcdPortDataDto } from '@dunefront/common/dto/icd-port-data.dto';
import { LengthConverter } from '@dunefront/common/unit-converters/converters/length/length.converter';
import { LengthUnit, UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { BhgeIcdOrAicdScreenModalComponent } from '../modals/bhge-icd-or-aicd-screen-modal/bhge-icd-or-aicd-screen.modal.component';
import { GenericIcdPortDataModalComponent } from '../modals/generic-icd-port-data-modal/generic-icd-port-data.modal.component';
import { IcdScreenPipe } from '@dunefront/common/modules/pipes/lower-completion-pipes/pipes/icd-screen-pipe';
import { ModalService } from '../../../../../../common-modules/modals/modal.service';
import { changeProp, ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';
import { IUpdateTableRowsProps, StoreCrudPropsFactory } from '@dunefront/common/common/common-store-crud.interfaces';

@Component({
  selector: 'app-icd-screen-component-props',
  templateUrl: './icd-screen-component-props.component.html',
  styleUrls: ['./icd-screen-component-props.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IcdScreenComponentPropsComponent {
  public IcdTypeItems = EnumHelpers.EnumToISelectItemArray(ICDType, undefined, undefined, [ICDType.None]);

  @Input() public icdPortData?: ITableState<IcdPortDataDto>;
  @Input() public diameterUnit?: LengthUnit;
  @Input() public icdScreenPipeRow?: ITableRow<IcdScreenPipe>;

  @Output() public rowUpdated = new EventEmitter<IUpdateTableRowsProps<IcdScreenPipe>>();
  @Output() public icdPortDataRowUpdated = new EventEmitter<IUpdateTableRowsProps<IcdPortDataDto>>();

  public ICDType = ICDType;
  public UnitType = UnitSystem;

  public get isNumberOfPortsInputVisible(): boolean {
    return (
      this.icdType != null &&
      [
        ICDType.Halliburton_EquiFlow_AICD_Range_1,
        ICDType.Halliburton_EquiFlow_AICD_Range_2,
        ICDType.Halliburton_EquiFlow_AICD_Range_3,
        ICDType.Halliburton_EquiFlow_AICD_Range_4,
      ].includes(this.icdType)
    );
  }

  public get icdType(): ICDType | undefined {
    return this.icdScreenPipeRow?.rowData.ICDType;
  }

  constructor(protected modalService: ModalService) {}

  public get icdValid(): boolean {
    if (!this.icdScreenPipeRow) {
      return true;
    }

    if (this.icdType === ICDType.BHGE_ICD_Or_AICD) {
      if (
        this.icdScreenPipeRow.error.A_Coefficient_Production ||
        this.icdScreenPipeRow.error.B_Coefficient_Production ||
        this.icdScreenPipeRow.error.C_Coefficient_Production ||
        this.icdScreenPipeRow.error.A_Coefficient_Injection ||
        this.icdScreenPipeRow.error.B_Coefficient_Injection ||
        this.icdScreenPipeRow.error.C_Coefficient_Injection
      ) {
        return false;
      }
    }
    if (this.icdType === ICDType.Generic_ICD && this.icdPortData) {
      return this.icdPortData.isValid;
    }

    return true;
  }

  public editPortDataClick(): void {
    if (!this.icdScreenPipeRow) {
      return;
    }
    const lowerCompletionId = this.icdScreenPipeRow.rowData.Id;
    if (this.icdType === ICDType.BHGE_ICD_Or_AICD) {
      this.modalService.open(BhgeIcdOrAicdScreenModalComponent, { lowerCompletionId });
    } else {
      this.modalService.open(GenericIcdPortDataModalComponent, { lowerCompletionId });
    }
  }

  public getPortsLabel(size: number): string {
    if (this.diameterUnit == null) {
      throw new Error('Missing Diameter Unit');
    }
    return (
      LengthConverter.fromSi(LengthConverter.toSi(size, LengthUnit.Inch), this.diameterUnit).toFixed(3) +
      ' ' +
      LengthConverter.getSymbol(this.diameterUnit) +
      ' Ports'
    );
  }

  public onValueChange(event: ObjectChangeProp<IcdScreenPipe>): void {
    if (!this.icdScreenPipeRow) {
      return;
    }
    const newRow: ITableRow<IcdScreenPipe> = {
      ...this.icdScreenPipeRow,
      rowData: changeProp(this.icdScreenPipeRow.rowData, event),
    };
    this.rowUpdated.emit(StoreCrudPropsFactory.updateTableRows([newRow], event));
  }

  public onIcdValueChange(event: ObjectChangeProp<IcdPortDataDto>): void {
    if (!this.icdPortData?.rows) {
      return;
    }
    const newRow: ITableRow<IcdPortDataDto> = {
      ...this.icdPortData.rows[0],
      rowData: { ...this.icdPortData.rows[0].rowData, [event.key]: event.value },
    };
    this.icdPortDataRowUpdated.emit(StoreCrudPropsFactory.updateTableRows([newRow], event));
  }
}
