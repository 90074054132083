import { createAction, props } from '@ngrx/store';
import { ValidatedResuspensionCalculatorModuleState } from '@dunefront/common/modules/calculators/resuspension-calculator/resuspension-calculator-module.state';
import { IUpdateRowsProps } from '@dunefront/common/common/common-store-crud.interfaces';

export const updateResuspensionCalculatorRow = createAction(
  '[calculators] updateResuspensionCalculatorRow',
  props<IUpdateRowsProps<ValidatedResuspensionCalculatorModuleState>>(),
);

export const calculateResuspension = createAction('[calculators] calculateResuspension', props<{ ScenarioId: number }>());
