import { GlobalOptionsDto } from '@dunefront/common/dto/global-options.dto';
import { CommonDbPropertiesHelper } from './common-db.properties.helper';

export class CommonDbSelectorsHelper {
  public static overrideGlobalOptions(globalOptions: GlobalOptionsDto, overrideGlobalOptions: GlobalOptionsDto): GlobalOptionsDto {
    const workingGlobalOptions = { ...globalOptions };

    workingGlobalOptions.IsOverrideChartMarker = overrideGlobalOptions.IsOverrideChartMarker;
    if (workingGlobalOptions.IsOverrideChartMarker) {
      CommonDbPropertiesHelper.applyChartMarkerOptions(workingGlobalOptions, overrideGlobalOptions);
    }

    workingGlobalOptions.IsOverrideAnnotationStyle = overrideGlobalOptions.IsOverrideAnnotationStyle;
    if (workingGlobalOptions.IsOverrideAnnotationStyle) {
      CommonDbPropertiesHelper.applyCharAnnotationStyleOptions(workingGlobalOptions, overrideGlobalOptions);
    }

    workingGlobalOptions.IsOverrideGradientLineStyle = overrideGlobalOptions.IsOverrideGradientLineStyle;
    if (workingGlobalOptions.IsOverrideGradientLineStyle) {
      CommonDbPropertiesHelper.applyCharGradientLineStyleOptions(workingGlobalOptions, overrideGlobalOptions);
    }

    workingGlobalOptions.IsOverrideChartPerformance = overrideGlobalOptions.IsOverrideChartPerformance;
    if (workingGlobalOptions.IsOverrideChartPerformance) {
      CommonDbPropertiesHelper.applyChartPerformanceOptions(workingGlobalOptions, overrideGlobalOptions);
    }

    workingGlobalOptions.IsOverrideVideoPerformance = overrideGlobalOptions.IsOverrideVideoPerformance;
    if (workingGlobalOptions.IsOverrideVideoPerformance) {
      CommonDbPropertiesHelper.applyVideoPerformanceOptions(workingGlobalOptions, overrideGlobalOptions);
    }

    workingGlobalOptions.IsOverrideChartAxis = overrideGlobalOptions.IsOverrideChartAxis;
    if (workingGlobalOptions.IsOverrideChartAxis) {
      CommonDbPropertiesHelper.applyCharAxisStyleOptions(workingGlobalOptions, overrideGlobalOptions);
    }

    workingGlobalOptions.IsOverrideLegend = overrideGlobalOptions.IsOverrideLegend;
    if (workingGlobalOptions.IsOverrideLegend) {
      CommonDbPropertiesHelper.applyLegendStyleOptions(workingGlobalOptions, overrideGlobalOptions);
    }

    workingGlobalOptions.IsOverrideSeries = overrideGlobalOptions.IsOverrideSeries;
    if (workingGlobalOptions.IsOverrideSeries) {
      CommonDbPropertiesHelper.applySeriesStyleOptions(workingGlobalOptions, overrideGlobalOptions);
    }

    workingGlobalOptions.IsOverrideCopyChartOptions = overrideGlobalOptions.IsOverrideCopyChartOptions;
    if (workingGlobalOptions.IsOverrideCopyChartOptions) {
      CommonDbPropertiesHelper.applyCopyChartOptions(workingGlobalOptions, overrideGlobalOptions);
    }

    CommonDbPropertiesHelper.applyFileOptions(workingGlobalOptions, overrideGlobalOptions);

    return workingGlobalOptions;
  }
}
