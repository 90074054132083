<ng-container *ngIf="componentState$ | async as componentState">
  <app-chart-controller
    *ngIf="componentState.chartState; else defaultMessage"
    [config]="config"
    [chartState]="componentState.chartState"
    [chartId]="componentState.chartId"
    [chartDisplayName]="'Injection Test Calculator Chart'"
    dataCy="reporting-chart"
  >
  </app-chart-controller>
</ng-container>

<ng-template #defaultMessage>
  <div class="text-center">Define at least 4 rate and pressure data points in the grid to see the chart.</div>
</ng-template>
