export enum LicenseCheck {
  Feature_Log_In_Status = 0,
  Feature_Expiration_Status = 1,
  Feature_Expiration_Warning = 2,
  All_Available_Features = 3,
}

export enum LicenseFeature {
  Default = 0,

  //Legacy
  Application = 1,
  Testing = 998,

  //Production
  Simulate = 2,
  Evaluate = 3,
  Trend_Analysis = 4,
  Calculators = 5,
  PSD_Analysis = 6,
  Engine = 7,
  Data_Analysis = 8,
  Encryption = 9,
  Simulate_CH = 10,
  MPGP = 11,
  Simulate_Disp = 12,
  Evaluate_Disp = 13,

  //Virtual
  Detaching = 200,
}

export const isAddonFeature = (feature: LicenseFeature): boolean => {
  return [LicenseFeature.MPGP, LicenseFeature.Detaching].includes(feature);
};

export interface LicenseProduct {
  id: string;
  name: string;
  haspId: string;
  detachMaxDays: number;
  isDetachable: boolean;
  productDetails: ProductDetails;
}

export interface ProductDetails {
  features: Feature[];
}

export interface GetProductsAndFeaturesResponse {
  features: LicenseFeature[];
  products: LicenseProduct[];
}

export interface Feature {
  id: LicenseFeature;
  name: string;
  expired: boolean;
  disabled: boolean;
}

export enum HaspStatus {
  StatusOk = 0,
  InvalidAddress = 1,
  InvalidFeature = 2,
  NotEnoughMemory = 3,
  TooManyOpenFeatures = 4,
  AccessDenied = 5,
  IncompatibleFeature = 6,
  ContainerNotFound = 7,
  BufferTooShort = 8,
  InvalidHandle = 9,
  InvalidFile = 10,
  DriverTooOld = 11,
  NoTime = 12,
  SystemError = 13,
  DriverNotFound = 14,
  InvalidFormat = 15,
  RequestNotSupported = 16,
  InvalidUpdateObject = 17,
  KeyIdNotFound = 18,
  InvalidUpdateData = 19,
  UpdateNotSupported = 20,
  InvalidUpdateCounter = 21,
  InvalidVendorCode = 22,
  EncryptionNotSupported = 23,
  InvalidTime = 24,
  NoBatteryPower = 25,
  UpdateNoAckSpace = 26,
  TerminalServiceDetected = 27,
  FeatureNotImplemented = 28,
  UnknownAlgorithm = 29,
  InvalidSignature = 30,
  FeatureNotFound = 31,
  NoLog = 32,
  LocalCommErr = 33,
  UnknownVcode = 34,
  InvalidXmlSpec = 35,
  InvalidXmlScope = 36,
  TooManyKeys = 37,
  TooManyUsers = 38,
  BrokenSession = 39,
  RemoteCommErr = 40,
  FeatureExpired = 41,
  TooOldLM = 42,
  DeviceError = 43,
  UpdateBlocked = 44,
  TimeError = 45,
  SecureChannelError = 46,
  CorruptStorage = 47,
  VendorLibNotFound = 48,
  InvalidVendorLib = 49,
  EmptyScopeResults = 50,
  VMDetected = 51,
  HardwareModified = 52,
  UserDenied = 53,
  UpdateTooOld = 54,
  UpdateTooNew = 55,
  VendorlibOld = 56,
  UploadError = 57,
  InvalidRecipient = 58,
  InvalidDetachAction = 59,
  TooManyProducts = 60,
  InvalidProduct = 61,
  UnknownRecipient = 62,
  InvalidDuration = 63,
  CloneDetected = 64,
  UpdateAlreadyAdded = 65,
  HaspInactive = 66,
  NoDetachableFeature = 67,
  TooManyHosts = 68,
  RehostNotAllowed = 69,
  LicenseRehosted = 70,
  RehostAlreadyApplied = 71,
  CannotReadFile = 72,
  ExtensionNotAllowed = 73,
  DetachDisabled = 74,
  RehostDisabled = 75,
  DetachedLicenseFound = 76,
  RecipientOldLm = 77,
  SecureStoreIdMismatch = 78,
  DuplicateHostname = 79,
  MissingLM = 80,
  FeatureInsufficientExecutionCount = 81,
  NoApiDylib = 400,
  InvApiDylib = 401,
  InvalidObject = 500,
  InvalidParameter = 501,
  AlreadyLoggedIn = 502,
  AlreadyLoggedOut = 503,
  OperationFailed = 525,
  NoExtensionBlock = 600,
  InvalidPortType = 650,
  InvalidPort = 651,
  HaspDotNetDllBroken = 652,
  NotImplemented = 698,
  InternalError = 699,
}

export interface LicensingJobInputData {
  LicenseFeature: LicenseFeature | null;
  LicenseCheck: LicenseCheck;
}

export interface LicenseInfo {
  features: LicenseFeature[];
  products: LicenseProduct[];
  licenseIds: string[];
}

export interface LoginInfo {
  licenseId: string;
  loginStatusMessage: string;
  licenseFeature: LicenseFeature;
  haspStatus: HaspStatus;
  expirationMessage: string;
  expirationWarning: string;
  isDetached: boolean;
  parentLicenseExpirationMessage: string;
  productId: string;
  isConnected: boolean;
  parentLicenseId: string;
}

export interface LicensingLoginResponse {
  isLicensingDisabled: boolean;
  isLoggedIn: boolean;
  loginInfos: LoginInfo[];
  mainFeature: LicenseFeature;
}

export interface LicensingLogoutResponse {
  status: LogoutStatus;
}

export interface LicensingCheckSessionsResponse {
  sessionStatuses: SessionStatus[];
}

export interface DetachLicenseResponse {
  detachedLicenseId: string;
}

export interface DetachInputData {
  licenseId: string;
  productId: string;
  duration: number;
}

export interface CancelDetachInputData {
  licenseId: string;
  parentLicenseId: string;
}

export interface SessionStatus {
  keyId: string;
  sessionId: string;
  feature: LicenseFeature;
  isActive: boolean;
  haspStatus: string;
}

export enum LogoutStatus {
  Ok = 0,
  UserNotFound = 1,
  Error = 2,
}

export interface LicenseWithSessionsInfo {
  licenseId: string;
  sessions: SessionInfo[];
}

export interface SessionInfo {
  sessionId: string;
  featureName: string;
  featureId: number;
  feature: LicenseFeature;
  loginTime: string;
  remainingTime: string;
  disableDisconnect: number;
}

export interface IGetCurrentFeaturesResult {
  mainFeature: LicenseFeature | undefined;
  accessibleFeatures: LicenseFeature[];
  addonFeatures: LicenseFeature[];
}
