import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IValidatedImportDataModuleState } from '../../../../+store/import-data/import-data-module.state';
import { DataFileType } from '@dunefront/common/dto/data-storage';
import dayjs from 'dayjs';
import { BackendConnectionService } from '../../../../shared/backend-connection/backend-connection.service';
import { IParsedFileResult } from '../../../../+store/import-data/model/parsed-result';
import { Store } from '@ngrx/store';
import { insertRowsSuccess } from '../../../../+store/app.actions';
import {
  DataStorageDataSaveAction,
  DataStorageFileCreateAction,
  DataStorageFileCreateActionResponse,
  DataStorageModuleName,
} from '@dunefront/common/modules/data-storage/data-storage-module.actions';
import { ImportFileFactory } from '@dunefront/common/modules/data-storage/dto/import-file.dto';
import { DataArgumentGenerator, IMapColumnsResponse } from '../services/data-argument-generator';
import { ModalService } from '../../modal.service';
import { firstValueFrom } from 'rxjs';
import { getStorageColumnNames } from '../../../../+store/data-storage/data-storage.selectors';
import { loadFilesAction } from '../../../../+store/data-storage/data-storage.actions';

@Component({
  selector: 'app-send-data',
  templateUrl: './send-data.component.html',
  styleUrls: ['./send-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SendDataComponent implements OnInit {
  @Input()
  public parsedResult!: IParsedFileResult;

  @Input()
  public state!: IValidatedImportDataModuleState;

  @Output()
  public isUploadedChange = new EventEmitter<boolean>();

  public progress = 0;
  private _isUploading = false;
  public set isUploading(isUploading: boolean) {
    this._isUploading = isUploading;
    if (!this._isUploading && this.progress === 100) {
      this.isUploadedChange.emit(true);
    } else {
      this.isUploadedChange.emit(false);
    }
  }

  public get isUploading(): boolean {
    return this._isUploading;
  }

  private dataArgumentGenerator = new DataArgumentGenerator();

  constructor(
    private backendConnectionService: BackendConnectionService,
    private cdRef: ChangeDetectorRef,
    private store: Store,
    private modalService: ModalService,
  ) {}

  public ngOnInit(): void {
    this.sendFile().then();
  }

  private async sendFile(): Promise<void> {
    this.progress = 0;
    this.isUploading = true;

    try {
      // create new import file
      // this file is missing MinArg/MaxArg as has no data yet
      const { fileId, columns } = await this.createFile();

      // send data
      await this.prepareAndSendData(fileId, columns);

      // fetch import files and update in the store
      // this corrects missing MinArg/MaxAr for previously created file
      this.store.dispatch(loadFilesAction());
    } catch (err: any) {
      throw new Error(err);
    } finally {
      this.isUploading = false;
      this.cdRef.markForCheck();
    }
  }

  /**
   * Creates new import file on server and in the store
   * @private
   */
  private async createFile(): Promise<{ fileId: number; columns: IMapColumnsResponse }> {
    const fileName = this.state.fileName + ' ' + dayjs().format('DD-MMM-YY HH:mm:ss');
    const fileDto = ImportFileFactory.create(fileName, DataFileType.ImportedData);

    const allColumnsNames = (await firstValueFrom(this.store.select(getStorageColumnNames))).map((c) => c.name);
    const columns = DataArgumentGenerator.getColumns(this.state, allColumnsNames);

    const argCol = columns.dtos.find((dto) => dto.IsXAxis);
    if (argCol) {
      argCol.StartDate = this.parsedResult.startDate;
    }

    this.dataArgumentGenerator.initialize(this.state);

    const createFileResponse = await this.backendConnectionService.emitAsync<DataStorageFileCreateActionResponse>(
      DataStorageModuleName,
      true,
      new DataStorageFileCreateAction(fileDto, columns.dtos),
    );

    this.store.dispatch(insertRowsSuccess(createFileResponse.payload.crudResponse));

    const fileId = createFileResponse.payload.newFileId;
    if (!fileId) {
      throw new Error('File Id not found');
    }

    return { fileId, columns };
  }

  private async prepareAndSendData(fileId: number, cols: IMapColumnsResponse): Promise<void> {
    let argumentRows: number[] = [];
    let combinedDataRows: string[] = [];
    let sourceArgumentRows: string[] = [];
    let counter = 0;

    const dataColIds = this.state.includedColumnIds.filter((colId) => !cols.argColIds.includes(colId));

    do {
      if (this.parsedResult.linesToSkipForWhenUploading.includes(counter)) {
        counter++;
        continue;
      }
      const row = this.parsedResult.data[counter];

      const values = dataColIds.map((colId) => +row[colId]);
      if (values.includes(NaN)) {
        const response = await this.modalService.showConfirm(
          `Can't parse line ${counter}.<br>${row}<br>Press continue to skip this line.`,
          '',
          'md',
          'Continue',
          'Cancel',
        );
        if (response) {
          counter++;
          continue;
        } else {
          // Stop sending file.
          this.progress = 100;
          this.isUploading = false;
          return;
        }
      }

      combinedDataRows.push(dataColIds.map((colId) => +row[colId]).join(','));
      const argument = this.dataArgumentGenerator.generateArgument(row, this.state, cols);

      argumentRows.push(argument.argument);
      if (argument.sourceArgument) {
        sourceArgumentRows.push(argument.sourceArgument);
      }

      if (counter > 0 && counter % 5000 === 0) {
        await this.sendData(fileId, argumentRows, combinedDataRows, sourceArgumentRows, counter);
        combinedDataRows = [];
        argumentRows = [];
        sourceArgumentRows = [];
      }
      counter++;
    } while (counter < this.parsedResult.data.length);

    if (argumentRows.length > 0) {
      await this.sendData(fileId, argumentRows, combinedDataRows, sourceArgumentRows, counter);
    }
  }

  private async sendData(
    fileId: number,
    argumentRows: number[],
    combinedDataRows: string[],
    sourceArgumentRows: string[],
    i: number,
  ): Promise<void> {
    const sendDataAction = new DataStorageDataSaveAction(
      fileId,
      argumentRows,
      combinedDataRows,
      sourceArgumentRows,
      i + 1 - argumentRows.length,
    );

    await this.backendConnectionService.emitAsync(DataStorageModuleName, true, sendDataAction);

    this.progress = Math.round((i / this.parsedResult.data.length) * 100);
    this.cdRef.markForCheck();
  }
}
