import { ChangeDetectorRef, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { DbDependentComponent } from '../../../common-modules/db-connection/db-dependent.component';
import * as uiActions from '../../../+store/ui/ui.actions';

@Component({
  selector: 'app-completion',
  templateUrl: './completion.component.html',
  styleUrls: ['./completion.component.scss'],
})
export class CompletionComponent extends DbDependentComponent {
  constructor(store: Store, cdRef: ChangeDetectorRef) {
    super(store, cdRef);
    store.dispatch(uiActions.setAppSectionAction({ section: 'completion' }));
  }
}
