import { WellFluidDto } from '../../dto/well-fluid.dto';
import { WellFluid } from './well-fluid';
import { WellFluidCalculations } from './well-fluid.calculations';
import { createTableRow, ITableRow } from '../../../../common/common-grid.interfaces';

export class WellFluidFactory {
  public static createRowListFromDtos(wellFluidsDtos: WellFluidDto[]): ITableRow<WellFluid>[] {
    const rows = wellFluidsDtos.map((dto, index) => this.createElement(dto, index));
    return WellFluidCalculations.UpdateCumulativeVolume(rows);
  }

  public static createElement(wellFluidDto: WellFluidDto, rowIndex: number): ITableRow<WellFluid> {
    return createTableRow<WellFluid>(this.createWellFluid(wellFluidDto), 'data', rowIndex);
  }

  public static createWellFluid(wellFluidDto: WellFluidDto): WellFluid {
    return {
      ...wellFluidDto,
      CumulativeVolume: 0,
    };
  }

  public static createEmpty(refRow: WellFluid): WellFluid {
    return {
      ...refRow,
      Id: -1,
      BottomMD: refRow.TopMD,
      Volume: 0,
      FluidId: null,
      GravelId: 0,
      GravelConcentration: 0,
      IsPacked: false,
    };
  }

  public static toDto(wellFluid: WellFluid): WellFluidDto {
    const { CumulativeVolume, ...wellFluidDto } = wellFluid;
    return wellFluidDto;
  }
}
