import { Validation } from '../../../common/validation';
import { ZoneModel } from './zone/zone.model';
import { WellModuleState } from '../well-module.state';
import { getRowsForCalculations } from '../../../common/common-grid.interfaces';

export class WellCalculations {
  // Calculate Casing MD
  public static MaxCasingMD(well: WellModuleState): number {
    // Find the max MD in the casing
    const casingMD = Math.max(...getRowsForCalculations(well.CasingData.rows).map((cas) => cas.BottomMD));
    // validate and return the result
    return Validation.UICheckNumberValidity(casingMD);
  }

  // Method to determine if losses have been specified
  public static IsLossSpecified(zoneData: ZoneModel[]): boolean {
    // Check if there are any losses specified
    return !!zoneData.find((zone) => zone.LossPercentage > 0);
  }
}
