import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CompletionModuleFeatureName } from './completion.reducer';
import { CompletionModuleState } from '@dunefront/common/modules/completion/completion-module.state';
import { CompletionModuleCalculations } from '@dunefront/common/modules/completion/completion-module-calculations';
import { getCaliperDataForCalculation, getCasingDataForCalculation } from '../well/well.selectors';

export const getCompletionRawModuleState = createFeatureSelector<CompletionModuleState>(CompletionModuleFeatureName);

export const getCompletionModuleState = createSelector(
  getCompletionRawModuleState,
  getCasingDataForCalculation,
  getCaliperDataForCalculation,
  (state, casing, caliper) => CompletionModuleCalculations.applyCalculations(state, casing, caliper),
);

export const getLowerCompletion = createSelector(getCompletionRawModuleState, (state) => state.LowerCompletion);
export const getRunningString = createSelector(getCompletionRawModuleState, (state) => state.RunningString);
