import { ChangeDetectorRef, Component, HostListener, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { getBackendConnectionState } from '../../../../+store/backend-connection/backend-connection.selectors';
import { getRedisJobConnectionStatus } from '../../../../+store/calculation-engine/calculation-engine.selectors';
import { AboutModuleState } from '../../../../+store/about/about.reducer';
import { Subscription } from 'rxjs';
import { getAboutServer } from '../../../../+store/about/about.selectors';
import { getAccessibleFeaturesText } from '../../../../+store/licensing/licensing.selectors';
import { isElectron } from '@dunefront/common/common/electron/is-electron';

@Component({
  selector: 'app-connection-status',
  templateUrl: './connection-status.component.html',
  styleUrls: ['./connection-status.component.scss'],
})
export class ConnectionStatusComponent implements OnDestroy {
  public licenseFeatures$ = this.store.select(getAccessibleFeaturesText);
  public connectionStatus$ = this.store.select(getBackendConnectionState);
  public redisStatus$ = this.store.select(getRedisJobConnectionStatus);
  public aboutServer!: AboutModuleState;
  public showUserPanel = !isElectron();
  public isVisible = false;

  private subscription = new Subscription();

  @HostListener('document:keydown.control.f3')
  private showHide(): void {
    this.isVisible = !this.isVisible;
  }

  constructor(
    protected store: Store,
    private cdRef: ChangeDetectorRef,
  ) {
    this.subscription.add(
      this.store.select(getAboutServer).subscribe((about) => {
        this.aboutServer = about;
        this.cdRef.markForCheck();
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
