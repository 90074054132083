import { IWellValidationDependencies, WellModuleState } from '../../well-module.state';
import { Pipe } from '../../../pipes/pipe';
import { getRowsForCalculations, ITableRow, ITableState } from '../../../../common/common-grid.interfaces';
import { isPerforatedCasingRow, PerforatedCasingPipe } from '../../../pipes/casing-pipes/pipes/perforated-casing-pipe';
import { LocationType, TreatmentType } from '../../dto/well.dto';
import { PipeType } from '../../../../dto/pipe.dto';
import { PerforationPhasingTypeEnum } from '../../dto/casing/casing-pipe.dto';
import { CasingPipe } from '../../../pipes/casing-pipes/casing-pipes.factory';
import { noErrors } from '../../../../common/state.helpers';
import { ConvertUnitPipe } from '../../../units/convert-unit.pipe/convert-unit.pipe';
import { IError } from '../../../../common/common-state.interfaces';
import { UnitSystem } from '../../../../dto/unit-system.dto';
import { MAX_PERFORATION_TUNNEL_LENGTH, MAX_PERFORMATION_DIAMETER } from '../../../../common/constants';
import { validateFeatureRequired } from '../../../licensing/licensing.validator';
import { IGetCurrentFeaturesResult, LicenseFeature } from '../../../licensing/licensing.interfaces';
import { ModuleType } from '../../../scenario/scenario.dto';
import { FluidProValidation } from '../../../fluid-pro/fluid-pro.validation';

export class CasingValidation {
  public static ValidateCasingGrid(well: WellModuleState, maxSurveyMD: number, deps: IWellValidationDependencies): ITableState<Pipe> {
    let isValid = true;
    let isWarning = false;
    const newCasingData = { ...well.CasingData };
    const casingRows: Pipe[] = getRowsForCalculations(well.CasingData.rows);
    const updatedRows = newCasingData.rows.map((row, rowIndex) => {
      if (row.rowType === 'insert-row') {
        return row;
      }
      const newRow = this.ValidateCasingGridRow(well.CasingData.rows[rowIndex], casingRows, rowIndex, well, maxSurveyMD, deps);
      isValid = isValid && newRow.isValid;
      isWarning = isWarning || (newRow.isWarning ?? false);
      return newRow;
    });

    return { ...newCasingData, rows: updatedRows, isValid, isWarning };
  }

  private static ValidateCasingGridRow(
    row: ITableRow<CasingPipe>,
    casingsData: Pipe[],
    rowIndex: number,
    well: WellModuleState,
    maxSurveyMD: number,
    { currentModuleType, currentLicenseFeatures, settings }: IWellValidationDependencies,
  ): ITableRow<Pipe> | ITableRow<PerforatedCasingPipe> {
    let pipeErrors: IError<CasingPipe> = {
      PipeType: this.ValidateCasingPipeType(casingsData, rowIndex, well, currentLicenseFeatures),
      BottomMD: this.ValidateCasingBottomMD(casingsData, rowIndex, maxSurveyMD),
      OuterDiameter: this.ValidateCasingOD(casingsData, rowIndex),
      InnerDiameter: this.ValidateCasingID(casingsData, rowIndex),
      Weight: this.ValidateCasingWeight(casingsData, rowIndex),
    };

    let pipeWarnings: IError<CasingPipe> = {};
    if (currentModuleType === ModuleType.Simulate_Disp) {
      const componentSizeWarning = FluidProValidation.ValidateFluidProComponentSize(casingsData, rowIndex, settings);
      pipeWarnings = {
        ...pipeWarnings,
        PipeType: componentSizeWarning,
        BottomMD: componentSizeWarning,
        TopMD: componentSizeWarning,
        Length: componentSizeWarning,
      };
    }

    const validatePerforatedCasingRow = currentModuleType !== ModuleType.Simulate_Disp;

    if (validatePerforatedCasingRow && isPerforatedCasingRow(row)) {
      pipeErrors = {
        ...pipeErrors,
        PerforationShotDensity: this.ValidateCasingPerforationShotDensity(row),
        PerforationVolume: this.ValidateCasingPerforationVolume(row),
        PerforationDiameter: this.ValidateCasingPerforationDiameter(row),
        PerforationTunnelLength: this.ValidateCasingPerforationTunnelLength(row),
        OpenPerforationPercentage: this.ValidateCasingPerforationOpenPercentage(row),
        PerforationPhasingType: this.ValidatePerforationPhasing(row),
      };
    }
    return {
      ...row,
      error: pipeErrors,
      warning: pipeWarnings,
      isValid: noErrors(pipeErrors),
      isWarning: !noErrors(pipeWarnings),
    };
  }

  // Used to be ValidateCasingComponentName
  // todo: write unit test
  private static ValidateCasingPipeType(
    casingsData: Pipe[],
    rowIndex: number,
    well: WellModuleState,
    currentLicenseFeatures: IGetCurrentFeaturesResult,
  ): string {
    const pipeType = casingsData[rowIndex].PipeType;

    if (pipeType === PipeType.Open_Hole) {
      const requiredFeatureError = validateFeatureRequired(
        [LicenseFeature.Simulate, LicenseFeature.Evaluate, LicenseFeature.Simulate_Disp],
        currentLicenseFeatures,
      );
      if (requiredFeatureError) {
        return requiredFeatureError;
      }
    }

    if (!casingsData.some((pipe) => pipe.IsReservoirSection)) {
      return 'At least one perforated casing or open hole must be defined';
    }

    if (well.LocationType === LocationType.Land) {
      if (pipeType === PipeType.Riser) {
        return "Riser can't be used for land wells";
      }
      if (rowIndex === 0 && pipeType !== PipeType.Casing) {
        return 'First component must be a casing';
      }
    } else {
      if (rowIndex === 0 && pipeType !== PipeType.Casing && pipeType !== PipeType.Riser) {
        return 'First component must be a casing or riser';
      }
      if (rowIndex !== 0 && pipeType === PipeType.Riser) {
        return 'Riser can only be used as the first component';
      }
    }

    if (well.TreatmentType === TreatmentType.High_Rate_Water_Pack) {
      if (pipeType === PipeType.Open_Hole) {
        return 'Open hole is not supported in HRWP treatments';
      }
      if (
        pipeType === PipeType.Perforated_Casing &&
        casingsData.filter((pipe) => pipe.PipeType === PipeType.Perforated_Casing).length > 1
      ) {
        return 'Only one perforated interval is supported in HRWP treatments';
      }
    }
    return '';
  }

  private static ValidateCasingBottomMD(casingsData: Pipe[], rowIndex: number, maxSurveyMD: number): string {
    const topMD = casingsData[rowIndex].TopMD;
    const bottomMD = casingsData[rowIndex].BottomMD;

    if (topMD >= bottomMD) {
      return 'Bottom MD must be greater than top MD';
    } else if (bottomMD > maxSurveyMD) {
      return 'Bottom MD must be less than or equal to maximum survey MD';
    }
    return '';
  }

  private static ValidateCasingOD(casingsData: Pipe[], rowIndex: number): string {
    if (casingsData[rowIndex].PipeType === PipeType.Open_Hole) {
      return '';
    }
    const od = casingsData[rowIndex].OuterDiameter;
    const id = casingsData[rowIndex].InnerDiameter;

    if (od <= 0) {
      return 'OD must be greater than zero';
    } else if (id >= od) {
      return 'OD must be greater than ID';
    }
    return '';
  }

  private static ValidateCasingID(casingsData: Pipe[], rowIndex: number): string {
    if (casingsData[rowIndex].PipeType === PipeType.Open_Hole) {
      return '';
    }

    const od = casingsData[rowIndex].OuterDiameter;
    const id = casingsData[rowIndex].InnerDiameter;

    if (id <= 0) {
      return 'ID must be greater than zero';
    } else if (id >= od) {
      return 'ID must be less than OD';
    }
    return '';
  }

  private static ValidateCasingWeight(casingsData: Pipe[], rowIndex: number): string {
    if (casingsData[rowIndex].PipeType === PipeType.Open_Hole) {
      return '';
    }
    if (casingsData[rowIndex].Weight <= 0) {
      return 'Weight must be greater than zero';
    }
    return '';
  }

  private static ValidateCasingPerforationShotDensity(row: ITableRow<PerforatedCasingPipe>): string {
    if (row.rowData.PerforationShotDensity <= 0) {
      return 'Perforation shot density must be greater than zero';
    }
    return '';
  }

  private static ValidateCasingPerforationVolume(row: ITableRow<PerforatedCasingPipe>): string {
    if (row.rowData.PerforationVolume <= 0) {
      return 'Perforation Volume must be greater than zero';
    }
    return '';
  }

  private static ValidateCasingPerforationDiameter(row: ITableRow<PerforatedCasingPipe>): string {
    if (row.rowData.PerforationDiameter <= 0) {
      return 'Perforation Diameter must be greater than zero';
    }
    if (row.rowData.PerforationDiameter > MAX_PERFORMATION_DIAMETER) {
      return 'Perforation Diameter must be less than or equal to ' + ConvertUnitPipe.encode(UnitSystem.Diameter, MAX_PERFORMATION_DIAMETER);
    }
    return '';
  }

  private static ValidateCasingPerforationTunnelLength(row: ITableRow<PerforatedCasingPipe>): string {
    if (row.rowData.PerforationTunnelLength <= 0 || row.rowData.PerforationTunnelLength >= MAX_PERFORATION_TUNNEL_LENGTH) {
      return 'Tunnel Length must be between 0 and ' + ConvertUnitPipe.encode(UnitSystem.Short_Length, MAX_PERFORATION_TUNNEL_LENGTH);
    }
    return '';
  }

  private static ValidateCasingPerforationOpenPercentage(row: ITableRow<PerforatedCasingPipe>): string {
    const perforationOpenPercentage = row.rowData.OpenPerforationPercentage;
    if (perforationOpenPercentage <= 0 || perforationOpenPercentage > 100) {
      return 'Open perforation percentage must be between 0 and 100';
    }
    return '';
  }

  private static ValidatePerforationPhasing(row: ITableRow<PerforatedCasingPipe>): string {
    if (row.rowData.PerforationPhasingType === PerforationPhasingTypeEnum.None) {
      return 'Perforation phasing angle must be selected';
    }
    return '';
  }
}
