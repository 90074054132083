import { Injectable, NgZone } from '@angular/core';
import { WsAction } from '@dunefront/common/ws.action';
import { Store } from '@ngrx/store';

import { Observable, tap } from 'rxjs';
import { IBackendConnectionService } from './backend-connection.interfaces';
import { ElectronIpcConnectionService } from './electron-ipc-connection.service';
import { WsConnectionService } from './ws-connection.service';
import { ElectronService } from '../services/electron-service/electron.service';
import { backendRequestStartedAction, backendResponseReceivedAction } from '../../+store/backend-connection/backend-connection.actions';
import { DocumentExtension, fileExtensions } from '@dunefront/common/common/helpFile';
import { WsActionResponse } from '@dunefront/common/response-ws.action';

@Injectable({
  providedIn: 'root',
})
export class BackendConnectionService {
  private service: IBackendConnectionService;
  public sessionId!: string;

  constructor(
    electronService: ElectronService,
    private ngZone: NgZone,
    private store: Store,
  ) {
    this.service = electronService.isElectronApp
      ? new ElectronIpcConnectionService(ngZone, store, electronService)
      : new WsConnectionService(ngZone, store);
  }

  public async connect(accessToken: string, sessionId: string): Promise<boolean> {
    return await this.service.connect(accessToken, sessionId);
  }

  public disconnect(): void {
    this.service.disconnect();
  }

  public async emitAsync<T>(moduleName: string, isDbDependent: boolean, action: WsAction): Promise<WsActionResponse<T>> {
    return await this.service.emitAsync(moduleName, isDbDependent, action);
  }

  public getSocketId(): string | undefined {
    return this.service.socketId;
  }

  public emit<T>(moduleName: string, isDbDependent: boolean, action: WsAction): Observable<WsActionResponse<T>> {
    this.store.dispatch(
      backendRequestStartedAction({
        actionType: action.type,
        taskId: action.taskId,
        activityOverlayConfig: action.activityOverlayConfig,
      }),
    );

    return this.service.emit<T>(moduleName, isDbDependent, action).pipe(
      tap({
        next: () => this.dispatchResponseReceivedAction(action),
        error: () => this.dispatchResponseReceivedAction(action),
      }),
    );
  }

  private dispatchResponseReceivedAction(action: WsAction): void {
    this.store.dispatch(
      backendResponseReceivedAction({ taskId: action.taskId, closeActivityOverlay: action.activityOverlayConfig != null }),
    );
  }

  public on(moduleName: string, isDbDependent: boolean): Observable<WsAction> {
    return this.service.on(moduleName, isDbDependent);
  }

  public async openDocument(url: string, extension: DocumentExtension = fileExtensions.HTML): Promise<void> {
    await this.service.openDocument(url, extension);
  }
}
