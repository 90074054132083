import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { getPSDChartSelectionData, IPSDChartSelectionData } from '../../../../../+store/psd-analysis/psd-analysis.selectors';
import { notEmpty } from '@dunefront/common/common/state.helpers';
import { DrawableContentProviderComponent } from '../../../../../shared/services/drawable-registry.service';

@Component({
  templateUrl: './consolidated-psd-chart-renderer.component.html',
})
export class ConsolidatedPsdChartRendererComponent implements DrawableContentProviderComponent {
  @Input() public drawableProviderId?: string;
  public psdChartSelectionData$: Observable<IPSDChartSelectionData[]> = notEmpty(this.store.select(getPSDChartSelectionData));

  constructor(protected store: Store) {}
}
