import { Routes } from '@angular/router';
import { SettingsComponent } from './settings.component';
import { ProjectOptionsComponent } from './project-options/project-options.component';
import { GlobalOptionsComponent } from './global-options/global-options.component';
import { DeveloperSettingsComponent } from './developer-options/developer-settings.component';
import {
  RouteModuleSettings,
  RouteModuleSettingsDeveloper,
  RouteModuleSettingsProject,
  RouteModuleSettingsOrganization,
  RouteModuleSettingsUser,
} from './settings-routes-names';
import { AdminAuthGuard } from '../../../common-modules/auth/guards/admin-auth.guard';

export const settingsRoutes: Routes = [
  {
    path: RouteModuleSettings,
    component: SettingsComponent,
    data: {
      breadcrumb: 'Settings',
    },
    children: [
      {
        path: RouteModuleSettingsProject,
        component: ProjectOptionsComponent,
        data: {
          breadcrumb: 'Project',
        },
      },
      {
        path: RouteModuleSettingsUser,
        component: GlobalOptionsComponent,
        data: {
          breadcrumb: 'User',
        },
      },
      {
        path: RouteModuleSettingsOrganization,
        component: GlobalOptionsComponent,
        canActivate: [AdminAuthGuard],
        data: {
          breadcrumb: 'Organization',
        },
      },
      {
        path: RouteModuleSettingsDeveloper,
        component: DeveloperSettingsComponent,
        data: {
          breadcrumb: 'Developer',
        },
      },
      {
        path: '',
        redirectTo: RouteModuleSettingsProject,
        pathMatch: 'full',
      },
    ],
  },
];
