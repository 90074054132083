import { TemperatureVolumeDerivativeUnit } from '../../../dto/unit-system.dto';
import { UnitConverter } from '../../converter.interfaces';

export class TemperatureVolumeDerivativeConverter extends UnitConverter {
  public static symbols: Array<string> = [
    '',
    '°C/m³',
    '°C/ft³',
    '°C/bbl',
    '°C/L',
    '°C/galUS',
    'K/m³',
    'K/ft³',
    'K/bbl',
    'K/L',
    'K/galUS',
    '°F/m³',
    '°F/ft³',
    '°F/bbl',
    '°F/L',
    '°F/galUS',
  ];

  public static unit = TemperatureVolumeDerivativeUnit;

  public static fromSi(value: number, unit: TemperatureVolumeDerivativeUnit): number {
    switch (unit) {
      case TemperatureVolumeDerivativeUnit.Celsius_per_cubic_meter: {
        return value;
      }
      case TemperatureVolumeDerivativeUnit.Celsius_per_cubic_foot: {
        return value * 2.83169e-2;
      }
      case TemperatureVolumeDerivativeUnit.Celsius_per_barrel: {
        return value * 1.58987e-1;
      }
      case TemperatureVolumeDerivativeUnit.Celsius_per_liter: {
        return value * 1e-3;
      }
      case TemperatureVolumeDerivativeUnit.Celsius_per_US_gallon: {
        return value * 3.78541e-3;
      }
      case TemperatureVolumeDerivativeUnit.Kelvin_per_cubic_meter: {
        return value;
      }
      case TemperatureVolumeDerivativeUnit.Kelvin_per_cubic_foot: {
        return value * 2.83169e-2;
      }
      case TemperatureVolumeDerivativeUnit.Kelvin_per_barrel: {
        return value * 1.58987e-1;
      }
      case TemperatureVolumeDerivativeUnit.Kelvin_per_liter: {
        return value * 1e-3;
      }
      case TemperatureVolumeDerivativeUnit.Kelvin_per_US_gallon: {
        return value * 3.78541e-3;
      }
      case TemperatureVolumeDerivativeUnit.Fahrenheit_per_cubic_meter: {
        return value * 1.8;
      }
      case TemperatureVolumeDerivativeUnit.Fahrenheit_per_cubic_foot: {
        return value * 5.097042e-2;
      }
      case TemperatureVolumeDerivativeUnit.Fahrenheit_per_barrel: {
        return value * 2.861766e-1;
      }
      case TemperatureVolumeDerivativeUnit.Fahrenheit_per_liter: {
        return value * 1.8e-3;
      }
      case TemperatureVolumeDerivativeUnit.Fahrenheit_per_US_gallon: {
        return value * 6.813738e-3;
      }
      default: {
        throw new TypeError('Unknown Temperature Volume Derivative Unit:' + unit);
      }
    }
  }

  public static toSi(value: number, unit: TemperatureVolumeDerivativeUnit): number {
    switch (unit) {
      case TemperatureVolumeDerivativeUnit.Celsius_per_cubic_meter: {
        return value;
      }
      case TemperatureVolumeDerivativeUnit.Celsius_per_cubic_foot: {
        return value / 2.83169e-2;
      }
      case TemperatureVolumeDerivativeUnit.Celsius_per_barrel: {
        return value / 1.58987e-1;
      }
      case TemperatureVolumeDerivativeUnit.Celsius_per_liter: {
        return value / 1e-3;
      }
      case TemperatureVolumeDerivativeUnit.Celsius_per_US_gallon: {
        return value / 3.78541e-3;
      }
      case TemperatureVolumeDerivativeUnit.Kelvin_per_cubic_meter: {
        return value;
      }
      case TemperatureVolumeDerivativeUnit.Kelvin_per_cubic_foot: {
        return value / 2.83169e-2;
      }
      case TemperatureVolumeDerivativeUnit.Kelvin_per_barrel: {
        return value / 1.58987e-1;
      }
      case TemperatureVolumeDerivativeUnit.Kelvin_per_liter: {
        return value / 1e-3;
      }
      case TemperatureVolumeDerivativeUnit.Kelvin_per_US_gallon: {
        return value / 3.78541e-3;
      }
      case TemperatureVolumeDerivativeUnit.Fahrenheit_per_cubic_meter: {
        return value / 1.8;
      }
      case TemperatureVolumeDerivativeUnit.Fahrenheit_per_cubic_foot: {
        return value / 5.097042e-2;
      }
      case TemperatureVolumeDerivativeUnit.Fahrenheit_per_barrel: {
        return value / 2.861766e-1;
      }
      case TemperatureVolumeDerivativeUnit.Fahrenheit_per_liter: {
        return value / 1.8e-3;
      }
      case TemperatureVolumeDerivativeUnit.Fahrenheit_per_US_gallon: {
        return value / 6.813738e-3;
      }
      default: {
        throw new TypeError('Unknown Temperature Volume Derivative Unit:' + unit);
      }
    }
  }

  public static getSymbol(unit: TemperatureVolumeDerivativeUnit): string {
    if (unit == null) {
      return '';
    }
    return this.symbols[unit];
  }
}
