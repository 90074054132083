import { Action, createAction, props } from '@ngrx/store';
import { LicenseFeature, LicenseInfo, LicensingLoginResponse } from '@dunefront/common/modules/licensing/licensing.interfaces';
import { IFile } from '@dunefront/common/dto/file.dto';
import { ConnectionLicensingConfig } from '@dunefront/common/modules/db-connection/db-connection.actions';

export const loadLicenseInfoAction = createAction('[Licensing] LoadLicenseInfo');
export const licenseInfoLoadedAction = createAction(
  '[Licensing] LicenseInfoLoaded',
  props<{
    licenseInfo: LicenseInfo;
  }>(),
);

export const openModuleSelectorDialog = createAction(
  '[Licensing] Open Module Selector',
  props<{ redirectHomeAfterCancel: boolean; file: IFile }>(),
);
export const refreshLicenseInfoSuccessAction = createAction(
  '[Licensing] Refresh LoadLicenseInfo Success',
  props<{ licenseInfo: LicenseInfo }>(),
);
export const licenseSwitchAction = createAction(
  '[Licensing] LicenseSwitchAction',
  props<{
    licensingConfig: ConnectionLicensingConfig;
  }>(),
);
export const licenseSwitchSuccessAction = createAction(
  '[Licensing] LicenseSwitchSuccessAction',
  props<{ licensingLoginResponse: LicensingLoginResponse }>(),
);
export const licenseReloginUserAction = createAction(
  '[Licensing] ReloginUser',
  props<{ auto: boolean; licensingConfig: ConnectionLicensingConfig; isChangingModule: boolean }>(),
);
export const licenseReloginUserSuccessAction = createAction(
  '[Licensing] ReloginUserSuccess',
  props<{ payload: { licensingLoginResponse: LicensingLoginResponse }; auto: boolean; isChangingModule: boolean }>(),
);
export const updateMainLicenseAction = createAction(
  '[Licensing] updateMainLicenseAction',
  props<{
    feature: LicenseFeature;
  }>(),
);
export const licensingFailureAction = createAction(
  '[Licensing] licensingFailureAction',
  props<{
    error: unknown;
    srcAction: Action;
  }>(),
);

export const generateLicensingDiagnosticsReportAction = createAction('[Licensing] generateLicensingDiagnosticsReportAction');
export const detachLicenseAction = createAction(
  '[Licensing] detachLicenseAction',
  props<{ licenseId: string; productId: string; duration: number }>(),
);
export const detachLicenseSuccessAction = createAction('[Licensing] detachLicenseSuccessAction');
export const cancelDetachLicenseAction = createAction(
  '[Licensing] cancelDetachLicenseAction',
  props<{ licenseId: string; parentLicenseId: string }>(),
);
export const cancelDetachLicenseSuccessAction = createAction('[Licensing] cancelDetachLicenseSuccessAction');
export const cancelDetachConnectionCheckFailureAction = createAction(
  '[Licensing] cancelDetachConnectionCheckFailureAction',
  props<{
    error: unknown;
    srcAction: Action;
  }>(),
);

export const toggleAddonAction = createAction(
  '[Licensing] toggleAddonAction',
  props<{
    licenseFeature: LicenseFeature;
  }>(),
);
