import { ScenarioDto } from './scenario.dto';

export interface Scenario extends Omit<ScenarioDto, 'CompareScenarioConfig'> {
  CompareScenarioIds: number[];
}

export interface IScenarioDict {
  [scenarioId: number]: Scenario;
  scenarioIds: number[];
  currentScenarioName: string;
}

export class ScenarioFactory {
  public static createEmptyScenarioDict(currentScenarioName: string): IScenarioDict {
    return { scenarioIds: [], currentScenarioName };
  }
  public static toDto(scenario: Scenario): ScenarioDto {
    const compareScenarioConfig = scenario.CompareScenarioIds.length ? JSON.stringify(scenario.CompareScenarioIds) : null;
    const { CompareScenarioIds, ...chartDto } = scenario;
    return { ...chartDto, CompareScenarioConfig: compareScenarioConfig };
  }

  public static fromDto(scenarioDto: ScenarioDto): Scenario {
    const CompareScenarioIds = this.parseCompareScenarioConfig(scenarioDto.CompareScenarioConfig);
    const { CompareScenarioConfig, ...chartModel } = scenarioDto;
    return { ...chartModel, CompareScenarioIds };
  }

  private static parseCompareScenarioConfig(compareScenarioConfig: string | null | undefined): number[] {
    let compareScenarioIds: number[] = [];

    if (compareScenarioConfig != null && compareScenarioConfig.length) {
      try {
        const parsedArray = JSON.parse(compareScenarioConfig);
        // Check if the parsed value is an array of numbers
        if (Array.isArray(parsedArray) && parsedArray.every((num) => typeof num === 'number')) {
          compareScenarioIds = parsedArray as number[];
        }
      } catch {
        // couldn't parse json
        // do nothing
      }
    }
    return compareScenarioIds;
  }
}
