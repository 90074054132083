import { SectionInformationRow } from '../model/volumes/section-information-row';

export interface SectionInformationDto {
  StartMD: number;
  StartTVD: number;
  EndMd: number;
  EndTvd: number;
  Length: number;
  Name: string;
  InnerVolume: number;
  OuterVolume: number;
  PackedGravelMass: number;
  SuspendedGravelMass: number;
  VolumeToSectionStart: number;
  TotalVolume: number;
}

export const createEmptySectionInformationDto = (): SectionInformationDto => ({
  StartMD: 0,
  StartTVD: 0,
  EndMd: 0,
  EndTvd: 0,
  Length: 0,
  Name: '',
  InnerVolume: 0,
  OuterVolume: 0,
  PackedGravelMass: 0,
  SuspendedGravelMass: 0,
  VolumeToSectionStart: 0,
  TotalVolume: 0,
});

export const createEmptyGaugeSectionInformationDto = (): { rows: SectionInformationRow[] } => ({ rows: [] });
