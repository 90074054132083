import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  ErrorHandler,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  NgModule,
  Output,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClient, HttpEventType, HttpHeaders, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ButtonModule } from '../button/button.module';
import { FileUploadModule } from 'primeng/fileupload';
import { ModalService } from '../../../common-modules/modals/modal.service';
import { firstValueFrom, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { IFile } from '@dunefront/common/dto/file.dto';
import { BackendConnectionService } from '../../backend-connection/backend-connection.service';
import { FileManagerModuleName, FileOverwriteAction } from '@dunefront/common/modules/file-manager/file-manager.actions';
import { dbDisconnectAction } from '../../../+store/backend-connection/backend-connection.actions';
import { getCurrentFolderState } from '../../../+store/file-manager/file-manager.selectors';

import { NgxFileDropEntry } from '../../../common-modules/ngx-file-drop/ngx-file-drop-entry';
import { FileSystemFileEntry } from '../../../common-modules/ngx-file-drop/dom.types';
import { NgxFileDropModule } from '../../../common-modules/ngx-file-drop/ngx-file-drop.module';
import { showFileOpenDialogSuccess } from '../../../+store/electron-main/electron-main.actions';
import { ElectronService } from '../../services/electron-service/electron.service';
import { notEmpty } from '@dunefront/common/common/state.helpers';
import { AppTargetConfig } from '../../services/app-target-config';
import { ClientErrorHandlerService } from '../error/client-error-handler.service';
import { getFilesJobsTypes } from '../../../+store/calculation-engine/files-jobs.selectors';
import { FileJobTypesHelper } from '../../../+store/calculation-engine/file-job-types-helper';
import { ClientAuthService } from '../../../common-modules/auth/client-auth.service';

export enum UploadStatus {
  SUCCESS = 0,
  FAILURE = 1,
  CANCELLED = 2,
}

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadComponent {
  @Input() public uploadUrl!: string;
  @Input() public accept = '';
  @Input() public fileListInFolder!: IFile[];
  @Output() public uploadFinished = new EventEmitter<UploadStatus>();
  @ViewChild('openFileSelector') public openFileSelector!: ElementRef;

  private allPpfFiles$ = notEmpty(this.store.select(getCurrentFolderState));
  private progressSubject: Subject<number> = new Subject();
  private isUploadOnGoing = false;
  public isDropZoneVisible = false;
  private isUploadAlertDisplayed = false;

  @HostListener('document:dragenter', ['$event'])
  public onDragEnter(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();

    // prevent drop when another upload is in progress
    if (this.isUploadOnGoing) {
      if (!this.isUploadAlertDisplayed) {
        this.isUploadAlertDisplayed = true;
        this.modalService.showAlert('Please wait, another file is being uploaded.', 'Warning').then(() => (this.isUploadAlertDisplayed = false));
      }
      return;
    }

    // don't show drop overlay for content other than files
    if (this.dragEventHasFiles(event) === false) {
      return;
    }

    this.isDropZoneVisible = true;
  }

  @HostListener('body:dragover', ['$event'])
  public onDragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }

  @HostListener('body:drop', ['$event'])
  public onDrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }

  constructor(
    private http: HttpClient,
    private modalService: ModalService,
    private store: Store,
    private authService: ClientAuthService,
    private backendConnectionService: BackendConnectionService,
    private electronService: ElectronService,
    public appConfig: AppTargetConfig,
    @Inject(ErrorHandler) private errorHandlerService: ClientErrorHandlerService,
  ) {}

  public async uploadFiles(files: NgxFileDropEntry[]): Promise<void> {
    this.isDropZoneVisible = false;
    if (files.length > 1) {
      await this.modalService.showAlert('You can only upload one file at a time.', 'Warning');
      return;
    }

    const extension = files[0].relativePath.split('.').reverse()[0];
    if (!this.accept.includes(extension)) {
      await this.modalService.showAlert(`You can only upload ${this.accept} files.`, 'Warning');
      return;
    }

    if (this.electronService.isElectronApp) {
      this.store.dispatch(showFileOpenDialogSuccess({ filePath: files[0].fileEntry.path }));
      return;
    }

    // check if file with the same name already exists the current folder
    const currentFolder = await firstValueFrom(this.allPpfFiles$);
    const fileName = files[0].fileEntry.name.replace(this.accept, '');
    const existingFile = currentFolder.Children?.find(
      (ppfFile: IFile) => ppfFile.Name.toLowerCase() === fileName.toLowerCase() && ppfFile.FileType === 'ppf-file',
    );
    if (existingFile !== undefined) {
      // check if existing file can be overwritten (no worker jobs are currently executed)
      const allFilesJobsTypes = await firstValueFrom(this.store.select(getFilesJobsTypes));
      const canOverwriteFile = FileJobTypesHelper.canUpdateFile(existingFile, allFilesJobsTypes);
      if (!canOverwriteFile) {
        await this.modalService.showAlert(
          `Project named ${fileName} already exists. The file is currently in use and cannot be overwritten.`,
          'Warning',
        );

        // early return!
        return;
      }

      const overwrite = await this.modalService.showConfirm(
        `Project with the name ${fileName} already exists, do you want to overwrite it?`,
        'Warning',
      );

      if (!overwrite) {
        // early return!
        return;
      }

      // file will be overwritten, enforce closing all db connections
      await this.backendConnectionService.emitAsync(FileManagerModuleName, false, new FileOverwriteAction(existingFile));
      this.store.dispatch(dbDisconnectAction());
    }

    this.sendFiles(files).then();
  }

  private get authHeaders(): HttpHeaders {
    const accessToken = this.authService.accessToken;
    if (accessToken == null || accessToken.length === 0) {
      throw new Error('Access token not found!');
    }

    const ppsessionid = this.authService.sessionId;
    if (ppsessionid == null || ppsessionid.length === 0) {
      throw new Error('Session ID not found!');
    }

    return new HttpHeaders({ authorization: 'Bearer ' + accessToken, ppsessionid });
  }

  private async sendFiles(files: NgxFileDropEntry[]): Promise<void> {
    this.isUploadOnGoing = true;

    const modal = this.modalService.showProgressIndicator('Uploading file', this.progressSubject, true);

    files.forEach((droppedFile: NgxFileDropEntry) => {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          const formData = new FormData();
          formData.append('file', file, droppedFile.relativePath);

          const request: any = this.http
            .post(this.uploadUrl, formData, { reportProgress: true, observe: 'events', headers: this.authHeaders })
            .pipe(
              map((event: any) => {
                switch (event?.type) {
                  case HttpEventType.UploadProgress:
                    this.progressSubject.next(Math.round((event.loaded * 100) / event.total));
                    if (event.loaded / event.total === 1) {
                      this.isUploadOnGoing = false;
                    }
                    break;
                  case HttpEventType.Response:
                    this.isUploadOnGoing = false;
                    return event;
                }
              }),
            )
            .subscribe({
              next: () => null,
              error: (error) => {
                this.errorHandlerService.handleError(error, false);
                this.uploadFinished.emit(UploadStatus.FAILURE);
              },
              complete: () => this.uploadFinished.emit(UploadStatus.SUCCESS),
            });

          modal.then((isUploadFinished) => {
            if (request && !isUploadFinished) {
              request.unsubscribe();
              this.isUploadOnGoing = false;
              this.uploadFinished.emit(UploadStatus.CANCELLED);
            }
          });
        });
      }
    });
  }

  public fileSelection(): void {
    this.openFileSelector.nativeElement.click();
  }

  private dragEventHasFiles(event: DragEvent): boolean {
    const dataTransfer = event.dataTransfer;
    if (dataTransfer == null) {
      return false;
    }

    if (dataTransfer.files.length > 0) {
      return true;
    }

    return Array.from(dataTransfer.items).some((f) => f.kind.toLowerCase() === 'file');
  }
}

@NgModule({
  declarations: [UploadComponent],
  exports: [UploadComponent],
  imports: [CommonModule, FormsModule, NgxFileDropModule, ButtonModule, FileUploadModule],
  providers: [provideHttpClient(withInterceptorsFromDi())],
})
export class UploadModule {}
