import { DbDependentComponent } from '../../../../common-modules/db-connection/db-dependent.component';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { getIsFluidStateLoaded, getSelectedFluidData, isCurrentFluidValid } from '../../../../+store/fluid/fluid.selectors';
import * as actions from '../../../../+store/fluid/fluid.actions';
import * as commonFluidActions from '../../../../+store/common-db/common-db.actions';
import { ModalService } from '../../../../common-modules/modals/modal.service';
import { take } from 'rxjs/operators';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { notEmpty } from '@dunefront/common/common/state.helpers';
import { isFluidDeletable } from '../../../../+store/fluid/fluid-delete.selectors';
import * as uiActions from '../../../../+store/ui/ui.actions';
import { Fluid } from '@dunefront/common/modules/fluid/model/fluid';
import { firstValueFrom } from 'rxjs';
import { StoreCrudPropsFactory } from '@dunefront/common/common/common-store-crud.interfaces';

@Component({
  selector: 'app-project-fluids',
  templateUrl: './project-fluids.component.html',
  styleUrls: ['./project-fluids.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectFluidsComponent extends DbDependentComponent {
  public currentFluid!: Fluid;
  public UnitType = UnitSystem;
  public isFluidsModuleLoaded$ = this.store.select(getIsFluidStateLoaded);

  constructor(
    store: Store,
    cdRef: ChangeDetectorRef,
    private modalService: ModalService,
  ) {
    super(store, cdRef);

    this.subscription.add(
      notEmpty(this.store.select(getSelectedFluidData)).subscribe((data) => {
        this.currentFluid = data.fluid;
        this.cdRef.markForCheck();
      }),
    );
    store.dispatch(uiActions.setFluidsTabAction({ tab: 'project-fluids' }));
  }

  private async checkIsValid(): Promise<boolean> {
    const isValid = await firstValueFrom(this.store.select(isCurrentFluidValid));
    if (!isValid) {
      await this.modalService.showAlert('Please correct all errors before saving the fluid to the database.');
    }
    return isValid ?? true;
  }

  public async saveToDatabase(): Promise<void> {
    const isValid = await this.checkIsValid();
    if (!isValid) {
      return;
    }

    const commonDbType = await this.modalService.getCommonDbType('fluid');
    if (!commonDbType) {
      return;
    }

    this.store.dispatch(
      commonFluidActions.copyProjectFluidToCommon({
        projectFluidId: this.currentFluid.Id,
        commonDbType,
        overwrite: false,
      }),
    );
  }

  public newFluid(): void {
    this.store.dispatch(actions.newFluid({ scenarioId: this.currentFluid.ScenarioId }));
  }

  public copyFluid(): void {
    this.store.dispatch(
      actions.copyFluid({
        fluidId: this.currentFluid.Id,
        scenarioId: this.currentFluid.ScenarioId,
        fluidName: this.currentFluid.Name,
      }),
    );
  }

  public async deleteFluid(): Promise<void> {
    this.store
      .select(isFluidDeletable)
      .pipe(take(1))
      .subscribe(async (fluidDeletable) => {
        if (fluidDeletable.length > 0) {
          await this.modalService.showAlert(fluidDeletable, 'Information');
          return;
        }
        const confirmResult = await this.modalService.showConfirm('Are you sure you want to delete the selected fluid?', 'Delete Fluid');
        if (!confirmResult) {
          return;
        }
        if (this.currentFluid == null) {
          return;
        }
        this.store.dispatch(actions.deleteFluid(StoreCrudPropsFactory.deleteRows(this.currentFluid, false)));
      });
  }
}
