<div class="grid-form-container">
  <label>{{ label || 'Search' }}</label>
  <app-input
    [isTextInput]="true"
    [value]="searchPhrase"
    [triggerOnKeyPress]="true"
    [width]="250"
    [maxWidth]="250"
    [align]="'left'"
    [autoFocus]="autofocus"
    (primitiveValueChanged)="searchChanged.emit($event.value)"
    [isUiLockable]="false"
    data-cy="searchbar"
  >
  </app-input>
</div>
