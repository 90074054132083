import { FlowPathType } from '../resuspension-calculator/resuspension-calculator.dto';
import { GravelDto } from '../../gravel/dto/gravel.dto';
import { FluidWithDependenciesDto } from '../../fluid/dto/fluid.dto';
import { DeveloperSettingsDto } from '../../settings/dto/settingsDto';

export interface FrictionCalculatorDto {
  ScenarioId: number;
  FlowPathType: FlowPathType;
  Length: number;
  MinDiameter: number;
  MaxDiameter: number;
  Width: number;
  Height: number;
  FluidId: number | null;
  GravelId: number | null;
  Concentration: number;
  MaxRate: number;
  RateStep: number;
  Temperature: number;
  Roughness: number;
  Eccentricity: number;
  UseShuntVESFrictionCorrelations: boolean;
  UseDegradationFactor: boolean;
  IsXAxisRate: FrictionXAxis;
}

export enum FrictionXAxis {
  Velocity = 0,
  Rate = 1,
}

export interface FrictionCalculatorJobInputDataDTO {
  Input: FrictionCalculatorDto;
  Gravels: GravelDto[];
  Fluids: FluidWithDependenciesDto[];
  DeveloperSettings: DeveloperSettingsDto;
}

export interface FrictionCalculatorJobResults {
  PumpRates: number[];
  PumpVelocities: number[];
  FrictionPressures: number[];
}

export interface FrictionCalculatorResultDto {
  ScenarioId: number;
  Rate: number;
  Velocity: number;
  Friction: number;
}
