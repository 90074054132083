import { Fluid } from './fluid';
import { DensityConverter } from '../../../unit-converters/converters/density/density.converter';
import { ConvertUnitPipe } from '../../units/convert-unit.pipe/convert-unit.pipe';
import { DictionaryWithArray, IDictionaryWithArray, noErrors } from '../../../common/state.helpers';
import { DensityUnit, UnitSystem } from '../../../dto/unit-system.dto';
import { IError } from '../../../common/common-state.interfaces';
import { FluidModuleState, IFluidValidationDependencies, ValidatedFluidModuleState } from '../fluid-module.state';
import { RheologyValidation } from './rheology/rheology.validation';
import { RheometerReadingValidation } from './rheometer-reading/rheometer-reading.validation';
import { RheometerValidation } from './rheometer/rheometer.validation';
import { ModuleType } from '../../scenario/scenario.dto';
import { validateFeatureNotSupportedInFluidPro } from '../../licensing/licensing.validator';

export class FluidValidation {
  public static validate(state: FluidModuleState, deps: IFluidValidationDependencies): ValidatedFluidModuleState {
    const validatedFluids = FluidValidation.ValidateFluids(state.Fluids, deps.currentModuleType);
    const validatedRheologies = RheologyValidation.ValidateRheologies(state);
    const validatedRheometerReadings = RheometerReadingValidation.ValidateRheometerReadings(state);
    const validatedRheometers = RheometerValidation.ValidateRheometers(state, validatedRheometerReadings);

    const validFluidIds: number[] = [];
    validatedFluids.ids.forEach((fluidId) => {
      const fluid = DictionaryWithArray.get(validatedFluids, fluidId);
      if (!fluid) {
        return;
      }
      fluid.isValid = fluid.isValid && !!validatedRheologies.dict[fluidId]?.isValid && !!validatedRheometers.dict[fluidId]?.isValid;
      if (fluid.isValid) {
        validFluidIds.push(fluid.Id);
      }
    });

    const isFluidsValid = DictionaryWithArray.isValid(validatedFluids);

    const isRheologiesValid = DictionaryWithArray.isValid(validatedRheologies);
    const isRheometersValid = DictionaryWithArray.isValid(validatedRheometers);
    const isShearRateValid = deps.settings.error.ShearRate === '';
    const isProjectFluidsScreenValid = isFluidsValid && isShearRateValid;
    const isFluidStateValid = isProjectFluidsScreenValid && isRheologiesValid && isRheometersValid;
    return {
      ...state,
      isValid: isFluidStateValid,
      Fluids: validatedFluids,
      Rheologies: validatedRheologies,
      RheometerReadings: validatedRheometerReadings,
      Rheometers: validatedRheometers,
      isShearRateValid,
      validFluidIds,
      error: {},
    };
  }

  private static ValidateFluids(fluidsDict: IDictionaryWithArray<Fluid>, currentModuleType: ModuleType): IDictionaryWithArray<Fluid> {
    let newFluids = { ...fluidsDict };
    fluidsDict.ids.forEach((fluidId) => {
      const fluid = DictionaryWithArray.getCopy(fluidsDict, fluidId);
      if (!fluid) {
        return;
      }

      const ignoreAdvancedProps = currentModuleType === ModuleType.Simulate_Disp;

      const error: IError<Fluid> = {
        Name: this.validateName(fluid.Name, fluidsDict),
        CleanFluidDensity: this.ValidateDensity(fluid.CleanFluidDensity),
        IsGravelSettling: fluid.IsGravelSettling ? validateFeatureNotSupportedInFluidPro(currentModuleType) : '',
        DragReductionFactor: this.ValidateDragReductionFactor(fluid.DragReductionFactor),

        ThermalConductivity: ignoreAdvancedProps ? '' : this.ValidateThermalConductivity(fluid.ThermalConductivity),
        SpecificHeat: ignoreAdvancedProps ? '' : this.ValidateSpecificHeatCapacity(fluid.SpecificHeat),
        MinViscosity: ignoreAdvancedProps
          ? ''
          : this.ValidateMinViscosity(fluid.MinViscosity, fluid.MaxViscosity, fluid.IsMinViscosity, fluid.IsMaxViscosity),
        MaxViscosity: ignoreAdvancedProps
          ? ''
          : this.ValidateMaxViscosity(fluid.MinViscosity, fluid.MaxViscosity, fluid.IsMinViscosity, fluid.IsMaxViscosity),
        WashpipeViscousDegradationFactor: ignoreAdvancedProps
          ? ''
          : this.ValidateLaminarFrictionFactorMultiplier(fluid.WashpipeViscousDegradationFactor),
        AnnulusViscousDegradationFactor: ignoreAdvancedProps
          ? ''
          : this.ValidateAnnulusViscousFluidDegradationFactor(fluid.AnnulusViscousDegradationFactor),
      };
      fluid.error = error;
      fluid.isValid = noErrors(error);
      newFluids = DictionaryWithArray.upsertById(newFluids, fluid, fluidId);
    });
    return newFluids;
  }

  // region fluid

  private static ValidateLaminarFrictionFactorMultiplier(WashpipeViscousDegradationFactor: number): string {
    if (WashpipeViscousDegradationFactor <= 0) {
      return 'Washpipe viscous fluid degradation factor must be greater than 0';
    }
    return '';
  }

  private static ValidateAnnulusViscousFluidDegradationFactor(AnnulusViscousDegradationFactor: number): string {
    if (AnnulusViscousDegradationFactor <= 0) {
      return 'Annulus viscous fluid degradation factor must be greater than 0';
    }
    return '';
  }

  private static validateName(name: string, fluids: IDictionaryWithArray<Fluid>): string {
    if (name.length === 0) {
      return "Fluid name can't be empty";
    }
    if (DictionaryWithArray.getArray(fluids).filter((fluid) => fluid.Name === name).length > 1) {
      return 'The name already exists - please use a different one';
    }
    return '';
  }

  private static ValidateDensity(density: number): string {
    const maxFluidDensity = DensityConverter.toSi(10, DensityUnit.Specific_Gravity);
    if (density <= 0 || density > maxFluidDensity) {
      const encodeValue = ConvertUnitPipe.encode(UnitSystem.Liquid_Density, maxFluidDensity);
      return `Density must be between 0 and ${encodeValue}`;
    }
    return '';
  }

  private static ValidateThermalConductivity(thermalConductivity: number): string {
    if (thermalConductivity <= 0) {
      return 'Thermal conductivity must be greater than 0';
    }
    return '';
  }

  private static ValidateSpecificHeatCapacity(specificHeatCapacity: number): string {
    if (specificHeatCapacity <= 0) {
      return 'Specific heat capacity must be greater than 0';
    }
    return '';
  }

  private static ValidateDragReductionFactor(dragReductionFactor: number): string {
    if (dragReductionFactor < 0 || dragReductionFactor > 100) {
      return 'Drag reduction must be between 0 and 100';
    }
    return '';
  }

  private static ValidateMaxViscosity(
    minViscosity: number,
    maxViscosity: number,
    isMinViscosity: boolean,
    isMaxViscosity: boolean,
  ): string {
    if (isMaxViscosity && maxViscosity <= 0) {
      return 'Max viscosity must be greater than zero';
    }
    if (isMinViscosity && isMaxViscosity && maxViscosity <= minViscosity) {
      return 'Max viscosity must be greater than min viscosity';
    }
    return '';
  }

  private static ValidateMinViscosity(
    minViscosity: number,
    maxViscosity: number,
    isMinViscosity: boolean,
    isMaxViscosity: boolean,
  ): string {
    if (isMinViscosity && minViscosity <= 0) {
      return 'Min viscosity must be greater than zero';
    }
    if (isMinViscosity && isMaxViscosity && maxViscosity <= minViscosity) {
      return 'Min viscosity must be less than max viscosity';
    }
    return '';
  }

  // endregion
}

export const isFluidValid = (fluids: IDictionaryWithArray<Fluid>, fluidId: number): string => {
  const fluid = DictionaryWithArray.get(fluids, fluidId);
  if (fluid && !fluid.isValid) {
    return 'Selected Fluid is not valid';
  }
  return '';
};
