import { UnitConverter } from '../../converter.interfaces';
import { LinearMassUnit } from '../../../dto/unit-system.dto';

export class LinearMassConverter extends UnitConverter {
  public static symbols: Array<string> = ['', 'kg/m', 'lbs/ft', 'kg/ft', 'lbs/m'];

  public static unit = LinearMassUnit;

  public static fromSi(value: number, unit: LinearMassUnit): number {
    switch (unit) {
      case LinearMassUnit.KilogramPerMeter: {
        return value;
      }
      case LinearMassUnit.PoundPerFoot: {
        return value * 0.671968975;
      }
      case LinearMassUnit.KilogramPerFoot: {
        return value * 0.3048;
      }
      case LinearMassUnit.PoundPerMeter: {
        return value * 2.20462262;
      }
      default: {
        throw new TypeError('Unknown Linear Mass Unit: ' + unit);
      }
    }
  }

  public static toSi(value: number, unit: LinearMassUnit): number {
    switch (unit) {
      case LinearMassUnit.KilogramPerMeter: {
        return value;
      }
      case LinearMassUnit.PoundPerFoot: {
        return value / 0.671968975;
      }
      case LinearMassUnit.KilogramPerFoot: {
        return value / 0.3048;
      }
      case LinearMassUnit.PoundPerMeter: {
        return value / 2.20462262;
      }
      default: {
        throw new TypeError('Unknown Linear Mass Unit: ' + unit);
      }
    }
  }

  public static getSymbol(unit: LinearMassUnit): string {
    return this.symbols[unit];
  }
}
