import { CalculationEngineModuleName } from '@dunefront/common/modules/calculation-engine/calculation-engine.actions';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  CalculationEngineState,
  CalculationStatus,
  CurrentFileCalculationEngineState,
  getEmptyFileCalculationEngineState,
  ICalculationJob,
  isUiBlockingStatus,
} from './calculation-engine-module.state';
import { areCalcContextsEqual, RedisConnectionStatus } from '@dunefront/common/modules/calculation-engine/calculation-engine.interfaces';
import { ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import { getFileHash } from '../backend-connection/backend-connection.selectors';
import { getCurrentCalculationContext } from './calculation-engine.context.selectors';

const getCalculationEngineState = createFeatureSelector<CalculationEngineState>(CalculationEngineModuleName);

export const getCalcEngineHashesAndJobs = createSelector(getCalculationEngineState, (state) => {
  return state.fileHash;
});

export const getCurrentFileCalculationEngineState = createSelector(
  getCalculationEngineState,
  getFileHash,
  (...[state, fileHash]): CurrentFileCalculationEngineState => {
    if (!fileHash) {
      return getEmptyFileCalculationEngineState();
    }
    return state.fileHash[fileHash] ?? getEmptyFileCalculationEngineState();
  },
);

export const getJobForCurrentContext = createSelector(
  getCurrentFileCalculationEngineState,
  getCurrentCalculationContext,
  (state, context) => {
    if (context == null) {
      return undefined;
    }

    return state.jobs.find((job) => areCalcContextsEqual(job, context));
  },
);

export const getCurrentFileHasAnyUIBlockingJobs = createSelector(getCurrentFileCalculationEngineState, (state) => {
  return state.jobs.some((job) => isUiBlockingStatus(job.status));
});

export const getRedisJobConnectionStatus = createSelector(getCalculationEngineState, (state): IRedisJobConnectionStatus => {
  const currentFileHashes = Object.keys(state.fileHash);
  const runningJobs: string[] = [];
  currentFileHashes.forEach((fileHash) => {
    const currentFileRunningJobs = state.fileHash[fileHash].jobs.some((job) => job.status === CalculationStatus.running);
    if (currentFileRunningJobs) {
      runningJobs.push(fileHash);
    }
  });

  return {
    status: state.redisConnectionStatus,
    runningJobs,
  };
});

export const getIsTrendAnalysisRunning = createSelector(
  getJobForCurrentContext,
  (activeJob) =>
    activeJob &&
    activeJob.moduleType === ModuleType.Trend_Analysis &&
    ![CalculationStatus.failed, CalculationStatus.canceled, CalculationStatus.completed].includes(activeJob.status),
);

export const getAllUserJobs = createSelector(getCalculationEngineState, (state): ICalculationJob[] => {
  const allJobs: ICalculationJob[] = [];

  for (const hash in state.fileHash) {
    const fileState = state.fileHash[hash];
    allJobs.push(...fileState.jobs);
  }

  // sort jobs
  allJobs.sort((a, b) => {
    // by status
    if (a.status !== b.status) {
      return b.status - a.status;
    }

    // for same statuses by position in queue if present
    if (a.positionInRemoteQueue != null && b.positionInRemoteQueue != null) {
      return a.positionInRemoteQueue - b.positionInRemoteQueue;
    }

    // otherwise by name (id)
    return a.jobId.localeCompare(b.jobId);
  });

  return allJobs;
});

export const getUserHasAnyWaitingOrRunningJobs = createSelector(getAllUserJobs, (userJobs) => {
  return userJobs.some((job) => job.status === CalculationStatus.running || job.status === CalculationStatus.waiting);
});

export interface IRedisJobConnectionStatus {
  status: RedisConnectionStatus;
  runningJobs: string[];
}
