import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import {
  getCurrentColIndex,
  ImportDataModuleState,
  IValidatedImportDataModuleState,
} from '../../../../+store/import-data/import-data-module.state';
import { ImportDataStep } from '../import-data.component';
import { ImportDataValidation } from '../../../../+store/import-data/validation/import-data.validation';
import { IParsedFileResult } from '../../../../+store/import-data/model/parsed-result';
import {
  ImportDataColumnDelimiterConfig,
  ImportDataFilePropertiesConfig,
} from '@dunefront/common/modules/data-storage/dto/import-template/import-template.dto';
import { IUnitSystemDto } from '@dunefront/common/dto/unit-system.dto';

@Component({
  selector: 'app-file-preview',
  templateUrl: './file-preview.component.html',
  styleUrls: ['./file-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilePreviewComponent {
  @Input()
  public parsedResult!: IParsedFileResult;

  @Input()
  public state!: IValidatedImportDataModuleState;

  @Input()
  public step!: ImportDataStep;

  @Input()
  public currentUnitSystem!: IUnitSystemDto;

  @Output()
  public stateChange = new EventEmitter<ImportDataModuleState>();

  @Output()
  public dateFormatsHelpClicked = new EventEmitter();

  @HostBinding('class')
  public get hostClass(): string {
    return 'step-' + this.step;
  }

  public isShowingHiddenColumns = true;

  public get dataError(): string {
    return ImportDataValidation.areThereAnyRowsToImport(this.parsedResult.data);
  }

  public get isInDefineDataMode(): boolean {
    return this.step === ImportDataStep.DefineData;
  }

  public onDelimiterConfigChanged(delimiterConfig: ImportDataColumnDelimiterConfig): void {
    this.stateChange.emit({ ...this.state, delimiterConfig });
  }

  public onFilePropertiesConfigChange(filePropertiesConfig: ImportDataFilePropertiesConfig): void {
    this.stateChange.emit({ ...this.state, filePropertiesConfig });
  }

  public onShowHiddenColumnsChanged(isShowingHiddenColumns: boolean): void {
    this.isShowingHiddenColumns = isShowingHiddenColumns;
  }

  public get header(): string {
    return this.step === ImportDataStep.Preview
      ? 'Preview'
      : 'Define Data - ' + this.parsedResult.columnsWithUnits[getCurrentColIndex(this.state)]?.columnName;
  }

  public onStateChanged(state: ImportDataModuleState, customTimeFormatColumnId: number | null): void {
    if (customTimeFormatColumnId != null && !this.state.colConfigs[customTimeFormatColumnId].possibleCustomFormats.length) {
      const colConfig = this.state.colConfigs[customTimeFormatColumnId];
      const possibleCustomFormats = ImportDataValidation.detectTimeFormat(colConfig);

      state = {
        ...state,
        colConfigs: state.colConfigs.map((colConfig, index) =>
          index === customTimeFormatColumnId ? { ...colConfig, possibleCustomFormats } : colConfig,
        ),
      };
    }

    this.stateChange.emit(state);
  }
}
