import { NgZone } from '@angular/core';
import { WsAction } from '@dunefront/common/ws.action';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as actions from '../../+store/backend-connection/backend-connection.actions';
import { encodeModuleName } from '@dunefront/common/modules/common.interfaces';
import { io, Socket } from 'socket.io-client';
import { DocumentExtension, fileExtensions } from '@dunefront/common/common/helpFile';
import { BaseBackendConnectionService } from './base.backend-connection.service';
import { getBackendToClientConfig } from '@dunefront/common/backend-to-client-config';
import { BACKEND_CONNECTED_NOTIFICATION_DELAY } from '@dunefront/common/common/constants';
import { WsActionResponse } from '@dunefront/common/response-ws.action';

export class WsConnectionService extends BaseBackendConnectionService {
  private socket: Socket | undefined;

  constructor(
    private ngZone: NgZone,
    store: Store,
  ) {
    super(store);
    console.warn('USING WS CONNECTION');
  }

  public async connect(accessToken: string, sessionId: string): Promise<boolean> {
    console.warn(`WS CONNECTION - CONNECT - SessionId: ${sessionId}`);
    if (this.socket && this.socket.connected) {
      // token has been refreshed. We shouldn't update
      this.socket.io.opts.query = { token: accessToken, ppSessionId: sessionId };
      return Promise.resolve(false);
    }
    this.ngZone.runOutsideAngular(() => {
      this.socket = io(getBackendToClientConfig().ServerBaseUrl, {
        reconnection: true,
        autoConnect: true,
        forceNew: true,
        query: { token: accessToken, ppSessionId: sessionId },
        timeout: 10000,
        transports: ['websocket'],
        upgrade: false,
      });

      this.socket.on('reconnect', (attempt: number) => {
        console.warn('WS: Reconnect ', attempt);
      });
      this.socket.on('reconnect_attempt', (attempt: number) => {
        console.warn('WS: reconnect_attempt ', attempt);
      });
      this.socket.on('reconnect_error', (error: unknown) => {
        console.error('WS: Reconnect Error ', error);
      });
      this.socket.on('reconnect_failed', () => {
        console.error('WS: Reconnect Failed ');
      });

      this.socket.on('disconnect', () => this.ngZone.run(() => this.store.dispatch(actions.backendDisconnectedAction())));
      this.socket.on('connect', () =>
        this.ngZone.run(() =>
          setTimeout(() => this.store.dispatch(actions.backendConnectedAction()), BACKEND_CONNECTED_NOTIFICATION_DELAY),
        ),
      );
      this.socket.on('connect_error', (error: any) => this.ngZone.run(() => this.dispatchError(error)));
      this.socket.on('error', (error: any) => this.ngZone.run(() => this.dispatchError(error)));
      this.socket.connect();
    });
    return true;
  }

  public override get socketId(): string | undefined {
    return this.socket?.id;
  }

  public disconnect(): void {
    this.socket?.disconnect();
  }

  public async emitAsync<T>(moduleName: string, isDbDependent: boolean, action: WsAction): Promise<WsActionResponse<T>> {
    return new Promise((resolve, reject) => {
      if (this.socket == null || !this.socket.connected) {
        throw new Error('Socket not connected!');
      }
      this.socket?.emit(encodeModuleName(moduleName, isDbDependent), action, (response: WsActionResponse<T>) =>
        this.ngZone.run(() => this.handleEmitAsyncResponse(response, resolve, reject)),
      );
    });
  }

  public emit<T>(moduleName: string, isDbDependent: boolean, action: WsAction): Observable<WsActionResponse<T>> {
    return new Observable<WsActionResponse<T>>((observer) => {
      this.socket?.emit(encodeModuleName(moduleName, isDbDependent), action, (response: WsActionResponse<T>) => {
        this.ngZone.run(() => this.handleObservableResponse(observer, response));
      });
    });
  }

  public on(moduleName: string, isDbDependent: boolean): Observable<WsAction> {
    return new Observable<WsAction>((observer) => {
      if (!this.socket) {
        return;
      }
      this.socket.off(encodeModuleName(moduleName, isDbDependent));
      this.socket.on(encodeModuleName(moduleName, isDbDependent), (args: WsAction) => {
        this.ngZone.run(() => {
          observer.next(args);
        });
      });
    });
  }

  public async openDocument(url: string, extension: DocumentExtension = fileExtensions.HTML): Promise<void> {
    const win = window.open('assets/' + url + extension, '_blank');
    if (win != null) {
      win.focus();
    }
    return Promise.resolve();
  }
}
