import { ChangeDetectorRef, Component, HostListener } from '@angular/core';
import { Store } from '@ngrx/store';
import { DbDependentComponent } from '../../../common-modules/db-connection/db-dependent.component';
import * as uiActions from '../../../+store/ui/ui.actions';
import { ModalService } from '../../../common-modules/modals/modal.service';
import { take } from 'rxjs/operators';
import { getCurrentFileCalculationEngineState } from '../../../+store/calculation-engine/calculation-engine.selectors';

@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.scss'],
})
export class ResultsComponent extends DbDependentComponent {
  constructor(
    store: Store,
    cdRef: ChangeDetectorRef,
    private modalService: ModalService,
  ) {
    super(store, cdRef);
    store.dispatch(uiActions.setAppSectionAction({ section: 'results' }));
  }

  @HostListener('document:keydown.F10', ['$event'])
  public showCalcEngineReport(e: KeyboardEvent): void {
    e.preventDefault();
    this.store
      .select(getCurrentFileCalculationEngineState)
      .pipe(take(1))
      .subscribe((state) => {
        const message = state.lastCalculationJobReport !== '' ? state.lastCalculationJobReport : 'Calculation Engine Task Report not found';
        this.modalService.showAlert(message, 'Calculation Engine Task Report', 'lg').then();
      });
  }
}
