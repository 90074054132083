import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Color, SeriesLineStyle } from '@dunefront/common/modules/reporting/dto/chart.types';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { EnumHelpers } from '@dunefront/common/utils/enum.helpers';
import { ISelectItem } from '@dunefront/common/common/select.helpers';
import { IEffectiveSeriesStyle, IGlobalSeriesStyles } from '@dunefront/common/modules/reporting/dto/chart-series.dto';
import { MarkerShape } from '@dunefront/common/modules/reporting/dto/chart-marker.dto';
import { ChartSeriesTemplateDto } from '@dunefront/common/dto/chart-series-template.dto';
import { defaultChartSeriesColours } from '@dunefront/common/common/constants';
import { getChangeProp, ObjectChangeProp, PrimitiveChangeValue } from '@dunefront/common/common/common-state.interfaces';
import { presetLineThicknessItems, presetPointMarkersSizeItems } from '../../chart.helpers';

type StylesObjType = IGlobalSeriesStyles | ChartSeriesTemplateDto;

@Component({
  selector: 'app-chart-series-style',
  templateUrl: './chart-series-style.component.html',
  styleUrls: ['./chart-series-style.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartSeriesStyleComponent implements OnInit {
  public seriesColors: string[] = [];

  public isTemplateMode!: boolean;
  public stylesObj: StylesObjType | undefined;
  @Input() public defaultSeriesStyle?: IGlobalSeriesStyles;
  public defaultSeriesColors: string[] = [];

  public get templateDataSource(): ChartSeriesTemplateDto | undefined {
    if (this.isTemplateMode) {
      return this.stylesObj as ChartSeriesTemplateDto;
    }
    return undefined;
  }

  public get globalStylesDataSource(): IGlobalSeriesStyles | undefined {
    if (!this.isTemplateMode) {
      return this.stylesObj as IGlobalSeriesStyles;
    }
    return undefined;
  }

  public Colors = EnumHelpers.StringEnumToSelectItemArray(Color);

  @Input()
  public set seriesStyle(styles: IGlobalSeriesStyles) {
    this.isTemplateMode = false;
    this.stylesObj = styles;
    this.initColorsArray();
  }

  @Input()
  public set seriesTemplate(template: ChartSeriesTemplateDto) {
    this.isTemplateMode = true;
    this.stylesObj = template;
  }

  @Input() public disabled = false;

  @Output() public valueChanged = new EventEmitter<ObjectChangeProp<IGlobalSeriesStyles> | ObjectChangeProp<IEffectiveSeriesStyle>>();

  public readonly lineThicknessSelect = presetLineThicknessItems;
  public readonly pointMarkersSizeSelect = presetPointMarkersSizeItems;
  protected readonly MarkerShape = MarkerShape;
  protected readonly SeriesLineStyle = SeriesLineStyle;
  public readonly seriesLineStyles = EnumHelpers.EnumToISelectItemArray(SeriesLineStyle, false);
  public readonly pointMarkerShapes = EnumHelpers.EnumToISelectItemArray(MarkerShape, false);
  public readonly seriesPointMarkerItems: ISelectItem<boolean>[] = [
    { value: false, text: 'Disabled' },
    { value: true, text: 'Enabled' },
  ];

  public UnitType = UnitSystem;

  public emitStyleValue(event: ObjectChangeProp<IGlobalSeriesStyles> | ObjectChangeProp<IEffectiveSeriesStyle>): void {
    if (event.key === 'SeriesLineStyle' && event.value === SeriesLineStyle.scatter) {
      this.valueChanged.emit(getChangeProp<StylesObjType>('SeriesPointMarkersEnabled', true, event.shouldResetResults));
    }

    this.valueChanged.emit(event);
  }

  public emitSeriesColourValueChange(event: PrimitiveChangeValue<string>): void {
    this.emitStyleValue(getChangeProp<IEffectiveSeriesStyle>('SeriesColour', event.value, event.shouldResetResults));
  }

  public ngOnInit(): void {
    if (!this.isTemplateMode) {
      this.initColorsArray();
    }
  }

  public initColorsArray(): void {
    const colorsArr = [...defaultChartSeriesColours];

    const allowedColours = EnumHelpers.StringEnumToArray(Color);

    if (this.stylesObj && 'SeriesColours' in this.stylesObj) {
      this.stylesObj.SeriesColours.split(',').forEach((color: string, idx: number) => {
        if (color.length && allowedColours.includes(color)) {
          colorsArr[idx] = color as Color;
        }
      });
    }
    this.seriesColors = colorsArr;
    this.defaultSeriesColors = this.defaultSeriesStyle?.SeriesColours.split(',') ?? [];
    this.onColorChanged(true);
  }

  public onColorChanged(doNotEmitValueChange = false): void {
    if (!this.isTemplateMode && this.stylesObj && 'SeriesColours' in this.stylesObj) {
      this.stylesObj = { ...this.stylesObj, SeriesColours: this.seriesColors.join(',') };

      if (doNotEmitValueChange === false) {
        this.emitStyleValue(getChangeProp<IGlobalSeriesStyles>('SeriesColours', this.stylesObj.SeriesColours, false));
      }
    }
  }

  public trackByFn = (index: number): number => index;

  public get seriesColour(): string {
    if (this.stylesObj && 'SeriesColour' in this.stylesObj) {
      return this.stylesObj.SeriesColour;
    }
    return defaultChartSeriesColours[0];
  }

  public updateColorAtIndex(event: PrimitiveChangeValue<string>, i: number): void {
    if (this.stylesObj && 'SeriesColours' in this.stylesObj) {
      this.seriesColors[i] = event.value;
      this.onColorChanged();
    }
  }

  public get isMarkerSelectDisabled(): boolean {
    return this.stylesObj?.SeriesLineStyle === SeriesLineStyle.scatter;
  }

  public isValueDiffThanDefault(key: keyof IGlobalSeriesStyles): boolean {
    return (
      !this.isTemplateMode &&
      this.defaultSeriesStyle != null &&
      (this.stylesObj as IGlobalSeriesStyles)[key] !== this.defaultSeriesStyle[key]
    );
  }

  public getDefaultTooltip(
    key: keyof IGlobalSeriesStyles,
    itemsEnum?: MarkerShape | SeriesLineStyle,
    itemsArray?: ISelectItem<boolean>[],
  ): string | null {
    const keyValue = this.defaultSeriesStyle && this.defaultSeriesStyle[key] != null ? this.defaultSeriesStyle[key] : null;
    const defaultValue =
      keyValue != null
        ? itemsEnum != null
          ? EnumHelpers.getEnumName(itemsEnum, keyValue as number)
          : itemsArray != null
          ? itemsArray.find((item) => item.value === keyValue)?.text
          : keyValue
        : null;

    return !this.isTemplateMode && defaultValue != null && this.isValueDiffThanDefault(key) ? 'Default value: ' + defaultValue : null;
  }
}
