export enum DataType {
  Time = 0,
  Pump_Rate = 1,
  Return_Rate = 2,
  Surface_Gravel_Concentration = 3,
  Pump_Pressure = 4,
  Return_Pressure = 5,
  Bottomhole_Pressure = 6,
  Bottomhole_Temperature = 7,
  Other = 8,
  Pump_Volume = 9,
  Toe_Pressure = 10,
  Heel_Pressure = 11,
  Workstring_Friction = 12,
  Annulus_Friction = 13,
  Washpipe_Friction = 14,
  Blank_Friction = 15,
  Screen_Friction = 16,
  Basepipe_Friction = 17,
  Service_Tool_Up_Flow_Friction = 18,
  Service_Tool_Down_Flow_Friction = 19,
  Shunt_Friction = 20,
  Gauge_Carrier_Friction = 21,
  Pressure_Attenuator_Friction = 22,
  Treating_Friction = 23,
  Heel_ECD = 24,
  Toe_ECD = 25,
  Reverse_Out_Pressure = 26,
  Choke_Friction = 27,
  Hydrostatic_Pressure = 28,
  Friction_Pressure = 29,
  Section_Friction_Pressure = 30,
  Section_Friction_Pressure_Gradient = 31,
  Equivalent_Circulating_Density = 32,
  Temperature_Time_Derivative = 33,
  Temperature_Volume_Derivative = 34,
  Measured_Depth = 35,
  Pack_Height = 36,
  Pack_Height_Ratio = 37,
  Reservoir_Pressure = 38,
  Fracture_Pressure = 39,
  True_Vertical_Depth = 40,
  Fluid_Index = 41,
  Bottomhole_Gravel_Concentration = 42,
  Horizontal_Displacement = 43,
  Max_Shunt_Burst_Pressure = 44,
  Max_Shunt_Collapse_Pressure = 45,
  Toe_Reservoir_Pressure = 46,
  Toe_Fracture_Pressure = 47,
  Heel_Reservoir_Pressure = 48,
  Heel_Fracture_Pressure = 49,
  Shunt_Burst_Rating = 50,
  Shunt_Collapse_Rating = 51,
  Hook_Load = 52,
  Block_Height = 53,
  Bit_Depth = 54,
  Mud_Weight = 55,
  Heel_Hydrostatic_Pressure = 56,
  Toe_Hydrostatic_Pressure = 57,
  Fluid_Velocity = 58,
  Dune_Front_Location = 59,
  Particle_Pickup_Velocity = 60,
  Total_Perf_Pack_Percent = 61,
  Pressure_Time_Derivative = 62,
  Pressure_Volume_Derivative = 63,
  Shunt_Rate = 64,
  Fluid_Rate = 65,
  Return_Volume = 66,
  Total_Perf_Pack_Volume = 67,
  Surface_Gravel_Mass = 68,
  Shunt_Gravel_Concentration = 69,
  External_Gravel_Concentration = 70,
  Bottomhole_Gravel_Mass = 71,
  Frac_Net_Pressure = 72,
  Perf_Pack_Volume = 73,
  Frac_Pack_Volume = 74,
  Frac_Fluid_Efficiency = 75,
  Frac_Pack_Efficiency = 76,
  Flow_Path_Description = 77,
  Flow_Direction = 78,
  Injectivity = 79,
  Liquid_Additive_Concentration = 80,
  Gravel_Index = 81,
  Reservoir_ECD = 82,
  Heel_Reservoir_ECD = 83,
  Toe_Reservoir_ECD = 84,
  Fracture_ECD = 85,
  Heel_Fracture_ECD = 86,
  Toe_Fracture_ECD = 87,
  Shunt_Pressure = 88,
  Fracture_Margin = 89,
  Heel_Fracture_Margin = 90,
  Toe_Fracture_Margin = 91,
  Fluid_Level = 92,

  //FluidPro Only
  Down_Path_Fluid_Concentration = 100,
  Up_Path_Fluid_Concentration = 101,
  Upper_Annulus_Friction = 102,
  Lower_Annulus_Friction = 103,
  Down_Path_StreamFunction = 104,
  Up_Path_StreamFunction = 105,
  Down_Path_Flow_Regime = 106,
  Up_Path_Flow_Regime = 107,

  Packer_Pressure = 108,
  Packer_Hydrostatic_Pressure = 109,
  Packer_ECD = 110,

  Packer_Reservoir_ECD = 111,
  Packer_Reservoir_Pressure = 112,
  Packer_Fracture_Pressure = 113,
  Packer_Fracture_Margin = 114,

  Down_Path_Displacing_Fluid_Index = 115,
  Up_Path_Displacing_Fluid_Index = 116,
  Fluid_Front = 117,

  // Non-engine chart units - start at 1000
  Open_Hole_Diameter = 1000,
  Open_Hole_Averaged_Diameter = 1001,
  Down_Path_Temperature = 1002,
  Up_Path_Temperature = 1003,
  Viscosity = 1004,
  Shear_Rate = 1005,
  Fluid_Ratio = 1006,
  Dune_Height = 1007,
  Particle_Size = 1008,
  Cumulative_Percent_Weight_Retained = 1009,
  Unknown = -1,
}

export enum DataFileType {
  ImportedData = 0, //user import
  SimulateResult = 1, //time
  EvaluateResult = 2,
  PackingResult = 3,
  StaticTemperatureResult = 4,
  CirculatingTemperatureResult = 5,
  PressureProfileResult = 6,
  MergedData = 7, //merged imported data
  SurveyProfileResult = 8,
  FlowProfileResult = 9,
  TrendAnalysisData = 10,
  EquationResult = 11,
  DataWithXYAxisShifts = 12,
  FpFluidConcentrationResult = 13,
  FpFluidFrontResult = 14,
  FpTimeBasedResult = 15,
  Unknown = -1,
}
