import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { autoYAxisShiftSaveAction } from '../../../+store/range/range.actions';
import { IAutoXAxisShiftParams, IAutoYAxisShiftParams } from '../chart-component-helpers/chart-types';
import {
  getInterpolatedColumnValue,
  IChartDataDtoColumn,
  IChartDataRowDto,
  IChartDataSetDto,
  LineChartDataSet,
} from '@dunefront/common/modules/reporting/dto/chart-data.dto';
import { getInterpolatedValue } from '../crosshair-plugin/helpers';
import { Point } from 'chart.js';
import { VerticalShiftDto } from '@dunefront/common/dto/trend-analysis/vertical-shift.dto';
import {
  axisTypeFromColumn,
  getArgumentAxisPrimaryArgSiValueFromPoint,
  getValueAxisSiValueFromPoint,
} from '../chart-component-helpers/chart-misc-helpers';
import { ImportColumnDto } from '@dunefront/common/modules/data-storage/dto/import-column.dto';
import { ImportFileWithMinMaxArgumentsDto } from '@dunefront/common/modules/data-storage/dto/import-file.dto';
import { DataFileType } from '@dunefront/common/dto/data-storage';
import { PrimarySecondaryArgumentConverter } from '@dunefront/common/modules/reporting/dto/primary-secondary-argument-converter';
import { UnitConverterHelper } from '@dunefront/common/unit-converters/unit.converter.helper';
import * as reportingActions from '../../../+store/reporting/reporting.actions';
import { IXAxisShiftUpdate } from '../../../+store/reporting/reporting-module.state';
import { xAxisAutoShiftAction } from '../../../+store/ui/ui.actions';
import { PartialEnumDictionary } from '@dunefront/common/common/state.helpers';
import { ChartAxis } from '@dunefront/common/modules/reporting/dto/chart-axis-property.dto';
import { MinMax } from '../chart-component-helpers/chart-data-helpers';
import { IUnitSystemDto } from '@dunefront/common/dto/unit-system.dto';

@Injectable()
export class ChartAutoShiftService {
  constructor(private store: Store) {}

  public onYAutoShift(
    payload: IAutoYAxisShiftParams,
    storageColumns: ImportColumnDto[],
    storageFiles: ImportFileWithMinMaxArgumentsDto[],
    scenarioId: number,
    rangeId: number,
  ): void {
    const { argumentPx, targetColumnIndex, chart, chartData, context } = payload;

    const dataset = chart.data.datasets[targetColumnIndex] as LineChartDataSet;
    if (dataset == null) {
      return;
    }

    // y value in px on chart where all series should align to
    const yPx = getInterpolatedValue(argumentPx, dataset, chart, false);
    if (yPx == null) {
      return;
    }

    const point: Point = { x: argumentPx, y: yPx };
    const argumentSi = getArgumentAxisPrimaryArgSiValueFromPoint(point, chart, context);
    if (argumentSi == null) {
      return;
    }

    const primaryArgument = PrimarySecondaryArgumentConverter.toPrimaryArgIfNeeded(chartData, argumentSi);

    const insertVerticalShifts: VerticalShiftDto[] = [];
    const updateVerticalShifts: VerticalShiftDto[] = [];
    const targetColumn = chartData.ChartDataColumns[targetColumnIndex];
    const targetAxisType = axisTypeFromColumn(targetColumn);
    const targetValueSi = getValueAxisSiValueFromPoint(targetAxisType, point, chart, context);
    if (targetValueSi == null) {
      return;
    }

    for (let datasetIndex = 0; datasetIndex < chartData.ChartDataColumns.length; datasetIndex++) {
      // skip auto-shift target dataset
      if (datasetIndex === targetColumnIndex) {
        continue;
      }

      const chartDataColumn = chartData.ChartDataColumns[datasetIndex];

      // don't perform auto shift on series (columns) with different UnitSystems
      if (chartDataColumn.UnitSystem !== targetColumn.UnitSystem) {
        continue;
      }

      // don't perform auto shift on equation results
      const file = storageFiles.find((f) => f.Id === chartDataColumn.FileId);
      if (file == null || file.FileType === DataFileType.EquationResult) {
        continue;
      }

      const importColumn = storageColumns.find((column) => column.ColumnName === chartDataColumn.Name);
      if (importColumn == null) {
        continue;
      }

      const columnId = chartDataColumn.ColumnId;
      if (columnId == null) {
        continue;
      }

      const interpolatedCurrentValueSi = getInterpolatedColumnValue(primaryArgument, columnId, chartData, context.verticalShifts);
      if (interpolatedCurrentValueSi == null) {
        continue;
      }

      const newShift = (targetValueSi - interpolatedCurrentValueSi) * (chartDataColumn?.Multiplier ?? 1);
      const currentVerticalShift = context.verticalShifts.find((vs) => vs.ColumnId === importColumn.Id);

      if (currentVerticalShift) {
        const updatedVerticalShift = {
          ...currentVerticalShift,
          ShiftValue: currentVerticalShift.ShiftValue + newShift,
        };
        updateVerticalShifts.push(updatedVerticalShift);
      } else {
        const newVerticalShift: VerticalShiftDto = {
          Id: -1,
          ColumnId: importColumn.Id,
          ScenarioId: scenarioId,
          RangeId: rangeId,
          ShiftValue: newShift,
          SortOrder: -1,
        };
        insertVerticalShifts.push(newVerticalShift);
      }
    }

    this.store.dispatch(autoYAxisShiftSaveAction({ updateVerticalShifts, insertVerticalShifts }));
  }

  public onXAutoShift(payload: IAutoXAxisShiftParams): void {
    const { targetColumnIndex, chartData, argumentStart, argumentEnd, chartContext, axesMinMax, verticalShifts: shifts } = payload;
    const { currentUnitSystem: unitSystem, argumentAxisUnit } = chartContext;

    const argumentStartSi = UnitConverterHelper.convertToSi(argumentAxisUnit.unitSystem, unitSystem, argumentStart);
    const argumentEndSi = UnitConverterHelper.convertToSi(argumentAxisUnit.unitSystem, unitSystem, argumentEnd);

    const targetColumn = chartData.ChartDataColumns[targetColumnIndex];
    const targetFile = chartData.ChartDataSets.find((file) => file.FileId === targetColumn.FileId);
    if (targetFile == null) {
      return;
    }

    const targetRowMax = this.rowWithMaxValue(targetFile, targetColumn, argumentStartSi, argumentEndSi, axesMinMax, unitSystem, shifts);
    if (targetRowMax == null) {
      return;
    }

    const xAxisShiftUpdates: IXAxisShiftUpdate[] = [];

    for (const file of chartData.ChartDataSets) {
      const { FileId } = file;

      if (FileId == null || FileId === targetFile.FileId) {
        continue;
      }

      const pressureColumnsInFile = chartData.ChartDataColumns.filter(
        (column) => column.FileId === FileId && UnitConverterHelper.isPressureDataType(column.DataType),
      );

      for (const pressureColumn of pressureColumnsInFile) {
        const maxPressureRow = this.rowWithMaxValue(file, pressureColumn, argumentStartSi, argumentEndSi, axesMinMax, unitSystem, shifts);
        if (maxPressureRow == null) {
          continue;
        }

        const shiftDiff = targetRowMax.Argument + targetFile.XAxisShift - (maxPressureRow.Argument + file.XAxisShift);
        const XAxisShift = file.XAxisShift + shiftDiff;

        if (XAxisShift !== file.XAxisShift) {
          xAxisShiftUpdates.push({ FileId, XAxisShift });
        }
        break;
      }
    }

    if (xAxisShiftUpdates.length > 0) {
      this.store.dispatch(reportingActions.updateXAxisShiftAction({ xAxisShiftUpdates }));
    }

    // disable xAxisAutoShift mode
    this.store.dispatch(xAxisAutoShiftAction());
  }

  private rowWithMaxValue(
    dataset: IChartDataSetDto,
    column: IChartDataDtoColumn,
    argumentStart: number,
    argumentEnd: number,
    axesMinMax: PartialEnumDictionary<ChartAxis, MinMax>,
    currentUnitSystem: IUnitSystemDto,
    verticalShifts: VerticalShiftDto[],
  ): IChartDataRowDto | null {
    const shiftedValueMinMax = this.getColumnMinMaxSi(column, axesMinMax, currentUnitSystem);
    if (shiftedValueMinMax == null) {
      return null;
    }

    const verticalShift = verticalShifts.find((v) => v.ColumnId === column.ColumnId)?.ShiftValue ?? 0;

    const valueMin = shiftedValueMinMax.min - verticalShift;
    const valueMax = shiftedValueMinMax.max - verticalShift;

    const unshiftedArgumentStart = argumentStart - dataset.XAxisShift;
    const unshiftedArgumentEnd = argumentEnd - dataset.XAxisShift;

    return dataset.ChartDataRows.reduce(
      (acc, current) => {
        if (current.Argument < unshiftedArgumentStart || current.Argument > unshiftedArgumentEnd) {
          return acc;
        }

        if (acc == null) {
          return current;
        }

        const accValue = acc.Values[column.DataValueIndex];
        if (accValue == null) {
          return current;
        }

        const currentValue = current.Values[column.DataValueIndex];
        if (currentValue == null || currentValue < valueMin || currentValue > valueMax) {
          return acc;
        }

        return currentValue > accValue ? current : acc;
      },
      null as null | IChartDataRowDto,
    );
  }

  private getColumnMinMaxSi(
    column: IChartDataDtoColumn,
    axesMinMax: PartialEnumDictionary<ChartAxis, MinMax>,
    currentUnitSystem: IUnitSystemDto,
  ): MinMax | null {
    const axisMinMax = axesMinMax[axisTypeFromColumn(column)];
    if (axisMinMax == null) {
      return null;
    }

    return {
      min: UnitConverterHelper.convertToSi(column.UnitSystem, currentUnitSystem, axisMinMax.min),
      max: UnitConverterHelper.convertToSi(column.UnitSystem, currentUnitSystem, axisMinMax.max),
    };
  }
}
