import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ConvertUnitPipe } from '@dunefront/common/modules/units/convert-unit.pipe/convert-unit.pipe';
import { Input2Component } from './components/input-2/input-2.component';
import { TooltipModule } from 'primeng/tooltip';
import { InputGridNavigationModule } from '../../../src/shared/directives/input-grid-navigation.directive';

const componentsToExport = [Input2Component];

@NgModule({
  declarations: componentsToExport,
  exports: componentsToExport,
  providers: [ConvertUnitPipe],
  imports: [CommonModule, FormsModule, TooltipModule, InputGridNavigationModule],
})
export class UnitsModule {}
