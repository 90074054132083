import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DbDependentComponent } from '../../../../../../common-modules/db-connection/db-dependent.component';
import { Store } from '@ngrx/store';
import {
  createChartDataColumn,
  createChartDataRowDto,
  createChartDataSet,
  createEmptyChartData,
  IChartDataDto,
} from '@dunefront/common/modules/reporting/dto/chart-data.dto';
import { getRheometerChartData, IRheometerChartSelectorData } from '../../../../../../+store/fluid/fluid.selectors';
import { DataType } from '@dunefront/common/dto/data-storage';
import { ChartDataSourceType } from '@dunefront/common/modules/reporting/dto/chart.dto';
import { getChartIds } from '../../../../../../+store/reporting/reporting.selectors';
import { ChartAxis } from '@dunefront/common/modules/reporting/dto/chart-axis-property.dto';
import { SeriesLineStyle } from '@dunefront/common/modules/reporting/dto/chart.types';
import { ChartState } from '../../../../../../+store/reporting/reporting-module.state';
import { ReportingFactory } from '../../../../../../+store/reporting/model/reporting.factory';

@Component({
  selector: 'app-rheometer-test-data-chart',
  templateUrl: './rheometer-test-data-chart.component.html',
  styleUrls: ['./rheometer-test-data-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RheomoeterTestDataChartComponent extends DbDependentComponent implements OnInit {
  public chartState: ChartState | undefined;
  public chartId = 0;

  constructor(store: Store, cdRef: ChangeDetectorRef) {
    super(store, cdRef);
    this.subscription.add(
      store.select(getChartIds).subscribe((chartIds) => {
        this.chartId = chartIds.rheometerChartId;
        cdRef.markForCheck();
      }),
    );
  }

  public override ngOnInit(): void {
    super.ngOnInit();

    this.subscription.add(
      this.store.select(getRheometerChartData).subscribe((rheometerChartData) => {
        if (rheometerChartData.rheometerReadings.length < 2) {
          this.chartState = undefined;
        } else {
          this.chartState = ReportingFactory.createChartStateWithData(
            ChartDataSourceType.Rheometer,
            this.calculateTestDataProfile(rheometerChartData),
          );
        }
        this.cdRef.markForCheck();
      }),
    );
  }

  private calculateTestDataProfile(rheometerChartData: IRheometerChartSelectorData): IChartDataDto {
    const chartDataDto = createEmptyChartData(DataType.Shear_Rate, ChartDataSourceType.Rheometer, this.chartId);

    const rheometerData = createChartDataColumn(ChartAxis.PrimaryValue, DataType.Viscosity, 0, 0, 'Rheometer Data (actual)', {
      lineStyle: SeriesLineStyle.scatter,
    });

    const readingDataRows = rheometerChartData.rheometerReadings.map((item) => createChartDataRowDto(item.shearRate, [item.viscosity]));
    const readingDataset = createChartDataSet(readingDataRows);
    chartDataDto.ChartDataColumns.push(rheometerData);
    chartDataDto.ChartDataSets.push(readingDataset);

    const rheometerDataAveraged = createChartDataColumn(ChartAxis.PrimaryValue, DataType.Viscosity, 1, 0, 'Rheology (interpolated)', {
      lineStyle: SeriesLineStyle.dashes,
    });

    const dataRows = rheometerChartData.fluidLine.map((item) => createChartDataRowDto(item.shearRate, [item.viscosity]));
    const averagedDataset = createChartDataSet(dataRows);
    chartDataDto.ChartDataColumns.push(rheometerDataAveraged);
    chartDataDto.ChartDataSets.push(averagedDataset);

    if (rheometerChartData.targetLine.length) {
      const shuntTargetRheology = createChartDataColumn(ChartAxis.PrimaryValue, DataType.Viscosity, 2, 0, 'Shunt Target Rheology', {
        lineStyle: SeriesLineStyle.dashes,
      });

      const dataRows = rheometerChartData.targetLine.map((item) => createChartDataRowDto(item.shearRate, [item.viscosity]));
      const targetDataset = createChartDataSet(dataRows);
      chartDataDto.ChartDataColumns.push(shuntTargetRheology);
      chartDataDto.ChartDataSets.push(targetDataset);
    }

    return chartDataDto;
  }
}
