import { InsertRowsWsAction, UpdateRowsWsAction, WsAction } from '../../ws.action';
import { IUnitSystemDto } from '../../dto/unit-system.dto';
import { AppError } from '../../exceptions/IAppError';
import { CommonDbType } from '../../dto/common-dto.interfaces';

export const UnitsModuleName = 'UnitsModule';

export enum UnitsModuleActionTypes {
  InsertUnitSystem = '[UnitsModule] InsertUnitSystem',
  UpdateUnitSystem = '[UnitsModule] UpdateUnitSystem',
  DeleteUnitSystem = '[UnitsModule] DeleteUnitSystem',
}

export class InsertUnitSystemAction extends InsertRowsWsAction<IUnitSystemDto> {
  public override readonly type = UnitsModuleActionTypes.InsertUnitSystem;
}

export class UpdateUnitSystemAction extends UpdateRowsWsAction<IUnitSystemDto> {
  public override readonly type = UnitsModuleActionTypes.UpdateUnitSystem;
}

export class DeleteUnitSystemAction extends WsAction {
  public override readonly type = UnitsModuleActionTypes.DeleteUnitSystem;
  constructor(public override payload: { sourceId: number; commonDbType: CommonDbType }) {
    super();
  }
}

export interface IUnitSystem {
  predefinedUnitSystems: IUnitSystemDto[];
  orgUnitSystems: IUnitSystemDto[];
  personalUnitSystems: IUnitSystemDto[];
  currentUnitSystemId: number;
}

export type UnitsErrorType = 'duplicate-name';

export const unitsErrorDuplicatedName: UnitsErrorType = 'duplicate-name';

export class PackProUnitsError extends AppError<{ type: UnitsErrorType }> {
  constructor(public type: UnitsErrorType) {
    super('PackPro UnitsError: ' + type, { type }, true);
    this.customHandle = type;
  }
}

export type UnitsActions = InsertUnitSystemAction | UpdateUnitSystemAction | DeleteUnitSystemAction;
