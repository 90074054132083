import { Component, OnDestroy } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-progress-indicator-modal-content',
  template: `
    <div class="dialog-content" [class.with-cancel]="showCancel">
      <div [innerHTML]="message"></div>
      <p-progressBar [value]="progress"></p-progressBar>
    </div>
    <div class="dialog-footer" *ngIf="showCancel">
      <button
        appButton
        buttonType="secondary"
        [dialogRef]="ref"
        class="btn btn-primary btn-sm"
        [isUiLockable]="false"
        (buttonClick)="ref.close(false)"
      >
        Cancel
      </button>
    </div>
  `,
  styles: [
    `
      .dialog-content {
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        padding: 48px 0;
        align-items: center;
        font-size: 1rem;
      }
      .dialog-content.with-cancel {
        padding-bottom: 20px;
      }
      p-progressBar {
        width: 100%;
        margin-top: 10px;
      }
    `,
  ],
})
export class ProgressIndicatorModalContentComponent implements OnDestroy {
  public message!: string;
  public progress = 0;
  public showCancel = false;
  protected subscription = new Subscription();

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
  ) {
    this.message = config.data.message;
    this.showCancel = config.data.showCancel;
    this.subscription.add(
      config.data.progress.subscribe((progress: number) => {
        this.progress = progress;
        if (progress === 100) {
          this.ref.close(true);
        }
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
