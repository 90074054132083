import { createAction, props } from '@ngrx/store';

export const showHelpAction = createAction('[Help] Show help', props<{ helpId: HelpIds }>());
export const getHelpAction = createAction('[Help] Get help Url', props<{ routeUrl: string }>());
export const getHelpUrlSuccessAction = createAction('[Help] Get help url success action', props<{ helpUrl: string }>());
export const getHelpUrlFailAction = createAction('[Help] Get help url fail action', props<{ error: string }>());
export const openOrCloseHelpPanelAction = createAction('[Help] Open/Close Help Panel');

export type ToolbarsHelpIds =
  | 'home'
  | 'general'
  | 'general-file'
  | 'general-unit-system'
  | 'general-scenario'
  | 'analysis'
  | 'analysis-range-management'
  | 'analysis-data-management'
  | 'charts'
  | 'charts-reporting'
  | 'charts-annotations'
  | 'charts-comparison'
  | 'charts-copy'
  | 'view'
  | 'view-axis-selection'
  | 'view-zoom'
  | 'view-crosshair'
  | 'view-other'
  | 'docs'
  | 'docs-version'
  | 'docs-documentation'
  | 'scenario-manager';

export type WellHelpIds =
  | 'caliper-data'
  | 'caliper-preview'
  | 'location-type'
  | 'pipe-properties'
  | 'temperature-profile'
  | 'survey'
  | 'zone-depth-definition'
  | 'perforated-casing'
  | 'pipe-data';

export type PumpHelpIds =
  | 'annular-bop'
  | 'choke'
  | 'pump-pop-off'
  | 'pumping-direction'
  | 'subsea-bop'
  | 'tool-position'
  | 'treating-line-coiled-tubing'
  | 'well-fluids'
  | 'schedule-simulate'
  | 'reference-parameters-simulate'
  | 'schedule-start-location'
  | 'particle-pickup'
  | 'schedule-generator'
  | 'automatic-scheduling'
  | 'schedule-evaluate'
  | 'schedule-evaluate-analysis'
  | 'schedule-evaluate-fluid-and-gravel'
  | 'reference-parameters-evaluate'
  | 'gravel-required';

export type WellReservoirDataHelpIds =
  | 'zone-depth-definition'
  | 'zone-pressure-definition'
  | 'zone-loss-definition'
  | 'zone-properties'
  | 'zone-calculate-losses'
  | 'zone-specify-losses';

export type CompletionHelpIds =
  | 'gravel-pack-packer'
  | 'isolation-packer'
  | 'washpipe'
  | 'washpipe-properties'
  | 'blank-pipe'
  | 'bull-nose'
  | 'gravel-pack-extension'
  | 'icd-screen'
  | 'isolation-valve'
  | 'screen'
  | 'sump-packer'
  | 'screen-centralizer'
  | 'section-volumes-calculator'
  | 'gauge-section-information'
  | 'pressure-attenuator'
  | 'eccentric-gauge-carrier'
  | 'concentric-gauge-carrier'
  | 'service-tool'
  | 'workstring'
  | 'schematics';

export type ShuntTubeHelpIds = 'nozzle-leakoff' | 'packing-factor' | 'rectangle' | 'round' | 'shroud' | 'system-configuration';

export type FluidHelpIds =
  | 'fluid-rheology'
  | 'fluid-database'
  | 'test-configuration'
  | 'rheometer-test-data'
  | 'gravel-settling'
  | 'project-fluids'
  | 'fluid-properties'
  | 'fluid-advanced-properties';

export type GravelHelpIds = 'gravel-properties' | 'gravel-advanced-properties' | 'gravel-database';

export type SettingsHelpIds =
  | 'general'
  | 'performance'
  | 'friction'
  | 'fluidpro'
  | 'fluidpro_dev'
  | 'packing'
  | 'thermal'
  | 'developer'
  | 'user'
  | 'organization'
  | 'chart-annotation'
  | 'chart-axis'
  | 'chart-legend'
  | 'chart-marker'
  | 'chart-performance'
  | 'chart-series'
  | 'chart-slope-line'
  | 'video-performance';

export type ResultsHelpIds = 'report' | 'summary' | 'animation-simulate' | 'animation-evaluate' | 'chart' | 'animation-visualization';

export type CalculatorHelpIds =
  | 'friction-calculator'
  | 'friction-flow-data'
  | 'friction-flow-path-dimensions'
  | 'friction-results'
  | 'injection-test-calculator'
  | 'injection-test-results'
  | 'leakoff-coefficient-calculator'
  | 'leakoff-coefficient-input-data'
  | 'leakoff-coefficient-results'
  | 'masp-calculator'
  | 'masp-input-data'
  | 'masp-results'
  | 'resuspension-calculator'
  | 'resuspension-flow-data'
  | 'resuspension-flow-path-dimensions'
  | 'resuspension-results'
  | 'settling-calculator'
  | 'settling-flow-data'
  | 'settling-flow-path-dimensions'
  | 'settling-results';

export type PsdHelpIds =
  | 'psd-analysis'
  | 'psd-definition'
  | 'psd-test-data'
  | 'psd-chart'
  | 'psd-sand-production'
  | 'psd-summary'
  | 'psd-selection'
  | 'psd-comparison';

export type DataHelpIds = 'column-properties' | 'data' | 'data-chart';

export type DocsHelpIds = 'eula' | 'release-notes';

export type TrendHelpIds = 'trend-analysis';

export type PageType =
  | 'well'
  | 'pumping'
  | 'toolbars'
  | 'well-reservoir-data'
  | 'well-caliper-data'
  | 'completion'
  | 'shunt-tube'
  | 'fluids'
  | 'gravels'
  | 'settings'
  | 'results'
  | 'calculators'
  | 'psd'
  | 'data'
  | 'trend';

export type HelpIds =
  | WellHelpIds
  | PumpHelpIds
  | ToolbarsHelpIds
  | WellReservoirDataHelpIds
  | CompletionHelpIds
  | ShuntTubeHelpIds
  | FluidHelpIds
  | GravelHelpIds
  | SettingsHelpIds
  | ResultsHelpIds
  | CalculatorHelpIds
  | PsdHelpIds
  | DataHelpIds
  | DocsHelpIds
  | TrendHelpIds;
