import { CompletionModuleState } from '../../completion-module.state';
import { getRowsForCalculations } from '../../../../common/common-grid.interfaces';
import { ArrayHelpers } from '../../../../common/array-helpers';
import { Pumping } from '../../../pumping/model/pumping/pumping';
import { PumpingDirection, ToolPosition } from '../../../pumping/dto/pumping.dto';
import { ArgumentException } from '../../../../exceptions/argumentException';
import { GaugeMeasurementType } from '../../../../dto/running-string-pipe.dto';
import { WellModuleState } from '../../../well/well-module.state';
import { Gravel } from '../../../gravel/model/gravel';
import { Volumes } from './volumes';
import { noErrors } from '../../../../common/state.helpers';
import { MAX_GRAVEL_CONCENTRATION_SI } from '../../../../common/constants';
import { ConvertUnitPipe } from '../../../units/convert-unit.pipe/convert-unit.pipe';
import { IError } from '../../../../common/common-state.interfaces';
import { UnitSystem } from '../../../../dto/unit-system.dto';

export class VolumesValidation {
  public static IsVolumeCalculatorValid(
    forSingleSection: boolean,
    completion: CompletionModuleState,
    well: WellModuleState,
    gravel: Gravel | undefined,
    isDataValid: boolean,
  ): boolean {
    return (
      (forSingleSection ? completion.Volumes.isValid : true) &&
      well.SurveyData.isValid &&
      well.CasingData.isValid &&
      well.CaliperData.isValid &&
      completion.LowerCompletion.isValid &&
      completion.RunningString.isValid &&
      completion.ShuntTube.isValid &&
      (!gravel || gravel.isValid) &&
      isDataValid
    );
  }

  public static ValidateVolume(completion: CompletionModuleState, pumping: Pumping): Volumes {
    const runningStrings = getRowsForCalculations(completion.RunningString.rows);
    const lowerCompletion = getRowsForCalculations(completion.LowerCompletion.rows);
    if (runningStrings.length === 0 || lowerCompletion.length === 0) {
      return completion.Volumes;
    }

    const lastRunningString = ArrayHelpers.last(runningStrings);
    if (!lastRunningString) {
      throw new ArgumentException('lastRunningString is undefined');
    }
    const packerTopMD = lowerCompletion[0].TopMD;
    const packerBottomMD = lowerCompletion[0].BottomMD;
    const runningStringBottomMD = lastRunningString.BottomMD;

    const error: IError<Volumes> = {
      SectionVolCalcStartMD: this.ValidateSecVolCalcStartMD(
        completion.Volumes,
        pumping,
        packerTopMD,
        packerBottomMD,
        runningStringBottomMD,
      ),
      SectionVolCalcEndMD: this.ValidateSecVolCalcEndMD(completion.Volumes, pumping, packerTopMD, packerBottomMD, runningStringBottomMD),
      SectionVolCalcStartMeasurementType: this.ValidateSecVolCalcStartMeasurementType(completion.Volumes, pumping),
      SectionVolCalcEndMeasurementType: this.ValidateSecVolCalcEndMeasurementType(completion.Volumes, pumping),
      SectionVolCalcGravelConc: this.ValidateSecVolCalcGravelConc(completion.Volumes),
    };

    return { ...completion.Volumes, error, isValid: noErrors(error) };
  }

  private static ValidateSecVolCalcStartMD(
    volumes: Volumes,
    pumping: Pumping,
    packerTopMD: number,
    packerBottomMD: number,
    runningStringBottomMD: number,
  ): string {
    if (volumes.SectionVolCalcStartMD < 0) {
      return 'Start MD must be greater than or equal to 0';
    } else if (pumping?.ToolPosition === ToolPosition.Reverse && volumes.SectionVolCalcStartMD > packerTopMD) {
      return 'Start MD must be less than or equal to packer top MD in reverse position';
    } else if (volumes.SectionVolCalcStartMD > runningStringBottomMD) {
      return 'Start MD must be less than or equal to running string bottom MD';
    } else if (volumes.SectionVolCalcStartMD > packerTopMD && volumes.SectionVolCalcStartMD < packerBottomMD) {
      return 'Start MD must not be inside the packer';
    }
    return '';
  }

  private static ValidateSecVolCalcEndMD(
    volumes: Volumes,
    pumping: Pumping,
    packerTopMD: number,
    packerBottomMD: number,
    runningStringBottomMD: number,
  ): string {
    if (volumes.SectionVolCalcEndMD < 0) {
      return 'End MD must be greater than or equal to 0';
    } else if (pumping?.ToolPosition === ToolPosition.Reverse && volumes.SectionVolCalcEndMD > packerTopMD) {
      return 'End MD must be less than or equal to packer top MD in reverse position';
    } else if (volumes.SectionVolCalcEndMD > runningStringBottomMD) {
      return 'End MD must be less than or equal to running string bottom MD';
    } else if (volumes.SectionVolCalcEndMD > packerTopMD && volumes.SectionVolCalcEndMD < packerBottomMD) {
      return 'End MD must not be inside the packer';
    }
    return '';
  }

  private static ValidateSecVolCalcStartMeasurementType(volumes: Volumes, pumping: Pumping): string {
    if (volumes.SectionVolCalcStartMD && volumes.SectionVolCalcStartMeasurementType === GaugeMeasurementType.None) {
      return 'Start location must be selected';
    }

    if (
      pumping.PumpingDirection == PumpingDirection.In_Workstring &&
      volumes.SectionVolCalcStartMD <= 0 &&
      volumes.SectionVolCalcStartMeasurementType != GaugeMeasurementType.Internal
    ) {
      return 'When pumping in the workstring, start measurement type at 0 MD must be internal';
    }

    if (
      pumping.PumpingDirection == PumpingDirection.In_Annulus &&
      volumes.SectionVolCalcStartMD <= 0 &&
      volumes.SectionVolCalcStartMeasurementType != GaugeMeasurementType.External
    ) {
      return 'When pumping in the annulus, start measurement type at 0 MD must be external';
    }

    return '';
  }

  private static ValidateSecVolCalcEndMeasurementType(volumes: Volumes, pumping: Pumping): string {
    if (volumes.SectionVolCalcEndMD && volumes.SectionVolCalcEndMeasurementType === GaugeMeasurementType.None) {
      return 'End location must be selected';
    }

    if (
      pumping.PumpingDirection == PumpingDirection.In_Workstring &&
      volumes.SectionVolCalcEndMD <= 0 &&
      volumes.SectionVolCalcEndMeasurementType != GaugeMeasurementType.External
    ) {
      return 'When pumping in the workstring, end measurement type at 0 MD must be external';
    }

    if (
      pumping.PumpingDirection == PumpingDirection.In_Annulus &&
      volumes.SectionVolCalcEndMD <= 0 &&
      volumes.SectionVolCalcEndMeasurementType != GaugeMeasurementType.Internal
    ) {
      return 'When pumping in the annulus, end measurement type at 0 MD must be internal';
    }

    return '';
  }

  private static ValidateSecVolCalcGravelConc(volumes: Volumes): string {
    if (volumes.SectionVolCalcGravelId > 0 && volumes.SectionVolCalcGravelConc < 0) {
      return 'Gravel concentration must be greater than or equal to 0';
    }

    if (volumes.SectionVolCalcGravelConc > MAX_GRAVEL_CONCENTRATION_SI) {
      const encodeValue = ConvertUnitPipe.encode(UnitSystem.Solid_Concentration, MAX_GRAVEL_CONCENTRATION_SI);
      return `Gravel concentration must be less than or equal to ${encodeValue}`;
    }

    return '';
  }
}
