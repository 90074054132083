import { createAction, props } from '@ngrx/store';
import {
  CalculatorsTab,
  ChartMode,
  CompletionTab,
  FluidsTab,
  GaugeDataTab,
  GravelsTab,
  ModuleSection,
  Notification,
  OneTimeInstructionType,
  PsdAnalysisTab,
  PumpingTab,
  ReportingTab,
  ResultsTab,
  TrendAnalysisTab,
  WellTab,
} from './ui-module.state';
import { ReportingTabDto } from '@dunefront/common/dto/reporting-tab.dto';
import { ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import { NewChartProps } from '../menu-selectors/chart/menu-chart.selector';
import { HelpIds } from '../help/help.actions';
import { ChartZoomMode } from '@dunefront/common/modules/reporting/reporting.settings';
import { NotificationState, NotificationStatus } from '@dunefront/common/dto/custom-settings.dto';

export enum ResetZoomMode {
  CHART = 0,
  DRAWING = 1,
  ALL = 2,
}

export const setAppModuleAction = createAction('[Ui] setTopModuleAction', props<{ appModuleType: ModuleType }>());
export const setAppSectionAction = createAction('[Ui] setAppSectionAction', props<{ section: ModuleSection }>());
export const setWellTabAction = createAction('[Ui] setWellTabAction', props<{ tab: WellTab }>());
export const setCompletionTabAction = createAction('[Ui] setCompletionTabAction', props<{ tab: CompletionTab }>());
export const setFluidsTabAction = createAction('[Ui] setFluidsTabAction', props<{ tab: FluidsTab }>());
export const setGravelsTabAction = createAction('[Ui] setGravelsTabAction', props<{ tab: GravelsTab }>());
export const setPumpingTabAction = createAction('[Ui] setPumpingTabAction', props<{ tab: PumpingTab }>());
export const setPsdAnalysisTabAction = createAction('[Ui] setPsdAnalysisTabAction', props<{ tab: PsdAnalysisTab }>());
export const setCalculatorsTabAction = createAction('[Ui] setCalculatorTabAction', props<{ tab: CalculatorsTab | null }>());
export const clearCalculatorsTabAction = createAction('[Ui] clearCalculatorsTabAction');

export const setResultsTabAction = createAction('[Ui] setResultsTabAction', props<{ tab: ResultsTab }>());
export const setReportingTabAction = createAction('[Ui] setReportingTabTypeAction', props<{ tabType: ReportingTab }>());
export const setGaugeDataTabAction = createAction('[Ui] setGaugeDataTabAction', props<{ tab: GaugeDataTab }>());
export const setTrendAnalysisTabAction = createAction('[Ui] setTrendAnalysisTabAction', props<{ tab: TrendAnalysisTab }>());
export const xAxisShiftAction = createAction('[Ui] xAxisShiftAction');
export const yAxisShiftAction = createAction('[Ui] yAxisShiftAction');
export const yAxisAutoShiftAction = createAction('[Ui] yAxisAutoShiftAction');
export const xAxisAutoShiftAction = createAction('[Ui] xAxisAutoShiftAction');
export const chartZoomModeAction = createAction('[Ui] chartZoomModeAction', props<{ chartZoomMode: ChartZoomMode }>());
export const chartResetZoomAction = createAction('[Ui] chartResetZoomAction', props<{ mode: ResetZoomMode }>());
export const chartAutoSizeAction = createAction('[Ui] chartAutoSizeAction');
export const chartModeAction = createAction('[Ui] chartModeAction', props<{ chartMode: ChartMode }>());
export const setAnnotationsVisibleAction = createAction('[Ui] setAnnotationsVisibleAction', props<{ visible: boolean }>());
export const setMarkersVisibleAction = createAction('[Ui] setMarkersVisibleAction', props<{ visible: boolean }>());
export const setGradientLinesVisibleAction = createAction('[Ui] setGradientLinesVisibleAction', props<{ visible: boolean }>());
export const copyImageAction = createAction('[Ui] copyImageAction', props<{ imageName: string | null }>());
export const addChartTabAction = createAction('[Ui] addChartTabAction', props<{ newChartProps: NewChartProps }>());
export const editChartTabAction = createAction('[Ui] editChartTabAction', props<{ reportingTab?: ReportingTabDto }>());
export const editChartPropsAction = createAction('[Ui] editChartPropsAction', props<{ reportingTab: ReportingTabDto }>());
export const editGaugeDataChartAction = createAction(
  '[Ui] editGaugeDataChartAction',
  props<{ gaugeDataRequestedAfterFileImport?: boolean }>(),
);
export const deleteReportingChartAction = createAction('[Ui] deleteReportingChartAction');
export const setShouldShowBeforeUnloadWarningAction = createAction(
  '[Ui] setShouldShowBeforeUnloadWarningAction',
  props<{ shouldShowWarning: boolean }>(),
);
export const setIsRibbonDisplayedAction = createAction('[Ui] setIsRibbonDisplayed', props<{ isRibbonDisplayed: boolean }>());
export const setSideNavPinnedAction = createAction('[Ui] setSideNavPinned', props<{ isSideNavPinned: boolean }>());
export const setHelpPinnedAction = createAction('[Ui] setHelpPinned', props<{ isHelpPinned: boolean }>());

export const loadNotificationsAction = createAction('[Ui] checkNotificationsAction', props<{ isTestEnv: boolean }>());
export const notificationsStatusLoadedAction = createAction(
  '[Ui] notificationsStatusLoadedAction',
  props<{ notificationsStates: NotificationState[] }>(),
);
export const notificationsLoadedAction = createAction('[Ui] notificationsLoadedAction', props<{ notifications: Notification[] }>());

export const updateNotificationStatusAction = createAction(
  '[Ui] updateNotificationStatusAction',
  props<{ id: string; status: NotificationStatus }>(),
);

export const showOneTimeInstructionPopupAction = createAction(
  '[Ui] showInstructionPopup',
  props<{ instructionType: OneTimeInstructionType }>(),
);
export const setOneTimeInstructionPopupShownAction = createAction(
  '[Ui] instructionPopupShown',
  props<{ instructionType: OneTimeInstructionType }>(),
);
export const ribbonMenuChangedAction = createAction('[Ui] ribbonMenuChangedAction', props<{ chartTab: HelpIds }>());
export const setExportDataUseTimestampsAction = createAction('[Ui] setExportDataUseTimestamps', props<{ useTimestamps: boolean }>());
