import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DbDependentComponent } from '../../../../common-modules/db-connection/db-dependent.component';
import { Store } from '@ngrx/store';
import { EnumHelpers } from '@dunefront/common/utils/enum.helpers';
import * as uiActions from '../../../../+store/ui/ui.actions';
import { ChartDataSourceType } from '@dunefront/common/modules/reporting/dto/chart.dto';
import { getAreCurrentResultsPresent } from '../../../../+store/calculation-engine/calculation-engine-results.selectors';
import { DrawingLayoutType, WellPartType, WellVisualizationType } from '@dunefront/common/modules/settings/dto/settingsDto';
import { changeSettingsProperty, changeWellVisualizationModeAction } from '../../../../+store/settings/settings.actions';
import {
  getEffectiveWellVisualizationWellLayout,
  getEffectiveWellVisualizationWellPart,
  getIsWellVisualizationWellPartFixed,
} from '../animation.selectors';
import { Scenario } from '@dunefront/common/modules/scenario/scenario';
import { RangeDto } from '@dunefront/common/dto/range.dto';
import { ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import { getWellVisualizationType } from '../../../../+store/settings/settings.selectors';

@Component({
  selector: 'app-well-visualization',
  templateUrl: './well-visualization.component.html',
  styleUrls: ['./well-visualization.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WellVisualizationComponent extends DbDependentComponent implements OnInit {
  public visualizationTypes = EnumHelpers.EnumToISelectItemArray(WellVisualizationType, false, undefined, [
    WellVisualizationType.Packing,
    WellVisualizationType.Stream_Function,
  ]);
  public selectedVisualizationType$ = this.store.select(getWellVisualizationType);
  public wellPartTypes = EnumHelpers.EnumToISelectItemArray(WellPartType);
  public selectedWellPartType = WellPartType.Full_Well;
  public isWellPartFixed = false;
  public contentVisible = true;
  public ChartDataSourceType = ChartDataSourceType;
  public layoutTypes = EnumHelpers.EnumToISelectItemArray(DrawingLayoutType);
  public selectedLayoutType = DrawingLayoutType.Survey;
  public currentResultsPresent$ = this.store.select(getAreCurrentResultsPresent);

  constructor(store: Store, cdRef: ChangeDetectorRef) {
    super(store, cdRef);
    store.dispatch(uiActions.setResultsTabAction({ tab: 'well-visualization' }));
    this.onHelpChange('results', 'animation-visualization');
  }

  public get isFluidProModule(): boolean {
    return this.currentAppModuleType === ModuleType.Simulate_Disp;
  }

  public override ngOnInit(): void {
    super.ngOnInit();

    this.subscription.add(
      this.store.select(getIsWellVisualizationWellPartFixed).subscribe((isFixed) => {
        if (this.isWellPartFixed !== isFixed) {
          this.isWellPartFixed = isFixed;
          this.cdRef.markForCheck();
        }
      }),
    );

    this.subscription.add(
      this.store.select(getEffectiveWellVisualizationWellPart).subscribe((wellPart) => {
        if (this.selectedWellPartType !== wellPart) {
          this.selectedWellPartType = wellPart;
          this.cdRef.markForCheck();
        }
      }),
    );

    this.subscription.add(
      this.store.select(getEffectiveWellVisualizationWellLayout).subscribe((layout) => {
        if (this.selectedLayoutType !== layout) {
          this.selectedLayoutType = layout;
          this.cdRef.markForCheck();
        }
      }),
    );
  }

  public onWellPartChange($event: WellPartType): void {
    this.selectedWellPartType = $event;

    this.store.dispatch(
      changeSettingsProperty({
        key: 'WellVisualizationWellPart',
        value: this.selectedWellPartType,
        shouldResetResults: false,
      }),
    );
  }

  public onVisualizationTypeChange($event: WellVisualizationType | null): void {
    if ($event != null) {
      this.store.dispatch(changeWellVisualizationModeAction({ wellVisualizationType: $event }));
    }
  }

  public onLayoutChange($event: DrawingLayoutType): void {
    this.selectedLayoutType = $event;

    this.store.dispatch(
      changeSettingsProperty({
        key: 'WellVisualizationWellLayout',
        value: this.selectedLayoutType,
        shouldResetResults: false,
      }),
    );
  }

  protected override onCurrentScenarioChanged(currentScenario: Scenario): void {
    super.onCurrentScenarioChanged(currentScenario);
    this.refreshContent();
  }

  protected override onRangeChanged(range: RangeDto | undefined): void {
    super.onRangeChanged(range);
    this.refreshContent();
  }

  private refreshContent(): void {
    this.contentVisible = false;
    this.cdRef.markForCheck();
    setTimeout(() => {
      this.contentVisible = true;
      this.cdRef.markForCheck();
    });
  }
}
