import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input } from '@angular/core';
import { ColumnType, IGridColumnConfig } from '../../../../../../shared/components/grid/grid.interfaces';
import { GridConfig } from '../../../../../../shared/components/grid/grid-config';
import { Store } from '@ngrx/store';
import { ModalService } from '../../../../../../common-modules/modals/modal.service';
import { createTableRow, ITableRow, ITableState } from '@dunefront/common/common/common-grid.interfaces';
import { FluidType } from '@dunefront/common/modules/fluid/dto/fluid.dto';
import { RheologyType } from '@dunefront/common/modules/fluid/dto/rheology/rheology.dto';
import * as actions from '../../../../../../+store/fluid/fluid.actions';
import { getSelectedFluid } from '../../../../../../+store/fluid/fluid.selectors';
import { GridContainerComponent } from '../../../../../../shared/components/grid/grid-container.component';
import { notEmpty } from '@dunefront/common/common/state.helpers';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { Rheology } from '@dunefront/common/modules/fluid/model/rheology/rheology';
import { Fluid } from '@dunefront/common/modules/fluid/model/fluid';
import { RheologyFactory } from '@dunefront/common/modules/fluid/model/rheology/rheology.factory';
import { GridResizeService } from '../../../../../../shared/services/grid-resize.service';
import { IDeleteRowsProps, IInsertRowsProps, IUpdateTableRowsProps } from '@dunefront/common/common/common-store-crud.interfaces';
import { ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import { InsertLocation } from '@dunefront/common/modules/common.interfaces';

@Component({
  selector: 'app-fluid-rheologies-grid',
  templateUrl: './fluid-rheologies-grid.component.html',
  styleUrls: ['./fluid-rheologies-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FluidRheologiesGridComponent extends GridContainerComponent<Rheology> {
  @Input() public fluid!: Fluid;
  @Input() public rheologiesData: ITableState<Rheology> | null = { rows: [], isValid: true };

  public RheologyType = RheologyType;

  constructor(
    store: Store,
    cdRef: ChangeDetectorRef,
    protected modalService: ModalService,
    el: ElementRef,
    gridResizeService: GridResizeService,
  ) {
    super(store, cdRef, el, new RheologyGridConfig(store, modalService), gridResizeService);
    this.height = 280;
  }

  protected getColumns(): IGridColumnConfig<Rheology>[] {
    const isRheometerType = this.fluid?.RheologyType === RheologyType.Rheometer;
    const isNPrimeAndKPrimeType = this.fluid?.RheologyType === RheologyType.NPrimeAndKPrime;
    const isNewtonianFluid = this.fluid?.Type === FluidType.Newtonian;

    const isTemperatureDisabled = isRheometerType;
    const isYieldStress = this.fluid?.IsYieldStress && this.currentAppModuleType === ModuleType.Simulate_Disp;
    const isNPrimeDisabled = !isNPrimeAndKPrimeType || (isNPrimeAndKPrimeType && isNewtonianFluid);
    const isKPrimeDisabled = !isNPrimeAndKPrimeType;
    const isViscosityDisabled = isRheometerType || isNPrimeAndKPrimeType;

    return [
      { disabled: false, visible: true, colId: ' ', type: ColumnType.selection },
      {
        disabled: isTemperatureDisabled,
        visible: true,
        colId: 'Temperature',
        unitSystem: UnitSystem.Temperature,
        type: ColumnType.number,
        matchingStrings: ['temp'],
      },
      {
        disabled: false,
        visible: isYieldStress,
        colId: 'YieldStress',
        unitSystem: UnitSystem.Yield_Stress,
        type: ColumnType.number,
        headerText: 'Yield Stress',
        decimalPlaces: 2,
        matchingStrings: ['yield', 'stress'],
      },
      {
        disabled: isNPrimeDisabled,
        visible: true,
        colId: 'NPrime',
        type: ColumnType.number,
        headerText: 'n`',
        unitSystem: UnitSystem.None,
        decimalPlaces: 4,
        matchingStrings: ['nprime', 'n`'],
      },
      {
        disabled: isKPrimeDisabled,
        visible: true,
        colId: 'KPrime',
        type: ColumnType.number,
        headerText: 'k`',
        unitSystem: UnitSystem.Consistency_Index,
        decimalPlaces: 6,
        matchingStrings: ['kprime', 'k`'],
      },
      {
        disabled: isViscosityDisabled,
        visible: true,
        colId: 'Viscosity',
        type: ColumnType.number,
        unitSystem: UnitSystem.Viscosity,
        decimalPlaces: 4,
        matchingStrings: ['visc'],
      },
    ];
  }

  public async onDelete(): Promise<void> {
    await this.grid.onDeleteClicked();
  }
}

export class RheologyGridConfig extends GridConfig<Rheology> {
  private fluid: Fluid | undefined;

  constructor(
    private store: Store,
    modalService: ModalService,
  ) {
    super(modalService);
    this.headerText = 'Rheologies';
    this.subscription.add(notEmpty(store.select(getSelectedFluid)).subscribe((selectedFluid) => (this.fluid = selectedFluid)));
  }

  public override updateRowsAction(props: IUpdateTableRowsProps<Rheology>): void {
    this.store.dispatch(actions.updateRheologyRow(props));
  }

  public override insertRowAction(props: IInsertRowsProps<Rheology>): void {
    this.store.dispatch(actions.insertRheologyRow(props));
  }

  public override async canRowsBeDeleted(props: IDeleteRowsProps): Promise<boolean> {
    return true;
  }

  public override deleteRowsAction(props: IDeleteRowsProps): void {
    this.store.dispatch(actions.deleteRheologyRows(props));
  }

  public override createEmptyModel(scenarioId: number): Rheology | undefined {
    if (!this.fluid) {
      return undefined;
    }
    return RheologyFactory.createEmpty(scenarioId, this.fluid.Id);
  }

  public override createDefaultTableRows(scenarioId: number, noOfRows: number): ITableRow<Rheology>[] {
    const newTableRows = [];
    for (let index = 0; index < noOfRows; index++) {
      const newEmptyModel = this.createEmptyModel(scenarioId);
      if (newEmptyModel) {
        newTableRows.push(createTableRow(newEmptyModel, 'data', index, false));
      }
    }
    return newTableRows;
  }

  public override replaceGridAction(rows: ITableRow<Rheology>[]): void {
    if (rows.length > 0) {
      const props: IInsertRowsProps<any> = {
        rows,
        refId: 0,
        insertLocation: 'replace' as InsertLocation,
        shouldResetResults: true,
      };
      this.store.dispatch(actions.insertRheologyRow(props));
    }
  }
}
