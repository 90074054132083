import { UnitConverter } from '../../converter.interfaces';
import { ReciprocalLengthUnit } from '../../../dto/unit-system.dto';

export class ReciprocalLengthConverter extends UnitConverter {
  public static symbols: Array<string> = ['', '1/m', '1/km', '1/cm', '1/mm', '1/mi', '1/yrd', '1/ft', '1/in', '1/μm', '1/ga'];

  public static unit = ReciprocalLengthUnit;

  public static fromSi(value: number, unit: ReciprocalLengthUnit): number {
    switch (unit) {
      case ReciprocalLengthUnit.Reciprocal_Meter: {
        return value;
      }
      case ReciprocalLengthUnit.Reciprocal_Kilometer: {
        return value * 1000;
      }
      case ReciprocalLengthUnit.Reciprocal_Centimeter: {
        return value * 0.01;
      }
      case ReciprocalLengthUnit.Reciprocal_Millimeter: {
        return value * 0.001;
      }
      case ReciprocalLengthUnit.Reciprocal_Mile: {
        return value * 1609.344;
      }
      case ReciprocalLengthUnit.Reciprocal_Yard: {
        return value * 0.9144;
      }
      case ReciprocalLengthUnit.Reciprocal_Foot: {
        return value * 0.3048;
      }
      case ReciprocalLengthUnit.Reciprocal_Inch: {
        return value * 0.0254;
      }
      case ReciprocalLengthUnit.Reciprocal_Micron: {
        return value * 1e-6;
      }
      case ReciprocalLengthUnit.Reciprocal_Gauge: {
        return value * 2.54e-5;
      }
      default: {
        throw new TypeError('Unknown Rate Unit:' + unit);
      }
    }
  }

  public static toSi(value: number, unit: ReciprocalLengthUnit): number {
    switch (unit) {
      case ReciprocalLengthUnit.Reciprocal_Meter: {
        return value;
      }
      case ReciprocalLengthUnit.Reciprocal_Kilometer: {
        return value / 1000;
      }
      case ReciprocalLengthUnit.Reciprocal_Centimeter: {
        return value / 0.01;
      }
      case ReciprocalLengthUnit.Reciprocal_Millimeter: {
        return value / 0.001;
      }
      case ReciprocalLengthUnit.Reciprocal_Mile: {
        return value / 1609.344;
      }
      case ReciprocalLengthUnit.Reciprocal_Yard: {
        return value / 0.9144;
      }
      case ReciprocalLengthUnit.Reciprocal_Foot: {
        return value / 0.3048;
      }
      case ReciprocalLengthUnit.Reciprocal_Inch: {
        return value / 0.0254;
      }
      case ReciprocalLengthUnit.Reciprocal_Micron: {
        return value / 1e-6;
      }
      case ReciprocalLengthUnit.Reciprocal_Gauge: {
        return value / 2.54e-5;
      }
      default: {
        throw new TypeError('Unknown Rate Unit:' + unit);
      }
    }
  }

  public static getSymbol(unit: ReciprocalLengthUnit): string {
    return this.symbols[unit];
  }
}
