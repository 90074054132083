import { createFeatureSelector, createSelector } from '@ngrx/store';
import { GaugeDataModuleState } from './gauge-data-module.state';
import { DictionaryWithArray } from '@dunefront/common/common/state.helpers';
import { getStorageFiles, getValidatedStorageFilesWithColumns } from '../data-storage/data-storage.selectors';
import { IValidatedStorageFileWithColumns } from '@dunefront/common/modules/data-storage/data-storage.validation';
import { IGaugeDataPageValidation } from '@dunefront/common/modules/validation/validation-helpers';
import { selectRouteParam } from '../router/router.selectors';

export const getGaugeDataModuleState = createFeatureSelector<GaugeDataModuleState>('gaugeData');

export const getGaugeDataFileIdFromUrl = createSelector(selectRouteParam('fileId'), (fileId) => Number(fileId ?? 0));
export const getSelectedGaugeDataFileIdFromState = createSelector(getGaugeDataModuleState, (state) => state.SelectedFileId);

export const getGaugeDataSelectedFileId = createSelector(
  getGaugeDataFileIdFromUrl,
  getSelectedGaugeDataFileIdFromState,
  getStorageFiles,
  (fileIdFromUrl, fileIdFromState, files) => {
    const fileId = fileIdFromUrl > 0 ? fileIdFromUrl : fileIdFromState;
    if (!files.ids.includes(fileId + '')) {
      return Number(files.ids[0]);
    } else {
      return fileId;
    }
  },
);

export const getGaugeDataSelectedFile = createSelector(getGaugeDataSelectedFileId, getStorageFiles, (fileId, files) => {
  return DictionaryWithArray.get(files, fileId);
});

export const getValidatedStorageFilesWithColumnsForSelectedFile = createSelector(
  getValidatedStorageFilesWithColumns,
  getGaugeDataSelectedFileId,
  (...[files, selectedFileId]): IValidatedStorageFileWithColumns | undefined => DictionaryWithArray.get(files, selectedFileId),
);

export const getGaugeDataTableState = createSelector(getGaugeDataModuleState, (state) => state.tableState);

export const getValidatedGaugeDataModuleState = createSelector(
  getGaugeDataModuleState,
  getValidatedStorageFilesWithColumnsForSelectedFile,
  getGaugeDataSelectedFileId,
  (gaugeDataState, fileWithColumns, selectedFileId): GaugeDataModuleState => {
    if (!fileWithColumns) {
      return gaugeDataState;
    }
    return {
      ...gaugeDataState,
      SelectedFileId: selectedFileId,
      gridColumns: gaugeDataState.gridColumns.map((col) => ({
        ...col,
        showError: fileWithColumns.columns.find((c) => c.ColumnName === col.headerText)?.isValid === false,
      })),
    };
  },
);

export const getGaugeDataPageValidation = createSelector(getValidatedStorageFilesWithColumns, (files): IGaugeDataPageValidation => {
  const invalidFileNames: string[] = [];
  let isValid = true;
  files.ids.forEach((fileId) => {
    const f = DictionaryWithArray.get(files, fileId);
    if (f && f.file && !f.isValid) {
      isValid = false;
      invalidFileNames.push(f.file.FileName);
    }
  });
  return { isValid, invalidFileNames };
});

export const getGaugeDataLeftNavData = createSelector(getGaugeDataPageValidation, getGaugeDataSelectedFileId, (state, selectedFileId) => ({
  isGaugeDataPageValid: state.isValid,
  selectedFileId,
}));
