<ng-container *ngIf="helpMenuProps$ | async as helpMenuProps">
  <ng-container *ngIf="electronService.isElectronApp">
    <app-ribbon-menu-group groupName="Version" (click)="changeHelp('toolbars', 'docs-version')">
      <div class="ribbon-menu-button-group no-hide">
        <app-button
          class="big-button"
          text="Check Updates"
          (click)="checkForUpdates()"
          [isUiLockable]="false"
          textIcon="icon-update-version"
        ></app-button>
      </div>
    </app-ribbon-menu-group>
    <hr />
  </ng-container>
  <app-ribbon-menu-group groupName="Documentation" (click)="changeHelp('toolbars', 'docs-documentation')">
    <div class="ribbon-menu-button-group">
      <app-button
        (buttonClick)="openInNewTab('eula')"
        class="big-button"
        [isUiLockable]="false"
        text="EULA"
        textIcon="icon-document-seal"
      ></app-button>
      <app-button
        (buttonClick)="openInNewTab('release-notes')"
        class="big-button"
        [isUiLockable]="false"
        text="Release Notes"
        textIcon="icon-list-paragraph"
      ></app-button>
      <app-button
        *hideOnDataPro
        (buttonClick)="openPDF(fileNames.EVALUATION_GUIDE)"
        class="big-button"
        [isUiLockable]="false"
        text="Evaluation Guide"
        textIcon="icon-evaluation-guide"
        [disabled]="isEvaluationGuideDisabled(moduleType$ | async)"
      ></app-button>
    </div>
  </app-ribbon-menu-group>
</ng-container>
