import { Routes } from '@angular/router';
import { TestPageComponent } from './test-page.component';
import { TabOneComponent } from './tab-one/tab-one.component';
import { TabTwoComponent } from './tab-two/tab-two.component';
import { TabThreeComponent } from './tab-three/tab-three.component';
import {
  RouteModuleTestPage,
  RouteModuleTestPageTabOne,
  RouteModuleTestPageTabThree,
  RouteModuleTestPageTabTwo,
} from './test-page-routes-names';

export const testPageRoutes: Routes = [
  {
    path: RouteModuleTestPage,
    component: TestPageComponent,
    children: [
      { path: RouteModuleTestPageTabOne, component: TabOneComponent },
      { path: RouteModuleTestPageTabTwo, component: TabTwoComponent },
      { path: RouteModuleTestPageTabThree, component: TabThreeComponent },
      { path: '', redirectTo: RouteModuleTestPageTabOne, pathMatch: 'full' },
    ],
  },
];
