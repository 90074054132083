export interface HelpModuleState {
  isOpen: boolean;
  helpUrl: string;
  isLoading: boolean;
  error: string;
}

export const initialHelpModuleState: HelpModuleState = {
  isOpen: false,
  helpUrl: 'assets/help-files/default/default.html',
  isLoading: false,
  error: '',
};
