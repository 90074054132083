import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input } from '@angular/core';
import { ITableState } from '@dunefront/common/common/common-grid.interfaces';
import { IGridColumnConfig } from '../../../../../shared/components/grid/grid.interfaces';
import { Store } from '@ngrx/store';
import { ModalService } from '../../../../../common-modules/modals/modal.service';
import { notEmpty } from '@dunefront/common/common/state.helpers';
import { getPSDAnalysis } from '../../../../../+store/psd-analysis/psd-analysis.selectors';
import { GridConfig } from '../../../../../shared/components/grid/grid-config';
import { ConvertUnitPipe } from '@dunefront/common/modules/units/convert-unit.pipe/convert-unit.pipe';
import { IUnitSystemDto } from '@dunefront/common/dto/unit-system.dto';
import { GridContainerComponent } from '../../../../../shared/components/grid/grid-container.component';
import { IScreenSize } from '../../../../../shared/services';
import { GridResizeService } from '../../../../../shared/services/grid-resize.service';
import { PSDResult } from '@dunefront/common/modules/psd-analysis/model/psd/psd';
import { PSDAnalysis } from '@dunefront/common/modules/psd-analysis/model/psd-analysis/psd-analysis';
import { getStandaloneScreensGridConfig } from './standalone-screens-grid.config';
import { PSDValidation } from '@dunefront/common/modules/psd-analysis/model/psd/psd.validation';

@Component({
  selector: 'app-standalone-screens-grid',
  templateUrl: './standalone-screens-grid.component.html',
  styleUrls: ['./standalone-screens-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StandaloneScreensGridComponent extends GridContainerComponent<PSDResult> {
  @Input() public psdTable: ITableState<PSDResult> = { rows: [], isValid: true };

  public psdAnalysis!: PSDAnalysis;

  constructor(
    store: Store,
    cdRef: ChangeDetectorRef,
    protected modalService: ModalService,
    protected convertUnitPipe: ConvertUnitPipe,
    el: ElementRef,
    gridResizeService: GridResizeService,
  ) {
    super(store, cdRef, el, new PSDGridConfig(store, modalService), gridResizeService);
    this.subscription.add(
      notEmpty(store.select(getPSDAnalysis)).subscribe((psdAnalysis) => {
        this.psdAnalysis = psdAnalysis;
        this.updateColumns(this.getColumns());
        cdRef.markForCheck();
      }),
    );
  }

  public override resize(size?: IScreenSize): void {
    if (size && size.resizeType === 'x') {
      return;
    }

    this.height = 0;
    this.cdRef.markForCheck();
    super.resize();
  }

  public override onUnitSystemChanged(currentUnitSystem: IUnitSystemDto): void {
    super.onUnitSystemChanged(currentUnitSystem);
    this.updateColumns(this.getColumns());
    this.cdRef.markForCheck();
  }

  public getColumns(): IGridColumnConfig<PSDResult>[] {
    return getStandaloneScreensGridConfig(this.isCalculateSandProduction, this.producedSandMassLabel);
  }

  private get isCalculateSandProduction(): boolean {
    return this.psdAnalysis?.IsCalculateSandProduction ?? true;
  }

  private get producedSandMassLabel(): string {
    if (this.currentUnitSystem == null) {
      return '';
    }
    return PSDValidation.getProducedSandMassLabel(this.currentUnitSystem, this.convertUnitPipe, this.psdAnalysis.ScreenSlotSize);
  }
}

export class PSDGridConfig extends GridConfig<PSDResult> {
  constructor(
    private store: Store,
    modalService: ModalService,
  ) {
    super(modalService);
  }
}
