import { createReducer, on } from '@ngrx/store';
import { loadScenarioDataSuccessAction, updateRowSuccess } from '../../app.actions';
import { ResuspensionCalculatorModuleReducerHelper } from './resuspension-calculator.reducer.helper';
import {
  ResuspensionCalculatorInitialState,
  ResuspensionCalculatorModuleState,
} from '@dunefront/common/modules/calculators/resuspension-calculator/resuspension-calculator-module.state';
import { removeDataResultsFromStoreIfNeeded } from '../../data-storage/data-storage.actions';

export const ResuspensionCalculatorModuleFeatureName = 'resuspensionCalculator';

export const resuspensionCalculatorModuleReducer = createReducer<ResuspensionCalculatorModuleState>(
  ResuspensionCalculatorInitialState,
  on(loadScenarioDataSuccessAction, (state, action) =>
    ResuspensionCalculatorModuleReducerHelper.onLoadScenarioDataSuccessAction(
      state,
      action.loadScenarioResponse.resuspensionCalculatorModule,
    ),
  ),
  on(updateRowSuccess, (state, action) => ResuspensionCalculatorModuleReducerHelper.onUpdateResuspensionCalculatorSuccess(state, action)),
  on(
    removeDataResultsFromStoreIfNeeded,
    (state, action): ResuspensionCalculatorModuleState =>
      ResuspensionCalculatorModuleReducerHelper.onRemoveDataResultsFromStore(state, action.deleteResultsFilter),
  ),
);
