import { LoadPSDAnalysisActionResponse } from '@dunefront/common/modules/psd-analysis/psd-analysis-module.actions';
import { CrudResponse } from '@dunefront/common/modules/common.actions';
import { DictionaryWithArray, IDictionaryWithArray } from '@dunefront/common/common/state.helpers';
import { psdAnalysisInitialState, PSDAnalysisModuleState } from '@dunefront/common/modules/psd-analysis/psd-analysis.module.state';
import { PSDFactory } from '@dunefront/common/modules/psd-analysis/model/psd/psd.factory';
import { PSDAnalysisFactory } from '@dunefront/common/modules/psd-analysis/model/psd-analysis/psd-analysis.factory';
import { PSD } from '@dunefront/common/modules/psd-analysis/model/psd/psd';
import { PsdReadingsFactory } from '@dunefront/common/modules/psd-analysis/model/psd-readings/psd-readings.factory';
import { ActionResponse } from '@dunefront/common/response-ws.action';

export class PSDAnalysisReducerHelper {
  public static onLoadScenarioDataSuccessAction(
    state: PSDAnalysisModuleState,
    response: ActionResponse<LoadPSDAnalysisActionResponse>,
  ): PSDAnalysisModuleState {
    if (response.status !== 'ok' || !response.payload) {
      return psdAnalysisInitialState;
    }

    const scenarioId = response.payload.psdAnalysisDto.ScenarioId;
    const psdIds = response.payload.psdDtos.map((p) => '' + p.Id);
    const SelectedPSDId = response.payload.psdDtos[0].Id ?? -1;
    const psd = PSDFactory.create(response.payload.psdDtos);
    const PSDAnalysis = PSDAnalysisFactory.create(response.payload.psdAnalysisDto);
    const psdReadings = PsdReadingsFactory.create(response.payload.psdReadingsDtos, scenarioId, psdIds);

    return {
      ...state,
      PSD: psd,
      PSDAnalysis,
      PSDReadings: psdReadings,
      SelectedPSDId,
      isLoaded: true,
    };
  }

  // region psd analysis

  public static updatePSDAnalysisSuccess(state: PSDAnalysisModuleState, action: CrudResponse): PSDAnalysisModuleState {
    if (!action.affectedRows.psdAnalysis) {
      return state;
    }
    const psdArray: PSD[] = DictionaryWithArray.getArray(state.PSD).map((psd) => ({
      ...psd,
      result: PSDFactory.getEmptyResult(psd.Id),
    }));
    return {
      ...state,
      PSDAnalysis: PSDAnalysisFactory.create(action.affectedRows.psdAnalysis),
      PSD: DictionaryWithArray.createByFn(psdArray, (psd) => psd.Id + ''),
    };
  }

  public static updatePsdResultsSuccess(state: PSDAnalysisModuleState, action: CrudResponse): PSDAnalysisModuleState {
    if (!state.PSD.ids.length || !action.affectedRows.psdResults) {
      return state;
    }
    let localPSD: IDictionaryWithArray<PSD> = { ...state.PSD };
    action.affectedRows.psdResults.rows.forEach((result) => {
      const psd = DictionaryWithArray.get(localPSD, result.Id);
      if (!psd) {
        return;
      }
      localPSD = DictionaryWithArray.upsert(localPSD, { ...psd, result }, 'Id');
    });
    return { ...state, PSD: localPSD };
  }

  // endregion

  // region psd

  public static updatePSDRowSuccess(state: PSDAnalysisModuleState, action: CrudResponse): PSDAnalysisModuleState {
    if (!action.affectedRows.psd) {
      return state;
    }
    let psdState = state.PSD;
    for (const psdRow of action.affectedRows.psd.rows) {
      psdState = DictionaryWithArray.upsert(psdState, PSDFactory.createPsd(psdRow), 'Id');
    }
    return { ...state, PSD: psdState };
  }

  public static insertPSDRowSuccess(state: PSDAnalysisModuleState, action: CrudResponse): PSDAnalysisModuleState {
    // when both PSD and PSDReadings are updated
    if (action.affectedRows.psd && action.affectedRows.psdReadings) {
      // if replace === 'all'
      if (action.affectedRows.psd.rows.length > 0 && action.affectedRows.psd.replace === 'all') {
        const psds = PSDFactory.create(action.affectedRows.psd.rows);
        const scenarioId = action.affectedRows.psdReadings.rows[0].ScenarioId;
        const psdReadings = PsdReadingsFactory.create(action.affectedRows.psdReadings.rows, scenarioId, psds.ids);

        const newPsdId = action.affectedRows.psd.rows.find((row) => row.Description === 'New PSD')?.Id ?? +psds.ids[psds.ids.length - 1];

        return {
          ...state,
          PSD: psds,
          SelectedPSDId: newPsdId,
          PSDReadings: psdReadings,
        };
      }

      const newPSD = PSDFactory.createPsd(action.affectedRows.psd.rows[0]);
      const psdDict = DictionaryWithArray.upsert(state.PSD, newPSD, 'Id');
      const psdId = action.affectedRows.psdReadings.rows[0].PSDId;
      const newPSDReadings = PsdReadingsFactory.createTableState(action.affectedRows.psdReadings.rows, action.scenarioId);
      const psdReadingsDict = DictionaryWithArray.upsertById(state.PSDReadings, newPSDReadings, psdId);

      return {
        ...state,
        PSD: psdDict,
        SelectedPSDId: psdId,
        PSDReadings: psdReadingsDict,
      };
    }

    // when only PSDReadings are updated
    if (action.affectedRows.psdReadings && action.affectedRows.psdReadings.rows.length > 0) {
      const psdId = action.affectedRows.psdReadings.rows[0].PSDId;
      const newRows = PsdReadingsFactory.createTableState(action.affectedRows.psdReadings.rows, action.scenarioId);

      return {
        ...state,
        PSDReadings: DictionaryWithArray.upsertById(state.PSDReadings, newRows, psdId),
        PSD: this.clearResults(state, psdId),
      };
    }

    return state;
  }

  public static deletePSDRowsSuccess(state: PSDAnalysisModuleState, action: CrudResponse): PSDAnalysisModuleState {
    if (!action.affectedRows.psd) {
      return state;
    }

    const psdIdToDelete = action.affectedRows.psd?.deletedIds[0];
    const psd = DictionaryWithArray.deleteItem(state.PSD, psdIdToDelete);
    const psdReadings = DictionaryWithArray.deleteItem(state.PSDReadings, psdIdToDelete);

    return {
      ...state,
      PSD: psd,
      PSDReadings: psdReadings,
      SelectedPSDId: DictionaryWithArray.getMaxId(psd),
    };
  }

  public static changeSelectedPSD(state: PSDAnalysisModuleState, psdId: number): PSDAnalysisModuleState {
    return { ...state, SelectedPSDId: psdId };
  }

  // endregion

  // region psd readings

  public static updatePSDReadingsRowSuccess(state: PSDAnalysisModuleState, action: CrudResponse): PSDAnalysisModuleState {
    if (!action.affectedRows.psdReadings || !action.affectedRows.psdReadings.rows.length) {
      return state;
    }

    const updatedRows = action.affectedRows.psdReadings.rows;
    const psdId = updatedRows[0].PSDId;
    const updatedTableState = PsdReadingsFactory.createTableState(updatedRows, updatedRows[0].ScenarioId);

    return {
      ...state,
      PSDReadings: DictionaryWithArray.upsertById(state.PSDReadings, updatedTableState, psdId),
      PSD: this.clearResults(state, psdId),
    };
  }

  public static deletePSDReadingsRowsSuccess(state: PSDAnalysisModuleState, action: CrudResponse): PSDAnalysisModuleState {
    if (!state.PSDReadings.ids.length || !action.affectedRows.psdReadings) {
      return state;
    }

    const psdId = state.SelectedPSDId;
    const newRows = PsdReadingsFactory.createTableState(action.affectedRows.psdReadings.rows, action.scenarioId);

    return {
      ...state,
      PSDReadings: DictionaryWithArray.upsertById(state.PSDReadings, newRows, psdId),
      PSD: this.clearResults(state, psdId),
    };
  }

  // endregion

  private static clearResults(state: PSDAnalysisModuleState, psdId: number): IDictionaryWithArray<PSD> {
    return {
      ...state.PSD,
      dict: { ...state.PSD.dict, [psdId]: { ...state.PSD.dict[psdId], result: PSDFactory.getEmptyResult(psdId) } },
    };
  }
}
