import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ISelectItem } from '@dunefront/common/common/select.helpers';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { PrimitiveChangeValue } from '@dunefront/common/common/common-state.interfaces';
import { ArrayHelpers } from '@dunefront/common/common/array-helpers';
import { defaultModalCancelButton, modalButton, ModalButtonConfig } from '../generic-modal/generic-modal.component';
import { BaseContentProvider } from '../../../shared/services/drawable-registry.service';

export enum ImageProviderMode {
  IMAGE = 0,
  VIDEO = 1,
}

interface ModalTexts {
  modalTitle: string;
  modalButtonText: string;
  modalButtonCy: string;
}

export const modalTexts: { [key: number]: ModalTexts } = {
  [ImageProviderMode.IMAGE]: {
    modalTitle: 'Copy Image',
    modalButtonText: 'Copy image',
    modalButtonCy: 'copy-image-copy',
  },
  [ImageProviderMode.VIDEO]: {
    modalTitle: 'Record chart',
    modalButtonText: 'Record',
    modalButtonCy: 'record-chart-record',
  },
};

@Component({
  selector: 'app-copy-image',
  template: ` <app-generic-modal [title]="texts.modalTitle" [modalButtonsConfigs]="modalButtonsConfigs">
    <app-radio-group
      *ngIf="providersSelectItems.length"
      id="copy-image-name"
      dataCy="copy-image-name"
      [items]="providersSelectItems"
      [isUiLockable]="false"
      [value]="selectedItem"
      [layout]="'vertical'"
      (primitiveValueChanged)="selectedItemChanged($event)"
    >
    </app-radio-group>
  </app-generic-modal>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectProviderComponent {
  public selectedItem?: BaseContentProvider;
  public texts: ModalTexts;
  public providersSelectItems: ISelectItem<BaseContentProvider>[];
  public modalButtonsConfigs: ModalButtonConfig[];

  constructor(
    public readonly dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
  ) {
    this.texts = modalTexts[this.config.data.mode];

    this.modalButtonsConfigs = [
      defaultModalCancelButton((): void => this.cancelClicked()),
      modalButton(
        this.texts.modalButtonText,
        (): Promise<void> => this.copyImageClicked(),
        this.texts.modalButtonCy,
        'primary',
        true,
        true,
        'confirm-btn',
      ),
    ];

    this.providersSelectItems = this.config.data.providers
      .map((provider: BaseContentProvider) => ({
        value: provider,
        text: provider.getDisplayName(),
      }))
      .sort((a: any, b: any) => ArrayHelpers.stringCompare(a.text, b.text));
  }

  public async copyImageClicked(): Promise<void> {
    const selected = this.selectedItem;

    if (selected == null) {
      throw new Error('No provider selected!');
    }

    this.dialogRef.close(selected);
  }

  public cancelClicked(): void {
    this.dialogRef.close(null);
  }

  public selectedItemChanged(value: PrimitiveChangeValue<BaseContentProvider>): void {
    const confirmBtn = this.modalButtonsConfigs.find((btn) => btn.id === 'confirm-btn');
    if (confirmBtn) {
      confirmBtn.disabled = false;
    }
    this.selectedItem = value.value;
  }
}
