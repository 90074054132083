<app-page-panel header="Video Performance" [allowFullScreen]="false" (click)="onHelpChange('settings', 'video-performance')">
  <div style="flex-direction: column" class="p-1">
    <app-radio-group
      [items]="[
        { value: false, text: 'Default' },
        { value: true, text: 'Custom' }
      ]"
      [source]="globalOptions"
      [key]="'IsOverrideVideoPerformance'"
      [isUiLockable]="false"
      id="IsOverrideVideoPerformance"
      dataCy="is-override-video-performance-radio"
      class="grid-two-one"
      layout="horizontal"
      (valueChanged)="onValueChange($event)"
    >
    </app-radio-group>


    <div class="grid-form-container no-padding m-top-1">

    <label [class.highlight]="isValueDiffThanDefault('VideoFramerate')">Video Framerate</label>
      <app-select
              [source]="globalOptions"
              [sourceDefaults]="defaultGlobalOptions"
              [key]="'VideoFramerate'"
              [items]="videoFramerates"
              [isUiLockable]="false"
              [disableSorting]="true"
              [width]="180"
              [disabled]="!globalOptions.IsOverrideVideoPerformance"
              [highlight]="isValueDiffThanDefault('VideoFramerate')"
              (valueChanged)="onValueChange($event)"
      ></app-select>
      <app-form-input
              appUnwrap
              [isUiLockable]="false"
              label="Video Steps"
              dataCy="video-steps"
              [source]="globalOptions"
              [sourceDefaults]="defaultGlobalOptions"
              [key]="'VideoSteps'"
              [decimalPlaces]="0"
              [isTextInput]="false"
              [unitType]="UnitType.None"
              [inputWidth]="180"
              [disabled]="!globalOptions.IsOverrideVideoPerformance"
              [highlight]="isValueDiffThanDefault('VideoSteps')"
              (valueChanged)="onValueChange($event)"
      ></app-form-input>
    </div>
  </div>
</app-page-panel>
