<div class="survey-grid-component-container" appOnResize (elResize)="onContainerResized()">
  <app-grid
    #grid
    *ngIf="height > 0"
    [dataSource]="surveyData"
    [gridConfig]="gridConfig"
    [columns]="gridConfig.columns"
    [height]="height"
    [headerTopRowHeight]="25"
    [showCrudControls]="false"
    [isPastingEnabled]="true"
    elementId="survey-grid"
    dataCy="survey-grid"
  ></app-grid>
  <div class="buttons-panel" *ngIf="surveyData">
    <div class="align-left">
      <button
        appButton
        type="button"
        class="btn btn-primary btn-sm"
        [isUiLockable]="false"
        (buttonClick)="openImportTempModal()"
        data-cy="btn-import-temp-profile"
      >
        Import Temp. Profile
      </button>
    </div>
    <div class="align-right">
      <button
        appButton
        type="button"
        class="btn btn-primary btn-sm"
        data-cy="survey-import"
        (buttonClick)="onImport()"
        [isDeleteResultsEnabled]="false"
      >
        Import
      </button>
      <button
        appButton
        type="button"
        class="btn btn-primary btn-sm"
        data-cy="survey-grid-insert"
        (buttonClick)="onInsert()"
        [isDeleteResultsEnabled]="false"
      >
        Insert
      </button>
      <button
        appButton
        type="button"
        class="btn btn-primary btn-sm"
        data-cy="survey-grid-delete"
        (buttonClick)="onDelete()"
        [isDeleteResultsEnabled]="false"
      >
        Delete
      </button>
    </div>
  </div>
</div>

