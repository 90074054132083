import { UnitConverter } from '../../converter.interfaces';
import { ShotDensityUnit } from '../../../dto/unit-system.dto';

export class ShotDensityConverter extends UnitConverter {
  public static symbols: Array<string> = ['', 'shots/m', 'shots/ft'];

  public static unit = ShotDensityUnit;

  public static fromSi(value: number, unit: ShotDensityUnit): number {
    switch (unit) {
      case ShotDensityUnit.Shot_per_meter: {
        return value;
      }
      case ShotDensityUnit.Shot_per_foot: {
        return value * 0.3048;
      }
      default: {
        throw new TypeError('Unknown Shot Density Unit:' + unit);
      }
    }
  }

  public static toSi(value: number, unit: ShotDensityUnit): number {
    switch (unit) {
      case ShotDensityUnit.Shot_per_meter: {
        return value;
      }
      case ShotDensityUnit.Shot_per_foot: {
        return value / 0.3048;
      }
      default: {
        throw new TypeError('Unknown Shot Density Unit:' + unit);
      }
    }
  }

  public static getSymbol(unit: ShotDensityUnit): string {
    return this.symbols[unit];
  }
}
