import { Component } from '@angular/core';
import { PanelButtonComponent } from '../panel.button.component';
import { Store } from '@ngrx/store';
import { getHelpMenuProps } from '../../../../+store/menu-selectors/help/menu-help.selectors';
import { ModalService } from '../../../modals/modal.service';
import { HelpIds } from '../../../../+store/help/help.actions';
import { BackendConnectionService } from '../../../../shared/backend-connection/backend-connection.service';
import { AppTargetConfig } from '../../../../shared/services/app-target-config';

import { CheckForUpdatesComponent } from '../../../modals/check-for-updates/check-for-updates.component';
import { ElectronService } from '../../../../shared/services/electron-service/electron.service';
import { fileExtensions } from '@dunefront/common/common/helpFile';
import { AssetService } from '../../../../shared/services/asset.service';
import { ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import { getCurrentAppModuleType } from '../../../../+store/ui/ui.selectors';

const fileNames = {
  EVALUATION_GUIDE: 'Evaluation Guide',
};

@Component({
  selector: 'app-help-menu-panel',
  templateUrl: './docs-menu-panel.component.html',
  styleUrls: ['./docs-menu-panel.component.scss'],
})
export class DocsMenuPanelComponent extends PanelButtonComponent {
  public helpMenuProps$ = this.store.select(getHelpMenuProps);
  public fileNames = fileNames;
  public moduleType$ = this.store.select(getCurrentAppModuleType);

  constructor(
    private modalService: ModalService,
    protected override store: Store,
    private backendConnectionService: BackendConnectionService,
    protected appConfig: AppTargetConfig,
    public electronService: ElectronService,
    private helpService: AssetService,
  ) {
    super(store);
  }

  public isEvaluationGuideDisabled(moduleType: ModuleType | null): boolean {
    return moduleType !== ModuleType.Evaluate && moduleType !== ModuleType.Trend_Analysis;
  }

  public async openInNewTab(uri: HelpIds): Promise<void> {
    const url = `${this.appConfig.documentationFolder}${uri}`;
    await this.backendConnectionService.openDocument(await this.helpService.getAssetUrl(url, true));
  }

  public async openPDF(filename: string): Promise<void> {
    const url = `${this.appConfig.documentationFolder}${this.appConfig.appName} ${filename}`;
    await this.backendConnectionService.openDocument(await this.helpService.getAssetUrl(url, true), fileExtensions.PDF);
  }

  public checkForUpdates(): void {
    this.modalService.open(CheckForUpdatesComponent, undefined);
  }
}
