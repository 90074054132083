import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-chart-overlay-info',
  templateUrl: './chart-overlay-info.component.html',
  styleUrls: ['./chart-overlay-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartOverlayInfoComponent {
  @Input()
  public overlayText = '';
}
