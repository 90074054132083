import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DbDependentComponent } from '../../../../../common-modules/db-connection/db-dependent.component';
import { Store } from '@ngrx/store';
import { updatePSD, updatePSDs } from '../../../../../+store/psd-analysis/psd-analysis.actions';
import { IPSDChartSelectionData } from '../../../../../+store/psd-analysis/psd-analysis.selectors';
import { IUnitSystemDto, UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { ConvertUnitPipe } from '@dunefront/common/modules/units/convert-unit.pipe/convert-unit.pipe';
import { changeProp, getChangeProp, ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';
import { StoreCrudPropsFactory } from '@dunefront/common/common/common-store-crud.interfaces';
import { PSD } from '@dunefront/common/modules/psd-analysis/model/psd/psd';

@Component({
  selector: 'app-psd-selection',
  templateUrl: './psd-selection.component.html',
  styleUrls: ['./psd-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PsdSelectionComponent extends DbDependentComponent implements OnChanges {
  @Input() public psdChartSelectionData: IPSDChartSelectionData[] = [];
  public psdLabels!: string[];

  constructor(
    store: Store,
    cdRef: ChangeDetectorRef,
    protected convertUnitPipe: ConvertUnitPipe,
  ) {
    super(store, cdRef);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.setLabels();
  }

  public override onUnitSystemChanged(currentUnitSystem: IUnitSystemDto): void {
    super.onUnitSystemChanged(currentUnitSystem);
    this.setLabels();
  }

  private setLabels(): void {
    this.psdLabels = this.psdChartSelectionData.map((psdChartData) => {
      const encodedValueAndSymbol = ConvertUnitPipe.encodeValueAndSymbol(UnitSystem.Long_Length, psdChartData.psd.Depth);
      return `${this.convertUnitPipe.transform(encodedValueAndSymbol.encodedValue, this.currentUnitSystem, 0)} - ${
        psdChartData.psd.Description
      }`;
    });
  }

  public updatePSD(props: ObjectChangeProp<PSD>, index: number): void {
    const psd = changeProp(this.psdChartSelectionData[index].psd, props);
    this.store.dispatch(updatePSD(StoreCrudPropsFactory.updateRow(psd, props)));
  }

  /**
   * when toggled == false -> unselects all items
   * when toggled == true  -> selects only valid items
   * @param toggled
   */
  public selectUnselectAll(toggled: boolean): void {
    const psdSelectionData = this.psdChartSelectionData;

    // filters out invalid items when toggled == true
    const psdsToUpdate = psdSelectionData.filter((p) => (toggled ? p.psd.isValid : true));
    if (psdsToUpdate.length === 0) {
      return;
    }

    const propChange = getChangeProp<PSD>('IsSelected', toggled, false);
    const updatedPsds = psdsToUpdate.map((psdChartData) => changeProp(psdChartData.psd, propChange));

    this.store.dispatch(updatePSDs(StoreCrudPropsFactory.updateRows(updatedPsds, propChange)));
  }
}
