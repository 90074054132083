import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { GenericOptionsComponent } from '../generic-options.component';
import { Store } from '@ngrx/store';
import { IEffectiveSeriesStyle, IGlobalSeriesStyles } from '@dunefront/common/modules/reporting/dto/chart-series.dto';
import { GlobalOptionsDto } from '@dunefront/common/dto/global-options.dto';
import { defaultChartSeriesColours } from '@dunefront/common/common/constants';
import { getChangeProp, ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';

@Component({
  selector: 'app-chart-series-options',
  templateUrl: './chart-series-options.component.html',
  styleUrls: ['./chart-series-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartSeriesOptionsComponent extends GenericOptionsComponent {
  @Input() public orgSeriesColours: string | null = null;

  constructor(store: Store, cdRef: ChangeDetectorRef) {
    super(store, cdRef);
  }

  public override onValueChange(props: ObjectChangeProp<GlobalOptionsDto>): void {
    super.onValueChange(props);
    if (props.value === true) {
      const seriesColours = !this.isOrganization && this.orgSeriesColours ? this.orgSeriesColours : defaultChartSeriesColours.join(',');
      this.settingsValueChange.emit(getChangeProp('SeriesColours', seriesColours, props.shouldResetResults));
    }
  }

  public onStyleValueChange(seriesStyleProp: ObjectChangeProp<IGlobalSeriesStyles> | ObjectChangeProp<IEffectiveSeriesStyle>): void {
    this.settingsValueChange.emit(seriesStyleProp as unknown as ObjectChangeProp<GlobalOptionsDto>);
  }
}
