import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { DbDependentComponent } from '../../../common-modules/db-connection/db-dependent.component';
import * as uiActions from '../../../+store/ui/ui.actions';

@Component({
  selector: 'app-well',
  templateUrl: './well.component.html',
  styleUrls: ['./well.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WellComponent extends DbDependentComponent {
  constructor(store: Store, cdRef: ChangeDetectorRef) {
    super(store, cdRef);
    store.dispatch(uiActions.setAppSectionAction({ section: 'well' }));
  }
}
