<div class="zone-definition-panels" *ngIf="wellState.isLoaded"
     [class.no-loss-definition]="isLossDefinitionAvailable === false">
  <app-page-panel
    header="Depth Definition"
    class="depth-definition-panel"
    (click)="onHelpChange('well-reservoir-data', 'zone-depth-definition')"
  >
    <app-depth-definition
      [state]="wellState"
      *ngIf="developerSettings$ | async as developerSettings"
      [developerSettings]="developerSettings"
      (propertyChanged)="onWellPropertyChanged($event)"
    ></app-depth-definition>
  </app-page-panel>
  <app-page-panel
    header="Pore/Frac Pressure Definition"
    class="pressure-definition-panel"
    (click)="onHelpChange('well-reservoir-data', 'zone-pressure-definition')"
  >
    <app-pressure-definition [state]="wellState"
                             (propertyChanged)="onWellPropertyChanged($event)"></app-pressure-definition>
  </app-page-panel>
  <app-page-panel *ngIf="isLossDefinitionAvailable"
                  header="Loss Definition"
                  class="loss-definition-panel"
                  (click)="onHelpChange('well-reservoir-data', 'zone-loss-definition')"
  >
    <app-loss-definition [state]="wellState" (propertyChanged)="onWellPropertyChanged($event)"></app-loss-definition>
  </app-page-panel>
</div>

<app-page-panel
  header="Reservoir Data"
  (click)="onHelpChange('well-reservoir-data', 'zone-properties')"
  [panelHelpMode]="PanelHelpMode.GRID"
>
  <app-reservoir-grid
    #gridComponent
    [zoneData]="wellState.ZoneData"
    [zonePressureType]="wellState.ZonePressureType"
    [isZoneDepthByMd]="wellState.IsZoneDepthByMD"
    (selectedRowsChanged)="onSelectedRowsChanged($event)"
  ></app-reservoir-grid>
</app-page-panel>

<app-page-panel
  header="Zone Properties"
  class="options-panel"
  [class.visible]="selectedRow && selectedRow.rowData.Id >= 0"
  *ngIf="isLossDefinitionAvailable && selectedRow"
  (click)="onHelpChange('well-reservoir-data', wellState.IsLossRateCalculated ? 'zone-calculate-losses' : 'zone-specify-losses')"
>
  <app-zone-properties [zoneRow]="selectedRow" [well]="wellState"
                       (rowUpdated)="updateRow($event)"></app-zone-properties>
</app-page-panel>
<div class="bottom-panel">
  <div></div>
  <div class="buttons-panel">
    <div class="align-right">
      <button
        appButton
        type="button"
        class="btn btn-primary btn-sm"
        data-cy="reservoir-import"
        (buttonClick)="onImport()"
        [isDeleteResultsEnabled]="false"
      >
        Import
      </button>
      <button appButton type="button" class="btn btn-primary btn-sm" (buttonClick)="onInsert()"
              [isDeleteResultsEnabled]="false">
        Insert
      </button>
      <button appButton type="button" class="btn btn-primary btn-sm" (buttonClick)="onDelete()"
              [isDeleteResultsEnabled]="false">
        Delete
      </button>
    </div>
  </div>
</div>
