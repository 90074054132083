import { Injectable, Input } from '@angular/core';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, firstValueFrom, Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { filterNil } from '@dunefront/common/common/state.helpers';
import {
  ChartAxisPropertiesActionProps,
  insertChartAxisProperty,
  updateChartAxisProperties,
} from '../../../+store/reporting/reporting.actions';
import { ChartAxisPropsComponent } from '../char-axis/chart-axis-props.component';
import { ModalService } from '../../modals/modal.service';
import { IAxisProps, IEditAxisProps } from '../chart-component-helpers/chart-types';
import { ChartAxis, XAxisFormat } from '@dunefront/common/modules/reporting/dto/chart-axis-property.dto';
import { ArgConverter, ChartAddonsWithConverter } from '../chart-component-helpers/chart-addons-helpers';

@Injectable()
export class ChartAxisPropertiesService {
  private _axisProperties$ = new BehaviorSubject<IAxisProps[] | undefined>(undefined);
  private chartId?: number;
  private subscription = new Subscription();
  private ignoreArgumentLimits$ = new BehaviorSubject<boolean>(false);

  @Input()
  public set ignoreArgumentLimits(newValue: boolean) {
    if (newValue !== this.ignoreArgumentLimits$.value) {
      this.ignoreArgumentLimits$.next(newValue);
    }
  }

  constructor(
    private store: Store,
    private modalService: ModalService,
    private addonsWithConverter$: Observable<ChartAddonsWithConverter | undefined>,
  ) {
    this.subscription.add(
      combineLatest([addonsWithConverter$, this.ignoreArgumentLimits$.pipe(distinctUntilChanged())]).subscribe(
        ([addonsWithConverter, ignoreArgumentLimits]) => {
          let newProps = addonsWithConverter?.chartUserAddons?.chartAxisProperties;
          const toSecondaryArgConverter = addonsWithConverter?.toSecondaryArgConverter;

          const calcMinMaxValue = (props: IAxisProps, minMax: number | undefined, converter: ArgConverter): number | undefined => {
            // set undefined for Argument axis when ignoreArgumentLimits is set
            if (props.axis === ChartAxis.Argument && ignoreArgumentLimits === true) {
              return undefined;
            }

            // convert when possible
            return minMax != null && converter != null && props.axis === ChartAxis.Argument ? converter(minMax) : minMax;
          };

          if (newProps != null && newProps.length > 0) {
            newProps = newProps.map((props) => ({
              ...props,
              min: calcMinMaxValue(props, props.min, toSecondaryArgConverter),
              max: calcMinMaxValue(props, props.max, toSecondaryArgConverter),
            }));
          }

          this.chartId = addonsWithConverter?.chartId;

          this._axisProperties$.next(newProps);
        },
      ),
    );
  }

  public get axisProperties$(): Observable<IAxisProps[]> {
    return this._axisProperties$.pipe(filterNil());
  }

  public get isReady$(): Observable<boolean> {
    return this._axisProperties$.pipe(map((props) => props != null));
  }

  public async onConfigureAxis(editAxisProps: IEditAxisProps, scenarioId: number): Promise<void> {
    if (this.chartId == null) {
      throw new Error('ChartId can not be undefined!');
    }

    const dialog = this.modalService.open(ChartAxisPropsComponent, { editAxisProps }, 'overflow-visible');
    const result: IAxisProps | undefined = await firstValueFrom(dialog.onClose);
    if (result == null) {
      return;
    }

    result.id = result.id ?? -1;
    result.chartId = this.chartId;
    result.scenarioId = scenarioId;

    if (result.xAxisFormat === XAxisFormat.deltaTime && result.StartTimeFileId != null) {
      result.StartTimeFileId = null;
    }

    const addonsWithConverter = await firstValueFrom(this.addonsWithConverter$);
    const secondaryArg = result.axis === ChartAxis.Argument && addonsWithConverter?.isSecondaryArgumentBased === true;

    const props: ChartAxisPropertiesActionProps = {
      axisProps: [result],
      secondaryArg,
    };

    this.store.dispatch(result?.id === -1 ? insertChartAxisProperty(props) : updateChartAxisProperties(props));
  }

  public dispose(): void {
    this.subscription.unsubscribe();
  }
}
