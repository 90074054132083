import { createReducer, on } from '@ngrx/store';
import { deleteRowsSuccess, insertRowsSuccess, loadScenarioDataSuccessAction, resetScenarioState, updateRowSuccess } from '../app.actions';
import { FluidModuleReducerHelper } from './fluid.reducer.helper';
import * as actions from './fluid.actions';
import { recalculateRheologies, selectTemperature } from './fluid.actions';
import { FluidModuleState, fluidsModuleInitialState } from '@dunefront/common/modules/fluid/fluid-module.state';
import { RheologyClientCalculations } from '@dunefront/common/modules/fluid/model/rheology/rheology-client.calculations';

export const fluidModuleReducer = createReducer<FluidModuleState>(
  fluidsModuleInitialState,
  on(resetScenarioState, (): FluidModuleState => fluidsModuleInitialState),
  on(
    loadScenarioDataSuccessAction,
    (state, action): FluidModuleState =>
      FluidModuleReducerHelper.onLoadScenarioDataSuccessAction(
        state,
        action.loadScenarioResponse.fluidModule,
        action.loadScenarioResponse.settingsModule.payload?.settingsDto.ShearRate ?? 0,
      ),
  ),

  on(actions.updateFluid, (state, action): FluidModuleState => FluidModuleReducerHelper.changeFluidProperty(state, action)),
  on(actions.selectFluidAction, (state, action): FluidModuleState => FluidModuleReducerHelper.selectFluid(state, action.fluidId)),
  on(insertRowsSuccess, (state, action): FluidModuleState => FluidModuleReducerHelper.insertFluidRowSuccess(state, action)),
  on(updateRowSuccess, (state, action): FluidModuleState => FluidModuleReducerHelper.updateFluidRowSuccess(state, action)),
  on(deleteRowsSuccess, (state, action): FluidModuleState => FluidModuleReducerHelper.deleteFluidRowsSuccess(state, action)),

  on(updateRowSuccess, (state, action): FluidModuleState => FluidModuleReducerHelper.updateRheologyRowSuccess(state, action)),
  on(insertRowsSuccess, (state, action): FluidModuleState => FluidModuleReducerHelper.insertRheologyRowsSuccess(state, action)),
  on(deleteRowsSuccess, (state, action): FluidModuleState => FluidModuleReducerHelper.deleteRheologyRowsSuccess(state, action)),
  on(selectTemperature, (state, action): FluidModuleState => FluidModuleReducerHelper.selectTemperature(state, action.temperatureId)),

  on(updateRowSuccess, (state, action): FluidModuleState => FluidModuleReducerHelper.updateRheometerRowSuccess(state, action)),
  on(insertRowsSuccess, (state, action): FluidModuleState => FluidModuleReducerHelper.insertRheometerRowsSuccess(state, action)),
  on(deleteRowsSuccess, (state, action): FluidModuleState => FluidModuleReducerHelper.deleteRheometerRowsSuccess(state, action)),

  on(updateRowSuccess, (state, action): FluidModuleState => FluidModuleReducerHelper.updateRheometerReadingRowSuccess(state, action)),
  on(insertRowsSuccess, (state, action): FluidModuleState => FluidModuleReducerHelper.insertRheometerReadingRowsSuccess(state, action)),
  on(deleteRowsSuccess, (state, action): FluidModuleState => FluidModuleReducerHelper.deleteRheometerReadingRowsSuccess(state, action)),

  on(
    recalculateRheologies,
    (state, action): FluidModuleState => RheologyClientCalculations.recalculateRheologies(state, action.shearRate, action.fluidId),
  ),
);
