import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IUndoRedoModuleState, UndoRedoModuleFeatureName } from './undo-redo.reducer';

export const getUndoRedoState = createFeatureSelector<IUndoRedoModuleState>(UndoRedoModuleFeatureName);
export const getCanUndoRedo = createSelector(getUndoRedoState, (state) => ({
  isBusy: state.isBusy,
  history: state.history[state.currentIndex],
}));

export const getCurrentHistoryState = createSelector(getUndoRedoState, (state) => state.history[state.currentIndex]);
export const getIsUndoRedoHistoryEmpty = createSelector(getUndoRedoState, (state) => state.history.length <= 1);
