import { createSelector } from '@ngrx/store';
import { getPumpingRawModuleState } from './pumping.selectors';
import { getRanges } from '../../range/range.selectors';
import { getSelectedFluidId } from '../../fluid/fluid.selectors';
import { getSelectedGravelId } from '../../gravel/gravel.selectors';
import {
  FluidGravelUsageFinder,
  FluidUsageSimulateEvaluateFinderDeps,
} from '@dunefront/common/modules/validation/fluid-gravel-usage-finder';
import { getCompletionModuleState } from '../../completion/completion.selectors';

export const getFluidUsageSimulateEvaluateFinderDeps = createSelector(
  getPumpingRawModuleState,
  getRanges,
  getCompletionModuleState,
  (...[pumpingState, ranges, completion]): FluidUsageSimulateEvaluateFinderDeps => ({ pumpingState, ranges, completion }),
);

export const getPumpingScreensFluidIsUsed = createSelector(
  getSelectedFluidId,
  getFluidUsageSimulateEvaluateFinderDeps,
  (...[currentFluidId, deps]): string[] => FluidGravelUsageFinder.FindFluidInPumping(currentFluidId, deps, 'all-ranges'),
);

export const getPumpingScreensGravelIsUsed = createSelector(
  getSelectedGravelId,
  getFluidUsageSimulateEvaluateFinderDeps,
  (...[currentGravelId, deps]): string[] => FluidGravelUsageFinder.FindGravelInPumping(currentGravelId, deps, 'all-ranges'),
);
