import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SelectModule } from '../form-components/select/select.module';
import { CheckBoxModule } from '../form-components/check-box/check-box.component';
import { ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';
import { EnumHelpers } from '@dunefront/common/utils/enum.helpers';
import { Color } from '@dunefront/common/modules/reporting/dto/chart.types';
import { presetFontSizeItems } from '../../../common-modules/chart/chart.helpers';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-font-style',
  standalone: true,
  imports: [SelectModule, CheckBoxModule, NgIf],
  templateUrl: './font-style.component.html',
})
export class FontStyleComponent<T> {
  @Input() public labelPrefix: string | undefined;
  @Input() public idPrefix = '';
  @Input() public source: T | undefined;
  @Input() public sourceDefaults: T | undefined;
  @Input() public fontSizeKey: keyof T | undefined;
  @Input() public isBoldKey: keyof T | undefined;
  @Input() public isItalicKey: keyof T | undefined;
  @Input() public fontColorKey: keyof T | undefined;
  @Input() public disabled = false;

  @Output() public valueChanged = new EventEmitter<ObjectChangeProp<NonNullable<T>>>();

  public Colors = EnumHelpers.StringEnumToSelectItemArray(Color);
  public readonly fontSizeSelect = presetFontSizeItems;

  public getLabelWithPrefix(label: string): string {
    return `${this.labelPrefix ? this.labelPrefix + ' ' : ''}${label}`;
  }

  public isValueDiffThanDefault(key: keyof T): boolean {
    return this.sourceDefaults != null && (this.source as T)[key] !== (this.sourceDefaults as T)[key];
  }
}
