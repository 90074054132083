import { RangeModuleState } from '../range-module.state';
import { DictionaryWithArray, noErrors } from '../../../common/state.helpers';
import { IError } from '../../../common/common-state.interfaces';
import { RangeConstants } from '../../../dto/range.dto';
import { initialValidatedScenarioRangeProperties, IValidatedScenarioRangeProperties } from '../model/range';
import { ScenarioRangePropertiesDto } from '../../../dto/scenario-range-properties.dto';
import { ImportFileDto } from '../../data-storage/dto/import-file.dto';

export class RangeValidation {
  public static validateBeforeDelete(rangeIdToDelete: number, importFiles: ImportFileDto[]): string {
    const rangeUsedInEquations = importFiles.filter((importFile) => importFile.RangeId === rangeIdToDelete);
    if (rangeUsedInEquations.length) {
      return `You’ll first need to delete the following equations which are associated with this range:
      ${rangeUsedInEquations.map((equation) => `<li>${equation.FileName}</li>`).join('')}`;
    }
    return '';
  }

  public static validate(
    currentScenarioId: number,
    rangeState: RangeModuleState,
    maxImportColumnGaugeDepth: number,
  ): IValidatedScenarioRangeProperties {
    const currentRangeProps = DictionaryWithArray.get(
      rangeState.scenarioRangeProperties,
      rangeState.currentRangeId ?? RangeConstants.EmptyRangeId,
    );
    if (!currentRangeProps) {
      return initialValidatedScenarioRangeProperties(currentScenarioId, rangeState.currentRangeId ?? RangeConstants.EmptyRangeId);
    }
    const trendAnalysisInputError: IError<ScenarioRangePropertiesDto> = {
      TrendAnalysisXOverMD: this.ValidateTrendAnalysisXOverMD(currentRangeProps),
      TrendAnalysisWashpipeMD: this.ValidateTrendAnalysisWashpipeMD(currentRangeProps, maxImportColumnGaugeDepth),
    };
    const isTrendAnalysisInputsValid = noErrors(trendAnalysisInputError);
    return {
      ...currentRangeProps,
      error: trendAnalysisInputError,
      isValid: isTrendAnalysisInputsValid,
      isTrendAnalysisInputsValid,
    };
  }

  private static ValidateTrendAnalysisXOverMD(rangeProps: ScenarioRangePropertiesDto): string {
    if (rangeProps != null && rangeProps.Id !== RangeConstants.EntireRangeId) {
      if (rangeProps.TrendAnalysisXOverMD <= 0) {
        return 'X-Over MD must be greater than zero';
      } else if (rangeProps.TrendAnalysisXOverMD >= rangeProps.TrendAnalysisWashpipeMD) {
        return 'X-Over MD must be less than washpipe bottom MD';
      }
    }
    return '';
  }

  private static ValidateTrendAnalysisWashpipeMD(rangeProps: ScenarioRangePropertiesDto, maxImportColumnGaugeDepth: number): string {
    if (rangeProps != null && rangeProps.Id !== RangeConstants.EntireRangeId) {
      if (rangeProps.TrendAnalysisWashpipeMD <= 0) {
        return 'Washpipe bottom MD must be greater than zero';
      } else if (rangeProps.TrendAnalysisWashpipeMD <= rangeProps.TrendAnalysisXOverMD) {
        return 'Washpipe bottom MD must be greater than x-over MD';
      } else if (rangeProps.TrendAnalysisWashpipeMD <= maxImportColumnGaugeDepth) {
        return 'Washpipe bottom MD must be greater than max gauge depth';
      }
    }

    return '';
  }
}
