import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { mergeMap } from 'rxjs/operators';
import {
  CalculateSettlingAction,
  SettlingCalculatorModuleName,
  UpdateSettlingCalculatorAction,
} from '@dunefront/common/modules/calculators/settling-calculator-module.action';
import { Store } from '@ngrx/store';
import { BaseWsEffects } from '../../base-ws.effects';
import { BackendConnectionService } from '../../../shared/backend-connection/backend-connection.service';
import { Injectable } from '@angular/core';
import { calculateSettling, updateSettlingCalculatorRow } from './settling-calculator.actions';
import { SettlingCalculatorDto } from '@dunefront/common/modules/calculators/dto/settling-calculator.dto';
import { ModalService } from '../../../common-modules/modals/modal.service';
import { WsActionPropsFactory } from '@dunefront/common/common/ws-action/ws-action-props.factory';
import { getValidatedDeveloperSettings } from '../../settings/validated-settings.selectors';

@Injectable()
export class SettlingCalculatorEffects extends BaseWsEffects {
  constructor(actions$: Actions, store: Store, wsService: BackendConnectionService, modalService: ModalService) {
    super(actions$, wsService, SettlingCalculatorModuleName, false, true, modalService, store);
  }

  public updateSettlingCalculator$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateSettlingCalculatorRow),
      mergeMap((action) =>
        this.emitUpdate(
          new UpdateSettlingCalculatorAction(WsActionPropsFactory.updateAction(action, (row) => row as SettlingCalculatorDto)),
        ),
      ),
    ),
  );

  public calculateSettling$ = createEffect(() =>
    this.actions$.pipe(
      ofType(calculateSettling),
      concatLatestFrom(() => this.store.select(getValidatedDeveloperSettings)),
      mergeMap(([action, developerSettings]) =>
        this.emitUpdate(new CalculateSettlingAction(action, developerSettings), 'Calculating Settling...'),
      ),
    ),
  );
}
