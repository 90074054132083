import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IUnitsModuleState } from './units.state';
import { UnitsModuleFeatureName } from './units.reducer';
import { toSelectItem } from '@dunefront/common/common/select.helpers';
import { ArrayHelpers } from '@dunefront/common/common/array-helpers';
import { IUnitSystemDto } from '@dunefront/common/dto/unit-system.dto';
import { IUnitSystem } from '@dunefront/common/modules/units/units.actions';

export const getUnitsState = createFeatureSelector<IUnitsModuleState>(UnitsModuleFeatureName);

const getUnitsSystem = createSelector(getUnitsState, (state) => state.unitSystem);

export const selectSortedUnitSystems = createSelector(getUnitsSystem, (unitSystem: IUnitSystem) => {
  const sortedPredefined = [...unitSystem.predefinedUnitSystems].sort((a, b) => ArrayHelpers.stringCompare(a.name, b.name));
  const sortedOrg = [...unitSystem.orgUnitSystems].sort((a, b) => ArrayHelpers.stringCompare(a.name, b.name));
  const sortedPersonal = [...unitSystem.personalUnitSystems].sort((a, b) => ArrayHelpers.stringCompare(a.name, b.name));
  return [...sortedPredefined, ...sortedPersonal, ...sortedOrg];
});

export const selectCurrentUnitSystem = createSelector(
  getUnitsSystem,
  selectSortedUnitSystems,
  (state, sorted) => sorted.find((s) => s.sourceId === state.currentUnitSystemId) ?? sorted[0],
);

export const selectAvailableUnitSystemLookupData = createSelector(selectSortedUnitSystems, (availableUnitSystems: IUnitSystemDto[]) =>
  availableUnitSystems.map((availableUnitSystem) =>
    toSelectItem(
      availableUnitSystem.sourceId,
      availableUnitSystem.name,
      availableUnitSystem.commonDbType === 'Predefined'
        ? 'Predefined Units'
        : availableUnitSystem.commonDbType === 'Organization'
        ? 'Organization Units'
        : 'Personal Units',
    ),
  ),
);
