<div class="progress-bar-panel">
  <p-progressBar [value]="progress"></p-progressBar>
  <div class="upload-status">
    <span *ngIf="isUploading">Importing file...<br /> </span>
    <span *ngIf="!isUploading && progress === 100">Imported<br />&nbsp;</span>
  </div>
</div>
<app-page-panel header="Template">
  <app-template-manager [isSaveAsTemplateMode]="true" [disabled]="isUploading && progress < 100"></app-template-manager>
</app-page-panel>
