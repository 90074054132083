<ng-container *ngIf="isPumpingEnabled; else pageDisabled">
  <ng-container *ngIf="pumpingState.isLoaded">
    <div (click)="onHelpChange('pumping', 'well-fluids')"
         [className]="'pumping-container' + (currentAppModuleType === ModuleType.Evaluate ? ' with-footer' : '')">
      <div class="pumping-header">
        <app-page-panel header="Well Fluids">
          <app-well-fluids-type [pumping]="pumpingState.pumping"
                                (pumpingPropertyChanged)="onPumpingPropertyChanged($event)">
          </app-well-fluids-type>
        </app-page-panel>
        <app-page-panel header="Simple Fluid Definition">
          <app-simple-fluid-definition
            [pumping]="pumpingState.pumping"
            (pumpingPropertyChanged)="onPumpingPropertyChanged($event)"
          ></app-simple-fluid-definition>
        </app-page-panel>
      </div>
      <app-page-panel header="Detailed Fluid Definition" [panelHelpMode]="PanelHelpMode.GRID">
        <app-detailed-fluid-definition
          [pumpingState]="pumpingState"
          (pumpingPropertyChanged)="onPumpingPropertyChanged($event)"
        ></app-detailed-fluid-definition>
      </app-page-panel>
      <div *ngIf="currentAppModuleType === ModuleType.Evaluate" class="pumping-footer">
        <button
          appButton
          data-cy="copy-well-fluids"
          type="button"
          (buttonClick)="copyFromSimulateEvaluate()"
          class="btn btn-primary btn-sm"
          [isUiLockable]="true"
        >
          Copy data from…
        </button>
      </div>
    </div>
  </ng-container>
</ng-container>
<ng-template #pageDisabled>
  <div class="centered-message">The Well Fluids screen is not available for the Entire Range - select another range to
    update the data
  </div>
</ng-template>
