import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { ITableState, ITableRow } from '@dunefront/common/common/common-grid.interfaces';
import { notEmpty } from '@dunefront/common/common/state.helpers';
import { getPSDsAsGrid } from '../../../../+store/psd-analysis/psd-analysis.selectors';
import { ModalService } from '../../../../common-modules/modals/modal.service';
import { ConvertUnitPipe } from '@dunefront/common/modules/units/convert-unit.pipe/convert-unit.pipe';
import { PageWithGridComponent } from '../../../../shared/components/grid/page-with-grid.component';
import { ScreenService } from '../../../../shared/services';
import * as uiActions from '../../../../+store/ui/ui.actions';
import { IUpdateTableRowsProps } from '@dunefront/common/common/common-store-crud.interfaces';
import { PSD, PSDResult } from '@dunefront/common/modules/psd-analysis/model/psd/psd';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SummaryComponent extends PageWithGridComponent<PSD> {
  public psdTable: ITableState<PSDResult> = { rows: [], isValid: true };

  constructor(
    store: Store,
    cdRef: ChangeDetectorRef,
    modalService: ModalService,
    protected convertUnitPipe: ConvertUnitPipe,
    resizeService: ScreenService,
  ) {
    super(store, cdRef, modalService, resizeService);
    this.subscription.add(
      notEmpty(store.select(getPSDsAsGrid)).subscribe((psdData) => {
        this.psdTable = psdData;
        this.cdRef.markForCheck();
      }),
    );

    super.onHelpChange('psd', 'psd-summary');
    store.dispatch(uiActions.setPsdAnalysisTabAction({ tab: 'summary' }));
  }

  public getRows(): ITableRow<PSD>[] {
    return [];
  }

  protected updateRow(row: IUpdateTableRowsProps<any>): void {
    return;
  }
}
