<div
  class="tabs"
  data-cy="tabbed-page-tabs"
  *ngIf="hasTabs"
  cdkDropList
  cdkDropListOrientation="horizontal"
  [cdkDropListDisabled]="!isReorderingEnabled"
  (cdkDropListDropped)="onDrop($event)"
>
  <div
    *ngFor="let tab of tabs; trackBy: trackByFn"
    class="tab"
    [class.error]="tab.hasError"
    routerLinkActive="tab-selected"
    [pTooltip]="tab.name"
    tooltipPosition="bottom"
    appendTo="body"
    positionStyle="fixed"
    [showDelay]="500"
    tooltipStyleClass="regular-tooltip"
    cdkDrag
    [cdkDragLockAxis]="'x'"
    [cdkDragDisabled]="!tab.isDraggable"
    #tabElement
    [tooltipDisabled]="!isTabNameCropped(tabElement)"
  >
    <div *cdkDragPlaceholder></div>
    <a
      [attr.data-cy]="(tab.routerLink.replace('/', '-') + '-link')"
      [routerLink]="tab.routerLink"
      [ngClass]="{ disabled: tab.disabled, 'no-click': tab.noClick }"
      (dblclick)="onDoubleClick(tab.dbClick)"
    >
      {{ tab.name }}
    </a>
  </div>
</div>
<router-outlet></router-outlet>
<ng-content></ng-content>
