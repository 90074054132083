import { Rect, Size } from '@dunefront/common/common/math-geometry-helpers';
import { Color } from '@dunefront/common/modules/reporting/dto/chart.types';
import { Point } from 'chart.js';

export interface TextDrawStyle {
  fontSize: number;
  italic: boolean;
  bold: boolean;
  fontColour: string;
}

export const measureText = (ctx: CanvasRenderingContext2D, text: string, style?: TextDrawStyle): Size => {
  ctx.save();

  ctx.textBaseline = 'bottom';
  if (style != null) {
    ctx.font = getCanvasFontString(style);
  }
  const { actualBoundingBoxAscent: height, width } = ctx.measureText(text);

  // Reset baseline
  ctx.restore();

  return { width, height };
};

export const getCanvasFontString = (style: TextDrawStyle): string => {
  return `${style.italic ? 'italic' : ''} ${style.bold ? 'bold ' : ' '}  ${style.fontSize}px Arial`;
};

/**
 * Converts transparency percentage (0-100) to hex alpha (FF-00)
 * @param transparencyPercentage
 */
export const transparencyPercentageToHexAlpha = (transparencyPercentage: number): string => {
  // 0-225 integer
  const alpha = Math.round(((100.0 - transparencyPercentage) / 100.0) * 255.0);

  // converts to hex, takes integer part before . (precaution), pads with 0 at the beginning
  return alpha.toString(16).split('.')[0].padStart(2, '0');
};

/**
 * @param color - 'White', 'Blue'
 * @param ctx
 */
export const colorToHex = (color: Color, ctx: CanvasRenderingContext2D): string => {
  // save current style;
  const prevStyle = ctx.fillStyle;

  // set text color to context
  ctx.fillStyle = color;

  // when reading it returns hex
  const colorHex = ctx.fillStyle;

  // restore style
  ctx.fillStyle = prevStyle;

  return colorHex;
};

export const getRoundedRectPath = ({ x, y, width, height }: Rect, radius: number): Path2D => {
  const path = new Path2D();

  x = Math.round(x);
  y = Math.round(y);
  width = Math.round(width);
  height = Math.round(height);

  path.moveTo(x, y + radius);
  path.lineTo(x, y + height - radius);
  path.arcTo(x, y + height, x + radius, y + height, radius);
  path.lineTo(x + width - radius, y + height);
  path.arcTo(x + width, y + height, x + width, y + height - radius, radius);
  path.lineTo(x + width, y + radius);
  path.arcTo(x + width, y, x + width - radius, y, radius);
  path.lineTo(x + radius, y);
  path.arcTo(x, y, x, y + radius, radius);

  return path;
};

function normalizeHex(hex: string): string {
  // Remove '#' if present
  return hex.replace(/^#/, '');
}

function hexToRGB(hex: string): number[] {
  hex = normalizeHex(hex);

  // Parse hex values into RGB components
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return [r, g, b];
}

/**
 * Blends multiple colors with alpha values onto a background color.
 * @param colors - An array of hexadecimal color values to blend.
 * @param alphas - An array of alpha values (0.0-1.0) corresponding to the colors.
 * @param backgroundColor - The background color to blend the colors onto. Defaults to white (#FFFFFF).
 * @returns The resulting blended color as a hexadecimal color value.
 */
export function blendColors(colors: string[], alphas: number[], backgroundColor = '#FFFFFF'): string {
  // Initialize resulting color to backgroundColor
  let resultColor = hexToRGB(backgroundColor);

  // Convert hex colors to RGB
  const colorAlphas = colors.map((hex, i) => ({
    rgb: hexToRGB(hex),
    alpha: alphas[i],
  }));

  // Filter out colors with alpha 0
  const colorsToBlend = colorAlphas.filter(({ alpha }) => alpha > 0);

  // Blend each color with the white background
  colorsToBlend.forEach(({ rgb, alpha }) => {
    resultColor = resultColor.map((channel, i) => channel + (rgb[i] - channel) * alpha);
  });

  // Clamp color channels
  const clampedColor = resultColor.map((channel) => Math.max(0, Math.min(Math.round(channel), 255)));

  // Convert to hexadecimal
  return '#' + clampedColor.map((channel) => channel.toString(16).padStart(2, '0')).join('');
}

export function findCenterPoint(points: Point[]): Point {
  // Ensure there are points to calculate center
  if (points.length === 0) {
    return { x: 0, y: 0 }; // Return origin as default center
  }

  // Calculate sum of coordinates
  const sumX = points.reduce((acc, point) => acc + point.x, 0);
  const sumY = points.reduce((acc, point) => acc + point.y, 0);

  // Calculate average of coordinates
  const centerX = sumX / points.length;
  const centerY = sumY / points.length;

  return { x: centerX, y: centerY };
}

export function movePointsTowardsCenter(points: Point[], offset: number): Point[] {
  const center = findCenterPoint(points);

  // Calculate displacement vector from each point to the center
  const displacedPoints = points.map((point) => ({
    x: center.x - point.x,
    y: center.y - point.y,
  }));

  // Normalize the displacement vector and scale it by the offset
  const scaledDisplacedPoints = displacedPoints.map((displacement) => ({
    x: (displacement.x / Math.sqrt(displacement.x ** 2 + displacement.y ** 2)) * offset,
    y: (displacement.y / Math.sqrt(displacement.x ** 2 + displacement.y ** 2)) * offset,
  }));

  // Move each point towards the center by adding the scaled displacement vector
  return points.map((point, i) => ({
    x: point.x + scaledDisplacedPoints[i].x,
    y: point.y + scaledDisplacedPoints[i].y,
  }));
}
