import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Color } from '@dunefront/common/modules/reporting/dto/chart.types';
import { EnumHelpers } from '@dunefront/common/utils/enum.helpers';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { IAnnotationStyle } from '@dunefront/common/modules/reporting/dto/chart-annotation.dto';
import { ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';
import { presetFontSizeItems } from '../../chart.helpers';

@Component({
  selector: 'app-chart-annotation-details',
  templateUrl: './chart-annotation-details.component.html',
  styleUrls: ['./chart-annotation-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartAnnotationDetailsComponent {
  @Input() public annotationStyle!: IAnnotationStyle;
  @Input() public defaultAnnotationStyle?: IAnnotationStyle;
  @Input() public disabled = false;
  @Output() public valueChanged = new EventEmitter<ObjectChangeProp<any>>();

  public UnitType = UnitSystem;
  public Colors = EnumHelpers.StringEnumToSelectItemArray(Color);
  public readonly fontSizeSelect = presetFontSizeItems;

  public emitStyleValue(event: ObjectChangeProp<any>): void {
    this.valueChanged.emit(event);
  }

  public isValueDiffThanDefault(key: keyof IAnnotationStyle): boolean {
    return this.defaultAnnotationStyle != null && this.annotationStyle[key] !== this.defaultAnnotationStyle[key];
  }

  public getDefaultTooltip(key: keyof IAnnotationStyle): string | null {
    return this.defaultAnnotationStyle != null && this.isValueDiffThanDefault(key)
      ? 'Default value: ' + this.defaultAnnotationStyle[key]
      : null;
  }
}
