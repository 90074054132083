import { LicenseFeature } from '@dunefront/common/modules/licensing/licensing.interfaces';
import { isElectron } from '@dunefront/common/common/electron/is-electron';

interface IModuleSelectorItem {
  feature: LicenseFeature;
  name: string;
  description: string;

  icon: string;
  isAddon?: boolean;
}

export interface IModuleSelectorGroup {
  name: string;
  items: IModuleSelectorItem[];
}

export const getModuleSelectorConfig = (): IModuleSelectorGroup[] => {
  return [
    {
      name: 'Design',
      items: [
        {
          feature: LicenseFeature.PSD_Analysis,
          name: 'PSD Analysis',
          icon: 'icon-PSD-Analysis',
          description: 'Select optimal screen and gravel for stand-alone screen and gravel pack completions',
        },
        {
          feature: LicenseFeature.Simulate,
          name: 'Simulate',
          icon: 'icon-Simulate',
          description: 'Design open and cased hole sand control treatments using the latest technology',
        },
        {
          feature: LicenseFeature.Simulate_CH,
          name: 'Simulate CH',
          icon: 'icon-simulate-ch',
          description: 'Design cased hole sand control treatments, below and above frac pressure',
        },
        {
          feature: LicenseFeature.Simulate_Disp,
          name: 'FluidPro',
          icon: 'icon-fluid',
          description: 'Optimize fluid displacement operations with non-uniform flow modeling',
        },
      ],
    },
    {
      name: 'Evaluation',
      items: [
        {
          feature: LicenseFeature.Evaluate,
          name: 'Evaluate',
          icon: 'icon-Evaluate',
          description: 'Advanced data analysis and model calibration for sand control treatments',
        },
        {
          feature: LicenseFeature.Trend_Analysis,
          name: 'Trend Analysis',
          icon: 'icon-Trend-Analysis',
          description: 'Simplified data analysis workflow for sand control treatments in horizontal wells',
        },
        {
          feature: LicenseFeature.Data_Analysis,
          name: 'DataPro',
          icon: 'icon-Data-Analysis',
          description: 'Handle large amounts of numerical data easily and visually',
        },
      ],
    },
    {
      name: 'Tools',
      items: [
        {
          feature: LicenseFeature.Calculators,
          name: 'Calculators',
          icon: 'icon-Calculators',
          description: 'Perform a range of useful calculations quickly with these powerful tools',
        },
      ],
    },
    {
      name: 'Add-ons (click to enable)',
      items: [
        {
          feature: LicenseFeature.MPGP,
          name: 'Managed Pressure Gravel Pack',
          icon: 'icon-dashboard',
          isAddon: true,
          description: 'Simulate and evaluate sand control operations using MPD technology',
        },
        ...(isElectron()
          ? [
              {
                feature: LicenseFeature.Detaching,
                name: 'Network License Detaching',
                icon: 'icon-key-circle',
                isAddon: true,
                description: 'Use network licenses offline without an internet connection',
              },
            ]
          : []),
      ],
    },
  ];
};
