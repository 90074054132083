import { Chart, Interaction } from 'chart.js';
import { LineChartDataSet } from '@dunefront/common/modules/reporting/dto/chart-data.dto';
import { CrosshairDataset, CrosshairPluginOptions } from './types';
import { isScatterSeries } from '../chart-component-helpers/chart-misc-helpers';
import {
  datasetVisibleAndInterpolated,
  getCrosshairPluginOptions,
  getCrosshairState,
  getInterpolatedValue,
  getScatterPointsToSnap,
} from './helpers';
import { CrosshairMode, TooltipPosition } from '@dunefront/common/modules/reporting/reporting.settings';

export function interpolate(chart: Chart, e: any, options: any): any {
  const crosshairOptions = getCrosshairPluginOptions(chart) as CrosshairPluginOptions;
  const isRotated = crosshairOptions.isChartRotated;
  const state = getCrosshairState(chart);
  const items = [];

  const scatterPointsToSnap = getScatterPointsToSnap(state.argumentPx, chart, isRotated);

  const argumentPx = scatterPointsToSnap[0]?.argPx ?? state.snapArgumentPx ?? state.argumentPx;

  if (argumentPx == null) {
    return [];
  }

  const closestDataSetIndex = crosshairOptions.crosshairContext.closestDataSetIndex;
  const crosshairMode = crosshairOptions.crosshairContext.crosshairMode;
  const tooltipPosition = crosshairOptions.crosshairContext.tooltipPosition;

  for (let datasetIndex = 0; datasetIndex < chart.data.datasets.length; datasetIndex++) {
    if (datasetIndex !== closestDataSetIndex && crosshairMode === CrosshairMode.SINGLE) {
      continue;
    }

    if (!datasetVisibleAndInterpolated(chart, datasetIndex)) {
      continue;
    }

    // check for interpolate setting
    const dataset = chart.data.datasets[datasetIndex] as LineChartDataSet & CrosshairDataset;

    const scatterPoint = scatterPointsToSnap.find((p) => p.datasetIndex === datasetIndex);

    // skip for scatter series we are not snapping to
    if (isScatterSeries(dataset) && scatterPoint == null) {
      continue;
    }

    // do not interpolate series without valid axis ids
    if (dataset?.xAxisID == null || dataset.yAxisID == null) {
      continue;
    }

    const valueScale = chart.scales[isRotated ? dataset.xAxisID : dataset.yAxisID];
    const interpolatedValuePx = getInterpolatedValue(argumentPx, dataset, chart, isRotated);
    if (interpolatedValuePx == null || isNaN(interpolatedValuePx)) {
      continue;
    }

    const interpolatedValueOnScale = valueScale.getValueForPixel(interpolatedValuePx);
    if (
      interpolatedValueOnScale == null ||
      isNaN(interpolatedValueOnScale) ||
      interpolatedValueOnScale > valueScale.max ||
      interpolatedValueOnScale < valueScale.min
    ) {
      continue;
    }

    const argumentScale = chart.scales[isRotated ? dataset.yAxisID : dataset.xAxisID];
    const argumentOnScale = argumentScale.getValueForPixel(argumentPx);

    // create a 'fake' event point
    const fakePoint = {
      hasValue: (): boolean => true,
      tooltipPosition: function (): any {
        return this._model;
      },
      _model: getTooltipPosition(isRotated, tooltipPosition, argumentPx, interpolatedValuePx, chart),
      skip: false,
      stop: false,
      x: isRotated ? interpolatedValueOnScale : argumentOnScale,
      y: isRotated ? argumentOnScale : interpolatedValueOnScale,
    } as any;

    items.push({ datasetIndex: datasetIndex, element: fakePoint, index: 0 });
  }

  // add other, not interpolated, items
  const xItems = Interaction.modes.x(chart, e, options);
  for (let index = 0; index < xItems.length; index++) {
    const item = xItems[index];
    if (!(chart.data.datasets[item.datasetIndex] as any).interpolate) {
      items.push(item);
    }
  }

  const chartRef = chart as any;

  const isDefaultTooltip = tooltipPosition === TooltipPosition.DEFAULT;
  // hide tooltip's caret if it's pinned
  chartRef.tooltip.options.caretSize = isDefaultTooltip ? 5 : 0;

  if (!isDefaultTooltip) {
    chartRef.tooltip.setActiveElements([], { x: 0, y: 0 });
  }

  return items;
}

function getTooltipPosition(
  isRotated: boolean,
  position: TooltipPosition,
  argumentPx: number,
  interpolatedValuePx: number,
  chartContext: Chart,
): { x: number; y: number } {
  if (position === TooltipPosition.DEFAULT) {
    return isRotated ? { x: interpolatedValuePx, y: argumentPx } : { x: argumentPx, y: interpolatedValuePx };
  } else {
    const x = position === TooltipPosition.TOP_LEFT || position === TooltipPosition.BOTTOM_LEFT ? 0 : chartContext.width;
    const y = position === TooltipPosition.TOP_RIGHT || position === TooltipPosition.TOP_LEFT ? 0 : chartContext.height;
    return { x, y };
  }
}
