import { importDataModuleInitialState, ImportDataModuleState } from './import-data-module.state';
import { IImportTemplateDto } from '@dunefront/common/modules/data-storage/dto/import-template/import-template.dto';
import {
  encodeTemplateId,
  getModifiedTemplateTemplateId,
  getNoneTemplateTemplateId,
  sortTemplates,
} from '@dunefront/common/common/templates/template-parser';
import { CrudResponse } from '@dunefront/common/modules/common.actions';
import { TemplatesLoadActionResponse } from '@dunefront/common/common/templates/templates.interfaces';

export class ImportDataReducerHelper {
  public static resetImportDataStateAction(state: ImportDataModuleState): ImportDataModuleState {
    const templates = state.templates;
    return { ...importDataModuleInitialState, templates };
  }

  public static loadTemplatesSuccessAction(
    state: ImportDataModuleState,
    action: TemplatesLoadActionResponse<IImportTemplateDto>,
  ): ImportDataModuleState {
    return {
      ...state,
      templates: sortTemplates<IImportTemplateDto>(action.templates),
    };
  }

  public static updateRowSuccess(state: ImportDataModuleState, action: CrudResponse): ImportDataModuleState {
    const affectedTemplates = action.affectedRows.importTemplate;
    if (!affectedTemplates) {
      return state;
    }
    const inserted = affectedTemplates.insertedRows ?? [];
    const updated = affectedTemplates.rows;
    let templates: IImportTemplateDto[] = state.templates;
    let selectedTemplateId = getNoneTemplateTemplateId();

    if (inserted.length) {
      templates = sortTemplates<IImportTemplateDto>([...templates, ...inserted]);
      selectedTemplateId = encodeTemplateId(inserted[0].Id, inserted[0].Type);
    }
    if (updated?.length) {
      const tmplMap = new Map(updated.map((u) => [encodeTemplateId(u.Id, u.Type), u]));
      templates = templates.map((tmpl) => {
        const templateId = encodeTemplateId(tmpl.Id, tmpl.Type);
        return tmplMap.has(templateId) ? (tmplMap.get(templateId) as IImportTemplateDto) : tmpl;
      });
      selectedTemplateId = encodeTemplateId(updated[0].Id, updated[0].Type);
    }

    return {
      ...state,
      templates,
      selectedTemplateId,
      templateError: '',
    };
  }

  public static deleteRowsSuccess(state: ImportDataModuleState, action: CrudResponse): ImportDataModuleState {
    const deletedIds = action.affectedRows.importTemplate?.deletedIds ?? [];
    if (!deletedIds.length) {
      return state;
    }
    const templates = state.templates.filter((tmpl) => !deletedIds.includes(encodeTemplateId(tmpl.Id, tmpl.Type)));
    let selectedTemplateId = state.selectedTemplateId;
    if (selectedTemplateId && !templates.some((tmpl) => encodeTemplateId(tmpl.Id, tmpl.Type) === selectedTemplateId)) {
      selectedTemplateId = getNoneTemplateTemplateId();
    }

    return {
      ...state,
      templates,
      selectedTemplateId,
      templateError: '',
    };
  }

  public static applyTemplateErrorAction(state: ImportDataModuleState, error: string): ImportDataModuleState {
    return { ...state, templateError: error };
  }

  public static nextColumnAction(state: ImportDataModuleState): ImportDataModuleState {
    return { ...state, defineDataIndex: state.defineDataIndex + 1 };
  }

  public static prevColumnAction(state: ImportDataModuleState): ImportDataModuleState {
    return { ...state, defineDataIndex: state.defineDataIndex - 1 };
  }

  public static includedColumnIdChangedAction(state: ImportDataModuleState, colId: number, isEnabled: boolean): ImportDataModuleState {
    const selectedColIdBeforeChange = state.includedColumnIds[state.defineDataIndex];
    let defineDataIndex = state.defineDataIndex;
    let deletedRowAfterCurrent = false;
    const includedColumnIdsSet = new Set(state.includedColumnIds);

    if (isEnabled) {
      includedColumnIdsSet.add(colId);
    } else {
      const deletedColIdx = [...includedColumnIdsSet].indexOf(colId);
      deletedRowAfterCurrent = defineDataIndex < deletedColIdx;
      includedColumnIdsSet.delete(colId);
    }
    const includedColumnIds = Array.from(includedColumnIdsSet).sort((a, b) => a - b);

    if (isEnabled) {
      const isPrevColSelected = colId < selectedColIdBeforeChange;
      const wasNothingSelected = selectedColIdBeforeChange == null;
      // select enabled column if lower that current one or nothing was selected earlier
      defineDataIndex = isPrevColSelected || wasNothingSelected ? includedColumnIds.indexOf(colId) : state.defineDataIndex;
    } else if (defineDataIndex >= includedColumnIds.length) {
      // check if defineDataColId is not outside range
      defineDataIndex = includedColumnIds.length - 1;
    } else if (deletedRowAfterCurrent) {
      // If unchecked column is after current, do nothing
    } else {
      // stay on the same column as it was before
      defineDataIndex = defineDataIndex === 0 ? 0 : state.defineDataIndex - 1;
    }

    const selectedTemplateId =
      state.selectedTemplateId === getNoneTemplateTemplateId() ? getNoneTemplateTemplateId() : getModifiedTemplateTemplateId();
    return {
      ...state,
      includedColumnIds,
      defineDataIndex,
      selectedTemplateId,
    };
  }
}
