import { createAction, props } from '@ngrx/store';
import { SettingsDto, WellVisualizationType } from '@dunefront/common/modules/settings/dto/settingsDto';
import { ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';

export const changeSettingsProperty = createAction('[settings] changeSettingsProperty', props<ObjectChangeProp<SettingsDto>>());

export const updateSettingsRow = createAction('[settings] updateSettingsRow', props<SettingsDto>());

export const changeShearRateAction = createAction('[settings] changeShearRateAction', props<ObjectChangeProp<SettingsDto>>());

export const changeWellVisualizationModeAction = createAction(
  '[settings] changeWellVisualizationModeAction',
  props<{ wellVisualizationType: WellVisualizationType }>(),
);
