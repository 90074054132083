import { BorderStyle, Paragraph, Table, TableCell, TableLayoutType, TableRow, TextRun, VerticalAlign, WidthType } from 'docx';
import { blueColor, defaultFontSize, lightBlueColor, mainFont, pageWidthDXA, whiteColor } from '../../report-consts';
import { IUnitSystemDto, UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import {
  CommonReportDataHelpers,
  IDataRowWithCode,
  IReportEnumItemMap,
  IReportTableColumnConfig,
  ITableData,
} from '../common/common-report-data-helpers';
import { ITableCellMarginOptions } from 'docx/build/file/table/table-properties/table-cell-margin';
import { TableGeneratorHelperBase } from '../common/table-generator-helper.base';

export interface PresetColumnsWidths {
  [key: number]: number;
}
export interface ExtraTableConfig {
  drawHeader: boolean;
  drawShadows: boolean;
  drawBorders: boolean;
  showHeaderUnit: boolean;
  cellWidth: any | null;
  rowHeight: number;
  headerRowHeight: number;
  cellMargins: ITableCellMarginOptions;
  headerNameMargins: ITableCellMarginOptions;
  headerUnitMargins: ITableCellMarginOptions;
  fontSize: number;
}

export const defaultExtraTableConfig: ExtraTableConfig = {
  drawHeader: true,
  drawShadows: true,
  drawBorders: true,
  showHeaderUnit: true,
  cellWidth: {
    size: 5505,
    type: WidthType.DXA,
  },
  rowHeight: 300,
  headerRowHeight: 250,
  cellMargins: { left: 50, right: 50, top: 50, bottom: 50, marginUnitType: WidthType.DXA },
  headerNameMargins: { left: 50, right: 50, top: 50, bottom: 50, marginUnitType: WidthType.DXA },
  headerUnitMargins: { left: 50, right: 50, top: 0, bottom: 0, marginUnitType: WidthType.DXA },
  fontSize: defaultFontSize,
};

export class DocumentTableGeneratorHelper extends TableGeneratorHelperBase {
  public static createTable<T>(
    tableData: ITableData<T>,
    enumItemMap: IReportEnumItemMap<T> | null | undefined,
    currentUnitSystem: IUnitSystemDto,
    extraConfig = defaultExtraTableConfig,
    presetColumnsWidths?: PresetColumnsWidths,
    isPropertiesTable = false,
  ): Table {
    tableData = this.hideDescriptionIfNeeded(tableData);
    // columns widths are calculated in percentage, now we need to calculate real numbers based on full page size
    const multiplier = pageWidthDXA / 100;
    const isCodeCellPresent = tableData.dataCells.findIndex((c) => c.colId === 'Code') !== -1;
    const convertedColumnsWidths = CommonReportDataHelpers.generateColumnsWidths(
      tableData.dataCells.length,
      isCodeCellPresent,
      presetColumnsWidths,
    ).map((cw) => cw * multiplier);

    return new Table({
      columnWidths: convertedColumnsWidths,
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
      layout: TableLayoutType.FIXED,
      rows: [
        // Header
        ...(extraConfig.drawHeader ? this.createTableHeader(tableData, currentUnitSystem, extraConfig) : []),

        // Table values
        ...this.createTableValues(tableData, currentUnitSystem, enumItemMap, extraConfig, isPropertiesTable),
      ],
    });
  }

  private static getTableLabelCell(
    text: string,
    cellIndex: number,
    extraConfig: ExtraTableConfig,
    margins: ITableCellMarginOptions,
    isLastCell = false,
    bold = true,
  ): TableCell {
    return new TableCell({
      verticalAlign: VerticalAlign.TOP,
      margins,
      children: [
        new Paragraph({
          children: [
            ...text.split('\n').map((line, index) => {
              return new TextRun({
                break: index > 0 ? 1 : 0,
                text: line,
                color: whiteColor,
                size: extraConfig.fontSize,
                bold,
                font: {
                  name: mainFont,
                },
              });
            }),
          ],
        }),
      ],
      shading: {
        fill: blueColor,
        color: 'auto',
      },
      borders: this.getTableBorders(true, cellIndex, isLastCell),
    });
  }

  private static createTableHeader<T>(
    tableData: ITableData<T>,
    currentUnitSystem: IUnitSystemDto,
    extraConfig: ExtraTableConfig,
  ): TableRow[] {
    const rows = [
      // column label
      new TableRow({
        children: tableData.dataCells.map((dataCell, cellIndex) => {
          return this.getTableLabelCell(
            (dataCell.reportingHeaderName ?? dataCell.headerText) || (typeof dataCell.colId === 'string' ? dataCell.colId : ''),
            cellIndex,
            extraConfig,
            extraConfig.headerNameMargins,
            cellIndex === tableData.dataCells.length - 1,
          );
        }),
        tableHeader: true,
      }),
    ];

    if (extraConfig.showHeaderUnit) {
      const units = tableData.dataCells.map((dataCell, cellIndex) =>
        CommonReportDataHelpers.getCellUnit(currentUnitSystem, dataCell.unitSystem ?? UnitSystem.None, dataCell.overrideUnitSymbol),
      );
      if (units.some((unit) => unit.trim().length > 0)) {
        rows.push(
          // column unit label
          new TableRow({
            children: tableData.dataCells.map((dataCell, cellIndex) =>
              this.getTableLabelCell(
                units[cellIndex],
                cellIndex,
                {
                  ...extraConfig,
                  fontSize: extraConfig.fontSize - 2,
                },
                extraConfig.headerUnitMargins,
                cellIndex === tableData.dataCells.length - 1,
                false,
              ),
            ),
            tableHeader: true,
          }),
        );
      }
    }

    return rows;
  }

  private static createTableValues<T>(
    tableData: ITableData<T>,
    currentUnitSystem: IUnitSystemDto,
    enumItemMap: IReportEnumItemMap<T> | null | undefined,
    extraConfig: ExtraTableConfig,
    isPropertiesTable = false,
  ): TableRow[] {
    return tableData.dataRows.map(
      (tableDataEntry, rowIndex) =>
        new TableRow({
          children: tableData.dataCells.map((dataCell, cellIndex) => {
            const text = CommonReportDataHelpers.getTableCellValue(dataCell, tableDataEntry, enumItemMap, currentUnitSystem);
            return new TableCell({
              margins: extraConfig.cellMargins,
              verticalAlign: VerticalAlign.CENTER,
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text,
                      font: {
                        name: mainFont,
                      },
                      bold: isPropertiesTable && cellIndex === 1,
                      size: defaultFontSize,
                    }),
                  ],
                }),
              ],
              shading: this.getTableShading(extraConfig.drawShadows, rowIndex),
              borders: this.getTableBorders(extraConfig.drawBorders, cellIndex, cellIndex === tableData.dataCells.length - 1),
            });
          }),
        }),
    );
  }

  public static getTableBorders(drawBorders: boolean, cellIndex: number, isLastCell: boolean): any {
    return {
      top: {
        style: drawBorders ? BorderStyle.SINGLE : BorderStyle.NONE,
        size: 1,
        color: blueColor,
      },
      bottom: {
        style: drawBorders ? BorderStyle.SINGLE : BorderStyle.NONE,
        size: 1,
        color: blueColor,
      },
      left: {
        style: cellIndex === 0 && drawBorders ? BorderStyle.SINGLE : BorderStyle.NONE,
        size: 1,
        color: blueColor,
      },
      right: {
        style: drawBorders ? BorderStyle.SINGLE : BorderStyle.NONE,
        size: 1,
        color: blueColor,
      },
    };
  }

  public static getTableShading(drawShadow: boolean, rowIndex: number): any {
    return drawShadow
      ? {
          fill: rowIndex % 2 === 0 ? lightBlueColor : whiteColor,
          color: 'auto',
        }
      : null;
  }

  public static createGenericKeyValueTable<T>(
    gridConfig: IReportTableColumnConfig<T>[],
    tableDataEntry: IDataRowWithCode<T>,
    currentUnitSystem: IUnitSystemDto,
    enumItemMap: IReportEnumItemMap<T> | null,
  ): Table {
    const tableData = CommonReportDataHelpers.createGenericKeyValueTableData(gridConfig, tableDataEntry, currentUnitSystem, enumItemMap);

    return this.createGenericKeyValueTableFromTableData(tableData, currentUnitSystem);
  }

  public static createGenericKeyValueTableFromTableData(tableData: ITableData<any>, currentUnitSystem: IUnitSystemDto): Table {
    const extraTableConfig = { ...defaultExtraTableConfig, drawBorders: false, drawShadows: false, drawHeader: false };
    return DocumentTableGeneratorHelper.createTable(tableData, null, currentUnitSystem, extraTableConfig, { 0: 10, 1: 20 }, true);
  }
}
