import { Component } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { defaultModalConfirmButton, ModalButtonConfig } from '../generic-modal/generic-modal.component';
import { ClientAuthService } from '../../auth/client-auth.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.modal.component.html',
  styleUrls: ['./user-profile.modal.component.scss'],
})
export class UserProfileModalComponent {
  constructor(
    private authService: ClientAuthService,
    public ref: DynamicDialogRef,
  ) {}

  public user$ = this.authService.user$;

  public modalButtonsConfigs: ModalButtonConfig[] = [defaultModalConfirmButton(() => this.ref.close())];
}
