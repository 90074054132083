import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { EnumHelpers } from '@dunefront/common/utils/enum.helpers';
import { ChartLegendLocation, ILegendStyle } from '@dunefront/common/modules/reporting/dto/chart-legend';
import { ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';
import { presetFontSizeItems } from '../../../chart/chart.helpers';

@Component({
  selector: 'app-chart-legend-style',
  templateUrl: './chart-legend-style.component.html',
  styleUrls: ['./chart-legend-style.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartLegendStyleComponent {
  public readonly LegendPositions = EnumHelpers.EnumToISelectItemArray(ChartLegendLocation, false);
  public readonly fontSizeSelect = presetFontSizeItems;

  @Input() public legendStyle!: ILegendStyle;
  @Input() public defaultLegendStyle?: ILegendStyle;
  @Input() public disabled = false;
  @Output() public valueChanged = new EventEmitter<ObjectChangeProp<ILegendStyle>>();

  public UnitType = UnitSystem;
  protected readonly ChartLegendLocation = ChartLegendLocation;

  public isValueDiffThanDefault(key: keyof ILegendStyle): boolean {
    return this.defaultLegendStyle != null && this.legendStyle[key] !== this.defaultLegendStyle[key];
  }

  public getDefaultTooltip(key: keyof ILegendStyle, itemsEnum?: ChartLegendLocation): string | null {
    if (this.defaultLegendStyle == null) {
      return null;
    }
    const defaultValue =
      itemsEnum != null ? EnumHelpers.getEnumName(itemsEnum, this.defaultLegendStyle[key] as number) : this.defaultLegendStyle[key];
    return this.isValueDiffThanDefault(key) ? 'Default value: ' + defaultValue : null;
  }
}
