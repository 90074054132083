import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { Pumping } from '@dunefront/common/modules/pumping/model/pumping/pumping';
import { ReferenceVariableCalculatorResultsDto } from '@dunefront/common/modules/reference-variables/dto/reference-variable-calculator-results.dto';
import { ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';

@Component({
  selector: 'app-gravel-required-panel',
  templateUrl: './gravel-required-panel.component.html',
  styleUrls: ['./gravel-required-panel.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GravelRequiredPanelComponent {
  @Input()
  public pumping!: Pumping;

  public UnitType = UnitSystem;

  @Output() public dataChange = new EventEmitter<ObjectChangeProp<Pumping>>();

  @Input() public refVariables!: ReferenceVariableCalculatorResultsDto | null | undefined;

  public get isTxtBlankPackingPercentageForGravelRequiredEnabled(): boolean {
    return this.pumping?.IsBlankPackingForGravelRequired;
  }
}
