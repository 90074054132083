import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { ModalService } from '../../../../common-modules/modals/modal.service';
import { notEmpty } from '@dunefront/common/common/state.helpers';
import { getPSDSelectData, IPSDSelectorData } from '../../../../+store/psd-analysis/psd-analysis.selectors';
import { addPSD, deletePSD, updatePSDs } from '../../../../+store/psd-analysis/psd-analysis.actions';
import { cancelChange } from '../../../../+store/store.helpers';
import { GeneralCalculations } from '@dunefront/common/common/general.calculations';
import { PageWithGridComponent } from '../../../../shared/components/grid/page-with-grid.component';
import { ScreenService } from '../../../../shared/services';
import { ITableRow } from '@dunefront/common/common/common-grid.interfaces';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import * as uiActions from '../../../../+store/ui/ui.actions';
import { changeProp, ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';
import { IUpdateTableRowsProps, StoreCrudPropsFactory } from '@dunefront/common/common/common-store-crud.interfaces';
import { PsdReading } from '@dunefront/common/modules/psd-analysis/model/psd-readings/psd-reading';
import { PSD } from '@dunefront/common/modules/psd-analysis/model/psd/psd';
import { PanelHelpMode } from '../../../../shared/components/help-button/help-buton.component';

@Component({
  selector: 'app-psd-data',
  templateUrl: './psd-data.component.html',
  styleUrls: ['./psd-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PsdDataComponent extends PageWithGridComponent<PsdReading> {
  public psdData!: IPSDSelectorData;

  public UnitType = UnitSystem;
  public PanelHelpMode = PanelHelpMode;
  constructor(store: Store, cdRef: ChangeDetectorRef, modalService: ModalService, resizeService: ScreenService) {
    super(store, cdRef, modalService, resizeService);
    this.subscription.add(
      notEmpty(store.select(getPSDSelectData)).subscribe((psdData) => {
        this.psdData = psdData;
        this.cdRef.markForCheck();
      }),
    );
    store.dispatch(uiActions.setPsdAnalysisTabAction({ tab: 'psd-data' }));
  }

  public async updatePSD(props: ObjectChangeProp<PSD>): Promise<void> {
    if (!(await this.validateBeforeUpdate(props))) {
      return;
    }
    const psd = changeProp(this.psdData.selectedPSD, props);
    this.store.dispatch(updatePSDs(StoreCrudPropsFactory.updateRow(psd, props)));
  }

  public async addPSD(): Promise<void> {
    if (this.psdData.items.find((psd) => psd.text === 'New PSD')) {
      await this.modalService.showAlert('New PSD is already in the list - Please rename it first.');
      return;
    }
    this.store.dispatch(addPSD({ scenarioId: this.psdData.selectedPSD.ScenarioId }));
  }

  public async deletePSD(): Promise<void> {
    if (this.psdData.items.length === 1) {
      await this.modalService.showAlert('At least one PSD must remain on the list');
      return;
    }
    const confirmResult = await this.modalService.showConfirm('Are you sure you want to delete the selected PSD?', 'Delete PSD');
    if (!confirmResult) {
      return;
    }
    this.store.dispatch(deletePSD(StoreCrudPropsFactory.deleteRows(this.psdData.selectedPSD, true)));
  }

  private async validateBeforeUpdate(props: ObjectChangeProp<PSD>): Promise<boolean> {
    if (props.key === 'Description') {
      const psdNames = this.psdData.items.map((psd) => psd.text);
      const isFluidNameAlreadyExist = GeneralCalculations.DoesArrayContainsString(psdNames, props.value as string);
      if (isFluidNameAlreadyExist) {
        await this.modalService.showAlert('This description already exists - please use a different one.', 'Information');

        const cancelGenerator = cancelChange(this.psdData.selectedPSD, props, this.cdRef);
        this.psdData.selectedPSD = cancelGenerator.next().value;
        this.psdData.selectedPSD = cancelGenerator.next().value;
        return false;
      }
    }

    return true;
  }

  public getRows(): ITableRow<PsdReading>[] {
    return [];
  }

  protected updateRow(row: IUpdateTableRowsProps<any>): void {
    return;
  }
}
