<ng-container *ngIf="chartState$ | async as chartState">
    <app-chart-controller
      [allowRecording]="allowRecording"
      [chartDisplayName]="drawableProviderId"
      [drawableProviderId]="drawableProviderId"
      [chartId]="chartId"
      [overlayText]="overlayText"
      [config]="config"
      [chartState]="chartState$ | async"
      [ignoreArgumentLimits]="(ignoreArgumentLimits$ | async) === true"
    >
    </app-chart-controller>
</ng-container>
