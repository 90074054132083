import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CommonDbModuleFeatureName, ICommonDbModuleState } from './common-db.reducer';
import { createTableState } from '@dunefront/common/common/common-grid.interfaces';
import { CommonFluidModel } from './model/common-fluid.model';
import { RheologyCalculations } from '@dunefront/common/modules/fluid/dto/rheology/rheology.calculations';
import { RheologyType } from '@dunefront/common/modules/fluid/dto/rheology/rheology.dto';
import { CommonDbSelectorsHelper } from './common-db.selectors.helper';
import { RheologyFactory } from '@dunefront/common/modules/fluid/model/rheology/rheology.factory';
import { FluidFactory } from '@dunefront/common/modules/fluid/model/fluid.factory';
import { getShearRate } from '../settings/validated-settings.selectors';
import { initialGlobalOptionsDto } from '@dunefront/common/dto/global-options.dto';
import { IMarkerStyle } from '@dunefront/common/modules/reporting/dto/chart-marker.dto';
import { getTemperatureProfile } from '../well/validated-well.selectors';
import { GlobalOptionsValidation } from './model/global-options.validation';

export const getCommonDbState = createFeatureSelector<ICommonDbModuleState>(CommonDbModuleFeatureName);

export const selectAvailableCommonFluids = createSelector(
  getCommonDbState,
  getTemperatureProfile,
  getShearRate,
  (...[commonDbState, tempProfile, shearRate]) => {
    const tempBh = tempProfile.BottomholeCirculating;
    const tempSurface = tempProfile.SurfaceTemp;
    const newRows = commonDbState.availableCommonFluids.rows.map((row) => Object.assign({}, row));
    newRows.forEach((row) => {
      row.rowData = { ...row.rowData };

      const rheologies =
        row.rowData.RheologyType === RheologyType.Rheometer
          ? row.rowData.Rheometers.map((rheometer) => {
              const rheometerReadings = row.rowData.RheometerReadings.filter(
                (rheometerReading) => rheometerReading.RheometerId === rheometer.Id,
              );
              return RheologyFactory.createRheologyFromRheometer(FluidFactory.createFluid(row.rowData), rheometer, rheometerReadings, 0);
            })
          : [...row.rowData.Rheologies];

      rheologies.sort((a, b) => a.Temperature - b.Temperature);

      row.rowData.ViscosityBHCT = RheologyCalculations.getViscosity(tempBh, shearRate, rheologies);
      row.rowData.ViscositySurface = RheologyCalculations.getViscosity(tempSurface, shearRate, rheologies);
    });
    return createTableState<CommonFluidModel>(newRows);
  },
);

export const selectAvailableCommonGravels = createSelector(getCommonDbState, (...[commonDbState]) => {
  return commonDbState.availableCommonGravels;
});

export const selectPredefinedGlobalOptions = createSelector(
  getCommonDbState,
  (commonDbState) => commonDbState.predefinedGlobalOptions ?? initialGlobalOptionsDto,
);

export const selectOrganizationGlobalOptions = createSelector(getCommonDbState, (...[commonDbState]) =>
  CommonDbSelectorsHelper.overrideGlobalOptions(
    commonDbState.predefinedGlobalOptions ?? initialGlobalOptionsDto,
    commonDbState.orgGlobalOptions ?? initialGlobalOptionsDto,
  ),
);

export const selectOrganizationSeriesColors = createSelector(selectOrganizationGlobalOptions, (state) => state.SeriesColours);

export const selectUserGlobalOptions = createSelector(
  getCommonDbState,
  selectOrganizationGlobalOptions,
  (...[commonDbState, organizationGlobalOptions]) =>
    CommonDbSelectorsHelper.overrideGlobalOptions(
      organizationGlobalOptions,
      commonDbState.personalGlobalOptions ?? initialGlobalOptionsDto,
    ),
);

export const selectVideoSteps = createSelector(selectUserGlobalOptions, (options) => options.VideoSteps);
export const selectVideoFramerate = createSelector(selectUserGlobalOptions, (options) => options.VideoFramerate);

export const selectDefaultMarkerStyle = createSelector(selectUserGlobalOptions, (globalOptions) => globalOptions as IMarkerStyle);

export const selectValidatedPredefinedGlobalOptions = createSelector(selectPredefinedGlobalOptions, (globalOptions) => {
  return GlobalOptionsValidation.validate(globalOptions);
});

export const selectValidatedOrganizationGlobalOptions = createSelector(selectOrganizationGlobalOptions, (globalOptions) => {
  return GlobalOptionsValidation.validate(globalOptions);
});

export const selectValidatedUserGlobalOptions = createSelector(selectUserGlobalOptions, (globalOptions) => {
  return GlobalOptionsValidation.validate(globalOptions);
});
