import { DocumentGeneratorHelper } from '../document-generator.helper';
import { HeadingLevel } from 'docx';
import { IUnitSystemDto } from '@dunefront/common/dto/unit-system.dto';
import { Store } from '@ngrx/store';
import { DocumentTableGeneratorHelper } from '../document-table-generator.helper';
import { ReportScenarioImages } from './chart-report-generator.helper';
import { ConvertUnitPipe } from '@dunefront/common/modules/units/convert-unit.pipe/convert-unit.pipe';
import { CommonReportDataHelpers } from '../../common/common-report-data-helpers';
import { FileChild } from 'docx/build/file/file-child';
import { DocxImageGenerator } from '../docx-image-generator';

export class PsdInputReportGeneratorHelper {
  public static async createPsdInputDataSection(
    store: Store,
    currentUnitSystem: IUnitSystemDto,
    convertUnitPipe: ConvertUnitPipe,
    images: ReportScenarioImages,
    docxImageGenerator: DocxImageGenerator,
  ): Promise<FileChild[]> {
    const psdInputDataArray = await CommonReportDataHelpers.createPsdInputData(store, currentUnitSystem, convertUnitPipe, images);
    const result: FileChild[] = [];

    result.push(DocumentGeneratorHelper.createParagraphHeader('PSD Input', HeadingLevel.HEADING_1, 0));

    for (const psdInputData of psdInputDataArray) {
      result.push(DocumentGeneratorHelper.createParagraphHeader(psdInputData.psdName, HeadingLevel.HEADING_2, 1));

      result.push(DocumentGeneratorHelper.createParagraphHeader('PSD Definition', HeadingLevel.HEADING_3, 2));
      result.push(
        DocumentTableGeneratorHelper.createGenericKeyValueTableFromTableData(psdInputData.psdDefinitionTableData, currentUnitSystem),
      );

      result.push(DocumentGeneratorHelper.createParagraphHeader('PSD Test Data', HeadingLevel.HEADING_3, 2));
      result.push(DocumentTableGeneratorHelper.createTable(psdInputData.psdReadingTableData, null, currentUnitSystem));

      result.push(DocumentGeneratorHelper.createParagraphHeader('PSD Chart', HeadingLevel.HEADING_3, 2));
      result.push(...(await DocumentGeneratorHelper.drawGenericImage(psdInputData.chartData, docxImageGenerator)));

      result.push(DocumentGeneratorHelper.createParagraphHeader('PSD Properties', HeadingLevel.HEADING_3, 2));
      result.push(
        DocumentTableGeneratorHelper.createGenericKeyValueTableFromTableData(psdInputData.psdPropertiesTableData, currentUnitSystem),
      );
    }

    return result;
  }
}
