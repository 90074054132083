import { Routes } from '@angular/router';
import { MASPComponent } from './masp.component';
import { MASPCalculatorComponent } from './masp-calculator/masp-calculator.component';

export const RouteModuleMasp = 'masp';

export const MASPRoutes: Routes = [
  {
    path: RouteModuleMasp,
    component: MASPComponent,
    data: {
      breadcrumb: 'MASP Calculator',
    },
    children: [{ path: '', component: MASPCalculatorComponent }],
  },
];
