<app-grid
  *ngIf="height > 0 && wellFluids"
  [dataSource]="wellFluids"
  [gridConfig]="gridConfig"
  [columns]="gridConfig.columns"
  (selectedRowsChanged)="selectedRowsChanged.emit($event)"
  [showCrudControls]="false"
  [isFirstRowInsertingAllowed]="true"
  [isFirstRowDeleteAllowed]="true"
  [height]="height"
  [groupByColumns]="['FlowPathDescription']"
  dataCy="detailed-fluid-definition-grid"
>
</app-grid>
