import { ChangeDetectorRef, Component, ViewEncapsulation } from '@angular/core';
import { PanelButtonComponent } from '../panel.button.component';
import { Store } from '@ngrx/store';
import { GeneralMenuProps, getGeneralMenuProps } from '../../../../+store/menu-selectors/general/menu-general.selector';
import { filterNil, notEmpty } from '@dunefront/common/common/state.helpers';
import { ModalService } from '../../../modals/modal.service';
import { getFileSettings, getIsFileLocked } from '../../../../+store/file-settings/file-settings.selectors';
import * as dayjs from 'dayjs';
import { lockInputsAction, unlockInputsAction } from '../../../../+store/file-settings/file-settings.actions';
import { FileSettingsDto } from '@dunefront/common/dto/file-settings.dto';
import { firstValueFrom } from 'rxjs';
import { getConnectedFile } from '../../../../+store/backend-connection/backend-connection.selectors';
import { electronSaveProjectAsFileAction } from '../../../../+store/electron-main/electron-main.actions';
import { CopyDialogComponent } from '../../../../pages/home/action-dialogs/copy-dialog/copy.dialog.component';
import { getIsDeleteResultEnabled } from '../../../../+store/menu-selectors/help/menu-help.selectors';
import { deleteAllResultsAction, vacuumDbAction } from '../../../../+store/data-storage/data-storage.actions';
import { DataStorageEffects } from '../../../../+store/data-storage/data-storage.effects';
import { isElectron } from '@dunefront/common/common/electron/is-electron';
import { ExportDataComponent } from '../../../modals/export-data/export-data.component';

@Component({
  selector: 'app-general-menu-panel',
  templateUrl: './general-menu-panel.component.html',
  styleUrls: ['./general-menu-panel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GeneralMenuPanelComponent extends PanelButtonComponent {
  public isFileLocked$ = this.store.select(getIsFileLocked);
  public isDeleteResultEnabled$ = this.store.select(getIsDeleteResultEnabled);
  public generalMenuProps!: GeneralMenuProps;
  private fileSettings!: FileSettingsDto;

  constructor(
    protected override store: Store,
    private cdRef: ChangeDetectorRef,
    private modalService: ModalService,
    private dataStorageEffects: DataStorageEffects,
  ) {
    super(store);

    this.subscription.add(
      notEmpty(store.select(getGeneralMenuProps)).subscribe((generalMenuProps) => {
        this.generalMenuProps = generalMenuProps;
        this.cdRef.markForCheck();
      }),
    );

    this.subscription.add(
      store.select(getFileSettings).subscribe((fileSettings) => {
        this.fileSettings = fileSettings;
        this.cdRef.markForCheck();
      }),
    );
  }

  public async lockInputs(): Promise<void> {
    const fileSettings = await firstValueFrom(this.store.select(getFileSettings));
    const name = await this.modalService.showPrompt(
      '',
      'Edit File Settings',
      'Enter name',
      'sm',
      'Save',
      'Cancel',
      true,
      undefined,
      undefined,
      fileSettings.LockUserName,
      '',
      false,
    );
    if (typeof name === 'string') {
      this.store.dispatch(lockInputsAction({ name }));
    }
  }

  public async unlockInputs(): Promise<void> {
    const confirmUnlock = await this.modalService.showConfirm(
      `The input were locked by ${this.fileSettings.LockUserName} on ${dayjs(this.fileSettings.LockDate as number).format(
        'DD-MMM-YYYY',
      )}. ` + 'Unlocking the inputs will enable editing - do you want to continue?',
      'Information',
    );
    if (confirmUnlock) {
      this.store.dispatch(unlockInputsAction());
    }
  }

  public async saveFileAs(): Promise<void> {
    await this.dataStorageEffects.vacuumIfNeededAsync();
    const file = await firstValueFrom(this.store.select(getConnectedFile).pipe(filterNil()));

    if (isElectron()) {
      this.store.dispatch(electronSaveProjectAsFileAction(file));
    } else {
      this.modalService.open(
        CopyDialogComponent,
        {
          file,
          isSaveAs: true,
        },
        '',
        'sm',
      );
    }
  }

  public async deleteAllResultsClick(): Promise<void> {
    const confirmDelete = await this.modalService.showConfirmNoUndoable('Are you sure you want to delete the results?', 'Delete Results');
    if (!confirmDelete) {
      return;
    }
    this.store.dispatch(deleteAllResultsAction());
  }

  public async vacuumDbClick(): Promise<void> {
    this.store.dispatch(vacuumDbAction({ force: false }));
  }

  public onExportResultsClick(): void {
    this.modalService.open(ExportDataComponent, undefined, 'export-results-modal');
  }
}
