import { Plugin } from 'chart.js/dist/types';
import { Chart, Point } from 'chart.js';
import { TextDrawStyle } from '../../../shared/helpers/canvas-drawing-helpers';
import { Size } from '@dunefront/common/common/math-geometry-helpers';
import { IGradientLineStyle } from '@dunefront/common/modules/reporting/dto/chart-gradient-line.dto';

export interface GradientLine {
  id: number;
  xAxisValue1: number;
  yAxisValue1: number;
  xAxisValue2: number;
  yAxisValue2: number;
  xScaleID: string;
  yScaleID: string;
  style: IGradientLineStyle;
}

export interface DrawingGradientLine {
  id: number;
  point1: Point; // pixels
  point2: Point; // pixels
  style: IGradientLineStyle;
  xScaleID: string;
  yScaleID: string;
}

export interface ScaleInfo {
  title: string;
  units: string[];
  id: string;
  isArgument: boolean;
}

export interface GradientLinePluginOptions {
  lines: GradientLine[];
  scales: ScaleInfo[];
  mode: GradientLineOperationMode;
  xScaleID: string;
  yScaleID: string;
  defaultStyle: IGradientLineStyle;
  canStartDrag?: () => boolean;
  dragChanged?: (isInDragMode: boolean) => void;
  highlightedLineChanged?: (isInDragMode: boolean) => void;
  gradientLineCreatedOrUpdated?: (line: GradientLine) => void;
  gradientLineDoubleClicked?: (gradientLine: GradientLine) => void;
}

export type GradientLinePlugin = Plugin & {
  willPluginHandleDoubleClick(chart: Chart, event: MouseEvent): boolean;
  getSelectedGradientLineId(chart: Chart): number | null;
};

export type GradientLineEditMode = 'default' | 'movePoint1' | 'movePoint2' | 'moveBoth';

export interface EventHandler {
  target: HTMLElement;
  handler: (this: HTMLElement, ev: Event) => any;
}

export interface PointOffsets {
  offsetX: number;
  offsetY: number;
}

export const emptyPointOffsets = (): PointOffsets => ({
  offsetX: 0,
  offsetY: 0,
});

export interface GradientLineAxisParameters {
  title: string;

  delta: { value: number; unit: string };
  gradient?: { value: number; unit: string };

  decimalPlaces: number;
}

export interface TooltipDrawingData {
  rows: TooltipDrawingRow<TooltipDrawingCell>[];
  columnMaxWidths?: number[];
}

export interface MeasuredTooltipDrawingData {
  rows: TooltipDrawingRow<MeasuredTooltipDrawingCell>[];
  columnWidths: number[];
  size: Size;
}

export interface TooltipDrawingRow<T extends TooltipDrawingCell> {
  cells: T[];
}

export interface TooltipDrawingCell<T extends TooltipDrawingItem = TooltipDrawingItem> {
  items: T[];
}

export interface MeasuredTooltipDrawingCell extends TooltipDrawingCell<MeasuredTooltipDrawingItem> {
  measuredSize: Size;
}

export interface TooltipDrawingItem {
  text: string;
  style?: TextDrawStyle;
}

export interface MeasuredTooltipDrawingItem extends TooltipDrawingItem {
  size: Size;
}

/**
 * inactive  - lines only drawn, no highlight,
 * highlight - lines can be highlighted
 * edit      - lines can be edited (as well as highlighted)
 */
export type GradientLineOperationMode = 'inactive' | 'highlight' | 'edit';
