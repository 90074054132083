<div class="search-container">
  <span class="icon-search"></span>
  <p-dropdown
    [optionLabel]="'text'"
    [optionValue]="'value'"
    [filter]="true"
    [options]="items"
    [(ngModel)]="value"
    (keydown.enter)="onEnterKeyDown()"
    [disabled]="disabled"
    filterPlaceholder="Type to search a project"
    data-cy="search-container"
    (onChange)="onChange($event)"
  >
  </p-dropdown>
</div>
