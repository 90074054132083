import { Routes } from '@angular/router';
import { CompletionComponent } from './completion.component';
import { LowerCompletionComponent } from './lower-completion/lower-completion.component';
import { RunningStringComponent } from './running-string/running-string.component';
import { ShuntTubesComponent } from './shunt-tubes/shunt-tubes.component';
import { SchematicComponent } from './schematic/schematic.component';
import { VolumesComponent } from './volumes/volumes.component';
import {
  RouteModuleCompletion,
  RouteModuleCompletionLowerCompletion,
  RouteModuleCompletionRunningString,
  RouteModuleCompletionSchematic,
  RouteModuleCompletionShuntTubes,
  RouteModuleCompletionVolumes,
} from './completion-routes-names';

export const completionRoutes: Routes = [
  {
    path: RouteModuleCompletion,
    component: CompletionComponent,
    data: {
      breadcrumb: 'Completion',
    },
    children: [
      {
        path: RouteModuleCompletionLowerCompletion,
        component: LowerCompletionComponent,
        data: {
          breadcrumb: 'Lower Completion',
        },
      },
      {
        path: RouteModuleCompletionRunningString,
        component: RunningStringComponent,
        data: {
          breadcrumb: 'Running String',
        },
      },
      {
        path: RouteModuleCompletionShuntTubes,
        component: ShuntTubesComponent,
        data: {
          breadcrumb: 'Shunt Tubes',
        },
      },
      {
        path: RouteModuleCompletionSchematic,
        component: SchematicComponent,
        data: {
          breadcrumb: 'Schematic',
        },
      },
      {
        path: RouteModuleCompletionVolumes,
        component: VolumesComponent,
        data: {
          breadcrumb: 'Volumes',
        },
      },
      {
        path: '',
        redirectTo: RouteModuleCompletionLowerCompletion,
        pathMatch: 'full',
      },
    ],
  },
];
