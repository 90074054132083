import { ChangeDetectionStrategy, Component } from '@angular/core';
import { OptionsComponent } from '../../options-component';

@Component({
  selector: 'app-thermal-options',
  templateUrl: './thermal-options.component.html',
  styleUrls: ['./thermal-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ThermalOptionsComponent extends OptionsComponent {}
