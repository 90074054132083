import { Injectable } from '@angular/core';
import { combineLatest, Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { filter, map } from 'rxjs/operators';
import { selectUserGlobalOptions } from '../../../../../+store/common-db/common-db.selectors';
import { MarkerMode } from '../../../../../common-modules/chart/chart-controller.component';
import { updateInjectionTestCalculatorRow } from '../../../../../+store/calculators/injection-test-calculator/injection-test-calculator.actions';
import { getValidatedInjectionTestCalculatorModuleState } from '../../../../../+store/calculators/injection-test-calculator/injection-test-calculator.selectors';
import { MarkersServiceBase } from '../../../../../common-modules/chart/chart-controller-providers/markers-service-base';
import { IMarker } from '@dunefront/common/modules/reporting/dto/chart-marker.dto';
import { changeProp, ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';
import { StoreCrudPropsFactory } from '@dunefront/common/common/common-store-crud.interfaces';
import { InjectionTestCalculatorDto } from '@dunefront/common/modules/calculators/injection-test-calculator/injection-test-calculator.dto';
import { concatLatestFrom } from '@ngrx/operators';

@Injectable()
export class InjectionTestMarkersService extends MarkersServiceBase {
  private readonly injectionTestCalculatorModuleState$ = this.store.select(getValidatedInjectionTestCalculatorModuleState);
  private readonly markerId = 1;

  private markerMoved$ = new Subject<IMarker>();

  private _markers$: Observable<IMarker[]> = combineLatest([
    this.store.select(selectUserGlobalOptions),
    this.injectionTestCalculatorModuleState$,
  ]).pipe(
    map(([globalOptions, state]) => [
      {
        id: this.markerId,
        value: state.InjectionTestCalculator.MarkerPosition,
        isValueAxisMarker: false,
        name: 'Slope Change',
        isOverrideStyle: false,
        style: globalOptions,
      },
    ]),
  );

  public get markers$(): Observable<IMarker[]> {
    return this._markers$;
  }

  public get disableAddingMarkers(): boolean {
    return true;
  }

  constructor(private store: Store) {
    super(MarkerMode.CustomHandler);

    this.subscription.add(
      this.markerMoved$
        .pipe(
          concatLatestFrom(() => this.injectionTestCalculatorModuleState$),
          filter(([marker, state]) => state.InjectionTestCalculator.MarkerPosition !== marker.value),
        )
        .subscribe(([marker, state]) => {
          const changeProps: ObjectChangeProp<InjectionTestCalculatorDto> = {
            key: 'MarkerPosition',
            value: marker.value,
            shouldResetResults: true,
          };
          store.dispatch(
            updateInjectionTestCalculatorRow(
              StoreCrudPropsFactory.updateRow(changeProp(state.InjectionTestCalculator, changeProps), changeProps),
            ),
          );
        }),
    );
  }

  public onMarkerMoved(marker: IMarker): void {
    this.markerMoved$.next(marker);
  }
}
