import { createReducer, on } from '@ngrx/store';
import { onAuthenticated, onTokenRefreshed, onUnAuthenticated } from './auth.actions';
import { UserRole } from '@dunefront/common/modules/auth/auth.interfaces';

export const AuthModuleFeatureName = 'auth';

export interface IAuthState {
  isAuthenticated: boolean;
  user: IUser | undefined;
  accessToken: string | undefined;
}

export interface IUser {
  name: string;
  email: string;
  avatarUrl: string | undefined;
  role: UserRole;
}

export const initialState: IAuthState = {
  isAuthenticated: false,
  user: undefined,
  accessToken: undefined,
};

export const authModuleReducer = createReducer<IAuthState>(
  initialState,
  on(
    onAuthenticated,
    (state, action): IAuthState => ({
      isAuthenticated: true,
      user: action.user,
      accessToken: action.accessToken,
    }),
  ),
  on(
    onTokenRefreshed,
    (state, action): IAuthState => ({
      ...state,
      accessToken: action.accessToken,
    }),
  ),
  on(onUnAuthenticated, (): IAuthState => ({ ...initialState })),
);
