import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-textarea-prompt-modal-content',
  template: `
    <div class="dialog-header" *ngIf="title">
      <h4>{{ title }}</h4>
    </div>
    <div class="dialog-content">
      <p *ngIf="message" [innerHTML]="message"></p>
      <textarea data-cy="prompt-modal-textarea" [(ngModel)]="value"></textarea>
    </div>
    <div class="dialog-footer">
      <button
        appButton
        type="button"
        [dialogRef]="ref"
        class="btn btn-light btn-sm"
        buttonType="cancel"
        data-cy="prompt-modal-reject"
        (buttonClick)="ref.close(null)"
        [isUiLockable]="false"
      >
        {{ cancelButtonText }}
      </button>
      <button
        appButton
        type="button"
        [dialogRef]="ref"
        class="btn btn-primary btn-sm"
        buttonType="primary"
        data-cy="prompt-modal-confirm"
        (buttonClick)="okClicked()"
        [isUiLockable]="false"
        [disabled]="!isOkEnabled"
      >
        {{ okButtonText }}
      </button>
    </div>
  `,
  styles: [
    `
      textarea {
        height: 100px;
      }
      .btn {
        min-width: 50px;
      }
    `,
  ],
})
export class PromptTextareaModalContentComponent {
  public title: string;
  public message: string;
  public label = '';
  public okButtonText = 'OK';
  public cancelButtonText = 'Cancel';
  public isTextInput = true;
  public decimalPlaces = 2;
  public value = '';

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig<IPromptConfigData>,
  ) {
    this.title = config.data?.title ?? '';
    this.message = config.data?.message ?? '';
    this.okButtonText = config.data?.okButtonText ?? 'Ok';
    this.cancelButtonText = config.data?.cancelButtonText ?? 'Cancel';

    const value = config.data?.inputValue;
    this.value = value ?? '';
  }

  public okClicked(): void {
    this.ref.close(this.value);
  }

  public get isOkEnabled(): boolean {
    return this.value.length > 0;
  }
}

export interface IPromptConfigData {
  title: string;
  message: string;
  label: string;
  okButtonText?: string;
  cancelButtonText?: string;
  inputValue?: string;
}
