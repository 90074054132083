import {
  IDeleteImportDataColumnsPayload,
  IDeleteImportDataFilePayload,
  IUpdateImportDataRowsPayload,
  IVacuumDbPayload,
} from './import-data-updater-worker';
import { IEquationCalculationPayload } from './equation-calculation-worker';
import { IConvertExportDataUnitsPayload } from './export-data-unit-converter-worker';
import { IDbMigrationWorkerPayload } from './db-migration-worker';
import { IUser } from '../auth/auth.interfaces';
import { IJobDataBase } from '../calculation-engine/calculation-engine.interfaces';
import { IActivityOverlayConfig } from '../ui/ui.interfaces';
import { TestCommandWorkerPayload } from './test-command-runner';
import { IFile } from '../../dto/file.dto';

export class JsWorkerJobInfo implements IJobDataBase {
  constructor(
    public readonly jobId: string,
    public readonly user: IUser,
    public readonly databaseFileHash: string,
    public readonly activityOverlayConfig: IActivityOverlayConfig | undefined,
  ) {}
}

export interface JsWorkerActionBase<T extends { file?: IFile }> {
  readonly jobInfo: JsWorkerJobInfo;
  readonly type: JsWorkerActionType;
  readonly payload: T;
}

export enum JsWorkerActionType {
  VacuumDb = '[JsWorkerAction] VacuumDb',
  UpdateImportDataRows = '[JsWorkerAction] UpdateImportDataRows',
  DeleteImportDataFile = '[JsWorkerAction] DeleteImportDataFile',
  DeleteImportDataColumns = '[JsWorkerAction] DeleteImportDataColumns',
  CalculateEquation = '[JsWorkerAction] CalculateEquation',
  ConvertExportDataUnits = '[JsWorkerAction] ConvertExportDataUnits',
  RunDbMigration = '[JsWorkerAction] RunDbMigration',
  RunTestCommand = '[JsWorkerAction]  RunTestCommand',
}

export class UpdateImportDataRowsJsWorkerAction implements JsWorkerActionBase<IUpdateImportDataRowsPayload> {
  public readonly type = JsWorkerActionType.UpdateImportDataRows;

  constructor(
    public readonly jobInfo: JsWorkerJobInfo,
    public readonly payload: IUpdateImportDataRowsPayload,
  ) {}
}

export class VacuumDbJsWorkerAction implements JsWorkerActionBase<IVacuumDbPayload> {
  public readonly type = JsWorkerActionType.VacuumDb;

  constructor(
    public readonly jobInfo: JsWorkerJobInfo,
    public readonly payload: IVacuumDbPayload,
  ) {}
}

export class DeleteImportDataFileJsWorkerAction implements JsWorkerActionBase<IDeleteImportDataFilePayload> {
  public readonly type = JsWorkerActionType.DeleteImportDataFile;

  constructor(
    public readonly jobInfo: JsWorkerJobInfo,
    public readonly payload: IDeleteImportDataFilePayload,
  ) {}
}

export class DeleteImportDataColumnsJsWorkerAction implements JsWorkerActionBase<IDeleteImportDataColumnsPayload> {
  public readonly type = JsWorkerActionType.DeleteImportDataColumns;

  constructor(
    public readonly jobInfo: JsWorkerJobInfo,
    public readonly payload: IDeleteImportDataColumnsPayload,
  ) {}
}

export class CalculateEquationJsWorkerAction implements JsWorkerActionBase<IEquationCalculationPayload> {
  public readonly type = JsWorkerActionType.CalculateEquation;

  constructor(
    public readonly jobInfo: JsWorkerJobInfo,
    public readonly payload: IEquationCalculationPayload,
  ) {}
}

export class ConvertExportDataUnitsJsWorkerAction implements JsWorkerActionBase<IConvertExportDataUnitsPayload> {
  public readonly type = JsWorkerActionType.ConvertExportDataUnits;

  constructor(
    public readonly jobInfo: JsWorkerJobInfo,
    public readonly payload: IConvertExportDataUnitsPayload,
  ) {}
}

export class RunDbMigrationJsWorkerAction implements JsWorkerActionBase<IDbMigrationWorkerPayload> {
  public readonly type = JsWorkerActionType.RunDbMigration;

  constructor(
    public readonly jobInfo: JsWorkerJobInfo,
    public readonly payload: IDbMigrationWorkerPayload,
  ) {}
}

export class RunTextCommandAction implements JsWorkerActionBase<TestCommandWorkerPayload> {
  public readonly type = JsWorkerActionType.RunTestCommand;

  constructor(
    public readonly jobInfo: JsWorkerJobInfo,
    public readonly payload: TestCommandWorkerPayload,
  ) {}
}

export type JsWorkerActions =
  | VacuumDbJsWorkerAction
  | UpdateImportDataRowsJsWorkerAction
  | DeleteImportDataFileJsWorkerAction
  | DeleteImportDataColumnsJsWorkerAction
  | CalculateEquationJsWorkerAction
  | ConvertExportDataUnitsJsWorkerAction
  | RunDbMigrationJsWorkerAction
  | RunTextCommandAction;

export interface IJobProgressEvent<T> {
  jobId: string;
  data: T;
}
