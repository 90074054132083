import { BypassPorts } from '../../bypass-ports';
import { Pipe } from '../../pipe';
import { isScreenPipe, ScreenPipe, ScreenPipeFactory } from './screen-pipe';
import { ICDType, LowerCompletionPipeDto } from '../../../../dto/lower-completion-pipe.dto';
import { PipeType } from '../../../../dto/pipe.dto';
import { ITableRow } from '../../../../common/common-grid.interfaces';

export interface IcdScreenPipe extends ScreenPipe {
  ICDType: ICDType;
  ICDPorts: BypassPorts[];
  NoOf0_075inPorts: number;
  NoOf0_100inPorts: number;
  NoOf0_125inPorts: number;
  NoOf0_150inPorts: number;
  A_Coefficient_Production: number;
  B_Coefficient_Production: number;
  C_Coefficient_Production: number;
  A_Coefficient_Injection: number;
  B_Coefficient_Injection: number;
  C_Coefficient_Injection: number;
}

export class IcdScreenPipeFactory {
  public static createFromPermanentCompletion(dto: LowerCompletionPipeDto, icdPorts: BypassPorts[]): IcdScreenPipe {
    const screenPipe = ScreenPipeFactory.createDto(dto);
    const icdScreenPipe: IcdScreenPipe = {
      ...screenPipe,
      PipeType: PipeType.ICD_Screen,
      ICDType: dto.ICDType ?? ICDType.None,
      NoOf0_075inPorts: dto.NoOf0_075inPorts,
      NoOf0_100inPorts: dto.NoOf0_100inPorts,
      NoOf0_125inPorts: dto.NoOf0_125inPorts,
      NoOf0_150inPorts: dto.NoOf0_150inPorts,
      A_Coefficient_Production: dto.A_Coefficient_Production,
      B_Coefficient_Production: dto.B_Coefficient_Production,
      C_Coefficient_Production: dto.C_Coefficient_Production,
      A_Coefficient_Injection: dto.A_Coefficient_Injection,
      B_Coefficient_Injection: dto.B_Coefficient_Injection,
      C_Coefficient_Injection: dto.C_Coefficient_Injection,
      ICDPorts: icdPorts,
    };
    return icdScreenPipe;
  }
}

export function isICDScreenPipe(pipe: Pipe): pipe is IcdScreenPipe {
  return isScreenPipe(pipe) && (pipe as IcdScreenPipe).ICDPorts !== undefined;
}

export function isICDScreenRow(row: ITableRow<Pipe>): row is ITableRow<IcdScreenPipe> {
  return isICDScreenPipe(row.rowData);
}
