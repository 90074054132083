import { ChangeDetectionStrategy, Component, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { getBackendConnectionState } from '../../../+store/backend-connection/backend-connection.selectors';
import { Store } from '@ngrx/store';
import { BackendConnectionModuleState, ConnectionStatus } from '../../../+store/backend-connection/backend-connection.reducer';
import { NavigationEnd, Router } from '@angular/router';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { LoginScreenUrl } from '@dunefront/common/common/constants';
import { ButtonModule } from '../button/button.module';
import { refreshAppAction } from '../../../+store/app.actions';
import { getHandledBackendConnectionErrorMessage } from '@dunefront/common/exceptions/errors';
import { Observable } from 'rxjs';
import { AuthErrorModule } from '../auth-error/auth-error.component';

@Component({
  selector: 'app-overlay',
  templateUrl: './connection-status-overlay.component.html',
  styleUrls: ['./connection-status-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConnectionStatusOverlayComponent {
  public connectionStatus$: Observable<BackendConnectionModuleState>;
  public ConnectionStatus = ConnectionStatus;
  public isOverlayDisabled$: Observable<boolean>;

  constructor(
    protected store: Store,
    private router: Router,
  ) {
    this.connectionStatus$ = this.store.select(getBackendConnectionState);
    this.isOverlayDisabled$ = this.router.events.pipe(
      filter((e: any) => e instanceof NavigationEnd),
      distinctUntilChanged((e1: NavigationEnd, e2: NavigationEnd) => e1.urlAfterRedirects === e2.urlAfterRedirects),
      map((navEnd) => navEnd.url.includes(LoginScreenUrl)),
    );
  }

  public refresh(): void {
    this.store.dispatch(refreshAppAction({ error: null }));
  }

  public isErrorHandled(connectionStatus: BackendConnectionModuleState): boolean {
    return getHandledBackendConnectionErrorMessage(connectionStatus.connectionErrorMessage) != '';
  }
}

@NgModule({
  imports: [CommonModule, ButtonModule, AuthErrorModule],
  declarations: [ConnectionStatusOverlayComponent],
  exports: [ConnectionStatusOverlayComponent],
})
export class OverlayModule {}
