<ng-container *ngIf="isStateLoaded$ | async">
  <app-page-panel header="Section Volume Calculator" (click)="onHelpChange('completion', 'section-volumes-calculator')">
    <app-section-volume-calculator [completion]="completion$ | async"></app-section-volume-calculator>
  </app-page-panel>
  <app-page-panel
    header="Gauge Section Information"
    [panelHelpMode]="PanelHelpMode.GRID"
    (click)="onHelpChange('completion', 'gauge-section-information')"
  >
    <app-gauge-section-info-grid #gridComponent></app-gauge-section-info-grid>
  </app-page-panel>
</ng-container>
