import { createSelector } from '@ngrx/store';
import { getUiState } from '../../ui/ui.selectors';
import { getUndoRedoState } from '../../undo-redo/undo-redo.selectors';
import { selectCurrentUnitSystem } from '../../units/units.selectors';
import { CalculationStatus } from '../../calculation-engine/calculation-engine-module.state';
import { isSimulateBased } from '@dunefront/common/modules/scenario/scenario.dto';
import { isDataAnalysis, isEvaluate, isTrendAnalysis } from '../menu-selectors.helpers';
import { getStorageColumnNames } from '../../data-storage/data-storage.selectors';
import { getAreCurrentResultsPresentAndCompleted } from '../../calculation-engine/calculation-engine-results.selectors';
import { getIsExportDataJobActive } from '../../reporting/reporting.selectors';
import { getJobForCurrentContext } from '../../calculation-engine/calculation-engine.selectors';

const getIsExportEnabled = createSelector(
  getUiState,
  getStorageColumnNames,
  getAreCurrentResultsPresentAndCompleted,
  getIsExportDataJobActive,
  getJobForCurrentContext,
  (uiState, storageColumnNames, areResultsPresent, isExportDataJobActive, currentJob) => {
    const _isSimulate = isSimulateBased(uiState.appModuleType);
    const _isEvaluate = isEvaluate(uiState.appModuleType);
    const _isTrendAnalysis = isTrendAnalysis(uiState.appModuleType);
    const _isDataAnalysis = isDataAnalysis(uiState.appModuleType);

    return (
      (((_isEvaluate || _isTrendAnalysis || _isDataAnalysis) && storageColumnNames.length > 0) ||
        ((_isSimulate || _isEvaluate || _isTrendAnalysis || _isDataAnalysis) && areResultsPresent)) &&
      !isExportDataJobActive &&
      currentJob?.status !== CalculationStatus.running
    );
  },
);

export const getGeneralMenuProps = createSelector(
  getUndoRedoState,
  selectCurrentUnitSystem,
  getIsExportEnabled,
  (undoRedoState, currentUnitSystem, isExportEnabled): GeneralMenuProps => {
    const generalMenuProps: GeneralMenuProps = {
      undoEnabled: undoRedoState.currentIndex !== 0 && !undoRedoState.isBusy,
      redoEnabled: undoRedoState.currentIndex !== undoRedoState.history.length - 1 && !undoRedoState.isBusy,
      editUnitEnabled: currentUnitSystem.commonDbType !== 'Predefined',
      deleteUnitEnabled: currentUnitSystem.commonDbType !== 'Predefined',
      exportEnabled: isExportEnabled,
    };
    return generalMenuProps;
  },
);

export interface GeneralMenuProps {
  undoEnabled: boolean;
  redoEnabled: boolean;
  editUnitEnabled: boolean;
  deleteUnitEnabled: boolean;
  exportEnabled: boolean;
}
