import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { ModalService } from '../modal.service';
import { Subscription } from 'rxjs';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { modalButton, ModalButtonConfig } from '../generic-modal/generic-modal.component';

@Component({
  selector: 'app-scenario-manager',
  templateUrl: './scenario-manager.component.html',
  styleUrls: ['./scenario-manager.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScenarioManagerComponent implements OnDestroy {
  protected subscription = new Subscription();
  public activeTab: 'basic' | 'clone' = 'basic';
  public isHelpOpen = false;
  public modalTitle = 'Scenario Manager';

  public modalButtonsConfigs: ModalButtonConfig[] = [
    modalButton('Close', (): void => this.closeClicked(), 'scenario-manager-close-btn', 'cancel'),
  ];

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    protected store: Store,
    protected cdRef: ChangeDetectorRef,
    protected modalService: ModalService,
  ) {}

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public closeClicked(): void {
    this.ref.close();
  }

  public onHelpClick(isHelpOpen: boolean): void {
    this.isHelpOpen = isHelpOpen;
  }

  public onSimulationsStarted(): void {
    this.ref.close();
  }
}
