import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ButtonModule } from '../../../../shared/components/button/button.module';
import { SelectModule } from '../../../../shared/components/form-components/select/select.module';
import { UnitsModule } from '../../../../common-modules/units/units.module';
import { FileOptionsComponent } from './file-options.component';
import { TabbedPageModule } from '../../../../common-modules/tabbed-page/tabbed-page.component';
import { PagePanelModule } from '../../../../common-modules/page-panel/page-panel.component';
import { FormInputModule } from '../../../../shared/components/form-components/form-input/form-input.component';
import { CheckBoxModule } from '../../../../shared/components/form-components/check-box/check-box.component';
import { RadioGroupModule } from '../../../../shared/components/form-components/radio-group/radio-group.component';
import { ChartModule } from '../../../../common-modules/chart/chart.module';
import { UnwrapDirectiveModule } from '../../../../shared/directives/unwrap.directive';
import { HideOnDataProModule } from '../../../../shared/directives/hide-on-datapro';
import { HomeOptionsComponent } from './home-options.component';

@NgModule({
  declarations: [FileOptionsComponent, HomeOptionsComponent],
  imports: [
    CommonModule,
    TabbedPageModule,
    PagePanelModule,
    UnitsModule,
    FormInputModule,
    CheckBoxModule,
    RadioGroupModule,
    ButtonModule,
    ChartModule,
    UnwrapDirectiveModule,
    SelectModule,
    RouterModule,
    HideOnDataProModule,
  ],
  exports: [FileOptionsComponent, HomeOptionsComponent],
})
export class FileOptionsComponentModule {}
