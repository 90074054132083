import { Injectable } from '@angular/core';
import {
  CopyCalcEngineGeneratedPumpingScheduleAction,
  CopyCalcEngineGeneratedRunningStringAction,
  DeletePumpedFluidAndGravelRowsAction,
  DeletePumpingScheduleRowsAction,
  DeleteWellFluidsRowsAction,
  GenerateEvaluationScheduleAction,
  GenerateSimulateScheduleAction,
  InsertPumpedFluidAndGravelRowAction,
  InsertPumpingScheduleRowAction,
  InsertWellFluidsRowAction,
  PumpingModuleActionTypes,
  PumpingModuleName,
  ReloadPumpingAction,
  ReloadPumpingScheduleAction,
  ReloadWellFluidsAction,
  SimulateScheduleGenerator_CouldNotGenerateSchedule,
  SwitchDetailedFluidDefinitionTypeAction,
  UpdatePumpedFluidAndGravelRowsAction,
  UpdatePumpingAction,
  UpdatePumpingScheduleRowsAction,
  UpdateWellFluidsRowAction,
} from '@dunefront/common/modules/pumping/pumping-module.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Action, Store } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { BackendConnectionService } from '../../shared/backend-connection/backend-connection.service';
import { dataFailed, deleteRowsSuccess, insertRowsSuccess, scenarioOrRangeLoadedAction, updateRowSuccess } from '../app.actions';
import * as actions from './pumping.actions';
import { setFullReturnsAction, switchDetailedFluidDefinitionTypeAction } from './pumping.actions';
import { CrudResponse } from '@dunefront/common/modules/common.actions';
import { BaseWsEffects } from '../base-ws.effects';
import { getCurrentHistoryState } from '../undo-redo/undo-redo.selectors';
import { IUndoableAction } from '../undo-redo/undo-redo.reducer';
import { getSingleRow, UpdateRowsWsAction } from '@dunefront/common/ws.action';
import { PumpingDto } from '@dunefront/common/modules/pumping/dto/pumping.dto';
import { PumpingScheduleConverter } from '@dunefront/common/modules/pumping/dto/pumping-schedule.dto';
import { getFluidsSelectData } from '../fluid/fluid.selectors';
import { getRowsWithoutInsertRow, ITableState } from '@dunefront/common/common/common-grid.interfaces';
import { ModalService } from '../../common-modules/modals/modal.service';
import { v4 as uuidV4 } from 'uuid';
import { getCalculatedSimulatePumpingSchedule, getPumpingScheduleValidationDeps } from './selectors/pumping-schedule.selectors';
import { getSwitchFluidDefinitionTypeJobData } from './selectors/well-fluid-fluid-definition-converter.selectors';
import { PumpingFactory } from '@dunefront/common/modules/pumping/model/pumping/pumping.factory';
import { WellFluidFactory } from '@dunefront/common/modules/pumping/model/well-fluid/well-fluid.factory';
import { PumpingScheduleFactory } from '@dunefront/common/modules/pumping/model/pumping-schedule/pumping-schedule.factory';
import { IPumpingScheduleValidationDeps } from '@dunefront/common/modules/pumping/model/pumping-schedule/pumping-schedule.validation';
import { PumpingScheduleCalculations } from '@dunefront/common/modules/pumping/model/pumping-schedule/pumping-schedule.calculations';
import { PumpingSchedule } from '@dunefront/common/modules/pumping/model/pumping-schedule/pumping-schedule';
import { getSimulateScheduleGeneratorJobInputData } from './selectors/simulate-schedule-generator.selectors';
import { loadReferenceVariablesAction } from '../reference-variables/reference-variables.actions';
import { validateState } from '../state-validation-custom-operators';
import { WsActionPropsFactory } from '@dunefront/common/common/ws-action/ws-action-props.factory';
import { undoRedoAction } from '../undo-redo/undo-redo.action';
import { getValidatedDeveloperSettings } from '../settings/validated-settings.selectors';
import { updateScenarioRangePropertiesAction } from '../range/range.actions';
import { ISelectItem } from '@dunefront/common/common/select.helpers';
import {
  getDataAnalysisComponentSelectedValues,
  IValidatedAnalysisDataComponentStateWithSelectedValues,
} from './selectors/evaluate-pumping-schedule-analysis-data-component.selectors';
import { getResultsSourceKey } from '../reporting/reporting.selectors';
import { IAnalysisDataComponentSelectedValues } from '@dunefront/common/modules/pumping/pumping-module.state';
import { ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import { ScenarioRangePropertiesDto } from '@dunefront/common/dto/scenario-range-properties.dto';
import { CrudResponseResultAction, WsActionResponse } from '@dunefront/common/response-ws.action';

@Injectable()
export class PumpingEffects extends BaseWsEffects {
  constructor(actions$: Actions, store: Store, wsService: BackendConnectionService, modalService: ModalService) {
    super(actions$, wsService, PumpingModuleName, true, true, modalService, store);
  }

  // region Pumping

  public updatePumping$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.updatePumpingAction),
      mergeMap((action) =>
        this.emit<CrudResponse>(
          new UpdatePumpingAction({
            rows: [PumpingFactory.toDto(action.pumping)],
            colIds: action.changedKeys,
            shouldResetResults: true,
            scenarioId: action.pumping.ScenarioId,
          }),
        ).pipe(
          mergeMap((response) => {
            const actions: Action[] = [updateRowSuccess(response.payload)];

            const changedCols = response.payload.affectedRows.pumping?.colIds;
            if (changedCols?.includes('BlankPackingPercentageForGravelRequired') || changedCols?.includes('IsBlankPackingForGravelRequired')) {
              actions.push(loadReferenceVariablesAction({ scenarioId: response.payload.scenarioId }));
            }
            return actions;
          }),
          catchError((err) => of(dataFailed(err))),
        ),
      ),
    ),
  );

  public reloadPumping$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.reloadPumpingAction),
      switchMap((action) => {
        return this.emit<CrudResponse>(new ReloadPumpingAction(action.scenarioId, action.rangeId)).pipe(
          map((result) => updateRowSuccess(result.payload)),
          catchError((err) => of(dataFailed(err))),
        );
      }),
    ),
  );

  // endregion

  // region WellFluids

  public insertWellFluidsRow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.insertWellFluidsRowsAction),
      mergeMap((action) => this.emitInsert(new InsertWellFluidsRowAction(WsActionPropsFactory.insertAction(action, WellFluidFactory.toDto)))),
    ),
  );

  public updateWellFluidsRow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.updateWellFluidsRowsAction),
      mergeMap((action) => this.emitUpdate(new UpdateWellFluidsRowAction(action))),
    ),
  );

  public deleteWellFluidsRows$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.deleteWellFluidsRowsAction),
      mergeMap((action) =>
        this.emit<CrudResponse>(new DeleteWellFluidsRowsAction(action)).pipe(
          map((result) => deleteRowsSuccess(result.payload)),
          catchError((err) => of(dataFailed(err))),
        ),
      ),
    ),
  );

  public reloadWellFluidsRows$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.reloadWellFluidsAction),
      switchMap((action) => {
        return this.emit<CrudResponse>(new ReloadWellFluidsAction(action.scenarioId, action.rangeId)).pipe(
          map((result) => updateRowSuccess(result.payload)),
          catchError((err) => of(dataFailed(err))),
        );
      }),
    ),
  );

  public switchDetailedFluidDefinitionType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.switchDetailedFluidDefinitionTypeAction),
      concatLatestFrom(() => this.store.select(getSwitchFluidDefinitionTypeJobData)),
      mergeMap(([action, jobData]) => {
        const isWellFluidDepthByVolume = action.isWellFluidDepthByVolume;
        const scenarioId = action.scenarioId;

        return this.emit<CrudResponse>(
          new SwitchDetailedFluidDefinitionTypeAction(isWellFluidDepthByVolume, scenarioId, action.rangeId, jobData),
        ).pipe(
          map((result) => updateRowSuccess({ ...result.payload, ...(action.undoOrRedo ? { undoOrRedo: action.undoOrRedo } : {}) })),
          catchError((err) => of(dataFailed(err))),
        );
      }),
    ),
  );

  // endregion

  // region PumpingSchedule

  public insertPumpingScheduleRow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.insertPumpingScheduleRowsAction),
      concatLatestFrom(() => this.store.select(getFluidsSelectData)),
      mergeMap(([action, fluids]) =>
        this.emitInsert(new InsertPumpingScheduleRowAction(WsActionPropsFactory.insertAction(action, PumpingScheduleFactory.toDto))).pipe(
          mergeMap((result) => [result, loadReferenceVariablesAction({ scenarioId: action.rows[0].rowData.ScenarioId })]),
        ),
      ),
    ),
  );

  public updatePumpingScheduleRow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.updatePumpingScheduleRowsAction),
      concatLatestFrom(() => this.store.select(getFluidsSelectData)),
      mergeMap(([action, fluids]) =>
        this.emit<CrudResponse>(new UpdatePumpingScheduleRowsAction(action, fluids.items[0]?.value)).pipe(
          mergeMap((result) => {
            const actions: Action[] = [updateRowSuccess(result.payload)];

            const changedCols = result.payload.affectedRows.pumpingSchedule?.colIds;

            // while pasting or auto-filling with ctrl+D, colIds are empty ( because we are updating whole rows )
            if (
              changedCols?.length === 0 ||
              changedCols?.includes('GravelId') ||
              changedCols?.includes('GravelConcentration') ||
              changedCols?.includes('StageVolume')
            ) {
              actions.push(loadReferenceVariablesAction({ scenarioId: result.payload.scenarioId }));
            }
            return actions;
          }),
          catchError((err) => of(dataFailed(err))),
        ),
      ),
    ),
  );

  public deletePumpingScheduleRows$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.deletePumpingScheduleRowsAction),
      mergeMap((action) =>
        this.emitDelete(new DeletePumpingScheduleRowsAction(action)).pipe(
          mergeMap((result) => [result, loadReferenceVariablesAction({ scenarioId: action.scenarioId })]),
        ),
      ),
    ),
  );

  public reloadPumpingScheduleRows$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.reloadPumpingScheduleAction),
      switchMap((action) => {
        return this.emit<CrudResponse>(new ReloadPumpingScheduleAction(action.scenarioId)).pipe(
          map((result) => updateRowSuccess(result.payload)),
          catchError((err) => of(dataFailed(err))),
        );
      }),
    ),
  );

  // endregion

  // region pumpedFluidAndGravel

  public insertPumpedFluidAndGravelRowsAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.insertPumpedFluidAndGravelRowsAction),
      mergeMap((action) =>
        this.emitInsert(
          new InsertPumpedFluidAndGravelRowAction(
            WsActionPropsFactory.insertAction(action, (row) => row),
            [],
            action.rows[0].rowData.RangeId,
          ),
        ),
      ),
    ),
  );

  public updatePumpedFluidAndGravelRowsAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.updatePumpedFluidAndGravelRowsAction),
      mergeMap((action) => this.emitUpdate(new UpdatePumpedFluidAndGravelRowsAction(action))),
    ),
  );

  public deletePumpedFluidAndGravelRowsAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.deletePumpedFluidAndGravelRowsAction),
      mergeMap((action) => this.emitDelete(new DeletePumpedFluidAndGravelRowsAction(action))),
    ),
  );

  // endregion

  //region schedule generator

  public setFullReturns$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setFullReturnsAction),
      concatLatestFrom(() => [this.store.select(getCalculatedSimulatePumpingSchedule), this.store.select(getPumpingScheduleValidationDeps)]),
      map(([, pumpingState, pumpingValidationDeps]) =>
        PumpingScheduleCalculations.SetFullReturns(
          pumpingState as ITableState<PumpingSchedule>,
          pumpingValidationDeps as IPumpingScheduleValidationDeps,
        ),
      ),
      mergeMap((pumpingSchedule) =>
        this.emitUpdate(
          new UpdatePumpingScheduleRowsAction({
            rows: getRowsWithoutInsertRow(pumpingSchedule.rows),
            colIds: ['ReturnRate'],
            shouldResetResults: true,
          }),
        ),
      ),
    ),
  );

  public generateScheduleAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.generateScheduleAction),
      switchMap((action) =>
        of(action).pipe(
          concatLatestFrom(() => this.store.select(getSimulateScheduleGeneratorJobInputData)),
          mergeMap(([action, inputData]) =>
            this.emit<CrudResponse>(new GenerateSimulateScheduleAction(action.scenarioId, uuidV4(), inputData), 'Generating schedule...').pipe(
              map((result: WsActionResponse<CrudResponse>) => updateRowSuccess(result.payload)),
              catchError((err) => {
                if (err === SimulateScheduleGenerator_CouldNotGenerateSchedule) {
                  this.modalService.showAlert('A schedule could not be generated for the specified conditions', 'Warning').then();
                  return EMPTY;
                }

                return of(dataFailed(err));
              }),
            ),
          ),
        ),
      ),
    ),
  );

  public generateEvaluationScheduleAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.generateEvaluationScheduleAction),
      validateState(this, this.modalService, this.store),
      concatLatestFrom(() => this.store.select(getValidatedDeveloperSettings)),
      mergeMap(([action, developerSettings]) => {
        return this.emitUpdate(
          new GenerateEvaluationScheduleAction(
            action.scenariosAndRanges[0].scenarioId,
            action.scenariosAndRanges[0].rangeId,
            developerSettings,
            uuidV4(),
          ),
          'Generating schedule preview...',
          '',
          true,
        );
      }),
    ),
  );

  public copyCalcEngineGeneratedPumpingScheduleAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.copyCalcEngineGeneratedPumpingScheduleAction),
      concatLatestFrom(() => this.store.select(getResultsSourceKey)),
      mergeMap(([, sourceKey]) => {
        return this.emitUpdate(new CopyCalcEngineGeneratedPumpingScheduleAction(sourceKey)).pipe(
          tap(() => this.modalService.showAlert('Schedule copied successfully.')),
        );
      }),
    ),
  );

  public copyCalcEngineGeneratedRunningStringAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.copyCalcEngineGeneratedRunningStringAction),
      concatLatestFrom(() => this.store.select(getResultsSourceKey)),
      mergeMap(([, sourceKey]) => {
        return this.emitUpdate(new CopyCalcEngineGeneratedRunningStringAction(sourceKey)).pipe(
          tap(() => this.modalService.showAlert('Running String copied successfully.')),
        );
      }),
    ),
  );

  // endregion

  public triggerUpdatePumpRangeAndPressureAfterImportedDataChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateRowSuccess, insertRowsSuccess, deleteRowsSuccess),
      filter((action) => action.affectedRows.importColumns != null || action.affectedRows.importFiles != null),
      map(() => actions.updateAnalysisDataComponentProperties({ selectedValues: {}, isUndoEnabled: false })),
    ),
  );

  public triggerUpdatePumpRangeAndPressureAfterScenarioOrRangeLoaded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(scenarioOrRangeLoadedAction),
      filter((action) => action.moduleType === ModuleType.Evaluate || action.moduleType === ModuleType.Trend_Analysis),
      map(() => actions.updateAnalysisDataComponentProperties({ selectedValues: {}, isUndoEnabled: false })),
    ),
  );

  public updateAnalysisDataComponentProperties$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.updateAnalysisDataComponentProperties),
        concatLatestFrom(() => this.store.select(getDataAnalysisComponentSelectedValues)),
        tap(([action, storeSelectedValues]) =>
          this.updateRangeAnalysisDataPropertiesColumnNames(storeSelectedValues, action.selectedValues, action.isUndoEnabled),
        ),
      ),
    { dispatch: false },
  );

  public pumpingUndoRedo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(undoRedoAction),
      concatLatestFrom(() => this.store.select(getCurrentHistoryState)),
      filter(
        ([action, historyState]) =>
          (action.undoOrRedo === 'undo' &&
            !!historyState.undo &&
            historyState.undo.type === PumpingModuleActionTypes.SwitchDetailedFluidDefinitionType) ||
          (action.undoOrRedo === 'redo' &&
            !!historyState.redo &&
            historyState.redo.type === PumpingModuleActionTypes.SwitchDetailedFluidDefinitionType),
      ),
      map(([action, historyState]) => {
        const state = historyState as IUndoableAction;

        const presentAction = (action.undoOrRedo === 'undo' ? state.undo : state.redo) as UpdateRowsWsAction<PumpingDto>;
        const row = getSingleRow(presentAction);
        return switchDetailedFluidDefinitionTypeAction({
          scenarioId: row.ScenarioId,
          rangeId: row.RangeId,
          isWellFluidDepthByVolume: row.IsWellFluidDepthByVolume,
          undoOrRedo: action.undoOrRedo,
        });
      }),
    ),
  );

  protected override onIncomingMessage(action: CrudResponseResultAction): void {
    if (action.type === PumpingModuleActionTypes.InsertPumpingScheduleRows) {
      this.store.dispatch(insertRowsSuccess(action.payload));
    } else if (action.type === PumpingModuleActionTypes.GenerateEvaluationScheduleSuccess) {
      if (action.payload.affectedRows.evaluationPumpingSchedule?.encodedRows) {
        const decodedRows = PumpingScheduleConverter.decodeToDto(
          action.payload.affectedRows.evaluationPumpingSchedule?.encodedRows,
          action.payload.scenarioId,
          action.payload.rangeId as number,
        );
        this.store.dispatch(
          insertRowsSuccess({
            scenarioId: action.payload.scenarioId,
            affectedRows: {
              evaluationPumpingSchedule: {
                rows: decodedRows,
                deletedIds: [],
                colIds: [],
              },
            },
          }),
        );
      }
    }
  }

  // update pump rate and pressure column name

  protected updateRangeAnalysisDataPropertiesColumnNames(
    componentState: IValidatedAnalysisDataComponentStateWithSelectedValues,
    actionSelectedValues: IAnalysisDataComponentSelectedValues,
    isUndoEnabled = true,
  ): void {
    const { state, selectedValues } = componentState;

    if (
      state.pumpPressureItems.length === 0 ||
      state.pumpRateItems.length === 0 ||
      state.returnRateItems.length === 0 ||
      state.gravelConcItems.length === 0
    ) {
      return;
    }

    const overrideSelectedValue = (actionSelectedValueId: number | undefined, currentSelectedValue: number | undefined): number | undefined => {
      // override selected pump rate id from dropdown
      if (actionSelectedValueId != null && actionSelectedValueId !== currentSelectedValue) {
        return actionSelectedValueId;
      } else {
        return currentSelectedValue;
      }
    };

    const selectedPumpRateId = overrideSelectedValue(actionSelectedValues.selectedPumpRateId, selectedValues.selectedPumpRateId);
    const selectedPumpPressureId = overrideSelectedValue(actionSelectedValues.selectedPumpPressureId, selectedValues.selectedPumpPressureId);
    const selectedReturnRateId = overrideSelectedValue(actionSelectedValues.selectedReturnRateId, selectedValues.selectedReturnRateId);
    const selectedGravelConcId = overrideSelectedValue(actionSelectedValues.selectedGravelConcId, selectedValues.selectedGravelConcId);

    const pumpRateColumnNameToUpdate = this.updateRangePumpColumnName(
      state.pumpRateItems,
      state.currentScenarioRangeProperties.PumpRateColumnName,
      selectedPumpRateId,
    );
    const pumpPressureColumnNameToUpdate = this.updateRangePumpColumnName(
      state.pumpPressureItems,
      state.currentScenarioRangeProperties.PumpPressureColumnName,
      selectedPumpPressureId,
    );
    const returnRateColumnNameToUpdate = this.updateRangePumpColumnName(
      state.returnRateItems,
      state.currentScenarioRangeProperties?.ReturnRateColumnName,
      selectedReturnRateId,
    );
    const gravelConcColumnNameToUpdate = this.updateRangePumpColumnName(
      state.gravelConcItems,
      state.currentScenarioRangeProperties?.GravelConcColumnName,
      selectedGravelConcId,
    );

    if (
      pumpRateColumnNameToUpdate !== undefined ||
      pumpPressureColumnNameToUpdate !== undefined ||
      returnRateColumnNameToUpdate !== undefined ||
      gravelConcColumnNameToUpdate !== undefined
    ) {
      const scenarioRangeProperties: ScenarioRangePropertiesDto = {
        ...state.currentScenarioRangeProperties,
        ...(pumpRateColumnNameToUpdate !== undefined && { PumpRateColumnName: pumpRateColumnNameToUpdate }),
        ...(pumpPressureColumnNameToUpdate !== undefined && { PumpPressureColumnName: pumpPressureColumnNameToUpdate }),
        ...(returnRateColumnNameToUpdate !== undefined && { ReturnRateColumnName: returnRateColumnNameToUpdate }),
        ...(gravelConcColumnNameToUpdate !== undefined && { GravelConcColumnName: gravelConcColumnNameToUpdate }),
      };
      this.store.dispatch(
        updateScenarioRangePropertiesAction(
          scenarioRangeProperties,
          ['PumpRateColumnName', 'PumpPressureColumnName', 'ReturnRateColumnName', 'GravelConcColumnName'],
          isUndoEnabled,
        ),
      );
    }
  }

  private updateRangePumpColumnName(items: ISelectItem<number>[], rangeColName: string, selectedId: number | undefined): string | undefined {
    const updatePumpRate = (colName: string): string | undefined => (colName !== rangeColName ? colName : undefined);

    /// no pump rate and pressure columns
    switch (items.length) {
      case 0:
        return undefined;
      case 1:
        return updatePumpRate(items[0].text);
      default: {
        return updatePumpRate(items.find((i) => i.value === selectedId)?.text ?? '');
      }
    }
  }
}
