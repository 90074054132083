import { CompletionModuleState } from './completion-module.state';
import { PipeType } from '../../dto/pipe.dto';
import { ITableState } from '../../common/common-grid.interfaces';
import { Pipe } from '../pipes/pipe';
import { Caliper } from '../well/model/caliper/caliper';

export class CompletionModuleCalculations {
  public static applyCalculations(state: CompletionModuleState, casingData: Pipe[], caliperData: Caliper[]): CompletionModuleState {
    return { ...state, LowerCompletion: this.setIsolationPackersOD(state.LowerCompletion, casingData, caliperData) };
  }

  private static setIsolationPackersOD(lowerCompletion: ITableState<Pipe>, casingData: Pipe[], caliperData: Caliper[]): ITableState<Pipe> {
    const rows = lowerCompletion.rows.map((row) => {
      if (row.rowData.PipeType === PipeType.Isolation_Packer || row.rowData.PipeType === PipeType.Sump_Packer) {
        const packer = { ...row.rowData };
        if (casingData.length === 0) {
          // Set the OD to zero
          packer.OuterDiameter = 0;
        } else {
          // Set equal to the inner diameter of the casing component
          const casingComponent = casingData.find((pipe) => pipe.TopMD <= packer.TopMD && pipe.BottomMD > packer.TopMD);
          if (!casingComponent) {
            // A component was not found so set the OD to zero
            packer.OuterDiameter = 0;
          } else if (casingComponent.PipeType === PipeType.Open_Hole) {
            // Set the diameter to zero initially then update if there is a relevant caliper section
            packer.OuterDiameter = 0;
            if (caliperData.length) {
              //Set the diameter using the caliper data
              for (let caliperIndex = 0; caliperIndex < caliperData.length - 1; caliperIndex++) {
                if (caliperData[caliperIndex].TopMD <= packer.TopMD && caliperData[caliperIndex + 1].TopMD > packer.TopMD) {
                  packer.OuterDiameter = caliperData[caliperIndex].Diameter;
                  break;
                }
              }
            }
          } else {
            // Set the diameter directly
            packer.OuterDiameter = casingComponent.InnerDiameter;
          }
        }
        return { ...row, rowData: packer };
      } else {
        return row;
      }
    });
    return { ...lowerCompletion, rows };
  }
}
