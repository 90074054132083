<div class="grid-form-container cols-2 no-padding" *ngIf="psdData$ | async as psdData">
  <app-select
    *ngIf="psdData.selectedPSD"
    [items]="psdData.items"
    [value]="psdData.selectedPSD.Id"
    [disabled]="disabled"
    [width]="270"
    (primitiveValueChanged)="changePSD($event.value)"
    dataCy="select-psd"
  >
  </app-select>
</div>
