import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BackendConnectionService } from '../../shared/backend-connection/backend-connection.service';
import * as backendConnectionActions from '../backend-connection/backend-connection.actions';
import { dbDisconnectAction } from '../backend-connection/backend-connection.actions';
import * as actions from './db-migration.actions';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import {
  DbMigrationModuleActions,
  DbMigrationModuleActionsTypes,
  DbMigrationModuleName,
  IMigrationServerResponse,
  RunMigrationAction,
} from '@dunefront/common/modules/db-migration/db-migration-module.actions';
import { BaseWsEffects } from '../base-ws.effects';
import { ModalService } from '../../common-modules/modals/modal.service';
import { RouterHelperService } from '../../shared/services/router-helper.service';

@Injectable()
export class DbMigrationEffects extends BaseWsEffects {
  private startDbMigration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.startDbMigration),
      mergeMap((action) => {
        const backendAction = new RunMigrationAction();
        return this.emit<IMigrationServerResponse>(backendAction).pipe(
          map((result) =>
            actions.progressDbMigration({
              migrationServerResponse: { ...result.payload, status: 'migrationComplete', fileHash: result.payload.fileHash },
              taskId: backendAction.taskId,
            }),
          ),
          catchError((error) => of(actions.failedDbMigration({ error }))),
        );
      }),
    ),
  );

  private failedDbMigration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.failedDbMigration, backendConnectionActions.wrongDbVersion),
      tap(async (action) => {
        await this.modalService.dismissAll();

        let message = 'There was an error during migration.';

        if (typeof action.error?.stack === 'string') {
          if (action.error?.stack.includes('Error: Unknown version')) {
            // This should never happen. It means migration started but version not found in a migration script
            message = `The project file was created in an unknown version of the application.`;
          }

          if (action.error?.stack.includes('dbVersionTooLow')) {
            message = `The project file was created in an unsupported version of the application.`;
          }

          if (action.error?.stack.includes('dbVersionTooHigh')) {
            message = `The project file was created in a newer version of the application.`;
          }
        }

        message += ' Please contact support@dunefront.com for help.';

        await this.modalService.showAlert(message);
        await this.routerHelperService.navigateToHome();
      }),
      map(() => dbDisconnectAction()),
    ),
  );

  private cancelMigration$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.cancelMigration),
        tap(() => this.routerHelperService.navigateToHome()),
      ),
    { dispatch: false },
  );

  constructor(
    actions$: Actions,
    store: Store,
    wsService: BackendConnectionService,
    modalService: ModalService,
    private routerHelperService: RouterHelperService,
  ) {
    super(actions$, wsService, DbMigrationModuleName, true, true, modalService, store);
  }

  protected override onIncomingMessage(action: DbMigrationModuleActions): void {
    switch (action.type) {
      case DbMigrationModuleActionsTypes.MigrationProgressUpdate:
        this.store.dispatch(actions.progressDbMigration({ taskId: action.taskId, migrationServerResponse: action.payload }));
    }
  }
}
