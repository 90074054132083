import { createAction, props } from '@ngrx/store';
import { EntityToCopy, IEntitiesExtras } from '@dunefront/common/modules/scenario/scenario.dto';
import { IDeleteRowsProps } from '@dunefront/common/common/common-store-crud.interfaces';
import { CrudResponse } from '@dunefront/common/modules/common.actions';
import { Scenario } from '@dunefront/common/modules/scenario/scenario';

export const changeCurrentScenarioIdAction = createAction(
  '[Scenario] ChangeCurrentScenarioId',
  props<{
    scenarioId: number;
  }>(),
);
export const createScenarioAction = createAction('[Scenario] Create', props<{ name: string }>());
export const updateScenariosAction = createAction(
  '[Scenario] Update',
  props<{
    scenarios: Scenario[];
    changedKey: keyof Scenario;
  }>(),
);
export const deleteScenarioAction = createAction('[Scenario] Delete', props<IDeleteRowsProps>());
export const copyScenarioAction = createAction(
  '[Scenario] Copy',
  props<{
    scenarioId: number;
    scenarioName: string;
  }>(),
);
export const reorderScenariosAction = createAction(
  '[Scenario] Reorder',
  props<{
    scenarios: Scenario[];
    from: number;
    to: number;
    changedKey: keyof Scenario;
  }>(),
);
export const cloneScenarioAction = createAction(
  '[Scenario] Clone',
  props<{
    sourceScenarioId: number;
    targetScenarioIds: number[];
    entitiesToCopy: EntityToCopy[];
    chartsToCopy: number[];
    currentScenarioId: number;
    fileHash: string;
  }>(),
);

export const copyPumpingBetweenRangesAction = createAction(
  '[Scenario] copyPumpingBetweenRangesAction',
  props<{
    sourceRangeId: number;
    targetRangeId: number;
    entitiesToCopy: EntityToCopy[];
    fileHash: string;
    entitiesExtras?: IEntitiesExtras;
  }>(),
);

export const copyPumpingBetweenRangesSuccess = createAction('[Scenario] copyPumpingBetweenRangesSuccess', props<CrudResponse>());

export const compareScenariosOpenConfigPanelAction = createAction(
  '[Scenario] compareScenariosOpenConfigPanelAction',
  props<{ batchAction: boolean }>(),
);
export const compareScenariosChangeConfigAction = createAction(
  '[Scenario] compareScenariosChangeConfigAction',
  props<{ batchAction: boolean; scenarioIds: number[] }>(),
);
