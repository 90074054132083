import { IValidatedColConfig } from './model/col-config';
import { IValidatedImportDataColumnDelimiterConfig } from './model/col-delimiter';
import { IValidatedModuleState } from '@dunefront/common/common/common-state.interfaces';
import {
  IColConfig,
  IImportTemplateDto,
  ImportDataColumnDelimiterConfig,
  ImportDataFilePropertiesConfig,
  ImportDataType,
  ImportFileColumnDelimiter,
} from '@dunefront/common/modules/data-storage/dto/import-template/import-template.dto';
import { getNoneTemplateTemplateId } from '@dunefront/common/common/templates/template-parser';

export interface ImportDataModuleState {
  delimiterConfig: ImportDataColumnDelimiterConfig;
  filePropertiesConfig: ImportDataFilePropertiesConfig;
  includedColumnIds: number[];
  originalIncludedColumnIds: number[];
  importDataType: ImportDataType;
  measuredDepth: number;
  defineDataIndex: number;
  colConfigs: IColConfig[];
  originalFileConfigs: IColConfig[];
  fileName: string;
  templates: IImportTemplateDto[];
  selectedTemplateId: string;
  templateError: string;
}

export const getCurrentColConfig = (state: IValidatedImportDataModuleState): IValidatedColConfig => {
  return state.colConfigs[getCurrentColIndex(state)];
};

export const getCurrentColIndex = (state: IValidatedImportDataModuleState): number => {
  return state.includedColumnIds[state.defineDataIndex];
};

export interface IValidatedImportDataModuleState extends ImportDataModuleState, IValidatedModuleState<ImportDataModuleState> {
  delimiterConfig: IValidatedImportDataColumnDelimiterConfig;
  colConfigs: IValidatedColConfig[];
  errorMessage: string;
}

export const initialDelimiterConfig: ImportDataColumnDelimiterConfig = {
  delimiter: ImportFileColumnDelimiter.Auto,
  customDelimiter: '',
};

export const initialFilePropertiesConfig: ImportDataFilePropertiesConfig = {
  initialRowsToSkip: 0,
  samplingFrequency: 1,
};

export const importDataModuleInitialState: ImportDataModuleState = {
  delimiterConfig: {
    ...initialDelimiterConfig,
  },
  filePropertiesConfig: {
    ...initialFilePropertiesConfig,
  },
  includedColumnIds: [],
  originalIncludedColumnIds: [],
  importDataType: ImportDataType.All_Data,
  measuredDepth: 0,
  defineDataIndex: 0,
  fileName: '',
  colConfigs: [],
  originalFileConfigs: [],
  templates: [],
  selectedTemplateId: getNoneTemplateTemplateId(),
  templateError: '',
};
