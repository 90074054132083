import { CrudResponse } from '@dunefront/common/modules/common.actions';
import { LoadResuspensionCalculatorActionResponse } from '@dunefront/common/modules/calculators/resuspension-calculator/resuspension-calculator.action';
import {
  ResuspensionCalculatorInitialState,
  ResuspensionCalculatorModuleState,
} from '@dunefront/common/modules/calculators/resuspension-calculator/resuspension-calculator-module.state';
import { DeleteResultsFilter, ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import { ResuspensionCalculatorFactory } from '@dunefront/common/modules/calculators/resuspension-calculator/model/resuspension-calculator.factory';
import { ActionResponse } from '@dunefront/common/response-ws.action';

export class ResuspensionCalculatorModuleReducerHelper {
  public static onLoadScenarioDataSuccessAction(
    state: ResuspensionCalculatorModuleState,
    response: ActionResponse<LoadResuspensionCalculatorActionResponse>,
  ): ResuspensionCalculatorModuleState {
    if (response.status !== 'ok' || !response.payload) {
      return ResuspensionCalculatorInitialState;
    }
    return { ...response.payload.resuspensionCalculatorDto, isLoaded: true };
  }

  public static onUpdateResuspensionCalculatorSuccess(
    state: ResuspensionCalculatorModuleState,
    response: CrudResponse,
  ): ResuspensionCalculatorModuleState {
    if (!response.affectedRows.resuspensionCalculator) {
      return state;
    }
    return {
      ...state,
      ...response.affectedRows.resuspensionCalculator,
    };
  }

  public static onRemoveDataResultsFromStore(
    state: ResuspensionCalculatorModuleState,
    deleteResultsFilter: DeleteResultsFilter,
  ): ResuspensionCalculatorModuleState {
    if (
      (deleteResultsFilter.moduleTypes != null && !deleteResultsFilter.moduleTypes.includes(ModuleType.Resuspension_Calc)) ||
      (deleteResultsFilter.scenarioIds != null && !deleteResultsFilter.scenarioIds.includes(state.ScenarioId))
    ) {
      return state;
    }
    return {
      ...state,
      ...ResuspensionCalculatorFactory.cleanResultsPartial(),
    };
  }
}
