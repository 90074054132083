import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { changeProp, ITypeWithError, ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';
import { isArray } from 'lodash';

@Component({
  selector: 'app-prompt-modal-content',
  template: `
    <div class="dialog-header" *ngIf="title">
      <h4>{{ title }}</h4>
    </div>
    <div class="dialog-content">
      <p *ngIf="message" [innerHTML]="message"></p>
      <app-form-input
        [isUiLockable]="isUiLockable"
        [isTextInput]="isTextInput"
        [label]="label"
        [autoFocus]="true"
        [triggerOnKeyPress]="true"
        [source]="inputValue"
        [min]="min"
        [key]="'value'"
        [decimalPlaces]="decimalPlaces"
        dataCy="prompt-modal-input"
        (valueChanged)="onValueChanged($event)"
      ></app-form-input>
    </div>
    <div class="dialog-footer">
      <button
        appButton
        type="button"
        [dialogRef]="ref"
        class="btn btn-light btn-sm"
        buttonType="cancel"
        data-cy="prompt-modal-reject"
        (buttonClick)="ref.close(null)"
        [isUiLockable]="false"
      >
        {{ cancelButtonText }}
      </button>
      <button
        appButton
        type="button"
        [dialogRef]="ref"
        class="btn btn-primary btn-sm"
        buttonType="primary"
        data-cy="prompt-modal-confirm"
        (buttonClick)="okClicked()"
        [isUiLockable]="false"
        [disabled]="!isOkEnabled"
      >
        {{ okButtonText }}
      </button>
    </div>
  `,
  styles: ['.btn { min-width: 50px; } .modal-body { overflow-y: auto; max-height: 300px; }'],
})
export class PromptModalContentComponent {
  public title: string;
  public message: string;
  public label = '';
  public okButtonText = 'OK';
  public cancelButtonText = 'Cancel';
  public isTextInput = true;
  public decimalPlaces = 2;
  public inputValue: IPromptSource;
  public min: number | undefined;
  public isUiLockable = true;

  public get messages(): string[] {
    return isArray(this.message) ? this.message : [this.message];
  }

  public onValueChanged(objChanged: ObjectChangeProp<IPromptSource>): void {
    const newValue = changeProp(this.inputValue, objChanged);
    this.inputValue = {
      ...newValue,
      error: { value: this.getErrorMessage(newValue.value as string) },
    };
  }

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig<IPromptConfigData>,
  ) {
    this.title = config.data?.title ?? '';
    this.message = config.data?.message ?? '';
    this.label = config.data?.label ?? '';
    this.okButtonText = config.data?.okButtonText ?? 'Ok';
    this.cancelButtonText = config.data?.cancelButtonText ?? 'Cancel';
    this.isTextInput = config.data?.isTextInput ?? true;
    this.decimalPlaces = config.data?.decimalPlaces ?? 2;
    this.isUiLockable = config.data?.isUiLockable ?? true;

    if (config.data?.min != null) {
      this.min = config.data.min;
    }

    const value = config.data?.inputValue;
    this.inputValue = { value: value == null ? (this.isTextInput ? '' : 1) : value, error: {} };
  }

  public okClicked(): void {
    this.ref.close(this.inputValue.value);
  }

  public get isOkEnabled(): boolean {
    return !this.inputValue.error?.value || this.inputValue.error?.value === '';
  }

  protected getErrorMessage(value: string): string {
    return value ? '' : 'This field is required!';
  }
}

interface IPromptSource extends ITypeWithError<{ value: string }> {
  value: string | number;
}

export interface IPromptConfigData {
  title: string;
  message: string;
  label: string;
  okButtonText?: string;
  cancelButtonText?: string;
  isTextInput?: boolean;
  decimalPlaces?: number;
  isUiLockable?: boolean;
  min?: number;
  inputValue?: string | number;
}
