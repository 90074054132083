import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { isPSDModuleValid } from '../../../+store/psd-analysis/psd-analysis.selectors';
import * as uiActions from '../../../+store/ui/ui.actions';

@Component({
  selector: 'app-psd-results',
  templateUrl: './psd-results.component.html',
  styleUrls: ['./psd-results.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PsdResultsComponent {
  public isValid$ = this.store.select(isPSDModuleValid);

  constructor(private store: Store) {
    store.dispatch(uiActions.setAppSectionAction({ section: 'results' }));
  }
}
