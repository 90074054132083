<app-grid
  *ngIf="height > 0 && rheologiesData"
  [dataSource]="rheologiesData"
  [gridConfig]="gridConfig"
  [columns]="gridConfig.columns"
  (selectedRowsChanged)="selectedRowsChanged.emit($event)"
  [showCrudControls]="false"
  [height]="height"
  [isFirstRowDeleteAllowed]="true"
  [minRowCount]="1"
  [isFirstRowInsertingAllowed]="true"
  [isInsertingRowDisabled]="fluid.RheologyType === RheologyType.Rheometer"
  [isInsertingDisabled]="true"
  [isDeletingDisabled]="fluid.RheologyType === RheologyType.Rheometer"
  dataCy="fluid-rheologies-grid"
></app-grid>
