import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-modal-help-button',
  templateUrl: './modal-help-button.component.html',
  styleUrls: ['./modal-help-button.component.scss'],
})
export class ModalHelpButtonComponent implements AfterViewInit {
  public isHelpOpen = false;

  @Input()
  public dialogHasScrollbar = false;

  @Output()
  public helpClick = new EventEmitter<{ isHelpOpen: boolean }>();

  private dialogElement: HTMLElement | undefined;
  private originalSize = 0;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
  ) {}

  public ngAfterViewInit(): void {
    this.dialogElement = this.findDialogElement();
    if (this.dialogElement) {
      this.originalSize = this.dialogElement.clientWidth;
      this.renderer.setStyle(this.dialogElement, 'max-width', this.originalSize + 'px');
    }
  }

  public onHelpClick(forceState: boolean | undefined): void {
    if (forceState == null) {
      this.isHelpOpen = !this.isHelpOpen;
    } else {
      this.isHelpOpen = forceState;
    }
    this.helpClick.emit({ isHelpOpen: this.isHelpOpen });
    this.onHelpContentChanged();
  }

  private onHelpContentChanged(): void {
    if (this.dialogElement) {
      this.dialogElement.classList.toggle('help-open', this.isHelpOpen);
      this.renderer.setStyle(this.dialogElement, 'max-width', this.originalSize + (this.isHelpOpen ? 278 : 0) + 'px');
    }
  }

  private findDialogElement(): HTMLElement | undefined {
    let element = this.el.nativeElement.parentElement;
    while (element != null) {
      const htmlElement = element as HTMLElement;
      if (htmlElement.className?.includes('p-dialog') && htmlElement.attributes.getNamedItem('role')?.value === 'dialog') {
        return element;
      }
      element = element.parentElement;
    }
    return undefined;
  }
}
