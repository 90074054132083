import { ActivatedRoute, NavigationBehaviorOptions, NavigationExtras, Params, Router, UrlTree } from '@angular/router';
import { ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import { Injectable } from '@angular/core';
import {
  RouteModuleGravels,
  RouteModuleGravelsAdvancedProperties,
  RouteModuleGravelsProjectGravels,
} from '../../pages/common/gravel/gravel-routes-names';
import {
  RouteModuleFluids,
  RouteModuleFluidsAdvancedProperties,
  RouteModuleFluidsProjectFluids,
  RouteModuleFluidsRheometerData,
} from '../../pages/common/fluids/fluids-routes-names';
import { RouteModuleData, RouteModuleDataChart, RouteModuleDataGrid } from '../../pages/gauge-data-page/gauge-data-routes-names';
import {
  RouteEvaluateAnimation,
  RouteModuleEvaluate,
  RouteModuleResults,
  RouteModuleSimulate,
  RouteModuleSimulateCH,
  RouteModuleSimulateDisp,
  RouteSimulateAnimation,
} from '../../pages/simulate-evaluate-page/simulate-evaluate-routes-names';
import { RouteModuleFile, RouteModuleHome } from '../../pages/home/home-page-routes-names';
import { RouteModulePumping, RouteModulePumpingSchedule } from '../../pages/simulate-evaluate-page/pumping/pumping-routes-names';
import {
  RouteModuleCompletion,
  RouteModuleCompletionRunningString,
} from '../../pages/simulate-evaluate-page/completion/completion-routes-names';
import { IFile } from '@dunefront/common/dto/file.dto';
import { FileManagerHelper } from '../../+store/file-manager/file-manager.helper';
import { RangeConstants } from '@dunefront/common/dto/range.dto';

export enum AppUrlPart {
  root = 1, // 'home' / 'admin' / 'file'
  file = 2,
  scenarioId = 3,
  rangeId = 4,
  module = 5,
  page = 6,
  subPage = 7,
  paramId = 8, //:fluidId, :gravelId or :fileId
  paramTemperatureId = 9, //:temperatureId
}

@Injectable({ providedIn: 'root' })
export class RouterHelperService {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {}

  private readonly scenarioChangeRedirectionMap = new Map<string, string>([
    [RouteModuleFluidsRheometerData, RouteModuleFluidsProjectFluids],
  ]);

  private static getModulePath(moduleType: ModuleType): string {
    switch (moduleType) {
      case ModuleType.Simulate:
        return 'simulate';
      case ModuleType.Simulate_CH:
        return 'simulate-ch';
      case ModuleType.Simulate_Disp:
        return 'simulate-disp';
      case ModuleType.Trend_Analysis:
        return 'trend-analysis';

      case ModuleType.Data_Analysis:
        return 'data-pro';

      case ModuleType.PSD_Analysis_Basic:
      case ModuleType.PSD_Analysis_Full:
        return 'psd-analysis';

      case ModuleType.Friction_Calc:
      case ModuleType.Injection_Test_Calc:
      case ModuleType.MASP_Calc:
      case ModuleType.Leakoff_Coefficient_Calc:
      case ModuleType.Resuspension_Calc:
      case ModuleType.Multiple_Section_Volume_Calc:
      case ModuleType.Single_Section_Volume_Calc:
      case ModuleType.Reference_Variable_Calc:
      case ModuleType.Settling_Calc:
        return 'calculators';

      case ModuleType.Evaluate:
      default:
        return 'evaluate';
    }
  }

  public navigate(commands: any[], extras?: NavigationExtras): Promise<boolean> {
    return this.router.navigate(commands, extras);
  }

  public changeRange(rangeId: number): Promise<boolean> {
    const urlParts = this.getDecodedUrlParts();
    if (urlParts[4] !== rangeId + '') {
      urlParts[4] = rangeId + '';
      return this.navigate(urlParts);
    }
    return Promise.resolve(false);
  }

  public navigateByUrl(url: string | UrlTree, extras?: NavigationBehaviorOptions): Promise<boolean> {
    return this.router.navigateByUrl(url, extras);
  }

  public navigateToHome(): Promise<boolean> {
    return this.router.navigate([`/${RouteModuleHome}`]);
  }

  public navigateToChartUrl(moduleType: ModuleType): Promise<boolean> {
    const url = this.router.routerState.snapshot.url;
    const urlParts = url.split('/', 5);
    urlParts.forEach((part, index) => (urlParts[index] = decodeURIComponent(part)));
    const newChartUrlBase = urlParts.join('/') + '/';

    let newChartUrl: string | null = null;

    switch (moduleType) {
      case ModuleType.Trend_Analysis:
        newChartUrl = 'trend-analysis/reporting/tab/report';
        break;
      case ModuleType.Simulate:
        newChartUrl = `/${RouteModuleSimulate}/${RouteModuleResults}/${RouteSimulateAnimation}`;
        break;
      case ModuleType.Simulate_CH:
        newChartUrl = `/${RouteModuleSimulateCH}/${RouteModuleResults}/${RouteSimulateAnimation}`;
        break;
      case ModuleType.Simulate_Disp:
        newChartUrl = `/${RouteModuleSimulateDisp}/${RouteModuleResults}/${RouteSimulateAnimation}`;
        break;
      case ModuleType.Evaluate:
        newChartUrl = `/${RouteModuleEvaluate}/${RouteModuleResults}/${RouteEvaluateAnimation}`;
        break;
    }

    if (newChartUrl == null) {
      throw new Error('Invalid module type');
    }

    return this.navigate([newChartUrlBase + newChartUrl]);
  }

  public navigateToAnimationChart({
    dbFile,
    scenarioId,
    rangeId,
    moduleType,
  }: {
    dbFile: IFile;
    scenarioId: number;
    rangeId: number;
    moduleType: ModuleType;
  }): Promise<boolean> {
    const urlParts = this.getDecodedUrlParts();

    let module = '';
    if (moduleType === ModuleType.Evaluate) {
      module = RouteModuleEvaluate;
    } else if (moduleType === ModuleType.Simulate) {
      module = RouteModuleSimulate;
    } else if (moduleType === ModuleType.Simulate_CH) {
      module = RouteModuleSimulateCH;
    } else if (moduleType === ModuleType.Simulate_Disp) {
      module = RouteModuleSimulateDisp;
    } else {
      throw new Error('Invalid module');
    }

    urlParts[AppUrlPart.root] = RouteModuleFile;
    urlParts[AppUrlPart.file] = FileManagerHelper.getEncodedUrlFilePart(dbFile);
    urlParts[AppUrlPart.scenarioId] = scenarioId.toString();
    urlParts[AppUrlPart.rangeId] = moduleType === ModuleType.Evaluate ? rangeId.toString() : RangeConstants.EntireRangeId.toString();
    urlParts[AppUrlPart.module] = module;
    urlParts[AppUrlPart.page] = RouteModuleResults;
    urlParts[AppUrlPart.subPage] = moduleType === ModuleType.Evaluate ? RouteEvaluateAnimation : RouteSimulateAnimation;

    return this.router.navigate(urlParts);
  }

  public getDecodedUrlParts(url?: string): string[] {
    const urlToSplit = url || this.router.routerState.snapshot.url;
    const urlParts = urlToSplit.split('/');
    urlParts.forEach((part, index) => (urlParts[index] = decodeURIComponent(part)));
    return urlParts;
  }

  public getFilenameAndScenario(): IFilenameAndScenario | null {
    const firstChildSnapshot = this.activatedRoute.firstChild?.snapshot;
    if (!firstChildSnapshot) {
      return null;
    }
    const dbFilename = firstChildSnapshot.paramMap.get('dbFilename') as string;
    const scenarioId = Number(firstChildSnapshot.paramMap.get('scenarioId'));
    const rangeId = Number(firstChildSnapshot.paramMap.get('rangeId'));
    return { dbFilename, scenarioId: +scenarioId, rangeId: +rangeId };
  }

  public isPageCurrentlyVisible(url: string, page: string, subPage?: string): boolean {
    const urlParts = this.getDecodedUrlParts(url);
    return urlParts[AppUrlPart.page] === page && (subPage == null || urlParts[AppUrlPart.subPage] === subPage);
  }

  public navigateToScenarioBasedPage(
    appModuleType: ModuleType,
    path: string[] = [],
    queryParams: Params | null | undefined,
  ): Promise<boolean> {
    const params = this.getFilenameAndScenario();
    if (!params) {
      return Promise.resolve(false);
    }
    const modulePath = RouterHelperService.getModulePath(appModuleType);

    return this.router.navigate([`/${RouteModuleFile}/${params.dbFilename}/${params.scenarioId}/${params.rangeId}`, modulePath, ...path], {
      queryParams,
    });
  }

  public navigateToScenarioAndRange(scenarioId: number, rangeId: number, chartTabId?: number | null): Promise<boolean> {
    const urlParts = this.getDecodedUrlParts();
    urlParts[AppUrlPart.scenarioId] = scenarioId + '';
    urlParts[AppUrlPart.rangeId] = rangeId + '';

    if (urlParts[AppUrlPart.subPage] != null) {
      // sometimes we need to go switch pages when changing scenarios
      urlParts[AppUrlPart.subPage] = this.scenarioChangeRedirectionMap.get(urlParts[AppUrlPart.subPage]) ?? urlParts[AppUrlPart.subPage];
    }

    // remove all parameters
    const partsWithoutParameters = urlParts.slice(0, 8);

    // if new chartTab is provided, and chart tab is currently open - redirect to chart with same name
    if (chartTabId != null && partsWithoutParameters.join('/').endsWith('reporting/tab')) {
      partsWithoutParameters.push(chartTabId.toString());
    }

    return this.router.navigate(partsWithoutParameters);
  }

  public navigateToEvaluateResultAnimation(): Promise<boolean> {
    const urlParts = this.getDecodedUrlParts();
    urlParts[AppUrlPart.page] = RouteModuleResults;
    urlParts[AppUrlPart.subPage] = RouteEvaluateAnimation;
    return this.router.navigate(urlParts);
  }

  public navigateToPumpingSchedule(): Promise<boolean> {
    const urlParts = this.getDecodedUrlParts();
    urlParts[AppUrlPart.page] = RouteModulePumping;
    urlParts[AppUrlPart.subPage] = RouteModulePumpingSchedule;
    return this.router.navigate(urlParts);
  }

  public navigateToRunningString(): Promise<boolean> {
    const urlParts = this.getDecodedUrlParts();
    urlParts[AppUrlPart.page] = RouteModuleCompletion;
    urlParts[AppUrlPart.subPage] = RouteModuleCompletionRunningString;
    return this.router.navigate(urlParts);
  }

  public navigateToFluid(fluidId: number): Promise<boolean> {
    const urlParts = this.getDecodedUrlParts();
    urlParts[AppUrlPart.page] = RouteModuleFluids;
    urlParts[AppUrlPart.subPage] = RouteModuleFluidsProjectFluids;
    urlParts[AppUrlPart.paramId] = fluidId + '';

    return this.router.navigate(urlParts);
  }

  public navigateToFluidAdvancedProperties(fluidId: number): Promise<boolean> {
    const urlParts = this.getDecodedUrlParts();
    urlParts[AppUrlPart.page] = RouteModuleFluids;
    urlParts[AppUrlPart.subPage] = RouteModuleFluidsAdvancedProperties;
    urlParts[AppUrlPart.paramId] = fluidId + '';

    return this.router.navigate(urlParts);
  }

  public navigateToGravel(gravelId: number): Promise<boolean> {
    const urlParts = this.getDecodedUrlParts();
    urlParts[AppUrlPart.page] = RouteModuleGravels;
    urlParts[AppUrlPart.subPage] = RouteModuleGravelsProjectGravels;
    urlParts[AppUrlPart.paramId] = gravelId + '';

    return this.router.navigate(urlParts);
  }

  public navigateToGravelAdvancedProperties(gravelId: number): Promise<boolean> {
    const urlParts = this.getDecodedUrlParts();
    urlParts[AppUrlPart.page] = RouteModuleGravels;
    urlParts[AppUrlPart.subPage] = RouteModuleGravelsAdvancedProperties;
    urlParts[AppUrlPart.paramId] = gravelId + '';

    return this.router.navigate(urlParts);
  }

  public navigateToFile(fileId: number): Promise<boolean> {
    const urlParts = this.getDecodedUrlParts();
    urlParts[AppUrlPart.page] = RouteModuleData;
    urlParts[AppUrlPart.subPage] = RouteModuleDataGrid;
    urlParts[AppUrlPart.paramId] = fileId + '';

    return this.router.navigate(urlParts);
  }

  public navigateToRheometer(fluidId: number, tempId: number): Promise<boolean> {
    const urlParts = this.getDecodedUrlParts();
    urlParts[AppUrlPart.page] = RouteModuleFluids;
    urlParts[AppUrlPart.subPage] = RouteModuleFluidsRheometerData;
    urlParts[AppUrlPart.paramId] = fluidId + '';
    urlParts[AppUrlPart.paramTemperatureId] = tempId + '';

    return this.router.navigate(urlParts);
  }

  public isDataChartVisible(): boolean {
    const urlParts = this.getDecodedUrlParts();
    return urlParts[AppUrlPart.page] === RouteModuleData && urlParts[AppUrlPart.subPage] === RouteModuleDataChart;
  }
}

export interface IFilenameAndScenario {
  dbFilename: string;
  scenarioId: number;
  rangeId: number;
}
