import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable } from 'rxjs';

@Injectable()
export class ElectronAuthService implements IAuthService {
  public isAuthenticated$ = new BehaviorSubject(true);
  public user$ = new BehaviorSubject(undefined);
  public logout(): Observable<void> {
    return from(Promise.resolve());
  }

  public getAccessTokenSilently(): Observable<string> {
    return from(Promise.resolve(''));
  }
}
export interface IAuthService {
  isAuthenticated$: Observable<boolean>;
  logout: () => Observable<void>;
  getAccessTokenSilently: () => void;
}
