import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { PanelButtonComponent } from '../panel.button.component';
import { setAnnotationsVisibleAction, setGradientLinesVisibleAction, setMarkersVisibleAction } from '../../../../+store/ui/ui.actions';
import { Store } from '@ngrx/store';
import { ChartMenuProps, getChartMenuProps } from '../../../../+store/menu-selectors/chart/menu-chart.selector';
import { ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import { getCurrentAppModuleType } from '../../../../+store/ui/ui.selectors';
import { ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';
import { EnumHelpers } from '@dunefront/common/utils/enum.helpers';
import { CrosshairMode, TooltipPosition } from '@dunefront/common/modules/reporting/reporting.settings';
import {
  setCrosshairModeAction,
  setMaxSeriesInTooltipAction,
  setTooltipPositionAction,
} from '../../../../+store/reporting/reporting.actions';
import { cancelChange } from '../../../../+store/store.helpers';
import { ModalService } from '../../../modals/modal.service';

@Component({
  selector: 'app-display-menu-panel',
  templateUrl: './display-menu-panel.component.html',
  styleUrls: ['./display-menu-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisplayMenuPanelComponent extends PanelButtonComponent {
  public ModuleType = ModuleType;

  public chartMenuProps: ChartMenuProps | undefined;
  public currentAppModuleType$ = this.store.select(getCurrentAppModuleType);

  public crosshairModes = EnumHelpers.EnumToISelectItemArray(
    CrosshairMode,
    false,
    undefined,
    undefined,
    new Map([
      [CrosshairMode.NONE, 'None'],
      [CrosshairMode.SINGLE, 'Single Series'],
      [CrosshairMode.MULTIPLE, 'Multiple Series'],
    ]),
  );

  public tooltipPositions = EnumHelpers.EnumToISelectItemArray(
    TooltipPosition,
    false,
    undefined,
    undefined,
    new Map([
      [TooltipPosition.DEFAULT, 'Default'],
      [TooltipPosition.TOP_LEFT, 'Top - Left'],
      [TooltipPosition.TOP_RIGHT, 'Top - Right'],
      [TooltipPosition.BOTTOM_LEFT, 'Bottom - Left'],
      [TooltipPosition.BOTTOM_RIGHT, 'Bottom - Right'],
    ]),
  );

  constructor(
    protected override store: Store,
    private modalService: ModalService,
    private cdRef: ChangeDetectorRef,
  ) {
    super(store);

    this.subscription.add(
      this.store.select(getChartMenuProps).subscribe((chartMenuProps) => {
        this.chartMenuProps = chartMenuProps;
      }),
    );
  }

  public setAnnotationsVisible(visible: boolean): void {
    this.store.dispatch(setAnnotationsVisibleAction({ visible }));
  }

  public setMarkersVisible(visible: boolean): void {
    this.store.dispatch(setMarkersVisibleAction({ visible }));
  }

  public setGradientLinesVisible(visible: boolean): void {
    this.store.dispatch(setGradientLinesVisibleAction({ visible }));
  }

  public async onCrosshairSectionChanged(changeProp: ObjectChangeProp<ChartMenuProps>): Promise<void> {
    if (changeProp.key === 'crossHairMode' && typeof changeProp.value === 'number') {
      this.store.dispatch(setCrosshairModeAction({ mode: changeProp.value as CrosshairMode }));
    }

    if (changeProp.key === 'tooltipPosition' && typeof changeProp.value === 'number') {
      this.store.dispatch(setTooltipPositionAction({ position: changeProp.value as TooltipPosition }));
    }

    if (changeProp.key === 'maxSeriesInTooltip' && typeof changeProp.value === 'number') {
      if (changeProp.value <= 0 && this.chartMenuProps) {
        await this.modalService.showAlert('Max Series must be greater than zero');

        const cancelGenerator = cancelChange(this.chartMenuProps, changeProp, this.cdRef);
        this.chartMenuProps = cancelGenerator.next().value;
        this.chartMenuProps = cancelGenerator.next().value;
        this.cdRef.markForCheck();

        return;
      }

      this.store.dispatch(setMaxSeriesInTooltipAction({ maxSeriesInTooltip: changeProp.value }));
    }
  }

  public get isMaxSeriesDisabled(): boolean {
    return this.chartMenuProps?.crossHairMode === CrosshairMode.SINGLE || this.chartMenuProps?.crossHairMode === CrosshairMode.NONE;
  }
}
