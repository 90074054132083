import { ImportFileDto } from './import-file.dto';
import { ImportColumnDto } from './import-column.dto';
import { DictionaryWithArray, IDictionaryWithArray } from '../../../common/state.helpers';
import { Dictionary } from 'lodash';
import { VerticalShiftDto } from '../../../dto/trend-analysis/vertical-shift.dto';

export interface ImportFileWithColumns extends ImportFileDto {
  ImportColumnProperties: ImportColumnDto[];
}

export interface ImportColumWithShifts extends ImportColumnDto {
  VerticalShifts: VerticalShiftDto[];
}

export interface ImportFileForAdvancedValidation extends ImportFileWithColumns {
  ImportColumnProperties: ImportColumWithShifts[];
}

export class ImportFileWithColumnsFactory {
  public static create(
    files: IDictionaryWithArray<ImportFileDto>,
    columns: IDictionaryWithArray<ImportColumnDto[]>,
  ): ImportFileWithColumns[] {
    return this.createFromDict(DictionaryWithArray.getArray(files), DictionaryWithArray.toDictionary(columns));
  }

  public static createFromDict(files: ImportFileDto[], columns: Dictionary<ImportColumnDto[]>): ImportFileWithColumns[] {
    return files.map((file) => {
      const ImportColumnProperties = columns[file.Id] ?? [];
      const fileWithColumns: ImportFileWithColumns = { ...file, ImportColumnProperties };
      return fileWithColumns;
    });
  }

  public static createImportFilesForAdvancedValidation(
    files: IDictionaryWithArray<ImportFileDto>,
    columns: IDictionaryWithArray<ImportColumnDto[]>,
    verticalShifts: VerticalShiftDto[],
  ): ImportFileForAdvancedValidation[] {
    return this.create(files, columns).map((fileWithCols) => this.createImportFileForAdvancedValidation(fileWithCols, verticalShifts));
  }

  public static createImportFileForAdvancedValidation(
    importFileWithColumns: ImportFileWithColumns,
    verticalShifts: VerticalShiftDto[],
  ): ImportFileForAdvancedValidation {
    const columns = importFileWithColumns.ImportColumnProperties.map(
      (col) =>
        ({
          ...col,
          VerticalShifts: verticalShifts.filter((vs) => vs.ColumnId === col.Id),
        }) as ImportColumWithShifts,
    );
    return { ...importFileWithColumns, ImportColumnProperties: columns };
  }
}
