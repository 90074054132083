export interface PumpedFluidAndGravelDto {
  Id: number;
  ScenarioId: number;
  RangeId: number;
  SortOrder: number;
  FluidId: number | null;
  GravelId: number | null;
  Volume: number;
}

export const createEmptyPumpedFluidAndGravelDto = (scenarioId: number, rangeId: number): PumpedFluidAndGravelDto => ({
  Id: -1,
  ScenarioId: scenarioId,
  RangeId: rangeId,
  FluidId: null,
  GravelId: null,
  SortOrder: -1,
  Volume: 0,
});
