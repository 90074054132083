import { NoteDto } from '@dunefront/common/dto/note.dto';
import { createReducer, on } from '@ngrx/store';
import { insertRowsSuccess, loadScenarioDataSuccessAction, updateRowSuccess } from '../app.actions';

export const NoteFeatureName = 'note';

export interface NoteModuleState {
  Notes: NoteDto[];
}

export const noteInitialState: NoteModuleState = {
  Notes: [],
};

export const noteModuleReducer = createReducer<NoteModuleState>(
  noteInitialState,
  on(
    loadScenarioDataSuccessAction,
    (state, action): NoteModuleState => ({ ...state, Notes: action.loadScenarioResponse.noteModule.payload?.notes ?? [] }),
  ),
  on(insertRowsSuccess, (state, action): NoteModuleState => {
    const rows = action.affectedRows.note?.rows ?? [];
    const deletedIds = action.affectedRows.note?.deletedIds;

    if (deletedIds != null && deletedIds.length) {
      return { ...state, Notes: state.Notes.filter((note) => !deletedIds.includes(note.Id)) };
    }

    return { ...state, Notes: [...state.Notes, ...(rows ?? [])] };
  }),
  on(updateRowSuccess, (state, action): NoteModuleState => {
    const updatedNote = action.affectedRows?.note?.rows[0];
    if (!updatedNote) {
      return state;
    }

    const newNotes = state.Notes.map((note) => (note.Id === updatedNote.Id ? updatedNote : note));

    return { ...state, Notes: newNotes };
  }),
);
