/* eslint no-constant-condition: 0 */ // --> OFF

import { WellFluid } from './well-fluid';
import { ITableRow } from '../../../../common/common-grid.interfaces';
import { DictionaryWithArray, IDictionaryWithArray } from '../../../../common/state.helpers';
import { Gravel } from '../../../gravel/model/gravel';
import { GravelCalculations } from '../../../gravel/dto/gravel.calculations';
import { Validation } from '../../../../common/validation';

export class WellFluidCalculations {
  public static UpdateCumulativeVolume(wellFluids: ITableRow<WellFluid>[]): ITableRow<WellFluid>[] {
    let firstRowIndex = 0;
    const newWellFluids: ITableRow<WellFluid>[] = [...wellFluids];
    for (let currentRowIndex = 0; currentRowIndex < wellFluids.length; currentRowIndex++) {
      const currentWellFluid = newWellFluids[currentRowIndex];
      const firstWellFluid = newWellFluids[firstRowIndex];

      if (currentRowIndex === 0 || currentWellFluid.rowData.FlowPathDescription !== firstWellFluid.rowData.FlowPathDescription) {
        // This is the start of a flow path
        firstRowIndex = currentRowIndex;
        newWellFluids[currentRowIndex] = {
          ...currentWellFluid,
          rowData: { ...currentWellFluid.rowData, CumulativeVolume: currentWellFluid.rowData.Volume },
        };
      } else {
        const previousWellFluid = newWellFluids[currentRowIndex - 1];

        newWellFluids[currentRowIndex] = {
          ...currentWellFluid,
          rowData: {
            ...currentWellFluid.rowData,
            CumulativeVolume: previousWellFluid.rowData.CumulativeVolume + currentWellFluid.rowData.Volume,
          },
        };
      }
    }

    return newWellFluids;
  }

  //Method to calculate the gravel mass in the stage
  public static GravelMass(wellFluid: WellFluid, gravels: IDictionaryWithArray<Gravel>): number {
    //Check if there is any gravel
    const gravel = DictionaryWithArray.get(gravels, wellFluid.GravelId);
    if (!gravel || wellFluid.GravelConcentration <= 0 || gravel.AbsoluteDensity <= 0) {
      //There is no gravel so return zero
      return 0;
    }

    //Calculate the yield
    const yieldData = GravelCalculations.Yield(gravel, wellFluid.GravelConcentration);

    //Calculate the clean fluid volume
    const cleanVolume = wellFluid.Volume / yieldData;

    //Calculate the gravel mass
    const gravelMass = cleanVolume * wellFluid.GravelConcentration;

    //Perform a consistency check on the result
    return Validation.UICheckNumberValidity(gravelMass);
  }
}
