import { DeleteRowsWsAction, InsertRowsWsAction, ReloadRowsWsAction, UpdateRowsWsAction, WsAction } from '../../ws.action';
import { PumpingDto } from './dto/pumping.dto';
import { WellFluidDto } from './dto/well-fluid.dto';
import { PumpingScheduleDto } from './dto/pumping-schedule.dto';
import { PumpedFluidAndGravelDto } from './dto/pumped-fluid-and-gravel.dto';
import { WellFluidConversionJobInputDataDto } from '../reference-variables/dto/well-fluid-conversion-job-input-data.dto';
import { SimulateScheduleGeneratorJobInputData } from './dto/simulate-schedule-generator-job-input-data.dto';
import { WsActionPropsFactory } from '../../common/ws-action/ws-action-props.factory';
import { PumpingSchedule } from './model/pumping-schedule/pumping-schedule';
import { PumpingScheduleFactory } from './model/pumping-schedule/pumping-schedule.factory';
import { WellFluid } from './model/well-fluid/well-fluid';
import { WellFluidFactory } from './model/well-fluid/well-fluid.factory';
import { IUpdateTableRowsProps } from '../../common/common-store-crud.interfaces';
import { DeveloperSettingsDto } from '../settings/dto/settingsDto';
import { ResultsSourceKey } from '../reporting/reporting-module.actions';

export const PumpingModuleName = 'PumpingModule';

export enum PumpingModuleActionTypes {
  CreatePumping = '[PumpingModule] Create',
  UpdatePumping = '[PumpingModule] Update',
  ReloadPumping = '[PumpingModule] Reload',

  UpdateWellFluidsRow = '[PumpingModule] [WellFluids] UpdateRow',
  DeleteWellFluidsRows = '[PumpingModule] [WellFluids] DeleteRows',
  InsertWellFluidsRows = '[PumpingModule] [WellFluids] InsertRows',
  ReloadWellFluidsRows = '[PumpingModule] [WellFluids] ReloadRows',
  SwitchDetailedFluidDefinitionType = '[PumpingModule] [WellFluids] SwitchDetailedFluidDefinitionType',

  UpdatePumpingScheduleRows = '[PumpingModule] [PumpingSchedule] UpdateRows',
  DeletePumpingScheduleRows = '[PumpingModule] [PumpingSchedule] DeleteRows',
  InsertPumpingScheduleRows = '[PumpingModule] [PumpingSchedule] InsertRows',
  ReloadPumpingScheduleRows = '[PumpingModule] [PumpingSchedule] ReloadRows',

  UpdatePumpedFluidAndGravelRows = '[PumpingModule] [PumpedFluidAndGravel] UpdateRows',
  DeletePumpedFluidAndGravelRows = '[PumpingModule] [PumpedFluidAndGravel] DeleteRows',
  InsertPumpedFluidAndGravelRows = '[PumpingModule] [PumpedFluidAndGravel] InsertRows',

  UpdateSimulateSchedule = '[PumpingModule] [GeneratePumpingSchedule] UpdateRows',

  GenerateEvaluationSchedule = '[PumpingModule] [PumpingSchedule] GenerateEvaluationSchedule',
  GenerateEvaluationScheduleSuccess = '[PumpingModule] [PumpingSchedule] GenerateEvaluationScheduleSuccess',

  CopyEvaluationScheduleResultsToSimulate = '[PumpingModule] [PumpingSchedule], CopyEvaluationScheduleResultsToSimulate_UpdateRows',
  CopyCalcEngineGeneratedPumpingSchedule = '[PumpingModule] [PumpingSchedule], CopyCalcEngineGeneratedPumpingSchedule_UpdateRows',
  CopyCalcEngineGeneratedRunningString = '[CompletionModule] [RunningString], CopyCalcEngineGeneratedRunningString_UpdateRows',
}

// region PUMPING

export interface LoadPumpingActionResponse {
  pumpingDtos: PumpingDto[];
  wellFluidDtos: WellFluidDto[];
  pumpingScheduleDtos: PumpingScheduleDto[];
  pumpedFluidAndGravelDtos: PumpedFluidAndGravelDto[];
}

export class UpdatePumpingAction extends UpdateRowsWsAction<PumpingDto> {
  public override readonly type = PumpingModuleActionTypes.UpdatePumping;
}

export class ReloadPumpingAction extends ReloadRowsWsAction {
  public override readonly type = PumpingModuleActionTypes.ReloadPumping;
}

// endregion

// region WellFluids

export class UpdateWellFluidsRowAction extends UpdateRowsWsAction<WellFluidDto> {
  public override readonly type = PumpingModuleActionTypes.UpdateWellFluidsRow;

  constructor(action: IUpdateTableRowsProps<WellFluid>) {
    super(WsActionPropsFactory.updateAction(action, WellFluidFactory.toDto));
  }
}

export class DeleteWellFluidsRowsAction extends DeleteRowsWsAction {
  public override readonly type = PumpingModuleActionTypes.DeleteWellFluidsRows;
}

export class InsertWellFluidsRowAction extends InsertRowsWsAction<WellFluidDto> {
  public override readonly type = PumpingModuleActionTypes.InsertWellFluidsRows;
}

export class ReloadWellFluidsAction extends ReloadRowsWsAction {
  public override readonly type = PumpingModuleActionTypes.ReloadWellFluidsRows;
}

export class SwitchDetailedFluidDefinitionTypeAction extends WsAction {
  public override readonly type = PumpingModuleActionTypes.SwitchDetailedFluidDefinitionType;

  constructor(
    public isWellFluidDepthByVolume: boolean,
    public scenarioId: number,
    public rangeId: number,
    public jobData: WellFluidConversionJobInputDataDto,
  ) {
    super();
  }
}

export interface CreateWellFluidsRowResponse {
  wellFluidDtos: WellFluidDto[];
  scenarioId: number;
}

// endregion

// region PumpingSchedule

export class UpdatePumpedFluidAndGravelRowsAction extends UpdateRowsWsAction<PumpedFluidAndGravelDto> {
  public override readonly type = PumpingModuleActionTypes.UpdatePumpedFluidAndGravelRows;

  constructor(action: IUpdateTableRowsProps<PumpedFluidAndGravelDto>) {
    super(WsActionPropsFactory.updateAction(action, (row) => row));
  }
}

export class DeletePumpedFluidAndGravelRowsAction extends DeleteRowsWsAction {
  public override readonly type = PumpingModuleActionTypes.DeletePumpedFluidAndGravelRows;
}

export class InsertPumpedFluidAndGravelRowAction extends InsertRowsWsAction<PumpedFluidAndGravelDto> {
  public override readonly type = PumpingModuleActionTypes.InsertPumpedFluidAndGravelRows;
}

export class CopyCalcEngineGeneratedPumpingScheduleAction extends WsAction {
  public override readonly type = PumpingModuleActionTypes.CopyCalcEngineGeneratedPumpingSchedule;

  constructor(public src: ResultsSourceKey) {
    super();
  }
}

export class CopyCalcEngineGeneratedRunningStringAction extends WsAction {
  public override readonly type = PumpingModuleActionTypes.CopyCalcEngineGeneratedRunningString;

  constructor(public src: ResultsSourceKey) {
    super();
  }
}

// endregion

// region PumpedFluid

export class UpdatePumpingScheduleRowsAction extends UpdateRowsWsAction<PumpingScheduleDto> {
  public override readonly type = PumpingModuleActionTypes.UpdatePumpingScheduleRows;

  constructor(action: IUpdateTableRowsProps<PumpingSchedule>, defaultFluidId?: number) {
    super(WsActionPropsFactory.updateAction(action, (row) => PumpingScheduleFactory.toDto(row, defaultFluidId)));
  }
}

export class DeletePumpingScheduleRowsAction extends DeleteRowsWsAction {
  public override readonly type = PumpingModuleActionTypes.DeletePumpingScheduleRows;
}

export class InsertPumpingScheduleRowAction extends InsertRowsWsAction<PumpingScheduleDto> {
  public override readonly type = PumpingModuleActionTypes.InsertPumpingScheduleRows;
}

export class ReloadPumpingScheduleAction extends ReloadRowsWsAction {
  public override readonly type = PumpingModuleActionTypes.ReloadPumpingScheduleRows;
}

// endregion

export const SimulateScheduleGenerator_CouldNotGenerateSchedule = 'SimulateScheduleGenerator_CouldNotGenerateSchedule';

export class GenerateSimulateScheduleAction extends WsAction {
  public override readonly type = PumpingModuleActionTypes.UpdateSimulateSchedule;

  constructor(
    public scenarioId: number,
    public jobId: string,
    public override payload: SimulateScheduleGeneratorJobInputData,
  ) {
    super();
    this.shouldDeleteResults = true;
  }
}

export class GenerateEvaluationScheduleAction extends WsAction {
  public override readonly type = PumpingModuleActionTypes.GenerateEvaluationSchedule;

  constructor(
    public scenarioId: number,
    public rangeId: number,
    public developerSettings: DeveloperSettingsDto,
    public jobId: string,
  ) {
    super();
  }
}

// endregion

export type PumpingModuleActions =
  | UpdatePumpingAction
  | ReloadPumpingAction
  | UpdateWellFluidsRowAction
  | DeleteWellFluidsRowsAction
  | InsertWellFluidsRowAction
  | ReloadWellFluidsAction
  | SwitchDetailedFluidDefinitionTypeAction
  | UpdatePumpingScheduleRowsAction
  | DeletePumpingScheduleRowsAction
  | InsertPumpingScheduleRowAction
  | ReloadPumpingScheduleAction
  | GenerateSimulateScheduleAction
  | UpdatePumpedFluidAndGravelRowsAction
  | DeletePumpedFluidAndGravelRowsAction
  | InsertPumpedFluidAndGravelRowAction
  | GenerateEvaluationScheduleAction
  | CopyCalcEngineGeneratedPumpingScheduleAction
  | CopyCalcEngineGeneratedRunningStringAction;
