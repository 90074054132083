import { IWellValidationDependencies, WellModuleState } from '../../well-module.state';
import { getRowsForCalculations, ITableRow, ITableState } from '../../../../common/common-grid.interfaces';
import { Caliper } from './caliper';
import { LengthConverter } from '../../../../unit-converters/converters/length/length.converter';
import { ConvertUnitPipe } from '../../../units/convert-unit.pipe/convert-unit.pipe';
import { PipeType } from '../../../../dto/pipe.dto';
import { noErrors } from '../../../../common/state.helpers';
import { LengthUnit, UnitSystem } from '../../../../dto/unit-system.dto';
import { Pipe } from '../../../pipes/pipe';
import { ArrayHelpers } from '../../../../common/array-helpers';
import { IError } from '../../../../common/common-state.interfaces';

export class CaliperValidation {
  public static IsCaliperScreenEnabled(well: WellModuleState): boolean {
    return well.CasingData.rows.some((row) => row.rowData.PipeType === PipeType.Open_Hole);
  }

  public static ValidateCaliperGrid(well: WellModuleState, deps: IWellValidationDependencies): ITableState<Caliper> {
    let isValid = true;
    const newCaliperData = { ...well.CaliperData };
    const caliperRows = getRowsForCalculations(well.CaliperData.rows);
    const casingRows = getRowsForCalculations(well.CasingData.rows);
    const hasOpenHole = casingRows.some((cas) => cas.PipeType === PipeType.Open_Hole);
    const firstOpenHole = casingRows.find((casingRow) => casingRow.PipeType === PipeType.Open_Hole);
    const lastOpenHole = ArrayHelpers.findLast(casingRows, (casingRow) => casingRow.PipeType === PipeType.Open_Hole);

    const updatedRows = newCaliperData.rows.map((row, rowIndex) => {
      if (row.rowType === 'insert-row') {
        return row;
      }
      const newRow = hasOpenHole
        ? this.ValidateCaliperGridRow(row, caliperRows, rowIndex, deps, firstOpenHole, lastOpenHole)
        : { ...row, isValid: true, error: {}, warning: {}, isWarning: false };
      isValid = isValid && newRow.isValid;
      return newRow;
    });

    return { ...newCaliperData, rows: updatedRows, isValid, isWarning: false };
  }

  private static ValidateCaliperGridRow(
    row: ITableRow<Caliper>,
    caliperRows: Caliper[],
    rowIndex: number,
    deps: IWellValidationDependencies,
    firstOpenHole: Pipe | undefined,
    lastOpenHole: Pipe | undefined,
  ): ITableRow<Caliper> {
    const errors: IError<Caliper> = {
      TopMD: this.validateCaliperMD(caliperRows, rowIndex, deps.developerSettings.GlobalTolerance, firstOpenHole, lastOpenHole),
      Diameter: this.validateCaliperDiameter(caliperRows, rowIndex),
    };

    return { ...row, error: errors, isValid: noErrors(errors), isWarning: false, warning: {} };
  }

  public static ValidateCaliperMultiplier(caliperMultiplier: number): string {
    if (caliperMultiplier <= 0) {
      return 'Caliper multiplier must be greater than zero';
    }
    return '';
  }

  public static validateCaliperGridLength(well: WellModuleState, rows: number[]): string {
    if (getRowsForCalculations(well.CaliperData.rows).length - rows.length < 2) {
      return 'At least 2 rows must remain in the grid';
    }
    return '';
  }

  public static validateCaliperToleranceInput(caliperTolerance: number): string {
    if (caliperTolerance <= 0) {
      return 'Caliper tolerance must be greater than zero';
    }
    if (LengthConverter.fromSi(caliperTolerance, LengthUnit.Inch) > 1) {
      const encodeMaxDiameter = ConvertUnitPipe.encode(UnitSystem.Diameter, LengthConverter.toSi(1, LengthUnit.Inch));
      return `Caliper tolerance must be less than or equal to ${encodeMaxDiameter}`;
    }
    return '';
  }

  private static validateCaliperMD(
    caliperRows: Caliper[],
    rowIndex: number,
    globalTolerance: number,
    firstOpenHole: Pipe | undefined,
    lastOpenHole: Pipe | undefined,
  ): string {
    const topMD = caliperRows[rowIndex].TopMD;

    if (topMD <= 0) {
      return 'MD must be greater than zero';
    } else if (rowIndex === 0) {
      if (firstOpenHole != null && topMD > firstOpenHole.TopMD) {
        return 'First row MD must be less than or equal to the top MD of the open hole section';
      }
    } else {
      const prevMD = caliperRows[rowIndex - 1].TopMD;
      if (topMD <= prevMD) {
        return 'MD must be greater than the MD in the previous row';
      } else if (rowIndex === caliperRows.length - 1) {
        if (lastOpenHole != null && topMD < lastOpenHole.BottomMD) {
          return 'Last row MD must be greater than or equal to the bottom MD of the open hole section';
        }
      }
    }

    return '';
  }

  private static validateCaliperDiameter(caliperRows: Caliper[], rowIndex: number): string {
    if (caliperRows[rowIndex]?.Diameter <= 0) {
      return 'Diameter must be greater than zero';
    }
    return '';
  }
}
