import { isScreenPipe, ScreenPipe, ScreenPipeFactory } from './screen-pipe';
import { Pipe, ShuntedPipe, ShuntedPipeTypes } from '../../pipe';
import { LowerCompletionPipeDto } from '../../../../dto/lower-completion-pipe.dto';
import { PipeType } from '../../../../dto/pipe.dto';
import { ITableRow } from '../../../../common/common-grid.interfaces';
import { ShuntTube } from '../../../shunt-tube/shunt-tube';

export interface ShuntedScreenPipe extends ScreenPipe, ShuntedPipe {
  PipeType: PipeType.Shunted_Screen;
}

export class ShuntedScreenPipeFactory {
  public static createFromDto(dto: LowerCompletionPipeDto): ShuntedScreenPipe {
    const screenPipe = ScreenPipeFactory.createDto(dto);
    const shuntedScreenPipe: ShuntedScreenPipe = {
      ...screenPipe,
      PipeType: PipeType.Shunted_Screen,
    };
    return shuntedScreenPipe;
  }
}

export function isShuntedScreenPipe(pipe: Pipe): pipe is ShuntedScreenPipe {
  return isScreenPipe(pipe) && isShuntedPipe(pipe);
}

export function isShuntedPipe(pipe: Pipe): pipe is ShuntedPipe {
  return ShuntedPipeTypes.includes(pipe.PipeType);
}

export function isShuntedPipeRow(row: ITableRow<Pipe>): row is ITableRow<ShuntedPipe> {
  return isShuntedPipe(row.rowData);
}

// Method to determine if the pipe has shunt tube nozzles
export function hasShuntNozzles(shuntTube: ShuntTube): boolean {
  return shuntTube.NozzleProperties.IsNozzleLeakoff;
}
