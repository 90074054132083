import { NgModule } from '@angular/core';
import { InjectionTestComponent } from './injection-test.component';
import { InjectionTestCalculatorComponent } from './injection-test/injection-test-calculator.component';
import { PagePanelModule } from '../../../common-modules/page-panel/page-panel.component';
import { CommonModule } from '@angular/common';
import { TabbedPageModule } from '../../../common-modules/tabbed-page/tabbed-page.component';
import { UnitsModule } from '../../../common-modules/units/units.module';
import { FluidSelectorModule } from '../../common/fluid-selector/fluid-selector.module';
import { GravelSelectorModule } from '../../common/gravel-selector/gravel-selector.module';
import { FormInputModule } from '../../../shared/components/form-components/form-input/form-input.component';
import { RadioGroupModule } from '../../../shared/components/form-components/radio-group/radio-group.component';
import { UnwrapDirectiveModule } from '../../../shared/directives/unwrap.directive';
import { ChartModule } from '../../../common-modules/chart/chart.module';
import { CheckBoxModule } from '../../../shared/components/form-components/check-box/check-box.component';
import { ModalService } from '../../../common-modules/modals/modal.service';
import { GridModule } from '../../../shared/components/grid/grid.module';
import { InjectionTestChartComponent } from './injection-test/injection-test-chart/injection-test-chart.component';
import { InjectionTestInputDataGridComponent } from './injection-test/injection-test-input-data/injection-test-input-data-grid.component';
import { SelectModule } from '../../../shared/components/form-components/select/select.module';
import { ButtonModule } from '../../../shared/components/button/button.module';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [InjectionTestComponent, InjectionTestCalculatorComponent, InjectionTestInputDataGridComponent, InjectionTestChartComponent],
  imports: [
    PagePanelModule,
    CommonModule,
    TabbedPageModule,
    UnitsModule,
    SelectModule,
    FluidSelectorModule,
    GravelSelectorModule,
    FormInputModule,
    RadioGroupModule,
    UnwrapDirectiveModule,
    ChartModule,
    CheckBoxModule,
    GridModule,
    ButtonModule,
    RouterModule,
  ],
  providers: [ModalService],
})
export class InjectionTestModule {}
