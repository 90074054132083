<ng-container *ngIf="source">
  <ng-container *ngIf="fontSizeKey">
    <label
      [class.highlight]="isValueDiffThanDefault(fontSizeKey)">{{ getLabelWithPrefix('Font Size') }}</label>
    <app-select
      [id]="idPrefix + '-font-size'"
      [dataCy]="idPrefix + '-font-size'"
      [source]="source"
      [sourceDefaults]="sourceDefaults || undefined"
      [key]="fontSizeKey"
      [items]="fontSizeSelect"
      [isUiLockable]="false"
      [disableSorting]="true"
      [width]="180"
      [disabled]="disabled"
      [highlight]="isValueDiffThanDefault(fontSizeKey)"
      (valueChanged)="valueChanged.emit($event)"
    ></app-select>
  </ng-container>

  <ng-container *ngIf="isBoldKey && isItalicKey">
    <label>{{ getLabelWithPrefix('Font Style') }}</label>
    <div style="display: flex; flex-flow: row">
      <app-check-box
        [id]="idPrefix + '-is-bold'"
        [dataCy]="idPrefix + '-is-bold'"
        [source]="source"
        [sourceDefaults]="sourceDefaults || undefined"
        [key]="isBoldKey"
        [isUiLockable]="false"
        [highlight]="isValueDiffThanDefault(isBoldKey)"
        [disabled]="disabled"
        label="Bold"
        (valueChanged)="valueChanged.emit($event)"
      >
      </app-check-box>
      <app-check-box
        [id]="idPrefix + '-is-italic'"
        [dataCy]="idPrefix + '-is-italic'"
        [source]="source"
        [sourceDefaults]="sourceDefaults || undefined"
        [key]="isItalicKey"
        [isUiLockable]="false"
        [highlight]="isValueDiffThanDefault(isItalicKey)"
        [disabled]="disabled"
        label="Italic"
        (valueChanged)="valueChanged.emit($event)"
        style="padding-left: 20px"
      >
      </app-check-box>
    </div>
  </ng-container>

  <ng-container *ngIf="fontColorKey">
    <label [class.highlight]="isValueDiffThanDefault(fontColorKey)">{{ getLabelWithPrefix('Font Color') }}</label>
    <app-select
      [id]="idPrefix + '-font-color'"
      [dataCy]="idPrefix + '-font-color'"
      [source]="source"
      [sourceDefaults]="sourceDefaults || undefined"
      [key]="fontColorKey"
      [items]="Colors"
      [colorPicker]="true"
      [isUiLockable]="false"
      [width]="180"
      [disabled]="disabled"
      [highlight]="isValueDiffThanDefault(fontColorKey)"
      (valueChanged)="valueChanged.emit($event)"
    ></app-select>
  </ng-container>
</ng-container>
