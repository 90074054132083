import { IReportTableColumnConfig } from '../common-report-data-helpers';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { ColumnType } from '../../../../../components/grid/grid.interfaces';
import { ScreenPipe } from '@dunefront/common/modules/pipes/lower-completion-pipes/pipes/screen-pipe';
import { ReportIcdScreen } from '../../doc/sections/completion-report-generator.helper';

export const lowerCompletionScreenProperties: IReportTableColumnConfig<ScreenPipe>[] = [
  { colId: 'Code', headerText: 'Code', unitSystem: UnitSystem.None, type: ColumnType.number },
  { colId: 'Name', headerText: 'Name', unitSystem: UnitSystem.None, type: ColumnType.text },
  {
    colId: 'BasepipeOuterDiameter',
    headerText: 'Base Pipe OD',
    unitSystem: UnitSystem.Diameter,
    type: ColumnType.number,
    decimalPlaces: 3,
  },
  { colId: 'FilterInnerDiameter', headerText: 'Filter ID', unitSystem: UnitSystem.Diameter, type: ColumnType.number, decimalPlaces: 3 },
  { colId: 'JointLength', headerText: 'Joint Length', unitSystem: UnitSystem.Long_Length, type: ColumnType.number },
  { colId: 'HandlingLength', headerText: 'Handling Length', unitSystem: UnitSystem.Long_Length, type: ColumnType.number },
];

export const icdScreenProperties: IReportTableColumnConfig<ReportIcdScreen>[] = [
  { colId: 'Code', headerText: 'Code', unitSystem: UnitSystem.None, type: ColumnType.number },
  { colId: 'Name', headerText: 'Name', unitSystem: UnitSystem.None, type: ColumnType.text },
  { colId: 'Type', headerText: 'Type', unitSystem: UnitSystem.None, type: ColumnType.text },
  { colId: 'NoOfPorts', headerText: 'No. of Ports', unitSystem: UnitSystem.None, type: ColumnType.number },
  { colId: 'PortDiameter', headerText: 'Port Diameter', unitSystem: UnitSystem.Diameter, type: ColumnType.number },
  { colId: 'PortLength', headerText: 'Port Length', unitSystem: UnitSystem.Short_Length, type: ColumnType.number },
];
