import { ChangeDetectionStrategy, Component } from '@angular/core';
import { OptionsComponent } from '../../options-component';

@Component({
  selector: 'app-performance-options',
  templateUrl: './performance-options.component.html',
  styleUrls: ['./performance-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PerformanceOptionsComponent extends OptionsComponent {}
