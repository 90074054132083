import { GravelDto } from '../../gravel/dto/gravel.dto';
import { FluidWithDependenciesDto } from '../../fluid/dto/fluid.dto';

export interface MASPCalculatorInput {
  FluidId: number | null;
  GravelId: number | null;
  Concentration: number;
  TVD: number;
  MaxPressureAtTVD: number;
  MASPCalculationType: MASPCalculationType;
  CollapseRating: number;
  InternalPressure: number;
  SafetyFactor: number;
}

export interface MASPCalculatorDto extends MASPCalculatorInput {
  ScenarioId: number;
  MaxAllowableSurfacePressure: number;
}

export enum MASPCalculationType {
  Absolute_Pressure = 0,
  Differential_Pressure = 1,
}

export interface MaspCalculatorJobInputData {
  Input: MASPCalculatorInput;
  Gravels: GravelDto[];
  Fluids: FluidWithDependenciesDto[];
}

export interface MaspCalculatorJobResult {
  MASP: number;
}
