import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FlowPathDescription } from '@dunefront/common/modules/pumping/dto/well-fluid.dto';
import { Store } from '@ngrx/store';
import { DbDependentComponent } from '../../../../../../common-modules/db-connection/db-dependent.component';
import { getRowsForCalculations, ITableRow } from '@dunefront/common/common/common-grid.interfaces';
import { PumpingStateByRange } from '@dunefront/common/modules/pumping/pumping-module.state';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { WellFluid } from '@dunefront/common/modules/pumping/model/well-fluid/well-fluid';
import { CompletionCalculations } from '@dunefront/common/modules/completion/model/completion.calculations';
import { ReferenceVariablesData } from '../../../../../../+store/pumping/selectors/well-fluid-reference-variables.selectors';

@Component({
  selector: 'app-well-fluid-volume',
  templateUrl: './well-fluid-volume.component.html',
  styleUrls: ['./well-fluid-volume.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WellFluidVolumeComponent extends DbDependentComponent implements OnChanges {
  public refWellFluidVolOrDepthValue = 0;

  @Input() public pumpingState?: PumpingStateByRange;
  @Input() public focusedWellFluidRow?: ITableRow<WellFluid>;
  @Input() public referenceVariablesData?: ReferenceVariablesData;

  public get currentUnitType(): UnitSystem {
    return this.pumpingState?.pumping.IsWellFluidDepthByVolume ? UnitSystem.Liquid_Volume : UnitSystem.Long_Length;
  }

  public get label(): string {
    if (!this.focusedWellFluidRow || !this.pumpingState) {
      return '';
    }
    return this.pumpingState.pumping.IsWellFluidDepthByVolume
      ? FlowPathDescription[this.focusedWellFluidRow.rowData.FlowPathDescription].replace('_', ' ') + ' Volume'
      : FlowPathDescription[this.focusedWellFluidRow.rowData.FlowPathDescription].replace('_', ' ') + ' Bottom MD';
  }

  constructor(store: Store, cdRef: ChangeDetectorRef) {
    super(store, cdRef);
  }

  private updateWellFluidsReferenceVariables(): void {
    if (!this.referenceVariablesData || !this.focusedWellFluidRow) {
      return;
    }

    const { pumping, referenceVariables, completion } = this.referenceVariablesData;
    const lowerCompletionsRows = getRowsForCalculations(completion.LowerCompletion.rows);
    const runningStringRows = getRowsForCalculations(completion.RunningString.rows);

    const wf = this.focusedWellFluidRow;

    if (pumping.IsWellFluidDepthByVolume) {
      if (wf.rowData.FlowPathDescription === FlowPathDescription.Workstring) {
        this.refWellFluidVolOrDepthValue = referenceVariables.WorkstringVolume;
      } else if (wf.rowData.FlowPathDescription === FlowPathDescription.Upper_Annulus) {
        this.refWellFluidVolOrDepthValue = referenceVariables.CasingAnnulusVolume;
      } else if (wf.rowData.FlowPathDescription === FlowPathDescription.Washpipe) {
        this.refWellFluidVolOrDepthValue = referenceVariables.WashpipeVolume;
      } else if (wf.rowData.FlowPathDescription === FlowPathDescription.Lower_Annulus) {
        this.refWellFluidVolOrDepthValue = referenceVariables.LowerAnnulusVolume;
      }
    } else {
      if (wf.rowData.FlowPathDescription === FlowPathDescription.Workstring) {
        this.refWellFluidVolOrDepthValue = completion.LowerCompletion.rows[0].rowData.TopMD;
      } else if (wf.rowData.FlowPathDescription === FlowPathDescription.Upper_Annulus) {
        this.refWellFluidVolOrDepthValue = completion.LowerCompletion.rows[0].rowData.TopMD;
      } else if (wf.rowData.FlowPathDescription === FlowPathDescription.Washpipe) {
        this.refWellFluidVolOrDepthValue = CompletionCalculations.MaxRunningStringMD(runningStringRows);
      } else if (wf.rowData.FlowPathDescription === FlowPathDescription.Lower_Annulus) {
        this.refWellFluidVolOrDepthValue = CompletionCalculations.MaxLowerCompletionMD(lowerCompletionsRows);
      }
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.focusedWellFluidRow != null || changes.referenceVariablesData != null) {
      this.updateWellFluidsReferenceVariables();
    }
  }
}
