import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SelectModule } from '../../../shared/components/form-components/select/select.module';
import { AnalysisDataComponent } from './analysis-data.component';

@NgModule({
  declarations: [AnalysisDataComponent],
  imports: [CommonModule, FormsModule, SelectModule],
  exports: [AnalysisDataComponent],
})
export class AnalysisDataModule {}
