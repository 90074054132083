<div class="summary-wrapper" [class.fluidpro]="isFluidPro">
<ng-container *ngIf="(getAreResultsPresent$ | async) && summaryState.summary; else pageDisabled">
  <app-page-panel [header]="'Key Events and Information'" (click)="onHelpChange('results', 'summary')">
    <app-warning-messages-grid [warningMessages]="summaryState.warningMessages"></app-warning-messages-grid>
  </app-page-panel>
  <app-summary-panels [currentAppModuleType]="currentAppModuleType" [summary]="summaryState.summary" (click)="onHelpChange('results', 'summary')"></app-summary-panels>
</ng-container>
<ng-template #pageDisabled>
  <div class="centered-message" (click)="onHelpChange('results', 'summary')">The Summary screen is not available - results are needed.</div>
</ng-template>
</div>
