<div class="activity-overlay-panel" *ngIf="showLoader">
  <app-activity-overlay [message]="'Loading...'"></app-activity-overlay>
</div>
<div class="no-data-overlay-panel" data-cy="no-data-overlay-panel"
     *ngIf="!showLoader && !showData">
  <div>The file has no data in the selected range</div>
</div>
<app-grid
  *ngIf="showData"
  [dataSource]="data"
  [gridConfig]="gridConfig"
  [columns]="gridConfig.columns"
  [showCrudControls]="false"
  [height]="height"
  [isEditingDisabled]="true"
  [isColumnSelectionMode]="true"
  [isFirstRowDeleteAllowed]="false"
  [isFirstRowInsertingAllowed]="false"
  [isInsertingRowDisabled]="true"
  [isInsertingDisabled]="true"
  [isDeletingDisabled]="true"
  [selectedCellIndex]="selectedCellIndex"
  (selectedCellIndexChanged)="selectedCellIndexChanged.emit($event)"
  [hasSelectionColumn]="false"
  dataCy="gauge-data-grid"
></app-grid>
