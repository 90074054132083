import { Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { getIsBackendConnected } from '../../+store/backend-connection/backend-connection.selectors';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { firstValueFrom, Subscription } from 'rxjs';
import { loadAllFilesAndFolders } from '../../+store/file-manager/file-manager.actions';
import { getCurrentFolderState } from '../../+store/file-manager/file-manager.selectors';
import { IFile, Repository } from '@dunefront/common/dto/file.dto';
import { UploadComponent, UploadStatus } from '../../shared/components/upload/upload.component';
import { FileManagerHelper } from '../../+store/file-manager/file-manager.helper';
import { AppTargetConfig } from '../../shared/services/app-target-config';
import { HomeBaseComponent } from './home.base.component';
import { ModalService } from '../../common-modules/modals/modal.service';
import { notEmpty } from '@dunefront/common/common/state.helpers';
import { RouterHelperService } from '../../shared/services/router-helper.service';
import {
  RouteModuleHome,
  RouteModuleHomeDemo,
  RouteModuleHomePersonal,
  RouteModuleHomeShared,
  RouteModuleHomeTrash,
} from './home-page-routes-names';
import { RouteModuleFileManager, RouteModuleFileManagerUpload } from '@dunefront/common/common/routes-names/file-manager-routes-names';
import { getIsAdmin } from '../../+store/auth/auth.selectors';
import { getBackendToClientConfig } from '@dunefront/common/backend-to-client-config';

@Component({
  templateUrl: 'home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent extends HomeBaseComponent implements OnInit, OnDestroy {
  @ViewChild(UploadComponent) public uploadComponent!: UploadComponent;
  public isBackendConnected$ = this.store.select(getIsBackendConnected);
  public homeTabs: IHomeTab[] = [];
  public selectedTab!: string;
  public folder?: IFile;
  public repository!: Repository;

  private subscription = new Subscription();

  constructor(
    private router: Router,
    store: Store,
    private ngZone: NgZone,
    public appConfig: AppTargetConfig,
    private modalService: ModalService,
    private routerHelperService: RouterHelperService,
  ) {
    super(store);
    this.selectedTab = router.url;
    this.subscription.add(
      router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event) => {
        const nav = (event as NavigationEnd).url.split('/');
        this.repository = nav[2] === 'personal' || nav[2] === 'shared' || nav[2] === 'demo' || nav[2] === 'trash' ? nav[2] : 'personal';
        this.selectedTab = nav[2] != null ? `/${nav[1]}/${nav[2]}` : `/${RouteModuleHome}`;
      }),
    );
    this.subscription.add(
      this.isBackendConnected$.pipe(filter((isConnected) => isConnected)).subscribe(() => this.store.dispatch(loadAllFilesAndFolders())),
    );
  }

  public async ngOnInit(): Promise<void> {
    this.subscription.add(
      notEmpty(this.store.select(getCurrentFolderState)).subscribe((currentFolder: IFile) => (this.folder = { ...currentFolder })),
    );

    await this.createHomeTabs();
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public navigate(uri: string): void {
    this.selectedTab = uri;
    this.routerHelperService.navigate([uri]).then();
  }

  public getUploadUrl(): string {
    if (!this.folder) {
      return '';
    }
    return (
      getBackendToClientConfig().ServerBaseUrl +
      `/${RouteModuleFileManager}/${RouteModuleFileManagerUpload}?repository=` +
      this.repository +
      '&path=' +
      encodeURIComponent(JSON.stringify([...this.folder.Folder, this.folder.Name]))
    );
  }

  public uploadFile(): void {
    this.uploadComponent.fileSelection();
  }

  public getAcceptedFileExtension(): string {
    return `.${this.appConfig.fileTypeExtension}`;
  }

  public getFileListInFolder(): IFile[] {
    if (!this.folder?.Children) {
      return [];
    }
    return this.folder.Children;
  }

  public uploadFinished(status: UploadStatus): void {
    this.ngZone.run(() => {
      this.store.dispatch(loadAllFilesAndFolders());

      if (status === UploadStatus.FAILURE) {
        this.modalService
          .showAlert('File could not be uploaded. Please try again or contact support@dunefront.com for help.', 'Upload Error')
          .then();
      }

      if (status === UploadStatus.SUCCESS) {
        if (!this.folder) {
          return;
        }

        FileManagerHelper.navigatePersonalFiles(this.router, this.folder);
      }
    });
  }

  private async createHomeTabs(): Promise<void> {
    const homeTabs = [
      { icon: 'icon-home', label: 'Home', uri: `/${RouteModuleHome}` },
      { icon: 'icon-people-card', label: 'My Projects', uri: `/${RouteModuleHome}/${RouteModuleHomePersonal}` },
      { icon: 'icon-people', label: 'Shared Projects', uri: `/${RouteModuleHome}/${RouteModuleHomeShared}` },
      { icon: 'icon-search-file', label: 'Demo Projects', uri: `/${RouteModuleHome}/${RouteModuleHomeDemo}` },
      { icon: 'icon-trashcan', label: 'Trash', uri: `/${RouteModuleHome}/${RouteModuleHomeTrash}` },
      { icon: 'icon-burger', label: 'Storage', uri: '' },
    ];

    const isAdmin = await firstValueFrom(this.store.select(getIsAdmin).pipe(filter((isAdmin) => isAdmin != null)));
    if (isAdmin) {
      homeTabs.push({ icon: 'icon-tools-settings', label: 'Admin Panel', uri: '/admin' });
    }

    this.homeTabs = homeTabs;
  }
}

export interface IHomeTab {
  icon: string;
  label: string;
  uri: string;
}
