import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges } from '@angular/core';
import { DbDependentComponent } from '../../../../../common-modules/db-connection/db-dependent.component';
import { Store } from '@ngrx/store';
import {
  createChartDataColumn,
  createChartDataRowDto,
  createChartDataSet,
  createEmptyChartData,
  IChartDataDto,
} from '@dunefront/common/modules/reporting/dto/chart-data.dto';
import {
  FrictionCalculatorResultDto,
  FrictionXAxis,
} from '@dunefront/common/modules/calculators/friction-calculator/friction-calculator.dto';
import { DataType } from '@dunefront/common/dto/data-storage';
import { ChartState } from '../../../../../+store/reporting/reporting-module.state';
import { ChartDataSourceType } from '@dunefront/common/modules/reporting/dto/chart.dto';
import { getChartIds } from '../../../../../+store/reporting/reporting.selectors';
import { ChartAxis } from '@dunefront/common/modules/reporting/dto/chart-axis-property.dto';
import { ReportingFactory } from '../../../../../+store/reporting/model/reporting.factory';

@Component({
  selector: 'app-friction-calculator-result-chart',
  templateUrl: './friction-calculator-result-chart.component.html',
  styleUrls: ['./friction-calculator-result-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FrictionCalculatorResultChartComponent extends DbDependentComponent implements OnChanges, AfterViewInit {
  @Input() public frictionCalculatorResult: FrictionCalculatorResultDto[] = [];

  @Input() public displayedAxis!: FrictionXAxis;

  public chartState?: ChartState;
  public chartId = 0;

  constructor(store: Store, cdRef: ChangeDetectorRef) {
    super(store, cdRef);

    this.subscription.add(
      store.select(getChartIds).subscribe((chartIds) => {
        this.chartId = chartIds.frictionCalculatorChartId;
        cdRef.markForCheck();
      }),
    );
  }

  public ngAfterViewInit(): void {
    this.setChartData();
  }

  public ngOnChanges(): void {
    this.setChartData();
  }

  public setChartData(): void {
    // this is to fix chart 'shaking' just after page is opened.
    setTimeout(() => {
      this.chartState = ReportingFactory.createChartStateWithData(ChartDataSourceType.FrictionCalculator, this.generateChartData());
      this.cdRef.markForCheck();
    }, 0);
  }

  public generateChartData(): IChartDataDto {
    const chartDataDto = createEmptyChartData(
      this.displayedAxis === FrictionXAxis.Rate ? DataType.Return_Rate : DataType.Fluid_Velocity,
      ChartDataSourceType.FrictionCalculator,
      this.chartId,
    );

    const columnFriction = createChartDataColumn(ChartAxis.PrimaryValue, DataType.Friction_Pressure, 0, 0, 'Friction');
    chartDataDto.ChartDataColumns.push(columnFriction);

    const chartDataRows = this.frictionCalculatorResult.map((result) =>
      createChartDataRowDto(this.getChartArgument(result), [result.Friction]),
    );
    chartDataDto.ChartDataSets.push(createChartDataSet(chartDataRows));
    return chartDataDto;
  }

  private getChartArgument(result: FrictionCalculatorResultDto): number {
    return this.displayedAxis === FrictionXAxis.Rate ? result.Rate : result.Velocity;
  }
}
