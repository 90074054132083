import { createSelector } from '@ngrx/store';
import { getChartState } from '../reporting/reporting.selectors';
import { DictionaryWithArray } from '@dunefront/common/common/state.helpers';
import { IChartDataDto } from '@dunefront/common/modules/reporting/dto/chart-data.dto';
import { RangeDto } from '@dunefront/common/dto/range.dto';
import { getCurrentRange, getRangeModuleState } from './range.selectors';

export const getRangeValidation = createSelector(getRangeModuleState, getCurrentRange, getChartState, (state, currentRange, chartData) => {
  const isDataInRange = getIsDataInRange(state.editingDto?.RangeStart, state.editingDto?.RangeEnd, chartData.chartData);
  const isNameUnique = getIsRangeNameUnique(state.editingDto, DictionaryWithArray.getArray(state.ranges));

  return {
    isNameUnique,
    isDataInRange,
  } as IGaugeDataRangeValidation;
});

function getIsRangeNameUnique(editingRange: RangeDto | undefined, allRanges: RangeDto[]): boolean {
  if (!editingRange) {
    return false;
  }
  return (
    allRanges.findIndex(
      (range) => range.Name.trim().toLowerCase() === editingRange.Name.trim().toLowerCase() && range.Id !== editingRange.Id,
    ) === -1
  );
}

function getIsDataInRange(rangeStart: number | undefined, rangeEnd: number | undefined, chartData: IChartDataDto | undefined): boolean {
  const startData = chartData?.StartDate;

  if (rangeStart === undefined || rangeEnd === undefined || chartData == null || startData == null) {
    return false;
  }

  for (const dataSet of chartData.ChartDataSets) {
    if (
      dataSet.ChartDataRows.findIndex(
        (row) => row.Argument + dataSet.XAxisShift + startData > rangeStart && row.Argument + dataSet.XAxisShift + startData < rangeEnd,
      ) !== -1
    ) {
      return true;
    }
  }

  return false;
}

export interface IGaugeDataRangeValidation {
  isNameUnique: boolean;
  isDataInRange: boolean;
}
