import { createSelector } from '@ngrx/store';
import { getValidatedPumping, getValidatedPumpingModuleState } from './pumping-validation.selectors';

import { getValidatedFluidModuleState } from '../../fluid/fluid.selectors';

import { getAllGravelsArray, getValidatedGravels } from '../../gravel/gravel.selectors';

import { getValidatedDeveloperSettings, getValidatedSettings } from '../../settings/validated-settings.selectors';
import { getValidatedCompletionModuleState } from '../../completion/validated-completion.selectors';
import {
  AutoScheduleInputs,
  HRWPPadStageGeneratorInputs,
  ScheduleGeneratorInputs,
  SimulateScheduleGeneratorJobInputData,
} from '@dunefront/common/modules/pumping/dto/simulate-schedule-generator-job-input-data.dto';
import { CasingPipesFactory } from '@dunefront/common/modules/pipes/casing-pipes/casing-pipes.factory';
import { getRowsForCalculations } from '@dunefront/common/common/common-grid.interfaces';
import { ZoneFactory } from '@dunefront/common/modules/well/model/zone/zone.factory';
import { SurveyFactory } from '@dunefront/common/modules/well/model/survey/survey.factory';
import { FluidWithDependenciesDto } from '@dunefront/common/modules/fluid/dto/fluid.dto';
import { DictionaryWithArray } from '@dunefront/common/common/state.helpers';
import { GravelDto } from '@dunefront/common/modules/gravel/dto/gravel.dto';
import { LowerCompletionPipesFactory } from '@dunefront/common/modules/pipes/lower-completion-pipes/lower-completion-pipes.factory';
import { RunningStringPipesFactory } from '@dunefront/common/modules/pipes/running-string-pipes/running-string-pipes.factory';
import { WellFluidFactory } from '@dunefront/common/modules/pumping/model/well-fluid/well-fluid.factory';
import { CaliperFactory } from '@dunefront/common/modules/well/model/caliper/caliper.factory';
import { ShuntTubeFactory } from '@dunefront/common/modules/shunt-tube/shunt-tube.factory';
import { FluidFactory } from '@dunefront/common/modules/fluid/model/fluid.factory';
import { TreatingReturnLinesFactory } from '@dunefront/common/modules/pumping/dto/treating-return-lines';
import { getValidatedWellModuleState } from '../../well/validated-well.selectors';
import { getPumpingSchedule } from './pumping.selectors';

const getAutoScheduleInputs = createSelector(
  getValidatedPumping,
  (pumping): AutoScheduleInputs => ({
    AutoScheduleCarrierFluidId: pumping.AutoScheduleCarrierFluidId as number,
    AutoScheduleCarrierFluidIndex: -1,
    AutoScheduleExcessGravelPercentage: pumping.AutoScheduleExcessGravelPercentage,
    AutoScheduleExcessPumpRate: pumping.AutoScheduleExcessPumpRate,
    AutoScheduleFlushFluidId: pumping.AutoScheduleFlushFluidId as number,
    AutoScheduleFlushFluidIndex: -1,
    AutoScheduleGravelId: pumping.AutoScheduleGravelId as number,
    AutoScheduleGravelIndex: -1,
    AutoScheduleMaxGravelConc: pumping.AutoScheduleMaxGravelConc,
    AutoSchedulePumpRate: pumping.AutoSchedulePumpRate,
    AutoScheduleReturnRate: pumping.AutoScheduleReturnRate,
    AutoScheduleTargetPacking: pumping.AutoScheduleTargetPacking,
    IsAutoScheduleRamped: pumping.IsAutoScheduleRamped,
  }),
);

const getHRWPPadStageGeneratorInputs = createSelector(
  getAutoScheduleInputs,
  getValidatedWellModuleState,
  getValidatedFluidModuleState,
  getValidatedGravels,
  getValidatedDeveloperSettings,
  getValidatedPumping,

  (...[autoScheduleInputs, well, fluids, gravels, developerSettings, pumping]): HRWPPadStageGeneratorInputs => {
    const CasingData = getRowsForCalculations(well.CasingData.rows).map((pipe) => CasingPipesFactory.toDto(pipe));
    const SurveyData = getRowsForCalculations(well.SurveyData.rows).map((pipe) => SurveyFactory.toDto(pipe));
    const ZoneData = getRowsForCalculations(well.ZoneData.rows).map((caliper) => ZoneFactory.toDto(caliper));
    const CarrierFluid: FluidWithDependenciesDto = FluidFactory.createFluidWithDependenciesDto(
      fluids,
      pumping.AutoScheduleCarrierFluidId as number,
    );
    const Gravel: GravelDto = DictionaryWithArray.get(gravels, pumping.AutoScheduleGravelId) as GravelDto;

    const result: HRWPPadStageGeneratorInputs = {
      ...autoScheduleInputs,
      CasingData,
      TemperatureProfile: well.TemperatureProfile,
      SurveyData,
      ZoneData,
      LocationType: well.LocationType,
      ZonePressureType: well.ZonePressureType,
      IsZoneDepthByMD: well.IsZoneDepthByMD,
      CarrierFluid,
      Gravel,
      PenetrationBoundaryRatio: developerSettings.PenetrationBoundaryRatio,
      MaxLoopIterations: developerSettings.MaxLoopIterations,
      RateDistributionMinTolerance: developerSettings.RateDistributionMinTolerance,
      DamageBoundaryRatio: developerSettings.DamageBoundaryRatio,
      MaxReservoirBoundaryRadius: developerSettings.MaxReservoirBoundaryRadius,
      GlobalTolerance: developerSettings.GlobalTolerance,
    };
    return result;
  },
);

export const getScheduleGeneratorInputs = createSelector(
  getHRWPPadStageGeneratorInputs,
  getValidatedWellModuleState,
  getValidatedCompletionModuleState,
  getValidatedPumpingModuleState,
  getValidatedSettings,

  (...[HRWPPadStageGeneratorInputs, well, completion, pumpingState, settings]): ScheduleGeneratorInputs => {
    const LowerCompletion = getRowsForCalculations(completion.LowerCompletion.rows).map((pipe) =>
      LowerCompletionPipesFactory.toDtoWithIcdPorts(pipe),
    );
    const RunningString = getRowsForCalculations(completion.RunningString.rows).map((pipe) => RunningStringPipesFactory.toDto(pipe));
    const caliperDtos = getRowsForCalculations(well.CaliperData.rows).map((caliper) => CaliperFactory.toDto(caliper));
    const CalculatedCaliperData = CaliperFactory.toCalculatedCaliperTransferArray(caliperDtos, well.CaliperTolerance);
    const WellFluids = getRowsForCalculations(pumpingState.wellFluids.rows).map((wellFluid) => WellFluidFactory.toDto(wellFluid));

    return {
      ...HRWPPadStageGeneratorInputs,
      LowerCompletion,
      RunningString,
      CalculatedCaliperData,
      TreatingReturnLines: TreatingReturnLinesFactory.create(pumpingState),
      ToolPosition: pumpingState.pumping.ToolPosition,
      PumpingDirection: pumpingState.pumping.PumpingDirection,
      IsModelScreenHandlingLength: settings.ModelScreenHandlingLength,
      IsToolJointAnalysed: settings.IsToolJointAnalysed,
      IsBlankPackingForGravelRequired: pumpingState.pumping.IsBlankPackingForGravelRequired,
      BlankPackingPercentageForGravelRequired: pumpingState.pumping.BlankPackingPercentageForGravelRequired,
      TreatmentType: well.TreatmentType,
      WellFluids,
      IsWellFluidSelectionSimple: pumpingState.pumping.IsWellFluidSelectionSimple,
      WorkstringFluidId: pumpingState.pumping.WorkstringFluidId as number,
      UpperAnnulusFluidId: pumpingState.pumping.UpperAnnulusFluidId as number,
      WorkstringFluidIndex: -1,
      UpperAnnulusFluidIndex: -1,
    };
  },
);

export const getSimulateScheduleGeneratorJobInputData = createSelector(
  getScheduleGeneratorInputs,
  getValidatedCompletionModuleState,
  getAllGravelsArray,
  getPumpingSchedule,
  (...[scheduleGeneratorInputs, completion, allGravels, pumpingSchedule]): SimulateScheduleGeneratorJobInputData => ({
    ...scheduleGeneratorInputs,
    ShuntTube: ShuntTubeFactory.toDto(completion.ShuntTube),
    AllGravels: allGravels,
    PumpingSchedule: getRowsForCalculations(pumpingSchedule.rows),
  }),
);
