<app-page-panel header="Shroud Properties">
  <div class="grid-form-container" *ngIf="shuntTube">
    <div>Shroud Present</div>
    <app-radio-group
      [source]="shuntTube.Shroud"
      [key]="'IsShroudPresent'"
      [items]="shroudPresentRadio"
      (valueChanged)="onValueChange($event)"
      id="IsShroudPresentRadio"
    >
    </app-radio-group>
    <app-form-input
      [disabled]="!shuntTube.Shroud.IsShroudPresent"
      label="OD"
      appUnwrap
      [source]="shuntTube.Shroud"
      [key]="'ShroudOuterDiameter'"
      [decimalPlaces]="3"
      [unitType]="UnitType.Diameter"
      (valueChanged)="onValueChange($event)"
      dataCy="shroud-outer-diameter"
    >
    </app-form-input>
    <app-form-input
      [disabled]="!shuntTube.Shroud.IsShroudPresent"
      label="ID"
      appUnwrap
      [source]="shuntTube.Shroud"
      [key]="'ShroudInnerDiameter'"
      [decimalPlaces]="3"
      [unitType]="UnitType.Diameter"
      (valueChanged)="onValueChange($event)"
      dataCy="shroud-inner-diameter"
    >
    </app-form-input>
    <app-form-input
      [disabled]="!shuntTube.Shroud.IsShroudPresent"
      label="Percentage Open Area"
      appUnwrap
      [source]="shuntTube.Shroud"
      [key]="'OpenAreaPercentage'"
      [decimalPlaces]="2"
      unitLabel="%"
      [unitType]="UnitType.None"
      (valueChanged)="onValueChange($event)"
    >
    </app-form-input>
  </div>
</app-page-panel>
