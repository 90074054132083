import NumberFormatOptions = Intl.NumberFormatOptions;
import NumberFormat = Intl.NumberFormat;

export const DEFAULT_DECIMAL_PLACES = 2;

export class DecimalNumberParser {
  public readonly _decimal = new RegExp('[.]', 'g');
  public readonly _group = new RegExp('[,]', 'g');
  public readonly _minusSign = new RegExp('[-]', 'g');
  public _prefixOption = '';
  public _suffixOption = '';
  public _numberFormat!: NumberFormat;
  public locale = 'En-gb';
  private _numeral: any;
  private _index: any;

  constructor() {
    this.constructParser();
  }

  private _suffix: any;

  public get suffix(): string {
    return this._suffixOption;
  }

  public set suffix(suffixOption: string) {
    this._suffixOption = suffixOption;
    this.constructParser();
  }

  private _prefix: any;

  public get prefix(): string {
    return this._prefixOption;
  }

  public set prefix(prefixOption: string) {
    this._prefixOption = prefixOption;
    this.constructParser();
  }

  private _decimalPlaces = DEFAULT_DECIMAL_PLACES;

  public set decimalPlaces(decPlaces: number | undefined) {
    if (decPlaces !== undefined) {
      this._decimalPlaces = decPlaces;
      this._numberFormat = new Intl.NumberFormat(this.locale, this.getOptions());
    } else {
      this._decimalPlaces = DEFAULT_DECIMAL_PLACES;
    }
  }

  public get decimalPlaces(): number | undefined {
    return this._decimalPlaces;
  }

  public parseDecimalNumber(text: string): number {
    const parsed = this.parseValue(text);
    if (parsed === '-' || parsed === null) {
      return NaN;
    }
    return parsed;
  }

  public constructParser(): void {
    this._numberFormat = new Intl.NumberFormat(this.locale, this.getOptions());
    const numerals = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    const index = new Map([
      ['0', 0],
      ['1', 1],
      ['2', 2],
      ['3', 3],
      ['4', 4],
      ['5', 5],
      ['6', 6],
      ['7', 7],
      ['8', 8],
      ['9', 9],
    ]);
    this._numeral = new RegExp(`[${numerals.join('')}]`, 'g');

    this._suffix = new RegExp(`${this.escapeRegExp(this._suffix || '')}`, 'g');
    this._prefix = new RegExp(`${this.escapeRegExp(this._prefix || '')}`, 'g');
    this._index = (d: string): number | undefined => index.get(d);
  }

  public isMinusSign(char: string): boolean {
    if (this._minusSign.test(char)) {
      this._minusSign.lastIndex = 0;
      return true;
    }

    return false;
  }

  public formatValue(value: ParsedValue | string): string {
    if (value !== null) {
      let formattedValue = this._numberFormat.format(+value);
      if (this.prefix) {
        formattedValue = this.prefix + formattedValue;
      }

      if (this.suffix) {
        formattedValue = formattedValue + this.suffix;
      }

      return formattedValue;
    }

    return '';
  }

  public isDecimalSign(char: string): boolean {
    if (this._decimal.test(char)) {
      this._decimal.lastIndex = 0;
      return true;
    }

    return false;
  }

  public parseValue(text: string | undefined | null): ParsedValue {
    if (text == null) {
      return null;
    }

    const filteredText = text
      .trim()
      .replace(/\s/g, '')
      .replace(this._group, '')
      .replace(this._minusSign, '-')
      .replace(this._decimal, '.')
      .replace(this._numeral, this._index);

    if (filteredText) {
      if (filteredText === '-') {
        // Minus sign
        return filteredText;
      }

      const parsedValue = +filteredText;
      return isNaN(parsedValue) ? null : parsedValue;
    }

    return null;
  }

  public translateNumber(text: string): string {
    const regex = /[,.]/g;
    const separators = text.match(regex) || [];
    const uniqueSeparators = Array.from(new Set(separators));

    if (!separators.length) {
      return text;
    }

    // if decimals are set by comma, replace comma with dot : 1,05 => 1.05
    if (separators.length === 1 && separators[0] === ',') {
      return text.replace(',', '.');
    }

    // if there are more separators, but with one type, there is no decimals
    // set all thousand separators to comma
    if (separators.length > 1 && uniqueSeparators.length === 1) {
      return text.replace(regex, ',');
    }

    // if there are more than one type of separator, use commas for thousands, and dot for decimal
    // use commas for thousands, and dot for decimal regardless to current format
    else {
      return text.split(regex).reduce((text, value, i, array) => text + (i < array.length - 1 ? ',' : '.') + value);
    }
  }

  public isNumeralChar(char: string): boolean {
    if (
      char.length === 1 &&
      (this._numeral.test(char) || this._decimal.test(char) || this._group.test(char) || this._minusSign.test(char))
    ) {
      this.resetRegex();
      return true;
    }

    return false;
  }

  private getOptions(): NumberFormatOptions {
    return {
      useGrouping: true,
      minimumFractionDigits: this._decimalPlaces,
      maximumFractionDigits: this._decimalPlaces,
    };
  }

  private escapeRegExp(text: string): string {
    return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  }

  private resetRegex(): void {
    this._numeral.lastIndex = 0;
    this._decimal.lastIndex = 0;
    this._group.lastIndex = 0;
    this._minusSign.lastIndex = 0;
  }
}

export type ParsedValue = number | null | '-';
