import { Chart, Point } from 'chart.js';
import {
  DrawingGradientLine,
  emptyPointOffsets,
  EventHandler,
  GradientLineEditMode,
  GradientLinePluginOptions,
  PointOffsets,
} from './types';
import { Color, LineStyle } from '@dunefront/common/modules/reporting/dto/chart.types';

const chartStates = new Map<Chart, GradientLinePluginState>();

export const getState = (chart: Chart): GradientLinePluginState => {
  let state = chartStates.get(chart);
  if (state == null) {
    state = {
      options: {
        lines: [],
        scales: [],
        mode: 'inactive',
        xScaleID: '',
        yScaleID: '',
        defaultStyle: {
          GradientLineStyle: LineStyle.solid,
          GradientLineColor: Color.Red,
          GradientLineThickness: 3,
          GradientLineDecimalPlaces: 2,
        },
      },
      lines: [],
      editMode: 'default',
      handlers: {},
      dragOffsetsPoint1: emptyPointOffsets(),
      dragOffsetsPoint2: emptyPointOffsets(),
    };

    chartStates.set(chart, state);
  }

  return state;
};

export const removeState = (chart: Chart): void => {
  chartStates.delete(chart);
};

export interface GradientLinePluginState {
  createdGradientLine?: DrawingGradientLine;
  editedGradientLine?: DrawingGradientLine;
  options: GradientLinePluginOptions;
  lines: DrawingGradientLine[];
  handlers: { [type: string]: EventHandler };
  editMode: GradientLineEditMode;
  dragOffsetsPoint1: PointOffsets;
  dragOffsetsPoint2: PointOffsets;
  prevPoint?: Point;
  highlightedGradientLineId?: number;
  uuid?: string;
}
