import { AfterViewInit, ChangeDetectorRef, Component, Input, OnChanges } from '@angular/core';
import { GeneralCalculations } from '@dunefront/common/common/general.calculations';
import { getRowsForCalculations } from '@dunefront/common/common/common-grid.interfaces';
import {
  createChartDataColumn,
  createChartDataSet,
  IChartDataDto,
  IChartDataRowDto,
  IChartDataSetDto,
} from '@dunefront/common/modules/reporting/dto/chart-data.dto';
import { DbDependentComponent } from '../../../../../common-modules/db-connection/db-dependent.component';
import { Store } from '@ngrx/store';
import { TemperatureProfileType } from '@dunefront/common/modules/well/dto/well.dto';
import { SurveyCalculations } from '@dunefront/common/modules/well/dto/survey/survey.dto';
import { LengthUnit } from '@dunefront/common/dto/unit-system.dto';
import * as wellActions from '../../../../../+store/well/well.actions';
import { DataType } from '@dunefront/common/dto/data-storage';
import { ChartState } from '../../../../../+store/reporting/reporting-module.state';
import { ChartDataSourceType } from '@dunefront/common/modules/reporting/dto/chart.dto';
import { getChartIds } from '../../../../../+store/reporting/reporting.selectors';
import { ChartAxis } from '@dunefront/common/modules/reporting/dto/chart-axis-property.dto';
import { ReportingFactory } from '../../../../../+store/reporting/model/reporting.factory';
import { IRadioItem } from '@dunefront/common/common/radio.helpers';
import { ValidatedWellModuleState } from '@dunefront/common/modules/well/well-module.state';
import { ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';

const CHART_NAME_SURVEY = 'Survey';

export enum SurveyLine {
  Md = 1,
  Tvd = 2,
}

export interface ISurveyProfilePoint {
  MD?: number;
  TVD?: number;
  HD?: number;
}

@Component({
  selector: 'app-survey-preview-chart',
  templateUrl: './survey-preview-chart.component.html',
  styleUrls: ['./survey-preview-chart.component.scss'],
})
export class SurveyPreviewChartComponent extends DbDependentComponent implements OnChanges, AfterViewInit {
  @Input() public state: ValidatedWellModuleState | undefined;
  @Input() public longLengthUnit: LengthUnit | undefined;
  @Input() public drawableProviderId?: string;
  @Input() public noMenu = false;

  public readonly chartDisplayName = CHART_NAME_SURVEY;

  public items: IRadioItem<boolean>[] = [
    { value: true, text: 'MD' },
    { value: false, text: 'TVD' },
  ];

  public chartState?: ChartState;
  public surveyProfile: ISurveyProfilePoint[] = [];
  public chartId = 0;

  constructor(store: Store, cdRef: ChangeDetectorRef) {
    super(store, cdRef);

    this.subscription.add(
      store.select(getChartIds).subscribe((chartIds) => {
        this.chartId = chartIds.wellSurveyChartId;
        cdRef.markForCheck();
      }),
    );
  }

  public ngAfterViewInit(): void {
    this.setChartData();
  }

  public ngOnChanges(): void {
    this.setChartData();
  }

  public setChartData(): void {
    if (this.state == null || !this.state.SurveyData.isValid) {
      this.chartState = undefined;
      return;
    }

    this.chartState = ReportingFactory.createChartStateWithData(ChartDataSourceType.WellSurvey, this.calculateSurveyProfile());
    this.cdRef.markForCheck();
  }

  public changeSurveyType($event: ObjectChangeProp<ValidatedWellModuleState>): void {
    this.store.dispatch(wellActions.changeWellPropertyAction($event));
  }

  public customizeLabelText(info: any): string {
    return info.value;
  }

  private get isSurveyDepthByMD(): boolean {
    return this.state?.IsSurveyDepthByMD ?? false;
  }

  public calculateSurveyProfile(): IChartDataDto {
    const chartDataDto: IChartDataDto = {
      ChartDataColumns: [],
      ChartDataSets: [],
      ArgumentDataType: this.isSurveyDepthByMD ? DataType.Measured_Depth : DataType.True_Vertical_Depth,
      IsPrimaryArgument: true,
      SecondaryArgumentDataType: this.isSurveyDepthByMD ? DataType.True_Vertical_Depth : DataType.Measured_Depth,
      IsMaxResolution: true,
      ChartId: this.chartId,
      ChartDataSourceType: ChartDataSourceType.WellSurvey,
    };

    if (this.state == null) {
      return chartDataDto;
    }

    const columnHD = createChartDataColumn(ChartAxis.PrimaryValue, DataType.Horizontal_Displacement, 0, 0);
    const columnDPT = createChartDataColumn(ChartAxis.OppositePrimaryValue, DataType.Down_Path_Temperature, 0, 1);
    const columnUPT = createChartDataColumn(ChartAxis.OppositePrimaryValue, DataType.Up_Path_Temperature, 0, 2);

    chartDataDto.ChartDataColumns.push(columnHD);
    if (this.state.TemperatureProfile.TemperatureProfileType === TemperatureProfileType.Detailed_Calc) {
      chartDataDto.ChartDataColumns.push(columnDPT);
      chartDataDto.ChartDataColumns.push(columnUPT);
    }

    const dataset: IChartDataSetDto = createChartDataSet();
    chartDataDto.ChartDataSets.push(dataset);

    // define variables needed for the calculation
    let totalNorth = 0;
    let totalEast = 0;

    const surveyData = this.state.SurveyData.rows;
    // Copy the data from the Survey into the profile
    for (let index = 0; index < getRowsForCalculations(surveyData).length; index++) {
      let horizontalDisplacement: number;

      // Calculate the horizontal displacement
      if (index === 0) {
        // first point must be zero
        horizontalDisplacement = 0;
      } else {
        const prevSurvey = surveyData[index - 1].rowData;
        const currentSurvey = surveyData[index].rowData;
        // Calculate the delta MD value
        const deltaMD = currentSurvey.MD - prevSurvey.MD;
        const curvatureFactor = SurveyCalculations.CurvatureFactor(prevSurvey, surveyData[index].rowData);

        // Calculate the delta North value
        const deltaNorthTerm1 =
          Math.sin(GeneralCalculations.DegreesToRadians(prevSurvey.Deviation)) *
          Math.cos(GeneralCalculations.DegreesToRadians(prevSurvey.Azimuth));

        const deltaNorthTerm2 =
          Math.sin(GeneralCalculations.DegreesToRadians(currentSurvey.Deviation)) *
          Math.cos(GeneralCalculations.DegreesToRadians(currentSurvey.Azimuth));

        const deltaNorth = 0.5 * deltaMD * (deltaNorthTerm1 + deltaNorthTerm2) * curvatureFactor;

        // Calculate the delta East value
        const deltaEastTerm1 =
          Math.sin(GeneralCalculations.DegreesToRadians(prevSurvey.Deviation)) *
          Math.sin(GeneralCalculations.DegreesToRadians(prevSurvey.Azimuth));

        const deltaEastTerm2 =
          Math.sin(GeneralCalculations.DegreesToRadians(currentSurvey.Deviation)) *
          Math.sin(GeneralCalculations.DegreesToRadians(currentSurvey.Azimuth));

        const deltaEast = 0.5 * deltaMD * (deltaEastTerm1 + deltaEastTerm2) * curvatureFactor;

        // Add the delta values to the totals
        totalNorth += deltaNorth;
        totalEast += deltaEast;

        // Calculate the horizontal displacement
        horizontalDisplacement = Math.sqrt(GeneralCalculations.Pow(totalNorth, 2) + GeneralCalculations.Pow(totalEast, 2));
      }

      let y: number;
      if (this.isSurveyDepthByMD) {
        y = surveyData[index].rowData.MD;
      } else {
        y = surveyData[index].rowData.TVD;
      }
      const x = horizontalDisplacement;
      const point: IChartDataRowDto = { Argument: y, SecondaryArgument: y, Values: [x] };

      if (this.state.TemperatureProfile.TemperatureProfileType === TemperatureProfileType.Detailed_Calc) {
        point.Values.push(surveyData[index].rowData.DownPathTemp);
        point.Values.push(surveyData[index].rowData.UpPathTemp);
      }
      dataset.ChartDataRows.push(point);
    }

    return chartDataDto;
  }
}
