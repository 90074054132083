import { IDrawingOptions, ImageRun } from 'docx';

export class DocxImageGenerator {
  private imageIndex = 0;

  public async getImage(url: string, width: number, height: number, options?: IDrawingOptions): Promise<ImageRun> {
    const imageBlob = await fetch(url).then((r) => r.arrayBuffer());

    const imageRun = new ImageRun({
      data: imageBlob,
      transformation: {
        width,
        height,
      },
      floating: options?.floating,
    });

    const oldKey = (imageRun as any).key;
    const newKey = 'image-' + this.imageIndex++ + '.png';

    const imageData = (imageRun as any).imageData;
    imageData.fileName = newKey;
    (imageRun as any).key = newKey;

    this.fixEmbed(imageRun as any, oldKey, newKey);

    return imageRun;
  }

  private fixEmbed(node: XmlNode, oldKey: string, newKey: string): void {
    const root = node.root;
    if (root == null) {
      return;
    }

    if ('embed' in root && root.embed != null) {
      root.embed = root.embed.replace(oldKey, newKey);
    } else if (Array.isArray(node.root)) {
      for (const child of node.root) {
        this.fixEmbed(child, oldKey, newKey);
      }
    }
  }
}

interface XmlNode {
  root?: XmlNode[] | { embed?: string };
}
