import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { IFile } from '@dunefront/common/dto/file.dto';
import { loadAllFilesAndFoldersSuccess, moveFileOrFolder } from '../../../../+store/file-manager/file-manager.actions';
import { getRepositoryFolderState } from '../../../../+store/file-manager/file-manager.selectors';
import { BaseFileActionDialogComponent } from '../base-file-action.dialog.component';
import { Store } from '@ngrx/store';
import { ISelectItem } from '@dunefront/common/common/select.helpers';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Actions, ofType } from '@ngrx/effects';
import { PrimitiveChangeValue } from '@dunefront/common/common/common-state.interfaces';
import { notEmpty } from '@dunefront/common/common/state.helpers';
import { FileErrorHelper } from '../file-error.helper';
import {
  CY_BUTTON_OK,
  defaultModalCancelButton,
  defaultModalConfirmButton,
  ModalButtonConfig,
} from '../../../../common-modules/modals/generic-modal/generic-modal.component';

@Component({
  selector: 'app-move-dialog',
  templateUrl: './move.dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MoveDialogComponent extends BaseFileActionDialogComponent implements OnInit {
  public datasource: ISelectItem<string>[] = [];
  public targetFolder?: IFile;
  protected repositoryFolderState?: IFile;
  public modalTitle = 'Move File';
  public modalButtonsConfigs: ModalButtonConfig[] = [];

  constructor(activeModal: DynamicDialogRef, config: DynamicDialogConfig, store: Store, cdRef: ChangeDetectorRef, actions$: Actions) {
    super(activeModal, config, store, cdRef);
    this.file = config.data.file;
    this.subscription.add(actions$.pipe(ofType(loadAllFilesAndFoldersSuccess)).subscribe((_) => this.activeModal.close()));

    this.subscription.add(
      notEmpty(this.store.select(getRepositoryFolderState)).subscribe((repositoryFolderState: IFile) => {
        this.repositoryFolderState = { ...repositoryFolderState };
        const datasource: ISelectItem<string>[] = [];
        this.addFolderToDatasource(datasource, repositoryFolderState, '');
        this.datasource = datasource;
        this.cdRef.markForCheck();
      }),
    );
  }

  public ngOnInit(): void {
    this.fileName = { ...this.fileName, targetName: this.file.Name.trim() };

    this.modalButtonsConfigs = [
      defaultModalCancelButton((): void => this.cancelClicked()),
      defaultModalConfirmButton((): void => this.okClicked(), 'OK', CY_BUTTON_OK, !this.targetFolder || !!this.fileName.error.targetName),
    ];
  }

  public addFolderToDatasource(datasouce: ISelectItem<string>[], folder: IFile, path: string): void {
    const requestedPathToMove = [this.file.Repository, ...this.file.Folder, this.file.Name].join('/');
    // Do not show requested folder and any of it's children
    if (requestedPathToMove === [folder.Repository, ...folder.Folder, folder.Name].join('/')) {
      return;
    }

    const newPath = !path ? folder.Repository : path + folder.Name;

    // Do not show parent of the requested folder but proceed with it's children as we want to show other siblings
    if (requestedPathToMove !== [folder.Repository, ...folder.Folder, folder.Name, this.file.Name].join('/')) {
      const moveOption: ISelectItem<string> = { text: newPath, value: JSON.stringify(folder) };
      datasouce.push(moveOption);
    }

    const folderChildrenFolders = folder.Children?.filter((child: IFile) => child.FileType === 'folder');

    if (!folderChildrenFolders) {
      return;
    }
    for (const child of folderChildrenFolders) {
      this.addFolderToDatasource(datasouce, child, newPath + '/');
    }
  }

  public okClicked(): void {
    if (!this.targetFolder || this.fileName.error.targetName !== '') {
      return;
    }
    this.store.dispatch(
      moveFileOrFolder({
        source: this.file,
        target: { ...this.file, Folder: [...this.targetFolder.Folder, this.targetFolder.Name] },
        mode: 'move',
      }),
    );
  }

  public valueChanged($event: PrimitiveChangeValue<string>): void {
    this.targetFolder = JSON.parse($event.value) as IFile;
    this.validateMoveAction(this.targetFolder);
  }

  private validateMoveAction(targetFolder: IFile): void {
    const fileNameList = this.getNamesForValidation(this.file.Repository, [...targetFolder.Folder, targetFolder.Name], this.file.FileType);
    this.fileName = FileErrorHelper.validateFileFolderName(this.fileName, fileNameList);
    this.errorMessage = this.fileName.error.targetName;
    const okButton = this.modalButtonsConfigs.find((conf) => conf.dataCy === CY_BUTTON_OK);
    if (okButton) {
      okButton.disabled = !!this.errorMessage;
    }
  }
}
