import { getFileHashFromFile, IFile, isFileDescendantOfFolder } from '@dunefront/common/dto/file.dto';
import { IFileJobTypes, IJobTypes } from './files-jobs.selectors';
import { EnumHelpers } from '@dunefront/common/utils/enum.helpers';
import { ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import { JsWorkerActionType } from '@dunefront/common/modules/worker-types/js-workers-common';

export class FileJobTypesHelper {
  public static canOpenFile(file: IFile, allFileJobTypes: IFileJobTypes[]): boolean {
    if (file.FileType === 'folder') {
      return true;
    }

    const fileJobTypes = this.getFileJobTypes(file, allFileJobTypes);
    if (fileJobTypes == null) {
      return true;
    }

    return !fileJobTypes.jsWorkerJobTypes.has(JsWorkerActionType.RunDbMigration);
  }

  public static canUpdateFile(file: IFile, allFileJobTypes: IFileJobTypes[]): boolean {
    const fileJobTypes = this.getFileJobTypes(file, allFileJobTypes);
    if (fileJobTypes == null) {
      return true;
    }

    return fileJobTypes.calcEngineJobTypes.size === 0 && fileJobTypes.jsWorkerJobTypes.size === 0;
  }

  public static getJobTypesAsString(file: IFile, allFileJobTypes: IFileJobTypes[]): string {
    const fileJobTypes = this.getFileJobTypes(file, allFileJobTypes);
    if (fileJobTypes == null) {
      return '';
    }

    const calcEngine = Array.from(fileJobTypes.calcEngineJobTypes.values()).map((type) => EnumHelpers.enumToText(ModuleType[type]));
    const js = Array.from(fileJobTypes.jsWorkerJobTypes.values()).map((actionType) => this.jsWorkerActionTypeToString(actionType));

    return [...calcEngine, ...js].join(', ');
  }

  public static getFileJobTypes(file: IFile, allFileJobTypes: IFileJobTypes[]): IJobTypes | undefined {
    if (file.FileType === 'folder') {
      const result: IJobTypes = {
        calcEngineJobTypes: new Set<ModuleType>(),
        jsWorkerJobTypes: new Set<JsWorkerActionType>(),
      };

      for (const fileJobTypes of allFileJobTypes) {
        if (isFileDescendantOfFolder(fileJobTypes.file, file)) {
          fileJobTypes.calcEngineJobTypes.forEach((type) => result.calcEngineJobTypes.add(type));
          fileJobTypes.jsWorkerJobTypes.forEach((type) => result.jsWorkerJobTypes.add(type));
        }
      }

      return result;
    } else {
      const fileHash = getFileHashFromFile(file);
      return allFileJobTypes.find((state) => state.fileHash === fileHash);
    }
  }

  public static jsWorkerActionTypeToString(actionType: JsWorkerActionType): string {
    switch (actionType) {
      case JsWorkerActionType.VacuumDb:
        return 'Vacuum Db';

      case JsWorkerActionType.UpdateImportDataRows:
        return 'Update Column';

      case JsWorkerActionType.DeleteImportDataFile:
        return 'Delete File';

      case JsWorkerActionType.DeleteImportDataColumns:
        return 'Delete Column';

      case JsWorkerActionType.CalculateEquation:
        return 'Calculate Equation';

      case JsWorkerActionType.ConvertExportDataUnits:
        return 'Export Data';

      case JsWorkerActionType.RunDbMigration:
        return 'Migration';

      case JsWorkerActionType.RunTestCommand:
        return 'Other';
    }
  }
}
