import { DictionaryWithArray, IDictionaryWithArray } from '../../common/state.helpers';
import { ITableState } from '../../common/common-grid.interfaces';
import { PSD } from './model/psd/psd';
import { PsdReading } from './model/psd-readings/psd-reading';
import { IModuleState, IValidatedModuleState } from '../../common/common-state.interfaces';
import { PsdSandProductionInputs } from './dto/psd-sand-production.inputs';
import { PSDAnalysis } from './model/psd-analysis/psd-analysis';
import { PSDInputType } from './dto/psd.dto';

export interface PSDAnalysisModuleState extends IModuleState {
  PSDAnalysis: PsdSandProductionInputs;
  PSD: IDictionaryWithArray<PSD>;
  PSDReadings: IDictionaryWithArray<ITableState<PsdReading>>;
  SelectedPSDId: number;
  LastInputType: PSDInputType;
}

export interface ValidatedPSDAnalysisModuleState extends PSDAnalysisModuleState, IValidatedModuleState<PSDAnalysisModuleState> {
  PSDAnalysis: PSDAnalysis;
}

export const psdAnalysisInitialState: PSDAnalysisModuleState = {
  PSDAnalysis: {
    IntervalLength: 0,
    IsCalculateSandProduction: false,
    SandDensity: 0,
    ScenarioId: -1,
    ScreenFilterOD: 0,
    ScreenHandlingLength: 0,
    ScreenJointLength: 0,
    ScreenSlotSize: 0,
    ScreenWireWidth: 0,
  },
  PSD: DictionaryWithArray.clear(),
  PSDReadings: DictionaryWithArray.clear(),
  SelectedPSDId: -1,
  isLoaded: false,
  LastInputType: PSDInputType.Weight,
};
