import { createTableState, ITableState } from '../../../common/common-grid.interfaces';
import { InjectionTestReading } from './model/injection-test-reading';
import { InjectionTestCalculatorDto } from './injection-test-calculator.dto';
import { IModuleState, IValidatedModuleState } from '../../../common/common-state.interfaces';

export interface InjectionTestCalculatorModuleState extends IModuleState {
  InjectionTestCalculator: InjectionTestCalculatorDto;
  InjectionTestReadings: ITableState<InjectionTestReading>;
}

export const InjectionTestCalculatorInitialState: InjectionTestCalculatorModuleState = {
  InjectionTestCalculator: {
    AboveFracIntercept: 0,
    AboveFracSlope: 0,
    BelowFracIntercept: 0,
    BelowFracSlope: 0,
    ClosurePressure: 0,
    ExtensionPressure: 0,
    ExtensionRate: 0,
    MarkerPosition: 0,
    ReservoirPressure: 0,
    ScenarioId: -1,
  },
  InjectionTestReadings: createTableState([]),
  isLoaded: false,
};

export type IValidatedInjectionTestCalculatorModuleState = InjectionTestCalculatorModuleState &
  IValidatedModuleState<InjectionTestCalculatorModuleState>;
