import { IAppTargetConfig } from '../common/app-target-config';
import { LicenseFeature } from '../modules/licensing/licensing.interfaces';

export const packProTargetConfig: IAppTargetConfig = {
  appName: 'PackPro',
  appCode: 'pack-pro',
  demoFolder: 'packpro',
  fileTypeExtension: 'ppf',
  fileTypeName: 'PackPro Project',
  documentationFolder: 'help-files/__packpro/documentation/',
  calcEngineLocation: 'packpro/PackPro.Worker',
  availableModules: [
    LicenseFeature.Simulate,
    LicenseFeature.Simulate_CH,
    LicenseFeature.Evaluate,
    LicenseFeature.PSD_Analysis,
    LicenseFeature.Trend_Analysis,
    LicenseFeature.Calculators,
    LicenseFeature.Data_Analysis,
  ],
  appFolderName: '',
};
