import { CrosshairDataset, CrosshairPlugin, MouseHandler } from './types';
import { Chart, Point } from 'chart.js';
import { LineChartDataSet } from '@dunefront/common/modules/reporting/dto/chart-data.dto';
import { CrosshairPluginId } from './constants';
import { isScatterSeries } from '../chart-component-helpers/chart-misc-helpers';
import {
  datasetVisibleAndInterpolated,
  getCrosshairPluginOptions,
  getCrosshairState,
  getInterpolatedValue,
  getScatterPointsToSnap,
  getXScale,
  getYScale,
  rotatePoint,
  ScatterPointsToSnap,
  setCrosshairInitialState,
} from './helpers';
import { CrosshairMode } from '@dunefront/common/modules/reporting/reporting.settings';

const onMouseMove = (chart: Chart, event: MouseEvent): void => {
  const state = getCrosshairState(chart);
  const options = getCrosshairPluginOptions(chart);

  const isRotated = options.isChartRotated;

  const eventPx = isRotated ? event.offsetY : event.offsetX;
  const argumentScale = isRotated ? getYScale(chart) : getXScale(chart);
  if (eventPx == null || argumentScale == null) {
    return;
  }

  // pixel positions of min and max values on argument axes
  // if axes is reversed or rotated (but not both) then: argAxesMinValuePx > argAxesMaxValuePx
  const argAxesMinValuePx = argumentScale.getPixelForValue(argumentScale.min);
  const argAxesMaxValuePx = argumentScale.getPixelForValue(argumentScale.max);

  const minPx = Math.min(argAxesMinValuePx, argAxesMaxValuePx);
  const maxPx = Math.max(argAxesMinValuePx, argAxesMaxValuePx);

  const isInAxisRange = eventPx >= minPx && eventPx <= maxPx;

  const argumentPx = isInAxisRange ? eventPx : undefined;
  const valueChanged = state.argumentPx !== argumentPx;

  state.argumentPx = argumentPx;

  if (options.enabled && valueChanged && chart?.canvas != null) {
    chart.draw();
  }
};

const onMouseOut = (chart: Chart): void => {
  const state = getCrosshairState(chart);
  state.snapArgumentPx = undefined;
  state.argumentPx = undefined;

  if (chart?.canvas != null) {
    chart.draw();
  }
};

export const crosshairPlugin: CrosshairPlugin = {
  id: CrosshairPluginId,

  afterInit(chart: any) {
    // set initial state
    setCrosshairInitialState(chart);

    // add mouse event handlers
    addMouseHandler(chart, 'mousemove', onMouseMove);
    addMouseHandler(chart, 'mouseout', onMouseOut);
  },

  afterDraw: function (chart: any) {
    const state = getCrosshairState(chart);

    const { isChartRotated: isRotated, enabled } = getCrosshairPluginOptions(chart);
    if (!enabled) {
      return;
    }

    const scatterPointsToSnap = getScatterPointsToSnap(state.argumentPx, chart, isRotated);

    const argumentPx = scatterPointsToSnap[0]?.argPx ?? state.snapArgumentPx ?? state.argumentPx;
    if (argumentPx == null) {
      return;
    }

    drawTraceLine(argumentPx, chart);
    drawTracePoints(argumentPx, chart, scatterPointsToSnap);
  },

  afterDestroy(chart: Chart) {
    removeEventListeners(chart);
  },
};

const removeEventListeners = (chart: Chart): void => {
  const { handlers } = getCrosshairState(chart);

  for (const type in handlers) {
    const handler = handlers[type];
    if (handler == null) {
      continue;
    }
    chart?.canvas?.removeEventListener(type, handler);
  }
};

const drawTraceLine = (argumentPx: number, chart: Chart): any => {
  const { isChartRotated: isRotated, lineStyle, crosshairContext } = getCrosshairPluginOptions(chart);

  if (crosshairContext.crosshairMode === CrosshairMode.NONE) {
    return;
  }

  const valueScale = isRotated ? getXScale(chart) : getYScale(chart);
  if (valueScale == null) {
    return;
  }

  let startPoint: Point = {
    x: argumentPx,
    y: valueScale.getPixelForValue(valueScale.max),
  };
  let endPoint: Point = {
    x: argumentPx,
    y: valueScale.getPixelForValue(valueScale.min),
  };

  if (isRotated) {
    startPoint = rotatePoint(startPoint);
    endPoint = rotatePoint(endPoint);
  }

  const { color, dashPattern, width: lineWidth } = lineStyle;

  chart.ctx.beginPath();
  chart.ctx.setLineDash(dashPattern);
  chart.ctx.moveTo(startPoint.x, startPoint.y);
  chart.ctx.lineWidth = lineWidth;
  chart.ctx.strokeStyle = color;
  chart.ctx.lineTo(endPoint.x, endPoint.y);
  chart.ctx.stroke();
  chart.ctx.setLineDash([]);
};

const drawTracePoints = (argumentPx: number, chart: Chart, scatterPointsToSnap: ScatterPointsToSnap): any => {
  const { isChartRotated: isRotated, crosshairContext } = getCrosshairPluginOptions(chart);

  if (crosshairContext.crosshairMode === CrosshairMode.NONE) {
    return;
  }
  const closestDataSetIndex = crosshairContext.closestDataSetIndex;

  // reverse datasets so the closest trace point will be drawn as last ( so it's on top, when there are more very close points )
  const datasetsToDisplay = [...crosshairContext.sortedAndTrimmedDataSets].reverse();

  for (const { dataSetIndex } of datasetsToDisplay) {
    if (crosshairContext.crosshairMode === CrosshairMode.SINGLE && dataSetIndex !== closestDataSetIndex) {
      continue;
    }

    if (!datasetVisibleAndInterpolated(chart, dataSetIndex)) {
      continue;
    }

    const dataset = chart.data.datasets[dataSetIndex] as LineChartDataSet & CrosshairDataset;
    if (dataset == null) {
      continue;
    }

    const scatterSnapPoint = scatterPointsToSnap.find((p) => p.datasetIndex === dataSetIndex);
    if (isScatterSeries(dataset) && scatterSnapPoint == null) {
      continue;
    }

    // do not work with series without valid axis ids
    if (dataset?.xAxisID == null || dataset.yAxisID == null) {
      continue;
    }

    const valuePx = getInterpolatedValue(argumentPx, dataset, chart, isRotated);
    if (valuePx == null) {
      continue;
    }

    const valueAxisId = isRotated ? dataset.xAxisID : dataset.yAxisID;
    const valueScale = chart.scales[valueAxisId];
    const valueOnScale = valueScale.getValueForPixel(valuePx);
    if (valueOnScale == null || valueOnScale < valueScale.min || valueOnScale > valueScale.max) {
      continue;
    }

    let point: Point = {
      x: argumentPx,
      y: valuePx,
    };

    if (isRotated) {
      point = rotatePoint(point);
    }

    chart.ctx.beginPath();
    chart.ctx.arc(point.x, point.y, 3, 0, 2 * Math.PI, false);
    chart.ctx.fillStyle = 'white';
    chart.ctx.lineWidth = 2;
    chart.ctx.strokeStyle = dataset.borderColor as any;
    chart.ctx.fill();
    chart.ctx.stroke();
  }
};

const addMouseHandler = (chart: Chart, type: string, handler: MouseHandler): void => {
  const state = getCrosshairState(chart);

  const eventListener = (event: Event): void => handler(chart, event as MouseEvent);

  chart.canvas.addEventListener(type, eventListener);
  state.handlers[type] = eventListener;
};
