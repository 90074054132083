import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { flatArrayToSelectObject, MultiselectItem } from '@dunefront/common/common/select.helpers';
import { PREDEFINED_RANGE_LABELS } from '@dunefront/common/common/constants';

export const noLabel = 'No Label';

@Component({
  selector: 'app-labels-selector',
  templateUrl: './labels-selector.component.html',
})
export class LabelsSelectorComponent implements OnInit {
  @Input() public isDisabled = false;
  @Input() public class = '';
  @Input() public componentWidth = 180;
  @Input() public dataCy = 'range-labels-multiselect';
  @Input() public showNoLabel = false;

  @Input() public set rangeLabels(labels: string | undefined) {
    this.selectedLabels = labels != null && labels.length ? labels.split(',') : [];
  }

  @Output() public labelsUpdated = new EventEmitter<string>();

  public items: MultiselectItem[] = [];
  public selectedLabels: string[] = [];

  public ngOnInit(): void {
    this.items = flatArrayToSelectObject([...(this.showNoLabel ? [noLabel] : []), ...PREDEFINED_RANGE_LABELS]);
  }

  public get tooltipText(): string {
    if (!this.isDisabled) {
      return '';
    }

    return this.selectedLabels.join('<br/>');
  }

  public onLabelsUpdated(): void {
    this.labelsUpdated.emit(this.selectedLabels.join(','));
  }
}
