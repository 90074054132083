import { LoadRangesActionResponse } from '@dunefront/common/modules/range/range-module.actions';
import { initialRangeModuleState, RangeModuleState } from '@dunefront/common/modules/range/range-module.state';
import { DictionaryWithArray } from '@dunefront/common/common/state.helpers';
import { CrudResponse } from '@dunefront/common/modules/common.actions';
import { RangeConstants } from '@dunefront/common/dto/range.dto';
import { VerticalShiftDto } from '@dunefront/common/dto/trend-analysis/vertical-shift.dto';
import { RangeFactory } from '@dunefront/common/modules/range/model/range.factory';
import { ActionResponse } from '@dunefront/common/response-ws.action';
import { Scenario } from '@dunefront/common/modules/scenario/scenario';

export class RangeReducerHelper {
  public static onLoadScenarioDataSuccessAction(
    state: RangeModuleState,
    response: ActionResponse<LoadRangesActionResponse>,
    rangeId: number,
  ): RangeModuleState {
    if (response.status !== 'ok' || !response.payload) {
      return initialRangeModuleState;
    }
    return RangeFactory.create(response.payload, rangeId);
  }

  public static onUpdateScenarios(
    state: RangeModuleState,
    action: { scenarios: Scenario[]; changedKey: keyof Scenario },
  ): RangeModuleState {
    if (action.changedKey !== 'CurrentRangeId') {
      return state;
    }

    if (action.scenarios.length !== 1) {
      return state;
    }

    return {
      ...state,
      currentRangeId: action.scenarios[0].CurrentRangeId,
    };
  }

  public static onEditRange(state: RangeModuleState): RangeModuleState {
    return {
      ...state,
      editingDto: DictionaryWithArray.get(state.ranges, state.currentRangeId ?? RangeConstants.EntireRangeId),
    };
  }

  public static onNewRange(state: RangeModuleState): RangeModuleState {
    const newRange = RangeFactory.newRange();
    return {
      ...state,
      editingDto: newRange,
    };
  }

  public static onUpdateRowSuccess(state: RangeModuleState, response: CrudResponse): RangeModuleState {
    if (!response.affectedRows.range && !response.affectedRows.verticalShift && !response.affectedRows.scenarioRangeProperties) {
      return state;
    }

    if (response.affectedRows.range) {
      return {
        ...state,
        ranges: DictionaryWithArray.upsertById(state.ranges, response.affectedRows.range.rows[0], response.affectedRows.range.rows[0].Id),
        editingDto: undefined,
      };
    }

    if (response.affectedRows.verticalShift) {
      let verticalShifts = { ...state.verticalShifts };

      response.affectedRows.verticalShift.rows.forEach((vs) => {
        verticalShifts = DictionaryWithArray.upsertById(verticalShifts, vs, vs.Id);
      });

      return {
        ...state,
        verticalShifts,
      };
    }

    if (response.affectedRows.scenarioRangeProperties) {
      let scenarioRangeProperties = { ...state.scenarioRangeProperties };
      for (const scenarioRangeProperty of response.affectedRows.scenarioRangeProperties.rows) {
        scenarioRangeProperties = DictionaryWithArray.upsertById(
          scenarioRangeProperties,
          scenarioRangeProperty,
          scenarioRangeProperty.RangeId,
        );
      }

      return {
        ...state,
        scenarioRangeProperties,
      };
    }

    return state;
  }

  public static onInsertRowsSuccess(state: RangeModuleState, response: CrudResponse): RangeModuleState {
    if (!response.affectedRows.range && !response.affectedRows.verticalShift && !response.affectedRows.scenarioRangeProperties) {
      return state;
    }

    let newState = { ...state };

    if (response.affectedRows.range) {
      const deletedRangeIds = response.affectedRows.range.deletedIds;
      if (deletedRangeIds.length) {
        newState = {
          ...newState,
          ranges: DictionaryWithArray.deleteItems(state.ranges, deletedRangeIds),
          scenarioRangeProperties: DictionaryWithArray.deleteItems(state.scenarioRangeProperties, deletedRangeIds),
          verticalShifts: DictionaryWithArray.deleteItems(state.verticalShifts, deletedRangeIds),
          // set 'Entire Range' if there is no other ranges
          currentRangeId: RangeConstants.EntireRangeId,
          editingDto: undefined,
        };
      } else {
        const newRange = response.affectedRows.range.rows[0];
        newState = {
          ...newState,
          ranges: DictionaryWithArray.upsert(newState.ranges, newRange, 'Id'),
          // set 'Entire Range' if there is no other ranges
          // update current range id, only when success was triggered from normal crud response
          currentRangeId: response.isTriggeredFromUiUpdate ? newState.currentRangeId : newRange.Id,
          editingDto: undefined,
        };
      }
    }

    if (response.affectedRows.verticalShift) {
      newState = {
        ...newState,
        verticalShifts: DictionaryWithArray.create(response.affectedRows.verticalShift.rows, 'Id'),
      };
    }

    if (response.affectedRows.scenarioRangeProperties) {
      const newScenarioRangeProperties = response.affectedRows.scenarioRangeProperties.rows[0];
      newState = {
        ...newState,
        scenarioRangeProperties: DictionaryWithArray.upsert(newState.scenarioRangeProperties, newScenarioRangeProperties, 'RangeId'),
      };
    }

    return newState;
  }

  public static onDeleteRowsSuccess(state: RangeModuleState, response: CrudResponse): RangeModuleState {
    const { range, verticalShift, deleteRangeData } = response.affectedRows;
    if (!range && !verticalShift && !deleteRangeData) {
      return state;
    }

    if (range?.replace === 'all') {
      return RangeFactory.create(
        {
          ranges: [],
          verticalShifts: [],
          scenarioRangeProperties: [],
        },
        RangeConstants.EntireRangeId,
      );
    }

    let newState = state;

    if (range) {
      newState = {
        ...newState,
        ranges: DictionaryWithArray.deleteItems(newState.ranges, range.deletedIds),
        currentRangeId: RangeConstants.EntireRangeId,
        editingDto: undefined,
      };
    }

    if (verticalShift) {
      const dictWithArrayAfterDelete = DictionaryWithArray.deleteItems(newState.verticalShifts, verticalShift.deletedIds);

      newState = {
        ...newState,
        verticalShifts: dictWithArrayAfterDelete,
      };
    }

    if (deleteRangeData) {
      newState = {
        ...newState,
        ranges: DictionaryWithArray.create([RangeFactory.DefaultRange, ...deleteRangeData.ranges], 'Id'),
        currentRangeId: RangeConstants.EntireRangeId,
        editingDto: undefined,
      };
    }

    return newState;
  }

  public static upsertVerticalShiftDtosAction(state: RangeModuleState, verticalShiftDtos: VerticalShiftDto[]): RangeModuleState {
    let verticalShifts = state.verticalShifts;
    verticalShiftDtos.forEach((verticalShift) => (verticalShifts = DictionaryWithArray.upsert(verticalShifts, verticalShift, 'Id')));

    return {
      ...state,
      verticalShifts,
    };
  }
}
