import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { Pumping } from '@dunefront/common/modules/pumping/model/pumping/pumping';
import { ScheduleOptionsValidation } from '@dunefront/common/modules/pumping/model/schedule-options/schedule-options.validation';
import { ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';
import { createSelector, Store } from '@ngrx/store';
import {
  getAreCurrentResultsPresent,
  getCurrentResults,
} from '../../../../../+store/calculation-engine/calculation-engine-results.selectors';
import {
  copyCalcEngineGeneratedPumpingScheduleAction,
  copyCalcEngineGeneratedRunningStringAction,
} from '../../../../../+store/pumping/pumping.actions';
import { RouterHelperService } from '../../../../../shared/services/router-helper.service';
import { EnumHelpers } from '@dunefront/common/utils/enum.helpers';
import { GaugeMeasurementType } from '@dunefront/common/dto/running-string-pipe.dto';

@Component({
  selector: 'app-automatic-scheduling',
  templateUrl: './automatic-scheduling.component.html',
  styleUrls: ['./automatic-scheduling.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutomaticSchedulingComponent {
  @Input() public pumping!: Pumping;

  @Output() public dataChange = new EventEmitter<ObjectChangeProp<Pumping>>();

  public referenceLocationOptions = EnumHelpers.EnumToISelectItemArray(GaugeMeasurementType).filter(
    (opt) => opt.value === GaugeMeasurementType.External || opt.value === GaugeMeasurementType.Internal,
  );

  public UnitType = UnitSystem;

  public copyButtonsDisabled$ = this.store.select(copyButtonsDisabled);
  public hasResults$ = this.store.select(getAreCurrentResultsPresent);

  constructor(
    private store: Store,
    private routerHelperService: RouterHelperService,
  ) {}

  public onValueChange(args: ObjectChangeProp<Pumping>): void {
    if (this.pumping[args.key] !== args.value) {
      this.dataChange.emit(args);
    }
  }

  public async onCopyGeneratedScheduleClick(): Promise<void> {
    await this.routerHelperService.navigateToPumpingSchedule();
    this.store.dispatch(copyCalcEngineGeneratedPumpingScheduleAction());
  }

  public async onCopyGeneratedRunningStringClick(): Promise<void> {
    await this.routerHelperService.navigateToRunningString();
    this.store.dispatch(copyCalcEngineGeneratedRunningStringAction());
  }

  public get isTxtAutoFracSafetyMarginEnabled(): boolean {
    return ScheduleOptionsValidation.isTxtAutoFracSafetyMarginEnabled(this.pumping);
  }

  public get isTxtAutoScheduleRateStepEnabled(): boolean {
    return ScheduleOptionsValidation.isTxtAutoScheduleRateStepEnabled(this.pumping);
  }

  public get isTxtAutoPressureAttenuatorActivationEnabled(): boolean {
    return ScheduleOptionsValidation.isTxtAutoPressureAttenuatorActivationEnabled(this.pumping);
  }

  public get isTxtAutoAlphaSafetyMarginEnabled(): boolean {
    return ScheduleOptionsValidation.isTxtAutoAlphaSafetyMarginEnabled(this.pumping);
  }

  public get isTxtAutoScheduleMinRateEnabled(): boolean {
    return ScheduleOptionsValidation.isTxtAutoScheduleMinRateEnabled(this.pumping);
  }
}

const copyButtonsDisabled = createSelector(getCurrentResults, (result) => ({
  hasCalcEngineGeneratedScheduleDisabled: !result || !result.hasCalcEngineGeneratedSchedule,
  hasCalcEngineGeneratedRunningStringDisabled: !result || !result.hasCalcEngineGeneratedRunningString,
}));
