import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { ModalService } from '../modal.service';
import { compareScenariosChangeConfigAction } from '../../../+store/scenario/scenario.actions';
import { getCompletedResultsForCurrentModule } from '../../../+store/calculation-engine/calculation-engine-results.selectors';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { createSelector, Store } from '@ngrx/store';
import { defaultModalCancelButton, modalButton, ModalButtonConfig } from '../generic-modal/generic-modal.component';
import { ScenarioConstants } from '@dunefront/common/modules/scenario/scenario.dto';
import { getAllScenarios, getCurrentScenario } from '../../../+store/scenario/scenario.selectors';
import { observableToBehaviorSubject } from '@dunefront/common/common/state.helpers';
import { firstValueFrom } from 'rxjs';
import { runMultipleSimulationsForCompareScenario } from '../../../+store/calculation-engine/calculation-engine.actions';
import { uniq } from 'lodash';

@Component({
  selector: 'app-compare-scenario',
  template: ` <app-generic-modal [title]="modalTitle" [modalButtonsConfigs]="modalButtonsConfigs">
    <ng-container *ngIf="state$ | async as vm">
      <app-scenario-manager-checkboxes
        [selectedIds]="vm.selectedScenarioIds"
        [scenarioIdsWithResults]="vm.scenarioIdsWithResults"
        [labelWidth]="390"
        [disableDefaultValue]="true"
        (valueChanged)="updateSelectedIds($event)"
        [errorMessages]="vm.errorMessages"
        [showResults]="true"
      >
      </app-scenario-manager-checkboxes>

      <p>Simulations for scenarios without results will be run automatically before comparing</p>
    </ng-container>
  </app-generic-modal>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompareScenarioComponent {
  public modalTitle = 'Compare Scenario';

  public modalButtonsConfigs: ModalButtonConfig[] = [
    modalButton('Clear All', (): void => this.clearSelection(), 'compare-scenario-clear-all'),
    defaultModalCancelButton(() => this.cancelClicked()),
    modalButton('Compare', (): Promise<void> => this.compareClicked(), 'compare-scenario-compare-btn'),
  ];

  public state$ = observableToBehaviorSubject(this.store.select(getScenarioStateWithResultsInfo), {
    currentScenarioId: ScenarioConstants.EmptyScenarioId,
    scenarioIdsWithResults: [],
    errorMessages: new Map<number, string>(),
    selectedScenarioIds: [],
  });

  constructor(
    protected store: Store,
    protected cdRef: ChangeDetectorRef,
    protected modalService: ModalService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
  ) {}

  public async cancelClicked(): Promise<void> {
    this.ref.close();
  }

  public async compareClicked(): Promise<void> {
    const state = await firstValueFrom(this.state$);
    if (state.selectedScenarioIds.length > 10) {
      await this.modalService.showAlert('You can compare a maximum of 10 scenarios.', 'Information');
      return;
    }

    if (state.selectedScenarioIds.filter((scenarioId) => scenarioId !== state.currentScenarioId).length === 0) {
      await this.modalService.showAlert('Select at least one other scenario for comparison.', 'Information');
      return;
    }

    if (state.errorMessages.size) {
      await this.modalService.showAlert(`Selected scenario(s) doesn't have results`, 'Information');
      return;
    }

    const scenariosWithoutResults = state.selectedScenarioIds.filter(
      (selectedScenario) => !state.scenarioIdsWithResults.includes(selectedScenario),
    );
    if (scenariosWithoutResults.length > 0) {
      this.store.dispatch(runMultipleSimulationsForCompareScenario({ scenarioIds: scenariosWithoutResults }));
    }

    const scenarioIds = state.selectedScenarioIds.filter((scenarioId) => scenarioId !== state.currentScenarioId);
    this.store.dispatch(
      compareScenariosChangeConfigAction({
        batchAction: this.config.data.batchAction,
        scenarioIds,
      }),
    );
    await this.cancelClicked();
  }

  public updateSelectedIds(selectId: { id: number; value: boolean }): void {
    const state = this.state$.value;
    if (selectId.value) {
      this.state$.next({ ...state, selectedScenarioIds: [...state.selectedScenarioIds, selectId.id] });
    } else {
      this.state$.next({
        ...state,
        selectedScenarioIds: state.selectedScenarioIds.filter((sid) => sid !== selectId.id),
      });
    }
  }

  public clearSelection(): void {
    const state = this.state$.value;
    this.state$.next({ ...state, selectedScenarioIds: [state.currentScenarioId] });
  }
}

export interface ICompareScenarioVM {
  currentScenarioId: number;
  scenarioIdsWithResults: number[];
  selectedScenarioIds: number[];
  errorMessages: Map<number, string>;
}

export const getScenarioStateWithResultsInfo = createSelector(
  getAllScenarios,
  getCurrentScenario,
  getCompletedResultsForCurrentModule,
  (allScenarios, currentScenario, completedResults) => {
    const currentScenarioId = currentScenario?.Id ?? ScenarioConstants.EmptyScenarioId;
    const vm: ICompareScenarioVM = {
      currentScenarioId,
      scenarioIdsWithResults: completedResults.map((r) => r.scenarioId),
      selectedScenarioIds: [currentScenarioId],
      errorMessages: new Map<number, string>(),
    };

    if (!currentScenario) {
      return vm;
    }

    vm.selectedScenarioIds = uniq([currentScenarioId, ...currentScenario.CompareScenarioIds]).filter((scenarioId) =>
      allScenarios.map((scenario) => scenario.Id).includes(scenarioId),
    );

    return vm;
  },
);
