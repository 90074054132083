import { Dictionary } from 'lodash';
import { IJsWorkerJobInfo } from '@dunefront/common/modules/calculation-engine/calculation-engine.interfaces';

export interface JsWorkerJobsState {
  fileHash: Dictionary<IFileJsWorkerJobsState>;
}

export interface IFileJsWorkerJobsState {
  activeJobId?: string;
  jobs: IJsWorkerJobInfo[];
}

export const jsWorkerJobsInitialState: JsWorkerJobsState = {
  fileHash: {},
};

export const getEmptyFileJsWorkerJobsState = (): IFileJsWorkerJobsState => ({
  activeJobId: undefined,
  jobs: [],
});
