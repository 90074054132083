import { createSelector } from '@ngrx/store';
import { getValidatedDataAnalysisComponentSelectItems } from '../pumping/selectors/evaluate-pumping-schedule-analysis-data-component.selectors';
import { getMaxImportColumnGaugeDepth } from '../data-storage/data-storage.selectors';
import { RangeValidation } from '@dunefront/common/modules/range/model/range.validation';
import { getRangeModuleState } from '../range/range.selectors';
import { getCurrentScenarioId } from '../scenario/scenario.selectors';

export const getValidatedTrendAnalysisRange = createSelector(
  getCurrentScenarioId,
  getRangeModuleState,
  getMaxImportColumnGaugeDepth,
  (...[currentScenarioId, rangeModuleState, maxImportColumnGaugeDepth]) =>
    RangeValidation.validate(currentScenarioId, rangeModuleState, maxImportColumnGaugeDepth),
);

export const getTrendAnalysisPageValidation = createSelector(
  getValidatedDataAnalysisComponentSelectItems,
  getValidatedTrendAnalysisRange,
  (validatedPumpRateAndPressure, validatedTrendAnalysisRange): ITrendAnalysisMenuValidationResult => ({
    isTrendAnalysisValid: validatedPumpRateAndPressure.isValid && validatedTrendAnalysisRange.isValid,
  }),
);

export interface ITrendAnalysisMenuValidationResult {
  isTrendAnalysisValid: boolean;
}
