import { getEmptyFileJsWorkerJobsState, IFileJsWorkerJobsState, JsWorkerJobsState } from './js-worker-jobs-state';
import { UserJobs } from '@dunefront/common/modules/calculation-engine/calculation-engine.actions';
import { uniq } from 'lodash';

export class JsWorkerJobsReducerHelper {
  public static onUserJobsUpdatedAction(state: JsWorkerJobsState, userJobs: UserJobs): JsWorkerJobsState {
    let updateState = { ...state };
    const waitingJobs = userJobs.jsWorkerJobs.waitingJobs;
    const activeJobs = userJobs.jsWorkerJobs.activeJobs;
    const allJobs = [...waitingJobs, ...activeJobs];
    const uniqueFileHashes = uniq(allJobs.map((job) => job.fileHash));

    for (const fileHash of uniqueFileHashes) {
      const fileState = this.getCurrentFileState(state, fileHash);
      const jobs = allJobs.filter((job) => job.fileHash === fileHash);
      const activeJobId = activeJobs[0]?.id;

      updateState = {
        ...updateState,
        fileHash: {
          ...updateState.fileHash,
          [fileHash]: { ...fileState, jobs, activeJobId },
        },
      };
    }

    // remove old jobs present in local files, but not present in update
    // updates only files not present in uniqueFileHashes
    for (const fileHash in state.fileHash) {
      if (!uniqueFileHashes.includes(fileHash)) {
        const fileState = this.getCurrentFileState(state, fileHash);
        updateState = {
          ...updateState,
          fileHash: {
            ...updateState.fileHash,
            [fileHash]: { ...fileState, jobs: [] },
          },
        };
      }
    }

    return updateState;
  }

  public static onProgressUpdateAction(state: JsWorkerJobsState, fileHash: string, taskId: string, progress: number): JsWorkerJobsState {
    if (progress === 100 && state.fileHash[fileHash]?.activeJobId === taskId) {
      const fileState = this.getCurrentFileState(state, fileHash);

      return {
        ...state,
        fileHash: {
          ...state.fileHash,
          [fileHash]: { ...fileState, activeJobId: undefined, jobs: fileState.jobs.filter((job) => job.id !== taskId) },
        },
      };
    } else {
      return state;
    }
  }

  private static getCurrentFileState(state: JsWorkerJobsState, fileHash: string): IFileJsWorkerJobsState {
    let currentFileState = state.fileHash[fileHash];
    if (currentFileState === undefined) {
      currentFileState = getEmptyFileJsWorkerJobsState();
    }
    return currentFileState;
  }
}
