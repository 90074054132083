import { CommonModule } from '@angular/common';
import { Component, HostListener, Inject, NgModule } from '@angular/core';
import { Store } from '@ngrx/store';
import { getUser } from '../../../+store/auth/auth.selectors';
import { ContextMenuModule } from 'primeng/contextmenu';
import { MenuItem } from 'primeng/api';
import { MenuModule } from 'primeng/menu';
import { getBackendConnectionState, getConnectedFile } from '../../../+store/backend-connection/backend-connection.selectors';
import { ConnectionStatus } from '../../../+store/backend-connection/backend-connection.reducer';
import { AboutModalComponent } from '../../../common-modules/help/about/about.modal.component';
import { ModalService } from '../../../common-modules/modals/modal.service';
import { isElectron } from '@dunefront/common/common/electron/is-electron';
import { LicenseExpirationWarningModule } from '../license-expiration-warning/license-expiration-warning.component';
import { ENVIRONMENT } from '../../services/environment';
import { IEnvironment } from '@dunefront/common/common/environment.interface';
import { ClientAuthService } from '../../../common-modules/auth/client-auth.service';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { UserProfileModalComponent } from '../../../common-modules/modals/user-profile/user-profile.modal.component';
import { CalculationQueueComponent } from '../../../pages/common/calculation-queue/components/calculation-queue.component';
import { CalculationQueueModule } from '../../../pages/common/calculation-queue/calculation-queue.module';
import { selectUnreadNotifications } from '../../../+store/ui/ui.selectors';
import { NotificationsModalComponent } from '../../../common-modules/modals/survey/notifications/notifications-modal.component';
import { getUserHasAnyWaitingOrRunningJobs } from '../../../+store/calculation-engine/calculation-engine.selectors';
import { LicensingModalComponent } from '../../../common-modules/help/licensing/licensing-dialog/licensing.modal.component';

@Component({
  selector: 'app-user-panel',
  templateUrl: 'user-panel.component.html',
  styleUrls: ['./user-panel.component.scss'],
})
export class UserPanelComponent {
  public isElectron = isElectron();
  public unreadNotifications$ = this.store.select(selectUnreadNotifications);
  public userHasAnyWaitingOrRunningJobs$ = this.store.select(getUserHasAnyWaitingOrRunningJobs);
  public user$ = this.store.select(getUser);
  public file$ = this.store.select(getConnectedFile);
  public backendConnectionState$ = this.store.select(getBackendConnectionState);
  public ConnectionStatus = ConnectionStatus;
  private isDevMode = false;
  public contextMenuItems: MenuItem[] = [];

  constructor(
    public store: Store,
    public authService: ClientAuthService,
    private modalService: ModalService,
    @Inject(ENVIRONMENT) env: IEnvironment,
  ) {
    this.contextMenuItems = [
      { label: 'Profile', command: (): DynamicDialogRef => this.modalService.open(UserProfileModalComponent, {}) },
      {
        label: 'Logout',
        command: (): Promise<void> => this.authService.logout(),
      },
    ];
  }

  public openNotifications(): void {
    this.modalService.open(NotificationsModalComponent, {});
  }

  public openAbout(): void {
    this.modalService.open(AboutModalComponent, { isDevMode: this.isDevMode }, '', 'lg');
  }

  public openLicensing(): void {
    this.modalService.open(LicensingModalComponent, { isDevMode: this.isDevMode }, '', 'lg');
  }

  public openCalculationQueue(): void {
    this.modalService.open(CalculationQueueComponent, {}, '', 'lg');
  }

  @HostListener('document:keydown.control.shift')
  public onKeysPressed(): void {
    this.isDevMode = true;
  }

  @HostListener('document:keyup')
  public onKeysUp(): void {
    this.isDevMode = false;
  }
}

@NgModule({
  imports: [CommonModule, ContextMenuModule, MenuModule, LicenseExpirationWarningModule, CalculationQueueModule],
  declarations: [UserPanelComponent],
  exports: [UserPanelComponent],
})
export class UserPanelModule {}
