export enum ChartLegendLocation {
  Top = 0,
  Right = 1,
  Bottom = 2,
  Left = 3,
}

export interface ILegendStyle {
  LegendLocation: ChartLegendLocation;
  LegendFontSize: number;
  IsLegendFontItalic: boolean;
  IsLegendFontBold: boolean;
  LegendFontColor: string;
}

export const defaultLegendFontSize = 12;
