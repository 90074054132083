import { UnitSystem } from '../../../../dto/unit-system.dto';
import { DataType } from '../../../../dto/data-storage';
import { GaugeMeasurementType } from '../../../../dto/running-string-pipe.dto';
import { ArgumentType } from '../import-column.dto';
import { IBaseTemplateDto } from '../../../../common/templates/templates.interfaces';

export interface IImportTemplateDto extends IBaseTemplateDto {
  TemplateConfig: IImportTemplateConfiguration;
}

export interface IImportTemplateConfiguration {
  delimiterConfig: ImportDataColumnDelimiterConfig;
  filePropertiesConfig: ImportDataFilePropertiesConfig;
  includedColumnIds: number[];
  importDataType: ImportDataType;
  colConfigs: IColConfig[];
}

export enum ImportFileColumnDelimiter {
  Auto = 0,
  Tab = 1,
  Space = 2,
  Comma = 3,
  Custom = 4,
}

export interface ImportDataColumnDelimiterConfig {
  delimiter: ImportFileColumnDelimiter;
  customDelimiter: string;
}

export interface ImportDataFilePropertiesConfig {
  initialRowsToSkip: number;
  samplingFrequency: number;
}

export enum ImportDataType {
  All_Data = 0,
  Surface_Data = 1,
  Bottomhole_Data = 2,
}

export enum ImportColumnType {
  Time = DataType.Time,
  Pump_Rate = DataType.Pump_Rate,
  Return_Rate = DataType.Return_Rate,
  Surface_Gravel_Concentration = DataType.Surface_Gravel_Concentration,
  Pump_Pressure = DataType.Pump_Pressure,
  Return_Pressure = DataType.Return_Pressure,
  Bottomhole_Pressure = DataType.Bottomhole_Pressure,
  Bottomhole_Temperature = DataType.Bottomhole_Temperature,
  Bit_Depth = DataType.Bit_Depth,
  Block_Height = DataType.Block_Height,
  Hook_Load = DataType.Hook_Load,
  Mud_Weight = DataType.Mud_Weight,
  Other = DataType.Other,
  Liquid_Additive_Concentration = DataType.Liquid_Additive_Concentration,
}

export interface IColConfig {
  name: string;
  originalUnit: string | undefined;
  originalName: string | undefined;
  columnType: ImportColumnType | null;
  measurementType: GaugeMeasurementType | null;
  measuredDepth: number;
  unitSystem: UnitSystem | null;
  unitSymbol: number;
  timeCustomFormat: string | null;
  isTimeCustomFormat: boolean;
  colIndex: number;
  isDeltaTimePreviewColumn: boolean;
  isXAxis: ArgumentType;
  possibleCustomFormats: string[];
}

export const filterWithoutDeltaTime = (colConfig: IColConfig): boolean => !colConfig.isDeltaTimePreviewColumn;
