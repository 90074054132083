import { NgModule } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { AppTreeComponent } from './app-tree.component';
import { TreeModule } from 'primeng/tree';
import { CheckBoxModule } from '../form-components/check-box/check-box.component';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [CommonModule, TreeModule, ButtonModule, CheckBoxModule],
  declarations: [AppTreeComponent],
  exports: [AppTreeComponent],
  providers: [],
})
export class AppTreeModule {}
