import { defaultFluidColours, FluidDto, FluidGravelDatabaseType, FluidType, FluidWithDependenciesDto } from '../dto/fluid.dto';
import { Fluid } from './fluid';
import { DictionaryWithArray, IDictionaryWithArray } from '../../../common/state.helpers';
import { RheologyDto, RheologyType } from '../dto/rheology/rheology.dto';
import { RheometerDto, RheometerReadingDto, RheometerTestType } from '../dto/rheometer.dto';
import { ISelectItem } from '../../../common/select.helpers';
import { LoadFluidsActionResponse } from '../fluid-module.actions';
import { FluidModuleState } from '../fluid-module.state';
import { RheologyFactory } from './rheology/rheology.factory';
import { RheometerFactory } from './rheometer/rheometer.factory';
import { RheometerReadingFactory } from './rheometer-reading/rheometer-reading.factory';
import { RheologyClientCalculations } from './rheology/rheology-client.calculations';
import { Dictionary } from 'lodash';
import { RheometerReading } from './rheometer-reading/rheometer-reading';
import { getRowsForCalculations } from '../../../common/common-grid.interfaces';
import { Color } from '../../reporting/dto/chart.types';

export class FluidFactory {
  public static create(payload: LoadFluidsActionResponse, shearRate: number): FluidModuleState {
    const selectedFluidId = payload.fluidDtos[0].Id;
    const fluids = FluidFactory.createDictionaryWithArray(payload.fluidDtos);
    const rheologies = RheologyFactory.create(payload.rheologyDtos, fluids.ids, payload.scenarioId);
    const rheometers = RheometerFactory.create(payload.rheometerDtos, fluids.ids);
    const selectedTemperatureId = rheometers.dict[selectedFluidId]?.rows[0]?.rowData.Id ?? -1;
    const rheometerReadings = RheometerReadingFactory.create(payload.rheometerReadingDtos);

    const fluidState: FluidModuleState = {
      SelectedFluidId: selectedFluidId,
      SelectedTemperatureId: selectedTemperatureId,
      Rheologies: rheologies,
      Fluids: fluids,
      Rheometers: rheometers,
      RheometerReadings: rheometerReadings,
      isLoaded: true,
    };

    return RheologyClientCalculations.recalculateRheologies(fluidState, shearRate);
  }

  public static createDictionaryWithArray(fluidDtos: FluidDto[]): IDictionaryWithArray<Fluid> {
    const fluids = fluidDtos.map((fluidDto) => this.createFluid(fluidDto));
    return DictionaryWithArray.create(fluids, 'Id');
  }

  public static createFluid(fluidDto: FluidDto): Fluid {
    return {
      ...fluidDto,
      error: {},
      isValid: true,
    };
  }

  public static fluidToDto(fluid: Fluid): FluidDto {
    return {
      ScenarioId: fluid.ScenarioId,
      Id: fluid.Id,
      CleanFluidDensity: fluid.CleanFluidDensity,
      DragReductionFactor: fluid.DragReductionFactor,
      Name: fluid.Name,
      Type: fluid.Type,
      DatabaseType: fluid.DatabaseType,
      RheologyType: fluid.RheologyType,
      IsGravelSettling: fluid.IsGravelSettling,
      IsYieldStress: fluid.IsYieldStress,
      IsMaxViscosity: fluid.IsMaxViscosity,
      IsMinViscosity: fluid.IsMinViscosity,
      MaxViscosity: fluid.MaxViscosity,
      MinViscosity: fluid.MinViscosity,
      RheometerTestType: fluid.RheometerTestType,
      ThermalConductivity: fluid.ThermalConductivity,
      SpecificHeat: fluid.SpecificHeat,
      SortOrder: fluid.SortOrder,
      IsRheologyManual: fluid.IsRheologyManual,
      WashpipeViscousDegradationFactor: fluid.WashpipeViscousDegradationFactor,
      AnnulusViscousDegradationFactor: fluid.AnnulusViscousDegradationFactor,
      Color: fluid.Color,
      Notes: fluid.Notes
    };
  }

  public static createEmptyFluidDto = (scenarioId: number, name?: string, color?: Color): FluidDto => ({
    Id: -1,
    ScenarioId: scenarioId,
    CleanFluidDensity: 0,
    DragReductionFactor: 0,
    Name: name ?? '',
    Type: FluidType.Polymer,
    DatabaseType: FluidGravelDatabaseType.Predefined,
    RheologyType: RheologyType.NPrimeAndKPrime,
    IsGravelSettling: true,
    IsYieldStress: false,
    IsMaxViscosity: false,
    IsMinViscosity: false,
    MaxViscosity: 0,
    MinViscosity: 0,
    RheometerTestType: RheometerTestType.RPM_Dial_Reading,
    ThermalConductivity: 2,
    SpecificHeat: 1500,
    SortOrder: -1,
    IsRheologyManual: false,
    WashpipeViscousDegradationFactor: 1,
    AnnulusViscousDegradationFactor: 1,
    Color: color ?? defaultFluidColours[0],
    Notes: ''
  });

  public static newFluid(scenarioId: number, name?: string, color?: Color): Fluid {
    const fluidDto: FluidDto = this.createEmptyFluidDto(scenarioId, name, color);
    return this.createFluid(fluidDto);
  }

  public static toLookupData(src: IDictionaryWithArray<Fluid>): ISelectItem<number>[] {
    const lookupData: ISelectItem<number>[] = DictionaryWithArray.getArray(src).map((fluid) => ({
      value: fluid.Id as number,
      text: fluid.Name,
    }));
    return [{ value: -1, text: 'Select Fluid' }, ...lookupData];
  }

  public static createFluidWithDependencies(
    fluid: FluidDto,
    rheologies: RheologyDto[],
    rheometers: RheometerDto[],
    rheometerReadings: Dictionary<RheometerReading[]>,
  ): FluidWithDependenciesDto {
    const rheometerData = rheometers.map((fr) => ({
      ...fr,
      RheometerReadings: rheometerReadings[fr.Id],
    }));
    const f: FluidWithDependenciesDto = {
      ...fluid,
      Rheologies: rheologies,
      RheometerData: rheometerData,
    };
    return f;
  }

  public static createFluidWithDependenciesDto(state: FluidModuleState, fluidId: number): FluidWithDependenciesDto {
    const fluid = DictionaryWithArray.get(state.Fluids, fluidId);
    if (!fluid) {
      throw new Error('Fluid not found');
    }
    const rheologies = getRowsForCalculations(DictionaryWithArray.get(state.Rheologies, fluidId)?.rows ?? []).map((rh) =>
      RheologyFactory.rheologyToDto(rh),
    );
    const fluidRheometers = DictionaryWithArray.get(state.Rheometers, fluidId)?.rows ?? [];
    const rheometers = getRowsForCalculations(fluidRheometers).map((rh) => RheometerFactory.rheometerToDto(rh));
    const rheometerIds = rheometers.map((rh) => rh.Id);
    const rheometerReadings: Dictionary<RheometerReadingDto[]> = {};
    rheometerIds.forEach((rheometerId) => {
      const readings = DictionaryWithArray.get(state.RheometerReadings, rheometerId);
      rheometerReadings[rheometerId] = getRowsForCalculations(readings?.rows ?? []).map((rr) =>
        RheometerReadingFactory.rheometerReadingToDto(rr),
      );
    });

    return this.createFluidWithDependencies(FluidFactory.fluidToDto(fluid), rheologies, rheometers, rheometerReadings);
  }

  public static getRandomColor(): Color {
    const values = Object.values(Color);
    const randomIndex = Math.floor(Math.random() * values.length);
    return values[randomIndex];
  }

  public static getNextFluidColor(fluids: Fluid[]): Color {
    return defaultFluidColours.find((color) => fluids?.every((fluid) => fluid.Color !== color)) ?? this.getRandomColor();
  }
}
