import {
  CompressibilityUnit,
  ConsistencyIndexUnit,
  DensityUnit,
  FluidConcentrationUnit,
  FluidLossCoefficientUnit,
  ForceUnit,
  InjectivityUnit,
  IUnitSystemDto,
  LengthUnit,
  LinearMassUnit,
  LinearVolumeUnit,
  LiquidConcentrationUnit,
  MassFlowRateUnit,
  MassUnit,
  NoneUnit,
  PermeabilityUnit,
  PlaneAngleUnit,
  PressureGradientUnit,
  PressureRateDerivativeUnit,
  PressureTimeDerivativeUnit,
  PressureUnit,
  PressureVolumeDerivativeUnit,
  RateUnit,
  ReciprocalLengthUnit,
  ShotDensityUnit,
  SolidConcentrationUnit,
  SpecificHeatCapacityUnit,
  TemperatureTimeDerivativeUnit,
  TemperatureUnit,
  TemperatureVolumeDerivativeUnit,
  ThermalConductivityUnit,
  TimeUnit,
  VelocityUnit,
  ViscosityUnit,
  VolumeUnit
} from '@dunefront/common/dto/unit-system.dto';
import { METRIC_UNIT_SYSTEM_ID } from '@dunefront/common/common/constants';
import { IUnitSystem } from '@dunefront/common/modules/units/units.actions';

export interface IUnitsModuleState {
  unitSystem: IUnitSystem;
  availableUnitSystems: IUnitSystemDto[];
}

export const initialUnitSystemState: IUnitsModuleState = {
  unitSystem: { personalUnitSystems: [], orgUnitSystems: [], predefinedUnitSystems: [], currentUnitSystemId: METRIC_UNIT_SYSTEM_ID },
  availableUnitSystems: [
    {
      id: 0,
      sourceId: 0,
      commonDbType: 'Predefined',
      name: 'Metric',
      Long_Length: LengthUnit.Meter,
      Angle: PlaneAngleUnit.Degree,
      Short_Length: LengthUnit.Millimeter,
      Temperature: TemperatureUnit.Celsius,
      Solid_Concentration: SolidConcentrationUnit.Kilogram_per_cubic_meter,
      Liquid_Concentration: LiquidConcentrationUnit.Liter_per_1000_liter,
      Liquid_Volume: VolumeUnit.Cubic_meter,
      Linear_Mass: LinearMassUnit.KilogramPerMeter,
      Pressure: PressureUnit.Kilo_Pascal,
      Pressure_Gradient: PressureGradientUnit.Kilo_Pascal_Per_Meter,
      Rate: RateUnit.Cubic_meter_per_minute,
      Mass: MassUnit.Kilogram,
      Shot_Density: ShotDensityUnit.Shot_per_meter,
      Liquid_Density: DensityUnit.Kilogram_per_cubic_meter,
      Permeability: PermeabilityUnit.Millidarcy,
      Viscosity: ViscosityUnit.Pascal_second,
      Fluid_Loss_Coefficient: FluidLossCoefficientUnit.Meter_per_root_second,
      Diameter: LengthUnit.Millimeter,
      Reciprocal_Length: ReciprocalLengthUnit.Reciprocal_Meter,
      Thermal_Conductivity: ThermalConductivityUnit.MetricThermalConductivity,
      Specific_Heat_Capacity: SpecificHeatCapacityUnit.Metric_specific_heat,
      Time: TimeUnit.Minute,
      Consistency_Index: ConsistencyIndexUnit.Metric_consistency_index,
      Bulk_Density: DensityUnit.Kilogram_per_cubic_meter,
      Solid_Volume: VolumeUnit.Cubic_meter,
      Linear_Volume: LinearVolumeUnit.Cubic_meter_per_meter,
      Temperature_Time_Derivative: TemperatureTimeDerivativeUnit.Celsius_per_minute,
      Temperature_Volume_Derivative: TemperatureVolumeDerivativeUnit.Celsius_per_cubic_meter,
      Solid_Density: DensityUnit.Specific_Gravity,
      Pressure_Time_Derivative: PressureTimeDerivativeUnit.Kilo_Pascal_per_minute,
      Pressure_Volume_Derivative: PressureVolumeDerivativeUnit.Kilo_Pascal_per_cubic_meter,
      Screen_Opening: LengthUnit.Micron,
      Small_Mass: MassUnit.Gram,
      Mass_Flow_Rate: MassFlowRateUnit.Kilogram_per_minute,
      Compressibility: CompressibilityUnit.Reciprocal_pascal,
      Velocity: VelocityUnit.Meter_per_second,
      Pressure_Rate_Derivative: PressureRateDerivativeUnit.Pound_per_square_inch_per_barrel_per_minute,
      Pressure_Rate_Squared_Derivative: PressureRateDerivativeUnit.Pound_per_square_inch_per_barrel_per_minute,
      Pressure_Rate_Cubed_Derivative: PressureRateDerivativeUnit.Pound_per_square_inch_per_barrel_per_minute,
      Sieve_Opening: LengthUnit.Gauge,
      Force: ForceUnit.Kilo_Newton,
      Injectivity: InjectivityUnit.Cubic_Meter_Per_Day_Per_Kilo_Pascal,
      Fluid_Concentration: FluidConcentrationUnit.Percent,
      Yield_Stress: PressureUnit.Dyne_per_square_centimeter,
      Other: NoneUnit.None,
    },
  ],
};
