import { Injectable } from '@angular/core';
import { BaseWsEffects } from '../base-ws.effects';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { BackendConnectionService } from '../../shared/backend-connection/backend-connection.service';
import { ModalService } from '../../common-modules/modals/modal.service';
import { Router } from '@angular/router';
import {
  generateReportAction,
  loadReportInfoModuleAction,
  loadReportInfoModuleSuccessAction,
  reportInfoSelectAllClearAllAction,
  updateReportInfoAction,
  updateReportInfoFieldsAction,
} from './report-info.actions';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { getCurrentScenarioId } from '../scenario/scenario.selectors';
import { ReportInfoDto } from '@dunefront/common/dto/report-info.dto';
import {
  LoadReportInfoAction,
  ReportInfoModuleName,
  UpdateReportInfoAction,
} from '@dunefront/common/modules/report-info/report-info-module.actions';
import { of } from 'rxjs';
import { dataFailed } from '../app.actions';
import { AppTargetConfig } from '../../shared/services/app-target-config';
import { ConvertUnitPipe } from '@dunefront/common/modules/units/convert-unit.pipe/convert-unit.pipe';
import { DocReportGenerator } from '../../shared/helpers/document-generator/generators/doc/doc-report-generator';
import { PptReportGenerator } from '../../shared/helpers/document-generator/generators/ppt/ppt-report-generator';
import { getReportingInfoRangesSelectionComponentItems } from './report-info.selectors';
import { flatten } from 'lodash';

@Injectable()
export class ReportInfoEffects extends BaseWsEffects {
  constructor(
    actions$: Actions,
    store: Store,
    wsService: BackendConnectionService,
    modalService: ModalService,
    protected router: Router,
    protected appConfig: AppTargetConfig,
    protected convertUnitPipe: ConvertUnitPipe,
  ) {
    super(actions$, wsService, ReportInfoModuleName, false, true, modalService, store);
  }

  public loadReportInfoModule$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadReportInfoModuleAction),
      concatLatestFrom(() => this.store.select(getCurrentScenarioId)),
      mergeMap(([action, scenarioId]) =>
        this.emit<ReportInfoDto>(new LoadReportInfoAction(scenarioId)).pipe(
          map((result) => loadReportInfoModuleSuccessAction(result)),
          catchError((err) => of(dataFailed(err))),
        ),
      ),
    ),
  );

  public updateReportInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateReportInfoAction),
      mergeMap((action) => this.emitUpdate(new UpdateReportInfoAction(action))),
    ),
  );

  public rangesSelectionSelectAllClearAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(reportInfoSelectAllClearAllAction),
      concatLatestFrom(() => [this.store.select(getReportingInfoRangesSelectionComponentItems)]),
      switchMap(([action, items]) => {
        let reportingRangeIds: number[] | null = null;
        let reportingTabIds: number[] | null = null;
        if (action.selectAll) {
          reportingRangeIds = items.map((r) => r.value);
          reportingTabIds = flatten(items.map((r) => r.items.map((i) => i.value)));
        }

        return [
          updateReportInfoFieldsAction({
            changes: [
              {
                value: reportingRangeIds,
                changedKey: 'RangeIds',
              },
              {
                value: reportingTabIds,
                changedKey: 'ReportingTabIds',
              },
            ],
          }),
        ];
      }),
    ),
  );

  public generateReport$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(generateReportAction),
        tap((action) => {
          const activityIndicator = this.modalService.showActivityOverlay('Generating report');

          const ReportGeneratorType = action.reportInfoFields.ReportType === 'docx' ? DocReportGenerator : PptReportGenerator;

          const reportGenerator = new ReportGeneratorType(
            action.reportInfo,
            action.reportInfoFields,
            action.reportName,
            this.appConfig,
            action.images,
            this.store,
            this.convertUnitPipe,
          );

          reportGenerator.generateReport().then(() => activityIndicator.close());
        }),
      ),
    { dispatch: false },
  );
}
