import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { createSelector, Store } from '@ngrx/store';
import { DbDependentComponent } from '../../../../../common-modules/db-connection/db-dependent.component';
import { ActivatedRoute } from '@angular/router';
import * as reportingActions from '../../../../../+store/reporting/reporting.actions';
import { ReportingCalculationJobStatus } from '../../../../../+store/reporting/reporting.actions';
import {
  getChartDtos,
  getChartTimeVolModeOrUndefined,
  getCurrentCalculationStatus,
  getResultsEvaluateFrictionChartState,
  getResultsEvaluatePressureChartState,
  getResultsTimeChartDataState,
  ICurrentCalculationStatusDetails,
} from '../../../../../+store/reporting/reporting.selectors';
import { GetChartDataParams, GetChartDataRequestType } from '@dunefront/common/modules/reporting/reporting-module.actions';
import { ChartDataSourceType, ChartDto } from '@dunefront/common/modules/reporting/dto/chart.dto';
import { RangeConstants } from '@dunefront/common/dto/range.dto';
import { distinctUntilChanged, filter, map, skip } from 'rxjs/operators';
import { ChartControllerConfig, ChartControllerFetchDataPayload } from '../../../../../common-modules/chart/chart-controller.component';
import { getChartDto, getChartId } from '@dunefront/common/modules/reporting/dto/chart-data.dto';
import { AnimationTimeBasedMarkersService } from './animation-time-based-markers.service';
import { asyncScheduler, firstValueFrom, throttleTime } from 'rxjs';
import { getAnimationChartCanFetchData } from '../animation-charts.selectors';

import { isSimulateBased } from '@dunefront/common/modules/scenario/scenario.dto';
import { getCurrentRangeId } from '../../../../../+store/range/range.selectors';
import { DrawableContentProviderComponent } from '../../../../../shared/services/drawable-registry.service';
import { filterNil } from '@dunefront/common/common/state.helpers';

@Component({
  selector: 'app-animation-time-based-chart',
  templateUrl: './animation-time-based-chart.component.html',
  styleUrls: ['./animation-time-based-chart.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnimationTimeBasedChartComponent extends DbDependentComponent implements OnInit, OnDestroy, DrawableContentProviderComponent {
  @Input() public drawableProviderId = '';
  @Input() public allowRecording = false;
  @Input() public chartDataSourceType!:
    | ChartDataSourceType.ChartSourceResultsTimeBased
    | ChartDataSourceType.ChartSourceEvaluatePressure
    | ChartDataSourceType.ChartSourceEvaluateFriction
    | ChartDataSourceType.ChartSourceFluidProPressureAndECD;

  public readonly config: ChartControllerConfig = {
    loadData: (payload) => this.fetchData(payload).then(),
    resetAxisLimitsOnRangeChange: true,
    createCustomMarkersService: () => new AnimationTimeBasedMarkersService(this.store, this.chartState$),
  };

  public chartState$ = this.store.select(getAnimationChartState).pipe(
    throttleTime(50, asyncScheduler, { trailing: true, leading: true }),
    map((state) => {
      switch (this.chartDataSourceType) {
        case ChartDataSourceType.ChartSourceResultsTimeBased:
        case ChartDataSourceType.ChartSourceFluidProPressureAndECD:
          return state.timeChartState;
        case ChartDataSourceType.ChartSourceEvaluatePressure:
          return state.evaluatePressureChartState;
        case ChartDataSourceType.ChartSourceEvaluateFriction:
          return state.evaluateFrictionChartState;
        default:
          throw new Error('Unknown chartDataSourceType');
      }
    }),
  );

  public readonly getAnimationChartCanFetchData$ = this.store.select(getAnimationChartCanFetchData).pipe(distinctUntilChanged());
  public readonly ignoreArgumentLimits$ = this.getAnimationChartCanFetchData$.pipe(map((canFetchData) => !canFetchData));

  public currentCalculationStatus?: ICurrentCalculationStatusDetails;
  private chartDtos: ChartDto[] = [];

  public get overlayText(): string {
    if (
      !this.currentCalculationStatus ||
      this.currentCalculationStatus.calculationJobStatus === ReportingCalculationJobStatus.chartAvailable
    ) {
      return '';
    }
    return this.currentCalculationStatus.calculationJobMessage ?? '';
  }

  constructor(
    store: Store,
    cdRef: ChangeDetectorRef,
    protected route: ActivatedRoute,
  ) {
    super(store, cdRef);
  }

  public override ngOnInit(): void {
    super.ngOnInit();

    this.subscription.add(this.store.select(getChartDtos).subscribe((chartDtos) => (this.chartDtos = chartDtos)));

    this.subscription.add(
      this.store.select(getCurrentCalculationStatus).subscribe((currentCalculationStatus) => {
        this.currentCalculationStatus = currentCalculationStatus;
        this.cdRef.markForCheck();
      }),
    );

    this.subscription.add(
      this.store
        .select(getChartTimeVolModeOrUndefined)
        .pipe(filterNil(), distinctUntilChanged(), skip(1))
        .subscribe(() => {
          this.fetchInitialChartData();
        }),
    );

    this.subscription.add(
      this.getAnimationChartCanFetchData$.pipe(filter((canFetch) => canFetch)).subscribe(() => {
        this.fetchInitialChartData();
      }),
    );
  }

  public get chartId(): number {
    return getChartId(this.chartDtos, this.chartDataSourceType);
  }

  private fetchInitialChartData(): void {
    this.fetchData({ src: 'fetchInitialChartData', requestType: GetChartDataRequestType.Initial }).then();
  }

  private async fetchData(payload: ChartControllerFetchDataPayload): Promise<void> {
    const canFetchData = await firstValueFrom(this.store.select(getAnimationChartCanFetchData));

    const chartDto = getChartDto(this.chartDtos, this.chartDataSourceType);

    // this component might get destroyed while awaiting firstValueFrom(this.store.select(getAnimationChartCanFetchData))
    // in that case attempt to read this.currentRangeId leads to crash (this.currentRangeId$ is null in that case)
    if (!canFetchData || this.currentAppModuleType == null || this.currentRangeId$ == null || chartDto == null) {
      return;
    }

    const { src, requestType, argumentStart, argumentEnd, isPrimaryArgument, isPrimaryArgumentRelative } = payload;

    const getChartDataParams: GetChartDataParams = {
      chartId: this.chartId,
      dataSourceType: this.chartDataSourceType,
      timeVolMode: chartDto.TimeVolumeMode,
      moduleType: this.currentAppModuleType,
      rangeId: isSimulateBased(this.currentAppModuleType) ? RangeConstants.EmptyRangeId : this.currentRangeId,
      argumentStart,
      argumentEnd,
      isPrimaryArgument,
      isPrimaryArgumentRelative,
      requestType,
      isOptimizeEvaluationChart: false,
    };

    this.store.dispatch(
      reportingActions.getTimeVolChartData({
        src: 'animation-time-based-chart.component - fetchData ' + src,
        getChartDataParams,
      }),
    );
  }

  public override ngOnDestroy(): void {
    this.store.dispatch(reportingActions.clearReportingChartData({ chartDataSourceType: ChartDataSourceType.ChartSourceResultsTimeBased }));
    super.ngOnDestroy();
  }
}

const getAnimationChartState = createSelector(
  getResultsTimeChartDataState,
  getResultsEvaluatePressureChartState,
  getResultsEvaluateFrictionChartState,
  getCurrentRangeId,
  (timeChartState, evaluatePressureChartState, evaluateFrictionChartState, currentRangeId) => {
    const currentTimeChartState = timeChartState.chartData?.RangeId === currentRangeId ? timeChartState : undefined;
    const currentEvaluatePressureChartState =
      evaluatePressureChartState.chartData?.RangeId === currentRangeId ? evaluatePressureChartState : undefined;
    const currentEvaluateFrictionChartState =
      evaluateFrictionChartState.chartData?.RangeId === currentRangeId ? evaluateFrictionChartState : undefined;
    return {
      timeChartState: currentTimeChartState,
      evaluatePressureChartState: currentEvaluatePressureChartState,
      evaluateFrictionChartState: currentEvaluateFrictionChartState,
    };
  },
);
