import { Component } from '@angular/core';
import { ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import { CommonCalculationPageComponent } from '../common-calculation-page.component';
import { CalculatorsTab } from '../../../+store/ui/ui-module.state';

@Component({
  selector: 'app-resuspension',
  templateUrl: './resuspension.component.html',
  styleUrls: ['./resuspension.component.scss'],
})
export class ResuspensionComponent extends CommonCalculationPageComponent {
  public override appModuleType = ModuleType.Resuspension_Calc;
  public override tab: CalculatorsTab = 'resuspension';
}
