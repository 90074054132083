import { UnitConverter } from '../../converter.interfaces';
import { VolumeUnit } from '../../../dto/unit-system.dto';

export class VolumeConverter extends UnitConverter {
  public static symbols: Array<string> = ['', 'm³', 'L', 'galUS', 'bbl', 'ft³', 'cm³', 'in³'];

  public static unit = VolumeUnit;

  public static fromSi(value: number, unit: VolumeUnit): number {
    switch (unit) {
      case VolumeUnit.Cubic_meter: {
        return value;
      }
      case VolumeUnit.Liter: {
        return value * 1e3;
      }
      case VolumeUnit.US_gallon: {
        return value * 264.172052;
      }
      case VolumeUnit.Barrel: {
        return value * 6.28981077;
      }
      case VolumeUnit.Cubic_foot: {
        return value * 35.3146667;
      }
      case VolumeUnit.Cubic_centimeter: {
        return value * 1e6;
      }
      case VolumeUnit.Cubic_inch: {
        return value * 6.10237441e4;
      }
      default: {
        throw new TypeError('Unknown Volume Unit:' + unit);
      }
    }
  }

  public static toSi(value: number, unit: VolumeUnit): number {
    switch (unit) {
      case VolumeUnit.Cubic_meter: {
        return value;
      }
      case VolumeUnit.Liter: {
        return value / 1e3;
      }
      case VolumeUnit.US_gallon: {
        return value / 264.172052;
      }
      case VolumeUnit.Barrel: {
        return value / 6.28981077;
      }
      case VolumeUnit.Cubic_foot: {
        return value / 35.3146667;
      }
      case VolumeUnit.Cubic_centimeter: {
        return value / 1e6;
      }
      case VolumeUnit.Cubic_inch: {
        return value / 6.10237441e4;
      }
      default: {
        throw new TypeError('Unknown Volume Unit:' + unit);
      }
    }
  }

  public static getSymbol(unit: VolumeUnit): string {
    return this.symbols[unit];
  }
}
