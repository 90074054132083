import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ITableRow } from '@dunefront/common/common/common-grid.interfaces';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { ServiceToolPipe } from '@dunefront/common/modules/pipes/running-string-pipes/pipes/service-tool-pipe';
import { BypassAnnulus } from '@dunefront/common/modules/pipes/running-string-pipes/bypass-annulus';
import { changeProp, ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';
import { IUpdateTableRowsProps, StoreCrudPropsFactory } from '@dunefront/common/common/common-store-crud.interfaces';

@Component({
  selector: 'app-service-tool-properties',
  templateUrl: './service-tool-properties.component.html',
  styleUrls: ['./service-tool-properties.component.scss'],
})
export class ServiceToolPropertiesComponent implements OnChanges {
  @Input() public pipe!: ITableRow<ServiceToolPipe>;
  @Output() public rowUpdated = new EventEmitter<IUpdateTableRowsProps<ServiceToolPipe>>();

  public downFlow!: BypassAnnulus;
  public upFlow!: BypassAnnulus;

  public UnitType = UnitSystem;

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.pipe != null) {
      this.downFlow = this.pipe.rowData.DownFlow;
      this.upFlow = this.pipe.rowData.UpFlow;
    }
  }

  public onFlowPropertyChange(event: ObjectChangeProp<BypassAnnulus>, isUpFlow: boolean): void {
    const newRow: ITableRow<ServiceToolPipe> = {
      ...this.pipe,
      rowData: {
        ...this.pipe.rowData,
        ...(isUpFlow && { UpFlow: changeProp(this.pipe.rowData.UpFlow, event) }),
        ...(!isUpFlow && { DownFlow: changeProp(this.pipe.rowData.DownFlow, event) }),
      },
    };
    this.rowUpdated.emit({ rows: [newRow], colIds: [isUpFlow ? 'UpFlow' : 'DownFlow'], shouldResetResults: event.shouldResetResults });
  }

  public onPropertyChange(event: ObjectChangeProp<ServiceToolPipe>): void {
    const newRow: ITableRow<ServiceToolPipe> = {
      ...this.pipe,
      rowData: changeProp(this.pipe.rowData, event),
    };
    this.rowUpdated.emit(StoreCrudPropsFactory.updateTableRows([newRow], event));
  }
}
