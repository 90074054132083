<div class="grid-outline-container">
  <app-grid
    [dataSource]="warningMessages"
    [showCrudControls]="false"
    [gridConfig]="gridConfig"
    [columns]="gridConfig.columns"
    [headerTopRowHeight]="30"
    [height]="getHeight()"
  >
  </app-grid>
</div>
