import { DbMigrationModuleState } from './db-migration.reducer';
import { IMigrationServerResponse } from '@dunefront/common/modules/db-migration/db-migration-module.actions';

export const dbMigrationInitialState: DbMigrationModuleState = {
  migrationInfo: { initialDbVersion: '?', finalDbVersion: '??', status: 'migrationRequired', fileHash: '' },
};

export class DbMigrationModuleReducerHelper {
  public static startDbMigration(state: DbMigrationModuleState): DbMigrationModuleState {
    return { ...state, migrationInfo: { ...state.migrationInfo, status: 'migrationInitialization' } } as DbMigrationModuleState;
  }

  public static progressDbMigration(state: DbMigrationModuleState, response: IMigrationServerResponse): DbMigrationModuleState {
    const migrationInfo = response;
    return { ...state, migrationInfo } as DbMigrationModuleState;
  }
}
