import {
  ConnectionLicensingConfig,
  IDbConnectionOpenedResponse,
  IDbInfo,
} from '@dunefront/common/modules/db-connection/db-connection.actions';
import { createAction, props } from '@ngrx/store';
import { IFile } from '@dunefront/common/dto/file.dto';
import { IActivityOverlayConfig } from '@dunefront/common/modules/ui/ui.interfaces';
import { Scenario } from '@dunefront/common/modules/scenario/scenario';

export const checkIfVacuumNeededAndCloseFile = createAction('[BackendConnection] checkIfVacuumNeededAndCloseFile');
export const dbConnectAction = createAction(
  '[BackendConnection] DatabaseConnect',
  props<{
    dbFile: IFile;
    licensingConfig: ConnectionLicensingConfig;
    scenarioId?: number;
    rangeId?: number;
  }>(),
);
export const dbConnectedSuccessAction = createAction(
  '[BackendConnection] DatabaseConnected',
  props<{ payload: IDbConnectionOpenedResponse; licensingConfig: ConnectionLicensingConfig; scenarioId: number; rangeId: number }>(),
);
export const dbConnectedMigrationRequiredAction = createAction(
  '[BackendConnection] DatabaseConnectedMigrationRequired',
  props<{ dbInfo: IDbInfo; licensingConfig: ConnectionLicensingConfig }>(),
);

export const dbDisconnectAction = createAction('[BackendConnection] DatabaseDisconnect');
export const dbDisconnectSuccessAction = createAction('[BackendConnection] DatabaseDisconnect Success');
export const dbConnectionFailedAction = createAction(
  '[BackendConnection] DatabaseConnectionFailed',
  props<{ fileName: string; error: string }>(),
);

export const backendConnectedAction = createAction('[BackendConnection] connected');
export const backendDisconnectedAction = createAction('[BackendConnection] disconnected');
export const backendConnectFailedAction = createAction('[BackendConnection] failed', props<{ error: string }>());

export const backendRequestStartedAction = createAction(
  '[BackendConnection] backendResponseReceivedActionRequestStartedAction',
  props<{ actionType: string; taskId: string; activityOverlayConfig: IActivityOverlayConfig | undefined }>(),
);
export const backendResponseReceivedAction = createAction(
  '[BackendConnection] backendResponseReceivedAction',
  props<{ taskId: string; closeActivityOverlay: boolean }>(),
);

export const setPageTitle = createAction(
  '[BackendConnection] setPageTitle',
  props<{ file: IFile | undefined; scenario: Scenario | undefined }>(),
);

export const wrongDbVersion = createAction('[BackendConnection] wrongDbVersion', props<{ error: Error }>());
