import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { BackendConnectionService } from '../../shared/backend-connection/backend-connection.service';
import { catchError, filter, map, mergeMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as actions from './common-db.actions';
import {
  CommonDbModuleName,
  CopyCommonFluidToProjectAction,
  CopyCommonGravelToProjectAction,
  CopyProjectFluidToCommonAction,
  CopyProjectGravelToCommonAction,
  DeleteCommonFluidAction,
  DeleteCommonGravelAction,
  getCommonDbError,
  LoadCommonFluidsAction,
  LoadCommonGravelsAction,
  PackProCommonFluidError,
  PackProCommonGravelError,
} from '@dunefront/common/modules/common-db/common-db.actions';
import { CrudResponse } from '@dunefront/common/modules/common.actions';
import { dataFailed, insertRowsSuccess } from '../app.actions';
import { BaseWsEffects } from '../base-ws.effects';
import { ModalService } from '../../common-modules/modals/modal.service';
import { FluidFactory } from '@dunefront/common/modules/fluid/model/fluid.factory';
import { getFluidsArray } from '../fluid/fluid.selectors';

@Injectable()
export class DbDependentCommonDbEffects extends BaseWsEffects {
  constructor(actions$: Actions, store: Store, wsService: BackendConnectionService, modalService: ModalService) {
    super(actions$, wsService, CommonDbModuleName, false, true, modalService, store);
  }

  // region Common Fluids
  public loadCommonFluids$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loadCommonFluids),
      mergeMap((action) => {
        return this.emit<CrudResponse>(new LoadCommonFluidsAction()).pipe(map((result) => actions.loadCommonFluidsSuccess(result.payload)));
      }),
    ),
  );

  public copyCommonFluidToProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.copyCommonFluidToProject),
      concatLatestFrom(() => this.store.select(getFluidsArray)),
      mergeMap(([action, fluids]) => {
        return this.emit<CrudResponse>(
          new CopyCommonFluidToProjectAction(
            action.commonFluidId,
            action.commonDbType,
            action.targetScenarioId,
            FluidFactory.getNextFluidColor(fluids),
          ),
        ).pipe(
          map((result) => insertRowsSuccess(result.payload)),
          tap(() => this.modalService.showAlert('Fluid loaded successfully.', 'Information')),
          catchError((error) => of(actions.commonFluidOperationFailure({ error }))),
        );
      }),
    ),
  );

  public copyProjectFluidToCommon$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.copyProjectFluidToCommon),
      mergeMap((action) => {
        return this.emit<CrudResponse>(
          new CopyProjectFluidToCommonAction(action.projectFluidId, action.commonDbType, action.overwrite),
        ).pipe(
          map((result) => insertRowsSuccess(result.payload)),
          tap(() => this.modalService.showAlert('Fluid saved successfully.', 'Information')),
          catchError((error) => of(actions.commonFluidOperationFailure({ error }))),
        );
      }),
    ),
  );

  public deleteCommonFluid$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.deleteCommonFluid),
      mergeMap((action) => {
        return this.emitDelete(new DeleteCommonFluidAction(action.commonFluidId, action.commonDbType));
      }),
    ),
  );

  public onCommonFluidOperationFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.commonFluidOperationFailure),
        filter((action) => !!action.error),
        tap(async (action) => {
          const error = action.error as PackProCommonFluidError;
          if (error.message === getCommonDbError('load-already-exists')) {
            await this.onLoadFluidAlreadyExist(error);
          } else if (error.message === getCommonDbError('save-already-exists')) {
            await this.onSaveFluidAlreadyExist(error);
          } else {
            this.store.dispatch(
              dataFailed({
                status: 'onCommonFluidOperationFailure$',
                message: error.message,
              }),
            );
          }
        }),
      ),
    { dispatch: false },
  );

  // endregion

  //region Common Gravels

  public loadCommonGravels$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loadCommonGravels),
      mergeMap((action) => {
        return this.emit<CrudResponse>(new LoadCommonGravelsAction()).pipe(
          map((result) => actions.loadCommonGravelsSuccess(result.payload)),
        );
      }),
    ),
  );

  public copyCommonGravelToProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.copyCommonGravelToProject),
      mergeMap((action) => {
        return this.emit<CrudResponse>(
          new CopyCommonGravelToProjectAction(action.commonGravelId, action.commonDbType, action.targetScenarioId),
        ).pipe(
          map((result) => insertRowsSuccess(result.payload)),
          tap(() => this.modalService.showAlert('Gravel loaded successfully.', 'Information')),
          catchError((error) => of(actions.commonGravelOperationFailure({ error }))),
        );
      }),
    ),
  );

  public copyProjectGravelToCommon$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.copyProjectGravelToCommon),
      mergeMap((action) => {
        return this.emit<CrudResponse>(
          new CopyProjectGravelToCommonAction(action.projectGravelId, action.commonDbType, action.overwrite),
        ).pipe(
          map((result) => insertRowsSuccess(result.payload)),
          tap(() => this.modalService.showAlert('Gravel saved successfully.', 'Information')),
          catchError((error) => of(actions.commonGravelOperationFailure({ error }))),
        );
      }),
    ),
  );

  public deleteCommonGravel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.deleteCommonGravel),
      mergeMap((action) => {
        return this.emitDelete(new DeleteCommonGravelAction(action.commonGravelId, action.commonDbType));
      }),
    ),
  );

  public onCommonGravelOperationFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.commonGravelOperationFailure),
        filter((action) => !!action.error),
        tap(async (action) => {
          const error = action.error as PackProCommonGravelError;
          if (error.message === getCommonDbError('load-already-exists')) {
            await this.onLoadGravelAlreadyExist(error);
          } else if (error.message === getCommonDbError('save-already-exists')) {
            await this.onSaveGravelAlreadyExist(error);
          } else {
            this.store.dispatch(
              dataFailed({
                status: 'onCommonGravelOperationFailure$',
                message: error.message,
              }),
            );
          }
        }),
      ),
    { dispatch: false },
  );

  // endregion

  private async onLoadFluidAlreadyExist(error: PackProCommonFluidError): Promise<void> {
    await this.modalService.showAlert('The fluid name already exists - please rename or delete the fluid before continuing', 'Information');
  }

  private async onSaveFluidAlreadyExist(error: PackProCommonFluidError): Promise<void> {
    const confirmResult = await this.modalService.showConfirm(
      'The fluid name already exists in the database - do you want to overwrite it?',
      'Information',
    );
    const commonDbType = error.data.commonDbType;
    const fluidId: number = error.data.fluidId;

    if (confirmResult) {
      this.store.dispatch(
        actions.copyProjectFluidToCommon({
          projectFluidId: fluidId,
          commonDbType: commonDbType,
          overwrite: true,
        }),
      );
    }
  }

  private async onLoadGravelAlreadyExist(error: PackProCommonGravelError): Promise<void> {
    await this.modalService.showAlert(
      'The gravel name already exists - please rename or delete the gravel before continuing',
      'Information',
    );
  }

  private async onSaveGravelAlreadyExist(error: PackProCommonGravelError): Promise<void> {
    const confirmResult = await this.modalService.showConfirm(
      'The gravel name already exists in the database - do you want to overwrite it?',
      'Information',
    );
    const commonDbType = error.data.commonDbType;
    const gravelId: number = error.data.gravelId;

    if (confirmResult) {
      this.store.dispatch(
        actions.copyProjectGravelToCommon({
          projectGravelId: gravelId,
          commonDbType: commonDbType,
          overwrite: true,
        }),
      );
    }
  }
}
