import { createAction, props } from '@ngrx/store';
import { IMigrationServerResponse } from '@dunefront/common/modules/db-migration/db-migration-module.actions';

export const startDbMigration = createAction('[dbMigration] startDbMigration');
export const progressDbMigration = createAction(
  '[dbMigration] progressDbMigration',
  props<{ taskId: string; migrationServerResponse: IMigrationServerResponse }>(),
);

export const failedDbMigration = createAction('[dbMigration] failedDbMigration', props<{ error: Error }>());
export const cancelMigration = createAction('[dbMigration] cancelMigration');
