import { ChangeDetectorRef, Component, ErrorHandler, Inject, NgModule, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { CommonModule } from '@angular/common';
import { ClientErrorHandlerService, IErrorState } from './client-error-handler.service';
import { ButtonModule } from '../button/button.module';

import { Store } from '@ngrx/store';
import { refreshAppAction } from '../../../+store/app.actions';
import { ModalService } from '../../../common-modules/modals/modal.service';
import { Nl2BrPipeModule } from '../../pipes/nl2br/nl2br.pipe';

@Component({
  selector: 'app-error',
  templateUrl: 'error.component.html',
  styleUrls: ['error.component.scss'],
})
export class ErrorComponent implements OnDestroy {
  public error: IErrorState | null = null;
  private subscription = new Subscription();

  constructor(
    @Inject(ErrorHandler) errorHandlerService: ClientErrorHandlerService,
    private readonly store: Store,
    private readonly modalService: ModalService,
    private readonly cdRef: ChangeDetectorRef,
  ) {
    this.subscription.add(
      errorHandlerService.getError().subscribe((error) => {
        if (error?.status === 'handled') {
          this.modalService.dismissAll();
          this.modalService.showAlert(error.message, error.dialogTitle ?? '').then();
        } else {
          this.error = error;
        }
        this.cdRef.markForCheck();
      }),
    );
  }

  public refresh(error: IErrorState): void {
    this.store.dispatch(refreshAppAction({ error }));
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

@NgModule({
  imports: [CommonModule, Nl2BrPipeModule, ButtonModule, Nl2BrPipeModule],
  providers: [{ provide: ErrorHandler, useClass: ClientErrorHandlerService }],
  declarations: [ErrorComponent],
  exports: [ErrorComponent],
})
export class ErrorModule {}
