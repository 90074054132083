import { Injectable, Type } from '@angular/core';
import { AlertModalContentComponent } from './alert/alert-modal-content.component';
import {
  ConfirmModalContentComponent,
  ConfirmModalContentComponentButton,
  ConfirmModalContentComponentData,
} from './alert/confirm-modal-content.component';

import { WorkingModalContentComponent } from './alert/working-indicator-modal-content.component';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { firstValueFrom, Subject } from 'rxjs';
import { ProgressIndicatorModalContentComponent } from './alert/progress-indicator-modal-content.component';
import { PromptModalContentComponent } from './alert/prompt-modal-content.component';
import { DateTimeModalContentComponent } from './alert/datetime-modal-content.component';
import { ActivityOverlayComponent } from './activity-overlay/activity-overlay.component';
import { ModalSize } from '@dunefront/common/modules/ui/ui.interfaces';
import { IUnitSystemDto, UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { selectCurrentUnitSystem } from '../../+store/units/units.selectors';
import { Store } from '@ngrx/store';
import { ConvertUnitPipe } from '@dunefront/common/modules/units/convert-unit.pipe/convert-unit.pipe';
import { CommonDbType } from '@dunefront/common/dto/common-dto.interfaces';

import { GetPpdTargetComponent } from './get-ppd-target/get-ppd-target.component';
import { PromptTextareaModalContentComponent } from './alert/textarea-modal-content.component';
import { isElectron } from '@dunefront/common/common/electron/is-electron';

@Injectable()
export class ModalService {
  constructor(
    public dialogService: DialogService,
    private store: Store,
  ) {}

  public async showAlert(
    message: string | string[],
    title = '',
    width: ModalSize = 'sm',
    isDisabledWhenCalculationActive = true,
    showOnTop = false,
  ): Promise<any> {
    const currentUnitSystem: IUnitSystemDto = (await firstValueFrom(this.store.select(selectCurrentUnitSystem))) ?? UnitSystem.None;

    const dialog = this.dialogService.open(AlertModalContentComponent, {
      data: {
        message: Array.isArray(message)
          ? message.map((msg) => ConvertUnitPipe.decode(msg, currentUnitSystem, 3))
          : ConvertUnitPipe.decode(message, currentUnitSystem, 3, false, false, true),
        title,
        isDisabledWhenCalculationActive,
      },
      width: this.getSize(width),
      showHeader: false,
      baseZIndex: showOnTop ? 10000 : undefined,
      duplicate: true,
    });

    return await firstValueFrom(dialog.onClose);
  }

  public async showConfirmNoUndoable(
    message: string | string[],
    title: string,
    width: ModalSize = 'sm',
    confirmButtonText = 'Yes',
    rejectButtonText = 'Cancel',
  ): Promise<boolean> {
    if (typeof message === 'string') {
      return this.showConfirm([message, 'This action cannot be undone.'], title, width, confirmButtonText, rejectButtonText);
    }
    return this.showConfirm([...message, 'This action cannot be undone.'], title, width, confirmButtonText, rejectButtonText);
  }

  public async showConfirm(
    message: string | string[],
    title: string,
    width: ModalSize = 'sm',
    confirmButtonText = 'Yes',
    rejectButtonText = 'Cancel',
    baseZIndex?: number,
  ): Promise<boolean> {
    const buttons: ConfirmModalContentComponentButton<boolean>[] = [
      {
        text: rejectButtonText,
        type: 'cancel',
        dataCy: 'confirm-modal-reject',
        cssClass: 'btn-light',
        result: false,
      },
      {
        text: confirmButtonText,
        type: 'primary',
        dataCy: 'confirm-modal-confirm',
        cssClass: 'btn-danger',
        result: true,
      },
    ];

    return await this.showCustomConfirm<boolean>(message, title, buttons, true, width, baseZIndex);
  }

  public async showCustomConfirm<T = any>(
    message: string | string[],
    title: string,
    buttons: ConfirmModalContentComponentButton<T>[],
    closeOnEscape = true,
    width: ModalSize = 'sm',
    baseZIndex?: number,
  ): Promise<T> {
    const modalSize: IModalSize = { width: this.getSize(width), height: 'auto' };

    const config: DynamicDialogConfig<ConfirmModalContentComponentData> = {
      ...modalSize,
      data: {
        message,
        title,
        buttons,
      },
      showHeader: false,
      closeOnEscape,
      baseZIndex,
      duplicate: true,
    };

    const dialog = this.dialogService.open(ConfirmModalContentComponent, config);

    return await firstValueFrom(dialog.onClose);
  }

  public async showPrompt(
    message: string,
    title: string,
    label = '',
    width: ModalSize = 'sm',
    okButtonText = 'OK',
    cancelButtonText = 'Cancel',
    isTextInput = true,
    decimalPlaces = 2,
    min?: number,
    inputValue?: string | number,
    styleClass?: string,
    isUiLockable = true,
  ): Promise<string | number | null> {
    const modalSize: IModalSize = { width: this.getSize(width), height: 'auto' };
    const dialog = this.dialogService.open(PromptModalContentComponent, {
      ...modalSize,
      styleClass,
      data: {
        message,
        title,
        label,
        okButtonText,
        cancelButtonText,
        isTextInput,
        decimalPlaces,
        min,
        inputValue,
        isUiLockable,
      },
      showHeader: false,
      duplicate: true,
    });

    return await firstValueFrom(dialog.onClose);
  }

  public async showTextareaPrompt(
    message: string,
    title: string,
    label = '',
    okButtonText = 'OK',
    cancelButtonText = 'Cancel',
    inputValue?: string | number,
    width: ModalSize = 'sm',
  ): Promise<string | null> {
    const modalSize: IModalSize = { width: this.getSize(width), height: 'auto' };
    const dialog = this.dialogService.open(PromptTextareaModalContentComponent, {
      ...modalSize,
      data: {
        message,
        title,
        okButtonText,
        cancelButtonText,
        inputValue,
      },
      showHeader: false,
    });

    return await firstValueFrom(dialog.onClose);
  }

  public async showDateTimePicker(
    message: string,
    title: string,
    width: ModalSize = 'sm',
    showTimePicker = true,
    presetTime?: number,
  ): Promise<Date | undefined> {
    const modalSize: IModalSize = { width: this.getSize(width), height: 'auto' };
    const dialog = this.dialogService.open(DateTimeModalContentComponent, {
      ...modalSize,
      data: {
        message,
        title,
        showTimePicker,
        presetTime,
      },
      showHeader: false,
      duplicate: true,
    });
    return await firstValueFrom(dialog.onClose);
  }

  public showWorkingIndicator(message: string): DynamicDialogRef {
    return this.dialogService.open(WorkingModalContentComponent, {
      data: {
        message,
      },
      width: '500px',
      height: 'auto',
      showHeader: false,
      duplicate: true,
    });
  }

  public showProgressIndicator(message: string, progress: Subject<number>, showCancel = false): Promise<any> {
    const dialog = this.dialogService.open(ProgressIndicatorModalContentComponent, {
      data: {
        message,
        progress,
        showCancel,
      },
      width: '500px',
      height: 'auto',
      showHeader: false,
      duplicate: true,
    });
    return firstValueFrom(dialog.onClose);
  }

  public showActivityOverlay(
    title?: string,
    message?: string,
    taskId?: string,
    displayDelayMs = 150,
    minDisplayTimeMs = 300,
    width: ModalSize | undefined = 'md',
  ): ModalCloseHandle {
    let closed = false;
    let modalRef: DynamicDialogRef | undefined = undefined;
    let showTime: number | undefined = undefined;

    const showModal = (): void => {
      if (!closed) {
        showTime = Date.now();
        modalRef = this.open(
          ActivityOverlayComponent,
          {
            message: message ?? '',
            title: title ?? '',
            taskId: taskId,
          },
          '',
          width,
        );
      }
    };

    // when displayDelayMs === 0 -> show modal synchronously (no setTimeout)
    // this way it will start ignoring all events (user interactions) immediately, it might be important in some cases
    if (displayDelayMs === 0) {
      showModal();
    } else {
      setTimeout(() => showModal(), displayDelayMs);
    }

    const close = (): void => {
      if (closed) {
        return;
      }

      closed = true;

      if (!modalRef) {
        return;
      }

      const displayTime = Date.now() - (showTime ?? Date.now());
      const displayTimeLeft = minDisplayTimeMs - displayTime;

      setTimeout(() => modalRef?.close(), Math.max(displayTimeLeft, 0));
    };

    return { close };
  }

  public open<T>(
    content: Type<any>,
    data: T | undefined,
    styleClass = '', // you can multiple, comma separated classes
    width: ModalSize = 'md',
    height: ModalSize = 'auto',
    closeOnEscape = true,
  ): DynamicDialogRef {
    const modalSize: IModalSize = { width: this.getSize(width), height: this.getSize(height) };

    return this.dialogService.open(content, {
      ...modalSize,
      styleClass: [width, ...styleClass.split(',')].join(' '),
      data,
      showHeader: false,
      closeOnEscape: closeOnEscape,
      duplicate: true,
    });
  }

  public async getCommonDbType(saveName: 'unit' | 'fluid' | 'gravel'): Promise<CommonDbType | undefined> {
    if (isElectron()) {
      return Promise.resolve('User');
    }
    const modalRef = this.open(GetPpdTargetComponent, { saveName });
    return await firstValueFrom(modalRef.onClose);
  }

  public dismissAll(): void {
    for (const dialogRef of this.dialogService.dialogComponentRefMap.values()) {
      dialogRef.instance.close();
    }
  }

  public areAnyDialogsOfTypeOpen(type: Type<any>): boolean {
    return [...this.dialogService.dialogComponentRefMap.values()].some((dialogRef) => dialogRef.instance.childComponentType == type);
  }

  public areAnyDialogsOpen(): boolean {
    return [...this.dialogService.dialogComponentRefMap.keys()].length > 0;
  }

  private getSize(modalSize: ModalSize): string {
    if (modalSize === 'sm' || modalSize === 'md' || modalSize === 'lg') {
      return modalSize === 'md' ? '600px' : modalSize === 'lg' ? '800px' : '400px';
    }
    return modalSize;
  }
}

export interface IModalConfig {
  type: 'ALERT' | 'CONFIRM';
  message: string;
  title: string;
}

export interface ModalCloseHandle {
  close: () => void;
}

export interface IModalSize {
  height: string;
  width: string;
}
