import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import {
  getCurrentColConfig,
  getCurrentColIndex,
  ImportDataModuleState,
  IValidatedImportDataModuleState,
} from '../../../../../+store/import-data/import-data-module.state';
import { EnumHelpers } from '@dunefront/common/utils/enum.helpers';
import { UnitHelpers } from '../../../../units/unit-helpers';
import { IUnitSystemDto, UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { GaugeMeasurementType } from '@dunefront/common/dto/running-string-pipe.dto';
import { getDefaultUnitSystem, IValidatedColConfig } from '../../../../../+store/import-data/model/col-config';
import { ImportDataValidation } from '../../../../../+store/import-data/validation/import-data.validation';
import { ISelectItem } from '@dunefront/common/common/select.helpers';
import { ImportColumnType, ImportDataType } from '@dunefront/common/modules/data-storage/dto/import-template/import-template.dto';
import { Subscription } from 'rxjs';
import { UnitConverterHelper } from '@dunefront/common/unit-converters/unit.converter.helper';
import { changeProp, ObjectChangeProp, PrimitiveChangeValue } from '@dunefront/common/common/common-state.interfaces';

@Component({
  selector: 'app-column-properties',
  templateUrl: './column-properties.component.html',
  styleUrls: ['./column-properties.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColumnPropertiesComponent implements OnChanges, OnDestroy {
  @Input()
  public state!: IValidatedImportDataModuleState;

  @Input()
  public currentUnitSystem!: IUnitSystemDto;

  @Output()
  public stateChange = new EventEmitter<{ state: ImportDataModuleState; customTimeFormatColumnId: number | null }>();

  @Output()
  public dateFormatsHelpClicked = new EventEmitter();

  public importColumnTypeItems: ISelectItem<ImportColumnType>[] = [];
  public unitSystemItems = EnumHelpers.EnumToISelectItemArray(UnitSystem);
  public unitSymbolsItems: ISelectItem<number>[] = [];
  public UnitType = UnitSystem;
  public colConfig!: IValidatedColConfig;
  public isHelpOpen = false;
  public subscription = new Subscription();

  public get isMeasurementInputsVisible(): boolean {
    return ImportDataValidation.isMeasurementInputsVisible(this.state);
  }

  public get isMeasurementEnabled(): boolean {
    return ImportDataValidation.isMeasurementEnabled(this.colConfig);
  }

  public get isUnitSystemEnabled(): boolean {
    return ImportDataValidation.isUnitSystemEnabled(this.colConfig);
  }

  public get isUnitSymbolsEnabled(): boolean {
    return ImportDataValidation.isUnitSymbolsEnabled(this.colConfig);
  }

  public get isCustomFormatCbEnabled(): boolean {
    return ImportDataValidation.isCustomFormatCbEnabled(this.colConfig);
  }

  private static getImportColumnExcludeValues(importDataType: ImportDataType): ImportColumnType[] {
    switch (importDataType) {
      case ImportDataType.Surface_Data:
        return [ImportColumnType.Bottomhole_Pressure, ImportColumnType.Bottomhole_Temperature];
      case ImportDataType.Bottomhole_Data:
        return [
          ImportColumnType.Pump_Rate,
          ImportColumnType.Return_Rate,
          ImportColumnType.Surface_Gravel_Concentration,
          ImportColumnType.Pump_Pressure,
          ImportColumnType.Return_Pressure,
          ImportColumnType.Bit_Depth,
          ImportColumnType.Block_Height,
          ImportColumnType.Hook_Load,
          ImportColumnType.Mud_Weight,
          ImportColumnType.Liquid_Additive_Concentration,
        ];
      default:
        return [];
    }
  }

  public ngOnDestroy(): void {
    this.isHelpOpen = false;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.state != null && (changes.state.isFirstChange() || changes.state.previousValue.dataType !== this.state.importDataType)) {
      this.importColumnTypeItems = EnumHelpers.EnumToISelectItemArray(
        ImportColumnType,
        true,
        undefined,
        ColumnPropertiesComponent.getImportColumnExcludeValues(this.state.importDataType),
      );
    }
    if (changes.state != null && this.state.includedColumnIds.length) {
      this.colConfig = getCurrentColConfig(this.state);
      if (changes.state.previousValue) {
        const prevState: IValidatedImportDataModuleState = changes.state.previousValue;
        const prevColConfig = getCurrentColConfig(prevState);

        if (prevColConfig?.unitSystem !== this.colConfig.unitSystem) {
          this.setUnitSymbolItems();
        }
      } else {
        this.setUnitSymbolItems();
      }
    }
  }

  public onColConfigChanged(prop: ObjectChangeProp<IValidatedColConfig>): void {
    let newConfig = changeProp(this.colConfig, prop);

    if (!newConfig.isTimeCustomFormat) {
      newConfig = { ...newConfig, timeCustomFormat: '' };
    }
    this.updateColConfig(newConfig);
  }

  private getMeasuredDepth(columnType: ImportColumnType): number {
    if (this.isBottomhole(columnType)) {
      if (this.colConfig.measuredDepth !== 0) {
        return this.colConfig.measuredDepth;
      }
      return this.state.measuredDepth;
    }
    return 0;
  }

  private getMeasurementType(columnType: ImportColumnType): number {
    if (this.isBottomhole(columnType) && this.colConfig.measurementType != null) {
      return this.colConfig.measurementType;
    }
    return -1;
  }

  public onImportColumnTypeChanged(event: PrimitiveChangeValue<ImportColumnType | null>): void {
    if (event.value == null) {
      return;
    }
    const columnType = event.value;
    const unitSystem = getDefaultUnitSystem(columnType);

    const newColConfig: IValidatedColConfig = {
      ...this.colConfig,
      columnType,
      unitSystem,
      unitSymbol: UnitConverterHelper.getCurrentUnit(unitSystem, this.currentUnitSystem),
      measuredDepth: this.getMeasuredDepth(columnType),
      measurementType: this.getMeasurementType(columnType),
      ...(columnType !== ImportColumnType.Time && { isTimeCustomFormat: false }),
      ...(columnType !== ImportColumnType.Time && { timeCustomFormat: '' }),
    };
    this.updateColConfig(newColConfig);
  }

  public onHelpClick(): void {
    this.dateFormatsHelpClicked.emit();
  }

  public onUnitTypeChanged(event: PrimitiveChangeValue<UnitSystem | null>): void {
    if (event.value == null) {
      return;
    }
    const unitSystem = event.value;
    this.updateColConfig({
      ...this.colConfig,
      unitSystem,
      unitSymbol: UnitConverterHelper.getCurrentUnit(unitSystem, this.currentUnitSystem),
    });
  }

  public onUnitSymbolChanged(event: PrimitiveChangeValue<number>): void {
    this.updateColConfig({ ...this.colConfig, unitSymbol: event.value });
  }

  public onMeasurementTypeChanged(event: PrimitiveChangeValue<GaugeMeasurementType | null>): void {
    if (event.value == null) {
      return;
    }
    this.updateColConfig({ ...this.colConfig, measurementType: event.value });
  }

  private setUnitSymbolItems(): void {
    const unitSystem = this.colConfig.unitSystem;
    this.unitSymbolsItems = unitSystem === null ? [] : UnitHelpers.getUnitSymbolSelectItems(unitSystem);
    if (unitSystem === UnitSystem.None) {
      this.onUnitSymbolChanged({ value: 0, shouldResetResults: true });
    }
  }

  private updateColConfig(colConfig: IValidatedColConfig): void {
    const shouldDetectTimeFormat = colConfig.isTimeCustomFormat;

    const state = {
      ...this.state,
      colConfigs: this.state.colConfigs.map((item, index) => (index === getCurrentColIndex(this.state) ? colConfig : item)),
    };

    this.stateChange.emit({ state, customTimeFormatColumnId: shouldDetectTimeFormat ? colConfig.colIndex : null });
  }

  private isBottomhole(columnType: ImportColumnType | null): boolean {
    return (
      columnType != null && (columnType === ImportColumnType.Bottomhole_Temperature || columnType === ImportColumnType.Bottomhole_Pressure)
    );
  }

  public get measurementTypes(): ISelectItem<GaugeMeasurementType>[] {
    const noneType = [GaugeMeasurementType.None];
    const defaultTypes = [GaugeMeasurementType.Internal, GaugeMeasurementType.External];

    return EnumHelpers.EnumToISelectItemArray(
      GaugeMeasurementType,
      false,
      this.isBottomhole(this.colConfig.columnType) ? defaultTypes : noneType,
    );
  }

  public selectTimeFormat(format: string): void {
    this.updateColConfig({ ...this.colConfig, timeCustomFormat: format });
  }
}
