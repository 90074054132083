import { HorizontalAlignment, VerticalAlignment } from '@dunefront/common/modules/reporting/dto/chart.types';
import { ChartSeriesDto } from '@dunefront/common/modules/reporting/dto/chart-series.dto';
import { ITableState } from '@dunefront/common/common/common-grid.interfaces';
import { IChartResultColumn } from '../reporting.selectors';
import { ReportingTabDto } from '@dunefront/common/dto/reporting-tab.dto';
import { ChartDataSourceType, ChartDto, emptyChartDto } from '@dunefront/common/modules/reporting/dto/chart.dto';
import { ChartState, IChartConfig, IChartUserAddons } from '../reporting-module.state';
import { ChartAxis, ChartAxisPropertyDto, IAxisStyle, XAxisFormat } from '@dunefront/common/modules/reporting/dto/chart-axis-property.dto';
import {
  GetChartDataRequestType,
  InsertChartAction,
  InsertChartSeriesAction,
  InsertReportingTabAction,
} from '@dunefront/common/modules/reporting/reporting-module.actions';
import { ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import { IChartMarkerDto, IMarker } from '@dunefront/common/modules/reporting/dto/chart-marker.dto';
import { createEmptyChartDataWithChartTemplateId, getChartId, IChartDataDto } from '@dunefront/common/modules/reporting/dto/chart-data.dto';
import { DataFileType, DataType } from '@dunefront/common/dto/data-storage';
import { IAnnotation, IAxisProps, IGradientLine } from '../../../common-modules/chart/chart-component-helpers/chart-types';
import { ChartAnnotationDto, IAnnotationStyle } from '@dunefront/common/modules/reporting/dto/chart-annotation.dto';
import { CommonDbType } from '@dunefront/common/dto/common-dto.interfaces';
import {
  ChartTemplateType,
  IChartTemplateAxis,
  IChartTemplateDto,
  IChartTemplateProperties,
} from '@dunefront/common/dto/chart-templates.dto';
import { ChartGradientLineDto, IGradientLineStyle } from '@dunefront/common/modules/reporting/dto/chart-gradient-line.dto';
import { IDictionaryWithArray } from '@dunefront/common/common/state.helpers';
import { UnitConverterHelper } from '@dunefront/common/unit-converters/unit.converter.helper';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';

export enum ChartLoadingStatus {
  notLoaded = 0,
  loading = 1,
  loaded = 2,
}

export class ReportingFactory {
  public static toChartAxisPropertyDto(axisProps: IAxisProps): ChartAxisPropertyDto {
    if (axisProps.chartId === undefined || axisProps.id === undefined || axisProps.scenarioId === undefined) {
      throw new Error('Missing chart marker details');
    }
    return {
      Id: axisProps.id,
      ChartId: axisProps.chartId,
      ScenarioId: axisProps.scenarioId,
      AxisType: axisProps.axis,
      AxisTitle: axisProps.title ?? null,
      IsManualLimit: axisProps.manualLimit,
      MinValue: axisProps.manualLimit ? axisProps.min ?? null : null,
      MaxValue: axisProps.manualLimit ? axisProps.max ?? null : null,
      IsOverrideChartAxis: axisProps.overrideStyle && axisProps.style != null,
      AxisTitleFontSize: axisProps.overrideStyle && axisProps.style != null ? axisProps.style.AxisTitleFontSize : null,
      AxisTitleFontColor: axisProps.overrideStyle && axisProps.style != null ? axisProps.style.AxisTitleFontColor : null,
      AxisTitleFontBold: axisProps.overrideStyle && axisProps.style != null ? axisProps.style.AxisTitleFontBold : null,
      AxisTitleFontItalic: axisProps.overrideStyle && axisProps.style != null ? axisProps.style.AxisTitleFontItalic : null,
      AxisLabelFontSize: axisProps.overrideStyle && axisProps.style != null ? axisProps.style.AxisLabelFontSize : null,
      AxisLabelFontColor: axisProps.overrideStyle && axisProps.style != null ? axisProps.style.AxisLabelFontColor : null,
      AxisLabelFontBold: axisProps.overrideStyle && axisProps.style != null ? axisProps.style.AxisLabelFontBold : null,
      AxisLabelFontItalic: axisProps.overrideStyle && axisProps.style != null ? axisProps.style.AxisLabelFontItalic : null,
      SortOrder: axisProps.id,
      IsLogarithmic: axisProps.isLogarithmic,
      XAxisFormat: axisProps.xAxisFormat,
      StartTimeFileId: axisProps.StartTimeFileId ?? null,
    };
  }

  public static createChartAxisPropertyDtosForTemplate(
    scenarioId: number,
    templateAxisProperties: IChartTemplateAxis[] = [],
  ): ChartAxisPropertyDto[] {
    return templateAxisProperties.map((props) =>
      ReportingFactory.toChartAxisPropertyDto({
        id: -1,
        chartId: -1,
        scenarioId,
        axis: props.axisType,
        overrideStyle: false,
        manualLimit: false,
        isLogarithmic: props.isLogarithmic,
        xAxisFormat: props.xAxisFormat,
        StartTimeFileId: null,
      }),
    );
  }

  public static toIAxisProps(dto: ChartAxisPropertyDto): IAxisProps {
    let style: IAxisStyle | undefined = undefined;

    if (
      dto.IsOverrideChartAxis &&
      dto.AxisTitleFontSize != null &&
      dto.AxisTitleFontBold != null &&
      dto.AxisTitleFontItalic != null &&
      dto.AxisLabelFontSize != null &&
      dto.AxisLabelFontBold != null &&
      dto.AxisLabelFontItalic != null &&
      dto.AxisLabelFontColor != null &&
      dto.AxisTitleFontColor != null
    ) {
      style = {
        AxisTitleFontSize: dto.AxisTitleFontSize,
        AxisTitleFontColor: dto.AxisTitleFontColor,
        AxisTitleFontBold: dto.AxisTitleFontBold,
        AxisTitleFontItalic: dto.AxisTitleFontItalic,
        AxisLabelFontSize: dto.AxisLabelFontSize,
        AxisLabelFontColor: dto.AxisLabelFontColor,
        AxisLabelFontBold: dto.AxisLabelFontBold,
        AxisLabelFontItalic: dto.AxisLabelFontItalic,
      };
    }

    return {
      id: dto.Id,
      chartId: dto.ChartId,
      scenarioId: dto.ScenarioId,
      axis: dto.AxisType,
      title: dto.AxisTitle ?? undefined,
      isLogarithmic: dto.IsLogarithmic,
      manualLimit: dto.IsManualLimit,
      min: dto.MinValue ?? undefined,
      max: dto.MaxValue ?? undefined,
      overrideStyle: style != null,
      xAxisFormat: dto.XAxisFormat,
      StartTimeFileId: dto.StartTimeFileId,
      style,
    };
  }

  public static toIAxesProps(dtos: ChartAxisPropertyDto[]): IAxisProps[] {
    return dtos.map((dto) => this.toIAxisProps(dto));
  }

  public static toChartMarkerDto(chartMarker: IMarker): IChartMarkerDto {
    if (chartMarker.chartId === undefined || chartMarker.id === undefined || chartMarker.scenarioId === undefined) {
      throw new Error('Missing chart marker details');
    }
    return {
      Value: chartMarker.value,
      IsValueAxisMarker: chartMarker.isValueAxisMarker,
      ChartId: chartMarker.chartId,
      SortOrder: chartMarker.sortOrder ?? 0,
      IsChartMarkerFontBold: chartMarker.style.IsChartMarkerFontBold,
      ChartMarkerFontColor: chartMarker.style.ChartMarkerFontColor,
      IsChartMarkerFontItalic: chartMarker.style.IsChartMarkerFontItalic,
      ChartMarkerFontSize: chartMarker.style.ChartMarkerFontSize,
      ChartMarkerHorizontalTextAlignment: chartMarker.style.ChartMarkerHorizontalTextAlignment ?? HorizontalAlignment.start,
      ChartMarkerHorizontalTextIndentation: chartMarker.style.ChartMarkerHorizontalTextIndentation ?? 0,
      ChartMarkerLineColor: chartMarker.style.ChartMarkerLineColor,
      ChartMarkerLineStyle: chartMarker.style.ChartMarkerLineStyle,
      ChartMarkerLineThickness: chartMarker.style.ChartMarkerLineThickness,
      ChartMarkerVerticalTextAlignment: chartMarker.style.ChartMarkerVerticalTextAlignment ?? VerticalAlignment.end,
      ChartMarkerVerticalTextIndentation: chartMarker.style.ChartMarkerVerticalTextIndentation ?? 0,
      Id: chartMarker.id,
      IsOverrideChartMarker: chartMarker.isOverrideStyle,
      ScenarioId: chartMarker.scenarioId,
      Text: chartMarker.name,
    };
  }

  public static toIMarkers(chartMarkerDtos: IChartMarkerDto[]): IMarker[] {
    return chartMarkerDtos.map((dto) => this.toIMarker(dto));
  }

  public static toIMarker(chartMarkerDto: IChartMarkerDto): IMarker {
    if (chartMarkerDto.ChartId === undefined || chartMarkerDto.Id === undefined || chartMarkerDto.ScenarioId === undefined) {
      throw new Error('Missing chart marker details');
    }
    return {
      value: chartMarkerDto.Value,
      isValueAxisMarker: chartMarkerDto.IsValueAxisMarker,
      chartId: chartMarkerDto.ChartId,
      id: chartMarkerDto.Id,
      sortOrder: chartMarkerDto.SortOrder,
      isOverrideStyle: chartMarkerDto.IsOverrideChartMarker,
      scenarioId: chartMarkerDto.ScenarioId,
      name: chartMarkerDto.Text,
      style: {
        IsChartMarkerFontBold: chartMarkerDto.IsChartMarkerFontBold,
        ChartMarkerFontColor: chartMarkerDto.ChartMarkerFontColor,
        IsChartMarkerFontItalic: chartMarkerDto.IsChartMarkerFontItalic,
        ChartMarkerFontSize: chartMarkerDto.ChartMarkerFontSize,
        ChartMarkerHorizontalTextAlignment: chartMarkerDto.ChartMarkerHorizontalTextAlignment,
        ChartMarkerHorizontalTextIndentation: chartMarkerDto.ChartMarkerHorizontalTextIndentation,
        ChartMarkerLineColor: chartMarkerDto.ChartMarkerLineColor,
        ChartMarkerLineStyle: chartMarkerDto.ChartMarkerLineStyle,
        ChartMarkerLineThickness: chartMarkerDto.ChartMarkerLineThickness,
        ChartMarkerVerticalTextAlignment: chartMarkerDto.ChartMarkerVerticalTextAlignment,
        ChartMarkerVerticalTextIndentation: chartMarkerDto.ChartMarkerVerticalTextIndentation,
      },
    };
  }

  public static toAnnotationDto(annotation: IAnnotation): ChartAnnotationDto {
    return {
      Id: annotation.Id,
      ChartId: annotation.chartId,
      ScenarioId: annotation.ScenarioId,
      SortOrder: annotation.sortOrder,
      Text: annotation.text,
      Argument: annotation.argument,
      Value: annotation.value,
      BoxArgument: annotation.boxArgument,
      BoxValue: annotation.boxValue,
      BoxAngle: annotation.boxAngle,
      BoxDistance: annotation.boxDistance,
      BoxWidth: annotation.boxWidth,
      BoxHeight: annotation.boxHeight,
      IsOverrideAnnotationStyle: annotation.style != null,
      AnnotationFontSize: annotation.style?.AnnotationFontSize ?? null,
      AnnotationFontBold: annotation.style?.AnnotationFontBold ?? null,
      AnnotationFontItalic: annotation.style?.AnnotationFontItalic ?? null,
      AnnotationFontColour: annotation.style?.AnnotationFontColour ?? null,
      AnnotationFillColour: annotation.style?.AnnotationFillColour ?? null,
      AnnotationFillTransparency: annotation.style?.AnnotationFillTransparency ?? null,
      AnnotationTailVisibility: annotation.style?.AnnotationTailVisibility ?? null,
    };
  }

  public static toIAnnotation(dto: ChartAnnotationDto): IAnnotation {
    if (dto.Id === undefined || dto.ChartId === undefined || dto.ScenarioId === undefined) {
      throw new Error('Missing annotation details');
    }

    const {
      IsOverrideAnnotationStyle,
      AnnotationFontSize,
      AnnotationFontBold,
      AnnotationFontItalic,
      AnnotationFontColour,
      AnnotationFillColour,
      AnnotationFillTransparency,
      AnnotationTailVisibility,
    } = dto;

    let style: IAnnotationStyle | undefined;

    if (
      IsOverrideAnnotationStyle &&
      AnnotationFontSize != null &&
      AnnotationFontBold != null &&
      AnnotationFontItalic != null &&
      AnnotationFontColour != null &&
      AnnotationFillColour != null &&
      AnnotationFillTransparency != null &&
      AnnotationTailVisibility != null
    ) {
      style = {
        AnnotationFontSize,
        AnnotationFontBold,
        AnnotationFontItalic,
        AnnotationFontColour,
        AnnotationFillColour,
        AnnotationFillTransparency,
        AnnotationTailVisibility,
      };
    }

    return {
      Id: dto.Id,
      chartId: dto.ChartId,
      ScenarioId: dto.ScenarioId,
      sortOrder: dto.SortOrder,
      text: dto.Text,
      argument: dto.Argument,
      value: dto.Value,
      boxArgument: dto.BoxArgument,
      boxValue: dto.BoxValue,
      boxAngle: dto.BoxAngle,
      boxDistance: dto.BoxDistance,
      boxWidth: dto.BoxWidth,
      boxHeight: dto.BoxHeight,
      style,
    };
  }

  public static toIAnnotations(chartAnnotationDtos: ChartAnnotationDto[]): IAnnotation[] {
    return chartAnnotationDtos.map((dto) => this.toIAnnotation(dto));
  }

  public static toGradientLineDto(line: IGradientLine): ChartGradientLineDto {
    return {
      Id: line.Id,
      ChartId: line.chartId,
      ScenarioId: line.ScenarioId,
      SortOrder: line.sortOrder,
      Argument1: line.argument1,
      Value1: line.value1,
      Argument2: line.argument2,
      Value2: line.value2,
      IsOverrideStyle: line.style != null,
      GradientLineStyle: line.style?.GradientLineStyle ?? null,
      GradientLineColor: line.style?.GradientLineColor ?? null,
      GradientLineThickness: line.style?.GradientLineThickness ?? null,
      PinCalculatedParams: line.pinCalculatedParams,
      GradientLineDecimalPlaces: line.style?.GradientLineDecimalPlaces ?? null,
    };
  }

  public static toIGradientLine(dto: ChartGradientLineDto): IGradientLine {
    if (dto.Id === undefined || dto.ChartId === undefined || dto.ScenarioId === undefined) {
      throw new Error('Missing GradientLine details');
    }

    const { IsOverrideStyle, GradientLineStyle, GradientLineColor, GradientLineThickness, GradientLineDecimalPlaces } = dto;

    let style: IGradientLineStyle | undefined;

    if (
      IsOverrideStyle &&
      GradientLineStyle != null &&
      GradientLineColor != null &&
      GradientLineThickness != null &&
      GradientLineDecimalPlaces != null
    ) {
      style = { GradientLineStyle, GradientLineColor, GradientLineThickness, GradientLineDecimalPlaces };
    }

    return {
      Id: dto.Id,
      chartId: dto.ChartId,
      ScenarioId: dto.ScenarioId,
      sortOrder: dto.SortOrder,
      argument1: dto.Argument1,
      value1: dto.Value1,
      argument2: dto.Argument2,
      value2: dto.Value2,
      pinCalculatedParams: dto.PinCalculatedParams,
      style,
    };
  }

  public static toIGradientLines(chartGradientLineDtos: ChartGradientLineDto[]): IGradientLine[] {
    return chartGradientLineDtos.map((dto) => this.toIGradientLine(dto));
  }

  public static getChartSeriesRows(table: ITableState<IChartResultColumn>, chartId: number, scenarioId: number): ChartSeriesDto[] {
    const chartSeriesArray: ChartSeriesDto[] = [];

    table.rows.forEach((row) => {
      let axisType: ChartAxis | undefined;
      if (row.rowData.PrimaryLeft) {
        axisType = ChartAxis.PrimaryValue;
      } else if (row.rowData.SecondaryLeft) {
        axisType = ChartAxis.SecondaryValue;
      } else if (row.rowData.PrimaryRight) {
        axisType = ChartAxis.OppositePrimaryValue;
      } else if (row.rowData.SecondaryRight) {
        axisType = ChartAxis.OppositeSecondaryValue;
      }
      if (axisType !== undefined) {
        const chartSeriesDto: ChartSeriesDto = {
          AxisType: axisType,
          ChartId: chartId,
          Id: -1,
          ColumnName: row.rowData.ColumnName,
          FileName: row.rowData.FileName,
          FileType: row.rowData.FileType,
          ScenarioId: scenarioId,
          SortOrder: chartSeriesArray.length,
        };
        chartSeriesArray.push(chartSeriesDto);
      }
    });

    // If no primary left lines, move secondary left lines to primary left
    if (chartSeriesArray.findIndex((chartSeriesDto) => chartSeriesDto.AxisType === ChartAxis.PrimaryValue) === -1) {
      chartSeriesArray.forEach((chartSeriesDto) => {
        if (chartSeriesDto.AxisType === ChartAxis.SecondaryValue) {
          chartSeriesDto.AxisType = ChartAxis.PrimaryValue;
        }
      });
    }

    // If no primary right lines, move secondary right lines to primary right
    if (chartSeriesArray.findIndex((chartSeriesDto) => chartSeriesDto.AxisType === ChartAxis.OppositePrimaryValue) === -1) {
      chartSeriesArray.forEach((chartSeriesDto) => {
        if (chartSeriesDto.AxisType === ChartAxis.OppositeSecondaryValue) {
          chartSeriesDto.AxisType = ChartAxis.OppositePrimaryValue;
        }
      });
    }

    // If still no primary left, move primary right to primary left and secondary right to secondary left
    if (chartSeriesArray.findIndex((chartSeriesDto) => chartSeriesDto.AxisType === ChartAxis.PrimaryValue) === -1) {
      chartSeriesArray.forEach((chartSeriesDto) => {
        if (chartSeriesDto.AxisType === ChartAxis.OppositePrimaryValue) {
          chartSeriesDto.AxisType = ChartAxis.PrimaryValue;
        } else if (chartSeriesDto.AxisType === ChartAxis.OppositeSecondaryValue) {
          chartSeriesDto.AxisType = ChartAxis.SecondaryValue;
        }
      });
    }

    return chartSeriesArray;
  }

  public static getNewReportingTabDto(
    isTimeVolume: boolean,
    rangeId: number,
    scenarioId: number,
    reportingTabs: ReportingTabDto[],
    moduleType: ModuleType,
    chartTitle?: string,
  ): ReportingTabDto {
    return {
      ChartDescription: '',
      ChartId: -1,
      SortOrder: -1,
      CurrentTabNo: -1,
      Id: -1,
      IsChartTimeVolume: isTimeVolume,
      RangeId: rangeId,
      ScenarioId: scenarioId,
      TabName: chartTitle != null ? chartTitle : ReportingFactory.getNewReportingTabName(reportingTabs),
      ModuleType: moduleType,
    };
  }

  public static getNewChartDto(scenarioId: number, source: ChartDataSourceType): ChartDto {
    return {
      ...emptyChartDto,
      ScenarioId: scenarioId,
      Source: source,
    };
  }

  public static getNewReportingTabName(reportingTabs: ReportingTabDto[]): string {
    let maxChartTabName = 0;
    reportingTabs.forEach((tab) => {
      if (tab.TabName.toLowerCase().startsWith('chart') && tab.TabName.length > 5) {
        const suffix = tab.TabName.substring(5).trim();
        const suffixNumber = parseFloat(suffix);
        if (suffixNumber > maxChartTabName) {
          maxChartTabName = suffixNumber;
        }
      }
    });

    maxChartTabName = Math.ceil(maxChartTabName) + 1;

    return 'Chart ' + maxChartTabName;
  }

  public static getEmptyChartState(chartDataSourceType?: ChartDataSourceType): ChartState {
    return {
      chartDataSourceType,
      chartData: undefined,
      chartSeries: [],
      requestType: GetChartDataRequestType.Initial,
      chartLoadingStatus: ChartLoadingStatus.loaded,
    };
  }

  public static createChartStateWithData(
    chartDataSourceType: ChartDataSourceType,
    chartData: IChartDataDto,
    chartSeries: ChartSeriesDto[] = [],
  ): ChartState {
    return {
      ...this.getEmptyChartState(chartDataSourceType),
      chartData,
      chartSeries,
      chartLoadingStatus: ChartLoadingStatus.loaded,
    };
  }

  public static createEmptyChartStateWithChartTemplateId(
    argumentDataType: DataType,
    chartDataSourceType: ChartDataSourceType,
    chartDtos: ChartDto[],
    rangeId: number,
  ): ChartState {
    return ReportingFactory.createChartStateWithData(
      chartDataSourceType,
      createEmptyChartDataWithChartTemplateId(argumentDataType, chartDataSourceType, chartDtos, rangeId),
      chartDataSourceType == ChartDataSourceType.ChartSourceResultsDepthBased ? this.createDepthBasedChartSeries(chartDtos) : [],
    );
  }

  private static createDepthBasedChartSeries(chartDtos: ChartDto[]): ChartSeriesDto[] {
    return [
      {
        ChartId: getChartId(chartDtos, ChartDataSourceType.ChartSourceResultsDepthBased),
        AxisType: ChartAxis.PrimaryValue,
        ColumnName: 'Total Perf. Pack Volume',
        FileName: '',
        Id: 0,
        ScenarioId: -1,
        FileType: DataFileType.PackingResult,
        SortOrder: 0,
      },
    ];
  }

  public static getEmptyChartConfig(): IChartConfig {
    return {
      chartId: 0,
      tabId: 0,
      chartDataSourceType: ChartDataSourceType.ChartSourceResultsDepthBased,
      resultColumnsTableState: { rows: [], isValid: true },
    };
  }

  public static getInsertReportingChartAction(
    isTimeVolume: boolean,
    scenarioId: number,
    rangeId: number,
    moduleType: ModuleType,
    reportingTabs: ReportingTabDto[],
    chartSeries: ChartSeriesDto[],
  ): InsertChartAction {
    const chartDto: ChartDto = this.getNewChartDto(scenarioId, ChartDataSourceType.ChartSourceReportingTab);
    const insertChartAction = new InsertChartAction(chartDto);

    const reportingTabDto = ReportingFactory.getNewReportingTabDto(isTimeVolume, rangeId, scenarioId, reportingTabs, moduleType);
    const insertTabAction = new InsertReportingTabAction([reportingTabDto]);
    const insertSeriesAction = new InsertChartSeriesAction(chartSeries);

    insertChartAction.childInsertActions = [insertTabAction, insertSeriesAction];

    return insertChartAction;
  }

  public static createChartTemplate(
    chartConfig: IChartConfig,
    chartUserAddonsDict: IDictionaryWithArray<IChartUserAddons>,
    name: string,
    dbType: CommonDbType,
    isTimeVol: boolean,
    labels: string,
    moduleType: ModuleType,
    partialProperties: Omit<IChartTemplateProperties, 'axis'>,
  ): IChartTemplateDto {
    const series = chartConfig.resultColumnsTableState.rows
      .filter((row) => row.rowData.PrimaryLeft || row.rowData.SecondaryLeft || row.rowData.PrimaryRight || row.rowData.SecondaryRight)
      .map((row) => ({
        chartAxis: row.rowData.PrimaryLeft
          ? ChartAxis.PrimaryValue
          : row.rowData.SecondaryLeft
          ? ChartAxis.SecondaryValue
          : row.rowData.PrimaryRight
          ? ChartAxis.OppositePrimaryValue
          : ChartAxis.OppositeSecondaryValue,
        seriesName: row.rowData.ColumnName,
        dataType: row.rowData.DataType,
        fileType: row.rowData.FileType,
      }));

    const axis: IChartTemplateAxis[] = [];
    const checkIsLogarithmicInSeries = (chartAxis: ChartAxis): boolean =>
      series
        .filter((s) => s.chartAxis === chartAxis)
        .some((s) => UnitConverterHelper.getUnitTypeAndName(s.dataType, UnitSystem.None).Logarithmic);

    // chartId exists only when editing chart - (don't update axis properties when adding new chart)
    if (chartConfig.chartId != null && chartUserAddonsDict.dict[chartConfig.chartId]?.chartAxisProperties) {
      // chart Axis properties have max 1 row for each axis type
      chartUserAddonsDict.dict[chartConfig.chartId]?.chartAxisProperties.forEach((props) => {
        // check if xAxisFormat or isLogarithmic is different from default and save only then
        // isLogarithmic default is calculated on the fly and depends on data type
        if (props.xAxisFormat !== XAxisFormat.deltaTime || props.isLogarithmic !== checkIsLogarithmicInSeries(props.axis)) {
          axis.push({ axisType: props.axis, xAxisFormat: props.xAxisFormat, isLogarithmic: props.isLogarithmic });
        }
      });
    }

    return {
      Id: -1,
      Type: dbType,
      Name: name,
      DefaultFileTypes: [],
      IsTimeVolume: isTimeVol,
      Modules: [moduleType],
      ChartType: ChartTemplateType.UserDefinedChart,
      Series: series,
      Properties: {
        ...partialProperties,
        axis,
      },
      Labels: labels,
    };
  }
}
