import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ValidatedWellModuleState } from '@dunefront/common/modules/well/well-module.state';
import { LocationType, TreatmentType } from '@dunefront/common/modules/well/dto/well.dto';
import { EnumHelpers } from '@dunefront/common/utils/enum.helpers';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { TemperatureProfile } from '@dunefront/common/modules/well/model/temperature-profile/temperature-profile';
import { ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';

@Component({
  selector: 'app-location-type',
  templateUrl: './location-type.component.html',
  styleUrls: ['./location-type.component.scss'],
})
export class LocationTypeComponent {
  @Input() public state: ValidatedWellModuleState | undefined;
  @Output() public wellPropertyChanged = new EventEmitter<ObjectChangeProp<ValidatedWellModuleState>>();
  @Output() public temperatureProfileChanged = new EventEmitter<ObjectChangeProp<TemperatureProfile>>();

  public locationTypeRadioItems = EnumHelpers.EnumToRadioItems(LocationType);
  public treatmentTypeRadioItems = EnumHelpers.EnumToRadioItems(TreatmentType);
  public UnitType = UnitSystem;

  public isLand(): boolean {
    return this.state?.LocationType === LocationType.Land;
  }

  public onTempProfileValueChange(props: ObjectChangeProp<TemperatureProfile>): void {
    if (this.state != null && this.state.TemperatureProfile[props.key] !== props.value) {
      this.temperatureProfileChanged.emit(props);
    }
  }
}
