<div class="grid-form-container main">
  <div class="grid-form-container no-padding inline">
    <app-form-input
      appUnwrap
      label="Activation Pressure"
      [source]="pipe"
      [key]="'ActivationPressure'"
      [unitType]="UnitType.Pressure"
      (valueChanged)="onValueChange($event)"
    ></app-form-input>
  </div>
</div>
