import { BasePptSectionGenerator, PptContext } from '../base-ppt-section-generator';
import { firstValueFrom } from 'rxjs';
import { selectCurrentUnitSystem } from '../../../../../../+store/units/units.selectors';
import { PptDocumentTableGeneratorHelper } from '../ppt-document-table-generator.helper';
import { IUnitSystemDto } from '@dunefront/common/dto/unit-system.dto';
import { Gravel } from '@dunefront/common/modules/gravel/model/gravel';
import { DictionaryWithArray } from '@dunefront/common/common/state.helpers';
import { getValidatedGravels } from '../../../../../../+store/gravel/gravel.selectors';
import { gravelsProperties } from '../../common/grid-configs/gravels';
import { ITableData } from '../../common/common-report-data-helpers';
import { PptHtmlParser } from '../../../generators/ppt/ppt-html-parser';
import { baseTextProps, contentPosition } from '../../../generators/ppt/style-constants';

export class GravelsDataPptGenerator extends BasePptSectionGenerator {
  constructor(context: PptContext) {
    super(context);
  }

  public async generate(): Promise<void> {
    const title = 'Gravel Data';
    const currentUnitSystem: IUnitSystemDto = await firstValueFrom(this.store.select(selectCurrentUnitSystem));
    const gravels: Gravel[] = DictionaryWithArray.getArray(await firstValueFrom(this.store.select(getValidatedGravels)));

    const tableData: ITableData<Gravel> = {
      dataRows: gravels,
      dataCells: gravelsProperties,
    };

    tableData.dataRows.forEach((row) => {
      const singleGravelTableData = {
        dataRows: [row],
        dataCells: tableData.dataCells,
      };

      const slide = this.context.pptx.addSlide();

      BasePptSectionGenerator.addSlideTitle(row.Name, slide);
      BasePptSectionGenerator.addSlideSubTitle(title, slide);
      BasePptSectionGenerator.addTableToSlide(slide, {
        tableRows: PptDocumentTableGeneratorHelper.createTableRows<Gravel>(singleGravelTableData, null, currentUnitSystem),
      });

      const notes = new PptHtmlParser(row.Notes).render();

      slide.addText(notes, {
        ...{ ...contentPosition, y: contentPosition.y + 0.9 },
        ...baseTextProps,
        fontSize: 10,
        align: 'justify',
        valign: 'top',
      });
    });
  }
}
