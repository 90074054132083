import { Routes } from '@angular/router';
import { PsdAnalysisPageComponent } from './psd-analysis-page.component';
import { PsdInputComponent } from './psd-input/psd-input.component';
import { PsdDataComponent } from './psd-input/psd-data/psd-data.component';
import { PsdChartComponent } from './psd-input/psd-chart/psd-chart.component';
import { PsdAnalysisComponent } from './psd-analysis/psd-analysis.component';
import { PsdComparisonComponent } from './psd-analysis/psd-comparison/psd-comparison.component';
import { SandProductionComponent } from './psd-analysis/sand-production/sand-production.component';
import { PsdResultsComponent } from './psd-results/psd-results.component';
import { SummaryComponent } from './psd-results/summary/summary.component';
import { ScreenAndGravelSelectionComponent } from './psd-results/screen-and-gravel-selection/screen-and-gravel-selection.component';
import { LicenseFeature } from '@dunefront/common/modules/licensing/licensing.interfaces';
import { reportingRoutes } from '../common/reporting/reporting.routes';
import { settingsRoutes } from '../common/settings/settings.routes';
import {
  RouteModulePsdAnalysis,
  RouteModulePsdAnalysisPsdChart,
  RouteModulePsdAnalysisPsdComparison,
  RouteModulePsdAnalysisPsdData,
  RouteModulePsdAnalysisPsdInput,
  RouteModulePsdAnalysisResults,
  RouteModulePsdAnalysisSandProduction,
  RouteModulePsdAnalysisScreenAndGravelSelection,
  RouteModulePsdAnalysisSummary,
} from './psd-analysis-routes-names';

export const psdAnalysisRoutes: Routes = [
  {
    path: RouteModulePsdAnalysis,
    component: PsdAnalysisPageComponent,
    data: {
      breadcrumb: 'PSD Module',
      licenseFeature: LicenseFeature.PSD_Analysis,
    },
    children: [
      {
        path: RouteModulePsdAnalysisPsdInput,
        component: PsdInputComponent,
        data: {
          breadcrumb: 'PSD Input',
        },
        children: [
          { path: RouteModulePsdAnalysisPsdData, component: PsdDataComponent },
          { path: RouteModulePsdAnalysisPsdChart, component: PsdChartComponent },
          { path: '', redirectTo: RouteModulePsdAnalysisPsdData, pathMatch: 'full' },
        ],
      },
      {
        path: RouteModulePsdAnalysis,
        component: PsdAnalysisComponent,
        data: {
          breadcrumb: 'PSD Analysis',
        },
        children: [
          { path: RouteModulePsdAnalysisPsdComparison, component: PsdComparisonComponent },
          { path: RouteModulePsdAnalysisSandProduction, component: SandProductionComponent },
          { path: '', redirectTo: RouteModulePsdAnalysisPsdComparison, pathMatch: 'full' },
        ],
      },
      {
        path: RouteModulePsdAnalysisResults,
        component: PsdResultsComponent,
        data: {
          breadcrumb: 'Results',
        },
        children: [
          { path: RouteModulePsdAnalysisSummary, component: SummaryComponent },
          { path: RouteModulePsdAnalysisScreenAndGravelSelection, component: ScreenAndGravelSelectionComponent },
          { path: '', redirectTo: RouteModulePsdAnalysisSummary, pathMatch: 'full' },
        ],
      },
      ...settingsRoutes,
      ...reportingRoutes,
      { path: '', redirectTo: `${RouteModulePsdAnalysisPsdInput}/${RouteModulePsdAnalysisPsdData}`, pathMatch: 'full' },
    ],
  },
];
