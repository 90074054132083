import { ChartAxis, IAxisMargin, IAxisStyle, XAxisFormat } from '@dunefront/common/modules/reporting/dto/chart-axis-property.dto';
import { IUnitSystemDto } from '@dunefront/common/dto/unit-system.dto';
import { IMarker, IMarkerStyle } from '@dunefront/common/modules/reporting/dto/chart-marker.dto';
import { IEffectiveSeriesStyle, IGlobalSeriesStyles } from '@dunefront/common/modules/reporting/dto/chart-series.dto';
import { ILegendStyle } from '@dunefront/common/modules/reporting/dto/chart-legend';
import { ConvertUnitPipe } from '@dunefront/common/modules/units/convert-unit.pipe/convert-unit.pipe';
import { VerticalShiftDto } from '@dunefront/common/dto/trend-analysis/vertical-shift.dto';
import { DeepPartial } from 'chart.js/dist/types/utils';
import { LineAnnotationOptions } from 'chartjs-plugin-annotation';
import { ChartSeriesTemplateDto } from '@dunefront/common/dto/chart-series-template.dto';
import { IAnnotationStyle } from '@dunefront/common/modules/reporting/dto/chart-annotation.dto';
import { ChartZoomedDataService } from './chart-zoomed-data.service';
import { IAxisUnit } from '@dunefront/common/unit-converters/converter.interfaces';
import { BehaviorSubject } from 'rxjs';
import { Chart } from 'chart.js';
import { IChartDataDto, IChartDataDtoColumn } from '@dunefront/common/modules/reporting/dto/chart-data.dto';
import { PartialEnumDictionary } from '@dunefront/common/common/state.helpers';
import { MinMax } from './chart-data-helpers';
import { CrosshairContext } from '../crosshair-plugin/types';
import { IGradientLineStyle } from '@dunefront/common/modules/reporting/dto/chart-gradient-line.dto';
import { ISortedDataset } from '../chart.component';
import { IScenarioDict } from '@dunefront/common/modules/scenario/scenario';

export interface ChartContext extends CrosshairContext {
  readonly isRotated: boolean;
  readonly reverseArgument: boolean;
  readonly allowInteractions: boolean;
  readonly tension: number;
  readonly argumentAxisUnit: IAxisUnit;
  readonly defaultMarkerStyle: IMarkerStyle;
  readonly defaultAnnotationStyle: IAnnotationStyle;
  readonly defaultGradientLineStyle: IGradientLineStyle;
  readonly defaultAxisStyle: IAxisStyle;
  readonly defaultAxisMargin: IAxisMargin;
  readonly defaultLegendStyle: ILegendStyle;
  readonly defaultSeriesStyles: IGlobalSeriesStyles;
  readonly chartSeriesTemplates: ChartSeriesTemplateDto[];
  readonly currentUnitSystem: IUnitSystemDto;
  readonly axesDefaults?: IAxisData[];
  readonly axesProperties: IAxisProps[];
  readonly verticalShifts: VerticalShiftDto[];
  readonly annotations: IAnnotation[];
  readonly markers: IMarker[];
  readonly annotationsVisible$: BehaviorSubject<boolean>;
  readonly markersVisible$: BehaviorSubject<boolean>;
  readonly activeMarker$: BehaviorSubject<DeepPartial<LineAnnotationOptions> | undefined>;
  readonly chartZoomedDataService: ChartZoomedDataService;
  readonly convertUnitPipe: ConvertUnitPipe;
  readonly scenariosToCompare: IScenarioDict;
  readonly mouseMoveMode$: BehaviorSubject<MouseMoveMode>;
  readonly chartDisplay: Chart | undefined;
  readonly activeChartData: IChartDataDto;
  readonly inactiveColumnIds: Set<number> | null;
  readonly closestDataSetIndex?: number;
  readonly sortedAndTrimmedDataSets: ISortedDataset[];
}

export interface IZoomPluginListener {
  onZoomStart(event: Event): void;

  onZoomComplete(): void;

  onPanStart(event: Event): boolean;

  onPanComplete(): void;
}

export interface IAxisData {
  axis: ChartAxis;
  axisUnits: IAxisUnit[];
  title?: string;
}

export interface IAxisProps {
  id?: number;
  chartId?: number;
  scenarioId?: number;
  axis: ChartAxis;
  title?: string | null;
  manualLimit: boolean;
  min?: number;
  max?: number;
  overrideStyle: boolean;
  style?: IAxisStyle;
  isLogarithmic: boolean;
  xAxisFormat: XAxisFormat;
  StartTimeFileId: number | null;
}

export interface IAnnotation {
  Id: number;
  ScenarioId: number;
  chartId: number;
  sortOrder: number;

  text: string;
  argument: number;
  value: number;

  boxArgument: number;
  boxValue: number;
  boxWidth: number;
  boxHeight: number;

  boxAngle: number | null;
  boxDistance: number | null;

  style?: IAnnotationStyle;
}

export interface IGradientLine {
  Id: number;
  ScenarioId: number;
  chartId: number;
  sortOrder: number;
  argument1: number;
  value1: number;
  argument2: number;
  value2: number;
  style?: IGradientLineStyle;
  pinCalculatedParams: boolean;
}

export interface IEditAxisProps {
  axisProps: IAxisProps;
  defaultTitle: string;
  defaultMin: number; // value in current unit system
  defaultMax: number; // value in current unit system
  axisUnit: IAxisUnit;
  currentUnitSystem: IUnitSystemDto;
  defaultStyle: IAxisStyle;
  startDate: number | undefined;
}

export interface IArgumentRange {
  argumentStart: number;
  argumentEnd: number;
}

export enum AllowedXYShiftAxis {
  none = 0,
  x = 1,
  y = 2,
  both = 3,
}

export enum MouseMoveMode {
  default = 0,
  dragMarker = 1,
  dragDataSet = 2,
  dragAnnotation = 3,
  dragGradientLine = 4,
}

export interface IAutoYAxisShiftParams {
  argumentPx: number;
  targetColumnIndex: number;
  context: ChartContext;
  chart: Chart;
  chartData: IChartDataDto;
}

export interface IAutoXAxisShiftParams {
  targetColumnIndex: number;
  chartData: IChartDataDto;
  chartContext: ChartContext;
  argumentStart: number;
  argumentEnd: number;
  axesMinMax: PartialEnumDictionary<ChartAxis, MinMax>;
  verticalShifts: VerticalShiftDto[];
}

export interface ConfigureSeriesPayload {
  template?: ChartSeriesTemplateDto;
  effectiveSeriesStyle: IEffectiveSeriesStyle;
  column: IChartDataDtoColumn;
}
