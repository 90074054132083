import { createSelector } from '@ngrx/store';
import { ILeftNavElement, LeftNavHelpers } from '../../../shared/components/left-nav/left-nav.helpers';
import { getSelectedGravel } from '../../../+store/gravel/gravel.selectors';
import {
  RouteModuleGravels,
  RouteModuleGravelsAdvancedProperties,
  RouteModuleGravelsDatabaseGravels,
  RouteModuleGravelsProjectGravels,
} from './gravel-routes-names';
import {
  getAreGravelsNotUsedInSelectedModuleValid,
  getAreGravelsUsedInSelectedModuleValid,
  getGravelsUsedInSelectedModule,
} from '../../../+store/gravel/gravel-helper.selectors';
import { Gravel } from '@dunefront/common/modules/gravel/model/gravel';
import { isSimulateDisp } from '../../../+store/menu-selectors/menu-selectors.helpers';
import { getAppModuleType } from '../../../+store/ui/ui.selectors';
import { MenuItem } from 'primeng/api';

export const gravelAdvOptionsValid = (gravel: Gravel): boolean => {
  const advOptionsFields: (keyof Gravel)[] = [
    'OroskarAndTurianCoefficient',
    'AngleOfRepose',
    'ThermalConductivity',
    'SpecificHeatCapacity',
    'FrictionMultiplierDeviation',
    'FrictionMultiplier',
  ];
  return advOptionsFields.every((key) => gravel.error[key]?.length === 0);
};

export const getGravelsLeftNavElement$ = createSelector(
  getSelectedGravel,
  getAreGravelsUsedInSelectedModuleValid,
  getAreGravelsNotUsedInSelectedModuleValid,
  getGravelsUsedInSelectedModule,
  getAppModuleType,
  (selectedGravel, usedGravelsValid, unusedGravelsValid, usedGravels, moduleType): ILeftNavElement => {
    const isSelectedGravelUsed = usedGravels.some((gravel) => gravel.Id === selectedGravel?.Id);

    const gravelAdvancedPropertiesAvailable = !isSimulateDisp(moduleType);
    const gravelAdvancedPropertiesHasErrors = isSelectedGravelUsed && (selectedGravel == null || !gravelAdvOptionsValid(selectedGravel));
    const gravelAdvancedPropertiesHasWarnings = !isSelectedGravelUsed && (selectedGravel == null || !gravelAdvOptionsValid(selectedGravel));

    const items: MenuItem[] = [
      {
        label: 'Project gravels',
        routerLink: `${RouteModuleGravels}/${RouteModuleGravelsProjectGravels}/${selectedGravel?.Id}`,
        isError: !usedGravelsValid,
        isWarning: !unusedGravelsValid,
      },
    ];
    if (gravelAdvancedPropertiesAvailable) {
      items.push({
        label: 'Advanced properties',
        routerLink: `${RouteModuleGravels}/${RouteModuleGravelsAdvancedProperties}/${selectedGravel?.Id}`,
        isError: gravelAdvancedPropertiesHasErrors,
        isWarning: gravelAdvancedPropertiesHasWarnings,
      });
    }
    items.push(LeftNavHelpers.getItem('Database gravels', `${RouteModuleGravels}/${RouteModuleGravelsDatabaseGravels}`));

    return LeftNavHelpers.toNavElement({
      label: 'Gravels',
      uri: RouteModuleGravels,
      icon: 'icon-gravel',
      isError: !usedGravelsValid,
      isWarning: !unusedGravelsValid,
      items,
    });
  },
);
