import { ResuspensionCalculatorDto, ResuspensionCalculatorResults } from '../resuspension-calculator.dto';
import { ValidatedResuspensionCalculatorModuleState } from '../resuspension-calculator-module.state';

export class ResuspensionCalculatorFactory {
  public static toDto(resuspensionCalculator: ValidatedResuspensionCalculatorModuleState): ResuspensionCalculatorDto {
    const { error, isValid, isLoaded, ...dto } = resuspensionCalculator;
    return dto;
  }

  public static cleanResultsPartial(): ResuspensionCalculatorResults {
    return {
      ResuspensionRate: 0,
      ResuspensionVelocity: 0,
    };
  }
}
