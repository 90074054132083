import { Routes } from '@angular/router';
import { wellRoutes } from './well/well.routes';
import { completionRoutes } from './completion/completion.routes';
import { evaluatePumpingRoutes, simulatePumpingRoutes } from './pumping/pumping.routes';
import { testPageRoutes } from './test-page/test-page.routes';
import { reportingRoutes } from '../common/reporting/reporting.routes';
import { fluidsRoutes } from '../common/fluids/fluids.routes';
import { gravelsRoutes } from '../common/gravel/gravels.routes';
import { gaugeDataPageRoutes } from '../gauge-data-page/gauge-data-page.routes';
import { resultsSimulateRoutes } from './results/results-simulate.routes';
import { resultsEvaluateRoutes } from './results/results-evaluate.routes';
import { LicenseFeature } from '@dunefront/common/modules/licensing/licensing.interfaces';
import {
  EvaluatePageComponent,
  SimulateCHPageComponent,
  SimulateDispPageComponent,
  SimulatePageComponent,
} from './simulate-evaluate-page.component';
import { RouteModuleEvaluate, RouteModuleSimulate, RouteModuleSimulateCH, RouteModuleSimulateDisp } from './simulate-evaluate-routes-names';
import { settingsRoutes } from '../common/settings/settings.routes';
import { RouteModuleWell, RouteModuleWellGeneralData } from './well/well-routes-names';
import { resultsSimulateDispRoutes } from './results/results-simulate-disp.routes';

const simulateEvaluateCommonChildren: Routes = [
  ...wellRoutes,
  ...completionRoutes,
  ...reportingRoutes,
  ...testPageRoutes,
  ...settingsRoutes,
  ...fluidsRoutes,
  ...gravelsRoutes,
  {
    path: '',
    redirectTo: `${RouteModuleWell}/${RouteModuleWellGeneralData}`,
    pathMatch: 'full',
  },
];

export const simulatePageRoutes: Routes = [
  {
    path: RouteModuleSimulate,
    component: SimulatePageComponent,
    data: {
      breadcrumb: 'Simulate',
      licenseFeature: LicenseFeature.Simulate,
    },
    children: [...simulateEvaluateCommonChildren, ...simulatePumpingRoutes, ...resultsSimulateRoutes],
  },
  {
    path: RouteModuleSimulateCH,
    component: SimulateCHPageComponent,
    data: {
      breadcrumb: 'Simulate CH',
      licenseFeature: LicenseFeature.Simulate_CH,
    },
    children: [...simulateEvaluateCommonChildren, ...simulatePumpingRoutes, ...resultsSimulateRoutes],
  },
  {
    path: RouteModuleSimulateDisp,
    component: SimulateDispPageComponent,
    data: {
      breadcrumb: 'FluidPro',
      licenseFeature: LicenseFeature.Simulate_Disp,
    },
    children: [...simulateEvaluateCommonChildren, ...simulatePumpingRoutes, ...resultsSimulateDispRoutes],
  },
  {
    path: RouteModuleEvaluate,
    component: EvaluatePageComponent,
    data: {
      breadcrumb: 'Evaluate',
      licenseFeature: LicenseFeature.Evaluate,
    },
    children: [...simulateEvaluateCommonChildren, ...evaluatePumpingRoutes, ...gaugeDataPageRoutes, ...resultsEvaluateRoutes],
  },
];
