<div class="grid-form-container">
  <div class="grid-two-one">
    <app-radio-group [items]="items" [value]="1" (primitiveValueChanged)="valueChange($event.value)" id="input1"></app-radio-group>
  </div>
  <div>Short length text input</div>
  <div>
    <input />
  </div>
  <div>Long length text input</div>
  <div>
    <input />
  </div>
  <div>
    <app-check-box
      [value]="false"
      [disabled]="false"
      label="Checkbox text input"
      (primitiveValueChanged)="valueChange($event.value)"
      id="input2"
    >
    </app-check-box>
  </div>
  <div>
    <input />
  </div>
</div>
