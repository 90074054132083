import { NgModule } from '@angular/core';
import { TestPageComponent } from './test-page.component';
import { TabOneComponent } from './tab-one/tab-one.component';
import { TabTwoComponent } from './tab-two/tab-two.component';
import { FormComponentComponent } from './tab-one/form-component/form-component.component';
import { SmallComponentComponent } from './tab-two/small-component/small-component.component';
import { TabThreeComponent } from './tab-three/tab-three.component';
import { CommonModule } from '@angular/common';
import { TabbedPageModule } from '../../../common-modules/tabbed-page/tabbed-page.component';
import { PagePanelModule } from '../../../common-modules/page-panel/page-panel.component';
import { UnitsModule } from '../../../common-modules/units/units.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkTableModule } from '@angular/cdk/table';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { CheckBoxModule } from '../../../shared/components/form-components/check-box/check-box.component';
import { RadioGroupModule } from '../../../shared/components/form-components/radio-group/radio-group.component';
import { ButtonModule } from '../../../shared/components/button/button.module';

@NgModule({
  declarations: [TestPageComponent, TabOneComponent, TabTwoComponent, FormComponentComponent, SmallComponentComponent, TabThreeComponent],
  imports: [
    CommonModule,
    TabbedPageModule,
    PagePanelModule,
    UnitsModule,
    ScrollingModule,
    CdkTableModule,
    MatTableModule,
    MatIconModule,
    CheckBoxModule,
    RadioGroupModule,
    ButtonModule,
  ],
})
export class TestPageModule {}
