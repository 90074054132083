import { noErrors } from '../../../../common/state.helpers';
import { FlowPathType } from '../resuspension-calculator.dto';
import { ConvertUnitPipe } from '../../../units/convert-unit.pipe/convert-unit.pipe';
import { MAX_GRAVEL_CONCENTRATION_SI } from '../../../../common/constants';
import { IError } from '../../../../common/common-state.interfaces';
import { ResuspensionCalculatorModuleState, ValidatedResuspensionCalculatorModuleState } from '../resuspension-calculator-module.state';
import { UnitSystem } from '../../../../dto/unit-system.dto';
import { Validation } from '../../../../common/validation';

export class ResuspensionCalculatorValidation {
  public static validate(state: ResuspensionCalculatorModuleState): ValidatedResuspensionCalculatorModuleState {
    const error: IError<ResuspensionCalculatorModuleState> = {
      FluidId: Validation.ValidateFluid(state.FluidId),
      GravelId: Validation.ValidateGravel(state.GravelId),
      MinDiameter: this.ValidateMinDiameter(state),
      MaxDiameter: this.ValidateMaxDiameter(state),
      Concentration: this.ValidateConcentration(state),
    };

    return { ...state, error, isValid: noErrors(error) };
  }

  private static ValidateMinDiameter(resuspensionCalculator: ResuspensionCalculatorModuleState): string {
    if (resuspensionCalculator.MinDiameter <= 0) {
      return 'ID must be greater than 0';
    }
    if (
      resuspensionCalculator.FlowPathType === FlowPathType.Annulus &&
      resuspensionCalculator.MinDiameter >= resuspensionCalculator.MaxDiameter
    ) {
      return 'ID must be less than OD';
    }
    return '';
  }

  private static ValidateMaxDiameter(resuspensionCalculator: ResuspensionCalculatorModuleState): string {
    if (resuspensionCalculator.FlowPathType === FlowPathType.Annulus && resuspensionCalculator.MaxDiameter <= 0) {
      return 'OD must be greater than 0';
    }
    if (
      resuspensionCalculator.FlowPathType === FlowPathType.Annulus &&
      resuspensionCalculator.MinDiameter >= resuspensionCalculator.MaxDiameter
    ) {
      return 'OD must be greater than ID';
    }
    return '';
  }

  private static ValidateConcentration(resuspensionCalculator: ResuspensionCalculatorModuleState): string {
    if (resuspensionCalculator.Concentration <= 0) {
      return 'Concentration must be greater than 0';
    }

    if (resuspensionCalculator.Concentration > MAX_GRAVEL_CONCENTRATION_SI) {
      const encodeValue = ConvertUnitPipe.encode(UnitSystem.Solid_Concentration, MAX_GRAVEL_CONCENTRATION_SI);
      return `Concentration must be less than or equal to ${encodeValue}`;
    }
    return '';
  }
}
