import { DeleteRowsWsAction, InsertRowsWsAction, UpdateRowsWsAction } from '../../../ws.action';
import { InjectionTestCalculatorDto, InjectionTestReadingDto } from './injection-test-calculator.dto';
import { IUpdateRowsProps, IUpdateTableRowsProps } from '../../../common/common-store-crud.interfaces';
import { WsActionPropsFactory } from '../../../common/ws-action/ws-action-props.factory';
import { InjectionTestReading } from './model/injection-test-reading';
import { InjectionTestReadingFactory } from './model/injection-test-reading.factory';

export const InjectionTestCalculatorModuleName = 'InjectionTestCalculatorModule';

export enum InjectionTestCalculatorModuleActionTypes {
  InjectionTestReadingsInsertRows = '[InjectionTestCalculatorModule] InsertRows',
  InjectionTestReadingsUpdateRow = '[InjectionTestCalculatorModule] UpdateRow',
  InjectionTestReadingsDeleteRows = '[InjectionTestCalculatorModule] DeleteRows',
  UpdateInjectionTestCalculatorAction = '[InjectionTestCalculatorModule][Marker] UpdateRow',
  CalculateInjectionTestAction = '[InjectionTestCalculatorModule] Calculate',
}

export interface LoadInjectionTestCalculatorActionResponse {
  injectionTestCalculatorDto: InjectionTestCalculatorDto;
  injectionTestReadingsDto: InjectionTestReadingDto[];
}

export class InjectionTestInsertRowsAction extends InsertRowsWsAction<InjectionTestReadingDto> {
  public override readonly type = InjectionTestCalculatorModuleActionTypes.InjectionTestReadingsInsertRows;
}

export class InjectionTestUpdateRowAction extends UpdateRowsWsAction<InjectionTestReadingDto> {
  public override readonly type = InjectionTestCalculatorModuleActionTypes.InjectionTestReadingsUpdateRow;

  constructor(action: IUpdateTableRowsProps<InjectionTestReading>) {
    super(WsActionPropsFactory.updateAction(action, InjectionTestReadingFactory.toDto));
  }
}

export class InjectionTestDeleteRowsAction extends DeleteRowsWsAction {
  public override readonly type = InjectionTestCalculatorModuleActionTypes.InjectionTestReadingsDeleteRows;
}

export class UpdateInjectionTestCalculatorAction extends UpdateRowsWsAction<InjectionTestCalculatorDto> {
  public override readonly type = InjectionTestCalculatorModuleActionTypes.UpdateInjectionTestCalculatorAction;

  constructor(action: IUpdateRowsProps<InjectionTestCalculatorDto>) {
    super(WsActionPropsFactory.updateAction(action, (row) => row));
  }
}

export class CalculateInjectionTestAction extends UpdateRowsWsAction<{ ScenarioId: number }> {
  public override readonly type = InjectionTestCalculatorModuleActionTypes.CalculateInjectionTestAction;

  constructor(scenarioId: number) {
    super(WsActionPropsFactory.update([{ ScenarioId: scenarioId }], true));
  }
}

export type InjectionTestCalculatorModuleActions =
  | InjectionTestInsertRowsAction
  | InjectionTestUpdateRowAction
  | InjectionTestDeleteRowsAction
  | UpdateInjectionTestCalculatorAction
  | CalculateInjectionTestAction;
