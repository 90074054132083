<app-grid
  *ngIf="height > 0"
  [dataSource]="runningStringData"
  [gridConfig]="gridConfig"
  [columns]="gridConfig.columns"
  [showCrudControls]="false"
  [isFirstRowInsertingAllowed]="true"
  [height]="height"
  [isMergingOfRowsEnabled]="true"
  (selectedRowsChanged)="selectedRowsChanged.emit($event)"
  dataCy="running-string-grid"
></app-grid>
