import { NgModule } from '@angular/core';
import { FileListComponent } from './file-list.component';
import { PersonalFilesComponent } from './personal-files-component/personal-files.component';
import { SharedFilesComponent } from './shared-files-component/shared-files.component';
import { DemoFilesComponent } from './demo-files-component/demo-files.component';
import { ElectronDemoFilesComponent } from './electron-demo-files-component/electron-demo-files.component';
import { TrashFilesComponent } from './trash-files-component/trash-files.component';
import { RecentFilesComponent } from './recent-files-component/recent-files.component';
import { CommonModule } from '@angular/common';
import { ClickOutsideModule } from '../../../shared/directives/click-outside.directive';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ContextMenuModule } from 'primeng/contextmenu';
import { FileDisplayNamePipeModule } from '../../../shared/pipes/file/file-display-name.pipe.module';
import { FileContextMenuComponent } from './file-context-menu-component/file-context-menu.component';
import { ButtonModule } from '../../../shared/components/button/button.module';

@NgModule({
  declarations: [
    FileListComponent,
    PersonalFilesComponent,
    SharedFilesComponent,
    DemoFilesComponent,
    ElectronDemoFilesComponent,
    TrashFilesComponent,
    RecentFilesComponent,
    FileContextMenuComponent,
  ],
  imports: [CommonModule, ClickOutsideModule, ScrollingModule, ContextMenuModule, FileDisplayNamePipeModule, ButtonModule],
  exports: [
    FileListComponent,
    PersonalFilesComponent,
    SharedFilesComponent,
    DemoFilesComponent,
    ElectronDemoFilesComponent,
    TrashFilesComponent,
    RecentFilesComponent,
    FileContextMenuComponent,
  ],
})
export class FileListModule {}
