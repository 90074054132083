<app-page-panel>
  <app-searchbar (searchChanged)="searchPhrase$.next($event)"></app-searchbar>
</app-page-panel>
<app-page-panel class="grid-panel" header="Database Gravels" (click)="onHelpChange('gravels', 'gravel-database')">
  <ng-container *ngIf="filteredCommonGravels$ | async as filteredCommonGravels">
    <app-database-gravels-grid
      *ngIf="filteredCommonGravels.rows.length"
      #gridComponent
      [customKeyboardHandlers]="getCustomKeyboardHandlers"
      [commonGravelData]="filteredCommonGravels"
      (selectedRowsChanged)="onSelectedRowChanged($event)"
    ></app-database-gravels-grid>
  </ng-container>
</app-page-panel>

<div class="buttons-panel">
  <div class="align-right">
    <button
      appButton
      type="button"
      data-cy="database-gravels-load"
      class="btn btn-primary btn-sm"
      [isUiLockable]="false"
      (buttonClick)="onLoadFromDatabase()"
      [disabled]="!selectedRow"
    >
      Load from Database
    </button>
    <button
      appButton
      type="button"
      data-cy="database-gravels-delete"
      class="btn btn-primary btn-sm"
      [isUiLockable]="false"
      (buttonClick)="onDelete()"
      [disabled]="!selectedRow || selectedRow.rowData.commonDbType === 'Predefined'"
    >
      Delete
    </button>
  </div>
</div>
