import {
  OroskarAndTurianDiameter,
  SettlingCalculatorDto,
  SettlingCalculatorResultDto,
} from '@dunefront/common/modules/calculators/dto/settling-calculator.dto';
import { IModuleState, IValidatedModuleState } from '@dunefront/common/common/common-state.interfaces';
import { FlowPathType } from '@dunefront/common/modules/calculators/resuspension-calculator/resuspension-calculator.dto';
import { FrictionXAxis } from '@dunefront/common/modules/calculators/friction-calculator/friction-calculator.dto';

export interface SettlingCalculatorModuleState extends IModuleState {
  SettlingCalculator: SettlingCalculatorDto;
  SettlingCalculatorResult: SettlingCalculatorResultDto[];
}

export interface ValidatedSettilingCalculatorModuleState
  extends SettlingCalculatorModuleState,
    IValidatedModuleState<SettlingCalculatorModuleState> {
  SettlingCalculator: ValidatedSettlingCalculator;
}

export type ValidatedSettlingCalculator = SettlingCalculatorDto & IValidatedModuleState<SettlingCalculatorDto>;

export const SettlingCalculatorInitialState: SettlingCalculatorModuleState = {
  SettlingCalculator: {
    StaticViscosity: 0,
    Concentration: 0,
    Deviation: 0,
    Eccentricity: 0,
    FlowPathType: FlowPathType.Pipe,
    FluidId: -1,
    GravelId: -1,
    IsXAxisRate: FrictionXAxis.Velocity,
    MaxDiameter: 0,
    MaxRate: 0,
    MinDiameter: 0,
    OroskarAndTurianDiameter: OroskarAndTurianDiameter.Equivalent,
    ScenarioId: -1,
    StokesSettlingVelocity: 0,
    Temperature: 0,
  },
  SettlingCalculatorResult: [],
  isLoaded: false,
};
