import { PipeDto } from '../../../../dto/pipe.dto';

export interface CasingPipeDto extends PipeDto {
  PerforationVolume: number;
  PerforationShotDensity: number;
  PerforationTunnelLength: number;
  PerforationDiameter: number;
  PerforationPhasingType: PerforationPhasingTypeEnum;
  OpenPerforationPercentage: number;
  SortOrder: number;
  Description: string;
}

export enum PerforationPhasingTypeEnum {
  None = 0,
  deg_0 = 1,
  deg_45 = 2,
  deg_60 = 3,
  deg_90 = 4,
  deg_120 = 5,
  deg_180 = 6,
}
