<div class="psd-data-container" *ngIf="psdData?.selectedPSD">
  <app-page-panel header="PSD Definition" (click)="onHelpChange('psd', 'psd-definition')">
    <div class="grid-form-container psd-def-grid">
      <label>Select PSD</label>
      <app-psd-selector> </app-psd-selector>
      <app-form-input
        class="grid-two-one ml-2"
        label="Description"
        [source]="psdData.selectedPSD"
        key="Description"
        [isTextInput]="true"
        [unitType]="UnitType.None"
        [maxWidth]="300"
        [minWidth]="300"
        (valueChanged)="updatePSD($event)"
        dataCy="psd-description"
      >
      </app-form-input>
      <app-form-input
        appUnwrap
        class="grid-two-one"
        label="Depth"
        [source]="psdData.selectedPSD"
        key="Depth"
        [decimalPlaces]="2"
        [maxWidth]="305"
        [minWidth]="305"
        [unitType]="UnitType.Long_Length"
        (valueChanged)="updatePSD($event)"
        dataCy="psd-depth"
      >
      </app-form-input>
      <div class="align-right ml-2">
        <button appButton type="button" class="btn btn-primary btn-sm mr-1" (buttonClick)="addPSD()" data-cy="add-psd-button">
          Add PSD
        </button>
        <button appButton type="button" class="btn btn-primary btn-sm" (buttonClick)="deletePSD()">Delete PSD</button>
      </div>
    </div>
  </app-page-panel>
  <app-page-panel header="PSD Test Data" (click)="onHelpChange('psd', 'psd-test-data')" [panelHelpMode]="PanelHelpMode.GRID">
    <app-psd-data-grid
      #gridComponent
      [psdReadings]="psdData.selectedPSDReadings"
      [psd]="psdData.selectedPSD"
      (psdInputTypeValueChange)="updatePSD($event)"
    >
    </app-psd-data-grid>
  </app-page-panel>
</div>
