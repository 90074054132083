<app-grid
  *ngIf="height > 0 && rheometerReadingData"
  [dataSource]="rheometerReadingData"
  [gridConfig]="gridConfig"
  [columns]="gridConfig.columns"
  (selectedRowsChanged)="selectedRowsChanged.emit($event)"
  [showCrudControls]="false"
  [height]="height"
  [isFirstRowDeleteAllowed]="true"
  [minRowCount]="1"
  [isInsertingRowDisabled]="!isSpeedTypeCustom"
  [isDeletingDisabled]="!isSpeedTypeCustom"
  [isInsertingDisabled]="true"
  [isFirstRowInsertingAllowed]="true"
  dataCy="rheometer-test-data-grid"
></app-grid>
