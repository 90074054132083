import { PipeType } from '../../../../dto/pipe.dto';
import { RunningStringPipeDto } from '../../../../dto/running-string-pipe.dto';
import { BypassPorts } from '../../bypass-ports';
import { GaugeCarrierPipe, GaugeCarrierPipeFactory } from './gauge-carrier-pipe';
import { Pipe } from '../../pipe';
import { ITableRow } from '../../../../common/common-grid.interfaces';

export interface ConcentricGaugeCarrierPipe extends GaugeCarrierPipe {
  PipeType: PipeType.Concentric_Gauge_Carrier;
  FlowPorts: BypassPorts;
}

export class ConcentricGaugeCarrierPipeFactory {
  public static createFromRunningString(runningStringDto: RunningStringPipeDto): ConcentricGaugeCarrierPipe {
    const gaugeCarrierPipe = GaugeCarrierPipeFactory.createFromRunningString(runningStringDto);
    const concentricGaugeCarrierPipe: ConcentricGaugeCarrierPipe = {
      ...gaugeCarrierPipe,
      PipeType: PipeType.Concentric_Gauge_Carrier,
      FlowPorts: {
        NoOfPorts: runningStringDto.NoOfPorts,
        PortLength: runningStringDto.PortLength,
        PortDiameter: runningStringDto.PortDiameter,
        error: {},
      },
    };
    return concentricGaugeCarrierPipe;
  }
}

export function isConcentricGaugeCarrierPipeRow(row: ITableRow<Pipe>): row is ITableRow<ConcentricGaugeCarrierPipe> {
  return isConcentricGaugeCarrierPipe(row.rowData);
}

export function isConcentricGaugeCarrierPipe(pipe: Pipe): pipe is ConcentricGaugeCarrierPipe {
  return pipe.PipeType === PipeType.Concentric_Gauge_Carrier;
}
