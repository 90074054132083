<div class="input-container align-left" [class.error]="!!errorMessage">
  <p-calendar
    #calendar
    tooltipPosition="top"
    tooltipStyleClass="error-tooltip"
    dateFormat="dd-mm-yy"
    hourFormat="24"
    appendTo="body"
    [showSeconds]="true"
    [showButtonBar]="true"
    [disabled]="isReadOnlyMode"
    [(ngModel)]="dateCalendar"
    [showTime]="showTimePicker"
    [pTooltip]="errorMessage"
    [style.width.px]="inputWidth"
    (onBlur)="emitChange()"
    (onInput)="onDateUpdated($event)"
    (onClose)="emitChange()"
  ></p-calendar>
</div>
