import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatTableModule } from '@angular/material/table';
import { TableVirtualScrollModule } from 'ng-table-virtual-scroll';
import { PagePanelModule } from '../../page-panel/page-panel.component';
import { RadioGroupModule } from '../../../shared/components/form-components/radio-group/radio-group.component';
import { FormInputModule } from '../../../shared/components/form-components/form-input/form-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SelectModule } from '../../../shared/components/form-components/select/select.module';
import { UnwrapDirectiveModule } from '../../../shared/directives/unwrap.directive';
import { CheckBoxModule } from '../../../shared/components/form-components/check-box/check-box.component';
import { UnitsModule } from '../../units/units.module';
import { ButtonModule } from '../../../shared/components/button/button.module';
import { ProgressBarModule } from 'primeng/progressbar';
import { NgxFileDropModule } from '../../ngx-file-drop/ngx-file-drop.module';
import { ModalsModule } from '../modals.module';
import { HelpUrlPipeModule } from '../../../shared/pipes/help-url.pipe';
import { PasteAreaComponent } from './paste-area/paste-area.component';
import { PasteDataGridComponent } from './paste-data-grid/paste-data-grid.component';
import { PasteDataComponent } from './paste-data.component';
import { ImportPasteDataCommonModule } from '../import-paste-data-common/import-paste-data-common.module';

@NgModule({
  declarations: [PasteDataComponent, PasteAreaComponent, PasteDataGridComponent],
  providers: [],
  imports: [
    CommonModule,
    RouterModule,
    NgxFileDropModule,
    ScrollingModule,
    MatTableModule,
    TableVirtualScrollModule,
    PagePanelModule,
    RadioGroupModule,
    FormInputModule,
    SelectModule,
    ReactiveFormsModule,
    FormsModule,
    SelectModule,
    UnwrapDirectiveModule,
    CheckBoxModule,
    UnitsModule,
    ButtonModule,
    ProgressBarModule,
    ModalsModule,
    HelpUrlPipeModule,
    ImportPasteDataCommonModule,
  ],
})
export class PasteDataModule {}
