import { BasePptSectionGenerator, PptContext } from '../base-ppt-section-generator';
import { baseTextProps, reportTitlePosition } from '../style-constants';

export class TitlePptGenerator extends BasePptSectionGenerator {
  constructor(context: PptContext) {
    super(context);
  }

  public async generate(): Promise<void> {
    const pptx = this.context.pptx;
    const slide = BasePptSectionGenerator.addSlide(pptx, {});
    slide.addText('PackPro Report', {
      ...reportTitlePosition,
      ...baseTextProps,
      align: 'center',
      valign: 'middle',
      fontSize: 24,
    });
  }
}
