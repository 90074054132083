import { ISimpleBlockData } from '../interfaces';

export const makeSimpleBlock = (data: ISimpleBlockData): any => {
  let flags = 0;
  if (data.keyframe) {
    flags |= 128;
  }
  if (data.invisible) {
    flags |= 8;
  }
  if (data.lacing) {
    flags |= data.lacing << 1;
  }
  if (data.discardable) {
    flags |= 1;
  }
  if (data.trackNum > 127) {
    throw 'TrackNumber > 127 not supported';
  }
  const out =
    [data.trackNum | 0x80, data.timecode >> 8, data.timecode & 0xff, flags]
      .map(function (e) {
        return String.fromCharCode(e);
      })
      .join('') + data.frame;

  return out;
};
