<div class="wrapper with-help" [class.help-open]="isHelpOpen">
  <app-generic-modal
    [title]="modalTitle"
    [modalButtonsConfigs]="modalButtonsConfigs"
    (helpClick)="onHelpClick($event)"
    [showHelpBtn]="true"
  >
    <div class="tabs white">
      <div class="tab" [class.tab-selected]="activeTab === 'basic'">
        <a (click)="activeTab = 'basic'" data-cy="scenario-manager-basic"> Basic Operations </a>
      </div>
      <div class="tab" [class.tab-selected]="activeTab === 'clone'">
        <a (click)="activeTab = 'clone'" data-cy="scenario-manager-clone"> Clone Operations </a>
      </div>
    </div>

    <div class="modal-content-container">
      <app-scenario-manager-basic-operations *ngIf="activeTab === 'basic'"
                                             (simulationsStarted)="onSimulationsStarted()">
      </app-scenario-manager-basic-operations>
      <app-scenario-manager-clone-operations *ngIf="activeTab === 'clone'"></app-scenario-manager-clone-operations>
    </div>
  </app-generic-modal>
</div>

<div class="modal-help" [class.open]="isHelpOpen">
  <div class="help-content">
    <iframe [src]="'assets/help-files/toolbars/scenario-manager.html' | helpUrl"></iframe>
  </div>
</div>
