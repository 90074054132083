import { createReducer, on } from '@ngrx/store';
import { loadScenarioDataAction, loadScenarioDataSuccessAction, resetScenarioState, updateRowSuccess } from '../app.actions';
import { UiReducerHelper } from './ui.reducer.helper';
import { ChartMode, initialUiModuleState, isChartInMarkerMode, UiMode, UiState, XYShiftAxis } from './ui-module.state';
import { v4 as uuidv4 } from 'uuid';
import {
  autoYAxisShiftSaveAction,
  cancelEditRangeAction,
  editRangeAction,
  newRangeAction,
  resetYAxisShiftAction,
  saveRangeAction,
} from '../range/range.actions';
import { routerRequestAction } from '@ngrx/router-store';
import { loadCommonDbInitialSuccess } from '../common-db/common-db.actions';
import {
  chartModeAction,
  chartResetZoomAction,
  chartZoomModeAction,
  clearCalculatorsTabAction,
  copyImageAction,
  notificationsLoadedAction,
  notificationsStatusLoadedAction,
  ribbonMenuChangedAction,
  setAnnotationsVisibleAction,
  setAppModuleAction,
  setAppSectionAction,
  setCalculatorsTabAction,
  setCompletionTabAction,
  setFluidsTabAction,
  setGaugeDataTabAction,
  setGradientLinesVisibleAction,
  setGravelsTabAction,
  setHelpPinnedAction,
  setIsRibbonDisplayedAction,
  setMarkersVisibleAction,
  setOneTimeInstructionPopupShownAction,
  setPsdAnalysisTabAction,
  setPumpingTabAction,
  setReportingTabAction,
  setResultsTabAction,
  setShouldShowBeforeUnloadWarningAction,
  setSideNavPinnedAction,
  setTrendAnalysisTabAction,
  setWellTabAction,
  xAxisAutoShiftAction,
  xAxisShiftAction,
  yAxisAutoShiftAction,
  yAxisShiftAction,
} from './ui.actions';

export const UiModuleFeatureName = 'ui';

export const uiReducer = createReducer<UiState>(
  initialUiModuleState,
  on(
    resetScenarioState,
    (state): UiState => ({
      ...initialUiModuleState,
      isHelpPinned: state.isHelpPinned,
      isSideNavPinned: state.isSideNavPinned,
      exportDataUseTimestamps: state.exportDataUseTimestamps,
      chartZoomMode: state.chartZoomMode,
      notificationsStates: state.notificationsStates,
      notifications: state.notifications,
    }),
  ),
  on(routerRequestAction, (state): UiState => UiReducerHelper.resetButtonsAfterRedirect(state)),
  on(loadScenarioDataAction, (state): UiState => ({ ...state, uiMode: UiMode.loading })),
  on(setAppModuleAction, (state, action): UiState => ({ ...state, appModuleType: action.appModuleType })),
  on(
    setAppSectionAction,
    (state, action): UiState => ({
      ...state,
      appModuleSection: action.section,
    }),
  ),
  on(setWellTabAction, (state, action): UiState => ({ ...state, wellTab: action.tab })),
  on(setResultsTabAction, (state, action): UiState => ({ ...state, resultsTab: action.tab })),
  on(
    setReportingTabAction,
    (state, action): UiState => ({
      ...state,
      reportingTab: action.tabType,
    }),
  ),
  on(
    setGaugeDataTabAction,
    (state, action): UiState => ({
      ...state,
      gaugeDataTab: action.tab,
      xyAxisShift: XYShiftAxis.none,
      isYAutoShiftActive: false,
    }),
  ),
  on(
    setTrendAnalysisTabAction,
    (state, action): UiState => ({
      ...state,
      trendAnalysisTab: action.tab,
      appModuleSection: 'trend-analysis',
    }),
  ),
  on(
    setCompletionTabAction,
    (state, action): UiState => ({
      ...state,
      completionTab: action.tab,
    }),
  ),
  on(
    setFluidsTabAction,
    (state, action): UiState => ({
      ...state,
      fluidsTab: action.tab,
    }),
  ),
  on(
    setGravelsTabAction,
    (state, action): UiState => ({
      ...state,
      gravelsTab: action.tab,
    }),
  ),
  on(
    setPumpingTabAction,
    (state, action): UiState => ({
      ...state,
      pumpingTab: action.tab,
    }),
  ),
  on(
    setPsdAnalysisTabAction,
    (state, action): UiState => ({
      ...state,
      psdAnalysisTab: action.tab,
    }),
  ),
  on(
    setCalculatorsTabAction,
    (state, action): UiState => ({
      ...state,
      calculatorsTab: action.tab,
      appModuleSection: 'calculators',
    }),
  ),
  on(clearCalculatorsTabAction, (state): UiState => ({ ...state, calculatorsTab: null })),
  on(xAxisShiftAction, (state): UiState => UiReducerHelper.xAxisShiftAction(state)),
  on(yAxisShiftAction, (state): UiState => UiReducerHelper.yAxisShiftAction(state)),
  on(yAxisAutoShiftAction, (state): UiState => UiReducerHelper.yAxisAutoShiftAction(state)),
  on(xAxisAutoShiftAction, (state): UiState => UiReducerHelper.xAxisAutoShiftAction(state)),
  on(
    autoYAxisShiftSaveAction,
    (state): UiState => ({
      ...UiReducerHelper.chartModeAction(ChartMode.default, state),
      isYAutoShiftActive: false,
    }),
  ),
  on(
    resetYAxisShiftAction,
    (state): UiState => ({
      ...UiReducerHelper.chartModeAction(ChartMode.default, state),
      xyAxisShift: XYShiftAxis.none,
      isYAutoShiftActive: false,
      evaluateResetAutoShift: uuidv4(),
    }),
  ),
  on(loadScenarioDataSuccessAction, (state): UiState => ({ ...state, uiMode: UiMode.ready })),
  on(chartZoomModeAction, (state, action): UiState => ({ ...state, chartZoomMode: action.chartZoomMode })),
  on(
    chartResetZoomAction,
    (state, action): UiState => ({
      ...state,
      chartZoomOriginalSize: {
        uuid: uuidv4(),
        mode: action.mode,
      },
    }),
  ),
  on(copyImageAction, (state, action): UiState => ({ ...state, copyImage: action.imageName })),
  on(chartModeAction, (state, action): UiState => UiReducerHelper.chartModeAction(action.chartMode, state)),
  on(
    setAnnotationsVisibleAction,
    (state, action): UiState => ({
      // when hiding annotations and chart is in annotation mode - it should switch to default mode
      ...(action.visible === false && state.chartMode === ChartMode.annotate
        ? UiReducerHelper.chartModeAction(ChartMode.default, state)
        : state),
      annotationsVisible: action.visible,
    }),
  ),
  on(
    setMarkersVisibleAction,
    (state, action): UiState => ({
      // when hiding markers and chart is in marker edit mode - it should switch to default mode
      ...(action.visible === false && isChartInMarkerMode(state.chartMode)
        ? UiReducerHelper.chartModeAction(ChartMode.default, state)
        : state),
      markersVisible: action.visible,
    }),
  ),
  on(
    setGradientLinesVisibleAction,
    (state, action): UiState => ({
      // if chart is in editGradientLine mode, when hiding gradient lines, it should switch to default mode
      ...(action.visible === false && state.chartMode === ChartMode.editGradientLine
        ? UiReducerHelper.chartModeAction(ChartMode.default, state)
        : state),
      gradientLinesVisible: action.visible,
    }),
  ),
  on(editRangeAction, (state): UiState => UiReducerHelper.chartModeAction(ChartMode.editVerticalMarker, state)),
  on(newRangeAction, (state): UiState => UiReducerHelper.chartModeAction(ChartMode.editVerticalMarker, state)),
  on(cancelEditRangeAction, (state): UiState => UiReducerHelper.chartModeAction(ChartMode.default, state)),
  on(saveRangeAction, (state): UiState => UiReducerHelper.chartModeAction(ChartMode.default, state)),

  on(
    setShouldShowBeforeUnloadWarningAction,
    (state, action): UiState => ({ ...state, shouldShowBeforeUnloadWarning: action.shouldShowWarning }),
  ),
  on(
    setIsRibbonDisplayedAction,
    (state, action): UiState => ({
      ...state,
      isRibbonDisplayed: action.isRibbonDisplayed,
    }),
  ),
  on(
    setSideNavPinnedAction,
    (state, action): UiState => ({
      ...state,
      isSideNavPinned: action.isSideNavPinned,
    }),
  ),
  on(
    setHelpPinnedAction,
    (state, action): UiState => ({
      ...state,
      isHelpPinned: action.isHelpPinned,
    }),
  ),
  on(
    setOneTimeInstructionPopupShownAction,
    (state, action): UiState => ({
      ...state,
      isOneTimeInstructionShown: { ...state.isOneTimeInstructionShown, [action.instructionType]: true },
    }),
  ),
  on(
    ribbonMenuChangedAction,
    (state, action): UiState => ({
      ...state,
      isRibbonDisplayed: state.currentMenuTab === action.chartTab ? !state.isRibbonDisplayed : true,
      currentMenuTab: action.chartTab,
    }),
  ),
  on(loadCommonDbInitialSuccess, (state, action): UiState => UiReducerHelper.updateCustomSettings(state, action.payload.customSettings)),
  on(updateRowSuccess, (state, action) => UiReducerHelper.onUpdateRowsSuccess(state, action)),
  on(
    notificationsStatusLoadedAction,
    (state, action): UiState => ({
      ...state,
      notificationsStates: action.notificationsStates,
    }),
  ),
  on(notificationsLoadedAction, (state, action): UiState => ({ ...state, notifications: action.notifications })),
);
