import { Store } from '@ngrx/store';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { getSelectData } from '../../../../+store/scenario/scenario.selectors';
import { Observable } from 'rxjs';
import { ISelectItem } from '@dunefront/common/common/select.helpers';

@Component({
  selector: 'app-scenario-manager-selector',
  templateUrl: './scenario-manager-selector.component.html',
  styleUrls: ['./scenario-manager-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScenarioManagerSelectorComponent {
  @Input() public dataCy = 'scenario-manager-selector';
  @Input() public value!: number;
  @Input() public class!: string;
  @Output() public valueChanged = new EventEmitter<number>();
  public selectData$: Observable<ISelectItem<number>[]> = this.store.select(getSelectData);

  constructor(protected store: Store) {}
}
