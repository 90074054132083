import { IReportTableColumnConfig } from '../common-report-data-helpers';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { ColumnType } from '../../../../../components/grid/grid.interfaces';
import { IValidatedScenarioRangeProperties } from '@dunefront/common/modules/range/model/range';

export const trendAnalysisInputs: IReportTableColumnConfig<IValidatedScenarioRangeProperties>[] = [
  {
    colId: 'TrendAnalysisXOverMD',
    headerText: 'Cross-Over MD',
    unitSystem: UnitSystem.Long_Length,
    type: ColumnType.number,
  },
  {
    colId: 'TrendAnalysisWashpipeMD',
    headerText: 'Washpipe Bottom MD',
    unitSystem: UnitSystem.Long_Length,
    type: ColumnType.number,
  },
  {
    colId: 'TrendAnalysisToolPosition',
    headerText: 'Tool Position',
    unitSystem: UnitSystem.None,
    type: ColumnType.select,
  },
  {
    colId: 'TrendAnalysisPumpingDirection',
    headerText: 'Pumping Direction',
    unitSystem: UnitSystem.None,
    type: ColumnType.select,
  },
  {
    colId: 'PumpRateColumnName',
    headerText: 'Pump Rate',
    unitSystem: UnitSystem.None,
    type: ColumnType.text,
  },
  {
    colId: 'PumpPressureColumnName',
    headerText: 'Pump Pressure',
    unitSystem: UnitSystem.None,
    type: ColumnType.text,
  },
];
