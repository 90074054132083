import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DictionaryWithArray, IDictionaryWithArray } from '@dunefront/common/common/state.helpers';
import { DataStorageModuleState } from '@dunefront/common/modules/data-storage/data-storage-module.state';
import { DataFileType } from '@dunefront/common/dto/data-storage';
import { DataStorageValidation, IValidatedStorageFileWithColumns } from '@dunefront/common/modules/data-storage/data-storage.validation';
import { EquationHashHelper } from '@dunefront/common/modules/equation/equation-hash.helper';
import { getCurrentRangeDetails, getCurrentRangeVerticalShifts, getRanges } from '../range/range.selectors';
import { ImportFileWithColumnsFactory } from '@dunefront/common/modules/data-storage/dto/import-file-with-columns';
import { ImportFileDto, ImportFileFactory, ImportFileWithRangeHashDto } from '@dunefront/common/modules/data-storage/dto/import-file.dto';
import { RangeConstants, RangeDto } from '@dunefront/common/dto/range.dto';
import { getCurrentAppModuleType } from '../ui/ui.selectors';
import { ArgumentType } from '@dunefront/common/modules/data-storage/dto/import-column.dto';
import { selectUserGlobalOptions } from '../common-db/common-db.selectors';

import { isSimulateBased } from '@dunefront/common/modules/scenario/scenario.dto';
import { getMaxSurveyMD } from '../well/well.selectors';

export const getDataStorageModuleState = createFeatureSelector<DataStorageModuleState>('dataStorage');
export const getStorageFiles = createSelector(getDataStorageModuleState, (state) => state.files);
export const getStorageFilesArray = createSelector(getStorageFiles, (files) => DictionaryWithArray.getArray(files));
export const getStorageFileNames = createSelector(getStorageFilesArray, (files) => files.map((file) => file.FileName));
export const getStorageFilesArrayWithRangeHash = createSelector(
  getStorageFilesArray,
  getRanges,
  (files, ranges): ImportFileWithRangeHashDto[] =>
    files.map((file) => {
      let rangeHash = '';
      if (file.RangeId > RangeConstants.EntireRangeId) {
        const range = DictionaryWithArray.get(ranges, file.RangeId);
        if (range != null) {
          rangeHash = `${range.RangeStart}-${range.RangeEnd}`;
        }
      }
      return { ...file, RangeHash: rangeHash };
    }),
);
export const getAnyFilesImported = createSelector(getStorageFiles, (files) => files.ids.length > 0);

export const getStorageColumns = createSelector(getDataStorageModuleState, (state) => state.columns);
export const getAllStorageColumnsArray = createSelector(getStorageColumns, (columnsDict) =>
  DictionaryWithArray.getArray(columnsDict).reduce((a, b) => a.concat(b), []),
);
export const getStorageColumnNames = createSelector(getDataStorageModuleState, (state) => state.columnNames);
export const getImportDataStorageFiles = createSelector(getStorageFilesArray, (files) =>
  files.filter((f) => f.FileType === DataFileType.ImportedData || f.FileType === DataFileType.EquationResult),
);

export const getMaxImportColumnGaugeDepth = createSelector(getAllStorageColumnsArray, (allColumns) =>
  Math.max(...allColumns.map((col) => col.MeasuredDepth)),
);

export const getValidatedStorageFilesWithColumns = createSelector(
  getStorageFilesArrayWithRangeHash,
  getAllStorageColumnsArray,
  getRanges,
  selectUserGlobalOptions,
  getMaxSurveyMD,
  getCurrentAppModuleType,
  (
    storageFiles,
    storageColumns,
    ranges,
    globalOptions,
    maxSurveyMD,
    moduleType,
  ): IDictionaryWithArray<IValidatedStorageFileWithColumns> => {
    const fileArray = storageFiles.map((file) => {
      const equationHash = EquationHashHelper.getEquationSourceFileHash(file.Id, file.RangeHash, storageFiles, storageColumns);
      const equationValidationError = DataStorageValidation.isEquationValid(file, equationHash);
      const columns = DataStorageValidation.validateColumns(
        storageColumns.filter((col) => col.FileId === file.Id),
        storageFiles,
        storageColumns,
        globalOptions.ImportFileMaxTimespan,
        maxSurveyMD,
        moduleType,
      );
      const isValid = columns.every((col) => col.isValid && !equationValidationError);
      const updatedFile = formatFilename(file, ranges);
      return { file: updatedFile, columns, isValid, equationValidationError };
    });

    return DictionaryWithArray.createByFn(fileArray, (file) => file.file.Id + '');
  },
);

export const formatFilename = (file: ImportFileDto, ranges: IDictionaryWithArray<RangeDto>): ImportFileDto => {
  const updatedFile = {
    ...file,
    FileName: file.FileName + (file.FileType === DataFileType.EquationResult ? ' (Equations)' : ''),
  };

  if (file.RangeId > RangeConstants.EntireRangeId) {
    const range = DictionaryWithArray.get(ranges, file.RangeId);

    if (range != null) {
      let filename = updatedFile.FileName.substring(0, updatedFile.FileName.length - 1);
      filename += ', Range: ' + range.Name + ')';
      updatedFile.FileName = filename;
    }
  }
  return updatedFile;
};

export const getIsLoaded = createSelector(getDataStorageModuleState, (state) => state.isLoaded);

export const getAnyFileHasXShift = createSelector(getStorageFilesArray, (files) => files.some((file) => file.XAxisShift !== 0));

export const getImportFilesForAdvancedValidation = createSelector(
  getStorageFiles,
  getStorageColumns,
  getCurrentRangeDetails,
  getCurrentAppModuleType,
  getCurrentRangeVerticalShifts,
  (files, columns, range, moduleType, verticalShifts) => {
    if (
      isSimulateBased(moduleType) ||
      !range.currentRange ||
      (range.currentRangeIdForData ?? RangeConstants.EntireRangeId) <= RangeConstants.EntireRangeId
    ) {
      return null;
    }

    const currentRange = range.currentRange;
    const rangeImportFiles = DictionaryWithArray.getArray(files).filter((file) =>
      ImportFileFactory.isFileInsideRange(file, currentRange, DictionaryWithArray.get(columns, file.Id) ?? []),
    );

    return ImportFileWithColumnsFactory.createImportFilesForAdvancedValidation(
      DictionaryWithArray.createFromArray(rangeImportFiles),
      columns,
      verticalShifts,
    );
  },
);

export const getStartDateOfMostRecentlyImportedFile = createSelector(getStorageFilesArray, getStorageColumns, (files, columns) => {
  // all import files ordered Desc by SortOrder - more recently added goes first
  const importFiles = files.filter((file) => file.FileType === DataFileType.ImportedData).sort((f1, f2) => f2.SortOrder - f1.SortOrder);

  for (const file of importFiles) {
    const fileColumns = DictionaryWithArray.get(columns, file.Id);
    const startDate = fileColumns?.find((column) => column.IsXAxis === ArgumentType.Primary_Argument && column.StartDate != 0)?.StartDate;

    if (startDate != null) {
      return startDate;
    }
  }

  return undefined;
});
