<ng-container>
  <main>
    <router-outlet></router-outlet>
  </main>
</ng-container>
<app-overlay></app-overlay>
<app-error></app-error>
<app-undo-redo></app-undo-redo>
<app-connection-status></app-connection-status>
<app-dev-console></app-dev-console>
