import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { GaugeMeasurementType } from '@dunefront/common/dto/running-string-pipe.dto';
import { Store } from '@ngrx/store';
import { CompletionModuleState } from '@dunefront/common/modules/completion/completion-module.state';
import {
  calculateVolumesSectionAction,
  changeCompletionProperty,
  resetVolumeCalculatorResults,
} from '../../../../../+store/completion/completion.actions';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { ISelectItem } from '@dunefront/common/common/select.helpers';
import { Volumes } from '@dunefront/common/modules/completion/model/volumes/volumes';
import { getVolumeSectionCalculatorResult } from '../../../../../+store/completion/validated-completion.selectors';
import { changeProp, ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';

@Component({
  selector: 'app-section-volume-calculator',
  templateUrl: './section-volume-calculator.component.html',
  styleUrls: ['./section-volume-calculator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class SectionVolumeCalculatorComponent {
  @Output() public completionChanged = new EventEmitter<ObjectChangeProp<CompletionModuleState>>();
  @Input() public completion!: CompletionModuleState | null;
  @Output() public gravelChanged = new EventEmitter();

  public sectionInfo$ = this.store.select(getVolumeSectionCalculatorResult);

  constructor(private store: Store) {
    this.store.dispatch(resetVolumeCalculatorResults());
  }

  public UnitType = UnitSystem;

  public GaugeLocationItems: ISelectItem<GaugeMeasurementType>[] = [
    { text: 'Internal', value: GaugeMeasurementType.Internal },
    { text: 'External', value: GaugeMeasurementType.External },
  ];

  public onVolumesPropertyChanged(props: ObjectChangeProp<Volumes>): void {
    if (!this.completion) {
      return;
    }

    const newVolumes = changeProp(this.completion.Volumes, props);

    this.store.dispatch(
      changeCompletionProperty({
        key: 'Volumes',
        value: newVolumes,
        shouldResetResults: false,
      }),
    );
  }

  public onCalculate(): void {
    this.store.dispatch(calculateVolumesSectionAction({ calculationType: 'single' }));
  }
}
