import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { RouterHelperService } from '../../../shared/services/router-helper.service';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';
import { getIsAdmin } from '../../../+store/auth/auth.selectors';
import { filter } from 'rxjs/operators';
import { RouteModuleHome } from '../../../pages/home/home-page-routes-names';

@Injectable()
export class AdminAuthGuard {
  constructor(
    private store: Store,
    private routerHelperService: RouterHelperService,
  ) {}

  public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const isAdmin = await firstValueFrom(this.store.select(getIsAdmin).pipe(filter((isAdmin) => isAdmin != null)));

    if (isAdmin) {
      return true;
    }

    await this.routerHelperService.navigate([RouteModuleHome]);
    return false;
  }

  public async canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return await this.canActivate(childRoute, state);
  }
}
