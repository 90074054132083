import { ChangeDetectorRef, Directive, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { InputsHelperService } from '../../services/inputs-helper.service';
import { getIsUiLocked } from '../../../+store/ui/calc-engine-ui.selectors';
import { Subscription } from 'rxjs';
import { implementsError, isTableRowDataSource } from '@dunefront/common/common/common-grid.interfaces';
import {
  DataSource,
  DataSourceKey,
  DataSourceValue,
  ObjectChangeProp,
  PrimitiveChangeValue,
} from '@dunefront/common/common/common-state.interfaces';
import { getIsCurrentScenarioCalculationActive } from '../../../+store/reporting/reporting.selectors';

export class FormDataHelper {
  public static getSourceValue<T>(source: DataSource<T> | undefined, key: DataSourceKey<T> | undefined): DataSourceValue<T> | undefined {
    if (key != null && source != null) {
      return isTableRowDataSource(source) ? source.rowData[key] : source[key];
    }
    return undefined;
  }

  public static isDataSourceValid<T>(source: DataSource<T> | undefined, key: DataSourceKey<T> | undefined): boolean {
    return source != null && key != null;
  }

  public static checkMissingInputs<T>(
    source: DataSource<T> | undefined,
    key: DataSourceKey<T> | undefined,
    valueChanged: EventEmitter<ObjectChangeProp<T>>,
    dataCy: string,
    isReadOnlyMode: boolean,
  ): void {
    if (isReadOnlyMode) {
      return;
    }
    if (!FormDataHelper.isDataSourceValid(source, key) && valueChanged.observed) {
      throw new Error(`You need to provide source and key properties for component ${dataCy}`);
    }
  }
}

@Directive()
export abstract class BaseFormComponent<T, ItemType extends DataSourceValue<T>> implements OnDestroy, OnInit, OnChanges {
  @Input() public isUiLockable = true;
  @Input() public dataCy = '';
  @Input() public source: DataSource<T> | undefined;
  @Input() public sourceDefaults: DataSource<Partial<T>> | undefined;
  @Input() public key: DataSourceKey<T> | undefined;
  @Input() public id = '';
  @Input() public disabled = false;
  @Input() public showErrorWhenDisabled = false;
  @Input() public overrideErrorMessage: string | undefined | null = '';
  @Input() public highlight = false;

  @Input()
  public set value(value: any) {
    this._overrideValue = value;
  }

  public get value(): ItemType | undefined {
    return this._value as ItemType;
  }

  @Output() public valueChanged = new EventEmitter<ObjectChangeProp<T>>();
  @Output() public primitiveValueChanged = new EventEmitter<PrimitiveChangeValue<ItemType>>();

  protected _overrideValue: ItemType | undefined;
  protected _value: DataSourceValue<T> | undefined;
  protected _storeValue: DataSourceValue<T> | undefined;
  protected _isUiLocked = false;
  protected _isCalculationActive = false;

  protected subscription = new Subscription();

  constructor(
    private store: Store,
    private cdRef: ChangeDetectorRef,
    private inputsHelperService: InputsHelperService,
  ) {
    this.subscription.add(
      this.store.select(getIsUiLocked).subscribe((isUiLocked) => {
        if (this._isUiLocked !== isUiLocked) {
          this._isUiLocked = isUiLocked;
          this.cdRef.markForCheck();
        }
      }),
    );

    this.subscription.add(
      this.store.select(getIsCurrentScenarioCalculationActive).subscribe((isCalculationActive) => {
        this._isCalculationActive = isCalculationActive;
        this.cdRef.markForCheck();
      }),
    );
  }

  public get isDisabled(): boolean {
    return this.disabled || (this.isUiLockable && this._isUiLocked) || this._isCalculationActive;
  }

  public async onChange(value: ItemType): Promise<void> {
    if (await this.inputsHelperService.checkResultsAndDeleteIfNeeded(this.isUiLockable)) {
      if (this.key != null) {
        this.valueChanged.emit({ key: this.key, value, shouldResetResults: this.isUiLockable });
      }
      this.primitiveValueChanged.emit({ value, shouldResetResults: this.isUiLockable });
      this._storeValue = value;
      return;
    }
    // 4 lines below are the hacky way to make change detection update with previous value
    this._value = value;
    this.cdRef.detectChanges();
    this._value = this._storeValue;
    this.cdRef.markForCheck();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    FormDataHelper.checkMissingInputs(this.source, this.key, this.valueChanged, this.dataCy, this.isDisabled);
    if ((changes.key != null || changes.source != null) && FormDataHelper.isDataSourceValid(this.source, this.key)) {
      this._storeValue = FormDataHelper.getSourceValue(this.source, this.key);
      this._value = this._storeValue;
    } else if (changes.value != null) {
      this._value = this._overrideValue;
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public ngOnInit(): void {
    FormDataHelper.checkMissingInputs(this.source, this.key, this.valueChanged, this.dataCy, this.isDisabled);
  }

  public get errorMessage(): string {
    if (this.overrideErrorMessage) {
      return this.overrideErrorMessage;
    }

    if (this.key == null || this.source == null) {
      return '';
    }
    return !this.disabled && implementsError(this.source) ? this.source.error[this.key] ?? '' : '';
  }

  public get isInErrorState(): boolean {
    return !!this.errorMessage && (this.showErrorWhenDisabled || !this.disabled);
  }

  public get tooltipMessage(): string {
    return this.errorMessage != '' ? this.errorMessage : this.highlight ? this.getDefaultTooltip() : '';
  }

  public abstract getDefaultTooltip(): string;

  public get tooltipStyle(): string {
    return this.errorMessage != '' ? 'error-tooltip' : 'regular-tooltip';
  }
}
