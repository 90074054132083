import { ELECTRON_ACTION_GET_BACKEND_CONFIG_EVENT } from './common/electron/electron.actions';
import { ipcRenderer } from 'electron';
import { isElectron } from './common/electron/is-electron';

export interface IBackendToClientConfig {
  SentryEnvironment: string | null;
  IsRunningInTestMode: boolean;
  SentryDSN: string | null;
  ServerBaseUrl: string;
}

declare const BackendToClientConfig: IBackendToClientConfig;
export const getBackendToClientConfig = (): IBackendToClientConfig => {
  if (isElectron()) {
    // call Electron for sentry environment
    const ipc = (<any>window).require('electron').ipcRenderer as typeof ipcRenderer;
    return ipc.sendSync(ELECTRON_ACTION_GET_BACKEND_CONFIG_EVENT);
  } else {
    // get variable from index.html script
    return BackendToClientConfig;
  }
};
