import { CompletionModuleState, ValidatedCompletionModuleState } from '../completion-module.state';
import { LowerCompletionValidation } from './lower-completion/lower-completion.validation';
import { RunningStringValidation } from './running-string/running-string.validation';
import { ShuntTubeValidation } from '../../shunt-tube/shunt-tube.validation';
import { VolumesValidation } from './volumes/volumes.validation';
import { noErrors } from '../../../common/state.helpers';
import { PipeType } from '../../../dto/pipe.dto';
import { IError } from '../../../common/common-state.interfaces';
import { WellModuleState } from '../../well/well-module.state';
import { PumpingStateByRange } from '../../pumping/pumping-module.state';
import { DeveloperSettingsDto, SettingsDto } from '../../settings/dto/settingsDto';
import { IGetCurrentFeaturesResult } from '../../licensing/licensing.interfaces';
import { ModuleType } from '../../scenario/scenario.dto';
import { getRowsForCalculations } from '../../../common/common-grid.interfaces';

export interface ICompletionValidationDependencies {
  well: WellModuleState;
  settings: SettingsDto;
  pumpingState: PumpingStateByRange;
  developerSettings: DeveloperSettingsDto;
  currentLicenseFeatures: IGetCurrentFeaturesResult;
  currentModuleType: ModuleType;
}

export class CompletionValidation {
  public static IsShuntTubeEnabled(completion: CompletionModuleState): boolean {
    return (
      completion.LowerCompletion.rows.some((row) => row.rowData.PipeType === PipeType.Shunted_Blank_Pipe) ||
      completion.LowerCompletion.rows.some((row) => row.rowData.PipeType === PipeType.Shunted_Screen)
    );
  }

  public static validate(completion: CompletionModuleState, deps: ICompletionValidationDependencies): ValidatedCompletionModuleState {

    if (!completion.isLoaded) {
      return {
        ...completion,
        isValid: true,
        isLowerCompletionScreenValid: true,
        isLowerCompletionScreenWarning: false,
        isRunningStringScreenValid: true,
        isRunningStringScreenWarning: false,
        isShuntTubesScreenValid: true,
        error: {},
      };
    }

    const [newLowerCompletion, newIcdPortData] = LowerCompletionValidation.ValidateLowerCompletionGrid(completion, deps);
    const newRunningString = RunningStringValidation.ValidateRunningStringGrid(completion, deps);
    const newShuntTube = ShuntTubeValidation.ValidateShuntTubesTab(completion, deps);
    const newVolumes = VolumesValidation.ValidateVolume(completion, deps.pumpingState.pumping);

    const error: IError<CompletionModuleState> = {
      CentralizerOD: this.ValidateScreenCentralizerOD(completion, deps),
      WashpipeEccentricity: this.ValidateWashpipeEccentricity(completion, deps),
    };

    newLowerCompletion.isValid = newLowerCompletion.isValid && !error.CentralizerOD;
    newRunningString.isValid = newRunningString.isValid && !error.WashpipeEccentricity;

    const isValid = newLowerCompletion.isValid && newRunningString.isValid && newShuntTube.isValid && noErrors(error);

    const newCompletion: ValidatedCompletionModuleState = {
      ...completion,
      isValid,
      error,
      LowerCompletion: newLowerCompletion,
      RunningString: newRunningString,
      ShuntTube: newShuntTube,
      Volumes: newVolumes,
      IcdPortData: newIcdPortData,
      isShuntTubesScreenValid: newShuntTube.isValid,
      isRunningStringScreenValid: newRunningString.isValid,
      isRunningStringScreenWarning: newRunningString.isWarning ?? false,
      isLowerCompletionScreenValid: newLowerCompletion.isValid,
      isLowerCompletionScreenWarning: newLowerCompletion.isWarning ?? false,
    };
    return newCompletion;
  }

  public static ValidateScreenCentralizerOD(completion: CompletionModuleState, deps: ICompletionValidationDependencies): string {

    const maxCasingID = Math.max(...getRowsForCalculations(deps.well.CasingData.rows).map(r => r.InnerDiameter))

    if(completion.HasCentralizers && completion.CentralizerOD > maxCasingID ) {
      return 'Centralizer OD must be less than or equal to max ID in casing data'
    }


    if (completion.HasCentralizers && completion.CentralizerOD <= 0) {
      return 'Centralizer OD must be greater than zero';
    }

    return '';
  }

  private static ValidateWashpipeEccentricity(completion: CompletionModuleState, deps: ICompletionValidationDependencies): string {
    if (!deps.settings.IsFrictionEccentric) {
      return '';
    }
    if (completion.WashpipeEccentricity < 0) {
      return 'Washpipe eccentricity must be greater than or equal to 0';
    }
    if (completion.WashpipeEccentricity > 1) {
      return 'Washpipe eccentricity must be less than or equal to 1';
    }

    return '';
  }
}
