import { Chart } from 'chart.js';
import { ChartContext, IAnnotation } from './chart-types';
import { getAxisId, getAxisUnit, getChartAxisFromAxisId, getFirstAvailableValueScale } from './chart-misc-helpers';
import { ChartAxis } from '@dunefront/common/modules/reporting/dto/chart-axis-property.dto';
import { UnitConverterHelper } from '@dunefront/common/unit-converters/unit.converter.helper';
import { updateChart } from './chart-common-helpers';
import { DeepPartial } from 'chart.js/dist/types/utils';
import { DuneFrontAnnotationOptions } from '../annotations-plugin/types';
import { DuneFrontAnnotationId } from '../annotations-plugin/dunefront-annotations';
import { IAnnotationStyle } from '@dunefront/common/modules/reporting/dto/chart-annotation.dto';
import { toRadians } from 'chart.js/helpers';

export interface CreateAnnotationPayload {
  argument: number;
  value: number;
  context: ChartContext;
  chart: Chart;
}

export const getAnnotationPluginOptions = (chart: Chart): DeepPartial<DuneFrontAnnotationOptions> | undefined => {
  return (chart?.options?.plugins as any | undefined)?.[DuneFrontAnnotationId];
};

export const migrateAnnotations = (annotations: IAnnotation[], context: ChartContext, chart: Chart): IAnnotation[] | undefined => {
  if (annotations.length === 0) {
    return undefined;
  }

  const valueScaleId = getFirstAvailableValueScale(chart, context.isRotated)?.id;
  if (valueScaleId == null) {
    return undefined;
  }

  const valueAxis = getChartAxisFromAxisId(valueScaleId);
  const valueAxisUnit = getAxisUnit(valueAxis, context.axesDefaults);
  if (valueAxisUnit == null) {
    return undefined;
  }

  const pluginOptions = getAnnotationPluginOptions(chart);
  if (pluginOptions == null) {
    return undefined;
  }

  const { currentUnitSystem, isRotated, argumentAxisUnit } = context;

  const argumentScaleId = getAxisId(ChartAxis.Argument, isRotated);

  const xScaleID = isRotated ? valueScaleId : argumentScaleId;
  const yScaleID = isRotated ? argumentScaleId : valueScaleId;

  const xAxisUnitSystem = isRotated ? valueAxisUnit.unitSystem : argumentAxisUnit.unitSystem;
  const yAxisUnitSystem = isRotated ? argumentAxisUnit.unitSystem : valueAxisUnit.unitSystem;

  const xScale = chart.scales[xScaleID];
  const yScale = chart.scales[yScaleID];

  if (xScale == null || yScale == null) {
    return undefined;
  }

  const migratedAnnotations: IAnnotation[] = [];

  for (const annotation of annotations) {
    if (annotation.boxAngle != null && annotation.boxDistance != null) {
      const xValueSi = isRotated ? annotation.value : annotation.argument;
      const yValueSi = isRotated ? annotation.argument : annotation.value;

      const xValue = UnitConverterHelper.convertFromSi(xAxisUnitSystem, currentUnitSystem, xValueSi);
      const yValue = UnitConverterHelper.convertFromSi(yAxisUnitSystem, currentUnitSystem, yValueSi);

      const xValuePx = xScale.getPixelForValue(xValue);
      const yValuePx = yScale.getPixelForValue(yValue);

      const boxXPx = xValuePx + Math.cos(toRadians(annotation.boxAngle)) * annotation.boxDistance;
      const boxYPx = yValuePx - Math.sin(toRadians(annotation.boxAngle)) * annotation.boxDistance;

      const boxX = xScale.getValueForPixel(boxXPx);
      const boxY = yScale.getValueForPixel(boxYPx);

      const boxXSi = boxX != null ? UnitConverterHelper.convertToSi(xAxisUnitSystem, currentUnitSystem, boxX) : xValueSi;
      const boxYSi = boxY != null ? UnitConverterHelper.convertToSi(yAxisUnitSystem, currentUnitSystem, boxY) : yValueSi;

      migratedAnnotations.push({
        ...annotation,
        boxArgument: isRotated ? boxYSi : boxXSi,
        boxValue: isRotated ? boxXSi : boxYSi,
        boxAngle: null,
        boxDistance: null,
      });
    }
  }

  return migratedAnnotations;
};

export const checkAnnotations = (
  annotations: IAnnotation[],
  annotationsVisible: boolean,
  sizeMultiplier: number,
  context: ChartContext,
  chart: Chart,
): void => {
  const pluginOptions = getAnnotationPluginOptions(chart);
  if (pluginOptions == null) {
    return;
  }

  const valueScaleId = getFirstAvailableValueScale(chart, context.isRotated)?.id;
  if (valueScaleId == null) {
    return;
  }

  const valueAxis = getChartAxisFromAxisId(valueScaleId);
  const valueAxisUnit = getAxisUnit(valueAxis, context.axesDefaults);
  if (valueAxisUnit == null) {
    return;
  }

  const argumentScaleId = getAxisId(ChartAxis.Argument, context.isRotated);

  const xScaleID = context.isRotated ? valueScaleId : argumentScaleId;
  const yScaleID = context.isRotated ? argumentScaleId : valueScaleId;

  const xAxisUnit = context.isRotated ? valueAxisUnit : context.argumentAxisUnit;
  const yAxisUnit = context.isRotated ? context.argumentAxisUnit : valueAxisUnit;

  const styleWithMultiplier = (style: IAnnotationStyle): IAnnotationStyle => ({
    ...style,
    AnnotationFontSize: style.AnnotationFontSize * sizeMultiplier,
  });

  const newAnnotations = annotationsVisible ? annotations : [];

  pluginOptions.annotations = newAnnotations.map((a) => ({
    id: a.Id,
    xValue: UnitConverterHelper.convertFromSi(xAxisUnit.unitSystem, context.currentUnitSystem, context.isRotated ? a.value : a.argument),
    yValue: UnitConverterHelper.convertFromSi(yAxisUnit.unitSystem, context.currentUnitSystem, context.isRotated ? a.argument : a.value),
    text: a.text,
    centerX: UnitConverterHelper.convertFromSi(
      xAxisUnit.unitSystem,
      context.currentUnitSystem,
      context.isRotated ? a.boxValue : a.boxArgument,
    ),
    centerY: UnitConverterHelper.convertFromSi(
      yAxisUnit.unitSystem,
      context.currentUnitSystem,
      context.isRotated ? a.boxArgument : a.boxValue,
    ),
    width: a.boxWidth * sizeMultiplier,
    height: a.boxHeight * sizeMultiplier,
    xScaleID,
    yScaleID,
    sizeMultiplier: sizeMultiplier,
    style: styleWithMultiplier(a.style ?? context.defaultAnnotationStyle),
  }));

  updateChart(chart);
};
