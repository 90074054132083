import { ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { DbDependentComponent } from '../../../../common-modules/db-connection/db-dependent.component';
import { createSelector, Store } from '@ngrx/store';
import { PumpingDirection, ToolPosition } from '@dunefront/common/modules/pumping/dto/pumping.dto';
import { Observable } from 'rxjs';
import * as uiActions from '../../../../+store/ui/ui.actions';
import { getReferenceVariablesState } from '../../../../+store/reference-variables/reference-variables.selectors';
import { getHasOpenHole } from '../../../../+store/well/well.selectors';
import { getPumpingDirection, getPumpingToolPosition } from '../../../../+store/pumping/selectors/pumping.selectors';
import { loadReferenceVariablesAction } from '../../../../+store/reference-variables/reference-variables.actions';
import html2canvas from 'html2canvas';
import { ModalService } from '../../../../common-modules/modals/modal.service';
import { Base64Image } from '../../../../common-modules/chart/image-provider.helpers';
import { DrawableContentProviderComponent, DrawableRegistryService } from '../../../../shared/services/drawable-registry.service';

const SCHEMATIC_IMAGE_NAME = 'Schematic';

export type test = () => void;

@Component({
  selector: 'app-schematic',
  templateUrl: './schematic.component.html',
  styleUrls: ['./schematic.component.scss'],
})
export class SchematicComponent extends DbDependentComponent implements OnDestroy, DrawableContentProviderComponent {
  @ViewChild('schematic') public schematic!: ElementRef<HTMLDivElement>;
  public data$!: Observable<ISchematicModel | undefined>;

  @Input() public drawableProviderId = SCHEMATIC_IMAGE_NAME;

  public ToolPosition = ToolPosition;
  public isLoading!: boolean;

  private schematicData = createSelector(
    getReferenceVariablesState,
    getHasOpenHole,
    getPumpingToolPosition,
    getPumpingDirection,
    (...[state, hasOpenHole, toolPosition, pumpingDirection]) => {
      this.isLoading = state.isLoading;
      if (!state.isLoaded || !state.referenceVariables) {
        return;
      }

      const rv = state.referenceVariables;

      const workstringAndTreatingLines: ISchematicData = {
        TopMD: rv.WorkstringTopMD,
        BottomMD: rv.WorkstringBottomMD,
        Volume: rv.WorkstringVolume,
      };
      const outerAnnulusBelowTopOfScreens: ISchematicData = {
        TopMD: rv.OuterAnnulusBelowTOSTopMD,
        BottomMD: rv.OuterAnnulusBelowTOSBottomMD,
        Volume: rv.OuterAnnulusBelowTOSVolume,
      };
      const innerAnnulusBelowTopOfScreens: ISchematicData = {
        TopMD: rv.InnerAnnulusBelowTOSTopMD,
        BottomMD: rv.InnerAnnulusBelowTOSBottomMD,
        Volume: rv.InnerAnnulusBelowTOSVolume,
      };
      const casingAnnulusAndReturnLines: ISchematicData = {
        TopMD: rv.CasingAnnulusTopMD,
        BottomMD: rv.CasingAnnulusBottomMD,
        Volume: rv.CasingAnnulusVolume,
      };
      const blankOuterAnnulus: ISchematicData = {
        TopMD: rv.BlankAnnulusTopMD,
        BottomMD: rv.BlankAnnulusBottomMD,
        Volume: rv.BlankAnnulusVolume,
      };
      const washpipe: ISchematicData = {
        TopMD: rv.WashpipeTopMD,
        BottomMD: rv.WashpipeBottomMD,
        Volume: rv.WashpipeVolume,
      };
      return {
        workstringAndTreatingLines,
        outerAnnulusBelowTopOfScreens,
        innerAnnulusBelowTopOfScreens,
        casingAnnulusAndReturnLines,
        blankOuterAnnulus,
        washpipe,
        imageRef: this.getImageRef(hasOpenHole, toolPosition, pumpingDirection),
        toolPosition,
      };
    },
  );

  constructor(
    store: Store,
    cdRef: ChangeDetectorRef,
    protected modalService: ModalService,
    private drawableRegistryService: DrawableRegistryService,
  ) {
    super(store, cdRef);
    this.data$ = store.select(this.schematicData);
    super.onHelpChange('completion', 'schematics');
    store.dispatch(uiActions.setCompletionTabAction({ tab: 'schematic' }));
  }

  public override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.drawableRegistryService.unregisterProvider(this.drawableProviderId);
  }

  public onImageLoaded(): void {
    this.drawableRegistryService.registerProvider({
      id: this.drawableProviderId,
      getDisplayName: () => SCHEMATIC_IMAGE_NAME,
      getBase64Image: () => this.getBase64Image(),
    });
  }

  protected override onStateLoaded(): void {
    super.onStateLoaded();
    this.store.dispatch(loadReferenceVariablesAction({ scenarioId: this.currentScenarioId }));
  }

  private getImageRef(hasOpenHole: boolean, toolPosition: ToolPosition, pumpingDirection: PumpingDirection): string {
    const annulusSuffix = pumpingDirection === PumpingDirection.In_Annulus ? 'Annulus' : '';
    if (hasOpenHole) {
      switch (toolPosition) {
        case ToolPosition.Circulating_GP:
          return `./assets/schema-files/OpenHoleCirculating${annulusSuffix}.png`;
        case ToolPosition.Reverse:
          return `./assets/schema-files/OpenHoleReverse${annulusSuffix}.png`;
        case ToolPosition.Reverse_Port_GP:
          return `./assets/schema-files/OpenHoleRPGP${annulusSuffix}.png`;
        case ToolPosition.Washdown:
          return `./assets/schema-files/OpenHoleWashdown${annulusSuffix}.png`;
      }
    }

    switch (toolPosition) {
      case ToolPosition.Circulating_GP:
        return `./assets/schema-files/CasedHoleCirculating${annulusSuffix}.png`;
      case ToolPosition.Reverse:
        return `./assets/schema-files/CasedHoleReverse${annulusSuffix}.png`;
      case ToolPosition.Reverse_Port_GP:
        return `./assets/schema-files/CasedHoleRPGP${annulusSuffix}.png`;
      case ToolPosition.Washdown:
        return `./assets/schema-files/CasedHoleWashdown${annulusSuffix}.png`;
    }
  }

  private async getBase64Image(): Promise<Base64Image | null> {
    const htmlElement = this.schematic.nativeElement;
    if (htmlElement == null) {
      return null;
    }

    const baseURI = document.baseURI;

    const canvas = await html2canvas(htmlElement, {
      scale: 2,
    });
    const { width, height } = canvas;

    // fix base tag
    const base = document.getElementsByTagName('head')[0].getElementsByTagName('base')[0];
    base.setAttribute('href', baseURI);

    const imageDataBase64 = canvas.toDataURL();

    canvas.remove();

    return {
      imageDataBase64,
      size: { width, height },
    };
  }
}

export interface ISchematicData {
  TopMD: number;
  BottomMD: number;
  Volume: number;
}

export interface ISchematicModel {
  workstringAndTreatingLines: ISchematicData;
  outerAnnulusBelowTopOfScreens: ISchematicData;
  innerAnnulusBelowTopOfScreens: ISchematicData;
  casingAnnulusAndReturnLines: ISchematicData;
  blankOuterAnnulus: ISchematicData;
  washpipe: ISchematicData;
  imageRef: string;
  toolPosition: ToolPosition;
}
