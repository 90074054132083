export const toFlatArray = (arr: Array<any>, outBuffer: Array<any> = []): Array<any> => {
  if (outBuffer == null) {
    outBuffer = [];
  }
  for (let i = 0; i < arr.length; i++) {
    if (typeof arr[i] == 'object') {
      //an array
      toFlatArray(arr[i], outBuffer);
    } else {
      //a simple element
      outBuffer.push(arr[i]);
    }
  }
  return outBuffer;
};
