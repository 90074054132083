import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { MenuItem } from 'primeng/api';
import { AxisSelectionProps } from '../../../../../../../+store/menu-selectors/chart/menu-chart.selector';
import { IRadioItem } from '@dunefront/common/common/radio.helpers';
import { ChartTimeVolMode } from '@dunefront/common/modules/reporting/reporting-module.actions';
import { updateChartAxisSelectionAction } from '../../../../../../../+store/reporting/reporting.actions';

@Component({
  selector: 'app-time-vol-button',
  templateUrl: './time-vol-button.component.html',
  styleUrls: ['./time-vol-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeVolButtonComponent implements OnChanges {
  @Input() public axisSelectionProps!: AxisSelectionProps;
  @Input() public isDataBasedModule = false;

  public timeVolumeRadio: IRadioItem<ChartTimeVolMode>[] = [
    { value: ChartTimeVolMode.time, text: 'Time' },
    { value: ChartTimeVolMode.volume, text: 'Volume' },
  ];

  private setTimeForRangeMenuItem = {
    label: 'Set <b>Time</b> for all charts in <b>range</b>',
    escape: false,
    command: (): void => {
      this.store.dispatch(
        updateChartAxisSelectionAction({
          changeScope: 'range',
          chartTimeVolMode: ChartTimeVolMode.time,
        }),
      );
    },
  };

  private setTimeForScenarioMenuItem = {
    label: 'Set <b>Time</b> for all charts in <b>scenario</b>',
    escape: false,
    command: (): void => {
      this.store.dispatch(
        updateChartAxisSelectionAction({
          changeScope: 'scenario',
          chartTimeVolMode: ChartTimeVolMode.time,
        }),
      );
    },
  };

  private setTimeForProjectMenuItem = {
    label: 'Set <b>Time</b> for all charts in <b>project</b>',
    escape: false,
    style: { borderBottom: '1px solid lightgray' },
    command: (): void => {
      this.store.dispatch(
        updateChartAxisSelectionAction({
          changeScope: 'project',
          chartTimeVolMode: ChartTimeVolMode.time,
        }),
      );
    },
  };

  private setVolumeForRangeMenuItem = {
    label: 'Set <b>Volume</b> for all charts in <b>range</b>',
    escape: false,
    command: (): void => {
      this.store.dispatch(
        updateChartAxisSelectionAction({
          changeScope: 'range',
          chartTimeVolMode: ChartTimeVolMode.volume,
        }),
      );
    },
  };

  private setVolumeForScenarioMenuItem = {
    label: 'Set <b>Volume</b> for all charts in <b>scenario</b>',
    escape: false,
    command: (): void => {
      this.store.dispatch(
        updateChartAxisSelectionAction({
          changeScope: 'scenario',
          chartTimeVolMode: ChartTimeVolMode.volume,
        }),
      );
    },
  };

  private setVolumeForProjectMenuItem = {
    label: 'Set <b>Volume</b> for all charts in <b>project</b>',
    escape: false,
    command: (): void => {
      this.store.dispatch(
        updateChartAxisSelectionAction({
          changeScope: 'project',
          chartTimeVolMode: ChartTimeVolMode.volume,
        }),
      );
    },
  };

  public advancedButtonMenuItems: MenuItem[] = [];
  public simulateBasedAdvancedButtonMenuItems: MenuItem[] = [
    this.setTimeForScenarioMenuItem,
    this.setTimeForProjectMenuItem,
    this.setVolumeForScenarioMenuItem,
    this.setVolumeForProjectMenuItem,
  ];
  public dataBasedAdvancedButtonMenuItems: MenuItem[] = [
    this.setTimeForRangeMenuItem,
    this.setTimeForScenarioMenuItem,
    this.setTimeForProjectMenuItem,
    this.setVolumeForRangeMenuItem,
    this.setVolumeForScenarioMenuItem,
    this.setVolumeForProjectMenuItem,
  ];

  constructor(private store: Store) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.isDataBasedModule?.currentValue !== changes.isDataBasedModule?.previousValue) {
      this.advancedButtonMenuItems = changes.isDataBasedModule?.currentValue
        ? this.dataBasedAdvancedButtonMenuItems
        : this.simulateBasedAdvancedButtonMenuItems;
    }
  }

  public timeVolModeChanged($event: ChartTimeVolMode): void {
    this.store.dispatch(
      updateChartAxisSelectionAction({
        changeScope: 'single',
        chartTimeVolMode: $event,
      }),
    );
  }
}
