import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { CompletionModuleName } from '@dunefront/common/modules/completion/completion-module.actions';
import { Store } from '@ngrx/store';
import { BackendConnectionService } from '../../shared/backend-connection/backend-connection.service';
import { BaseWsEffects } from '../base-ws.effects';
import { ModalService } from '../../common-modules/modals/modal.service';

@Injectable()
export class AuthEffects extends BaseWsEffects {
  constructor(actions$: Actions, store: Store, wsService: BackendConnectionService, modalService: ModalService) {
    super(actions$, wsService, CompletionModuleName, false, true, modalService, store);
  }
}
