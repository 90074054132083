import { getArgument, IChartDataDto, IChartDataDtoColumn } from '@dunefront/common/modules/reporting/dto/chart-data.dto';
import { IUnitSystemDto } from '@dunefront/common/dto/unit-system.dto';
import { IAxisUnit } from '@dunefront/common/unit-converters/converter.interfaces';
import { axisTypeFromColumn, getAxisDataTypes, getAxisPrimaryUnitSystemAndDataType, getAxisProps } from './chart-misc-helpers';
import { UnitConverterHelper } from '@dunefront/common/unit-converters/unit.converter.helper';
import { ChartContext, IAxisProps } from './chart-types';
import { ChartAxis, IAxisMargin } from '@dunefront/common/modules/reporting/dto/chart-axis-property.dto';
import { isAxesLogarithmic } from './chart-axis-units-summary-helpers';
import { ChartConfiguration, ScaleOptionsByType } from 'chart.js';
import { MinMax } from './chart-data-helpers';
import { DataType } from '@dunefront/common/dto/data-storage';

const maxPositive = Number.MAX_VALUE;
const minNegative = -Number.MAX_VALUE;

const dataTypesWithMin0 = [
  DataType.Pump_Rate,
  DataType.Return_Rate,
  DataType.Surface_Gravel_Concentration,
  DataType.Bottomhole_Gravel_Concentration,
  DataType.Fluid_Velocity,
  DataType.Shunt_Rate,
  DataType.Fluid_Rate,
  DataType.Shunt_Gravel_Concentration,
  DataType.External_Gravel_Concentration,
];

interface IChartStartEndMargin {
  start: number;
  end: number;
}

export class ChartDataAxisLimitsHelpers {
  private static getMinMaxArguments(chartData: IChartDataDto, currentUnitSystem: IUnitSystemDto, argumentAxisUnit: IAxisUnit): MinMax {
    let min = maxPositive;
    let max = minNegative;

    for (const sourceDataSet of chartData.ChartDataSets) {
      for (const row of sourceDataSet.ChartDataRows) {
        if (row == null) {
          continue;
        }
        max = Math.max(max, getArgument(chartData, row) + sourceDataSet.XAxisShift);
        min = Math.min(min, getArgument(chartData, row) + sourceDataSet.XAxisShift);
      }
    }

    if (min === maxPositive || max === minNegative) {
      // don't convert from Si, otherwise odd numbers visible on axis scale
      return { min, max };
    }

    return {
      min: UnitConverterHelper.convertFromSi(argumentAxisUnit.unitSystem, currentUnitSystem, min),
      max: UnitConverterHelper.convertFromSi(argumentAxisUnit.unitSystem, currentUnitSystem, max),
    };
  }

  public static addAxesLimitsPadding(minMaxValues: MinMax, isLogarithmicAxes: boolean, axisMargin: IChartStartEndMargin): MinMax {
    let { min, max } = minMaxValues;

    const convertToLog = isLogarithmicAxes && isFinite(Math.log10(min)) && isFinite(Math.log10(max));
    if (convertToLog) {
      min = Math.log10(min);
      max = Math.log10(max);
    }

    // add padding
    const marginStart = ((max - min) * axisMargin.start) / 100;
    const marginEnd = ((max - min) * axisMargin.end) / 100;

    min = min - marginStart;
    max = max + marginEnd;

    if (convertToLog) {
      min = Math.pow(10, min);
      max = Math.pow(10, max);
    }

    return { min, max };
  }

  public static getArgumentScaleLimits(
    chartData: IChartDataDto,
    currentUnitSystem: IUnitSystemDto,
    argumentAxisUnit: IAxisUnit,
    axesProperties: IAxisProps[],
    isRotated: boolean,
    axisMargin: IAxisMargin,
  ): MinMax {
    // apply manual axis limits if defined
    const axisProps = getAxisProps(ChartAxis.Argument, axesProperties);
    if (axisProps?.manualLimit && axisProps.min != null && axisProps.max != null) {
      const min = UnitConverterHelper.convertFromSi(argumentAxisUnit.unitSystem, currentUnitSystem, axisProps.min);
      const max = UnitConverterHelper.convertFromSi(argumentAxisUnit.unitSystem, currentUnitSystem, axisProps.max);

      return { min, max };
    }

    // apply limits from ChartData
    if (chartData.ArgumentStart != null && chartData.ArgumentEnd != null) {
      const min = UnitConverterHelper.convertFromSi(argumentAxisUnit.unitSystem, currentUnitSystem, chartData.ArgumentStart);
      const max = UnitConverterHelper.convertFromSi(argumentAxisUnit.unitSystem, currentUnitSystem, chartData.ArgumentEnd);

      return { min, max };
    }

    // calculate limits based on argument min/max values
    const minMax = ChartDataAxisLimitsHelpers.getMinMaxArguments(chartData, currentUnitSystem, argumentAxisUnit);
    const isAxesLog = isAxesLogarithmic([argumentAxisUnit], axisProps);

    const chartMargin: IChartStartEndMargin = {
      start: isRotated ? axisMargin.YAxisStartMarginPercent : axisMargin.XAxisStartMarginPercent,
      end: isRotated ? axisMargin.YAxisEndMarginPercent : axisMargin.XAxisEndMarginPercent,
    };

    const autoMinMaxWithPadding = ChartDataAxisLimitsHelpers.addAxesLimitsPadding(minMax, isAxesLog, chartMargin);
    const isNoMarginWhenNearZero = isRotated ? axisMargin.YAxisNoMarginWhenStartingNearZero : axisMargin.XAxisNoMarginWhenStartingNearZero;

    return {
      min: minMax.min >= 0 && isNoMarginWhenNearZero ? Math.max(0, autoMinMaxWithPadding.min) : autoMinMaxWithPadding.min,
      max: autoMinMaxWithPadding.max,
    };
  }

  private static getMinMaxValues(smoothedPoints: [number, number][]): MinMax {
    if (smoothedPoints.length === 0) {
      return { min: 0, max: 0 };
    }

    let min = Number.MAX_VALUE;
    let max = -Number.MAX_VALUE;

    for (const [, value] of smoothedPoints) {
      if (min > value) {
        min = value;
      }
      if (max < value) {
        max = value;
      }
    }

    return { min, max };
  }

  public static applyScaleLimits(axisId: string, limits: MinMax, chartConfig: ChartConfiguration): void {
    const scale = chartConfig.options?.scales?.[axisId] as ScaleOptionsByType<'linear'> | ScaleOptionsByType<'logarithmic'>;
    if (scale == null || scale.ticks == null || scale.max == null || scale.min == null) {
      return;
    }

    let { min, max } = limits;

    // add extra padding if min == max,
    if (min === max) {
      let padding = Math.abs(min) * 0.1;
      if (padding == 0) {
        padding = 1;
      }
      min -= padding;
      max += padding;
    }

    scale.min = min;
    scale.max = max;
  }

  public static getValueAxisLimits(
    axis: ChartAxis,
    chartContext: ChartContext,
    chartData: IChartDataDto,
    getSmoothedPoints: (column: IChartDataDtoColumn) => [number, number][],
    axisMargin: IAxisMargin,
  ): MinMax {
    const { currentUnitSystem, axesProperties, isRotated } = chartContext;

    const axisProps = getAxisProps(axis, axesProperties);
    const axisUnits = getAxisPrimaryUnitSystemAndDataType(axis, chartData);

    // manual limits
    if (axisProps?.manualLimit && axisProps.min != null && axisProps.max != null && axisUnits != null) {
      const unitSystem = axisUnits.unitSystem;
      const min = UnitConverterHelper.convertFromSi(unitSystem, currentUnitSystem, axisProps.min);
      const max = UnitConverterHelper.convertFromSi(unitSystem, currentUnitSystem, axisProps.max);

      return { min, max };
    }

    // auto limits
    let autoMinMax: MinMax = { min: Number.MAX_VALUE, max: -Number.MAX_VALUE };
    const axisColumns = chartData.ChartDataColumns.filter((column) => axisTypeFromColumn(column) === axis);
    for (const column of axisColumns) {
      const smoothedPoints = getSmoothedPoints(column);
      const columnMinMax = ChartDataAxisLimitsHelpers.getMinMaxValues(smoothedPoints);

      autoMinMax = {
        min: Math.min(autoMinMax.min, columnMinMax.min),
        max: Math.max(autoMinMax.max, columnMinMax.max),
      };
    }

    // check if any of axis data types has min 0, if yes adjust autoMinMax if needed
    const axisDataTypes = getAxisDataTypes(axis, chartData);
    if (axisDataTypes.some((dataType) => dataTypesWithMin0.includes(dataType))) {
      autoMinMax = {
        min: Math.min(0, autoMinMax.min),
        max: autoMinMax.max,
      };
    }

    const chartMargin: IChartStartEndMargin = {
      start: isRotated ? axisMargin.XAxisStartMarginPercent : axisMargin.YAxisStartMarginPercent,
      end: isRotated ? axisMargin.XAxisEndMarginPercent : axisMargin.YAxisEndMarginPercent,
    };

    const autoMinMaxWithPadding = ChartDataAxisLimitsHelpers.addAxesLimitsPadding(autoMinMax, false, chartMargin);
    const isNoMarginWhenNearZero = isRotated ? axisMargin.XAxisNoMarginWhenStartingNearZero : axisMargin.YAxisNoMarginWhenStartingNearZero;

    return {
      min: autoMinMax.min >= 0 && isNoMarginWhenNearZero ? Math.max(0, autoMinMaxWithPadding.min) : autoMinMaxWithPadding.min,
      max: autoMinMaxWithPadding.max,
    };
  }
}
