import { Directive, ElementRef, HostListener, Input, OnInit, Renderer2 } from '@angular/core';
import { Store } from '@ngrx/store';
import { InputsHelperService } from '../../services/inputs-helper.service';

import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { ButtonBaseComponent } from './button.base.component';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[appButton]',
})
export class ButtonDirective extends ButtonBaseComponent implements OnInit {
  @Input() public dialogRef: DynamicDialogRef | undefined;

  @HostListener('click', ['$event'])
  public override async onClick(event: MouseEvent): Promise<void> {
    await super.onClick(event);
  }

  @HostListener('document:keydown', ['$event'])
  public async onKeyDown(event: KeyboardEvent): Promise<void> {
    if (this.dialogRef) {
      await super.onKeyPressed(event);
    }
  }

  constructor(
    public override el: ElementRef,
    renderer: Renderer2,
    store: Store,
    inputsHelperService: InputsHelperService,
  ) {
    super(store, inputsHelperService, renderer, el);
  }

  public ngOnInit(): void {
    if (this.isMenuButton) {
      this.el.nativeElement.innerHTML = `<i class="icon-burger"></i>` + this.el.nativeElement.innerHTML;
    }
  }
}
