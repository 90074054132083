<div *ngIf="data?.pumping as pumping" class="grid-form-container">
  <app-radio-group
    [source]="pumping"
    [key]="'ChokePosition'"
    id="choke-group"
    class="grid-two-one"
    [items]="chokePositionItems"
    layout="horizontal"
    (valueChanged)="onValueChange($event)"
    dataCy="choke-open"
  >
  </app-radio-group>

  <app-check-box
    [source]="pumping"
    [key]="'IsChokeOpenAtVolume'"
    (valueChanged)="onValueChange($event)"
    label="Open at Pump Volume"
    [attr.data-cy]="'choke-open-at-pump-volume'"
  >
  </app-check-box>

  <app-form-input
    [source]="pumping"
    [key]="'ChokeOpenVolume'"
    [disabled]="!isTxtChokeOpenVolumeEnabled"
    [decimalPlaces]="2"
    [unitType]="UnitType.Liquid_Volume"
    (valueChanged)="onValueChange($event)"
    dataCy="choke-open-volume"
  >
  </app-form-input>

  <app-radio-group
    [source]="pumping"
    [key]="'VariableChoke'"
    id="variable-choke-group"
    class="grid-two-one"
    [items]="variableChokeItems"
    [disabled]="!isVariableChokeEnabled"
    layout="horizontal"
    (valueChanged)="onValueChange($event)"
    dataCy="variable-choke"
  >
  </app-radio-group>

  <app-form-input
    class="grid-two-one"
    appUnwrap
    [label]="chokeOrificeIDLabelText"
    [source]="pumping"
    [key]="'ChokeOrificeID'"
    [disabled]="!isTxtChokeOrificeIDEnabled"
    [decimalPlaces]="3"
    [unitType]="UnitType.Diameter"
    (valueChanged)="onValueChange($event)"
    dataCy="choke-orifice-id"
  >
  </app-form-input>
</div>
