import { IScenarioIdBasedTableEntity } from '../../../../dto/common-dto.interfaces';
import { ArgumentOutOfRangeError } from '../../../../exceptions/argumentOutOfRangeException';
import { GeneralCalculations } from '../../../../common/general.calculations';
import { ArgumentNullError } from '../../../../exceptions/argumentNullException';
import { Validation } from '../../../../common/validation';
import { ModuleType } from '../../../scenario/scenario.dto';
import { MathHelpers } from '../../../../common/math-helpers';

export interface SurveyDto extends IScenarioIdBasedTableEntity {
  Id: number;
  ScenarioId: number;
  Azimuth: number;
  Deviation: number;
  MD: number;
  TVD: number;
  DownPathTemp: number;
  UpPathTemp: number;
  SortOrder: number;
}

export interface ImportTempProfileWorkerPayload {
  SourceScenarioId: number;
  ModuleType: ModuleType;
  BottomholeCirculatingTemperature: number;
  SourceRangeId: number;
}

export class SurveyCalculations {
  // Method to calculate the MD at a given TVD
  // Note that there may be multiple MDs at the same TVD
  public static TVDtoMD(surveyData: SurveyDto[], targetTVD: number): number[] {
    // Calculate the max TVD
    const bottomholeTVD = Math.max(...surveyData.map((survey) => survey.TVD));

    // Calculate the MD
    if (targetTVD > bottomholeTVD) {
      // This is an error
      throw new ArgumentOutOfRangeError('targetTVD', 'TVD must be less than the survey bottomhole TVD');
    } else if (targetTVD <= 0) {
      // this is at the surface, so return the MD as zero.
      return [0.0];
    } else {
      // using Set to prevent duplicated values ( f.e. in fishhook well )
      const mdList = new Set<number>();
      // Find the entries either side of the TVD for interpolation
      // Note that there may be multiple MDs at the same TVD
      for (let index = 0; index < surveyData.length - 1; index++) {
        const currentSurvey = surveyData[index];
        const nextSurvey = surveyData[index + 1];
        if (nextSurvey.TVD === currentSurvey.TVD) {
          continue;
        } else if (nextSurvey.TVD === targetTVD) {
          mdList.add(MathHelpers.round(nextSurvey.MD, 2));
        } else if (
          (currentSurvey.TVD < targetTVD && nextSurvey.TVD > targetTVD) ||
          (currentSurvey.TVD > targetTVD && nextSurvey.TVD < targetTVD)
        ) {
          // use linear interpolation to determine the MD at the target TVD
          mdList.add(
            MathHelpers.round(
              GeneralCalculations.LinearInterpolation(targetTVD, currentSurvey.TVD, nextSurvey.TVD, currentSurvey.MD, nextSurvey.MD),
              2,
            ),
          );
        }
      }
      if (mdList.size <= 0) {
        throw new ArgumentNullError('targetTVD', 'Target TVD not found');
      } else {
        return Array.from(mdList);
      }
    }
  }

  // Method to calculate the TVD at a given MD
  public static MDToTVD(surveyData: SurveyDto[], targetMD: number): number {
    // Calculate the bottomholeMD
    const bottomholeMD = surveyData[surveyData.length - 1].MD;
    // Calculate the TVD
    if (targetMD > bottomholeMD) {
      // This is an error
      console.warn(new ArgumentOutOfRangeError('targetMD', 'MD must be less than the survey bottomhole MD'));
      return 0;
      // throw new ArgumentOutOfRangeError("targetMD", "MD must be less than the survey bottomhole MD");
    } else if (targetMD <= 0) {
      // this is at the surface, so return the TVD as zero.
      return 0;
    } else if (targetMD === bottomholeMD) {
      // This is the last point in the array, so return the corresponding TVD
      return surveyData[surveyData.length - 1].TVD;
    } else {
      // Find the entries either side of the MD for interpolation
      for (let index = 0; index < surveyData.length - 1; index++) {
        const currentSurvey = surveyData[index];
        const nextSurvey = surveyData[index + 1];
        if (nextSurvey.MD === targetMD) {
          return nextSurvey.TVD;
        } else if (nextSurvey.MD > targetMD) {
          // use linear interpolation to determine the TVD at the target MD
          return GeneralCalculations.LinearInterpolation(targetMD, currentSurvey.MD, nextSurvey.MD, currentSurvey.TVD, nextSurvey.TVD);
        }
      }
      throw new ArgumentNullError('targetMD', 'Target MD not found');
    }
  }

  // Method to calculate the TVD values in the survey using the minimum Curvature method
  // The deviation is expected in degrees in the survey, but is converted to radians in the method
  public static CalculateSurveyTVDs(surveyData: SurveyDto[]): SurveyDto[] {
    // Check if the survey is null
    if (surveyData.length === 0) {
      // There is no user data in the survey yet so do do nothing
      return [];
    }
    let prevSurveyTVD = 0;
    const newSurveyData: SurveyDto[] = surveyData.map((currentSurvey, index) => {
      if (index === 0) {
        return { ...currentSurvey, TVD: 0 };
      }
      const prevSurvey = surveyData[index - 1];

      // Calculate necessary variables
      const deltaMD = currentSurvey.MD - prevSurvey.MD;
      const curvatureFactor = this.CurvatureFactor(prevSurvey, currentSurvey);

      // Calculate TVD term for convenience and readability
      const tvdTerm1 =
        Math.cos(GeneralCalculations.DegreesToRadians(prevSurvey.Deviation)) +
        Math.cos(GeneralCalculations.DegreesToRadians(currentSurvey.Deviation));

      // Calculate the TVD
      const tvd = prevSurveyTVD + 0.5 * deltaMD * tvdTerm1 * curvatureFactor;
      prevSurveyTVD = tvd;
      // Validate and update the list
      return { ...currentSurvey, TVD: Validation.UICheckNumberValidity(tvd) };
    });

    return newSurveyData;
  }

  // Calculate bottomhole TVD
  public static BottomholeTVD(surveyData: SurveyDto[]): number {
    // Find the max TVD in the survey
    const bottomholeTVD = Math.max(...surveyData.map((survey) => survey.TVD));
    // validate and return the result
    return Validation.UICheckNumberValidity(bottomholeTVD);
  }

  // Calculate bottomhole MD
  public static MaxSurveyMD(surveyData: SurveyDto[]): number {
    // Find the max MD in the survey
    const bottomholeMD = Math.max(...surveyData.map((survey) => survey.MD));

    // validate and return the result
    return Validation.UICheckNumberValidity(bottomholeMD);
  }

  public static CurvatureFactor(firstSurveyPoint: SurveyDto, secondSurveyPoint: SurveyDto): number {
    const dogLeg = this.DogLeg(firstSurveyPoint, secondSurveyPoint);
    if (dogLeg === 0) {
      return 1.0;
    } else {
      return (2.0 / dogLeg) * Math.tan(dogLeg / 2.0);
    }
  }

  // Method to calculate the dog leg
  private static DogLeg(firstSurveyPoint: SurveyDto, secondSurveyPoint: SurveyDto): number {
    // Calculate necessary values
    const deltaDeviation = GeneralCalculations.DegreesToRadians(secondSurveyPoint.Deviation - firstSurveyPoint.Deviation);
    const deltaAzimuth = GeneralCalculations.DegreesToRadians(secondSurveyPoint.Azimuth - firstSurveyPoint.Azimuth);

    // Calculate and return the dog leg
    const dogLeg = Math.acos(
      Math.cos(deltaDeviation) -
        Math.sin(GeneralCalculations.DegreesToRadians(firstSurveyPoint.Deviation)) *
          Math.sin(GeneralCalculations.DegreesToRadians(secondSurveyPoint.Deviation)) *
          (1.0 - Math.cos(deltaAzimuth)),
    );
    return Validation.UICheckNumberValidity(dogLeg);
  }
}
