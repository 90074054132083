import { InjectivityUnit } from '../../../dto/unit-system.dto';
import { UnitConverter } from '../../converter.interfaces';

export class InjectivityConverter extends UnitConverter {
  public static symbols: Array<string> = ['', 'm³/s/Pa', 'm³/day/kPa', 'bpd/psi', 'galUS/day/psi', 'L/day/bar'];

  public static unit = InjectivityUnit;

  public static fromSi(value: number, unit: InjectivityUnit): number {
    switch (unit) {
      case InjectivityUnit.Cubic_Meter_Per_Second_Per_Pascal: {
        return value;
      }
      case InjectivityUnit.Cubic_Meter_Per_Day_Per_Kilo_Pascal: {
        return value * 8.64e7;
      }
      case InjectivityUnit.Barrel_Per_Day_Per_Pound_Per_Square_Inch: {
        return value * 3.746891445e9;
      }
      case InjectivityUnit.US_Gallon_Per_Day_Per_Pound_Per_Square_Inch: {
        return value * 1.573692229e11;
      }
      case InjectivityUnit.Litre_Per_Day_Per_Bar: {
        return value * 8.64e12;
      }
      default: {
        throw new TypeError('Unknown Injectivity Unit:' + unit);
      }
    }
  }

  public static toSi(value: number, unit: InjectivityUnit): number {
    switch (unit) {
      case InjectivityUnit.Cubic_Meter_Per_Second_Per_Pascal: {
        return value;
      }
      case InjectivityUnit.Cubic_Meter_Per_Day_Per_Kilo_Pascal: {
        return value / 8.64e7;
      }
      case InjectivityUnit.Barrel_Per_Day_Per_Pound_Per_Square_Inch: {
        return value / 3.746891445e9;
      }
      case InjectivityUnit.US_Gallon_Per_Day_Per_Pound_Per_Square_Inch: {
        return value / 1.573692229e11;
      }
      case InjectivityUnit.Litre_Per_Day_Per_Bar: {
        return value / 8.64e12;
      }
      default: {
        throw new TypeError('Unknown Injectivity Unit:' + unit);
      }
    }
  }

  public static getSymbol(unit: InjectivityUnit): string {
    return this.symbols[unit];
  }
}
