import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input } from '@angular/core';
import { createTableRow, ITableRow, ITableState } from '@dunefront/common/common/common-grid.interfaces';
import { IGridColumnConfig } from '../../../../../shared/components/grid/grid.interfaces';
import { GridConfig } from '../../../../../shared/components/grid/grid-config';
import { Store } from '@ngrx/store';
import { ModalService } from '../../../../../common-modules/modals/modal.service';
import * as completionActions from '../../../../../+store/completion/completion.actions';
import { EnumHelpers } from '@dunefront/common/utils/enum.helpers';
import { LowerCompletionPipeType, PipeType } from '@dunefront/common/dto/pipe.dto';
import { GridContainerComponent } from '../../../../../shared/components/grid/grid-container.component';
import { ISelectItem } from '@dunefront/common/common/select.helpers';
import { Pipe } from '@dunefront/common/modules/pipes/pipe';
import { GridResizeService } from '../../../../../shared/services/grid-resize.service';
import { IDeleteRowsProps, IInsertRowsProps, IUpdateTableRowsProps } from '@dunefront/common/common/common-store-crud.interfaces';
import { lowerCompletionGridConfig, PIPE_TYPES_LOOKUP_SRC_ID } from './lower-completion-grid.config';
import { LowerCompletionPipesFactory } from '@dunefront/common/modules/pipes/lower-completion-pipes/lower-completion-pipes.factory';
import { InsertLocation } from '@dunefront/common/modules/common.interfaces';
import { ImportDataHelper } from '../../../../../shared/helpers/import-data/import-data-helper';

@Component({
  selector: 'app-lower-completion-grid',
  templateUrl: './lower-completion-grid.component.html',
  styleUrls: ['./lower-completion-grid.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LowerCompletionGridComponent extends GridContainerComponent<Pipe> {
  @Input()
  public lowerCompletionData: ITableState<Pipe> = { rows: [], isValid: true };

  constructor(
    store: Store,
    cdRef: ChangeDetectorRef,
    protected modalService: ModalService,
    el: ElementRef,
    protected resizeService: GridResizeService,
  ) {
    super(store, cdRef, el, new LowerCompletionGridConfig(store, modalService), resizeService);
  }

  protected getColumns(): IGridColumnConfig<Pipe>[] {
    return lowerCompletionGridConfig;
  }
}

export class LowerCompletionGridConfig extends GridConfig<Pipe> {
  public pipeTypes: ISelectItem<LowerCompletionPipeType>[] = [];
  public placeholder = '';

  constructor(
    private store: Store,
    modalService: ModalService,
  ) {
    super(modalService);
    this.headerText = 'Lower Completion';
    this.initLookupData();
  }

  public initLookupData(): void {
    const pipeTypes = EnumHelpers.EnumToISelectItemArray(LowerCompletionPipeType);
    this.pipeTypes = [...pipeTypes];

    this.pipeTypes.forEach((pipe) => {
      if (pipe.value === LowerCompletionPipeType.Gravel_Pack_Packer) {
        pipe.disabled = true;
      }
    });
    this.placeholder = 'Select Component';
  }

  public override mappingLookupDataByText(text: string): number | undefined {
    if (ImportDataHelper.containsWords(text, ['shunt', 'screen'])) {
      return LowerCompletionPipeType.Shunted_Screen;
    } else if (ImportDataHelper.containsWords(text, ['shunt'])) {
      return LowerCompletionPipeType.Shunted_Blank_Pipe;
    } else if (ImportDataHelper.containsWords(text, ['gravel'])) {
      return LowerCompletionPipeType.Gravel_Pack_Extension;
    } else if (ImportDataHelper.containsWords(text, ['sump'])) {
      return LowerCompletionPipeType.Sump_Packer;
    } else if (ImportDataHelper.containsWords(text, ['isolat', 'pack'])) {
      return LowerCompletionPipeType.Isolation_Packer;
    } else if (ImportDataHelper.containsWords(text, ['isolat'])) {
      return LowerCompletionPipeType.Isolation_Valve;
    } else if (ImportDataHelper.containsWords(text, ['bull']) || ImportDataHelper.containsWords(text, ['nose'])) {
      return LowerCompletionPipeType.Bull_Nose;
    } else if (ImportDataHelper.containsWords(text, ['icd'])) {
      return LowerCompletionPipeType.ICD_Screen;
    } else if (ImportDataHelper.containsWords(text, ['screen'])) {
      return LowerCompletionPipeType.Screen;
    } else if (ImportDataHelper.containsWords(text, ['blank']) || ImportDataHelper.containsWords(text, ['pipe'])) {
      return LowerCompletionPipeType.Blank_Pipe;
    } else {
      return undefined;
    }
  }

  public override isCellDisabled(rows: ITableRow<Pipe>[], rowIndex: number, cellIndex: number): boolean {
    if (rows.length === 0) {
      return false;
    }

    if (rows[rowIndex].rowType === 'insert-row') {
      return this.columns[cellIndex].colId !== 'PipeType';
    }

    if (rowIndex === 0 && this.columns[cellIndex].colId === 'PipeType') {
      return true;
    }

    if (rowIndex !== 0 && this.columns[cellIndex].colId === 'TopMD') {
      return true;
    }

    if (this.columns[cellIndex].colId === 'OuterDiameter') {
      const pipe = rows[rowIndex].rowData;
      return pipe.PipeType === PipeType.Sump_Packer || pipe.PipeType === PipeType.Isolation_Packer;
    }
    return false;
  }

  public override updateRowsAction(props: IUpdateTableRowsProps<Pipe>): void {
    this.store.dispatch(completionActions.updateLowerCompletionRow(props));
  }

  public override insertRowAction(props: IInsertRowsProps<Pipe>, refRow: ITableRow<Pipe>): void {
    if (props.insertLocation !== 'merge-rows') {
      const row = props.rows[0];
      row.rowData.BottomMD = refRow.rowData.TopMD;
    }
    this.store.dispatch(completionActions.insertLowerCompletionRow(props));
  }

  public override deleteRowsAction(props: IDeleteRowsProps): void {
    this.store.dispatch(completionActions.deleteLowerCompletionRows(props));
  }

  public override createEmptyModel(scenarioId: number): Pipe {
    return LowerCompletionPipesFactory.createEmpty(scenarioId);
  }

  public override createDefaultTableRows(scenarioId: number, noOfRows: number): ITableRow<Pipe>[] {
    const newTableRows = [];
    for (let index = 0; index < noOfRows; index++) {
      const newEmptyModel = this.createEmptyModel(scenarioId);
      newEmptyModel.PipeType = PipeType.Blank_Pipe;
      newTableRows.push(createTableRow(newEmptyModel, 'data', index, false));
    }
    if (newTableRows[0] != null) {
      newTableRows[0].rowData.PipeType = PipeType.Gravel_Pack_Packer;
    }
    return newTableRows;
  }

  public override replaceGridAction(rows: ITableRow<Pipe>[]): void {
    const checkedRows = rows.map((row, index) => {
      const newRow = { ...row, RowData: { ...row.rowData } };
      if (newRow.rowData.PipeType === PipeType.Gravel_Pack_Packer && index > 0) {
        newRow.rowData.PipeType = PipeType.Blank_Pipe;
      }
      return newRow;
    });

    if (checkedRows.length > 0) {
      const props: IInsertRowsProps<any> = {
        rows: checkedRows,
        refId: 0,
        insertLocation: 'replace' as InsertLocation,
        shouldResetResults: true,
      };
      this.store.dispatch(completionActions.insertLowerCompletionRow(props));
    }
  }

  // this need to be defined like that because of lexical scope
  public override getLookupDataSource = (type: string): ISelectItem<any>[] => {
    if (type === PIPE_TYPES_LOOKUP_SRC_ID) {
      return this.pipeTypes;
    }
    return [];
  };

  public override getLookupDataPlaceholder = (type: string): string => {
    if (type === PIPE_TYPES_LOOKUP_SRC_ID) {
      return this.placeholder;
    }
    return '';
  };

  public override canRowsBeMerged(rows: ITableRow<Pipe>[], selectedRowsIndexes: number[]): boolean {
    const firstRowIndex = Math.min(...selectedRowsIndexes);
    const lastRowIndex = Math.max(...selectedRowsIndexes);

    if (rows[firstRowIndex] == null || rows[lastRowIndex] == null) {
      return false;
    }

    const gravelPackPackerSelected = rows
      .slice(firstRowIndex, lastRowIndex + 1)
      .some((row) => row.rowData.PipeType === PipeType.Gravel_Pack_Packer);
    if (gravelPackPackerSelected) {
      this.modalService.showAlert('Gravel Pack Packer can’t be merged', 'Information').then();
      return false;
    }
    return true;
  }

  protected override createMergedRow(rows: ITableRow<Pipe>[], firstRowIndex: number, lastRowIndex: number): ITableRow<Pipe> | undefined {
    if (rows[firstRowIndex] == null || rows[lastRowIndex] == null) {
      return undefined;
    }
    const mergedRow = { ...rows[firstRowIndex], rowData: { ...rows[firstRowIndex].rowData } };
    mergedRow.rowData = { ...mergedRow.rowData, Id: -1, BottomMD: rows[lastRowIndex].rowData['BottomMD'] };
    return mergedRow;
  }
}
