import { DrawingAnnotation } from './annotation';
import { canvasTxt } from './canvas-txt';
import { applyStateOffsets, getResizeHandles, resizeHandleRadius, updateArrowPoints } from './helpers';
import { AnnotationsPluginState, getState } from './state';
import { Chart } from 'chart.js';
import { getOffsetToFitSegment, isPointInsideRect, Rect, rectWithPadding } from '@dunefront/common/common/math-geometry-helpers';
import {
  annotationBorderColor,
  annotationDefaultLineWidth,
  annotationHandleFillColor,
  annotationHighlightedLineWidth,
  annotationHighlightedStrokeStyle,
  annotationRoundedCornerRadius,
  annotationTextBoxPadding,
} from './constants';
import { getRoundedRectPath, TextDrawStyle, transparencyPercentageToHexAlpha } from '../../../shared/helpers/canvas-drawing-helpers';
import { getChartDrawingArea, getDrawingAreaWithPadding } from '../plugins-common-helpers';

export const drawAnnotations = (chart: Chart): void => {
  const state = getState(chart);
  const { options, annotations } = state;
  const { ctx } = chart;

  ctx.save();

  const drawingRect = getChartDrawingArea(chart);
  const drawingRectWithPadding = getDrawingAreaWithPadding(chart);

  // apply clipping rect
  const clippingPath = new Path2D();
  clippingPath.rect(drawingRect.x, drawingRect.y, drawingRect.width, drawingRect.height);
  ctx.clip(clippingPath);

  for (const annotation of annotations) {
    const annotationWithStateOffsets = applyStateOffsets(annotation, state);

    // don't draw annotations with anchor outside drawing area
    if (!isPointInsideRect(annotationWithStateOffsets, drawingRect)) {
      continue;
    }

    const annotationWithOffsets = options.fitWithinDrawingArea
      ? moveToDrawingArea(annotationWithStateOffsets, drawingRectWithPadding)
      : annotationWithStateOffsets;

    drawAnnotation(ctx, annotationWithOffsets, state);
  }

  ctx.restore();
};

export const moveToDrawingArea = (annotation: DrawingAnnotation, drawingRect: Rect): DrawingAnnotation => {
  const { textBox } = annotation;

  const correctedAnnotation = {
    ...annotation,
    textBox: {
      ...textBox,
      x:
        textBox.x +
        getOffsetToFitSegment(
          { start: textBox.x, len: textBox.width },
          {
            start: drawingRect.x,
            len: drawingRect.width,
          },
        ),
      y:
        textBox.y +
        getOffsetToFitSegment(
          { start: textBox.y, len: textBox.height },
          {
            start: drawingRect.y,
            len: drawingRect.height,
          },
        ),
    },
  };

  updateArrowPoints(correctedAnnotation);

  return correctedAnnotation;
};

export const drawAnnotation = (
  ctx: CanvasRenderingContext2D,
  annotation: DrawingAnnotation,
  state: Partial<AnnotationsPluginState>,
): void => {
  const { arrowPoint1, arrowPoint2, textBox, x, y, text, sizeMultiplier } = annotation;
  const { options, highlightedAnnotationId, selectedAnnotation } = state;

  const isHighlighted = highlightedAnnotationId === annotation.id;
  const drawHighlighted = isHighlighted && options?.readonly !== true;

  const isSelected = selectedAnnotation?.id === annotation.id;
  const drawSelected = isSelected && options?.readonly !== true;

  const {
    AnnotationFillColour,
    AnnotationTailVisibility,
    AnnotationFontColour,
    AnnotationFontSize,
    AnnotationFontBold,
    AnnotationFontItalic,
    AnnotationFillTransparency,
  } = annotation.style;

  const alphaHex = transparencyPercentageToHexAlpha(AnnotationFillTransparency);

  // this is a little trick:
  // AnnotationFillColour holds color name (eg 'green'), needs to get converted to hex in order to let us combine with alphaHex
  // assigning it to fillStyle and then reading in next line does the trick (read value is hex)
  ctx.fillStyle = AnnotationFillColour;
  ctx.fillStyle = ctx.fillStyle + alphaHex;
  ctx.lineWidth = (drawHighlighted ? annotationHighlightedLineWidth : annotationDefaultLineWidth) * sizeMultiplier;
  ctx.strokeStyle = drawHighlighted ? annotationHighlightedStrokeStyle : annotationBorderColor;
  if (!drawHighlighted) {
    ctx.strokeStyle = ctx.strokeStyle + alphaHex;
  }

  const entireCanvasRectPath = new Path2D();
  entireCanvasRectPath.rect(0, 0, ctx.canvas.width, ctx.canvas.height);

  const rectPath = getRoundedRectPath(textBox, annotationRoundedCornerRadius);

  const tailPath = new Path2D();
  tailPath.moveTo(annotation.x, annotation.y);
  tailPath.lineTo(arrowPoint1.x, arrowPoint1.y);
  tailPath.lineTo(arrowPoint2.x, arrowPoint2.y);
  tailPath.lineTo(annotation.x, annotation.y);

  // draw text box border
  ctx.save();
  if (AnnotationTailVisibility) {
    // clipping below let draw on entire canvas except tail
    const tileClip = new Path2D();
    tileClip.addPath(entireCanvasRectPath);
    tileClip.addPath(tailPath);
    ctx.clip(tileClip, 'evenodd');
  }
  ctx.stroke(rectPath);
  ctx.restore();

  // fill text box
  ctx.fill(rectPath);

  // draw tail
  if (AnnotationTailVisibility) {
    ctx.save();
    // clipping below let draw on entire canvas except text box
    const textBoxClip = new Path2D();
    textBoxClip.addPath(entireCanvasRectPath);
    textBoxClip.addPath(rectPath);
    ctx.clip(textBoxClip, 'evenodd');

    ctx.stroke(tailPath);
    ctx.fill(tailPath);
    ctx.restore();
  }

  // draw text
  const drawingRect = rectWithPadding(textBox, annotationTextBoxPadding * sizeMultiplier);

  const textStyle: TextDrawStyle = {
    fontSize: AnnotationFontSize,
    fontColour: AnnotationFontColour,
    italic: AnnotationFontItalic,
    bold: AnnotationFontBold,
  };

  canvasTxt.drawText(ctx, text, drawingRect, textStyle);

  // draw handles (circles)
  if (drawSelected) {
    ctx.fillStyle = AnnotationFillColour;
    ctx.fillStyle = annotationHandleFillColor;
    ctx.strokeStyle = annotationBorderColor;
    ctx.lineWidth = annotationDefaultLineWidth * sizeMultiplier;

    // draw "drag all" handle
    ctx.beginPath();
    ctx.arc(x, y, resizeHandleRadius, 0, 2 * Math.PI);
    ctx.fill();
    ctx.stroke();

    // draw all resize handles
    const resizeHandles = getResizeHandles(annotation);
    for (const resizeHandle of resizeHandles) {
      ctx.beginPath();
      ctx.arc(resizeHandle.x, resizeHandle.y, resizeHandleRadius, 0, 2 * Math.PI);
      ctx.fill();
      ctx.stroke();
    }
  }
};
