import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { IRadioButtonValue } from '../../../../../shared/components/form-components/radio-group/radio-group.component';
import { ValidatedWellModuleState } from '@dunefront/common/modules/well/well-module.state';
import { BasicPropertyChangeComponent } from '../../../../../common-modules/db-connection/basic-property-change.component';
import { ModalService } from '../../../../../common-modules/modals/modal.service';
import { cancelChange } from '../../../../../+store/store.helpers';
import { ZoneValidation } from '@dunefront/common/modules/well/model/zone/zone.validation';
import { ValidatedDeveloperSettings } from '@dunefront/common/modules/settings/dto/settingsDto';
import { IRadioItem } from '@dunefront/common/common/radio.helpers';
import { getChangeProp, ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';

@Component({
  selector: 'app-depth-definition',
  templateUrl: './depth-definition.component.html',
  styleUrls: ['./depth-definition.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DepthDefinitionComponent extends BasicPropertyChangeComponent<ValidatedWellModuleState> {
  @Input() public state!: ValidatedWellModuleState;
  @Input() public developerSettings!: ValidatedDeveloperSettings;

  public depthDefRadioItems: IRadioItem<boolean>[] = [
    { text: 'MD', value: true },
    { text: 'TVD', value: false },
  ];
  public checkedValue!: IRadioButtonValue;

  constructor(
    private modalService: ModalService,
    private cdRef: ChangeDetectorRef,
  ) {
    super();
  }

  public async onDepthChange(event: ObjectChangeProp<ValidatedWellModuleState>): Promise<void> {
    const isZoneDepth = event.value;
    if (isZoneDepth === this.state.IsZoneDepthByMD) {
      return;
    }
    if (ZoneValidation.ValidateGridDepth(this.state, this.developerSettings)) {
      this.propertyChanged.emit(getChangeProp('IsZoneDepthByMD', isZoneDepth, event.shouldResetResults));
    } else {
      await this.modalService.showAlert('Please correct all zone depth errors before changing between MD/TVD', 'Information');
      const cancelGenerator = cancelChange(this.state, event, this.cdRef);
      this.state = cancelGenerator.next().value;
      this.state = cancelGenerator.next().value;
    }
  }
}
