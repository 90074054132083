import { noErrors } from '../../../../common/state.helpers';
import { ConvertUnitPipe } from '../../../units/convert-unit.pipe/convert-unit.pipe';
import { IError } from '../../../../common/common-state.interfaces';
import {
  LeakoffCoefficientCalculatorModuleState,
  ValidatedLeakoffCoefficientCalculatorModuleState,
} from '../leakoff-coefficient-calculator-module.state';
import { UnitSystem } from '../../../../dto/unit-system.dto';

export class LeakoffCoefficientCalculatorValidation {
  public static validate(state: LeakoffCoefficientCalculatorModuleState): ValidatedLeakoffCoefficientCalculatorModuleState {
    const error: IError<LeakoffCoefficientCalculatorModuleState> = {
      ReservoirCompressibility: this.ValidateReservoirCompressibility(state.ReservoirCompressibility),
      ReservoirPorosity: this.ValidateReservoirPorosity(state.ReservoirPorosity),
      PermeabilityToReservoirFluid: this.ValidatePermeabilityToReservoirFluid(state.PermeabilityToReservoirFluid),
      PermeabilityToInjectedFluid: this.ValidatePermeabilityToInjectedFluid(state.PermeabilityToInjectedFluid),
      ReservoirFluidViscosity: this.ValidateReservoirFluidViscosity(state.ReservoirFluidViscosity),
      InjectedFluidViscosity: this.ValidateInjectedFluidViscosity(state.InjectedFluidViscosity),
      PressureDifferential: this.ValidatePressureDifferential(state.PressureDifferential),
    };
    return { ...state, error, isValid: noErrors(error) };
  }

  private static ValidateReservoirCompressibility(reservoirCompressibility: number): string {
    if (reservoirCompressibility <= 0) {
      return 'Reservoir compressibility must be greater than 0';
    }
    return '';
  }

  private static ValidateReservoirPorosity(reservoirPorosity: number): string {
    if (reservoirPorosity <= 0 || reservoirPorosity > 50) {
      return 'Porosity must be between 0 and 50';
    }
    return '';
  }

  private static ValidatePermeabilityToReservoirFluid(permeabilityToReservoirFluid: number): string {
    const maxPermeability = 9.869223e-12;
    if (permeabilityToReservoirFluid <= 0 || permeabilityToReservoirFluid > maxPermeability) {
      const encodeValue = ConvertUnitPipe.encode(UnitSystem.Permeability, maxPermeability);
      return `Permeability must be between 0 and ${encodeValue}`;
    }
    return '';
  }

  private static ValidatePermeabilityToInjectedFluid(permeabilityToInjectedFluid: number): string {
    const maxPermeability = 9.869223e-12;
    if (permeabilityToInjectedFluid <= 0 || permeabilityToInjectedFluid > maxPermeability) {
      const encodeValue = ConvertUnitPipe.encode(UnitSystem.Permeability, maxPermeability);
      return `Permeability must be between 0 and ${encodeValue}`;
    }
    return '';
  }

  private static ValidateReservoirFluidViscosity(reservoirFluidViscosity: number): string {
    if (reservoirFluidViscosity <= 0) {
      return 'Reservoir fluid viscosity must be greater than 0';
    }
    return '';
  }

  private static ValidateInjectedFluidViscosity(injectedFluidViscosity: number): string {
    if (injectedFluidViscosity <= 0) {
      return 'Injected fluid viscosity must be greater than 0';
    }
    return '';
  }

  private static ValidatePressureDifferential(pressureDifferential: number): string {
    if (pressureDifferential <= 0) {
      return 'Pressure differential must be greater than 0';
    }
    return '';
  }
}
