import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { fetchLicenseSessions, killLicenseSession } from '../../../../+store/about/about.actions';
import { getLicensesWithSessions } from '../../../../+store/about/about.selectors';
import { firstValueFrom, Observable } from 'rxjs';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { LicenseWithSessionsInfo, LoginInfo } from '@dunefront/common/modules/licensing/licensing.interfaces';
import { getMainFeatureLoginInfo } from '../../../../+store/licensing/licensing.selectors';

@Component({
  selector: 'app-licensing-component',
  templateUrl: './licensing-sessions.modal.component.html',
  styleUrls: ['./licensing-sessions.modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LicensingSessionsModalComponent implements OnInit {
  public licensesWithSessions$: Observable<LicenseWithSessionsInfo[]> = this.store.select(getLicensesWithSessions);
  public currentLoginInfo$ = this.store.select(getMainFeatureLoginInfo);
  constructor(
    public ref: DynamicDialogRef,
    public store: Store,
  ) {}

  public async ngOnInit(): Promise<void> {
    await this.onGetSessionsClick();
  }

  public getLicenseId(licenseInto: LoginInfo | undefined | null): string | null {
    return licenseInto?.licenseId ?? null;
  }

  public async onGetSessionsClick(): Promise<void> {
    const currentLicenseInto = await firstValueFrom(this.currentLoginInfo$);
    const licenseId = this.getLicenseId(currentLicenseInto);
    if (licenseId != null) {
      this.store.dispatch(fetchLicenseSessions({ licenseId }));
    }
  }

  public killSession(licenseId: string, sessionId: string): void {
    this.store.dispatch(killLicenseSession({ licenseId, sessionId }));
  }
}
