import { Chart, Point } from 'chart.js';
import { DrawingAnnotation } from './annotation';
import { AnnotationDragMode, AnnotationOffsets, DuneFrontAnnotationOptions, EventHandler, ResizeHandle } from './types';

const chartStates = new Map<Chart, AnnotationsPluginState>();

export const getState = (chart: Chart): AnnotationsPluginState => {
  let state = chartStates.get(chart);
  if (state == null) {
    state = {
      mode: 'default',
      annotations: [],
      handlers: {},
      options: {
        annotations: [],
        readonly: true,
        fitWithinDrawingArea: false,
      },
    };

    chartStates.set(chart, state);
  }

  return state;
};

export const removeState = (chart: Chart): void => {
  chartStates.delete(chart);
};

export const getEmptyOffsets = (): AnnotationOffsets => ({
  offsetX: 0,
  offsetY: 0,
  offsetBoxX: 0,
  offsetBoxY: 0,
  offsetBoxWidth: 0,
  offsetBoxHeight: 0,
});

export interface AnnotationsPluginState {
  options: DuneFrontAnnotationOptions;
  annotations: DrawingAnnotation[];
  handlers: { [type: string]: EventHandler };
  mode: AnnotationDragMode;
  prevPoint?: Point;
  mouseDownPoint?: Point;
  selectedAnnotation?: DrawingAnnotation;
  highlightedAnnotationId?: number;
  selectedAnnotationOffsets?: AnnotationOffsets;
  selectedAnnotationUpdated?: boolean;
  resizeHandle?: ResizeHandle;
}
