import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ModalService } from '../modal.service';
import { RouterHelperService } from '../../../shared/services/router-helper.service';
import { firstValueFrom } from 'rxjs';
import { getUiState } from '../../../+store/ui/ui.selectors';
import { Store } from '@ngrx/store';
import { getDeleteResultsParams } from '../../../+store/import-data/import-data.selectors';
import { removeDataResultsFromStoreIfNeeded } from '../../../+store/data-storage/data-storage.actions';
import { IAppError } from '@dunefront/common/exceptions/IAppError';
import { defaultModalConfirmButton, ModalButtonConfig } from '../generic-modal/generic-modal.component';
import { RouteModuleWell, RouteModuleWellGeneralData } from '../../../pages/simulate-evaluate-page/well/well-routes-names';
import { getCurrentScenarioId } from '../../../+store/scenario/scenario.selectors';

const DEFAULT_ERROR_MESSAGE = 'A calculation error occurred. <br>Please contact support@dunefront.com for help.';

@Component({
  selector: 'app-calc-engine-error',
  template: `
    <app-generic-modal [title]="modalTitle" [modalButtonsConfigs]="modalButtonsConfigs">
      <p [innerHTML]="parseErrorMessage"></p>
      <p>
        <button
          appButton
          type="button"
          buttonType="primary"
          class="btn btn-primary btn-sm"
          [isUiLockable]="false"
          (buttonClick)="btnCopyErrorClick()"
        >
          Copy Error
        </button>
      </p>
    </app-generic-modal>
  `,
})
export class CalcEngineErrorComponent {
  public modalTitle = 'Calculation Error';
  private readonly CustomErrorMessageSources: string[] = ['EnvelopeNotificationHandler', 'License status:'];
  public modalButtonsConfigs: ModalButtonConfig[] = [defaultModalConfirmButton(() => this.onOkClick())];

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    protected modalService: ModalService,
    private routerHelper: RouterHelperService,
    private store: Store,
  ) {}

  public get parseErrorMessage(): string {
    const error: IAppError = this.config.data?.error ?? '';
    if (this.CustomErrorMessageSources.some((source) => JSON.stringify(error).includes(source))) {
      return error.message;
    }

    return DEFAULT_ERROR_MESSAGE;
  }

  public async btnCopyErrorClick(): Promise<void> {
    await navigator.clipboard.writeText(JSON.stringify(this.config.data.error));
    await this.modalService.showAlert('The error was copied to the clipboard.');
  }

  public async onOkClick(): Promise<void> {
    const uiState = await firstValueFrom(this.store.select(getUiState));
    await this.routerHelper.navigateToScenarioBasedPage(uiState.appModuleType, [RouteModuleWell, RouteModuleWellGeneralData], null);

    const { fileHash, scenarioId } = await firstValueFrom(this.store.select(getDeleteResultsParams));
    if (fileHash != null) {
      const currentScenarioId = await firstValueFrom(this.store.select(getCurrentScenarioId));
      this.store.dispatch(
        removeDataResultsFromStoreIfNeeded({
          fileHash,
          deleteResultsFilter: { scenarioIds: [scenarioId] },
          currentData: { currentScenarioId },
        }),
      );
    }

    this.ref.close();
  }
}
