<div class="dialog-header">
  <h4>Detach License</h4>
</div>
<div class="dialog-content">
  <div class="m-bottom-2" *ngIf="data">
    <p>Detaching product: {{data.product.name}}.</p>
    <app-form-input [label]="daysInputLabel" [source]="{ selectedDetachDays }" [overrideErrorMessage]="validationError" [width]="60"
                    [key]="'selectedDetachDays'" (primitiveValueChanged)="daysValueChanged($event)" [decimalPlaces]="0"></app-form-input>
  </div>
</div>
<div class="dialog-footer">
  <button
    appButton
    type="button"
    buttonType="secondary"
    class="btn btn-cancel btn-sm"
    [dialogRef]="ref"
    [isUiLockable]="false"
    (buttonClick)="ref.close()"
    [isDisabledWhenCalculationActive]="false"
  >
    Cancel
  </button>
  <button
    appButton
    type="button"
    buttonType="primary"
    class="btn btn-primary btn-sm"
    [dialogRef]="ref"
    [isUiLockable]="false"
    (buttonClick)="btnDetachClick()"
    [isDisabledWhenCalculationActive]="false"
  >
    Detach
  </button>
</div>
