import { ChangeDetectorRef, Component, HostBinding } from '@angular/core';
import { Store } from '@ngrx/store';
import * as wellActions from '../../../../+store/well/well.actions';
import * as casingActions from '../../../../+store/well/well.actions';
import { WellModuleState } from '@dunefront/common/modules/well/well-module.state';
import { PipeDataComponent, PipeDimensionData } from '../../completion/lower-completion/pipe-data/pipe-data.component';
import { ModalService } from '../../../../common-modules/modals/modal.service';
import { PipeType } from '@dunefront/common/dto/pipe.dto';
import { ITableRow } from '@dunefront/common/common/common-grid.interfaces';
import { PageWithGridComponent } from '../../../../shared/components/grid/page-with-grid.component';
import { ScreenService } from '../../../../shared/services';
import * as uiActions from '../../../../+store/ui/ui.actions';
import { Pipe } from '@dunefront/common/modules/pipes/pipe';
import { PerforatedCasingPipe } from '@dunefront/common/modules/pipes/casing-pipes/pipes/perforated-casing-pipe';
import { WellHelpIds } from '../../../../+store/help/help.actions';
import { firstValueFrom } from 'rxjs';
import { IUpdateTableRowsProps } from '@dunefront/common/common/common-store-crud.interfaces';
import { getValidatedWellModuleState } from '../../../../+store/well/validated-well.selectors';
import { PanelHelpMode } from '../../../../shared/components/help-button/help-buton.component';
import { getAppModuleType } from '../../../../+store/ui/ui.selectors';
import { isSimulateDisp } from '../../../../+store/menu-selectors/menu-selectors.helpers';

@Component({
  selector: 'app-casing-data',
  templateUrl: './casing-data.component.html',
  styleUrls: ['./casing-data.component.scss'],
})
export class CasingDataComponent extends PageWithGridComponent<Pipe> {
  public wellState!: WellModuleState;
  public PanelHelpMode = PanelHelpMode;
  public arePerforatedCasingPropsAvailable = true;

  constructor(store: Store, cdRef: ChangeDetectorRef, modalService: ModalService, resizeService: ScreenService) {
    super(store, cdRef, modalService, resizeService);

    super.onHelpChange('well', 'pipe-properties');
    store.dispatch(uiActions.setWellTabAction({ tab: 'casing-data' }));
    this.subscription.add(
      store.select(getValidatedWellModuleState).subscribe((state) => {
        this.wellState = state;
        this.setSelectedRow();
        this.cdRef.markForCheck();
      }),
    );

    this.subscription.add(
      this.store.select(getAppModuleType).subscribe((moduleType) => (this.arePerforatedCasingPropsAvailable = !isSimulateDisp(moduleType))),
    );
  }

  @HostBinding('class.perforated-casing-pipe')
  public get showPerforatedCasingProps(): boolean {
    return this.arePerforatedCasingPropsAvailable && this.perforatedCasingPipe != null;
  }

  public get perforatedCasingPipe(): ITableRow<PerforatedCasingPipe> | undefined {
    if (this.selectedRow && this.selectedRow.rowData.PipeType === PipeType.Perforated_Casing) {
      return this.selectedRow as ITableRow<PerforatedCasingPipe>;
    }
    return;
  }

  public get isPipeDataDisabled(): boolean {
    return !this.selectedRow || this.selectedRow.rowData.PipeType === PipeType.Open_Hole;
  }

  public async onShowPipeDataClick(): Promise<void> {
    const result: PipeDimensionData | undefined = await firstValueFrom(this.modalService.open(PipeDataComponent, undefined).onClose);

    this.onPipeDimensionSelected(result);
  }

  public getRows(): ITableRow<Pipe>[] {
    return this.wellState?.CasingData.rows ?? [];
  }

  public updateRow(row: IUpdateTableRowsProps<PerforatedCasingPipe>): void {
    this.store.dispatch(wellActions.updateCasingRow(row as any));
  }

  private onPipeDimensionSelected(pipeData: PipeDimensionData | undefined): void {
    if (!pipeData || !this.selectedRow) {
      return;
    }

    const newRow: ITableRow<Pipe> = {
      ...this.selectedRow,
      rowData: {
        ...this.selectedRow.rowData,
        InnerDiameter: pipeData.innerDiameter,
        OuterDiameter: pipeData.outerDiameter,
        Weight: pipeData.weight,
      },
    };

    this.store.dispatch(
      casingActions.updateCasingRow({
        rows: [newRow],
        colIds: ['InnerDiameter', 'OuterDiameter', 'Weight'],
        focusedRowKey: newRow.rowData.Id,
        shouldResetResults: true,
      }),
    );
  }

  public changeHelp(uri: WellHelpIds): void {
    super.onHelpChange('well', uri);
  }
}
