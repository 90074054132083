import { ImportFileWithMinMaxArgumentsDto, ImportFileWithRangeHashDto } from '../data-storage/dto/import-file.dto';
import { ImportColumnDto } from '../data-storage/dto/import-column.dto';
import { EquationColumnFactory } from './equation-column';
import { sha224 } from 'js-sha256';
import { Dict } from '../../common/state.helpers';
import { DataFileType } from '../../dto/data-storage';

export class EquationHashHelper {
  // Equation Hash is created from update dates from following items:
  // each source file + each column from that file + vertical shift for that column

  public static getEquationSourceFileHashes(files: ImportFileWithRangeHashDto[], columns: ImportColumnDto[]): Dict<IEquationSourceHash> {
    const equationHashes: Dict<IEquationSourceHash> = {};
    const equationFiles = files.filter((file) => file.FileType === DataFileType.EquationResult);

    for (const equationFile of equationFiles) {
      const equationSourceHash = this.getEquationSourceFileHash(equationFile.Id, equationFile.RangeHash, files, columns);
      equationHashes[equationFile.Id] = { equationHash: equationFile.EquationHash ?? '', equationSourceHash };
    }

    return equationHashes;
  }

  public static getEquationSourceFileHash(
    fileId: number,
    rangeHash: string,
    files: ImportFileWithMinMaxArgumentsDto[],
    importColumns: ImportColumnDto[],
  ): string {
    // find equation columns for current file
    const equationColumns = importColumns
      .filter((column) => column.FileId === fileId && column.Equation != null)
      .sort((a, b) => a.Id - b.Id)
      .map((column) => EquationColumnFactory.fromDto(column));

    const columnsByFileMap: Map<number, Set<number>> = new Map();

    // find unique source file ids
    for (const equationColumn of equationColumns) {
      const sortedEquationVariables = equationColumn.Equation.variables.sort((a, b) => a.FileId - b.FileId);
      for (const variable of sortedEquationVariables) {
        if (columnsByFileMap.get(variable.FileId) == null) {
          columnsByFileMap.set(variable.FileId, new Set());
        }
        columnsByFileMap.get(variable.FileId)?.add(variable.ColId);
      }
    }

    // build update dates string
    let updateDates = 'FileHash';
    for (const [fileId, columnsIds] of columnsByFileMap.entries()) {
      const sourceFileUpdateDate = 'FileId_' + fileId + '_' + files.find((f) => f.Id === fileId)?.UpdateDate ?? '';
      const sourceFileColumnsUpdateDates = importColumns
        .filter((col) => col.FileId === fileId && columnsIds.has(col.Id))
        .sort((a, b) => a.Id - b.Id)
        .map((col) => 'ColId_' + col.Id + '_' + (col.UpdateDate ?? ''));
      updateDates = [updateDates, sourceFileUpdateDate, sourceFileColumnsUpdateDates, rangeHash].join();
    }

    return sha224(updateDates);
  }
}

export interface IEquationSourceHash {
  equationHash: string;
  equationSourceHash: string;
}
