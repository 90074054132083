import { BasePptSectionGenerator, PptContext } from '../base-ppt-section-generator';
import { IUnitSystemDto } from '@dunefront/common/dto/unit-system.dto';
import { firstValueFrom } from 'rxjs';
import { selectCurrentUnitSystem } from '../../../../../../+store/units/units.selectors';
import { WellModuleState } from '@dunefront/common/modules/well/well-module.state';
import { getWellModuleState } from '../../../../../../+store/well/well.selectors';
import { CommonReportDataHelpers, IDataRowWithCode } from '../../common/common-report-data-helpers';
import { PptDocumentTableGeneratorHelper } from '../ppt-document-table-generator.helper';
import { Pipe } from '@dunefront/common/modules/pipes/pipe';
import { getRowsForCalculations } from '@dunefront/common/common/common-grid.interfaces';
import { PresetColumnsWidths } from '../../doc/document-table-generator.helper';

export class CasingDataPptGenerator extends BasePptSectionGenerator {
  constructor(context: PptContext) {
    super(context);
  }

  public async generate(): Promise<void> {
    const title = 'Well - Casing Data';
    const currentUnitSystem: IUnitSystemDto = await firstValueFrom(this.store.select(selectCurrentUnitSystem));
    const wellModuleState: WellModuleState = await firstValueFrom(this.store.select(getWellModuleState));
    const casingPipeData: IDataRowWithCode<Pipe>[] = CommonReportDataHelpers.generateCodeValues(
      getRowsForCalculations(wellModuleState.CasingData.rows),
    );
    const { tableData, enumItemMap } = CommonReportDataHelpers.createCasingTableData(casingPipeData);
    const tableRows = PptDocumentTableGeneratorHelper.createTableRows<Pipe>(tableData, enumItemMap, currentUnitSystem);

    const presetColumnsWidths: PresetColumnsWidths = { 1: 20 };

    this.addTableSlide({ title, tableRows, presetColumnsWidths });
  }
}
