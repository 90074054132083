import { DataType } from '../../../dto/data-storage';
import { ChartDataSourceType, ChartDto } from './chart.dto';
import { ChartAxis } from './chart-axis-property.dto';
import { RangeConstants } from '../../../dto/range.dto';
import { ChartDataset, Point } from 'chart.js';
import { UnitSystem } from '../../../dto/unit-system.dto';
import { EnumHelpers } from '../../../utils/enum.helpers';
import { UnitConverterHelper } from '../../../unit-converters/unit.converter.helper';
import { SeriesLineStyle } from './chart.types';
import { IAxisUnit } from '../../../unit-converters/converter.interfaces';
import { VerticalShiftDto } from '../../../dto/trend-analysis/vertical-shift.dto';

export const undefinedChartId = -1;

/***
 * Object contains all data needed to draw chart
 */
export interface IChartDataDto {
  ChartId: number;
  ChartDataSourceType: ChartDataSourceType;
  ChartDataColumns: IChartDataDtoColumn[];
  ChartDataSets: IChartDataSetDto[];
  ArgumentDataType: DataType;
  SecondaryArgumentDataType?: DataType;
  IsPrimaryArgument: boolean;
  IsMaxResolution: boolean;
  ArgumentStart?: number;
  ArgumentEnd?: number;
  RangeId?: number;
  StartDate?: number;
}

export type ArgumentColumnName = 'Argument' | 'SecondaryArgument';

export const getArgumentColumnName = (isPrimaryArgument: boolean): ArgumentColumnName =>
  isPrimaryArgument ? 'Argument' : 'SecondaryArgument';

export const getArgument = (chartDataDto: IChartDataDto, row: IChartDataRowDto): number =>
  chartDataDto.IsPrimaryArgument || row.SecondaryArgument == null ? row.Argument : row.SecondaryArgument;

export const getArgumentAxisUnit = (chartDataDto: IChartDataDto): IAxisUnit => {
  const dataType =
    chartDataDto.IsPrimaryArgument || chartDataDto.SecondaryArgumentDataType == null
      ? chartDataDto.ArgumentDataType
      : chartDataDto.SecondaryArgumentDataType;
  return { dataType, unitSystem: UnitConverterHelper.getUnitSystemFromDataType(dataType) };
};

/***
 * Chart Column definition with settings
 */
export interface IChartDataDtoColumn {
  Name: string;
  AxisType: ChartAxis;
  DataType: DataType;
  DataSetIndex: number;
  DataValueIndex: number;
  ColumnId?: number;
  FileId?: number;
  SmoothingPoints?: number;
  VerticalOffset?: number;
  Multiplier?: number;
  ScenarioId?: number;
  AddSimulateToEvaluate?: boolean;
  UnitSystem: UnitSystem;
  ColumnDefaultStyle?: Partial<IColumnStyle>;
}

export interface IColumnStyle {
  lineStyle?: SeriesLineStyle;
}

export interface IChartDataSetDto {
  XAxisShift: number;
  ChartDataRows: IChartDataRowDto[];
  IsImportedData: boolean;
  FileId: number | null; // is set to null for client defined charts (like survey);
  ResampleInterval: number | null;
}

export interface IChartDatasetWithSrcKey extends IChartDataSetDto {
  srcKeyPrefix: string;
}

export interface IChartDataRowDto {
  RowIndex?: number;
  Argument: number;
  SecondaryArgument?: number;
  SourceArgument?: string;
  Values: (number | undefined)[];
}

export enum DepthDataStatus {
  NotRequested = 0,
  SetByCalculation = 1,
  Requested = 2,
  Loaded = 3,
}

export const isFullRange = (chartData: IChartDataDto): boolean => chartData.RangeId === RangeConstants.EntireRangeId;

export const createEmptyChartData = (
  argumentDataType: DataType,
  chartDataSourceType: ChartDataSourceType,
  chartId: number,
  rangeId = RangeConstants.EmptyRangeId,
  secondaryArgumentDataType: DataType | undefined = undefined,
): IChartDataDto => ({
  ChartId: chartId,
  ArgumentDataType: argumentDataType,
  ChartDataColumns: [],
  ChartDataSets: [],
  RangeId: rangeId,
  IsPrimaryArgument: true,
  IsMaxResolution: true,
  SecondaryArgumentDataType: secondaryArgumentDataType,
  ChartDataSourceType: chartDataSourceType,
});

export const getChartId = (chartDtos: ChartDto[], source: ChartDataSourceType): number =>
  chartDtos.find((chart) => chart.Source === source)?.Id ?? undefinedChartId;

export const getChartDto = (chartDtos: ChartDto[], source: ChartDataSourceType): ChartDto | undefined =>
  chartDtos.find((chart) => chart.Source === source);

export const createEmptyChartDataWithChartTemplateId = (
  argumentDataType: DataType,
  chartDataSourceType: ChartDataSourceType,
  chartDtos: ChartDto[],
  rangeId: number,
): IChartDataDto => createEmptyChartData(argumentDataType, chartDataSourceType, getChartId(chartDtos, chartDataSourceType), rangeId);

export const createChartDataRowDto = (
  argument: number,
  values: (number | undefined)[],
  rowIdex: number | undefined = undefined,
): IChartDataRowDto => ({ Argument: argument, RowIndex: rowIdex, Values: values });

export const createChartDataSet = (chartDataRows: IChartDataRowDto[] = []): IChartDataSetDto => ({
  XAxisShift: 0,
  ChartDataRows: chartDataRows,
  IsImportedData: false,
  FileId: null,
  ResampleInterval: null,
});

export const createChartDataColumn = (
  axisType: ChartAxis,
  dataType: DataType,
  datasetIndex: number,
  dataValueIndex: number,
  name: string | undefined = undefined,
  style: Partial<IColumnStyle> | undefined = undefined,
): IChartDataDtoColumn => ({
  AxisType: axisType,
  DataType: dataType,
  DataSetIndex: datasetIndex,
  DataValueIndex: dataValueIndex,
  Name: name ?? EnumHelpers.enumToText(DataType[dataType]),
  UnitSystem: UnitConverterHelper.getUnitSystemFromDataType(dataType),
  ColumnDefaultStyle: style,
});

export type LineChartDataSet = ChartDataset<'line'>;

export const getInterpolatedColumnValue = (
  argument: number,
  columnId: number,
  chartData: IChartDataDto,
  verticalShifts: VerticalShiftDto[],
): number | undefined => {
  const column = chartData.ChartDataColumns.find((col) => col.ColumnId === columnId);
  if (column == null) {
    return undefined;
  }

  const verticalShift = (column.VerticalOffset ?? 0) + (verticalShifts.find((vs) => vs.ColumnId === column.ColumnId)?.ShiftValue ?? 0);
  const dataset = chartData.ChartDataSets[column.DataSetIndex];

  for (let rowIndex = 0; rowIndex < dataset.ChartDataRows.length - 1; rowIndex++) {
    const row = dataset.ChartDataRows[rowIndex];
    const rowArg = row.Argument + dataset.XAxisShift;
    // argument is outside dataset ags range
    if (argument < rowArg) {
      return undefined;
    }

    const nextRow = dataset.ChartDataRows[rowIndex + 1];
    const nextRowArg = nextRow.Argument + dataset.XAxisShift;

    const rowValue = row.Values[column.DataValueIndex];
    const newRowValue = nextRow.Values[column.DataValueIndex];
    if (rowValue == null || newRowValue == null) {
      continue;
    }

    if (rowArg <= argument && nextRowArg >= argument) {
      return calcInterpolatedValue(
        argument,
        { x: rowArg, y: rowValue + verticalShift },
        {
          x: nextRowArg,
          y: newRowValue + verticalShift,
        },
      );
    }
  }

  return undefined;
};

const calcInterpolatedValue = (argument: number, p1: Point, p2: Point): number => {
  const slope = (p2.y - p1.y) / (p2.x - p1.x);
  return p1.y + (argument - p1.x) * slope;
};
