import { createSelector, createSelectorFactory, defaultMemoize } from '@ngrx/store';
import { getPumpingValidationDeps } from './pumping-validation-deps.selectors';
import { getCurrentPumpingModuleState, getEvaluationPumpingSchedule, getPumpingSchedule } from './pumping.selectors';
import {
  IPumpingValidationDependencies,
  PumpingStateByRange,
  ValidatedAnalysisDataComponentState,
  ValidatedPumpingModuleState,
} from '@dunefront/common/modules/pumping/pumping-module.state';
import { ITableState } from '@dunefront/common/common/common-grid.interfaces';
import { PumpingSchedule } from '@dunefront/common/modules/pumping/model/pumping-schedule/pumping-schedule';
import { PumpingStateValidation } from '@dunefront/common/modules/pumping/model/pumping-state.validation';

const pumpingComparer: () => (incoming: any, current: any) => boolean = () => {
  let currentJson = '';
  return (incoming: any, current: any): boolean => {
    if (incoming === current) {
      return true;
    }

    const incomingJson = JSON.stringify(incoming);
    if (currentJson !== incomingJson) {
      currentJson = incomingJson;
      return false;
    }

    return true;
  };
};

export const getValidatedPumpingModuleState = createSelectorFactory<any, ValidatedPumpingModuleState>((projection) =>
  defaultMemoize(projection, pumpingComparer()),
)(
  getCurrentPumpingModuleState,
  getPumpingSchedule,
  getEvaluationPumpingSchedule,
  getPumpingValidationDeps,
  (
    ...[pumpingState, pumpingSchedule, evaluationSchedule, validationDeps]: [
      PumpingStateByRange,
      ITableState<PumpingSchedule>,
      ITableState<PumpingSchedule>,
      IPumpingValidationDependencies,
      ValidatedAnalysisDataComponentState,
    ]
  ) => PumpingStateValidation.validate(pumpingState, pumpingSchedule, evaluationSchedule, validationDeps),
);

export const getValidatedPumping = createSelector(getValidatedPumpingModuleState, (state) => state.pumping);
export const getWellFluidsRows = createSelector(getValidatedPumpingModuleState, (state) => state.wellFluids.rows);
export const getValidatedPumpingSchedule = createSelector(getValidatedPumpingModuleState, (state) => state.pumpingSchedule);
export const getValidatedEvaluationPumpingSchedule = createSelector(getValidatedPumpingModuleState, (state) => state.evaluationSchedule);
