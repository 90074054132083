import { UnitConverter } from '../../converter.interfaces';
import { PressureRateDerivativeUnit } from '../../../dto/unit-system.dto';

export class PressureRateSquaredDerivativeConverter extends UnitConverter {
  public static symbols: Array<string> = [
    '',
    'Pa/(m³/s)²',
    'Pa/(m³/min)²',
    'Pa/(ft³/min)²',
    'Pa/(ft³/s)²',
    'Pa/(bbl/min)²',
    'Pa/(L/min)²',
    'Pa/(galUS/min)²',
    'kPa/(m³/s)²',
    'kPa/(m³/min)²',
    'kPa/(ft³/min)²',
    'kPa/(ft³/s)²',
    'kPa/(bbl/min)²',
    'kPa/(L/min)²',
    'kPa/(galUS/min)²',
    'psi/(m³/s)²',
    'psi/(m³/min)²',
    'psi/(ft³/min)²',
    'psi/(ft³/s)²',
    'psi/(bbl/min)²',
    'psi/(L/min)²',
    'psi/(galUS/min)²',
    'kg/cm²/(m³/s)²',
    'kg/cm²/(m³/min)²',
    'kg/cm²/(ft³/min)²',
    'kg/cm²/(ft³/s)²',
    'kg/cm²/(bbl/min)²',
    'kg/cm²/(L/min)²',
    'kg/cm²/(galUS/min)²',
    'bar/(m³/s)²',
    'bar/(m³/min)²',
    'bar/(ft³/min)²',
    'bar/(ft³/s)²',
    'bar/(bbl/min)²',
    'bar/(L/min)²',
    'bar/(galUS/min)²',
    'MPa/(m³/s)²',
    'MPa/(m³/min)²',
    'MPa/(ft³/min)²',
    'MPa/(ft³/s)²',
    'MPa/(bbl/min)²',
    'MPa/(L/min)²',
    'MPa/(galUS/min)²',
  ];

  public static unit = PressureRateDerivativeUnit;

  public static fromSi(value: number, unit: PressureRateDerivativeUnit): number {
    switch (unit) {
      case PressureRateDerivativeUnit.Pascal_per_cubic_meter_per_second: {
        return value;
      }
      case PressureRateDerivativeUnit.Pascal_per_cubic_meter_per_minute: {
        return value * 2.7777777778e-4;
      }
      case PressureRateDerivativeUnit.Pascal_per_cubic_foot_per_minute: {
        return value * 2.2273228747e-7;
      }
      case PressureRateDerivativeUnit.Pascal_per_cubic_foot_per_second: {
        return value * 8.0184380189e-4;
      }
      case PressureRateDerivativeUnit.Pascal_per_barrel_per_minute: {
        return value * 7.0213777719e-6;
      }
      case PressureRateDerivativeUnit.Pascal_per_liter_per_minute: {
        return value * 2.7777777778e-10;
      }
      case PressureRateDerivativeUnit.Pascal_per_us_gallon_per_minute: {
        return value * 3.9803729026e-9;
      }
      case PressureRateDerivativeUnit.Kilo_Pascal_per_cubic_meter_per_second: {
        return value * 1.0e-3;
      }
      case PressureRateDerivativeUnit.Kilo_Pascal_per_cubic_meter_per_minute: {
        return value * 2.7777777778e-7;
      }
      case PressureRateDerivativeUnit.Kilo_Pascal_per_cubic_foot_per_minute: {
        return value * 2.2273228747e-10;
      }
      case PressureRateDerivativeUnit.Kilo_Pascal_per_cubic_foot_per_second: {
        return value * 8.0184380189e-7;
      }
      case PressureRateDerivativeUnit.Kilo_Pascal_per_barrel_per_minute: {
        return value * 7.0213777719e-9;
      }
      case PressureRateDerivativeUnit.Kilo_Pascal_per_liter_per_minute: {
        return value * 2.7777777778e-13;
      }
      case PressureRateDerivativeUnit.Kilo_Pascal_per_us_gallon_per_minute: {
        return value * 3.9803729026e-12;
      }
      case PressureRateDerivativeUnit.Pound_per_square_inch_per_cubic_meter_per_second: {
        return value * 1.45037738e-4;
      }
      case PressureRateDerivativeUnit.Pound_per_square_inch_per_cubic_meter_per_minute: {
        return value * 4.0288260556e-8;
      }
      case PressureRateDerivativeUnit.Pound_per_square_inch_per_cubic_foot_per_minute: {
        return value * 3.2304587154e-11;
      }
      case PressureRateDerivativeUnit.Pound_per_square_inch_per_cubic_foot_per_second: {
        return value * 1.1629761126e-7;
      }
      case PressureRateDerivativeUnit.Pound_per_square_inch_per_barrel_per_minute: {
        return value * 1.0183647497e-9;
      }
      case PressureRateDerivativeUnit.Pound_per_square_inch_per_liter_per_minute: {
        return value * 4.0288260556e-14;
      }
      case PressureRateDerivativeUnit.Pound_per_square_inch_per_us_gallon_per_minute: {
        return value * 5.7730428219e-13;
      }
      case PressureRateDerivativeUnit.Kilogram_per_square_centimeter_per_cubic_meter_per_second: {
        return value * 1.019716213e-5;
      }
      case PressureRateDerivativeUnit.Kilogram_per_square_centimeter_per_cubic_meter_per_minute: {
        return value * 2.8325450361e-9;
      }
      case PressureRateDerivativeUnit.Kilogram_per_square_centimeter_per_cubic_foot_per_minute: {
        return value * 2.2712372469e-12;
      }
      case PressureRateDerivativeUnit.Kilogram_per_square_centimeter_per_cubic_foot_per_second: {
        return value * 8.1765312508e-9;
      }
      case PressureRateDerivativeUnit.Kilogram_per_square_centimeter_per_barrel_per_minute: {
        return value * 7.1598127516e-11;
      }
      case PressureRateDerivativeUnit.Kilogram_per_square_centimeter_per_liter_per_minute: {
        return value * 2.8325450361e-15;
      }
      case PressureRateDerivativeUnit.Kilogram_per_square_centimeter_per_us_gallon_per_minute: {
        return value * 4.0588507826e-14;
      }
      case PressureRateDerivativeUnit.Bar_per_cubic_meter_per_second: {
        return value * 1.0e-5;
      }
      case PressureRateDerivativeUnit.Bar_per_cubic_meter_per_minute: {
        return value * 2.7777777778e-9;
      }
      case PressureRateDerivativeUnit.Bar_per_cubic_foot_per_minute: {
        return value * 2.2273228747e-12;
      }
      case PressureRateDerivativeUnit.Bar_per_cubic_foot_per_second: {
        return value * 8.0184380189e-9;
      }
      case PressureRateDerivativeUnit.Bar_per_barrel_per_minute: {
        return value * 7.0213777719e-11;
      }
      case PressureRateDerivativeUnit.Bar_per_liter_per_minute: {
        return value * 2.7777777778e-15;
      }
      case PressureRateDerivativeUnit.Bar_per_us_gallon_per_minute: {
        return value * 3.9803729026e-14;
      }

      case PressureRateDerivativeUnit.MPa_per_cubic_meter_per_second: {
        return value * 1.0e-6;
      }
      case PressureRateDerivativeUnit.MPa_per_cubic_meter_per_minute: {
        return value * 2.7777777778e-10;
      }
      case PressureRateDerivativeUnit.MPa_per_cubic_foot_per_minute: {
        return value * 2.2273438914e-13;
      }
      case PressureRateDerivativeUnit.MPa_per_cubic_foot_per_second: {
        return value * 8.0184380091e-10;
      }
      case PressureRateDerivativeUnit.MPa_per_barrel_per_minute: {
        return value * 7.0213517136e-12;
      }
      case PressureRateDerivativeUnit.MPa_per_liter_per_minute: {
        return value * 2.7777777778e-16;
      }
      case PressureRateDerivativeUnit.MPa_per_us_gallon_per_minute: {
        return value * 3.98036913e-15;
      }
      default: {
        throw new TypeError('Unknown Pressure Rate Squared Derivative Unit:' + unit);
      }
    }
  }

  public static toSi(value: number, unit: PressureRateDerivativeUnit): number {
    switch (unit) {
      case PressureRateDerivativeUnit.Pascal_per_cubic_meter_per_second: {
        return value;
      }
      case PressureRateDerivativeUnit.Pascal_per_cubic_meter_per_minute: {
        return value / 2.7777777778e-4;
      }
      case PressureRateDerivativeUnit.Pascal_per_cubic_foot_per_minute: {
        return value / 2.2273228747e-7;
      }
      case PressureRateDerivativeUnit.Pascal_per_cubic_foot_per_second: {
        return value / 8.0184380189e-4;
      }
      case PressureRateDerivativeUnit.Pascal_per_barrel_per_minute: {
        return value / 7.0213777719e-6;
      }
      case PressureRateDerivativeUnit.Pascal_per_liter_per_minute: {
        return value / 2.7777777778e-10;
      }
      case PressureRateDerivativeUnit.Pascal_per_us_gallon_per_minute: {
        return value / 3.9803729026e-9;
      }
      case PressureRateDerivativeUnit.Kilo_Pascal_per_cubic_meter_per_second: {
        return value / 1.0e-3;
      }
      case PressureRateDerivativeUnit.Kilo_Pascal_per_cubic_meter_per_minute: {
        return value / 2.7777777778e-7;
      }
      case PressureRateDerivativeUnit.Kilo_Pascal_per_cubic_foot_per_minute: {
        return value / 2.2273228747e-10;
      }
      case PressureRateDerivativeUnit.Kilo_Pascal_per_cubic_foot_per_second: {
        return value / 8.0184380189e-7;
      }
      case PressureRateDerivativeUnit.Kilo_Pascal_per_barrel_per_minute: {
        return value / 7.0213777719e-9;
      }
      case PressureRateDerivativeUnit.Kilo_Pascal_per_liter_per_minute: {
        return value / 2.7777777778e-13;
      }
      case PressureRateDerivativeUnit.Kilo_Pascal_per_us_gallon_per_minute: {
        return value / 3.9803729026e-12;
      }
      case PressureRateDerivativeUnit.Pound_per_square_inch_per_cubic_meter_per_second: {
        return value / 1.45037738e-4;
      }
      case PressureRateDerivativeUnit.Pound_per_square_inch_per_cubic_meter_per_minute: {
        return value / 4.0288260556e-8;
      }
      case PressureRateDerivativeUnit.Pound_per_square_inch_per_cubic_foot_per_minute: {
        return value / 3.2304587154e-11;
      }
      case PressureRateDerivativeUnit.Pound_per_square_inch_per_cubic_foot_per_second: {
        return value / 1.1629761126e-7;
      }
      case PressureRateDerivativeUnit.Pound_per_square_inch_per_barrel_per_minute: {
        return value / 1.0183647497e-9;
      }
      case PressureRateDerivativeUnit.Pound_per_square_inch_per_liter_per_minute: {
        return value / 4.0288260556e-14;
      }
      case PressureRateDerivativeUnit.Pound_per_square_inch_per_us_gallon_per_minute: {
        return value / 5.7730428219e-13;
      }
      case PressureRateDerivativeUnit.Kilogram_per_square_centimeter_per_cubic_meter_per_second: {
        return value / 1.019716213e-5;
      }
      case PressureRateDerivativeUnit.Kilogram_per_square_centimeter_per_cubic_meter_per_minute: {
        return value / 2.8325450361e-9;
      }
      case PressureRateDerivativeUnit.Kilogram_per_square_centimeter_per_cubic_foot_per_minute: {
        return value / 2.2712372469e-12;
      }
      case PressureRateDerivativeUnit.Kilogram_per_square_centimeter_per_cubic_foot_per_second: {
        return value / 8.1765312508e-9;
      }
      case PressureRateDerivativeUnit.Kilogram_per_square_centimeter_per_barrel_per_minute: {
        return value / 7.1598127516e-11;
      }
      case PressureRateDerivativeUnit.Kilogram_per_square_centimeter_per_liter_per_minute: {
        return value / 2.8325450361e-15;
      }
      case PressureRateDerivativeUnit.Kilogram_per_square_centimeter_per_us_gallon_per_minute: {
        return value / 4.0588507826e-14;
      }
      case PressureRateDerivativeUnit.Bar_per_cubic_meter_per_second: {
        return value / 1.0e-5;
      }
      case PressureRateDerivativeUnit.Bar_per_cubic_meter_per_minute: {
        return value / 2.7777777778e-9;
      }
      case PressureRateDerivativeUnit.Bar_per_cubic_foot_per_minute: {
        return value / 2.2273228747e-12;
      }
      case PressureRateDerivativeUnit.Bar_per_cubic_foot_per_second: {
        return value / 8.0184380189e-9;
      }
      case PressureRateDerivativeUnit.Bar_per_barrel_per_minute: {
        return value / 7.0213777719e-11;
      }
      case PressureRateDerivativeUnit.Bar_per_liter_per_minute: {
        return value / 2.7777777778e-15;
      }
      case PressureRateDerivativeUnit.Bar_per_us_gallon_per_minute: {
        return value / 3.9803729026e-14;
      }

      case PressureRateDerivativeUnit.MPa_per_cubic_meter_per_second: {
        return value / 1.0e-6;
      }
      case PressureRateDerivativeUnit.MPa_per_cubic_meter_per_minute: {
        return value / 2.7777777778e-10;
      }
      case PressureRateDerivativeUnit.MPa_per_cubic_foot_per_minute: {
        return value / 2.2273438914e-13;
      }
      case PressureRateDerivativeUnit.MPa_per_cubic_foot_per_second: {
        return value / 8.0184380091e-10;
      }
      case PressureRateDerivativeUnit.MPa_per_barrel_per_minute: {
        return value / 7.0213517136e-12;
      }
      case PressureRateDerivativeUnit.MPa_per_liter_per_minute: {
        return value / 2.7777777778e-16;
      }
      case PressureRateDerivativeUnit.MPa_per_us_gallon_per_minute: {
        return value / 3.98036913e-15;
      }

      default: {
        throw new TypeError('Unknown Pressure Rate Squared Derivative Unit:' + unit);
      }
    }
  }

  public static getSymbol(unit: PressureRateDerivativeUnit): string {
    if (unit == null) {
      return '';
    }
    return this.symbols[unit];
  }
}
