<div class="grid-form-container no-padding">
  <app-font-style
    appUnwrap
    [labelPrefix]="'Title'"
    [source]="axisStyle"
    [sourceDefaults]="defaultAxisStyle"
    [fontSizeKey]="'AxisTitleFontSize'"
    [fontColorKey]="'AxisTitleFontColor'"
    [isBoldKey]="'AxisTitleFontBold'"
    [isItalicKey]="'AxisTitleFontItalic'"
    [disabled]="disabled"
    (valueChanged)="valueChanged.emit($event)"
    [idPrefix]="'axis-title'"
  ></app-font-style>

  <app-font-style
    appUnwrap
    [labelPrefix]="'Label'"
    [source]="axisStyle"
    [sourceDefaults]="defaultAxisStyle"
    [fontSizeKey]="'AxisLabelFontSize'"
    [isBoldKey]="'AxisLabelFontBold'"
    [isItalicKey]="'AxisLabelFontItalic'"
    [fontColorKey]="'AxisLabelFontColor'"
    [idPrefix]="'axis-label'"
    [disabled]="disabled"
    (valueChanged)="valueChanged.emit($event)"
  ></app-font-style>
</div>
