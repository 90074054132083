import { Routes } from '@angular/router';
import { GravelComponent } from './gravel.component';
import { ProjectGravelsComponent } from './project-gravels/project-gravels.component';
import { DatabaseGravelsComponent } from './database-gravels/database-gravels.component';
import {
  RouteModuleGravels,
  RouteModuleGravelsAdvancedProperties,
  RouteModuleGravelsDatabaseGravels,
  RouteModuleGravelsProjectGravels,
} from './gravel-routes-names';
import { GravelAdvancedPropertiesComponent } from './gravel-advanced-properties/gravel-advanced-properties.component';

export const gravelsRoutes: Routes = [
  {
    path: RouteModuleGravels,
    component: GravelComponent,

    data: {
      breadcrumb: 'Gravel',
    },
    children: [
      {
        path: `${RouteModuleGravelsProjectGravels}/:gravelId`,
        component: ProjectGravelsComponent,
        data: {
          breadcrumb: 'Project Gravels',
        },
      },
      {
        path: RouteModuleGravelsProjectGravels,
        redirectTo: `${RouteModuleGravelsProjectGravels}/`,
        pathMatch: 'full',
      },
      {
        path: `${RouteModuleGravelsAdvancedProperties}/:gravelId`,
        component: GravelAdvancedPropertiesComponent,
        data: {
          breadcrumb: 'Advanced Gravel Properties',
        },
      },
      {
        path: RouteModuleGravelsAdvancedProperties,
        redirectTo: `${RouteModuleGravelsAdvancedProperties}/`,
        pathMatch: 'full',
      },
      {
        path: RouteModuleGravelsDatabaseGravels,
        component: DatabaseGravelsComponent,
        data: {
          breadcrumb: 'Database Gravels',
        },
      },
      {
        path: '',
        redirectTo: RouteModuleGravelsProjectGravels,
        pathMatch: 'full',
      },
    ],
  },
];
