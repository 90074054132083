<div class="sm-scroll">
  <div class="grid-form-container no-padding" *ngIf="gravel">
    <app-form-input
      [source]="gravel"
      [key]="'Name'"
      appUnwrap
      label="Gravel Name"
      dataCy="gravel-name"
      class="no-padding-left"
      [isTextInput]="true"
      [unitType]="UnitType.None"
      [inputWidth]="250"
      (valueChanged)="emitValue($event)"
    >
    </app-form-input>
    <label>Mesh Size</label>
    <app-select
      [source]="gravel"
      [key]="'MeshSize'"
      [items]="meshSizes"
      [disableSorting]="true"
      [width]="250"
      dataCy="gravel-mesh-size"
      (valueChanged)="emitValue($event)"
    >
    </app-select>
    <app-form-input
      [source]="gravel"
      [key]="'MeanDiameter'"
      appUnwrap
      label="Mean Diameter"
      dataCy="gravel-mean-diameter"
      [unitType]="UnitType.Diameter"
      [inputWidth]="250"
      [decimalPlaces]="3"
      (valueChanged)="emitValue($event)"
    >
    </app-form-input>
    <app-form-input
      [source]="gravel"
      [key]="'AbsoluteDensity'"
      appUnwrap
      label="Density"
      dataCy="gravel-absolute-density"
      [unitType]="UnitType.Solid_Density"
      [inputWidth]="250"
      (valueChanged)="emitValue($event)"
    >
    </app-form-input>
    <app-form-input
      [source]="gravel"
      [key]="'BulkDensity'"
      appUnwrap
      label="Bulk Density"
      dataCy="gravel-bulk-density"
      [unitType]="UnitType.Bulk_Density"
      [inputWidth]="250"
      (valueChanged)="emitValue($event)"
    >
    </app-form-input>
    <app-form-input
      [source]="gravel"
      [key]="'PackPermeability'"
      appUnwrap
      label="Pack Permeability"
      dataCy="gravel-pack-permeability"
      [unitType]="UnitType.Permeability"
      [inputWidth]="250"
      (valueChanged)="emitValue($event)"
    >
    </app-form-input>
    <div><!--offset--></div>
    <div *ngIf="gravelAdvancedOptionsAvailable$ | async" class="button-panel">
      <button
        appButton
        data-cy="open-advanced-gravel-options"
        type="button"
        class="btn btn-primary btn-sm"
        [isUiLockable]="false"
        tooltipPosition="top"
        positionStyle="absolute"
        tooltipStyleClass="error-tooltip"
        [tooltipDisabled]="gravelAdvOptionsValid"
        [pTooltip]="'Click the button to correct advanced properties errors'"
        (buttonClick)="openAdvancedGravelProperties()"
      >
        Advanced Properties<span *ngIf="!gravelAdvOptionsValid" class="error-row-icon"></span>
      </button>
    </div>
  </div>
</div>
