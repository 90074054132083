import { DeleteRowsWsAction, UpdateRowsWsAction, WsAction } from '../../ws.action';
import { EntityToCopy, IEntitiesExtras, ScenarioDto } from './scenario.dto';
import { LoadSettingsActionResponse } from '../settings/settings-module.actions';
import { LoadCompletionActionResponse } from '../completion/completion-module.actions';
import { LoadFluidsActionResponse } from '../fluid/fluid-module.actions';
import { LoadGravelsActionResponse } from '../gravel/gravel-module.actions';
import { LoadPumpingActionResponse } from '../pumping/pumping-module.actions';
import { LoadWellActionResponse } from '../well/well-module.actions';
import { LoadMASPCalculatorActionResponse } from '../calculators/masp-calculator/masp-calculator-module.action';
import { LoadResuspensionCalculatorActionResponse } from '../calculators/resuspension-calculator/resuspension-calculator.action';
import { LoadFrictionCalculatorActionResponse } from '../calculators/friction-calculator/friction-calculator-module.action';
import { LoadSettlingCalculatorActionResponse } from '../calculators/settling-calculator-module.action';
import { LoadInjectionTestCalculatorActionResponse } from '../calculators/injection-test-calculator/injection-test-calculator-module.action';
import { LoadLeakoffCoefficientCalculatorActionResponse } from '../calculators/leakoff-coefficient-calculator/leakoff-coefficient-calculator-module.action';
import { LoadReportingActionResponse } from '../reporting/reporting-module.actions';
import { LoadPSDAnalysisActionResponse } from '../psd-analysis/psd-analysis-module.actions';
import { LoadDataStorageActionResponse } from '../data-storage/data-storage-module.actions';
import { LoadRangesActionResponse } from '../range/range-module.actions';
import { LoadNotesActionResponse } from '../note/note-module.actions';
import { ActionResponse } from '../../response-ws.action';

export const ScenarioModuleName = 'ScenarioModule';

export enum ScenarioModuleActionTypes {
  Load = '[ScenarioModule] Load',
  UpdateScenarioRow = '[ScenarioModule] UpdateRow',
  DeleteScenarioRows = '[ScenarioModule] DeleteRows',
  CopyScenarioRow = '[ScenarioModule] [InsertRows] Copy',
  CreateScenario = '[ScenarioModule] InsertRows',
  CloneScenario = '[ScenarioModule] [UpdateRow] Clone',
}

// region load

export class LoadScenarioAction extends WsAction {
  public override readonly type = ScenarioModuleActionTypes.Load;

  constructor(public scenarioId: number) {
    super();
  }
}

export interface LoadScenarioActionResponse {
  settingsModule: ActionResponse<LoadSettingsActionResponse>;
  completionModule: ActionResponse<LoadCompletionActionResponse>;
  fluidModule: ActionResponse<LoadFluidsActionResponse>;
  gravelModule: ActionResponse<LoadGravelsActionResponse>;
  MASPCalculatorModule: ActionResponse<LoadMASPCalculatorActionResponse>;
  resuspensionCalculatorModule: ActionResponse<LoadResuspensionCalculatorActionResponse>;
  leakoffCoefficientCalculatorModule: ActionResponse<LoadLeakoffCoefficientCalculatorActionResponse>;
  frictionCalculatorModule: ActionResponse<LoadFrictionCalculatorActionResponse>;
  settlingCalculatorModule: ActionResponse<LoadSettlingCalculatorActionResponse>;
  injectionTestCalculatorModule: ActionResponse<LoadInjectionTestCalculatorActionResponse>;
  psdAnalysisModule: ActionResponse<LoadPSDAnalysisActionResponse>;
  pumpingModule: ActionResponse<LoadPumpingActionResponse>;
  wellModule: ActionResponse<LoadWellActionResponse>;
  reportingModule: ActionResponse<LoadReportingActionResponse>;
  dataStorageModule: ActionResponse<LoadDataStorageActionResponse>;
  rangeModule: ActionResponse<LoadRangesActionResponse>;
  noteModule: ActionResponse<LoadNotesActionResponse>;
  scenarioId: number;
}

// endregion

export class CreateScenarioAction extends WsAction {
  public override readonly type = ScenarioModuleActionTypes.CreateScenario;

  constructor(public name: string) {
    super();
  }
}

export class UpdateScenarioRowAction extends UpdateRowsWsAction<ScenarioDto> {
  public override readonly type = ScenarioModuleActionTypes.UpdateScenarioRow;
}

export class DeleteScenarioRowsAction extends DeleteRowsWsAction {
  public override readonly type = ScenarioModuleActionTypes.DeleteScenarioRows;
}

export class CopyScenarioRowAction extends WsAction {
  public override readonly type = ScenarioModuleActionTypes.CopyScenarioRow;

  constructor(
    public scenarioId: number,
    public scenarioName: string,
  ) {
    super();
  }
}

export class CloneScenarioAction extends WsAction {
  public override readonly type = ScenarioModuleActionTypes.CloneScenario;

  constructor(
    public sourceScenarioId: number,
    public targetScenarioIds: number[],
    public entitiesToCopy: EntityToCopy[],
    public chartsToCopy: number[],
    public sourceRangeId?: number,
    public targetRangeId?: number,
    public entitiesExtras?: IEntitiesExtras,
  ) {
    super();
  }
}

export type ScenarioModuleActions =
  | LoadScenarioAction
  | CreateScenarioAction
  | CloneScenarioAction
  | UpdateScenarioRowAction
  | DeleteScenarioRowsAction
  | CopyScenarioRowAction;
