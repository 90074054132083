import { createSelector } from '@ngrx/store';
import { getGaugeDataLeftNavElement } from '../gauge-data-page/gauge-data.menu';
import { getTrendAnalysisPageValidation } from '../../+store/trend-analysis/trend-analysis-page.selectors';
import { ILeftNavItem, LeftNavHelpers } from '../../shared/components/left-nav/left-nav.helpers';
import { getSimulateEvaluateReportingLeftNavElement } from '../simulate-evaluate-page/simulate-evaluate-module.menu';
import { getSettingsLeftNavElement } from '../common/settings/settings.menu';

export const getTrendAnalysisModuleMenuData = createSelector(
  getTrendAnalysisPageValidation,
  getGaugeDataLeftNavElement,
  getSimulateEvaluateReportingLeftNavElement,
  getSettingsLeftNavElement,
  (isValid, gaugeDataLeftNavElement, reportingLeftNavElement, settings): ILeftNavItem[] => [
    gaugeDataLeftNavElement,
    LeftNavHelpers.getNavElement(
      'Trend analysis',
      'trend-analysis',
      'icon-chart-analytics-modern-circle',
      isValid.isTrendAnalysisValid,
      false,
      [],
    ),
    settings,
    LeftNavHelpers.getBreak(),
    LeftNavHelpers.getStartStopButton(),
    reportingLeftNavElement,
  ],
);
