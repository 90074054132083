import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { GenericOptionsComponent } from '../generic-options.component';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-chart-marker-options',
  templateUrl: './chart-marker-options.component.html',
  styleUrls: ['./chart-marker-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartMarkerOptionsComponent extends GenericOptionsComponent {
  constructor(store: Store, cdRef: ChangeDetectorRef) {
    super(store, cdRef);
  }
}
