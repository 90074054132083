import { Directive, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as uiActions from '../../+store/ui/ui.actions';
import { clearCalculatorsTabAction } from '../../+store/ui/ui.actions';
import { ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import { CalculatorsTab } from '../../+store/ui/ui-module.state';

@Directive()
export class CommonCalculationPageComponent implements OnInit, OnDestroy {
  public appModuleType?: ModuleType;
  public tab?: CalculatorsTab;

  constructor(private store: Store) {}

  public ngOnInit(): void {
    if (this.appModuleType != null) {
      this.store.dispatch(uiActions.setAppModuleAction({ appModuleType: this.appModuleType }));
    }

    if (this.tab) {
      this.store.dispatch(uiActions.setCalculatorsTabAction({ tab: this.tab }));
    }
  }

  public ngOnDestroy(): void {
    this.store.dispatch(clearCalculatorsTabAction());
    this.store.dispatch(uiActions.setAppModuleAction({ appModuleType: ModuleType.Calculators_Generic }));
  }
}
