import { GeneralCalculations } from '../../../common/general.calculations';

export class UsMeshConverter {
  private static readonly globalTolerance = 0.0001;
  private static readonly maxLoopIterations = 1000;
  private static readonly USMeshConversionTable: number[][] = [
    [7, 0.0028],
    [8, 0.00236],
    [10, 0.002],
    [12, 0.0017],
    [14, 0.0014],
    [16, 0.00118],
    [18, 0.001],
    [20, 0.00085],
    [25, 0.00071],
    [30, 0.0006],
    [35, 0.0005],
    [40, 0.000425],
    [45, 0.000355],
    [50, 0.0003],
    [60, 0.00025],
    [70, 0.000212],
    [80, 0.00018],
    [100, 0.00015],
    [120, 0.000125],
    [140, 0.000106],
    [170, 0.00009],
    [200, 0.000075],
    [230, 0.000063],
    [270, 0.000053],
    [325, 0.000045],
    [400, 0.000038],
    [550, 0.000025],
    [625, 0.00002],
  ];

  public static USMeshToMeters(usMeshValue: number): number {
    const minMeshValue = this.USMeshConversionTable[0][0];
    const maxMeshValue = this.USMeshConversionTable[this.USMeshConversionTable.length - 1][0];

    if (usMeshValue <= 0) {
      return usMeshValue;
    }
    if (usMeshValue > maxMeshValue) {
      return this.USMeshConversionTable[this.USMeshConversionTable.length - 1][1];
    }
    if (usMeshValue < minMeshValue) {
      return this.USMeshConversionTable[0][1];
    }

    for (let i = 0; i < this.USMeshConversionTable.length; i++) {
      if (Math.abs(this.USMeshConversionTable[i][0] - usMeshValue) < this.globalTolerance / 1000) {
        return this.USMeshConversionTable[i][1];
      }
    }

    //Define sixth order polynomial constants
    let a6: number;
    let a5: number;
    let a4: number;
    let a3: number;
    let a2: number;
    let a1: number;
    let a0: number;

    if (usMeshValue <= 20) {
      /* eslint-disable @typescript-eslint/no-loss-of-precision */
      a6 = 0.00000000990219535095807;
      a5 = -0.000000846670558969447;
      a4 = 0.0000296117169867683;
      a3 = -0.00054148609714737;
      a2 = 0.00545806726327774;
      a1 = -0.0289083951219542;
      a0 = 0.0654103674839504;
    } else if (usMeshValue <= 80) {
      a6 = 0.000000000000200999799420662;
      a5 = -0.0000000000597114849280753;
      a4 = 0.00000000708715228769461;
      a3 = -0.000000430907632044653;
      a2 = 0.0000144699343201446;
      a1 = -0.000277912773742798;
      a0 = 0.00311204638425842;
    } else if (usMeshValue <= 270) {
      a6 = -0.000000000000000047640162499344;
      a5 = 0.0000000000000507764635064149;
      a4 = -0.000000000021786674402594;
      a3 = 0.00000000477945578706958;
      a2 = -0.000000556711816727458;
      a1 = 0.0000311072060085619;
      a0 = -0.000454258740126025;
    } else {
      a6 = 0;
      a5 = 0;
      a4 = 0.00000000000000429186532474146;
      a3 = -0.00000000000795701325281328;
      a2 = 0.00000000546718174888874;
      a1 = -0.00000173556611012501;
      a0 = 0.000256854460094453;
    }
    /* eslint-enable @typescript-eslint/no-loss-of-precision */
    //Convert
    return (
      a6 * GeneralCalculations.Pow(usMeshValue, 6) +
      a5 * GeneralCalculations.Pow(usMeshValue, 5) +
      a4 * GeneralCalculations.Pow(usMeshValue, 4) +
      a3 * GeneralCalculations.Pow(usMeshValue, 3) +
      a2 * GeneralCalculations.Pow(usMeshValue, 2) +
      a1 * GeneralCalculations.Pow(usMeshValue, 1) +
      a0
    );
  }

  public static MetersToUSMesh(meterValue: number): number {
    const minMeterValue = this.USMeshConversionTable[this.USMeshConversionTable.length - 1][1];
    const maxMeterValue = this.USMeshConversionTable[0][1];

    if (meterValue <= 0) {
      return meterValue;
    }
    if (meterValue <= minMeterValue) {
      return this.USMeshConversionTable[this.USMeshConversionTable.length - 1][0];
    }
    if (meterValue >= maxMeterValue) {
      return this.USMeshConversionTable[0][0];
    }

    for (let i = 0; i < this.USMeshConversionTable.length; i++) {
      if (Math.abs(this.USMeshConversionTable[i][1] - meterValue) < this.globalTolerance / 1000) {
        return this.USMeshConversionTable[i][0];
      }
    }

    let lowerBound = 0;
    let upperBound = this.USMeshConversionTable[this.USMeshConversionTable.length - 1][0];

    let midMeterValue: number;
    let midMeshValue: number;
    let iterationCounter = 0;
    do {
      if (iterationCounter >= this.maxLoopIterations) {
        return 0;
      }
      //Increment the iteration variable
      iterationCounter++;

      //Calculate the new mid value
      midMeshValue = (lowerBound + upperBound) / 2.0;

      //Calculate the US mesh at the mid value
      midMeterValue = this.USMeshToMeters(midMeshValue);

      //Check in which direction the mid value should be moved
      if (meterValue > midMeterValue) {
        //Target is between lower and mid meshes, so reset upper to mid
        upperBound = midMeshValue;
      } else {
        //Target is between mid and upper meshes, so reset lower to mid
        lowerBound = midMeshValue;
      }
    } while (Math.abs(meterValue - midMeterValue) / meterValue > this.globalTolerance);
    return midMeshValue;
  }
}
