import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';
import { getIsUndoRedoHistoryEmpty } from '../../+store/undo-redo/undo-redo.selectors';
import { ModalService } from '../../common-modules/modals/modal.service';
import { undoRedoClearHistoryAction } from '../../+store/undo-redo/undo-redo.action';

@Injectable()
export class HomePageUndoRedoGuard {
  constructor(
    private router: Router,
    private store: Store,
    private modalService: ModalService,
  ) {}

  public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const isUndoRedoHistoryEmpty = await firstValueFrom(this.store.select(getIsUndoRedoHistoryEmpty));

    if (isUndoRedoHistoryEmpty) {
      return true;
    }

    const canGoToHome = await this.modalService.showConfirm(
      'File will be closed so you will no longer be able to undo/redo changes made before this point. Do you want to continue?',
      '',
    );

    if (canGoToHome) {
      this.store.dispatch(undoRedoClearHistoryAction());
    }

    return canGoToHome;
  }
}
