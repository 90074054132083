import { Routes } from '@angular/router';
import { CalculationsPageComponent } from './calculations-page.component';
import { MASPRoutes } from './masp/masp.routes';
import { ResuspensionRoutes } from './resuspension/resuspension.routes';
import { FrictionRoutes, RouteModuleFriction } from './friction/friction.route';
import { SettlingRoutes } from './settling/settling.route';
import { InjectionTestRoutes } from './injection-test/injection-test.route';
import { LeakoffCoefficientRoutes } from './leakoff-coefficient/leakoff-coefficient.route';
import { fluidsRoutes } from '../common/fluids/fluids.routes';
import { gravelsRoutes } from '../common/gravel/gravels.routes';
import { LicenseFeature } from '@dunefront/common/modules/licensing/licensing.interfaces';
import { settingsRoutes } from '../common/settings/settings.routes';

export const RouteModuleCalculators = 'calculators';

export const calculationsPageRoutes: Routes = [
  {
    path: RouteModuleCalculators,
    component: CalculationsPageComponent,
    data: {
      breadcrumb: 'Calculators',
      licenseFeature: LicenseFeature.Calculators,
    },
    children: [
      ...fluidsRoutes,
      ...gravelsRoutes,
      ...MASPRoutes,
      ...ResuspensionRoutes,
      ...FrictionRoutes,
      ...SettlingRoutes,
      ...InjectionTestRoutes,
      ...LeakoffCoefficientRoutes,
      ...settingsRoutes,
      {
        path: '',
        redirectTo: RouteModuleFriction,
        pathMatch: 'full',
      },
    ],
  },
];
