import { createReducer, on } from '@ngrx/store';
import * as actions from './units.actions';
import { initialUnitSystemState, IUnitsModuleState } from './units.state';
import { UnitsReducerHelper } from './units.reducer.helper';
import { deleteRowsSuccess, insertRowsSuccess, updateRowSuccess } from '../app.actions';
import { loadCommonDbInitialSuccess } from '../common-db/common-db.actions';

export const UnitsModuleFeatureName = 'units';

export const unitsModuleReducer = createReducer<IUnitsModuleState>(
  initialUnitSystemState,
  on(loadCommonDbInitialSuccess, (state, response): IUnitsModuleState => ({ ...state, unitSystem: response.payload.unitSystem })),
  on(
    actions.chooseUnitSystemAction,
    (state, action): IUnitsModuleState => ({ ...state, unitSystem: { ...state.unitSystem, currentUnitSystemId: action.unitSystemId } }),
  ),
  on(
    insertRowsSuccess,
    updateRowSuccess,
    deleteRowsSuccess,
    (state, response): IUnitsModuleState => UnitsReducerHelper.onCrudSuccess(state, response),
  ),
);
