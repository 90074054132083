import { LoadCompletionActionResponse, SectionCalculationType } from '@dunefront/common/modules/completion/completion-module.actions';
import { createTableState, ITableState } from '@dunefront/common/common/common-grid.interfaces';
import {
  completionInitialState,
  CompletionModuleState,
  emptyVolumeSectionCalculatorResult,
} from '@dunefront/common/modules/completion/completion-module.state';

import { CrudResponse } from '@dunefront/common/modules/common.actions';
import { LowerCompletionPipeDto } from '@dunefront/common/dto/lower-completion-pipe.dto';
import { RunningStringPipeDto } from '@dunefront/common/dto/running-string-pipe.dto';
import {
  deleteObjectsFromArray,
  DictionaryWithArray,
  IDictionaryWithArray,
  updateObjectsInArray,
} from '@dunefront/common/common/state.helpers';
import { IcdPortDataDto } from '@dunefront/common/dto/icd-port-data.dto';
import { CompletionFactory } from '@dunefront/common/modules/completion/model/completion.factory';
import { ShuntTubeFactory } from '@dunefront/common/modules/shunt-tube/shunt-tube.factory';
import { LowerCompletionPipesFactory } from '@dunefront/common/modules/pipes/lower-completion-pipes/lower-completion-pipes.factory';
import { Pipe } from '@dunefront/common/modules/pipes/pipe';
import { IcdPortDataFactory } from '@dunefront/common/modules/completion/model/icd-port-data.factory';
import { RunningStringPipesFactory } from '@dunefront/common/modules/pipes/running-string-pipes/running-string-pipes.factory';
import { changeProp, ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';
import { ShuntTube } from '@dunefront/common/modules/shunt-tube/shunt-tube';
import { IUpdateTableRowsProps } from '@dunefront/common/common/common-store-crud.interfaces';
import { ActionResponse } from '@dunefront/common/response-ws.action';
import {
  createEmptyGaugeSectionInformationDto,
  createEmptySectionInformationDto,
} from '@dunefront/common/modules/completion/dto/section-information.dto';

export class CompletionModuleReducerHelper {
  public static onLoadScenarioDataSuccessAction(
    state: CompletionModuleState,
    response: ActionResponse<LoadCompletionActionResponse>,
    scenarioId: number,
  ): CompletionModuleState {
    if (response.status !== 'ok' || !response.payload) {
      return completionInitialState;
    }

    return CompletionFactory.create(response.payload, scenarioId);
  }

  public static updateCompletionSuccess(state: CompletionModuleState, response: CrudResponse): CompletionModuleState {
    if (!response.affectedRows.completion) {
      return state;
    }

    return {
      ...CompletionFactory.updateCompletionProps(state, response.affectedRows.completion),
    };
  }

  // region Shunt Tube

  public static onUpdateShuntTubeSuccess(state: CompletionModuleState, response: CrudResponse): CompletionModuleState {
    if (!response.affectedRows.shuntTube) {
      return state;
    }
    return { ...state, ShuntTube: ShuntTubeFactory.create(response.affectedRows.shuntTube) };
  }

  // endregion

  // region LowerCompletion Grid

  public static insertLowerCompletionRowSuccess(state: CompletionModuleState, response: CrudResponse): CompletionModuleState {
    if (!response.affectedRows.lowerCompletion) {
      return state;
    }
    let newCompletion: CompletionModuleState;
    if (response.affectedRows.lowerCompletion.replace === 'affectedOnly') {
      newCompletion = this.updateLowerCompletionRows(state, response.affectedRows.lowerCompletion.rows);
    } else {
      const rows = LowerCompletionPipesFactory.createRowListFromDtos(response.affectedRows.lowerCompletion.rows, response.scenarioId);
      newCompletion = { ...state, LowerCompletion: createTableState<Pipe>(rows) };
    }

    if (response.affectedRows.icdPortData) {
      const newIcdPortData = this.updateIcdPortDataRows(state.IcdPortData, response.affectedRows.icdPortData.rows);
      newCompletion = { ...newCompletion, IcdPortData: newIcdPortData };
    }

    return newCompletion;
  }

  public static deleteLowerCompletionRowsSuccess(state: CompletionModuleState, response: CrudResponse): CompletionModuleState {
    if (!response.affectedRows.lowerCompletion) {
      return state;
    }

    const rows: ITableState<Pipe> = {
      ...state.LowerCompletion,
      rows: deleteObjectsFromArray(state.LowerCompletion.rows, response.affectedRows.lowerCompletion.deletedIds),
    };

    return this.updateLowerCompletionRows(
      {
        ...state,
        LowerCompletion: rows,
      },
      response.affectedRows.lowerCompletion.rows,
    );
  }

  public static updateLowerCompletionRowSuccess(state: CompletionModuleState, response: CrudResponse): CompletionModuleState {
    if (!response.affectedRows.lowerCompletion) {
      return state;
    }
    return this.updateLowerCompletionRows(state, response.affectedRows.lowerCompletion.rows);
  }

  private static updateLowerCompletionRows(
    completion: CompletionModuleState,
    affectedRows: LowerCompletionPipeDto[],
  ): CompletionModuleState {
    const rows = updateObjectsInArray(
      completion.LowerCompletion.rows,
      affectedRows.map((rowDto) => LowerCompletionPipesFactory.createFromDto(rowDto)),
    );
    return { ...completion, LowerCompletion: { ...completion.LowerCompletion, rows } };
  }

  // endregion

  // region IcdPortData Grid

  public static insertIcdPortDataRowSuccess(state: CompletionModuleState, response: CrudResponse): CompletionModuleState {
    if (!response.affectedRows.icdPortData || response.affectedRows.lowerCompletion) {
      return state;
    }
    const lowerCompletionId = response.affectedRows.icdPortData.parentId as number;
    const icdPortDataTableState = DictionaryWithArray.get(state.IcdPortData, lowerCompletionId);
    if (!icdPortDataTableState) {
      console.error('insertIcdPortDataRowSuccess - table state not found');
      return state;
    }

    const rows = IcdPortDataFactory.createRowListFromDtos(response.affectedRows.icdPortData.rows, response.scenarioId, lowerCompletionId);

    const newTableState: ITableState<IcdPortDataDto> = {
      ...icdPortDataTableState,
      rows,
    };

    const icdPortData = DictionaryWithArray.upsertById(state.IcdPortData, newTableState, lowerCompletionId);

    return { ...state, IcdPortData: icdPortData };
  }

  public static deleteIcdPortDataRowsSuccess(state: CompletionModuleState, response: CrudResponse): CompletionModuleState {
    if (!response.affectedRows.icdPortData) {
      return state;
    }

    const permanentCompletionId = response.affectedRows.icdPortData.parentId as number;
    const deletedIds = response.affectedRows.icdPortData.deletedIds;

    return {
      ...state,
      IcdPortData: this.deleteIcdPortDataRows(state.IcdPortData, permanentCompletionId, deletedIds),
    };
  }

  public static updateIcdPortDataRowSuccess(state: CompletionModuleState, response: CrudResponse): CompletionModuleState {
    if (!response.affectedRows.icdPortData) {
      return state;
    }

    const permanentCompletionId = response.affectedRows.icdPortData.parentId as number;
    const deletedIds = response.affectedRows.icdPortData.deletedIds;
    let icdPortData = this.deleteIcdPortDataRows(state.IcdPortData, permanentCompletionId, deletedIds);
    icdPortData = this.updateIcdPortDataRows(icdPortData, response.affectedRows.icdPortData.rows);

    return { ...state, IcdPortData: icdPortData };
  }

  private static deleteIcdPortDataRows(
    icdPortData: IDictionaryWithArray<ITableState<IcdPortDataDto>>,
    permanentCompletionId: number,
    deletedIds: (number | string)[],
  ): IDictionaryWithArray<ITableState<IcdPortDataDto>> {
    const tableState = DictionaryWithArray.get(icdPortData, permanentCompletionId);

    if (!tableState) {
      return icdPortData;
    }
    const rowsAfterDelete = deleteObjectsFromArray(tableState.rows, deletedIds);
    const newTableState: ITableState<IcdPortDataDto> = {
      ...tableState,
      rows: rowsAfterDelete,
    };
    if (newTableState.rows.find((row) => row.rowType !== 'insert-row')) {
      return DictionaryWithArray.upsertById(icdPortData, newTableState, permanentCompletionId);
    }
    return DictionaryWithArray.deleteItem(icdPortData, permanentCompletionId);
  }

  private static updateIcdPortDataRows(
    icdPortData: IDictionaryWithArray<ITableState<IcdPortDataDto>>,
    affectedRows: IcdPortDataDto[],
  ): IDictionaryWithArray<ITableState<IcdPortDataDto>> {
    if (!affectedRows.length) {
      return icdPortData;
    }

    // assume that all the updated rows have the permanentCompletionId
    const permanentCompletionId = affectedRows[0].LowerCompletionId;
    let newTableState!: ITableState<IcdPortDataDto>;
    const icdPortDataTableState = DictionaryWithArray.get(icdPortData, permanentCompletionId);
    if (icdPortDataTableState) {
      newTableState = {
        ...icdPortDataTableState,
        rows: updateObjectsInArray(icdPortDataTableState.rows, affectedRows),
      };
    } else {
      newTableState = IcdPortDataFactory.createTableStateForCompletionId(
        affectedRows,
        affectedRows[0].ScenarioId,
        affectedRows[0].LowerCompletionId,
      );
    }

    if (
      !newTableState.rows.find((row) => row.rowType === 'insert-row' && row.rowData.LowerCompletionId === affectedRows[0].LowerCompletionId)
    ) {
      newTableState.rows.push(
        IcdPortDataFactory.createEmptyRow(affectedRows[0].ScenarioId, affectedRows[0].LowerCompletionId, -1, false, 'insert-row'),
      );
    }

    return DictionaryWithArray.upsertById(icdPortData, newTableState, permanentCompletionId);
  }

  // endregion

  // region RunningString Grid

  public static updateRunningStringRow(state: CompletionModuleState, props: IUpdateTableRowsProps<Pipe>): CompletionModuleState {
    if (!props.colIds?.includes('PipeType')) {
      return state;
    }
    const dtos = props.rows.map((row) => ({
      ...RunningStringPipesFactory.toDto(row.rowData),
      PipeType: row.rowData.PipeType,
    }));

    return this.updateRunningStringRows(state, dtos);
  }

  public static insertRunningStringRowSuccess(state: CompletionModuleState, response: CrudResponse): CompletionModuleState {
    if (!response.affectedRows.runningString) {
      return state;
    }

    const rows = RunningStringPipesFactory.createRowListFromDtos(response.affectedRows.runningString.rows, response.scenarioId);
    return { ...state, RunningString: createTableState<Pipe>(rows) };
  }

  public static deleteRunningStringRowsSuccess(state: CompletionModuleState, response: CrudResponse): CompletionModuleState {
    if (!response.affectedRows.runningString) {
      return state;
    }
    const rows: ITableState<Pipe> = {
      ...state.LowerCompletion,
      rows: deleteObjectsFromArray(state.RunningString.rows, response.affectedRows.runningString.deletedIds),
    };

    return this.updateRunningStringRows(
      {
        ...state,
        RunningString: rows,
      },
      response.affectedRows.runningString.rows,
    );
  }

  public static updateRunningStringRowSuccess(state: CompletionModuleState, response: CrudResponse): CompletionModuleState {
    const runningString = response.affectedRows.runningString;
    if (!runningString) {
      return state;
    }

    if (runningString.replace === 'all') {
      return {
        ...state,
        RunningString: createTableState(RunningStringPipesFactory.createRowListFromDtos(runningString.rows, response.scenarioId)),
      };
    } else {
      return this.updateRunningStringRows(state, runningString.rows);
    }
  }

  private static updateRunningStringRows(completion: CompletionModuleState, affectedRows: RunningStringPipeDto[]): CompletionModuleState {
    const rows = updateObjectsInArray(
      completion.RunningString.rows,
      affectedRows.map((rowDto) => RunningStringPipesFactory.createFromRunningString(rowDto)),
    );
    return { ...completion, RunningString: { ...completion.RunningString, rows } };
  }

  // endregion

  public static onCalculateVolumesSectionAction(
    state: CompletionModuleState,
    action: { calculationType: SectionCalculationType },
  ): CompletionModuleState {
    return {
      ...state,
      VolumeSectionCalculatorResult:
        action.calculationType === 'single'
          ? { ...state.VolumeSectionCalculatorResult, isLoaded: false }
          : state.VolumeSectionCalculatorResult,
      VolumeGaugeSectionInformationResult:
        action.calculationType === 'multiple'
          ? { ...state.VolumeGaugeSectionInformationResult, isLoaded: false }
          : state.VolumeGaugeSectionInformationResult,
    };
  }

  public static onCalculateVolumesSectionAbortedAction(
    state: CompletionModuleState,
    action: { calculationType: SectionCalculationType },
  ): CompletionModuleState {
    return {
      ...state,
      VolumeSectionCalculatorResult:
        action.calculationType === 'single'
          ? { ...createEmptySectionInformationDto(), isLoaded: true }
          : state.VolumeSectionCalculatorResult,
      VolumeGaugeSectionInformationResult:
        action.calculationType === 'multiple'
          ? { ...createEmptyGaugeSectionInformationDto(), isLoaded: true }
          : state.VolumeGaugeSectionInformationResult,
    };
  }

  public static onVolumeSectionCalculatorResultSuccess(state: CompletionModuleState, response: CrudResponse): CompletionModuleState {
    if (!response.affectedRows.volumeSectionCalculatorResult) {
      return state;
    }

    return {
      ...state,
      VolumeSectionCalculatorResult: { ...response.affectedRows.volumeSectionCalculatorResult, isLoaded: true },
    };
  }

  public static onVolumeGaugeSectionInformationResultSuccess(state: CompletionModuleState, response: CrudResponse): CompletionModuleState {
    if (!response.affectedRows.volumeGaugeSectionInformationResult) {
      return state;
    }

    return {
      ...state,
      VolumeGaugeSectionInformationResult: {
        rows: response.affectedRows.volumeGaugeSectionInformationResult.rows.map((row, Id) => ({
          ...row,
          Id,
        })),
        isLoaded: true,
      },
    };
  }

  public static changeCompletionProperty(
    state: CompletionModuleState,
    changedProp: ObjectChangeProp<CompletionModuleState>,
  ): CompletionModuleState {
    return { ...changeProp(state, changedProp), VolumeSectionCalculatorResult: emptyVolumeSectionCalculatorResult() };
  }

  public static changeShuntTubeProperty(state: CompletionModuleState, changedProp: ObjectChangeProp<ShuntTube>): CompletionModuleState {
    const newShuntTube = changeProp(state.ShuntTube, changedProp);
    return this.changeCompletionProperty(state, {
      key: 'ShuntTube',
      shouldResetResults: changedProp.shouldResetResults,
      value: newShuntTube,
    });
  }
}
