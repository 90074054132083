import { ColumnType, IGridColumnConfig } from '../../../../../shared/components/grid/grid.interfaces';
import { PSDResult } from '@dunefront/common/modules/psd-analysis/model/psd/psd';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';

export const gravelPackGridConfig: IGridColumnConfig<PSDResult>[] = [
  { disabled: true, visible: true, colId: ' ', type: ColumnType.selection },
  {
    disabled: true,
    visible: true,
    colId: 'Description',
    headerText: 'PSD Description',
    width: 100,
    type: ColumnType.text,
  },
  {
    disabled: true,
    visible: true,
    colId: 'Depth',
    unitSystem: UnitSystem.Long_Length,
    headerText: 'PSD Depth',
    width: 100,
    type: ColumnType.number,
  },
  {
    disabled: true,
    visible: true,
    colId: 'SaucierCriteria',
    unitSystem: UnitSystem.Screen_Opening,
    headerText: 'Saucier Gravel D50\n(5-6 x d50)',
    width: 150,
    type: ColumnType.text,
  },
  {
    disabled: true,
    visible: true,
    colId: 'GravelSize',
    unitSystem: UnitSystem.None,
    headerText: 'Gravel size',
    width: 100,
    type: ColumnType.text,
  },
  {
    disabled: true,
    visible: true,
    colId: 'ScreenOpeningGridValue',
    unitSystem: UnitSystem.Screen_Opening,
    headerText: 'Screen Opening\n(0.5-0.75 x D100)',
    width: 150,
    type: ColumnType.text,
  },
];
