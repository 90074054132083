import { DocumentGeneratorHelper } from '../document-generator.helper';
import { HeadingLevel, Paragraph, Table } from 'docx';
import { IUnitSystemDto } from '@dunefront/common/dto/unit-system.dto';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';
import { DocumentTableGeneratorHelper } from '../document-table-generator.helper';
import { getPSDAnalysis } from '../../../../../../+store/psd-analysis/psd-analysis.selectors';
import { ReportScenarioImages } from './chart-report-generator.helper';
import { PsdSandProductionInputs } from '@dunefront/common/modules/psd-analysis/dto/psd-sand-production.inputs';
import { CommonReportDataHelpers } from '../../common/common-report-data-helpers';
import { DocxImageGenerator } from '../docx-image-generator';

export class PsdAnalysisReportGeneratorHelper {
  public static async createPsdAnalysisDataSection(
    store: Store,
    currentUnitSystem: IUnitSystemDto,
    images: ReportScenarioImages,
    docxImageGenerator: DocxImageGenerator,
  ): Promise<Array<Paragraph | Table>> {
    const psdAnalysisState = await firstValueFrom(store.select(getPSDAnalysis));
    const chartData = images.psdAnalysisChart
      ? await DocumentGeneratorHelper.drawGenericImage(images.psdAnalysisChart, docxImageGenerator)
      : [];

    return [
      DocumentGeneratorHelper.createParagraphHeader(`PSD Analysis`, HeadingLevel.HEADING_1, 0),

      DocumentGeneratorHelper.createParagraphHeader(`Consolidated PSD Chart`, HeadingLevel.HEADING_2, 1),

      ...chartData,
      ...(psdAnalysisState.IsCalculateSandProduction
        ? [
            DocumentGeneratorHelper.createParagraphHeader(`Calculate Sand Production through Wire-Wrap Screens`, HeadingLevel.HEADING_2, 1),
            this.createPsdSandProduction(psdAnalysisState, currentUnitSystem),
          ]
        : []),
    ];
  }

  private static createPsdSandProduction(psdAnalysis: PsdSandProductionInputs, currentUnitSystem: IUnitSystemDto): Table {
    const tableData = CommonReportDataHelpers.createPsdSandProductionTableData(psdAnalysis, currentUnitSystem);

    return DocumentTableGeneratorHelper.createGenericKeyValueTableFromTableData(tableData, currentUnitSystem);
  }
}
