import { createTableRow, GridRowType, ITableRow } from '../../../../common/common-grid.interfaces';
import { InjectionTestReading } from './injection-test-reading';
import { InjectionTestReadingDto } from '../injection-test-calculator.dto';

export class InjectionTestReadingFactory {
  public static createRowListFromDtos(
    injectionTestReadingsDtoArray: InjectionTestReadingDto[],
    scenarioId: number,
  ): ITableRow<InjectionTestReading>[] {
    const rows = injectionTestReadingsDtoArray.map((dto, index) => this.createElement(dto, index));
    const insertRow = this.createEmptyRow(scenarioId, rows.length, false, 'insert-row');
    return [...rows, insertRow];
  }

  public static createRowFromPaste(Rate: number, Pressure: number, ScenarioId: number): ITableRow<InjectionTestReading> {
    return this.createElement(
      {
        SortOrder: 0,
        Id: -1,
        Rate,
        Pressure,
        ScenarioId,
      },
      0,
    );
  }

  public static createElement(
    injectionTestReadingsDto: InjectionTestReadingDto,
    rowIndex: number,
    isEditingDisabled = false,
    rowType: GridRowType = 'data',
  ): ITableRow<InjectionTestReading> {
    if (rowIndex === 0) {
      rowType = 'first-row';
      isEditingDisabled = false;
    }
    return createTableRow<InjectionTestReading>(
      this.createInjectionTestReadings(injectionTestReadingsDto),
      rowType,
      rowIndex,
      isEditingDisabled,
    );
  }

  public static createEmpty(scenarioId: number): InjectionTestReading {
    return {
      ScenarioId: scenarioId,
      Id: -1,
      Rate: 0,
      Pressure: 0,
      SortOrder: -1,
      error: {},
      isValid: true,
    };
  }

  public static createEmptyRow(
    scenarioId: number,
    rowIndex: number,
    isEditingDisabled: boolean,
    rowType: GridRowType,
  ): ITableRow<InjectionTestReading> {
    const rowData: InjectionTestReading = this.createInjectionTestReadings(this.createEmpty(scenarioId));
    return createTableRow(rowData, rowType, rowIndex, isEditingDisabled);
  }

  public static toDto(injectionTestReadings: InjectionTestReading): InjectionTestReadingDto {
    const { error, isValid, ...dto } = injectionTestReadings;
    return dto;
  }

  public static createInjectionTestReadings(injectionTestReadingsDto: InjectionTestReadingDto): InjectionTestReading {
    return {
      ...injectionTestReadingsDto,
      error: {},
      isValid: true,
    };
  }
}
