import { CommonDbType } from './common-dto.interfaces';

export enum CompressibilityUnit {
  Reciprocal_pascal = 1,
  Reciprocal_kilo_pascal = 2,
  Reciprocal_pound_per_square_inch = 3,
  Square_centimeter_perkilogram = 4,
  Reciprocal_bar = 5,
}

export enum ConsistencyIndexUnit {
  Metric_consistency_index = 1,
  Oilfield_consistency_index = 2,
}

export enum DensityUnit {
  Kilogram_per_cubic_meter = 1,
  Gram_per_cubic_centimeter = 2,
  Kilogram_per_liter = 3,
  Gram_per_liter = 4,
  Pound_per_cubic_foot = 5,
  Pound_per_US_gallon = 6,
  Specific_Gravity = 7,
}

export enum FluidLossCoefficientUnit {
  Meter_per_root_second = 1,
  Meter_per_root_minute = 2,
  Foot_per_root_second = 3,
  Foot_per_root_minute = 4,
}

export enum SolidConcentrationUnit {
  Kilogram_per_cubic_meter = 1,
  Pound_per_US_gallon = 2,
  Pound_per_1000_US_gallon = 3,
  Pound_per_barrel = 4,
}

export enum LiquidConcentrationUnit {
  Cubic_meter_per_cubic_meter = 1,
  Liter_per_1000_liter = 2,
  US_gallon_per_1000_US_gallon = 3,
}

export enum FluidConcentrationUnit {
  Percent = 1,
}

export enum LengthUnit {
  Meter = 1,
  Kilometer = 2,
  Centimeter = 3,
  Millimeter = 4,
  Mile = 5,
  Yard = 6,
  Foot = 7,
  Inch = 8,
  Micron = 9,
  Gauge = 10,
  US_Mesh = 11,
}

export enum LinearMassUnit {
  KilogramPerMeter = 1,
  PoundPerFoot = 2,
  KilogramPerFoot = 3,
  PoundPerMeter = 4,
}

export enum LinearVolumeUnit {
  Cubic_meter_per_meter = 1,
  Cubic_meter_per_foot = 2,
  Cubic_foot_per_foot = 3,
  Cubic_foot_per_meter = 4,
}

export enum MassUnit {
  Kilogram = 1,
  Gram = 2,
  Milligram = 3,
  Pound = 4,
  Ounce = 5,
  ThousandPound = 6,
  Hundred_Pound = 7,
  Ton_US = 8,
  Ton_UK = 9,
  Tonne = 10,
}

export enum MassFlowRateUnit {
  Kilogram_per_second = 1,
  Kilogram_per_minute = 2,
  Pound_per_second = 3,
  Pound_per_minute = 4,
}

export enum NoneUnit {
  None = 0,
}

export enum PermeabilityUnit {
  Square_meter = 1,
  Darcy = 2,
  Millidarcy = 3,
}

export enum PlaneAngleUnit {
  Degree = 1,
  Radian = 2,
}

export enum PressureUnit {
  Pascal = 1,
  Kilo_Pascal = 2,
  Pound_per_square_inch = 3,
  Kilogram_per_square_centimeter = 4,
  Bar = 5,
  Mega_Pascal = 6,
  Pound_per_hundred_square_feet = 7,
  Dyne_per_square_centimeter = 8,
}

export enum PressureGradientUnit {
  Pascal_per_meter = 1,
  Kilo_Pascal_Per_Meter = 2,
  Pound_per_square_inch_per_foot = 3,
  Pound_per_square_inch_per_meter = 4,
  Pascal_per_foot = 5,
  Kilo_Pascal_per_foot = 6,
  Kilogram_per_square_centimeter_per_foot = 7,
  Kilogram_per_square_centimeter_per_meter = 8,
  Bar_per_foot = 9,
  Bar_per_meter = 10,
  Mega_Pascal_per_foot = 11,
  Mega_Pascal_per_meter = 12,
}

export enum PressureRateDerivativeUnit { //used for Pressure per rate, Pressure per rate squared and Pressure per rate cubed
  Pascal_per_cubic_meter_per_second = 1,
  Pascal_per_cubic_meter_per_minute = 2,
  Pascal_per_cubic_foot_per_minute = 3,
  Pascal_per_cubic_foot_per_second = 4,
  Pascal_per_barrel_per_minute = 5,
  Pascal_per_liter_per_minute = 6,
  Pascal_per_us_gallon_per_minute = 7,
  Kilo_Pascal_per_cubic_meter_per_second = 8,
  Kilo_Pascal_per_cubic_meter_per_minute = 9,
  Kilo_Pascal_per_cubic_foot_per_minute = 10,
  Kilo_Pascal_per_cubic_foot_per_second = 11,
  Kilo_Pascal_per_barrel_per_minute = 12,
  Kilo_Pascal_per_liter_per_minute = 13,
  Kilo_Pascal_per_us_gallon_per_minute = 14,
  Pound_per_square_inch_per_cubic_meter_per_second = 15,
  Pound_per_square_inch_per_cubic_meter_per_minute = 16,
  Pound_per_square_inch_per_cubic_foot_per_minute = 17,
  Pound_per_square_inch_per_cubic_foot_per_second = 18,
  Pound_per_square_inch_per_barrel_per_minute = 19,
  Pound_per_square_inch_per_liter_per_minute = 20,
  Pound_per_square_inch_per_us_gallon_per_minute = 21,
  Kilogram_per_square_centimeter_per_cubic_meter_per_second = 22,
  Kilogram_per_square_centimeter_per_cubic_meter_per_minute = 23,
  Kilogram_per_square_centimeter_per_cubic_foot_per_minute = 24,
  Kilogram_per_square_centimeter_per_cubic_foot_per_second = 25,
  Kilogram_per_square_centimeter_per_barrel_per_minute = 26,
  Kilogram_per_square_centimeter_per_liter_per_minute = 27,
  Kilogram_per_square_centimeter_per_us_gallon_per_minute = 28,
  Bar_per_cubic_meter_per_second = 29,
  Bar_per_cubic_meter_per_minute = 30,
  Bar_per_cubic_foot_per_minute = 31,
  Bar_per_cubic_foot_per_second = 32,
  Bar_per_barrel_per_minute = 33,
  Bar_per_liter_per_minute = 34,
  Bar_per_us_gallon_per_minute = 35,

  MPa_per_cubic_meter_per_second = 36,
  MPa_per_cubic_meter_per_minute = 37,
  MPa_per_cubic_foot_per_minute = 38,
  MPa_per_cubic_foot_per_second = 39,
  MPa_per_barrel_per_minute = 40,
  MPa_per_liter_per_minute = 41,
  MPa_per_us_gallon_per_minute = 42,
}

export enum PressureTimeDerivativeUnit {
  Pascal_per_second = 1,
  Pascal_per_hour = 2,
  Pascal_per_minute = 3,
  Kilo_Pascal_per_second = 4,
  Kilo_Pascal_per_hour = 5,
  Kilo_Pascal_per_minute = 6,
  Pound_per_square_inch_per_second = 7,
  Pound_per_square_inch_per_hour = 8,
  Pound_per_square_inch_per_minute = 9,
  Kilogram_per_square_centimeter_per_second = 10,
  Kilogram_per_square_centimeter_per_hour = 11,
  Kilogram_per_square_centimeter_per_minute = 12,
  Bar_per_second = 13,
  Bar_per_hour = 14,
  Bar_per_minute = 15,
  Pascal_per_day = 16,
  Kilo_Pascal_per_day = 17,
  Pound_per_square_inch_per_day = 18,
  Kilogram_per_square_centimeter_per_day = 19,
  Bar_per_day = 20,
  MPa_per_second = 21,
  MPa_per_hour = 22,
  MPa_per_minute = 23,
  MPa_per_day = 24,
}

export enum PressureVolumeDerivativeUnit {
  Pascal_per_cubic_meter = 1,
  Pascal_per_cubic_foot = 2,
  Pascal_per_barrel = 3,
  Pascal_per_litre = 4,
  Pascal_per_US_gallon = 5,
  Kilo_Pascal_per_cubic_meter = 6,
  Kilo_Pascal_per_cubic_foot = 7,
  Kilo_Pascal_per_barrel = 8,
  Kilo_Pascal_per_litre = 9,
  Kilo_Pascal_per_US_gallon = 10,
  Pound_per_square_inch_per_cubic_meter = 11,
  Pound_per_square_inch_per_cubic_foot = 12,
  Pound_per_square_inch_per_barrel = 13,
  Pound_per_square_inch_per_litre = 14,
  Pound_per_square_inch_per_US_gallon = 15,
  Kilogram_per_square_centimeter_per_cubic_meter = 16,
  Kilogram_per_square_centimeter_per_cubic_foot = 17,
  Kilogram_per_square_centimeter_per_barrel = 18,
  Kilogram_per_square_centimeter_per_litre = 19,
  Kilogram_per_square_centimeter_per_US_gallon = 20,
  Bar_per_cubic_meter = 21,
  Bar_per_cubic_foot = 22,
  Bar_per_barrel = 23,
  Bar_per_litre = 24,
  Bar_per_US_gallon = 25,
  MPa_per_cubic_meter = 26,
  MPa_per_cubic_foot = 27,
  MPa_per_barrel = 28,
  MPa_per_litre = 29,
  MPa_per_US_gallon = 30,
}

export enum RateUnit {
  Cubic_meter_per_second = 1,
  Cubic_meter_per_minute = 2,
  Cubic_foot_per_minute = 3,
  Cubic_foot_per_second = 4,
  Barrel_per_minute = 5,
  Liter_per_minute = 6,
  US_gallon_per_minute = 7,
}

export enum ReciprocalLengthUnit {
  Reciprocal_Meter = 1,
  Reciprocal_Kilometer = 2,
  Reciprocal_Centimeter = 3,
  Reciprocal_Millimeter = 4,
  Reciprocal_Mile = 5,
  Reciprocal_Yard = 6,
  Reciprocal_Foot = 7,
  Reciprocal_Inch = 8,
  Reciprocal_Micron = 9,
  Reciprocal_Gauge = 10,
}

export enum ShotDensityUnit {
  Shot_per_meter = 1,
  Shot_per_foot = 2,
}

export enum SpecificHeatCapacityUnit {
  Metric_specific_heat = 1,
  Oilfield_specific_heat = 2,
}

export enum TemperatureUnit {
  Celsius = 1,
  Kelvin = 2,
  Fahrenheit = 3,
}

export enum TemperatureTimeDerivativeUnit {
  Celsius_per_second = 1,
  Celsius_per_hour = 2,
  Celsius_per_minute = 3,
  Kelvin_per_second = 4,
  Kelvin_per_hour = 5,
  Kelvin_per_minute = 6,
  Fahrenheit_per_second = 7,
  Fahrenheit_per_hour = 8,
  Fahrenheit_per_minute = 9,
}

export enum TemperatureVolumeDerivativeUnit {
  Celsius_per_cubic_meter = 1,
  Celsius_per_cubic_foot = 2,
  Celsius_per_barrel = 3,
  Celsius_per_liter = 4,
  Celsius_per_US_gallon = 5,
  Kelvin_per_cubic_meter = 6,
  Kelvin_per_cubic_foot = 7,
  Kelvin_per_barrel = 8,
  Kelvin_per_liter = 9,
  Kelvin_per_US_gallon = 10,
  Fahrenheit_per_cubic_meter = 11,
  Fahrenheit_per_cubic_foot = 12,
  Fahrenheit_per_barrel = 13,
  Fahrenheit_per_liter = 14,
  Fahrenheit_per_US_gallon = 15,
}

export enum ThermalConductivityUnit {
  MetricThermalConductivity = 1,
  OilfieldThermalConductivity = 2,
}

export enum TimeUnit {
  Second = 1,
  Hour = 2,
  Minute = 3,
}

export enum VelocityUnit {
  Meter_per_second = 1,
  Meter_per_minute = 2,
  Foot_per_minute = 3,
  Foot_per_second = 4,
}

export enum ViscosityUnit {
  Pascal_second = 1,
  Centipoise = 2,
  Poise = 3,
}

export enum VolumeUnit {
  Cubic_meter = 1,
  Liter = 2,
  US_gallon = 3,
  Barrel = 4,
  Cubic_foot = 5,
  Cubic_centimeter = 6,
  Cubic_inch = 7,
}

export enum ForceUnit {
  Newton = 1,
  Kilo_Newton = 2,
  Pound_Force = 3,
  Hundred_Pound_Force = 4,
  Thousand_Pound_Force = 5,
  Gram_Force = 6,
  Kilogram_Force = 7,
}

export enum InjectivityUnit {
  Cubic_Meter_Per_Second_Per_Pascal = 1,
  Cubic_Meter_Per_Day_Per_Kilo_Pascal = 2,
  Barrel_Per_Day_Per_Pound_Per_Square_Inch = 3,
  US_Gallon_Per_Day_Per_Pound_Per_Square_Inch = 4,
  Litre_Per_Day_Per_Bar = 5,
}

export interface IUnitSystemDto {
  id: number; //need unique numeric id for selector control
  commonDbType: CommonDbType;
  sourceId: number; //need to keep source id to allow common db update
  name: string;
  Long_Length: LengthUnit;
  Angle: PlaneAngleUnit;
  Short_Length: LengthUnit;
  Temperature: TemperatureUnit;
  Solid_Concentration: SolidConcentrationUnit;
  Liquid_Volume: VolumeUnit;
  Linear_Mass: LinearMassUnit;
  Pressure: PressureUnit;
  Liquid_Density: DensityUnit;
  Pressure_Gradient: PressureGradientUnit;
  Rate: RateUnit;
  Mass: MassUnit;
  Shot_Density: ShotDensityUnit;
  Permeability: PermeabilityUnit;
  Viscosity: ViscosityUnit;
  Fluid_Loss_Coefficient: FluidLossCoefficientUnit;
  Diameter: LengthUnit;
  Reciprocal_Length: ReciprocalLengthUnit;
  Thermal_Conductivity: ThermalConductivityUnit;
  Specific_Heat_Capacity: SpecificHeatCapacityUnit;
  Time: TimeUnit;
  Consistency_Index: ConsistencyIndexUnit;
  Bulk_Density: DensityUnit;
  Solid_Volume: VolumeUnit;
  Linear_Volume: LinearVolumeUnit;
  Temperature_Time_Derivative: TemperatureTimeDerivativeUnit;
  Temperature_Volume_Derivative: TemperatureVolumeDerivativeUnit;
  Solid_Density: DensityUnit;
  Pressure_Time_Derivative: PressureTimeDerivativeUnit;
  Pressure_Volume_Derivative: PressureVolumeDerivativeUnit;
  Screen_Opening: LengthUnit;
  Small_Mass: MassUnit;
  Mass_Flow_Rate: MassFlowRateUnit;
  Compressibility: CompressibilityUnit;
  Velocity: VelocityUnit;
  Pressure_Rate_Derivative: PressureRateDerivativeUnit;
  Pressure_Rate_Squared_Derivative: PressureRateDerivativeUnit;
  Pressure_Rate_Cubed_Derivative: PressureRateDerivativeUnit;
  Sieve_Opening: LengthUnit;
  Force: ForceUnit;
  Injectivity: InjectivityUnit;
  Liquid_Concentration: LiquidConcentrationUnit;
  Yield_Stress: PressureUnit;
  Fluid_Concentration: FluidConcentrationUnit;
  Other: NoneUnit;
}

export enum UnitSystem {
  NotSelected = -1,
  Time = 0,
  Long_Length = 1,
  Short_Length = 2,
  Angle = 3,
  Diameter = 4,
  Liquid_Density = 5,
  Temperature = 6,
  Pressure = 7,
  Pressure_Gradient = 8,
  Consistency_Index = 9,
  Viscosity = 10,
  Bulk_Density = 11,
  Rate = 12,
  Liquid_Volume = 13,
  Solid_Volume = 14,
  Linear_Volume = 15,
  Solid_Concentration = 16,
  Mass = 17,
  Linear_Mass = 18,
  Permeability = 19,
  Shot_Density = 20,
  Temperature_Time_Derivative = 21,
  Temperature_Volume_Derivative = 22,
  None = 23,
  Velocity = 24,
  Solid_Density = 25,
  Pressure_Time_Derivative = 26,
  Pressure_Volume_Derivative = 27,
  Screen_Opening = 28,
  Small_Mass = 29,
  Thermal_Conductivity = 30,
  Specific_Heat_Capacity = 31,
  Fluid_Loss_Coefficient = 32,
  Mass_Flow_Rate = 33,
  Compressibility = 34,
  Reciprocal_Length = 35,
  Pressure_Rate_Derivative = 36,
  Pressure_Rate_Squared_Derivative = 37,
  Pressure_Rate_Cubed_Derivative = 38,
  Sieve_Opening = 39,
  Force = 40,
  Injectivity = 41,
  Liquid_Concentration = 42,
  Fluid_Concentration = 43,
  Yield_Stress = 44,
}
