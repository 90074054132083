<ng-container *ngrxLet="vm$ as vm">
  <div class="dialog-header">
    <h4>Select Module</h4>
  </div>
  <div class="module-selector-info">Only licensed modules are available for selection</div>
  <div class="dialog-content">
    <div class="module-selector-wrapper">
      <ng-template #moduleSelectorItem
                   let-feature="feature"
                   let-name="name"
                   let-icon="icon"
                   let-isAddon="isAddon"
                   let-description="description"

      >
        <div class="module-selector-item"
             *ngrxLet="getItemInfo(vm, feature) as info"
             [ngClass]="{'disabled' : info.isDisabled, 'selected' : isItemHighlighted(vm, feature, isAddon)}"
             [attr.data-cy]="'module-selector-item_' + feature"
             [pTooltip]="info.message"
             positionStyle="absolute"
             tooltipPosition="top"
        >
          <i
            [ngClass]="info.icon"
            class="status-icon"
          >
          </i>
          <a (click)="onClick($event, info.isDisabled, feature, vm.accessibleLicenseIds, isAddon)">
            <div [class.highlight]="isItemHighlighted(vm, feature, isAddon)" class="module-icon">
              <span class="{{ icon }}"></span>
            </div>
            <span>
          <p class="module-name">{{ name }}</p>
          <p class="module-desc">{{description}}</p>
        </span>
          </a>
        </div>
      </ng-template>


      <div class="module-selector-grid">

        <div class="module-group" *ngFor="let group of moduleSelectorConfig">
          <h4>{{group.name}}</h4>

          <div class="modules-row">
            <ng-container
              *ngFor="let item of group.items"
              [ngTemplateOutlet]="moduleSelectorItem"
              [ngTemplateOutletContext]="item"
            ></ng-container>
          </div>
        </div>
      </div>

    </div>
  </div>

  <div class="contact">
    Get in touch with us at <a target="_blank" href="mailto:sales@dunefront.com">sales&#64;dunefront.com</a> to upgrade your license or
    purchase add-ons
  </div>
  <div class="dialog-footer left-right">

    <div data-cy="module-selector-current-license-id">
      <ng-container *ngIf="vm.licensingLogin && vm.licensingLogin.loginInfos[0]?.licenseId; else notLoggedIn"
      >Using license: {{ vm.licensingLogin.loginInfos[0].licenseId }}</ng-container>
      <ng-template #notLoggedIn>
        <ng-container *ngIf="vm.accessibleFeatures.length === 0">No license found</ng-container>
        <ng-container *ngIf="vm.accessibleFeatures.length > 0 && vm.accessibleLicenseIds.length > 0">Compatible licenses: {{vm.accessibleLicenseIds.join(', ')}}</ng-container>
      </ng-template>

    </div>
    <button
      (buttonClick)="cancelClicked()"
      [dialogRef]="activeModal"
      [isUiLockable]="false"
      appButton
      buttonType="cancel"
      class="btn btn-primary btn-sm"
      data-cy="module-selector-cancel-btn"
      type="button"
    >
      Cancel
    </button>
  </div>
</ng-container>
