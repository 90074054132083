import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import * as wellActions from '../../../../+store/well/well.actions';
import { ValidatedWellModuleState } from '@dunefront/common/modules/well/well-module.state';
import { ITableRow } from '@dunefront/common/common/common-grid.interfaces';
import { cancelChange } from '../../../../+store/store.helpers';
import { ModalService } from '../../../../common-modules/modals/modal.service';
import { notEmpty } from '@dunefront/common/common/state.helpers';
import { PageWithGridComponent } from '../../../../shared/components/grid/page-with-grid.component';
import { ScreenService } from '../../../../shared/services';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import * as uiActions from '../../../../+store/ui/ui.actions';
import { Caliper } from '@dunefront/common/modules/well/model/caliper/caliper';
import { CaliperValidation } from '@dunefront/common/modules/well/model/caliper/caliper.validation';
import { ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';
import { IUpdateTableRowsProps } from '@dunefront/common/common/common-store-crud.interfaces';
import { getValidatedWellModuleState } from '../../../../+store/well/validated-well.selectors';
import { PanelHelpMode } from '../../../../shared/components/help-button/help-buton.component';

@Component({
  selector: 'app-caliper-data',
  templateUrl: './caliper-data.component.html',
  styleUrls: ['./caliper-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CaliperDataComponent extends PageWithGridComponent<Caliper> {
  public wellState!: ValidatedWellModuleState;

  public CaliperValidation = CaliperValidation;
  public UnitType = UnitSystem;
  public PanelHelpMode = PanelHelpMode;
  constructor(store: Store, cdRef: ChangeDetectorRef, modalService: ModalService, resizeService: ScreenService) {
    super(store, cdRef, modalService, resizeService);
    super.onHelpChange('well', 'caliper-data');
    store.dispatch(uiActions.setWellTabAction({ tab: 'caliper-data' }));
    this.subscription.add(
      notEmpty(store.select(getValidatedWellModuleState)).subscribe((state) => {
        this.wellState = state;
        this.cdRef.markForCheck();
      }),
    );
  }

  public async changeWellProperty(props: ObjectChangeProp<ValidatedWellModuleState>): Promise<void> {
    if (props.key === 'CaliperMultiplier') {
      const caliperMultiplierError = CaliperValidation.ValidateCaliperMultiplier(props.value as number);
      if (caliperMultiplierError) {
        await this.modalService.showAlert(caliperMultiplierError);
        const cancelGenerator = cancelChange(this.wellState, props, this.cdRef);
        this.wellState = cancelGenerator.next().value;
        this.wellState = cancelGenerator.next().value;
        this.cdRef.markForCheck();
        return;
      }
    }
    this.store.dispatch(wellActions.changeWellPropertyAction(props));
  }

  public getRows(): ITableRow<Caliper>[] {
    return this.wellState?.CaliperData.rows ?? [];
  }

  public updateRow(row: IUpdateTableRowsProps<Caliper>): void {
    this.gridComponent?.grid.gridConfig?.updateRowsAction(row);
  }

  public override onInsert(): void {
    this.gridComponent?.grid.onInsertClicked();
  }

  public override onDelete(): void {
    this.gridComponent?.grid.onDeleteClicked();
  }
}
