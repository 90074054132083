<div
  class="scenario-checkboxes-container"
  *ngIf="scenarios$ | async as scenarios"
  [attr.data-cy]="dataCy"
  cdkDropList
  cdkDropListOrientation="vertical"
  (cdkDropListDropped)="onDrop($event)"
>
  <ng-container *ngFor="let scenario of scenarios">
    <div
      *ngIf="isVisible(scenario)"
      cdkDrag
      [cdkDragLockAxis]="'y'"
      class="scenario"
      [class.draggable]="isReorderOn"
      [cdkDragDisabled]="!isReorderOn"
      cdkDragBoundary=".scenario-checkboxes-container"
    >
      <app-check-box
        [label]="scenario.Name"
        [isUiLockable]="false"
        [labelWidth]="labelWidth"
        [disabled]="isDisabled(scenario)"
        [error]="!!errorMessages.get(scenario.Id)"
        [overrideErrorMessage]="errorMessages.get(scenario.Id)"
        [value]="selectedIds.includes(scenario.Id)"
        (primitiveValueChanged)="valueChanged.emit({ id: scenario.Id, value: $event.value })"
      >
      </app-check-box>

      <span *ngIf="showResults && !hasResults(scenario)">(no results)</span>
      <i class="icon-arrow-up-down" *ngIf="isReorderOn"></i>
    </div>
  </ng-container>
</div>
