import { EventEmitter, Injectable, NgZone, Output } from '@angular/core';
import { BreakpointObserver, Breakpoints, MediaMatcher } from '@angular/cdk/layout';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Injectable()
export class ScreenService {
  @Output() public changed = new EventEmitter();
  public screenResized$ = new Subject<IScreenSize>();
  public devicePixelRationChanged = new Subject<number>();
  public currentWindowSize!: IScreenSize;
  public currentPixelRatio!: number;
  public focusedElementChanged$ = new Subject<EventTarget | null>();

  constructor(
    private breakpointObserver: BreakpointObserver,
    ngZone: NgZone,
    mediaMatcher: MediaMatcher,
  ) {
    this.breakpointObserver
      .observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium, Breakpoints.Large])
      .subscribe(() => this.changed.next(true));

    this.currentWindowSize = { width: window.innerWidth, height: window.innerHeight, resizeType: 'xy' };

    document.addEventListener('focusin', (e) => {
      this.focusedElementChanged$.next(e.target);
    });

    ngZone.runOutsideAngular(() => {
      fromEvent(window, 'resize')
        .pipe(debounceTime(500))
        .subscribe(() => {
          const width = window.innerWidth;
          const height = window.innerHeight;
          const newSize: IScreenSize = {
            width,
            height,
            resizeType: this.getScreenResizeType(width, height),
          };
          this.currentWindowSize = newSize;
          ngZone.run(() => this.screenResized$.next(newSize));
        });
    });

    const mqString = `(resolution: ${window.devicePixelRatio}dppx)`;
    mediaMatcher.matchMedia(mqString).addEventListener('change', () => this.onResolutionChange());
    this.onResolutionChange();

    this.screenResized$.next(this.currentWindowSize);
  }

  public get sizes(): IScreenSizes {
    return {
      'screen-x-small': this.breakpointObserver.isMatched(Breakpoints.XSmall),
      'screen-small': this.breakpointObserver.isMatched(Breakpoints.Small),
      'screen-medium': this.breakpointObserver.isMatched(Breakpoints.Medium),
      'screen-large': this.isLargeScreen(),
    };
  }

  private isLargeScreen(): boolean {
    const isLarge = this.breakpointObserver.isMatched(Breakpoints.Large);
    const isXLarge = this.breakpointObserver.isMatched(Breakpoints.XLarge);

    return isLarge || isXLarge;
  }

  private onResolutionChange(): void {
    this.currentPixelRatio = window.devicePixelRatio;
    this.devicePixelRationChanged.next(this.currentPixelRatio);
  }

  private getScreenResizeType(width: number, height: number): ScreenResizeType {
    if (this.currentWindowSize.width !== width && this.currentWindowSize.height !== height) {
      return 'xy';
    }
    if (this.currentWindowSize.width !== width) {
      return 'x';
    }
    return 'y';
  }
}

export interface IScreenSizes {
  'screen-x-small': boolean;
  'screen-large': boolean;
  'screen-small': boolean;
  'screen-medium': boolean;
}

export interface IScreenSize {
  width: number;
  height: number;
  resizeType: ScreenResizeType;
}

export type ScreenResizeType = 'x' | 'y' | 'xy';

export const getDeviceRatioCss = (ratio: number): string => {
  if (ratio >= 200) {
    return 'ratio_200';
  }
  if (ratio >= 1.5) {
    return 'ratio_150';
  }
  return 'ratio_100';
};
