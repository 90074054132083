import { DocumentGeneratorHelper } from '../document-generator.helper';
import { HeadingLevel, Paragraph, Table } from 'docx';
import { IUnitSystemDto } from '@dunefront/common/dto/unit-system.dto';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';
import { DocumentTableGeneratorHelper } from '../document-table-generator.helper';
import { getPSDsAsGrid } from '../../../../../../+store/psd-analysis/psd-analysis.selectors';
import { ITableState } from '@dunefront/common/common/common-grid.interfaces';
import { PSDResult } from '@dunefront/common/modules/psd-analysis/model/psd/psd';
import { ConvertUnitPipe } from '@dunefront/common/modules/units/convert-unit.pipe/convert-unit.pipe';
import { CommonReportDataHelpers } from '../../common/common-report-data-helpers';

export class PsdSummaryReportGeneratorHelper {
  public static async createPsdSummaryDataSection(
    store: Store,
    currentUnitSystem: IUnitSystemDto,
    convertUnitPipe: ConvertUnitPipe,
  ): Promise<Array<Paragraph | Table>> {
    const psdGrid = await firstValueFrom(store.select(getPSDsAsGrid));

    return [
      DocumentGeneratorHelper.createParagraphHeader(`PSD Summary`, HeadingLevel.HEADING_1, 0),
      DocumentGeneratorHelper.createParagraphHeader(`PSD Data Summary`, HeadingLevel.HEADING_2, 1),
      this.createPsdSummaryTable(psdGrid, currentUnitSystem, convertUnitPipe),
    ];
  }

  private static createPsdSummaryTable(
    psdGrid: ITableState<PSDResult>,
    currentUnitSystem: IUnitSystemDto,
    convertUnitPipe: ConvertUnitPipe,
  ): Table {
    const psdSummaryTableData = CommonReportDataHelpers.createPsdSummaryTableData(psdGrid, currentUnitSystem, convertUnitPipe);

    return DocumentTableGeneratorHelper.createTable(psdSummaryTableData, null, currentUnitSystem);
  }
}
