import { Injectable } from '@angular/core';
import { Base64Image } from '../../common-modules/chart/image-provider.helpers';
import { BehaviorSubject, Observable } from 'rxjs';
import { ChartContext } from '../../common-modules/chart/chart-component-helpers/chart-types';
import { Store } from '@ngrx/store';
import { updateVisibleDrawables } from '../../+store/reporting/reporting.actions';
import { VisibleDrawable } from '../../+store/reporting/reporting-module.state';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class DrawableRegistryService {
  private registeredProviders = new BehaviorSubject<DrawableContentProvider[]>([]);

  constructor(private store: Store) {}

  public registerProvider(newProvider: DrawableContentProvider): void {
    const currentProviders = this.registeredProviders.value;

    if (currentProviders.some((provider) => provider.id === newProvider.id)) {
      console.error(`Provider with the same id (${newProvider.id}) is already registered!`);
      return;
    }

    const newProviders = [...currentProviders, newProvider];
    this.updateVisibleDrawablesInStore(newProviders);
    this.registeredProviders.next(newProviders);
  }

  public unregisterProvider(id: string): void {
    const currentProviders = this.registeredProviders.value;
    const newProviders = currentProviders.filter((provider) => provider.id !== id);
    this.updateVisibleDrawablesInStore(newProviders);
    this.registeredProviders.next(newProviders);
  }

  private updateVisibleDrawablesInStore(newProviders: DrawableContentProvider[]): void {
    const currentlyVisibleDrawables: VisibleDrawable[] = newProviders.map((p) => ({
      id: p.id,
      name: p.getDisplayName(),
      chartId: p.getChartId != null ? p.getChartId() : undefined,
      isImageProvider: p.getBase64Image != null,
      isDataProvider: p.getDataContext != null,
      isVideoProvider: p.getCanvasForRecording != null,
    }));

    this.store.dispatch(updateVisibleDrawables({ currentlyVisibleDrawables }));
  }

  public get dataProviders$(): Observable<DrawableDataProvider[]> {
    return this.registeredProviders.pipe(
      map((providers) => {
        const res: DrawableDataProvider[] = [];
        for (const { id, getDisplayName, getDataContext } of providers) {
          if (getDataContext != null) {
            res.push({ id, getDisplayName, getDataContext });
          }
        }

        return res;
      }),
    );
  }

  public get videoProviders$(): Observable<DrawableVideoProvider[]> {
    return this.registeredProviders.pipe(
      map((providers) => {
        const res: DrawableVideoProvider[] = [];
        for (const { id, getDisplayName, getCanvasForRecording, getBase64Image } of providers) {
          if (getCanvasForRecording != null && getBase64Image != null) {
            res.push({ id, getDisplayName, getCanvasForRecording, getBase64Image });
          }
        }

        return res;
      }),
    );
  }

  public get imageProviders$(): Observable<DrawableImageProvider[]> {
    return this.registeredProviders.pipe(
      map((providers) => {
        const res: DrawableImageProvider[] = [];
        for (const { id, getDisplayName, getBase64Image } of providers) {
          if (getBase64Image != null) {
            res.push({ id, getDisplayName, getBase64Image });
          }
        }

        return res;
      }),
    );
  }

  public get allProviders$(): Observable<DrawableContentProvider[]> {
    return this.registeredProviders.asObservable();
  }
}

export interface DrawableContentProviderComponent {
  drawableProviderId?: string;
}

export interface BaseContentProvider {
  id: string;
  getDisplayName: () => string;
}

export interface DrawableContentProvider extends BaseContentProvider {
  getChartId?: () => number;
  getBase64Image?: () => Promise<Base64Image | null>;
  getCanvasForRecording?: () => HTMLCanvasElement;
  getDataContext?: () => ChartContext;
}

export interface DrawableImageProvider extends BaseContentProvider {
  getBase64Image: () => Promise<Base64Image | null>;
}

export interface DrawableVideoProvider extends DrawableImageProvider {
  getCanvasForRecording: () => HTMLCanvasElement;
}

export interface DrawableDataProvider extends BaseContentProvider {
  getDataContext: () => ChartContext;
}
