import { createSelector } from '@ngrx/store';
import { getWellLeftNavElement$ } from './well/well.menu';
import { getCompletionLeftNavElement$ } from './completion/completion.menu';
import { getFluidsLeftNavElement$ } from '../common/fluids/fluids.menu';
import { getGravelsLeftNavElement$ } from '../common/gravel/gravel.menu';
import { getPumpingLeftNavElement$ } from './pumping/pumping.menu';
import { getGaugeDataLeftNavElement } from '../gauge-data-page/gauge-data.menu';
import { ILeftNavElement, ILeftNavItem, LeftNavHelpers } from '../../shared/components/left-nav/left-nav.helpers';
import { getIsReportingEnabled, getSortedReportingTabsForCurrentRange } from '../../+store/reporting/reporting.selectors';
import { getCurrentAppModuleType } from '../../+store/ui/ui.selectors';
import {
  getAreCurrentResultsPresent,
  getAreCurrentResultsPresentAndCompleted,
} from '../../+store/calculation-engine/calculation-engine-results.selectors';
import { ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import { getReportingLeftMenu } from '../common/reporting/reporting-left-menu.helper';
import {
  RouteEvaluateAnimation,
  RouteModuleResults,
  RouteSimulateAnimation,
  RouteSummary,
  RouteWellVisualization,
} from './simulate-evaluate-routes-names';
import { getSettingsLeftNavElement } from '../common/settings/settings.menu';
import { getUsersWithFolders } from '../../+store/file-manager/file-manager.selectors';
import {
  RouteModuleAdmin,
  RouteModuleAdminBackup,
  RouteModuleAdminFileManagement,
  RouteModuleAdminQueueManagement,
  RouteModuleAdminShared,
  RouteModuleAdminUser,
} from '@dunefront/common/common/routes-names/admin-routes-names';

const getResultsLeftNavElement = createSelector(
  getCurrentAppModuleType,
  getAreCurrentResultsPresent,
  getAreCurrentResultsPresentAndCompleted,
  (moduleType, areResultPresent, areResultPresentAndCompleted): ILeftNavElement => {
    // Navigation items are enabled/disabled based on 2 flags: areResultPresent and areResultPresentAndCompleted. Ones controlled by
    // areResultPresent flag should be available during the simulation/evaluation(so if user moves to other page he can still move back
    // to animation). Ones controlled by areResultPresentAndCompleted flag should be available only when simulation/evaluation is finished.

    const items = [];
    if (moduleType === ModuleType.Simulate || moduleType === ModuleType.Simulate_CH) {
      items.push(
        ...[
          LeftNavHelpers.getItem('Animation', `${RouteModuleResults}/${RouteSimulateAnimation}`, true, !areResultPresent),
          LeftNavHelpers.getItem('Well visualization', `${RouteModuleResults}/${RouteWellVisualization}`, true, !areResultPresent),
          LeftNavHelpers.getItem('Summary', `${RouteModuleResults}/${RouteSummary}`, true, !areResultPresentAndCompleted),
        ],
      );
    }
    if (moduleType === ModuleType.Simulate_Disp) {
      items.push(
        ...[
          LeftNavHelpers.getItem('Animation', `${RouteModuleResults}/${RouteSimulateAnimation}`, true, !areResultPresent),
          LeftNavHelpers.getItem('Well visualization', `${RouteModuleResults}/${RouteWellVisualization}`, true, !areResultPresent),
          LeftNavHelpers.getItem('Summary', `${RouteModuleResults}/${RouteSummary}`, true, !areResultPresentAndCompleted),
        ],
      );
    }
    if (moduleType === ModuleType.Evaluate) {
      items.push(
        ...[
          LeftNavHelpers.getItem('Evaluation animation', `${RouteModuleResults}/${RouteEvaluateAnimation}`, true, !areResultPresent),
          LeftNavHelpers.getBreak(),
          LeftNavHelpers.getItem(
            'Simulation animation',
            `${RouteModuleResults}/${RouteSimulateAnimation}`,
            true,
            !areResultPresentAndCompleted,
          ),
          LeftNavHelpers.getItem(
            'Well visualization',
            `${RouteModuleResults}/${RouteWellVisualization}`,
            true,
            !areResultPresentAndCompleted,
          ),
          LeftNavHelpers.getItem('Simulation summary', `${RouteModuleResults}/${RouteSummary}`, true, !areResultPresentAndCompleted),
        ],
      );
    }

    return LeftNavHelpers.getNavElement('Results', `${RouteModuleResults}`, 'icon-analysis', true, false, items);
  },
);

export const getSimulateEvaluateReportingLeftNavElement = createSelector(
  getSortedReportingTabsForCurrentRange,
  getIsReportingEnabled,
  (reportingTabs, areResultPresentAndCompleted): ILeftNavElement => getReportingLeftMenu(reportingTabs, !areResultPresentAndCompleted),
);

const getSimulateEvaluateModuleLeftNavElements = createSelector(
  getCurrentAppModuleType,
  getWellLeftNavElement$,
  getCompletionLeftNavElement$,
  getFluidsLeftNavElement$,
  getGravelsLeftNavElement$,
  getPumpingLeftNavElement$,
  getSettingsLeftNavElement,
  getGaugeDataLeftNavElement,

  (
    moduleType,
    wellNavElement,
    completionNavElement,
    fluidsNavElement,
    gravelsNavElement,
    pumpingLeftNavElement,
    settingsLeftNavElement,
    gaugeDataLeftNavElement,
  ): ILeftNavElement[] => [
    wellNavElement,
    completionNavElement,
    fluidsNavElement,
    gravelsNavElement,
    ...(moduleType === ModuleType.Evaluate ? [gaugeDataLeftNavElement] : []),
    pumpingLeftNavElement,
    settingsLeftNavElement,
  ],
);

export const getSimulateEvaluateLeftNavMenu = createSelector(
  getSimulateEvaluateModuleLeftNavElements,
  getResultsLeftNavElement,
  getSimulateEvaluateReportingLeftNavElement,
  (navElements, results, reporting): ILeftNavItem[] => [
    ...navElements,
    LeftNavHelpers.getBreak(),
    LeftNavHelpers.getStartStopButton(),
    results,
    reporting,
  ],
);

export const getAdminPanelLeftNavMenu = createSelector(getUsersWithFolders, (users): ILeftNavItem[] => [
  LeftNavHelpers.getNavElement('File manager', `/${RouteModuleAdmin}/${RouteModuleAdminFileManagement}`, 'icon-file', true, false, [
    LeftNavHelpers.getItem('Shared', `/${RouteModuleAdmin}/${RouteModuleAdminFileManagement}/${RouteModuleAdminShared}`),
    LeftNavHelpers.getItem('Backups', `/${RouteModuleAdmin}/${RouteModuleAdminFileManagement}/${RouteModuleAdminBackup}`),
    LeftNavHelpers.getItem('User', `/${RouteModuleAdmin}/${RouteModuleAdminFileManagement}/${RouteModuleAdminUser}`),
  ]),
  LeftNavHelpers.getNavElement('Queue manager', `/${RouteModuleAdmin}/${RouteModuleAdminQueueManagement}`, 'icon-well'),
]);
