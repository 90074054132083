<ng-container *ngIf="showDrawing; else loading">
  <ng-container *ngIf="drawing$ | async as drawing; else loading">

    <ng-container *ngIf="isDrawingError(drawing)">
      <div class="error-container">
        <app-chart-overlay-info [overlayText]="drawing.error"></app-chart-overlay-info>
      </div>
    </ng-container>
    <app-drawing
      *ngIf="isDrawing(drawing)"
      [chartId]="chartId"
      data-cy="animation-packing-drawing"
      [drawing]="drawing"
      [isRecordingAllowed]="true"
      [drawableProviderId]="drawableProviderId"
      [drawingSection]="drawingSection"
      [layoutType]="layoutType"
      [isInPanel]="isInPanel"
    ></app-drawing>
  </ng-container>
</ng-container>

<ng-template #loading>
  <div class="loader-container">
    <app-loader></app-loader>
  </div>
</ng-template>
