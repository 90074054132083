import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-ribbon-menu-group',
  templateUrl: './menu-group.component.html',
  styleUrls: ['./menu-group.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MenuGroupComponent {
  @Input() public groupName: string | undefined;
}
