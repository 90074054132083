import { ICalculationEngineUpdatePayload, UserJobs } from '@dunefront/common/modules/calculation-engine/calculation-engine.actions';
import { createAction, props } from '@ngrx/store';
import { RedisConnectionStatus } from '@dunefront/common/modules/calculation-engine/calculation-engine.interfaces';
import { ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import { IGetCurrentFeaturesResult } from '@dunefront/common/modules/licensing/licensing.interfaces';

export const calculationProgressUpdatedAction = createAction(
  '[calculationEngine] calculationProgressUpdatedAction',
  props<{ jobPayload: ICalculationEngineUpdatePayload; currentFileHash: string }>(),
);

export const addCalculationsToQueueAction = createAction(
  '[calculationEngine] addCalculationsToQueueAction',
  props<IValidateAndAddCalculationToQueueActionPayload>(),
);

export const validateAndAddCalculationToQueueAction = createAction(
  '[calculationEngine] validateAndAddCalculationsToQueueAction',
  props<IValidateAndAddCalculationToQueueActionPayload>(),
);

export const updateRefVariablesValidateAndAddCalculationToQueueAction = createAction(
  '[calculationEngine] updateRefVariablesValidateAndAddCalculationToQueueAction',
  props<IValidateAndAddCalculationToQueueActionPayload>(),
);

export const runMultipleSimulationsForCompareScenario = createAction(
  '[calculationEngine] runMultipleSimulationsForCompareScenario',
  props<{ scenarioIds: number[] }>(),
);

export const stopCalculationsAction = createAction(
  '[calculationEngine] stopCalculationsAction',
  props<{ fileHash: string; jobIds: string[] }>(),
);

export interface IBaseAdvancedValidationActionPayload {
  moduleType: ModuleType;
  scenariosAndRanges: IScenarioAndRange[];
}

export enum CalculationContext {
  SINGLE_SCENARIO = 0,
  SCENARIO_MANAGER = 1,
  COMPARE_SCENARIO = 2,
}

export interface IValidateAndAddCalculationToQueueActionPayload extends IBaseAdvancedValidationActionPayload {
  fileHash: string;
  redirect: boolean;
  calculationContext: CalculationContext;
  currentLicenseFeatures: IGetCurrentFeaturesResult;
}

export const cancelJobsAction = createAction(
  '[calculationEngine] cancelJobs',
  props<{
    fileHash: string;
    jobIds: string[];
  }>(),
);

export const listUserJobsAction = createAction('[calculationEngine] listUserJobs');

export const userJobsUpdatedAction = createAction(
  '[calculationEngine] userJobsUpdated',
  props<{
    payload: { userJobs: UserJobs };
  }>(),
);

export const redisConnectionStatusChangedAction = createAction(
  '[BackendConnection] redisConnectionStatusChanged',
  props<{ status: RedisConnectionStatus }>(),
);
export const clearCalculationQueueAction = createAction(
  '[calculationEngine] clearCalculationQueueAction',
  props<{
    fileHash: string;
  }>(),
);

export interface IScenarioAndRange {
  scenarioId: number;
  rangeId: number;
}
