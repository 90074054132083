import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Pumping } from '@dunefront/common/modules/pumping/model/pumping/pumping';
import { ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';

@Component({
  selector: 'app-simple-fluid-definition',
  templateUrl: './simple-fluid-definition.component.html',
  styleUrls: ['./simple-fluid-definition.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimpleFluidDefinitionComponent {
  @Input() public pumping: Pumping | undefined;

  public get isWellFluidSelectionSimple(): boolean {
    return this.pumping?.IsWellFluidSelectionSimple ?? true;
  }

  @Output() public pumpingPropertyChanged = new EventEmitter<ObjectChangeProp<Pumping>>();

  public onValueChange(args: ObjectChangeProp<Pumping>): void {
    if (this.pumping && this.pumping[args.key] !== args.value) {
      this.pumpingPropertyChanged.emit(args);
    }
  }
}
