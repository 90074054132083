import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { IPromptConfigData, PromptModalContentComponent } from '../../../../common-modules/modals/alert/prompt-modal-content.component';
import { getCurrentRange, getRangeSelectData } from '../../../../+store/range/range.selectors';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';
import { RangeConstants } from '@dunefront/common/dto/range.dto';
import { ScenarioConstants } from '@dunefront/common/modules/scenario/scenario.dto';
import { getCurrentScenarioId } from '../../../../+store/scenario/scenario.selectors';
import { getStorageFileNames } from '../../../../+store/data-storage/data-storage.selectors';
import { areStringsTheSame } from '@dunefront/common/common/helpers';

@Component({
  selector: 'app-new-equation-file-dialog',
  templateUrl: './new-equation-file-dialog.component.html',
  styleUrls: ['./new-equation-file-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewEquationFileDialogComponent extends PromptModalContentComponent implements OnInit {
  public selectRangeData$ = this.store.select(getRangeSelectData);
  public selectedRangeId = RangeConstants.EntireRangeId;
  private currentScenarioId = ScenarioConstants.EmptyScenarioId;
  private storageFilenames: string[] = [];
  private originalFileName = '';

  constructor(
    ref: DynamicDialogRef,
    config: DynamicDialogConfig<IUpsertEquationConfigData>,
    private store: Store,
    private cdRef: ChangeDetectorRef,
  ) {
    super(ref, config);

    this.inputValue.value = config.data?.fileName ?? '';
    this.originalFileName = this.inputValue.value;
    this.selectedRangeId = config.data?.rangeId ?? RangeConstants.EmptyRangeId;
  }

  public async ngOnInit(): Promise<void> {
    this.selectedRangeId =
      this.selectedRangeId === RangeConstants.EmptyRangeId
        ? (await firstValueFrom(this.store.select(getCurrentRange)))?.Id ?? RangeConstants.EntireRangeId
        : this.selectedRangeId;

    this.storageFilenames = await firstValueFrom(this.store.select(getStorageFileNames));
    this.currentScenarioId = await firstValueFrom(this.store.select(getCurrentScenarioId));
    this.cdRef.markForCheck();
  }

  protected override getErrorMessage(value: string): string {
    if (!value) {
      return 'This field is required!';
    } else if (
      this.storageFilenames
        .filter((f) => !areStringsTheSame(f, this.originalFileName))
        .some((fileName) => areStringsTheSame(fileName, value))
    ) {
      return 'Filename must be unique!';
    } else {
      return '';
    }
  }

  public override okClicked(): void {
    const result: INewEquationFileResult = {
      fileName: this.inputValue.value + '',
      rangeId: this.selectedRangeId,
      // scenario should be null when range is null - this if full range equation, range independent
      scenarioId: ScenarioConstants.EmptyScenarioId,
    };
    this.ref.close(result);
  }

  public onRangeChanged(rangeId: number): void {
    this.selectedRangeId = rangeId;
  }
}

export interface IUpsertEquationConfigData extends IPromptConfigData {
  fileName: string;
  rangeId: number | null;
}

export interface INewEquationFileResult {
  fileName: string;
  rangeId: number;
  scenarioId: number;
}
