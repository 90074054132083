import { DocumentGeneratorHelper } from '../document-generator.helper';
import { HeadingLevel, Paragraph, Table } from 'docx';
import { IUnitSystemDto } from '@dunefront/common/dto/unit-system.dto';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';
import { DocumentTableGeneratorHelper, PresetColumnsWidths } from '../document-table-generator.helper';
import { getPSDAnalysis, getPSDsAsGrid } from '../../../../../../+store/psd-analysis/psd-analysis.selectors';
import { ITableState } from '@dunefront/common/common/common-grid.interfaces';
import { PSDResult } from '@dunefront/common/modules/psd-analysis/model/psd/psd';
import { ConvertUnitPipe } from '@dunefront/common/modules/units/convert-unit.pipe/convert-unit.pipe';
import { CommonReportDataHelpers } from '../../common/common-report-data-helpers';
import { PSDAnalysis } from '@dunefront/common/modules/psd-analysis/model/psd-analysis/psd-analysis';

export class PsdScreenAndGravelSelectionReportGeneratorHelper {
  public static async createPsdScreenAndGravelSelectionDataSection(
    store: Store,
    currentUnitSystem: IUnitSystemDto,
    convertUnitPipe: ConvertUnitPipe,
  ): Promise<Array<Paragraph | Table>> {
    const psdGrid = await firstValueFrom(store.select(getPSDsAsGrid));
    const psdAnalysis = await firstValueFrom(store.select(getPSDAnalysis));

    return [
      DocumentGeneratorHelper.createParagraphHeader(`PSD Screen and Gravel Selection`, HeadingLevel.HEADING_1, 0),

      DocumentGeneratorHelper.createParagraphHeader(`Standalone Screens`, HeadingLevel.HEADING_2, 1),
      this.createPsdScreenTable(psdGrid, psdAnalysis, currentUnitSystem, convertUnitPipe),

      DocumentGeneratorHelper.createParagraphHeader(`Gravel Pack`, HeadingLevel.HEADING_2, 1),
      this.createPsdGravelTable(psdGrid, currentUnitSystem),
    ];
  }

  private static createPsdScreenTable(
    psdGrid: ITableState<PSDResult>,
    psdAnalysis: PSDAnalysis,
    currentUnitSystem: IUnitSystemDto,
    convertUnitPipe: ConvertUnitPipe,
  ): Table {
    const tableData = CommonReportDataHelpers.createPsdScreenTableData(psdGrid, psdAnalysis, currentUnitSystem, convertUnitPipe);

    return DocumentTableGeneratorHelper.createTable(tableData, null, currentUnitSystem, undefined, {
      0: 5,
      1: 10,
      2: 8,
      3: 10,
      4: 10,
      5: 15,
    });
  }

  private static createPsdGravelTable(psdGrid: ITableState<PSDResult>, currentUnitSystem: IUnitSystemDto): Table {
    const tableData = CommonReportDataHelpers.createPsdGravelTableData(psdGrid);

    const presetColumnsWidths: PresetColumnsWidths = { 0: 5, 1: 10, 2: 8, 3: 15, 4: 10, 5: 15 };

    return DocumentTableGeneratorHelper.createTable(tableData, null, currentUnitSystem, undefined, presetColumnsWidths);
  }
}
