import { Injectable } from '@angular/core';
import {
  CalculateSectionAction,
  CompletionModuleName,
  IcdPortDataDeleteRowsAction,
  IcdPortDataInsertRowsAction,
  IcdPortDataUpdateRowAction,
  LowerCompletionDeleteRowsAction,
  LowerCompletionInsertRowsAction,
  LowerCompletionUpdateRowAction,
  RunningStringDeleteRowsAction,
  RunningStringInsertRowsAction,
  RunningStringReloadRowsAction,
  RunningStringUpdateRowAction,
  UpdateCompletionAction,
  UpdateShuntTubeAction,
} from '@dunefront/common/modules/completion/completion-module.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { filter, map, mergeMap } from 'rxjs/operators';
import { BackendConnectionService } from '../../shared/backend-connection/backend-connection.service';
import * as actions from './completion.actions';
import {
  calculateVolumesSectionAbortedAction,
  calculateVolumesSectionAction,
  calculateVolumesSectionUpdateAction,
} from './completion.actions';
import { BaseWsEffects } from '../base-ws.effects';
import { ModalService } from '../../common-modules/modals/modal.service';
import { LowerCompletionPipesFactory } from '@dunefront/common/modules/pipes/lower-completion-pipes/lower-completion-pipes.factory';
import { RunningStringPipesFactory } from '@dunefront/common/modules/pipes/running-string-pipes/running-string-pipes.factory';
import { ShuntTubeFactory } from '@dunefront/common/modules/shunt-tube/shunt-tube.factory';
import { CompletionFactory } from '@dunefront/common/modules/completion/model/completion.factory';
import {
  getValidatedCompletionModuleState,
  getValidatedMultipleSectionInputs,
  getValidatedSingleSectionInputs,
} from './validated-completion.selectors';
import { getCurrentScenarioId } from '../scenario/scenario.selectors';
import { WsActionPropsFactory } from '@dunefront/common/common/ws-action/ws-action-props.factory';
import { ShuntTubeDto } from '@dunefront/common/dto/shunt-tube.dto';
import { CompletionDto } from '@dunefront/common/modules/completion/dto/completion.dto';
import { VolumeCalculationErrorMessage } from '@dunefront/common/exceptions/errors';
import { getReferenceVariableCalculatorInputs } from '../reference-variables/reference-variables-calculator.selectors';
import { deleteRowsSuccess, insertRowsSuccess, updateRowSuccess } from '../app.actions';
import {
  getMdsForFirstRowToleranceCheck,
  getMdsForLastRowToleranceCheck,
  ToleranceHelperService,
} from '../../shared/services/tolerance-helper.service';
import { getValidatedDeveloperSettings } from '../settings/validated-settings.selectors';
import { getCurrentAppModuleType } from '../ui/ui.selectors';

@Injectable()
export class CompletionEffects extends BaseWsEffects {
  constructor(
    actions$: Actions,
    store: Store,
    wsService: BackendConnectionService,
    modalService: ModalService,
    private toleranceHelperService: ToleranceHelperService,
  ) {
    super(actions$, wsService, CompletionModuleName, false, true, modalService, store);
  }

  //region lower completion

  public insertLowerCompletionRow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.insertLowerCompletionRow),
      mergeMap((action) =>
        this.emitInsert(new LowerCompletionInsertRowsAction(WsActionPropsFactory.insertAction(action, LowerCompletionPipesFactory.toDto))),
      ),
    ),
  );

  public updateLowerCompletionRow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.updateLowerCompletionRow),
      mergeMap((action) => this.emitUpdate(new LowerCompletionUpdateRowAction(action))),
    ),
  );

  public deleteLowerCompletionRow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.deleteLowerCompletionRows),
      mergeMap((action) => this.emitDelete(new LowerCompletionDeleteRowsAction(action))),
    ),
  );

  // endregion

  //region icd port data

  public insertIcdPortDataRow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.insertIcdPortDataRow),
      mergeMap((action) =>
        this.emitInsert(
          new IcdPortDataInsertRowsAction(
            WsActionPropsFactory.insertAction(action, (row) => row),
            [],
            action.rows[0].rowData.LowerCompletionId,
          ),
        ),
      ),
    ),
  );

  public updateIcdPortDataRow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.updateIcdPortDataRow),
      mergeMap((action) => this.emitUpdate(new IcdPortDataUpdateRowAction(action))),
    ),
  );

  public deleteIcdPortDataRow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.deleteIcdPortDataRow),
      mergeMap((action) => this.emitDelete(new IcdPortDataDeleteRowsAction(action))),
    ),
  );

  // endregion

  //region running string

  public insertRunningStringRow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.insertRunningStringRow),
      mergeMap((action) =>
        this.emitInsert(new RunningStringInsertRowsAction(WsActionPropsFactory.insertAction(action, RunningStringPipesFactory.toDto))),
      ),
    ),
  );

  public updateRunningStringRow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.updateRunningStringRow),
      mergeMap((action) => this.emitUpdate(new RunningStringUpdateRowAction(action))),
    ),
  );

  public deleteRunningStringRows$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.deleteRunningStringRows),
      mergeMap((action) => this.emitDelete(new RunningStringDeleteRowsAction(action))),
    ),
  );

  public reloadRunningStringRows$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.reloadRunningStringAction),
      mergeMap((action) => this.emitInsert(new RunningStringReloadRowsAction(action.scenarioId))),
    ),
  );

  // endregion

  //region running string

  // endregion

  public afterCompletionChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.changeCompletionProperty),
      concatLatestFrom(() => this.store.select(getValidatedCompletionModuleState).pipe(filter((completion) => completion != null))),
      mergeMap(([action, completion]) =>
        this.emitUpdate(
          new UpdateCompletionAction(
            WsActionPropsFactory.update(
              [CompletionFactory.toDto(completion)],
              action.shouldResetResults,
              action.key ? [action.key as keyof CompletionDto] : [],
            ),
          ),
        ),
      ),
    ),
  );

  public afterShuntTubeChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.changeShuntTubeProperty),
      concatLatestFrom(() => this.store.select(getValidatedCompletionModuleState)),
      mergeMap(([action, completion]) =>
        this.emitUpdate(
          new UpdateShuntTubeAction(
            WsActionPropsFactory.update([ShuntTubeFactory.toDto(completion.ShuntTube)], action.shouldResetResults, [
              action.key as keyof ShuntTubeDto,
            ]),
          ),
        ),
      ),
    ),
  );

  public updateCompletion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.updateCompletion),
      mergeMap((action) =>
        this.emitUpdate(
          new UpdateCompletionAction({
            rows: [CompletionFactory.toDto(action.completion)],
            colIds: action.changedKey ? [action.changedKey] : [],
            shouldResetResults: action.shouldResetResults,
            scenarioId: action.completion.ScenarioId,
          }),
        ),
      ),
    ),
  );

  public calculateSingleSectionAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(calculateVolumesSectionAction),
      concatLatestFrom((action) => [
        this.store.select(action.calculationType === 'single' ? getValidatedSingleSectionInputs : getValidatedMultipleSectionInputs),
        this.store.select(getCurrentAppModuleType),
      ]),
      map(([action, sectionInputs, moduleType]) => {
        if (sectionInputs == null && action.calculationType === 'single') {
          this.modalService.showAlert(VolumeCalculationErrorMessage(moduleType)).then();
        }
        return sectionInputs != null
          ? calculateVolumesSectionUpdateAction({ ...action, sectionInputs })
          : calculateVolumesSectionAbortedAction(action);
      }),
    ),
  );

  public calculateSingleSectionUpdateAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(calculateVolumesSectionUpdateAction),
      concatLatestFrom(() => [this.store.select(getCurrentScenarioId), this.store.select(getReferenceVariableCalculatorInputs)]),
      mergeMap(([action, scenarioId, referenceVariableInput]) =>
        this.emitUpdate(
          new CalculateSectionAction(
            {
              SectionCalculatorInputs: action.sectionInputs,
              ReferenceVariableCalculatorJobInputData: referenceVariableInput,
            },
            scenarioId,
            action.calculationType,
          ),
        ),
      ),
    ),
  );

  public fixLastRowWithToleranceCheck$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateRowSuccess, insertRowsSuccess, deleteRowsSuccess),
        filter(
          (action) =>
            action.affectedRows.survey != null ||
            action.affectedRows.casing != null ||
            action.affectedRows.zone != null ||
            action.affectedRows.caliper != null ||
            action.affectedRows.lowerCompletion != null ||
            action.affectedRows.runningString != null,
        ),
        concatLatestFrom((action) => [
          this.store.select(getMdsForFirstRowToleranceCheck),
          this.store.select(getMdsForLastRowToleranceCheck),
          this.store.select(getValidatedDeveloperSettings),
        ]),
        map(([action, mdsForFirstRowToleranceCheck, mdsForLastRowToleranceCheck, devOptions]) => {
          this.toleranceHelperService.fixFirstRowWithToleranceCheck(mdsForFirstRowToleranceCheck, devOptions.GlobalTolerance);
          this.toleranceHelperService.fixLastRowWithToleranceCheck(mdsForLastRowToleranceCheck, devOptions.GlobalTolerance);
        }),
      ),
    { dispatch: false },
  );
}
