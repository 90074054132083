import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Pumping } from '@dunefront/common/modules/pumping/model/pumping/pumping';
import { IRadioItem } from '@dunefront/common/common/radio.helpers';
import { ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';

@Component({
  selector: 'app-well-fluids-type',
  templateUrl: './well-fluids-type.component.html',
  styleUrls: ['./well-fluids-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WellFluidsTypeComponent {
  @Input() public pumping: Pumping | undefined;
  @Output() public pumpingPropertyChanged = new EventEmitter<ObjectChangeProp<Pumping>>();

  public items: IRadioItem<boolean>[] = [
    { value: true, text: 'Simple' },
    { value: false, text: 'Detailed' },
  ];
}
