import { IScenarioIdBasedTableEntity } from '../../../../dto/common-dto.interfaces';
import { SurveyDto } from '../survey/survey.dto';
import { ZonePressureType } from '../well.dto';

export interface ZoneDto extends IScenarioIdBasedTableEntity {
  Id: number;
  ScenarioId: number;
  Name: string;
  TopMD: number;
  BottomMD: number;
  FracturePressureGradient: number;
  ReservoirPressureGradient: number;
  FracturePressure: number;
  ReservoirPressure: number;
  FracturePressureEMW: number;
  ReservoirPressureEMW: number;
  PermeabilityToReservoirFluid: number;
  Skin: number;
  Porosity: number;
  LossZoneType: LossZoneType;
  LossPercentage: number;
  TopTVD: number;
  BottomTVD: number;
  ReservoirFluidViscosity: number;
  PermeabilityToInjectedFluid: number;
  YoungsModulus: number;
  PoissonsRatio: number;
  FluidLossCoefficient: number;
  SortOrder: number;
}

export enum LossZoneType {
  None = 0,
  Single_Point = 1,
  Interval = 2,
}

export function createEmptyZoneDto(scenarioId: number): ZoneDto {
  const zoneDto: ZoneDto = {
    ScenarioId: scenarioId,
    Id: -1,
    TopTVD: 0,
    BottomTVD: 0,
    BottomMD: 0,
    FluidLossCoefficient: 0,
    FracturePressure: 0,
    FracturePressureEMW: 0,
    FracturePressureGradient: 0,
    LossPercentage: 0,
    LossZoneType: LossZoneType.None,
    Name: '',
    PermeabilityToInjectedFluid: 0,
    PermeabilityToReservoirFluid: 0,
    PoissonsRatio: 0,
    Porosity: 0,
    ReservoirFluidViscosity: 0,
    ReservoirPressure: 0,
    ReservoirPressureEMW: 0,
    ReservoirPressureGradient: 0,
    Skin: 0,
    TopMD: 0,
    YoungsModulus: 0,
    SortOrder: -1,
  };
  return zoneDto;
}

export interface ZoneJobInputData {
  ZoneData: ZoneDto[];
  SurveyData: SurveyDto[];
  ZonePressureType: ZonePressureType;
  IsZoneDepthByMD: boolean;
}
