import { IModuleState, IValidatedModuleState } from '@dunefront/common/common/common-state.interfaces';
import { createTableState, ITableState } from '@dunefront/common/common/common-grid.interfaces';
import { IIndexedDataType } from '@dunefront/common/dto/common-dto.interfaces';
import { IGridColumnConfig } from '../../shared/components/grid/grid.interfaces';

export interface GaugeDataModuleState extends IModuleState {
  SelectedFileId: number;
  gridColumns: IGridColumnConfig<IGaugeDataRow>[];
  tableState: ITableState<IGaugeDataRow>;
  isLoading: boolean;
}

export const initialGaugeDataModuleState: GaugeDataModuleState = {
  SelectedFileId: -1,
  isLoaded: true,
  isLoading: false,
  gridColumns: [],
  tableState: createTableState([]),
};

export type ValidatedGaugeDataModuleState = GaugeDataModuleState & IValidatedModuleState<GaugeDataModuleState>;

export interface IGaugeDataRow extends IIndexedDataType, Record<string, number> {
  Argument: number;
  RowIndex: number;
}
