import { RheometerReadingDto } from '../../dto/rheometer.dto';
import { createTableRow, createTableState, GridRowType, ITableRow, ITableState } from '../../../../common/common-grid.interfaces';
import { RheometerReading } from './rheometer-reading';
import { DictionaryWithArray, IDictionaryWithArray } from '../../../../common/state.helpers';
import { groupBy } from 'lodash';

export class RheometerReadingFactory {
  public static create(rheometerReadingDtos: RheometerReadingDto[]): IDictionaryWithArray<ITableState<RheometerReading>> {
    const rheometerReadingsGroupedByFluidId = groupBy(rheometerReadingDtos, (data) => data.RheometerId);

    let dict: IDictionaryWithArray<ITableState<RheometerReading>> = {
      ids: [],
      dict: {},
    };
    for (const rheometerId of Object.keys(rheometerReadingsGroupedByFluidId)) {
      const rh = rheometerReadingsGroupedByFluidId[rheometerId] ?? [];
      dict = DictionaryWithArray.upsertById(dict, this.createRheometerReadingTableState(rh), rheometerId);
    }
    return dict;
  }

  public static createRheometerReadingTableState(rheometerReadingDtos: RheometerReadingDto[]): ITableState<RheometerReading> {
    return createTableState<RheometerReading>(this.createRheometerReadingRowListFromDtos(rheometerReadingDtos));
  }

  public static createRheometerReading(rheometerReadingDto: RheometerReadingDto): RheometerReading {
    return { ...rheometerReadingDto };
  }

  public static createRheometerReadingRowFromDto(
    rheometerReadingDto: RheometerReadingDto,
    rowIndex: number,
    isEditingDisabled = false,
    rowType: GridRowType = 'data',
  ): ITableRow<RheometerReading> {
    return createTableRow<RheometerReading>(
      RheometerReadingFactory.createRheometerReading(rheometerReadingDto),
      rowType,
      rowIndex,
      isEditingDisabled,
    );
  }

  public static createRheometerReadingRowListFromDtos(rheometerReadingDtos: RheometerReadingDto[]): ITableRow<RheometerReading>[] {
    const rheometerId = rheometerReadingDtos[0]?.RheometerId ?? -1;
    const scenarioId = rheometerReadingDtos[0]?.ScenarioId ?? -1;
    return [
      ...rheometerReadingDtos
        .sort((a, b) => a.RPM - b.RPM)
        .sort((a, b) => a.ShearRate - b.ShearRate)
        .map((rheometerReadingDto, index) => this.createRheometerReadingRowFromDto(rheometerReadingDto, index)),
      this.createRheometerReadingEmptyRow(rheometerId, scenarioId, rheometerReadingDtos.length, false, 'insert-row'),
    ];
  }

  public static createEmpty(scenarioId: number, rheometerId: number): RheometerReading {
    return {
      Id: -1,
      RheometerId: rheometerId,
      RPM: 0,
      DialReading: 0,
      ShearRate: 0,
      Viscosity: 0,
      ScenarioId: scenarioId,
      SortOrder: -1,
    };
  }

  public static createRowFromPaste(
    rpmOrShearRate: number,
    dialReadingOrViscosity: number,
    scenarioId: number,
    rheometerId: number,
    isRheometerTestTypeShearRate: boolean,
  ): ITableRow<RheometerReading> {
    const rowData: RheometerReading = {
      Id: -1,
      RheometerId: rheometerId,
      RPM: isRheometerTestTypeShearRate ? 0 : rpmOrShearRate,
      DialReading: isRheometerTestTypeShearRate ? 0 : dialReadingOrViscosity,
      ShearRate: isRheometerTestTypeShearRate ? rpmOrShearRate : 0,
      Viscosity: isRheometerTestTypeShearRate ? dialReadingOrViscosity : 0,
      ScenarioId: scenarioId,
      SortOrder: -1,
    };
    return createTableRow(rowData, 'data', -1, false);
  }

  public static createRheometerReadingEmptyRow(
    rheometerId: number,
    scenarioId: number,
    rowIndex: number,
    isEditingDisabled: boolean,
    rowType: GridRowType,
  ): ITableRow<RheometerReading> {
    const rowData: RheometerReading = {
      Id: -1,
      RheometerId: rheometerId,
      RPM: 0,
      DialReading: 0,
      ShearRate: 0,
      Viscosity: 0,
      ScenarioId: scenarioId,
      SortOrder: -1,
    };
    return createTableRow(rowData, rowType, rowIndex, isEditingDisabled);
  }

  public static rheometerReadingToDto(rheometerReading: RheometerReading): RheometerReadingDto {
    return { ...rheometerReading };
  }
}
