import { LocationType, TemperatureProfileType, TreatmentType } from '@dunefront/common/modules/well/dto/well.dto';
import { DocumentGeneratorHelper } from '../document-generator.helper';
import { HeadingLevel, Paragraph, Table } from 'docx';
import { WellModuleState } from '@dunefront/common/modules/well/well-module.state';
import { IUnitSystemDto } from '@dunefront/common/dto/unit-system.dto';
import { getRowsForCalculations } from '@dunefront/common/common/common-grid.interfaces';
import { EnumHelpers } from '@dunefront/common/utils/enum.helpers';
import { PipeType } from '@dunefront/common/dto/pipe.dto';
import { ISelectItem } from '@dunefront/common/common/select.helpers';
import { DocumentTableGeneratorHelper } from '../document-table-generator.helper';
import { ZoneModel } from '@dunefront/common/modules/well/model/zone/zone.model';
import { Pipe } from '@dunefront/common/modules/pipes/pipe';
import { getSurveyGridConfig } from '../../../../../../+store/well/well.grid.selectors';
import { firstValueFrom } from 'rxjs';
import { Store } from '@ngrx/store';
import { LossZoneType } from '@dunefront/common/modules/well/dto/zone/zone.dto';
import { wellGeneralGridConfig } from '../../common/grid-configs/well-general';
import { calculateLossesZoneProperties, specifyLossesZoneProperties } from '../../common/grid-configs/well-zone-properties';
import { getWellModuleState } from '../../../../../../+store/well/well.selectors';
import { Caliper } from '@dunefront/common/modules/well/model/caliper/caliper';
import { caliperDataGridConfig } from '../../../../../../pages/simulate-evaluate-page/well/caliper-data/caliper-data-grid/caliper-data-grid.config';
import { ReportScenarioImages } from './chart-report-generator.helper';
import { ReportInfoFields } from '@dunefront/common/dto/report-info.dto';
import { Base64Image } from '../../../../../../common-modules/chart/image-provider.helpers';
import { Survey } from '@dunefront/common/modules/well/model/survey/survey';
import { perforationProperties } from '../../common/grid-configs/perforation-properties';
import { PerforatedCasingPipe } from '@dunefront/common/modules/pipes/casing-pipes/pipes/perforated-casing-pipe';
import { PerforationPhasingTypeEnum } from '@dunefront/common/modules/well/dto/casing/casing-pipe.dto';
import { PlaneAngleConverter } from '@dunefront/common/unit-converters/converters/plane-angle/plane-angle.converter';
import { MathHelpers } from '@dunefront/common/common/math-helpers';
import { CommonReportDataHelpers, IDataRowWithCode, ITableData } from '../../common/common-report-data-helpers';
import { DocxImageGenerator } from '../docx-image-generator';

export class WellReportGeneratorHelper {
  public static async createWellDataSection(
    store: Store,
    currentUnitSystem: IUnitSystemDto,
    images: ReportScenarioImages,
    reportInfoFields: ReportInfoFields,
    isOpenHole: boolean,
    docxImageGenerator: DocxImageGenerator,
  ): Promise<Array<Paragraph | Table>> {
    const wellModuleState: WellModuleState = await firstValueFrom(store.select(getWellModuleState));

    const casingPipeData: IDataRowWithCode<Pipe>[] = CommonReportDataHelpers.generateCodeValues(
      getRowsForCalculations(wellModuleState.CasingData.rows),
    );

    const zoneData: IDataRowWithCode<ZoneModel>[] = CommonReportDataHelpers.generateCodeValues(
      getRowsForCalculations(wellModuleState.ZoneData.rows),
    );

    const isPerforatedProperties = casingPipeData.some((row) => row.PipeType === PipeType.Perforated_Casing);

    return [
      DocumentGeneratorHelper.createParagraphHeader(`Well Data`, HeadingLevel.HEADING_1, 0),
      DocumentGeneratorHelper.createParagraphHeader(`General Data`, HeadingLevel.HEADING_2, 1),
      this.createGeneralData(wellModuleState, currentUnitSystem),
      ...(await this.createSurveyDataSection(
        images.surveyChart,
        reportInfoFields.IsWellSurveyData,
        wellModuleState,
        currentUnitSystem,
        store,
        docxImageGenerator,
      )),

      DocumentGeneratorHelper.createParagraphHeader(`Casing Data`, HeadingLevel.HEADING_2, 1),
      this.createCasingTable(casingPipeData, currentUnitSystem),
      ...(isPerforatedProperties
        ? [
            DocumentGeneratorHelper.createParagraphHeader(`Perforation Properties`, HeadingLevel.HEADING_3, 2),
            this.createCasingPerforationProperties(casingPipeData, currentUnitSystem),
          ]
        : []),

      DocumentGeneratorHelper.createParagraphHeader(`Reservoir Zones`, HeadingLevel.HEADING_2, 1),
      await this.createReservoirZoneTable(zoneData, currentUnitSystem, store),

      DocumentGeneratorHelper.createParagraphHeader(`Zone Properties`, HeadingLevel.HEADING_3, 2),
      this.createZonePropertiesTable(wellModuleState, zoneData, currentUnitSystem),
      ...(isOpenHole
        ? await this.createCaliperDataSection(
            images.caliperChart,
            reportInfoFields.IsWellCaliperData,
            wellModuleState,
            currentUnitSystem,
            docxImageGenerator,
          )
        : []),
    ];
  }

  private static async createSurveyDataSection(
    chart: Base64Image | undefined,
    renderData: boolean,
    wellModuleState: WellModuleState,
    currentUnitSystem: IUnitSystemDto,
    store: Store,
    docxImageGenerator: DocxImageGenerator,
  ): Promise<Array<Paragraph | Table>> {
    const reportingObjects: Array<Paragraph | Table> = [];
    let level = 1;
    if (renderData && chart) {
      reportingObjects.push(...[DocumentGeneratorHelper.createParagraphHeader(`Survey`, HeadingLevel.HEADING_2, 1)]);
      level = 2;
    }

    if (renderData) {
      reportingObjects.push(
        ...[
          DocumentGeneratorHelper.createParagraphHeader(`Survey Data`, HeadingLevel.HEADING_3, level),
          await this.createSurveyDataTable(wellModuleState, currentUnitSystem, store),
        ],
      );
    }
    if (chart) {
      reportingObjects.push(
        ...[
          DocumentGeneratorHelper.createParagraphHeader(`Survey Preview`, HeadingLevel.HEADING_3, level),
          ...(await DocumentGeneratorHelper.drawGenericImage(chart, docxImageGenerator)),
        ],
      );
    }
    return reportingObjects;
  }

  private static async createCaliperDataSection(
    chart: Base64Image | undefined,
    renderData: boolean,
    wellModuleState: WellModuleState,
    currentUnitSystem: IUnitSystemDto,
    docxImageGenerator: DocxImageGenerator,
  ): Promise<Array<Paragraph | Table>> {
    const reportingObjects: Array<Paragraph | Table> = [];
    let level = 1;
    if (renderData && chart) {
      reportingObjects.push(...[DocumentGeneratorHelper.createParagraphHeader(`Caliper`, HeadingLevel.HEADING_2, 1)]);
      level = 2;
    }

    if (renderData) {
      reportingObjects.push(
        ...[
          DocumentGeneratorHelper.createParagraphHeader(`Caliper Data`, HeadingLevel.HEADING_3, level),
          this.createCaliperDataTable(wellModuleState, currentUnitSystem),
        ],
      );
    }

    if (chart) {
      reportingObjects.push(
        ...[
          DocumentGeneratorHelper.createParagraphHeader(`Caliper Preview`, HeadingLevel.HEADING_3, level),
          ...(await DocumentGeneratorHelper.drawGenericImage(chart, docxImageGenerator)),
        ],
      );
    }
    return reportingObjects;
  }

  private static createGeneralData(wellModuleState: WellModuleState, currentUnitSystem: IUnitSystemDto): Table {
    const { keyValueData, enumItemMap } = CommonReportDataHelpers.createGeneralData(wellModuleState);

    return DocumentTableGeneratorHelper.createGenericKeyValueTable<IWellGeneralData>(
      wellGeneralGridConfig,
      keyValueData,
      currentUnitSystem,
      enumItemMap,
    );
  }

  private static createCasingTable(casingData: IDataRowWithCode<Pipe>[], currentUnitSystem: IUnitSystemDto): Table {
    const { tableData, enumItemMap } = CommonReportDataHelpers.createCasingTableData(casingData);

    return DocumentTableGeneratorHelper.createTable(
      tableData,
      enumItemMap,
      currentUnitSystem,
      undefined,
      tableData.dataCells.length === 8
        ? {
            0: 5,
            1: 20,
            2: 10,
            3: 10,
            4: 10,
            5: 10,
            6: 10,
            7: 10,
          }
        : {
            0: 6,
            1: 20,
            2: 10,
            3: 10,
            4: 10,
            5: 8,
            6: 8,
            7: 8,
            8: 20,
          },
    );
  }

  private static createCasingPerforationProperties(casingData: IDataRowWithCode<Pipe>[], currentUnitSystem: IUnitSystemDto): Table {
    const enumItemMap = new Map<keyof PerforatedCasingPipe, ISelectItem<any>[]>();
    enumItemMap.set('PipeType', EnumHelpers.EnumToISelectItemArray(PipeType));

    // Phasing type is an enum, with text values (like 'deg 60'), so we need to prepare and convert values before passing it to builder
    const phasingTypeSelectItems = EnumHelpers.EnumToISelectItemArray(PerforationPhasingTypeEnum).map((opt) => {
      let phasingValue = opt.text.replace('deg ', '');

      if (Number(phasingValue)) {
        phasingValue = MathHelpers.round(PlaneAngleConverter.fromSi(+phasingValue, currentUnitSystem.Angle), 4).toString();
      }

      return { ...opt, text: phasingValue };
    });

    enumItemMap.set('PerforationPhasingType', phasingTypeSelectItems);

    const perforatedPipes: PerforatedCasingPipe[] = casingData.filter(
      (casing) => casing.PipeType === PipeType.Perforated_Casing,
    ) as PerforatedCasingPipe[];

    const casingTableData: ITableData<PerforatedCasingPipe> = {
      dataRows: perforatedPipes,
      dataCells: perforationProperties,
    };

    return DocumentTableGeneratorHelper.createTable(casingTableData, enumItemMap, currentUnitSystem, undefined, {
      0: 5,
      1: 20,
      2: 10,
      3: 10,
      4: 10,
      5: 10,
      6: 10,
      7: 10,
    });
  }

  private static async createReservoirZoneTable(
    zoneData: IDataRowWithCode<ZoneModel>[],
    currentUnitSystem: IUnitSystemDto,
    store: Store,
  ): Promise<Table> {
    const { tableData, enumItemMap } = await CommonReportDataHelpers.createReservoirZoneTableData(zoneData, store);

    return DocumentTableGeneratorHelper.createTable(tableData, enumItemMap, currentUnitSystem, undefined, {
      0: 5,
      1: 20,
      2: 10,
      3: 10,
      4: 10,
      5: 10,
      6: 10,
      7: 10,
    });
  }

  private static createZonePropertiesTable(
    wellModuleState: WellModuleState,
    zoneData: IDataRowWithCode<ZoneModel>[],
    currentUnitSystem: IUnitSystemDto,
  ): Table {
    const enumItemMap = new Map<keyof ZoneModel, ISelectItem<any>[]>();
    enumItemMap.set('LossZoneType', EnumHelpers.EnumToISelectItemArray(LossZoneType));
    const zoneTableData: ITableData<ZoneModel> = {
      dataRows: zoneData,
      dataCells: wellModuleState.IsLossRateCalculated ? calculateLossesZoneProperties : specifyLossesZoneProperties,
    };
    return DocumentTableGeneratorHelper.createTable(zoneTableData, enumItemMap, currentUnitSystem, undefined, {
      0: 7,
      1: 20,
      2: 10,
      3: 10,
      4: 10,
      5: 12,
      6: 10,
      7: 6,
      8: 14,
      9: 10,
    });
  }

  private static createCaliperDataTable(wellModuleState: WellModuleState, currentUnitSystem: IUnitSystemDto): Table {
    const caliperData = getRowsForCalculations(wellModuleState.CaliperData.rows);
    const table: ITableData<Caliper> = {
      dataRows: CommonReportDataHelpers.generateCodeValues(caliperData),
      dataCells: CommonReportDataHelpers.gridColConfigToTableColConfig(caliperDataGridConfig),
    };

    return DocumentTableGeneratorHelper.createTable(table, null, currentUnitSystem);
  }

  private static async createSurveyDataTable(
    wellModuleState: WellModuleState,
    currentUnitSystem: IUnitSystemDto,
    store: Store,
  ): Promise<Table> {
    const rows = getRowsForCalculations(wellModuleState.SurveyData.rows);
    const dataCells = CommonReportDataHelpers.gridColConfigToTableColConfig(
      (await firstValueFrom(store.select(getSurveyGridConfig))).filter((col) => col.visible),
    );

    const table: ITableData<Survey> = {
      dataRows: CommonReportDataHelpers.generateCodeValues(rows),
      dataCells,
    };

    return DocumentTableGeneratorHelper.createTable(table, null, currentUnitSystem);
  }
}

export interface IWellGeneralData {
  TreatmentType: TreatmentType;
  LocationType: LocationType;
  SeaLevelDepth: number;
  SeaBedDepth: number;
  BottomholeMD: number;
  BottomholeTVD: number;
  MaxDeviation: number;
  TemperatureProfile: TemperatureProfileType;
  IsLossRateCalculated: boolean;
}
