<div class="grid-form-container main with-gap" (click)="changeHelp()" *ngIf="perforatedCasingPipe">
  <div class="grid-form-container form-column no-padding inline" appEqualizeSymbols>
    <app-form-input
      appUnwrap
      label="Shot Density"
      [source]="perforatedCasingPipe"
      key="PerforationShotDensity"
      [decimalPlaces]="2"
      [unitType]="UnitType.Shot_Density"
      (valueChanged)="onValueChange($event)"
    >
    </app-form-input>
    <app-form-input
      appUnwrap
      label="Volume"
      [source]="perforatedCasingPipe"
      key="PerforationVolume"
      [decimalPlaces]="2"
      [unitType]="UnitType.Linear_Volume"
      (valueChanged)="onValueChange($event)"
    >
    </app-form-input>
    <label>Phasing</label>
    <div class="input">
      <app-select
        [source]="perforatedCasingPipe.rowData"
        [key]="'PerforationPhasingType'"
        [items]="anglesList"
        [width]="154"
        [disableSorting]="true"
        [unitLabel]="PlaneAngleConverter.getSymbol(currentUnitSystem.Angle)"
        (valueChanged)="onValueChange($event)"
        [overrideErrorMessage]="perforatedCasingPipe.error.PerforationPhasingType"
      >
      </app-select>
    </div>
  </div>
  <div class="grid-form-container form-column no-padding inline" appEqualizeSymbols>
    <app-form-input
      appUnwrap
      label="Tunnel Length"
      [source]="perforatedCasingPipe"
      key="PerforationTunnelLength"
      [decimalPlaces]="2"
      [unitType]="UnitType.Short_Length"
      (valueChanged)="onValueChange($event)"
    >
    </app-form-input>
    <app-form-input
      appUnwrap
      label="Diameter"
      [source]="perforatedCasingPipe"
      key="PerforationDiameter"
      [decimalPlaces]="3"
      [unitType]="UnitType.Diameter"
      (valueChanged)="onValueChange($event)"
      dataCy="perforation-properties-diameter-input"
    >
    </app-form-input>
    <app-form-input
      appUnwrap
      label="Open Perf %"
      [source]="perforatedCasingPipe"
      key="OpenPerforationPercentage"
      [decimalPlaces]="2"
      [unitType]="UnitType.None"
      unitLabel="%"
      (valueChanged)="onValueChange($event)"
    >
    </app-form-input>
  </div>
</div>
