import { Routes } from '@angular/router';
import { EvaluateAnimationComponent } from './animation/evaluate-animation.component';
import { SummaryComponent } from './summary/summary.component';
import { ResultsComponent } from './results.component';
import { WellVisualizationComponent } from './well-visualization/well-visualization.component';
import {
  RouteEvaluateAnimation,
  RouteModuleResults,
  RouteSimulateAnimation,
  RouteSummary,
  RouteWellVisualization,
} from '../simulate-evaluate-routes-names';
import { SimulationAnimationComponent } from './animation/simulation-animation.component';

export const resultsEvaluateRoutes: Routes = [
  {
    path: RouteModuleResults,
    component: ResultsComponent,
    data: {
      breadcrumb: 'Results',
    },
    children: [
      {
        path: RouteEvaluateAnimation,
        component: EvaluateAnimationComponent,
        data: {
          breadcrumb: 'Evaluation Animation',
        },
      },
      {
        path: RouteSimulateAnimation,
        component: SimulationAnimationComponent,
        data: {
          breadcrumb: 'Simulation Animation',
        },
      },
      {
        path: RouteWellVisualization,
        component: WellVisualizationComponent,
        data: {
          breadcrumb: 'Well Visualization',
        },
      },
      {
        path: RouteSummary,
        component: SummaryComponent,
        data: {
          breadcrumb: 'Simulation Summary',
        },
      },
      {
        path: '',
        redirectTo: RouteEvaluateAnimation,
        pathMatch: 'full',
      },
    ],
  },
];
