import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CompletionModuleState } from '@dunefront/common/modules/completion/completion-module.state';
import { BasicPropertyChangeComponent } from '../../../../../common-modules/db-connection/basic-property-change.component';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { CompletionCalculations } from '@dunefront/common/modules/completion/model/completion.calculations';
import { ReferenceVariableCalculatorResultsDto } from '@dunefront/common/modules/reference-variables/dto/reference-variable-calculator-results.dto';

@Component({
  selector: 'app-washpipe-properties',
  templateUrl: './washpipe-properties.component.html',
  styleUrls: ['./washpipe-properties.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WashpipePropertiesComponent extends BasicPropertyChangeComponent<CompletionModuleState> {
  @Input()
  public completion!: CompletionModuleState;

  @Input()
  public referenceVariables!: ReferenceVariableCalculatorResultsDto;

  public UnitType = UnitSystem;
  public CompletionCalculations = CompletionCalculations;
}
