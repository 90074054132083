import { createSelector } from '@ngrx/store';
import { AnimationLayout, WellPartType } from '@dunefront/common/modules/settings/dto/settingsDto';
import { getPumpingToolPosition } from '../../../+store/pumping/selectors/pumping.selectors';
import { AnimationHelpers } from './helpers/animation-helpers';
import {
  getSimulationAnimationWellLayout,
  getWellVisualizationWellLayout,
  getWellVisualizationWellPart,
} from '../../../+store/settings/validated-settings.selectors';
import { getWellOrientation } from '../../../+store/well/validated-well.selectors';
import { getAppModuleType } from '../../../+store/ui/ui.selectors';

// well visualisation

export const getIsWellVisualizationWellPartFixed = createSelector(getPumpingToolPosition, (toolPosition) =>
  AnimationHelpers.IsWellVisualizationDrawingSectionFixed(toolPosition),
);

export const getEffectiveWellVisualizationWellPart = createSelector(
  getPumpingToolPosition,
  getWellVisualizationWellPart,
  getIsWellVisualizationWellPartFixed,
  (toolPosition, wellPart, isDrawingSectionFixed): WellPartType =>
    isDrawingSectionFixed ? AnimationHelpers.GetDefaultWellVisualizationDrawingSection(toolPosition) : wellPart,
);

export const getEffectiveWellVisualizationWellLayout = createSelector(
  getWellVisualizationWellLayout,
  (wellVisualizationWellLayout) => wellVisualizationWellLayout,
);

// simulation animation

export const getEffectiveSimulationAnimationWellLayout = createSelector(
  getSimulationAnimationWellLayout,
  getWellOrientation,
  (simulationAnimationWellLayout, wellOrientation): AnimationLayout.Horizontal | AnimationLayout.Vertical =>
    simulationAnimationWellLayout === AnimationLayout.Auto
      ? AnimationHelpers.GetAutoSimulationAnimationLayoutType(wellOrientation)
      : simulationAnimationWellLayout,
);

export const getEffectiveSimulationAnimationWellPart = createSelector(
  getPumpingToolPosition,
  getAppModuleType,
  (toolPosition, moduleType): WellPartType => AnimationHelpers.GetDefaultSimulationAnimationDrawingSection(toolPosition, moduleType),
);
