import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { NotificationWithStatus } from '../../../../../+store/ui/ui-module.state';
import { NotificationStatus } from '@dunefront/common/dto/custom-settings.dto';

@Component({
  selector: 'app-single-notification',
  templateUrl: 'single-notification.component.html',
  styleUrls: ['./single-notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SingleNotificationComponent {
  @Input({ required: true }) public notification!: NotificationWithStatus;
  @Input({ required: true }) public showExtraButtons = false;

  @Output() public buttonClicked = new EventEmitter<NotificationStatus>();

  public NotificationStatus = NotificationStatus;
}
