import { BasePptSectionGenerator, PptContext } from '../base-ppt-section-generator';
import { IUnitSystemDto } from '@dunefront/common/dto/unit-system.dto';
import { firstValueFrom } from 'rxjs';
import { selectCurrentUnitSystem } from '../../../../../../+store/units/units.selectors';
import { getWellTreatmentType } from '../../../../../../+store/well/well.selectors';
import { CommonReportDataHelpers, ITableData, KeyValueTableRow } from '../../common/common-report-data-helpers';
import { PptDocumentTableGeneratorHelper } from '../ppt-document-table-generator.helper';
import { SummaryModuleState } from '../../../../../../+store/summary/summary.reducer';
import { getSummaryState } from '../../../../../../+store/summary/summary.selectors';
import { filter } from 'rxjs/operators';
import { TreatmentType } from '@dunefront/common/modules/well/dto/well.dto';
import { WarningMessage } from '@dunefront/common/modules/summary/warning-message.factory';
import { SummaryDto } from '@dunefront/common/dto/summary.dto';
import { loadSummaryModuleAction } from '../../../../../../+store/summary/summary.actions';
import { EmptyResultsPptGenerator } from './empty-results-ppt-generator';
import { getAreCurrentResultsPresent } from '../../../../../../+store/calculation-engine/calculation-engine-results.selectors';

export class SimulationSummaryDataPptGenerator extends BasePptSectionGenerator {
  constructor(context: PptContext) {
    super(context);
  }

  public async generate(rangeName?: string, isTestRun?: boolean): Promise<void> {
    const mainTitle = 'Simulation Summary';
    const subTitle = rangeName;

    const areCurrentResultsPresent = await firstValueFrom(this.store.select(getAreCurrentResultsPresent));
    if (!areCurrentResultsPresent) {
      return await new EmptyResultsPptGenerator({
        ...this.context,
        title: mainTitle,
        subTitle,
      }).generate();
    }

    this.store.dispatch(loadSummaryModuleAction());
    const currentUnitSystem: IUnitSystemDto = await firstValueFrom(this.store.select(selectCurrentUnitSystem));
    const summaryState: SummaryModuleState = await firstValueFrom(this.store.select(getSummaryState).pipe(filter((state) => state.isLoaded)));
    const wellTreatment: TreatmentType = await firstValueFrom(this.store.select(getWellTreatmentType));

    const summary = summaryState.summary;
    if (!summary) {
      return await new EmptyResultsPptGenerator({
        ...this.context,
        title: mainTitle,
        subTitle,
      }).generate();
    }

    const tableData = CommonReportDataHelpers.createSummaryWarningMessagesTableData(summaryState, currentUnitSystem, isTestRun ?? false);
    const tableRows = PptDocumentTableGeneratorHelper.createTableRows<WarningMessage>(tableData, null, currentUnitSystem);
    this.addTableSlide({
      title: `${mainTitle} - Warning Messages`,
      subTitle,
      tableRows,
      presetColumnsWidths: { 0: 12, 1: 12, 2: 12, 3: 64 },
    });

    const createSectionSlide = (
      sectionTitle: string,
      createTableData: (summary: SummaryDto, currentUnitSystem: IUnitSystemDto) => ITableData<KeyValueTableRow>,
    ): void => {
      const tableData = createTableData(summary, currentUnitSystem);
      const tableRows = PptDocumentTableGeneratorHelper.createGenericKeyValueTableRowsWithTableData(tableData, currentUnitSystem);

      this.addKeyValueTableSlide({ title: `${mainTitle} - ${sectionTitle}`, subTitle, tableRows });
    };

    // gravel pack efficiencies
    createSectionSlide('Gravel Pack Efficiencies', CommonReportDataHelpers.createSummaryGravelPackEfficienciesSectionTableData);

    // gravel below packer
    createSectionSlide('Gravel Below Packer', CommonReportDataHelpers.createSummaryGravelBelowPackerSectionTableData);

    // maximum total pressure
    createSectionSlide('Maximum Total Pressure', CommonReportDataHelpers.createSummaryMaxTotalPressureSectionTableData);

    // final frac data
    if (wellTreatment === TreatmentType.High_Rate_Water_Pack) {
      createSectionSlide('Final Frac Data', CommonReportDataHelpers.createSummaryFinalFracDataSectionTableData);
    }

    // maximum friction pressure
    createSectionSlide('Maximum Friction Pressure', CommonReportDataHelpers.createSummaryMaxFrictionPressureSectionTableData);
  }
}
