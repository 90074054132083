import { createReducer, on } from '@ngrx/store';
import { dbMigrationInitialState, DbMigrationModuleReducerHelper } from './db-migration.reducer.helper';
import { IMigrationServerResponse } from '@dunefront/common/modules/db-migration/db-migration-module.actions';
import * as actions from './db-migration.actions';
import { dbConnectAction } from '../backend-connection/backend-connection.actions';

export const DbMigrationFeatureName = 'dbMigration';

export interface DbMigrationModuleState {
  migrationInfo: IMigrationServerResponse;
}

export const dbMigrationModuleReducer = createReducer<DbMigrationModuleState>(
  dbMigrationInitialState,
  on(actions.startDbMigration, (state): DbMigrationModuleState => DbMigrationModuleReducerHelper.startDbMigration(state)),
  on(
    actions.progressDbMigration,
    (state, action): DbMigrationModuleState => DbMigrationModuleReducerHelper.progressDbMigration(state, action.migrationServerResponse),
  ),
  on(dbConnectAction, actions.cancelMigration, (): DbMigrationModuleState => ({ ...dbMigrationInitialState })),
  on(actions.failedDbMigration, (): DbMigrationModuleState => ({ ...dbMigrationInitialState })),
);
