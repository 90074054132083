<div class="dialog-header">
  <h4>Reporting Chart Configuration</h4>
</div>
<div class="dialog-content">
  <app-chart-configuration-grid *ngIf="resultColumnsTableState.rows.length" #gridComponent [chartSeries]="resultColumnsTableState">
  </app-chart-configuration-grid>
</div>
<div class="dialog-footer">
  <button
    appButton
    class="btn btn-primary btn-sm"
    data-cy="reporting-save-as-template"
    [disabled]="!isSomethingSelected || isDataPro"
    [isUiLockable]="false"
    (buttonClick)="onSaveClicked()"
  >
    Save as Template
  </button>
  <button appButton class="btn btn-primary btn-sm" [isUiLockable]="false" (buttonClick)="onClearClicked()">Clear Data</button>
  <button appButton class="btn btn-primary btn-sm mr-auto" [isUiLockable]="false" (buttonClick)="onClearCustomizationsClicked()">
    Clear Customizations
  </button>
  <button
    appButton
    buttonType="cancel"
    class="btn btn-primary btn-sm"
    data-cy="reporting-cancel-btn"
    [isUiLockable]="false"
    (buttonClick)="onCancelClicked()"
  >
    Cancel
  </button>
  <button
    appButton
    buttonType="primary"
    class="btn btn-primary btn-sm"
    data-cy="reporting-generate-chart-btn"
    [isUiLockable]="false"
    (buttonClick)="onOkClicked()"
    [disabled]="!isSomethingSelected"
  >
    Generate Chart
  </button>
</div>
