<app-ribbon-menu class="menu" [ngClass]="{ recording: recordingState$ | async }" *ngIf="isMenuVisible"></app-ribbon-menu>
<div
  class="main-container"
  [ngClass]="{
    'shrink-left': isSideNavPinned$ | async,
    'shrink-right': isHelpPinned$ | async,
    recording: recordingState$ | async
  }"
>
  <div class="main-content">
    <router-outlet *ngIf="isReady$ | async"></router-outlet>
  </div>
</div>
