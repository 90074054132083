import { NgModule } from '@angular/core';
import { GlobalOptionsComponent } from './global-options/global-options.component';
import { ProjectOptionsComponent } from './project-options/project-options.component';
import { CommonModule } from '@angular/common';
import { TabbedPageModule } from '../../../common-modules/tabbed-page/tabbed-page.component';
import { PagePanelModule } from '../../../common-modules/page-panel/page-panel.component';
import { UnitsModule } from '../../../common-modules/units/units.module';
import { SettingsComponent } from './settings.component';
import { GeneralOptionsComponent } from './project-options/general-options/general-options.component';
import { FormInputModule } from '../../../shared/components/form-components/form-input/form-input.component';
import { CheckBoxModule } from '../../../shared/components/form-components/check-box/check-box.component';
import { RadioGroupModule } from '../../../shared/components/form-components/radio-group/radio-group.component';
import { FrictionOptionsComponent } from './project-options/friction-options/friction-options.component';
import { PackingOptionsComponent } from './project-options/packing-options/packing-options.component';
import { ThermalOptionsComponent } from './project-options/thermal-options/thermal-options.component';
import { ChartMarkerOptionsComponent } from './global-options/chart-marker-options/chart-marker-options.component';
import { ChartModule } from '../../../common-modules/chart/chart.module';
import { UnwrapDirectiveModule } from '../../../shared/directives/unwrap.directive';
import { ChartPerformanceOptionsComponent } from './global-options/chart-performance-options/chart-performance-options.component';
import { SelectModule } from '../../../shared/components/form-components/select/select.module';
import { ButtonModule } from '../../../shared/components/button/button.module';
import { RouterModule } from '@angular/router';
import { ChartAxisStyleOptionsComponent } from './global-options/chart-axis-style-options/chart-axis-style-options.component';
import { GenericOptionsComponent } from './global-options/generic-options.component';
import { ChartLegendOptionsComponent } from './global-options/chart-legend-options/chart-legend-options.component';
import { ChartSeriesOptionsComponent } from './global-options/chart-series-options/chart-series-options.component';
import { ChartAnnotationOptionsComponent } from './global-options/chart-annotation-options/chart-annotation-options.component';
import { ChartCopyOptionsComponent } from './global-options/chart-copy-options/chart-copy-options.component';
import { DeveloperSettingsComponent } from './developer-options/developer-settings.component';
import { GeneralDevOptionsComponent } from './developer-options/general-dev-options/general-dev-options.component';
import { DebugDevOptionsComponent } from './developer-options/debug-dev-options/debug-dev-options.component';
import { ChartGradientLineOptionsComponent } from './global-options/chart-gradient-line-options/chart-gradient-line-options.component';
import { FileOptionsComponentModule } from './file-options/file-options-component.module';
import { PerformanceOptionsComponent } from './project-options/performance-options/performance-options.component';
import { ChartVideoPerformanceOptionsComponent } from './global-options/chart-video-performance-options/chart-video-performance-options.component';
import { FluidProOptionsComponent } from './project-options/fluid-pro-options/fluid-pro-options.component';
import { HideOnFluidProModule } from '../../../shared/directives/hide-on-fluidpro';
import { HideOnDataProModule } from '../../../shared/directives/hide-on-datapro';

@NgModule({
  declarations: [
    SettingsComponent,
    GlobalOptionsComponent,
    ProjectOptionsComponent,
    PerformanceOptionsComponent,
    GeneralOptionsComponent,
    FrictionOptionsComponent,
    PackingOptionsComponent,
    ThermalOptionsComponent,
    ChartMarkerOptionsComponent,
    ChartAnnotationOptionsComponent,
    ChartGradientLineOptionsComponent,
    ChartPerformanceOptionsComponent,
    ChartAxisStyleOptionsComponent,
    ChartLegendOptionsComponent,
    GenericOptionsComponent,
    ChartSeriesOptionsComponent,
    ChartCopyOptionsComponent,
    DeveloperSettingsComponent,
    GeneralDevOptionsComponent,
    DebugDevOptionsComponent,
    ChartVideoPerformanceOptionsComponent,
    FluidProOptionsComponent,
  ],
  imports: [
    CommonModule,
    TabbedPageModule,
    PagePanelModule,
    UnitsModule,
    FormInputModule,
    CheckBoxModule,
    RadioGroupModule,
    ButtonModule,
    ChartModule,
    UnwrapDirectiveModule,
    SelectModule,
    RouterModule,
    FileOptionsComponentModule,
    HideOnFluidProModule,
    HideOnDataProModule,
  ],
})
export class SettingsModule {}
