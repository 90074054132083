import { ScenarioModuleState } from './scenario.reducer';
import { IDbConnectionOpenedResponse } from '@dunefront/common/modules/db-connection/db-connection.actions';
import { CrudResponse } from '@dunefront/common/modules/common.actions';
import { ArrayHelpers } from '@dunefront/common/common/array-helpers';
import { ScenarioConstants } from '@dunefront/common/modules/scenario/scenario.dto';
import { DictionaryWithArray } from '@dunefront/common/common/state.helpers';
import { Scenario, ScenarioFactory } from '@dunefront/common/modules/scenario/scenario';

export class ScenarioReducerHelper {
  public static scenarioModuleLoaded(
    state: ScenarioModuleState,
    response: { payload: IDbConnectionOpenedResponse; scenarioId: number },
  ): ScenarioModuleState {
    const scenarios = response.payload.scenarios.map((dto) => ScenarioFactory.fromDto(dto));
    let currentScenarioId = response.scenarioId;

    // if scenario is not in response, load last used, or empty scenario
    if (currentScenarioId === -1) {
      currentScenarioId =
        response.payload.fileSettings?.LastUsedScenarioId != null
          ? response.payload.fileSettings.LastUsedScenarioId
          : ScenarioConstants.EmptyScenarioId;
    }

    return {
      ...state,
      scenarios,
      currentScenarioId,
      isLoaded: true,
      isScenarioLoaded: false,
    };
  }

  public static onScenarioLoaded(state: ScenarioModuleState, scenarioId: number): ScenarioModuleState {
    if (state.currentScenarioId !== scenarioId) {
      return state;
    }
    return { ...state, isScenarioLoaded: true };
  }

  public static insertScenarioSuccess(state: ScenarioModuleState, action: CrudResponse): ScenarioModuleState {
    if (!state.currentScenarioId || !action.affectedRows.scenario || action.affectedRows.scenario.rows.length === 0) {
      return state;
    }
    return { ...state, scenarios: action.affectedRows.scenario.rows.map((row) => ScenarioFactory.fromDto(row)) };
  }

  public static updateScenarioSuccess(state: ScenarioModuleState, action: CrudResponse): ScenarioModuleState {
    if (!state.currentScenarioId || !action.affectedRows.scenario || action.affectedRows.scenario.rows.length === 0) {
      return state;
    }
    return { ...state, scenarios: action.affectedRows.scenario.rows.map((row) => ScenarioFactory.fromDto(row)) };
  }

  public static deleteScenarioSuccess(state: ScenarioModuleState, action: CrudResponse): ScenarioModuleState {
    if (!state.currentScenarioId || !action.affectedRows.scenario || action.affectedRows.scenario.deletedIds.length === 0) {
      return state;
    }
    const scenarios = ArrayHelpers.deleteRowsWithIds(state.scenarios, action.affectedRows.scenario.deletedIds);
    return { ...state, scenarios };
  }

  public static reorderScenarios(
    state: ScenarioModuleState,
    action: {
      scenarios: Scenario[];
      from: number;
      to: number;
      changedKey: keyof Scenario;
    },
  ): ScenarioModuleState {
    const scenariosDict = DictionaryWithArray.create(action.scenarios, 'Id');
    const orderedIds = ArrayHelpers.move(
      scenariosDict.ids.map((id) => +id),
      action.from,
      action.to,
    );
    const scenarios = orderedIds.map((id, index) => {
      return { ...scenariosDict.dict[id], SortOrder: index };
    }) as Scenario[];
    return { ...state, scenarios };
  }
}
