import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, NgModule, Output } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TooltipModule } from 'primeng/tooltip';
import { CdkDragDrop, DragDropModule, moveItemInArray } from '@angular/cdk/drag-drop';
import { DraggableMenuItem } from '../../shared/components/left-nav/left-nav.helpers';
import { IReorderEventArgs } from '../../shared/components/left-nav/left-nav-item/left-nav-item.component';
import { HelpButtonComponent } from '../../shared/components/help-button/help-buton.component';

@Component({
  selector: 'app-tabbed-page',
  templateUrl: './tabbed-page.component.html',
  styleUrls: ['./tabbed-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabbedPageComponent {
  @Input() public tabs: TabMenuItem[] | null = [];

  @Input() public isReorderingEnabled = false;

  @Output()
  public reordered = new EventEmitter<IReorderEventArgs>();

  public get hasTabs(): boolean {
    return !!this.tabs && this.tabs.length > 0;
  }

  public onDoubleClick(action?: () => void): void {
    action?.();
  }

  public trackByLink(index: number, tab: TabMenuItem): string {
    return tab.routerLink;
  }

  public trackByFn(index: number, item: TabMenuItem): string {
    return item.name;
  }

  public onDrop(event: CdkDragDrop<DraggableMenuItem[]>): void {
    if (event.currentIndex === 0) {
      event.currentIndex = 1;
    }
    moveItemInArray(this.tabs ?? [], event.previousIndex, event.currentIndex);
    this.reordered.emit({ draggableType: '', prevIndex: event.previousIndex, currentIndex: event.currentIndex });
  }

  public isTabNameCropped(tab: HTMLElement): boolean {
    const a = tab.querySelector('a');
    if (!a) {
      return false;
    }
    return a.scrollWidth > a.offsetWidth;
  }
}

export interface TabMenuItem {
  routerLink: string;
  name: string;
  disabled?: boolean;
  noClick?: boolean;
  hasError?: boolean;
  dbClick?: () => void;
  isDraggable?: boolean;
}

@NgModule({
  imports: [CommonModule, RouterModule, TooltipModule, DragDropModule, HelpButtonComponent],
  declarations: [TabbedPageComponent],
  exports: [TabbedPageComponent],
})
export class TabbedPageModule {}
