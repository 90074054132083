import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { DbDependentComponent } from '../db-connection/db-dependent.component';
import { Store } from '@ngrx/store';
import { ScreenService } from '../../shared/services';
import {
  getChartUserAddons,
  reloadChartAfterXAxisShiftUndoRedoAction,
  resetChartAxisLimitsProperties,
} from '../../+store/reporting/reporting.actions';
import { IChartDataDto, IChartDataDtoColumn } from '@dunefront/common/modules/reporting/dto/chart-data.dto';
import { getChartUserAddonsDict, getUnmappedRestrictedReportingChartTimeVolMode } from '../../+store/reporting/reporting.selectors';
import { ChartState } from '../../+store/reporting/reporting-module.state';
import { ChartSeriesDto } from '@dunefront/common/modules/reporting/dto/chart-series.dto';
import { ChartUserMarkersService } from './chart-controller-providers/chart-user-markers.service';
import { ChartAxisPropertiesService } from './chart-controller-providers/chart-axis-properties.service';
import { getUiChartZoomOriginalSize } from '../../+store/ui/ui.selectors';
import { ChartTimeVolMode, GetChartDataRequestType } from '@dunefront/common/modules/reporting/reporting-module.actions';
import { DataType } from '@dunefront/common/dto/data-storage';
import { BehaviorSubject, combineLatest, first, merge, Observable, Subject, withLatestFrom } from 'rxjs';
import { DictionaryWithArray, filterNil, observableToBehaviorSubject } from '@dunefront/common/common/state.helpers';
import { distinctUntilChanged, filter, map, pairwise, skip } from 'rxjs/operators';
import { ChartEditRangeService } from './chart-controller-providers/chart-edit-range.service';
import { getCurrentRangeVerticalShifts, selectIsCurrentRangeInEditingState } from '../../+store/range/range.selectors';
import { ModalService } from '../modals/modal.service';
import { ChartAutoShiftService } from './chart-controller-providers/chart-auto-shift.service';
import { ChartVerticalShiftService } from './chart-controller-providers/chart-vertical-shift.service';
import { ChartHorizontalShiftService } from './chart-controller-providers/chart-horizontal-shift.service';
import { MarkersServiceBase } from './chart-controller-providers/markers-service-base';
import { ChartDataSourceType, ChartDto } from '@dunefront/common/modules/reporting/dto/chart.dto';
import { ChartPropertiesService } from './chart-controller-providers/chart-properties.service';
import { ChartSeriesTemplatesService } from './chart-controller-providers/chart-series-templates.service';
import { ChartLoadingStatus } from '../../+store/reporting/model/reporting.factory';
import { Actions, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { ChartAnnotationsService } from './chart-controller-providers/chart-annotations.service';
import { areRangesEqual, chartSeriesChanged, getColumnKey, rangeContainsRange } from './chart-component-helpers/chart-misc-helpers';
import { AllowedXYShiftAxis, IArgumentRange, IAutoYAxisShiftParams } from './chart-component-helpers/chart-types';
import { ResetZoomMode } from '../../+store/ui/ui.actions';
import { getAllStorageColumnsArray, getStorageFilesArray } from '../../+store/data-storage/data-storage.selectors';
import { ChartAxis } from '@dunefront/common/modules/reporting/dto/chart-axis-property.dto';
import { ChartSeriesVisibilityManager } from './chart-component-helpers/chart-series-visibility-manager';
import { toChartAddonsWithConverter } from './chart-component-helpers/chart-addons-helpers';
import { ChartDtoUpdateNotificationService } from './chart-dto-update-notification.service';
import { ChartGradientLinesService } from './chart-controller-providers/chart-gradient-lines.service';

export interface ChartControllerFetchDataPayload {
  src: string;
  requestType: GetChartDataRequestType;
  argumentStart?: number;
  argumentEnd?: number;
  isPrimaryArgument?: boolean;
  isPrimaryArgumentRelative?: boolean;
}

export interface ReloadInitialChartDataArgs {
  src: string;
  forceFullReload?: boolean;
}

export type ChartControllerFetchData = (payload: ChartControllerFetchDataPayload) => void;

export interface ChartControllerConfig {
  loadData?: ChartControllerFetchData;
  timeVolMode?: ChartTimeVolMode;
  createCustomMarkersService?: () => MarkersServiceBase;
  resetAxisLimitsOnRangeChange?: boolean;
  fetchInitialData$?: Observable<void>;
}

export enum MarkerMode {
  Disabled = 0,
  UserMarkers = 1,
  EditRanges = 2,
  CustomHandler = 3,
}

const getRelativeArgumentRange = (data: IChartDataDto | undefined): IArgumentRange | undefined => {
  if (data?.ArgumentStart == null || data?.ArgumentEnd == null || data?.StartDate == null) {
    return undefined;
  }

  return { argumentStart: data.ArgumentStart - data.StartDate, argumentEnd: data.ArgumentEnd - data.StartDate };
};

const calcArg = (date: number, offset: number | undefined): number | undefined => (offset == null ? offset : offset + date);

const loadingText = 'Loading...';

@Component({
  selector: 'app-chart-controller',
  templateUrl: './chart-controller.component.html',
  styleUrls: ['./chart-controller.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ChartSeriesTemplatesService],
})
export class ChartControllerComponent extends DbDependentComponent implements OnInit, OnDestroy {
  @Input()
  public set chartId(newChartId: number | undefined) {
    this.chartId$.next(newChartId);
  }

  public get chartId(): number | undefined {
    return this.chartId$.value;
  }

  @Input() public allowRecording = false;
  @Input() public config?: ChartControllerConfig;
  @Input() public chartDisplayName = '';
  @Input() public drawableProviderId?: string;
  @Input() public rotateChart = false;
  @Input() public reverseArgument = false;
  @Input() public displayLegend = true;
  @Input() public allowedChangingXYShift: AllowedXYShiftAxis = AllowedXYShiftAxis.none;
  @Input() public allowCompareScenarios = false;
  @Input() public overlayText = '';
  @Input() public dataCy = '';
  @Input() public tension = 0;

  @Input()
  public set isVerticalShiftEnabled(value: boolean) {
    this.isVerticalShiftEnabled$.next(value);
  }

  public get isVerticalShiftEnabled(): boolean {
    return this.isVerticalShiftEnabled$.value;
  }

  @Output() public hiddenColumnsChange = new EventEmitter<IChartDataDtoColumn[]>();

  @Input()
  public set hiddenColumns(newHiddenColumns: IChartDataDtoColumn[]) {
    this.chartSeriesVisibilityManager.reset(newHiddenColumns, this);
  }

  @Input()
  public set ignoreArgumentLimits(newValue: boolean) {
    this.axisPropertiesService.ignoreArgumentLimits = newValue;
  }

  @Input()
  public set chartState(newChartState: ChartState | undefined | null) {
    if (newChartState == null) {
      return;
    }

    // update loading status
    this.chartLoadingStatus$.next(newChartState.chartLoadingStatus);

    if (
      // ignore data if not present
      newChartState.chartData == null ||
      // not loaded
      newChartState.chartLoadingStatus !== ChartLoadingStatus.loaded ||
      // OriginalSize button has been used
      (this.chartData$.value && this.originalSizeSinceDataRequested && newChartState.requestType === GetChartDataRequestType.Zoomed)
    ) {
      return;
    }

    // set chart data if not yet set or not time/vol based or series changed
    if (
      newChartState.requestType === GetChartDataRequestType.Initial ||
      (newChartState.chartData.ArgumentDataType !== DataType.Time && newChartState.chartData.ArgumentDataType !== DataType.Pump_Volume) ||
      chartSeriesChanged(this.chartSeriesDisplayed, newChartState.chartSeries)
    ) {
      if (newChartState.chartData !== this.chartData$.value) {
        this.chartData$.next(newChartState.chartData);
        this.xAxisShiftedSinceDataReload = false;
      }

      this.chartSeriesDisplayed = newChartState.chartSeries;
    }

    if (newChartState.chartData !== this.chartZoomData$.value) {
      this.chartZoomData$.next({ ...newChartState.chartData });
    }

    this.originalSizeSinceDataRequested = false;
  }

  // chart data
  public chartData$ = new BehaviorSubject<IChartDataDto | undefined>(undefined);
  public chartZoomData$ = new BehaviorSubject<IChartDataDto | undefined>(undefined);
  public chartId$ = new BehaviorSubject<number | undefined>(undefined);

  public chartSeriesVisibilityManager = new ChartSeriesVisibilityManager();

  private xAxisShiftedSinceDataReload = false;
  private isVerticalShiftEnabled$ = new BehaviorSubject(false);

  /**
   * Stream of ChartUserAddons for current chart with conversion
   * @private
   */
  private addonsWithConverter$ = combineLatest([this.chartZoomData$.pipe(filterNil()), this.store.select(getChartUserAddonsDict)]).pipe(
    map(([chartData, chartUserAddonsDict]) => toChartAddonsWithConverter(chartUserAddonsDict, chartData)),
  );

  private getChartAddonsDict$ = this.store
    .select(getChartUserAddonsDict)
    .pipe(map((chartUserAddonsDict) => DictionaryWithArray.get(chartUserAddonsDict, this.chartId)));

  private argumentAxisLimits$: Observable<IArgumentRange | null> = this.getChartAddonsDict$.pipe(
    map((addons) => addons?.chartAxisProperties),
    filterNil(),
    map((axisProps) => axisProps.find((props) => props.axis === ChartAxis.Argument)),
    distinctUntilChanged(),
    map((argProps) => {
      const argumentStart = argProps?.manualLimit === true ? argProps?.min : null;
      const argumentEnd = argProps?.manualLimit === true ? argProps?.max : null;

      return argumentStart != null && argumentEnd != null ? { argumentStart, argumentEnd } : null;
    }),
  );

  public readonly horizontalShiftService = new ChartHorizontalShiftService(this.store, this.actions$, () => {
    this.xAxisShiftedSinceDataReload = true;
    this.reloadInitialChartData$.next({ src: 'horizontalShiftService' });
  });

  public verticalShifts$ = observableToBehaviorSubject(
    combineLatest(
      [this.store.select(getCurrentRangeVerticalShifts), this.isVerticalShiftEnabled$],
      (verticalShifts, isVerticalShiftEnabled) => (isVerticalShiftEnabled ? verticalShifts : []),
    ),
    [],
  );
  public readonly verticalShiftService = new ChartVerticalShiftService(
    this.store,
    this.currentRange$,
    this.verticalShifts$,
    this.currentScenarioId$,
  );

  public readonly autoShiftService = new ChartAutoShiftService(this.store);

  public markersService?: MarkersServiceBase;
  public annotationsService: ChartAnnotationsService;
  public gradientLinesService: ChartGradientLinesService;
  public axisPropertiesService: ChartAxisPropertiesService;
  public chartLegendPropertiesService: ChartPropertiesService;

  public chartDto$: Observable<ChartDto>;
  public chartLoadingStatus$ = new BehaviorSubject<ChartLoadingStatus>(ChartLoadingStatus.notLoaded);
  public isZoomed = false;

  // indicates if data was already fetched from BE
  private isDataReady$ = combineLatest([this.chartLoadingStatus$, this.chartData$]).pipe(
    map(([chartLoadingStatus, data]) => chartLoadingStatus !== ChartLoadingStatus.notLoaded && data != null),
  );

  // indicates if downloaded ChartDataDto is not empty (has correct data to be displayed on chart)
  private isDataPresent$ = combineLatest([this.chartData$]).pipe(
    map(([data]) => data != null && data.ChartDataSets != null && data.ChartDataSets.some((dataSet) => dataSet.ChartDataRows.length > 0)),
  );

  // shown instead of chart component
  public waitingOverlayText$ = combineLatest([this.isDataReady$, this.isDataPresent$]).pipe(
    map(([isDataReady, isDataPresent]) => {
      if (this.overlayText.length > 0) {
        return this.overlayText;
      }

      if (isDataReady && !isDataPresent) {
        return 'There is no data available for the selected chart configuration';
      }

      return loadingText;
    }),
  );

  private getAllStorageColumns$ = observableToBehaviorSubject(this.store.select(getAllStorageColumnsArray), []);
  public storageFiles$ = observableToBehaviorSubject(this.store.select(getStorageFilesArray), []);

  public shouldDisplayChart$: Observable<boolean>;

  private isEditingRange$ = this.store.select(selectIsCurrentRangeInEditingState);

  private reloadInitialChartData$ = new Subject<ReloadInitialChartDataArgs>();

  private chartSeriesDisplayed: ChartSeriesDto[] = [];
  private initialArgumentRange?: IArgumentRange;
  private currentArgumentRange?: IArgumentRange;
  private originalSizeSinceDataRequested = false; // indicates that AutoSize button has been pressed since data requested
  private currentChartId?: number;

  constructor(
    private modalService: ModalService,
    store: Store,
    cdRef: ChangeDetectorRef,
    protected ngZone: NgZone,
    protected resizeService: ScreenService,
    protected actions$: Actions,
    public chartSeriesTemplatesService: ChartSeriesTemplatesService,
    public chartDtoUpdateNotificationService: ChartDtoUpdateNotificationService,
  ) {
    super(store, cdRef);

    this.chartLegendPropertiesService = new ChartPropertiesService(store, modalService, this.chartId$.pipe(filterNil()));
    this.annotationsService = new ChartAnnotationsService(store, modalService, this.addonsWithConverter$);
    this.gradientLinesService = new ChartGradientLinesService(store, modalService, this.addonsWithConverter$);
    this.chartDto$ = this.chartLegendPropertiesService.chart;

    const addonsWithConverterForAxisProps$ = combineLatest([
      this.chartData$.pipe(filterNil()),
      this.store.select(getChartUserAddonsDict),
    ]).pipe(map(([chartData, chartUserAddonsDict]) => toChartAddonsWithConverter(chartUserAddonsDict, chartData)));

    this.axisPropertiesService = new ChartAxisPropertiesService(store, modalService, addonsWithConverterForAxisProps$);

    this.shouldDisplayChart$ = combineLatest([this.isDataReady$, this.isDataPresent$, this.axisPropertiesService.isReady$]).pipe(
      map(([isDataReady, dataPresent, isAxisPropertiesServiceReady]) => isDataReady && dataPresent && isAxisPropertiesServiceReady),
    );
  }

  public override ngOnInit(): void {
    super.ngOnInit();

    this.subscription.add(
      this.actions$
        .pipe(
          ofType(reloadChartAfterXAxisShiftUndoRedoAction),
          filter(
            () =>
              this.chartData$.value != null &&
              [
                ChartDataSourceType.ChartSourceGaugeData,
                ChartDataSourceType.ChartSourceReportingTab,
                ChartDataSourceType.ChartSourceTrendAnalysis,
              ].includes(this.chartData$.value.ChartDataSourceType),
          ),
        )
        .subscribe(() => {
          this.xAxisShiftedSinceDataReload = true;
          this.reloadInitialChartData$.next({ src: 'reloadChartAfterXAxisShiftUndoRedoAction', forceFullReload: true });
        }),
    );

    this.subscription.add(
      this.reloadInitialChartData$.pipe(withLatestFrom(this.argumentAxisLimits$)).subscribe(([reloadDataArgs, argumentAxeLimits]) => {
        const { src, forceFullReload } = reloadDataArgs;

        if (this.currentArgumentRange && !forceFullReload) {
          // it's in zoomed in mode, and high-res data is loaded
          this.onArgumentRangeChanged(this.currentArgumentRange);
        } else if (this.isZoomed && !forceFullReload) {
          // it's zoomed, but we don't have high-res data yet
          // don't reload anything and wait patiently for currentArgRange to change
        } else {
          this.config?.loadData?.({
            src,
            requestType: GetChartDataRequestType.Initial,
            isPrimaryArgumentRelative: true,
            isPrimaryArgument: true,
            argumentStart: argumentAxeLimits?.argumentStart,
            argumentEnd: argumentAxeLimits?.argumentEnd,
          });
        }
      }),
    );

    const fetchInitialData$ = this.config?.fetchInitialData$;
    if (fetchInitialData$ != null) {
      this.subscription.add(
        combineLatest([fetchInitialData$, this.argumentAxisLimits$.pipe(first())]).subscribe(() => {
          this.reloadInitialChartData$.next({ src: 'fetchInitialData$' });
        }),
      );
    }

    this.subscription.add(
      this.argumentAxisLimits$
        .pipe(
          pairwise(),
          filter(([a, b]) => a?.argumentStart !== b?.argumentStart || a?.argumentEnd !== b?.argumentEnd),
        )
        .subscribe(() => this.reloadInitialChartData$.next({ src: 'argumentAxisLimits$', forceFullReload: true })),
    );

    this.subscription.add(
      this.store
        .select(getUnmappedRestrictedReportingChartTimeVolMode)
        .pipe(filterNil(), distinctUntilChanged(), skip(1))
        .subscribe(() =>
          this.reloadInitialChartData$.next({
            src: 'chartTimeVolMode changed',
            forceFullReload: true,
          }),
        ),
    );

    this.subscription.add(
      this.chartDtoUpdateNotificationService.onChartConfigUpdated$.subscribe(() =>
        this.reloadInitialChartData$.next({
          src: 'onChartConfigUpdated$',
          forceFullReload: true,
        }),
      ),
    );

    this.subscription.add(
      this.chartId$
        .pipe(
          filterNil(),
          concatLatestFrom(() => this.store.select(getChartUserAddonsDict)),
        )
        .subscribe(([chartId, chartUserAddonsDict]) => {
          // set visible chartId
          this.setVisibleChartId(chartId);

          // fetch addons for chart if not present in the store
          const addons = DictionaryWithArray.get(chartUserAddonsDict, chartId);
          if (addons == null) {
            this.store.dispatch(
              getChartUserAddons({
                chartId,
                scenarioId: this.currentScenarioId$.value,
              }),
            );
          }
        }),
    );

    this.subscription.add(
      this.store
        .select(getUiChartZoomOriginalSize)
        .pipe(
          filter((action) => action.mode === ResetZoomMode.ALL || action.mode === ResetZoomMode.CHART),
          skip(1),
          distinctUntilChanged(),
        )
        .subscribe(() => {
          this.originalSizeSinceDataRequested = true;

          // force to reload all data
          if (this.xAxisShiftedSinceDataReload) {
            this.reloadInitialChartData$.next({
              src: 'getUiChartZoomOriginalSize',
              forceFullReload: true,
            });
          } else {
            const chartData = this.chartData$.value;
            const dataToReload = !this.xAxisShiftedSinceDataReload && chartData ? { ...chartData } : undefined;

            this.chartZoomData$.next(dataToReload);
            this.chartData$.next(dataToReload);
          }
        }),
    );

    const currentRangeChanged$ = this.currentRange$.pipe(
      pairwise(),
      concatLatestFrom(() => this.isEditingRange$),
      filter(([[previous, current], isEditing]) => {
        const currentIsDefined = current != null;
        const idChanged = current?.Id !== previous?.Id;
        // when performing Undo on Range Editing
        const limitsChangedWhenNotEditing =
          isEditing === false && (current?.RangeStart !== previous?.RangeStart || current?.RangeEnd !== previous?.RangeEnd);

        return currentIsDefined && (idChanged || limitsChangedWhenNotEditing);
      }),
    );

    // This need to be triggered only when current range has been changed or edit mode changed
    // We're skipping initial value because it is always triggered when page is loading.
    this.subscription.add(
      merge(currentRangeChanged$, this.isEditingRange$)
        .pipe(skip(1))
        .subscribe(() => {
          if (this.config?.resetAxisLimitsOnRangeChange === true) {
            this.store.dispatch(resetChartAxisLimitsProperties());
          }
          this.chartData$.next(undefined);
          this.config?.loadData?.({
            src: 'editRange or current range changed',
            requestType: GetChartDataRequestType.Initial,
          });
        }),
    );

    this.subscription.add(
      combineLatest([this.isEditingRange$]).subscribe(([isEditingRange]) => {
        if (this.config?.createCustomMarkersService != null) {
          this.setNewMarkersService(this.config?.createCustomMarkersService());
        } else if (isEditingRange && this.markersService?.mode !== MarkerMode.EditRanges) {
          this.setNewMarkersService(new ChartEditRangeService(this.store, this.currentRange$, this.chartData$));
        } else {
          this.setNewMarkersService(new ChartUserMarkersService(this.store, this.modalService, this.addonsWithConverter$));
        }
      }),
    );

    this.subscription.add(
      this.chartSeriesVisibilityManager.onVisibleColumnsChanged.subscribe((event) => {
        if (event.eventSource !== this) {
          const chartData = this.chartData$.value;
          if (chartData == null) {
            return;
          }

          const hiddenColumns = chartData.ChartDataColumns.filter((column) => event.hiddenColumnKeys.includes(getColumnKey(column)));

          this.hiddenColumnsChange.emit(hiddenColumns);
        }
      }),
    );
  }

  private setVisibleChartId(chartId: number): void {
    this.currentChartId = chartId;
  }

  public onInitialArgumentRange(range: IArgumentRange): void {
    this.initialArgumentRange = range;
    this.currentArgumentRange = range;
  }

  //region Chart data

  public onArgumentRangeChanged(range: IArgumentRange): void {
    const xAxisShifted = this.xAxisShiftedSinceDataReload;

    // if xAxisShift was not change, and range is same as current one - skip
    if (!xAxisShifted && areRangesEqual(this.currentArgumentRange, range)) {
      return;
    }

    this.currentArgumentRange = range;

    const chartZoomData = this.chartZoomData$.value;
    const chartData = this.chartData$.value;
    const startDate = chartData?.StartDate;
    const initialArgumentRange = this.initialArgumentRange;

    if (!chartData || !chartZoomData || !initialArgumentRange || startDate == null) {
      return;
    }

    if (!xAxisShifted) {
      if (rangeContainsRange(range, initialArgumentRange)) {
        // if reset to original scale or zooming so range is covered by original scale, use the original chart data
        this.chartZoomData$.next({ ...chartData }); // needed to trigger onChanges in chart component
        return;
      }

      const zoomDataRange = getRelativeArgumentRange(chartZoomData);
      if (chartZoomData.IsMaxResolution && zoomDataRange && rangeContainsRange(zoomDataRange, range)) {
        // don't fetch data because requested range is already at max resolution
        return;
      }
    }

    this.originalSizeSinceDataRequested = false;
    const src = `onArgumentRangeChanged - isXAxisShiftChanged: ${xAxisShifted}, start: ${range.argumentStart}, end: ${range.argumentEnd}`;
    this.config?.loadData?.({
      src,
      requestType: GetChartDataRequestType.Zoomed,
      argumentStart: chartData.IsPrimaryArgument ? calcArg(startDate, range.argumentStart) : range.argumentStart,
      argumentEnd: chartData.IsPrimaryArgument ? calcArg(startDate, range.argumentEnd) : range.argumentEnd,
    });
  }

  public override ngOnDestroy(): void {
    this.markersService?.dispose();
    this.annotationsService.dispose();
    this.gradientLinesService.dispose();
    this.axisPropertiesService.dispose();
    this.horizontalShiftService.dispose();

    // release objects held by observableToBehaviorSubject
    this.storageFiles$ = null as any;
    this.getAllStorageColumns$ = null as any;
    this.verticalShifts$ = null as any;

    super.ngOnDestroy();
  }

  // endregion

  private setNewMarkersService(markersService: MarkersServiceBase): void {
    this.markersService?.dispose();

    this.markersService = markersService;

    this.cdRef.markForCheck();
  }

  // shown on a top of chart component
  public get chartOverlayText(): string {
    return this.chartLoadingStatus$.value === ChartLoadingStatus.loading ? loadingText : this.overlayText;
  }

  public onAutoYShift(payload: IAutoYAxisShiftParams): void {
    this.autoShiftService.onYAutoShift(
      payload,
      this.getAllStorageColumns$.value,
      this.storageFiles$.value,
      this.currentScenarioId$.value,
      this.currentRangeId$.value,
    );
  }
}
