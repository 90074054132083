import { ChangeDetectionStrategy, Component } from '@angular/core';
import * as dayjs from 'dayjs';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import * as customParseFormatPlugin from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormatPlugin);

@Component({
  selector: 'app-datetime-modal-content',
  template: `
    <div class="dialog-header" *ngIf="title">
      <h4>{{ title }}</h4>
    </div>
    <div class="dialog-content">
      <p [innerHTML]="message"></p>
      <p>
        <app-date-time-input
          [value]="date"
          (primitiveValueChanged)="date = $event.value"
          (isValid)="isValid = $event"
          [isUiLockable]="false"
          [showTimePicker]="showTimePicker"
        ></app-date-time-input>
      </p>
    </div>
    <div class="dialog-footer">
      <button
        appButton
        type="button"
        [dialogRef]="ref"
        class="btn btn-light btn-sm"
        buttonType="cancel"
        data-cy="datetime-modal-cancel-btn"
        (buttonClick)="ref.close()"
        [isUiLockable]="false"
      >
        {{ cancelButtonText }}
      </button>
      <button
        appButton
        type="button"
        [dialogRef]="ref"
        class="btn btn-primary btn-sm"
        buttonType="primary"
        data-cy="datetime-modal-confirm-btn"
        (buttonClick)="okClicked()"
        [isUiLockable]="false"
        [disabled]="!isValid"
      >
        {{ okButtonText }}
      </button>
    </div>
  `,
  styles: ['.btn { min-width: 50px; } .modal-body { overflow-y: auto; max-height: 300px; }'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateTimeModalContentComponent {
  public title: string;
  public message: string;

  public okButtonText = 'OK';
  public cancelButtonText = 'Cancel';

  public showTimePicker = false;
  public date = new Date();
  public isValid = true;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
  ) {
    this.title = config.data.title;
    this.message = config.data.message;
    this.showTimePicker = config.data.showTimePicker;

    if (config.data.presetTime) {
      this.date = new Date(config.data.presetTime * 1000);
    }
  }

  public okClicked(): void {
    this.ref.close(this.date);
  }
}
