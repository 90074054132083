import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import * as uiActions from '../../../+store/ui/ui.actions';
import { DbDependentComponent } from '../../../common-modules/db-connection/db-dependent.component';
import { trendAnalysisEnabled } from '../../../+store/range/range.selectors';

@Component({
  selector: 'app-trend-analysis',
  templateUrl: './trend-analysis.component.html',
  styleUrls: ['./trend-analysis.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrendAnalysisComponent extends DbDependentComponent {
  public trendAnalysisEnabled$ = this.store.select(trendAnalysisEnabled);

  constructor(
    store: Store,
    cdRef: ChangeDetectorRef,
    protected route: ActivatedRoute,
    private router: Router,
  ) {
    super(store, cdRef);
    store.dispatch(uiActions.setTrendAnalysisTabAction({ tab: 'trend-analysis' }));
    this.onHelpChange('trend', 'trend-analysis');
  }
}
