import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { ModalService } from '../../../../../common-modules/modals/modal.service';
import { createTableRow, ITableRow, ITableState } from '@dunefront/common/common/common-grid.interfaces';
import { IGridColumnConfig } from '../../../../../shared/components/grid/grid.interfaces';
import { GridConfig } from '../../../../../shared/components/grid/grid-config';
import * as actions from '../../../../../+store/psd-analysis/psd-analysis.actions';
import { updateLastPSDInputType } from '../../../../../+store/psd-analysis/psd-analysis.actions';
import { cancelChange } from '../../../../../+store/store.helpers';
import { PSDInputType } from '@dunefront/common/modules/psd-analysis/dto/psd.dto';
import { IScreenSize, ScreenService } from '../../../../../shared/services';
import { notEmpty } from '@dunefront/common/common/state.helpers';
import { getPSDSelectData, IPSDSelectorData } from '../../../../../+store/psd-analysis/psd-analysis.selectors';
import { GridContainerComponent } from '../../../../../shared/components/grid/grid-container.component';
import { IRadioItem } from '@dunefront/common/common/radio.helpers';
import { GridResizeService } from '../../../../../shared/services/grid-resize.service';
import { ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';
import { IDeleteRowsProps, IInsertRowsProps, IUpdateTableRowsProps } from '@dunefront/common/common/common-store-crud.interfaces';
import { PsdReading } from '@dunefront/common/modules/psd-analysis/model/psd-readings/psd-reading';
import { PSD } from '@dunefront/common/modules/psd-analysis/model/psd/psd';
import { PsdReadingsFactory } from '@dunefront/common/modules/psd-analysis/model/psd-readings/psd-readings.factory';
import { getPsdDataGridConfig } from './psd-data-grid.config';
import { getIsOneTimeInstructionShown } from '../../../../../+store/ui/ui.selectors';
import { firstValueFrom } from 'rxjs';
import { setOneTimeInstructionPopupShownAction } from '../../../../../+store/ui/ui.actions';
import { OneTimeInstructionType } from '../../../../../+store/ui/ui-module.state';
import { OneTimeMessageHelpers } from '../../../../../+store/ui/one-time-message-helpers';
import { InsertLocation } from '@dunefront/common/modules/common.interfaces';

const radioRowHeight = 42;
const buttonsRowHeight = 42;
const defaultLayoutGridGap = 16;

@Component({
  selector: 'app-psd-data-grid',
  templateUrl: './psd-data-grid.component.html',
  styleUrls: ['./psd-data-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PsdDataGridComponent extends GridContainerComponent<PsdReading> implements OnInit {
  @Input() public psdReadings: ITableState<PsdReading> = { rows: [], isValid: true };
  @Input() public psd!: PSD;
  @Output() public psdInputTypeValueChange = new EventEmitter<ObjectChangeProp<PSD>>();

  public override async ngOnInit(): Promise<void> {
    await super.ngOnInit();
    this.store.dispatch(updateLastPSDInputType({ PSDInputType: this.psd.PSDInputType }));
  }

  public gridRadioItems: IRadioItem<number>[] = [
    {
      value: PSDInputType.Weight,
      text: 'Weight',
    },
    {
      value: PSDInputType.PercentWeight,
      text: '% Weight',
    },
    {
      value: PSDInputType.CumulativePercentWeight,
      text: 'Cumulative % Weight',
    },
  ];

  constructor(
    store: Store,
    cdRef: ChangeDetectorRef,
    el: ElementRef,
    protected modalService: ModalService,
    protected resizeService: ScreenService,
    gridResizeService: GridResizeService,
  ) {
    super(store, cdRef, el, new PSDReadingsGridConfig(store, modalService), gridResizeService);
    this.margin = radioRowHeight + buttonsRowHeight + 2 * defaultLayoutGridGap;
  }

  public override resize(size?: IScreenSize): void {
    if (size && size.resizeType === 'x') {
      return;
    }

    this.height = 0;
    this.cdRef.markForCheck();
    super.resize();
  }

  protected getColumns(): IGridColumnConfig<PsdReading>[] {
    const isWeight = this.psd?.PSDInputType === PSDInputType.Weight;
    const isPercent = this.psd?.PSDInputType === PSDInputType.PercentWeight;
    const isCumulative = this.psd?.PSDInputType === PSDInputType.CumulativePercentWeight;
    return getPsdDataGridConfig(isWeight, isPercent, isCumulative);
  }

  public async onImport(): Promise<void> {
    await this.grid.onImportClicked();
  }

  public async onInsert(): Promise<void> {
    await this.grid.onInsertClicked(false);
  }

  public async onDelete(): Promise<void> {
    await this.grid.onDeleteClicked();
  }

  public async onInputTypeChanged(event: ObjectChangeProp<PSD>): Promise<void> {
    const oneTimeInstructionState = await firstValueFrom(this.store.select(getIsOneTimeInstructionShown));
    const instructionType = OneTimeInstructionType.changePSDInputType;
    this.store.dispatch(setOneTimeInstructionPopupShownAction({ instructionType }));

    if (OneTimeMessageHelpers.shouldShowInstruction(instructionType, oneTimeInstructionState)) {
      const changeConfirmed = await this.modalService.showConfirm(OneTimeMessageHelpers.getMessage(instructionType), 'Warning');

      if (!changeConfirmed) {
        const cancelGenerator = cancelChange(this.psd, event, this.cdRef);
        this.psd = cancelGenerator.next().value;
        this.psd = cancelGenerator.next().value;
        return;
      }
    }

    if (event.key === 'PSDInputType') {
      this.store.dispatch(updateLastPSDInputType({ PSDInputType: event.value as PSDInputType }));
    }
    this.psdInputTypeValueChange.emit(event);
  }
}

export class PSDReadingsGridConfig extends GridConfig<PsdReading> {
  private psdData!: IPSDSelectorData | undefined;

  public override createEmptyModel(scenarioId: number): PsdReading | undefined {
    if (!this.psdData) {
      return undefined;
    }
    return PsdReadingsFactory.createEmpty(scenarioId, this.psdData.selectedPSD.Id);
  }

  constructor(
    private store: Store,
    modalService: ModalService,
  ) {
    super(modalService);
    this.headerText = 'PSD Test Data';
    this.subscription.add(
      notEmpty(store.select(getPSDSelectData)).subscribe((psdData) => {
        this.psdData = psdData;
      }),
    );
  }

  public override updateRowsAction(props: IUpdateTableRowsProps<PsdReading>): void {
    if (props.colIds?.includes('SieveDescription') && props.rows.some((row) => row.rowData.SieveDescription === 'Pan')) {
      this.modalService.showAlert('This description cannot be Pan.', 'Information').then(() => null);
      return;
    }
    this.store.dispatch(actions.updatePsdReadingsRow(props));
  }

  public override insertRowAction(
    props: IInsertRowsProps<PsdReading>,
    refRow: ITableRow<PsdReading>,
    isPaste: boolean,
    isPasteSingleColumn: boolean,
  ): void {
    // when pasting a column, we don't want to change or update the Pan, because then we would have two Pans
    // when inserting row, we can just change SieveDescription to new Sieve, to create a new row
    if (isPasteSingleColumn) {
      props.rows = props.rows.filter((r) => r.rowData.SieveDescription !== 'Pan');
    } else {
      for (const row of props.rows) {
        if (row.rowData.SieveDescription === 'Pan') {
          row.rowData.SieveDescription = 'New Sieve';
        }
      }
    }

    this.store.dispatch(actions.insertPsdReadingsRow(props));
  }

  public override filterPastedRows(rows: ITableRow<PsdReading>[], isPasteSingleColumn: boolean): ITableRow<PsdReading>[] {
    if (isPasteSingleColumn) {
      return rows.filter((r) => r.rowData.SieveDescription !== 'Pan');
    }
    return rows;
  }

  public override isCellDisabled(rows: ITableRow<PsdReading>[], rowIndex: number, cellIndex: number): boolean {
    if (
      rows.length &&
      rows[rowIndex]?.rowData.SieveDescription === 'Pan' &&
      (this.columns[cellIndex].colId === 'SieveDescription' ||
        this.columns[cellIndex].colId === 'SieveOpening' ||
        this.columns[cellIndex].colId === 'CumulativePercentWeightRetained' ||
        (this.columns[cellIndex].colId === 'PercentWeightRetained' && this.psdData?.selectedPSD.PSDInputType === PSDInputType.PercentWeight))
    ) {
      return true;
    }

    return false;
  }

  public override deleteRowsAction(props: IDeleteRowsProps): void {
    const psdData = this.psdData;
    if (!psdData) {
      return;
    }
    const psdReadings = psdData.selectedPSDReadings.rows.map((row) => row.rowData);
    for (const id of props.rowIds) {
      const psdReading = psdReadings.find((psdR) => psdR.Id === id);
      if (psdReading && psdReading.SieveDescription === 'Pan') {
        this.modalService.showAlert('Pan cannot be deleted.', 'Information').then(() => null);
        return;
      }
    }
    this.store.dispatch(actions.deletePsdReadingsRows(props));
  }

  public override createDefaultTableRows(scenarioId: number, noOfRows: number): ITableRow<PsdReading>[] {
    const newTableRows = [];
    for (let index = 0; index < Math.max(3, noOfRows); index++) {
      const newEmptyModel = this.createEmptyModel(scenarioId);
      if (newEmptyModel) {
        newTableRows.push(createTableRow(newEmptyModel, 'data', index, false));
      }
    }
    return newTableRows;
  }

  public override replaceGridAction(rows: ITableRow<PsdReading>[]): void {
    const checkedRows = rows.map((row, index) => {
      const newRow = { ...row, RowData: { ...row.rowData } };
      if (index !== rows.length - 1 && newRow.rowData.SieveDescription === 'Pan') {
        newRow.rowData.SieveDescription = 'New Sieve';
      } else if (index === rows.length - 1) {
        newRow.rowData.SieveDescription = 'Pan';
        newRow.rowData.SieveOpening = 0;
        newRow.rowData.CumulativePercentWeightRetained = 100;
      }
      return newRow;
    });

    if (checkedRows.length > 0) {
      const props: IInsertRowsProps<any> = {
        rows: checkedRows,
        refId: 0,
        insertLocation: 'replace' as InsertLocation,
        shouldResetResults: true,
      };
      this.store.dispatch(actions.insertPsdReadingsRow(props));
    }
  }
}
