import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { GlobalOptionsDto, ValidatedGlobalOptions } from '@dunefront/common/dto/global-options.dto';
import {
  selectOrganizationSeriesColors,
  selectValidatedOrganizationGlobalOptions,
  selectValidatedPredefinedGlobalOptions,
  selectValidatedUserGlobalOptions,
} from '../../../../+store/common-db/common-db.selectors';
import {
  changeGlobalOptionsOrgProperty,
  changeGlobalOptionsPersonalProperty,
  saveGlobalOptions,
} from '../../../../+store/common-db/common-db.actions';
import { ActivatedRoute } from '@angular/router';
import { DbDependentComponent } from '../../../../common-modules/db-connection/db-dependent.component';
import { ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';
import { ModalService } from '../../../../common-modules/modals/modal.service';
import { isElectron } from '@dunefront/common/common/electron/is-electron';

@Component({
  selector: 'app-global-options',
  templateUrl: './global-options.component.html',
  styleUrls: ['./global-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlobalOptionsComponent extends DbDependentComponent implements OnInit {
  public isOrganization = false;
  public globalOptions!: ValidatedGlobalOptions;
  public defaultGlobalOptions!: GlobalOptionsDto;
  public organizationSeriesColours$ = this.store.select(selectOrganizationSeriesColors);

  constructor(
    store: Store,
    cdRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    private modalService: ModalService,
  ) {
    super(store, cdRef);
  }

  public override ngOnInit(): void {
    super.ngOnInit();

    this.isOrganization = this.route.snapshot.routeConfig?.path === 'organization';

    this.subscription.add(
      this.store
        .select(this.isOrganization ? selectValidatedOrganizationGlobalOptions : selectValidatedUserGlobalOptions)
        .subscribe((globalOptions) => {
          this.globalOptions = globalOptions;
          this.cdRef.markForCheck();
        }),
    );

    this.subscription.add(
      this.store
        .select(this.isOrganization || isElectron() ? selectValidatedPredefinedGlobalOptions : selectValidatedOrganizationGlobalOptions)
        .subscribe((parentGlobalOptions) => {
          this.defaultGlobalOptions = parentGlobalOptions;
          this.cdRef.markForCheck();
        }),
    );

    if (this.isOrganization) {
      this.onHelpChange('settings', 'organization');
    } else {
      this.onHelpChange('settings', 'user');
    }
  }

  public submitOption(props: ObjectChangeProp<GlobalOptionsDto>): void {
    if (this.isOrganization) {
      this.store.dispatch(changeGlobalOptionsOrgProperty(props));
    } else {
      this.store.dispatch(changeGlobalOptionsPersonalProperty(props));
    }
  }

  public async resetDefault(): Promise<void> {
    const ifResetConfirmed = await this.modalService.showConfirm(
      `Are you sure you want to reset ${this.isOrganization ? 'Organization' : 'User'} settings?`,
      'Warning',
    );

    if (ifResetConfirmed) {
      const resetSettings = {
        ...this.defaultGlobalOptions,
        UseTemporaryProjectFile: this.globalOptions.UseTemporaryProjectFile,
      };
      this.store.dispatch(
        saveGlobalOptions({
          commonDbType: this.isOrganization ? 'Organization' : 'User',
          globalOptions: resetSettings,
        }),
      );
    }
  }
}
