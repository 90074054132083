import { Chart, Point } from 'chart.js';
import { getState } from './state';
import { getChartDrawingArea, getPointFromEvent } from '../plugins-common-helpers';
import { isPointInsideRect, pointsDistance, segmentCenter, segmentLength } from '@dunefront/common/common/math-geometry-helpers';
import { drawingGradientLineWithOffsets, getClosestGradientLine, toGradientLine } from './helpers';
import { handleThreshold, minLineLength } from './constants';
import { DrawingGradientLine, emptyPointOffsets } from './types';

type MouseHandler = (chart: Chart, event: any) => void;

const mouseDown = (chart: Chart, event: MouseEvent): void => {
  const state = getState(chart);
  if (state.options.mode !== 'edit') {
    return;
  }

  // mouse down point (px)
  const point = getPointFromEvent(event);
  const drawingArea = getChartDrawingArea(chart);
  if (!isPointInsideRect(point, drawingArea)) {
    return;
  }

  if (state.options.canStartDrag?.() === false) {
    return;
  }

  state.editMode = 'default';

  for (const line of state.lines) {
    if (pointsDistance(point, line.point1) <= handleThreshold) {
      state.editedGradientLine = line;
      state.highlightedGradientLineId = line.id;
      state.editMode = 'movePoint1';
      break;
    } else if (pointsDistance(point, line.point2) <= handleThreshold) {
      state.editedGradientLine = line;
      state.highlightedGradientLineId = line.id;
      state.editMode = 'movePoint2';
      break;
    } else if (pointsDistance(point, segmentCenter(line.point1, line.point2)) <= handleThreshold) {
      state.editedGradientLine = line;
      state.highlightedGradientLineId = line.id;
      state.editMode = 'moveBoth';
      break;
    }
  }

  if (state.highlightedGradientLineId != null) {
    return;
  }

  if (state.editMode === 'default') {
    state.editMode = 'movePoint2';
    state.createdGradientLine = {
      id: -1,
      point1: { ...point },
      point2: { ...point },
      xScaleID: state.options.xScaleID,
      yScaleID: state.options.yScaleID,
      style: state.options.defaultStyle,
    };
    state.editedGradientLine = state.createdGradientLine;
  }

  state.dragOffsetsPoint1 = emptyPointOffsets();
  state.dragOffsetsPoint2 = emptyPointOffsets();

  state.prevPoint = point;
  state.options.dragChanged?.(true);
  chart.render();
};

const mouseMove = (chart: Chart, event: MouseEvent): void => {
  const state = getState(chart);
  const point = getPointFromEvent(event);

  if (state.options.mode === 'inactive') {
    state.prevPoint = point;
    state.highlightedGradientLineId = undefined;
    chart.canvas.style.cursor = 'default';
    return;
  }

  if (state.options.mode === 'highlight') {
    const { hoverLine, cursor } = getHoverProps(chart, point);
    const newId = hoverLine?.id;

    state.prevPoint = point;
    chart.canvas.style.cursor = cursor;

    if (newId !== state.highlightedGradientLineId) {
      state.highlightedGradientLineId = newId;
      state.options.highlightedLineChanged?.(state.highlightedGradientLineId != null);
    }

    return;
  }

  // edit mode

  const { dragOffsetsPoint1, dragOffsetsPoint2, editMode, prevPoint } = state;
  const drawingArea = getChartDrawingArea(chart);
  if (!isPointInsideRect(point, drawingArea)) {
    mouseUp(chart, event);
    return;
  }

  const xDiff = point.x - (prevPoint?.x ?? point.x);
  const yDiff = point.y - (prevPoint?.y ?? point.y);

  let cursor = 'default';

  const editedGradientLine = state.editedGradientLine;
  if (editedGradientLine != null) {
    if (editMode === 'movePoint1' || editMode === 'moveBoth') {
      dragOffsetsPoint1.offsetX += xDiff;
      dragOffsetsPoint1.offsetY += yDiff;
    }
    if (editMode === 'movePoint2' || editMode === 'moveBoth') {
      dragOffsetsPoint2.offsetX += xDiff;
      dragOffsetsPoint2.offsetY += yDiff;
    }

    cursor = editMode === 'movePoint1' || editMode === 'movePoint2' ? 'pointer' : cursor;
    cursor = editMode === 'moveBoth' ? 'move' : cursor;
  }

  chart.canvas.style.cursor = cursor;

  if (editedGradientLine == null) {
    updateHoverProps(chart, point);
  }

  state.dragOffsetsPoint1 = dragOffsetsPoint1;
  state.dragOffsetsPoint2 = dragOffsetsPoint2;
  state.prevPoint = point;

  chart.render();
};

const mouseUp = (chart: Chart, event: MouseEvent): void => {
  const state = getState(chart);
  if (state.options.mode !== 'edit') {
    return;
  }

  const { dragOffsetsPoint1, dragOffsetsPoint2, editedGradientLine } = state;

  if (editedGradientLine != null) {
    const editedGradientLineWithOffsets = drawingGradientLineWithOffsets(editedGradientLine, dragOffsetsPoint1, dragOffsetsPoint2);

    state.dragOffsetsPoint1 = emptyPointOffsets();
    state.dragOffsetsPoint2 = emptyPointOffsets();
    state.editedGradientLine = undefined;
    state.createdGradientLine = undefined;
    state.prevPoint = undefined;
    state.editMode = 'default';

    if (segmentLength(editedGradientLineWithOffsets) >= minLineLength) {
      state.options.gradientLineCreatedOrUpdated?.(toGradientLine(editedGradientLineWithOffsets, chart));
      state.lines = [...state.lines.filter((l) => l.id !== editedGradientLineWithOffsets.id), editedGradientLineWithOffsets];
      state.highlightedGradientLineId = editedGradientLineWithOffsets.id;
    }
  }

  updateHoverProps(chart, getPointFromEvent(event));
  state.options.dragChanged?.(false);
};

const mouseOut = (chart: Chart, event: MouseEvent): void => {
  const state = getState(chart);
  if (state.options.mode === 'edit') {
    mouseUp(chart, event);
  } else {
    updateHoverProps(chart, getPointFromEvent(event));
  }
};

const mouseDblClick = (chart: Chart, event: MouseEvent): void => {
  const state = getState(chart);
  if (state.options.mode !== 'edit') {
    return;
  }

  const point = getPointFromEvent(event);

  const drawingLine = getClosestGradientLine(point, state.lines);
  const gradientLine = drawingLine != null ? state.options.lines.find((a) => a.id === drawingLine.id) : null;
  if (gradientLine != null) {
    state.options.gradientLineDoubleClicked?.(gradientLine);
  }

  state.editMode = 'default';
};

//
export const addHandlers = (chart: Chart): void => {
  addHandler(chart, chart.canvas, 'mousedown', mouseDown);
  addHandler(chart, chart.canvas, 'mousemove', mouseMove);
  addHandler(chart, chart.canvas, 'mouseup', mouseUp);
  addHandler(chart, chart.canvas, 'mouseout', mouseOut);
  addHandler(chart, chart.canvas, 'dblclick', mouseDblClick);
};

export const removeHandlers = (chart: Chart): void => {
  removeHandler(chart, 'mousedown');
  removeHandler(chart, 'mousemove');
  removeHandler(chart, 'mouseup');
  removeHandler(chart, 'mouseout');
  removeHandler(chart, 'dblclick');
};

export const addHandler = (chart: Chart, target: HTMLElement, type: string, handler: MouseHandler): void => {
  const { handlers } = getState(chart);

  removeHandler(chart, type);

  handlers[type] = {
    handler: (event): void => handler(chart, event),
    target,
  };

  target.addEventListener(type, handlers[type].handler);
};

export const removeHandler = (chart: Chart, type: string): void => {
  const { handlers } = getState(chart);
  const handler = handlers[type];

  if (handler != null) {
    handler.target.removeEventListener(type, handler.handler);
    delete handlers[type];
  }
};

export const getHoverProps = (
  chart: Chart,
  point: Point,
): {
  hoverLine: DrawingGradientLine | undefined;
  cursor: string;
} => {
  const state = getState(chart);

  if (state.options.mode === 'inactive') {
    return { hoverLine: undefined, cursor: 'default' };
  }

  const hoverLine = getClosestGradientLine(point, state.lines);
  let cursor = 'default';

  if (hoverLine != null) {
    if (
      state.options.mode === 'highlight' ||
      pointsDistance(point, hoverLine.point1) <= handleThreshold ||
      pointsDistance(point, hoverLine.point2) <= handleThreshold
    ) {
      cursor = 'pointer';
    } else if (pointsDistance(point, segmentCenter(hoverLine.point1, hoverLine.point2)) <= handleThreshold) {
      cursor = 'move';
    }
  }

  return { hoverLine, cursor };
};

export const updateHoverProps = (chart: Chart, point: Point): void => {
  const state = getState(chart);
  const { hoverLine, cursor } = getHoverProps(chart, point);

  chart.canvas.style.cursor = cursor;
  // prevPoint must be updated before highlightedLineChanged
  state.prevPoint = point;

  const newHighlightedGradientLineId = state.editedGradientLine?.id ?? hoverLine?.id;
  if (newHighlightedGradientLineId !== state.highlightedGradientLineId) {
    state.highlightedGradientLineId = newHighlightedGradientLineId;
    state.options.highlightedLineChanged?.(state.highlightedGradientLineId != null);
  }
};
