import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MASPCalculatorModuleFeatureName } from './masp-calculator.reducer';
import { MASPCalculatorModuleState } from '@dunefront/common/modules/calculators/masp-calculator/masp-calculator-module.state';
import { MASPCalculatorValidation } from '@dunefront/common/modules/calculators/masp-calculator/model/masp-calculator.validation';

const getMASPCalculatorModuleState = createFeatureSelector<MASPCalculatorModuleState>(MASPCalculatorModuleFeatureName);
export const getValidatedMASPCalculatorModuleState = createSelector(getMASPCalculatorModuleState, (state) =>
  MASPCalculatorValidation.validate(state),
);

export const getHasMASPCalculatorResults = createSelector(getValidatedMASPCalculatorModuleState, (state): boolean => {
  return state.MaxAllowableSurfacePressure !== 0;
});
