import { getLicenseErrorTextFromError, isLicensingError } from './IAppError';
import { ModuleType } from '../modules/scenario/scenario.dto';

export const ERROR_VACUUM_NOT_NEEDED = 'ERROR_VACUUM_NOT_NEEDED';
export const ERROR_COMMON_DB_MISSING_PREDEFINED = 'ERROR_COMMON_DB_MISSING_PREDEFINED';
export const ERROR_DB_MISSING_CONNECTION_NAME = 'ERROR_DB_MISSING_CONNECTION_NAME';
export const ERROR_DB_NOT_EXISTS = 'ERROR_DB_NOT_EXISTS';
export const ERROR_DB_MIGRATION = 'ERROR_DB_MIGRATION';
export const ERROR_DB_MIGRATION_UNKNOWN_DB_VERSION = 'ERROR_DB_MIGRATION_UNKNOWN_DB_VERSION';
export const ERROR_DB_USED = 'ERROR_DB_USED';
export const ERROR_DB_READ_ONLY = 'ERROR_DB_READ_ONLY';
export const ERROR_DB_ROOT_FILE = 'ERROR_DB_ROOT_FILE';
export const ERROR_DB_TEMP_FILE = 'ERROR_DB_TEMP_FILE';

export enum LicensingErrorType {
  ERROR_LICENSE_CONSUMER_SERVER_URL_NOT_AVAILABLE = 'ERROR_LICENSE_CONSUMER_SERVER_URL_NOT_AVAILABLE',
  ERROR_LICENSE_CONSUMER_SERVER_NOT_AVAILABLE = 'ERROR_LICENSE_CONSUMER_SERVER_NOT_AVAILABLE',
  ERROR_LICENSE_SERVER_NOT_AVAILABLE = 'ERROR_LICENSE_SERVER_NOT_AVAILABLE',
  ERROR_LICENSE_SESSION_EXPIRED = 'ERROR_LICENSE_SESSION_EXPIRED',
  ERROR_LICENSE_FEATURE_NOT_FOUND = 'ERROR_LICENSE_FEATURE_NOT_FOUND',
  ERROR_LICENSE_LOGIN_NOT_FOUND = 'ERROR_LICENSE_LOGIN_NOT_FOUND',
  ERROR_LICENSE_HASP = 'ERROR_LICENSE_HASP',
  ERROR_LOGGED_IN_LICENSE_FEATURE_NOT_FOUND = 'ERROR_LOGGED_IN_LICENSE_FEATURE_NOT_FOUND',
}

export enum ConnectionErrorType {
  ERROR_USER_VALIDATION = 'ERROR_USER_VALIDATION: ',
  ERROR_TOKEN_VERIFICATION_FAILED = 'ERROR_AUTH_TOKEN_VERIFICATION_FAILED: ',
  ERROR_RETRIEVING_SIGNING_KEY_FAILED = 'ERROR_RETRIEVING_SIGNING_KEY_FAILED: ',
  ERROR_EMAIL_NOT_VERIFIED = 'ERROR_EMAIL_NOT_VERIFIED',
  ERROR_NO_LICENSE_APPLIED = 'ERROR_NO_LICENSE_APPLIED',
}

export const combineErrorMessage = (message: string, info: unknown): string => {
  return `${message}\n${convertErrorToString(info)}`;
};

export const convertErrorToString = (error: unknown): string => {
  return typeof error === 'object' && error !== null && toString.call(error) === '[object Object]' ? JSON.stringify(error) : error + '';
};

export const isError = (error: unknown): error is Error => error != null && typeof error === 'object' && (error as Error).message != null;
export const parseError = (error: unknown): string => {
  if (isLicensingError(error)) {
    return getLicenseErrorTextFromError(error);
  } else {
    const parsedError = isError(error) ? error : new Error(JSON.stringify(error));
    return parsedError.message;
  }
};

export const VolumeCalculationErrorMessage = (moduleType: ModuleType): string =>
  moduleType === ModuleType.Evaluate
    ? 'Correct all errors on the well, completion, data and gravel screens to display calculation results'
    : 'Correct all errors on the well, completion and gravel screens to display calculation results';

export const HANDLED_CONNECTION_ERRORS = [
  ConnectionErrorType.ERROR_USER_VALIDATION,
  ConnectionErrorType.ERROR_TOKEN_VERIFICATION_FAILED,
  ConnectionErrorType.ERROR_NO_LICENSE_APPLIED,
  ConnectionErrorType.ERROR_EMAIL_NOT_VERIFIED,
];

export const getHandledBackendConnectionErrorMessage = (err: string | undefined, appName = ''): string => {
  if (!err) {
    return '';
  }

  for (const handledError of HANDLED_CONNECTION_ERRORS) {
    if (err.startsWith(handledError)) {
      switch (handledError) {
        case ConnectionErrorType.ERROR_TOKEN_VERIFICATION_FAILED:
        case ConnectionErrorType.ERROR_RETRIEVING_SIGNING_KEY_FAILED:
        case ConnectionErrorType.ERROR_USER_VALIDATION:
          return `User Authorization Failed. Please contact Administrator. <br><br> Error code: ${err.replace(handledError, '')}`;
        case ConnectionErrorType.ERROR_NO_LICENSE_APPLIED:
          return (
            `<p>A license is required to access ${appName} Cloud app.</p>` +
            `<p>We're setting up your account and will assign the appropriate license soon.</p>` +
            `<p>We'll let you know as soon as it's ready.</p>` +
            `<p>Questions or issues? Contact <a href="mailto:support@dunefront.com">support@dunefront.com.</a></p>`
          );
        case ConnectionErrorType.ERROR_EMAIL_NOT_VERIFIED:
          return (
            `<p>Please verify your email to access the ${appName} Cloud app.</p>` +
            '<p>Click the link we sent to your inbox to get started.</p>' +
            '<p>Questions or issues? Contact <a href="mailto:support@dunefront.com">support@dunefront.com</a>.</p>'
          );
      }
      return err.replace(handledError, '');
    }
  }

  return '';
};
