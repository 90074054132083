import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { createSelector, Store } from '@ngrx/store';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ElectronService } from '../../../../shared/services/electron-service/electron.service';
import { ModalService } from '../../../modals/modal.service';
import {
  getAccessibleFeaturesAndLicenses,
  getCurrentLicensingProduct,
  getLicenseExpirationWarning,
  getLoggedInFeaturesStatus,
  getMainFeatureLoginInfo,
  IFeatureInfo,
} from '../../../../+store/licensing/licensing.selectors';
import { DbDependentComponent } from '../../../db-connection/db-dependent.component';
import { cancelDetachLicenseAction, generateLicensingDiagnosticsReportAction } from '../../../../+store/licensing/licensing.actions';
import { LicensingSessionsModalComponent } from '../licensing-sessions/licensing-sessions.modal.component';
import { CalculationStatus } from '../../../../+store/calculation-engine/calculation-engine-module.state';
import { Observable } from 'rxjs';
import { LicenseFeature, LicenseProduct, LoginInfo } from '@dunefront/common/modules/licensing/licensing.interfaces';
import { LicensingDetachModalComponent, LicensingDetachModalData } from '../licensing-detach-dialog/licensing-detach.modal.component';
import { isElectron } from '@dunefront/common/common/electron/is-electron';
import { EnumHelpers } from '@dunefront/common/utils/enum.helpers';
import { ArrayHelpers } from '@dunefront/common/common/array-helpers';
import { modalButton, ModalButtonConfig } from '../../../modals/generic-modal/generic-modal.component';

@Component({
  selector: 'app-licensing-modal-component',
  templateUrl: './licensing.modal.component.html',
  styleUrls: ['./licensing.modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LicensingModalComponent extends DbDependentComponent implements OnInit {
  public isDevMode = false;
  public isHelpOpen = false;

  public features$: Observable<IFeatureInfo[]> = this.store.select(getFeatureInfos);
  public licenseExpirationWarning$: Observable<string | undefined> = this.store.select(getLicenseExpirationWarning);
  public loginInfo$: Observable<LoginInfo | undefined> = this.store.select(getMainFeatureLoginInfo);
  public currentProduct$: Observable<LicenseProduct | undefined> = this.store.select(getCurrentLicensingProduct);

  public readonly modalButtonsConfigs: ModalButtonConfig[] = [
    modalButton('Close', (): void => this.ref.close(), 'licensing-close-btn', 'primary'),
  ];
  constructor(
    store: Store,
    cdRef: ChangeDetectorRef,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public electronService: ElectronService,
    private modalService: ModalService,
  ) {
    super(store, cdRef);

    this.isDevMode = config.data.isDevMode;
  }

  public async btnGenerateLicensingDiagnostics(): Promise<void> {
    this.store.dispatch(generateLicensingDiagnosticsReportAction());
  }

  public btnLicensingClick(): void {
    this.modalService.open(LicensingSessionsModalComponent, {}, '', 'lg');
  }

  public btnDetachClick(licenseId: string, product: LicenseProduct | undefined): void {
    if (!product) {
      return;
    }

    this.modalService.open<LicensingDetachModalData>(LicensingDetachModalComponent, { licenseId, product }, '', 'sm');
  }

  public async btnCancelDetachClick(licenseId: string, parentLicenseId: string): Promise<void> {
    const warningAccepted = await this.modalService.showConfirm(
      `<p>When you cancel the detached license, it will be removed from your computer and you won't be able to use it offline.</p>
<p>Make sure you are connected to the license server before continuing.</p>
<p>Are you ready to cancel the detached license?</p>`,
      'Warning',
      'md',
      'Yes',
      'No',
    );

    if (warningAccepted) {
      this.store.dispatch(cancelDetachLicenseAction({ licenseId, parentLicenseId }));
    }
  }

  protected readonly CalculationStatus = CalculationStatus;
  protected readonly isElectron = isElectron;
}

export const getFeatureInfos = createSelector(
  getAccessibleFeaturesAndLicenses,
  getLoggedInFeaturesStatus,
  getMainFeatureLoginInfo,
  (allFeatures, loggedInFeaturesInfo, mainFeatureLoginInfo) => {
    return allFeatures.features
      .filter((feature) => feature < LicenseFeature.Detaching)
      .map((feature) => {
        const featureInfo: IFeatureInfo = {
          feature,
          name: EnumHelpers.getEnumName(LicenseFeature, feature) ?? '',
          isMainFeature: mainFeatureLoginInfo?.licenseFeature === feature,
          loginInfo: (loggedInFeaturesInfo ?? []).find((logInInfo) => logInInfo.licenseFeature === feature),
        };
        return featureInfo;
      })
      .sort((a, b) => ArrayHelpers.stringCompare(a.name, b.name));
  },
);
