<div class="dev-panel" *ngIf="isVisible">
  <table class="table table-hover table-sm table-dark table-striped" *ngIf="undoRedo$ | async as undoRedo">
    <tr>
      <th>Undo</th>
      <th>Redo</th>
    </tr>
    <tbody>
      <tr [class.bg-primary]="i === undoRedo.currentIndex" *ngFor="let state of undoRedo.history; let i = index">
        <td (click)="showPayload(state.undo)">{{ state.undo?.type }}</td>
        <td (click)="showPayload(state.redo)">{{ state.redo?.type }}</td>
      </tr>
    </tbody>
  </table>
</div>
