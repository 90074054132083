import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DictionaryWithArray, IDictionaryWithArray } from '@dunefront/common/common/state.helpers';
import {
  createTableRow,
  createTableState,
  ITableRow,
  ITableState,
  IValidatedDataType,
} from '@dunefront/common/common/common-grid.interfaces';
import { selectCurrentUnitSystem } from '../units/units.selectors';
import { PSDAnalysisModuleState } from '@dunefront/common/modules/psd-analysis/psd-analysis.module.state';
import { ISelectItem, toSelectItem } from '@dunefront/common/common/select.helpers';
import { PSDResultDto } from '@dunefront/common/modules/psd-analysis/dto/psd.dto';
import { ConvertUnitPipe } from '@dunefront/common/modules/units/convert-unit.pipe/convert-unit.pipe';
import { UnitConverterHelper } from '@dunefront/common/unit-converters/unit.converter.helper';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { ErrorHelper } from '@dunefront/common/common/common-state.interfaces';
import { PSDAnalysisValidation } from '@dunefront/common/modules/psd-analysis/model/psd-analysis/psd-analysis.validation';
import { PsdReading } from '@dunefront/common/modules/psd-analysis/model/psd-readings/psd-reading';
import { PSD, PSDResult } from '@dunefront/common/modules/psd-analysis/model/psd/psd';
import { getUndoRedoState } from '../undo-redo/undo-redo.selectors';

const getPSDAnalysisModuleState = createFeatureSelector<PSDAnalysisModuleState>('psdAnalysis');

export const getValidatedPSDAnalysisModuleState = createSelector(getPSDAnalysisModuleState, (state) =>
  PSDAnalysisValidation.validate(state),
);

export const getPSDAnalysis = createSelector(getValidatedPSDAnalysisModuleState, (state) => state.PSDAnalysis);
export const getLastPSDId = createSelector(getPSDAnalysisModuleState, (state) => Math.max(...state.PSD.ids.map((id) => +id)));
export const getLastPSDInputType = createSelector(getPSDAnalysisModuleState, (state) => state.LastInputType);
export const getPsdDict = createSelector(getPSDAnalysisModuleState, (state) => state.PSD.dict);
export const getPsdReadingsDict = createSelector(getPSDAnalysisModuleState, (state) => state.PSDReadings.dict);

export const getPSDsAsGrid = createSelector(
  getValidatedPSDAnalysisModuleState,
  selectCurrentUnitSystem,
  (state, currentUnitSystem): ITableState<PSDResult> => {
    const results: ITableRow<PSDResult>[] = DictionaryWithArray.getArray(state.PSD)
      .filter((psd) => psd.IsSelected && psd.result != null && psd.result.IsFullAnalysis)
      .sort((psd1, psd2) => psd1.Depth - psd2.Depth)
      .map((psd, index) => {
        const result = psd.result as PSDResultDto;

        const minPenberthyCopeOpening = UnitConverterHelper.convertFromSiWithDecimalPlaces(
          UnitSystem.Screen_Opening,
          currentUnitSystem,
          result.MinPenberthyCopeOpening,
          0,
        );

        const maxPenberthyCopeOpening = UnitConverterHelper.convertFromSiWithDecimalPlaces(
          UnitSystem.Screen_Opening,
          currentUnitSystem,
          result.MaxPenberthyCopeOpening,
          0,
        );

        const resultTableRow: ITableRow<PSDResult> = createTableRow(
          {
            ...result,
            ProducedSandMass:
              result.ProducedSandMass === 'No Sand Control' || result.ProducedSandMass < 0 ? 'No Sand Control' : result.ProducedSandMass,
            Description: psd.Description,
            PenberthyCopeCriteria: ConvertUnitPipe.decode(result.PenberthyCopeCriteria, currentUnitSystem),
            CoberlyCriteria: ConvertUnitPipe.decode(result.CoberlyCriteria, currentUnitSystem, 0, true),
            GulfCoastCriteria: ConvertUnitPipe.decode(result.GulfCoastCriteria, currentUnitSystem),
            MalbrelCriteria: ConvertUnitPipe.decode(result.MalbrelCriteria, currentUnitSystem),
            SaucierCriteria: ConvertUnitPipe.decode(result.SaucierCriteria, currentUnitSystem, 0, true),
            ProducedSandMassCriteria: ConvertUnitPipe.decode(result.ProducedSandMassCriteria, currentUnitSystem),
            ScreenOpeningGridValue: `${minPenberthyCopeOpening} - ${maxPenberthyCopeOpening}`,
          },
          'data',
          index,
        );
        return resultTableRow;
      });
    return createTableState(results);
  },
);

export const getSelectedPSDIds = createSelector(getValidatedPSDAnalysisModuleState, (state): number[] => {
  const selectedIds = [];

  for (const id of state.PSD.ids) {
    const psd = state.PSD.dict[id];
    if (psd?.IsSelected) {
      selectedIds.push(psd.Id);
    }
  }

  return selectedIds;
});

export const getPSDSelectData = createSelector(getValidatedPSDAnalysisModuleState, (state): IPSDSelectorData | undefined => {
  const selectedPSD = DictionaryWithArray.getCopy(state.PSD, state.SelectedPSDId);
  const selectedPSDReadings = DictionaryWithArray.get(state.PSDReadings, state.SelectedPSDId);
  if (!selectedPSD || !selectedPSDReadings) {
    return undefined;
  }
  return {
    items: DictionaryWithArray.getArray(state.PSD)
      .sort((a, b) => a.Id - b.Id)
      .map((psd) => toSelectItem(psd.Id, psd.Description, undefined, !psd.isValid)),
    selectedPSD: selectedPSD,
    selectedPSDReadings: selectedPSDReadings ?? createTableState([]),
  };
});

export const getPSDChartSelectionData = createSelector(
  getValidatedPSDAnalysisModuleState,
  (state: PSDAnalysisModuleState): IPSDChartSelectionData[] =>
    DictionaryWithArray.getArray(state.PSD).map((psd) => ({
      psd,
      psdReadings: DictionaryWithArray.get(state.PSDReadings, psd.Id) ?? createTableState([]),
    })),
);

export const invalidPSDDescriptions = createSelector(getValidatedPSDAnalysisModuleState, (state): string[] => {
  const invalidPsds: string[] = [];
  for (const id of state.PSD.ids) {
    const psd = state.PSD.dict[id];
    const psdReadings = state.PSDReadings.dict[id];
    if (psd && psdReadings && psd.IsSelected && (!psd.isValid || !psdReadings.isValid)) {
      invalidPsds.push(psd.Description);
    }
  }
  return invalidPsds;
});

export const isPSDAnalysisValid = createSelector(getValidatedPSDAnalysisModuleState, (state) => state.PSDAnalysis.isValid);

export const getPSDModuleError = createSelector(
  invalidPSDDescriptions,
  getSelectedPSDIds,
  isPSDAnalysisValid,
  (psdDescriptions, selectedPSDIds, psdAnalysisValid): string => {
    if (!selectedPSDIds.length) {
      return ErrorHelper.ERROR_MULTIPLE_SCREENS_MESSAGE_HEADER + ' -> PSD Comparison';
    }
    if (!psdAnalysisValid) {
      return ErrorHelper.ERROR_MULTIPLE_SCREENS_MESSAGE_HEADER + ' -> Sand Production';
    }
    return psdDescriptions.length > 0
      ? `The PSD Analysis cannot be run because the following selected PSDs are invalid: <br> -> ${psdDescriptions.join('<br> -> ')}`
      : '';
  },
);

export const isCurrentPSDLoadedAndValid = createSelector(
  getValidatedPSDAnalysisModuleState,
  (state): boolean => state.isLoaded && (state.PSD.dict[state.SelectedPSDId]?.isValid ?? false),
);

export const isPSDModuleValid = createSelector(
  getValidatedPSDAnalysisModuleState,
  getUndoRedoState,
  (state, undoRedoState): IPSDModuleValid => {
    if (undoRedoState.isBusy || !state.PSD.ids.length || !state.PSDReadings.ids.length) {
      return {
        isPSDValid: false,
        isAllSelectedPSDValid: false,
        isAllSelectedPSDReadingsValid: false,
        isPSDAnalysisValid: false,
        isAllUnselectedPSDValid: false,
        isAllUnselectedPSDReadingsValid: false,
        hasResults: false,
        isLoaded: false,
        isAtLeastOneSelected: false,
      };
    }
    const isPsdSelected = (id: string): boolean | undefined => state.PSD.dict[id]?.IsSelected;
    const isValid = (dict: IDictionaryWithArray<IValidatedDataType>, id: string): boolean | undefined => dict.dict[id]?.isValid;

    const isPSDValid = state.PSD.ids.every((id) => isValid(state.PSD, id));
    const isAllSelectedPSDValid = state.PSD.ids.filter((id) => isPsdSelected(id)).every((id) => isValid(state.PSD, id));
    const isAllUnselectedPSDValid = state.PSD.ids.filter((id) => !isPsdSelected(id)).every((id) => isValid(state.PSD, id));
    const isAllSelectedPSDReadingsValid = state.PSDReadings.ids
      .filter((id) => isPsdSelected(id))
      .every((id) => isValid(state.PSDReadings, id));
    const isAllUnselectedPSDReadingsValid = state.PSDReadings.ids
      .filter((id) => !isPsdSelected(id))
      .every((id) => isValid(state.PSDReadings, id));
    const isPSDAnalysisValid = state.PSDAnalysis.isValid;
    const everySelectedPSDHasResult = state.PSD.ids
      .filter((id) => isPsdSelected(id))
      .every((id) => {
        const psd = state.PSD.dict[id];
        return psd?.result?.IsFullAnalysis;
      });
    const hasResults = isAllSelectedPSDValid && isAllSelectedPSDReadingsValid && isPSDAnalysisValid && everySelectedPSDHasResult;
    const isAtLeastOneSelected = !!state.PSD.ids.filter((id) => isPsdSelected(id)).length;

    return {
      isPSDValid,
      isAllSelectedPSDValid,
      isAllSelectedPSDReadingsValid,
      isAllUnselectedPSDValid,
      isAllUnselectedPSDReadingsValid,
      isPSDAnalysisValid,
      isLoaded: state.isLoaded,
      hasResults,
      isAtLeastOneSelected,
    };
  },
);

export interface IPSDSelectorData {
  items: ISelectItem<number>[];
  selectedPSD: PSD;
  selectedPSDReadings: ITableState<PsdReading>;
}

export interface IPSDChartSelectionData {
  psd: PSD;
  psdReadings: ITableState<PsdReading>;
}

export interface IPSDModuleValid {
  isPSDValid: boolean;
  isAllSelectedPSDValid: boolean;
  isAllSelectedPSDReadingsValid: boolean;

  isAllUnselectedPSDValid: boolean;
  isAllUnselectedPSDReadingsValid: boolean;
  isPSDAnalysisValid: boolean;
  hasResults: boolean;
  isLoaded: boolean;
  isAtLeastOneSelected: boolean;
}
