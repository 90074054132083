import { CrudResponse } from '@dunefront/common/modules/common.actions';
import { LoadLeakoffCoefficientCalculatorActionResponse } from '@dunefront/common/modules/calculators/leakoff-coefficient-calculator/leakoff-coefficient-calculator-module.action';
import {
  LeakoffCoefficientCalculatorInitialState,
  LeakoffCoefficientCalculatorModuleState,
} from '@dunefront/common/modules/calculators/leakoff-coefficient-calculator/leakoff-coefficient-calculator-module.state';
import { LeakoffCoefficientCalculatorFactory } from '@dunefront/common/modules/calculators/leakoff-coefficient-calculator/model/leakoff-coefficient-calculator.factory';
import { DeleteResultsFilter, ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import { ActionResponse } from '@dunefront/common/response-ws.action';

export class LeakoffCoefficientCalculatorModuleReducerHelper {
  public static onLoadScenarioDataSuccessAction(
    state: LeakoffCoefficientCalculatorModuleState,
    response: ActionResponse<LoadLeakoffCoefficientCalculatorActionResponse>,
  ): LeakoffCoefficientCalculatorModuleState {
    if (response.status !== 'ok' || !response.payload) {
      return LeakoffCoefficientCalculatorInitialState;
    }
    return LeakoffCoefficientCalculatorFactory.create(response.payload.leakoffCoefficientCalculatorDto);
  }

  public static onUpdateLeakoffCoefficientCalculatorSuccess(
    state: LeakoffCoefficientCalculatorModuleState,
    response: CrudResponse,
  ): LeakoffCoefficientCalculatorModuleState {
    if (!response.affectedRows.leakoffCoefficientCalculator) {
      return state;
    }
    return LeakoffCoefficientCalculatorFactory.create(response.affectedRows.leakoffCoefficientCalculator);
  }

  public static onRemoveDataResultsFromStore(
    state: LeakoffCoefficientCalculatorModuleState,
    deleteResultsFilter: DeleteResultsFilter,
  ): LeakoffCoefficientCalculatorModuleState {
    if (
      (deleteResultsFilter.moduleTypes != null && !deleteResultsFilter.moduleTypes.includes(ModuleType.Leakoff_Coefficient_Calc)) ||
      (deleteResultsFilter.scenarioIds != null && !deleteResultsFilter.scenarioIds.includes(state.ScenarioId))
    ) {
      return state;
    }
    return {
      ...state,
      ...LeakoffCoefficientCalculatorFactory.cleanResultsPartial(),
    };
  }
}
