<app-generic-modal [title]="modalTitle" [modalButtonsConfigs]="modalButtonsConfigs">
  <p>
    Rename <strong>{{ file.Name }}</strong> to:
  </p>
  <app-input
    class="grid-two-one"
    [isTextInput]="true"
    [value]="fileName.targetName"
    [isUiLockable]="false"
    [maxWidth]="368"
    [triggerOnKeyPress]="true"
    [autoFocus]="true"
    align="left"
    dataCy="new-file-name"
    (primitiveValueChanged)="textValueChanged($event.value)"
  >
  </app-input>
  <div class="error error-text" data-cy="dialog-error">{{ errorMessage }}</div>
</app-generic-modal>
