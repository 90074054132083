<div class="grid-form-container main cols-3 with-gap">
  <div class="grid-form-container form-column inline col-1">
    <app-form-input
      class="grid-two-one"
      appUnwrap
      label="Cross-Over MD"
      dataCy="cross-over-md"
      [minWidth]="140"
      [source]="scenarioRangeProperties"
      [key]="'TrendAnalysisXOverMD'"
      [decimalPlaces]="2"
      [unitType]="UnitType.Long_Length"
      (valueChanged)="onChange($event)"
      [disabled]="disableInputs"
    >
    </app-form-input>
    <app-form-input
      class="grid-two-one"
      appUnwrap
      label="Washpipe Bottom MD"
      [minWidth]="140"
      [source]="scenarioRangeProperties"
      [key]="'TrendAnalysisWashpipeMD'"
      [decimalPlaces]="2"
      [unitType]="UnitType.Long_Length"
      (valueChanged)="onChange($event)"
      [disabled]="disableInputs"
    >
    </app-form-input>
  </div>

  <div class="grid-form-container form-column inline col-2">
    <label>Tool Position</label>
    <app-select
      [source]="scenarioRangeProperties"
      [key]="'TrendAnalysisToolPosition'"
      [items]="toolPositions"
      [width]="160"
      (valueChanged)="onChange($event)"
      [disabled]="disableInputs"
    ></app-select>
    <label>Pumping Direction</label>
    <app-select
      [source]="scenarioRangeProperties"
      [key]="'TrendAnalysisPumpingDirection'"
      [items]="pumpingDirections"
      [width]="160"
      (valueChanged)="onChange($event)"
      [disabled]="disableInputs"
    ></app-select>
  </div>
  <app-analysis-data [inputWidth]="150" [renderAs2Rows]="true"></app-analysis-data>
</div>

<div class="copy-buttons">
  <button
    appButton
    data-cy="copy-from-simulate-btn"
    type="button"
    (buttonClick)="copyFromSimulateEvaluate('Simulate')"
    class="btn btn-primary btn-sm"
    [disabled]="!isSimulateEnabled"
  >
    Copy from Simulate
  </button>
  <button
    appButton
    data-cy="copy-from-evaluate-btn"
    type="button"
    (buttonClick)="copyFromSimulateEvaluate('Evaluate')"
    class="btn btn-primary btn-sm"
    [disabled]="!isEvaluateEnabled"
  >
    Copy from Evaluate
  </button>
</div>
