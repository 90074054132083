<app-page-panel header="Thermal" *ngIf="settings && defaultSettings" [allowFullScreen]="false">
  <div class="grid-form-container">
    <app-form-input
      class="grid-two-one"
      appUnwrap
      label="Max Thermal Cell Length"
      [source]="settings"
      [sourceDefaults]="defaultSettings"
      [key]="'MaxThermalCellLength'"
      [decimalPlaces]="4"
      [unitType]="UnitType.Long_Length"
      [highlight]="settings.MaxThermalCellLength !== defaultSettings.MaxThermalCellLength"
      [inputWidth]="150"
      (valueChanged)="onValueChange($event)"
    >
    </app-form-input>
    <app-form-input
      class="grid-two-one"
      appUnwrap
      label="Min Thermal Cell Length"
      [source]="settings"
      [sourceDefaults]="defaultSettings"
      [key]="'MinThermalCellLength'"
      [decimalPlaces]="4"
      [unitType]="UnitType.Long_Length"
      [highlight]="settings.MinThermalCellLength !== defaultSettings.MinThermalCellLength"
      [inputWidth]="150"
      (valueChanged)="onValueChange($event)"
    >
    </app-form-input>
    <app-form-input
      class="grid-two-one"
      appUnwrap
      label="Pipe Thermal Conductivity"
      [source]="settings"
      [sourceDefaults]="defaultSettings"
      [key]="'PipeThermalConductivity'"
      [decimalPlaces]="4"
      [unitType]="UnitType.Thermal_Conductivity"
      [highlight]="settings.PipeThermalConductivity !== defaultSettings.PipeThermalConductivity"
      [inputWidth]="150"
      (valueChanged)="onValueChange($event)"
    >
    </app-form-input>
    <app-form-input
      class="grid-two-one"
      appUnwrap
      label="Screen Thermal Conductivity"
      [source]="settings"
      [sourceDefaults]="defaultSettings"
      [key]="'ScreenThermalConductivity'"
      [decimalPlaces]="4"
      [unitType]="UnitType.Thermal_Conductivity"
      [highlight]="settings.ScreenThermalConductivity !== defaultSettings.ScreenThermalConductivity"
      [inputWidth]="150"
      (valueChanged)="onValueChange($event)"
    >
    </app-form-input>
    <app-form-input
      class="grid-two-one"
      appUnwrap
      label="Cement Thickness (Radial)"
      [source]="settings"
      [sourceDefaults]="defaultSettings"
      [key]="'CementThickness'"
      [decimalPlaces]="4"
      [unitType]="UnitType.Diameter"
      [highlight]="settings.CementThickness !== defaultSettings.CementThickness"
      [inputWidth]="150"
      (valueChanged)="onValueChange($event)"
    >
    </app-form-input>
    <app-form-input
      class="grid-two-one"
      appUnwrap
      label="Cement Thermal Conductivity"
      [source]="settings"
      [sourceDefaults]="defaultSettings"
      [key]="'CementThermalConductivity'"
      [decimalPlaces]="4"
      [unitType]="UnitType.Thermal_Conductivity"
      [highlight]="settings.CementThermalConductivity !== defaultSettings.CementThermalConductivity"
      [inputWidth]="150"
      (valueChanged)="onValueChange($event)"
    >
    </app-form-input>
    <app-form-input
      class="grid-two-one"
      appUnwrap
      label="Filter Cake Thickness (Radial)"
      [source]="settings"
      [sourceDefaults]="defaultSettings"
      [key]="'FilterCakeThickness'"
      [decimalPlaces]="4"
      [unitType]="UnitType.Diameter"
      [highlight]="settings.FilterCakeThickness !== defaultSettings.FilterCakeThickness"
      [inputWidth]="150"
      (valueChanged)="onValueChange($event)"
    >
    </app-form-input>
    <app-form-input
      class="grid-two-one"
      appUnwrap
      label="Filter Cake Thermal Conductivity"
      [source]="settings"
      [sourceDefaults]="defaultSettings"
      [key]="'FilterCakeThermalConductivity'"
      [decimalPlaces]="4"
      [unitType]="UnitType.Thermal_Conductivity"
      [highlight]="settings.FilterCakeThermalConductivity !== defaultSettings.FilterCakeThermalConductivity"
      [inputWidth]="150"
      (valueChanged)="onValueChange($event)"
    >
    </app-form-input>
    <app-form-input
      class="grid-two-one"
      appUnwrap
      label="Min Deviation for Horizontal"
      [source]="settings"
      [sourceDefaults]="defaultSettings"
      [key]="'MinDeviationForHorizontal'"
      [decimalPlaces]="4"
      [unitType]="UnitType.Angle"
      [inputWidth]="150"
      [highlight]="settings.MinDeviationForHorizontal !== defaultSettings.MinDeviationForHorizontal"
      (valueChanged)="onValueChange($event)"
    >
    </app-form-input>
    <app-form-input
      class="grid-two-one"
      appUnwrap
      label="Horizontal Thermal Multiplier"
      [source]="settings"
      [sourceDefaults]="defaultSettings"
      [key]="'HorizontalThermalMultiplier'"
      [decimalPlaces]="4"
      [unitType]="UnitType.None"
      [highlight]="settings.HorizontalThermalMultiplier !== defaultSettings.HorizontalThermalMultiplier"
      [inputWidth]="150"
      (valueChanged)="onValueChange($event)"
    >
    </app-form-input>
  </div>
</app-page-panel>
