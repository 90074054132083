import { MASPCalculatorDto } from './masp-calculator.dto';
import { UpdateRowsWsAction } from '../../../ws.action';
import { IUpdateRowsProps } from '../../../common/common-store-crud.interfaces';
import { ValidatedMaspCalculatorModuleState } from './masp-calculator-module.state';
import { WsActionPropsFactory } from '../../../common/ws-action/ws-action-props.factory';
import { MASPCalculatorFactory } from './model/masp-calculator.factory';

export const MASPCalculatorModuleName = 'MASPCalculatorModule';

export enum MASPCalculatorModuleActionTypes {
  UpdateMASPCalculator = '[MASPCalculatorModule] Update',
  CalculateMASPAction = '[MASPCalculatorModule] Calculate',
  UpdateResult = '[MASPCalculatorModule] UpdateResult',
}

export interface LoadMASPCalculatorActionResponse {
  MASPCalculatorDto: MASPCalculatorDto;
}

export class UpdateMASPCalculatorAction extends UpdateRowsWsAction<MASPCalculatorDto> {
  public override readonly type = MASPCalculatorModuleActionTypes.UpdateMASPCalculator;

  constructor(action: IUpdateRowsProps<ValidatedMaspCalculatorModuleState>) {
    super(WsActionPropsFactory.updateAction(action, MASPCalculatorFactory.toDto));
  }
}

export class CalculateMASPAction extends UpdateRowsWsAction<{ ScenarioId: number }> {
  public override readonly type = MASPCalculatorModuleActionTypes.CalculateMASPAction;

  constructor(scenarioId: number) {
    super(WsActionPropsFactory.update([{ ScenarioId: scenarioId }], true));
  }
}

export type MASPCalculatorModuleActions = UpdateMASPCalculatorAction | CalculateMASPAction;
