<div class="input-container" [class.align-center]="alignCenter" [class.error]="!!errorMessage" [class.warning]="!!warningMessage">
  <app-select
    *ngIf="!renderAsLabel"
    [items]="dataSource"
    [selectPlaceholder]="placeholder"
    [source]="source"
    [key]="key"
    (valueChanged)="valuedChanged.emit($event)"
    [dataCy]="dataCy"
    (changeFocusCell)="changeFocusCell.emit($event)"
    [isGridInput]="true"
    (selectHide)="onHide()"
    #selectComponent
  >
  </app-select>
  <span
    *ngIf="renderAsLabel"
    tooltipPosition="top"
    positionStyle="absolute"
    [tooltipStyleClass]="tooltipStyleClass"
    class="grid-select-label"
    [tooltipDisabled]="!errorMessage && !warningMessage"
    [pTooltip]="tooltipMessage"
    [attr.data-cy]="dataCy"
  >{{ getLookupValue(dataSource) }}</span
  >
  <ng-template #errorMessageTemplate>{{ errorMessage }}</ng-template>
</div>
