import { BasePptSectionGenerator, PptContext } from '../base-ppt-section-generator';
import { IUnitSystemDto } from '@dunefront/common/dto/unit-system.dto';
import { firstValueFrom } from 'rxjs';
import { selectCurrentUnitSystem } from '../../../../../../+store/units/units.selectors';
import { CommonReportDataHelpers } from '../../common/common-report-data-helpers';
import { PptDocumentTableGeneratorHelper } from '../ppt-document-table-generator.helper';
import { ReportScenarioImages } from '../../doc/sections/chart-report-generator.helper';
import { ConvertUnitPipe } from '@dunefront/common/modules/units/convert-unit.pipe/convert-unit.pipe';
import { getPSDAnalysis } from '../../../../../../+store/psd-analysis/psd-analysis.selectors';

export class PsdAnalysisDataPptGenerator extends BasePptSectionGenerator {
  constructor(
    protected override context: PptContext & {
      images: ReportScenarioImages;
      convertUnitPipe: ConvertUnitPipe;
    },
  ) {
    super(context);
  }

  public async generate(): Promise<void> {
    const { store, images } = this.context;
    const currentUnitSystem: IUnitSystemDto = await firstValueFrom(this.store.select(selectCurrentUnitSystem));
    const psdAnalysisState = await firstValueFrom(store.select(getPSDAnalysis));

    if (images.psdAnalysisChart) {
      this.addImageSlide({ title: 'PSD Analysis - Consolidated PSD Chart', image: images.psdAnalysisChart });
    }

    if (psdAnalysisState.IsCalculateSandProduction) {
      const tableData = CommonReportDataHelpers.createPsdSandProductionTableData(psdAnalysisState, currentUnitSystem);
      const tableRows = PptDocumentTableGeneratorHelper.createGenericKeyValueTableRowsWithTableData(tableData, currentUnitSystem);

      this.addKeyValueTableSlide({ title: 'PSD - Calculate Sand Production through Wire-Wrap Screens', tableRows });
    }
  }
}
