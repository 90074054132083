import { Injectable } from '@angular/core';
import { ColumnNameGeneratorService } from '../../import-paste-data-common/column-name-generator.service';
import {
  ImportDataModuleState,
  initialDelimiterConfig,
  initialFilePropertiesConfig,
} from '../../../../+store/import-data/import-data-module.state';
import { decodeTemplateId } from '@dunefront/common/common/templates/template-parser';
import { filterWithoutDeltaTime } from '@dunefront/common/modules/data-storage/dto/import-template/import-template.dto';
import { FileParserService } from '../../import-paste-data-common/file-parser/file-parser.service';
import { DELTA_TIME_COL_NAME } from '../../../../+store/import-data/model/col-config';

@Injectable()
export class ImportTemplateService {
  constructor(
    private columnNameGenerator: ColumnNameGeneratorService,
    private parser: FileParserService,
  ) {}

  public applyTemplate(
    state: ImportDataModuleState,
    selectedTemplateId: string,
    applyFilePropertiesConfig: boolean,
  ): ImportDataModuleState {
    this.columnNameGenerator.resetColNames();
    const { id, type } = decodeTemplateId(selectedTemplateId);
    if (!state.fileName || id === -1) {
      if (applyFilePropertiesConfig) {
        return {
          ...state,
          selectedTemplateId,
          templateError: '',
          colConfigs: [...state.originalFileConfigs],
          includedColumnIds: [...state.originalIncludedColumnIds],
          delimiterConfig: { ...initialDelimiterConfig },
          filePropertiesConfig: { ...initialFilePropertiesConfig },
        };
      } else {
        return state;
      }
    }

    if (id === -2) {
      return state;
    }
    const template = state.templates.find((tmpl) => tmpl.Id === id && tmpl.Type === type);
    if (!template) {
      return {
        ...state,
        selectedTemplateId,
        templateError: `Template with id: ${selectedTemplateId} not found`,
      };
    }

    const templateConfig = template.TemplateConfig;
    const columnDelimiter = this.parser.getColumnDelimiter(templateConfig.delimiterConfig);
    const parsedColConfigs =
      columnDelimiter == null
        ? []
        : this.parser
            .tryReadFileData(this.parser.fileLines, columnDelimiter, false, templateConfig.filePropertiesConfig)
            .columnsWithUnits.filter((colWithUnit) => colWithUnit.columnName !== DELTA_TIME_COL_NAME);

    if (templateConfig.colConfigs.length !== parsedColConfigs.length) {
      return {
        ...state,
        selectedTemplateId,
        importDataType: templateConfig.importDataType,
        templateError: "The selected template can't be applied to the file as they have a different number of columns",
      };
    }

    const colConfigs = state.originalFileConfigs.map((colConfig, index) => {
      const colConfigTmpl = templateConfig.colConfigs.filter(filterWithoutDeltaTime).find((col) => col.colIndex === colConfig.colIndex);
      if (!colConfigTmpl) {
        return colConfig;
      }

      // override configuration from template
      return {
        ...colConfig,
        name: this.columnNameGenerator.getColName(colConfigTmpl.name, index + 1),
        columnType: colConfigTmpl.columnType,
        isTimeCustomFormat: colConfigTmpl.isTimeCustomFormat,
        isXAxis: colConfigTmpl.isXAxis,
        measurementType: colConfigTmpl.measurementType,
        timeCustomFormat: colConfigTmpl.timeCustomFormat,
        unitSymbol: colConfigTmpl.unitSymbol,
        unitSystem: colConfigTmpl.unitSystem,
        measuredDepth: state.measuredDepth,
      };
    });

    return {
      ...state,
      selectedTemplateId: selectedTemplateId,
      includedColumnIds: applyFilePropertiesConfig ? templateConfig.includedColumnIds : state.includedColumnIds,
      importDataType: applyFilePropertiesConfig ? templateConfig.importDataType : state.importDataType,
      filePropertiesConfig: applyFilePropertiesConfig ? templateConfig.filePropertiesConfig : state.filePropertiesConfig,
      delimiterConfig: applyFilePropertiesConfig ? templateConfig.delimiterConfig : state.delimiterConfig,
      colConfigs,
      templateError: '',
    };
  }
}
