import { createReducer, on } from '@ngrx/store';
import { licensingModuleInitialState, LicensingModuleState } from '@dunefront/common/modules/licensing/licensing-module.state';
import {
  toggleAddonAction,
  detachLicenseSuccessAction,
  licenseInfoLoadedAction,
  licenseReloginUserSuccessAction,
  licenseSwitchAction,
  licenseSwitchSuccessAction,
  licensingFailureAction,
  refreshLicenseInfoSuccessAction,
  updateMainLicenseAction,
} from './licensing.actions';
import * as dbConnectionActions from '../backend-connection/backend-connection.actions';
import { loadCommonDbInitialSuccess } from '../common-db/common-db.actions';
import { LicenseFeature } from '@dunefront/common/modules/licensing/licensing.interfaces';

export const licensingModuleReducer = createReducer<LicensingModuleState>(
  licensingModuleInitialState,
  on(licensingFailureAction, (): LicensingModuleState => ({ ...licensingModuleInitialState, isLoaded: true })),
  on(
    licenseInfoLoadedAction,
    refreshLicenseInfoSuccessAction,
    (state, action): LicensingModuleState => ({ ...state, isLoaded: true, licenseInfo: action.licenseInfo }),
  ),
  on(
    dbConnectionActions.dbConnectAction,
    (state, action): LicensingModuleState => ({ ...state, licensingLogin: undefined, mainFeature: action.licensingConfig.feature }),
  ),
  on(
    licenseSwitchAction,
    dbConnectionActions.dbDisconnectAction,
    (state): LicensingModuleState => ({ ...state, licensingLogin: undefined, mainFeature: undefined }),
  ),
  on(
    dbConnectionActions.dbConnectedSuccessAction,
    licenseReloginUserSuccessAction,
    (state, action): LicensingModuleState => ({
      ...state,
      licensingLogin: action.payload.licensingLoginResponse,
      mainFeature: action.payload.licensingLoginResponse.mainFeature,
    }),
  ),
  on(
    loadCommonDbInitialSuccess,
    (state, action): LicensingModuleState => ({
      ...state,
      selectedAddons: action.payload.customSettings.licensing?.selectedLicenseAddonFeatures ?? [],
    }),
  ),
  on(
    licenseSwitchSuccessAction,
    (state, action): LicensingModuleState => ({
      ...state,
      licensingLogin: action.licensingLoginResponse,
      mainFeature: action.licensingLoginResponse.mainFeature,
    }),
  ),
  on(
    toggleAddonAction,
    (state, action): LicensingModuleState => ({
      ...state,
      selectedAddons: state.selectedAddons.includes(action.licenseFeature)
        ? state.selectedAddons.filter((addon) => addon !== action.licenseFeature)
        : [...state.selectedAddons, action.licenseFeature],
    }),
  ),
  on(
    detachLicenseSuccessAction,
    (state, action): LicensingModuleState => ({
      ...state,
      selectedAddons: state.selectedAddons.filter((addon) => addon != LicenseFeature.Detaching),
    }),
  ),
  on(updateMainLicenseAction, (state, action): LicensingModuleState => ({ ...state, mainFeature: action.feature })),
);
