<app-page-panel>
  <app-searchbar (searchChanged)="searchPhrase$.next($event)"></app-searchbar>
</app-page-panel>
<app-page-panel header="Database Fluids" class="grid-panel" (click)="onHelpChange('fluids', 'fluid-database')">
  <ng-container *ngIf="filteredCommonFluids$ | async as filteredCommonFluids">
    <app-database-fluids-grid
      *ngIf="filteredCommonFluids.rows.length"
      #gridComponent
      [customKeyboardHandlers]="getCustomKeyboardHandlers"
      [commonFluidData]="filteredCommonFluids"
      (selectedRowsChanged)="onSelectedRowChanged($event)"
    ></app-database-fluids-grid>
  </ng-container>
</app-page-panel>

<div class="buttons-panel">
  <div class="align-right">
    <button
      appButton
      data-cy="database-fluids-load"
      type="button"
      class="btn btn-primary btn-sm"
      (buttonClick)="onLoadFromDatabase()"
      [disabled]="!selectedRow"
      [isUiLockable]="false"
    >
      Load from Database
    </button>
    <button
      appButton
      data-cy="database-fluids-delete"
      type="button"
      class="btn btn-primary btn-sm"
      (buttonClick)="onDelete()"
      [disabled]="!selectedRow || selectedRow.rowData.commonDbType === 'Predefined'"
      [isUiLockable]="false"
    >
      Delete
    </button>
  </div>
</div>
