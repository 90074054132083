<div class="grid-form-container" *ngIf="state">
  <label>{{ isSaveAsTemplateMode ? 'Used Template' : 'Select Template' }}</label>
  <app-select
    [items]="templateItems"
    [value]="selectedTemplateId"
    [width]="200"
    (primitiveValueChanged)="onTemplateChanged($event)"
    [disabled]="isSaveAsTemplateMode || !state.fileName"
    [overrideErrorMessage]="state.templateError"
    dataCy="template-manager-select"
  ></app-select>

  <div class="buttons-panel">
    <div class="align-left">
      <ng-container *ngIf="isSaveAsTemplateMode">
        <button
          appButton
          type="button"
          data-cy="import-data-template-save"
          class="btn btn-primary btn-sm"
          [disabled]="disabled"
          (buttonClick)="onSaveAsTemplateClick()"
        >
          {{ selectedTemplate ? 'Save as new' : 'Save' }}
        </button>
      </ng-container>
      <ng-container *ngIf="!isSaveAsTemplateMode">
        <button appButton type="button" class="btn btn-primary btn-sm" [disabled]="!isEditDeleteEnabled" (buttonClick)="onRenameClick()">
          Rename
        </button>
        <button
          appButton
          data-cy="import-data-template-delete"
          type="button"
          class="btn btn-primary btn-sm"
          [disabled]="!isEditDeleteEnabled"
          (buttonClick)="onDeleteClick()"
        >
          Delete
        </button>
      </ng-container>
    </div>
  </div>
</div>
