export const accentColor = '4F81BD';
export const accentSubColor = '767171';
export const whiteColor = 'FFFFFF';
export const blueColor = '4F81BD';
export const lightBlueColor = 'D3DFEE';

export const mainFont = 'Verdana';

export const headerFontSize = 24;
export const subHeaderFontSize = 20;
export const defaultFontSize = 16;
export const pageWidthDXA = 9638;
export const defaultIndexCellWidth = 6;

export const chartConfigurationRequiredMessage =
  'Please run calculations or use the edit chart button located on the charts toolbar above to generate a chart.';
