import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { Action, ActionReducer, ActionReducerMap } from '@ngrx/store';
import { settingsModuleReducer } from './settings/settings.reducer';
import { calculationEngineModuleReducer } from './calculation-engine/calculation-engine.reducer';
import { completionModuleReducer } from './completion/completion.reducer';
import { fluidModuleReducer } from './fluid/fluid.reducer';
import { gravelModuleReducer } from './gravel/gravel.reducer';
import { pumpingModuleReducer } from './pumping/pumping.reducer';
import { reportingModuleReducer } from './reporting/reporting.reducer';
import { scenarioModuleReducer, ScenarioModuleState } from './scenario/scenario.reducer';
import { uiReducer } from './ui/ui.reducer';
import { wellModuleReducer } from './well/well.reducer';
import { aboutModuleReducer, AboutModuleState } from './about/about.reducer';
import { dbMigrationModuleReducer, DbMigrationModuleState } from './db-migration/db-migration.reducer';
import { fileManagerModuleReducer, FileManagerModuleState } from './file-manager/file-manager.reducer';
import { unitsModuleReducer } from './units/units.reducer';
import { IUnitsModuleState } from './units/units.state';
import { IUndoRedoModuleState, undoRedoModuleReducer } from './undo-redo/undo-redo.reducer';
import { commonDbModuleReducer, ICommonDbModuleState } from './common-db/common-db.reducer';
import { authModuleReducer, IAuthState } from './auth/auth.reducer';
import { maspCalculatorModuleReducer } from './calculators/masp-calculator/masp-calculator.reducer';
import { psdAnalysisModuleReducer } from './psd-analysis/psd-analysis.reducer';
import { WellModuleState } from '@dunefront/common/modules/well/well-module.state';
import { PumpingModuleState } from '@dunefront/common/modules/pumping/pumping-module.state';
import { CompletionModuleState } from '@dunefront/common/modules/completion/completion-module.state';
import { SettingsModuleState } from '@dunefront/common/modules/settings/settings-module.state';
import { FluidModuleState } from '@dunefront/common/modules/fluid/fluid-module.state';
import { GravelModuleState } from '@dunefront/common/modules/gravel/gravel-module.state';
import { PSDAnalysisModuleState } from '@dunefront/common/modules/psd-analysis/psd-analysis.module.state';
import { FrictionCalculatorModuleState } from '@dunefront/common/modules/calculators/friction-calculator/friction-calculator-module.state';
import { InjectionTestCalculatorModuleState } from '@dunefront/common/modules/calculators/injection-test-calculator/injection-test-calculator-module.state';
import { LeakoffCoefficientCalculatorModuleState } from '@dunefront/common/modules/calculators/leakoff-coefficient-calculator/leakoff-coefficient-calculator-module.state';
import { frictionCalculatorModuleReducer } from './calculators/friction-calculator/friction-calculator.reducer';
import { injectionTestCalculatorModuleReducer } from './calculators/injection-test-calculator/injection-test-calculator.reducer';
import { leakoffCoefficientCalculatorModuleReducer } from './calculators/leakoff-coefficient-calculator/leakoff-coefficient-calculator.reducer';
import { MASPCalculatorModuleState } from '@dunefront/common/modules/calculators/masp-calculator/masp-calculator-module.state';
import { ResuspensionCalculatorModuleState } from '@dunefront/common/modules/calculators/resuspension-calculator/resuspension-calculator-module.state';
import { resuspensionCalculatorModuleReducer } from './calculators/resuspension-calculator/resuspension-calculator.reducer';
import { SettlingCalculatorModuleState } from './calculators/settling-calculator/settling-calculator-module.state';
import { settlingCalculatorModuleReducer } from './calculators/settling-calculator/settling-calculator.reducer';
import { importDataModuleReducer } from './import-data/import-data.reducer';
import { ImportDataModuleState } from './import-data/import-data-module.state';
import { CalculationEngineState } from './calculation-engine/calculation-engine-module.state';
import { UiState } from './ui/ui-module.state';
import { gaugeDataModuleReducer } from './gauge-data/gauge-data.reducer';
import { GaugeDataModuleState } from './gauge-data/gauge-data-module.state';
import { ReportingModuleState } from './reporting/reporting-module.state';
import { DataStorageModuleState } from '@dunefront/common/modules/data-storage/data-storage-module.state';
import { dataStorageModuleReducer } from './data-storage/data-storage.reducer';
import { RangeModuleState } from '@dunefront/common/modules/range/range-module.state';
import { rangeModuleReducer } from './range/range.reducer';
import { fileSettingsModuleReducer, FileSettingsModuleState } from './file-settings/file-settings.reducer';
import { summaryModuleReducer, SummaryModuleState } from './summary/summary.reducer';
import { reportInfoModuleReducer, ReportInfoModuleState } from './report-info/report-info.reducer';
import { ReferenceVariablesModuleState } from '@dunefront/common/modules/reference-variables/reference-variables-module.state';
import { referenceVariablesReducer } from './reference-variables/reference-variables.reducer';
import { licensingModuleReducer } from './licensing/licensing.reducer';
import { LicensingModuleState } from '@dunefront/common/modules/licensing/licensing-module.state';
import { MetaReducer } from '@ngrx/store/src/models';
import { equationModuleReducer } from './equation/equation.reducer';
import { EquationModuleState } from '@dunefront/common/modules/equation/equation-module.state';
import { jsWorkerJobsReducer } from './calculation-engine/js-worker-jobs.reducer';
import { JsWorkerJobsState } from './calculation-engine/js-worker-jobs-state';
import { noteModuleReducer, NoteModuleState } from './note/note.reducers';

export interface IAppState {
  auth: IAuthState;
  ui: UiState;
  router: RouterReducerState;
  well: WellModuleState;
  pumping: PumpingModuleState;
  fluid: FluidModuleState;
  gravel: GravelModuleState;
  gaugeData: GaugeDataModuleState;
  completion: CompletionModuleState;
  settings: SettingsModuleState;
  scenario: ScenarioModuleState;
  reporting: ReportingModuleState;
  calculationEngine: CalculationEngineState;
  jsWorker: JsWorkerJobsState;
  about: AboutModuleState;
  dbMigration: DbMigrationModuleState;
  fileManager: FileManagerModuleState;
  units: IUnitsModuleState;
  undoRedo: IUndoRedoModuleState;
  commonDb: ICommonDbModuleState;
  maspCalculator: MASPCalculatorModuleState;
  resuspensionCalculator: ResuspensionCalculatorModuleState;
  frictionCalculator: FrictionCalculatorModuleState;
  settlingCalculator: SettlingCalculatorModuleState;
  injectionTestCalculator: InjectionTestCalculatorModuleState;
  leakoffCoefficientCalculator: LeakoffCoefficientCalculatorModuleState;
  psdAnalysis: PSDAnalysisModuleState;
  importData: ImportDataModuleState;
  dataStorage: DataStorageModuleState;
  range: RangeModuleState;
  fileSettings: FileSettingsModuleState;
  summary: SummaryModuleState;
  reportInfo: ReportInfoModuleState;
  referenceVariables: ReferenceVariablesModuleState;
  licensing: LicensingModuleState;
  equation: EquationModuleState;
  note: NoteModuleState;
}

export const appReducers: ActionReducerMap<IAppState> = {
  about: aboutModuleReducer,
  settings: settingsModuleReducer,
  auth: authModuleReducer,
  dbMigration: dbMigrationModuleReducer,
  calculationEngine: calculationEngineModuleReducer,
  jsWorker: jsWorkerJobsReducer,
  commonDb: commonDbModuleReducer,
  completion: completionModuleReducer,
  dataStorage: dataStorageModuleReducer,
  fileManager: fileManagerModuleReducer,
  fileSettings: fileSettingsModuleReducer,
  fluid: fluidModuleReducer,
  frictionCalculator: frictionCalculatorModuleReducer,
  gaugeData: gaugeDataModuleReducer,
  gravel: gravelModuleReducer,
  importData: importDataModuleReducer,
  injectionTestCalculator: injectionTestCalculatorModuleReducer,
  leakoffCoefficientCalculator: leakoffCoefficientCalculatorModuleReducer,
  maspCalculator: maspCalculatorModuleReducer,
  psdAnalysis: psdAnalysisModuleReducer,
  pumping: pumpingModuleReducer,
  range: rangeModuleReducer,
  reporting: reportingModuleReducer,
  resuspensionCalculator: resuspensionCalculatorModuleReducer,
  router: routerReducer,
  scenario: scenarioModuleReducer,
  settlingCalculator: settlingCalculatorModuleReducer,
  ui: uiReducer,
  undoRedo: undoRedoModuleReducer,
  units: unitsModuleReducer,
  well: wellModuleReducer,
  summary: summaryModuleReducer,
  reportInfo: reportInfoModuleReducer,
  referenceVariables: referenceVariablesReducer,
  licensing: licensingModuleReducer,
  equation: equationModuleReducer,
  note: noteModuleReducer,
};

export const metaReducers = (isProductionEnv: boolean): MetaReducer[] => (!isProductionEnv ? [logger] : []);

export function logger(reducerToLog: ActionReducer<any>): ActionReducer<any> {
  return (state: any, action: Action): ActionReducer<any> => {
    // uncomment for debugging
    // console.warn('state', state);
    // console.warn(`action ${action.type}`, action);
    return reducerToLog(state, action);
  };
}
