import { PSDAnalysis } from './psd-analysis';
import { PsdSandProductionInputs } from '../../dto/psd-sand-production.inputs';

export class PSDAnalysisFactory {
  public static create(psdAnalysisDto: PsdSandProductionInputs): PSDAnalysis {
    return {
      ...psdAnalysisDto,
      error: {},
      isValid: true,
    };
  }

  public static toDto(psdAnalysis: PSDAnalysis): PsdSandProductionInputs {
    const { error, isValid, ...dto } = psdAnalysis;
    return dto;
  }
}
