import { createAction, props } from '@ngrx/store';
import { Dict } from '@dunefront/common/common/state.helpers';
import { ValidatedSettlingCalculator } from './settling-calculator-module.state';
import { Fluid } from '@dunefront/common/modules/fluid/model/fluid';
import { IUpdateRowsProps } from '@dunefront/common/common/common-store-crud.interfaces';
import { Gravel } from '@dunefront/common/modules/gravel/model/gravel';

export const updateSettlingCalculatorRow = createAction(
  '[calculators] updateSettlingCalculatorRow',
  props<IUpdateRowsProps<ValidatedSettlingCalculator>>(),
);

export const calculateSettling = createAction(
  '[calculators] calculateSettling',
  props<{
    ScenarioId: number;
    StaticViscosity: number;
  }>(),
);

export interface ISettlingValidationDependencies {
  fluids: Dict<Fluid>;
  gravels: Dict<Gravel>;
}
