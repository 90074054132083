import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CommonModalComponentBase } from './common-modal.component.base';
import { ButtonType } from '../../../shared/components/button/button.base.component';

export interface ConfirmModalContentComponentData {
  title: string;
  message: string | string[];
  buttons: ConfirmModalContentComponentButton[];
}

export interface ConfirmModalContentComponentButton<T = any> {
  text: string;
  type: ButtonType;
  result: T;
  dataCy?: string;
  cssClass?: 'btn-light' | 'btn-danger';
}

@Component({
  selector: 'app-confirm-modal-content',
  template: `
    <div class="dialog-header" *ngIf="title">
      <h4>{{ title }}</h4>
    </div>
    <div class="dialog-content" data-cy="confirm-modal-content">
      <p *ngFor="let message of messages" [innerHTML]="message"></p>
    </div>
    <div class="dialog-footer">
      <button
        *ngFor="let button of buttons"
        appButton
        type="button"
        [dialogRef]="ref"
        [class]="'btn btn-sm ' + (button.cssClass ?? '')"
        [buttonType]="button.type"
        [attr.data-cy]="button.dataCy ?? ''"
        (buttonClick)="ref.close(button.result)"
        [isUiLockable]="false"
      >
        {{ button.text }}
      </button>
    </div>
  `,
  styles: ['.btn { min-width: 50px; } .modal-body { overflow-y: auto; max-height: 300px; }'],
})
export class ConfirmModalContentComponent extends CommonModalComponentBase {
  public buttons: ConfirmModalContentComponentButton[] = [];

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig<ConfirmModalContentComponentData>,
  ) {
    super(config.data?.title ?? '', config.data?.message ?? '');
    this.buttons = config.data?.buttons ?? [];
  }

  public okClicked(): void {
    this.ref.close(true);
  }
}
