<div class="project-options-grid" *ngIf="settings.isLoaded && (referenceVariables$ | async)" [class.fluidPro]="isFluidPro">
  <app-general-options
    [settings]="settings"
    [defaultSettings]="defaultSettings"
    (click)="onHelpChange('settings', 'general')"
    (settingsValueChange)="submitOption($event)"
  >
  </app-general-options>
  <app-performance-options
    *ngIf="!isFluidPro"
    [settings]="settings"
    [defaultSettings]="defaultSettings"
    (click)="onHelpChange('settings', 'performance')"
    (settingsValueChange)="submitOption($event)"
  >
  </app-performance-options>
  <app-friction-options
    [settings]="settings"
    [defaultSettings]="defaultSettings"
    (click)="onHelpChange('settings', 'friction')"
    (settingsValueChange)="submitOption($event)"
  >
  </app-friction-options>
  <app-packing-options
    [settings]="settings"
    [defaultSettings]="defaultSettings"
    (click)="onHelpChange('settings', 'packing')"
    (settingsValueChange)="submitOption($event)"
  >
  </app-packing-options>
  <app-thermal-options
    *ngIf="!isFluidPro"
    [settings]="settings"
    [defaultSettings]="defaultSettings"
    (click)="onHelpChange('settings', 'thermal')"
    (settingsValueChange)="submitOption($event)"
  >
  </app-thermal-options>

  <app-fluid-pro-options
    *ngIf="isFluidPro"
    [settings]="settings"
    [defaultSettings]="defaultSettings"
    (helpChanged)="onHelpChange('settings', $event)"
    (settingsValueChange)="submitOption($event)" >

  </app-fluid-pro-options>
  <div class="buttons-panel">
    <button appButton type="button" class="btn btn-primary btn-sm" [isDeleteResultsEnabled]="false" (buttonClick)="resetDefault()">
      Reset
    </button>
  </div>
</div>
