import { PSDAnalysis } from './psd-analysis';
import { DictionaryWithArray, noErrors } from '../../../../common/state.helpers';
import { IError } from '../../../../common/common-state.interfaces';
import { PSDReadingsValidation } from '../psd-readings/psd-readings.validation';
import { PSDValidation } from '../psd/psd.validation';
import { PSDAnalysisModuleState, ValidatedPSDAnalysisModuleState } from '../../psd-analysis.module.state';
import { PsdSandProductionInputs } from '../../dto/psd-sand-production.inputs';

export class PSDAnalysisValidation {
  public static validate(state: PSDAnalysisModuleState): ValidatedPSDAnalysisModuleState {
    const psdReadings = PSDReadingsValidation.ValidatePSDReadings(state.PSDReadings, state.PSD);
    const psd = PSDValidation.ValidatePSD(state.PSD, psdReadings);
    const psdAnalysis = PSDAnalysisValidation.ValidatePSDAnalysis(state.PSDAnalysis);
    const isValid = DictionaryWithArray.isValid(psdReadings) && DictionaryWithArray.isValid(psd) && noErrors(psdAnalysis.error);
    return {
      ...state,
      PSD: psd,
      PSDReadings: psdReadings,
      PSDAnalysis: psdAnalysis,
      error: {},
      isValid,
    };
  }

  public static ValidatePSDAnalysis(psdAnalysis: PsdSandProductionInputs): PSDAnalysis {
    if (!psdAnalysis.IsCalculateSandProduction) {
      return { ...psdAnalysis, error: {}, isValid: true };
    }

    const error: IError<PSDAnalysis> = {
      SandDensity: this.ValidateSandDensity(psdAnalysis.SandDensity),
      IntervalLength: this.ValidateIntervalLength(psdAnalysis.IntervalLength),
      ScreenSlotSize: this.ValidateScreenSlotSize(psdAnalysis.ScreenSlotSize),
      ScreenWireWidth: this.ValidateScreenWireWidth(psdAnalysis.ScreenWireWidth),
      ScreenJointLength: this.ValidateScreenJointLength(psdAnalysis.ScreenJointLength, psdAnalysis.ScreenHandlingLength),
      ScreenHandlingLength: this.ValidateScreenHandlingLength(psdAnalysis.ScreenJointLength, psdAnalysis.ScreenHandlingLength),
      ScreenFilterOD: this.ValidateScreenFilterOD(psdAnalysis.ScreenFilterOD),
    };

    return { ...psdAnalysis, error, isValid: noErrors(error) };
  }

  private static ValidateSandDensity(sandDensity: number): string {
    if (sandDensity <= 0) {
      return 'Sand density must be greater than 0';
    }
    return '';
  }

  private static ValidateIntervalLength(intervalLength: number): string {
    if (intervalLength <= 0) {
      return 'Interval length must be greater than 0';
    }
    return '';
  }

  private static ValidateScreenSlotSize(screenSlotSize: number): string {
    if (screenSlotSize <= 0) {
      return 'Screen slot size must be greater than 0';
    }
    return '';
  }

  private static ValidateScreenWireWidth(screenWireWidth: number): string {
    if (screenWireWidth <= 0) {
      return 'Screen wire width must be greater than 0';
    }
    return '';
  }

  private static ValidateScreenJointLength(screenJointLength: number, screenHandlingLength: number): string {
    if (screenJointLength <= 0) {
      return 'Screen joint length must be greater than 0';
    }
    if (screenJointLength <= screenHandlingLength) {
      return 'Screen joint length must be greater than screen handling length';
    }
    return '';
  }

  private static ValidateScreenHandlingLength(screenJointLength: number, screenHandlingLength: number): string {
    if (screenHandlingLength <= 0) {
      return 'Screen handling length must be greater than 0';
    }
    if (screenJointLength <= screenHandlingLength) {
      return 'Screen handling length must be less than screen joint length';
    }
    return '';
  }

  private static ValidateScreenFilterOD(screenFilterOD: number): string {
    if (screenFilterOD <= 0) {
      return 'Screen filter OD must be greater than 0';
    }
    return '';
  }
}
