import { Color, HorizontalAlignment, LineStyle, VerticalAlignment } from './chart.types';

export interface IChartMarkerDto {
  Id: number;
  ScenarioId: number;
  ChartId: number;
  SortOrder: number;
  Text: string;
  Value: number;
  IsOverrideChartMarker: boolean;
  ChartMarkerLineStyle: LineStyle;
  ChartMarkerLineThickness: number;
  ChartMarkerLineColor: Color;
  ChartMarkerHorizontalTextAlignment: HorizontalAlignment;
  ChartMarkerHorizontalTextIndentation: number;
  ChartMarkerVerticalTextAlignment: VerticalAlignment;
  ChartMarkerVerticalTextIndentation: number;
  ChartMarkerFontSize: number;
  IsChartMarkerFontBold: boolean;
  IsChartMarkerFontItalic: boolean;
  ChartMarkerFontColor: Color;
  IsValueAxisMarker: boolean;
}

export interface IMarker {
  id?: number;
  chartId?: number;
  scenarioId?: number;
  sortOrder?: number;
  name: string;
  value: number;
  isOverrideStyle: boolean;
  style: IMarkerStyle;
  isValueAxisMarker: boolean;
}

export interface IMarkerStyle {
  ChartMarkerLineStyle: LineStyle;
  ChartMarkerLineThickness: number;
  ChartMarkerLineColor: Color;
  ChartMarkerHorizontalTextAlignment: HorizontalAlignment;
  ChartMarkerHorizontalTextIndentation: number;
  ChartMarkerVerticalTextAlignment: VerticalAlignment;
  ChartMarkerVerticalTextIndentation: number;
  ChartMarkerFontSize: number;
  IsChartMarkerFontBold: boolean;
  IsChartMarkerFontItalic: boolean;
  ChartMarkerFontColor: Color;
}

export enum MarkerShape {
  'circle' = 0,
  'cross' = 1,
  'crossRot' = 2,
  'dash' = 3,
  'line' = 4,
  'rect' = 5,
  'rectRounded' = 6,
  'rectRot' = 7,
  'star' = 8,
  'triangle' = 9,
}
