import { IAffectedModels } from '../dto/dto.common';
import { CopyRowsWsAction, DeleteRowsWsAction, InsertRowsWsAction, ReorderRowWsAction, UpdateRowsWsAction, WsAction } from '../ws.action';
import { IUserId } from './auth/auth.interfaces';
import { ModuleType } from './scenario/scenario.dto';
import { IActivityOverlayConfig } from './ui/ui.interfaces';
import { ActionResponse } from '../response-ws.action';

export interface CrudResponse {
  affectedRows: Partial<IAffectedModels>;
  scenarioId: number;
  undoInfo?: IUndoInfo<any>;
  undoOrRedo?: UndoOrRedo;
  commonDbCopyDirection?: CommonDbCopyDirection;
  error?: CrudResponseError;
  progress?: IProgressUpdatePayload;
  rangeId?: number;
  isTriggeredFromUiUpdate?: boolean;
}

export type UpsertDbOperation = 'insert' | 'update';

export interface UpsertCrudResponse extends CrudResponse {
  performedDbOperation: UpsertDbOperation;
}

export const toActionResponseUpsertCrudResponse = (
  crudResponse: ActionResponse<CrudResponse>,
  performedDbOperation: UpsertDbOperation,
): ActionResponse<UpsertCrudResponse> => ({
  ...crudResponse,
  payload: crudResponse.payload
    ? {
        ...crudResponse.payload,
        performedDbOperation: performedDbOperation,
      }
    : undefined,
});

export interface CrudResponseError {
  message: string;
  moduleType: ModuleType;
}

export const UpdateNotificationActionName = 'UiUpdate';

export enum UiActionTypes {
  UiActivityOverlayShow = '[UiUpdate] UiActivityOverlayShow',
  UiActivityOverlayHide = '[UiUpdate] UiActivityOverlayHide',
  UiActivityProgressUpdate = '[UiUpdate] UiActivityProgressUpdate',
}

export class UiActivityOverlayShowAction extends WsAction {
  public override readonly type = UiActionTypes.UiActivityOverlayShow;

  constructor(
    public override activityOverlayConfig: IActivityOverlayConfig,
    public override taskId: string,
    public actionType: string,
    public fileHash: string,
  ) {
    super();
  }
}

export class UiActivityOverlayCloseAction extends WsAction {
  public override readonly type = UiActionTypes.UiActivityOverlayHide;

  constructor(
    public override taskId: string,
    public fileHash: string,
  ) {
    super();
  }
}

export class ProgressUpdateAction extends WsAction {
  public override readonly type = UiActionTypes.UiActivityProgressUpdate;

  constructor(
    taskId: string,
    public override payload: CrudResponse,
  ) {
    super();
    this.taskId = taskId;
  }
}

export type UiModuleActions = UiActivityOverlayShowAction | ProgressUpdateAction | UiActivityOverlayCloseAction;

export interface IProgressUpdatePayload {
  progress: number;
  message?: string;
}

export type UndoOrRedo = 'undo' | 'redo';

export interface IUndoInfo<T> {
  undo: UpdateRowsWsAction<T> | InsertRowsWsAction<T> | DeleteRowsWsAction | ReorderRowWsAction<T> | WsAction;
  redo: UpdateRowsWsAction<T> | InsertRowsWsAction<T> | DeleteRowsWsAction | ReorderRowWsAction<T> | CopyRowsWsAction | WsAction;
}

export interface IUpdateProgressPayload {
  userId: IUserId | undefined;
  taskId: string;
  progress: number;
  message: string;
}

export const getUpdateProgressPayload = (
  userId: IUserId | undefined,
  taskId: string,
  progress: number,
  message: string,
): IUpdateProgressPayload => ({ progress, taskId, message, userId });

export enum CommonDbCopyDirection {
  toCommonDb = 0,
  fromCommonDb = 1,
}
