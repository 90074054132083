import { NgModule } from '@angular/core';
import { FluidColorSelectorComponent } from './fluid-color-selector.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SelectModule } from '../../../shared/components/form-components/select/select.module';
import { FormInputModule } from '../../../shared/components/form-components/form-input/form-input.component';

@NgModule({
  declarations: [FluidColorSelectorComponent],
  imports: [CommonModule, FormsModule, SelectModule, FormInputModule],
  exports: [FluidColorSelectorComponent],
})
export class FluidColorSelectorModule {}
