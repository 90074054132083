import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnChanges,
  OnDestroy,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { ILeftNavElement, ILeftNavItem } from '../left-nav/left-nav.helpers';
import { RouterHelperService } from '../../services/router-helper.service';
import { Store } from '@ngrx/store';
import { ButtonModule } from '../button/button.module';
import { ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-prev-next-nav',
  templateUrl: './prev-next-nav.component.html',
  styleUrls: ['./prev-next-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrevNextNavComponent implements OnChanges, OnDestroy {
  @Input() public menuData: ILeftNavItem[] | null = [];
  @Input() public moduleType: ModuleType = ModuleType.None;
  @Output() public showButtons = new EventEmitter<boolean>();
  @Output() private activeMenuItemChanged = new EventEmitter();

  private currAvailableLinks: string[] = [];
  private buttonsVisible = false;
  public routerSubscription = new Subscription();
  public prevDisabled = true;
  public nextDisabled = true;

  constructor(
    private router: Router,
    private routerHelper: RouterHelperService,
    private store: Store,
    private cdRef: ChangeDetectorRef,
  ) {
    this.routerSubscription.add(
      router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event) => this.updateAvailableLinks()),
    );
  }

  public ngOnChanges(): void {
    this.updateAvailableLinks();
  }

  public ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
  }

  private updateAvailableLinks(): void {
    this.currAvailableLinks = this.menuData ? this.createLinksArray(this.menuData) : [];
    const currPathIndex = this.findCurrentPathIndex();
    if (currPathIndex !== -1) {
      this.setButtonsDisableStatus(currPathIndex);
      !this.buttonsVisible && this.toggleButtonsVisibility();
    } else {
      this.buttonsVisible && this.toggleButtonsVisibility();
    }
  }

  private createLinksArray(menuData: ILeftNavItem[]): string[] {
    const breakIndex = menuData.findIndex((data) => data.type === 'Break');
    return menuData.slice(0, breakIndex !== -1 ? breakIndex : menuData.length).reduce((acc: string[], curr: ILeftNavItem) => {
      const currChildItems = (curr as ILeftNavElement).children?.[0]?.items ?? [];
      if (currChildItems.length) {
        currChildItems.filter((childItem) => !childItem.disabled).forEach((childItem) => acc.push(childItem.routerLink));
      } else {
        acc.push(curr.uri);
      }
      return acc;
    }, []);
  }

  private findCurrentPathIndex(): number {
    const sectionUrl = this.router.url.split('/').slice(6, 8).join('/');
    return this.currAvailableLinks.findIndex((link) => link.includes(sectionUrl));
  }

  private toggleButtonsVisibility(): void {
    this.buttonsVisible = !this.buttonsVisible;
    this.showButtons.emit(this.buttonsVisible);
  }

  private setButtonsDisableStatus(pathIndex: number): void {
    this.prevDisabled = pathIndex === -1 || pathIndex === 0;
    this.nextDisabled = pathIndex === -1 || pathIndex === this.currAvailableLinks.length - 1;
    this.cdRef.markForCheck();
  }

  public async navigate(direction: 'prev' | 'next'): Promise<void> {
    const pathIndex = this.findCurrentPathIndex();
    const newPathIndex = direction === 'prev' ? pathIndex - 1 : pathIndex + 1;
    if (newPathIndex >= 0 && newPathIndex < this.currAvailableLinks.length) {
      const newPath = this.currAvailableLinks[newPathIndex].split('/');
      await this.routerHelper.navigateToScenarioBasedPage(this.moduleType, newPath, null);
      this.activeMenuItemChanged.emit(newPath[0]);
    }
  }
}

@NgModule({
  imports: [CommonModule, ButtonModule],
  declarations: [PrevNextNavComponent],
  exports: [PrevNextNavComponent],
})
export class PrevNextNavModule {}
