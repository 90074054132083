import { InsertRowsWsAction, UpdateRowsWsAction } from '../../ws.action';
import { NoteDto } from '../../dto/note.dto';

export const NoteModuleName = 'NoteModule';

export enum NoteModuleActionTypes {
  Load = '[NoteModule] Load',
  NoteInsertRows = '[NoteModule] [Note] InsertRows',
  NoteUpdateRow = '[NoteModule] [Note] UpdateRow',
}

export interface LoadNotesActionResponse {
  notes?: NoteDto[];
}

// endregion

export class CreateNoteAction extends InsertRowsWsAction<NoteDto> {
  public override readonly type = NoteModuleActionTypes.NoteInsertRows;
}

export class UpdateNoteRowAction extends UpdateRowsWsAction<NoteDto> {
  public override readonly type = NoteModuleActionTypes.NoteUpdateRow;
}

export type NoteModuleActions = CreateNoteAction | UpdateNoteRowAction;
