import { DictionaryWithArray, IDictionaryWithArray } from '../../common/state.helpers';
import { Gravel } from './model/gravel';
import { IModuleState, IValidatedModuleState } from '../../common/common-state.interfaces';
import { ModuleType } from '../scenario/scenario.dto';

export interface GravelModuleState extends IModuleState {
  Gravels: IDictionaryWithArray<Gravel>;
  SelectedGravelId: number;
}

export const gravelModuleInitialState: GravelModuleState = {
  Gravels: DictionaryWithArray.clear(),
  SelectedGravelId: -1,
  isLoaded: false,
};

export interface ValidatedGravelModuleState extends GravelModuleState, IValidatedModuleState<GravelModuleState> {
  validGravelIds: number[];
}

export interface IGravelValidationDependencies {
  currentModuleType: ModuleType;
}
