import { WellModuleState } from './well-module.state';
import { ITableState } from '../../common/common-grid.interfaces';
import { Survey } from './model/survey/survey';
import { TemperatureProfile } from './model/temperature-profile/temperature-profile';
import { TemperatureProfileType } from './dto/well.dto';
import { Caliper } from './model/caliper/caliper';

export class WellModuleCalculations {
  public static applyCalculations(state: WellModuleState): WellModuleState {
    return {
      ...state,
      SurveyData: this.updateSurveyTempValues(state.SurveyData, state.TemperatureProfile),
      CaliperData: this.updateCaliperMultipliedDiameter(state.CaliperData, state.CaliperMultiplier),
    };
  }

  private static updateSurveyTempValues(survey: ITableState<Survey>, temperatureProfile: TemperatureProfile): ITableState<Survey> {
    if (survey.rows.length == 0 || temperatureProfile.TemperatureProfileType != TemperatureProfileType.Detailed_Calc) {
      return survey;
    }
    return {
      ...survey,
      rows: survey.rows.map((row, index) => {
        if (index > 0) {
          return row;
        }
        return {
          ...row,
          rowData: {
            ...row.rowData,
            UpPathTemp: temperatureProfile.SurfaceTemp,
            DownPathTemp: temperatureProfile.SurfaceTemp,
          },
        };
      }),
    };
  }

  private static updateCaliperMultipliedDiameter(caliperState: ITableState<Caliper>, caliperMultiplier: number): ITableState<Caliper> {
    const rows = caliperState.rows.map((row) => ({
      ...row,
      rowData: { ...row.rowData, Diameter: row.rowData.Diameter * caliperMultiplier },
    }));
    return { ...caliperState, rows };
  }
}
