import { BasePptSectionGenerator, PptContext } from '../base-ppt-section-generator';
import { firstValueFrom } from 'rxjs';
import { selectCurrentUnitSystem } from '../../../../../../+store/units/units.selectors';
import { CommonReportDataHelpers, IDataRowWithCode } from '../../common/common-report-data-helpers';
import { PptDocumentTableGeneratorHelper } from '../ppt-document-table-generator.helper';
import { getRowsForCalculations } from '@dunefront/common/common/common-grid.interfaces';
import { Pipe } from '@dunefront/common/modules/pipes/pipe';
import { getCompletionModuleState } from '../../../../../../+store/completion/completion.selectors';
import { PresetColumnsWidths } from '../../doc/document-table-generator.helper';

export class RunningStringDataPptGenerator extends BasePptSectionGenerator {
  constructor(context: PptContext) {
    super(context);
  }

  public async generate(): Promise<void> {
    const title = 'Completion - Running String';
    const currentUnitSystem = await firstValueFrom(this.store.select(selectCurrentUnitSystem));
    const completionState = await firstValueFrom(this.store.select(getCompletionModuleState));
    const runningStringData: IDataRowWithCode<Pipe>[] = CommonReportDataHelpers.generateCodeValues(
      getRowsForCalculations(completionState.RunningString.rows),
    );

    const { tableData, enumItemMap } = CommonReportDataHelpers.createRunningStringTableData(runningStringData);
    const tableRows = PptDocumentTableGeneratorHelper.createTableRows<Pipe>(tableData, enumItemMap, currentUnitSystem);

    const presetColumnsWidths: PresetColumnsWidths = { 1: 20 };

    this.addTableSlide({ title, tableRows, presetColumnsWidths });
  }
}
