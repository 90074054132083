import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { defaultModalCancelButton, ModalButtonConfig } from '../generic-modal/generic-modal.component';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { PanelHelpMode } from '../../../shared/components/help-button/help-buton.component';
import { AuthService } from '@auth0/auth0-angular';
import { AssetService } from '../../../shared/services/asset.service';

@Component({
  selector: 'app-help-modal',
  styleUrls: ['help-modal.component.scss'],
  template: ` <app-generic-modal [title]="modalTitle" [modalButtonsConfigs]="modalButtonsConfigs">
    <iframe *ngIf="iFrameSrc" [src]="iFrameSrc"></iframe>
  </app-generic-modal>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HelpModalComponent implements OnInit {
  public modalButtonsConfigs: ModalButtonConfig[] = [defaultModalCancelButton((): void => this.ref.close(), 'Close')];
  public modalTitle = 'Help';
  public helpFileName: string | null = null;

  public iFrameSrc: SafeResourceUrl | null = null;

  constructor(
    public ref: DynamicDialogRef,
    private cdRef: ChangeDetectorRef,
    private domSanitizer: DomSanitizer,
    private authService: AuthService,
    private helpService: AssetService,
    config: DynamicDialogConfig,
  ) {
    switch (config.data.mode) {
      case PanelHelpMode.GRID:
        this.modalTitle = 'Grid help';
        this.helpFileName = 'grid.html';
        break;
      case PanelHelpMode.CHART:
        this.modalTitle = 'Chart help';
        this.helpFileName = 'chart.html';
        break;
    }
  }

  public async ngOnInit(): Promise<void> {
    if (this.helpFileName != null) {
      this.iFrameSrc = this.domSanitizer.bypassSecurityTrustResourceUrl(
        this.helpService.getAssetUrl(`assets/help-files/components/${this.helpFileName}`),
      );

      this.cdRef.markForCheck();
    }
  }
}
