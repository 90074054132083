import { Component, ElementRef, Output, EventEmitter, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-menu-panel',
  templateUrl: './menu-panel.component.html',
  styleUrls: ['./menu-panel.component.scss'],
})
export class MenuPanelComponent implements AfterViewInit {
  @Output() public widthChanged = new EventEmitter<number>();
  constructor(protected eRef: ElementRef) {}

  public ngAfterViewInit(): void {
    setTimeout(() => {
      this.widthChanged.emit(this.eRef.nativeElement.offsetWidth);
    }, 0);
  }
}
