import { HeadingLevel, Paragraph, TextRun } from 'docx';
import { DocumentGeneratorHelper } from '../document-generator.helper';
import { ReportingTabDto } from '@dunefront/common/dto/reporting-tab.dto';
import { Base64Image, Base64Media } from '../../../../../../common-modules/chart/image-provider.helpers';
import { mainFont } from '../../../report-consts';
import { DocxImageGenerator } from '../docx-image-generator';
import { DocxHtmlParser } from '../docx-html-parser';

export const defaultChartImageWidth = 686;

export interface IReportingTabImage {
  reportingTab: ReportingTabDto;
  image?: Base64Image;
}

export interface ReportScenarioImages {
  surveyChart?: Base64Image;
  caliperChart?: Base64Image;
  schematic?: Base64Image;
  psdInputChart?: { [id: string]: Base64Image | undefined };
  psdAnalysisChart?: Base64Image;
  rangeImages?: { [key: number]: ReportRangeImages };
}

export interface ReportRangeImages {
  reportingTabs?: IReportingTabImage[];
  simulationAnimationWell?: Base64Media;
  simulationAnimationPressureChart?: Base64Image;
  simulationAnimationPerfPackingChart?: Base64Media;
  evaluationAnimationPressureChart?: Base64Image;
  evaluationAnimationFrictionChart?: Base64Image;
  wellVisualization?: Base64Media;
  psdInputChart?: { [id: string]: Base64Image | undefined };
  psdAnalysisChart?: Base64Image;
  trendAnalysisInputs?: Base64Image;
}

export class ChartReportGeneratorHelper {
  public static async createReportingChartSection(
    images: ReportScenarioImages,
    docxImageGenerator: DocxImageGenerator,
    rangeId: number,
    rangeName?: string,
  ): Promise<Array<Paragraph>> {
    const rangeImages = images.rangeImages ? images.rangeImages[rangeId] : null;
    const reportingTabsImages = rangeImages?.reportingTabs || [];

    if (reportingTabsImages.length === 0) {
      return [];
    }

    return [
      DocumentGeneratorHelper.createParagraphHeader(
        DocumentGeneratorHelper.addRangeNameToTitle('Charts', rangeName),
        HeadingLevel.HEADING_2,
        1,
      ),
      ...(await this.createCharts(reportingTabsImages, docxImageGenerator)),
    ];
  }

  private static async createCharts(
    reportingTabsImages: IReportingTabImage[],
    docxImageGenerator: DocxImageGenerator,
  ): Promise<Paragraph[]> {
    const paragraphs: Paragraph[] = [];
    for (const tabImage of reportingTabsImages) {
      const chartName = tabImage.reportingTab.TabName;

      paragraphs.push(DocumentGeneratorHelper.createParagraphHeader(chartName, HeadingLevel.HEADING_3, 2));

      const image = tabImage.image;
      if (image != null && image.imageDataBase64.length > 0) {
        const imageAspect = image.size.width / image.size.height;
        paragraphs.push(
          new Paragraph({
            children: [
              await docxImageGenerator.getImage(image.imageDataBase64, defaultChartImageWidth, defaultChartImageWidth / imageAspect),
            ],
          }),
        );
        if (tabImage.reportingTab.ChartDescription) {
          paragraphs.push(...new DocxHtmlParser(tabImage.reportingTab.ChartDescription).render());
        }
      } else {
        paragraphs.push(
          new Paragraph({
            children: [new TextRun({ text: `Chart is not available - results are needed.`, font: mainFont })],
          }),
        );
      }
    }
    return paragraphs;
  }
}
