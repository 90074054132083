<app-page-panel header="System Configuration">
  <div class="grid-form-container" *ngIf="shuntTube">
    <div>Tube Shape</div>
    <app-radio-group
      [source]="shuntTube"
      [key]="'Shape'"
      [items]="tubeShapeRadio"
      (valueChanged)="onValueChange($event)"
      dataCy="shunt-tubes-tube-shape"
      id="tubeShapeRadio"
    >
    </app-radio-group>
    <div>Tube Location</div>
    <app-radio-group
      [source]="shuntTube"
      [key]="'IsExternal'"
      [items]="tubeLocationRadio"
      (valueChanged)="onValueChange($event)"
      id="tubeLocationRadio"
    >
    </app-radio-group>
    <div>Internal Reservoir</div>
    <app-radio-group
      [source]="shuntTube"
      [key]="'IsInternalReservoir'"
      [items]="internalReservoirRadio"
      (valueChanged)="onValueChange($event)"
      id="internalReservoirRadio"
    >
    </app-radio-group>
    <div>Transport Tube</div>
    <app-radio-group
      [source]="shuntTube"
      [key]="'IsTransportTubePresent'"
      [items]="transportTubeRadio"
      (valueChanged)="onValueChange($event)"
      id="transportTubeRadio"
    >
    </app-radio-group>
  </div>
</app-page-panel>
