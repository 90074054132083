import { FrictionCalculatorDto, FrictionCalculatorResultDto } from './friction-calculator.dto';
import { UpdateRowsWsAction } from '../../../ws.action';
import { WsActionPropsFactory } from '../../../common/ws-action/ws-action-props.factory';
import { IUpdateRowsProps } from '../../../common/common-store-crud.interfaces';
import { FrictionCalculatorFactory } from './model/friction-calculator.factory';
import { ValidatedFrictionCalculator } from './friction-calculator-module.state';
import { DeveloperSettingsDto } from '../../settings/dto/settingsDto';

export const FrictionCalculatorModuleName = 'FrictionCalculatorModule';

export enum FrictionCalculatorModuleActionTypes {
  UpdateFrictionCalculator = '[FrictionCalculatorModule] Update',
  CalculateFrictionAction = '[FrictionCalculatorModule] Calculate',
  UpdateResult = '[FrictionCalculatorModule] UpdateResult',
}

export interface LoadFrictionCalculatorActionResponse {
  frictionCalculatorDto: FrictionCalculatorDto;
  frictionCalculatorResultDto: FrictionCalculatorResultDto[];
}

export class UpdateFrictionCalculatorAction extends UpdateRowsWsAction<FrictionCalculatorDto> {
  public override readonly type = FrictionCalculatorModuleActionTypes.UpdateFrictionCalculator;

  constructor(action: IUpdateRowsProps<ValidatedFrictionCalculator>) {
    super(WsActionPropsFactory.updateAction(action, FrictionCalculatorFactory.toDto));
  }
}

export class CalculateFrictionAction extends UpdateRowsWsAction<{ ScenarioId: number }> {
  public override readonly type = FrictionCalculatorModuleActionTypes.CalculateFrictionAction;

  constructor(
    scenarioId: number,
    public developerSettings: DeveloperSettingsDto,
  ) {
    super(WsActionPropsFactory.update([{ ScenarioId: scenarioId }], true));
  }
}

export type FrictionCalculatorModuleActions = UpdateFrictionCalculatorAction | CalculateFrictionAction;
