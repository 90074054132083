import { createAction, props } from '@ngrx/store';
import {
  ICommonDbInitialData,
  PackProCommonFluidError,
  PackProCommonGravelError,
} from '@dunefront/common/modules/common-db/common-db.actions';
import { GlobalOptionsDto } from '@dunefront/common/dto/global-options.dto';
import { CommonDbType, WritableCommonDbType } from '@dunefront/common/dto/common-dto.interfaces';
import { ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';
import { CrudResponse } from '@dunefront/common/modules/common.actions';

export const loadCommonFluids = createAction('[common-db] loadCommonFluids');
export const loadCommonFluidsSuccess = createAction('[common-db] loadCommonFluidsSuccess', props<CrudResponse>());
export const copyCommonFluidToProject = createAction(
  '[common-db] copyCommonFluidToProject',
  props<{ commonFluidId: number; commonDbType: CommonDbType; targetScenarioId: number }>(),
);
export const copyProjectFluidToCommon = createAction(
  '[common-db] copyProjectFluidToCommon',
  props<{ projectFluidId: number; commonDbType: CommonDbType; overwrite: boolean }>(),
);
export const deleteCommonFluid = createAction(
  '[common-db] deleteCommonFluid',
  props<{ commonFluidId: number; commonDbType: CommonDbType }>(),
);

export const commonFluidOperationFailure = createAction(
  '[common-db] commonFluidOperationFailureAction',
  props<{ error?: PackProCommonFluidError }>(),
);

export const loadCommonGravels = createAction('[common-db] loadCommonGravels');
export const loadCommonGravelsSuccess = createAction('[common-db] loadCommonGravelsSuccess', props<CrudResponse>());
export const copyCommonGravelToProject = createAction(
  '[common-db] copyCommonGravelToProject',
  props<{ commonGravelId: number; commonDbType: CommonDbType; targetScenarioId: number }>(),
);
export const copyProjectGravelToCommon = createAction(
  '[common-db] copyProjectGravelToCommon',
  props<{ projectGravelId: number; commonDbType: CommonDbType; overwrite: boolean }>(),
);
export const deleteCommonGravel = createAction(
  '[common-db] deleteCommonGravel',
  props<{ commonGravelId: number; commonDbType: CommonDbType }>(),
);

export const commonGravelOperationFailure = createAction(
  '[common-db] commonGravelOperationFailureAction',
  props<{ error?: PackProCommonGravelError }>(),
);

export const changeGlobalOptionsPersonalProperty = createAction(
  '[common-db] changeGlobalOptionsPersonalProperty',
  props<ObjectChangeProp<GlobalOptionsDto>>(),
);
export const changeGlobalOptionsOrgProperty = createAction(
  '[common-db] changeGlobalOptionsOrgProperty',
  props<ObjectChangeProp<GlobalOptionsDto>>(),
);

export const changeAndSaveGlobalOptionsProperty = createAction(
  '[common-db] changeAndSaveGlobalOptionsProperty',
  props<{ props: ObjectChangeProp<GlobalOptionsDto>; commonDbType: WritableCommonDbType }>(),
);

export const loadCommonDbInitialData = createAction('[common-db] loadCommonDbInitialData');
export const loadCommonDbInitialSuccess = createAction('[common-db] loadGlobalOptionsSuccess', props<{ payload: ICommonDbInitialData }>());
export const saveGlobalOptions = createAction(
  '[common-db] saveGlobalOptions',
  props<{ commonDbType: WritableCommonDbType; globalOptions: GlobalOptionsDto }>(),
);
export const updateGlobalOptions = createAction(
  '[common-db] updateGlobalOptions',
  props<{ commonDbType: CommonDbType; globalOptions: GlobalOptionsDto }>(),
);
