import { createSelector } from '@ngrx/store';
import { getCurrentFileResultInfos } from '../../calculation-engine/calculation-engine-results.selectors';
import { getCurrentAppModuleType } from '../../ui/ui.selectors';
import { isDataAnalysis } from '../menu-selectors.helpers';

export const getIsDeleteResultEnabled = createSelector(getCurrentFileResultInfos, (scenarioIdsWithResults) =>
  scenarioIdsWithResults.some((res) => res.isCompleted),
);
export const getIsDataAnalysis = createSelector(getCurrentAppModuleType, (moduleType) => isDataAnalysis(moduleType));

export const getHelpMenuProps = createSelector(
  getIsDeleteResultEnabled,
  getIsDataAnalysis,
  (deleteResultEnabled, evaluationGuideAvailable) =>
    ({
      deleteResultEnabled,
      evaluationGuideAvailable,
    }) as GeneralMenuProps,
);

export interface GeneralMenuProps {
  deleteResultEnabled: boolean;
  evaluationGuideAvailable: boolean;
}
