import { IGaugeDataRow } from '../gauge-data-module.state';
import { ColumnType, IGridColumnConfig } from '../../../shared/components/grid/grid.interfaces';
import { ImportDataDto } from '@dunefront/common/modules/data-storage/dto/import-data.dto';
import { ArgumentType, ImportColumnDto } from '@dunefront/common/modules/data-storage/dto/import-column.dto';
import { createTableRow, createTableState, ITableState } from '@dunefront/common/common/common-grid.interfaces';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';

interface ToTableStateParams {
  data: ImportDataDto[];
  columns: ImportColumnDto[];
}

export class GaugeDataFactory {
  public static toTableState({ data, columns }: ToTableStateParams): ITableState<IGaugeDataRow> {
    const rows = data.map((d, index) => {
      const values = d.CombinedValues.split(',');
      const row = createTableRow<IGaugeDataRow>(
        {
          Id: d.Id,
          Argument: d.Argument,
          RowIndex: index,
        },
        'data',
        index,
      );

      columns
        .filter((col) => col.IsXAxis === ArgumentType.Value)
        .forEach(
          (col, colId) =>
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            (row.rowData['col_' + colId] = +values[colId]),
        );
      return row;
    });
    return createTableState(rows);
  }

  public static getGridColumns(columns: ImportColumnDto[]): IGridColumnConfig<IGaugeDataRow>[] {
    const selectionCol: IGridColumnConfig<IGaugeDataRow> = {
      disabled: false,
      visible: true,
      colId: ' ',
      type: ColumnType.selection,
    };

    const argCol = columns.find((c) => c.IsXAxis);

    const argColConfig: IGridColumnConfig<IGaugeDataRow> = {
      disabled: false,
      visible: true,
      colId: 'Argument',
      type: ColumnType.number,
      unitSystem: UnitSystem.Time,
      decimalPlaces: 3,
      headerText: argCol?.ColumnName ?? 'Time',
      align: 'right',
      width: 100,
    };

    return [
      selectionCol,
      argColConfig,
      ...columns
        .filter((col) => col.IsXAxis === ArgumentType.Value)
        .map(
          (col, index) =>
            ({
              align: 'right',
              colId: 'col_' + index,
              decimalPlaces: 3,
              disabled: false,
              type: ColumnType.number,
              visible: true,
              unitSystem: col.UnitSystem,
              headerText: col.ColumnName,
              width: 100,
            }) as IGridColumnConfig<IGaugeDataRow>,
        ),
    ];
  }
}
