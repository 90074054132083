import { createFeatureSelector, createSelector } from '@ngrx/store';
import { createEmptyPumpingState, PumpingModuleState, PumpingStateByRange } from '@dunefront/common/modules/pumping/pumping-module.state';
import { getCurrentRangeId } from '../../range/range.selectors';
import { getCurrentAppModuleType } from '../../ui/ui.selectors';
import { RangeConstants } from '@dunefront/common/dto/range.dto';
import { isSimulateBased, ScenarioConstants } from '@dunefront/common/modules/scenario/scenario.dto';
import { getLowerCompletion } from '../../completion/completion.selectors';
import { PumpingModuleCalculations } from '@dunefront/common/modules/pumping/pumping-module-calculations';
import { getMaxLowerCompletionBottomMD } from '../../completion/max-lower-completion-bottom-md.selector';

export const getPumpingRawModuleState = createFeatureSelector<PumpingModuleState>('pumping');

export const getCurrentPumpingModuleState = createSelector(
  getPumpingRawModuleState,
  getCurrentRangeId,
  getLowerCompletion,
  getMaxLowerCompletionBottomMD,
  (state, currentRangeId, lowerCompletion): PumpingStateByRange => {
    const pumping = state[currentRangeId] ?? createEmptyPumpingState(ScenarioConstants.EmptyScenarioId, currentRangeId);
    return PumpingModuleCalculations.applyPumpingCalculations(pumping, lowerCompletion);
  },
);

export const getSimulatePumpingModuleState = createSelector(
  getPumpingRawModuleState,
  getLowerCompletion,
  getMaxLowerCompletionBottomMD,
  (state, lowerCompletion): PumpingStateByRange => {
    const rangeId = RangeConstants.EmptyRangeId;
    const pumping = state[rangeId] ?? createEmptyPumpingState(ScenarioConstants.EmptyScenarioId, rangeId);
    return PumpingModuleCalculations.applyPumpingCalculations(pumping, lowerCompletion);
  },
);

export const getPumpingToolPosition = createSelector(getCurrentPumpingModuleState, (state) => state.pumping.ToolPosition);
export const getPumpingDirection = createSelector(getCurrentPumpingModuleState, (state) => state.pumping.PumpingDirection);
export const getPumpingSchedule = createSelector(getPumpingRawModuleState, (state) => state.pumpingSchedule);
export const getEvaluationPumpingSchedule = createSelector(getPumpingRawModuleState, (state) => state.evaluationSchedule);
export const getIsPumpingStateLoaded = createSelector(getCurrentPumpingModuleState, (state) => state?.isLoaded);

export const isPumpingEnabled = createSelector(
  getCurrentAppModuleType,
  getCurrentRangeId,
  (...[appModuleType, currentRangeId]) =>
    isSimulateBased(appModuleType) || (currentRangeId ?? RangeConstants.EntireRangeId) > RangeConstants.EntireRangeId,
);
