import { Pumping } from './model/pumping/pumping';
import { createTableState, ITableState } from '../../common/common-grid.interfaces';
import { PumpingSchedule } from './model/pumping-schedule/pumping-schedule';
import { WellFluid } from './model/well-fluid/well-fluid';
import { WellModuleState } from '../well/well-module.state';
import { CompletionModuleState } from '../completion/completion-module.state';
import { IDictionaryWithArray } from '../../common/state.helpers';
import { Gravel } from '../gravel/model/gravel';
import { IModuleState, IValidatedModuleState } from '../../common/common-state.interfaces';
import { PumpingFactory } from './model/pumping/pumping.factory';
import { Fluid } from '../fluid/model/fluid';
import { RangeConstants } from '../../dto/range.dto';
import { ModuleType, ScenarioConstants } from '../scenario/scenario.dto';
import { PumpedFluidAndGravelDto } from './dto/pumped-fluid-and-gravel.dto';
import { DeveloperSettingsDto, SettingsDto } from '../settings/dto/settingsDto';
import { ISelectItem } from '../../common/select.helpers';
import { ReferenceVariableCalculatorResultsDto } from '../reference-variables/dto/reference-variable-calculator-results.dto';
import { ScenarioRangePropertiesDto } from '../../dto/scenario-range-properties.dto';
import { IGetCurrentFeaturesResult } from '../licensing/licensing.interfaces';

export interface PumpingModuleState {
  [rangeId: number]: PumpingStateByRange;

  pumpingSchedule: ITableState<PumpingSchedule>;
  evaluationSchedule: ITableState<PumpingSchedule>;
}

export interface PumpingStateByRange extends IModuleState {
  pumping: Pumping;
  wellFluids: ITableState<WellFluid>;
  pumpedFluidAndGravel: ITableState<PumpedFluidAndGravelDto>;
  rangeId: number;
}

export interface ValidatedPumpingModuleState extends PumpingStateByRange, IValidatedModuleState<PumpingStateByRange> {
  isInitialScreenValid: boolean;
  isWellFluidsScreenValid: boolean;
  isScheduleScreenValid: boolean;
  isAutomaticSchedulingPanelValid: boolean;
  isScheduleGeneratorPanelValid: boolean;
  pumpingSchedule: ITableState<PumpingSchedule>;
  evaluationSchedule: ITableState<PumpingSchedule>;
}

export const createEmptyPumpingState = (scenarioId: number, rangeId: number, isLoaded = false): PumpingStateByRange => ({
  pumping: PumpingFactory.createEmptyPumping(scenarioId, rangeId),
  wellFluids: createTableState([]),
  pumpedFluidAndGravel: createTableState([]),
  rangeId,
  isLoaded,
});

export const pumpingModuleInitialState: PumpingModuleState = {
  [RangeConstants.EmptyRangeId]: createEmptyPumpingState(ScenarioConstants.EmptyScenarioId, RangeConstants.EmptyRangeId),
  pumpingSchedule: createTableState([]),
  evaluationSchedule: createTableState([]),
};

export interface IPumpingValidationDependencies {
  well: WellModuleState;
  completion: CompletionModuleState;
  settings: SettingsDto;
  referenceVariables: ReferenceVariableCalculatorResultsDto | undefined;
  gravels: IDictionaryWithArray<Gravel>;
  fluids: IDictionaryWithArray<Fluid>;
  developerSettings: DeveloperSettingsDto;
  rangeId: number;
  appModuleType: ModuleType;
  currentLicenseFeatures: IGetCurrentFeaturesResult;
}

export interface AnalysisDataComponentSelectItems {
  pumpRateItems: ISelectItem<number>[];
  pumpPressureItems: ISelectItem<number>[];
  returnRateItems: ISelectItem<number>[];
  gravelConcItems: ISelectItem<number>[];
}

export interface IAnalysisDataComponentSelectedValues {
  selectedPumpRateId?: number;
  selectedPumpPressureId?: number;
  selectedReturnRateId?: number;
  selectedGravelConcId?: number;
}

export interface IAnalysisDataComponentState extends AnalysisDataComponentSelectItems {
  isLoaded: boolean;
  currentScenarioRangeProperties: ScenarioRangePropertiesDto;
}

export type ValidatedAnalysisDataComponentState = IAnalysisDataComponentState & IValidatedModuleState<IAnalysisDataComponentState>;
