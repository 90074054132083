import { CasingPipeDto } from '../../well/dto/casing/casing-pipe.dto';
import { PipeType } from '../../../dto/pipe.dto';
import { createTableRow, GridRowType, ITableRow } from '../../../common/common-grid.interfaces';
import { BasePipeFactory } from '../base.pipe.factory';
import { OpenHolePipe, OpenHolePipeFactory } from './pipes/open-hole-pipe';
import { isPerforatedCasingPipe, PerforatedCasingPipe, PerforatedCasingPipeFactory } from './pipes/perforated-casing-pipe';
import { Pipe } from '../pipe';
import { PipeFactory } from '../pipe.factory';

export class CasingPipesFactory {
  public static createFromCasing(casingDto: CasingPipeDto): Pipe {
    switch (casingDto.PipeType) {
      case PipeType.Perforated_Casing:
        return PerforatedCasingPipeFactory.createFromCasing(casingDto);
      case PipeType.Open_Hole:
        return OpenHolePipeFactory.createFromCasing(casingDto);
      default:
        return BasePipeFactory.createFromDto(casingDto);
    }
  }

  public static createRowListFromDtos(pipeDtoArray: CasingPipeDto[], scenarioId: number): ITableRow<Pipe>[] {
    const rows = pipeDtoArray.map((dto, index) => this.createElement(dto, index));
    const insertRow = PipeFactory.createEmptyRow(scenarioId, rows.length, false, 'insert-row');
    return [...rows, insertRow];
  }

  public static createElement(
    pipeDto: CasingPipeDto,
    rowIndex: number,
    isEditingDisabled = false,
    rowType: GridRowType = 'data',
  ): ITableRow<Pipe> {
    const pipe = this.createFromCasing(pipeDto);
    return createTableRow<Pipe>(pipe, rowType, rowIndex, isEditingDisabled);
  }

  public static toDto(pipe: Pipe): CasingPipeDto {
    const casingPipeDto: CasingPipeDto = {
      ScenarioId: pipe.ScenarioId,
      Id: pipe.Id,
      PipeType: pipe.PipeType,
      TopMD: pipe.TopMD,
      BottomMD: pipe.BottomMD,
      OuterDiameter: pipe.OuterDiameter,
      InnerDiameter: pipe.InnerDiameter,
      Weight: pipe.Weight,
      SortOrder: pipe.SortOrder,
      PerforationShotDensity: 0,
      PerforationTunnelLength: 0,
      PerforationDiameter: 0,
      PerforationPhasingType: 0,
      PerforationVolume: 0,
      OpenPerforationPercentage: 0,
      Description: pipe.Description,
    };
    if (isPerforatedCasingPipe(pipe)) {
      casingPipeDto.PerforationShotDensity = pipe.PerforationShotDensity ?? 0;
      casingPipeDto.PerforationTunnelLength = pipe.PerforationTunnelLength ?? 0;
      casingPipeDto.PerforationDiameter = pipe.PerforationDiameter ?? 0;
      casingPipeDto.PerforationPhasingType = pipe.PerforationPhasingType ?? 0;
      casingPipeDto.PerforationVolume = pipe.PerforationVolume ?? 0;
      casingPipeDto.OpenPerforationPercentage = pipe.OpenPerforationPercentage ?? 0;
    }
    return casingPipeDto;
  }

  public static getEmptyDto(scenarioId: number): CasingPipeDto {
    return {
      ScenarioId: scenarioId,
      Id: -1,
      PipeType: PipeType.None,
      TopMD: 0,
      BottomMD: 0,
      OuterDiameter: 0,
      InnerDiameter: 0,
      Weight: 0,
      SortOrder: 0,
      PerforationShotDensity: 0,
      PerforationTunnelLength: 0,
      PerforationDiameter: 0,
      PerforationPhasingType: 0,
      PerforationVolume: 0,
      OpenPerforationPercentage: 0,
      Description: '',
    };
  }

  public static createEmpty(scenarioId: number): Pipe {
    return this.createFromCasing(this.getEmptyDto(scenarioId));
  }
}

export type CasingPipe = Pipe | PerforatedCasingPipe | OpenHolePipe;
