import { getValidatedState } from '../app.selector';

import {
  AdvancedValidationJobInputData,
  AdvancedValidationJobInputDataFactory,
  SimulateAdvancedValidationJobInputData,
  TrendAnalysisAdvancedValidationJobInputData,
} from '@dunefront/common/modules/validation/dto/validation.dto';
import { getImportFilesForAdvancedValidation } from '../data-storage/data-storage.selectors';
import { getCurrentScenarioRangeProperties } from '../range/range.selectors';
import { getCurrentAppModuleType } from '../ui/ui.selectors';
import { ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import { createSelector } from '@ngrx/store';

const getSimulateAdvancedValidationJobInputData = createSelector(
  getCurrentAppModuleType,
  getValidatedState,
  (...[moduleType, simulationState]): SimulateAdvancedValidationJobInputData | null => {
    if (moduleType === ModuleType.Trend_Analysis) {
      return null;
    }

    return AdvancedValidationJobInputDataFactory.createSimulateAdvancedValidationJobInputData(simulationState);
  },
);

const getTrendAnalysisAdvancedValidationJobInputData = createSelector(
  getCurrentScenarioRangeProperties,
  getImportFilesForAdvancedValidation,
  (scenarioRangeProperties, files): TrendAnalysisAdvancedValidationJobInputData | null => {
    if (files == null || scenarioRangeProperties == null) {
      return null;
    }

    return {
      ImportFiles: files,
      AnalysisDataSelectedColumnNames: {
        PumpRateColumnName: scenarioRangeProperties.PumpRateColumnName,
        PumpPressureColumnName: scenarioRangeProperties.PumpPressureColumnName,
        GravelConcColumnName: scenarioRangeProperties.GravelConcColumnName,
        ReturnRateColumnName: scenarioRangeProperties.ReturnRateColumnName,
      },
    };
  },
);

export const getAdvancedValidationInput = createSelector(
  getSimulateAdvancedValidationJobInputData,
  getTrendAnalysisAdvancedValidationJobInputData,
  (...[SimulateInputData, TrendAnalysisInputData]): AdvancedValidationJobInputData => ({
    SimulateInputData,
    TrendAnalysisInputData,
  }),
);
