import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { PumpingDirection } from '@dunefront/common/modules/pumping/dto/pumping.dto';
import { EnumHelpers } from '@dunefront/common/utils/enum.helpers';
import { Pumping } from '@dunefront/common/modules/pumping/model/pumping/pumping';
import { ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';

@Component({
  selector: 'app-pumping-direction',
  templateUrl: './pumping-direction.component.html',
  styleUrls: ['./pumping-direction.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PumpingDirectionComponent {
  @Input() public pumping: Pumping | undefined;
  @Output() public dataChange = new EventEmitter<ObjectChangeProp<Pumping>>();

  public items = EnumHelpers.EnumToRadioItems(PumpingDirection);
}
