import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef } from '@angular/core';
import { GridContainerComponent } from '../../../../../shared/components/grid/grid-container.component';
import { ColumnType, IGridColumnConfig } from '../../../../../shared/components/grid/grid.interfaces';
import { GridConfig } from '../../../../../shared/components/grid/grid-config';
import { ISelectItem, toSelectItem } from '@dunefront/common/common/select.helpers';
import { Store } from '@ngrx/store';
import { ModalService } from '../../../../../common-modules/modals/modal.service';
import {
  getEquationFilesSelectItems,
  selectEquationVariables,
  selectValidatedDataSourceVariables,
} from '../../../../../+store/equation/equation.selectors';
import { getStorageColumns } from '../../../../../+store/data-storage/data-storage.selectors';
import { updateEquationVariable } from '../../../../../+store/equation/equation.actions';
import { ArgumentType, ImportColumnDto } from '@dunefront/common/modules/data-storage/dto/import-column.dto';
import { UnitHelpers } from '../../../../../common-modules/units/unit-helpers';
import { take } from 'rxjs/operators';
import { IEquationVariable } from '@dunefront/common/modules/equation/equation-variable.dto';
import { GridResizeService } from '../../../../../shared/services/grid-resize.service';
import { IUpdateTableRowsProps } from '@dunefront/common/common/common-store-crud.interfaces';

const FILE_LOOKUP_SRC_ID = 'files';
const COLUMN_LOOKUP_SRC_ID = 'columns';
const UNIT_LOOKUP_SRC_ID = 'units';

@Component({
  selector: 'app-variables-grid',
  templateUrl: './variables-grid.component.html',
  styleUrls: ['./variables-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VariablesGridComponent extends GridContainerComponent<IEquationVariable> implements AfterViewInit {
  public dataSource$ = this.store.select(selectValidatedDataSourceVariables);
  public override height = 150;
  public columns = this.getColumns();

  constructor(
    store: Store,
    cdRef: ChangeDetectorRef,
    protected modalService: ModalService,
    el: ElementRef,
    gridResizeService: GridResizeService,
  ) {
    super(store, cdRef, el, new EquationVariablesGridConfig(modalService, store), gridResizeService);
  }

  protected getColumns(): IGridColumnConfig<IEquationVariable>[] {
    return [
      { disabled: false, visible: true, colId: ' ', type: ColumnType.selection },
      {
        disabled: true,
        visible: true,
        colId: 'Name',
        headerText: 'Variable name',
        type: ColumnType.text,
        align: 'center',
        width: 150,
        isFillDownDisabled: true,
      },
      {
        disabled: false,
        visible: true,
        colId: 'FileId',
        headerText: 'File',
        lookupDataSourceType: FILE_LOOKUP_SRC_ID,
        type: ColumnType.select,
        align: 'center',
        width: 150,
        isFillDownDisabled: true,
      },
      {
        disabled: false,
        visible: true,
        colId: 'ColId',
        headerText: 'Column',
        lookupDataSourceType: COLUMN_LOOKUP_SRC_ID,
        type: ColumnType.select,
        align: 'center',
        width: 150,
        isFillDownDisabled: true,
      },
      {
        disabled: false,
        visible: true,
        colId: 'Unit',
        headerText: 'Unit',
        align: 'center',
        lookupDataSourceType: UNIT_LOOKUP_SRC_ID,
        type: ColumnType.select,
        width: 150,
        isFillDownDisabled: true,
      },
    ];
  }
}

export class EquationVariablesGridConfig extends GridConfig<IEquationVariable> {
  public filesItems: ISelectItem<number>[] = [];
  public columnItems: ImportColumnDto[] = [];
  public equationVariables!: IEquationVariable[];

  constructor(
    modalService: ModalService,
    private store: Store,
  ) {
    super(modalService);

    this.getFilesItems();
    this.getVariables();
    this.getColumns();
  }

  private getFilesItems(): void {
    this.subscription.add(
      this.store
        .select(getEquationFilesSelectItems)
        .pipe(take(1))
        .subscribe((filesItems) => (this.filesItems = filesItems)),
    );
  }

  private getVariables(): void {
    this.subscription.add(
      this.store.select(selectEquationVariables).subscribe((res) => {
        this.equationVariables = res;
      }),
    );
  }

  public getColumns(): void {
    this.subscription.add(
      this.store
        .select(getStorageColumns)
        .pipe(take(1))
        .subscribe((res) => {
          // unpack all columns from dict
          const cols: ImportColumnDto[] = [];
          Object.keys(res.dict).forEach((key) => {
            cols.push(...(res.dict[key] as ImportColumnDto[]));
          });
          this.columnItems = cols;
        }),
    );
  }

  public override updateRowsAction(props: IUpdateTableRowsProps<IEquationVariable>, cellIndex?: number): void {
    const variable: IEquationVariable = { ...props.rows[0].rowData };
    const colId = props.colIds?.[0];
    // reset column and unit when file changes
    if (colId === 'FileId') {
      variable.ColId = -1;
      variable.Unit = -1;
    }
    // reset unit when column changes
    if (colId === 'ColId') {
      variable.Unit = -1;
    }
    this.store.dispatch(updateEquationVariable({ variable: variable }));
  }

  public override getLookupDataSource = (type: string, rowId: number): ISelectItem<number>[] => {
    const variable = this.equationVariables.find((eqVar) => eqVar.Id === rowId);

    if (type === FILE_LOOKUP_SRC_ID) {
      return this.filesItems;
    } else if (type === COLUMN_LOOKUP_SRC_ID && variable) {
      return this.getColumnsForFile(variable.FileId);
    } else if (type === UNIT_LOOKUP_SRC_ID && variable) {
      return this.getUnitsForColumn(variable.ColId);
    }
    return [];
  };

  public override getLookupDataPlaceholder(type: string, rowId: number): string {
    if (type === FILE_LOOKUP_SRC_ID) {
      return 'Select File';
    } else if (type === COLUMN_LOOKUP_SRC_ID) {
      return 'Select Column';
    } else if (type === UNIT_LOOKUP_SRC_ID) {
      return 'Select unit';
    }
    return '';
  }

  private getColumnsForFile(FileId: number): ISelectItem<number>[] {
    return this.columnItems
      .filter((col) => col?.FileId === FileId && col.IsXAxis === ArgumentType.Value)
      .map((col) => toSelectItem(col.Id, col.ColumnName));
  }

  private getUnitsForColumn(ColId: number): ISelectItem<number>[] {
    const unitSystem = this.columnItems.find((col) => col?.Id === ColId)?.UnitSystem;
    if (unitSystem == null) {
      return [];
    }
    return UnitHelpers.getUnitSymbolSelectItems(unitSystem);
  }
}
