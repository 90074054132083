import { UnitConverter } from '../../converter.interfaces';
import { PressureUnit } from '../../../dto/unit-system.dto';

export class PressureConverter extends UnitConverter {
  public static symbols: Array<string> = ['', 'Pa', 'kPa', 'psi', 'kg/cm²', 'bar', 'MPa', 'lbf/100ft²', 'dyn/cm²'];

  public static unit = PressureUnit;

  public static fromSi(value: number, unit: PressureUnit): number {
    switch (unit) {
      case PressureUnit.Pascal: {
        return value;
      }
      case PressureUnit.Kilo_Pascal: {
        return value * 1e-3;
      }
      case PressureUnit.Pound_per_square_inch: {
        return value * 1.45037738e-4;
      }
      case PressureUnit.Kilogram_per_square_centimeter: {
        return value * 1.019716213e-5;
      }
      case PressureUnit.Bar: {
        return value * 1e-5;
      }
      case PressureUnit.Mega_Pascal: {
        return value * 1e-6;
      }
      case PressureUnit.Pound_per_hundred_square_feet: {
        return value * 2.08854342331501;
      }
      case PressureUnit.Dyne_per_square_centimeter: {
        return value * 10;
      }
      default: {
        throw new TypeError('Unknown Pressure Unit:' + unit);
      }
    }
  }

  public static toSi(value: number, unit: PressureUnit): number {
    switch (unit) {
      case PressureUnit.Pascal: {
        return value;
      }
      case PressureUnit.Kilo_Pascal: {
        return value / 1e-3;
      }
      case PressureUnit.Pound_per_square_inch: {
        return value / 1.45037738e-4;
      }
      case PressureUnit.Kilogram_per_square_centimeter: {
        return value / 1.019716213e-5;
      }
      case PressureUnit.Bar: {
        return value / 1e-5;
      }
      case PressureUnit.Mega_Pascal: {
        return value / 1e-6;
      }
      case PressureUnit.Pound_per_hundred_square_feet: {
        return value / 2.08854342331501;
      }
      case PressureUnit.Dyne_per_square_centimeter: {
        return value / 10;
      }
      default: {
        throw new TypeError('Unknown Pressure Unit:' + unit);
      }
    }
  }

  public static getSymbol(unit: PressureUnit): string {
    return this.symbols[unit];
  }
}
