<ng-container *ngIf="completionState.isLoaded">
  <app-page-panel header="Lower Completion" (click)="onPipeTypeHelpChange('gravel-pack-packer')"
                  [panelHelpMode]="PanelHelpMode.GRID">
    <app-lower-completion-grid
      #gridComponent
      [lowerCompletionData]="completionState.LowerCompletion"
      (selectedRowsChanged)="onSelectedRowsChanged($event)"
    >
    </app-lower-completion-grid>
  </app-page-panel>

  <app-page-panel class="options-panel" [header]="pipeTypeName" *ngIf="selectedScreen"
                  (click)="setSelectedRow(); onPipeTypeHelpChange()">
    <app-screen-component
      [screenPipeRow]="selectedScreen"
      [icdPortData]="selectedIcdPortData"
      (rowUpdated)="onLowerCompletionScreenPipePropertyChanged($any($event))"
      (icdPortDataRowUpdated)="onIcdPortDataPropertyChanged($event)"
      [diameterUnit]="currentUnitSystem.Diameter"
    ></app-screen-component>
  </app-page-panel>

  <div class="bottom-panel-button-lg">
    <app-page-panel
      header="Screen Centralizers"
      class="location-type-panel"
      *ngIf="completionState"
      (click)="onHelpChange('completion', 'screen-centralizer')"
    >
      <app-screen-centralizers [completionData]="completionState"
                               (completionPropertyChanged)="onCompletionPropertyChanged($event)">
      </app-screen-centralizers>
    </app-page-panel>

    <div class="buttons-panel">
      <div class="align-buttons">
        <button
          appButton
          type="button"
          class="btn btn-primary btn-sm"
          data-cy="pipe-data"
          [isUiLockable]="false"
          (buttonClick)="onShowPipeDataClick()"
          [disabled]="isPipeDataDisabled"
        >
          Pipe Data
        </button>
        <button appButton type="button" class="btn btn-primary btn-sm" data-cy="lower-completion-import"
                (buttonClick)="onImport()" [isDeleteResultsEnabled]="false">
          Import
        </button>
        <button appButton type="button" class="btn btn-primary btn-sm" (buttonClick)="onInsert()"
                [isDeleteResultsEnabled]="false">
          Insert
        </button>
        <button appButton type="button" class="btn btn-primary btn-sm" (buttonClick)="onDelete()"
                [isDeleteResultsEnabled]="false">
          Delete
        </button>
      </div>
    </div>
  </div>
</ng-container>
