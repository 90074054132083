import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { isSimulateBased } from '@dunefront/common/modules/scenario/scenario.dto';
import { firstValueFrom } from 'rxjs';
import { MenuItem } from 'primeng/api';
import { compareScenariosOpenConfigPanelAction } from '../../../../../../+store/scenario/scenario.actions';
import { ChartMenuProps } from '../../../../../../+store/menu-selectors/chart/menu-chart.selector';
import { isCompareScenarioActiveAction } from '../../../../../../+store/reporting/reporting.actions';
import { getCurrentScenario, getScenariosToCompare } from '../../../../../../+store/scenario/scenario.selectors';
import { getCompletedResultsForCurrentModule } from '../../../../../../+store/calculation-engine/calculation-engine-results.selectors';

@Component({
  selector: 'app-compare-scenario-button',
  templateUrl: './compare-scenario-button.component.html',
  styleUrls: ['./compare-scenario-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompareScenarioButtonComponent {
  @Input() public chartMenuProps!: ChartMenuProps;

  private compareScenarioConfigureMenuItem = {
    label: 'Configure',
    style: { borderTop: '1px solid lightgray' },
    command: (): void => {
      this.store.dispatch(compareScenariosOpenConfigPanelAction({ batchAction: false }));
    },
  };

  private compareScenarioEnableCurrentMenuItem = {
    label: 'Enable for this chart',
    command: async (): Promise<void> => {
      await this.compareScenarioMainClick(true, false);
    },
  };

  private compareScenarioEnableAllMenuItem = {
    label: 'Enable for all charts in scenario',
    command: async (): Promise<void> => {
      await this.compareScenarioMainClick(true, true);
    },
  };

  private compareScenarioDisableCurrentMenuItem = {
    label: 'Disable for this chart',
    command: (): void => {
      this.store.dispatch(isCompareScenarioActiveAction({ batchAction: false, isCompareScenarioActive: false }));
    },
  };

  private compareScenarioDisableAllMenuItem = {
    label: 'Disable for all charts in scenario',
    command: (): void => {
      this.store.dispatch(isCompareScenarioActiveAction({ batchAction: true, isCompareScenarioActive: false }));
    },
  };

  public compareScenarioAdvancedButtonMenuItemsWhenDisabled: MenuItem[] = [
    this.compareScenarioEnableCurrentMenuItem,
    this.compareScenarioEnableAllMenuItem,
    this.compareScenarioDisableAllMenuItem,
    this.compareScenarioConfigureMenuItem,
  ];

  public compareScenarioAdvancedButtonMenuItemsWhenEnabled: MenuItem[] = [
    this.compareScenarioDisableCurrentMenuItem,
    this.compareScenarioDisableAllMenuItem,
    this.compareScenarioEnableAllMenuItem,
    this.compareScenarioConfigureMenuItem,
  ];

  constructor(private store: Store) {}

  public async compareScenarioMainClick(isCompareScenarioActive: boolean, batchAction = false): Promise<void> {
    const completedSimulateResults = (await firstValueFrom(this.store.select(getCompletedResultsForCurrentModule))).filter((result) =>
      isSimulateBased(result.moduleType),
    );
    const currentScenario = await firstValueFrom(this.store.select(getCurrentScenario));
    const scenariosToCompare = await firstValueFrom(this.store.select(getScenariosToCompare));
    if (!currentScenario) {
      return;
    }

    const isCurrentScenarioWithResults = completedSimulateResults.some((result) => result.scenarioId === currentScenario.Id);
    const allScenariosToCompareHaveResults = scenariosToCompare.scenarioIds.every((scenarioId) =>
      completedSimulateResults.some((result) => result.scenarioId === scenarioId),
    );

    if (!isCurrentScenarioWithResults || scenariosToCompare.scenarioIds.length === 0 || !allScenariosToCompareHaveResults) {
      this.store.dispatch(compareScenariosOpenConfigPanelAction({ batchAction }));
    } else {
      this.store.dispatch(isCompareScenarioActiveAction({ batchAction, isCompareScenarioActive }));
    }
  }
}
