<app-generic-modal [title]="modalTitle" [modalButtonsConfigs]="modalButtonsConfigs" >
  <app-radio-group
    [isUiLockable]="false"
    [items]="[
      { value: false, text: 'Default' },
      { value: true, text: 'Custom' }
    ]"
    [value]="isOverrideStyle"
    (primitiveValueChanged)="onOverrideStyleChanged($event.value)"
    class="grid-two-one"
    id="customize-gradient-line-style-radio"
    dataCy="customize-gradient-line-style-radio"
    layout="horizontal"
  >
  </app-radio-group>

  <app-chart-gradient-line-details *ngIf="gradientLine.style" [lineStyle]="gradientLine.style" (valueChanged)="emitStyleValue($event)">
  </app-chart-gradient-line-details>
</app-generic-modal>
