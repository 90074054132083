<div class="grid-form-container" *ngIf="pumping">
  <div class="grid-two-one">
    <app-radio-group
      [source]="pumping"
      [key]="'PumpingDirection'"
      id="pumping-direction"
      [items]="items"
      [layout]="'horizontal'"
      (valueChanged)="dataChange.emit($event)"
      dataCy="pumping-direction"
    >
    </app-radio-group>
  </div>
</div>
