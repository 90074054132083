<app-grid
  *ngIf="height > 0"
  [dataSource]="lowerCompletionData"
  [gridConfig]="gridConfig"
  [columns]="gridConfig.columns"
  [isMergingOfRowsEnabled]="true"
  [showCrudControls]="false"
  [height]="height"
  (selectedRowsChanged)="selectedRowsChanged.emit($event)"
  dataCy="lower-completion-grid"
></app-grid>
