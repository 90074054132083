import { NgModule } from '@angular/core';

import { DevConsoleComponent } from './dev-console.component';
import { DevConsoleModalComponent } from './dev-console-modal/dev-console-modal.component';
import { ButtonModule } from '../components/button/button.module';
import { ClientErrorHandlerService } from '../components/error/client-error-handler.service';
import { CommonModule } from '@angular/common';
import { ModalsModule } from '../../common-modules/modals/modals.module';

@NgModule({
  imports: [ButtonModule, CommonModule, ModalsModule],
  declarations: [DevConsoleComponent, DevConsoleModalComponent],
  exports: [DevConsoleComponent],
  providers: [ClientErrorHandlerService],
})
export class DevConsoleModule {}
