<div *ngIf="parsedResult?.isParsed && dataSource && height > 0" class="preview-grid" [style.height.px]="height"
     data-cy="import-data-grid">
  <cdk-virtual-scroll-viewport [tvsItemSize]="20" [headerHeight]="79" [headerEnabled]="true" class="wrapper"
                               [style.height.px]="height">
    <table
      mat-table
      [dataSource]="dataSource"
      class="table table-sm table-bordered with-selection-col"
    >
      <tr mat-header-row *matHeaderRowDef="colNames; sticky: true"></tr>
      <tr mat-row [class.alternate]="rowIndex % 2 === 0"
          *matRowDef="let row; columns: colNames; let rowIndex = index"></tr>
      <ng-container [matColumnDef]="col" *ngFor="let col of colNames; let cellIndex = index">
        <ng-container *matHeaderCellDef>
          <th
            mat-header-cell
          >
            <app-select
              *ngIf="cellIndex > 0"
              [value]="getColumnSelectionKeyByIndex(cellIndex)"
              [items]="configColumnItems"
              [disableSorting]="true"
              [isGridInput]="true"
              [overrideErrorMessage]="columnTypeSelectError(getColumnSelectionKeyByIndex(cellIndex))"
              [dataCy]="'paste-grid-column-type-select-col-' + cellIndex"
              (primitiveValueChanged)="updateTypeSelection($event.value, cellIndex)"
            >
            </app-select>
            <app-select
              *ngIf="cellIndex > 0"
              [value]="columnSelections[cellIndex].unit"
              [items]="getUnitSymbolItemsByIndex(cellIndex)"
              [disabled]="getUnitSymbolItemsByIndex(cellIndex).length === 0"
              [selectPlaceholder]="getUnitSymbolItemsByIndex(cellIndex).length === 0 || getColumnSelectionKeyByIndex(cellIndex) === 'ignore' ? 'Not applicable': 'Select an option'"
              [disableSorting]="true"
              [isGridInput]="true"
              [dataCy]="'paste-grid-column-unit-select-col-' + cellIndex"
              (primitiveValueChanged)="updateUnitSelection($event.value, cellIndex)"
              [overrideErrorMessage]="columnUnitSelectError(getColumnSelectionKeyByIndex(cellIndex))"
            >
            </app-select>
          </th>
        </ng-container>
        <ng-container *matCellDef="let row; let rowIndex = index">
          <td
            mat-cell
            class="cell"
          >
            <ng-container *ngIf="cellIndex === 0">
              <app-check-box
                [value]="rowsToInclude[rowIndex]"
                [dataCy]="'paste-grid-row-to-include-checkbox-row-' + rowIndex"
                (primitiveValueChanged)="updateRowsToIgnore(!rowsToInclude[rowIndex], rowIndex)"
              >
              </app-check-box>
            </ng-container>
            <span *ngIf="cellIndex > 0">{{ row[cellIndex] }}</span>
          </td>
        </ng-container>
      </ng-container>
    </table>
  </cdk-virtual-scroll-viewport>
</div>
