import { ChangeDetectionStrategy, Component, Input, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientAuthService } from '../../../common-modules/auth/client-auth.service';
import { ButtonModule } from '../button/button.module';
import { PagePanelModule } from '../../../common-modules/page-panel/page-panel.component';
import { ConnectionErrorType, getHandledBackendConnectionErrorMessage } from '@dunefront/common/exceptions/errors';
import { AppTargetConfig } from '../../services/app-target-config';
import { ApiService } from '../../backend-connection/api.service';
import { ModalService } from '../../../common-modules/modals/modal.service';

@Component({
  selector: 'app-auth-error',
  templateUrl: './auth-error.component.html',
  styleUrls: ['./auth-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthErrorComponent {
  @Input()
  public errorMessage: string | undefined;

  public readonly appName: string;

  constructor(
    private clientAuthService: ClientAuthService,
    private apiService: ApiService,
    private config: AppTargetConfig,
    private modalService: ModalService,
  ) {
    this.appName = config.appName;
  }
  public async logoutClick(): Promise<void> {
    await this.clientAuthService.logout();
  }

  public async resendVerificationEmail(): Promise<void> {
    await this.apiService.resendVerificationEmail();
    await this.modalService.showAlert('Verification email has been sent.', '', 'sm', false, true);
  }

  public get isEmailVerificationErrorMessage(): boolean {
    return (this.errorMessage ?? '').startsWith(ConnectionErrorType.ERROR_EMAIL_NOT_VERIFIED);
  }

  protected readonly getHandledBackendConnectionErrorMessage = getHandledBackendConnectionErrorMessage;
}

@NgModule({
  imports: [CommonModule, ButtonModule, PagePanelModule],
  declarations: [AuthErrorComponent],
  exports: [AuthErrorComponent],
  providers: [ApiService],
})
export class AuthErrorModule {}
