<div
  class="top-header center-text"
  #headerCell
  [style.height.px]="headerTopRowHeight"
  [tooltipDisabled]="!tooltipEnabled"
  [pTooltip]="headerText"
  [tooltipStyleClass]="'regular-tooltip'"
  positionStyle="absolute"
>
  <span class="top-header-text" [class.with-error]="showError === true">{{ headerText }}</span>
  <span class="error-icon-wrapper" *ngIf="showError === true">
    <span class="error-row-icon error-icon"></span>
  </span>
</div>
<div class="bottom-header" *ngIf="showHeaderUnits">
  <span [innerHTML]="unitSystemText"></span>
</div>
