<app-chart-controller
  *ngIf="chartState && displayChart; else defaultMessage"
  [chartState]="chartState"
  [chartId]="chartId"
  [chartDisplayName]="chartDisplayName"
  [drawableProviderId]="drawableProviderId"
  [reverseArgument]="true"
  [displayLegend]="false"
  [dataCy]="'psd-chart'"
  [tension]="0.2"
>
</app-chart-controller>
<ng-template #defaultMessage>
  <div class="text-center">PSD data validation errors must be corrected before chart can be generated.</div>
</ng-template>
