import { Injectable } from '@angular/core';
import {
  DeleteCaliperRowAction,
  DeleteCasingRowAction,
  DeleteSurveyRowAction,
  DeleteZoneRowsAction,
  ImportTempProfileAction,
  InsertCaliperRowAction,
  InsertCasingRowAction,
  InsertSurveyRowAction,
  InsertZoneRowsAction,
  UpdateCaliperRowAction,
  UpdateCasingRowAction,
  UpdateSurveyRowAction,
  UpdateWellAction,
  UpdateZoneRowAction,
  WellModuleName,
} from '@dunefront/common/modules/well/well-module.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import { BackendConnectionService } from '../../shared/backend-connection/backend-connection.service';
import * as actions from './well.actions';
import { importTemperatureProfileAction, importTemperatureProfileSuccessAction } from './well.actions';
import { CrudResponse } from '@dunefront/common/modules/common.actions';
import { WellDto } from '@dunefront/common/modules/well/dto/well.dto';
import { dataFailed, updateRowSuccess } from '../app.actions';
import { BaseWsEffects } from '../base-ws.effects';
import { ModalService } from '../../common-modules/modals/modal.service';
import { SurveyFactory } from '@dunefront/common/modules/well/model/survey/survey.factory';
import { CasingPipesFactory } from '@dunefront/common/modules/pipes/casing-pipes/casing-pipes.factory';
import { CaliperFactory } from '@dunefront/common/modules/well/model/caliper/caliper.factory';
import { ZoneFactory } from '@dunefront/common/modules/well/model/zone/zone.factory';
import { WellFactory } from '@dunefront/common/modules/well/model/well.factory';
import { ValidatedSettings } from '@dunefront/common/modules/settings/dto/settingsDto';
import { getValidatedDeveloperSettings, getValidatedSettings } from '../settings/validated-settings.selectors';
import { getCurrentScenarioId } from '../scenario/scenario.selectors';
import { getDeleteResultsParams } from '../import-data/import-data.selectors';
import { JobidHelper } from '@dunefront/common/common/jobid-helper';
import { WsActionPropsFactory } from '@dunefront/common/common/ws-action/ws-action-props.factory';
import { deleteResultsAction } from '../data-storage/data-storage.actions';
import { getValidatedWellModuleState } from './validated-well.selectors';
import { getCurrentAppModuleType } from '../ui/ui.selectors';

@Injectable()
export class WellEffects extends BaseWsEffects {
  public settings!: ValidatedSettings;

  constructor(actions$: Actions, store: Store, wsService: BackendConnectionService, modalService: ModalService) {
    super(actions$, wsService, WellModuleName, false, true, modalService, store);
    store.select(getValidatedSettings).subscribe((settings) => (this.settings = settings));
  }

  //region Survey grid
  public insertSurveyRow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.insertSurveyRowsAction),
      mergeMap((action) => this.emitInsert(new InsertSurveyRowAction(WsActionPropsFactory.insertAction(action, SurveyFactory.toDto)))),
    ),
  );

  public updateSurveyRow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.updateSurveyRowAction),
      mergeMap((action) => this.emitUpdate(new UpdateSurveyRowAction(WsActionPropsFactory.updateAction(action, SurveyFactory.toDto)))),
    ),
  );

  public deleteSurveyRow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.deleteSurveyRowsAction),
      mergeMap((action) => this.emitDelete(new DeleteSurveyRowAction(action))),
    ),
  );

  public importTempProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(importTemperatureProfileAction),
      concatLatestFrom(() => [
        this.store.select(getCurrentScenarioId),
        this.store.select(getValidatedDeveloperSettings),
        this.store.select(getCurrentAppModuleType),
      ]),
      mergeMap(([action, scenarioId, developerSettings, moduleType]) =>
        this.emit<CrudResponse>(
          new ImportTempProfileAction(
            scenarioId,
            action.sourceScenarioId,
            action.sourceRangeId,
            action.sourceModuleType,
            action.bottomholeCirculating,
            JobidHelper.generateJobId(),
            developerSettings,
            moduleType,
          ),
          'Importing Temperature Profile...',
          '',
          true,
        ).pipe(
          mergeMap((result) => [updateRowSuccess(result.payload), importTemperatureProfileSuccessAction()]),
          catchError((err: any) => of(dataFailed(err))),
        ),
      ),
    ),
  );

  public importTempProfileSuccessDeleteResults$ = createEffect(() =>
    this.actions$.pipe(
      ofType(importTemperatureProfileSuccessAction),
      concatLatestFrom(() => this.store.select(getDeleteResultsParams)),
      filter(([, data]) => data.fileHash != null),
      map(() => deleteResultsAction()),
    ),
  );

  //endregion Survey grid

  //region Casing grid
  public insertCasingRow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.insertCasingRow),
      mergeMap((action) => this.emitInsert(new InsertCasingRowAction(WsActionPropsFactory.insertAction(action, CasingPipesFactory.toDto)))),
    ),
  );
  public updateCasingRow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.updateCasingRow),
      mergeMap((action) => this.emitUpdate(new UpdateCasingRowAction(WsActionPropsFactory.updateAction(action, CasingPipesFactory.toDto)))),
    ),
  );

  public deleteCasingRow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.deleteCasingRows),
      mergeMap((action) => this.emitDelete(new DeleteCasingRowAction(action))),
    ),
  );
  public insertCaliperRow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.insertCaliperRow),
      mergeMap((action) => this.emitInsert(new InsertCaliperRowAction(WsActionPropsFactory.insertAction(action, CaliperFactory.toDto)))),
    ),
  );
  public updateCaliperRow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.updateCaliperRow),
      mergeMap((action) => this.emitUpdate(new UpdateCaliperRowAction(WsActionPropsFactory.updateAction(action, CaliperFactory.toDto)))),
    ),
  );

  //endregion Casing grid

  //region Caliper grid
  public deleteCaliperRow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.deleteCaliperRows),
      mergeMap((action) => this.emitDelete(new DeleteCaliperRowAction(action))),
    ),
  );
  public insertZoneRows$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.insertZoneRows),
      mergeMap((action) => this.emitInsert(new InsertZoneRowsAction(WsActionPropsFactory.insertAction(action, ZoneFactory.toDto)))),
    ),
  );
  public updateZoneRow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.updateZoneRow),
      mergeMap((action) => this.emitUpdate(new UpdateZoneRowAction(WsActionPropsFactory.updateAction(action, ZoneFactory.toDto)))),
    ),
  );

  //endregion Caliper grid

  // region Reservoir Grid
  public deleteZoneRows$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.deleteZoneRows),
      mergeMap((action) => this.emitDelete(new DeleteZoneRowsAction(action))),
    ),
  );

  public afterWellChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.changeWellPropertyAction, actions.changeTemperatureProfilePropertyAction),
      concatLatestFrom(() => this.store.select(getValidatedWellModuleState).pipe(filter((well) => well != null))),
      map(([actionsArr, well]) =>
        actions.updateWell({
          rows: [WellFactory.toDto(well)],
          colIds: [actionsArr.key] as (keyof WellDto)[],
          shouldResetResults: actionsArr.shouldResetResults,
          scenarioId: well.ScenarioId,
        }),
      ),
    ),
  );
  public updateWell$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.updateWell),
      mergeMap((action) => this.emitUpdate(new UpdateWellAction(action))),
    ),
  );

  // endregion
}
