<div class="psd-selection-container" data-cy="psd-selection-container">
  <div>
    <app-check-box
      *ngFor="let psdData of psdChartSelectionData; let i = index"
      [label]="psdLabels[i]"
      [source]="psdData.psd"
      [key]="'IsSelected'"
      [dataCy]="'psd-selection-' + psdData.psd.Id"
      (valueChanged)="updatePSD($event, i)"
      [overrideErrorMessage]="psdData.psd.isValid ? '' : 'Correct data validation errors to plot PSD'"
    >
    </app-check-box>
  </div>
  <div class="buttons-panel grid-four-one">
    <div class="align-right">
      <button appButton type="button" class="btn btn-primary btn-sm" (buttonClick)="selectUnselectAll(false)">Unselect All</button>
      <button appButton type="button" class="btn btn-primary btn-sm" (buttonClick)="selectUnselectAll(true)">Select All</button>
    </div>
  </div>
</div>
