import { IAppTargetConfig } from '@dunefront/common/common/app-target-config';
import { getSentryBasicOptions } from '@dunefront/common/common/sentry';
import * as Sentry from '@sentry/angular';
import { rewriteFramesIntegration } from '@sentry/angular';
import { IEnvironment } from '../environment-interface';
import { getBackendToClientConfig } from '@dunefront/common/backend-to-client-config';
import { StackFrame } from '@sentry/node';

const { SentryEnvironment, SentryDSN } = getBackendToClientConfig();

const iteratee = (frame: StackFrame): StackFrame => {
  let abs_path = frame.filename;
  if (abs_path != null && abs_path.length > 0) {
    const fileName = abs_path.split('/').reverse()[0];
    abs_path = `/${fileName}`;
  }

  return { ...frame, abs_path: abs_path };
};

export const initSentry = (environment: IEnvironment, config: IAppTargetConfig): void => {
  if (!SentryEnvironment || !SentryDSN) {
    console.warn('Sentry not initialized!');
    return;
  }

  const options = getSentryBasicOptions(SentryDSN, config, 'Client', SentryEnvironment);
  Sentry.init({
    ...options,
    integrations: [rewriteFramesIntegration({ iteratee })],
    transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
  });
  Sentry.setTag('service', 'client');
  Sentry.setTag('isTest', !environment.production);
  Sentry.setTag('appName', config.appName);
};
