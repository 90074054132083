<ng-container *ngIf="dataSource$ | async as dataSource">
  <app-grid
    *ngIf="height > 0"
    [dataSource]="dataSource"
    [gridConfig]="gridConfig"
    [columns]="columns"
    (selectedRowsChanged)="selectedRowsChanged.emit($event)"
    [showCrudControls]="false"
    [height]="height"
    [singleItemSelectionMode]="true"
  ></app-grid>
</ng-container>
