import { FlowPathType, ResuspensionCalculatorDto } from './resuspension-calculator.dto';
import { IModuleState, IValidatedModuleState } from '../../../common/common-state.interfaces';

export type ResuspensionCalculatorModuleState = ResuspensionCalculatorDto & IModuleState;
export type ValidatedResuspensionCalculatorModuleState = ResuspensionCalculatorModuleState &
  IValidatedModuleState<ResuspensionCalculatorModuleState>;

export const ResuspensionCalculatorInitialState: ResuspensionCalculatorModuleState = {
  isLoaded: false,
  Concentration: 0,
  FlowPathType: FlowPathType.Pipe,
  FluidId: -1,
  GravelId: -1,
  MaxDiameter: 0,
  MinDiameter: 0,
  ResuspensionRate: 0,
  ResuspensionVelocity: 0,
  ScenarioId: -1,
  Temperature: 0,
};
