import { ChangeDetectionStrategy, Component } from '@angular/core';
import { OptionsComponent } from '../../options-component';

@Component({
  selector: 'app-general-options',
  templateUrl: './general-options.component.html',
  styleUrls: ['./general-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeneralOptionsComponent extends OptionsComponent {}
