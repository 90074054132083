<app-grid
  [dataSource]="psdTable"
  [headerTopRowHeight]="30"
  [showCrudControls]="false"
  elementId="summary-grid"
  [gridConfig]="gridConfig"
  [columns]="gridConfig.columns"
  [height]="height"
  [isFirstRowInsertingAllowed]="true"
></app-grid>
