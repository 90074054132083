import { createAction, props } from '@ngrx/store';
import {
  ChartTimeVolMode,
  ExportDataStartJobActionPayload,
  GetChartDataParams,
  GetChartUserAddonsResultsActionResponse,
  GetDepthDataResultsActionResponse,
  GetReportingColumnsActionResponse,
  IDepthBasedResult,
  ResultsSourceKey,
  TimeVolChartDataResponse,
} from '@dunefront/common/modules/reporting/reporting-module.actions';
import { ITableState } from '@dunefront/common/common/common-grid.interfaces';
import { IChartResultColumn } from './reporting.selectors';
import { ICalculationEngineUpdatePayload } from '@dunefront/common/modules/calculation-engine/calculation-engine.actions';
import { ChartDataSourceType, ChartDto } from '@dunefront/common/modules/reporting/dto/chart.dto';
import { ILowerCompletionRange, IXAxisShiftUpdate, VisibleDrawable } from './reporting-module.state';
import { IChartTemplateDto } from '@dunefront/common/dto/chart-templates.dto';
import { CrudResponse } from '@dunefront/common/modules/common.actions';
import { ChartSeriesTemplateDto } from '@dunefront/common/dto/chart-series-template.dto';
import { IMarker } from '@dunefront/common/modules/reporting/dto/chart-marker.dto';
import { IAnnotation, IAxisProps, IGradientLine } from '../../common-modules/chart/chart-component-helpers/chart-types';
import { IInsertRowsWsActionProps, IUpdateRowsWsActionProps } from '@dunefront/common/ws.action';
import { WsActionPropsFactory } from '@dunefront/common/common/ws-action/ws-action-props.factory';
import { IDeleteRowsProps, IUpdateTableRowsProps } from '@dunefront/common/common/common-store-crud.interfaces';
import { ChartMdTvdMode, CrosshairMode, TooltipPosition } from '@dunefront/common/modules/reporting/reporting.settings';
import { ReportingTabDto } from '@dunefront/common/dto/reporting-tab.dto';
import { IDictionaryWithArray } from '@dunefront/common/common/state.helpers';
import { RangeDto } from '@dunefront/common/dto/range.dto';
import { ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';

// region get chart data
export const getTimeVolChartData = createAction(
  '[Reporting] getTimeVolChartData',
  props<{
    src: string;
    getChartDataParams: GetChartDataParams;
    reportingTabId?: number;
    gaugeDataRequestedAfterFileImport?: boolean;
  }>(),
);

export const getTimeVolChartDataSuccess = createAction(
  '[Reporting] getTimeVolChartDataSuccess',
  props<{
    response: TimeVolChartDataResponse;
    reportingTabId: number | undefined;
    gaugeDataRequestedAfterFileImport?: boolean;
  }>(),
);
// endregion

// region crud reporting tabs
export const addChartTabConfigureSeries = createAction(
  '[Reporting] addChartTabConfigureSeries',
  props<{ chartType: 'timevol' | 'mdtvd' }>(),
);

export const addChartTabConfigDone = createAction(
  '[Reporting] addChartTabConfigDone',
  props<{ chartResultsTableState: ITableState<IChartResultColumn>; chartType: 'timevol' | 'mdtvd' }>(),
);

export const copyReportingChartTabAction = createAction('[Reporting] copyReportingChartTabAction');

export const deleteReportingChart = createAction('[Reporting] deleteReportingChart', props<IDeleteRowsProps>());

export const setSelectedReportingChartTabId = createAction(
  '[Reporting] setSelectedReportingChartTabId',
  props<{ tabId: number | undefined }>(),
);

export const saveChartConfigDialog = createAction(
  '[Reporting] saveChartConfigDialog',
  props<{ chartResultsTableState: ITableState<IChartResultColumn>; chartId: number; scenarioId: number }>(),
);

export const reorderReportingTabAction = createAction(
  '[Reporting] reorder reporting tab',
  props<{
    fromIndex: number;
    toIndex: number;
  }>(),
);

export const getSourceReportingTabs = createAction(
  '[Reporting] get source reporting tabs',
  props<{
    scenarioId: number;
  }>(),
);
export const getSourceReportingTabsSuccess = createAction(
  '[Reporting] get source reporting tabs success',
  props<{ sourceReportingTabs: ReportingTabDto[] }>(),
);

// endregion

export const getReportingColumnsSuccess = createAction(
  '[Reporting] GetReportingColumnsSuccess',
  props<{ result: GetReportingColumnsActionResponse; ranges: IDictionaryWithArray<RangeDto> }>(),
);
export const clearReportingChartData = createAction(
  '[Reporting] ClearReportingChartData',
  props<{
    chartDataSourceType: ChartDataSourceType;
  }>(),
);

export const clearResultsChartsData = createAction('[Reporting] ClearResultsChartsData');

// region depth data

export const checkDepthDataAction = createAction(
  '[Reporting] CheckDepthData',
  props<{
    depthDataKeys: ResultsSourceKey[];
  }>(),
);
export const requestedDepthData = createAction(
  '[Reporting] RequestedDepthData',
  props<{ lowerCompletionRange: ILowerCompletionRange; depthDataKey: ResultsSourceKey }>(),
);
export const getDepthDataSuccess = createAction(
  '[Reporting] getDepthDataSuccess',
  props<{ depthDataKey: ResultsSourceKey; response: GetDepthDataResultsActionResponse }>(),
);

export const getSinglePointDepthDataSuccess = createAction(
  '[Reporting] getSinglePointDepthDataSuccess',
  props<{ depthResults: IDepthBasedResult[] }>(),
);

// endregion

// region user addons

export const getChartUserAddons = createAction(
  '[Reporting] getChartUserAddons',
  props<{
    scenarioId: number;
    chartId: number;
  }>(),
);
export const getChartUserAddonsSuccess = createAction(
  '[Reporting] getChartUserAddonsSuccess',
  props<GetChartUserAddonsResultsActionResponse>(),
);

// endregion

// region chart marker

export const updateChartMarker = createAction('[Reporting] updateChartMarker', props<IMarker>());
export const insertChartMarker = createAction('[Reporting] insertChartMarker', props<IMarker>());
export const deleteChartMarker = createAction('[Reporting] deleteChartMarker', props<IDeleteRowsProps>());
// endregion

// region chart annotation

export const insertChartAnnotation = createAction('[Reporting] insertChartAnnotation', props<IAnnotation>());
export const updateChartAnnotation = createAction(
  '[Reporting] updateChartAnnotation',
  props<{
    annotations: IAnnotation[];
  }>(),
);
export const deleteChartAnnotation = createAction('[Reporting] deleteChartAnnotation', props<IDeleteRowsProps>());

// endregion

// region chart gradient lines

export const insertChartGradientLine = createAction('[Reporting] insertChartGradientLine', props<IGradientLine>());
export const updateChartGradientLines = createAction(
  '[Reporting] updateChartGradientLines',
  props<{
    lines: IGradientLine[];
  }>(),
);
export const deleteChartGradientLine = createAction('[Reporting] deleteChartGradientLine', props<IDeleteRowsProps>());

// endregion

// region chart axis prop

export const insertChartAxisProperty = createAction('[Reporting] insertChartAxisProperty', props<ChartAxisPropertiesActionProps>());
export const updateChartAxisProperties = createAction('[Reporting] updateChartAxisProperties', props<ChartAxisPropertiesActionProps>());
export const resetChartAxisLimitsProperties = createAction('[Reporting] resetChartAxisLimitsProperties');
export const clearCustomizations = createAction('[Reporting] clearCustomizations', props<{ chartId: number }>());

// endregion

// region x-axis shift

export const updateXAxisShiftAction = createAction(
  '[Reporting] updateXAxisShift',
  props<{
    xAxisShiftUpdates: IXAxisShiftUpdate[];
  }>(),
);

export const updateXAxisShiftSuccessAction = createAction(
  '[Reporting] updateXAxisShiftSuccess',
  props<{
    fileIds: number[];
  }>(),
);

export const resetXAxisShiftAction = createAction('[Reporting] resetXAxisShift');

// endregion

// region calculations

export interface CalculationProgressUpdatedCurrentScenarioActionProps {
  calcPayload: ICalculationEngineUpdatePayload;
  scenarioId: number;
  timeVolMode: ChartTimeVolMode;
  rangeId: number;
  moduleType: ModuleType;
}

export const calculationProgressUpdatedCurrentScenarioAction = createAction(
  '[Reporting] calculationProgressUpdatedCurrentScenarioAction',
  props<CalculationProgressUpdatedCurrentScenarioActionProps>(),
);

export const updateChartResultColumnRow = createAction(
  '[Reporting] updateChartResultColumnRow',
  props<IUpdateTableRowsProps<IChartResultColumn>>(),
);
export const setChartResultColumns = createAction(
  '[Reporting] setChartResultColumns',
  props<{
    updateType: ChartUpdateType;
  }>(),
);

export const setSelectedSimulationTime = createAction(
  '[Reporting] setSelectedSimulationTime',
  props<{
    selectedSimulationTime: number;
  }>(),
);

// endregion

// region export data

export const startExportDataJobAction = createAction(
  '[Reporting] startExportDataJobAction',
  props<{ payload: ExportDataStartJobActionPayload }>(),
);

export const validateAndStartExportDataJobAction = createAction(
  '[Reporting] validateAndStartExportDataJobAction',
  props<{ payload: ExportDataStartJobActionPayload }>(),
);

export const startExportDataUnitsConversionAction = createAction(
  '[Reporting] startExportDataUnitsConversionAction',
  props<{ jobId: string; payload: ExportDataStartJobActionPayload }>(),
);
export const exportDataSuccessAction = createAction('[Reporting] exportDataSuccessAction');

// endregion data

// region chart templates

export const loadChartTemplates = createAction('[Reporting] loadChartTemplates');
export const loadChartTemplatesSuccess = createAction(
  '[Reporting] loadChartTemplatesSuccess',
  props<{
    charts: IChartTemplateDto[];
  }>(),
);
export const newReportingChartTabsFromTemplates = createAction(
  '[Reporting] newReportingChartTabsFromTemplates',
  props<{ chartTemplates: IChartTemplateDto[] }>(),
);

export const createChartTemplate = createAction(
  '[Reporting] createChartTemplate',
  props<{
    template: IChartTemplateDto;
  }>(),
);

export const chartTemplateAlreadyExistsAction = createAction(
  '[Reporting] chartTemplateAlreadyExists',
  props<{ template: IChartTemplateDto; existingTemplateId: number }>(),
);

export const updateChartTemplateAction = createAction(
  '[Reporting] updateChartTemplate',
  props<{
    template: IChartTemplateDto;
  }>(),
);

export const deleteChartTemplatesAction = createAction(
  '[Reporting] deleteChartTemplates',
  props<{
    templatesIds: string[];
  }>(),
);

export const closeEditChartTemplateModalAction = createAction('[Reporting] closeEditChartTemplateModal');

export const reportingChartCreated = createAction('[Reporting] reportingChartCreated', props<CrudResponse>());

// endregion

// region chart series template

export const updateChartSeriesTemplate = createAction(
  '[Reporting] updateChartSeriesTemplate',
  (dto: ChartSeriesTemplateDto): IUpdateRowsWsActionProps<ChartSeriesTemplateDto> => WsActionPropsFactory.update([dto], false),
);
export const insertChartSeriesTemplate = createAction(
  '[Reporting] insertChartSeriesTemplate',
  (dto: ChartSeriesTemplateDto): IInsertRowsWsActionProps<ChartSeriesTemplateDto> => WsActionPropsFactory.insertDto(dto, false),
);
export const deleteChartSeriesTemplate = createAction('[Reporting] deleteChartSeriesTemplate', props<IDeleteRowsProps>());
export const getChartSeriesTemplates = createAction('[Reporting] getChartSeriesTemplates');
export const getChartSeriesTemplatesSuccess = createAction(
  '[Reporting] getChartSeriesTemplates Success',
  props<{ chartSeriesTemplates: ChartSeriesTemplateDto[] }>(),
);

// endregion

export const isOptimizeActiveAction = createAction(
  '[Reporting] optimizeEvaluationChartAction',
  props<{
    batchAction: boolean;
    isOptimizeActive: boolean;
  }>(),
);

export const compareScenarioMainButtonClickAction = createAction(
  '[Reporting] compareScenarioMainButtonClickAction',
  props<{ isCompareScenarioActive: boolean }>(),
);

export const isCompareScenarioActiveAction = createAction(
  '[Reporting] compareScenarioChartAction',
  props<{ batchAction: boolean; isCompareScenarioActive: boolean }>(),
);

export const setGaugeDataRequestedAfterFileImport = createAction(
  '[Reporting] setGaugeDataRequestedAfterFileImport',
  props<{ gaugeDataRequestedAfterFileImport: boolean }>(),
);

export const updateVisibleDrawables = createAction(
  '[Reporting] updateVisibleDrawables',
  props<{
    currentlyVisibleDrawables: VisibleDrawable[];
  }>(),
);

export const updateChartAction = createAction(
  '[Reporting] updateChart',
  (chartDtos: ChartDto[]): IUpdateRowsWsActionProps<ChartDto> => WsActionPropsFactory.update(chartDtos, false, []),
);

export const updateChartAxisSelectionAction = createAction(
  '[Reporting] updateChartAxisSelection',
  props<{
    changeScope: 'single' | 'range' | 'scenario' | 'project';
    chartTimeVolMode?: ChartTimeVolMode;
    chartMdTvdMode?: ChartMdTvdMode;
  }>(),
);

export const reloadChartAfterXAxisShiftUndoRedoAction = createAction('[Reporting] reloadChartAfterXAxisShiftUndoRedo');

export enum ChartUpdateType {
  clear = 0,
  gaugeDataDefault = 1,
  pressureOnly = 2,
  surfacePressureOnly = 3,
  bottomHolePressureOnly = 4,
  rateOnly = 5,
}

export enum ReportingCalculationJobStatus {
  notActive = 0,
  requested = 1,
  starting = 2,
  firstData = 3,
  chartAvailable = 4,
}

export interface ChartAxisPropertiesActionProps {
  axisProps: IAxisProps[];
  secondaryArg: boolean;
}

export const setCrosshairModeAction = createAction('[Ui] setCrosshairModeAction', props<{ mode: CrosshairMode }>());
export const setTooltipPositionAction = createAction(
  '[Ui] setTooltipPositionAction',
  props<{
    position: TooltipPosition;
  }>(),
);
export const setMaxSeriesInTooltipAction = createAction(
  '[Ui] setTooltipMaxSeries',
  props<{
    maxSeriesInTooltip: number;
  }>(),
);

export const navigateToScenarioAction = createAction(
  '[Reporting] navigateToChartWithScenarioAction',
  props<{
    scenarioId: number;
  }>(),
);
