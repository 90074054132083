import { loadScenarioDataSuccessAction, updateRowSuccess } from '../../app.actions';
import { createReducer, on } from '@ngrx/store';
import { FrictionCalculatorModuleReducerHelper } from './friction-calculator.reducer.helper';
import {
  FrictionCalculatorInitialState,
  FrictionCalculatorModuleState,
} from '@dunefront/common/modules/calculators/friction-calculator/friction-calculator-module.state';
import { removeDataResultsFromStoreIfNeeded } from '../../data-storage/data-storage.actions';

export const FrictionCalculatorModuleFeatureName = 'frictionCalculator';

export const frictionCalculatorModuleReducer = createReducer<FrictionCalculatorModuleState>(
  FrictionCalculatorInitialState,
  on(
    loadScenarioDataSuccessAction,
    (state, action): FrictionCalculatorModuleState =>
      FrictionCalculatorModuleReducerHelper.onLoadScenarioDataSuccessAction(state, action.loadScenarioResponse.frictionCalculatorModule),
  ),
  on(
    updateRowSuccess,
    (state, action): FrictionCalculatorModuleState =>
      FrictionCalculatorModuleReducerHelper.onUpdateFrictionCalculatorSuccess(state, action),
  ),
  on(
    updateRowSuccess,
    (state, action): FrictionCalculatorModuleState =>
      FrictionCalculatorModuleReducerHelper.onUpdateFrictionCalculatorResultSuccess(state, action),
  ),
  on(
    removeDataResultsFromStoreIfNeeded,
    (state, action): FrictionCalculatorModuleState =>
      FrictionCalculatorModuleReducerHelper.onRemoveDataResultsFromStore(state, action.deleteResultsFilter),
  ),
);
