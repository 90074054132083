import { Store } from '@ngrx/store';
import { AfterViewInit, Directive } from '@angular/core';
import { getIsLoaded } from '../../+store/data-storage/data-storage.selectors';
import { take } from 'rxjs/operators';
import { checkIfVacuumNeededAndCloseFile } from '../../+store/backend-connection/backend-connection.actions';

@Directive()
export class HomeBaseComponent implements AfterViewInit {
  constructor(public store: Store) {}

  public ngAfterViewInit(): void {
    this.store
      .select(getIsLoaded)
      .pipe(take(1))
      .subscribe((isDbLoaded) => {
        if (isDbLoaded) {
          this.store.dispatch(checkIfVacuumNeededAndCloseFile());
        }
      });
  }
}
