import { createReducer, on } from '@ngrx/store';
import { importDataModuleInitialState, ImportDataModuleState } from './import-data-module.state';
import {
  includedColumnIdChangedAction,
  loadImportDataTemplatesSuccessAction,
  nextColumnAction,
  prevColumnAction,
  resetImportDataStateAction,
  updateImportDataStateAction,
} from './import-data.actions';
import { ImportDataReducerHelper } from './import-data.reducer.helper';
import { deleteRowsSuccess, updateRowSuccess } from '../app.actions';

export const importDataModuleReducer = createReducer<ImportDataModuleState>(
  { ...importDataModuleInitialState },
  on(updateImportDataStateAction, (state, action): ImportDataModuleState => ({ ...state, ...action })),
  on(resetImportDataStateAction, (state): ImportDataModuleState => ImportDataReducerHelper.resetImportDataStateAction(state)),
  on(
    loadImportDataTemplatesSuccessAction,
    (state, action): ImportDataModuleState => ImportDataReducerHelper.loadTemplatesSuccessAction(state, action),
  ),
  on(updateRowSuccess, (state, action): ImportDataModuleState => ImportDataReducerHelper.updateRowSuccess(state, action)),
  on(deleteRowsSuccess, (state, action): ImportDataModuleState => ImportDataReducerHelper.deleteRowsSuccess(state, action)),
  on(nextColumnAction, (state): ImportDataModuleState => ImportDataReducerHelper.nextColumnAction(state)),
  on(prevColumnAction, (state): ImportDataModuleState => ImportDataReducerHelper.prevColumnAction(state)),
  on(
    includedColumnIdChangedAction,
    (state, action): ImportDataModuleState => ImportDataReducerHelper.includedColumnIdChangedAction(state, action.colId, action.isEnabled),
  ),
);
