import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LeakoffCoefficientCalculatorModuleFeatureName } from './leakoff-coefficient-calculator.reducer';
import { LeakoffCoefficientCalculatorModuleState } from '@dunefront/common/modules/calculators/leakoff-coefficient-calculator/leakoff-coefficient-calculator-module.state';
import { LeakoffCoefficientCalculatorValidation } from '@dunefront/common/modules/calculators/leakoff-coefficient-calculator/model/leakoff-coefficient-calculator.validation';

const getLeakoffCoefficientCalculatorModuleState = createFeatureSelector<LeakoffCoefficientCalculatorModuleState>(
  LeakoffCoefficientCalculatorModuleFeatureName,
);
export const getValidatedLeakoffCoefficientCalculatorModuleState = createSelector(getLeakoffCoefficientCalculatorModuleState, (state) =>
  LeakoffCoefficientCalculatorValidation.validate(state),
);

export const getHasLeakoffCalculatorResults = createSelector(
  getValidatedLeakoffCoefficientCalculatorModuleState,
  (state) =>
    state.ViscosityControlledLeakoff !== 0 || state.CompressibilityControlledLeakoff !== 0 || state.CombinedLeakoffCoefficient !== 0,
);
