import { UnitConverter } from '../../converter.interfaces';
import { PermeabilityUnit } from '../../../dto/unit-system.dto';

export class PermeabilityConverter extends UnitConverter {
  public static symbols: Array<string> = ['', 'm²', 'd', 'md'];

  public static unit = PermeabilityUnit;

  public static fromSi(value: number, unit: PermeabilityUnit): number {
    switch (unit) {
      case PermeabilityUnit.Square_meter: {
        return value;
      }
      case PermeabilityUnit.Darcy: {
        return value * 1.01325e12;
      }
      case PermeabilityUnit.Millidarcy: {
        return value * 1.01325e15;
      }
      default: {
        throw new TypeError('Unknown Permeability Unit:' + unit);
      }
    }
  }

  public static toSi(value: number, unit: PermeabilityUnit): number {
    switch (unit) {
      case PermeabilityUnit.Square_meter: {
        return value;
      }
      case PermeabilityUnit.Darcy: {
        return value / 1.01325e12;
      }
      case PermeabilityUnit.Millidarcy: {
        return value / 1.01325e15;
      }
      default: {
        throw new TypeError('Unknown Permeability Unit:' + unit);
      }
    }
  }

  public static getSymbol(unit: PermeabilityUnit): string {
    return this.symbols[unit];
  }
}
