import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { createTableRow, ITableRow, ITableState } from '@dunefront/common/common/common-grid.interfaces';
import { IGridColumnConfig } from '../../../../../shared/components/grid/grid.interfaces';
import * as wellActions from '../../../../../+store/well/well.actions';
import { Store } from '@ngrx/store';
import { GridConfig } from '../../../../../shared/components/grid/grid-config';
import { ModalService } from '../../../../../common-modules/modals/modal.service';
import { TemperatureProfileType } from '@dunefront/common/modules/well/dto/well.dto';
import { GridContainerComponent } from '../../../../../shared/components/grid/grid-container.component';
import { IScreenSize } from '../../../../../shared/services';
import { Survey } from '@dunefront/common/modules/well/model/survey/survey';
import { TemperatureProfile } from '@dunefront/common/modules/well/model/temperature-profile/temperature-profile';
import { SurveyFactory } from '@dunefront/common/modules/well/model/survey/survey.factory';
import {
  getScenarioRangeStateWithResultsInfo,
  ImportTempProfileModalComponent,
  IScenarioWithRange,
} from '../import-temp-profile-modal/import-temp-profile-modal.component';
import { GridResizeService } from '../../../../../shared/services/grid-resize.service';
import { ErrorHelper } from '@dunefront/common/common/common-state.interfaces';
import { DictionaryWithArray } from '@dunefront/common/common/state.helpers';
import { RangeConstants } from '@dunefront/common/dto/range.dto';
import { IDeleteRowsProps, IInsertRowsProps, IUpdateTableRowsProps } from '@dunefront/common/common/common-store-crud.interfaces';
import { firstValueFrom } from 'rxjs';
import { getSurveyGridConfig } from '../../../../../+store/well/well.grid.selectors';
import { isLongCalculationWithSimulateResultModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import { validateFeatureRequired } from '@dunefront/common/modules/licensing/licensing.validator';
import { LicenseFeature } from '@dunefront/common/modules/licensing/licensing.interfaces';
import { InsertLocation } from '@dunefront/common/modules/common.interfaces';

@Component({
  selector: 'app-survey-grid',
  templateUrl: './survey-grid.component.html',
  styleUrls: ['./survey-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SurveyGridComponent extends GridContainerComponent<Survey> implements OnChanges {
  @Input() public surveyData: ITableState<Survey> = { rows: [], isValid: true };
  @Input() public temperatureProfile: TemperatureProfile | undefined;

  public TemperatureProfileType = TemperatureProfileType;
  public scenarioRangeWithResults: IScenarioWithRange[] = [];

  constructor(
    store: Store,
    cdRef: ChangeDetectorRef,
    private modalService: ModalService,
    el: ElementRef,
    gridResizeService: GridResizeService,
  ) {
    super(store, cdRef, el, new SurveyGridConfig(store, modalService), gridResizeService);
    this.margin = 64;

    this.getScenarioRangeWithResults();
  }

  private get showTempCols(): boolean {
    return this.temperatureProfile?.TemperatureProfileType === TemperatureProfileType.Detailed_Calc;
  }

  public override resize(size?: IScreenSize): void {
    if (size && size.resizeType === 'x') {
      return;
    }

    this.height = 0;
    this.cdRef.markForCheck();
    super.resize();
  }

  public override async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.temperatureProfile != null) {
      this.updateColumns(await this.getColumns());
    }
  }

  public onContainerResized(): void {
    this.resize();
  }

  public onImport(): void {
    this.grid.onImportClicked();
  }

  public onInsert(): void {
    this.grid.onInsertClicked();
  }

  public onDelete(): void {
    this.grid.onDeleteClicked();
  }

  protected async getColumns(): Promise<IGridColumnConfig<Survey>[]> {
    return await firstValueFrom(this.store.select(getSurveyGridConfig));
  }

  public async openImportTempModal(): Promise<void> {
    const validateRequiredResult = validateFeatureRequired([LicenseFeature.Simulate, LicenseFeature.Evaluate], this.currentLicenseFeatures);
    if (validateRequiredResult) {
      return await this.modalService.showAlert(validateRequiredResult, 'Information');
    }

    if (this.temperatureProfile?.TemperatureProfileType !== TemperatureProfileType.Detailed_Calc) {
      return await this.modalService.showAlert(ErrorHelper.ERROR_IMPORT_TEMP_PROFILE_ONLY_DETAILED_CALC, 'Information');
    }
    if (!this.scenarioRangeWithResults.length) {
      return await this.modalService.showAlert(ErrorHelper.ERROR_NO_RESULTS_IN_FILE, 'Information');
    }

    this.modalService.open(
      ImportTempProfileModalComponent,
      { scenarioRangeWithResults: this.scenarioRangeWithResults },
      'import-temp-profile-modal',
    );
  }

  private getScenarioRangeWithResults(): void {
    this.subscription.add(
      this.store.select(getScenarioRangeStateWithResultsInfo).subscribe((scenarioStateRangeWithResults) => {
        let scenarioRangeWithResults: IScenarioWithRange[] = [];
        const simulateEvaluateResults = scenarioStateRangeWithResults.resultsInfos.filter(
          (info) => isLongCalculationWithSimulateResultModuleType(info.moduleType) && info.isCompleted,
        );

        for (const resultsInfo of simulateEvaluateResults) {
          const scenario = scenarioStateRangeWithResults.scenarios.find((scenario) => scenario.Id === resultsInfo.scenarioId);
          const range = DictionaryWithArray.get(scenarioStateRangeWithResults.ranges, resultsInfo.rangeId);
          if (!scenario) {
            continue;
          }
          const rangeName = range && resultsInfo.rangeId !== RangeConstants.EmptyRangeId ? range.Name : 'Simulate';
          scenarioRangeWithResults = [
            ...scenarioRangeWithResults,
            {
              rangeId: resultsInfo.rangeId,
              rangeName,
              scenarioId: resultsInfo.scenarioId,
              scenarioName: scenario.Name,
              moduleType: resultsInfo.moduleType,
            },
          ];
        }
        this.scenarioRangeWithResults = scenarioRangeWithResults;
        this.cdRef.markForCheck();
      }),
    );
  }
}

export class SurveyGridConfig extends GridConfig<Survey> {
  constructor(
    private store: Store,
    modalService: ModalService,
  ) {
    super(modalService);
    this.headerText = 'Survey Data';
  }

  public override updateRowsAction(props: IUpdateTableRowsProps<Survey>): void {
    this.store.dispatch(wellActions.updateSurveyRowAction(props));
  }

  public override insertRowAction(props: IInsertRowsProps<Survey>): void {
    this.store.dispatch(wellActions.insertSurveyRowsAction(props));
  }

  public override deleteRowsAction(props: IDeleteRowsProps): void {
    this.store.dispatch(wellActions.deleteSurveyRowsAction(props));
  }

  public override filterPastedRows(rows: ITableRow<Survey>[], isPasteSingleCell: boolean): ITableRow<Survey>[] {
    if (!rows.length || isPasteSingleCell) {
      return rows;
    }
    return rows.filter((row) => row.rowData.MD > 0);
  }

  public override createEmptyModel(scenarioId: number): Survey {
    return SurveyFactory.createEmpty(scenarioId);
  }

  public override createDefaultTableRows(scenarioId: number, noOfRows: number): ITableRow<Survey>[] {
    const newTableRows = [];
    for (let index = 0; index < noOfRows; index++) {
      newTableRows.push(createTableRow(this.createEmptyModel(scenarioId), 'data', index, false));
    }
    return newTableRows;
  }

  public override replaceGridAction(rows: ITableRow<Survey>[]): void {
    if (rows.length > 0) {
      const props: IInsertRowsProps<any> = {
        rows,
        refId: 0,
        insertLocation: 'replace' as InsertLocation,
        shouldResetResults: true,
      };
      this.store.dispatch(wellActions.insertSurveyRowsAction(props));
    }
  }
}
