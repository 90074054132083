import { DataType } from '../dto/data-storage';
import { UnitSystem } from '../dto/unit-system.dto';

export abstract class UnitConverter {
  public symbols: string[] = [];
  public unit: any;
  public fromSi(value: number, unit: number): number {
    return 0;
  }

  public toSi(value: number, unit: number): number {
    return 0;
  }

  public getSymbol(unit: number): string {
    return '';
  }
}

export interface IAxisUnit {
  dataType: DataType;
  unitSystem: UnitSystem;
}
