import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { DbDependentComponent } from '../../common-modules/db-connection/db-dependent.component';
import { Store } from '@ngrx/store';
import * as uiActions from '../../+store/ui/ui.actions';

@Component({
  selector: 'app-gauge-data-page',
  templateUrl: './gauge-data-page.component.html',
  styleUrls: ['./gauge-data-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GaugeDataPageComponent extends DbDependentComponent {
  constructor(store: Store, cdRef: ChangeDetectorRef) {
    super(store, cdRef);
    store.dispatch(uiActions.setAppSectionAction({ section: 'gauge-data' }));
  }
}
