import { Injectable } from '@angular/core';
import { ImportDataModuleState, IValidatedImportDataModuleState } from '../../../../../+store/import-data/import-data-module.state';
import { GroupedLineError, ILineError, IParsedFileResult } from '../../../../../+store/import-data/model/parsed-result';
import { ImportDataValidation } from '../../../../../+store/import-data/validation/import-data.validation';
import { ModalService } from '../../../modal.service';
import { CANT_PARSE_CUSTOM_FORMAT_ERROR, FileParserService } from '../../../import-paste-data-common/file-parser/file-parser.service';
import { DataArgumentGenerator, IMapColumnsResponse } from '../data-argument-generator';
import { BackendConnectionService } from '../../../../../shared/backend-connection/backend-connection.service';
import {
  DataStorageImportColumnValidateNamesAction,
  DataStorageModuleName,
} from '@dunefront/common/modules/data-storage/data-storage-module.actions';
import { ImportFileStartTimeService } from '../import-file-start-time/import-file-start-time.service';
import dayjs from 'dayjs';
import { firstValueFrom } from 'rxjs';
import { getStorageColumnNames } from '../../../../../+store/data-storage/data-storage.selectors';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class ImportFileAdvancedValidatorService {
  constructor(
    private modalService: ModalService,
    private backendConnectionService: BackendConnectionService,
    private importFileStartTimeService: ImportFileStartTimeService,
    private store: Store,
  ) {}

  public async validate(state: IValidatedImportDataModuleState, startDateOfMostRecentlyImportedFile?: number): Promise<boolean> {
    if (!this.validateColumnTypes(state)) {
      return false;
    }
    const dataArgumentGenerator = new DataArgumentGenerator();
    dataArgumentGenerator.initialize(state);

    const parsedResult = FileParserService.parsedResult;

    const allColumnsNames = (await firstValueFrom(this.store.select(getStorageColumnNames))).map((c) => c.name);
    const columns = DataArgumentGenerator.getColumns(state, allColumnsNames);

    try {
      const startDate = await this.setStartDateToXAxisCol(
        dataArgumentGenerator,
        state,
        columns,
        parsedResult,
        startDateOfMostRecentlyImportedFile,
      );

      if (startDate == null) {
        return false;
      }

      FileParserService.parsedResult.startDate = startDate;
    } catch (error: any) {
      await this.modalService.showAlert(error?.message);
      return false;
    }

    const workingIndicatorDialog = await this.modalService.showWorkingIndicator('Validating...');

    const columnNameValidationErrorMessage = await this.validateColumnNames(columns);
    if (columnNameValidationErrorMessage) {
      workingIndicatorDialog.close();
      await this.modalService.showAlert(columnNameValidationErrorMessage);
      return false;
    }

    const brokenDeltaTimeLines = await this.validateDeltaTime(dataArgumentGenerator, state, columns, parsedResult);

    const allErrorLines = [...parsedResult.skippedLineWarnings, ...brokenDeltaTimeLines].sort((a, b) => a.line - b.line);

    parsedResult.linesToSkipForWhenUploading = allErrorLines.map((e) => e.line - FileParserService.parsedResult.headerLines - 2);

    workingIndicatorDialog.close();

    const customTimeFormat = columns.dtos.find((col) => col.IsXAxis)?.CustomFormat;
    const groupedErrors = this.groupConsecutiveRows(allErrorLines, customTimeFormat);

    const warningLinesLimit = 1000;

    const errorsToDisplay = groupedErrors.slice(0, warningLinesLimit);
    const croppedErrorsQty = groupedErrors.length - errorsToDisplay.length;

    if (allErrorLines.length) {
      let errorMessage = `Found ${allErrorLines.length} rows that couldn't be parsed and will be skipped in import process.<br><br>`;
      errorsToDisplay.forEach((errGroup) => {
        const isMultiple = errGroup.from !== errGroup.to;

        const lines = `Line${isMultiple ? 's' : ''}`;
        const lineNumbers = isMultiple ? `${errGroup.from}-${errGroup.to}` : errGroup.from;

        errorMessage += `${lines} ${lineNumbers} : ${errGroup.error}<br>`;
      });

      if (croppedErrorsQty > 0) {
        errorMessage += `... and ${croppedErrorsQty} more`;
      }

      return await this.modalService.showConfirm(errorMessage, '', 'md', 'Continue', 'Cancel Import');
    }
    return true;
  }

  private validateColumnTypes(state: ImportDataModuleState): boolean {
    const validateColumnTypesErrorMessage = ImportDataValidation.validateColumnTypes(state);
    if (validateColumnTypesErrorMessage) {
      this.modalService.showAlert(validateColumnTypesErrorMessage).then();
      return false;
    }
    return true;
  }

  private async validateDeltaTime(
    dataArgumentGenerator: DataArgumentGenerator,
    state: IValidatedImportDataModuleState,
    columns: IMapColumnsResponse,
    parsedResult: IParsedFileResult,
  ): Promise<ILineError[]> {
    return await dataArgumentGenerator.validateArgument(parsedResult, state, columns);
  }

  private async validateColumnNames(columns: IMapColumnsResponse): Promise<string> {
    const result = await this.backendConnectionService.emitAsync<string[]>(
      DataStorageModuleName,
      true,
      new DataStorageImportColumnValidateNamesAction(columns.dtos.map((col) => col.ColumnName)),
    );
    if (result.payload.length) {
      let errorMessage = 'Following columns names already exists in a previously imported file: <br>';
      result.payload.forEach((colName) => (errorMessage += colName + '<br>'));
      return errorMessage;
    }
    return '';
  }

  private async setStartDateToXAxisCol(
    dataArgumentGenerator: DataArgumentGenerator,
    state: IValidatedImportDataModuleState,
    columns: IMapColumnsResponse,
    parsedResult: IParsedFileResult,
    presetDateTime?: number,
  ): Promise<number | undefined> {
    // set StartDate to xAxisCol
    const argColumn = columns.dtos.find((col) => col.IsXAxis);
    if (!argColumn) {
      throw new Error(`Can't find arg column!`);
    }
    const initialRowsToSkip = parsedResult.initialRowsToSkip;

    const firstRow = parsedResult.data[initialRowsToSkip] ?? parsedResult.data[0];
    const argument = dataArgumentGenerator.generateArgument(firstRow, state, columns);

    if (typeof argument.sourceArgument === 'string' && typeof argColumn.CustomFormat === 'string') {
      argColumn.StartDate = dayjs(argument.sourceArgument, argColumn.CustomFormat).unix();
    }

    return await this.importFileStartTimeService.getStartTime(argColumn, presetDateTime);
  }

  private groupConsecutiveRows(array: ILineError[], customTimeFormat?: string | null): GroupedLineError[] {
    const formatError = (err: string): string =>
      err.toString().includes(CANT_PARSE_CUSTOM_FORMAT_ERROR)
        ? `${CANT_PARSE_CUSTOM_FORMAT_ERROR} ${customTimeFormat ? '(' + customTimeFormat + ')' : ''}`
        : err;

    if (array.length === 0) {
      return [];
    }

    const result = [];
    let currentGroup = {
      error: formatError(array[0].error),
      from: array[0].line,
      to: array[0].line,
    };

    for (let i = 1; i < array.length; i++) {
      if (formatError(array[i].error) === formatError(currentGroup.error) && array[i].line === currentGroup.to + 1) {
        currentGroup.to = array[i].line;
      } else {
        result.push(currentGroup);
        currentGroup = { error: formatError(array[i].error), from: array[i].line, to: array[i].line };
      }
    }

    result.push(currentGroup);

    return result;
  }
}
