import { UnitConverter } from '../../converter.interfaces';
import { LengthUnit } from '../../../dto/unit-system.dto';
import { UsMeshConverter } from '../us-mesh/us-mesh.converter';

export class LengthConverter extends UnitConverter {
  public static symbols: Array<string> = ['', 'm', 'km', 'cm', 'mm', 'mi', 'yrd', 'ft', 'in', 'μm', 'ga', 'US Mesh'];

  public static unit = LengthUnit;

  public static fromSi(value: number, unit: LengthUnit): number {
    switch (unit) {
      case LengthUnit.Meter: {
        return value;
      }
      case LengthUnit.Kilometer: {
        return value * 1e-3;
      }
      case LengthUnit.Centimeter: {
        return value * 1e2;
      }
      case LengthUnit.Millimeter: {
        return value * 1e3;
      }
      case LengthUnit.Mile: {
        return value * 6.21371192e-4;
      }
      case LengthUnit.Yard: {
        return value * 1.0936133;
      }
      case LengthUnit.Foot: {
        return value * 3.2808399;
      }
      case LengthUnit.Inch: {
        return value * 39.3700787;
      }
      case LengthUnit.Micron: {
        return value * 1e6;
      }
      case LengthUnit.Gauge: {
        return value * 39370.07874;
      }
      case LengthUnit.US_Mesh: {
        return UsMeshConverter.MetersToUSMesh(value);
      }
      default: {
        throw new TypeError('Unknown Length Unit:' + unit);
      }
    }
  }

  public static toSi(value: number, unit: LengthUnit): number {
    switch (unit) {
      case LengthUnit.Meter: {
        return value;
      }
      case LengthUnit.Kilometer: {
        return value / 1e-3;
      }
      case LengthUnit.Centimeter: {
        return value / 1e2;
      }
      case LengthUnit.Millimeter: {
        return value / 1e3;
      }
      case LengthUnit.Mile: {
        return value / 6.21371192e-4;
      }
      case LengthUnit.Yard: {
        return value / 1.0936133;
      }
      case LengthUnit.Foot: {
        return value / 3.2808399;
      }
      case LengthUnit.Inch: {
        return value / 39.3700787;
      }
      case LengthUnit.Micron: {
        return value / 1e6;
      }
      case LengthUnit.Gauge: {
        return value / 39370.07874;
      }
      case LengthUnit.US_Mesh: {
        return UsMeshConverter.USMeshToMeters(value);
      }
      default: {
        throw new TypeError('Unknown Length Unit:' + unit);
      }
    }
  }

  public static getSymbol(unit: LengthUnit): string {
    if (unit == null) {
      return '';
    }
    return this.symbols[unit];
  }
}
