import { DictionaryWithArray, IDictionaryWithArray } from '../../../../common/state.helpers';
import { ITableState } from '../../../../common/common-grid.interfaces';
import { RheometerReading } from '../rheometer-reading/rheometer-reading';
import { Rheometer } from './rheometer';
import { RheologyType } from '../../dto/rheology/rheology.dto';
import { FluidModuleState } from '../../fluid-module.state';

export class RheometerValidation {
  public static ValidateRheometers(
    fluidState: FluidModuleState,
    validatedRheometerReading: IDictionaryWithArray<ITableState<RheometerReading>>,
  ): IDictionaryWithArray<ITableState<Rheometer>> {
    let newRheometerDict: IDictionaryWithArray<ITableState<Rheometer>> = { ...fluidState.Rheometers };

    newRheometerDict.ids.forEach((fluidId) => {
      const rheometersTableState = DictionaryWithArray.get(newRheometerDict, +fluidId);
      if (!rheometersTableState) {
        return;
      }
      const fluid = DictionaryWithArray.get(fluidState.Fluids, +fluidId);
      if (!fluid) {
        return;
      }
      if (fluid.RheologyType !== RheologyType.Rheometer) {
        newRheometerDict = DictionaryWithArray.upsertById(
          newRheometerDict,
          {
            ...rheometersTableState,
            isValid: true,
          },
          fluidId,
        );
        return;
      }

      let isValid = true;
      const newRows = rheometersTableState.rows.map((rheometerRow) => {
        if (rheometerRow.rowType !== 'data') {
          return rheometerRow;
        }
        const isRheometerValid = DictionaryWithArray.get(validatedRheometerReading, +rheometerRow.rowData.Id)?.isValid ?? true;
        isValid = isValid && isRheometerValid;
        return {
          ...rheometerRow,
          isValid: isRheometerValid,
        };
      });

      const newRheometerTableState: ITableState<Rheometer> = { ...rheometersTableState, rows: newRows, isValid };
      newRheometerDict = DictionaryWithArray.upsertById(newRheometerDict, newRheometerTableState, fluidId);
    });

    return newRheometerDict;
  }
}
