import { WsAction } from '../../ws.action';
import { ReferenceVariableCalculatorInputs } from './dto/reference-variable-calculator-inputs.dto';

export const ReferenceVariablesModuleName = 'ReferenceVariablesModule';

export enum ReferenceVariablesModuleActionTypes {
  Calculate = '[ReferenceVariablesModuleAction] Calculate Update',
}

export class CalculateReferenceVariablesAction extends WsAction {
  public override readonly type = ReferenceVariablesModuleActionTypes.Calculate;

  constructor(
    public override payload: ReferenceVariableCalculatorInputs,
    public scenarioId: number,
  ) {
    super();
  }
}

export type ReferenceVariablesModuleActions = CalculateReferenceVariablesAction;
