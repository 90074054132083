import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { RouterHelperService } from '../../../../../shared/services/router-helper.service';
import { ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';
import { getGravelsSelectData } from '../../../../../+store/gravel/gravel.selectors';
import { Gravel } from '@dunefront/common/modules/gravel/model/gravel';

@Component({
  selector: 'app-gravel-header',
  templateUrl: './gravel-header.component.html',
  styleUrls: ['./gravel-header.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GravelHeaderComponent {
  public data$ = this.store.select(getGravelsSelectData);

  @Input() public isReadOnly = false;

  constructor(
    private store: Store,
    private routerHelperService: RouterHelperService,
  ) {}

  public async changeSelectedGravel(event: ObjectChangeProp<Gravel>): Promise<void> {
    const gravelId = event.value as number;
    await this.routerHelperService.navigateToGravel(gravelId);
  }
}
