import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SettlingCalculatorModuleFeatureName } from './settling-calculator.reducer';
import { getFluidAndRheologies, getValidatedFluidModuleState } from '../../fluid/fluid.selectors';
import { FluidType } from '@dunefront/common/modules/fluid/dto/fluid.dto';
import { RheologyCalculations } from '@dunefront/common/modules/fluid/dto/rheology/rheology.calculations';
import { getRowsForCalculations } from '@dunefront/common/common/common-grid.interfaces';
import { SettlingCalculatorModuleState, ValidatedSettilingCalculatorModuleState } from './settling-calculator-module.state';
import { SettlingCalculatorValidation } from './model/settling-calculator.validation';
import { DictionaryWithArray } from '@dunefront/common/common/state.helpers';
import { getValidatedSettings } from '../../settings/validated-settings.selectors';
import { getCurrentScenarioId } from '../../scenario/scenario.selectors';
import { getValidatedGravelModuleState } from '../../gravel/gravel.selectors';

const getSettlingCalculatorModuleState = createFeatureSelector<SettlingCalculatorModuleState>(SettlingCalculatorModuleFeatureName);

export const getValidatedSettlingCalculatorModuleState = createSelector(
  getSettlingCalculatorModuleState,
  getValidatedFluidModuleState,
  getValidatedGravelModuleState,
  (...[state, validatedFluidState, validatedGravelState]) =>
    SettlingCalculatorValidation.validate(state, {
      fluids: validatedFluidState.Fluids.dict,
      gravels: validatedGravelState.Gravels.dict,
    }),
);

export const getSettlingCalculatorWithStaticVisc = createSelector(
  getValidatedSettlingCalculatorModuleState,
  getFluidAndRheologies,
  getValidatedSettings,
  (...[state, fluidData, settings]): ISettlingAndStaticViscosity => {
    const fluid = DictionaryWithArray.get(fluidData.fluid, state.SettlingCalculator.FluidId);
    const rheologies = DictionaryWithArray.get(fluidData.rheologies, state.SettlingCalculator.FluidId);

    if (!fluid || !rheologies || fluid.Type !== FluidType.Newtonian) {
      return {
        ...state,
        isNewtonian: false,
        viscosityAtTemperature: 0,
      };
    }
    const temperature = state.SettlingCalculator.Temperature;
    const shearRate = settings.ShearRate;

    return {
      ...state,
      isNewtonian: true,
      viscosityAtTemperature: RheologyCalculations.getViscosityAtTemperature(
        getRowsForCalculations(rheologies.rows).filter((rheology) => rheology.Id !== -1),
        temperature,
        shearRate,
      ),
    };
  },
);

export const getHasSettlingCalculatorResults = createSelector(
  getValidatedSettlingCalculatorModuleState,
  getCurrentScenarioId,
  (state, scenarioId) => {
    return state.SettlingCalculatorResult.some((res) => res.ScenarioId === scenarioId);
  },
);

export interface ISettlingAndStaticViscosity extends ValidatedSettilingCalculatorModuleState {
  isNewtonian: boolean;
  viscosityAtTemperature: number;
}
