import { IReportTableColumnConfig } from '../common-report-data-helpers';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { ColumnType } from '../../../../../components/grid/grid.interfaces';
import { ToolJoint } from '@dunefront/common/modules/pipes/tool-joint/tool-joint';
import { ReportServiceTool, ReportWashPipeAccessory } from '../../doc/sections/completion-report-generator.helper';

export const workstringProperties: IReportTableColumnConfig<ToolJoint>[] = [
  { colId: 'Code', headerText: 'Code', unitSystem: UnitSystem.None, type: ColumnType.number },
  { colId: 'Name', headerText: 'Name', unitSystem: UnitSystem.None, type: ColumnType.text },

  {
    colId: 'SingleJointLength',
    headerText: 'Single Joint Length',
    reportingHeaderName: 'Joint Length',
    unitSystem: UnitSystem.Long_Length,
    type: ColumnType.number,
  },
  {
    colId: 'ToolJointLength',
    headerText: 'Tool Joint Length',
    unitSystem: UnitSystem.Long_Length,
    type: ColumnType.number,
  },
  {
    colId: 'InnerDiameter',
    headerText: 'Tool Joint ID',
    unitSystem: UnitSystem.Diameter,
    type: ColumnType.number,
  },
  {
    colId: 'OuterDiameter',
    headerText: 'Tool Joint OD',
    unitSystem: UnitSystem.Diameter,
    type: ColumnType.number,
  },
];

export const bypassAnnulusesProperties: IReportTableColumnConfig<ReportServiceTool>[] = [
  { colId: 'FlowType', headerText: 'Flow Type', unitSystem: UnitSystem.None, type: ColumnType.text },
  {
    colId: 'NoOfPorts',
    headerText: 'No. of ports',
    unitSystem: UnitSystem.None,
    type: ColumnType.number,
    decimalPlaces: 0,
  },
  { colId: 'PortDiameter', headerText: 'Port Diameter', unitSystem: UnitSystem.Diameter, type: ColumnType.number },
  { colId: 'PortLength', headerText: 'Port Length', unitSystem: UnitSystem.Short_Length, type: ColumnType.number },
  { colId: 'AnnulusOD', headerText: 'Annulus OD', unitSystem: UnitSystem.Diameter, type: ColumnType.number },
  { colId: 'AnnulusID', headerText: 'Annulus ID', unitSystem: UnitSystem.Diameter, type: ColumnType.number },
  {
    colId: 'AnnulusLength',
    headerText: 'Annulus Length',
    unitSystem: UnitSystem.Short_Length,
    type: ColumnType.number,
  },
];

export const washPipeAccessoriesProperties: IReportTableColumnConfig<ReportWashPipeAccessory>[] = [
  { colId: 'Code', headerText: 'Code', unitSystem: UnitSystem.None, type: ColumnType.number },
  { colId: 'Name', headerText: 'Name', unitSystem: UnitSystem.None, type: ColumnType.text },
  {
    colId: 'ActivationPressure',
    headerText: 'Activation Pressure',
    unitSystem: UnitSystem.Pressure,
    type: ColumnType.number,
  },
  {
    colId: 'NoOfPorts',
    headerText: 'No. of ports',
    unitSystem: UnitSystem.None,
    type: ColumnType.number,
    decimalPlaces: 0,
  },
  { colId: 'PortDiameter', headerText: 'Port Diameter', unitSystem: UnitSystem.Diameter, type: ColumnType.number },
  { colId: 'PortLength', headerText: 'Port Length', unitSystem: UnitSystem.Short_Length, type: ColumnType.number },
];
