import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DbDependentComponent } from '../../../db-connection/db-dependent.component';
import { detachLicenseAction } from '../../../../+store/licensing/licensing.actions';
import { LicenseProduct } from '@dunefront/common/modules/licensing/licensing.interfaces';
import { DataSourceValue, PrimitiveChangeValue } from '@dunefront/common/common/common-state.interfaces';

@Component({
  selector: 'app-licensing-detach-modal-component',
  templateUrl: './licensing-detach.modal.component.html',
  styleUrls: ['./licensing-detach.modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LicensingDetachModalComponent extends DbDependentComponent implements OnInit {
  constructor(
    store: Store,
    cdRef: ChangeDetectorRef,
    public ref: DynamicDialogRef,
    config: DynamicDialogConfig<LicensingDetachModalData>,
  ) {
    super(store, cdRef);
    this.data = config.data;
    this.detachMaxDays = config.data?.product.detachMaxDays ?? 0;
    this.selectedDetachDays = this.detachMaxDays;
  }

  public validationError = '';
  public detachMaxDays = 0;
  public selectedDetachDays = 0;
  public get daysInputLabel(): string {
    return `Number of days (max ${this.detachMaxDays}):`;
  }

  public readonly data: LicensingDetachModalData | undefined;

  public btnDetachClick(): void {
    if (!this.data || this.validationError.length > 0) {
      return;
    }
    this.store.dispatch(
      detachLicenseAction({
        licenseId: this.data.licenseId,
        productId: this.data.product.id,
        duration: this.daysToSeconds(this.selectedDetachDays),
      }),
    );
    this.ref.close();
  }

  private daysToSeconds(days: number): number {
    return days * 24 * 3600;
  }

  public daysValueChanged(value: PrimitiveChangeValue<DataSourceValue<{ selectedDetachDays: number }>>): void {
    this.selectedDetachDays = value.value;
    this.validate();
  }

  private validate(): void {
    if (this.selectedDetachDays < 1 || this.selectedDetachDays > this.detachMaxDays) {
      this.validationError = `Number of days must be between 1 and ${this.detachMaxDays}`;
    } else {
      this.validationError = '';
    }
  }
}

export interface LicensingDetachModalData {
  licenseId: string;
  product: LicenseProduct;
}
