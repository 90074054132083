export enum WarningMessageType {
  Info = 0,
  Warning = 1,
}

export interface WarningMessageDto {
  Id: number;
  ScenarioId: number;
  RangeId: number;
  SimulationTime: number;
  SimulationVolume: number;
  MeasuredDepth: number;
  MessageType: WarningMessageType;
  MessageText: string;
}
