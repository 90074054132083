<app-generic-modal [title]="modalTitle" [modalButtonsConfigs]="modalButtonsConfigs">
  <div class="grid-form-container">
    <app-form-input
      appUnwrap
      label="Annotation text"
      [isUiLockable]="false"
      [source]="validatedAnnotation"
      dataCy="chart-annotation-dlg--name-input"
      [key]="'text'"
      [isTextInput]="true"
      [unitType]="UnitType.None"
      cssClass="full-width"
      [autoFocus]="true"
      [isStringComparisonStrict]="true"
      (valueChanged)="emitValue($event)"
    >
    </app-form-input>

    <app-radio-group
      [isUiLockable]="false"
      [items]="[
        { value: false, text: 'Default' },
        { value: true, text: 'Custom' }
      ]"
      [value]="isOverrideStyle"
      (primitiveValueChanged)="onOverrideStyleChanged($event.value)"
      class="grid-two-one"
      id="customize-annotation-style-radio"
      dataCy="customize-annotation-style-radio"
      layout="horizontal"
    >
    </app-radio-group>
  </div>
  <app-chart-annotation-details *ngIf="annotation.style" [annotationStyle]="annotation.style" (valueChanged)="emitStyleValue($event)">
  </app-chart-annotation-details>
</app-generic-modal>
