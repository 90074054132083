import { LoadSettlingCalculatorActionResponse } from '@dunefront/common/modules/calculators/settling-calculator-module.action';
import { CrudResponse } from '@dunefront/common/modules/common.actions';
import { SettlingCalculatorInitialState, SettlingCalculatorModuleState } from './settling-calculator-module.state';
import { DeleteResultsFilter, ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import { ActionResponse } from '@dunefront/common/response-ws.action';

export class SettlingCalculatorReducerHelper {
  public static onLoadScenarioDataSuccessAction(
    state: SettlingCalculatorModuleState,
    response: ActionResponse<LoadSettlingCalculatorActionResponse>,
  ): SettlingCalculatorModuleState {
    if (response.status !== 'ok' || !response.payload) {
      return SettlingCalculatorInitialState;
    }
    const { settlingCalculatorDto, settlingCalculatorResultDto } = response.payload;
    return {
      ...state,
      SettlingCalculator: settlingCalculatorDto,
      SettlingCalculatorResult: settlingCalculatorResultDto,
      isLoaded: true,
    };
  }

  public static onUpdateSettlingCalculatorSuccess(
    state: SettlingCalculatorModuleState,
    response: CrudResponse,
  ): SettlingCalculatorModuleState {
    if (!response.affectedRows.settlingCalculator) {
      return state;
    }
    return {
      ...state,
      SettlingCalculator: response.affectedRows.settlingCalculator,
    };
  }

  public static onUpdateSettlingCalculatorResultSuccess(
    state: SettlingCalculatorModuleState,
    response: CrudResponse,
  ): SettlingCalculatorModuleState {
    if (!response.affectedRows.settlingCalculatorResult) {
      return state;
    }
    return {
      ...state,
      SettlingCalculatorResult: response.affectedRows.settlingCalculatorResult,
    };
  }

  public static onRemoveDataResultsFromStore(
    state: SettlingCalculatorModuleState,
    deleteResultsFilter: DeleteResultsFilter,
  ): SettlingCalculatorModuleState {
    if (deleteResultsFilter.moduleTypes != null && !deleteResultsFilter.moduleTypes.includes(ModuleType.Settling_Calc)) {
      return state;
    }
    return {
      ...state,
      SettlingCalculatorResult: state.SettlingCalculatorResult.filter(
        (result) => deleteResultsFilter.scenarioIds != null && !deleteResultsFilter.scenarioIds.includes(result.ScenarioId),
      ),
    };
  }
}
