import { UnitConverterHelper } from '@dunefront/common/unit-converters/unit.converter.helper';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { ISelectItem } from '@dunefront/common/common/select.helpers';

export class UnitHelpers {
  public static getUnitSymbolSelectItems(unitType: UnitSystem | -1): ISelectItem<UnitSystem>[] {
    return this.convertUnitSymbolsToSelectArray(UnitConverterHelper.getUnitConverter(unitType).symbols);
  }

  public static getUnitSystemText(unitType: UnitSystem, unitSymbol: number, nbspIfEmpty = true): string {
    if (unitType === UnitSystem.None) {
      return nbspIfEmpty ? '&nbsp;' : '';
    }
    return UnitConverterHelper.getUnitConverter(unitType).getSymbol(unitSymbol);
  }

  private static convertUnitSymbolsToSelectArray(converterUnits: string[]): ISelectItem<number>[] {
    const selectItems: ISelectItem<number>[] = [];
    converterUnits.forEach((unit, index) => {
      if (unit !== '') {
        selectItems.push({ value: index, text: unit });
      }
    });
    return selectItems;
  }
}
