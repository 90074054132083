import { DocumentGeneratorHelper } from '../document-generator.helper';
import { HeadingLevel, Paragraph, Table } from 'docx';
import { IUnitSystemDto } from '@dunefront/common/dto/unit-system.dto';
import { Store } from '@ngrx/store';
import { getValidatedTrendAnalysisRange } from '../../../../../../+store/trend-analysis/trend-analysis-page.selectors';
import { firstValueFrom } from 'rxjs';
import { DocumentTableGeneratorHelper } from '../document-table-generator.helper';
import { ReportScenarioImages } from './chart-report-generator.helper';
import { IValidatedScenarioRangeProperties } from '@dunefront/common/modules/range/model/range';
import { CommonReportDataHelpers } from '../../common/common-report-data-helpers';
import { DocxImageGenerator } from '../docx-image-generator';

export class TrendAnalysisReportGeneratorHelper {
  public static async createTrendAnalysisReport(
    store: Store,
    currentUnitSystem: IUnitSystemDto,
    images: ReportScenarioImages,
    docxImageGenerator: DocxImageGenerator,
    rangeId: number,
    rangeName: string,
  ): Promise<Array<Paragraph | Table>> {
    const trendAnalysisRange = await firstValueFrom(store.select(getValidatedTrendAnalysisRange));
    const rangeImages = images.rangeImages ? images.rangeImages[rangeId] : null;

    const rows: Array<Paragraph | Table> = [
      DocumentGeneratorHelper.createParagraphHeader(
        DocumentGeneratorHelper.addRangeNameToTitle(`Trend Analysis Inputs`, rangeName),
        HeadingLevel.HEADING_2,
        1,
      ),
      this.createTrendAnalysisInputs(trendAnalysisRange, currentUnitSystem),
    ];

    if (rangeImages?.trendAnalysisInputs) {
      rows.push(
        ...[
          DocumentGeneratorHelper.insertLineBreaks(1),
          ...(await DocumentGeneratorHelper.drawGenericImage(rangeImages.trendAnalysisInputs, docxImageGenerator)),
        ],
      );
    }

    return rows;
  }

  private static createTrendAnalysisInputs(
    trendAnalysisRange: IValidatedScenarioRangeProperties,
    currentUnitSystem: IUnitSystemDto,
  ): Table {
    const tableData = CommonReportDataHelpers.createTrendAnalysisInputsTableData(trendAnalysisRange, currentUnitSystem);

    return DocumentTableGeneratorHelper.createGenericKeyValueTableFromTableData(tableData, currentUnitSystem);
  }
}
