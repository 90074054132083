import { Validation } from '../../../common/validation';

export interface PumpingScheduleDto {
  ScenarioId: number;
  Id: number;
  SortOrder: number;
  FluidId: number | null;
  GravelId: number | null;
  GravelConcentration: number;
  ChokePressure: number;
  PumpRate: number;
  ReturnRate: number;
  StageVolume: number;
  Name: string;
  RangeId: number;
}

export interface PumpingScheduleWithCalculatedValues extends PumpingScheduleDto {
  StageTime: number;
  ShutDownTime: number;
  CumulativeVolume: number;
  CumulativeTime: number;
}

export class PumpingScheduleConverter {
  public static decodeToDto(encoded: string, scenarioId: number, rangeId: number): PumpingScheduleDto[] {
    const arr = JSON.parse(encoded) as [][];
    return arr.map((item: any[7], index) => ({
      FluidId: item[0] ?? null,
      GravelId: +item[1] ?? null,
      GravelConcentration: +item[2],
      PumpRate: +item[3],
      ReturnRate: +item[4],
      StageVolume: +item[5],
      Name: item[6] + '',
      Id: -1,
      ScenarioId: +scenarioId,
      SortOrder: index,
      RangeId: rangeId,
      ChokePressure: +item[13],
    }));
  }

  public static encodeToDto(schedule: PumpingScheduleDto[]): any[][] {
    return schedule.map((s) => [
      s.FluidId,
      s.GravelId,
      s.GravelConcentration,
      s.PumpRate,
      s.ReturnRate,
      s.StageVolume,
      s.Name,
      s.Id,
      s.ScenarioId,
      s.SortOrder,
      s.RangeId,
    ]);
  }
}

export class PumpingScheduleDtoCalculations {
  public static calculateStageTime(dto: PumpingScheduleDto): number {
    return Validation.UICheckNumberValidity(dto.StageVolume / dto.PumpRate);
  }

  public static toPumpingScheduleWithCalculatedValues(dto: PumpingScheduleDto): PumpingScheduleWithCalculatedValues {
    return {
      ...dto,
      StageTime: this.calculateStageTime(dto),
      ShutDownTime: 0,
      CumulativeVolume: 0,
      CumulativeTime: 0,
    };
  }

  public static applyPumpingScheduleCalculatedValues(dtos: PumpingScheduleDto[]): PumpingScheduleWithCalculatedValues[] {
    const rows = dtos.map((dto) => this.toPumpingScheduleWithCalculatedValues(dto));

    for (let index = 0; index < rows.length; index++) {
      const row = rows[index];
      if (index === 0) {
        row.CumulativeTime = row.StageTime;
        row.CumulativeVolume = row.StageVolume;
      } else {
        row.CumulativeTime = rows[index - 1].CumulativeTime + row.StageTime;
        row.CumulativeVolume = rows[index - 1].CumulativeVolume + row.StageVolume;
      }
    }
    return rows;
  }
}
