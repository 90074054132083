import { LowerCompletionPipeDto, LowerCompletionPipeDtoWithICDPorts } from '../../../dto/lower-completion-pipe.dto';
import { PipeType } from '../../../dto/pipe.dto';
import { createTableRow, GridRowType, ITableRow } from '../../../common/common-grid.interfaces';
import { BasePipeFactory } from '../base.pipe.factory';
import { Pipe } from '../pipe';
import { isScreenPipe, ScreenPipe, ScreenPipeFactory } from './pipes/screen-pipe';
import { ShuntedBlankPipe, ShuntedBlankPipeFactory } from './pipes/shunted-blank-pipe';
import { isShuntedScreenPipe, ShuntedScreenPipe, ShuntedScreenPipeFactory } from './pipes/shunted-screen-pipe';
import { IcdScreenPipe, IcdScreenPipeFactory, isICDScreenPipe } from './pipes/icd-screen-pipe';
import { BypassPorts } from '../bypass-ports';

export class LowerCompletionPipesFactory {
  public static createFromDto(dto: LowerCompletionPipeDto): Pipe {
    switch (dto.PipeType) {
      case PipeType.Shunted_Blank_Pipe:
        return ShuntedBlankPipeFactory.createFromPermanentCompletion(dto);
      case PipeType.Screen:
        return ScreenPipeFactory.createDto(dto);
      case PipeType.Shunted_Screen:
        return ShuntedScreenPipeFactory.createFromDto(dto);
      case PipeType.ICD_Screen:
        return IcdScreenPipeFactory.createFromPermanentCompletion(dto, []);
      default:
        return BasePipeFactory.createFromDto(dto);
    }
  }

  public static createRowListFromDtos(pipeDtoArray: LowerCompletionPipeDto[], scenarioId: number): ITableRow<Pipe>[] {
    const sortedPipeDtoArray = [...pipeDtoArray].sort((a, b) => a.SortOrder - b.SortOrder);
    const rows = sortedPipeDtoArray.map((dto, index) => this.createElement(dto, index));
    const insertRow = this.createEmptyRow(scenarioId, rows.length, false, 'insert-row');
    return [...rows, insertRow];
  }

  public static createElement(
    pipeDto: LowerCompletionPipeDto,
    index: number,
    isEditingDisabled = false,
    rowType: GridRowType = 'data',
  ): ITableRow<Pipe> {
    const pipe = this.createFromDto(pipeDto);
    return createTableRow<Pipe>(pipe, rowType, index, isEditingDisabled);
  }

  public static toDtoWithIcdPorts(pipe: Pipe): LowerCompletionPipeDtoWithICDPorts {
    const icdPorts: BypassPorts[] = isICDScreenPipe(pipe) ? pipe.ICDPorts : [];
    const dto: LowerCompletionPipeDtoWithICDPorts = { ...this.toDto(pipe), ICDPorts: icdPorts };
    return dto;
  }

  public static toDto(pipe: Pipe): LowerCompletionPipeDto {
    const dto: LowerCompletionPipeDto = {
      ...LowerCompletionPipesFactory.getEmptyDto(pipe.ScenarioId),
      Weight: pipe.Weight,
      TopMD: pipe.TopMD,
      BottomMD: pipe.BottomMD,
      Id: pipe.Id,
      OuterDiameter: pipe.OuterDiameter,
      PipeType: pipe.PipeType,
      ScenarioId: pipe.ScenarioId,
      SortOrder: pipe.SortOrder,
      InnerDiameter: pipe.InnerDiameter,
      Description: pipe.Description,
    };
    if (isScreenPipe(pipe) || isShuntedScreenPipe(pipe) || isICDScreenPipe(pipe)) {
      dto.JointLength = pipe.JointLength ?? 0;
      dto.HandlingLength = pipe.HandlingLength ?? 0;
      dto.BasePipeOD = pipe.BasepipeOuterDiameter ?? 0;
      dto.FilterID = pipe.FilterInnerDiameter ?? 0;
      if (isICDScreenPipe(pipe)) {
        dto.ICDType = pipe.ICDType;

        dto.NoOf0_075inPorts = pipe.NoOf0_075inPorts;
        dto.NoOf0_100inPorts = pipe.NoOf0_100inPorts;
        dto.NoOf0_125inPorts = pipe.NoOf0_125inPorts;
        dto.NoOf0_150inPorts = pipe.NoOf0_150inPorts;

        dto.A_Coefficient_Production = pipe.A_Coefficient_Production;
        dto.B_Coefficient_Production = pipe.B_Coefficient_Production;
        dto.C_Coefficient_Production = pipe.C_Coefficient_Production;
        dto.A_Coefficient_Injection = pipe.A_Coefficient_Injection;
        dto.B_Coefficient_Injection = pipe.B_Coefficient_Injection;
        dto.C_Coefficient_Injection = pipe.C_Coefficient_Injection;
      }
    }
    return dto;
  }

  public static createEmptyRow(scenarioId: number, index: number, isEditingDisabled: boolean, rowType: GridRowType): ITableRow<Pipe> {
    const pipe = this.createEmpty(scenarioId);
    return createTableRow(pipe, rowType, index, isEditingDisabled);
  }

  public static getEmptyDto(scenarioId: number): LowerCompletionPipeDto {
    return {
      A_Coefficient_Injection: 0,
      A_Coefficient_Production: 0,
      B_Coefficient_Injection: 0,
      B_Coefficient_Production: 0,
      BasePipeOD: 0,
      Weight: 0,
      BottomMD: 0,
      C_Coefficient_Injection: 0,
      C_Coefficient_Production: 0,
      FilterID: 0,
      HandlingLength: 0,
      ICDType: 0,
      Id: -1,
      InnerDiameter: 0,
      JointLength: 0,
      NoOf0_075inPorts: 0,
      NoOf0_100inPorts: 0,
      NoOf0_125inPorts: 0,
      NoOf0_150inPorts: 0,
      OuterDiameter: 0,
      PipeType: PipeType.None,
      ScenarioId: scenarioId,
      SortOrder: 0,
      TopMD: 0,
      Description: '',
    };
  }

  public static createEmpty(scenarioId: number): Pipe {
    return this.createFromDto(this.getEmptyDto(scenarioId));
  }
}

export type LowerCompletionPipe = Pipe | IcdScreenPipe | ScreenPipe | ShuntedBlankPipe | ShuntedScreenPipe;
