import { Routes } from '@angular/router';
import { ResultsComponent } from './results.component';
import {
  RouteModuleResults,
  RouteSimulateAnimation,
  RouteSummary,
  RouteWellVisualization
} from '../simulate-evaluate-routes-names';
import { SimulationDispAnimationComponent } from './simulation-disp/simulation-disp-animation.component';
import { WellVisualizationComponent } from './well-visualization/well-visualization.component';
import { SummaryComponent } from './summary/summary.component'

export const resultsSimulateDispRoutes: Routes = [
  {
    path: RouteModuleResults,
    component: ResultsComponent,
    data: {
      breadcrumb: 'Results',
    },
    children: [
      {
        path: RouteSimulateAnimation,
        component: SimulationDispAnimationComponent,
        data: {
          breadcrumb: 'Animation',
        },
      },
      {
        path: RouteWellVisualization,
        component: WellVisualizationComponent,
        data: {
          breadcrumb: 'Well Visualization',
        },
      },
      {
        path: RouteSummary,
        component: SummaryComponent,
        data: {
          breadcrumb: 'Summary',
        },
      },
      {
        path: '',
        redirectTo: RouteSimulateAnimation,
        pathMatch: 'full',
      },
    ],
  },
];
