import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { chartAutoSizeAction, chartResetZoomAction, chartZoomModeAction, ResetZoomMode } from '../../../../../+store/ui/ui.actions';
import { ChartZoomProps, getChartZoomProps } from '../../../../../+store/menu-selectors/chart/menu-chart.selector';
import { PanelButtonComponent } from '../../panel.button.component';
import { IRadioItem } from '@dunefront/common/common/radio.helpers';
import { ChartZoomMode } from '@dunefront/common/modules/reporting/reporting.settings';

@Component({
  selector: 'app-zoom',
  templateUrl: './zoom.component.html',
  styleUrls: ['./zoom.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ZoomComponent extends PanelButtonComponent implements OnDestroy, AfterViewInit {
  public chartZoomProps: ChartZoomProps | undefined;

  public zoomModeRadio: IRadioItem<ChartZoomMode>[] = [
    { value: 'x', text: 'X' },
    { value: 'y', text: 'Y' },
    { value: 'xy', text: 'XY' },
  ];

  constructor(
    protected override store: Store,
    protected cdRef: ChangeDetectorRef,
  ) {
    super(store);
  }

  public resetZoomClicked(): void {
    this.store.dispatch(chartResetZoomAction({ mode: ResetZoomMode.ALL }));
  }

  public autoSizeClicked(): void {
    this.store.dispatch(chartAutoSizeAction());
  }

  public zoomModeChanged($event: ChartZoomMode): void {
    this.store.dispatch(chartZoomModeAction({ chartZoomMode: $event }));
  }

  public ngAfterViewInit(): void {
    this.subscription.add(
      this.store.select(getChartZoomProps).subscribe((chartZoomProps) => {
        this.chartZoomProps = chartZoomProps;
        this.cdRef.detectChanges();
      }),
    );
  }
}
