import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import {
  IAnalysisDataComponentSelectedValues,
  ValidatedAnalysisDataComponentState,
} from '@dunefront/common/modules/pumping/pumping-module.state';
import { updateAnalysisDataComponentProperties } from '../../../+store/pumping/pumping.actions';
import { getDataAnalysisComponentSelectedValues } from '../../../+store/pumping/selectors/evaluate-pumping-schedule-analysis-data-component.selectors';
import { initialValidatedScenarioRangeProperties } from '@dunefront/common/modules/range/model/range';
import { ScenarioConstants } from '@dunefront/common/modules/scenario/scenario.dto';
import { RangeConstants } from '@dunefront/common/dto/range.dto';

@Component({
  selector: 'app-analysis-data',
  templateUrl: './analysis-data.component.html',
  styleUrls: ['./analysis-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnalysisDataComponent implements OnDestroy, OnInit {
  @Input() public inputWidth = 200;
  @Input() public renderAs2Rows = false;

  public state: ValidatedAnalysisDataComponentState = {
    pumpRateItems: [],
    pumpPressureItems: [],
    returnRateItems: [],
    gravelConcItems: [],
    currentScenarioRangeProperties: initialValidatedScenarioRangeProperties(ScenarioConstants.EmptyScenarioId, RangeConstants.EmptyRangeId),
    error: {},
    isValid: true,
    isLoaded: false,
  };
  private subscription = new Subscription();

  public selectedValues: IAnalysisDataComponentSelectedValues = {};

  constructor(
    private store: Store,
    private cdRef: ChangeDetectorRef,
  ) {
    this.subscription.add(
      store.select(getDataAnalysisComponentSelectedValues).subscribe((state) => {
        this.state = state.state;
        this.selectedValues = state.selectedValues;
        this.cdRef.markForCheck();
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public onPumpRateChange(selectedPumpRateId: number): void {
    this.updateRangePumpRateAndPressureColumnName({ ...this.selectedValues, selectedPumpRateId });
  }

  public onPumpPressureChange(selectedPumpPressureId: number): void {
    this.updateRangePumpRateAndPressureColumnName({ ...this.selectedValues, selectedPumpPressureId });
  }

  public onReturnRateChange(selectedReturnRateId: number): void {
    this.updateRangePumpRateAndPressureColumnName({ ...this.selectedValues, selectedReturnRateId });
  }

  public onGravelConcChange(selectedGravelConcId: number): void {
    this.updateRangePumpRateAndPressureColumnName({ ...this.selectedValues, selectedGravelConcId });
  }

  private updateRangePumpRateAndPressureColumnName(selectedValues: IAnalysisDataComponentSelectedValues): void {
    this.store.dispatch(updateAnalysisDataComponentProperties({ selectedValues, isUndoEnabled: true }));
  }

  public get getIsPumpRateEnabled(): boolean {
    return this.state.pumpRateItems.length > 1;
  }

  public get getIsPumpPressureEnabled(): boolean {
    return this.state.pumpPressureItems.length > 1;
  }

  public get getIsReturnRateEnabled(): boolean {
    return this.state.returnRateItems.length > 1;
  }

  public get getIsGravelConcEnabled(): boolean {
    return this.state.gravelConcItems.length > 1;
  }

  public ngOnInit(): void {
    this.updateRangePumpRateAndPressureColumnName(this.selectedValues);
  }
}
