import { SummaryDto } from '../../dto/summary.dto';
import { WarningMessageDto } from '../../dto/warning-message.dto';
import { WsAction } from '../../ws.action';
import { ResultsSourceKey } from '../reporting/reporting-module.actions';

export const SummaryModuleName = 'SummaryModule';

export enum SummaryModuleActionTypes {
  Load = '[SummaryModule] Load',
}

export interface LoadSummaryActionResponse {
  summaryDto: SummaryDto | undefined;
  warningMessagesDto: WarningMessageDto[];
}

export class LoadSummaryAction extends WsAction {
  public override readonly type = SummaryModuleActionTypes.Load;

  constructor(public src: ResultsSourceKey) {
    super();
  }
}

export type SummaryModuleActions = LoadSummaryAction;
