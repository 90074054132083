<div
  class="custom-checkbox"
  [class.highlight]="highlight"
  [class.enable-label-ellipsis]="enableTextOverflowEllipsis"
  [class.error]="isInErrorState"
  [tooltipStyleClass]="tooltipStyle"
  [tooltipDisabled]="!tooltipMessage"
  [pTooltip]="tooltipMessage + ''"
  tooltipPosition="top"
  positionStyle="absolute"
  tooltipStyleClass="error-tooltip"
>
  <p-checkbox
          [inputId]="'checkbox-' + id + label"
          [ngModel]="value"
          [disabled]="isDisabled"
          [binary]="true"
          [attr.data-cy]="dataCy"
          (onChange)="onCheckboxChange($event)"
  ></p-checkbox>
  
  <label [title]="label" *ngIf="showLabel" [ngClass]="{'error': error, 'disabled': isDisabled}" [for]="'checkbox-' + id + label"
    ><span [style.width.px]="labelWidth">{{ label }}</span></label
  >
</div>
