import { Chart, ChartEvent } from 'chart.js';
import { DrawingAnnotation } from './annotation';
import { getState, removeState } from './state';
import { drawAnnotations } from './drawing';
import { annotationToDrawingAnnotation, isOverAnnotation } from './helpers';
import { addHandlers, annotationUnderPoint, removeHandlers } from './handlers';
import { DuneFrontAnnotationOptions, DuneFrontAnnotationPlugin } from './types';
import { getPointFromEvent } from '../plugins-common-helpers';

export const DuneFrontAnnotationId = 'DuneFrontAnnotation';

const eventTypesToDiscard = ['mousedown', 'mousemove', 'mouseup', 'click'];

export const duneFrontAnnotationPlugin: DuneFrontAnnotationPlugin = {
  id: DuneFrontAnnotationId,

  // region Plugin Lifecycle Hooks

  afterInit(chart: Chart) {
    addHandlers(chart);
  },

  beforeUpdate(chart: Chart): boolean | void {
    const state = getState(chart);

    const pluginOptions: DuneFrontAnnotationOptions = (chart.options.plugins as any)?.[DuneFrontAnnotationId];
    if (pluginOptions != null) {
      state.options = { ...pluginOptions };
    }
  },

  afterUpdate(chart: Chart) {
    const state = getState(chart);
    state.annotations = state.options.annotations
      .map((a) => annotationToDrawingAnnotation(a, chart))
      .filter((item): item is DrawingAnnotation => item != null);
  },

  afterDraw(chart: Chart) {
    drawAnnotations(chart);
  },

  beforeEvent(chart: Chart, args: { event: ChartEvent; replay: boolean; cancelable: true }): boolean | void {
    const state = getState(chart);
    if (state.options.readonly) {
      return true;
    }

    const { type, x, y } = args.event;

    // when there is annotation at
    if (eventTypesToDiscard.includes(type) && x != null && y != null) {
      return annotationUnderPoint(chart, { x, y }) == null;
    }
  },

  afterDestroy(chart: Chart) {
    removeHandlers(chart);
    removeState(chart);
  },

  // endregion

  // region Annotation Plugin specific methods

  willPluginHandleDoubleClick(chart: Chart, event: MouseEvent): boolean {
    const state = getState(chart);
    if (state.options.readonly) {
      return false;
    }

    const point = getPointFromEvent(event);

    for (const annotation of state.annotations) {
      if (isOverAnnotation(point, annotation)) {
        return true;
      }
    }

    return false;
  },

  getSelectedAnnotationId(chart: Chart): number | null {
    const state = getState(chart);
    return state.selectedAnnotation ? state.selectedAnnotation.id : null;
  },

  unSelectAnnotation(chart: Chart): void {
    const state = getState(chart);
    state.selectedAnnotation = undefined;
    chart.update();
  },
  // endregion
};
