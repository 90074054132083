<app-page-panel header="Nozzle Properties">
  <div class="grid-form-container" *ngIf="shuntTube">
    <app-check-box
      [source]="shuntTube.NozzleProperties"
      [key]="'IsNozzleLeakoff'"
      class="grid-two-one"
      (valueChanged)="onValueChange($event)"
      label="Calculate Nozzle Leakoff"
      dataCy="calculate-nozzle-leakoff"
    >
    </app-check-box>
    <app-form-input
      [disabled]="!shuntTube.NozzleProperties.IsNozzleLeakoff"
      label="Nozzle Diameter"
      appUnwrap
      [source]="shuntTube.NozzleProperties"
      [key]="'NozzleDiameter'"
      [decimalPlaces]="3"
      [unitType]="UnitType.Diameter"
      (valueChanged)="onValueChange($event)"
    >
    </app-form-input>
    <app-form-input
      [disabled]="!shuntTube.NozzleProperties.IsNozzleLeakoff"
      label="Nozzle Length"
      appUnwrap
      [source]="shuntTube.NozzleProperties"
      [key]="'NozzleLength'"
      [decimalPlaces]="3"
      [unitType]="UnitType.Short_Length"
      (valueChanged)="onValueChange($event)"
    >
    </app-form-input>
    <app-form-input
      [disabled]="!shuntTube.NozzleProperties.IsNozzleLeakoff || !isTransportTubePresent"
      label="Length to First Nozzle"
      appUnwrap
      [source]="shuntTube.NozzleProperties"
      [key]="'LengthToFirstNozzle'"
      [decimalPlaces]="2"
      [unitType]="UnitType.Long_Length"
      (valueChanged)="onValueChange($event)"
    >
    </app-form-input>
    <app-form-input
      [disabled]="!shuntTube.NozzleProperties.IsNozzleLeakoff"
      label="Length Between Nozzles"
      appUnwrap
      [source]="shuntTube.NozzleProperties"
      [key]="'LengthBetweenNozzles'"
      [decimalPlaces]="2"
      [unitType]="UnitType.Long_Length"
      (valueChanged)="onValueChange($event)"
    >
    </app-form-input>
  </div>
</app-page-panel>
