import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ITableRow } from '@dunefront/common/common/common-grid.interfaces';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { WorkstringPipe } from '@dunefront/common/modules/pipes/running-string-pipes/pipes/workstring-pipe';
import { ToolJoint } from '@dunefront/common/modules/pipes/tool-joint/tool-joint';
import { changeProp, ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';
import { IUpdateTableRowsProps } from '@dunefront/common/common/common-store-crud.interfaces';

@Component({
  selector: 'app-workstring-properties',
  templateUrl: './workstring-properties.component.html',
  styleUrls: ['./workstring-properties.component.css'],
})
export class WorkstringPropertiesComponent implements OnChanges {
  @Input() public pipe!: ITableRow<WorkstringPipe>;
  @Output() public rowUpdated = new EventEmitter<IUpdateTableRowsProps<WorkstringPipe>>();

  public toolJoint?: ToolJoint;
  public UnitType = UnitSystem;

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.pipe != null) {
      this.toolJoint = this.pipe.rowData.ToolJoint;
    }
  }

  public onValueChange(event: ObjectChangeProp<ToolJoint>): void {
    const newRow: ITableRow<WorkstringPipe> = {
      ...this.pipe,
      rowData: { ...this.pipe.rowData, ToolJoint: changeProp(this.pipe.rowData.ToolJoint, event) },
    };
    this.rowUpdated.emit({ rows: [newRow], colIds: ['ToolJoint'], shouldResetResults: event.shouldResetResults });
  }
}
