import { Routes } from '@angular/router';
import { InjectionTestCalculatorComponent } from './injection-test/injection-test-calculator.component';
import { InjectionTestComponent } from './injection-test.component';

export const RouteModuleInjectionTest = 'injection-test';

export const InjectionTestRoutes: Routes = [
  {
    path: RouteModuleInjectionTest,
    component: InjectionTestComponent,
    data: {
      breadcrumb: 'Injection Test Calculator',
    },
    children: [{ path: '', component: InjectionTestCalculatorComponent }],
  },
];
