import { IEBMLItem, IWebPFrame } from '../interfaces';
import { checkFrames } from './checkFrames';
import { generateEBML } from './generateEBML';
import { makeSimpleBlock } from './makeSimpleBlock';
import { CreateEBML } from './createEBML';

export const toWebM = (frames: IWebPFrame[], outputAsArray = false): Blob | Uint8Array => {
  const info = checkFrames(frames);

  //max duration by cluster in milliseconds
  const CLUSTER_MAX_DURATION = 30000;

  const EBML: Array<IEBMLItem> = CreateEBML(info);

  const segment = EBML[1];
  const cues: any = segment.data[2];

  //Generate clusters (max duration)
  let frameNumber = 0;
  let clusterTimecode = 0;
  while (frameNumber < frames.length) {
    const cuePoint = {
      id: 0xbb, // CuePoint
      data: [
        {
          data: Math.round(clusterTimecode),
          id: 0xb3, // CueTime
        },
        {
          id: 0xb7, // CueTrackPositions
          data: [
            {
              data: 1,
              id: 0xf7, // CueTrack
            },
            {
              data: 0, // to be filled in when we know it
              size: 8,
              id: 0xf1, // CueClusterPosition
            },
          ],
        },
      ],
    };

    (cues.data as Array<any>).push(cuePoint);

    const clusterFrames = [];
    let clusterDuration = 0;
    do {
      clusterFrames.push(frames[frameNumber]);
      clusterDuration += frames[frameNumber].duration;
      frameNumber++;
    } while (frameNumber < frames.length && clusterDuration < CLUSTER_MAX_DURATION);

    let clusterCounter = 0;
    const cluster = {
      id: 0x1f43b675, // Cluster
      data: [
        {
          data: Math.round(clusterTimecode),
          id: 0xe7, // Timecode
        },
      ].concat(
        clusterFrames.map((webp) => {
          const block = makeSimpleBlock({
            discardable: 0,
            frame: webp.data.slice(webp.data.indexOf('\x9d\x01\x2a') - 3),
            invisible: 0,
            keyframe: 1,
            lacing: 0,
            trackNum: 1,
            timecode: Math.round(clusterCounter),
          });
          clusterCounter += webp.duration;
          return {
            data: block,
            id: 0xa3,
          };
        }),
      ),
    };

    //Add cluster to segment
    (segment.data as Array<any>).push(cluster);
    clusterTimecode += clusterDuration;
  }

  //First pass to compute cluster positions
  let position = 0;
  for (let i = 0; i < segment.data.length; i++) {
    if (i >= 3) {
      cues.data[i - 3].data[1].data[1].data = position;
    }
    const data = generateEBML([segment.data[i]], outputAsArray);
    if ('size' in data) {
      position += data.size;
    } else if ('byteLength' in data) {
      position += data.byteLength;
    } else {
      console.warn('no size and no byteLength found');
      position += (data as any).length;
    }

    if (i !== 2) {
      // not cues
      //Save results to avoid having to encode everything twice
      segment.data[i] = data;
    }
  }

  return generateEBML(EBML, outputAsArray);
};
