import { IXAxisShiftUpdate } from '../../../+store/reporting/reporting-module.state';
import * as reportingActions from '../../../+store/reporting/reporting.actions';
import { updateXAxisShiftSuccessAction } from '../../../+store/reporting/reporting.actions';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { Subscription } from 'rxjs';

export class ChartHorizontalShiftService {
  private subscription = new Subscription();

  constructor(
    private readonly store: Store,
    protected actions$: Actions,
    private readonly reloadChartCallback: () => void,
  ) {
    this.subscription.add(this.actions$.pipe(ofType(updateXAxisShiftSuccessAction)).subscribe(() => reloadChartCallback()));
  }

  public onUpdateXAxisShift($event: IXAxisShiftUpdate): void {
    this.store.dispatch(reportingActions.updateXAxisShiftAction({ xAxisShiftUpdates: [$event] }));
  }

  public dispose(): void {
    this.subscription.unsubscribe();
  }
}
