import { ColumnType, IGridColumnConfig } from '../../../../../shared/components/grid/grid.interfaces';
import { Caliper } from '@dunefront/common/modules/well/model/caliper/caliper';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';

export const caliperDataGridConfig: IGridColumnConfig<Caliper>[] = [
  { disabled: false, visible: true, colId: ' ', type: ColumnType.selection },
  {
    disabled: false,
    visible: true,
    colId: 'TopMD',
    type: ColumnType.number,
    headerText: 'Top MD',
    unitSystem: UnitSystem.Long_Length,
    matchingStrings: ['top', 'md'],
  },
  {
    disabled: false,
    visible: true,
    colId: 'Diameter',
    type: ColumnType.number,
    headerText: 'OH ID',
    unitSystem: UnitSystem.Diameter,
    decimalPlaces: 3,
    matchingStrings: ['oh', 'id', 'diameter'],
  },
];
