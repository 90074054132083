import { ChangeDetectorRef, Component } from '@angular/core';
import { DbDependentComponent } from '../../../common-modules/db-connection/db-dependent.component';
import { Store } from '@ngrx/store';
import * as uiActions from '../../../+store/ui/ui.actions';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent extends DbDependentComponent {
  constructor(store: Store, cdRef: ChangeDetectorRef) {
    super(store, cdRef);
    store.dispatch(uiActions.setAppSectionAction({ section: 'settings' }));
  }
}
