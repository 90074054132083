import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { ModalService } from '../../../../common-modules/modals/modal.service';
import { notEmpty } from '@dunefront/common/common/state.helpers';
import { getPSDsAsGrid } from '../../../../+store/psd-analysis/psd-analysis.selectors';
import { PageWithGridComponent } from '../../../../shared/components/grid/page-with-grid.component';
import { ConvertUnitPipe } from '@dunefront/common/modules/units/convert-unit.pipe/convert-unit.pipe';
import { ScreenService } from '../../../../shared/services';
import { ITableRow } from '@dunefront/common/common/common-grid.interfaces';
import * as uiActions from '../../../../+store/ui/ui.actions';
import { IUpdateTableRowsProps } from '@dunefront/common/common/common-store-crud.interfaces';
import { PSDResult } from '@dunefront/common/modules/psd-analysis/model/psd/psd';

@Component({
  selector: 'app-screen-and-gravel-selection',
  templateUrl: './screen-and-gravel-selection.component.html',
  styleUrls: ['./screen-and-gravel-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScreenAndGravelSelectionComponent extends PageWithGridComponent<PSDResult> {
  public psdTable$ = notEmpty(this.store.select(getPSDsAsGrid));

  constructor(
    store: Store,
    cdRef: ChangeDetectorRef,
    modalService: ModalService,
    protected convertUnitPipe: ConvertUnitPipe,
    resizeService: ScreenService,
  ) {
    super(store, cdRef, modalService, resizeService);
    this.onHelpChange('psd', 'psd-selection');
    store.dispatch(uiActions.setPsdAnalysisTabAction({ tab: 'screen-and-gravel-selection' }));
  }

  public getRows(): ITableRow<PSDResult>[] {
    return [];
  }

  protected updateRow(row: IUpdateTableRowsProps<any>): void {
    return;
  }
}
