import { getRowsForCalculations, ITableRow, ITableState } from '../../../../common/common-grid.interfaces';
import { InjectionTestReading } from './injection-test-reading';
import { noErrors } from '../../../../common/state.helpers';
import { ArrayHelpers } from '../../../../common/array-helpers';
import { IError } from '../../../../common/common-state.interfaces';
import {
  InjectionTestCalculatorModuleState,
  IValidatedInjectionTestCalculatorModuleState,
} from '../injection-test-calculator-module.state';
import { InjectionTestCalculatorDto } from '../injection-test-calculator.dto';

export class InjectionTestCalculatorValidation {
  public static validate(state: InjectionTestCalculatorModuleState): IValidatedInjectionTestCalculatorModuleState {
    const InjectionTestReadings = this.ValidateInjectionTestCalculator(state.InjectionTestReadings, state.InjectionTestCalculator);
    return {
      ...state,
      InjectionTestReadings,
      isValid: InjectionTestReadings.isValid,
      error: {},
    };
  }

  public static ValidateInjectionTestCalculator(
    injectionTestReadings: ITableState<InjectionTestReading>,
    injectionTestCalculator: InjectionTestCalculatorDto,
  ): ITableState<InjectionTestReading> {
    let isValid = true;
    const newInjectionTestReadings = { ...injectionTestReadings };
    const injectionTestReadingsRows: InjectionTestReading[] = getRowsForCalculations(newInjectionTestReadings.rows);
    const updatedRows = newInjectionTestReadings.rows.map((row, rowIndex) => {
      if (row.rowType === 'insert-row') {
        return row;
      }
      const newRow = this.ValidateInjectionTestGridRow(
        injectionTestReadings.rows[rowIndex],
        injectionTestReadingsRows,
        rowIndex,
        injectionTestCalculator,
      );
      isValid = isValid && newRow.isValid;
      return newRow;
    });

    return { ...injectionTestReadings, rows: updatedRows, isValid };
  }

  private static ValidateInjectionTestGridRow(
    row: ITableRow<InjectionTestReading>,
    injectionTestReadings: InjectionTestReading[],
    rowIndex: number,
    injectionTestCalculator: InjectionTestCalculatorDto,
  ): ITableRow<InjectionTestReading> {
    const rowError: IError<InjectionTestReading> = {
      Rate: this.ValidateInjectionTestRate(injectionTestReadings, rowIndex, injectionTestCalculator),
      Pressure: this.ValidateInjectionTestPressure(injectionTestReadings, rowIndex),
    };
    return { ...row, error: rowError, isValid: noErrors(rowError) };
  }

  private static ValidateInjectionTestRate(
    injectionTestReadings: InjectionTestReading[],
    rowIndex: number,
    injectionTestCalculator: InjectionTestCalculatorDto,
  ): string {
    if (injectionTestReadings[rowIndex].Rate <= 0) {
      return 'Rate must be greater than 0';
    }
    if (injectionTestReadings.length < 4) {
      return 'At least 4 data points are required';
    }
    if (injectionTestReadings.filter((itr) => itr.Rate === injectionTestReadings[rowIndex].Rate).length > 1) {
      return 'Rate value must be unique';
    }
    const counts = ArrayHelpers.countAboveAndBelow(
      injectionTestReadings.map((itr) => itr.Rate),
      injectionTestCalculator.MarkerPosition,
    );
    if (counts.above < 2 || counts.below < 2) {
      return 'At least 2 data points are required either side of the slope change line';
    }
    return '';
  }

  private static ValidateInjectionTestPressure(injectionTestReadings: InjectionTestReading[], rowIndex: number): string {
    if (injectionTestReadings[rowIndex].Pressure <= 0) {
      return 'Pressure must be greater than 0';
    }
    if (injectionTestReadings.length < 4) {
      return 'At least 4 data points are required';
    }
    return '';
  }
}
