import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { DbDependentComponent } from '../db-connection/db-dependent.component';
import { getDbMigration } from '../../+store/db-migration/db-migration.selectors';
import { IMigrationServerResponse } from '@dunefront/common/modules/db-migration/db-migration-module.actions';
import { cancelMigration, startDbMigration } from '../../+store/db-migration/db-migration.actions';
import { getAllScenarios } from '../../+store/scenario/scenario.selectors';
import { getBackendConnectionState } from '../../+store/backend-connection/backend-connection.selectors';
import { IFile } from '@dunefront/common/dto/file.dto';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import * as dbConnectionActions from '../../+store/backend-connection/backend-connection.actions';
import { ConnectionLicensingConfig } from '@dunefront/common/modules/db-connection/db-connection.actions';

@Component({
  selector: 'app-db-migration',
  templateUrl: './db-migration.component.html',
  styleUrls: ['./db-migration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DbMigrationComponent extends DbDependentComponent implements OnInit, OnDestroy {
  public migrationInfo!: IMigrationServerResponse;
  protected targetScenario?: number;
  protected targetFile?: IFile;
  public readonly licensingConfig: ConnectionLicensingConfig;

  constructor(
    store: Store,
    cdRef: ChangeDetectorRef,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig<DbMigrationComponentConfigData>,
  ) {
    super(store, cdRef);
    if (config.data == null) {
      throw new Error('Missing Config Data');
    }
    this.licensingConfig = config.data.licensingConfig;
  }

  public override ngOnInit(): void {
    super.ngOnInit();
    this.subscription.add(
      this.store.select(getAllScenarios).subscribe((scenarios) => {
        if (scenarios.length > 0) {
          this.targetScenario = scenarios[0].Id;
        }
      }),
    );

    this.subscription.add(
      this.store.select(getDbMigration).subscribe((dbMigration) => {
        this.migrationInfo = dbMigration.migrationInfo;
        this.cdRef.markForCheck();
      }),
    );

    this.subscription.add(
      this.store.select(getBackendConnectionState).subscribe((state) => {
        if (state.dbInfo) {
          this.targetFile = state.dbInfo.file;
        }
      }),
    );
  }

  public onStartMigrationClicked(): void {
    this.store.dispatch(startDbMigration());
  }

  public onCompleteMigrationClicked(): void {
    this.ref.close();
    if (this.targetFile) {
      this.store.dispatch(dbConnectionActions.dbConnectAction({ dbFile: this.targetFile, licensingConfig: this.licensingConfig }));
    }
  }

  public onCancelMigrationClicked(): void {
    this.ref.close();
    this.store.dispatch(cancelMigration());
  }

  public get isMigrationRequired(): boolean {
    return this.migrationInfo.status === 'migrationRequired' || this.migrationInfo.status === 'migrationRequiredWithBackup';
  }
}

export interface DbMigrationComponentConfigData {
  licensingConfig: ConnectionLicensingConfig;
}
