import { LoadGravelsActionResponse } from '@dunefront/common/modules/gravel/gravel-module.actions';
import { DictionaryWithArray } from '@dunefront/common/common/state.helpers';
import { CommonDbCopyDirection, CrudResponse } from '@dunefront/common/modules/common.actions';
import { gravelModuleInitialState, GravelModuleState } from '@dunefront/common/modules/gravel/gravel-module.state';
import { GravelFactory } from '@dunefront/common/modules/gravel/model/gravel.factory';
import { ActionResponse } from '@dunefront/common/response-ws.action';

export class GravelModuleReducerHelper {
  public static onLoadScenarioDataSuccessAction(
    state: GravelModuleState,
    response: ActionResponse<LoadGravelsActionResponse>,
  ): GravelModuleState {
    if (response.status !== 'ok' || !response.payload) {
      return gravelModuleInitialState;
    }
    return GravelFactory.create(response.payload);
  }

  public static selectGravel(state: GravelModuleState, gravelId: number): GravelModuleState {
    return {
      ...state,
      SelectedGravelId: gravelId,
    };
  }

  public static updateGravelRowSuccess(state: GravelModuleState, action: CrudResponse): GravelModuleState {
    if (!action.affectedRows.gravel) {
      return state;
    }
    const newGravel = GravelFactory.createItem(action.affectedRows.gravel.rows[0]);
    return { ...state, Gravels: DictionaryWithArray.upsert(state.Gravels, newGravel, 'Id') };
  }

  public static insertGravelRowSuccess(state: GravelModuleState, action: CrudResponse): GravelModuleState {
    const affectedGravel = action.affectedRows.gravel;
    if (!affectedGravel || action.commonDbCopyDirection === CommonDbCopyDirection.toCommonDb) {
      return state;
    }

    let Gravels = state.Gravels;
    let SelectedGravelId = state.SelectedGravelId;

    const deletedGravelIds = affectedGravel.deletedIds;
    if (deletedGravelIds.length) {
      Gravels = DictionaryWithArray.deleteItems(Gravels, deletedGravelIds);
    }

    if (affectedGravel.replace === 'all') {
      // this is undo case
      Gravels = GravelFactory.createDictionaryWithArray(affectedGravel.rows);
      SelectedGravelId = affectedGravel.rows.find((row) => row.Name === 'New Gravel')?.Id ?? +Gravels.ids[Gravels.ids.length - 1];
    } else {
      const newGravel = GravelFactory.createItem(affectedGravel.rows[0]);
      Gravels = DictionaryWithArray.upsert(Gravels, newGravel, 'Id');
      SelectedGravelId = newGravel.Id;
    }

    return {
      ...state,
      Gravels,
      SelectedGravelId,
    };
  }

  public static deleteGravelRowsSuccess(state: GravelModuleState, action: CrudResponse): GravelModuleState {
    if (!action.affectedRows.gravel) {
      return state;
    }
    const gravelIdToDelete = action.affectedRows.gravel.deletedIds[0];
    const Gravels = DictionaryWithArray.deleteItem(state.Gravels, gravelIdToDelete);
    return {
      ...state,
      Gravels,
      SelectedGravelId: DictionaryWithArray.getMaxId(Gravels),
    };
  }
}
