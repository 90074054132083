import { IGetCurrentFeaturesResult, LicenseFeature } from './licensing.interfaces';
import { ModuleType } from '../scenario/scenario.dto';

export const validateFeatureRequired = (
  requiredLicenseFeatures: LicenseFeature[],
  currentLicenseFeatures: IGetCurrentFeaturesResult,
): string => {
  if (currentLicenseFeatures.accessibleFeatures.length === 0 || requiredLicenseFeatures.length === 0) {
    return '';
  } else if (requiredLicenseFeatures.some((required) => currentLicenseFeatures.accessibleFeatures.includes(required))) {
    return '';
  } else {
    return 'Upgrade your license to use this feature';
  }
};

export const validateFeatureNotSupportedInFluidPro = (moduleType: ModuleType): string =>
  moduleType === ModuleType.Simulate_Disp ? 'This option is not supported in FluidPro' : '';
