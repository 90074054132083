import { createAction, props } from '@ngrx/store';
import {
  IDeleteRowsProps,
  IInsertRowsProps,
  IUpdateRowsProps,
  IUpdateTableRowsProps,
} from '@dunefront/common/common/common-store-crud.interfaces';
import { PSDAnalysis } from '@dunefront/common/modules/psd-analysis/model/psd-analysis/psd-analysis';
import { PSD } from '@dunefront/common/modules/psd-analysis/model/psd/psd';
import { PsdReading } from '@dunefront/common/modules/psd-analysis/model/psd-readings/psd-reading';
import { PSDInputType } from '@dunefront/common/modules/psd-analysis/dto/psd.dto';

// region psd analysis

export const updatePSDAnalysis = createAction('[PSD-analysis] updatePSDAnalysis', props<IUpdateRowsProps<PSDAnalysis>>());

// endregion

// region psd

export const selectPSDAction = createAction('[PSD] selectPSD', props<{ psdId: number }>());
export const updatePSD = createAction('[PSD] savePSD', props<IUpdateRowsProps<PSD>>());
export const addPSD = createAction('[PSD] addPSD', props<{ scenarioId: number }>());
export const deletePSD = createAction('[PSD] deletePSD', props<IDeleteRowsProps>());
export const updatePSDs = createAction('[PSD] toggleIsSelectedPSD', props<IUpdateRowsProps<PSD>>());
export const updateLastPSDInputType = createAction('[PSD] updateLastPSDInputType', props<{ PSDInputType: PSDInputType }>());
// endregion

// region psd readings

export const updatePsdReadingsRow = createAction('[PSD-readings] updateRow', props<IUpdateTableRowsProps<PsdReading>>());
export const insertPsdReadingsRow = createAction('[PSD-readings] insertRows', props<IInsertRowsProps<PsdReading>>());
export const deletePsdReadingsRows = createAction('[PSD-readings] deleteRows', props<IDeleteRowsProps>());

// endregion

// region analysis

export const calculatePsdAnalysis = createAction('[PSD] startAnalysis', props<{ isFullAnalysis: boolean }>());

//endregion
