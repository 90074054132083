import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { filterNil } from '@dunefront/common/common/state.helpers';
import { deleteChartGradientLine, insertChartGradientLine, updateChartGradientLines } from '../../../+store/reporting/reporting.actions';
import { ModalService } from '../../modals/modal.service';
import { IGradientLine } from '../chart-component-helpers/chart-types';
import { StoreCrudPropsFactory } from '@dunefront/common/common/common-store-crud.interfaces';
import { ArgConverter, ChartAddonsWithConverter } from '../chart-component-helpers/chart-addons-helpers';
import { CreateGradientLinePayload } from '../chart-component-helpers/chart-gradient-line-helpers';
import { ChartGradientLineComponent, ChartGradientLineDialogResult } from '../chart-gradient-line/chart-gradient-line.component';
import { take } from 'rxjs/operators';
import { IGradientLineStyle } from '@dunefront/common/modules/reporting/dto/chart-gradient-line.dto';
import { selectUserGlobalOptions } from '../../../+store/common-db/common-db.selectors';

export const ChartGradientLineComponentClass = 'chart-gradient-line-component';

@Injectable()
export class ChartGradientLinesService {
  private subscription = new Subscription();
  private defaultLineStyle!: IGradientLineStyle;
  private chartId?: number;
  private _gradientLines$ = new BehaviorSubject<IGradientLine[] | undefined>(undefined);
  private toPrimaryArgConverter: ArgConverter;

  constructor(
    private store: Store,
    private modalService: ModalService,
    addonsWithConverter$: Observable<ChartAddonsWithConverter | undefined>,
  ) {
    this.subscription.add(this.store.select(selectUserGlobalOptions).subscribe((globalOptions) => (this.defaultLineStyle = globalOptions)));

    this.subscription.add(
      addonsWithConverter$.subscribe((addonsWithConverter) => {
        let newLines = addonsWithConverter?.chartUserAddons?.chartGradientLines;
        const toSecondaryArgConverter = addonsWithConverter?.toSecondaryArgConverter;
        // convert to secondary argument if possible and needed
        if (newLines != null && toSecondaryArgConverter != null) {
          const result: IGradientLine[] = [];

          for (const line of newLines) {
            result.push({
              ...line,
              argument1: toSecondaryArgConverter(line.argument1),
              argument2: toSecondaryArgConverter(line.argument2),
            });
          }

          newLines = result;
        }

        this.chartId = addonsWithConverter?.chartId;
        this._gradientLines$.next(newLines);
        this.toPrimaryArgConverter = addonsWithConverter?.toPrimaryArgConverter;
      }),
    );
  }

  public get gradientLines$(): Observable<IGradientLine[]> {
    return this._gradientLines$.pipe(filterNil());
  }

  public onCreateGradientLine({ argument1, value1, argument2, value2 }: CreateGradientLinePayload, scenarioId: number): void {
    const chartId = this.chartId;
    if (chartId == null) {
      throw new Error("ChartId can't be undefined!");
    }

    const newGradientLine: IGradientLine = {
      Id: -1,
      chartId: chartId,
      ScenarioId: scenarioId,
      sortOrder: 0,
      argument1,
      value1,
      argument2,
      value2,
      pinCalculatedParams: false,
    };

    // add new gradient line to collection
    // only temporarily to prevent the line from disappearing from the chart until BE responds
    const newLines = [...(this._gradientLines$.value ?? []), newGradientLine];
    this._gradientLines$.next(newLines);

    const newPrimaryArgGradientLine = this.toPrimaryArgGradientLine(newGradientLine);
    this.store.dispatch(insertChartGradientLine(newPrimaryArgGradientLine));
  }

  public onEditGradientLine(secondaryArgGradientLine: IGradientLine): void {
    this.showDialog(secondaryArgGradientLine, true);
  }

  public onGradientLinesUpdated(secondaryArgGradientLine: IGradientLine[]): void {
    const lines = secondaryArgGradientLine.map((a) => this.toPrimaryArgGradientLine(a));

    this.store.dispatch(updateChartGradientLines({ lines }));
  }

  public dispose(): void {
    this.subscription.unsubscribe();
  }

  private showDialog(secondaryArgGradientLine: IGradientLine, allowDelete: boolean): void {
    const dialog = this.modalService.open(
      ChartGradientLineComponent,
      {
        gradientLine: secondaryArgGradientLine,
        allowDelete,
        defaultLineStyle: this.defaultLineStyle,
      },
      ChartGradientLineComponentClass,
    );

    dialog.onClose.pipe(take(1)).subscribe((result: ChartGradientLineDialogResult | undefined) => {
      if (!result) {
        return;
      }

      const resultSecondaryArgGradientLine = result.value;
      const primaryArgGradientLine = this.toPrimaryArgGradientLine(resultSecondaryArgGradientLine);

      switch (result.action) {
        case 'ok':
          this.store.dispatch(
            primaryArgGradientLine.Id === -1
              ? insertChartGradientLine(primaryArgGradientLine)
              : updateChartGradientLines({ lines: [primaryArgGradientLine] }),
          );
          break;
        case 'delete':
          this.onDeleteGradientLine(primaryArgGradientLine).then();
          break;
      }
    });
  }

  private toPrimaryArgGradientLine(gradientLine: IGradientLine): IGradientLine {
    return {
      ...gradientLine,
      argument1: this.toPrimaryArgConverter?.(gradientLine.argument1) ?? gradientLine.argument1,
      argument2: this.toPrimaryArgConverter?.(gradientLine.argument2) ?? gradientLine.argument2,
    };
  }

  public onKeyboardDeleteGradientLine(gradientLine: IGradientLine): void {
    const primaryArgGradientLine = this.toPrimaryArgGradientLine(gradientLine);
    this.onDeleteGradientLine(primaryArgGradientLine).then();
  }

  private async onDeleteGradientLine(primaryArgGradientLine: IGradientLine): Promise<void> {
    const confirm = await this.modalService.showConfirm('Confirm delete Slope Line?', 'Information');
    if (confirm) {
      this.store.dispatch(deleteChartGradientLine(StoreCrudPropsFactory.deleteRows(primaryArgGradientLine, false)));
    }
  }
}
