import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewEncapsulation } from '@angular/core';
import { DbDependentComponent } from '../../common-modules/db-connection/db-dependent.component';
import { Store } from '@ngrx/store';
import { calculatePsdAnalysis } from '../../+store/psd-analysis/psd-analysis.actions';
import { notEmpty } from '@dunefront/common/common/state.helpers';
import { IPSDModuleValid, getPSDModuleError, isPSDModuleValid } from '../../+store/psd-analysis/psd-analysis.selectors';
import { ModalService } from '../../common-modules/modals/modal.service';
import { ActivatedRoute } from '@angular/router';
import * as uiActions from '../../+store/ui/ui.actions';
import { ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import { getPsdAnalysisModuleMenuData } from './psd-analysis-module.menu';
import { LeftNavHelperService } from '../../shared/components/left-nav/left-nav-helper.service';
import { firstValueFrom } from 'rxjs';
import { RouterHelperService } from '../../shared/services/router-helper.service';
import { RouteModulePsdAnalysisPsdInput } from './psd-analysis-routes-names';
import { RouteModuleResults } from '../simulate-evaluate-page/simulate-evaluate-routes-names';

@Component({
  selector: 'app-psd-analysis',
  templateUrl: './psd-analysis-page.component.html',
  styleUrls: ['./psd-analysis-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PsdAnalysisPageComponent extends DbDependentComponent {
  public psdMenu$ = this.store.select(getPsdAnalysisModuleMenuData);
  public isPSDModuleValid!: IPSDModuleValid;
  public selectedUri: string | null = null;
  public showPrevNextNav = false;
  public moduleType = ModuleType.PSD_Analysis_Full;

  constructor(
    store: Store,
    cdRef: ChangeDetectorRef,
    private modalService: ModalService,
    private activatedRoute: ActivatedRoute,
    private runCalculationService: LeftNavHelperService,
    private routerHelperService: RouterHelperService,
  ) {
    super(store, cdRef);

    store.dispatch(uiActions.setAppModuleAction({ appModuleType: this.moduleType }));

    this.subscription.add(
      notEmpty(store.select(isPSDModuleValid)).subscribe((isPsdModuleValid) => {
        this.isPSDModuleValid = isPsdModuleValid;
        if (!isPsdModuleValid.hasResults && this.routerHelperService.getDecodedUrlParts().includes('results')) {
          this.routerHelperService.navigate([RouteModulePsdAnalysisPsdInput], { relativeTo: this.activatedRoute }).then(() => null);
        }
        this.cdRef.markForCheck();
      }),
    );

    this.subscription.add(this.runCalculationService.calculationSubject.subscribe(async () => await this.startAnalysis()));
    store.dispatch(uiActions.setAppSectionAction({ section: 'psd-analysis' }));
  }

  private async startAnalysis(): Promise<void> {
    const psdError = await firstValueFrom(this.store.select(getPSDModuleError));
    if (psdError && psdError.length > 0) {
      await this.modalService.showAlert(psdError, 'Information');
      return;
    }
    this.store.dispatch(calculatePsdAnalysis({ isFullAnalysis: true }));
    await this.routerHelperService.navigate([RouteModuleResults], { relativeTo: this.activatedRoute });
  }
}
