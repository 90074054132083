import { CasingPipeDto } from '../../well/dto/casing/casing-pipe.dto';
import { LowerCompletionPipeDtoWithICDPorts } from '../../../dto/lower-completion-pipe.dto';
import { RunningStringPipeDto } from '../../../dto/running-string-pipe.dto';
import { WellFluidDto } from '../../pumping/dto/well-fluid.dto';
import { TreatingReturnLines, TreatingReturnLinesFactory } from '../../pumping/dto/treating-return-lines';
import { WellModuleState } from '../../well/well-module.state';
import { CompletionModuleState } from '../../completion/completion-module.state';
import { PumpingStateByRange } from '../../pumping/pumping-module.state';
import { SettingsDto, DeveloperSettingsDto } from '../../settings/dto/settingsDto';
import { getRowsForCalculations } from '../../../common/common-grid.interfaces';
import { CasingPipesFactory } from '../../pipes/casing-pipes/casing-pipes.factory';
import { CaliperFactory } from '../../well/model/caliper/caliper.factory';
import { LowerCompletionPipesFactory } from '../../pipes/lower-completion-pipes/lower-completion-pipes.factory';
import { RunningStringPipesFactory } from '../../pipes/running-string-pipes/running-string-pipes.factory';
import { WellFluidFactory } from '../../pumping/model/well-fluid/well-fluid.factory';
import { ShuntTubeDto } from '../../../dto/shunt-tube.dto';
import { ShuntTubeFactory } from '../../shunt-tube/shunt-tube.factory';
import { CaliperTransferArray } from '../../well/dto/caliper/caliper.dto';

export interface WellFluidConversionJobInputDataDto {
  // Well
  CasingData: CasingPipeDto[];
  CalculatedCaliperData: CaliperTransferArray;
  SeaBedDepth: number;

  //Completion
  LowerCompletion: LowerCompletionPipeDtoWithICDPorts[];
  RunningString: RunningStringPipeDto[];
  ShuntTube: ShuntTubeDto;

  //Pumping
  WellFluids: WellFluidDto[];
  TreatingReturnLines: TreatingReturnLines;

  //Settings
  IsToolJointAnalysed: boolean;
  IsModelScreenHandlingLength: boolean;
  GlobalTolerance: number;
}

export interface WellFluidConversionJobInputDataDeps {
  well: WellModuleState;
  completion: CompletionModuleState;
  pumping: PumpingStateByRange;
  settings: SettingsDto;
  devOptions: DeveloperSettingsDto;
}

export class WellFluidConversionJobInputDataFactory {
  public static toDto(deps: WellFluidConversionJobInputDataDeps): WellFluidConversionJobInputDataDto {
    {
      const { well, completion, pumping, settings, devOptions } = deps;

      const CasingData = getRowsForCalculations(well.CasingData.rows).map((pipe) => CasingPipesFactory.toDto(pipe));
      const caliperDtos = getRowsForCalculations(well.CaliperData.rows).map((caliper) => CaliperFactory.toDto(caliper));
      const CalculatedCaliperData = CaliperFactory.toCalculatedCaliperTransferArray(caliperDtos, well.CaliperTolerance);
      const SeaBedDepth = well.TemperatureProfile.SeaBedDepth;

      const LowerCompletion = getRowsForCalculations(completion.LowerCompletion.rows).map((pipe) => {
        return LowerCompletionPipesFactory.toDtoWithIcdPorts(pipe);
      });
      const RunningString = getRowsForCalculations(completion.RunningString.rows).map((pipe) => RunningStringPipesFactory.toDto(pipe));

      const WellFluids = getRowsForCalculations(pumping.wellFluids.rows).map((wellFluids) => WellFluidFactory.toDto(wellFluids));
      const ShuntTube = ShuntTubeFactory.toDto(completion.ShuntTube);

      const TreatingReturnLines = TreatingReturnLinesFactory.create(pumping);

      const { IsToolJointAnalysed, ModelScreenHandlingLength } = settings;
      const { GlobalTolerance } = devOptions;

      return {
        CasingData,
        CalculatedCaliperData,
        SeaBedDepth,
        LowerCompletion,
        RunningString,
        ShuntTube,
        WellFluids,
        TreatingReturnLines,
        IsToolJointAnalysed,
        IsModelScreenHandlingLength: ModelScreenHandlingLength,
        GlobalTolerance,
      };
    }
  }
}
