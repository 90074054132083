import { NgModule } from '@angular/core';
import { FileDisplayNamePipe } from './file-display-name.pipe';
import { FileLocationPipe } from './file-location.pipe';
import { FileSizePipe } from './file-size.pipe';

@NgModule({
  declarations: [FileDisplayNamePipe, FileLocationPipe, FileSizePipe],
  exports: [FileDisplayNamePipe, FileLocationPipe, FileSizePipe],
})
export class FileDisplayNamePipeModule {}
