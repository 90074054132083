import { AfterViewInit, Directive, ElementRef, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[appUnwrap]',
})
export class UnwrapDirective implements AfterViewInit {
  constructor(private el: ElementRef) {}

  public ngAfterViewInit(): void {
    // get the element's parent node
    const parent = this.el.nativeElement.parentNode;

    // move all children out of the element
    while (this.el.nativeElement.firstChild) {
      parent.insertBefore(this.el.nativeElement.firstChild, this.el.nativeElement);
    }
    // remove the empty element
    parent.removeChild(this.el.nativeElement);
  }
}

@NgModule({
  imports: [CommonModule],
  declarations: [UnwrapDirective],
  exports: [UnwrapDirective],
})
export class UnwrapDirectiveModule {}
