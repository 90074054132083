import { CommonFluidModel } from './common-fluid.model';
import { createTableRow, GridRowType, ITableRow } from '@dunefront/common/common/common-grid.interfaces';
import { FluidDto, FluidType } from '@dunefront/common/modules/fluid/dto/fluid.dto';
import { RheologyDto } from '@dunefront/common/modules/fluid/dto/rheology/rheology.dto';
import { ILoadCommonFluidsActionResponse } from '@dunefront/common/modules/common-db/common-db.actions';
import { RheometerDto, RheometerReadingDto } from '@dunefront/common/modules/fluid/dto/rheometer.dto';
import { ArrayHelpers } from '@dunefront/common/common/array-helpers';
import { CommonDbType } from '@dunefront/common/dto/common-dto.interfaces';
import { RheologyFactory } from '@dunefront/common/modules/fluid/model/rheology/rheology.factory';
import { RheometerFactory } from '@dunefront/common/modules/fluid/model/rheometer/rheometer.factory';

export class CommonFluidFactory {
  public static createRowListFromDtos(response: ILoadCommonFluidsActionResponse): ITableRow<CommonFluidModel>[] {
    const rowArray: ITableRow<CommonFluidModel>[] = [];
    this.addLevelDtos(
      rowArray,
      response.personalCommonFluids,
      response.personalCommonRheologies,
      response.personalCommonRheometers,
      response.personalCommonRheometerReadings,
      'User',
    );
    this.addLevelDtos(
      rowArray,
      response.orgCommonFluids,
      response.orgCommonRheologies,
      response.orgCommonRheometers,
      response.orgCommonRheometerReadingss,
      'Organization',
    );
    this.addLevelDtos(rowArray, response.predefinedCommonFluids, response.predefinedCommonRheologies, [], [], 'Predefined');
    return rowArray;
  }

  public static addLevelDtos(
    rowArray: ITableRow<CommonFluidModel>[],
    fluids: FluidDto[],
    rheologies: RheologyDto[],
    rheometers: RheometerDto[],
    rheometerReadings: RheometerReadingDto[],
    level: CommonDbType,
  ): void {
    const currentCount = rowArray.length;
    [...fluids]
      .sort((a, b) => ArrayHelpers.stringCompare(a.Name, b.Name))
      .forEach((fluid, index) => {
        const fluidRheologies = rheologies.filter((rheology) => rheology.FluidId === fluid.Id);
        const fluidRheometers = rheometers.filter((rheometer) => rheometer.FluidId === fluid.Id);
        const fluidRheometerIds = fluidRheometers.map((rheometer) => rheometer.Id);
        const fluidRheometerReadings = rheometerReadings.filter((rheometerReading) =>
          fluidRheometerIds.includes(rheometerReading.RheometerId),
        );
        if (!fluidRheologies.length && !fluidRheometerReadings.length) {
          throw new Error('Missing common fluid rheologies or rheometer readings for fluid ' + level + ' ' + fluid.Name);
        }
        rowArray.push(this.createRowFromDto(fluid, fluidRheologies, fluidRheometers, fluidRheometerReadings, level, currentCount + index));
      });
  }

  public static createRowFromDto(
    rowDto: FluidDto,
    rheologies: RheologyDto[],
    rheometers: RheometerDto[],
    rheometerReadings: RheometerReadingDto[],
    level: CommonDbType,
    rowIndex: number,
    isEditingDisabled = true,
    rowType: GridRowType = 'data',
  ): ITableRow<CommonFluidModel> {
    if (rowIndex === 0) {
      rowType = 'first-row';
    }
    return createTableRow<CommonFluidModel>(
      this.createCommonFluid(rowDto, rheologies, rheometers, rheometerReadings, level),
      rowType,
      rowIndex,
      isEditingDisabled,
    );
  }

  public static createCommonFluid(
    commonFluidDto: FluidDto,
    rheologies: RheologyDto[],
    rheometers: RheometerDto[],
    rheometerReadings: RheometerReadingDto[],
    level: CommonDbType,
  ): CommonFluidModel {
    const commonFluid: CommonFluidModel = {
      ...commonFluidDto,
      commonDbType: level,
      Rheologies: rheologies.map((rl) => RheologyFactory.createRheology(rl)),
      Rheometers: rheometers.map((rm) => RheometerFactory.createRheometer(rm)),
      RheometerReadings: rheometerReadings,
      ViscositySurface: 0,
      ViscosityBHCT: 0,
      TypeName: FluidType[commonFluidDto.Type].replace('_', ' '),
    };
    return commonFluid;
  }
}
