import { IFile } from '@dunefront/common/dto/file.dto';
import { ActivatedRoute, Navigation, Router } from '@angular/router';
import { NewProjectDialogComponent } from './action-dialogs/open-new-shared-demo.dialog/new-project.dialog.component';
import { RestoreDialogComponent } from './action-dialogs/restore-dialog/restore.dialog.component';
import { ModalService } from '../../common-modules/modals/modal.service';
import { LicenseFeature } from '@dunefront/common/modules/licensing/licensing.interfaces';
import { Base64, toBase64 } from 'js-base64';
import {
  RouteModuleAdmin,
  RouteModuleAdminFileManagement,
  RouteModuleAdminUser,
} from '@dunefront/common/common/routes-names/admin-routes-names';
import { RouteModuleFile, RouteModuleHome } from './home-page-routes-names';

export class FileUrlHelper {
  public static getPersonalFileFromUrlParam(urlParam: string): IFile {
    const path: string[] = [];
    urlParam.split('|').forEach((part) => {
      path.push(decodeURIComponent(part));
    });
    const Name = path[path.length - 1];
    const Folder: string[] = path.slice(0, path.length - 1);
    const file: IFile = { FileType: 'ppf-file', Folder, Name, Repository: 'personal' };
    return file;
  }

  public static getElectronFileFromUrlParam(urlParam: string): IFile {
    return {
      Name: Base64.fromBase64(urlParam),
      FileType: 'type-in-filename',
      Folder: [],
      Repository: 'electron',
    };
  }

  public static getFolderPathFromUrlParam(urlParam: string): string[] {
    const path: string[] = [];
    urlParam.split('|').forEach((part) => {
      path.push(decodeURIComponent(part));
    });
    return path;
  }

  public static getLicenseFeatureFromActivatedRoute(route: ActivatedRoute): LicenseFeature | undefined {
    const feature = route.snapshot.queryParams?.licenseFeature ?? route.snapshot.children[0]?.data?.licenseFeature;
    return feature ? +feature : undefined;
  }

  public static getLicenseFeatureFromUrlParamSegments(navigation: Navigation | null): LicenseFeature {
    const urlSegments = navigation?.finalUrl?.root.children.primary.segments ?? [];
    const moduleSegment = urlSegments[4];
    switch (moduleSegment.path) {
      case AppModuleUrlSegment.simulate:
        return LicenseFeature.Simulate;
      case AppModuleUrlSegment.evaluate:
        return LicenseFeature.Evaluate;
      case AppModuleUrlSegment.trendAnalysis:
        return LicenseFeature.Trend_Analysis;
      case AppModuleUrlSegment.calculators:
        return LicenseFeature.Calculators;
      case AppModuleUrlSegment.psdAnalysis:
        return LicenseFeature.PSD_Analysis;
      default:
        return LicenseFeature.Default;
    }
  }

  private static getModulePath(feature: LicenseFeature): string {
    switch (feature) {
      case LicenseFeature.Simulate:
        return AppModuleUrlSegment.simulate;
      case LicenseFeature.Evaluate:
        return AppModuleUrlSegment.evaluate;
      case LicenseFeature.Trend_Analysis:
        return AppModuleUrlSegment.trendAnalysis;
      case LicenseFeature.Calculators:
        return AppModuleUrlSegment.calculators;
      case LicenseFeature.PSD_Analysis:
        return AppModuleUrlSegment.psdAnalysis;
      case LicenseFeature.Data_Analysis:
        return AppModuleUrlSegment.dataAnalysis;
      case LicenseFeature.Simulate_CH:
        return AppModuleUrlSegment.simulate_ch;
      case LicenseFeature.Simulate_Disp:
        return AppModuleUrlSegment.simulate_disp;
      default:
        return AppModuleUrlSegment.simulate;
    }
  }

  public static scenarioNavigate(file: IFile, scenarioId: number, rangeId: number, feature: LicenseFeature, router: Router): void {
    const routerLink = this.getFileLink(file) + `/${scenarioId}/${rangeId}/${this.getModulePath(feature)}`;
    router.navigate([routerLink]);
    router.navigate([routerLink]).then();
  }

  public static fileNavigate(
    file: IFile,
    router: Router,
    modalService: ModalService,
    licenseFeature?: LicenseFeature,
    isAdminPage = false,
  ): void {
    const routerLink = this.getFileLink(file, isAdminPage);
    if (routerLink) {
      router.navigate([routerLink], { queryParams: licenseFeature != null ? { licenseFeature } : {} }).then();
      return;
    }

    if (file.Repository !== 'trash') {
      modalService.open(NewProjectDialogComponent, { file }, '', 'sm');
    } else {
      modalService.open(RestoreDialogComponent, { file }, '', 'sm');
    }
  }

  public static getFileLink(file: IFile, isAdminPage = false): string | undefined {
    if (file.Repository === 'electron') {
      return `/${RouteModuleFile}/${toBase64(file.Name, true)}`;
    }

    let encodedPath = '';
    for (const folder of file.Folder) {
      encodedPath += encodeURIComponent(folder) + '|';
    }
    encodedPath += encodeURIComponent(file.Name);

    if (file.FileType === 'ppf-file') {
      if (file.Repository === 'personal') {
        return `${RouteModuleFile}/${encodedPath}`;
      } else {
        return undefined;
      }
    } else {
      return isAdminPage
        ? `/${RouteModuleAdmin}/${RouteModuleAdminFileManagement}/${RouteModuleAdminUser}/${encodedPath}`
        : `/${RouteModuleHome}/${file.Repository}/${encodedPath}`;
    }
  }

  public static getFileRepositoryFromUrl(url: string): string {
    const urlArr = url.split('/');
    return urlArr.includes('admin') ? urlArr[3] : urlArr[2];
  }

  public static isAdmin(url: string): boolean {
    const urlArr = url.split('/');
    return urlArr.includes('admin');
  }
}

export enum AppModuleUrlSegment {
  simulate = 'simulate',
  evaluate = 'evaluate',
  trendAnalysis = 'trend-analysis',
  calculators = 'calculators',
  psdAnalysis = 'psd-analysis',
  dataAnalysis = 'data-pro',
  simulate_ch = 'simulate-ch',
  simulate_disp = 'simulate-disp',
}
