<ng-container *ngIf="isGravelModuleLoaded$ | async">
  <ng-container *ngIf="currentGravel">
    <app-gravel-header (click)="onHelpChange('gravels', 'gravel-properties')"></app-gravel-header>

    <app-page-panel header="Gravel Properties" (click)="onHelpChange('gravels', 'gravel-properties')"
                    class="gravel-props-panel">
      <app-gravel-properties *ngIf="currentGravel" [gravel]="currentGravel"></app-gravel-properties>
    </app-page-panel>
  </ng-container>

  <div class="buttons-panel">
    <div class="align-left">
      <button
        appButton
        data-cy="gravel-to-database"
        type="button"
        class="btn btn-primary btn-sm"
        [isUiLockable]="false"
        (buttonClick)="saveToDatabase()"
      >
        Save to Database
      </button>
    </div>
    <div class="align-right">
      <button appButton data-cy="new-gravel" type="button" class="btn btn-primary btn-sm" (buttonClick)="newGravel()">
        New Gravel
      </button>
      <button appButton data-cy="copy-gravel" type="button" class="btn btn-primary btn-sm" (buttonClick)="copyGravel()">
        Copy Gravel
      </button>
      <button appButton data-cy="delete-gravel" type="button" class="btn btn-primary btn-sm"
              (buttonClick)="deleteGravel()">
        Delete Gravel
      </button>
    </div>
  </div>
</ng-container>
