import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { Store } from '@ngrx/store';
import { cloneDeep, isArray, uniq } from 'lodash';
import { ModalService } from '../../modal.service';
import { DbDependentComponent } from '../../../db-connection/db-dependent.component';
import { getCurrentScenario } from '../../../../+store/scenario/scenario.selectors';
import { EntityToCopy } from '@dunefront/common/modules/scenario/scenario.dto';
import { getSourceReportingTabs } from '../../../../+store/reporting/reporting.actions';
import { getSourceReportingTabDtos } from '../../../../+store/reporting/reporting.selectors';
import { RangeConstants } from '@dunefront/common/dto/range.dto';
import { isDefined } from '@dunefront/common/common/state.helpers';
import { cloneScenarioAction } from '../../../../+store/scenario/scenario.actions';

export interface IDataToCloneType {
  entity: EntityToCopy;
  id?: number;
}

@Component({
  selector: 'app-scenario-manager-clone-operations',
  templateUrl: './scenario-manager-clone-operations.component.html',
  styleUrls: ['./scenario-manager-clone-operations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScenarioManagerCloneOperationsComponent extends DbDependentComponent {
  public treeNodes: TreeNode<IDataToCloneType>[] = [
    {
      key: '0',
      label: 'PSD',
      type: 'checkbox-wide',
      expanded: true,
      data: { entity: EntityToCopy.Default },
      children: [
        {
          key: '0-0',
          label: 'PSD Input & Analysis',
          type: 'checkbox',
          data: { entity: EntityToCopy.PSDAnalysis },
        },
      ],
    },
    {
      key: '1',
      label: 'Well',
      type: 'checkbox-wide',
      expanded: true,
      data: { entity: EntityToCopy.Default },
      children: [
        { key: '1-0', label: 'General Data', type: 'checkbox', data: { entity: EntityToCopy.GeneralData } },
        { key: '1-1', label: 'Casing Data', type: 'checkbox', data: { entity: EntityToCopy.CasingData } },
        { key: '1-2', label: 'Reservoir Data', type: 'checkbox', data: { entity: EntityToCopy.ReservoirData } },
        { key: '1-3', label: 'Caliper Data', type: 'checkbox', data: { entity: EntityToCopy.CaliperData } },
      ],
    },
    {
      key: '2',
      label: 'Completion',
      type: 'checkbox-wide',
      expanded: true,
      data: { entity: EntityToCopy.Default },
      children: [
        { key: '2-0', label: 'Lower Completion', type: 'checkbox', data: { entity: EntityToCopy.LowerCompletion } },
        { key: '2-1', label: 'Running String', type: 'checkbox', data: { entity: EntityToCopy.RunningString } },
        { key: '2-2', label: 'Shunt Tubes', type: 'checkbox', data: { entity: EntityToCopy.ShuntTube } },
        { key: '2-3', label: 'Volumes', type: 'checkbox', data: { entity: EntityToCopy.Volumes } },
      ],
    },
    {
      key: '3',
      label: 'Fluid',
      type: 'checkbox-wide',
      expanded: true,
      data: { entity: EntityToCopy.Default },
      children: [{ key: '3-0', label: 'Project Fluid', type: 'checkbox', data: { entity: EntityToCopy.ProjectFluid } }],
    },
    {
      key: '4',
      label: 'Gravel',
      type: 'checkbox-wide',
      expanded: true,
      data: { entity: EntityToCopy.Default },
      children: [
        {
          key: '4-0',
          label: 'Project Gravel',
          type: 'checkbox',
          data: { entity: EntityToCopy.ProjectGravel },
        },
      ],
    },
    {
      key: '5',
      label: 'Pumping (Simulate)',
      type: 'checkbox-wide',
      expanded: true,
      data: { entity: EntityToCopy.Default },
      children: [
        { key: '5-0', label: 'Initial Data', type: 'checkbox', data: { entity: EntityToCopy.InitialData } },
        { key: '5-1', label: 'Well Fluids', type: 'checkbox', data: { entity: EntityToCopy.WellFluids } },
        { key: '5-2', label: 'Schedule', type: 'checkbox', data: { entity: EntityToCopy.Schedule } },
        { key: '5-3', label: 'Schedule Options', type: 'checkbox', data: { entity: EntityToCopy.ScheduleOptions } },
      ],
    },
    {
      key: '6',
      label: 'Settings',
      type: 'checkbox-wide',
      expanded: true,
      data: { entity: EntityToCopy.Default },
      children: [
        {
          key: '6-0',
          label: 'Project',
          type: 'checkbox',
          data: { entity: EntityToCopy.EngineOptions },
        },
      ],
    },
    {
      key: '7',
      label: 'Calculators',
      type: 'checkbox-wide',
      expanded: true,
      data: { entity: EntityToCopy.Default },
      children: [
        { key: '7-0', label: 'Friction', type: 'checkbox', data: { entity: EntityToCopy.FrictionCalculator } },
        { key: '7-1', label: 'Settling', type: 'checkbox', data: { entity: EntityToCopy.SettlingCalculator } },
        { key: '7-2', label: 'Resuspension', type: 'checkbox', data: { entity: EntityToCopy.ResuspensionCalculator } },
        { key: '7-3', label: 'MASP', type: 'checkbox', data: { entity: EntityToCopy.MaspCalculator } },
        {
          key: '7-4',
          label: 'Injection Test',
          type: 'checkbox',
          data: { entity: EntityToCopy.InjectionTestCalculator },
        },
        {
          key: '7-5',
          label: 'Leakoff Coefficient',
          type: 'checkbox',
          data: { entity: EntityToCopy.LeakoffCoefficientCalculator },
        },
      ],
    },
    {
      key: '8',
      label: 'Reporting (Simulate)',
      type: 'checkbox-wide',
      expanded: true,
      data: { entity: EntityToCopy.Default },
      children: [{ key: '8-0', label: 'Report', type: 'checkbox', data: { entity: EntityToCopy.Report } }],
    },
  ];
  public selectedTreeNodes: TreeNode<IDataToCloneType>[] = [];
  public sourceScenarioId!: number;
  public targetScenarioIds: number[] = [];

  constructor(
    store: Store,
    cdRef: ChangeDetectorRef,
    private modalService: ModalService,
  ) {
    super(store, cdRef);
    this.subscription.add(
      this.store.select(getCurrentScenario).subscribe((currentScenario) => {
        if (currentScenario) {
          this.sourceScenarioId = currentScenario.Id;
          this.store.dispatch(getSourceReportingTabs({ scenarioId: this.sourceScenarioId }));
        }
        this.cdRef.markForCheck();
      }),
    );
    this.subscription.add(
      this.store.select(getSourceReportingTabDtos).subscribe((sourceReportingTabs) => {
        const newTreeNodes = cloneDeep(this.treeNodes);
        newTreeNodes?.[8].children?.splice(
          1,
          Infinity,
          ...sourceReportingTabs
            .filter((tab) => tab.RangeId === RangeConstants.EmptyRangeId)
            .map((tab, index) => ({
              key: '8-' + (index + 1), // +1 as 8-0 already used for Report
              label: tab.TabName,
              type: 'checkbox',
              data: { entity: EntityToCopy.Charts, id: tab.ChartId },
            })),
        );
        this.treeNodes = newTreeNodes;
        this.cdRef.markForCheck();
      }),
    );
  }

  public onSelectionChange = (newSelection: TreeNode<IDataToCloneType> | TreeNode<IDataToCloneType>[] | null): void => {
    if (newSelection == null || !isArray(newSelection)) {
      return;
    }
    this.selectedTreeNodes = newSelection;
  };

  public onCloneClicked(): void {
    if (!this.dbInfo) {
      return;
    }
    const entitiesToCopy = uniq(
      this.selectedTreeNodes
        .filter((node) => node.data?.entity !== EntityToCopy.Default)
        .map((node) => node.data?.entity)
        .filter(isDefined),
    );
    const chartsToCopy = this.selectedTreeNodes.map((node) => node.data?.id).filter(isDefined);
    this.store.dispatch(
      cloneScenarioAction({
        sourceScenarioId: this.sourceScenarioId,
        targetScenarioIds: this.targetScenarioIds,
        entitiesToCopy,
        chartsToCopy,
        fileHash: this.dbInfo.fileHash,
        currentScenarioId: this.currentScenarioId,
      }),
    );
  }

  public updateSourceScenarioId(selectedScenarioId: number): void {
    this.sourceScenarioId = selectedScenarioId;
    this.targetScenarioIds = [];
    this.selectedTreeNodes = this.selectedTreeNodes.filter((node) => !node.key?.startsWith('8'));
    this.store.dispatch(getSourceReportingTabs({ scenarioId: this.sourceScenarioId }));
  }

  public updateSelectedIds(selectId: { id: number; value: boolean }): void {
    if (selectId.value) {
      this.targetScenarioIds = [...this.targetScenarioIds, selectId.id];
    } else {
      this.removeSelectedId(selectId.id);
    }
  }

  public removeSelectedId(selectedId: number): void {
    const index = this.targetScenarioIds.indexOf(selectedId);
    this.targetScenarioIds = [...this.targetScenarioIds.slice(0, index), ...this.targetScenarioIds.slice(index + 1)];
  }
}
