import { GlobalOptionsDto } from '@dunefront/common/dto/global-options.dto';
import { IMarkerStyle } from '@dunefront/common/modules/reporting/dto/chart-marker.dto';
import { ILegendStyle } from '@dunefront/common/modules/reporting/dto/chart-legend';
import { IGlobalSeriesStyles } from '@dunefront/common/modules/reporting/dto/chart-series.dto';
import { IAnnotationStyle } from '@dunefront/common/modules/reporting/dto/chart-annotation.dto';
import { ChartCopyOptionsDto } from '@dunefront/common/modules/reporting/dto/chart-copy-options.dto';
import { IGradientLineStyle } from '@dunefront/common/modules/reporting/dto/chart-gradient-line.dto';
import { changeProp, ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';

export class CommonDbPropertiesHelper {
  public static changeGlobalOptionsProperty(
    changedProp: ObjectChangeProp<GlobalOptionsDto>,
    globalOptionsToUpdate: GlobalOptionsDto,
    parentGlobalOptions: GlobalOptionsDto | undefined,
    defaultGlobalOptions: GlobalOptionsDto,
  ): GlobalOptionsDto {
    const updateGlobalOptions = changeProp(globalOptionsToUpdate, changedProp);

    if (changedProp.key === 'IsOverrideChartMarker' && changedProp.value != null) {
      if (parentGlobalOptions?.IsOverrideChartMarker) {
        CommonDbPropertiesHelper.applyChartMarkerOptions(updateGlobalOptions, parentGlobalOptions);
      } else {
        CommonDbPropertiesHelper.applyChartMarkerOptions(updateGlobalOptions, defaultGlobalOptions);
      }
    }

    if (changedProp.key === 'IsOverrideAnnotationStyle' && changedProp.value != null) {
      if (parentGlobalOptions?.IsOverrideAnnotationStyle) {
        CommonDbPropertiesHelper.applyCharAnnotationStyleOptions(updateGlobalOptions, parentGlobalOptions);
      } else {
        CommonDbPropertiesHelper.applyCharAnnotationStyleOptions(updateGlobalOptions, defaultGlobalOptions);
      }
    }

    if (changedProp.key === 'IsOverrideGradientLineStyle' && changedProp.value != null) {
      if (parentGlobalOptions?.IsOverrideGradientLineStyle) {
        CommonDbPropertiesHelper.applyCharGradientLineStyleOptions(updateGlobalOptions, parentGlobalOptions);
      } else {
        CommonDbPropertiesHelper.applyCharGradientLineStyleOptions(updateGlobalOptions, defaultGlobalOptions);
      }
    }

    if (changedProp.key === 'IsOverrideChartPerformance' && changedProp.value != null) {
      if (parentGlobalOptions?.IsOverrideChartPerformance) {
        CommonDbPropertiesHelper.applyChartPerformanceOptions(updateGlobalOptions, parentGlobalOptions);
      } else {
        CommonDbPropertiesHelper.applyChartPerformanceOptions(updateGlobalOptions, defaultGlobalOptions);
      }
    }

    if (changedProp.key === 'IsOverrideVideoPerformance' && changedProp.value != null) {
      if (parentGlobalOptions?.IsOverrideVideoPerformance) {
        CommonDbPropertiesHelper.applyVideoPerformanceOptions(updateGlobalOptions, parentGlobalOptions);
      } else {
        CommonDbPropertiesHelper.applyVideoPerformanceOptions(updateGlobalOptions, defaultGlobalOptions);
      }
    }

    if (changedProp.key === 'IsOverrideChartAxis' && changedProp.value != null) {
      if (parentGlobalOptions?.IsOverrideChartAxis) {
        CommonDbPropertiesHelper.applyCharAxisStyleOptions(updateGlobalOptions, parentGlobalOptions);
      } else {
        CommonDbPropertiesHelper.applyCharAxisStyleOptions(updateGlobalOptions, defaultGlobalOptions);
      }
    }

    if (changedProp.key === 'IsOverrideLegend' && changedProp.value != null) {
      if (parentGlobalOptions?.IsOverrideLegend) {
        CommonDbPropertiesHelper.applyLegendStyleOptions(updateGlobalOptions, parentGlobalOptions);
      } else {
        CommonDbPropertiesHelper.applyLegendStyleOptions(updateGlobalOptions, defaultGlobalOptions);
      }
    }

    if (changedProp.key === 'IsOverrideSeries' && changedProp.value != null) {
      if (parentGlobalOptions?.IsOverrideSeries) {
        CommonDbPropertiesHelper.applySeriesStyleOptions(updateGlobalOptions, parentGlobalOptions);
      } else {
        CommonDbPropertiesHelper.applySeriesStyleOptions(updateGlobalOptions, defaultGlobalOptions);
      }
    }

    if (changedProp.key === 'IsOverrideCopyChartOptions' && changedProp.value != null) {
      if (parentGlobalOptions?.IsOverrideCopyChartOptions) {
        CommonDbPropertiesHelper.applyCopyChartOptions(updateGlobalOptions, parentGlobalOptions);
      } else {
        CommonDbPropertiesHelper.applyCopyChartOptions(updateGlobalOptions, defaultGlobalOptions);
      }
    }

    return updateGlobalOptions;
  }

  public static applyChartMarkerOptions(target: IMarkerStyle, source: IMarkerStyle): void {
    target.IsChartMarkerFontBold = source.IsChartMarkerFontBold;
    target.ChartMarkerFontColor = source.ChartMarkerFontColor;
    target.IsChartMarkerFontItalic = source.IsChartMarkerFontItalic;
    target.ChartMarkerFontSize = source.ChartMarkerFontSize;
    target.ChartMarkerHorizontalTextAlignment = source.ChartMarkerHorizontalTextAlignment;
    target.ChartMarkerHorizontalTextIndentation = source.ChartMarkerHorizontalTextIndentation;
    target.ChartMarkerLineColor = source.ChartMarkerLineColor;
    target.ChartMarkerLineStyle = source.ChartMarkerLineStyle;
    target.ChartMarkerLineThickness = source.ChartMarkerLineThickness;
    target.ChartMarkerVerticalTextAlignment = source.ChartMarkerVerticalTextAlignment;
    target.ChartMarkerVerticalTextIndentation = source.ChartMarkerVerticalTextIndentation;
  }

  public static applyCharAnnotationStyleOptions(target: IAnnotationStyle, source: IAnnotationStyle): void {
    target.AnnotationFontSize = source.AnnotationFontSize;
    target.AnnotationFontBold = source.AnnotationFontBold;
    target.AnnotationFontItalic = source.AnnotationFontItalic;
    target.AnnotationFontColour = source.AnnotationFontColour;
    target.AnnotationFillColour = source.AnnotationFillColour;
    target.AnnotationFillTransparency = source.AnnotationFillTransparency;
    target.AnnotationTailVisibility = source.AnnotationTailVisibility;
  }

  public static applyCharGradientLineStyleOptions(target: IGradientLineStyle, source: IGradientLineStyle): void {
    target.GradientLineStyle = source.GradientLineStyle;
    target.GradientLineThickness = source.GradientLineThickness;
    target.GradientLineColor = source.GradientLineColor;
    target.GradientLineDecimalPlaces = source.GradientLineDecimalPlaces;
  }

  public static applyChartPerformanceOptions(target: GlobalOptionsDto, source: GlobalOptionsDto): void {
    target.EngineRealTimeUpdateInterval = source.EngineRealTimeUpdateInterval;
    target.ChartSliderUpdateInterval = source.ChartSliderUpdateInterval;
    target.MaxChartPoints = source.MaxChartPoints;
    target.ImportFileMaxTimespan = source.ImportFileMaxTimespan;
  }

  public static applyVideoPerformanceOptions(target: GlobalOptionsDto, source: GlobalOptionsDto): void {
    target.VideoSteps = source.VideoSteps;
    target.VideoFramerate = source.VideoFramerate;
  }

  public static applyCharAxisStyleOptions(target: GlobalOptionsDto, source: GlobalOptionsDto): void {
    target.AxisTitleFontSize = source.AxisTitleFontSize;
    target.AxisTitleFontColor = source.AxisTitleFontColor;
    target.AxisTitleFontBold = source.AxisTitleFontBold;
    target.AxisTitleFontItalic = source.AxisTitleFontItalic;
    target.AxisLabelFontSize = source.AxisLabelFontSize;
    target.AxisLabelFontColor = source.AxisLabelFontColor;
    target.AxisLabelFontBold = source.AxisLabelFontBold;
    target.AxisLabelFontItalic = source.AxisLabelFontItalic;

    target.XAxisStartMarginPercent = source.XAxisStartMarginPercent;
    target.XAxisEndMarginPercent = source.XAxisEndMarginPercent;
    target.XAxisNoMarginWhenStartingNearZero = source.XAxisNoMarginWhenStartingNearZero;

    target.YAxisStartMarginPercent = source.YAxisStartMarginPercent;
    target.YAxisEndMarginPercent = source.YAxisEndMarginPercent;
    target.YAxisNoMarginWhenStartingNearZero = source.YAxisNoMarginWhenStartingNearZero;
  }

  public static applyLegendStyleOptions(target: ILegendStyle, source: ILegendStyle): void {
    target.IsLegendFontBold = source.IsLegendFontBold;
    target.IsLegendFontItalic = source.IsLegendFontItalic;
    target.LegendFontSize = source.LegendFontSize;
    target.LegendLocation = source.LegendLocation;
    target.LegendFontColor = source.LegendFontColor;
  }

  public static applySeriesStyleOptions(target: IGlobalSeriesStyles, source: IGlobalSeriesStyles): void {
    target.SeriesColours = source.SeriesColours;
    target.SeriesLineStyle = source.SeriesLineStyle;
    target.SeriesLineThickness = source.SeriesLineThickness;
    target.SeriesPointMarkersShape = source.SeriesPointMarkersShape;
    target.SeriesPointMarkersEnabled = source.SeriesPointMarkersEnabled;
    target.SeriesPointMarkersSize = source.SeriesPointMarkersSize;
    target.PumpRateColor = source.PumpRateColor;
    target.PumpPressureColor = source.PumpPressureColor;
    target.GravelConcColor = source.GravelConcColor;
    target.ReturnRateColor = source.ReturnRateColor;
  }

  public static applyCopyChartOptions(target: ChartCopyOptionsDto, source: ChartCopyOptionsDto): void {
    target.CopyChartScaling = source.CopyChartScaling;
    target.CopyChartRatio = source.CopyChartRatio;
  }

  public static applyFileOptions(target: GlobalOptionsDto, source: GlobalOptionsDto): void {
    target.UseTemporaryProjectFile = source.UseTemporaryProjectFile;
  }
}
