import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';
import { Fluid } from '@dunefront/common/modules/fluid/model/fluid';
import * as actions from '../../../../+store/fluid/fluid.actions';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { FluidType } from '@dunefront/common/modules/fluid/dto/fluid.dto';
import { notEmpty } from '@dunefront/common/common/state.helpers';
import { DbDependentComponent } from '../../../../common-modules/db-connection/db-dependent.component';
import { getSelectedFluidData } from '../../../../+store/fluid/fluid.selectors';

@Component({
  selector: 'app-fluid-advanced-properties',
  templateUrl: 'fluid-advanced-properties.component.html',
  styleUrls: ['fluid-advanced-properties.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FluidAdvancedPropertiesComponent extends DbDependentComponent {
  public fluid: Fluid | undefined;
  public UnitType = UnitSystem;
  public FluidType = FluidType;

  constructor(store: Store, cdRef: ChangeDetectorRef) {
    super(store, cdRef);
    this.onHelpChange('fluids', 'fluid-advanced-properties');

    this.subscription.add(
      notEmpty(this.store.select(getSelectedFluidData)).subscribe((data) => {
        this.fluid = data.fluid;
        this.cdRef.markForCheck();
      }),
    );
  }

  public async emitValue(props: ObjectChangeProp<Fluid>): Promise<void> {
    if (!this.fluid) {
      return;
    }

    this.store.dispatch(
      actions.updateFluid({
        changedProp: props,
        fluidId: this.fluid.Id,
        shouldSaveRheologies: false,
      }),
    );
  }
}
