import { PipeType } from '../../../dto/pipe.dto';
import { GaugeMeasurementType, RunningStringPipeDto } from '../../../dto/running-string-pipe.dto';
import { createTableRow, GridRowType, ITableRow } from '../../../common/common-grid.interfaces';
import { BasePipeFactory } from '../base.pipe.factory';
import {
  ConcentricGaugeCarrierPipe,
  ConcentricGaugeCarrierPipeFactory,
  isConcentricGaugeCarrierPipe,
} from './pipes/concentric-gauge-carrier-pipe';
import { EccentricGaugeCarrierPipeFactory } from './pipes/eccentric-gauge-carrier-pipe';
import { Pipe } from '../pipe';
import { isPressureAttenuatorPipe, PressureAttenuatorPipe, PressureAttenuatorPipeFactory } from './pipes/pressure-attenuator-pipe';
import { isServiceToolPipe, ServiceToolPipe, ServiceToolPipeFactory } from './pipes/service-tool-pipe';
import { WashPipeFactory } from './pipes/wash-pipe';
import { isWorkstringPipe, WorkstringPipe, WorkstringPipeFactory } from './pipes/workstring-pipe';
import { PipeFactory } from '../pipe.factory';
import { GaugeCarrierPipe, isGaugeCarrierPipe } from './pipes/gauge-carrier-pipe';

export class RunningStringPipesFactory {
  public static createFromRunningString(runningStringDto: RunningStringPipeDto): Pipe {
    switch (runningStringDto.PipeType) {
      case PipeType.Eccentric_Gauge_Carrier:
        return EccentricGaugeCarrierPipeFactory.createFromRunningString(runningStringDto);
      case PipeType.Concentric_Gauge_Carrier:
        return ConcentricGaugeCarrierPipeFactory.createFromRunningString(runningStringDto);
      case PipeType.Washpipe:
        return WashPipeFactory.createFromRunningString(runningStringDto);
      case PipeType.Workstring:
        return WorkstringPipeFactory.createFromRunningString(runningStringDto);
      case PipeType.Service_Tool:
        return ServiceToolPipeFactory.createFromRunningString(runningStringDto);
      case PipeType.Pressure_Attenuator:
        return PressureAttenuatorPipeFactory.createFromRunningString(runningStringDto);
      default:
        return BasePipeFactory.createFromDto(runningStringDto);
    }
  }

  public static createRowListFromDtos(pipeDtoArray: RunningStringPipeDto[], scenarioId: number): ITableRow<Pipe>[] {
    const rows = pipeDtoArray.map((dto, index) => this.createElement(dto, index));
    const insertRow = PipeFactory.createEmptyRow(scenarioId, rows.length, false, 'insert-row');
    return [...rows, insertRow];
  }

  public static createElement(
    pipeDto: RunningStringPipeDto,
    rowIndex: number,
    isEditingDisabled = false,
    rowType: GridRowType = 'data',
  ): ITableRow<Pipe> {
    const pipe = this.createFromRunningString(pipeDto);
    return createTableRow<Pipe>(pipe, rowType, rowIndex, isEditingDisabled);
  }

  public static toDto(pipe: Pipe): RunningStringPipeDto {
    const dto: RunningStringPipeDto = {
      ...PipeFactory.toDto(pipe),
      ActivationPressure: 0,
      DownFlowAnnulusID: 0,
      DownFlowAnnulusLength: 0,
      DownFlowAnnulusOD: 0,
      DownFlowNoOfPorts: 0,
      DownFlowPortDiameter: 0,
      DownFlowPortLength: 0,
      UpFlowAnnulusID: 0,
      UpFlowAnnulusLength: 0,
      UpFlowAnnulusOD: 0,
      UpFlowNoOfPorts: 0,
      UpFlowPortDiameter: 0,
      UpFlowPortLength: 0,
      GaugeMeasurementType: GaugeMeasurementType.None,
      IsDownFlowPresent: false,
      IsUpFlowPresent: false,
      ToolJointInnerDiameter: 0,
      ToolJointLength: 0,
      ToolJointOuterDiameter: 0,
      NoOfPorts: 0,
      PortDiameter: 0,
      PortLength: 0,
      SingleJointLength: 0,
      Description: pipe.Description,
    };

    if (isGaugeCarrierPipe(pipe) && pipe.GaugeMeasurementType !== undefined) {
      dto.GaugeMeasurementType = pipe.GaugeMeasurementType;
    }
    if (isConcentricGaugeCarrierPipe(pipe)) {
      dto.NoOfPorts = pipe.FlowPorts?.NoOfPorts ?? 0;
      dto.PortDiameter = pipe.FlowPorts?.PortDiameter ?? 0;
      dto.PortLength = pipe.FlowPorts?.PortLength ?? 0;
    }
    if (isWorkstringPipe(pipe)) {
      dto.ToolJointInnerDiameter = pipe.ToolJoint?.InnerDiameter ?? 0;
      dto.ToolJointOuterDiameter = pipe.ToolJoint?.OuterDiameter ?? 0;
      dto.SingleJointLength = pipe.ToolJoint?.SingleJointLength ?? 0;
      dto.ToolJointLength = pipe.ToolJoint?.ToolJointLength ?? 0;
    }
    if (isServiceToolPipe(pipe)) {
      dto.IsDownFlowPresent = pipe.IsDownFlowPresent ?? false;
      dto.IsUpFlowPresent = pipe.IsUpFlowPresent ?? false;

      dto.DownFlowNoOfPorts = pipe.DownFlow?.NoOfPorts ?? 0;
      dto.DownFlowPortDiameter = pipe.DownFlow?.PortDiameter ?? 0;
      dto.DownFlowPortLength = pipe.DownFlow?.PortLength ?? 0;
      dto.DownFlowAnnulusID = pipe.DownFlow?.AnnulusID ?? 0;
      dto.DownFlowAnnulusOD = pipe.DownFlow?.AnnulusOD ?? 0;
      dto.DownFlowAnnulusLength = pipe.DownFlow?.AnnulusLength ?? 0;

      dto.UpFlowNoOfPorts = pipe.UpFlow?.NoOfPorts ?? 0;
      dto.UpFlowPortDiameter = pipe.UpFlow?.PortDiameter ?? 0;
      dto.UpFlowPortLength = pipe.UpFlow?.PortLength ?? 0;
      dto.UpFlowAnnulusID = pipe.UpFlow?.AnnulusID ?? 0;
      dto.UpFlowAnnulusOD = pipe.UpFlow?.AnnulusOD ?? 0;
      dto.UpFlowAnnulusLength = pipe.UpFlow?.AnnulusLength ?? 0;
    }
    if (isPressureAttenuatorPipe(pipe)) {
      dto.ActivationPressure = pipe.ActivationPressure ?? 0;
    }

    return dto;
  }

  public static getEmptyDto(scenarioId: number): RunningStringPipeDto {
    return {
      ActivationPressure: 0,
      DownFlowAnnulusID: 0,
      DownFlowAnnulusLength: 0,
      DownFlowAnnulusOD: 0,
      DownFlowNoOfPorts: 0,
      DownFlowPortDiameter: 0,
      DownFlowPortLength: 0,
      UpFlowAnnulusID: 0,
      UpFlowAnnulusLength: 0,
      UpFlowAnnulusOD: 0,
      UpFlowNoOfPorts: 0,
      UpFlowPortDiameter: 0,
      UpFlowPortLength: 0,
      GaugeMeasurementType: GaugeMeasurementType.None,
      IsDownFlowPresent: false,
      IsUpFlowPresent: false,
      ToolJointInnerDiameter: 0,
      ToolJointLength: 0,
      ToolJointOuterDiameter: 0,
      NoOfPorts: 0,
      PortDiameter: 0,
      PortLength: 0,
      SingleJointLength: 0,
      Description: '',
      Id: -1,
      ScenarioId: scenarioId,
      TopMD: 0,
      BottomMD: 0,
      InnerDiameter: 0,
      OuterDiameter: 0,
      PipeType: PipeType.None,
      Weight: 0,
      SortOrder: 0,
    };
  }

  public static createEmpty(scenarioId: number): Pipe {
    return this.createFromRunningString(this.getEmptyDto(scenarioId));
  }
}

export type RunningStringPipe =
  | Pipe
  | WorkstringPipe
  | ServiceToolPipe
  | ConcentricGaugeCarrierPipe
  | GaugeCarrierPipe
  | PressureAttenuatorPipe;
