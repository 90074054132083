import { IUnitSystemDto, UnitSystem } from '../../../dto/unit-system.dto';
import { UnitConverterHelper } from '../../../unit-converters/unit.converter.helper';
import { stringReplaceLast } from '../../../common/helpers';

const UnitSymbolTemplate = 'unit';
const UnitsDecoderRegexMatcher = /(?:\{{2,3}([^{}]+(?=\}{2,3}))\}{2,3})/g;

export class ConvertUnitPipe {
  public static encode(unitType: UnitSystem, value: number): string {
    return `{{${unitType}:${value}}}`;
  }

  public static encodeUnitSymbol(unitType: UnitSystem): string {
    return `{{${unitType}:${UnitSymbolTemplate}}}`;
  }

  public static encodeValueAndSymbol(unitType: UnitSystem, value: number): IEncodedValueAndSymbol {
    return {
      siValue: value,
      encodedValue: this.encode(unitType, value),
      symbol: this.encodeUnitSymbol(unitType),
    };
  }

  public static decodeUnit(value: string, currentUnitSystem: IUnitSystemDto): string {
    return this.decode(value, currentUnitSystem, 0, false, true);
  }

  public static getUnitSystem(value: string): UnitSystem | undefined {
    const extractedGroups = value.match(UnitsDecoderRegexMatcher);
    if (!extractedGroups || !extractedGroups.length) {
      return undefined;
    }
    const group = extractedGroups[0];
    const [unitTypeString] = this.splitExtractedGroup(group);
    const unitSystem: UnitSystem = +unitTypeString;
    return unitSystem;
  }

  private static splitExtractedGroup(group: string): string[] {
    return group.replace('{{', '').replace('}}', '').split(':');
  }

  public static decode(
    value: string,
    currentUnitSystem: IUnitSystemDto,
    decimalPlaces = 2,
    hideUnitSymbol = false,
    showOnlyUnit = false,
    alwaysShowUnits = false,
  ): string {
    const extractedGroups = value.match(UnitsDecoderRegexMatcher);
    if (!extractedGroups || !extractedGroups.length) {
      return value + '';
    }
    let retValue = value;
    let nextUnitType: string | undefined;
    // iterate though values in reverse order and add symbol for the last value or if next value is different unit
    for (let i = extractedGroups.length - 1; i >= 0; i--) {
      const group = extractedGroups[i];
      const [unitTypeString, unitValue, thisDecimalPlaces] = this.splitExtractedGroup(group);
      const unitSystem: UnitSystem = +unitTypeString;
      const currentUnit = UnitConverterHelper.getCurrentUnit(unitSystem, currentUnitSystem);

      const convertedVal =
        unitValue === UnitSymbolTemplate || showOnlyUnit
          ? ''
          : UnitConverterHelper.getUnitConverter(unitSystem)
              .fromSi(+unitValue, UnitConverterHelper.getCurrentUnit(unitSystem, currentUnitSystem))
              .toFixed(thisDecimalPlaces ? +thisDecimalPlaces : decimalPlaces);

      const unitSymbol = hideUnitSymbol
        ? ''
        : unitTypeString !== nextUnitType || alwaysShowUnits
          ? ' ' + UnitConverterHelper.getUnitConverter(unitSystem).getSymbol(currentUnit)
          : '';

      retValue = stringReplaceLast(retValue, group, convertedVal + unitSymbol);
      nextUnitType = unitTypeString;
    }
    return retValue;
  }

  public transform(value: unknown, currentUnitSystem: IUnitSystemDto | undefined, decimalPlaces = 2): string {
    if (currentUnitSystem && (typeof value === 'string' || value instanceof String)) {
      return ConvertUnitPipe.decode(value + '', currentUnitSystem, decimalPlaces);
    }

    return value + '';
  }
}

export interface IEncodedValueAndSymbol {
  siValue: number;
  encodedValue: string;
  symbol: string;
}
