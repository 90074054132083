import { createSelector } from '@ngrx/store';
import { getSelectedFluidData } from '../../../+store/fluid/fluid.selectors';
import { ILeftNavElement, LeftNavHelpers } from '../../../shared/components/left-nav/left-nav.helpers';
import { RheologyType } from '@dunefront/common/modules/fluid/dto/rheology/rheology.dto';
import {
  RouteModuleFluids,
  RouteModuleFluidsAdvancedProperties,
  RouteModuleFluidsProjectFluids,
  RouteModuleFluidsRheometerData,
} from './fluids-routes-names';
import {
  getAreFluidsWithRheologiesNotUsedInSelectedModuleValid,
  getAreFluidsWithRheologiesUsedInSelectedModuleValid,
  getFluidsUsedInSelectedModule,
} from '../../../+store/fluid/fluid-helper.selectors';
import { Fluid } from '@dunefront/common/modules/fluid/model/fluid';
import { getAppModuleType } from '../../../+store/ui/ui.selectors';
import { isSimulateDisp } from '../../../+store/menu-selectors/menu-selectors.helpers';
import { MenuItem } from 'primeng/api';

export const fluidAdvOptionsValid = (fluid: Fluid): boolean => {
  const advOptionsFields: (keyof Fluid)[] = [
    'ThermalConductivity',
    'SpecificHeat',
    'MinViscosity',
    'MaxViscosity',
    'WashpipeViscousDegradationFactor',
    'AnnulusViscousDegradationFactor',
  ];

  return advOptionsFields.every((key: keyof Fluid) => fluid.error[key]?.length === 0);
};

export const getFluidsLeftNavElement$ = createSelector(
  getSelectedFluidData,
  getAreFluidsWithRheologiesUsedInSelectedModuleValid,
  getAreFluidsWithRheologiesNotUsedInSelectedModuleValid,
  getFluidsUsedInSelectedModule,
  getAppModuleType,
  (data, usedFluidsValid, unusedFluidsValid, usedFluids, moduleType): ILeftNavElement => {
    const projectFluidsHasErrors = !usedFluidsValid;
    const projectFluidsHasWarnings = !unusedFluidsValid || !data?.isShearRateValid;

    const isSelectedFluidUsed = usedFluids.some((fluid) => fluid.Id === data?.fluid.Id);

    const fluidAdvancedPropertiesAvailable = !isSimulateDisp(moduleType);
    const fluidAdvancedPropertiesHasErrors = isSelectedFluidUsed && (data == null || !fluidAdvOptionsValid(data?.fluid));
    const fluidAdvancedPropertiesHasWarnings = !isSelectedFluidUsed && (data == null || !fluidAdvOptionsValid(data?.fluid));

    const rheometerDataHasErrors = !data?.isRheometerStateValid && isSelectedFluidUsed;
    const rheometerDataHasWarnings = !data?.isRheometerStateValid && !isSelectedFluidUsed;

    const items: MenuItem[] = [
      {
        label: 'Project fluids',
        routerLink: `${RouteModuleFluids}/${RouteModuleFluidsProjectFluids}/${data?.fluid.Id}`,
        isError: projectFluidsHasErrors,
        isWarning: projectFluidsHasWarnings,
      },
    ];
    if (fluidAdvancedPropertiesAvailable) {
      items.push({
        label: 'Advanced properties',
        routerLink: `${RouteModuleFluids}/${RouteModuleFluidsAdvancedProperties}/${data?.fluid.Id}`,
        isError: fluidAdvancedPropertiesHasErrors,
        isWarning: fluidAdvancedPropertiesHasWarnings,
      });
    }
    items.push({
      label: 'Rheometer data',
      routerLink: `${RouteModuleFluids}/${RouteModuleFluidsRheometerData}/${data?.fluid.Id}/${data?.selectedTemperatureId}`,
      isError: rheometerDataHasErrors,
      isWarning: rheometerDataHasWarnings,
      disabled: data?.fluid.RheologyType !== RheologyType.Rheometer,
    });
    items.push(LeftNavHelpers.getItem('Database fluids', 'fluids/database-fluids'));

    return LeftNavHelpers.toNavElement({
      label: 'Fluids',
      uri: RouteModuleFluids,
      icon: 'icon-fluid',
      isError: projectFluidsHasErrors || rheometerDataHasErrors,
      isWarning: projectFluidsHasWarnings || rheometerDataHasWarnings,
      items,
    });
  },
);
