<app-page-panel>
  <div class="grid-form-container main">
    <label>Select Data File:</label>
    <app-select
      [items]="filesItems"
      [value]="selectedFileId"
      [isUiLockable]="false"
      selectPlaceholder="Select File"
      (primitiveValueChanged)="changeValue($event.value)"
      class="fluid-selector"
      [width]="400"
      dataCy="select-data-file"
    >
    </app-select>
    <div *ngIf="fileErrorMessage" class="error" data-cy="data-file-selector-error-message">{{ fileErrorMessage }}</div>
  </div>
</app-page-panel>
