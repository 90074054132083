import { Pipe, PipeTransform } from '@angular/core';
import { ITableState } from '@dunefront/common/common/common-grid.interfaces';
import { IIndexedDataType } from '@dunefront/common/dto/common-dto.interfaces';

@Pipe({ name: 'searchTableStatePipe' })
export class SearchTableStatePipe implements PipeTransform {
  public transform<T extends IIndexedDataType>(state: ITableState<any>, phrase: string, key = 'Name'): ITableState<T> {
    // filtering needs to update row indexes (rowIndex) to keep consistent with array indexes
    const rows = state.rows
      .filter((row) => row.rowData[key].toLowerCase().includes(phrase.toLowerCase()))
      .map((row, index) => ({
        ...row,
        rowIndex: index,
      }));

    return {
      ...state,
      rows,
    };
  }
}
