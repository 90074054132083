import { ModuleType } from '../modules/scenario/scenario.dto';

export const removeDuplicatesFromArray = <T>(arr: T[], prop: keyof T): T[] => {
  return arr.filter((obj, pos) => {
    return arr.map((mapObj) => mapObj[prop]).indexOf(obj[prop]) === pos;
  });
};

export function getRandomIntInclusive(min: number, max: number): number {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function toNumber(value: number | string | undefined): number | undefined {
  if (value == null) {
    return undefined;
  }

  const parsedNumber = typeof value === 'string' ? parseFloat(value) : value;

  return isNaN(parsedNumber) ? undefined : parsedNumber;
}

export function shallowClone<T extends object>(obj: T): T {
  return Object.create(Object.getPrototypeOf(obj), Object.getOwnPropertyDescriptors(obj));
}

export const rationalizeString = (str: string | number, strict = false): string =>
  strict ? (str + '').trim().replace('_', ' ') : (str + '').toLowerCase().trim().replace('_', ' ');

export const areStringsTheSame = (str1: string | number, str2: string | number, strict = false): boolean =>
  rationalizeString(str1, strict) === rationalizeString(str2, strict);

export const stringReverse = (str: string): string => {
  return str.split('').reverse().join('');
};

export const stringReplaceLast = (str: string, what: string, replacement: string): string => {
  let newString = stringReverse(str);
  newString = newString.replace(new RegExp(stringReverse(what)), stringReverse(replacement));
  return stringReverse(newString);
};

export function getKeys<T extends object>(obj: T): Array<keyof T> {
  return Object.keys(obj) as Array<keyof T>;
}

export const stringContainsSubstrings = (str: string, substrings: string[]): boolean => {
  return substrings.some((substring) => str.includes(substring));
};

export const isRangeBasedModule = (module: ModuleType): boolean => {
  return module === ModuleType.Evaluate || module === ModuleType.Trend_Analysis || module === ModuleType.Data_Analysis;
};

export const wait = async (time: number): Promise<void> => await new Promise((resolve) => setTimeout(resolve, time));
