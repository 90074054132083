import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { ITableState } from '@dunefront/common/common/common-grid.interfaces';
import { PsdReading } from '@dunefront/common/modules/psd-analysis/model/psd-readings/psd-reading';
import { PSD } from '@dunefront/common/modules/psd-analysis/model/psd/psd';
import { DrawableContentProviderComponent } from '../../../../../shared/services/drawable-registry.service';

@Component({
  templateUrl: './psd-chart-panel-renderer.component.html',
})
export class PsdChartPanelRendererComponent implements DrawableContentProviderComponent {
  @Input() public drawableProviderId?: string;
  @Input() public selectedPSD?: PSD;
  @Input() public selectedPSDReadings?: ITableState<PsdReading>;

  constructor(protected store: Store) {}
}
