/* eslint no-useless-escape: 0 */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Action, Store } from '@ngrx/store';
import { BackendConnectionService } from '../../shared/backend-connection/backend-connection.service';
import { IUndoableAction } from './undo-redo.reducer';
import { catchError, map, take } from 'rxjs/operators';
import { undoRedoAction, UndoRedoActionArg, undoRedoActionClick, undoRedoBusyAction, undoRedoNotBusyAction } from './undo-redo.action';
import { getCanUndoRedo, getCurrentHistoryState } from './undo-redo.selectors';
import { CrudResponse, UndoOrRedo } from '@dunefront/common/modules/common.actions';
import { dataFailed, deleteRowsSuccess, insertRowsSuccess, reorderRowSuccess, updateRowSuccess } from '../app.actions';
import { of } from 'rxjs';
import { WsAction } from '@dunefront/common/ws.action';
import { selectUrl } from '../router/router.selectors';
import { InputsHelperService } from '../../shared/services/inputs-helper.service';
import { RouterHelperService } from '../../shared/services/router-helper.service';
import { copyPumpingBetweenRangesSuccess } from '../scenario/scenario.actions';
import { IErrorState } from '../../shared/components/error/client-error-handler.service';
import { loadReferenceVariablesAction } from '../reference-variables/reference-variables.actions';
import { WsActionResponse } from '@dunefront/common/response-ws.action';

@Injectable()
export class UndoRedoEffects {
  public undoClick$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(undoRedoActionClick),
        concatLatestFrom(() => this.store.select(getCanUndoRedo)),
        map(async ([action, state]) => {
          if (state.isBusy === true || this.undoRedoFilter(action, state.history) === false) {
            return;
          }
          this.store.dispatch(undoRedoBusyAction());
          const presentAction = this.getUndoRedoAction(state.history, action.undoOrRedo);
          if (presentAction == null) {
            return this.store.dispatch(undoRedoNotBusyAction());
          }
          const result = await this.inputsHelperService.checkResultsAndDeleteIfNeeded(presentAction?.shouldDeleteResults ?? false);
          if (result === false) {
            return this.store.dispatch(undoRedoNotBusyAction());
          }
          this.store.dispatch(undoRedoAction(action.undoOrRedo));
        }),
        catchError((err) => of(undoRedoNotBusyAction())),
      ),
    { dispatch: false },
  );
  public undoRedo$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(undoRedoAction),
        concatLatestFrom(() => [this.store.select(getCurrentHistoryState), this.store.select(selectUrl)]),
        map(async ([action, historyState, currentUrl]) => {
          if (this.undoRedoFilter(action, historyState) === false) {
            return this.store.dispatch(undoRedoNotBusyAction());
          }
          const presentAction = this.getUndoRedoAction(historyState, action.undoOrRedo);
          if (presentAction == null) {
            return this.store.dispatch(undoRedoNotBusyAction());
          }
          const actionType = decodeActionType(presentAction);
          if (actionType == null || actionType.type === 'switch') {
            return this.store.dispatch(undoRedoNotBusyAction());
          }
          this.wsService
            .emit<CrudResponse>(actionType.moduleName, actionType.moduleName !== 'CommonDbModule', presentAction)
            .pipe(take(1))
            .subscribe((response) => {
              this.store.dispatch(getSuccessAction(actionType.type, action.undoOrRedo, response));
              this.updateReferenceVariablesIfNeeded(response);
              if (presentAction.url && currentUrl !== presentAction.url) {
                this.routerHelperService.navigateByUrl(presentAction.url).then();
              }
            });
        }),
        catchError((err: IErrorState) => of(dataFailed(err), undoRedoNotBusyAction())),
      ),
    { dispatch: false },
  );

  constructor(
    protected actions$: Actions,
    protected store: Store,
    protected wsService: BackendConnectionService,
    public inputsHelperService: InputsHelperService,
    private routerHelperService: RouterHelperService,
  ) {}

  private undoRedoFilter(undoOrRedo: UndoRedoActionArg, historyState: IUndoableAction): boolean {
    return (
      (undoOrRedo.undoOrRedo === 'undo' && historyState?.undo != null) || (undoOrRedo.undoOrRedo === 'redo' && historyState?.redo != null)
    );
  }

  private getUndoRedoAction(historyState: IUndoableAction, undoOrRedo: UndoOrRedo): WsAction | undefined {
    let action = undoOrRedo === 'undo' ? historyState.undo : historyState.redo;
    if (action) {
      action = { ...action, undoOrRedo };
    }
    return action;
  }

  private updateReferenceVariablesIfNeeded(response: WsActionResponse<CrudResponse>): void {
    const changedPumpingCols = response.payload.affectedRows.pumping?.colIds;
    const changedPumpingScheduleCols = response.payload.affectedRows.pumpingSchedule?.colIds;

    if (
      changedPumpingCols?.includes('BlankPackingPercentageForGravelRequired') ||
      changedPumpingCols?.includes('IsBlankPackingForGravelRequired') ||
      changedPumpingScheduleCols?.includes('GravelId') ||
      changedPumpingScheduleCols?.includes('GravelConcentration') ||
      changedPumpingScheduleCols?.includes('StageVolume') ||
      (response.payload.affectedRows.pumpingSchedule?.rows.length ?? 0) > 0 ||
      (response.payload.affectedRows.pumpingSchedule?.deletedIds.length ?? 0) > 0
    ) {
      this.store.dispatch(loadReferenceVariablesAction({ scenarioId: response.payload.scenarioId }));
    }
  }
}

export const getSuccessAction = (actionType: ActionType, undoOrRedo: UndoOrRedo, response: WsActionResponse<CrudResponse>): Action => {
  const props: CrudResponse = { ...response.payload, undoOrRedo };
  switch (actionType) {
    case 'insert':
      return insertRowsSuccess(props);
    case 'update':
      return updateRowSuccess(props);
    case 'reorder':
      return reorderRowSuccess(props);
    case 'delete':
      return deleteRowsSuccess(props);
    case 'clone':
      return copyPumpingBetweenRangesSuccess(props);
    default:
      throw new Error('Unsupported action type!');
  }
};

const decodeActionType = (action: WsAction): IDecodedActionType | undefined => {
  const matches = action.type.match(/\[([^\]\[\r\n]*)\]/);
  const moduleName = matches && matches.length > 0 ? matches[1] : undefined;
  if (!moduleName) {
    return;
  }

  let type: ActionType;
  if (action.type.includes('Insert')) {
    type = 'insert';
  } else if (action.type.includes('Update')) {
    type = 'update';
  } else if (action.type.includes('Delete')) {
    type = 'delete';
  } else if (action.type.includes('Switch')) {
    type = 'switch';
  } else if (action.type.includes('Reorder')) {
    type = 'reorder';
  } else if (action.type.includes('Clone')) {
    type = 'clone';
  } else {
    return;
  }

  return { moduleName, type };
};

interface IDecodedActionType {
  moduleName: string;
  type: ActionType;
}

type ActionType = 'update' | 'insert' | 'delete' | 'switch' | 'reorder' | 'clone';
