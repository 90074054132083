import { IInsertRowsWsActionProps, IUpdateRowsWsActionProps } from '../../ws.action';
import { ScenarioConstants } from '../../modules/scenario/scenario.dto';
import { InsertLocation } from '../../modules/common.interfaces';
import { ObjectChangeProp } from '../common-state.interfaces';
import { IInsertRowsProps, IUpdateRowsProps, IUpdateTableRowsProps } from '../common-store-crud.interfaces';
import { IScenarioIdBasedEntity } from '../../dto/common-dto.interfaces';
import { AppError } from '../../exceptions/IAppError';

export class WsActionPropsFactory {
  public static insert<DTO>(
    rows: DTO[],
    scenarioId: number,
    shouldResetResults: boolean,
    insertLocation?: InsertLocation,
    refId?: number,
    noOfRowsToDelete?: number,
  ): IInsertRowsWsActionProps<DTO> {
    return {
      rows,
      scenarioId,
      insertLocation,
      refId,
      shouldResetResults,
      noOfRowsToDelete,
    };
  }

  public static insertAction<T extends { ScenarioId: number }, DTO>(
    action: IInsertRowsProps<T>,
    rowConverter: (row: T) => DTO,
  ): IInsertRowsWsActionProps<DTO> {
    const scenarioId = action.rows.length > 0 ? action.rows[0].rowData.ScenarioId : ScenarioConstants.EmptyScenarioId;
    const rows = action.rows.map((row) => rowConverter(row.rowData));
    return this.insert(rows, scenarioId, action.shouldResetResults, action.insertLocation, action.refId, action.noOfRowsToDelete);
  }

  public static insertDto<DTO extends { ScenarioId: number }>(
    dto: DTO,
    shouldResetResults: boolean,
    insertLocation?: InsertLocation,
    refId?: number,
  ): IInsertRowsWsActionProps<DTO> {
    return this.insertDtos([dto], shouldResetResults, insertLocation, refId);
  }

  public static insertDtos<DTO extends IScenarioIdBasedEntity>(
    rows: DTO[],
    shouldResetResults: boolean,
    insertLocation?: InsertLocation,
    refId?: number,
  ): IInsertRowsWsActionProps<DTO> {
    const scenarioId = rows.length ? rows[0].ScenarioId : ScenarioConstants.EmptyScenarioId;
    return this.insert(rows, scenarioId, shouldResetResults, insertLocation, refId);
  }

  public static update<DTO extends IScenarioIdBasedEntity>(
    rows: DTO[],
    shouldResetResults: boolean,
    colIds?: (keyof DTO)[],
    scenarioId?: number,
    isUndoEnabled?: boolean,
  ): IUpdateRowsWsActionProps<DTO> {
    const actionScenarioId = scenarioId != null ? scenarioId : rows.length ? rows[0].ScenarioId : null;
    if (actionScenarioId == null) {
      throw new AppError('Please provide ScenarioId', {});
    }
    return { rows, colIds, shouldResetResults, scenarioId: actionScenarioId, isUndoEnabled };
  }

  public static updateAction<T extends IScenarioIdBasedEntity, DTO extends IScenarioIdBasedEntity>(
    action: IUpdateTableRowsProps<T> | IUpdateRowsProps<T>,
    rowConverter: (row: T) => DTO,
  ): IUpdateRowsWsActionProps<DTO> {
    const colIds = action.colIds as unknown as (keyof DTO)[];

    if (this.isUpdateTableRowsProps(action)) {
      const rows = action.rows.map((row) => rowConverter(row.rowData));
      const scenarioId = rows.length ? rows[0].ScenarioId : action.scenarioId;
      return this.update(rows, action.shouldResetResults, colIds, scenarioId, action.isUndoEnabled);
    } else {
      const rows = action.rows.map((row) => rowConverter(row));
      const scenarioId = rows.length ? rows[0].ScenarioId : action.scenarioId;
      return this.update(rows, action.shouldResetResults, colIds, scenarioId, action.isUndoEnabled);
    }
  }

  public static updateDto<DTO extends IScenarioIdBasedEntity>(dto: DTO, props: ObjectChangeProp<DTO>): IUpdateRowsWsActionProps<DTO> {
    return {
      rows: [dto],
      colIds: [props.key],
      shouldResetResults: props.shouldResetResults,
      scenarioId: dto.ScenarioId,
    };
  }

  private static isUpdateTableRowsProps<T>(action: IUpdateTableRowsProps<T> | IUpdateRowsProps<T>): action is IUpdateTableRowsProps<T> {
    return action.rows.length > 0 && (<IUpdateTableRowsProps<T>>action).rows[0].rowData != null;
  }
}
