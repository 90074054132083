import { WsAction } from '../../ws.action';

export const errorTestingModuleName = 'ErrorTestingModule';

export enum ErrorTestingModuleActionTypes {
  CrashBackend = '[ErrorTesting] CrashBackend',
  CrashCalcEngine = '[ErrorTesting] CrashCalcEngine',
  FreezeBackend = '[ErrorTesting] FreezeBackend',
  PingPong = '[ErrorTesting] PingPong',
}

export type CalcEngineShortWorkerErrorType = 'crash' | 'timeout';

// region backend
export class CrashBackendAction extends WsAction {
  public override readonly type = ErrorTestingModuleActionTypes.CrashBackend;

  constructor(public errorMessage: string) {
    super();
  }
}

export class FreezeBackendAction extends WsAction {
  public override readonly type = ErrorTestingModuleActionTypes.FreezeBackend;
}

// endregion

// ping pong
export class PingPongAction extends WsAction {
  public override readonly type = ErrorTestingModuleActionTypes.PingPong;

  constructor(public inputData: string) {
    super();
  }
}

// endregion

// region calc engine
export class CrashCalcEngineAction extends WsAction {
  public override readonly type = ErrorTestingModuleActionTypes.CrashCalcEngine;

  constructor(
    public ignoreSentry: boolean,
    public errorType: CalcEngineShortWorkerErrorType,
  ) {
    super();
  }
}

// endregion

export type ErrorTestingModuleActions = CrashBackendAction | CrashCalcEngineAction | FreezeBackendAction | PingPongAction;
