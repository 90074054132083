<p>
  <!--  <app-grid [surveyData]="surveyData" [displayedColumns]="displayedColumns">-->
  <!--  </app-grid>-->
</p>
<!-- <p>
  <app-grid-well-fluids [pumping]="pumping"
                        [fluids]="fluids"
                        [gravels]="gravels"
                        [completion]="completion"
                        [isWellFluidSelectionSimple]="pumping?.IsWellFluidSelectionSimple"
  ></app-grid-well-fluids>
</p> -->

<!-- <p>
  <app-grid-group></app-grid-group>
</p> -->
