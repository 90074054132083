import { ChangeDetectorRef, Component } from '@angular/core';
import { DbDependentComponent } from '../../../../common-modules/db-connection/db-dependent.component';
import { Store } from '@ngrx/store';
import { CompletionModuleState } from '@dunefront/common/modules/completion/completion-module.state';
import { changeShuntTubeProperty } from '../../../../+store/completion/completion.actions';
import { TubeShape } from '@dunefront/common/dto/shunt-tube.dto';
import * as uiActions from '../../../../+store/ui/ui.actions';
import { CompletionValidation } from '@dunefront/common/modules/completion/model/completion.validation';
import { ShuntTube } from '@dunefront/common/modules/shunt-tube/shunt-tube';
import { getValidatedCompletionModuleState } from '../../../../+store/completion/validated-completion.selectors';
import { ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';

@Component({
  selector: 'app-shunt-tubes',
  templateUrl: './shunt-tubes.component.html',
  styleUrls: ['./shunt-tubes.component.scss'],
})
export class ShuntTubesComponent extends DbDependentComponent {
  public completionState!: CompletionModuleState;

  public CompletionValidation = CompletionValidation;

  public isTransportTubePresent = false;

  public TubeShape = TubeShape;

  constructor(store: Store, cdRef: ChangeDetectorRef) {
    super(store, cdRef);

    store.dispatch(uiActions.setCompletionTabAction({ tab: 'shunt-tubes' }));
    this.subscription.add(
      store.select(getValidatedCompletionModuleState).subscribe((state) => {
        this.completionState = state;
        cdRef.markForCheck();
      }),
    );
    this.onHelpChange('shunt-tube', 'system-configuration');
  }

  public submitProperty(props: ObjectChangeProp<ShuntTube>): void {
    if (this.completionState.ShuntTube[props.key] !== props.value) {
      this.store.dispatch(changeShuntTubeProperty(props));
    }
  }
}
