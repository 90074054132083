import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { PageWithGridComponent } from '../../../../shared/components/grid/page-with-grid.component';
import { Store } from '@ngrx/store';
import { ModalService } from '../../../../common-modules/modals/modal.service';
import { ScreenService } from '../../../../shared/services';
import { ITableRow } from '@dunefront/common/common/common-grid.interfaces';
import { setFullReturnsAction, updatePumpingAction, updatePumpingScheduleRowsAction } from '../../../../+store/pumping/pumping.actions';
import { cancelChange } from '../../../../+store/store.helpers';
import { PumpingDto } from '@dunefront/common/modules/pumping/dto/pumping.dto';
import { ValidatedPumpingModuleState } from '@dunefront/common/modules/pumping/pumping-module.state';
import * as uiActions from '../../../../+store/ui/ui.actions';
import { isPumpingEnabled } from '../../../../+store/pumping/selectors/pumping.selectors';
import { getValidatedPumpingModuleState } from '../../../../+store/pumping/selectors/pumping-validation.selectors';
import { getCalculatedSimulatePumpingSchedule } from '../../../../+store/pumping/selectors/pumping-schedule.selectors';
import { PumpingSchedule } from '@dunefront/common/modules/pumping/model/pumping-schedule/pumping-schedule';
import { Pumping } from '@dunefront/common/modules/pumping/model/pumping/pumping';
import { getReferenceVariables } from '../../../../+store/reference-variables/reference-variables.selectors';
import { loadReferenceVariablesAction } from '../../../../+store/reference-variables/reference-variables.actions';
import { changeProp, ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';
import { IUpdateTableRowsProps } from '@dunefront/common/common/common-store-crud.interfaces';
import { PanelHelpMode } from '../../../../shared/components/help-button/help-buton.component';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScheduleComponent extends PageWithGridComponent<PumpingSchedule> {
  public isPumpingEnabled$ = this.store.select(isPumpingEnabled);
  public pumpingState!: ValidatedPumpingModuleState;
  public scheduleReferenceVariables$ = this.store.select(getReferenceVariables);
  public scheduleTableState$ = this.store.select(getCalculatedSimulatePumpingSchedule);
  public PanelHelpMode = PanelHelpMode;
  constructor(store: Store, cdRef: ChangeDetectorRef, modalService: ModalService, resizeService: ScreenService) {
    super(store, cdRef, modalService, resizeService);
    this.subscription.add(
      this.store.select(getValidatedPumpingModuleState).subscribe((pumpingState) => {
        this.pumpingState = pumpingState;
        this.cdRef.markForCheck();
      }),
    );

    store.dispatch(uiActions.setPumpingTabAction({ tab: 'schedule' }));
    this.onHelpChange('pumping', 'schedule-simulate');
  }

  protected override onStateLoaded(): void {
    super.onStateLoaded();
    this.store.dispatch(loadReferenceVariablesAction({ scenarioId: this.currentScenarioId }));
  }

  public getRows(): ITableRow<PumpingSchedule>[] {
    return this.pumpingState.pumpingSchedule.rows;
  }

  public async onPumpingPropertyChanged(props: ObjectChangeProp<Pumping>): Promise<void> {
    if (props.key === 'BlankPackingPercentageForGravelRequired') {
      if (props.value != null && ((props.value as number) < 0 || (props.value as number) > 100)) {
        await this.modalService.showAlert('Blank packing percentage must be between 0 and 100 - previous value will be restored');
        const cancelGenerator = cancelChange(this.pumpingState.pumping, props, this.cdRef);

        this.pumpingState.pumping = cancelGenerator.next().value;
        this.pumpingState.pumping = cancelGenerator.next().value;
        this.cdRef.markForCheck();
        return;
      }
    }
    const newPumping = changeProp(this.pumpingState.pumping, props);
    this.store.dispatch(
      updatePumpingAction({
        isSimulate: false,
        pumping: newPumping,
        changedKeys: [props.key] as (keyof PumpingDto)[],
      }),
    );
  }

  public onSetFullReturns(): void {
    this.store.dispatch(setFullReturnsAction());
  }

  protected updateRow(row: IUpdateTableRowsProps<PumpingSchedule>): void {
    this.store.dispatch(updatePumpingScheduleRowsAction(row));
  }
}
