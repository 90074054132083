<app-page-panel>
  <div class="grid-form-container main with-gap" *ngIf="data$ | async as data">
    <ng-container *ngIf="data.selectedGravel !== undefined">
      <app-gravel-selector
        [source]="data.selectedGravel"
        [key]="'Id'"
        (valueChanged)="changeSelectedGravel($event)"
        [disabled]="isReadOnly"
        [isNoneAvailable]="false"
        [isUiLockable]="false"
        dataCy="gravel-selector"
      ></app-gravel-selector>
    </ng-container>
  </div>
</app-page-panel>
