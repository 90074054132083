import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { DbDependentComponent } from '../../../../common-modules/db-connection/db-dependent.component';
import { Observable } from 'rxjs';
import { getPSDChartSelectionData, IPSDChartSelectionData } from '../../../../+store/psd-analysis/psd-analysis.selectors';
import { notEmpty } from '@dunefront/common/common/state.helpers';
import { Store } from '@ngrx/store';
import * as uiActions from '../../../../+store/ui/ui.actions';
import { PanelHelpMode } from '../../../../shared/components/help-button/help-buton.component';
@Component({
  selector: 'app-psd-comparison',
  templateUrl: './psd-comparison.component.html',
  styleUrls: ['./psd-comparison.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PsdComparisonComponent extends DbDependentComponent {
  public psdChartSelectionData$: Observable<IPSDChartSelectionData[]> = notEmpty(this.store.select(getPSDChartSelectionData));
  public PanelHelpMode = PanelHelpMode;

  constructor(store: Store, cdRef: ChangeDetectorRef) {
    super(store, cdRef);
    store.dispatch(uiActions.setPsdAnalysisTabAction({ tab: 'psd-comparison' }));
    this.onHelpChange('psd', 'psd-comparison');
  }
}
