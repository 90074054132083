import { createAction, props } from '@ngrx/store';
import { PumpingDto } from '@dunefront/common/modules/pumping/dto/pumping.dto';
import { UndoOrRedo } from '@dunefront/common/modules/common.actions';
import { PumpedFluidAndGravelDto } from '@dunefront/common/modules/pumping/dto/pumped-fluid-and-gravel.dto';
import { Pumping } from '@dunefront/common/modules/pumping/model/pumping/pumping';
import { WellFluid } from '@dunefront/common/modules/pumping/model/well-fluid/well-fluid';
import { PumpingSchedule } from '@dunefront/common/modules/pumping/model/pumping-schedule/pumping-schedule';
import { IBaseAdvancedValidationActionPayload } from '../calculation-engine/calculation-engine.actions';
import { IDeleteRowsProps, IInsertRowsProps, IUpdateTableRowsProps } from '@dunefront/common/common/common-store-crud.interfaces';
import { IAnalysisDataComponentSelectedValues } from '@dunefront/common/modules/pumping/pumping-module.state';

// region pumping

export const updatePumpingAction = createAction(
  '[pumping] savePumping',
  props<{ isSimulate: boolean; pumping: Pumping; changedKeys: (keyof PumpingDto)[] }>(),
);

export const reloadPumpingAction = createAction('[pumping-schedule] reloadPumping', props<{ scenarioId: number; rangeId: number }>());

// endregion

// region well fluids

export const insertWellFluidsRowsAction = createAction('[well-fluids] insertRows', props<IInsertRowsProps<WellFluid>>());

export const updateWellFluidsRowsAction = createAction('[well-fluids] updateRows', props<IUpdateTableRowsProps<WellFluid>>());

export const deleteWellFluidsRowsAction = createAction('[well-fluids] deleteRows', props<IDeleteRowsProps>());

export const reloadWellFluidsAction = createAction('[well-fluids] reloadWellFluids', props<{ scenarioId: number; rangeId: number }>());

// endregion

// region pumping schedule

export const insertPumpingScheduleRowsAction = createAction('[pumping-schedule] insertRows', props<IInsertRowsProps<PumpingSchedule>>());

export const updatePumpingScheduleRowsAction = createAction(
  '[pumping-schedule] updateRows',
  props<IUpdateTableRowsProps<PumpingSchedule>>(),
);

export const deletePumpingScheduleRowsAction = createAction('[pumping-schedule] deleteRows', props<IDeleteRowsProps>());

export const reloadPumpingScheduleAction = createAction('[pumping-schedule] reloadPumpingSchedule', props<{ scenarioId: number }>());

// endregion

// region pumped fluids

export const insertPumpedFluidAndGravelRowsAction = createAction(
  '[PumpedFluidAndGravel] insertRows',
  props<IInsertRowsProps<PumpedFluidAndGravelDto>>(),
);

export const updatePumpedFluidAndGravelRowsAction = createAction(
  '[PumpedFluidAndGravel] updateRows',
  props<IUpdateTableRowsProps<PumpedFluidAndGravelDto>>(),
);

export const deletePumpedFluidAndGravelRowsAction = createAction('[PumpedFluidAndGravel] deleteRows', props<IDeleteRowsProps>());

// endregion

export const switchDetailedFluidDefinitionTypeAction = createAction(
  '[well-fluids] switchDetailedFluidDefinitionType',
  props<{ isWellFluidDepthByVolume: boolean; scenarioId: number; rangeId: number; undoOrRedo?: UndoOrRedo }>(),
);

export const setFullReturnsAction = createAction('[pumping-schedule] setFullReturns');

export const generateScheduleAction = createAction('[pumping-schedule] generateSchedule', props<{ scenarioId: number }>());

export const clearEvaluateScheduleAction = createAction('[pumping-schedule] clearEvaluateScheduleAction');

export const generateEvaluationScheduleAction = createAction(
  '[pumping-schedule] generateEvaluationSchedule',
  props<IBaseAdvancedValidationActionPayload>(),
);

export const updateAnalysisDataComponentProperties = createAction(
  '[pumping-schedule] updateAnalysisDataComponentProperties',
  props<{ selectedValues: IAnalysisDataComponentSelectedValues; isUndoEnabled: boolean }>(),
);

export const copyCalcEngineGeneratedPumpingScheduleAction = createAction('[pumping-schedule] copyCalcEngineGeneratedPumpingScheduleAction');
export const copyCalcEngineGeneratedRunningStringAction = createAction('[pumping-schedule] copyCalcEngineGeneratedRunningStringAction');
