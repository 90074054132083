import { PumpingSchedule } from './pumping-schedule';
import { ITableState } from '../../../../common/common-grid.interfaces';
import { IPumpingScheduleValidationDeps } from './pumping-schedule.validation';
import { DictionaryWithArray, IDictionaryWithArray } from '../../../../common/state.helpers';
import { GravelCalculations } from '../../../gravel/dto/gravel.calculations';
import { Validation } from '../../../../common/validation';
import { Gravel } from '../../../gravel/model/gravel';
import { PumpingScheduleDtoCalculations, PumpingScheduleWithCalculatedValues } from '../../dto/pumping-schedule.dto';
import { ChokeSetting, PumpingExtensions, VariableChokeSetting } from '../../dto/pumping.dto';

export class PumpingScheduleCalculations {
  public static SetFullReturns(
    pumpingSchedules: ITableState<PumpingSchedule>,
    deps: IPumpingScheduleValidationDeps,
  ): ITableState<PumpingSchedule> {
    //Set the return rate equal to the pump rate
    const newRows = pumpingSchedules.rows.map((row) => {
      if (row.rowType === 'insert-row' || this.IsStageReturnRateZero(row.rowData, deps)) {
        return { ...row, rowData: { ...row.rowData, ReturnRate: 0 } };
      }

      return { ...row, rowData: { ...row.rowData, ReturnRate: row.rowData.PumpRate } };
    });
    return { ...pumpingSchedules, rows: newRows };
  }

  //Method to update calculated properties
  public static UpdateCalculatedScheduleProperties(
    scheduleTableState: ITableState<PumpingSchedule>,
    gravels: IDictionaryWithArray<Gravel>,
    deps: IPumpingScheduleValidationDeps,
  ): ITableState<PumpingSchedule> {
    const rows = [...scheduleTableState.rows];
    const calculatedRows = PumpingScheduleDtoCalculations.applyPumpingScheduleCalculatedValues(rows.map((row) => row.rowData));
    for (let index = 0; index < calculatedRows.length; index++) {
      const calculatedRow = calculatedRows[index];
      const rowData: PumpingSchedule = {
        ...calculatedRow,
        ReturnRate: this.IsStageReturnRateZero(calculatedRow, deps) ? 0 : calculatedRow.ReturnRate,
        StageGravelMass: this.GravelMass(calculatedRow, gravels),
        StageGravelRate: this.GravelAdditionRate(calculatedRow, gravels),
      };

      rows[index] = { ...rows[index], rowData };
    }
    return { ...scheduleTableState, rows };
  }

  //Method to calculate the gravel addition rate
  public static GravelAdditionRate(ps: PumpingScheduleWithCalculatedValues, gravels: IDictionaryWithArray<Gravel>): number {
    //Calculate the yield
    const yieldValue = this.calculateYield(ps, gravels);

    //Calculate the gravel rate
    const gravelRate = (ps.PumpRate * ps.GravelConcentration) / yieldValue;

    //Perform a consistency check on the result
    return Validation.UICheckNumberValidity(gravelRate);
  }

  public static GravelMass(ps: PumpingScheduleWithCalculatedValues, gravels: IDictionaryWithArray<Gravel>): number {
    //Calculate the yield
    const yieldValue = this.calculateYield(ps, gravels);

    //Calculate the clean fluid volume
    const cleanVolume = ps.StageVolume / yieldValue;

    //Calculate the gravel mass
    const gravelMass = cleanVolume * ps.GravelConcentration;

    //Perform a consistency check on the result
    return Validation.UICheckNumberValidity(gravelMass);
  }

  private static calculateYield(ps: PumpingScheduleWithCalculatedValues, gravels: IDictionaryWithArray<Gravel>): number {
    //Check if there is any gravel
    if (gravels.ids.length === 0 || ps.GravelId === null || ps.GravelId === 0 || ps.GravelConcentration <= 0) {
      //There is no gravel so return zero
      return 0;
    }
    const gravel = DictionaryWithArray.get(gravels, ps.GravelId);
    if (!gravel || gravel.AbsoluteDensity <= 0) {
      return 0;
    }

    //Calculate the yield
    return GravelCalculations.Yield(gravel, ps.GravelConcentration);
  }

  public static IsStageReturnRateZero(
    ps: PumpingScheduleWithCalculatedValues,
    { isLossCalculated, pumping }: IPumpingScheduleValidationDeps,
  ): boolean {
    if (!isLossCalculated) {
      // Return rate is never locked in specify losses
      return false;
    }

    if (pumping.ChokePosition === ChokeSetting.None || PumpingExtensions.getVariableChoke(pumping) !== VariableChokeSetting.Managed_Rate) {
      // Return rate is always locked if the choke is not Managed_Rate
      return true;
    }

    if (pumping.ChokePosition === ChokeSetting.Closed && !pumping.IsChokeOpenAtVolume) {
      return true;
    }

    if (pumping.IsChokeOpenAtVolume && ps.CumulativeVolume <= pumping.ChokeOpenVolume) {
      return true;
    }
    return false;
  }

  public static IsChokePressureLocked(ps: PumpingScheduleWithCalculatedValues, { pumping }: IPumpingScheduleValidationDeps): boolean {
    if (pumping.ChokePosition === ChokeSetting.None || pumping.VariableChoke !== VariableChokeSetting.Managed_Pressure) {
      // ChokePressure is always locked if the choke is not Managed_Pressure
      return true;
    }

    if (pumping.ChokePosition === ChokeSetting.Closed && !pumping.IsChokeOpenAtVolume) {
      return true;
    }

    if (pumping.IsChokeOpenAtVolume && ps.CumulativeVolume <= pumping.ChokeOpenVolume) {
      return true;
    }
    return false;
  }
}
