export const convertToUnicodeEscape = (character: string): string => {
  const unicodeEscape = character.charCodeAt(0).toString(16).toUpperCase();
  const paddedUnicode = '0000'.substring(0, 4 - unicodeEscape.length) + unicodeEscape;
  return `\\u${paddedUnicode}`;
};

export const replaceMultiByteCharacters = (inputString: string): string => {
  return inputString
    .split('')
    .map((char) => {
      if (Buffer.from(char).length > 1) {
        return convertToUnicodeEscape(char);
      }
      return char;
    })
    .join('');
};

export const replaceNonAsciiWithQuestionMark = (str: string): string => {
  return str
    .split('')
    .map((char) => (char.charCodeAt(0) <= 127 ? char : '?'))
    .join('');
};
