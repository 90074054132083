import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { ModalService } from '../modal.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BuildInfo } from '@dunefront/common/common/build-info';
import { AppTargetConfig } from '../../../shared/services/app-target-config';
import { AvailableAppVersion, UpdateInfoDto } from '@dunefront/common/dto/update.dto';
import { Store } from '@ngrx/store';
import { getDownloadUrl, getUpdateInfo } from '../../../+store/about/about.selectors';
import { checkForUpdatesAction, fetchDownloadUrl, resetDownloadUrl, resetUpdatesInfoAction } from '../../../+store/about/about.actions';
import { filterNil } from '@dunefront/common/common/state.helpers';
import { firstValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';
import { cmpVersions, getLatestAppVersion } from '../../../+store/about/about.effect.helper';
import { defaultModalCancelButton, modalButton, ModalButtonConfig } from '../generic-modal/generic-modal.component';
import { getIsAnyFeatureUsableFactory } from '../../../+store/licensing/licensing.selectors';

const downloadBtnId = 'updates-download-btn';

@Component({
  selector: 'app-check-for-updates',
  template: ` <app-generic-modal [title]="modalTitle" [modalButtonsConfigs]="modalButtonsConfigs">
    <div class="loading" *ngIf="isLoading">
      <app-loader></app-loader>
    </div>

    <div class="content" *ngIf="!isLoading && !error">
      <p *ngIf="!shouldUpdate">You already have the latest version of {{ appTargetConfig.appName }}.</p>
      <div *ngIf="shouldUpdate">
        <p>A newer version is available, do you want to download now?</p>
        <ul *ngIf="latestAvailable && clientBuildInfo">
          <li>Newest version: {{ latestAvailable.version }}</li>
          <li>Your version: {{ clientBuildInfo.version }}</li>
        </ul>
        <p>
          The download will launch in your browser and may take a while depending on your internet connection. Once completed, you’ll need
          to double click the file to update.
        </p>
      </div>
    </div>

    <div class="error" *ngIf="!isLoading && error">
      <p>{{ error }}</p>
    </div>
  </app-generic-modal>`,
  styleUrls: ['./check-for-updates.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckForUpdatesComponent {
  public isLoading = true;
  public clientBuildInfo = BuildInfo;
  public shouldUpdate = false;
  public latestAvailable: AvailableAppVersion | undefined;
  public error: string | undefined;
  public modalTitle = 'Updates';

  public modalButtonsConfigs: ModalButtonConfig[] = [
    defaultModalCancelButton(() => this.cancelClicked(), 'Close'),
    modalButton(
      'Download',
      (): Promise<void> => this.onDownloadClicked(),
      downloadBtnId,
      'primary',
      this.shouldUpdate,
      false,
      downloadBtnId,
    ),
  ];

  constructor(
    protected cdRef: ChangeDetectorRef,
    protected modalService: ModalService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public appTargetConfig: AppTargetConfig,
    public store: Store,
  ) {
    if (!config.data?.updateInfo) {
      this.fetchVersion();
    } else {
      this.handleUpdateResponse(config.data.updateInfo);
    }
  }

  public async cancelClicked(): Promise<void> {
    this.ref.close();
  }

  private async fetchVersion(): Promise<any> {
    const isAnyFeatureAvailable = await firstValueFrom(this.store.select(getIsAnyFeatureUsableFactory(this.appTargetConfig.appName)));
    if (!isAnyFeatureAvailable) {
      return this.showLicenseNotAvailableError();
    }

    this.store.dispatch(checkForUpdatesAction({ isPeriodicCheck: false }));
    const updatesInfo = await firstValueFrom(this.store.select(getUpdateInfo).pipe(filterNil()));
    this.handleUpdateResponse(updatesInfo);
  }

  private handleUpdateResponse(updateInfo: UpdateInfoDto): void {
    const versions = updateInfo?.versions ?? [];
    if (!versions.length) {
      return this.showFetchError();
    }

    this.latestAvailable = getLatestAppVersion([...versions]);
    const versionDiff = cmpVersions(this.clientBuildInfo.version, this.latestAvailable.version);
    this.shouldUpdate = versionDiff < 0;
    this.isLoading = false;
    this.cdRef.markForCheck();

    const downloadBtn = this.modalButtonsConfigs.find((btn) => btn.id === downloadBtnId);
    if (this.shouldUpdate && downloadBtn) {
      downloadBtn.show = true;
    }

    this.store.dispatch(resetUpdatesInfoAction());
  }

  private showFetchError(): void {
    this.error = 'Cannot connect to updates server. Please try again later';
    this.isLoading = false;
    this.cdRef.markForCheck();
  }

  private showLicenseNotAvailableError(): void {
    this.error = 'A valid license is needed to download updates';
    this.isLoading = false;
    this.cdRef.markForCheck();
  }

  public async onDownloadClicked(): Promise<void> {
    if (!this.latestAvailable) {
      return;
    }
    this.isLoading = true;
    this.store.dispatch(fetchDownloadUrl({ version: this.latestAvailable.version }));
    this.store
      .select(getDownloadUrl)
      .pipe(filterNil(), take(1))
      .subscribe((url) => {
        if (!url.length) {
          return this.showFetchError();
        }
        window.open(url, '_blank');
        this.store.dispatch(resetDownloadUrl());
        this.ref.close();
      });
  }
}
