import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { BackendConnectionService } from '../../shared/backend-connection/backend-connection.service';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { firstValueFrom, forkJoin, of } from 'rxjs';
import * as actions from './common-db.actions';
import { changeAndSaveGlobalOptionsProperty, loadCommonDbInitialData, saveGlobalOptions } from './common-db.actions';
import {
  CommonDbModuleName,
  CommonDbModuleNotificationTypes,
  CommonDbNotifications,
  CommonDbRestoreItem,
  ICommonDbInitialData,
  LoadCommonDbInitialData,
  LoadNotificationsStatusAction,
  SaveCustomSettingsAction,
  SaveGlobalOptionsAction,
} from '@dunefront/common/modules/common-db/common-db.actions';
import { BaseWsEffects } from '../base-ws.effects';
import { ModalService } from '../../common-modules/modals/modal.service';
import { BuildInfo } from '@dunefront/common/common/build-info';
import { CustomSettingsType, ICustomSettingsDto, NotificationState } from '@dunefront/common/dto/custom-settings.dto';
import {
  chartZoomModeAction,
  loadNotificationsAction,
  notificationsLoadedAction,
  notificationsStatusLoadedAction,
  setExportDataUseTimestampsAction,
  setHelpPinnedAction,
  setSideNavPinnedAction,
  updateNotificationStatusAction,
} from '../ui/ui.actions';
import { getPinSettings, getUnreadNotifications, selectNotificationStates } from '../ui/ui.selectors';
import { chooseUnitSystemAction } from '../units/units.actions';
import { setCrosshairModeAction, setMaxSeriesInTooltipAction, setTooltipPositionAction } from '../reporting/reporting.actions';
import { getCrosshairMode, getMaxSeriesInTooltip, getTooltipPosition } from '../reporting/reporting.selectors';
import { backendConnectedAction } from '../backend-connection/backend-connection.actions';
import { getCommonDbState, selectValidatedOrganizationGlobalOptions, selectValidatedUserGlobalOptions } from './common-db.selectors';
import { CommonDbPropertiesHelper } from './common-db.properties.helper';
import { dataFailed, noopAction } from '../app.actions';
import { NOTIFICATIONS_LAMBDA_URL } from '@dunefront/common/common/constants';
import { AppTargetConfig } from '../../shared/services/app-target-config';
import { HttpClient } from '@angular/common/http';
import { Notification } from '../ui/ui-module.state';
import { NotificationsModalComponent } from '../../common-modules/modals/survey/notifications/notifications-modal.component';
import { aboutServerSuccessAction } from '../about/about.actions';
import { toggleAddonAction } from '../licensing/licensing.actions';
import { getSelectedAddonFeatures } from '../licensing/licensing.selectors';

@Injectable()
export class CommonDbEffects extends BaseWsEffects {
  constructor(
    actions$: Actions,
    store: Store,
    wsService: BackendConnectionService,
    modalService: ModalService,
    public appTargetConfig: AppTargetConfig,
    private http: HttpClient,
  ) {
    super(actions$, wsService, CommonDbModuleName, true, false, modalService, store);
  }

  private backendConnected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(backendConnectedAction),
      map(() => loadCommonDbInitialData()), /// REMOVE
    ),
  );

  private aboutServerSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(aboutServerSuccessAction), /// TO CHANGES
      map((action) => loadNotificationsAction({ isTestEnv: action.aboutServerResult.isTestEnv })),
    ),
  );

  //region Global Options

  public loadGlobalOptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loadCommonDbInitialData),
      mergeMap((action) => {
        return this.emit<ICommonDbInitialData>(new LoadCommonDbInitialData()).pipe(
          map((result) => actions.loadCommonDbInitialSuccess({ payload: result.payload })),
          catchError((err) => of(dataFailed(err))),
        );
      }),
    ),
  );

  public changeAndSaveGlobalOptionsProperty$ = createEffect(() =>
    this.actions$.pipe(
      ofType(changeAndSaveGlobalOptionsProperty),
      concatLatestFrom(() => this.store.select(getCommonDbState)),
      map(([{ commonDbType, props }, { personalGlobalOptions, orgGlobalOptions, predefinedGlobalOptions }]) => {
        if (personalGlobalOptions == null || orgGlobalOptions == null || predefinedGlobalOptions == null) {
          return noopAction();
        }

        const globalOptionsToUpdate = commonDbType === 'User' ? personalGlobalOptions : orgGlobalOptions;
        const parentGlobalOptions = commonDbType === 'User' ? orgGlobalOptions : undefined;
        const globalOptions = CommonDbPropertiesHelper.changeGlobalOptionsProperty(
          props,
          globalOptionsToUpdate,
          parentGlobalOptions,
          predefinedGlobalOptions,
        );

        return saveGlobalOptions({ commonDbType, globalOptions });
      }),
    ),
  );

  public saveGlobalOptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.saveGlobalOptions),
      mergeMap(({ commonDbType, globalOptions }) => this.emitUpdate(new SaveGlobalOptionsAction(commonDbType, globalOptions))),
    ),
  );

  public changeGlobalOptionsPersonalProperty$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.changeGlobalOptionsPersonalProperty),
      concatLatestFrom(() => this.store.select(selectValidatedUserGlobalOptions)),
      mergeMap(([action, globalOptions]) => this.emitUpdate(new SaveGlobalOptionsAction('User', globalOptions))),
    ),
  );

  public changeGlobalOptionsOrgProperty$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.changeGlobalOptionsOrgProperty),
      concatLatestFrom(() => this.store.select(selectValidatedOrganizationGlobalOptions)),
      mergeMap(([action, globalOptions]) => this.emitUpdate(new SaveGlobalOptionsAction('Organization', globalOptions))),
    ),
  );

  public updatePinSettingsAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setHelpPinnedAction, setSideNavPinnedAction),
      concatLatestFrom(() => this.store.select(getPinSettings)),
      switchMap(([action, pinSettingsState]) => {
        const customSettingsDto: ICustomSettingsDto = {
          Id: CustomSettingsType.Pin,
          Settings: pinSettingsState,
        };
        return this.emitUpdate(new SaveCustomSettingsAction('User', customSettingsDto));
      }),
    ),
  );

  public onSetExportDataUseTimestampsAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setExportDataUseTimestampsAction),
      switchMap((action) => {
        const customSettingsDto: ICustomSettingsDto = {
          Id: CustomSettingsType.ExportData,
          Settings: { shouldUseTimestamps: action.useTimestamps },
        };

        return this.emitUpdate(new SaveCustomSettingsAction('User', customSettingsDto));
      }),
    ),
  );

  public chooseUnitSystem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(chooseUnitSystemAction),
      mergeMap((action) => {
        const customSettingsDto: ICustomSettingsDto = {
          Id: CustomSettingsType.Unit,
          Settings: { unitSystemId: action.unitSystemId },
        };
        return this.emitUpdate(new SaveCustomSettingsAction('User', customSettingsDto));
      }),
    ),
  );

  public chartZoomModeAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(chartZoomModeAction),
      mergeMap((action) => {
        const customSettingsDto: ICustomSettingsDto = {
          Id: CustomSettingsType.Zoom,
          Settings: { zoomMode: action.chartZoomMode },
        };
        return this.emitUpdate(new SaveCustomSettingsAction('User', customSettingsDto));
      }),
    ),
  );

  public setCrosshairConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setCrosshairModeAction, setTooltipPositionAction, setMaxSeriesInTooltipAction),
      concatLatestFrom(() => [
        this.store.select(getCrosshairMode),
        this.store.select(getTooltipPosition),
        this.store.select(getMaxSeriesInTooltip),
      ]),
      mergeMap(([, crosshairMode, tooltipPosition, maxSeriesInTooltip]) => {
        const customSettingsDto: ICustomSettingsDto = {
          Id: CustomSettingsType.Crosshair,
          Settings: { crosshairMode, tooltipPosition, maxSeriesInTooltip },
        };
        return this.emitUpdate(new SaveCustomSettingsAction('User', customSettingsDto));
      }),
    ),
  );

  public updateMessagesAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateNotificationStatusAction),
      concatLatestFrom(() => [this.store.select(selectNotificationStates)]),
      mergeMap(([action, notificationStates]) => {
        const updatedMessage = { id: action.id, status: action.status, date: new Date() };

        const updatedstatusState = notificationStates.find((msg) => msg.id === action.id)
          ? notificationStates.map((msg) => (msg.id === action.id ? updatedMessage : msg))
          : [...notificationStates, updatedMessage];

        const customSettingsDto: ICustomSettingsDto = {
          Id: CustomSettingsType.Notifications,
          Settings: updatedstatusState,
        };
        return this.emitUpdate(new SaveCustomSettingsAction('User', customSettingsDto)).pipe(
          map((res) => notificationsStatusLoadedAction({ notificationsStates: updatedstatusState })),
        );
      }),
    ),
  );

  public selectedAddonChanged = createEffect(() =>
    this.actions$.pipe(
      ofType(toggleAddonAction),
      concatLatestFrom(() => this.store.select(getSelectedAddonFeatures)),
      mergeMap(([, selectedLicenseAddonFeatures]) => {
        const customSettingsDto: ICustomSettingsDto = {
          Id: CustomSettingsType.Licensing,
          Settings: { selectedLicenseAddonFeatures },
        };
        return this.emitUpdate(new SaveCustomSettingsAction('User', customSettingsDto));
      }),
    ),
  );

  protected override onIncomingMessage(action: CommonDbNotifications): void {
    switch (action.type) {
      case CommonDbModuleNotificationTypes.CommonDbRestored: {
        this.showCommonDbRestoredInfo(action.payload).then();
      }
    }
  }

  private async showCommonDbRestoredInfo(items: CommonDbRestoreItem[]): Promise<void> {
    // check if any DB was too old
    if (items.some((item) => item.tooOldDBVersion === true)) {
      await this.modalService.showAlert('Configuration database is too old to migrate and will be reset to default values', 'Information');
    }

    // check restored DB's
    const restoredFromBackup = items.filter((item) => item.tooOldDBVersion !== true);
    if (restoredFromBackup.length > 0) {
      let message = 'Common DBs restored from backup:';
      for (const item of items) {
        message += `</br>${item.commonDbType} DB restored from version: ${item.restoredVersion}`;
      }

      await this.modalService.showAlert(message, 'Information');
    }
  }

  private loadNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadNotificationsAction),
      switchMap((action) =>
        forkJoin([
          this.http
            .get<Notification[]>(
              `${NOTIFICATIONS_LAMBDA_URL}?appVersion=${BuildInfo.version}&appName=${this.appTargetConfig.appName}${
                action.isTestEnv ? 'Test' : ''
              }`,
            )
            .pipe(
              catchError((e) => {
                if (action.isTestEnv) {
                  alert(
                    `Cannot connect to notifications server. Check your internet connection, or contact dev team \n
                    ( this is a test-only message )`,
                  );
                }
                return of([]);
              }),
            ),
          firstValueFrom(this.emit<NotificationState[]>(new LoadNotificationsStatusAction())),
        ]).pipe(
          tap(([notifications, notificationsStatus]) => {
            if (
              ((notifications.length && notificationsStatus.payload == null) ||
                getUnreadNotifications(notificationsWithReleaseNotes(notifications), notificationsStatus.payload).length) &&
              !this.modalService.areAnyDialogsOfTypeOpen(NotificationsModalComponent)
            ) {
              this.modalService.open(NotificationsModalComponent, {});
            }
          }),
          switchMap(([notifications, notificationsStatus]) => [
            notificationsStatusLoadedAction({ notificationsStates: notificationsStatus?.payload ?? [] }),
            notificationsLoadedAction({ notifications: notificationsWithReleaseNotes(notifications) }),
          ]),
        ),
      ),
    ),
  );
}

const notificationsWithReleaseNotes = (notifications: Notification[]): Notification[] => {
  const currentVersion = BuildInfo.version;

  const releaseNotesNotification = (version: string): Notification => ({
    title: `Release Notes ( ${version} )`,
    description: "You've got the last update! Check out what's new!",
    url: '',
    id: `release-notes-${version}`,
    showDate: new Date(),
    appName: [],
  });

  return [releaseNotesNotification(currentVersion), ...notifications];
};
