import { NgModule } from '@angular/core';
import { RheometerDataComponent } from './rheometer-data/rheometer-data.component';
import { ProjectFluidsComponent } from './project-fluids/project-fluids.component';
import { DatabaseFluidsComponent } from './database-fluids/database-fluids.component';
import { CommonModule } from '@angular/common';
import { FluidPropertiesComponent } from './project-fluids/fluid-properties/fluid-properties.component';
import { TabbedPageModule } from '../../../common-modules/tabbed-page/tabbed-page.component';
import { PagePanelModule } from '../../../common-modules/page-panel/page-panel.component';
import { UnitsModule } from '../../../common-modules/units/units.module';
import { GridSelectComponentModule } from '../../../common-modules/grid-select/grid-select.component';
import { CheckBoxModule } from '../../../shared/components/form-components/check-box/check-box.component';
import { RadioGroupModule } from '../../../shared/components/form-components/radio-group/radio-group.component';
import { GridModule } from '../../../shared/components/grid/grid.module';
import { FormInputModule } from '../../../shared/components/form-components/form-input/form-input.component';
import { UnwrapDirectiveModule } from '../../../shared/directives/unwrap.directive';
import { FormsModule } from '@angular/forms';
import { ChartModule } from '../../../common-modules/chart/chart.module';
import { FluidRheologiesGridComponent } from './project-fluids/fluid-properties/fluid-rheologies-grid/fluid-rheologies-grid.component';
import { FluidHeaderComponent } from './common/fluid-header/fluid-header.component';
import { DatabaseFluidsGridComponent } from './database-fluids/database-fluids-grid/database-fluids-grid.component';
import { RheometerTestConfigurationComponent } from './rheometer-data/rheometer-test-configuration/rheometer-test-configuration.component';
import { RheometerTestDataComponent } from './rheometer-data/rheometer-test-data/rheometer-test-data.component';
import { RheometerTestDataGridComponent } from './rheometer-data/rheometer-test-data/rheometer-test-data-grid/rheometer-test-data-grid.component';
import { RheomoeterTestDataChartComponent } from './rheometer-data/rheometer-test-data/rheometer-test-data-chart/rheomoeter-test-data-chart.component';
import { FluidSelectorModule } from '../fluid-selector/fluid-selector.module';
import { SelectModule } from '../../../shared/components/form-components/select/select.module';
import { ButtonModule } from '../../../shared/components/button/button.module';
import { SearchbarModule } from '../../../shared/components/searchbar/searchbar.component';
import { TooltipModule } from 'primeng/tooltip';
import { FluidAdvancedPropertiesComponent } from './fluid-advanced-properties/fluid-advanced-properties.component';
import { FluidColorSelectorModule } from '../fluid-color-selector/fluid-color-selector.module';

@NgModule({
  declarations: [
    DatabaseFluidsComponent,
    DatabaseFluidsGridComponent,
    ProjectFluidsComponent,
    FluidAdvancedPropertiesComponent,
    RheometerDataComponent,
    FluidPropertiesComponent,
    FluidRheologiesGridComponent,
    FluidHeaderComponent,
    RheometerTestConfigurationComponent,
    RheometerTestDataComponent,
    RheometerTestDataGridComponent,
    RheomoeterTestDataChartComponent,
  ],
  imports: [
    PagePanelModule,
    CommonModule,
    TabbedPageModule,
    UnitsModule,
    FormsModule,
    GridModule,
    ChartModule,
    CheckBoxModule,
    RadioGroupModule,
    ButtonModule,
    SelectModule,
    GridSelectComponentModule,
    FormInputModule,
    UnwrapDirectiveModule,
    GridSelectComponentModule,
    ChartModule,
    FluidSelectorModule,
    FluidColorSelectorModule,
    SearchbarModule,
    TooltipModule,
  ],
})
export class FluidsModule {}
