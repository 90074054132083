<app-generic-modal [title]="modalTitle" [modalButtonsConfigs]="modalButtonsConfigs">
  <label>Enter new scenario name</label>
  <app-input
    class="grid-two-one"
    [isTextInput]="true"
    [value]="scenarioName"
    [isUiLockable]="false"
    [maxWidth]="300"
    [autoFocus]="true"
    align="left"
    dataCy="create-scenario-name"
    (primitiveValueChanged)="textValueChanged($event.value)"
  >
  </app-input>
</app-generic-modal>
