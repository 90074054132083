import { ChangeDetectorRef } from '@angular/core';
import { changeProp, ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';
import { firstValueFrom, Observable } from 'rxjs';

export function* cancelChange<T>(model: T, changedProp: ObjectChangeProp<T>, cdRef: ChangeDetectorRef): Generator<T> {
  const prevValue = model[changedProp.key];
  yield changeProp(model, changedProp);
  cdRef.detectChanges();
  yield changeProp(model, changedProp, prevValue);
  cdRef.markForCheck();
}

export const getCurrentValue = async <T>(selector: Observable<T>): Promise<T | undefined> => {
  return await firstValueFrom(selector);
};
