import { GravelDto } from './gravel.dto';
import { Validation } from '../../../common/validation';

export class GravelCalculations {
  public static Yield(gravel: GravelDto, gravelConcentration: number): number {
    return 1.0 + gravelConcentration / gravel.AbsoluteDensity;
  }

  //Method to calculate the volume fraction ratio
  public static VolumeFractionRatio(gravel: GravelDto, gravelConcentration: number): number {
    //return the ratio of actual and max volume fraction
    return this.VolumeFraction(gravel, gravelConcentration) / this.MaxVolumeFraction(gravel);
  }

  //Method to calculate the slurry volume fraction
  public static VolumeFraction(gravel: GravelDto, gravelConcentration: number): number {
    //Calculate the volume of the gravel in 1 volume unit of slurry (= volume fraction)
    return gravelConcentration / (gravelConcentration + gravel.AbsoluteDensity);
  }

  //Method to calculate the max volume fraction
  public static MaxVolumeFraction(gravel: GravelDto): number {
    //calcualte the max ratio
    return gravel.BulkDensity / gravel.AbsoluteDensity;
  }

  //Method to calculate the maximum gravel concentration
  public static MaxGravelConcentration(gravel: GravelDto, bridgingRatio: number): number {
    //Set the max volume fraction ratio half way between the user defined max, and the actual max of 1.0
    //This prevents any uncertainty in the calculation determining whether or not there should be a bridge
    const maxVolumeFractionRatio = (bridgingRatio + 1.0) / 2.0;
    //Calculate the maximum concentration based on the max volume fraction ratio
    return (
      (maxVolumeFractionRatio * gravel.AbsoluteDensity * gravel.BulkDensity) /
      (gravel.AbsoluteDensity - maxVolumeFractionRatio * gravel.BulkDensity)
    );
  }

  //Method to calculate the Forchheimer Beta
  public static ForchheimerBeta(gravel: GravelDto): number {
    const maxVolumeFraction = gravel.BulkDensity / gravel.AbsoluteDensity;
    const packPorosity = 1 - maxVolumeFraction;
    return Validation.UICheckNumberValidity(0.005 / (Math.pow(gravel.PackPermeability, 0.5) * Math.pow(packPorosity, 5.5)));
  }
}
