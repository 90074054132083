import { LoadScenarioActionResponse } from '@dunefront/common/modules/scenario/scenario-module.actions';
import { createAction, props } from '@ngrx/store';
import { CrudResponse, IProgressUpdatePayload } from '@dunefront/common/modules/common.actions';
import { IErrorState } from '../shared/components/error/client-error-handler.service';
import { ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import { IFile } from '@dunefront/common/dto/file.dto';
import { LicenseFeature } from '@dunefront/common/modules/licensing/licensing.interfaces';

createAction('[app] validateData');
export const loadScenarioDataAction = createAction('[app] loadScenarioDataAction', props<{ scenarioId: number; rangeId?: number }>());
export const loadScenarioDataSuccessAction = createAction(
  '[app] loadScenarioDataSuccessAction',
  props<{ loadScenarioResponse: LoadScenarioActionResponse; rangeId: number }>(),
);

export const updateRowSuccess = createAction('[app] updateRowSuccess', props<CrudResponse>());
export const deleteRowsSuccess = createAction('[app] deleteRowsSuccess', props<CrudResponse>());
export const insertRowsSuccess = createAction('[app] insertRowsSuccess', props<CrudResponse>());
export const reorderRowSuccess = createAction('[app] reorderRowSuccess', props<CrudResponse>());

export const progressUpdateAction = createAction(
  '[app] progressUpdate',
  props<{ taskId: string; fileHash: string; progressPayload: IProgressUpdatePayload }>(),
);

export const dataFailed = createAction('[app] dataFailed', props<IErrorState>());
export const noopAction = createAction('[app] noopAction');

export const resetScenarioState = createAction('[app] resetScenarioState');

export const scenarioOrRangeLoadedAction = createAction(
  '[app] scenarioOrRangeLoadedAction',
  props<{ scenarioId: number; rangeId: number; moduleType: ModuleType }>(),
);

export const refreshAppAction = createAction('[app] refreshApp', props<{ error: IErrorState | null }>());

export const switchModuleAction = createAction('[app] switchModule', props<{ file: IFile, selectedLicenseFeature: LicenseFeature  }>());
