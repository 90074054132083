import { ColumnType, IGridColumnConfig } from '../../../../../shared/components/grid/grid.interfaces';
import { PsdReading } from '@dunefront/common/modules/psd-analysis/model/psd-readings/psd-reading';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';

export const getPsdDataGridConfig = (isWeight: boolean, isPercent: boolean, isCumulative: boolean): IGridColumnConfig<PsdReading>[] => {
  return [
    { disabled: false, visible: true, colId: ' ', type: ColumnType.selection },
    {
      disabled: false,
      visible: true,
      colId: 'SieveOpening',
      type: ColumnType.number,
      unitSystem: UnitSystem.Sieve_Opening,
      headerText: 'Sieve Opening',
      width: 100,
      decimalPlaces: 3,
      matchingStrings: ['open'],
    },
    {
      disabled: false,
      visible: isWeight,
      colId: 'InitialSieveWeight',
      type: ColumnType.number,
      unitSystem: UnitSystem.Small_Mass,
      headerText: 'Initial Sieve Weight',
      width: 100,
      decimalPlaces: 3,
      matchingStrings: ['init'],
    },
    {
      disabled: false,
      visible: isWeight,
      colId: 'FinalSieveWeight',
      type: ColumnType.number,
      unitSystem: UnitSystem.Small_Mass,
      headerText: 'Final Sieve Weight',
      width: 100,
      decimalPlaces: 3,
      matchingStrings: ['final'],
    },
    {
      disabled: !isPercent,
      visible: isPercent || isWeight,
      colId: 'PercentWeightRetained',
      type: ColumnType.number,
      unitSystem: UnitSystem.None,
      headerText: '% Weight Retained',
      width: 100,
      overrideUnitSymbol: '%',
      decimalPlaces: 3,
      matchingStrings: ['percent'],
    },
    {
      disabled: !isCumulative,
      visible: true,
      colId: 'CumulativePercentWeightRetained',
      unitSystem: UnitSystem.None,
      type: ColumnType.number,
      headerText: 'Cumulative % Weight Retained',
      width: 120,
      overrideUnitSymbol: '%',
      decimalPlaces: 3,
      matchingStrings: ['cumul'],
    },
    {
      disabled: false,
      visible: true,
      colId: 'SieveDescription',
      unitSystem: UnitSystem.None,
      type: ColumnType.text,
      headerText: 'Sieve Description',
      width: 120,
      matchingStrings: ['desc', 'name'],
    },
  ];
};
