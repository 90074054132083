import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnDestroy,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MenuModule } from 'primeng/menu';
import { LeftNavHelperService } from './left-nav-helper.service';
import { IReorderEventArgs, LeftNavItemComponent } from './left-nav-item/left-nav-item.component';
import { ILeftNavItem, LeftNavHelpers } from './left-nav.helpers';
import { StartStopButtonComponent } from './start-stop-button.component';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { AppTargetConfig } from '../../services/app-target-config';
import { Store } from '@ngrx/store';
import { setSideNavPinnedAction } from '../../../+store/ui/ui.actions';
import { Subscription } from 'rxjs';
import { getIsSideNavPinned } from '../../../+store/ui/ui.selectors';
import { debounceTime, filter, skip } from 'rxjs/operators';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { reorderReportingTabAction } from '../../../+store/reporting/reporting.actions';
import { RouteModuleReporting } from '../../../pages/common/reporting/reporting.routes';
import { RouteModuleHome } from '../../../pages/home/home-page-routes-names';
import { getIsProjectCalculationActive } from '../../../+store/ui/calc-engine-ui.selectors';

@Component({
  selector: 'app-left-nav',
  templateUrl: './left-nav.component.html',
  styleUrls: ['./left-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LeftNavComponent implements OnDestroy {
  @Input() public menuData: ILeftNavItem[] | null = [];
  @Input() public selectedUri: string | null = null;
  @Input() public showModuleSwitcher = true;
  @Input() public isAdminPanel = false;

  @Output() public activeMenuItemChanged = new EventEmitter();

  @ViewChildren('navMenuItem') public navMenuItems: QueryList<LeftNavItemComponent> | undefined;
  public isCalculationActive = false;
  public isSideNavPinned = true;

  public areMultipleModulesAvailable = true;
  public isMouseOver = false;
  public initialHoverItem: ILeftNavItem | null = null;

  private subscription = new Subscription();
  private blockHoveringNavItems = false;

  constructor(
    public runCalculationService: LeftNavHelperService,
    protected appConfig: AppTargetConfig,
    private store: Store,
    private router: Router,
    private cdRef: ChangeDetectorRef,
  ) {
    this.watchRouter();

    this.areMultipleModulesAvailable = appConfig.availableModules.length > 1;

    this.subscription.add(
      this.store.select(getIsSideNavPinned).subscribe((isSideNavPinned) => {
        this.isSideNavPinned = isSideNavPinned;
        this.cdRef.markForCheck();
      }),
    );

    this.subscription.add(
      this.store.select(getIsProjectCalculationActive).subscribe((isCalculationActive) => {
        this.isCalculationActive = isCalculationActive;
        this.cdRef.markForCheck();
      }),
    );
  }

  public moduleSelectionItem = LeftNavHelpers.getNavElement('Select module', 'module-selector', 'icon-module-select');

  public homeButtonItem = LeftNavHelpers.getNavElement('Home', RouteModuleHome, 'icon-home');

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public trackByFn(index: number, item: ILeftNavItem): string {
    return item.uri + '_' + item.type;
  }

  public togglePinSideNav(): void {
    this.store.dispatch(setSideNavPinnedAction({ isSideNavPinned: !this.isSideNavPinned }));
  }

  public onHoverNavItem(menuElement: ILeftNavItem): void {
    if (!this.blockHoveringNavItems && !this.isSideNavPinned) {
      this.initialHoverItem = menuElement;
    }
  }

  public onReordered(event: IReorderEventArgs): void {
    if (event.draggableType === RouteModuleReporting) {
      this.store.dispatch(
        reorderReportingTabAction({
          toIndex: event.currentIndex - 1,
          fromIndex: event.prevIndex - 1,
        }),
      );
    }
  }

  public isInitHover(menuElement: ILeftNavItem): ILeftNavItem | null {
    if (this.initialHoverItem != null && this.initialHoverItem.uri === menuElement.uri) {
      return menuElement;
    }
    return null;
  }

  public onMouseEnter(): void {
    this.initialHoverItem = null;
    this.isMouseOver = true;
    this.blockHoveringNavItems = false;

    setTimeout(() => {
      if (this.initialHoverItem === null) {
        // if no item is hovered, expand selected item
        const sectionUrl = this.router.url.split('/')[6];
        const currEl = this.menuData?.find((el) => el.uri === sectionUrl);
        this.initialHoverItem = currEl != undefined ? currEl : null;
        this.cdRef.markForCheck();
      }
      this.blockHoveringNavItems = true;
    }, 0);
  }

  private watchRouter(): void {
    // on navigation end, check if cursor is still above sidebar, if not, collapse it
    this.subscription.add(
      this.router.events
        .pipe(
          filter((e) => e instanceof NavigationEnd),
          skip(1),
          debounceTime(100),
        )
        .subscribe(() => {
          if (this.navMenuItems != null && this.navMenuItems.length > 0) {
            this.navMenuItems.forEach((item) => item.cdRef.markForCheck());
          }

          const hoveredSideNav = document.querySelector('.left-nav-container:hover');
          if (!hoveredSideNav && this.isMouseOver) {
            this.isMouseOver = false;
            this.cdRef.detectChanges();
          }
        }),
    );
  }

  public onMouseLeave(): void {
    this.isMouseOver = false;
  }

  public get isSideNavExpanded(): boolean {
    return this.isMouseOver || this.isSideNavPinned || this.isAdminPanel;
  }
}

@NgModule({
  imports: [CommonModule, FormsModule, MenuModule, RouterModule, DragDropModule, DragDropModule],
  declarations: [LeftNavComponent, LeftNavItemComponent, StartStopButtonComponent],
  providers: [LeftNavHelperService],
  exports: [LeftNavComponent],
})
export class LeftNavModule {}
