import { Component } from '@angular/core';
import { IRadioItem } from '@dunefront/common/common/radio.helpers';

@Component({
  selector: 'app-form-component',
  templateUrl: './form-component.component.html',
  styleUrls: ['./form-component.component.scss'],
})
export class FormComponentComponent {
  public items: IRadioItem<number>[] = [
    { value: 1, text: 'Option 1' },
    { value: 2, text: 'Option 2', disabled: true },
    { value: 3, text: 'Option 3' },
    { value: 4, text: 'Option 4' },
  ];

  public valueChange(value: boolean | string | number): void {
    console.warn('checkbox change value', value);
  }
}
