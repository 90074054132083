import { SurveyDto } from '../../dto/survey/survey.dto';
import { Survey } from './survey';
import { createTableRow, GridRowType, ITableRow } from '../../../../common/common-grid.interfaces';

export class SurveyFactory {
  public static createRowListFromDtos(surveyDtoArray: SurveyDto[], scenarioId: number): ITableRow<Survey>[] {
    const rows = surveyDtoArray.map((dto, index) => this.createElement(dto, index));
    const insertRow = this.createEmptyRow(scenarioId, rows.length, false, 'insert-row');
    return [...rows, insertRow];
  }

  public static createElement(
    surveyDto: SurveyDto,
    rowIndex: number,
    isEditingDisabled = false,
    rowType: GridRowType = 'data',
  ): ITableRow<Survey> {
    if (rowIndex === 0) {
      rowType = 'first-row';
      isEditingDisabled = true;
    }
    return createTableRow<Survey>(this.createSurvey(surveyDto), rowType, rowIndex, isEditingDisabled);
  }

  public static createEmpty(scenarioId: number, surfaceTemp = 0): Survey {
    return {
      ScenarioId: scenarioId,
      Id: -1,
      Deviation: 0,
      Azimuth: 0,
      MD: 0,
      TVD: 0,
      DownPathTemp: surfaceTemp,
      UpPathTemp: surfaceTemp,
      SortOrder: -1,
    };
  }

  public static createEmptyRow(
    scenarioId: number,
    rowIndex: number,
    isEditingDisabled: boolean,
    rowType: GridRowType,
    surfaceTemp = 0,
  ): ITableRow<Survey> {
    return createTableRow(this.createEmpty(scenarioId, surfaceTemp), rowType, rowIndex, isEditingDisabled);
  }

  public static toDto(survey: Survey): SurveyDto {
    const surveyDto: SurveyDto = {
      ScenarioId: survey.ScenarioId,
      Id: survey.Id,
      Deviation: survey.Deviation,
      Azimuth: survey.Azimuth,
      MD: survey.MD,
      TVD: survey.TVD,
      DownPathTemp: survey.DownPathTemp,
      UpPathTemp: survey.UpPathTemp,
      SortOrder: survey.SortOrder,
    };
    return surveyDto;
  }

  public static createSurvey(surveyDto: SurveyDto): Survey {
    const survey: Survey = {
      ...surveyDto,
    };
    return survey;
  }
}
