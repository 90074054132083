import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { BackendConnectionService } from '../../shared/backend-connection/backend-connection.service';
import { BaseWsEffects } from '../base-ws.effects';
import {
  GravelCopyRowAction,
  GravelDeleteRowsAction,
  GravelInsertRowsAction,
  GravelModuleName,
  GravelUpdateRowAction,
} from '@dunefront/common/modules/gravel/gravel-module.actions';
import * as actions from '../gravel/gravel.actions';
import { selectGravelAction } from '../gravel/gravel.actions';
import { filter, mergeMap, tap } from 'rxjs/operators';
import { getGravelIdFromUrl, getGravelNames, getLastGravelId, getSelectedGravelIdFromState } from './gravel.selectors';
import { deleteRowsSuccess, insertRowsSuccess } from '../app.actions';
import { combineLatest } from 'rxjs';
import { GeneralCalculations } from '@dunefront/common/common/general.calculations';
import { GravelHelper } from '@dunefront/common/modules/gravel/dto/gravel.dto';
import { ModalService } from '../../common-modules/modals/modal.service';
import { GravelFactory } from '@dunefront/common/modules/gravel/model/gravel.factory';
import { RouterHelperService } from '../../shared/services/router-helper.service';
import { WsActionPropsFactory } from '@dunefront/common/common/ws-action/ws-action-props.factory';
import { CommonDbCopyDirection } from '@dunefront/common/modules/common.actions';

@Injectable({ providedIn: 'root' })
export class GravelEffects extends BaseWsEffects {
  constructor(
    actions$: Actions,
    store: Store,
    wsService: BackendConnectionService,
    modalService: ModalService,
    private routerHelperService: RouterHelperService,
  ) {
    super(actions$, wsService, GravelModuleName, false, true, modalService, store);

    const gravelIdFromUrl$ = this.store.select(getGravelIdFromUrl).pipe(filter((gravelId) => gravelId > 0));
    const gravelIdFromState$ = this.store.select(getSelectedGravelIdFromState);

    combineLatest([gravelIdFromUrl$, gravelIdFromState$])
      .pipe(filter(([fromUrl, fromState]) => fromUrl !== fromState))
      .subscribe(([fromUrl]) => this.store.dispatch(selectGravelAction({ gravelId: fromUrl })));
  }

  public updateGravelRow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.updateGravel),
      mergeMap((action) => this.emitUpdate(new GravelUpdateRowAction(action))),
    ),
  );

  public insertGravelRow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.newGravel),
      concatLatestFrom(() => [this.store.select(getLastGravelId), this.store.select(getGravelNames)]),
      filter(([action, lastGravelId, GravelNames]) => {
        const isGravelNameAlreadyExist = GeneralCalculations.DoesArrayContainsString(GravelNames, GravelHelper.DEFAULT_NAME);
        if (isGravelNameAlreadyExist) {
          this.modalService.showAlert(`${GravelHelper.DEFAULT_NAME} is already in the list - please rename it first.`).then();
        }
        return !isGravelNameAlreadyExist;
      }),
      mergeMap(([action, lastGravelId]) =>
        this.emitInsert(
          new GravelInsertRowsAction(
            WsActionPropsFactory.insertDto(
              GravelFactory.gravelToDto(GravelFactory.newGravel(action.scenarioId, GravelHelper.DEFAULT_NAME)),
              false,
              'insert',
              lastGravelId,
            ),
          ),
        ),
      ),
    ),
  );

  public copyGravelRow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.copyGravel),
      concatLatestFrom(() => [this.store.select(getLastGravelId), this.store.select(getGravelNames)]),
      mergeMap(([action]) => this.emitInsert(new GravelCopyRowAction(action.gravelId, action.scenarioId))),
    ),
  );

  public deleteGravelRow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.deleteGravel),
      mergeMap((action) => this.emitDelete(new GravelDeleteRowsAction(action))),
    ),
  );

  public redirectAfterGravelCrudSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(insertRowsSuccess, deleteRowsSuccess),
        filter((action) => action.affectedRows.gravel != null && action.commonDbCopyDirection != CommonDbCopyDirection.toCommonDb),
        concatLatestFrom(() => this.store.select(getSelectedGravelIdFromState)),
        tap(([, gravelId]) => this.routerHelperService.navigateToGravel(gravelId)),
      ),
    { dispatch: false },
  );
}
