<div class="grid-form-container">
  <div class="grid-two-one">
    <app-radio-group
      id="pressureDefinitionRadio"
      [items]="pressureDefRadioItems"
      layout="vertical"
      [isUiLockable]="false"
      [key]="'ZonePressureType'"
      [source]="state"
      dataCy="pressure-definition"
      (valueChanged)="propertyChanged.emit($event)"
    >
    </app-radio-group>
  </div>
</div>
