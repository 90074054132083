import { createSelector, MemoizedSelector } from '@ngrx/store';
import { getIsFluidStateLoaded, getValidatedFluidModuleState } from './fluid/fluid.selectors';
import { getIsGravelStateLoaded, getValidatedGravelModuleState } from './gravel/gravel.selectors';
import { getIsPumpingStateLoaded } from './pumping/selectors/pumping.selectors';
import { getCurrentScenarioId, getIsScenarioStateLoaded } from './scenario/scenario.selectors';
import { getIsWellStateLoaded } from './well/well.selectors';
import { DictionaryWithArray } from '@dunefront/common/common/state.helpers';
import { getValidatedPumpingModuleState } from './pumping/selectors/pumping-validation.selectors';
import { IValidatedSimulateEvaluateState, IValidatedState } from '@dunefront/common/modules/validation/validation-helpers';
import { getIsSettingsStateLoaded, getValidatedDeveloperSettings, getValidatedSettings } from './settings/validated-settings.selectors';
import { getIsCompletionStateLoaded, getValidatedCompletionModuleState } from './completion/validated-completion.selectors';
import { getGaugeDataPageValidation } from './gauge-data/gauge-data.selectors';
import { getValidatedDataAnalysisComponentSelectItems } from './pumping/selectors/evaluate-pumping-schedule-analysis-data-component.selectors';
import { getValidatedTrendAnalysisRange } from './trend-analysis/trend-analysis-page.selectors';
import { getValidatedWellModuleState } from './well/validated-well.selectors';
import { getCurrentRangeId } from './range/range.selectors';
import { getCurrentAppModuleType } from './ui/ui.selectors';

export const getIsAppStateLoaded = createSelector(
  getIsSettingsStateLoaded,
  getIsCompletionStateLoaded,
  getIsFluidStateLoaded,
  getIsGravelStateLoaded,
  getIsPumpingStateLoaded,
  getIsScenarioStateLoaded,
  getIsWellStateLoaded,
  (...states) => states.every((isLoaded) => isLoaded),
);

export const getScenarioOrRangeLoaded = createSelector(
  getCurrentScenarioId,
  getCurrentRangeId,
  getCurrentAppModuleType,
  getIsAppStateLoaded,
  (...[scenario, range, appModuleType, appStateLoaded]) => {
    return { scenario, range, appModuleType, appStateLoaded };
  },
);

const getValidatedSimulateEvaluateState = createSelector(
  getValidatedWellModuleState,
  getValidatedCompletionModuleState,
  getValidatedFluidModuleState,
  getValidatedSettings,
  getValidatedGravelModuleState,
  getValidatedPumpingModuleState,
  getValidatedDeveloperSettings,
  getGaugeDataPageValidation,

  (
    ...[wellState, completionState, fluidsState, settings, gravelState, pumpingState, developerSettings, gaugeDataPageValidation]
  ): IValidatedSimulateEvaluateState => ({
    wellState,
    completionState,
    fluidsState,
    settings,
    gravelState,
    pumpingState,
    developerSettings,
    gaugeDataPageValidation,
  }),
);

export const getValidatedState = createSelector(
  getValidatedSimulateEvaluateState,
  getValidatedTrendAnalysisRange,
  getValidatedDataAnalysisComponentSelectItems,
  (...[validatedSimulateEvaluateState, validatedRange, validatedPumpRateAndPressure]): IValidatedState => ({
    ...validatedSimulateEvaluateState,
    validatedScenarioRangeProperties: validatedRange,
    validatedPumpRateAndPressure,
  }),
);

// eslint-disable-next-line @typescript-eslint/ban-types
export const getIsFluidAndGravelValid = (fluidId: number | null, gravelId?: number | null): MemoizedSelector<object, string> => {
  return createSelector(getValidatedFluidModuleState, getValidatedGravelModuleState, (...[fluidState, gravelState]): string => {
    const invalidFields: string[] = [];
    const fluid = DictionaryWithArray.get(fluidState.Fluids, fluidId);
    if (fluid && !fluid.isValid) {
      invalidFields.push('fluid');
    }
    if ((gravelId ?? 0) > 0 && DictionaryWithArray.get(gravelState.Gravels, gravelId)?.isValid === false) {
      invalidFields.push('gravel');
    }
    return invalidFields.length > 0 ? `There are errors in the following selections: ${invalidFields.join(', ')}.` : '';
  });
};
