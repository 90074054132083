import { Injectable } from '@angular/core';
import { BaseWsEffects } from '../base-ws.effects';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { BackendConnectionService } from '../../shared/backend-connection/backend-connection.service';
import { ModalService } from '../../common-modules/modals/modal.service';
import {
  DataStorageDataLoadAction,
  DataStorageDataLoadActionResponse,
  DataStorageDataReduceDataAction,
  DataStorageModuleName,
} from '@dunefront/common/modules/data-storage/data-storage-module.actions';
import { catchError, delay, filter, map, mergeMap, tap } from 'rxjs/operators';
import { dataFailed, deleteRowsSuccess, insertRowsSuccess, updateRowSuccess } from '../app.actions';
import { combineLatest, of } from 'rxjs';
import { loadGaugeDataAction, loadGaugeDataSuccess, selectGaugeDataFileAction } from './gauge-data.actions';
import { ImportFileDto } from '@dunefront/common/modules/data-storage/dto/import-file.dto';
import { reduceDataAction } from '../data-storage/data-storage.actions';
import {
  getGaugeDataFileIdFromUrl,
  getGaugeDataSelectedFileId,
  getSelectedGaugeDataFileIdFromState,
  getValidatedStorageFilesWithColumnsForSelectedFile,
} from './gauge-data.selectors';
import { CrudResponse } from '@dunefront/common/modules/common.actions';
import { DataFileType } from '@dunefront/common/dto/data-storage';
import { RouterHelperService } from '../../shared/services/router-helper.service';
import { getStorageFiles } from '../data-storage/data-storage.selectors';
import { getCurrentRangeId } from '../range/range.selectors';
import { filterNil } from '@dunefront/common/common/state.helpers';

@Injectable()
export class GaugeDataEffects extends BaseWsEffects {
  constructor(
    actions$: Actions,
    store: Store,
    wsService: BackendConnectionService,
    modalService: ModalService,
    private routerHelperService: RouterHelperService,
  ) {
    super(actions$, wsService, DataStorageModuleName, false, true, modalService, store);

    const fileIdFromUrl$ = this.store.select(getGaugeDataFileIdFromUrl);
    const fileIdFromState$ = this.store.select(getSelectedGaugeDataFileIdFromState);

    combineLatest([fileIdFromUrl$, fileIdFromState$, this.store.select(getStorageFiles)])
      .pipe(filter(([fromUrl, fromState, files]) => fromUrl > 0 && fromUrl !== fromState))
      .subscribe(([fromUrl, fromState, files]) => {
        const fileId = files.ids.includes(fromUrl + '') ? fromUrl : fromState;
        this.store.dispatch(selectGaugeDataFileAction({ fileId, src: 'GaugeDataEffects - combineLatest' }));
      });
  }

  public loadGaugeDataFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadGaugeDataAction),
      concatLatestFrom(() => this.store.select(getCurrentRangeId)),
      mergeMap(([action, currentRangeId]) =>
        this.emit<DataStorageDataLoadActionResponse>(new DataStorageDataLoadAction(action.fileId, currentRangeId)).pipe(
          concatLatestFrom(() => this.store.select(getValidatedStorageFilesWithColumnsForSelectedFile)),
          filter(([response, fileWithColumns]) => response.payload.fileId === fileWithColumns?.file?.Id),
          map(([response, fileWithColumns]) =>
            loadGaugeDataSuccess({
              data: response.payload.data,
              columns: fileWithColumns?.columns ?? [],
            }),
          ),
          catchError((err) => of(dataFailed(err))),
        ),
      ),
    ),
  );

  public deleteColumnSuccessAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteRowsSuccess),
      filter((action) => !!action.affectedRows.importColumns),
      delay(250), // wait for delete modal to close
      map((action) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const fileId = action.affectedRows.importColumns!.parentId!;
        return loadGaugeDataAction({ fileId });
      }),
    ),
  );

  public updateRowsSuccessRebuildGaugeDataGrid$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateRowSuccess),
      concatLatestFrom(() => this.store.select(getValidatedStorageFilesWithColumnsForSelectedFile)),
      filter(([action, f]) => !!action.affectedRows.importColumns && !!f),
      delay(250), // wait for delete modal to close
      map(([action, f]) =>
        selectGaugeDataFileAction({
          fileId: (f?.file as ImportFileDto).Id,
          src: 'updateRowsSuccessRebuildGaugeDataGrid$',
        }),
      ),
    ),
  );

  /**
   * Triggers loadGaugeDataAction whenever StartDate of currently selected file in Gauge Data is changed
   */
  public gaugeDataSelectedFileStartDateChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateRowSuccess),
      concatLatestFrom(() => [this.store.select(getGaugeDataSelectedFileId)]),
      map(([action, selectedFileId]) => {
        const importColumns = action.affectedRows.importColumns;
        if (importColumns == null || !importColumns.colIds.includes('StartDate')) {
          return undefined;
        }

        const wasSelectedFileUpdated = importColumns.rows.find((c) => c.FileId === selectedFileId) != null;

        return wasSelectedFileUpdated ? selectedFileId : undefined;
      }),
      filterNil(),
      map((fileId) => loadGaugeDataAction({ fileId })),
    ),
  );

  public reduceDataAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(reduceDataAction),
      mergeMap((action) =>
        this.emit<CrudResponse>(
          new DataStorageDataReduceDataAction(action.fileId, action.samplingFrequency),
          'Reduce Data',
          'Sampling data, please wait',
        ).pipe(
          mergeMap((result) => [updateRowSuccess(result.payload), loadGaugeDataAction({ fileId: action.fileId })]),
          catchError((err) => of(dataFailed(err))),
        ),
      ),
    ),
  );

  public redirectAfterFileDeletedCrudSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(deleteRowsSuccess, insertRowsSuccess),
        filter((action) => {
          if (action.affectedRows.importFiles == null) {
            return false;
          }
          return action.affectedRows.importFiles.insertedRows?.some((row) => row.FileType === DataFileType.EquationResult) ?? false;
        }),
        concatLatestFrom(() => this.store.select(getSelectedGaugeDataFileIdFromState)),
        tap(([, fileId]) => this.routerHelperService.navigateToFile(fileId)),
      ),
    { dispatch: false },
  );
}
