import { createSelector } from '@ngrx/store';
import { getGaugeDataLeftNavElement } from '../gauge-data-page/gauge-data.menu';
import { ILeftNavElement, ILeftNavItem } from '../../shared/components/left-nav/left-nav.helpers';
import { getSortedReportingTabsForCurrentRange } from '../../+store/reporting/reporting.selectors';
import { getAnyFilesImported } from '../../+store/data-storage/data-storage.selectors';
import { getReportingLeftMenu } from '../common/reporting/reporting-left-menu.helper';
import { getSettingsLeftNavElement } from '../common/settings/settings.menu';

const getDataAnalysisReportingLeftNavElement = createSelector(
  getSortedReportingTabsForCurrentRange,
  getAnyFilesImported,
  (reportingTabs, anyFilesImported): ILeftNavElement => getReportingLeftMenu(reportingTabs, !anyFilesImported),
);

export const getDataAnalysisModuleMenuData = createSelector(
  getGaugeDataLeftNavElement,
  getDataAnalysisReportingLeftNavElement,
  getSettingsLeftNavElement,
  (gaugeDataLeftNavElement, reportingLeftNavElement, settings): ILeftNavItem[] => [
    gaugeDataLeftNavElement,
    settings,
    reportingLeftNavElement,
  ],
);
