import { BasePptSectionGenerator, PptContext } from './base-ppt-section-generator';
import { Base64Media, ImageSize } from '../../../../../common-modules/chart/image-provider.helpers';
import { contentPosition, NumericPositionProps } from './style-constants';
import { calcFittingRect, Rect } from '@dunefront/common/common/math-geometry-helpers';

export class MediaPptGenerator extends BasePptSectionGenerator {
  constructor(protected override context: PptContext & { media?: Base64Media; title: string; subTitle?: string }) {
    super(context);
  }

  public async generate(): Promise<void> {
    const { media, title, subTitle, pptx } = this.context;
    if (media == null) {
      return;
    }

    if (media.videoDataBase64 != null && media.videoDataBase64.length > 0) {
      const slide = BasePptSectionGenerator.addSlide(pptx, { title, subTitle });
      const mediaPosition = this.calcMediaFittingRect(media.size, contentPosition);

      slide.addMedia({
        ...mediaPosition,
        type: 'video',
        data: media.videoDataBase64,
        cover: media.imageDataBase64,
      });
    } else if (media.imageDataBase64.length > 0) {
      this.addImageSlide({ title, subTitle, image: media });
    }
  }

  private calcMediaFittingRect(size: ImageSize, container: NumericPositionProps): NumericPositionProps {
    const contentRect: Rect = {
      x: container.x,
      y: container.y,
      width: container.w,
      height: container.h,
    };

    const mediaRect = calcFittingRect(size, contentRect);

    return {
      ...mediaRect,
      w: mediaRect.width,
      h: mediaRect.height,
    };
  }
}
