import { DictionaryWithArray } from '@dunefront/common/common/state.helpers';
import { Gravel } from '@dunefront/common/modules/gravel/model/gravel';
import { getCurrentAppModuleType } from '../ui/ui.selectors';
import { getCurrentRangeId } from '../range/range.selectors';
import { getFluidUsageSimulateEvaluateFinderDeps } from '../pumping/selectors/fluid-and-gravel-delete.selectors';
import { getValidatedGravelModuleState } from './gravel.selectors';
import { createSelector } from '@ngrx/store';
import { isSimulateBased, ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import { FluidGravelUsageFinder } from '@dunefront/common/modules/validation/fluid-gravel-usage-finder';
import { isCalculator } from '../menu-selectors/menu-selectors.helpers';
import { getFluidAndGravelUsageCalculatorsDeps } from '../fluid/fluid-delete.selectors';

export const getGravelsUsedInSelectedModule = createSelector(
  getValidatedGravelModuleState,
  getFluidUsageSimulateEvaluateFinderDeps,
  getFluidAndGravelUsageCalculatorsDeps,
  getCurrentRangeId,
  getCurrentAppModuleType,
  (gravelState, simulateEvaluateDeps, calculatorsDeps, rangeId, module) => {
    const result: Gravel[] = [];
    const gravels = DictionaryWithArray.getArray(gravelState.Gravels);

    const isSimulateEvaluate = isSimulateBased(module) || module === ModuleType.Evaluate;
    const isCalc = isCalculator(module);

    for (const gravel of gravels) {
      // current module is Simulate/Evaluate and this gravel is used in it
      const isSimulateEvaluateAndIsUsed =
        isSimulateEvaluate && FluidGravelUsageFinder.FindGravelInPumping(gravel.Id, simulateEvaluateDeps, rangeId).length > 0;

      // current module is Calculators and this gravel is used in it
      const isCalculatorsAndIsUsed = isCalc && FluidGravelUsageFinder.FindGravelInCalculators(gravel.Id, calculatorsDeps).length > 0;

      if (isSimulateEvaluateAndIsUsed || isCalculatorsAndIsUsed) {
        result.push(gravel);
      }
    }

    return result;
  },
);

export const getGravelsNotUsedInSelectedModule = createSelector(
  getValidatedGravelModuleState,
  getGravelsUsedInSelectedModule,
  (gravelState, usedGravels) => {
    const allGravels = DictionaryWithArray.getArray(gravelState.Gravels);

    return allGravels.filter((gravel) => !usedGravels.includes(gravel));
  },
);

export const getAreGravelsUsedInSelectedModuleValid = createSelector(getGravelsUsedInSelectedModule, (usedGravels) => {
  return usedGravels.every((g) => g.isValid);
});

export const getAreGravelsNotUsedInSelectedModuleValid = createSelector(getGravelsNotUsedInSelectedModule, (unusedGravels) => {
  return unusedGravels.every((g) => g.isValid);
});
