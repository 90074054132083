import { Injectable } from '@angular/core';
import {
  CalculationEngineActionTypes,
  CalculationEngineCrashRunningJob,
  CalculationEngineModuleActions,
  CalculationEngineModuleName,
  CancelJobsAction,
  ListUserJobsAction,
  UserJobs,
} from '@dunefront/common/modules/calculation-engine/calculation-engine.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import { BackendConnectionService } from '../../shared/backend-connection/backend-connection.service';
import {
  calculationProgressUpdatedAction,
  cancelJobsAction,
  listUserJobsAction,
  redisConnectionStatusChangedAction,
  stopCalculationsAction,
  userJobsUpdatedAction,
} from './calculation-engine.actions';
import { dataFailed } from '../app.actions';
import { BaseWsEffects } from '../base-ws.effects';
import { ModalService } from '../../common-modules/modals/modal.service';
import { backendConnectedAction, dbConnectedSuccessAction } from '../backend-connection/backend-connection.actions';
import { triggerCalcEngineLongWorkerError } from '../error-testing/error-testing.actions';

@Injectable()
export class CalculationEngineEffects extends BaseWsEffects {
  constructor(actions$: Actions, store: Store, wsService: BackendConnectionService, modalService: ModalService) {
    super(actions$, wsService, CalculationEngineModuleName, true, false, modalService, store);
  }

  /**
   * Load user jobs once connected
   */
  private backendConnectedAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(backendConnectedAction, dbConnectedSuccessAction),
      map(() => listUserJobsAction()),
    ),
  );

  /**
   * User jobs loaded
   */
  private listUserJobsAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(listUserJobsAction),
      mergeMap(() =>
        this.emit<UserJobs>(new ListUserJobsAction()).pipe(
          map(({ payload: userJobs }) => userJobsUpdatedAction({ payload: { userJobs } })),
          catchError((err) => of(dataFailed(err))),
        ),
      ),
    ),
  );

  /**
   * Cancel jobs
   */
  private cancelJobsAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(cancelJobsAction),
        filter((action) => action.jobIds.length > 0),
        mergeMap((action) => this.emit(new CancelJobsAction(action.jobIds)).pipe(catchError((err) => of(dataFailed(err))))),
      ),
    { dispatch: false },
  );

  /**
   * Trigger Calc Engine Long Worker Error
   * @private
   */
  private triggerCalcEngineLongWorkerErrorAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(triggerCalcEngineLongWorkerError),
        mergeMap((action) =>
          this.emit(new CalculationEngineCrashRunningJob(action.jobId, action.ignoreSentry)).pipe(catchError((err) => of(dataFailed(err)))),
        ),
      ),
    { dispatch: false },
  );

  private onStopCalculationsAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(stopCalculationsAction),
      map((action) => cancelJobsAction({ fileHash: action.fileHash, jobIds: action.jobIds })),
    ),
  );

  protected override onIncomingMessage(action: CalculationEngineModuleActions, fileHash: string | undefined): void {
    switch (action.type) {
      case CalculationEngineActionTypes.RedisConnectionChanged:
        this.store.dispatch(redisConnectionStatusChangedAction({ status: action.status }));
        break;

      case CalculationEngineActionTypes.QueueUpdate:
        this.store.dispatch(userJobsUpdatedAction({ payload: { userJobs: action.payload } }));
        break;

      case CalculationEngineActionTypes.CalculationUpdate:
        if (fileHash != null) {
          this.store.dispatch(
            calculationProgressUpdatedAction({
              jobPayload: action.payload,
              currentFileHash: fileHash,
            }),
          );
        }
        break;
    }
  }
}
