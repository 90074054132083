<div class="dialog-content grid-container">
  <div class="grid-two-one top-bar-container">
    <label>Source Scenario</label>
    <app-scenario-manager-selector
      [value]="sourceScenarioId"
      (valueChanged)="updateSourceScenarioId($event)"
      [dataCy]="'clone-operations-scenario-manager-selector'"
    ></app-scenario-manager-selector>
    <div class="clone-button-panel">
      <button
        type="button"
        class="btn btn-primary btn-sm"
        appButton
        buttonType="primary"
        data-cy="scenario-clone-operations-clone"
        [disabled]="selectedTreeNodes.length === 0 || targetScenarioIds.length === 0"
        [isDeleteResultsEnabled]="false"
        (buttonClick)="onCloneClicked()"
      >
        Clone
      </button>
    </div>
  </div>
  <app-page-panel header="Select Data to Clone" class="clone-tree-container scrollable-container" [allowFullScreen]="false">
    <app-tree
      [nodes]="treeNodes"
      [isCheckboxMode]="true"
      [selection]="selectedTreeNodes"
      (selectionChange)="onSelectionChange($event)"
    ></app-tree>
  </app-page-panel>
  <app-page-panel header="Select Target Scenario(s)" class="scrollable-container" [allowFullScreen]="false">
    <app-scenario-manager-checkboxes
      [selectedIds]="targetScenarioIds"
      [excludedValues]="[sourceScenarioId]"
      (valueChanged)="updateSelectedIds($event)"
      [labelWidth]="190"
    >
    </app-scenario-manager-checkboxes>
  </app-page-panel>
</div>
