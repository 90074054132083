<ng-container *ngIf="pumpingState.isLoaded">
  <app-page-panel header="Schedule Generator" (click)="onHelpChange('pumping', 'schedule-generator')">
    <app-schedule-generator
      [pumping]="pumpingState.pumping"
      (dataChange)="onPumpingPropertyChanged($event)"
      [treatmentType]="(wellState$ | async)?.TreatmentType"
    ></app-schedule-generator>
  </app-page-panel>
  <app-page-panel header="Automatic Scheduling" (click)="onHelpChange('pumping', 'automatic-scheduling')">
    <app-automatic-scheduling [pumping]="pumpingState.pumping" (dataChange)="onPumpingPropertyChanged($event)"></app-automatic-scheduling>
  </app-page-panel>
</ng-container>
