import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import * as completionActions from '../../../../+store/completion/completion.actions';
import { updateLowerCompletionRow } from '../../../../+store/completion/completion.actions';
import { Store } from '@ngrx/store';
import { ITableRow, ITableState } from '@dunefront/common/common/common-grid.interfaces';
import { ModalService } from '../../../../common-modules/modals/modal.service';
import { PipeType } from '@dunefront/common/dto/pipe.dto';
import { BaseCompletionComponent } from '../base-completion.component';
import { ScreenService } from '../../../../shared/services';
import { IcdPortDataDto } from '@dunefront/common/dto/icd-port-data.dto';
import { DictionaryWithArray } from '@dunefront/common/common/state.helpers';
import * as uiActions from '../../../../+store/ui/ui.actions';
import { isScreenPipe, ScreenPipe } from '@dunefront/common/modules/pipes/lower-completion-pipes/pipes/screen-pipe';
import { Pipe } from '@dunefront/common/modules/pipes/pipe';
import { IUpdateTableRowsProps } from '@dunefront/common/common/common-store-crud.interfaces';
import { PanelHelpMode } from '../../../../shared/components/help-button/help-buton.component';

@Component({
  selector: 'app-lower-completion',
  templateUrl: './lower-completion.component.html',
  styleUrls: ['./lower-completion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LowerCompletionComponent extends BaseCompletionComponent {
  public PanelHelpMode = PanelHelpMode;
  constructor(store: Store, cdRef: ChangeDetectorRef, modalService: ModalService, resizeService: ScreenService) {
    super(store, cdRef, modalService, resizeService);
    super.onHelpChange('completion', 'gravel-pack-packer');
    store.dispatch(uiActions.setCompletionTabAction({ tab: 'lower-completion' }));
  }

  public get selectedScreen(): ITableRow<ScreenPipe> | undefined {
    if (this.selectedRow && isScreenPipe(this.selectedRow.rowData)) {
      return this.selectedRow as ITableRow<ScreenPipe>;
    }
    return undefined;
  }

  public get selectedIcdPortData(): ITableState<IcdPortDataDto> | undefined {
    if (this.selectedRow && isScreenPipe(this.selectedRow.rowData)) {
      const lowerCompletionId = this.selectedRow.rowData.Id;
      const icdPortData = DictionaryWithArray.get(this.completionState.IcdPortData, lowerCompletionId);
      if (icdPortData) {
        return icdPortData;
      }
    }
    return undefined;
  }

  public get isPipeDataDisabled(): boolean {
    return !this.selectedRow || this.selectedRow.rowData.PipeType === PipeType.Open_Hole;
  }

  public getRows(): ITableRow<Pipe>[] {
    return this.completionState?.LowerCompletion.rows ?? [];
  }

  public onLowerCompletionScreenPipePropertyChanged(props: IUpdateTableRowsProps<Pipe>): void {
    this.store.dispatch(completionActions.updateLowerCompletionRow(props));
  }

  public onIcdPortDataPropertyChanged(props: IUpdateTableRowsProps<IcdPortDataDto>): void {
    this.store.dispatch(completionActions.updateIcdPortDataRow(props));
  }

  protected updateRow(props: IUpdateTableRowsProps<Pipe>): void {
    this.store.dispatch(updateLowerCompletionRow(props));
  }
}
