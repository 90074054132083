import { createSelector } from '@ngrx/store';
import { getWellModuleState } from '../well/well.selectors';
import { PipeType } from '@dunefront/common/dto/pipe.dto';
import { getCompletionModuleState } from '../completion/completion.selectors';
import { DataType } from '@dunefront/common/dto/data-storage';
import { FlowPathDescription } from '@dunefront/common/modules/pumping/dto/well-fluid.dto';
import { getFluidModuleState } from '../fluid/fluid.selectors';
import { DictionaryWithArray } from '@dunefront/common/common/state.helpers';
import { getRowsForCalculations, getRowsWithoutInsertRow } from '@dunefront/common/common/common-grid.interfaces';
import { Survey } from '@dunefront/common/modules/well/model/survey/survey';
import { Pipe } from '@dunefront/common/modules/pipes/pipe';
import { Fluid } from '@dunefront/common/modules/fluid/model/fluid';
import { getSelectedFluidFrontData, getSelectedPackingData, ISelectedDepthData } from './reporting-get-depth-chart-state.selector';
import { getPumpingDirection, getPumpingToolPosition } from '../pumping/selectors/pumping.selectors';
import { PumpingDirection, ToolPosition } from '@dunefront/common/modules/pumping/dto/pumping.dto';
import { CalculatedCaliper, CaliperDataHelper } from '@dunefront/common/modules/well/model/caliper/caliper-data.helper';
import {
  isConcentrationWellVisualizationType,
  isStreamLinesBasedWellVisualizationType,
  WellVisualizationType,
} from '@dunefront/common/modules/settings/dto/settingsDto';
import { Color } from '@dunefront/common/modules/reporting/dto/chart.types';
import { getWellVisualizationType } from '../settings/settings.selectors';
import { FpFlowRegime } from '@dunefront/common/modules/fluid-pro/fluid-pro.model';
import { EnumHelpers } from '@dunefront/common/utils/enum.helpers';
import { DrawingLegendItem } from '../../pages/simulate-evaluate-page/results/drawing/well-drawing-helpers';
import { IDepthBasedColumn, IDepthBasedRow } from '@dunefront/common/modules/reporting/reporting-module.actions';
import { replaceNonAsciiWithQuestionMark } from '@dunefront/common/common/string-helpers';

export interface IDrawingError {
  error: string;
}

export function isDrawingError(drawing: IDrawing | IDrawingError): drawing is IDrawingError {
  return drawing != null && 'error' in drawing;
}

export function isDrawing(drawing: IDrawing | IDrawingError): drawing is IDrawing {
  return drawing != null && !('error' in drawing);
}

export function getPackingData({ Columns: columns, Rows: rows }: ISelectedDepthData): IPackingResult[] {
  const flowPathDescriptionIndex = getColIdx(columns, DataType.Flow_Path_Description);
  const packHeightIndex = getColIdx(columns, DataType.Pack_Height);
  const packRatioIndex = getColIdx(columns, DataType.Pack_Height_Ratio);

  if (packHeightIndex == -1 || packRatioIndex == -1) {
    return [];
  }

  return rows
    .filter(
      (row) =>
        row.Values[flowPathDescriptionIndex][0] === FlowPathDescription.Lower_Annulus ||
        row.Values[flowPathDescriptionIndex][0] === FlowPathDescription.Workstring,
    )
    .sort((row1, row2) => row1.MD - row2.MD)
    .map((row) => ({
      packingHeight: row.Values[packHeightIndex][0],
      packingRatio: row.Values[packRatioIndex][0],
      topMd: row.MD,
    }));
}

function getColIdx(columns: IDepthBasedColumn[], dataType: DataType): number {
  return columns.findIndex((col) => col.DataType == dataType);
}

export const getFluidFronts = createSelector(getSelectedFluidFrontData, ({ isLoaded, data }) => {
  const result: IFluidFront[] = [];
  if (!isLoaded || data == null) {
    return result;
  }

  const { Rows: rows, Columns: columns } = data;

  const flowDirectionColumnIndex = getColIdx(columns, DataType.Flow_Direction);
  const flowPathDescriptionColumnIndex = getColIdx(columns, DataType.Flow_Path_Description);
  const fluidFrontColumnIndex = getColIdx(columns, DataType.Fluid_Front);

  for (const row of rows) {
    result.push({
      md: row.MD,
      flowDirection: row.Values[flowDirectionColumnIndex][0],
      flowPathDescription: row.Values[flowPathDescriptionColumnIndex][0],
      fluidId: row.Values[fluidFrontColumnIndex][0],
    });
  }

  return result;
});

export const getDrawing = createSelector(
  getWellModuleState,
  getCompletionModuleState,
  getSelectedPackingData,
  getFluidModuleState,
  getPumpingToolPosition,
  getPumpingDirection,
  getWellVisualizationType,
  getFluidFronts,
  (
    wellState,
    completionState,
    selectedPackingData,
    fluidState,
    toolPosition,
    pumpingDirection,
    wellVisualizationType,
    fluidFronts,
  ): IDrawing | IDrawingError | undefined => {
    if (!selectedPackingData.isLoaded) {
      return undefined;
    }

    const depthData = selectedPackingData.data;
    if (depthData == null) {
      return { error: 'There is no data available' };
    }

    if (wellState.CasingData.rows.length < 2) {
      return { error: 'Wrong Casing Data' };
    }

    const survey = getRowsForCalculations(wellState.SurveyData.rows);
    const lowerCompletion = getRowsForCalculations(completionState.LowerCompletion.rows);
    const lowerCompletionBottomMD = Math.max(...lowerCompletion.map((a) => a.BottomMD));
    const runningString = getRowsForCalculations(completionState.RunningString.rows);
    const calipers = getRowsForCalculations(getRowsWithoutInsertRow(wellState.CaliperData.rows));
    const caliperAveraged = CaliperDataHelper.calculateCaliperAveraged(calipers, wellState.CaliperTolerance);

    const hole: IDrawingHoleSection[] = [];
    for (const { rowData: casingRow } of wellState.CasingData.rows) {
      if (casingRow.TopMD >= lowerCompletionBottomMD) {
        continue;
      }

      const bottomMd = Math.min(casingRow.BottomMD, lowerCompletionBottomMD);

      if (casingRow.PipeType === PipeType.Open_Hole) {
        const firstCaliperIndex = caliperAveraged.findIndex(
          (caliper, index) =>
            caliper.TopMD <= casingRow.TopMD && index < caliperAveraged.length - 1 && caliperAveraged[index + 1].TopMD > casingRow.TopMD,
        );

        for (let i = firstCaliperIndex; i >= 0 && i < caliperAveraged.length; i++) {
          const caliperRow = caliperAveraged[i];

          let nextCaliperRow: CalculatedCaliper | undefined = caliperAveraged[i + 1];
          if (nextCaliperRow != null && nextCaliperRow.TopMD >= bottomMd) {
            nextCaliperRow = undefined;
          }

          // use Caliper to set hole diameter
          hole.push({
            topMd: caliperRow.TopMD < casingRow.TopMD ? casingRow.TopMD : caliperRow.TopMD,
            bottomMd: nextCaliperRow ? nextCaliperRow.TopMD : bottomMd,
            diameter: caliperRow.Diameter,
          });

          if (!nextCaliperRow) {
            break;
          }
        }
      } else if (
        casingRow.PipeType === PipeType.Casing ||
        casingRow.PipeType === PipeType.Perforated_Casing ||
        casingRow.PipeType === PipeType.Riser
      ) {
        hole.push({
          topMd: casingRow.TopMD,
          bottomMd,
          diameter: casingRow.InnerDiameter,
        });
      }
    }

    const packingData = getPackingData(depthData);

    const upperPartBottomMd = completionState.LowerCompletion.rows[0].rowData.TopMD;
    const lowerPartBottomMd = completionState.LowerCompletion.rows[completionState.LowerCompletion.rows.length - 2].rowData.BottomMD;
    const fluids = DictionaryWithArray.getArray(fluidState.Fluids).sort((a, b) => a.Name.localeCompare(b.Name));

    const washpipeRows = runningString.filter((pipe) => pipe.PipeType === PipeType.Washpipe);
    const bottomOfWashpipe: number | undefined = washpipeRows[washpipeRows.length - 1]?.BottomMD;
    const bottomOfFullPacking = getFullPackingBottomMd(packingData);

    let endOfWashpipeFluids = lowerPartBottomMd;
    if (bottomOfWashpipe != null && bottomOfFullPacking != null) {
      endOfWashpipeFluids = Math.max(bottomOfWashpipe, bottomOfFullPacking);
    } else if (bottomOfWashpipe != null) {
      endOfWashpipeFluids = bottomOfWashpipe;
    } else if (bottomOfFullPacking != null) {
      endOfWashpipeFluids = bottomOfFullPacking;
    }

    const workstringFluids = getFlowPathSectionFluids(
      wellVisualizationType,
      pumpingDirection,
      toolPosition,
      depthData,
      FlowPathDescription.Workstring,
      upperPartBottomMd,
      fluids,
    );
    const upperAnnulusFluids = getFlowPathSectionFluids(
      wellVisualizationType,
      pumpingDirection,
      toolPosition,
      depthData,
      FlowPathDescription.Upper_Annulus,
      upperPartBottomMd,
      fluids,
    );

    const washpipeFluids = getFlowPathSectionFluids(
      wellVisualizationType,
      pumpingDirection,
      toolPosition,
      depthData,
      FlowPathDescription.Washpipe,
      endOfWashpipeFluids,
      fluids,
    );
    const lowerAnnulusFluids = getFlowPathSectionFluids(
      wellVisualizationType,
      pumpingDirection,
      toolPosition,
      depthData,
      FlowPathDescription.Lower_Annulus,
      lowerPartBottomMd,
      fluids,
    );

    const addStreamFunctions = isStreamLinesBasedWellVisualizationType(wellVisualizationType);

    const upperAnnulusStreamFunctions = addStreamFunctions
      ? getFlowPathSectionFluids(
          WellVisualizationType.Stream_Function,
          pumpingDirection,
          toolPosition,
          depthData,
          FlowPathDescription.Upper_Annulus,
          upperPartBottomMd,
          fluids,
        )
      : undefined;

    const lowerAnnulusStreamFunctions = addStreamFunctions
      ? getFlowPathSectionFluids(
          WellVisualizationType.Stream_Function,
          pumpingDirection,
          toolPosition,
          depthData,
          FlowPathDescription.Lower_Annulus,
          lowerPartBottomMd,
          fluids,
        )
      : undefined;

    const addFluidFronts =
      wellVisualizationType === WellVisualizationType.Concentration ||
      wellVisualizationType === WellVisualizationType.Concentration_With_Stream_Lines;

    return {
      survey,
      hole,
      lowerCompletion,
      runningString,
      packingData,
      fluids,
      workstringFluids,
      upperAnnulusFluids,
      washpipeFluids,
      lowerAnnulusFluids,
      isGravelPackerInstalled: toolPosition !== ToolPosition.Washdown,
      wellVisualizationType,
      lowerAnnulusStreamFunctions,
      upperAnnulusStreamFunctions,
      fluidFronts: addFluidFronts ? fluidFronts : [],
    };
  },
);

function getFullPackingBottomMd(packingData: IPackingResult[]): number | undefined {
  // all fullPackingRows in reverse order (bottom most first)
  const fullPackingRows = packingData.filter((p) => p.packingRatio >= 100.0).sort((a, b) => b.topMd - a.topMd);
  if (fullPackingRows.length === 0) {
    return undefined;
  }

  const indexOfLastFullPacking = packingData.lastIndexOf(fullPackingRows[0]);
  const isLastPackingData = indexOfLastFullPacking === packingData.length - 1;

  // when full packing data cell is last of all packing - then take it's topMd, otherwise when there take topMd of next cell -
  // which is bottomMd of the current one
  return isLastPackingData ? packingData[indexOfLastFullPacking].topMd : packingData[indexOfLastFullPacking + 1].topMd;
}

function getFlowPathSectionFluids(
  wellVisualizationType: WellVisualizationType,
  pumpingDirection: PumpingDirection,
  toolPosition: ToolPosition,
  depthData: ISelectedDepthData | undefined,
  flowPathSection: FlowPathDescription,
  sectionBottomMd: number,
  fluids: Fluid[],
): IFlowPathFluidResults {
  if (!depthData) {
    return { sections: [] };
  }

  if (wellVisualizationType === WellVisualizationType.Packing) {
    return getFluidIndexBasedFlowPathSectionFluids(depthData, flowPathSection, sectionBottomMd);
  } else if (isConcentrationWellVisualizationType(wellVisualizationType)) {
    return getConcentrationBasedFlowPathSectionFluids(depthData, pumpingDirection, toolPosition, flowPathSection, sectionBottomMd, fluids);
  } else {
    // flow regime
    return getAzimuthBasedFlowPathFluids(depthData, pumpingDirection, toolPosition, flowPathSection, sectionBottomMd, wellVisualizationType);
  }
}

function isDownPathDirection(toolPosition: ToolPosition, pumpingDirection: PumpingDirection, flowPathSection: FlowPathDescription): boolean {
  if (toolPosition === ToolPosition.Circulating_GP || toolPosition === ToolPosition.Reverse) {
    if (pumpingDirection == PumpingDirection.In_Workstring) {
      return flowPathSection == FlowPathDescription.Workstring || flowPathSection == FlowPathDescription.Lower_Annulus;
    } else {
      return flowPathSection == FlowPathDescription.Washpipe || flowPathSection == FlowPathDescription.Upper_Annulus;
    }
  } else {
    if (pumpingDirection == PumpingDirection.In_Workstring) {
      return flowPathSection == FlowPathDescription.Workstring || flowPathSection == FlowPathDescription.Washpipe;
    } else {
      return flowPathSection == FlowPathDescription.Upper_Annulus || flowPathSection == FlowPathDescription.Lower_Annulus;
    }
  }
}

function getAzimuthBasedFlowPathFluids(
  { Columns: columns, Rows: rows }: ISelectedDepthData,
  pumpingDirection: PumpingDirection,
  toolPosition: ToolPosition,
  flowPathSection: FlowPathDescription,
  sectionBottomMd: number,
  wellVisualizationType: WellVisualizationType,
): IFlowPathFluidResults {
  const sections: IAzimuthBasedSection[] = [];

  const isDownPath = isDownPathDirection(toolPosition, pumpingDirection, flowPathSection);
  const filteredSortedDepthData = getSortedFlowPathSectionRows(rows, columns, flowPathSection);

  const getDataType = (): DataType => {
    switch (wellVisualizationType) {
      case WellVisualizationType.Stream_Function:
        return isDownPath ? DataType.Down_Path_StreamFunction : DataType.Up_Path_StreamFunction;
      case WellVisualizationType.Flow_Regime:
      case WellVisualizationType.Flow_Regime_With_Stream_Lines:
        return isDownPath ? DataType.Down_Path_Flow_Regime : DataType.Up_Path_Flow_Regime;
      default:
        throw new Error('Well Visualization type not supported');
    }
  };

  const dataType = getDataType();
  const columnIndex = getColIdx(columns, dataType);

  for (const row of filteredSortedDepthData) {
    const azimuthValues = row.Values[columnIndex];
    const azimuthBasedData =
      wellVisualizationType == WellVisualizationType.Stream_Function
        ? streamFunctionToAzimuthBasedData(azimuthValues)
        : flowRegimeToAzimuthBasedData(azimuthValues);

    sections.push({
      azimuthBasedData,
      topMd: row.MD,
      bottomMd: 0,
      gravelId: 0,
    });
  }

  updateSectionsBottomMD(sections, sectionBottomMd);

  return { sections };
}

export function getFlowRegimeLegendItems(): DrawingLegendItem<FpFlowRegime>[] {
  const getFlowRegimeColor = (flowRegime: FpFlowRegime): Color => {
    switch (flowRegime) {
      case FpFlowRegime.Turbulent:
        return Color.Red;
      case FpFlowRegime.Transitional:
        return Color.LightPink;
      case FpFlowRegime.Laminar_stable:
        return Color.Yellow;
      case FpFlowRegime.Laminar_mixed:
        return Color.Green;
      case FpFlowRegime.Stratified_internal:
        return Color.LightBlue;
      case FpFlowRegime.Stratified_viscous:
        return Color.Blue;
      case FpFlowRegime.Static_laminar:
        return Color.Black;
      default:
        return Color.Black;
    }
  };

  return EnumHelpers.EnumToRadioItems(FpFlowRegime).map((flowRegime) => {
    return {
      text: flowRegime.text,
      color: getFlowRegimeColor(flowRegime.value),
      reference: flowRegime.value,
    };
  });
}

function flowRegimeToAzimuthBasedData(inputFlowRegimes: FpFlowRegime[]): IAzimuthBasedData[] {
  const legendItems = getFlowRegimeLegendItems();
  return legendItems.map((flowRegime) => {
    const data: IAzimuthBasedData = {
      color: flowRegime.color ?? Color.Black,
      data: inputFlowRegimes.map((ifr) => (ifr === flowRegime.reference ? 100 : 0)),
      seriesName: flowRegime.text,
      fluidId: undefined,
    };
    return data;
  });
}

function streamFunctionToAzimuthBasedData(azimuthValues: number[]): IAzimuthBasedData[] {
  const mapValue = (value: number): number => {
    return Math.max(0, Math.min(value, 1.0));
  };

  return [
    {
      color: Color.Black,
      seriesName: 'Stream Function',
      data: azimuthValues.map((fr) => mapValue(fr)),
      fluidId: undefined,
    },
  ];
}

function updateSectionsBottomMD(sections: IAzimuthBasedSection[], sectionBottomMd: number): void {
  for (let i = 0; i < sections.length - 1; i++) {
    sections[i].bottomMd = sections[i + 1].topMd;
  }

  if (sections.length) {
    sections[sections.length - 1].bottomMd = sectionBottomMd;
  }
}

function getConcentrationBasedFlowPathSectionFluids(
  { Columns: columns, Rows: rows }: ISelectedDepthData,
  pumpingDirection: PumpingDirection,
  toolPosition: ToolPosition,
  flowPathSection: FlowPathDescription,
  sectionBottomMd: number,
  availableFluids: Fluid[],
): IFlowPathFluidResults {
  const sections: IAzimuthBasedSection[] = [];

  const isDownPath = isDownPathDirection(toolPosition, pumpingDirection, flowPathSection);
  const pathPrefix = isDownPath ? 'Down Path' : 'Up Path';

  const fluids: { fluidIndex: number; fluidId: number }[] = [];
  for (const column of columns) {
    const fluidId = extractFluidIdFromColumnName(column.ColumnName, availableFluids);

    if (fluidId !== null && column.ColumnName.startsWith(pathPrefix)) {
      fluids.push({ fluidId, fluidIndex: columns.indexOf(column) });
    }
  }

  const flowRegimeDataType = isDownPath ? DataType.Down_Path_Flow_Regime : DataType.Up_Path_Flow_Regime;
  const flowRegimeIndex = getColIdx(columns, flowRegimeDataType);

  const displacingFluidDataType = isDownPath ? DataType.Down_Path_Displacing_Fluid_Index : DataType.Up_Path_Displacing_Fluid_Index;
  const displacingFluidIndex = getColIdx(columns, displacingFluidDataType);

  const filteredSortedDepthData = getSortedFlowPathSectionRows(rows, columns, flowPathSection);
  for (const row of filteredSortedDepthData) {
    const fluidsConcentrations: IAzimuthBasedData[] = [];

    for (const { fluidId, fluidIndex } of fluids) {
      fluidsConcentrations.push({
        fluidId,
        data: [...row.Values[fluidIndex]],
        color: undefined,
        seriesName: undefined,
      });
    }

    // region Roping  Fluid
    const ropingFlowRegimes = [FpFlowRegime.Stratified_internal, FpFlowRegime.Stratified_viscous, FpFlowRegime.Static_laminar];
    const isRopingFlowPath = flowPathSection === FlowPathDescription.Washpipe || flowPathSection === FlowPathDescription.Workstring;
    const isRopingFlowRegime = row.Values[flowRegimeIndex].length === 1 && ropingFlowRegimes.includes(row.Values[flowRegimeIndex][0]);

    let ropingFluidId: number | undefined;
    if (isRopingFlowPath && isRopingFlowRegime) {
      const tmpRopingFluidId = row.Values[displacingFluidIndex][0];
      const ropingFluidsConcentration = fluidsConcentrations.find((f) => f.fluidId === tmpRopingFluidId);
      // it's a roping fluid only if it's concentration is below 80%
      if (ropingFluidsConcentration != null && ropingFluidsConcentration.data[0] <= 80) {
        ropingFluidId = tmpRopingFluidId;
      }
    }
    // endregion

    sections.push({
      azimuthBasedData: fluidsConcentrations,
      ropingFluidId,
      gravelId: 0,
      topMd: row.MD,
      bottomMd: 0,
    });
  }

  updateSectionsBottomMD(sections, sectionBottomMd);

  return { sections };
}

function getSortedFlowPathSectionRows(
  rows: IDepthBasedRow[],
  columns: IDepthBasedColumn[],
  flowPathSection: FlowPathDescription,
): IDepthBasedRow[] {
  const flowPathDescriptionIndex = getColIdx(columns, DataType.Flow_Path_Description);

  return rows.filter((row) => row.Values[flowPathDescriptionIndex][0] === flowPathSection).sort((row1, row2) => row1.MD - row2.MD);
}

function extractFluidIdFromColumnName(columnName: string, fluids: Fluid[]): number | null {
  const regex = /Concentration - (.+)/;
  const match: RegExpMatchArray | null = columnName.match(regex);

  if (match) {
    const fluidName = replaceNonAsciiWithQuestionMark(match[1]);
    const fluid = fluids.find((f) => replaceNonAsciiWithQuestionMark(f.Name) === fluidName);
    if (fluid) {
      return fluid.Id;
    } else {
      throw new Error('Could not find fluid for column ' + columnName);
    }
  } else {
    return null;
  }
}

function getFluidIndexBasedFlowPathSectionFluids(
  { Columns: columns, Rows: rows }: ISelectedDepthData,
  flowPathSection: FlowPathDescription,
  sectionBottomMd: number,
): IFlowPathFluidResults {
  const sections: IAzimuthBasedSection[] = [];
  const fluidIndex = getColIdx(columns, DataType.Fluid_Index);
  const gravelIndex = getColIdx(columns, DataType.Gravel_Index);

  let previousFluidId = -1;
  let previousInternalFluidId: number | undefined = undefined;
  let previousGravelId = -1;

  const filteredSortedDepthData = getSortedFlowPathSectionRows(rows, columns, flowPathSection);

  for (const row of filteredSortedDepthData) {
    const fluidId = row.Values[fluidIndex][0]; // works as external fluid
    const internalFluidId = row.Values[fluidIndex].length > 1 ? row.Values[fluidIndex][1] : undefined;
    const gravelId = row.Values[gravelIndex][0];

    if (fluidId !== previousFluidId || gravelId !== previousGravelId || internalFluidId !== previousInternalFluidId) {
      sections.push({
        azimuthBasedData: [
          {
            fluidId,
            data: [100],
            color: undefined,
            seriesName: undefined,
          },
        ],
        gravelId,
        topMd: row.MD,
        bottomMd: 0,
        internalFluidId,
      });
    }

    previousFluidId = fluidId;
    previousInternalFluidId = internalFluidId;
    previousGravelId = gravelId;
  }

  for (let i = 0; i < sections.length - 1; i++) {
    sections[i].bottomMd = sections[i + 1].topMd;
  }
  if (sections.length) {
    sections[sections.length - 1].bottomMd = sectionBottomMd;
  }

  return { sections };
}

export interface IDrawing {
  survey: Survey[];
  hole: IDrawingHoleSection[];
  lowerCompletion: Pipe[];
  runningString: Pipe[];
  packingData: IPackingResult[];
  fluids: Fluid[];
  workstringFluids: IFlowPathFluidResults;
  upperAnnulusFluids: IFlowPathFluidResults;
  washpipeFluids: IFlowPathFluidResults;
  lowerAnnulusFluids: IFlowPathFluidResults;
  isGravelPackerInstalled: boolean;
  wellVisualizationType: WellVisualizationType;
  lowerAnnulusStreamFunctions: IFlowPathFluidResults | undefined;
  upperAnnulusStreamFunctions: IFlowPathFluidResults | undefined;
  fluidFronts: IFluidFront[];
}

export interface IFluidFront {
  fluidId: number;
  md: number;
  flowPathDescription: FlowPathDescription;
  flowDirection: FlowDirection;
}

export interface IHoleSection {
  topMd: number;
  bottomMd: number;
}

export interface IDrawingHoleSection extends IHoleSection {
  diameter: number;
}

export interface IPackingResult {
  topMd: number;
  packingHeight: number;
  packingRatio: number;
}

export enum FlowDirection {
  None = 0,
  DownFlow = 1,
  UpFlow = 2,
}

export interface IFluidResult {
  topMd: number;
  bottomMd: number;
  fluidId: number;
  gravelId: number;
}

export interface IAzimuthBasedSection {
  topMd: number;
  bottomMd: number;
  azimuthBasedData: IAzimuthBasedData[];
  gravelId: number;
  internalFluidId?: number;
  ropingFluidId?: number;
}

export interface IAzimuthBasedData {
  fluidId: number | undefined;
  data: number[];
  color: Color | undefined;
  seriesName: string | undefined;
}

export interface IFlowPathFluidResults {
  sections: IAzimuthBasedSection[];
}
