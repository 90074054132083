import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as uiActions from '../../+store/ui/ui.actions';
import { ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import { Subscription } from 'rxjs';
import { getDataAnalysisModuleMenuData } from './data-analysis-page.menu';
import { DOCUMENT } from '@angular/common';
import { AppTargetConfig } from '../../shared/services/app-target-config';

@Component({
  selector: 'app-data-analysis-page',
  templateUrl: './data-analysis-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataAnalysisPageComponent implements OnInit, OnDestroy {
  public dataAnalysisMenu$ = this.store.select(getDataAnalysisModuleMenuData);
  public subscription = new Subscription();
  private isDataPro = false;

  constructor(
    private store: Store,
    private appConfig: AppTargetConfig,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.isDataPro = this.appConfig.isAppCode('data-pro');
    store.dispatch(uiActions.setAppModuleAction({ appModuleType: ModuleType.Data_Analysis }));
  }

  public ngOnInit(): void {
    if (this.isDataPro == false) {
      this.loadStyle('green-theme', 'packpro-theme-green.css');
    }
  }

  public ngOnDestroy(): void {
    if (this.isDataPro == false) {
      this.removeStyle('green-theme');
    }
  }

  private loadStyle(elementId: string, styleName: string): void {
    const head = this.document.getElementsByTagName('head')[0];

    const themeLink = this.document.getElementById(elementId) as HTMLLinkElement | null;
    if (themeLink != null) {
      themeLink.href = styleName;
    } else {
      const style = this.document.createElement('link');
      style.id = elementId;
      style.rel = 'stylesheet';
      style.href = `${styleName}`;

      head.appendChild(style);
    }
  }

  private removeStyle(elementId: string): void {
    const head = this.document.getElementsByTagName('head')[0];
    const themeLink = this.document.getElementById(elementId) as HTMLLinkElement | null;
    if (themeLink != null) {
      head.removeChild(themeLink);
    }
  }
}
