import { Directive, ElementRef, EventEmitter, NgModule, OnDestroy, Output } from '@angular/core';

export interface ElementResize {
  width: number;
  height: number;
}
@Directive({
  selector: '[appOnResize]',
})
export class OnResizeDirective implements OnDestroy {
  @Output() public elResize = new EventEmitter<ElementResize>();
  private observer: ResizeObserver;

  constructor(private el: ElementRef) {
    this.observer = new ResizeObserver((entries) => {
      try {
        const rect = entries[0].contentRect;
        const width = rect.width;
        const height = rect.height;
        this.elResize.emit({ width, height });
      } catch (err) {
        // Check if the error message is NOT 'ResizeObserver loop completed with undelivered notifications'
        if (err instanceof Error && !err.message.includes('ResizeObserver loop completed with undelivered notifications')) {
          // Re-throw the error because it's a different error
          throw err;
        } else {
          // Silently catch the 'ResizeObserver loop completed with undelivered notifications' error
          console.warn('Silently handling ResizeObserver notification error:', err);
        }
      }
    });

    if (this.el.nativeElement) {
      this.observer.observe(this.el.nativeElement);
    }
  }

  public ngOnDestroy(): void {
    this.observer.disconnect();
  }
}

@NgModule({
  declarations: [OnResizeDirective],
  exports: [OnResizeDirective],
})
export class OnResizeDirectiveModule {}
