import { CommonFluidFactory } from './model/common-fluid.factory';
import { createTableState } from '@dunefront/common/common/common-grid.interfaces';
import { CommonFluidModel } from './model/common-fluid.model';
import { ICommonDbModuleState } from './common-db.reducer';
import { CommonGravelFactory } from './model/common-gravel.factory';
import { CommonGravelModel } from './model/common-gravel.model';
import { GlobalOptionsDto } from '@dunefront/common/dto/global-options.dto';
import { CommonDbPropertiesHelper } from './common-db.properties.helper';
import { ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';
import { CommonDbType } from '@dunefront/common/dto/common-dto.interfaces';
import { CrudResponse } from '@dunefront/common/modules/common.actions';
import { ICommonDbInitialData } from '@dunefront/common/modules/common-db/common-db.actions';

export class CommonDbReducerHelper {
  public static onDeleteRowsSuccess(state: ICommonDbModuleState, response: CrudResponse): ICommonDbModuleState {
    if (response.affectedRows.commonDbFluids) {
      return this.onLoadCommonFluidsSuccess(state, response);
    }

    if (response.affectedRows.commonDbGravels) {
      return this.onLoadCommonGravelsSuccess(state, response);
    }

    return state;
  }
  public static onLoadCommonFluidsSuccess(state: ICommonDbModuleState, response: CrudResponse): ICommonDbModuleState {
    if (response.affectedRows.commonDbFluids == null) {
      return state;
    }
    const commonFluidRows = CommonFluidFactory.createRowListFromDtos(response.affectedRows.commonDbFluids);

    const availableCommonFluids = createTableState<CommonFluidModel>(commonFluidRows);
    return { ...state, availableCommonFluids };
  }

  public static onLoadCommonGravelsSuccess(state: ICommonDbModuleState, response: CrudResponse): ICommonDbModuleState {
    if (response.affectedRows.commonDbGravels == null) {
      return state;
    }

    const commonGravelRows = CommonGravelFactory.createRowListFromDtos(response.affectedRows.commonDbGravels);

    const availableCommonGravels = createTableState<CommonGravelModel>(commonGravelRows);
    return { ...state, availableCommonGravels };
  }

  public static onCommonDbInitialDataSuccess(state: ICommonDbModuleState, response: ICommonDbInitialData): ICommonDbModuleState {
    return {
      ...state,
      predefinedGlobalOptions: response.globalOptions.predefinedGlobalOptions,
      orgGlobalOptions: response.globalOptions.orgGlobalOptions,
      personalGlobalOptions: response.globalOptions.personalGlobalOptions,
    };
  }

  public static onUpdateGlobalOptions(
    state: ICommonDbModuleState,
    commonDbType: CommonDbType,
    globalOptions: GlobalOptionsDto,
  ): ICommonDbModuleState {
    return {
      ...state,
      orgGlobalOptions: commonDbType === 'Organization' ? globalOptions : state.orgGlobalOptions,
      personalGlobalOptions: commonDbType === 'User' ? globalOptions : state.personalGlobalOptions,
    };
  }

  public static onUpdateRowSuccess(state: ICommonDbModuleState, response: CrudResponse): ICommonDbModuleState {
    if (!response.affectedRows.globalOptionsForDbType) {
      return state;
    }

    const { globalOptions, commonDbType } = response.affectedRows.globalOptionsForDbType;

    return this.onUpdateGlobalOptions(state, commonDbType, globalOptions);
  }

  public static changeGlobalOptionsPersonalProperty(
    state: ICommonDbModuleState,
    changedProp: ObjectChangeProp<GlobalOptionsDto>,
  ): ICommonDbModuleState {
    const { personalGlobalOptions, orgGlobalOptions, predefinedGlobalOptions } = state;
    if (personalGlobalOptions == null || orgGlobalOptions == null || predefinedGlobalOptions == null) {
      return state;
    }

    const updatePersonalGlobalOptions = CommonDbPropertiesHelper.changeGlobalOptionsProperty(
      changedProp,
      personalGlobalOptions,
      orgGlobalOptions,
      predefinedGlobalOptions,
    );

    return { ...state, personalGlobalOptions: updatePersonalGlobalOptions };
  }

  public static changeGlobalOptionsOrgProperty(
    state: ICommonDbModuleState,
    changedProp: ObjectChangeProp<GlobalOptionsDto>,
  ): ICommonDbModuleState {
    const { orgGlobalOptions, predefinedGlobalOptions } = state;
    if (orgGlobalOptions == null || predefinedGlobalOptions == null) {
      return state;
    }

    const updateOrgGlobalOptions = CommonDbPropertiesHelper.changeGlobalOptionsProperty(
      changedProp,
      orgGlobalOptions,
      undefined,
      predefinedGlobalOptions,
    );

    return { ...state, orgGlobalOptions: updateOrgGlobalOptions };
  }
}
