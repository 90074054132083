import { ChangeDetectorRef, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { DbDependentComponent } from '../../../../common-modules/db-connection/db-dependent.component';
import { ITableState } from '@dunefront/common/common/common-grid.interfaces';
import { CompletionModuleState } from '@dunefront/common/modules/completion/completion-module.state';
import { getValidatedFluidModuleState } from '../../../../+store/fluid/fluid.selectors';
import { getCompletionModuleState } from '../../../../+store/completion/completion.selectors';
import { getValidatedGravelModuleState } from '../../../../+store/gravel/gravel.selectors';
import { ISelectItem } from '@dunefront/common/common/select.helpers';
import { getCurrentPumpingModuleState } from '../../../../+store/pumping/selectors/pumping.selectors';
import { Pumping } from '@dunefront/common/modules/pumping/model/pumping/pumping';
import { Survey } from '@dunefront/common/modules/well/model/survey/survey';
import { FluidFactory } from '@dunefront/common/modules/fluid/model/fluid.factory';
import { GravelFactory } from '@dunefront/common/modules/gravel/model/gravel.factory';
import { ValidatedSettings } from '@dunefront/common/modules/settings/dto/settingsDto';
import { getValidatedSettings } from '../../../../+store/settings/validated-settings.selectors';
import { getValidatedWellModuleState } from '../../../../+store/well/validated-well.selectors';

@Component({
  selector: 'app-tab-three',
  templateUrl: './tab-three.component.html',
  styleUrls: ['./tab-three.component.scss'],
})
export class TabThreeComponent extends DbDependentComponent {
  public surveyData!: ITableState<Survey> | null;
  public fluids: ISelectItem<number>[] = [];
  public gravels: ISelectItem<number>[] = [];
  public pumping!: Pumping;
  public completion!: CompletionModuleState;
  public settings!: ValidatedSettings;
  public displayedColumns: columns[] = ['MD', 'Deviation', 'Azimuth', 'TVD'];

  constructor(store: Store, cdRef: ChangeDetectorRef) {
    super(store, cdRef);

    this.subscription.add(
      store.select(getValidatedWellModuleState).subscribe((wellState) => {
        this.surveyData = wellState.SurveyData;
        cdRef.markForCheck();
      }),
    );
    this.store.select(getValidatedFluidModuleState).subscribe((fluidState) => {
      this.fluids = FluidFactory.toLookupData(fluidState.Fluids);
      this.cdRef.markForCheck();
    });
    this.store.select(getValidatedGravelModuleState).subscribe((gravelState) => {
      this.gravels = GravelFactory.toLookupData(gravelState.Gravels);
      this.cdRef.markForCheck();
    });
    this.store.select(getCurrentPumpingModuleState).subscribe((pumpingState) => {
      this.pumping = pumpingState.pumping;
      this.cdRef.markForCheck();
    });

    this.store.select(getCompletionModuleState).subscribe((completionState) => {
      this.completion = completionState;
      this.cdRef.markForCheck();
    });

    this.subscription.add(
      store.select(getValidatedSettings).subscribe((settings) => {
        this.settings = settings;
        cdRef.markForCheck();
      }),
    );
    super.onHelpChange('well', 'survey');
    super.onHelpChange('pumping', 'well-fluids');
  }
}

export type columns = 'MD' | 'Deviation' | 'Azimuth' | 'TVD';
