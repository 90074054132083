import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { GlobalOptionsDto } from '@dunefront/common/dto/global-options.dto';
import { Store } from '@ngrx/store';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { DbDependentComponent } from '../../../../common-modules/db-connection/db-dependent.component';
import { ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';

@Component({
  selector: 'app-generic-options',
  template: '',
})
export class GenericOptionsComponent extends DbDependentComponent {
  @Input() public globalOptions!: GlobalOptionsDto;
  @Input() public defaultGlobalOptions?: GlobalOptionsDto;
  @Input() public isOrganization!: boolean;

  @Output() public settingsValueChange = new EventEmitter<ObjectChangeProp<GlobalOptionsDto>>();

  public UnitType = UnitSystem;

  constructor(store: Store, cdRef: ChangeDetectorRef) {
    super(store, cdRef);
  }

  public onValueChange(props: ObjectChangeProp<GlobalOptionsDto>): void {
    this.settingsValueChange.emit(props);
  }

  public isValueDiffThanDefault(key: keyof GlobalOptionsDto): boolean {
    return this.defaultGlobalOptions != null && this.globalOptions[key] !== this.defaultGlobalOptions[key];
  }

  public getDefaultTooltip(key: keyof GlobalOptionsDto): string | null {
    return this.defaultGlobalOptions != null && this.isValueDiffThanDefault(key)
      ? 'Default value: ' + this.defaultGlobalOptions[key]
      : null;
  }
}
