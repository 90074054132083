import { createReducer, on } from '@ngrx/store';
import * as actions from './about.actions';
import { aboutInitialState, AboutModuleReducerHelper } from './about.reducer.helper';
import { AboutServerDto } from '@dunefront/common/dto/about-server.dto';
import { UpdateInfoDto } from '@dunefront/common/dto/update.dto';
import { LicenseWithSessionsInfo } from '@dunefront/common/modules/licensing/licensing.interfaces';
import { dbDisconnectAction } from '../backend-connection/backend-connection.actions';

export const AboutModuleName = 'about';

export interface AboutModuleState {
  serverInfo?: AboutServerDto;
  updateInfo?: UpdateInfoDto | null;
  downloadUrl?: string | null;
  licenseWithSessions: LicenseWithSessionsInfo[];
}

export const aboutModuleReducer = createReducer<AboutModuleState>(
  aboutInitialState,
  on(dbDisconnectAction, (state): AboutModuleState => ({ ...state, licenseWithSessions: [] })),
  on(
    actions.aboutServerSuccessAction,
    (state, action): AboutModuleState => AboutModuleReducerHelper.aboutServerInfo(state, action.aboutServerResult),
  ),
  on(actions.checkForUpdatesSuccessAction, (state, action): AboutModuleState => ({ ...state, updateInfo: action.updateInfo })),
  on(actions.resetUpdatesInfoAction, (state): AboutModuleState => ({ ...state, updateInfo: null })),
  on(actions.fetchDownloadUrlSuccess, (state, action): AboutModuleState => ({ ...state, downloadUrl: action.url })),
  on(actions.resetDownloadUrl, (state): AboutModuleState => ({ ...state, downloadUrl: null })),
  on(actions.fetchLicenseSessionsSuccess, (state, action): AboutModuleState => ({ ...state, licenseWithSessions: action.response })),
);
