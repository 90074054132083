export interface IParsedFileResult {
  isParsed: boolean;
  columnsWithUnits: IColNameWithUnit[];
  data: string[][];
  header: string;
  dataLength: number;
  skippedLineWarnings: ILineError[];
  headerLines: number;
  linesToSkipForWhenUploading: number[];
  initialRowsToSkip: number;
  startDate: number;
}

export interface IColNameWithUnit {
  columnName: string;
  originalUnit: string | undefined;
  originalColumnName: string;
}

export const emptyParsedFileResult: IParsedFileResult = {
  isParsed: false,
  header: '',
  data: [],
  columnsWithUnits: [],
  dataLength: 0,
  skippedLineWarnings: [],
  headerLines: 0,
  linesToSkipForWhenUploading: [],
  initialRowsToSkip: 0,
  startDate: 0,
};

export interface ILineError {
  line: number;
  error: string;
}

export interface GroupedLineError {
  error: string;
  from: number;
  to: number;
}
