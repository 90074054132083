import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PerforationPhasingTypeEnum } from '@dunefront/common/modules/well/dto/casing/casing-pipe.dto';
import { ITableRow } from '@dunefront/common/common/common-grid.interfaces';
import { DbDependentComponent } from '../../../../../common-modules/db-connection/db-dependent.component';
import { PlaneAngleConverter } from '@dunefront/common/unit-converters/converters/plane-angle/plane-angle.converter';
import { IUnitSystemDto, PlaneAngleUnit, UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { Store } from '@ngrx/store';
import { ISelectItem } from '@dunefront/common/common/select.helpers';
import { PerforatedCasingPipe } from '@dunefront/common/modules/pipes/casing-pipes/pipes/perforated-casing-pipe';
import { changeProp, ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';
import { IUpdateTableRowsProps, StoreCrudPropsFactory } from '@dunefront/common/common/common-store-crud.interfaces';

@Component({
  selector: 'app-perforation-properties',
  templateUrl: './perforation-properties.component.html',
  styleUrls: ['./perforation-properties.component.scss'],
})
export class PerforationPropertiesComponent extends DbDependentComponent implements OnInit {
  @Input() public perforatedCasingPipe: ITableRow<PerforatedCasingPipe> | undefined;
  @Output() public perforationPropertiesChanged = new EventEmitter<IUpdateTableRowsProps<PerforatedCasingPipe>>();
  public anglesList!: ISelectItem<number>[];
  public UnitType = UnitSystem;

  constructor(store: Store, cdRef: ChangeDetectorRef) {
    super(store, cdRef);
  }

  public override ngOnInit(): void {
    super.ngOnInit();
    this.setAnglesList();
  }

  public override onUnitSystemChanged(currentUnitSystem: IUnitSystemDto): void {
    super.onUnitSystemChanged(currentUnitSystem);
    this.setAnglesList();
  }

  private setAnglesList(): void {
    const anglesList: ISelectItem<number>[] = [];

    for (const n in PerforationPhasingTypeEnum) {
      if (typeof PerforationPhasingTypeEnum[n] === 'number') {
        const value = +PerforationPhasingTypeEnum[n] as number;
        let text = PerforationPhasingTypeEnum[value];
        if (text.startsWith('deg_')) {
          const degrees = Number(text.substr(4));
          if (this.currentUnitSystem.Angle === PlaneAngleUnit.Degree || degrees === 0) {
            text = degrees.toString();
          } else {
            text = (Math.round(PlaneAngleConverter.fromSi(degrees, this.currentUnitSystem.Angle) * 1000) / 1000).toString();
          }
        }

        anglesList.push({ value, text });
      }
    }

    this.anglesList = anglesList;
  }

  public onValueChange(props: ObjectChangeProp<PerforatedCasingPipe>): void {
    if (this.perforatedCasingPipe && this.perforatedCasingPipe.rowData[props.key] !== props.value) {
      const row: ITableRow<PerforatedCasingPipe> = {
        ...this.perforatedCasingPipe,
        rowData: changeProp(this.perforatedCasingPipe.rowData, props),
      };
      this.perforationPropertiesChanged.emit(StoreCrudPropsFactory.updateTableRows([row], props));
    }
  }

  public changeHelp(): void {
    this.onHelpChange('well', 'perforated-casing');
  }
}
