import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectUserGlobalOptions } from '../../../+store/common-db/common-db.selectors';
import { updateEditRangeAction } from '../../../+store/range/range.actions';
import { filter, map } from 'rxjs/operators';
import { RangeDto } from '@dunefront/common/dto/range.dto';
import { MarkerMode } from '../chart-controller.component';
import { IChartDataDto, isFullRange } from '@dunefront/common/modules/reporting/dto/chart-data.dto';
import { filterNil } from '@dunefront/common/common/state.helpers';
import { MarkersServiceBase } from './markers-service-base';
import { IMarker } from '@dunefront/common/modules/reporting/dto/chart-marker.dto';

@Injectable()
export class ChartEditRangeService extends MarkersServiceBase {
  private readonly startMarkerId = 1;
  private readonly endMarkerId = 2;

  private _markers$: Observable<IMarker[]> = combineLatest([
    this.store.select(selectUserGlobalOptions),
    this.currentRange$.pipe(filterNil()),
    this.chartData$.pipe(
      filterNil(),
      map((data) => data.StartDate),
      filterNil(),
    ),
  ]).pipe(
    map(([globalOptions, currentRange, startDate]) => [
      {
        id: this.startMarkerId,
        value: currentRange.RangeStart - startDate,
        isValueAxisMarker: false,
        name: 'Start',
        isOverrideStyle: false,
        style: globalOptions,
      },
      {
        id: this.endMarkerId,
        value: currentRange.RangeEnd - startDate,
        isValueAxisMarker: false,
        name: 'End',
        isOverrideStyle: false,
        style: globalOptions,
      },
    ]),
  );

  public get markers$(): Observable<IMarker[]> {
    return this._markers$;
  }

  public get disableAddingMarkers(): boolean {
    return true;
  }

  constructor(
    private store: Store,
    private currentRange$: BehaviorSubject<RangeDto | undefined>,
    private chartData$: BehaviorSubject<IChartDataDto | undefined>,
  ) {
    super(MarkerMode.EditRanges);
    this.subscription.add(
      chartData$
        .pipe(
          filterNil(),
          filter((data) => isFullRange(data)),
        )
        .subscribe((chartData) => this.setUpDefaultMarkersForNewRange(chartData, currentRange$.value)),
    );
  }

  public onMarkerMoved(marker: IMarker): void {
    const currentRange = this.currentRange$.value;
    const startDate = this.chartData$.value?.StartDate;
    if (currentRange == null || startDate == null) {
      return;
    }
    this.store.dispatch(
      updateEditRangeAction({
        range: {
          ...currentRange,
          RangeStart: marker.id === this.startMarkerId ? marker.value + startDate : currentRange.RangeStart,
          RangeEnd: marker.id === this.endMarkerId ? marker.value + startDate : currentRange.RangeEnd,
        },
      }),
    );
  }

  private setUpDefaultMarkersForNewRange(chartData: IChartDataDto, currentRange: RangeDto | undefined): void {
    const startDate = chartData.StartDate;
    if (currentRange == null || startDate == null) {
      return;
    }

    if (currentRange.RangeStart >= currentRange.RangeEnd) {
      // that means that range is new and does not have ranges set up
      // sort the ranges out relative to the size of the chart if creating a new range
      let start = Number.MAX_VALUE;
      let end = -Number.MAX_VALUE;
      for (const dataSet of chartData.ChartDataSets) {
        // we never have ChartDataRows = 1. There is a check in backend, when rows.length < 2, return 0
        if (dataSet.ChartDataRows.length === 0) {
          // we don't have data in current dataset.
          continue;
        }

        const firstArg = dataSet.ChartDataRows[0].Argument + dataSet.XAxisShift;
        const lastArg = dataSet.ChartDataRows[dataSet.ChartDataRows.length - 1].Argument + dataSet.XAxisShift;
        if (firstArg < start) {
          start = firstArg;
        }
        if (lastArg > end) {
          end = lastArg;
        }
      }

      if (start < Number.MAX_VALUE && end > -Number.MAX_VALUE) {
        // this is very unlikely that start === Number.MAX_VALUE and end === -Number.MAX_VALUE)
        // but we should check that before triggering this action
        this.store.dispatch(
          updateEditRangeAction({
            range: {
              ...currentRange,
              RangeStart: start + (end - start) / 3 + startDate,
              RangeEnd: start + (2 * (end - start)) / 3 + startDate,
            },
          }),
        );
      }
    }
  }
}
