<div class="shunt-tubes-data-grid" *ngIf="completionState && completionState.isLoaded">
  <ng-container *ngIf="CompletionValidation.IsShuntTubeEnabled(completionState); else shuntTubeDisabled">
    <app-system-configuration
      [shuntTube]="completionState.ShuntTube"
      (shuntTubeValueChange)="submitProperty($event)"
      (click)="onHelpChange('shunt-tube', 'system-configuration')"
      (isTransportTubePresentChange)="isTransportTubePresent = $event"
    >
    </app-system-configuration>
    <app-shroud-properties
      [shuntTube]="completionState.ShuntTube"
      (click)="onHelpChange('shunt-tube', 'shroud')"
      (shuntTubeValueChange)="submitProperty($event)"
    >
    </app-shroud-properties>
    <app-nozzle-properties
      [isTransportTubePresent]="isTransportTubePresent"
      [shuntTube]="completionState.ShuntTube"
      (click)="onHelpChange('shunt-tube', 'nozzle-leakoff')"
      (shuntTubeValueChange)="submitProperty($event)"
    >
    </app-nozzle-properties>
    <app-packing-tube-properties
      [shuntTube]="completionState.ShuntTube"
      (click)="onHelpChange('shunt-tube', completionState.ShuntTube.Shape === TubeShape.Round ? 'round' : 'rectangle')"
      (shuntTubeValueChange)="submitProperty($event)"
    >
    </app-packing-tube-properties>
    <app-transport-tube-properties
      [isTransportTubePresent]="isTransportTubePresent"
      [shuntTube]="completionState.ShuntTube"
      (click)="onHelpChange('shunt-tube', completionState.ShuntTube.Shape === TubeShape.Round ? 'round' : 'rectangle')"
      (shuntTubeValueChange)="submitProperty($event)"
    >
    </app-transport-tube-properties>
    <app-packing-factor
      [shuntTube]="completionState.ShuntTube"
      (click)="onHelpChange('shunt-tube', 'packing-factor')"
      (shuntTubeValueChange)="submitProperty($event)"
    >
    </app-packing-factor>
  </ng-container>
  <ng-template #shuntTubeDisabled>
    <div class="centered-message">There are no shunted components defined in the lower completion</div>
  </ng-template>
</div>
