import { FlowPathType } from '../resuspension-calculator/resuspension-calculator.dto';
import { FrictionXAxis } from '../friction-calculator/friction-calculator.dto';
import { GravelDto } from '../../gravel/dto/gravel.dto';
import { FluidWithDependenciesDto } from '../../fluid/dto/fluid.dto';
import { DeveloperSettingsDto } from '../../settings/dto/settingsDto';

export interface SettlingCalculatorDto {
  ScenarioId: number;
  FlowPathType: FlowPathType;
  MinDiameter: number;
  MaxDiameter: number;
  FluidId: number | null;
  GravelId: number | null;
  Concentration: number;
  MaxRate: number;
  StaticViscosity: number;
  Temperature: number;
  Deviation: number;
  Eccentricity: number;
  IsXAxisRate: FrictionXAxis;
  OroskarAndTurianDiameter: OroskarAndTurianDiameter;
  StokesSettlingVelocity: number;
}

export enum OroskarAndTurianDiameter {
  Equivalent = 0,
  Hydraulic = 1,
}

export interface SettlingCalculatorJobInputData {
  Input: SettlingCalculatorDto;
  Gravels: GravelDto[];
  Fluids: FluidWithDependenciesDto[];
  DeveloperSettings: DeveloperSettingsDto;
}

export interface SettlingCalculatorJobResult {
  PumpRateResults: number[];
  PumpVelocityResults: number[];
  DuneHeightResults: number[];
  StokesSettlingVelocity: number;
}

export interface SettlingCalculatorResultDto {
  ScenarioId: number;
  Rate: number;
  Velocity: number;
  DuneHeight: number;
}
