import { CompletionDto } from '../../dto/completion.dto';
import { Volumes } from './volumes';
import { pick } from '../../../../common/state.helpers';
import { GaugeMeasurementType } from '../../../../dto/running-string-pipe.dto';

export class VolumesFactory {
  public static create(completion: CompletionDto): Volumes {
    return {
      ...pick(completion, [
        'SectionVolCalcStartMD',
        'SectionVolCalcEndMD',
        'SectionVolCalcStartMeasurementType',
        'SectionVolCalcEndMeasurementType',
        'SectionVolCalcGravelConc',
      ]),
      SectionVolCalcGravelId: completion.SectionVolCalcGravelId === null ? 0 : completion.SectionVolCalcGravelId,
      SectionTotalVolCalcResult: 0,
      error: {},
      isValid: true,
    };
  }

  public static createEmpty(): Volumes {
    return {
      SectionVolCalcGravelId: -1,
      SectionVolCalcEndMD: 0,
      SectionVolCalcStartMD: 0,
      SectionTotalVolCalcResult: 0,
      SectionVolCalcEndMeasurementType: GaugeMeasurementType.None,
      SectionVolCalcGravelConc: 0,
      SectionVolCalcStartMeasurementType: GaugeMeasurementType.None,
      error: {},
      isValid: false,
    };
  }
}
