import { BasePptSectionGenerator, PptContext } from '../base-ppt-section-generator';
import { firstValueFrom } from 'rxjs';
import { selectCurrentUnitSystem } from '../../../../../../+store/units/units.selectors';
import { CommonReportDataHelpers } from '../../common/common-report-data-helpers';
import { PptDocumentTableGeneratorHelper } from '../ppt-document-table-generator.helper';
import { ConvertUnitPipe } from '@dunefront/common/modules/units/convert-unit.pipe/convert-unit.pipe';
import { getPSDAnalysis, getPSDsAsGrid } from '../../../../../../+store/psd-analysis/psd-analysis.selectors';
import { PSDResult } from '@dunefront/common/modules/psd-analysis/model/psd/psd';
import { PresetColumnsWidths } from '../../doc/document-table-generator.helper';

export class PsdScreenAndGravelSelectionDataPptGenerator extends BasePptSectionGenerator {
  constructor(protected override context: PptContext & { convertUnitPipe: ConvertUnitPipe }) {
    super(context);
  }

  public async generate(): Promise<void> {
    const { store, convertUnitPipe } = this.context;
    const currentUnitSystem = await firstValueFrom(this.store.select(selectCurrentUnitSystem));
    const psdGrid = await firstValueFrom(store.select(getPSDsAsGrid));
    const psdAnalysis = await firstValueFrom(store.select(getPSDAnalysis));

    // standalone Screens
    {
      const tableData = CommonReportDataHelpers.createPsdScreenTableData(psdGrid, psdAnalysis, currentUnitSystem, convertUnitPipe);
      const tableRows = PptDocumentTableGeneratorHelper.createTableRows<PSDResult>(tableData, null, currentUnitSystem);
      const presetColumnsWidths: PresetColumnsWidths = { 2: 15, 3: 25 };

      this.addTableSlide({ title: 'PSD Screen and Gravel Selection - Standalone Screens', tableRows, presetColumnsWidths });
    }

    // gravel Pack
    {
      const tableData = CommonReportDataHelpers.createPsdGravelTableData(psdGrid);
      const tableRows = PptDocumentTableGeneratorHelper.createTableRows<PSDResult>(tableData, null, currentUnitSystem);
      const presetColumnsWidths: PresetColumnsWidths = { 2: 15, 3: 25 };

      this.addTableSlide({ title: 'PSD Screen and Gravel Selection - Gravel Pack', tableRows, presetColumnsWidths });
    }
  }
}
