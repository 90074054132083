import { LocationType, TemperatureProfileType, TreatmentType, ZonePressureType } from './dto/well.dto';
import { createTableState, ITableState } from '../../common/common-grid.interfaces';
import { Pipe } from '../pipes/pipe';
import { Caliper } from './model/caliper/caliper';
import { Survey } from './model/survey/survey';
import { TemperatureProfile } from './model/temperature-profile/temperature-profile';
import { ZoneModel } from './model/zone/zone.model';
import { IModuleState, IValidatedModuleState } from '../../common/common-state.interfaces';
import { ModuleType } from '../scenario/scenario.dto';
import { PumpingStateByRange } from '../pumping/pumping-module.state';
import { DeveloperSettingsDto, ValidatedSettings } from '../settings/dto/settingsDto';
import { IGetCurrentFeaturesResult } from '../licensing/licensing.interfaces';

export interface WellModuleState extends IModuleState {
  CasingData: ITableState<Pipe>;
  CaliperData: ITableState<Caliper>;
  SurveyData: ITableState<Survey>;
  ZoneData: ITableState<ZoneModel>;
  TemperatureProfile: TemperatureProfile;
  LocationType: LocationType;
  CaliperTolerance: number;
  CaliperMultiplier: number;
  IsLossRateCalculated: boolean;
  ZonePressureType: ZonePressureType;
  IsZoneDepthByMD: boolean;
  IsSurveyDepthByMD: boolean;
  TreatmentType: TreatmentType;

  ScenarioId: number;
}

export interface ValidatedWellModuleState extends WellModuleState, IValidatedModuleState<WellModuleState> {
  isGeneralDataScreenValid: boolean;
  isCasingDataScreenValid: boolean;
  isReservoirDataScreenValid: boolean;
  isCaliperDataScreenValid: boolean;
  isCasingDataScreenWarning: boolean;
}

export const initialWellModuleState: WellModuleState = {
  CaliperMultiplier: 1,
  CasingData: createTableState([]),
  CaliperData: createTableState([]),
  ZoneData: createTableState([]),
  IsLossRateCalculated: false,
  TreatmentType: TreatmentType.Gravel_Pack,
  TemperatureProfile: {
    BottomHoleTVD: 0,
    BottomholeCirculating: 0,
    BottomholeStaticTemperature: 0,
    MinWaterTemp: 0,
    MinWaterTempDepth: 0,
    SeaBedDepth: 0,
    SeaLevelDepth: 0,
    SurfaceTemp: 0,
    TemperatureProfileType: TemperatureProfileType.Simple_Calc,
    error: {},
    isValid: false,
  },
  LocationType: LocationType.Offshore,
  CaliperTolerance: 0,
  IsSurveyDepthByMD: false,
  IsZoneDepthByMD: false,
  ScenarioId: -1,
  SurveyData: createTableState([]),
  ZonePressureType: ZonePressureType.Pressure_Gradient,
  isLoaded: false,
};

export interface IWellValidationDependencies {
  developerSettings: DeveloperSettingsDto;
  currentModuleType: ModuleType;
  pumpingModule: PumpingStateByRange;
  currentLicenseFeatures: IGetCurrentFeaturesResult;
  settings: ValidatedSettings;
}
