import { AnimationLayout, WellPartType } from '@dunefront/common/modules/settings/dto/settingsDto';
import { ToolPosition } from '@dunefront/common/modules/pumping/dto/pumping.dto';
import { WellOrientation } from '../../../../+store/well/well.selectors';
import { ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import { isSimulateDisp } from '../../../../+store/menu-selectors/menu-selectors.helpers';

export class AnimationHelpers {
  public static GetDefaultSimulationAnimationDrawingSection(toolPosition: ToolPosition, moduleType: ModuleType): WellPartType {
    if (toolPosition === ToolPosition.Reverse) {
      return WellPartType.Above_Packer;
    } else {
      return isSimulateDisp(moduleType) ? WellPartType.Full_Well : WellPartType.Below_Packer;
    }
  }

  public static GetAutoSimulationAnimationLayoutType(wellOrientation: WellOrientation): AnimationLayout.Horizontal | AnimationLayout.Vertical {
    return wellOrientation === 'horizontal' ? AnimationLayout.Horizontal : AnimationLayout.Vertical;
  }

  public static GetDefaultWellVisualizationDrawingSection(toolPosition: ToolPosition): WellPartType {
    return toolPosition === ToolPosition.Reverse ? WellPartType.Above_Packer : WellPartType.Full_Well;
  }

  public static IsWellVisualizationDrawingSectionFixed(toolPosition: ToolPosition): boolean {
    return toolPosition === ToolPosition.Reverse;
  }
}
