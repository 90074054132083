import { PipeDto, PipeType } from '../../dto/pipe.dto';
import { ScreenPipe } from './lower-completion-pipes/pipes/screen-pipe';
import { Pipe } from './pipe';
import { createTableRow, GridRowType, ITableRow } from '../../common/common-grid.interfaces';

export class PipeFactory {
  public static createFromPipe(thePipe: Pipe): Pipe {
    return { ...thePipe };
  }

  // Screen handling section constructor
  public static createFromScreen(theScreen: ScreenPipe, bottomMD: number, topMD: number): Pipe {
    // Adjust parameters for the handling section
    const pipe: Pipe = {
      ...theScreen,
      OuterDiameter: theScreen.BasepipeOuterDiameter,
      PipeType: PipeType.Blank_Pipe,
      BottomMD: bottomMD,
      TopMD: topMD,
    };
    return pipe;
  }

  public static createEmptyRow(scenarioId: number, rowIndex: number, isEditingDisabled: boolean, rowType: GridRowType): ITableRow<Pipe> {
    const pipe: Pipe = {
      ScenarioId: scenarioId,
      Id: -1,
      BottomMD: 0,
      InnerDiameter: 0,
      OuterDiameter: 0,
      PipeType: PipeType.None,
      TopMD: 0,
      Weight: 0,
      SortOrder: 0,
      Length: 0,
      IsReservoirSection: false,
      Description: '',
    };
    return createTableRow(pipe, rowType, rowIndex, isEditingDisabled);
  }

  public static toDto(pipe: Pipe): PipeDto {
    return {
      ScenarioId: pipe.ScenarioId,
      Id: pipe.Id,
      SortOrder: pipe.SortOrder,
      PipeType: pipe.PipeType,
      BottomMD: pipe.BottomMD,
      TopMD: pipe.TopMD,
      InnerDiameter: pipe.InnerDiameter,
      OuterDiameter: pipe.OuterDiameter,
      Weight: pipe.Weight,
    };
  }
}
