import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { IFile } from '@dunefront/common/dto/file.dto';
import { Store } from '@ngrx/store';
import { loadAllFilesAndFoldersSuccess, restoreFile } from '../../../../+store/file-manager/file-manager.actions';
import { Router } from '@angular/router';
import { BaseFileActionDialogComponent } from '../base-file-action.dialog.component';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Actions, ofType } from '@ngrx/effects';
import { FileErrorHelper } from '../file-error.helper';
import {
  CY_BUTTON_OK,
  defaultModalCancelButton,
  defaultModalConfirmButton,
  ModalButtonConfig,
} from '../../../../common-modules/modals/generic-modal/generic-modal.component';

@Component({
  selector: 'app-restore-dialog',
  templateUrl: './restore.dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RestoreDialogComponent extends BaseFileActionDialogComponent implements OnInit {
  public modalTitle = 'Restore';
  public modalButtonsConfigs: ModalButtonConfig[] = [
    defaultModalCancelButton((): void => this.cancelClicked()),
    defaultModalConfirmButton((): void => this.okClicked(), 'OK', CY_BUTTON_OK, !!this.fileName.error.targetName),
  ];

  constructor(
    activeModal: DynamicDialogRef,
    config: DynamicDialogConfig,
    store: Store,
    cdRef: ChangeDetectorRef,
    private router: Router,
    actions$: Actions,
  ) {
    super(activeModal, config, store, cdRef);
    this.file = config.data.file;
    this.subscription.add(actions$.pipe(ofType(loadAllFilesAndFoldersSuccess)).subscribe((_) => this.activeModal.close()));
  }

  public ngOnInit(): void {
    this.fileName = { ...this.fileName, targetName: this.file.Name.trim() };
    this.validateRestoreAction(this.file, this.file.Name.trim());
  }

  public textValueChanged(textValue: string): void {
    this.validateRestoreAction(this.file, textValue.trim());
  }

  public okClicked(): void {
    if (this.fileName.error.targetName !== '') {
      return;
    }
    this.store.dispatch(
      restoreFile({
        source: this.file,
        target: { ...this.file, Name: this.fileName.targetName, Repository: 'personal' },
      }),
    );
  }

  private validateRestoreAction(targetFolder: IFile, newFileName?: string): void {
    const fileNameList = this.getNamesForValidation('personal', [...targetFolder.Folder], this.file.FileType);
    this.fileName = FileErrorHelper.validateFileFolderName(
      {
        ...this.fileName,
        targetName: newFileName ? newFileName : this.fileName.targetName,
      },
      fileNameList,
    );
    this.errorMessage = this.fileName.error.targetName;
    const okButton = this.modalButtonsConfigs.find((conf) => conf.dataCy === CY_BUTTON_OK);
    if (okButton) {
      okButton.disabled = !!this.errorMessage;
    }
  }
}
