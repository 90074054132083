import { InsertRowsWsAction, UpdateRowsWsAction, WsAction } from '../../ws.action';
import { ImportColumnDto } from '../data-storage/dto/import-column.dto';
import { EquationColumn, EquationColumnFactory } from '../equation/equation-column';
import { WsActionPropsFactory } from '../../common/ws-action/ws-action-props.factory';
import { IEquationVariable } from '../equation/equation-variable.dto';
import { DeveloperSettingsDto } from '../settings/dto/settingsDto';
import { ImportFileDto } from '../data-storage/dto/import-file.dto';

export const EquationsModuleName = 'EquationsModule';

export enum EquationsModuleActionTypes {
  EquationCreateFile = '[EquationsModule] EquationCreateFile',
  EquationUpdateFile = '[EquationsModule] EquationUpdateFile',
  EquationCreateColumn = '[EquationsModule] EquationCreateColumn',
  EquationUpdateColumn = '[EquationsModule] EquationUpdateColumn',
  EquationFetchPreview = '[EquationsModule] EquationFetchPreview',
  EquationGenerateSourceVariables = '[EquationsModule] EquationGenerateSourceVariables',
  EquationApplyFormulaCalculations = '[EquationsModule] EquationApplyFormulaCalculations',
}

export class EquationCreateFileAction extends WsAction {
  public override readonly type = EquationsModuleActionTypes.EquationCreateFile;
  constructor(
    public fileName: string,
    public scenarioId: number,
    public rangeId: number,
  ) {
    super();
  }
}

export class EquationUpdateFileAction extends UpdateRowsWsAction<ImportFileDto> {
  public override readonly type = EquationsModuleActionTypes.EquationUpdateFile;

  constructor(dto: ImportFileDto, colIds?: (keyof ImportFileDto)[]) {
    super(WsActionPropsFactory.update([dto], true, colIds));
  }
}

export class EquationCreateColumnAction extends InsertRowsWsAction<ImportColumnDto> {
  public override readonly type = EquationsModuleActionTypes.EquationCreateColumn;
}

export class EquationUpdateColumnAction extends UpdateRowsWsAction<ImportColumnDto> {
  public override readonly type = EquationsModuleActionTypes.EquationUpdateColumn;

  constructor(equationColumn: EquationColumn) {
    super(WsActionPropsFactory.update([EquationColumnFactory.toDto(equationColumn)], false));
  }
}

export class EquationFetchPreviewAction extends WsAction {
  public override readonly type = EquationsModuleActionTypes.EquationFetchPreview;

  constructor(
    public variables: IEquationVariable[],
    public equationFileId: number,
    public rangeId: number,
  ) {
    super();
  }
}

export class EquationGenerateSourceVariablesAction extends WsAction {
  public override readonly type = EquationsModuleActionTypes.EquationGenerateSourceVariables;

  constructor(
    public fileId: number,
    public override taskId: string,
    public scenarioId: number,
    public developerSettings: DeveloperSettingsDto,
    public fileHash: string,
    public updateDate: string,
  ) {
    super();
  }
}

export class EquationApplyFormulaCalculationsAction extends WsAction {
  public override readonly type = EquationsModuleActionTypes.EquationApplyFormulaCalculations;

  constructor(
    public override taskId: string,
    public equationFileId: number,
  ) {
    super();
  }
}

export type EquationsModuleActions =
  | EquationCreateFileAction
  | EquationUpdateFileAction
  | EquationCreateColumnAction
  | EquationFetchPreviewAction
  | EquationGenerateSourceVariablesAction
  | EquationApplyFormulaCalculationsAction
  | EquationUpdateColumnAction;
