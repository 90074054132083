import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import { CommonCalculationPageComponent } from '../common-calculation-page.component';
import { CalculatorsTab } from '../../../+store/ui/ui-module.state';

@Component({
  selector: 'app-leakoff-coefficient',
  templateUrl: './leakoff-coefficient.component.html',
  styleUrls: ['./leakoff-coefficient.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LeakoffCoefficientComponent extends CommonCalculationPageComponent {
  public override appModuleType = ModuleType.Leakoff_Coefficient_Calc;
  public override tab: CalculatorsTab = 'leakoff-coefficient';
}
