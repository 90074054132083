import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';
import { IImportTemplateDto } from '@dunefront/common/modules/data-storage/dto/import-template/import-template.dto';
import { ISelectItem, toSelectItem } from '@dunefront/common/common/select.helpers';
import { Store } from '@ngrx/store';
import { applyImportTemplateAction, deleteImportTemplateAction } from '../../../../+store/import-data/import-data.actions';
import { ModalService } from '../../modal.service';
import {
  decodeTemplateId,
  EditTemplateModalMode,
  encodeTemplateId,
  getModifiedTemplateTemplateId,
  getNoneTemplateTemplateId,
} from '@dunefront/common/common/templates/template-parser';
import { getValidatedImportModuleState } from '../../../../+store/import-data/import-data.selectors';
import { Subscription } from 'rxjs';
import { IValidatedImportDataModuleState } from '../../../../+store/import-data/import-data-module.state';
import { PrimitiveChangeValue } from '@dunefront/common/common/common-state.interfaces';
import { EditImportTemplateModalComponent } from './edit-import-template-modal/edit-import-template-modal.component';

@Component({
  selector: 'app-template-manager',
  templateUrl: './template-manager.component.html',
  styleUrls: ['./template-manager.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TemplateManagerComponent implements OnDestroy {
  @Input()
  public isSaveAsTemplateMode = false;

  @Input()
  public disabled = false;

  @Input()
  public resetTemplateOnInit = false;

  public templateItems: ISelectItem<string>[] = [];

  private subscription = new Subscription();
  public state!: IValidatedImportDataModuleState;

  public selectedTemplateId!: string;

  private noneTemplateId = getNoneTemplateTemplateId();
  private modifiedTemplateId = getModifiedTemplateTemplateId();

  constructor(
    private store: Store,
    private modalService: ModalService,
    private cdRef: ChangeDetectorRef,
  ) {
    this.subscription.add(
      store.select(getValidatedImportModuleState).subscribe((state) => {
        this.state = state;
        this.fillTemplateItems(this.state.selectedTemplateId === this.modifiedTemplateId);
        this.selectedTemplateId = state.selectedTemplateId;
        this.cdRef.markForCheck();
      }),
    );

    // if user have selected template, changed columns configs, and got back to first screen -> reset selected template
    if (this.selectedTemplateId !== getNoneTemplateTemplateId() && this.resetTemplateOnInit) {
      this.store.dispatch(
        applyImportTemplateAction({
          templateId: this.selectedTemplateId,
          applyFilePropertiesConfig: true,
        }),
      );
    }
  }

  public get isEditDeleteEnabled(): boolean {
    return this.state.selectedTemplateId !== this.noneTemplateId && this.state.selectedTemplateId !== this.modifiedTemplateId;
  }

  public get selectedTemplate(): IImportTemplateDto | undefined {
    const decodedTemplateId = decodeTemplateId(this.state.selectedTemplateId);
    return this.state.selectedTemplateId === this.noneTemplateId
      ? undefined
      : this.state.templates.find((tmpl) => tmpl.Id === decodedTemplateId.id && tmpl.Type === decodedTemplateId.type);
  }

  private fillTemplateItems(showModified = false): void {
    this.templateItems = [
      toSelectItem(this.noneTemplateId, 'None'),
      ...this.state.templates.map((tmpl) =>
        toSelectItem(encodeTemplateId(tmpl.Id, tmpl.Type), tmpl.Name, tmpl.Type, undefined, tmpl.Type.toLocaleLowerCase()),
      ),
    ];

    if (showModified) {
      this.templateItems.unshift(toSelectItem(getModifiedTemplateTemplateId(), 'Modified', undefined, undefined, undefined, false));
    }
  }

  public onTemplateChanged(event: PrimitiveChangeValue<string>): void {
    this.store.dispatch(applyImportTemplateAction({ templateId: event.value, applyFilePropertiesConfig: true }));
  }

  public async onSaveAsTemplateClick(): Promise<void> {
    return await this.openEditTemplateModal(EditTemplateModalMode.create);
  }

  public async onRenameClick(): Promise<void> {
    return await this.openEditTemplateModal(EditTemplateModalMode.update);
  }

  public async onDeleteClick(): Promise<void> {
    if (!this.state.selectedTemplateId) {
      return;
    }
    const result = await this.modalService.showConfirm('Are you sure you want to delete template?', 'Delete template');
    if (result) {
      this.store.dispatch(deleteImportTemplateAction({ templateId: this.state.selectedTemplateId }));
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private async openEditTemplateModal(mode: EditTemplateModalMode): Promise<void> {
    const data = {
      mode: mode,
      dbType: this.selectedTemplate?.Type,
      selectedTemplate: this.selectedTemplate,
      usedNames:
        mode === EditTemplateModalMode.create
          ? []
          : this.state.templates
              .filter((template) => template.Type === this.selectedTemplate?.Type && template.Id !== this.selectedTemplate.Id)
              .map((template) => template.Name),
    };
    this.modalService.open(EditImportTemplateModalComponent, data);
  }
}
