import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { GenericOptionsComponent } from '../generic-options.component';
import { Store } from '@ngrx/store';
import { ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';
import {IAxisStyle} from '@dunefront/common/modules/reporting/dto/chart-axis-property.dto';

@Component({
  selector: 'app-chart-axis-style-options',
  templateUrl: './chart-axis-style-options.component.html',
  styleUrls: ['./chart-axis-style-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartAxisStyleOptionsComponent extends GenericOptionsComponent {
  constructor(store: Store, cdRef: ChangeDetectorRef) {
    super(store, cdRef);
  }

  public onStyleValueChange(axisStyleProp: ObjectChangeProp<IAxisStyle>): void {
    this.settingsValueChange.emit({ ...this.globalOptions, ...axisStyleProp });
  }
}
