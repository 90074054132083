<ng-container *ngIf="isPumpingEnabled$ | async; else pageDisabled">
  <ng-container *ngIf="pumpingState.isLoaded">
    <app-page-panel header="Schedule" (click)="onHelpChange('pumping', 'schedule-simulate')" [panelHelpMode]="PanelHelpMode.GRID">
      <app-schedule-grid
        *ngIf="scheduleTableState$ | async as scheduleTableState"
        #gridComponent
        [pumpingSchedule]="scheduleTableState"
      ></app-schedule-grid>
    </app-page-panel>
    <div class="buttons-panel">
      <div class="align-left">
        <button appButton type="button" class="btn btn-primary btn-sm" (buttonClick)="onSetFullReturns()">Set Full Returns</button>
      </div>
      <div class="align-right">
        <button
          appButton
          type="button"
          class="btn btn-primary btn-sm"
          data-cy="schedule-import"
          (buttonClick)="onImport()"
          [isDeleteResultsEnabled]="false"
        >
          Import
        </button>
        <button
          appButton
          type="button"
          class="btn btn-primary btn-sm"
          data-cy="schedule-insert"
          (buttonClick)="onInsert()"
          [isDeleteResultsEnabled]="false"
        >
          Insert
        </button>
        <button
          appButton
          type="button"
          class="btn btn-primary btn-sm"
          data-cy="schedule-delete"
          (buttonClick)="onDelete()"
          [isDeleteResultsEnabled]="false"
        >
          Delete
        </button>
      </div>
    </div>
    <div class="bottom-panel grid-2-col" *ngIf="scheduleReferenceVariables$ | async as refVar">
      <app-page-panel header="Gravel Required" (click)="onHelpChange('pumping', 'gravel-required')">
        <app-gravel-required-panel [refVariables]="refVar" [pumping]="pumpingState.pumping" (dataChange)="onPumpingPropertyChanged($event)">
        </app-gravel-required-panel>
      </app-page-panel>
      <app-page-panel header="Reference Parameters" (click)="onHelpChange('pumping', 'reference-parameters-simulate')">
        <app-reference-parameters-panel [refVariables]="refVar"></app-reference-parameters-panel>
      </app-page-panel>
    </div>
  </ng-container>
</ng-container>
<ng-template #pageDisabled>
  <div class="centered-message">The Schedule screen is not available for the Entire Range - select another range to update the data</div>
</ng-template>
