<div *ngIf="contentVisible && (currentResultsPresent$ | async); else noResults" class="root-container">
  <div
    class="evaluation-charts-horizontal top-chart"
    data-cy="evaluation-pressure-animation"
    (click)="onHelpChange('results', 'animation-evaluate')"
  >
    <app-page-panel header="Pressure" [allowFullScreen]="true">
      <app-animation-time-based-chart
        drawableProviderId="Rate and Pressure"
        [chartDataSourceType]="ChartDataSourceType.ChartSourceEvaluatePressure"
        *ngIf="contentVisible"
      ></app-animation-time-based-chart>
    </app-page-panel>
  </div>
  <div class="evaluation-charts-horizontal" data-cy="evaluation-friction-animation" (click)="onHelpChange('results', 'animation-evaluate')">
    <app-page-panel header="Friction" [allowFullScreen]="true">
      <app-animation-time-based-chart
        drawableProviderId="Friction"
        [chartDataSourceType]="ChartDataSourceType.ChartSourceEvaluateFriction"
        *ngIf="contentVisible"
      ></app-animation-time-based-chart>
    </app-page-panel>
  </div>
</div>
<ng-template #noResults>
  <div class="centered-message" (click)="onHelpChange('results', 'animation-evaluate')">
    No results available, please run an evaluation.
  </div>
</ng-template>
