import { createSelector } from '@ngrx/store';
import { getWellModuleState } from '../../well/well.selectors';
import { getCompletionModuleState } from '../../completion/completion.selectors';
import { getCurrentPumpingModuleState } from './pumping.selectors';
import {
  WellFluidConversionJobInputDataDeps,
  WellFluidConversionJobInputDataFactory,
} from '@dunefront/common/modules/reference-variables/dto/well-fluid-conversion-job-input-data.dto';
import { getValidatedDeveloperSettings, getValidatedSettings } from '../../settings/validated-settings.selectors';

export const getWellFluidConversionJobInputDataDeps = createSelector(
  getWellModuleState,
  getCompletionModuleState,
  getCurrentPumpingModuleState,
  getValidatedSettings,
  getValidatedDeveloperSettings,
  (...[well, completion, pumping, settings, devOptions]): WellFluidConversionJobInputDataDeps => ({
    well,
    completion,
    pumping,
    settings,
    devOptions,
  }),
);

export const getSwitchFluidDefinitionTypeJobData = createSelector(getWellFluidConversionJobInputDataDeps, (deps) =>
  WellFluidConversionJobInputDataFactory.toDto(deps),
);
