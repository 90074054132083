import { createReducer, on } from '@ngrx/store';
import { deleteRowsSuccess, insertRowsSuccess, loadScenarioDataSuccessAction, resetScenarioState, updateRowSuccess } from '../app.actions';
import { GravelModuleReducerHelper } from './gravel.reducer.helper';
import { selectGravelAction } from '../gravel/gravel.actions';
import { gravelModuleInitialState, GravelModuleState } from '@dunefront/common/modules/gravel/gravel-module.state';

export const gravelModuleReducer = createReducer<GravelModuleState>(
  gravelModuleInitialState,
  on(resetScenarioState, (): GravelModuleState => gravelModuleInitialState),
  on(
    loadScenarioDataSuccessAction,
    (state, action): GravelModuleState =>
      GravelModuleReducerHelper.onLoadScenarioDataSuccessAction(state, action.loadScenarioResponse.gravelModule),
  ),
  on(selectGravelAction, (state, action): GravelModuleState => GravelModuleReducerHelper.selectGravel(state, action.gravelId)),
  on(insertRowsSuccess, (state, action): GravelModuleState => GravelModuleReducerHelper.insertGravelRowSuccess(state, action)),
  on(updateRowSuccess, (state, action): GravelModuleState => GravelModuleReducerHelper.updateGravelRowSuccess(state, action)),
  on(deleteRowsSuccess, (state, action): GravelModuleState => GravelModuleReducerHelper.deleteGravelRowsSuccess(state, action)),
);
