import { Dict, IDictionaryWithArray } from '../../../common/state.helpers';
import { createTableRow, createTableState, GridRowType, ITableRow, ITableState } from '../../../common/common-grid.interfaces';
import { groupBy } from 'lodash';
import { IcdPortDataDto } from '../../../dto/icd-port-data.dto';

export class IcdPortDataFactory {
  public static create(
    icdPortDataDtoArray: IcdPortDataDto[],
    permanentCompletionIds: string[],
    scenarioId: number,
  ): IDictionaryWithArray<ITableState<IcdPortDataDto>> {
    const portDatas = [...icdPortDataDtoArray];

    const portDataByCompletionId = groupBy(portDatas, (data) => data.LowerCompletionId);

    // make it reusable in DictionaryWithArray...
    const dict: Dict<ITableState<IcdPortDataDto>> = {};
    permanentCompletionIds.map((completionId) => {
      const pd = portDataByCompletionId[completionId] ?? [];
      dict[+completionId] = this.createTableStateForCompletionId(pd, scenarioId, +completionId);
    });
    return { ids: permanentCompletionIds.map((id) => id), dict };
  }

  public static createRowListFromDtos(
    icdPortDatas: IcdPortDataDto[],
    scenarioId: number,
    lowerCompletionId: number,
  ): ITableRow<IcdPortDataDto>[] {
    const rows = icdPortDatas.map((dto, index) => this.createElement(dto, index));
    const insertRow = this.createEmptyRow(scenarioId, lowerCompletionId, rows.length, false, 'insert-row');
    return [...rows, insertRow];
  }

  public static createTableStateForCompletionId(
    icdPortDatas: IcdPortDataDto[],
    scenarioId: number,
    lowerCompletionId: number,
  ): ITableState<IcdPortDataDto> {
    return createTableState(this.createRowListFromDtos(icdPortDatas, scenarioId, lowerCompletionId));
  }

  public static createEmptyRow(
    scenarioId: number,
    lowerCompletionId: number,
    rowIndex: number,
    isEditingDisabled: boolean,
    rowType: GridRowType,
  ): ITableRow<IcdPortDataDto> {
    const rowData: IcdPortDataDto = this.createEmpty(scenarioId, lowerCompletionId);
    return createTableRow(rowData, rowType, rowIndex, isEditingDisabled);
  }

  public static createEmpty(scenarioId: number, lowerCompletion: number): IcdPortDataDto {
    const dto: IcdPortDataDto = {
      Id: -1,
      LowerCompletionId: lowerCompletion,
      NumberOfPorts: 0,
      PortDiameter: 0,
      PortLength: 0,
      SortOrder: -1,
      ScenarioId: scenarioId,
    };
    return dto;
  }

  public static createElement(
    icdPortDataDto: IcdPortDataDto,
    index: number,
    isEditingDisabled = false,
    rowType: GridRowType = 'data',
  ): ITableRow<IcdPortDataDto> {
    return createTableRow<IcdPortDataDto>(icdPortDataDto, rowType, index, isEditingDisabled);
  }
}
