import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { DbDependentComponent } from '../../../../common-modules/db-connection/db-dependent.component';
import { Store } from '@ngrx/store';
import { EnumHelpers } from '@dunefront/common/utils/enum.helpers';
import { ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import { ISelectItem } from '@dunefront/common/common/select.helpers';
import * as uiActions from '../../../../+store/ui/ui.actions';
import { ChartDataSourceType } from '@dunefront/common/modules/reporting/dto/chart.dto';
import { getAreCurrentResultsPresent } from '../../../../+store/calculation-engine/calculation-engine-results.selectors';
import { WellOrientation } from '../../../../+store/well/well.selectors';
import { PagePanelComponent } from '../../../../common-modules/page-panel/page-panel.component';
import { getWellOrientation } from '../../../../+store/well/validated-well.selectors';
import { changeSettingsProperty } from '../../../../+store/settings/settings.actions';
import { AnimationLayout, DrawingLayoutType, WellPartType } from '@dunefront/common/modules/settings/dto/settingsDto';
import { getEffectiveSimulationAnimationWellLayout, getEffectiveSimulationAnimationWellPart } from '../animation.selectors';
import { getSimulationAnimationWellLayout } from '../../../../+store/settings/validated-settings.selectors';
import { PanelHelpMode } from '../../../../shared/components/help-button/help-buton.component';
import { getCanRenderDepthChart } from '../../../../+store/reporting/reporting.selectors';

@Component({
  selector: 'app-simulation-animation',
  templateUrl: './simulation-animation.component.html',
  styleUrls: ['./simulation-animation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimulationAnimationComponent extends DbDependentComponent implements OnInit {
  public layoutTypes!: ISelectItem<AnimationLayout>[];
  public selectedLayoutType: AnimationLayout = AnimationLayout.Auto;
  public displayLayoutType: AnimationLayout.Horizontal | AnimationLayout.Vertical = AnimationLayout.Horizontal;
  public wellOrientation: WellOrientation = 'vertical';
  public contentVisible = true;
  public ChartDataSourceType = ChartDataSourceType;
  public DrawingLayoutType = DrawingLayoutType;
  public ModuleType = ModuleType;
  public drawingSection = WellPartType.Below_Packer;
  public currentResultsPresent$ = this.store.select(getAreCurrentResultsPresent);
  public showDepthChart$ = this.store.select(getCanRenderDepthChart);
  public PanelHelpMode = PanelHelpMode;

  @ViewChildren(PagePanelComponent) private pagePanelComponents!: QueryList<PagePanelComponent>;

  public get isHorizontal(): boolean {
    return this.displayLayoutType === AnimationLayout.Horizontal;
  }

  constructor(store: Store, cdRef: ChangeDetectorRef) {
    super(store, cdRef);
    store.dispatch(uiActions.setResultsTabAction({ tab: 'simulation-animation' }));
    this.layoutTypes = EnumHelpers.EnumToISelectItemArray(AnimationLayout);

    this.subscription.add(
      this.store.select(getEffectiveSimulationAnimationWellPart).subscribe((wellPart) => {
        this.drawingSection = wellPart;
        this.cdRef.markForCheck();
      }),
    );

    this.subscription.add(
      this.store.select(getEffectiveSimulationAnimationWellLayout).subscribe((layout) => {
        if (this.displayLayoutType !== layout) {
          this.displayLayoutType = layout;
          this.cdRef.markForCheck();
        }
      }),
    );

    this.subscription.add(
      this.store.select(getSimulationAnimationWellLayout).subscribe((layout) => {
        if (this.selectedLayoutType !== layout) {
          this.selectedLayoutType = layout;
          this.cdRef.markForCheck();
        }
      }),
    );
    this.subscription.add(this.store.select(getWellOrientation).subscribe((orientation) => (this.wellOrientation = orientation)));

    this.subscription.add(
      this.currentRangeId$.subscribe(() => {
        this.forceReload();
      }),
    );

    this.onHelpChange('results', 'animation-simulate');
  }

  public onLayoutChange($event: AnimationLayout): void {
    this.store.dispatch(
      changeSettingsProperty({
        key: 'SimulationAnimationWellLayout',
        value: $event,
        shouldResetResults: false,
      }),
    );

    for (const pagePanel of this.pagePanelComponents) {
      pagePanel.switchToPanelMode();
    }
  }

  public override onCurrentScenarioChanged(): void {
    this.forceReload();
  }

  private forceReload(): void {
    this.contentVisible = false;
    this.cdRef.markForCheck();
    setTimeout(() => {
      this.contentVisible = true;
      this.cdRef.markForCheck();
    });
  }
}
