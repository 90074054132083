<app-radio-group
  [isUiLockable]="false"
  id="md-tvd"
  [items]="mdTvdRadio"
  [value]="axisSelectionProps.chartMdTvdMode"
  (primitiveValueChanged)="mdTvdModeChanged($event.value)"
  [layout]="'vertical'"
  dataCy="axis-selection--md-tvd-radio"
  [disabled]="!axisSelectionProps.mdTvdEnabled"
>
</app-radio-group>
<app-button
  class="big-button"
  [isAdvancedButton]="true"
  [isAdvancedButtonOnly]="true"
  [advancedButtonWidth]="'17rem'"
  [isUiLockable]="false"
  [advancedButtonContextMenuItems]="advancedButtonMenuItems"
  dataCy="axis-selection--md-tvd-radio-advanced-btn"
  [disabled]="!axisSelectionProps.mdTvdEnabled"
></app-button>
