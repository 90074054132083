import { ArrayHelpers } from '@dunefront/common/common/array-helpers';

export const UNKNOWN_COLUMN_NAME = 'Column#';
export const numberAfterLastHyphenRegex = /(?:-)(\d+)$/g;

export const nonNumericRegex = /[^0-9.]+/g;

export class ImportDataHelper {
  public static getMaxSuffixNumber(allColumnNames: string[]): number {
    if (!allColumnNames.length) {
      return 0;
    }
    const numberMatches = allColumnNames.map((col) => {
      const match = col.match(numberAfterLastHyphenRegex);
      if (!match) {
        return 0;
      }
      return +match[0].replace(nonNumericRegex, '');
    });
    return Math.max(...numberMatches) + 1;
  }

  public static stringsSameOrWithSuffix(s1: string, s2: string, isGeneric = false): boolean {
    const removeSuffix = (s: string): string => s.replace(numberAfterLastHyphenRegex, '');

    return s1 === s2 || removeSuffix(s1) === s2 || s1 === removeSuffix(s2) || removeSuffix(s1) === removeSuffix(s2);
  }

  public static getUniqueColName(colName: string, allColumnNames: string[], colIndex?: number, currentColumnNames: string[] = []): string {
    // if column name doesn't exist - return not changed
    if (colName === '') {
      colName = UNKNOWN_COLUMN_NAME + colIndex;
    }

    const allColumns = [...allColumnNames, ...currentColumnNames];

    // if proposed name is not used, return it
    if (!allColumns.includes(colName)) {
      return colName;
    }

    // check if there are names with same name but different suffix ( e.g time, time-1 )
    const foundSimilarColumns = allColumns
      .filter((col) => ImportDataHelper.stringsSameOrWithSuffix(col, colName))
      .sort((a, b) => ArrayHelpers.stringCompare(a, b));

    if (foundSimilarColumns.length === 0) {
      return colName;
    }

    // add suffix to column name
    if (foundSimilarColumns.length > 0) {
      // find max number suffix
      const maxSuffixNumber = ImportDataHelper.getMaxSuffixNumber(foundSimilarColumns);
      const suffix = colName.endsWith('#') || colName.endsWith('-') ? '' : '-';
      return colName + suffix + maxSuffixNumber;
    }
    return colName;
  }

  public static containsWords(text: string, words: string[] = []): boolean {
    if (text == null || text.trim() === '') {
      return false;
    }

    // Convert the text to lowercase to make the search case insensitive
    const textLower = text.toLowerCase();

    // Check if each word is found in the text
    for (const word of words) {
      if (!textLower.includes(word.toLowerCase())) {
        return false; // Return false if any word is not found
      }
    }

    return true; // Return true if all words are found
  }
}
