import { getRowsForCalculations, ITableRow, ITableState } from '../../../../common/common-grid.interfaces';
import { Rheology } from './rheology';
import { FluidType } from '../../dto/fluid.dto';
import { RheologyType } from '../../dto/rheology/rheology.dto';
import { DictionaryWithArray, IDictionaryWithArray, noErrors } from '../../../../common/state.helpers';
import { Fluid } from '../fluid';
import { FluidModuleState } from '../../fluid-module.state';
import { IError } from '../../../../common/common-state.interfaces';

export class RheologyValidation {
  public static ValidateRheologies(fluidState: FluidModuleState): IDictionaryWithArray<ITableState<Rheology>> {
    let rheologiesDict = { ...fluidState.Rheologies };

    rheologiesDict.ids.forEach((fluidId) => {
      const fluid = DictionaryWithArray.get(fluidState.Fluids, fluidId);
      const rheologiesTableState = DictionaryWithArray.get(rheologiesDict, fluidId);

      if (!rheologiesTableState || !fluid) {
        return;
      }
      const rows = rheologiesTableState.rows;
      const rheologiesForCalc = getRowsForCalculations(rheologiesTableState.rows);
      let isValid = true;
      const newRows = rows.map((row) => {
        if (row.rowType === 'insert-row') {
          return row;
        }
        const newRow = this.ValidateRheologyRow(row, rheologiesForCalc, fluid);
        isValid = isValid && newRow.isValid;
        return newRow;
      });

      const newRheologiesTableState: ITableState<Rheology> = { ...rheologiesTableState, rows: newRows, isValid };
      rheologiesDict = DictionaryWithArray.upsertById(rheologiesDict, newRheologiesTableState, fluidId);
    });
    return rheologiesDict;
  }

  private static ValidateRheologyRow(row: ITableRow<Rheology>, rheologies: Rheology[], fluid: Fluid): ITableRow<Rheology> {
    const error: IError<Rheology> = {
      Temperature: this.ValidateTemperature(row.rowData.Temperature, rheologies),
      YieldStress: this.ValidateYieldStress(row.rowData.YieldStress, fluid.IsYieldStress),
      NPrime: this.ValidateNPrime(row.rowData.NPrime, fluid.Type),
      KPrime: this.ValidateKPrime(row.rowData.KPrime, fluid.RheologyType),
      Viscosity: this.ValidateViscosity(row.rowData.Viscosity, fluid.RheologyType),
    };

    return { ...row, error, isValid: noErrors(error) };
  }

  private static ValidateTemperature(temperature: number, rheologies: Rheology[]): string {
    if (rheologies.filter((row) => row.Temperature === temperature).length > 1) {
      return 'Temperature value must not be duplicated';
    }
    return '';
  }

  private static ValidateYieldStress(yieldStress: number, fluidWithYieldStress: boolean): string {
    if (fluidWithYieldStress && yieldStress < 0) {
      return 'Yield stress must be greater than or equal to 0';
    }
    return '';
  }

  private static ValidateNPrime(nPrime: number, fluidType: FluidType): string {
    if (fluidType !== FluidType.Newtonian && (nPrime <= 0.1 || nPrime > 1)) {
      return "n' must be greater than 0.1 and less than or equal to 1.0";
    }

    if (fluidType !== FluidType.Newtonian && nPrime === 1) {
      return "n' cannot be set to 1 for non-Newtonian fluids";
    }

    return '';
  }

  private static ValidateKPrime(kPrime: number, rheologyType: RheologyType): string {
    if (rheologyType !== RheologyType.Viscosity && kPrime <= 0) {
      return "k' must be greater than zero";
    }
    return '';
  }

  private static ValidateViscosity(viscosity: number, rheologyType: RheologyType): string {
    if (rheologyType === RheologyType.Viscosity && viscosity <= 0) {
      return 'Viscosity must be greater than zero';
    }
    return '';
  }
}
