import { PsdSandProductionInputs } from './psd-sand-production.inputs';
import { PSDReadingDto } from './psd-reading.dto';

export interface PSDDto {
  Id: number;
  ScenarioId: number;
  SortOrder: number;
  Description: string;
  PSDInputType: PSDInputType;
  Depth: number;
  IsSelected: boolean;
}

export enum PSDInputType {
  Weight = 0,
  PercentWeight = 1,
  CumulativePercentWeight = 2,
}

export interface PSDInputDto extends PSDDto {
  PSDReadings: PSDReadingDto[];
}

export interface PSDAnalysisJobInputData {
  PSDInputs: PSDInputDto[];
  PSDSandProductionInputs?: PsdSandProductionInputs;
}

export type NO_SAND_CONTROL = 'No Sand Control';

export interface PSDResultDto {
  Id: number;
  Depth: number;
  UniformityCoefficient: number;
  SortingCoefficient: number;
  PercentFines: number;
  D10: number;
  D40: number;
  D50: number;
  D70: number;
  D90: number;
  D95: number;
  ProducedSandMass: number | NO_SAND_CONTROL;
  GravelSize: number;
  MinPenberthyCopeOpening: number;
  MaxPenberthyCopeOpening: number;
  PenberthyCopeCriteria: string;
  CoberlyCriteria: string;
  GulfCoastCriteria: string;
  MalbrelCriteria: string;
  SaucierCriteria: string;
  ProducedSandMassCriteria: string;
  IsFullAnalysis: boolean;
}
