import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IParsedFileResult } from '../../../../+store/import-data/model/parsed-result';
import { IFileLoadedArgs } from '../../import-data/file-loader/file-loader.component';

@Component({
  selector: 'app-paste-area',
  templateUrl: './paste-area.component.html',
  styleUrl: './paste-area.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasteAreaComponent {
  @Input()
  public set parsedResult(parsedResults: IParsedFileResult) {
    if (this.pastedText === '' && parsedResults.data.length) {
      this.pastedText = parsedResults.header + '\n' + parsedResults.data.map((row) => row.join('\t')).join('\n');
    }
  }

  @Output()
  public pastedTextChanged = new EventEmitter<IFileLoadedArgs>();
  public pastedText = '';

  public onInputChange($event: Event): void {
    const target = $event.target as HTMLTextAreaElement;
    this.pastedText = target.value;
    this.onPastedTextUpdated();
  }

  public handleTab(event: KeyboardEvent): void {
    if (event.key === 'Tab') {
      event.preventDefault();
      const target = event.target as HTMLTextAreaElement;
      const start = target.selectionStart;
      const end = target.selectionEnd;

      this.pastedText = this.pastedText.substring(0, start) + '\t' + this.pastedText.substring(end);
      target.selectionStart = target.selectionEnd = start + 1;

      this.onPastedTextUpdated();
    }
  }

  public onPastedTextUpdated(): void {
    if (this.pastedText != null) {
      const allLines = this.pastedText.split(/\r\n|\n/);
      this.pastedTextChanged.emit({ fileName: 'Data from Clipboard', data: allLines });
    }
  }
}
