<ng-container *ngIf="isFluidsModuleLoaded$ | async">
  <app-fluid-header (click)="onHelpChange('fluids', 'project-fluids')"></app-fluid-header>

  <app-page-panel header="Fluids Properties">
    <app-fluid-properties *ngIf="currentFluid" [fluid]="currentFluid"></app-fluid-properties>
  </app-page-panel>

  <div class="buttons-panel" (click)="onHelpChange('fluids', 'project-fluids')">
    <div class="align-left">
      <button
        appButton
        data-cy="fluid-to-database"
        type="button"
        class="btn btn-primary btn-sm"
        [isUiLockable]="false"
        (buttonClick)="saveToDatabase()"
      >
        Save to Database
      </button>
    </div>
    <div class="align-right">
      <button
        appButton
        data-cy="new-fluid"
        type="button"
        class="btn btn-primary btn-sm"
        (buttonClick)="newFluid()"
        [isDeleteResultsEnabled]="false"
      >
        New Fluid
      </button>
      <button
        appButton
        data-cy="copy-fluid"
        type="button"
        class="btn btn-primary btn-sm"
        (buttonClick)="copyFluid()"
        [isDeleteResultsEnabled]="false"
      >
        Copy Fluid
      </button>
      <button appButton data-cy="delete-fluid" type="button" class="btn btn-primary btn-sm" (buttonClick)="deleteFluid()">
        Delete Fluid
      </button>
    </div>
  </div>
</ng-container>
