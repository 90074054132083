import { createReducer, on } from '@ngrx/store';
import { deleteRowsSuccess, insertRowsSuccess, loadScenarioDataSuccessAction, updateRowSuccess } from '../../app.actions';
import { InjectionTestCalculatorReducerHelper } from './injection-test-calculator.reducer.helper';
import { updateInjectionTestCalculatorRow } from './injection-test-calculator.actions';
import {
  InjectionTestCalculatorInitialState,
  InjectionTestCalculatorModuleState,
} from '@dunefront/common/modules/calculators/injection-test-calculator/injection-test-calculator-module.state';
import { removeDataResultsFromStoreIfNeeded } from '../../data-storage/data-storage.actions';

export const InjectionTestCalculatorModuleFeatureName = 'injectionTestCalculator';

export const injectionTestCalculatorModuleReducer = createReducer<InjectionTestCalculatorModuleState>(
  InjectionTestCalculatorInitialState,
  on(loadScenarioDataSuccessAction, (state, action) =>
    InjectionTestCalculatorReducerHelper.onLoadScenarioDataSuccessAction(state, action.loadScenarioResponse.injectionTestCalculatorModule),
  ),
  on(insertRowsSuccess, (state, action) => InjectionTestCalculatorReducerHelper.insertInjectionTestReadingsRowSuccess(state, action)),
  on(deleteRowsSuccess, (state, action) => InjectionTestCalculatorReducerHelper.deleteInjectionTestReadingsRowsSuccess(state, action)),
  on(updateRowSuccess, (state, action) => InjectionTestCalculatorReducerHelper.updateInjectionTestReadingsRowSuccess(state, action)),
  on(updateRowSuccess, (state, action) => InjectionTestCalculatorReducerHelper.onUpdateInjectionTestCalculatorSuccess(state, action)),
  on(updateInjectionTestCalculatorRow, (state, action) =>
    InjectionTestCalculatorReducerHelper.beforeUpdateInjectionTestCalculator(state, action),
  ),
  on(
    removeDataResultsFromStoreIfNeeded,
    (state, action): InjectionTestCalculatorModuleState =>
      InjectionTestCalculatorReducerHelper.onRemoveDataResultsFromStore(state, action.deleteResultsFilter),
  ),
);
