import { createSelector } from '@ngrx/store';
import { getCurrentScenarioId } from '../scenario/scenario.selectors';
import { getFileHash } from '../backend-connection/backend-connection.selectors';
import { getCurrentAppModuleType } from '../ui/ui.selectors';
import { getCurrentRangeId } from '../range/range.selectors';
import { CalculationContext } from '@dunefront/common/modules/calculation-engine/calculation-engine.interfaces';

export const getCurrentCalculationContext = createSelector(
  getCurrentAppModuleType,
  getCurrentScenarioId,
  getCurrentRangeId,
  getFileHash,
  (...[moduleType, scenarioId, rangeId, fileHash]): CalculationContext | undefined => {
    if (fileHash == null) {
      return undefined;
    }
    return { moduleType, scenarioId, rangeId, fileHash };
  },
);
