import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnChanges, OnInit } from '@angular/core';
import { IGridColumnConfig } from '../../../../../shared/components/grid/grid.interfaces';
import { Store } from '@ngrx/store';
import { ModalService } from '../../../../../common-modules/modals/modal.service';
import { GridContainerComponent } from '../../../../../shared/components/grid/grid-container.component';
import { ReadOnlyGridConfig } from '../../../../../shared/components/grid/grid-config';
import { SectionInformationRow } from '@dunefront/common/modules/completion/model/volumes/section-information-row';
import {
  getGaugeSectionInformationRows,
  getValidatedMultipleSectionInputs,
} from '../../../../../+store/completion/validated-completion.selectors';
import { GridResizeService } from '../../../../../shared/services/grid-resize.service';
import { VolumeCalculationErrorMessage } from '@dunefront/common/exceptions/errors';
import { gaugeSectionInfoGridConfig } from './gauge-section-info-grid.config';

@Component({
  selector: 'app-gauge-section-info-grid',
  templateUrl: './gauge-section-info-grid.component.html',
  styleUrls: ['./gauge-section-info-grid.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GaugeSectionInfoGridComponent
  extends GridContainerComponent<SectionInformationRow>
  implements OnChanges, AfterViewInit, OnInit
{
  public isValid$ = this.store.select(getValidatedMultipleSectionInputs);
  public tableData$ = this.store.select(getGaugeSectionInformationRows);
  public VolumeCalculationErrorMessage = VolumeCalculationErrorMessage;

  constructor(
    store: Store,
    cdRef: ChangeDetectorRef,
    protected modalService: ModalService,
    el: ElementRef,
    gridResizeService: GridResizeService,
  ) {
    super(store, cdRef, el, new ReadOnlyGridConfig(modalService), gridResizeService);
  }

  protected getColumns(): IGridColumnConfig<SectionInformationRow>[] {
    return gaugeSectionInfoGridConfig;
  }
}
