import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Color, LineStyle } from '@dunefront/common/modules/reporting/dto/chart.types';
import { EnumHelpers } from '@dunefront/common/utils/enum.helpers';
import { ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';
import { presetLineThicknessItems } from '../../chart.helpers';
import { IGradientLineStyle } from '@dunefront/common/modules/reporting/dto/chart-gradient-line.dto';
import { ISelectItem } from '@dunefront/common/common/select.helpers';

@Component({
  selector: 'app-chart-gradient-line-details',
  templateUrl: './chart-gradient-line-details.component.html',
  styleUrls: ['./chart-gradient-line-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartGradientLineDetailsComponent {
  @Input() public lineStyle!: IGradientLineStyle;
  @Input() public defaultLineStyle?: IGradientLineStyle;
  @Input() public disabled = false;
  @Output() public valueChanged = new EventEmitter<ObjectChangeProp<any>>();

  public readonly colors = EnumHelpers.StringEnumToSelectItemArray(Color);
  public readonly lineStyles = EnumHelpers.EnumToISelectItemArray(LineStyle, false);
  public readonly lineThicknessSelect = presetLineThicknessItems;
  public readonly decimalPlacesSelect: ISelectItem<number>[] = [
    { text: '0', value: 0 },
    { text: '1', value: 1 },
    { text: '2', value: 2 },
    { text: '3', value: 3 },
    { text: '4', value: 4 },
    { text: '5', value: 5 },
    { text: '6', value: 6 },
    { text: '7', value: 7 },
    { text: '8', value: 8 },
    { text: '9', value: 9 },
    { text: '10', value: 10 },
  ];
  protected readonly LineStyle = LineStyle;

  public emitStyleValue(event: ObjectChangeProp<any>): void {
    this.valueChanged.emit(event);
  }

  public isValueDiffThanDefault(key: keyof IGradientLineStyle): boolean {
    return this.defaultLineStyle != null && this.lineStyle[key] !== this.defaultLineStyle[key];
  }

  public getDefaultTooltip(key: keyof IGradientLineStyle, itemsEnum?: LineStyle): string | null {
    if (this.defaultLineStyle == null) {
      return null;
    }
    const defaultValue =
      itemsEnum != null ? EnumHelpers.getEnumName(itemsEnum, this.defaultLineStyle[key] as number) : this.defaultLineStyle[key];
    return this.isValueDiffThanDefault(key) ? 'Default value: ' + defaultValue : null;
  }
}
