import { createFeatureSelector, createSelector } from '@ngrx/store';
import { InjectionTestCalculatorModuleFeatureName } from './injection-test-calculator.reducer';
import { InjectionTestCalculatorModuleState } from '@dunefront/common/modules/calculators/injection-test-calculator/injection-test-calculator-module.state';
import { InjectionTestCalculatorDto } from '@dunefront/common/modules/calculators/injection-test-calculator/injection-test-calculator.dto';
import { InjectionTestCalculatorValidation } from '@dunefront/common/modules/calculators/injection-test-calculator/model/injection-test-calculator.validation';

const getInjectionTestCalculatorModuleState = createFeatureSelector<InjectionTestCalculatorModuleState>(
  InjectionTestCalculatorModuleFeatureName,
);
export const getValidatedInjectionTestCalculatorModuleState = createSelector(getInjectionTestCalculatorModuleState, (state) =>
  InjectionTestCalculatorValidation.validate(state),
);

export const getHasInjectionCalculatorResults = createSelector(getValidatedInjectionTestCalculatorModuleState, (state) =>
  Object.keys(state.InjectionTestCalculator)
    .filter((key) => key !== 'ScenarioId' && key !== 'MarkerPosition')
    .some((key: string) => state.InjectionTestCalculator[key as keyof InjectionTestCalculatorDto] !== 0),
);
