import {
  AlignmentType,
  Footer,
  Header,
  HeadingLevel,
  IParagraphOptions,
  ISectionOptions,
  IStylesOptions,
  PageNumber,
  Paragraph,
  TextRun,
} from 'docx';
import { IUnitSystemDto, NoneUnit, UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { UnitConverterHelper } from '@dunefront/common/unit-converters/unit.converter.helper';
import { ReportInfoDto } from '@dunefront/common/dto/report-info.dto';
import { accentColor, defaultFontSize, headerFontSize, mainFont, subHeaderFontSize } from '../../report-consts';
import { DEFAULT_DECIMAL_PLACES } from '../../../../../common-modules/units/components/decimal-number-parser';
import { Base64Image } from '../../../../../common-modules/chart/image-provider.helpers';
import { defaultChartImageWidth } from './sections/chart-report-generator.helper';
import {
  CommonReportDataHelpers,
  disclaimer1,
  disclaimer2,
  IBulletPoint,
  IReportTableColumnConfig,
} from '../common/common-report-data-helpers';
import { DocxImageGenerator } from './docx-image-generator';
import { ISectionPropertiesOptions } from 'docx/build/file/document/body/section-properties';
import { ISpacingProperties } from 'docx/build/file/paragraph/formatting/spacing';

export class DocumentGeneratorHelper {
  private static spacing = {
    before: 100,
    after: 100,
  };

  public static configurePageMargins(): ISectionPropertiesOptions {
    return {
      page: {
        margin: {
          top: 800,
          bottom: 800,
          left: 800,
          right: 800,
        },
      },
    };
  }

  public static createFirstPage(reportInfo: ReportInfoDto, date: string, appName: string): ISectionOptions {
    return {
      properties: this.configurePageMargins(),
      footers: {
        default: this.getFooter(),
      },
      children: this.getFirstPage(reportInfo, date, appName),
    };
  }

  public static createDisclaimer(): ISectionOptions {
    return {
      properties: this.configurePageMargins(),
      footers: {
        default: this.getFooter(),
      },
      children: this.getDisclaimer(),
    };
  }

  public static createBulletPoints(bulletPoints: IBulletPoint[]): Paragraph[] {
    return bulletPoints.map((bulletPoint) => {
      return new Paragraph({
        children: [
          new TextRun({
            text: `${bulletPoint.label}:${this.insertTabulations(bulletPoint.tabulations ?? 0)}${bulletPoint.value}`,
            size: defaultFontSize,
            font: {
              name: mainFont,
            },
          }),
        ],
        bullet: {
          level: bulletPoint.level,
        },
        spacing: this.spacing,
      });
    });
  }

  public static async drawGenericImage(image: Base64Image, docxImageGenerator: DocxImageGenerator): Promise<Paragraph[]> {
    const imageAspect = image.size.width / image.size.height;

    return [
      new Paragraph({
        children: [await docxImageGenerator.getImage(image.imageDataBase64, defaultChartImageWidth, defaultChartImageWidth / imageAspect)],
      }),
    ];
  }

  public static convertValue<T>(
    value: number,
    props: IReportTableColumnConfig<T>,
    currentUnitSystem: IUnitSystemDto,
    addUnit: boolean,
  ): string {
    const currUnit = UnitConverterHelper.getCurrentUnit(props.unitSystem ?? UnitSystem.None, currentUnitSystem) || NoneUnit.None;
    const unitConverter = UnitConverterHelper.getUnitConverter(props.unitSystem ?? UnitSystem.None);

    const unitSymbol =
      addUnit && props.unitSystem != null && (props.unitSystem !== UnitSystem.None || props.overrideUnitSymbol)
        ? ' ' + CommonReportDataHelpers.getCellUnit(currentUnitSystem, props.unitSystem, props.overrideUnitSymbol)
        : '';
    return this.formatNumber(unitConverter.fromSi(value, currUnit).toFixed(props.decimalPlaces ?? DEFAULT_DECIMAL_PLACES) + unitSymbol);
  }

  public static formatNumber(value: string): string {
    // This used to use space as a thousand separator.
    // We decided to remove it for now.
    // In the future, we will probably implement configuration so define thousand amd coma separator
    // const parts = value.split('.');
    // parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    // return parts.join('.');
    return value;
  }

  private static getFirstPage(reportInfo: ReportInfoDto, date: string, appName: string): Paragraph[] {
    return [
      new Paragraph({
        alignment: AlignmentType.CENTER,
        heading: HeadingLevel.TITLE,
        children: [
          new TextRun({
            text: `${appName} Report`,
            font: {
              name: mainFont,
            },
          }),
        ],
      }),
      this.insertLineBreaks(15),
      ...this.createReportInformation(reportInfo, date),
    ];
  }

  private static createReportInformation(reportInfo: ReportInfoDto, date: string): Paragraph[] {
    const bulletPoints: IBulletPoint[] = CommonReportDataHelpers.createReportInformationBulletPoints(reportInfo, date);
    return this.createBulletPoints(bulletPoints);
  }

  private static getDisclaimer(): Paragraph[] {
    return [
      this.insertLineBreaks(50),
      new Paragraph({
        alignment: AlignmentType.LEFT,
        style: 'text-small',
        children: [
          new TextRun({
            text: disclaimer1,
            font: {
              name: mainFont,
            },
          }),
        ],
      }),
      new Paragraph({
        alignment: AlignmentType.LEFT,
        style: 'text-small',
        children: [
          new TextRun({
            text: disclaimer2,
            break: 1,
            font: {
              name: mainFont,
            },
          }),
        ],
      }),
    ];
  }

  public static async getHeader(
    date: string,
    appName: string,
    docxImageGenerator: DocxImageGenerator,
    isFirstPage = false,
  ): Promise<Header> {
    const leftBarOptions = {
      floating: {
        horizontalPosition: {
          offset: 0,
        },
        verticalPosition: {
          offset: 0,
        },
      },
    };
    const leftBar = await docxImageGenerator.getImage('assets/reporting/left-bar.png', 41, 1128, leftBarOptions);
    const headerLogo = await docxImageGenerator.getImage('assets/reporting/header-logo.png', 162, 38);
    if (isFirstPage) {
      return new Header({
        children: [new Paragraph({ children: [leftBar] })],
      });
    }
    return new Header({
      children: [
        new Paragraph({
          children: [
            new TextRun({ text: `${appName} Report${this.insertTabulations(2)}`, font: { name: mainFont } }),
            headerLogo,
            new TextRun({ text: `${this.insertTabulations(2)}${date}`, font: { name: mainFont } }),
          ],
          alignment: AlignmentType.CENTER,
        }),
        new Paragraph({ children: [leftBar] }),
      ],
    });
  }

  public static getFooter(): Footer {
    return new Footer({
      children: [
        new Paragraph({
          spacing: { before: 200 },
          alignment: AlignmentType.CENTER,
          children: [
            new TextRun({
              font: mainFont,
              size: defaultFontSize - 2,
              children: ['Page ', PageNumber.CURRENT, ' of ', PageNumber.TOTAL_PAGES],
            }),
          ],
        }),
      ],
    });
  }

  public static getDocStyles(): IStylesOptions {
    const baseHeadingStyle = {
      id: 'TOC1',
      name: 'toc 1',
      basedOn: 'Normal',
      next: 'Normal',
      quickFormat: true,
      paragraph: {
        spacing: {
          line: 480,
        },
      },
      run: {
        font: {
          name: mainFont,
        },
      },
      numbering: {
        color: accentColor,
        font: {
          name: mainFont,
          size: defaultFontSize,
        },
      },
    };

    return {
      default: {
        heading1: {
          run: {
            size: headerFontSize,
            bold: true,
            color: accentColor,
            font: {
              name: mainFont,
            },
          },
          paragraph: {
            spacing: {
              before: 400,
            },
          },
        },
        heading2: {
          run: {
            size: subHeaderFontSize,
            bold: true,
            color: accentColor,
            font: {
              name: mainFont,
            },
          },
          paragraph: {
            spacing: {
              before: 400,
            },
          },
        },
        heading3: {
          run: {
            size: subHeaderFontSize,
            bold: true,
            color: accentColor,
            font: {
              name: mainFont,
            },
          },
          paragraph: {
            spacing: {
              before: 400,
            },
          },
        },
      },
      paragraphStyles: [
        {
          id: 'text-small',
          run: {
            size: defaultFontSize,
          },
        },
        {
          ...baseHeadingStyle,
          run: {
            ...baseHeadingStyle.run,
            size: defaultFontSize,
          },
        },
        {
          ...baseHeadingStyle,
          id: 'TOC2',
          name: 'toc 2',
          paragraph: {
            ...baseHeadingStyle.paragraph,
            indent: {
              left: 720,
            },
          },
          run: {
            ...baseHeadingStyle.run,
            size: defaultFontSize,
          },
        },
        {
          ...baseHeadingStyle,
          id: 'TOC3',
          name: 'toc 3',
          paragraph: {
            ...baseHeadingStyle.paragraph,
            indent: {
              left: 1440,
            },
          },
          run: {
            ...baseHeadingStyle.run,
            size: defaultFontSize,
          },
        },
      ],
    };
  }

  public static createParagraphHeader(
    text: string,
    heading: (typeof HeadingLevel)[keyof typeof HeadingLevel],
    level: number | null = null,
    pageBreakBefore = false,
  ): Paragraph {
    // Text gets extra space at the beginning as the docx library not reserve any space for a gap between number and title.
    // It becomes a problem if there is at least 10 charts or 10 chapters (e.g. '8.2.10Chart 1' could be displayed).
    let options: IParagraphOptions = {
      heading,
      pageBreakBefore,
      text: ' ' + text,
      keepNext: true,
    };

    const getParagraphSpacing = (): ISpacingProperties => {
      switch (heading) {
        case HeadingLevel.HEADING_1:
          return { before: 600, after: 400 };
        default:
          return { before: 400, after: 200 };
      }
    };
    if (level != null) {
      options = {
        ...options,
        numbering: {
          level,
          reference: 'main-numbering',
        },
        spacing: getParagraphSpacing(),
      };
    }

    return new Paragraph(options);
  }

  public static insertTabulations(nbOfTabs: number): string {
    return '\t'.repeat(nbOfTabs);
  }

  public static insertLineBreaks(nbOfLines: number): Paragraph {
    return new Paragraph({
      children: [
        new TextRun({
          break: nbOfLines,
        }),
      ],
    });
  }

  public static addRangeNameToTitle(title: string, rangeName?: string): string {
    return rangeName ? `${title} (${rangeName})` : title;
  }
}
