import { Injectable } from '@angular/core';
import { BaseWsEffects } from '../base-ws.effects';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store, Action } from '@ngrx/store';
import { BackendConnectionService } from '../../shared/backend-connection/backend-connection.service';
import { ModalService } from '../../common-modules/modals/modal.service';
import {
  CopyReportingTabAction,
  CreateChartFromTemplateAction,
  DeleteChartAction,
  GetReportingColumnsAction,
  GetReportingColumnsActionResponse,
  GetSourceReportingTabAction,
  InsertChartSeriesAction,
  ReorderReportingTabAction,
  ReportingModuleName,
  UpdateReportingTabAction,
} from '@dunefront/common/modules/reporting/reporting-module.actions';
import * as uiActions from '../ui/ui.actions';
import { getCurrentAppModuleType } from '../ui/ui.selectors';
import { catchError, filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import * as actions from './reporting.actions';
import { addChartTabConfigureSeries, newReportingChartTabsFromTemplates, reportingChartCreated } from './reporting.actions';
import { ReportingChartCreationComponent } from '../../common-modules/chart/reporting-chart-creation/reporting-chart-creation.component';
import {
  getPrevSelectedReportingTabIds,
  getReportingDataState,
  getSelectedReportingTab,
  getSelectedReportingTabId,
  getSortedReportingTabsForCurrentRange,
} from './reporting.selectors';
import { getCurrentRangeId, getRanges } from '../range/range.selectors';
import { getCurrentScenarioId, getScenarioState } from '../scenario/scenario.selectors';
import { CrudResponse } from '@dunefront/common/modules/common.actions';
import { ReportingFactory } from './model/reporting.factory';
import { RangeConstants } from '@dunefront/common/dto/range.dto';
import * as appActions from '../app.actions';
import { dataFailed, deleteRowsSuccess, insertRowsSuccess, reorderRowSuccess, updateRowSuccess } from '../app.actions';
import { of } from 'rxjs';
import { GaugeDataChartConfigurationComponent } from '../../pages/gauge-data-page/gauge-data-chart/gauge-data-chart-configuration/gauge-data-chart-configuration.component';
import { ReportingChartConfigurationComponent } from '../../pages/common/reporting/chart/reporting-chart-configuration/reporting-chart-configuration.component';
import { notEmpty } from '@dunefront/common/common/state.helpers';
import { defaultSelectedReportingTabId } from './reporting-module.state';
import { ReportingTabDto } from '@dunefront/common/dto/reporting-tab.dto';
import { RouterHelperService } from '../../shared/services/router-helper.service';
import {
  RouteModuleReporting,
  RouteModuleReportingTab,
  RouteModuleReportingTabReport,
} from '../../pages/common/reporting/reporting.routes';
import { ChartDtoUpdateNotificationService } from '../../common-modules/chart/chart-dto-update-notification.service';
import { IChartTemplateDto } from '@dunefront/common/dto/chart-templates.dto';
import { sortTemplatesByDb } from '@dunefront/common/common/templates/template-parser';
import { getAreCurrentResultsPresentAndCompleted } from '../calculation-engine/calculation-engine-results.selectors';

@Injectable()
export class ReportingTabEffects extends BaseWsEffects {
  constructor(
    actions$: Actions,
    store: Store,
    wsService: BackendConnectionService,
    modalService: ModalService,
    private routerHelperService: RouterHelperService,
    private chartDtoUpdateNotificationService: ChartDtoUpdateNotificationService,
  ) {
    super(actions$, wsService, ReportingModuleName, true, true, modalService, store);
  }

  // region new reporting tab
  public newChartAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(uiActions.addChartTabAction),
        concatLatestFrom(() => this.store.select(getCurrentAppModuleType)),
        map(([action, moduleType]) => {
          if (moduleType === ModuleType.Data_Analysis) {
            this.store.dispatch(addChartTabConfigureSeries({ chartType: 'timevol' }));
          } else {
            const data = { newChartProps: action.newChartProps };
            this.modalService.open(ReportingChartCreationComponent, data);
          }
        }),
      ),
    { dispatch: false },
  );

  public addChartTabConfigureSeries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.addChartTabConfigureSeries),
      concatLatestFrom(() => [
        this.store.select(getCurrentScenarioId),
        this.store.select(getCurrentAppModuleType),
        this.store.select(getCurrentRangeId),
        this.store.select(getRanges),
      ]),
      mergeMap(([action, currentScenarioId, moduleType, rangeId, ranges]) => {
        return this.emit<GetReportingColumnsActionResponse>(
          new GetReportingColumnsAction({
            chartType: action.chartType,
            scenarioId: currentScenarioId,
            moduleType,
            rangeId,
          }),
        ).pipe(
          map((result) => actions.getReportingColumnsSuccess({ result: result.payload, ranges })),
          tap(() =>
            this.modalService.open(ReportingChartConfigurationComponent, { chartType: action.chartType }, 'chart-config-modal', '1000px'),
          ),
          catchError((err) => of(dataFailed(err))),
        );
      }),
    ),
  );

  public addChartTabConfigDone$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.addChartTabConfigDone),
      concatLatestFrom(() => [
        this.store.select(getCurrentRangeId),
        this.store.select(getCurrentScenarioId),
        this.store.select(getCurrentAppModuleType),
        this.store.select(getSortedReportingTabsForCurrentRange),
      ]),
      mergeMap(([action, rangeId, scenarioId, moduleType, reportingTabs]) => {
        return this.emit<CrudResponse>(
          ReportingFactory.getInsertReportingChartAction(
            action.chartType === 'timevol',
            scenarioId,
            rangeId,
            moduleType,
            reportingTabs,
            ReportingFactory.getChartSeriesRows(action.chartResultsTableState, -1, scenarioId),
          ),
        ).pipe(
          switchMap((result) => [insertRowsSuccess(result.payload), reportingChartCreated(result.payload)]),
          catchError((err) => of(dataFailed(err))),
        );
      }),
    ),
  );

  // endregion

  // region edit reporting tab
  public editChartPropsAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(uiActions.editChartPropsAction),
      mergeMap((action) => this.emitUpdate(new UpdateReportingTabAction(action.reportingTab))),
    ),
  );

  public reportingTabNameChanged = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateRowSuccess),
        filter((action) => action.affectedRows.reportingTabs != null),
      ),
    { dispatch: false },
  );

  public editGaugeDataChartAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(uiActions.editGaugeDataChartAction),
      concatLatestFrom(() => [
        this.store.select(getReportingDataState),
        this.store.select(getScenarioState),
        this.store.select(getCurrentAppModuleType),
        this.store.select(getRanges),
      ]),
      tap(([action]) =>
        this.modalService.open(
          GaugeDataChartConfigurationComponent,
          { isInitialConfiguration: action.gaugeDataRequestedAfterFileImport },
          'chart-config-modal',
          '1000px',
          undefined,
          false,
        ),
      ),
      mergeMap(([, reportingState, scenarioState, moduleType, ranges]) => {
        return this.emit<GetReportingColumnsActionResponse>(
          new GetReportingColumnsAction({
            chartId: reportingState.chartState.chartData?.ChartId ?? 0,
            chartType: 'gaugedata',
            scenarioId: scenarioState.currentScenarioId,
            moduleType,
            rangeId: RangeConstants.EmptyRangeId,
          }),
        ).pipe(
          map((result) => actions.getReportingColumnsSuccess({ result: result.payload, ranges: ranges })),
          catchError((err) => of(dataFailed(err))),
        );
      }),
    ),
  );

  public editReportingChartAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(uiActions.editChartTabAction),
      concatLatestFrom(() => [
        this.store.select(getSelectedReportingTab),
        this.store.select(getScenarioState),
        this.store.select(getCurrentAppModuleType),
        this.store.select(getRanges),
      ]),
      tap(([action, selectedReportingTab]) => {
        const reportingTab = action.reportingTab ?? selectedReportingTab;
        if (reportingTab === undefined) {
          throw new Error('Reporting tab not found');
        }
        return this.modalService.open(
          ReportingChartConfigurationComponent,
          { chartType: reportingTab.IsChartTimeVolume ? 'timevol' : 'mdtvd' },
          'chart-config-modal',
          '1000px',
          undefined,
          false,
        );
      }),
      mergeMap(([action, selectedReportingTab, scenarioState, moduleType, ranges]) => {
        const reportingTab = action.reportingTab ?? selectedReportingTab;
        if (reportingTab === undefined) {
          throw new Error('Reporting tab not found');
        }
        return this.emit<GetReportingColumnsActionResponse>(
          new GetReportingColumnsAction({
            chartId: reportingTab.ChartId,
            chartType: reportingTab.IsChartTimeVolume ? 'timevol' : 'mdtvd',
            scenarioId: scenarioState.currentScenarioId,
            moduleType,
            rangeId: reportingTab.RangeId,
          }),
        ).pipe(
          map((result) => actions.getReportingColumnsSuccess({ result: result.payload, ranges })),
          catchError((err) => of(dataFailed(err))),
        );
      }),
    ),
  );

  public saveChartConfigDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.saveChartConfigDialog),
      mergeMap(({ chartResultsTableState, chartId, scenarioId }) =>
        this.emitInsert(new InsertChartSeriesAction(ReportingFactory.getChartSeriesRows(chartResultsTableState, chartId, scenarioId))),
      ),
    ),
  );

  public notifyChartUpdatedAfterInsertSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(insertRowsSuccess),
        filter((action) => action.affectedRows.chartSeries != null),
        tap((action) => {
          const chartId = (action.affectedRows.chartSeries?.rows ?? [])[0]?.ChartId;
          if (chartId != null && chartId > 0) {
            this.chartDtoUpdateNotificationService.notifyChartUpdated(chartId);
          }
        }),
        catchError((err) => of(dataFailed(err))),
      ),
    { dispatch: false },
  );
  // endregion

  // region delete reporting tab
  public deleteChartAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(uiActions.deleteReportingChartAction),
        concatLatestFrom(() => notEmpty(this.store.select(getSelectedReportingTab))),
        tap(async ([, reportingTab]) => {
          const confirmResult = await this.modalService.showConfirm('Confirm delete reporting tab?', 'Information');
          if (!confirmResult) {
            return;
          }
          this.store.dispatch(
            actions.deleteReportingChart({
              rowIds: [reportingTab.ChartId],
              shouldResetResults: false,
              scenarioId: reportingTab.ScenarioId,
            }),
          );
        }),
      ),
    { dispatch: false },
  );

  public deleteReportingTab$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.deleteReportingChart),
      mergeMap((action) =>
        this.emit<CrudResponse>(new DeleteChartAction(action)).pipe(
          map((result) => deleteRowsSuccess(result.payload)),
          catchError((err) => of(dataFailed(err))),
        ),
      ),
    ),
  );
  // endregion

  // region copy reporting tab
  public copyReportingChartTab$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.copyReportingChartTabAction),
      concatLatestFrom(() => this.store.select(getSelectedReportingTabId)),
      filter(([action, reportingTabId]) => reportingTabId !== undefined || reportingTabId !== defaultSelectedReportingTabId),
      mergeMap(([action, reportingTabId]) =>
        this.emit<CrudResponse>(new CopyReportingTabAction(<number>reportingTabId)).pipe(
          switchMap((result) => [insertRowsSuccess(result.payload), reportingChartCreated(result.payload)]),
          catchError((err) => of(dataFailed(err))),
        ),
      ),
    ),
  );
  // endregion

  // region chart templates
  public newReportingChartTabsFromTemplates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.newReportingChartTabsFromTemplates),
      concatLatestFrom(() => [
        this.store.select(getSortedReportingTabsForCurrentRange),
        this.store.select(getCurrentRangeId),
        this.store.select(getCurrentScenarioId),
        this.store.select(getCurrentAppModuleType),
        this.store.select(getAreCurrentResultsPresentAndCompleted),
      ]),
      mergeMap(([action, reportingTabs, rangeId, scenarioId, moduleType, areCurrentResultsPresentAndCompleted]) => {
        const sortedChartTemplates = sortTemplatesByDb(action.chartTemplates);
        const chartTemplate = sortedChartTemplates.shift() as IChartTemplateDto;
        return this.emit<CrudResponse>(
          new CreateChartFromTemplateAction({
            chartTemplate: chartTemplate,
            chartAxisProperties: ReportingFactory.createChartAxisPropertyDtosForTemplate(scenarioId, chartTemplate.Properties.axis),
            reportingTab: ReportingFactory.getNewReportingTabDto(
              chartTemplate.IsTimeVolume,
              rangeId,
              scenarioId,
              reportingTabs,
              moduleType,
              chartTemplate.Name,
            ),
            useImportData: areCurrentResultsPresentAndCompleted === false,
          }),
        ).pipe(
          switchMap((result) => {
            const actions: (
              | (CrudResponse & Action<'[app] insertRowsSuccess'>)
              | (CrudResponse & Action<'[Reporting] reportingChartCreated'>)
              | ({
                  chartTemplates: IChartTemplateDto[];
                } & Action<'[Reporting] newReportingChartTabsFromTemplates'>)
            )[] = [insertRowsSuccess(result.payload), reportingChartCreated(result.payload)];
            if (sortedChartTemplates.length) {
              actions.push(newReportingChartTabsFromTemplates({ chartTemplates: sortedChartTemplates }));
            }
            return actions;
          }),
          catchError((err) => of(dataFailed(err))),
        );
      }),
    ),
  );

  public insertRowsSuccess_Chart$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(appActions.insertRowsSuccess, appActions.deleteRowsSuccess),
        filter((action) => action.affectedRows.chart != null),
        concatLatestFrom(() => [
          this.store.select(getSortedReportingTabsForCurrentRange),
          this.store.select(getPrevSelectedReportingTabIds),
        ]),
        tap(async ([{ affectedRows }, reportingTabs, prevSelectedReportingTabIds]) => {
          const affectedCharts = affectedRows.chart;

          // navigate after chart inserted/copied (includes delete undo)
          const insertedChartId = affectedCharts?.insertedRows?.[0]?.Id;
          if (insertedChartId != null) {
            const reportingTab = affectedRows.reportingTabs?.insertedRows?.find((tab) => tab.ChartId === insertedChartId);
            if (reportingTab) {
              this.navigateToTab(reportingTab.Id);
            }

            // navigate after chart deleted (includes insert/copy undo)
          } else if (affectedCharts?.deletedIds && affectedCharts.deletedIds.length > 0) {
            const deletedChartId = affectedCharts.deletedIds[0];
            let currentTabIndex = reportingTabs.findIndex((tab) => tab.ChartId === deletedChartId);

            if (currentTabIndex < 0) {
              for (const prevTabId of [...prevSelectedReportingTabIds].reverse()) {
                const tabIndex = reportingTabs.findIndex((tab) => tab.Id === prevTabId);
                {
                  if (tabIndex >= 0) {
                    currentTabIndex = prevTabId;
                    break;
                  }
                }
              }
            }

            if (currentTabIndex < 0) {
              currentTabIndex = reportingTabs.length > 0 ? reportingTabs[reportingTabs.length - 1].Id : 0;
            }

            this.navigateToTab(currentTabIndex);
          }
        }),
      ),
    { dispatch: false },
  );

  //endregion

  // region reorder reporting tab

  public copyReorderReportingTab$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.reorderReportingTabAction),
      concatLatestFrom(() => [this.store.select(getSortedReportingTabsForCurrentRange)]),
      mergeMap(([action, reportingTabs]) =>
        this.emit<CrudResponse>(new ReorderReportingTabAction(reportingTabs[action.fromIndex], action.toIndex)).pipe(
          map((result) => reorderRowSuccess(result.payload)),
        ),
      ),
    ),
  );

  //endregion

  // region get source reporting tabs

  public getSourceReportingTabsAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.getSourceReportingTabs),
      switchMap((action) =>
        this.emit<ReportingTabDto[]>(new GetSourceReportingTabAction(action.scenarioId)).pipe(
          map((result) => actions.getSourceReportingTabsSuccess({ sourceReportingTabs: result.payload })),
          catchError((err) => of(dataFailed(err))),
        ),
      ),
    ),
  );

  //endregion

  // region helper functions

  private navigateToTab(tabId: number): void {
    const urlParts = this.routerHelperService.getDecodedUrlParts();
    const newChartUrl =
      urlParts.slice(0, 6).join('/') +
      `/${RouteModuleReporting}/${RouteModuleReportingTab}/` +
      (tabId > 0 ? tabId : RouteModuleReportingTabReport);
    this.routerHelperService.navigate([newChartUrl]).then();
  }

  //endregion
}
