<div class="dialog-header">
  <h4>Data Chart Configuration</h4>
</div>
<div class="dialog-content" *ngIf="resultColumnsTableState.rows.length">
  <app-chart-configuration-grid #gridComponent [chartSeries]="resultColumnsTableState"></app-chart-configuration-grid>
</div>
<div class="dialog-content" *ngIf="!resultColumnsTableState.rows.length">No data available.</div>
<div class="dialog-footer">
  <div class="presets">
    <button
      appButton
      type="button"
      class="btn btn-primary btn-sm"
      (click)="onMenuToggle($event, menu)"
      [isUiLockable]="false"
      [disabled]="!resultColumnsTableState.rows.length"
    >
      Select Template
    </button>
    <p-menu #menu [popup]="true" [appendTo]="'body'" [model]="templatesList" [style]="{ width: '13rem' }"></p-menu>
    <button
      appButton
      type="button"
      class="btn btn-primary btn-sm"
      [isUiLockable]="false"
      (buttonClick)="onClearClicked()"
      [disabled]="!resultColumnsTableState.rows.length"
    >
      Clear All
    </button>
  </div>
  <div class="row">
    <button
      appButton
      type="button"
      class="btn btn-primary btn-sm"
      [isUiLockable]="false"
      (buttonClick)="onCancelClicked()"
      [disabled]="isInitialConfiguration"
    >
      Cancel
    </button>
    <button
      appButton
      type="button"
      class="btn btn-primary btn-sm"
      data-cy="data-chart-configuration-generate-chart-btn"
      [isUiLockable]="false"
      (buttonClick)="onOkClicked()"
      [disabled]="!isSomethingSelected"
    >
      Generate Chart
    </button>
  </div>
</div>
