<div class="grid-form-container main with-gap" *ngIf="pipe">
  <div class="grid-form-container no-padding inline cols-3 with-gap">
    <div></div>
    <div>Down</div>
    <div>Up</div>
    <app-form-input
      appUnwrap
      label="Number Of Ports"
      [source]="downFlow"
      [key]="'NoOfPorts'"
      [decimalPlaces]="0"
      [unitType]="UnitType.None"
      (valueChanged)="onFlowPropertyChange($event, false)"
      dataCy="no-of-ports-down"
    ></app-form-input>
    <app-form-input
      appUnwrap
      [source]="upFlow"
      [key]="'NoOfPorts'"
      [decimalPlaces]="0"
      [unitType]="UnitType.None"
      (valueChanged)="onFlowPropertyChange($event, true)"
      dataCy="no-of-ports-up"
    ></app-form-input>
    <app-form-input
      appUnwrap
      label="Port Diameter"
      [source]="downFlow"
      [key]="'PortDiameter'"
      [decimalPlaces]="3"
      [unitType]="UnitType.Diameter"
      (valueChanged)="onFlowPropertyChange($event, false)"
      dataCy="port-diameter-down"
    ></app-form-input>
    <app-form-input
      appUnwrap
      [source]="upFlow"
      [key]="'PortDiameter'"
      [decimalPlaces]="3"
      [unitType]="UnitType.Diameter"
      (valueChanged)="onFlowPropertyChange($event, true)"
      dataCy="port-diameter-up"
    ></app-form-input>
    <app-form-input
      appUnwrap
      label="Port Length"
      [source]="downFlow"
      [key]="'PortLength'"
      [unitType]="UnitType.Short_Length"
      (valueChanged)="onFlowPropertyChange($event, false)"
      dataCy="port-length-down"
    ></app-form-input>
    <app-form-input
      appUnwrap
      [source]="upFlow"
      [key]="'PortLength'"
      [unitType]="UnitType.Short_Length"
      (valueChanged)="onFlowPropertyChange($event, true)"
      dataCy="port-length-up"
    ></app-form-input>
  </div>
  <div class="grid-form-container no-padding inline cols-3 with-gap">
    <div></div>
    <div>
      <app-check-box [source]="pipe.rowData" [key]="'IsDownFlowPresent'" label="Down" (valueChanged)="onPropertyChange($event)">
      </app-check-box>
    </div>
    <div>
      <app-check-box [source]="pipe.rowData" [key]="'IsUpFlowPresent'" label="Up" (valueChanged)="onPropertyChange($event)">
      </app-check-box>
    </div>
    <app-form-input
      appUnwrap
      label="Annulus OD"
      [source]="downFlow"
      [key]="'AnnulusOD'"
      [disabled]="!pipe.rowData.IsDownFlowPresent"
      [unitType]="UnitType.Diameter"
      [decimalPlaces]="3"
      (valueChanged)="onFlowPropertyChange($event, false)"
    ></app-form-input>
    <app-form-input
      appUnwrap
      [source]="upFlow"
      [key]="'AnnulusOD'"
      [disabled]="!pipe.rowData.IsUpFlowPresent"
      [unitType]="UnitType.Diameter"
      [decimalPlaces]="3"
      (valueChanged)="onFlowPropertyChange($event, true)"
    ></app-form-input>
    <app-form-input
      appUnwrap
      label="Annulus ID"
      [source]="downFlow"
      [key]="'AnnulusID'"
      [disabled]="!pipe.rowData.IsDownFlowPresent"
      [unitType]="UnitType.Diameter"
      [decimalPlaces]="3"
      (valueChanged)="onFlowPropertyChange($event, false)"
    ></app-form-input>
    <app-form-input
      appUnwrap
      [source]="upFlow"
      [key]="'AnnulusID'"
      [disabled]="!pipe.rowData.IsUpFlowPresent"
      [unitType]="UnitType.Diameter"
      [decimalPlaces]="3"
      (valueChanged)="onFlowPropertyChange($event, true)"
    ></app-form-input>
    <app-form-input
      appUnwrap
      label="Annulus Length"
      [source]="downFlow"
      [key]="'AnnulusLength'"
      [disabled]="!pipe.rowData.IsDownFlowPresent"
      [unitType]="UnitType.Short_Length"
      (valueChanged)="onFlowPropertyChange($event, false)"
    ></app-form-input>
    <app-form-input
      appUnwrap
      [source]="upFlow"
      [key]="'AnnulusLength'"
      [disabled]="!pipe.rowData.IsUpFlowPresent"
      [unitType]="UnitType.Short_Length"
      (valueChanged)="onFlowPropertyChange($event, true)"
    ></app-form-input>
  </div>
</div>
