import {
  ChartTimeVolMode,
  IDepthBasedColumn,
  IDepthBasedResult,
  IDepthBasedRow,
  IDepthBasedTime,
} from '@dunefront/common/modules/reporting/reporting-module.actions';
import { DictionaryWithArray, IDictionaryWithArray } from '@dunefront/common/common/state.helpers';
import {
  createChartDataColumn,
  createChartDataRowDto,
  createChartDataSet,
  DepthDataStatus,
  IChartDataDtoColumn,
} from '@dunefront/common/modules/reporting/dto/chart-data.dto';
import { CalculationEngineMessageType } from '@dunefront/common/modules/calculation-engine/calculation-engine.interfaces';
import { DataFileType, DataType } from '@dunefront/common/dto/data-storage';
import { EnumHelpers } from '@dunefront/common/utils/enum.helpers';
import { ImportColumnGroupType } from '@dunefront/common/modules/data-storage/dto/import-column.dto';
import { CalculationProgressUpdatedCurrentScenarioActionProps, ReportingCalculationJobStatus } from './reporting.actions';
import { ChartState, IDepthDataForRange, IDepthDataForScenario, ReportingModuleState } from './reporting-module.state';
import { RangeConstants } from '@dunefront/common/dto/range.dto';
import { ReportingHelpers } from '@dunefront/common/modules/reporting/reporting-helpers';
import { ChartDataSourceType } from '@dunefront/common/modules/reporting/dto/chart.dto';
import { ReportingFactory } from './model/reporting.factory';
import { ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';

export class ReportingCalculationProgressModuleReducerHelper {
  public static calculationProgressUpdatedCurrentScenarioAction(
    state: ReportingModuleState,
    { calcPayload, scenarioId, rangeId, timeVolMode, moduleType }: CalculationProgressUpdatedCurrentScenarioActionProps,
  ): ReportingModuleState {
    let workingState = { ...state };

    if (!workingState.calculationJobId) {
      workingState = this.initialiseStateForNewCalculation(workingState, calcPayload.jobId, rangeId, timeVolMode, moduleType);
    }

    if (calcPayload.jobId !== workingState.calculationJobId) {
      return workingState;
    }

    if (calcPayload.messageType === CalculationEngineMessageType.complete) {
      workingState.calculationJobId = undefined;
      workingState.calculationJobStatus = ReportingCalculationJobStatus.notActive;
      workingState.selectedSimulationTime = Number.MAX_VALUE;

      return workingState;
    }

    if (calcPayload.messageType !== CalculationEngineMessageType.dataUpdate) {
      return workingState;
    }

    // update status and progress
    workingState.calculationJobMessage = calcPayload.calcEngineMessage.textMessage;
    workingState.calculationJobProgress = calcPayload.calcEngineMessage.progress;

    if (!calcPayload.calcEngineMessage.data) {
      return workingState;
    }

    workingState = this.processData(calcPayload.calcEngineMessage.data, workingState, moduleType, scenarioId);

    // update status and progress
    switch (workingState.calculationJobStatus) {
      case ReportingCalculationJobStatus.starting:
        workingState.calculationJobStatus = ReportingCalculationJobStatus.firstData;
        break;
      case ReportingCalculationJobStatus.firstData:
        workingState.calculationJobStatus = ReportingCalculationJobStatus.chartAvailable;
        break;
    }

    return workingState;
  }

  private static processData(
    calcEngineMessageData: any,
    state: ReportingModuleState,
    moduleType: ModuleType,
    scenarioId: number,
  ): ReportingModuleState {
    // add time based data

    let workingState = { ...state };

    // FluidPro
    if (moduleType === ModuleType.Simulate_Disp) {
      // parse
      const parsedData: IFluidProParsedData = JSON.parse(calcEngineMessageData);

      // add time based data
      workingState = this.addTimeBasedData_FluidPro(workingState, parsedData);

      // add depth based data
      const { depthDataForRange } = ReportingCalculationProgressModuleReducerHelper.getStateForDepthDataUpdate(workingState, scenarioId, true);

      const depthBasedResults = ReportingCalculationProgressModuleReducerHelper.getDepthBasedResult_FluidPro(
        depthDataForRange,
        scenarioId,
        parsedData,
      );

      for (const depthBasedResult of depthBasedResults) {
        const stateForDepthDataUpdate = ReportingCalculationProgressModuleReducerHelper.getStateForDepthDataUpdate(workingState, scenarioId);
        workingState = this.updateDepthBasedResultData(depthBasedResult, scenarioId, stateForDepthDataUpdate);
      }

      // time
      workingState = this.updateTime(workingState, parsedData.Time);
    }
    // PackPro
    else {
      const parsedData: IPackProParsedData = JSON.parse(calcEngineMessageData);

      // add time based data
      workingState = this.addTimeBasedData_PackPro(workingState, parsedData.Volume.Data, parsedData.Time.Data, parsedData.TimeData);

      // add depth based data
      const stateForDepthDataUpdate = ReportingCalculationProgressModuleReducerHelper.getStateForDepthDataUpdate(workingState, scenarioId, true);

      const depthBasedResult = ReportingCalculationProgressModuleReducerHelper.getDepthBasedResult_PackPro(
        stateForDepthDataUpdate.depthDataForRange,
        scenarioId,
        parsedData.Time.Data,
        parsedData.PackingData,
      );

      workingState = this.updateDepthBasedResultData(depthBasedResult, scenarioId, stateForDepthDataUpdate);

      // time
      workingState = this.updateTime(workingState, parsedData.Time.Data);
    }

    return workingState;
  }

  private static updateTime(state: ReportingModuleState, time: number): ReportingModuleState {
    const workingState = { ...state };

    // update time and duration
    const simulationDuration = time > workingState.simulationDuration ? time : workingState.simulationDuration;
    workingState.simulationDuration = simulationDuration;
    workingState.selectedSimulationTime = simulationDuration;

    return workingState;
  }

  private static updateDepthBasedResultData(
    depthBasedResult: IDepthBasedResult,
    scenarioId: number,
    { workingState, depthDataForRange, depthDataForScenario }: IStateForDepthDataUpdate,
  ): ReportingModuleState {
    workingState = { ...workingState };

    const depthDataResults = DictionaryWithArray.upsert(depthDataForRange.depthDataResults, depthBasedResult, 'FileType');

    depthDataForRange = { ...depthDataForRange, depthDataResults };
    depthDataForScenario = {
      ...depthDataForScenario,
      depthDataForRanges: DictionaryWithArray.upsertById(
        depthDataForScenario.depthDataForRanges,
        depthDataForRange,
        RangeConstants.EmptyRangeId,
      ),
    };

    workingState.depthDataForScenarios = DictionaryWithArray.upsertById(workingState.depthDataForScenarios, depthDataForScenario, scenarioId);

    return workingState;
  }

  /**
   * @param resetRangeDepthData - during calculation animation shows only latest depth data, not needed to store them all
   */
  private static getStateForDepthDataUpdate(
    state: ReportingModuleState,
    scenarioId: number,
    resetRangeDepthData = false,
  ): IStateForDepthDataUpdate {
    let workingState = { ...state };

    if (!workingState.depthDataForScenarios.dict[scenarioId]) {
      workingState = {
        ...workingState,
        depthDataForScenarios: DictionaryWithArray.upsert(
          workingState.depthDataForScenarios,
          {
            scenarioId: scenarioId,
            depthDataForRanges: DictionaryWithArray.clear() as IDictionaryWithArray<IDepthDataForRange>,
          },
          'scenarioId',
        ),
      };
    }

    let depthDataForScenario = workingState.depthDataForScenarios.dict[scenarioId];
    if (!depthDataForScenario) {
      depthDataForScenario = { scenarioId, depthDataForRanges: { ids: [], dict: {} } };
      workingState.depthDataForScenarios = DictionaryWithArray.upsertById(workingState.depthDataForScenarios, depthDataForScenario, scenarioId);
    }

    let depthDataForRange = depthDataForScenario.depthDataForRanges.dict[RangeConstants.EmptyRangeId];
    if (!depthDataForRange || resetRangeDepthData) {
      depthDataForRange = {
        rangeId: RangeConstants.EmptyRangeId,
        depthDataResultsStatus: DepthDataStatus.NotRequested,
        simulationDuration: 0,
        depthDataResults: { ids: [], dict: {} },
        allDataPoints: [],
        loadedDataPoints: [],
      };
      workingState.depthDataForScenarios = DictionaryWithArray.upsertById(workingState.depthDataForScenarios, depthDataForScenario, scenarioId);
    }

    return { workingState, depthDataForScenario, depthDataForRange };
  }

  private static getDepthBasedResult_PackPro(
    depthDataForRange: IDepthDataForRange,
    scenarioId: number,
    time: number,
    packProDepthDataPoints: ICalcDataPoint[][],
  ): IDepthBasedResult {
    let packingResult = DictionaryWithArray.getCopy(depthDataForRange.depthDataResults, DataFileType.PackingResult);
    if (!packingResult) {
      packingResult = {
        Columns: [
          {
            DataType: DataType.Measured_Depth,
            ColumnName: EnumHelpers.enumToText(DataType[DataType.Measured_Depth]),
            GroupType: ImportColumnGroupType.Miscellaneous,
          },
          {
            DataType: DataType.Pack_Height,
            ColumnName: EnumHelpers.enumToText(DataType[DataType.Pack_Height]),
            GroupType: ImportColumnGroupType.Miscellaneous,
          },
          {
            DataType: DataType.Fluid_Index,
            ColumnName: EnumHelpers.enumToText(DataType[DataType.Fluid_Index]),
            GroupType: ImportColumnGroupType.Miscellaneous,
          },
          {
            DataType: DataType.Total_Perf_Pack_Volume,
            ColumnName: EnumHelpers.enumToText(DataType[DataType.Total_Perf_Pack_Volume]),
            GroupType: ImportColumnGroupType.Miscellaneous,
          },
          {
            DataType: DataType.Flow_Path_Description,
            ColumnName: EnumHelpers.enumToText(DataType[DataType.Flow_Path_Description]),
            GroupType: ImportColumnGroupType.Miscellaneous,
          },
          {
            DataType: DataType.Flow_Direction,
            ColumnName: EnumHelpers.enumToText(DataType[DataType.Flow_Direction]),
            GroupType: ImportColumnGroupType.Miscellaneous,
          },
          {
            DataType: DataType.Gravel_Index,
            ColumnName: EnumHelpers.enumToText(DataType[DataType.Gravel_Index]),
            GroupType: ImportColumnGroupType.Miscellaneous,
          },
          {
            DataType: DataType.Pack_Height_Ratio,
            ColumnName: EnumHelpers.enumToText(DataType[DataType.Pack_Height_Ratio]),
            GroupType: ImportColumnGroupType.Miscellaneous,
          },
        ],
        Times: [],
        FileType: DataFileType.PackingResult,
        ScenarioId: scenarioId,
        RangeId: RangeConstants.EmptyRangeId,
      };
    }

    const packingTime: IDepthBasedTime = { Time: time, Rows: [] };
    for (const depthData of packProDepthDataPoints) {
      const getByColumn = (name: string): ICalcDataPoint | undefined => depthData.find((column) => column.ColumnName === name);

      const measuredDepth = getByColumn('Measured Depth')?.Data;
      const trueVerticalDepth = getByColumn('True Vertical Depth')?.Data;
      const packHeight = getByColumn('Pack Height')?.Data;
      const fluidIds = getByColumn('Fluid Index')?.DataArray;
      const totalPerfPackVolume = getByColumn('Total Perf. Pack Volume')?.Data;
      const flowPathDescription = getByColumn('Flow Path Description')?.Data;
      const flowDirection = getByColumn('Flow Direction')?.Data;
      const gravelIndex = getByColumn('Gravel Index')?.Data;
      const packHeightRatio = getByColumn('Pack Height Ratio')?.Data;

      if (
        measuredDepth === undefined ||
        packHeight === undefined ||
        fluidIds === undefined ||
        totalPerfPackVolume === undefined ||
        flowPathDescription === undefined ||
        flowDirection === undefined ||
        gravelIndex === undefined ||
        packHeightRatio === undefined ||
        trueVerticalDepth === undefined
      ) {
        throw new Error('Packing data is missing value');
      }

      const packingDataRow: IDepthBasedRow = {
        RowIndex: packingTime.Rows.length,
        MD: measuredDepth,
        TVD: trueVerticalDepth,
        Values: [
          [measuredDepth],
          [packHeight],
          fluidIds,
          [totalPerfPackVolume],
          [flowPathDescription],
          [flowDirection],
          [gravelIndex],
          [packHeightRatio],
        ],
      };
      packingTime.Rows.push(packingDataRow);
    }
    packingResult = { ...packingResult, Times: [...packingResult.Times, packingTime] };
    return packingResult;
  }

  private static getDepthBasedResult_FluidPro(
    depthDataForRange: IDepthDataForRange,
    scenarioId: number,
    parsedData: IFluidProParsedData,
  ): IDepthBasedResult[] {
    const result: IDepthBasedResult[] = [];

    for (const parsedDepthDataFile of parsedData.DepthData) {
      let depthBasedResult = DictionaryWithArray.getCopy(depthDataForRange.depthDataResults, parsedDepthDataFile.FileType);
      if (!depthBasedResult) {
        const columns: IDepthBasedColumn[] = parsedDepthDataFile.ImportColumnProperties.map((col) => ({
          ColumnName: col.ColumnName,
          DataType: col.DataType,
          GroupType: ImportColumnGroupType.Miscellaneous,
        }));

        depthBasedResult = {
          Columns: columns,
          Times: [],
          FileType: parsedDepthDataFile.FileType,
          ScenarioId: scenarioId,
          RangeId: RangeConstants.EmptyRangeId,
        };
      }

      const depthBasedTime: IDepthBasedTime = { Time: parsedData.Time, Rows: [] };

      const mdColIndex = 0;
      const tvdColdIndex = 1;
      const numberOfRows = parsedDepthDataFile.ImportColumnProperties[0].ImportDatas.length;

      for (let rowIndex = 0; rowIndex < numberOfRows; rowIndex++) {
        const values = parsedDepthDataFile.ImportColumnProperties.map((c) => c.ImportDatas[rowIndex].DataArray);
        const depthBasedRow: IDepthBasedRow = {
          RowIndex: depthBasedTime.Rows.length,
          MD: parsedDepthDataFile.ImportColumnProperties[mdColIndex].ImportDatas[rowIndex].DataArray[0],
          TVD: parsedDepthDataFile.ImportColumnProperties[tvdColdIndex].ImportDatas[rowIndex].DataArray[0],
          Values: values,
        };

        depthBasedTime.Rows.push(depthBasedRow);
      }

      depthBasedResult = { ...depthBasedResult, Times: [...depthBasedResult.Times, depthBasedTime] };

      result.push(depthBasedResult);
    }

    return result;
  }

  private static addTimeBasedData_PackPro(
    state: ReportingModuleState,
    volume: number,
    time: number,
    timeData: ICalcDataPoint[],
  ): ReportingModuleState {
    const workingState = { ...state };
    const argument = workingState.resultsTimeChartState.chartData?.ArgumentDataType === DataType.Pump_Volume ? volume : time;

    workingState.resultsTimeChartState = this.addToTimeBasedAnimationChart(
      workingState.resultsTimeChartState,
      timeData,
      argument,
      ChartDataSourceType.ChartSourceResultsTimeBased,
    );

    workingState.resultsEvaluateFrictionChartState = this.addToTimeBasedAnimationChart(
      workingState.resultsEvaluateFrictionChartState,
      timeData,
      argument,
      ChartDataSourceType.ChartSourceEvaluateFriction,
    );

    workingState.resultsEvaluatePressureChartState = this.addToTimeBasedAnimationChart(
      workingState.resultsEvaluatePressureChartState,
      timeData,
      argument,
      ChartDataSourceType.ChartSourceEvaluatePressure,
    );

    return workingState;
  }

  private static addTimeBasedData_FluidPro(state: ReportingModuleState, parsedData: IFluidProParsedData): ReportingModuleState {
    const workingState = { ...state };
    const argument = state.resultsTimeChartState.chartData?.ArgumentDataType === DataType.Pump_Volume ? parsedData.Volume : parsedData.Time;

    workingState.resultsTimeChartState = this.addToTimeBasedAnimationChart(
      workingState.resultsTimeChartState,
      this.toCalcDataPoints(parsedData.TimeData.ImportColumnProperties),
      argument,
      ChartDataSourceType.ChartSourceFluidProPressureAndECD,
    );

    return workingState;
  }

  private static toCalcDataPoints(data: ImportColumnProperty[]): ICalcDataPoint[] {
    return data.map((c) => {
      return {
        ColumnName: c.ColumnName,
        DataType: c.DataType,
        Data: c.ImportDatas[0].DataArray[0],
      };
    });
  }

  private static addToTimeBasedAnimationChart(
    currentChartState: ChartState,
    timeData: ICalcDataPoint[],
    argument: number,
    chartDataSourceType: ChartDataSourceType,
  ): ChartState {
    if (!currentChartState.chartData) {
      return currentChartState;
    }

    // add row to time based data
    let workingChartDataColumns: IChartDataDtoColumn[] = [...(currentChartState.chartData?.ChartDataColumns ?? [])];
    const workingChartDatasets = [...(currentChartState.chartData?.ChartDataSets ?? [])];

    // if no columns, add columns from time data
    if (!workingChartDataColumns.length) {
      workingChartDataColumns = timeData
        .filter((timeDataPoint) => ReportingHelpers.isFixedChartDataType(chartDataSourceType, timeDataPoint.DataType))
        .map((timeDataPoint, index) =>
          createChartDataColumn(
            ReportingHelpers.getAxisForEvaluateAnimationCharts(timeDataPoint.DataType, chartDataSourceType),
            timeDataPoint.DataType,
            0,
            index,
            timeDataPoint.ColumnName,
          ),
        );
    }

    // insert chart data columns if not already set - these can not be created on the server because the data types are not available
    if (workingChartDataColumns.length === 0) {
      currentChartState.chartSeries.forEach((chartSeries) => {
        const chartSeriesDataType = timeData.find((column) => column.ColumnName === chartSeries.ColumnName)?.DataType;
        if (chartSeriesDataType !== undefined) {
          const chartDataColumn = createChartDataColumn(
            chartSeries.AxisType,
            chartSeriesDataType,
            0,
            workingChartDataColumns.length,
            chartSeries.ColumnName,
          );
          workingChartDataColumns.push(chartDataColumn);
        }
      });
    }

    // if no data is in state, create one
    if (currentChartState.chartData?.ChartDataSets.length === 0) {
      workingChartDatasets.push(createChartDataSet());
    }

    const row = createChartDataRowDto(argument, [], currentChartState.chartData?.ChartDataSets.length);

    workingChartDataColumns.forEach((seriesColumn) => {
      if (seriesColumn.DataSetIndex === 0) {
        const columnData = timeData.find((column) => column.ColumnName === seriesColumn.Name)?.Data;
        if (columnData !== undefined) {
          row.Values[seriesColumn.DataValueIndex] = timeData.find((column) => column.ColumnName === seriesColumn.Name)?.Data;
        }
      }
    });

    workingChartDatasets[0] = { ...workingChartDatasets[0] };
    workingChartDatasets[0].ChartDataRows = [...workingChartDatasets[0].ChartDataRows];
    workingChartDatasets[0].ChartDataRows.push(row);

    return {
      ...currentChartState,
      chartData: {
        ...currentChartState.chartData,
        ChartId: currentChartState.chartData.ChartId,
        ChartDataSets: workingChartDatasets,
        ChartDataColumns: workingChartDataColumns,
      },
    };
  }

  public static initialiseStateForNewCalculation(
    state: ReportingModuleState,
    jobId: string,
    rangeId: number,
    timeVolMode: ChartTimeVolMode,
    moduleType: ModuleType,
  ): ReportingModuleState {
    const argumentDataType = timeVolMode === ChartTimeVolMode.time ? DataType.Time : DataType.Pump_Volume;

    return {
      ...state,
      calculationJobId: jobId,
      calculationJobStatus: ReportingCalculationJobStatus.starting,
      resultsTimeChartState: ReportingFactory.createEmptyChartStateWithChartTemplateId(
        argumentDataType,
        moduleType === ModuleType.Simulate_Disp
          ? ChartDataSourceType.ChartSourceFluidProPressureAndECD
          : ChartDataSourceType.ChartSourceResultsTimeBased,
        state.chartDtos,
        RangeConstants.EmptyRangeId,
      ),
      resultsEvaluatePressureChartState: ReportingFactory.createEmptyChartStateWithChartTemplateId(
        argumentDataType,
        ChartDataSourceType.ChartSourceEvaluatePressure,
        state.chartDtos,
        rangeId,
      ),
      resultsEvaluateFrictionChartState: ReportingFactory.createEmptyChartStateWithChartTemplateId(
        argumentDataType,
        ChartDataSourceType.ChartSourceEvaluateFriction,
        state.chartDtos,
        rangeId,
      ),
      resultsDepthChartState: ReportingFactory.createEmptyChartStateWithChartTemplateId(
        DataType.Measured_Depth,
        ChartDataSourceType.ChartSourceResultsDepthBased,
        state.chartDtos,
        rangeId,
      ),
      depthDataForScenarios: DictionaryWithArray.clear(),
      simulationDuration: 0,
      selectedSimulationTime: 0,
    };
  }
}

interface IPackProParsedData {
  Time: ICalcDataPoint;
  Volume: ICalcDataPoint;
  TimeData: ICalcDataPoint[];
  PackingData: ICalcDataPoint[][];
}

interface ICalcDataPoint {
  ColumnName: string;
  DataType: number;
  Data: number;
  DataArray?: number[];
}

interface ImportColumnProperty {
  ColumnName: string;
  DataType: DataType;
  ImportDatas: { DataArray: number[] }[];
}

interface ImportFileWithImportDatas {
  FileType: DataFileType;
  ImportColumnProperties: ImportColumnProperty[];
}

interface IFluidProParsedData {
  Time: number;
  Volume: number;
  TimeData: {
    ImportColumnProperties: ImportColumnProperty[];
  };
  DepthData: [ImportFileWithImportDatas];
}

interface IStateForDepthDataUpdate {
  workingState: ReportingModuleState;
  depthDataForScenario: IDepthDataForScenario;
  depthDataForRange: IDepthDataForRange;
}
