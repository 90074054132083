import { DictionaryWithArray, IDictionaryWithArray } from '../../common/state.helpers';
import { ImportFileWithMinMaxArgumentsDto } from '../../modules/data-storage/dto/import-file.dto';
import { ImportColumnDto } from '../../modules/data-storage/dto/import-column.dto';
import { IValidatedModuleState } from '../../common/common-state.interfaces';

export interface DataStorageModuleState {
  files: IDictionaryWithArray<ImportFileWithMinMaxArgumentsDto>;
  columns: IDictionaryWithArray<ImportColumnDto[]>;
  columnNames: IColNameWithId[];
  isLoaded: boolean;
}

export const dataStorageInitialState: DataStorageModuleState = {
  files: DictionaryWithArray.createFromArray([]),
  columns: DictionaryWithArray.createFromArray([]),
  columnNames: [],
  isLoaded: false,
};

export interface IColNameWithId {
  colId: number;
  name: string;
}

export type ValidatedImportColumnDto = ImportColumnDto & IValidatedModuleState<ImportColumnDto>;

export function isValidatedModuleState<T>(obj: any | undefined | null): obj is IValidatedModuleState<T> {
  return obj?.error != null;
}
