export enum ChartMdTvdMode {
  md = 0,
  tvd = 1,
}

export enum CrosshairMode {
  NONE = 0,
  SINGLE = 1,
  MULTIPLE = 2,
}

export enum TooltipPosition {
  DEFAULT = 0,
  TOP_LEFT = 1,
  TOP_RIGHT = 2,
  BOTTOM_RIGHT = 3,
  BOTTOM_LEFT = 4,
}

export type ChartZoomMode = 'x' | 'y' | 'xy';
