import { Fluid } from '@dunefront/common/modules/fluid/model/fluid';
import {
  IAzimuthBasedData,
  IAzimuthBasedSection,
  IDrawing,
  IDrawingHoleSection,
  IFlowPathFluidResults,
  IFluidFront,
  IHoleSection,
  IPackingResult,
} from '../../../../+store/reporting/reporting-depth-animation.selectors';
import {
  IDrawPoint,
  IDrawPointWithAngle,
  IFluidLabelParams,
  ILayoutParams,
  IMappedPoint,
  ScaleTickCoordinates,
  TextType,
  TooltipRow,
} from './drawing.component.types';
import { DrawingSurveyMapHelper, IMappedSurveySection } from './drawing.survey-map.helper';
import { Pipe } from '@dunefront/common/modules/pipes/pipe';
import { IAxisStyle } from '@dunefront/common/modules/reporting/dto/chart-axis-property.dto';
import {
  areRectsClose,
  getRotatedRectBoundary,
  isPointCloseToRect,
  isPointInsideRect,
  isPointInsideTriangle,
  offsetPointWithPolarCoordinates,
  pointsDistance,
  pointToSegmentDistance,
  Rect,
  rotateRect,
  Size,
  updateRectPositionToKeepDistanceToPoint,
} from '@dunefront/common/common/math-geometry-helpers';
import { LengthConverter } from '@dunefront/common/unit-converters/converters/length/length.converter';
import { IUnitSystemDto, LengthUnit } from '@dunefront/common/dto/unit-system.dto';
import { toRadians } from 'chart.js/helpers';
import { Point } from 'chart.js';
import { blendColors, colorToHex, measureText } from '../../../../shared/helpers/canvas-drawing-helpers';
import { DrawingLayoutType, WellPartType } from '@dunefront/common/modules/settings/dto/settingsDto';
import { PipeType } from '@dunefront/common/dto/pipe.dto';
import { isScreenPipe, ScreenPipe } from '@dunefront/common/modules/pipes/lower-completion-pipes/pipes/screen-pipe';
import { GaugeCarrierPipe } from '@dunefront/common/modules/pipes/running-string-pipes/pipes/gauge-carrier-pipe';
import { drawCrosshair, drawPackingTooltip } from './packing-tooltip';
import { GlobalOptionsDto } from '@dunefront/common/dto/global-options.dto';
import { Survey } from '@dunefront/common/modules/well/model/survey/survey';
import { Color } from '@dunefront/common/modules/reporting/dto/chart.types';
import { ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import { GeneralCalculations } from '@dunefront/common/common/general.calculations';
import { FlowPathDescription } from '@dunefront/common/modules/pumping/dto/well-fluid.dto';

export interface WellDrawingContext {
  readonly zoom: number;
  readonly currentUnitSystem: IUnitSystemDto;
  readonly axisStyles: IAxisStyle | null;
  readonly layoutType: DrawingLayoutType;
  readonly isInPanel: boolean;
  readonly defaultGlobalOptions: GlobalOptionsDto;
  readonly lastMousePosition?: Point;
  readonly isTooltipVisible: boolean;
  readonly moduleType?: ModuleType;
}

interface FluidConcentrationInfo {
  fluidName: string;
  cellConcentration: number;
  avgConcentration: number;
}

export const defaultFont = '12px Arial';
export const defaultFontHeight = 12;
export const defaultFontBaseLine = 9;

export const defaultLineWidth = 1;
export const legendColourTop = 10; // gap between top of legend block and top of colour rectangle
export const legendColourWidth = 40; // legend colour rectangle width
export const legendColourHeight = 14; // legend colour rectangle height
export const legendLabelTop = 11; // gap between top of legend block and top of text
export const legendColorGap = 5; // horizontal gap between top of legend block and text label
export const legendItemGap = 12; // horizontal gap between legend items
export const legendRowHeight = 23; // height of legend row
export const legendGapBelow = 10; // vertical gap below legend
export const legendBackgroundPadding = 4;

// height from top of font to baseline
export const surveyDrawingWidth = 100; // pipe width for survey view
export const maxDrawingWidth = 200; // max pipe width for horizontal and vertical views
export const minPadding = 5;
export const noFluidColor: Color = Color.White;

export const partsToDrawAll: DrawingPart = {
  fluidsInAnnulus: true,
  fluidsInPipes: true,
  holeBoundary: true,
  legendReturnHeight: true,
  packingData: true,
  pipes: true,
  runningStringPipes: true,
  xAxisLabels: true,
  depthScale: true,
  radiusScale: true,

  workstring: true,
  lowerAnnulus: true,
  washpipe: true,
  upperAnnulus: true,
};

export interface DrawingPart {
  packingData: boolean;
  lowerAnnulus: boolean;
  upperAnnulus: boolean;
  washpipe: boolean;
  workstring: boolean;
  fluidsInAnnulus: boolean;
  fluidsInPipes: boolean;
  holeBoundary: boolean;
  pipes: boolean;
  runningStringPipes: boolean;
  xAxisLabels: boolean;
  legendReturnHeight: boolean;
  depthScale: boolean;
  radiusScale: boolean;
}

export interface WellDrawingCommonParams {
  ctx: CanvasRenderingContext2D;
  canvasSize: Size;
  drawing: IDrawing;
  drawingSection: WellPartType;
  layoutType: DrawingLayoutType;
  context: WellDrawingContext;
  smoothedSurvey: Survey[];
  smoothedHole: IDrawingHoleSection[];
  pipesAsDrawingHoleSections: IDrawingHoleSection[];
  legendHeight: number;
  legendItems: DrawingLegendItem[];
}

export interface WellDrawingParams extends WellDrawingCommonParams {
  partsToDraw: DrawingPart;
  wellSize: Size;
  offset: IDrawPoint;
  zoomFactor: number;
  wScaleFactor: number;
}

export interface WellDrawingResults {
  params: WellDrawingParams;
  layout: ILayoutParams;
  drawnPipes: Pipe[];
  drawnHoleSections: IDrawingHoleSection[];
}

export interface DrawingLegendItem<T = any> {
  text: string;
  color: Color | null;
  reference?: T;
}

export interface ContourLinePoint {
  md: number;
  value: number; // 0.0 - 1.0 of hole diameter
}

type ContourLine = ContourLinePoint[];

export class WellDrawingHelpers {
  // region Fluid Front Lines

  public static drawFluidFrontLines(
    fluidFronts: IFluidFront[],
    fluids: Fluid[],
    flowPathDescription: FlowPathDescription,
    hole: IDrawingHoleSection[],
    ctx: CanvasRenderingContext2D,
    layout: ILayoutParams,
  ): void {
    if (hole.length === 0) {
      return;
    }

    const minMd = hole[0].topMd;
    const maxMd = hole[hole.length - 1].bottomMd;

    const fluidFrontsToDraw = fluidFronts.filter((f) => f.flowPathDescription === flowPathDescription && f.md >= minMd && f.md <= maxMd);

    ctx.save();
    ctx.lineWidth = 2;

    const drawLine = (points: [IDrawPoint, IDrawPoint], lineWidth: number, alpha: number, color: string, dash: number[]): void => {
      ctx.lineWidth = lineWidth;
      ctx.globalAlpha = alpha;
      ctx.strokeStyle = color;
      ctx.setLineDash(dash);

      ctx.beginPath();
      ctx.moveTo(Math.round(points[0].x), Math.round(points[0].y));
      ctx.lineTo(Math.round(points[1].x), Math.round(points[1].y));
      ctx.stroke();
    };

    for (const front of fluidFrontsToDraw) {
      const holeIndex = this.holeIndexForMd(front.md, hole);
      const radius = hole[holeIndex].diameter / 2;
      const points = this.xyForMdRadius(front.md, radius, layout);

      // draw background line
      drawLine(points, 3, 0.8, 'black', []);

      // draw dashed line
      drawLine(points, 2, 1, this.getFluidColorHex(front.fluidId, fluids, ctx), [10, 1]);
    }

    ctx.restore();
  }

  // endregion

  // region Contour Lines

  public static getContourLines(fluidSection: IFlowPathFluidResults): ContourLine[] {
    const numberOfLines = 20;
    const { sections } = fluidSection;
    const linesResults: ContourLine[] = Array.from({ length: 20 }, () => []);

    for (const section of sections) {
      const numberOfValues = WellDrawingHelpers.getNumberOfAzimuths(section);
      if (numberOfValues == null) {
        continue;
      }

      const numberOfAzimuthCells = numberOfValues - 1;
      const singleCellDiffValue = 1.0 / numberOfAzimuthCells;

      for (let lineIdx = 0; lineIdx < numberOfLines; lineIdx++) {
        const lineIndexValue = (1.0 / (numberOfLines + 1)) * (lineIdx + 1);
        let linePos: number | undefined;
        for (let cellIndex = 0; cellIndex < numberOfValues; cellIndex++) {
          const dataValueStart = section.azimuthBasedData[0].data[cellIndex];
          const dataValueEnd = section.azimuthBasedData[0].data[cellIndex + 1];

          if (lineIndexValue >= dataValueStart && lineIndexValue <= dataValueEnd) {
            const cellStartValue = cellIndex * singleCellDiffValue;

            linePos = GeneralCalculations.LinearInterpolation(
              lineIndexValue,
              dataValueStart,
              dataValueEnd,
              cellStartValue,
              cellStartValue + singleCellDiffValue,
            );

            break;
          }

          if (cellIndex == 0 && lineIndexValue < dataValueStart) {
            linePos = 0;
            break;
          }

          if (cellIndex == numberOfValues - 1 && lineIndexValue > dataValueEnd) {
            linePos = 1;
            break;
          }
        }

        const isLastSection = sections.indexOf(section) === sections.length - 1;

        linesResults[lineIdx].push({
          md: isLastSection ? section.bottomMd : section.topMd,
          value: linePos ?? 0,
        });
      }
    }

    return linesResults;
  }

  public static drawContourLines(
    fluidSection: IFlowPathFluidResults,
    hole: IDrawingHoleSection[],
    ctx: CanvasRenderingContext2D,
    layout: ILayoutParams,
  ): void {
    const lines = this.getContourLines(fluidSection);

    ctx.save();
    ctx.strokeStyle = 'red';
    ctx.lineWidth = 1;
    ctx.setLineDash([5, 4]);

    for (const line of lines) {
      ctx.beginPath();

      for (let i = 0; i < line.length; i++) {
        const { md, value } = line[i];
        const holeIndex = this.holeIndexForMd(md, hole);

        const radius = (value - 0.5) * hole[holeIndex].diameter;
        const [, point] = this.xyForMdRadius(md, radius, layout);

        if (i === 0) {
          ctx.moveTo(point.x, point.y);
        } else {
          ctx.lineTo(point.x, point.y);
        }
      }

      ctx.stroke();
    }

    ctx.restore();
  }

  // endregion

  // region draw fluids

  public static drawFluidsInHole(
    fluids: Fluid[],
    flowPathFluidSections: IFlowPathFluidResults,
    hole: IDrawingHoleSection[],
    ctx: CanvasRenderingContext2D,
    layout: ILayoutParams,
    opacity: number,
    context: WellDrawingContext,
    internalFlowHoleSections?: IDrawingHoleSection[],
  ): void {
    const { sections } = flowPathFluidSections;

    for (const section of sections) {
      const numberOfAzimuths = WellDrawingHelpers.getNumberOfAzimuths(section);
      if (numberOfAzimuths == null) {
        continue;
      }

      const startMd = section.topMd;
      const endMd = section.bottomMd;
      const isGravelPresent = section.gravelId != null && section.gravelId > 0;

      for (let azimuthIndex = 0; azimuthIndex < numberOfAzimuths; azimuthIndex++) {
        const fillStyle = this.getFluidFillStyle(section.azimuthBasedData, fluids, ctx, azimuthIndex, isGravelPresent, context);

        if (numberOfAzimuths === 1 && internalFlowHoleSections != null && section.internalFluidId != null) {
          // draw external fluid (outside screen)
          WellDrawingHelpers.drawExternalFluidShape(internalFlowHoleSections, hole, startMd, endMd, layout, ctx, opacity, fillStyle, true);

          // draw internal fluid (between washpipe and screen)
          // single fluid, no gravel possible
          const fluidColorHex = this.getFluidColorHex(section.internalFluidId, fluids, ctx);

          const firstHoleIndex = this.holeIndexForMd(startMd, internalFlowHoleSections);
          const lastHoleIndex = this.holeIndexForMd(endMd, internalFlowHoleSections);

          WellDrawingHelpers.drawFluidShape(
            internalFlowHoleSections,
            firstHoleIndex,
            lastHoleIndex,
            startMd,
            endMd,
            azimuthIndex,
            azimuthIndex + 1,
            numberOfAzimuths,
            layout,
            ctx,
            opacity,
            fluidColorHex,
            true,
          );
        } else {
          const { ropingFluidId, ropingFluidConcentration, drawRoping } = this.getRopingParams(section, numberOfAzimuths);
          const fillStyle = this.getFluidFillStyle(section.azimuthBasedData, fluids, ctx, azimuthIndex, isGravelPresent, context, ropingFluidId);

          const firstHoleIndex = this.holeIndexForMd(startMd, hole);
          const lastHoleIndex = this.holeIndexForMd(endMd, hole);

          WellDrawingHelpers.drawFluidShape(
            hole,
            firstHoleIndex,
            lastHoleIndex,
            startMd,
            endMd,
            azimuthIndex,
            azimuthIndex + 1,
            numberOfAzimuths,
            layout,
            ctx,
            opacity,
            fillStyle,
            context.moduleType === ModuleType.Simulate_Disp,
          );

          // region Roping Fluid
          if (drawRoping) {
            const fluidColorHex = this.getFluidColorHex(ropingFluidId, fluids, ctx);

            const azimuthsCount = 1000;
            const md = (startMd + endMd) / 2;
            const angle = Math.abs(this.findSurveyByMd(layout.smoothedSurvey, md).Deviation);

            // eccentricityFactor (0-1)
            // 0 - roping on the middle of pipe
            // 1 - roping on the pipe wall
            const eccentricityFactor = angle < 30 ? Math.pow(Math.sin(toRadians(3 * angle)), 2) : 1;
            const concentration = ropingFluidConcentration.data[azimuthIndex] ?? 0;
            const ropingHeightInAzimuths = (concentration * azimuthsCount) / 100.0;

            const centerAzimuthOffset = azimuthsCount / 2 - ropingHeightInAzimuths / 2;
            const deviationEccentricityOffset = eccentricityFactor * centerAzimuthOffset;
            // drawing (azimuth 0) starts from top of pipe
            // we need to offset it by centerAzimuthOffset to place roping in the middle of the pipe
            // and then push further toward pipe bottom wall by deviationEccentricityOffset
            const azimuthStart = centerAzimuthOffset + deviationEccentricityOffset;

            WellDrawingHelpers.drawFluidShape(
              hole,
              firstHoleIndex,
              lastHoleIndex,
              startMd,
              endMd,
              azimuthStart,
              azimuthStart + ropingHeightInAzimuths,
              azimuthsCount,
              layout,
              ctx,
              opacity,
              fluidColorHex,
              true,
            );
          }
          // endregion (Roping Fluid)
        }
      }
    }
  }

  private static getRopingParams(
    section: IAzimuthBasedSection,
    numberOfAzimuths: number,
  ):
    | { drawRoping: true; ropingFluidConcentration: IAzimuthBasedData; ropingFluidId: number }
    | { drawRoping: false; ropingFluidConcentration: undefined; ropingFluidId: undefined } {
    const ropingFluidId = numberOfAzimuths === 1 && section.ropingFluidId != null ? section.ropingFluidId : undefined;
    const ropingFluidConcentration = section.azimuthBasedData.find((d) => d.fluidId === ropingFluidId);

    if (ropingFluidId != null && ropingFluidConcentration != null) {
      return {
        drawRoping: true,
        ropingFluidId,
        ropingFluidConcentration,
      };
    } else {
      return {
        drawRoping: false,
        ropingFluidConcentration: undefined,
        ropingFluidId: undefined,
      };
    }
  }

  private static getFluidFillStyle(
    azimuthBasedData: IAzimuthBasedData[],
    fluids: Fluid[],
    ctx: CanvasRenderingContext2D,
    azimuthIndex: number,
    isGravelPresent: boolean,
    context: WellDrawingContext,
    excludedFluidId?: number,
  ): string | CanvasPattern {
    const colors: string[] = [];
    const alphas: number[] = [];

    // filter out excluded fluid if present
    azimuthBasedData = excludedFluidId != null ? azimuthBasedData.filter((d) => d.fluidId !== excludedFluidId) : azimuthBasedData;

    const concentrationsTotal = azimuthBasedData.reduce((sum, item) => sum + item.data[azimuthIndex], 0);

    for (const fluidConcentration of azimuthBasedData) {
      let color = noFluidColor;
      if (fluidConcentration.color != null) {
        color = fluidConcentration.color;
      } else {
        color = this.getFluidColor(fluidConcentration.fluidId, fluids);
      }

      colors.push(colorToHex(color, ctx));

      // alpha normalised to 0-1
      const alpha = fluidConcentration.data[azimuthIndex] / concentrationsTotal;

      alphas.push(alpha);
    }

    const color = blendColors(colors, alphas, 'FFFFFF');

    return isGravelPresent ? (this.getFluidPattern(color, isGravelPresent, ctx, context) ?? color) : color;
  }

  private static getFluidColor(fluidId: number | undefined, fluids: Fluid[]): Color {
    const fluid = fluids.find((f) => f.Id === fluidId);

    return fluid && fluid.Color != null ? fluid.Color : noFluidColor;
  }

  private static getFluidColorHex(fluidId: number | undefined, fluids: Fluid[], ctx: CanvasRenderingContext2D): string {
    return colorToHex(this.getFluidColor(fluidId, fluids), ctx);
  }

  private static drawExternalFluidShape(
    innerHole: IDrawingHoleSection[],
    outerHole: IDrawingHoleSection[],
    startMd: number,
    endMd: number,
    layout: ILayoutParams,
    ctx: CanvasRenderingContext2D,
    opacity: number,
    fillStyle: string | CanvasGradient | CanvasPattern,
    addStroke = true,
  ): void {
    const additionalSize = defaultLineWidth * 0.5;

    const firstInnerHoleIndex = this.holeIndexForMd(startMd, innerHole);
    const lastInnerHoleIndex = this.holeIndexForMd(endMd, innerHole);

    const firstOuterHoleIndex = this.holeIndexForMd(startMd, outerHole);
    const lastOuterHoleIndex = this.holeIndexForMd(endMd, outerHole);

    const pathsToDraw: [IDrawPoint[], IDrawPoint[]] = [[], []];

    let prevDiameter = 0;
    const appendPoints = (md: number, diameter: number): void => {
      const points = this.xyForMdRadius(md, diameter * 0.5, layout, additionalSize);
      pathsToDraw[0].push(points[0]);
      pathsToDraw[1].push(points[1]);

      prevDiameter = diameter;
    };

    appendPoints(startMd, outerHole[firstOuterHoleIndex].diameter);

    for (let j = firstOuterHoleIndex + 1; j <= lastOuterHoleIndex; j++) {
      appendPoints(outerHole[j].topMd, prevDiameter);
      appendPoints(outerHole[j].topMd, outerHole[j].diameter);
    }

    // add endMd points
    appendPoints(endMd, prevDiameter);
    appendPoints(endMd, innerHole[lastInnerHoleIndex].diameter);

    for (let j = lastInnerHoleIndex - 1; j >= firstInnerHoleIndex; j--) {
      appendPoints(innerHole[j].bottomMd, prevDiameter);
      appendPoints(innerHole[j].bottomMd, innerHole[j].diameter);
    }

    appendPoints(startMd, innerHole[firstInnerHoleIndex].diameter);

    this.drawFluidShapePaths(pathsToDraw, ctx, opacity, fillStyle, addStroke);
  }

  private static drawFluidShapePaths(
    pathsToDraw: IDrawPoint[][],
    ctx: CanvasRenderingContext2D,
    opacity: number,
    fillStyle: string | CanvasGradient | CanvasPattern,
    addStroke: boolean,
  ): void {
    for (const pathToDraw of pathsToDraw) {
      ctx.beginPath();
      ctx.moveTo(pathToDraw[0].x, pathToDraw[0].y);
      for (const { x, y } of pathToDraw) {
        ctx.lineTo(x, y);
      }
      ctx.closePath();

      ctx.globalAlpha = opacity;
      ctx.lineWidth = defaultLineWidth;
      ctx.fillStyle = fillStyle;
      ctx.strokeStyle = fillStyle;
      ctx.fill();
      if (addStroke) {
        ctx.stroke();
      }

      ctx.globalAlpha = 1;
    }
  }

  private static getNumberOfAzimuths({ topMd, bottomMd, azimuthBasedData }: IAzimuthBasedSection): number | undefined {
    if (azimuthBasedData.length === 0) {
      return undefined;
    }

    if (azimuthBasedData.some((c) => c.data.length !== azimuthBasedData[0].data.length)) {
      console.error('Inconsistent number of Azimuths', topMd, bottomMd, azimuthBasedData);
      return undefined;
    }

    return azimuthBasedData[0].data.length;
  }

  private static drawFluidShape(
    hole: IDrawingHoleSection[],
    firstHoleIndex: number,
    lastHoleIndex: number,
    startMd: number,
    endMd: number,
    azimuthStartIndex: number,
    azimuthEndIndex: number,
    totalAzimuths: number,
    layout: ILayoutParams,
    ctx: CanvasRenderingContext2D,
    opacity: number,
    fillStyle: string | CanvasGradient | CanvasPattern,
    addStroke = true,
  ): void {
    const additionalSize = defaultLineWidth * 0.5;
    const azimuthStartFactor = 0.5 - azimuthEndIndex / totalAzimuths;
    const azimuthEndFactor = 0.5 - azimuthStartIndex / totalAzimuths;
    const pathToDraw: IDrawPoint[] = [];

    let prevRadius = 0;
    const appendPoint = (md: number, radius: number): void => {
      pathToDraw.push(this.xyForMdRadius(md, radius, layout, additionalSize)[0]);
      prevRadius = radius;
    };

    appendPoint(startMd, azimuthStartFactor * hole[firstHoleIndex].diameter);

    for (let j = firstHoleIndex + 1; j <= lastHoleIndex; j++) {
      appendPoint(hole[j].topMd, prevRadius);
      appendPoint(hole[j].topMd, azimuthStartFactor * hole[j].diameter);
    }

    appendPoint(endMd, prevRadius);
    appendPoint(endMd, azimuthEndFactor * hole[lastHoleIndex].diameter);

    for (let j = lastHoleIndex - 1; j >= firstHoleIndex; j--) {
      appendPoint(hole[j].bottomMd, prevRadius);
      appendPoint(hole[j].bottomMd, azimuthEndFactor * hole[j].diameter);
    }

    appendPoint(startMd, azimuthEndFactor * hole[firstHoleIndex].diameter);

    this.drawFluidShapePaths([pathToDraw], ctx, opacity, fillStyle, addStroke);
  }

  public static getFluidPattern(
    color: string,
    addGravel: boolean,
    mainCtx: CanvasRenderingContext2D,
    context: WellDrawingContext,
  ): CanvasPattern | null {
    const pattern = document.createElement('canvas');
    pattern.width = 8;
    pattern.height = 8;
    const patternCtx = pattern.getContext('2d');
    if (patternCtx == null) {
      return null;
    }

    patternCtx.fillStyle = color;
    patternCtx.fillRect(0, 0, 40, 40);

    if (addGravel) {
      patternCtx.fillStyle = context.defaultGlobalOptions.GravelConcColor;

      patternCtx.fillRect(2, 2, 1, 1);
      patternCtx.fillRect(2, 3, 1, 1);
      patternCtx.fillRect(3, 3, 1, 1);

      patternCtx.fillRect(6, 6, 1, 1);
      patternCtx.fillRect(7, 6, 1, 1);
      patternCtx.fillRect(7, 5, 1, 1);

      patternCtx.globalAlpha = 0.4;
      patternCtx.fillRect(3, 2, 1, 1);
      patternCtx.fillRect(6, 5, 1, 1);

      patternCtx.fill();
    }

    return mainCtx.createPattern(pattern, 'repeat');
  }

  // endregion draw fluids

  // region helpers
  public static xyForMdRadius(md: number, radius: number, layout: ILayoutParams, additionalSize = 0): [IDrawPoint, IDrawPoint] {
    // returns x,y coordinates of points on radius either side of given md
    const centerPoint = this.xyAngleForMd(md, layout);

    const angleRadians = (centerPoint.angle * Math.PI) / 180;

    const offsetX = (radius * layout.wScale + additionalSize * Math.sign(radius)) * Math.cos(angleRadians);
    const offsetY = (radius * layout.wScale + additionalSize * Math.sign(radius)) * Math.sin(angleRadians);
    return [
      { x: centerPoint.x + offsetX, y: centerPoint.y - offsetY },
      { x: centerPoint.x - offsetX, y: centerPoint.y + offsetY },
    ];
  }

  public static xyAngleForMd(md: number, layout: ILayoutParams): IDrawPointWithAngle {
    // returns x,y coordinates of center line point at given md
    const { hd, vd, lengthProportion, surveySection } = this.mappedPointForMd(md, layout.mappedSurvey);

    const x = (hd - layout.topHd) * layout.lScale + layout.leftMargin - minPadding;
    const y = (vd - layout.topVd) * layout.lScale + layout.topMargin;

    const angle = (1 - lengthProportion) * surveySection.topDeviation + lengthProportion * surveySection.bottomDeviation;

    return { x, y, angle };
  }

  public static findSurveyByMd(survey: Survey[], md: number): Survey {
    if (!Array.isArray(survey) || survey.length === 0) {
      throw new Error('Survey array is empty or not an array');
    }

    if (md < survey[0].MD || md > survey[survey.length - 1].MD) {
      throw new Error('MD outside survey range');
    }

    for (let i = 0; i < survey.length - 1; i++) {
      const currentMD = survey[i].MD;
      const nextMD = survey[i + 1].MD;

      if (currentMD <= md && md < nextMD) {
        return survey[i];
      }
    }

    // If md equals the exact last MD in the array
    if (md === survey[survey.length - 1].MD) {
      return survey[survey.length - 1];
    }

    throw new Error('MD not found within range');
  }

  public static mappedPointForMd(md: number, mappedSurvey: IMappedSurveySection[]): IMappedPoint {
    // returns x,y coordinates of center line at md
    const surveySectionIndex = mappedSurvey.findIndex((point) => point.topMd <= md && point.bottomMd >= md);
    if (surveySectionIndex === -1) {
      throw new Error(`Survey point not found, md: ${md}`);
    }

    const surveySection = mappedSurvey[surveySectionIndex];
    const lengthProportion = (md - surveySection.topMd) / surveySection.length;

    const hd = surveySection.topHd + (md > surveySection.topMd ? (surveySection.bottomHd - surveySection.topHd) * lengthProportion : 0);
    const vd = surveySection.topVd + (md > surveySection.topMd ? (surveySection.bottomVd - surveySection.topVd) * lengthProportion : 0);

    return { hd, vd, lengthProportion, surveySection };
  }

  public static holeIndexForMd(md: number, hole: IDrawingHoleSection[]): number {
    const index = hole.findIndex((holeSize) => holeSize.topMd <= md && holeSize.bottomMd > md);
    return index === -1 ? hole.length - 1 : index;
  }

  public static innerPipesToHoleSizes(pipes: Pipe[]): IDrawingHoleSection[] {
    return pipes.map((pipe) => ({
      topMd: pipe.TopMD,
      bottomMd: pipe.BottomMD,
      diameter: isScreenPipe(pipe) ? pipe.FilterInnerDiameter : pipe.InnerDiameter,
    }));
  }

  public static drawText(
    ctx: CanvasRenderingContext2D,
    label: string,
    x: number,
    y: number,
    axisStyles: IAxisStyle | null,
    type = TextType.LABEL,
  ): void {
    ctx.fillStyle = '#666';

    const isBold = type === TextType.LABEL ? axisStyles?.AxisLabelFontBold : axisStyles?.AxisTitleFontBold;
    const isItalic = type === TextType.LABEL ? axisStyles?.AxisLabelFontItalic : axisStyles?.AxisTitleFontItalic;

    ctx.font = `${isBold ? 'bold' : ''} ${isItalic ? 'italic' : ''} ${defaultFont}`;
    ctx.fillText(label, x, y);
    ctx.fillStyle = 'black';
  }

  // endregion helper

  /**
   * Removes rows without packing at beginning and end of set.
   * At the end keeps just one (first) row without packing.
   * @param packingData
   * @private
   */
  public static trimPackingData(packingData: IPackingResult[]): IPackingResult[] {
    let firstPackingIndex: number | undefined = undefined;
    let lastPackingIndex: number | undefined;

    for (let i = 0; i < packingData.length; i++) {
      if (packingData[i].packingHeight > 0) {
        if (firstPackingIndex == null) {
          firstPackingIndex = i;
        }
        lastPackingIndex = i;
      }
    }

    if (firstPackingIndex == null || lastPackingIndex == null) {
      return [];
    }

    return packingData.slice(firstPackingIndex, lastPackingIndex + 2);
  }

  /**
   * Returns packing result for given MD
   * @param md
   * @param packingData
   * @private
   */
  private static findPackingResult(md: number, packingData: IPackingResult[]): IPackingResult | undefined {
    for (let i = 0; i < packingData.length - 1; i++) {
      if (packingData[i].topMd <= md && packingData[i + 1].topMd > md) {
        return packingData[i];
      }
    }

    return undefined;
  }

  // region depth scale
  public static drawDepthScale(
    ctx: CanvasRenderingContext2D,
    layout: ILayoutParams,
    context: WellDrawingContext,
    drawingArea: Rect,
    partsToDraw: DrawingPart,
  ): ScaleTickCoordinates[] {
    if (!partsToDraw.depthScale) {
      return [];
    }

    const minTicksDistance = 4;

    ctx.save();
    ctx.font = defaultFont;
    ctx.strokeStyle = 'silver';
    ctx.lineWidth = defaultLineWidth;

    const topMdConverted = LengthConverter.fromSi(layout.topMd, context.currentUnitSystem.Long_Length);
    const bottomMdConverted = LengthConverter.fromSi(layout.bottomMd, context.currentUnitSystem.Long_Length);
    const rangeConverted = bottomMdConverted - topMdConverted;

    const incrementOrder = Math.floor(Math.log(rangeConverted / context.zoom) / Math.LN10 + 0.000000001);
    let increment = Math.pow(10, incrementOrder);

    if (rangeConverted / increment < 1.5) {
      increment = increment / 2;
    }

    const drawnPoints: ScaleTickCoordinates[] = [];
    const initialMd = Math.round(layout.topMd / increment) * increment;

    for (let tickMd = initialMd; tickMd <= bottomMdConverted; tickMd += increment) {
      if (tickMd < topMdConverted) {
        continue;
      }

      const tickCoords = this.getDepthScaleTickCoords(tickMd, ctx, layout, context.currentUnitSystem.Long_Length);
      const { tickStart, tickEnd, labelRect, label } = tickCoords;

      if (!isPointInsideRect(tickStart, drawingArea) && !isPointInsideRect(tickEnd, drawingArea)) {
        continue;
      }

      const areTicksTooClose = drawnPoints.some((drawnTick) => this.areScaleTicksTooClose(drawnTick, tickCoords, minTicksDistance));
      if (areTicksTooClose) {
        continue;
      }

      // draw tick
      ctx.moveTo(tickStart.x, tickStart.y);
      ctx.lineTo(tickEnd.x, tickEnd.y);
      ctx.stroke();

      // draw label
      WellDrawingHelpers.drawText(ctx, label, labelRect.x, labelRect.y + labelRect.height, context.axisStyles);

      drawnPoints.push(tickCoords);
    }

    ctx.restore();

    return drawnPoints;
  }

  private static getDepthScaleTickCoords(
    value: number,
    ctx: CanvasRenderingContext2D,
    layout: ILayoutParams,
    longLengthUnit: LengthUnit,
  ): ScaleTickCoordinates {
    const md = LengthConverter.toSi(value, longLengthUnit);
    const { angle } = WellDrawingHelpers.xyAngleForMd(md, layout);
    const angleRadians = -toRadians(angle) + Math.PI;
    const [, tickStartPoint] = WellDrawingHelpers.xyForMdRadius(md, layout.holeWidth * 0.5, layout);

    return this.getScaleTickCoords(value, tickStartPoint, angleRadians, ctx);
  }

  // endregion depth scale

  // region radius scale
  public static drawRadiusScale(
    ctx: CanvasRenderingContext2D,
    layout: ILayoutParams,
    context: WellDrawingContext,
    partsToDraw: DrawingPart,
  ): ScaleTickCoordinates[] {
    if (!partsToDraw.radiusScale || context.currentUnitSystem == null) {
      return [];
    }

    ctx.save();
    ctx.font = defaultFont;
    ctx.lineWidth = defaultLineWidth;
    ctx.strokeStyle = 'silver';
    ctx.beginPath();

    const minAllowedPointsDistance = 4;

    const radiusConverted = LengthConverter.fromSi(layout.holeWidth * 0.5, context.currentUnitSystem.Short_Length);
    const incrementOrder = Math.floor(Math.log(radiusConverted) / Math.LN10 + 0.000000001);
    const increment = Math.pow(10, incrementOrder);

    const { angle } = WellDrawingHelpers.xyAngleForMd(layout.topMd, layout);

    const drawnTicks: ScaleTickCoordinates[] = [];

    for (let radius = 0; radius < radiusConverted; radius += increment) {
      const radiusPoints = WellDrawingHelpers.xyForMdRadius(
        layout.topMd,
        LengthConverter.toSi(radius, context.currentUnitSystem.Short_Length),
        layout,
        0,
      );
      const newScalePoints = radiusPoints.map((point) =>
        this.getRadiusScaleTickCoords(
          {
            ...point,
            angle,
          },
          radius,
          ctx,
        ),
      );

      const arePointsTooClose = newScalePoints.some((newScalePoint) =>
        drawnTicks.some((drawn) => this.areScaleTicksTooClose(drawn, newScalePoint, minAllowedPointsDistance)),
      );

      if (!arePointsTooClose) {
        for (const point of newScalePoints) {
          this.drawRadiusScalePoint(point, ctx, context);
          drawnTicks.push(point);
        }
      }
    }

    ctx.restore();

    return drawnTicks;
  }

  private static drawRadiusScalePoint(scalePoint: ScaleTickCoordinates, ctx: CanvasRenderingContext2D, context: WellDrawingContext): void {
    const { tickStart, tickEnd, label, labelRect } = scalePoint;

    ctx.moveTo(tickStart.x, tickStart.y);
    ctx.lineTo(tickEnd.x, tickEnd.y);
    ctx.stroke();

    WellDrawingHelpers.drawText(ctx, label, labelRect.x, labelRect.y + labelRect.height, context.axisStyles);
  }

  private static getRadiusScaleTickCoords(xyAngle: IDrawPointWithAngle, value: number, ctx: CanvasRenderingContext2D): ScaleTickCoordinates {
    const { x, y, angle } = xyAngle;
    const angleRadians = -toRadians(angle) - Math.PI / 2;

    return this.getScaleTickCoords(value, { x, y }, angleRadians, ctx);
  }

  private static getScaleTickCoords(
    tickValue: number,
    tickStart: Point,
    tickAngleRadian: number,
    ctx: CanvasRenderingContext2D,
  ): ScaleTickCoordinates {
    const tickLength = 8;
    const tickToTextSpacing = 3;

    const label = tickValue.toString();
    const labelSize = measureText(ctx, label);

    const tickToTextBoxCenterDistance = tickToTextSpacing + Math.max(labelSize.width, labelSize.height);
    const tickEnd = offsetPointWithPolarCoordinates(tickStart, tickAngleRadian, tickLength);
    const boxCenter = offsetPointWithPolarCoordinates(tickEnd, tickAngleRadian, tickToTextBoxCenterDistance);

    const initialRect: Rect = {
      x: boxCenter.x - labelSize.width / 2,
      y: boxCenter.y - labelSize.height / 2 - 2,
      width: labelSize.width,
      height: labelSize.height,
    };

    return {
      tickStart,
      tickEnd,
      label,
      labelRect: updateRectPositionToKeepDistanceToPoint(initialRect, tickEnd, tickToTextSpacing),
    };
  }

  private static areScaleTicksTooClose(scalePoint1: ScaleTickCoordinates, scalePoint2: ScaleTickCoordinates, minDistance = 0): boolean {
    return (
      areRectsClose(scalePoint1.labelRect, scalePoint2.labelRect, minDistance) ||
      isPointCloseToRect(scalePoint1.tickStart, scalePoint2.labelRect, minDistance) ||
      isPointCloseToRect(scalePoint1.tickEnd, scalePoint2.labelRect, minDistance) ||
      isPointCloseToRect(scalePoint2.tickStart, scalePoint1.labelRect, minDistance) ||
      isPointCloseToRect(scalePoint2.tickEnd, scalePoint1.labelRect, minDistance)
    );
  }

  // endregion radius scale

  // region axis labels
  public static drawAxisLabels(
    ctx: CanvasRenderingContext2D,
    layout: ILayoutParams,
    radiusTicks: ScaleTickCoordinates[],
    depthTicks: ScaleTickCoordinates[],
    context: WellDrawingContext,
    partsToDraw: DrawingPart,
  ): void {
    if (!partsToDraw.xAxisLabels) {
      return;
    }
    ctx.font = defaultFont;

    // draw radius axis label
    {
      const radiusLabel = 'Radius (' + LengthConverter.getSymbol(context.currentUnitSystem.Short_Length) + ')';
      const labelSize = measureText(ctx, radiusLabel);
      const { angle, x, y } = WellDrawingHelpers.xyAngleForMd(layout.topMd, layout);

      const labelTickAngle = -toRadians(angle) - Math.PI / 2;
      const initialDistance = angle === 0 ? 20 : 40;
      const labelCenter = this.getScaleLabelCenter({ x, y }, labelTickAngle, labelSize, radiusTicks, initialDistance);

      ctx.save();

      ctx.translate(labelCenter.x, labelCenter.y);
      ctx.rotate(-toRadians(angle));
      WellDrawingHelpers.drawText(ctx, radiusLabel, -labelSize.width / 2, 2, context.axisStyles, TextType.TITLE);

      ctx.restore();
    }

    // draw depth axis label
    {
      const md = (layout.topMd + layout.bottomMd) * 0.5;
      const { angle } = WellDrawingHelpers.xyAngleForMd(md, layout);
      const [, holeEdgePoint] = WellDrawingHelpers.xyForMdRadius(md, layout.holeWidth * 0.5, layout);

      const depthLabel = 'Measured Depth (' + LengthConverter.getSymbol(context.currentUnitSystem.Long_Length) + ')';
      const labelSize = measureText(ctx, depthLabel);

      const labelCenter = this.getScaleLabelCenter(holeEdgePoint, -toRadians(angle) + Math.PI, labelSize, depthTicks, 40, 8);

      ctx.save();
      ctx.translate(labelCenter.x, labelCenter.y);
      ctx.rotate(-toRadians(angle) + Math.PI / 2);

      WellDrawingHelpers.drawText(ctx, depthLabel, -labelSize.width / 2, 2, context.axisStyles, TextType.TITLE);

      ctx.restore();
    }
  }

  private static getScaleLabelCenter(
    startPoint: Point,
    angleRad: number,
    labelSize: Size,
    drawnTicks: ScaleTickCoordinates[],
    initialDistance = 20,
    minDistance = 2,
  ): Point {
    if (isNaN(startPoint.x) || isNaN(startPoint.y)) {
      return { x: 0, y: 0 };
    }
    for (let distance = initialDistance; ; distance += 2) {
      const labelCenter = offsetPointWithPolarCoordinates(startPoint, angleRad, distance);

      const rect: Rect = {
        x: labelCenter.x - labelSize.width / 2,
        y: labelCenter.y - labelSize.height / 2,
        width: labelSize.width,
        height: labelSize.height,
      };

      const labelRotatedRect = rotateRect(rect, angleRad + Math.PI / 2);
      const labelRectBoundary = getRotatedRectBoundary(labelRotatedRect);

      if (
        !isPointCloseToRect(startPoint, labelRectBoundary, minDistance) &&
        !drawnTicks.some((tick) => areRectsClose(tick.labelRect, labelRectBoundary, minDistance))
      ) {
        return labelCenter;
      }
    }
  }

  private static drawLegendBackground(ctx: CanvasRenderingContext2D, x: number, y: number, width: number, height: number): void {
    ctx.save();

    ctx.fillStyle = 'white';
    ctx.fillRect(x, y, width, height);

    ctx.restore();
  }

  // endregion axis labels

  public static fluidsToLegend(drawing: IDrawing): DrawingLegendItem<Fluid>[] {
    const fluidIds = WellDrawingHelpers.getFluidIdsToLabel(drawing);
    const items: DrawingLegendItem<Fluid>[] = [];

    for (const fluidId of fluidIds) {
      const fluid = drawing.fluids.find((f) => f.Id === fluidId);
      if (fluid == null) {
        continue;
      }
      items.push({ text: fluid.Name, color: fluid.Color, reference: fluid });
    }
    return items;
  }

  public static getLegendLabels(legendItems: DrawingLegendItem[], ctx: CanvasRenderingContext2D, layoutWidth: number): IFluidLabelParams[] {
    let rowEndX = 0;
    let rowIndex = 0;
    const labels: IFluidLabelParams[] = [];

    for (const legend of legendItems) {
      const textWidth = ctx.measureText(legend.text).width;

      const width = textWidth + legendColourWidth + legendColorGap;
      if (rowEndX > 0 && rowEndX + legendItemGap + width > layoutWidth) {
        rowIndex++;
        rowEndX = 0;
      }

      labels.push({
        color: legend.color ?? noFluidColor,
        name: legend.text,
        width,
        x: rowEndX,
        row: rowIndex,
      });

      rowEndX += width + legendItemGap;
    }

    // center labels in rows
    for (let i = 0; i <= rowIndex; i++) {
      const rowLabels = labels.filter((label) => label.row === i);
      const lastLabel = rowLabels[rowLabels.length - 1];
      if (lastLabel == null) {
        continue;
      }
      const offset = Math.round((layoutWidth - lastLabel.x - lastLabel.width) / 2);
      rowLabels.forEach((label) => (label.x = Math.max((label.x += offset), 0)));
    }

    return labels;
  }

  public static getLegendHeightWithLabels(labels: IFluidLabelParams[]): number {
    const numberOfRows = Math.max(...labels.map((l) => l.row)) + 1;

    return numberOfRows * legendRowHeight;
  }

  public static getLegendHeight(legendItems: DrawingLegendItem[], ctx: CanvasRenderingContext2D, layoutWidth: number): number {
    const labels = this.getLegendLabels(legendItems, ctx, layoutWidth);

    return this.getLegendHeightWithLabels(labels);
  }

  // region draw fluid labels
  public static drawLegend(
    legendItems: DrawingLegendItem[],
    ctx: CanvasRenderingContext2D,
    layoutWidth: number,
    context: WellDrawingContext,
  ): void {
    const labels = this.getLegendLabels(legendItems, ctx, layoutWidth);
    const legendHeight = this.getLegendHeightWithLabels(labels);

    const minLabelX = Math.min(...labels.map((l) => l.x));
    const maxLabelX = Math.max(...labels.map((l) => l.x + l.width));

    const backgroundX = minLabelX - legendBackgroundPadding;
    const backgroundWidth = maxLabelX - minLabelX + 2 * legendBackgroundPadding;
    const backgroundHeight = legendHeight + legendBackgroundPadding;

    ctx.save();
    ctx.font = defaultFont;

    // draw background
    WellDrawingHelpers.drawLegendBackground(ctx, backgroundX, 0, backgroundWidth, backgroundHeight);

    // draw labels
    for (const label of labels) {
      this.drawLegendItem(label, ctx, context);
    }

    ctx.restore();
  }

  private static drawLegendItem(label: IFluidLabelParams, ctx: CanvasRenderingContext2D, context: WellDrawingContext): void {
    ctx.fillStyle = label.color;
    ctx.fillRect(label.x, label.row * legendRowHeight + legendColourTop, legendColourWidth, legendColourHeight);
    ctx.fillStyle = 'black';

    WellDrawingHelpers.drawText(
      ctx,
      label.name,
      label.x + legendColourWidth + legendColorGap,
      label.row * legendRowHeight + legendLabelTop + defaultFontBaseLine,
      context.axisStyles,
      TextType.LEGEND,
    );
  }

  // endregion draw fluid labels

  // region draw packing data
  public static drawPackingData(
    hole: IDrawingHoleSection[],
    packingData: IPackingResult[],
    ctx: CanvasRenderingContext2D,
    layout: ILayoutParams,
    context: WellDrawingContext,
    partsToDraw: DrawingPart,
  ): void {
    if (!partsToDraw.packingData || packingData.length === 0) {
      return;
    }
    ctx.fillStyle = context.defaultGlobalOptions.GravelConcColor;
    ctx.beginPath();
    ctx.lineWidth = defaultLineWidth;
    const firstPackingMd = packingData[0].topMd;
    const lastPackingMd = packingData[packingData.length - 1].topMd;
    const firstHoleIndex = WellDrawingHelpers.holeIndexForMd(firstPackingMd, hole);

    let radius = -hole[firstHoleIndex].diameter * 0.5;
    const originPoints = WellDrawingHelpers.xyForMdRadius(firstPackingMd, radius, layout);

    ctx.moveTo(originPoints[0].x, originPoints[0].y);

    let points: IDrawPoint[] = [];
    let md = 0;

    // draw top of packing data
    for (let i = 0; i < packingData.length; i++) {
      const packingPoint = packingData[i];
      const holeIndex = WellDrawingHelpers.holeIndexForMd(packingPoint.topMd, hole);

      // draw packing continued to current topMd
      md = packingPoint.topMd;
      points = WellDrawingHelpers.xyForMdRadius(md, radius, layout);
      ctx.lineTo(points[0].x, points[0].y);

      // draw new packing height packing point for current topMd
      radius = packingPoint.packingHeight - hole[holeIndex].diameter * 0.5;
      points = WellDrawingHelpers.xyForMdRadius(md, radius, layout);
      ctx.lineTo(points[0].x, points[0].y);

      if (i < packingData.length - 1) {
        // draw additional packing points for survey
        const nextPackingPoint = packingData[i + 1];

        hole
          .filter((holeSection) => holeSection.topMd > packingPoint.topMd && holeSection.topMd < nextPackingPoint.topMd)
          .forEach((holeSection) => {
            // draw packing continued to current topMd
            md = holeSection.topMd;
            points = WellDrawingHelpers.xyForMdRadius(md, radius, layout);
            ctx.lineTo(points[0].x, points[0].y);

            // draw new packing height packing point for current topMd
            radius = packingPoint.packingHeight - holeSection.diameter * 0.5;
            points = WellDrawingHelpers.xyForMdRadius(md, radius, layout);
            ctx.lineTo(points[0].x, points[0].y);
          });
      }
    }

    // draw bottom corner of packing before the rest of bottom edge when final packing point was > 0
    if (packingData[packingData.length - 1].packingHeight >= 0) {
      const bottomPackingHoleSection = WellDrawingHelpers.holeIndexForMd(md, hole);
      points = WellDrawingHelpers.xyForMdRadius(md, hole[bottomPackingHoleSection].diameter * 0.5, layout, defaultLineWidth * 0.5);
      ctx.lineTo(points[1].x, points[1].y);
    }

    let bottomHolePoints = hole.filter((holeSection) => holeSection.topMd >= firstPackingMd && holeSection.topMd <= lastPackingMd);
    radius = bottomHolePoints.length > 0 ? bottomHolePoints[bottomHolePoints.length - 1].diameter * 0.5 : 0;

    const firstPackingHole = hole[WellDrawingHelpers.holeIndexForMd(firstPackingMd, hole)];
    bottomHolePoints = [firstPackingHole, ...bottomHolePoints];

    for (let i = bottomHolePoints.length - 1; i > 0; i--) {
      md = bottomHolePoints[i].topMd;
      points = WellDrawingHelpers.xyForMdRadius(md, radius, layout, defaultLineWidth * 0.5);
      ctx.lineTo(points[1].x, points[1].y);

      radius = bottomHolePoints[i - 1].diameter * 0.5;
      points = WellDrawingHelpers.xyForMdRadius(md, radius, layout, defaultLineWidth * 0.5);
      ctx.lineTo(points[1].x, points[1].y);
    }

    ctx.lineTo(originPoints[0].x, originPoints[0].y);
    ctx.fill();
  }

  // endregion draw packing data

  // region draw hole boundary
  public static drawHoleBoundary(
    hole: IDrawingHoleSection[],
    ctx: CanvasRenderingContext2D,
    layout: ILayoutParams,
    partsToDraw: DrawingPart,
  ): void {
    if (!partsToDraw.holeBoundary) {
      return;
    }

    ctx.strokeStyle = 'black';
    ctx.lineWidth = defaultLineWidth;

    const topMdRadius = ([...hole].reverse().find((holePoint) => holePoint.topMd <= layout.topMd)?.diameter ?? 0) * 0.5;
    const topMdPoints = WellDrawingHelpers.xyForMdRadius(layout.topMd, topMdRadius, layout, defaultLineWidth * 0.5);

    ctx.beginPath();
    ctx.moveTo(topMdPoints[0].x, topMdPoints[0].y);
    ctx.lineTo(topMdPoints[1].x, topMdPoints[1].y);
    ctx.stroke();

    for (let t = 0; t <= 1; t++) {
      let points = { ...topMdPoints };
      let radius = topMdRadius;

      ctx.beginPath();
      ctx.moveTo(points[t].x, points[t].y);

      hole
        .filter((holePoint) => holePoint.topMd > layout.topMd && holePoint.topMd < layout.bottomMd)
        .forEach((holePoint) => {
          points = WellDrawingHelpers.xyForMdRadius(holePoint.topMd, radius, layout, defaultLineWidth * 0.5);
          ctx.lineTo(points[t].x, points[t].y);
          radius = holePoint.diameter * 0.5;
          points = WellDrawingHelpers.xyForMdRadius(holePoint.topMd, radius, layout, defaultLineWidth * 0.5);
          ctx.lineTo(points[t].x, points[t].y);
        });

      points = WellDrawingHelpers.xyForMdRadius(layout.bottomMd, radius, layout, defaultLineWidth * 0.5);

      ctx.lineTo(points[t].x, points[t].y);
      ctx.stroke();
    }
  }

  // endregion draw hole boundary

  // region draw pipes
  public static drawPipes(
    pipes: Pipe[],
    hole: IDrawingHoleSection[],
    ctx: CanvasRenderingContext2D,
    layout: ILayoutParams,
    isPackerInstalled: boolean,
    partsToDraw: DrawingPart,
  ): void {
    if (!partsToDraw.pipes) {
      return;
    }
    let prevPipeId = 0;
    let prevPipeOd = 0;
    for (let i = 0; i < pipes.length; i++) {
      const pipe = pipes[i];
      switch (pipe.PipeType) {
        case PipeType.Gravel_Pack_Packer:
          this.drawGravelPacker(pipe, ctx, hole, layout, isPackerInstalled);
          prevPipeId = 0;
          prevPipeOd = 0;
          break;

        case PipeType.Screen:
        case PipeType.Shunted_Screen:
        case PipeType.ICD_Screen:
          this.joinPipePartsIfNecessary(
            pipe.TopMD,
            (pipe as ScreenPipe).FilterInnerDiameter,
            pipe.OuterDiameter,
            prevPipeId,
            prevPipeOd,
            ctx,
            layout,
          );
          this.drawScreen(pipe as ScreenPipe, ctx, layout);
          prevPipeId = (pipe as ScreenPipe).FilterInnerDiameter;
          prevPipeOd = pipe.OuterDiameter;
          break;

        case PipeType.Concentric_Gauge_Carrier:
        case PipeType.Eccentric_Gauge_Carrier:
          this.joinPipePartsIfNecessary(pipe.TopMD, 0, pipe.OuterDiameter, prevPipeId, prevPipeOd, ctx, layout);
          this.drawGauge(pipe as GaugeCarrierPipe, ctx, layout);
          prevPipeId = 0;
          prevPipeOd = pipe.OuterDiameter;
          break;

        case PipeType.Gravel_Pack_Extension:
        case PipeType.Workstring:
        case PipeType.Service_Tool:
        case PipeType.Sump_Packer:
        case PipeType.Isolation_Packer:
        case PipeType.Blank_Pipe:
        case PipeType.Shunted_Blank_Pipe:
        case PipeType.Washpipe:
        case PipeType.Pressure_Attenuator:
        case PipeType.Riser:
        case PipeType.Isolation_Valve:
        case PipeType.Bull_Nose:
          this.joinPipePartsIfNecessary(pipe.TopMD, pipe.InnerDiameter, pipe.OuterDiameter, prevPipeId, prevPipeOd, ctx, layout);
          this.drawPipeWall(pipe, ctx, layout);
          prevPipeId = pipe.InnerDiameter;
          prevPipeOd = pipe.OuterDiameter;
          break;

        case PipeType.Casing:
        case PipeType.Open_Hole:
        case PipeType.Perforated_Casing:
          throw new Error('Hole pipe type ' + pipe.PipeType);

        default:
          throw new Error('Unknown pipe type' + pipe.PipeType);
      }
    }

    ctx.globalAlpha = 1;
  }

  private static joinPipePartsIfNecessary(
    md: number,
    innerDiameter: number,
    outerDiameter: number,
    prevInnerDiameter: number,
    prevOuterDiameter: number,
    ctx: CanvasRenderingContext2D,
    layout: ILayoutParams,
  ): void {
    if (innerDiameter && prevOuterDiameter && innerDiameter > prevOuterDiameter) {
      // getting bigger without overlap
      this.drawJoinLine(md, prevInnerDiameter, outerDiameter, ctx, layout);
    } else if (prevInnerDiameter && outerDiameter && outerDiameter < prevInnerDiameter) {
      // getting smaller without overlap
      this.drawJoinLine(md, innerDiameter, prevOuterDiameter, ctx, layout);
    }
  }

  private static drawJoinLine(
    md: number,
    innerDiameter: number,
    outerDiameter: number,
    ctx: CanvasRenderingContext2D,
    layout: ILayoutParams,
  ): void {
    const drawPoints1 = WellDrawingHelpers.xyForMdRadius(md, innerDiameter * 0.5, layout);
    const drawPoints2 = WellDrawingHelpers.xyForMdRadius(md, outerDiameter * 0.5, layout);
    for (let t = 0; t <= 1; t++) {
      ctx.strokeStyle = 'black';
      ctx.beginPath();
      ctx.setLineDash([]);
      ctx.lineWidth = defaultLineWidth;
      ctx.moveTo(drawPoints1[t].x, drawPoints1[t].y);
      ctx.lineTo(drawPoints2[t].x, drawPoints2[t].y);
      ctx.stroke();
    }
  }

  private static drawPipeWall(pipe: Pipe, ctx: CanvasRenderingContext2D, layout: ILayoutParams): void {
    const pipeWallCenterRadius = (pipe.InnerDiameter + pipe.OuterDiameter) * 0.25;
    const w = Math.max((pipe.OuterDiameter - pipe.InnerDiameter) * layout.wScale * 0.5, defaultLineWidth);
    for (let t = 0; t <= 1; t++) {
      ctx.strokeStyle = 'black';
      ctx.beginPath();
      ctx.setLineDash([]);
      ctx.lineWidth = w;
      let drawPoints = WellDrawingHelpers.xyForMdRadius(pipe.TopMD, pipeWallCenterRadius, layout);
      ctx.moveTo(drawPoints[t].x, drawPoints[t].y);

      // add points for extra survey locations
      layout.mappedSurvey
        .filter((surveySection) => surveySection.topMd > pipe.TopMD && surveySection.topMd < pipe.BottomMD)
        .forEach((surveyPoint) => {
          drawPoints = WellDrawingHelpers.xyForMdRadius(surveyPoint.topMd, pipeWallCenterRadius, layout);
          ctx.lineTo(drawPoints[t].x, drawPoints[t].y);
        });

      drawPoints = WellDrawingHelpers.xyForMdRadius(pipe.BottomMD, pipeWallCenterRadius, layout);
      ctx.lineTo(drawPoints[t].x, drawPoints[t].y);
      ctx.stroke();
    }
  }

  private static drawScreen(pipe: ScreenPipe, ctx: CanvasRenderingContext2D, layout: ILayoutParams): void {
    const screenCenterRadius = (pipe.OuterDiameter + pipe.FilterInnerDiameter) * 0.25;
    const w = Math.max((pipe.OuterDiameter - pipe.FilterInnerDiameter) * layout.wScale * 0.5, defaultLineWidth);
    for (let t = 0; t <= 1; t++) {
      ctx.strokeStyle = 'black';
      ctx.beginPath();
      ctx.setLineDash([defaultLineWidth * 3, defaultLineWidth * 3]);
      ctx.lineWidth = w;
      let drawPoints = WellDrawingHelpers.xyForMdRadius(pipe.TopMD, screenCenterRadius, layout);
      ctx.moveTo(drawPoints[t].x, drawPoints[t].y);

      // add points for extra survey locations
      layout.mappedSurvey
        .filter((surveySection) => surveySection.topMd > pipe.TopMD && surveySection.topMd < pipe.BottomMD)
        .forEach((surveySection) => {
          drawPoints = WellDrawingHelpers.xyForMdRadius(surveySection.topMd, screenCenterRadius, layout);
          ctx.lineTo(drawPoints[t].x, drawPoints[t].y);
        });

      drawPoints = WellDrawingHelpers.xyForMdRadius(pipe.BottomMD, screenCenterRadius, layout);
      ctx.lineTo(drawPoints[t].x, drawPoints[t].y);
      ctx.stroke();
    }
  }

  private static drawGauge(pipe: Pipe, ctx: CanvasRenderingContext2D, layout: ILayoutParams): void {
    const drawPoints = WellDrawingHelpers.xyForMdRadius((pipe.TopMD + pipe.BottomMD) * 0.5, pipe.OuterDiameter * 0.5, layout);
    const width = Math.max((pipe.BottomMD - pipe.TopMD) * layout.lScale, defaultLineWidth);

    ctx.beginPath();
    ctx.lineWidth = width;
    ctx.setLineDash([defaultLineWidth * 2, defaultLineWidth * 2]);
    ctx.moveTo(drawPoints[0].x, drawPoints[0].y);
    ctx.lineTo(drawPoints[1].x, drawPoints[1].y);
    ctx.stroke();
  }

  private static drawGravelPacker(
    packer: Pipe,
    ctx: CanvasRenderingContext2D,
    hole: IDrawingHoleSection[],
    layout: ILayoutParams,
    isInstalled: boolean,
  ): void {
    let outerDiameter = packer.OuterDiameter;

    // when packer installed it's sealed to hole, so should be drawn with hole diameter instead of own outer diameter
    if (isInstalled) {
      const holeSection = hole.find((section) => packer.TopMD >= section.topMd && packer.TopMD < section.bottomMd);
      outerDiameter = holeSection?.diameter ?? outerDiameter;
    }

    const drawPoints1 = WellDrawingHelpers.xyForMdRadius(packer.TopMD, outerDiameter * 0.5, layout);
    const drawPoints2 = WellDrawingHelpers.xyForMdRadius(packer.BottomMD, outerDiameter * 0.5, layout);
    ctx.beginPath();
    ctx.moveTo(drawPoints1[0].x, drawPoints1[0].y);
    ctx.lineTo(drawPoints2[0].x, drawPoints2[0].y);
    ctx.lineTo(drawPoints2[1].x, drawPoints2[1].y);
    ctx.lineTo(drawPoints1[1].x, drawPoints1[1].y);
    ctx.lineTo(drawPoints1[0].x, drawPoints1[0].y);
    ctx.fillStyle = 'black';
    ctx.fill();
  }

  // endregion draw pipes

  // region draw tooltip

  /**
   * Removes segments outside drawing zone
   * @param hole
   * @param holeSection
   * @private
   */
  private static getFilteredHoleFittingSection(hole: IDrawingHoleSection[], holeSection: IHoleSection): IDrawingHoleSection[] {
    const filteredHole = hole.filter((holePoint) => holePoint.bottomMd > holeSection.topMd && holePoint.topMd < holeSection.bottomMd);

    // adjust first section top md to match layout topMd, in case it starts outside drawing zone
    const firstSection = filteredHole[0];
    if (firstSection != null && firstSection.topMd < holeSection.topMd) {
      filteredHole[0] = {
        ...firstSection,
        topMd: holeSection.topMd,
      };
    }

    // adjust last section bottomMd to match layout bottomMd, in case it goes outside drawing zone
    const lastSection = filteredHole[filteredHole.length - 1];
    if (lastSection != null && lastSection.bottomMd > holeSection.bottomMd) {
      filteredHole[filteredHole.length - 1] = {
        ...lastSection,
        bottomMd: holeSection.bottomMd,
      };
    }

    return filteredHole;
  }

  public static drawCrosshairWithTooltip(
    mousePosition: Point,
    drawnPipes: Pipe[],
    drawnHoleSections: IDrawingHoleSection[],
    drawing: IDrawing,
    partsToDraw: DrawingPart,
    ctx: CanvasRenderingContext2D,
    layout: ILayoutParams,
    context: WellDrawingContext,
    smoothedSurvey: Survey[],
    drawingArea: Rect,
  ): void {
    const { workstringFluids, upperAnnulusFluids, washpipeFluids, lowerAnnulusFluids } = drawing;

    const pipesAsHoleSections = DrawingSurveyMapHelper.extraHoleSectionsFromSurvey(
      WellDrawingHelpers.innerPipesToHoleSizes(drawnPipes),
      smoothedSurvey,
    );
    const fluidsInPipes = [
      ...(partsToDraw.workstring ? workstringFluids.sections : []),
      ...(partsToDraw.washpipe ? washpipeFluids.sections : []),
    ];

    const fluidsInAnnulus = [
      ...(partsToDraw.upperAnnulus ? upperAnnulusFluids.sections : []),
      ...(partsToDraw.lowerAnnulus ? lowerAnnulusFluids.sections : []),
    ];

    // try to draw in pipes
    const inPipe = this.drawCrosshairWithTooltipInSection(
      mousePosition,
      pipesAsHoleSections,
      fluidsInPipes,
      drawing,
      ctx,
      layout,
      context,
      drawingArea,
    );
    if (!inPipe) {
      // attempt to draw in annulus
      this.drawCrosshairWithTooltipInSection(mousePosition, drawnHoleSections, fluidsInAnnulus, drawing, ctx, layout, context, drawingArea);
    }
  }

  private static drawCrosshairWithTooltipInSection(
    mousePosition: Point,
    holeSections: IDrawingHoleSection[],
    fluidSections: IAzimuthBasedSection[],
    drawing: IDrawing,
    ctx: CanvasRenderingContext2D,
    layout: ILayoutParams,
    context: WellDrawingContext,
    drawingArea: Rect,
  ): boolean {
    const { packingData, fluids } = drawing;

    for (let i = 0; i < holeSections.length; i++) {
      const section = holeSections[i];
      const startPoints = WellDrawingHelpers.xyForMdRadius(section.topMd, section.diameter * 0.5, layout, defaultLineWidth * 0.5);
      const endPoints = WellDrawingHelpers.xyForMdRadius(section.bottomMd, section.diameter * 0.5, layout, defaultLineWidth * 0.5);

      // detect mouse pointer inside current segment, segment divided to two triangles
      if (
        isPointInsideTriangle(mousePosition, startPoints[0], startPoints[1], endPoints[0]) ||
        isPointInsideTriangle(mousePosition, startPoints[1], endPoints[0], endPoints[1])
      ) {
        // find md
        const distanceToStart = pointToSegmentDistance(mousePosition, startPoints[0], startPoints[1]);
        const distanceToEnd = pointToSegmentDistance(mousePosition, endPoints[0], endPoints[1]);
        const ratio = distanceToStart / (distanceToStart + distanceToEnd); // 0.0 - 1.0
        const mdSi = section.topMd + (section.bottomMd - section.topMd) * ratio;

        // find drawing points at md (points at hole boundaries)
        const mdDrawingPoints = WellDrawingHelpers.xyForMdRadius(mdSi, section.diameter * 0.5, layout, defaultLineWidth * 0.5);

        // find azimuth
        const distanceToPoint1 = pointsDistance(mousePosition, mdDrawingPoints[0]);
        const distanceToPoint2 = pointsDistance(mousePosition, mdDrawingPoints[1]);
        const azimuth = distanceToPoint1 / (distanceToPoint1 + distanceToPoint2); // 0.0 - 1.0

        const packingResult = this.findPackingResult(mdSi, packingData);
        const fluidConcentrationInfos = this.getFluidsAtMd(mdSi, azimuth, fluidSections, fluids);

        const tooltipRows = this.getTooltipRows(mdSi, packingResult, fluidConcentrationInfos, context);

        drawCrosshair(ctx, mdDrawingPoints[0], mdDrawingPoints[1]);
        drawPackingTooltip(ctx, mousePosition, tooltipRows, context.defaultGlobalOptions, drawingArea);

        return true;
      }
    }

    return false;
  }

  private static getFluidsAtMd(md: number, azimuth: number, fluidSections: IAzimuthBasedSection[], fluids: Fluid[]): FluidConcentrationInfo[] {
    const result: FluidConcentrationInfo[] = [];
    for (const section of fluidSections) {
      if (md >= section.topMd && md <= section.bottomMd) {
        for (const dataItems of section.azimuthBasedData) {
          const fluid = fluids.find((f) => f.Id === dataItems.fluidId);
          const azimuthData = dataItems.data;
          if (fluid != null && azimuthData.length > 0) {
            const azimuthPerCell = 1.0 / azimuthData.length;
            const azimuthIndex = Math.floor(azimuth / azimuthPerCell);

            const cellConcentration = azimuthData[azimuthIndex];
            const avgConcentration = azimuthData.reduce((acc, value) => acc + value, 0) / azimuthData.length;

            const roundedCellConcentration = Math.round(cellConcentration * 100);
            const roundedAvgConcentration = Math.round(avgConcentration * 100);

            if (roundedCellConcentration > 0 || roundedAvgConcentration > 0) {
              result.push({
                fluidName: fluid.Name,
                cellConcentration,
                avgConcentration,
              });
            }
          }
        }
        break;
      }
    }

    // sort by avg concentration - higher first
    result.sort((a, b) => b.avgConcentration - a.avgConcentration);

    return result;
  }

  private static getTooltipRows(
    mdSi: number,
    packingResult: IPackingResult | undefined,
    fluids: FluidConcentrationInfo[],
    context: WellDrawingContext,
  ): TooltipRow[] {
    const md = LengthConverter.fromSi(mdSi, context.currentUnitSystem.Long_Length);
    const longLengthSymbol = LengthConverter.getSymbol(context.currentUnitSystem.Long_Length);
    const shortLengthSymbol = LengthConverter.getSymbol(context.currentUnitSystem.Short_Length);

    const result: TooltipRow[] = [];

    result.push({ name: 'MD', value: md.toFixed(2), unit: longLengthSymbol });

    const showPacking = packingResult != null && packingResult.packingHeight > 0;
    if (showPacking) {
      const packingHeight = LengthConverter.fromSi(packingResult.packingHeight, context.currentUnitSystem.Short_Length);

      result.push({ name: 'Pack height', value: packingHeight.toFixed(3), unit: shortLengthSymbol });
      result.push({ name: 'Pack ratio', value: packingResult.packingRatio.toFixed(2), unit: '%' });
    }

    for (const { fluidName, avgConcentration, cellConcentration } of fluids) {
      result.push({
        name: fluidName,
        value: `${avgConcentration.toFixed(2)} % (${cellConcentration.toFixed(2)} %)`,
        unit: '',
      });
    }

    return result;
  }

  // endregion draw tooltip

  public static calculateLayoutParams(
    width: number,
    height: number,
    legendHeight: number,
    smoothedSurvey: Survey[],
    drawingSection: WellPartType,
    layoutType: DrawingLayoutType,
    drawing: IDrawing,
    zoomFactor: number,
    offset: IDrawPoint,
    wScaleFactor: number,
  ): ILayoutParams {
    const { hole, lowerCompletion } = drawing;

    const packerTop = lowerCompletion[0].TopMD;
    const topMd = drawingSection === WellPartType.Below_Packer ? packerTop : 0;
    const bottomMd = drawingSection === WellPartType.Above_Packer ? packerTop : hole[hole.length - 1].bottomMd;

    const mappedSurvey: IMappedSurveySection[] = DrawingSurveyMapHelper.getSurveyMap(layoutType, smoothedSurvey);
    const holeToDraw = hole.filter((holeElement) => !(holeElement.topMd > bottomMd || holeElement.bottomMd < topMd));

    let maxHoleDiameter = 0;
    holeToDraw.forEach((holeSize) => {
      if (holeSize.diameter > maxHoleDiameter) {
        maxHoleDiameter = holeSize.diameter;
      }
    });

    let drawingHoleDiameter;
    let lScale;
    let wScale;

    let leftMargin;
    let rightMargin;
    let bottomMargin;
    let topMargin;

    const topMappedPoint = WellDrawingHelpers.mappedPointForMd(topMd, mappedSurvey);
    const bottomMappedPoint = WellDrawingHelpers.mappedPointForMd(bottomMd, mappedSurvey);

    // workout range of vertical depths to draw
    const minHd = topMappedPoint.hd;
    const maxHd = bottomMappedPoint.hd;
    let minVd = Math.min(topMappedPoint.vd, bottomMappedPoint.vd);
    let maxVd = Math.max(topMappedPoint.vd, bottomMappedPoint.vd);
    let lowestMd = bottomMd;
    mappedSurvey.forEach((surveySection) => {
      if (surveySection.topMd > topMd && surveySection.topMd < bottomMd && surveySection.topVd < minVd) {
        minVd = surveySection.topVd;
      }
      if (surveySection.bottomMd > topMd && surveySection.bottomMd < bottomMd && surveySection.bottomVd > maxVd) {
        lowestMd = surveySection.bottomMd;
        maxVd = surveySection.bottomVd;
      }
    });

    // workout scaling
    switch (layoutType) {
      case DrawingLayoutType.Horizontal:
        {
          leftMargin = 70;
          rightMargin = 20;
          bottomMargin = 45;
          const totalFixedHeights = bottomMargin + legendHeight + legendGapBelow + defaultLineWidth * 2;

          drawingHoleDiameter = Math.min(height - totalFixedHeights, maxDrawingWidth);
          const remainingHeight = height - (drawingHoleDiameter + totalFixedHeights);

          topMargin = legendHeight + legendGapBelow + drawingHoleDiameter / 2 + remainingHeight / 2;
          lScale = (width - leftMargin - rightMargin) / (bottomMd - topMd);
          wScale = drawingHoleDiameter / maxHoleDiameter;
        }

        break;

      case DrawingLayoutType.Vertical:
        rightMargin = 60;
        drawingHoleDiameter = Math.min(width - 60 - defaultLineWidth * 2, maxDrawingWidth);
        leftMargin = width > drawingHoleDiameter + 120 ? width * 0.5 : width - drawingHoleDiameter * 0.5;
        bottomMargin = defaultFontHeight;
        topMargin = legendHeight + legendGapBelow + 45;

        lScale = (height - topMargin - bottomMargin) / (maxVd - minVd);
        wScale = drawingHoleDiameter / maxHoleDiameter - rightMargin;
        break;

      default: // 60 - padding
        // 100 - padding
        drawingHoleDiameter = surveyDrawingWidth;
        leftMargin = surveyDrawingWidth * 0.5 + 60;
        rightMargin = surveyDrawingWidth * 0.5;
        bottomMargin = surveyDrawingWidth * 0.5 + 60;
        topMargin = legendHeight + legendGapBelow + 100;

        mappedSurvey
          .filter((point) => point.topMd > topMd && point.bottomMd < bottomMd)
          .forEach((point) => {
            const mappedPoint = WellDrawingHelpers.mappedPointForMd(point.topMd, mappedSurvey);
            if (mappedPoint.vd < minVd) {
              minVd = mappedPoint.vd;
            }
            if (mappedPoint.vd < minVd) {
              minVd = mappedPoint.vd;
            }
            if (mappedPoint.vd > maxVd) {
              maxVd = mappedPoint.vd;
            }
            if (mappedPoint.vd > maxVd) {
              maxVd = mappedPoint.vd;
            }
          });

        if ((maxHd - minHd) / (width - leftMargin - rightMargin) > (maxVd - minVd) / (height - topMargin - bottomMargin)) {
          // limit width
          lScale = (width - leftMargin - rightMargin) / (maxHd - minHd);
          wScale = drawingHoleDiameter / maxHoleDiameter;

          // center vertically
          const remainingHeight = height - (topMargin + bottomMargin + lScale * (maxVd - minVd));
          topMargin += Math.max(remainingHeight / 2, 0);
        } else {
          // limit height
          lScale = (height - topMargin - bottomMargin) / (maxVd - minVd);
          wScale = drawingHoleDiameter / maxHoleDiameter;

          // center horizontally
          const remainingWidth = width - (leftMargin + rightMargin + lScale * (maxHd - minHd));
          leftMargin += Math.max(remainingWidth / 2, 0);
        }
    }

    return {
      lScale: lScale * zoomFactor,
      wScale: wScale * zoomFactor * wScaleFactor,
      topMargin: topMargin + offset.y,
      leftMargin: leftMargin + offset.x,
      topMd,
      topHd: topMappedPoint.hd,
      topVd: topMappedPoint.vd,
      bottomMd,
      holeWidth: maxHoleDiameter,
      lowestMd,
      maxVd,
      mappedSurvey,
      smoothedSurvey,
    };
  }

  public static drawWells(commonParams: WellDrawingCommonParams, wellsParams: WellDrawingParams[]): WellDrawingResults[] | undefined {
    const results: WellDrawingResults[] = [];
    const { canvasSize, context, ctx, drawing, smoothedSurvey, legendItems } = commonParams;
    const drawingArea: Rect = { x: 0, y: 0, ...canvasSize };

    // draw wells
    for (const wellParams of wellsParams) {
      const result = this.drawWellbore(wellParams);
      if (result == null) {
        return undefined;
      }

      results.push(result);
    }

    // draw legend
    WellDrawingHelpers.drawLegend(legendItems, ctx, canvasSize.width, context);

    // draw Tooltip
    for (const { layout, params, drawnPipes, drawnHoleSections } of results) {
      const { partsToDraw } = params;

      if (context.isTooltipVisible && context.lastMousePosition) {
        WellDrawingHelpers.drawCrosshairWithTooltip(
          context.lastMousePosition,
          drawnPipes,
          drawnHoleSections,
          drawing,
          partsToDraw,
          ctx,
          layout,
          context,
          smoothedSurvey,
          drawingArea,
        );
      }
    }

    return results;
  }

  public static drawWellbore(params: WellDrawingParams): WellDrawingResults | undefined {
    const {
      partsToDraw,
      ctx,
      wellSize,
      drawing,
      drawingSection,
      layoutType,
      context,
      offset,
      zoomFactor,
      legendHeight,
      wScaleFactor,
      smoothedSurvey,
      smoothedHole,
      pipesAsDrawingHoleSections,
    } = params;
    const { width, height } = wellSize;
    const {
      hole,
      lowerCompletion,
      runningString,
      packingData,
      workstringFluids,
      upperAnnulusFluids,
      washpipeFluids,
      lowerAnnulusFluids,
      fluids,
      isGravelPackerInstalled,
      upperAnnulusStreamFunctions,
      lowerAnnulusStreamFunctions,
      fluidFronts,
    } = drawing;

    const layout = this.calculateLayoutParams(
      width,
      height,
      legendHeight,
      smoothedSurvey,
      drawingSection,
      layoutType,
      drawing,
      zoomFactor,
      offset,
      wScaleFactor,
    );

    if (!isFinite(layout.lScale) || !isFinite(layout.wScale)) {
      return undefined;
    }

    const radiusScaleTicks = WellDrawingHelpers.drawRadiusScale(ctx, layout, context, partsToDraw);
    const drawingArea: Rect = { x: 0, y: 0, ...params.canvasSize };
    const depthScaleTicks = WellDrawingHelpers.drawDepthScale(ctx, layout, context, drawingArea, partsToDraw);

    const packerTop = lowerCompletion[0].TopMD;
    const packerBottom = lowerCompletion[0].BottomMD;
    const { topMd, bottomMd } = layout;

    const annulusAsDrawingHoleSections = smoothedHole;
    const lowerCompletionAsDrawingHoleSections = DrawingSurveyMapHelper.extraHoleSectionsFromSurvey(
      WellDrawingHelpers.innerPipesToHoleSizes(lowerCompletion),
      smoothedSurvey,
    );

    const drawnPipes: Pipe[] = [];
    const drawnHoleSections: IDrawingHoleSection[] = [];

    if (drawingSection === WellPartType.Full_Well || drawingSection === WellPartType.Above_Packer) {
      if (partsToDraw.upperAnnulus) {
        WellDrawingHelpers.drawFluidsInHole(fluids, upperAnnulusFluids, annulusAsDrawingHoleSections, ctx, layout, 1, context);

        if (upperAnnulusStreamFunctions != null) {
          WellDrawingHelpers.drawContourLines(upperAnnulusStreamFunctions, annulusAsDrawingHoleSections, ctx, layout);
        }

        WellDrawingHelpers.drawFluidFrontLines(
          fluidFronts,
          fluids,
          FlowPathDescription.Upper_Annulus,
          annulusAsDrawingHoleSections,
          ctx,
          layout,
        );
      }

      if (partsToDraw.workstring) {
        WellDrawingHelpers.drawFluidsInHole(fluids, workstringFluids, pipesAsDrawingHoleSections, ctx, layout, 1, context);
        WellDrawingHelpers.drawFluidFrontLines(fluidFronts, fluids, FlowPathDescription.Workstring, pipesAsDrawingHoleSections, ctx, layout);
      }
    }

    const packingDataAbovePacker = WellDrawingHelpers.trimPackingData(packingData.filter((data) => data.topMd <= packerTop));
    if (
      packingDataAbovePacker.some((packing) => packing.packingHeight > 0) &&
      (drawingSection === WellPartType.Full_Well || drawingSection === WellPartType.Above_Packer)
    ) {
      WellDrawingHelpers.drawPackingData(pipesAsDrawingHoleSections, packingDataAbovePacker, ctx, layout, context, partsToDraw);
      // draw fluid on top of packing
      WellDrawingHelpers.drawFluidsInHole(fluids, workstringFluids, pipesAsDrawingHoleSections, ctx, layout, 0.2, context);
    }

    if (drawingSection === WellPartType.Full_Well || drawingSection === WellPartType.Below_Packer) {
      if (partsToDraw.lowerAnnulus) {
        WellDrawingHelpers.drawFluidsInHole(
          fluids,
          lowerAnnulusFluids,
          annulusAsDrawingHoleSections,
          ctx,
          layout,
          1,
          context,
          lowerCompletionAsDrawingHoleSections,
        );

        if (lowerAnnulusStreamFunctions != null) {
          WellDrawingHelpers.drawContourLines(lowerAnnulusStreamFunctions, annulusAsDrawingHoleSections, ctx, layout);
        }

        WellDrawingHelpers.drawFluidFrontLines(
          fluidFronts,
          fluids,
          FlowPathDescription.Lower_Annulus,
          annulusAsDrawingHoleSections,
          ctx,
          layout,
        );
      }

      if (partsToDraw.washpipe) {
        WellDrawingHelpers.drawFluidsInHole(fluids, washpipeFluids, pipesAsDrawingHoleSections, ctx, layout, 1, context);
        WellDrawingHelpers.drawFluidFrontLines(fluidFronts, fluids, FlowPathDescription.Washpipe, pipesAsDrawingHoleSections, ctx, layout);
      }
    }

    const packingDataBelowPacker = WellDrawingHelpers.trimPackingData(packingData.filter((data) => data.topMd >= packerTop));
    if (
      packingDataBelowPacker.some((packing) => packing.packingHeight > 0) &&
      (drawingSection === WellPartType.Full_Well || drawingSection === WellPartType.Below_Packer)
    ) {
      WellDrawingHelpers.drawPackingData(annulusAsDrawingHoleSections, packingDataBelowPacker, ctx, layout, context, partsToDraw);
      // draw fluid on top of packing
      WellDrawingHelpers.drawFluidsInHole(fluids, washpipeFluids, pipesAsDrawingHoleSections, ctx, layout, 0.2, context);
    }

    // draw hole boundary and pipes last to cover joins in fluid areas
    {
      const holeSection: IHoleSection = {
        topMd: !partsToDraw.upperAnnulus ? packerTop : topMd,
        bottomMd: !partsToDraw.lowerAnnulus ? packerBottom : bottomMd,
      };

      const filteredHole = this.getFilteredHoleFittingSection(annulusAsDrawingHoleSections, holeSection);

      WellDrawingHelpers.drawHoleBoundary(filteredHole, ctx, { ...layout, ...holeSection }, partsToDraw);

      drawnHoleSections.push(...filteredHole);
    }

    if (drawingSection !== WellPartType.Above_Packer) {
      if (partsToDraw.lowerAnnulus) {
        // draw full lower completion
        WellDrawingHelpers.drawPipes(lowerCompletion, hole, ctx, layout, isGravelPackerInstalled, partsToDraw);
      } else {
        // draw only packer
        WellDrawingHelpers.drawPipes(
          lowerCompletion.filter((p) => p.PipeType === PipeType.Gravel_Pack_Packer),
          hole,
          ctx,
          layout,
          isGravelPackerInstalled,
          partsToDraw,
        );
      }
    }

    if (partsToDraw.workstring && drawingSection !== WellPartType.Below_Packer) {
      const workstringTopMd = 0;
      const workstringBottomMd = packerTop;

      const pipesToDraw = runningString.filter(
        (pipe) =>
          (pipe.TopMD >= workstringTopMd && pipe.TopMD < workstringBottomMd) ||
          (pipe.BottomMD > workstringTopMd && pipe.BottomMD <= workstringBottomMd) ||
          (pipe.TopMD <= workstringTopMd && pipe.BottomMD >= workstringBottomMd),
      );

      WellDrawingHelpers.drawPipes(pipesToDraw, hole, ctx, layout, isGravelPackerInstalled, partsToDraw);
      drawnPipes.push(...pipesToDraw);
    }

    if (partsToDraw.washpipe && drawingSection !== WellPartType.Above_Packer) {
      const washpipeTopMd = packerTop;
      const washpipeBottomMd = bottomMd;

      const pipesToDraw = runningString.filter(
        (pipe) =>
          (pipe.TopMD >= washpipeTopMd && pipe.TopMD < washpipeBottomMd) ||
          (pipe.BottomMD > washpipeTopMd && pipe.BottomMD <= washpipeBottomMd) ||
          (pipe.TopMD <= washpipeTopMd && pipe.BottomMD >= washpipeBottomMd),
      );

      WellDrawingHelpers.drawPipes(pipesToDraw, hole, ctx, layout, isGravelPackerInstalled, partsToDraw);
      drawnPipes.push(...pipesToDraw);
    }

    // draw axis labels and legend in the end, to prevent it being overlapped by well
    WellDrawingHelpers.drawAxisLabels(ctx, layout, radiusScaleTicks, depthScaleTicks, context, partsToDraw);

    return { layout, params, drawnHoleSections, drawnPipes };
  }

  private static getFluidIdsToLabel({ workstringFluids, upperAnnulusFluids, washpipeFluids, lowerAnnulusFluids }: IDrawing): number[] {
    const sortedFluidsToLabel = [
      ...workstringFluids.sections,
      ...upperAnnulusFluids.sections,
      ...washpipeFluids.sections,
      ...lowerAnnulusFluids.sections,
    ].sort((fluidResult1, fluidResult2) => fluidResult2.topMd - fluidResult1.topMd);

    const fluidIds: number[] = [];

    for (const section of sortedFluidsToLabel) {
      for (const concentration of section.azimuthBasedData) {
        if (concentration.fluidId != null && !fluidIds.includes(concentration.fluidId)) {
          fluidIds.push(concentration.fluidId);
        }
      }
    }

    return fluidIds;
  }
}
