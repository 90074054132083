import { BasePptSectionGenerator, PptContext } from '../base-ppt-section-generator';
import {
  baseTextProps,
  contentPosition,
  reportTitlePosition,
  slideSubTitleFontSize,
  slideTitleFontSize
} from '../style-constants';
import { accentColor, accentSubColor } from '../../../report-consts';
import { PptHtmlParser } from '../ppt-html-parser';

export class RangeNamePptGenerator extends BasePptSectionGenerator {
  constructor(context: PptContext) {
    super(context);
  }

  public async generate(rangeName?: string, rangeNotes?: string | null): Promise<void> {
    const pptx = this.context.pptx;

    if (rangeNotes) {
      const slide = BasePptSectionGenerator.addSlide(pptx, { title: 'Range:', subTitle: rangeName });
      const rangeNotesText = new PptHtmlParser(rangeNotes).render();

      slide.addText(
        rangeNotesText,
        {
          ...contentPosition,
          ...baseTextProps,
          fontSize: 10,
          align: 'justify',
          valign: 'top'
        }
      );

    } else {

      const slide = BasePptSectionGenerator.addSlide(pptx, {});

      slide.addText([{ text: 'Range: ' }], {
        ...reportTitlePosition,
        ...baseTextProps,
        y: reportTitlePosition.y - 0.18,
        margin: [0, 0, 0, 150],
        fontSize: slideTitleFontSize + 4,
        color: accentColor,
        align: 'center',
        valign: 'top',
        bold: true
      });

      if (rangeName) {
        slide.addText([{ text: rangeName ?? '' }], {
          ...reportTitlePosition,
          ...baseTextProps,
          y: reportTitlePosition.y + 0.18,
          fontSize: slideSubTitleFontSize + 4,
          margin: [0, 0, 0, 160],
          color: accentSubColor,
          align: 'center',
          valign: 'top'
        });
      }
    }
  }
}
