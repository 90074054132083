import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { getHelpUrlSuccessAction, HelpIds, PageType } from '../../../+store/help/help.actions';
import { Store } from '@ngrx/store';

@Component({
  template: '',
})
export abstract class PanelButtonComponent implements OnDestroy {
  public subscription = new Subscription();

  protected constructor(protected store: Store) {}

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public changeHelp(page: PageType, uri: HelpIds): void {
    this.store.dispatch(getHelpUrlSuccessAction({ helpUrl: `assets/help-files/${page}/${uri}.html` }));
  }
}
