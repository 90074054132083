import { ImportFileDto, ImportFileWithMinMaxArgumentsDto } from './dto/import-file.dto';
import { ImportColumnDto } from './dto/import-column.dto';
import { CrudResponse } from '../common.actions';
import { DeleteRowsWsAction, UpdateRowsWsAction, WsAction } from '../../ws.action';
import { ImportDataDto } from './dto/import-data.dto';
import { DeleteResultsFilter } from '../scenario/scenario.dto';
import { WsActionPropsFactory } from '../../common/ws-action/ws-action-props.factory';
import { IUpdateRowsProps } from '../../common/common-store-crud.interfaces';
import { ImportOrEquationColumn } from './data-storage.validation';

export const DataStorageModuleName = 'DataStorageModule';

export enum DataStorageModuleActionTypes {
  DataStorageFileCreate = '[DataStorageModule] [File] Create',
  DataStorageFilesLoad = '[DataStorageModule] [Files] Load',
  DataStorageFileDelete = '[DataStorageModule] [File} Delete',

  DataStorageDataSave = '[DataStorageModule] [Data] Save',
  DataStorageDataLoad = '[DataStorageModule] [Data] Load',
  DataStorageReduceData = '[DataStorageModule] [Data] Reduce Update',

  DataStorageImportColumnValidateNames = '[DataStorageModule] [ImportColumn] ValidateColumnNames',
  DataStorageImportColumnUpdateRows = '[DataStorageModule] [ImportColumn] UpdateRows',
  DataStorageImportColumnDeleteRows = '[DataStorageModule] [ImportColumn] DeleteRows',
  DataStorageImportColumnResetData = '[DataStorageModule] [ImportColumn] ResetData',

  DeleteAllResults = '[DataStorageModule] DeleteAllResults',
  DeleteResults = '[DataStorageModule] DeleteResults',
  DeleteResultsSuccess = '[DataStorageModule] DeleteResultsSuccess',

  VacuumDb = '[DataStorageModule] VacuumDb',
  CheckIfVacuumNeeded = '[DataStorageModule] CheckIfVacuumNeeded',
}

export interface LoadDataStorageActionResponse {
  importFileDtos: ImportFileWithMinMaxArgumentsDto[];
  importColumnDtos: ImportColumnDto[];
  scenarioId: number;
}

// File

export class DataStorageFileCreateAction extends WsAction {
  public override readonly type = DataStorageModuleActionTypes.DataStorageFileCreate;

  constructor(
    public file: ImportFileDto,
    public columns: ImportColumnDto[],
  ) {
    super();
  }
}

export interface DataStorageFileCreateActionResponse {
  crudResponse: CrudResponse;
  newFileId: number;
}

export class DataStorageFilesLoadAction extends WsAction {
  public override readonly type = DataStorageModuleActionTypes.DataStorageFilesLoad;
}

export class DataStorageFileDeleteAction extends DeleteRowsWsAction {
  public override readonly type = DataStorageModuleActionTypes.DataStorageFileDelete;
}

// endregion

// Data

export class DataStorageDataSaveAction extends WsAction {
  public override readonly type = DataStorageModuleActionTypes.DataStorageDataSave;

  constructor(
    public fileId: number,
    public argumentRows: number[],
    public combinedDataRows: string[],
    public sourceArgumentRows: string[],
    public startRowIndex: number,
  ) {
    super();
  }
}

export class DataStorageDataLoadAction extends WsAction {
  public override readonly type = DataStorageModuleActionTypes.DataStorageDataLoad;

  constructor(
    public fileId: number,
    public rangeId: number,
  ) {
    super();
  }
}

export class DataStorageDataReduceDataAction extends WsAction {
  public override readonly type = DataStorageModuleActionTypes.DataStorageReduceData;

  constructor(
    public fileId: number,
    public samplingFrequency: number,
  ) {
    super();
  }
}

export interface DataStorageDataLoadActionResponse {
  fileId: number;
  data: ImportDataDto[];
}

// endregion

// region ImportColumn

export class DataStorageImportColumnValidateNamesAction extends WsAction {
  public override readonly type = DataStorageModuleActionTypes.DataStorageImportColumnValidateNames;

  constructor(public columnNames: string[]) {
    super();
  }
}

export class DataStorageImportColumnUpdateRowsAction extends UpdateRowsWsAction<ImportColumnDto> {
  public override readonly type = DataStorageModuleActionTypes.DataStorageImportColumnUpdateRows;

  constructor(action: IUpdateRowsProps<ImportOrEquationColumn>) {
    super(WsActionPropsFactory.updateAction(action, (row) => row as ImportColumnDto));
  }
}

export class DataStorageImportColumnDeleteRowsAction extends DeleteRowsWsAction {
  public override readonly type = DataStorageModuleActionTypes.DataStorageImportColumnDeleteRows;
}

export class DataStorageImportColumnResetDataAction extends WsAction {
  public override readonly type = DataStorageModuleActionTypes.DataStorageImportColumnResetData;

  constructor(public fileId: number) {
    super();
  }
}

export class DeleteResultsAction extends WsAction {
  public override readonly type = DataStorageModuleActionTypes.DeleteResults;

  constructor(public deleteResultsFilter: DeleteResultsFilter) {
    super();
  }
}

export class DeleteAllResultsAction extends WsAction {
  public override readonly type = DataStorageModuleActionTypes.DeleteAllResults;
}

export class DeleteResultsSuccess extends WsAction {
  public override readonly type = DataStorageModuleActionTypes.DeleteResultsSuccess;

  constructor(
    public fileHash: string,
    public deleteResultsFilter: DeleteResultsFilter,
  ) {
    super();
  }
}

export class VacuumDbAction extends WsAction {
  public override readonly type = DataStorageModuleActionTypes.VacuumDb;

  constructor(public forceVacuum: boolean) {
    super();
  }
}

export class CheckIfVacuumNeeded extends WsAction {
  public override readonly type = DataStorageModuleActionTypes.CheckIfVacuumNeeded;
}

export interface CheckIfVacuumNeededResponse {
  isVacuumNeeded: boolean;
}

// endregion

export type DataStorageModuleActions =
  | DataStorageFileCreateAction
  | DataStorageFilesLoadAction
  | DataStorageFileDeleteAction
  | DataStorageDataSaveAction
  | DataStorageDataLoadAction
  | DataStorageDataReduceDataAction
  | DataStorageImportColumnValidateNamesAction
  | DataStorageImportColumnUpdateRowsAction
  | DataStorageImportColumnDeleteRowsAction
  | DataStorageImportColumnResetDataAction
  | DeleteAllResultsAction
  | DeleteResultsAction
  | DeleteResultsSuccess
  | VacuumDbAction
  | CheckIfVacuumNeeded;
