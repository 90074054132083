import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { DbDependentComponent } from '../../../../common-modules/db-connection/db-dependent.component';
import { Store } from '@ngrx/store';
import { ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import * as uiActions from '../../../../+store/ui/ui.actions';
import { ChartDataSourceType } from '@dunefront/common/modules/reporting/dto/chart.dto';
import { getAreCurrentResultsPresent } from '../../../../+store/calculation-engine/calculation-engine-results.selectors';
import { Scenario } from '@dunefront/common/modules/scenario/scenario';

@Component({
  selector: 'app-evaluate-animation',
  templateUrl: './evaluate-animation.component.html',
  styleUrls: ['./evaluate-animation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EvaluateAnimationComponent extends DbDependentComponent {
  public contentVisible = true;
  public ChartDataSourceType = ChartDataSourceType;
  public ModuleType = ModuleType;
  public currentResultsPresent$ = this.store.select(getAreCurrentResultsPresent);

  constructor(store: Store, cdRef: ChangeDetectorRef) {
    super(store, cdRef);
    store.dispatch(uiActions.setResultsTabAction({ tab: 'evaluation-animation' }));
    this.onHelpChange('results', 'animation-evaluate');
  }

  public override onCurrentScenarioChanged(currentScenario: Scenario): void {
    this.contentVisible = false;
    this.cdRef.markForCheck();
    setTimeout(() => {
      this.contentVisible = true;
      this.cdRef.markForCheck();
    });
  }
}

export enum LayoutType {
  Auto = 0,
  Horizontal = 1,
  Vertical = 2,
}
