import { BasePptSectionGenerator, PptContext } from '../base-ppt-section-generator';
import { IUnitSystemDto } from '@dunefront/common/dto/unit-system.dto';
import { firstValueFrom } from 'rxjs';
import { selectCurrentUnitSystem } from '../../../../../../+store/units/units.selectors';
import { CommonReportDataHelpers } from '../../common/common-report-data-helpers';
import { PptDocumentTableGeneratorHelper } from '../ppt-document-table-generator.helper';
import { ReportScenarioImages } from '../../doc/sections/chart-report-generator.helper';
import { getValidatedTrendAnalysisRange } from '../../../../../../+store/trend-analysis/trend-analysis-page.selectors';

export class TrendAnalysisDataPptGenerator extends BasePptSectionGenerator {
  constructor(protected override context: PptContext & { images: ReportScenarioImages }) {
    super(context);
  }

  public async generate(rangeId?: number, rangeName?: string): Promise<void> {
    const { store, images } = this.context;
    const rangeImages = rangeId != null && images.rangeImages ? images.rangeImages[rangeId] : null;
    const currentUnitSystem: IUnitSystemDto = await firstValueFrom(this.store.select(selectCurrentUnitSystem));
    const trendAnalysisRange = await firstValueFrom(store.select(getValidatedTrendAnalysisRange));

    const tableData = CommonReportDataHelpers.createTrendAnalysisInputsTableData(trendAnalysisRange, currentUnitSystem);
    const tableRows = PptDocumentTableGeneratorHelper.createGenericKeyValueTableRowsWithTableData(tableData, currentUnitSystem);
    const mainTitle = 'Trend Analysis Inputs';
    const subTitle = rangeName;

    this.addKeyValueTableSlide({ title: mainTitle, subTitle, tableRows });

    const image = rangeImages?.trendAnalysisInputs;
    if (image) {
      const title = `${mainTitle} - Chart`;
      this.addImageSlide({ title, subTitle, image });
    }
  }
}
