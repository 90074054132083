import { createSelector, Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { getCompletionRawModuleState } from '../../+store/completion/completion.selectors';
import { getFirstDataRow, getLastDataRow, ITableRow } from '@dunefront/common/common/common-grid.interfaces';
import { IUpdateTableRowsProps } from '@dunefront/common/common/common-store-crud.interfaces';
import { GeneralCalculations } from '@dunefront/common/common/general.calculations';
import { getWellRawModuleState } from '../../+store/well/well.selectors';
import { isDefined } from '@dunefront/common/common/state.helpers';
import { Survey } from '@dunefront/common/modules/well/model/survey/survey';
import { ZoneModel } from '@dunefront/common/modules/well/model/zone/zone.model';
import { Pipe } from '@dunefront/common/modules/pipes/pipe';
import { Caliper } from '@dunefront/common/modules/well/model/caliper/caliper';
import * as completionActions from '../../+store/completion/completion.actions';
import * as wellActions from '../../+store/well/well.actions';
import { isScreenRow } from '@dunefront/common/modules/pipes/lower-completion-pipes/pipes/screen-pipe';

// First row selectors

export const getFirstZonesDataRow = createSelector(getWellRawModuleState, (state) => getFirstDataRow(state.ZoneData.rows));

export const getFirstCasingReservoirSectionDataRow = createSelector(getWellRawModuleState, (state) => {
  const reservoirSectionRows = state.CasingData.rows.filter((cd) => cd.rowData.IsReservoirSection);
  return reservoirSectionRows.length ? reservoirSectionRows[0] : undefined;
});

export const getMdsForFirstRowToleranceCheck = createSelector(
  getFirstZonesDataRow,
  getFirstCasingReservoirSectionDataRow,
  (...[firstZonesDataRow, firstCasingReservoirSectionDataRow]): MDsForFirstRowToleranceCheck => ({
    firstZoneDataRow: firstZonesDataRow,
    firstCasingReservoirSectionDataRow: firstCasingReservoirSectionDataRow,
  }),
);

// Last row selectors

export const getLastSurveyDataRow = createSelector(getWellRawModuleState, (state) => getLastDataRow(state.SurveyData.rows));
export const getLastZoneDataRow = createSelector(getWellRawModuleState, (state) => getLastDataRow(state.ZoneData.rows));
export const getLastCasingDataRow = createSelector(getWellRawModuleState, (state) => getLastDataRow(state.CasingData.rows));
export const getLastCaliperDataRow = createSelector(getWellRawModuleState, (state) => getLastDataRow(state.CaliperData.rows));

export const getLastCasingReservoirSectionDataRow = createSelector(getWellRawModuleState, (state) => {
  const reservoirSectionRows = state.CasingData.rows.filter((cd) => cd.rowData.IsReservoirSection);
  return reservoirSectionRows.length ? reservoirSectionRows[reservoirSectionRows.length - 1] : undefined;
});

export const getLastLowerCompletionDataRow = createSelector(getCompletionRawModuleState, (state) =>
  getLastDataRow(state.LowerCompletion.rows),
);
export const getLastLowerCompletionScreenDataRow = createSelector(getCompletionRawModuleState, (state) => {
  const screenRows = state.LowerCompletion.rows.filter((lc) => isScreenRow(lc));
  return screenRows.length ? screenRows[screenRows.length - 1] : undefined;
});
export const getLastRunningStringDataRow = createSelector(getCompletionRawModuleState, (state) => getLastDataRow(state.RunningString.rows));

export const getMdsForLastRowToleranceCheck = createSelector(
  getLastSurveyDataRow,
  getLastZoneDataRow,
  getLastCasingDataRow,
  getLastCaliperDataRow,
  getLastLowerCompletionDataRow,
  getLastRunningStringDataRow,
  getLastLowerCompletionScreenDataRow,
  getLastCasingReservoirSectionDataRow,
  (
    lastSurveyDataRow,
    lastZonesDataRow,
    lastCasingDataRow,
    lastCaliperDataRow,
    lastLowerCompletionDataRow,
    lastRunningStringDataRow,
    lastLowerCompletionScreenDataRow,
    lastCasingReservoirSectionDataRow,
  ): MDsForLastRowToleranceCheck => ({
    lastSurveyDataRow: lastSurveyDataRow,
    lastZoneDataRow: lastZonesDataRow,
    lastCasingDataRow: lastCasingDataRow,
    lastCaliperDataRow: lastCaliperDataRow,
    lastLowerCompletionDataRow: lastLowerCompletionDataRow,
    lastRunningStringDataRow: lastRunningStringDataRow,
    lastLowerCompletionScreenDataRow: lastLowerCompletionScreenDataRow,
    lastCasingReservoirSectionDataRow: lastCasingReservoirSectionDataRow,
  }),
);

@Injectable({ providedIn: 'root' })
export class ToleranceHelperService {
  constructor(private store: Store) {}

  private checkMdForRow<T extends { ScenarioId: number }>(
    rowToCheck: ITableRow<T> | undefined,
    mdsToCheck: number[],
    globalTolerance: number,
    keyToCheck: keyof T,
    fixDirection: 'up' | 'down' = 'up',
  ): IUpdateTableRowsProps<T> | undefined {
    if (rowToCheck != null && globalTolerance != null) {
      // update only to bigger(when updating last row) or smaller(when updating first row) values to prevent circular updates.
      // When we have several variables in a tolerance some are bigger, some smaller. We don't want to end up with bigger ones
      // being updated to smaller and smaller ones being updated to bigger as it would be infinite update loop.

      const valueToCheck = rowToCheck.rowData[keyToCheck] as number;

      const foundMdInTolerance = mdsToCheck
        .sort((a, b) => (fixDirection === 'up' ? b - a : a - b))
        .find((md) => GeneralCalculations.IsValueWithinTolerance(valueToCheck, md, globalTolerance));

      if (foundMdInTolerance != null && (fixDirection === 'up' ? foundMdInTolerance > valueToCheck : foundMdInTolerance < valueToCheck)) {
        return {
          rows: [{ ...rowToCheck, rowData: { ...rowToCheck.rowData, [keyToCheck]: foundMdInTolerance } }],
          colIds: [keyToCheck],
          shouldResetResults: false,
          scenarioId: rowToCheck.rowData.ScenarioId,
          isUndoEnabled: false,
        } as IUpdateTableRowsProps<T>;
      }
    }
    return;
  }

  public fixFirstRowWithToleranceCheck(mdsForFirstRowToleranceCheck: MDsForFirstRowToleranceCheck, globalTolerance: number): void {
    const firstMdsToCheck = [
      mdsForFirstRowToleranceCheck.firstZoneDataRow?.rowData.TopMD,
      mdsForFirstRowToleranceCheck.firstCasingReservoirSectionDataRow?.rowData.TopMD,
    ].filter(isDefined);

    const newZoneProps = this.checkMdForRow(
      mdsForFirstRowToleranceCheck.firstZoneDataRow,
      firstMdsToCheck,
      globalTolerance,
      'TopMD',
      'down',
    );
    if (newZoneProps != null) {
      this.store.dispatch(wellActions.updateZoneRow(newZoneProps));
    }
    return;
  }

  public fixLastRowWithToleranceCheck(mdsForLastRowToleranceCheck: MDsForLastRowToleranceCheck, globalTolerance: number): void {
    const lastMdsToCheck = [
      mdsForLastRowToleranceCheck.lastSurveyDataRow?.rowData.MD,
      mdsForLastRowToleranceCheck.lastCasingDataRow?.rowData.BottomMD,
      mdsForLastRowToleranceCheck.lastZoneDataRow?.rowData.BottomMD,
      mdsForLastRowToleranceCheck.lastCaliperDataRow?.rowData.TopMD,
      mdsForLastRowToleranceCheck.lastLowerCompletionDataRow?.rowData.BottomMD,
      mdsForLastRowToleranceCheck.lastRunningStringDataRow?.rowData.BottomMD,
      mdsForLastRowToleranceCheck.lastCasingReservoirSectionDataRow?.rowData.BottomMD,
      mdsForLastRowToleranceCheck.lastLowerCompletionScreenDataRow?.rowData.BottomMD,
    ].filter(isDefined);

    // Each dispatch ends checking. We don't want to send multiple updates at once because it could cause first responses trigger updates
    // for already sent ones. Next update may be handled after one is finished.

    const newSurveyProps = this.checkMdForRow(mdsForLastRowToleranceCheck.lastSurveyDataRow, lastMdsToCheck, globalTolerance, 'MD');
    if (newSurveyProps != null) {
      return this.store.dispatch(wellActions.updateSurveyRowAction(newSurveyProps));
    }

    const newCasingProps = this.checkMdForRow(mdsForLastRowToleranceCheck.lastCasingDataRow, lastMdsToCheck, globalTolerance, 'BottomMD');
    if (newCasingProps != null) {
      return this.store.dispatch(wellActions.updateCasingRow(newCasingProps));
    }

    const newZoneProps = this.checkMdForRow(mdsForLastRowToleranceCheck.lastZoneDataRow, lastMdsToCheck, globalTolerance, 'BottomMD');
    if (newZoneProps != null) {
      return this.store.dispatch(wellActions.updateZoneRow(newZoneProps));
    }

    const newCaliperProps = this.checkMdForRow(mdsForLastRowToleranceCheck.lastCaliperDataRow, lastMdsToCheck, globalTolerance, 'TopMD');
    if (newCaliperProps != null) {
      return this.store.dispatch(wellActions.updateCaliperRow(newCaliperProps));
    }

    const newLowerCompletionProps = this.checkMdForRow(
      mdsForLastRowToleranceCheck.lastLowerCompletionDataRow,
      lastMdsToCheck,
      globalTolerance,
      'BottomMD',
    );
    if (newLowerCompletionProps != null) {
      return this.store.dispatch(completionActions.updateLowerCompletionRow(newLowerCompletionProps));
    }

    const newRunningStringProps = this.checkMdForRow(
      mdsForLastRowToleranceCheck.lastRunningStringDataRow,
      lastMdsToCheck,
      globalTolerance,
      'BottomMD',
    );
    if (newRunningStringProps != null) {
      return this.store.dispatch(completionActions.updateRunningStringRow(newRunningStringProps));
    }
  }
}

export interface MDsForFirstRowToleranceCheck {
  firstZoneDataRow?: ITableRow<ZoneModel>;
  firstCasingReservoirSectionDataRow?: ITableRow<Pipe>;
}

export interface MDsForLastRowToleranceCheck {
  lastSurveyDataRow?: ITableRow<Survey>;
  lastZoneDataRow?: ITableRow<ZoneModel>;
  lastCasingDataRow?: ITableRow<Pipe>;
  lastCaliperDataRow?: ITableRow<Caliper>;
  lastLowerCompletionDataRow?: ITableRow<Pipe>;
  lastRunningStringDataRow?: ITableRow<Pipe>;
  lastLowerCompletionScreenDataRow?: ITableRow<Pipe>;
  lastCasingReservoirSectionDataRow?: ITableRow<Pipe>;
}
