import { AllFilesAndFoldersState, FileManagerModuleState, RecentFilesState } from './file-manager.reducer';
import { AllFilesAndFoldersActionResponse, RecentFilesActionResponse } from '@dunefront/common/modules/file-manager/file-manager.actions';
import { AppError, IAppError } from '@dunefront/common/exceptions/IAppError';
import { PackProProfileErrorData } from '@dunefront/common/exceptions/file.errors';
import { IUserProfile } from '@dunefront/common/modules/auth/auth.interfaces';

export class FileManagerModuleReducerHelper {
  public static onLoadAllFilesAndFoldersSuccess(
    state: FileManagerModuleState,
    allFilesAndFoldersActionResponse: AllFilesAndFoldersActionResponse,
  ): FileManagerModuleState {
    const allFilesAndFolders: AllFilesAndFoldersState = {
      rootFolders: allFilesAndFoldersActionResponse.rootFolders,
      error: undefined,
    };
    return { ...state, allFilesAndFolders, fileOperationErrorState: {} };
  }

  public static onLoadAllFilesAndFoldersFailure(state: FileManagerModuleState, error: IAppError): FileManagerModuleState {
    const allFilesAndFolders: AllFilesAndFoldersState = { ...state.allFilesAndFolders, rootFolders: undefined, error };
    return { ...state, allFilesAndFolders, fileOperationErrorState: {} };
  }

  public static onFileOperationFailure(state: FileManagerModuleState, error?: AppError<PackProProfileErrorData>): FileManagerModuleState {
    if (error === undefined) {
      return { ...state, fileOperationErrorState: {} };
    }
    return { ...state, fileOperationErrorState: { error } };
  }

  public static onClearFileOperationFailure(state: FileManagerModuleState): FileManagerModuleState {
    return { ...state, fileOperationErrorState: {} };
  }

  public static onLoadRecentFilesSuccess(
    state: FileManagerModuleState,
    recentFilesActionResponse: RecentFilesActionResponse,
  ): FileManagerModuleState {
    const recentFilesState: RecentFilesState = { recent: recentFilesActionResponse.recent };
    return { ...state, recentFilesState };
  }

  public static onLoadRecentFilesFailure(state: FileManagerModuleState, error: Error): FileManagerModuleState {
    const recentFilesState = { ...recentFilesStateInitialState, getError: error };
    return { ...state, recentFilesState };
  }

  public static onClearRecentFilesSuccess(state: FileManagerModuleState): FileManagerModuleState {
    const recentFilesState = { ...recentFilesStateInitialState };
    return { ...state, recentFilesState };
  }

  public static onClearRecentFilesFailure(state: FileManagerModuleState, error: Error): FileManagerModuleState {
    const recentFilesState = { ...recentFilesStateInitialState, clearError: error };
    return { ...state, recentFilesState };
  }

  public static clearOperationFailure(state: FileManagerModuleState): FileManagerModuleState {
    return { ...state, fileOperationErrorState: {} };
  }

  public static onSelectUser(state: FileManagerModuleState, id: string): FileManagerModuleState {
    return { ...state, selectedUser: state.organizationUsers.find((usr) => usr.uid === id) ?? null };
  }

  public static onLoadUsersWithFoldersSuccess(state: FileManagerModuleState, usersWithFolders: IUserProfile[]): FileManagerModuleState {
    return { ...state, organizationUsers: usersWithFolders };
  }

  public static onClearSelectUser(state: FileManagerModuleState): FileManagerModuleState {
    return { ...state, selectedUser: null };
  }

  public static onUserDeleted(state: FileManagerModuleState, user: IUserProfile): FileManagerModuleState {
    return {
      ...state,
      organizationUsers: state.organizationUsers.filter((usr) => usr.uid !== user.uid),
      selectedUser: state.selectedUser?.uid === user.uid ? null : state.selectedUser,
    };
  }
}

export const recentFilesStateInitialState: RecentFilesState = {
  recent: { recentFiles: [], recentFolders: [] },
  getError: undefined,
  clearError: undefined,
};
