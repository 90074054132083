import { CasingPipeDto } from '../well/dto/casing/casing-pipe.dto';
import { LowerCompletionPipeDto } from '../../dto/lower-completion-pipe.dto';
import { RunningStringPipeDto } from '../../dto/running-string-pipe.dto';
import { BasePipeCalculations, Pipe } from './pipe';

export class BasePipeFactory {
  public static createFromDto(dto: LowerCompletionPipeDto | RunningStringPipeDto | CasingPipeDto): Pipe {
    const { Id, BottomMD, InnerDiameter, OuterDiameter, TopMD, Weight, ScenarioId, SortOrder, PipeType, Description } = dto;
    const pipe: Pipe = {
      Length: 0,
      Id,
      ScenarioId,
      SortOrder,
      BottomMD,
      InnerDiameter,
      OuterDiameter,
      PipeType,
      TopMD,
      Weight,
      IsReservoirSection: false,
      Description,
    };
    pipe.Length = BasePipeCalculations.PipeLength(pipe);
    return pipe;
  }
}
