<app-page-panel [header]="title" [allowFullScreen]="false">
  <div class="grid-form-container" appEqualizeSymbols>
    <app-form-input
      class="grid-two-one"
      appUnwrap
      label="Top MD"
      [disabled]="true"
      [source]="data"
      key="TopMD"
      [decimalPlaces]="2"
      [unitType]="UnitType.Long_Length"
    >
    </app-form-input>
    <app-form-input
      class="grid-two-one"
      appUnwrap
      label="Bottom MD"
      [disabled]="true"
      [source]="data"
      key="BottomMD"
      [decimalPlaces]="2"
      [unitType]="UnitType.Long_Length"
    >
    </app-form-input>
    <app-form-input
      class="grid-two-one"
      appUnwrap
      label="Volume"
      [disabled]="true"
      [source]="data"
      key="Volume"
      [decimalPlaces]="2"
      [unitType]="UnitType.Liquid_Volume"
    >
    </app-form-input>
  </div>
</app-page-panel>
