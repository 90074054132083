import { LengthConverter } from '@dunefront/common/unit-converters/converters/length/length.converter';
import { PlaneAngleConverter } from '@dunefront/common/unit-converters/converters/plane-angle/plane-angle.converter';
import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectCurrentUnitSystem, selectSortedUnitSystems } from '../../../+store/units/units.selectors';
import { chooseUnitSystemAction } from '../../../+store/units/units.actions';
import { LinearMassConverter } from '@dunefront/common/unit-converters/converters/linear-mass/linear-mass.converter';
import { IUnitSystemDto, LengthUnit, LinearMassUnit, PlaneAngleUnit, UnitSystem } from '@dunefront/common/dto/unit-system.dto';

@Component({
  selector: 'app-units-test',
  templateUrl: './units-test.component.html',
  styleUrls: ['./units-test.component.scss'],
})
export class UnitsTestComponent implements OnDestroy {
  public UnitType = UnitSystem;
  public LengthUnit = LengthUnit;
  public Length = LengthConverter;
  public PlaneAngleUnit = PlaneAngleUnit;
  public PlaneAngle = PlaneAngleConverter;
  public LinearDensityUnit = LinearMassUnit;
  public LinearDensity = LinearMassConverter;
  private subscription = new Subscription();

  public availableUnitSystems$: Observable<IUnitSystemDto[]>;
  public currentUnitSystem?: IUnitSystemDto;

  public testLength = 0;

  constructor(
    private store: Store,
    private cdRef: ChangeDetectorRef,
  ) {
    this.availableUnitSystems$ = store.select(selectSortedUnitSystems);
    this.subscription.add(
      store.select(selectCurrentUnitSystem).subscribe((currentUnitSystem) => {
        this.currentUnitSystem = currentUnitSystem;
        cdRef.markForCheck();
      }),
    );
  }

  public onSelect(unitSystem: IUnitSystemDto): void {
    this.store.dispatch(chooseUnitSystemAction({ unitSystemId: unitSystem.sourceId }));
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
