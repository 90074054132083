import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BaseWsEffects } from '../base-ws.effects';
import { Store } from '@ngrx/store';
import { BackendConnectionService } from '../../shared/backend-connection/backend-connection.service';
import { ModalService } from '../../common-modules/modals/modal.service';
import { CreateNoteAction, NoteModuleName, UpdateNoteRowAction } from '@dunefront/common/modules/note/note-module.actions';
import { createNote, updateNote } from './note.actions';
import { dataFailed, insertRowsSuccess, updateRowSuccess } from '../app.actions';
import { of } from 'rxjs';
import { CrudResponse } from '@dunefront/common/modules/common.actions';

@Injectable()
export class NoteEffects extends BaseWsEffects {
  constructor(actions$: Actions, store: Store, wsService: BackendConnectionService, modalService: ModalService) {
    super(actions$, wsService, NoteModuleName, false, true, modalService, store);
  }

  public createNote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createNote),
      mergeMap((note) =>
        this.emit<CrudResponse>(
          new CreateNoteAction({
            shouldResetResults: false,
            rows: [note],
            scenarioId: note.ScenarioId,
          }),
        ).pipe(
          map((result) => insertRowsSuccess(result.payload)),
          catchError((err) => of(dataFailed(err))),
        ),
      ),
    ),
  );

  public updateNote = createEffect(() =>
    this.actions$.pipe(
      ofType(updateNote),
      mergeMap((note) =>
        this.emit<CrudResponse>(
          new UpdateNoteRowAction({
            shouldResetResults: false,
            rows: [note],
            scenarioId: note.ScenarioId,
          }),
        ).pipe(
          map((result) => updateRowSuccess(result.payload)),
          catchError((err) => of(dataFailed(err))),
        ),
      ),
    ),
  );
}
