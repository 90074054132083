import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ITableRow } from '@dunefront/common/common/common-grid.interfaces';
import { EnumHelpers } from '@dunefront/common/utils/enum.helpers';
import { GaugeMeasurementType } from '@dunefront/common/dto/running-string-pipe.dto';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { GaugeCarrierPipe } from '@dunefront/common/modules/pipes/running-string-pipes/pipes/gauge-carrier-pipe';
import { changeProp, ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';
import { IUpdateTableRowsProps, StoreCrudPropsFactory } from '@dunefront/common/common/common-store-crud.interfaces';

@Component({
  template: '',
})
export abstract class BaseGaugeCarrierPropertiesComponent<T extends GaugeCarrierPipe> {
  @Input() public pipe: ITableRow<T> | undefined;
  @Output() public rowUpdated = new EventEmitter<IUpdateTableRowsProps<T>>();

  public gaugeMeasurementTypeItems = EnumHelpers.EnumToISelectItemArray(GaugeMeasurementType);
  public UnitType = UnitSystem;

  public onPropertyChange(event: ObjectChangeProp<T>): void {
    if (this.pipe == null) {
      return;
    }
    const newRow: ITableRow<T> = {
      ...this.pipe,
      rowData: changeProp(this.pipe.rowData, event),
    };
    this.rowUpdated.emit(StoreCrudPropsFactory.updateTableRows([newRow], event));
  }
}
