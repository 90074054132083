import { Chart } from 'chart.js';
import { DrawingGradientLine, GradientLine, GradientLineAxisParameters, PointOffsets } from './types';
import { pointToSegmentDistance } from '@dunefront/common/common/math-geometry-helpers';
import { Point } from 'chart.js/dist/types';
import { getState } from './state';
import { lineSelectionThreshold } from './constants';

export const gradientLineToDrawingGradientLine = (
  { id, xAxisValue1, yAxisValue1, xAxisValue2, yAxisValue2, xScaleID, yScaleID, style }: GradientLine,
  chart: Chart,
): DrawingGradientLine | undefined => {
  const xScale = chart.scales[xScaleID];
  const yScale = chart.scales[yScaleID];
  if (xScale == null || yScale == null) {
    return undefined;
  }

  return {
    id,
    point1: {
      x: xScale.getPixelForValue(xAxisValue1),
      y: yScale.getPixelForValue(yAxisValue1),
    },
    point2: {
      x: xScale.getPixelForValue(xAxisValue2),
      y: yScale.getPixelForValue(yAxisValue2),
    },
    style,
    xScaleID,
    yScaleID,
  };
};

export const toGradientLine = ({ id, point1, point2, xScaleID, yScaleID, style }: DrawingGradientLine, chart: Chart): GradientLine => {
  const xScale = chart.scales[xScaleID];
  const yScale = chart.scales[yScaleID];

  return {
    id,
    xAxisValue1: xScale.getValueForPixel(point1.x) ?? 0,
    yAxisValue1: yScale.getValueForPixel(point1.y) ?? 0,
    xAxisValue2: xScale.getValueForPixel(point2.x) ?? 0,
    yAxisValue2: yScale.getValueForPixel(point2.y) ?? 0,
    xScaleID,
    yScaleID,
    style,
  };
};

export const isOverGradientLine = (point: Point, { point1, point2 }: DrawingGradientLine): boolean => {
  return pointToSegmentDistance(point, point1, point2) <= lineSelectionThreshold;
};

export const getClosestGradientLine = (point: Point, lines: DrawingGradientLine[]): DrawingGradientLine | undefined => {
  let closestLine: DrawingGradientLine | undefined;
  let distanceToClosest = Number.MAX_VALUE;

  for (const line of lines) {
    const distance = pointToSegmentDistance(point, line.point1, line.point2);
    if (distance < distanceToClosest) {
      distanceToClosest = distance;
      closestLine = line;
    }
  }

  return distanceToClosest <= lineSelectionThreshold ? closestLine : undefined;
};

export const pointWithOffset = ({ x, y }: Point, { offsetX, offsetY }: PointOffsets): Point => ({
  x: x + offsetX,
  y: y + offsetY,
});

export const drawingGradientLineWithOffsets = (
  line: DrawingGradientLine,
  p1Offsets: PointOffsets,
  p2Offsets: PointOffsets,
): DrawingGradientLine => {
  return {
    ...line,
    point1: pointWithOffset(line.point1, p1Offsets),
    point2: pointWithOffset(line.point2, p2Offsets),
  };
};
export const getGradientLineParams = (
  { point1, point2, xScaleID, style }: DrawingGradientLine,
  chart: Chart,
): GradientLineAxisParameters[] => {
  const { scales } = getState(chart).options;

  const argScaleInfo = scales.find((s) => s.isArgument);
  if (argScaleInfo == null) {
    return [];
  }

  const results: GradientLineAxisParameters[] = [];

  const isNotRotated = argScaleInfo.id === xScaleID;
  const argScale = chart.scales[argScaleInfo.id];

  const argPx1 = isNotRotated ? point1.x : point1.y;
  let argPx2 = isNotRotated ? point2.x : point2.y;

  // if there is a friction of pixel difference between args - it's most likely floating point issue,
  // in this case args must get equalized, otherwise value gradients gets calculated insanely large
  if (Math.abs(argPx1 - argPx2) < 0.001) {
    argPx2 = argPx1;
  }

  const valuePx1 = isNotRotated ? point1.y : point1.x;
  const valuePx2 = isNotRotated ? point2.y : point2.x;

  const argScaleValue1 = argScale.getValueForPixel(argPx1) ?? 0;
  const argScaleValue2 = argScale.getValueForPixel(argPx2) ?? 0;

  const argStartScaleValue = argScaleValue1 <= argScaleValue2 ? argScaleValue1 : argScaleValue2;
  const argEndScaleValue = argScaleValue1 <= argScaleValue2 ? argScaleValue2 : argScaleValue1;

  const startValuePx = argScaleValue1 <= argScaleValue2 ? valuePx1 : valuePx2;
  const endValuePx = argScaleValue1 <= argScaleValue2 ? valuePx2 : valuePx1;

  const deltaArg = argEndScaleValue - argStartScaleValue;

  results.push({
    title: argScaleInfo.title,
    delta: {
      value: deltaArg,
      unit: argScaleInfo.units.join(', '),
    },
    decimalPlaces: style.GradientLineDecimalPlaces,
  });

  for (const scaleInfo of scales) {
    if (scaleInfo === argScaleInfo) {
      continue;
    }

    const valueScale = chart.scales[scaleInfo.id];

    const startScaleValue = valueScale.getValueForPixel(startValuePx) ?? 0;
    const endScaleValue = valueScale.getValueForPixel(endValuePx) ?? 0;

    const deltaValue = endScaleValue - startScaleValue;
    const unitString = scaleInfo.units.join(', ');

    results.push({
      title: scaleInfo.title,
      delta: {
        value: deltaValue,
        unit: unitString,
      },
      decimalPlaces: style.GradientLineDecimalPlaces,
      gradient:
        deltaArg !== 0
          ? {
              value: deltaValue / deltaArg,
              unit: (scaleInfo.units.length > 1 ? `(${unitString})` : unitString) + ` / ${argScaleInfo.units[0]}`,
            }
          : undefined,
    });
  }

  return results;
};
