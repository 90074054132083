import { BasePptSectionGenerator, PptContext } from '../base-ppt-section-generator';
import { firstValueFrom } from 'rxjs';
import { selectCurrentUnitSystem } from '../../../../../../+store/units/units.selectors';
import { CommonReportDataHelpers, ReportFluid } from '../../common/common-report-data-helpers';
import { PptDocumentTableGeneratorHelper } from '../ppt-document-table-generator.helper';
import { IUnitSystemDto } from '@dunefront/common/dto/unit-system.dto';
import { FluidModuleState } from '@dunefront/common/modules/fluid/fluid-module.state';
import { getFluidModuleState } from '../../../../../../+store/fluid/fluid.selectors';
import { WellModuleState } from '@dunefront/common/modules/well/well-module.state';
import { getWellModuleState } from '../../../../../../+store/well/well.selectors';
import { ValidatedSettings } from '@dunefront/common/modules/settings/dto/settingsDto';
import { getValidatedSettings } from '../../../../../../+store/settings/validated-settings.selectors';
import { PptHtmlParser } from '../../../generators/ppt/ppt-html-parser';
import { baseTextProps, contentPosition } from '../../../generators/ppt/style-constants';

export class FluidsDataPptGenerator extends BasePptSectionGenerator {
  constructor(context: PptContext) {
    super(context);
  }

  public async generate(): Promise<void> {
    const title = 'Fluids Data';
    const currentUnitSystem: IUnitSystemDto = await firstValueFrom(this.store.select(selectCurrentUnitSystem));
    const fluidsState: FluidModuleState = await firstValueFrom(this.store.select(getFluidModuleState));
    const wellState: WellModuleState = await firstValueFrom(this.store.select(getWellModuleState));
    const settingsState: ValidatedSettings = await firstValueFrom(this.store.select(getValidatedSettings));

    const { tableData, enumItemMap } = await CommonReportDataHelpers.createFluidsTableData(
      this.context.store,
      fluidsState,
      wellState,
      settingsState
    );


    tableData.dataRows.forEach(row => {

      const singleFluidTableData = {
        dataRows: [row],
        dataCells: tableData.dataCells
      };

      const slide = this.context.pptx.addSlide();

      BasePptSectionGenerator.addSlideTitle(row.Name, slide);
      BasePptSectionGenerator.addSlideSubTitle(title, slide);
      BasePptSectionGenerator.addTableToSlide(slide, {
        tableRows: PptDocumentTableGeneratorHelper.createTableRows<ReportFluid>(singleFluidTableData, enumItemMap, currentUnitSystem)
      });

      const notes = new PptHtmlParser(row.Notes).render();

      slide.addText(
        notes,
        {
          ...{ ...contentPosition, y: contentPosition.y + 1.2 },
          ...baseTextProps,
          fontSize: 10,
          align: 'justify',
          valign: 'top'
        }
      );
    });
  }
}
