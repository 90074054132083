import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { ITableState } from '@dunefront/common/common/common-grid.interfaces';
import { GridComponent, ISelectedRowProps } from '../../../../../shared/components/grid/grid.component';
import { LengthConverter } from '@dunefront/common/unit-converters/converters/length/length.converter';
import { DbDependentComponent } from '../../../../../common-modules/db-connection/db-dependent.component';
import { Store } from '@ngrx/store';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AutoFocusPosition } from '../../../../../common-modules/units/components/input-2/input-2.component';
import { pipeDataGridConfig, pipeDataRows } from './pipe-data-grid.config';
import { modalButton, ModalButtonConfig } from '../../../../../common-modules/modals/generic-modal/generic-modal.component';

@Component({
  selector: 'app-pipe-data',
  templateUrl: './pipe-data.component.html',
  styleUrls: ['./pipe-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PipeDataComponent extends DbDependentComponent implements AfterViewInit {
  public autoFocusPosition = AutoFocusPosition;
  public selectedRowIndex?: number;
  public isGridVisible = false;
  public UnitType = UnitSystem;
  public isHelpOpen = false;
  public OD = 0;
  @ViewChild(GridComponent) public grid!: GridComponent<any>;

  public columns = pipeDataGridConfig;
  private rows: any[] = pipeDataRows;

  public pipeData: ITableState<PipeDimensionData> = {
    error: '',
    isValid: false,
    selectedRowId: undefined,
    rows: this.rows.map((row, rowIndex) => ({ ...row, rowIndex })),
  };

  public filteredPipeData: ITableState<PipeDimensionData>;
  public modalTitle = 'Pipe Data';

  public modalButtonsConfigs: ModalButtonConfig[] = [
    modalButton('Cancel', (): Promise<void> => this.onCancelClicked(), 'pipe-data-cancel', 'cancel'),
    modalButton('Submit', (): void => this.onSubmitClicked(), 'pipe-data-submit', undefined, undefined, undefined, undefined, true),
  ];

  constructor(
    store: Store,
    cdRef: ChangeDetectorRef,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
  ) {
    super(store, cdRef);
    this.filteredPipeData = { ...this.pipeData };
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      this.isGridVisible = true;
      this.cdRef.markForCheck();
    }, 0);
  }

  public onSelectedRowsChanged(selectedRows: ISelectedRowProps[]): void {
    if (!selectedRows.length) {
      this.selectedRowIndex = undefined;
      return;
    }

    const selRowIdx = selectedRows[0].rowIndex;
    this.selectedRowIndex = selRowIdx;
    this.OD = this.rows[selRowIdx].rowData.outerDiameter;
  }

  public onSubmitClicked(): void {
    if (this.selectedRowIndex === undefined) {
      return this.ref.close();
    }

    const pipeData = this.pipeData.rows[this.selectedRowIndex].rowData;
    this.ref.close(pipeData);
  }

  public async onCancelClicked(): Promise<void> {
    this.ref.close();
  }

  public onChange(value: number): void {
    if (value === this.OD) {
      return;
    }

    this.OD = value;
    const unitSystem = this.currentUnitSystem;
    const convertedValue1000 = Math.round(LengthConverter.fromSi(value, unitSystem.Diameter) * 1000);
    const index = this.pipeData.rows.findIndex(
      (row) => Math.round(LengthConverter.fromSi(row.rowData.outerDiameter, unitSystem.Diameter) * 1000) >= convertedValue1000,
    );
    this.grid.viewport?.scrollToIndex(index !== -1 ? index - 1 : this.pipeData.rows.length - 1);
  }

  public onHelpClick(isHelpOpen: boolean): void {
    this.isHelpOpen = isHelpOpen;
  }
}

export interface PipeDimensionData {
  Id: any;
  RowIndex?: number;
  outerDiameter: number;
  innerDiameter: number;
  weight: number;
}
