import { BuildInfo } from './build-info';
import { IAppTargetConfig } from './app-target-config';
import { ignoredErrors } from '../exceptions/ignoredErrors';
import { IUserId } from '../modules/auth/auth.interfaces';
import { User } from '@sentry/node';
import { LicenseFeature, LicensingLoginResponse } from '../modules/licensing/licensing.interfaces';
import { isElectron } from './electron/is-electron';

type AppPlatform = 'Cloud' | 'Electron';
export type AppProcess = 'Client' | 'Server';

export interface SentryBasicOptions {
  dsn: string | undefined;
  environment: string | undefined;
  release: string | undefined;
  ignoreErrors?: Array<string | RegExp>;
}

export const getSentryBasicOptions = (
  dsn: string | undefined,
  appTarget: IAppTargetConfig,
  process: AppProcess,
  environment = 'production',
): SentryBasicOptions => {
  const platform: AppPlatform = isElectron() ? 'Electron' : 'Cloud';
  return {
    dsn,
    environment,
    release: `${appTarget.appName}-${platform}-${process}-${BuildInfo.version}-${BuildInfo.commitRef}`,
    ignoreErrors: ignoredErrors,
  };
};

export const getSentryUser = (userId: IUserId, filename: string | undefined): User => ({
  id: userId.uid,
  org_id: userId.org,
  license_id: JSON.stringify(userId.licenseIds),
  filename,
});

export const getSentryLicenseInfo = (licenseResponse: LicensingLoginResponse): { mainFeature: string; id: string } => {
  return {
    mainFeature: LicenseFeature[licenseResponse.mainFeature],
    id: licenseResponse.loginInfos.find((loginInfo) => loginInfo.licenseFeature === licenseResponse.mainFeature)?.licenseId ?? '',
  };
};
