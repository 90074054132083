import { ForceUnit } from '../../../dto/unit-system.dto';
import { UnitConverter } from '../../converter.interfaces';

export class ForceConverter extends UnitConverter {
  public static symbols: Array<string> = ['', 'N', 'kN', 'lbf', 'hlbf', 'klbf', 'gf', 'kgf'];

  public static unit = ForceUnit;

  public static fromSi(value: number, unit: ForceUnit): number {
    switch (unit) {
      case ForceUnit.Newton: {
        return value;
      }
      case ForceUnit.Kilo_Newton: {
        return value * 1.0e-3;
      }
      case ForceUnit.Pound_Force: {
        return value * 2.248089431e-1;
      }
      case ForceUnit.Hundred_Pound_Force: {
        return value * 2.248089431e-3;
      }
      case ForceUnit.Thousand_Pound_Force: {
        return value * 2.248089431e-4;
      }
      case ForceUnit.Gram_Force: {
        return value * 1.019716213e2;
      }
      case ForceUnit.Kilogram_Force: {
        return value * 1.019716213e-1;
      }
      default: {
        throw new TypeError('Unknown Force Unit:' + unit);
      }
    }
  }

  public static toSi(value: number, unit: ForceUnit): number {
    switch (unit) {
      case ForceUnit.Newton: {
        return value;
      }
      case ForceUnit.Kilo_Newton: {
        return value / 1.0e-3;
      }
      case ForceUnit.Pound_Force: {
        return value / 2.248089431e-1;
      }
      case ForceUnit.Hundred_Pound_Force: {
        return value / 2.248089431e-3;
      }
      case ForceUnit.Thousand_Pound_Force: {
        return value / 2.248089431e-4;
      }
      case ForceUnit.Gram_Force: {
        return value / 1.019716213e2;
      }
      case ForceUnit.Kilogram_Force: {
        return value / 1.019716213e-1;
      }
      default: {
        throw new TypeError('Unknown Force Unit:' + unit);
      }
    }
  }

  public static getSymbol(unit: ForceUnit): string {
    return this.symbols[unit];
  }
}
