import { WsAction } from '../../ws.action';
import { FluidDto } from '../fluid/dto/fluid.dto';
import { RheologyDto } from '../fluid/dto/rheology/rheology.dto';
import { RheometerDto, RheometerReadingDto } from '../fluid/dto/rheometer.dto';
import { GravelDto } from '../gravel/dto/gravel.dto';
import { GlobalOptionsDto } from '../../dto/global-options.dto';
import { CommonDbType, WritableCommonDbType } from '../../dto/common-dto.interfaces';
import { ICustomSettings, ICustomSettingsDto } from '../../dto/custom-settings.dto';
import { AppError } from '../../exceptions/IAppError';
import { ModuleType } from '../scenario/scenario.dto';
import { Color } from '../reporting/dto/chart.types';
import { IUnitSystem } from '../units/units.actions';
import { IGlobalOptions } from '../db-connection/db-connection.actions';

export const CommonDbModuleName = 'CommonDbModule';

export enum CommonDbModuleActionTypes {
  LoadCommonDbInitialData = '[CommonDbModule] LoadCommonDbInitialData',

  LoadCommonFluids = '[CommonDbModule] LoadCommonFluids',
  CopyCommonFluidToProject = '[CommonDbModule] [Insert] CopyCommonFluidToProject',
  CopyProjectFluidToCommon = '[CommonDbModule] [Insert] CopyProjectFluidToCommon',
  DeleteCommonFluid = '[CommonDbModule] [Delete] DeleteCommonFluid',

  LoadCommonGravels = '[CommonDbModule] LoadCommonGravels',
  CopyCommonGravelToProject = '[CommonDbModule] [Insert] CopyCommonGravelToProject',
  CopyProjectGravelToCommon = '[CommonDbModule] [Insert] CopyProjectGravelToCommon',
  DeleteCommonGravel = '[CommonDbModule] [Delete] DeleteCommonGravel',

  SaveGlobalOptions = '[CommonDbModule] [Update] SaveGlobalOptions',
  UpdateCustomSettings = '[CommonDbModule] [Update] CustomSettings',

  LoadNotificationsStatus = '[CommonDbModule] LoadNotificationsStatus',
}

// notifications emitted to users (BE => FE)
export enum CommonDbModuleNotificationTypes {
  CommonDbRestored = '[CommonDbModule] CommonDbRestored',
}

export class LoadCommonDbInitialData extends WsAction {
  public override readonly type = CommonDbModuleActionTypes.LoadCommonDbInitialData;
}

export interface ICommonDbInitialData {
  unitSystem: IUnitSystem;
  customSettings: Partial<ICustomSettings>;
  globalOptions: IGlobalOptions;
}

// region notifications
export class LoadNotificationsStatusAction extends WsAction {
  public override readonly type = CommonDbModuleActionTypes.LoadNotificationsStatus;
}

//endregion

//region dbRestore
export interface CommonDbRestoreItem {
  commonDbType: CommonDbType;
  restoredVersion: string;
  tooOldDBVersion?: boolean;
}

export class CommonDbRestoredNotification extends WsAction {
  public override readonly type = CommonDbModuleNotificationTypes.CommonDbRestored;

  constructor(public override payload: CommonDbRestoreItem[]) {
    super();
  }
}

//endregion

//region Fluids
export class LoadCommonFluidsAction extends WsAction {
  public override readonly type = CommonDbModuleActionTypes.LoadCommonFluids;
}

export class CopyCommonFluidToProjectAction extends WsAction {
  public override readonly type = CommonDbModuleActionTypes.CopyCommonFluidToProject;

  constructor(
    public commonFluidId: number,
    public commonDbType: CommonDbType,
    public targetScenarioId: number,
    public fluidColor: Color,
  ) {
    super();
  }
}

export class CopyProjectFluidToCommonAction extends WsAction {
  public override readonly type = CommonDbModuleActionTypes.CopyProjectFluidToCommon;

  constructor(
    public fluidId: number,
    public commonDbType: CommonDbType,
    public overwrite: boolean,
  ) {
    super();
  }
}

export class DeleteCommonFluidAction extends WsAction {
  public override readonly type = CommonDbModuleActionTypes.DeleteCommonFluid;

  constructor(
    public commonFluidId: number,
    public commonDbType: CommonDbType,
  ) {
    super();
  }
}

export interface ILoadCommonFluidsActionResponse {
  predefinedCommonFluids: FluidDto[];
  predefinedCommonRheologies: RheologyDto[];
  orgCommonFluids: FluidDto[];
  orgCommonRheologies: RheologyDto[];
  orgCommonRheometers: RheometerDto[];
  orgCommonRheometerReadingss: RheometerReadingDto[];
  personalCommonFluids: FluidDto[];
  personalCommonRheologies: RheologyDto[];
  personalCommonRheometers: RheometerDto[];
  personalCommonRheometerReadings: RheometerReadingDto[];
}

export class PackProCommonFluidError extends AppError<{
  fluidId: number;
  commonDbType: CommonDbType;
  scenarioId: number;
}> {
  constructor(
    public type: CommonDbErrorType,
    fluidId: number,
    scenarioId: number,
    commonDbType: CommonDbType,
    isHandled?: boolean,
    moduleType?: ModuleType,
  ) {
    super(getCommonDbError(type), { fluidId, commonDbType, scenarioId }, isHandled, 'common-db-error', moduleType);
  }

  public override data!: CommonFluidErrorData;
}

export interface CommonFluidErrorData {
  fluidId: number;
  commonDbType: CommonDbType;
  scenarioId: number;
}

//endregion

//region Gravels
export class LoadCommonGravelsAction extends WsAction {
  public override readonly type = CommonDbModuleActionTypes.LoadCommonGravels;
}

export class CopyCommonGravelToProjectAction extends WsAction {
  public override readonly type = CommonDbModuleActionTypes.CopyCommonGravelToProject;

  constructor(
    public commonGravelId: number,
    public commonDbType: CommonDbType,
    public targetScenarioId: number,
    deleteGravelId?: number,
  ) {
    super();
  }
}

export class CopyProjectGravelToCommonAction extends WsAction {
  public override readonly type = CommonDbModuleActionTypes.CopyProjectGravelToCommon;

  constructor(
    public gravelId: number,
    public commonDbType: CommonDbType,
    public overwrite: boolean,
  ) {
    super();
  }
}

export class DeleteCommonGravelAction extends WsAction {
  public override readonly type = CommonDbModuleActionTypes.DeleteCommonGravel;

  constructor(
    public commonGravelId: number,
    public commonDbType: CommonDbType,
  ) {
    super();
  }
}

export interface ILoadCommonGravelsActionResponse {
  predefinedCommonGravels: GravelDto[];
  orgCommonGravels: GravelDto[];
  personalCommonGravels: GravelDto[];
}

export class PackProCommonGravelError extends AppError<{
  gravelId: number;
  commonDbType: CommonDbType;
  scenarioId: number;
}> {
  constructor(
    public type: CommonDbErrorType,
    gravelId: number,
    scenarioId: number,
    commonDbType: CommonDbType,
    isHandled?: boolean,
    moduleType?: ModuleType,
  ) {
    super(getCommonDbError(type), { gravelId, commonDbType, scenarioId }, isHandled, 'common-db-error', moduleType);
  }

  public override data!: CommonGravelErrorData;
}

export interface CommonGravelErrorData {
  gravelId: number;
  commonDbType: CommonDbType;
  scenarioId: number;
}

//endregion

//region Settings

export class SaveGlobalOptionsAction extends WsAction {
  public override readonly type = CommonDbModuleActionTypes.SaveGlobalOptions;

  constructor(
    public commonDbType: WritableCommonDbType,
    public globalOptions: GlobalOptionsDto,
  ) {
    super();
  }
}
export class SaveCustomSettingsAction extends WsAction {
  public override readonly type = CommonDbModuleActionTypes.UpdateCustomSettings;

  constructor(
    public commonDbType: WritableCommonDbType,
    public customSettings: ICustomSettingsDto,
  ) {
    super();
  }
}

//endregion

export type CommonDbErrorType = 'load-already-exists' | 'save-already-exists';

export const getCommonDbError = (errorType: CommonDbErrorType): string => 'PackPro CommonDbError: ' + errorType;

export type CommonDbActions =
  | LoadCommonFluidsAction
  | CopyCommonFluidToProjectAction
  | CopyProjectFluidToCommonAction
  | DeleteCommonFluidAction
  | LoadCommonGravelsAction
  | CopyCommonGravelToProjectAction
  | CopyProjectGravelToCommonAction
  | DeleteCommonGravelAction
  | LoadCommonDbInitialData
  | SaveGlobalOptionsAction
  | SaveCustomSettingsAction
  | LoadNotificationsStatusAction;

export type CommonDbNotifications = CommonDbRestoredNotification;
