import { Component, Input, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BaseFormComponent } from '../base-form-component';
import { TooltipModule } from 'primeng/tooltip';
import { DropdownModule } from 'primeng/dropdown';
import { CheckboxModule } from 'primeng/checkbox';

@Component({
  selector: 'app-check-box',
  templateUrl: './check-box.component.html',
  styleUrls: ['./check-box.component.scss'],
})
export class CheckBoxComponent<T, ITempType extends T[keyof T]> extends BaseFormComponent<T, ITempType> {
  @Input() public label = '';
  @Input() public error = false;
  @Input() public showLabel = true;
  @Input() public enableTextOverflowEllipsis = false;
  @Input() public labelWidth: number | undefined;

  public checked = false;

  public async onCheckboxChange(event: any): Promise<void> {
    await this.onChange(event.checked);
  }

  public getDefaultTooltip(): string {
    if (this.sourceDefaults == null || this.key == null) {
      return '';
    }
    const defaultValue = (this.sourceDefaults as T)[this.key] === true ? 'Checked' : 'Unchecked';
    return defaultValue ? 'Default value: ' + defaultValue : '';
  }
}

@NgModule({
  imports: [CommonModule, FormsModule, TooltipModule, DropdownModule, CheckboxModule],
  declarations: [CheckBoxComponent],
  exports: [CheckBoxComponent],
})
export class CheckBoxModule {}
