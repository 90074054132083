import { IArgumentRange } from './chart-types';
import { UnitConverterHelper } from '@dunefront/common/unit-converters/unit.converter.helper';
import { getArgumentAxisUnit, IChartDataDto } from '@dunefront/common/modules/reporting/dto/chart-data.dto';
import { IUnitSystemDto } from '@dunefront/common/dto/unit-system.dto';
import { Scale } from 'chart.js';
import { EventEmitter, NgZone } from '@angular/core';

export class ChartZoomedDataService {
  constructor(
    private argumentRangeChanged: EventEmitter<IArgumentRange>,
    private isZoomed: EventEmitter<boolean>,
    private ngZone: NgZone,
  ) {}

  public static readonly ZOOM_REQUEST_TIMEOUT = 500;

  public afterUpdateMinX = -Number.MAX_VALUE;
  public afterUpdateMaxX = Number.MAX_VALUE;
  public firstAfterUpdate = true;

  private timerHandle?: number;
  private chartDataDto?: IChartDataDto;
  private currentUnitSystem?: IUnitSystemDto;
  private currentArgumentStart: number | undefined;
  private currentArgumentEnd: number | undefined;
  private isScaleChanged = false;

  public reset(): void {
    this.stopTimer();
    this.chartDataDto = undefined;
    this.currentArgumentStart = undefined;
    this.currentArgumentEnd = undefined;
    this.firstAfterUpdate = true;
    this.isScaleChanged = false;
  }

  public updateScales(
    scale: Scale | undefined,
    chartDataDto: IChartDataDto,
    currentArgumentStart: number | undefined,
    currentArgumentEnd: number | undefined,
    currentUnitSystem: IUnitSystemDto,
  ): void {
    if (scale === undefined || !scale.min === undefined || !scale.max === undefined) {
      return;
    }

    this.chartDataDto = chartDataDto;
    this.currentUnitSystem = currentUnitSystem;
    this.currentArgumentStart = currentArgumentStart;
    this.currentArgumentEnd = currentArgumentEnd;

    if (this.firstAfterUpdate) {
      this.firstAfterUpdate = false;
      this.afterUpdateMinX = scale.min;
      this.afterUpdateMaxX = scale.max;
      this.isZoomed.next(false);
    } else {
      if (this.afterUpdateMinX !== scale.min || this.afterUpdateMaxX !== scale.max) {
        this.afterUpdateMinX = scale.min;
        this.afterUpdateMaxX = scale.max;
        this.isScaleChanged = true;
        this.isZoomed.next(true);
        this.startTimer();
      }
    }
  }

  public stopTimer(): void {
    if (this.timerHandle != null) {
      window.clearTimeout(this.timerHandle);
      this.timerHandle = undefined;
    }
  }

  public startTimer(): void {
    if (this.isScaleChanged) {
      this.stopTimer();
      this.timerHandle = window.setTimeout(() => this.onTimeout(), ChartZoomedDataService.ZOOM_REQUEST_TIMEOUT);
    }
  }

  private onTimeout(): void {
    if (!this.chartDataDto || !this.currentUnitSystem) {
      return;
    }
    this.timerHandle = undefined;
    const argumentAxisUnit = getArgumentAxisUnit(this.chartDataDto);
    let argumentStart = UnitConverterHelper.convertToSi(argumentAxisUnit.unitSystem, this.currentUnitSystem, this.afterUpdateMinX);
    let argumentEnd = UnitConverterHelper.convertToSi(argumentAxisUnit.unitSystem, this.currentUnitSystem, this.afterUpdateMaxX);

    if (this.currentArgumentStart !== undefined && argumentStart < this.currentArgumentStart) {
      argumentStart = this.currentArgumentStart;
    }
    if (this.currentArgumentEnd !== undefined && argumentEnd > this.currentArgumentEnd) {
      argumentEnd = this.currentArgumentEnd;
    }

    this.ngZone.run(() => this.argumentRangeChanged.next({ argumentStart, argumentEnd }));
    this.isScaleChanged = false;
  }

  public dispose(): void {
    this.stopTimer();
  }
}
