import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges } from '@angular/core';
import { ITableState } from '@dunefront/common/common/common-grid.interfaces';
import {
  createChartDataColumn,
  createChartDataRowDto,
  createChartDataSet,
  createEmptyChartData,
  IChartDataDtoColumn,
} from '@dunefront/common/modules/reporting/dto/chart-data.dto';
import { DbDependentComponent } from '../../../../../common-modules/db-connection/db-dependent.component';
import { Store } from '@ngrx/store';
import { DataType } from '@dunefront/common/dto/data-storage';
import { ChartDataSourceType } from '@dunefront/common/modules/reporting/dto/chart.dto';
import { getChartIds } from '../../../../../+store/reporting/reporting.selectors';
import { ChartAxis } from '@dunefront/common/modules/reporting/dto/chart-axis-property.dto';
import { ChartState } from '../../../../../+store/reporting/reporting-module.state';
import { ReportingFactory } from '../../../../../+store/reporting/model/reporting.factory';
import { PsdReading } from '@dunefront/common/modules/psd-analysis/model/psd-readings/psd-reading';
import { PSD } from '@dunefront/common/modules/psd-analysis/model/psd/psd';

const CHART_NAME_PSD_CHART_PREVIEW = 'PSD Chart Preview';

@Component({
  selector: 'app-psd-chart-panel',
  templateUrl: './psd-chart-panel.component.html',
  styleUrls: ['./psd-chart-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PsdChartPanelComponent extends DbDependentComponent implements AfterViewInit, OnChanges {
  @Input() public psdReadings: ITableState<PsdReading> | undefined;
  @Input() public psd: PSD | undefined;
  @Input() public drawableProviderId = CHART_NAME_PSD_CHART_PREVIEW;

  public readonly chartDisplayName = CHART_NAME_PSD_CHART_PREVIEW;

  public chartState!: ChartState;
  public displayChart = false;
  public chartId = 0;

  constructor(store: Store, cdRef: ChangeDetectorRef) {
    super(store, cdRef);

    this.subscription.add(
      store.select(getChartIds).subscribe((chartIds) => {
        this.chartId = chartIds.psdAChartId;
        cdRef.markForCheck();
      }),
    );
  }

  public ngAfterViewInit(): void {
    this.chartState = this.initChart();
  }

  public ngOnChanges(): void {
    this.chartState = this.initChart();
  }

  public initChart(): ChartState {
    this.displayChart = false;
    const chartDataDto = createEmptyChartData(DataType.Particle_Size, ChartDataSourceType.Psd, this.chartId);

    if (!this.psd || !this.psdReadings || !this.psdReadings.isValid || !this.psd.isValid) {
      return ReportingFactory.createChartStateWithData(ChartDataSourceType.Psd, chartDataDto);
    }

    const inputColumn: IChartDataDtoColumn = createChartDataColumn(
      ChartAxis.PrimaryValue,
      DataType.Cumulative_Percent_Weight_Retained,
      0,
      0,
    );

    chartDataDto.ChartDataColumns.push(inputColumn);

    const psdReadingsChartData = this.psdReadings.rows.filter((row) => row.rowType !== 'insert-row');

    const chartDataRows = psdReadingsChartData.map((row) =>
      createChartDataRowDto(row.rowData.SieveOpening, [row.rowData.CumulativePercentWeightRetained]),
    );
    chartDataDto.ChartDataSets.push(createChartDataSet(chartDataRows));

    this.displayChart = true;

    return ReportingFactory.createChartStateWithData(ChartDataSourceType.Psd, chartDataDto);
  }
}
