import { createReducer, on } from '@ngrx/store';
import {
  initialReferenceVariablesModuleState,
  ReferenceVariablesModuleState,
} from '@dunefront/common/modules/reference-variables/reference-variables-module.state';
import { loadReferenceVariablesAction } from './reference-variables.actions';
import { updateRowSuccess } from '../app.actions';

export const referenceVariablesReducer = createReducer<ReferenceVariablesModuleState>(
  initialReferenceVariablesModuleState,
  on(
    loadReferenceVariablesAction,
    (state): ReferenceVariablesModuleState => ({
      ...state,
      isLoading: true,
      isLoaded: false,
    }),
  ),
  on(updateRowSuccess, (state, action): ReferenceVariablesModuleState => {
    if (!action.affectedRows.referenceVariables) {
      return state;
    }
    return { ...state, isLoaded: true, isLoading: false, referenceVariables: action.affectedRows.referenceVariables };
  }),
);
