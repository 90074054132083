import { SettlingCalculatorDto, SettlingCalculatorResultDto } from './dto/settling-calculator.dto';
import { UpdateRowsWsAction } from '../../ws.action';
import { WsActionPropsFactory } from '../../common/ws-action/ws-action-props.factory';
import { DeveloperSettingsDto } from '../settings/dto/settingsDto';

export const SettlingCalculatorModuleName = 'SettlingCalculatorModule';

export enum SettlingCalculatorModuleActionTypes {
  UpdateSettlingCalculator = '[SettlingCalculatorModule] Update',
  CalculateSettlingAction = '[SettlingCalculatorModule] Calculate',
  UpdateResult = '[SettlingCalculatorModule] UpdateResult',
}

export interface LoadSettlingCalculatorActionResponse {
  settlingCalculatorDto: SettlingCalculatorDto;
  settlingCalculatorResultDto: SettlingCalculatorResultDto[];
}

export class UpdateSettlingCalculatorAction extends UpdateRowsWsAction<SettlingCalculatorDto> {
  public override readonly type = SettlingCalculatorModuleActionTypes.UpdateSettlingCalculator;
}

export class CalculateSettlingAction extends UpdateRowsWsAction<{ ScenarioId: number; StaticViscosity: number }> {
  public override readonly type = SettlingCalculatorModuleActionTypes.CalculateSettlingAction;

  constructor(
    action: { ScenarioId: number; StaticViscosity: number },
    public developerSettings: DeveloperSettingsDto,
  ) {
    super(WsActionPropsFactory.update([action], true, []));
  }
}

export type SettlingCalculatorModuleActions = UpdateSettlingCalculatorAction | CalculateSettlingAction;
