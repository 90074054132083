import { IError } from '../../../../common/common-state.interfaces';
import { ITableRow } from '../../../../common/common-grid.interfaces';
import { ScreenPipe } from '../../../pipes/lower-completion-pipes/pipes/screen-pipe';

export class ScreenValidation {
  public static validateScreenRow(error: IError<ScreenPipe>, row: ITableRow<ScreenPipe>): IError<ScreenPipe> {
    return {
      ...error,
      HandlingLength: this.ValidateScreenHandlingLength(row),
      JointLength: this.ValidateScreenJointLength(row),
      BasepipeOuterDiameter: this.ValidateScreenBasepipeOD(row),
      FilterInnerDiameter: this.ValidateScreenFilterID(row),
    };
  }

  private static ValidateScreenHandlingLength(row: ITableRow<ScreenPipe>): string {
    if (row.rowData.HandlingLength <= 0) {
      return 'Handling length must be greater than zero';
    }
    if (row.rowData.HandlingLength >= row.rowData.JointLength) {
      return 'Handling length must be less than joint length';
    }

    return '';
  }

  private static ValidateScreenJointLength(row: ITableRow<ScreenPipe>): string {
    if (row.rowData.JointLength <= 0) {
      return 'Joint length must be greater than zero';
    }
    if (row.rowData.JointLength <= row.rowData.HandlingLength) {
      return 'Joint length must be greater than handling length';
    }
    if (row.rowData.JointLength > row.rowData.Length) {
      return 'Joint length must be less than or equal to total length';
    }
    return '';
  }

  private static ValidateScreenBasepipeOD(row: ITableRow<ScreenPipe>): string {
    if (row.rowData.BasepipeOuterDiameter <= row.rowData.InnerDiameter) {
      return 'Basepipe OD must be greater than screen ID';
    }
    if (row.rowData.BasepipeOuterDiameter >= row.rowData.OuterDiameter) {
      return 'Basepipe OD must be less than screen OD';
    }

    return '';
  }

  private static ValidateScreenFilterID(row: ITableRow<ScreenPipe>): string {
    if (row.rowData.FilterInnerDiameter <= row.rowData.BasepipeOuterDiameter) {
      return 'Filter ID must be greater than basepipe OD';
    }
    if (row.rowData.FilterInnerDiameter >= row.rowData.OuterDiameter) {
      return 'Filter ID must be less than screen OD';
    }

    return '';
  }
}
