import { createSelector } from '@ngrx/store';
import { getValidatedMASPCalculatorModuleState } from '../calculators/masp-calculator/masp-calculator.selectors';
import { getValidatedResuspensionCalculatorModuleState } from '../calculators/resuspension-calculator/resuspension-calculator.selectors';
import { getFrictionCalculatorData } from '../calculators/friction-calculator/friction-calculator.selectors';
import { getFluidsCount, getSelectedFluidId } from './fluid.selectors';
import { getValidatedSettlingCalculatorModuleState } from '../calculators/settling-calculator/settling-calculator.selectors';
import { getPumpingScreensFluidIsUsed } from '../pumping/selectors/fluid-and-gravel-delete.selectors';
import { FluidAndGravelUsageCalculatorsDeps, FluidGravelUsageFinder } from '@dunefront/common/modules/validation/fluid-gravel-usage-finder';

export const getFluidAndGravelUsageCalculatorsDeps = createSelector(
  getValidatedMASPCalculatorModuleState,
  getValidatedResuspensionCalculatorModuleState,
  getFrictionCalculatorData,
  getValidatedSettlingCalculatorModuleState,

  (...[maspCalculator, resuspensionCalculator, frictionCalculator, settlingCalculator]): FluidAndGravelUsageCalculatorsDeps => ({
    frictionCalculator,
    maspCalculator,
    resuspensionCalculator,
    settlingCalculator: settlingCalculator.SettlingCalculator,
  }),
);

const getCalculatorsScreensFluidIsUsed = createSelector(getSelectedFluidId, getFluidAndGravelUsageCalculatorsDeps, (...[fluidId, deps]) =>
  FluidGravelUsageFinder.FindFluidInCalculators(fluidId, deps),
);

export const getScreensFluidIsUsed = createSelector(
  getCalculatorsScreensFluidIsUsed,
  getPumpingScreensFluidIsUsed,
  (...[calculatorsScreensFluidUsed, pumpingScreensFluidUsed]) => {
    const errorStrings = [...calculatorsScreensFluidUsed];
    if (pumpingScreensFluidUsed.length) {
      errorStrings.push(...pumpingScreensFluidUsed);
    }
    return errorStrings;
  },
);

export const isFluidDeletable = createSelector(getFluidsCount, getScreensFluidIsUsed, (fluidsCount, fluidErrors) => {
  if (fluidsCount <= 1) {
    return 'At least one fluid must remain in the list';
  }
  if (fluidErrors.length > 0) {
    return `This fluid can't be deleted as it is being used in the following screens: <br> -> ${fluidErrors.join('<br> -> ')}`;
  }
  return '';
});
