import { PumpingStateByRange } from './pumping-module.state';
import { FlowPathDescription } from './dto/well-fluid.dto';
import { ITableState } from '../../common/common-grid.interfaces';
import { WellFluid } from './model/well-fluid/well-fluid';
import { Pipe } from '../pipes/pipe';
import { PipeType } from '../../dto/pipe.dto';

export class PumpingModuleCalculations {
  public static applyPumpingCalculations(pumpingState: PumpingStateByRange, lowerCompletion: ITableState<Pipe>): PumpingStateByRange {
    return {
      ...pumpingState,
      wellFluids: PumpingModuleCalculations.updateWellFluidsWashPipeAndLowerAnnulusTopMD(pumpingState.wellFluids, lowerCompletion),
    };
  }

  private static updateWellFluidsWashPipeAndLowerAnnulusTopMD(
    wellFluids: ITableState<WellFluid>,
    lowerCompletion: ITableState<Pipe>,
  ): ITableState<WellFluid> {
    const gravelPackPackerBottomMD = lowerCompletion.rows.find((pipe) => pipe.rowData.PipeType === PipeType.Gravel_Pack_Packer)?.rowData
      .BottomMD;

    if (gravelPackPackerBottomMD == null) {
      return wellFluids;
    }

    const firstWashpipe = wellFluids.rows.find((row) => row.rowData.FlowPathDescription === FlowPathDescription.Washpipe);
    const firstLowerAnnulus = wellFluids.rows.find((row) => row.rowData.FlowPathDescription === FlowPathDescription.Lower_Annulus);

    const rows = wellFluids.rows.map((row) => {
      if (row.rowData.Id === firstWashpipe?.rowData.Id) {
        return { ...row, rowData: { ...row.rowData, TopMD: gravelPackPackerBottomMD } };
      } else if (row.rowData.Id === firstLowerAnnulus?.rowData.Id) {
        return { ...row, rowData: { ...row.rowData, TopMD: gravelPackPackerBottomMD } };
      } else {
        return row;
      }
    });
    return { ...wellFluids, rows };
  }
}
