import { UnitConverter } from '../../converter.interfaces';
import { MassFlowRateUnit } from '../../../dto/unit-system.dto';

export class MassFlowRateConverter extends UnitConverter {
  public static symbols: Array<string> = ['', 'kg/s', 'kg/min', 'lbs/s', 'lbs/min'];

  public static unit = MassFlowRateUnit;

  public static fromSi(value: number, unit: MassFlowRateUnit): number {
    switch (unit) {
      case MassFlowRateUnit.Kilogram_per_second: {
        return value;
      }
      case MassFlowRateUnit.Kilogram_per_minute: {
        return value * 60;
      }
      case MassFlowRateUnit.Pound_per_second: {
        return value * 2.204622622;
      }
      case MassFlowRateUnit.Pound_per_minute: {
        return value * 132.2773573;
      }
      default: {
        throw new TypeError('Unknown Mass Flow Rate Unit:' + unit);
      }
    }
  }

  public static toSi(value: number, unit: MassFlowRateUnit): number {
    switch (unit) {
      case MassFlowRateUnit.Kilogram_per_second: {
        return value;
      }
      case MassFlowRateUnit.Kilogram_per_minute: {
        return value / 60;
      }
      case MassFlowRateUnit.Pound_per_second: {
        return value / 2.204622622;
      }
      case MassFlowRateUnit.Pound_per_minute: {
        return value / 132.2773573;
      }
      default: {
        throw new TypeError('Unknown Mass Flow Rate Unit:' + unit);
      }
    }
  }

  public static getSymbol(unit: MassFlowRateUnit): string {
    if (unit == null) {
      return '';
    }
    return this.symbols[unit];
  }
}
