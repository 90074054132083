import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { getFluidsSelectData } from '../../../../../+store/fluid/fluid.selectors';
import { Store } from '@ngrx/store';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { RouterHelperService } from '../../../../../shared/services/router-helper.service';
import { Fluid } from '@dunefront/common/modules/fluid/model/fluid';
import { ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';

@Component({
  selector: 'app-fluid-header',
  templateUrl: './fluid-header.component.html',
  styleUrls: ['./fluid-header.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FluidHeaderComponent {
  public data$ = this.store.select(getFluidsSelectData);

  public UnitType = UnitSystem;

  @Input() public isReadOnly = false;

  constructor(
    private store: Store,
    private routerHelperService: RouterHelperService,
  ) {}

  public async changeSelectedFluid(event: ObjectChangeProp<Fluid>): Promise<void> {
    const fluidId = event.value as number;
    await this.routerHelperService.navigateToFluid(fluidId);
  }
}
