import { Caliper } from './caliper';
import { GeneralCalculations } from '../../../../common/general.calculations';

export interface CalculatedCaliper {
  TopMD: number;
  Diameter: number;
}

export class CaliperDataHelper {
  public static calculateCaliperAveraged(caliperData: Caliper[], caliperTolerance: number): CalculatedCaliper[] {
    const result: CalculatedCaliper[] = [];

    if (caliperData.length < 2) {
      return result;
    }

    if (caliperData.every((cd) => cd.Diameter === caliperData[0].Diameter)) {
      // if all diameters are the same don't calculate average as it will break numbers precision and cause wrong chart rendering
      result.push({ TopMD: caliperData[0].TopMD, Diameter: caliperData[0].Diameter });
    } else {
      let sectionStartIndex = 0;
      let totalLength = 0;
      let volume = 0;

      for (let i = 0; i <= caliperData.length - 2; i++) {
        const length = caliperData[i + 1].TopMD - caliperData[i].TopMD;
        totalLength += length;
        volume += GeneralCalculations.CircleArea(caliperData[i].Diameter) * length;

        if (
          i === caliperData.length - 2 ||
          Math.abs(caliperData[i + 1].Diameter - caliperData[sectionStartIndex].Diameter) > caliperTolerance
        ) {
          //There is a change in diameter so create a new section
          result.push({
            TopMD: caliperData[sectionStartIndex].TopMD,
            Diameter: GeneralCalculations.EquivalentDiameter(volume / totalLength),
          });

          //Reset averaging parameters for new section
          sectionStartIndex = i + 1;
          totalLength = 0;
          volume = 0;
        }
      }
    }

    // add a point for the bottom of the caliper
    result.push({ TopMD: caliperData[caliperData.length - 1].TopMD, Diameter: result[result.length - 1].Diameter });

    return result;
  }
}
