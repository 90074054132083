<app-generic-modal [title]="title" [modalButtonsConfigs]="modalButtonsConfigs">
  <div class="grid-form-container no-padding">
    <label for="importTemplateNameInput">Template Name</label>
    <app-input
      id="importTemplateNameInput"
      [isUiLockable]="false"
      [errorMessage]="templateNameError"
      [isTextInput]="true"
      [value]="templateName"
      [align]="'left'"
      [maxWidth]="300"
      [ngStyle]="{ 'width.px': 300 }"
      [triggerOnKeyPress]="true"
      [dataCy]="'edit-import-template-name-input'"
      (primitiveValueChanged)="onTemplateNameChange($event.value)"
    ></app-input>
    <app-radio-group
      *ngIf="mode === EditTemplateModalMode.create && !electronService.isElectronApp"
      class="col-span-2"
      dataCy="edit-template-modal"
      [isUiLockable]="false"
      [items]="radioItems"
      [value]="saveMode"
      layout="vertical"
      id="edit-import-template-modal-radio"
      (primitiveValueChanged)="changeSaveMode($event.value)"
    ></app-radio-group>
  </div>
</app-generic-modal>
