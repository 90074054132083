<app-grid
  *ngIf="height > 0"
  [isFirstRowInsertingAllowed]="true"
  [isFirstRowDeleteAllowed]="true"
  [minRowCount]="1"
  [dataSource]="zoneData"
  [gridConfig]="gridConfig"
  [columns]="gridConfig.columns"
  [height]="height"
  [headerTopRowHeight]="30"
  [showCrudControls]="false"
  [isMergingOfRowsEnabled]="true"
  (selectedRowsChanged)="selectedRowsChanged.emit($event)"
  dataCy="reservoir-grid"
></app-grid>
