import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { CommonDbType, WritableCommonDbType } from '@dunefront/common/dto/common-dto.interfaces';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { IRadioItem } from '@dunefront/common/common/radio.helpers';
import { IChartTemplateDto } from '@dunefront/common/dto/chart-templates.dto';
import { defaultModalCancelButton, modalButton, ModalButtonConfig } from '../../modals/generic-modal/generic-modal.component';
import { ElectronService } from '../../../shared/services/electron-service/electron.service';
import {
  closeEditChartTemplateModalAction,
  createChartTemplate,
  updateChartTemplateAction,
} from '../../../+store/reporting/reporting.actions';
import { EnumHelpers } from '@dunefront/common/utils/enum.helpers';
import { ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import { ChartTemplateHelpers } from '@dunefront/common/common/templates/chart-template.helpers';
import { IError } from '@dunefront/common/common/common-state.interfaces';

@Component({
  selector: 'app-edit-chart-template-modal',
  templateUrl: './edit-chart-template-modal.component.html',
  styleUrls: ['./edit-chart-template-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditChartTemplateModalComponent implements OnDestroy {
  public readonly modulesItems = EnumHelpers.EnumToISelectItemArray(ModuleType, false, [
    ModuleType.Simulate,
    ModuleType.Simulate_CH,
    ModuleType.Evaluate,
    ModuleType.Trend_Analysis,
  ]);
  public readonly dbItems: IRadioItem<WritableCommonDbType>[] = [
    { value: 'User', text: 'Save to my settings' },
    { value: 'Organization', text: 'Save to organization settings' },
  ];
  public readonly modalButtonsConfigs: ModalButtonConfig[] = [
    defaultModalCancelButton((): void => this.onCancelClick()),
    modalButton('Save', (): void => this.onSaveClick(), 'edit-chart-template-save-btn', 'primary', true, false),
  ];

  public selectedTemplate: IChartTemplateDto;
  public errors: IError<IChartTemplateDto> = {};

  private subscription = new Subscription();
  private allTemplates: IChartTemplateDto[] = [];

  constructor(
    private readonly store: Store,
    private readonly ref: DynamicDialogRef,
    public readonly electronService: ElectronService,
    config: DynamicDialogConfig<EditChartTemplateModalComponentPayload>,
    actions$: Actions,
  ) {
    const template = config.data?.template;
    if (template == null) {
      throw new Error('Template is not defined!');
    }

    this.selectedTemplate = template;
    this.allTemplates = config.data?.allTemplates ?? [];

    this.validate();
    this.subscription.add(actions$.pipe(ofType(closeEditChartTemplateModalAction)).subscribe(() => this.ref.close()));
  }

  public get isCreateNew(): boolean {
    return this.selectedTemplate.Id == null || this.selectedTemplate.Id < 0;
  }

  public get title(): string {
    return this.isCreateNew ? 'Save template' : 'Edit template';
  }

  public onDbChange(mode: CommonDbType): void {
    this.selectedTemplate.Type = mode;
  }

  public onLabelsChange(event: string): void {
    this.selectedTemplate.Labels = event;
    this.validate();
  }

  public onModulesChange(): void {
    this.validate();
  }

  public onTemplateNameChange(event: string): void {
    this.selectedTemplate.Name = event.trim();
    this.validate();
  }

  public onSaveClick(): void {
    if (hasErrors(this.errors)) {
      return;
    }

    const template = { ...this.selectedTemplate };
    const action = this.isCreateNew ? createChartTemplate({ template }) : updateChartTemplateAction({ template });

    this.store.dispatch(action);
  }

  public onCancelClick(): void {
    this.ref.close();
  }

  private validate(): void {
    const errors: IError<IChartTemplateDto> = {};

    const newName = this.selectedTemplate.Name;
    if (!newName) {
      errors.Name = 'Please enter template name';
    } else if (this.getUsedNames().includes(newName)) {
      const dbName = this.selectedTemplate.Type.toLocaleLowerCase();
      errors.Name = `Template with the name: "${newName}" ( ${dbName} settings ) already exists`;
    }

    if (this.selectedTemplate.Modules.length === 0) {
      errors.Modules = 'Select at least one module';
    }

    const saveBtn = this.modalButtonsConfigs.find((btn) => btn.text === 'Save');
    if (saveBtn) {
      saveBtn.disabled = hasErrors(errors);
    }

    this.errors = errors;
  }

  private getUsedNames(): string[] {
    return this.allTemplates
      .filter(
        (template) =>
          template.Type === this.selectedTemplate.Type &&
          template.Id !== this.selectedTemplate?.Id &&
          ChartTemplateHelpers.findMatchingTemplateId(this.selectedTemplate.Modules, [template]),
      )
      .map((chart) => chart.Name);
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

export interface EditChartTemplateModalComponentPayload {
  template: IChartTemplateDto;
  allTemplates?: IChartTemplateDto[];
}

const hasErrors = (errors: IError<any>): boolean => {
  for (const error in errors) {
    if (error != null && error.length > 0) {
      return true;
    }
  }
  return false;
};
