import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { IFile } from '@dunefront/common/dto/file.dto';
import { copyFileReturnList, loadAllFilesAndFoldersSuccess } from '../../../../+store/file-manager/file-manager.actions';
import { BaseFileActionDialogComponent } from '../base-file-action.dialog.component';
import { Store } from '@ngrx/store';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Actions, ofType } from '@ngrx/effects';
import { FileErrorHelper } from '../file-error.helper';
import {
  CY_BUTTON_OK,
  defaultModalCancelButton,
  defaultModalConfirmButton,
  ModalButtonConfig,
} from '../../../../common-modules/modals/generic-modal/generic-modal.component';

@Component({
  selector: 'app-copy-dialog',
  templateUrl: './copy.dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CopyDialogComponent extends BaseFileActionDialogComponent implements OnInit {
  public isSaveAs = false;
  public dialogLabel: string;

  public modalButtonsConfigs: ModalButtonConfig[] = [];

  constructor(activeModal: DynamicDialogRef, config: DynamicDialogConfig, store: Store, cdRef: ChangeDetectorRef, actions$: Actions) {
    super(activeModal, config, store, cdRef);
    this.file = config.data.file;
    this.isSaveAs = config.data.isSaveAs;
    this.subscription.add(actions$.pipe(ofType(loadAllFilesAndFoldersSuccess)).subscribe((_) => this.activeModal.close()));

    this.dialogLabel = this.isSaveAs
      ? `Save file in "${this.file.Folder.slice(-1)[0] || 'personal'}" folder as: `
      : `Copy ${this.file.Name} to?`;
  }

  public ngOnInit(): void {
    this.fileName = { ...this.fileName, targetName: this.file.Name.trim() + ' - Copy' };
    this.validateCopyAction(this.file, this.fileName.targetName);

    this.modalButtonsConfigs = [
      defaultModalCancelButton((): void => this.cancelClicked(), 'Cancel', 'button-cancel'),
      defaultModalConfirmButton(
        (): void => this.okClicked(),
        'OK',
        CY_BUTTON_OK,
        (this.file.Name === this.fileName.targetName && !this.isSaveAs) || !!this.fileName.error.targetName,
      ),
    ];
  }

  public textValueChanged(textValue: string): void {
    this.validateCopyAction(this.file, textValue.trim());
  }

  public okClicked(): void {
    if (this.fileName.error.targetName !== '') {
      return;
    }
    this.store.dispatch(
      copyFileReturnList({
        source: this.file,
        target: { ...this.file, Name: this.fileName.targetName },
        isSaveAs: this.isSaveAs,
      }),
    );
  }

  private validateCopyAction(targetFolder: IFile, newFileName: string): void {
    const fileNameList = this.getNamesForValidation(targetFolder.Repository, [...targetFolder.Folder], this.file.FileType);

    this.fileName = FileErrorHelper.validateFileFolderName(
      {
        ...this.fileName,
        targetName: newFileName,
      },
      fileNameList,
    );
    this.errorMessage = this.fileName.error.targetName;
    const okButton = this.modalButtonsConfigs.find((conf) => conf.dataCy === CY_BUTTON_OK);
    if (okButton) {
      okButton.disabled = (this.file.Name === this.fileName.targetName && !this.isSaveAs) || !!this.errorMessage;
    }
  }

  public get modalTitle(): string {
    return this.isSaveAs ? 'Save file as' : 'Copy File';
  }
}
