import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, ViewEncapsulation } from '@angular/core';
import { DbDependentComponent } from '../../../../common-modules/db-connection/db-dependent.component';
import { Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { getChartState } from '../../../../+store/reporting/reporting.selectors';
import * as reportingActions from '../../../../+store/reporting/reporting.actions';
import { AllowedXYShiftAxis } from '../../../../common-modules/chart/chart-component-helpers/chart-types';
import { ChartDataSourceType } from '@dunefront/common/modules/reporting/dto/chart.dto';
import {
  ChartTimeVolMode,
  GetChartDataParams,
  GetChartDataRequestType,
} from '@dunefront/common/modules/reporting/reporting-module.actions';
import { ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import { getStorageFilesArray } from '../../../../+store/data-storage/data-storage.selectors';
import { ChartControllerConfig, ChartControllerFetchDataPayload } from '../../../../common-modules/chart/chart-controller.component';
import { map } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { ChartLoadingStatus } from '../../../../+store/reporting/model/reporting.factory';
import { PanelHelpMode } from '../../../../shared/components/help-button/help-buton.component';
import { DrawableContentProviderComponent } from '../../../../shared/services/drawable-registry.service';

const TREND_ANALYSIS_CHART_NAME = 'Trend Analysis';

class Scenario {}

@Component({
  selector: 'app-trend-analysis-chart',
  templateUrl: './trend-analysis-chart.component.html',
  styleUrls: ['./trend-analysis-chart.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrendAnalysisChartComponent extends DbDependentComponent implements OnDestroy, DrawableContentProviderComponent {
  public readonly chartDisplayName = TREND_ANALYSIS_CHART_NAME;

  @Input() public drawableProviderId?: string;
  @Input() public noHeader = false;

  public AllowedXYShiftAxis = AllowedXYShiftAxis;
  public chartState$ = this.store.select(getChartState);
  public storageFiles$ = this.store.select(getStorageFilesArray);
  public PanelHelpMode = PanelHelpMode;

  public trendAnalysisErrorType$ = combineLatest([this.chartState$, this.storageFiles$]).pipe(
    map(([chartState, storageFiles]): TrendAnalysisChartErrorMessageType => {
      if (storageFiles.length === 0) {
        return 'no-import-data';
      }
      if (
        chartState.chartLoadingStatus === ChartLoadingStatus.loaded &&
        (!chartState.chartData || chartState.chartData.ChartDataColumns.length === 0)
      ) {
        return 'pressure-data-not-defines';
      }
      return 'no-error';
    }),
  );

  public readonly config: ChartControllerConfig = {
    loadData: (payload) => this.fetchData(payload),
    resetAxisLimitsOnRangeChange: true,
  };

  constructor(
    store: Store,
    cdRef: ChangeDetectorRef,
    protected route: ActivatedRoute,
  ) {
    super(store, cdRef);
  }

  public get chartId(): number {
    if (this.chartIds$.value == null) {
      throw new Error('chartIds$ not defined!');
    }

    return this.chartIds$.value.trendAnalysisChartId;
  }

  private fetchData(payload: ChartControllerFetchDataPayload): void {
    if (this.currentScenarioId === undefined || this.currentRange === undefined) {
      return;
    }

    const { src, requestType, argumentStart, argumentEnd, isPrimaryArgument, isPrimaryArgumentRelative } = payload;

    const getChartDataParams: GetChartDataParams = {
      chartId: this.chartId,
      dataSourceType: ChartDataSourceType.ChartSourceTrendAnalysis,
      timeVolMode: ChartTimeVolMode.time,
      rangeId: this.currentRangeId,
      moduleType: ModuleType.Trend_Analysis,
      argumentStart,
      argumentEnd,
      isPrimaryArgument,
      isPrimaryArgumentRelative,
      requestType,
      isOptimizeEvaluationChart: false,
    };

    this.store.dispatch(
      reportingActions.getTimeVolChartData({
        src: 'trend-analysis.component - fetchData ' + src,
        getChartDataParams,
      }),
    );
  }

  private fetchInitialChartData(): void {
    this.fetchData({ src: 'fetchInitialChartData', requestType: GetChartDataRequestType.Initial });
  }

  public override ngOnDestroy(): void {
    this.store.dispatch(reportingActions.clearReportingChartData({ chartDataSourceType: ChartDataSourceType.ChartSourceTrendAnalysis }));
    super.ngOnDestroy();
  }

  public override onScenarioLoaded(currentScenario: Scenario): void {
    this.fetchInitialChartData();
  }
}

export type TrendAnalysisChartErrorMessageType = 'no-error' | 'no-import-data' | 'pressure-data-not-defines';
