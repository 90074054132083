<div class="app-tools-panel-container">
  <app-user-panel></app-user-panel>
</div>
<div class="filter-bar-container">
  <app-file-filter></app-file-filter>
</div>
<span *ngIf="user$ | async as user" class="home-header"> Hi {{ user.name }}, what would you like to do? </span>
<ng-container *ngIf="plusContextMenu">
  <div class="plus-button" data-cy="home-new-project" (click)="cm.show($event); $event.stopPropagation()">
    <span>+</span>
  </div>
  <p-contextMenu #cm [model]="plusContextMenu" appendTo="body"></p-contextMenu>
</ng-container>
