import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { EnumHelpers } from '@dunefront/common/utils/enum.helpers';
import { ImportColumnType } from '@dunefront/common/modules/data-storage/dto/import-template/import-template.dto';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { GaugeMeasurementType } from '@dunefront/common/dto/running-string-pipe.dto';
import { UnitHelpers } from '../../../../common-modules/units/unit-helpers';
import { ISelectItem } from '@dunefront/common/common/select.helpers';
import { DataType } from '@dunefront/common/dto/data-storage';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { getStorageColumnNames } from '../../../../+store/data-storage/data-storage.selectors';
import {
  IColNameWithId,
  isValidatedModuleState,
  ValidatedImportColumnDto,
} from '@dunefront/common/modules/data-storage/data-storage-module.state';
import { rationalizeString } from '@dunefront/common/common/helpers';
import { ModalService } from '../../../../common-modules/modals/modal.service';
import { getDefaultUnitSystem } from '../../../../+store/import-data/model/col-config';
import { updateEquationColumnFields } from '../../../../+store/equation/equation.actions';
import { EquationColumn } from '@dunefront/common/modules/equation/equation-column';
import { ArgumentType } from '@dunefront/common/modules/data-storage/dto/import-column.dto';
import dayjs from 'dayjs';
import {
  changeProp,
  DataSourceValue,
  getChangeProp,
  ObjectChangeProp,
  PrimitiveChangeValue,
} from '@dunefront/common/common/common-state.interfaces';
import { ImportOrEquationColumn } from '@dunefront/common/modules/data-storage/data-storage.validation';
import { updateImportColumnAction } from '../../../../+store/data-storage/data-storage.actions';

@Component({
  selector: 'app-gauge-data-column-props',
  templateUrl: './gauge-data-column-props.component.html',
  styleUrls: ['./gauge-data-column-props.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GaugeDataColumnPropsComponent implements OnChanges {
  @Input() public isAddColumnMode = false;

  @Input() public selectedColumn: ImportOrEquationColumn | undefined;

  public importColumnTypeItems = EnumHelpers.EnumToISelectItemArray(ImportColumnType) as unknown as ISelectItem<DataType>[];
  public unitSystemItems = EnumHelpers.EnumToISelectItemArray(UnitSystem);

  public unitSymbolsItems: ISelectItem<number>[] = [];

  public UnitSystem = UnitSystem;
  public startTime: Date | undefined;
  private columnNames: IColNameWithId[] = [];
  private subscription = new Subscription();

  constructor(
    private store: Store,
    private modalService: ModalService,
    private cdRef: ChangeDetectorRef,
  ) {
    this.subscription.add(
      this.store.select(getStorageColumnNames).subscribe((colNames) => {
        this.columnNames = colNames;
      }),
    );
  }

  public get measurementTypeError(): string {
    return isValidatedModuleState(this.selectedColumn) ? this.selectedColumn.error?.MeasurementType ?? '' : '';
  }

  public get startDateError(): string {
    return isValidatedModuleState(this.selectedColumn) ? this.selectedColumn.error?.StartDate ?? '' : '';
  }

  public get isUnitSystemEnabled(): boolean {
    return this.selectedColumn?.DataType === DataType.Other;
  }

  public get isUnitSymbolsEnabled(): boolean {
    return this.selectedColumn != null && this.selectedColumn.UnitSystem >= 0 && this.selectedColumn.UnitSystem !== UnitSystem.None;
  }

  public get isBottomhole(): boolean {
    if (!this.selectedColumn) {
      return false;
    }

    return (
      this.selectedColumn.IsXAxis === ArgumentType.Value &&
      (this.selectedColumn.DataType === DataType.Bottomhole_Pressure || this.selectedColumn.DataType === DataType.Bottomhole_Temperature)
    );
  }

  public get isXAxis(): boolean {
    return (this.selectedColumn != null && this.selectedColumn?.IsXAxis !== ArgumentType.Value) ?? false;
  }

  public get measurementTypes(): ISelectItem<GaugeMeasurementType>[] {
    const noneType = [GaugeMeasurementType.None];
    const defaultTypes = [GaugeMeasurementType.Internal, GaugeMeasurementType.External];

    return EnumHelpers.EnumToISelectItemArray(GaugeMeasurementType, false, this.isBottomhole ? defaultTypes : noneType);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.setUnitSymbolItems();

    if (changes.selectedColumn != null && this.selectedColumn != null) {
      if (this.selectedColumn.DataType === DataType.Time && this.selectedColumn.StartDate != null) {
        this.startTime = dayjs.unix(this.selectedColumn.StartDate).toDate();
      } else {
        this.startTime = undefined;
      }
    }

    if (this.selectedColumn) {
      this.resetColumnsPropertiesWhenLocked(this.selectedColumn);
    }
  }

  public onStartTimeChanged(selectedColumn: ImportOrEquationColumn | undefined, event: PrimitiveChangeValue<Date>): void {
    const value = dayjs(event.value).unix();
    const change: ObjectChangeProp<ImportOrEquationColumn> = {
      key: 'StartDate',
      value,
      shouldResetResults: event.shouldResetResults,
    };
    if (selectedColumn && selectedColumn.StartDate !== value) {
      const newColumn = changeProp(selectedColumn, change);
      this.store.dispatch(updateImportColumnAction(newColumn, change));
    }
  }

  public onDataTypeChanged(selectedColumn: ImportOrEquationColumn, event: ObjectChangeProp<ImportOrEquationColumn>): void {
    if (event.key !== 'DataType') {
      throw new Error('Not DataType change key!');
    }
    const dataType = event.value as DataType;

    const newColumn: ImportOrEquationColumn = {
      ...selectedColumn,
      UnitSystem: getDefaultUnitSystem(dataType as unknown as ImportColumnType),
      DataType: dataType,
      Unit: 1,
    };

    if (!this.validate(selectedColumn, event)) {
      return;
    }

    if (this.isAddColumnMode) {
      this.store.dispatch(updateEquationColumnFields({ changes: newColumn as EquationColumn }));
    } else {
      this.store.dispatch(updateImportColumnAction(newColumn, event));
    }
  }

  public onValueChanged(
    selectedColumn: ImportOrEquationColumn,
    change: ObjectChangeProp<ImportOrEquationColumn>,
    isUndoEnabled = true,
  ): void {
    if (!this.validate(selectedColumn, change)) {
      return;
    }
    if (this.isAddColumnMode) {
      this.store.dispatch(updateEquationColumnFields({ changes: { [change.key]: change.value } }));
    } else if ('error' in selectedColumn) {
      const row = changeProp(selectedColumn, change);
      this.store.dispatch(updateImportColumnAction(row, change, isUndoEnabled));
    }
  }

  private setUnitSymbolItems(): void {
    this.unitSymbolsItems =
      !this.selectedColumn || this.selectedColumn.UnitSystem === null
        ? []
        : UnitHelpers.getUnitSymbolSelectItems(this.selectedColumn.UnitSystem);
  }

  private validate(selectedColumn: ImportOrEquationColumn, change: ObjectChangeProp<ImportOrEquationColumn>): boolean {
    let errorMessage = '';
    let isValid = true;

    if (change.key === 'ColumnName' && this.isEmpty(change.value)) {
      isValid = false;
    } else if (change.key === 'ColumnName') {
      errorMessage = this.validateColumnName(selectedColumn, change.value);
      isValid = !errorMessage;
    } else if (change.key === 'DataType' || change.key === 'UnitSystem') {
      errorMessage = this.validateTime(selectedColumn, change);
      isValid = !errorMessage;
    }

    // show alert if message provided
    if (errorMessage) {
      this.modalService.showAlert(errorMessage).then();
    }

    // restore previous value if not valid
    if (!isValid && change.key in selectedColumn) {
      const currentValue = selectedColumn[change.key];
      this.selectedColumn = changeProp(selectedColumn, change);
      this.cdRef.detectChanges();
      change.value = currentValue;
      this.selectedColumn = changeProp(selectedColumn, change);
      this.cdRef.markForCheck();
    }

    return isValid;
  }

  private validateTime(
    selectedColumn: ImportOrEquationColumn,
    change: ObjectChangeProp<ValidatedImportColumnDto | EquationColumn>,
  ): string {
    if (
      ((change.key === 'DataType' && change.value === DataType.Time) ||
        (change.key === 'UnitSystem' && change.value === UnitSystem.Time)) &&
      selectedColumn.IsXAxis === ArgumentType.Value
    ) {
      return 'Only one time column (x-axis) can be defined per file.';
    }

    return '';
  }

  private validateColumnName(
    selectedColumn: ImportOrEquationColumn,
    value: DataSourceValue<ValidatedImportColumnDto | EquationColumn>,
  ): string {
    const selectedColId = selectedColumn.Id;

    const foundSimilarColumns = this.columnNames
      .filter((col) => col.colId !== selectedColId)
      .map((col) => rationalizeString(col.name))
      .includes(rationalizeString(value + ''));

    if (foundSimilarColumns) {
      return 'The column name already exists.';
    }

    return '';
  }

  private isEmpty(value: DataSourceValue<ValidatedImportColumnDto | EquationColumn>): boolean {
    return value?.toString().trim().length === 0;
  }

  private resetColumnsPropertiesWhenLocked(selectedColumn: ImportOrEquationColumn): void {
    if (this.isBottomhole) {
      return;
    }

    if (selectedColumn.MeasurementType !== GaugeMeasurementType.None) {
      this.onValueChanged(selectedColumn, getChangeProp('MeasurementType', GaugeMeasurementType.None, false));
    }
    if (selectedColumn.MeasuredDepth !== 0) {
      this.onValueChanged(selectedColumn, getChangeProp('MeasuredDepth', 0, false));
    }
  }
}
