import { IReportTableColumnConfig } from '../common-report-data-helpers';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { ColumnType } from '../../../../../components/grid/grid.interfaces';
import { Gravel } from '@dunefront/common/modules/gravel/model/gravel';

export const gravelsProperties: IReportTableColumnConfig<Gravel>[] = [
  { colId: 'MeshSize', headerText: 'Mesh Size', unitSystem: UnitSystem.None, type: ColumnType.text },
  { colId: 'MeanDiameter', headerText: 'Mean Diameter', unitSystem: UnitSystem.Diameter, type: ColumnType.number },
  {
    colId: 'AbsoluteDensity',
    headerText: 'Absolute Density',
    unitSystem: UnitSystem.Solid_Density,
    type: ColumnType.number,
  },
  { colId: 'BulkDensity', headerText: 'Bulk Density', unitSystem: UnitSystem.Bulk_Density, type: ColumnType.number },
  {
    colId: 'PackPermeability',
    headerText: 'Pack Permeability',
    reportingHeaderName: 'Pack Perm.',
    unitSystem: UnitSystem.Permeability,
    type: ColumnType.number,
  },
  {
    colId: 'OroskarAndTurianCoefficient',
    headerText: 'Oroskar & Turian Coefficient',
    reportingHeaderName: 'O&T Coeff.',
    unitSystem: UnitSystem.None,
    type: ColumnType.number,
  },
  {
    colId: 'AngleOfRepose',
    headerText: 'Angle of Repose',
    unitSystem: UnitSystem.Angle,
    type: ColumnType.number,
  },
];
