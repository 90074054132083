import { Routes } from '@angular/router';
import { LeakoffCoefficientComponent } from './leakoff-coefficient.component';
import { LeakoffCoefficientCalculatorComponent } from './leakoff-coefficient-calculator/leakoff-coefficient-calculator.component';

export const RouteModuleLeakoffCoefficient = 'leakoff-coefficient';

export const LeakoffCoefficientRoutes: Routes = [
  {
    path: RouteModuleLeakoffCoefficient,
    component: LeakoffCoefficientComponent,
    data: {
      breadcrumb: 'Leakoff Coefficient Calculator',
    },
    children: [
      {
        path: '',
        component: LeakoffCoefficientCalculatorComponent,
      },
    ],
  },
];
