import PptxGenJS from 'pptxgenjs';
import TextPropsOptions = PptxGenJS.TextPropsOptions;
import SlideNumberProps = PptxGenJS.SlideNumberProps;
import TableProps = PptxGenJS.TableProps;

export interface NumericPositionProps {
  x: number;
  y: number;
  w: number;
  h: number;
}

// LAYOUT_16x9 - 10" x 5.625"
export const slideWidth = 10; // inches
export const slideHeight = 5.625; // inches

const sideMargin = 0.3;
const topBottomMargin = 0.3;

export const titleHeight = 0.45;
export const subTitleHeight = 0.35;
export const titlePosition: NumericPositionProps = {
  x: sideMargin,
  y: 0,
  w: slideWidth - 2 * sideMargin,
  h: titleHeight,
};

export const subTitlePosition: NumericPositionProps = {
  x: sideMargin,
  y: titleHeight,
  w: slideWidth - 2 * sideMargin,
  h: subTitleHeight,
};

const contentY = subTitlePosition.y + subTitlePosition.h;

export const contentPosition: NumericPositionProps = {
  x: sideMargin,
  y: contentY,
  w: slideWidth - 2 * sideMargin,
  h: slideHeight - (contentY + topBottomMargin),
};

export const pptImageAspectRatio = contentPosition.w / contentPosition.h;

export const reportTitlePosition: NumericPositionProps = {
  x: sideMargin,
  y: sideMargin,
  w: slideWidth - 2 * sideMargin,
  h: slideHeight - 2 * sideMargin,
};

export const grayTextColor = '888888';
export const baseTextProps: TextPropsOptions = {
  fontSize: 10,
  fontFace: 'Verdana',
  margin: 0,
};

export const slideTitleFontSize = 12;
export const slideSubTitleFontSize = 10;

const slideNumberWidth = 0.5;
const slideNumberHeight = 0.3;
export const slideNumberProps: SlideNumberProps = {
  ...baseTextProps,
  fontSize: 8,
  x: slideWidth - slideNumberWidth,
  y: slideHeight - slideNumberHeight,
  w: slideNumberWidth,
  h: slideNumberHeight,
  valign: 'bottom',
  align: 'right',
  color: grayTextColor,
  // override baseTextProps.margin back to default values
  // SlideNumberProps.margin uses pixels instead of inches, but is meant to bo converted to inches in v4
  margin: undefined,
};

// Two custom settings used below:
//   autoPageCharWeight: -0.5,
//   autoPageLineWeight: 0,
// autoPageCharWeight used because pptxGenJS calculations don't work very well. Usually the line breaks much faster
// than pptxGenJS predicts. That's why such security factor is added.
export const defaultTableProps: TableProps = {
  ...baseTextProps,
  ...contentPosition,
  h: undefined,
  w: undefined,
  fontSize: 10,
  align: 'left',
  valign: 'middle',
  autoPage: true,
  autoPageRepeatHeader: true,
  autoPageHeaderRows: 2,
  autoPageSlideStartY: contentPosition.y,
  autoPageCharWeight: -0.5,
  autoPageLineWeight: 0,
  margin: 0.05,
};

export const keyValueTableProps: TableProps = {
  ...baseTextProps,
  ...contentPosition,
  colW: [2.5, 6],
  rowH: 0.3,
  margin: [0.05, 0.05, 0.05, 0],
};

export const twoColumnsLayout = {
  keyFieldWidth: 2,
  columnsGap: 0.2,
  leftColumnMaxRowsCount: 15,
};
