import { LoadWellActionResponse } from '@dunefront/common/modules/well/well-module.actions';
import {
  createTableState,
  getRowsForCalculations,
  getRowsWithoutInsertRow,
  ITableState,
} from '@dunefront/common/common/common-grid.interfaces';
import { initialWellModuleState, ValidatedWellModuleState, WellModuleState } from '@dunefront/common/modules/well/well-module.state';
import { CrudResponse } from '@dunefront/common/modules/common.actions';
import { SurveyCalculations, SurveyDto } from '@dunefront/common/modules/well/dto/survey/survey.dto';
import { CasingPipeDto } from '@dunefront/common/modules/well/dto/casing/casing-pipe.dto';
import { CaliperDto } from '@dunefront/common/modules/well/dto/caliper/caliper.dto';
import { ZoneDto } from '@dunefront/common/modules/well/dto/zone/zone.dto';
import { LoadSettingsActionResponse } from '@dunefront/common/modules/settings/settings-module.actions';
import { deleteObjectsFromArray, updateObjectsInArray } from '@dunefront/common/common/state.helpers';
import { WellFactory } from '@dunefront/common/modules/well/model/well.factory';
import { SurveyFactory } from '@dunefront/common/modules/well/model/survey/survey.factory';
import { Survey } from '@dunefront/common/modules/well/model/survey/survey';
import { CasingPipesFactory } from '@dunefront/common/modules/pipes/casing-pipes/casing-pipes.factory';
import { Pipe } from '@dunefront/common/modules/pipes/pipe';
import { Caliper } from '@dunefront/common/modules/well/model/caliper/caliper';
import { CaliperFactory } from '@dunefront/common/modules/well/model/caliper/caliper.factory';
import { ZoneFactory } from '@dunefront/common/modules/well/model/zone/zone.factory';
import { ZoneModel } from '@dunefront/common/modules/well/model/zone/zone.model';
import { TemperatureProfile } from '@dunefront/common/modules/well/model/temperature-profile/temperature-profile';
import { changeProp, ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';
import { ActionResponse } from '@dunefront/common/response-ws.action';

export class WellModuleReducerHelper {
  public static onWellModuleLoaded(
    state: WellModuleState,
    response: ActionResponse<LoadWellActionResponse>,
    settingsResponse: ActionResponse<LoadSettingsActionResponse>,
    scenarioId: number,
  ): WellModuleState {
    if (response.status !== 'ok' || !response.payload) {
      return initialWellModuleState;
    }
    return WellFactory.create(response.payload, scenarioId);
  }

  public static onUpdateWellSuccess(state: WellModuleState, response: CrudResponse): WellModuleState {
    if (!response.affectedRows.well) {
      return state;
    }

    return WellFactory.updateWellProps(state, response.affectedRows.well);
  }

  // region Survey grid

  public static onInsertSurveyRowSuccess(state: WellModuleState, response: CrudResponse): WellModuleState {
    if (!response.affectedRows.survey) {
      return state;
    }
    const surveyArray = SurveyFactory.createRowListFromDtos(response.affectedRows.survey.rows, response.scenarioId);
    const bottomHoleTVD = SurveyCalculations.BottomholeTVD(response.affectedRows.survey.rows);
    return {
      ...state,
      TemperatureProfile: { ...state.TemperatureProfile, BottomHoleTVD: bottomHoleTVD },
      SurveyData: createTableState<Survey>(surveyArray),
    };
  }

  public static deleteSurveyRows(state: WellModuleState, response: CrudResponse): WellModuleState {
    if (!response.affectedRows.survey) {
      return state;
    }

    const rowsAfterDelete = deleteObjectsFromArray(state.SurveyData.rows, response.affectedRows.survey.deletedIds);
    const recalculatedRows = SurveyCalculations.CalculateSurveyTVDs(getRowsForCalculations(rowsAfterDelete));
    const bottomHoleTVD = SurveyCalculations.BottomholeTVD(recalculatedRows);
    const surveyTableState: ITableState<Survey> = {
      ...state.SurveyData,
      rows: updateObjectsInArray(rowsAfterDelete, recalculatedRows),
    };

    return {
      ...state,
      TemperatureProfile: { ...state.TemperatureProfile, BottomHoleTVD: bottomHoleTVD },
      SurveyData: surveyTableState,
    };
  }

  public static updateSurveyRowSuccess(state: WellModuleState, action: CrudResponse): WellModuleState {
    if (!action.affectedRows.survey) {
      return state;
    }

    return this.updateSurveyRows(state, action.affectedRows.survey.rows);
  }

  private static updateSurveyRows(well: WellModuleState, affectedRows: SurveyDto[]): WellModuleState {
    const rows = updateObjectsInArray(
      getRowsWithoutInsertRow(well.SurveyData.rows),
      affectedRows.map((rowDto) => SurveyFactory.createSurvey(rowDto)),
    );
    const recalculatedRows = SurveyCalculations.CalculateSurveyTVDs(rows.map((r) => r.rowData));
    const bottomHoleTVD = SurveyCalculations.BottomholeTVD(recalculatedRows);

    return {
      ...well,
      TemperatureProfile: { ...well.TemperatureProfile, BottomHoleTVD: bottomHoleTVD },
      SurveyData: { ...well.SurveyData, rows: updateObjectsInArray(well.SurveyData.rows, recalculatedRows) },
    };
  }

  // endregion

  //region Casing grid

  public static insertCasingRowSuccess(state: WellModuleState, response: CrudResponse): WellModuleState {
    if (!response.affectedRows.casing) {
      return state;
    }
    const rowsArray = CasingPipesFactory.createRowListFromDtos(response.affectedRows.casing.rows, response.scenarioId);
    return { ...state, CasingData: createTableState<Pipe>(rowsArray) };
  }

  public static deleteCasingRowsSuccess(state: WellModuleState, response: CrudResponse): WellModuleState {
    if (!response.affectedRows.casing) {
      return state;
    }

    const rows: ITableState<Pipe> = {
      ...state.CasingData,
      rows: deleteObjectsFromArray(state.CasingData.rows, response.affectedRows.casing.deletedIds),
    };

    return this.updateCasingRows({ ...state, CasingData: rows }, response.affectedRows.casing.rows);
  }

  public static updateCasingRowSuccess(state: WellModuleState, action: CrudResponse): WellModuleState {
    if (!action.affectedRows.casing) {
      return state;
    }

    return this.updateCasingRows(state, action.affectedRows.casing.rows);
  }

  private static updateCasingRows(well: WellModuleState, affectedRows: CasingPipeDto[]): WellModuleState {
    const rows = updateObjectsInArray(
      well.CasingData.rows,
      affectedRows.map((rowDto) => CasingPipesFactory.createFromCasing(rowDto)),
    );
    return { ...well, CasingData: { ...well.CasingData, rows } };
  }

  //endregion Casing grid

  // region Caliper grid

  public static insertCaliperRowSuccess(state: WellModuleState, response: CrudResponse): WellModuleState {
    if (!response.affectedRows.caliper) {
      return state;
    }

    const rowsArray = CaliperFactory.createRowListFromDtos(response.affectedRows.caliper.rows, response.scenarioId);
    return { ...state, CaliperData: createTableState<Caliper>(rowsArray) };
  }

  public static deleteCaliperRows(state: WellModuleState, response: CrudResponse): WellModuleState {
    if (!response.affectedRows.caliper) {
      return state;
    }

    const rows: ITableState<Caliper> = {
      ...state.CaliperData,
      rows: deleteObjectsFromArray(state.CaliperData.rows, response.affectedRows.caliper.deletedIds),
    };

    return this.updateCaliperRows({ ...state, CaliperData: rows }, response.affectedRows.caliper.rows);
  }

  public static updateCaliperRowSuccess(state: WellModuleState, action: CrudResponse): WellModuleState {
    if (!action.affectedRows.caliper) {
      return state;
    }

    return this.updateCaliperRows(state, action.affectedRows.caliper.rows);
  }

  private static updateCaliperRows(well: WellModuleState, affectedRows: CaliperDto[]): WellModuleState {
    const rows = updateObjectsInArray(
      well.CaliperData.rows,
      affectedRows.map((rowDto) => CaliperFactory.createCaliper(rowDto)),
    );
    return { ...well, CaliperData: { ...well.CaliperData, rows } };
  }

  // endregion

  //region Zone grid

  public static insertZoneRowsSuccess(state: WellModuleState, response: CrudResponse): WellModuleState {
    if (!response.affectedRows.zone) {
      return state;
    }

    const rows = ZoneFactory.createRowListFromDtos(response.affectedRows.zone.rows, response.scenarioId);
    return { ...state, ZoneData: createTableState<ZoneModel>(rows) };
  }

  public static deleteZoneRows(state: WellModuleState, response: CrudResponse): WellModuleState {
    if (!response.affectedRows.zone) {
      return state;
    }

    const rows: ITableState<ZoneModel> = {
      ...state.ZoneData,
      rows: deleteObjectsFromArray(state.ZoneData.rows, response.affectedRows.zone.deletedIds),
    };

    return this.updateZoneRows({ ...state, ZoneData: rows }, response.affectedRows.zone.rows, false);
  }

  public static updateZoneRowSuccess(state: WellModuleState, action: CrudResponse): WellModuleState {
    if (!action.affectedRows.zone) {
      return state;
    }

    return this.updateZoneRows(state, action.affectedRows.zone.rows, action.affectedRows.zone.replace === 'all');
  }

  private static updateZoneRows(well: WellModuleState, affectedRows: ZoneDto[], replaceAll: boolean): WellModuleState {
    const rows = replaceAll
      ? ZoneFactory.createRowListFromDtos(affectedRows, well.ScenarioId)
      : updateObjectsInArray(
          well.ZoneData.rows,
          affectedRows.map((rowDto) => ZoneFactory.createZone(rowDto)),
        );
    return { ...well, ZoneData: { ...well.ZoneData, rows } };
  }

  //endregion  Caliper grid

  public static changeWellProperty(state: WellModuleState, props: ObjectChangeProp<ValidatedWellModuleState>): WellModuleState {
    return { ...state, [props.key]: props.value };
  }

  public static changeTemperatureProfileProperty(
    state: WellModuleState,
    changedProp: ObjectChangeProp<TemperatureProfile>,
  ): WellModuleState {
    return {
      ...state,
      TemperatureProfile: changeProp(state.TemperatureProfile, changedProp),
    };
  }
}
