import { ChangeDetectorRef, Component, HostBinding, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { getHelpState } from '../../../+store/help/help.selectors';
import { openOrCloseHelpPanelAction } from '../../../+store/help/help.actions';
import { editChartPropsAction, setHelpPinnedAction } from '../../../+store/ui/ui.actions';
import { Subscription } from 'rxjs';
import { getCurrentAppModuleSection, getIsHelpPinned } from '../../../+store/ui/ui.selectors';
import { ContentChange } from 'ngx-quill';
import { getCurrentNotes, ICurrentNotes } from '../../../+store/note/note.selectors';
import { NoteDto } from '@dunefront/common/dto/note.dto';
import { createNote, updateNote } from '../../../+store/note/note.actions';
import { AssetService } from '../../../shared/services/asset.service';
import { getSelectedReportingTab } from '../../../+store/reporting/reporting.selectors';
import {
  changeProp,
  ObjectChangeProp
} from '@dunefront/common/common/common-state.interfaces';
import { ReportingTabDto } from '@dunefront/common/dto/reporting-tab.dto';
import { getSelectedFluid } from '../../../+store/fluid/fluid.selectors';
import { FluidDto } from '@dunefront/common/modules/fluid/dto/fluid.dto';
import { updateFluid } from '../../../+store/fluid/fluid.actions';
import { getSelectedGravel } from '../../../+store/gravel/gravel.selectors';
import { StoreCrudPropsFactory } from '@dunefront/common/common/common-store-crud.interfaces';
import { Gravel } from '@dunefront/common/modules/gravel/model/gravel';
import { updateGravel } from '../../../+store/gravel/gravel.actions';
import { getIsUiLocked } from '../../../+store/ui/calc-engine-ui.selectors';

@Component({
  selector: 'app-help',
  templateUrl: 'help-and-notes.component.html',
  styleUrls: ['./help-and-notes.component.scss'],
})
export class HelpAndNotesComponent implements OnDestroy, OnInit {
  @HostBinding('class.open') public isHelpPanelOpen = false;
  public helpUrl: SafeResourceUrl | undefined;
  public isHelpPinned = false;
  private prevHelpUrl = '';
  private subscription = new Subscription();
  public currentNotes: ICurrentNotes | null = null;
  public currentReportingTab$ = this.store.select(getSelectedReportingTab);
  public currentFluid$ = this.store.select(getSelectedFluid);
  public currentGravel$ = this.store.select(getSelectedGravel);
  public appModuleSection$ = this.store.select(getCurrentAppModuleSection)
  public isUiLocked$ = this.store.select(getIsUiLocked)

  constructor(
    protected store: Store,
    private sanitizer: DomSanitizer,
    private helpService: AssetService,
    private cdRef: ChangeDetectorRef,
  ) {}

  public ngOnInit(): void {
    this.subscription.add(
      this.store.select(getCurrentNotes).subscribe((currentNotes) => {
        this.currentNotes = currentNotes;
      }),
    );

    this.subscription.add(
      this.store.select(getHelpState).subscribe((state) => {
        this.isHelpPanelOpen = state.isOpen;
        if (state.helpUrl !== this.prevHelpUrl) {
          this.prevHelpUrl = state.helpUrl;
          this.onHelpUrlChanged(state.helpUrl).then();
        }
      }),
    );

    this.subscription.add(this.store.select(getIsHelpPinned).subscribe((isHelpPinned) => (this.isHelpPinned = isHelpPinned)));
  }

  private async onHelpUrlChanged(helpUrl: string): Promise<void> {
    // enforce removing iframe from DOM
    // this prevents old help being displayed when new is loading
    // (sometimes token validation takes ~7secs)
    this.helpUrl = undefined;
    this.cdRef.detectChanges();

    // add new iframe for new help content
    this.helpUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.helpService.getAssetUrl(helpUrl));
    this.cdRef.detectChanges();
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  @HostListener('window:message', ['$event'])
  public onWindowMessage(event: MessageEvent): void {
    if (event.isTrusted && event.data === 'SHOW-HIDE-HELP' && event.origin === window.origin) {
      this.store.dispatch(openOrCloseHelpPanelAction());
    }
  }

  @HostListener('document:keydown.f1', ['$event'])
  public onKeydownHandler(event: KeyboardEvent): void {
    this.toggleHelpVisible();
    event.preventDefault();
  }

  public toggleHelpVisible(): void {
    if (this.isHelpPanelOpen) {
      this.store.dispatch(setHelpPinnedAction({ isHelpPinned: false }));
    }
    this.store.dispatch(openOrCloseHelpPanelAction());
  }

  public toggleHelpPinned(e: Event): void {
    e.stopPropagation();
    this.store.dispatch(setHelpPinnedAction({ isHelpPinned: !this.isHelpPinned }));
  }

  public onNoteContentChanged(event: ContentChange, note: NoteDto): void {
    // return if it's a undo-redo action
    if (event.source !== 'user') {
      return;
    }

    if (note.Id === -1) {
      this.store.dispatch(createNote({ ...note, Note: event.html || '' }));
    } else {
      this.store.dispatch(updateNote({ ...note, Note: event.html || '' }));
    }
  }

  public onChartNoteChanged(event: ContentChange, reportingTab: ReportingTabDto): void {
    if (event.source !== 'user') {
      return;
    }

    const updatedProp: ObjectChangeProp<ReportingTabDto> = {
      value: event.html,
      key: 'ChartDescription',
      shouldResetResults: false,
    };
    this.store.dispatch(editChartPropsAction({ reportingTab: changeProp(reportingTab, updatedProp) }));
  }

  public onFluidNoteChanged(event: ContentChange, fluid: FluidDto): void {
    if (event.source !== 'user') {
      return;
    }

    this.store.dispatch(
      updateFluid({
        changedProp: {
          value: event.html,
          key: 'Notes',
          shouldResetResults: false,
        },
        fluidId: fluid.Id,
        shouldSaveRheologies: false,
      }),
    );
  }

  public onGravelNoteChanged(event: ContentChange, gravel: Gravel): void {
    if (event.source !== 'user') {
      return;
    }

    const props: ObjectChangeProp<Gravel> = {
      key: 'Notes',
      value: event.html || '',
      shouldResetResults: false
    }
    const updatedGravel = changeProp(gravel, props);
    this.store.dispatch(updateGravel(StoreCrudPropsFactory.updateRow<Gravel>(updatedGravel, props)));

  }
}
