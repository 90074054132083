<div class="main-panel">
  <div class="left-button-menu">
    <div>
      <button
        appButton
        type="button"
        class="btn btn-primary btn-sm"
        data-cy="scenario-manager-create-btn"
        [disabled]="isReorderOn"
        [isDeleteResultsEnabled]="false"
        (buttonClick)="createScenario()"
      >
        Create
      </button>
      <button
        appButton
        type="button"
        class="btn btn-primary btn-sm"
        data-cy="scenario-manager-copy-btn"
        [disabled]="selectedScenarioIds.length !== 1 || isReorderOn"
        [isDeleteResultsEnabled]="false"
        (buttonClick)="copyScenario()"
      >
        Copy
      </button>
      <button
        appButton
        type="button"
        class="btn btn-primary btn-sm"
        data-cy="scenario-manager-rename-btn"
        [disabled]="selectedScenarioIds.length !== 1 || isReorderOn"
        [isDeleteResultsEnabled]="false"
        (buttonClick)="editScenario()"
      >
        Rename
      </button>
      <button
        appButton
        type="button"
        class="btn btn-primary btn-sm"
        data-cy="scenario-manager-delete-btn"
        [disabled]="selectedScenarioIds.length === 0 || isReorderOn"
        [isDeleteResultsEnabled]="false"
        (buttonClick)="deleteScenarios()"
      >
        Delete
      </button>
      <button
        appButton
        type="button"
        class="btn btn-primary btn-sm"
        data-cy="scenario-manager-reorder"
        (buttonClick)="toggleReorder()"
        [class.highlight]="isReorderOn"
        [isUiLockable]="false"
        [disabled]="scenarios.length <= 1"
      >
        Reorder
      </button>
      <button
        appButton
        type="button"
        class="btn btn-primary btn-sm"
        data-cy="scenario-manager-switch"
        (buttonClick)="switchScenario()"
        [isUiLockable]="false"
        [disabled]="selectedScenarioIds.length !== 1 || selectedScenarioIds[0] === currentScenarioId"
      >
        Switch
      </button>
    </div>
    <div *ngIf="isSimulateBased(currentAppModuleType)">
      <button
        appButton
        type="button"
        class="btn btn-primary btn-sm"
        data-cy="scenario-manager-simulate-btn"
        (buttonClick)="runSimulations()"
        [isUiLockable]="false"
        [disabled]="selectedScenarioIds.length === 0 || isReorderOn"
      >
        Simulate
      </button>
    </div>
  </div>
  <app-page-panel header="Select Scenario(s)" class="scenarios-selector-container" [allowFullScreen]="false">
    <app-scenario-manager-checkboxes
      dataCy="scenario-manager-checkboxes"
      [selectedIds]="selectedScenarioIds"
      [labelWidth]="360"
      (valueChanged)="updateSelectedIds($event)"
      [isReorderOn]="isReorderOn"
      (scenarioDropped)="onDrop($event)"
    >
    </app-scenario-manager-checkboxes>
  </app-page-panel>
</div>
