import { FluidType } from '../../dto/fluid.dto';
import { RheologyType } from '../../dto/rheology/rheology.dto';
import { Fluid } from '../fluid';
import { Rheology } from './rheology';
import { RheologyCalculations } from '../../dto/rheology/rheology.calculations';
import { Rheometer } from '../rheometer/rheometer';
import { RheometerReading } from '../rheometer-reading/rheometer-reading';
import { RheologyFactory } from './rheology.factory';
import { DictionaryWithArray, IDictionaryWithArray } from '../../../../common/state.helpers';
import { createTableState, getRowsForCalculations, ITableState } from '../../../../common/common-grid.interfaces';
import { FluidModuleState } from '../../fluid-module.state';

export class RheologyClientCalculations {
  private static UpdateRheologyGridData(
    fluid: Fluid,
    rheologies: Rheology[],
    rheometers: Rheometer[],
    rheometerReadings: IDictionaryWithArray<ITableState<RheometerReading>>,
    shearRate: number,
  ): Rheology[] {
    let newRheologies = rheologies;
    if (fluid.RheologyType === RheologyType.Viscosity) {
      //Calculate the k'
      newRheologies = rheologies.map((rheology) => {
        const NPrime = fluid.Type === FluidType.Newtonian ? 1 : rheology.NPrime;
        const KPrime = RheologyCalculations.KPrime(rheology, shearRate);
        return { ...rheology, NPrime, KPrime };
      });
    } else {
      if (fluid.RheologyType === RheologyType.Rheometer) {
        // calculate rheologies based on rheometers
        newRheologies = rheometers.map((rheometer, index) => {
          const currentRheometerReadingsTable = DictionaryWithArray.get(rheometerReadings, rheometer.Id) ?? createTableState([]);
          const currentRheometerReadings = getRowsForCalculations(currentRheometerReadingsTable.rows);
          return RheologyFactory.createRheologyFromRheometer(fluid, rheometer, currentRheometerReadings, index);
        });
      }

      //Calculate the viscosity
      newRheologies = newRheologies.map((rheology) => {
        const NPrime = fluid.Type === FluidType.Newtonian ? 1 : rheology.NPrime;
        const newRheology = { ...rheology, NPrime };
        const Viscosity = RheologyCalculations.ViscosityAtShearRate(newRheology, shearRate);
        return { ...newRheology, Viscosity };
      });
    }

    return newRheologies;
  }

  public static recalculateRheologies(state: FluidModuleState, shearRate: number, fluidId?: number): FluidModuleState {
    const fluidIds = fluidId !== undefined ? [fluidId] : state.Fluids.ids.map((id) => +id);
    let newStateRheologies = state.Rheologies;
    fluidIds.forEach((currentFluidId) => {
      const fluid = DictionaryWithArray.get(state.Fluids, currentFluidId);
      const fluidRheologies = DictionaryWithArray.get(state.Rheologies, currentFluidId);
      const fluidRheometers = DictionaryWithArray.get(state.Rheometers, currentFluidId);

      if (!fluid || !fluidRheologies || !fluidRheometers) {
        return;
      }
      const rheologies = this.UpdateRheologyGridData(
        fluid,
        getRowsForCalculations(fluidRheologies.rows),
        getRowsForCalculations(fluidRheometers.rows),
        state.RheometerReadings,
        shearRate,
      );
      const rheologiesTableState = RheologyFactory.createTableStateForFluidId(rheologies, fluid.ScenarioId, fluid.Id);
      newStateRheologies = DictionaryWithArray.upsertById(newStateRheologies, rheologiesTableState, fluid.Id);
    });
    return { ...state, Rheologies: newStateRheologies };
  }
}
