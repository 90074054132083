import { ImportDataModuleState, IValidatedImportDataModuleState } from '../import-data-module.state';
import { IValidatedColConfig } from '../model/col-config';
import { IValidatedImportDataColumnDelimiterConfig } from '../model/col-delimiter';
import { IError } from '@dunefront/common/common/common-state.interfaces';
import { noErrors } from '@dunefront/common/common/state.helpers';
import { areStringsTheSame, rationalizeString } from '@dunefront/common/common/helpers';
import { FileParserService } from '../../../common-modules/modals/import-paste-data-common/file-parser/file-parser.service';
import { ImportDataCalculations } from '../import-data.calculations';
import {
  IColConfig,
  ImportColumnType,
  ImportDataColumnDelimiterConfig,
  ImportDataType,
  ImportFileColumnDelimiter,
} from '@dunefront/common/modules/data-storage/dto/import-template/import-template.dto';
import { IColNameWithId } from '@dunefront/common/modules/data-storage/data-storage-module.state';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import * as dayjs from 'dayjs';
import { dateFormats, timeFormats } from '@dunefront/common/common/date-time-formats';

export class ImportDataValidation {
  public static isMeasurementInputsVisible(state: ImportDataModuleState): boolean {
    return state.importDataType !== ImportDataType.Surface_Data;
  }

  public static isMeasurementEnabled(colConfig: IColConfig): boolean {
    return colConfig.columnType === ImportColumnType.Bottomhole_Temperature || colConfig.columnType === ImportColumnType.Bottomhole_Pressure;
  }

  public static isUnitSystemEnabled(colConfig: IColConfig): boolean {
    return colConfig.columnType === ImportColumnType.Other;
  }

  public static isUnitSymbolsEnabled(colConfig: IColConfig): boolean {
    return colConfig.unitSystem !== null && colConfig.unitSystem !== UnitSystem.None && !colConfig.isTimeCustomFormat;
  }

  public static isCustomFormatCbEnabled(colConfig: IColConfig): boolean {
    return colConfig.columnType === ImportColumnType.Time;
  }

  public static validate(state: ImportDataModuleState, columnNames: IColNameWithId[]): IValidatedImportDataModuleState {
    const error: IError<ImportDataModuleState> = {};
    const delimiterConfig = this.validateColumnDelimiterConfig(state.delimiterConfig);
    const errorMessage = delimiterConfig.error.customDelimiter || '';
    let isValid = noErrors(error) && noErrors(delimiterConfig.error) && !errorMessage && !state.templateError;
    const colConfigs = this.validateColConfigs(state, columnNames);
    if (colConfigs.some((colConfig) => !colConfig.isValid)) {
      isValid = false;
    }

    return {
      ...state,
      delimiterConfig,
      colConfigs,
      error,
      errorMessage,
      isValid,
    };
  }

  public static validateColumnTypes(state: ImportDataModuleState): string {
    const selectedRowsError = this.isDateTimeColumnValid(state) || this.isAtLeastOneDataColumnSelected(state);
    return selectedRowsError;
  }

  public static validateColumnSelections(state: ImportDataModuleState): string {
    const selectedRowsError = this.areAtLeast2ColumnsSelected(state);
    return selectedRowsError;
  }

  public static validateParsedFileLines(parsedDataLinesLength: number): string {
    if (!parsedDataLinesLength) {
      return 'There is no data to import';
    }
    return '';
  }

  public static validateColumnDelimiterConfig(delimiterConfig: ImportDataColumnDelimiterConfig): IValidatedImportDataColumnDelimiterConfig {
    const error: IError<ImportDataColumnDelimiterConfig> = {};
    error.customDelimiter = this.validateCustomDelimiter(delimiterConfig);
    return { ...delimiterConfig, error, isValid: noErrors(error) };
  }

  private static validateColConfigs(state: ImportDataModuleState, existingColumnNames: IColNameWithId[]): IValidatedColConfig[] {
    const validatedColConfigs = state.colConfigs.map((colConfig) =>
      state.includedColumnIds.includes(colConfig.colIndex)
        ? this.validateColConfig(
            colConfig,
            state.colConfigs.filter((c) => state.includedColumnIds.includes(c.colIndex)),
          )
        : { ...colConfig, error: {}, isValid: true },
    );

    return this.validateColumnName(validatedColConfigs, existingColumnNames, state.includedColumnIds);
  }

  private static validateColConfig(currentColConfig: IColConfig, allConfigs: IColConfig[]): IValidatedColConfig {
    const error: IError<IColConfig> = {};
    error.columnType = this.validateDataType(currentColConfig, allConfigs);
    error.unitSystem = this.validateUnitSystem(currentColConfig);
    error.measurementType = this.validateMeasurementType(currentColConfig);
    error.measuredDepth = this.validateMeasuredDepth(currentColConfig);
    error.timeCustomFormat = this.validateCustomFormat(currentColConfig);

    return { ...currentColConfig, error, isValid: noErrors(error) };
  }

  private static validateColumnName(
    colConfigs: IValidatedColConfig[],
    existingColumnNames: IColNameWithId[],
    includedColumnIds: number[],
  ): IValidatedColConfig[] {
    const currentFileColNames: string[] = [];
    return colConfigs.map((colConfig) => {
      if (!includedColumnIds.includes(colConfig.colIndex)) {
        // do not check not included columns
        return colConfig;
      }
      if (!colConfig.name) {
        return { ...colConfig, error: { ...colConfig.error, ['name']: 'Enter Column Name' }, isValid: false };
      }
      if (currentFileColNames.some((existingCol) => areStringsTheSame(existingCol, colConfig.name))) {
        return {
          ...colConfig,
          error: { ...colConfig.error, ['name']: 'Column name already exists in the file being imported' },
          isValid: false,
        };
      }
      if (existingColumnNames.map((col) => rationalizeString(col.name)).includes(rationalizeString(colConfig.name))) {
        return {
          ...colConfig,
          error: { ...colConfig.error, ['name']: 'Column name already exists in a previously imported file' },
          isValid: false,
        };
      }

      currentFileColNames.push(colConfig.name);
      return colConfig;
    });
  }

  public static validateDataType(colConfig: IColConfig, allConfigs: IColConfig[]): string {
    if (colConfig.columnType === null) {
      return 'Select Data Type';
    }
    if (colConfig.columnType === ImportColumnType.Time) {
      if (!colConfig.isTimeCustomFormat && !this.validateTimeData(colConfig)) {
        return 'Data must be numerical for specified unit';
      }

      const allTimeColumns = allConfigs.filter((col) => col.columnType === ImportColumnType.Time);
      const customFormatTimeColumns = allTimeColumns.filter((col) => col.isTimeCustomFormat && col.colIndex <= colConfig.colIndex);
      const notCustomFormatTimeColumns = allTimeColumns.filter((col) => !col.isTimeCustomFormat && col.colIndex <= colConfig.colIndex);
      if (notCustomFormatTimeColumns.length > 1 && customFormatTimeColumns.length === 0) {
        return 'When using delta time, only one time column can be selected in the file';
      }
      if (notCustomFormatTimeColumns.length > 0 && customFormatTimeColumns.length > 0) {
        return 'Delta time and custom time cannot be used in the same file';
      }
    }

    return '';
  }

  public static validateUnitSystem(colConfig: IColConfig): string {
    if (this.isUnitSystemEnabled(colConfig) && colConfig.unitSystem === null) {
      return 'Select Unit System';
    }
    return '';
  }

  public static validateMeasurementType(colConfig: IColConfig): string {
    if (this.isMeasurementEnabled(colConfig) && (colConfig.measurementType === null || colConfig.measurementType === -1)) {
      return 'Select Measurement type';
    }
    return '';
  }

  public static validateMeasuredDepth(colConfig: IColConfig): string {
    if (this.isMeasurementEnabled(colConfig) && colConfig.measuredDepth <= 0) {
      return 'Measured depth must be greater than zero';
    }
    return '';
  }

  public static areThereAnyRowsToImport(rows: string[][]): string {
    if (rows.length === 0) {
      return 'There is no data to import.';
    }
    return '';
  }

  public static areAtLeast2ColumnsSelected(state: ImportDataModuleState): string {
    if (state.includedColumnIds.length < 2) {
      return 'Please select at 2 columns.';
    }
    return '';
  }

  public static isDateTimeColumnValid(state: ImportDataModuleState): string {
    const timeCols = state.colConfigs.filter(
      (col) => state.includedColumnIds.includes(col.colIndex) && col.columnType === ImportColumnType.Time,
    );

    if (timeCols.length === 0) {
      return 'Please select at least one date/time column.';
    }

    const customFormatCols = timeCols.filter((col) => col.isTimeCustomFormat);
    if (customFormatCols.length) {
      const combinedCustomFormat = ImportDataCalculations.getCombinedCustomFormat(customFormatCols);
      const ccf = combinedCustomFormat.combinedCustomFormat;
      if (!ccf.includes('m')) {
        return 'When using custom time, minutes must be defined in the time stamp.';
      }
      if (!(ccf.includes('h') || ccf.includes('H'))) {
        return 'When using custom time, hours must be defined in the time stamp.';
      }
    }
    return '';
  }

  public static isAtLeastOneDataColumnSelected(state: ImportDataModuleState): string {
    if (
      state.colConfigs.filter((col) => state.includedColumnIds.includes(col.colIndex) && col.columnType !== ImportColumnType.Time).length === 0
    ) {
      return 'There must be at least one data column (all columns have been selected as date/time).';
    }
    return '';
  }

  public static validateCustomDelimiter(delimiterConfig: ImportDataColumnDelimiterConfig): string {
    if (delimiterConfig.delimiter === ImportFileColumnDelimiter.Custom && !delimiterConfig.customDelimiter) {
      return 'Please define a custom delimiter.';
    }
    return '';
  }

  public static validateCustomFormat(colConfig: IColConfig): string {
    if (colConfig.isTimeCustomFormat) {
      if (colConfig.timeCustomFormat === null || colConfig.timeCustomFormat.trim() === '') {
        return 'Enter Custom Format for time data';
      }
      if (!this.validateCustomTimeData(colConfig)) {
        return 'Custom Format does not match time data';
      }
    }

    return '';
  }

  private static validateTimeData(colConfig: IColConfig): boolean {
    for (let rowIndex = 0; rowIndex <= FileParserService.lineTolerance; rowIndex++) {
      const row = FileParserService.parsedResult.data[rowIndex];

      // when using sampling frequency sometimes there are less rows than line tolerance
      if (row == null) {
        continue;
      }
      const value = row[colConfig.colIndex];
      if (isNaN(+value)) {
        return false;
      }
    }
    return true;
  }

  public static validateCustomTimeData(colConfig: IColConfig, formatToCheck?: string): boolean {
    const data = FileParserService.parsedResult.data;
    const lineTolerance = FileParserService.lineTolerance;

    const linesToCheck =
      data.length < lineTolerance * 3 // if dataset is smaller than 3*[lineTolerance] (45) rows, check it all.
        ? data
        : [
            // otherwise check first, middle and last, [lineTolerance]-sized chunks
            ...data.slice(0, lineTolerance),
            ...data.slice(data.length / 2 - lineTolerance / 2, data.length / 2 + lineTolerance / 2),
            ...data.slice(data.length - lineTolerance),
          ];

    const timeFormat = formatToCheck ? formatToCheck : colConfig.timeCustomFormat;

    for (const row of linesToCheck) {
      if (row == null) {
        return false;
      }

      const value = row[colConfig.colIndex];
      const parseResult = ImportDataCalculations.parseCustomFormatValue(value.trim(), timeFormat as string);

      const isValid = parseResult.isValid();

      if (!isValid) {
        return false;
      }
    }
    return true;
  }

  public static detectTimeFormat(colConfig: IValidatedColConfig): string[] {
    // split date to parts ( so we have date and time separated )
    const date = FileParserService.parsedResult.data[0][colConfig.colIndex];

    const dateTimeParts: string[] = date.split(' ');
    // non numeric regex
    const regex = /\D/gm;

    // array that will hold all possible formats, grouped to date and time
    const allPossibleFormats: string[][] = [];

    const dateSeparators = ['/', '-', '.'];
    const timeSeparators = [':', '.'];

    dateTimeParts.forEach((dateTimePart, idx) => {
      const separatorsArr: string[] = dateTimePart.match(regex) || [];

      const isDate = separatorsArr.every((separator) => dateSeparators.includes(separator));
      const isTime = separatorsArr.every((separator) => timeSeparators.includes(separator)) && separatorsArr.includes(timeSeparators[0]);

      // check the type of current date part, and choose only relevant formats
      let rawFormats: string[][] = [];
      if (isTime) {
        const timePartsLen = dateTimePart.split(regex).length;
        // if time does not contain milliseconds, skip formats with ms
        rawFormats = timeFormats.filter((format) => format.length === timePartsLen);
      } else if (isDate) {
        rawFormats = dateFormats;
      }

      // build possible formats, using same separator as in file
      const formatted = [];
      if (rawFormats.length) {
        formatted.push(...Array.from(new Set(this.formatRawDateFormats(rawFormats, separatorsArr))));
      }

      // filter out non-valid formats ( validating only first row )
      allPossibleFormats[idx] = formatted.filter((format) => {
        return dayjs(dateTimePart, format, true).isValid();
      });
    });

    // merge time with date if required, and build possible combinations
    const mergedFormats =
      allPossibleFormats.length === 1
        ? allPossibleFormats[0]
        : allPossibleFormats[0].flatMap((d: any) => allPossibleFormats[1].map((v: any) => d + ' ' + v));

    // properly validate all formats
    const possibleCustomFormats = mergedFormats.filter((format: string) => {
      return dayjs(date, format, true).isValid() && ImportDataValidation.validateCustomTimeData(colConfig, format);
    });

    return possibleCustomFormats.slice(0, 3);
  }

  private static formatRawDateFormats(rawFormats: string[][], separators: string[]): string[] {
    // convert raw format ( f,e ['HH', 'mm', 'ss', 'SS'] ) to proper format, compatible with user input ( HH:mm:ss.SS )
    const formatted: string[] = [];

    rawFormats.forEach((formatArr) => {
      let str = '';

      formatArr.forEach((symbol, idx) => {
        str += symbol;
        if (separators[idx]) {
          str += separators[idx] || '';
        }
      });

      formatted.push(str);
    });
    return formatted;
  }
}
