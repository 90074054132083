import { Injectable } from '@angular/core';
import { BaseWsEffects } from '../base-ws.effects';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { BackendConnectionService } from '../../shared/backend-connection/backend-connection.service';
import { ModalService } from '../../common-modules/modals/modal.service';
import {
  CalculateReferenceVariablesAction,
  ReferenceVariablesModuleName,
} from '@dunefront/common/modules/reference-variables/reference-variables-module.actions';
import { loadReferenceVariablesAction } from './reference-variables.actions';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import { AppTargetConfig } from '../../shared/services/app-target-config';
import { isSimulateBased, ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import { dataFailed, scenarioOrRangeLoadedAction, updateRowSuccess } from '../app.actions';
import { getReferenceVariableCalculatorInputs } from './reference-variables-calculator.selectors';
import {
  CalculationContext,
  IScenarioAndRange,
  runMultipleSimulationsForCompareScenario,
  updateRefVariablesValidateAndAddCalculationToQueueAction,
  validateAndAddCalculationToQueueAction,
} from '../calculation-engine/calculation-engine.actions';
import { getCurrentScenarioId } from '../scenario/scenario.selectors';
import { CrudResponse } from '@dunefront/common/modules/common.actions';
import { of } from 'rxjs';
import { WsActionResponse } from '@dunefront/common/response-ws.action';
import { getFileHash } from '../backend-connection/backend-connection.selectors';
import { getCurrentAppModuleType } from '../ui/ui.selectors';
import { getCurrentFeatures } from '../licensing/licensing.selectors';
import { RangeConstants } from '@dunefront/common/dto/range.dto';
import { filterNil } from '@dunefront/common/common/state.helpers';

@Injectable()
export class ReferenceVariablesEffects extends BaseWsEffects {
  constructor(
    actions$: Actions,
    store: Store,
    wsService: BackendConnectionService,
    modalService: ModalService,
    protected appConfig: AppTargetConfig,
  ) {
    super(actions$, wsService, ReferenceVariablesModuleName, false, true, modalService, store);
  }

  public scenarioOrRangeLoadedAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(scenarioOrRangeLoadedAction),
      filter((payload) => isSimulateBased(payload.moduleType) || payload.moduleType === ModuleType.Evaluate),
      map((payload) => loadReferenceVariablesAction({ scenarioId: payload.scenarioId })),
    ),
  );

  public loadReferenceVariablesAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadReferenceVariablesAction),
      filter(() => this.appConfig.isAppCode('pack-pro') || this.appConfig.isAppCode('fluid-pro')),
      concatLatestFrom(() => this.store.select(getReferenceVariableCalculatorInputs)),
      mergeMap(([action, payload]) => this.emitUpdate(new CalculateReferenceVariablesAction(payload, action.scenarioId))),
    ),
  );

  private updateRefVariablesBeforeCalculationsAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateRefVariablesValidateAndAddCalculationToQueueAction),
      concatLatestFrom(() => [this.store.select(getReferenceVariableCalculatorInputs), this.store.select(getCurrentScenarioId)]),
      mergeMap(([action, payload, scenarioId]) =>
        // Order of the 2 actions in mergeMap should stay as it is as update of Reference Variables should happen before running
        // Validations/Calculations
        this.emit<CrudResponse>(new CalculateReferenceVariablesAction(payload, scenarioId)).pipe(
          mergeMap((result: WsActionResponse<CrudResponse>) => [
            updateRowSuccess(result.payload),
            validateAndAddCalculationToQueueAction(action),
          ]),
          catchError((err) => of(dataFailed(err))),
        ),
      ),
    ),
  );

  private runMultipleSimulationsForCompareScenario$ = createEffect(() =>
    this.actions$.pipe(
      ofType(runMultipleSimulationsForCompareScenario),
      concatLatestFrom(() => [
        this.store.select(getFileHash).pipe(filterNil()),
        this.store.select(getCurrentAppModuleType),
        this.store.select(getCurrentFeatures),
      ]),
      map(([action, fileHash, appModuleType, licenceFeatures]) => {
        const scenariosAndRanges: IScenarioAndRange[] = action.scenarioIds.map((scenario) => ({
          scenarioId: scenario,
          rangeId: RangeConstants.EmptyRangeId,
        }));

        return updateRefVariablesValidateAndAddCalculationToQueueAction({
          scenariosAndRanges,
          fileHash: fileHash,
          moduleType: appModuleType,
          currentLicenseFeatures: licenceFeatures,
          calculationContext: CalculationContext.COMPARE_SCENARIO,
          redirect: false,
        });
      }),
    ),
  );
}
