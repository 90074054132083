import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { Subscription } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';
import { filter } from 'rxjs/operators';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { progressUpdateAction } from '../../../+store/app.actions';

@Component({
  selector: 'app-activity-overlay',
  templateUrl: './activity-overlay.component.html',
  styleUrls: ['./activity-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityOverlayComponent implements OnDestroy, OnInit {
  @Input() public title = '';
  @Input() public message = '';
  @Input() public activityIndicator = true;
  @Input() public progress: number | undefined;
  public taskId?: string;

  private subscription = new Subscription();

  constructor(
    @Optional() public ref: DynamicDialogRef,
    @Optional() public config: DynamicDialogConfig,
    protected actions$: Actions,
    private cdRef: ChangeDetectorRef,
  ) {
    if (config == null) {
      return;
    }

    config.closable = false;

    if (config.data) {
      this.message = config.data.message ?? '';
      this.title = config.data.title ?? '';
      this.taskId = config.data.taskId;
    }
  }

  public ngOnInit(): void {
    this.enableProgressIndicatorIfNeeded();
  }

  public enableProgressIndicatorIfNeeded(): void {
    if (!this.taskId) {
      return;
    }
    this.subscription.add(
      this.actions$
        .pipe(
          ofType(progressUpdateAction),
          filter((action) => action.taskId === this.taskId),
        )
        .subscribe((action) => {
          this.progress = action.progressPayload.progress;
          this.message = action.progressPayload.message ?? '';
          this.cdRef.markForCheck();
        }),
    );
  }

  public get isProgressVisible(): boolean {
    return this.progress !== undefined;
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
