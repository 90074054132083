import { DocumentGeneratorHelper } from '../document-generator.helper';
import { HeadingLevel, Paragraph, Table } from 'docx';
import { ReportScenarioImages } from './chart-report-generator.helper';
import { CommonReportDataHelpers } from '../../common/common-report-data-helpers';
import { DocxImageGenerator } from '../docx-image-generator';

export class AnimationVisualizationReportGeneratorHelper {
  public static async createSimulationAnimationSection(
    images: ReportScenarioImages,
    docxImageGenerator: DocxImageGenerator,
    rangeId: number,
    rangeName?: string,
  ): Promise<Array<Paragraph | Table>> {
    const rangeImages = images.rangeImages ? images.rangeImages[rangeId] : {};
    const mainTitle = DocumentGeneratorHelper.addRangeNameToTitle('Simulation Animation', rangeName);

    if (
      !rangeImages.simulationAnimationPressureChart &&
      !rangeImages.simulationAnimationWell &&
      !rangeImages.simulationAnimationPerfPackingChart
    ) {
      return CommonReportDataHelpers.createNoResultsMessage(mainTitle);
    }

    const rows = [DocumentGeneratorHelper.createParagraphHeader(mainTitle, HeadingLevel.HEADING_2, 1)];

    if (rangeImages.simulationAnimationPressureChart) {
      rows.push(
        ...[
          DocumentGeneratorHelper.createParagraphHeader('Pressure', HeadingLevel.HEADING_3, 2),
          ...(await DocumentGeneratorHelper.drawGenericImage(rangeImages.simulationAnimationPressureChart, docxImageGenerator)),
        ],
      );
    }

    if (rangeImages.simulationAnimationWell) {
      rows.push(
        ...[
          DocumentGeneratorHelper.createParagraphHeader('Well', HeadingLevel.HEADING_3, 2),
          ...(await DocumentGeneratorHelper.drawGenericImage(rangeImages.simulationAnimationWell, docxImageGenerator)),
        ],
      );
    }

    if (rangeImages.simulationAnimationPerfPackingChart) {
      rows.push(
        ...[
          DocumentGeneratorHelper.createParagraphHeader('Perf Packing', HeadingLevel.HEADING_3, 2),
          ...(await DocumentGeneratorHelper.drawGenericImage(rangeImages.simulationAnimationPerfPackingChart, docxImageGenerator)),
        ],
      );
    }

    return rows;
  }

  public static async createEvaluationAnimationSection(
    images: ReportScenarioImages,
    docxImageGenerator: DocxImageGenerator,
    rangeId: number,
    rangeName?: string,
  ): Promise<Array<Paragraph | Table>> {
    const rangeImages = images.rangeImages ? images.rangeImages[rangeId] : {};
    const mainTitle = DocumentGeneratorHelper.addRangeNameToTitle('Evaluation Animation', rangeName);

    if (!rangeImages.evaluationAnimationPressureChart && !rangeImages.evaluationAnimationFrictionChart) {
      return CommonReportDataHelpers.createNoResultsMessage(mainTitle);
    }

    const rows = [DocumentGeneratorHelper.createParagraphHeader(mainTitle, HeadingLevel.HEADING_2, 1)];

    if (rangeImages.evaluationAnimationPressureChart) {
      rows.push(
        ...[
          DocumentGeneratorHelper.createParagraphHeader('Pressure', HeadingLevel.HEADING_3, 2),
          ...(await DocumentGeneratorHelper.drawGenericImage(rangeImages.evaluationAnimationPressureChart, docxImageGenerator)),
        ],
      );
    }

    if (rangeImages.evaluationAnimationFrictionChart) {
      rows.push(
        ...[
          DocumentGeneratorHelper.createParagraphHeader('Friction', HeadingLevel.HEADING_3, 2),
          ...(await DocumentGeneratorHelper.drawGenericImage(rangeImages.evaluationAnimationFrictionChart, docxImageGenerator)),
        ],
      );
    }

    return rows;
  }

  public static async createWellVisualization(
    images: ReportScenarioImages,
    docxImageGenerator: DocxImageGenerator,
    rangeId: number,
    rangeName?: string,
  ): Promise<Array<Paragraph | Table>> {
    const rangeImages = images.rangeImages ? images.rangeImages[rangeId] : {};
    const mainTitle = DocumentGeneratorHelper.addRangeNameToTitle('Well Visualization', rangeName);

    if (!rangeImages.wellVisualization) {
      return CommonReportDataHelpers.createNoResultsMessage(mainTitle);
    }

    return [
      DocumentGeneratorHelper.createParagraphHeader(mainTitle, HeadingLevel.HEADING_2, 1),
      ...(await DocumentGeneratorHelper.drawGenericImage(rangeImages.wellVisualization, docxImageGenerator)),
    ];
  }
}
