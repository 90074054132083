import { NgModule } from '@angular/core';
import { ResultsComponent } from './results.component';
import { SummaryComponent } from './summary/summary.component';
import { EvaluateAnimationComponent } from './animation/evaluate-animation.component';
import { PagePanelModule } from '../../../common-modules/page-panel/page-panel.component';
import { TabbedPageModule } from '../../../common-modules/tabbed-page/tabbed-page.component';
import { CommonModule } from '@angular/common';
import { UnitsModule } from '../../../common-modules/units/units.module';
import { FormsModule } from '@angular/forms';
import { GridSelectComponentModule } from '../../../common-modules/grid-select/grid-select.component';
import { ChartModule } from '../../../common-modules/chart/chart.module';
import { RadioGroupModule } from '../../../shared/components/form-components/radio-group/radio-group.component';
import { AnimationTimeBasedChartComponent } from './animation/animation-time-based-chart/animation-time-based-chart.component';
import { ChartSliderComponent } from './animation/chart-slider/chart-slider.component';
import { MatSliderModule } from '@angular/material/slider';
import { SelectModule } from '../../../shared/components/form-components/select/select.module';
import { LoaderModule } from '../../../common-modules/loader/loader.component';
import { SimulationAnimationComponent } from './animation/simulation-animation.component';
import { SummaryPanelsComponent } from './summary/summary-panels/summary-panels.component';
import { WarningMessagesGridComponent } from './summary/warning-messages-grid/warning-messages-grid.component';
import { FormInputModule } from '../../../shared/components/form-components/form-input/form-input.component';
import { UnwrapDirectiveModule } from '../../../shared/directives/unwrap.directive';
import { GridModule } from '../../../shared/components/grid/grid.module';
import { AnimationPackingDrawingComponent } from './animation/animation-packing-drawing/animation-packing-drawing.component';
import { DrawingComponent } from './drawing/drawing.component';
import { WellVisualizationComponent } from './well-visualization/well-visualization.component';
import { RouterModule } from '@angular/router';
import { AnimationDepthBasedChartComponent } from './animation/animation-depth-based-chart/animation-depth-based-chart.component';
import { ProgressBarModule } from 'primeng/progressbar';
import { ModalsModule } from '../../../common-modules/modals/modals.module';
import { ButtonModule } from '../../../shared/components/button/button.module';
import { SimulationDispAnimationComponent } from './simulation-disp/simulation-disp-animation.component';

@NgModule({
  declarations: [
    ResultsComponent,
    SummaryComponent,
    EvaluateAnimationComponent,
    SimulationAnimationComponent,
    ChartSliderComponent,
    AnimationTimeBasedChartComponent,
    AnimationDepthBasedChartComponent,
    SummaryPanelsComponent,
    WarningMessagesGridComponent,
    AnimationPackingDrawingComponent,
    WellVisualizationComponent,
    DrawingComponent,
    SimulationDispAnimationComponent,
  ],
  imports: [
    PagePanelModule,
    CommonModule,
    TabbedPageModule,
    UnitsModule,
    FormsModule,
    GridSelectComponentModule,
    ChartModule,
    RadioGroupModule,
    SelectModule,
    MatSliderModule,
    LoaderModule,
    ModalsModule,
    FormInputModule,
    UnwrapDirectiveModule,
    GridModule,
    RouterModule,
    ProgressBarModule,
    ButtonModule,
  ],
  exports: [ChartSliderComponent, AnimationPackingDrawingComponent],
})
export class ResultsModule {}
