import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { BuildInfo } from '@dunefront/common/common/build-info';
import { DbDependentComponent } from '../../db-connection/db-dependent.component';
import { Store } from '@ngrx/store';
import { getLicensingLogin, getLoggedInFeaturesStatus } from '../../../+store/licensing/licensing.selectors';
import { LicenseFeature, LoginInfo } from '@dunefront/common/modules/licensing/licensing.interfaces';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AppTargetConfig } from '../../../shared/services/app-target-config';
import { CheckForUpdatesComponent } from '../../modals/check-for-updates/check-for-updates.component';
import { ModalService } from '../../modals/modal.service';
import { ElectronService } from '../../../shared/services/electron-service/electron.service';

@Component({
  selector: 'app-about-component',
  templateUrl: './about.modal.component.html',
  styleUrls: ['./about.modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AboutModalComponent extends DbDependentComponent implements OnInit {
  public loginInfo: LoginInfo | undefined;
  public clientBuildInfo = BuildInfo;
  public loggedInFeaturesStatus$ = this.store.select(getLoggedInFeaturesStatus);
  public LicenseFeature = LicenseFeature;
  // public licenseStatus: string | undefined;
  // public licenseExpiration: string | undefined;
  // public licenseId: string | undefined;
  public isDevMode = false;
  public currYear: number;

  public get version(): string {
    return this.clientBuildInfo.version + (this.isTestVersion ? ' Test' : '');
  }

  public get commitId(): string {
    return this.clientBuildInfo.commitRef?.substring(0, 8);
  }

  constructor(
    store: Store,
    cdRef: ChangeDetectorRef,
    public ref: DynamicDialogRef,
    public appTargetConfig: AppTargetConfig,
    public config: DynamicDialogConfig,
    public electronService: ElectronService,
    private modalService: ModalService,
  ) {
    super(store, cdRef);

    this.isDevMode = config.data.isDevMode;

    this.currYear = new Date().getFullYear();
  }

  public override ngOnInit(): void {
    super.ngOnInit();

    this.subscription.add(
      this.store.select(getLicensingLogin).subscribe((licensingLogin) => {
        this.loginInfo = licensingLogin?.loginInfos.find((feature) => feature.licenseFeature === licensingLogin?.mainFeature);
        //
        // this.licenseStatus = mainFeature?.loginStatusMessage;
        // this.licenseExpiration = mainFeature?.expirationMessage;
        // this.licenseId = mainFeature?.licenseId;
      }),
    );
  }

  public onBtnCheckForUpdatesClick(): void {
    this.modalService.open(CheckForUpdatesComponent, undefined);
  }

  public async btnCopyVersionClick(): Promise<void> {
    const version = `App: ${this.appTargetConfig.appName}
Version: ${this.version}
CommitId: ${this.commitId}
Platform: ${this.electronService.isElectronApp ? 'electron' : 'cloud'}
Info: ${navigator.userAgent}
Location: ${location.href}`;

    await navigator.clipboard.writeText(version);
    await this.modalService.showAlert('Version info was copied to the clipboard.', '', 'sm', false);
  }

  public onBtnCancelClick(): void {
    this.ref.close();
  }
}
