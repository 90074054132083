import { GravelDto } from '../../gravel/dto/gravel.dto';
import { FluidWithDependenciesDto } from '../../fluid/dto/fluid.dto';
import { DeveloperSettingsDto } from '../../settings/dto/settingsDto';

export interface ResuspensionCalculatorDto extends ResuspensionCalculatorInputs, ResuspensionCalculatorResults {
  ScenarioId: number;
}

export interface ResuspensionCalculatorInputs {
  FlowPathType: FlowPathType;
  MinDiameter: number;
  MaxDiameter: number;
  FluidId: number | null;
  GravelId: number | null;
  Concentration: number;
  Temperature: number;
}

export interface ResuspensionCalculatorResults {
  ResuspensionRate: number;
  ResuspensionVelocity: number;
}

export enum FlowPathType {
  Pipe = 0,
  Annulus = 1,
  Round_Shunt = 2,
  Rectangular_Shunt = 3,
}

export interface ResuspensionCalculatorJobInputDataDTO {
  Input: ResuspensionCalculatorInputs;
  Gravels: GravelDto[];
  Fluids: FluidWithDependenciesDto[];
  DeveloperSettings: DeveloperSettingsDto;
}
