import { IWellValidationDependencies, WellModuleState } from '../../well-module.state';
import { ZoneModel } from './zone.model';
import { ArrayHelpers } from '../../../../common/array-helpers';
import { TreatmentType, ZonePressureType } from '../../dto/well.dto';
import { PipeType } from '../../../../dto/pipe.dto';
import { LossZoneType } from '../../dto/zone/zone.dto';
import { Pipe } from '../../../pipes/pipe';
import { ConvertUnitPipe } from '../../../units/convert-unit.pipe/convert-unit.pipe';
import { getRowsForCalculations, ITableRow, ITableState } from '../../../../common/common-grid.interfaces';
import { SurveyCalculations } from '../../dto/survey/survey.dto';
import { noErrors } from '../../../../common/state.helpers';
import { UnitSystem } from '../../../../dto/unit-system.dto';
import { MAX_PERMEABILITY_SI } from '../../../../common/constants';
import { IError } from '../../../../common/common-state.interfaces';
import { ModuleType } from '../../../scenario/scenario.dto';
import { ToolPosition } from '../../../pumping/dto/pumping.dto';
import { DeveloperSettingsDto, ValidatedDeveloperSettings } from '../../../settings/dto/settingsDto';
import { MathHelpers } from '../../../../common/math-helpers';

export class ZoneValidation {
  private static MinYoungsModulus = 68947572.932; // pascal -> 10000.0 psi
  private static MaxYoungsModulus = 34473786466; // pascal -> 5000000.0 psi

  public static ValidateGridDepth(well: WellModuleState, developerSettings: ValidatedDeveloperSettings): boolean {
    const rows = getRowsForCalculations(well.ZoneData.rows);

    for (let index = 0; index < rows.length; index++) {
      const rowTopMdError = this.ValidateZoneTopMD(well, rows, index, developerSettings);
      const rowBottomMdError = this.ValidateZoneBottomMD(well, rows, index, developerSettings);
      const rowTopTVDError = this.ValidateZoneTopTVD(well, rows, index, developerSettings);
      const rowBottomTVDError = this.ValidateZoneBottomTVD(well, rows, index);
      if (rowTopMdError || rowBottomMdError || rowTopTVDError || rowBottomTVDError) {
        return false;
      }
    }
    return true;
  }

  public static ValidateZoneGrid(well: WellModuleState, deps: IWellValidationDependencies): ITableState<ZoneModel> {
    let isValid = true;
    const newZoneData = { ...well.ZoneData };
    const rows = getRowsForCalculations(well.ZoneData.rows);
    const updatedRows = newZoneData.rows.map((row, rowIndex) => {
      if (row.rowType === 'insert-row') {
        return row;
      }
      const newRow = this.ValidateZoneGridRow(well.ZoneData.rows[rowIndex], rows, well, rowIndex, deps);
      isValid = isValid && newRow.isValid;
      return newRow;
    });

    return { ...newZoneData, rows: updatedRows, isValid, isWarning: false };
  }

  private static ValidateZoneGridRow(
    row: ITableRow<ZoneModel>,
    rows: ZoneModel[],
    well: WellModuleState,
    rowIndex: number,
    deps: IWellValidationDependencies,
  ): ITableRow<ZoneModel> {
    const { developerSettings, currentModuleType } = deps;
    const ignoreZoneProps = currentModuleType === ModuleType.Simulate_Disp;

    const zoneErrors: IError<ZoneModel> = {
      TopMD: this.ValidateZoneTopMD(well, rows, rowIndex, developerSettings),
      BottomMD: this.ValidateZoneBottomMD(well, rows, rowIndex, developerSettings),
      TopTVD: this.ValidateZoneTopTVD(well, rows, rowIndex, developerSettings),
      BottomTVD: this.ValidateZoneBottomTVD(well, rows, rowIndex),
      ReservoirPressureGradient: this.ValidateZonePorePressureGradient(well, rows, rowIndex),
      FracturePressureGradient: this.ValidateZoneFracPressureGradient(well, rows, rowIndex),
      ReservoirPressure: this.ValidateZonePorePressure(well, rows, rowIndex),
      FracturePressure: this.ValidateZoneFracPressure(well, rows, rowIndex),
      ReservoirPressureEMW: this.ValidateZonePorePressureEMW(well, rows, rowIndex),
      FracturePressureEMW: this.ValidateZoneFracPressureEMW(well, rows, rowIndex),
      PermeabilityToReservoirFluid: ignoreZoneProps ? '' : this.ValidateZoneReservoirFluidPermeability(well, rows, rowIndex),
      PermeabilityToInjectedFluid: ignoreZoneProps ? '' : this.ValidateZoneInjectedFluidPermeability(well, rows, rowIndex),
      Porosity: ignoreZoneProps ? '' : this.ValidateZonePorosity(well, rows, rowIndex),
      Skin: ignoreZoneProps ? '' : this.ValidateZoneSkin(well, rows, rowIndex),
      LossPercentage: ignoreZoneProps ? '' : this.ValidateZoneLossPercentage(well, rows, rowIndex),
      FluidLossCoefficient: ignoreZoneProps ? '' : this.ValidateZoneFluidLossCoefficient(well, rows, rowIndex),
      ReservoirFluidViscosity: ignoreZoneProps ? '' : this.ValidateZoneReservoirFluidViscosity(well, rows, rowIndex),
      YoungsModulus: ignoreZoneProps ? '' : this.ValidateZoneYoungsModulus(well, rows, rowIndex),
      PoissonsRatio: ignoreZoneProps ? '' : this.ValidateZonePoissonsRatio(well, rows, rowIndex),
      LossZoneType: ignoreZoneProps ? '' : this.ValidateZoneLossType(well, rows, deps),
      Height: '',
      Length: '',
      Name: '',
      PlaneStrainModulus: '',
    };

    return { ...row, error: zoneErrors, isValid: noErrors(zoneErrors), isWarning: false, warning: {} };
  }

  private static ValidateZoneTopMD(
    well: WellModuleState,
    rows: ZoneModel[],
    rowIndex: number,
    developerSettings: DeveloperSettingsDto,
  ): string {
    if (well.IsZoneDepthByMD && rowIndex === 0) {
      const topMD: number = rows[rowIndex].TopMD;
      const firstReservoir = well.CasingData.rows.find((pipe) => pipe.rowData.IsReservoirSection);
      if (firstReservoir && topMD > firstReservoir.rowData.TopMD) {
        return 'First row top MD must be less than or equal to top MD of the reservoir section on the Casing Data input screen';
      }
    }
    return '';
  }

  private static ValidateZoneBottomMD(
    well: WellModuleState,
    rows: ZoneModel[],
    rowIndex: number,
    developerSettings: DeveloperSettingsDto,
  ): string {
    if (well.IsZoneDepthByMD) {
      const topMD: number = rows[rowIndex].TopMD;
      const bottomMD: number = rows[rowIndex].BottomMD;
      const maxMd: number = ArrayHelpers.last(getRowsForCalculations(well.SurveyData.rows))?.MD ?? 0;
      if (bottomMD <= 0) {
        return 'Bottom MD must be greater than zero';
      } else if (bottomMD <= topMD) {
        return 'Bottom MD must be greater than top MD';
      } else if (bottomMD >= maxMd && topMD >= maxMd) {
        return 'Zone must not be entirely below survey max MD';
      } else if (rowIndex === rows.length - 1) {
        const lastReservoir = ArrayHelpers.findLast(well.CasingData.rows, (pipe) => pipe.rowData.IsReservoirSection);
        if (lastReservoir && bottomMD < lastReservoir.rowData.BottomMD) {
          return 'Last row bottom MD must be greater than or equal to bottom MD of the reservoir section on the Casing Data input screen';
        }
      }

      if (well.TreatmentType === TreatmentType.High_Rate_Water_Pack) {
        const perforatedCasingPipes = well.CasingData.rows.filter((pipe) => pipe.rowData.PipeType === PipeType.Perforated_Casing);
        if (perforatedCasingPipes.some((casing) => bottomMD > casing.rowData.TopMD && bottomMD < casing.rowData.BottomMD)) {
          return 'Multiple zones across a perforated interval are not supported in HRWP treatments';
        }
      }
    }
    return '';
  }

  private static ValidateZoneTopTVD(
    well: WellModuleState,
    rows: ZoneModel[],
    rowIndex: number,
    developerSettings: DeveloperSettingsDto,
  ): string {
    if (!well.IsZoneDepthByMD && rowIndex === 0) {
      const topTVD = rows[rowIndex].TopTVD;
      const firstReservoir = well.CasingData.rows.find((pipe) => pipe.rowData.IsReservoirSection);
      const surveys = getRowsForCalculations(well.SurveyData.rows);
      const maxMd: number = ArrayHelpers.last(surveys)?.MD ?? 0;
      if (firstReservoir && firstReservoir.rowData.TopMD <= maxMd) {
        const reservoirTopTVD = SurveyCalculations.MDToTVD(surveys, firstReservoir.rowData.TopMD);
        if (topTVD > reservoirTopTVD) {
          return 'First row top TVD must be less than or equal to top TVD of the reservoir section on the Casing Data input screen';
        }
      }
    }
    return '';
  }

  private static ValidateZoneBottomTVD(well: WellModuleState, rows: ZoneModel[], rowIndex: number): string {
    if (!well.IsZoneDepthByMD) {
      const topTVD = rows[rowIndex].TopTVD;
      const bottomTVD = rows[rowIndex].BottomTVD;
      const surveys = getRowsForCalculations(well.SurveyData.rows);
      const maxTVD = Math.max(...surveys.map((row) => row.TVD));

      if (bottomTVD <= 0) {
        return 'Bottom TVD must be greater than zero';
      } else if (bottomTVD <= topTVD) {
        return 'Bottom TVD must be greater than top TVD';
      } else if (bottomTVD >= maxTVD && topTVD >= maxTVD) {
        return 'Zone must not be entirely below survey max TVD';
      } else if (rowIndex === rows.length - 1 && bottomTVD < maxTVD) {
        return 'Last row bottom TVD must be greater than or equal to survey max TVD';
      }

      if (well.TreatmentType === TreatmentType.High_Rate_Water_Pack) {
        const perforatedCasingPipes = well.CasingData.rows.filter((pipe) => pipe.rowData.PipeType === PipeType.Perforated_Casing);

        if (
          perforatedCasingPipes.some((casing) => {
            const casingTopTVD = MathHelpers.round(SurveyCalculations.MDToTVD(surveys, casing.rowData.TopMD), 2);
            const casingBottomTVD = MathHelpers.round(SurveyCalculations.MDToTVD(surveys, casing.rowData.BottomMD), 2);
            return bottomTVD > casingTopTVD && bottomTVD < casingBottomTVD;
          })
        ) {
          return 'Multiple zones across a perforated interval are not supported in HRWP treatments';
        }
      }
    }
    return '';
  }

  private static ValidateZonePorePressureGradient(well: WellModuleState, rows: ZoneModel[], rowIndex: number): string {
    if (well.ZonePressureType === ZonePressureType.Pressure_Gradient) {
      const porePressure = rows[rowIndex].ReservoirPressureGradient;
      const fracPressure = rows[rowIndex].FracturePressureGradient;
      if (porePressure <= 0) {
        return 'Pore pressure gradient must be greater than zero';
      } else if (porePressure >= fracPressure) {
        return 'Pore pressure gradient must be less than fracture pressure gradient';
      }
    }
    return '';
  }

  private static ValidateZoneFracPressureGradient(well: WellModuleState, rows: ZoneModel[], rowIndex: number): string {
    if (well.ZonePressureType === ZonePressureType.Pressure_Gradient) {
      const porePressure = rows[rowIndex].ReservoirPressureGradient;
      const fracPressure = rows[rowIndex].FracturePressureGradient;
      if (fracPressure <= 0) {
        return 'Fracture pressure gradient must be greater than zero';
      } else if (fracPressure <= porePressure) {
        return 'Fracture pressure gradient must be greater than pore pressure gradient';
      }
    }
    return '';
  }

  private static ValidateZonePorePressure(well: WellModuleState, rows: ZoneModel[], rowIndex: number): string {
    if (well.ZonePressureType === ZonePressureType.Pressure) {
      const porePressure: number = rows[rowIndex].ReservoirPressure;
      const fracPressure: number = rows[rowIndex].FracturePressure;
      if (porePressure <= 0) {
        return 'Pore pressure must be greater than zero';
      } else if (porePressure >= fracPressure) {
        return 'Pore pressure must be less than fracture pressure';
      }
    }
    return '';
  }

  private static ValidateZoneFracPressure(well: WellModuleState, rows: ZoneModel[], rowIndex: number): string {
    if (well.ZonePressureType === ZonePressureType.Pressure) {
      const porePressure = rows[rowIndex].ReservoirPressure;
      const fracPressure = rows[rowIndex].FracturePressure;
      if (fracPressure <= 0) {
        return 'Fracture pressure must be greater than zero';
      } else if (fracPressure <= porePressure) {
        return 'Fracture pressure must be greater than pore pressure';
      }
    }
    return '';
  }

  private static ValidateZonePorePressureEMW(well: WellModuleState, rows: ZoneModel[], rowIndex: number): string {
    if (well.ZonePressureType === ZonePressureType.Equivalent_Mud_Weight) {
      const porePressure = rows[rowIndex].ReservoirPressureEMW;
      const fracPressure = rows[rowIndex].FracturePressureEMW;
      if (porePressure <= 0) {
        return 'Pore pressure EMW must be greater than zero';
      } else if (porePressure >= fracPressure) {
        return 'Pore pressure EMW must be less than fracture pressure EMW';
      }
    }
    return '';
  }

  private static ValidateZoneFracPressureEMW(well: WellModuleState, rows: ZoneModel[], rowIndex: number): string {
    if (well.ZonePressureType === ZonePressureType.Equivalent_Mud_Weight) {
      const porePressure = rows[rowIndex].ReservoirPressureEMW;
      const fracPressure = rows[rowIndex].FracturePressureEMW;
      if (fracPressure <= 0) {
        return 'Fracture pressure EMW must be greater than zero';
      } else if (fracPressure <= porePressure) {
        return 'Fracture pressure EMW must be greater than pore pressure EMW';
      }
    }
    return '';
  }

  private static ValidateZoneReservoirFluidPermeability(well: WellModuleState, rows: ZoneModel[], rowIndex: number): string {
    if (well.IsLossRateCalculated) {
      const perm = rows[rowIndex].PermeabilityToReservoirFluid;
      if (perm <= 0 || perm > MAX_PERMEABILITY_SI) {
        const encodedMaxPerm = ConvertUnitPipe.encode(UnitSystem.Permeability, MAX_PERMEABILITY_SI);
        const encodedUnitSymbol = ConvertUnitPipe.encodeUnitSymbol(UnitSystem.Permeability);

        return `Permeability must be between 0 and ${encodedMaxPerm} ${encodedUnitSymbol}`;
      }
    }
    return '';
  }

  private static ValidateZoneInjectedFluidPermeability(well: WellModuleState, rows: ZoneModel[], rowIndex: number): string {
    if (well.IsLossRateCalculated) {
      const perm = rows[rowIndex].PermeabilityToInjectedFluid;
      if (perm <= 0 || perm > MAX_PERMEABILITY_SI) {
        const encodedMaxPerm = ConvertUnitPipe.encode(UnitSystem.Permeability, MAX_PERMEABILITY_SI);
        const encodedUnitSymbol = ConvertUnitPipe.encodeUnitSymbol(UnitSystem.Permeability);
        return `Permeability must be between 0 and ${encodedMaxPerm} ${encodedUnitSymbol}`;
      }
    }
    return '';
  }

  private static ValidateZonePorosity(well: WellModuleState, rows: ZoneModel[], rowIndex: number): string {
    if (well.IsLossRateCalculated) {
      const porosity = rows[rowIndex].Porosity;
      if (porosity <= 0 || porosity > 50) {
        return 'Porosity must be between 0 and 50';
      }
    }
    return '';
  }

  private static ValidateZoneSkin(well: WellModuleState, rows: ZoneModel[], rowIndex: number): string {
    if (well.IsLossRateCalculated) {
      const skin = rows[rowIndex].Skin;
      if (skin <= -5 || skin >= 10000) {
        return 'Skin must be between -5 and 10000';
      }
    }
    return '';
  }

  private static ValidateZoneLossPercentage(well: WellModuleState, rows: ZoneModel[], rowIndex: number): string {
    const currentZone = rows[rowIndex];
    const lossZoneType = currentZone.LossZoneType;
    const allCasings = getRowsForCalculations(well.CasingData.rows);
    const surveys = getRowsForCalculations(well.SurveyData.rows);
    const maxMd: number = ArrayHelpers.last(surveys)?.MD ?? 0;

    if (!well.IsLossRateCalculated && lossZoneType !== LossZoneType.None) {
      const lossPercentage = currentZone.LossPercentage;
      const totalLossPercentage = ArrayHelpers.sum(rows.map((row) => row.LossPercentage));
      if (lossPercentage <= 0 || lossPercentage > 100) {
        return 'Loss percentage must be between 0 and 100';
      } else if (totalLossPercentage !== 100) {
        return 'Sum of loss percentage for all zones must be 100';
      } else if (currentZone.LossZoneType === LossZoneType.Single_Point) {
        let casing: Pipe | undefined;
        if (well.IsZoneDepthByMD) {
          const currentZoneTopMD = MathHelpers.round(currentZone.TopMD, 2);
          casing = ArrayHelpers.findLast(allCasings, (cas) => MathHelpers.round(cas.TopMD, 2) <= currentZoneTopMD);
        } else {
          casing = ArrayHelpers.findLast(
            allCasings,
            (cas) =>
              cas.TopMD <= maxMd &&
              MathHelpers.round(SurveyCalculations.MDToTVD(surveys, cas.TopMD), 2) <= MathHelpers.round(currentZone.TopTVD, 2),
          );
        }
        if (!casing || !casing.IsReservoirSection) {
          return 'Single point losses must be located in a perforated or open hole section';
        }
      } else {
        let casings: Pipe[];
        if (well.IsZoneDepthByMD) {
          casings = allCasings.filter(
            (cas) =>
              MathHelpers.round(cas.TopMD, 2) < MathHelpers.round(currentZone.BottomMD, 2) &&
              MathHelpers.round(cas.BottomMD, 2) > MathHelpers.round(currentZone.TopMD, 2),
          );
        } else {
          casings = allCasings.filter((cas) => {
            const topTVD = MathHelpers.round(SurveyCalculations.MDToTVD(surveys, cas.TopMD), 2);
            const bottomTVD = MathHelpers.round(SurveyCalculations.MDToTVD(surveys, cas.BottomMD), 2);
            return (
              cas.TopMD <= maxMd &&
              cas.BottomMD <= maxMd &&
              topTVD < MathHelpers.round(currentZone.BottomTVD, 2) &&
              bottomTVD > MathHelpers.round(currentZone.TopTVD, 2)
            );
          });
        }
        if (casings.length <= 0) {
          return 'Loss zones must be located across perforated or open hole sections';
        } else if (!casings.some((cas) => cas.IsReservoirSection)) {
          return 'Loss zones must be located across perforated or open hole sections';
        }
      }
    }
    return '';
  }

  private static ValidateZoneFluidLossCoefficient(well: WellModuleState, rows: ZoneModel[], rowIndex: number): string {
    if (well.TreatmentType === TreatmentType.High_Rate_Water_Pack) {
      const lossCoefficient = rows[rowIndex].FluidLossCoefficient;
      if (lossCoefficient <= 0) {
        return 'Fluid loss coefficient must be greater than 0';
      }
    }

    return '';
  }

  private static ValidateZoneReservoirFluidViscosity(well: WellModuleState, rows: ZoneModel[], rowIndex: number): string {
    if (well.IsLossRateCalculated) {
      const viscosity: number = rows[rowIndex].ReservoirFluidViscosity;
      if (viscosity <= 0) {
        return 'Reservoir fluid viscosity must be greater than 0';
      }
    }
    return '';
  }

  private static ValidateZoneYoungsModulus(well: WellModuleState, rows: ZoneModel[], rowIndex: number): string {
    if (well.TreatmentType === TreatmentType.High_Rate_Water_Pack) {
      const youngsModulus: number = rows[rowIndex].YoungsModulus;
      if (youngsModulus < this.MinYoungsModulus || youngsModulus > this.MaxYoungsModulus) {
        const encodedMinYM = ConvertUnitPipe.encode(UnitSystem.Pressure, this.MinYoungsModulus);
        const encodedMaxYM = ConvertUnitPipe.encode(UnitSystem.Pressure, this.MaxYoungsModulus);
        const symbol = ConvertUnitPipe.encodeUnitSymbol(UnitSystem.Pressure);

        return `Young's modulus must be between ${encodedMinYM} and ${encodedMaxYM} ${symbol}`;
      }
    }
    return '';
  }

  private static ValidateZonePoissonsRatio(well: WellModuleState, rows: ZoneModel[], rowIndex: number): string {
    if (well.TreatmentType === TreatmentType.High_Rate_Water_Pack) {
      const poissonsRatio = rows[rowIndex].PoissonsRatio;
      if (poissonsRatio < 0.1 || poissonsRatio > 0.5) {
        return "Poisson's ratio must be between 0.1 and 0.5";
      }
    }
    return '';
  }

  private static ValidateZoneLossType(well: WellModuleState, rows: ZoneModel[], deps: IWellValidationDependencies): string {
    if (
      deps.currentModuleType === ModuleType.Evaluate &&
      !well.IsLossRateCalculated &&
      deps.pumpingModule.pumping.ToolPosition !== ToolPosition.Reverse
    ) {
      const isLossZoneDefined = rows.some((zone) => zone.LossZoneType !== LossZoneType.None);
      if (!isLossZoneDefined) {
        return 'Loss zones must be defined for evaluation';
      }
    }
    return '';
  }
}
