import { UnitConverter } from '../../converter.interfaces';
import { PressureTimeDerivativeUnit } from '../../../dto/unit-system.dto';

export class PressureTimeDerivativeConverter extends UnitConverter {
  public static symbols: Array<string> = [
    '',
    'Pa/s',
    'Pa/h',
    'Pa/min',
    'kPa/s',
    'kPa/h',
    'kPa/min',
    'psi/s',
    'psi/h',
    'psi/min',
    'kg/cm²/s',
    'kg/cm²/h',
    'kg/cm²/min',
    'bar/s',
    'bar/h',
    'bar/min',
    'Pa/day',
    'kPa/day',
    'psi/day',
    'kg/cm²/day',
    'bar/day',
    'MPa/s',
    'MPa/h',
    'MPa/min',
    'MPa/day',
  ];

  public static unit = PressureTimeDerivativeUnit;

  public static fromSi(value: number, unit: PressureTimeDerivativeUnit): number {
    switch (unit) {
      case PressureTimeDerivativeUnit.Pascal_per_second: {
        return value;
      }
      case PressureTimeDerivativeUnit.Pascal_per_hour: {
        return value * 3600;
      }
      case PressureTimeDerivativeUnit.Pascal_per_minute: {
        return value * 60;
      }
      case PressureTimeDerivativeUnit.Kilo_Pascal_per_second: {
        return value * 1e-3;
      }
      case PressureTimeDerivativeUnit.Kilo_Pascal_per_hour: {
        return value * 3.6;
      }
      case PressureTimeDerivativeUnit.Kilo_Pascal_per_minute: {
        return value * 0.06;
      }
      case PressureTimeDerivativeUnit.Pound_per_square_inch_per_second: {
        return value * 0.0001450377377;
      }
      case PressureTimeDerivativeUnit.Pound_per_square_inch_per_hour: {
        return value * 0.5221358558;
      }
      case PressureTimeDerivativeUnit.Pound_per_square_inch_per_minute: {
        return value * 0.008702264264;
      }
      case PressureTimeDerivativeUnit.Kilogram_per_square_centimeter_per_second: {
        return value * 0.00001019716213;
      }
      case PressureTimeDerivativeUnit.Kilogram_per_square_centimeter_per_hour: {
        return value * 0.03670978367;
      }
      case PressureTimeDerivativeUnit.Kilogram_per_square_centimeter_per_minute: {
        return value * 0.0006118297278;
      }
      case PressureTimeDerivativeUnit.Bar_per_second: {
        return value * 0.00001;
      }
      case PressureTimeDerivativeUnit.Bar_per_hour: {
        return value * 0.036;
      }
      case PressureTimeDerivativeUnit.Bar_per_minute: {
        return value * 0.0006;
      }
      case PressureTimeDerivativeUnit.Pascal_per_day: {
        return value * 8.64e4;
      }
      case PressureTimeDerivativeUnit.Kilo_Pascal_per_day: {
        return value * 8.64e1;
      }
      case PressureTimeDerivativeUnit.Pound_per_square_inch_per_day: {
        return value * 1.253126054e1;
      }
      case PressureTimeDerivativeUnit.Kilogram_per_square_centimeter_per_day: {
        return value * 8.81034808e-1;
      }
      case PressureTimeDerivativeUnit.Bar_per_day: {
        return value * 8.64e-1;
      }

      case PressureTimeDerivativeUnit.MPa_per_second: {
        return value * 1e-6;
      }
      case PressureTimeDerivativeUnit.MPa_per_hour: {
        return value * 3.6e-3;
      }
      case PressureTimeDerivativeUnit.MPa_per_minute: {
        return value * 6e-5;
      }
      case PressureTimeDerivativeUnit.MPa_per_day: {
        return value * 8.64e-2;
      }
      default: {
        throw new TypeError('Unknown Pressure Time Derivative Unit:' + unit);
      }
    }
  }

  public static toSi(value: number, unit: PressureTimeDerivativeUnit): number {
    switch (unit) {
      case PressureTimeDerivativeUnit.Pascal_per_second: {
        return value;
      }
      case PressureTimeDerivativeUnit.Pascal_per_hour: {
        return value / 3600;
      }
      case PressureTimeDerivativeUnit.Pascal_per_minute: {
        return value / 60;
      }
      case PressureTimeDerivativeUnit.Kilo_Pascal_per_second: {
        return value / 1e-3;
      }
      case PressureTimeDerivativeUnit.Kilo_Pascal_per_hour: {
        return value / 3.6;
      }
      case PressureTimeDerivativeUnit.Kilo_Pascal_per_minute: {
        return value / 0.06;
      }
      case PressureTimeDerivativeUnit.Pound_per_square_inch_per_second: {
        return value / 0.0001450377377;
      }
      case PressureTimeDerivativeUnit.Pound_per_square_inch_per_hour: {
        return value / 0.5221358558;
      }
      case PressureTimeDerivativeUnit.Pound_per_square_inch_per_minute: {
        return value / 0.008702264264;
      }
      case PressureTimeDerivativeUnit.Kilogram_per_square_centimeter_per_second: {
        return value / 0.00001019716213;
      }
      case PressureTimeDerivativeUnit.Kilogram_per_square_centimeter_per_hour: {
        return value / 0.03670978367;
      }
      case PressureTimeDerivativeUnit.Kilogram_per_square_centimeter_per_minute: {
        return value / 0.0006118297278;
      }
      case PressureTimeDerivativeUnit.Bar_per_second: {
        return value / 0.00001;
      }
      case PressureTimeDerivativeUnit.Bar_per_hour: {
        return value / 0.036;
      }
      case PressureTimeDerivativeUnit.Bar_per_minute: {
        return value / 0.0006;
      }
      case PressureTimeDerivativeUnit.Pascal_per_day: {
        return value / 8.64e4;
      }
      case PressureTimeDerivativeUnit.Kilo_Pascal_per_day: {
        return value / 8.64e1;
      }
      case PressureTimeDerivativeUnit.Pound_per_square_inch_per_day: {
        return value / 1.253126054e1;
      }
      case PressureTimeDerivativeUnit.Kilogram_per_square_centimeter_per_day: {
        return value / 8.81034808e-1;
      }
      case PressureTimeDerivativeUnit.Bar_per_day: {
        return value / 8.64e-1;
      }

      case PressureTimeDerivativeUnit.MPa_per_second: {
        return value / 1e-6;
      }
      case PressureTimeDerivativeUnit.MPa_per_hour: {
        return value / 3.6e-3;
      }
      case PressureTimeDerivativeUnit.MPa_per_minute: {
        return value / 6e-5;
      }
      case PressureTimeDerivativeUnit.MPa_per_day: {
        return value / 8.64e-2;
      }
      default: {
        throw new TypeError('Unknown Pressure Time Derivative Unit:' + unit);
      }
    }
  }

  public static getSymbol(unit: PressureTimeDerivativeUnit): string {
    if (unit == null) {
      return '';
    }
    return this.symbols[unit];
  }
}
