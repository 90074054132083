import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  NgModule,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { StopEditingKey } from '../units/components/input-2/input-2.component';
import { SelectModule } from '../../shared/components/form-components/select/select.module';
import { getSelectItemValue, ISelectItem } from '@dunefront/common/common/select.helpers';
import { TooltipModule } from 'primeng/tooltip';
import { FormDataHelper } from '../../shared/components/form-components/base-form-component';
import { DataSource, DataSourceKey, DataSourceValue, ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';
import { SelectComponent } from '../../shared/components/form-components/select/select.component';
import { ClickOutsideModule } from '../../shared/directives/click-outside.directive';

@Component({
  selector: 'app-grid-select',
  templateUrl: './grid-select.component.html',
  styleUrls: ['./grid-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridSelectComponent<T> implements OnChanges {
  @Input() public dataSource: ISelectItem<DataSourceValue<T>>[] = [];
  @Input() public source: DataSource<T> | undefined;
  @Input() public key: DataSourceKey<T> | undefined;
  @Input() public isInEditMode = false;

  @Output() public valuedChanged = new EventEmitter<ObjectChangeProp<T>>();
  @Output() public stopEditing = new EventEmitter<StopEditingKey>();
  @Input() public renderAsLabel = false;
  @Input() public alignCenter = false;
  @Input() public errorMessage = '';
  @Input() public warningMessage = '';
  @Input() public placeholder!: string;
  @Input() public dataCy = '';
  @Output() public changeFocusCell = new EventEmitter();
  @Input() public selected = false;

  @ViewChild('selectComponent') public selectComponent?: SelectComponent<any, any>;

  @Input()
  @HostBinding()
  public id = '';
  public getLookupValue = (dataSource: ISelectItem<DataSourceValue<T>>[]): string => {
    const value = FormDataHelper.getSourceValue(this.source, this.key);
    if (value != null) {
      const selectedItemValue = getSelectItemValue(value, dataSource);
      if (selectedItemValue.length > 0) {
        return selectedItemValue;
      }
    }
    return this.placeholder;
  };

  public get tooltipMessage(): string {
    return this.errorMessage || this.warningMessage;
  }

  public get tooltipStyleClass(): string {
    return this.errorMessage ? 'error-tooltip' : this.warningMessage ? 'warning-tooltip' : '';
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.renderAsLabel != null) {
      const { previousValue, currentValue } = changes.renderAsLabel;
      if (!this.selected && currentValue === false && previousValue === true) {
        // if renderAsLabel was true, and now it's false (no undefined) it means that it's now focused.
        // when cell is double-clicked ( wasn't selected before ) - open the dropdown
        this.openDropdown();
      }
    }

    // open dropdown when editCell in GridComponent is changed ( f.e. after pressing enter or tab )
    if (changes.isInEditMode != null && changes.isInEditMode.currentValue) {
      this.openDropdown();
    }
  }

  public valueChanged(event: any): void {
    this.valuedChanged.emit(event);
  }

  private openDropdown(): void {
    // At this point selectComponent does not exist, so we need to wait for it to render, and open dropdown manually
    setTimeout(() => {
      this.selectComponent?.openDropdown();
    }, 50);
  }

  public onHide(): void {
    this.stopEditing.emit('None');
  }
}

@NgModule({
  imports: [CommonModule, SelectModule, TooltipModule, ClickOutsideModule],
  declarations: [GridSelectComponent],
  exports: [GridSelectComponent],
})
export class GridSelectComponentModule {}
