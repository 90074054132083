import { CommonDbType } from '../../dto/common-dto.interfaces';
import { IBaseTemplateDto } from './templates.interfaces';

export class TemplateParser {
  public static decode<T>(text: string): T {
    return JSON.parse(text) as T;
  }

  public static encode<T>(config: T): string {
    return JSON.stringify(config);
  }
}

export const encodeTemplateId = (id: number, type: CommonDbType | undefined): string => {
  return `${id}${type === undefined ? '' : '_' + type}`;
};

export const getNoneTemplateTemplateId = (): string => encodeTemplateId(-1, undefined);
export const getModifiedTemplateTemplateId = (): string => encodeTemplateId(-2, undefined);

export const decodeTemplateId = (encodedId: string): { id: number; type: CommonDbType } => {
  const [id, type] = encodedId.split('_');
  return { id: +id, type: type as CommonDbType };
};

export const sortTemplates = <T extends IBaseTemplateDto>(templates: T[]): T[] => {
  return [...templates].sort((t1, t2) => (t1.Type + t1.Name).localeCompare(t2.Type + t2.Name));
};

export const sortTemplatesByDb = <T extends IBaseTemplateDto>(templates: T[]): T[] => {
  const compareTemplatesWithDbType = (t1: IBaseTemplateDto, t2: IBaseTemplateDto): number => {
    if (t1.Type === t2.Type) {
      return t1.Name.localeCompare(t2.Name);
    }
    return t1.Type === 'User' || (t1.Type === 'Organization' && t2.Type === 'Predefined') ? -1 : 1;
  };
  return [...templates].sort(compareTemplatesWithDbType);
};

export enum EditTemplateModalMode {
  update = 0,
  create = 1,
}
