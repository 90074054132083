<div class="grid-form-container cols-4" *ngIf="colConfig">
  <app-form-input
    appUnwrap
    label="Column Name"
    [source]="colConfig"
    [key]="'name'"
    [unitType]="UnitType.None"
    [isTextInput]="true"
    [maxWidth]="200"
    [width]="200"
    (valueChanged)="onColConfigChanged($event)"
    [dataCy]="'column-properties-column-name'"
  ></app-form-input>

  <label>Data Type</label>
  <app-select
    [items]="importColumnTypeItems"
    (primitiveValueChanged)="onImportColumnTypeChanged($event)"
    [source]="colConfig"
    [key]="'columnType'"
    [width]="200"
    [dataCy]="'column-properties-data-type'"
  ></app-select>

  <ng-container *ngIf="isMeasurementInputsVisible">
    <label>Measurement Type</label>
    <app-select
      label="Measurement Type"
      [width]="200"
      [disabled]="!isMeasurementEnabled"
      [items]="measurementTypes"
      [source]="colConfig"
      [key]="'measurementType'"
      [value]="colConfig.measurementType"
      (primitiveValueChanged)="onMeasurementTypeChanged($event)"
      [dataCy]="'column-properties-measurement-type'"
    ></app-select>
  </ng-container>

  <ng-container *ngIf="isMeasurementInputsVisible">
    <label>Measured Depth</label>
    <app-form-input
      appUnwrap
      [disabled]="!isMeasurementEnabled"
      [inputWidth]="200"
      [unitType]="UnitType.Long_Length"
      [source]="colConfig"
      [key]="'measuredDepth'"
      [dataCy]="'column-properties-measured-depth'"
      (valueChanged)="onColConfigChanged($event)"
    ></app-form-input>
  </ng-container>

  <label>Unit System</label>
  <app-select
    [width]="200"
    [items]="unitSystemItems"
    [disabled]="!isUnitSystemEnabled"
    [source]="colConfig"
    [key]="'unitSystem'"
    [value]="colConfig.unitSystem"
    (primitiveValueChanged)="onUnitTypeChanged($event)"
  ></app-select>

  <label>Units</label>
  <app-select
    [width]="200"
    [items]="unitSymbolsItems"
    [disabled]="!isUnitSymbolsEnabled"
    [dataCy]="'column-properties-units'"
    [value]="colConfig.unitSymbol"
    (primitiveValueChanged)="onUnitSymbolChanged($event)"
  ></app-select>
  <div class="col-span-2"></div>

  <app-check-box
    [source]="colConfig"
    [key]="'isTimeCustomFormat'"
    label="Custom Format"
    [disabled]="!isCustomFormatCbEnabled"
    class="cb-time-custom-format"
    (valueChanged)="onColConfigChanged($event)"
  ></app-check-box>
  <div class="time-custom-panel">
    <app-form-input
      [source]="colConfig"
      [key]="'timeCustomFormat'"
      appUnwrap
      [disabled]="!colConfig.isTimeCustomFormat"
      [isTextInput]="true"
      [maxWidth]="200"
      [width]="200"
      [dataCy]="'column-properties-custom-time'"
      [unitType]="UnitType.None"
      (valueChanged)="onColConfigChanged($event)"
      [isStringComparisonStrict]="true"
    ></app-form-input>

    <div class="time-format-suggestions" *ngIf="colConfig.isTimeCustomFormat" appDateFormatPosition>
      <p-chip
        styleClass="time-format-suggestion {{ format === colConfig.timeCustomFormat ? 'active' : '' }}"
        *ngFor="let format of colConfig.possibleCustomFormats"
        label="{{ format }}"
        (click)="selectTimeFormat(format)"
      ></p-chip>
    </div>

    <button class="btn btn-light btn-sm btn-sm" (click)="onHelpClick()">{{ isHelpOpen ? 'Close help' : 'Help' }}</button>
  </div>
</div>
