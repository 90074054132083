import { createAction, props } from '@ngrx/store';
import { AboutServerDto } from '@dunefront/common/dto/about-server.dto';
import { UpdateInfoDto } from '@dunefront/common/dto/update.dto';
import { LicenseWithSessionsInfo } from '@dunefront/common/modules/licensing/licensing.interfaces';

export const aboutServerAction = createAction('[AboutModule] AboutServer');
export const aboutServerSuccessAction = createAction('[AboutModule] AboutServerSuccess', props<{ aboutServerResult: AboutServerDto }>());

export const checkForUpdatesAction = createAction('[AboutModule] CheckForUpdates', props<{ isPeriodicCheck: boolean }>());
export const checkForUpdatesSuccessAction = createAction('[AboutModule] CheckForUpdatesSuccess', props<{ updateInfo: UpdateInfoDto }>());
export const resetUpdatesInfoAction = createAction('[AboutModule] resetUpdatesInfo');

export const fetchDownloadUrl = createAction('[AboutModule] FetchDownloadLink', props<{ version: string }>());
export const fetchDownloadUrlSuccess = createAction('[AboutModule] fetchDownloadLinkSuccess', props<{ url: string }>());
export const resetDownloadUrl = createAction('[AboutModule] resetDownloadUrl');

export const fetchLicenseSessions = createAction('[AboutModule] getLicenseSessions', props<{ licenseId: string }>());
export const fetchLicenseSessionsSuccess = createAction(
  '[AboutModule] fetchLicenseSessionsSuccess',
  props<{ response: LicenseWithSessionsInfo[] }>(),
);

export const killLicenseSession = createAction('[AboutModule] killLicenseSession', props<{ licenseId: string; sessionId: string }>());

export const performPeriodicVersionCheck = createAction('[AboutModule] performPeriodicVersionCheck');
