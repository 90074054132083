import { createSelector } from '@ngrx/store';
import { IWellValidationDependencies } from '@dunefront/common/modules/well/well-module.state';
import { getValidatedDeveloperSettings, getValidatedSettings } from '../settings/validated-settings.selectors';
import { getCurrentAppModuleType } from '../ui/ui.selectors';
import { getCurrentPumpingModuleState } from '../pumping/selectors/pumping.selectors';
import { WellValidation } from '@dunefront/common/modules/well/validation/well.validation';
import { getMaxSurveyMD, getSurveyDataForCalculation, getWellModuleState, WellOrientation } from './well.selectors';
import { getCurrentFeatures } from '../licensing/licensing.selectors';

const getWellValidationDeps = createSelector(
  getValidatedDeveloperSettings,
  getCurrentAppModuleType,
  getCurrentPumpingModuleState,
  getCurrentFeatures,
  getValidatedSettings,
  (developerSettings, currentModuleType, pumpingModule, currentLicenseFeatures, settings): IWellValidationDependencies => ({
    developerSettings,
    currentModuleType,
    pumpingModule,
    currentLicenseFeatures,
    settings,
  }),
);
export const getValidatedWellModuleState = createSelector(
  getWellModuleState,
  getMaxSurveyMD,
  getWellValidationDeps,
  (state, maxSurveyMD, deps) => WellValidation.validate(state, maxSurveyMD, deps),
);

export const getTemperatureProfile = createSelector(getValidatedWellModuleState, (state) => state.TemperatureProfile);

export const getWellOrientation = createSelector(getSurveyDataForCalculation, (survey) => {
  const maxDeviation = Math.max(...survey.map((item) => item.Deviation));

  const wellOrientation: WellOrientation = maxDeviation < 45 ? 'vertical' : 'horizontal';

  return wellOrientation;
});
