import { ReportInfoModuleState, ReportInfoRangesConfig } from './report-info.reducer';
import { createEmptyReportInfoFields, ReportInfoDto, ReportInfoFields } from '@dunefront/common/dto/report-info.dto';
import { ToggleInfoFieldsActionPayload } from './report-info.actions';
import { ActionResponse } from '@dunefront/common/response-ws.action';
import { DataSourceValue } from '@dunefront/common/common/common-state.interfaces';

export class ReportInfoReducerHelper {
  public static ReportInfoLoadSuccess(state: ReportInfoModuleState, action: ActionResponse<ReportInfoDto>): ReportInfoModuleState {
    if (!action.payload) {
      return state;
    }
    return {
      ...state,
      reportInfo: action.payload,
    };
  }

  public static UpdateReportInfoFields(
    state: ReportInfoModuleState,
    action: { changes: { value: DataSourceValue<ReportInfoFields>; changedKey: keyof ReportInfoFields }[] },
  ): ReportInfoModuleState {
    let reportInfoFields = { ...state.reportInfoFields };
    for (const change of action.changes) {
      reportInfoFields = { ...reportInfoFields, [change.changedKey]: change.value };
      if (change.changedKey === 'IsWell' && change.value === false) {
        reportInfoFields = { ...reportInfoFields, IsWellSurveyData: false, IsWellCaliperData: false };
      }
    }

    return { ...state, reportInfoFields };
  }

  public static UpdateReportInfoRangesConfig(
    state: ReportInfoModuleState,
    action: { value: DataSourceValue<ReportInfoRangesConfig>; changedKey: keyof ReportInfoRangesConfig },
  ): ReportInfoModuleState {
    const rangesConfig = { ...state.rangesConfig, [action.changedKey]: action.value };

    return { ...state, rangesConfig };
  }

  public static ResetRangeSelection(state: ReportInfoModuleState): ReportInfoModuleState {
    return {
      ...state,
      rangesConfig: { ...state.rangesConfig, RangesSelectorOrder: [] },
      reportInfoFields: createEmptyReportInfoFields(),
    };
  }

  public static SelectAllClearAll(state: ReportInfoModuleState, action: ToggleInfoFieldsActionPayload): ReportInfoModuleState {
    const isPPT = state.reportInfoFields.ReportType === 'pptx';

    return {
      ...state,
      rangesConfig: { ...state.rangesConfig },
      reportInfoFields: {
        ...state.reportInfoFields,
        IsWell: action.selectAll && action.isInputDataVisible,
        IsWellSurveyData: action.selectAll && !isPPT && action.isInputDataVisible,
        IsWellCaliperData: action.selectAll && !isPPT && action.isInputDataVisible,
        IsFluids: action.selectAll && action.isInputDataVisible,
        IsPumping: action.selectAll && action.isInputDataVisible,
        IsCompletion: action.selectAll && action.isInputDataVisible,
        IsGravel: action.selectAll && action.isInputDataVisible,
        IsSettings: action.selectAll && !isPPT && action.isInputDataVisible,
        IsSummary: action.selectAll && action.isSimulationSummaryVisible,
        IsSimulationAnimation: action.selectAll && action.isSimulationAnimationVisible,
        IsWellVisualization: action.selectAll && action.isSimulationAnimationVisible,
        IsEvaluationAnimation: action.selectAll && action.isEvaluationAnimationVisible,
        IsCharts: action.selectAll && action.isChartsVisible,
        IsImportedDataInformation: action.selectAll && action.isImportedDataInfoVisible,
        IsPsdInput: action.selectAll && action.isPsdDataVisible,
        IsPsdAnalysis: action.selectAll && action.isPsdDataVisible,
        IsPsdScreenAndGravelSelection: action.selectAll && action.isPsdDataVisible,
        IsPsdSummary: action.selectAll && action.isPsdDataVisible,
        IsTrendAnalysisInputs: action.selectAll && action.isTrendAnalysisVisible,
      },
    };
  }
}
