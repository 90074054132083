<div class="top-menu-container" [ngClass]="{ pinned: isMenuDisplayed }">
  <div class="header-row">
    <span class="header-element">
      <span class="icon-logo" data-cy="icon-logo"></span>

      <span class="error test-mode" *ngIf="isTestMode">(TEST MODE)</span>
      <span class="file-name text-ellipsis">
        <ng-container *ngIf="file$ | async as file">{{ file | fileDisplayName }}</ng-container>
      </span>
      <span class="scenario-lock-container">
        <span class="text-ellipsis">{{ scenarioName }}</span>
        <span class="lock-message" *ngIf="lockMessage.length || true">
          <span class="icon-lock"></span>
          <span class="text-ellipsis">{{ lockMessage }}</span>
        </span>
      </span>
    </span>
    <app-user-panel></app-user-panel>
  </div>
  <div
    class="ribbon-button-row"
    [ngClass]="{
      'shrink-left': isNavPinned,
      'shrink-right': isHelpPinned
    }"
  >
    <app-button
      class="big-button undo-redo"
      iconAsText="⤺"
      [disabled]="!generalMenuProps.undoEnabled"
      [dataCy]="'undo-btn'"
      [isDeleteResultsEnabled]="false"
      (buttonClick)="undoClick()"
    ></app-button>
    <app-button
      class="big-button undo-redo"
      iconAsText="⤻"
      [disabled]="!generalMenuProps.redoEnabled"
      [dataCy]="'redo-btn'"
      [isDeleteResultsEnabled]="false"
      (buttonClick)="redoClick()"
    ></app-button>

    <button
      class="ribbon-button"
      appButton
      *ngFor="let tab of tabs"
      [attr.data-cy]="'ribbon-menu-tab-' + tab.id"
      [isUiLockable]="false"
      [ngClass]="{ 'selected-tab': tab.id === openTab && isMenuDisplayed }"
      (click)="changeTab('toolbars', tab.id)"
      [isDisabledWhenCalculationActive]="false"
    >
      <span class="{{ tab.icon }} button-icon"></span>
      <span class="button-text">{{ tab.name }}</span>
    </button>
    <app-help class="help-button"></app-help>
  </div>
  <div
    #ribbonContent
    [ngClass]="{
      visible: isMenuDisplayed,
      'shrink-left': isNavPinned,
      'shrink-right': isHelpPinned
    }"
    class="ribbon-content"
  >
    <app-menu-panel *ngIf="openTab === 'general'">
      <app-general-menu-panel class="menu-panel"></app-general-menu-panel>
    </app-menu-panel>
    <app-menu-panel *ngIf="openTab === 'analysis'">
      <app-analysis-menu-panel class="menu-panel"></app-analysis-menu-panel>
    </app-menu-panel>
    <app-menu-panel *ngIf="openTab === 'charts'">
      <app-charts-menu-panel class="menu-panel"></app-charts-menu-panel>
    </app-menu-panel>
    <app-menu-panel *ngIf="openTab === 'view'">
      <app-display-menu-panel class="menu-panel"></app-display-menu-panel>
    </app-menu-panel>
    <app-menu-panel *ngIf="openTab === 'docs'">
      <app-help-menu-panel class="menu-panel"></app-help-menu-panel>
    </app-menu-panel>
  </div>
</div>
