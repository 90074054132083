import { Point } from 'chart.js';
import { IAnnotationStyle } from '@dunefront/common/modules/reporting/dto/chart-annotation.dto';
import {
  annotationBorderColor,
  annotationDefaultLineWidth,
  annotationRoundedCornerRadius,
} from '../../../../common-modules/chart/annotations-plugin/constants';
import { getTailTrianglePoints, Rect } from '@dunefront/common/common/math-geometry-helpers';
import { TooltipRow } from './drawing.component.types';
import { getRoundedRectPath } from '../../../../shared/helpers/canvas-drawing-helpers';

const defaultTextBoxCenterOffset = 100;
const textBoxToCrosshairMinDistance = 20;
const crosshairLineWidth = 1;
const crosshairColor = '#555';

const tooltipDrawingPadding = {
  top: 30, // below legend
  bottom: 2,
  left: 2,
  right: 2,
};

export const drawPackingTooltip = (
  ctx: CanvasRenderingContext2D,
  anchor: Point,
  rows: TooltipRow[],
  style: IAnnotationStyle,
  drawingArea: Rect,
): void => {
  ctx.save();

  const { x, y } = anchor;

  // prepare styles
  const { AnnotationFillColour, AnnotationFontColour, AnnotationFontSize, AnnotationFontBold, AnnotationFontItalic } = style;
  const regularFontStyle = `${AnnotationFontItalic ? 'italic' : ''} ${AnnotationFontBold ? 'bold ' : ' '}  ${AnnotationFontSize}px Arial`;
  const boldFontStyle = `${AnnotationFontItalic ? 'italic' : ''} bold ${AnnotationFontSize}px Arial`;

  // set initial styles in ctx
  ctx.fillStyle = AnnotationFillColour;
  ctx.strokeStyle = annotationBorderColor;
  ctx.lineWidth = annotationDefaultLineWidth;
  ctx.textBaseline = 'bottom';
  ctx.font = regularFontStyle;

  // calculate text metrics
  const lineHeight = Math.ceil(ctx.measureText('0').actualBoundingBoxAscent);
  const nameValueSpacing = lineHeight / 2;
  const valueUnitSpacing = lineHeight / 4;
  const lineSpacing = lineHeight / 3;
  const textBoxPadding = lineHeight / 2;
  const maxNameWidth = Math.ceil(Math.max(...rows.map((r) => ctx.measureText(r.name).width)));
  const maxValueWithUnitWidth = Math.ceil(
    Math.max(
      ...rows.map((row) => {
        ctx.font = boldFontStyle;
        const valueWidth = ctx.measureText(row.value).width;

        ctx.font = regularFontStyle;
        const unitWidth = ctx.measureText(row.unit).width;

        return valueWidth + unitWidth;
      }),
    ),
  );

  const textBoxWidth = maxNameWidth + nameValueSpacing + maxValueWithUnitWidth + valueUnitSpacing + 2 * textBoxPadding;
  const textBoxHeight = lineHeight * rows.length + lineSpacing * (rows.length - 1) + 2 * textBoxPadding;

  const minX = drawingArea.x + tooltipDrawingPadding.left;
  const maxX = drawingArea.x + drawingArea.width - textBoxWidth - tooltipDrawingPadding.right;
  const minY = drawingArea.y + tooltipDrawingPadding.top;
  const maxY = drawingArea.y + drawingArea.height - textBoxHeight - tooltipDrawingPadding.bottom;

  const textBoxOffsetX = Math.max(defaultTextBoxCenterOffset, textBoxWidth / 2 + textBoxToCrosshairMinDistance);
  const textBoxOffsetY = Math.max(defaultTextBoxCenterOffset, textBoxHeight / 2 + textBoxToCrosshairMinDistance);

  let textBoxX = x - textBoxWidth / 2;
  // try to move text box to right
  if (textBoxX + textBoxOffsetX <= maxX) {
    textBoxX += textBoxOffsetX;
  }
  // otherwise try to move to left
  else if (textBoxX - textBoxOffsetX >= minX) {
    textBoxX -= textBoxOffsetX;
  }

  let textBoxY = y - textBoxHeight / 2;
  // try to move text box up
  if (textBoxY - textBoxOffsetY >= minY) {
    textBoxY -= textBoxOffsetY;
  }
  // otherwise try to move down
  else if (textBoxY + textBoxOffsetY <= maxY) {
    textBoxY += textBoxOffsetY;
  }

  const textBox: Rect = {
    x: Math.ceil(applyBoundaries(textBoxX, minX, maxX)),
    y: Math.ceil(applyBoundaries(textBoxY, minY, maxY)),
    width: textBoxWidth,
    height: textBoxHeight,
  };

  const tailTrianglePoints = getTailTrianglePoints(anchor, textBox);

  const rectPath = getRoundedRectPath(textBox, annotationRoundedCornerRadius);
  // draw text box border
  ctx.stroke(rectPath);

  // draw connector triangle (tail)
  ctx.beginPath();
  ctx.moveTo(x, y);
  ctx.lineTo(tailTrianglePoints[0].x, tailTrianglePoints[0].y);
  ctx.lineTo(tailTrianglePoints[1].x, tailTrianglePoints[1].y);
  ctx.lineTo(x, y);
  ctx.stroke();
  ctx.fill();

  // draw text box background
  ctx.fill(rectPath);
  ctx.fillStyle = AnnotationFontColour;

  // draw lines
  let rowY = textBox.y + textBoxPadding + lineHeight + 1;
  for (const row of rows) {
    let rowX = textBox.x + textBoxPadding;

    // draw name
    ctx.fillText(row.name, rowX, rowY);
    rowX += maxNameWidth + nameValueSpacing;

    // draw value
    ctx.font = boldFontStyle;
    ctx.fillText(row.value, rowX, rowY);
    rowX += ctx.measureText(row.value).width + valueUnitSpacing;

    // draw unit
    ctx.font = regularFontStyle;
    ctx.fillText(row.unit, rowX, rowY);

    rowY += lineHeight + lineSpacing;
  }

  ctx.restore();
};

export const drawCrosshair = (ctx: CanvasRenderingContext2D, p1: Point, p2: Point): void => {
  ctx.lineWidth = crosshairLineWidth;
  ctx.strokeStyle = crosshairColor;

  ctx.beginPath();
  ctx.moveTo(p1.x, p1.y);
  ctx.lineTo(p2.x, p2.y);
  ctx.stroke();
};

const applyBoundaries = (value: number, min: number, max: number): number => Math.max(Math.min(value, max), min);
