<div class="grid-form-container">
  <div class="grid-two-one">
    <app-radio-group
      id="depthDefinitionRadio"
      [items]="depthDefRadioItems"
      layout="vertical"
      [source]="state"
      [key]="'IsZoneDepthByMD'"
      dataCy="depth-definition-radio"
      (valueChanged)="onDepthChange($event)"
    >
    </app-radio-group>
  </div>
</div>
