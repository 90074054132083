import { Injectable } from '@angular/core';
import { BaseWsEffects } from '../../base-ws.effects';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BackendConnectionService } from '../../../shared/backend-connection/backend-connection.service';
import { mergeMap } from 'rxjs/operators';
import {
  CalculateMASPAction,
  MASPCalculatorModuleName,
  UpdateMASPCalculatorAction,
} from '@dunefront/common/modules/calculators/masp-calculator/masp-calculator-module.action';
import { calculateMASP, updateMASPCalculatorRow } from './masp-calculator.actions';
import { ModalService } from '../../../common-modules/modals/modal.service';

@Injectable()
export class MASPCalculatorEffects extends BaseWsEffects {
  constructor(actions$: Actions, store: Store, wsService: BackendConnectionService, modalService: ModalService) {
    super(actions$, wsService, MASPCalculatorModuleName, false, true, modalService, store);
  }

  public updateMASPCalculator$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateMASPCalculatorRow),
      mergeMap((action) => this.emitUpdate(new UpdateMASPCalculatorAction(action))),
    ),
  );

  public calculateMASP$ = createEffect(() =>
    this.actions$.pipe(
      ofType(calculateMASP),
      mergeMap((action) => this.emitUpdate(new CalculateMASPAction(action.ScenarioId), 'Calculating MASP...')),
    ),
  );
}
