import { createSelector } from '@ngrx/store';
import { ILeftNavElement, LeftNavHelpers } from '../../../shared/components/left-nav/left-nav.helpers';
import { isElectron } from '@dunefront/common/common/electron/is-electron';
import { getAboutServer } from '../../../+store/about/about.selectors';
import { getSimulateMenuValidation } from '../../simulate-evaluate-page/simulate-evaluate-page.validators';
import { getCurrentAppModuleType } from '../../../+store/ui/ui.selectors';
import { isSimulateBased, ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import { selectValidatedOrganizationGlobalOptions, selectValidatedUserGlobalOptions } from '../../../+store/common-db/common-db.selectors';
import { getIsAdmin } from '../../../+store/auth/auth.selectors';

export const getSettingsLeftNavElement = createSelector(
  getSimulateMenuValidation,
  selectValidatedUserGlobalOptions,
  selectValidatedOrganizationGlobalOptions,
  getAboutServer,
  getCurrentAppModuleType,
  getIsAdmin,
  (isValid, validatedUserGlobalOptions, validatedOrgGlobalOptions, aboutServer, moduleType, isAdmin): ILeftNavElement => {
    const isTestEnv = aboutServer.serverInfo?.isTestEnv;

    const isFullSettings = moduleType === ModuleType.Evaluate || isSimulateBased(moduleType);

    const navItems = [
      LeftNavHelpers.getItem('User', 'settings/user', validatedUserGlobalOptions.isValid),
      ...(!isElectron() && isAdmin
        ? [LeftNavHelpers.getItem('Organization', 'settings/organization', validatedOrgGlobalOptions.isValid)]
        : []),
    ];

    if (isFullSettings) {
      navItems.unshift(LeftNavHelpers.getItem('Project', 'settings/project', isValid.isSettingsValid));
    }

    if (isTestEnv) {
      navItems.push(LeftNavHelpers.getItem('Developer', 'settings/developer'));
    }

    // when Engine options are not available, we can ignore it's errors
    const isSettingsValid = isFullSettings ? isValid.isSettingsValid : true;

    // global options are not available on electron, so we can ignore global validation errors in this case
    const isOrgGlobalOptionsValid = isElectron() ? true : validatedOrgGlobalOptions.isValid;

    return LeftNavHelpers.getNavElement(
      'Settings',
      'settings',
      'icon-setting-gear',
      isSettingsValid && isOrgGlobalOptionsValid && validatedUserGlobalOptions.isValid,
      false,
      navItems,
    );
  },
);
