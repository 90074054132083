import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import {
  createChartDataColumn,
  createChartDataRowDto,
  createChartDataSet,
  createEmptyChartData,
  IChartDataDto,
  IChartDataDtoColumn,
} from '@dunefront/common/modules/reporting/dto/chart-data.dto';
import { DbDependentComponent } from '../../../../../common-modules/db-connection/db-dependent.component';
import { createSelector, Store } from '@ngrx/store';
import { chartModeAction } from '../../../../../+store/ui/ui.actions';
import {
  InjectionTestCalculatorModuleState,
  IValidatedInjectionTestCalculatorModuleState,
} from '@dunefront/common/modules/calculators/injection-test-calculator/injection-test-calculator-module.state';
import { DataType } from '@dunefront/common/dto/data-storage';
import { ChartMode } from '../../../../../+store/ui/ui-module.state';
import { ChartDataSourceType } from '@dunefront/common/modules/reporting/dto/chart.dto';
import { getChartIds } from '../../../../../+store/reporting/reporting.selectors';
import { ChartControllerConfig } from '../../../../../common-modules/chart/chart-controller.component';
import { InjectionTestMarkersService } from './injection-test-markers.service';
import { ChartState } from '../../../../../+store/reporting/reporting-module.state';
import { getValidatedInjectionTestCalculatorModuleState } from '../../../../../+store/calculators/injection-test-calculator/injection-test-calculator.selectors';
import { getRowsForCalculations } from '@dunefront/common/common/common-grid.interfaces';
import { ChartAxis } from '@dunefront/common/modules/reporting/dto/chart-axis-property.dto';
import { ReportingFactory } from '../../../../../+store/reporting/model/reporting.factory';
import { SeriesLineStyle } from '@dunefront/common/modules/reporting/dto/chart.types';

const selectInjectionTestChartStateWithChartId = createSelector(
  getValidatedInjectionTestCalculatorModuleState,
  getChartIds,
  (state, chartIds) => InjectionTestChartFactory.create(state, chartIds.injectionTestCalculatorChartId),
);

@Component({
  selector: 'app-injection-test-chart',
  templateUrl: './injection-test-chart.component.html',
  styleUrls: ['./injection-test-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InjectionTestChartComponent extends DbDependentComponent {
  public componentState$ = this.store.select(selectInjectionTestChartStateWithChartId);

  public readonly config: ChartControllerConfig = {
    createCustomMarkersService: () => new InjectionTestMarkersService(this.store),
  };

  constructor(store: Store, cdRef: ChangeDetectorRef) {
    super(store, cdRef);

    this.store.dispatch(chartModeAction({ chartMode: ChartMode.editVerticalMarker }));
  }
}

class InjectionTestChartFactory {
  public static create(
    state: IValidatedInjectionTestCalculatorModuleState,
    chartId: number,
  ): { chartState: ChartState | undefined; chartId: number } {
    const chartState = this.buildChartState(state, chartId);
    return { chartState, chartId };
  }

  private static buildChartState(state: InjectionTestCalculatorModuleState, chartId: number): ChartState | undefined {
    const injectionTestData = state.InjectionTestReadings.rows.filter((row) => row.rowType !== 'insert-row');
    if (injectionTestData.length <= 3) {
      return undefined;
    }

    const chartData = createEmptyChartData(DataType.Return_Rate, ChartDataSourceType.InjectionTestCalculator, chartId);

    const inputData = createChartDataColumn(ChartAxis.PrimaryValue, DataType.Bottomhole_Pressure, 0, 0, 'Bottomhole Pressure', {
      lineStyle: SeriesLineStyle.scatter,
    });

    const dataRows = injectionTestData.map((item) => createChartDataRowDto(item.rowData.Rate, [item.rowData.Pressure]));

    chartData.ChartDataColumns.push(inputData);
    chartData.ChartDataSets.push(createChartDataSet(dataRows));

    const aboveFracColumn = createChartDataColumn(ChartAxis.PrimaryValue, DataType.Bottomhole_Pressure, 1, 0, 'Above Frac', {
      lineStyle: SeriesLineStyle.dashes,
    });

    const aboveFracSlope = state.InjectionTestCalculator.AboveFracSlope;
    const aboveFracIntercept = state.InjectionTestCalculator.AboveFracIntercept;
    this.addDataSet(aboveFracSlope, aboveFracIntercept, state, aboveFracColumn, chartData);

    const belowFracColumn = createChartDataColumn(ChartAxis.PrimaryValue, DataType.Bottomhole_Pressure, 2, 0, 'Below Frac', {
      lineStyle: SeriesLineStyle.dashes,
    });

    const belowFracSlope = state.InjectionTestCalculator.BelowFracSlope;
    const belowFracIntercept = state.InjectionTestCalculator.BelowFracIntercept;
    this.addDataSet(belowFracSlope, belowFracIntercept, state, belowFracColumn, chartData);

    return ReportingFactory.createChartStateWithData(ChartDataSourceType.InjectionTestCalculator, chartData);
  }

  private static addDataSet(
    fracSlope: number,
    fracIntercept: number,
    state: InjectionTestCalculatorModuleState,
    column: IChartDataDtoColumn,
    chartData: IChartDataDto,
  ): void {
    if (fracSlope !== 0 || fracIntercept !== 0) {
      const initialChartDataRow = createChartDataRowDto(0, [fracIntercept]);
      const rows = getRowsForCalculations(state.InjectionTestReadings.rows).map((row) =>
        createChartDataRowDto(row.Rate, [row.Rate * fracSlope + fracIntercept]),
      );

      chartData.ChartDataSets.push(createChartDataSet([initialChartDataRow, ...rows]));
      chartData.ChartDataColumns.push(column);
    }
  }
}
