import { IUser, IUserId } from '../auth/auth.interfaces';
import { ModuleType } from '../scenario/scenario.dto';
import { DataFileType } from '../../dto/data-storage';
import { DeveloperSettingsDto } from '../settings/dto/settingsDto';
import { Job } from 'bullmq';
import { IActivityOverlayConfig } from '../ui/ui.interfaces';
import { IFile } from '../../dto/file.dto';
import { JsWorkerActionType } from '../worker-types/js-workers-common';

export interface ICalculationEngineMessage {
  textMessage: string;
  messageType: CalculationEngineMessageType;
  data?: any;
  progress?: number;
}

export interface ICalculationEngineData {
  reportMessage: string;
  additionalData: SimulateEvaluateCompleteAdditionalData;
}

export interface SimulateEvaluateCompleteAdditionalData {
  shouldUploadPumpingSchedule: boolean;
  shouldUploadRunningString: boolean;
}

export interface IJobDataBase {
  jobId: string;
  user: IUserId;
  databaseFileHash: string;
}

export interface ICalculationEngineJobData extends IJobDataBase {
  engine: string;
  developerSettings: DeveloperSettingsDto;
  scenarioId: number;
  calculationType: ModuleType;
  rangeId: number;
  databaseFilePathBase64Encoded: string;
  dbFile: IFile;
  options: ICalculationEngineJobDataOptions;
  exportFilePath?: string;
  exportModuleType?: ModuleType;
  exportFileType?: DataFileType;
  payload?: string;
  error?: any;
}

export interface ICalculationEngineJobDataOptions {
  isStartedFromScenarioManager: boolean;
}

export interface ICalculationEngineShortJobData {
  userId: IUserId;
  jobId: string;
  moduleType: ModuleType;
  inputData: string;
  appFolderName: string;
}

export interface IMergeImportDataPayload {
  fileIds: number[];
  columnIds: number[];
  equationFileId: number;
  equationXAxisColumnId: number;
}

export interface IImportTempProfileWorkerPayload {
  SourceScenarioId: number;
  ModuleType: ModuleType;
  BottomholeCirculatingTemperature: number;
}

export interface ILongWorkerJobInfo {
  id: string;
  fileHash: string;
  activityOverlayConfig: IActivityOverlayConfig | undefined;
  type: string;
}

export interface ICalcEngineJobInfo extends ILongWorkerJobInfo {
  name: string;
  data: ICalculationEngineJobData;
  timestamp: number;
  attemptsMade: number;
  failedReason: any;
  stacktrace: string[] | null;
  positionInQueue?: number;
}

export interface IJsWorkerJobInfo extends ILongWorkerJobInfo {
  actionType: JsWorkerActionType;
  file?: IFile;
}

export const jobToCalcEngineJobInfo = (job: Job<ICalculationEngineJobData>, positionInQueue?: number): ICalcEngineJobInfo => ({
  id: job.id as string,
  type: job.data.calculationType.toString(),
  name: job.name,
  data: job.data,
  timestamp: job.timestamp,
  attemptsMade: job.attemptsMade,
  failedReason: job.failedReason,
  stacktrace: job.stacktrace,
  fileHash: job.data.databaseFileHash,
  positionInQueue,
  activityOverlayConfig: undefined,
});

export enum CalculationEngineMessageType {
  error = 'error',
  dataUpdate = 'dataUpdate',
  textUpdate = 'textUpdate',
  complete = 'complete',
  ping = 'ping',
  report = 'report',
}

export interface CalculationNotification {
  messageType: CalculationEngineMessageType;
  jobData: ICalculationEngineJobData;
  message: ICalculationEngineMessage;
}

export interface ICalculationEngineConnection {
  onDisconnect: () => void;

  send(user: IUser, type: string, args: object, onResponse?: (returnArg: any) => void): void | Promise<any>;

  close(): void;

  on(event: string, onRequest: (requestArg: any) => any): void;
}

export type RedisConnectionStatus = 'disconnected' | 'connected' | 'reconnecting' | 'ready';

export interface CalculationContext {
  moduleType: ModuleType;
  scenarioId: number;
  rangeId: number;
  fileHash: string;
}

export const areCalcContextsEqual = (context1: CalculationContext | undefined, context2: CalculationContext | undefined): boolean => {
  if (!context1 || !context2) {
    return false;
  }

  return (
    context1.scenarioId === context2.scenarioId &&
    context1.rangeId === context2.rangeId &&
    context1.moduleType == context2.moduleType &&
    context1.fileHash === context2.fileHash
  );
};

export const jobToContext = (job: ICalcEngineJobInfo): CalculationContext => ({
  fileHash: job.fileHash,
  scenarioId: job.data.scenarioId,
  rangeId: job.data.rangeId,
  moduleType: job.data.calculationType,
});
