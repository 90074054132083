import { HydrilState } from './pumping.dto';
import { PumpingStateByRange } from '../pumping-module.state';

export interface TreatingReturnLines {
  SurfaceChokeLineLength: number;
  SurfaceChokeLineID: number;
  ChokeKillLineID: number;
  ChokeOrificeID: number;
  HydrilState: HydrilState;
  IsSubseaBOP: boolean;
  IsTreatingLine: boolean;
  SurfaceTreatingLineLength: number;
  TreatingLineID: number;
}

export class TreatingReturnLinesFactory {
  public static create(pumping: PumpingStateByRange): TreatingReturnLines {
    const {
      HydrilState,
      IsSubseaBOP,
      IsTreatingLine,
      TreatingLineID,
      SurfaceTreatingLineLength,
      SurfaceChokeLineID,
      SurfaceChokeLineLength,
      ChokeKillLineID,
      ChokeOrificeID,
    } = pumping.pumping;

    const treatingLines: TreatingReturnLines = {
      ChokeKillLineID,
      ChokeOrificeID,
      HydrilState,
      IsSubseaBOP,
      IsTreatingLine,
      SurfaceChokeLineID,
      SurfaceChokeLineLength,
      SurfaceTreatingLineLength,
      TreatingLineID,
    };
    return treatingLines;
  }
}
