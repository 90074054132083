import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { getUser } from '../../../+store/auth/auth.selectors';
import { Store } from '@ngrx/store';
import { MenuItem } from 'primeng/api';
import { ModalService } from '../../../common-modules/modals/modal.service';
import { IFile, Repository } from '@dunefront/common/dto/file.dto';
import { NewProjectDialogComponent } from '../action-dialogs/open-new-shared-demo.dialog/new-project.dialog.component';
import { NewFolderDialogComponent } from '../action-dialogs/new-folder-dialog/new-folder.dialog.component';
import { Subscription } from 'rxjs';
import { getCurrentFolderState } from '../../../+store/file-manager/file-manager.selectors';
import { notEmpty } from '@dunefront/common/common/state.helpers';
import { AppTargetConfig } from '../../../shared/services/app-target-config';

@Component({
  selector: 'app-home-header',
  templateUrl: './home-header.component.html',
  styleUrls: ['./home-header.component.scss'],
})
export class HomeHeaderComponent implements OnDestroy {
  @Input() public folder?: IFile;

  @Input()
  public set repository(repository: Repository) {
    this.plusContextMenu =
      repository === 'personal'
        ? [
            { label: 'New Project', icon: 'icon-file', command: (): void => this.newProject() },
            { label: 'New Project Folder', icon: 'icon-folder', command: (): void => this.newProjectFolder() },
            { label: 'Upload Project', icon: 'icon-upload', command: (): void => this.uploadFile() },
          ]
        : repository === 'shared'
        ? [{ label: 'New Project Folder', icon: 'icon-folder', command: (): void => this.newProjectFolder() }]
        : undefined;
  }

  @Output() public uploadTriggered = new EventEmitter<void>();

  public user$ = this.store.select(getUser);
  public plusContextMenu?: MenuItem[];
  protected subscription = new Subscription();
  private fileNameList!: string[];
  private folderNameList!: string[];

  constructor(
    private store: Store,
    public modalService: ModalService,
    private appConfig: AppTargetConfig,
  ) {
    this.subscription.add(
      notEmpty(this.store.select(getCurrentFolderState)).subscribe((currentFolderState: IFile) => {
        if (currentFolderState?.Children) {
          this.fileNameList = currentFolderState.Children.filter((file) => file.FileType === 'ppf-file').map((file) => file.Name);
          this.folderNameList = currentFolderState.Children.filter((file) => file.FileType === 'folder').map((file) => file.Name);
          return;
        }
        this.fileNameList = [];
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public newProject(): void {
    const file: IFile = {
      Repository: 'template',
      FileType: 'ppf-file',
      Name: 'default',
      Folder: [],
    };
    this.modalService.open(
      NewProjectDialogComponent,
      {
        file,
        targetFolder: this.folder,
        fileNameList: this.fileNameList,
      },
      '',
      'sm',
    );
  }

  public newProjectFolder(): void {
    this.modalService.open(
      NewFolderDialogComponent,
      {
        targetFolder: this.folder,
        folderNameList: this.folderNameList,
      },
      '',
      'sm',
    );
  }

  private uploadFile(): void {
    this.uploadTriggered.emit();
  }
}
