import { WsAction } from '../../ws.action';
import { ScenarioDto } from '../scenario/scenario.dto';
import { IFile } from '../../dto/file.dto';
import { GlobalOptionsDto } from '../../dto/global-options.dto';
import { FileSettingsDto } from '../../dto/file-settings.dto';
import { CalculationResultsInfoDto } from '../calculation-engine/dto/calculation-results-info.dto';
import { LicenseFeature, LicensingLoginResponse } from '../licensing/licensing.interfaces';
import { ChartSeriesTemplateDto } from '../../dto/chart-series-template.dto';
import { IUserId } from '../auth/auth.interfaces';

export const DbConnectionModuleName = 'DbConnectionModule';

export enum DbConnectionModuleActionTypes {
  Open = '[DbConnectionModule] Open',
  Close = '[DbConnectionModule] Close',
}

export class OpenDbConnectionAction extends WsAction {
  public override readonly type = DbConnectionModuleActionTypes.Open;

  constructor(
    public dbFile: IFile,
    public licensingConfig: ConnectionLicensingConfig,
  ) {
    super();
  }
}

export class CloseDbConnectionAction extends WsAction {
  public override readonly type = DbConnectionModuleActionTypes.Close;
}

export interface ConnectionLicensingConfig {
  feature: LicenseFeature;
  addonFeatures: LicenseFeature[];
  licenseIds: string[];
}

export interface IDbConnectionOpenedResponse {
  userId: IUserId;
  dbInfo: IDbInfo;
  scenarios: ScenarioDto[];
  licensingLoginResponse: LicensingLoginResponse;
  resultsInfos: CalculationResultsInfoDto[];
  chartSeriesTemplates: ChartSeriesTemplateDto[];
  fileSettings?: FileSettingsDto;
}

export interface IGlobalOptions {
  predefinedGlobalOptions: GlobalOptionsDto;
  orgGlobalOptions: GlobalOptionsDto;
  personalGlobalOptions: GlobalOptionsDto;
}

export type DbVersionStatus =
  | 'connecting'
  | 'ok'
  | 'dbMigrationRequired'
  | 'dbMigrationRequiredWithBackup'
  | 'dbVersionTooLow'
  | 'dbVersionTooHigh';

export interface IDbInfo {
  dbStatus: DbVersionStatus;
  file: IFile;
  fileHash: string;
}
