import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertModalContentComponent } from './alert/alert-modal-content.component';
import { ConfirmModalContentComponent } from './alert/confirm-modal-content.component';
import { RadioGroupModule } from '../../shared/components/form-components/radio-group/radio-group.component';
import { UnitsModule } from '../units/units.module';
import { FormsModule } from '@angular/forms';
import { GetPpdTargetComponent } from './get-ppd-target/get-ppd-target.component';
import { UnitsCreateEditModalComponent } from './units-create-edit-modal/units-create-edit-modal.component';
import { FormInputModule } from '../../shared/components/form-components/form-input/form-input.component';
import { SelectModule } from '../../shared/components/form-components/select/select.module';
import { WorkingModalContentComponent } from './alert/working-indicator-modal-content.component';
import { ScenarioManagerSelectorModule } from './scenario-manager/scenario-manager-selector/scenario-manager-selector.module';
import { ScenarioManagerComponent } from './scenario-manager/scenario-manager.component';
import { PagePanelModule } from '../page-panel/page-panel.component';
import { ScenarioManagerBasicOperationsComponent } from './scenario-manager/scenario-manager-basic-operations/scenario-manager-basic-operations.component';
import { ScenarioManagerCloneOperationsComponent } from './scenario-manager/scenario-manager-clone-operations/scenario-manager-clone-operations.component';
import { CreateEditCopyScenarioComponent } from './scenario-manager/scenario-manager-basic-operations/create-edit-copy-scenario/create-edit-copy-scenario.component';
import { LoaderModule } from '../loader/loader.component';
import { ButtonModule } from '../../shared/components/button/button.module';
import { AppTreeModule } from '../../shared/components/tree/app-tree.module';
import { CompareScenarioComponent } from './compare-scenario/compare-scenario.component';
import { CopyDataComponent } from './copy-data/copy-data.component';
import { CheckBoxModule } from '../../shared/components/form-components/check-box/check-box.component';
import { ExportDataComponent } from './export-data/export-data.component';
import { ImportTempProfileModalComponent } from '../../pages/simulate-evaluate-page/well/general-data/import-temp-profile-modal/import-temp-profile-modal.component';
import { GridModule } from '../../shared/components/grid/grid.module';
import { UnwrapDirectiveModule } from '../../shared/directives/unwrap.directive';
import { DropdownModule } from 'primeng/dropdown';
import { ProgressIndicatorModalContentComponent } from './alert/progress-indicator-modal-content.component';
import { ProgressBarModule } from 'primeng/progressbar';
import { PromptModalContentComponent } from './alert/prompt-modal-content.component';
import { DateTimeModalContentComponent } from './alert/datetime-modal-content.component';
import { CalendarModule } from 'primeng/calendar';
import { InputMaskModule } from 'primeng/inputmask';
import { DateTimeInputModule } from '../../shared/components/form-components/date-time-input/date-time-input.component';
import { CheckForUpdatesComponent } from './check-for-updates/check-for-updates.component';
import { ModalHelpButtonComponent } from './modal-help-button/modal-help-button.component';
import { CalcEngineErrorComponent } from './calc-engine-error/calc-engine-error.component';
import { SafePipeModule } from '../../shared/pipes/safe.pipe';
import { GenericModalComponent } from './generic-modal/generic-modal.component';
import { ActivityOverlayComponent } from './activity-overlay/activity-overlay.component';
import { CopyDataFromComponent } from './copy-data-from/copy-data-from.component';
import { SelectProviderComponent } from './select-image-provider/select-provider.component';
import { ReleaseNotesModalComponent } from './release-notes/release-notes-modal.component';
import { HelpModalComponent } from './help/help-modal.component';
import { UserProfileModalComponent } from './user-profile/user-profile.modal.component';
import { HelpUrlPipeModule } from '../../shared/pipes/help-url.pipe';
import { CardModule } from 'primeng/card';
import { SingleNotificationComponent } from './survey/notifications/single-notification/single-notification.component';
import { NotificationsModalComponent } from './survey/notifications/notifications-modal.component';
import { NotificationContentModalComponent } from './survey/notifications/notification-content.modal.component';
import { PromptTextareaModalContentComponent } from './alert/textarea-modal-content.component';
import { LetDirective } from '@ngrx/component';

@NgModule({
  declarations: [
    AlertModalContentComponent,
    ConfirmModalContentComponent,
    PromptModalContentComponent,
    PromptTextareaModalContentComponent,
    WorkingModalContentComponent,
    UnitsCreateEditModalComponent,
    GetPpdTargetComponent,
    ScenarioManagerComponent,
    ScenarioManagerBasicOperationsComponent,
    ScenarioManagerCloneOperationsComponent,
    CreateEditCopyScenarioComponent,
    CompareScenarioComponent,
    CopyDataComponent,
    SelectProviderComponent,
    ExportDataComponent,
    CopyDataFromComponent,
    ImportTempProfileModalComponent,
    ProgressIndicatorModalContentComponent,
    DateTimeModalContentComponent,
    CheckForUpdatesComponent,
    ModalHelpButtonComponent,
    CalcEngineErrorComponent,
    GenericModalComponent,
    ActivityOverlayComponent,
    ReleaseNotesModalComponent,
    HelpModalComponent,
    UserProfileModalComponent,
    NotificationsModalComponent,
    SingleNotificationComponent,
    NotificationContentModalComponent,
  ],
  imports: [
    CommonModule,
    ButtonModule,
    SelectModule,
    UnitsModule,
    FormsModule,
    RadioGroupModule,
    FormInputModule,
    ScenarioManagerSelectorModule,
    PagePanelModule,
    LoaderModule,
    AppTreeModule,
    GridModule,
    CheckBoxModule,
    UnwrapDirectiveModule,
    DropdownModule,
    ProgressBarModule,
    CalendarModule,
    InputMaskModule,
    DateTimeInputModule,
    SafePipeModule,
    HelpUrlPipeModule,
    CardModule,
    LetDirective,
  ],
  exports: [ModalHelpButtonComponent, SafePipeModule, GenericModalComponent, ActivityOverlayComponent],
})
export class ModalsModule {}
