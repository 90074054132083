import { createReducer, on } from '@ngrx/store';
import { jsWorkerJobsInitialState, JsWorkerJobsState } from './js-worker-jobs-state';
import { userJobsUpdatedAction } from './calculation-engine.actions';
import { JsWorkerJobsReducerHelper } from './js-worker-jobs.reducer.helper';
import { progressUpdateAction } from '../app.actions';
import { progressDbMigration } from '../db-migration/db-migration.actions';

export const jsWorkerJobsReducer = createReducer<JsWorkerJobsState>(
  jsWorkerJobsInitialState,
  on(
    userJobsUpdatedAction,
    (state, action): JsWorkerJobsState => JsWorkerJobsReducerHelper.onUserJobsUpdatedAction(state, action.payload.userJobs),
  ),
  on(
    progressUpdateAction,
    (state, action): JsWorkerJobsState =>
      JsWorkerJobsReducerHelper.onProgressUpdateAction(state, action.fileHash, action.taskId, action.progressPayload.progress),
  ),
  on(
    progressDbMigration,
    (state, action): JsWorkerJobsState =>
      JsWorkerJobsReducerHelper.onProgressUpdateAction(
        state,
        action.migrationServerResponse.fileHash,
        action.taskId,
        action.migrationServerResponse.status === 'migrationComplete' ? 100 : 0,
      ),
  ),
);
