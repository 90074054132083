import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input } from '@angular/core';
import { GridContainerComponent } from '../../../../../shared/components/grid/grid-container.component';
import { Store } from '@ngrx/store';
import { ModalService } from '../../../../../common-modules/modals/modal.service';
import { ConvertUnitPipe } from '@dunefront/common/modules/units/convert-unit.pipe/convert-unit.pipe';
import { IUnitSystemDto } from '@dunefront/common/dto/unit-system.dto';
import { ITableState } from '@dunefront/common/common/common-grid.interfaces';
import { GridConfig } from '../../../../../shared/components/grid/grid-config';
import { IScreenSize } from '../../../../../shared/services';
import { GridResizeService } from '../../../../../shared/services/grid-resize.service';
import { PSDValidation } from '@dunefront/common/modules/psd-analysis/model/psd/psd.validation';
import { PSDResult } from '@dunefront/common/modules/psd-analysis/model/psd/psd';
import { getSummaryGridConfig } from './summary-grid.config';
import { IGridColumnConfig } from '../../../../../shared/components/grid/grid.interfaces';

@Component({
  selector: 'app-summary-grid',
  templateUrl: './summary-grid.component.html',
  styleUrls: ['./summary-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SummaryGridComponent extends GridContainerComponent<PSDResult> {
  @Input() public psdTable!: ITableState<PSDResult>;

  constructor(
    protected modalService: ModalService,
    protected convertUnitPipe: ConvertUnitPipe,
    store: Store,
    cdRef: ChangeDetectorRef,
    el: ElementRef,
    gridResizeService: GridResizeService,
  ) {
    super(store, cdRef, el, new PSDGridConfig(store, modalService), gridResizeService);
    this.margin = 70;
  }

  public override onUnitSystemChanged(currentUnitSystem: IUnitSystemDto): void {
    super.onUnitSystemChanged(currentUnitSystem);
    this.updateColumns(this.getColumns());
  }

  public getColumns(): IGridColumnConfig<PSDResult>[] {
    return getSummaryGridConfig(this.percentFinesLabel);
  }

  public override resize(size?: IScreenSize): void {
    if (size && size.resizeType === 'x') {
      return;
    }

    this.height = 0;
    this.cdRef.markForCheck();
    super.resize();
  }

  public get percentFinesLabel(): string {
    return PSDValidation.getPercentFinesLabel(this.currentUnitSystem, this.convertUnitPipe);
  }
}

export class PSDGridConfig extends GridConfig<PSDResult> {
  constructor(
    private store: Store,
    modalService: ModalService,
  ) {
    super(modalService);
  }
}
