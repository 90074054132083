import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import {
  EvaluatePageComponent,
  SimulateCHPageComponent,
  SimulateDispPageComponent,
  SimulatePageComponent,
} from '@dunefront/client-common/pages/simulate-evaluate-page/simulate-evaluate-page.component';
import { MainContentComponent } from '@dunefront/client-common/shared/components/main-content/main-content.component';
import { DbDependentContentComponent } from '@dunefront/client-common/shared/components/db-dependent-content/db-dependent-content.component';
import { CalculationsPageComponent } from '@dunefront/client-common/pages/calculations-page/calculations-page.component';
import { appReducers, metaReducers } from '@dunefront/client-common/+store/app.reducers';
import { InputsHelperService } from '@dunefront/client-common/shared/services/inputs-helper.service';
import { CommonModule } from '@angular/common';
import { RouterHelperService } from '@dunefront/client-common/shared/services/router-helper.service';
import { LeakoffCoefficientModule } from '@dunefront/client-common/pages/calculations-page/leakoff-coefficient/leakoff-coefficient.module';
import { appEffects } from '@dunefront/client-common/+store/app.effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { RibbonMenuModule } from '@dunefront/client-common/common-modules/ribbon-menu/ribbon-menu.module';
import { RadioGroupModule } from '@dunefront/client-common/shared/components/form-components/radio-group/radio-group.component';
import { FluidsModule } from '@dunefront/client-common/pages/common/fluids/fluids.module';
import { TabbedPageModule } from '@dunefront/client-common/common-modules/tabbed-page/tabbed-page.component';
import { GridModule } from '@dunefront/client-common/shared/components/grid/grid.module';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { ImportDataModule } from '@dunefront/client-common/common-modules/modals/import-data/import-data.module';
import { PagePanelModule } from '@dunefront/client-common/common-modules/page-panel/page-panel.component';
import { MASPModule } from '@dunefront/client-common/pages/calculations-page/masp/masp.module';
import { DbMigrationComponent } from '@dunefront/client-common/common-modules/db-migration/db-migration.component';
import { BreadcrumbsModule } from '@dunefront/client-common/shared/components/breadcrumbs/breadcrumb.component';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { SettlingModule } from '@dunefront/client-common/pages/calculations-page/settling/settling.module';
import { AppInfoService, ScreenService } from '@dunefront/client-common/shared/services';
import { PsdInputComponent } from '@dunefront/client-common/pages/psd-analysis-page/psd-input/psd-input.component';
import { PsdAnalysisPageComponent } from '@dunefront/client-common/pages/psd-analysis-page/psd-analysis-page.component';
import { ResultsModule } from '@dunefront/client-common/pages/simulate-evaluate-page/results/results.module';
import { ModalsModule } from '@dunefront/client-common/common-modules/modals/modals.module';
import { PsdAnalysisComponent } from '@dunefront/client-common/pages/psd-analysis-page/psd-analysis/psd-analysis.component';
import { FrictionModule } from '@dunefront/client-common/pages/calculations-page/friction/friction.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { PsdResultsModule } from '@dunefront/client-common/pages/psd-analysis-page/psd-results/psd-results.module';
import { GravelComponent } from '@dunefront/client-common/pages/common/gravel/gravel.component';
import { TestPageModule } from '@dunefront/client-common/pages/simulate-evaluate-page/test-page/test-page.module';
import { UndoRedoModule } from '@dunefront/client-common/common-modules/undo-redo/undo-redo.component';
import { ResuspensionModule } from '@dunefront/client-common/pages/calculations-page/resuspension/resuspension.module';
import { LeftNavModule } from '@dunefront/client-common/shared/components/left-nav/left-nav.component';
import { FluidsComponent } from '@dunefront/client-common/pages/common/fluids/fluids.component';
import { TrendAnalysisPageModule } from '@dunefront/client-common/pages/trend-analysis-page/trend-analysis-page.module';
import { ModalService } from '@dunefront/client-common/common-modules/modals/modal.service';
import { ErrorModule } from '@dunefront/client-common/shared/components/error/error.component';
import { PSDInputModule } from '@dunefront/client-common/pages/psd-analysis-page/psd-input/psd-input.module';
import { HomeModule } from '@dunefront/client-common/pages/home/home.module';
import { InjectionTestModule } from '@dunefront/client-common/pages/calculations-page/injection-test/injection-test.module';
import { DbConnectionModule } from '@dunefront/client-common/common-modules/db-connection/db-connection.module';
import { GaugeDataPageModule } from '@dunefront/client-common/pages/gauge-data-page/gauge-data-page.module';
import { PumpingModule } from '@dunefront/client-common/pages/simulate-evaluate-page/pumping/pumping.module';
import { UnitsModule } from '@dunefront/client-common/common-modules/units/units.module';
import { WellModule } from '@dunefront/client-common/pages/simulate-evaluate-page/well/well.module';
import { GravelModule } from '@dunefront/client-common/pages/common/gravel/gravel.module';
import { CompletionModule } from '@dunefront/client-common/pages/simulate-evaluate-page/completion/completion.module';
import { ReportingModule } from '@dunefront/client-common/pages/common/reporting/reporting.module';
import { ButtonModule } from '@dunefront/client-common/shared/components/button/button.module';
import { PSDAnalysisModule } from '@dunefront/client-common/pages/psd-analysis-page/psd-analysis/psd-analysis.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { ENVIRONMENT } from '@dunefront/client-common/shared/services/environment';
import { OverlayModule } from '@dunefront/client-common/shared/components/overlay/connection-status-overlay.component';
import { AppTargetConfig } from '@dunefront/client-common/shared/services/app-target-config';
import { DataAnalysisPageModule } from '@dunefront/client-common/pages/data-analysis-page/data-analysis-page.module';
import { DevConsoleModule } from '@dunefront/client-common/shared/dev-console/dev-console.module';
import { packProTargetConfig } from '@dunefront/common/configs/packpro-target-config';
import { ElectronModule } from '@dunefront/client-common/shared/services/electron-service/electron.module';
import { HomePageUndoRedoGuard } from '@dunefront/client-common/shared/guards/home-page-undo-redo-guard.service';
import { PrevNextNavModule } from '@dunefront/client-common/shared/components/prev-next-nav/prev-next-nav.component';
import { SettingsModule } from '@dunefront/client-common/pages/common/settings/settings.module';
import { ClientAuthService } from '@dunefront/client-common/common-modules/auth/client-auth.service';
import { ClientAuthModule } from '@dunefront/client-common/common-modules/auth/client-auth.module';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ToleranceHelperService } from '@dunefront/client-common/shared/services/tolerance-helper.service';
import { PasteDataModule } from '@dunefront/client-common/common-modules/modals/paste-data/paste-data.module';
import { ImportPasteDataCommonModule } from '@dunefront/client-common/common-modules/modals/import-paste-data-common/import-paste-data-common.module';

@NgModule({ declarations: [
        AppComponent,
        PsdAnalysisPageComponent,
        SimulatePageComponent,
        EvaluatePageComponent,
        SimulateCHPageComponent,
        SimulateDispPageComponent,
        CalculationsPageComponent,
        MainContentComponent,
        PsdInputComponent,
        PsdAnalysisComponent,
        FluidsComponent,
        GravelComponent,
        DbDependentContentComponent,
        DbMigrationComponent,
    ],
    bootstrap: [AppComponent], imports: [CommonModule,
        ClientAuthModule.forRoot(environment.auth, environment.httpInterceptor),
        DbConnectionModule,
        PumpingModule,
        UnitsModule,
        BreadcrumbsModule,
        PrevNextNavModule,
        StoreModule.forRoot(appReducers, {
            metaReducers: metaReducers(environment.production),
            runtimeChecks: {
                strictStateImmutability: true,
                strictActionImmutability: true,
                strictStateSerializability: true,
                strictActionSerializability: true,
                strictActionWithinNgZone: true,
            },
        }),
        EffectsModule.forRoot(appEffects),
        // !environment.production ? StoreDevtoolsModule.instrument({ maxAge: 25 }) : [],
        StoreRouterConnectingModule.forRoot(),
        BrowserModule,
        AppRoutingModule,
        RibbonMenuModule,
        ImportPasteDataCommonModule,
        ImportDataModule,
        PasteDataModule,
        WellModule,
        FluidsModule,
        GravelModule,
        TabbedPageModule,
        FormsModule,
        HomeModule,
        PagePanelModule,
        RadioGroupModule,
        CompletionModule,
        ElectronModule,
        ReportingModule,
        NoopAnimationsModule,
        BrowserAnimationsModule,
        GridModule,
        TestPageModule,
        SettingsModule,
        ScrollingModule,
        ButtonModule,
        ErrorModule,
        UndoRedoModule,
        ModalsModule,
        MASPModule,
        ResuspensionModule,
        FrictionModule,
        SettlingModule,
        InjectionTestModule,
        LeakoffCoefficientModule,
        PSDInputModule,
        PSDAnalysisModule,
        PsdResultsModule,
        ResultsModule,
        GaugeDataPageModule,
        CommonModule,
        TrendAnalysisPageModule,
        DynamicDialogModule,
        LeftNavModule,
        OverlayModule,
        DataAnalysisPageModule,
        DevConsoleModule], providers: [
        ClientAuthService,
        ScreenService,
        AppInfoService,
        RouterHelperService,
        InputsHelperService,
        ToleranceHelperService,
        DialogService,
        ModalService,
        HomePageUndoRedoGuard,
        { provide: ENVIRONMENT, useValue: environment },
        {
            provide: AppTargetConfig,
            useValue: new AppTargetConfig(packProTargetConfig),
        },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {}
