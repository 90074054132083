import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { IValidatedModuleState } from '@dunefront/common/common/common-state.interfaces';
import { IColConfig, ImportColumnType } from '@dunefront/common/modules/data-storage/dto/import-template/import-template.dto';
import { ArgumentType } from '@dunefront/common/modules/data-storage/dto/import-column.dto';
import { IColNameWithUnit } from './parsed-result';

export type IValidatedColConfig = IColConfig & IValidatedModuleState<IColConfig>;

// this is unique code for DeltaTimeColumn - it's because column names needs to be unique and
// there is very low chance that someone put this as col name in imported file.
export const DELTA_TIME_COL_NAME = 'YVKw8cOci4RAYlQ7';

export const createColConfig = (colNameWithUnit: IColNameWithUnit, colIndex: number): IColConfig => ({
  name: colNameWithUnit.columnName,
  originalName: colNameWithUnit.originalColumnName,
  originalUnit: colNameWithUnit.originalUnit,
  measuredDepth: 0,
  measurementType: null,
  columnType: null,
  unitSymbol: 1,
  unitSystem: null,
  timeCustomFormat: null,
  isTimeCustomFormat: false,
  isDeltaTimePreviewColumn: colNameWithUnit.columnName === DELTA_TIME_COL_NAME,
  colIndex,
  isXAxis: ArgumentType.Value,
  possibleCustomFormats: [],
});

export const getDefaultUnitSystem = (columnType: ImportColumnType): UnitSystem => {
  switch (columnType) {
    case ImportColumnType.Time:
      return UnitSystem.Time;
    case ImportColumnType.Pump_Rate:
    case ImportColumnType.Return_Rate:
      return UnitSystem.Rate;
    case ImportColumnType.Pump_Pressure:
    case ImportColumnType.Return_Pressure:
    case ImportColumnType.Bottomhole_Pressure:
      return UnitSystem.Pressure;
    case ImportColumnType.Surface_Gravel_Concentration:
      return UnitSystem.Solid_Concentration;
    case ImportColumnType.Bottomhole_Temperature:
      return UnitSystem.Temperature;
    case ImportColumnType.Bit_Depth:
    case ImportColumnType.Block_Height:
      return UnitSystem.Long_Length;
    case ImportColumnType.Hook_Load:
      return UnitSystem.Force;
    case ImportColumnType.Mud_Weight:
      return UnitSystem.Liquid_Density;
    case ImportColumnType.Liquid_Additive_Concentration:
      return UnitSystem.Liquid_Concentration;
    default:
      return UnitSystem.None;
  }
};
