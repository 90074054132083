import { createSelector } from '@ngrx/store';
import { getSimulateMenuValidation } from '../simulate-evaluate-page.validators';
import { ILeftNavElement, LeftNavHelpers } from '../../../shared/components/left-nav/left-nav.helpers';
import { CaliperValidation } from '@dunefront/common/modules/well/model/caliper/caliper.validation';
import { getValidatedWellModuleState } from '../../../+store/well/validated-well.selectors';

export const getWellLeftNavElement$ = createSelector(
  getSimulateMenuValidation,
  getValidatedWellModuleState,
  (isValid, wellData): ILeftNavElement =>
    LeftNavHelpers.getNavElement('Well', 'well', 'icon-well', isValid.isWellValid, isValid.isWellWarning, [
      LeftNavHelpers.getItem('General data', 'well/general-data', wellData.isGeneralDataScreenValid),
      LeftNavHelpers.getItem(
        'Casing data',
        'well/casing-data',
        wellData.isCasingDataScreenValid,
        false,
        false,
        wellData.isCasingDataScreenWarning,
      ),
      LeftNavHelpers.getItem('Reservoir data', 'well/reservoir-data', wellData.isReservoirDataScreenValid),
      LeftNavHelpers.getItem(
        'Caliper data',
        'well/caliper-data',
        wellData.isCaliperDataScreenValid,
        !CaliperValidation.IsCaliperScreenEnabled(wellData),
      ),
    ]),
);
