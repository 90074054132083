import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input } from '@angular/core';
import { createTableRow, getRowsForCalculations, ITableRow, ITableState } from '@dunefront/common/common/common-grid.interfaces';
import { IGridColumnConfig } from '../../../../../shared/components/grid/grid.interfaces';
import { Store } from '@ngrx/store';
import { ModalService } from '../../../../../common-modules/modals/modal.service';
import { GridConfig } from '../../../../../shared/components/grid/grid-config';
import { EnumHelpers } from '@dunefront/common/utils/enum.helpers';
import * as completionActions from '../../../../../+store/completion/completion.actions';
import { RunningStringPipeType } from '@dunefront/common/dto/running-string-pipe.dto';
import { PipeType } from '@dunefront/common/dto/pipe.dto';
import { CompletionModuleState } from '@dunefront/common/modules/completion/completion-module.state';
import { GridContainerComponent } from '../../../../../shared/components/grid/grid-container.component';
import { ISelectItem } from '@dunefront/common/common/select.helpers';
import { Pipe } from '@dunefront/common/modules/pipes/pipe';
import { RunningStringValidation } from '@dunefront/common/modules/completion/model/running-string/running-string.validation';
import { getCompletionValidationDeps, getValidatedCompletionModuleState } from '../../../../../+store/completion/validated-completion.selectors';
import { GridResizeService } from '../../../../../shared/services/grid-resize.service';
import { getCurrentPumpingModuleState } from '../../../../../+store/pumping/selectors/pumping.selectors';
import { PumpingStateByRange } from '@dunefront/common/modules/pumping/pumping-module.state';
import { IDeleteRowsProps, IInsertRowsProps, IUpdateTableRowsProps } from '@dunefront/common/common/common-store-crud.interfaces';
import { runningStringGridConfig } from './running-string-grid.config';
import { PIPE_TYPES_LOOKUP_SRC_ID } from '../../../well/casing-data/casing-data-grid/casing-data-grid.config';
import { ICompletionValidationDependencies } from '@dunefront/common/modules/completion/model/completion.validation';
import { InsertLocation } from '@dunefront/common/modules/common.interfaces';
import { RunningStringPipesFactory } from '@dunefront/common/modules/pipes/running-string-pipes/running-string-pipes.factory';
import { ImportDataHelper } from '../../../../../shared/helpers/import-data/import-data-helper';

@Component({
  selector: 'app-running-string-grid',
  templateUrl: './running-string-grid.component.html',
  styleUrls: ['./running-string-grid.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RunningStringGridComponent extends GridContainerComponent<Pipe> {
  @Input()
  public runningStringData: ITableState<Pipe> = { rows: [], isValid: true };

  constructor(
    store: Store,
    cdRef: ChangeDetectorRef,
    private modalService: ModalService,
    el: ElementRef,
    gridResizeService: GridResizeService,
  ) {
    super(store, cdRef, el, new RunningStringGridConfig(store, modalService), gridResizeService);
  }

  protected getColumns(): IGridColumnConfig<Pipe>[] {
    return runningStringGridConfig;
  }
}

export class RunningStringGridConfig extends GridConfig<Pipe> {
  public pipeTypes: ISelectItem<RunningStringPipeType>[] = [];
  public placeholder = '';

  public data!: CompletionModuleState | undefined;
  private pumpingState: PumpingStateByRange | undefined;
  private completionValidationDeps?: ICompletionValidationDependencies;

  constructor(
    private store: Store,
    modalService: ModalService,
  ) {
    super(modalService);
    this.headerText = 'Running String';
    this.initLookupData();

    this.subscription.add(
      store.select(getValidatedCompletionModuleState).subscribe((completionData) => {
        this.data = completionData;
        this.initLookupData(this.data.RunningString.rows);
      }),
    );
    this.subscription.add(store.select(getCurrentPumpingModuleState).subscribe((pumpingState) => (this.pumpingState = pumpingState)));
    this.subscription.add(
      store
        .select(getCompletionValidationDeps)
        .subscribe((completionValidationDeps) => (this.completionValidationDeps = completionValidationDeps)),
    );
  }

  public override isCellDisabled(rows: ITableRow<Pipe>[], rowIndex: number, cellIndex: number): boolean {
    if (rows.length === 0) {
      return false;
    }

    const serviceToolIndex = rows.findIndex((row) => row.rowData.PipeType === PipeType.Service_Tool);
    const column = this.columns[cellIndex];

    if (rows[rowIndex].rowType === 'insert-row' && column.colId !== 'PipeType') {
      return true;
    }
    if (column.colId === 'PipeType' && (rowIndex === 0 || rowIndex === serviceToolIndex)) {
      return true;
    }
    if (column.colId === 'TopMD') {
      return true;
    }
    if (column.colId === 'BottomMD' && (rowIndex === serviceToolIndex || rowIndex === serviceToolIndex - 1)) {
      return true;
    }
    if (column.colId === 'Length') {
      return true;
    }
    if (column.colId === 'OuterDiameter' && rowIndex === serviceToolIndex) {
      return true;
    }

    return false;
  }

  public initLookupData(rows?: ITableRow<Pipe>[]): void {
    const serviceToolAlreadyExist = rows?.find((row) => row.rowData.PipeType === PipeType.Service_Tool);
    const pipeTypes = EnumHelpers.EnumToISelectItemArray(RunningStringPipeType);
    this.pipeTypes = [...pipeTypes];

    if (serviceToolAlreadyExist) {
      this.pipeTypes.forEach((pipe) => {
        if (+pipe.value === PipeType.Service_Tool) {
          pipe.disabled = true;
        }
      });
    }
    this.placeholder = 'Select Component';
  }

  public override mappingLookupDataByText(text: string): number | undefined {
    if (ImportDataHelper.containsWords(text, ['eccent'])) {
      return RunningStringPipeType.Eccentric_Gauge_Carrier;
    } else if (ImportDataHelper.containsWords(text, ['concent'])) {
      return RunningStringPipeType.Concentric_Gauge_Carrier;
    } else if (ImportDataHelper.containsWords(text, ['serv']) || ImportDataHelper.containsWords(text, ['tool'])) {
      return RunningStringPipeType.Service_Tool;
    } else if (ImportDataHelper.containsWords(text, ['pres']) || ImportDataHelper.containsWords(text, ['atten'])) {
      return RunningStringPipeType.Pressure_Attenuator;
    } else if (ImportDataHelper.containsWords(text, ['wash'])) {
      return RunningStringPipeType.Washpipe;
    } else if (ImportDataHelper.containsWords(text, ['work']) || ImportDataHelper.containsWords(text, ['string'])) {
      return RunningStringPipeType.Workstring;
    } else {
      return undefined;
    }
  }

  public override updateRowsAction(props: IUpdateTableRowsProps<Pipe>): void {
    const canUpdate = props.colIds?.includes('PipeType') ? this.validateRunningStringComponent(props.rows) : true;
    if (canUpdate) {
      this.store.dispatch(completionActions.updateRunningStringRow(props));
    }
  }

  public override async insertRowAction(props: IInsertRowsProps<Pipe>, refRow: ITableRow<Pipe>): Promise<void> {
    const isValid = this.validateRunningStringComponent(props.rows);
    if (isValid && props.insertLocation !== 'merge-rows') {
      const row = props.rows[0];
      if (row.rowIndex === 0) {
        row.rowData.TopMD = refRow.rowData.TopMD;
        row.rowData.BottomMD = refRow.rowData.BottomMD;
      } else {
        row.rowData.BottomMD = refRow.rowData.TopMD;
      }
    }
    this.store.dispatch(completionActions.insertRunningStringRow(props));
  }

  private validateRunningStringComponent(rows: ITableRow<Pipe>[]): boolean {
    if (!this.data || !this.pumpingState || !this.completionValidationDeps) {
      return false;
    }
    for (const row of rows) {
      const errorMessage = RunningStringValidation.ValidateRunningStringComponentName(
        this.data,
        this.pumpingState,
        getRowsForCalculations(this.data.RunningString.rows),
        row.rowData,
        row.rowIndex,
        this.completionValidationDeps,
      );

      row.error = { ...row.error, PipeType: errorMessage };
    }
    return true;
  }

  public override async canRowsBeDeleted(props: IDeleteRowsProps): Promise<boolean> {
    const errorMessage = RunningStringValidation.CanDelete(this.data as CompletionModuleState, props.rowIds);
    if (errorMessage) {
      this.modalService.showAlert(errorMessage).then();
      return false;
    }
    return true;
  }

  public override deleteRowsAction(props: IDeleteRowsProps): void {
    this.store.dispatch(completionActions.deleteRunningStringRows(props));
  }

  public override createEmptyModel(scenarioId: number): Pipe {
    return RunningStringPipesFactory.createEmpty(scenarioId);
  }

  public override createDefaultTableRows(scenarioId: number, noOfRows: number): ITableRow<Pipe>[] {
    const newTableRows = [];
    for (let index = 0; index < noOfRows; index++) {
      const newEmptyModel = this.createEmptyModel(scenarioId);
      newEmptyModel.PipeType = PipeType.Workstring;
      newTableRows.push(createTableRow(newEmptyModel, 'data', index, false));
    }
    return newTableRows;
  }

  public override replaceGridAction(rows: ITableRow<Pipe>[]): void {
    let isServiceToolFound = false;
    const checkedRows = rows.map((row) => {
      const newRow = { ...row, RowData: { ...row.rowData } };
      if (newRow.rowData.PipeType === PipeType.Service_Tool) {
        if (!isServiceToolFound) {
          isServiceToolFound = true;
        } else {
          newRow.rowData.PipeType = PipeType.Workstring;
        }
      }
      return newRow;
    });

    if (checkedRows.length > 0) {
      const props: IInsertRowsProps<any> = {
        rows: checkedRows,
        refId: 0,
        insertLocation: 'replace' as InsertLocation,
        shouldResetResults: true,
      };
      this.store.dispatch(completionActions.insertRunningStringRow(props));
    }
  }

  // this need to be defined like that because of lexical scope
  public override getLookupDataSource = (type: string): ISelectItem<any>[] => {
    if (type === PIPE_TYPES_LOOKUP_SRC_ID) {
      return this.pipeTypes;
    }
    return [];
  };

  public override getLookupDataPlaceholder = (type: string): string => {
    if (type === PIPE_TYPES_LOOKUP_SRC_ID) {
      return this.placeholder;
    }
    return '';
  };

  public override canRowsBeMerged(rows: ITableRow<Pipe>[], selectedRowsIndexes: number[]): boolean {
    const firstRowIndex = Math.min(...selectedRowsIndexes);
    const lastRowIndex = Math.max(...selectedRowsIndexes);

    if (rows[firstRowIndex] == null || rows[lastRowIndex] == null) {
      return false;
    }

    const serviceToolSelected = rows.slice(firstRowIndex, lastRowIndex + 1).some((row) => row.rowData.PipeType === PipeType.Service_Tool);
    if (serviceToolSelected) {
      this.modalService.showAlert('Service Tool can’t be merged', 'Information').then();
      return false;
    }
    return true;
  }

  protected override createMergedRow(rows: ITableRow<Pipe>[], firstRowIndex: number, lastRowIndex: number): ITableRow<Pipe> | undefined {
    if (rows[firstRowIndex] == null || rows[lastRowIndex] == null) {
      return undefined;
    }
    const mergedRow = { ...rows[firstRowIndex], rowData: { ...rows[firstRowIndex].rowData } };
    mergedRow.rowData = { ...mergedRow.rowData, Id: -1, BottomMD: rows[lastRowIndex].rowData['BottomMD'] };
    return mergedRow;
  }
}
