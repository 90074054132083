import { IReportTableColumnConfig } from '../common-report-data-helpers';
import { Pumping } from '@dunefront/common/modules/pumping/model/pumping/pumping';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { ColumnType, IGridColumnConfig } from '../../../../../components/grid/grid.interfaces';
import { ReportPumpingSimpleFluid, ReportWellFluid } from '../../doc/sections/pumping-report-generator.helper';
import { detailedFluidDefinitionConfig } from '../../../../../../pages/simulate-evaluate-page/pumping/well-fluids/detailed-fluid-definition/detailed-fluid-definition-grid/detailed-fluid-definition.config';
import { ChokeSetting, VariableChokeSetting } from '@dunefront/common/modules/pumping/dto/pumping.dto';

export const pumpingInitialProperties: IReportTableColumnConfig<Pumping>[] = [
  { colId: 'ToolPosition', headerText: 'Tool Position:', unitSystem: UnitSystem.None, type: ColumnType.select },
  { colId: 'PumpingDirection', headerText: 'Pumping Direction:', unitSystem: UnitSystem.None, type: ColumnType.select },
  { colId: 'IsSubseaBOP', headerText: 'Subsea BOP:', unitSystem: UnitSystem.None, type: ColumnType.boolean },
  {
    colId: 'MaxSurfacePressure',
    headerText: 'Pump Pop-Off:',
    unitSystem: UnitSystem.Pressure,
    type: ColumnType.number,
  },
];

export const pumpingInitialLineProperties = (isTreatingLine: boolean, isCoiledTubing: boolean): IReportTableColumnConfig<Pumping>[] => {
  const treatingLineLabel = isCoiledTubing && isTreatingLine ? 'Coiled Tubing:' : 'Treating Line:';
  const rows: IReportTableColumnConfig<Pumping>[] = [
    { colId: 'IsTreatingLine', headerText: treatingLineLabel, unitSystem: UnitSystem.None, type: ColumnType.boolean },
  ];

  const reelDiameter: IReportTableColumnConfig<Pumping> = {
    colId: 'ReelDiameter',
    headerText: 'Reel Diameter',
    unitSystem: UnitSystem.Diameter,
    type: ColumnType.number,
  };

  const lineProps: IReportTableColumnConfig<Pumping>[] = [
    {
      colId: 'TreatingLineID',
      headerText: isCoiledTubing ? 'Coil ID' : 'Treating Line ID:',
      unitSystem: UnitSystem.Diameter,
      type: ColumnType.number,
    },
    {
      colId: 'SurfaceTreatingLineLength',
      headerText: isCoiledTubing ? 'Coil Length' : 'Treating Line Length:',
      unitSystem: UnitSystem.Long_Length,
      type: ColumnType.number,
    },
  ];

  if (isCoiledTubing && isTreatingLine) {
    rows.push(reelDiameter);
  }

  if (isTreatingLine) {
    rows.push(...lineProps);
  }

  return rows;
};

// Bop / Choke section

const annularBop: IReportTableColumnConfig<Pumping> = {
  colId: 'HydrilState',
  headerText: 'Annular BOP:',
  unitSystem: UnitSystem.None,
  type: ColumnType.select,
};

export const openBOPProperties: IReportTableColumnConfig<Pumping>[] = [annularBop];

export const annularBOPProperties: IReportTableColumnConfig<Pumping>[] = [
  annularBop,
  {
    colId: 'SurfaceChokeLineID',
    headerText: 'Surface Line ID:',
    unitSystem: UnitSystem.Diameter,
    type: ColumnType.number,
  },
  {
    colId: 'SurfaceChokeLineLength',
    headerText: 'Surface Line Length:',
    unitSystem: UnitSystem.Long_Length,
    type: ColumnType.number,
  },
  {
    colId: 'ChokeKillLineID',
    headerText: 'Choke/Kill Line ID:',
    unitSystem: UnitSystem.Diameter,
    type: ColumnType.number,
  },
];

export const chokeProperties = (pumping: Pumping): IReportTableColumnConfig<Pumping>[] => {
  const columns: IReportTableColumnConfig<Pumping>[] = [
    {
      colId: 'ChokePosition',
      headerText: 'Choke Position:',
      unitSystem: UnitSystem.None,
      type: ColumnType.select,
    },
    {
      colId: 'ChokeOpenVolume',
      headerText: 'Choke Open Volume',
      unitSystem: UnitSystem.Liquid_Volume,
      type: ColumnType.number,
    },
    {
      colId: 'VariableChoke',
      headerText: 'Choke Type:',
      unitSystem: UnitSystem.None,
      type: ColumnType.select,
    },
    {
      colId: 'ChokeOrificeID',
      headerText: pumping.VariableChoke !== VariableChokeSetting.Fixed ? 'Max Choke Orifice ID' : 'Choke Orifice ID',
      unitSystem: UnitSystem.Diameter,
      type: ColumnType.number,
    },
  ];
  return columns
    .filter((col) => col.colId === 'ChokePosition' || pumping.ChokePosition !== ChokeSetting.None)
    .filter((col) => col.colId !== 'ChokeOpenVolume' || pumping.IsChokeOpenAtVolume);
};

export const simpleWellFluidsProperties: IReportTableColumnConfig<ReportPumpingSimpleFluid>[] = [
  {
    colId: 'WorkstringFluidId',
    headerText: 'Workstring Fluid:',
    unitSystem: UnitSystem.None,
    type: ColumnType.text,
  },
  {
    colId: 'UpperAnnulusFluidId',
    headerText: 'Upper Annulus Fluid:',
    unitSystem: UnitSystem.None,
    type: ColumnType.text,
  },
  {
    colId: 'WashpipeFluidId',
    headerText: 'Washpipe Fluid:',
    unitSystem: UnitSystem.None,
    type: ColumnType.text,
  },
  {
    colId: 'LowerAnnulusFluidId',
    headerText: 'Lower Annulus Fluid:',
    unitSystem: UnitSystem.None,
    type: ColumnType.text,
  },
];

export const detailedWellFluidsProperties = (isWellFluidDepthByVolume: boolean): IGridColumnConfig<ReportWellFluid>[] => {
  return detailedFluidDefinitionConfig(false, isWellFluidDepthByVolume)
    .filter((col) => col.visible)
    .map((col) => {
      if (col.type === ColumnType.checkbox) {
        col = { ...col, type: ColumnType.boolean };
      }
      if (col.colId === 'FluidId' || col.colId === 'GravelId') {
        col = { ...col, type: ColumnType.text };
      }
      return col as IGridColumnConfig<ReportWellFluid>;
    });
};
