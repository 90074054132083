import { BasePptSectionGenerator, PptContext } from './base-ppt-section-generator';
import { Base64Image } from '../../../../../common-modules/chart/image-provider.helpers';

export class ImagePptGenerator extends BasePptSectionGenerator {
  constructor(protected override context: PptContext & { image?: Base64Image; title: string; subTitle?: string }) {
    super(context);
  }

  public async generate(): Promise<void> {
    this.addImageSlide(this.context);
  }
}
