import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { clearFileOperationFailure } from '../../../+store/file-manager/file-manager.actions';
import { getAllFilesAndFoldersState, getFileOperationErrorState } from '../../../+store/file-manager/file-manager.selectors';
import { Subscription } from 'rxjs';
import { FileErrorHelper, IFileFolderName } from './file-error.helper';
import { FileType, IFile, Repository } from '@dunefront/common/dto/file.dto';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { notEmpty } from '@dunefront/common/common/state.helpers';
import { FileManagerHelper } from '../../../+store/file-manager/file-manager.helper';

@Component({
  template: '',
})
export abstract class BaseFileActionDialogComponent implements OnDestroy {
  protected subscription = new Subscription();
  public errorMessage?: string;
  protected personalFileNameList?: IFile;
  protected sharedFileNameList?: IFile;
  public file!: IFile;

  public fileName: IFileFolderName = {
    targetName: '',
    error: {},
  };

  constructor(
    public activeModal: DynamicDialogRef,
    public config: DynamicDialogConfig,
    protected store: Store,
    protected cdRef: ChangeDetectorRef,
  ) {
    this.subscription.add(
      this.store.select(getFileOperationErrorState).subscribe((state) => {
        this.errorMessage = FileErrorHelper.getFileErrorMessage(state);
        this.cdRef.markForCheck();
      }),
    );
    this.subscription.add(
      notEmpty(this.store.select(getAllFilesAndFoldersState)).subscribe((allFilesAndFoldersState) => {
        this.personalFileNameList = allFilesAndFoldersState.rootFolders?.personalRootFolder;
        this.sharedFileNameList = allFilesAndFoldersState.rootFolders?.sharedRootFolder;
        this.cdRef.markForCheck();
      }),
    );
  }

  protected getNamesForValidation(repository: Repository, targetFolderArray: string[], fileType: FileType): string[] {
    const filteredTargetFolderArray = targetFolderArray.filter(Boolean);
    const targetRepository = repository === 'shared' ? this.sharedFileNameList : this.personalFileNameList;
    const targetFolder = FileManagerHelper.findFolder(filteredTargetFolderArray, targetRepository);
    return targetFolder?.Children?.filter((child: IFile) => child.FileType === fileType).map((child: IFile) => child.Name) || [];
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public cancelClicked(): void {
    this.store.dispatch(clearFileOperationFailure());
    this.activeModal.close();
  }

  public getFolderArrayFromParent(targetFolder: IFile): string[] {
    if (targetFolder.Name === '') {
      return targetFolder.Folder;
    } else {
      return [...targetFolder.Folder, targetFolder.Name];
    }
  }
}
