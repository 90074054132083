import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { PanelButtonComponent } from '../../panel.button.component';
import { AxisSelectionProps, getAxisSelectionProps } from '../../../../../+store/menu-selectors/chart/menu-chart.selector';
import { Store } from '@ngrx/store';
import { getCurrentAppModuleType } from '../../../../../+store/ui/ui.selectors';
import { isDataBased } from '@dunefront/common/modules/scenario/scenario.dto';

@Component({
  selector: 'app-axis-selection',
  templateUrl: './axis-selection.component.html',
  styleUrls: ['./axis-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AxisSelectionComponent extends PanelButtonComponent implements OnDestroy {
  public axisSelectionProps!: AxisSelectionProps;
  public isDataBasedModule!: boolean;

  constructor(
    protected override store: Store,
    protected cdRef: ChangeDetectorRef,
  ) {
    super(store);

    this.subscription.add(
      store.select(getAxisSelectionProps).subscribe((axisSelectionProps) => {
        this.axisSelectionProps = axisSelectionProps;
        this.cdRef.markForCheck();
      }),
    );

    this.subscription.add(
      store.select(getCurrentAppModuleType).subscribe((currentAppModuleType) => {
        this.isDataBasedModule = isDataBased(currentAppModuleType);
        this.cdRef.markForCheck();
      }),
    );
  }
}
