import { IModuleState } from '../../common/common-state.interfaces';
import { DictionaryWithArray, IDictionaryWithArray } from '../../common/state.helpers';
import { RangeConstants, RangeDto } from '../../dto/range.dto';
import { VerticalShiftDto } from '../../dto/trend-analysis/vertical-shift.dto';
import { ScenarioRangePropertiesDto } from '../../dto/scenario-range-properties.dto';

export interface RangeModuleState extends IModuleState {
  ranges: IDictionaryWithArray<RangeDto>;
  scenarioRangeProperties: IDictionaryWithArray<ScenarioRangePropertiesDto>;
  currentRangeId: number | undefined;
  editingDto: RangeDto | undefined;
  verticalShifts: IDictionaryWithArray<VerticalShiftDto>;
}

export const initialRangeModuleState: RangeModuleState = {
  ranges: DictionaryWithArray.clear(),
  scenarioRangeProperties: DictionaryWithArray.clear(),
  verticalShifts: DictionaryWithArray.clear(),
  currentRangeId: RangeConstants.EntireRangeId,
  editingDto: undefined,
  isLoaded: false,
};
