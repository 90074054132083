import { noErrors } from '@dunefront/common/common/state.helpers';
import { FlowPathType } from '@dunefront/common/modules/calculators/resuspension-calculator/resuspension-calculator.dto';
import { ConvertUnitPipe } from '@dunefront/common/modules/units/convert-unit.pipe/convert-unit.pipe';
import { ISettlingValidationDependencies } from '../settling-calculator.actions';
import { FluidType } from '@dunefront/common/modules/fluid/dto/fluid.dto';
import { MAX_GRAVEL_CONCENTRATION_SI, MAX_RATE_LIMIT_SI } from '@dunefront/common/common/constants';
import { IError } from '@dunefront/common/common/common-state.interfaces';
import { SettlingCalculatorModuleState, ValidatedSettilingCalculatorModuleState } from '../settling-calculator-module.state';
import { SettlingCalculatorDto } from '@dunefront/common/modules/calculators/dto/settling-calculator.dto';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { Validation } from '@dunefront/common/common/validation';

export class SettlingCalculatorValidation {
  public static validate(state: SettlingCalculatorModuleState, deps: ISettlingValidationDependencies): ValidatedSettilingCalculatorModuleState {
    const { SettlingCalculator: settlingCalculator } = state;
    const error: IError<SettlingCalculatorDto> = {
      MinDiameter: this.ValidateMinDiameter(settlingCalculator),
      MaxDiameter: this.ValidateMaxDiameter(settlingCalculator),
      FluidId: Validation.ValidateFluid(settlingCalculator.FluidId),
      GravelId: Validation.ValidateGravel(settlingCalculator.GravelId),
      Concentration: this.ValidateConcentration(settlingCalculator),
      MaxRate: this.ValidateMaxRate(settlingCalculator),
      StaticViscosity: this.ValidateStaticViscosity(settlingCalculator.StaticViscosity, deps, settlingCalculator.FluidId),
      Deviation: this.ValidateDeviation(settlingCalculator.Deviation, deps, settlingCalculator.GravelId),
      Eccentricity: this.ValidateEccentricity(settlingCalculator),
    };

    const isValid = noErrors(error);

    return {
      ...state,
      SettlingCalculator: { ...state.SettlingCalculator, error, isValid },
      error: {},
      isValid,
    };
  }

  private static ValidateMinDiameter(settlingCalculator: SettlingCalculatorDto): string {
    if (settlingCalculator.MinDiameter <= 0 && settlingCalculator.FlowPathType !== FlowPathType.Rectangular_Shunt) {
      return 'ID must be greater than 0';
    }
    if (settlingCalculator.FlowPathType === FlowPathType.Annulus && settlingCalculator.MinDiameter >= settlingCalculator.MaxDiameter) {
      return 'ID must be less than OD';
    }
    return '';
  }

  private static ValidateMaxDiameter(settlingCalculator: SettlingCalculatorDto): string {
    if (settlingCalculator.FlowPathType === FlowPathType.Annulus && settlingCalculator.MaxDiameter <= 0) {
      return 'OD must be greater than 0';
    }
    if (settlingCalculator.FlowPathType === FlowPathType.Annulus && settlingCalculator.MinDiameter >= settlingCalculator.MaxDiameter) {
      return 'OD must be greater than ID';
    }
    return '';
  }

  private static ValidateConcentration(settlingCalculator: SettlingCalculatorDto): string {
    if (settlingCalculator.Concentration <= 0) {
      return 'Concentration must be greater than 0';
    }

    if (settlingCalculator.Concentration > MAX_GRAVEL_CONCENTRATION_SI) {
      const encodeValue = ConvertUnitPipe.encode(UnitSystem.Solid_Concentration, MAX_GRAVEL_CONCENTRATION_SI);
      return `Concentration must be less than or equal to ${encodeValue}`;
    }
    return '';
  }

  private static ValidateMaxRate(settlingCalculator: SettlingCalculatorDto): string {
    if (settlingCalculator.MaxRate <= 0) {
      return 'Max rate must be greater than 0';
    }

    if (settlingCalculator.MaxRate > MAX_RATE_LIMIT_SI) {
      const encodeValue = ConvertUnitPipe.encode(UnitSystem.Rate, MAX_RATE_LIMIT_SI);
      return `Max rate must be less than or equal to ${encodeValue}`;
    }
    return '';
  }

  private static ValidateStaticViscosity(staticViscosity: number, deps: ISettlingValidationDependencies, fluidId: number | null): string {
    if (fluidId != null && deps.fluids[fluidId]?.Type === FluidType.Newtonian) {
      return '';
    }
    if (staticViscosity <= 0) {
      return 'Static viscosity must be greater than 0';
    }
    return '';
  }

  private static ValidateDeviation(deviation: number, deps: ISettlingValidationDependencies, gravelId: number | null): string {
    if (deviation < 0) {
      return 'Deviation must be greater than or equal to 0';
    }
    const selectedGravel = gravelId != null && deps.gravels[gravelId] ? deps.gravels[gravelId] : null;
    const angleOfRepose = selectedGravel != null ? selectedGravel.AngleOfRepose : 0;
    const maxDeviation = 90 + angleOfRepose;
    if (deviation >= maxDeviation) {
      const encodeValue = ConvertUnitPipe.encode(UnitSystem.Angle, maxDeviation);
      return `Deviation must be less than ${encodeValue}`;
    }
    return '';
  }

  private static ValidateEccentricity(settlingCalculator: SettlingCalculatorDto): string {
    if (settlingCalculator.FlowPathType === FlowPathType.Annulus && settlingCalculator.Eccentricity < 0) {
      return 'Eccentricity must be greater than or equal to 0';
    }
    if (settlingCalculator.FlowPathType === FlowPathType.Annulus && settlingCalculator.Eccentricity > 1) {
      return 'Eccentricity must be less than or equal to 1';
    }
    return '';
  }
}
