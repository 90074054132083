import { IReportTableColumnConfig } from '../common-report-data-helpers';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { ColumnType } from '../../../../../components/grid/grid.interfaces';
import { ValidatedSettings } from '@dunefront/common/modules/settings/dto/settingsDto';

export const settingsGeneralProperties = (IsRopingPercentage: boolean): IReportTableColumnConfig<ValidatedSettings>[] => [
  {
    colId: 'PermeabilityRatio',
    headerText: 'Vertical to Horizontal Perm. Ratio',
    unitSystem: UnitSystem.None,
    type: ColumnType.number,
    decimalPlaces: 4,
  },
  {
    colId: 'IsBottomholeGravelConcMD',
    headerText: 'Bottomhole Gravel Conc MD Specified',
    unitSystem: UnitSystem.None,
    type: ColumnType.boolean,
  },
  {
    colId: 'BottomholeGravelConcMD',
    headerText: 'Bottomhole Gravel Conc MD',
    unitSystem: UnitSystem.Long_Length,
    type: ColumnType.number,
    decimalPlaces: 4,
  },
  IsRopingPercentage
    ? {
        colId: 'RopingPercentage',
        headerText: 'Roping Percentage',
        unitSystem: UnitSystem.None,
        type: ColumnType.number,
        overrideUnitSymbol: '%',
        decimalPlaces: 4,
      }
    : {
        colId: 'RopingVolume',
        headerText: 'Roping Volume',
        unitSystem: UnitSystem.Liquid_Volume,
        type: ColumnType.number,
      },
  {
    colId: 'IsDiameterValidationDisabled',
    headerText: 'Disable Diameter Validation',
    unitSystem: UnitSystem.None,
    type: ColumnType.boolean,
  },
];

export const settingsPerformanceProperties = (): IReportTableColumnConfig<ValidatedSettings>[] => [
  {
    colId: 'MaxVolumeStep',
    headerText: 'Max Volume Step',
    unitSystem: UnitSystem.Liquid_Volume,
    type: ColumnType.number,
    decimalPlaces: 4,
  },
  {
    colId: 'RateChangeTolerance',
    headerText: 'Rate Tolerance',
    unitSystem: UnitSystem.Rate,
    type: ColumnType.number,
    decimalPlaces: 4,
  },
  {
    colId: 'GravelConcentrationChangeTolerance',
    headerText: 'Gravel Concentration Tolerance',
    unitSystem: UnitSystem.Solid_Concentration,
    type: ColumnType.number,
    decimalPlaces: 4,
  },
  {
    colId: 'MaxNumberOfReservoirCells',
    headerText: 'Max No. of Reservoir Cells',
    unitSystem: UnitSystem.None,
    type: ColumnType.number,
    decimalPlaces: 0,
  },
  {
    colId: 'ChokePressureChangeTolerance',
    headerText: 'Choke Pressure Tolerance',
    unitSystem: UnitSystem.Pressure,
    type: ColumnType.number,
    decimalPlaces: 4,
  },
];

export const settingsFrictionProperties: IReportTableColumnConfig<ValidatedSettings>[] = [
  {
    colId: 'DischargeCoefficient',
    headerText: 'Discharge Coefficient',
    unitSystem: UnitSystem.None,
    type: ColumnType.number,
    decimalPlaces: 4,
  },
  {
    colId: 'ICDRateDistributionMinTolerance',
    headerText: 'ICD Rate Distribution Min Tolerance',
    unitSystem: UnitSystem.Pressure,
    type: ColumnType.number,
    decimalPlaces: 4,
  },
  {
    colId: 'IsToolJointAnalysed',
    headerText: 'Analyze Tool Joint',
    unitSystem: UnitSystem.None,
    type: ColumnType.boolean,
  },
  {
    colId: 'IsFrictionEccentric',
    headerText: 'Eccentric Friction',
    unitSystem: UnitSystem.None,
    type: ColumnType.boolean,
  },
  {
    colId: 'UseShuntVESFrictionCorrelations',
    headerText: 'Shunt Surfactant Gel Correlations',
    unitSystem: UnitSystem.None,
    type: ColumnType.boolean,
  },
  {
    colId: 'IsRoughness',
    headerText: 'Roughness',
    unitSystem: UnitSystem.None,
    type: ColumnType.boolean,
  },
  {
    colId: 'TreatingLineRoughness',
    headerText: 'Treating',
    unitSystem: UnitSystem.Diameter,
    type: ColumnType.number,
    decimalPlaces: 4,
  },
  {
    colId: 'ChokeLineRoughness',
    headerText: 'Choke',
    unitSystem: UnitSystem.Diameter,
    type: ColumnType.number,
    decimalPlaces: 4,
  },
  {
    colId: 'WorkstringRoughness',
    headerText: 'Workstring',
    unitSystem: UnitSystem.Diameter,
    type: ColumnType.number,
    decimalPlaces: 4,
  },
  {
    colId: 'ScreenAnnulusRoughness',
    headerText: 'Screen Annulus',
    unitSystem: UnitSystem.Diameter,
    type: ColumnType.number,
    decimalPlaces: 4,
  },
  {
    colId: 'WashpipeRoughness',
    headerText: 'Washpipe',
    unitSystem: UnitSystem.Diameter,
    type: ColumnType.number,
    decimalPlaces: 4,
  },
  {
    colId: 'BlankAnnulusRoughness',
    headerText: 'Blank Annulus',
    unitSystem: UnitSystem.Diameter,
    type: ColumnType.number,
    decimalPlaces: 4,
  },
  {
    colId: 'WashpipeAnnulusRoughness',
    headerText: 'Washpipe Annulus',
    unitSystem: UnitSystem.Diameter,
    type: ColumnType.number,
    decimalPlaces: 4,
  },
  {
    colId: 'WorkstringAnnulusRoughness',
    headerText: 'Workstring Annulus',
    unitSystem: UnitSystem.Diameter,
    type: ColumnType.number,
    decimalPlaces: 4,
  },
  {
    colId: 'WashpipeGaugeMeasurementFlowPath',
    headerText: 'WP Gauge Measurement Flow Path',
    unitSystem: UnitSystem.None,
    type: ColumnType.select,
  },
];

export const settingsPackingProperties: IReportTableColumnConfig<ValidatedSettings>[] = [
  {
    colId: 'OroskarAndTurianDiameter',
    headerText: 'Oroskar and Turian Diameter',
    unitSystem: UnitSystem.None,
    type: ColumnType.select,
  },
  {
    colId: 'StokesSettlingMultiplier',
    headerText: 'Stokes Settling Multiplier',
    unitSystem: UnitSystem.None,
    type: ColumnType.number,
    decimalPlaces: 4,
  },
  {
    colId: 'BridgingRatio',
    headerText: 'Bridging Ratio',
    unitSystem: UnitSystem.None,
    type: ColumnType.number,
    decimalPlaces: 4,
  },
  {
    colId: 'PerfAutoPackPercentage',
    headerText: 'Max Perf Auto Pack Percentage',
    unitSystem: UnitSystem.None,
    type: ColumnType.number,
    decimalPlaces: 4,
    overrideUnitSymbol: '%',
  },
  {
    colId: 'NetPressureForBridging',
    headerText: 'Net Pressure for Annular Bridging',
    unitSystem: UnitSystem.Pressure,
    type: ColumnType.number,
    decimalPlaces: 4,
  },
  {
    colId: 'UseNumberOfGrainDiametersForBridging',
    headerText: 'Use Grain Diameter for Perf Bridging',
    unitSystem: UnitSystem.None,
    type: ColumnType.boolean,
  },
  {
    colId: 'NumberOfGrainDiametersToBridge',
    headerText: 'No. of Grain Diameters',
    unitSystem: UnitSystem.None,
    type: ColumnType.number,
    decimalPlaces: 0,
  },
  {
    colId: 'DoesPackCureLosses',
    headerText: 'Pack Cure Losses',
    unitSystem: UnitSystem.None,
    type: ColumnType.boolean,
  },
  {
    colId: 'AreLossesRedistributed',
    headerText: 'Redistribute Losses',
    unitSystem: UnitSystem.None,
    type: ColumnType.boolean,
  },
  {
    colId: 'ModelScreenHandlingLength',
    headerText: 'Model Screen Handling Length',
    unitSystem: UnitSystem.None,
    type: ColumnType.boolean,
  },
  {
    colId: 'IsNozzleLeakoffConvergence',
    headerText: 'Enable Nozzle Leakoff Convergence',
    unitSystem: UnitSystem.None,
    type: ColumnType.boolean,
  },
  {
    colId: 'IsNozzleLeakoffBeta',
    headerText: 'Specify Nozzle Leakoff Forchheimer Beta',
    unitSystem: UnitSystem.None,
    type: ColumnType.boolean,
  },
  {
    colId: 'NozzleLeakoffBeta',
    headerText: 'Shunt Nozzle Leakoff Forchheimer Beta',
    unitSystem: UnitSystem.Reciprocal_Length,
    type: ColumnType.number,
    decimalPlaces: 4,
  },
];

export const settingsThermalProperties: IReportTableColumnConfig<ValidatedSettings>[] = [
  {
    colId: 'MaxThermalCellLength',
    headerText: 'Max Thermal Cell Length',
    unitSystem: UnitSystem.Long_Length,
    type: ColumnType.number,
    decimalPlaces: 4,
  },
  {
    colId: 'MinThermalCellLength',
    headerText: 'Min Thermal Cell Length',
    unitSystem: UnitSystem.Long_Length,
    type: ColumnType.number,
    decimalPlaces: 4,
  },
  {
    colId: 'PipeThermalConductivity',
    headerText: 'Pipe Thermal Conductivity',
    unitSystem: UnitSystem.Thermal_Conductivity,
    type: ColumnType.number,
    decimalPlaces: 4,
  },
  {
    colId: 'ScreenThermalConductivity',
    headerText: 'Screen Thermal Conductivity',
    unitSystem: UnitSystem.Thermal_Conductivity,
    type: ColumnType.number,
    decimalPlaces: 4,
  },
  {
    colId: 'CementThickness',
    headerText: 'Cement Thickness (Radial)',
    unitSystem: UnitSystem.Diameter,
    type: ColumnType.number,
    decimalPlaces: 4,
  },
  {
    colId: 'CementThermalConductivity',
    headerText: 'Cement Thermal Conductivity',
    unitSystem: UnitSystem.Thermal_Conductivity,
    type: ColumnType.number,
    decimalPlaces: 4,
  },
  {
    colId: 'FilterCakeThickness',
    headerText: 'Filter Cake Thickness (Radial)',
    unitSystem: UnitSystem.Diameter,
    type: ColumnType.number,
    decimalPlaces: 4,
  },
  {
    colId: 'FilterCakeThermalConductivity',
    headerText: 'Filter Cake Thermal Conductivity',
    unitSystem: UnitSystem.Thermal_Conductivity,
    type: ColumnType.number,
    decimalPlaces: 4,
  },
  {
    colId: 'MinDeviationForHorizontal',
    headerText: 'Min Deviation for Horizontal',
    unitSystem: UnitSystem.Angle,
    type: ColumnType.number,
    decimalPlaces: 4,
  },
  {
    colId: 'HorizontalThermalMultiplier',
    headerText: 'Horizontal Thermal Multiplier',
    unitSystem: UnitSystem.None,
    type: ColumnType.number,
    decimalPlaces: 4,
  },
];
