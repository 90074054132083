import { OneTimeInstructionType } from './ui-module.state';
import { PartialEnumDictionary } from '@dunefront/common/common/state.helpers';

export class OneTimeMessageHelpers {
  public static readonly MESSAGE_WILL_NOT_BE_DISPLAYED_AGAIN_SUFFIX = ' (this message will not be displayed again)';

  public static shouldShowInstruction(
    instructionType: OneTimeInstructionType,
    instructionShown: PartialEnumDictionary<OneTimeInstructionType, boolean>,
  ): boolean {
    return !instructionShown[instructionType];
  }

  public static getMessage(instructionType: OneTimeInstructionType): string[] {
    let message;
    switch (instructionType) {
      case OneTimeInstructionType.annotation:
        message = `Double click anywhere on the chart to create a new annotation,
             or double click an existing annotation to edit it`;
        break;

      case OneTimeInstructionType.marker:
        message = `Double click anywhere on the chart to create a new marker,
             or double click an existing marker to edit it`;
        break;

      case OneTimeInstructionType.verticalAutoShift:
        message = `Select a point on an existing series as a reference for shifting the data`;
        break;

      case OneTimeInstructionType.horizontalAutoShift:
        message = `Select a pressure series as a reference for shifting the data.
            This feature works by aligning the maximum value of each series in the data range visible on the chart.
            It is recommended to roughly align the data then zoom in on an operation where there are significant
            rate and/or pressure changes`;
        break;

      case OneTimeInstructionType.optimize:
        message = `The results summary tab will now be enabled and reporting
            charts will show Evaluate module results as solid lines and
            Simulate module results as dashed lines`;
        break;
      case OneTimeInstructionType.deleteResults:
        message = `This will cause the results to be deleted - would you like to continue?`;
        break;

      case OneTimeInstructionType.videoRecordingResizeInfo:
        message = `Video recording will now start, don’t minimize or resize the window during this time.`;
        break;

      case OneTimeInstructionType.gradientLine:
        message = 'Click and drag anywhere on the chart to create a new slope line, or double click an existing slope line to edit it';
        break;

      case OneTimeInstructionType.changePSDInputType:
        message = 'Are you sure you want to change input type? Your data will be recalculated and updated.';
        break;

      case OneTimeInstructionType.mergeRowsInGrid:
        message = 'Properties of the first row will be used for the merged row';
        break;
    }

    if (message) {
      return [message, this.MESSAGE_WILL_NOT_BE_DISPLAYED_AGAIN_SUFFIX];
    } else {
      return ['MESSAGE NOT FOUND!'];
    }
  }
}
