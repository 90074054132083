import { createAction, props } from '@ngrx/store';
import { LeakoffCoefficientCalculatorModuleState } from './leakoff-coefficient-calculator-module.state';
import { IUpdateRowsProps } from '../../../common/common-store-crud.interfaces';

export const updateLeakoffCoefficientCalculatorRow = createAction(
  '[calculators] updateLeakoffCoefficientCalculatorRow',
  props<IUpdateRowsProps<LeakoffCoefficientCalculatorModuleState>>(),
);

export const calculateLeakoffCoefficient = createAction('[calculators] calculateLeakoffCoefficient', props<{ ScenarioId: number }>());
