import PptxGenJS from 'pptxgenjs';
import {
  baseTextProps,
  contentPosition,
  defaultTableProps,
  keyValueTableProps,
  reportTitlePosition,
  slideNumberProps,
  slideSubTitleFontSize,
  slideTitleFontSize,
  subTitlePosition,
  titlePosition,
  twoColumnsLayout,
} from './style-constants';
import { Base64Image } from '../../../../../common-modules/chart/image-provider.helpers';
import { accentColor, accentSubColor } from '../../report-consts';
import { Store } from '@ngrx/store';
import { CommonReportDataHelpers } from '../common/common-report-data-helpers';
import { PresetColumnsWidths } from '../doc/document-table-generator.helper';
import { emptyTableCell } from './ppt-document-table-generator.helper';
import Slide = PptxGenJS.Slide;
import TableRow = PptxGenJS.TableRow;
import TableProps = PptxGenJS.TableProps;

export interface PptContext {
  readonly pptx: PptxGenJS;
  readonly store: Store;
}

export abstract class BasePptSectionGenerator {
  protected constructor(protected readonly context: PptContext) {}

  public abstract generate(): Promise<void>;

  protected static addSlide(pptx: PptxGenJS, { title, subTitle }: AddSlideProps): Slide {
    const slide = pptx.addSlide();

    if (title) {
      this.addSlideTitle(title, slide);
    }

    if (subTitle) {
      this.addSlideSubTitle(subTitle, slide);
    }

    slide.slideNumber = { ...slideNumberProps };

    return slide;
  }

  protected static addSlideTitle(title: string, slide: Slide): void {
    const maxLength = 130;
    title = title.length > maxLength ? title.substring(0, maxLength - 1) + '…' : title;

    slide.addText(title, {
      ...titlePosition,
      ...baseTextProps,
      fontSize: slideTitleFontSize,
      color: accentColor,
      valign: 'bottom',
      bold: true,
    });
  }

  protected static addSlideSubTitle(subTitle: string, slide: Slide): void {
    slide.addText(subTitle, {
      ...subTitlePosition,
      ...baseTextProps,
      fontSize: slideSubTitleFontSize,
      color: accentSubColor,
      valign: 'top',
    });
  }

  protected static addTableToSlide(slide: Slide, props: AddTableSlideProps): void {
    const { tableRows, options, presetColumnsWidths, title, subTitle } = props;

    // columns widths are calculated in percentage, now we need to calculate real numbers based on full page size
    const multiplier = contentPosition.w / 100;
    const isCodeCellPresent = tableRows[0].findIndex((c) => c.text === 'Code') !== -1;
    const convertedColumnsWidths = CommonReportDataHelpers.generateColumnsWidths(
      tableRows[0].length,
      isCodeCellPresent,
      presetColumnsWidths,
    ).map((cw) => cw * multiplier);

    const initialOptions = options ?? defaultTableProps;

    const opt = {
      ...initialOptions,
      colW: initialOptions.colW ?? convertedColumnsWidths,
    };

    slide.addTable(tableRows, opt);

    // update auto paged slides
    for (const newAutoPagedSlide of slide.newAutoPagedSlides) {
      const slide = newAutoPagedSlide as any as Slide;

      if (title) {
        BasePptSectionGenerator.addSlideTitle(title + ' continued...', slide);
      }

      if (subTitle) {
        BasePptSectionGenerator.addSlideSubTitle(subTitle, slide);
      }

      newAutoPagedSlide.slideNumber = { ...slideNumberProps };
    }
  }

  protected addTableSlide(props: AddTableSlideProps): void {
    const pptx = this.context.pptx;

    // create slide
    const slide = BasePptSectionGenerator.addSlide(pptx, props);

    BasePptSectionGenerator.addTableToSlide(slide, props);
  }

  protected addKeyValueTableSlide(props: AddTableSlideProps): void {
    props = {
      ...props,
      options: props.options ?? keyValueTableProps,
    };

    this.addTableSlide(props);
  }

  protected addTwoColumnsKeyValueTableSlide(props: AddTwoColumnTableSlideProps): void {
    const pptx = this.context.pptx;
    const { title, subTitle, options, tableRowsGroups } = props;

    const tableProps = props.options ?? keyValueTableProps;
    const { columnsGap, keyFieldWidth, leftColumnMaxRowsCount } = twoColumnsLayout;

    // create slide
    const slide = BasePptSectionGenerator.addSlide(pptx, props);

    // total content width (two columns + gap)
    const fullContentWidth = (options?.w ?? contentPosition.w) as number;
    const fullContentX = (options?.x ?? contentPosition.x) as number;
    const tableW = (fullContentWidth - columnsGap) / 2.0;
    // widths of columns within each table
    const colW = [keyFieldWidth, tableW - keyFieldWidth];

    // split groups to two arrays of table rows
    const [leftTableRows, rightTableRows] = this.splitTableRowsGroups(tableRowsGroups, leftColumnMaxRowsCount);

    // add left table
    const leftTableProps = {
      ...tableProps,
      w: tableW,
      colW,
    };

    BasePptSectionGenerator.addTableToSlide(slide, {
      title,
      subTitle,
      tableRows: leftTableRows,
      options: leftTableProps,
    });

    // add right table (only if some rows available)
    if (rightTableRows.length > 0) {
      const rightTableProps = {
        ...tableProps,
        x: fullContentX + tableW + columnsGap,
        w: tableW,
        colW,
      };

      BasePptSectionGenerator.addTableToSlide(slide, {
        title,
        subTitle,
        tableRows: rightTableRows,
        options: rightTableProps,
      });
    }
  }

  protected splitTableRowsGroups(tableRowsGroups: TableRow[][], firstColumnMaxRowsCount: number): [TableRow[], TableRow[]] {
    const firstColumn: TableRow[] = [];
    const secondColumn: TableRow[] = [];

    for (const group of tableRowsGroups) {
      const separatorLength = firstColumn.length === 0 ? 0 : 1;
      // add to first column only if group fits and never added to second col, otherwise need to continue in second column and if
      // it's possible that prev group was not fitting, but current one would, this however would mess up order of groups
      const addToFirstColumn = firstColumn.length + group.length + separatorLength <= firstColumnMaxRowsCount && secondColumn.length === 0;
      const destinationColumn = addToFirstColumn ? firstColumn : secondColumn;

      if (destinationColumn.length > 0) {
        destinationColumn.push([emptyTableCell, emptyTableCell]);
      }

      destinationColumn.push(...group);
    }

    return [firstColumn, secondColumn];
  }

  protected addImageSlide(props: AddImageSlideProps): void {
    const pptx = this.context.pptx;
    const slide = BasePptSectionGenerator.addSlide(pptx, props);

    const { image } = props;
    if (image == null || image.imageDataBase64.length === 0) {
      slide.addText(`Chart is not available - results are needed.`, {
        ...reportTitlePosition,
        ...baseTextProps,
        align: 'center',
        valign: 'middle',
        fontSize: 12,
      });
      return;
    }

    slide.addImage({
      ...contentPosition,
      w: image.size.width,
      h: image.size.height,
      data: image.imageDataBase64,
      sizing: { type: 'contain', ...contentPosition },
    });
  }

  protected get store(): Store {
    return this.context.store;
  }
}

export interface AddSlideProps {
  title?: string;
  subTitle?: string;
}

export interface AddTableSlideProps extends AddSlideProps {
  tableRows: TableRow[];
  options?: TableProps;
  presetColumnsWidths?: PresetColumnsWidths;
}

export interface AddTwoColumnTableSlideProps extends AddSlideProps {
  tableRowsGroups: TableRow[][];
  options?: TableProps;
  presetColumnsWidths?: PresetColumnsWidths;
}

export interface AddImageSlideProps extends AddSlideProps {
  image?: Base64Image;
}
