import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { IValidatedDataType } from '@dunefront/common/common/common-grid.interfaces';
import { IError, ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { IAxisProps, IEditAxisProps } from '../chart-component-helpers/chart-types';
import { UnitConverterHelper } from '@dunefront/common/unit-converters/unit.converter.helper';
import { noErrors } from '@dunefront/common/common/state.helpers';
import { IAxisStyle, XAxisFormat } from '@dunefront/common/modules/reporting/dto/chart-axis-property.dto';
import dayjs from 'dayjs';
import { TimeConverter } from '@dunefront/common/unit-converters/converters/time/time.converter';
import { COMMON_DATE_TIME_SECOND_STRING_FORMAT } from '@dunefront/common/common/constants';
import { ChartDataSeriesStyleHelpers } from '../chart-component-helpers/chart-data-series-style-helpers';
import { ChartDataHelpers } from '../chart-component-helpers/chart-data-helpers';
import { MathHelpers } from '@dunefront/common/common/math-helpers';
import { defaultModalCancelButton, defaultModalConfirmButton, ModalButtonConfig } from '../../modals/generic-modal/generic-modal.component';
import { getIsTimeAxis } from '../chart-component-helpers/chart-misc-helpers';

@Component({
  templateUrl: './chart-axis-props.component.html',
  styleUrls: ['./chart-axis-props.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-chart-axis-props-component',
})
export class ChartAxisPropsComponent {
  public XAxisFormat = XAxisFormat;
  public UnitType = UnitSystem;
  public editAxisProps!: IEditAxisProps;
  public axisProps!: IValidatedAxisProps;

  public isTimeAxis: boolean;

  public timeMin: Date = new Date();
  public timeMax: Date = new Date();

  private chartDataSeriesStyleHelpers = new ChartDataSeriesStyleHelpers();
  private chartDataHelpers = new ChartDataHelpers(this.chartDataSeriesStyleHelpers);
  public modalTitle = 'Axis Properties';

  public modalButtonsConfigs: ModalButtonConfig[] = [
    defaultModalCancelButton(() => this.cancelClicked(), 'Cancel', 'chart-axis-props---cancel-btn'),
    defaultModalConfirmButton(() => this.okClicked(), 'OK', 'chart-axis-props---ok-btn'),
  ];

  public get isLogarithmicDisabled(): boolean {
    return this.axisProps?.xAxisFormat === XAxisFormat.timestamp;
  }

  constructor(
    public activeModal: DynamicDialogRef,
    config: DynamicDialogConfig,
    private cdRef: ChangeDetectorRef,
  ) {
    const editAxisProps: IEditAxisProps = config.data.editAxisProps;

    const axisProps = {
      ...editAxisProps.axisProps,
      title: editAxisProps.axisProps.title ?? editAxisProps.defaultTitle,
    };

    if (axisProps.manualLimit && axisProps.min != null && axisProps.max != null) {
      axisProps.min = UnitConverterHelper.convertFromSi(editAxisProps.axisUnit.unitSystem, editAxisProps.currentUnitSystem, axisProps.min);
      axisProps.max = UnitConverterHelper.convertFromSi(editAxisProps.axisUnit.unitSystem, editAxisProps.currentUnitSystem, axisProps.max);
    }

    this.isTimeAxis = getIsTimeAxis(editAxisProps.axisUnit);
    this.axisProps = this.validateProps(axisProps);
    this.editAxisProps = editAxisProps;

    this.updateTimestampMinMax();
  }

  public get title(): string {
    return this.axisProps.title ?? this.editAxisProps.defaultTitle;
  }

  public onValueChanged(event: ObjectChangeProp<IValidatedAxisProps>): void {
    const axisProps = { ...this.axisProps, [event.key]: event.value };

    if (event.key === 'overrideStyle' && event.value === true) {
      axisProps.style = this.editAxisProps.defaultStyle;
    } else if (event.key === 'manualLimit' && event.value === true) {
      const editAxisProps = this.editAxisProps;
      axisProps.min = editAxisProps.defaultMin;
      axisProps.max = editAxisProps.defaultMax;
    } else if (event.key === 'xAxisFormat' && event.value === XAxisFormat.timestamp) {
      axisProps.isLogarithmic = false;
    }

    this.axisProps = this.validateProps(axisProps);
    this.cdRef.markForCheck();

    this.updateTimestampMinMax();
  }

  public onStyleValueChanged(event: ObjectChangeProp<IAxisStyle>): void {
    if (!this.axisProps.style) {
      return;
    }

    this.axisProps.style = { ...this.axisProps.style, [event.key]: event.value };
  }

  public okClicked(): void {
    this.axisProps = this.validateProps(this.axisProps);
    if (!this.axisProps.isValid) {
      return;
    }
    const editAxisProps = this.editAxisProps;

    if (this.axisProps.manualLimit && this.axisProps.min != null && this.axisProps.max != null) {
      this.axisProps.min = UnitConverterHelper.convertToSi(
        editAxisProps.axisUnit.unitSystem,
        editAxisProps.currentUnitSystem,
        this.axisProps.min,
      );
      this.axisProps.max = UnitConverterHelper.convertToSi(
        editAxisProps.axisUnit.unitSystem,
        editAxisProps.currentUnitSystem,
        this.axisProps.max,
      );
    }

    if (this.axisProps.title === editAxisProps.defaultTitle) {
      this.axisProps.title = null;
    }

    this.activeModal.close(this.axisProps);
  }

  public cancelClicked(): void {
    this.activeModal.close();
  }

  private validateProps(axisProps: IAxisProps): IValidatedAxisProps {
    const error: IError<IAxisProps> = {};

    if (
      axisProps?.manualLimit &&
      axisProps?.min != null &&
      axisProps.max != null &&
      MathHelpers.round(axisProps.min, 2) >= MathHelpers.round(axisProps.max, 2)
    ) {
      error.min = 'Minimum must be lower than Maximum';
    }

    return { ...axisProps, error, isValid: noErrors(error) };
  }

  public onResetTitle(): void {
    this.axisProps = { ...this.axisProps, title: this.editAxisProps.defaultTitle };
    this.cdRef.detectChanges();
  }

  private updateTimestampMinMax(): void {
    // convert min and max to Dates and store it for calendars
    if (this.axisProps.min != null) {
      const date = this.chartDataHelpers.formatScaleTick(
        this.axisProps.min,
        this.axisProps.isLogarithmic,
        2,
        COMMON_DATE_TIME_SECOND_STRING_FORMAT,
        this.editAxisProps.startDate,
        this.editAxisProps.currentUnitSystem.Time,
      );

      this.timeMin = dayjs(date, COMMON_DATE_TIME_SECOND_STRING_FORMAT).toDate();
    }

    if (this.axisProps.max != null) {
      const date = this.chartDataHelpers.formatScaleTick(
        this.axisProps.max,
        this.axisProps.isLogarithmic,
        2,
        COMMON_DATE_TIME_SECOND_STRING_FORMAT,
        this.editAxisProps.startDate,
        this.editAxisProps.currentUnitSystem.Time,
      );

      this.timeMax = dayjs(date, COMMON_DATE_TIME_SECOND_STRING_FORMAT).toDate();
    }
  }

  public updateMinMax(): void {
    // parse Dates to seconds, then to selected time units, and get delta
    const startTimeSi = this.editAxisProps.startDate;

    if (startTimeSi == null) {
      return;
    }

    const currentTimeUnit = this.editAxisProps.currentUnitSystem.Time;

    const minTimeSi = this.timeMin.getTime() / 1000;
    const maxTimeSi = this.timeMax.getTime() / 1000;
    //const startTimeSi = TimeConverter.toSi(startDate, currentTimeUnit);

    const deltaMin = TimeConverter.fromSi(minTimeSi - startTimeSi, currentTimeUnit);
    const deltaMax = TimeConverter.fromSi(maxTimeSi - startTimeSi, currentTimeUnit);

    this.axisProps.min = deltaMin;
    this.axisProps.max = deltaMax;

    this.axisProps = this.validateProps(this.axisProps);
  }
}

export interface IValidatedAxisProps extends IAxisProps, IValidatedDataType {
  error: IError<IAxisProps>;
}
