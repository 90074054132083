import { RangeConstants, RangeDto } from '../../../dto/range.dto';
import { LoadRangesActionResponse } from '../range-module.actions';
import { RangeModuleState } from '../range-module.state';
import { DictionaryWithArray } from '../../../common/state.helpers';
import { ScenarioRangePropertiesDto } from '../../../dto/scenario-range-properties.dto';

export class RangeFactory {
  public static create(payload: LoadRangesActionResponse, rangeId: number): RangeModuleState {
    const rangesState: RangeModuleState = {
      ranges: DictionaryWithArray.create([RangeFactory.DefaultRange, ...(payload.ranges ?? [])], 'Id'),
      scenarioRangeProperties: DictionaryWithArray.create(payload.scenarioRangeProperties ?? [], 'RangeId'),
      verticalShifts: DictionaryWithArray.create([...(payload.verticalShifts ?? [])], 'Id'),
      isLoaded: true,
      editingDto: undefined,
      currentRangeId: rangeId,
    };
    return rangesState;
  }

  public static DefaultRange: RangeDto = {
    Id: 0,
    ScenarioId: RangeConstants.EntireRangeId,
    Name: 'Entire Range',
    RangeStart: 0,
    RangeEnd: 0,
    SortOrder: 0,
    Labels: '',
  };

  public static newRange(): RangeDto {
    return { ...RangeFactory.DefaultRange, Id: RangeConstants.NewRangeId, Name: '' };
  }

  public static newScenarioRangeProperties(scenarioId: number, rangeId: number): ScenarioRangePropertiesDto {
    return {
      Id: -1,
      RangeId: rangeId,
      ScenarioId: scenarioId,
      TrendAnalysisToolPosition: 0,
      TrendAnalysisPumpingDirection: 0,
      TrendAnalysisXOverMD: 0,
      TrendAnalysisWashpipeMD: 0,
      PumpRateColumnName: '',
      PumpPressureColumnName: '',
      ReturnRateColumnName: '',
      GravelConcColumnName: '',
      SortOrder: 0,
    };
  }
}
