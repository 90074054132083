import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ValidatedWellModuleState } from '@dunefront/common/modules/well/well-module.state';
import { BasicPropertyChangeComponent } from '../../../../../common-modules/db-connection/basic-property-change.component';
import { IRadioItem } from '@dunefront/common/common/radio.helpers';

@Component({
  selector: 'app-loss-definition',
  templateUrl: './loss-definition.component.html',
  styleUrls: ['./loss-definition.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LossDefinitionComponent extends BasicPropertyChangeComponent<ValidatedWellModuleState> {
  @Input() public state!: ValidatedWellModuleState;
  public specifyLossRadioItems: IRadioItem<boolean>[] = [
    { text: 'Specify Losses', value: false },
    { text: 'Calculate Losses', value: true },
  ];
}
