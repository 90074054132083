<div
  [class.highlight]="highlight"
  [ngClass]="cssOverride"
  [attr.data-cy]="dataCy"
  [class.error]="isInErrorState"
  [tooltipStyleClass]="tooltipStyle"
  [tooltipDisabled]="!tooltipMessage"
  [pTooltip]="tooltipMessage + ''"
  tooltipPosition="top"
  positionStyle="absolute"
  tooltipStyleClass="error-tooltip"
>
  <ng-container *ngFor="let item of items; index as i">
    <div class="custom-control custom-radio" [class.custom-control-inline]="layout === 'horizontal'">
      <input
        type="radio"
        id="{{ 'radio-' + id + item.text }}"
        name="{{ 'radio-' + id + item.text }}"
        class="custom-control-input"
        [ngModel]="value"
        [attr.data-cy]="dataCy + '_' + item.value"
        [value]="item.value"
        [disabled]="isRadioItemDisabled(item)"
        (change)="onChange(item.value)"
      />
      <label class="custom-control-label" for="{{ 'radio-' + id + item.text }}">{{ item.text }}</label>
    </div>
    <br *ngIf="isBreak(i)" />
  </ng-container>
</div>
