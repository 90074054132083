import { Chart } from 'chart.js';
import { DrawingGradientLine, GradientLinePlugin, GradientLinePluginOptions } from './types';
import { drawGradientLines } from './drawing';
import { getState, removeState } from './state';
import { gradientLineToDrawingGradientLine, isOverGradientLine } from './helpers';
import { addHandlers, removeHandlers, updateHoverProps } from './handlers';
import { getPointFromEvent } from '../plugins-common-helpers';
import { v4 as uuid } from 'uuid';

export const GradientLinePluginId = 'GradientLinePluginId';

export const gradientLinePlugin: GradientLinePlugin = {
  id: GradientLinePluginId,

  afterInit(chart: Chart) {
    addHandlers(chart);

    const state = getState(chart);
    state.uuid = uuid();
  },

  beforeUpdate(chart: Chart): boolean | void {
    const state = getState(chart);

    const pluginOptions: GradientLinePluginOptions = (chart.options.plugins as any)?.[GradientLinePluginId];
    if (pluginOptions != null) {
      state.options = { ...pluginOptions };
    }
  },

  afterUpdate(chart: Chart) {
    const state = getState(chart);
    state.lines = state.options.lines
      .map((line) => gradientLineToDrawingGradientLine(line, chart))
      .filter((item): item is DrawingGradientLine => item != null);

    const { prevPoint } = state;

    if (prevPoint != null) {
      updateHoverProps(chart, prevPoint);
    }
  },

  afterDraw(chart: Chart) {
    drawGradientLines(chart);
  },

  beforeEvent(chart: Chart): boolean | void {
    const state = getState(chart);
    if (state.options.mode === 'inactive') {
      return true;
    }
  },

  afterDestroy(chart: Chart) {
    removeHandlers(chart);
    removeState(chart);
  },

  // endregion

  // region Gradient Line Plugin specific methods

  willPluginHandleDoubleClick(chart: Chart, event: MouseEvent): boolean {
    const state = getState(chart);
    if (state.options.mode !== 'edit') {
      return false;
    }

    const point = getPointFromEvent(event);

    for (const line of state.lines) {
      if (isOverGradientLine(point, line)) {
        return true;
      }
    }

    return false;
  },

  getSelectedGradientLineId(chart: Chart): number | null {
    const state = getState(chart);
    return state.highlightedGradientLineId ?? null;
  },

  // endregion
};
