<ng-container *ngIf="chartMenuProps$ | async as chartMenuProps">
  <app-ribbon-menu-group groupName="Reporting" (click)="changeHelp('toolbars', 'charts-reporting')">
    <div class="ribbon-menu-button-group">
      <app-button
        class="big-button"
        text="Add Chart Tab"
        [isUiLockable]="false"
        textIcon="icon-chart-add-plus-square"
        dataCy="reporting-new-chart-btn"
        (buttonClick)="newChartClicked(chartMenuProps.newChartProps)"
        [disabled]="!chartMenuProps.newChartEnabled"
      ></app-button>
      <app-button
        class="big-button"
        text="Copy Chart Tab"
        [isUiLockable]="false"
        textIcon="icon-charts-copy"
        dataCy="reporting-copy-chart-btn"
        (buttonClick)="copyChartTabClicked()"
        [disabled]="!chartMenuProps.copyChartTabEnabled"
      ></app-button>
      <app-button
        class="big-button"
        text="Edit Chart Tab"
        [isUiLockable]="false"
        textIcon="icon-chart-analytics-edit-pen-square"
        dataCy="reporting-edit-chart-btn"
        (buttonClick)="editChartClicked()"
        [disabled]="!chartMenuProps.editChartEnabled"
      ></app-button>
      <app-button
        class="big-button"
        text="Delete Chart Tab"
        [isUiLockable]="false"
        textIcon="icon-chart-analytics-cross-delete-square"
        dataCy="reporting-delete-chart-btn"
        (buttonClick)="deleteChartClicked()"
        [disabled]="!chartMenuProps.deleteChartEnabled"
      ></app-button>
    </div>
  </app-ribbon-menu-group>
  <hr />
  <app-ribbon-menu-group groupName="Annotations" (click)="changeHelp('toolbars', 'charts-annotations')">
    <div class="ribbon-menu-button-group">
      <app-button
        class="big-button"
        [isToggled]="chartMenuProps.chartMode === ChartMode.annotate"
        text="Annotate Chart"
        [isUiLockable]="false"
        textIcon="icon-annotation"
        dataCy="reporting-tools-annotate-chart-btn"
        (buttonClick)="setChartMode(ChartMode.annotate)"
        [disabled]="!chartMenuProps.annotateChartEnabled"
      ></app-button>
      <app-button
        class="big-button"
        [isToggled]="chartMenuProps.chartMode === ChartMode.editVerticalMarker"
        text="Vertical Mark"
        [iconTransformation]="'scale(1,-1) rotate(90deg)'"
        [isUiLockable]="false"
        textIcon="icon-Pencil"
        dataCy="reporting-tools-vertical-mark-chart-btn"
        (buttonClick)="setChartMode(ChartMode.editVerticalMarker)"
        [disabled]="!chartMenuProps.markChartEnabled"
      ></app-button>
      <app-button
        class="big-button"
        [isToggled]="chartMenuProps.chartMode === ChartMode.editHorizontalMarker"
        text="Horizontal Mark"
        [isUiLockable]="false"
        textIcon="icon-Pencil"
        dataCy="reporting-tools-horizontal-mark-chart-btn"
        (buttonClick)="setChartMode(ChartMode.editHorizontalMarker)"
        [disabled]="!chartMenuProps.markChartEnabled"
      ></app-button>
      <app-button
        class="big-button"
        [isToggled]="chartMenuProps.chartMode === ChartMode.editGradientLine"
        text="Slope Line"
        [isUiLockable]="false"
        textIcon="icon-gradient-line"
        dataCy="reporting-tools-gradient-line-chart-btn"
        (buttonClick)="setChartMode(ChartMode.editGradientLine)"
        [disabled]="!chartMenuProps.gradientLineEnabled"
      ></app-button>
    </div>
  </app-ribbon-menu-group>
  <hr />
  <ng-container *ngIf="chartMenuProps.isCompareScenariosVisible || chartMenuProps.isOptimizeChartVisible">
    <app-ribbon-menu-group groupName="Comparisons" (click)="changeHelp('toolbars', 'charts-comparison')">
      <div class="ribbon-menu-button-group">
        <app-compare-scenario-button *ngIf="chartMenuProps.isCompareScenariosVisible"
                                     [chartMenuProps]="chartMenuProps"></app-compare-scenario-button>
        <app-optimize-button *ngIf="chartMenuProps.isOptimizeChartVisible"
                             [chartMenuProps]="chartMenuProps"></app-optimize-button>
      </div>
    </app-ribbon-menu-group>
    <hr />
  </ng-container>
  <app-ribbon-menu-group groupName="Copy" (click)="changeHelp('toolbars', 'charts-copy')">
    <div class="ribbon-menu-button-group">
      <app-button
        class="big-button"
        text="Copy Image"
        [isUiLockable]="false"
        textIcon="icon-images-picture-copy"
        subIcon="sub-icon-chart"
        (buttonClick)="copyChartClicked()"
        [disabled]="!chartMenuProps.isCopyChartEnabled"
        dataCy="chart-menu-copy-image-btn"
      ></app-button>
      <app-button
        class="big-button"
        text="Copy Data"
        [isUiLockable]="false"
        textIcon="icon-documet-copy"
        subIcon="sub-icon-data"
        (buttonClick)="copyDataClicked()"
        [disabled]="!chartMenuProps.isCopyDataEnabled"
        dataCy="chart-menu-copy-data-btn"
      ></app-button>
    </div>
  </app-ribbon-menu-group>
</ng-container>
