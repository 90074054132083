import { createSelector } from '@ngrx/store';
import { ColumnType, IGridColumnConfig } from '../../shared/components/grid/grid.interfaces';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { TemperatureProfileType, ZonePressureType } from '@dunefront/common/modules/well/dto/well.dto';
import { ZoneModel } from '@dunefront/common/modules/well/model/zone/zone.model';
import { Survey } from '@dunefront/common/modules/well/model/survey/survey';
import { getValidatedWellModuleState } from './validated-well.selectors';

export const getSurveyGridConfig = createSelector(getValidatedWellModuleState, (state): IGridColumnConfig<Survey>[] => {
  const showTempCols = state.TemperatureProfile.TemperatureProfileType === TemperatureProfileType.Detailed_Calc;
  return [
    { disabled: false, visible: true, colId: ' ', type: ColumnType.selection },
    {
      disabled: false,
      visible: true,
      colId: 'MD',
      type: ColumnType.number,
      unitSystem: UnitSystem.Long_Length,
      matchingStrings: ['md', 'depth'],
    },
    {
      disabled: false,
      visible: true,
      colId: 'Deviation',
      type: ColumnType.number,
      unitSystem: UnitSystem.Angle,
      matchingStrings: ['deviation'],
    },
    {
      disabled: false,
      visible: true,
      colId: 'Azimuth',
      type: ColumnType.number,
      unitSystem: UnitSystem.Angle,
      matchingStrings: ['azimuth'],
    },
    { disabled: true, visible: true, colId: 'TVD', type: ColumnType.number, unitSystem: UnitSystem.Long_Length },
    {
      disabled: false,
      visible: showTempCols,
      colId: 'DownPathTemp',
      type: ColumnType.number,
      unitSystem: UnitSystem.Temperature,
      headerText: 'Down Path Temp.',
      width: 75,
      matchingStrings: ['down'],
    },
    {
      disabled: false,
      visible: showTempCols,
      colId: 'UpPathTemp',
      type: ColumnType.number,
      unitSystem: UnitSystem.Temperature,
      headerText: 'Up Path Temp.',
      width: 75,
      matchingStrings: ['up'],
    },
  ];
});

export const getReservoirGridConfig = createSelector(getValidatedWellModuleState, (state): IGridColumnConfig<ZoneModel>[] => {
  const isGradientPressureVisible = state.ZonePressureType === ZonePressureType.Pressure_Gradient;
  const isPressureVisible = state.ZonePressureType === ZonePressureType.Pressure;
  const isEMWPressureVisible = !isPressureVisible && !isGradientPressureVisible;
  return [
    { disabled: false, visible: true, colId: ' ', type: ColumnType.selection, unitSystem: UnitSystem.None },
    {
      disabled: false,
      visible: true,
      colId: 'Name',
      type: ColumnType.text,
      headerText: 'Zone Name',
      reportingHeaderName: 'Name',
      placeholder: 'Enter Name',
      align: 'left',
      unitSystem: UnitSystem.None,
      isNotUiLockable: true,
      matchingStrings: ['zone', 'name', 'reservoir'],
    },
    {
      disabled: false,
      visible: state.IsZoneDepthByMD,
      colId: 'TopMD',
      type: ColumnType.number,
      unitSystem: UnitSystem.Long_Length,
      headerText: 'Top MD',
      matchingStrings: ['top md'],
    },
    {
      disabled: false,
      visible: state.IsZoneDepthByMD,
      colId: 'BottomMD',
      type: ColumnType.number,
      unitSystem: UnitSystem.Long_Length,
      headerText: 'Bottom MD',
      matchingStrings: ['bottom md'],
    },
    {
      disabled: false,
      visible: !state.IsZoneDepthByMD,
      colId: 'TopTVD',
      type: ColumnType.number,
      unitSystem: UnitSystem.Long_Length,
      headerText: 'Top TVD',
      matchingStrings: ['top tvd'],
    },
    {
      disabled: false,
      visible: !state.IsZoneDepthByMD,
      colId: 'BottomTVD',
      type: ColumnType.number,
      unitSystem: UnitSystem.Long_Length,
      headerText: 'Bottom TVD',
      matchingStrings: ['bottom tvd'],
    },
    {
      disabled: true,
      visible: state.IsZoneDepthByMD,
      colId: 'Length',
      type: ColumnType.number,
      unitSystem: UnitSystem.Long_Length,
      headerText: 'Length',
    },
    {
      disabled: true,
      visible: !state.IsZoneDepthByMD,
      colId: 'Height',
      type: ColumnType.number,
      unitSystem: UnitSystem.Long_Length,
      headerText: 'Height',
    },
    {
      disabled: false,
      visible: isGradientPressureVisible,
      colId: 'ReservoirPressureGradient',
      type: ColumnType.number,
      headerText: 'Pore Pressure Gradient',
      reportingHeaderName: 'PP Gradient',
      unitSystem: UnitSystem.Pressure_Gradient,
      decimalPlaces: 3,
      matchingStrings: ['pore pressure gradient'],
    },
    {
      disabled: false,
      visible: isGradientPressureVisible,
      colId: 'FracturePressureGradient',
      type: ColumnType.number,
      headerText: 'Frac Pressure Gradient',
      reportingHeaderName: 'FP Gradient',
      unitSystem: UnitSystem.Pressure_Gradient,
      decimalPlaces: 3,
      matchingStrings: ['frac pressure gradient'],
    },
    {
      disabled: false,
      visible: isPressureVisible,
      colId: 'ReservoirPressure',
      type: ColumnType.number,
      headerText: 'Pore Pressure at Mid TVD',
      reportingHeaderName: 'PP at Mid TVD',
      unitSystem: UnitSystem.Pressure,
      decimalPlaces: 3,
      matchingStrings: ['pore pressure at mid tvd'],
    },
    {
      disabled: false,
      visible: isPressureVisible,
      colId: 'FracturePressure',
      type: ColumnType.number,
      headerText: 'Frac Pressure at Mid TVD',
      reportingHeaderName: 'FP at Mid TVD',
      unitSystem: UnitSystem.Pressure,
      decimalPlaces: 3,
      matchingStrings: ['frac pressure at mid tvd'],
    },
    {
      disabled: false,
      visible: isEMWPressureVisible,
      colId: 'ReservoirPressureEMW',
      type: ColumnType.number,
      headerText: 'Pore Pressure EMW',
      reportingHeaderName: 'PP EMW',
      unitSystem: UnitSystem.Liquid_Density,
      decimalPlaces: 3,
      matchingStrings: ['pore pressure emw'],
    },
    {
      disabled: false,
      visible: isEMWPressureVisible,
      colId: 'FracturePressureEMW',
      type: ColumnType.number,
      headerText: 'Frac Pressure EMW',
      reportingHeaderName: 'FP EMW',
      unitSystem: UnitSystem.Liquid_Density,
      decimalPlaces: 3,
      matchingStrings: ['frac pressure emw'],
    },
  ];
});
