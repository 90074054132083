import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { changeProp, ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';
import { Gravel } from '@dunefront/common/modules/gravel/model/gravel';
import * as actions from '../../../../+store/gravel/gravel.actions';
import { StoreCrudPropsFactory } from '@dunefront/common/common/common-store-crud.interfaces';
import { notEmpty } from '@dunefront/common/common/state.helpers';
import { getSelectedGravelState } from '../../../../+store/gravel/gravel.selectors';
import { DbDependentComponent } from '../../../../common-modules/db-connection/db-dependent.component';

@Component({
  selector: 'app-gravel-advanced-properties',
  templateUrl: 'gravel-advanced-properties.component.html',
  styleUrls: ['gravel-advanced-properties.components.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GravelAdvancedPropertiesComponent extends DbDependentComponent {
  public gravel: Gravel | undefined;
  public UnitType = UnitSystem;

  constructor(store: Store, cdRef: ChangeDetectorRef) {
    super(store, cdRef);
    this.onHelpChange('gravels', 'gravel-advanced-properties');

    this.subscription.add(
      notEmpty(this.store.select(getSelectedGravelState)).subscribe((gravel) => {
        this.gravel = gravel;
        this.cdRef.markForCheck();
      }),
    );
  }

  public onValueChanged(prop: ObjectChangeProp<Gravel>): void {
    if (!this.gravel) {
      return;
    }
    const gravel = changeProp(this.gravel, prop);
    this.store.dispatch(actions.updateGravel(StoreCrudPropsFactory.updateRow(gravel, prop)));
  }
}
