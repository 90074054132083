import { BasePptSectionGenerator, PptContext } from '../base-ppt-section-generator';
import { IUnitSystemDto } from '@dunefront/common/dto/unit-system.dto';
import { firstValueFrom } from 'rxjs';
import { selectCurrentUnitSystem } from '../../../../../../+store/units/units.selectors';
import { CommonReportDataHelpers } from '../../common/common-report-data-helpers';
import { Pumping } from '@dunefront/common/modules/pumping/model/pumping/pumping';
import { getValidatedPumping } from '../../../../../../+store/pumping/selectors/pumping-validation.selectors';
import PptxGenJS from 'pptxgenjs';
import { PptDocumentTableGeneratorHelper } from '../ppt-document-table-generator.helper';
import TableRow = PptxGenJS.TableRow;

export class PumpingInitialDataPptGenerator extends BasePptSectionGenerator {
  constructor(context: PptContext) {
    super(context);
  }

  public async generate(rangeName?: string): Promise<void> {
    const title = 'Pumping - Initial Data';
    const subTitle = rangeName;

    const pumpingInitialState: Pumping = await firstValueFrom(this.store.select(getValidatedPumping));
    const currentUnitSystem: IUnitSystemDto = await firstValueFrom(this.store.select(selectCurrentUnitSystem));

    const sections = CommonReportDataHelpers.createPumpingInitialDataTableData(pumpingInitialState, currentUnitSystem);
    const tableRowsGroups: TableRow[][] = [];
    for (const section of sections) {
      tableRowsGroups.push(PptDocumentTableGeneratorHelper.createGenericKeyValueTableRowsWithTableData(section, currentUnitSystem));
    }

    this.addTwoColumnsKeyValueTableSlide({ title, subTitle, tableRowsGroups });
  }
}
