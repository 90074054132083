// noinspection JSUnusedGlobalSymbols

import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Action, createSelector, Store } from '@ngrx/store';
import * as actions from './reporting.actions';
import {
  calculationProgressUpdatedCurrentScenarioAction,
  ChartAxisPropertiesActionProps,
  chartTemplateAlreadyExistsAction,
  checkDepthDataAction,
  closeEditChartTemplateModalAction,
  createChartTemplate,
  deleteChartTemplatesAction,
  getChartSeriesTemplatesSuccess,
  isCompareScenarioActiveAction,
  isOptimizeActiveAction,
  reloadChartAfterXAxisShiftUndoRedoAction,
  updateChartAction,
  updateChartTemplateAction,
  updateXAxisShiftSuccessAction,
} from './reporting.actions';
import * as uiActions from '../ui/ui.actions';
import { chartAutoSizeAction, chartResetZoomAction, ResetZoomMode } from '../ui/ui.actions';
import * as calcActions from '../calculation-engine/calculation-engine.actions';
import { dataFailed, deleteRowsSuccess, insertRowsSuccess, updateRowSuccess } from '../app.actions';
import { catchError, exhaustMap, filter, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { firstValueFrom, of } from 'rxjs';
import {
  ChartTemplateLoadAction,
  ChartTemplateSaveAction,
  ChartTemplatesDeleteAction,
  DeleteChartAnnotationAction,
  DeleteChartGradientLineAction,
  DeleteChartMarkerAction,
  DeleteChartSeriesTemplateAction,
  ExportDataConvertUnitsActionPayload,
  GetChartSeriesTemplatesAction,
  GetChartUserAddonsAction,
  GetChartUserAddonsResultsActionResponse,
  GetDepthDataForSinglePointAction,
  GetDepthDataResultsAction,
  GetDepthDataResultsActionResponse,
  GetTimeBasedChartDataAction,
  IDepthBasedResult,
  InsertChartAnnotationAction,
  InsertChartGradientLineAction,
  InsertChartMarkerAction,
  InsertChartSeriesTemplateAction,
  LoadTabIdForScenarioChangeAction,
  ReportingModuleConvertExportDataUnitsAction,
  ReportingModuleConvertExportDataUnitsActionResponse,
  ReportingModuleName,
  ReportingModuleStartExportDataJobAction,
  ReportingModuleUpdateFilesAction,
  ResultsSourceKey,
  TimeVolChartDataResponse,
  UpdateChartAction,
  UpdateChartAnnotationAction,
  UpdateChartAxisPropertyAction,
  UpdateChartAxisSelectionAction,
  UpdateChartGradientLineAction,
  UpdateChartMarkerAction,
  UpdateChartSeriesTemplateAction,
  UpsertChartAxisPropertyAction,
} from '@dunefront/common/modules/reporting/reporting-module.actions';
import { BackendConnectionService } from '../../shared/backend-connection/backend-connection.service';
import { BaseWsEffects } from '../base-ws.effects';
import { CrudResponse } from '@dunefront/common/modules/common.actions';
import { ReportingFactory } from './model/reporting.factory';
import {
  getCalculationChartTimeVolMode,
  getChartById,
  getChartDtos,
  getChartUserAddonsDict,
  getCurrentlyVisibleChartCompareScenarioIds,
  getCurrentlyVisibleChartDtos,
  getCurrentlyVisibleChartIds,
  getCurrentRangeBasedChartsAxisProperties,
  getReportingDataState,
  getResultsSourceKey,
  getSelectedReportingTab,
  getSortedReportingTabsForCurrentRange,
} from './reporting.selectors';
import { Router } from '@angular/router';
import { ModalService } from '../../common-modules/modals/modal.service';
import { DictionaryWithArray, IDictionaryWithArray, isDefined } from '@dunefront/common/common/state.helpers';
import { DepthDataStatus, undefinedChartId } from '@dunefront/common/modules/reporting/dto/chart-data.dto';
import { getJobForCurrentContext } from '../calculation-engine/calculation-engine.selectors';
import { getAllScenarios, getCurrentScenarioId, getScenarioState } from '../scenario/scenario.selectors';
import { redirectToGaugeDataAction } from '../import-data/import-data.actions';
import { selectUserGlobalOptions } from '../common-db/common-db.selectors';
import { ChartDataSourceType, ChartDto } from '@dunefront/common/modules/reporting/dto/chart.dto';
import { ReportingModuleState } from './reporting-module.state';
import {
  getStorageColumns,
  getStorageFiles,
  getStorageFilesArray,
  getValidatedStorageFilesWithColumns,
} from '../data-storage/data-storage.selectors';
import { getCurrentRangeId } from '../range/range.selectors';
import { getCurrentAppModuleType } from '../ui/ui.selectors';
import { RangeConstants } from '@dunefront/common/dto/range.dto';
import { CalculationStatus, ICalculationJob } from '../calculation-engine/calculation-engine-module.state';
import { getLowerCompletionRange } from '../completion/validated-completion.selectors';
import { IChartTemplateDto } from '@dunefront/common/dto/chart-templates.dto';
import { selectCurrentUnitSystem } from '../units/units.selectors';
import { FileManagerHelper } from '../file-manager/file-manager.helper';
import { IFile } from '@dunefront/common/dto/file.dto';
import { ENVIRONMENT } from '../../shared/services/environment';
import { ChartSeriesTemplateDto } from '@dunefront/common/dto/chart-series-template.dto';
import { electronSaveCSVAsFileAction } from '../electron-main/electron-main.actions';
import { ChartAxisPropertyDto, XAxisFormat } from '@dunefront/common/modules/reporting/dto/chart-axis-property.dto';
import { ChartLegendLocation, defaultLegendFontSize } from '@dunefront/common/modules/reporting/dto/chart-legend';
import { IAxisProps } from '../../common-modules/chart/chart-component-helpers/chart-types';
import { IEnvironment } from '@dunefront/common/common/environment.interface';
import { AppTargetConfig } from '../../shared/services/app-target-config';
import { JobidHelper } from '@dunefront/common/common/jobid-helper';
import { saveAs } from 'file-saver';
import { HttpClient } from '@angular/common/http';
import { ImportFileDto } from '@dunefront/common/modules/data-storage/dto/import-file.dto';
import { IScenarioIdBasedEntity } from '@dunefront/common/dto/common-dto.interfaces';
import { WsActionPropsFactory } from '@dunefront/common/common/ws-action/ws-action-props.factory';
import { getValidatedDeveloperSettings } from '../settings/validated-settings.selectors';
import { getDepthDataPointsToLoad } from './reporting-get-depth-chart-state.selector';
import { RouterHelperService } from '../../shared/services/router-helper.service';
import { TemplatesLoadActionResponse } from '@dunefront/common/common/templates/templates.interfaces';
import { ClientAuthService } from '../../common-modules/auth/client-auth.service';
import { ScenarioConstants } from '@dunefront/common/modules/scenario/scenario.dto';
import { IValidatedStorageFileWithColumns } from '@dunefront/common/modules/data-storage/data-storage.validation';
import { DataFileType } from '@dunefront/common/dto/data-storage';
import { changePropValue, ErrorHelper } from '@dunefront/common/common/common-state.interfaces';
import { isElectron } from '@dunefront/common/common/electron/is-electron';
import { updateLastUsedScenarioId } from '../file-settings/file-settings.actions';
import { CalculationEngineMessageType } from '@dunefront/common/modules/calculation-engine/calculation-engine.interfaces';

@Injectable()
export class ReportingEffects extends BaseWsEffects {
  public xAxisShiftUndoRedo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateRowSuccess),
      filter((action) => action.undoOrRedo != null && action.affectedRows.importFiles != null),
      map(() => reloadChartAfterXAxisShiftUndoRedoAction()),
    ),
  );
  // region chart addons
  public getChartUserAddons$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.getChartUserAddons),
      mergeMap((action) =>
        this.emit<GetChartUserAddonsResultsActionResponse>(new GetChartUserAddonsAction(action.scenarioId, action.chartId)).pipe(
          map((result) => actions.getChartUserAddonsSuccess(result.payload)),
          catchError((err) => of(dataFailed(err))),
        ),
      ),
    ),
  );

  // region chart marker
  public insertChartMarker$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.insertChartMarker),
      map((action) => ReportingFactory.toChartMarkerDto(action)),
      concatLatestFrom((markerDto) => this.store.select(getChartById(markerDto.ChartId))),
      map(([markerDto, chartDtos]) => this.copyChartScenarioIdToEntity(markerDto, chartDtos)),
      mergeMap((markerDto) => this.emitInsert(new InsertChartMarkerAction([markerDto]))),
    ),
  );

  public updateChartMarker$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.updateChartMarker),
      map((action) => ReportingFactory.toChartMarkerDto(action)),
      concatLatestFrom((markerDto) => this.store.select(getChartById(markerDto.ChartId))),
      map(([markerDto, chartDto]) => this.copyChartScenarioIdToEntity(markerDto, chartDto)),
      mergeMap((markerDto) => this.emitUpdate(new UpdateChartMarkerAction(markerDto))),
    ),
  );

  public deleteChartMarker$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.deleteChartMarker),
      mergeMap((action) => this.emitDelete(new DeleteChartMarkerAction(action))),
    ),
  );

  // region chart annotation
  public insertChartAnnotation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.insertChartAnnotation),
      map((action) => ReportingFactory.toAnnotationDto(action)),
      concatLatestFrom((annotationDto) => this.store.select(getChartById(annotationDto.ChartId))),
      map(([annotationDto, chartDto]) => this.copyChartScenarioIdToEntity(annotationDto, chartDto)),
      mergeMap((annotationDto) => this.emitInsert(new InsertChartAnnotationAction([annotationDto]))),
    ),
  );

  public updateChartAnnotation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.updateChartAnnotation),
      map((action) => action.annotations.map((a) => ReportingFactory.toAnnotationDto(a))),
      concatLatestFrom(() => this.store.select(getChartDtos)),
      map(([annotationDtos, chartDtos]) => {
        for (const annotation of annotationDtos) {
          const chartDto = chartDtos.find((c) => c.Id === annotation.ChartId);
          if (chartDto == null) {
            throw new Error(`Can't find chart dto with id: ${annotation.ChartId}`);
          }

          this.copyChartScenarioIdToEntity(annotation, chartDto);
        }

        return annotationDtos;
      }),
      mergeMap((annotationDtos) => this.emitUpdate(new UpdateChartAnnotationAction(annotationDtos))),
    ),
  );

  public deleteChartAnnotation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.deleteChartAnnotation),
      mergeMap((action) => this.emitDelete(new DeleteChartAnnotationAction(action))),
    ),
  );

  // region chart annotation
  public insertChartGradientLine$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.insertChartGradientLine),
      map((action) => ReportingFactory.toGradientLineDto(action)),
      concatLatestFrom((line) => this.store.select(getChartById(line.ChartId))),
      map(([line, chartDto]) => this.copyChartScenarioIdToEntity(line, chartDto)),
      mergeMap((line) => this.emitInsert(new InsertChartGradientLineAction([line]))),
    ),
  );

  public updateChartGradientLine$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.updateChartGradientLines),
      map((action) => action.lines.map((a) => ReportingFactory.toGradientLineDto(a))),
      concatLatestFrom(() => this.store.select(getChartDtos)),
      map(([lineDtos, chartDtos]) => {
        for (const line of lineDtos) {
          const chartDto = chartDtos.find((c) => c.Id === line.ChartId);
          if (chartDto == null) {
            throw new Error(`Can't find chart dto with id: ${line.ChartId}`);
          }

          this.copyChartScenarioIdToEntity(line, chartDto);
        }

        return lineDtos;
      }),
      mergeMap((lineDtos) => this.emitUpdate(new UpdateChartGradientLineAction(lineDtos))),
    ),
  );

  public deleteChartGradientLine$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.deleteChartGradientLine),
      mergeMap((action) => this.emitDelete(new DeleteChartGradientLineAction(action))),
    ),
  );

  // region chart axis config
  public insertChartAxisProperty$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.insertChartAxisProperty),
      concatLatestFrom(() => this.store.select(getChartDtos)),
      map(([action, chartDtos]) => this.axisPropsActionToDtos(action, chartDtos)),
      concatLatestFrom(() => this.store.select(getResultsSourceKey)),
      mergeMap(([{ axisPropertyDtos, secondaryArg }, resultsSourceKey]) =>
        this.emitUpsert(new UpsertChartAxisPropertyAction(axisPropertyDtos, { secondaryArg, resultsSourceKey })),
      ),
    ),
  );

  public updateChartAxisProperty$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.updateChartAxisProperties),
      concatLatestFrom(() => this.store.select(getChartDtos)),
      map(([action, chartDtos]) => this.axisPropsActionToDtos(action, chartDtos)),
      concatLatestFrom(() => this.store.select(getResultsSourceKey)),
      mergeMap(([{ axisPropertyDtos, secondaryArg }, resultsSourceKey]) =>
        this.emitUpdate(new UpdateChartAxisPropertyAction(axisPropertyDtos, { secondaryArg, resultsSourceKey })),
      ),
    ),
  );

  public clearCustomizations$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.clearCustomizations),
        concatLatestFrom((action) => [this.store.select(getChartUserAddonsDict), this.store.select(getChartById(action.chartId))]),
        tap(([action, addons, chart]) => {
          const chartAxisProperties = DictionaryWithArray.get(addons, action.chartId)?.chartAxisProperties || [];
          if (chartAxisProperties.length) {
            const axisProps = chartAxisProperties.map((prop) => this.clearChartAxisPropsStyles(prop));
            this.store.dispatch(actions.updateChartAxisProperties({ axisProps, secondaryArg: false }));
          }

          if (chart) {
            const updatedChart = this.clearChartStyles(chart);
            this.store.dispatch(updateChartAction([updatedChart]));
          }
        }),
      ),
    { dispatch: false },
  );

  public chartAutoSizeAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(chartAutoSizeAction),
      concatLatestFrom(() => [this.store.select(getCurrentlyVisibleChartIds), this.store.select(getChartUserAddonsDict)]),
      tap(([, visibleChartIds, addons]) => {
        for (const chartId of visibleChartIds) {
          const chartAxisProperties = DictionaryWithArray.get(addons, chartId)?.chartAxisProperties || [];
          if (chartAxisProperties.length) {
            const axisProps = chartAxisProperties.map((prop) => ({ ...prop, manualLimit: false }));
            this.store.dispatch(actions.updateChartAxisProperties({ axisProps, secondaryArg: false }));
          }
        }
      }),
      map(() => chartResetZoomAction({ mode: ResetZoomMode.CHART })),
    ),
  );

  public resetChartAxisLimitsProperties$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.resetChartAxisLimitsProperties),
        concatLatestFrom(() => this.store.select(getCurrentRangeBasedChartsAxisProperties).pipe(filter((x) => x != null))),
        tap(([, chartAxisProperties]) => {
          const axisPropsToUpdate = chartAxisProperties
            .filter((axisProps) => axisProps.manualLimit)
            .map((axisProps) => ({ ...axisProps, manualLimit: false }));

          if (axisPropsToUpdate.length > 0) {
            this.store.dispatch(
              actions.updateChartAxisProperties({
                axisProps: axisPropsToUpdate,
                secondaryArg: false,
              }),
            );
          }
        }),
      ),
    { dispatch: false },
  );
  // region X Axis shift
  public updateXAxisShift$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.updateXAxisShiftAction),
      concatLatestFrom(() => this.store.select(getStorageFiles)),
      mergeMap(([{ xAxisShiftUpdates }, storageFiles]) => {
        const updatedFiles: ImportFileDto[] = [];

        for (const { FileId, XAxisShift } of xAxisShiftUpdates) {
          const file = DictionaryWithArray.get(storageFiles, FileId);
          if (!file) {
            throw new Error('Missing file');
          }

          updatedFiles.push({ ...file, XAxisShift });
        }

        return this.emit<CrudResponse>(
          new ReportingModuleUpdateFilesAction(WsActionPropsFactory.update(updatedFiles, true, [], ScenarioConstants.AllScenarioId)),
        ).pipe(
          switchMap((result) => [
            updateRowSuccess(result.payload),
            updateXAxisShiftSuccessAction({ fileIds: updatedFiles.map((file) => file.Id) }),
          ]),
          catchError((err) => of(dataFailed(err))),
        );
      }),
    ),
  );

  public resetXAxisShift$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.resetXAxisShiftAction),
      concatLatestFrom(() => this.store.select(getStorageFilesArray)),
      mergeMap(([, storageFiles]) => {
        const filesWithoutShifts: ImportFileDto[] = storageFiles.map((file) => ({ ...file, XAxisShift: 0 }));
        return this.emit<CrudResponse>(
          new ReportingModuleUpdateFilesAction(WsActionPropsFactory.update(filesWithoutShifts, true, [], ScenarioConstants.AllScenarioId)),
        ).pipe(
          switchMap((result) => [
            updateRowSuccess(result.payload),
            updateXAxisShiftSuccessAction({ fileIds: filesWithoutShifts.map((file) => file.Id) }),
          ]),
          catchError((err) => of(dataFailed(err))),
        );
      }),
    ),
  );

  // region running calculation
  public startCalculationSuccessOrProgressUpdatedAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(calcActions.calculationProgressUpdatedAction),
      concatLatestFrom(() => [
        this.store.select(getJobForCurrentContext),
        this.store.select(getCurrentScenarioId),
        this.store.select(getCurrentRangeId),
        this.store.select(getCalculationChartTimeVolMode),
        this.store.select(getCurrentAppModuleType),
      ]),
      filter(([action, activeJob, currentScenarioId, currentRangeId, _]) =>
        this.isJobForCurrentScenarioAndRange(
          action.jobPayload.jobId,
          activeJob,
          currentScenarioId,
          currentRangeId ?? RangeConstants.EntireRangeId,
        ),
      ),
      mergeMap(([action, , currentScenarioId, rangeId, timeVolMode, moduleType]) => {
        const actions: Action[] = [
          calculationProgressUpdatedCurrentScenarioAction({
            calcPayload: action.jobPayload,
            scenarioId: currentScenarioId,
            timeVolMode,
            rangeId,
            moduleType,
          }),
        ];

        if (action.jobPayload.calcEngineMessage.messageType === CalculationEngineMessageType.complete) {
          actions.push(
            checkDepthDataAction({
              depthDataKeys: [
                {
                  scenarioId: currentScenarioId,
                  moduleType,
                  rangeId,
                },
              ],
            }),
          );
        }

        return actions;
      }),
    ),
  );

  // region get chart data
  public getChartData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.getTimeVolChartData),
      filter((action) => action.getChartDataParams.chartId !== undefinedChartId),
      concatLatestFrom(() => [
        this.store.select(getJobForCurrentContext),
        this.store.select(getScenarioState),
        this.store.select(getCurrentAppModuleType),
        this.store.select(selectUserGlobalOptions),
        this.store.select(getCurrentlyVisibleChartCompareScenarioIds),
      ]),
      tap(([action, , , moduleType]) => {
        if (action.gaugeDataRequestedAfterFileImport) {
          this.store.dispatch(redirectToGaugeDataAction({ moduleType }));
        }
      }),
      map(([action, activeCalculationJob, scenarioState, , globalOptions, currentlyVisibleChartCompareScenarioIds]) => ({
        requestAction: new GetTimeBasedChartDataAction({
          ...action.getChartDataParams,
          structureOnly:
            activeCalculationJob?.status === CalculationStatus.running &&
            action.getChartDataParams.dataSourceType === ChartDataSourceType.ChartSourceResultsTimeBased,
          scenarioId: scenarioState.currentScenarioId,
          compareScenarioIds: currentlyVisibleChartCompareScenarioIds,
          maxPoints: globalOptions.MaxChartPoints,
        }),
        reportingTabId: action.reportingTabId,
        gaugeDataRequestedAfterFileImport: action.gaugeDataRequestedAfterFileImport,
      })),
      mergeMap(({ requestAction, reportingTabId, gaugeDataRequestedAfterFileImport }) =>
        this.emit<TimeVolChartDataResponse>(requestAction).pipe(
          map(({ payload: response }) =>
            actions.getTimeVolChartDataSuccess({
              response,
              reportingTabId,
              gaugeDataRequestedAfterFileImport,
            }),
          ),
          catchError((err) => of(dataFailed(err))),
        ),
      ),
    ),
  );

  public getChartDataSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.getTimeVolChartDataSuccess),
      concatLatestFrom(() => [this.store.select(getReportingDataState), this.store.select(getStorageColumns)]),
      filter(
        ([_, reportingDataState, storageColumns]) =>
          reportingDataState.chartState.chartDataSourceType === ChartDataSourceType.ChartSourceGaugeData &&
          ((!reportingDataState.chartState.chartSeries.length && !!storageColumns.ids.length) ||
            reportingDataState.gaugeDataRequestedAfterFileImport),
      ),
      map(([action]) => uiActions.editGaugeDataChartAction({ gaugeDataRequestedAfterFileImport: action.gaugeDataRequestedAfterFileImport })),
    ),
  );

  // region get depth data
  public checkDepthDataForScenarioSimulationsAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.checkDepthDataAction),
      concatLatestFrom(() => [
        this.store.select(getReportingDataState),
        this.store.select(getLowerCompletionRange),
        this.store.select(getJobForCurrentContext),
      ]),
      filter(([action, reportingDataState, _, activeCalculationJob]) => {
        if (activeCalculationJob?.status === CalculationStatus.running || reportingDataState.calculationJobId) {
          return false;
        }

        const indexToProcess = this.getDepthIndexToProcess(action.depthDataKeys, reportingDataState);

        return indexToProcess !== -1;
      }),
      mergeMap(([action, reportingDataState]) => {
        const indexToProcess = this.getDepthIndexToProcess(action.depthDataKeys, reportingDataState);
        const depthDataKey = action.depthDataKeys[indexToProcess];

        return this.emit<GetDepthDataResultsActionResponse>(new GetDepthDataResultsAction(depthDataKey)).pipe(
          tap(() => {
            if (indexToProcess < action.depthDataKeys.length - 1) {
              this.store.dispatch(checkDepthDataAction({ depthDataKeys: action.depthDataKeys.slice(indexToProcess + 1) }));
            }
          }),
          map((result) => actions.getDepthDataSuccess({ depthDataKey, response: result.payload })),
          catchError((err) => of(dataFailed(err))),
        );
      }),
    ),
  );

  public getDepthDataForSinglePointAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.setSelectedSimulationTime),
      concatLatestFrom(() => [this.store.select(getDepthDataPointsToLoad)]),
      filter(([, depthDataPointsToLoad]) => depthDataPointsToLoad.length > 0),
      mergeMap(([, keys]) => {
        return this.emit<IDepthBasedResult[]>(new GetDepthDataForSinglePointAction(keys)).pipe(
          map((result) => actions.getSinglePointDepthDataSuccess({ depthResults: result.payload })),
          catchError((err) => of(dataFailed(err))),
        );
      }),
    ),
  );

  // region export data

  public validateAndStartExportDataJobAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.validateAndStartExportDataJobAction),
      concatLatestFrom(() => this.store.select(getValidatedStorageFilesWithColumns)),
      map(([action, filesWithColumns]) => {
        const isValid = this.validateImportFiles(filesWithColumns);
        return { action, isValid };
      }),
      filter(({ isValid }) => isValid),
      map(({ action }) => {
        return actions.startExportDataJobAction(action);
      }),
    ),
  );

  public exportDataAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.startExportDataJobAction),
      concatLatestFrom(() => this.store.select(getValidatedDeveloperSettings)),
      mergeMap(([action, developerSettings]) => {
        const jobId = JobidHelper.generateJobId();
        return this.emit(
          new ReportingModuleStartExportDataJobAction(action.payload, developerSettings, jobId),
          'Exporting data...',
          '',
          true,
        ).pipe(
          map(() => actions.startExportDataUnitsConversionAction({ jobId, payload: action.payload })),
          catchError((err) => of(dataFailed(err))),
        );
      }),
    ),
  );

  public exportDataJobStartedAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.startExportDataUnitsConversionAction),
      concatLatestFrom(() => [this.store.select(selectCurrentUnitSystem)]),
      mergeMap(([action, unitSystem]) => {
        const payload: ExportDataConvertUnitsActionPayload = {
          ...action.payload,
          unitSystem,
        };
        return this.emit<ReportingModuleConvertExportDataUnitsActionResponse>(
          new ReportingModuleConvertExportDataUnitsAction(action.jobId, payload),
          'Creating csv file...',
          '',
          true,
        ).pipe(
          tap(async (action) => {
            this.modalService.dismissAll();

            const file: IFile = {
              Name: action.payload.filename,
              Repository: 'temp',
              FileType: 'csv',
              Folder: [],
            };

            if (isElectron()) {
              this.store.dispatch(electronSaveCSVAsFileAction({ fileName: file.Name }));
            } else {
              const token = this.authService.accessToken;
              const sessionId = this.authService.sessionId;
              this.http
                .get(FileManagerHelper.getDownloadUrl(file, token as string, sessionId, this.env), { responseType: 'blob' })
                .pipe(take(1))
                .subscribe((blob) => {
                  saveAs(blob, file.Name);
                });
            }
          }),
          map(() => actions.exportDataSuccessAction()),
          catchError((err) => of(dataFailed(err))),
        );
      }),
    ),
  );

  // region chart templates
  public loadChartTemplatesAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loadChartTemplates),
      mergeMap(() =>
        this.emit<TemplatesLoadActionResponse<IChartTemplateDto>>(new ChartTemplateLoadAction()).pipe(
          map((result) => actions.loadChartTemplatesSuccess({ charts: result.payload.templates })),
          catchError((err) => of(dataFailed(err))),
        ),
      ),
    ),
  );

  public createChartTemplatesAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createChartTemplate),
      mergeMap(({ template }) => {
        return this.emit<CrudResponse>(new ChartTemplateSaveAction(template)).pipe(
          switchMap((result) => [updateRowSuccess(result.payload), closeEditChartTemplateModalAction()]),
          catchError((err) => {
            if (err.name === 'exists') {
              return of(chartTemplateAlreadyExistsAction({ template, existingTemplateId: err.data.templateId }));
            } else {
              return of(dataFailed(err));
            }
          }),
        );
      }),
    ),
  );

  public templateAlreadyExistsAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(chartTemplateAlreadyExistsAction),
        mergeMap(async (action) => {
          const confirm = await this.modalService.showConfirm(
            `Template with the name: "${
              action.template.Name
            }" ( ${action.template.Type.toLocaleLowerCase()} settings ) already exists. Do you want to override it?`,
            'Template already exists',
          );

          if (confirm) {
            this.store.dispatch(
              updateChartTemplateAction({
                template: { ...action.template, Id: action.existingTemplateId },
              }),
            );
          }
        }),
      ),
    { dispatch: false },
  );

  public updateChartTemplatesAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateChartTemplateAction),
      mergeMap(({ template }) => {
        return this.emit<CrudResponse>(new ChartTemplateSaveAction(template)).pipe(
          switchMap((result) => [updateRowSuccess(result.payload), closeEditChartTemplateModalAction()]),
          catchError((err) => of(dataFailed(err))),
        );
      }),
    ),
  );

  public deleteChartTemplateAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteChartTemplatesAction),
      mergeMap((action) =>
        this.emit<CrudResponse>(new ChartTemplatesDeleteAction(action.templatesIds)).pipe(
          exhaustMap((result) => [deleteRowsSuccess(result.payload)]),
          catchError((err) => of(dataFailed(err))),
        ),
      ),
    ),
  );

  // region chart series templates
  public updateChart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.updateChartAction),
      mergeMap((action) => this.emitUpdate(new UpdateChartAction(action))),
    ),
  );
  public updateChartAxisSelection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.updateChartAxisSelectionAction),
      concatLatestFrom(() => [
        this.store.select(getCurrentlyVisibleChartDtos),
        this.store.select(getCurrentRangeId),
        this.store.select(getCurrentScenarioId),
      ]),
      mergeMap(([action, currentlyVisibleChartDtos, currentRangeId, currentScenarioId]) =>
        this.emitUpdate(
          new UpdateChartAxisSelectionAction(
            action.changeScope === 'single' ? currentlyVisibleChartDtos.map((dto) => dto.Id) : undefined,
            action.changeScope === 'range' ? currentRangeId : undefined,
            action.changeScope !== 'project' ? currentScenarioId : undefined,
            action.chartTimeVolMode,
            action.chartMdTvdMode,
          ),
        ),
      ),
    ),
  );
  public updateChartSeriesTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.updateChartSeriesTemplate),
      mergeMap((action) => this.emitUpdate(new UpdateChartSeriesTemplateAction(action))),
    ),
  );
  public insertChartSeriesTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.insertChartSeriesTemplate),
      mergeMap((action) =>
        this.emit<CrudResponse>(new InsertChartSeriesTemplateAction(action)).pipe(
          map((result) => insertRowsSuccess(result.payload)),
          catchError((err) => of(dataFailed(err))),
        ),
      ),
    ),
  );
  public getChartSeriesTemplates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.getChartSeriesTemplates),
      mergeMap(() =>
        this.emit<ChartSeriesTemplateDto[]>(new GetChartSeriesTemplatesAction()).pipe(
          map((result) => getChartSeriesTemplatesSuccess({ chartSeriesTemplates: result.payload })),
          catchError((err) => of(dataFailed(err))),
        ),
      ),
    ),
  );

  public deleteChartSeriesTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.deleteChartSeriesTemplate),
      mergeMap((action) =>
        this.emit<CrudResponse>(new DeleteChartSeriesTemplateAction(action)).pipe(
          map((result) => deleteRowsSuccess(result.payload)),
          catchError((err) => of(dataFailed(err))),
        ),
      ),
    ),
  );

  public navigateToScenarioRangeAndChart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.navigateToScenarioAction),
      concatLatestFrom(() => [
        this.store.select(getCurrentAppModuleType),
        this.store.select(getSortedReportingTabsForCurrentRange),
        this.store.select(getSelectedReportingTab),
        this.store.select(getAllScenarios),
      ]),
      mergeMap(async ([action, moduleType, allTabs, currTab, scenarios]) => {
        let newTabId;

        const targetScenario = scenarios.find((scenario) => scenario.Id === action.scenarioId);
        const targetRange = targetScenario?.CurrentRangeId;

        const currTabIndex = allTabs.findIndex((tab) => tab.Id === currTab?.Id);

        if (targetRange != null && currTabIndex != -1) {
          const paramsResponse = await firstValueFrom(
            this.emit<{ tabId: number | null }>(new LoadTabIdForScenarioChangeAction(moduleType, currTabIndex, action.scenarioId, targetRange)),
          );
          newTabId = paramsResponse.payload.tabId;
        }

        await this.routerHelperService.navigateToScenarioAndRange(action.scenarioId, targetRange ?? 0, newTabId);
        return updateLastUsedScenarioId({ scenarioId: action.scenarioId });
        // return
      }),
    ),
  );

  public updateVisibleChartsProperties$ = createEffect(() =>
    this.actions$.pipe(
      ofType(isOptimizeActiveAction, isCompareScenarioActiveAction),
      concatLatestFrom((action) => {
        let chartSelector;
        if (action.type === isOptimizeActiveAction.type && action.batchAction) {
          chartSelector = getRangeReportingOptimizeChartDtos;
        } else if (action.type === isCompareScenarioActiveAction.type && action.batchAction) {
          chartSelector = getRangeReportingCompareScenariosChartDtos;
        } else {
          chartSelector = getCurrentlyVisibleChartDtos;
        }
        return [this.store.select(chartSelector)];
      }),
      mergeMap(([action, chartDtos]) => {
        // if chartDtos array is empty do not dispatch updateChartAction
        if (chartDtos.length === 0) {
          return of();
        }

        const changeChartDtos = (): ChartDto[] => {
          switch (action.type) {
            case isOptimizeActiveAction.type:
              return chartDtos.map((dto) => changePropValue(dto, 'IsOptimizeActive', action.isOptimizeActive));
            case isCompareScenarioActiveAction.type:
              return chartDtos.map((dto) => changePropValue(dto, 'IsCompareScenarioActive', action.isCompareScenarioActive));
          }
        };

        return of(updateChartAction(changeChartDtos()));
      }),
    ),
  );

  constructor(
    actions$: Actions,
    store: Store,
    wsService: BackendConnectionService,
    modalService: ModalService,
    private router: Router,
    private authService: ClientAuthService,
    private appTargetConfig: AppTargetConfig,
    private http: HttpClient,
    @Inject(ENVIRONMENT) private env: IEnvironment,
    private routerHelperService: RouterHelperService,
  ) {
    super(actions$, wsService, ReportingModuleName, true, true, modalService, store);
  }

  protected clearChartAxisPropsStyles(prop: IAxisProps): IAxisProps {
    return {
      overrideStyle: false,
      manualLimit: false,
      isLogarithmic: false,
      xAxisFormat: XAxisFormat.deltaTime,
      StartTimeFileId: null,
      axis: prop.axis,
      chartId: prop.chartId,
      scenarioId: prop.scenarioId,
      id: prop.id,
    };
  }

  protected clearChartStyles(chartDto: ChartDto): ChartDto {
    return {
      ...chartDto,
      LegendFontSize: defaultLegendFontSize,
      LegendLocation: ChartLegendLocation.Top,
      IsLegendFontItalic: false,
      IsLegendFontBold: false,
    };
  }

  // region helper functions
  private getDepthIndexToProcess(src: ResultsSourceKey[], reportingState: ReportingModuleState): number {
    return src.findIndex((resultsSourceKey) => {
      const depthDataStatus =
        reportingState.depthDataForScenarios.dict[resultsSourceKey.scenarioId]?.depthDataForRanges.dict[resultsSourceKey.rangeId]
          ?.depthDataResultsStatus;
      return depthDataStatus !== DepthDataStatus.Loaded && depthDataStatus !== DepthDataStatus.Requested;
    });
  }

  private isJobForCurrentScenarioAndRange(
    actionJobId: string,
    activeJob: ICalculationJob | undefined,
    scenarioId: number,
    rangeId: number,
  ): boolean {
    if (!activeJob || actionJobId !== activeJob.jobId) {
      return false;
    }
    return activeJob.scenarioId === scenarioId && activeJob.rangeId === rangeId;
  }

  private copyChartScenarioIdToEntity<E extends IScenarioIdBasedEntity>(entity: E, chartDto: ChartDto | undefined | null): E {
    if (chartDto == null) {
      throw new Error('ChartDto is not defined!');
    }

    const { ScenarioId } = chartDto;

    return {
      ...entity,
      ScenarioId,
    };
  }

  private axisPropsActionToDtos(
    { axisProps, secondaryArg }: ChartAxisPropertiesActionProps,
    chartDtos: ChartDto[],
  ): { axisPropertyDtos: ChartAxisPropertyDto[]; secondaryArg: boolean } {
    const axisPropertyDtos = axisProps.map((props) => {
      // find chart corresponding to props
      const chart = chartDtos.find((chart) => chart.Id === props.chartId);
      if (chart == null) {
        throw new Error('Chart DTO not found!');
      }

      // convert to props dto
      const propDto = ReportingFactory.toChartAxisPropertyDto(props);

      // update props scenario id
      return this.copyChartScenarioIdToEntity(propDto, chart);
    });

    return {
      axisPropertyDtos,
      secondaryArg,
    };
  }

  private validateImportFiles(filesWithColumns: IDictionaryWithArray<IValidatedStorageFileWithColumns>): boolean {
    const invalidImportFiles = DictionaryWithArray.getArray(filesWithColumns)
      .filter((file) => !file.isValid) // only invalid IValidatedStorageFileWithColumns
      .map((f) => f.file) // map to ImportFileDto
      .filter(isDefined) // filter out undefined ImportFileDtos
      .filter((file) => file.FileType === DataFileType.ImportedData); // only ImportedData type

    const areImportFilesValid = invalidImportFiles.length === 0;

    if (!areImportFilesValid) {
      this.modalService.showAlert(`${ErrorHelper.ERROR_MULTIPLE_SCREENS_MESSAGE_HEADER} <br> -> Data Grid`, 'Warning', 'lg').then();
    }

    return areImportFilesValid;
  }
}

const getRangeReportingOptimizeChartDtos = createSelector(
  getSortedReportingTabsForCurrentRange,
  getChartDtos,
  (sortedReportingTab, chartDtos) => {
    // Enabled only in Evaluate - take Time/Vol and reporting(but not importData)
    const chartIds = sortedReportingTab.filter((chart) => chart.IsChartTimeVolume).map((chart) => chart.ChartId);

    return chartDtos.filter((dto) => dto.Source === ChartDataSourceType.ChartSourceReportingTab && chartIds.includes(dto.Id));
  },
);

const getRangeReportingCompareScenariosChartDtos = createSelector(
  getSortedReportingTabsForCurrentRange,
  getChartDtos,
  (sortedReportingTab, chartDtos) => {
    // Enabled only in Simulate - take all reporting
    const chartIds = sortedReportingTab.map((chart) => chart.ChartId);

    return chartDtos.filter((dto) => dto.Source === ChartDataSourceType.ChartSourceReportingTab && chartIds.includes(dto.Id));
  },
);
