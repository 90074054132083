import { WsAction } from '../../ws.action';

export const DbMigrationModuleName = 'DbMigrationModule';

export enum DbMigrationModuleActionsTypes {
  RunMigrationRequest = '[DbMigrationModule] RunMigrationRequest',
  MigrationProgressUpdate = '[DbMigrationModule] MigrationProgressUpdate',
  CommonDbMigrationProgressUpdate = '[DbMigrationModule] CommonDbMigrationProgressUpdate',
}

export class RunMigrationAction extends WsAction {
  public override readonly type = DbMigrationModuleActionsTypes.RunMigrationRequest;
}

export type MigrationStatus =
  | 'migrationRequired'
  | 'migrationRequiredWithBackup'
  | 'migrationInProgress'
  | 'migrationComplete'
  | 'migrationError'
  | 'creatingBackup'
  | 'migrationInitialization';

export interface IMigrationServerResponse {
  status: MigrationStatus;
  fileHash: string;

  initialDbVersion?: string;
  currentDbVersion?: string;
  finalDbVersion?: string;
  importDataFileCount?: number;
  importDataFilesDone?: number;
  importDataRowCount?: number;
  importDataRowsDone?: number;
}

export type DbMigrationModuleActions = RunMigrationAction | MigrationProgressUpdateAction;

export class MigrationProgressUpdateAction extends WsAction {
  public override readonly type = DbMigrationModuleActionsTypes.MigrationProgressUpdate;

  constructor(
    public override readonly taskId: string,
    public override readonly payload: IMigrationServerResponse,
  ) {
    super();
  }
}

export class CommonDbMigrationProgressUpdateAction extends WsAction {
  public override readonly type = DbMigrationModuleActionsTypes.CommonDbMigrationProgressUpdate;

  constructor(public override payload: IMigrationServerResponse) {
    super();
  }
}
