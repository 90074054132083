import { createSelector } from '@ngrx/store';
import { getValidatedPumping } from './pumping-validation.selectors';
import { Pumping } from '@dunefront/common/modules/pumping/model/pumping/pumping';
import { CompletionModuleState } from '@dunefront/common/modules/completion/completion-module.state';
import { getValidatedCompletionModuleState } from '../../completion/validated-completion.selectors';
import { ReferenceVariableCalculatorResultsDto } from '@dunefront/common/modules/reference-variables/dto/reference-variable-calculator-results.dto';
import { getReferenceVariables } from '../../reference-variables/reference-variables.selectors';

export const getWellFluidReferenceVariablesData = createSelector(
  getValidatedPumping,
  getValidatedCompletionModuleState,
  getReferenceVariables,
  (...[pumping, completion, referenceVariables]): ReferenceVariablesData | undefined => {
    return !completion.isLoaded || !referenceVariables ? undefined : { pumping, completion, referenceVariables };
  },
);

export interface ReferenceVariablesData {
  pumping: Pumping;
  completion: CompletionModuleState;
  referenceVariables: ReferenceVariableCalculatorResultsDto;
}
