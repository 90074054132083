import { BasePptSectionGenerator, PptContext } from '../base-ppt-section-generator';
import { IReportingTabImage } from '../../doc/sections/chart-report-generator.helper';
import { EmptyResultsPptGenerator } from './empty-results-ppt-generator';

export interface ChartPptGeneratorContext extends PptContext {
  reportingTabsImages: IReportingTabImage[];
}

export class ReportingTabsGenerator extends BasePptSectionGenerator {
  constructor(protected override context: ChartPptGeneratorContext) {
    super(context);
  }

  public async generate(rangeName?: string): Promise<void> {
    const reportingTabsImages = this.context.reportingTabsImages;

    for (const { image, reportingTab } of reportingTabsImages) {
      const title = reportingTab.TabName;
      const subTitle = rangeName;

      if (image == null || image.imageDataBase64.length === 0) {
        await new EmptyResultsPptGenerator({
          ...this.context,
          title,
          subTitle,
        }).generate();
        continue;
      }

      this.addImageSlide({ title, subTitle, image });
    }
  }
}
