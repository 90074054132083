import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RibbonMenuComponent } from './ribbon-menu.component';
import { MenuGroupComponent } from './common-components/menu-group/menu-group.component';
import { MenuPanelComponent } from './common-components/menu-panel/menu-panel.component';
import { GeneralMenuPanelComponent } from './panels/general-menu-panel/general-menu-panel.component';
import { DocsMenuPanelComponent } from './panels/docs-menu-panel/docs-menu-panel.component';
import { UnitSystemComponent } from './panels/general-menu-panel/unit-system/unit-system.component';
import { ScenarioComponent } from './panels/general-menu-panel/scenario/scenario.component';
import { ChartsMenuPanelComponent } from './panels/charts-menu-panel/charts-menu-panel.component';
import { AnalysisMenuPanelComponent } from './panels/analysis-menu-panel/analysis-menu-panel.component';
import { RangeManagementComponent } from './panels/analysis-menu-panel/range-management/range-management.component';
import { AxisSelectionComponent } from './panels/display-menu-panel/axis-selection/axis-selection.component';
import { ZoomComponent } from './panels/display-menu-panel/zoom/zoom.component';
import { RouterModule } from '@angular/router';
import { RadioGroupModule } from '../../shared/components/form-components/radio-group/radio-group.component';
import { ConnectionStatusComponent } from './panels/tools-panel/connection-status.component';
import { UserPanelModule } from '../../shared/components';
import { SelectModule } from '../../shared/components/form-components/select/select.module';
import { ScenarioManagerSelectorModule } from '../modals/scenario-manager/scenario-manager-selector/scenario-manager-selector.module';
import { ButtonModule } from '../../shared/components/button/button.module';
import { HelpModule } from '../help/help.module';
import { FileDisplayNamePipeModule } from '../../shared/pipes/file/file-display-name.pipe.module';
import { UnitsModule } from '../units/units.module';
import { HideOnDataProModule } from '../../shared/directives/hide-on-datapro';
import { DisplayMenuPanelComponent } from './panels/display-menu-panel/display-menu-panel.component';
import { AssetService } from '../../shared/services/asset.service';
import { FormInputModule } from '../../shared/components/form-components/form-input/form-input.component';
import { LetDirective } from '@ngrx/component';
import { FormsModule } from '@angular/forms';
import { RangeLabelsSelectorModule } from '../../shared/components/range-labels-selector/range-labels-selector.module';
import { CompareScenarioButtonComponent } from './panels/charts-menu-panel/advanced-buttons/compare-scenario-button/compare-scenario-button.component';
import { OptimizeButtonComponent } from './panels/charts-menu-panel/advanced-buttons/optimize-button/optimize-button.component';
import { TimeVolButtonComponent } from './panels/display-menu-panel/axis-selection/advanced-buttons/time-vol-button/time-vol-button.component';
import { MenuModule } from 'primeng/menu';
import { MdTvdButtonComponent } from './panels/display-menu-panel/axis-selection/advanced-buttons/md-tvd-button/md-tvd-button.component';

@NgModule({
  declarations: [
    RibbonMenuComponent,
    MenuGroupComponent,
    MenuPanelComponent,
    GeneralMenuPanelComponent,
    AnalysisMenuPanelComponent,
    ChartsMenuPanelComponent,
    CompareScenarioButtonComponent,
    OptimizeButtonComponent,
    DisplayMenuPanelComponent,
    DocsMenuPanelComponent,
    UnitSystemComponent,
    ScenarioComponent,
    RangeManagementComponent,
    AxisSelectionComponent,
    TimeVolButtonComponent,
    MdTvdButtonComponent,
    ZoomComponent,
    ConnectionStatusComponent,
  ],
  imports: [
    RouterModule,
    CommonModule,
    RadioGroupModule,
    SelectModule,
    RadioGroupModule,
    ButtonModule,
    UserPanelModule,
    ScenarioManagerSelectorModule,
    HelpModule,
    FileDisplayNamePipeModule,
    UnitsModule,
    HideOnDataProModule,
    FormInputModule,
    LetDirective,
    FormsModule,
    RangeLabelsSelectorModule,
    MenuModule,
  ],
  exports: [RibbonMenuComponent, ConnectionStatusComponent],
  providers: [AssetService],
})
export class RibbonMenuModule {}
