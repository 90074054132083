import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ISelectItem, sortNumberSelectItem, sortSelectItem } from '@dunefront/common/common/select.helpers';
import { SelectItemGroup } from 'primeng/api';
import { groupBy } from 'lodash';
import { Dropdown } from 'primeng/dropdown';
import { BaseFormComponent } from '../base-form-component';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectComponent<T, ItemType extends T[keyof T]> extends BaseFormComponent<T, ItemType> implements AfterViewInit, OnChanges {
  @Input() public items: ISelectItem<ItemType>[] | null = [];

  public options: ISelectItem<any>[] | SelectItemGroup[] = [];

  @Input() public selectPlaceholder = 'Select an option';
  @Input() public class = '';

  @ViewChild(Dropdown) public dropdown: Dropdown | undefined;
  @ViewChild('hiddenInput') private hiddenInput: ElementRef | undefined;

  @Input() public opened!: boolean;
  @Input() public clearable = false;

  @Input() public disableSorting = false;
  @Input() public width?: number;
  @Input() public searchable = true;
  @Input() public unitLabel!: string;
  @Input() public colorPicker = false;
  @Input() public multiple = false;
  @Input() public isGridInput = false;

  @Input() public decimalPlaces?: number;

  @Output() public changeFocusCell = new EventEmitter();
  @Output() public selectHide = new EventEmitter();

  public get isCurrentValueInvalid(): boolean {
    return !!this.items?.find((item) => item.value === this.value)?.isInvalid && !this.disabled;
  }

  public get someItemsInvalidError(): boolean {
    return !!this.items?.find((item) => item.isInvalid) && !this.disabled;
  }

  public isGrouped = false;

  public setOptions(): void {
    this.isGrouped = false;
    if (this.items === null) {
      this.options = [];
      return;
    }
    if (!this.isGrouped) {
      const isNumberSelect = this.items.every((item) => !isNaN(Number(item.text)));

      this.options =
        this.disableSorting || this.colorPicker ? this.items : this.items.sort(isNumberSelect ? sortNumberSelectItem : sortSelectItem);
      return;
    }
    const groups = groupBy(this.items, (g) => g.group);
    const keys = Object.keys(groups);
    if (Object.keys(groups).length <= 1) {
      this.options = this.items;
      return;
    }
    this.isGrouped = true;
    this.options = keys.map((key) => {
      const g: SelectItemGroup = {
        label: key,
        items: this.disableSorting || this.colorPicker ? groups[key] : groups[key].sort(sortSelectItem),
      };
      return g;
    });
  }

  private setFocusOnHiddenInput(): void {
    setTimeout(() => {
      // focus hidden input on selecting dropdown item, to listen keypress
      this.hiddenInput?.nativeElement.focus();
    }, 0);
  }

  public get valueIfAvailable(): any {
    if (this.items?.find((item) => item.value === this.value)) {
      return this.value;
    }
    return null;
  }

  public set valueIfAvailable(value: any) {
    if (value == null) {
      return;
    }
    this.setFocusOnHiddenInput();
    this._value = value;
  }

  public get componentWidth(): string {
    return this.width !== undefined ? this.width + 'px' : '100%';
  }

  public async onSelectChange(): Promise<void> {
    if (this.value != null) {
      await this.onChange(this.value);
      this.selectHide.emit();
    }
  }

  public onHide(): void {
    if (this.value === this._storeValue) {
      this.selectHide.emit();
    }
  }

  public ngAfterViewInit(): void {
    if (this.opened) {
      setTimeout(() => {
        this.openDropdown();
      }, 10);
    }
  }

  public openDropdown(): void {
    this.dropdown?.zone.run(() => {
      if (this.dropdown != null) {
        this.dropdown.show();
        this.dropdown.cd.markForCheck();
        this.setFocusOnHiddenInput();
      }
    });
  }

  public override ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    if (changes.opened != null && changes.opened.currentValue) {
      this.setFocusOnHiddenInput();
    }
    if (changes.items != null) {
      this.setOptions();
    }
  }

  public encodeCyData(text: any): string {
    return text.toString().replace('/', '');
  }

  public onFocusNextCell(event: KeyboardEvent): void {
    event.stopPropagation();
    event.preventDefault();
    this.changeFocusCell.emit(event);
  }

  public getItemText(text: string): string | number {
    if (this.decimalPlaces != null && Number(text)) {
      // round and fix number only if its not an integer
      if (parseInt(text) === parseFloat(text)) {
        return parseInt(text);
      } else {
        return parseFloat(text).toFixed(this.decimalPlaces);
      }
    }

    return text;
  }

  public getDefaultTooltip(): string {
    if (this.sourceDefaults == null || this.key == null) {
      return '';
    }
    const key = this.key;
    const defaultValue =
      this.items != null && this.items.length
        ? this.items.find((item) => item.value === (this.sourceDefaults as T)[key])?.text
        : (this.sourceDefaults as T)[key];
    return defaultValue != null ? 'Default value: ' + defaultValue : '';
  }
}

export interface ISelectValueChanged {
  value: any;
  shouldResetResults: boolean;
}
