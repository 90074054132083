import { PipeDto, PipeType } from '../../../dto/pipe.dto';
import { Validation } from '../../../common/validation';
import { isShuntedPipe } from '../../pipes/lower-completion-pipes/pipes/shunted-screen-pipe';
import { CompletionModuleState } from '../completion-module.state';
import { ArgumentOutOfRangeError } from '../../../exceptions/argumentOutOfRangeException';
import { CaliperDto } from '../../well/dto/caliper/caliper.dto';
import { getRowsForCalculations } from '../../../common/common-grid.interfaces';
import { Pipe } from '../../pipes/pipe';
import { LowerCompletionCalculations } from '../../pipes/lower-completion-pipes/lower-completion-pipes.calculations';

export class CompletionCalculations {
  // Method to calculate the WP/BP ratio
  public static WashpipeBasepipeRatio(completion: CompletionModuleState): number {
    const lcRows = getRowsForCalculations(completion.LowerCompletion.rows);
    const screenTopMD: number = LowerCompletionCalculations.ScreenTopMD(lcRows);
    const screenBottomMD: number = LowerCompletionCalculations.ScreenBottomMD(lcRows);
    let maxWashpipeOD = 0;
    let minBasepipeID = 0;

    // Calculate the max washpipe OD between top and bottom of screens
    const washpipesODs = getRowsForCalculations(completion.RunningString.rows)
      .filter((pipe) => pipe.BottomMD > screenTopMD && pipe.TopMD < screenBottomMD && pipe.PipeType === PipeType.Washpipe)
      .map((pipe) => pipe.OuterDiameter);

    // Calculate the min basepipe ID between top and bottom of screens
    const lowerCompletionInnerDiameters = lcRows
      .filter((pipe) => pipe.BottomMD > screenTopMD && pipe.TopMD < screenBottomMD)
      .map((pipe) => pipe.InnerDiameter);

    // There are no elements in the series
    if (!washpipesODs.length || !lowerCompletionInnerDiameters.length) {
      return 0;
    }

    maxWashpipeOD = Math.max(...washpipesODs);
    minBasepipeID = Math.min(...lowerCompletionInnerDiameters);

    // Check for error conditions
    if (maxWashpipeOD <= 0 || minBasepipeID <= 0) {
      return 0;
    }

    // Calculate the ratio and validate
    return Validation.UICheckNumberValidity(maxWashpipeOD / minBasepipeID);
  }

  // Method to calculate the isolation packer ODs
  public static SetIsolationPackersOD(casingData: PipeDto[], lowerCompletion: PipeDto[], caliperData: CaliperDto[]): void {
    lowerCompletion
      .filter((pipe) => pipe.PipeType === PipeType.Isolation_Packer || pipe.PipeType === PipeType.Sump_Packer)
      .forEach((isolationPacker) => {
        if (casingData == null) {
          // Set the OD to zero
          isolationPacker.OuterDiameter = 0;
        } else {
          // Set equal to the inner diameter of the casing component
          const component = casingData.find((pipe) => pipe.TopMD <= isolationPacker.TopMD && pipe.BottomMD > isolationPacker.TopMD);
          if (component == null) {
            // A component was not found so set the OD to zero
            isolationPacker.OuterDiameter = 0;
          } else if (component.PipeType === PipeType.Open_Hole) {
            // Set the diameter to zero initially then update if there is a relevant caliper section
            isolationPacker.OuterDiameter = 0;
            if (caliperData != null) {
              // Set the diameter using the caliper data
              for (let caliperIndex = 0; caliperIndex < caliperData.length - 1; caliperIndex++) {
                if (
                  caliperData[caliperIndex].TopMD <= isolationPacker.TopMD &&
                  caliperData[caliperIndex + 1].TopMD > isolationPacker.TopMD
                ) {
                  isolationPacker.OuterDiameter = caliperData[caliperIndex].Diameter;
                  break;
                }
              }
            }
          } else {
            // Set the diameter directly
            isolationPacker.OuterDiameter = component.InnerDiameter;
          }
        }
      });
  }

  // Calculate lower completion MD
  public static MaxLowerCompletionMD(lowerCompletionRows: Pipe[]): number {
    if (!lowerCompletionRows.length) {
      throw new ArgumentOutOfRangeError('CompletionCalculations.MaxLowerCompletionMD', 'LowerCompletion is empty');
    }
    const lowerCompletionMD = Math.max(...lowerCompletionRows.map((lc) => lc.BottomMD));
    return Validation.UICheckNumberValidity(lowerCompletionMD);
  }

  // Calculate running string MD
  public static MaxRunningStringMD(runningStringRows: Pipe[]): number {
    if (!runningStringRows.length) {
      throw new ArgumentOutOfRangeError('CompletionCalculations.MaxRunningStringMD', 'RunningString is empty');
    }
    const runningStringMD = Math.max(...runningStringRows.map((rs) => rs.BottomMD));
    return Validation.UICheckNumberValidity(runningStringMD);
  }

  // Determine if there are shunts
  public static HasShunts(completion: CompletionModuleState): boolean {
    return completion.LowerCompletion.rows.some((pipe) => isShuntedPipe(pipe.rowData));
  }
}
