import { WsAction } from '../../ws.action';
import { IFile } from '../../dto/file.dto';

export const ElectronMainModuleName = 'ElectronMainModule';

export const ELECTRON_ACTION_SAVE_VIDEO = 'save-video';
export const ELECTRON_ACTION_SAVE_VIDEO_FINISHED = 'save-video-finished';
export const ELECTRON_ACTION_OPEN_FILE_PATH = 'open-file-path';
export const ELECTRON_ACTION_UNREGISTER_ALL_MODULES = 'unregister-all-modules';
export const ELECTION_ACTION_RELOAD = 'reload-electron';
export const ELECTION_ACTION_BACKEND_CONNECTED = 'backend-connected';
export const ELECTRON_ACTION_APP_CLOSE = 'app-close';
export const ELECTION_ACTION_PREVENT_APP_SUSPENSION = 'prevent-app-suspension';
export const ELECTION_ACTION_CANCEL_PREVENT_APP_SUSPENSION = 'cancel-prevent-app-suspension';
export const ELECTRON_ACTION_GET_BACKEND_CONFIG_EVENT = 'GET_BACKEND_CONFIG_EVENT';
export const ELECTRON_ACTION_IPC_INIT_CONNECTION = 'ElectronIpcInitConnection';
export const ELECTRON_ACTION_OPEN_DOCUMENT = 'ElectronIpcGetAppPath';

export interface FileFilter {
  extensions: string[];
  name: string;
}

export interface ISaveReportPayload {
  base64: string;
  reportName: string;
  fileFilters: FileFilter[];
}

export enum ElectronMainModuleActionTypes {
  ShowFileOpen = '[ElectronMain] Show File Open',
  ShowFileSave = '[ElectronMain] Show File Save',
  OpenItem = '[ElectronMain] Open Item',
  SaveAsCSVFile = '[ElectronMain] Save As CSV File',
  SaveReportAsFile = '[ElectronMain] Save Report as File',
  SaveAsProjectFile = '[ElectronMain] Save As Project File',
}

export class ElectronMainShowFileOpenAction extends WsAction {
  public override readonly type = ElectronMainModuleActionTypes.ShowFileOpen;
}

export class ElectronMainShowFileSaveAction extends WsAction {
  public override readonly type = ElectronMainModuleActionTypes.ShowFileSave;

  constructor(public fileName: string) {
    super();
  }
}

export class ElectronMainOpenItemAction extends WsAction {
  public override readonly type = ElectronMainModuleActionTypes.OpenItem;

  constructor(public filePath: string) {
    super();
  }
}

export class ElectronMainSaveAsCSVFileAction extends WsAction {
  public override readonly type = ElectronMainModuleActionTypes.SaveAsCSVFile;

  constructor(public fileName: string) {
    super();
  }
}

export class ElectronMainSaveReportAsFileAction extends WsAction {
  public override readonly type = ElectronMainModuleActionTypes.SaveReportAsFile;

  constructor(public override payload: ISaveReportPayload) {
    super();
  }
}

export class ElectronMainSaveAsProjectFileAction extends WsAction {
  public override readonly type = ElectronMainModuleActionTypes.SaveAsProjectFile;

  constructor(public file: IFile) {
    super();
  }
}

export interface ElectronMainShowFileOpenSaveResponse {
  filePath: string;
}

export type ElectronMainModuleActions =
  | ElectronMainShowFileOpenAction
  | ElectronMainShowFileSaveAction
  | ElectronMainOpenItemAction
  | ElectronMainSaveAsCSVFileAction
  | ElectronMainSaveAsProjectFileAction
  | ElectronMainSaveReportAsFileAction;
