import { UnitConverter } from '../../converter.interfaces';
import { PressureRateDerivativeUnit } from '../../../dto/unit-system.dto';

export class PressureRateDerivativeConverter extends UnitConverter {
  public static symbols: Array<string> = [
    '',
    'Pa/m³/s',
    'Pa/m³/min',
    'Pa/ft³/min',
    'Pa/ft³/s',
    'Pa/bbl/min',
    'Pa/L/min',
    'Pa/galUS/min',
    'kPa/m³/s',
    'kPa/m³/min',
    'kPa/ft³/min',
    'kPa/ft³/s',
    'kPa/bbl/min',
    'kPa/L/min',
    'kPa/galUS/min',
    'psi/m³/s',
    'psi/m³/min',
    'psi/ft³/min',
    'psi/ft³/s',
    'psi/bbl/min',
    'psi/L/min',
    'psi/galUS/min',
    'kg/cm²/m³/s',
    'kg/cm²/m³/min',
    'kg/cm²/ft³/min',
    'kg/cm²/ft³/s',
    'kg/cm²/bbl/min',
    'kg/cm²/L/min',
    'kg/cm²/galUS/min',
    'bar/m³/s',
    'bar/m³/min',
    'bar/ft³/min',
    'bar/ft³/s',
    'bar/bbl/min',
    'bar/L/min',
    'bar/galUS/min',
    'MPa/m³/s',
    'MPa/m³/min',
    'MPa/ft³/min',
    'MPa/ft³/s',
    'MPa/bbl/min',
    'MPa/L/min',
    'MPa/galUS/min',
  ];

  public static unit = PressureRateDerivativeUnit;

  public static fromSi(value: number, unit: PressureRateDerivativeUnit): number {
    switch (unit) {
      case PressureRateDerivativeUnit.Pascal_per_cubic_meter_per_second: {
        return value;
      }
      case PressureRateDerivativeUnit.Pascal_per_cubic_meter_per_minute: {
        return value * 1.6666666667e-2;
      }
      case PressureRateDerivativeUnit.Pascal_per_cubic_foot_per_minute: {
        return value * 4.719452166e-4;
      }
      case PressureRateDerivativeUnit.Pascal_per_cubic_foot_per_second: {
        return value * 2.8316846609e-2;
      }
      case PressureRateDerivativeUnit.Pascal_per_barrel_per_minute: {
        return value * 2.6497882504e-3;
      }
      case PressureRateDerivativeUnit.Pascal_per_liter_per_minute: {
        return value * 1.6666666667e-5;
      }
      case PressureRateDerivativeUnit.Pascal_per_us_gallon_per_minute: {
        return value * 6.3090196565e-5;
      }
      case PressureRateDerivativeUnit.Kilo_Pascal_per_cubic_meter_per_second: {
        return value * 1.0e-3;
      }
      case PressureRateDerivativeUnit.Kilo_Pascal_per_cubic_meter_per_minute: {
        return value * 1.6666666667e-5;
      }
      case PressureRateDerivativeUnit.Kilo_Pascal_per_cubic_foot_per_minute: {
        return value * 4.719452166e-7;
      }
      case PressureRateDerivativeUnit.Kilo_Pascal_per_cubic_foot_per_second: {
        return value * 2.8316846609e-5;
      }
      case PressureRateDerivativeUnit.Kilo_Pascal_per_barrel_per_minute: {
        return value * 2.6497882504e-6;
      }
      case PressureRateDerivativeUnit.Kilo_Pascal_per_liter_per_minute: {
        return value * 1.6666666667e-8;
      }
      case PressureRateDerivativeUnit.Kilo_Pascal_per_us_gallon_per_minute: {
        return value * 6.3090196565e-8;
      }
      case PressureRateDerivativeUnit.Pound_per_square_inch_per_cubic_meter_per_second: {
        return value * 1.45037738e-4;
      }
      case PressureRateDerivativeUnit.Pound_per_square_inch_per_cubic_meter_per_minute: {
        return value * 2.4172956333e-6;
      }
      case PressureRateDerivativeUnit.Pound_per_square_inch_per_cubic_foot_per_minute: {
        return value * 6.8449866675e-8;
      }
      case PressureRateDerivativeUnit.Pound_per_square_inch_per_cubic_foot_per_second: {
        return value * 4.1070113795e-6;
      }
      case PressureRateDerivativeUnit.Pound_per_square_inch_per_barrel_per_minute: {
        return value * 3.8431929402e-7;
      }
      case PressureRateDerivativeUnit.Pound_per_square_inch_per_liter_per_minute: {
        return value * 2.4172956333e-9;
      }
      case PressureRateDerivativeUnit.Pound_per_square_inch_per_us_gallon_per_minute: {
        return value * 9.1504593998e-9;
      }
      case PressureRateDerivativeUnit.Kilogram_per_square_centimeter_per_cubic_meter_per_second: {
        return value * 1.019716213e-5;
      }
      case PressureRateDerivativeUnit.Kilogram_per_square_centimeter_per_cubic_meter_per_minute: {
        return value * 1.6995270217e-7;
      }
      case PressureRateDerivativeUnit.Kilogram_per_square_centimeter_per_cubic_foot_per_minute: {
        return value * 4.8125018901e-9;
      }
      case PressureRateDerivativeUnit.Kilogram_per_square_centimeter_per_cubic_foot_per_second: {
        return value * 2.8875147588e-7;
      }
      case PressureRateDerivativeUnit.Kilogram_per_square_centimeter_per_barrel_per_minute: {
        return value * 2.7020320399e-8;
      }
      case PressureRateDerivativeUnit.Kilogram_per_square_centimeter_per_liter_per_minute: {
        return value * 1.6995270217e-10;
      }
      case PressureRateDerivativeUnit.Kilogram_per_square_centimeter_per_us_gallon_per_minute: {
        return value * 6.4334096319e-10;
      }
      case PressureRateDerivativeUnit.Bar_per_cubic_meter_per_second: {
        return value * 1.0e-5;
      }
      case PressureRateDerivativeUnit.Bar_per_cubic_meter_per_minute: {
        return value * 1.6666666667e-7;
      }
      case PressureRateDerivativeUnit.Bar_per_cubic_foot_per_minute: {
        return value * 4.719452166e-9;
      }
      case PressureRateDerivativeUnit.Bar_per_cubic_foot_per_second: {
        return value * 2.8316846609e-7;
      }
      case PressureRateDerivativeUnit.Bar_per_barrel_per_minute: {
        return value * 2.6497882504e-8;
      }
      case PressureRateDerivativeUnit.Bar_per_liter_per_minute: {
        return value * 1.6666666667e-10;
      }
      case PressureRateDerivativeUnit.Bar_per_us_gallon_per_minute: {
        return value * 6.3090196565e-10;
      }

      case PressureRateDerivativeUnit.MPa_per_cubic_meter_per_second: {
        return value * 1.0e-6;
      }
      case PressureRateDerivativeUnit.MPa_per_cubic_meter_per_minute: {
        return value * 6.0e-5;
      }
      case PressureRateDerivativeUnit.MPa_per_cubic_foot_per_minute: {
        return value * 1.6990107955e-6;
      }
      case PressureRateDerivativeUnit.MPa_per_cubic_foot_per_second: {
        return value * 2.8316846592e-8;
      }
      case PressureRateDerivativeUnit.MPa_per_barrel_per_minute: {
        return value * 9.53922e-6;
      }
      case PressureRateDerivativeUnit.MPa_per_liter_per_minute: {
        return value * 6.0e-8;
      }
      case PressureRateDerivativeUnit.MPa_per_us_gallon_per_minute: {
        return value * 2.271246e-7;
      }

      default: {
        throw new TypeError('Unknown Pressure Rate Derivative Unit:' + unit);
      }
    }
  }

  public static toSi(value: number, unit: PressureRateDerivativeUnit): number {
    switch (unit) {
      case PressureRateDerivativeUnit.Pascal_per_cubic_meter_per_second: {
        return value;
      }
      case PressureRateDerivativeUnit.Pascal_per_cubic_meter_per_minute: {
        return value / 1.6666666667e-2;
      }
      case PressureRateDerivativeUnit.Pascal_per_cubic_foot_per_minute: {
        return value / 4.719452166e-4;
      }
      case PressureRateDerivativeUnit.Pascal_per_cubic_foot_per_second: {
        return value / 2.8316846609e-2;
      }
      case PressureRateDerivativeUnit.Pascal_per_barrel_per_minute: {
        return value / 2.6497882504e-3;
      }
      case PressureRateDerivativeUnit.Pascal_per_liter_per_minute: {
        return value / 1.6666666667e-5;
      }
      case PressureRateDerivativeUnit.Pascal_per_us_gallon_per_minute: {
        return value / 6.3090196565e-5;
      }
      case PressureRateDerivativeUnit.Kilo_Pascal_per_cubic_meter_per_second: {
        return value / 1.0e-3;
      }
      case PressureRateDerivativeUnit.Kilo_Pascal_per_cubic_meter_per_minute: {
        return value / 1.6666666667e-5;
      }
      case PressureRateDerivativeUnit.Kilo_Pascal_per_cubic_foot_per_minute: {
        return value / 4.719452166e-7;
      }
      case PressureRateDerivativeUnit.Kilo_Pascal_per_cubic_foot_per_second: {
        return value / 2.8316846609e-5;
      }
      case PressureRateDerivativeUnit.Kilo_Pascal_per_barrel_per_minute: {
        return value / 2.6497882504e-6;
      }
      case PressureRateDerivativeUnit.Kilo_Pascal_per_liter_per_minute: {
        return value / 1.6666666667e-8;
      }
      case PressureRateDerivativeUnit.Kilo_Pascal_per_us_gallon_per_minute: {
        return value / 6.3090196565e-8;
      }
      case PressureRateDerivativeUnit.Pound_per_square_inch_per_cubic_meter_per_second: {
        return value / 1.45037738e-4;
      }
      case PressureRateDerivativeUnit.Pound_per_square_inch_per_cubic_meter_per_minute: {
        return value / 2.4172956333e-6;
      }
      case PressureRateDerivativeUnit.Pound_per_square_inch_per_cubic_foot_per_minute: {
        return value / 6.8449866675e-8;
      }
      case PressureRateDerivativeUnit.Pound_per_square_inch_per_cubic_foot_per_second: {
        return value / 4.1070113795e-6;
      }
      case PressureRateDerivativeUnit.Pound_per_square_inch_per_barrel_per_minute: {
        return value / 3.8431929402e-7;
      }
      case PressureRateDerivativeUnit.Pound_per_square_inch_per_liter_per_minute: {
        return value / 2.4172956333e-9;
      }
      case PressureRateDerivativeUnit.Pound_per_square_inch_per_us_gallon_per_minute: {
        return value / 9.1504593998e-9;
      }
      case PressureRateDerivativeUnit.Kilogram_per_square_centimeter_per_cubic_meter_per_second: {
        return value / 1.019716213e-5;
      }
      case PressureRateDerivativeUnit.Kilogram_per_square_centimeter_per_cubic_meter_per_minute: {
        return value / 1.6995270217e-7;
      }
      case PressureRateDerivativeUnit.Kilogram_per_square_centimeter_per_cubic_foot_per_minute: {
        return value / 4.8125018901e-9;
      }
      case PressureRateDerivativeUnit.Kilogram_per_square_centimeter_per_cubic_foot_per_second: {
        return value / 2.8875147588e-7;
      }
      case PressureRateDerivativeUnit.Kilogram_per_square_centimeter_per_barrel_per_minute: {
        return value / 2.7020320399e-8;
      }
      case PressureRateDerivativeUnit.Kilogram_per_square_centimeter_per_liter_per_minute: {
        return value / 1.6995270217e-10;
      }
      case PressureRateDerivativeUnit.Kilogram_per_square_centimeter_per_us_gallon_per_minute: {
        return value / 6.4334096319e-10;
      }
      case PressureRateDerivativeUnit.Bar_per_cubic_meter_per_second: {
        return value / 1.0e-5;
      }
      case PressureRateDerivativeUnit.Bar_per_cubic_meter_per_minute: {
        return value / 1.6666666667e-7;
      }
      case PressureRateDerivativeUnit.Bar_per_cubic_foot_per_minute: {
        return value / 4.719452166e-9;
      }
      case PressureRateDerivativeUnit.Bar_per_cubic_foot_per_second: {
        return value / 2.8316846609e-7;
      }
      case PressureRateDerivativeUnit.Bar_per_barrel_per_minute: {
        return value / 2.6497882504e-8;
      }
      case PressureRateDerivativeUnit.Bar_per_liter_per_minute: {
        return value / 1.6666666667e-10;
      }
      case PressureRateDerivativeUnit.Bar_per_us_gallon_per_minute: {
        return value / 6.3090196565e-10;
      }

      case PressureRateDerivativeUnit.MPa_per_cubic_meter_per_second: {
        return value / 1.0e-6;
      }
      case PressureRateDerivativeUnit.MPa_per_cubic_meter_per_minute: {
        return value / 6.0e-5;
      }
      case PressureRateDerivativeUnit.MPa_per_cubic_foot_per_minute: {
        return value / 1.6990107955e-6;
      }
      case PressureRateDerivativeUnit.MPa_per_cubic_foot_per_second: {
        return value / 2.8316846592e-8;
      }
      case PressureRateDerivativeUnit.MPa_per_barrel_per_minute: {
        return value / 9.53922e-6;
      }
      case PressureRateDerivativeUnit.MPa_per_liter_per_minute: {
        return value / 6.0e-8;
      }
      case PressureRateDerivativeUnit.MPa_per_us_gallon_per_minute: {
        return value / 2.271246e-7;
      }
      default: {
        throw new TypeError('Unknown Pressure Rate Derivative Unit:' + unit);
      }
    }
  }

  public static getSymbol(unit: PressureRateDerivativeUnit): string {
    if (unit == null) {
      return '';
    }
    return this.symbols[unit];
  }
}
