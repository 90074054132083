import { WsAction } from '../../ws.action';
import { AbandonedTempFile, AbandonedTempFileHandling } from './temp-project-file.types';

export const TempProjectFileModuleName = 'TempProjectFileModule';

export enum TempProjectFileActionTypes {
  HandleAbandonedTempFiles = '[TempProjectFile] HandleAbandonedTempFiles',
}

export class HandleAbandonedTempFilesAction extends WsAction {
  public override readonly type = TempProjectFileActionTypes.HandleAbandonedTempFiles;

  constructor(public handling?: AbandonedTempFileHandling) {
    super();
  }
}

export type HandleAbandonedTempFilesActionResponse = AbandonedTempFile[];

export type TempProjectFileActionActions = HandleAbandonedTempFilesAction;
