import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { EnumHelpers } from '@dunefront/common/utils/enum.helpers';
import {
  ImportDataColumnDelimiterConfig,
  ImportFileColumnDelimiter,
} from '@dunefront/common/modules/data-storage/dto/import-template/import-template.dto';
import { changeProp, ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';
import { ImportDataValidation } from '../../../../+store/import-data/validation/import-data.validation';

@Component({
  selector: 'app-column-delimiter',
  templateUrl: './column-delimiter.component.html',
  styleUrls: ['./column-delimiter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColumnDelimiterComponent {
  @Input()
  public delimiterConfig!: ImportDataColumnDelimiterConfig;

  @Output()
  public delimiterConfigChange = new EventEmitter<ImportDataColumnDelimiterConfig>();
  public radioItems = EnumHelpers.EnumToRadioItems(ImportFileColumnDelimiter);

  public ImportFileColumnDelimiter = ImportFileColumnDelimiter;

  public onDelimiterChanged(event: ObjectChangeProp<ImportDataColumnDelimiterConfig>): void {
    const delimiter = event.value as ImportFileColumnDelimiter;
    const newDelimeterConfig = {
      ...this.delimiterConfig,
      delimiter,
      ...(delimiter !== ImportFileColumnDelimiter.Custom && { customDelimiter: '' }),
    };
    this.delimiterConfigChange.emit(ImportDataValidation.validateColumnDelimiterConfig(newDelimeterConfig));
  }

  public onCustomDelimiterChanged(change: ObjectChangeProp<ImportDataColumnDelimiterConfig>): void {
    const newDelimeterConfig = changeProp(this.delimiterConfig, change);
    this.delimiterConfigChange.emit(ImportDataValidation.validateColumnDelimiterConfig(newDelimeterConfig));
  }
}
