import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { DbDependentComponent } from '../../db-connection/db-dependent.component';
import {
  addChartTabConfigureSeries,
  deleteChartTemplatesAction,
  loadChartTemplates,
  newReportingChartTabsFromTemplates,
} from '../../../+store/reporting/reporting.actions';
import { NewChartProps } from '../../../+store/menu-selectors/chart/menu-chart.selector';
import { take } from 'rxjs/operators';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { IChartTemplateDto } from '@dunefront/common/dto/chart-templates.dto';
import { getAllChartTemplates, getAllSortedChartTemplates, getFilteredChartTemplates } from '../../../+store/reporting/reporting.selectors';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import { DataSourceValue, PrimitiveChangeValue } from '@dunefront/common/common/common-state.interfaces';
import { decodeTemplateId, encodeTemplateId } from '@dunefront/common/common/templates/template-parser';
import { ModalService } from '../../modals/modal.service';
import { CommonDbType } from '@dunefront/common/dto/common-dto.interfaces';
import { SelectItemGroup } from 'primeng/api';
import {
  EditChartTemplateModalComponent,
  EditChartTemplateModalComponentPayload,
} from '../edit-template-modal/edit-chart-template-modal.component';
import { getAreCurrentResultsPresentAndCompleted } from '../../../+store/calculation-engine/calculation-engine-results.selectors';
import { noLabel } from '../../../shared/components/range-labels-selector/labels-selector.component';

type ChartTypeValue = 'timevol' | 'mdtvd';
type ChartCreateValue = 'blank' | 'template';

export interface IRadioItemEx<T extends boolean | string | number = string> {
  value: T;
  text: string;
  disabled?: boolean;
}

@Component({
  templateUrl: './reporting-chart-creation.component.html',
  styleUrls: ['./reporting-chart-creation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportingChartCreationComponent extends DbDependentComponent {
  @Input()
  public set newChartProps(value: NewChartProps) {
    this._newChartProps = value;
    this.chartTypeItems = this.buildChartTypeItems();
    this.cdRef.markForCheck();
  }

  public chartTypeSelectedValue$ = new BehaviorSubject<ChartTypeValue>('timevol');
  public areCurrentResultsPresentAndCompleted$ = this.store.select(getAreCurrentResultsPresentAndCompleted);

  public chartTypeItems: IRadioItemEx<ChartTypeValue>[] = this.buildChartTypeItems();
  public chartCreateSelectedValue: ChartCreateValue = 'template';
  public chartCreateItems: IRadioItemEx<ChartCreateValue>[] = [];
  private _newChartProps?: NewChartProps;

  public selectedChartTemplatesEncodedIds: string[] = [];
  public ModuleType = ModuleType;

  public templates: IChartTemplateDto[] = [];
  public templateItems: SelectItemGroup[] = [];
  public filterByName = '';
  public filterByLabels = '';
  public isHelpOpen = false;

  constructor(
    store: Store,
    cdRef: ChangeDetectorRef,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private modalService: ModalService,
  ) {
    super(store, cdRef);
    store
      .select(getAllSortedChartTemplates)
      .pipe(take(1))
      .subscribe((charts) => {
        // Checks only predefined because new template may be created and already be in a store
        if (charts.filter((chart) => chart.Type === 'Predefined').length === 0) {
          store.dispatch(loadChartTemplates());
        }
      });
    this.subscription.add(
      this.store.select(getFilteredChartTemplates).subscribe((templates) => {
        this.templates = templates;

        const selectedTemplates = this.getSelectedTemplates(this.selectedChartTemplatesEncodedIds);
        this.selectedChartTemplatesEncodedIds = selectedTemplates.map((t) => encodeTemplateId(t.Id, t.Type));

        this.setTemplateItems();
        this.chartCreateItems = [
          { value: 'template', text: 'Chart Template', disabled: this.templates.length === 0 },
          { value: 'blank', text: 'Blank Chart' },
        ];
        this.cdRef.markForCheck();
      }),
    );
    this.newChartProps = config.data.newChartProps;
    this.filterByLabels = this.currentRange?.Labels ?? '';
    this.setTemplateItems();
  }

  public onReportingChartSelection(reportingChartIds: string[]): void {
    if (reportingChartIds[0] && this.selectedChartTemplatesEncodedIds.find((id) => id === reportingChartIds[0])) {
      this.selectedChartTemplatesEncodedIds = this.selectedChartTemplatesEncodedIds.filter((id) => id !== reportingChartIds[0]);
    } else {
      this.selectedChartTemplatesEncodedIds = [...this.selectedChartTemplatesEncodedIds, reportingChartIds[0]];
    }
  }

  public cancelClicked(): void {
    this.ref.close();
  }

  public chartTypeValueChanged(event: PrimitiveChangeValue<ChartTypeValue>): void {
    this.selectedChartTemplatesEncodedIds = [];
    this.chartTypeSelectedValue$.next(event.value);
  }

  public chartCreateValueChanged(event: PrimitiveChangeValue<ChartCreateValue>): void {
    this.chartCreateSelectedValue = event.value;
  }

  public getSelectedTemplates(encodedTemplateIds: string[]): IChartTemplateDto[] {
    const decodedTemplates: { id: number; type: CommonDbType }[] = encodedTemplateIds.map((encodedTemplateId) =>
      decodeTemplateId(encodedTemplateId),
    );
    return this.templates.filter((tmpl) =>
      decodedTemplates.find((decodedTemplate) => decodedTemplate.id === tmpl.Id && decodedTemplate.type === tmpl.Type),
    );
  }

  public okClicked(): void {
    if (this.chartCreateSelectedValue === 'blank') {
      this.store.dispatch(addChartTabConfigureSeries({ chartType: this.chartTypeSelectedValue$.value }));
    } else if (this.selectedChartTemplatesEncodedIds.length) {
      const chartTemplates = this.getSelectedTemplates(this.selectedChartTemplatesEncodedIds);
      chartTemplates.length && this.store.dispatch(newReportingChartTabsFromTemplates({ chartTemplates }));
    }
    this.ref.close();
  }

  public get isEditDisabled(): boolean {
    return (
      this.selectedChartTemplatesEncodedIds.length !== 1 || decodeTemplateId(this.selectedChartTemplatesEncodedIds[0]).type === 'Predefined'
    );
  }

  public async onEditClick(): Promise<void> {
    if (this.selectedChartTemplatesEncodedIds.length !== 1) {
      return;
    }

    const template = this.getSelectedTemplates(this.selectedChartTemplatesEncodedIds)[0];
    if (template != null) {
      const allTemplates = await firstValueFrom(this.store.select(getAllChartTemplates));
      this.modalService.open<EditChartTemplateModalComponentPayload>(EditChartTemplateModalComponent, {
        template: { ...template },
        allTemplates,
      });
    }
  }

  public get isDeleteDisabled(): boolean {
    return (
      this.selectedChartTemplatesEncodedIds.length === 0 ||
      this.selectedChartTemplatesEncodedIds.some((encodedId: string) => decodeTemplateId(encodedId).type === 'Predefined')
    );
  }

  public async onDeleteClick(): Promise<void> {
    if (this.selectedChartTemplatesEncodedIds.length === 0) {
      return;
    }
    const result = await this.modalService.showConfirm('Are you sure you want to delete template(s)?', 'Delete template(s)');
    if (result) {
      this.store.dispatch(deleteChartTemplatesAction({ templatesIds: this.selectedChartTemplatesEncodedIds }));
      this.selectedChartTemplatesEncodedIds = [];
    }
  }

  public chartTemplatesEnabled(): boolean {
    return this.chartCreateSelectedValue === 'template';
  }

  private buildChartTypeItems(): IRadioItemEx<ChartTypeValue>[] {
    return [
      { value: 'timevol', text: 'Time/Volume' },
      { value: 'mdtvd', text: 'MD/TVD', disabled: this._newChartProps?.mdTvdDisabled },
    ];
  }

  public setTemplateItems(): void {
    const types = Array.from(new Set(this.templates.map((t) => t.Type)));

    const groups: { [key: string]: IChartTemplateDto[] } = {};

    types.forEach((type) => {
      groups[type] = this.templates.filter((t) => t.Type === type);
    });

    const items: any = [];

    const isNamePassing = (tpl: IChartTemplateDto): boolean =>
      this.filterByName ? tpl.Name.toLowerCase().includes(this.filterByName.toLowerCase()) : true;
    const isLabelPassing = (tpl: IChartTemplateDto): boolean => {
      if (!this.filterByLabels) {
        return true;
      }
      const includeTemplatesWithoutLabels = this.filterByLabels.includes(noLabel);

      const filterLabels = this.filterByLabels.split(',');
      const isTemplateContainsLabel = tpl.Labels.split(',').some((label) => filterLabels.includes(label));

      const isTemplateNoLabels = tpl.Labels.length === 0 || tpl.Labels === noLabel;

      if (includeTemplatesWithoutLabels) {
        return isTemplateContainsLabel || isTemplateNoLabels;
      } else {
        return isTemplateContainsLabel;
      }
    };

    Object.keys(groups)
      .sort((t1, t2) => (t1 === 'User' || (t1 === 'Organization' && t2 === 'Predefined') ? -1 : 1))
      .forEach((key) => {
        const node = {
          label: key,
          value: null,
          items: groups[key]
            .filter((tpl) => isLabelPassing(tpl) && isNamePassing(tpl))
            .map((tpl) => {
              // icon is used to store a chart type. PrimeNg doesn't provide any customizable variable to use.
              return {
                label: tpl.Name,
                value: `${tpl.Id}_${tpl.Type}`,
                icon: tpl.IsTimeVolume ? 'Time/Volume' : 'MD/TVD',
              };
            }),
        };

        if (node.items.length) {
          items.push(node);
        }
      });

    this.templateItems = items;
  }

  public clearFilters(): void {
    this.filterByLabels = '';
    this.filterByName = '';
    this.setTemplateItems();
  }

  public onNameFilterChanged(event: PrimitiveChangeValue<DataSourceValue<{ value: string }>>): void {
    if (this.filterByName === event.value) {
      return;
    }

    this.filterByName = event.value;
    this.setTemplateItems();
  }

  public onLabelsFilterChanged(event: string): void {
    this.filterByLabels = event;
    this.setTemplateItems();
  }

  public onSelectAll(): void {
    const allTemplatesIds: string[] = [];
    for (const templateGroup of this.templateItems) {
      allTemplatesIds.push(...templateGroup.items.map((item) => item.value));
    }

    this.selectedChartTemplatesEncodedIds = allTemplatesIds;
  }

  public onClearAll(): void {
    this.selectedChartTemplatesEncodedIds = [];
  }

  public onHelpClick(isHelpOpen: boolean): void {
    this.isHelpOpen = isHelpOpen;
  }
}
