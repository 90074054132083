import { Component, Input } from '@angular/core';
import { ISchematicData } from '../schematic.component';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';

@Component({
  selector: 'app-schematic-data',
  templateUrl: './schematic-data.component.html',
  styleUrls: ['./schematic-data.component.scss'],
})
export class SchematicDataComponent {
  @Input() public title!: string;
  @Input() public data!: ISchematicData;
  public UnitType = UnitSystem;
}
