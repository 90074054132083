import { createAction, props } from '@ngrx/store';
import { CompletionModuleState } from '@dunefront/common/modules/completion/completion-module.state';
import { IcdPortDataDto } from '@dunefront/common/dto/icd-port-data.dto';
import { CompletionDto } from '@dunefront/common/modules/completion/dto/completion.dto';
import { Pipe } from '@dunefront/common/modules/pipes/pipe';
import { ShuntTube } from '@dunefront/common/modules/shunt-tube/shunt-tube';
import { SectionCalculationType } from '@dunefront/common/modules/completion/completion-module.actions';
import { ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';
import { IDeleteRowsProps, IInsertRowsProps, IUpdateTableRowsProps } from '@dunefront/common/common/common-store-crud.interfaces';
import { SectionInputs } from '@dunefront/common/modules/completion/dto/section-calculator.dto';

export const updateCompletion = createAction(
  '[completion] saveCompletion',
  props<{ completion: CompletionModuleState; shouldResetResults: boolean; changedKey?: keyof CompletionDto }>(),
);

// region lowerCompletion grid

export const updateLowerCompletionRow = createAction('[lowerCompletion] updateRows', props<IUpdateTableRowsProps<Pipe>>());

export const insertLowerCompletionRow = createAction('[lowerCompletion] insertRows', props<IInsertRowsProps<Pipe>>());

export const deleteLowerCompletionRows = createAction('[lowerCompletion] deleteRows', props<IDeleteRowsProps>());

// endregion

// region IcdPortData grid

export const updateIcdPortDataRow = createAction('[icdportdata] updateRows', props<IUpdateTableRowsProps<IcdPortDataDto>>());

export const insertIcdPortDataRow = createAction('[icdportdata] insertRows', props<IInsertRowsProps<IcdPortDataDto>>());

export const deleteIcdPortDataRow = createAction('[icdportdata] deleteRows', props<IDeleteRowsProps>());

// endregion

// region runningString grid

export const updateRunningStringRow = createAction('[runningString] updateRows', props<IUpdateTableRowsProps<Pipe>>());

export const insertRunningStringRow = createAction('[runningString] insertRows', props<IInsertRowsProps<Pipe>>());

export const deleteRunningStringRows = createAction('[runningString] deleteRows', props<IDeleteRowsProps>());

export const reloadRunningStringAction = createAction(
  '[runningString] reloadRunningString',
  props<{
    scenarioId: number;
  }>(),
);

// endregion

export const changeCompletionProperty = createAction(
  '[completion] changeCompletionProperty',
  props<ObjectChangeProp<CompletionModuleState>>(),
);

// region Shunt Tube

export const changeShuntTubeProperty = createAction('[shunt-tube] changeShuntTubeProperty', props<ObjectChangeProp<ShuntTube>>());

// endregion

// region Volumes

export const resetVolumeCalculatorResults = createAction('[completion] CalculateVolumesSectionAction');

export const calculateVolumesSectionAction = createAction(
  '[completion] CalculateVolumesSectionAction',
  props<{ calculationType: SectionCalculationType }>(),
);

export const calculateVolumesSectionAbortedAction = createAction(
  '[completion] CalculateVolumesSectionAbortedAction',
  props<{ calculationType: SectionCalculationType }>(),
);

export const calculateVolumesSectionUpdateAction = createAction(
  '[completion] CalculateVolumesSectionUpdateAction',
  props<{ calculationType: SectionCalculationType; sectionInputs: SectionInputs }>(),
);

// endregion
