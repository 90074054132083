<div class="grid-container">
  <app-page-panel header="This is a test component">
    <app-form-component></app-form-component>
  </app-page-panel>
  <app-page-panel header="This is a test component">
    <app-form-component></app-form-component>
  </app-page-panel>
  <app-page-panel header="This is a test component">
    <app-form-component></app-form-component>
  </app-page-panel>
  <app-page-panel header="This is a test component">
    <app-form-component></app-form-component>
  </app-page-panel>
  <app-page-panel header="This is a test component">
    <app-form-component></app-form-component>
  </app-page-panel>
  <app-page-panel header="This is a test component">
    <app-form-component></app-form-component>
  </app-page-panel>
  <app-page-panel header="This is a test component">
    <app-form-component></app-form-component>
  </app-page-panel>
  <app-page-panel header="This is a test component">
    <app-form-component></app-form-component>
  </app-page-panel>
</div>
