import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { GenericOptionsComponent } from '../generic-options.component';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-chart-gradient-line-options',
  templateUrl: './chart-gradient-line-options.component.html',
  styleUrls: ['./chart-gradient-line-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartGradientLineOptionsComponent extends GenericOptionsComponent {
  constructor(store: Store, cdRef: ChangeDetectorRef) {
    super(store, cdRef);
  }
}
