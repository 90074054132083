import { ArrayHelpers } from '../common/array-helpers';
import { ISelectItem } from '../common/select.helpers';
import { IRadioItem } from '../common/radio.helpers';
import { DataSourceValue } from '../common/common-state.interfaces';

export type EnumValue<T> = T[Extract<keyof T, string>];

export class EnumHelpers {
  // not working for string enums
  public static EnumToRadioItems<T>(
    enumObj: T,
    values?: EnumValue<T>[],
    excludeValues?: EnumValue<T>[],
    translationDict?: Map<EnumValue<T>, string>,
  ): IRadioItem<DataSourceValue<T>>[] {
    const radioItems: IRadioItem<DataSourceValue<T>>[] = [];

    for (const n in enumObj) {
      if (typeof enumObj[n] === 'number') {
        const value = enumObj[n];
        if ((!values || values.includes(value)) && (!excludeValues || !excludeValues.includes(value))) {
          radioItems.push({ value, text: translationDict?.get(value) ?? this.enumToText(n), disabled: false });
        }
      }
    }

    return radioItems;
  }

  public static enumToText<T extends string>(enumObj: T): string {
    return enumObj.replace(/_/g, ' ');
  }

  public static EnumToISelectItemArray<T>(
    enumObj: T,
    autoSort = true,
    values?: EnumValue<T>[],
    excludeValues?: EnumValue<T>[],
    translationDict?: Map<EnumValue<T>, string>,
  ): ISelectItem<DataSourceValue<T>>[] {
    const items: IRadioItem<DataSourceValue<T>>[] = this.EnumToRadioItems(enumObj, values, excludeValues, translationDict).map((item) => ({
      value: item.value,
      text: item.text,
    }));
    if (autoSort) {
      items.sort((a, b) => ArrayHelpers.stringCompare(a.text, b.text));
    }
    return items as ISelectItem<DataSourceValue<T>>[];
  }

  public static StringEnumToSelectItemArray(enumObj: { [key: string]: any }): ISelectItem<string>[] {
    return Object.keys(enumObj).map((key) => ({ value: key, text: key }));
  }

  public static StringEnumToArray(enumObj: { [key: string]: any }): string[] {
    return Object.keys(enumObj).map((key) => key);
  }

  public static getEnumName(enumObj: any, enumValue: number): string | undefined {
    const enumKeys = Object.keys(enumObj).filter((key) => isNaN(Number(key)));
    for (const key of enumKeys) {
      if (enumObj[key] === enumValue) {
        return key.replace(/_/g, ' ');
      }
    }
    return undefined;
  }
}
