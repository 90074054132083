import { Injectable } from '@angular/core';
import {
  CloneScenarioAction,
  CopyScenarioRowAction,
  CreateScenarioAction,
  DeleteScenarioRowsAction,
  ScenarioModuleName,
  UpdateScenarioRowAction,
} from '@dunefront/common/modules/scenario/scenario-module.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Action, Store } from '@ngrx/store';
import { BackendConnectionService } from '../../shared/backend-connection/backend-connection.service';
import { BaseWsEffects } from '../base-ws.effects';
import * as actions from './scenario.actions';
import {
  compareScenariosChangeConfigAction,
  compareScenariosOpenConfigPanelAction,
  copyPumpingBetweenRangesSuccess,
  updateScenariosAction,
} from './scenario.actions';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { CrudResponse } from '@dunefront/common/modules/common.actions';
import { dataFailed, deleteRowsSuccess, loadScenarioDataAction, updateRowSuccess } from '../app.actions';
import { filter, of } from 'rxjs';
import { getAllScenarios, getCurrentScenario, getCurrentScenarioId } from './scenario.selectors';
import { ModalService } from '../../common-modules/modals/modal.service';
import { RangeConstants } from '@dunefront/common/dto/range.dto';
import { removeDataResultsFromStoreIfNeeded } from '../data-storage/data-storage.actions';
import {
  clearEvaluateScheduleAction,
  reloadPumpingAction,
  reloadPumpingScheduleAction,
  reloadWellFluidsAction,
} from '../pumping/pumping.actions';
import { RouterHelperService } from '../../shared/services/router-helper.service';
import { ModuleType, ScenarioDto } from '@dunefront/common/modules/scenario/scenario.dto';
import { calculateVolumesSectionAction } from '../completion/completion.actions';
import { getCurrentAppModuleType } from '../ui/ui.selectors';
import { isEvaluate } from '../menu-selectors/menu-selectors.helpers';
import { isCompareScenarioActiveAction, navigateToScenarioAction } from '../reporting/reporting.actions';
import { getCloningDeleteResultsFilter } from '@dunefront/common/common/scenario-manager/scenario-manager.helpers';
import { ScenarioFactory } from '@dunefront/common/modules/scenario/scenario';
import { changePropValue } from '@dunefront/common/common/common-state.interfaces';
import { CompareScenarioComponent } from '../../common-modules/modals/compare-scenario/compare-scenario.component';
import { getFileHash } from '../backend-connection/backend-connection.selectors';
import { getCurrentRangeId } from '../range/range.selectors';

@Injectable()
export class ScenarioEffects extends BaseWsEffects {
  constructor(
    actions$: Actions,
    store: Store,
    wsService: BackendConnectionService,
    modalService: ModalService,
    private routerHelperService: RouterHelperService,
  ) {
    super(actions$, wsService, ScenarioModuleName, false, true, modalService, store);
  }

  public updateScenarioRow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.updateScenariosAction),
      concatLatestFrom(() => this.store.select(getCurrentAppModuleType)),
      mergeMap(([action, moduleType]) =>
        this.emit<CrudResponse>(
          new UpdateScenarioRowAction({
            rows: action.scenarios.map((scenario) => ScenarioFactory.toDto(scenario)),
            shouldResetResults: true,
            colIds: [action.changedKey as keyof ScenarioDto],
            scenarioId: -1,
          }),
        ).pipe(
          mergeMap((result) => {
            const successActions: Action[] = [updateRowSuccess(result.payload), clearEvaluateScheduleAction()];
            if (action.changedKey === 'CurrentRangeId' && action.scenarios.length === 1) {
              if (isEvaluate(moduleType)) {
                successActions.push(calculateVolumesSectionAction({ calculationType: 'multiple' }));
              }
              this.routerHelperService.navigateToScenarioAndRange(action.scenarios[0].Id, action.scenarios[0].CurrentRangeId).then();
            }
            return successActions;
          }),
          catchError((err) => of(dataFailed(err))),
        ),
      ),
    ),
  );

  public createScenario$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.createScenarioAction),
      mergeMap((action) => this.emitInsert(new CreateScenarioAction(action.name))),
    ),
  );

  public deleteScenarioRow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.deleteScenarioAction),
      mergeMap((action) => this.emitDelete(new DeleteScenarioRowsAction(action), 'Information', 'Scenarios are being deleted')),
    ),
  );

  public deleteScenarioSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteRowsSuccess),
      concatLatestFrom(() => [this.store.select(getFileHash), this.store.select(getCurrentScenarioId)]),
      map(([action, fileHash, currentScenarioId]) => ({
        scenarioIds: (action.affectedRows.scenario?.deletedIds ?? []).map((scenarioId) => +scenarioId),
        fileHash,
        currentScenarioId,
      })),
      filter(({ scenarioIds, fileHash, currentScenarioId }) => scenarioIds.length > 0 && fileHash != null),
      map(({ scenarioIds, fileHash, currentScenarioId }) =>
        removeDataResultsFromStoreIfNeeded({
          fileHash: fileHash as string,
          deleteResultsFilter: { scenarioIds },
          currentData: { currentScenarioId },
        }),
      ),
    ),
  );

  public reorderScenarioRow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.reorderScenariosAction),
      concatLatestFrom(() => this.store.select(getAllScenarios)),
      mergeMap(([action, scenarios]) =>
        this.emitUpdate(
          new UpdateScenarioRowAction({
            rows: scenarios.map((scenario) => ScenarioFactory.toDto(scenario)),
            shouldResetResults: true,
            colIds: [action.changedKey as keyof ScenarioDto],
            scenarioId: -1,
          }),
        ),
      ),
    ),
  );

  public copyScenarioRow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.copyScenarioAction),
      mergeMap((action) => {
        return this.emitInsert(new CopyScenarioRowAction(action.scenarioId, action.scenarioName), 'Copying Scenario', 'Scenario Manager');
      }),
    ),
  );

  public cloneScenarioRow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.cloneScenarioAction),
      mergeMap((action) =>
        this.emitUpdate(
          new CloneScenarioAction(action.sourceScenarioId, action.targetScenarioIds, action.entitiesToCopy, action.chartsToCopy),
          'Cloning Scenario',
          'Scenario Manager',
        ),
      ),
    ),
  );

  public cloneScenarioSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateRowSuccess),
        filter((action) => action.affectedRows.cloneSuccess != null),
        concatLatestFrom(() => [
          this.store.select(getCurrentAppModuleType),
          this.store.select(getCurrentScenarioId),
          this.store.select(getCurrentRangeId),
        ]),
        tap(([action, currentAppModuleType, currentScenarioId, currentRangeId]) => {
          this.modalService.showAlert('Data cloning has finished successfully.', 'Information').then();

          const result = action.affectedRows.cloneSuccess;
          if (result == null) {
            return;
          }

          const deleteResultsFilter = getCloningDeleteResultsFilter(
            result.targetScenarioIds,
            result.entitiesToCopy,
            RangeConstants.EmptyRangeId,
          );

          if (deleteResultsFilter != null) {
            this.store.dispatch(
              removeDataResultsFromStoreIfNeeded({
                fileHash: result.fileHash,
                deleteResultsFilter,
                currentData: { currentAppModuleType, currentScenarioId, currentRangeId },
              }),
            );
          }
          if (result.targetScenarioIds.includes(currentScenarioId)) {
            this.store.dispatch(
              loadScenarioDataAction({
                scenarioId: currentScenarioId,
                rangeId: currentRangeId,
              }),
            );
          }
        }),
      ),
    { dispatch: false },
  );

  public copyPumpingBetweenRangesAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.copyPumpingBetweenRangesAction),
      concatLatestFrom(() => [
        this.store.select(getCurrentAppModuleType),
        this.store.select(getCurrentScenarioId),
        this.store.select(getCurrentRangeId),
      ]),
      mergeMap(([action, currentAppModuleType, currentScenarioId, currentRangeId]) => {
        return this.emit<CrudResponse>(
          new CloneScenarioAction(
            currentScenarioId,
            [currentScenarioId],
            action.entitiesToCopy,
            [],
            action.sourceRangeId,
            action.targetRangeId,
            action.entitiesExtras,
          ),
        ).pipe(
          mergeMap((result) => [
            copyPumpingBetweenRangesSuccess(result.payload),
            removeDataResultsFromStoreIfNeeded({
              fileHash: action.fileHash,
              deleteResultsFilter: {
                moduleTypes:
                  action.targetRangeId === RangeConstants.EmptyRangeId
                    ? [ModuleType.Simulate, ModuleType.Simulate_CH, ModuleType.Simulate_Disp]
                    : [ModuleType.Evaluate],
                scenarioIds: [currentScenarioId],
                rangeIds: [action.targetRangeId],
              },
              currentData: { currentAppModuleType, currentScenarioId, currentRangeId },
            }),
          ]),
          catchError((err) => of(dataFailed(err))),
        );
      }),
    ),
  );

  public copyPumpingBetweenRangesSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.copyPumpingBetweenRangesSuccess),
      mergeMap((result) => {
        return [
          reloadPumpingAction({
            scenarioId: result.scenarioId,
            rangeId: result.rangeId ?? -1,
          }),
          reloadWellFluidsAction({
            scenarioId: result.scenarioId,
            rangeId: result.rangeId ?? -1,
          }),
          reloadPumpingScheduleAction({ scenarioId: result.scenarioId }),
        ];
      }),
    ),
  );

  public redirectToFirstScenarioWhenNeededAfterDelete = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteRowsSuccess),
      concatLatestFrom(() => [this.store.select(getCurrentScenarioId), this.store.select(getAllScenarios)]),
      filter(
        ([response, currentScenarioId]): boolean =>
          response.affectedRows.scenario != null && response.affectedRows.scenario.deletedIds.includes(currentScenarioId),
      ),
      map(([, , allScenarios]) => navigateToScenarioAction({ scenarioId: allScenarios[0].Id })),
    ),
  );

  public compareScenariosOpenConfigPanelAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(compareScenariosOpenConfigPanelAction),
        tap((action) => this.modalService.open(CompareScenarioComponent, action, 'compare-scenario-modal')),
      ),
    { dispatch: false },
  );

  public compareScenariosChangeConfigAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(compareScenariosChangeConfigAction),
      concatLatestFrom(() => [this.store.select(getCurrentScenario)]),
      switchMap(([action, scenario]) => {
        const anyScenariosSelected = action.scenarioIds.length > 0;
        // If any scenarios selected than activate for current or all(depends how we got here).
        // If none selected than deactivate for all.
        return [
          updateScenariosAction({
            scenarios: scenario ? [changePropValue(scenario, 'CompareScenarioIds', action.scenarioIds)] : [],
            changedKey: 'CompareScenarioIds',
          }),
          isCompareScenarioActiveAction({
            batchAction: anyScenariosSelected ? action.batchAction : true,
            isCompareScenarioActive: anyScenariosSelected,
          }),
        ];
      }),
    ),
  );
}
