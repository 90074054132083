import { createReducer, on } from '@ngrx/store';
import { calculationProgressUpdatedAction, redisConnectionStatusChangedAction, userJobsUpdatedAction } from './calculation-engine.actions';
import { CalculationEngineReducerHelper } from './calculation-engine.reducer.helper';
import { calculationEngineInitialState, CalculationEngineState } from './calculation-engine-module.state';
import { dbConnectedSuccessAction } from '../backend-connection/backend-connection.actions';
import { removeDataResultsFromStoreIfNeeded } from '../data-storage/data-storage.actions';

export const calculationEngineModuleReducer = createReducer<CalculationEngineState>(
  calculationEngineInitialState,

  on(
    userJobsUpdatedAction,
    (state, { payload }): CalculationEngineState => CalculationEngineReducerHelper.userJobsUpdated(state, payload.userJobs),
  ),

  on(dbConnectedSuccessAction, (state, action) => CalculationEngineReducerHelper.onDbConnectedAction(state, action)),

  on(
    redisConnectionStatusChangedAction,
    (state, action): CalculationEngineState => ({
      ...state,
      redisConnectionStatus: action.status,
    }),
  ),

  on(
    calculationProgressUpdatedAction,
    (state, action): CalculationEngineState => CalculationEngineReducerHelper.updateJob(state, action.jobPayload, action.currentFileHash),
  ),

  on(removeDataResultsFromStoreIfNeeded, (state, action) =>
    CalculationEngineReducerHelper.onDeleteResultsSuccessAction(state, action.fileHash, action.deleteResultsFilter),
  ),
);
