<div class="notification" [attr.data-cy]="'notification-'+notification.id">
  <h3>{{ notification.title }}</h3>
  <p class="m-0">
    {{ notification.description }}
  </p>

  <div style="display: flex; justify-content: space-between; padding-top: 1rem">
          <span >
            <ng-container *ngIf="showExtraButtons">
            <button [isUiLockable]="false" appButton type="button"
                    class="btn btn-primary  highlight btn-sm " (click)="buttonClicked.emit(NotificationStatus.DISMISSED)">Dismiss</button>
            <button [isUiLockable]="false" appButton type="button" class="btn btn-cancel  btn-sm"
                    style="margin-left: .5rem" (click)="buttonClicked.emit(NotificationStatus.REMIND_LATER)">
              Remind me later
            </button>
              </ng-container>
          </span>
    <button [isUiLockable]="false" appButton type="button" class="btn btn-primary btn-sm"
            (click)="buttonClicked.emit(NotificationStatus.OPENED)">
      Open
    </button>
  </div>
</div>
