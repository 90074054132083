import { LoadInjectionTestCalculatorActionResponse } from '@dunefront/common/modules/calculators/injection-test-calculator/injection-test-calculator-module.action';
import { createTableState, ITableState } from '@dunefront/common/common/common-grid.interfaces';
import { CrudResponse } from '@dunefront/common/modules/common.actions';
import { deleteObjectsFromArray } from '@dunefront/common/common/state.helpers';
import {
  InjectionTestCalculatorInitialState,
  InjectionTestCalculatorModuleState,
} from '@dunefront/common/modules/calculators/injection-test-calculator/injection-test-calculator-module.state';
import { InjectionTestCalculatorDto } from '@dunefront/common/modules/calculators/injection-test-calculator/injection-test-calculator.dto';
import { IUpdateRowsProps } from '@dunefront/common/common/common-store-crud.interfaces';
import { InjectionTestReadingFactory } from '@dunefront/common/modules/calculators/injection-test-calculator/model/injection-test-reading.factory';
import { InjectionTestReading } from '@dunefront/common/modules/calculators/injection-test-calculator/model/injection-test-reading';
import { DeleteResultsFilter, ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import { InjectionTestCalculatorFactory } from '@dunefront/common/modules/calculators/injection-test-calculator/model/injection-test-calculator.factory';
import { ActionResponse } from '@dunefront/common/response-ws.action';

export class InjectionTestCalculatorReducerHelper {
  public static onLoadScenarioDataSuccessAction(
    state: InjectionTestCalculatorModuleState,
    response: ActionResponse<LoadInjectionTestCalculatorActionResponse>,
  ): InjectionTestCalculatorModuleState {
    if (response.status !== 'ok' || !response.payload) {
      return InjectionTestCalculatorInitialState;
    }
    const { injectionTestCalculatorDto, injectionTestReadingsDto } = response.payload;
    const scenarioId = injectionTestCalculatorDto.ScenarioId;

    const injectionTestReadings = InjectionTestReadingFactory.createRowListFromDtos(injectionTestReadingsDto, scenarioId);

    return {
      ...state,
      InjectionTestCalculator: injectionTestCalculatorDto,
      InjectionTestReadings: createTableState(injectionTestReadings),
      isLoaded: true,
    };
  }

  public static insertInjectionTestReadingsRowSuccess(
    state: InjectionTestCalculatorModuleState,
    response: CrudResponse,
  ): InjectionTestCalculatorModuleState {
    if (!response.affectedRows.injectionTestReadings || !response.affectedRows.injectionTestCalculator) {
      return state;
    }
    const injectionTestReadings = createTableState<InjectionTestReading>(
      InjectionTestReadingFactory.createRowListFromDtos(
        response.affectedRows.injectionTestReadings.rows,
        response.affectedRows.injectionTestReadings.rows[0].ScenarioId,
      ),
    );
    return {
      ...state,
      InjectionTestReadings: injectionTestReadings,
      InjectionTestCalculator: response.affectedRows.injectionTestCalculator,
    };
  }

  public static deleteInjectionTestReadingsRowsSuccess(
    state: InjectionTestCalculatorModuleState,
    response: CrudResponse,
  ): InjectionTestCalculatorModuleState {
    if (!response.affectedRows.injectionTestReadings || !response.affectedRows.injectionTestCalculator) {
      return state;
    }

    const rows: ITableState<InjectionTestReading> = {
      ...state.InjectionTestReadings,
      rows: deleteObjectsFromArray(state.InjectionTestReadings.rows, response.affectedRows.injectionTestReadings.deletedIds),
    };

    return {
      ...state,
      InjectionTestReadings: rows,
      InjectionTestCalculator: response.affectedRows.injectionTestCalculator,
    };
  }

  public static updateInjectionTestReadingsRowSuccess(
    state: InjectionTestCalculatorModuleState,
    response: CrudResponse,
  ): InjectionTestCalculatorModuleState {
    if (!response.affectedRows.injectionTestReadings || !response.affectedRows.injectionTestCalculator) {
      return state;
    }

    const injectionTestReadings = createTableState<InjectionTestReading>(
      InjectionTestReadingFactory.createRowListFromDtos(
        response.affectedRows.injectionTestReadings.rows,
        response.affectedRows.injectionTestReadings.rows[0].ScenarioId,
      ),
    );

    return {
      ...state,
      InjectionTestReadings: injectionTestReadings,
      InjectionTestCalculator: response.affectedRows.injectionTestCalculator,
    };
  }

  public static onUpdateInjectionTestCalculatorSuccess(
    state: InjectionTestCalculatorModuleState,
    response: CrudResponse,
  ): InjectionTestCalculatorModuleState {
    if (!response.affectedRows.injectionTestCalculator) {
      return state;
    }
    return {
      ...state,
      InjectionTestCalculator: response.affectedRows.injectionTestCalculator,
    };
  }

  public static beforeUpdateInjectionTestCalculator(
    state: InjectionTestCalculatorModuleState,
    action: IUpdateRowsProps<InjectionTestCalculatorDto>,
  ): InjectionTestCalculatorModuleState {
    return { ...state, InjectionTestCalculator: action.rows[0] };
  }

  public static onRemoveDataResultsFromStore(
    state: InjectionTestCalculatorModuleState,
    deleteResultsFilter: DeleteResultsFilter,
  ): InjectionTestCalculatorModuleState {
    if (
      (deleteResultsFilter.moduleTypes != null && !deleteResultsFilter.moduleTypes.includes(ModuleType.Injection_Test_Calc)) ||
      (deleteResultsFilter.scenarioIds != null && !deleteResultsFilter.scenarioIds.includes(state.InjectionTestCalculator.ScenarioId))
    ) {
      return state;
    }
    return {
      ...state,
      InjectionTestCalculator: {
        ...state.InjectionTestCalculator,
        ...InjectionTestCalculatorFactory.cleanResultsPartial(),
      },
    };
  }
}
