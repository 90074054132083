<div *ngIf="parsedResult?.isParsed && dataSource && height > 0" class="preview-grid" [style.height.px]="height"
     data-cy="import-data-grid">
  <cdk-virtual-scroll-viewport [tvsItemSize]="20" [headerHeight]="79" [headerEnabled]="true" class="wrapper"
                               [style.height.px]="height">
    <table
      mat-table
      [dataSource]="dataSource"
      class="table table-sm table-bordered with-selection-col"
      [class.define-data-mode]="isInDefineDataMode"
    >
      <tr mat-header-row *matHeaderRowDef="colNames; sticky: true"></tr>
      <tr mat-row [class.alternate]="rowIndex % 2 === 0"
          *matRowDef="let row; columns: colNames; let rowIndex = index"></tr>
      <ng-container [matColumnDef]="col" *ngFor="let col of colNames; let cellIndex = index">
        <ng-container *matHeaderCellDef>
          <th
            mat-header-cell
            [class.disabled]="isColumnDisabled(cellIndex)"
            [class.hidden]="isColumnHidden(cellIndex)"
            [class.current-column]="isCurrentColumn(cellIndex)"
          >
            <div class="top-header center-text">
              <div class="ellipsis-wrapper">
                <span class="text-container" *ngIf="cellIndex === 0">Delta Time</span>
                <ng-container *ngIf="cellIndex > 0">
                  <app-check-box
                    [value]="isColumnIncluded(cellIndex)"
                    [id]="'import-col_' + cellIndex"
                    [enableTextOverflowEllipsis]="true"
                    (primitiveValueChanged)="onIgnoredColumnIdChanged(cellIndex, $event.value)"
                    [label]="getColName(cellIndex)"
                  >
                  </app-check-box>
                </ng-container>
              </div>
            </div>
              <div class="bottom-header" [title]="getColSymbol(cellIndex, false)"><span
                [innerHTML]="getColSymbol(cellIndex)"></span></div>
              <div class="ellipsis-wrapper original-values" [title]="getOriginalColName(cellIndex, false)">
                <div class="text-container" [innerHTML]="getOriginalColName(cellIndex)"></div>
              </div>
              <div class="ellipsis-wrapper original-values" [title]="getOriginalUnitName(cellIndex, false)">
                <div class="text-container" [innerHTML]="getOriginalUnitName(cellIndex)"></div>
              </div>
          </th>
        </ng-container>
        <ng-container *matCellDef="let row">
          <td
            mat-cell
            class="cell"
            [class.disabled]="isColumnDisabled(cellIndex)"
            [class.hidden]="isColumnHidden(cellIndex)"
            [class.current-column]="isCurrentColumn(cellIndex)"
          >
            <ng-container *ngIf="cellIndex === 0; else data">{{ getTimestamp(row) }}</ng-container>
            <ng-template #data>{{ row[cellIndex] }}</ng-template>
          </td>
        </ng-container>
      </ng-container>
    </table>
  </cdk-virtual-scroll-viewport>
</div>
