import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { DbDependentComponent } from '../../../../common-modules/db-connection/db-dependent.component';
import { SettingsModuleState } from '@dunefront/common/modules/settings/settings-module.state';
import { CompletionModuleState } from '@dunefront/common/modules/completion/completion-module.state';
import { PumpingStateByRange } from '@dunefront/common/modules/pumping/pumping-module.state';
import { updatePumpingAction } from '../../../../+store/pumping/pumping.actions';
import { PumpingDto } from '@dunefront/common/modules/pumping/dto/pumping.dto';
import { getValidatedPumpingModuleState } from '../../../../+store/pumping/selectors/pumping-validation.selectors';
import { ISelectItem } from '@dunefront/common/common/select.helpers';
import * as uiActions from '../../../../+store/ui/ui.actions';
import { isPumpingEnabled } from '../../../../+store/pumping/selectors/pumping.selectors';
import { Pumping } from '@dunefront/common/modules/pumping/model/pumping/pumping';
import { loadReferenceVariablesAction } from '../../../../+store/reference-variables/reference-variables.actions';
import { changeProp, ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';
import { CopyDataFromComponent } from '../../../../common-modules/modals/copy-data-from/copy-data-from.component';
import { combineLatestWith, take } from 'rxjs/operators';
import { EntityToCopy, ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import { ModalService } from '../../../../common-modules/modals/modal.service';
import { getCurrentRangeId } from '../../../../+store/range/range.selectors';
import { copyPumpingBetweenRangesAction } from '../../../../+store/scenario/scenario.actions';
import { PanelHelpMode } from '../../../../shared/components/help-button/help-buton.component';

@Component({
  selector: 'app-well-fluids',
  templateUrl: './well-fluids.component.html',
  styleUrls: ['./well-fluids.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WellFluidsComponent extends DbDependentComponent {
  public isPumpingEnabled = false;
  public fluids: ISelectItem<number>[] = [];
  public gravels: ISelectItem<number>[] = [];
  public pumpingState!: PumpingStateByRange;
  public completion!: CompletionModuleState;
  public settings!: SettingsModuleState | null;
  public ModuleType = ModuleType;
  public PanelHelpMode = PanelHelpMode;

  constructor(
    store: Store,
    cdRef: ChangeDetectorRef,
    private modalService: ModalService,
  ) {
    super(store, cdRef);
    store.dispatch(uiActions.setPumpingTabAction({ tab: 'well-fluids' }));
    super.onHelpChange('pumping', 'well-fluids');

    this.subscription.add(
      this.store.select(getValidatedPumpingModuleState).subscribe((state) => {
        this.pumpingState = state;
        this.cdRef.markForCheck();
      }),
    );
    this.subscription.add(
      this.store.select(isPumpingEnabled).subscribe((isPumpingEnabled) => {
        this.isPumpingEnabled = isPumpingEnabled;
        this.cdRef.markForCheck();
      }),
    );
  }

  protected override onStateLoaded(): void {
    super.onStateLoaded();
    this.store.dispatch(loadReferenceVariablesAction({ scenarioId: this.currentScenarioId }));
  }

  public onPumpingPropertyChanged(args: ObjectChangeProp<Pumping>): void {
    const newPumping = changeProp(this.pumpingState.pumping, args);
    this.store.dispatch(
      updatePumpingAction({
        isSimulate: false,
        pumping: newPumping,
        changedKeys: [args.key] as (keyof PumpingDto)[],
      }),
    );
  }

  public async copyFromSimulateEvaluate(): Promise<void> {
    const dialog = this.modalService.open(CopyDataFromComponent, undefined, 'copy-data-from-modal');

    dialog.onClose
      .pipe(combineLatestWith(this.store.select(getCurrentRangeId)), take(1))
      .subscribe((result: [copyData: { moduleType: ModuleType; rangeId?: number }, rangeId: number]) => {
        if (result[0] == null || !this.dbInfo) {
          return;
        }
        this.store.dispatch(
          copyPumpingBetweenRangesAction({
            sourceRangeId: result[0].rangeId ?? -1,
            targetRangeId: result[1],
            entitiesToCopy: [EntityToCopy.WellFluids],
            fileHash: this.dbInfo.fileHash,
          }),
        );
      });
  }
}
