<div class="overlay-info" data-cy="chart-overlay-info">
  <div class="overlay-info-message">
    <ng-container *ngIf="overlayText; else loading">
      <span>{{ overlayText }}</span>
    </ng-container>
    <ng-template #loading>
      <app-activity-overlay title="Loading"></app-activity-overlay>
    </ng-template>
  </div>
</div>
