import { Injectable } from '@angular/core';
import { asyncScheduler, combineLatest, Observable, throttleTime } from 'rxjs';
import { Store } from '@ngrx/store';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { selectUserGlobalOptions } from '../../../../../+store/common-db/common-db.selectors';
import { MarkerMode } from '../../../../../common-modules/chart/chart-controller.component';
import { MarkersServiceBase } from '../../../../../common-modules/chart/chart-controller-providers/markers-service-base';
import { IMarker } from '@dunefront/common/modules/reporting/dto/chart-marker.dto';
import { getSelectedSimulationTime } from '../../../../../+store/reporting/reporting.selectors';
import { getDepthDataSimulationDuration } from '../../../../../+store/reporting/reporting-get-depth-chart-state.selector';
import { ChartState } from '../../../../../+store/reporting/reporting-module.state';
import { PrimarySecondaryArgumentConverter } from '@dunefront/common/modules/reporting/dto/primary-secondary-argument-converter';

const noMarkers: IMarker[] = []; // it's important to use same empty array, so distinctUntilChanged can correctly recognise it as not changed

@Injectable()
export class AnimationTimeBasedMarkersService extends MarkersServiceBase {
  private _markers$: Observable<IMarker[]> = combineLatest([
    this.store.select(selectUserGlobalOptions),
    this.store.select(getSelectedSimulationTime),
    this.store.select(getDepthDataSimulationDuration),
    this.chartState$,
  ]).pipe(
    map(([globalOptions, time, duration, chartState]) => {
      const chartData = chartState?.chartData;
      if (chartData == null || duration == null || duration === 0) {
        return noMarkers;
      }

      const shouldConvert = PrimarySecondaryArgumentConverter.shouldConvert(chartData);
      const sliderPosition = time < duration ? time : duration;
      const value = shouldConvert ? PrimarySecondaryArgumentConverter.toSecondaryArgIfNeeded(chartData, sliderPosition) : sliderPosition;

      const marker: IMarker = {
        id: 0,
        value,
        isValueAxisMarker: false,
        name: 'Slider',
        isOverrideStyle: true,
        style: {
          ...globalOptions,
        },
      };

      return [marker];
    }),
    distinctUntilChanged(),
    throttleTime(50, asyncScheduler, { leading: true, trailing: true }),
  );

  public get markers$(): Observable<IMarker[]> {
    return this._markers$;
  }

  public get disableAddingMarkers(): boolean {
    return true;
  }

  constructor(
    private readonly store: Store,
    private readonly chartState$: Observable<ChartState | undefined>,
  ) {
    super(MarkerMode.CustomHandler);
  }

  public onMarkerMoved(marker: IMarker): void {
    // empty;
  }
}
