import { CompletionModuleState } from '../../completion-module.state';
import { PipeType } from '../../../../dto/pipe.dto';
import { TreatmentType } from '../../../well/dto/well.dto';
import { ArrayHelpers } from '../../../../common/array-helpers';
import { ICDType } from '../../../../dto/lower-completion-pipe.dto';
import { IcdPortDataValidation } from './icd-port-data.validation';
import { IcdPortDataDto } from '../../../../dto/icd-port-data.dto';
import { DictionaryWithArray, IDictionaryWithArray, noErrors } from '../../../../common/state.helpers';
import { LowerCompletionPipe } from '../../../pipes/lower-completion-pipes/lower-completion-pipes.factory';
import { IError } from '../../../../common/common-state.interfaces';
import { isShuntedPipe, isShuntedPipeRow } from '../../../pipes/lower-completion-pipes/pipes/shunted-screen-pipe';
import { WellCalculations } from '../../../well/model/well.calculations';
import { Pipe } from '../../../pipes/pipe';
import { getRowsForCalculations, ITableRow, ITableState } from '../../../../common/common-grid.interfaces';
import { IcdScreenPipe, isICDScreenRow } from '../../../pipes/lower-completion-pipes/pipes/icd-screen-pipe';
import { isScreenPipe, isScreenRow } from '../../../pipes/lower-completion-pipes/pipes/screen-pipe';
import { ICompletionValidationDependencies } from '../completion.validation';
import { IcdScreenValidation } from './icd-screen.validation';
import { ScreenValidation } from './screen.validation';
import { validateFeatureNotSupportedInFluidPro, validateFeatureRequired } from '../../../licensing/licensing.validator';
import { LicenseFeature } from '../../../licensing/licensing.interfaces';
import { CasingPipe } from '../../../pipes/casing-pipes/casing-pipes.factory';
import { ModuleType } from '../../../scenario/scenario.dto';
import { FluidProValidation } from '../../../fluid-pro/fluid-pro.validation';
import { isBlankPipe } from '../../../pipes/lower-completion-pipes/pipes/blank-pipe';

export class LowerCompletionValidation {
  public static ValidateLowerCompletionGrid(
    completion: CompletionModuleState,
    deps: ICompletionValidationDependencies,
  ): [ITableState<Pipe>, IDictionaryWithArray<ITableState<IcdPortDataDto>>] {
    let isValid = true;
    let isWarning = false;
    let icdPortData = completion.IcdPortData;
    const newLowerCompletion = { ...completion.LowerCompletion };
    const lowerCompletionRows = getRowsForCalculations(newLowerCompletion.rows);

    const updatedRows = newLowerCompletion.rows.map((row, rowIndex) => {
      if (row.rowType === 'insert-row') {
        return row;
      }
      const newRow = this.ValidateLowerCompletionGridRow(completion, lowerCompletionRows, deps, rowIndex);

      if (isICDScreenRow(newRow)) {
        if (
          newRow.rowData.ICDType === ICDType.Generic_ICD ||
          newRow.rowData.ICDType === ICDType.Halliburton_EquiFlow_AICD_Range_1 ||
          newRow.rowData.ICDType === ICDType.Halliburton_EquiFlow_AICD_Range_2 ||
          newRow.rowData.ICDType === ICDType.Halliburton_EquiFlow_AICD_Range_3 ||
          newRow.rowData.ICDType === ICDType.Halliburton_EquiFlow_AICD_Range_4
        ) {
          const permanentCompletionId = newRow.rowData.Id;
          const icdPortDataGrid = DictionaryWithArray.get(icdPortData, permanentCompletionId);
          if (icdPortDataGrid) {
            const newPortDataGrid = IcdPortDataValidation.ValidateIcdPortDataGrid(icdPortDataGrid, row as ITableRow<IcdScreenPipe>);
            icdPortData = DictionaryWithArray.upsertById(icdPortData, newPortDataGrid, permanentCompletionId);
            newRow.isValid = newRow.isValid && newPortDataGrid.isValid;
          }
        }
      }

      isValid = isValid && newRow.isValid;
      isWarning = isWarning || (newRow.isWarning ?? false);
      return newRow;
    });

    return [
      {
        ...newLowerCompletion,
        rows: updatedRows,
        isValid,
        isWarning,
      },
      icdPortData,
    ];
  }

  private static ValidateLowerCompletionGridRow(
    completion: CompletionModuleState,
    rows: Pipe[],
    deps: ICompletionValidationDependencies,
    rowIndex: number,
  ): ITableRow<Pipe> {
    const row: ITableRow<Pipe> = { ...completion.LowerCompletion.rows[rowIndex] };
    let error: IError<LowerCompletionPipe> = {
      PipeType: this.ValidateLowerCompletionComponentName(completion, rows, deps, row, rowIndex),
      TopMD: this.ValidateLowerCompletionTopMD(row, rowIndex),
      BottomMD: this.ValidateLowerCompletionBottomMD(completion, deps, row, rowIndex),
      OuterDiameter: this.ValidateLowerCompletionOD(row),
      InnerDiameter: this.ValidateLowerCompletionID(row),
      Weight: this.ValidateLowerCompletionWeight(row),
    };

    const { currentModuleType, settings } = deps;

    let warning: IError<CasingPipe> = {};
    if (currentModuleType === ModuleType.Simulate_Disp) {
      const componentSizeWarning = FluidProValidation.ValidateFluidProComponentSize(rows, rowIndex, settings);
      warning = {
        ...warning,
        PipeType: componentSizeWarning,
        BottomMD: componentSizeWarning,
        TopMD: componentSizeWarning,
        Length: componentSizeWarning,
      };
    }

    if (isScreenRow(row)) {
      error = ScreenValidation.validateScreenRow(error, row);

      if (isICDScreenRow(row)) {
        error = IcdScreenValidation.validateICDScreenRow(error, row);
      }
    }

    const isValid = noErrors(error);
    const isWarning = !noErrors(warning);
    return {
      ...row,
      isValid,
      error,
      warning,
      isWarning,
    };
  }

  private static ValidateLowerCompletionComponentName(
    completion: CompletionModuleState,
    rows: Pipe[],
    deps: ICompletionValidationDependencies,
    currentRow: ITableRow<Pipe>,
    rowIndex: number,
  ): string {
    const { well } = deps;
    const prevRow = rows[rowIndex - 1];

    const currentPipeType = currentRow.rowData.PipeType;

    const fluidProInvalidPipes = [PipeType.ICD_Screen, PipeType.Shunted_Blank_Pipe, PipeType.Shunted_Screen];
    const fluidProNotSupportedError = validateFeatureNotSupportedInFluidPro(deps.currentModuleType);
    if (fluidProInvalidPipes.includes(currentPipeType) && fluidProNotSupportedError) {
      return fluidProNotSupportedError;
    } else if (currentPipeType === PipeType.ICD_Screen) {
      const requiredFeatureError = validateFeatureRequired([LicenseFeature.Simulate, LicenseFeature.Evaluate], deps.currentLicenseFeatures);
      if (requiredFeatureError) {
        return requiredFeatureError;
      }
    }

    const bullNoseRowIndex = rows.findIndex((row) => row.PipeType === PipeType.Bull_Nose);
    if (bullNoseRowIndex >= 0 && rowIndex > bullNoseRowIndex) {
      return "Components can't be placed below the bull nose";
    }

    const sumpPackerRowIndex = rows.findIndex((row) => row.PipeType === PipeType.Sump_Packer);
    if (sumpPackerRowIndex >= 0 && rowIndex > sumpPackerRowIndex) {
      return "Components can't be placed below the sump packer";
    }

    if (rowIndex > 0 && currentPipeType === PipeType.Gravel_Pack_Packer) {
      return "You can't add more than one gravel pack packer";
    }

    if (currentPipeType === PipeType.Gravel_Pack_Extension && prevRow != null && prevRow.PipeType !== PipeType.Gravel_Pack_Packer) {
      return 'Gravel pack extension must be placed below the gravel pack packer';
    }

    if (currentPipeType == PipeType.Sump_Packer && deps.well.CasingData.rows.some((row) => row.rowData.PipeType === PipeType.Open_Hole)) {
      return 'Sump packer can’t be run in open hole completions';
    }

    const screenAbove = rows.find((pipe) => isScreenPipe(pipe) && pipe.BottomMD <= currentRow.rowData.TopMD);
    const screenBelow = rows.find((pipe) => isScreenPipe(pipe) && pipe.TopMD >= currentRow.rowData.BottomMD);

    if (deps.currentModuleType === ModuleType.Simulate_Disp && isBlankPipe(currentRow.rowData) && screenAbove != null && screenBelow != null) {
      return 'Blanks between screens are not supported in FluidPro';
    }

    if (isICDScreenRow(currentRow)) {
      const icdScreenRowError = IcdScreenValidation.validateIcdScreenComponentName(well, completion);
      if (icdScreenRowError) {
        return icdScreenRowError;
      }
    } else if (isShuntedPipeRow(currentRow)) {
      if (well.TreatmentType === TreatmentType.High_Rate_Water_Pack) {
        return "Shunt tubes can't be run when the high rate water pack option is selected";
      }
      if (completion.LowerCompletion.rows.some((row) => isICDScreenRow(row))) {
        return "ICDs and shunt tubes can't be run together";
      }
      if (!completion.LowerCompletion.rows.some((row) => isScreenPipe(row.rowData) && isShuntedPipe(row.rowData))) {
        return 'Shunted screens must be run with shunted blanks';
      }
    }
    return '';
  }

  private static ValidateLowerCompletionTopMD(row: ITableRow<Pipe>, rowIndex: number): string {
    if (rowIndex === 0 && row.rowData.TopMD <= 0) {
      return 'Top MD must be greater than zero';
    }
    return '';
  }

  public static ValidateLowerCompletionBottomMD(
    completion: CompletionModuleState,
    deps: ICompletionValidationDependencies,
    row: ITableRow<Pipe>,
    rowIndex: number,
  ): string {
    const { well } = deps;
    const topMD = row.rowData.TopMD;
    const bottomMD = row.rowData.BottomMD;
    const maxCasingMD = WellCalculations.MaxCasingMD(well);
    const firstReservoir = well.CasingData.rows.find((r) => r.rowData.IsReservoirSection);
    const lastScreenIndex = ArrayHelpers.findLastIndex(completion.LowerCompletion.rows, (p) => isScreenPipe(p.rowData));
    if (topMD >= bottomMD) {
      return 'Bottom MD must be greater than top MD';
    }
    if (bottomMD > maxCasingMD) {
      return 'Bottom MD must be less than or equal to maximum casing or open hole MD';
    }
    if (rowIndex === lastScreenIndex && firstReservoir && bottomMD <= firstReservoir.rowData.TopMD) {
      return 'Bottom MD of screens must be greater than top MD of the first open hole or perforated casing';
    }
    return '';
  }

  public static ValidateLowerCompletionOD(row: ITableRow<Pipe>): string {
    if (row.rowData.PipeType !== PipeType.Sump_Packer && row.rowData.PipeType !== PipeType.Isolation_Packer) {
      const od = row.rowData.OuterDiameter;
      const id = row.rowData.InnerDiameter;

      if (od <= 0) {
        return 'OD must be greater than zero';
      }
      if (id >= od) {
        return 'OD must be greater than ID';
      }
    }
    return '';
  }

  private static ValidateLowerCompletionID(row: ITableRow<Pipe>): string {
    const od = row.rowData.OuterDiameter;
    const id = row.rowData.InnerDiameter;

    if (id <= 0) {
      return 'ID must be greater than zero';
    }
    if (id >= od) {
      return 'ID must be less than OD';
    }

    // Validation passes
    return '';
  }

  private static ValidateLowerCompletionWeight(row: ITableRow<Pipe>): string {
    const weight = row.rowData.Weight;

    if (weight <= 0) {
      return 'Weight must be greater than zero';
    }

    // Validation passes
    return '';
  }
}
