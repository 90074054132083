import { IEquationVariable } from '@dunefront/common/modules/equation/equation-variable.dto';
import { EquationColumn } from '@dunefront/common/modules/equation/equation-column';
import { EquationModuleState } from '@dunefront/common/modules/equation/equation-module.state';
import { ImportColumnFactory } from '@dunefront/common/modules/data-storage/dto/import-column.dto';

export class EquationReducerHelper {
  public static updateEquationVariable(state: EquationModuleState, updatedVariable: IEquationVariable): EquationModuleState {
    return {
      ...state,
      equationColumn: {
        ...state.equationColumn,
        Equation: {
          ...state.equationColumn.Equation,
          variables: state.equationColumn.Equation.variables.map((variable) => {
            if (updatedVariable.Id === variable.Id) {
              return updatedVariable;
            } else {
              return variable;
            }
          }),
        },
      },
    };
  }

  public static removeVariable(state: EquationModuleState, index: number): EquationModuleState {
    return {
      ...state,
      equationColumn: {
        ...state.equationColumn,
        Equation: {
          ...state.equationColumn.Equation,
          variables: state.equationColumn.Equation.variables.filter((variable, idx) => idx !== index),
        },
      },
    };
  }

  public static insertVariable(state: EquationModuleState, variable: IEquationVariable): EquationModuleState {
    return {
      ...state,
      equationColumn: {
        ...state.equationColumn,
        Equation: {
          ...state.equationColumn.Equation,
          variables: [...state.equationColumn.Equation.variables, variable].sort((a, b) => a.Name.localeCompare(b.Name)),
        },
      },
    };
  }

  public static updateEquation(state: EquationModuleState, equationFormula: string): EquationModuleState {
    const siConstantValue = ImportColumnFactory.evaluateConstantValue(
      state.equationColumn,
      equationFormula,
      state.equationColumn.Equation.variables,
    );

    return {
      ...state,
      equationColumn: {
        ...state.equationColumn,
        Equation: {
          ...state.equationColumn.Equation,
          equationFormula,
          constantValue: siConstantValue,
        },
      },
    };
  }

  public static updateEquationColumnFields(state: EquationModuleState, changes: Partial<EquationColumn>): EquationModuleState {
    return {
      ...state,
      equationColumn: {
        ...state.equationColumn,
        ...changes,
      },
    };
  }
}
