import { ISelectItem } from '../select.helpers';

export interface CopyChartRatio {
  w: number;
  h: number;
}

export interface PresetRatio {
  [Key: string]: {
    Ratio: CopyChartRatio;
    CopyChartScaling: number;
  } | null;
}

export enum Ratios {
  WIDE = 0,
  VERY_WIDE = 1,
  STANDARD = 2,
  SQUARE = 3,
}

export const presetRatiosItems: ISelectItem<Ratios | -1>[] = [
  {
    text: '16:9 ( very wide )',
    value: Ratios.VERY_WIDE,
  },
  {
    text: '4:3 ( wide )',
    value: Ratios.WIDE,
  },
  {
    text: '2:1 ( default )',
    value: Ratios.STANDARD,
  },
  {
    text: '1:1 ( square )',
    value: Ratios.SQUARE,
  },
  {
    text: 'custom',
    value: -1,
  },
];

export const presetRatios: PresetRatio = {
  [Ratios.WIDE]: {
    Ratio: { w: 4, h: 3 },
    CopyChartScaling: 3,
  },
  [Ratios.VERY_WIDE]: {
    Ratio: { w: 16, h: 9 },
    CopyChartScaling: 3,
  },
  [Ratios.STANDARD]: {
    Ratio: { w: 2, h: 1 },
    CopyChartScaling: 3,
  },
  [Ratios.SQUARE]: {
    Ratio: { w: 1, h: 1 },
    CopyChartScaling: 2,
  },
};

export const ratioStrToArr = (ratioStr: string): number[] => ratioStr.split(':').map((x) => parseInt(x));
export const copyChartRatioFromStr = (ratioStr: string): CopyChartRatio | undefined => {
  const arr = ratioStrToArr(ratioStr);
  if (arr.length !== 2) {
    return undefined;
  }

  return { w: arr[0], h: arr[1] };
};
export const ratioToStr = (ratio: CopyChartRatio): string => `${ratio.w}:${ratio.h}`;
