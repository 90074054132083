import { UnitConverter } from '../../converter.interfaces';
import { PressureRateDerivativeUnit } from '../../../dto/unit-system.dto';

export class PressureRateCubedDerivativeConverter extends UnitConverter {
  public static symbols: Array<string> = [
    '',
    'Pa/(m³/s)³',
    'Pa/(m³/min)³',
    'Pa/(ft³/min)³',
    'Pa/(ft³/s)³',
    'Pa/(bbl/min)³',
    'Pa/(L/min)³',
    'Pa/(galUS/min)³',
    'kPa/(m³/s)³',
    'kPa/(m³/min)³',
    'kPa/(ft³/min)³',
    'kPa/(ft³/s)³',
    'kPa/(bbl/min)³',
    'kPa/(L/min)³',
    'kPa/(galUS/min)³',
    'psi/(m³/s)³',
    'psi/(m³/min)³',
    'psi/(ft³/min)³',
    'psi/(ft³/s)³',
    'psi/(bbl/min)³',
    'psi/(L/min)³',
    'psi/(galUS/min)³',
    'kg/cm²/(m³/s)³',
    'kg/cm²/(m³/min)³',
    'kg/cm²/(ft³/min)³',
    'kg/cm²/(ft³/s)³',
    'kg/cm²/(bbl/min)³',
    'kg/cm²/(L/min)³',
    'kg/cm²/(galUS/min)³',
    'bar/(m³/s)³',
    'bar/(m³/min)³',
    'bar/(ft³/min)³',
    'bar/(ft³/s)³',
    'bar/(bbl/min)³',
    'bar/(L/min)³',
    'bar/(galUS/min)³',
    'MPa/(m³/s)³',
    'MPa/(m³/min)³',
    'MPa/(ft³/min)³',
    'MPa/(ft³/s)³',
    'MPa/(bbl/min)³',
    'MPa/(L/min)³',
    'MPa/(galUS/min)³',
  ];

  public static unit = PressureRateDerivativeUnit;

  public static fromSi(value: number, unit: PressureRateDerivativeUnit): number {
    switch (unit) {
      case PressureRateDerivativeUnit.Pascal_per_cubic_meter_per_second: {
        return value;
      }
      case PressureRateDerivativeUnit.Pascal_per_cubic_meter_per_minute: {
        return value * 4.6296296296e-6;
      }
      case PressureRateDerivativeUnit.Pascal_per_cubic_foot_per_minute: {
        return value * 1.0511743765e-10;
      }
      case PressureRateDerivativeUnit.Pascal_per_cubic_foot_per_second: {
        return value * 2.2705687943e-5;
      }
      case PressureRateDerivativeUnit.Pascal_per_barrel_per_minute: {
        return value * 1.8605164321e-8;
      }
      case PressureRateDerivativeUnit.Pascal_per_liter_per_minute: {
        return value * 4.6296296296e-15;
      }
      case PressureRateDerivativeUnit.Pascal_per_us_gallon_per_minute: {
        return value * 2.5112250883e-13;
      }
      case PressureRateDerivativeUnit.Kilo_Pascal_per_cubic_meter_per_second: {
        return value * 1.0e-3;
      }
      case PressureRateDerivativeUnit.Kilo_Pascal_per_cubic_meter_per_minute: {
        return value * 4.6296296296e-9;
      }
      case PressureRateDerivativeUnit.Kilo_Pascal_per_cubic_foot_per_minute: {
        return value * 1.0511743765e-13;
      }
      case PressureRateDerivativeUnit.Kilo_Pascal_per_cubic_foot_per_second: {
        return value * 2.2705687943e-8;
      }
      case PressureRateDerivativeUnit.Kilo_Pascal_per_barrel_per_minute: {
        return value * 1.8605164321e-11;
      }
      case PressureRateDerivativeUnit.Kilo_Pascal_per_liter_per_minute: {
        return value * 4.6296296296e-18;
      }
      case PressureRateDerivativeUnit.Kilo_Pascal_per_us_gallon_per_minute: {
        return value * 2.5112250883e-16;
      }
      case PressureRateDerivativeUnit.Pound_per_square_inch_per_cubic_meter_per_second: {
        return value * 1.45037738e-4;
      }
      case PressureRateDerivativeUnit.Pound_per_square_inch_per_cubic_meter_per_minute: {
        return value * 6.7147100926e-10;
      }
      case PressureRateDerivativeUnit.Pound_per_square_inch_per_cubic_foot_per_minute: {
        return value * 1.5246211143e-14;
      }
      case PressureRateDerivativeUnit.Pound_per_square_inch_per_cubic_foot_per_second: {
        return value * 3.2931816189e-9;
      }
      case PressureRateDerivativeUnit.Pound_per_square_inch_per_barrel_per_minute: {
        return value * 2.6984509483e-12;
      }
      case PressureRateDerivativeUnit.Pound_per_square_inch_per_liter_per_minute: {
        return value * 6.7147100926e-19;
      }
      case PressureRateDerivativeUnit.Pound_per_square_inch_per_us_gallon_per_minute: {
        return value * 3.6422240641e-17;
      }
      case PressureRateDerivativeUnit.Kilogram_per_square_centimeter_per_cubic_meter_per_second: {
        return value * 1.019716213e-5;
      }
      case PressureRateDerivativeUnit.Kilogram_per_square_centimeter_per_cubic_meter_per_minute: {
        return value * 4.7209083935e-11;
      }
      case PressureRateDerivativeUnit.Kilogram_per_square_centimeter_per_cubic_foot_per_minute: {
        return value * 1.0718995544e-15;
      }
      case PressureRateDerivativeUnit.Kilogram_per_square_centimeter_per_cubic_foot_per_second: {
        return value * 2.3153358122e-10;
      }
      case PressureRateDerivativeUnit.Kilogram_per_square_centimeter_per_barrel_per_minute: {
        return value * 1.8971987704e-13;
      }
      case PressureRateDerivativeUnit.Kilogram_per_square_centimeter_per_liter_per_minute: {
        return value * 4.7209083935e-20;
      }
      case PressureRateDerivativeUnit.Kilogram_per_square_centimeter_per_us_gallon_per_minute: {
        return value * 2.560736937e-18;
      }
      case PressureRateDerivativeUnit.Bar_per_cubic_meter_per_second: {
        return value * 1.0e-5;
      }
      case PressureRateDerivativeUnit.Bar_per_cubic_meter_per_minute: {
        return value * 4.6296296296e-11;
      }
      case PressureRateDerivativeUnit.Bar_per_cubic_foot_per_minute: {
        return value * 1.0511743765e-15;
      }
      case PressureRateDerivativeUnit.Bar_per_cubic_foot_per_second: {
        return value * 2.2705687943e-10;
      }
      case PressureRateDerivativeUnit.Bar_per_barrel_per_minute: {
        return value * 1.8605164321e-13;
      }
      case PressureRateDerivativeUnit.Bar_per_liter_per_minute: {
        return value * 4.6296296296e-20;
      }
      case PressureRateDerivativeUnit.Bar_per_us_gallon_per_minute: {
        return value * 2.5112250883e-18;
      }

      case PressureRateDerivativeUnit.MPa_per_cubic_meter_per_second: {
        return value * 1.0e-6;
      }
      case PressureRateDerivativeUnit.MPa_per_cubic_meter_per_minute: {
        return value * 4.6296296296e-12;
      }
      case PressureRateDerivativeUnit.MPa_per_cubic_foot_per_minute: {
        return value * 1.0511892547e-16;
      }
      case PressureRateDerivativeUnit.MPa_per_cubic_foot_per_second: {
        return value * 2.2705687901e-11;
      }
      case PressureRateDerivativeUnit.MPa_per_barrel_per_minute: {
        return value * 1.8605060748e-14;
      }
      case PressureRateDerivativeUnit.MPa_per_liter_per_minute: {
        return value * 4.6296296296e-21;
      }
      case PressureRateDerivativeUnit.MPa_per_us_gallon_per_minute: {
        return value * 2.5112215181e-19;
      }
      default: {
        throw new TypeError('Unknown Pressure Rate Cubed Derivative Unit:' + unit);
      }
    }
  }

  public static toSi(value: number, unit: PressureRateDerivativeUnit): number {
    switch (unit) {
      case PressureRateDerivativeUnit.Pascal_per_cubic_meter_per_second: {
        return value;
      }
      case PressureRateDerivativeUnit.Pascal_per_cubic_meter_per_minute: {
        return value / 4.6296296296e-6;
      }
      case PressureRateDerivativeUnit.Pascal_per_cubic_foot_per_minute: {
        return value / 1.0511743765e-10;
      }
      case PressureRateDerivativeUnit.Pascal_per_cubic_foot_per_second: {
        return value / 2.2705687943e-5;
      }
      case PressureRateDerivativeUnit.Pascal_per_barrel_per_minute: {
        return value / 1.8605164321e-8;
      }
      case PressureRateDerivativeUnit.Pascal_per_liter_per_minute: {
        return value / 4.6296296296e-15;
      }
      case PressureRateDerivativeUnit.Pascal_per_us_gallon_per_minute: {
        return value / 2.5112250883e-13;
      }
      case PressureRateDerivativeUnit.Kilo_Pascal_per_cubic_meter_per_second: {
        return value / 1.0e-3;
      }
      case PressureRateDerivativeUnit.Kilo_Pascal_per_cubic_meter_per_minute: {
        return value / 4.6296296296e-9;
      }
      case PressureRateDerivativeUnit.Kilo_Pascal_per_cubic_foot_per_minute: {
        return value / 1.0511743765e-13;
      }
      case PressureRateDerivativeUnit.Kilo_Pascal_per_cubic_foot_per_second: {
        return value / 2.2705687943e-8;
      }
      case PressureRateDerivativeUnit.Kilo_Pascal_per_barrel_per_minute: {
        return value / 1.8605164321e-11;
      }
      case PressureRateDerivativeUnit.Kilo_Pascal_per_liter_per_minute: {
        return value / 4.6296296296e-18;
      }
      case PressureRateDerivativeUnit.Kilo_Pascal_per_us_gallon_per_minute: {
        return value / 2.5112250883e-16;
      }
      case PressureRateDerivativeUnit.Pound_per_square_inch_per_cubic_meter_per_second: {
        return value / 1.45037738e-4;
      }
      case PressureRateDerivativeUnit.Pound_per_square_inch_per_cubic_meter_per_minute: {
        return value / 6.7147100926e-10;
      }
      case PressureRateDerivativeUnit.Pound_per_square_inch_per_cubic_foot_per_minute: {
        return value / 1.5246211143e-14;
      }
      case PressureRateDerivativeUnit.Pound_per_square_inch_per_cubic_foot_per_second: {
        return value / 3.2931816189e-9;
      }
      case PressureRateDerivativeUnit.Pound_per_square_inch_per_barrel_per_minute: {
        return value / 2.6984509483e-12;
      }
      case PressureRateDerivativeUnit.Pound_per_square_inch_per_liter_per_minute: {
        return value / 6.7147100926e-19;
      }
      case PressureRateDerivativeUnit.Pound_per_square_inch_per_us_gallon_per_minute: {
        return value / 3.6422240641e-17;
      }
      case PressureRateDerivativeUnit.Kilogram_per_square_centimeter_per_cubic_meter_per_second: {
        return value / 1.019716213e-5;
      }
      case PressureRateDerivativeUnit.Kilogram_per_square_centimeter_per_cubic_meter_per_minute: {
        return value / 4.7209083935e-11;
      }
      case PressureRateDerivativeUnit.Kilogram_per_square_centimeter_per_cubic_foot_per_minute: {
        return value / 1.0718995544e-15;
      }
      case PressureRateDerivativeUnit.Kilogram_per_square_centimeter_per_cubic_foot_per_second: {
        return value / 2.3153358122e-10;
      }
      case PressureRateDerivativeUnit.Kilogram_per_square_centimeter_per_barrel_per_minute: {
        return value / 1.8971987704e-13;
      }
      case PressureRateDerivativeUnit.Kilogram_per_square_centimeter_per_liter_per_minute: {
        return value / 4.7209083935e-20;
      }
      case PressureRateDerivativeUnit.Kilogram_per_square_centimeter_per_us_gallon_per_minute: {
        return value / 2.560736937e-18;
      }
      case PressureRateDerivativeUnit.Bar_per_cubic_meter_per_second: {
        return value / 1.0e-5;
      }
      case PressureRateDerivativeUnit.Bar_per_cubic_meter_per_minute: {
        return value / 4.6296296296e-11;
      }
      case PressureRateDerivativeUnit.Bar_per_cubic_foot_per_minute: {
        return value / 1.0511743765e-15;
      }
      case PressureRateDerivativeUnit.Bar_per_cubic_foot_per_second: {
        return value / 2.2705687943e-10;
      }
      case PressureRateDerivativeUnit.Bar_per_barrel_per_minute: {
        return value / 1.8605164321e-13;
      }
      case PressureRateDerivativeUnit.Bar_per_liter_per_minute: {
        return value / 4.6296296296e-20;
      }
      case PressureRateDerivativeUnit.Bar_per_us_gallon_per_minute: {
        return value / 2.5112250883e-18;
      }

      case PressureRateDerivativeUnit.MPa_per_cubic_meter_per_second: {
        return value / 1.0e-6;
      }
      case PressureRateDerivativeUnit.MPa_per_cubic_meter_per_minute: {
        return value / 4.6296296296e-12;
      }
      case PressureRateDerivativeUnit.MPa_per_cubic_foot_per_minute: {
        return value / 1.0511892547e-16;
      }
      case PressureRateDerivativeUnit.MPa_per_cubic_foot_per_second: {
        return value / 2.2705687901e-11;
      }
      case PressureRateDerivativeUnit.MPa_per_barrel_per_minute: {
        return value / 1.8605060748e-14;
      }
      case PressureRateDerivativeUnit.MPa_per_liter_per_minute: {
        return value / 4.6296296296e-21;
      }
      case PressureRateDerivativeUnit.MPa_per_us_gallon_per_minute: {
        return value / 2.5112215181e-19;
      }
      default: {
        throw new TypeError('Unknown Pressure Rate Cubed Derivative Unit:' + unit);
      }
    }
  }

  public static getSymbol(unit: PressureRateDerivativeUnit): string {
    if (unit == null) {
      return '';
    }
    return this.symbols[unit];
  }
}
