import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnChanges } from '@angular/core';
import { ITableState } from '@dunefront/common/common/common-grid.interfaces';
import { ColumnType, ICustomKeyboardHandlers, IGridColumnConfig } from '../../../../../shared/components/grid/grid.interfaces';
import { Store } from '@ngrx/store';
import { ModalService } from '../../../../../common-modules/modals/modal.service';
import { GridConfig } from '../../../../../shared/components/grid/grid-config';
import { ScreenService } from '../../../../../shared/services';
import { CommonGravelModel } from '../../../../../+store/common-db/model/common-gravel.model';
import { GridContainerComponent } from '../../../../../shared/components/grid/grid-container.component';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { GridResizeService } from '../../../../../shared/services/grid-resize.service';

@Component({
  selector: 'app-database-gravels-grid',
  templateUrl: './database-gravels-grid.component.html',
  styleUrls: ['./database-gravels-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatabaseGravelsGridComponent extends GridContainerComponent<CommonGravelModel> implements OnChanges, AfterViewInit {
  @Input() public commonGravelData: ITableState<CommonGravelModel> = { rows: [], isValid: true };
  @Input() public customKeyboardHandlers: ICustomKeyboardHandlers = {};

  constructor(
    store: Store,
    cdRef: ChangeDetectorRef,
    protected modalService: ModalService,
    el: ElementRef,
    protected resizeService: ScreenService,
    gridResizeService: GridResizeService,
  ) {
    super(store, cdRef, el, new DatabaseGravelsGridConfig(store, modalService), gridResizeService);
  }

  private _columns: IGridColumnConfig<CommonGravelModel>[] = [
    { disabled: false, visible: true, colId: ' ', type: ColumnType.selection },
    {
      disabled: false,
      visible: false,
      colId: 'commonDbType',
      type: ColumnType.text,
      align: 'left',
      headerText: 'Source',
    },
    {
      disabled: false,
      visible: true,
      colId: 'Name',
      type: ColumnType.text,
      align: 'left',
      headerText: 'Gravel Name',
      width: 300,
    },
    { disabled: false, visible: true, colId: 'MeshSize', type: ColumnType.text, headerText: 'MeshSize' },
    {
      disabled: false,
      visible: true,
      colId: 'MeanDiameter',
      type: ColumnType.number,
      headerText: 'Mean Diameter',
      unitSystem: UnitSystem.Diameter,
      decimalPlaces: 3,
    },
    {
      disabled: false,
      visible: true,
      colId: 'AbsoluteDensity',
      type: ColumnType.number,
      headerText: 'Density',
      unitSystem: UnitSystem.Solid_Density,
    },
    {
      disabled: false,
      visible: true,
      colId: 'BulkDensity',
      type: ColumnType.number,
      headerText: 'Bulk Density',
      unitSystem: UnitSystem.Bulk_Density,
    },
    {
      disabled: false,
      visible: true,
      colId: 'PackPermeability',
      type: ColumnType.number,
      headerText: 'Pack Permeability',
      unitSystem: UnitSystem.Permeability,
    },
  ];

  protected getColumns(): IGridColumnConfig<CommonGravelModel>[] {
    return this._columns;
  }
}

export class DatabaseGravelsGridConfig extends GridConfig<CommonGravelModel> {
  constructor(
    private store: Store,
    modalService: ModalService,
  ) {
    super(modalService);
  }
}
