import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as reportingActions from '../../../../+store/reporting/reporting.actions';
import { ReportingCalculationJobStatus, setSelectedReportingChartTabId } from '../../../../+store/reporting/reporting.actions';
import {
  getCurrentCalculationStatus,
  getCurrentlyVisibleChartCompareScenarioIds,
  getIsCurrentScenarioCalculationActive,
  getReportingIsOptimizeActive,
  getRestrictedReportingChartTimeVolMode,
  getSelectedReportingTab,
  ICurrentCalculationStatusDetails,
} from '../../../../+store/reporting/reporting.selectors';
import { DbDependentComponent } from '../../../../common-modules/db-connection/db-dependent.component';
import 'hammerjs';
import 'chartjs-plugin-zoom';
import 'chartjs-plugin-annotation';
import { ActivatedRoute } from '@angular/router';
import * as uiActions from '../../../../+store/ui/ui.actions';
import { ChartState } from '../../../../+store/reporting/reporting-module.state';
import { DepthDataStatus } from '@dunefront/common/modules/reporting/dto/chart-data.dto';
import { ReportingTabDto } from '@dunefront/common/dto/reporting-tab.dto';
import { ChartTimeVolMode, GetChartDataParams } from '@dunefront/common/modules/reporting/reporting-module.actions';
import { ChartDataSourceType } from '@dunefront/common/modules/reporting/dto/chart.dto';
import { isSimulateBased, ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import { distinctUntilChanged, filter, pairwise, startWith } from 'rxjs/operators';
import { ChartControllerConfig, ChartControllerFetchDataPayload } from '../../../../common-modules/chart/chart-controller.component';
import { RangeConstants } from '@dunefront/common/dto/range.dto';
import { combineLatest, ReplaySubject } from 'rxjs';
import { observableToBehaviorSubject } from '@dunefront/common/common/state.helpers';
import { isEqual } from 'lodash';
import { getReportingChartState } from '../../../../+store/reporting/reporting-get-chart-data.selector';
import { getDepthDataResultsStatus } from '../../../../+store/reporting/reporting-get-depth-chart-state.selector';
import { chartConfigurationRequiredMessage } from '../../../../shared/helpers/document-generator/report-consts';
import { PanelHelpMode } from '../../../../shared/components/help-button/help-buton.component';
import { getIsCompareScenariosEnabled } from '../../../../+store/ui/ui.selectors';
import { getAreCurrentResultsPresent } from '../../../../+store/calculation-engine/calculation-engine-results.selectors';
import { DrawableContentProviderComponent, DrawableRegistryService } from '../../../../shared/services/drawable-registry.service';

@Component({
  selector: 'app-reporting-chart',
  templateUrl: './reporting-chart.component.html',
  styleUrls: ['./reporting-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportingChartComponent extends DbDependentComponent implements OnInit, OnDestroy, DrawableContentProviderComponent {
  @Input() public drawableProviderId?: string;

  private isRotatedOverride: boolean | undefined;

  private isShowChart = true;
  private isAltKeyDown = false;

  @HostListener('document:keydown.alt')
  public documentKeydownCtrl(): void {
    this.isAltKeyDown = true;
  }

  @HostListener('document:keyup.alt')
  public documentKeyupCtrl(): void {
    this.isAltKeyDown = false;
  }

  @HostListener('document:keydown', ['$event'])
  public handleKeyboardEvent(event: KeyboardEvent): void {
    if (this.isAltKeyDown && event.key.toLowerCase() === 'r') {
      if (this.isAltKeyDown && event.key.toLowerCase() === 'r') {
        this.isRotatedOverride = !this.isRotatedOverride;
      }
    }
  }

  public chartState?: ChartState;
  public depthResultsStatus = DepthDataStatus.NotRequested;

  private readonly notifyToFetchInitialData$ = new ReplaySubject<void>(1);

  public readonly config: ChartControllerConfig = {
    loadData: (payload) => this.fetchData(payload),
    fetchInitialData$: this.notifyToFetchInitialData$,
  };

  public currentReportingTab$ = observableToBehaviorSubject(this.store.select(getSelectedReportingTab), undefined);
  public isCompareScenariosEnabled = false;
  public chartId: number | undefined;
  public PanelHelpMode = PanelHelpMode;

  public get chartName(): string {
    const reportingTab = this.currentReportingTab$.value;
    if (reportingTab == null) {
      throw new Error('Reporting chart component: no chartName!');
    }

    return reportingTab.TabName;
  }

  private currentCalculationStatus?: ICurrentCalculationStatusDetails;
  private depthDataScenarioIdsRequested: number[] = [];

  private registeredDrawableProviderId: string | undefined;

  private isOptimizeChartMode$ = observableToBehaviorSubject(
    this.store.select(getReportingIsOptimizeActive).pipe(
      filter((isOptimize) => isOptimize != null),
      distinctUntilChanged(),
    ),
    false,
  );

  private areCurrentResultsPresent$ = observableToBehaviorSubject(this.store.select(getAreCurrentResultsPresent), false);

  private restrictedReportingChartTimeVolMode$ = observableToBehaviorSubject(
    this.store.select(getRestrictedReportingChartTimeVolMode),
    ChartTimeVolMode.time,
  );
  private isCalculationActive$ = this.store.select(getIsCurrentScenarioCalculationActive);
  private scenarioIdsToCompare$ = observableToBehaviorSubject(this.store.select(getCurrentlyVisibleChartCompareScenarioIds), []);
  private isCompareScenarioChanged$ = this.scenarioIdsToCompare$.pipe(
    startWith(undefined),
    pairwise(),
    filter(([previous, current]) => !isEqual(previous, current)),
  );
  private isReportingTabChanged$ = this.currentReportingTab$.pipe(
    startWith(undefined),
    pairwise(),
    filter(([previous, current]) => current != null && current.Id !== previous?.Id),
  );

  private fetchInitialData$ = combineLatest([
    this.isOptimizeChartMode$,
    this.isCalculationActive$,
    this.isReportingTabChanged$,
    this.isCompareScenarioChanged$,
  ]).pipe(filter(([isOptimize, isCalculationActive, isReportingTabChanged, isCompareScenarioChanged]) => isOptimize != null));

  constructor(
    store: Store,
    cdRef: ChangeDetectorRef,
    protected route: ActivatedRoute,
    private drawableRegistryService: DrawableRegistryService,
  ) {
    super(store, cdRef);
    store.dispatch(uiActions.setReportingTabAction({ tabType: 'reporting-chart' }));
    this.onHelpChange('results', 'chart');
  }

  public get showChart(): boolean {
    return this.isShowChart && this.currentCalculationStatus?.calculationJobStatus === ReportingCalculationJobStatus.notActive;
  }

  public get overlayText(): string {
    if (this.isChartConfigurationRequired) {
      return chartConfigurationRequiredMessage;
    }

    if (this.depthResultsStatus === DepthDataStatus.Requested) {
      return 'Loading depth data.';
    }

    return '';
  }

  private get isChartConfigurationRequired(): boolean {
    return (
      this.chartState?.chartData?.ChartDataColumns.length === 0 &&
      (this.depthResultsStatus === DepthDataStatus.Loaded || this.depthResultsStatus === DepthDataStatus.NotRequested)
    );
  }

  public isDepthBased(reportingTab: ReportingTabDto): boolean {
    return !reportingTab.IsChartTimeVolume;
  }

  public isRotatedChart(reportingTab: ReportingTabDto): boolean {
    return this.isRotatedOverride ?? (this.isDepthBased(reportingTab) && this.currentAppModuleType !== ModuleType.Simulate_Disp);
  }

  public override ngOnInit(): void {
    super.ngOnInit();

    this.subscription.add(
      this.store.select(getCurrentCalculationStatus).subscribe((currentCalculationStatus) => {
        this.currentCalculationStatus = currentCalculationStatus;
        this.cdRef.markForCheck();
      }),
    );

    this.subscription.add(
      this.store.select(getIsCompareScenariosEnabled).subscribe((isCompareScenariosAllowed) => {
        this.isCompareScenariosEnabled = isCompareScenariosAllowed;
        this.cdRef.markForCheck();
      }),
    );

    this.subscription.add(
      this.store.select(getDepthDataResultsStatus).subscribe((depthResultsStatus) => {
        if (depthResultsStatus == null) {
          return;
        }
        this.depthResultsStatus = depthResultsStatus;
        this.cdRef.markForCheck();
      }),
    );

    this.subscription.add(
      this.route.params.subscribe((params) => {
        const id = params['id'];
        if (id == null) {
          return;
        }
        this.unregisterDrawableProviderIfRequired();
        const tabId = Number(id);
        this.store.dispatch(setSelectedReportingChartTabId({ tabId }));
      }),
    );

    this.subscription.add(
      this.store.select(getReportingChartState).subscribe((chartState) => {
        if (!chartState) {
          return;
        }
        this.onReportingData(chartState);
      }),
    );

    this.subscription.add(this.fetchInitialData$.subscribe(() => this.fetchInitialChartData(this.currentReportingTab$.value)));
  }

  public override ngOnDestroy(): void {
    this.unregisterDrawableProviderIfRequired();
    this.store.dispatch(reportingActions.clearReportingChartData({ chartDataSourceType: ChartDataSourceType.ChartSourceReportingTab }));
    this.store.dispatch(setSelectedReportingChartTabId({ tabId: undefined }));

    // release objects held by observableToBehaviorSubject
    this.currentReportingTab$ = null as any;
    this.restrictedReportingChartTimeVolMode$ = null as any;
    this.isOptimizeChartMode$ = null as any;
    this.areCurrentResultsPresent$ = null as any;
    this.scenarioIdsToCompare$ = null as any;

    super.ngOnDestroy();
  }

  private fetchData(payload: ChartControllerFetchDataPayload): void {
    const { src, requestType, argumentStart, argumentEnd, isPrimaryArgumentRelative, isPrimaryArgument } = payload;

    const currentReportingTab = this.currentReportingTab$?.value;
    if (currentReportingTab == null || this.currentAppModuleType == null || this.currentUnitSystem == null) {
      return;
    }

    const getChartDataParams: GetChartDataParams = {
      chartId: currentReportingTab.ChartId,
      dataSourceType: ChartDataSourceType.ChartSourceReportingTab,
      timeVolMode: this.restrictedReportingChartTimeVolMode$.value,
      isOptimizeEvaluationChart: this.isOptimizeChartMode$.value && this.areCurrentResultsPresent$.value,
      rangeId: this.currentRangeId,
      moduleType: this.currentAppModuleType,
      argumentStart,
      argumentEnd,
      isPrimaryArgumentRelative,
      isPrimaryArgument,
      requestType,
    };

    this.store.dispatch(
      reportingActions.getTimeVolChartData({
        src: 'reporting-chart.component - fetchData ' + src,
        getChartDataParams,
        reportingTabId: currentReportingTab.Id,
      }),
    );

    if (!currentReportingTab.IsChartTimeVolume && this.currentScenarioId !== undefined) {
      this.checkDepthDataIfChanged([this.currentScenarioId, ...this.scenarioIdsToCompare$.value], this.currentAppModuleType);
    }
  }

  private fetchInitialChartData(reportingTab: ReportingTabDto | undefined): void {
    // force to rerender without app-chart-controller (*ngIf="chartId")
    this.chartId = undefined;
    this.chartState = undefined;
    this.cdRef.detectChanges();

    if (reportingTab == null) {
      return;
    }

    setTimeout(() => {
      // force to rerender with new instance of app-chart-controller
      const currentReportingTab = this.currentReportingTab$?.value;
      if (currentReportingTab != null) {
        this.cdRef.detectChanges();
        this.chartId = currentReportingTab.ChartId;
        this.notifyToFetchInitialData$.next();
        this.cdRef.detectChanges();
      }
    }, 0);
  }

  private onReportingData(chartState: ChartState): void {
    if (this.currentReportingTab$?.value?.ChartId !== chartState.chartData?.ChartId) {
      return;
    }
    this.chartState = chartState;
    const shouldRegisterEmptyProvider = this.chartState?.chartData != null && this.isChartConfigurationRequired;

    if (shouldRegisterEmptyProvider && this.registeredDrawableProviderId == null) {
      this.registerDrawableProviderIfRequired();
    } else if (!shouldRegisterEmptyProvider) {
      this.unregisterDrawableProviderIfRequired();
    }

    this.cdRef.markForCheck();
  }

  private unregisterDrawableProviderIfRequired(): void {
    if (this.registeredDrawableProviderId != null) {
      this.drawableRegistryService.unregisterProvider(this.registeredDrawableProviderId);
      this.registeredDrawableProviderId = undefined;
    }
  }

  /**
   * It registers empty provider when there is no data. It's a workaround for a situation when report generator is waiting
   * for a provider to register(in a chart) but it's not happening due to a lack of data.
   */
  private registerDrawableProviderIfRequired(): void {
    const chartId = this.chartState?.chartData?.ChartId;
    if (chartId != null) {
      const id = this.drawableProviderId ?? chartId.toString();
      const chartName = this.chartName;

      this.drawableRegistryService.registerProvider({
        id,
        getDisplayName: () => chartName,
        getChartId: () => chartId,
      });

      this.registeredDrawableProviderId = id;
    }
  }

  private checkDepthDataIfChanged(scenarioIds: number[], moduleType: ModuleType): void {
    if (isSimulateBased(this.currentAppModuleType)) {
      if (
        this.depthDataScenarioIdsRequested.length !== scenarioIds.length ||
        !this.depthDataScenarioIdsRequested.every((val, index) => val === scenarioIds[index])
      ) {
        this.store.dispatch(
          reportingActions.checkDepthDataAction({
            depthDataKeys: scenarioIds.map((scenarioId) => ({
              scenarioId: scenarioId,
              moduleType: this.currentAppModuleType,
              rangeId: RangeConstants.EmptyRangeId,
            })),
          }),
        );
        this.depthDataScenarioIdsRequested = scenarioIds;
      }
    } else {
      this.store.dispatch(
        reportingActions.checkDepthDataAction({
          depthDataKeys: [
            {
              scenarioId: scenarioIds[0],
              moduleType,
              rangeId: this.currentRangeId,
            },
          ],
        }),
      );
    }
  }
}
