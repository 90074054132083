import { WellModuleState } from '../../well-module.state';
import { getRowsForCalculations, ITableRow, ITableState } from '../../../../common/common-grid.interfaces';
import { Survey } from './survey';
import { ConvertUnitPipe } from '../../../units/convert-unit.pipe/convert-unit.pipe';
import { noErrors } from '../../../../common/state.helpers';
import { IError } from '../../../../common/common-state.interfaces';
import { UnitSystem } from '../../../../dto/unit-system.dto';

export class SurveyValidation {
  private static readonly maxDeviationSi = 180;
  private static readonly maxAzimuthSi = 360;

  public static ValidateSurveyGrid(well: WellModuleState): ITableState<Survey> {
    let isValid = true;
    const newSurveyData = { ...well.SurveyData };
    const surveyRows: Survey[] = getRowsForCalculations(well.SurveyData.rows);
    const updatedRows = newSurveyData.rows.map((row, rowIndex) => {
      if (row.rowType === 'insert-row') {
        return row;
      }
      const newRow = this.ValidateSurveyGridRow(well.SurveyData.rows[rowIndex], surveyRows, rowIndex);
      isValid = isValid && newRow.isValid;
      return newRow;
    });

    isValid = isValid && surveyRows.length > 1;

    return { ...newSurveyData, rows: updatedRows, isValid, isWarning: false };
  }

  private static ValidateSurveyGridRow(row: ITableRow<Survey>, surveys: Survey[], rowIndex: number): ITableRow<Survey> {
    const rowError: IError<Survey> = {
      MD: this.ValidateSurveyMD(surveys, rowIndex),
      Deviation: this.ValidateSurveyDeviation(surveys, rowIndex),
      Azimuth: this.ValidateSurveyAzimuth(surveys, rowIndex),
    };
    return { ...row, error: rowError, isValid: noErrors(rowError), isWarning: false, warning: {} };
  }

  private static ValidateSurveyMD(surveyData: Survey[], rowIndex: number): string {
    if (rowIndex > 0) {
      const md = surveyData[rowIndex].MD;
      const prevMD = surveyData[rowIndex - 1].MD;
      if (md <= 0) {
        return 'MD must be greater than zero';
      }
      if (md <= prevMD) {
        return 'MD must be greater than the MD in the previous row';
      }
    }
    return '';
  }

  private static ValidateSurveyDeviation(surveyData: Survey[], rowIndex: number): string {
    if (rowIndex > 0) {
      const deviation = surveyData[rowIndex].Deviation;

      if (deviation > this.maxDeviationSi || deviation < 0) {
        const encodeMaxDeviation = ConvertUnitPipe.encode(UnitSystem.Angle, this.maxDeviationSi);
        return `Deviation must be between 0 and ${encodeMaxDeviation}`;
      }
    }
    return '';
  }

  private static ValidateSurveyAzimuth(surveyData: Survey[], rowIndex: number): string {
    if (rowIndex > 0) {
      const azimuth = surveyData[rowIndex].Azimuth;

      if (azimuth >= this.maxAzimuthSi || azimuth < 0) {
        const encodeMaxAzimuth = ConvertUnitPipe.encode(UnitSystem.Angle, this.maxAzimuthSi);
        return `Azimuth must be between 0 and ${encodeMaxAzimuth}`;
      }
    }
    return '';
  }
}
