import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { defaultModalCancelButton, modalButton, ModalButtonConfig } from '../../generic-modal/generic-modal.component';
import { selectSeenNotifications, selectUnreadNotifications } from '../../../../+store/ui/ui.selectors';
import { ModalService } from '../../modal.service';
import { updateNotificationStatusAction } from '../../../../+store/ui/ui.actions';
import { NotificationStatus } from '@dunefront/common/dto/custom-settings.dto';
import { NotificationWithStatus } from '../../../../+store/ui/ui-module.state';
import { NotificationContentModalComponent } from './notification-content.modal.component';
import { firstValueFrom, Subscription } from 'rxjs';
import { ReleaseNotesModalComponent } from '../../release-notes/release-notes-modal.component';
import { HttpClient } from '@angular/common/http';
import { CHECK_URL_LAMBDA_URL } from '@dunefront/common/common/constants';

const showReadCy = 'show-read-messages';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications-modal.component.html',
  styleUrls: ['./notifications-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationsModalComponent implements OnInit, OnDestroy {
  @ViewChild('readMessagesSection') public readMessagesSection?: ElementRef;
  @ViewChild('newMessagesSection') public newMessagesSection?: ElementRef;
  @ViewChild('notificationsWrapper') public notificationsWrapper?: ElementRef;

  public title = 'Notifications';
  public modalButtonsConfigs: ModalButtonConfig[] = [
    modalButton(
      'Show read messages',
      (): void => this.toggleReadMessages(),
      showReadCy,
      undefined,
      false,
      undefined,
      undefined,
      undefined,
      'left',
    ),
    defaultModalCancelButton((): void => this.cancelClicked(), 'Close'),
  ];
  public unreadNotifications: NotificationWithStatus[] = [];
  public seenNotifications: NotificationWithStatus[] = [];
  public showReadMessages = false;
  public subscription = new Subscription();
  public isHelpOpen = false;

  constructor(
    protected store: Store,
    protected modalService: ModalService,
    public dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public cdRef: ChangeDetectorRef,
    private http: HttpClient,
  ) {}

  public ngOnInit(): void {
    this.subscription.add(
      this.store.select(selectUnreadNotifications).subscribe((unread) => {
        this.unreadNotifications = unread;
        this.cdRef.markForCheck();
      }),
    );

    this.subscription.add(
      this.store.select(selectSeenNotifications).subscribe((seen) => {
        this.seenNotifications = seen;
        this.updateShowReadButton();
        this.cdRef.markForCheck();
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public cancelClicked(): void {
    this.dialogRef.close();
  }

  public async openNotification(notification: NotificationWithStatus, status: NotificationStatus): Promise<void> {
    const isReleaseNotes = notification.id.startsWith('release-notes');

    let shouldUpdateNotificationStatus = true;

    if (status === NotificationStatus.OPENED) {
      if (isReleaseNotes) {
        this.modalService.open(ReleaseNotesModalComponent, {}, 'release-notes-modal');
      } else {
        const loadingDialog = this.modalService.showActivityOverlay('Loading notification...');
        const canOpenUrl = await this.canOpenUrl(notification.url);
        loadingDialog.close();

        if (canOpenUrl) {
          this.modalService.open(NotificationContentModalComponent, { url: notification.url }, 'notification-content-modal', 'lg', 'lg');
        } else {
          shouldUpdateNotificationStatus = false;
          this.modalService.showAlert('Cannot open notification. Please check your internet connection, or contact our support').then();
        }
      }
    }

    if (shouldUpdateNotificationStatus) {
      // update notification state
      this.store.dispatch(updateNotificationStatusAction({ id: notification.id, status }));

      // this means, that it's the last notification viewed
      if (this.unreadNotifications.length === 1) {
        this.dialogRef.close();
      }
    }
  }

  private toggleReadMessages(): void {
    this.showReadMessages = !this.showReadMessages;
    this.updateShowReadButton();
    this.scrollToSection();
  }

  private scrollToSection(): void {
    setTimeout(() => {
      const extraPadding = 80;
      const offsetTop = this.showReadMessages ? this.readMessagesSection?.nativeElement.offsetTop - extraPadding : 0;

      if (this.notificationsWrapper) {
        this.notificationsWrapper.nativeElement.scrollTo({
          top: offsetTop,
          left: 0,
          behavior: 'smooth',
        });
      }
    });
  }

  private updateShowReadButton(): void {
    const toggleBtn = this.modalButtonsConfigs.find((btn) => btn.dataCy === showReadCy);
    if (toggleBtn) {
      toggleBtn.text = this.showReadMessages ? 'Hide read messages' : 'Show read messages';
      toggleBtn.show = this.seenNotifications.length > 0;
    }
  }

  private async canOpenUrl(url: string): Promise<boolean> {
    try {
      await firstValueFrom(this.http.get(CHECK_URL_LAMBDA_URL, { params: { url: btoa(url) } }));
      return true;
    } catch (e) {
      return false;
    }
  }
}
