import { createSelector } from '@ngrx/store';
import { getSettingsModuleState } from '../settings/settings.selectors';
import { getCurrentPumpingModuleState } from '../pumping/selectors/pumping.selectors';
import { getValidatedDeveloperSettings } from '../settings/validated-settings.selectors';
import { CompletionValidation, ICompletionValidationDependencies } from '@dunefront/common/modules/completion/model/completion.validation';
import { getCompletionModuleState } from './completion.selectors';
import { getValidatedGravels } from '../gravel/gravel.selectors';
import { VolumesValidation } from '@dunefront/common/modules/completion/model/volumes/volumes.validation';
import { DictionaryWithArray } from '@dunefront/common/common/state.helpers';
import { SectionInputs, SectionInputsFactory } from '@dunefront/common/modules/completion/dto/section-calculator.dto';
import { getCurrentAppModuleType } from '../ui/ui.selectors';
import { getImportFilesForAdvancedValidation } from '../data-storage/data-storage.selectors';
import { createTableRow, createTableState, ITableState } from '@dunefront/common/common/common-grid.interfaces';
import { SectionInformationRow } from '@dunefront/common/modules/completion/model/volumes/section-information-row';
import { getValidatedWellModuleState } from '../well/validated-well.selectors';
import { getCurrentFeatures } from '../licensing/licensing.selectors';
import { getGaugeDataPageValidation } from '../gauge-data/gauge-data.selectors';
import { ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';

export const getCompletionValidationDeps = createSelector(
  getValidatedWellModuleState,
  getSettingsModuleState,
  getCurrentPumpingModuleState,
  getValidatedDeveloperSettings,
  getCurrentFeatures,
  getCurrentAppModuleType,
  (well, settingsState, pumpingState, developerSettings, currentLicenseFeatures, currentModuleType): ICompletionValidationDependencies => ({
    well,
    pumpingState,
    settings: settingsState.settings,
    developerSettings,
    currentLicenseFeatures,
    currentModuleType,
  }),
);

export const getValidatedCompletionModuleState = createSelector(getCompletionModuleState, getCompletionValidationDeps, (state, deps) =>
  CompletionValidation.validate(state, deps),
);

const getSectionInputs = createSelector(
  getValidatedCompletionModuleState,
  getValidatedGravels,
  getValidatedWellModuleState,
  getGaugeDataPageValidation,
  getCurrentAppModuleType,
  getImportFilesForAdvancedValidation,
  (completion, gravels, well, dataPageValidation, moduleType, files) => {
    return {
      completion,
      gravels,
      well,
      isDataValid: moduleType === ModuleType.Evaluate ? dataPageValidation.isValid : true,
      moduleType,
      files,
    };
  },
);

export const getValidatedSingleSectionInputs = createSelector(
  getSectionInputs,
  ({ completion, gravels, well, isDataValid, moduleType, files }): SectionInputs | undefined => {
    const gravel = DictionaryWithArray.get(gravels, completion.Volumes.SectionVolCalcGravelId);
    const isValid = VolumesValidation.IsVolumeCalculatorValid(true, completion, well, gravel, isDataValid);
    if (!isValid) {
      return;
    }
    return isValid ? SectionInputsFactory.toDtoSingleSection(completion.Volumes, moduleType, well) : undefined;
  },
);

export const getValidatedMultipleSectionInputs = createSelector(
  getSectionInputs,
  ({ completion, gravels, well, isDataValid, moduleType, files }): SectionInputs | undefined => {
    const gravel = DictionaryWithArray.get(gravels, completion.Volumes.SectionVolCalcGravelId);
    const isValid = VolumesValidation.IsVolumeCalculatorValid(false, completion, well, gravel, isDataValid);
    if (!isValid) {
      return;
    }
    return isValid ? SectionInputsFactory.toDtoMultipleSections(completion.Volumes, moduleType, well, files) : undefined;
  },
);

export const getGaugeSectionInformationRows = createSelector(
  getValidatedCompletionModuleState,
  (state): ITableState<SectionInformationRow> | null =>
    state.VolumeGaugeSectionInformationResult.isLoaded
      ? createTableState(state.VolumeGaugeSectionInformationResult.rows.map((row, index) => createTableRow(row, 'data', index)))
      : null,
);

export const getIsCompletionStateLoaded = createSelector(getCompletionModuleState, (state) => state.isLoaded);

export const getVolumeSectionCalculatorResult = createSelector(getCompletionModuleState, (state) => state.VolumeSectionCalculatorResult);

export const getIcdPortDataDict = createSelector(getValidatedCompletionModuleState, (state) => state.IcdPortData);
export const getLowerCompletionRange = createSelector(getValidatedCompletionModuleState, (state) =>
  state.LowerCompletion.rows.length
    ? {
        lcTop: state.LowerCompletion.rows[0].rowData.TopMD,
        lcBottom: state.LowerCompletion.rows[state.LowerCompletion.rows.length - 2].rowData.BottomMD,
      }
    : {
        lcTop: 0,
        lcBottom: 0,
      },
);
