import { UnitConverter } from '../../converter.interfaces';
import { PressureGradientUnit } from '../../../dto/unit-system.dto';

export class PressureGradientConverter extends UnitConverter {
  public static symbols: Array<string> = [
    '',
    'Pa/m',
    'kPa/m',
    'psi/ft',
    'psi/m',
    'Pa/ft',
    'kPa/ft',
    'kg/cm²/ft',
    'kg/cm²/m',
    'Bar/ft',
    'Bar/m',
    'MPa/ft',
    'MPa/m',
  ];

  public static unit = PressureGradientUnit;

  public static fromSi(value: number, unit: PressureGradientUnit): number {
    switch (unit) {
      case PressureGradientUnit.Pascal_per_meter: {
        return value;
      }
      case PressureGradientUnit.Kilo_Pascal_Per_Meter: {
        return value * 1e-3;
      }
      case PressureGradientUnit.Pound_per_square_inch_per_foot: {
        return value * 4.42075025e-5;
      }
      case PressureGradientUnit.Pound_per_square_inch_per_meter: {
        return value * 1.45037738e-4;
      }
      case PressureGradientUnit.Pascal_per_foot: {
        return value * 0.3048;
      }
      case PressureGradientUnit.Kilo_Pascal_per_foot: {
        return value * 0.0003048;
      }
      case PressureGradientUnit.Kilogram_per_square_centimeter_per_foot: {
        return value * 3.108095017e-6;
      }
      case PressureGradientUnit.Kilogram_per_square_centimeter_per_meter: {
        return value * 1.019716213e-5;
      }
      case PressureGradientUnit.Bar_per_foot: {
        return value * 3.048e-6;
      }
      case PressureGradientUnit.Bar_per_meter: {
        return value * 1.0e-5;
      }
      case PressureGradientUnit.Mega_Pascal_per_foot: {
        return value * 3.048e-7;
      }
      case PressureGradientUnit.Mega_Pascal_per_meter: {
        return value * 1.0e-6;
      }
      default: {
        throw new TypeError('Unknown Pressure Gradient Unit:' + unit);
      }
    }
  }

  public static toSi(value: number, unit: PressureGradientUnit): number {
    switch (unit) {
      case PressureGradientUnit.Pascal_per_meter: {
        return value;
      }
      case PressureGradientUnit.Kilo_Pascal_Per_Meter: {
        return value / 1e-3;
      }
      case PressureGradientUnit.Pound_per_square_inch_per_foot: {
        return value / 4.42075025e-5;
      }
      case PressureGradientUnit.Pound_per_square_inch_per_meter: {
        return value / 1.45037738e-4;
      }
      case PressureGradientUnit.Pascal_per_foot: {
        return value / 0.3048;
      }
      case PressureGradientUnit.Kilo_Pascal_per_foot: {
        return value / 0.0003048;
      }
      case PressureGradientUnit.Kilogram_per_square_centimeter_per_foot: {
        return value / 3.108095017e-6;
      }
      case PressureGradientUnit.Kilogram_per_square_centimeter_per_meter: {
        return value / 1.019716213e-5;
      }
      case PressureGradientUnit.Bar_per_foot: {
        return value / 3.048e-6;
      }
      case PressureGradientUnit.Bar_per_meter: {
        return value / 1.0e-5;
      }
      case PressureGradientUnit.Mega_Pascal_per_foot: {
        return value / 3.048e-7;
      }
      case PressureGradientUnit.Mega_Pascal_per_meter: {
        return value / 1.0e-6;
      }
      default: {
        throw new TypeError('Unknown Pressure Gradient Unit:' + unit);
      }
    }
  }

  public static getSymbol(unit: PressureGradientUnit): string {
    return this.symbols[unit];
  }
}
