import { LeakoffCoefficientCalculatorDto, LeakoffCoefficientCalculatorJobResults } from '../leakoff-coefficient-calculator.dto';
import { LeakoffCoefficientCalculatorModuleState } from '../leakoff-coefficient-calculator-module.state';

export class LeakoffCoefficientCalculatorFactory {
  public static create(leakoffCoefficientCalculatorDto: LeakoffCoefficientCalculatorDto): LeakoffCoefficientCalculatorModuleState {
    return {
      ...leakoffCoefficientCalculatorDto,
      isLoaded: true,
    };
  }

  public static toDto(leakoffCoefficientCalculator: LeakoffCoefficientCalculatorModuleState): LeakoffCoefficientCalculatorDto {
    const { isLoaded, ...dto } = leakoffCoefficientCalculator;
    return dto;
  }

  public static cleanResultsPartial(): LeakoffCoefficientCalculatorJobResults {
    return {
      ViscosityControlledLeakoff: 0,
      CompressibilityControlledLeakoff: 0,
      CombinedLeakoffCoefficient: 0,
    };
  }
}
