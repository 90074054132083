import { Rect, Size } from '@dunefront/common/common/math-geometry-helpers';
import { getCanvasFontString, measureText, TextDrawStyle } from '../../../shared/helpers/canvas-drawing-helpers';

const SPACE = '\u200a';

export const canvasTxt = {
  debug: false,
  align: 'center',
  vAlign: 'middle',
  font: 'Arial',
  lineHeight: null,
  justify: false,
  /**
   *
   * @param {CanvasRenderingContext2D} ctx
   * @param {string} myText
   * @param rect
   * @param style
   * @param adjustFontSizeIfNeeded
   */
  drawText: function (
    ctx: CanvasRenderingContext2D,
    myText: string,
    rect: Rect,
    style: TextDrawStyle,
    adjustFontSizeIfNeeded = true,
  ): number {
    const { x, y, width, height } = rect;

    if (width <= 0 || height <= 0 || style.fontSize <= 0) {
      //width or height or font size cannot be 0
      return 0;
    }

    // End points
    const xEnd = x + width;
    const yEnd = y + height;

    if ((this as any).textSize) {
      console.error('%cCanvas-Txt:', 'font-weight: bold;', 'textSize is deprecated and has been renamed to fontSize');
    }

    ctx.font = `${style.italic ? 'italic' : ''} ${style.bold ? 'bold ' : ' '}  ${style.fontSize}px Arial`;
    ctx.fillStyle = style.fontColour;

    let txtY = y + height / 2 + style.fontSize / 2;

    let textAnchor: number;

    if (this.align === 'right') {
      textAnchor = xEnd;
      ctx.textAlign = 'right';
    } else if (this.align === 'left') {
      textAnchor = x;
      ctx.textAlign = 'left';
    } else {
      textAnchor = x + width / 2;
      ctx.textAlign = 'center';
    }

    //added one-line only auto linebreak feature
    const textArray: string[] = [];
    const tempTextArray = myText.split('\n');

    const spaceWidth = this.justify ? ctx.measureText(SPACE).width : 0;

    tempTextArray.forEach((tmpTxt) => {
      let textWidth = ctx.measureText(tmpTxt).width;
      if (textWidth <= width) {
        textArray.push(tmpTxt);
      } else {
        let tempText = tmpTxt;
        const lineLen = width;
        let textLen;
        let textPixLen;
        let textToPrint;
        textWidth = ctx.measureText(tempText).width;
        while (textWidth > lineLen) {
          textLen = 0;
          textPixLen = 0;
          textToPrint = '';
          while (textPixLen < lineLen) {
            textLen++;
            textToPrint = tempText.substr(0, textLen);
            textPixLen = ctx.measureText(tempText.substr(0, textLen)).width;
          }

          // Remove last character that was out of the box (unless it's just a single character)
          textLen = textLen > 1 ? textLen - 1 : 1;

          textToPrint = textToPrint.substr(0, textLen);
          //if statement ensures a new line only happens at a space, and not amidst a word
          const backup = textLen;
          if (tempText.substr(textLen, 1) != ' ') {
            while (tempText.substr(textLen, 1) != ' ' && textLen != 0) {
              textLen--;
            }
            if (textLen == 0) {
              textLen = backup;
            }
            textToPrint = tempText.substr(0, textLen);
          }

          textToPrint = this.justify ? this.justifyLine(ctx, textToPrint, spaceWidth, SPACE, width) : textToPrint;

          tempText = tempText.substr(textLen);
          textWidth = ctx.measureText(tempText).width;
          textArray.push(textToPrint);
        }
        if (textWidth > 0) {
          textArray.push(tempText);
        }
      }
      // end foreach tempTextArray
    });

    //close approximation of height with width
    const charHeight = (this.lineHeight != null && this.lineHeight > 0 ? this.lineHeight : measureText(ctx, myText, style)).height ?? 0;

    const maxFittingLines = Math.floor(height / charHeight);

    let numberOfLinesToDraw = Math.min(maxFittingLines, textArray.length);
    // ensure at least one line will be drawn
    numberOfLinesToDraw = Math.max(numberOfLinesToDraw, 1);

    const allLinesFitting = maxFittingLines >= textArray.length;

    const vHeight = charHeight * numberOfLinesToDraw;
    const negOffset = vHeight / 2;

    if (this.vAlign === 'top') {
      txtY = y + style.fontSize;
    } else if (this.vAlign === 'bottom') {
      txtY = yEnd - vHeight;
    } else {
      txtY -= negOffset;
    }

    //print all lines of text
    for (let i = 0; i < numberOfLinesToDraw; i++) {
      let txtLine = textArray[i].trim();

      if (allLinesFitting === false && i === numberOfLinesToDraw - 1) {
        txtLine = txtLine.slice(0, -2);
        txtLine = txtLine + '\u{2026}';
      }

      //
      // ensure given line text is actually fitting rect, otherwise find the closest font size fitting
      const fontSize = adjustFontSizeIfNeeded ? this.findFontFittingRectSize(ctx, txtLine, style, rect) : style.fontSize;
      ctx.font = getCanvasFontString({ ...style, fontSize });

      ctx.fillText(txtLine, textAnchor, txtY);
      txtY += charHeight;
    }

    return vHeight + charHeight;
  },

  findFontFittingRectSize: function (ctx: CanvasRenderingContext2D, text: string, style: TextDrawStyle, rectSize: Size): number {
    const minFontSize = 4;

    let currentFontSize = style.fontSize;

    while (currentFontSize >= minFontSize) {
      const textSize = measureText(ctx, text, { ...style, fontSize: currentFontSize });
      if (textSize.width <= rectSize.width && textSize.height <= rectSize.height) {
        break;
      }

      currentFontSize--;
    }

    return currentFontSize;
  },

  /**
   * This function will insert spaces between words in a line in order
   * to raise the line width to the box width.
   * The spaces are evenly spread in the line, and extra spaces (if any) are inserted
   * between the first words.
   *
   * It returns the justified text.
   *
   * @param {CanvasRenderingContext2D} ctx
   * @param {string} line
   * @param {number} spaceWidth
   * @param {string} spaceChar
   * @param {number} width
   */
  justifyLine: function (ctx: CanvasRenderingContext2D, line: string, spaceWidth: number, spaceChar: string, width: number): string {
    const text = line.trim();

    const lineWidth = ctx.measureText(text).width;

    const nbSpaces = text.split(/\s+/).length - 1;
    const nbSpacesToInsert = Math.floor((width - lineWidth) / spaceWidth);

    if (nbSpaces <= 0 || nbSpacesToInsert <= 0) {
      return text;
    }

    // We insert at least nbSpacesMinimum and we add extraSpaces to the first words
    const nbSpacesMinimum = Math.floor(nbSpacesToInsert / nbSpaces);
    let extraSpaces = nbSpacesToInsert - nbSpaces * nbSpacesMinimum;

    const spaces = [];
    for (let i = 0; i < nbSpacesMinimum; i++) {
      spaces.push(spaceChar);
    }
    const spacesStr = spaces.join('');

    return text.replace(/\s+/g, (match) => {
      const allSpaces = extraSpaces > 0 ? spacesStr + spaceChar : spacesStr;
      extraSpaces--;
      return match + allSpaces;
    });
  },
};
