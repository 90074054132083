import { ChangeDetectorRef, Component } from '@angular/core';
import { DbDependentComponent } from '../../../../common-modules/db-connection/db-dependent.component';
import { Store } from '@ngrx/store';
import { calculateMASP, updateMASPCalculatorRow } from '../../../../+store/calculators/masp-calculator/masp-calculator.actions';
import { ModalService } from '../../../../common-modules/modals/modal.service';
import { getIsFluidAndGravelValid } from '../../../../+store/app.selector';
import { take } from 'rxjs/operators';
import { ValidatedMaspCalculatorModuleState } from '@dunefront/common/modules/calculators/masp-calculator/masp-calculator-module.state';
import { getValidatedMASPCalculatorModuleState } from '../../../../+store/calculators/masp-calculator/masp-calculator.selectors';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { changeProp, ErrorHelper, ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';
import { LeftNavHelperService } from '../../../../shared/components/left-nav/left-nav-helper.service';
import { IRadioItem } from '@dunefront/common/common/radio.helpers';
import { StoreCrudPropsFactory } from '@dunefront/common/common/common-store-crud.interfaces';

@Component({
  selector: 'app-masp-calculator',
  templateUrl: './masp-calculator.component.html',
  styleUrls: ['./masp-calculator.component.scss'],
})
export class MASPCalculatorComponent extends DbDependentComponent {
  public maspCalculatorData!: ValidatedMaspCalculatorModuleState;

  public UnitType = UnitSystem;

  public calculationTypes: IRadioItem<number>[] = [
    {
      value: 0,
      text: 'Absolute Pressure',
    },
    {
      value: 1,
      text: 'Differential Pressure',
    },
  ];

  constructor(
    store: Store,
    cdRef: ChangeDetectorRef,
    private modalService: ModalService,
    public runCalculationService: LeftNavHelperService,
  ) {
    super(store, cdRef);
    this.subscription.add(
      store.select(getValidatedMASPCalculatorModuleState).subscribe((maspCalculatorData) => {
        this.maspCalculatorData = maspCalculatorData;
        cdRef.markForCheck();
      }),
    );
    this.subscription.add(runCalculationService.calculationSubject.subscribe(() => this.calculate()));
    this.onHelpChange('calculators', 'masp-calculator');
  }

  public submitCalcData(props: ObjectChangeProp<ValidatedMaspCalculatorModuleState>): void {
    if (this.maspCalculatorData[props.key] !== props.value) {
      this.store.dispatch(updateMASPCalculatorRow(StoreCrudPropsFactory.updateRow(changeProp(this.maspCalculatorData, props), props)));
    }
  }

  public async calculate(): Promise<void> {
    if (!this.maspCalculatorData.isValid) {
      await this.modalService.showAlert(ErrorHelper.ERROR_CURRENT_SCREENS_MESSAGE_HEADER, 'Warning');
      return;
    }
    this.store
      .select(getIsFluidAndGravelValid(this.maspCalculatorData.FluidId, this.maspCalculatorData.GravelId))
      .pipe(take(1))
      .subscribe(async (invalidFields) => {
        if (invalidFields.length > 0) {
          await this.modalService.showAlert(invalidFields, 'Warning');
          return;
        }
        this.store.dispatch(calculateMASP({ ScenarioId: this.maspCalculatorData.ScenarioId }));
      });
  }
}
