import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { Shroud, ShuntTube } from '@dunefront/common/modules/shunt-tube/shunt-tube';
import { IRadioItem } from '@dunefront/common/common/radio.helpers';
import { changeProp, getChangeProp, ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';

@Component({
  selector: 'app-shroud-properties',
  templateUrl: './shroud-properties.component.html',
  styleUrls: ['./shroud-properties.component.scss'],
})
export class ShroudPropertiesComponent {
  @Input() public shuntTube!: ShuntTube;

  @Output() public shuntTubeValueChange = new EventEmitter<ObjectChangeProp<ShuntTube>>();

  public UnitType = UnitSystem;

  public shroudPresentRadio: IRadioItem<boolean>[] = [
    { value: true, text: 'Yes' },
    { value: false, text: 'No' },
  ];

  public onValueChange(props: ObjectChangeProp<Shroud>): void {
    const shuntTubeProp: ObjectChangeProp<ShuntTube> = getChangeProp(
      'Shroud',
      changeProp(this.shuntTube.Shroud, props),
      props.shouldResetResults,
    );
    this.shuntTubeValueChange.emit(shuntTubeProp);
  }
}
