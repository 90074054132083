import { IUndoableAction, IUndoRedoModuleState } from './undo-redo.reducer';
import { CrudResponse } from '@dunefront/common/modules/common.actions';
import { Action } from '@ngrx/store';

export class UndoRedoReducerHelper {
  public static onUpdateSuccessAction(state: IUndoRedoModuleState, action: CrudResponse & Action): IUndoRedoModuleState {
    if (action.undoOrRedo) {
      const counter = action.undoOrRedo === 'undo' ? -1 : 1;
      return { ...state, currentIndex: state.currentIndex + counter, isBusy: false };
    }
    if (!action.undoInfo) {
      return state;
    }

    const prevAction = { ...state.history[state.currentIndex], redo: action.undoInfo.redo };
    const currentAction: IUndoableAction = { ...action.undoInfo, redo: undefined };
    const history = [...state.history.slice(0, state.currentIndex), prevAction, currentAction];
    return {
      ...state,
      history,
      currentIndex: history.length - 1,
      isBusy: false,
    };
  }
}
