import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { IValidatedDataType } from '@dunefront/common/common/common-grid.interfaces';
import { IError, ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';
import { noErrors } from '@dunefront/common/common/state.helpers';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { IMarker, IMarkerStyle } from '@dunefront/common/modules/reporting/dto/chart-marker.dto';
import { isMarkerHorizontal } from '../chart-component-helpers/chart-marker-helpers';
import {
  defaultModalCancelButton,
  defaultModalConfirmButton,
  modalButton,
  ModalButtonConfig,
} from '../../modals/generic-modal/generic-modal.component';

@Component({
  templateUrl: './chart-marker.component.html',
  styleUrls: ['./chart-marker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartMarkerComponent implements OnInit {
  public marker!: IMarker;
  public defaultMarkerStyle!: IMarkerStyle;
  public isMarkerHorizontal = false;
  public allowDelete = false;

  public UnitType = UnitSystem;
  public workingMarker!: IValidatedMarker;
  public modalTitle = 'Chart Marker';

  public modalButtonsConfigs: ModalButtonConfig[] = [];

  public ngOnInit(): void {
    this.workingMarker = this.validateMarker(this.marker);
    this.workingMarker.style = { ...this.marker.style };
  }

  constructor(
    public activeModal: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private cdRef: ChangeDetectorRef,
  ) {
    this.marker = config.data.marker;
    this.defaultMarkerStyle = config.data.defaultMarkerStyle;
    this.isMarkerHorizontal = isMarkerHorizontal(this.marker.isValueAxisMarker, config.data.isChartRotated);
    this.allowDelete = config.data.allowDelete;

    this.modalButtonsConfigs = [
      modalButton('Delete', () => this.deleteClicked(), 'chart-marker-dlg--delete-btn', 'primary', this.allowDelete),
      defaultModalCancelButton(() => this.cancelClicked(), 'Cancel'),
      defaultModalConfirmButton(() => this.okClicked(), 'Ok', 'chart-marker-dlg--ok-btn'),
    ];
  }

  public emitValue(event: ObjectChangeProp<IValidatedMarker>): void {
    this.workingMarker = this.validateMarker({ ...this.workingMarker, [event.key]: event.value });
    if (event.key === 'isOverrideStyle' && event.value === true) {
      this.workingMarker.style = this.defaultMarkerStyle;
    }
    this.cdRef.markForCheck();
  }

  public emitStyleValue(event: ObjectChangeProp<IMarkerStyle>): void {
    this.workingMarker.style = { ...this.workingMarker.style, [event.key]: event.value };
  }

  public okClicked(): void {
    this.workingMarker = this.validateMarker(this.workingMarker);
    if (!this.workingMarker.isValid) {
      return;
    }
    const result = { action: 'ok', value: this.workingMarker };
    this.activeModal.close(result);
  }

  public cancelClicked(): void {
    this.activeModal.close();
  }

  public deleteClicked(): void {
    const result: ChartMarkerDialogResult = { action: 'delete', value: this.workingMarker };
    this.activeModal.close(result);
  }

  private validateMarker(marker: IMarker): IValidatedMarker {
    const error: IError<IMarker> = {};
    if (!marker.name.trim().length) {
      error.name = 'Marker name required';
    }
    return { ...marker, error, isValid: noErrors(error) };
  }
}

export interface ChartMarkerDialogResult {
  action: 'ok' | 'delete';
  value: IMarker;
}

export interface IValidatedMarker extends IMarker, IValidatedDataType {
  error: IError<IMarker>;
}
