<ng-container *ngIf="dataSource$ | async as dataSource">
  <app-grid
    *ngIf="height > 0"
    [dataSource]="dataSource"
    [gridConfig]="gridConfig"
    [columns]="(columns$ | async) ?? []"
    (selectedRowsChanged)="selectedRowsChanged.emit($event)"
    [showCrudControls]="false"
    [height]="height"
  ></app-grid>
</ng-container>
