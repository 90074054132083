import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { createSelector, Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { flatten } from 'lodash';
import {
  getReportInfoFields,
  getReportInfoRangesConfig,
  getReportingInfoRangesSelectionComponentItems,
} from '../../../../+store/report-info/report-info.selectors';
import {
  reportInfoSelectAllClearAllAction,
  updateReportInfoFieldsAction,
  updateReportInfoRangesConfigAction,
} from '../../../../+store/report-info/report-info.actions';
import { isSimulateBased, ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import { Actions, ofType } from '@ngrx/effects';
import { SelectItem } from 'primeng/api';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { getGroupedReportingTabs } from '../../../../+store/reporting/reporting.selectors';
import { GroupedReportingTabDto } from '@dunefront/common/dto/reporting-tab.dto';

interface SelectionState {
  RangeIds: number[];
  ReportingTabIds: number[];
}

const getRangesAndReportingTabsSelection = createSelector(getReportInfoFields, (infoFields) => ({
  RangeIds: infoFields.RangeIds,
  ReportingTabIds: infoFields.ReportingTabIds,
}));

export interface RangeWithReportingTabsListItem {
  label: string;
  value: number;
  items: SelectItem[];
}

@Component({
  selector: 'app-reporting-range-selection',
  templateUrl: './reporting-range-selection.component.html',
  styleUrls: ['./reporting-range-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportingRangeSelectionComponent implements OnInit {
  @Input({ required: true }) public moduleType!: ModuleType;

  public filter$ = new BehaviorSubject<string>('');
  public items$ = this.store.select(getReportingInfoRangesSelectionComponentItems);
  public groupedReportingTabs$ = this.store.select(getGroupedReportingTabs);

  public filteredItems$ = combineLatest([this.items$, this.filter$.pipe(distinctUntilChanged())]).pipe(
    map(([items, filter]) => {
      const itemsWithFilteredChildren = items.map((item) => {
        return {
          ...item,
          items: item.items.filter((ch) => (ch.label ?? '').toLowerCase().includes(filter.toLowerCase())),
        };
      });
      // Show only ranges that contains typed string, or ones that contains children with provided string
      return itemsWithFilteredChildren.filter((item) => {
        return item.label.toLowerCase().includes(filter.toLowerCase()) || item.items.length;
      });
    }),
  );

  public selection$ = this.store.select(getRangesAndReportingTabsSelection);
  public config$ = this.store.select(getReportInfoRangesConfig);
  public isSimulateBased = false;
  public isReorderMode = false;

  constructor(
    private store: Store,
    private actions$: Actions,
  ) {
    this.actions$.pipe(ofType(reportInfoSelectAllClearAllAction), takeUntilDestroyed()).subscribe(() => this.filter$.next(''));
  }

  public ngOnInit(): void {
    this.isSimulateBased = isSimulateBased(this.moduleType);
  }

  // reorder
  public async drop(event: CdkDragDrop<string[]>, items: RangeWithReportingTabsListItem[], selectionState: SelectionState): Promise<void> {
    const newSortOrder = items.map((r) => r.value);
    moveItemInArray(newSortOrder, event.previousIndex, event.currentIndex);
    this.store.dispatch(updateReportInfoRangesConfigAction({ value: newSortOrder, changedKey: 'RangesSelectorOrder' }));
    this.updateSelectedIds(selectionState.RangeIds, selectionState.ReportingTabIds, newSortOrder);
  }

  public onRangeSelection(
    rangeId: number,
    groupedReportingTabDto: GroupedReportingTabDto,
    selection: SelectionState,
    order: number[],
  ): void {
    const isUnselect = selection.RangeIds.includes(rangeId);

    const rangeReportingTabs = groupedReportingTabDto[rangeId] ?? [];
    const rangeReportingTabIds = rangeReportingTabs.map((r) => r.Id);

    if (isUnselect) {
      selection.RangeIds = selection.RangeIds.filter((r) => r !== rangeId);
      selection.ReportingTabIds = selection.ReportingTabIds.filter((r) => !rangeReportingTabIds.includes(r));
    } else {
      selection.RangeIds = [rangeId, ...selection.RangeIds];
      selection.ReportingTabIds = Array.from(new Set([...selection.ReportingTabIds, ...rangeReportingTabIds]));
    }

    this.updateSelectedIds(selection.RangeIds, selection.ReportingTabIds, order);
  }

  // reorder

  public onReportingTabSelection(
    reportingTabIds: number[],
    allItems: RangeWithReportingTabsListItem[],
    selection: SelectionState,
    order: number[],
  ): void {
    // when reporting tab is clicked, we need to also select parent item ( range )
    const rangesIds = allItems.filter((r) => r.items.some((item) => reportingTabIds.includes(item.value))).map((r) => r.value);

    const rangeIdsToSelect = Array.from(new Set([...(selection?.RangeIds ?? []), ...rangesIds]));

    this.updateSelectedIds(rangeIdsToSelect, reportingTabIds, order);
  }

  public onToggleAll(items: RangeWithReportingTabsListItem[], selectionState: SelectionState, order: number[], selectAll: boolean): void {
    const visibleRangeIds = items.map((r) => r.value);
    const visibleReportingTabIds = flatten(items.map((r) => r.items.map((i) => i.value)));

    if (selectAll) {
      // build a list of all ranges to be selected
      selectionState.RangeIds = visibleRangeIds;
      // create selection list basing on original list, to keep order
      selectionState.ReportingTabIds = visibleReportingTabIds;
    } else {
      selectionState.ReportingTabIds = selectionState.ReportingTabIds.filter((c) => !visibleReportingTabIds.includes(c));
      selectionState.RangeIds = selectionState.RangeIds.filter((r) => !visibleRangeIds.includes(r));
    }

    this.updateSelectedIds(selectionState.RangeIds, selectionState.ReportingTabIds, order);
  }

  public isEverythingVisibleChecked(items: RangeWithReportingTabsListItem[], selectionState: SelectionState): boolean {
    const visibleRangeIds = items.map((r) => r.value);
    const visibleReportingTabIds = flatten(items.map((r) => r.items.map((i) => i.value)));

    return (
      visibleRangeIds.every((r) => selectionState.RangeIds.includes(r)) &&
      visibleReportingTabIds.every((c) => selectionState.ReportingTabIds.includes(c))
    );
  }

  private updateSelectedIds(rangeIds: number[], reportingTabIds: number[], selectorOrder: number[]): void {
    const sortedRangesIds = selectorOrder.length ? selectorOrder.filter((r) => rangeIds.includes(r)) : rangeIds.sort();

    this.store.dispatch(
      updateReportInfoFieldsAction({
        changes: [
          { value: sortedRangesIds, changedKey: 'RangeIds' },
          { value: reportingTabIds, changedKey: 'ReportingTabIds' },
        ],
      }),
    );
  }
}
