<div class="grid-form-container cols-1">
  <app-radio-group
    [items]="radioItems"
    [source]="delimiterConfig"
    [key]="'delimiter'"
    layout="horizontal"
    [cssOverride]="'grid-form-container no-padding'"
    dataCy="custom-delimiter-radio"
    (valueChanged)="onDelimiterChanged($event)"
    id="column-delimiter-radio"
  >
  </app-radio-group>

  <app-form-input
    [isTextInput]="true"
    [source]="delimiterConfig"
    key="customDelimiter"
    [disabled]="delimiterConfig.delimiter !== ImportFileColumnDelimiter.Custom"
    (valueChanged)="onCustomDelimiterChanged($event)"
    [trim]="false"
    dataCy="custom-delimiter-input"
    [triggerOnKeyPress]="true"
  >
  </app-form-input>
</div>
