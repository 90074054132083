import { Directive, ElementRef, OnDestroy, OnInit, Renderer2, TemplateRef, ViewContainerRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { getCurrentAppModuleType } from '../../+store/ui/ui.selectors';
import { Subscription } from 'rxjs';
import { ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';

@Directive()
export class HideOnBaseDirective implements OnInit, OnDestroy {
  private subscription = new Subscription();
  public hideOnModule: ModuleType = ModuleType.None;
  private hasView = false;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private el: ElementRef,
    private renderer: Renderer2,
    private store: Store,
  ) {}

  public ngOnInit(): void {
    this.subscription = this.store.select(getCurrentAppModuleType).subscribe((moduleType: ModuleType) => {
      if (moduleType === this.hideOnModule) {
        if (this.templateRef != null) {
          this.viewContainer.clear(); // Clear the view for structural directives
        } else {
          this.renderer.setStyle(this.el.nativeElement, 'display', 'none');
        }
      } else {
        if (this.templateRef != null) {
          if (!this.hasView) {
            this.viewContainer.createEmbeddedView(this.templateRef);
            this.hasView = true;
          }
        } else {
          this.renderer.removeStyle(this.el.nativeElement, 'display');
        }
      }
    });
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
