<div class="wrapper with-help" [class.help-open]="isHelpOpen">
  <div class="dialog-header">
    <h4>Add new column</h4>
    <app-modal-help-button (helpClick)="onHelpClick($event.isHelpOpen)"></app-modal-help-button>
  </div>

  <div class="dialog-content" *ngIf="column$ | async as column">
    <app-page-panel header="Output column">
      <app-gauge-data-column-props [selectedColumn]="column" [isAddColumnMode]="true"></app-gauge-data-column-props>
    </app-page-panel>

    <app-page-panel header="Define variables">
      <div class="define-variables-wrapper">
        <app-variables-grid (selectedRowsChanged)="onSelectRowChanged($event)"></app-variables-grid>

        <div class="buttons">
          <span>
            <button appButton type="button" class="btn btn-primary btn-sm" (buttonClick)="onAddNewVariable()">Add new variable</button>
            <button
              appButton
              type="button"
              class="btn btn-primary btn-sm"
              [disabled]="selectedRow === null"
              (buttonClick)="onRemoveVariable()"
            >
              Remove selected variable
            </button>
          </span>
        </div>

        <app-add-column-equation-input></app-add-column-equation-input>
      </div>
    </app-page-panel>

    <app-page-panel header="Preview">
      <app-equation-preview></app-equation-preview>
    </app-page-panel>
  </div>

  <div class="dialog-footer space-between">
    <button
      appButton
      type="button"
      buttonType="cancel"
      class="btn btn-primary btn-sm"
      [isUiLockable]="false"
      (buttonClick)="cancelClicked()"
      data-cy="btn-cancel-add-new-column"
    >
      Cancel
    </button>
    <button
      appButton
      type="button"
      buttonType="primary"
      class="btn btn-primary btn-sm"
      data-cy="confirm-modal-confirm"
      [isUiLockable]="false"
      (buttonClick)="onConfirm()"
    >
      Save
    </button>
  </div>
</div>

<div class="modal-help" [class.open]="isHelpOpen">
  <div class="help-content">
    <iframe [src]="'assets/help-files/data/equations.html' | helpUrl"></iframe>
  </div>
</div>
