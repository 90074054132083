export class Paragraph {
  constructor(public type: ParagraphType = ParagraphType.text) {
  }

  public texts: IParagraphText[] = [];
}

export class IParagraphText {
  public text = '';
  public isBold = false;
  public isItalic = false;
  public isUnderline = false;
}

export enum ParagraphType {
  text = 0,
  bullet = 1,
}

export abstract class BaseHtmlParser {
  protected paragraphs: Paragraph[] = [];

  constructor(htmlString: string) {
    try {
      this.parseHtml(htmlString);
    } catch (error) {
      console.error(error);
    }
  }

  private parseHtml(htmlString: string): void {
    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(htmlString, 'text/html');
    this.paragraphs = [];

    const nodes = Array.from(htmlDoc.body.childNodes);

    for (const node of nodes) {
      if (node.nodeName === 'P') {
        const paragraphDoc = new Paragraph();
        this.handleNode(paragraphDoc, node, new IParagraphText());
        this.paragraphs.push(paragraphDoc);
      } else if (node.nodeName === 'UL') {
        for (const childNode of Array.from(node.childNodes)) {
          const paragraphDoc = new Paragraph(ParagraphType.bullet);
          this.handleNode(paragraphDoc, childNode, new IParagraphText());
          this.paragraphs.push(paragraphDoc);
        }
      } else if (node.nodeName === 'OL') {
        for (let i = 0; i < node.childNodes.length; i++) {
          const childNode = node.childNodes[i];
          const paragraphDoc = new Paragraph(ParagraphType.bullet);
          this.handleNode(paragraphDoc, childNode, new IParagraphText());
          this.paragraphs.push(paragraphDoc);
        }
      }
    }
  }

  private handleNode(paragraphDoc: Paragraph, node: any, text: IParagraphText): void {
    if (node == null) {
      return;
    }
    let currentText = { ...text };

    if (node.nodeType === Node.TEXT_NODE) {
      currentText.text = node.textContent ?? '';
      paragraphDoc.texts.push(currentText);
      // reset current options
      currentText = new IParagraphText();
    } else if (node.tagName === 'STRONG') {
      currentText.isBold = true;
    } else if (node.tagName === 'EM') {
      currentText.isItalic = true;
    } else if (node.tagName === 'U') {
      currentText.isUnderline = true;
    }

    const childNodes = Array.from(node.childNodes);
    for (const child of childNodes) {
      this.handleNode(paragraphDoc, child, currentText);
    }
  }

  public abstract render(): any[];
}
