import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ImportDataModuleState } from './import-data-module.state';
import { ImportDataValidation } from './validation/import-data.validation';
import { getStorageColumnNames } from '../data-storage/data-storage.selectors';
import { getCurrentAppModuleType } from '../ui/ui.selectors';
import { getFileHash } from '../backend-connection/backend-connection.selectors';
import { getCurrentScenarioId } from '../scenario/scenario.selectors';
import { getCurrentRangeId } from '../range/range.selectors';
import { ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import { RangeConstants } from '@dunefront/common/dto/range.dto';

export const getImportDataModuleState = createFeatureSelector<ImportDataModuleState>('importData');
export const getValidatedImportModuleState = createSelector(getImportDataModuleState, getStorageColumnNames, (...[state, columnNames]) =>
  ImportDataValidation.validate(state, columnNames),
);

export const getImportDataTemplates = createSelector(getValidatedImportModuleState, (state) => state.templates);

export const getImportDataTemplateNames = createSelector(getImportDataModuleState, (state) => state.templates.map((t) => t.Name));

export const getDeleteResultsParams = createSelector(
  getFileHash,
  getCurrentScenarioId,
  getCurrentAppModuleType,
  getCurrentRangeId,
  (...[fileHash, scenarioId, moduleType, rangeId]): IDeleteResultsParams => ({
    fileHash,
    scenarioId,
    moduleType,
    rangeId: rangeId ?? RangeConstants.EmptyRangeId,
  }),
);

export interface IDeleteResultsParams {
  fileHash: string | undefined;
  scenarioId: number;
  moduleType: ModuleType;
  rangeId: number;
}
