import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import { distinctUntilChanged } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { InputsHelperService } from '../../services/inputs-helper.service';
import { isUiLockedOrRequestInProgress } from '../../../+store/ui/calc-engine-ui.selectors';
import { getIsCurrentScenarioCalculationActive } from '../../../+store/reporting/reporting.selectors';

export type ButtonType = 'primary' | 'secondary' | 'cancel';

@Directive()
export class ButtonBaseComponent implements AfterViewInit, OnDestroy, OnChanges {
  public buttonRef: ElementRef | undefined;

  @Input() public disabled: boolean | undefined = false;
  @Input() public isUiLockable = true;
  @Input() public isDeleteResultsEnabled = true;
  @Input() public isDisabledOnRequest = false;
  @Input() public isDisabledWhenCalculationActive = true;
  @Input() public buttonType: ButtonType = 'secondary';
  @Input() public stopPropagation = false;
  @Input() public isMenuButton = false;

  @Output() public buttonClick = new EventEmitter<Event | KeyboardEvent | void>();
  @Output() public contextMenuClick = new EventEmitter<Event | KeyboardEvent | void>();

  private isRequestActive = false;
  private isCalculationActive = false;
  private nativeElement: HTMLElement | undefined;

  public subscription = new Subscription();
  public isUiLocked = false;

  protected constructor(
    public store: Store,
    public inputsHelperService: InputsHelperService,
    public renderer?: Renderer2,
    public el?: ElementRef,
  ) {}

  public ngAfterViewInit(): void {
    this.nativeElement = this.el ? this.el.nativeElement : this.buttonRef?.nativeElement;

    this.subscription.add(
      this.store
        .select(isUiLockedOrRequestInProgress)
        .pipe(distinctUntilChanged((s1, s2) => s1.isUiLocked === s2.isUiLocked && s1.isRequestActive === s2.isRequestActive))
        .subscribe((state) => {
          this.isUiLocked = state.isUiLocked;
          this.isRequestActive = state.isRequestActive;

          this.enableDisableButton();
        }),
    );

    this.subscription.add(
      this.store.select(getIsCurrentScenarioCalculationActive).subscribe((isCalculationActive) => {
        this.isCalculationActive = isCalculationActive;
        this.enableDisableButton();
      }),
    );

    if (this.buttonType === 'cancel' && this.el && this.renderer) {
      this.renderer.addClass(this.el.nativeElement, 'btn-cancel');
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.enableDisableButton();
  }

  public enableDisableButton(): void {
    if (!this.nativeElement) {
      return;
    }

    (this.nativeElement as HTMLButtonElement).disabled =
      this.disabled ||
      (this.isUiLockable && this.isUiLocked) ||
      (this.isDisabledOnRequest && this.isRequestActive) ||
      (this.isDisabledWhenCalculationActive && this.isCalculationActive && this.buttonType !== 'cancel');
  }

  public async onClick(event: MouseEvent): Promise<void> {
    if (this.buttonRef) {
      this.buttonRef.nativeElement.blur();
    }
    await this.emitEvent(event);
  }

  public async emitEvent(event: MouseEvent | KeyboardEvent): Promise<void> {
    if (this.stopPropagation) {
      event.stopPropagation();
    }
    event.preventDefault();

    if (this.isDeleteResultsEnabled && !(await this.inputsHelperService.checkResultsAndDeleteIfNeeded(this.isUiLockable))) {
      return;
    }

    this.buttonClick.emit(event);
  }

  public async onKeyPressed(event: KeyboardEvent): Promise<void> {
    if (
      (this.buttonType === 'cancel' && event.key === 'Escape') ||
      (this.buttonType === 'primary' && event.key === 'Enter' && !this.disabled)
    ) {
      await this.emitEvent(event);
    }
  }
}
