import { IModuleState, IValidatedModuleState } from '../../../common/common-state.interfaces';
import { MASPCalculationType, MASPCalculatorDto } from './masp-calculator.dto';

export type MASPCalculatorModuleState = MASPCalculatorDto & IModuleState;

export type ValidatedMaspCalculatorModuleState = MASPCalculatorModuleState & IValidatedModuleState<MASPCalculatorModuleState>;

export const MASPCalculatorModuleInitialState: MASPCalculatorModuleState = {
  isLoaded: false,
  MaxAllowableSurfacePressure: 0,
  CollapseRating: 0,
  Concentration: 0,
  FluidId: -1,
  GravelId: -1,
  InternalPressure: 0,
  MASPCalculationType: MASPCalculationType.Absolute_Pressure,
  MaxPressureAtTVD: 0,
  SafetyFactor: 0,
  ScenarioId: -1,
  TVD: 0,
};
