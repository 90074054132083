import { ModalService } from '../../../common-modules/modals/modal.service';
import { isEqual } from 'lodash';
import { IUnitSystemDto, UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { UnitConverterHelper } from '@dunefront/common/unit-converters/unit.converter.helper';
import { IGridColumnConfig } from './grid.interfaces';

export class GridHelpers {
  constructor(private modalService: ModalService) {}

  public canRowsBeAdded(selectedRows: string[]): boolean {
    if (selectedRows.length <= 0) {
      return false;
    }
    if (selectedRows.length > 1) {
      this.modalService.showAlert('Select only one row', 'Information').then();
      return false;
    }

    return true;
  }

  public canRowsBePasted(refRowIndex: number, isFirstRowPasteAllowed: boolean): boolean {
    if (!isFirstRowPasteAllowed && refRowIndex === 0) {
      this.modalService.showAlert("Data can't be pasted in the first row", 'Information').then();
      return false;
    }
    return true;
  }

  public canRowsBeMerged(selectedRowsIndexes: number[]): boolean {
    const areSelectedRowsConsecutive = Math.max(...selectedRowsIndexes) - Math.min(...selectedRowsIndexes) === selectedRowsIndexes.length - 1;

    if (!areSelectedRowsConsecutive) {
      this.modalService.showAlert('Only consecutive rows can be merged', 'Information').then();
      return false;
    }
    return true;
  }

  public canRowsBeInserted(selectedRowIndexes: number[], isFirstRowInsertAllowed: boolean, isRefNodeBased: boolean): boolean {
    if (!isRefNodeBased) {
      return true;
    }
    if (!isFirstRowInsertAllowed && selectedRowIndexes[0] === 0) {
      this.modalService.showAlert("You can't insert above the first row", 'Information').then();
      return false;
    }
    if (selectedRowIndexes.length <= 0) {
      this.modalService.showAlert('Select at least one row', 'Information').then();
      return false;
    }
    if (selectedRowIndexes.length > 1) {
      this.modalService.showAlert('Select only one row', 'Information').then();
      return false;
    }
    return true;
  }

  public async canRowsBeDeleted(
    selectedRowIndexes: number[],
    dataRowCount: number,
    minRowCount: number,
    isFirstRowDeleteAllowed: boolean,
    isInsertRowSelected = false,
  ): Promise<boolean> {
    if (selectedRowIndexes.length <= 0) {
      return false;
    }
    if (!isFirstRowDeleteAllowed && selectedRowIndexes.includes(0)) {
      this.modalService.showAlert("The first row can't be deleted", 'Information').then();
      return false;
    }
    const selectedRowsIndexesLength = selectedRowIndexes.length - (isInsertRowSelected ? 1 : 0);
    if (minRowCount > 0 && dataRowCount - selectedRowsIndexesLength < minRowCount) {
      this.modalService.showAlert(`At least ${minRowCount} row(s) must remain in the grid`, 'Information').then();
      return false;
    }

    return await this.modalService.showConfirm('This will permanently delete the selected row(s) - do you want to continue?', 'Delete Row(s)');
  }

  // Check if pasted data has the same dimensions as selected cells
  public isDataFitSelection(selectedCells: Set<string>, clipboardData: string): boolean {
    const selectionDimensions = this.getSelectionDimensions(selectedCells);
    const isSelectionSymmetric = selectionDimensions != null;

    const isSingleCellSelected = selectedCells.size === 1;

    const cellsSelected: number[] = [];
    const rowsSelected: number[] = [];

    Array.from(selectedCells).forEach((selectedCell) => {
      const [row, cell] = this.decodeCellPosition(selectedCell);
      cellsSelected.push(cell);
      rowsSelected.push(row);
    });

    const isSingleRowSelected = cellsSelected.includes(0) && rowsSelected.every((r) => r === rowsSelected[0]);

    if (isSingleCellSelected || isSingleRowSelected) {
      return true;
    }

    const parsedClipboardData = clipboardData
      .split('\n')
      .map((r) => r.split('\t'))
      .filter((r) => r.some((c) => c.length));
    const isPastedDataSymmetric = parsedClipboardData.every((row) => row.length === parsedClipboardData[0].length);

    if (!isSelectionSymmetric || !isPastedDataSymmetric) {
      return false;
    }

    const pastedDataDimensions = [parsedClipboardData.length, parsedClipboardData[0].length];

    const [dataRowDimension, dataColDimension] = pastedDataDimensions;
    const [selRowDimension, selColDimension] = selectionDimensions;

    return dataRowDimension === selRowDimension && dataColDimension === selColDimension;
  }

  // return dimensions of selected cells in format [ rows x cols ]
  // return null if selection is not symmetric
  public getSelectionDimensions(selectedCells: Set<string>): [number, number] | null {
    if (selectedCells.size === 0) {
      return [0, 0];
    }

    const rowsMap: { [key: string]: number[] } = {};

    // create and object like { rowIndex: columnIndex[] }
    Array.from(selectedCells).forEach((selectedCell) => {
      const [row, cell] = this.decodeCellPosition(selectedCell);
      if (rowsMap[row] == null) {
        rowsMap[row] = [];
      }
      rowsMap[row].push(cell);
    });

    const sortRowCells = (arr: number[]): number[] => arr.sort((a, b) => a - b);

    const rows = Object.keys(rowsMap);

    if (!rows.length) {
      return null;
    }
    if (rows.length < 2) {
      return [1, rows[0].length];
    }

    const isSymmetric = rows.every((row) => isEqual(sortRowCells(rowsMap[rows[0]]), sortRowCells(rowsMap[row])));

    if (!isSymmetric) {
      return null;
    }

    return [rows.length, rowsMap[rows[0]].length];
  }

  public encodeCellPosition(rowIndex: number, cellIndex: number): string {
    return `${rowIndex}:${cellIndex}`;
  }

  public decodeCellPosition(rowPosition: string): number[] {
    return rowPosition.split(':').map((pos) => +pos);
  }

  public getMostDistantCell(selectedCell: string, cellCoordinatesList: Set<string>): string {
    // Calculate Euclidean distance between two cells
    const calculateDistance = (cell1: string, cell2: string): number => {
      const [c1Row, c1Col] = this.decodeCellPosition(cell1);
      const [c2Row, c2Col] = this.decodeCellPosition(cell2);

      const deltaX = c1Row - c2Row;
      const deltaY = c1Col - c2Col;
      return Math.sqrt(deltaX * deltaX + deltaY * deltaY);
    };

    // Initialize variables to store the maximum distance and corresponding cell coordinates
    let maxDistance = -1;
    let mostDistantCell = selectedCell;

    // Iterate through the list of cell coordinates
    for (const cell of cellCoordinatesList) {
      // Calculate distance between the selected cell and the current cell
      const distance = calculateDistance(selectedCell, cell);
      // Update maxDistance and mostDistantCell if the current cell is farther away
      if (distance > maxDistance) {
        maxDistance = distance;
        mostDistantCell = cell;
      }
    }

    return mostDistantCell;
  }

  public getUnitSystemText(colConfig?: IGridColumnConfig<any>, currentUnitSystem?: IUnitSystemDto): string {
    if (colConfig && colConfig.overrideUnitSymbol) {
      return colConfig.overrideUnitSymbol;
    }

    if (!colConfig || !currentUnitSystem || colConfig.unitSystem === undefined || colConfig.unitSystem === UnitSystem.None) {
      return '&nbsp;';
    }

    if (colConfig.overrideUnitType != null && colConfig.overrideUnitType > 0) {
      return UnitConverterHelper.getUnitConverter(colConfig.unitSystem).getSymbol(colConfig.overrideUnitType);
    }

    return UnitConverterHelper.getUnitConverter(colConfig.unitSystem).getSymbol(
      UnitConverterHelper.getCurrentUnit(colConfig.unitSystem, currentUnitSystem),
    );
  }
}
