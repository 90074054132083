import { getHelpAction, getHelpUrlFailAction, getHelpUrlSuccessAction, openOrCloseHelpPanelAction } from './help.actions';
import { HelpModuleState, initialHelpModuleState } from './help.interface';
import { createReducer, on } from '@ngrx/store';
import { loadCommonDbInitialSuccess } from '../common-db/common-db.actions';

export const helpReducer = createReducer<HelpModuleState>(
  initialHelpModuleState,
  on(
    openOrCloseHelpPanelAction,
    (state): HelpModuleState => ({
      ...state,
      isOpen: !state.isOpen,
    }),
  ),
  on(getHelpAction, (state): HelpModuleState => ({ ...state, isLoading: true })),
  on(
    getHelpUrlSuccessAction,
    (state, action): HelpModuleState => ({
      ...state,
      helpUrl: action.helpUrl,
      isLoading: false,
    }),
  ),
  on(
    getHelpUrlFailAction,
    (state, action): HelpModuleState => ({
      ...state,
      error: action.error,
      isLoading: false,
    }),
  ),
  on(
    loadCommonDbInitialSuccess,
    (state, action): HelpModuleState => ({
      ...state,
      isOpen: action.payload.customSettings.pinSettings?.isHelpPinned ? true : state.isOpen,
    }),
  ),
);
