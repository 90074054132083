import { ErrorHandler, Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import {
  CrashBackendAction,
  CrashCalcEngineAction,
  errorTestingModuleName,
  FreezeBackendAction,
  PingPongAction,
} from '@dunefront/common/modules/error-testing/error-testing-module.actions';
import { Store } from '@ngrx/store';
import { BackendConnectionService } from '../../shared/backend-connection/backend-connection.service';
import { ModalService } from '../../common-modules/modals/modal.service';
import { BaseWsEffects } from '../base-ws.effects';
import { ClientErrorHandlerService } from '../../shared/components/error/client-error-handler.service';
import {
  freezeBackend,
  triggerBackendError,
  triggerCalcEngineShortWorkerError,
  triggerFrontendError,
  triggerPing,
} from './error-testing.actions';
import { of } from 'rxjs';
import { dataFailed, noopAction } from '../app.actions';

@Injectable()
export class ErrorTestingEffects extends BaseWsEffects {
  constructor(
    actions$: Actions,
    store: Store,
    wsService: BackendConnectionService,
    modalService: ModalService,
    @Inject(ErrorHandler) private errorHandlerService: ClientErrorHandlerService,
  ) {
    super(actions$, wsService, errorTestingModuleName, false, false, modalService, store);
  }

  public triggerFrontendError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(triggerFrontendError),
        tap((action) => {
          throw new Error(action.errorMessage);
        }),
      ),
    { dispatch: false },
  );

  public triggerBackendError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(triggerBackendError),
      mergeMap((action) =>
        this.emit<void>(new CrashBackendAction(action.errorMessage)).pipe(
          map((result) => noopAction()),
          catchError((err) => of(dataFailed(err))),
        ),
      ),
    ),
  );

  public triggerPing$ = createEffect(() =>
    this.actions$.pipe(
      ofType(triggerPing),
      mergeMap((action) =>
        this.emit<string>(new PingPongAction(action.inputData)).pipe(
          map((result) => {
            this.modalService.showAlert(result?.payload || '', 'Pong returned value', 'lg');
            return noopAction();
          }),
          catchError((err) => of(dataFailed(err))),
        ),
      ),
    ),
  );

  public triggerCalcEngineError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(triggerCalcEngineShortWorkerError),
      mergeMap((action) =>
        this.emit<void>(new CrashCalcEngineAction(action.ignoreSentry, action.errorType)).pipe(
          map((result) => noopAction()),
          catchError((err) => of(dataFailed(err))),
        ),
      ),
    ),
  );

  public freezeBackend$ = createEffect(() =>
    this.actions$.pipe(
      ofType(freezeBackend),
      mergeMap((action) =>
        this.emit<void>(new FreezeBackendAction()).pipe(
          map((result) => noopAction()),
          catchError((err) => of(dataFailed(err))),
        ),
      ),
    ),
  );
}
