import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap } from 'rxjs/operators';
import {
  CalculateLeakoffCoefficientAction,
  LeakoffCoefficientCalculatorModuleName,
  UpdateLeakoffCoefficientCalculatorAction,
} from '@dunefront/common/modules/calculators/leakoff-coefficient-calculator/leakoff-coefficient-calculator-module.action';
import { Store } from '@ngrx/store';
import { BaseWsEffects } from '../../base-ws.effects';
import { BackendConnectionService } from '../../../shared/backend-connection/backend-connection.service';
import {
  calculateLeakoffCoefficient,
  updateLeakoffCoefficientCalculatorRow,
} from '@dunefront/common/modules/calculators/leakoff-coefficient-calculator/leakoff-coefficient-calculator.actions';
import { Injectable } from '@angular/core';
import { ModalService } from '../../../common-modules/modals/modal.service';

@Injectable()
export class LeakoffCoefficientCalculatorEffects extends BaseWsEffects {
  constructor(actions$: Actions, store: Store, wsService: BackendConnectionService, modalService: ModalService) {
    super(actions$, wsService, LeakoffCoefficientCalculatorModuleName, false, true, modalService, store);
  }

  public updateLeakoffCoefficientCalculator$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateLeakoffCoefficientCalculatorRow),
      mergeMap((action) => this.emitUpdate(new UpdateLeakoffCoefficientCalculatorAction(action))),
    ),
  );

  public calculateLeakoffCoefficient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(calculateLeakoffCoefficient),
      mergeMap((action) => this.emitUpdate(new CalculateLeakoffCoefficientAction(action.ScenarioId), 'Calculating Leakoff Coefficient...')),
    ),
  );
}
