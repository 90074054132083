import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { getValidatedInjectionTestCalculatorModuleState } from '../../../../+store/calculators/injection-test-calculator/injection-test-calculator.selectors';
import * as actions from '../../../../+store/calculators/injection-test-calculator/injection-test-calculator.actions';
import { calculateInjectionTest } from '../../../../+store/calculators/injection-test-calculator/injection-test-calculator.actions';
import { ModalService } from '../../../../common-modules/modals/modal.service';
import { IValidatedInjectionTestCalculatorModuleState } from '@dunefront/common/modules/calculators/injection-test-calculator/injection-test-calculator-module.state';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { ErrorHelper } from '@dunefront/common/common/common-state.interfaces';
import { LeftNavHelperService } from '../../../../shared/components/left-nav/left-nav-helper.service';
import { PanelHelpMode } from '../../../../shared/components/help-button/help-buton.component';
import { PageWithGridComponent } from '../../../../shared/components/grid/page-with-grid.component';
import { InjectionTestReading } from '@dunefront/common/modules/calculators/injection-test-calculator/model/injection-test-reading';
import { ScreenService } from '../../../../shared/services';
import { ITableRow } from '@dunefront/common/common/common-grid.interfaces';
import { IUpdateTableRowsProps } from '@dunefront/common/common/common-store-crud.interfaces';

@Component({
  selector: 'app-injection-test-calculator',
  templateUrl: './injection-test-calculator.component.html',
  styleUrls: ['./injection-test-calculator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InjectionTestCalculatorComponent extends PageWithGridComponent<InjectionTestReading> {
  public state!: IValidatedInjectionTestCalculatorModuleState;
  public UnitType = UnitSystem;
  public PanelHelpMode = PanelHelpMode;

  constructor(
    store: Store,
    cdRef: ChangeDetectorRef,
    modalService: ModalService,
    public runCalculationService: LeftNavHelperService,
    resizeService: ScreenService,
  ) {
    super(store, cdRef, modalService, resizeService);

    this.subscription.add(
      store.select(getValidatedInjectionTestCalculatorModuleState).subscribe((state) => {
        this.state = state;
        cdRef.markForCheck();
      }),
    );

    this.subscription.add(runCalculationService.calculationSubject.subscribe(() => this.calculate()));
    this.onHelpChange('calculators', 'injection-test-calculator');
  }

  public async calculate(): Promise<void> {
    if (!this.state.isValid) {
      await this.modalService.showAlert(ErrorHelper.ERROR_MULTIPLE_SCREENS_MESSAGE_HEADER, 'Warning');
      return;
    }
    this.store.dispatch(calculateInjectionTest({ ScenarioId: this.state.InjectionTestCalculator.ScenarioId }));
  }

  public getRows(): ITableRow<InjectionTestReading>[] {
    return this.state.InjectionTestReadings.rows ?? [];
  }

  protected updateRow(row: IUpdateTableRowsProps<InjectionTestReading>): void {
    this.store.dispatch(actions.updateInjectionTestReadingsRowAction(row));
  }
}
