import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnChanges } from '@angular/core';
import { ITableState } from '@dunefront/common/common/common-grid.interfaces';
import { ColumnType, ICustomKeyboardHandlers, IGridColumnConfig } from '../../../../../shared/components/grid/grid.interfaces';
import { Store } from '@ngrx/store';
import { ModalService } from '../../../../../common-modules/modals/modal.service';
import { GridConfig } from '../../../../../shared/components/grid/grid-config';
import { CommonFluidModel } from '../../../../../+store/common-db/model/common-fluid.model';
import { ScreenService } from '../../../../../shared/services';
import { GridContainerComponent } from '../../../../../shared/components/grid/grid-container.component';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { GridResizeService } from '../../../../../shared/services/grid-resize.service';

@Component({
  selector: 'app-database-fluids-grid',
  templateUrl: './database-fluids-grid.component.html',
  styleUrls: ['./database-fluids-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatabaseFluidsGridComponent extends GridContainerComponent<CommonFluidModel> implements OnChanges, AfterViewInit {
  @Input() public commonFluidData: ITableState<CommonFluidModel> = { rows: [], isValid: true };
  @Input() public customKeyboardHandlers: ICustomKeyboardHandlers | undefined;

  private _columns: IGridColumnConfig<CommonFluidModel>[] = [
    { disabled: false, visible: true, colId: ' ', type: ColumnType.selection },
    {
      disabled: false,
      visible: false,
      colId: 'commonDbType',
      type: ColumnType.text,
      align: 'left',
      headerText: 'Source',
    },
    {
      disabled: false,
      visible: true,
      colId: 'Name',
      type: ColumnType.text,
      align: 'left',
      headerText: 'Fluid Name',
      width: 300,
    },
    {
      disabled: false,
      visible: true,
      colId: 'TypeName',
      type: ColumnType.text,
      align: 'left',
      headerText: 'Fluid Type',
    },
    {
      disabled: false,
      visible: true,
      colId: 'CleanFluidDensity',
      type: ColumnType.number,
      headerText: 'Density',
      unitSystem: UnitSystem.Liquid_Density,
      decimalPlaces: 3,
    },
    {
      disabled: false,
      visible: true,
      colId: 'DragReductionFactor',
      type: ColumnType.number,
      headerText: 'Drag Reduction',
      unitSystem: UnitSystem.None,
      overrideUnitSymbol: '%',
      decimalPlaces: 3,
    },
    {
      disabled: false,
      visible: true,
      colId: 'ViscositySurface',
      type: ColumnType.number,
      headerText: 'Viscosity at Surface',
      unitSystem: UnitSystem.Viscosity,
      decimalPlaces: 4,
    },
    {
      disabled: false,
      visible: true,
      colId: 'ViscosityBHCT',
      type: ColumnType.number,
      headerText: 'Viscosity at BHCT',
      unitSystem: UnitSystem.Viscosity,
      decimalPlaces: 4,
    },
  ];

  constructor(
    store: Store,
    cdRef: ChangeDetectorRef,
    protected modalService: ModalService,
    el: ElementRef,
    protected resizeService: ScreenService,
    gridResizeService: GridResizeService,
  ) {
    super(store, cdRef, el, new DatabaseFluidsGridConfig(store, modalService), gridResizeService);
  }

  protected getColumns(): IGridColumnConfig<CommonFluidModel>[] {
    return this._columns;
  }
}

export class DatabaseFluidsGridConfig extends GridConfig<CommonFluidModel> {
  constructor(
    private store: Store,
    modalService: ModalService,
  ) {
    super(modalService);
  }
}
