import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { getBackendToClientConfig } from '@dunefront/common/backend-to-client-config';
import { firstValueFrom } from 'rxjs';
import { ClientAuthService } from '../../common-modules/auth/client-auth.service';

@Injectable()
export class ApiService {
  private readonly apiUrl: string;

  constructor(
    public http: HttpClient,
    private authService: ClientAuthService,
  ) {
    this.apiUrl = getBackendToClientConfig().ServerBaseUrl;
  }

  private get headers(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.authService.accessToken}`,
      ppsessionid: this.authService.sessionId,
    });
  }

  public getActionUrl(controller: string, action: string): string {
    return `${this.apiUrl}/${controller}/${action}`;
  }

  public async resendVerificationEmail(): Promise<void> {
    await firstValueFrom(this.http.get(this.getActionUrl('auth', 'resend-verification-email'), { headers: this.headers }));
  }
}
