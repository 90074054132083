export interface ReportInfoDto {
  ScenarioId: number;
  Well: string;
  Field: string;
  Country: string;
  PreparedByName: string;
  PreparedByCompany: string;
  PreparedByPhone: string;
  PreparedByEmail: string;
  PreparedForName: string;
  PreparedForCompany: string;
  PreparedForPhone: string;
  PreparedForEmail: string;
}

export type ReportFileType = 'docx' | 'pptx';

export interface ReportInfoFields {
  IsWell: boolean;
  IsWellSurveyData: boolean;
  IsWellCaliperData: boolean;
  IsFluids: boolean;
  IsPumping: boolean;
  IsCompletion: boolean;
  IsGravel: boolean;
  IsSettings: boolean;
  IsSimulationAnimation: boolean;
  IsEvaluationAnimation: boolean;
  IsWellVisualization: boolean;
  IsSummary: boolean;
  IsCharts: boolean;
  IsImportedDataInformation: boolean;
  Date: number;
  IsPsdInput: boolean;
  IsPsdAnalysis: boolean;
  IsPsdSummary: boolean;
  IsPsdScreenAndGravelSelection: boolean;
  IsTrendAnalysisInputs: boolean;
  RangeIds: number[] | null;
  ReportingTabIds: number[] | null;
  ReportType: ReportFileType;
}

export const createEmptyReportInfoDto = (): ReportInfoDto => {
  return {
    ScenarioId: -1,
    Well: '',
    Field: '',
    Country: '',
    PreparedByName: '',
    PreparedByCompany: '',
    PreparedByPhone: '',
    PreparedByEmail: '',
    PreparedForName: '',
    PreparedForCompany: '',
    PreparedForPhone: '',
    PreparedForEmail: '',
  };
};

export const createEmptyReportInfoFields = (): ReportInfoFields => {
  return {
    IsWell: false,
    IsWellSurveyData: false,
    IsWellCaliperData: false,
    IsFluids: false,
    IsPumping: false,
    IsCompletion: false,
    IsGravel: false,
    IsSettings: false,
    IsSimulationAnimation: false,
    IsEvaluationAnimation: false,
    IsWellVisualization: false,
    IsSummary: false,
    IsCharts: false,
    IsImportedDataInformation: false,
    Date: 0,
    IsPsdInput: false,
    IsPsdAnalysis: false,
    IsPsdSummary: false,
    IsPsdScreenAndGravelSelection: false,
    IsTrendAnalysisInputs: false,
    RangeIds: null,
    ReportingTabIds: null,
    ReportType: 'docx',
  };
};
