import { DataFileType, DataType } from './data-storage';
import { ModuleType } from '../modules/scenario/scenario.dto';
import { ChartAxis, XAxisFormat } from '../modules/reporting/dto/chart-axis-property.dto';
import { IBaseTemplateDto } from '../common/templates/templates.interfaces';
import { GaugeAnnotation } from '../modules/data-storage/dto/import-column.dto';

export interface IChartTemplateSeriesDto {
  chartAxis: ChartAxis;
  seriesName?: string;
  dataType: DataType;
  fileType?: DataFileType;
  gaugeAnnotation?: GaugeAnnotation;
}

export interface IGaugeAnnotationData {
  packerBottomMD: number;
  packerTopMD: number;
  dataTypesToAnnotate: DataType[];
}

export interface IChartTemplateAxis {
  axisType: ChartAxis;
  xAxisFormat: XAxisFormat;
  isLogarithmic: boolean;
}

export interface IChartTemplateProperties {
  axis?: IChartTemplateAxis[];
  isVolumeMode?: boolean;
  isTvdMode?: boolean;
  isOptimizeActive?: boolean;
  isCompareScenarioActive?: boolean;
}

export interface IChartTemplateDto extends IBaseTemplateDto {
  DefaultFileTypes: DataFileType[];
  IsTimeVolume: boolean;
  Modules: ModuleType[];
  ChartType: ChartTemplateType;
  Series: IChartTemplateSeriesDto[];
  Properties: IChartTemplateProperties;
  Labels: string;
}

export enum ChartTemplateType {
  None = 0,
  EquivalentCirculatingDensity = 1,
  WorkstringAnnulusHydrostaticDifference = 2,
  StaticAndCirculatingTemperature = 3,
  ReservoirAndFracturePressure = 4,
  WellSurvey = 5,
  ShuntTubeTotalPressures = 6,
  SurfacePumpingData = 7,
  SystemFrictionPressure = 8,
  DuneFrontLocation = 9,
  FluidVelocity = 10,
  PerforationPacking = 11,
  InternalAndExternalFlowProfile = 12,
  WellborePacking = 13,
  TotalPressures = 14,
  TotalFrictionPressures = 15,
  NormalizedSectionFrictionPressures = 16,
  Temperatures = 17,
  TemperatureTimeDerivative = 18,
  TemperatureVolumeDerivative = 19,
  PressureTimeDerivative = 20,
  PressureVolumeDerivative = 21,
  SectionFrictionPressures = 22,

  UserDefinedChart = 1000,
}
