<app-grid
  *ngIf="height > 0 && caliperData"
  [dataSource]="caliperData"
  [columns]="gridConfig.columns"
  [gridConfig]="gridConfig"
  [showCrudControls]="false"
  [height]="height"
  [isFirstRowDeleteAllowed]="true"
  [isFirstRowInsertingAllowed]="true"
  [isPastingEnabled]="true"
  dataCy="caliper-grid"
>
</app-grid>
