<div
  class="menu-item-wrapper"
  *ngIf="element"
  [ngClass]="{
    open: isSideNavExpanded,
    'select-module': element.label === 'Select module',
    home: element.label === 'Home',
    dataPro: isDataPro
  }"
>
  <a
    class="menu-item"
    (keydown.enter)="$event.preventDefault()"
    [routerLink]="getRouterLink()"
    [class.menu-item-accent]="isSelected()"
    (click)="onMenuItemClicked()"
    [class.disabled]="isDisabled"
    [attr.data-cy]="(element.uri || 'home') + '-link'"
  >
    <div class="icon-left-border"></div>
    <span class="icon-wrapper">
      <span class="error-row-icon nav-error-icon" *ngIf="element.isValid === false"></span>
      <span class="warning-row-icon nav-error-icon" *ngIf="element.isValid && element.isWarning"></span>
      <span class="{{ element.icon }} icon-light"></span>
    </span>
    <p-menu styleClass="left-nav-context-menu" #contextMenu [popup]="true" appendTo="body"
            [model]="element.children"></p-menu>
    <p class="main-label">{{ element.label }}</p>
  </a>

  <ul
    class="sub-menu"
    [ngStyle]="{ maxHeight: isSubMenuExpanded && isSideNavExpanded ? subMenuItemsHeight : 0 }"
    cdkDropList
    [attr.data-cy]="'sub-menu_' + element.uri"
    [class.opened]="isSubMenuExpanded && isSideNavExpanded"
    [cdkDropListAutoScrollDisabled]="true"
    [cdkDropListDisabled]="!element.isDraggable"
    (cdkDropListDropped)="onDrop(element.draggableType, $event)"
  >
    <li
      *ngFor="let item of childrenItems; trackBy: menuItemTrackBy"
      [class.link-error]="item.isError"
      [class.link-warn]="!item.isError && item.isWarning"
      class="sub-menu-item"
      cdkDrag
      [cdkDragLockAxis]="'y'"
      [cdkDragDisabled]="!item.isDraggable"
    >
      <div *cdkDragPlaceholder></div>

      <ng-container *ngIf="item.type === 'Break'; else itemBlock">
        <a  class="sub-menu-link item-break"></a>
      </ng-container>
      <ng-template #itemBlock>
        <a class="sub-menu-link"
           [routerLink]="item.routerLink"
           [routerLinkActive]="'active'"
           [class.p-disabled]="item.disabled"
           (click)="onSubMenuItemClicked()"
        >{{ item.label }}</a>
      </ng-template>

    </li>
  </ul>
</div>
