import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { MenuItem } from 'primeng/api';
import { ChartMenuProps } from '../../../../../../+store/menu-selectors/chart/menu-chart.selector';
import { isOptimizeActiveAction } from '../../../../../../+store/reporting/reporting.actions';
import { showOneTimeInstructionPopupAction } from '../../../../../../+store/ui/ui.actions';
import { OneTimeInstructionType } from '../../../../../../+store/ui/ui-module.state';

@Component({
  selector: 'app-optimize-button',
  templateUrl: './optimize-button.component.html',
  styleUrls: ['./optimize-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptimizeButtonComponent {
  @Input() public chartMenuProps!: ChartMenuProps;

  private optimizeEnableCurrentMenuItem = {
    label: 'Enable for this chart',
    command: (): void => {
      this.optimizeChart(true, false);
    },
  };

  private optimizeEnableAllMenuItem = {
    label: 'Enable for all charts in range',
    command: (): void => {
      this.optimizeChart(true, true);
    },
  };

  private optimizeDisableCurrentMenuItem = {
    label: 'Disable for this chart',
    command: (): void => {
      this.store.dispatch(isOptimizeActiveAction({ batchAction: false, isOptimizeActive: false }));
    },
  };

  private optimizeDisableAllMenuItem = {
    label: 'Disable for all charts in range',
    command: (): void => {
      this.store.dispatch(isOptimizeActiveAction({ batchAction: true, isOptimizeActive: false }));
    },
  };

  public optimizeAdvancedButtonMenuItemsWhenDisabled: MenuItem[] = [
    this.optimizeEnableCurrentMenuItem,
    this.optimizeEnableAllMenuItem,
    this.optimizeDisableAllMenuItem,
  ];

  public optimizeAdvancedButtonMenuItemsWhenEnabled: MenuItem[] = [
    this.optimizeDisableCurrentMenuItem,
    this.optimizeDisableAllMenuItem,
    this.optimizeEnableAllMenuItem,
  ];

  constructor(private store: Store) {}

  public optimizeChart(isOptimizeActive: boolean, batchAction = false): void {
    this.store.dispatch(isOptimizeActiveAction({ batchAction, isOptimizeActive }));
    if (isOptimizeActive === true) {
      this.store.dispatch(showOneTimeInstructionPopupAction({ instructionType: OneTimeInstructionType.optimize }));
    }
  }
}
