import { WsAction } from '../../ws.action';
import { AdvancedValidationJobInputData } from './dto/validation.dto';
import { ModuleType } from '../scenario/scenario.dto';
import { IGetCurrentFeaturesResult } from '../licensing/licensing.interfaces';

export const ValidationModuleName = 'Validation';

export enum AdvancedValidationActionTypes {
  ValidateScenarioQuick = '[Validation] AdvancedValidation',
  ValidateSimulations = '[Validation] ValidateSimulations',
}

export class AdvancedValidationAction extends WsAction {
  public override readonly type = AdvancedValidationActionTypes.ValidateScenarioQuick;

  constructor(
    public scenarioId: number,
    public inputData: AdvancedValidationJobInputData,
    public moduleType: ModuleType,
  ) {
    super();
  }
}

export class ValidateSimulationsAction extends WsAction {
  public override readonly type = AdvancedValidationActionTypes.ValidateSimulations;
  constructor(
    public scenarioIds: number[],
    public moduleType: ModuleType,
    public currentLicenseFeatures: IGetCurrentFeaturesResult,
  ) {
    super();
  }
}

export interface ISimulationValidationResponse {
  scenarioId: number;
  errorMessage: string;
}

export type ValidationActions = AdvancedValidationAction | ValidateSimulationsAction;
