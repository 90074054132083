import { UnitConverter } from '../../converter.interfaces';
import { TemperatureUnit } from '../../../dto/unit-system.dto';

export class TemperatureConverter extends UnitConverter {
  public static symbols: Array<string> = ['', '°C', 'K', '°F'];

  public static unit = TemperatureUnit;

  public static fromSi(value: number, unit: TemperatureUnit): number {
    switch (unit) {
      case TemperatureUnit.Celsius: {
        return value;
      }
      case TemperatureUnit.Kelvin: {
        return value + 273.15;
      }
      case TemperatureUnit.Fahrenheit: {
        return value * 1.8 + 32;
      }
      default: {
        throw new TypeError('Unknown Temperature Unit:' + unit);
      }
    }
  }

  public static toSi(value: number, unit: TemperatureUnit): number {
    switch (unit) {
      case TemperatureUnit.Celsius: {
        return value;
      }
      case TemperatureUnit.Kelvin: {
        return value - 273.15;
      }
      case TemperatureUnit.Fahrenheit: {
        return (value - 32) / 1.8;
      }
      default: {
        throw new TypeError('Unknown Temperature Unit:' + unit);
      }
    }
  }

  public static getSymbol(unit: TemperatureUnit): string {
    return this.symbols[unit];
  }
}
