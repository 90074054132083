import { DbDependentComponent } from '../../../../common-modules/db-connection/db-dependent.component';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import * as actions from '../../../../+store/gravel/gravel.actions';
import { ModalService } from '../../../../common-modules/modals/modal.service';
import { take } from 'rxjs/operators';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { notEmpty } from '@dunefront/common/common/state.helpers';
import { getIsGravelStateLoaded, getSelectedGravelState } from '../../../../+store/gravel/gravel.selectors';
import * as commonFluidActions from '../../../../+store/common-db/common-db.actions';
import { isGravelDeletable } from '../../../../+store/gravel/gravel-delete.selectors';
import * as uiActions from '../../../../+store/ui/ui.actions';
import { Gravel } from '@dunefront/common/modules/gravel/model/gravel';
import { RouterHelperService } from '../../../../shared/services/router-helper.service';

import { ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';
import { StoreCrudPropsFactory } from '@dunefront/common/common/common-store-crud.interfaces';

@Component({
  selector: 'app-project-gravels',
  templateUrl: './project-gravels.component.html',
  styleUrls: ['./project-gravels.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectGravelsComponent extends DbDependentComponent {
  public currentGravel: Gravel | undefined;
  public UnitType = UnitSystem;
  public isGravelModuleLoaded$ = this.store.select(getIsGravelStateLoaded);

  constructor(
    store: Store,
    cdRef: ChangeDetectorRef,
    private modalService: ModalService,
    public routerHelperService: RouterHelperService,
  ) {
    super(store, cdRef);

    this.subscription.add(
      notEmpty(this.store.select(getSelectedGravelState)).subscribe((gravel) => {
        this.currentGravel = gravel;
        this.cdRef.markForCheck();
      }),
    );
    store.dispatch(uiActions.setGravelsTabAction({ tab: 'project-gravels' }));
    this.onHelpChange('gravels', 'gravel-properties');
  }

  private async checkIsValid(): Promise<boolean> {
    if (!this.currentGravel?.isValid) {
      await this.modalService.showAlert('Please correct all errors before saving the gravel to the database.');
    }
    return this.currentGravel?.isValid ?? true;
  }

  public async saveToDatabase(): Promise<void> {
    if (this.currentGravel == null) {
      return;
    }
    const isValid = await this.checkIsValid();
    if (!isValid) {
      return;
    }

    const commonDbType = await this.modalService.getCommonDbType('gravel');
    if (!commonDbType) {
      return;
    }

    this.store.dispatch(
      commonFluidActions.copyProjectGravelToCommon({
        projectGravelId: this.currentGravel.Id,
        commonDbType,
        overwrite: false,
      }),
    );
  }

  public async changeSelectedGravel(event: ObjectChangeProp<Gravel>): Promise<void> {
    const gravelId = event.value as number;
    await this.routerHelperService.navigateToGravel(gravelId);
  }

  public newGravel(): void {
    if (this.currentGravel == null) {
      return;
    }
    this.store.dispatch(actions.newGravel({ scenarioId: this.currentGravel.ScenarioId }));
  }

  public copyGravel(): void {
    if (this.currentGravel == null) {
      return;
    }
    this.store.dispatch(
      actions.copyGravel({
        gravelId: this.currentGravel.Id,
        scenarioId: this.currentGravel.ScenarioId,
        gravelName: this.currentGravel.Name,
      }),
    );
  }

  public async deleteGravel(): Promise<void> {
    this.store
      .select(isGravelDeletable)
      .pipe(take(1))
      .subscribe(async (gravelsDeletable) => {
        if (gravelsDeletable.length > 0) {
          await this.modalService.showAlert(gravelsDeletable, 'Information');
          return;
        }
        const confirmResult = await this.modalService.showConfirm('Are you sure you want to delete the selected gravel?', 'Delete Gravel');
        if (!confirmResult) {
          return;
        }
        if (this.currentGravel == null) {
          return;
        }

        this.store.dispatch(actions.deleteGravel(StoreCrudPropsFactory.deleteRows(this.currentGravel, false)));
      });
  }
}
