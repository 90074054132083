import { createAction, props } from '@ngrx/store';
import { ValidatedWellModuleState } from '@dunefront/common/modules/well/well-module.state';
import { WellDto } from '@dunefront/common/modules/well/dto/well.dto';
import { TemperatureProfile } from '@dunefront/common/modules/well/model/temperature-profile/temperature-profile';
import { Survey } from '@dunefront/common/modules/well/model/survey/survey';
import { PerforatedCasingPipe } from '@dunefront/common/modules/pipes/casing-pipes/pipes/perforated-casing-pipe';
import { Pipe } from '@dunefront/common/modules/pipes/pipe';
import { ZoneModel } from '@dunefront/common/modules/well/model/zone/zone.model';
import { Caliper } from '@dunefront/common/modules/well/model/caliper/caliper';
import { ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';
import { IUpdateRowsWsActionProps } from '@dunefront/common/ws.action';
import { IDeleteRowsProps, IInsertRowsProps, IUpdateTableRowsProps } from '@dunefront/common/common/common-store-crud.interfaces';
import { ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';

export const updateWell = createAction('[well] save', props<IUpdateRowsWsActionProps<WellDto>>());

export const changeWellPropertyAction = createAction('[well] changeProp', props<ObjectChangeProp<ValidatedWellModuleState>>());
export const changeTemperatureProfilePropertyAction = createAction(
  '[well] changeProp tempProfile',
  props<ObjectChangeProp<TemperatureProfile>>(),
);

// region survey grid

export const updateSurveyRowAction = createAction('[well] survey updateRow', props<IUpdateTableRowsProps<Survey>>());

export const insertSurveyRowsAction = createAction('[well] survey insertRows', props<IInsertRowsProps<Survey>>());

export const deleteSurveyRowsAction = createAction('[well] survey deleteRows', props<IDeleteRowsProps>());

export const importTemperatureProfileAction = createAction(
  '[well] survey importTempProfile',
  props<{ sourceScenarioId: number; sourceRangeId: number; sourceModuleType: ModuleType; bottomholeCirculating: number }>(),
);

export const importTemperatureProfileSuccessAction = createAction('[well] survey importTempProfileSuccess');

// endregion

// region casing grid

export const updateCasingRow = createAction('[well] casing updateRow', props<IUpdateTableRowsProps<Pipe | PerforatedCasingPipe>>());

export const insertCasingRow = createAction('[well] casing insertRows', props<IInsertRowsProps<Pipe>>());

export const deleteCasingRows = createAction('[well] casing deleteRows', props<IDeleteRowsProps>());

// endregion

// region zone grid

export const updateZoneRow = createAction('[well] zone updateRow', props<IUpdateTableRowsProps<ZoneModel>>());

export const insertZoneRows = createAction('[well] zone insertRows', props<IInsertRowsProps<ZoneModel>>());

export const deleteZoneRows = createAction('[well] zone deleteRows', props<IDeleteRowsProps>());

// endregion

// region caliper grid

export const updateCaliperRow = createAction('[well] caliper updateRow', props<IUpdateTableRowsProps<Caliper>>());

export const insertCaliperRow = createAction('[well] caliper insertRow', props<IInsertRowsProps<Caliper>>());

export const deleteCaliperRows = createAction('[well] caliper deleteRows', props<IDeleteRowsProps>());

// endregion
