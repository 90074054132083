import { createAction, props } from '@ngrx/store';
import { RangeDto } from '@dunefront/common/dto/range.dto';
import { VerticalShiftDto } from '@dunefront/common/dto/trend-analysis/vertical-shift.dto';
import { IDeleteRowsProps, IUpdateRowsProps } from '@dunefront/common/common/common-store-crud.interfaces';
import { ScenarioRangePropertiesDto } from '@dunefront/common/dto/scenario-range-properties.dto';
import { DataSourceKey } from '@dunefront/common/common/common-state.interfaces';

export const changeCurrentRangeIdAction = createAction('[Range] ChangeCurrentRangeId', props<{ rangeId: number }>());
export const editRangeAction = createAction('[Range] EditRange');
export const updateEditRangeAction = createAction('[Range] UpdateEditRange', props<{ range: RangeDto }>());
export const cancelEditRangeAction = createAction('[Range] CancelEditRange');
export const saveRangeAction = createAction('[Range] SaveRange', props<IUpdateRowsProps<RangeDto>>());

export const newRangeAction = createAction('[Range] NewRange', props<{ scenarioId: number }>());
export const deleteRangeAction = createAction('[Range] DeleteRange', props<IDeleteRowsProps>());
export const deleteRangeDataAction = createAction('[Range] DeleteRangeData', props<{ range: RangeDto }>());
export const updateScenarioRangePropertiesAction = createAction(
  '[Range] UpsertScenarioRangeProperties',
  (
    dto: ScenarioRangePropertiesDto,
    colIds: DataSourceKey<ScenarioRangePropertiesDto>[],
    isUndoEnabled: boolean,
  ): IUpdateRowsProps<ScenarioRangePropertiesDto> => ({
    rows: [dto],
    shouldResetResults: true,
    colIds,
    isUndoEnabled,
  }),
);

export const autoYAxisShiftSaveAction = createAction(
  '[Range] autoYAxisShiftSaveAction',
  props<{
    insertVerticalShifts: VerticalShiftDto[];
    updateVerticalShifts: VerticalShiftDto[];
  }>(),
);
export const insertVerticalShiftDtosAction = createAction(
  '[Range] insertVerticalShiftDtosAction',
  props<{ verticalShifts: VerticalShiftDto[] }>(),
);
export const updateVerticalShiftDtosAction = createAction(
  '[Range] updateVerticalShiftDtosAction',
  props<{ verticalShifts: VerticalShiftDto[] }>(),
);
export const resetYAxisShiftAction = createAction('[Range] resetYAxisShiftAction');
