import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, Subscription } from 'rxjs';
import { IUnitSystemDto, UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { selectCurrentUnitSystem, selectSortedUnitSystems } from '../../../+store/units/units.selectors';
import { ModalService } from '../modal.service';
import { createUnitSystemAction, updateUnitSystemAction } from '../../../+store/units/units.actions';
import { changeProp, IError, ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';
import { UnitHelpers } from '../../units/unit-helpers';
import { ISelectItem } from '@dunefront/common/common/select.helpers';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { areStringsTheSame } from '@dunefront/common/common/helpers';
import { defaultModalCancelButton, modalButton, ModalButtonConfig } from '../generic-modal/generic-modal.component';
import { Actions, ofType } from '@ngrx/effects';
import { dataFailed, insertRowsSuccess, updateRowSuccess } from '../../../+store/app.actions';
import { unitsErrorDuplicatedName } from '@dunefront/common/modules/units/units.actions';

@Component({
  selector: 'app-units',
  templateUrl: './units-create-edit-modal.component.html',
  styleUrls: ['./units-create-edit-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnitsCreateEditModalComponent implements OnInit, OnDestroy {
  @Input() public mode!: 'new' | 'edit';
  public workingUnitSystem!: WorkingUnitSystem;

  protected subscription = new Subscription();

  public UnitType = UnitSystem;
  private isDispatched = false;
  private predefinedUnitSystemsNames: string[] = [];

  public modalButtonsConfigs: ModalButtonConfig[] = [
    defaultModalCancelButton((): void => this.cancelClicked()),
    modalButton('Save', (): Promise<void> => this.saveClicked(), 'unit-save-button'),
  ];

  constructor(
    protected store: Store,
    protected cdRef: ChangeDetectorRef,
    protected modalService: ModalService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    protected actions$: Actions,
  ) {
    this.mode = config.data.mode;

    this.subscription.add(
      store.select(selectCurrentUnitSystem).subscribe((currentUnitSystem) => {
        this.workingUnitSystem = { ...currentUnitSystem, error: {} as IError<WorkingUnitSystem> };
        cdRef.markForCheck();
      }),
    );

    this.subscription.add(
      store.select(selectSortedUnitSystems).subscribe((availableUnitSystems) => {
        this.predefinedUnitSystemsNames = availableUnitSystems
          .filter((system) => system.commonDbType === 'Predefined')
          .map((system) => system.name);
      }),
    );

    this.subscription.add(
      this.actions$
        .pipe(
          ofType(insertRowsSuccess, updateRowSuccess),
          filter((action) => action.affectedRows.unitSystem != null),
        )
        .subscribe(() => this.ref.close()),
    );

    this.subscription.add(
      this.actions$
        .pipe(
          ofType(dataFailed),
          filter((action) => action.customHandle != null),
        )
        .subscribe((action) => {
          if (action.customHandle === unitsErrorDuplicatedName) {
            this.modalService.showAlert('This name already exists - please use a different one.').then();
          } else {
            this.store.dispatch(dataFailed({ ...action, customHandle: undefined }));
          }
        }),
    );
  }

  private unitSymbolsMap = new Map<UnitSystem, ISelectItem<UnitSystem>[]>();

  public getUnitSymbols(unitType: UnitSystem): ISelectItem<UnitSystem>[] {
    if (!this.unitSymbolsMap.has(unitType)) {
      this.unitSymbolsMap.set(unitType, UnitHelpers.getUnitSymbolSelectItems(unitType));
    }

    return this.unitSymbolsMap.get(unitType) ?? [];
  }

  public onUnitValueChange(args: ObjectChangeProp<WorkingUnitSystem>): void {
    this.workingUnitSystem = changeProp(this.workingUnitSystem, args);
  }

  public ngOnInit(): void {
    if (this.mode === 'new') {
      this.workingUnitSystem.name = '';
    }

    this.cdRef.markForCheck();
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public emitValue(props: ObjectChangeProp<WorkingUnitSystem>): void {
    this.workingUnitSystem = { ...this.workingUnitSystem, name: props.value as string };
    this.validate();
  }

  private validate(): boolean {
    if (this.workingUnitSystem.name === '') {
      this.workingUnitSystem = { ...this.workingUnitSystem, error: { name: 'Name cannot be blank.' } };
      return false;
    }

    if (this.predefinedUnitSystemsNames.findIndex((name) => areStringsTheSame(name, this.workingUnitSystem.name)) !== -1) {
      this.workingUnitSystem = { ...this.workingUnitSystem, error: { name: 'This name already exists - please use a different one.' } };
      return false;
    }

    this.workingUnitSystem = { ...this.workingUnitSystem, error: {} };
    return true;
  }

  public async saveClicked(): Promise<void> {
    if (!this.validate()) {
      return;
    }

    switch (this.mode) {
      case 'new':
        {
          const commonDbType = await this.modalService.getCommonDbType('unit');
          if (!commonDbType) {
            return;
          }
          this.workingUnitSystem.commonDbType = commonDbType;
          this.store.dispatch(createUnitSystemAction({ unitSystem: this.workingUnitSystem }));
        }
        break;
      case 'edit':
        this.store.dispatch(updateUnitSystemAction({ unitSystem: this.workingUnitSystem }));
        break;
      default:
        throw new Error('unknown unit component mode');
    }
  }

  public cancelClicked(): void {
    this.ref.close();
  }

  public getModalTitle(): string {
    return (this.mode === 'new' ? 'Create' : 'Edit') + ' custom unit system';
  }
}

export interface WorkingUnitSystem extends IUnitSystemDto {
  error: IError<WorkingUnitSystem>;
}
