import { Routes } from '@angular/router';
import { ReportComponent } from './report/report.component';
import { ReportingChartComponent } from './chart/reporting-chart.component';
import { ReportingComponent } from './reporting.component';
import { ReportTestComponent } from './report-test/report-test.component';

export const RouteModuleReporting = 'reporting';
export const RouteModuleReportingTab = 'tab';
export const RouteModuleReportingTest = 'test';
export const RouteModuleReportingTabReport = 'report';

export const reportingRoutes: Routes = [
  {
    path: RouteModuleReporting,
    component: ReportingComponent,
    data: {
      breadcrumb: 'Reporting',
    },
    children: [
      {
        path: `${RouteModuleReportingTab}/${RouteModuleReportingTabReport}`,
        component: ReportComponent,
        data: {
          breadcrumb: 'Report',
        },
      },
      {
        path: `${RouteModuleReportingTab}/:id`,
        component: ReportingChartComponent,
        data: {
          breadcrumb: 'Chart',
        },
      },
      {
        path: RouteModuleReportingTest,
        component: ReportTestComponent,
      },
      {
        path: `${RouteModuleReportingTab}`,
        redirectTo: `${RouteModuleReportingTab}/${RouteModuleReportingTabReport}`,
        pathMatch: 'full',
      },
      {
        path: '',
        redirectTo: `${RouteModuleReportingTab}/${RouteModuleReportingTabReport}`,
        pathMatch: 'full',
      },
    ],
  },
];
