<app-ribbon-menu-group groupName="File" (click)="changeHelp('toolbars', 'general-file')">
  <div class="ribbon-menu-button-group">
    <app-button
      [isDeleteResultsEnabled]="false"
      class="big-button"
      text="Save as"
      textIcon="icon-save"
      (buttonClick)="saveFileAs()"
    ></app-button>

    <app-button
      [isDeleteResultsEnabled]="false"
      [isUiLockable]="false"
      [isDisabledOnRequest]="true"
      *ngIf="isFileLocked$ | async; else lockInputsButton"
      (buttonClick)="unlockInputs()"
      class="big-button"
      text="Unlock Inputs"
      [isToggled]="true"
      data-cy="ribbon-unlock-inputs"
      textIcon="icon-documents-file-lock"
    ></app-button>
    <ng-template #lockInputsButton>
      <app-button
        [isDeleteResultsEnabled]="false"
        (buttonClick)="lockInputs()"
        class="big-button"
        text="Lock Inputs"
        data-cy="ribbon-lock-inputs"
        textIcon="icon-documents-file-lock"
      ></app-button>
    </ng-template>
    <app-button
      *hideOnDataPro
      [disabled]="(isDeleteResultEnabled$ | async) === false"
      [isDeleteResultsEnabled]="false"
      class="big-button"
      text="Delete Results"
      textIcon="icon-data-delete"
      [dataCy]="'delete-results-btn'"
      (buttonClick)="deleteAllResultsClick()"
    ></app-button>
    <app-button
      [isDeleteResultsEnabled]="false"
      class="big-button"
      text="Shrink File"
      textIcon="icon-vacuum-db"
      [dataCy]="'shrink-db-file'"
      (buttonClick)="vacuumDbClick()"
    ></app-button>
    <app-button
      class="big-button"
      text="Export Results"
      textIcon="icon-file-sync"
      [dataCy]="'general-menu-export-results-btn'"
      [disabled]="!generalMenuProps.exportEnabled"
      (buttonClick)="onExportResultsClick()"
      [isUiLockable]="false"
    ></app-button>
  </div>
</app-ribbon-menu-group>
<hr />

<app-ribbon-menu-group groupName="Unit System" (click)="changeHelp('toolbars', 'general-unit-system')">
  <app-unit-system [generalMenuProps]="generalMenuProps"></app-unit-system>
</app-ribbon-menu-group>
<ng-container *hideOnDataPro>
  <hr />
  <app-ribbon-menu-group groupName="Scenario" (click)="changeHelp('toolbars', 'general-scenario')">
    <app-scenario></app-scenario>
  </app-ribbon-menu-group>
</ng-container>
