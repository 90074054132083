import { LowerCompletionPipeDto } from './lower-completion-pipe.dto';
import { PipeDto, PipeType } from './pipe.dto';

export interface RunningStringPipeDto extends PipeDto {
  PortDiameter: number;
  PortLength: number;
  SingleJointLength: number;
  ToolJointOuterDiameter: number;
  ToolJointInnerDiameter: number;
  ToolJointLength: number;
  NoOfPorts: number;
  DownFlowNoOfPorts: number;
  DownFlowPortDiameter: number;
  DownFlowPortLength: number;
  DownFlowAnnulusOD: number;
  DownFlowAnnulusID: number;
  DownFlowAnnulusLength: number;
  UpFlowNoOfPorts: number;
  UpFlowPortDiameter: number;
  UpFlowPortLength: number;
  UpFlowAnnulusOD: number;
  UpFlowAnnulusID: number;
  UpFlowAnnulusLength: number;
  ActivationPressure: number;
  IsUpFlowPresent: boolean;
  IsDownFlowPresent: boolean;
  GaugeMeasurementType: GaugeMeasurementType;
  Description: string;
}

export function isRunningStringPipeDto(pipe: RunningStringPipeDto | LowerCompletionPipeDto): pipe is RunningStringPipeDto {
  return Object.keys(RunningStringPipeType)
    .map((key) => +key)
    .includes(pipe.PipeType);
}

export enum GaugeMeasurementType {
  None = -1,
  Internal = 0,
  External = 1,
  Internal_and_External = 2,
}

export enum RunningStringPipeType {
  Workstring = PipeType.Workstring,
  Eccentric_Gauge_Carrier = PipeType.Eccentric_Gauge_Carrier,
  Concentric_Gauge_Carrier = PipeType.Concentric_Gauge_Carrier,
  Service_Tool = PipeType.Service_Tool,
  Washpipe = PipeType.Washpipe,
  Pressure_Attenuator = PipeType.Pressure_Attenuator,
}
