<ng-container *ngIf="chartState$ | async as chartState">
  <app-chart-controller
    [chartDisplayName]="drawableProviderId"
    [drawableProviderId]="drawableProviderId"
    [allowRecording]="true"
    [chartId]="chartId"
    [overlayText]="overlayText"
    [config]="config"
    [chartState]="chartState$ | async"
    [rotateChart]="isRotated"
    [reverseArgument]="isRotated"
  >
  </app-chart-controller>
</ng-container>
