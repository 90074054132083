import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CommonModalComponentBase } from './common-modal.component.base';

@Component({
  selector: 'app-alert-modal-content',
  template: `
    <div class="dialog-header" *ngIf="title">
      <h4>{{ title }}</h4>
    </div>
    <div class="dialog-content">
      <p *ngFor="let message of messages" [innerHTML]="message"></p>
    </div>
    <div class="dialog-footer">
      <button
        data-cy="alert-ok"
        appButton
        buttonType="primary"
        [dialogRef]="ref"
        class="btn btn-primary btn-sm"
        [isUiLockable]="false"
        (buttonClick)="ref.close('Close click')"
        [isDisabledWhenCalculationActive]="isDisabledWhenCalculationActive"
      >
        OK
      </button>
    </div>
  `,
})
export class AlertModalContentComponent extends CommonModalComponentBase {
  public isDisabledWhenCalculationActive: boolean;
  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
  ) {
    super(config.data.title, config.data.message);
    this.isDisabledWhenCalculationActive = config.data.isDisabledWhenCalculationActive ?? true;
  }
}
