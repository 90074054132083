import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as actions from '../../../../+store/common-db/common-db.actions';
import { loadCommonGravels } from '../../../../+store/common-db/common-db.actions';
import { ISelectedRowProps } from '../../../../shared/components/grid/grid.component';
import { selectAvailableCommonGravels } from '../../../../+store/common-db/common-db.selectors';
import { createTableState, ITableRow, ITableState } from '@dunefront/common/common/common-grid.interfaces';
import { ModalService } from '../../../../common-modules/modals/modal.service';
import { getIsDatabaseConnected } from '../../../../+store/backend-connection/backend-connection.selectors';
import { CommonGravelModel } from '../../../../+store/common-db/model/common-gravel.model';
import * as uiActions from '../../../../+store/ui/ui.actions';
import { PageWithGridComponent } from '../../../../shared/components/grid/page-with-grid.component';
import { ScreenService } from '../../../../shared/services';
import { IUpdateTableRowsProps } from '@dunefront/common/common/common-store-crud.interfaces';
import { ICustomKeyboardHandlers } from '../../../../shared/components/grid/grid.interfaces';
import { BehaviorSubject, combineLatest, filter } from 'rxjs';
import { SearchTableStatePipe } from '../../../../shared/components/searchbar/searchTableState.pipe';
import { observableToBehaviorSubject } from '@dunefront/common/common/state.helpers';
import { map } from 'rxjs/operators';
import { Actions, ofType } from '@ngrx/effects';
import { insertRowsSuccess } from '../../../../+store/app.actions';
import { CommonDbCopyDirection } from '@dunefront/common/modules/common.actions';

@Component({
  selector: 'app-database-gravels',
  templateUrl: './database-gravels.component.html',
  styleUrls: ['../../database-fluids-gravels.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatabaseGravelsComponent extends PageWithGridComponent<CommonGravelModel> implements OnInit, OnDestroy {
  private searchTableStatePipe = new SearchTableStatePipe();

  public searchPhrase$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public filteredCommonGravels$: BehaviorSubject<ITableState<CommonGravelModel>> = observableToBehaviorSubject(
    combineLatest([this.searchPhrase$, this.store.select(selectAvailableCommonGravels)]).pipe(
      map(([searchPhrase, availableCommonFluids]) =>
        this.searchTableStatePipe.transform<CommonGravelModel>(availableCommonFluids, searchPhrase),
      ),
    ),
    createTableState([]),
  );

  constructor(
    store: Store,
    cdRef: ChangeDetectorRef,
    modalService: ModalService,
    resizeService: ScreenService,
    private actions$: Actions,
  ) {
    super(store, cdRef, modalService, resizeService);
    store.dispatch(uiActions.setGravelsTabAction({ tab: 'database-gravels' }));
  }

  public getRows(): ITableRow<CommonGravelModel>[] {
    return this.filteredCommonGravels$.value.rows;
  }

  protected updateRow(row: IUpdateTableRowsProps<any>): void {
    throw new Error('Method not implemented.');
  }

  public override ngOnInit(): void {
    super.ngOnInit();

    this.loadGravelsOnPageInit();
    this.reloadGravelsWhenSavedInDb();

    this.onHelpChange('gravels', 'gravel-database');
  }

  private loadGravelsOnPageInit(): void {
    this.subscription.add(
      this.store.select(getIsDatabaseConnected).subscribe((connected) => {
        if (connected) {
          this.store.dispatch(loadCommonGravels());
        }
      }),
    );
  }

  private reloadGravelsWhenSavedInDb(): void {
    this.subscription.add(
      this.actions$
        .pipe(
          ofType(insertRowsSuccess),
          filter((action) => action.commonDbCopyDirection === CommonDbCopyDirection.toCommonDb && action.affectedRows.gravel != null),
        )
        .subscribe(() => this.store.dispatch(loadCommonGravels())),
    );
  }

  public onSelectedRowChanged(event: ISelectedRowProps[]): void {
    // think about moving that to store ...
    if (event.length && event[0] != null && this.getRows().length > 0) {
      this.selectedRowIndex = event[0].rowIndex;
    } else {
      this.selectedRowIndex = undefined;
    }

    this.setSelectedRow();
  }

  public override setSelectedRow(): void {
    if (this.selectedRowIndex !== undefined) {
      this.selectedRow = this.getRows().find((g) => g.rowIndex === this.selectedRowIndex);
    } else {
      this.selectedRow = undefined;
    }
  }

  public onLoadFromDatabase(): void {
    if (this.selectedRow?.rowData.Id == null) {
      return;
    }

    this.store.dispatch(
      actions.copyCommonGravelToProject({
        commonGravelId: this.selectedRow.rowData.Id,
        commonDbType: this.selectedRow.rowData.commonDbType,
        targetScenarioId: this.currentScenarioId,
      }),
    );
  }

  public override async onDelete(): Promise<void> {
    if (this.selectedRow?.rowData.Id == null || this.selectedRow?.rowData.commonDbType === 'Predefined') {
      return;
    }
    const gravelName = this.selectedRow.rowData.Name;
    const confirmResult = await this.modalService.showConfirm(
      `Are you sure you want to delete gravel <br><strong>${gravelName}</strong>?`,
      'Information',
    );

    if (confirmResult) {
      this.store.dispatch(
        actions.deleteCommonGravel({
          commonGravelId: this.selectedRow.rowData.Id,
          commonDbType: this.selectedRow.rowData.commonDbType,
        }),
      );
    }
  }

  public get getCustomKeyboardHandlers(): ICustomKeyboardHandlers {
    return { Delete: () => this.onDelete() };
  }

  public override ngOnDestroy(): void {
    this.filteredCommonGravels$ = null as any;
    this.searchPhrase$ = null as any;

    super.ngOnDestroy();
  }
}
