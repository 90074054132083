import { LoadPumpingActionResponse } from '@dunefront/common/modules/pumping/pumping-module.actions';
import { CrudResponse } from '@dunefront/common/modules/common.actions';
import { createTableRow, createTableState, ITableState } from '@dunefront/common/common/common-grid.interfaces';
import { deleteObjectsFromArray, updateObjectsInArray } from '@dunefront/common/common/state.helpers';
import {
  createEmptyPumpingState,
  pumpingModuleInitialState,
  PumpingModuleState,
} from '@dunefront/common/modules/pumping/pumping-module.state';
import { uniq } from 'lodash';
import { PumpingFactory } from '@dunefront/common/modules/pumping/model/pumping/pumping.factory';
import { WellFluidFactory } from '@dunefront/common/modules/pumping/model/well-fluid/well-fluid.factory';
import { WellFluid } from '@dunefront/common/modules/pumping/model/well-fluid/well-fluid';
import { WellFluidCalculations } from '@dunefront/common/modules/pumping/model/well-fluid/well-fluid.calculations';
import { PumpingScheduleFactory } from '@dunefront/common/modules/pumping/model/pumping-schedule/pumping-schedule.factory';
import { PumpingSchedule } from '@dunefront/common/modules/pumping/model/pumping-schedule/pumping-schedule';
import { PumpedFluidAndGravelFactory } from '@dunefront/common/modules/pumping/model/pumped-fluid-and-gravel/pumped-fluid-and-gravel.factory';
import { ActionResponse } from '@dunefront/common/response-ws.action';

export class PumpingModuleReducerHelper {
  public static pumpingModuleLoaded(
    state: PumpingModuleState,
    response: ActionResponse<LoadPumpingActionResponse>,
    scenarioId: number,
  ): PumpingModuleState {
    if (response.status !== 'ok' || !response.payload) {
      return pumpingModuleInitialState;
    }

    return PumpingFactory.create(response.payload, scenarioId);
  }

  public static onInsertRows(state: PumpingModuleState, response: CrudResponse): PumpingModuleState {
    let newState = this.updatePumpingSuccess(state, response);
    newState = this.insertWellFluidRowSuccess(newState, response);
    newState = this.insertPumpingScheduleRowSuccess(newState, response);
    newState = this.insertEvaluatePumpingScheduleRowSuccess(newState, response);
    newState = this.insertPumpedFluidAndGravelRowsSuccess(newState, response);
    newState = this.deleteRangeSuccess(newState, response);
    return newState;
  }

  public static onUpdateRows(state: PumpingModuleState, response: CrudResponse): PumpingModuleState {
    let newState = this.updatePumpingSuccess(state, response);
    newState = this.updateWellFluidsRowsSuccess(newState, response);
    newState = this.updatePumpingScheduleRowsSuccess(newState, response);
    newState = this.updatePumpedFluidAndGravelRowsSuccess(newState, response);
    return newState;
  }

  public static onDeleteRows(state: PumpingModuleState, response: CrudResponse): PumpingModuleState {
    let newState = this.deleteWellFluidsRowSuccess(state, response);
    newState = this.deletePumpingScheduleRowSuccess(newState, response);
    newState = this.deletePumpedFluidAndGravelRowsSuccess(newState, response);
    newState = this.deleteRangeSuccess(newState, response);
    return newState;
  }

  // region Pumping

  private static updatePumpingSuccess(state: PumpingModuleState, response: CrudResponse): PumpingModuleState {
    if (!response.affectedRows.pumping || !response.affectedRows.pumping.rows.length) {
      return state;
    }
    const pumping = response.affectedRows.pumping.rows[0];
    const newState = this.createPumpingPumpingStateIfNotExist(state, pumping.ScenarioId, pumping.RangeId);

    return PumpingFactory.updatePumpingProps(newState, response.affectedRows.pumping.rows[0]);
  }

  private static createPumpingPumpingStateIfNotExist(state: PumpingModuleState, scenarioId: number, rangeId: number): PumpingModuleState {
    if (state[rangeId] != null) {
      return state;
    }
    return { ...state, [rangeId]: createEmptyPumpingState(scenarioId, rangeId, true) };
  }

  // endregion

  // region WellFluids

  private static insertWellFluidRowSuccess(state: PumpingModuleState, response: CrudResponse): PumpingModuleState {
    if (!response.affectedRows.wellFluid) {
      return state;
    }

    const affectedWellFluid = response.affectedRows.wellFluid;
    const rangeIds = uniq(affectedWellFluid.rows.map((r) => r.RangeId));
    let newState: PumpingModuleState = { ...state };

    rangeIds.forEach((rangeId) => {
      const wellFluids = WellFluidFactory.createRowListFromDtos(affectedWellFluid.rows);
      newState = {
        ...newState,
        [rangeId]: { ...newState[rangeId], wellFluids: createTableState<WellFluid>(wellFluids) },
      };
    });

    return newState;
  }

  private static updateWellFluidsRowsSuccess(state: PumpingModuleState, response: CrudResponse): PumpingModuleState {
    if (!response.affectedRows.wellFluid) {
      return state;
    }

    const affectedWellFluid = response.affectedRows.wellFluid;
    const rangeIds = uniq(affectedWellFluid.rows.map((r) => r.RangeId));
    let newState: PumpingModuleState = { ...state };

    rangeIds.forEach((rangeId) => {
      const rows =
        affectedWellFluid.replace === 'all'
          ? affectedWellFluid.rows.map((row, index) => createTableRow(WellFluidFactory.createWellFluid(row), 'data', index))
          : updateObjectsInArray(
              state[rangeId].wellFluids.rows,
              affectedWellFluid.rows.map((row) => WellFluidFactory.createWellFluid(row)),
            );

      newState = {
        ...newState,
        [rangeId]: {
          ...newState[rangeId],
          wellFluids: { ...newState[rangeId].wellFluids, rows: WellFluidCalculations.UpdateCumulativeVolume(rows) },
        },
      };
    });

    return newState;
  }

  private static deleteWellFluidsRowSuccess(state: PumpingModuleState, response: CrudResponse): PumpingModuleState {
    if (!response.affectedRows.wellFluid) {
      return state;
    }
    const affectedWellFluid = response.affectedRows.wellFluid;

    const rangeIds = uniq(affectedWellFluid.rows.map((r) => r.RangeId));
    let newState: PumpingModuleState = { ...state };

    rangeIds.forEach((rangeId) => {
      let rowsAfterDelete = deleteObjectsFromArray(state[rangeId].wellFluids.rows, affectedWellFluid.deletedIds);

      if (affectedWellFluid.rows.length > 0) {
        rowsAfterDelete = updateObjectsInArray(
          rowsAfterDelete,
          affectedWellFluid.rows.map((row) => WellFluidFactory.createWellFluid(row)),
        );
      }

      const newWellFluids: ITableState<WellFluid> = {
        ...state[rangeId].wellFluids,
        rows: WellFluidCalculations.UpdateCumulativeVolume(rowsAfterDelete),
      };

      newState = { ...newState, [rangeId]: { ...newState[rangeId], wellFluids: newWellFluids } };
    });

    return newState;
  }

  // endregion

  // region PumpingSchedule

  private static insertPumpingScheduleRowSuccess(state: PumpingModuleState, response: CrudResponse): PumpingModuleState {
    if (!response.affectedRows.pumpingSchedule) {
      return state;
    }

    const affectedPumpingSchedule = response.affectedRows.pumpingSchedule;
    const pumpingScheduleRows = PumpingScheduleFactory.createRowListFromDtos(affectedPumpingSchedule.rows, response.scenarioId, true);
    return {
      ...state,
      pumpingSchedule: createTableState<PumpingSchedule>(pumpingScheduleRows),
    };
  }

  private static insertEvaluatePumpingScheduleRowSuccess(state: PumpingModuleState, response: CrudResponse): PumpingModuleState {
    if (!response.affectedRows.evaluationPumpingSchedule) {
      return state;
    }

    const affectedPumpingSchedule = response.affectedRows.evaluationPumpingSchedule;
    const pumpingScheduleRows = PumpingScheduleFactory.createRowListFromDtos(affectedPumpingSchedule.rows, response.scenarioId, false);
    return {
      ...state,
      evaluationSchedule: createTableState<PumpingSchedule>(pumpingScheduleRows),
    };
  }

  private static updatePumpingScheduleRowsSuccess(state: PumpingModuleState, response: CrudResponse): PumpingModuleState {
    if (!response.affectedRows.pumpingSchedule) {
      return state;
    }
    let pumpingSchedule: ITableState<PumpingSchedule> = createTableState([]);
    const scheduleTableRows = state.pumpingSchedule.rows;
    if (response.affectedRows.pumpingSchedule.replace === 'all') {
      pumpingSchedule = createTableState(
        PumpingScheduleFactory.createRowListFromDtos(response.affectedRows.pumpingSchedule.rows, response.scenarioId, true),
      );
    } else {
      const rows = updateObjectsInArray(
        scheduleTableRows,
        response.affectedRows.pumpingSchedule.rows.map((row) => PumpingScheduleFactory.createPumpingSchedule(row)),
      );
      pumpingSchedule = { ...pumpingSchedule, rows };
    }

    return {
      ...state,
      pumpingSchedule,
    };
  }

  private static deletePumpingScheduleRowSuccess(state: PumpingModuleState, response: CrudResponse): PumpingModuleState {
    if (!response.affectedRows.pumpingSchedule) {
      return state;
    }

    const affectedPumpingSchedule = response.affectedRows.pumpingSchedule;
    const rowsAfterDelete = deleteObjectsFromArray(state.pumpingSchedule.rows, affectedPumpingSchedule.deletedIds);

    const newPumpingSchedule: ITableState<PumpingSchedule> = {
      ...state.pumpingSchedule,
      rows: rowsAfterDelete,
    };

    return { ...state, pumpingSchedule: newPumpingSchedule };
  }

  // endregion

  // region PumpedFluidAndGravelDto

  private static insertPumpedFluidAndGravelRowsSuccess(state: PumpingModuleState, response: CrudResponse): PumpingModuleState {
    const affectedRows = response?.affectedRows.pumpedFluidsAndGravel;
    if (!affectedRows || affectedRows.parentId == null) {
      return state;
    }
    const rangeId = affectedRows.parentId;
    const pumpedFluidAndGravelRows = PumpedFluidAndGravelFactory.createRowListFromDtos(response.scenarioId, rangeId, affectedRows.rows);
    return {
      ...state,
      [rangeId]: { ...state[rangeId], pumpedFluidAndGravel: createTableState(pumpedFluidAndGravelRows) },
    };
  }

  private static updatePumpedFluidAndGravelRowsSuccess(state: PumpingModuleState, response: CrudResponse): PumpingModuleState {
    const affectedRows = response?.affectedRows.pumpedFluidsAndGravel;
    if (!affectedRows || !affectedRows.rows.length) {
      return state;
    }
    const rangeId = affectedRows.rows[0].RangeId;
    const rows = updateObjectsInArray(state[rangeId].pumpedFluidAndGravel.rows, affectedRows.rows);

    return {
      ...state,
      [rangeId]: { ...state[rangeId], pumpedFluidAndGravel: createTableState(rows) },
    };
  }

  private static deletePumpedFluidAndGravelRowsSuccess(state: PumpingModuleState, response: CrudResponse): PumpingModuleState {
    const affectedRows = response?.affectedRows.pumpedFluidsAndGravel;
    if (!affectedRows || !affectedRows.deletedIds.length) {
      return state;
    }
    const rangeId = affectedRows.parentId as number;
    const rowsAfterDelete = deleteObjectsFromArray(state[rangeId].pumpedFluidAndGravel.rows, affectedRows.deletedIds);

    return {
      ...state,
      [rangeId]: { ...state[rangeId], pumpedFluidAndGravel: createTableState(rowsAfterDelete) },
    };
  }

  private static deleteRangeSuccess = (state: PumpingModuleState, response: CrudResponse): PumpingModuleState => {
    const affectedRows = response?.affectedRows.range;

    if (!affectedRows || !affectedRows.deletedIds.length) {
      return state;
    }

    const newState = { ...state };

    affectedRows.deletedIds.forEach((rangeID) => {
      delete newState[rangeID as keyof PumpingModuleState];
    });

    return newState;
  };
}
