import { CompletionModuleState } from '@dunefront/common/modules/completion/completion-module.state';
import { ChangeDetectorRef, Component, HostBinding } from '@angular/core';
import { ITableRow } from '@dunefront/common/common/common-grid.interfaces';
import { Store } from '@ngrx/store';
import { ModalService } from '../../../common-modules/modals/modal.service';
import * as completionActions from '../../../+store/completion/completion.actions';
import { PipeDataComponent, PipeDimensionData } from './lower-completion/pipe-data/pipe-data.component';
import { EnumHelpers } from '@dunefront/common/utils/enum.helpers';
import { PipeType } from '@dunefront/common/dto/pipe.dto';
import { PageWithGridComponent } from '../../../shared/components/grid/page-with-grid.component';
import { ScreenService } from '../../../shared/services';
import { ICDType } from '@dunefront/common/dto/lower-completion-pipe.dto';
import { isICDScreenRow } from '@dunefront/common/modules/pipes/lower-completion-pipes/pipes/icd-screen-pipe';
import { Pipe } from '@dunefront/common/modules/pipes/pipe';
import { getValidatedCompletionModuleState } from '../../../+store/completion/validated-completion.selectors';
import { HelpIds } from '../../../+store/help/help.actions';
import { firstValueFrom } from 'rxjs';
import { changeProp, ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';
import { isScreenPipe } from '@dunefront/common/modules/pipes/lower-completion-pipes/pipes/screen-pipe';
import { getLowerCompletion, getRunningString } from '../../../+store/completion/completion.selectors';

@Component({
  template: '',
})
export abstract class BaseCompletionComponent extends PageWithGridComponent<Pipe> {
  public completionState!: CompletionModuleState;

  @HostBinding('class')
  public get pipePropsTypeCssName(): string {
    return this.pipeTypeName.replace(' Properties', '').replace(/ /g, '-').toLowerCase();
  }

  protected constructor(store: Store, cdRef: ChangeDetectorRef, modalService: ModalService, resizeService: ScreenService) {
    super(store, cdRef, modalService, resizeService);

    this.subscription.add(
      store.select(getValidatedCompletionModuleState).subscribe((state) => {
        this.completionState = state;
        cdRef.markForCheck();
      }),
    );

    this.subscription.add(store.select(getLowerCompletion).subscribe((state) => this.updateSelectedRowAndHelp()));
    this.subscription.add(store.select(getRunningString).subscribe((state) => this.updateSelectedRowAndHelp()));
  }

  private updateSelectedRowAndHelp(): void {
    this.setSelectedRow();
    this.onPipeTypeHelpChange();
  }

  public get pipeTypeName(): string {
    if (!this.selectedRow || this.selectedRow.rowData.PipeType < 0) {
      return '';
    }
    return (
      EnumHelpers.enumToText(PipeType[this.selectedRow.rowData.PipeType]) +
      (isICDScreenRow(this.selectedRow) && this.selectedRow.rowData.ICDType !== ICDType.None
        ? ' - ' + EnumHelpers.enumToText(ICDType[this.selectedRow.rowData.ICDType])
        : '')
    );
  }

  public onCompletionPropertyChanged(props: ObjectChangeProp<CompletionModuleState>): void {
    this.store.dispatch(completionActions.changeCompletionProperty(props));
  }

  public async onShowPipeDataClick(): Promise<void> {
    const result = await firstValueFrom<PipeDimensionData | undefined>(this.modalService.open(PipeDataComponent, undefined).onClose);
    this.onPipeDimensionSelected(result);
  }

  protected onPipeDimensionSelected(pipeData: PipeDimensionData | undefined): void {
    if (!pipeData || !this.selectedRow) {
      return;
    }

    let OuterDiameter = this.selectedRow.rowData.OuterDiameter;
    if (
      this.selectedRow.rowData.PipeType !== PipeType.Sump_Packer &&
      this.selectedRow.rowData.PipeType !== PipeType.Isolation_Packer && // Lower Completion
      this.selectedRow.rowData.PipeType !== PipeType.Service_Tool && // Running String
      !isScreenPipe(this.selectedRow.rowData)
    ) {
      OuterDiameter = pipeData.outerDiameter;
    }

    if (isScreenPipe(this.selectedRow.rowData)) {
      this.selectedRow.rowData = changeProp(this.selectedRow.rowData, {
        key: 'BasepipeOuterDiameter' as keyof Pipe,
        shouldResetResults: true,
        value: pipeData.outerDiameter,
      });
    }

    const row: ITableRow<Pipe> = {
      ...this.selectedRow,
      rowData: {
        ...this.selectedRow.rowData,
        InnerDiameter: pipeData.innerDiameter,
        OuterDiameter,
        Weight: pipeData.weight,
      },
    };
    this.updateRow({
      rows: [row],
      colIds: ['InnerDiameter', 'OuterDiameter', 'Weight'],
      shouldResetResults: true,
    });
  }

  public onPipeTypeHelpChange(defaultUri?: HelpIds): void {
    const helpUri = (this.selectedRow != null ? this.getHelpUri(this.selectedRow.rowData) : null) ?? defaultUri;
    if (helpUri != null) {
      this.onHelpChange('completion', helpUri);
    }
  }

  private getHelpUri(selectedRowData: Pipe): HelpIds | null {
    switch (selectedRowData.PipeType) {
      case PipeType.Gravel_Pack_Packer:
        return 'gravel-pack-packer';
      case PipeType.Washpipe:
        return 'washpipe';
      case PipeType.Blank_Pipe:
        return 'blank-pipe';
      case PipeType.Bull_Nose:
        return 'bull-nose';
      case PipeType.Gravel_Pack_Extension:
        return 'gravel-pack-extension';
      case PipeType.ICD_Screen:
        return 'icd-screen';
      case PipeType.Isolation_Packer:
        return 'isolation-packer';
      case PipeType.Isolation_Valve:
        return 'isolation-valve';
      case PipeType.Screen:
        return 'screen';
      case PipeType.Shunted_Screen:
        return 'screen';
      case PipeType.Sump_Packer:
        return 'sump-packer';
      case PipeType.Pressure_Attenuator:
        return 'pressure-attenuator';
      case PipeType.Eccentric_Gauge_Carrier:
        return 'eccentric-gauge-carrier';
      case PipeType.Concentric_Gauge_Carrier:
        return 'concentric-gauge-carrier';
      case PipeType.Service_Tool:
        return 'service-tool';
      case PipeType.Workstring:
        return 'workstring';
      case PipeType.Shunted_Blank_Pipe:
        return 'blank-pipe';
      default:
        return null;
    }
  }
}
