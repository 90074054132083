<app-grid
  *ngIf="height > 0"
  [dataSource]="casingGridData"
  [gridConfig]="gridConfig"
  [columns]="gridConfig.columns"
  (selectedRowsChanged)="selectedRowsChanged.emit($event)"
  [showCrudControls]="false"
  [height]="height"
  [isFirstRowInsertingAllowed]="true"
  [isMergingOfRowsEnabled]="true"
  dataCy="casing-grid"
>
</app-grid>
