import { ChartMdTvdMode } from '../reporting.settings';
import { ChartTimeVolMode } from '../reporting-module.actions';
import { ChartLegendLocation } from './chart-legend';
import { defaultLegendFontColor } from '../../../common/constants';

export interface ChartDto {
  Id: number;
  ScenarioId: number;
  Source: ChartDataSourceType;
  LegendLocation: ChartLegendLocation;
  LegendFontSize: number;
  IsLegendFontBold: boolean;
  IsLegendFontItalic: boolean;
  LegendFontColor: string;
  IsOptimizeActive: boolean;
  IsCompareScenarioActive: boolean;
  MdTvdMode: ChartMdTvdMode;
  TimeVolumeMode: ChartTimeVolMode;

  SortOrder: number;
}

export enum ChartDataSourceType {
  ChartSourceReportingTab = 0,
  ChartSourceGaugeData = 1, // from import data
  ChartSourceResultsTimeBased = 10,
  ChartSourceResultsDepthBased = 11,
  ChartSourceTrendAnalysis = 12, // from import data
  ChartSourceEvaluatePressure = 13,
  ChartSourceEvaluateFriction = 14,
  WellSurvey = 20,
  Caliper = 21,
  FrictionCalculator = 22,
  SettlingCalculator = 23,
  Rheometer = 24, // no annotations - log axis
  InjectionTestCalculator = 25, // hard coded marker, so no additional annotations
  ConsolidatedPsd = 26, // no annotations - log axis
  Psd = 27, // no annotations - log axis
  WellVisualization = 38,
  SimulationVisualization = 39,
  ChartSourceFluidProResultsFluidConcentration = 40,
  ChartSourceFluidProPressureAndECD = 41,
}

export const resultDataSourceTypes = [
  ChartDataSourceType.ChartSourceResultsTimeBased,
  ChartDataSourceType.ChartSourceResultsDepthBased,
  ChartDataSourceType.ChartSourceEvaluatePressure,
  ChartDataSourceType.ChartSourceEvaluateFriction,
  ChartDataSourceType.WellVisualization,
  ChartDataSourceType.SimulationVisualization,
];

export const emptyChartDto: ChartDto = {
  Id: -1,
  SortOrder: -1,
  ScenarioId: -1,
  Source: ChartDataSourceType.ChartSourceReportingTab,
  LegendLocation: ChartLegendLocation.Top,
  LegendFontSize: 0,
  IsLegendFontBold: false,
  IsLegendFontItalic: false,
  LegendFontColor: defaultLegendFontColor,
  IsOptimizeActive: false,
  IsCompareScenarioActive: false,
  MdTvdMode: ChartMdTvdMode.md,
  TimeVolumeMode: ChartTimeVolMode.time,
};
