import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { HelpIds } from '../../../../../+store/help/help.actions';
import { OptionsComponent } from '../../options-component';

@Component({
  selector: 'app-fluid-pro-options',
  templateUrl: './fluid-pro-options.component.html',
  styleUrls: ['./fluid-pro-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FluidProOptionsComponent extends OptionsComponent {
  @Output() public helpChanged = new EventEmitter<HelpIds>();
}
