import { Component, EventEmitter, HostBinding, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { getGravelsSelectData, getGravelsSelectDataWithoutNone } from '../../../+store/gravel/gravel.selectors';
import { DataSource, DataSourceKey, ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';

@Component({
  selector: 'app-gravel-selector',
  templateUrl: './gravel-selector.component.html',
  styleUrls: ['./gravel-selector.component.scss'],
})
export class GravelSelectorComponent<T> implements OnChanges {
  @Input() public source: DataSource<T> | undefined;
  @Input() public key: DataSourceKey<T> | undefined;

  @Input() public label = 'Select Gravel';
  @Input() public width = 280;
  @Input() public dropdownWidth = 300;
  @Input() public labelWidth: number | undefined;
  @Input() public errorMessage: string | undefined = '';
  @Input() public disabled = false;
  @Input() public isNoneAvailable = true;

  @Input() public dataCy = '';

  @HostBinding('class') public classAttribute = 'grid-form-container no-padding';

  @Input() public isUiLockable = true;
  @Output() public valueChanged = new EventEmitter<ObjectChangeProp<T>>();

  public data$ = this.store.select(getGravelsSelectData);

  constructor(private store: Store) {}

  public ngOnChanges(changes: SimpleChanges): void {
    this.data$ = this.isNoneAvailable ? this.store.select(getGravelsSelectData) : this.store.select(getGravelsSelectDataWithoutNone);
  }
}
