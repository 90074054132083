import { IDataRowWithCode, IReportTableColumnConfig } from '../common-report-data-helpers';
import { PerforatedCasingPipe } from '@dunefront/common/modules/pipes/casing-pipes/pipes/perforated-casing-pipe';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { ColumnType } from '../../../../../components/grid/grid.interfaces';

export const perforationProperties: IReportTableColumnConfig<IDataRowWithCode<PerforatedCasingPipe>>[] = [
  { colId: 'Code', headerText: 'Code', unitSystem: UnitSystem.None, type: ColumnType.number },
  {
    colId: 'PipeType',
    headerText: 'Component',
    type: ColumnType.select,
    unitSystem: UnitSystem.None,
  },
  { colId: 'PerforationShotDensity', headerText: 'Shot Density', unitSystem: UnitSystem.Shot_Density, type: ColumnType.number },
  { colId: 'PerforationTunnelLength', headerText: 'Tunnel Length', unitSystem: UnitSystem.Short_Length, type: ColumnType.number },
  { colId: 'PerforationVolume', headerText: 'Volume', unitSystem: UnitSystem.Linear_Volume, type: ColumnType.number },
  { colId: 'PerforationDiameter', headerText: 'Diameter', unitSystem: UnitSystem.Diameter, type: ColumnType.number, decimalPlaces: 3 },
  {
    colId: 'PerforationPhasingType',
    headerText: 'Phasing',
    unitSystem: UnitSystem.Angle,
    type: ColumnType.select,
  },
  { colId: 'OpenPerforationPercentage', headerText: 'Open', unitSystem: UnitSystem.None, type: ColumnType.text, overrideUnitSymbol: '%' },
];
