import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ISelectedFluidData } from '../../../../../+store/fluid/fluid.selectors';
import { getRowsForCalculations, ITableRow } from '@dunefront/common/common/common-grid.interfaces';
import { RheometerTestDataGridComponent } from './rheometer-test-data-grid/rheometer-test-data-grid.component';
import { PageWithGridComponent } from '../../../../../shared/components/grid/page-with-grid.component';
import { updateRheometerReadingRow } from '../../../../../+store/fluid/fluid.actions';
import { Store } from '@ngrx/store';
import { ModalService } from '../../../../../common-modules/modals/modal.service';
import { ScreenService } from '../../../../../shared/services';
import { SpeedType } from '@dunefront/common/modules/fluid/dto/rheometer.dto';
import { cancelChange } from '../../../../../+store/store.helpers';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { RheometerReading } from '@dunefront/common/modules/fluid/model/rheometer-reading/rheometer-reading';
import { Rheology } from '@dunefront/common/modules/fluid/model/rheology/rheology';
import { Rheometer } from '@dunefront/common/modules/fluid/model/rheometer/rheometer';
import { changeProp, ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';
import { IUpdateTableRowsProps, StoreCrudPropsFactory } from '@dunefront/common/common/common-store-crud.interfaces';
import { PanelHelpMode } from '../../../../../shared/components/help-button/help-buton.component';

@Component({
  selector: 'app-rheometer-test-data',
  templateUrl: './rheometer-test-data.component.html',
  styleUrls: ['./rheometer-test-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RheometerTestDataComponent extends PageWithGridComponent<RheometerReading> {
  @ViewChild(RheometerTestDataGridComponent) public grid!: RheometerTestDataGridComponent;
  @Input() public selectedFluidData!: ISelectedFluidData;
  @Input() public rheology!: Rheology | null;
  public PanelHelpMode = PanelHelpMode;
  public UnitType = UnitSystem;
  public SpeedType = SpeedType;

  @Output() public rheometerPropertyChange = new EventEmitter<IUpdateTableRowsProps<Rheometer>>();

  constructor(store: Store, cdRef: ChangeDetectorRef, modalService: ModalService, resizeService: ScreenService) {
    super(store, cdRef, modalService, resizeService);
  }

  public async emitRheometerValue(props: ObjectChangeProp<Rheometer>): Promise<void> {
    const isTempValid = await this.validateTempInput(props);
    if (!isTempValid) {
      return;
    }

    const rheometerRow = this.selectedFluidData.rheometer;
    const row = { ...rheometerRow, rowData: changeProp(rheometerRow.rowData, props) };
    this.rheometerPropertyChange.emit(StoreCrudPropsFactory.updateTableRows([row], props));
  }

  private async validateTempInput(props: ObjectChangeProp<Rheometer>): Promise<boolean> {
    if (props.key !== 'Temperature') {
      return true;
    }

    const allTemps = getRowsForCalculations(this.selectedFluidData.rheologies.rows).map((rh) => rh.Temperature);
    const tempAlreadyExists = allTemps.some((temp) => temp === props.value);
    if (!tempAlreadyExists) {
      return true;
    }

    await this.modalService.showAlert('This temperature already exists - please use a different one.', 'Information');

    const rheometerRow = this.selectedFluidData.rheometer;
    const cancelGenerator = cancelChange(rheometerRow.rowData, props, this.cdRef);
    this.selectedFluidData = {
      ...this.selectedFluidData,
      rheometer: { ...this.selectedFluidData.rheometer, rowData: cancelGenerator.next().value },
    };
    this.selectedFluidData = {
      ...this.selectedFluidData,
      rheometer: { ...this.selectedFluidData.rheometer, rowData: cancelGenerator.next().value },
    };
    this.cdRef.markForCheck();
    return false;
  }

  public getRows(): ITableRow<RheometerReading>[] {
    return this.selectedFluidData.rheometerReading.rows ?? [];
  }

  protected updateRow(props: IUpdateTableRowsProps<RheometerReading>): void {
    this.store.dispatch(updateRheometerReadingRow(props));
  }

  public override async onDelete(): Promise<void> {
    await this.grid.onDelete();
  }
}
