<app-page-panel>
  <div class="grid-form-container cols-1">
    <div class="app-logo">
      <span class="icon-logo icon-white"></span>
      {{ appName }}
    </div>
    <div class="info-panel" [innerHtml]="getHandledBackendConnectionErrorMessage(errorMessage, appName)"></div>
    <div class="button-panel">
      <button
        *ngIf="isEmailVerificationErrorMessage"
        appButton
        [isUiLockable]="false"
        (click)="resendVerificationEmail()"
        type="button"
        class="btn btn-primary btn-sm"
      >
        Resend verification email
      </button>
      <button appButton [isUiLockable]="false" (click)="logoutClick()" type="button" class="btn btn-primary btn-sm">Logout</button>
    </div>
  </div>
</app-page-panel>
