import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { createTableRow, ITableRow, ITableState } from '@dunefront/common/common/common-grid.interfaces';
import { Store } from '@ngrx/store';
import { ModalService } from '../../../../../../common-modules/modals/modal.service';
import { GridConfig } from '../../../../../../shared/components/grid/grid-config';
import { IGridColumnConfig } from '../../../../../../shared/components/grid/grid.interfaces';
import { createEmptyWellFluidDto, FlowPathDescription } from '@dunefront/common/modules/pumping/dto/well-fluid.dto';
import { EnumHelpers } from '@dunefront/common/utils/enum.helpers';
import { getFluidsSelectData } from '../../../../../../+store/fluid/fluid.selectors';
import { getGravelsSelectData } from '../../../../../../+store/gravel/gravel.selectors';
import { GridContainerComponent } from '../../../../../../shared/components/grid/grid-container.component';
import {
  deleteWellFluidsRowsAction,
  insertWellFluidsRowsAction,
  updateWellFluidsRowsAction,
} from '../../../../../../+store/pumping/pumping.actions';
import { groupBy } from 'lodash';
import { ArrayHelpers } from '@dunefront/common/common/array-helpers';
import { getWellFluidsRows } from '../../../../../../+store/pumping/selectors/pumping-validation.selectors';
import { ISelectItem } from '@dunefront/common/common/select.helpers';
import { WellFluid } from '@dunefront/common/modules/pumping/model/well-fluid/well-fluid';
import { WellFluidFactory } from '@dunefront/common/modules/pumping/model/well-fluid/well-fluid.factory';
import { GridResizeService } from '../../../../../../shared/services/grid-resize.service';
import { IDeleteRowsProps, IInsertRowsProps, IUpdateTableRowsProps } from '@dunefront/common/common/common-store-crud.interfaces';
import {
  detailedFluidDefinitionConfig,
  FLUID_DESCRIPTION_LOOKUP_SRC_ID,
  FLUID_TYPES_LOOKUP_SRC_ID,
  GRAVEL_TYPES_LOOKUP_SRC_ID,
} from './detailed-fluid-definition.config';
import { InsertLocation } from '@dunefront/common/modules/common.interfaces';

@Component({
  selector: 'app-detailed-fluid-definition-grid',
  templateUrl: './detailed-fluid-definition-grid.component.html',
  styleUrls: ['./detailed-fluid-definition-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetailedFluidDefinitionGridComponent extends GridContainerComponent<WellFluid> implements OnChanges {
  @Input() public wellFluids: ITableState<WellFluid> = { rows: [], isValid: true };
  @Input() public isWellFluidDepthByVolume = false;
  @Input() public disabled = false;

  constructor(
    store: Store,
    cdRef: ChangeDetectorRef,
    private modalService: ModalService,
    el: ElementRef,
    gridResizeService: GridResizeService,
  ) {
    super(store, cdRef, el, new DetailedFluidDefinitionGridConfig(store, modalService), gridResizeService);
  }

  public override async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.isWellFluidDepthByVolume != null || changes.disabled != null) {
      this.updateColumns(this.getColumns());
    }
  }

  protected getColumns(): IGridColumnConfig<WellFluid>[] {
    return detailedFluidDefinitionConfig(this.disabled, this.isWellFluidDepthByVolume);
  }
}

class DetailedFluidDefinitionGridConfig extends GridConfig<WellFluid> {
  private fluids: ISelectItem<number>[] = [];
  private gravels: ISelectItem<number>[] = [];
  private flowDescriptions: ISelectItem<FlowPathDescription>[] = [];
  private wellFluidsRows: ITableRow<WellFluid>[] = [];

  constructor(
    private store: Store,
    modalService: ModalService,
  ) {
    super(modalService);
    this.flowDescriptions = EnumHelpers.EnumToISelectItemArray(FlowPathDescription);
    this.headerText = 'Detailed Fluid Definition';
    this.subscription.add(
      this.store.select(getFluidsSelectData).subscribe((fluids) => {
        this.fluids = fluids.items;
      }),
    );

    this.subscription.add(
      this.store.select(getGravelsSelectData).subscribe((gravels) => {
        this.gravels = gravels.items;
      }),
    );

    this.subscription.add(this.store.select(getWellFluidsRows).subscribe((wellFluids) => (this.wellFluidsRows = wellFluids)));
  }

  public override isCellDisabled(rows: ITableRow<WellFluid>[], rowIndex: number, cellIndex: number): boolean {
    if (rows.length === 0) {
      return false;
    }

    const row = rows[rowIndex];
    const col = this.columns[cellIndex];
    if (
      col.colId === 'GravelId' &&
      (row.rowData.FlowPathDescription === FlowPathDescription.Upper_Annulus || row.rowData.FlowPathDescription === FlowPathDescription.Washpipe)
    ) {
      return true;
    }
    if (col.colId === 'GravelConcentration') {
      return row.rowData.GravelId === 0 || row.rowData.IsPacked;
    }
    if (col.colId === 'IsPacked') {
      return row.rowData.FlowPathDescription !== FlowPathDescription.Lower_Annulus || row.rowData.GravelId === 0;
    }
    return false;
  }

  public override async canRowsBeDeleted(props: IDeleteRowsProps): Promise<boolean> {
    if (!this.wellFluidsRows.length) {
      return false;
    }

    const wellFluidsGroupedByFlowPathDescription = groupBy(
      this.wellFluidsRows.map((row) => row.rowData),
      (key) => key.FlowPathDescription,
    );
    const rowsToDelete = this.wellFluidsRows.filter((row) => props.rowIds.includes(row.rowData.Id));
    for (const row of rowsToDelete) {
      const groupedList = wellFluidsGroupedByFlowPathDescription[row.rowData.FlowPathDescription];
      const firstInGroup = groupedList.indexOf(row.rowData) === 0;
      if (firstInGroup) {
        await this.modalService.showAlert("The first row in each flow path can't be deleted");
        return false;
      }
    }
    return true;
  }

  public override async deleteRowsAction(props: IDeleteRowsProps): Promise<void> {
    this.store.dispatch(deleteWellFluidsRowsAction(props));
  }

  public override insertRowAction(props: IInsertRowsProps<WellFluid>, refRow: ITableRow<WellFluid>): void {
    const row = props.rows[0];
    row.rowData.BottomMD = refRow.rowData.TopMD;

    if (props.insertLocation === 'add') {
      const flowPathDescription = refRow.rowData.FlowPathDescription;
      //need to find last row from existing FlowPathGroup
      refRow = ArrayHelpers.last(this.wellFluidsRows.filter((rows) => rows.rowData.FlowPathDescription === flowPathDescription)) ?? refRow;
      row.rowData = WellFluidFactory.createEmpty(refRow.rowData);
    }
    row.rowData.BottomMD = refRow.rowData.TopMD;
    props.refId = refRow.rowData.Id;
    this.store.dispatch(insertWellFluidsRowsAction(props));
  }

  public override updateRowsAction(props: IUpdateTableRowsProps<WellFluid>): void {
    this.store.dispatch(updateWellFluidsRowsAction(props));
  }

  // this need to be defined like that because of lexical scope
  public override getLookupDataSource = (type: string): ISelectItem<any>[] => {
    if (type === GRAVEL_TYPES_LOOKUP_SRC_ID) {
      return this.gravels;
    }
    if (type === FLUID_TYPES_LOOKUP_SRC_ID) {
      return this.fluids;
    }
    if (type === FLUID_DESCRIPTION_LOOKUP_SRC_ID) {
      return this.flowDescriptions;
    }
    return [];
  };

  public override getLookupDataPlaceholder(type: string): string {
    if (type === FLUID_TYPES_LOOKUP_SRC_ID) {
      return 'Select Fluid';
    }
    return '';
  }

  public override createEmptyModel(scenarioId: number, rangeId?: number, refRow?: WellFluid): WellFluid | undefined {
    if (refRow) {
      const emptyWellFluidDto = createEmptyWellFluidDto(refRow.ScenarioId, refRow.RangeId, refRow.FlowPathDescription);
      return WellFluidFactory.createWellFluid(emptyWellFluidDto);
    }
    return;
  }

  public override createDefaultTableRows(
    scenarioId: number,
    noOfRows: number,
    rangeId?: number,
    refRow?: ITableRow<WellFluid>,
  ): ITableRow<WellFluid>[] {
    const newTableRows = [];
    for (let index = 0; index < noOfRows; index++) {
      if (refRow) {
        const newEmptyModel = this.createEmptyModel(scenarioId, rangeId, refRow.rowData);
        if (newEmptyModel) {
          newTableRows.push(createTableRow(newEmptyModel, 'data', index, false));
        }
      }
    }
    return newTableRows;
  }

  public override replaceGridAction(rows: ITableRow<WellFluid>[]): void {
    if (rows.length > 0) {
      const props: IInsertRowsProps<any> = {
        rows,
        refId: 0,
        insertLocation: 'replace' as InsertLocation,
        shouldResetResults: true,
      };
      this.store.dispatch(insertWellFluidsRowsAction(props));
    }
  }
}
