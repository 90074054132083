import { ChangeDetectorRef, Component } from '@angular/core';
import { DeveloperSettingsDto, ValidatedDeveloperSettings } from '@dunefront/common/modules/settings/dto/settingsDto';
import { Store } from '@ngrx/store';
import { ModalService } from '../../../../common-modules/modals/modal.service';
import { getValidatedDeveloperSettings } from '../../../../+store/settings/validated-settings.selectors';
import { ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';
import { DbDependentComponent } from '../../../../common-modules/db-connection/db-dependent.component';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'app-developer-settings',
  templateUrl: './developer-settings.component.html',
  styleUrls: ['./developer-settings.component.scss'],
})
export class DeveloperSettingsComponent extends DbDependentComponent {
  public developerSettings!: ValidatedDeveloperSettings;
  public defaultOptions!: DeveloperSettingsDto;

  private developerSettingsState$ = this.store.select(getValidatedDeveloperSettings);
  private defaultOptionsState$ = this.store.select(getValidatedDeveloperSettings);

  constructor(
    store: Store,
    cdRef: ChangeDetectorRef,
    private modalService: ModalService,
  ) {
    super(store, cdRef);
    this.subscription.add(
      combineLatest([this.developerSettingsState$, this.defaultOptionsState$]).subscribe(
        ([developerSettingsState, defaultOptionsState]) => {
          this.developerSettings = developerSettingsState;
          this.defaultOptions = defaultOptionsState;
          this.cdRef.markForCheck();
        },
      ),
    );
    super.onHelpChange('settings', 'developer');
  }

  public submitOption(props: ObjectChangeProp<ValidatedDeveloperSettings>): void {
    // Todo: feature inactive - pseudocode below
    // if (this.developerOptions && this.developerOptions[props.key] !== props.value) {
    //   this.store.dispatch(changeDeveloperOptionsProperty(props as unknown as ObjectChangeProp<DeveloperOptionsDto>));
    // }
  }

  public async resetDefault(): Promise<void> {
    // Todo: feature inactive - pseudocode below
    // const ifResetConfirmed = await this.modalService.showConfirm('Are you sure you want to reset developer options?', 'Warning');
    //
    // if (ifResetConfirmed) {
    //   this.store.dispatch(resetDefaultDeveloperOptions({ scenarioId: this.developerOptions.ScenarioId }));
    // }
  }
}
