import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { createSelector, Store } from '@ngrx/store';
import { ModalService } from '../../../../../common-modules/modals/modal.service';
import { Subscription } from 'rxjs';
import { getAllScenarios } from '../../../../../+store/scenario/scenario.selectors';
import { getRanges } from '../../../../../+store/range/range.selectors';
import { importTemperatureProfileAction } from '../../../../../+store/well/well.actions';
import { ValidatedWellModuleState } from '@dunefront/common/modules/well/well-module.state';
import { TemperatureProfile } from '@dunefront/common/modules/well/model/temperature-profile/temperature-profile';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { changeProp, ErrorHelper, ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { getCurrentFileResultInfos } from '../../../../../+store/calculation-engine/calculation-engine-results.selectors';
import { InputsHelperService } from '../../../../../shared/services/inputs-helper.service';
import { getValidatedWellModuleState } from '../../../../../+store/well/validated-well.selectors';
import { ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import { EnumHelpers } from '@dunefront/common/utils/enum.helpers';

@Component({
  selector: 'app-import-temp-profile',
  templateUrl: './import-temp-profile-modal.component.html',
  styleUrls: ['./import-temp-profile-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImportTempProfileModalComponent implements OnDestroy {
  protected subscription = new Subscription();
  public scenarioRangeWithResults: IScenarioWithRange[] = [];
  public selectedScenarioRangeWithResults?: IScenarioWithRange;
  public UnitType = UnitSystem;
  public well!: ValidatedWellModuleState;
  public EnumHelpers = EnumHelpers;

  constructor(
    protected store: Store,
    protected cdRef: ChangeDetectorRef,
    protected modalService: ModalService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private inputsHelperService: InputsHelperService,
  ) {
    this.subscription.add(
      this.store.select(getValidatedWellModuleState).subscribe((well) => {
        this.well = well;
        this.cdRef.markForCheck();
      }),
    );

    this.scenarioRangeWithResults = config.data?.scenarioRangeWithResults;
  }

  public closeModal(): void {
    this.ref.close();
  }

  public async importClicked(): Promise<void> {
    if (!this.selectedScenarioRangeWithResults) {
      await this.modalService.showAlert('Select one scenario for importing.', 'Information');
      return;
    }
    if (this.well.TemperatureProfile.error.BottomholeCirculating) {
      await this.modalService.showAlert(ErrorHelper.ERROR_CURRENT_SCREENS_MESSAGE_HEADER, 'Information');
      return;
    }

    if (await this.inputsHelperService.checkResultsAndDeleteIfNeeded(true, true)) {
      this.store.dispatch(
        importTemperatureProfileAction({
          sourceScenarioId: this.selectedScenarioRangeWithResults.scenarioId,
          sourceRangeId: this.selectedScenarioRangeWithResults.rangeId,
          bottomholeCirculating: this.well.TemperatureProfile.BottomholeCirculating,
          sourceModuleType: this.selectedScenarioRangeWithResults.moduleType,
        }),
      );

      this.closeModal();
    }
  }

  public selectValue(scenarioRangeWithResults: IScenarioWithRange, value: boolean): void {
    this.selectedScenarioRangeWithResults = value ? scenarioRangeWithResults : undefined;
    this.cdRef.markForCheck();
  }

  public clearSelection(): void {
    this.selectedScenarioRangeWithResults = undefined;
    this.cdRef.markForCheck();
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public onValueChanged(props: ObjectChangeProp<TemperatureProfile>): void {
    if (props.key === 'BottomholeCirculating') {
      this.well = { ...this.well, TemperatureProfile: changeProp(this.well.TemperatureProfile, props) };
    }
  }

  protected readonly ModuleType = ModuleType;
}

export const getScenarioRangeStateWithResultsInfo = createSelector(
  getAllScenarios,
  getRanges,
  getCurrentFileResultInfos,
  (...[scenarios, ranges, resultsInfos]) => ({ scenarios, ranges, resultsInfos }),
);

export interface IScenarioWithRange {
  rangeId: number;
  rangeName: string;
  scenarioId: number;
  scenarioName: string;
  moduleType: ModuleType;
}
