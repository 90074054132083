import { evaluate } from 'mathjs';
import { IEquationVariable } from './equation-variable.dto';
import { DictionaryWithArray, IDictionaryWithArray, noErrors } from '../../common/state.helpers';
import { createTableRow, createTableState, ITableRow, ITableState } from '../../common/common-grid.interfaces';
import { DataFileType } from '../../dto/data-storage';
import { IValidatedStorageFileWithColumns } from '../data-storage/data-storage.validation';
import { EquationColumn, EquationColumnFactory, IEquation } from './equation-column';
import { EquationValidityState } from './equation-module.state';
import { IError, IValidatedModuleState } from '../../common/common-state.interfaces';
import { ArgumentType } from '../data-storage/dto/import-column.dto';
import { UnitConverterHelper } from '../../unit-converters/unit.converter.helper';
import { EquationRecalculationHelper } from './equation-recalculation.helper';
import { UnitSystem } from '../../dto/unit-system.dto';

export class EquationStateValidation {
  public static createValidatedDataSourceVariables(
    variables: IEquationVariable[],
    storageFiles: IDictionaryWithArray<IValidatedStorageFileWithColumns>,
    fileId: number,
  ): ITableState<IEquationVariable> {
    return EquationStateValidation.validateEquationVariables(variables, storageFiles, fileId);
  }

  public static validateEquationFiles(
    storageFiles: IDictionaryWithArray<IValidatedStorageFileWithColumns>,
    fileIdsToValidate?: number[],
  ): string[] {
    const errorMessages: string[] = [];
    const fileKeysToValidate = fileIdsToValidate ? fileIdsToValidate.map((id) => id.toString()) : storageFiles.ids;
    for (const fileId of fileKeysToValidate) {
      const file = DictionaryWithArray.get(storageFiles, fileId);
      if (file && file.file?.FileType === DataFileType.EquationResult) {
        for (const column of file.columns.filter((column) => column.IsXAxis === ArgumentType.Value)) {
          const equationColumn = EquationColumnFactory.fromDto(column);
          const variables = this.createValidatedDataSourceVariables(equationColumn.Equation.variables, storageFiles, equationColumn.FileId);
          const equationFileValidation = this.validateEquationState(equationColumn, variables);
          if (!equationFileValidation.isValid) {
            errorMessages.push(` - File: ${file.file.FileName}, Column: ${column.ColumnName}<br>`);
          }
        }
      }
    }
    return errorMessages;
  }

  public static validateEquationState(column: EquationColumn, variables: ITableState<IEquationVariable>): EquationValidityState {
    const equationColumn = this.validateEquationColumn(column);
    const equation = this.validateEquation(column, variables);

    const isValid = equation.isValid && equationColumn.isValid && variables.isValid;
    return {
      equationColumn,
      equation,
      isVariablesValid: variables.isValid,
      isValid,
    };
  }

  private static validateEquationColumn(column: EquationColumn): IValidatedModuleState<EquationColumn> {
    const equationColumn: IValidatedModuleState<EquationColumn> = {
      error: {},
      isValid: true,
    };

    if (!column.ColumnName.length) {
      equationColumn.error.ColumnName = 'Column name cannot be empty';
    }
    if (column.Unit <= 0) {
      equationColumn.error.Unit = 'Unit is required';
    }
    if (column.UnitSystem === UnitSystem.NotSelected) {
      equationColumn.error.UnitSystem = 'Unit system is required';
    }
    if (column.DataType === -1) {
      equationColumn.error.DataType = 'Data type is required';
    }
    equationColumn.isValid = !Object.keys(equationColumn.error).length;

    return equationColumn;
  }

  private static validateEquation(column: EquationColumn, variables: ITableState<IEquationVariable>): IValidatedModuleState<IEquation> {
    const equation: IValidatedModuleState<IEquation> = {
      error: {},
      isValid: true,
    };

    const capLettersRegex = /[A-Z]/g;

    const uniqueVariables = column.Equation.variables.map((variable) => variable.Name);
    const capLetters = column.Equation.equationFormula.match(capLettersRegex) || [];
    const undefinedVars = capLetters.filter((letter) => !uniqueVariables.includes(letter));

    if (undefinedVars.length) {
      equation.error.equationFormula = 'Equation contains undefined variable(s): ' + undefinedVars.join(', ');
    }

    try {
      evaluate(column.Equation.equationFormula.replace(capLettersRegex, ' 1 '));
    } catch (e) {
      equation.error.equationFormula = 'Equation contains invalid characters';
    }

    if (!column.Equation.equationFormula.length) {
      equation.error.equationFormula = 'Equation cannot be empty';
    }

    if (!variables.isValid) {
      equation.error.variables = 'All variables needs to be filled and correct';
    }

    equation.isValid = !Object.keys(equation.error).length;

    return equation;
  }

  public static validateEquationVariables(
    variables: IEquationVariable[],
    storageFiles: IDictionaryWithArray<IValidatedStorageFileWithColumns>,
    fileId: number,
  ): ITableState<IEquationVariable> {
    const rows = variables.map((variable, idx) => this.validateVariable(variable, idx, storageFiles, fileId));

    const isValid = rows.every((row) => row.isValid);
    return createTableState(rows, undefined, isValid);
  }

  private static validateVariable(
    variable: IEquationVariable,
    idx: number,
    storageFiles: IDictionaryWithArray<IValidatedStorageFileWithColumns>,
    fileId: number,
  ): ITableRow<IEquationVariable> {
    const error: IError<IEquationVariable> = {};
    if (variable.FileId === -1) {
      error.FileId = 'This field is required';
    }
    if (variable.ColId == -1) {
      error.ColId = 'This field is required';
    }
    if (variable.Unit === -1) {
      error.Unit = 'This field is required';
    }
    let isValid = noErrors(error);
    if (isValid) {
      // if variable input fields are correct validate is source column exists
      const sourceFile = DictionaryWithArray.get(storageFiles, variable.FileId);
      const sourceColumns = sourceFile?.columns;
      if (sourceFile == null || sourceColumns == null) {
        error.FileId = 'Source file not found';
      } else {
        // source import data file must be valid
        if (sourceFile.file?.FileType === DataFileType.ImportedData && !sourceFile.isValid) {
          error.FileId = 'Source file is not valid';
        }

        const sourceColumn = sourceColumns.find((col) => col.Id === variable.ColId);
        if (sourceColumn == null) {
          error.ColId = 'Source column not found';
        } else if (!UnitConverterHelper.isUnitCorrect(sourceColumn.UnitSystem, variable.Unit)) {
          error.Unit = 'Incorrect source column unit';
        }

        try {
          EquationRecalculationHelper.findDependencyOrder(storageFiles, {
            vertex: fileId.toString(),
            dependency: variable.FileId.toString(),
          });
        } catch {
          error.FileId = 'Circular dependencies found';
        }
      }

      isValid = noErrors(error);
    }

    return { ...createTableRow(variable, 'data', idx, false, isValid), error };
  }
}
