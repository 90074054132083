import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { filterNil } from '@dunefront/common/common/state.helpers';
import { deleteChartAnnotation, insertChartAnnotation, updateChartAnnotation } from '../../../+store/reporting/reporting.actions';
import { ModalService } from '../../modals/modal.service';
import { selectUserGlobalOptions } from '../../../+store/common-db/common-db.selectors';
import { ChartAnnotationComponent, ChartAnnotationDialogResult } from '../chart-annotation/chart-annotation.component';
import { IAnnotation } from '../chart-component-helpers/chart-types';
import { IAnnotationStyle } from '@dunefront/common/modules/reporting/dto/chart-annotation.dto';
import { StoreCrudPropsFactory } from '@dunefront/common/common/common-store-crud.interfaces';
import { ArgConverter, ChartAddonsWithConverter } from '../chart-component-helpers/chart-addons-helpers';
import { CreateAnnotationPayload, migrateAnnotations } from '../chart-component-helpers/chart-annotation-helpers';

export const ChartAnnotationComponentClass = 'chart-annotation-component';

@Injectable()
export class ChartAnnotationsService {
  private subscription = new Subscription();
  private defaultAnnotationStyle!: IAnnotationStyle;
  private chartId?: number;
  private _annotations$ = new BehaviorSubject<IAnnotation[] | undefined>(undefined);
  private toPrimaryArgConverter: ArgConverter;

  constructor(
    private store: Store,
    private modalService: ModalService,
    addonsWithConverter$: Observable<ChartAddonsWithConverter | undefined>,
  ) {
    this.subscription.add(
      this.store.select(selectUserGlobalOptions).subscribe((globalOptions) => (this.defaultAnnotationStyle = globalOptions)),
    );

    this.subscription.add(
      addonsWithConverter$.subscribe((addonsWithConverter) => {
        let newAnnotations = addonsWithConverter?.chartUserAddons?.chartAnnotations;
        const converter = addonsWithConverter?.toSecondaryArgConverter;

        // convert to secondary argument if possible and needed
        if (newAnnotations != null && converter != null) {
          const result: IAnnotation[] = [];

          for (const annotation of newAnnotations) {
            result.push({
              ...annotation,
              argument: converter(annotation.argument),
              boxArgument: converter(annotation.boxArgument),
            });
          }

          newAnnotations = result;
        }

        this.chartId = addonsWithConverter?.chartId;
        this._annotations$.next(newAnnotations);
        this.toPrimaryArgConverter = addonsWithConverter?.toPrimaryArgConverter;
      }),
    );
  }

  public get annotations$(): Observable<IAnnotation[]> {
    return this._annotations$.pipe(filterNil());
  }

  public onCreateAnnotation(payload: CreateAnnotationPayload, scenarioId: number): void {
    const { argument, value, context, chart } = payload;

    const chartId = this.chartId;
    if (chartId == null) {
      throw new Error("ChartId can't be undefined!");
    }

    const oldTypeAnnotation: IAnnotation = {
      Id: -1,
      text: '',
      argument,
      value,
      boxArgument: 0,
      boxValue: 0,
      boxWidth: 120,
      boxHeight: 60,
      boxAngle: 45,
      boxDistance: 100,
      chartId: chartId,
      ScenarioId: scenarioId,
      sortOrder: 0,
    };

    const migrated = migrateAnnotations([oldTypeAnnotation], context, chart);
    if (migrated != null && migrated.length === 1) {
      this.showAnnotationDialog(migrated[0], false);
    }
  }

  public onEditAnnotation(secondaryArgAnnotation: IAnnotation): void {
    const chartId = this.chartId;
    if (chartId == null) {
      throw new Error("ChartId can't be undefined!");
    }

    this.showAnnotationDialog(secondaryArgAnnotation, true);
  }

  public onAnnotationsUpdated(secondaryArgAnnotations: IAnnotation[]): void {
    const chartId = this.chartId;
    if (chartId == null) {
      throw new Error("ChartId can't be undefined!");
    }

    const annotations = secondaryArgAnnotations.map((a) => this.toPrimaryArgAnnotation(a));

    this.store.dispatch(updateChartAnnotation({ annotations }));
  }

  public dispose(): void {
    this.subscription.unsubscribe();
  }

  private showAnnotationDialog(secondaryArgAnnotation: IAnnotation, allowDelete: boolean): void {
    const dialog = this.modalService.open(
      ChartAnnotationComponent,
      {
        annotation: secondaryArgAnnotation,
        allowDelete,
        defaultAnnotationStyle: this.defaultAnnotationStyle,
      },
      ChartAnnotationComponentClass,
    );

    dialog.onClose.pipe(take(1)).subscribe((result: ChartAnnotationDialogResult | undefined) => {
      if (!result) {
        return;
      }

      const resultSecondaryArgAnnotation = result.value;
      const primaryArgAnnotation = this.toPrimaryArgAnnotation(resultSecondaryArgAnnotation);

      switch (result.action) {
        case 'ok':
          this.store.dispatch(
            primaryArgAnnotation.Id === -1
              ? insertChartAnnotation(primaryArgAnnotation)
              : updateChartAnnotation({ annotations: [primaryArgAnnotation] }),
          );
          break;
        case 'delete':
          this.onDeleteAnnotation(primaryArgAnnotation).then();
          break;
      }
    });
  }

  private toPrimaryArgAnnotation(annotation: IAnnotation): IAnnotation {
    return {
      ...annotation,
      argument: this.toPrimaryArgConverter?.(annotation.argument) ?? annotation.argument,
      boxArgument: this.toPrimaryArgConverter?.(annotation.boxArgument) ?? annotation.boxArgument,
    };
  }

  public onKeyboardDeleteAnnotation(annotation: IAnnotation): void {
    const primaryArgAnnotation = this.toPrimaryArgAnnotation(annotation);
    this.onDeleteAnnotation(primaryArgAnnotation).then();
  }

  private async onDeleteAnnotation(primaryArgAnnotation: IAnnotation): Promise<void> {
    const confirm = await this.modalService.showConfirm('Confirm delete annotation?', 'Information');
    if (confirm) {
      this.store.dispatch(deleteChartAnnotation(StoreCrudPropsFactory.deleteRows(primaryArgAnnotation, false)));
    }
  }
}
