import { createAction, props } from '@ngrx/store';
import { InjectionTestCalculatorDto } from '@dunefront/common/modules/calculators/injection-test-calculator/injection-test-calculator.dto';
import {
  IDeleteRowsProps,
  IInsertRowsProps,
  IUpdateRowsProps,
  IUpdateTableRowsProps,
} from '@dunefront/common/common/common-store-crud.interfaces';
import { InjectionTestReading } from '@dunefront/common/modules/calculators/injection-test-calculator/model/injection-test-reading';

export const updateInjectionTestReadingsRowAction = createAction(
  '[InjectionTestCalculatorModule] UpdateRow',
  props<IUpdateTableRowsProps<InjectionTestReading>>(),
);
export const insertInjectionTestReadingsRowsAction = createAction(
  '[InjectionTestCalculatorModule] InsertRows',
  props<IInsertRowsProps<InjectionTestReading>>(),
);
export const deleteInjectionTestReadingsRowsAction = createAction('[InjectionTestCalculatorModule] DeleteRows', props<IDeleteRowsProps>());

export const calculateInjectionTest = createAction('[InjectionTestCalculatorModule] Calculate', props<{ ScenarioId: number }>());
export const updateInjectionTestCalculatorRow = createAction(
  '[InjectionTestCalculatorModule] Update',
  props<IUpdateRowsProps<InjectionTestCalculatorDto>>(),
);
