import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { DbDependentComponent } from '../../../db-connection/db-dependent.component';
import { Store } from '@ngrx/store';
import { getAllScenarios } from '../../../../+store/scenario/scenario.selectors';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Scenario } from '@dunefront/common/modules/scenario/scenario';

@Component({
  selector: 'app-scenario-manager-checkboxes',
  templateUrl: './scenario-manager-checkboxes.component.html',
  styleUrls: ['./scenario-manager-checkboxes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScenarioManagerCheckboxesComponent extends DbDependentComponent {
  @Output() public valueChanged = new EventEmitter<{ id: number; value: boolean }>();
  @Input() public selectedIds: number[] = [];
  @Input() public errorMessages: Map<number, string> = new Map<number, string>();
  @Input() public labelWidth!: number;
  @Input() public isReorderOn = false;
  @Input() public excludedValues: number[] = [];
  @Input() public scenarioIdsWithResults: number[] = [];
  @Input() public disableDefaultValue = false;
  @Input() public showResults = false;
  @Input() public dataCy = '';

  @Output() public scenarioDropped = new EventEmitter<CdkDragDrop<Scenario>>();

  public scenarios$ = this.store.select(getAllScenarios);

  constructor(store: Store, cdRef: ChangeDetectorRef) {
    super(store, cdRef);
  }

  public onDrop(e: CdkDragDrop<Scenario>): void {
    this.scenarioDropped.emit(e);
  }

  public isVisible(scenario: Scenario): boolean {
    return !this.excludedValues.includes(scenario.Id);
  }

  public isDisabled(scenario: Scenario): boolean {
    return this.disableDefaultValue && scenario.Id === this.currentScenarioId;
  }

  public hasResults(scenario: Scenario): boolean {
    return this.scenarioIdsWithResults.includes(scenario.Id);
  }
}
