import { DeleteRowsWsAction, InsertRowsWsAction, UpdateRowsWsAction, WsAction } from '../../ws.action';
import { SurveyDto } from './dto/survey/survey.dto';
import { WellDto } from './dto/well.dto';
import { CaliperDto } from './dto/caliper/caliper.dto';
import { CasingPipeDto } from './dto/casing/casing-pipe.dto';
import { InsertLocation } from '../common.interfaces';
import { ZoneDto } from './dto/zone/zone.dto';
import { DeveloperSettingsDto } from '../settings/dto/settingsDto';
import { ModuleType } from '../scenario/scenario.dto';

export const WellModuleName = 'WellModule';

export enum WellModuleActionTypes {
  UpdateWell = '[WellModule] UpdateWell',

  InsertSurveyRow = '[WellModule] [Survey] InsertRows',
  DeleteSurveyRow = '[WellModule] [Survey] DeleteRows',
  UpdateSurveyRow = '[WellModule] [Survey] UpdateRow',
  UpdateImportTempProfile = '[WellModule] [Survey] ImportTempProfile Update',

  InsertCasingRow = '[WellModule] [Casing] InsertRows',
  DeleteCasingRow = '[WellModule] [Casing] DeleteRows',
  UpdateCasingRow = '[WellModule] [Casing] UpdateRow',

  InsertCaliperRow = '[WellModule] [Caliper] InsertRows',
  DeleteCaliperRow = '[WellModule] [Caliper] DeleteRows',
  UpdateCaliperRow = '[WellModule] [Caliper] UpdateRow',

  InsertZoneRows = '[WellModule] [Zone] InsertRows',
  DeleteZoneRows = '[WellModule] [Zone] DeleteRows',
  UpdateZoneRow = '[WellModule] [Zone] UpdateRow',
}

export interface LoadWellActionResponse {
  wellDto: WellDto;
  surveyDtos: SurveyDto[];
  caliperDtos: CaliperDto[];
  casingDtos: CasingPipeDto[];
  zoneDtos: ZoneDto[];
}

export class UpdateWellAction extends UpdateRowsWsAction<WellDto> {
  public override readonly type = WellModuleActionTypes.UpdateWell;
}

// region Survey

export class InsertSurveyRowAction extends InsertRowsWsAction<SurveyDto> {
  public override readonly type = WellModuleActionTypes.InsertSurveyRow;
}

export class UpdateSurveyRowAction extends UpdateRowsWsAction<SurveyDto> {
  public override readonly type = WellModuleActionTypes.UpdateSurveyRow;
}

export class DeleteSurveyRowAction extends DeleteRowsWsAction {
  public override readonly type = WellModuleActionTypes.DeleteSurveyRow;
}

export class ImportTempProfileAction extends UpdateRowsWsAction<SurveyDto> {
  public override readonly type = WellModuleActionTypes.UpdateImportTempProfile;

  constructor(
    public targetScenarioId: number,
    public sourceScenarioId: number,
    public sourceRangeId: number,
    public sourceModuleType: ModuleType,
    public bottomholeCirculating: number,
    public override taskId: string,
    public developerSettings: DeveloperSettingsDto,
    public moduleType: ModuleType,
  ) {
    super({ rows: [], colIds: [], shouldResetResults: true, scenarioId: targetScenarioId });
  }
}

// endregion

// region Casing

export class InsertCasingRowAction extends InsertRowsWsAction<CasingPipeDto> {
  public override readonly type = WellModuleActionTypes.InsertCasingRow;
}

export class UpdateCasingRowAction extends UpdateRowsWsAction<CasingPipeDto> {
  public override readonly type = WellModuleActionTypes.UpdateCasingRow;
}

export class DeleteCasingRowAction extends DeleteRowsWsAction {
  public override readonly type = WellModuleActionTypes.DeleteCasingRow;
}

// endregion

// region Caliper

export class InsertCaliperRowAction extends InsertRowsWsAction<CaliperDto> {
  public override readonly type = WellModuleActionTypes.InsertCaliperRow;
}

export class UpdateCaliperRowAction extends UpdateRowsWsAction<CaliperDto> {
  public override readonly type = WellModuleActionTypes.UpdateCaliperRow;
}

export class DeleteCaliperRowAction extends DeleteRowsWsAction {
  public override readonly type = WellModuleActionTypes.DeleteCaliperRow;
}

// endregion

// region Zone

export class InsertZoneRowsAction extends InsertRowsWsAction<ZoneDto> {
  public override readonly type = WellModuleActionTypes.InsertZoneRows;
}

export class UpdateZoneRowAction extends UpdateRowsWsAction<ZoneDto> {
  public override readonly type = WellModuleActionTypes.UpdateZoneRow;
}

export class DeleteZoneRowsAction extends DeleteRowsWsAction {
  public override readonly type = WellModuleActionTypes.DeleteZoneRows;
}

// endregion

/// experimental ....

export class GenericCrudAction<Type extends string> extends WsAction {
  constructor(public override readonly type: Type) {
    super();
  }
}

export type CrudActionType = 'insert' | 'update' | 'delete';

export const getActionType = <Type extends string>(moduleName: string, type: CrudActionType): Type => (moduleName + type) as Type;

export class InsertRowAction<DTO, Type extends string> extends GenericCrudAction<Type> {
  constructor(
    public readonly moduleName: string,
    public override payload: { rows: DTO[]; location: InsertLocation; refId: number },
  ) {
    super(getActionType(moduleName, 'insert'));
  }
}

export class UpdateRowAction<DTO, Type extends string> extends GenericCrudAction<Type> {
  constructor(
    public readonly moduleName: string,
    public override payload: DTO,
  ) {
    super(getActionType(moduleName, 'update'));
  }
}

export class DeleteRowsAction<Type extends string> extends GenericCrudAction<Type> {
  constructor(
    public readonly moduleName: string,
    public scenarioId: number,
    public rowIds: number[],
  ) {
    super(getActionType(moduleName, 'delete'));
  }
}

///

export type WellModuleActions =
  | InsertSurveyRowAction
  | UpdateSurveyRowAction
  | DeleteSurveyRowAction
  | ImportTempProfileAction
  | InsertCasingRowAction
  | UpdateCasingRowAction
  | DeleteCasingRowAction
  | InsertCaliperRowAction
  | UpdateCaliperRowAction
  | DeleteCaliperRowAction
  | InsertZoneRowsAction
  | UpdateZoneRowAction
  | DeleteZoneRowsAction
  | UpdateWellAction;
