import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { MenuItem } from 'primeng/api';
import { IFile } from '@dunefront/common/dto/file.dto';
import { showFileOpenDialog, showFileSaveDialog } from '../../../../+store/electron-main/electron-main.actions';
import { AppTargetConfig } from '../../../../shared/services/app-target-config';

@Component({
  selector: 'app-electron-home-header',
  templateUrl: './electron-home-header.component.html',
  styleUrls: ['../home-header.component.scss', './electron-home-header.component.scss'],
})
export class ElectronHomeHeaderComponent {
  public plusContextMenu: MenuItem[] = [
    { label: 'New Project', icon: 'icon-file', command: (): void => this.newProject() },
    { label: 'Open Project', icon: 'icon-folder-open', command: (): void => this.openProject() },
  ];

  constructor(
    private store: Store,
    private appConfig: AppTargetConfig,
  ) {}

  public newProject(): void {
    const source: IFile = { Repository: 'template', FileType: 'ppf-file', Name: 'default', Folder: [] };
    this.store.dispatch(showFileSaveDialog({ source }));
  }

  private openProject(): void {
    this.store.dispatch(showFileOpenDialog());
  }
}
