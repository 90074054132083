<ng-container *ngIf="showChart; else simulationInProgress">
  <ng-container *ngIf="currentReportingTab$ | async as reportingTab">
    <app-page-panel
      [allowFullScreen]="false"
      [panelHelpMode]="PanelHelpMode.CHART"
      [class.with-slider]="isDepthBased(reportingTab)"
    >

      <div
        class="reporting-chart"
        (click)="onHelpChange('results', 'chart')"
      >
        <app-chart-controller
          *ngIf="chartId"
          [allowRecording]="isDepthBased(reportingTab)"
          [chartId]="chartId"
          [config]="config"
          [overlayText]="overlayText"
          [chartState]="chartState"
          [rotateChart]="isRotatedChart(reportingTab)"
          [reverseArgument]="isRotatedChart(reportingTab)"
          [chartDisplayName]="chartName"
          [drawableProviderId]="drawableProviderId"
          [allowCompareScenarios]="isCompareScenariosEnabled"

          dataCy="reporting-chart"
        >
        </app-chart-controller>
      </div>
    </app-page-panel>

    <div class="reporting-chart-slider" *ngIf="isDepthBased(reportingTab)">
      <app-chart-slider></app-chart-slider>
    </div>
  </ng-container>
</ng-container>

<ng-template #simulationInProgress>
  <div class="centered-message">Simulation in progress.</div>
</ng-template>
