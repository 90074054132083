import { ModuleType } from '../../scenario/scenario.dto';
import { SurveyDto } from '../../well/dto/survey/survey.dto';
import { CompletionSectionVolDto } from './completion.dto';
import { Volumes } from '../model/volumes/volumes';
import { WellModuleState } from '../../well/well-module.state';
import { getRowsForCalculations } from '../../../common/common-grid.interfaces';
import { SurveyFactory } from '../../well/model/survey/survey.factory';
import { ImportFileForAdvancedValidation } from '../../data-storage/dto/import-file-with-columns';
import { ReferenceVariableCalculatorInputs } from '../../reference-variables/dto/reference-variable-calculator-inputs.dto';

export interface SectionInputs extends CompletionSectionVolDto {
  ModuleType: ModuleType;
  SurveyData: SurveyDto[];
  ImportFiles: ImportFileForAdvancedValidation[];
}

export interface SectionCalculatorJobInputData {
  SectionCalculatorInputs: SectionInputs;
  ReferenceVariableCalculatorJobInputData: ReferenceVariableCalculatorInputs;
}

export class SectionInputsFactory {
  public static toDtoSingleSection(volumes: Volumes, moduleType: ModuleType, well: WellModuleState): SectionInputs {
    const SurveyData = getRowsForCalculations(well.SurveyData.rows).map((survey) => SurveyFactory.toDto(survey));
    const inputs: SectionInputs = {
      SectionVolCalcStartMD: volumes.SectionVolCalcStartMD,
      SectionVolCalcEndMD: volumes.SectionVolCalcEndMD,
      SectionVolCalcGravelId: volumes.SectionVolCalcGravelId,
      SectionVolCalcStartMeasurementType: volumes.SectionVolCalcStartMeasurementType,
      SectionVolCalcEndMeasurementType: volumes.SectionVolCalcEndMeasurementType,
      SectionVolCalcGravelConc: volumes.SectionVolCalcGravelConc,
      SurveyData,
      ModuleType: moduleType,
      ImportFiles: [],
    };
    return inputs;
  }

  public static toDtoMultipleSections(
    volumes: Volumes,
    moduleType: ModuleType,
    well: WellModuleState,
    importFiles: ImportFileForAdvancedValidation[] | null,
  ): SectionInputs {
    return { ...this.toDtoSingleSection(volumes, moduleType, well), ImportFiles: importFiles ?? [] };
  }
}
