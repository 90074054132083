<app-grid
  #grid
  [isUiLockable]="false"
  [dataSource]="chartSeries"
  [gridConfig]="gridConfig"
  [columns]="gridConfig.columns"
  (selectedRowsChanged)="selectedRowsChanged.emit($event)"
  [showCrudControls]="false"
  [isFirstRowInsertingAllowed]="false"
  [isFirstRowDeleteAllowed]="false"
  [height]="height"
  [groupByColumns]="['GroupName']"
  [showHeaderUnits]="false"
  [isContextMenuDisabled]="true"
  [isColumnSelectionMode]="false"
  [isSelectionDisabled]="true"
  dataCy="chart-configuration-grid"
>
</app-grid>
