import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import { CommonCalculationPageComponent } from '../common-calculation-page.component';
import { CalculatorsTab } from '../../../+store/ui/ui-module.state';

@Component({
  selector: 'app-friction',
  templateUrl: './friction.component.html',
  styleUrls: ['./friction.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FrictionComponent extends CommonCalculationPageComponent {
  public override appModuleType = ModuleType.Friction_Calc;
  public override tab: CalculatorsTab = 'friction';
}
