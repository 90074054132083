import { PipeType } from '../../../../dto/pipe.dto';
import { RunningStringPipeDto } from '../../../../dto/running-string-pipe.dto';
import { GaugeCarrierPipe, GaugeCarrierPipeFactory } from './gauge-carrier-pipe';
import { Pipe } from '../../pipe';

export interface EccentricGaugeCarrierPipe extends GaugeCarrierPipe {
  PipeType: PipeType.Eccentric_Gauge_Carrier;
}

export class EccentricGaugeCarrierPipeFactory {
  public static createFromRunningString(runningStringDto: RunningStringPipeDto): EccentricGaugeCarrierPipe {
    const gaugeCarrierPipe = GaugeCarrierPipeFactory.createFromRunningString(runningStringDto);
    const eccentricGaugeCarrierPipe: EccentricGaugeCarrierPipe = {
      ...gaugeCarrierPipe,
      PipeType: PipeType.Eccentric_Gauge_Carrier,
    };
    return eccentricGaugeCarrierPipe;
  }
}

export function isEccentricGaugeCarrierPipe(pipe: Pipe): pipe is EccentricGaugeCarrierPipe {
  return pipe.PipeType === PipeType.Eccentric_Gauge_Carrier;
}
