import { Pipe, PipeTransform } from '@angular/core';
import { IFile } from '@dunefront/common/dto/file.dto';
import { ProjectFileHelpers } from '@dunefront/common/common/project-file-helpers';

@Pipe({
  name: 'fileDisplayName',
})
export class FileDisplayNamePipe implements PipeTransform {
  public transform(file: IFile | undefined): string {
    if (file == null) {
      return '';
    }

    return ProjectFileHelpers.getFileDisplayName(file);
  }
}
