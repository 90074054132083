import { IChartTemplateDto } from '../../dto/chart-templates.dto';
import { ModuleType } from '../../modules/scenario/scenario.dto';
import { DataFileType } from '../../dto/data-storage';

export class ChartTemplateHelpers {
  public static findMatchingTemplateId(modules: ModuleType[], possiblyMatchingTemplates: IChartTemplateDto[]): number | undefined {
    for (const module of modules) {
      const matchingTemplate = possiblyMatchingTemplates.find((template) => template.Modules.includes(module));
      if (matchingTemplate) {
        return matchingTemplate.Id;
      }
    }
    return;
  }

  public static isFileTypeMatching(
    fileType: DataFileType,
    templateDefaultFileTypes: DataFileType[],
    seriesFileType?: DataFileType,
  ): boolean {
    const templateTypes = seriesFileType != null ? [seriesFileType] : templateDefaultFileTypes;

    return templateTypes.includes(fileType);
  }
}
