import { Injectable } from '@angular/core';
import { BaseWsEffects } from '../base-ws.effects';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { BackendConnectionService } from '../../shared/backend-connection/backend-connection.service';
import { ModalService } from '../../common-modules/modals/modal.service';
import { Store } from '@ngrx/store';
import {
  CalculationEngineModuleName,
  StartCalculationsAction,
  UserJobs,
} from '@dunefront/common/modules/calculation-engine/calculation-engine.actions';
import {
  addCalculationsToQueueAction,
  CalculationContext,
  calculationProgressUpdatedAction,
  clearCalculationQueueAction,
  IValidateAndAddCalculationToQueueActionPayload,
  userJobsUpdatedAction,
  validateAndAddCalculationToQueueAction,
} from './calculation-engine.actions';
import { catchError, filter, map, mergeMap, tap } from 'rxjs/operators';
import { validateState } from '../state-validation-custom-operators';
import { filterNil } from '@dunefront/common/common/state.helpers';
import { firstValueFrom, Observable, of } from 'rxjs';
import {
  ISimulationValidationResponse,
  ValidateSimulationsAction,
  ValidationModuleName,
} from '@dunefront/common/modules/validation/validation.actions';
import { getAllScenarios, getScenariosToCompare } from '../scenario/scenario.selectors';
import { ErrorHelper } from '@dunefront/common/common/common-state.interfaces';
import { WsAction } from '@dunefront/common/ws.action';

import { getValidatedDeveloperSettings } from '../settings/validated-settings.selectors';
import { dataFailed, insertRowsSuccess } from '../app.actions';
import { CrudResponse } from '@dunefront/common/modules/common.actions';
import { RouterHelperService } from '../../shared/services/router-helper.service';
import { getCurrentFileCalculationEngineState, getJobForCurrentContext } from './calculation-engine.selectors';
import {
  areCalcContextsEqual,
  CalculationEngineMessageType,
  jobToContext,
} from '@dunefront/common/modules/calculation-engine/calculation-engine.interfaces';
import { WsActionResponse } from '@dunefront/common/response-ws.action';
import { CalculationQueueComponent } from '../../pages/common/calculation-queue/components/calculation-queue.component';
import { getCurrentFeatures } from '../licensing/licensing.selectors';
import { IGetCurrentFeaturesResult } from '@dunefront/common/modules/licensing/licensing.interfaces';
import { CalcEngineErrorComponent } from '../../common-modules/modals/calc-engine-error/calc-engine-error.component';
import { IAppError } from '@dunefront/common/exceptions/IAppError';
import { Scenario } from '@dunefront/common/modules/scenario/scenario';
import { compareScenariosChangeConfigAction } from '../scenario/scenario.actions';

export type EmitToModuleFn = (
  moduleName: string,
  isDbDependent: boolean,
  action: WsAction,
  activityTitle?: string,
  message?: string,
) => Observable<WsActionResponse<string[]>>;

@Injectable()
export class CalculationEngineDbDependentEffects extends BaseWsEffects {
  public insertRowsSuccessUserJobs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(insertRowsSuccess),
      map((action) => action.affectedRows.userJobs),
      filterNil(),
      map((userJobs) => userJobsUpdatedAction({ payload: { userJobs } })),
    ),
  );

  private startCalculationsAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addCalculationsToQueueAction),
      concatLatestFrom(() => this.store.select(getValidatedDeveloperSettings)),
      mergeMap(([action, developerSettings]) =>
        this.emit<CrudResponse>(
          new StartCalculationsAction(
            {
              calcDetails: action.scenariosAndRanges.map(({ scenarioId, rangeId }) => ({
                scenarioId,
                rangeId,
                moduleType: action.moduleType,
                fileHash: action.fileHash,
              })),
              developerSettings,
            },
            { isStartedFromScenarioManager: action.calculationContext !== CalculationContext.SINGLE_SCENARIO },
          ),
        ).pipe(
          tap(async (response) => {
            if (this.shouldShowAnimationView(action, response.payload.affectedRows.userJobs)) {
              await this.routerHelperService.navigateToChartUrl(action.moduleType);
            } else {
              this.showCalculationQueue();
            }
          }),

          map((result: WsActionResponse<CrudResponse>) => insertRowsSuccess(result.payload)),
          catchError((err) => of(dataFailed(err))),
        ),
      ),
    ),
  );

  private validateAndAddCalculationsToQueueAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(validateAndAddCalculationToQueueAction),
      filter((action) => action.calculationContext === CalculationContext.SINGLE_SCENARIO),
      validateState(this, this.modalService, this.store),
      map((action) => addCalculationsToQueueAction(action)),
    ),
  );

  private validateAndAddCalculationsToQueueActionFromScenarioManager$ = createEffect(() =>
    this.actions$.pipe(
      ofType(validateAndAddCalculationToQueueAction),
      filter(
        (action) =>
          action.calculationContext === CalculationContext.SCENARIO_MANAGER ||
          action.calculationContext === CalculationContext.COMPARE_SCENARIO,
      ),
      concatLatestFrom(() => this.store.select(getCurrentFeatures)),
      mergeMap(([action, currentFeatures]) => this.multipleScenarioSimulationValidate(action, currentFeatures)),
      filterNil(),
      map((validatedAction) => addCalculationsToQueueAction(validatedAction)),
    ),
  );

  private onCompletedAndNoJobsInQueue$ = createEffect(() =>
    this.actions$.pipe(
      ofType(calculationProgressUpdatedAction),
      concatLatestFrom(() => [this.store.select(getCurrentFileCalculationEngineState), this.store.select(getJobForCurrentContext)]),
      filter(([action, state]) => action.jobPayload.messageType === CalculationEngineMessageType.complete),
      tap(([action, state, job]) => {
        if (job != null && action.jobPayload.jobId === job.jobId && action.jobPayload.options?.isStartedFromScenarioManager === false) {
          this.modalService.showAlert(action.jobPayload.calcEngineMessage.textMessage, 'Information').then();
        }
      }),
      map(([action]) => clearCalculationQueueAction({ fileHash: action.currentFileHash })),
    ),
  );

  private onCalculationEngineErrorQueue$ = createEffect(() =>
    this.actions$.pipe(
      ofType(calculationProgressUpdatedAction),
      concatLatestFrom(() => this.store.select(getCurrentFileCalculationEngineState)),
      filter(([action, state]) => action.jobPayload.messageType === CalculationEngineMessageType.error),
      tap(([action]) =>
        this.modalService.open(CalcEngineErrorComponent, {
          error: JSON.parse(action.jobPayload.calcEngineMessage.textMessage) as IAppError,
        }),
      ),
      map(([action]) => clearCalculationQueueAction({ fileHash: action.currentFileHash })),
    ),
  );

  constructor(
    private routerHelperService: RouterHelperService,
    actions$: Actions,
    wsService: BackendConnectionService,
    modalService: ModalService,
    store: Store,
  ) {
    super(actions$, wsService, CalculationEngineModuleName, false, true, modalService, store);
  }

  // region helpers

  private multipleScenarioSimulationValidate(
    action: IValidateAndAddCalculationToQueueActionPayload,
    currentLicenseFeatures: IGetCurrentFeaturesResult,
  ): Observable<IValidateAndAddCalculationToQueueActionPayload | null> {
    return this.emitToModule<ISimulationValidationResponse[]>(
      ValidationModuleName,
      true,
      new ValidateSimulationsAction(
        action.scenariosAndRanges.map((scenario) => scenario.scenarioId),
        action.moduleType,
        currentLicenseFeatures,
      ),
      'Validating scenarios...',
    ).pipe(
      concatLatestFrom(() => this.store.select(getAllScenarios)),
      mergeMap(async ([response, scenarios]) => {
        const scenariosWithError = response.payload.filter((err) => !!err.errorMessage);
        const scenariosWithErrorIds = scenariosWithError.map((sc) => sc.scenarioId);

        if (scenariosWithError.length) {
          // if there are scenarios with errors, show prompt, if confirmed, return only valid scenarios

          if (action.calculationContext === CalculationContext.COMPARE_SCENARIO) {
            const selectedScenarios = await firstValueFrom(this.store.select(getScenariosToCompare));
            const isCompareScenarioPossible = selectedScenarios.scenarioIds.length - scenariosWithError.length > 0;

            // deselect invalid scenarios from compare scenario
            this.store.dispatch(
              compareScenariosChangeConfigAction({
                batchAction: false,
                scenarioIds: selectedScenarios.scenarioIds.filter((s) => !scenariosWithErrorIds.includes(s)),
              }),
            );

            // when all selected scenarios for compare scenario are invalid, we cannot continue.
            if (!isCompareScenarioPossible) {
              await this.showCannotCompareScenario(scenariosWithError, scenarios);
              return null;
            }
          }

          const confirm = await this.showMultipleSimulationValidationError(scenariosWithError, scenarios);
          const scenariosToRun = action.scenariosAndRanges.filter((sc) => !scenariosWithErrorIds.includes(sc.scenarioId));

          if (!confirm || scenariosToRun.length === 0) {
            return null;
          }

          return {
            ...action,
            scenariosAndRanges: scenariosToRun,
            startedFromScenarioManager: true,
          };
        }

        return action;
      }),
    );
  }

  private shouldShowAnimationView(action: IValidateAndAddCalculationToQueueActionPayload, userJobs?: UserJobs): boolean {
    if (
      action.calculationContext === CalculationContext.COMPARE_SCENARIO ||
      action.calculationContext === CalculationContext.SCENARIO_MANAGER
    ) {
      return false;
    }

    const activeJobs = userJobs?.calcEngineJobs?.activeJobs;
    if (activeJobs == null) {
      return false;
    }

    // check if all requested (should be one) calculations are running

    for (const { scenarioId, rangeId } of action.scenariosAndRanges) {
      const hasActiveJob = activeJobs.some((job) =>
        areCalcContextsEqual(jobToContext(job), {
          fileHash: action.fileHash,
          moduleType: action.moduleType,
          scenarioId,
          rangeId,
        }),
      );

      if (!hasActiveJob) {
        return false;
      }
    }

    return true;
  }

  private getInvalidScenariosMessage(errors: ISimulationValidationResponse[], scenarios: Scenario[]): string {
    let message = ErrorHelper.ERROR_MULTIPLE_SCENARIOS_MESSAGE_HEADER;
    errors.forEach((scenarioError) => {
      const scenario = scenarios.find((s) => s.Id === scenarioError.scenarioId);
      message += `-> ${scenario?.Name} <br>`;
    });

    return message;
  }

  private async showMultipleSimulationValidationError(errors: ISimulationValidationResponse[], scenarios: Scenario[]): Promise<boolean> {
    let message = this.getInvalidScenariosMessage(errors, scenarios);

    message += ErrorHelper.ERROR_SIMULATION_ONLY_VALID_CONTINUE;

    return await this.modalService.showConfirm(message, 'Warning', 'lg');
  }

  private async showCannotCompareScenario(errors: ISimulationValidationResponse[], scenarios: Scenario[]): Promise<void> {
    let message = this.getInvalidScenariosMessage(errors, scenarios);

    message += ErrorHelper.ERROR_SIMULATION_COMPARE_SCENARIO_NOT_POSSIBLE;

    return await this.modalService.showAlert(message);
  }

  private showCalculationQueue(): void {
    this.modalService.open(CalculationQueueComponent, {}, '', 'lg');
  }

  // endregion
}
