import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { DbDependentComponent } from '../../../../common-modules/db-connection/db-dependent.component';
import { Store } from '@ngrx/store';
import { updatePumpingAction } from '../../../../+store/pumping/pumping.actions';
import { PumpingDto } from '@dunefront/common/modules/pumping/dto/pumping.dto';
import { ModalService } from '../../../../common-modules/modals/modal.service';
import { getValidatedPumpingModuleState } from '../../../../+store/pumping/selectors/pumping-validation.selectors';
import { ValidatedPumpingModuleState } from '@dunefront/common/modules/pumping/pumping-module.state';
import * as uiActions from '../../../../+store/ui/ui.actions';
import { Pumping } from '@dunefront/common/modules/pumping/model/pumping/pumping';
import { changeProp, ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';
import { getValidatedWellModuleState } from '../../../../+store/well/validated-well.selectors';

@Component({
  selector: 'app-schedule-options',
  templateUrl: './schedule-options.component.html',
  styleUrls: ['./schedule-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScheduleOptionsComponent extends DbDependentComponent {
  public pumpingState!: ValidatedPumpingModuleState;
  public wellState$ = this.store.select(getValidatedWellModuleState);

  constructor(
    store: Store,
    cdRef: ChangeDetectorRef,
    protected modalService: ModalService,
  ) {
    super(store, cdRef);

    this.subscription.add(
      this.store.select(getValidatedPumpingModuleState).subscribe((pumping) => {
        this.pumpingState = pumping;
        this.cdRef.markForCheck();
      }),
    );
    store.dispatch(uiActions.setPumpingTabAction({ tab: 'schedule-options' }));
    this.onHelpChange('pumping', 'schedule-generator');
  }

  public onPumpingPropertyChanged(props: ObjectChangeProp<Pumping>): void {
    const newPumping = changeProp(this.pumpingState.pumping, props);
    this.store.dispatch(
      updatePumpingAction({
        isSimulate: false,
        pumping: newPumping,
        changedKeys: [props.key] as (keyof PumpingDto)[],
      }),
    );
  }
}
