import { UnitConverter } from '../../converter.interfaces';
import { TemperatureTimeDerivativeUnit } from '../../../dto/unit-system.dto';

export class TemperatureTimeDerivativeConverter extends UnitConverter {
  public static symbols: Array<string> = ['', '°C/s', '°C/h', '°C/min', 'K/s', 'K/h', 'K/min', '°F/s', '°F/h', '°F/min'];

  public static unit = TemperatureTimeDerivativeUnit;

  public static fromSi(value: number, unit: TemperatureTimeDerivativeUnit): number {
    switch (unit) {
      case TemperatureTimeDerivativeUnit.Celsius_per_second: {
        return value;
      }
      case TemperatureTimeDerivativeUnit.Celsius_per_hour: {
        return value * 3600;
      }
      case TemperatureTimeDerivativeUnit.Celsius_per_minute: {
        return value * 60;
      }
      case TemperatureTimeDerivativeUnit.Kelvin_per_second: {
        return value;
      }
      case TemperatureTimeDerivativeUnit.Kelvin_per_hour: {
        return value * 3600;
      }
      case TemperatureTimeDerivativeUnit.Kelvin_per_minute: {
        return value * 60;
      }
      case TemperatureTimeDerivativeUnit.Fahrenheit_per_second: {
        return value * 1.8;
      }
      case TemperatureTimeDerivativeUnit.Fahrenheit_per_hour: {
        return value * 6480;
      }
      case TemperatureTimeDerivativeUnit.Fahrenheit_per_minute: {
        return value * 108;
      }
      default: {
        throw new TypeError('Unknown Temperature Time Derivative Unit:' + unit);
      }
    }
  }

  public static toSi(value: number, unit: TemperatureTimeDerivativeUnit): number {
    switch (unit) {
      case TemperatureTimeDerivativeUnit.Celsius_per_second: {
        return value;
      }
      case TemperatureTimeDerivativeUnit.Celsius_per_hour: {
        return value / 3600;
      }
      case TemperatureTimeDerivativeUnit.Celsius_per_minute: {
        return value / 60;
      }
      case TemperatureTimeDerivativeUnit.Kelvin_per_second: {
        return value;
      }
      case TemperatureTimeDerivativeUnit.Kelvin_per_hour: {
        return value / 3600;
      }
      case TemperatureTimeDerivativeUnit.Kelvin_per_minute: {
        return value / 60;
      }
      case TemperatureTimeDerivativeUnit.Fahrenheit_per_second: {
        return value / 1.8;
      }
      case TemperatureTimeDerivativeUnit.Fahrenheit_per_hour: {
        return value / 6480;
      }
      case TemperatureTimeDerivativeUnit.Fahrenheit_per_minute: {
        return value / 108;
      }
      default: {
        throw new TypeError('Unknown Temperature Time Derivative Unit:' + unit);
      }
    }
  }

  public static getSymbol(unit: TemperatureTimeDerivativeUnit): string {
    if (unit == null) {
      return '';
    }
    return this.symbols[unit];
  }
}
