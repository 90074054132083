<app-page-panel header="Transport Tube Properties">
  <div class="grid-form-container" *ngIf="shuntTube" [class.show-shape-rectangle]="isRectangle" [class.show-shape-round]="!isRectangle">
    <app-form-input
      label="No. of Tubes"
      appUnwrap
      [disabled]="!isTransportTubePresent"
      [source]="shuntTube.TransportTube"
      [key]="'NumberOfTubes'"
      [decimalPlaces]="0"
      [unitType]="UnitType.None"
      (valueChanged)="onValueChange($event)"
    >
    </app-form-input>
    <app-form-input
      appUnwrap
      label="Width"
      [cssClass]="'shape-rectangle'"
      [disabled]="!isTransportTubePresent"
      [source]="shuntTube.TransportTube"
      [key]="'Width'"
      [decimalPlaces]="3"
      [unitType]="UnitType.Short_Length"
      (valueChanged)="onValueChange($event)"
    >
    </app-form-input>
    <app-form-input
      appUnwrap
      label="Height"
      [cssClass]="'shape-rectangle'"
      [disabled]="!isTransportTubePresent"
      [source]="shuntTube.TransportTube"
      [key]="'Height'"
      [decimalPlaces]="3"
      [unitType]="UnitType.Short_Length"
      (valueChanged)="onValueChange($event)"
    >
    </app-form-input>
    <app-form-input
      appUnwrap
      label="Thickness"
      [cssClass]="'shape-rectangle'"
      [disabled]="!isTransportTubePresent"
      [source]="shuntTube.TransportTube"
      [key]="'Thickness'"
      [decimalPlaces]="3"
      [unitType]="UnitType.Short_Length"
      (valueChanged)="onValueChange($event)"
    >
    </app-form-input>
    <app-form-input
      appUnwrap
      label="OD"
      [cssClass]="'shape-round'"
      [disabled]="!isTransportTubePresent"
      [source]="shuntTube.TransportTube"
      [key]="'OuterDiameter'"
      [decimalPlaces]="3"
      [unitType]="UnitType.Diameter"
      (valueChanged)="onValueChange($event)"
    >
    </app-form-input>
    <app-form-input
      appUnwrap
      label="ID"
      [cssClass]="'shape-round'"
      [disabled]="!isTransportTubePresent"
      [source]="shuntTube.TransportTube"
      [key]="'InnerDiameter'"
      [decimalPlaces]="3"
      [unitType]="UnitType.Diameter"
      (valueChanged)="onValueChange($event)"
    >
    </app-form-input>
    <app-form-input
      appUnwrap
      label="Burst Rating"
      [disabled]="!isTransportTubePresent"
      [source]="shuntTube.TransportTube"
      [key]="'BurstRating'"
      [decimalPlaces]="2"
      [unitType]="UnitType.Pressure"
      (valueChanged)="onValueChange($event)"
    >
    </app-form-input>
    <app-form-input
      appUnwrap
      label="Collapse Rating"
      [disabled]="!isTransportTubePresent"
      [source]="shuntTube.TransportTube"
      [key]="'CollapseRating'"
      [decimalPlaces]="2"
      [unitType]="UnitType.Pressure"
      (valueChanged)="onValueChange($event)"
    >
    </app-form-input>
  </div>
</app-page-panel>
