import { Color, HorizontalAlignment, LineStyle, SeriesLineStyle, VerticalAlignment } from '../modules/reporting/dto/chart.types';
import { IAxisMargin, IAxisStyle } from '../modules/reporting/dto/chart-axis-property.dto';
import { ILegendStyle } from '../modules/reporting/dto/chart-legend';
import { MarkerShape } from '../modules/reporting/dto/chart-marker.dto';
import { IAnnotationStyle } from '../modules/reporting/dto/chart-annotation.dto';
import {
  defaultAxisFontColor,
  defaultChartSeriesColours,
  defaultCopyChartRatio,
  defaultCopyChartScaling,
  defaultGravelConcColor,
  defaultLegendFontColor,
  defaultPumpPressureColor,
  defaultPumpRateColor,
  defaultReturnRateColor,
  defaultVideoFramerate,
  defaultVideoSteps,
  defaultXAxisMarginPercent,
  defaultYAxisMarginPercent,
} from '../common/constants';
import { ratioToStr } from '../common/chart-helpers/chart-copy-options.helpers';
import { IGlobalSeriesStyles } from '../modules/reporting/dto/chart-series.dto';
import { IValidatedModuleState } from '../common/common-state.interfaces';
import { IGradientLineStyle } from '../modules/reporting/dto/chart-gradient-line.dto';

export interface GlobalOptionsDto extends IAxisStyle, IAxisMargin, ILegendStyle, IAnnotationStyle, IGlobalSeriesStyles, IGradientLineStyle {
  IsOverrideChartMarker: boolean;
  IsChartMarkerFontBold: boolean;
  ChartMarkerFontColor: Color;
  IsChartMarkerFontItalic: boolean;
  ChartMarkerFontSize: number;
  ChartMarkerHorizontalTextAlignment: HorizontalAlignment;
  ChartMarkerHorizontalTextIndentation: number;
  ChartMarkerLineColor: Color;
  ChartMarkerLineStyle: LineStyle;
  ChartMarkerLineThickness: number;
  ChartMarkerVerticalTextAlignment: VerticalAlignment;
  ChartMarkerVerticalTextIndentation: number;

  IsOverrideChartPerformance: boolean;
  EngineRealTimeUpdateInterval: number;
  ChartSliderUpdateInterval: number;
  MaxChartPoints: number;
  ImportFileMaxTimespan: number; // days

  IsOverrideChartAxis: boolean;

  IsOverrideLegend: boolean;
  LegendLocation: number;
  IsLegendFontBold: boolean;
  IsLegendFontItalic: boolean;
  LegendFontSize: number;

  IsOverrideSeries: boolean;
  IsOverrideAnnotationStyle: boolean;
  IsOverrideGradientLineStyle: boolean;

  IsOverrideCopyChartOptions: boolean;
  CopyChartRatio: string;
  CopyChartScaling: number;

  PumpPressureColor: string;
  PumpRateColor: string;
  ReturnRateColor: string;
  GravelConcColor: string;

  IsOverrideVideoPerformance: boolean;
  VideoSteps: number;
  VideoFramerate: number;

  // other options
  UseTemporaryProjectFile: boolean;
}

export interface DefinedGlobalOptionsDto extends GlobalOptionsDto {
  UseTemporaryProjectFile: boolean;
}

export const initialGlobalOptionsDto: DefinedGlobalOptionsDto = {
  IsOverrideChartMarker: false,
  IsChartMarkerFontBold: false,
  ChartMarkerFontColor: Color.DarkSlateGrey,
  IsChartMarkerFontItalic: false,
  ChartMarkerFontSize: 12,
  ChartMarkerHorizontalTextAlignment: HorizontalAlignment.start,
  ChartMarkerHorizontalTextIndentation: 0,
  ChartMarkerLineColor: Color.DarkSlateGrey,
  ChartMarkerLineStyle: LineStyle.solid,
  ChartMarkerLineThickness: 1,
  ChartMarkerVerticalTextAlignment: VerticalAlignment.start,
  ChartMarkerVerticalTextIndentation: 0,

  IsOverrideChartPerformance: false,
  EngineRealTimeUpdateInterval: 0.1,
  ChartSliderUpdateInterval: 0.05,
  MaxChartPoints: 2500,

  IsOverrideChartAxis: false,
  AxisTitleFontSize: 12,
  AxisTitleFontColor: defaultAxisFontColor,
  AxisTitleFontBold: false,
  AxisTitleFontItalic: false,
  AxisLabelFontSize: 12,
  AxisLabelFontColor: defaultAxisFontColor,
  AxisLabelFontBold: false,
  AxisLabelFontItalic: false,
  XAxisStartMarginPercent: defaultXAxisMarginPercent,
  XAxisNoMarginWhenStartingNearZero: false,
  XAxisEndMarginPercent: defaultXAxisMarginPercent,
  YAxisStartMarginPercent: defaultYAxisMarginPercent,
  YAxisNoMarginWhenStartingNearZero: false,
  YAxisEndMarginPercent: defaultYAxisMarginPercent,

  IsOverrideLegend: false,
  LegendLocation: 0,
  IsLegendFontBold: false,
  IsLegendFontItalic: false,
  LegendFontSize: 12,
  LegendFontColor: defaultLegendFontColor,

  IsOverrideSeries: false,
  SeriesLineThickness: 2,
  SeriesColours: defaultChartSeriesColours.join(','),
  SeriesLineStyle: SeriesLineStyle.solid,
  SeriesPointMarkersEnabled: false,
  SeriesPointMarkersShape: MarkerShape.circle,
  SeriesPointMarkersSize: 3,
  PumpPressureColor: defaultPumpPressureColor,
  PumpRateColor: defaultPumpRateColor,
  ReturnRateColor: defaultReturnRateColor,
  GravelConcColor: defaultGravelConcColor,

  IsOverrideAnnotationStyle: false,
  AnnotationFontSize: 12,
  AnnotationFontBold: false,
  AnnotationFontItalic: false,
  AnnotationFontColour: Color.Black,
  AnnotationFillColour: Color.WhiteSmoke,
  AnnotationFillTransparency: 0,
  AnnotationTailVisibility: true,

  IsOverrideGradientLineStyle: false,
  GradientLineStyle: LineStyle.dashes,
  GradientLineThickness: 3,
  GradientLineColor: Color.DarkSlateGrey,
  GradientLineDecimalPlaces: 2,

  IsOverrideCopyChartOptions: false,
  CopyChartRatio: ratioToStr(defaultCopyChartRatio),
  CopyChartScaling: defaultCopyChartScaling,

  IsOverrideVideoPerformance: false,
  VideoSteps: defaultVideoSteps,
  VideoFramerate: defaultVideoFramerate,
  ImportFileMaxTimespan: 30,

  UseTemporaryProjectFile: true,
};

export type ValidatedGlobalOptions = GlobalOptionsDto & IValidatedModuleState<GlobalOptionsDto>;
