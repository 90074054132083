import { IUnitsModuleState } from './units.state';
import { CrudResponse } from '@dunefront/common/modules/common.actions';

export class UnitsReducerHelper {
  public static onCrudSuccess(state: IUnitsModuleState, response: CrudResponse): IUnitsModuleState {
    const us = response.affectedRows.unitSystem;

    if (us == null) {
      return state;
    }

    return {
      ...state,
      unitSystem: {
        ...state.unitSystem,
        personalUnitSystems: us.personalUnitSystems,
        orgUnitSystems: us.orgUnitSystems,
        currentUnitSystemId: us.currentUnitSystemId,
      },
    };
  }
}
