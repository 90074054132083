import { RheometerTestType, RheometerWithRheometerReadings } from './rheometer.dto';
import { RheologyDto, RheologyType } from './rheology/rheology.dto';
import { GeneralCalculations } from '../../../common/general.calculations';
import { COPY_NAME_SUFFIX } from '../../../common/constants';
import { Color } from '../../reporting/dto/chart.types';

export interface FluidDto {
  ScenarioId: number;
  Id: number;
  CleanFluidDensity: number;
  DragReductionFactor: number;
  Name: string;
  Type: FluidType;
  DatabaseType: FluidGravelDatabaseType;
  RheologyType: RheologyType;
  IsGravelSettling: boolean;
  IsYieldStress: boolean;
  IsMaxViscosity: boolean;
  IsMinViscosity: boolean;
  MaxViscosity: number;
  MinViscosity: number;
  RheometerTestType: RheometerTestType;
  ThermalConductivity: number;
  SpecificHeat: number;
  SortOrder: number;
  IsRheologyManual: boolean;
  WashpipeViscousDegradationFactor: number;
  AnnulusViscousDegradationFactor: number;
  Color: Color | null;
  Notes: string;
}

export enum FluidType {
  Polymer = 0,
  Surfactant_Gel = 1,
  Newtonian = 2,
}

export enum FluidGravelDatabaseType {
  Custom = 0,
  Predefined = 1,
}

export const defaultFluidColours = [Color.Red, Color.Orange, Color.Yellow, Color.Blue, Color.Green, Color.Indigo, Color.Violet];

export class FluidHelper {
  public static readonly DEFAULT_NAME = 'New Fluid';

  public static getFluidCopyName(fluidName: string): string {
    return fluidName + COPY_NAME_SUFFIX;
  }

  // Method to check if this is the default fluid
  public static IsDefaultFluid(fluid: FluidDto): boolean {
    if (!this.IsNamedNewFluid(fluid)) {
      return false;
    }
    if (fluid.Type !== FluidType.Polymer) {
      return false;
    }
    if (fluid.CleanFluidDensity !== 0) {
      return false;
    }
    if (fluid.DragReductionFactor !== 0) {
      return false;
    }
    if (fluid.RheologyType !== RheologyType.NPrimeAndKPrime) {
      return false;
    }
    if (!fluid.IsGravelSettling) {
      return false;
    }
    if (!fluid.IsYieldStress) {
      return false;
    }
    if (fluid.IsMaxViscosity) {
      return false;
    }
    if (fluid.IsMinViscosity) {
      return false;
    }
    return true;
  }

  public static HasFluidDefaultRheologies(rheologies: RheologyDto[]): boolean {
    if (rheologies.length !== 1) {
      return false;
    }
    if (rheologies[0].NPrime !== 0) {
      return false;
    }
    if (rheologies[0].KPrime !== 0) {
      return false;
    }
    return true;
  }

  // Method to check if this is a new fluid
  public static IsNamedNewFluid(fluid: FluidDto): boolean {
    return GeneralCalculations.AreStringsTheSame(fluid.Name, this.DEFAULT_NAME);
  }
}

export interface FluidWithDependenciesDto extends FluidDto {
  RheometerData: RheometerWithRheometerReadings[];
  Rheologies: RheologyDto[];
}
