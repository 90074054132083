import { Validation } from '../../../../common/validation';
import { GeneralCalculations } from '../../../../common/general.calculations';
import { ZoneDto } from './zone.dto';

export class ZoneCalculations {
  public static ZoneLength(zone: ZoneDto): number {
    return Validation.UICheckNumberValidity(zone.BottomMD - zone.TopMD);
  }

  public static ZoneHeight(zone: ZoneDto): number {
    return Validation.UICheckNumberValidity(zone.BottomTVD - zone.TopTVD);
  }

  public static PlaneStrainModulus(zone: ZoneDto): number {
    return zone.YoungsModulus / (1.0 - GeneralCalculations.Pow(zone.PoissonsRatio, 2.0));
  }
}
