import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { helpReducer } from '../../+store/help/help.reducer';
import { AboutModalComponent } from './about/about.modal.component';
import { ButtonModule } from '../../shared/components/button/button.module';
import { LicensingSessionsModalComponent } from './licensing/licensing-sessions/licensing-sessions.modal.component';
import { MatTableModule } from '@angular/material/table';
import { AccordionModule } from 'primeng/accordion';
import { QuillModule } from 'ngx-quill';
import { FormsModule } from '@angular/forms';
import { HelpAndNotesComponent } from './help-notes-component/help-and-notes.component';
import { LicensingModalComponent } from './licensing/licensing-dialog/licensing.modal.component';
import { LetDirective } from '@ngrx/component';
import { PagePanelModule } from '../page-panel/page-panel.component';
import { LicensingDetachModalComponent } from './licensing/licensing-detach-dialog/licensing-detach.modal.component';
import { FormInputModule } from '../../shared/components/form-components/form-input/form-input.component';
import { ModalsModule } from '../modals/modals.module';
import { HelpUrlPipeModule } from '../../shared/pipes/help-url.pipe';

@NgModule({
  declarations: [
    HelpAndNotesComponent,
    AboutModalComponent,
    LicensingModalComponent,
    LicensingSessionsModalComponent,
    LicensingDetachModalComponent,
  ],
  exports: [HelpAndNotesComponent, AboutModalComponent, LicensingModalComponent],
  imports: [
    CommonModule,
    StoreModule.forFeature('help', helpReducer),
    ButtonModule,
    MatTableModule,
    AccordionModule,
    QuillModule.forRoot({
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],
          [{ list: 'bullet' }],
        ],
        clipboard: {
          matchVisual: false,
        },
      },
    }),
    FormsModule,
    LetDirective,
    PagePanelModule,
    FormInputModule,
    ModalsModule,
    HelpUrlPipeModule,
  ],
})
export class HelpModule {}
