import { WsAction } from '../../ws.action';

export const FileSettingsModuleName = 'FileSettingsModule';

export enum FileSettingsModuleActionTypes {
  Load = '[FileSettingsModule] Load',
  Lock = '[FileSettingsModule] Lock',
  Unlock = '[FileSettingsModule] Unlock',
  UpdateLastUsedScenarioId = '[FileSettingsModule] UpdateLastUsedScenarioId',
}

// region load

export class LoadFileSettingsAction extends WsAction {
  public override readonly type = FileSettingsModuleActionTypes.Load;
}

// endregion

// region lock

export class LockInputsAction extends WsAction {
  public override readonly type = FileSettingsModuleActionTypes.Lock;

  constructor(public name: string) {
    super();
  }
}

// endregion

// region unlock

export class UnlockInputsAction extends WsAction {
  public override readonly type = FileSettingsModuleActionTypes.Unlock;
}

// endregion

// region lock

export class UpdateFileSettingsAction extends WsAction {
  public override readonly type = FileSettingsModuleActionTypes.UpdateLastUsedScenarioId;

  constructor(public scenarioId: number) {
    super();
  }
}

// endregion

export type FileSettingsModuleActions = LoadFileSettingsAction | LockInputsAction | UnlockInputsAction | UpdateFileSettingsAction;
