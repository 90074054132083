import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { DbDependentComponent } from '../../../../common-modules/db-connection/db-dependent.component';
import { Store } from '@ngrx/store';
import { ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import * as uiActions from '../../../../+store/ui/ui.actions';
import { ChartDataSourceType } from '@dunefront/common/modules/reporting/dto/chart.dto';
import { getAreCurrentResultsPresent } from '../../../../+store/calculation-engine/calculation-engine-results.selectors';
import { PagePanelComponent } from '../../../../common-modules/page-panel/page-panel.component';
import { DrawingLayoutType, WellPartType } from '@dunefront/common/modules/settings/dto/settingsDto';
import { PanelHelpMode } from '../../../../shared/components/help-button/help-buton.component';
import { getEffectiveSimulationAnimationWellPart } from '../animation.selectors';

@Component({
  selector: 'app-disp-simulation-animation',
  templateUrl: './simulation-disp-animation.component.html',
  styleUrls: ['./simulation-disp-animation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimulationDispAnimationComponent extends DbDependentComponent implements OnInit {
  public contentVisible = true;
  public ChartDataSourceType = ChartDataSourceType;
  public DrawingLayoutType = DrawingLayoutType;
  public ModuleType = ModuleType;
  public WellPartType = WellPartType;
  public currentResultsPresent$ = this.store.select(getAreCurrentResultsPresent);
  public PanelHelpMode = PanelHelpMode;
  public drawingSection = WellPartType.Below_Packer;

  @ViewChildren(PagePanelComponent) private pagePanelComponents!: QueryList<PagePanelComponent>;

  constructor(store: Store, cdRef: ChangeDetectorRef) {
    super(store, cdRef);
    store.dispatch(uiActions.setResultsTabAction({ tab: 'simulation-animation' }));

    this.subscription.add(
      this.currentRangeId$.subscribe(() => {
        this.forceReload();
      }),
    );

    this.subscription.add(
      this.store.select(getEffectiveSimulationAnimationWellPart).subscribe((wellPart) => {
        this.drawingSection = wellPart;
        this.cdRef.markForCheck();
      }),
    );

    this.onHelpChange('results', 'animation-simulate');
  }

  public override onCurrentScenarioChanged(): void {
    this.forceReload();
  }

  private forceReload(): void {
    this.contentVisible = false;
    this.cdRef.markForCheck();
    setTimeout(() => {
      this.contentVisible = true;
      this.cdRef.markForCheck();
    });
  }
}
