import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { BackendConnectionService } from '../../shared/backend-connection/backend-connection.service';
import * as actions from './fluid.actions';
import { IFluidUpdate, selectFluidAction, selectTemperature } from './fluid.actions';
import { filter, map, mergeMap, tap } from 'rxjs/operators';
import { deleteRowsSuccess, insertRowsSuccess, updateRowSuccess } from '../app.actions';
import { combineLatest } from 'rxjs';
import * as selectors from './fluid.selectors';
import {
  getFluidIdFromUrl,
  getFluidNames,
  getFluidsArray,
  getLastFluidId,
  getSelectedFluid,
  getSelectedFluidIdFromState,
  getSelectedTemperatureFromState,
  getTemperatureFromUrl,
  ISelectedFluidState,
} from './fluid.selectors';
import {
  FluidCopyRowAction,
  FluidDeleteRowsAction,
  FluidInsertRowsAction,
  FluidModuleName,
  FluidUpdateRowsAction,
  RheologyDeleteRowsAction,
  RheologyInsertRowsAction,
  RheologyUpdateRowsAction,
  RheometerDeleteRowsAction,
  RheometerInsertRowsAction,
  RheometerReadingDeleteRowsAction,
  RheometerReadingInsertRowsAction,
  RheometerReadingUpdateRowsAction,
  RheometerUpdateRowsAction,
} from '@dunefront/common/modules/fluid/fluid-module.actions';
import { BaseWsEffects, EmitUpdateResult } from '../base-ws.effects';
import { FluidDto, FluidHelper } from '@dunefront/common/modules/fluid/dto/fluid.dto';
import { changeShearRateAction } from '../settings/settings.actions';
import { getRowsForCalculations } from '@dunefront/common/common/common-grid.interfaces';
import { GeneralCalculations } from '@dunefront/common/common/general.calculations';
import { ModalService } from '../../common-modules/modals/modal.service';
import { notEmpty } from '@dunefront/common/common/state.helpers';
import { FluidFactory } from '@dunefront/common/modules/fluid/model/fluid.factory';
import { RheologyFactory } from '@dunefront/common/modules/fluid/model/rheology/rheology.factory';
import { RheometerFactory } from '@dunefront/common/modules/fluid/model/rheometer/rheometer.factory';
import { RheometerReadingFactory } from '@dunefront/common/modules/fluid/model/rheometer-reading/rheometer-reading.factory';
import { Fluid } from '@dunefront/common/modules/fluid/model/fluid';
import { getShearRate } from '../settings/validated-settings.selectors';
import { RouterHelperService } from '../../shared/services/router-helper.service';
import { WsActionPropsFactory } from '@dunefront/common/common/ws-action/ws-action-props.factory';
import { CommonDbCopyDirection } from '@dunefront/common/modules/common.actions';

@Injectable()
export class FluidEffects extends BaseWsEffects {
  constructor(
    actions$: Actions,
    store: Store,
    wsService: BackendConnectionService,
    modalService: ModalService,
    private routerHelperService: RouterHelperService,
  ) {
    super(actions$, wsService, FluidModuleName, false, true, modalService, store);

    const fluidIdFromUrl$ = this.store.select(getFluidIdFromUrl).pipe(filter((fluidId) => fluidId > 0));
    const fluidIdFromState$ = this.store.select(getSelectedFluidIdFromState);

    combineLatest([fluidIdFromUrl$, fluidIdFromState$])
      .pipe(filter(([fromUrl, fromState]) => fromUrl !== fromState))
      .subscribe(([fromUrl]) => this.store.dispatch(selectFluidAction({ fluidId: fromUrl })));

    const tempIdFromUtl$ = this.store.select(getTemperatureFromUrl).pipe(filter((tempId) => tempId > 0));
    const tempIdFromState$ = this.store.select(getSelectedTemperatureFromState);

    combineLatest([tempIdFromUtl$, tempIdFromState$])
      .pipe(filter(([fromUrl, fromState]) => fromUrl !== fromState))
      .subscribe(([fromUrl]) => this.store.dispatch(selectTemperature({ temperatureId: fromUrl })));
  }

  //region fluid

  public updateFluidRow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.updateFluid),
      concatLatestFrom(() => this.store.select(selectors.getSelectedFluidState).pipe(filter((currentFluidState) => !!currentFluidState))),
      mergeMap(([action, currentFluidState]) => this.emitFluidUpdateRowAction(action, currentFluidState as ISelectedFluidState)),
    ),
  );

  public insertFluidRow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.newFluid),
      concatLatestFrom(() => [this.store.select(getLastFluidId), this.store.select(getFluidNames), this.store.select(getFluidsArray)]),
      filter(([action, lastFluidId, fluidNames, fluids]) => {
        const isFluidNameAlreadyExist = GeneralCalculations.DoesArrayContainsString(fluidNames, FluidHelper.DEFAULT_NAME);
        if (isFluidNameAlreadyExist) {
          this.modalService.showAlert(`${FluidHelper.DEFAULT_NAME} is already in the list - please rename it first.`).then();
        }
        return !isFluidNameAlreadyExist;
      }),
      mergeMap(([action, lastFluidId, fluidNames, fluids]) =>
        this.emitInsert(
          new FluidInsertRowsAction(
            WsActionPropsFactory.insertDto(
              FluidFactory.fluidToDto(
                FluidFactory.newFluid(action.scenarioId, FluidHelper.DEFAULT_NAME, FluidFactory.getNextFluidColor(fluids)),
              ),
              false,
              'insert',
              lastFluidId,
            ),
          ),
        ),
      ),
    ),
  );

  public copyFluidRow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.copyFluid),
      concatLatestFrom(() => [this.store.select(getLastFluidId), this.store.select(getFluidNames)]),
      mergeMap(([action]) => this.emitInsert(new FluidCopyRowAction(action.fluidId, action.scenarioId))),
    ),
  );

  public deleteFluidRow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.deleteFluid),
      mergeMap((action) => this.emitDelete(new FluidDeleteRowsAction(action))),
    ),
  );

  public redirectAfterFluidCrudSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(insertRowsSuccess, deleteRowsSuccess),
        filter((action) => action.affectedRows.fluid != null && action.commonDbCopyDirection != CommonDbCopyDirection.toCommonDb),
        concatLatestFrom(() => this.store.select(getSelectedFluidIdFromState)),
        tap(([, fluidId]) => this.routerHelperService.navigateToFluid(fluidId)),
      ),
    { dispatch: false },
  );

  // endregion

  //region rheology

  public updateRheologyRow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.updateRheologyRow),
      mergeMap((action) =>
        this.emitUpdate(new RheologyUpdateRowsAction(WsActionPropsFactory.updateAction(action, RheologyFactory.rheologyToDto))),
      ),
    ),
  );

  public insertRheologyRow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.insertRheologyRow),
      mergeMap((action) =>
        this.emitInsert(new RheologyInsertRowsAction(WsActionPropsFactory.insertAction(action, RheologyFactory.rheologyToDto))),
      ),
    ),
  );

  public deleteRheologyRow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.deleteRheologyRows),
      mergeMap((action) => this.emitDelete(new RheologyDeleteRowsAction(action))),
    ),
  );

  public redirectAfterRheometerInsertSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(insertRowsSuccess),
        filter((action) => action.affectedRows.rheometer != null),
        concatLatestFrom(() => this.store.select(getSelectedFluidIdFromState)),
        tap(([action, fluidId]) => {
          const rheometerId = (action.affectedRows.rheometer?.insertedRows ?? [])[0]?.Id;
          if (rheometerId != null) {
            this.routerHelperService.navigateToRheometer(fluidId, rheometerId).then();
          }
        }),
      ),
    { dispatch: false },
  );

  // endregion

  //region rheometer

  public updateRheometerRow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.updateRheometerRow),
      mergeMap((action) => this.emitUpdate(new RheometerUpdateRowsAction(action))),
    ),
  );

  public insertRheometerRow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.insertRheometerRow),
      mergeMap((action) =>
        this.emitInsert(
          new RheometerInsertRowsAction(
            WsActionPropsFactory.insertDto(
              RheometerFactory.rheometerToDto(
                RheometerFactory.newRheometer(action.fluidId, action.scenarioId, action.temperature, action.speedType),
              ),
              true,
            ),
          ),
        ),
      ),
    ),
  );

  public deleteRheometerRow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.deleteRheometerRows),
      mergeMap((action) => this.emitDelete(new RheometerDeleteRowsAction(action))),
    ),
  );

  // endregion

  //region rheometer readings

  public updateRheometerReadingRow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.updateRheometerReadingRow),
      mergeMap((action) => this.emitUpdate(new RheometerReadingUpdateRowsAction(action))),
    ),
  );

  public insertRheometerReadingRow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.insertRheometerReadingRow),
      mergeMap((action) =>
        this.emitInsert(
          new RheometerReadingInsertRowsAction(WsActionPropsFactory.insertAction(action, RheometerReadingFactory.rheometerReadingToDto)),
        ),
      ),
    ),
  );

  public deleteRheometerReadingRow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.deleteRheometerReadingRows),
      mergeMap((action) => this.emitDelete(new RheometerReadingDeleteRowsAction(action))),
    ),
  );

  // endregion

  public recalculateRheologiesAfterDataChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateRowSuccess, deleteRowsSuccess, insertRowsSuccess),
      filter(
        (action) =>
          !!action.affectedRows.fluid ||
          !!action.affectedRows.rheology ||
          !!action.affectedRows.rheometer ||
          !!action.affectedRows.rheometerReading,
      ),
      concatLatestFrom(() => [this.store.select(getShearRate), notEmpty(this.store.select(getSelectedFluid))]),
      map(([action, shearRate, fluid]) => actions.recalculateRheologies({ shearRate, fluidId: fluid.Id })),
    ),
  );

  public recalculateRheologiesAfterSelectedFluidOrShearRateChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(selectFluidAction, changeShearRateAction),
      concatLatestFrom(() => [this.store.select(getShearRate), notEmpty(this.store.select(getSelectedFluid))]),
      map(([action, shearRate, fluid]) => actions.recalculateRheologies({ shearRate, fluidId: fluid.Id })),
    ),
  );

  private emitFluidUpdateRowAction(action: IFluidUpdate, selectedFluidState: ISelectedFluidState): EmitUpdateResult {
    const fluidDto = FluidFactory.fluidToDto(selectedFluidState.fluid as Fluid);
    const rheologiesToSave = getRowsForCalculations(selectedFluidState.rheologies.rows).map((row) => RheologyFactory.rheologyToDto(row));
    const wsAction = WsActionPropsFactory.update([fluidDto], true, [action.changedProp.key as keyof FluidDto]);

    return this.emitUpdate(new FluidUpdateRowsAction(wsAction, rheologiesToSave));
  }
}
