import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { DbDependentComponent } from '../../../../common-modules/db-connection/db-dependent.component';
import { Store } from '@ngrx/store';
import { ModalService } from '../../../../common-modules/modals/modal.service';
import { notEmpty } from '@dunefront/common/common/state.helpers';

import {
  calculateLeakoffCoefficient,
  updateLeakoffCoefficientCalculatorRow,
} from '@dunefront/common/modules/calculators/leakoff-coefficient-calculator/leakoff-coefficient-calculator.actions';
import {
  LeakoffCoefficientCalculatorModuleState,
  ValidatedLeakoffCoefficientCalculatorModuleState,
} from '@dunefront/common/modules/calculators/leakoff-coefficient-calculator/leakoff-coefficient-calculator-module.state';
import { getValidatedLeakoffCoefficientCalculatorModuleState } from '../../../../+store/calculators/leakoff-coefficient-calculator/leakoff-coefficient-calculator.selectors';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { changeProp, ErrorHelper, ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';
import { LeftNavHelperService } from '../../../../shared/components/left-nav/left-nav-helper.service';
import { StoreCrudPropsFactory } from '@dunefront/common/common/common-store-crud.interfaces';

@Component({
  selector: 'app-leakoff-coefficient-calculator',
  templateUrl: './leakoff-coefficient-calculator.component.html',
  styleUrls: ['./leakoff-coefficient-calculator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LeakoffCoefficientCalculatorComponent extends DbDependentComponent {
  public leakoffCoefficientCalculator!: ValidatedLeakoffCoefficientCalculatorModuleState;

  public UnitType = UnitSystem;

  constructor(
    store: Store,
    cdRef: ChangeDetectorRef,
    private modalService: ModalService,
    public runCalculationService: LeftNavHelperService,
  ) {
    super(store, cdRef);
    this.subscription.add(
      notEmpty(store.select(getValidatedLeakoffCoefficientCalculatorModuleState)).subscribe((state) => {
        this.leakoffCoefficientCalculator = state;
        cdRef.markForCheck();
      }),
    );
    this.subscription.add(runCalculationService.calculationSubject.subscribe(() => this.calculate()));
    this.onHelpChange('calculators', 'leakoff-coefficient-calculator');
  }

  public submitCalcData(props: ObjectChangeProp<ValidatedLeakoffCoefficientCalculatorModuleState>): void {
    if (this.leakoffCoefficientCalculator[props.key] !== props.value) {
      this.store.dispatch(
        updateLeakoffCoefficientCalculatorRow(
          StoreCrudPropsFactory.updateRow(
            changeProp(this.leakoffCoefficientCalculator, props),
            props as unknown as ObjectChangeProp<LeakoffCoefficientCalculatorModuleState>,
          ),
        ),
      );
    }
  }

  public async calculate(): Promise<void> {
    if (!this.leakoffCoefficientCalculator.isValid) {
      await this.modalService.showAlert(ErrorHelper.ERROR_CURRENT_SCREENS_MESSAGE_HEADER, 'Warning');
      return;
    }
    this.store.dispatch(calculateLeakoffCoefficient({ ScenarioId: this.leakoffCoefficientCalculator.ScenarioId }));
  }
}
