import { DensityConverter } from '../../../unit-converters/converters/density/density.converter';
import { ConvertUnitPipe } from '../../units/convert-unit.pipe/convert-unit.pipe';
import { DictionaryWithArray, IDictionaryWithArray, noErrors } from '../../../common/state.helpers';
import { DensityUnit, UnitSystem } from '../../../dto/unit-system.dto';
import { Gravel } from './gravel';
import { GravelModuleState, IGravelValidationDependencies, ValidatedGravelModuleState } from '../gravel-module.state';
import { IError } from '../../../common/common-state.interfaces';
import { Fluid } from '../../fluid/model/fluid';
import { GravelConstants } from '../dto/gravel.dto';
import { ModuleType } from '../../scenario/scenario.dto';
import { MAX_ANGLE_OF_REPOSE } from '../../../common/constants';

export class GravelValidation {
  public static validate(state: GravelModuleState, deps: IGravelValidationDependencies): ValidatedGravelModuleState {
    const validatedGravels = this.ValidateGravels(state.Gravels, deps);
    const validGravelIds: number[] = [];

    validatedGravels.ids.forEach((gravelId) => {
      const gravel = DictionaryWithArray.get(validatedGravels, gravelId);
      if (!gravel) {
        return;
      }
      if (gravel.isValid) {
        validGravelIds.push(gravel.Id);
      }
    });

    return {
      ...state,
      Gravels: validatedGravels,
      isValid: DictionaryWithArray.isValid(validatedGravels),
      validGravelIds,
      error: {},
    };
  }

  private static ValidateGravels(
    gravelsDict: IDictionaryWithArray<Gravel>,
    { currentModuleType }: IGravelValidationDependencies,
  ): IDictionaryWithArray<Gravel> {
    let newGravels = { ...gravelsDict };
    gravelsDict.ids.forEach((gravelId) => {
      const gravel = DictionaryWithArray.getCopy(gravelsDict, gravelId);
      if (!gravel) {
        return;
      }

      const ignoreAdvancedProps = currentModuleType === ModuleType.Simulate_Disp;

      const error: IError<Gravel> = {
        Name: this.validateName(gravel.Name, gravelsDict),
        MeanDiameter: this.ValidateMeanDiameter(gravel.MeanDiameter),
        AbsoluteDensity: this.ValidateDensity(gravel.AbsoluteDensity, gravel.BulkDensity),
        BulkDensity: this.ValidateBulkDensity(gravel.AbsoluteDensity, gravel.BulkDensity),
        PackPermeability: this.ValidatePackPermeability(gravel.PackPermeability),
        OroskarAndTurianCoefficient: ignoreAdvancedProps ? '' : this.ValidateOroskarAndTurianCoefficient(gravel.OroskarAndTurianCoefficient),
        AngleOfRepose: ignoreAdvancedProps ? '' : this.ValidateAngleOfRepose(gravel.AngleOfRepose),
        ThermalConductivity: ignoreAdvancedProps ? '' : this.ValidateThermalConductivity(gravel.ThermalConductivity),
        SpecificHeatCapacity: ignoreAdvancedProps ? '' : this.ValidateSpecificHeatCapacity(gravel.SpecificHeatCapacity),
        FrictionMultiplier: ignoreAdvancedProps ? '' : this.ValidateGravelFriMul(gravel),
        FrictionMultiplierDeviation: ignoreAdvancedProps ? '' : this.validateGravelFrictionDeviation(gravel),
      };
      gravel.error = error;
      gravel.isValid = noErrors(error);
      newGravels = DictionaryWithArray.upsertById(newGravels, gravel, gravelId);
    });
    return newGravels;
  }

  // region gravel

  private static validateName(name: string, gravels: IDictionaryWithArray<Gravel>): string {
    if (name.length === 0) {
      return "Gravel name can't be empty";
    }
    if (DictionaryWithArray.getArray(gravels).filter((gravel) => gravel.Name === name).length > 1) {
      return 'The name already exists - please use a different one';
    }
    return '';
  }

  private static ValidateMeanDiameter(diameter: number): string {
    if (diameter <= 0) {
      return 'Mean diameter must be greater than zero';
    }
    return '';
  }

  private static ValidateDensity(density: number, bulkDensity: number): string {
    const maxDensity = DensityConverter.toSi(10, DensityUnit.Specific_Gravity);

    if (density <= 0) {
      return 'Density must be greater than zero';
    } else if (density > maxDensity) {
      return 'Density must be less than ' + ConvertUnitPipe.encode(UnitSystem.Solid_Density, maxDensity);
    } else if (bulkDensity >= density) {
      return 'Density must be greater than bulk density';
    }

    return '';
  }

  private static ValidateBulkDensity(density: number, bulkDensity: number): string {
    const maxDensity = DensityConverter.toSi(10, DensityUnit.Specific_Gravity);

    if (bulkDensity <= 0) {
      return 'Bulk density must be greater than zero';
    } else if (bulkDensity > maxDensity) {
      return 'Bulk density must be less than ' + ConvertUnitPipe.encode(UnitSystem.Bulk_Density, maxDensity);
    } else if (bulkDensity >= density) {
      return 'Bulk density must be less than density';
    }

    return '';
  }

  private static ValidatePackPermeability(pp: number): string {
    if (pp <= 0) {
      return 'Pack permeability must be greater than zero';
    }
    return '';
  }

  private static ValidateOroskarAndTurianCoefficient(otc: number): string {
    if (otc <= 0) {
      return 'Oroskar and Turian coefficient must be greater than 0';
    }
    if (otc > 30) {
      return 'Oroskar and Turian coefficient must be less than or equal to 30';
    }
    return '';
  }

  private static ValidateAngleOfRepose(aor: number): string {
    if (aor <= 0) {
      return 'Angle of repose must be greater than 0';
    }
    if (aor > MAX_ANGLE_OF_REPOSE) {
      const encodeValue = ConvertUnitPipe.encode(UnitSystem.Angle, MAX_ANGLE_OF_REPOSE);
      return `Angle of repose must be less than or equal to ${encodeValue}`;
    }
    return '';
  }

  private static ValidateThermalConductivity(tc: number): string {
    if (tc <= 0) {
      return 'Thermal conductivity must be greater than zero';
    }

    return '';
  }

  private static ValidateSpecificHeatCapacity(shc: number): string {
    if (shc <= 0) {
      return 'Specific heat capacity must be greater than zero';
    }
    return '';
  }

  private static ValidateGravelFriMul(gravel: Gravel): string {
    if (gravel.FrictionMultiplier <= 0) {
      return 'Gravel friction multiplier must be greater than 0';
    }
    return '';
  }

  private static validateGravelFrictionDeviation(gravel: Gravel): string {
    if (gravel.IsFrictionMultiplierByDeviation) {
      if (gravel.FrictionMultiplierDeviation <= 0) {
        return 'Deviation must be greater than 0';
      }
      if (gravel.FrictionMultiplierDeviation > 90) {
        const encodeValue = ConvertUnitPipe.encode(UnitSystem.Angle, 90);
        return `Deviation must be less than or equal to ${encodeValue}`;
      }
    }
    return '';
  }

  // endregion
}

export const ValidateGravel = (gravels: IDictionaryWithArray<Gravel>, gravelId: number): string => {
  const gravel = DictionaryWithArray.get(gravels, gravelId);
  if (gravel && !gravel.isValid) {
    return 'Selected Gravel is not valid';
  }
  return '';
};

export const ValidateGravelDensity = (
  gravels: IDictionaryWithArray<Gravel>,
  gravelId: number,
  fluids: IDictionaryWithArray<Fluid>,
  fluidId: number | null,
): string => {
  const gravel = DictionaryWithArray.get(gravels, gravelId);
  const fluid = DictionaryWithArray.get(fluids, fluidId);
  if (fluid && gravel && gravel.Id > GravelConstants.NoGravel) {
    if (gravel.AbsoluteDensity <= fluid.CleanFluidDensity) {
      return 'Gravel density must be greater than fluid density';
    }
  }
  return '';
};
