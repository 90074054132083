import { LineChartDataSet } from '@dunefront/common/modules/reporting/dto/chart-data.dto';
import { Chart, CoreScaleOptions, Point, Scale, ScatterDataPoint } from 'chart.js';
import { CrosshairDataset, CrosshairLineStyle, CrosshairPluginOptions, CrosshairState } from './types';
import { isScatterSeries } from '../chart-component-helpers/chart-misc-helpers';
import { CrosshairPluginId, defaultCrosshairLineColor, scatterPointSnapThreshold } from './constants';
import { GeneralCalculations } from '@dunefront/common/common/general.calculations';
import { CrosshairMode, TooltipPosition } from '@dunefront/common/modules/reporting/reporting.settings';

const defaultLineStyle: CrosshairLineStyle = {
  color: defaultCrosshairLineColor,
  width: 1,
  dashPattern: [],
};

const defaultOptions: CrosshairPluginOptions = {
  enabled: true,
  isChartRotated: false,
  lineStyle: defaultLineStyle,
  crosshairContext: {
    crosshairMode: CrosshairMode.MULTIPLE,
    tooltipPosition: TooltipPosition.DEFAULT,
    sortedAndTrimmedDataSets: [],
  },
};

const initialState: CrosshairState = {
  argumentPx: undefined,
  handlers: {},
};

export const rotatePoint = (point: Point): Point => ({ x: point.y, y: point.x });
const getArg = (point: ScatterDataPoint, isRotated: boolean): number => (isRotated ? point.y : point.x);
const getVal = (point: ScatterDataPoint, isRotated: boolean): number => (isRotated ? point.x : point.y);

const getIsArgsOrderReversed = (points: (ScatterDataPoint | null)[], isRotated: boolean): boolean => {
  const firstPoint = points[0];
  const lastPoint = points[points.length - 1];

  if (firstPoint == null || lastPoint == null) {
    return false;
  }

  return getArg(firstPoint, isRotated) > getArg(lastPoint, isRotated);
};

const getSurroundingPoints = (
  argumentValue: number,
  points: (ScatterDataPoint | null)[],
  isRotated: boolean,
): [ScatterDataPoint, ScatterDataPoint] | null => {
  const isArgsOrderReversed = getIsArgsOrderReversed(points, isRotated);
  const index = points.findIndex((point) => {
    if (point == null) {
      return false;
    }

    return isArgsOrderReversed ? getArg(point, isRotated) <= argumentValue : getArg(point, isRotated) >= argumentValue;
  });

  const prev = points[index - 1] as ScatterDataPoint;
  const next = points[index] as ScatterDataPoint;
  if (prev == null || next == null) {
    return null;
  }

  return [prev, next];
};

export const getInterpolatedValue = (
  argumentPx: number,
  dataset: LineChartDataSet,
  chart: Chart,
  isRotated: boolean,
): number | undefined => {
  if (dataset.hidden || (dataset as any).interpolate == null) {
    return undefined;
  }

  const argumentAxisId = isRotated ? dataset.yAxisID : dataset.xAxisID;
  const valueAxisId = isRotated ? dataset.xAxisID : dataset.yAxisID;
  if (argumentAxisId == null || valueAxisId == null) {
    return undefined;
  }

  const argumentScale = chart.scales[argumentAxisId];
  if (argumentScale == null) {
    return undefined;
  }

  const argumentOnScale = argumentScale.getValueForPixel(argumentPx);
  if (argumentOnScale == null) {
    return undefined;
  }

  const data = dataset.data as (ScatterDataPoint | null)[];

  const points = getSurroundingPoints(argumentOnScale, data, isRotated);
  if (points == null) {
    return undefined;
  }

  const [prev, next] = points;

  const valueScale = chart.scales[valueAxisId];
  if (valueScale == null) {
    return undefined;
  }

  if (dataset.stepped !== false && prev != null) {
    return valueScale.getPixelForValue(getVal(prev, isRotated));
  } else if (prev != null && next != null) {
    const isArgumentScaleLog = argumentScale.type === 'logarithmic';
    let prevArg = getArg(prev, isRotated);
    let nextArg = getArg(next, isRotated);
    // on logarithmic scale getPixelForValue(0) returns equivalent of getPixelForValue(scale.min), see:
    // https://github.com/chartjs/Chart.js/blob/51441272a781ba575149b214933f0c5b4bafb6ab/src/scales/scale.logarithmic.js#L211
    // in our case this leads to LinearInterpolation crash, in order to get around this we are going to pass Number.EPSILON instead of 0
    if (isArgumentScaleLog && prevArg === 0) {
      prevArg = Number.EPSILON;
    }
    if (isArgumentScaleLog && nextArg === 0) {
      nextArg = Number.EPSILON;
    }
    const prevArgPx = argumentScale.getPixelForValue(prevArg);
    const nextArgPX = argumentScale.getPixelForValue(nextArg);

    const isValueScaleLog = valueScale.type === 'logarithmic';
    let prevValue = getVal(prev, isRotated);
    let nextValue = getVal(next, isRotated);
    // see comment above
    if (isValueScaleLog && prevValue === 0) {
      prevValue = Number.EPSILON;
    }
    if (isValueScaleLog && nextValue === 0) {
      nextValue = Number.EPSILON;
    }
    const prevValuePx = valueScale.getPixelForValue(prevValue);
    const nextValuePX = valueScale.getPixelForValue(nextValue);

    try {
      return GeneralCalculations.LinearInterpolation(argumentPx, prevArgPx, nextArgPX, prevValuePx, nextValuePX);
    } catch (e) {
      console.error(e);
    }
  } else if (next != null) {
    // just next point available (argX at first point)
    return valueScale.getPixelForValue(getVal(next, isRotated));
  }

  return undefined;
};

export type ScatterPointsToSnap = { point: ScatterDataPoint; datasetIndex: number; argPx: number }[];

export const getScatterPointsToSnap = (argumentPx: number | undefined, chart: Chart, isRotated: boolean): ScatterPointsToSnap => {
  if (argumentPx == null) {
    return [];
  }

  let result: ScatterPointsToSnap = [];
  let closestPointDistance = Number.MAX_VALUE;

  for (let datasetIndex = 0; datasetIndex < chart.data.datasets.length; datasetIndex++) {
    if (!datasetVisibleAndInterpolated(chart, datasetIndex)) {
      continue;
    }

    const dataset = chart.data.datasets[datasetIndex] as LineChartDataSet & CrosshairDataset;

    if (!isScatterSeries(dataset)) {
      continue;
    }

    const argumentAxisId = isRotated ? dataset.yAxisID : dataset.xAxisID;
    const valueAxisId = isRotated ? dataset.xAxisID : dataset.yAxisID;
    if (argumentAxisId == null || valueAxisId == null) {
      continue;
    }

    const argumentScale = chart.scales[argumentAxisId];
    const argumentOnScale = argumentScale.getValueForPixel(argumentPx);
    if (argumentOnScale == null) {
      continue;
    }

    const points = dataset.data as (ScatterDataPoint | null)[];
    const prevNextPoints = getSurroundingPoints(argumentOnScale, points, isRotated);
    if (prevNextPoints == null) {
      return [];
    }

    for (const point of prevNextPoints) {
      if (point == null) {
        continue;
      }

      const prevArgPx = argumentScale.getPixelForValue(getArg(point, isRotated));
      const distance = Math.abs(prevArgPx - argumentPx);

      const isNewClosestPoint = distance <= scatterPointSnapThreshold && distance < closestPointDistance;
      const isSameAsPrevClosestPoint = result[0]?.argPx === prevArgPx;

      // reset results
      if (isNewClosestPoint) {
        closestPointDistance = distance;
        result = [];
      }

      // add point to results
      if (isNewClosestPoint || isSameAsPrevClosestPoint) {
        result.push({ point: point, argPx: prevArgPx, datasetIndex });
      }
    }
  }

  return result;
};

export const getXScale = (chart: Chart): Scale<CoreScaleOptions> | undefined => {
  const dataset = (chart.data.datasets as LineChartDataSet[]).find((dataset) => dataset.xAxisID != null);

  if (dataset?.xAxisID == null) {
    return undefined;
  }

  return chart.scales[dataset?.xAxisID];
};

export const getYScale = (chart: Chart): Scale<CoreScaleOptions> | undefined => {
  const dataset = (chart.data.datasets as LineChartDataSet[]).find((dataset) => dataset.yAxisID != null);

  if (dataset?.yAxisID == null) {
    return undefined;
  }

  return chart.scales[dataset?.yAxisID];
};

export const datasetVisibleAndInterpolated = (chart: Chart, datasetIndex: number): boolean => {
  const dataset = chart.data.datasets[datasetIndex] as LineChartDataSet & CrosshairDataset;

  // make sure dataset exists
  if (dataset == null) {
    return false;
  }

  // check dataset is visible
  if (!chart.isDatasetVisible(datasetIndex)) {
    return false;
  }

  // check for interpolate setting
  if (dataset.interpolate !== true) {
    return false;
  }

  return true;
};

export const getCrosshairPluginOptions = (chart: Chart): CrosshairPluginOptions => {
  let options: CrosshairPluginOptions | undefined = (chart.options.plugins as any)[CrosshairPluginId];

  if (options == null) {
    options = defaultOptions;
    (chart.options.plugins as any)[CrosshairPluginId] = options;
  }

  return options;
};

export const updateCrosshairOptions = (chart: Chart, isChartRotated: boolean): void => {
  const options: CrosshairPluginOptions | undefined = (chart.options.plugins as any)[CrosshairPluginId];

  if (options == null) {
    return;
  }

  if (options.isChartRotated !== isChartRotated) {
    (chart.options.plugins as any)[CrosshairPluginId] = { ...options, isChartRotated };
  }
};

export const getCrosshairState = (chart: Chart): CrosshairState => {
  let state: CrosshairState | undefined = (chart as any)[CrosshairPluginId];

  if (state == null) {
    state = initialState;
    setCrosshairState(chart, state);
  }

  return state;
};

export const setCrosshairState = (chart: Chart, state: CrosshairState): void => {
  (chart as any)[CrosshairPluginId] = state;
};

export const setCrosshairInitialState = (chart: Chart): void => {
  setCrosshairState(chart, { ...initialState });
};
