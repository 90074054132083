import { ModuleWithProviders, NgModule } from '@angular/core';
import { ElectronAuthService } from './electron.auth.service';
import { ClientAuthService } from './client-auth.service';
import { AuthGuard, AuthService } from '@auth0/auth0-angular';
import { ElectronAuthGuard } from './guards/electron-auth.guard';
import { AdminAuthGuard } from './guards/admin-auth.guard';

@NgModule()
export class ElectronAuthModule {
  public static forRoot(): ModuleWithProviders<ElectronAuthModule> {
    return {
      ngModule: ElectronAuthModule,
      providers: [
        { provide: ClientAuthService, useClass: ElectronAuthService },
        { provide: AuthGuard, useClass: ElectronAuthGuard },
        { provide: AdminAuthGuard, useClass: ElectronAuthGuard },
        { provide: AuthService, useClass: ElectronAuthService },
      ],
    };
  }
}
