import { Actions, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { BackendConnectionService } from '../shared/backend-connection/backend-connection.service';
import * as backendActions from './backend-connection/backend-connection.actions';
import { Observable, of, Subscription } from 'rxjs';
import { WsAction } from '@dunefront/common/ws.action';
import { ModalService } from '../common-modules/modals/modal.service';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { Store, Action } from '@ngrx/store';
import { selectUrl } from './router/router.selectors';
import { CrudResponse, UpsertCrudResponse } from '@dunefront/common/modules/common.actions';
import { dataFailed, deleteRowsSuccess, insertRowsSuccess, updateRowSuccess } from './app.actions';
import { IErrorState } from '../shared/components/error/client-error-handler.service';
import { IActivityOverlayConfig, ModalSize } from '@dunefront/common/modules/ui/ui.interfaces';
import { getFileHash } from './backend-connection/backend-connection.selectors';
import { undoRedoClearHistoryAction } from './undo-redo/undo-redo.action';
import { WsActionResponse } from '@dunefront/common/response-ws.action';

type dataFailedType = IErrorState & Action<'[app] dataFailed'>;
type insertRowsSuccessType = CrudResponse & Action<'[app] insertRowsSuccess'>;
type updateRowSuccessType = CrudResponse & Action<'[app] updateRowSuccess'>;
type deleteRowsSuccessType = CrudResponse & Action<'[app] deleteRowsSuccess'>;

export type EmitInsertResult = Observable<dataFailedType | insertRowsSuccessType>;
export type EmitUpdateResult = Observable<dataFailedType | updateRowSuccessType>;
export type EmitDeleteResult = Observable<dataFailedType | deleteRowsSuccessType>;
export type EmitUpsertResult = Observable<dataFailedType | insertRowsSuccessType | updateRowSuccessType>;

export abstract class BaseWsEffects {
  protected subscription: Subscription | undefined;

  private currentUrl: string | undefined;

  protected constructor(
    protected actions$: Actions,
    protected wsService: BackendConnectionService,
    protected moduleName: string,
    private isWsListening: boolean,
    protected isDbDependent: boolean,
    protected modalService: ModalService,
    protected store: Store,
  ) {
    this.store.select(selectUrl).subscribe((currentUrl) => (this.currentUrl = currentUrl));
    if (isWsListening) {
      const actionType = isDbDependent ? backendActions.dbConnectAction : backendActions.backendConnectedAction;
      this.actions$.pipe(ofType(actionType)).subscribe(() => {
        if (this.subscription) {
          this.subscription.unsubscribe();
        }

        this.subscription = this.wsService
          .on(this.moduleName, this.isDbDependent)
          .pipe(concatLatestFrom(() => this.store.select(getFileHash)))
          .subscribe(([action, fileHash]) => this.onIncomingMessage(action, fileHash));
      });

      if (isDbDependent) {
        this.actions$
          .pipe(ofType(backendActions.dbDisconnectSuccessAction, backendActions.dbConnectionFailedAction))
          .subscribe(() => this.subscription?.unsubscribe());
      } else {
        this.actions$
          .pipe(ofType(backendActions.backendDisconnectedAction, backendActions.backendConnectFailedAction))
          .subscribe(() => this.subscription?.unsubscribe());
      }
    }
  }

  public emit<T>(
    action: WsAction,
    activityTitle?: string,
    message?: string,
    forceShowActivityOverlay = false,
    minDisplayTimeMs = 300,
  ): Observable<WsActionResponse<T>> {
    action.url = this.currentUrl ?? '';
    return this.emitToModule(
      this.moduleName,
      this.isDbDependent,
      action,
      activityTitle,
      message,
      forceShowActivityOverlay,
      'md',
      minDisplayTimeMs,
    );
  }

  public emitInsert(action: WsAction, activityTitle?: string, message?: string, forceShowActivityOverlay = false): EmitInsertResult {
    return this.emit<CrudResponse>(action, activityTitle, message, forceShowActivityOverlay).pipe(
      map((result: WsActionResponse<CrudResponse>) => insertRowsSuccess(result.payload)),
      catchError((err) => of(dataFailed(err))),
    );
  }

  public emitUpdate(action: WsAction, activityTitle?: string, message?: string, forceShowActivityOverlay = false): EmitUpdateResult {
    return this.emit<CrudResponse>(action, activityTitle, message, forceShowActivityOverlay).pipe(
      map((result: WsActionResponse<CrudResponse>) => updateRowSuccess(result.payload)),
      catchError((err) => of(dataFailed(err))),
    );
  }

  public emitUpsert(action: WsAction, activityTitle?: string, message?: string, forceShowActivityOverlay = false): EmitUpsertResult {
    return this.emit<UpsertCrudResponse>(action, activityTitle, message, forceShowActivityOverlay).pipe(
      map((result: WsActionResponse<UpsertCrudResponse>) =>
        result.payload.performedDbOperation === 'insert' ? insertRowsSuccess(result.payload) : updateRowSuccess(result.payload),
      ),
      catchError((err) => of(dataFailed(err))),
    );
  }

  public emitDelete(
    action: WsAction,
    activityTitle?: string,
    message?: string,
    forceShowActivityOverlay = false,
    clearUndoHistory = false,
  ): EmitDeleteResult {
    return this.emit<CrudResponse>(action, activityTitle, message, forceShowActivityOverlay).pipe(
      mergeMap((result: WsActionResponse<CrudResponse>) => [deleteRowsSuccess(result.payload)]),
      catchError((err) => of(dataFailed(err))),
      tap(() => {
        if (clearUndoHistory) {
          this.store.dispatch(undoRedoClearHistoryAction());
        }
      }),
    );
  }

  public emitToModule<T>(
    moduleName: string,
    isDbDependent: boolean,
    action: WsAction,
    activityTitle?: string,
    message?: string,
    forceShowActivityOverlay = false,
    modalWidth: ModalSize | undefined = 'md',
    minDisplayTimeMs = 300,
  ): Observable<WsActionResponse<T>> {
    let activityOverlayConfig: IActivityOverlayConfig | undefined;

    if (activityTitle) {
      activityOverlayConfig = {
        taskId: action.taskId,
        message: message ?? '',
        title: activityTitle,
        displayDelayMs: forceShowActivityOverlay ? 0 : 150,
        minDisplayTimeMs,
        width: modalWidth,
      };

      action.activityOverlayConfig = activityOverlayConfig;
    }
    return this.wsService.emit<T>(moduleName, isDbDependent, action);
  }

  protected onIncomingMessage(action: WsAction, fileHash: string | undefined): void {
    // this is hook to override
  }
}
