import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { deleteFileOrFolder, loadAllFilesAndFoldersSuccess } from '../../../../+store/file-manager/file-manager.actions';
import { BaseFileActionDialogComponent } from '../base-file-action.dialog.component';
import { Store } from '@ngrx/store';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Actions, ofType } from '@ngrx/effects';
import {
  CY_BUTTON_OK,
  defaultModalCancelButton,
  defaultModalConfirmButton,
  ModalButtonConfig,
} from '../../../../common-modules/modals/generic-modal/generic-modal.component';

@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete.dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeleteDialogComponent extends BaseFileActionDialogComponent {
  public modalTitle = 'Delete';
  public modalButtonsConfigs: ModalButtonConfig[] = [
    defaultModalCancelButton((): void => this.cancelClicked(), 'Cancel', 'button-cancel'),
    defaultModalConfirmButton((): void => this.okClicked(), 'OK', CY_BUTTON_OK),
  ];
  private readonly isAdmin = false;

  constructor(activeModal: DynamicDialogRef, config: DynamicDialogConfig, store: Store, cdRef: ChangeDetectorRef, actions$: Actions) {
    super(activeModal, config, store, cdRef);
    this.file = config.data.file;
    this.isAdmin = config.data.isAdmin;

    this.subscription.add(actions$.pipe(ofType(loadAllFilesAndFoldersSuccess)).subscribe((_) => this.activeModal.close()));
  }

  public okClicked(): void {
    this.store.dispatch(deleteFileOrFolder({ target: this.file, isAdminPanel: this.isAdmin }));
  }
}
