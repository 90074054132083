import { Injectable } from '@angular/core';
import { BaseWsEffects } from '../../base-ws.effects';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { BackendConnectionService } from '../../../shared/backend-connection/backend-connection.service';
import { mergeMap } from 'rxjs/operators';
import { calculateResuspension, updateResuspensionCalculatorRow } from './resuspension-calculator.actions';
import {
  CalculateResuspensionAction,
  ResuspensionCalculatorModuleName,
  UpdateResuspensionCalculatorAction,
} from '@dunefront/common/modules/calculators/resuspension-calculator/resuspension-calculator.action';
import { ModalService } from '../../../common-modules/modals/modal.service';
import { getValidatedDeveloperSettings } from '../../settings/validated-settings.selectors';

@Injectable()
export class ResuspensionCalculatorEffects extends BaseWsEffects {
  constructor(actions$: Actions, store: Store, wsService: BackendConnectionService, modalService: ModalService) {
    super(actions$, wsService, ResuspensionCalculatorModuleName, false, true, modalService, store);
  }

  public updateResuspensionCalculator$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateResuspensionCalculatorRow),
      mergeMap((action) => this.emitUpdate(new UpdateResuspensionCalculatorAction(action))),
    ),
  );

  public calculateResuspension$ = createEffect(() =>
    this.actions$.pipe(
      ofType(calculateResuspension),
      concatLatestFrom(() => this.store.select(getValidatedDeveloperSettings)),
      mergeMap(([action, developerSettings]) =>
        this.emitUpdate(new CalculateResuspensionAction(action.ScenarioId, developerSettings), 'Calculating Resuspension...'),
      ),
    ),
  );
}
