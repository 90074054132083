<ng-container *ngIf="pumpingState">
  <div class="grid-form-container">
    <label>Define by:</label>
    <app-radio-group
      [source]="pumpingState.pumping"
      [key]="'IsWellFluidDepthByVolume'"
      (valueChanged)="switchDetailedFluidDefinitionType($event)"
      [disabled]="pumpingState.pumping.IsWellFluidSelectionSimple"
      [items]="items"
      dataCy="detailed-fluid-definition-radio"
      id="detailed-fluid-definition-radio"
      [value]="isWellFluidDepthByVolume"
    >
    </app-radio-group>
  </div>
  <div class="grid-panel">
    <app-detailed-fluid-definition-grid
      #gridComponent
      [wellFluids]="pumpingState.wellFluids"
      [isWellFluidDepthByVolume]="pumpingState.pumping.IsWellFluidDepthByVolume"
      [disabled]="pumpingState.pumping.IsWellFluidSelectionSimple"
      (selectedRowsChanged)="onSelectedRowsChanged($event)"
    >
    </app-detailed-fluid-definition-grid>
  </div>
  <div class="buttons-panel">
    <div class="align-left">
      <app-well-fluid-volume
        [pumpingState]="pumpingState"
        [focusedWellFluidRow]="selectedRow"
        [referenceVariablesData]="referenceVariablesData"
      >
      </app-well-fluid-volume>
    </div>
    <div class="align-right">
      <button
        appButton
        type="button"
        data-cy="detailed-fluid-definition-import"
        class="btn btn-primary btn-sm"
        (buttonClick)="onImport(true)"
        [disabled]="pumpingState.pumping.IsWellFluidSelectionSimple"
        [isDeleteResultsEnabled]="false"
      >
        Import
      </button>
      <button
        appButton
        type="button"
        data-cy="detailed-fluid-definition-add"
        class="btn btn-primary btn-sm"
        (buttonClick)="onAdd()"
        [disabled]="pumpingState.pumping.IsWellFluidSelectionSimple"
        [isDeleteResultsEnabled]="false"
      >
        Add
      </button>
      <button
        appButton
        type="button"
        data-cy="detailed-fluid-definition-insert"
        class="btn btn-primary btn-sm"
        (buttonClick)="onInsert()"
        [disabled]="pumpingState.pumping.IsWellFluidSelectionSimple"
        [isDeleteResultsEnabled]="false"
      >
        Insert
      </button>
      <button
        appButton
        type="button"
        data-cy="detailed-fluid-definition-delete"
        class="btn btn-primary btn-sm"
        (buttonClick)="onDelete()"
        [disabled]="pumpingState.pumping.IsWellFluidSelectionSimple"
        [isDeleteResultsEnabled]="false"
      >
        Delete
      </button>
    </div>
  </div>
</ng-container>
