import { DictionaryWithArray, IDictionaryWithArray, noErrors } from '../../../../common/state.helpers';
import { getRowsForCalculations, ITableRow, ITableState } from '../../../../common/common-grid.interfaces';
import { RheometerReading } from './rheometer-reading';
import { RheometerTestType } from '../../dto/rheometer.dto';
import { FluidModuleState } from '../../fluid-module.state';
import { IError } from '../../../../common/common-state.interfaces';

export class RheometerReadingValidation {
  public static ValidateRheometerReadings(fluidState: FluidModuleState): IDictionaryWithArray<ITableState<RheometerReading>> {
    const rheometers = fluidState.Rheometers;

    let newRheometerReadingDict: IDictionaryWithArray<ITableState<RheometerReading>> = { ...fluidState.RheometerReadings };
    rheometers.ids.forEach((fluidId) => {
      const fluid = DictionaryWithArray.get(fluidState.Fluids, +fluidId);
      const fluidRheometers = DictionaryWithArray.get(rheometers, +fluidId);

      if (!fluidRheometers || !fluid) {
        return;
      }

      getRowsForCalculations(fluidRheometers.rows).forEach((rheometer) => {
        const rheometerReadingTableState = DictionaryWithArray.get(newRheometerReadingDict, +rheometer.Id);
        if (!rheometerReadingTableState) {
          return;
        }
        const rows = rheometerReadingTableState.rows;

        const rheometerTestType = fluid.RheometerTestType;

        const rheometerReadingsForCalc = getRowsForCalculations(rows);
        let isValid = true;
        const newRows = rows.map((row) => {
          if (row.rowType !== 'data') {
            return row;
          }
          const newRow = this.ValidateRheometerReadingRow(row, rheometerReadingsForCalc, rheometerTestType);
          isValid = isValid && newRow.isValid;
          return newRow;
        });

        const newRheometerReadingsTableState: ITableState<RheometerReading> = {
          ...rheometerReadingTableState,
          rows: newRows,
          isValid,
        };
        newRheometerReadingDict = DictionaryWithArray.upsertById(newRheometerReadingDict, newRheometerReadingsTableState, rheometer.Id);
      });
    });

    return newRheometerReadingDict;
  }

  private static ValidateRheometerReadingRow(
    row: ITableRow<RheometerReading>,
    rheometerReadings: RheometerReading[],
    rheometerTestType: RheometerTestType,
  ): ITableRow<RheometerReading> {
    let error: IError<RheometerReading>;
    if (rheometerTestType === RheometerTestType.Shear_Rate_Viscosity) {
      error = {
        ShearRate: this.ValidateRheometerShearRate(row.rowData.ShearRate, rheometerReadings),
        Viscosity: this.ValidateRheometerViscosity(row.rowData.Viscosity),
      };
      return { ...row, error, isValid: noErrors(error) };
    }
    error = {
      RPM: this.ValidateRheometerRPM(row.rowData.RPM, rheometerReadings),
      DialReading: this.ValidateRheometerDialReading(row.rowData.DialReading),
    };
    return { ...row, error, isValid: noErrors(error) };
  }

  private static ValidateRheometerRPM(RPM: number, rheometerReadings: RheometerReading[]): string {
    if (RPM <= 0 || RPM > 2000) {
      return 'RPM must be between 0 and 2000';
    }
    if (rheometerReadings.filter((reading) => reading.RPM === RPM).length > 1) {
      return 'RPM value must not be duplicated';
    }
    return '';
  }

  private static ValidateRheometerDialReading(dialReading: number): string {
    if (dialReading <= 0) {
      return 'Dial Reading must be greater than 0';
    }
    return '';
  }

  private static ValidateRheometerShearRate(shearRate: number, rheometerReadings: RheometerReading[]): string {
    if (shearRate <= 0) {
      return 'Shear rate must be greater than 0';
    }
    if (rheometerReadings.filter((reading) => reading.ShearRate === shearRate).length > 1) {
      return 'Shear rate value must not be duplicated';
    }
    return '';
  }

  private static ValidateRheometerViscosity(viscosity: number): string {
    if (viscosity <= 0) {
      return 'Viscosity must be greater than 0';
    }
    return '';
  }
}
