import { DocumentGeneratorHelper } from '../document-generator.helper';
import { HeadingLevel, Paragraph, Table } from 'docx';
import { IUnitSystemDto } from '@dunefront/common/dto/unit-system.dto';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';
import { getValidatedSettingsState } from '../../../../../../+store/settings/validated-settings.selectors';
import { ValidatedSettings } from '@dunefront/common/modules/settings/dto/settingsDto';
import {
  settingsFrictionProperties,
  settingsGeneralProperties,
  settingsPackingProperties,
  settingsPerformanceProperties,
  settingsThermalProperties,
} from '../../common/grid-configs/settings';
import { DocumentTableGeneratorHelper } from '../document-table-generator.helper';
import { ISelectItem } from '@dunefront/common/common/select.helpers';
import { EnumHelpers } from '@dunefront/common/utils/enum.helpers';

export class SettingsReportGeneratorHelper {
  public static async createSettingsSection(store: Store, currentUnitSystem: IUnitSystemDto): Promise<Array<Paragraph | Table>> {
    const settings: ValidatedSettings = (await firstValueFrom(store.select(getValidatedSettingsState))).settings;
    return [
      DocumentGeneratorHelper.createParagraphHeader(`Settings`, HeadingLevel.HEADING_1, 0),

      DocumentGeneratorHelper.createParagraphHeader(`General`, HeadingLevel.HEADING_2, 1),
      this.createGeneralOptions(settings, currentUnitSystem),

      DocumentGeneratorHelper.createParagraphHeader(`Performance`, HeadingLevel.HEADING_2, 1),
      this.createPerformanceOptions(settings, currentUnitSystem),

      DocumentGeneratorHelper.createParagraphHeader(`Friction`, HeadingLevel.HEADING_2, 1),
      this.createFrictionOptions(settings, currentUnitSystem),

      DocumentGeneratorHelper.createParagraphHeader(`Packing`, HeadingLevel.HEADING_2, 1),
      this.createPackingOptions(settings, currentUnitSystem),

      DocumentGeneratorHelper.createParagraphHeader(`Thermal`, HeadingLevel.HEADING_2, 1),
      this.createThermalOptions(settings, currentUnitSystem),
    ];
  }

  private static createGeneralOptions(settings: ValidatedSettings, currentUnitSystem: IUnitSystemDto): Table {
    return DocumentTableGeneratorHelper.createGenericKeyValueTable<ValidatedSettings>(
      settingsGeneralProperties(settings.IsRopingPercentage),
      settings,
      currentUnitSystem,
      null,
    );
  }

  private static createPerformanceOptions(settings: ValidatedSettings, currentUnitSystem: IUnitSystemDto): Table {
    return DocumentTableGeneratorHelper.createGenericKeyValueTable<ValidatedSettings>(
      settingsPerformanceProperties(),
      settings,
      currentUnitSystem,
      null,
    );
  }

  private static createFrictionOptions(settings: ValidatedSettings, currentUnitSystem: IUnitSystemDto): Table {
    enum WashpipeGaugeMeasurementFlowPath {
      'Internal (WP/BP)' = 0,
      'External (Screen/Wellbore)' = 1,
    }

    const enumItemMap = new Map<keyof ValidatedSettings, ISelectItem<any>[]>();
    enumItemMap.set('WashpipeGaugeMeasurementFlowPath', EnumHelpers.EnumToISelectItemArray(WashpipeGaugeMeasurementFlowPath));

    return DocumentTableGeneratorHelper.createGenericKeyValueTable<ValidatedSettings>(
      settingsFrictionProperties,
      settings,
      currentUnitSystem,
      enumItemMap,
    );
  }

  private static createPackingOptions(settings: ValidatedSettings, currentUnitSystem: IUnitSystemDto): Table {
    enum OroskarAndTurianDiameter {
      'Equivalent Diameter' = 0,
      'Hydraulic Diameter' = 1,
    }

    const enumItemMap = new Map<keyof ValidatedSettings, ISelectItem<any>[]>();
    enumItemMap.set('OroskarAndTurianDiameter', EnumHelpers.EnumToISelectItemArray(OroskarAndTurianDiameter));

    return DocumentTableGeneratorHelper.createGenericKeyValueTable<ValidatedSettings>(
      settingsPackingProperties,
      settings,
      currentUnitSystem,
      enumItemMap,
    );
  }

  private static createThermalOptions(settings: ValidatedSettings, currentUnitSystem: IUnitSystemDto): Table {
    return DocumentTableGeneratorHelper.createGenericKeyValueTable<ValidatedSettings>(
      settingsThermalProperties,
      settings,
      currentUnitSystem,
      null,
    );
  }
}
