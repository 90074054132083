<div *ngIf="caliperData?.isValid && well?.error?.CaliperTolerance?.length === 0 && chartState; else error">
  <app-chart-controller
    [chartState]="chartState"
    [chartId]="chartId"
    [chartDisplayName]="chartName"
    [drawableProviderId]="drawableProviderId ?? chartName"
    [reverseArgument]="true"
    [rotateChart]="true"
    dataCy="caliper-preview-chart"
  ></app-chart-controller>
</div>
<ng-template #error>
  <div class="error-message">Caliper data validation errors must be corrected before open hole selection preview can be
    generated
  </div>
</ng-template>
