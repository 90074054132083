// Kahn's algorithm for sorting Directed Acyclic Graphs
export class KahnsAlgorithm {
  // Map of all vertices with an arrays of other vertices depending on them
  private adjacencies: { [key: string]: string[] } = {};

  public addDependency(vertex: string, dependency?: string): void {
    if (this.adjacencies[vertex] === undefined) {
      this.adjacencies[vertex] = [];
    }
    if (!dependency) {
      return;
    }
    if (this.adjacencies[dependency] === undefined) {
      this.adjacencies[dependency] = [];
    }
    this.adjacencies[dependency].push(vertex);
  }

  public calculateExecutionOrder(): string[] {
    // Map of all vertices with the number of dependencies
    const numOfDependencies: { [key: string]: number } = {};
    for (const [key, adjArr] of Object.entries(this.adjacencies)) {
      if (numOfDependencies[key] === undefined) {
        numOfDependencies[key] = 0;
      }
      for (const vertex of adjArr) {
        if (numOfDependencies[vertex] === undefined) {
          numOfDependencies[vertex] = 0;
        }
        numOfDependencies[vertex] += 1;
      }
    }

    const verticesWithoutDependencies = Object.keys(numOfDependencies).filter((key) => numOfDependencies[key] === 0);
    const calculatedExecutionOrder = [];

    while (verticesWithoutDependencies.length) {
      const vertexWithoutDependencies = verticesWithoutDependencies.shift() as string;
      calculatedExecutionOrder.push(vertexWithoutDependencies);

      // Decrease number of dependencies for all vertices depending on the processed one
      for (const vertex of this.adjacencies[vertexWithoutDependencies]) {
        numOfDependencies[vertex] -= 1;
        if (numOfDependencies[vertex] === 0) {
          verticesWithoutDependencies.push(vertex);
        }
      }
    }

    if (calculatedExecutionOrder.length != Object.keys(numOfDependencies).length) {
      throw new Error('Circular dependencies');
    }

    return calculatedExecutionOrder;
  }
}
