import { DocumentGeneratorHelper } from '../document-generator.helper';
import { HeadingLevel, Paragraph, Table } from 'docx';
import { IUnitSystemDto } from '@dunefront/common/dto/unit-system.dto';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';
import { FluidModuleState } from '@dunefront/common/modules/fluid/fluid-module.state';
import { getFluidModuleState } from '../../../../../../+store/fluid/fluid.selectors';
import { DocumentTableGeneratorHelper } from '../document-table-generator.helper';
import { getWellModuleState } from '../../../../../../+store/well/well.selectors';
import { WellModuleState } from '@dunefront/common/modules/well/well-module.state';
import { getValidatedSettings } from '../../../../../../+store/settings/validated-settings.selectors';
import { ValidatedSettings } from '@dunefront/common/modules/settings/dto/settingsDto';
import { CommonReportDataHelpers } from '../../common/common-report-data-helpers';
import { DocxHtmlParser } from '../../../generators/doc/docx-html-parser';

export class FluidsReportGeneratorHelper {
  public static async createFluidsDataSection(store: Store, currentUnitSystem: IUnitSystemDto): Promise<Array<Paragraph | Table>> {
    const fluidsState: FluidModuleState = await firstValueFrom(store.select(getFluidModuleState));
    const wellState: WellModuleState = await firstValueFrom(store.select(getWellModuleState));
    const settingsState: ValidatedSettings = await firstValueFrom(store.select(getValidatedSettings));
    const fluidsData = await this.createFluidsData(store, fluidsState, wellState, settingsState, currentUnitSystem);

    return [
      DocumentGeneratorHelper.createParagraphHeader(`Fluids Data`, HeadingLevel.HEADING_1, 0),
      ...fluidsData
    ];
  }

  private static async createFluidsData(
    store: Store,
    fluidsState: FluidModuleState,
    wellState: WellModuleState,
    settings: ValidatedSettings,
    currentUnitSystem: IUnitSystemDto
  ): Promise<Array<Paragraph | Table>> {
    const {
      tableData,
      enumItemMap
    } = await CommonReportDataHelpers.createFluidsTableData(store, fluidsState, wellState, settings);

    const elements: Array<Paragraph | Table> = [];

    tableData.dataRows.forEach((row) => {
      const singleFluidTableData = {
        dataRows: [row],
        dataCells: tableData.dataCells
      };
      elements.push(
        ...[
          DocumentGeneratorHelper.createParagraphHeader(row.Name, HeadingLevel.HEADING_2, 1),
          DocumentTableGeneratorHelper.createTable(singleFluidTableData, enumItemMap, currentUnitSystem, undefined, {
            0: 15,
            3: 11
          }),
          new Paragraph({}),
          ...new DocxHtmlParser(row.Notes).render()
        ]
      );
    });
    return elements;
  }
}
