import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { ITableRow } from '@dunefront/common/common/common-grid.interfaces';
import { DbDependentComponent } from '../../../../../../../common-modules/db-connection/db-dependent.component';
import { Store } from '@ngrx/store';
import { CompletionModuleState } from '@dunefront/common/modules/completion/completion-module.state';
import { notEmpty } from '@dunefront/common/common/state.helpers';
import * as completionActions from '../../../../../../../+store/completion/completion.actions';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { IcdScreenPipe } from '@dunefront/common/modules/pipes/lower-completion-pipes/pipes/icd-screen-pipe';
import { Pipe } from '@dunefront/common/modules/pipes/pipe';
import { getValidatedCompletionModuleState } from '../../../../../../../+store/completion/validated-completion.selectors';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { changeProp, ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';
import { modalButton, ModalButtonConfig } from '../../../../../../../common-modules/modals/generic-modal/generic-modal.component';

@Component({
  selector: 'app-bhge-icd-or-aicd-screen',
  templateUrl: './bhge-icd-or-aicd-screen.modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BhgeIcdOrAicdScreenModalComponent extends DbDependentComponent {
  public screenPipeRow: ITableRow<IcdScreenPipe> | undefined;

  private _lowerCompletionId?: number;
  public set lowerCompletionId(lowerCompletionId: number) {
    this._lowerCompletionId = lowerCompletionId;
    this.loadData();
  }

  public UnitType = UnitSystem;
  private completion: CompletionModuleState | undefined;
  public modalTitle = 'ICD Port Data';
  public modalButtonsConfigs: ModalButtonConfig[] = [modalButton('Close', (): void => this.onCloseClicked(), 'aicd-cancel-btn', 'cancel')];

  constructor(
    public activeModal: DynamicDialogRef,
    public config: DynamicDialogConfig,
    store: Store,
    cdRef: ChangeDetectorRef,
  ) {
    super(store, cdRef);

    this.subscription.add(
      notEmpty(store.select(getValidatedCompletionModuleState)).subscribe((completionData) => {
        this.completion = completionData;
        this.loadData();
        this.cdRef.markForCheck();
      }),
    );

    this.lowerCompletionId = config.data.lowerCompletionId;
  }

  public loadData(): void {
    if (this.completion && this._lowerCompletionId != null && this._lowerCompletionId > 0) {
      this.screenPipeRow = this.completion.LowerCompletion.rows.find(
        (row) => row.rowData.Id === this._lowerCompletionId,
      ) as ITableRow<IcdScreenPipe>;
    }
  }

  public onCloseClicked(): void {
    this.activeModal.close();
  }

  public onValueChange(event: ObjectChangeProp<IcdScreenPipe>): void {
    if (!this.screenPipeRow) {
      return;
    }

    this.screenPipeRow = {
      ...this.screenPipeRow,
      rowData: changeProp(this.screenPipeRow.rowData, event),
    };
    this.store.dispatch(
      completionActions.updateLowerCompletionRow({
        rows: [this.screenPipeRow],
        colIds: [event.key as keyof Pipe],
        shouldResetResults: event.shouldResetResults,
      }),
    );
  }
}
