import { PipeType } from '../../../../dto/pipe.dto';
import { RunningStringPipeDto } from '../../../../dto/running-string-pipe.dto';
import { BasePipeFactory } from '../../base.pipe.factory';
import { BypassAnnulus } from '../bypass-annulus';
import { Pipe } from '../../pipe';
import { ITableRow } from '../../../../common/common-grid.interfaces';
import { IError } from '../../../../common/common-state.interfaces';

export interface ServiceToolPipe extends Pipe {
  PipeType: PipeType.Service_Tool;
  DownFlow: BypassAnnulus;
  UpFlow: BypassAnnulus;
  IsUpFlowPresent: boolean;
  IsDownFlowPresent: boolean;
  error: IError<ServiceToolPipe>;
}

export class ServiceToolPipeFactory {
  public static createFromRunningString(runningStringDto: RunningStringPipeDto): ServiceToolPipe {
    const pipe = BasePipeFactory.createFromDto(runningStringDto);
    const serviceToolPipe: ServiceToolPipe = {
      ...pipe,
      PipeType: PipeType.Service_Tool,
      IsDownFlowPresent: runningStringDto.IsDownFlowPresent,
      IsUpFlowPresent: runningStringDto.IsUpFlowPresent,
      DownFlow: {
        NoOfPorts: runningStringDto.DownFlowNoOfPorts,
        PortDiameter: runningStringDto.DownFlowPortDiameter,
        PortLength: runningStringDto.DownFlowPortLength,
        AnnulusID: runningStringDto.DownFlowAnnulusID,
        AnnulusOD: runningStringDto.DownFlowAnnulusOD,
        AnnulusLength: runningStringDto.DownFlowAnnulusLength,
        error: {},
      },
      UpFlow: {
        NoOfPorts: runningStringDto.UpFlowNoOfPorts,
        PortDiameter: runningStringDto.UpFlowPortDiameter,
        PortLength: runningStringDto.UpFlowPortLength,
        AnnulusID: runningStringDto.UpFlowAnnulusID,
        AnnulusOD: runningStringDto.UpFlowAnnulusOD,
        AnnulusLength: runningStringDto.UpFlowAnnulusLength,
        error: {},
      },
      error: {},
    };
    return serviceToolPipe;
  }
}

export function isServiceToolPipeRow(row: ITableRow<Pipe>): row is ITableRow<ServiceToolPipe> {
  return isServiceToolPipe(row.rowData);
}

export function isServiceToolPipe(pipe: Pipe): pipe is ServiceToolPipe {
  return pipe.PipeType === PipeType.Service_Tool;
}
