<div class="grid-form-container main with-gap" *ngIf="toolJoint">
  <div class="grid-form-container no-padding inline">
    <app-form-input
      appUnwrap
      label="Single Joint Length"
      [source]="toolJoint"
      [key]="'SingleJointLength'"
      [unitType]="UnitType.Long_Length"
      (valueChanged)="onValueChange($event)"
      dataCy="single-joint-length"
    ></app-form-input>
    <app-form-input
      appUnwrap
      label="Tool Joint Length"
      [source]="toolJoint"
      [key]="'ToolJointLength'"
      [unitType]="UnitType.Long_Length"
      (valueChanged)="onValueChange($event)"
      dataCy="tool-joint-length"
    ></app-form-input>
  </div>
  <div class="grid-form-container no-padding inline">
    <app-form-input
      appUnwrap
      label="Tool Joint OD"
      [source]="toolJoint"
      [key]="'OuterDiameter'"
      [unitType]="UnitType.Diameter"
      [decimalPlaces]="3"
      (valueChanged)="onValueChange($event)"
      dataCy="tool-joint-od"
    ></app-form-input>
    <app-form-input
      appUnwrap
      label="Tool Joint ID"
      [source]="toolJoint"
      [key]="'InnerDiameter'"
      [decimalPlaces]="3"
      [unitType]="UnitType.Diameter"
      (valueChanged)="onValueChange($event)"
      dataCy="tool-joint-id"
    ></app-form-input>
  </div>
</div>
