export function isElectron(): boolean {
  // Renderer process
  if (typeof window !== 'undefined' && typeof (window as any).process === 'object' && (window as any).process.type === 'renderer') {
    return true;
  }

  // Detect the user agent when the `nodeIntegration` option is set to true
  if (typeof navigator === 'object' && typeof navigator.userAgent === 'string' && navigator.userAgent.includes('Electron')) {
    return true;
  }

  // Main process
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (typeof process !== 'undefined' && typeof process.versions === 'object' && !!(process.versions as any).electron) {
    return true;
  }

  return false;
}

export function isElectronPackaged(): boolean {
  return parseInt(process.env.ELECTRON_IS_PACKAGED ?? '0', 10) === 1;
}
