import { CompletionDto } from '../modules/completion/dto/completion.dto';
import { ZoneDto } from '../modules/well/dto/zone/zone.dto';
import { LowerCompletionPipeDto } from './lower-completion-pipe.dto';
import { CasingPipeDto } from '../modules/well/dto/casing/casing-pipe.dto';
import { CaliperDto } from '../modules/well/dto/caliper/caliper.dto';
import { SurveyDto } from '../modules/well/dto/survey/survey.dto';
import { RunningStringPipeDto } from './running-string-pipe.dto';
import { WellFluidDto } from '../modules/pumping/dto/well-fluid.dto';
import { WellDto } from '../modules/well/dto/well.dto';
import { ShuntTubeDto } from './shunt-tube.dto';
import { FluidDto } from '../modules/fluid/dto/fluid.dto';
import { RheologyDto } from '../modules/fluid/dto/rheology/rheology.dto';
import { RheometerDto, RheometerReadingDto } from '../modules/fluid/dto/rheometer.dto';
import { IIndexedDataType, IScenarioIdBasedTableEntity, WritableCommonDbType } from './common-dto.interfaces';
import { IcdPortDataDto } from './icd-port-data.dto';
import { GravelDto } from '../modules/gravel/dto/gravel.dto';
import { MASPCalculatorDto } from '../modules/calculators/masp-calculator/masp-calculator.dto';
import { ResuspensionCalculatorDto } from '../modules/calculators/resuspension-calculator/resuspension-calculator.dto';
import { PumpingDto } from '../modules/pumping/dto/pumping.dto';
import { FrictionCalculatorDto, FrictionCalculatorResultDto } from '../modules/calculators/friction-calculator/friction-calculator.dto';
import { SettlingCalculatorDto, SettlingCalculatorResultDto } from '../modules/calculators/dto/settling-calculator.dto';
import {
  InjectionTestCalculatorDto,
  InjectionTestReadingDto,
} from '../modules/calculators/injection-test-calculator/injection-test-calculator.dto';
import { LeakoffCoefficientCalculatorDto } from '../modules/calculators/leakoff-coefficient-calculator/leakoff-coefficient-calculator.dto';
import { PSDDto, PSDResultDto } from '../modules/psd-analysis/dto/psd.dto';
import { PSDReadingDto } from '../modules/psd-analysis/dto/psd-reading.dto';
import { PsdSandProductionInputs } from '../modules/psd-analysis/dto/psd-sand-production.inputs';
import { ReportingTabDto } from './reporting-tab.dto';
import { ChartDto } from '../modules/reporting/dto/chart.dto';
import { ImportColumnDto } from '../modules/data-storage/dto/import-column.dto';
import { EntityToCopy, ScenarioDto } from '../modules/scenario/scenario.dto';
import { IImportTemplateDto } from '../modules/data-storage/dto/import-template/import-template.dto';
import { RangeDto } from './range.dto';
import { FileSettingsDto } from './file-settings.dto';
import { PumpingScheduleDto } from '../modules/pumping/dto/pumping-schedule.dto';
import { PumpedFluidAndGravelDto } from '../modules/pumping/dto/pumped-fluid-and-gravel.dto';
import { VerticalShiftDto } from './trend-analysis/vertical-shift.dto';
import { ReportInfoDto } from './report-info.dto';
import { SettingsDto } from '../modules/settings/dto/settingsDto';
import { ReferenceVariableCalculatorResultsDto } from '../modules/reference-variables/dto/reference-variable-calculator-results.dto';
import { SectionInformationDto } from '../modules/completion/dto/section-information.dto';
import { ChartSeriesTemplateDto } from './chart-series-template.dto';
import { IChartMarkerDto } from '../modules/reporting/dto/chart-marker.dto';
import { ChartAxisPropertyDto } from '../modules/reporting/dto/chart-axis-property.dto';
import { ChartSeriesDto } from '../modules/reporting/dto/chart-series.dto';
import { ImportFileWithMinMaxArgumentsDto } from '../modules/data-storage/dto/import-file.dto';
import { ChartAnnotationDto } from '../modules/reporting/dto/chart-annotation.dto';
import { ICustomSettings } from './custom-settings.dto';
import { IChartTemplateDto } from './chart-templates.dto';
import { ScenarioRangePropertiesDto } from './scenario-range-properties.dto';
import { ChartGradientLineDto } from '../modules/reporting/dto/chart-gradient-line.dto';
import { NoteDto } from './note.dto';
import { GlobalOptionsDto } from './global-options.dto';
import { ILoadCommonFluidsActionResponse, ILoadCommonGravelsActionResponse } from '../modules/common-db/common-db.actions';
import { DeleteRangeDataActionResponse } from '../modules/range/range-module.actions';
import { IUnitSystem } from '../modules/units/units.actions';
import { UserJobs } from '../modules/calculation-engine/calculation-engine.actions';

export interface IAffectedModels {
  well: WellDto;
  completion: CompletionDto;
  shuntTube: ShuntTubeDto;
  pumping: IAffectedModel<PumpingDto>;
  pumpingSchedule: IAffectedModel<PumpingScheduleDto>;
  evaluationPumpingSchedule: IAffectedModel<PumpingScheduleDto>;
  settings: SettingsDto;
  MASPCalculator: MASPCalculatorDto;
  resuspensionCalculator: ResuspensionCalculatorDto;
  leakoffCoefficientCalculator: LeakoffCoefficientCalculatorDto;
  frictionCalculator: FrictionCalculatorDto;
  frictionCalculatorResult: FrictionCalculatorResultDto[];
  settlingCalculator: SettlingCalculatorDto;
  settlingCalculatorResult: SettlingCalculatorResultDto[];
  psd: IAffectedModel<PSDDto>;
  psdAnalysis: PsdSandProductionInputs;
  psdReadings: IAffectedModel<PSDReadingDto>;
  psdResults: IAffectedModel<PSDResultDto>;
  fluid: IAffectedModel<FluidDto>;
  gravel: IAffectedModel<GravelDto>;
  zone: IAffectedModel<ZoneDto>;
  lowerCompletion: IAffectedModel<LowerCompletionPipeDto>;
  icdPortData: IAffectedModel<IcdPortDataDto>;
  casing: IAffectedModel<CasingPipeDto>;
  caliper: IAffectedModel<CaliperDto>;
  survey: IAffectedModel<SurveyDto>;
  runningString: IAffectedModel<RunningStringPipeDto>;
  wellFluid: IAffectedModel<WellFluidDto>;
  rheology: IAffectedModel<RheologyDto>;
  rheometer: IAffectedModel<RheometerDto>;
  rheometerReading: IAffectedModel<RheometerReadingDto>;
  injectionTestCalculator: InjectionTestCalculatorDto;
  injectionTestReadings: IAffectedModel<InjectionTestReadingDto>;
  chartMarker: IAffectedModel<IChartMarkerDto>;
  chartAnnotation: IAffectedModel<ChartAnnotationDto>;
  chartGradientLine: IAffectedModel<ChartGradientLineDto>;
  chartAxisProperty: IAffectedModel<ChartAxisPropertyDto>;
  reportingTabs: IAffectedModel<ReportingTabDto>;
  chart: IAffectedModel<ChartDto>;
  chartSeries: IAffectedModel<ChartSeriesDto>;
  importFiles: IAffectedModel<ImportFileWithMinMaxArgumentsDto>;
  importColumns: IAffectedModel<ImportColumnDto>;
  scenario: IAffectedModel<ScenarioDto>;
  importTemplate: IAffectedModel<IImportTemplateDto>;
  chartTemplate: IAffectedModel<IChartTemplateDto>;
  range: IAffectedModel<RangeDto>;
  scenarioRangeProperties: IAffectedModel<ScenarioRangePropertiesDto>;
  fileSettings: FileSettingsDto;
  pumpedFluidsAndGravel: IAffectedModel<PumpedFluidAndGravelDto>;
  verticalShift: IAffectedModel<VerticalShiftDto>;
  reportInfo: ReportInfoDto;
  referenceVariables: ReferenceVariableCalculatorResultsDto;
  volumeSectionCalculatorResult: SectionInformationDto;
  volumeGaugeSectionInformationResult: IAffectedModel<SectionInformationDto>;
  chartSeriesTemplate: IAffectedModel<ChartSeriesTemplateDto>;
  customSettings: Partial<ICustomSettings>;
  note: IAffectedModel<NoteDto>;
  globalOptionsForDbType: { globalOptions: GlobalOptionsDto; commonDbType: WritableCommonDbType };
  cloneSuccess: { entitiesToCopy: EntityToCopy[]; targetScenarioIds: number[]; fileHash: string };
  commonDbFluids: ILoadCommonFluidsActionResponse;
  commonDbGravels: ILoadCommonGravelsActionResponse;
  deleteRangeData: DeleteRangeDataActionResponse;
  unitSystem: IUnitSystem;
  userJobs: UserJobs;
}

export const getAffected = <DTO>(
  rows: DTO[],
  deletedIds: (number | string)[] = [],
  parentId?: number,
  replace?: ReplaceMode,
  replaceByParentIds?: number[],
  insertedRows?: DTO[],
): IAffectedModel<DTO> => getAffectedAfterUpdate(rows, [], deletedIds, parentId, replace, replaceByParentIds, insertedRows);

export const getAffectedAfterUpdate = <DTO>(
  rows: DTO[],
  colIds: (keyof DTO)[],
  deletedIds: (number | string)[] = [],
  parentId?: number,
  replace?: ReplaceMode,
  replaceByParentIds?: number[],
  insertedRows?: DTO[],
): IAffectedModel<DTO> => ({
  rows,
  deletedIds,
  colIds,
  ...(parentId !== undefined ? { parentId } : {}),
  ...(replace !== undefined ? { replace } : {}),
  ...(replaceByParentIds !== undefined ? { replaceByParentIds } : {}),
  ...(insertedRows !== undefined ? { insertedRows } : {}),
});

export interface IAffectedModel<DTO> {
  rows: DTO[];
  deletedIds: (number | string)[];
  colIds: (keyof DTO)[];
  insertedRows?: DTO[];
  parentId?: number; // this should be filled only when on delete action
  replace?: ReplaceMode;
  replaceByParentIds?: number[];
  encodedRows?: string;
}

export type ReplaceMode = 'all' | 'byParentId' | 'affectedOnly';

export function mergeArrays<DTO extends IIndexedDataType>(array1: DTO[], array2: DTO[]): DTO[] {
  const newArray = array1.filter((a1) => !array2.map((a2) => a2.Id).includes(a1.Id));
  return [...newArray, ...array2];
}

export function removeId<T extends IScenarioIdBasedTableEntity>(dto: T): T {
  dto.Id = undefined;
  return dto;
}
