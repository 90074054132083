import { createSelector } from '@ngrx/store';
import { getIsDatabaseConnected } from '../../../../+store/backend-connection/backend-connection.selectors';
import { getIsCurrentScenarioCalculationActive } from '../../../../+store/reporting/reporting.selectors';
import { getAreCurrentResultsPresentAndCompleted } from '../../../../+store/calculation-engine/calculation-engine-results.selectors';

export const getAnimationChartCanFetchData = createSelector(
  getIsDatabaseConnected,
  getIsCurrentScenarioCalculationActive,
  getAreCurrentResultsPresentAndCompleted,
  (isDatabaseConnected, isCalculationActive, areCurrentResultsPresentAndCompleted): boolean => {
    return isDatabaseConnected && !isCalculationActive && areCurrentResultsPresentAndCompleted;
  },
);
