import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import * as wellActions from '../../../../+store/well/well.actions';
import { ValidatedWellModuleState } from '@dunefront/common/modules/well/well-module.state';
import { TemperatureProfileType } from '@dunefront/common/modules/well/dto/well.dto';
import { PageWithGridComponent } from '../../../../shared/components/grid/page-with-grid.component';
import { ModalService } from '../../../../common-modules/modals/modal.service';
import { ScreenService } from '../../../../shared/services';
import { ITableRow } from '@dunefront/common/common/common-grid.interfaces';
import * as uiActions from '../../../../+store/ui/ui.actions';
import { Survey } from '@dunefront/common/modules/well/model/survey/survey';
import { TemperatureProfile } from '@dunefront/common/modules/well/model/temperature-profile/temperature-profile';
import { ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';
import { IUpdateTableRowsProps } from '@dunefront/common/common/common-store-crud.interfaces';
import { getValidatedWellModuleState } from '../../../../+store/well/validated-well.selectors';
import { PanelHelpMode } from '../../../../shared/components/help-button/help-buton.component';

@Component({
  selector: 'app-general-data',
  templateUrl: './general-data.component.html',
  styleUrls: ['./general-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeneralDataComponent extends PageWithGridComponent<Survey> {
  public wellState!: ValidatedWellModuleState;
  public TemperatureProfileType = TemperatureProfileType;
  public PanelHelpMode = PanelHelpMode;

  constructor(store: Store, cdRef: ChangeDetectorRef, modalService: ModalService, resizeService: ScreenService) {
    super(store, cdRef, modalService, resizeService);
    store.dispatch(uiActions.setWellTabAction({ tab: 'general-data' }));

    this.subscription.add(
      store.select(getValidatedWellModuleState).subscribe((state) => {
        this.wellState = state;
        cdRef.markForCheck();
      }),
    );
    super.onHelpChange('well', 'survey');
  }

  public getRows(): ITableRow<Survey>[] {
    return this.wellState?.SurveyData.rows ?? [];
  }

  protected updateRow(row: IUpdateTableRowsProps<any>): void {
    this.gridComponent?.grid.gridConfig?.updateRowsAction(row);
  }

  public onWellPropertyChanged(props: ObjectChangeProp<ValidatedWellModuleState>): void {
    this.store.dispatch(wellActions.changeWellPropertyAction(props));
  }

  public onTemperatureProfileChanged(props: ObjectChangeProp<TemperatureProfile>): void {
    this.store.dispatch(wellActions.changeTemperatureProfilePropertyAction(props));
  }

  public get isDetailedCalc(): boolean {
    return this.wellState.TemperatureProfile.TemperatureProfileType === TemperatureProfileType.Detailed_Calc;
  }
}
