import { IScenarioIdBasedEntity } from './common-dto.interfaces';

export interface ShuntTubeDto extends ShroudDto, TransportTubeDto, PackingTubeDto, NozzlePropertiesDto, IScenarioIdBasedEntity {
  IsTransportTubePresent: boolean;
  IsInternalReservoir: boolean;
  ShuntPackingFactor: number;
  IsExternal: boolean;
  Shape: TubeShape;
}

export interface NozzlePropertiesDto {
  IsNozzleLeakoff: boolean;
  NozzleDiameter: number;
  NozzleLength: number;
  LengthBetweenNozzles: number;
  LengthToFirstNozzle: number;
}

export interface ShroudDto {
  IsShroudPresent: boolean;
  ShroudOuterDiameter: number;
  ShroudInnerDiameter: number;
  OpenAreaPercentage: number;
}

export interface PackingTubeDto {
  NumberOfPackingTubes: number;
  PackingTubeWidth: number;
  PackingTubeHeight: number;
  PackingTubeThickness: number;
  PackingTubeOuterDiameter: number;
  PackingTubeInnerDiameter: number;
  PackingTubeBurstRating: number;
  PackingTubeCollapseRating: number;
}

export interface TransportTubeDto {
  NumberOfTransportTubes: number;
  TransportTubeWidth: number;
  TransportTubeHeight: number;
  TransportTubeThickness: number;
  TransportTubeOuterDiameter: number;
  TransportTubeInnerDiameter: number;
  TransportTubeBurstRating: number;
  TransportTubeCollapseRating: number;
}

export enum TubeShape {
  Rectangle = 0,
  Round = 1,
}
