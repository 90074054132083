<div *ngIf="pumping" class="grid-form-container">
  <div class="grid-two-one">
    <app-radio-group
      id="treating-line-coiled-tubing"
      [items]="items"
      [layout]="'horizontal'"
      [source]="pumping"
      [key]="'IsTreatingLine'"
      (valueChanged)="dataChange.emit($event)"
      dataCy="is-treating-line"
    >
    </app-radio-group>
  </div>

  <app-check-box
    [disabled]="!isCheckEditReelDiameterEnabled"
    [source]="pumping"
    [key]="'IsCoiledTubing'"
    (valueChanged)="dataChange.emit($event)"
    label="Reel Diameter"
    [attr.data-cy]="'is-coiled-tubing'"
  >
  </app-check-box>
  <app-form-input
    [source]="pumping"
    [key]="'ReelDiameter'"
    [decimalPlaces]="3"
    [unitType]="UnitType.Diameter"
    [disabled]="!isTxtReelDiameterEnabled"
    (valueChanged)="dataChange.emit($event)"
    dataCy="reel-diameter"
  >
  </app-form-input>
  <app-form-input
    class="grid-two-one"
    appUnwrap
    [source]="pumping"
    [label]="lineIdLabelText"
    key="TreatingLineID"
    [decimalPlaces]="3"
    [unitType]="UnitType.Diameter"
    [disabled]="!isTxtTreatLineIDEnabled"
    (valueChanged)="dataChange.emit($event)"
    dataCy="treating-line-id"
  >
  </app-form-input>
  <app-form-input
    class="grid-two-one"
    appUnwrap
    [source]="pumping"
    [label]="lineLengthLabelText"
    key="SurfaceTreatingLineLength"
    [decimalPlaces]="2"
    [unitType]="UnitType.Long_Length"
    [disabled]="!isTxtTreatLineLengthEnabled"
    (valueChanged)="dataChange.emit($event)"
    dataCy="treating-line-length"
  >
  </app-form-input>
</div>
