import { NgZone } from '@angular/core';
import { WsAction } from '@dunefront/common/ws.action';
import { Store } from '@ngrx/store';
import { IpcRenderer } from 'electron';
import { Observable } from 'rxjs';
import * as actions from '../../+store/backend-connection/backend-connection.actions';

import { encodeModuleName } from '@dunefront/common/modules/common.interfaces';
import { ElectronService } from '../services/electron-service/electron.service';
import { DocumentExtension } from '@dunefront/common/common/helpFile';
import { BaseBackendConnectionService } from './base.backend-connection.service';
import { WsActionResponse } from '@dunefront/common/response-ws.action';
import { ELECTRON_ACTION_IPC_INIT_CONNECTION, ELECTRON_ACTION_OPEN_DOCUMENT } from '@dunefront/common/common/electron/electron.actions';
import IpcRendererEvent = Electron.IpcRendererEvent;

export class ElectronIpcConnectionService extends BaseBackendConnectionService {
  private readonly ipcRenderer: IpcRenderer;

  constructor(
    private ngZone: NgZone,
    store: Store,
    private electronService: ElectronService,
  ) {
    super(store);
    console.warn('USING ELECTRON IPC CONNECTION');
    if (!electronService.instance) {
      throw new Error('Election instance not found!');
    }

    this.ipcRenderer = electronService.instance.ipcRenderer;

    this.connect().then();
  }

  public async connect(): Promise<boolean> {
    await this.ipcRenderer.invoke(ELECTRON_ACTION_IPC_INIT_CONNECTION);
    this.store.dispatch(actions.backendConnectedAction());
    return true;
  }

  public disconnect(): void {
    return;
  }

  public async emitAsync<T>(moduleName: string, isDbDependent: boolean, action: WsAction): Promise<WsActionResponse<T>> {
    return new Promise((resolve, reject) =>
      this.ipcRenderer
        .invoke(encodeModuleName(moduleName, isDbDependent), action)
        .then((response) => this.handleEmitAsyncResponse(response, resolve, reject))
        .catch((err) => reject(this.parseError(err))),
    );
  }

  public override get socketId(): string | undefined {
    return '';
  }

  public emit<T>(moduleName: string, isDbDependent: boolean, action: WsAction): Observable<WsActionResponse<T>> {
    return new Observable<WsActionResponse<T>>((observer) => {
      // console.log("ElectronIpcConnectionService - emit", action);
      this.ipcRenderer
        .invoke(encodeModuleName(moduleName, isDbDependent), action)
        .then((response) => this.handleObservableResponse(observer, response))
        .catch((err) => observer.error(this.parseError(err)));
    });
  }

  public on(moduleName: string, isDbDependent: boolean): Observable<WsAction> {
    return new Observable<WsAction>((observer) => {
      this.ipcRenderer.on(encodeModuleName(moduleName, isDbDependent), (event: IpcRendererEvent, ...args: any[]) => {
        this.ngZone.run(() => {
          observer.next(args[0]);
        });
      });
    });
  }

  public async openDocument(url: string, extension?: DocumentExtension): Promise<void> {
    await this.ipcRenderer.invoke(ELECTRON_ACTION_OPEN_DOCUMENT, { url, extension });
  }
}
