import { getCurrentFileHasAnyUIBlockingJobs } from '../calculation-engine/calculation-engine.selectors';
import { getIsFileLocked } from '../file-settings/file-settings.selectors';
import { createSelector } from '@ngrx/store';
import { getBackendActiveRequestsCount } from '../backend-connection/backend-connection.selectors';
import { getCurrentFileHasAnyJsWorkerJobs } from '../calculation-engine/js-worker-jobs.selectors';
import { getIsCurrentScenarioCalculationActive } from '../reporting/reporting.selectors';

export const getIsProjectCalculationActive = createSelector(
  getIsCurrentScenarioCalculationActive,
  getCurrentFileHasAnyUIBlockingJobs,
  getCurrentFileHasAnyJsWorkerJobs,
  (scenarioCalculationActive, hasAnyWaitingOrRunningJobs, hasAnyJsWorkerJobs): boolean =>
    scenarioCalculationActive || hasAnyWaitingOrRunningJobs || hasAnyJsWorkerJobs,
);

export const getIsUiLocked = createSelector(
  getIsFileLocked,
  getIsProjectCalculationActive,
  (isFileLocked, isCalculationActive): boolean => isFileLocked || isCalculationActive,
);

export const isUiLockedOrRequestInProgress = createSelector(getIsUiLocked, getBackendActiveRequestsCount, (isUiLocked, activeRequests) => ({
  isUiLocked,
  isRequestActive: activeRequests > 0,
}));
