import { MarkerMode } from '../chart-controller.component';
import { Observable, Subscription } from 'rxjs';
import { IMarker } from '@dunefront/common/modules/reporting/dto/chart-marker.dto';
import { CreateMarkerPayload } from '../chart-component-helpers/chart-marker-helpers';

export abstract class MarkersServiceBase {
  protected subscription = new Subscription();

  protected constructor(public readonly mode: MarkerMode) {}

  public abstract get markers$(): Observable<IMarker[]>;

  public abstract get disableAddingMarkers(): boolean;

  public abstract onMarkerMoved(marker: IMarker): void;

  public onCreateMarker(payload: CreateMarkerPayload, scenarioId: number, isChartRotated: boolean): void {
    // overridden in derived class
  }

  public onEditMarker(marker: IMarker, isChartRotated: boolean): void {
    // overridden in derived class
  }

  public dispose(): void {
    this.subscription.unsubscribe();
  }
}
