import { ITableState } from '@dunefront/common/common/common-grid.interfaces';
import { WarningMessage } from '@dunefront/common/modules/summary/warning-message.factory';

export const hideWarningMessagesTime = (warningMessages: ITableState<WarningMessage>): ITableState<WarningMessage> => {
  const trimAfterSubstring = (str: string, substring: string): string => {
    const index = str.indexOf(substring);
    if (index !== -1) {
      return str.slice(0, index) + '(text trimmed for e2e run)';
    }
    return str;
  };

  return {
    ...warningMessages,
    rows: warningMessages.rows.map((msg) => {
      return {
        ...msg,
        rowData: { ...msg.rowData, MessageText: trimAfterSubstring(msg.rowData.MessageText, '(total time') },
      };
    }),
  };
};
