<div class="grid-form-container no-padding">
  <label [class.highlight]="isValueDiffThanDefault('LegendLocation')">Legend Position</label>
  <app-select
    [source]="legendStyle"
    [sourceDefaults]="defaultLegendStyle"
    [key]="'LegendLocation'"
    [items]="LegendPositions"
    [width]="180"
    [isUiLockable]="false"
    [disabled]="disabled"
    [highlight]="isValueDiffThanDefault('LegendLocation')"
    (valueChanged)="valueChanged.emit($event)"
  ></app-select>

  <app-font-style
    appUnwrap
    [source]="legendStyle"
    [sourceDefaults]="defaultLegendStyle"
    [fontSizeKey]="'LegendFontSize'"
    [isBoldKey]="'IsLegendFontBold'"
    [isItalicKey]="'IsLegendFontItalic'"
    [fontColorKey]="'LegendFontColor'"
    [idPrefix]="'legend'"
    [disabled]="disabled"
    (valueChanged)="valueChanged.emit($event)"
  ></app-font-style>
</div>
