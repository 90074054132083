import { DbDependentComponent } from '../../../../common-modules/db-connection/db-dependent.component';
import { getPSDSelectData, IPSDSelectorData } from '../../../../+store/psd-analysis/psd-analysis.selectors';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, NgModule } from '@angular/core';
import { Store } from '@ngrx/store';
import { notEmpty } from '@dunefront/common/common/state.helpers';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { selectPSDAction } from '../../../../+store/psd-analysis/psd-analysis.actions';
import { Observable } from 'rxjs';
import { SelectModule } from '../../../../shared/components/form-components/select/select.module';

@Component({
  selector: 'app-psd-selector',
  templateUrl: './psd-selector.component.html',
  styleUrls: ['./psd-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PsdSelectorComponent extends DbDependentComponent {
  @Input() public disabled = false;

  public psdData$: Observable<IPSDSelectorData> = notEmpty(this.store.select(getPSDSelectData));

  constructor(store: Store, cdRef: ChangeDetectorRef) {
    super(store, cdRef);
  }

  public changePSD(psdId: number): void {
    this.store.dispatch(selectPSDAction({ psdId }));
  }
}

@NgModule({
  imports: [CommonModule, FormsModule, SelectModule],
  declarations: [PsdSelectorComponent],
  exports: [PsdSelectorComponent],
})
export class PsdSelectorModule {}
