import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { IAxisStyle } from '@dunefront/common/modules/reporting/dto/chart-axis-property.dto';
import { ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';
import { presetFontSizeItems } from '../../../chart/chart.helpers';

@Component({
  selector: 'app-chart-axis-style',
  templateUrl: './chart-axis-style.component.html',
  styleUrls: ['./chart-axis-style.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartAxisStyleComponent {
  @Input() public axisStyle!: IAxisStyle;
  @Input() public defaultAxisStyle?: IAxisStyle;
  @Input() public disabled = false;
  @Output() public valueChanged = new EventEmitter<ObjectChangeProp<IAxisStyle>>();

  public UnitType = UnitSystem;
  public readonly fontSizeSelect = presetFontSizeItems;
}
