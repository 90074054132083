<ng-container *ngIf="isVisible">
  <div class="dev-panel" *ngIf="connectionStatus$ | async as connStatus">
    <div>backend:&nbsp;{{ connStatus.backendConnectionStatus }}</div>
    <div>db:&nbsp;{{ connStatus.dbConnectionStatus }}</div>
    <div>pod: {{ aboutServer.serverInfo?.podName }}</div>
    <div [style]="connStatus.backendConnectionActiveRequests.length ? 'background-color:yellow' : ''">
      requests:&nbsp;<span>{{ connStatus.backendConnectionActiveRequests.length }}</span>
    </div>
    <div *ngIf="redisStatus$ | async as redisStatus">redis: {{ redisStatus.status }}, jobs: {{ redisStatus.runningJobs.length }}</div>
    licenses: {{ licenseFeatures$ | async }}
  </div>
</ng-container>
