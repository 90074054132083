import { FileSettingsModuleState } from './file-settings.reducer';
import { IDbConnectionOpenedResponse } from '@dunefront/common/modules/db-connection/db-connection.actions';
import { CrudResponse } from '@dunefront/common/modules/common.actions';

export class FileSettingsReducerHelper {
  public static fileSettingsModuleLoaded(
    state: FileSettingsModuleState,
    response: { payload: IDbConnectionOpenedResponse; scenarioId: number },
  ): FileSettingsModuleState {
    if (!response.payload.fileSettings) {
      return state;
    }
    return { ...state, fileSettings: response.payload.fileSettings, isLoaded: true };
  }

  public static updateFileSettingsSuccess(state: FileSettingsModuleState, action: CrudResponse): FileSettingsModuleState {
    if (!action.affectedRows.fileSettings) {
      return state;
    }
    return { ...state, fileSettings: action.affectedRows.fileSettings };
  }
}
