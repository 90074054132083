import { ArrayHelpers } from './array-helpers';

export interface ISelectItem<T> {
  value: T;
  text: string;
  group?: string;
  isInvalid?: boolean;
  additionalText?: string;
  disabled?: boolean;
}

export const toSelectItem = <T>(
  id: T,
  text: string,
  group?: string,
  isInvalid?: boolean,
  additionalText?: string,
  disabled = false,
): ISelectItem<T> => ({
  value: id,
  text,
  group,
  isInvalid,
  additionalText,
  disabled,
});

export interface MultiselectItem {
  label: string;
  disabled: boolean;
}

export const getSelectItemValue = <T>(id: T, selectItem: ISelectItem<T>[]): string => {
  const ld = selectItem.find((item) => item.value === id);
  return ld ? ld.text : '';
};

export const sortSelectItem = <T>(a: ISelectItem<T>, b: ISelectItem<T>): number => {
  return ArrayHelpers.stringCompare(a.text, b.text);
};

export const sortNumberSelectItem = <T>(a: ISelectItem<T>, b: ISelectItem<T>): number => {
  return Number(a.text) - Number(b.text);
};

// map list of strings to list of objects, which are required for primeNG select search to work
export const flatArrayToSelectObject = (arr: Array<string>): MultiselectItem[] => arr.map((label) => ({ label, disabled: false }));
