import { NgModule } from '@angular/core';
import { ElectronService } from './electron.service';
import { ElectronServiceRef } from './electron.service.ref';

@NgModule({
  declarations: [],
  exports: [],
  providers: [{ provide: ElectronService, useClass: ElectronServiceRef }],
})
export class ElectronModule {}
