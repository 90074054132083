<app-page-panel header="Packing Tube Properties">
  <div class="grid-form-container" *ngIf="shuntTube" [class.show-shape-rectangle]="isRectangle" [class.show-shape-round]="!isRectangle">
    <app-form-input
      appUnwrap
      label="No. of Tubes"
      [source]="shuntTube.PackingTube"
      key="NumberOfTubes"
      [decimalPlaces]="0"
      [unitType]="UnitType.None"
      (valueChanged)="onValueChange($event)"
    >
    </app-form-input>

    <app-form-input
      [cssClass]="'shape-rectangle'"
      appUnwrap
      class="shape-rectangle"
      label="Width"
      [source]="shuntTube.PackingTube"
      key="Width"
      [decimalPlaces]="3"
      [unitType]="UnitType.Short_Length"
      (valueChanged)="onValueChange($event)"
    >
    </app-form-input>

    <app-form-input
      appUnwrap
      [cssClass]="'shape-rectangle'"
      label="Height"
      [source]="shuntTube.PackingTube"
      key="Height"
      [decimalPlaces]="3"
      [unitType]="UnitType.Short_Length"
      (valueChanged)="onValueChange($event)"
    >
    </app-form-input>

    <app-form-input
      appUnwrap
      [cssClass]="'shape-rectangle'"
      label="Thickness"
      [source]="shuntTube.PackingTube"
      key="Thickness"
      [decimalPlaces]="3"
      [unitType]="UnitType.Short_Length"
      (valueChanged)="onValueChange($event)"
    >
    </app-form-input>

    <app-form-input
      [cssClass]="'shape-round'"
      appUnwrap
      label="OD"
      [source]="shuntTube.PackingTube"
      key="OuterDiameter"
      [decimalPlaces]="3"
      [unitType]="UnitType.Diameter"
      (valueChanged)="onValueChange($event)"
    >
    </app-form-input>

    <app-form-input
      appUnwrap
      [cssClass]="'shape-round'"
      label="ID"
      [source]="shuntTube.PackingTube"
      key="InnerDiameter"
      [decimalPlaces]="3"
      [unitType]="UnitType.Diameter"
      (valueChanged)="onValueChange($event)"
    >
    </app-form-input>

    <app-form-input
      appUnwrap
      label="Burst Rating"
      [source]="shuntTube.PackingTube"
      key="BurstRating"
      [decimalPlaces]="2"
      [unitType]="UnitType.Pressure"
      (valueChanged)="onValueChange($event)"
    >
    </app-form-input>

    <app-form-input
      appUnwrap
      label="Collapse Rating"
      [source]="shuntTube.PackingTube"
      key="CollapseRating"
      [decimalPlaces]="2"
      [unitType]="UnitType.Pressure"
      (valueChanged)="onValueChange($event)"
    >
    </app-form-input>
  </div>
</app-page-panel>
