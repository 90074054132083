import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { PanelButtonComponent } from '../../panel.button.component';
import { createSelector, Store } from '@ngrx/store';
import { notEmpty } from '@dunefront/common/common/state.helpers';
import { getRangeMenuProps, RangeMenuProps } from '../../../../../+store/menu-selectors/analysis/menu-analysis.selector';
import { Observable } from 'rxjs';
import { ISelectItem } from '@dunefront/common/common/select.helpers';
import {
  getCurrentRange,
  getEditingRangeState,
  getRangeByCurrentRangeId,
  getRangeSelectData,
} from '../../../../../+store/range/range.selectors';
import { getCurrentScenario } from '../../../../../+store/scenario/scenario.selectors';
import { updateScenariosAction } from '../../../../../+store/scenario/scenario.actions';
import { Router } from '@angular/router';
import {
  cancelEditRangeAction,
  deleteRangeAction,
  deleteRangeDataAction,
  editRangeAction,
  newRangeAction,
  saveRangeAction,
  updateEditRangeAction,
} from '../../../../../+store/range/range.actions';
import { RangeConstants, RangeDto } from '@dunefront/common/dto/range.dto';
import { ModalService } from '../../../../modals/modal.service';
import { getRangeValidation, IGaugeDataRangeValidation } from '../../../../../+store/range/range-validation.selectors';
import { StoreCrudPropsFactory } from '@dunefront/common/common/common-store-crud.interfaces';
import { getIsProjectCalculationActive } from '../../../../../+store/ui/calc-engine-ui.selectors';
import { Scenario } from '@dunefront/common/modules/scenario/scenario';

export const shouldDeleteResults$ = createSelector(getRangeByCurrentRangeId, getEditingRangeState, (currRange, editRange): boolean => {
  if (currRange == null || editRange.currentRange == null) {
    return true;
  }

  if (editRange.currentRange.Id === RangeConstants.NewRangeId) {
    // this means it's creating a new range
    return false;
  }

  return currRange.RangeEnd !== editRange.currentRange.RangeEnd || currRange.RangeStart !== editRange.currentRange.RangeStart;
});

@Component({
  selector: 'app-range-management',
  templateUrl: './range-management.component.html',
  styleUrls: ['./range-management.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RangeManagementComponent extends PanelButtonComponent implements OnDestroy {
  public rangeMenuProps!: RangeMenuProps;
  public selectRangeData$: Observable<ISelectItem<number>[]> = this.store.select(getRangeSelectData);
  public isCalcEngineActive$: Observable<boolean> = this.store.select(getIsProjectCalculationActive);
  public shouldDeleteResults$: Observable<boolean> = this.store.select(shouldDeleteResults$);

  public scenario!: Scenario;
  public currentRange?: RangeDto;
  public gaugeDataRangeValidation!: IGaugeDataRangeValidation;

  public get currentRangeName(): string {
    return this.currentRange?.Name ?? '';
  }

  constructor(
    protected override store: Store,
    protected cdRef: ChangeDetectorRef,
    public router: Router,
    protected modalService: ModalService,
  ) {
    super(store);

    this.subscription.add(
      notEmpty(store.select(getRangeMenuProps)).subscribe((rangeMenuProps) => {
        this.rangeMenuProps = rangeMenuProps;
        this.cdRef.markForCheck();
      }),
    );

    this.subscription.add(
      this.store.select(getCurrentScenario).subscribe((scenario) => {
        if (scenario) {
          this.scenario = scenario;
        }
        this.cdRef.markForCheck();
      }),
    );

    this.subscription.add(
      this.store.select(getCurrentRange).subscribe((range) => {
        this.currentRange = range;

        this.cdRef.markForCheck();
      }),
    );

    this.subscription.add(
      this.store.select(getRangeValidation).subscribe((gaugeDataRangeValidation) => {
        this.gaugeDataRangeValidation = gaugeDataRangeValidation;
        this.cdRef.markForCheck();
      }),
    );
  }

  public onRangeChanged(rangeId: number): void {
    this.store.dispatch(
      updateScenariosAction({
        scenarios: [{ ...this.scenario, CurrentRangeId: rangeId }],
        changedKey: 'CurrentRangeId',
      }),
    );
  }

  public onAddRange(): void {
    this.store.dispatch(newRangeAction({ scenarioId: this.scenario.Id }));
  }

  public onSaveRange(): void {
    if (!this.currentRange) {
      return;
    }

    if (this.currentRange.Name.trim() === '') {
      this.modalService.showAlert('Please specify a range name.', 'Warning').then();
      return;
    }

    if (!this.gaugeDataRangeValidation.isNameUnique) {
      this.modalService.showAlert('Range name already exists - please use a different one.', 'Warning').then();
      return;
    }

    if (this.currentRange.RangeStart >= this.currentRange.RangeEnd) {
      this.modalService.showAlert('Range end must be greater than range start.', 'Warning').then();
      return;
    }

    if (!this.gaugeDataRangeValidation.isDataInRange) {
      this.modalService
        .showAlert(
          'Chart must contain data for the specified range - please update range start/end or chart configuration accordingly.',
          'Warning',
        )
        .then();
      return;
    }

    this.store.dispatch(saveRangeAction({ rows: [this.currentRange], shouldResetResults: true }));
  }

  public onEditRange(): void {
    this.store.dispatch(editRangeAction());
  }

  public async onDeleteRange(): Promise<void> {
    if (!this.currentRange) {
      return;
    }

    if (await this.modalService.showConfirm('Are you sure want to delete the current range?', 'Information')) {
      this.store.dispatch(deleteRangeAction(StoreCrudPropsFactory.deleteRows(this.currentRange, true)));
    }
  }

  public async onDeleteData(): Promise<void> {
    if (!this.currentRange) {
      return;
    }

    if (await this.modalService.showConfirmNoUndoable('Are you sure you want to delete the data of the current range?', 'Information')) {
      this.store.dispatch(deleteRangeDataAction({ range: this.currentRange }));
    }
  }

  public onRangeNameChanged(name: string): void {
    if (!this.currentRange) {
      return;
    }

    if (name !== this.currentRange.Name) {
      this.store.dispatch(updateEditRangeAction({ range: { ...this.currentRange, Name: name } }));
    }
  }

  public onLabelsUpdated(selectedLabels: string): void {
    if (!this.currentRange) {
      return;
    }

    if (selectedLabels !== this.currentRange.Labels) {
      this.store.dispatch(updateEditRangeAction({ range: { ...this.currentRange, Labels: selectedLabels } }));
    }
  }

  public onCancel(): void {
    this.store.dispatch(cancelEditRangeAction());
  }
}
