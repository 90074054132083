import { HeadingLevel, Paragraph, Table } from 'docx';
import { IUnitSystemDto } from '@dunefront/common/dto/unit-system.dto';
import { DocumentGeneratorHelper } from '../document-generator.helper';
import { DocumentTableGeneratorHelper } from '../document-table-generator.helper';
import { ImportColumnDto } from '@dunefront/common/modules/data-storage/dto/import-column.dto';
import { Store } from '@ngrx/store';
import { CommonReportDataHelpers } from '../../common/common-report-data-helpers';

export class ImportedDataGeneratorHelper {
  public static async createImportedDataSection(store: Store, currentUnitSystem: IUnitSystemDto): Promise<Array<Paragraph | Table>> {
    const { tableData, enumItemMap } = await CommonReportDataHelpers.createImportedDataTableData(store, currentUnitSystem);

    return [
      DocumentGeneratorHelper.createParagraphHeader('Imported Data Information', HeadingLevel.HEADING_1, 0),
      DocumentTableGeneratorHelper.createTable(tableData, enumItemMap, currentUnitSystem),
    ];
  }
}

export interface IReportImportColumn extends Omit<ImportColumnDto, 'VerticalOffset' | 'disabled' | 'visible'> {
  VerticalOffset: string;
}
