import { DeleteRowsWsAction, InsertRowsWsAction, ReorderRowWsAction, UpdateRowsWsAction, WsAction } from '../../ws.action';
import { IChartDataDto } from './dto/chart-data.dto';
import { ChartAxisPropertyDto } from './dto/chart-axis-property.dto';
import { ReportingTabDto } from '../../dto/reporting-tab.dto';
import { ChartDataSourceType, ChartDto } from './dto/chart.dto';
import { ImportFileDto } from '../data-storage/dto/import-file.dto';
import { DataFileType, DataType } from '../../dto/data-storage';
import { ModuleType } from '../scenario/scenario.dto';
import { ReportingColumnDto } from '../../dto/reporting-column.dto';
import { ImportColumnDto, ImportColumnGroupType } from '../data-storage/dto/import-column.dto';
import { IChartTemplateDto } from '../../dto/chart-templates.dto';
import { ChartSeriesDto } from './dto/chart-series.dto';
import { IUnitSystemDto } from '../../dto/unit-system.dto';
import { ChartSeriesTemplateDto } from '../../dto/chart-series-template.dto';
import { IChartMarkerDto } from './dto/chart-marker.dto';
import { BaseCalculationEngineUpdateAction, ICalculationEngineUpdatePayload } from '../calculation-engine/calculation-engine.actions';
import { ChartAnnotationDto } from './dto/chart-annotation.dto';
import { WsActionPropsFactory } from '../../common/ws-action/ws-action-props.factory';
import { InsertLocation } from '../common.interfaces';
import { DeveloperSettingsDto } from '../settings/dto/settingsDto';
import { ChartGradientLineDto } from './dto/chart-gradient-line.dto';
import { ChartMdTvdMode } from './reporting.settings';

export const ReportingModuleName = 'ReportingModule';

export enum ReportingModuleActionTypes {
  GetTimeBasedChartData = '[ReportingModule] GetTimeBasedChartData',
  GetReportingColumns = '[ReportingModule] GetReportingColumns',
  GetChartUserAddons = '[ReportingModule] GetChartUserAddons',
  GetDepthData = '[ReportingModule] GetDepthData',
  GetDepthDataSinglePoint = '[ReportingModule] GetDepthDataSinglePoint',

  InsertChartMarker = '[ReportingModule] ChartMarker InsertRows',
  UpdateChartMarker = '[ReportingModule] ChartMarker UpdateRows',
  DeleteChartMarker = '[ReportingModule] ChartMarker DeleteRows',

  InsertChartAnnotation = '[ReportingModule] ChartAnnotation InsertRows',
  UpdateChartAnnotation = '[ReportingModule] ChartAnnotation UpdateRows',
  DeleteChartAnnotation = '[ReportingModule] ChartAnnotation DeleteRows',

  InsertChartGradientLine = '[ReportingModule] ChartGradientLine InsertRows',
  UpdateChartGradientLine = '[ReportingModule] ChartGradientLine UpdateRows',
  DeleteChartGradientLine = '[ReportingModule] ChartGradientLine DeleteRows',

  UpsertChartAxisProperty = '[ReportingModule] ChartAxisProperty InsertRows',
  UpdateChartAxisProperty = '[ReportingModule] ChartAxisProperty UpdateRows',

  InsertReportingTab = '[ReportingModule] ReportingTab InsertRows',
  UpdateReportingTab = '[ReportingModule] ReportingTab UpdateRows',

  ReorderReportingTab = '[ReportingModule] ReportingTab Reorder',
  GetSourceReportingTabs = '[ReportingModule] GetSourceReportingTabs',
  GetSourceReportingTabsSuccess = '[ReportingModule] GetSourceReportingTabsSuccess',

  InsertChartSeries = '[ReportingModule] InsertChartSeries',
  UpdateFiles = '[ReportingModule] UpdateFiles',
  StartExportDataJob = '[ReportingModule] StartExportDataJob',
  ExportDataEngineUpdate = '[ReportingModule] ExportDataEngineUpdate',
  ConvertExportDataUnits = '[ReportingModule] ConvertExportDataUnits',

  InsertChart = '[ReportingModule] Chart InsertRows',
  UpdateChart = '[ReportingModule] Chart UpdateRows',
  DeleteChart = '[ReportingModule] Chart DeleteRows',
  CopyReportingChart = '[ReportingModule] ReportingChart Copy',
  CreateChartFromTemplate = '[ReportingModule] CreateChartFromTemplate',

  ChartTemplateLoad = '[ReportingModule] Load Chart Template',
  ChartTemplateSave = '[ReportingModule] Save Chart Template',
  ChartTemplatesDelete = '[ReportingModule] Delete Chart Templates',

  InsertChartSeriesTemplate = '[ReportingModule] ChartSeriesTemplate InsertRows',
  UpdateChartSeriesTemplate = '[ReportingModule] ChartSeriesTemplate UpdateRows',
  DeleteChartSeriesTemplate = '[ReportingModule] ChartSeriesTemplate DeleteRows',
  GetChartSeriesTemplates = '[ReportingModule] GetChartSeriesTemplates',
  LoadTabIdForScenarioChange = '[ReportingModule] LoadTabIdForScenarioChange',

  UpdateChartAxisSelection = '[ReportingModule] ChartAxisSelection UpdateRows',
}

export interface LoadReportingActionResponse {
  reportingTabDtos: ReportingTabDto[];
  chartDtos: ChartDto[];
}

export class GetTimeBasedChartDataAction extends WsAction {
  public override readonly type = ReportingModuleActionTypes.GetTimeBasedChartData;

  constructor(public getTimeBasedChartDataActionRequest: TimeVolChartDataRequestParams) {
    super();
  }
}

export class GetReportingColumnsAction extends WsAction {
  public override readonly type = ReportingModuleActionTypes.GetReportingColumns;

  constructor(public getReportingColumnsActionRequest: GetReportingColumnsActionRequest) {
    super();
  }
}

export class GetChartUserAddonsAction extends WsAction {
  public override readonly type = ReportingModuleActionTypes.GetChartUserAddons;

  constructor(
    public scenarioId: number,
    public chartId: number,
  ) {
    super();
  }
}

export class GetDepthDataResultsAction extends WsAction {
  public override readonly type = ReportingModuleActionTypes.GetDepthData;

  constructor(public depthDataKey: ResultsSourceKey) {
    super();
  }
}

export class GetDepthDataForSinglePointAction extends WsAction {
  public override readonly type = ReportingModuleActionTypes.GetDepthDataSinglePoint;

  constructor(public depthDataKeys: ResultsSourceKeyWithArg[]) {
    super();
  }
}

export class InsertChartMarkerAction extends InsertRowsWsAction<IChartMarkerDto> {
  public override readonly type = ReportingModuleActionTypes.InsertChartMarker;

  constructor(dtos: IChartMarkerDto[]) {
    super(WsActionPropsFactory.insertDtos(dtos, false));
  }
}

export class UpdateChartMarkerAction extends UpdateRowsWsAction<IChartMarkerDto> {
  public override readonly type = ReportingModuleActionTypes.UpdateChartMarker;

  constructor(dto: IChartMarkerDto) {
    super(WsActionPropsFactory.update([dto], false));
  }
}

export class DeleteChartMarkerAction extends DeleteRowsWsAction {
  public override readonly type = ReportingModuleActionTypes.DeleteChartMarker;
}

export class InsertChartAnnotationAction extends InsertRowsWsAction<ChartAnnotationDto> {
  public override readonly type = ReportingModuleActionTypes.InsertChartAnnotation;

  constructor(dtos: ChartAnnotationDto[]) {
    super(WsActionPropsFactory.insertDtos(dtos, false));
  }
}

export class UpdateChartAnnotationAction extends UpdateRowsWsAction<ChartAnnotationDto> {
  public override readonly type = ReportingModuleActionTypes.UpdateChartAnnotation;

  constructor(dto: ChartAnnotationDto[]) {
    super(WsActionPropsFactory.update(dto, false));
  }
}

export class DeleteChartAnnotationAction extends DeleteRowsWsAction {
  public override readonly type = ReportingModuleActionTypes.DeleteChartAnnotation;
}

export class InsertChartGradientLineAction extends InsertRowsWsAction<ChartGradientLineDto> {
  public override readonly type = ReportingModuleActionTypes.InsertChartGradientLine;

  constructor(dtos: ChartGradientLineDto[]) {
    super(WsActionPropsFactory.insertDtos(dtos, false));
  }
}

export class UpdateChartGradientLineAction extends UpdateRowsWsAction<ChartGradientLineDto> {
  public override readonly type = ReportingModuleActionTypes.UpdateChartGradientLine;

  constructor(dto: ChartGradientLineDto[]) {
    super(WsActionPropsFactory.update(dto, false));
  }
}

export class DeleteChartGradientLineAction extends DeleteRowsWsAction {
  public override readonly type = ReportingModuleActionTypes.DeleteChartGradientLine;
}

export interface ChartAxisPropertyActionConversionProps {
  secondaryArg: boolean;
  resultsSourceKey: ResultsSourceKey;
}

export class UpsertChartAxisPropertyAction extends InsertRowsWsAction<ChartAxisPropertyDto> {
  public override readonly type = ReportingModuleActionTypes.UpsertChartAxisProperty;

  constructor(
    dtos: ChartAxisPropertyDto[],
    public readonly conversionProps?: ChartAxisPropertyActionConversionProps,
  ) {
    super(WsActionPropsFactory.insertDtos(dtos, false));
  }
}

export class UpdateChartAxisPropertyAction extends UpdateRowsWsAction<ChartAxisPropertyDto> {
  public override readonly type = ReportingModuleActionTypes.UpdateChartAxisProperty;

  constructor(
    dtos: ChartAxisPropertyDto[],
    public readonly conversionProps?: ChartAxisPropertyActionConversionProps,
  ) {
    super(WsActionPropsFactory.update(dtos, false, []));
  }
}

export class InsertReportingTabAction extends InsertRowsWsAction<ReportingTabDto> {
  public override readonly type = ReportingModuleActionTypes.InsertReportingTab;

  constructor(dtos: ReportingTabDto[], insertLocation?: InsertLocation, refId?: number) {
    super(WsActionPropsFactory.insertDtos(dtos, false, insertLocation, refId));
  }
}

export class CopyReportingTabAction extends WsAction {
  public override readonly type = ReportingModuleActionTypes.CopyReportingChart;

  constructor(public reportingTabId: number) {
    super();
  }
}

export class UpdateReportingTabAction extends UpdateRowsWsAction<ReportingTabDto> {
  public override readonly type = ReportingModuleActionTypes.UpdateReportingTab;

  constructor(dto: ReportingTabDto) {
    super(WsActionPropsFactory.update([dto], false));
  }
}

export class ReorderReportingTabAction extends ReorderRowWsAction<ReportingTabDto> {
  public override readonly type = ReportingModuleActionTypes.ReorderReportingTab;
}

export class GetSourceReportingTabAction extends WsAction {
  public override readonly type = ReportingModuleActionTypes.GetSourceReportingTabs;

  constructor(public scenarioId: number) {
    super();
  }
}

export class InsertChartSeriesAction extends InsertRowsWsAction<ChartSeriesDto> {
  public override readonly type = ReportingModuleActionTypes.InsertChartSeries;

  constructor(dtos: ChartSeriesDto[]) {
    super(WsActionPropsFactory.insertDtos(dtos, false));
  }
}

export class ReportingModuleUpdateFilesAction extends UpdateRowsWsAction<ImportFileDto> {
  public override readonly type = ReportingModuleActionTypes.UpdateFiles;
}

export class ReportingModuleStartExportDataJobAction extends WsAction {
  public override readonly type = ReportingModuleActionTypes.StartExportDataJob;

  constructor(
    public exportDataActionRequest: ExportDataStartJobActionPayload,
    public developerSettings: DeveloperSettingsDto,
    taskId: string,
  ) {
    super();
    this.taskId = taskId;
  }
}

export class ReportingModuleConvertExportDataUnitsAction extends WsAction {
  public override readonly type = ReportingModuleActionTypes.ConvertExportDataUnits;

  constructor(
    public override readonly taskId: string,
    public override readonly payload: ExportDataConvertUnitsActionPayload,
  ) {
    super();
  }
}

export class ChartTemplateLoadAction extends WsAction {
  public override readonly type = ReportingModuleActionTypes.ChartTemplateLoad;
}

export class ChartTemplateSaveAction extends WsAction {
  public override readonly type = ReportingModuleActionTypes.ChartTemplateSave;

  constructor(public dto: IChartTemplateDto) {
    super();
  }
}

export class ChartTemplatesDeleteAction extends WsAction {
  public override readonly type = ReportingModuleActionTypes.ChartTemplatesDelete;

  constructor(public templatesIds: string[]) {
    super();
  }
}

export class CreateChartFromTemplateAction extends WsAction {
  public override readonly type = ReportingModuleActionTypes.CreateChartFromTemplate;

  constructor(public override payload: CreateChartFromTemplateActionRequest) {
    super();
  }
}

export class InsertChartAction extends InsertRowsWsAction<ChartDto> {
  public override readonly type = ReportingModuleActionTypes.InsertChart;

  constructor(dto: ChartDto) {
    super(WsActionPropsFactory.insertDto(dto, false));
  }
}

export class UpdateChartAction extends UpdateRowsWsAction<ChartDto> {
  public override readonly type = ReportingModuleActionTypes.UpdateChart;
}

export class DeleteChartAction extends DeleteRowsWsAction {
  public override readonly type = ReportingModuleActionTypes.DeleteChart;
}

export class InsertChartSeriesTemplateAction extends InsertRowsWsAction<ChartSeriesTemplateDto> {
  public override readonly type = ReportingModuleActionTypes.InsertChartSeriesTemplate;
}

export class UpdateChartSeriesTemplateAction extends UpdateRowsWsAction<ChartSeriesTemplateDto> {
  public override readonly type = ReportingModuleActionTypes.UpdateChartSeriesTemplate;
}

export class DeleteChartSeriesTemplateAction extends DeleteRowsWsAction {
  public override readonly type = ReportingModuleActionTypes.DeleteChartSeriesTemplate;
}

export class GetChartSeriesTemplatesAction extends WsAction {
  public override readonly type = ReportingModuleActionTypes.GetChartSeriesTemplates;
}

export class UpdateChartAxisSelectionAction extends WsAction {
  public override readonly type = ReportingModuleActionTypes.UpdateChartAxisSelection;

  constructor(
    public readonly chartIds?: number[],
    public readonly rangeId?: number,
    public readonly scenarioId?: number,
    public readonly chartTimeVolMode?: ChartTimeVolMode,
    public readonly chartMdTvdMode?: ChartMdTvdMode,
    public readonly optimizeMode?: boolean,
    public readonly compareScenarioMode?: boolean,
  ) {
    super();
  }
}

export class LoadTabIdForScenarioChangeAction extends WsAction {
  constructor(
    public readonly moduleType: ModuleType,
    public readonly tabIndex: number,
    public readonly scenarioId: number,
    public readonly rangeId: number,
  ) {
    super();
  }

  public override readonly type = ReportingModuleActionTypes.LoadTabIdForScenarioChange;
}

export enum GetChartDataRequestType {
  Initial = 0,
  Zoomed = 1,
}

export interface GetChartDataParams {
  dataSourceType: ChartDataSourceType;
  timeVolMode: ChartTimeVolMode;
  requestType: GetChartDataRequestType;
  moduleType: ModuleType;
  rangeId: number;
  chartId: number;

  argumentStart?: number;
  argumentEnd?: number;
  isPrimaryArgument?: boolean;
  isPrimaryArgumentRelative?: boolean;

  isOptimizeEvaluationChart: boolean;
}

export interface TimeVolChartDataRequestParams extends GetChartDataParams {
  scenarioId: number;
  structureOnly: boolean;
  compareScenarioIds: number[];
  maxPoints: number;
}

export interface GetReportingColumnsActionRequest {
  chartId?: number;
  chartType: GetReportingColumnsChartType;
  moduleType: ModuleType;
  scenarioId: number;
  rangeId: number;
}

export interface ExportDataStartJobActionPayload {
  scenarioId: number;
  rangeId: number;
  fileType: DataFileType;
  moduleType: ModuleType;
  useTimestamps: boolean;
  exportFilePath?: string;
}

export interface ExportDataConvertUnitsActionPayload extends ExportDataStartJobActionPayload {
  unitSystem: IUnitSystemDto;
}

export interface CreateChartFromTemplateActionRequest {
  chartTemplate: IChartTemplateDto;
  chartAxisProperties: ChartAxisPropertyDto[];
  reportingTab: ReportingTabDto;
  useImportData: boolean;
}

export enum ChartTimeVolMode {
  time = 0,
  volume = 1,
}

export type GetReportingColumnsChartType = 'timevol' | 'mdtvd' | 'gaugedata';

export interface TimeVolChartDataResponse {
  chartDataSourceType: ChartDataSourceType;
  chartDataDto: IChartDataDto;
  requestType: GetChartDataRequestType;
  chartSeries?: ChartSeriesDto[];
  timeVolMode: ChartTimeVolMode;
}

export interface GetReportingColumnsActionResponse {
  chartId?: number;
  resultColumns?: ReportingColumnDto[];
  importFiles?: ImportFileDto[];
  importColumns?: ImportColumnDto[];
  chartSeries: ChartSeriesDto[];
}

export interface GetChartUserAddonsResultsActionResponse {
  chartId: number;
  chartMarkers: IChartMarkerDto[];
  chartAnnotations: ChartAnnotationDto[];
  chartGradientLines: ChartGradientLineDto[];
  chartAxisProperties: ChartAxisPropertyDto[];
}

export interface GetDepthDataResultsActionResponse {
  DepthResults: IDepthBasedResult[];
  AllDataPoints: ITimeVolDataPoint[];
  LoadedDataPoints: number[];
  SimulationDuration: number;
}

export interface IDepthBasedResult {
  FileType: DataFileType;
  Columns: IDepthBasedColumn[];
  Times: IDepthBasedTime[];
  ScenarioId: number;
  RangeId: number;
}

export interface IDepthBasedColumn {
  ColumnName: string;
  DataType: DataType;
  GroupType: ImportColumnGroupType;
}

export interface IDepthBasedTime {
  Time: number;
  Rows: IDepthBasedRow[];
}

export interface ITimeVolDataPoint {
  PumpTime: number;
  PumpVolume: number;
}

export interface IDepthBasedRow {
  RowIndex: number;
  MD: number;
  TVD: number;
  Values: number[][];
}

export interface ResultsSourceKey {
  moduleType: ModuleType;
  scenarioId: number;
  rangeId: number;
  dataFileType?: DataFileType;
}

export interface ResultsSourceKeyWithArg extends ResultsSourceKey {
  argument: number;
  isSecondaryArg?: boolean;
}

export interface ConvertImportDataActionResponse {
  convertImportDataMessage: string;
}

export interface ReportingModuleConvertExportDataUnitsActionResponse {
  filename: string;
}

export class ExportDataEngineUpdateAction extends BaseCalculationEngineUpdateAction<ICalculationEngineUpdatePayload> {
  public override readonly type = ReportingModuleActionTypes.ExportDataEngineUpdate;
}

export type ReportingModuleActions =
  | GetTimeBasedChartDataAction
  | GetReportingColumnsAction
  | GetChartUserAddonsAction
  | GetDepthDataResultsAction
  | GetDepthDataForSinglePointAction
  | InsertChartMarkerAction
  | UpdateChartMarkerAction
  | DeleteChartMarkerAction
  | InsertChartAnnotationAction
  | UpdateChartAnnotationAction
  | DeleteChartAnnotationAction
  | InsertChartGradientLineAction
  | UpdateChartGradientLineAction
  | DeleteChartGradientLineAction
  | UpsertChartAxisPropertyAction
  | UpdateChartAxisPropertyAction
  | InsertReportingTabAction
  | UpdateReportingTabAction
  | ReorderReportingTabAction
  | GetSourceReportingTabAction
  | CopyReportingTabAction
  | InsertChartSeriesAction
  | ReportingModuleUpdateFilesAction
  | ReportingModuleStartExportDataJobAction
  | ReportingModuleConvertExportDataUnitsAction
  | CreateChartFromTemplateAction
  | ChartTemplateLoadAction
  | ChartTemplateSaveAction
  | ChartTemplatesDeleteAction
  | ExportDataEngineUpdateAction
  | InsertChartAction
  | UpdateChartAction
  | DeleteChartAction
  | InsertChartSeriesTemplateAction
  | UpdateChartSeriesTemplateAction
  | DeleteChartSeriesTemplateAction
  | GetChartSeriesTemplatesAction
  | UpdateChartAxisSelectionAction
  | LoadTabIdForScenarioChangeAction;
