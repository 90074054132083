import { BasePptSectionGenerator, PptContext } from '../base-ppt-section-generator';
import { IUnitSystemDto } from '@dunefront/common/dto/unit-system.dto';
import { firstValueFrom } from 'rxjs';
import { selectCurrentUnitSystem } from '../../../../../../+store/units/units.selectors';
import { CommonReportDataHelpers } from '../../common/common-report-data-helpers';
import { PptDocumentTableGeneratorHelper } from '../ppt-document-table-generator.helper';
import { ReportScenarioImages } from '../../doc/sections/chart-report-generator.helper';
import { ConvertUnitPipe } from '@dunefront/common/modules/units/convert-unit.pipe/convert-unit.pipe';
import { PsdReading } from '@dunefront/common/modules/psd-analysis/model/psd-readings/psd-reading';
import { PresetColumnsWidths } from '../../doc/document-table-generator.helper';

export class PsdInputDataPptGenerator extends BasePptSectionGenerator {
  constructor(
    protected override context: PptContext & {
      images: ReportScenarioImages;
      convertUnitPipe: ConvertUnitPipe;
    },
  ) {
    super(context);
  }

  public async generate(): Promise<void> {
    const { store, images, convertUnitPipe } = this.context;
    const currentUnitSystem: IUnitSystemDto = await firstValueFrom(this.store.select(selectCurrentUnitSystem));

    const psdInputDataArray = await CommonReportDataHelpers.createPsdInputData(store, currentUnitSystem, convertUnitPipe, images);

    for (const { psdName, psdDefinitionTableData, psdPropertiesTableData, psdReadingTableData, chartData } of psdInputDataArray) {
      // PSD Definition
      {
        const tableRows = PptDocumentTableGeneratorHelper.createGenericKeyValueTableRowsWithTableData(
          psdDefinitionTableData,
          currentUnitSystem,
        );
        this.addKeyValueTableSlide({ title: `${psdName} - PSD Definition`, tableRows });
      }

      //  PSD Test Data
      {
        const tableRows = PptDocumentTableGeneratorHelper.createTableRows<PsdReading>(psdReadingTableData, null, currentUnitSystem);
        const presetColumnsWidths: PresetColumnsWidths = { 2: 15, 3: 25 };
        this.addTableSlide({ title: `${psdName} - PSD Test Data`, tableRows, presetColumnsWidths });
      }

      // PSD Chart
      {
        this.addImageSlide({ title: `${psdName} - PSD Chart`, image: chartData });
      }

      // PSD Properties
      {
        const tableRows = PptDocumentTableGeneratorHelper.createGenericKeyValueTableRowsWithTableData(
          psdPropertiesTableData,
          currentUnitSystem,
        );
        this.addKeyValueTableSlide({ title: `${psdName} - PSD Properties`, tableRows });
      }
    }
  }
}
