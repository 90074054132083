import { IChartUserAddons } from '../../../+store/reporting/reporting-module.state';
import { DictionaryWithArray, IDictionaryWithArray } from '@dunefront/common/common/state.helpers';
import { PrimarySecondaryArgumentConverter } from '@dunefront/common/modules/reporting/dto/primary-secondary-argument-converter';
import { IChartDataDto } from '@dunefront/common/modules/reporting/dto/chart-data.dto';

export type ArgConverter = ((value: number) => number) | undefined;

export interface ChartAddonsWithConverter {
  chartUserAddons: IChartUserAddons | undefined;
  toSecondaryArgConverter: ArgConverter;
  toPrimaryArgConverter: ArgConverter;
  isSecondaryArgumentBased: boolean;
  chartId: number;
}

export const toChartAddonsWithConverter = (
  chartUserAddonsDict: IDictionaryWithArray<IChartUserAddons>,
  chartData: IChartDataDto,
): ChartAddonsWithConverter => {
  const chartId = chartData.ChartId;
  const isSecondaryArgumentBased = !chartData.IsPrimaryArgument;
  const addons = DictionaryWithArray.get(chartUserAddonsDict, chartData.ChartId);
  const shouldConvert = PrimarySecondaryArgumentConverter.shouldConvert(chartData);

  const toSecondaryArgConverter = shouldConvert
    ? (value: number): number => PrimarySecondaryArgumentConverter.toSecondaryArgIfNeeded(chartData, value)
    : undefined;

  const toPrimaryArgConverter = shouldConvert
    ? (value: number): number => PrimarySecondaryArgumentConverter.toPrimaryArgIfNeeded(chartData, value)
    : undefined;

  return {
    chartUserAddons: addons,
    toSecondaryArgConverter,
    toPrimaryArgConverter,
    chartId,
    isSecondaryArgumentBased,
  };
};
