<p>Report Test</p>

<table>
  <tr>
    <td>Unit ID</td>
    <td>Unit Name</td>
    <td>Convert 1</td>
    <td>Series Name</td>
    <td>Series Symbol</td>
    <td>Log Scale</td>
    <td>Decimal Places</td>
    <td>Step Series</td>
  </tr>
  <tr *ngFor="let chartUnitDescription of ChartUnitDescriptions">
    <td>
      {{ chartUnitDescription.dataType }}
    </td>
    <td>
      {{ chartUnitDescription.unitName }}
    </td>
    <td>
      {{
        ChartUnitConverter.getUnitTypeAndName(chartUnitDescription.dataType, chartUnitDescription.unitSystem, currentUnitSystem).AxisName
      }}
    </td>
    <td>
      {{
        ChartUnitConverter.getUnitTypeAndName(chartUnitDescription.dataType, chartUnitDescription.unitSystem, currentUnitSystem).AxisSymbol
      }}
    </td>
    <td>
      {{
        ChartUnitConverter.getUnitTypeAndName(chartUnitDescription.dataType, chartUnitDescription.unitSystem, currentUnitSystem).Logarithmic
      }}
    </td>
    <td>
      {{
        ChartUnitConverter.getUnitTypeAndName(chartUnitDescription.dataType, chartUnitDescription.unitSystem, currentUnitSystem)
          .DecimalPlaces
      }}
    </td>
    <td>
      {{
        ChartUnitConverter.getUnitTypeAndName(chartUnitDescription.dataType, chartUnitDescription.unitSystem, currentUnitSystem)
          .IsStepSeries
      }}
    </td>
  </tr>
</table>
