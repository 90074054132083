import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { NozzleProperties, ShuntTube } from '@dunefront/common/modules/shunt-tube/shunt-tube';
import { changeProp, getChangeProp, ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';

@Component({
  selector: 'app-nozzle-properties',
  templateUrl: './nozzle-properties.component.html',
  styleUrls: ['./nozzle-properties.component.scss'],
})
export class NozzlePropertiesComponent {
  @Input() public shuntTube!: ShuntTube;

  @Input() public isTransportTubePresent!: boolean;

  @Output() public shuntTubeValueChange = new EventEmitter<ObjectChangeProp<ShuntTube>>();

  public UnitType = UnitSystem;

  public onValueChange(props: ObjectChangeProp<NozzleProperties>): void {
    const shuntTubeProp: ObjectChangeProp<ShuntTube> = getChangeProp(
      'NozzleProperties',
      changeProp(this.shuntTube.NozzleProperties, props),
      props.shouldResetResults,
    );
    this.shuntTubeValueChange.emit(shuntTubeProp);
  }
}
