import { ColumnType } from '../../../../components/grid/grid.interfaces';
import { ITableData } from './common-report-data-helpers';

export abstract class TableGeneratorHelperBase {
  protected static hideDescriptionIfNeeded<T>(tableData: ITableData<T>): ITableData<T> {
    const descriptionColumnIndex = tableData.dataCells.findIndex((cell) => cell.colId === 'Description' && cell.type === ColumnType.text);
    const areThereAnyDescriptions =
      descriptionColumnIndex >= 0 && tableData.dataRows.some((row) => row.Description && row.Description.length > 0);
    if (!areThereAnyDescriptions) {
      tableData.dataCells = tableData.dataCells.filter((cell, index) => index != descriptionColumnIndex);
    }
    return tableData;
  }
}
