import { ChartTimeVolMode } from '../modules/reporting/reporting-module.actions';
import { ChartMdTvdMode, ChartZoomMode, CrosshairMode, TooltipPosition } from '../modules/reporting/reporting.settings';
import { LicenseFeature } from '../modules/licensing/licensing.interfaces';

export interface ICustomSettingsDto {
  Id: CustomSettingsType;
  Settings: IPossibleCustomSettings;
}

export enum CustomSettingsType {
  Pin = 1,
  ExportData = 2,
  Unit = 3,
  AxisSelection = 4,
  Zoom = 5,
  Crosshair = 6,
  Notifications = 8,
  Licensing = 9,
}

export interface IPinSettings {
  isSideNavPinned?: boolean;
  isHelpPinned?: boolean;
}

export interface IExportDataSettings {
  shouldUseTimestamps: boolean;
}

export interface IUnitSettings {
  unitSystemId: number;
}

export interface IAxisSelectionSettings {
  timeVolMode: ChartTimeVolMode;
  mdTvdMode: ChartMdTvdMode;
}

export interface IZoomSettings {
  zoomMode: ChartZoomMode;
}

export interface ICrosshairSettings {
  crosshairMode: CrosshairMode;
  tooltipPosition: TooltipPosition;
  maxSeriesInTooltip: number;
}
export enum NotificationStatus {
  UNREAD = 0,
  DISMISSED = 1,
  OPENED = 2,
  REMIND_LATER = 3,
}
export interface NotificationState {
  id: string;
  date: Date;
  status: NotificationStatus;
}

export interface ILicensingCustomSettings {
  selectedLicenseAddonFeatures: LicenseFeature[];
}

export type IPossibleCustomSettings =
  | IPinSettings
  | IExportDataSettings
  | IUnitSettings
  | IAxisSelectionSettings
  | IZoomSettings
  | ICrosshairSettings
  | NotificationState[]
  | ILicensingCustomSettings;

export interface ICustomSettings {
  pinSettings: IPinSettings;
  exportDataSettings: IExportDataSettings;
  unitSettings: IUnitSettings;
  axisSelectionSettings: IAxisSelectionSettings;
  zoomSettings: IZoomSettings;
  crosshairSettings: ICrosshairSettings;
  notificationsStatus: NotificationState[];
  licensing: ILicensingCustomSettings;
}
