import { PumpingScheduleDto } from '../../pumping/dto/pumping-schedule.dto';
import {
  WellFluidConversionJobInputDataDeps,
  WellFluidConversionJobInputDataDto,
  WellFluidConversionJobInputDataFactory,
} from './well-fluid-conversion-job-input-data.dto';
import { GravelDto } from '../../gravel/dto/gravel.dto';
import { DictionaryWithArray, IDictionaryWithArray } from '../../../common/state.helpers';
import { Gravel } from '../../gravel/model/gravel';
import { GravelFactory } from '../../gravel/model/gravel.factory';
import { getRowsForCalculations, ITableState } from '../../../common/common-grid.interfaces';
import { PumpingSchedule } from '../../pumping/model/pumping-schedule/pumping-schedule';
import { PumpingScheduleFactory } from '../../pumping/model/pumping-schedule/pumping-schedule.factory';
import { PumpingDirection, ToolPosition } from '../../pumping/dto/pumping.dto';

export interface ReferenceVariableCalculatorInputs extends WellFluidConversionJobInputDataDto {
  AllGravels: GravelDto[];
  IsBlankPackingForGravelRequired: boolean;
  BlankPackingPercentageForGravelRequired: number;
  PumpingSchedule: PumpingScheduleDto[];
  IsWellFluidSelectionSimple: boolean;
  AllGravelIds: number[];
  PumpingDirection: PumpingDirection;
  ToolPosition: ToolPosition;
}

export interface ReferenceVariableCalculatorInputsDeps extends WellFluidConversionJobInputDataDeps {
  pumpingSchedule: ITableState<PumpingSchedule>;
  gravels: IDictionaryWithArray<Gravel>;
}

export class ReferenceVariableCalculatorInputsFactory {
  public static toDto(deps: ReferenceVariableCalculatorInputsDeps): ReferenceVariableCalculatorInputs {
    const { pumping, gravels, pumpingSchedule } = deps;

    const { IsBlankPackingForGravelRequired, BlankPackingPercentageForGravelRequired, IsWellFluidSelectionSimple, PumpingDirection } =
      pumping.pumping;

    const AllGravelIds: number[] = [];
    const Gravels = DictionaryWithArray.getArray(gravels).map((gravel, index) => {
      AllGravelIds.push(gravel.Id);
      return GravelFactory.gravelToDto(gravel);
    });
    const PumpingSchedule = getRowsForCalculations(pumpingSchedule.rows).map((row) => PumpingScheduleFactory.toDto(row));

    const inputData: ReferenceVariableCalculatorInputs = {
      ...WellFluidConversionJobInputDataFactory.toDto(deps),
      IsBlankPackingForGravelRequired,
      BlankPackingPercentageForGravelRequired,
      IsWellFluidSelectionSimple,
      AllGravels: Gravels,
      PumpingSchedule,
      AllGravelIds,
      PumpingDirection,
      ToolPosition: pumping.pumping.ToolPosition,
    };
    return inputData;
  }
}
