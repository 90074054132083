<app-generic-modal [title]="modalTitle" [modalButtonsConfigs]="modalButtonsConfigs">
  <div class="grid-form-container">
    <app-form-input
      appUnwrap
      label="Marker Name"
      [isUiLockable]="false"
      [source]="workingMarker"
      dataCy="chart-marker-dlg--name-input"
      [key]="'name'"
      [isTextInput]="true"
      [unitType]="UnitType.None"
      cssClass="full-width"
      [autoFocus]="true"
      [isStringComparisonStrict]="true"
      (valueChanged)="emitValue($event)"
    ></app-form-input>
    
    <app-radio-group
      [isUiLockable]="false"
      [items]="[
        { value: false, text: 'Default' },
        { value: true, text: 'Custom' }
      ]"
      [source]="workingMarker"
      [key]="'isOverrideStyle'"
      (valueChanged)="emitValue($event)"
      class="grid-two-one"
      id="chart-maker-radio"
      layout="horizontal"
    >
    </app-radio-group>
  </div>

  <app-chart-marker-details
    *ngIf="workingMarker.isOverrideStyle"
    [markerStyle]="workingMarker.style"
    [showHorizontalAlignment]="this.isMarkerHorizontal"
    [showVerticalAlignment]="!this.isMarkerHorizontal"
    (valueChanged)="emitStyleValue($event)"
  ></app-chart-marker-details>
</app-generic-modal>
