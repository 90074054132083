import { Routes } from '@angular/router';
import { SettlingComponent } from './settling.component';
import { SettlingCalculatorComponent } from './settling-calculator/settling-calculator.component';

export const RouteModuleSettling = 'settling';

export const SettlingRoutes: Routes = [
  {
    path: RouteModuleSettling,
    component: SettlingComponent,
    data: {
      breadcrumb: 'Settling Calculator',
    },
    children: [{ path: '', component: SettlingCalculatorComponent }],
  },
];
