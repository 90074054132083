import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { DbDependentComponent } from '../../db-connection/db-dependent.component';
import { FileParserService } from '../import-paste-data-common/file-parser/file-parser.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Store } from '@ngrx/store';
import { ColumnNameGeneratorService } from '../import-paste-data-common/column-name-generator.service';
import { getCurrentScenarioId } from '../../../+store/scenario/scenario.selectors';
import { IFileLoadedArgs } from '../import-data/file-loader/file-loader.component';
import {
  ImportDataColumnDelimiterConfig,
  ImportFileColumnDelimiter,
} from '@dunefront/common/modules/data-storage/dto/import-template/import-template.dto';
import { firstValueFrom } from 'rxjs';
import { ColumnType, IGridColumnConfig } from '../../../shared/components/grid/grid.interfaces';
import { ITableRow } from '@dunefront/common/common/common-grid.interfaces';
import { UnitConverterHelper } from '@dunefront/common/unit-converters/unit.converter.helper';
import { GridConfig } from '../../../shared/components/grid/grid-config';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { IIndexedDataType } from '@dunefront/common/dto/common-dto.interfaces';
import { getCurrentRangeId } from '../../../+store/range/range.selectors';
import { DecimalNumberParser } from '../../units/components/decimal-number-parser';

const LIMIT_OF_LINES_TO_PASTE = 1000;

@Component({
  selector: 'app-paste-data',
  templateUrl: './paste-data.component.html',
  styleUrls: ['./paste-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasteDataComponent<T extends IIndexedDataType> extends DbDependentComponent implements OnDestroy {
  public FileParser = FileParserService;

  public isHelpOpen = false;
  public helpContentUrl = 'assets/help-files/paste-data/paste-data.html';

  public gridConfig!: GridConfig<T>;
  public refRow: ITableRow<T> | undefined;

  public delimiterConfig: ImportDataColumnDelimiterConfig = {
    delimiter: ImportFileColumnDelimiter.Auto,
    customDelimiter: '',
  };

  public columnSelections: { [index: number]: { key: string; unitSystem?: UnitSystem; unit?: number } } = {};
  public rowsToInclude: { [index: number]: boolean } = {};

  private decimalNumberParser = new DecimalNumberParser();

  public get dataError(): string {
    if (this.FileParser.parsedResult.data.length === 0) {
      return 'There is no data to import.';
    } else if (this.FileParser.parsedResult.data.length > LIMIT_OF_LINES_TO_PASTE) {
      return `You have exceeded the limit of ${LIMIT_OF_LINES_TO_PASTE} lines.`;
    }
    return '';
  }

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    store: Store,
    cdRef: ChangeDetectorRef,
    private parser: FileParserService,
    private colNameParser: ColumnNameGeneratorService,
  ) {
    super(store, cdRef);
    this.parser.isPasteMode = true;
    this.gridConfig = config.data.gridConfig;
    this.refRow = config.data.refRow;
    this.resetParsers();
  }

  public async onPastedTextChanged(args: IFileLoadedArgs): Promise<void> {
    this.parser.fileLines = args.data;
    await this.parseFile();
    this.cdRef.markForCheck();
  }

  public async onDelimiterConfigChanged(delimiterConfig: ImportDataColumnDelimiterConfig): Promise<void> {
    this.delimiterConfig = { ...delimiterConfig };
    await this.parseFile();
    this.cdRef.markForCheck();
  }

  public async onImportClick(): Promise<void> {
    if (
      this.FileParser.parsedResult?.data == null ||
      this.FileParser.parsedResult?.data.length === 0 ||
      Object.entries(this.columnSelections).length === 0
    ) {
      return;
    }

    const currentScenarioId = await firstValueFrom(this.store.select(getCurrentScenarioId));
    const currentRangeId = await firstValueFrom(this.store.select(getCurrentRangeId));
    const parsedRowsToInclude = this.FileParser.parsedResult.data.filter((pastedRow, rowIndex) => this.rowsToInclude[rowIndex]);

    const columnConfigs: { [key: string]: IGridColumnConfig<T> } = {};
    const columnIndexes: { [key: string]: number } = {};
    Object.values(this.columnSelections).forEach((columnSelection) => {
      const columnIndex = this.gridConfig.columns.findIndex((column) => column.colId === columnSelection.key);
      if (columnIndex !== -1) {
        columnIndexes[columnSelection.key] = columnIndex;
        columnConfigs[columnSelection.key] = this.gridConfig.columns[columnIndex];
      }
    });

    // First create new table with as many rows as we try to import.
    let newTableRows = this.gridConfig.createDefaultTableRows(currentScenarioId, parsedRowsToInclude.length, currentRangeId, this.refRow);

    // If new table should be smaller then number of pasted rows then ignore excess ones
    parsedRowsToInclude.splice(newTableRows.length);

    // Override columns of a select type first. Do not override if cell is disabled. We want to do it first as some other columns
    // may be disabled based on selections(e.g. Sump Packer disables OD)
    newTableRows = this.updateTableRows(parsedRowsToInclude, columnConfigs, [ColumnType.select], columnIndexes, newTableRows);

    // Override other types of columns. If numeric cells are disabled reset them to 0
    newTableRows = this.updateTableRows(
      parsedRowsToInclude,
      columnConfigs,
      [ColumnType.number, ColumnType.text, ColumnType.checkbox],
      columnIndexes,
      newTableRows,
    );

    this.gridConfig.replaceGridAction(newTableRows);
    this.ref.close();
  }

  private updateTableRows(
    parsedRowsToInclude: string[][],
    columnConfigs: { [key: string]: IGridColumnConfig<T> },
    typesToUpdate: ColumnType[],
    columnIndexes: { [key: string]: number },
    newTableRows: ITableRow<T>[],
  ): ITableRow<T>[] {
    const tryToUseDescriptionForPipeType =
      typesToUpdate.includes(ColumnType.select) && this.gridConfig.columns.some((column) => column.colId === 'PipeType');
    const descriptionColumnIndex = Object.values(this.columnSelections).findIndex((col) => col.key === 'Description');

    parsedRowsToInclude.forEach((pastedRow, rowIndex) => {
      const columnsToOverride: { [key: string]: string | number | undefined } = {};
      Object.entries(this.columnSelections)
        .filter(
          ([, columnSelection]) =>
            columnSelection.key !== 'ignore' &&
            typesToUpdate.includes(columnConfigs[columnSelection.key].type) &&
            !this.gridConfig.isCellDisabled(newTableRows, rowIndex, columnIndexes[columnSelection.key]),
        )
        .forEach(([columnIndex, columnSelection]) => {
          const newValue = this.getNewCellValueFromPastedData(
            columnConfigs[columnSelection.key].type,
            pastedRow[+columnIndex],
            columnSelection.key,
            columnSelection.unitSystem,
            columnSelection.unit,
            columnConfigs[columnSelection.key].lookupDataSourceType,
          );
          if (newValue != null) {
            columnsToOverride[columnSelection.key] = newValue;
          }
        });

      // If PipeType not selected but Description is then try to match based on Description. It needs to be done when
      // checking Select type overrides as they may change cell disabled option
      if (tryToUseDescriptionForPipeType && columnsToOverride['PipeType'] == null && descriptionColumnIndex !== -1) {
        const matchedValue = this.gridConfig.mappingLookupDataByText(pastedRow[+descriptionColumnIndex]);
        if (matchedValue != null) {
          columnsToOverride['PipeType'] = matchedValue;
        }
      }

      newTableRows[rowIndex].rowData = { ...newTableRows[rowIndex].rowData, ...columnsToOverride };
    });
    return newTableRows;
  }

  private getNewCellValueFromPastedData(
    type: ColumnType,
    pastedRowText: string,
    key: string,
    unitSystem?: UnitSystem,
    unit?: number,
    lookupDataSourceType?: string,
  ): string | number | undefined {
    let overrideResult;
    if (type === ColumnType.select) {
      const columnLookupData = this.gridConfig.getLookupDataSource(lookupDataSourceType ?? '');
      const matchedValue = columnLookupData.find((lookup) => lookup.text === pastedRowText)?.value;
      if (matchedValue) {
        overrideResult = matchedValue;
      }

      if (overrideResult == null && key === 'PipeType') {
        const matchedValue = this.gridConfig.mappingLookupDataByText(pastedRowText);
        if (matchedValue != null) {
          overrideResult = matchedValue;
        }
      }
    } else if (type === ColumnType.number && pastedRowText != null) {
      const value = this.decimalNumberParser.parseDecimalNumber(this.decimalNumberParser.translateNumber(pastedRowText));
      if (!isNaN(value)) {
        overrideResult = unitSystem != null && unit != null ? UnitConverterHelper.convertToSiForUnit(unitSystem, unit, value) : value;
      }
    } else if (type === ColumnType.text && pastedRowText) {
      overrideResult = pastedRowText;
    } else if (type === ColumnType.checkbox) {
      overrideResult = pastedRowText != null && pastedRowText.trim() !== '' && pastedRowText.trim() !== 'false' && +pastedRowText !== 0;
    }
    return overrideResult;
  }

  public async onCancelClick(): Promise<void> {
    this.ref.close();
  }

  public async onReverseClick(): Promise<void> {
    this.FileParser.parsedResult = {
      ...this.FileParser.parsedResult,
      data: this.FileParser.parsedResult.data.reverse(),
    };
  }

  public get isImportDisabled(): boolean {
    const filteredColumnSelections = Object.values(this.columnSelections).filter((columnSelection) => columnSelection.key !== 'ignore');
    const isAnyColumnDuplicated = filteredColumnSelections.some(
      (columnSelection) =>
        Object.values(this.columnSelections).filter((columnSelection2) => columnSelection.key === columnSelection2.key).length > 1,
    );
    const isAnyColumnUnitMissing = Object.values(this.columnSelections).some(
      (col) => col.key !== 'ignore' && col.unitSystem != null && col.unitSystem !== UnitSystem.None && col.unit == null,
    );

    return (
      this.dataError !== '' ||
      isAnyColumnDuplicated ||
      isAnyColumnUnitMissing ||
      Object.values(this.rowsToInclude).filter(Boolean).length === 0 ||
      filteredColumnSelections.length === 0
    );
  }

  private resetParsers(): void {
    FileParserService.reset();
    this.colNameParser.resetColNames();
    this.parser.fileLines = [];
  }

  private async parseFile(): Promise<void> {
    if (!this.parser.fileLines.length) {
      return;
    }
    this.colNameParser.resetColNames();
    const columnDelimiter = this.parser.getColumnDelimiter(this.delimiterConfig, this.parser.fileLines.length);

    if (columnDelimiter == null) {
      FileParserService.reset();
      this.cdRef.markForCheck();
      return;
    }

    FileParserService.parsedResult = this.parser.tryReadFileData(
      this.parser.fileLines,
      columnDelimiter,
      true,
      null,
      this.parser.fileLines.length,
    );
  }
}
