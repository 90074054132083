import { createSelector } from '@ngrx/store';
import { getCalcEngineHashesAndJobs } from './calculation-engine.selectors';
import { getJsWorkerHashesAndJobs } from './js-worker-jobs.selectors';
import { ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import { getFileHashFromFile, IFile } from '@dunefront/common/dto/file.dto';
import { isUiBlockingStatus } from './calculation-engine-module.state';
import { JsWorkerActionType } from '@dunefront/common/modules/worker-types/js-workers-common';

export interface IJobTypes {
  calcEngineJobTypes: Set<ModuleType>;
  jsWorkerJobTypes: Set<JsWorkerActionType>;
}

export interface IFileJobTypes extends IJobTypes {
  fileHash: string;
  file: IFile;
}

export const getFilesJobsTypes = createSelector(getCalcEngineHashesAndJobs, getJsWorkerHashesAndJobs, (...[calcEngineJobs, jsWorkerJobs]) => {
  const result: IFileJobTypes[] = [];

  const getOrCreateFileState = (file: IFile): IFileJobTypes => {
    const fileHash = getFileHashFromFile(file);
    const fileState = result.find((f) => f.fileHash === fileHash);
    if (fileState != null) {
      return fileState;
    }

    const newFileState: IFileJobTypes = {
      fileHash,
      file,
      calcEngineJobTypes: new Set(),
      jsWorkerJobTypes: new Set(),
    };
    result.push(newFileState);

    return newFileState;
  };

  for (const fileHash in calcEngineJobs) {
    const jobs = calcEngineJobs[fileHash].jobs.filter((job) => isUiBlockingStatus(job.status));
    for (const { moduleType, dbFile } of jobs) {
      const fileJobsState = getOrCreateFileState(dbFile);
      fileJobsState.calcEngineJobTypes.add(moduleType);
    }
  }

  for (const fileHash in jsWorkerJobs) {
    const { jobs } = jsWorkerJobs[fileHash];
    for (const { actionType, file } of jobs) {
      if (file == null) {
        continue;
      }

      const fileJobsState = getOrCreateFileState(file);
      fileJobsState.jsWorkerJobTypes.add(actionType);
    }
  }

  return result;
});
