import { createSelector } from '@ngrx/store';
import { getValidatedState } from '../../+store/app.selector';
import { getGaugeDataPageValidation } from '../../+store/gauge-data/gauge-data.selectors';

export const getSimulateMenuValidation = createSelector(
  getValidatedState,
  getGaugeDataPageValidation,
  (state, gaugeDataPageValidation): ISimulateMenuValidationResult => {
    const vs = {
      isWellValid: state.wellState.isValid,
      isWellWarning: state.wellState.isWarning ?? false,
      isCompletionValid: state.completionState.isValid,
      isSettingsValid: state.settings.isValid,
      isFluidsValid: state.fluidsState.isValid,
      isGravelsValid: state.gravelState.isValid,
      isPumpingValid:
        state.pumpingState.isInitialScreenValid &&
        state.pumpingState.isWellFluidsScreenValid &&
        state.pumpingState.isScheduleScreenValid &&
        state.pumpingState.isAutomaticSchedulingPanelValid,
    };

    const isValidForStartingSimulation =
      vs.isWellValid && vs.isCompletionValid && vs.isSettingsValid && vs.isFluidsValid && vs.isGravelsValid && vs.isPumpingValid;

    return {
      ...vs,
      isValidForStartingSimulation,
      isGaugeDataPageValid: gaugeDataPageValidation.isValid,
    };
  },
);

export interface ISimulateMenuValidationResult {
  isWellValid: boolean;
  isWellWarning: boolean;
  isCompletionValid: boolean;
  isSettingsValid: boolean;
  isFluidsValid: boolean;
  isGravelsValid: boolean;
  isPumpingValid: boolean;
  isValidForStartingSimulation: boolean;
  isGaugeDataPageValid: boolean;
}
