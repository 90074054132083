export interface PipeDto {
  Id: number;
  ScenarioId: number;
  TopMD: number;
  BottomMD: number;
  InnerDiameter: number;
  OuterDiameter: number;
  PipeType: PipeType;
  Weight: number;
  SortOrder: number;
}

export enum PipeType {
  None = 0,
  Screen = 1,
  Workstring = 2,
  Concentric_Gauge_Carrier = 3,
  Service_Tool = 4,
  Sump_Packer = 5,
  Isolation_Packer = 6,
  Shunted_Screen = 7,
  Eccentric_Gauge_Carrier = 8,
  Blank_Pipe = 9,
  Shunted_Blank_Pipe = 10,
  Washpipe = 11,
  Gravel_Pack_Packer = 12,
  Casing = 13,
  Open_Hole = 14,
  Perforated_Casing = 15,
  Pressure_Attenuator = 16,
  ICD_Screen = 17,
  Choke_Line = 18,
  Treating_Line = 19,
  Riser = 20,
  Isolation_Valve = 21,
  Gravel_Pack_Extension = 22,
  Bull_Nose = 23,
}

export enum CasingPipeType {
  Casing = PipeType.Casing,
  Perforated_Casing = PipeType.Perforated_Casing,
  Open_Hole = PipeType.Open_Hole,
  Riser = PipeType.Riser,
}

export enum LowerCompletionPipeType {
  Blank_Pipe = PipeType.Blank_Pipe,
  Gravel_Pack_Packer = PipeType.Gravel_Pack_Packer,
  Shunted_Blank_Pipe = PipeType.Shunted_Blank_Pipe,
  Screen = PipeType.Screen,
  Shunted_Screen = PipeType.Shunted_Screen,
  ICD_Screen = PipeType.ICD_Screen,
  Isolation_Packer = PipeType.Isolation_Packer,
  Sump_Packer = PipeType.Sump_Packer,
  Isolation_Valve = PipeType.Isolation_Valve,
  Gravel_Pack_Extension = PipeType.Gravel_Pack_Extension,
  Bull_Nose = PipeType.Bull_Nose,
}
