import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DbDependentComponent } from '../../../../common-modules/db-connection/db-dependent.component';
import { Store } from '@ngrx/store';
import { notEmpty } from '@dunefront/common/common/state.helpers';
import { getPSDSelectData, IPSDSelectorData, isCurrentPSDLoadedAndValid } from '../../../../+store/psd-analysis/psd-analysis.selectors';
import { Observable } from 'rxjs';
import { ConvertUnitPipe } from '@dunefront/common/modules/units/convert-unit.pipe/convert-unit.pipe';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import * as uiActions from '../../../../+store/ui/ui.actions';
import { calculatePsdAnalysis } from '../../../../+store/psd-analysis/psd-analysis.actions';
import { filter, take } from 'rxjs/operators';
import { PSDValidation } from '@dunefront/common/modules/psd-analysis/model/psd/psd.validation';
import { PanelHelpMode } from '../../../../shared/components/help-button/help-buton.component';

@Component({
  selector: 'app-psd-chart',
  templateUrl: './psd-chart.component.html',
  styleUrls: ['./psd-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PsdChartComponent extends DbDependentComponent implements OnInit {
  public psdData$: Observable<IPSDSelectorData> = notEmpty(this.store.select(getPSDSelectData));
  public UnitType = UnitSystem;
  public PanelHelpMode = PanelHelpMode;

  constructor(
    store: Store,
    cdRef: ChangeDetectorRef,
    protected convertUnitPipe: ConvertUnitPipe,
  ) {
    super(store, cdRef);
    store.dispatch(uiActions.setPsdAnalysisTabAction({ tab: 'psd-chart' }));
    this.onHelpChange('psd', 'psd-chart');
  }

  public override ngOnInit(): void {
    super.ngOnInit();

    this.store
      .select(isCurrentPSDLoadedAndValid)
      .pipe(
        filter((isValid) => isValid),
        take(1),
      )
      .subscribe(() => this.store.dispatch(calculatePsdAnalysis({ isFullAnalysis: false })));
  }

  public get percentFinesLabel(): string {
    return PSDValidation.getPercentFinesLabel(this.currentUnitSystem, this.convertUnitPipe);
  }
}
