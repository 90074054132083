import { createSelector } from '@ngrx/store';
import { getWellFluidConversionJobInputDataDeps } from '../pumping/selectors/well-fluid-fluid-definition-converter.selectors';
import { getPumpingSchedule } from '../pumping/selectors/pumping.selectors';
import { getValidatedGravels } from '../gravel/gravel.selectors';
import {
  ReferenceVariableCalculatorInputs,
  ReferenceVariableCalculatorInputsDeps,
  ReferenceVariableCalculatorInputsFactory,
} from '@dunefront/common/modules/reference-variables/dto/reference-variable-calculator-inputs.dto';

export const getReferenceVariableCalculatorInputs = createSelector(
  getWellFluidConversionJobInputDataDeps,
  getPumpingSchedule,
  getValidatedGravels,
  (...[wellFluidConversionDeps, pumpingSchedule, gravels]): ReferenceVariableCalculatorInputs => {
    const deps: ReferenceVariableCalculatorInputsDeps = {
      ...wellFluidConversionDeps,
      pumpingSchedule,
      gravels,
    };
    return ReferenceVariableCalculatorInputsFactory.toDto(deps);
  },
);
