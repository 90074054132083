import { Chart, Point } from 'chart.js/dist/types';
import { Rect, rectWithPadding } from '@dunefront/common/common/math-geometry-helpers';
import { annotationRoundedCornerRadius } from './annotations-plugin/constants';

export const getPointFromEvent = (event: MouseEvent): Point => ({ x: event.offsetX, y: event.offsetY });
export const getChartDrawingArea = (chart: Chart): Rect => {
  const { left, top, width, height } = chart.chartArea;

  return { x: left, y: top, width, height };
};
export const getDrawingAreaWithPadding = (chart: Chart): Rect => {
  return rectWithPadding(getChartDrawingArea(chart), annotationRoundedCornerRadius / 2 + 2);
};
