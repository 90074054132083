import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { HydrilState } from '@dunefront/common/modules/pumping/dto/pumping.dto';
import { IInitialData } from '../initial-data.component';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { PumpingInitialDataValidation } from '@dunefront/common/modules/pumping/model/initial-data/pumping-initial-data.validation';
import { Pumping } from '@dunefront/common/modules/pumping/model/pumping/pumping';
import { IRadioItem } from '@dunefront/common/common/radio.helpers';
import { ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';

@Component({
  selector: 'app-annular-bop',
  templateUrl: './annular-bop.component.html',
  styleUrls: ['./annular-bop.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnnularBopComponent {
  @Input() public data: IInitialData | undefined;

  @Output() public dataChange = new EventEmitter<ObjectChangeProp<Pumping>>();

  public UnitType = UnitSystem;

  public hydrilStateItems: IRadioItem<HydrilState>[] = [
    { value: HydrilState.Open, text: 'Open' },
    { value: HydrilState.One_Line, text: '1 Line' },
    { value: HydrilState.Two_Lines, text: '2 Lines' },
  ];

  public isTxtChokeLineIDEnabled(pumping: Pumping): boolean {
    return PumpingInitialDataValidation.isTxtChokeLineIDEnabled(pumping);
  }

  public isTxtSurfaceChokeLineLengthEnabled(pumping: Pumping): boolean {
    return PumpingInitialDataValidation.isTxtSurfaceChokeLineLengthEnabled(pumping);
  }

  public isTxtChokeKillLineIDEnabled(pumping: Pumping): boolean {
    return PumpingInitialDataValidation.isTxtChokeKillLineIDEnabled(pumping);
  }
}
