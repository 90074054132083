import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ScenarioManagerSelectorComponent } from './scenario-manager-selector.component';
import { CheckBoxModule } from '../../../../shared/components/form-components/check-box/check-box.component';
import { ScenarioManagerCheckboxesComponent } from '../scenario-manager-checkboxes/scenario-manager-checkboxes.component';
import { SelectModule } from '../../../../shared/components/form-components/select/select.module';
import { DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
  declarations: [ScenarioManagerSelectorComponent, ScenarioManagerCheckboxesComponent],
  imports: [CommonModule, FormsModule, SelectModule, CheckBoxModule, DragDropModule],
  exports: [ScenarioManagerSelectorComponent, ScenarioManagerCheckboxesComponent],
})
export class ScenarioManagerSelectorModule {}
