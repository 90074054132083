import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ChartDtoUpdateNotificationService {
  private _onChartConfigUpdated$ = new Subject<number>();

  public get onChartConfigUpdated$(): Observable<number> {
    return this._onChartConfigUpdated$.asObservable();
  }

  public notifyChartUpdated(chartId: number): void {
    this._onChartConfigUpdated$.next(chartId);
  }
}
