<div class="grid-form-container">
  <div class="grid-two-one" *ngIf="data?.pumping as pumping">
    <app-radio-group
      [source]="pumping"
      [key]="'IsSubseaBOP'"
      id="subsea-bop"
      [items]="isSubseaBOPSItems"
      layout="horizontal"
      (valueChanged)="dataChange.emit($event)"
      dataCy="subsea-bop"
    >
    </app-radio-group>
  </div>
</div>
