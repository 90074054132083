import { PipeType } from '../../../../dto/pipe.dto';
import { RunningStringPipeDto } from '../../../../dto/running-string-pipe.dto';
import { ToolJoint } from '../../tool-joint/tool-joint';
import { BasePipeFactory } from '../../base.pipe.factory';
import { Pipe } from '../../pipe';
import { ITableRow } from '../../../../common/common-grid.interfaces';

export interface WorkstringPipe extends Pipe {
  ToolJoint: ToolJoint;
  PipeType: PipeType.Workstring;
}

export class WorkstringPipeFactory {
  public static createFromRunningString(runningStringDto: RunningStringPipeDto): WorkstringPipe {
    const pipe = BasePipeFactory.createFromDto(runningStringDto);
    const workstringPipe: WorkstringPipe = {
      ...pipe,
      PipeType: PipeType.Workstring,
      ToolJoint: {
        InnerDiameter: runningStringDto.ToolJointInnerDiameter,
        OuterDiameter: runningStringDto.ToolJointOuterDiameter,
        SingleJointLength: runningStringDto.SingleJointLength,
        ToolJointLength: runningStringDto.ToolJointLength,
        error: {},
      },
    };
    return workstringPipe;
  }
}

export function isWorkstringPipeRow(row: ITableRow<Pipe>): row is ITableRow<WorkstringPipe> {
  return isWorkstringPipe(row.rowData);
}

export function isWorkstringPipe(pipe: Pipe): pipe is WorkstringPipe {
  return pipe.PipeType === PipeType.Workstring;
}
