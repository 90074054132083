import { createTableRow, ITableRow } from '@dunefront/common/common/common-grid.interfaces';
import { ModalService } from '../../../common-modules/modals/modal.service';
import { InsertLocation } from '@dunefront/common/modules/common.interfaces';
import { Subscription } from 'rxjs';
import { IUnitSystemDto } from '@dunefront/common/dto/unit-system.dto';
import { IIndexedDataType } from '@dunefront/common/dto/common-dto.interfaces';
import { DecimalNumberParser } from '../../../common-modules/units/components/decimal-number-parser';
import { ColumnType, IGridColumnConfig } from './grid.interfaces';
import { UnitConverterHelper } from '@dunefront/common/unit-converters/unit.converter.helper';
import { ISelectItem } from '@dunefront/common/common/select.helpers';
import { IDeleteRowsProps, IInsertRowsProps, IUpdateTableRowsProps } from '@dunefront/common/common/common-store-crud.interfaces';
import { GridHelpers } from './grid.helpers';

export abstract class GridConfig<T extends IIndexedDataType> {
  public columns: IGridColumnConfig<T>[] = [];
  public headerText?: string;

  protected parser = new DecimalNumberParser();
  protected subscription = new Subscription();

  protected gridHelpers = new GridHelpers(this.modalService);

  constructor(protected modalService: ModalService) {}

  public updateRowsAction(props: IUpdateTableRowsProps<T>, cellIndex?: number): void {
    // to be overridden
  }

  public insertRowAction(props: IInsertRowsProps<T>, refRow?: ITableRow<T>, isPaste = false, isPasteSingleColumn = false): void {
    // to be overridden
  }

  public replaceGridAction(rows: ITableRow<T>[]): void {
    // to be overridden
  }

  public async canRowsBeDeleted(props: IDeleteRowsProps): Promise<boolean> {
    return true;
  }

  public deleteRowsAction(props: IDeleteRowsProps): void {
    // to be overridden
  }

  public isCellDisabled(rows: ITableRow<T>[], rowIndex: number, cellIndex: number): boolean {
    return false;
  }

  public async pasteFromClipboard(
    currentUnitSystem: IUnitSystemDto,
    refRow: ITableRow<T>,
    scenarioId: number,
    insertLocation: InsertLocation = 'paste',
    textFromClipboard: string,
    shouldResetResults: boolean,
    dataSourceRows: ITableRow<T>[],
    selectedCells: Set<string>,
    singleColSelected?: number,
  ): Promise<void> {
    const clipBoardData = textFromClipboard.split('\n');

    // Allow pasting only when whole column, or one cell is selected or selection fits pasted data
    const dataFitsSelection =
      selectedCells.size === 0 || // selected size == 0 means that it's pasting in insert row
      singleColSelected != null ||
      selectedCells.size === 1 ||
      this.gridHelpers.isDataFitSelection(selectedCells, textFromClipboard);

    if (!dataFitsSelection) {
      await this.modalService.showAlert('Pasted data does not fit selection');
      return;
    }

    const parsedRows = this.filterPastedRows(
      this.parseClipboardData(currentUnitSystem, clipBoardData, scenarioId, dataSourceRows, selectedCells, singleColSelected),
      singleColSelected != null,
    );

    if (!parsedRows.length) {
      await this.modalService.showAlert("Can't parse pasted data");
    } else {
      this.insertRowAction(
        {
          rows: parsedRows,
          refId: refRow.rowData.Id,
          insertLocation,
          shouldResetResults,
        },
        refRow,
        true,
        singleColSelected != null,
      );
    }
  }

  public canRowsBeMerged(rows: ITableRow<T>[], selectedRowsIndexes: number[]): boolean {
    return true;
  }

  protected createMergedRow(rows: ITableRow<T>[], firstRowIndex: number, lastRowIndex: number): ITableRow<T> | undefined {
    return undefined;
  }

  public async mergeRows(shouldResetResults: boolean, rows: ITableRow<T>[], selectedRowsIndexes: number[]): Promise<void> {
    const firstRowIndex = Math.min(...selectedRowsIndexes);
    const lastRowIndex = Math.max(...selectedRowsIndexes);

    const firstRefRow = rows[firstRowIndex];
    const lastRefRow = rows[lastRowIndex];

    if (firstRefRow == null || lastRefRow == null) {
      return;
    }

    const newMergedRow = this.createMergedRow(rows, firstRowIndex, lastRowIndex);

    if (newMergedRow != null) {
      this.insertRowAction(
        {
          rows: [newMergedRow],
          refId: firstRefRow.rowData.Id,
          insertLocation: 'merge-rows',
          noOfRowsToDelete: selectedRowsIndexes.length,
          shouldResetResults,
        },
        firstRefRow,
      );
    }
  }

  public filterPastedRows(rows: ITableRow<T>[], isPasteSingleColumn: boolean): ITableRow<T>[] {
    return rows;
  }

  public createEmptyModel(scenarioId: number, rangeId?: number, refRow?: T): T | undefined {
    return undefined;
  }

  public createDefaultTableRows(scenarioId: number, noOfRows: number, rangeId?: number, refRow?: ITableRow<T>): ITableRow<T>[] {
    return [];
  }

  public mappingLookupDataByText(text: string): number | undefined {
    return undefined;
  }

  protected parseClipboardData(
    currentUnitSystem: IUnitSystemDto,
    clipboardRows: string[],
    scenarioId: number,
    dataSourceRows: ITableRow<T>[],
    selectedCells: Set<string>,
    singleColSelected?: number,
  ): ITableRow<T>[] {
    return clipboardRows
      .filter((pasteRow) => pasteRow.length > 0)
      .reduce<ITableRow<T>[]>((dataRows, element, rowIndex) => {
        const pasteRowColumns = element.split('\t');
        let isValid = true;

        // get column, from which pasting should be performed ( the smallest selected column )
        // if the smallest column is zero ( so it's selection col ) - set starting to 1 as selection col will never be updated
        // if it's pasting whole column mode - set starting col to 0 as this value won't be used on updating
        let startingCell = 1;
        let startingRow = 0;

        if (selectedCells.size !== 0) {
          startingCell = singleColSelected != null ? 0 : Math.min(...Array.from(selectedCells).map((cell) => +cell.split(':')[1])) || 1;
          startingRow = Math.min(...Array.from(selectedCells).map((cell) => +cell.split(':')[0]));
        }

        const isRowExist = dataSourceRows[rowIndex + startingRow] != null;
        const isRowDisabled = isRowExist && dataSourceRows[rowIndex + startingRow].isEditingDisabled;

        const isUpdateExistingRow = singleColSelected != null || selectedCells.size > 0;

        const model =
          isUpdateExistingRow && isRowExist
            ? {
                ...dataSourceRows[rowIndex + startingRow].rowData,
                Id: -1,
              }
            : this.createEmptyModel(scenarioId);

        if (!model) {
          console.warn("Can't create empty model.");
          return dataRows;
        }

        const visibleColumns = this.columns.filter((col) => col.visible);
        const columnsForUpdate =
          isRowDisabled && singleColSelected != null
            ? []
            : visibleColumns.slice(singleColSelected ?? 0, (singleColSelected ?? 0) + pasteRowColumns.length + startingCell);

        let pasteColIndex = -1;
        columnsForUpdate.forEach((column) => {
          // index of the column in the full columns list (not columnsForUpdate, with filtered out hidden columns)
          const originalIdx = this.columns.indexOf(column);
          // skip column if one of below conditions is met
          // skip column if one of below conditions is met
          // pasteColIndex wasn't incremented, therefore value from next pasteColIndex won't be skipped
          if (
            column.colId === ' ' ||
            !column.visible ||
            column.type === ColumnType.select ||
            column.type === ColumnType.checkbox ||
            originalIdx < startingCell
          ) {
            return;
          }

          pasteColIndex++;

          // disabled columns are skipped
          // pasteColIndex is incremented, so value from current pasteColIndex will be skipped
          if (column.disabled || this.isCellDisabled([], rowIndex, originalIdx)) {
            return;
          }

          const colId = column.colId as keyof T;

          const cellToPaste = pasteRowColumns[pasteColIndex];
          if (cellToPaste === undefined) {
            return;
          }

          if (column.type === ColumnType.text || column.unitSystem === undefined) {
            (model[colId] as any) = cellToPaste.trim();
          } else {
            const value = this.parser.parseDecimalNumber(this.parser.translateNumber(cellToPaste));
            if (!isNaN(value)) {
              (model[colId] as any) = UnitConverterHelper.convertToSi(column.unitSystem, currentUnitSystem, value);
            } else {
              isValid = false;
              return;
            }
          }
        });

        if (isValid) {
          dataRows.push(createTableRow(model, 'data', 0));
        }
        return dataRows;
      }, []);
  }

  public getLookupDataSource(type: string, rowIndex?: number): ISelectItem<any>[] {
    return [];
  }

  public getLookupDataPlaceholder(type: string, rowIndex?: number): string {
    return '';
  }

  public async beforePasteCheck(): Promise<boolean> {
    return true;
  }

  public dispose(): void {
    this.subscription.unsubscribe();
  }
}

export class ReadOnlyGridConfig<T extends IIndexedDataType> extends GridConfig<T> {}
