import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IInitialData } from '../initial-data.component';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { PumpingInitialDataValidation } from '@dunefront/common/modules/pumping/model/initial-data/pumping-initial-data.validation';
import { Pumping } from '@dunefront/common/modules/pumping/model/pumping/pumping';
import { IRadioItem } from '@dunefront/common/common/radio.helpers';
import { ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';
import { VariableChokeSetting } from '@dunefront/common/modules/pumping/dto/pumping.dto';

@Component({
  selector: 'app-choke',
  templateUrl: './choke.component.html',
  styleUrls: ['./choke.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChokeComponent {
  @Input() public data!: IInitialData;

  @Output() public dataChange = new EventEmitter<ObjectChangeProp<Pumping>>();

  public UnitType = UnitSystem;

  public readonly chokePositionItems: IRadioItem<number>[] = [
    { value: 0, text: 'Closed' },
    { value: 1, text: 'Open' },
    { value: 2, text: 'None' },
  ];

  public readonly variableChokeItems: IRadioItem<number>[] = [
    { value: 0, text: 'Fixed' },
    { value: 1, text: 'Managed Rate' },
    { value: 2, text: 'Managed Pressure' },
  ];

  public get isTxtChokeOpenVolumeEnabled(): boolean {
    return PumpingInitialDataValidation.isTxtChokeOpenVolumeEnabled(this.data.pumping);
  }

  public get isVariableChokeEnabled(): boolean {
    return PumpingInitialDataValidation.isVariableChokeEnabled(this.data.pumping);
  }

  public get isTxtChokeOrificeIDEnabled(): boolean {
    return PumpingInitialDataValidation.isTxtChokeOrificeIDEnabled(this.data.pumping);
  }

  public get chokeOrificeIDLabelText(): string {
    return this.data.pumping.VariableChoke !== VariableChokeSetting.Fixed ? 'Max Choke Orifice ID' : 'Choke Orifice ID';
  }

  public onValueChange(props: ObjectChangeProp<Pumping>): void {
    this.dataChange.emit(props);
  }
}
