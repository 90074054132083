import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy } from '@angular/core';
import {
  createChartDataColumn,
  createChartDataSet,
  createEmptyChartData,
  IChartDataDtoColumn,
} from '@dunefront/common/modules/reporting/dto/chart-data.dto';
import { IPSDChartSelectionData } from '../../../../../+store/psd-analysis/psd-analysis.selectors';
import { getRowsForCalculations } from '@dunefront/common/common/common-grid.interfaces';
import { DataType } from '@dunefront/common/dto/data-storage';
import { ChartDataSourceType } from '@dunefront/common/modules/reporting/dto/chart.dto';
import { getChartIds } from '../../../../../+store/reporting/reporting.selectors';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { ChartAxis } from '@dunefront/common/modules/reporting/dto/chart-axis-property.dto';
import { ChartState } from '../../../../../+store/reporting/reporting-module.state';
import { ReportingFactory } from '../../../../../+store/reporting/model/reporting.factory';
import { updatePSD, updatePSDs } from '../../../../../+store/psd-analysis/psd-analysis.actions';
import { changeProp, getChangeProp, ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';
import { StoreCrudPropsFactory } from '@dunefront/common/common/common-store-crud.interfaces';
import { PSD } from '@dunefront/common/modules/psd-analysis/model/psd/psd';

const CHART_NAME_CONSOLIDATED_PSD_CHART_PREVIEW = 'Consolidated PSD Chart Preview';

@Component({
  selector: 'app-consolidated-psd-chart',
  templateUrl: './consolidated-psd-chart.component.html',
  styleUrls: ['./consolidated-psd-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConsolidatedPsdChartComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input() public psdChartSelectionData: IPSDChartSelectionData[] = [];
  @Input() public chartName = CHART_NAME_CONSOLIDATED_PSD_CHART_PREVIEW;
  @Input() public drawableProviderId = CHART_NAME_CONSOLIDATED_PSD_CHART_PREVIEW;
  public chartState!: ChartState;
  public hiddenColumns: IChartDataDtoColumn[] = [];
  public chartId = 0;
  private subscription = new Subscription();

  constructor(
    private cdRef: ChangeDetectorRef,
    private store: Store,
  ) {
    this.subscription.add(
      store.select(getChartIds).subscribe((chartIds) => {
        this.chartId = chartIds.consolidatedPsd;
        cdRef.markForCheck();
      }),
    );
  }

  public get displayChart(): boolean {
    return this.psdChartSelectionData?.some((psdChartData) => psdChartData.psd.isValid && psdChartData.psd.IsSelected);
  }

  public ngAfterViewInit(): void {
    const { chartState, hiddenColumns } = this.initChart();

    this.chartState = chartState;
    this.hiddenColumns = hiddenColumns;
  }

  public ngOnChanges(): void {
    const { chartState, hiddenColumns } = this.initChart();

    this.chartState = chartState;
    this.hiddenColumns = hiddenColumns;
  }

  public initChart(): { chartState: ChartState; hiddenColumns: IChartDataDtoColumn[] } {
    const chartDataDto = createEmptyChartData(DataType.Particle_Size, ChartDataSourceType.ConsolidatedPsd, this.chartId);
    const hiddenColumns: IChartDataDtoColumn[] = [];
    const filteredPsdChartSelectionData = this.psdChartSelectionData.filter((psdChartData) => psdChartData.psd.isValid);

    filteredPsdChartSelectionData.forEach((psdChartSelectionData, index) => {
      const { psdReadings, psd } = psdChartSelectionData;

      const chartDataColumn = createChartDataColumn(
        ChartAxis.PrimaryValue,
        DataType.Cumulative_Percent_Weight_Retained,
        index,
        0,
        psd.Description,
      );

      const chartDataRows = getRowsForCalculations(psdReadings.rows).map((psdReading) => ({
        Argument: psdReading.SieveOpening,
        Values: [psdReading.CumulativePercentWeightRetained],
      }));
      const chartDataSet = createChartDataSet(chartDataRows);

      chartDataDto.ChartDataColumns.push(chartDataColumn);
      chartDataDto.ChartDataSets.push(chartDataSet);

      if (!psdChartSelectionData.psd.IsSelected) {
        hiddenColumns.push(chartDataColumn);
      }
    });

    return {
      chartState: ReportingFactory.createChartStateWithData(ChartDataSourceType.ConsolidatedPsd, chartDataDto),
      hiddenColumns,
    };
  }

  public updatePSD(props: ObjectChangeProp<PSD>, index: number): void {
    const psd = changeProp(this.psdChartSelectionData[index].psd, props);
    this.store.dispatch(updatePSD(StoreCrudPropsFactory.updateRow(psd, props)));
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public onHiddenColumnsChanged(hiddenColumns: IChartDataDtoColumn[]): void {
    const updatedPSDs: PSD[] = [];

    for (const psdSelectionData of this.psdChartSelectionData) {
      const wasSelected = psdSelectionData.psd.IsSelected;
      const isSelected = !hiddenColumns.some((col) => col.Name === psdSelectionData.psd.Description);

      if (wasSelected !== isSelected) {
        updatedPSDs.push(changeProp(psdSelectionData.psd, getChangeProp<PSD>('IsSelected', isSelected, false)));
      }
    }

    if (updatedPSDs.length > 0) {
      this.store.dispatch(updatePSDs(StoreCrudPropsFactory.updateRows(updatedPSDs)));
    }
  }
}
