import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { IGradientLine } from '../chart-component-helpers/chart-types';
import {
  defaultModalCancelButton,
  defaultModalConfirmButton,
  modalButton,
  ModalButtonConfig,
} from '../../modals/generic-modal/generic-modal.component';
import { IGradientLineStyle } from '@dunefront/common/modules/reporting/dto/chart-gradient-line.dto';

@Component({
  templateUrl: './chart-gradient-line.component.html',
  styleUrls: ['./chart-gradient-line.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartGradientLineComponent {
  public gradientLine!: IGradientLine;
  public defaultLineStyle!: IGradientLineStyle;
  public isChartRotated = false;
  public UnitType = UnitSystem;
  public modalTitle = 'Chart Slope Line';
  public modalButtonsConfigs: ModalButtonConfig[] = [];

  constructor(
    public activeModal: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private cdRef: ChangeDetectorRef,
  ) {
    this.gradientLine = { ...config.data.gradientLine };
    this.defaultLineStyle = config.data.defaultLineStyle;

    this.modalButtonsConfigs = [
      modalButton('Duplicate', (): void => this.duplicateClicked(), 'chart-gradient-line-duplicate-btn', 'primary'),
      modalButton('Delete', (): void => this.deleteClicked(), 'chart-gradient-line-dlg-delete-btn', 'primary'),
      defaultModalCancelButton((): void => this.cancelClicked()),
      defaultModalConfirmButton((): void => this.okClicked(), 'OK', 'chart-gradient-line-dlg-ok-btn'),
    ];
  }

  public get isOverrideStyle(): boolean {
    return this.gradientLine.style != null;
  }

  public onOverrideStyleChanged(override: boolean): void {
    this.gradientLine.style = override ? { ...this.defaultLineStyle } : undefined;

    this.cdRef.markForCheck();
  }

  public emitValue(event: ObjectChangeProp<IGradientLine>): void {
    this.gradientLine = { ...this.gradientLine, [event.key]: event.value };

    this.cdRef.markForCheck();
  }

  public emitStyleValue(event: ObjectChangeProp<IGradientLine>): void {
    const style = this.gradientLine.style;
    if (style != null) {
      this.gradientLine.style = { ...style, [event.key]: event.value };
    }

    this.cdRef.markForCheck();
  }

  public okClicked(): void {
    const result = { action: 'ok', value: this.gradientLine };
    this.activeModal.close(result);
  }

  public cancelClicked(): void {
    this.activeModal.close();
  }

  public deleteClicked(): void {
    const result: ChartGradientLineDialogResult = { action: 'delete', value: this.gradientLine };
    this.activeModal.close(result);
  }

  public duplicateClicked(): void {
    const xOffset = Math.abs(this.gradientLine.argument1 - this.gradientLine.argument2) / 3;
    const yOffset = Math.abs(this.gradientLine.value1 - this.gradientLine.value1) / 3;

    const duplicatedLine: IGradientLine = {
      ...this.gradientLine,
      Id: -1,
      argument1: this.gradientLine.argument1 + xOffset,
      argument2: this.gradientLine.argument2 + xOffset,
      value1: this.gradientLine.value1 + yOffset,
      value2: this.gradientLine.value2 + yOffset,
    };

    const result: ChartGradientLineDialogResult = { action: 'ok', value: duplicatedLine };
    this.activeModal.close(result);
  }
}

export interface ChartGradientLineDialogResult {
  action: 'ok' | 'delete';
  value: IGradientLine;
}
