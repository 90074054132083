import { FluidDto } from './dto/fluid.dto';
import { RheometerDto, RheometerReadingDto } from './dto/rheometer.dto';
import { RheologyDto } from './dto/rheology/rheology.dto';
import { CopyRowsWsAction, DeleteRowsWsAction, InsertRowsWsAction, IUpdateRowsWsActionProps, UpdateRowsWsAction } from '../../ws.action';
import { IUpdateTableRowsProps } from '../../common/common-store-crud.interfaces';
import { WsActionPropsFactory } from '../../common/ws-action/ws-action-props.factory';
import { Rheometer } from './model/rheometer/rheometer';
import { RheometerFactory } from './model/rheometer/rheometer.factory';
import { RheometerReading } from './model/rheometer-reading/rheometer-reading';
import { RheometerReadingFactory } from './model/rheometer-reading/rheometer-reading.factory';

export const FluidModuleName = 'FluidModule';

export enum FluidModuleActionTypes {
  FluidInsertRows = '[FluidModule] [Fluid] InsertRows',
  FluidUpdateRow = '[FluidModule] [Fluid] UpdateRow',
  FluidDeleteRows = '[FluidModule] [Fluid] DeleteRows',
  FluidCopyRow = '[FluidModule] [Fluid] [Insert] CopyRow',
  RheologyInsertRows = '[FluidModule] [Rheology] InsertRows',
  RheologyUpdateRow = '[FluidModule] [Rheology] UpdateRow',
  RheologyDeleteRows = '[FluidModule] [Rheology] DeleteRows',
  RheometerInsertRows = '[FluidModule] [Rheometer] InsertRows',
  RheometerUpdateRow = '[FluidModule] [Rheometer] UpdateRow',
  RheometerDeleteRows = '[FluidModule] [Rheometer] DeleteRows',
  RheometerReadingInsertRows = '[FluidModule] [RheometerReading] InsertRows',
  RheometerReadingUpdateRow = '[FluidModule] [RheometerReading] UpdateRow',
  RheometerReadingDeleteRows = '[FluidModule] [RheometerReading] DeleteRows',
}

export interface LoadFluidsActionResponse {
  fluidDtos: FluidDto[];
  rheometerDtos: RheometerDto[];
  rheologyDtos: RheologyDto[];
  rheometerReadingDtos: RheometerReadingDto[];
  scenarioId: number;
}

// region Fluid

export class FluidInsertRowsAction extends InsertRowsWsAction<FluidDto> {
  public override readonly type = FluidModuleActionTypes.FluidInsertRows;
}

export class FluidUpdateRowsAction extends UpdateRowsWsAction<FluidDto> {
  public override readonly type = FluidModuleActionTypes.FluidUpdateRow;

  constructor(action: IUpdateRowsWsActionProps<FluidDto>, rheologiesToSave: RheologyDto[] = []) {
    super(action);
    this.childUpdateActions = [
      new RheologyUpdateRowsAction({
        rows: rheologiesToSave,
        scenarioId: action.scenarioId,
        shouldResetResults: false,
      }),
    ];
  }
}

export class FluidDeleteRowsAction extends DeleteRowsWsAction {
  public override readonly type = FluidModuleActionTypes.FluidDeleteRows;
}

export class FluidCopyRowAction extends CopyRowsWsAction {
  public override readonly type = FluidModuleActionTypes.FluidCopyRow;
}

// endregion

// region Rheology

export class RheologyInsertRowsAction extends InsertRowsWsAction<RheologyDto> {
  public override readonly type = FluidModuleActionTypes.RheologyInsertRows;
}

export class RheologyUpdateRowsAction extends UpdateRowsWsAction<RheologyDto> {
  public override readonly type = FluidModuleActionTypes.RheologyUpdateRow;

  constructor(action: IUpdateRowsWsActionProps<RheologyDto>) {
    super(action);
  }
}

export class RheologyDeleteRowsAction extends DeleteRowsWsAction {
  public override readonly type = FluidModuleActionTypes.RheologyDeleteRows;
}

// endregion

// region Rheometer

export class RheometerInsertRowsAction extends InsertRowsWsAction<RheometerDto> {
  public override readonly type = FluidModuleActionTypes.RheometerInsertRows;
}

export class RheometerUpdateRowsAction extends UpdateRowsWsAction<RheometerDto> {
  public override readonly type = FluidModuleActionTypes.RheometerUpdateRow;
  constructor(action: IUpdateTableRowsProps<Rheometer>) {
    super(WsActionPropsFactory.updateAction(action, RheometerFactory.rheometerToDto));
  }
}

export class RheometerDeleteRowsAction extends DeleteRowsWsAction {
  public override readonly type = FluidModuleActionTypes.RheometerDeleteRows;
}

// endregion

// region RheometerReading

export class RheometerReadingInsertRowsAction extends InsertRowsWsAction<RheometerReadingDto> {
  public override readonly type = FluidModuleActionTypes.RheometerReadingInsertRows;
}

export class RheometerReadingUpdateRowsAction extends UpdateRowsWsAction<RheometerReadingDto> {
  public override readonly type = FluidModuleActionTypes.RheometerReadingUpdateRow;
  constructor(action: IUpdateTableRowsProps<RheometerReading>) {
    super(WsActionPropsFactory.updateAction(action, RheometerReadingFactory.rheometerReadingToDto));
  }
}

export class RheometerReadingDeleteRowsAction extends DeleteRowsWsAction {
  public override readonly type = FluidModuleActionTypes.RheometerReadingDeleteRows;
}

// endregion

export type FluidModuleActions =
  | FluidInsertRowsAction
  | FluidUpdateRowsAction
  | FluidDeleteRowsAction
  | FluidCopyRowAction
  | RheologyInsertRowsAction
  | RheologyUpdateRowsAction
  | RheologyDeleteRowsAction
  | RheometerUpdateRowsAction
  | RheometerInsertRowsAction
  | RheometerDeleteRowsAction
  | RheometerReadingInsertRowsAction
  | RheometerReadingUpdateRowsAction
  | RheometerReadingDeleteRowsAction;
