import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ChartSeriesTemplateDto } from '@dunefront/common/dto/chart-series-template.dto';
import {
  deleteChartSeriesTemplate,
  insertChartSeriesTemplate,
  updateChartSeriesTemplate,
} from '../../../+store/reporting/reporting.actions';
import { ModalService } from '../../modals/modal.service';
import { ChartSeriesProps, ChartSeriesPropsComponent } from '../chart-series/chart-series-props.component';
import { filter, take } from 'rxjs/operators';
import { ConfigureSeriesPayload } from '../chart-component-helpers/chart-types';
import { IUnitSystemDto } from '@dunefront/common/dto/unit-system.dto';
import { isDefined } from '@dunefront/common/common/state.helpers';

@Injectable()
export class ChartSeriesTemplatesService {
  constructor(
    private store: Store,
    private modalService: ModalService,
  ) {}

  public onConfigureSeries({ template, effectiveSeriesStyle, column }: ConfigureSeriesPayload, currentUnitSystem: IUnitSystemDto): void {
    let chartSeriesTemplate: ChartSeriesTemplateDto | undefined = template;

    if (!chartSeriesTemplate) {
      chartSeriesTemplate = {
        ...effectiveSeriesStyle,
        ColumnName: column.Name,
        Id: -1,
        ScenarioId: 0,
        SortOrder: 0,
      };
    }

    const dialog = this.modalService.open(ChartSeriesPropsComponent, { chartSeriesTemplate, currentUnitSystem });

    dialog.onClose.pipe(take(1), filter(isDefined)).subscribe((result: ChartSeriesProps) => {
      if (result.type === 'confirm' && result.template != null) {
        this.store.dispatch(
          result.template.Id === -1 ? insertChartSeriesTemplate(result.template) : updateChartSeriesTemplate(result.template),
        );
      }
      if (result.type === 'resetDefault' && template != null) {
        this.store.dispatch(
          deleteChartSeriesTemplate({
            rowIds: [template.Id],
            shouldResetResults: false,
            scenarioId: template.ScenarioId,
          }),
        );
      }
    });
  }
}
