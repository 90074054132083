import { NozzleProperties, Shroud, ShuntTube, Tube } from './shunt-tube';
import { Pipe } from '../pipes/pipe';
import { TubeShape } from '../../dto/shunt-tube.dto';
import { getRowsForCalculations } from '../../common/common-grid.interfaces';
import { CompletionModuleState } from '../completion/completion-module.state';
import { CompletionCalculations } from '../completion/model/completion.calculations';
import { IError } from '../../common/common-state.interfaces';
import { isScreenPipe } from '../pipes/lower-completion-pipes/pipes/screen-pipe';
import { WellModuleState } from '../well/well-module.state';
import { ICompletionValidationDependencies } from '../completion/model/completion.validation';
import { validateFeatureRequired } from '../licensing/licensing.validator';
import { IGetCurrentFeaturesResult, LicenseFeature } from '../licensing/licensing.interfaces';

export class ShuntTubeValidation {
  public static ValidateShuntTubesTab(completion: CompletionModuleState, deps: ICompletionValidationDependencies): ShuntTube {
    if (!CompletionCalculations.HasShunts(completion)) {
      return { ...completion.ShuntTube, isValid: true };
    }

    const shuntTube = completion.ShuntTube;
    const lowerCompletion = getRowsForCalculations(completion.LowerCompletion.rows);
    const shuntTubeError: IError<ShuntTube> = {
      IsTransportTubePresent: this.ValidateIsTransportTubePresent(shuntTube, deps.currentLicenseFeatures),
      ShuntPackingFactor: this.ValidateShuntPackingFactor(shuntTube),
      IsInternalReservoir: this.ValidateInternalReservoir(shuntTube),
    };

    const transportTubeError: IError<Tube> = {
      NumberOfTubes: this.ValidateShuntTransportTubeCount(shuntTube),
      Height: this.ValidateShuntTransportHeight(shuntTube),
      Width: this.ValidateShuntTransportWidth(shuntTube),
      Thickness: this.ValidateShuntTransportThickness(shuntTube),
      BurstRating: this.ValidateShuntTransportBurstRating(shuntTube, shuntTube.TransportTube),
      CollapseRating: this.ValidateShuntTransportCollapse(shuntTube),
      OuterDiameter: this.ValidateShuntTransportOD(shuntTube),
      InnerDiameter: this.ValidateShuntTransportID(shuntTube),
    };

    const transportTube: Tube = {
      ...shuntTube.TransportTube,
      error: transportTubeError,
      isValid: !Object.values(transportTubeError).some((err) => err),
    };

    const packingTubeError: IError<Tube> = {
      NumberOfTubes: this.ValidateShuntPackingTubeCount(shuntTube),
      Height: this.ValidateShuntPackingHeight(shuntTube),
      Width: this.ValidateShuntPackingWidth(shuntTube),
      Thickness: this.ValidateShuntPackingThickness(shuntTube),
      BurstRating: this.ValidateShuntPackingBurstRating(shuntTube.PackingTube),
      CollapseRating: this.ValidateShuntPackingRectCollapse(shuntTube),
      OuterDiameter: this.ValidateShuntPackingOD(shuntTube),
      InnerDiameter: this.ValidateShuntPackingID(shuntTube),
    };

    const packingTube: Tube = {
      ...shuntTube.PackingTube,
      error: packingTubeError,
      isValid: !Object.values(packingTubeError).some((err) => err),
    };

    const shroudError: IError<Shroud> = {
      ShroudOuterDiameter: this.ValidateShuntShroudOD(shuntTube),
      ShroudInnerDiameter: this.ValidateShuntShroudID(shuntTube, lowerCompletion),
      OpenAreaPercentage: this.ValidateShroudOpenArea(shuntTube),
    };

    const shroud: Shroud = {
      ...shuntTube.Shroud,
      error: shroudError,
      isValid: !Object.values(shroudError).some((err) => err),
    };

    const nozzlePropsError: IError<NozzleProperties> = {
      NozzleDiameter: this.ValidateShuntNozzleDiameter(shuntTube),
      NozzleLength: this.ValidateShuntNozzleLength(shuntTube),
      LengthToFirstNozzle: this.ValidateShuntLengthToFirstNozzle(shuntTube),
      LengthBetweenNozzles: this.ValidateShuntLengthBetweenNozzles(shuntTube),
      IsNozzleLeakoff: this.ValidateIsNozzleLeakoff(shuntTube, deps.well, deps.currentLicenseFeatures),
    };

    const nozzleProperties: NozzleProperties = {
      ...shuntTube.NozzleProperties,
      error: nozzlePropsError,
      isValid: !Object.values(nozzlePropsError).some((err) => err),
    };

    const isValid =
      !Object.values(shuntTubeError).some((err) => err) &&
      transportTube.isValid &&
      packingTube.isValid &&
      shroud.isValid &&
      nozzleProperties.isValid;

    const newShuntTube: ShuntTube = {
      ...shuntTube,
      error: shuntTubeError,
      TransportTube: transportTube,
      PackingTube: packingTube,
      Shroud: shroud,
      NozzleProperties: nozzleProperties,
      isValid,
    };

    return newShuntTube;
  }

  private static ValidateShuntTransportTubeCount(shuntTube: ShuntTube): string {
    if (shuntTube.IsTransportTubePresent && shuntTube.TransportTube.NumberOfTubes <= 0) {
      return 'Number of transport tubes must be greater than zero';
    }
    return '';
  }

  private static ValidateShuntShroudOD(shuntTube: ShuntTube): string {
    if (shuntTube.Shroud.IsShroudPresent && shuntTube.Shroud.ShroudOuterDiameter <= 0) {
      return 'Outer diameter must be greater than zero';
    }
    if (shuntTube.Shroud.IsShroudPresent && shuntTube.Shroud.ShroudOuterDiameter <= shuntTube.Shroud.ShroudInnerDiameter) {
      return 'Outer diameter must be greater than inner diameter';
    }
    return '';
  }

  private static ValidateShuntShroudID(shuntTube: ShuntTube, lowerCompletions: Pipe[]): string {
    if (shuntTube.Shroud.IsShroudPresent) {
      const maxScreenOD = Math.max(
        ...lowerCompletions.filter((lc) => isScreenPipe(lc)).map((lowerCompletion) => lowerCompletion.OuterDiameter),
      );

      if (shuntTube.Shroud.ShroudInnerDiameter <= 0) {
        return 'Inner diameter must be greater than zero';
      }
      if (shuntTube.Shroud.ShroudInnerDiameter <= maxScreenOD) {
        return 'Inner diameter must be greater than maximum screen outer diameter';
      }
      if (shuntTube.Shroud.ShroudInnerDiameter >= shuntTube.Shroud.ShroudOuterDiameter) {
        return 'Inner diameter must be less than outer diameter';
      }
    }
    return '';
  }

  private static ValidateShroudOpenArea(shuntTube: ShuntTube): string {
    if (shuntTube.Shroud.IsShroudPresent && (shuntTube.Shroud.OpenAreaPercentage <= 0 || shuntTube.Shroud.OpenAreaPercentage >= 100)) {
      return 'Percentage open area must be between 0 and 100';
    }
    return '';
  }

  private static ValidateShuntTransportHeight(shuntTube: ShuntTube): string {
    if (shuntTube.IsTransportTubePresent && shuntTube.Shape === TubeShape.Rectangle && shuntTube.TransportTube.Height <= 0) {
      return 'Height must be greater than zero';
    }
    return '';
  }

  private static ValidateShuntTransportWidth(shuntTube: ShuntTube): string {
    if (shuntTube.IsTransportTubePresent && shuntTube.Shape === TubeShape.Rectangle && shuntTube.TransportTube.Width <= 0) {
      return 'Width must be greater than zero';
    }
    return '';
  }

  private static ValidateShuntTransportThickness(shuntTube: ShuntTube): string {
    if (shuntTube.IsTransportTubePresent && shuntTube.Shape === TubeShape.Rectangle) {
      if (shuntTube.TransportTube.Thickness <= 0) {
        return 'Thickness must be greater than zero';
      }
      if (shuntTube.TransportTube.Thickness >= 0.5 * Math.min(shuntTube.TransportTube.Width, shuntTube.TransportTube.Height)) {
        if (shuntTube.TransportTube.Width <= shuntTube.TransportTube.Height) {
          return 'Thickness must be less than half of the width';
        } else {
          return 'Thickness must be less than half of the height';
        }
      }
    }
    return '';
  }

  private static ValidateShuntTransportBurstRating(shuntTube: ShuntTube, tube: Tube): string {
    if (shuntTube.IsTransportTubePresent && tube.BurstRating <= 0) {
      return 'Burst rating must be greater than zero';
    }
    return '';
  }

  private static ValidateShuntTransportCollapse(shuntTube: ShuntTube): string {
    if (shuntTube.IsTransportTubePresent && shuntTube.TransportTube.CollapseRating <= 0) {
      return 'Collapse rating must be greater than zero';
    }
    return '';
  }

  private static ValidateShuntPackingTubeCount(shuntTube: ShuntTube): string {
    if (shuntTube.PackingTube.NumberOfTubes <= 0) {
      return 'Number of packing tubes must be greater than zero';
    }
    return '';
  }

  private static ValidateShuntPackingHeight(shuntTube: ShuntTube): string {
    if (shuntTube.Shape === TubeShape.Rectangle && shuntTube.PackingTube.Height <= 0) {
      return 'Height must be greater than zero';
    }
    return '';
  }

  private static ValidateShuntPackingBurstRating(tube: Tube): string {
    if (tube.BurstRating <= 0) {
      return 'Burst rating must be greater than zero';
    }
    return '';
  }

  private static ValidateShuntPackingWidth(shuntTube: ShuntTube): string {
    if (shuntTube.Shape === TubeShape.Rectangle && shuntTube.PackingTube.Width <= 0) {
      return 'Width must be greater than zero';
    }

    return '';
  }

  private static ValidateShuntPackingThickness(shuntTube: ShuntTube): string {
    if (shuntTube.Shape === TubeShape.Rectangle) {
      if (shuntTube.PackingTube.Thickness <= 0) {
        return 'Thickness must be greater than zero';
      }
      if (shuntTube.PackingTube.Thickness >= 0.5 * Math.min(shuntTube.PackingTube.Width, shuntTube.PackingTube.Height)) {
        if (shuntTube.PackingTube.Width <= shuntTube.PackingTube.Height) {
          return 'Thickness must be less than half of the width';
        } else {
          return 'Thickness must be less than half of the height';
        }
      }
    }
    return '';
  }

  private static ValidateShuntPackingRectCollapse(shuntTube: ShuntTube): string {
    if (shuntTube.PackingTube.CollapseRating <= 0) {
      return 'Collapse rating must be greater than zero';
    }
    return '';
  }

  private static ValidateShuntTransportOD(shuntTube: ShuntTube): string {
    if (shuntTube.IsTransportTubePresent && shuntTube.Shape === TubeShape.Round) {
      if (shuntTube.TransportTube.OuterDiameter <= 0) {
        return 'Outer diameter must be greater than zero';
      }
      if (shuntTube.TransportTube.OuterDiameter <= shuntTube.TransportTube.InnerDiameter) {
        return 'Outer diameter must be greater than inner diameter';
      }
    }
    return '';
  }

  private static ValidateShuntTransportID(shuntTube: ShuntTube): string {
    if (shuntTube.IsTransportTubePresent && shuntTube.Shape === TubeShape.Round) {
      if (shuntTube.TransportTube.InnerDiameter <= 0) {
        return 'Inner diameter must be greater than zero';
      }
      if (shuntTube.TransportTube.InnerDiameter >= shuntTube.TransportTube.OuterDiameter) {
        return 'Inner diameter must be less than outer diameter';
      }
    }
    return '';
  }

  private static ValidateShuntPackingOD(shuntTube: ShuntTube): string {
    if (shuntTube.Shape === TubeShape.Round) {
      if (shuntTube.PackingTube.OuterDiameter <= 0) {
        return 'Outer diameter must be greater than zero';
      }
      if (shuntTube.PackingTube.OuterDiameter <= shuntTube.PackingTube.InnerDiameter) {
        return 'Outer diameter must be greater than inner diameter';
      }
    }
    return '';
  }

  private static ValidateShuntPackingID(shuntTube: ShuntTube): string {
    if (shuntTube.Shape === TubeShape.Round) {
      if (shuntTube.PackingTube.InnerDiameter <= 0) {
        return 'Inner diameter must be greater than zero';
      }
      if (shuntTube.PackingTube.InnerDiameter >= shuntTube.PackingTube.OuterDiameter) {
        return 'Inner diameter must be less than outer diameter';
      }
    }
    return '';
  }

  private static ValidateIsTransportTubePresent(shuntTube: ShuntTube, currentLicenseFeatures: IGetCurrentFeaturesResult): string {
    if (shuntTube.IsTransportTubePresent) {
      return validateFeatureRequired([LicenseFeature.Simulate, LicenseFeature.Evaluate], currentLicenseFeatures);
    }
    return '';
  }

  private static ValidateShuntPackingFactor(shuntTube: ShuntTube): string {
    if (shuntTube.ShuntPackingFactor < 0) {
      return 'Packing factor must be greater than or equal to 0';
    }
    if (shuntTube.ShuntPackingFactor > 1) {
      return 'Packing factor must be less than or equal to 1';
    }
    return '';
  }

  private static ValidateInternalReservoir(shuntTube: ShuntTube): string {
    if (shuntTube.IsInternalReservoir && shuntTube.IsExternal) {
      return 'Internal reservoir can’t be used when tube location is external';
    }
    return '';
  }

  private static ValidateShuntNozzleDiameter(shuntTube: ShuntTube): string {
    if (shuntTube.NozzleProperties.IsNozzleLeakoff && shuntTube.NozzleProperties.NozzleDiameter <= 0) {
      return 'Nozzle diameter must be greater than 0';
    }
    return '';
  }

  private static ValidateShuntNozzleLength(shuntTube: ShuntTube): string {
    if (shuntTube.NozzleProperties.IsNozzleLeakoff && shuntTube.NozzleProperties.NozzleLength <= 0) {
      return 'Nozzle length must be greater than 0';
    }
    return '';
  }

  private static ValidateShuntLengthToFirstNozzle(shuntTube: ShuntTube): string {
    if (
      shuntTube.NozzleProperties.IsNozzleLeakoff &&
      shuntTube.IsTransportTubePresent &&
      shuntTube.NozzleProperties.LengthToFirstNozzle <= 0
    ) {
      return 'Length to first nozzle must be greater than 0';
    }
    return '';
  }

  private static ValidateShuntLengthBetweenNozzles(shuntTube: ShuntTube): string {
    if (shuntTube.NozzleProperties.IsNozzleLeakoff && shuntTube.NozzleProperties.LengthBetweenNozzles <= 0) {
      return 'Length between nozzles must be greater than 0';
    }
    return '';
  }

  private static ValidateIsNozzleLeakoff(
    shuntTube: ShuntTube,
    well: WellModuleState,
    currentLicenseFeatures: IGetCurrentFeaturesResult,
  ): string {
    if (shuntTube.NozzleProperties.IsNozzleLeakoff) {
      const requiredFeatureError = validateFeatureRequired([LicenseFeature.Simulate, LicenseFeature.Evaluate], currentLicenseFeatures);
      if (requiredFeatureError) {
        return requiredFeatureError;
      }
    }

    if (well.IsLossRateCalculated && shuntTube.NozzleProperties.IsNozzleLeakoff) {
      return 'Nozzle Leakoff is not supported when using calculate losses';
    }
    return '';
  }
}
