import { RheometerDto, RotorBobCombination, SpeedType, TorsionSpringAssembly } from '../../dto/rheometer.dto';
import { Rheometer } from './rheometer';
import { createTableRow, createTableState, GridRowType, ITableRow, ITableState } from '../../../../common/common-grid.interfaces';
import { DictionaryWithArray, IDictionaryWithArray } from '../../../../common/state.helpers';
import { groupBy } from 'lodash';

export class RheometerFactory {
  public static create(rheometerDtos: RheometerDto[], fluidIds: string[]): IDictionaryWithArray<ITableState<Rheometer>> {
    const rheometers = [...rheometerDtos];
    const rheometersGroupedByFluidId = groupBy(rheometers, (data) => data.FluidId);

    let dict: IDictionaryWithArray<ITableState<Rheometer>> = {
      ids: [],
      dict: {},
    };
    for (const fluidId of fluidIds) {
      const rh = rheometersGroupedByFluidId[fluidId] ?? [];
      dict = DictionaryWithArray.upsertById(dict, this.createTableStateForFluidId(rh), fluidId);
    }
    return dict;
  }

  public static createTableStateForFluidId(fluidRheometers: RheometerDto[]): ITableState<Rheometer> {
    const tableRows = [...fluidRheometers.map((dto, index) => this.createRheometerRowFromDto(dto, index))];
    return createTableState(tableRows);
  }

  public static createRheometerRowFromDto(
    rheometer: RheometerDto,
    index: number,
    isEditingDisabled = false,
    rowType: GridRowType = 'data',
  ): ITableRow<Rheometer> {
    return createTableRow(this.createRheometer(rheometer), rowType, index, isEditingDisabled);
  }

  public static createRheometer(rheometerDto: RheometerDto): Rheometer {
    return { ...rheometerDto, error: {} };
  }

  public static rheometerToDto(rheometerData: Rheometer): RheometerDto {
    const { error, ...rheometerDto } = rheometerData;
    return rheometerDto;
  }

  public static newRheometer(fluidId: number, scenarioId: number, temperature: number, speedType: SpeedType): Rheometer {
    return {
      Id: -1,
      FluidId: fluidId,
      Temperature: temperature,
      RotorBobCombination: RotorBobCombination.R1B1,
      TorsionSpringAssembly: TorsionSpringAssembly.F1,
      SpeedType: speedType,
      IsShuntRefCurveShown: false,
      SortOrder: -1,
      ScenarioId: scenarioId,
      error: {},
    };
  }
}
