<div class="grid-form-container">
  <div class="grid-two-one">
    <app-radio-group
      id="lossDefinitionRadio"
      [items]="specifyLossRadioItems"
      layout="vertical"
      [source]="state"
      [key]="'IsLossRateCalculated'"
      dataCy="loss-definition"
      (valueChanged)="propertyChanged.emit($event)"
    >
    </app-radio-group>
  </div>
</div>
