import { createReducer, on } from '@ngrx/store';
import * as actions from './common-db.actions';
import { CommonDbReducerHelper } from './common-db.reducer.helper';
import { createTableState, ITableState } from '@dunefront/common/common/common-grid.interfaces';
import { CommonFluidModel } from './model/common-fluid.model';
import { CommonGravelModel } from './model/common-gravel.model';
import { GlobalOptionsDto } from '@dunefront/common/dto/global-options.dto';
import { deleteRowsSuccess, updateRowSuccess } from '../app.actions';

export const CommonDbModuleFeatureName = 'commonDb';

export interface CommonDbModuleState {
  CommonFluids: ITableState<CommonFluidModel>;
  CommonGravels: ITableState<CommonGravelModel>;
  PredefinedGlobalOptions: GlobalOptionsDto | null;
  OrganizationGlobalOptions: GlobalOptionsDto | null;
  PersonalGlobalOptions: GlobalOptionsDto | null;
}

export interface ICommonDbModuleState {
  availableCommonFluids: ITableState<CommonFluidModel>;
  availableCommonGravels: ITableState<CommonGravelModel>;
  predefinedGlobalOptions: GlobalOptionsDto | null;
  orgGlobalOptions: GlobalOptionsDto | null;
  personalGlobalOptions: GlobalOptionsDto | null;
}

const initialCommonDbModuleState: ICommonDbModuleState = {
  availableCommonFluids: createTableState([]),
  availableCommonGravels: createTableState([]),
  predefinedGlobalOptions: null,
  orgGlobalOptions: null,
  personalGlobalOptions: null,
};

export const commonDbModuleReducer = createReducer<ICommonDbModuleState>(
  initialCommonDbModuleState,
  on(actions.loadCommonDbInitialSuccess, (state, response) => CommonDbReducerHelper.onCommonDbInitialDataSuccess(state, response.payload)),
  on(deleteRowsSuccess, (state, response) => CommonDbReducerHelper.onDeleteRowsSuccess(state, response)),
  on(actions.loadCommonFluidsSuccess, (state, response) => CommonDbReducerHelper.onLoadCommonFluidsSuccess(state, response)),
  on(actions.loadCommonGravelsSuccess, (state, response) => CommonDbReducerHelper.onLoadCommonGravelsSuccess(state, response)),

  on(actions.changeGlobalOptionsPersonalProperty, (state, action) =>
    CommonDbReducerHelper.changeGlobalOptionsPersonalProperty(state, action),
  ),
  on(actions.changeGlobalOptionsOrgProperty, (state, action) => {
    return CommonDbReducerHelper.changeGlobalOptionsOrgProperty(state, action);
  }),
  on(actions.updateGlobalOptions, (state, { commonDbType, globalOptions }) =>
    CommonDbReducerHelper.onUpdateGlobalOptions(state, commonDbType, globalOptions),
  ),
  on(updateRowSuccess, (state, action): ICommonDbModuleState => CommonDbReducerHelper.onUpdateRowSuccess(state, action)),
);
