import { RedisConnectionStatus } from '@dunefront/common/modules/calculation-engine/calculation-engine.interfaces';
import { CalculationResultsInfoDto } from '@dunefront/common/modules/calculation-engine/dto/calculation-results-info.dto';
import { ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import { Dictionary } from 'lodash';
import { IFile } from '@dunefront/common/dto/file.dto';

export interface CalculationEngineState {
  fileHash: Dictionary<CurrentFileCalculationEngineState>;
  redisConnectionStatus: RedisConnectionStatus;
}

export interface CurrentFileCalculationEngineState {
  resultsInfos: CalculationResultsInfoDto[];
  jobs: ICalculationJob[];
  lastCalculationJobReport: string;
}

export const getEmptyFileCalculationEngineState = (): CurrentFileCalculationEngineState => ({
  jobs: [],
  lastCalculationJobReport: '',
  resultsInfos: [],
});

export enum CalculationStatus {
  waiting = 0, // waiting on the server - not requested on server
  starting = 1, // job has been sent to server by startCalculationAction
  running = 2, // first update from running calculation has been received from server
  completed = 3, // calculation complete message has been received from server
  canceling = 4, // cancellation requested on the client - not cancelled on server
  canceled = 5, // calc engine has sent completed action for cancelling job
  failed = 6, // calc engine sent error
}

export interface ICalculationJob {
  jobId: string;
  scenarioId: number;
  rangeId: number;
  fileHash: string;
  moduleType: ModuleType;
  status: CalculationStatus;
  dbFile: IFile;
  positionInRemoteQueue?: number;
}

export const calculationEngineInitialState: CalculationEngineState = {
  fileHash: {},
  redisConnectionStatus: 'disconnected',
};

export const calculationStatusToString = (status: CalculationStatus): string => {
  switch (status) {
    case CalculationStatus.waiting:
      return 'waiting';
    case CalculationStatus.starting:
      return 'starting';
    case CalculationStatus.running:
      return 'running';
    case CalculationStatus.completed:
      return 'completed';
    case CalculationStatus.canceling:
      return 'canceling';
    case CalculationStatus.canceled:
      return 'canceled';
    case CalculationStatus.failed:
      return 'failed';
  }
};

export const isUiBlockingStatus = (status: CalculationStatus): boolean =>
  status === CalculationStatus.waiting ||
  status === CalculationStatus.starting ||
  status === CalculationStatus.running ||
  status === CalculationStatus.canceling;
