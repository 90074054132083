import { PipeDto } from './pipe.dto';
import { BypassPorts } from '../modules/pipes/bypass-ports';

export interface LowerCompletionPipeDto extends PipeDto {
  BasePipeOD: number;
  FilterID: number;
  JointLength: number;
  HandlingLength: number;
  ICDType: ICDType;
  NoOf0_075inPorts: number;
  NoOf0_100inPorts: number;
  NoOf0_125inPorts: number;
  NoOf0_150inPorts: number;
  A_Coefficient_Production: number;
  B_Coefficient_Production: number;
  C_Coefficient_Production: number;
  A_Coefficient_Injection: number;
  B_Coefficient_Injection: number;
  C_Coefficient_Injection: number;
  Description: string;
}

export interface LowerCompletionPipeDtoWithICDPorts extends LowerCompletionPipeDto {
  ICDPorts: BypassPorts[];
}

export enum ICDType {
  None = 0,
  Generic_ICD = 1,
  Halliburton_EquiFlow_AICD_Range_1 = 2,
  Halliburton_EquiFlow_AICD_Range_2 = 3,
  Halliburton_EquiFlow_AICD_Range_3 = 4,
  Halliburton_EquiFlow_AICD_Range_4 = 5,
  Halliburton_EquiFlow_Nozzle_ICD = 6,
  BHGE_ICD_Or_AICD = 7,
}
