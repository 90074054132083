import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { ITableRow, ITableState } from '@dunefront/common/common/common-grid.interfaces';
import { getChartConfig, IChartResultColumn } from '../../../+store/reporting/reporting.selectors';
import { PageWithGridComponent } from '../../../shared/components/grid/page-with-grid.component';
import { ModalService } from '../../modals/modal.service';
import { ScreenService } from '../../../shared/services';
import { ChartUpdateType, setChartResultColumns } from '../../../+store/reporting/reporting.actions';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { IChartUnitDetails, UnitConverterHelper } from '@dunefront/common/unit-converters/unit.converter.helper';
import { IUpdateTableRowsProps } from '@dunefront/common/common/common-store-crud.interfaces';
import { IChartConfig } from '../../../+store/reporting/reporting-module.state';
import { DataFileType } from '@dunefront/common/dto/data-storage';

@Component({
  template: '',
})
export class BaseChartConfigurationComponent extends PageWithGridComponent<IChartResultColumn> {
  @Input() public mode!: 'new' | 'edit';

  public chartId?: number;
  public resultColumnsTableState!: ITableState<IChartResultColumn>;
  public chartUnitDetails: Array<IChartUnitDetails> = [];

  public get isSomethingSelected(): boolean {
    for (const row of this.resultColumnsTableState.rows) {
      if (row.rowData.PrimaryLeft || row.rowData.SecondaryLeft || row.rowData.PrimaryRight || row.rowData.SecondaryRight) {
        return true;
      }
    }
    return false;
  }

  constructor(
    protected ref: DynamicDialogRef,
    store: Store,
    cdRef: ChangeDetectorRef,
    modalService: ModalService,
    resizeService: ScreenService,
  ) {
    super(store, cdRef, modalService, resizeService);

    this.subscription.add(
      store.select(getChartConfig).subscribe((config: IChartConfig | undefined) => {
        if (config) {
          this.chartId = config.chartId ?? 0;
          this.resultColumnsTableState = config.resultColumnsTableState;
          this.chartUnitDetails = this.resultColumnsTableState.rows.map((row) => {
            const unitSystem = UnitConverterHelper.getUnitSystemFromDataType(row.rowData.DataType);
            return UnitConverterHelper.getUnitTypeAndName(row.rowData.DataType, unitSystem);
          });
        } else {
          this.chartId = 0;
          this.resultColumnsTableState = { rows: [], isValid: true };
        }
        cdRef.markForCheck();
      }),
    );
  }

  public onClearClicked(): void {
    this.store.dispatch(setChartResultColumns({ updateType: ChartUpdateType.clear }));
  }

  public onCancelClicked(): void {
    this.ref.close();
  }

  public onOkClicked(): void {
    // must be overridden to save config
    this.ref.close();
  }

  public getRows(): ITableRow<IChartResultColumn>[] {
    return this.resultColumnsTableState.rows;
  }

  protected updateRow(row: IUpdateTableRowsProps<IChartResultColumn>): void {
    this.gridComponent?.grid.gridConfig?.updateRowsAction(row);
  }

  public isImportDataColumnSelected(): boolean {
    for (const row of this.resultColumnsTableState.rows) {
      if (
        [
          DataFileType.ImportedData,
          DataFileType.MergedData,
          DataFileType.TrendAnalysisData,
          DataFileType.EquationResult,
          DataFileType.DataWithXYAxisShifts,
        ].includes(row.rowData.FileType) &&
        (row.rowData.PrimaryLeft || row.rowData.SecondaryLeft || row.rowData.PrimaryRight || row.rowData.SecondaryRight)
      ) {
        return true;
      }
    }
    return false;
  }
}
