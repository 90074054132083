import { isArray } from 'lodash';

export abstract class CommonModalComponentBase {
  public get messages(): string[] {
    return isArray(this.message) ? this.message : [this.message];
  }

  protected constructor(
    public title: string,
    public message: string | string[],
  ) {}
}
