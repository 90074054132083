import { Injectable } from '@angular/core';
import { BaseWsEffects } from '../base-ws.effects';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BackendConnectionService } from '../../shared/backend-connection/backend-connection.service';
import { ModalService } from '../../common-modules/modals/modal.service';
import { Router } from '@angular/router';
import {
  FileSettingsModuleName,
  LockInputsAction,
  UnlockInputsAction,
  UpdateFileSettingsAction,
} from '@dunefront/common/modules/file-settings/file-settings-module.actions';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { CrudResponse } from '@dunefront/common/modules/common.actions';
import { dataFailed, updateRowSuccess } from '../app.actions';
import { of } from 'rxjs';
import { lockInputsAction, unlockInputsAction, updateLastUsedScenarioId } from './file-settings.actions';

@Injectable()
export class FileSettingsEffects extends BaseWsEffects {
  constructor(
    actions$: Actions,
    store: Store,
    wsService: BackendConnectionService,
    modalService: ModalService,
    protected router: Router,
  ) {
    super(actions$, wsService, FileSettingsModuleName, false, true, modalService, store);
  }

  // region lock inputs

  public lockInputs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(lockInputsAction),
      mergeMap((action) =>
        this.emit<CrudResponse>(new LockInputsAction(action.name)).pipe(
          map((response) => updateRowSuccess(response.payload)),
          catchError((err) => of(dataFailed(err))),
        ),
      ),
    ),
  );

  // endregion

  // region unlock inputs

  public unlockInputs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(unlockInputsAction),
      mergeMap(() =>
        this.emit<CrudResponse>(new UnlockInputsAction()).pipe(
          map((response) => updateRowSuccess(response.payload)),
          catchError((err) => of(dataFailed(err))),
        ),
      ),
    ),
  );

  // endregion

  // region update file settings

  public updateLastUsedScenarioId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateLastUsedScenarioId),
      mergeMap((action) => {
        return this.emit<CrudResponse>(new UpdateFileSettingsAction(action.scenarioId)).pipe(
          map((response) => updateRowSuccess(response.payload)),
          catchError((err) => of(dataFailed(err))),
        );
      }),
    ),
  );

  // endregion
}
