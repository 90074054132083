export const whiteBackgroundPlugin = {
  id: 'canvas_white_bg',
  beforeDraw: (chart: any): any => {
    const ctx = chart.canvas.getContext('2d');
    ctx.save();
    ctx.globalCompositeOperation = 'destination-over';
    ctx.fillStyle = '#fff';
    ctx.fillRect(0, 0, chart.width, chart.height);
    ctx.restore();
  },
};
