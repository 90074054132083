import { IModuleState } from '../../common/common-state.interfaces';
import { DeveloperSettingsDto, SettingsDto, WellVisualizationType } from './dto/settingsDto';

export interface SettingsModuleState {
  settings: SettingsDto & IModuleState;
  developerSettings: DeveloperSettingsDto & IModuleState;
  defaults: SettingsDto;
  developerSettingsDefaults: DeveloperSettingsDto;
  wellVisualizationType: WellVisualizationType | undefined;
}

export const settingsInitialState: SettingsModuleState = {
  settings: { ...({} as SettingsDto), isLoaded: false },
  developerSettings: { ...({} as DeveloperSettingsDto), isLoaded: false },
  defaults: { ...({} as SettingsDto) },
  developerSettingsDefaults: { ...({} as DeveloperSettingsDto) },
  wellVisualizationType: undefined,
};
