import { createSelector } from '@ngrx/store';
import { getImportDataStorageFiles, getStorageColumnNames, getStorageColumns } from '../../data-storage/data-storage.selectors';
import { getCurrentRange, getCurrentScenarioRangeProperties } from '../../range/range.selectors';
import { RangeConstants, RangeDto } from '@dunefront/common/dto/range.dto';
import { AnalysisDataComponentValidation } from '@dunefront/common/modules/pumping/model/pumped-fluid-and-gravel/analysis-data-component.validation';
import {
  AnalysisDataComponentSelectItems,
  IAnalysisDataComponentSelectedValues,
  ValidatedAnalysisDataComponentState,
} from '@dunefront/common/modules/pumping/pumping-module.state';
import { getCurrentAppModuleType } from '../../ui/ui.selectors';
import { ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import { ImportFileFactory, ImportFileWithMinMaxArgumentsDto } from '@dunefront/common/modules/data-storage/dto/import-file.dto';
import { DictionaryWithArray, IDictionaryWithArray } from '@dunefront/common/common/state.helpers';
import { ImportColumnDto } from '@dunefront/common/modules/data-storage/dto/import-column.dto';
import { ISelectItem, toSelectItem } from '@dunefront/common/common/select.helpers';
import { DataType } from '@dunefront/common/dto/data-storage';

export const getValidatedDataAnalysisComponentSelectItems = createSelector(
  getCurrentRange,
  getCurrentScenarioRangeProperties,
  getImportDataStorageFiles,
  getStorageColumns,
  getStorageColumnNames,
  getCurrentAppModuleType,
  (
    ...[currentRange, currentScenarioRangeProperties, importFiles, storageColumns, colNames, moduleType]
  ): ValidatedAnalysisDataComponentState => {
    if (!currentRange || currentRange.Id <= RangeConstants.EntireRangeId) {
      return {
        pumpPressureItems: [],
        pumpRateItems: [],
        returnRateItems: [],
        gravelConcItems: [],
        currentScenarioRangeProperties,
        error: {},
        isValid: true,
        isLoaded: false,
      };
    }
    const selectItems = getAnalysisDataComponentSelectItems(importFiles, storageColumns, currentRange);

    return AnalysisDataComponentValidation.validate(
      {
        ...selectItems,
        currentScenarioRangeProperties,
        isLoaded: true,
      },
      colNames,
      moduleType === ModuleType.Trend_Analysis,
    );
  },
);

export const getDataAnalysisComponentSelectedValues = createSelector(
  getValidatedDataAnalysisComponentSelectItems,
  (state): IValidatedAnalysisDataComponentStateWithSelectedValues => {
    const selectedValues: IAnalysisDataComponentSelectedValues = {};

    const getSelectedValue = (items: ISelectItem<number>[], selectedColumnName: string | undefined): number => {
      if (items.length === 1) {
        return items[0].value as number;
      } else {
        return +(items.find((r) => r.text === selectedColumnName)?.value ?? -1);
      }
    };

    selectedValues.selectedPumpRateId = getSelectedValue(state.pumpRateItems, state.currentScenarioRangeProperties?.PumpRateColumnName);
    selectedValues.selectedPumpPressureId = getSelectedValue(
      state.pumpPressureItems,
      state.currentScenarioRangeProperties?.PumpPressureColumnName,
    );
    selectedValues.selectedReturnRateId = getSelectedValue(
      state.returnRateItems,
      state.currentScenarioRangeProperties?.ReturnRateColumnName,
    );
    selectedValues.selectedGravelConcId = getSelectedValue(
      state.gravelConcItems,
      state.currentScenarioRangeProperties?.GravelConcColumnName,
    );

    return { state, selectedValues };
  },
);

export interface IValidatedAnalysisDataComponentStateWithSelectedValues {
  state: ValidatedAnalysisDataComponentState;
  selectedValues: IAnalysisDataComponentSelectedValues;
}

const getAnalysisDataComponentSelectItems = (
  importFiles: ImportFileWithMinMaxArgumentsDto[],
  storageColumns: IDictionaryWithArray<ImportColumnDto[]>,
  currentRange: RangeDto,
): AnalysisDataComponentSelectItems => {
  const selectItems: AnalysisDataComponentSelectItems = {
    gravelConcItems: [],
    returnRateItems: [],
    pumpPressureItems: [],
    pumpRateItems: [],
  };

  const checkIfEmpty = (items: ISelectItem<number>[]): void => {
    if (items.length === 0) {
      items.push(toSelectItem(-1, 'No Columns'));
    }
  };

  const filItems = (items: ISelectItem<number>[], fileColumns: ImportColumnDto[], dataType: DataType): void => {
    const filteredItems = fileColumns.filter((col) => col.DataType === dataType).map((col) => toSelectItem(col.Id, col.ColumnName));

    items.push(...filteredItems);
  };

  importFiles.forEach((file) => {
    const fileColumns = DictionaryWithArray.get(storageColumns, file.Id) ?? [];

    // check if entire file is inside range
    if (ImportFileFactory.isFileInsideRange(file, currentRange, fileColumns)) {
      filItems(selectItems.pumpRateItems, fileColumns, DataType.Pump_Rate);
      filItems(selectItems.pumpPressureItems, fileColumns, DataType.Pump_Pressure);
      filItems(selectItems.returnRateItems, fileColumns, DataType.Return_Rate);
      filItems(selectItems.gravelConcItems, fileColumns, DataType.Surface_Gravel_Concentration);
    }
  });

  checkIfEmpty(selectItems.pumpRateItems);
  checkIfEmpty(selectItems.pumpPressureItems);
  checkIfEmpty(selectItems.returnRateItems);
  checkIfEmpty(selectItems.gravelConcItems);

  return selectItems;
};
