import { ValidatedImportColumnDto } from './data-storage-module.state';
import { ImportColumnDto } from './dto/import-column.dto';
import { ImportFileDto, ImportFileWithMinMaxArgumentsDto } from './dto/import-file.dto';
import { GaugeMeasurementType } from '../../dto/running-string-pipe.dto';
import { noErrors } from '../../common/state.helpers';
import { IError } from '../../common/common-state.interfaces';
import { DataFileType, DataType } from '../../dto/data-storage';
import { EquationColumn } from '../equation/equation-column';
import { ModuleType } from '../scenario/scenario.dto';

export interface IValidatedStorageFileWithColumns {
  file: ImportFileDto | undefined;
  columns: ValidatedImportColumnDto[];
  equationValidationError: string;
  isValid: boolean;
}

export type ImportOrEquationColumn = ValidatedImportColumnDto | EquationColumn;

export class DataStorageValidation {
  public static validateColumns(
    columns: ImportColumnDto[],
    importFiles: ImportFileWithMinMaxArgumentsDto[],
    importColumns: ImportColumnDto[],
    importFileMaxTimespan: number,
    maxSurveyMD: number,
    moduleType: ModuleType,
  ): ValidatedImportColumnDto[] {
    return columns.map((col) => this.validateColumn(col, importFiles, importColumns, importFileMaxTimespan, maxSurveyMD, moduleType));
  }

  public static isEquationValid(equationFile: ImportFileWithMinMaxArgumentsDto, fileHash: string): string {
    if (equationFile.FileType !== DataFileType.EquationResult) {
      return '';
    }

    if (!equationFile.EquationHash) {
      return 'Equation does not have results yet.';
    }

    if (equationFile.EquationHash !== fileHash) {
      return 'Equation results are outdated.';
    }

    return '';
  }

  private static validateColumn(
    col: ImportColumnDto,
    importFiles: ImportFileWithMinMaxArgumentsDto[],
    importColumns: ImportColumnDto[],
    importFileMaxTimespan: number,
    maxSurveyMD: number,
    moduleType: ModuleType,
  ): ValidatedImportColumnDto {
    const error: IError<ImportColumnDto> = {
      StartDate: this.validateStartDate(col, importFiles, importColumns, importFileMaxTimespan),
      MeasuredDepth: this.validateMeasureDepth(col, moduleType, maxSurveyMD),
      SmoothingPoints: this.validateSmoothingPoints(col),
      MeasurementType: this.validateMeasurementType(col),
    };
    return { ...col, error, isValid: noErrors(error) };
  }

  private static validateStartDate(
    col: ImportColumnDto,
    importFiles: ImportFileWithMinMaxArgumentsDto[],
    _: any,
    importFileMaxTimespan: number,
  ): string {
    if (col.DataType !== DataType.Time) {
      return '';
    }

    const currentFile = importFiles.find((file) => file.Id === col.FileId);
    if (currentFile == null) {
      throw `Can't find file with id: ${col.FileId} for column: ${col.ColumnName}`;
    }

    if (currentFile.FileType !== DataFileType.ImportedData) {
      return '';
    }

    let startOfFirstFile = Number.MAX_VALUE;

    for (const file of importFiles.filter((f) => f.FileType === DataFileType.ImportedData)) {
      if (file.MinArg < startOfFirstFile) {
        startOfFirstFile = file.MinArg;
      }
    }

    const endOFCurrentFile = currentFile.MaxArg;
    const thresholdInSec = importFileMaxTimespan * 24 * 60 * 60;

    const d1 = new Date(endOFCurrentFile * 1000).toLocaleString();
    const d2 = new Date(startOfFirstFile * 1000).toLocaleString();

    if (currentFile.MaxArg > startOfFirstFile + thresholdInSec) {
      return `Last time of this file (${d1}) is more than ${importFileMaxTimespan} days from first time of the earliest file (${d2})`;
    }

    return '';
  }

  private static validateMeasureDepth(col: ImportColumnDto, moduleType: ModuleType, maxSurveyMD: number | undefined): string {
    if (col.DataType == DataType.Bottomhole_Pressure || col.DataType == DataType.Bottomhole_Temperature) {
      // Validate the MD
      if (col.MeasuredDepth <= 0) {
        return 'Measured depth must be greater than zero';
      } else if (moduleType === ModuleType.Evaluate && maxSurveyMD != null && col.MeasuredDepth > maxSurveyMD) {
        return 'Measured depth must be less than or equal to maximum survey MD';
      }
    }
    return '';
  }

  private static validateSmoothingPoints(col: ImportColumnDto): string {
    if (col.SmoothingPoints < 0 || col.SmoothingPoints > 30) {
      return 'Smoothing points must be between 0 and 30';
    }
    return '';
  }

  private static validateMeasurementType(col: ImportColumnDto): string {
    if (col.DataType == DataType.Bottomhole_Pressure || col.DataType == DataType.Bottomhole_Temperature) {
      //Validate the measurement type
      if (col.MeasurementType != GaugeMeasurementType.Internal && col.MeasurementType != GaugeMeasurementType.External) {
        return 'Measurement type must be defined';
      }
    }
    return '';
  }
}
