import { createReducer, on } from '@ngrx/store';
import { dataStorageInitialState, DataStorageModuleState } from '@dunefront/common/modules/data-storage/data-storage-module.state';
import { deleteRowsSuccess, insertRowsSuccess, loadScenarioDataSuccessAction, updateRowSuccess } from '../app.actions';
import { DataStorageReducerHelper } from './data-storage.reducer.helper';
import { updateEquationFileHash } from './data-storage.actions';

export const dataStorageModuleReducer = createReducer<DataStorageModuleState>(
  dataStorageInitialState,
  on(loadScenarioDataSuccessAction, (state, action) =>
    DataStorageReducerHelper.onLoadScenarioDataSuccessAction(state, action.loadScenarioResponse.dataStorageModule),
  ),
  on(insertRowsSuccess, (state, action) => DataStorageReducerHelper.insertRowsSuccess(state, action)),
  on(deleteRowsSuccess, (state, action) => DataStorageReducerHelper.deleteRowsSuccess(state, action)),
  on(updateRowSuccess, (state, action) => DataStorageReducerHelper.updateRowsSuccess(state, action)),
  on(updateEquationFileHash, (state, action) =>
    DataStorageReducerHelper.updateEquationFileHash(state, action.fileId, action.fileHash, action.updateDate),
  ),
);
