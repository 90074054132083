import { GravelDto, MeshSize } from '../dto/gravel.dto';
import { DictionaryWithArray, IDictionaryWithArray } from '../../../common/state.helpers';
import { Gravel } from './gravel';
import { FluidGravelDatabaseType } from '../../fluid/dto/fluid.dto';
import { ISelectItem } from '../../../common/select.helpers';
import { GravelModuleState } from '../gravel-module.state';
import { LoadGravelsActionResponse } from '../gravel-module.actions';

export class GravelFactory {
  public static create(payload: LoadGravelsActionResponse): GravelModuleState {
    const Gravels = GravelFactory.createDictionaryWithArray(payload.gravelDtos);
    return { Gravels, SelectedGravelId: +Gravels.ids[0], isLoaded: true };
  }

  public static createDictionaryWithArray(gravelDtos: GravelDto[]): IDictionaryWithArray<Gravel> {
    const gravels = gravelDtos.map((gravelDto) => this.createItem(gravelDto));
    return DictionaryWithArray.create<Gravel>(gravels, 'Id');
  }

  public static createItem(gravelDto: GravelDto): Gravel {
    const gravel: Gravel = {
      ...gravelDto,
      isValid: true,
      error: {},
    };
    return gravel;
  }

  public static toLookupData(src: IDictionaryWithArray<Gravel>): ISelectItem<number>[] {
    const lookupData: ISelectItem<number>[] = DictionaryWithArray.getArray(src).map((gravel) => ({
      value: gravel.Id,
      text: gravel.Name,
    }));
    return lookupData;
  }

  public static gravelToDto(gravel: Gravel): GravelDto {
    return {
      Id: gravel.Id,
      ScenarioId: gravel.ScenarioId,
      BulkDensity: gravel.BulkDensity,
      AbsoluteDensity: gravel.AbsoluteDensity,
      MeanDiameter: gravel.MeanDiameter,
      MeshSize: gravel.MeshSize,
      Name: gravel.Name,
      PackPermeability: gravel.PackPermeability,
      DatabaseType: gravel.DatabaseType,
      ThermalConductivity: gravel.ThermalConductivity,
      SpecificHeatCapacity: gravel.SpecificHeatCapacity,
      OroskarAndTurianCoefficient: gravel.OroskarAndTurianCoefficient,
      AngleOfRepose: gravel.AngleOfRepose,
      FrictionMultiplier: gravel.FrictionMultiplier,
      FrictionMultiplierDeviation: gravel.FrictionMultiplierDeviation,
      IsFrictionMultiplierByDeviation: gravel.IsFrictionMultiplierByDeviation,
      SortOrder: -1,
      Notes: gravel.Notes
    };
  }

  public static createEmptyGravelDto = (scenarioId: number, name?: string): GravelDto => ({
    Id: -1,
    ScenarioId: scenarioId,
    BulkDensity: 0,
    AbsoluteDensity: 0,
    MeanDiameter: 0,
    MeshSize: MeshSize['6/12'],
    Name: name ?? '',
    PackPermeability: 0,
    DatabaseType: FluidGravelDatabaseType.Predefined,
    ThermalConductivity: 0.2,
    SpecificHeatCapacity: 830,
    OroskarAndTurianCoefficient: 1.85,
    AngleOfRepose: 30,
    SortOrder: -1,
    FrictionMultiplier: 1,
    FrictionMultiplierDeviation: 45,
    IsFrictionMultiplierByDeviation: false,
    Notes: ''
  });

  public static newGravel(scenarioId: number, name?: string): Gravel {
    const gravelDto: GravelDto = this.createEmptyGravelDto(scenarioId, name);
    return this.createItem(gravelDto);
  }
}
