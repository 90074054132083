import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  getArgumentAxisUnit,
  IChartDataDto,
  IChartDataDtoColumn,
  LineChartDataSet,
} from '@dunefront/common/modules/reporting/dto/chart-data.dto';
import {
  getAnnotationsVisible,
  getGradientLinesVisible,
  getMarkersVisible,
  getUiChartMode,
  getUiChartZoomMode,
  getXAutoShift,
  getXYAxisShift,
  getYAutoShift,
} from '../../+store/ui/ui.selectors';
import {
  Chart,
  ChartConfiguration,
  Interaction,
  LayoutPosition,
  LegendItem,
  LegendOptions,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  registerables,
  ScaleOptionsByType,
  ScatterDataPoint,
  Title,
} from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import annotationPlugin, { LineAnnotationOptions } from 'chartjs-plugin-annotation';
import { UnitConverterHelper } from '@dunefront/common/unit-converters/unit.converter.helper';
import { DbDependentComponent } from '../db-connection/db-dependent.component';
import { Store } from '@ngrx/store';
import { ScreenService } from '../../shared/services';
import { selectUserGlobalOptions } from '../../+store/common-db/common-db.selectors';
import { filterNil, notEmpty, observableToBehaviorSubject, PartialEnumDictionary } from '@dunefront/common/common/state.helpers';
import { ConvertUnitPipe } from '@dunefront/common/modules/units/convert-unit.pipe/convert-unit.pipe';
import { DataFileType, DataType } from '@dunefront/common/dto/data-storage';
import { ChartMode, XYShiftAxis } from '../../+store/ui/ui-module.state';
import { IXAxisShiftUpdate, IYAxisShiftUpdate } from '../../+store/reporting/reporting-module.state';
import { VerticalShiftDto } from '@dunefront/common/dto/trend-analysis/vertical-shift.dto';
import { getScenariosToCompare } from '../../+store/scenario/scenario.selectors';
import { DeepPartial } from 'chart.js/dist/types/utils';
import { rationalizeString, toNumber } from '@dunefront/common/common/helpers';
import { ChartAxis, IAxisMargin, IAxisStyle, XAxisFormat } from '@dunefront/common/modules/reporting/dto/chart-axis-property.dto';
import { ChartLegendLocation, defaultLegendFontSize, ILegendStyle } from '@dunefront/common/modules/reporting/dto/chart-legend';
import {
  getCrosshairMode,
  getMaxSeriesInTooltip,
  getTooltipPosition,
  selectChartSeriesTemplates,
} from '../../+store/reporting/reporting.selectors';
import { ChartSeriesTemplateDto } from '@dunefront/common/dto/chart-series-template.dto';
import { IMarker, IMarkerStyle } from '@dunefront/common/modules/reporting/dto/chart-marker.dto';
import { IGlobalSeriesStyles } from '@dunefront/common/modules/reporting/dto/chart-series.dto';
import { chartResetZoomAction, ResetZoomMode } from '../../+store/ui/ui.actions';
import { DuneFrontAnnotationId, duneFrontAnnotationPlugin } from './annotations-plugin/dunefront-annotations';
import { Annotation } from './annotations-plugin/annotation';
import { DuneFrontAnnotationOptions, DuneFrontAnnotationPlugin } from './annotations-plugin/types';
import { ChartDataHelpers, MinMax } from './chart-component-helpers/chart-data-helpers';
import {
  axisTypeFromColumn,
  borderDashForLineStyle,
  getArgumentAxisPrimaryArgSiValueFromPoint,
  getAxisId,
  getAxisTicksFont,
  getAxisTitleFont,
  getAxisUnit,
  getAxisUnitForColumn,
  getChartAxisFromAxisId,
  getFirstAvailableValueAxis,
  getFirstAvailableValueScale,
  getFirstValueAxisSiValueFromPoint,
  getFontSpec,
  getGetChartAxisIdAtEvent,
  getIsTimeAxis,
  hasManualAxisLimitChanged,
  OrderedValueAxes,
  setActiveMarker,
  setMouseMoveMode,
  setTooltipEnabled,
  updateColumnsVisibility,
} from './chart-component-helpers/chart-misc-helpers';
import {
  AllowedXYShiftAxis,
  ChartContext,
  ConfigureSeriesPayload,
  IAnnotation,
  IArgumentRange,
  IAutoXAxisShiftParams,
  IAutoYAxisShiftParams,
  IAxisData,
  IAxisProps,
  IEditAxisProps,
  IGradientLine,
  MouseMoveMode,
} from './chart-component-helpers/chart-types';
import { ChartMarkerHelpers, CreateMarkerPayload, getMarkerUnderMouseEvent } from './chart-component-helpers/chart-marker-helpers';
import { IAnnotationStyle } from '@dunefront/common/modules/reporting/dto/chart-annotation.dto';
import { ChartZoomedDataService } from './chart-component-helpers/chart-zoomed-data.service';
import { IAxisUnit } from '@dunefront/common/unit-converters/converter.interfaces';
import { crosshairPlugin } from './crosshair-plugin/crosshair-plugin';
import { interpolate } from './crosshair-plugin/interpolate';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { isAxisShiftAllowed } from './chart-component-helpers/chart-xy-axis-shift-helpers';
import { IUnitSystemDto, UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { ZoomPluginHelper } from './chart-component-helpers/chart-data-zoom-helpers';
import { patchChart, updateChartWithHitRadiusFix } from './chart-component-helpers/chart-js-patches';
import { defaultCrosshairLineColor, transparentCrosshairLineColor } from './crosshair-plugin/constants';
import {
  checkAnnotations,
  CreateAnnotationPayload,
  getAnnotationPluginOptions,
  migrateAnnotations,
} from './chart-component-helpers/chart-annotation-helpers';
import { ImportFileWithMinMaxArgumentsDto } from '@dunefront/common/modules/data-storage/dto/import-file.dto';
import { whiteBackgroundPlugin } from './additional-plugins/white-background';
import {
  COMMON_DATE_TIME_SECOND_STRING_FORMAT,
  defaultCopyChartRatio,
  defaultLegendFontColor,
  defaultMaxSeriesInTooltip,
} from '@dunefront/common/common/constants';
import { ChartCopyOptionsDto } from '@dunefront/common/modules/reporting/dto/chart-copy-options.dto';
import { LineStyle, SeriesLineStyle } from '@dunefront/common/modules/reporting/dto/chart.types';
import { copyChartRatioFromStr } from '@dunefront/common/common/chart-helpers/chart-copy-options.helpers';
import { getCrosshairPluginOptions, getCrosshairState, getInterpolatedValue, updateCrosshairOptions } from './crosshair-plugin/helpers';
import { ChartDataSeriesStyleHelpers } from './chart-component-helpers/chart-data-series-style-helpers';
import { updateChart } from './chart-component-helpers/chart-common-helpers';
import { getAxisUnitsSummary } from './chart-component-helpers/chart-axis-units-summary-helpers';
import { ChartDataAxisLimitsHelpers } from './chart-component-helpers/chart-data-axis-limits-helpers';
import { ChartSeriesVisibilityManager } from './chart-component-helpers/chart-series-visibility-manager';
import { ChartDataPointsHelpers } from './chart-component-helpers/chart-data-points-helpers';
import { Base64Image } from './image-provider.helpers';
import { ChartAnnotationComponentClass } from './chart-controller-providers/chart-annotations.service';
import { isPointInsideRect, offsetRect, rectWithPadding } from '@dunefront/common/common/math-geometry-helpers';
import { ModalService } from '../modals/modal.service';
import { ChartZoomMode, CrosshairMode, TooltipPosition } from '@dunefront/common/modules/reporting/reporting.settings';
import {
  CreateGradientLinePayload,
  getGradientLinePluginOptions,
  updateGradientLinePluginOptions,
} from './chart-component-helpers/chart-gradient-line-helpers';
import {
  GradientLine,
  GradientLineOperationMode,
  GradientLinePlugin,
  GradientLinePluginOptions,
  ScaleInfo,
} from './gradient-line-plugin/types';
import { IGradientLineStyle } from '@dunefront/common/modules/reporting/dto/chart-gradient-line.dto';
import { getChartDrawingArea, getPointFromEvent } from './plugins-common-helpers';
import { gradientLinePlugin, GradientLinePluginId } from './gradient-line-plugin/gradient-line-plugin';
import { ChartConversionsHelper } from './chart-component-helpers/chart-conversions-helper';
import { VideoRecorderService } from './video-recorder.service';
import { ChartDto } from '@dunefront/common/modules/reporting/dto/chart.dto';
import { IScenarioDict, ScenarioFactory } from '@dunefront/common/modules/scenario/scenario';
import { DrawableRegistryService } from '../../shared/services/drawable-registry.service';

export interface ISortedDataset {
  dataSetIndex: number;
  distancePx: number;
}

const defaultBaseCopyChartSize = 2000;

// order is used for drawing
// crosshair tooltip is part of 'registerables', based on current order it will be drawn on top of annotation,
// however crosshair line will be placed behind annotation (duneFrontAnnotationPlugin)
const defaultPluginsOrder = [
  zoomPlugin,
  whiteBackgroundPlugin,
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  crosshairPlugin,
  gradientLinePlugin, // gradient line by default should be drawn behind annotations
  duneFrontAnnotationPlugin,
  annotationPlugin,
  ...registerables,
];

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartComponent extends DbDependentComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges, ChartContext {
  @ViewChild('chartDiv') public chartDiv!: ElementRef;
  @ViewChild('chartCanvas') public chartCanvas!: ElementRef<HTMLCanvasElement>;
  @ViewChild('chartCanvasPrint') public chartCanvasPrint!: ElementRef<HTMLCanvasElement>;

  @Input() public allowRecording = false;
  @Input() public drawableProviderId = 'Unknown drawableProviderId';
  @Input() public chartName = 'Unknown chartName';
  @Input() public chartData!: IChartDataDto;
  @Input() public chartDto: ChartDto | undefined | null;
  @Input() public chartZoomData: IChartDataDto | null | undefined;
  @Input() public displayLegend = true;
  @Input() public isRotated = false;
  @Input() public reverseArgument = false;
  @Input() public allowCompareScenarios = false;
  @Input() public disableAddingMarkers = false;
  @Input() public disableAddingAnnotations = false;
  @Input() public disableAddingGradientLines = false;
  @Input() public storageFiles: ImportFileWithMinMaxArgumentsDto[] | null = null;
  @Input() public markers: IMarker[] = [];
  @Input() public annotations: IAnnotation[] = [];
  @Input() public gradientLines: IGradientLine[] = [];
  @Input() public axesProperties: IAxisProps[] = [];
  @Input() public allowedXYShiftAxis: AllowedXYShiftAxis = AllowedXYShiftAxis.none;
  @Input() public allowInteractions = true;
  @Input() public reloadWhileVisible = true;
  @Input() public verticalShifts: VerticalShiftDto[] = [];
  @Input() public chartSeriesVisibilityManager = new ChartSeriesVisibilityManager();
  @Input() public tension = 0;

  @Output() public argumentRangeChanged = new EventEmitter<IArgumentRange>();
  @Output() public initialArgumentRange = new EventEmitter<IArgumentRange>();

  @Output() public createMarker = new EventEmitter<CreateMarkerPayload>();
  @Output() public editMarker = new EventEmitter<IMarker>();
  @Output() public markerMoved = new EventEmitter<IMarker>();

  @Output() public configureAxis = new EventEmitter<IEditAxisProps>();
  @Output() public configureLegend = new EventEmitter<ILegendStyle>();

  @Output() public configureSeries = new EventEmitter<ConfigureSeriesPayload>();

  @Output() public createAnnotation = new EventEmitter<CreateAnnotationPayload>();
  @Output() public editAnnotation = new EventEmitter<IAnnotation>();
  @Output() public annotationsUpdated = new EventEmitter<IAnnotation[]>();
  @Output() public keyboardDeleteAnnotation = new EventEmitter<IAnnotation>();

  @Output() public createGradientLine = new EventEmitter<CreateGradientLinePayload>();
  @Output() public editGradientLine = new EventEmitter<IGradientLine>();
  @Output() public gradientLinesUpdated = new EventEmitter<IGradientLine[]>();
  @Output() public keyboardDeleteGradientLine = new EventEmitter<IGradientLine>();

  @Output() public updateXAxisShift = new EventEmitter<IXAxisShiftUpdate>();
  @Output() public autoXAxisShift = new EventEmitter<IAutoXAxisShiftParams>();

  @Output() public updateYAxisShift = new EventEmitter<IYAxisShiftUpdate>();
  @Output() public autoYAxisShift = new EventEmitter<IAutoYAxisShiftParams>();

  @Output() public isZoomed = new EventEmitter<boolean>();

  private chartMode$ = new BehaviorSubject<ChartMode>(ChartMode.default);

  public chartDisplay: Chart | undefined;
  public activeMarker$ = new BehaviorSubject<DeepPartial<LineAnnotationOptions> | undefined>(undefined);
  public mouseHoverMarker$ = new BehaviorSubject<DeepPartial<LineAnnotationOptions> | undefined>(undefined);
  public chartSeriesTemplates: ChartSeriesTemplateDto[] = [];
  public argumentAxisUnit: IAxisUnit = {
    dataType: DataType.Time,
    unitSystem: UnitSystem.Time,
  };
  public axesDefaults?: IAxisData[];
  private isChartFocused = false;

  private activeXYShiftAxis$ = new BehaviorSubject<XYShiftAxis>(XYShiftAxis.none);
  private yAutoShiftActive$ = observableToBehaviorSubject(this.store.select(getYAutoShift), false);
  private xAutoShiftActive$ = observableToBehaviorSubject(this.store.select(getXAutoShift), false);
  public annotationsVisible$ = new BehaviorSubject<boolean>(true);
  public gradientLinesVisible$ = new BehaviorSubject<boolean>(true);
  public markersVisible$ = new BehaviorSubject<boolean>(true);
  public mouseMoveMode$ = new BehaviorSubject<MouseMoveMode>(MouseMoveMode.default);
  public anyGradientLineHighlighted$ = new BehaviorSubject<boolean>(false);

  private readonly zoomPluginHelper = new ZoomPluginHelper(this);

  public crosshairMode$ = observableToBehaviorSubject(
    combineLatest(
      [
        this.store.select(getCrosshairMode),
        this.chartMode$.pipe(distinctUntilChanged()),
        this.activeXYShiftAxis$.pipe(distinctUntilChanged()),
        this.zoomPluginHelper.dragToZoomPending$.pipe(distinctUntilChanged()),
        this.zoomPluginHelper.dragToPanPending$.pipe(distinctUntilChanged()),
        this.anyGradientLineHighlighted$.pipe(distinctUntilChanged()),
      ],
      (crosshairMode, chartMode, xyAxisShift, dragToZoomPending, dragToPanPending, anyGradientLineHighlighted) => {
        const hideInChartMode =
          chartMode === ChartMode.annotate || chartMode === ChartMode.editGradientLine || chartMode === ChartMode.editVerticalMarker;
        const hideInDragPending = dragToZoomPending || dragToPanPending;
        const hideWhenGradientLineHighlighted = anyGradientLineHighlighted;

        // show crosshair when user is performing XYAxisShift
        const isInVisibleMode =
          crosshairMode === CrosshairMode.SINGLE || crosshairMode === CrosshairMode.MULTIPLE || xyAxisShift !== XYShiftAxis.none;

        const isHidden = !isInVisibleMode || hideInChartMode || hideInDragPending || hideWhenGradientLineHighlighted;

        return isHidden ? CrosshairMode.NONE : crosshairMode;
      },
    ),
    CrosshairMode.MULTIPLE,
  );

  public tooltipPosition: TooltipPosition = TooltipPosition.DEFAULT;
  public maxSeriesInTooltip: number = defaultMaxSeriesInTooltip;
  public sortedAndTrimmedDataSets: ISortedDataset[] = [];

  private get activeDisplayMarker(): IMarker | undefined {
    const id = this.activeMarker$.value?.id;
    if (!id) {
      return undefined;
    }

    const markerId = parseInt(id.split('_')[0].substring(6), 10);
    return this.markers.find((marker) => marker.id === markerId);
  }

  public defaultMarkerStyle!: IMarkerStyle;
  public defaultAnnotationStyle!: IAnnotationStyle;
  public defaultGradientLineStyle!: IGradientLineStyle;
  public defaultAxisStyle!: IAxisStyle;
  public defaultAxisMargin!: IAxisMargin;
  public defaultSeriesStyles!: IGlobalSeriesStyles;
  public defaultLegendStyle!: ILegendStyle;
  private defaultCopyChartOptions: ChartCopyOptionsDto | undefined;

  public activeXyShiftIndex?: number;
  public closestDataSetIndex?: number;
  private initialXAxisShift?: number;
  private moveScale?: number;
  private moveInitialMouseX?: number;
  private movePreviousMouseX?: number;
  private moveInitialMouseY?: number;
  private movePreviousMouseY?: number;
  private datasetOffsets: number[] = [];

  public readonly chartZoomedDataService!: ChartZoomedDataService;

  private foregroundPlugin?: typeof duneFrontAnnotationPlugin | typeof gradientLinePlugin;

  private unlistenMouseMove!: () => void;
  private unlistenMouseUp!: () => void;
  private unlistenMouseDown!: () => void;
  private unlistenMouseOut!: () => void;

  private _scenariosToCompare = ScenarioFactory.createEmptyScenarioDict('');
  public get scenariosToCompare(): IScenarioDict {
    return this.chartDto?.IsCompareScenarioActive ? this._scenariosToCompare : ScenarioFactory.createEmptyScenarioDict('');
  }

  public get activeChartData(): IChartDataDto {
    return this.chartZoomData ?? this.chartData;
  }

  public get crosshairMode(): CrosshairMode {
    return this.crosshairMode$.value;
  }

  private chartDataSeriesStyleHelpers = new ChartDataSeriesStyleHelpers();
  private chartDataHelpers = new ChartDataHelpers(this.chartDataSeriesStyleHelpers);
  private chartMarkerHelpers = new ChartMarkerHelpers();

  constructor(
    store: Store,
    cdRef: ChangeDetectorRef,
    private readonly ngZone: NgZone,
    private readonly renderer: Renderer2,
    private readonly screenService: ScreenService,
    private readonly drawableRegistryService: DrawableRegistryService,
    private readonly modalService: ModalService,
    public readonly convertUnitPipe: ConvertUnitPipe,
    private videoRecorder: VideoRecorderService,
  ) {
    super(store, cdRef);

    this.chartZoomedDataService = new ChartZoomedDataService(this.argumentRangeChanged, this.isZoomed, ngZone);
    this.subscription.add(this.screenService.focusedElementChanged$.subscribe(() => this.onChartBlur()));
  }

  public override ngOnInit(): void {
    super.ngOnInit();
    this.subscription.add(this.screenService.screenResized$.subscribe(() => this.redrawForResize()));

    // update plugins rendering order
    this.subscription.add(
      combineLatest([
        this.chartMode$.pipe(distinctUntilChanged()),
        this.anyGradientLineHighlighted$.pipe(distinctUntilChanged()),
      ]).subscribe(([chartMode, isHighlighted]) => this.updatePluginsOrder(chartMode, isHighlighted)),
    );

    if (this.allowCompareScenarios) {
      this.subscription.add(
        this.store.select(getScenariosToCompare).subscribe((scenariosToCompare) => {
          this._scenariosToCompare = scenariosToCompare;
          this.cdRef.markForCheck();
        }),
      );
    }

    if (this.allowInteractions) {
      this.subscription.add(
        this.store.select(getUiChartMode).subscribe((newChartMode) => {
          if (newChartMode !== this.chartMode$.value) {
            this.chartMode$.next(newChartMode);
            this.checkAnnotationsOptions();
            this.updateGradientLinesMode();
          }
        }),
      );

      this.subscription.add(
        notEmpty(this.store.select(selectUserGlobalOptions)).subscribe((globalOptions) => {
          this.defaultMarkerStyle = { ...globalOptions };
          this.defaultAxisStyle = { ...globalOptions };
          this.defaultAxisMargin = { ...globalOptions };
          this.defaultSeriesStyles = { ...globalOptions };
          this.defaultLegendStyle = { ...globalOptions };
          this.defaultAnnotationStyle = { ...globalOptions };
          this.defaultGradientLineStyle = { ...globalOptions };
          this.defaultCopyChartOptions = { ...globalOptions };

          const chart = this.chartDisplay;
          if (chart != null) {
            this.checkMarkers(chart);
            this.checkAnnotations(chart);
            this.checkGradientLines(chart);
          }
        }),
      );

      this.subscription.add(
        this.store
          .select(selectChartSeriesTemplates)
          .pipe(filterNil())
          .subscribe((chartTemplates) => {
            this.chartSeriesTemplates = chartTemplates;
            const chart = this.chartDisplay;
            if (chart == null) {
              return;
            }
            this.setDataSeriesStyle();
            updateChart(chart);
          }),
      );

      this.activeXYShiftAxis$ = observableToBehaviorSubject(
        this.store.select(getXYAxisShift).pipe(
          distinctUntilChanged(),
          map((xyAxisShift) => (isAxisShiftAllowed(xyAxisShift, this.allowedXYShiftAxis) ? xyAxisShift : XYShiftAxis.none)),
        ),
        XYShiftAxis.none,
      );

      this.subscription.add(
        combineLatest([
          this.activeXYShiftAxis$.pipe(distinctUntilChanged()),
          this.yAutoShiftActive$.pipe(distinctUntilChanged()),
          this.xAutoShiftActive$.pipe(distinctUntilChanged()),
        ]).subscribe(() => {
          this.setDataSeriesStyle();
          const chart = this.chartDisplay;
          if (chart != null) {
            updateChart(chart);
          }
        }),
      );
    }

    this.subscription.add(
      this.store.select(getAnnotationsVisible).subscribe((annotationsVisible) => {
        if (annotationsVisible === this.annotationsVisible$.value) {
          return;
        }

        this.annotationsVisible$.next(annotationsVisible);
        const chart = this.chartDisplay;
        if (chart != null) {
          this.checkAnnotations(chart);
        }
      }),
    );

    this.subscription.add(
      this.store.select(getGradientLinesVisible).subscribe((visible) => {
        if (visible === this.gradientLinesVisible$.value) {
          return;
        }

        this.gradientLinesVisible$.next(visible);
        const chart = this.chartDisplay;
        if (chart != null) {
          this.checkGradientLines(chart);
        }
      }),
    );

    this.subscription.add(
      this.store.select(getMarkersVisible).subscribe((markersVisible) => {
        if (markersVisible === this.markersVisible$.value) {
          return;
        }

        this.markersVisible$.next(markersVisible);
        const chart = this.chartDisplay;
        if (chart != null) {
          this.removeChartMarkers();
          this.checkMarkers(chart);
        }
      }),
    );

    this.subscription.add(
      this.store.select(getTooltipPosition).subscribe((position) => {
        this.tooltipPosition = position;
      }),
    );
    this.subscription.add(
      this.store.select(getMaxSeriesInTooltip).subscribe((maxSeries) => {
        this.maxSeriesInTooltip = maxSeries;
      }),
    );
  }

  private redrawForResize(): void {
    this.chartDiv.nativeElement.style = 'height: calc(100% - 1px); width: 10px;';
    this.chartDisplay?.resize();
    this.chartDiv.nativeElement.style = 'height: calc(100% - 1px);';
    this.chartDisplay?.resize();
  }

  private notifyInitialArgumentRange(): void {
    const chart = this.chartDisplay;
    if (chart == null) {
      return;
    }

    const argumentScale = chart.config.options?.scales?.[this.getAxisId(ChartAxis.Argument)];
    if (argumentScale == null || argumentScale.min == null || argumentScale.max == null) {
      return;
    }

    const min = toNumber(argumentScale.min);
    const max = toNumber(argumentScale.max);
    if (min == null || max == null) {
      return;
    }

    const argumentStart = UnitConverterHelper.convertToSi(this.argumentAxisUnit.unitSystem, this.currentUnitSystem, min);
    const argumentEnd = UnitConverterHelper.convertToSi(this.argumentAxisUnit.unitSystem, this.currentUnitSystem, max);

    this.initialArgumentRange.emit({ argumentStart, argumentEnd });
  }

  public initialiseChart(): Chart {
    if (this.chartDisplay != null) {
      this.chartDisplay.destroy();
    }

    const chartData: ChartConfiguration = this.getChartConfiguration();

    const chart = new Chart(this.chartCanvas.nativeElement, chartData);
    this.chartDisplay = chart;
    patchChart(chart);

    this.initializeChartDatasetsScales(this.chartData, chart, 1, true);
    this.setDataSeries(this.activeChartData, chart);
    this.checkMarkers(chart);
    this.checkAnnotations(chart, true);
    this.checkGradientLines(chart);

    this.setDataSeriesStyle();
    this.notifyInitialArgumentRange();
    return chart;
  }

  public ngAfterViewInit(): void {
    this.registerPlugins();
    this.setupInterpolate();

    const chart = this.initialiseChart();

    // register provider
    this.drawableRegistryService.registerProvider({
      id: this.drawableProviderId,
      getDisplayName: () => this.chartName,
      getChartId: () => this.chartData.ChartId,
      getBase64Image: () => this.getBase64Image(),
      getDataContext: () => this,
      getCanvasForRecording: this.allowRecording ? (): HTMLCanvasElement => this.chartCanvas.nativeElement : undefined,
    });
    // there is no change detection triggered after ngAfterViewInit
    // other components listening for changes to registered charts (via store selectors) won't get properly updated
    // (unless they explicitly call cdr.detectChanges)
    // code below aims to mitigate that issue
    this.triggerGlobalChangeDetection().then();

    if (this.allowInteractions) {
      this.ngZone.runOutsideAngular(() => {
        this.unlistenMouseMove = this.renderer.listen(this.chartDiv.nativeElement, 'mousemove', ($eventMove) =>
          this.onMouseMovePassive($eventMove),
        );

        this.unlistenMouseDown = this.renderer.listen(this.chartDiv.nativeElement, 'mousedown', ($event) => this.onMouseDown($event));
        this.unlistenMouseUp = this.renderer.listen(document, 'mouseup', ($event) => this.onMouseUp($event));
        this.unlistenMouseOut = this.renderer.listen(document, 'mouseout', ($event) => this.onMouseOut($event));
        this.renderer.listen(document, 'keydown', ($event) => this.onKeyDown($event));
        this.renderer.listen(document, 'keyup', ($event) => this.onKeyUp($event));
        this.renderer.listen(this.chartDiv.nativeElement, 'dblclick', ($event) => this.onDblClick($event));
        this.renderer.listen(this.chartDiv.nativeElement, 'click', () => (this.isChartFocused = true));
      });
    }

    if (this.allowInteractions) {
      this.subscription.add(
        this.store.select(getUiChartZoomMode).subscribe((uiChartZoomMode) => {
          if (uiChartZoomMode) {
            this.onZoomMode(uiChartZoomMode);
          }
        }),
      );
    }

    // reset UI state (e.g. highlighted series or markers) when starting Zoom/Pan
    this.subscription.add(
      combineLatest([
        this.zoomPluginHelper.dragToZoomPending$.pipe(distinctUntilChanged()),
        this.zoomPluginHelper.dragToPanPending$.pipe(distinctUntilChanged()),
      ]).subscribe(([dragToZoomPending, dragToPanPending]) => {
        if (dragToZoomPending || dragToPanPending) {
          this.stopMovingEverything();
        }
      }),
    );

    // when tooltip should be visible
    this.subscription.add(
      combineLatest([this.crosshairMode$, this.mouseMoveMode$.pipe(distinctUntilChanged())]).subscribe(([crosshairMode, mouseMoveMode]) => {
        const crosshairVisible = crosshairMode === CrosshairMode.MULTIPLE || crosshairMode === CrosshairMode.SINGLE;
        const hideInMouseMode = mouseMoveMode === MouseMoveMode.dragDataSet;

        const notEnabled = !crosshairVisible || hideInMouseMode;

        setTooltipEnabled(chart, !notEnabled);
      }),
    );

    // modify crosshair options
    // when either activeMarker or mouseHoverMarker is set crosshair should snap to marker position and turn line to transparent
    this.subscription.add(
      combineLatest([this.activeMarker$.pipe(distinctUntilChanged()), this.mouseHoverMarker$.pipe(distinctUntilChanged())]).subscribe(
        ([activeMarker, mouseHoverMarker]) => {
          const chart = this.chartDisplay;

          if (chart == null) {
            return;
          }

          const markerToSnapTo = activeMarker ?? mouseHoverMarker;

          let snapPx: number | undefined;
          const markerValue = markerToSnapTo?.value as number | undefined;

          if (markerValue != null) {
            const argumentScaleId = this.getAxisId(ChartAxis.Argument);
            const scale = chart.scales[argumentScaleId];

            if (scale != null) {
              snapPx = scale.getPixelForValue(markerValue);
            }
          }

          const state = getCrosshairState(chart);
          const options = getCrosshairPluginOptions(chart);

          const wasSnapSet = state.snapArgumentPx != null;
          const isSnapSet = snapPx != null;

          // set snap position
          if (state.snapArgumentPx !== snapPx) {
            state.snapArgumentPx = snapPx;
          }

          // change line color (only if snap is changing)
          if (wasSnapSet !== isSnapSet || (!wasSnapSet && !isSnapSet)) {
            options.lineStyle = {
              ...options.lineStyle,
              color: isSnapSet ? transparentCrosshairLineColor : defaultCrosshairLineColor,
            };

            updateChart(chart);
          }
        },
      ),
    );

    updateChart(chart);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.isRotated != null && this.chartCanvas != null) {
      const chart = this.initialiseChart();
      updateChart(chart);
    }

    const chart = this.chartDisplay;
    if (!chart) {
      return;
    }

    if (changes.chartData != null) {
      this.axesDefaults = undefined;
      this.chartZoomedDataService.reset();
      this.initializeChartDatasetsScales(this.chartData, chart, 1, true);
      this.setDataSeries(this.activeChartData, chart);
      this.checkMarkers(chart);
      this.checkAnnotations(chart, true);
      this.checkGradientLines(chart);
      this.notifyInitialArgumentRange();
    }

    if (changes.chartZoomData != null && this.chartZoomData) {
      this.chartZoomedDataService.reset();
      if (
        this.activeXYShiftAxis$.value === XYShiftAxis.x &&
        this.initialXAxisShift !== undefined &&
        this.activeXyShiftIndex !== undefined &&
        this.moveScale !== undefined &&
        this.movePreviousMouseX !== undefined &&
        this.moveInitialMouseX !== undefined
      ) {
        const argumentAxisUnit = getArgumentAxisUnit(this.activeChartData);
        const moveDeltaX = UnitConverterHelper.convertToSi(
          argumentAxisUnit.unitSystem,
          this.currentUnitSystem,
          (this.movePreviousMouseX - this.moveInitialMouseX) * this.moveScale,
        );
        this.moveInitialMouseX = this.movePreviousMouseX;
        const XAxisShift = this.initialXAxisShift + moveDeltaX;
        this.chartZoomData = this.chartDataHelpers.chartDataWithUpdatedXAxisShift(this.chartZoomData, this.activeXyShiftIndex, XAxisShift);
        this.initialXAxisShift = XAxisShift;
      }

      this.writeChartValues(this.activeChartData, false, chart);
      updateChartWithHitRadiusFix(chart);

      //adjust X axis shift if in progress
      if (this.moveScale !== undefined) {
        this.setMoveScaleForXYShift(this.activeXYShiftAxis$.value);
      }
    }

    if (changes.markers != null) {
      this.removeChartMarkers();
      this.checkMarkers(chart);
    }

    if (changes.annotations != null) {
      this.checkAnnotations(chart, true);
    }

    if (changes.gradientLines != null) {
      this.checkGradientLines(chart);
    }

    if (changes.axesProperties != null) {
      this.onAxesPropertiesChanged(changes.axesProperties.previousValue, chart);
    }

    if (changes.verticalShifts != null) {
      this.writeChartValues(this.activeChartData, false, chart);
      updateChartWithHitRadiusFix(chart);

      // after ResetYShift
      if (this.verticalShifts.length === 0) {
        this.resetVisualRange();
      }
    }

    if (changes.chartDto != null) {
      this.updateChartLegend(chart);
      updateChart(chart);
    }

    if (changes.isRotated != null && changes.isRotated.currentValue !== changes.isRotated.previousValue) {
      updateCrosshairOptions(chart, this.isRotated);
    }

    this.videoRecorder.handleChartUpdated(this.drawableProviderId).then();
  }

  private onAxesPropertiesChanged(prevAxesProperties: IAxisProps[] | undefined, chart: Chart): void {
    const prevArgumentAxisProps = prevAxesProperties?.find((props) => props.axis === ChartAxis.Argument);
    const newArgumentAxisProps = this.axesProperties.find((props) => props.axis === ChartAxis.Argument);

    const hasArgLimitsChanged = hasManualAxisLimitChanged(prevArgumentAxisProps, newArgumentAxisProps);
    if (!hasArgLimitsChanged) {
      // if limits not changed - apply changes to existing scales

      const currentUnitSystem = this.currentUnitSystem;
      const chartData = this.chartData;
      const argumentUnitDetails = UnitConverterHelper.getUnitTypeAndName(
        this.argumentAxisUnit.dataType,
        this.argumentAxisUnit.unitSystem,
        this.currentUnitSystem,
      );

      const isPrimaryArg = this.chartData.IsPrimaryArgument;

      // axis props can be missing only if undo operation is performed and is reverting newly created props,
      // lines below finds prev axis props that not present in current set and create default props for each of them
      const missingAxisProps = (prevAxesProperties ?? [])
        .filter((prev) => !this.axesProperties.some((newProp) => newProp.axis === prev.axis))
        .map((missing) => this.getDefaultAxisProps(missing.axis));

      for (const axisProps of [...this.axesProperties, ...missingAxisProps]) {
        const scaleId = this.getAxisId(axisProps.axis);
        const isArgAxis = axisProps.axis === ChartAxis.Argument;
        const axisStyle = axisProps?.style ?? this.defaultAxisStyle;

        const axisData = this.axesDefaults?.find((data) => data.axis === axisProps.axis);
        const axisUnitsSummary = axisData ? getAxisUnitsSummary(axisData, this.currentUnitSystem) : undefined;
        const type = axisProps?.isLogarithmic ?? axisUnitsSummary?.hasAnyLogarithmicUnit === true ? 'logarithmic' : 'linear';

        const scaleConfig = chart?.options?.scales?.[scaleId] as DeepPartial<ScaleOptionsByType<'linear' | 'logarithmic'>> | undefined;
        if (scaleConfig != null) {
          scaleConfig.type = type;

          scaleConfig.title = {
            ...scaleConfig.title,
            text: isArgAxis
              ? this.chartDataHelpers.getArgumentAxisText(axisProps, argumentUnitDetails, this.argumentAxisUnit)
              : this.chartDataHelpers.getValueAxisText(
                  axisProps,
                  axisUnitsSummary?.unitTypeNames ?? [],
                  axisUnitsSummary?.unitTypeUnits ?? [],
                ),
            font: getAxisTitleFont(axisStyle),
            color: axisStyle.AxisTitleFontColor,
          };

          scaleConfig.ticks = {
            ...scaleConfig.ticks,
            font: getAxisTicksFont(axisStyle),
            color: axisStyle.AxisLabelFontColor,
            callback: (value: string | number): string | number =>
              this.chartDataHelpers.formatScaleTick(
                value,
                type === 'logarithmic',
                (isArgAxis ? argumentUnitDetails.DecimalPlaces : axisUnitsSummary?.decimalPlaces) ?? 0,
                COMMON_DATE_TIME_SECOND_STRING_FORMAT,
                this.activeChartData != null &&
                  isArgAxis &&
                  isPrimaryArg &&
                  getIsTimeAxis(this.argumentAxisUnit) &&
                  axisProps?.xAxisFormat === XAxisFormat.timestamp
                  ? this.activeChartData.StartDate
                  : null,
                isArgAxis && isPrimaryArg ? currentUnitSystem.Time : undefined,
              ),
          };

          // apply manual axis limits
          const prevAxisProps = prevAxesProperties?.find((props) => props.axis === axisProps.axis);
          if (hasManualAxisLimitChanged(prevAxisProps, axisProps)) {
            const calcSmoothedPoints = (column: IChartDataDtoColumn): [number, number][] =>
              ChartDataPointsHelpers.calcSmoothedPoints(column, this, chartData);

            const axisId = getAxisId(axisProps.axis, this.isRotated);
            const axisLimits = ChartDataAxisLimitsHelpers.getValueAxisLimits(
              axisProps.axis,
              this,
              this.chartData,
              calcSmoothedPoints,
              this.defaultAxisMargin,
            );

            ChartDataAxisLimitsHelpers.applyScaleLimits(axisId, axisLimits, chart.config as ChartConfiguration);
          }
        }

        chart.update();
      }
    } else {
      // argument axis limits has changed - reinitialize chart
      this.initializeChartDatasetsScales(this.chartData, chart, 1, false);
      this.setDataSeries(this.chartData, chart);
      updateChartWithHitRadiusFix(chart);
    }
  }

  public override ngOnDestroy(): void {
    this.drawableRegistryService.unregisterProvider(this.drawableProviderId);
    this.chartZoomedDataService.dispose();

    this.unlistenMouseDown?.();
    this.unlistenMouseUp?.();
    this.unlistenMouseOut?.();
    this.unlistenMouseMove?.();

    // release objects held by observableToBehaviorSubject
    // replace with non-null BehaviorSubjects as some of rx streams, the below are used in, might emit after ngOnDestroy and lead to crash
    this.activeXYShiftAxis$ = new BehaviorSubject<XYShiftAxis>(this.activeXYShiftAxis$.value);
    this.yAutoShiftActive$ = new BehaviorSubject<boolean>(this.yAutoShiftActive$.value);
    this.xAutoShiftActive$ = new BehaviorSubject<boolean>(this.xAutoShiftActive$.value);
    this.crosshairMode$ = new BehaviorSubject<CrosshairMode>(CrosshairMode.MULTIPLE);

    super.ngOnDestroy();
  }

  private async getBase64Image(): Promise<Base64Image | null> {
    const chart = this.chartDisplay;

    if (chart == null) {
      return null;
    }

    const imageDataBase64 = chart.toBase64Image();
    const { width, height } = this.chartCanvas.nativeElement;

    return {
      imageDataBase64,
      size: { width, height },
    };

    // To be restored

    // return this.chartCopyImageHelpers.getChartBase64Image(
    //   this.chartCanvasPrint,
    //   this.getChartConfiguration(true),
    //   this.activeChartData,
    //   chart,
    //   this,
    //   this.defaultCopyChartOptions?.CopyChartScaling
    // );
  }

  public registerPlugins(foregroundPlugin?: typeof duneFrontAnnotationPlugin | typeof gradientLinePlugin): void {
    for (const plugin of defaultPluginsOrder) {
      Chart.unregister(plugin);
    }

    const newOrder = foregroundPlugin
      ? [...defaultPluginsOrder.filter((p) => p != foregroundPlugin), foregroundPlugin]
      : defaultPluginsOrder;
    for (const plugin of newOrder) {
      Chart.register(plugin);
    }
  }

  private updatePluginsOrder(newChartMode: ChartMode, isGradientLineHighlighted = false): void {
    let newForegroundPlugin = undefined;
    if (newChartMode === ChartMode.editGradientLine || isGradientLineHighlighted) {
      newForegroundPlugin = gradientLinePlugin;
    } else if (newChartMode === ChartMode.annotate) {
      newForegroundPlugin = duneFrontAnnotationPlugin;
    }

    if (newForegroundPlugin !== this.foregroundPlugin) {
      this.foregroundPlugin = newForegroundPlugin;
      this.registerPlugins(newForegroundPlugin);
      this.chartDisplay?.update();
    }
  }

  public resetVisualRange(): void {
    const chart = this.chartDisplay;

    if (chart == null) {
      return;
    }

    this.onDragDefault(chart);
    this.chartZoomedDataService.reset();

    // make chartData an activeChartData
    this.chartZoomData = null;
    this.initializeChartDatasetsScales(this.chartData, chart, 1, false);
    this.setDataSeries(this.chartData, chart);
  }

  public override onUnitSystemChanged(currentUnitSystem: IUnitSystemDto): void {
    const chart = this.chartDisplay;

    if (chart == null) {
      return;
    }

    this.removeChartMarkers();
    this.axesDefaults = undefined;

    this.initializeChartDatasetsScales(this.chartData, chart, 1, false);
    this.setDataSeries(this.activeChartData, chart);
    this.checkMarkers(chart);
    this.checkAnnotations(chart);
    this.checkGradientLines(chart);
  }

  public removeChartMarkers(): void {
    const chart = this.chartDisplay;

    if (chart == null) {
      return;
    }

    const annotationPlugin = chart?.options?.plugins?.annotation;
    if (annotationPlugin == null) {
      return;
    }

    annotationPlugin.annotations = [];
    updateChart(chart);
  }

  public onZoomMode(mode: ChartZoomMode): void {
    const chart = this.chartDisplay;
    if (chart == null) {
      return;
    }

    const zoomPlugin = chart?.options?.plugins?.zoom;
    if (zoomPlugin == null) {
      return;
    }

    zoomPlugin.zoom = {
      ...zoomPlugin.zoom,
      mode,
    };

    updateChart(chart);
  }

  public onMouseDown(event: MouseEvent): void {
    // not a main (left) button
    if (event.button != 0) {
      return;
    }

    const chart = this.chartDisplay;
    if (chart == null) {
      return;
    }

    if (this.yAutoShiftActive$.value) {
      this.onYAutoShift(event, chart);
    } else if (this.xAutoShiftActive$.value) {
      this.onXAutoShift(chart);
    } else {
      switch (this.chartMode$.value) {
        case ChartMode.default:
          this.onMouseDownModeDefault(event);
          break;
        case ChartMode.editVerticalMarker:
        case ChartMode.editHorizontalMarker:
          this.onMouseDownModeMark(event);
          break;
      }
    }

    this.chartZoomedDataService.stopTimer();
  }

  public onMouseDownModeMark(event: MouseEvent): void {
    if (this.zoomPluginHelper.isDragPending) {
      return;
    }

    const chart = this.chartDisplay;

    if (chart == null) {
      return;
    }

    const annotationPlugin = chart?.options?.plugins?.annotation;
    const annotations = chart?.options?.plugins?.annotation?.annotations as DeepPartial<LineAnnotationOptions>[];
    const activeMarker = this.activeMarker$.value;

    if (annotationPlugin == null || annotations == null || activeMarker == null) {
      return;
    }

    this.setMoveScaleForMarkers();

    const otherAnnotations = annotations.filter((annotation) => annotation.id !== activeMarker.id);

    const lineThickness =
      this.activeDisplayMarker?.isOverrideStyle && this.activeDisplayMarker?.style?.ChartMarkerLineThickness
        ? this.activeDisplayMarker.style.ChartMarkerLineThickness
        : this.defaultMarkerStyle.ChartMarkerLineThickness;

    const updatedActiveMark = {
      ...activeMarker,
      borderDash: borderDashForLineStyle(LineStyle.dashes, lineThickness),
    };

    setActiveMarker(updatedActiveMark, this.activeMarker$);

    annotationPlugin.annotations = [...otherAnnotations, updatedActiveMark];
    updateChart(chart);

    const isHorizontal = this.chartMode$.value === ChartMode.editHorizontalMarker;

    this.moveInitialMouseX = isHorizontal ? event.y : event.x;
    this.movePreviousMouseX = isHorizontal ? event.y : event.x;

    // switch to watching mousemove on document
    this.unlistenMouseMove();
    this.unlistenMouseMove = this.renderer.listen(document, 'mousemove', ($eventMove) => this.onMouseMoveActiveDragMark($eventMove));
    setMouseMoveMode(MouseMoveMode.dragMarker, this.mouseMoveMode$);
  }

  public onMouseDownModeDefault(event: MouseEvent): void {
    if (this.zoomPluginHelper.isDragPending) {
      return;
    }

    if (this.activeXyShiftIndex === undefined) {
      return;
    }

    const chart = this.chartDisplay;
    if (!chart?.options?.scales) {
      return;
    }

    this.setMoveScaleForXYShift(this.activeXYShiftAxis$.value);

    this.moveInitialMouseX = this.isRotated ? event.y : event.x;
    this.movePreviousMouseX = this.isRotated ? event.y : event.x;
    this.moveInitialMouseY = this.isRotated ? event.x : event.y;
    this.movePreviousMouseY = this.isRotated ? event.x : event.y;

    //switch to watching mousemove on document
    this.unlistenMouseMove();
    this.unlistenMouseMove = this.renderer.listen(document, 'mousemove', ($event) => this.onMouseMoveActiveDragDataset($event));

    setMouseMoveMode(MouseMoveMode.dragDataSet, this.mouseMoveMode$);
  }

  public onMouseUp(event: MouseEvent): void {
    // not a main (left) button
    if (event.button != 0) {
      return;
    }

    switch (this.mouseMoveMode$.value) {
      case MouseMoveMode.dragDataSet:
        this.onMouseUpDragDataSet(event);
        break;
    }

    this.stopMovingEverything();
    this.chartZoomedDataService.startTimer();
  }

  public setMoveScaleForXYShift(axis: XYShiftAxis): void {
    const chart = this.chartDisplay;

    if (chart == null) {
      return;
    }

    if (axis === XYShiftAxis.x) {
      const argumentAxis = chart.scales[this.getAxisId(ChartAxis.Argument)];
      this.moveScale = (argumentAxis.max - argumentAxis.min) / (this.isRotated ? argumentAxis.height : argumentAxis.width);
      return;
    }
    if (axis === XYShiftAxis.y && this.activeXyShiftIndex !== undefined) {
      const axisType = axisTypeFromColumn(this.activeChartData.ChartDataColumns[this.activeXyShiftIndex]);
      const axisId = this.getAxisId(axisType);
      const valueAxis = chart.scales[axisId];
      this.moveScale = (valueAxis.max - valueAxis.min) / (this.isRotated ? valueAxis.width : valueAxis.height);
      return;
    }
    this.moveScale = undefined;
  }

  public setMoveScaleForMarkers(): void {
    const chart = this.chartDisplay;

    if (chart == null) {
      return;
    }

    const scale = this.isValueAxisMarkerMode()
      ? getFirstAvailableValueScale(chart, this.isRotated)
      : chart.scales[this.getAxisId(ChartAxis.Argument)];
    if (scale == null) {
      return;
    }

    const isHorizontal = this.chartMode$.value === ChartMode.editHorizontalMarker;

    this.moveScale = (scale.max - scale.min) / (isHorizontal ? scale.height : scale.width);
  }

  // when nothing is being dragged and only watching chart area
  public onMouseMovePassive(event: MouseEvent): void {
    const chart = this.chartDisplay;

    if (chart == null) {
      return;
    }

    // sort datasets by the distance from cursor, and trim them, to show only amount set in settings
    this.sortedAndTrimmedDataSets = this.getSortedDataSetsByDistance(event, chart).slice(0, this.maxSeriesInTooltip);
    const newClosestDataSetIndex = this.sortedAndTrimmedDataSets[0]?.dataSetIndex;

    if (newClosestDataSetIndex !== this.closestDataSetIndex) {
      this.closestDataSetIndex = newClosestDataSetIndex;
      chart.update();
    }

    switch (this.chartMode$.value) {
      case ChartMode.default:
        if (this.activeXYShiftAxis$.value !== XYShiftAxis.none || this.yAutoShiftActive$.value || this.xAutoShiftActive$.value) {
          this.onMouseMovePassiveXYShift(event);
        } else {
          this.onMouseMovePassiveDefault(event);
        }
        break;
      case ChartMode.editVerticalMarker:
      case ChartMode.editHorizontalMarker:
        this.onMouseMovePassiveMark(event);
        break;
    }
  }

  public onMouseMovePassiveXYShift(event: MouseEvent): void {
    if (this.zoomPluginHelper.isDragPending || this.activeChartData == null) {
      return;
    }

    const chart = this.chartDisplay;

    if (chart?.data?.datasets == null) {
      return;
    }

    const chartSeriesDatasetIndex = this.getNearbyDataSetIndex(event, chart, 30); // this is IChartDataDtoColumn index

    if (chartSeriesDatasetIndex == null) {
      this.setActiveXyShiftIndex(chart, undefined);
      return;
    }

    const requiredXyShiftIndex =
      this.activeXYShiftAxis$.value === XYShiftAxis.x
        ? this.activeChartData.ChartDataColumns[chartSeriesDatasetIndex].DataSetIndex
        : chartSeriesDatasetIndex;

    // XY axis shifts not available on EquationResults
    const fileId = this.activeChartData.ChartDataColumns[chartSeriesDatasetIndex].FileId;
    const file = this.storageFiles?.find((f) => f.Id === fileId);
    if (file == null || file.FileType === DataFileType.EquationResult) {
      this.setActiveXyShiftIndex(chart, undefined);
      return;
    }

    this.setActiveXyShiftIndex(chart, requiredXyShiftIndex);
  }

  public getNearbyDataSetIndex(event: MouseEvent, chart: Chart, threshold: number): number | undefined {
    const clickedDataset = chart.getElementsAtEventForMode(event, 'nearest', { intersect: true }, false);
    if (clickedDataset[0]?.datasetIndex) {
      return clickedDataset[0].datasetIndex;
    }

    const closestDataset = this.getSortedDataSetsByDistance(event, chart)[0];

    return closestDataset != null && closestDataset.distancePx <= threshold ? closestDataset.dataSetIndex : undefined;
  }

  public getSortedDataSetsByDistance(event: MouseEvent, chart: Chart): ISortedDataset[] {
    // sort datasets by distance from cursor
    const argPx = this.isRotated ? event.offsetY : event.offsetX;
    const valuePx = this.isRotated ? event.offsetX : event.offsetY;

    return chart.data.datasets
      .map((dataset, dataSetIndex): ISortedDataset | null => {
        const interpolatedValuePx = getInterpolatedValue(argPx, dataset as LineChartDataSet, chart, this.isRotated);

        if (interpolatedValuePx == null) {
          return null;
        }

        const distancePx = Math.abs(interpolatedValuePx - valuePx);

        return { dataSetIndex, distancePx };
      })
      .filter((dataset, idx): dataset is ISortedDataset => dataset != null && chart.isDatasetVisible(idx))
      .sort((a, b) => a.distancePx - b.distancePx);
  }

  public onMouseMovePassiveDefault(event: MouseEvent): void {
    const chart = this.chartDisplay;

    if (chart == null) {
      return;
    }

    const marker = getMarkerUnderMouseEvent(event, chart, this.isRotated, false);
    setActiveMarker(marker, this.mouseHoverMarker$);
  }

  public onMouseMovePassiveMark(event: MouseEvent): void {
    const chart = this.chartDisplay;

    if (chart == null) {
      return;
    }

    if (this.zoomPluginHelper.isDragPending) {
      this.chartCanvas.nativeElement.style.cursor = 'default';
      setActiveMarker(undefined, this.activeMarker$);
      return;
    }

    const marker = getMarkerUnderMouseEvent(event, chart, this.isRotated, this.isValueAxisMarkerMode());

    const isHorizontal = this.chartMode$.value === ChartMode.editHorizontalMarker;

    this.chartCanvas.nativeElement.style.cursor = marker != null ? (isHorizontal ? 'ns-resize' : 'ew-resize') : 'default';
    setActiveMarker(marker, this.activeMarker$);
  }

  // when something is being dragged and only watching full window
  public onMouseMoveActiveDragDataset(event: MouseEvent): void {
    const chart = this.chartDisplay;
    if (chart == null || chart.data?.datasets == null) {
      return;
    }

    if (this.chartMode$.value !== ChartMode.default) {
      return;
    }

    if (this.zoomPluginHelper.isDragPending) {
      return;
    }

    if (
      this.activeXYShiftAxis$.value === XYShiftAxis.x &&
      this.activeXyShiftIndex != null &&
      this.moveScale != null &&
      this.movePreviousMouseX != null
    ) {
      const newMouseX = event.x;
      const deltaMouseX = newMouseX - this.movePreviousMouseX;
      const deltaAxisX = deltaMouseX * this.moveScale;

      this.activeChartData.ChartDataColumns.forEach((column, index) => {
        if (column.DataSetIndex === this.activeXyShiftIndex) {
          if (chart.data == null || chart.data.datasets == null) {
            return;
          }
          const dataset = chart.data.datasets[index];

          if (dataset.data != null) {
            const datasetData: ScatterDataPoint[] = dataset.data as ScatterDataPoint[];
            datasetData.forEach((point) => {
              if (point.x !== undefined) {
                const oldX = Number(point.x);
                point.x = oldX + deltaAxisX;
              }
            });
          }
        }
      });

      updateChart(chart);
      this.datasetOffsets[this.activeXyShiftIndex] = (this.datasetOffsets[this.activeXyShiftIndex] ?? 0) + deltaAxisX;

      this.movePreviousMouseX = newMouseX;
    }

    if (
      this.activeXYShiftAxis$.value === XYShiftAxis.y &&
      this.activeXyShiftIndex != null &&
      this.moveScale != null &&
      this.movePreviousMouseY != null
    ) {
      const newMouseY = event.y;
      const deltaMouseY = newMouseY - this.movePreviousMouseY;
      const deltaAxisY = deltaMouseY * this.moveScale;

      if (chart.data == null) {
        return;
      }
      const dataset = chart.data.datasets[this.activeXyShiftIndex];

      if (dataset.data != null) {
        const datasetData: ScatterDataPoint[] = dataset.data as ScatterDataPoint[];
        datasetData.forEach((point) => {
          if (point.y !== undefined) {
            const oldY = Number(point.y);
            point.y = oldY - deltaAxisY;
          }
        });
      }

      updateChart(chart);
      this.datasetOffsets[this.activeXyShiftIndex] = (this.datasetOffsets[this.activeXyShiftIndex] ?? 0) + deltaAxisY;

      this.movePreviousMouseY = newMouseY;
    }
  }

  // when something is being dragged and only watching full window
  public onMouseMoveActiveDragMark(event: MouseEvent): void {
    if (this.zoomPluginHelper.isDragPending) {
      return;
    }

    const activeMarker = this.activeMarker$.value;
    if (activeMarker == null || this.movePreviousMouseX == null || this.moveScale == null) {
      return;
    }

    const chart = this.chartDisplay;
    const annotationPlugin = chart?.options?.plugins?.annotation;
    const annotations = annotationPlugin?.annotations as DeepPartial<LineAnnotationOptions>[];
    if (chart == null || annotationPlugin == null || annotations == null) {
      return;
    }

    const isValueAxisMarker = this.isValueAxisMarkerMode();
    const isHorizontal = this.chartMode$.value === ChartMode.editHorizontalMarker;

    const boundingRect = this.chartCanvas.nativeElement.getBoundingClientRect();
    const drawingAreaRectRelativeToChart = getChartDrawingArea(chart);
    const drawingAreaRectAbsolute = offsetRect(drawingAreaRectRelativeToChart, boundingRect);
    const workingRect = rectWithPadding(drawingAreaRectAbsolute, 4);

    const min = isHorizontal ? workingRect.y : workingRect.x;
    const max = isHorizontal ? workingRect.y + workingRect.height : workingRect.x + workingRect.width;

    // raw position from event
    const newPositionUnconstrained = isHorizontal ? event.y : event.x;
    // position with applied min and max
    const newPositionConstrained = Math.max(min, Math.min(max, newPositionUnconstrained));
    if (newPositionConstrained === this.movePreviousMouseX) {
      return;
    }

    const deltaMouse = newPositionConstrained - this.movePreviousMouseX;
    this.movePreviousMouseX = newPositionConstrained;

    let deltaAxis = deltaMouse * this.moveScale;

    // is on Y axis (pixes and axis values are in opposite directions)
    if (isHorizontal) {
      deltaAxis *= -1;
    }
    // is on argument axis and it's reversed
    if (!isValueAxisMarker && this.reverseArgument) {
      deltaAxis *= -1;
    }

    const markerValue = activeMarker.value as number;
    const otherAnnotations = annotations.filter((annotation) => annotation.id !== activeMarker.id);
    const updatedActiveMark = {
      ...activeMarker,
      value: markerValue + deltaAxis,
      endValue: markerValue + deltaAxis,
    };
    setActiveMarker(updatedActiveMark, this.activeMarker$);

    annotationPlugin.annotations = [...otherAnnotations, updatedActiveMark];
    updateChart(chart);
  }

  private onMouseUpDragDataSet(event: MouseEvent): void {
    if (this.activeXYShiftAxis$.value === XYShiftAxis.x) {
      this.onXAxisShiftMouseUp(event);
    } else if (this.activeXYShiftAxis$.value === XYShiftAxis.y) {
      this.onYAxisShiftMouseUp(event);
    }
  }

  private onXAxisShiftMouseUp(event: MouseEvent): void {
    if (
      this.activeXYShiftAxis$.value !== XYShiftAxis.x ||
      this.activeXyShiftIndex == null ||
      this.moveInitialMouseX == null ||
      this.moveScale == null ||
      this.chartData == null ||
      this.currentUnitSystem == null ||
      this.activeChartData == null
    ) {
      return;
    }
    const moveDeltaX = UnitConverterHelper.convertDeltaToSi(
      this.argumentAxisUnit.unitSystem,
      this.currentUnitSystem,
      (event.x - this.moveInitialMouseX) * this.moveScale,
    );

    const XAxisShift = this.activeChartData.ChartDataSets[this.activeXyShiftIndex].XAxisShift + moveDeltaX;

    // update chartData in case original size button is used later
    this.chartData = this.chartDataHelpers.chartDataWithUpdatedXAxisShift(this.chartData, this.activeXyShiftIndex, XAxisShift);

    if (this.chartZoomData) {
      // update x-axis shift in chartZoomData so correct offset can be applied if data is reloaded
      this.chartZoomData = this.chartDataHelpers.chartDataWithUpdatedXAxisShift(this.chartZoomData, this.activeXyShiftIndex, XAxisShift);
    }

    const fileId = this.activeChartData.ChartDataSets[this.activeXyShiftIndex].FileId;
    if (fileId != null) {
      const xAxisShiftDto: IXAxisShiftUpdate = {
        FileId: fileId,
        XAxisShift,
      };
      this.ngZone.run(() => this.updateXAxisShift.emit(xAxisShiftDto));
    }
  }

  private onYAxisShiftMouseUp(event: MouseEvent): void {
    if (
      this.activeXYShiftAxis$.value !== XYShiftAxis.y ||
      this.activeXyShiftIndex == null ||
      this.moveInitialMouseY == null ||
      this.moveScale == null
    ) {
      return;
    }

    const column = this.activeChartData.ChartDataColumns[this.activeXyShiftIndex];

    if (column.ColumnId == null || column.FileId == null) {
      return;
    }

    const columnAxisUnit = getAxisUnitForColumn(column);

    const moveDeltaY = UnitConverterHelper.convertDeltaToSi(
      columnAxisUnit.unitSystem,
      this.currentUnitSystem,
      (event.y - this.moveInitialMouseY) * this.moveScale,
    );

    const yAxisShiftDto: IYAxisShiftUpdate = {
      ColumnId: column.ColumnId,
      FileId: column.FileId,
      VerticalShift: -moveDeltaY,
    };

    this.ngZone.run(() => this.updateYAxisShift.emit(yAxisShiftDto));
  }

  public onMouseOut(event: MouseEvent): void {
    if (this.chartMode$.value !== ChartMode.default) {
      return;
    }

    if (event?.target === this.chartCanvas.nativeElement) {
      // if mouse out from canvas and series is highlighted but not actively dragging, remove highlight and prevent series from being moved
      if (this.activeXyShiftIndex !== undefined && this.moveScale === undefined) {
        this.stopMovingEverything();
      }
    }
    this.chartZoomedDataService.startTimer();
  }

  public onKeyDown(event: KeyboardEvent): void {
    switch (event.key) {
      case 'Shift':
        this.zoomPluginHelper.onShiftKeyDown();
        break;

      case 'Control':
        this.zoomPluginHelper.onControlKeyDown();
        break;

      case 'Delete':
        this.ngZone.run(() => this.onDeleteKeyDown());
        break;
    }
    this.chartZoomedDataService.stopTimer();
  }

  public onKeyUp(event: KeyboardEvent): void {
    switch (event.key) {
      case 'Shift':
        this.zoomPluginHelper.onShiftKeyUp();
        break;

      case 'Control':
        this.zoomPluginHelper.onControlKeyUp();
        break;
    }
    this.chartZoomedDataService.startTimer();
  }

  public stopMovingEverything(): void {
    const chart = this.chartDisplay;

    if (chart == null) {
      return;
    }

    // stop dragging datasets
    if (this.activeXyShiftIndex != null) {
      this.setActiveXyShiftIndex(chart, undefined);
    }

    // stop moving marker
    this.stopMovingMarker();
    this.chartCanvas.nativeElement.style.cursor = 'default';

    //switch to watching mousemove on document
    this.unlistenMouseMove();
    this.unlistenMouseMove = this.renderer.listen(document, 'mousemove', ($eventMove) => this.onMouseMovePassive($eventMove));
    setMouseMoveMode(MouseMoveMode.default, this.mouseMoveMode$);
  }

  public setActiveXyShiftIndex(chart: Chart, requiredXyShiftIndex: number | undefined): void {
    if (this.activeXyShiftIndex === requiredXyShiftIndex) {
      return;
    }

    // if it's auto X axis mode, and line is grayed out, we want to cancel action
    if (
      this.xAutoShiftActive$.value &&
      requiredXyShiftIndex != null &&
      !UnitConverterHelper.isPressureDataType(this.chartData.ChartDataColumns[requiredXyShiftIndex].DataType)
    ) {
      requiredXyShiftIndex = undefined;
    }

    this.activeXyShiftIndex = requiredXyShiftIndex;

    if (requiredXyShiftIndex === undefined) {
      this.moveScale = undefined;
      this.moveInitialMouseX = undefined;
      this.movePreviousMouseX = undefined;
      this.moveInitialMouseY = undefined;
      this.movePreviousMouseY = undefined;
      this.initialXAxisShift = undefined;
    } else if (this.activeXYShiftAxis$.value === XYShiftAxis.x) {
      this.initialXAxisShift = this.activeChartData.ChartDataSets[requiredXyShiftIndex].XAxisShift;
    }

    this.setColumnWidths(chart, this.activeXyShiftIndex);
    updateChart(chart);
  }

  private setColumnWidths(chart: Chart, activeSourceDatasetIndex: number | undefined, sizeMultiplier = 1): void {
    if (chart?.data?.datasets == null) {
      return;
    }

    for (let i = 0; i < this.activeChartData.ChartDataColumns.length; i++) {
      const sourceDataColumn = this.activeChartData.ChartDataColumns[i];
      const dataset = chart.data.datasets[i];

      const isHighlighted =
        (this.activeXYShiftAxis$.value === XYShiftAxis.x && activeSourceDatasetIndex === sourceDataColumn.DataSetIndex) ||
        (this.activeXYShiftAxis$.value === XYShiftAxis.y && activeSourceDatasetIndex === i) ||
        (this.yAutoShiftActive$.value && activeSourceDatasetIndex === i) ||
        (this.xAutoShiftActive$.value && activeSourceDatasetIndex === i);

      const selectedColumnMultiplier = isHighlighted ? 2 : 1;

      dataset.borderWidth =
        (sourceDataColumn.ColumnDefaultStyle?.lineStyle === SeriesLineStyle.scatter ? 0 : 2) * sizeMultiplier * selectedColumnMultiplier;
    }
  }

  public onDblClick(event: MouseEvent): void {
    const chart = this.chartDisplay;
    if (chart == null) {
      return;
    }

    if (this.onDblClickHandleAnnotation(event, chart)) {
      return;
    }

    if (this.onDblClickHandleGradientLine(event, chart)) {
      return;
    }

    if (this.onDblClickHandleMarker(event, chart)) {
      return;
    }

    if (this.onDblClickHandleAxis(event, chart)) {
      return;
    }

    if (this.onDblClickHandleLegend(event, chart)) {
      return;
    }

    if (this.onDblClickHandleSeries(event, chart)) {
      return;
    }

    if (this.onDblClickHandleZoomOut()) {
      // This needs to be last
      return;
    }
  }

  private async triggerGlobalChangeDetection(): Promise<void> {
    return new Promise((resolve) =>
      setTimeout(() => {
        this.cdRef.markForCheck();
        resolve();
      }, 0),
    );
  }

  private onDblClickHandleLegend(event: MouseEvent, chart: Chart): boolean {
    const x = event.offsetX;
    const y = event.offsetY;

    const legend = chart.boxes.find((box) => 'legendHitBoxes' in box && 'legendItems' in box);
    if (!legend) {
      return false;
    }

    if (x >= legend.left && x <= legend.right && y >= legend.top && y <= legend.bottom) {
      const propsToUpdate = this.chartDto?.LegendFontSize !== 0 ? this.chartDto : this.defaultLegendStyle;

      if (!propsToUpdate) {
        return false;
      }

      const { IsLegendFontBold, LegendFontSize, LegendLocation, IsLegendFontItalic, LegendFontColor } = propsToUpdate;

      this.configureLegend.emit({
        IsLegendFontBold,
        LegendFontSize,
        LegendLocation,
        IsLegendFontItalic,
        LegendFontColor,
      });
      return true;
    }

    return false;
  }

  private onDblClickHandleSeries(event: MouseEvent, chart: Chart): boolean {
    const datasetIndex = this.getNearbyDataSetIndex(event, chart, 15); // this is IChartDataDtoColumn index

    if (datasetIndex != null) {
      const series = chart.data.datasets[datasetIndex];
      const column = this.chartData.ChartDataColumns[datasetIndex];

      if (series == null || column == null) {
        return false;
      }

      // Don't do anything if clicked on Optimize or Compare Scenario child series
      if (column.AddSimulateToEvaluate || this.scenariosToCompare.scenarioIds.includes(column.ScenarioId ?? 0)) {
        return false;
      }

      const template = this.chartSeriesTemplates?.find((tpl) => rationalizeString(tpl.ColumnName) === rationalizeString(column.Name));

      const effectiveSeriesStyle = this.chartDataSeriesStyleHelpers.getSeriesStyle(
        datasetIndex,
        this.chartData,
        this.defaultSeriesStyles,
        this.chartSeriesTemplates,
      );

      const seriesProps: ConfigureSeriesPayload = {
        template,
        effectiveSeriesStyle,
        column,
      };

      this.ngZone.run(() => this.configureSeries.emit(seriesProps));

      return true;
    }

    return false;
  }

  private onDblClickHandleZoomOut(): boolean {
    this.ngZone.run(() => this.store.dispatch(chartResetZoomAction({ mode: ResetZoomMode.CHART })));
    return false;
  }

  private onDblClickHandleAxis(event: MouseEvent, chart: Chart): boolean {
    const axisId = getGetChartAxisIdAtEvent(event, chart);
    if (!axisId) {
      return false;
    }

    const axis = getChartAxisFromAxisId(axisId);
    const axisProps = this.axesProperties.find((c) => c.axis === axis);
    const axisDefaults = this.axesDefaults?.find((c) => c.axis === axis);
    const scale = chart.scales[axisId];

    const editAxisProps: IEditAxisProps = {
      axisProps: axisProps ?? this.getDefaultAxisProps(axis),
      defaultMin: scale?.min ?? 0,
      defaultMax: scale?.max ?? 100,
      axisUnit: axisDefaults?.axisUnits[0] ?? { dataType: DataType.Unknown, unitSystem: UnitSystem.None },
      currentUnitSystem: this.currentUnitSystem,
      defaultStyle: this.defaultAxisStyle,
      defaultTitle: axisDefaults?.title ?? '',
      startDate: this.chartData.StartDate,
    };

    this.ngZone.run(() => this.configureAxis.emit(editAxisProps));

    return true;
  }

  private getDefaultAxisProps(axis: ChartAxis): IAxisProps {
    const axisDefaults = this.axesDefaults?.find((c) => c.axis === axis);
    const axisUnitsSummary = axisDefaults ? getAxisUnitsSummary(axisDefaults, this.currentUnitSystem) : undefined;
    const isLogarithmic = axisUnitsSummary?.hasAnyLogarithmicUnit ?? false;

    return {
      axis,
      overrideStyle: false,
      manualLimit: false,
      isLogarithmic,
      xAxisFormat: XAxisFormat.deltaTime,
      StartTimeFileId: null,
    };
  }

  private onDblClickHandleAnnotation(event: MouseEvent, chart: Chart): boolean {
    if (!this.allowInteractions || this.chartMode$.value !== ChartMode.annotate || this.disableAddingAnnotations) {
      return false;
    }

    const point = getPointFromEvent(event);
    const drawingArea = getChartDrawingArea(chart);

    // double click outside chart area
    if (!isPointInsideRect(point, drawingArea)) {
      return false;
    }

    const plugin = Chart.registry.getPlugin(DuneFrontAnnotationId) as DuneFrontAnnotationPlugin | undefined;
    if (plugin?.willPluginHandleDoubleClick(chart, event) === true) {
      return true;
    }

    const argument = getArgumentAxisPrimaryArgSiValueFromPoint(point, chart, this);
    const value = getFirstValueAxisSiValueFromPoint(point, chart, this);

    if (argument == null || value == null) {
      return false;
    }

    this.ngZone.run(() => this.createAnnotation.emit({ argument, value, context: this, chart }));

    return true;
  }

  private onDblClickHandleGradientLine(event: MouseEvent, chart: Chart): boolean {
    if (!this.allowInteractions || this.chartMode$.value !== ChartMode.editGradientLine || this.disableAddingGradientLines) {
      return false;
    }

    const point = getPointFromEvent(event);
    const drawingArea = getChartDrawingArea(chart);

    // double click outside chart area
    if (!isPointInsideRect(point, drawingArea)) {
      return false;
    }

    const plugin = Chart.registry.getPlugin(GradientLinePluginId) as GradientLinePlugin | undefined;

    return plugin?.willPluginHandleDoubleClick(chart, event) === true;
  }

  private onXAutoShift(chart: Chart): void {
    if (
      !this.allowInteractions ||
      !this.xAutoShiftActive$.value ||
      this.activeXyShiftIndex == null ||
      this.zoomPluginHelper.isDragPending
    ) {
      return;
    }

    const axesMinMax: PartialEnumDictionary<ChartAxis, MinMax> = {};
    for (const axis of OrderedValueAxes) {
      const scale = chart.scales[getAxisId(axis, this.isRotated)];
      if (scale != null) {
        axesMinMax[axis] = { min: scale.min, max: scale.max };
      }
    }

    const argumentAxisId = this.getAxisId(ChartAxis.Argument);
    const argumentScale = chart.scales[argumentAxisId];
    const argumentStart = argumentScale.min;
    const argumentEnd = argumentScale.max;

    const params: IAutoXAxisShiftParams = {
      targetColumnIndex: this.activeXyShiftIndex,
      chartData: this.activeChartData,
      chartContext: this,
      argumentStart,
      argumentEnd,
      axesMinMax,
      verticalShifts: this.verticalShifts,
    };

    this.ngZone.run(() => this.autoXAxisShift.emit(params));
  }

  private onYAutoShift(event: MouseEvent, chart: Chart): void {
    if (
      !this.allowInteractions ||
      !this.yAutoShiftActive$.value ||
      this.activeXyShiftIndex == null ||
      this.zoomPluginHelper.isDragPending ||
      this.activeChartData == null
    ) {
      return;
    }

    const payload: IAutoYAxisShiftParams = {
      argumentPx: event.offsetX,
      chart,
      targetColumnIndex: this.activeXyShiftIndex,
      chartData: this.activeChartData,
      context: this,
    };

    this.ngZone.run(() => this.autoYAxisShift.emit(payload));
  }

  private checkAnnotations(chart: Chart, migrate = false): void {
    if (migrate) {
      const migratedAnnotations = migrateAnnotations(this.annotations, this, chart);
      if (migratedAnnotations != null && migratedAnnotations.length > 0) {
        this.annotationsUpdated.emit(migratedAnnotations);

        const migratedAnnotationsIds = migratedAnnotations.map((a) => a.Id);
        const otherAnnotations = this.annotations.filter((a) => !migratedAnnotationsIds.includes(a.Id));

        this.annotations = [...migratedAnnotations, ...otherAnnotations];
      }
    }

    checkAnnotations(this.annotations, this.annotationsVisible$.value, 1, this, chart);
  }

  private checkGradientLines(chart: Chart): void {
    updateGradientLinePluginOptions(this.gradientLines, this.getGradientLineScales(), this.gradientLinesVisible$.value, chart, this);
    this.updateGradientLinesMode();
  }

  private getGradientLineMode(): GradientLineOperationMode {
    if (this.chartMode$.value === ChartMode.editGradientLine && !this.disableAddingGradientLines) {
      return 'edit';
    } else if (this.chartMode$.value === ChartMode.default) {
      return 'highlight';
    }

    return 'inactive';
  }

  private updateGradientLinesMode(): void {
    const chart = this.chartDisplay;
    if (chart == null) {
      return;
    }

    const pluginOptions = getGradientLinePluginOptions(chart);
    if (pluginOptions == null) {
      return;
    }

    const mode = this.getGradientLineMode();
    if (mode !== pluginOptions.mode) {
      pluginOptions.mode = mode;
      updateChart(chart);
    }
  }

  private getGradientLineScales(): ScaleInfo[] {
    const axesDefaults = this.axesDefaults;
    if (axesDefaults == null) {
      return [];
    }

    const result: ScaleInfo[] = [];

    for (const axisData of axesDefaults) {
      if (axisData.title == null || axisData.axisUnits.length === 0) {
        continue;
      }

      const props = this.axesProperties.find((axisProps) => axisProps.axis === axisData.axis);
      const axisUnitsSummary = getAxisUnitsSummary(axisData, this.currentUnitSystem);
      const axisId = getAxisId(axisData.axis, this.isRotated);

      const title = props?.title ?? axisUnitsSummary.unitTypeNames.toString();

      result.push({
        title,
        units: axisUnitsSummary.unitTypeUnits,
        id: axisId,
        isArgument: axisData.axis === ChartAxis.Argument,
      });
    }

    return result;
  }

  private checkAnnotationsOptions(): void {
    const chart = this.chartDisplay;
    if (chart == null) {
      return;
    }

    const pluginOptions = getAnnotationPluginOptions(chart);
    if (pluginOptions == null) {
      return;
    }

    const readOnly = this.chartMode$.value !== ChartMode.annotate || this.disableAddingAnnotations;
    if (pluginOptions.readonly !== readOnly) {
      pluginOptions.readonly = readOnly;
      updateChart(chart);
    }
  }

  public onDragDefault(chart: Chart): void {
    if (this.mouseMoveMode$.value === MouseMoveMode.default) {
      this.setActiveXyShiftIndex(chart, undefined);
    }

    updateChart(chart);
  }

  public setDataSeries(chartDataDto: IChartDataDto, chart: Chart): void {
    this.argumentAxisUnit = getArgumentAxisUnit(chartDataDto);

    this.writeChartValues(chartDataDto, true, chart);
    this.setDataSeriesStyle();

    updateColumnsVisibility(chartDataDto, chart, this.chartSeriesVisibilityManager.getHiddenColumnKeys());

    updateChartWithHitRadiusFix(chart);
  }

  /**
   * Returns set of IChartDataDtoColumn ids that inactive in X/Y/Auto Shift modes
   */
  public get inactiveColumnIds(): Set<number> | null {
    return this.xShiftInactiveColumnIds ?? this.yShiftInactiveColumnIds;
  }

  private get yShiftInactiveColumnIds(): Set<number> | null {
    if (!this.yAutoShiftActive$.value && this.activeXYShiftAxis$.value !== XYShiftAxis.y) {
      return null;
    }

    const result = new Set<number>();

    for (const column of this.activeChartData.ChartDataColumns) {
      if (column.ColumnId == null) {
        continue;
      }

      const file = this.storageFiles?.find((f) => f.Id === column.FileId);
      if (file == null || file.FileType === DataFileType.EquationResult) {
        result.add(column.ColumnId);
      }
    }

    return result;
  }

  private get xShiftInactiveColumnIds(): Set<number> | null {
    if (!this.xAutoShiftActive$.value) {
      return null;
    }

    const nonPressureColumnIds = this.activeChartData.ChartDataColumns.filter(
      (col) => col.ColumnId != undefined && !UnitConverterHelper.isPressureDataType(col.DataType),
    ).map((col) => (col.ColumnId != null ? col.ColumnId : -1));

    return new Set(nonPressureColumnIds);
  }

  private setDataSeriesStyle(): void {
    this.chartDataSeriesStyleHelpers.setDataSeriesStyle(this, this.chartDisplay, this.chartData, 1, this.inactiveColumnIds);
  }

  private getInitialDuneFrontAnnotationOptions(fitWithinDrawingArea?: boolean): DuneFrontAnnotationOptions {
    return {
      annotations: [],
      annotationMoved:
        () =>
        (annotation: Annotation): void =>
          this.onAnnotationMoved(annotation),
      annotationDoubleClicked:
        () =>
        (annotation: Annotation): void =>
          this.onAnnotationDoubleClicked(annotation),
      canStartDrag: (() => (): boolean => this.canStartAnnotationDrag()) as any,
      dragChanged:
        () =>
        (isInDragMode: boolean): void =>
          this.onAnnotationPluginIsDragging(isInDragMode),
      readonly: this.chartMode$.value !== ChartMode.annotate || this.disableAddingAnnotations,
      fitWithinDrawingArea,
    };
  }

  private getInitialGradientLinePluginOptions(): GradientLinePluginOptions {
    const chart = this.chartDisplay;
    const converter = chart ? ChartConversionsHelper.getConverter(chart, this) : undefined;

    const scales: ScaleInfo[] = [];

    return {
      lines: [],
      mode: this.getGradientLineMode(),
      scales,
      xScaleID: converter?.xScaleID ?? '',
      yScaleID: converter?.yScaleID ?? '',
      defaultStyle: this.defaultGradientLineStyle,
      canStartDrag: (() => (): boolean => this.canStartGradientLineDrag()) as any,
      dragChanged:
        () =>
        (isInDragMode: boolean): void =>
          this.onGradientLinePluginIsDragging(isInDragMode),
      highlightedLineChanged:
        () =>
        (isHighlighted: boolean): void =>
          this.ngZone.run(() => {
            this.anyGradientLineHighlighted$.next(isHighlighted);
          }),
      gradientLineCreatedOrUpdated:
        () =>
        (line: GradientLine): void =>
          this.onGradientLineCreatedOrUpdated(line),
      gradientLineDoubleClicked:
        () =>
        (line: GradientLine): void =>
          this.onGradientLineDoubleClicked(line),
    };
  }

  private getChartConfiguration(fitAnnotationsWithinDrawingArea?: boolean): ChartConfiguration {
    const argConverter = (arg: number): string => this.chartDataHelpers.formatArgument(arg, this, this.chartData);

    return this.chartDataHelpers.buildChartConfiguration(
      this,
      this.allowInteractions,
      this.getInitialDuneFrontAnnotationOptions(fitAnnotationsWithinDrawingArea),
      this.getInitialGradientLinePluginOptions(),
      this.zoomPluginHelper,
      {
        ...this.createChartLegendOptions(),
        onClick: (_: any, legendItem: LegendItem) => this.onChartLegendClicked(legendItem),
      },
      argConverter,
    );
  }

  /**
   * This is custom implementation of Chart Legend OnClick handler.
   * Default implementation allows to hide all DataSets, our requirement
   * is to keep prevent last visible DataSet from being hidden
   * @param legendItem
   * @private
   */
  private onChartLegendClicked(legendItem: LegendItem): void {
    const chart = this.chartDisplay;
    if (chart == null) {
      return;
    }

    const { datasetIndex } = legendItem;
    if (datasetIndex == null) {
      return;
    }

    const column = this.activeChartData.ChartDataColumns[datasetIndex];

    // when in Optimise we hide/show Simulate columns together with Evaluate
    // find matching Simulate column (if exists)
    const simulateColumn = this.activeChartData.ChartDataColumns.find((col) => col.AddSimulateToEvaluate && col.Name === column.Name);
    const simulateColumnIndex = simulateColumn != null ? this.activeChartData.ChartDataColumns.indexOf(simulateColumn) : -1;

    const indexesToChange = [datasetIndex, ...(simulateColumnIndex != -1 ? [simulateColumnIndex] : [])];

    const canHide = chart.getVisibleDatasetCount() > indexesToChange.length;
    const wasVisible = chart.isDatasetVisible(datasetIndex);
    const visible = !wasVisible;

    if (visible || (wasVisible && canHide)) {
      // update chart dataset visibilities
      for (const columnIndex of indexesToChange) {
        chart.setDatasetVisibility(columnIndex, visible);
      }
      chart.update();

      this.chartSeriesVisibilityManager.onColumnsVisibilityChanged([column, ...(simulateColumn ? [simulateColumn] : [])], visible, this);
    }
  }

  private canStartAnnotationDrag(): boolean {
    return !this.zoomPluginHelper.isDragToPanPending;
  }

  private canStartGradientLineDrag(): boolean {
    return !this.zoomPluginHelper.isDragToPanPending;
  }

  private onAnnotationPluginIsDragging(isInDragMode: boolean): void {
    setMouseMoveMode(isInDragMode ? MouseMoveMode.dragAnnotation : MouseMoveMode.default, this.mouseMoveMode$);
  }

  private onGradientLinePluginIsDragging(isInDragMode: boolean): void {
    setMouseMoveMode(isInDragMode ? MouseMoveMode.dragGradientLine : MouseMoveMode.default, this.mouseMoveMode$);
  }

  private onAnnotationMoved(annotation: Annotation): void {
    const iAnnotation = this.annotations.find((a) => a.Id === annotation.id);
    if (iAnnotation == null) {
      throw new Error("Can't find annotation with Id: " + annotation.id);
    }

    const xAxis = getChartAxisFromAxisId(annotation.xScaleID);
    const yAxis = getChartAxisFromAxisId(annotation.yScaleID);

    const xAxisUnit = this.getAxisUnit(xAxis);
    const yAxisUnit = this.getAxisUnit(yAxis);

    if (xAxisUnit == null || yAxisUnit == null) {
      throw new Error("Can't find annotation x/y axis data type!");
    }

    const xAxisValue = UnitConverterHelper.convertToSi(xAxisUnit.unitSystem, this.currentUnitSystem, annotation.xValue);
    const yAxisValue = UnitConverterHelper.convertToSi(yAxisUnit.unitSystem, this.currentUnitSystem, annotation.yValue);

    const argument = this.isRotated ? yAxisValue : xAxisValue;
    const value = this.isRotated ? xAxisValue : yAxisValue;

    const xAxisBoxValue = UnitConverterHelper.convertToSi(xAxisUnit.unitSystem, this.currentUnitSystem, annotation.centerX);
    const yAxisBoxValue = UnitConverterHelper.convertToSi(yAxisUnit.unitSystem, this.currentUnitSystem, annotation.centerY);

    const boxArgument = this.isRotated ? yAxisBoxValue : xAxisBoxValue;
    const boxValue = this.isRotated ? xAxisBoxValue : yAxisBoxValue;

    const ann: IAnnotation = {
      ...iAnnotation,
      argument,
      value,
      boxArgument,
      boxValue,
      boxWidth: annotation.width,
      boxHeight: annotation.height,
    };

    const newAnnotations = this.annotations.filter((a) => a.Id !== ann.Id);
    newAnnotations.push(ann);
    this.annotations = newAnnotations;
    const chart = this.chartDisplay;
    if (chart != null) {
      this.checkAnnotations(chart);
    }

    this.annotationsUpdated.emit([ann]);
  }

  private onAnnotationDoubleClicked(annotation: Annotation): void {
    const iAnnotation = this.annotations.find((a) => a.Id === annotation.id);
    if (iAnnotation == null) {
      throw new Error("Can't find annotation with Id: " + annotation.id);
    }

    this.editAnnotation.emit(iAnnotation);
  }

  private onGradientLineCreatedOrUpdated({
    id,
    xAxisValue1,
    yAxisValue1,
    xAxisValue2,
    yAxisValue2,
    xScaleID,
    yScaleID,
  }: GradientLine): void {
    const chart = this.chartDisplay;
    if (chart == null) {
      return;
    }

    const converter = ChartConversionsHelper.getConverter(chart, this, { xScaleID, yScaleID });
    if (converter == null) {
      return;
    }

    const p1 = converter.convertFromAxisValuesToSi(xAxisValue1, yAxisValue1);
    const p2 = converter.convertFromAxisValuesToSi(xAxisValue2, yAxisValue2);

    if (id > 0) {
      const gradientLine = this.gradientLines.find((line) => line.Id === id);
      if (gradientLine == null) {
        throw new Error(`Can not find Slope Line with id: ${id}`);
      }

      const updatedGradientLine = {
        ...gradientLine,
        argument1: p1.argument,
        value1: p1.value,
        argument2: p2.argument,
        value2: p2.value,
      };
      this.gradientLinesUpdated.emit([updatedGradientLine]);
    } else {
      const createGradientLinePayload: CreateGradientLinePayload = {
        argument1: p1.argument,
        value1: p1.value,
        argument2: p2.argument,
        value2: p2.value,
      };

      this.createGradientLine.emit(createGradientLinePayload);
    }
  }

  private onGradientLineDoubleClicked(line: GradientLine): void {
    const gradientLine = this.gradientLines.find((l) => l.Id === line.id);
    if (gradientLine == null) {
      throw new Error("Can't find Slope Line with Id: " + line.id);
    }

    this.editGradientLine.emit(gradientLine);
  }

  public writeChartValues(chartData: IChartDataDto, updateScaleLimits: boolean, chart: Chart): void {
    this.chartDataHelpers.writeChartValues(this, chartData, chart, updateScaleLimits);
  }

  private initializeChartDatasetsScales(chartDataDto: IChartDataDto, chart: Chart, sizeMultiplier: number, afterViewInit: boolean): void {
    if (!chart.config.options || !chart.config.options.scales) {
      return;
    }

    const { ArgumentStart, ArgumentEnd } = this.chartData;

    const axesDefaults = this.chartDataHelpers.initializeChartDatasetsScales(
      this,
      chartDataDto,
      chart,
      afterViewInit,
      sizeMultiplier,
      ArgumentStart,
      ArgumentEnd,
    );

    if (axesDefaults != null && (this.axesDefaults == null || afterViewInit)) {
      this.axesDefaults = axesDefaults;
    }
  }

  private getAxisId(chartAxis: ChartAxis): string {
    return getAxisId(chartAxis, this.isRotated);
  }

  private getAxisUnit(axis: ChartAxis): IAxisUnit | undefined {
    return getAxisUnit(axis, this.axesDefaults);
  }

  private updateChartLegend(chart: Chart): void {
    if (chart?.options?.plugins?.legend != null) {
      const chartLegendOptions = this.createChartLegendOptions();
      chart.options.plugins.legend.position = chartLegendOptions.position;
      chart.options.plugins.legend.labels = chartLegendOptions.labels;
    }
  }

  private createChartLegendOptions(): DeepPartial<LegendOptions<'line'>> {
    const legendProps = this.chartDto?.LegendFontSize !== 0 ? this.chartDto : this.defaultLegendStyle;
    const legendPosition = ChartLegendLocation[legendProps?.LegendLocation ?? 0].toLowerCase();
    const legendTextColor = legendProps?.LegendFontColor != null ? legendProps.LegendFontColor : defaultLegendFontColor;

    const filter = ({ text }: LegendItem): boolean =>
      this.chartData.ChartDataColumns.every(
        (column) =>
          !column.AddSimulateToEvaluate ||
          text !==
            this.chartDataHelpers.getSeriesLabel(
              column,
              this.chartData.ChartDataColumns,
              this.scenariosToCompare,
              this.convertUnitPipe,
              this.currentUnitSystem,
            ),
      );

    return {
      display: this.displayLegend,
      position: legendPosition as LayoutPosition,
      labels: {
        font: getFontSpec(
          legendProps != null ? legendProps.LegendFontSize : defaultLegendFontSize,
          legendProps?.IsLegendFontBold || false,
          legendProps?.IsLegendFontItalic || false,
        ),
        color: legendTextColor,
        filter,
      },
    };
  }

  // region marker

  private onDblClickHandleMarker(event: MouseEvent, chart: Chart): boolean {
    if (
      !this.allowInteractions ||
      this.disableAddingMarkers ||
      (this.chartMode$.value !== ChartMode.editVerticalMarker && this.chartMode$.value !== ChartMode.editHorizontalMarker)
    ) {
      return false;
    }

    const point = getPointFromEvent(event);
    const drawingArea = getChartDrawingArea(chart);

    // double click outside chart area
    if (!isPointInsideRect(point, drawingArea)) {
      return false;
    }

    if (this.activeMarker$.value) {
      const displayMaker = this.activeDisplayMarker;
      if (!displayMaker) {
        return false;
      }

      this.ngZone.run(() => this.editMarker.emit(displayMaker));
      return true;
    }

    // no active marker - so use doubleClick to create a new marker
    if (chart.data?.datasets == null) {
      return false;
    }

    const isValueAxisMarker = this.isValueAxisMarkerMode();
    const value = isValueAxisMarker
      ? getFirstValueAxisSiValueFromPoint(point, chart, this)
      : getArgumentAxisPrimaryArgSiValueFromPoint(point, chart, this);
    if (value == null) {
      return false;
    }

    this.ngZone.run(() => this.createMarker.emit({ value, isValueAxisMarker }));

    return true;
  }

  private isValueAxisMarkerMode(): boolean {
    return (
      (this.chartMode$.value === ChartMode.editHorizontalMarker && !this.isRotated) ||
      (this.chartMode$.value === ChartMode.editVerticalMarker && this.isRotated)
    );
  }

  private checkMarkers(chart: Chart): void {
    const newActiveMarker = this.chartMarkerHelpers.checkMarkers(this.markers, this.markersVisible$.value, this, chart, 1);
    setActiveMarker(newActiveMarker, this.activeMarker$);

    updateChart(chart);
  }

  private stopMovingMarker(): void {
    const activeMarker = this.activeMarker$.value;
    if (activeMarker == null || this.currentUnitSystem == null) {
      return;
    }

    const chart = this.chartDisplay;
    const annotationPlugin = chart?.options?.plugins?.annotation;
    const annotations = annotationPlugin?.annotations as DeepPartial<LineAnnotationOptions>[];
    if (chart == null || annotationPlugin == null || annotations == null) {
      return;
    }

    const displayMaker = this.activeDisplayMarker;
    if (!displayMaker) {
      throw Error('Missing display marker');
    }

    const lineStyle = displayMaker.isOverrideStyle ? displayMaker.style.ChartMarkerLineStyle : this.defaultMarkerStyle.ChartMarkerLineStyle;
    const lineThickness = displayMaker.isOverrideStyle
      ? displayMaker.style.ChartMarkerLineThickness
      : this.defaultMarkerStyle.ChartMarkerLineThickness;

    const updatedActiveMark = {
      ...activeMarker,
      borderDash: borderDashForLineStyle(lineStyle, lineThickness),
    };

    const otherAnnotations = annotations.filter((annotation) => annotation.id !== activeMarker.id);

    annotationPlugin.annotations = [...otherAnnotations, updatedActiveMark];
    updateChart(chart);

    const updatedActiveMarkValue = updatedActiveMark.value as number;
    const isValueAxisMarker = this.isValueAxisMarkerMode();

    const axis = isValueAxisMarker ? getFirstAvailableValueAxis(chart, this.isRotated) : ChartAxis.Argument;
    if (axis == null) {
      return;
    }

    const axisUnit = this.getAxisUnit(axis);
    if (axisUnit == null) {
      return;
    }

    const siValue = UnitConverterHelper.convertToSi(axisUnit.unitSystem, this.currentUnitSystem, updatedActiveMarkValue);

    if (siValue !== displayMaker.value) {
      this.ngZone.run(() =>
        this.markerMoved.emit({
          ...displayMaker,
          value: siValue,
        }),
      );
    }
  }

  public copyChartSize(): { width: string; height: string } {
    const { w, h } = copyChartRatioFromStr(this.defaultCopyChartOptions?.CopyChartRatio ?? '') ?? defaultCopyChartRatio;
    return { width: defaultBaseCopyChartSize + 'px', height: defaultBaseCopyChartSize * (h / w) + 'px' };
  }

  // endregion
  private onDeleteKeyDown(): void {
    if (!this.isChartFocused || this.chartDisplay == null || this.modalService.areAnyDialogsOpen()) {
      return;
    }

    const chart = this.chartDisplay;
    if (chart == null) {
      return;
    }

    // annotations
    if (this.chartMode$.value === ChartMode.annotate) {
      const plugin = Chart.registry.getPlugin(DuneFrontAnnotationId) as DuneFrontAnnotationPlugin | undefined;
      const selectedAnnotationId = plugin?.getSelectedAnnotationId(chart);
      const selectedAnnotation = this.annotations.find((annotation) => annotation.Id === selectedAnnotationId);

      if (selectedAnnotation) {
        this.keyboardDeleteAnnotation.emit(selectedAnnotation);
      }
    }

    // gradient lines
    else if (this.chartMode$.value === ChartMode.editGradientLine) {
      const plugin = Chart.registry.getPlugin(GradientLinePluginId) as GradientLinePlugin | undefined;
      const selectedGradientLineId = plugin?.getSelectedGradientLineId(chart);
      const selectedGradientLine = this.gradientLines.find((line) => line.Id === selectedGradientLineId);

      if (selectedGradientLine) {
        this.keyboardDeleteGradientLine.emit(selectedGradientLine);
      }
    }
  }

  private onChartBlur(): void {
    if (this.chartDisplay == null) {
      return;
    }

    // check if annotation modal is open. Don't blur if it is
    const isAnnotationModalOpen = !!document.querySelector(`.${ChartAnnotationComponentClass}`);
    if (isAnnotationModalOpen) {
      return;
    }

    this.isChartFocused = false;
    const plugin = Chart.registry.getPlugin(DuneFrontAnnotationId) as DuneFrontAnnotationPlugin | undefined;
    if (plugin) {
      plugin?.unSelectAnnotation(this.chartDisplay);
    }
  }

  private setupInterpolate(): void {
    (Interaction.modes as any).interpolate = interpolate;
  }
}
