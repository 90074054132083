import { ColumnType, IGridColumnConfig } from '../../../../../shared/components/grid/grid.interfaces';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { PipeDimensionData } from './pipe-data.component';

export const pipeDataGridConfig: IGridColumnConfig<PipeDimensionData>[] = [
  { disabled: false, visible: true, colId: ' ', type: ColumnType.selection },
  {
    disabled: false,
    visible: true,
    colId: 'outerDiameter',
    type: ColumnType.number,
    unitSystem: UnitSystem.Diameter,
    headerText: 'OD',
    decimalPlaces: 3,
  },
  {
    disabled: false,
    visible: true,
    colId: 'innerDiameter',
    type: ColumnType.number,
    unitSystem: UnitSystem.Diameter,
    headerText: 'ID',
    decimalPlaces: 3,
  },
  {
    disabled: false,
    visible: true,
    colId: 'weight',
    type: ColumnType.number,
    unitSystem: UnitSystem.Linear_Mass,
    headerText: 'Weight',
  },
];

export const pipeDataRows = [
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 0, outerDiameter: 0.02667, innerDiameter: 0.0209296, weight: 1.696509252 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 1, outerDiameter: 0.02667, innerDiameter: 0.0209296, weight: 1.7857992126 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 2, outerDiameter: 0.02667, innerDiameter: 0.0188468, weight: 2.2917756562 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 3, outerDiameter: 0.033401, innerDiameter: 0.0266446, weight: 2.5298822178 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 4, outerDiameter: 0.033401, innerDiameter: 0.0266446, weight: 2.5596455381 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 5, outerDiameter: 0.033401, innerDiameter: 0.0266446, weight: 2.6786988189 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 6, outerDiameter: 0.033401, innerDiameter: 0.0243078, weight: 3.3334918635 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 7, outerDiameter: 0.042164, innerDiameter: 0.035814, weight: 3.125148622 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 8, outerDiameter: 0.042164, innerDiameter: 0.035052, weight: 3.4227818241 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 9, outerDiameter: 0.042164, innerDiameter: 0.035052, weight: 3.4674268045 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 10, outerDiameter: 0.042164, innerDiameter: 0.035052, weight: 3.5715984252 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 11, outerDiameter: 0.042164, innerDiameter: 0.0324612, weight: 4.5686696522 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 12, outerDiameter: 0.04826, innerDiameter: 0.04191, weight: 3.5715984252 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 13, outerDiameter: 0.04826, innerDiameter: 0.040894, weight: 4.0924565289 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 14, outerDiameter: 0.04826, innerDiameter: 0.040894, weight: 4.107338189 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 15, outerDiameter: 0.04826, innerDiameter: 0.040894, weight: 4.3156814304 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 16, outerDiameter: 0.04826, innerDiameter: 0.0381, weight: 5.5508592192 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 17, outerDiameter: 0.04826, innerDiameter: 0.03556, weight: 6.5776937664 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 18, outerDiameter: 0.04826, innerDiameter: 0.03302, weight: 7.6640549541 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 19, outerDiameter: 0.0524002, innerDiameter: 0.0444754, weight: 4.8365395341 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 20, outerDiameter: 0.0524002, innerDiameter: 0.0409702, weight: 6.6967470472 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 21, outerDiameter: 0.060325, innerDiameter: 0.0518414, weight: 5.952664042 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 22, outerDiameter: 0.060325, innerDiameter: 0.050673, weight: 6.8455636483 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 23, outerDiameter: 0.060325, innerDiameter: 0.050673, weight: 6.9943802493 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 24, outerDiameter: 0.060325, innerDiameter: 0.050673, weight: 7.2176051509 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 25, outerDiameter: 0.060325, innerDiameter: 0.0474218, weight: 8.6313628609 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 26, outerDiameter: 0.060325, innerDiameter: 0.0474218, weight: 8.8545877625 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 27, outerDiameter: 0.060325, innerDiameter: 0.045339, weight: 9.8218956693 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 28, outerDiameter: 0.060325, innerDiameter: 0.046101, weight: 9.8963039698 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 29, outerDiameter: 0.060325, innerDiameter: 0.0432562, weight: 10.9380201772 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 30, outerDiameter: 0.060325, innerDiameter: 0.0432562, weight: 11.0868367782 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 31, outerDiameter: 0.073025, innerDiameter: 0.0620014, weight: 9.5242624672 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 32, outerDiameter: 0.073025, innerDiameter: 0.0620014, weight: 9.6730790682 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 33, outerDiameter: 0.073025, innerDiameter: 0.0620014, weight: 10.1939371719 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 34, outerDiameter: 0.073025, innerDiameter: 0.0590042, weight: 11.6076948819 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 35, outerDiameter: 0.073025, innerDiameter: 0.0590042, weight: 11.7565114829 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 36, outerDiameter: 0.073025, innerDiameter: 0.0573786, weight: 12.7982276903 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 37, outerDiameter: 0.073025, innerDiameter: 0.0573786, weight: 12.9470442913 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 38, outerDiameter: 0.073025, innerDiameter: 0.055753, weight: 13.9143521982 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 39, outerDiameter: 0.073025, innerDiameter: 0.055753, weight: 14.0631687992 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 40, outerDiameter: 0.073025, innerDiameter: 0.0546354, weight: 15.4769265092 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 41, outerDiameter: 0.073025, innerDiameter: 0.0531114, weight: 15.6257431102 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 42, outerDiameter: 0.073025, innerDiameter: 0.050673, weight: 17.1139091207 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 43, outerDiameter: 0.0889, innerDiameter: 0.0779272, weight: 11.4588782808 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 44, outerDiameter: 0.0889, innerDiameter: 0.0759968, weight: 13.6911272966 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 45, outerDiameter: 0.0889, innerDiameter: 0.0759968, weight: 13.8399438976 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 46, outerDiameter: 0.0889, innerDiameter: 0.0759968, weight: 14.1375770997 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 47, outerDiameter: 0.0889, innerDiameter: 0.0759968, weight: 15.1792933071 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 48, outerDiameter: 0.0889, innerDiameter: 0.06985, weight: 18.8997083333 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 49, outerDiameter: 0.0889, innerDiameter: 0.06985, weight: 19.271749836 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 50, outerDiameter: 0.0889, innerDiameter: 0.0702056, weight: 19.7926079396 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 51, outerDiameter: 0.0889, innerDiameter: 0.067056, weight: 21.2807739501 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 52, outerDiameter: 0.0889, innerDiameter: 0.0647192, weight: 23.0665731627 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 53, outerDiameter: 0.0889, innerDiameter: 0.0660908, weight: 23.0665731627 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 54, outerDiameter: 0.0889, innerDiameter: 0.061976, weight: 25.2988221785 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 55, outerDiameter: 0.1016, innerDiameter: 0.0901192, weight: 14.1375770997 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 56, outerDiameter: 0.1016, innerDiameter: 0.0882904, weight: 16.3698261155 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 57, outerDiameter: 0.1016, innerDiameter: 0.0882904, weight: 17.6347672244 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 58, outerDiameter: 0.1016, innerDiameter: 0.084836, weight: 19.6437913386 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 59, outerDiameter: 0.1016, innerDiameter: 0.084836, weight: 20.834324147 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 60, outerDiameter: 0.1016, innerDiameter: 0.082296, weight: 23.3642063648 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 61, outerDiameter: 0.1016, innerDiameter: 0.080518, weight: 23.959472769 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 62, outerDiameter: 0.1016, innerDiameter: 0.0762, weight: 28.1263375984 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 63, outerDiameter: 0.1016, innerDiameter: 0.070612, weight: 33.0372854331 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 64, outerDiameter: 0.1143, innerDiameter: 0.103886, weight: 14.1375770997 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 65, outerDiameter: 0.1143, innerDiameter: 0.1029208, weight: 15.6257431102 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 66, outerDiameter: 0.1143, innerDiameter: 0.1016, weight: 17.2627257218 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 67, outerDiameter: 0.1143, innerDiameter: 0.1005332, weight: 18.7508917323 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 68, outerDiameter: 0.1143, innerDiameter: 0.1005332, weight: 18.9741166339 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 69, outerDiameter: 0.1143, innerDiameter: 0.099568, weight: 20.0902411417 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 70, outerDiameter: 0.1143, innerDiameter: 0.1005332, weight: 20.4622826444 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 71, outerDiameter: 0.1143, innerDiameter: 0.0971804, weight: 22.4713067585 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 72, outerDiameter: 0.1143, innerDiameter: 0.0971804, weight: 22.6201233596 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 73, outerDiameter: 0.1143, innerDiameter: 0.0971804, weight: 24.7035557743 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 74, outerDiameter: 0.1143, innerDiameter: 0.094996, weight: 25.2988221785 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 75, outerDiameter: 0.1143, innerDiameter: 0.092456, weight: 28.1263375984 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 76, outerDiameter: 0.1143, innerDiameter: 0.092456, weight: 29.76332021 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 77, outerDiameter: 0.1143, innerDiameter: 0.0889, weight: 31.9955692257 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 78, outerDiameter: 0.1143, innerDiameter: 0.0889, weight: 33.9599483596 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 79, outerDiameter: 0.1143, innerDiameter: 0.085852, weight: 35.2695344488 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 80, outerDiameter: 0.1143, innerDiameter: 0.082296, weight: 38.692316273 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 81, outerDiameter: 0.127, innerDiameter: 0.115824, weight: 17.1139091207 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 82, outerDiameter: 0.127, innerDiameter: 0.1141476, weight: 19.3461581365 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 83, outerDiameter: 0.127, innerDiameter: 0.1119632, weight: 22.3224901575 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 84, outerDiameter: 0.127, innerDiameter: 0.1119632, weight: 24.1826976706 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 85, outerDiameter: 0.127, innerDiameter: 0.1086104, weight: 26.786988189 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 86, outerDiameter: 0.127, innerDiameter: 0.1086104, weight: 29.0192372047 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 87, outerDiameter: 0.127, innerDiameter: 0.1048004, weight: 31.8467526247 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 88, outerDiameter: 0.127, innerDiameter: 0.1027176, weight: 34.5254514436 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 89, outerDiameter: 0.127, innerDiameter: 0.1016, weight: 35.864800853 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 90, outerDiameter: 0.127, innerDiameter: 0.1016, weight: 38.0970498688 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 91, outerDiameter: 0.1397, innerDiameter: 0.1273048, weight: 20.834324147 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 92, outerDiameter: 0.1397, innerDiameter: 0.12573, weight: 23.0665731627 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 93, outerDiameter: 0.1397, innerDiameter: 0.1242568, weight: 25.2988221785 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 94, outerDiameter: 0.1397, innerDiameter: 0.1242568, weight: 28.5727874016 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 95, outerDiameter: 0.1397, innerDiameter: 0.1213612, weight: 29.76332021 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 96, outerDiameter: 0.1397, innerDiameter: 0.1213612, weight: 32.5908356299 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 97, outerDiameter: 0.1397, innerDiameter: 0.118618, weight: 34.2278182415 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 98, outerDiameter: 0.1397, innerDiameter: 0.118618, weight: 36.7577004593 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 99, outerDiameter: 0.1397, innerDiameter: 0.1143, weight: 39.8828490814 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 100, outerDiameter: 0.1397, innerDiameter: 0.1111504, weight: 44.1985305118 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 101, outerDiameter: 0.1397, innerDiameter: 0.10795, weight: 48.5142119423 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 102, outerDiameter: 0.1397, innerDiameter: 0.1048004, weight: 52.5322601706 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 103, outerDiameter: 0.1397, innerDiameter: 0.1016, weight: 56.550308399 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 104, outerDiameter: 0.1397, innerDiameter: 0.0984504, weight: 60.2707234252 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 105, outerDiameter: 0.1397, innerDiameter: 0.09525, weight: 64.1399550525 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 106, outerDiameter: 0.168275, innerDiameter: 0.1536446, weight: 29.76332021 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 107, outerDiameter: 0.168275, innerDiameter: 0.1503934, weight: 35.715984252 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 108, outerDiameter: 0.168275, innerDiameter: 0.151511, weight: 37.5017834646 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 109, outerDiameter: 0.168275, innerDiameter: 0.1498854, weight: 41.2221984908 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 110, outerDiameter: 0.168275, innerDiameter: 0.1470914, weight: 41.668648294 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 111, outerDiameter: 0.168275, innerDiameter: 0.144145, weight: 47.621312336 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 112, outerDiameter: 0.1778, innerDiameter: 0.1660652, weight: 25.2988221785 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 113, outerDiameter: 0.1778, innerDiameter: 0.1639824, weight: 29.76332021 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 114, outerDiameter: 0.1778, innerDiameter: 0.1616964, weight: 34.2278182415 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 115, outerDiameter: 0.1778, innerDiameter: 0.1594104, weight: 38.692316273 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 116, outerDiameter: 0.1778, innerDiameter: 0.1570736, weight: 43.1568143045 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 117, outerDiameter: 0.1778, innerDiameter: 0.1547876, weight: 47.621312336 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 118, outerDiameter: 0.1778, innerDiameter: 0.1525016, weight: 52.0858103675 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 119, outerDiameter: 0.1778, innerDiameter: 0.150368, weight: 56.550308399 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 120, outerDiameter: 0.1778, innerDiameter: 0.14605, weight: 63.5446886483 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 121, outerDiameter: 0.1778, innerDiameter: 0.1429004, weight: 69.0509028871 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 122, outerDiameter: 0.1778, innerDiameter: 0.1397, weight: 74.557117126 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 123, outerDiameter: 0.1778, innerDiameter: 0.1365504, weight: 79.7656981627 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 124, outerDiameter: 0.1778, innerDiameter: 0.13335, weight: 84.9742791995 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 125, outerDiameter: 0.193675, innerDiameter: 0.178435, weight: 35.715984252 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 126, outerDiameter: 0.193675, innerDiameter: 0.1770126, weight: 39.2875826772 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 127, outerDiameter: 0.193675, innerDiameter: 0.174625, weight: 44.1985305118 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 128, outerDiameter: 0.193675, innerDiameter: 0.171831, weight: 50.1511945538 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 129, outerDiameter: 0.193675, innerDiameter: 0.168275, weight: 58.0384744094 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 130, outerDiameter: 0.193675, innerDiameter: 0.1651254, weight: 63.6935052493 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 131, outerDiameter: 0.193675, innerDiameter: 0.163449, weight: 67.4139202756 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 132, outerDiameter: 0.193675, innerDiameter: 0.161925, weight: 70.0926190945 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 133, outerDiameter: 0.193675, innerDiameter: 0.1587754, weight: 76.1940997375 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 134, outerDiameter: 0.193675, innerDiameter: 0.155575, weight: 82.2955803806 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 135, outerDiameter: 0.19685, innerDiameter: 0.166624, weight: 68.604453084 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 136, outerDiameter: 0.219075, innerDiameter: 0.2056638, weight: 35.715984252 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 137, outerDiameter: 0.219075, innerDiameter: 0.2036318, weight: 41.668648294 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 138, outerDiameter: 0.219075, innerDiameter: 0.2011934, weight: 47.621312336 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 139, outerDiameter: 0.219075, innerDiameter: 0.198755, weight: 53.573976378 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 140, outerDiameter: 0.219075, innerDiameter: 0.196215, weight: 59.5266404199 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 141, outerDiameter: 0.219075, innerDiameter: 0.193675, weight: 65.4793044619 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 142, outerDiameter: 0.219075, innerDiameter: 0.1907794, weight: 72.9201345144 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 143, outerDiameter: 0.244475, innerDiameter: 0.2286254, weight: 48.0677621391 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 144, outerDiameter: 0.244475, innerDiameter: 0.2265934, weight: 53.573976378 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 145, outerDiameter: 0.244475, innerDiameter: 0.224409, weight: 59.5266404199 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 146, outerDiameter: 0.244475, innerDiameter: 0.222377, weight: 64.7352214567 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 147, outerDiameter: 0.244475, innerDiameter: 0.2204974, weight: 69.9438024934 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 148, outerDiameter: 0.244475, innerDiameter: 0.216789, weight: 79.6168815617 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 149, outerDiameter: 0.244475, innerDiameter: 0.214249, weight: 86.9088950131 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 150, outerDiameter: 0.244475, innerDiameter: 0.2135378, weight: 88.3970610236 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 151, outerDiameter: 0.244475, innerDiameter: 0.2103374, weight: 96.5819740814 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 152, outerDiameter: 0.244475, innerDiameter: 0.2071878, weight: 104.6180705381 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 153, outerDiameter: 0.244475, innerDiameter: 0.2039874, weight: 112.5053503937 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 154, outerDiameter: 0.24765, innerDiameter: 0.217424, weight: 88.09931 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 155, outerDiameter: 0.250825, innerDiameter: 0.219075, weight: 93.4567 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 156, outerDiameter: 0.27305, innerDiameter: 0.2588768, weight: 48.7374368438 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 157, outerDiameter: 0.27305, innerDiameter: 0.25527, weight: 60.2707234252 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 158, outerDiameter: 0.27305, innerDiameter: 0.25273, weight: 67.7115534777 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 159, outerDiameter: 0.27305, innerDiameter: 0.25019, weight: 75.8964665354 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 160, outerDiameter: 0.27305, innerDiameter: 0.247904, weight: 82.5932135827 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 161, outerDiameter: 0.27305, innerDiameter: 0.245364, weight: 90.3316768373 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 162, outerDiameter: 0.27305, innerDiameter: 0.242824, weight: 97.7725068898 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 163, outerDiameter: 0.27305, innerDiameter: 0.2389124, weight: 108.9337519685 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 164, outerDiameter: 0.27305, innerDiameter: 0.2357628, weight: 117.8627480315 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 165, outerDiameter: 0.27305, innerDiameter: 0.2325624, weight: 126.9405606955 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 166, outerDiameter: 0.29845, innerDiameter: 0.2794, weight: 69.9438024934 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 167, outerDiameter: 0.29845, innerDiameter: 0.276352, weight: 80.3609645669 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 168, outerDiameter: 0.29845, innerDiameter: 0.2736088, weight: 89.2899606299 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 169, outerDiameter: 0.29845, innerDiameter: 0.2713228, weight: 96.7307906824 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 170, outerDiameter: 0.29845, innerDiameter: 0.2688844, weight: 105.6597867454 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 171, outerDiameter: 0.339725, innerDiameter: 0.322961, weight: 71.4319685039 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 172, outerDiameter: 0.339725, innerDiameter: 0.320421, weight: 81.1050475722 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 173, outerDiameter: 0.339725, innerDiameter: 0.317881, weight: 90.7781266404 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 174, outerDiameter: 0.339725, innerDiameter: 0.315341, weight: 101.1952887139 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 175, outerDiameter: 0.339725, innerDiameter: 0.3136138, weight: 107.1479527559 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 176, outerDiameter: 0.4064, innerDiameter: 0.38735, weight: 96.7307906824 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 177, outerDiameter: 0.4064, innerDiameter: 0.3841496, weight: 111.6124507874 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 178, outerDiameter: 0.4064, innerDiameter: 0.381254, weight: 125.0059448819 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 179, outerDiameter: 0.4064, innerDiameter: 0.3730752, weight: 162.2100951444 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 180, outerDiameter: 0.473075, innerDiameter: 0.450977, weight: 130.2145259186 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 181, outerDiameter: 0.508, innerDiameter: 0.4857496, weight: 139.8876049869 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 182, outerDiameter: 0.508, innerDiameter: 0.4826, weight: 158.4896801181 },
  },
  {
    error: {},
    isValid: true,
    rowType: 'data',
    isEditingDisabled: true,
    rowData: { Id: 183, outerDiameter: 0.508, innerDiameter: 0.475742, weight: 197.9260793963 },
  },
];
