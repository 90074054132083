import { BasePptSectionGenerator, PptContext } from '../base-ppt-section-generator';
import { disclaimer1, disclaimer2 } from '../../common/common-report-data-helpers';
import { baseTextProps, contentPosition } from '../style-constants';

export class DisclaimerPptGenerator extends BasePptSectionGenerator {
  constructor(context: PptContext) {
    super(context);
  }

  public async generate(): Promise<void> {
    const slide = BasePptSectionGenerator.addSlide(this.context.pptx, { title: 'Disclaimer' });
    slide.addText(
      [
        { text: disclaimer1, options: { breakLine: true } },
        { text: '', options: { breakLine: true } },
        { text: disclaimer2, options: { breakLine: true } },
      ],
      {
        ...contentPosition,
        ...baseTextProps,
        fontSize: 11,
        align: 'justify',
        valign: 'bottom',
      },
    );
  }
}
