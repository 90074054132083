import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LabelsSelectorComponent } from './labels-selector.component';
import { FormsModule } from '@angular/forms';
import { MultiSelectModule } from 'primeng/multiselect';
import { TooltipModule } from 'primeng/tooltip';

@NgModule({
  imports: [CommonModule, FormsModule, MultiSelectModule, TooltipModule],
  declarations: [LabelsSelectorComponent],
  exports: [LabelsSelectorComponent],
})
export class RangeLabelsSelectorModule {}
