import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';

export interface IGridColumnConfig<Entity> {
  disabled: boolean;
  visible: boolean;
  colId: keyof Entity | ' ';
  type: ColumnType;
  isNotUiLockable?: boolean;
  headerText?: string;
  reportingHeaderName?: string;
  showError?: boolean;
  unitSystem?: UnitSystem;
  overrideUnitSymbol?: string;
  overrideUnitType?: number;
  lookupDataSourceType?: string;
  width?: number;
  decimalPlaces?: number;
  align?: GridCellAlign;
  isVisibleOnInsertRow?: boolean;
  placeholder?: string;
  isFillDownDisabled?: boolean;
  readonly?: boolean;
  maxLength?: number;
  allowOptionalStringValue?: boolean;
  castNullToNumber?: boolean;
  matchingStrings?: string[];
}

export type GridCellAlign = 'left' | 'center' | 'right';

export enum ColumnType {
  number = 0,
  text = 1,
  select = 2,
  checkbox = 3,
  selection = 4, // this is first column where user clicks to select row
  boolean = 5, // for report tables
}

export type ICustomKeyboardHandlers = {
  [key in KeyboardKey]?: () => void | Promise<void>;
};

export type KeyboardKey = 'Delete' | 'Insert' | 'Escape ';
