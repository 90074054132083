import { IRiff } from '../interfaces';

export const parseRIFF = (string: string): IRiff => {
  let offset = 0;
  const chunks: {
    [index: string]: any;
  } = {};

  while (offset < string.length) {
    const id = string.substr(offset, 4);
    chunks[id] = chunks[id] || [];
    if (id == 'RIFF' || id == 'LIST') {
      const len = parseInt(
        string
          .substr(offset + 4, 4)
          .split('')
          .map(function (i) {
            const unpadded = i.charCodeAt(0).toString(2);
            return new Array(8 - unpadded.length + 1).join('0') + unpadded;
          })
          .join(''),
        2,
      );
      const data = string.substr(offset + 4 + 4, len);
      offset += 4 + 4 + len;
      chunks[id].push(parseRIFF(data));
    } else if (id == 'WEBP') {
      // Use (offset + 8) to skip past "VP8 "/"VP8L"/"VP8X" field after "WEBP"
      chunks[id].push(string.substr(offset + 8));
      offset = string.length;
    } else {
      // Unknown chunk type; push entire payload
      chunks[id].push(string.substr(offset + 4));
      offset = string.length;
    }
  }
  return chunks as any as IRiff;
};
