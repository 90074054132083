<p-tree
  *ngIf="isCheckboxMode"
  [value]="nodes"
  [(selection)]="selection"
  [selectionMode]="'checkbox'"
  (onNodeSelect)="selectionChange.emit(selection)"
  (onNodeUnselect)="selectionChange.emit(selection)"
>
</p-tree>
<p-tree *ngIf="!isCheckboxMode" [value]="nodes"> </p-tree>
