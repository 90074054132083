import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { getRowsForCalculations, ITableState } from '@dunefront/common/common/common-grid.interfaces';
import {
  createChartDataColumn,
  createChartDataSet,
  createEmptyChartData,
  IChartDataDto,
} from '@dunefront/common/modules/reporting/dto/chart-data.dto';
import { DbDependentComponent } from '../../../../../common-modules/db-connection/db-dependent.component';
import { Store } from '@ngrx/store';

import { ValidatedWellModuleState } from '@dunefront/common/modules/well/well-module.state';
import { ScreenService } from '../../../../../shared/services';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { DataType } from '@dunefront/common/dto/data-storage';
import { Caliper } from '@dunefront/common/modules/well/model/caliper/caliper';
import { ChartState } from '../../../../../+store/reporting/reporting-module.state';
import { ChartDataSourceType } from '@dunefront/common/modules/reporting/dto/chart.dto';
import { getChartIds } from '../../../../../+store/reporting/reporting.selectors';
import { ChartAxis } from '@dunefront/common/modules/reporting/dto/chart-axis-property.dto';
import { ReportingFactory } from '../../../../../+store/reporting/model/reporting.factory';
import { CaliperDataHelper } from '@dunefront/common/modules/well/model/caliper/caliper-data.helper';

const CHART_NAME_CALIPER_PREVIEW = 'Caliper Preview';

@Component({
  selector: 'app-caliper-preview-chart',
  templateUrl: './caliper-preview-chart.component.html',
  styleUrls: ['./caliper-preview-chart.component.scss'],
})
export class CaliperPreviewChartComponent extends DbDependentComponent implements OnChanges, AfterViewInit {
  public readonly unit: UnitSystem = UnitSystem.Diameter;
  public readonly chartName = CHART_NAME_CALIPER_PREVIEW;

  @Input() public well: ValidatedWellModuleState | undefined;
  @Input() public drawableProviderId?: string;
  @Input() public caliperData: ITableState<Caliper> | undefined;

  @Output() public caliperToleranceChange = new EventEmitter<number>();

  public chartState?: ChartState;
  public chartId = 0;

  constructor(
    store: Store,
    cdRef: ChangeDetectorRef,
    protected resizeService: ScreenService,
  ) {
    super(store, cdRef);

    this.subscription.add(
      store.select(getChartIds).subscribe((chartIds) => {
        this.chartId = chartIds.caliperChartId;
        cdRef.markForCheck();
      }),
    );
  }

  public ngAfterViewInit(): void {
    this.setChartData();
  }

  public ngOnChanges(): void {
    this.setChartData();
  }

  private setChartData(): void {
    if (!this.caliperData || !this.well || !this.well.CaliperData.isValid || (this.well.error.CaliperMultiplier?.length as number) > 0) {
      this.chartState = undefined;
      return;
    }

    this.chartState = ReportingFactory.createChartStateWithData(ChartDataSourceType.Caliper, this.calculateCaliperProfile());
    this.cdRef.markForCheck();
  }

  private calculateCaliperProfile(): IChartDataDto {
    const chartDataDto = createEmptyChartData(DataType.Measured_Depth, ChartDataSourceType.Caliper, this.chartId);

    const averagedOHDiameter = createChartDataColumn(
      ChartAxis.PrimaryValue,
      DataType.Open_Hole_Averaged_Diameter,
      0,
      0,
      'Averaged OH Diameter',
    );
    const averagedDataset = createChartDataSet();
    chartDataDto.ChartDataColumns.push(averagedOHDiameter);
    chartDataDto.ChartDataSets.push(averagedDataset);

    const actualOHDiameter = createChartDataColumn(ChartAxis.PrimaryValue, DataType.Open_Hole_Diameter, 1, 0, 'Actual OH Diameter');
    const actualDataset = createChartDataSet();
    chartDataDto.ChartDataColumns.push(actualOHDiameter);
    chartDataDto.ChartDataSets.push(actualDataset);

    if (!this.well || !this.caliperData) {
      return chartDataDto;
    }

    const caliperData = getRowsForCalculations(this.caliperData.rows);

    // write actual values line
    for (let i = 0; i < caliperData.length; i++) {
      actualDataset.ChartDataRows.push({
        Argument: caliperData[i]?.TopMD,
        Values: [caliperData[i]?.Diameter],
      });
    }

    const caliperAveragedData = CaliperDataHelper.calculateCaliperAveraged(caliperData, this.well.CaliperTolerance);

    for (let i = 0; i < caliperAveragedData.length; i++) {
      averagedDataset.ChartDataRows.push({
        Argument: caliperAveragedData[i].TopMD,
        Values: [caliperAveragedData[i].Diameter],
      });
    }

    return chartDataDto;
  }

  public onValueChange(value: number): void {
    this.caliperToleranceChange.emit(value);
  }
}
