import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FrictionCalculatorModuleFeatureName } from './friction-calculator.reducer';
import { FrictionCalculatorModuleState } from '@dunefront/common/modules/calculators/friction-calculator/friction-calculator-module.state';
import { getValidatedFluids } from '../../fluid/fluid.selectors';
import { FlowPathType } from '@dunefront/common/modules/calculators/resuspension-calculator/resuspension-calculator.dto';
import { FluidType } from '@dunefront/common/modules/fluid/dto/fluid.dto';
import { DictionaryWithArray } from '@dunefront/common/common/state.helpers';
import { getCurrentScenarioId } from '../../scenario/scenario.selectors';
import { FrictionCalculatorValidation } from '@dunefront/common/modules/calculators/friction-calculator/model/friction-calculator.validation';

const getFrictionCalculatorModuleState = createFeatureSelector<FrictionCalculatorModuleState>(FrictionCalculatorModuleFeatureName);
export const getValidatedFrictionCalculatorModuleState = createSelector(
  getFrictionCalculatorModuleState,
  getValidatedFluids,
  (...[state, fluids]) => FrictionCalculatorValidation.validate(state, fluids),
);
export const getFrictionCalculatorData = createSelector(
  getFrictionCalculatorModuleState,
  (state: FrictionCalculatorModuleState) => state.FrictionCalculator,
);
export const getIsFluidNewtonianOrShuntTube = createSelector(
  getFrictionCalculatorData,
  getValidatedFluids,
  (...[frictionCalculator, fluids]) => {
    if (frictionCalculator.FluidId == null) {
      return true;
    }
    const fluid = DictionaryWithArray.get(fluids, frictionCalculator.FluidId);
    if (!fluid) {
      return true;
    }

    return (
      frictionCalculator.FlowPathType === FlowPathType.Rectangular_Shunt ||
      frictionCalculator.FlowPathType === FlowPathType.Round_Shunt ||
      fluid.Type === FluidType.Newtonian
    );
  },
);

export const getHasFrictionCalculatorResults = createSelector(getFrictionCalculatorModuleState, getCurrentScenarioId, (state, scenarioId) =>
  state.FrictionCalculatorResult.some((res) => res.ScenarioId === scenarioId),
);
