import { ChartContext, IZoomPluginListener, MouseMoveMode } from './chart-types';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { updateChart } from './chart-common-helpers';
import { ChartDataHelpers } from './chart-data-helpers';

const isDragToZoomPending = (zoomPending: boolean, shiftDown: boolean, mouseMoveMode: MouseMoveMode): boolean => {
  return zoomPending || (shiftDown && mouseMoveMode === MouseMoveMode.default);
};

const isDragToPanPending = (panPending: boolean, controlDown: boolean, mouseMoveMode: MouseMoveMode): boolean => {
  return panPending || (controlDown && mouseMoveMode === MouseMoveMode.default);
};

export class ZoomPluginHelper implements IZoomPluginListener {
  private _zoomPending$ = new BehaviorSubject<boolean>(false);
  private _panPending$ = new BehaviorSubject<boolean>(false);

  private _shiftDown$ = new BehaviorSubject<boolean>(false);
  private _controlDown$ = new BehaviorSubject<boolean>(false);

  private subscription = new Subscription();

  constructor(private context: ChartContext) {
    this.subscription.add(
      context.mouseMoveMode$.pipe(distinctUntilChanged()).subscribe((mode) => {
        const chart = context.chartDisplay;
        if (chart == null) {
          return;
        }

        const plugins = chart.options?.plugins;
        if (plugins == null) {
          return;
        }

        const enable = mode === MouseMoveMode.default;

        plugins.zoom = enable ? ChartDataHelpers.getZoomPluginOptions(this, context.allowInteractions) : undefined;

        updateChart(chart);
      }),
    );
  }

  public get dragToZoomPending$(): Observable<boolean> {
    return combineLatest([this._zoomPending$, this._shiftDown$, this.context.mouseMoveMode$]).pipe(
      map(([zoomPending, shiftDown, mouseMoveMode]) => isDragToZoomPending(zoomPending, shiftDown, mouseMoveMode)),
    );
  }

  public get dragToPanPending$(): Observable<boolean> {
    return combineLatest([this._panPending$, this._controlDown$, this.context.mouseMoveMode$]).pipe(
      map(([panPending, controlDown, mouseMoveMode]) => isDragToPanPending(panPending, controlDown, mouseMoveMode)),
    );
  }

  public get isDragToZoomPending(): boolean {
    return isDragToZoomPending(this._zoomPending$.value, this._shiftDown$.value, this.context.mouseMoveMode$.value);
  }

  public get isDragToPanPending(): boolean {
    return isDragToPanPending(this._panPending$.value, this._controlDown$.value, this.context.mouseMoveMode$.value);
  }

  public get isDragPending(): boolean {
    return this.isDragToZoomPending || this.isDragToPanPending;
  }

  public onPanComplete(): void {
    if (this._panPending$.value) {
      this._panPending$.next(false);
    }
  }

  public onPanStart(event: Event): boolean {
    if (!this._panPending$.value) {
      this._panPending$.next(true);
    }

    return true;
  }

  public onZoomComplete(): void {
    if (this._zoomPending$.value) {
      this._zoomPending$.next(false);
    }
  }

  public onZoomStart(event: Event): void {
    if (event.type === 'mousedown' && !this._zoomPending$.value) {
      this._zoomPending$.next(true);
    }
  }

  public onShiftKeyDown(): void {
    if (!this._shiftDown$.value) {
      this._shiftDown$.next(true);
    }
  }

  public onShiftKeyUp(): void {
    if (this._shiftDown$.value) {
      this._shiftDown$.next(false);
    }
  }

  public onControlKeyDown(): void {
    if (!this._controlDown$.value) {
      this._controlDown$.next(true);
    }
  }

  public onControlKeyUp(): void {
    if (this._controlDown$.value) {
      this._controlDown$.next(false);
    }
  }
}
