import { CaliperDto, CaliperTransferArray } from '../../dto/caliper/caliper.dto';
import { createTableRow, GridRowType, ITableRow } from '../../../../common/common-grid.interfaces';
import { Caliper } from './caliper';
import { CaliperDataHelper } from './caliper-data.helper';

export class CaliperFactory {
  public static createRowListFromDtos(caliperDtos: CaliperDto[], scenarioId: number): ITableRow<Caliper>[] {
    return [
      ...caliperDtos.map((caliperDto, index) => this.createRowFromDto(caliperDto, index)),
      this.createEmptyRow(scenarioId, caliperDtos.length, false, 'insert-row'),
    ];
  }

  public static createRowFromDto(
    caliperDto: CaliperDto,
    index: number,
    isEditingDisabled = false,
    rowType: GridRowType = 'data',
  ): ITableRow<Caliper> {
    if (index === 0) {
      rowType = 'first-row';
      isEditingDisabled = false;
    }
    return createTableRow<Caliper>(this.createCaliper(caliperDto), rowType, index, isEditingDisabled);
  }

  public static createEmpty(scenarioId: number): Caliper {
    return {
      ScenarioId: scenarioId,
      Id: -1,
      TopMD: 0,
      Diameter: 0,
      SortOrder: -1,
    };
  }

  public static createEmptyRow(scenarioId: number, rowIndex: number, isEditingDisabled: boolean, rowType: GridRowType): ITableRow<Caliper> {
    return createTableRow(this.createEmpty(scenarioId), rowType, rowIndex, isEditingDisabled);
  }

  public static toDto(caliper: Caliper): CaliperDto {
    return {
      ScenarioId: caliper.ScenarioId,
      Id: caliper.Id,
      TopMD: caliper.TopMD,
      Diameter: caliper.Diameter,
      SortOrder: caliper.SortOrder,
    };
  }

  public static createCaliper(caliperDto: CaliperDto): Caliper {
    return {
      ...caliperDto,
    };
  }

  public static createRowFromPaste(TopMD: number, Diameter: number, ScenarioId: number): ITableRow<Caliper> {
    return this.createRowFromDto(
      {
        SortOrder: 0,
        TopMD,
        Diameter,
        Id: -1,
        ScenarioId,
      },
      0,
    );
  }

  /**
   * Calculates averaged caliper and converts to flat array of points: [TopMD, Diameter, TopMD, Diameter...]
   * Done in order to reduce size of serialised caliper objects
   */
  public static toCalculatedCaliperTransferArray(caliperDtos: CaliperDto[], tolerance: number): CaliperTransferArray {
    const calculatedCaliper = CaliperDataHelper.calculateCaliperAveraged(caliperDtos, tolerance);

    return calculatedCaliper.reduce((acc: number[], { TopMD, Diameter }) => [...acc, ...[TopMD, Diameter]], []);
  }
}
