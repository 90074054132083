import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { updateEquation } from '../../../../../+store/equation/equation.actions';
import { selectEquationFormula, selectEquationValidityState } from '../../../../../+store/equation/equation.selectors';

@Component({
  selector: 'app-add-column-equation-input',
  templateUrl: './add-column-equation-input.component.html',
  styleUrls: ['./add-column-equation-input.component.scss'],
})
export class AddColumnEquationInputComponent {
  public validityState$ = this.store.select(selectEquationValidityState);
  public equation$ = this.store.select(selectEquationFormula);

  constructor(private store: Store) {}

  public onEquationChanged(equation: string): void {
    this.store.dispatch(updateEquation({ equation }));
  }
}
