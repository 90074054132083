import { MASPCalculatorDto } from '../masp-calculator.dto';
import { ValidatedMaspCalculatorModuleState } from '../masp-calculator-module.state';

export class MASPCalculatorFactory {
  public static toDto(maspCalculator: ValidatedMaspCalculatorModuleState): MASPCalculatorDto {
    const { error, isValid, ...dto } = maspCalculator;
    return dto;
  }

  public static cleanResultsPartial(): Partial<MASPCalculatorDto> {
    return {
      MaxAllowableSurfacePressure: 0,
    };
  }
}
