import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import * as uiActions from '../../../+store/ui/ui.actions';
import { getValidatedGaugeDataModuleState } from '../../../+store/gauge-data/gauge-data.selectors';
import { PageWithGridComponent } from '../../../shared/components/grid/page-with-grid.component';
import { ModalService } from '../../../common-modules/modals/modal.service';
import { ScreenService } from '../../../shared/services';
import { GaugeDataModuleState, IGaugeDataRow } from '../../../+store/gauge-data/gauge-data-module.state';
import { ITableRow } from '@dunefront/common/common/common-grid.interfaces';
import { loadGaugeDataAction } from '../../../+store/gauge-data/gauge-data.actions';
import { deleteColumnAction, deleteFileAction, reduceDataAction, resetDataAction } from '../../../+store/data-storage/data-storage.actions';
import { getValidatedStorageFilesWithColumns } from '../../../+store/data-storage/data-storage.selectors';
import { ValidatedImportColumnDto } from '@dunefront/common/modules/data-storage/data-storage-module.state';
import { ArgumentType, ImportColumnDto } from '@dunefront/common/modules/data-storage/dto/import-column.dto';
import { DictionaryWithArray, IDictionaryWithArray, isDefined } from '@dunefront/common/common/state.helpers';
import { IValidatedStorageFileWithColumns } from '@dunefront/common/modules/data-storage/data-storage.validation';
import {
  addUserDefinedFileAction,
  addUserDefinedFormulaColumnAction,
  editUserDefinedFileAction,
  editUserDefinedFormulaColumnAction,
  generateSourceVariablesAction,
  recalculateAllEquationsAction,
} from '../../../+store/equation/equation.actions';
import { DataFileType } from '@dunefront/common/dto/data-storage';
import { HelpIds } from '../../../+store/help/help.actions';
import { ScenarioConstants } from '@dunefront/common/modules/scenario/scenario.dto';
import { IUpdateTableRowsProps, StoreCrudPropsFactory } from '@dunefront/common/common/common-store-crud.interfaces';
import { getAccessibleFeaturesAndLicenses } from '../../../+store/licensing/licensing.selectors';
import { LicenseFeature } from '@dunefront/common/modules/licensing/licensing.interfaces';
import { PanelHelpMode } from '../../../shared/components/help-button/help-buton.component';
import { MenuItem } from 'primeng/api';
import { openImportDataModalAction } from '../../../+store/import-data/import-data.actions';
import { Menu } from 'primeng/menu';
import { InputsHelperService } from '../../../shared/services/inputs-helper.service';
import { getCurrentRangeId } from '../../../+store/range/range.selectors';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-gauge-data',
  templateUrl: './gauge-data.component.html',
  styleUrls: ['./gauge-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GaugeDataComponent extends PageWithGridComponent<IGaugeDataRow> implements AfterViewInit {
  public state!: GaugeDataModuleState;
  public selectedColumn: ValidatedImportColumnDto | undefined;
  public storageFiles: IDictionaryWithArray<IValidatedStorageFileWithColumns> = DictionaryWithArray.clear();
  public columns: ValidatedImportColumnDto[] = [];
  public selectedColumnCellIndex: number | undefined = 1;
  public prevSelectedColumnCellIndex: number | undefined;

  public PanelHelpMode = PanelHelpMode;

  public dataMenuItems: MenuItem[] = [];
  public recalculateMenuItems: MenuItem[] = [];
  public columnMenuItems: MenuItem[] = [];
  public fileMenuItems: MenuItem[] = [];

  @ViewChild('dataMenu')
  public dataMenu: Menu | undefined;

  @ViewChild('recalculateMenu')
  public recalculateMenu: Menu | undefined;

  @ViewChild('columnMenu')
  public columnMenu: Menu | undefined;

  @ViewChild('fileMenu')
  public fileMenu: Menu | undefined;

  public isEquationFileDisabled = false;

  public get noValueColumn(): boolean {
    return this.columns.filter((col) => col.IsXAxis === ArgumentType.Value).length === 0;
  }

  constructor(
    store: Store,
    cdRef: ChangeDetectorRef,
    modalService: ModalService,
    resizeService: ScreenService,
    private readonly inputsHelperService: InputsHelperService,
  ) {
    super(store, cdRef, modalService, resizeService);
    store.dispatch(uiActions.setGaugeDataTabAction({ tab: 'data' }));

    this.subscription.add(
      this.store.select(getAccessibleFeaturesAndLicenses).subscribe((features) => {
        this.isEquationFileDisabled = !features.features.includes(LicenseFeature.Data_Analysis);
        this.cdRef.markForCheck();
      }),
    );

    this.subscription.add(
      this.store.select(getValidatedGaugeDataModuleState).subscribe((state) => {
        const prevSelectedFileId = this.state?.SelectedFileId;
        this.state = state;
        this.onDataChanged(prevSelectedFileId !== state.SelectedFileId);
      }),
    );

    this.subscription.add(
      this.store.select(getValidatedStorageFilesWithColumns).subscribe((storageFiles) => {
        this.storageFiles = storageFiles;
        this.onDataChanged();
      }),
    );

    this.subscription.add(
      this.store
        .select(getCurrentRangeId)
        .pipe(filter(() => this.state.SelectedFileId !== -1))
        .subscribe(() => this.store.dispatch(loadGaugeDataAction({ fileId: this.state.SelectedFileId }))),
    );
  }

  private async execCommand(command: () => void): Promise<void> {
    if (!(await this.inputsHelperService.checkResultsAndDeleteIfNeeded(true))) {
      return;
    }

    command();
  }

  public toggleDataMenu($event: any): void {
    this.dataMenuItems = [
      {
        automationId: 'data-menu-reset',
        label: 'Reset Data',
        command: (): Promise<void> => this.execCommand(() => this.onResetDataClick()),
      },
      {
        automationId: 'data-menu-reduce',
        label: 'Reduce Data',
        command: (): Promise<void> => this.execCommand(() => this.onReduceDataClick()),
      },
    ];
    this.cdRef.detectChanges();
    this.dataMenu?.toggle($event);
  }

  public toggleRecalculateMenu($event: any): void {
    this.recalculateMenuItems = [
      {
        automationId: 'recalculate-menu-current-file',
        label: 'Recalculate Current File',
        disabled: !this.isEquationResultFile,
        command: (): Promise<void> => this.execCommand(() => this.onRecalculateClick()),
      },
      {
        automationId: 'recalculate-menu-all-files',
        label: 'Recalculate All Files',
        disabled: !this.isAtLeastOneEquationResultFile,
        command: (): Promise<void> => this.execCommand(() => this.onRecalculateAllClick()),
      },
    ];

    this.cdRef.detectChanges();
    this.recalculateMenu?.toggle($event);
  }

  public toggleFileMenu($event: any): void {
    this.fileMenuItems = [
      {
        automationId: 'file-menu-import',
        label: 'Import File',
        command: (): Promise<void> => this.execCommand(() => this.onImportClick()),
      },
      {
        automationId: 'file-menu-add-equation',
        label: 'Add Equation File',
        disabled: this.isEquationFileDisabled,
        command: (): Promise<void> => this.execCommand(() => this.onAddEquationFileClick()),
      },
      {
        automationId: 'file-menu-edit-equation',
        label: 'Edit Equation File',
        disabled: this.isEquationFileDisabled,
        visible: this.isEquationResultFile,
        command: (): Promise<void> => this.execCommand(() => this.onEditEquationFileClick()),
      },
      {
        automationId: 'file-menu-delete',
        label: 'Delete File',
        disabled: !this.isDeleteFileEnabled,
        command: (): Promise<void> => this.execCommand(() => this.onDeleteFileClick()),
      },
    ];

    this.cdRef.detectChanges();
    this.fileMenu?.toggle($event);
  }

  public toggleColumnMenu($event: any): void {
    this.columnMenuItems = [
      {
        automationId: 'column-menu-add-equation',
        label: 'Add Equation Column',
        visible: this.isEquationResultFile,
        command: (): Promise<void> => this.execCommand(() => this.onAddEquationColumnClick()),
      },
      {
        automationId: 'column-menu-edit-equation',
        label: 'Edit Equation Column',
        visible: this.isEquationResultFile,
        disabled: !this.isEditEquationColumnEnabled,
        command: (): Promise<void> => this.execCommand(() => this.onEditEquationColumnClick()),
      },
      {
        automationId: 'column-menu-delete',
        label: 'Delete Column',
        disabled: !this.isDeleteColumnEnabled,
        command: (): Promise<void> => this.execCommand(() => this.onDeleteColumnClick()),
      },
    ];

    this.cdRef.detectChanges();
    this.columnMenu?.toggle($event);
  }

  public ngAfterViewInit(): void {
    this.onDataPageHelpChange('data');
  }

  private onDataChanged(currentFileChanged = false): void {
    const prevColumns = this.columns;
    const currentFile = DictionaryWithArray.get(this.storageFiles, this.state.SelectedFileId);
    if (!currentFile) {
      this.cdRef.markForCheck();
      return;
    }

    this.columns = [...currentFile.columns].sort(
      (a, b) => (a.IsXAxis !== ArgumentType.Value ? -1 : a.SortOrder) - (b.IsXAxis !== ArgumentType.Value ? -1 : b.SortOrder),
    );

    if (currentFileChanged) {
      this.selectedColumnCellIndex = 1;
    } else if (this.state.tableState.rows.length == 0 && this.selectedColumnCellIndex != undefined) {
      this.prevSelectedColumnCellIndex = this.selectedColumnCellIndex;
      this.selectedColumnCellIndex = undefined;
    } else if (this.state.tableState.rows.length > 0 && this.prevSelectedColumnCellIndex != undefined) {
      this.selectedColumnCellIndex = this.prevSelectedColumnCellIndex;
      this.prevSelectedColumnCellIndex = undefined;
    }

    this.updateSelectedColumn();

    if (this.state.SelectedFileId && this.shouldReloadGrid(prevColumns, this.columns)) {
      this.store.dispatch(loadGaugeDataAction({ fileId: this.state.SelectedFileId }));
    }
  }

  public get isDeleteColumnEnabled(): boolean {
    return !!this.selectedColumn && this.selectedColumn.IsXAxis === ArgumentType.Value;
  }

  public get isDeleteFileEnabled(): boolean {
    return this.storageFiles.ids.length > 0 && this.state.isLoaded;
  }

  public get isEditEquationColumnEnabled(): boolean {
    return !!this.selectedColumn?.Equation && this.isEquationResultFile;
  }

  public get isEquationResultFile(): boolean {
    if (this.state.SelectedFileId == null) {
      return false;
    }
    const currentFile = DictionaryWithArray.get(this.storageFiles, this.state.SelectedFileId);
    if (currentFile == null) {
      return false;
    }
    return currentFile.file?.FileType === DataFileType.EquationResult;
  }

  public get isAtLeastOneEquationResultFile(): boolean {
    return Object.values(this.storageFiles.dict)
      .filter(isDefined)
      .some((file) => file.file?.FileType === DataFileType.EquationResult);
  }

  public getRows(): ITableRow<IGaugeDataRow>[] {
    return [];
  }

  public async onDeleteColumnClick(): Promise<void> {
    if (!this.selectedColumn) {
      return;
    }
    if (this.columns.filter((col) => col.IsXAxis === ArgumentType.Value).length <= 1) {
      await this.modalService.showAlert('At least one column must remain. To delete entire file, use "Delete File" button.');
      return;
    }

    if (await this.modalService.showConfirmNoUndoable('Are you sure you want to delete this column?', 'Delete Column')) {
      this.selectedColumn != null && this.store.dispatch(deleteColumnAction(StoreCrudPropsFactory.deleteRows(this.selectedColumn, true)));
    }
  }

  public async onDeleteFileClick(): Promise<void> {
    if (await this.modalService.showConfirmNoUndoable('Are you sure you want to delete file?', 'Delete File')) {
      this.state.SelectedFileId &&
        this.store.dispatch(
          deleteFileAction({
            rowIds: [this.state.SelectedFileId],
            scenarioId: ScenarioConstants.EmptyScenarioId,
            shouldResetResults: true,
          }),
        );
    }
  }

  public onSelectedCellIndexChanged(cellIndex: number | undefined): void {
    // this is done to change selected column index in next angular tick.
    // There was situation that this was triggered before blur on input box, so selected column index was wrong
    setTimeout(() => {
      this.selectedColumnCellIndex = cellIndex;
      this.updateSelectedColumn();
    }, 5);
  }

  public async onResetDataClick(): Promise<void> {
    if (await this.modalService.showConfirm('Are you sure want to reset data for all columns?', 'Reset Data')) {
      this.state.SelectedFileId &&
        this.store.dispatch(
          resetDataAction({
            fileId: this.state.SelectedFileId,
          }),
        );
    }
  }

  public async onReduceDataClick(): Promise<void> {
    const result: number | string | null = await this.modalService.showPrompt(
      'Sampling frequency for data reduction',
      'Sampling Frequency',
      '',
      'sm',
      'OK',
      'Cancel',
      false,
      0,
      1,
    );

    if (this.state.SelectedFileId && result != null) {
      const isReduceDataConfirmed = await this.modalService.showConfirm(
        'This will sample the data at the specified frequency and permanently delete all other data in the entire file ' +
          '(not just the selected range) - are you sure you want to continue?',
        'Information',
      );
      if (isReduceDataConfirmed) {
        this.store.dispatch(reduceDataAction({ fileId: this.state.SelectedFileId, samplingFrequency: Number(result) }));
      }
    }
  }

  private onImportClick(): void {
    this.store.dispatch(openImportDataModalAction());
  }

  private onRecalculateClick(): void {
    this.store.dispatch(generateSourceVariablesAction({ fileId: this.state.SelectedFileId }));
  }

  private onRecalculateAllClick(): void {
    this.store.dispatch(recalculateAllEquationsAction({ currentUiFileId: this.state.SelectedFileId }));
  }

  private onEditEquationColumnClick(): void {
    if (this.selectedColumn) {
      this.store.dispatch(editUserDefinedFormulaColumnAction({ selectedColumn: this.selectedColumn }));
    }
  }

  private onAddEquationColumnClick(): void {
    this.store.dispatch(addUserDefinedFormulaColumnAction({ fileId: this.state.SelectedFileId }));
  }

  private onAddEquationFileClick(): void {
    this.store.dispatch(addUserDefinedFileAction());
  }

  private onEditEquationFileClick(): void {
    this.store.dispatch(editUserDefinedFileAction());
  }

  protected updateRow(row: IUpdateTableRowsProps<any>): void {
    return;
  }

  private shouldReloadGrid(prevColumns: ImportColumnDto[], currentColumns: ImportColumnDto[]): boolean {
    if (prevColumns.length !== currentColumns.length) {
      return true;
    }

    const getColsCompareString = (columns: ImportColumnDto[]): string =>
      columns.map((col) => `${col.DataType}_${col.UnitSystem}_${col.Unit}_${col.ColumnName}`).join();

    return getColsCompareString(prevColumns) !== getColsCompareString(currentColumns);
  }

  private updateSelectedColumn(): void {
    this.selectedColumn = this.selectedColumnCellIndex === undefined ? undefined : this.columns[this.selectedColumnCellIndex - 1];
    this.cdRef.markForCheck();
  }

  public onDataPageHelpChange(uri: HelpIds): void {
    this.onHelpChange('data', uri);
  }
}
